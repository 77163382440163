import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {filterEmptyValues} from "../../../../utils/objectHelper";

import {getKedoStaffPage, updateKedoStaffStore} from "../../../../ducks/kedo/staff/actionCreators";
import {getKedoStaffInitialState} from "../../../../ducks/kedo/staff/reducer";

export const useKedoStaffRegistryFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        archiveFilter,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        archiveFilter,
    ]);

    useEffect(() => {
        return () => {
            const initialState = getKedoStaffInitialState();

            dispatch(updateKedoStaffStore(initialState));
        };
    }, []);

    const fetchList = () => {
        const requestData = filterEmptyValues(filterData);

        dispatch(getKedoStaffPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            archiveFilter,
            ...requestData,
        }));
    };

    return {
        fetchList,
    };
};