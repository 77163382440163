import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    updateRecruitmentVacancyCandidatesFromResponse,
} from "../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    getRecruitmentVacancyCandidatesResponse,
    updateRecruitmentVacancyCandidatesResponsesStore,
} from "../../../ducks/bff/recruitment/vacancyCandidates/responses/actionCreators";
import {
    bffRecruitmentVacancyCandidatesResponsesCardSelector,
    bffRecruitmentVacancyCandidatesResponsesProgressCardSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/responses/selectors";
import {
    bffRecruitmentVacancyCandidatesProgressActionSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import {updateFileStoreRecruitmentCandidateStore} from "../../../ducks/fileStoreRecruitment";
import NmCheckboxV2 from "../../ActualComponents/NmCheckboxV2";
import NmForm from "../../ActualComponents/NmForm";
import NmLabelText from "../../ActualComponents/NmLabelText";
import NmModal from "../../ActualComponents/NmModal";
import Text from "../../ActualComponents/Text";
import ApplyButtons from "../../ApplyButtons";
import NmLoader from "../../NmLoader";
import NmTitle from "../../NmTitle";
import {CandidateInformationShort} from "../CandidateInformation/components/Short";
import RecruitmentResponseSource from "../ResponseSource";

import {useRecruitmentResponseDataTransferValues} from "./hooks/useValues";

import {isLastElementInArray} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";

export const RESPONSE_CANDIDATE_FIELD_NAMES = {
    applicantName: "ФИО",
    birthDate: "Дата рождения",
    addresses: "Город",
    specialities: "Виды деятельности",
    gender: "Пол",
    phone: "Номер телефона",
    email: "E-mail",
    citizenship: "Гражданство",
    driverLicences: "Категории водительских прав",
    hasCar: "Есть свой автомобиль",
    languages: "Иностранные языки",
    avatar: "Фото",
    experiences: "Опыт работы",
    education: "Образование",
    resumes: "Резюме",
    notes: "Другая информация",
    age: "Возраст",
    workExperience: "Стаж работы",
    salary: "Зарплата",
    schedules: "Подходящие графики работы",
    socialMedia: "Профиль в соц сетях",
    readyForTrip: "Готовность к командировкам",
    readyForMoving: "Возможность переезда",
};

const RecruitmentCandidateResponseDataTransfer = (props) => {
    const {
        onClose,
        candidateId,
        responseId,
        vacancyId,
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(bffRecruitmentVacancyCandidatesResponsesProgressCardSelector);
    const progressAction = useSelector(bffRecruitmentVacancyCandidatesProgressActionSelector);
    const card = useSelector(bffRecruitmentVacancyCandidatesResponsesCardSelector);

    const [fields, setFields] = useState([]);

    const {
        responseFields,
        actualizeFields,
    } = card;

    const responseValues = useRecruitmentResponseDataTransferValues({
        card: responseFields.card,
        list: responseFields.list,
        responseId,
        isResponse: true,
    });

    const actualizeResponseValues = useRecruitmentResponseDataTransferValues({
        card: actualizeFields.response,
        list: actualizeFields.list,
        responseId,
        candidateId,
        isResponse: true,
    });

    const actualizeCandidateValues = useRecruitmentResponseDataTransferValues({
        card: actualizeFields.candidate,
        list: actualizeFields.list,
        responseId,
        candidateId,
    });

    useEffect(() => {
        dispatch(getRecruitmentVacancyCandidatesResponse({
            candidateId,
            responseId,
            vacancyId,
        }));

        return () => {
            dispatch(updateFileStoreRecruitmentCandidateStore({
                responseAvatarBase64Str: null,
            }));
            dispatch(updateRecruitmentVacancyCandidatesResponsesStore({
                card: {},
            }));
        };
    }, []);

    function onSubmit() {
        dispatch(updateRecruitmentVacancyCandidatesFromResponse({
            candidateId,
            responseId,
            fields,
            onSuccess: () => {
                onClose();
                fetchList({isLoadMore: false});
            },
        }));
    }

    const onChange = (event, {name, checked}) => {
        if (checked) {
            setFields([...fields, name]);

            return;
        }

        const _fields = fields.filter(fieldName => fieldName !== name);

        setFields(_fields);
    };

    const getTitle = (text, className) => {
        return (
            <Text.Sub
                level="2"
                color={COLOR.SECONDARY_100}
                className={`${className} mb-2`}
                medium={true}
            >
                {text}
            </Text.Sub>
        );
    };

    const getActualizingData = () => {
        return (
            <>
                {getTitle("Выберите данные, которые нужно актуализировать из отклика", "mt-3 mt-md-4")}
                {
                    isEmpty(actualizeFields.list)
                        ? "-"
                        : actualizeFields.list.map((field, index) => {
                            const isLast = isLastElementInArray(actualizeFields, index);

                            const {
                                className: responseClassName,
                                value: responseValue,
                            } = actualizeResponseValues[field.name] || {};
                            const {
                                className: candidateClassName,
                                value: candidateValue,
                            } = actualizeCandidateValues[field.name] || {};

                            const isDefined = Boolean(RESPONSE_CANDIDATE_FIELD_NAMES[field.name]);

                            return (
                                <React.Fragment
                                    key={field.name}
                                >
                                    <NmCheckboxV2
                                        className="mb-2"
                                        label={
                                            isDefined ?
                                                RESPONSE_CANDIDATE_FIELD_NAMES[field.name] :
                                                "Не определено"
                                        }
                                        name={field.name}
                                        checked={fields.includes(field.name)}
                                        onChange={onChange}
                                    />
                                    <NmLabelText
                                        label="В отклике"
                                        text={responseValue || "-"}
                                        className={`${responseClassName} mb-1`}
                                    />
                                    <NmLabelText
                                        label="В кандидате"
                                        text={candidateValue || "-"}
                                        className={`${candidateClassName} ${!isLast ? "mb-2" : ""}`}
                                    />
                                </React.Fragment>
                            );
                        })
                }
            </>
        );
    };

    const getResponseData = () => {
        return (
            <>
                {getTitle("Данные из отклика", "mt-3 mt-md-4")}
                {
                    isEmpty(responseFields.list) ?
                        "-" :
                        <CandidateInformationShort
                            list={responseFields.list}
                            card={responseValues}
                        />
                }
            </>
        );
    };

    const getSource = () => {
        return (
            <>
                {getTitle("Источник")}
                {
                    card.source ?
                        <RecruitmentResponseSource source={card.source} /> :
                        "-"
                }
            </>
        );
    };

    return (
        <NmModal
            size="md"
            className="stop-list-add"
            header={
                <NmTitle size="lg">
                    {`Перенос данных отклика ${card.applicantName || ""}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    submit={onSubmit}
                />
            }
            classNameContent="d-flex flex-column"
            onClose={onClose}
        >
            <NmLoader
                active={progress}
                inverted={true}
            />
            <NmForm>
                {getSource()}
                {getResponseData()}
                {getActualizingData()}
            </NmForm>
        </NmModal>
    );
};

export default RecruitmentCandidateResponseDataTransfer;