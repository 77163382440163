import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import {isNumber} from "lodash";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import AmountInput from "../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {
    formatAmountWithComma,
    getNumberFromFormattedAmount,
} from "../../../../../../utils/stringFormat";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../../../../constants/finance";

import {
    updateSettingsDirectoriesBank,
} from "../../../../../../ducks/bff/settings/directories/naimix/banks/actionCreators";

const OneTransactionLimitsEditModal = (props) => {
    const {
        onClose,
        fetchList,
        data,
    } = props;

    const dispatch = useDispatch();

    const getInitialLimit = (type) => {
        const {paymentTypeLimits} = data;

        const value = paymentTypeLimits[type];

        if (!isNumber(value)) {
            return "";
        }

        return formatAmountWithComma(value);
    };

    const initialValues = useMemo(() => {
        return {
            [ORDER_WORK_REPORT_TYPE.SMZ]: getInitialLimit(ORDER_WORK_REPORT_TYPE.SMZ),
            [ORDER_WORK_REPORT_TYPE.CIVIL]: getInitialLimit(ORDER_WORK_REPORT_TYPE.CIVIL),
            [ORDER_WORK_REPORT_TYPE.INDIVIDUAL]: getInitialLimit(ORDER_WORK_REPORT_TYPE.INDIVIDUAL),
        };
    }, [data]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(updateSettingsDirectoriesBank({
            ...data,
            paymentTypeLimits: Object.keys(values).map(item => {
                return {
                    paymentType: item,
                    oneTransactionLimit: values[item] ? getNumberFromFormattedAmount(values[item]) : null,
                };
            }),
            onSuccess: () => {
                onClose();

                if (fetchList) {
                    fetchList();
                }

                toastSuccess("Параметры успешно обновлены");
            },
        }));
    };

    const {
        handleSubmit,
        values,
        handleChange,
        isValid,
    } = useFormik({
        initialValues,
        onSubmit,
    });

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {`Изменение лимитов на один платеж в банке "${data.bankName}"`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    loading={false}
                    disabled={false}
                    submitBtnContent="Сохранить"
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin={true}>
                {
                    [
                        ORDER_WORK_REPORT_TYPE.SMZ,
                        ORDER_WORK_REPORT_TYPE.CIVIL,
                        ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                    ].map((field) => {
                        return (
                            <AmountInput
                                newInput={true}
                                maskChar={null}
                                size="xl"
                                label={ORDER_WORK_REPORT_TYPE_TRANSLATE[field]}
                                placeholder="Введите значение"
                                name={field}
                                onChange={handleChange}
                                value={values[field]}
                            />
                        );
                    })
                }
            </NmForm>
        </NmModal>
    );
};

export default OneTransactionLimitsEditModal;