import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getFinancesPaidApisSettings} from "./actionCreators";
import {
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR,
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST,
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/finances/npd-deposit/paid-apis",
        client: "/client-adm/finances/npd-deposit/paid-apis",
    });
};

const getFinancesPaymentsPaidApiPageSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const getFinancesPaidApisSettingsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get-paid-api-settings?clientId=${payload.clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR,
            payload: error,
        });
    }
};

const updateFinancesPaidApisSettingsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update-paid-api-settings`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Данные сохранены успешно");
        yield put(getFinancesPaidApisSettings({clientId: payload.clientId}));

        yield put({
            type: BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST, getFinancesPaymentsPaidApiPageSaga),
        takeEvery(BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST, getFinancesPaidApisSettingsSaga),
        takeEvery(BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST, updateFinancesPaidApisSettingsSaga),
    ]);
}