import {
    BFF_ADD_REGISTRY_INVITATIONS_ERROR,
    BFF_ADD_REGISTRY_INVITATIONS_REQUEST,
    BFF_ADD_REGISTRY_INVITATIONS_SUCCESS,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS,
    BFF_DELETE_REGISTRY_INVITATIONS_ERROR,
    BFF_DELETE_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS,
    BFF_UPDATE_REGISTRY_INVITATIONS_ERROR,
    BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    pageData: {},
    progress: false,
    actionProgress: false,
    totalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_DELETE_REGISTRY_INVITATIONS_REQUEST:
        case BFF_ADD_REGISTRY_INVITATIONS_REQUEST:
        case BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST:
        case BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_DELETE_REGISTRY_INVITATIONS_ERROR:
        case BFF_ADD_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_ADD_REGISTRY_INVITATIONS_ERROR:
        case BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_UPDATE_REGISTRY_INVITATIONS_ERROR:
        case BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        default:
            return state;
    }
};
