import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {useObjectsChangeDeposit} from "./useChangeDeposit";
import {useObjectsEdit} from "./useEdit";
import {useLogHistory} from "./useLogHistory";

import {useModal} from "../../../hooks/useModal";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
    SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
} from "../../../constants/deposit";
import {formatFileDict, UPLOAD_TYPE} from "../../../constants/financeExport";
import {OBJECT_ACTION_DICT, OBJECT_STATUS_DICT, OBJECT_STATUS_FILTER_DICT} from "../../../constants/objects";
import {PROJECT_STATUS_DICT} from "../../../constants/projects";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    changeArchiveObject,
    closeClientObject,
    exportClientObjects,
    multipleChangeArchiveObject,
    updateClientObjectStatus,
} from "../../../ducks/bff/clients/objects/actionCreators";
import {clientProjectCardInfoSelector} from "../../../ducks/bff/clients/projects/card/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";

export const useObjectsAction = (props) => {
    const {
        clientId,
        projectId,
        setPagination,
        pageSize,
        selectedList,
        clearSelectedRows,
    } = props;

    const clientProperties = useSelector(clientCardPropertiesSelector);
    const {
        enableIndividualProjectAndObjectDeposit,
    } = clientProperties;

    const [hideDetails, setHideDetails] = useState(false);
    const [statusFilter, setStatusFilter] = useState(OBJECT_STATUS_FILTER_DICT.ALL.VALUE);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [archived, setArchived] = useState(false);

    const role = ls(USER_ROLE);
    const isAccessByLimits = clientProperties.ordersLimit > 0 || clientProperties.civilOrdersLimit > 0;

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        archived: isClientArchived,
    } = useSelector(clientCardInfoSelector);
    const hideDepositDetailsProgress = useSelector(hideDepositDetailsProgressSelector);
    const project = useSelector(clientProjectCardInfoSelector);

    const isAccessActionsClientObjects = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientProjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
    ]);
    const isAccessDepositActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_addOrRefundDepositObjects,
    ]);

    const archive = getArchiveButton(t, archived, {mobile: true});

    useEffect(() => {
        dispatch(getHideDepositDetails({
            urls: GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.LIST_OBJECTS,
            getResult: (hideDetails) => setHideDetails(hideDetails),
        }));
    }, []);

    useEffect(() => {
        clearSelectedRows();
    }, [
        statusFilter,
        archived,
    ]);

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const {
        onOpenAddDeposit,
        onOpenReturnDeposit,
        changeDepositData,
    } = useObjectsChangeDeposit();

    const {
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
    } = useLogHistory();

    const {
        editData,
        onOpenEdit,
    } = useObjectsEdit();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const updateHideDetails = (value) => {
        setHideDetails(value);

        dispatch(setHideDepositDetails({
            urls: SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.LIST_OBJECTS,
            value,
        }));
    };

    const _updateObjectStatus = (params) => {
        const {
            clientId,
            objectId,
            projectId,
            status,
        } = params;

        dispatch(updateClientObjectStatus({
            clientId,
            objectId,
            projectId,
            status,
        }));

        onCloseConfirm();
    };

    const _closeObject = (params) => {
        const {
            clientId,
            projectId,
            objectId,
        } = params;

        dispatch(closeClientObject({
            clientId,
            projectId,
            objectId,
        }));

        onCloseConfirm();
    };

    const archiveObject = ({objectId, name}) => {
        dispatch(changeArchiveObject({
            clientId,
            objectId,
            archived: !archived,
            onSuccess: () => {
                toastSuccess(
                    archived
                        ? `Объект ${name} успешно восстановлен из архива`
                        : `Объект ${name} успешно перенесен в архив`,
                );
                onCloseConfirm();
            },
        }));
    };

    const multipleArchiveObject = () => {
        const objectIds = selectedList.filter(item => item.isSelected).map(({objectId}) => objectId);

        dispatch(multipleChangeArchiveObject({
            clientId,
            objectIds,
            onSuccess: () => {
                toastSuccess("Запущен процесс массовой архивации объектов. Результат можно посмотреть в разделе \"Список задач\"");
                clearSelectedRows();
                onCloseConfirm();
            },
        }));
    };

    const onClickExportObjects = () => {
        dispatch(exportClientObjects({
            fromClientId: clientId,
            projectId,
            uploadType: UPLOAD_TYPE.PROJECT_EMBEDDED_OBJECTS_EXPORT_FOR_OPTIMUM.value,
            formatType: formatFileDict.XLSX,
            onSuccess: () => {
                toastSuccess(`Запущен экспорт объектов Проекта "${project.name}". Скачать файл по готовности можно в разделе Отчеты → Экспорт отчетов`);
            },
        }));
    };

    const getListMediaControls = (item) => {
        const {
            name,
            objectId,
            clientId,
            projectId,
            hasAccessToArchive,
            status,
            projectName,
            enableIndividualDeposit,
        } = item;

        const isAccessAction = ![FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);
        const isAccessEditActions = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editClientObject,
        ]);

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit({
                                open: true,
                                object: item,
                            });
                        },
                        children: "Редактировать объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived
                        && isAccessAction
                        && isAccessActionsClientObjects
                        && isAccessEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmTitle: `Вы действительно хотите закрыть объект "${name}"?`,
                                confirmText: t(
                                    enableIndividualDeposit
                                        ? "objects.close-individual-deposit-confirm-text"
                                        : "objects.close-confirm-text",
                                    {projectName},
                                ),
                                confirmWarning: true,
                                func: () => {
                                    _closeObject({
                                        objectId,
                                        clientId,
                                        projectId,
                                    });
                                },
                            });
                        },
                        children: "Закрыть объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived
                        && isAccessAction
                        && isAccessActionsClientObjects
                        && isAccessEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmText: t(OBJECT_ACTION_DICT.OPEN.CONFIRM_KEY, {name}),
                                func: () => {
                                    _updateObjectStatus({
                                        clientId,
                                        projectId,
                                        objectId,
                                        status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
                                    });
                                },
                            });
                        },
                        children: "Открыть объект",
                    },
                    visible: item.status === OBJECT_STATUS_DICT.CLOSE.VALUE
                        && item.projectStatus === PROJECT_STATUS_DICT.IN_WORK.VALUE
                        && !isClientArchived
                        && isAccessAction
                        && !archived
                        && isAccessActionsClientObjects
                        && isAccessEditActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenAddDeposit(item);
                        },
                        children: "Пополнить депозит объекта",
                    },
                    visible: [
                        ADMIN,
                        NM_MANAGER,
                        CLIENT_ADMIN,
                        CLIENT_ACCOUNTANT,
                    ].includes(role)
                        && Boolean(item.enableIndividualDeposit)
                        && !isClientArchived
                        && isAccessActionsClientObjects
                        && isAccessDepositActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenReturnDeposit(item);
                        },
                        children: "Вернуть депозит объекта",
                    },
                    visible: [
                        ADMIN,
                        NM_MANAGER,
                        CLIENT_ADMIN,
                        CLIENT_ACCOUNTANT,
                    ].includes(role)
                        && Boolean(item.enableIndividualDeposit)
                        && !isClientArchived
                        && isAccessActionsClientObjects
                        && isAccessDepositActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            toggleLogDepositHistoryModal({
                                name,
                                objectId,
                                clientId,
                            });
                        },
                        children: "История изменений депозита объекта",
                    },
                    visible: Boolean(item.enableIndividualDeposit)
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.getObjectsDepositHistory,
                        ]),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                item,
                                isOpenChangeOrdersLimit: true,
                            });
                        },
                        children: "Изменить лимит заказов на объекте",
                    },
                    visible: !archived
                        && !!item.enableIndividualDeposit
                        && isAccessByLimits
                    && [
                        ADMIN,
                        NM_MANAGER,
                        CLIENT_ADMIN,
                        CLIENT_ACCOUNTANT,
                    ].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenConfirm({
                                confirmWarning: archived,
                                confirmTitle: archived &&
                                    `Вы действительно хотите восстановить объект ${name}из архива?`,
                                confirmText: archived
                                    ? "В списковой форме объект отобразится в статусе \"закрыт\""
                                    : `Вы действительно хотите перенести объект ${name} в архив?`,
                                func: () => {
                                    archiveObject({
                                        objectId,
                                        name,
                                    });
                                },
                            });
                        },
                        children: archived ? "Из архива" : "В архив",
                    },
                    visible: hasAccessToArchive
                        && status === OBJECT_STATUS_DICT.CLOSE.VALUE,
                },
            ],
        };
    };

    const headerMediaControls = {
        renderCount: {
            desktop: 3,
            tablet: 3,
            mobile: 0,
        },
        size: "xl",
        buttons: [
            {
                isAdditional: true,
                component: COMPONENT.HORIZONTAL_TOGGLE,
                props: {
                    disabled: hideDepositDetailsProgress,
                    noWrapLabel: true,
                    leftLabel: "Скрыть детали",
                    onChange: () => {
                        updateHideDetails(!hideDetails);
                    },
                    checked: hideDetails,
                    single: true,
                    duplex: false,
                },
                asset: {
                    mobile: {children: hideDetails ? "Показать детали" : "Скрыть детали"},
                },
                visible: !projectId,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Добавить объект",
                    onClick: () => {
                        onOpenEdit({open: true, object: {}});
                    },
                    color: "green",
                    size: "xl",
                    icon: <AddIcon />,
                },
                visible: ![FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR, RECRUITER].includes(role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_addClientObject,
                    ])
                    && !(projectId && project.status === PROJECT_STATUS_DICT.CLOSE.VALUE)
                    && !isClientArchived
                    && !archived
                    && isAccessActionsClientObjects,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    ...archive.props,
                    onClick: () => {
                        setArchived(!archived);
                        setPagination({
                            pageSize,
                            pageNum: 1,
                        });
                    },
                },
                visible: statusFilter !== OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Пополнить депозит проектов и объектов из файла",
                    onClick: () => setOpenImportModal(true),
                },
                visible: [
                    ADMIN,
                    NM_MANAGER,
                    CLIENT_ADMIN,
                    CLIENT_ACCOUNTANT,
                ].includes(role)
                    && !projectId
                    && !archived
                    && Boolean(enableIndividualProjectAndObjectDeposit)
                    && isAccessActionsClientObjects
                    && isAccessDepositActions,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            isOpenChangeProjectsAndObjectsOrdersLimit: true,
                        });
                    },
                    children: "Изменить лимит заказов по проектам и объектам",
                },
                visible: !archived
                    && !projectId
                    && Boolean(enableIndividualProjectAndObjectDeposit)
                    && [
                        ADMIN,
                        NM_MANAGER,
                        CLIENT_ADMIN,
                        CLIENT_ACCOUNTANT,
                    ].includes(role)
                    && isAccessByLimits,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    children: "Выгрузить объекты",
                    onClick: onClickExportObjects,
                    color: "grey",
                    size: "xl",
                },
                visible: Boolean(projectId) && clientProperties.crowdTasksAvailable && !archived,
            },
        ],
    };

    const getStatusFilterTabs = () => {
        return ([
            {
                key: OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.IN_WORK.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.IN_WORK.VALUE,
            },
            {
                key: OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.CLOSE.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.CLOSE.VALUE,
            },
            {
                key: OBJECT_STATUS_FILTER_DICT.ALL.VALUE,
                name: OBJECT_STATUS_FILTER_DICT.ALL.TEXT,
                onClick: () => setStatusFilter(OBJECT_STATUS_FILTER_DICT.ALL.VALUE),
                active: statusFilter === OBJECT_STATUS_FILTER_DICT.ALL.VALUE,
            },
        ]);
    };

    return {
        onOpenAddDeposit,
        onOpenReturnDeposit,
        headerMediaControls,
        changeDepositData,
        isOpenConfirm,
        confirmData,
        onCloseConfirm,
        getListMediaControls,
        editData,
        onOpenEdit,
        hideDetails,
        getStatusFilterTabs,
        statusFilter,
        isOpenLogDepositHistoryModal,
        toggleLogDepositHistoryModal,
        logData,
        openImportModal,
        setOpenImportModal,
        archived,
        onOpenConfirm,
        multipleArchiveObject,
        modalData,
        onCloseModal,
    };
};