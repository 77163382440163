import React from "react";
import {useDispatch} from "react-redux";
import copy from "copy-to-clipboard";

import {ReactComponent as LinkIcon} from "../../../../images/link_24.svg";
import {ReactComponent as MailIcon} from "../../../../images/mail_24.svg";
import {ReactComponent as OkIcon} from "../../../../images/ok_24.svg";
import {ReactComponent as VkIcon} from "../../../../images/vk_24.svg";

import {OPEN_NAIMIX_CATALOG_ORDER, OPEN_NAIMIX_CATALOG_TASK} from "../../../../constants/links";

import {shortUrl} from "../../../../ducks/urlShortener";

interface IProps {
    clientId: string;
    orderId?: string;
    taskId?: string;
}

const winParams = `width=655, height=430, top=${(window.screen.height - 430) / 2}, left=${(window.screen.width - 655) / 2}, resizable=yes, scrollbars=no, status=yes`;
const okWinParams = `width=572, height=480, top=${(window.screen.height - 548) / 2}, left=${(window.screen.width - 588) / 2}, resizable=yes, scrollbars=no, status=yes`;

export const useShareButtonOptions = (props: IProps) => {
    const {
        clientId,
        orderId,
        taskId,
    } = props;

    const dispatch = useDispatch();

    const getShareLink = () => {
        if (orderId) {
            return OPEN_NAIMIX_CATALOG_ORDER
                .replace(":domain", process.env.REACT_APP_NAIMIX_OPEN_SITE_LINK as string)
                .replace(":clientId", clientId)
                .replace(":orderId", orderId);
        }

        if (taskId) {
            return OPEN_NAIMIX_CATALOG_TASK
                .replace(":domain", process.env.REACT_APP_NAIMIX_OPEN_SITE_LINK as string)
                .replace(":clientId", clientId)
                .replace(":taskId", taskId);
        }
    };

    const getOptions = () => {
        const shareLink = getShareLink();

        return [
            {
                key: "vk",
                text: "ВКонтакте",
                icon: <VkIcon />,
                onClick: () => window.open(`https://vk.com/share.php?url=${shareLink}`,"sharer", winParams),
            },
            {
                key: "email",
                icon: <MailIcon />,
                text: "Отправить по Email",
                onClick: () => window.open(`mailto:?body=${shareLink}`),
            },
            {
                key: "ok",
                icon: <OkIcon />,
                text: "Одноклассники",
                onClick: () => window.open(`https://connect.ok.ru/offer?url=${shareLink}`,"sharer", okWinParams),
            },
            {
                key: "copy",
                icon: <LinkIcon />,
                text: "Скопировать ссылку",
                onClick: () => {
                    dispatch(shortUrl({
                        orderId,
                        taskId,
                        originalUrl: shareLink,
                        callBack: copy,
                    }));
                },
            },
        ];
    };

    return getOptions();
};