import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {importObjectDeposit} from "../../../ducks/bff/clients/objects/actionCreators";
import {clientObjectsProgressActionSelector} from "../../../ducks/bff/clients/objects/selectors";
import {projectProgressSelector} from "../../../ducks/projects";

const AddProjectsObjectsDeposit = (props) => {
    const {
        clientId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const clientProperties = useSelector(clientCardPropertiesSelector);

    const progressImport = useSelector(projectProgressSelector);
    const progressDepositImport = useSelector(clientObjectsProgressActionSelector);

    const importNumbers = ({file}) => {
        const formData = new FormData();

        formData.append("mFile", file);

        dispatch(importObjectDeposit({
            clientId,
            formData,
            onSuccess: onClose,
        }));
    };

    return (
        <ImportFromFilePatternV2
            patternLink={
                clientProperties.enableIndividualProjectAndObjectDeposit
                    ? "/files/Список проектов и объектов для пополнения депозита.xlsx"
                    : "/files/Список объектов для пополнения депозита.xlsx"
            }
            onSubmit={importNumbers}
            onClose={onClose}
            progress={progressImport || progressDepositImport}
        />
    );
};

export default AddProjectsObjectsDeposit;