import React, {useState} from "react";

import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmRadioV2 from "../ActualComponents/NmRadioV2";
import Text from "../ActualComponents/Text";
import AmountInput from "../AmountInput";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {useChangeDepositForm} from "./hooks/useForm";

import {CHANGE_DEPOSIT_RADIO_TYPE} from "./constants";

const DepositChangeModal = (props) => {
    const {
        text,
        onClose,
        header,
        progress,
        confirmContent,
        amountLabel,
        targetText,
        targetName,
        isHideConfirm,
        isHideText,
        isHideDepositTypeBlock,
        isReturnDeposit,
        maxValueNpd,
        maxValueNdfl,
        onSubmit,
        lastPartConfirmText = "",
        minValue,
    } = props;

    const [openConfirm, setOpenConfirm] = useState(false);

    const {
        touched,
        values,
        errors,
        handleSubmit,
        setFieldValue,
        onSubmitWithoutValidation,
    } = useChangeDepositForm({
        onSubmit,
        isHideConfirm,
        setOpenConfirm,
        maxValueNpd,
        maxValueNdfl,
        minValue,
    });

    const renderConfirmModal = () => {
        return (
            <NmConfirmV2
                content={getConfirmContent()}
                onCancel={() => setOpenConfirm(false)}
                onConfirm={onSubmitWithoutValidation}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getConfirmContent = () => {
        if (confirmContent) {
            return confirmContent;
        }

        const actionText = isReturnDeposit
            ? "вернуть"
            : "пополнить";

        return "Вы действительно хотите :actionText депозит :targetText \":targetName\":lastPartConfirmText?"
            .replace(":lastPartConfirmText", lastPartConfirmText)
            .replace(":actionText", actionText)
            .replace(":targetText", targetText)
            .replace(":targetName", targetName);
    };

    const getAmountLabel = () => {
        if (amountLabel) {
            return amountLabel;
        }

        const actionText = isReturnDeposit
            ? "возврата"
            : "пополнения";

        return "Сумма :actionText депозита :targetText, ₽"
            .replace(":actionText", actionText)
            .replace(":targetText", targetText);
    };

    const getHeader = () => {
        if (header) {
            return header;
        }

        const actionText = isReturnDeposit
            ? "Возврат"
            : "Пополнение";

        return ":actionText депозита :targetText :targetName"
            .replace(":actionText", actionText)
            .replace(":targetText", targetText)
            .replace(":targetName", targetName);
    };

    const getText = () => {
        if (text) {
            return text;
        }

        const actionText = isReturnDeposit
            ? "Вернуть"
            : "Пополнить";

        return ":actionText депозит :targetText для выплат по счету"
            .replace(":actionText", actionText)
            .replace(":targetText", targetText);
    };

    if (openConfirm) {
        return renderConfirmModal();
    }

    return (
        <NmModal
            size="md"
            header={
                <NmTitle
                    noWrapToLines={true}
                    size="lg"
                >
                    {getHeader()}
                </NmTitle>
            }
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    onClose={onClose}
                    submit={handleSubmit}
                    isHiddenCancelOnMobile
                    cancelBtnContent="Отменить"
                    submitBtnContent="Сохранить"
                />
            }
            loading={progress}
        >
            <NmForm addMargin>
                {
                    !isHideText &&
                    <Text level="3">
                        {getText()}
                    </Text>
                }
                {
                    !isHideDepositTypeBlock &&
                    <div className="d-flex align-items-center">
                        <NmRadioV2
                            className="me-3"
                            checked={values.type === CHANGE_DEPOSIT_RADIO_TYPE.NPD}
                            label="НПД"
                            onChange={() => {
                                setFieldValue("type", CHANGE_DEPOSIT_RADIO_TYPE.NPD);
                            }}
                        />
                        <NmRadioV2
                            checked={values.type === CHANGE_DEPOSIT_RADIO_TYPE.NDFL}
                            label="НДФЛ"
                            onChange={() => {
                                setFieldValue("type", CHANGE_DEPOSIT_RADIO_TYPE.NDFL);
                            }}
                        />
                    </div>
                }
                <AmountInput
                    newInput
                    label={getAmountLabel()}
                    required
                    size="xl"
                    maxLength={50}
                    placeholder="Введите сумму"
                    value={values.amount}
                    onChange={(event, {value}) => {
                        setFieldValue("amount", value);
                    }}
                    error={touched && touched.amount && touched.amount ?
                        errors && errors.amount && errors.amount :
                        undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};

export default DepositChangeModal;