import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {
    addRegistryInvitations,
    updateRegistryInvitations,
} from "../../../../../../../ducks/bff/orders/order-invitations-registry/actionCreators";

const useDataForm = ({card, clientId, isEdit, fetchList, onClose}) => {
    const dispatch = useDispatch();

    const initialValues = {
        ...card,
    };

    const handleChange = (e, {name, value}) => setFieldValue(name, value);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const onSuccess = () => {
            fetchList();
            onClose();
        };

        if (isEdit) {
            dispatch(updateRegistryInvitations({
                clientId,
                registryInvitationId: card.registryInvitationId,
                ...values,
                onSuccess,
            }));

            return;
        }

        dispatch(addRegistryInvitations({
            clientId,
            ...values,
            onSuccess,
        }));
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
    };
};

export default useDataForm;