import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmRangeInput from "../../../components/ActualComponents/NmRangeInput";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../components/ApplyButtons";
import {ContractorsCountRefreshButton} from "../../../components/ContractorsCountRefreshButton";
import NmTitle from "../../../components/NmTitle";
import {AdvertisementDescriptionEditor} from "../card/components/description-editor";

import {SAVE_TYPE, useAdvertisementEditModal} from "./hooks/useAdvertisementEditModal";

import {dadataFromCityToApartmentFilter} from "../../../utils/dadata";

import {GENDER_OPTIONS_WITH_ALL_OPTION} from "../../../constants/contractorInfo";
import {ADVERTISEMENT_STATUS} from "../list/constants";

import {clientMemberOptionsSelector} from "../../../ducks/bff/clients/employees/selectors";
import {clientSpecialitiesOptionsSelector} from "../../../ducks/bff/clients/info/selectors";
import {getCitizenshipOptionsSelector} from "../../../ducks/contractor";

import "./style.sass";

function AdvertisementEditModal(props) {
    const {
        onClose,
        isEdit,
        fetchList,
        isClearCard,
        clientId,
        duplicate,
    } = props;

    const {
        values,
        handleSubmit,
        setFieldValue,
        handleChange,
        handleDownload,
        touched,
        handleBlur,
        handleAdvertisementContractorsCount,
        countContractors,
        errors,
        setFieldTouched,
        status,
        progressAdd,
        progressCountContractors,
        openWarning,
        setOpenWarning,
        publishAdvert,
        isAccessActionsMassiveAdvertisementClient,
    } = useAdvertisementEditModal({
        clientId,
        onClose,
        fetchList,
        isClearCard,
        duplicate,
        isEdit,
    });

    const getDraftButton = () => {
        if (!isEdit || status === ADVERTISEMENT_STATUS.DRAFT.VALUE) {
            return "Сохранить черновик";
        }

        return null;
    };

    const getSubmitButton = () => {
        if (
            (status === ADVERTISEMENT_STATUS.DRAFT.VALUE || !isEdit)
            && isAccessActionsMassiveAdvertisementClient
        ) {
            return {
                submitBtnContent: "Опубликовать",
                submit: () => {setFieldValue("type", SAVE_TYPE.PUBLISH);},
            };
        }

        if (
            isEdit
            && [ADVERTISEMENT_STATUS.PUBLISHED.VALUE, ADVERTISEMENT_STATUS.AWAIT_PUBLISH.VALUE].includes(status)
            && isAccessActionsMassiveAdvertisementClient
        ) {
            return {
                submitBtnContent: "Сохранить",
                submit: () => {setFieldValue("type", SAVE_TYPE.EDIT);},
            };
        }

        return {
            submitBtnContent: null,
        };
    };

    const onChangeDropzone = (files) => {
        if (!isEmpty(values.fileValue)) {
            setFieldValue("fileValue", null);
        }

        setFieldValue("file", files);
    };

    const {
        managerId,
        name,
        description,
        dateFrom,
        dateTo,
        publicationDateFrom,
        publicationDateTo,
        specialityId,
        ageFrom,
        ageTo,
        file,
        citizenships,
        gender,
        workAddressFiasValue,
    } = values;

    const {t} = useTranslation();

    const specialityOptions = useSelector(clientSpecialitiesOptionsSelector);
    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);
    const managerOptions = useSelector(clientMemberOptionsSelector);

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование объявления" : "Добавление объявления"}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    disabled={progressAdd}
                    onClickAdditional={onClose}
                    additionalBtnContent="Отменить"
                    isHiddenAdditionalOnMobile
                    formId="formAdvert"
                    isSubmitCancelButton
                    onClose={() => setFieldValue("type", SAVE_TYPE.DRAFT)}
                    cancelBtnContent={getDraftButton()}
                    {...getSubmitButton()}
                />
            }
        >
            {
                openWarning &&
                    <NmConfirmV2
                        onCancel={() => {setOpenWarning(false);}}
                        onConfirm={publishAdvert}
                        confirmButton="Да"
                        cancelButton="Отменить"
                        content="Под заданные фильтры не найдено ни одного исполнителя. Все равно создать объявление?"
                    />
            }
            <NmForm
                id="formAdvert"
                onSubmit={handleSubmit}
                addMargin
            >
                <NmDropdownV2
                    required
                    label="Ответственный"
                    name="managerId"
                    options={managerOptions}
                    value={managerId}
                    onBlur={() => {setFieldTouched("managerId", true);}}
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    error={touched && touched.managerId ?
                        errors && errors.managerId :
                        undefined
                    }
                />
                <NmTextareaV2
                    required
                    minRows={2}
                    label="Наименование объявления"
                    name="name"
                    maxLength={255}
                    onBlur={handleBlur}
                    value={name}
                    onChange={handleChange}
                    error={touched && touched.name ? errors && errors.name : undefined}
                />
                <AdvertisementDescriptionEditor
                    isVisibleTooltip={true}
                    value={description}
                    onChange={(_event, {value}) => {
                        setFieldValue("description", value);
                    }}
                    error={touched.description && errors.description}
                    maxLength={5000}
                />
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDateRangePickerV2
                            isClearable
                            size="xl"
                            label="Период в объявлении"
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            startFieldName="dateFrom"
                            endFieldName="dateTo"
                            value={{
                                dateFrom,
                                dateTo,
                            }}
                            onBlur={() => {
                                setFieldTouched("dateTo", true);
                                setFieldTouched("dateFrom", true);
                            }}
                            tooltip={
                                <HelpTooltip
                                    info
                                    width={24}
                                    height={24}
                                    hover
                                >
                                    Период, отображаемый на объявлении исполнителям в мобильном приложении
                                </HelpTooltip>
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 ml-0 ml-md-4">
                        <NmDateRangePickerV2
                            required
                            isClearable
                            size="xl"
                            label="Период публикации объявления"
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            startFieldName="publicationDateFrom"
                            endFieldName="publicationDateTo"
                            minDate={{
                                start: new Date(),
                                end: new Date(),
                            }}
                            // onBlur={() => {
                            //     setFieldTouched("publicationDateFrom", true);
                            //     setFieldTouched("publicationDateTo", true);
                            // }}
                            value={{
                                publicationDateFrom,
                                publicationDateTo,
                            }}
                            tooltip={
                                <HelpTooltip
                                    info
                                    width={24}
                                    height={24}
                                    hover
                                >
                                    Период, в течение которого объявление отображается исполнителям в мобильном приложении
                                </HelpTooltip>
                            }
                            error={
                                touched && (touched.publicationDateFrom || touched.publicationDateTo) ?
                                    errors.publicationDateFrom || errors.publicationDateTo :
                                    undefined
                            }
                        />
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmTitle size="lg">
                            Сегментация
                        </NmTitle>
                    </div>
                    <ContractorsCountRefreshButton
                        className="col-16 col-md-8"
                        count={countContractors}
                        onClick={handleAdvertisementContractorsCount}
                        loading={progressCountContractors}
                    />
                </div>
                <NmDropdownV2
                    required
                    name="specialityId"
                    label="Вид деятельности"
                    options={specialityOptions}
                    value={specialityId}
                    onBlur={() => {setFieldTouched("specialityId", true);}}
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    error={touched && touched.specialityId ?
                        errors && errors.specialityId :
                        undefined
                    }
                    search
                />
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmDadataInput
                            required
                            label="Город ведения деятельности"
                            filter={dadataFromCityToApartmentFilter}
                            query={workAddressFiasValue}
                            onChange={(value) => {
                                setFieldValue("workAddressFiasValue", value.value);

                                if (value.data && value.data.fias_id) {
                                    setFieldValue("workAddressFiasId", value.data.fias_id);
                                }
                            }}
                            onBlur={() => {setFieldTouched("workAddressFiasId", true);}}
                            size="xl"
                            error={touched && touched.workAddressFiasId ?
                                errors && errors.workAddressFiasId :
                                undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 ml-0 ml-md-4">
                        <NmDropdownV2
                            label="Гражданство"
                            name="citizenships"
                            options={citizenshipOptions}
                            value={citizenships}
                            onBlur={() => {setFieldTouched("citizenships", true);}}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                            multiple
                        />
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8 mb-3 mb-md-0">
                        <NmRangeInput
                            className="fluid"
                            label="Возраст"
                            nameStart="ageFrom"
                            nameEnd="ageTo"
                            maskStart="99"
                            maskEnd="99"
                            onBlur={handleBlur}
                            valueStart={ageFrom}
                            valueEnd={ageTo}
                            onChange={handleChange}
                            placeholderStart={t("component-labels.from")}
                            placeholderEnd={t("component-labels.to")}
                            error={
                                touched && (touched.ageTo || touched.ageFrom)?
                                    errors.ageTo || errors.ageFrom :
                                    undefined
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 ml-0 ml-md-4">
                        <NmDropdownV2
                            label="Пол"
                            name="gender"
                            options={GENDER_OPTIONS_WITH_ALL_OPTION}
                            value={gender}
                            onBlur={() => {setFieldTouched("gender", true);}}
                            onChange={(event, {name, value}) => setFieldValue(name, value)}
                        />
                    </div>
                </div>
                <DropzoneV2
                    multiple={false}
                    onChange={onChangeDropzone}
                    onDownload={handleDownload}
                    uploadedFileLabel="Файл для объявления:"
                    accept=".pdf, .doc, .docx, .xlsx"
                    format="*.pdf, *.doc, *.docx, *.xlsx"
                    maxSize={4}
                    isDownload={true}
                    isShowUploadedFileNameTitle={true}
                    files={file}
                    error={touched && touched.file ?
                        errors && errors.file :
                        undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
}

export default AdvertisementEditModal;