import {createSelector} from "reselect";

export const contractorBanksSelector = state => state.bff.contractorCardBanks;
export const getContractorBanksListSelector = createSelector(contractorBanksSelector, ({list}) => list);
export const getContractorBanksProgressSelector = createSelector(contractorBanksSelector, ({progress}) => progress);
export const getContractorBanksProgressActionSelector = createSelector(contractorBanksSelector, ({progressAction}) => progressAction);
export const contractorBanksSendingProgressForciblySelector = createSelector(contractorBanksSelector, ({progressForciblySendingToBank}) => progressForciblySendingToBank);
export const contractorCardLogsSelector = createSelector(
    contractorBanksSelector,
    ({identificationLogs}) => identificationLogs,
);
export const contractorBanksIdentificationLogsLogsProgressSelector = createSelector(
    contractorBanksSelector,
    ({identificationLogsProgress}) => identificationLogsProgress,
);