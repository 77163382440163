import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const clientObjectsSelector = state => state.bff.clientObjects;
export const clientObjectsListSelector = createSelector(clientObjectsSelector, ({list}) => list);
export const clientObjectsListPageDataSelector = createSelector(clientObjectsSelector, ({pageData}) => pageData);
export const clientObjectsTotalCountSelector = createSelector(clientObjectsSelector, ({totalCount}) => totalCount);
export const clientObjectsTotalPagesSelector = createSelector(clientObjectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const clientObjectsProgressSelector = createSelector(clientObjectsSelector, ({progress}) => progress);
export const clientObjectsProgressActionSelector = createSelector(clientObjectsSelector, ({progressAction}) => progressAction);
export const objectsDepositHistoryTotalCountSelector = createSelector(clientObjectsSelector, ({objectsDepositHistoryTotalCount}) => objectsDepositHistoryTotalCount);
export const objectsDepositHistoryTotalPagesSelector = createSelector(clientObjectsSelector, ({objectsDepositHistoryTotalCount: totalCount, objectsDepositHistoryPageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const objectsDepositHistoryPageSelector = createSelector(clientObjectsSelector, ({objectsDepositHistory}) => objectsDepositHistory);