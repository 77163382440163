import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {ObjectsDropdown} from "../../../../../components/ObjectsDropdown";
import {ProjectsDropdown} from "../../../../../components/ProjectsDropdown";

import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";

import {SimpleDropdownOptions} from "../../../../../constants/contractorList";

import {
    updateDeferredActsOfAcceptanceWorkCardStore,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";
import {
    getFinancesPaidApisSettings,
    updateFinancesPaidApisSettings,
} from "../../../../../ducks/bff/finances/paid-apis/actionCreators";
import {
    bffFinancesPaidApisErrorSelector,
    bffFinancesPaidApisProgressActionSelector,
    bffFinancesPaidApisProgressSettingsSelector,
    bffFinancesPaidApisSettingsInfoSelector,
} from "../../../../../ducks/bff/finances/paid-apis/selectors";

const PaidApisSettingsModal = (props) => {
    const {
        clientId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(bffFinancesPaidApisProgressSettingsSelector);
    const progressAction = useSelector(bffFinancesPaidApisProgressActionSelector);
    const data = useSelector(bffFinancesPaidApisSettingsInfoSelector);
    const error = useSelector(bffFinancesPaidApisErrorSelector);

    const initialValues = useMemo(() => {
        return {
            projectIdForPaidApis: null,
            objectIdForPaidApis: null,
            useClientDepositForPaidApis: false,
            ...data,
        };
    }, [data]);

    useEffect(() => {
        fetchInfo();
    }, []);

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateDeferredActsOfAcceptanceWorkCardStore({
                    error: null,
                }));
            }

            onClose();
        },
        progressAction,
        error,
    });

    const fetchInfo = () => {
        dispatch(getFinancesPaidApisSettings({
            clientId,
        }));
    };

    const onSubmit = () => {
        dispatch(updateFinancesPaidApisSettings({
            clientId,
            projectIdForPaidApis: values.projectIdForPaidApis,
            objectIdForPaidApis: values.objectIdForPaidApis,
            useClientDepositForPaidApis: values.useClientDepositForPaidApis,
        }));
    };

    const {
        values,
        handleSubmit,
        setFieldValue,
        setValues,
    } = useFormik({
        onSubmit,
        initialValues,
        enableReinitialize: true,
    });

    const onChange = (_event, {name, value, checked}) => {
        if (name === "useClientDepositForPaidApis" && value) {
            setValues(prevState => ({
                ...prevState,
                projectIdForPaidApis: null,
                objectIdForPaidApis: null,
                useClientDepositForPaidApis: true,
            }));

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Выберите проект или объект
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    cancelBtnContent="Отменить"
                    submitBtnContent="Сохранить"
                    onClose={onClose}
                    submit={handleSubmit}
                    loading={progressAction}
                    disabled={progressAction}
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                <Text level="3">
                    С индивидуального депозита проекта или объекта будут списаны средства за использование платных методов
                </Text>
                <ProjectsDropdown
                    disabled={values.useClientDepositForPaidApis}
                    label="Проект для списания"
                    placeholder="Не выбрано"
                    name="projectIdForPaidApis"
                    value={values.projectIdForPaidApis}
                    onChange={onChange}
                    filters={{
                        clientId,
                        enableIndividualDeposit: true,
                    }}
                />
                <ObjectsDropdown
                    disabled={values.useClientDepositForPaidApis}
                    label="Объект для списания"
                    placeholder="Не выбрано"
                    name="objectIdForPaidApis"
                    value={values.objectIdForPaidApis}
                    onChange={onChange}
                    filters={{
                        clientId,
                        enableIndividualDeposit: true,
                    }}
                />
                <NmDropdownV2
                    placeholder="Не выбрано"
                    label="Списывать из депозита компании"
                    name="useClientDepositForPaidApis"
                    onChange={onChange}
                    value={values.useClientDepositForPaidApis}
                    options={SimpleDropdownOptions}
                />
            </NmForm>
        </NmModal>
    );
};

export default PaidApisSettingsModal;