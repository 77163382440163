import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getDirectoriesStopWordsPage} from "./actionCreators";
import {
    ADD_DIRECTORIES_STOP_WORDS_ERROR,
    ADD_DIRECTORIES_STOP_WORDS_REQUEST,
    ADD_DIRECTORIES_STOP_WORDS_SUCCESS,
    DELETE_DIRECTORIES_STOP_WORDS_ERROR,
    DELETE_DIRECTORIES_STOP_WORDS_REQUEST,
    DELETE_DIRECTORIES_STOP_WORDS_SUCCESS,
    GET_PAGE_DIRECTORIES_STOP_WORDS_ERROR,
    GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST,
    GET_PAGE_DIRECTORIES_STOP_WORDS_SUCCESS,
    UPDATE_DIRECTORIES_STOP_WORDS_ERROR,
    UPDATE_DIRECTORIES_STOP_WORDS_REQUEST,
    UPDATE_DIRECTORIES_STOP_WORDS_SUCCESS,
} from "./actions";
import {settingsDirectoriesNaimixStopWordsPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";

const controller = "/adm/settings/directories/naimix/stop-words";

const fetchList = function* () {
    const state = yield select();
    const pageData = settingsDirectoriesNaimixStopWordsPageDataSelector(state);

    yield put(getDirectoriesStopWordsPage(pageData));
};

// POST /bff/adm/settings/directories/naimix/stop-words/page
// Получение списка данных стоп-слов
const getDirectoriesStopWordsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_DIRECTORIES_STOP_WORDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_DIRECTORIES_STOP_WORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_DIRECTORIES_STOP_WORDS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/settings/directories/naimix/stop-words/add
// Добавление данных стоп-слова
const addDirectoriesStopWordsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DIRECTORIES_STOP_WORDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield fetchList();
        toastSuccess(`Стоп-слово "${payload.value}" успешно добавлено`);

        yield put({
            type: ADD_DIRECTORIES_STOP_WORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DIRECTORIES_STOP_WORDS_ERROR,
            payload: error,
        });
    }
};

// PATCH /bff/adm/settings/directories/naimix/stop-words/{id}
// Обновление данных стоп-слова
const updateDirectoriesStopWordsSaga = function* ({payload}) {
    try {
        const {
            id,
            value,
        } = payload;

        const result = yield request.bff.patch(`${controller}/${id}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DIRECTORIES_STOP_WORDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield fetchList();
        toastSuccess("Изменения успешно сохранены");

        yield put({
            type: UPDATE_DIRECTORIES_STOP_WORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DIRECTORIES_STOP_WORDS_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/settings/directories/naimix/stop-words
// Удаление данных стоп-слова
const deleteDirectoriesStopWordsSaga = function* ({payload}) {
    try {
        const {id} = payload;

        const result = yield request.bff.delete(`${controller}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DIRECTORIES_STOP_WORDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield fetchList();
        toastSuccess("Стоп-слово успешно удалено");

        yield put({
            type: DELETE_DIRECTORIES_STOP_WORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DIRECTORIES_STOP_WORDS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_DIRECTORIES_STOP_WORDS_REQUEST, getDirectoriesStopWordsPageSaga),
        takeEvery(UPDATE_DIRECTORIES_STOP_WORDS_REQUEST, updateDirectoriesStopWordsSaga),
        takeEvery(DELETE_DIRECTORIES_STOP_WORDS_REQUEST, deleteDirectoriesStopWordsSaga),
        takeEvery(ADD_DIRECTORIES_STOP_WORDS_REQUEST, addDirectoriesStopWordsSaga),
    ]);
}