import {
    CLIENT_OBJECTS_ADD_ERROR,
    CLIENT_OBJECTS_ADD_REQUEST,
    CLIENT_OBJECTS_ADD_SUCCESS,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
    CLIENT_OBJECTS_CLEAR_STORE,
    CLIENT_OBJECTS_EXPORT_ERROR,
    CLIENT_OBJECTS_EXPORT_REQUEST,
    CLIENT_OBJECTS_EXPORT_SUCCESS,
    CLIENT_OBJECTS_GET_PAGE_ERROR,
    CLIENT_OBJECTS_GET_PAGE_REQUEST,
    CLIENT_OBJECTS_GET_PAGE_SUCCESS,
    CLIENT_OBJECTS_UPDATE_ERROR,
    CLIENT_OBJECTS_UPDATE_REQUEST,
    CLIENT_OBJECTS_UPDATE_STORE,
    CLIENT_OBJECTS_UPDATE_SUCCESS,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS,
    IMPORT_OBJECT_DEPOSIT_ERROR,
    IMPORT_OBJECT_DEPOSIT_REQUEST,
    IMPORT_OBJECT_DEPOSIT_SUCCESS,
    OBJECTS_CHANGE_ARCHIVE_ERROR,
    OBJECTS_CHANGE_ARCHIVE_REQUEST,
    OBJECTS_CHANGE_ARCHIVE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    objectsDepositHistory: [],
    objectsDepositHistoryTotalCount: 0,
    objectsDepositHistoryPageData: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_OBJECTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CLIENT_OBJECTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.objects,
                totalCount: payload.totalCount,
                progress: false,
            };
        case CLIENT_OBJECTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST:
        case CLIENT_OBJECTS_ADD_REQUEST:
        case IMPORT_OBJECT_DEPOSIT_REQUEST:
        case CLIENT_OBJECTS_UPDATE_REQUEST:
        case OBJECTS_CHANGE_ARCHIVE_REQUEST:
        case CLIENT_OBJECTS_EXPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS:
        case CLIENT_OBJECTS_ADD_SUCCESS:
        case IMPORT_OBJECT_DEPOSIT_SUCCESS:
        case CLIENT_OBJECTS_UPDATE_SUCCESS:
        case OBJECTS_CHANGE_ARCHIVE_SUCCESS:
        case CLIENT_OBJECTS_EXPORT_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR:
        case CLIENT_OBJECTS_ADD_ERROR:
        case IMPORT_OBJECT_DEPOSIT_ERROR:
        case CLIENT_OBJECTS_UPDATE_ERROR:
        case OBJECTS_CHANGE_ARCHIVE_ERROR:
        case CLIENT_OBJECTS_EXPORT_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST:
            return {
                ...state,
                objectsDepositHistoryPageData: {
                    pageNum: payload.pageNum,
                    pageSize: payload.pageSize,
                },
            };
        case GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS:
            return {
                ...state,
                objectsDepositHistory: payload.results,
                objectsDepositHistoryTotalCount: payload.totalCount,
            };
        case CLIENT_OBJECTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLIENT_OBJECTS_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};