import {useDispatch, useSelector} from "react-redux";

import {useModal} from "../../../../hooks/useModal";
import {useSuccessActionCallback} from "../../../../hooks/useSuccessAction";

import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS} from "../../../../constants/documentType";

import {submitAdditionalAgreements} from "../../../../ducks/bff/documents/additional-agreements/actionCreators";
import {
    archiveRegistryOfAdditionalAgreement,
    cancelAllAdditionalAgreements,
    getAdditionalAgreementWarnings,
    updateRegistryOfAdditionalAgreementStore,
} from "../../../../ducks/bff/documents/additional-agreements/registry/actionCreators";
import {
    bffAdditionalAgreementsRegistryErrorSelector,
    bffAdditionalAgreementsRegistryProgressActionSelector,
} from "../../../../ducks/bff/documents/additional-agreements/registry/selectors";

export const useAdditionalAgreementsAction = (params) => {
    const {
        isList = false,
        isCard = false,
        selectedIds,
    } = params;

    const dispatch = useDispatch();

    const error = useSelector(bffAdditionalAgreementsRegistryErrorSelector);
    const progressAction = useSelector(bffAdditionalAgreementsRegistryProgressActionSelector);

    const isAccessActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsRegisterAdditionalAgreements,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsRegisterAdditionalAgreements,
    ]);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateRegistryOfAdditionalAgreementStore({
                    error: null,
                }));
            }

            onCloseModal();
        },
        progressAction,
        error,
    });

    const getListMediaControls = (item) => {
        const isVisibleSignButton = item.totalItemsCount && !item.archived && item.status?.value === DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.CREATED;

        const upload = {
            component: COMPONENT.BUTTON,
            props: {
                size: "xl",
                children: "Выгрузить",
                onClick: () => {},
            },
        };
        const sendToSign = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите передать реестр на подписание?",
                            onConfirm: () => {
                                dispatch(getAdditionalAgreementWarnings({
                                    id: item.id,
                                    isSubmit: true,
                                    isFetchCard: isCard,
                                    isFetchList: isList,
                                    isSubmitRegistry: true,
                                }));
                            },
                        },
                    });
                },
                children: "Передать на подписание",
            },
            visible: isVisibleSignButton
                && isAccessActions,
        };
        const cancelUnfulfilled = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите отменить подписание и удалить невыполненные строки реестра?",
                            onConfirm: () => {
                                dispatch(cancelAllAdditionalAgreements({
                                    id: item.id,
                                    isFetchCard: isCard,
                                    isFetchList: isList,
                                }));
                            },
                        },
                    });
                },
                children: "Отменить невыполненные",
            },
            visible: item.declinedItemsCount
                && !item.archived
                && isAccessActions,
        };
        const repeatUnfulfilled = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите повторить невыполненные строки реестра?",
                            onConfirm: () => {
                                dispatch(getAdditionalAgreementWarnings({
                                    id: item.id,
                                    isResubmit: true,
                                    isFetchCard: isCard,
                                    isFetchList: isList,
                                }));
                            },
                        },
                    });
                },
                children: "Повторить невыполненные",
            },
            visible: item.declinedItemsCount
                && !item.archived
                && isAccessActions,
        };

        if (isList) {
            const edit = {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            isOpenEdit: true,
                            id: item.id,
                        });
                    },
                    color: "grey",
                    children: "Редактировать",
                },
                visible: !item.archived
                    && item.status?.value === DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.CREATED
                    && isAccessActions,
            };
            const archive = {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            confirmProps: {
                                content: item.archived
                                    ? `Вы действительно хотите восстановить из архива реестр ${item.name}?`
                                    : `Вы действительно хотите добавить в архив реестр ${item.name}?`,
                                onConfirm: () => {
                                    dispatch(archiveRegistryOfAdditionalAgreement({
                                        id: item.id,
                                        archived: item.archived,
                                    }));
                                },
                            },
                        });
                    },
                    children: item.archived ? "Из архива" : "Архивировать",
                },
                visible: [
                    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.CREATED,
                    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.DECLINED,
                    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS.FULLY_SIGNED,
                ].includes(item.status?.value),
            };

            return {
                renderCount: {
                    mobile: 0,
                    tablet: 0,
                    desktop: 0,
                },
                buttons: [
                    edit,
                    archive,
                    sendToSign,
                    cancelUnfulfilled,
                    repeatUnfulfilled,
                ],
            };
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                upload,
                sendToSign,
                cancelUnfulfilled,
                repeatUnfulfilled,
            ],
        };
    };

    const onClickSignRows = () => {
        onOpenModal({
            confirmProps: {
                content: "Вы действительно хотите передать реестры на подписание? " +
                    "В случае обнаружения в реестрах повторяющихся строк с одним и тем же исполнителей на " +
                    "один договор и версию персональных данных они будут удалены.",
                onConfirm: () => {
                    dispatch(submitAdditionalAgreements(selectedIds));
                },
            },
        });
    };

    return {
        onOpenModal,
        modalData,
        onCloseModal,
        getListMediaControls,
        onClickSignRows,
    };
};
