import React from "react";

import ExtLink from "../../ExtLink";

import {ls, USER_ROLE} from "../../../utils/localstorage";

import {LINK_CONTRACTOR_PROFILE} from "../../../constants/links";
import {RECRUITMENT_OBSERVER} from "../../../constants/roles";

export const RecruitmentResponseContractorAvailableIndicator = (props) => {
    const {
        contractorId,
        contractorName,
    } = props;

    if (!contractorId) {
        return "Нет";
    }

    const role = ls(USER_ROLE);

    if (role === RECRUITMENT_OBSERVER) {
        return contractorName;
    }

    const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

    return (
        <ExtLink
            historyEnabled
            to={link}
            title="Открыть карточку исполнителя"
        >
            {contractorName}
        </ExtLink>
    );
};