import {
    BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS,
    BFF_CONTRACTOR_READ_ERROR,
    BFF_CONTRACTOR_READ_REQUEST,
    BFF_CONTRACTOR_READ_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
    CONTRACTOR_ADD_FAVORITE_ERROR,
    CONTRACTOR_ADD_FAVORITE_REQUEST,
    CONTRACTOR_ADD_FAVORITE_SUCCESS,
    CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
    CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
    CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS,
    CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
    CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
    CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS,
    CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
    CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
    CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS,
    CONTRACTOR_SHORT_LIST_READ_ERROR,
    CONTRACTOR_SHORT_LIST_READ_REQUEST,
    CONTRACTOR_SHORT_LIST_READ_SUCCESS,
    ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
    ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST,
    ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS,
    UPDATE_CONTRACTOR_REGISTRY_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    secondTryingIndicator: false,
    recommendationModelType: "",
    shortPage: {
        list: [],
        totalCount: 0,
        pageData: {},
        progress: false,
        error: null,
    },
    card: {},
    progressCard: false,
    contractorList: [],
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS:
            const {
                contractors = [],
                totalCount = 0,
                secondTry = false,
                recommendationModelType,
            } = payload;

            return {
                ...state,
                progress: false,
                list: contractors,
                totalCount,
                secondTryingIndicator: secondTry,
                recommendationModelType,
            };
        case BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST:
        case BFF_CONTRACTOR_REGISTRY_ADD_REQUEST:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST:
        case CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST:
        case CONTRACTOR_ADD_FAVORITE_REQUEST:
        case CONTRACTOR_ADD_TO_BLACKLIST_REQUEST:
        case ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS:
        case BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS:
        case CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS:
        case CONTRACTOR_ADD_FAVORITE_SUCCESS:
        case CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS:
        case ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR:
        case BFF_CONTRACTOR_REGISTRY_ADD_ERROR:
        case CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR:
        case CONTRACTOR_DELETE_FROM_FAVORITE_ERROR:
        case CONTRACTOR_ADD_FAVORITE_ERROR:
        case CONTRACTOR_ADD_TO_BLACKLIST_ERROR:
        case ORDER_CONTRACTOR_ADD_SEVERAL_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case CONTRACTOR_SHORT_LIST_READ_REQUEST:
            return {
                ...state,
                shortPage: {
                    ...state.shortPage,
                    pageData: payload,
                    progress: true,
                },
            };
        case CONTRACTOR_SHORT_LIST_READ_SUCCESS:
            return {
                ...state,
                shortPage: {
                    ...state.shortPage,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case CONTRACTOR_SHORT_LIST_READ_ERROR:
            return {
                ...state,
                shortPage: {
                    ...state.shortPage,
                    error: payload,
                    progress: false,
                },
            };
        case BFF_CONTRACTOR_READ_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_CONTRACTOR_READ_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case BFF_CONTRACTOR_READ_ERROR:
            return {
                ...state,
                progressCard: false,
                error: payload,
            };
        case BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS:
            return {
                ...state,
                contractorList: payload,
            };
        case UPDATE_CONTRACTOR_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};