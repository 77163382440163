import {COMMON_ALL_OPTION} from "./common";

export const RECRUITMENT_SOCIAL_NETWORKS = {
    WHATSAPP: "WHATSAPP",
    TELEGRAM:  "TELEGRAM",
    VK: "VK",
    OK: "OK",
    SKYPE: "SKYPE",
    INSTAGRAM: "INSTAGRAM",
    FACEBOOK: "FACEBOOK",
    LINKEDIN: "LINKEDIN",
    YANDEX_ZEN: "YANDEX_ZEN",
    BEHANCE: "BEHANCE",
    TEN_CHAT: "TEN_CHAT",
};

export const RECRUITMENT_CONTACTS = {
    WHATSAPP: "WhatsApp",
    TELEGRAM: "Telegram",
};

export const RECRUITMENT_FUNNEL_STATUS_FILTER = {
    ALL_CANDIDATES: "all",
};

export const RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS_DICT = {
    ACTIVE: "Активный",
    NOT_ACTIVE: "Неактивный",
};

export const RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_STATUS = {
    ACTIVE: "ACTIVE",
    NOT_ACTIVE: "NOT_ACTIVE",
};

export const RECRUITMENT_JOB_BOARD_PUBLICATION_STATUS = {
    ERROR: "ERROR",
    PROCESS: "PROCESS",
    PUBLISHED: "PUBLISHED",
    ARCHIVED: "ARCHIVED", // Статус для закрытой вакансии
};

export const RECRUITMENT_JOB_BOARD_PUBLICATION_STATUS_DICT = {
    ERROR: "Ошибка публикации",
    PROCESS: "Ожидает публикации",
    PUBLISHED: "Опубликован",
};

export const RECRUITMENT_TEXT_NO_PARAM = "нет параметра:";

export const RECRUITMENT_KEDO_ERROR_CODE = {
    KEDO_STAFF_WITH_GIVEN_SNILS_EXISTS: "KEDO_STAFF_WITH_GIVEN_SNILS_EXISTS",
};

export const RECRUITMENT_REGISTRATION_STATUS_DICT = {
    BASIC_REGISTRATION: "Простая",
    FULL_REGISTRATION: "Полная",
};

export const RECRUITMENT_VACANCY_REGISTRATION_STATUS = {
    BASIC_REGISTRATION: "BASIC_REGISTRATION",
    FULL_REGISTRATION: "FULL_REGISTRATION",
};

export const RECRUITMENT_VACANCY_REGISTRATION_STATUS_OPTIONS = [
    COMMON_ALL_OPTION,
    {
        key: "no",
        value: [],
        text: "Нет",
    },
    {
        key: RECRUITMENT_VACANCY_REGISTRATION_STATUS.BASIC_REGISTRATION,
        value: RECRUITMENT_VACANCY_REGISTRATION_STATUS.BASIC_REGISTRATION,
        text: "Простая регистрация",
    },
    {
        key: RECRUITMENT_VACANCY_REGISTRATION_STATUS.FULL_REGISTRATION,
        value: RECRUITMENT_VACANCY_REGISTRATION_STATUS.FULL_REGISTRATION,
        text: "Проверка пройдена",
    },
];