import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    EDM_ADD_STAFF_ERROR,
    EDM_ADD_STAFF_REQUEST,
    EDM_ADD_STAFF_SUCCESS,
    EDM_ARCHIVE_STAFF_ERROR,
    EDM_ARCHIVE_STAFF_REQUEST,
    EDM_ARCHIVE_STAFF_SUCCESS,
    EDM_GET_STAFF_ERROR,
    EDM_GET_STAFF_LIST_ERROR,
    EDM_GET_STAFF_LIST_REQUEST,
    EDM_GET_STAFF_LIST_SUCCESS,
    EDM_GET_STAFF_REQUEST,
    EDM_GET_STAFF_SUCCESS,
    EDM_STAFF_EXPORT_ERROR,
    EDM_STAFF_EXPORT_REQUEST,
    EDM_STAFF_EXPORT_SUCCESS,
    EDM_STAFF_FIND_CONTRACTORS_ERROR,
    EDM_STAFF_FIND_CONTRACTORS_REQUEST,
    EDM_STAFF_FIND_CONTRACTORS_SUCCESS,
    EDM_STAFF_FIND_USERS_ERROR,
    EDM_STAFF_FIND_USERS_REQUEST,
    EDM_STAFF_FIND_USERS_SUCCESS,
    EDM_STAFF_IMPORT_REQUEST,
    EDM_STAFF_USER_EXIST_ERROR,
    EDM_STAFF_USER_EXIST_REQUEST,
    EDM_STAFF_USER_EXIST_SUCCESS,
    EDM_UPDATE_STAFF_REQUEST,
} from "./actions";

import request from "../../../../../utils/postman";
import {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

import {EDM_STAFF_LIST_REQUEST_TOAST} from "../../../../../constants/messages";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/staff",
        client: "/client-adm/edo/staff",
    });
};

const getEdmStaffByIdSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.bff.get(`${getController()}/${clientId}/${staffId}`);

        if (result.errorMessage) {
            yield put({
                type: EDM_GET_STAFF_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_GET_STAFF_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_GET_STAFF_ERROR,
            payload: error,
        });
    }
};

const getEdmStaffListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/staff/richPage`, payload);

        if (result.errorMessage) {
            yield put({
                type: EDM_GET_STAFF_LIST_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_GET_STAFF_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(EDM_STAFF_LIST_REQUEST_TOAST.ERROR);

        yield put({
            type: EDM_GET_STAFF_LIST_ERROR,
            payload: error,
        });
    }
};

const addEdmStaffSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);

        if (result.errorMessage) {
            yield put({
                type: EDM_ADD_STAFF_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_ADD_STAFF_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Сотрудник добавлен успешно");
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ADD_STAFF_ERROR,
            payload: error,
        });
    }
};

const updateEdmStaffSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.bff.patch(`${getController()}/${clientId}/${staffId}`, {
            ...data,
            staffId: undefined,
        });

        if (result.errorMessage) {
            yield put({
                type: EDM_ADD_STAFF_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_ADD_STAFF_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ADD_STAFF_ERROR,
            payload: error,
        });
    }
};

const findContractorsForEdmStaffSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/contractors/page`, data);

        if (result.errorMessage) {
            yield put({
                type: EDM_STAFF_FIND_CONTRACTORS_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_STAFF_FIND_CONTRACTORS_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_FIND_CONTRACTORS_ERROR,
            payload: error,
        });
    }
};

const findUsersForEdmStaffSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/users/page`, payload);

        if (result.errorMessage) {
            yield put({
                type: EDM_STAFF_FIND_USERS_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_STAFF_FIND_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_FIND_USERS_ERROR,
            payload: error,
        });
    }
};

const archiveEdmStaffSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            staffId,
        } = data;

        const result = yield request.bff.put(`${getController()}/${clientId}/${staffId}/archive`, data);

        if (result.errorMessage) {
            yield put({
                type: EDM_ARCHIVE_STAFF_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_ARCHIVE_STAFF_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_ARCHIVE_STAFF_ERROR,
            payload: error,
        });
    }
};

const importEdmStaffSaga = function* ({payload}) {
    try {
        const {
            data,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${getController()}/addImport`, data, {...getMultipartHeaders()});

        if (getResult) {
            getResult(result);
        }

        if (result.errorMessage) {
            yield put({
                type: EDM_ARCHIVE_STAFF_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_ARCHIVE_STAFF_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error(error);

        yield put({
            type: EDM_ARCHIVE_STAFF_ERROR,
            payload: error,
        });
    }
};

const edmStaffUserExistSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/common/info/staff/exists",
            client: "/client-adm/common/info/staff/exists",
        });

        const result = yield request.bff.get(url);

        if (result.errorMessage) {
            yield put({
                type: EDM_STAFF_USER_EXIST_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: EDM_STAFF_USER_EXIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: EDM_STAFF_USER_EXIST_ERROR,
            payload: error,
        });
    }
};

const exportEdmStaffUserExistSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/addExport`, data);
        const {
            errorMessage,
            error,
        } = result;

        if (errorMessage || error) {
            toastError(errorMessage || "Не удалось создать задачу");

            yield put({
                type: EDM_STAFF_EXPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Выгрузка успешно запущена и в ближайшее время станет доступна в разделе ЭДО → Экспорт");

        yield put({
            type: EDM_STAFF_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDM_STAFF_EXPORT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(EDM_GET_STAFF_REQUEST, getEdmStaffByIdSaga),
        takeEvery(EDM_GET_STAFF_LIST_REQUEST, getEdmStaffListSaga),
        takeEvery(EDM_ADD_STAFF_REQUEST, addEdmStaffSaga),
        takeEvery(EDM_UPDATE_STAFF_REQUEST, updateEdmStaffSaga),
        takeEvery(EDM_ARCHIVE_STAFF_REQUEST, archiveEdmStaffSaga),
        takeEvery(EDM_STAFF_FIND_CONTRACTORS_REQUEST, findContractorsForEdmStaffSaga),
        takeEvery(EDM_STAFF_FIND_USERS_REQUEST, findUsersForEdmStaffSaga),
        takeEvery(EDM_STAFF_IMPORT_REQUEST, importEdmStaffSaga),
        takeEvery(EDM_STAFF_USER_EXIST_REQUEST, edmStaffUserExistSaga),
        takeEvery(EDM_STAFF_EXPORT_REQUEST, exportEdmStaffUserExistSaga),
    ]);
}