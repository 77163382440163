import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearDeferredActsOfAcceptanceWorkCardStore,
    getDeferredActsOfAcceptanceWorkCard,
    getDeferredActsOfAcceptanceWorkCardItemsPage,
    getDeferredActsOfAcceptanceWorkCardItemsStatusDict,
} from "../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";

export const useDocumentsDeferredActsOfAcceptanceWorkCardFetch = (props) => {
    const {
        clientId,
        registryId,
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCard();

        dispatch(getDeferredActsOfAcceptanceWorkCardItemsStatusDict());

        return () => {
            dispatch(clearDeferredActsOfAcceptanceWorkCardStore());
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filter,
    ]);

    const fetchCard = () => {
        dispatch(getDeferredActsOfAcceptanceWorkCard({
            clientId,
            registryId,
        }));
    };

    const fetchList = () => {
        dispatch(getDeferredActsOfAcceptanceWorkCardItemsPage({
            clientId,
            registryId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchCard,
        fetchList,
    };
};