import {CONTRACTOR_FILE_TYPES} from "../../../../../../constants/clientList";

// Страницы, где может быть фунционал дозапроса ИНН
export const CONTRACTOR_VERIFICATION_DATA_FILE_TYPES_WITH_INN_CHECK = [
    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
    CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO, // ru
    CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION, // ru
];

// Страницы, где может быть фунционал дозапроса 19-й страницы
export const CONTRACTOR_VERIFICATION_DATA_FILE_TYPES_WITH_PAGE_NUMBER_19_CHECK = [
    CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO,
    CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION,
];

export const CONTRACTOR_VHI_POLICE_TYPE_DICT = {
    COMPULSORY: "Полис обязательного медицинского страхования (полис ОМС)",
    VOLUNTARY: "Полис добровольного медицинского страхования (полис ДМС)",
};