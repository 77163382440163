import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";

import {default as auth} from "../ducks/auth";
import {default as clientGroups} from "../ducks/clientGroups";
import {default as client} from "../ducks/client";
import {default as clientMember} from "../ducks/clientMember";
import {default as contractor} from "../ducks/contractor";
import {default as clientObject} from "../ducks/clientObject";
import {default as fileStore} from "../ducks/fileStore";
import {default as dict} from "../ducks/dict";
import {default as order} from "../ducks/order";
import {default as orderWork} from "../ducks/orderWork";
import {default as orderContractors} from "../ducks/orderContractors";
import {default as orderWorkReport} from "../ducks/orderWorkReport";
import {default as documents} from "../ducks/documents";
import {default as registration} from "../ducks/registration";
import {default as naimixInfo} from "../ducks/naimixInfo";
import {default as minCommission} from "../ducks/minCommissions";
import {default as clientFinanceUploadLog} from "../ducks/clientFinanceUpload";
import {default as deposit} from "../ducks/deposit";
import {default as feedback} from "../ducks/feedback";
import {default as federalMigration} from "../ducks/federalMigration";
import {default as promocode} from "../ducks/promocode";
import {default as address} from "../ducks/address";
import {default as projectParams} from "../ducks/projectParams";
import {default as patentsDeposit} from "../ducks/patentsDeposits";
import {default as patentsDocuments} from "../ducks/patentsDocuments";
import {default as passwordRecovery} from "../ducks/clientUsers";
import {default as messagesList} from "../ducks/chat";
import {default as contractorWorkLog} from "../ducks/contractorWorkLog";
import {default as clientProperties} from "../ducks/clientProperties";
import {default as contractorScan} from "../ducks/contractorScan";
import {default as disputeToken} from "../ducks/disputeFirebase";
import {default as contractorReceiptController} from "../ducks/contractorReceiptController";
import {default as logs} from "../ducks/logs";
import {default as chats} from "../ducks/chatList";
import {default as financeReport} from "../ducks/financeReport";
import {default as financeExport} from "../ducks/financeExport";
import {default as contractorOtherFiles} from "../ducks/contractorOtherFiles";
import {default as insurance} from "../ducks/insurance";
import {default as kedoStaff} from "../ducks/kedo/staff/reducer";
import {default as keyword} from "../ducks/keyword";
import {default as stopWords} from "../ducks/stopWords";
import {default as documentsExport} from "../ducks/documentsExport";
import {default as operatorReports} from "../ducks/operatorReports";
import {default as orderSubCategories} from  "../ducks/orderSubcategories";
import {default as projects} from  "../ducks/projects";
import {default as speciality} from  "../ducks/speciality";
import {default as objects} from  "../ducks/objects";
import {default as email} from  "../ducks/email";
import {default as registryPaymentStatuses} from "../ducks/registryPaymentStatuses";
import {default as contractorsSubscription} from "../ducks/contractorsSubscription";
import {default as job} from "../ducks/job";
import {default as ratings} from "../ducks/ratings";
import {default as sms} from "../ducks/sms";
import {default as bankDeposit} from "../ducks/bankDeposit";
import {default as workShedules} from "../ducks/contractorWorkSchedules";
import {default as contractorBankCheck} from "../ducks/contractorBankCheck";
import {default as dadata} from "../ducks/dadata";
import {default as clientSpecialityOffer} from "../ducks/clientSpecialityOffer";
import {default as urlShortener} from "../ducks/urlShortener";
import {default as clientRemoteContractors} from "../ducks/clientRemoteContractors";
import {default as contractorReceipt} from "../ducks/contractorReceiptController";
import {default as patentsDocumentsExport} from "../ducks/patentsDocumentsExport";
import {default as contractorPendingPersonalData} from "../ducks/contractorPendingPersonalData";
import {default as systemPropertyHistory} from "../ducks/systemPropertyHistory";
import {default as contractorEventLog} from "../ducks/contractorEventLog";
import {default as medicalBook} from "../ducks/medicalBook";
import {default as civil} from "../ducks/civil";
import {default as tariff} from "../ducks/tariff";
import {default as availableClientUsersAccess} from "../ducks/availableClientUsersAccess";
import {default as clientDadata} from "../ducks/clientDadata";
import {default as ticket} from "../ducks/crm/ticket";
import {default as contractorsPersonalDataAccess} from "../ducks/contractorsPersonalDataAccess";
import {default as crmSettings} from "../ducks/crmSettings";
import {default as crmRecording} from "../ducks/crm/recording";
import {default as orderWorkReportTemplate} from "../ducks/orderWorkReportTemplate";
import {default as contractorsDicts} from "../ducks/contractorsDicts";
import {default as contractorAdditionalDocuments} from "../ducks/contractorAdditionalDocuments";
import {default as strapi} from "../ducks/strapi";
import {default as contractorNotificationLog} from "../ducks/contractorNotificationLog";
import {default as settingsUsersRoles} from "../ducks/settingsUsersRoles";
import {default as clientUserRestrictions} from "../ducks/clientUserRestrictions";
import {default as onboarding} from "../ducks/onboarding";
import {default as onboardingRecruiters} from "../ducks/onboardingRecruiters";
import {default as onboardingContractors} from "../ducks/onboardingContractors";
import {default as mutualSettlementsReconciliationActs} from "../ducks/mutualSettlementsReconciliationActs";
import {default as contractorFinanceIncomeNpd} from "../ducks/contractorFinanceIncomeNpd";
import {default as contractorFinanceIncomeNdfl} from "../ducks/contractorFinanceIncomeNdfl";
import {default as contractorFinanceIncomeIndividual} from "../ducks/contractorFinanceIncomeIndividual";
import {default as bffRootReducer} from "../ducks/bff/reducer";
import {default as serverSentEvents} from "../ducks/serverSentEvents";
import {default as externalJobBoards} from "../ducks/externalJobBoards";
import {default as fileStoreRecruitment} from "../ducks/fileStoreRecruitment";
import {default as kedoDocuments} from "../ducks/kedo/documents/reducer";
import {default as kedoDirectories} from "../ducks/kedo/directories/reducer";
import {default as kedoDepartments} from "../ducks/kedo/departments/reducer";
import {default as task} from "../ducks/task/reducer";
import {default as kedoExports} from "../ducks/kedo/exports/reducer";
import {default as internalPayments} from "../ducks/internalPayments/reducer";
import {default as kedoTemplates} from "../ducks/kedo/templates/reducer";
import {default as clientBrands} from "../ducks/client/brands/reducer";
import {default as smartLink} from "../ducks/smart-link/reducer";
import {default as eventsContractor} from "../ducks/events/contractor/reducer";
import {default as financePayments} from "../ducks/financePayments/reducer";
import {default as contractorNoteForClient} from "../ducks/bff/contractor/note-for-client/reducer";
import {default as kedoDepartmentsStaff} from "../ducks/kedo/departments/staff/reducer"

export default (history) => combineReducers({
    router: connectRouter(history),
    bff: bffRootReducer(),
    auth,
    client,
    contractor,
    clientMember,
    clientObject,
    fileStore,
    dict,
    order,
    orderWork,
    orderContractors,
    orderWorkReport,
    documents,
    naimixInfo,
    clientFinanceUploadLog,
    deposit,
    feedback,
    registration,
    patentsDeposit,
    patentsDocuments,
    promocode,
    federalMigration,
    passwordRecovery,
    projectParams,
    messagesList,
    clientProperties,
    contractorWorkLog,
    contractorScan,
    disputeToken,
    contractorReceipt,
    contractorReceiptController,
    logs,
    chats,
    financeReport,
    financeExport,
    contractorOtherFiles,
    insurance,
    keyword,
    stopWords,
    documentsExport,
    operatorReports,
    minCommission,
    projects,
    contractorsSubscription,
    orderSubCategories,
    speciality,
    address,
    objects,
    email,
    registryPaymentStatuses,
    sms,
    job,
    ratings,
    workShedules,
    clientRemoteContractors,
    bankDeposit,
    contractorBankCheck,
    dadata,
    clientSpecialityOffer,
    urlShortener,
    patentsDocumentsExport,
    clientGroups,
    contractorPendingPersonalData,
    systemPropertyHistory,
    contractorEventLog,
    medicalBook,
    civil,
    tariff,
    availableClientUsersAccess,
    clientDadata,
    contractorsPersonalDataAccess,
    ticket,
    crmSettings,
    crmRecording,
    orderWorkReportTemplate,
    contractorsDicts,
    contractorAdditionalDocuments,
    strapi,
    contractorNotificationLog,
    settingsUsersRoles,
    clientUserRestrictions,
    onboarding,
    onboardingRecruiters,
    onboardingContractors,
    mutualSettlementsReconciliationActs,
    contractorFinanceIncomeNdfl,
    contractorFinanceIncomeIndividual,
    contractorFinanceIncomeNpd,
    serverSentEvents,
    externalJobBoards,
    fileStoreRecruitment,
    kedoDocuments,
    kedoDirectories,
    task,
    kedoExports,
    internalPayments,
    kedoTemplates,
    clientBrands,
    smartLink,
    kedoStaff,
    eventsContractor,
    financePayments,
    contractorNoteForClient,
    kedoDepartments,
    kedoDepartmentsStaff,
});