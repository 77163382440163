import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty, isEqual} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import Tabs from "../../../components/ActualComponents/Tabs";
import ButtonBack from "../../../components/ButtonBack";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import NmAccordion from "../../../components/NmAccordion";
import NmButton from "../../../components/NmButton";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import OrderSpecialityParagraph from "../../../components/OrderSpecialityParagraph";
import ShareSocial from "../../../components/ShareSocial";
import ViewsCount from "../../../components/ViewsCount";
import {ReactComponent as IconCheckbox} from "../../../images/check_box_24.svg";
import {ReactComponent as IconShare} from "../../../images/share_24.svg";
import OrderEdit from "../order-edit";
import OrderCreateSuccess from "../order-list/order-create-success";
import OrderTemplateInfo from "../template-info";
import OrderCardContractors from "./contractors";
import OrderCardDisputes from "./disputes";
import OrderCardHeaderLabelText from "./header-label-text";
import OrderCardHideResponses from "./hide-responses";
import OrderCardInfoPayout, {OrderCardInfoContractors, OrderCardInfoVolume} from "./info";
import {Dimmer, Loader} from "semantic-ui-react";

import dateFormat from "../../../utils/dateFormat";
import {CURRENT_CLIENT_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmountWithComma, getFormattedFullName, getFullName, phoneFormat} from "../../../utils/stringFormat";
import {getCheckText, getLinesByMediaQueries} from "./utils";

import {ORDER_FIELDS_NAME, ORDER_STATUS} from "../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {CHAT_LINK_PARAMS} from "../../../constants/disputes";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../constants/finance";
import {
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {ORDER} from "../../../constants/messages";
import {ORDER_KIND, ORDER_SOURCE_TYPES} from "../../../constants/order";
import {
    isUserFromNm,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    RNKO,
} from "../../../constants/roles";
import {ORDER_STATUS_DICT, STATUS_PAYMENT} from "../../../constants/status";

import {history} from "../../../store/configureStore";

import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    completeOrder,
    getOrderRichById,
    getPaidSumList,
    updateOrder,
    updateOrderArchive,
    updateOrderContractorsFound,
    updateStoreOrders,
} from "../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    getOrderWorkReportPaidSumListSelector,
    ordersRegistryCardProgressSelector,
    ordersRegistryCardSelector,
    ordersRegistryCreatedOrderInfoSelector,
} from "../../../ducks/bff/orders/orders-registry/selectors";
import {
    clientCurrentMemberIdSelector,
    clientCurrentMemberSelector,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";
import {contractorCountOrderTabSelector, updateFieldContractorStore} from "../../../ducks/contractor";
import {refreshOrderContractorsBankPossibility} from "../../../ducks/contractorBankCheck";
import {getOrderAttachmentsFile} from "../../../ducks/fileStore";
import {financeBackLinkSelector, updateFinanceStore} from "../../../ducks/financeExport";
import {getAllTasks, jobTasksContractorStatusProcessingSelector} from "../../../ducks/job";
import {
    unitsAbbreviationDictSelector,
} from "../../../ducks/order";
import {
    updateFieldOrderContractorStore,
} from "../../../ducks/orderContractors";
import {
    clearSubCategoriesStore,
    getSubCategoryById,
    subCategoriesCardSelector,
} from "../../../ducks/orderSubcategories";

import {orderContractorType, orderType, paidSumType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

import {DISPUTES, SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../constants/link-params";

class OrderCard extends Component {
    static propTypes = {
        order: orderType,
        list: PropTypes.arrayOf(orderContractorType),
        getContractorList: PropTypes.func,
        paidSumList: PropTypes.arrayOf(paidSumType),
        updateFieldOrderContractorStore: PropTypes.func,
        updateFieldClientMemberStore: PropTypes.func,
        updateStoreOrders: PropTypes.func,
        getPaidSumList: PropTypes.func,
    };

    static defaultProps = {
        order: {},
        list: [],
        getContractorList: () => {
        },
        paidSumList: [],
        updateFieldOrderContractorStore: () => {
        },
        updateFieldClientMemberStore: () => {
        },
        updateStoreOrders: () => {
        },
        getPaidSumList: () => {
        },
    };

    constructor(props) {
        super(props);

        this.descWorkRef = React.createRef();

        const {
            match: {
                params: {
                    clientId,
                    orderId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.orderId = orderId;
        this.taskId = `${this.clientId}:${this.orderId}`;
        this.role = ls(USER_ROLE);

        this.state = {
            pageSize: 25,
            pageNum: 1,
            pageSizes: [25, 50, 100],
            loading: true,
            isShowDescription: false,
            widthDescription: 0,
            widthName: 0,
            heightName: 0,
            description: "",
            isOpenModalForCreateDuplicate: false,
            isOpenTimeSheet: false,
            historyData: "",
            openConfirmWindow: false,
            action: {},
            isShowShareModal: false,
            documentFile: {},
            documentFileUrl: "",
            successCreate: false,
        };
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    get isApiOrRegistryOrderSourceType() {
        const {
            order: {
                orderSource,
            },
        } = this.props;

        return [ORDER_SOURCE_TYPES.API, ORDER_SOURCE_TYPES.REGISTRY].includes(orderSource);
    }

    get isAccessCardActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderCard,
        ]);
    }

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {extData} = state;

        if (extData) {
            const {pageData, prevPath, filterData} = extData;

            this.setState({
                historyData: {prevPath, pageData, filterData},
            });

            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, filterData} = state;

            this.setState({
                historyData: {prevPath, pageData, filterData},
            });
        }
    };

    componentDidMount() {
        this.saveHistoryData();

        this.fetchOrder();
        this.fetchPaidSum();
        this.refreshBankPossibility();
    }

    static getDerivedStateFromProps(props, state) {
        const {
            order,
            createdOrderInfo: {
                orderId: createdOrderId,
                status,
            },
            currentMember,
        } = props;
        const {
            description = "",
            orderSource,
            orderWorkDescription = "",
        } = order;
        const {
            description: oldDescription,
            createdOrderId: _createdOrderId,
        } = state;

        if (oldDescription === "") {
            return {
                ...state,
                description: orderSource === ORDER_SOURCE_TYPES.REGISTRY ? orderWorkDescription : description,
            };
        }

        if (
            !currentMember.contractorInvitationProhibited
            && createdOrderId
            && _createdOrderId !== createdOrderId
            && ![ORDER_STATUS.DRAFT, ORDER_STATUS.PUBLISHED].includes(status)
        ) {
            return {
                ...state,
                createdOrderId,
                successCreate: true,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        const {
            order,
        } = this.props;

        if (!isEqual(order, prevProps.order)) {
            this.setState({
                contractorsFound: order.contractorsFound,
            });
        }

        if (order.orderId && order.orderId !== prevProps.order.orderId) {
            this.getSubCategory();
        }
    }

    componentWillUnmount() {
        const {
            updateFieldOrderContractorStore,
            updateStoreOrders,
            updateFieldClientMemberStore,
            updateFieldContractorStore,
            clearSubCategoriesStore,
        } = this.props;

        updateFieldContractorStore({contractorsByOrder: [], pageDataContractorsByOrder: {}, applicationCountMap: {}});
        updateFieldOrderContractorStore("performers", []);
        updateFieldOrderContractorStore("pageDataPerformers", {});
        updateFieldOrderContractorStore("list", []);
        updateStoreOrders({card: {}});
        updateFieldClientMemberStore({card: {}});
        clearSubCategoriesStore({subCategoryCard: {}});
    }

    getAttachment = ({attachmentFileName, orderId}) => {
        if (attachmentFileName) {
            const {getOrderAttachmentsFile} = this.props;

            getOrderAttachmentsFile({
                orderId,
                fileName: attachmentFileName,
                clientId: this.clientId,
                setFile: this.setFile,
            });
        }
    };

    refreshBankPossibility = () => {
        const {match: {params: {orderId}}} = this.props;
        const {refreshOrderContractorsBankPossibility} = this.props;

        refreshOrderContractorsBankPossibility({
            orderId,
            clientId: this.clientId,
        });
    };

    getAccessOption = (option) => {
        return ![NM_COORDINATOR, NM_CONSULTANT, RNKO, NM_OPERATOR, NM_CHIEF_ACCOUNTANT].includes(this.role) ? option : null;
    };

    get contentByStatus() {
        const {
            order: {
                archived,
                orderNum,
            },
            t,
        } = this.props;

        const isAccessEditPublishCloseOrder = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editPublishCloseOrder,
        ]);
        const isAccessCreateDuplicateOrder = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.createDuplicateOrder,
        ]);

        const option = {
            createDuplicate: {
                text: "Создать дубликат",
                key: "create-duplicate",
                value: "create-duplicate",
            },
            cancelOrder: {
                text: "Завершить заказ",
                key: "cancel-order",
                value: "cancel-order",
            },
            archived: {
                text: archived ? "Восстановить из архива" : "Добавить в архив",
                key: "archived",
                value: "archived",
            },
        };

        const endOrderAction = {
            isVisibleAction: ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR].includes(this.role)
                && isAccessEditPublishCloseOrder,
            actionText: "Завершить заказ",
            action: () => {
                this.toggleConfirmWindow(true, {
                    confirmTitle: t("order-list.titleEndOrder", {nums: orderNum}),
                    confirmText: t("order-list.closeOneOrderMsg"),
                    warning: true,
                    onConfirm: this.closeOrder,
                });
            },
        };

        if (this.isApiOrRegistryOrderSourceType) {
            return {
                [ORDER_STATUS.CONTRACTOR_SEARCH]: endOrderAction,
                [ORDER_STATUS.PUBLISHED]: endOrderAction,
            };
        }

        return {
            [ORDER_STATUS.CONTRACTOR_SEARCH]: {
                isVisibleAction: ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)
                    && isAccessEditPublishCloseOrder
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.publishOrder,
                        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_publishOrders,
                    ]),
                actionText: "Завершить подбор",
                action: () => {
                    this.toggleConfirmWindow(true, {
                        confirmText: t("order-list.complete-selection-confirm-text"),
                        onConfirm: this.completeSelection,
                    });
                },
                options: [
                    (
                        !this.isClientArchived
                        && isAccessCreateDuplicateOrder
                    ) && this.getAccessOption(option["createDuplicate"]),
                    isAccessEditPublishCloseOrder && this.getAccessOption(option["cancelOrder"]),
                ].filter(Boolean),
            },
            [ORDER_STATUS.PUBLISHED]: {
                ...endOrderAction,
                options: [
                    (
                        !this.isClientArchived
                        && isAccessCreateDuplicateOrder
                    ) && this.getAccessOption(option["createDuplicate"]),
                ].filter(Boolean),
            },
            [ORDER_STATUS.CLOSED]: {
                options: [
                    (
                        !this.isClientArchived
                        && isAccessCreateDuplicateOrder
                    ) && this.getAccessOption(option["createDuplicate"]),
                    !this.isClientArchived && this.getAccessOption(option["archived"]),
                ].filter(Boolean),
            },
        };
    }

    getSubCategory = () => {
        const {
            getSubCategoryById,
            order,
        } = this.props;

        if (order[ORDER_FIELDS_NAME.ORDER_SUB_CATEGORY_ID]) {
            getSubCategoryById(order[ORDER_FIELDS_NAME.ORDER_SUB_CATEGORY_ID]);
        }
    };

    fetchPaidSum = () => {
        const {getPaidSumList, match} = this.props;
        const {params} = match;
        const {orderId} = params;

        getPaidSumList({
            clientId: this.clientId,
            ordersId: [
                orderId,
            ],
            statusFilter: [
                STATUS_PAYMENT.PAYMENT_COMPLETED,
            ],
        });
    };

    fetchOrder = () => {
        const {getOrderRichById, match} = this.props;
        const {params} = match;
        const {orderId} = params;

        getOrderRichById({
            orderId,
            clientId: this.clientId || ls(CURRENT_CLIENT_ID),
            callBack: this.getAttachment,
        });
    };

    setFile = ({documentFile, documentFileUrl}) => {
        this.setState({
            documentFile,
            documentFileUrl,
        });
    };

    handleOnClickBack = () => {
        const {backLink, updateFinanceStore} = this.props;
        const {historyData} = this.state;
        const {prevPath, ...otherData} = historyData;

        if (prevPath) {
            history.push(prevPath, otherData);

            return;
        }

        if (backLink) {
            history.push(backLink);

            updateFinanceStore({backLink: ""});

            return;
        }

        const linkToList = LINK_CLIENT_ORDER_LIST.replace(":clientId", this.clientId);

        history.push(linkToList);
    };

    completeSelection = () => {
        const {
            order,
            updateOrder,
            currentMember: {
                clientUserId,
                clientId,
            },
            getAllTasks,
        } = this.props;

        updateOrder({
            ...order,
            contractorsFound: true,
            [ORDER_FIELDS_NAME.STATUS]: ORDER_STATUS.PUBLISHED,
            successToast: ORDER.ORDER_UPDATE_SUCCESS_TEXT,
            onSuccess: ({jobId}) => {
                if (!jobId) {
                    this.fetchOrder();

                    return;
                }

                getAllTasks({
                    clientId,
                    clientUserId,
                });
            },
        });

        this.toggleConfirmWindow(false);
    };

    renderStatusBlock() {
        const {order} = this.props;
        const {
            status,
        } = order;

        return status ?
            <span
                className="order-card__status"
                style={{
                    color: ORDER_STATUS_DICT[status].COLOR,
                    background: ORDER_STATUS_DICT[status].BACKGROUND,
                }}
            >
                {ORDER_STATUS_DICT[status].TEXT}
            </span> :
            null;
    }

    toggleDesc = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowDescription: !prevState.isShowDescription,
        }));
    };

    mapKeywords(keywords = []) {
        if (isEmpty(keywords)) {
            return null;
        }

        return (
            <div className="order-card__keywords">
                {
                    keywords.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="order-card__keywords-item"
                            >
                                {item.keyword}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    renderAdditionalTerms(text) {
        if (isEmpty(text)) {
            return null;
        }

        return (
            <NmShowMoreText
                lines={1}
                more="Подробнее"
                anchor="green"
                type="page"
            >
                {text}
            </NmShowMoreText>
        );
    }

    renderGeneralDescription() {
        const {
            description,
        } = this.state;
        const {
            subCategory: {
                value: subCategoryValue,
                categories = [{}],
            },
            order,
        } = this.props;
        const {name, keywords} = order;

        const [category] = categories;
        const {value: categoryValue} = category;

        return (
            <div className="order-card__description-general col-md-16 col-xl-8 col-xxl-6">
                <div className="order-card__name">
                    {name}
                </div>
                <div className="order-card__description">
                    <Media
                        queries={{
                            mobile: {maxWidth: 767},
                            tablet: {minWidth: 768, maxWidth: 1199},
                        }}
                    >
                        {
                            (matches) => (
                                <NmShowMoreText
                                    anchor="green"
                                    title="Описание услуг (работ)"
                                    lines={getLinesByMediaQueries(matches)}
                                    children={description}
                                    more="Читать полностью"
                                />
                            )
                        }
                    </Media>
                </div>
                {
                    Boolean(categoryValue) &&
                    <div className="order-card__category">
                        {categoryValue || "--"}
                        {" "}
                        /
                        {subCategoryValue || "--"}
                    </div>
                }
                {this.mapKeywords(keywords)}
            </div>
        );
    }

    getProjectLink = ({clientId, projectId, projectName}) => {
        if (!projectId) {
            return "-";
        }

        const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST.replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <Link to={projectLink}>
                {projectName}
            </Link>
        );
    };

    getObjectLink = ({clientId, projectId, objectId, objectName}) => {
        if (!objectId) {
            return "-";
        }

        const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST.replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <Link
                className="me-2"
                to={objectLink}
            >
                {objectName}
            </Link>
        );
    };

    get infoFields() {
        const {
            order,
            t,
        } = this.props;

        const {
            additionalTerms,
            workStartDate,
            workEndDate,
            checkInRequired,
            checkOutRequired,
            checkOutPhotoRequired,
            checkInPhotoRequired,
            orderManagerPhone,
            orderManagerPhoneVisible,
            checkInFrom,
            checkInTo,
            checkOutFrom,
            checkOutTo,
            orderManagerLastName,
            orderManagerName,
            orderManagerPatronymic,
            orderSource,
            orderContractPaymentType,
            registryOrdinalNumber,
            registryName,
            registrySeqNum,
            contractorRecruitmentRequired,
            metroInfo,
            outTime,
            timeForExecutionHours,
            frameContractByObjectTemplate,
        } = order;

        const contractTypeText = ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType];

        return [
            {
                name: "Период выполнения работ:",
                value: `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`,
                renderCondition: true,
            },
            {
                name: "Время выхода:",
                value: outTime || "-",
                renderCondition: true,
            },
            {
                name: "Длительность заказа:",
                value: timeForExecutionHours || "-",
                renderCondition: true,
            },
            {
                name: "Проект:",
                value: this.getProjectLink(order),
                renderCondition: true,
            },
            {
                name: `${t("order-new.object")}:`,
                value: this.getObjectLink(order),
                renderCondition: true,
            },
            {
                name: `Заключать отдельный договор ${contractTypeText} на объекте:`,
                value: frameContractByObjectTemplate ? "Да" : "Нет",
                renderCondition: Boolean(contractTypeText),
            },
            {
                name: "Ближайшие станции метро:",
                value: metroInfo,
                renderCondition: metroInfo,
            },
            {
                name: "Требовать отметку о прибытии:",
                value: getCheckText(checkInRequired, checkInPhotoRequired, checkInFrom, checkInTo),
                renderCondition: !this.isApiOrRegistryOrderSourceType,
            },
            {
                name: "Требовать отметку об уходе:",
                value: getCheckText(checkOutRequired, checkOutPhotoRequired, checkOutFrom, checkOutTo),
                renderCondition: !this.isApiOrRegistryOrderSourceType,
            },
            {
                name: "Дополнительные условия:",
                value: this.renderAdditionalTerms(additionalTerms),
                renderCondition: additionalTerms,
                noWrap: true,
            },
            {
                name: "Реестр оплат:",
                value: `№${registryOrdinalNumber} ${registryName} (строка №${registrySeqNum})`,
                renderCondition: orderSource === ORDER_SOURCE_TYPES.REGISTRY,
            },
            {
                name: `${t("order-new.responsible")}:`,
                value: getFormattedFullName(getFullName(orderManagerLastName, orderManagerName, orderManagerPatronymic))
                    || t("not-assigned.content"),
                renderCondition: orderSource !== ORDER_SOURCE_TYPES.API,
            },
            {
                name: "Договор:",
                value: contractTypeText,
                renderCondition: true,
            },
            {
                name: "Номер телефона:",
                value: phoneFormat(orderManagerPhone),
                renderCondition: orderManagerPhoneVisible,
            },
            {
                name: "Требуется подбор исполнителя:",
                value: contractorRecruitmentRequired ? "Да" : "Нет",
                renderCondition: true,
            },
            {
                name: "Шаблон договора:",
                value: (
                    <OrderTemplateInfo
                        id={order.frameContractTemplateId}
                        linkName={order.frameContractTemplateName}
                        link={order.frameContractTemplatePdfDownloadLink}
                    />
                ),
                noWrap: true,
                renderCondition: true,
            },
            {
                name: "Шаблон заявки:",
                value: (
                    <OrderTemplateInfo
                        id={order.orderApplicationTemplateId}
                        linkName={order.orderApplicationTemplateName}
                        link={order.orderApplicationTemplatePdfDownloadLink}
                    />
                ),
                noWrap: true,
                renderCondition: true,
            },
            {
                name: "Шаблон акта:",
                value: (
                    <OrderTemplateInfo
                        id={order.actOfAcceptanceOfWorkTemplateId}
                        linkName={order.actOfAcceptanceOfWorkTemplateName}
                        link={order.actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                    />
                ),
                noWrap: true,
                renderCondition: true,
            },
        ].filter(({renderCondition}) => Boolean(renderCondition));
    }

    renderSubDescription() {
        const {
            order: {
                attachmentFileExists,
                speciality,
                clientSpecialityOffer,
                specialityId,
            },
        } = this.props;
        const {documentFile: {name}, documentFileUrl} = this.state;

        return (
            <div className="order-card__description-sub col-md-16 col-xl-8 col-xxl-5">
                {
                    !this.isApiOrRegistryOrderSourceType &&
                    <OrderSpecialityParagraph
                        show={clientSpecialityOffer || specialityId}
                        isValueTitle={false}
                        className="order-card__speciality"
                        speciality={{speciality, clientSpecialityOffer}}
                    />
                }
                {
                    this.infoFields.map(({name, value, noWrap}, index) => {
                        return (
                            <OrderCardHeaderLabelText
                                noWrap={noWrap}
                                className="order-card__label"
                                key={index}
                                name={name}
                                value={value}
                            />
                        );
                    })
                }
                {
                    attachmentFileExists &&
                    <OrderCardHeaderLabelText
                        name="Документация к заказу:"
                        className="order-card__label order-card__documentation"
                        customValue={
                            <a
                                className="order-card__documentation-link"
                                title={name}
                                download={name}
                                href={documentFileUrl}
                            >
                                {name}
                            </a>
                        }
                    />
                }
            </div>
        );
    }

    renderMoneyDescription = (isMobileTablet) => {
        const {order, paidSumList} = this.props;
        const {
            amount,
            contractorsNeededCount,
            awaitingConfirmationTotalAmount,
            notConfirmedTotalAmount,
            inProgressTotalAmount,
            contractorCommission,
        } = order;
        const [paidSum = {}] = paidSumList;

        return (
            <OrderCardInfoPayout
                className="order-card__payout-info"
                paid={paidSum.sum}
                amount={amount}
                contractorCommission={contractorCommission}
                neededCount={contractorsNeededCount}
                awaitingConfirmationTotalAmount={awaitingConfirmationTotalAmount}
                notConfirmedTotalAmount={notConfirmedTotalAmount}
                inProgressTotalAmount={inProgressTotalAmount}
                children={
                    isMobileTablet && this.renderVolumePlan()
                }
            />
        );
    };

    renderVolumePlan() {
        const {order, workUnitsDict} = this.props;
        const {
            orderKind,
            hiredCount = "",
            workUnitPrice,
            workUnit,
            orderVolumePaid = "",
            volumeOfWork,
        } = order;

        const isSecondTypeOrder = orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER;
        const unit = workUnitsDict.get(workUnit);

        const volume = isSecondTypeOrder ? `${orderVolumePaid} / ${volumeOfWork} ${unit}` : `${hiredCount} услуг`;
        const volumePerOne = isSecondTypeOrder ? `${formatAmountWithComma(workUnitPrice)} ₽/${unit}` : "1 услуга на исполнителя";

        return (
            <OrderCardInfoVolume
                volume={volume || ""}
                subVolume={volumePerOne || ""}
            />
        );
    }

    renderContractorsCount() {
        const {order} = this.props;
        const {
            contractorsNeededCount,
            hiredCount,
        } = order;

        return (
            <OrderCardInfoContractors
                className="order-card__info-contractors"
                hiredCount={hiredCount}
                contractorsCount={contractorsNeededCount}
            />
        );
    }

    openModalForCreateDuplicate = () => {
        this.setState({isOpenModalForCreateDuplicate: true});
    };

    closeModalForCreateDuplicate = () => {
        this.setState({isOpenModalForCreateDuplicate: false});
    };

    closeOrder = () => {
        const {
            completeOrder, order: {
                orderId,
                clientId,
            },
            getAllTasks,
            currentMember: {
                clientUserId,
            },
        } = this.props;

        completeOrder({
            orderId,
            clientId,
            handleResponse: ({jobId}) => {
                if (!jobId) {
                    this.fetchOrder();

                    return;
                }

                getAllTasks({
                    clientId,
                    clientUserId,
                });
            },
        });

        this.toggleConfirmWindow(false);
    };

    updateArchive = () => {
        const {
            updateOrderArchive,
            order: {
                clientId,
                orderId,
                projectId,
                archived,
            },
        } = this.props;

        updateOrderArchive(
            {
                orderId,
                clientId,
                projectId,
                isArchive: !archived,
                //Флаги для начитки списка или карточки после завершения запроса
                isFetchList: false,
                isFetchCard: true,
            },
        );
        this.toggleConfirmWindow(false);
    };

    onClickActionItem(option) {
        const {value: action} = option;
        const {
            order: {
                name,
                archived,
                orderNum,
                status,
            },
            t,
        } = this.props;

        switch (action) {
            //Создать дубликат
            case "create-duplicate": {
                this.openModalForCreateDuplicate();
                return;
            }
            //Завершить заказ
            case "cancel-order": {
                const action = {
                    confirmTitle: t("order-list.titleEndOrder", {nums: orderNum}),
                    confirmText: t("order-list.messageEndOrder"),
                    onConfirm: this.closeOrder,
                };

                this.toggleConfirmWindow(true, action);
                return;
            }
            //Добавить в архив заказ или восстановить
            case "archived": {
                const action = {
                    confirmText: archived ? `Вы действительно хотите восстановить заказ "${name}" из архива?` : `Вы действительно хотите добавить заказ "${name}" в архив?`,
                    onConfirm: this.updateArchive,
                };

                this.toggleConfirmWindow(true, action);

                return;
            }
            // Поделиться (в адаптиве)
            case "share":
                this.toggleShareModal();

                return;
            // Скрыть отклики (в адаптиве)
            case "hideResponse":
                this.hideResponses();

                return;
            // Завершить подбор/Завершить заказ (в адаптиве)
            case "end-action":
                const {
                    action,
                } = this.contentByStatus[status] || {};

                action();

                return;
            default:
                console.error("Недопустимое действие");

                return;
        }
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            action: {
                confirmTitle,
                confirmText,
                onConfirm,
                warning,
            },
        } = this.state;

        const {t} = this.props;

        return openConfirmWindow &&
            <NmConfirmV2
                warning={warning}
                onCancel={() => this.toggleConfirmWindow(false)}
                onConfirm={onConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                content={confirmText}
                title={confirmTitle}
                size="md"
            />;
    }

    toggleConfirmWindow = (openConfirmWindow, action = {}) => {
        this.setState({
            openConfirmWindow,
            action,
        });
    };

    toggleShareModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowShareModal: !prevState.isShowShareModal,
        }));
    };

    renderSharePopup() {
        const {isShowShareModal} = this.state;

        return isShowShareModal &&
            <ShareSocial
                clientId={this.clientId}
                orderId={this.orderId}
                isOrderCard={true}
                toggle={this.toggleShareModal}
            />;
    }

    get tabletOptions() {
        const {
            contractorsFound,
        } = this.state;

        if (this.isApiOrRegistryOrderSourceType) {
            return [];
        }

        console.log("contractorsFound", contractorsFound);

        return [
            {
                key: "share",
                text: "Поделиться",
                value: "share",
            },
            {
                ket: "hideResponse",
                text: contractorsFound ? "Открыть для откликов" : "Скрыть для откликов",
                value: "hideResponse",
                visible: ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.hideResponsesOrder,
                    ]),
            },
        ];
    }

    getContextMenu = ({mobile, tablet}) => {
        const {
            order: {
                status,
            },
        } = this.props;
        const {
            actionText,
            options = [],
            isVisibleAction,
        } = this.contentByStatus[status] || {};

        let opts = [...options];

        if (tablet) {
            opts = [...opts, ...this.tabletOptions];
        }

        if (mobile) {
            const mobileOptions = status !== ORDER_STATUS.CLOSED && isVisibleAction ? [{
                key: "end-action",
                text: actionText,
                value: "end-action",
            }] : [];

            opts = [...opts, ...this.tabletOptions, ...mobileOptions];
        }

        if (opts.length === 0) {
            return null;
        }

        return (
            <ContextMenu
                size="xl"
                inline={false}
                className="order-card__actions-button"
                options={opts}
                onClickItem={(option) => {
                    this.onClickActionItem(option);
                }}
            />
        );
    };

    hideResponses = () => {
        const {
            updateOrderContractorsFound,
            order: {
                orderId,
                clientId,
            },
        } = this.props;
        const {contractorsFound} = this.state;

        updateOrderContractorsFound({
            orderId,
            clientId,
            contractorsFound: !contractorsFound,
            getResult: (errorMessage) => {
                if (errorMessage) {
                    return;
                }

                this.setState(prevState => ({
                    ...prevState,
                    contractorsFound: !prevState.contractorsFound,
                }));
            },
        });
    };

    renderHeader() {
        const {contractorsFound} = this.state;
        const {
            order,
            t,
        } = this.props;
        const {
            orderNum,
            status,
            orderViewCount,
            orderSource,
        } = order;

        const {
            action,
            actionText,
            isVisibleAction,
        } = this.contentByStatus[status] || {};

        return (
            <Media
                queries={{
                    mobile: "(max-width: 767px)",
                    tablet: "(min-width: 768px) and (max-width: 1199px)",
                    desktop: "(min-width: 1200px)",
                }}
            >
                {
                    (matches) => {
                        const isMobileTablet = matches.mobile || matches.tablet;

                        return (
                            <>
                                <div className="order-card__header">
                                    <div className="order-card__row">
                                        <div className="order-card__title">
                                            <ButtonBack
                                                className="order-card__back"
                                                onClick={this.handleOnClickBack}
                                            />
                                            <div className="order-card__title-row">
                                                <div className="order-card__num">
                                                    {t("order-card.order-num")}
                                                    {" "}
                                                    №
                                                    {orderNum}
                                                </div>
                                                <div className="order-card__status-row">
                                                    {this.renderStatusBlock()}
                                                    {
                                                        !this.isApiOrRegistryOrderSourceType &&
                                                        <ViewsCount count={orderViewCount} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.isAccessCardActions &&
                                            <div className="order-card__actions">
                                                {
                                                    (
                                                        !this.isApiOrRegistryOrderSourceType
                                                        && isAccessByRestrictions([
                                                            CLIENT_USER_RESTRICTIONS_VARIABLE.hideResponsesOrder,
                                                        ])
                                                    ) &&
                                                    <OrderCardHideResponses
                                                        onChange={this.hideResponses}
                                                        contractorsFound={contractorsFound}
                                                        disabled={[NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role)}
                                                    />
                                                }
                                                {
                                                    orderSource !== ORDER_SOURCE_TYPES.REGISTRY &&
                                                    matches.desktop &&
                                                    status !== ORDER_STATUS.DRAFT &&
                                                    <div className="order-card__share">
                                                        <NmButton
                                                            size="xl"
                                                            color="grey"
                                                            onClick={this.toggleShareModal}
                                                            className="order-card__actions-button"
                                                            icon={<IconShare />}
                                                        >
                                                            Поделиться
                                                        </NmButton>
                                                        {this.renderSharePopup()}
                                                    </div>
                                                }
                                                {
                                                    status !== ORDER_STATUS.CLOSED && isVisibleAction &&
                                                    <NmButton
                                                        size="xl"
                                                        className="order-card__end-action order-card__actions-button"
                                                        onClick={action}
                                                        icon={<IconCheckbox />}
                                                    >
                                                        {actionText}
                                                    </NmButton>
                                                }
                                                {
                                                    !this.isApiOrRegistryOrderSourceType &&
                                                    isMobileTablet &&
                                                    this.renderSharePopup()
                                                }
                                                {this.getContextMenu(matches)}
                                            </div>
                                        }
                                    </div>
                                    <div className="row gx-sm-0 gx-0 gx-xl-5 order-card__information">
                                        {this.renderGeneralDescription()}
                                        {
                                            isMobileTablet ?
                                                <NmAccordion
                                                    className="mb-2"
                                                    name="Подробная информация"
                                                >
                                                    {this.renderSubDescription()}
                                                </NmAccordion> :
                                                this.renderSubDescription()
                                        }
                                        <div className="col-md-16 mt-xl-4 mt-xxl-0 col-xl-8 col-xxl-5">
                                            <div className="w-100">
                                                <div className="row gx-xl-4 gx-sm-0 gx-0">
                                                    {
                                                        isMobileTablet ?
                                                            <NmAccordion
                                                                name="Планируемые ресурсы"
                                                            >
                                                                <div className="col-xxl-10 col-md-16">
                                                                    {this.renderMoneyDescription(isMobileTablet)}
                                                                </div>
                                                                <div className="col-xxl-6 col-md-16">
                                                                    {this.renderContractorsCount()}
                                                                </div>
                                                            </NmAccordion> :
                                                            <>
                                                                <div className="col-md-16 col-xl-10 col-xxl-10">
                                                                    {this.renderMoneyDescription()}
                                                                </div>
                                                                <div className="col-md-16 col-xl-6 col-xxl-6">
                                                                    {this.renderVolumePlan()}
                                                                    {this.renderContractorsCount()}
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    }
                }
            </Media>
        );
    }

    getTabs = () => {
        const {countContractorTab, order: {totalDisputesCount}, match, t} = this.props;
        const {params: {page: tab, orderId}} = match;

        const link = LINK_ORDER_CARD.replace(":clientId", this.clientId);

        const linkSelfContractor = link.replace(":orderId", orderId).replace(":page", SELF_CONTRACTOR).replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        const linkDisputes = link.replace(":orderId", orderId).replace(":page", DISPUTES).replace(":subpage", CHAT_LINK_PARAMS.OPEN);

        return [
            {
                name: "Исполнители",
                count: countContractorTab,
                link: linkSelfContractor,
                active: tab === SELF_CONTRACTOR,
            },
            {
                name: t("order-card.disputes"),
                count: totalDisputesCount,
                link: linkDisputes,
                active: tab === DISPUTES,
            },
        ];
    };

    renderPanes() {
        const {
            match: {
                params: {
                    page: tab,
                    orderId,
                    subpage,
                },
            },
            location,
        } = this.props;

        return (
            <div className="order-card__tabs-container">
                <Tabs
                    className="order-card__tabs"
                    panes={this.getTabs()}
                />
                {
                    tab === SELF_CONTRACTOR &&
                    <OrderCardContractors
                        subpage={subpage}
                        orderId={orderId}
                        clientId={this.clientId}
                        isAccessActions={this.isAccessCardActions}
                    />
                }
                {
                    tab === DISPUTES &&
                    <OrderCardDisputes
                        clientId={this.clientId}
                        orderId={orderId}
                        subpage={subpage}
                        role={this.role}
                        location={location}
                    />
                }
            </div>
        );
    }

    renderDuplicateModal() {
        const {isOpenModalForCreateDuplicate} = this.state;

        return isOpenModalForCreateDuplicate &&
            <OrderEdit
                handleClose={this.closeModalForCreateDuplicate}
                clientId={this.clientId}
                editOrderId={this.orderId}
                duplicate
            />;
    }

    renderDeskModal() {
        const {
            isShowDescription,
            description,
        } = this.state;

        return isShowDescription &&
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        Описание услуг (работ)
                    </NmTitle>
                }
                children={description}
                classNameContent="order-card__modal-desk-content"
                onClose={() => {
                    this.setState({
                        isShowDescription: false,
                    });
                }}
            />;
    }

    renderLoader() {
        const {progressCard} = this.props;

        return (
            <Dimmer
                className="order-card__dimmer"
                active={progressCard}
                inverted
            >
                <Loader />
            </Dimmer>
        );
    }

    handleCloseSuccessCreate = () => {
        this.setState({
            successCreate: false,
        });
    };

    renderSuccessCreate() {
        const {successCreate} = this.state;

        return successCreate &&
            <OrderCreateSuccess
                clientId={this.clientId}
                handleClose={this.handleCloseSuccessCreate}
            />;
    }

    render() {
        const {
            updateCompleteTasks,
        } = this.props;

        return (
            <div
                id="order-card"
                className="order-card"
            >
                <Task />
                <ByTaskUpdater
                    fetch={this.fetchOrder}
                    taskIds={updateCompleteTasks}
                    dataIds={[this.taskId]}
                />
                {this.renderSuccessCreate()}
                {this.renderLoader()}
                {this.renderDuplicateModal()}
                {this.renderConfirmWindow()}
                {this.renderHeader()}
                {this.renderPanes()}
                {this.renderDeskModal()}
            </div>
        );
    }
}

export default connect(
    state => ({
        location: state.router.location,
        order: ordersRegistryCardSelector(state),
        paidSumList: getOrderWorkReportPaidSumListSelector(state),
        currentMemberId: clientCurrentMemberIdSelector(state),
        client: clientCardInfoSelector(state),
        backLink: financeBackLinkSelector(state),
        subCategory: subCategoriesCardSelector(state),
        countContractorTab: contractorCountOrderTabSelector(state),
        progressCard: ordersRegistryCardProgressSelector(state),
        createdOrderInfo: ordersRegistryCreatedOrderInfoSelector(state),
        workUnitsDict: unitsAbbreviationDictSelector(state),
        updateCompleteTasks: jobTasksContractorStatusProcessingSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }), {
        getOrderRichById,
        updateFinanceStore,
        updateFieldOrderContractorStore,
        updateFieldClientMemberStore,
        updateStoreOrders,
        getPaidSumList,
        getSubCategoryById,
        completeOrder,
        updateOrder,
        updateOrderArchive,
        updateFieldContractorStore,
        getOrderAttachmentsFile,
        clearSubCategoriesStore,
        updateOrderContractorsFound,
        refreshOrderContractorsBankPossibility,
        getAllTasks,

    })(withTranslation()(OrderCard));
