import {
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS,
    ADD_ITEM_INTO_FC_REGISTRY_ERROR,
    ADD_ITEM_INTO_FC_REGISTRY_REQUEST,
    ADD_ITEM_INTO_FC_REGISTRY_SUCCESS,
    ADD_REGISTRY_FRAME_CONTRACT_ERROR,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
    ADD_REGISTRY_FRAME_CONTRACT_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_SUCCESS,
    ARCHIVE_FC_REGISTRY_ERROR,
    ARCHIVE_FC_REGISTRY_REQUEST,
    ARCHIVE_FC_REGISTRY_SUCCESS,
    CANCEL_FC_REGISTRY_ITEM_ERROR,
    CANCEL_FC_REGISTRY_ITEM_REQUEST,
    CANCEL_FC_REGISTRY_ITEM_SUCCESS,
    DELETE_FC_REGISTRY_ITEMS_ERROR,
    DELETE_FC_REGISTRY_ITEMS_REQUEST,
    DELETE_FC_REGISTRY_ITEMS_SUCCESS,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS,
    EXPORT_FC_REGISTRY_ERROR,
    EXPORT_FC_REGISTRY_REQUEST,
    EXPORT_FC_REGISTRY_SUCCESS,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS,
    GET_FC_REGISTRIES_PAGE_ERROR,
    GET_FC_REGISTRIES_PAGE_REQUEST,
    GET_FC_REGISTRIES_PAGE_SUCCESS,
    GET_FC_REGISTRY_BY_ID_ERROR,
    GET_FC_REGISTRY_BY_ID_REQUEST,
    GET_FC_REGISTRY_BY_ID_SUCCESS,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS,
    GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
    GET_FC_REGISTRY_ITEMS_PAGE_REQUEST,
    GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS,
    GET_FC_REGISTRY_STATUS_DICT_ERROR,
    GET_FC_REGISTRY_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_STATUS_DICT_SUCCESS,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS,
    IMPORT_ITEMS_FC_REGISTRY_ERROR,
    IMPORT_ITEMS_FC_REGISTRY_REQUEST,
    IMPORT_ITEMS_FC_REGISTRY_SUCCESS,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
    SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
    SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
    UPDATE_REGISTRY_FRAME_CONTRACT_ERROR,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
    UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST,
    UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    listProgress: false,
    statusDict: {},
    itemStatusDict: {},
    statusDictProgress: false,
    itemStatusDictProgress: false,
    actionProgress: false,
    registryItemsPageData: {},
    registryItemsProgress: false,
    registryItemsImportProgress: false,
    registryItemsList: [],
    registryItemsTotalCount: 0,
    registryProgress: false,
    card: {},
    fcRegistrySubmittingFlags: {},
    contractorList: [],
    contractorListProgress: false,
    contractorListPageData: {},
    contractorListTotalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST:
            return {
                ...state,
                contractorListPageData: payload.data,
                contractorListProgress: true,
            };
        case GET_FC_REGISTRY_ITEMS_PAGE_REQUEST:
            return {
                ...state,
                registryItemsPageData: payload.data,
                registryItemsProgress: true,
            };
        case GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST:
        case DELETE_FC_REGISTRY_ITEMS_REQUEST:
        case EXPORT_FC_REGISTRY_REQUEST:
        case CANCEL_FC_REGISTRY_ITEM_REQUEST:
        case ADD_ITEM_INTO_FC_REGISTRY_REQUEST:
        case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST:
        case RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST:
        case SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST:
        case ADD_REGISTRY_FRAME_CONTRACT_REQUEST:
        case UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST:
        case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST:
        case UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST:
        case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case GET_FC_REGISTRY_BY_ID_REQUEST:
            return {
                ...state,
                registryProgress: true,
            };
        case ARCHIVE_FC_REGISTRY_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case GET_FC_REGISTRIES_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload.data,
                listProgress: true,
            };
        case GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST:
            return {
                ...state,
                itemStatusDictProgress: true,
            };
        case GET_FC_REGISTRY_STATUS_DICT_REQUEST:
            return {
                ...state,
                statusDictProgress: true,
            };
        case IMPORT_ITEMS_FC_REGISTRY_REQUEST:
            return {
                ...state,
                registryItemsImportProgress: true,
            };
        case GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS:
            return {
                ...state,
                actionProgress: false,
                fcRegistrySubmittingFlags: payload,
            };
        case DELETE_FC_REGISTRY_ITEMS_SUCCESS:
        case EXPORT_FC_REGISTRY_SUCCESS:
        case CANCEL_FC_REGISTRY_ITEM_SUCCESS:
        case ADD_ITEM_INTO_FC_REGISTRY_SUCCESS:
        case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS:
        case RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS:
        case SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS:
        case ADD_REGISTRY_FRAME_CONTRACT_SUCCESS:
        case UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS:
        case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS:
        case UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS:
        case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_FC_REGISTRY_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                registryProgress: false,
            };
        case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS:
            return {
                ...state,
                contractorList: payload.contractors || [],
                contractorListTotalCount: payload.totalCount,
                contractorListProgress: false,
            };
        case GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS:
            return {
                ...state,
                registryItemsList: payload.items || [],
                registryItemsTotalCount: payload.totalCount,
                registryItemsProgress: false,
            };
        case ARCHIVE_FC_REGISTRY_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS:
            return {
                ...state,
                itemStatusDict: payload,
                itemStatusDictProgress: false,
            };
        case GET_FC_REGISTRY_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
                statusDictProgress: false,
            };
        case GET_FC_REGISTRIES_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.registries || [],
                totalCount: payload.totalCount,
                listProgress: false,
            };
        case GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR:
            return {
                ...state,
                error: payload,
                itemStatusDictProgress: false,
            };
        case IMPORT_ITEMS_FC_REGISTRY_SUCCESS:
            return {
                ...state,
                registryItemsImportProgress: false,
            };
        case GET_FC_REGISTRY_STATUS_DICT_ERROR:
            return {
                ...state,
                error: payload,
                statusDictProgress: false,
            };
        case GET_FC_REGISTRIES_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                listProgress: false,
            };
        case GET_FC_REGISTRY_BY_ID_ERROR:
            return {
                ...state,
                error: payload,
                registryProgress: false,
            };
        case GET_FC_REGISTRY_ITEMS_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                registryItemsProgress: false,
            };
        case GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR:
            return {
                ...state,
                error: payload,
                contractorListProgress: false,
            };
        case GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR:
        case DELETE_FC_REGISTRY_ITEMS_ERROR:
        case ADD_ITEM_INTO_FC_REGISTRY_ERROR:
        case DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR:
        case RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR:
        case CANCEL_FC_REGISTRY_ITEM_ERROR:
        case SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR:
        case EXPORT_FC_REGISTRY_ERROR:
        case ARCHIVE_FC_REGISTRY_ERROR:
        case ADD_REGISTRY_FRAME_CONTRACT_ERROR:
        case UPDATE_REGISTRY_FRAME_CONTRACT_ERROR:
        case ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR:
        case UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR:
        case ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR:
            return {
                ...state,
                error: payload,
                actionProgress: false,
            };
        case IMPORT_ITEMS_FC_REGISTRY_ERROR:
            return {
                ...state,
                registryItemsImportProgress: false,
            };
        default:
            return state;
    }
};