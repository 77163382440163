import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Tabs from "../../components/ActualComponents/Tabs";
import NmPage from "../../components/NmPage";
import NmTitle from "../../components/NmTitle";
import {SettingsRoute} from "./routes/setting-route";

import {getUserRole} from "../../utils/access";
import bem from "../../utils/bem";
import {isAccessByRestrictions} from "../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DOCUMENTS_CORRECTION,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_KEYWORDS,
    LINK_SETTINGS_MEMBERS_LIST, LINK_SETTINGS_ML,
    LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TKB_TRANSFERS,
    LINK_SETTINGS_USERS_ROLES_LIST,
} from "../../constants/links";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../constants/roles";
import {USERS_ROLES_TYPE} from "../../constants/settingsUsersRoles";

import {
    clientUserCountSelector,
} from "../../ducks/clientMember";

import "./style.sass";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../constants/link-params";

const Settings = () => {
    const userTotalCount = useSelector(clientUserCountSelector);
    const pathname = useSelector(state => state.router.location.pathname);
    const specialityNonDecisionCount = useSelector(state => state.clientSpecialityOffer.nonDecisionCount);

    const {t} = useTranslation();
    const role = getUserRole();

    const [block] = bem("settings");

    const getTabs = () => {
        const isAccessTabs = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
        ]);

        const fnsServiceTab = {
            name: "ФНС России сервис",
            link: LINK_SETTINGS_FNS_SERVICE,
            active: LINK_SETTINGS_FNS_SERVICE,
            isVisible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessFtsService,
            ]),
        };

        if (role === NM_OPERATOR) {
            return [
                fnsServiceTab,
                {
                    name: t("settings.directories"),
                    link: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                    active: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                },
            ];
        }

        const tabs = [
            {
                name: t("settings.support-messages"),
                link: LINK_SETTINGS_TECHSUPPORT_LIST,
                active: pathname.includes("settings/techsupport"),
                isVisible: isAccessTabs,
            },
            {
                name: t("settings.directories"),
                link: LINK_SETTINGS_KEYWORDS,
                active: pathname.includes(LINK_SETTINGS_DIRECTORIES),
                count: [ADMIN, NM_MANAGER].includes(role) ? specialityNonDecisionCount : null,
            },
        ];

        if ([ADMIN].includes(role)) {
            tabs.push({
                name: "Ролевая модель",
                link: LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.NAIMIX),
                active: [
                    LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.NAIMIX),
                    LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.CLIENT),
                ],
                isVisible: isAccessTabs
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRoleModel,
                    ]),
            });
        }

        tabs.push({
            name: t("settings.requisites"),
            link: LINK_SETTINGS_INFO,
            active: pathname.includes(LINK_SETTINGS_INFO),
            isVisible: isAccessTabs,
        });

        tabs.push({
            name: t("settings.instruction"),
            link: LINK_SETTINGS_INSTRUCTION,
            active: [
                LINK_SETTINGS_INSTRUCTION,
                LINK_SETTINGS_INSTRUCTION_KEDO,
            ],
            isVisible: isAccessTabs,
        });

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push({
                name: t("settings.joining-requests"),
                link: LINK_SETTINGS_JOINING_REQUESTS,
                active: pathname.includes(LINK_SETTINGS_JOINING_REQUESTS),
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessRequestConnection,
                ]),
            });
        }

        if (role === ADMIN) {
            // tabs.push({
            //     name: "Логирование",
            //     link: LINK_SETTINGS_EVENT_LOG,
            //     active: pathname.includes(LINK_SETTINGS_LOG)
            // });
            tabs.push({
                name: t("settings.staff"),
                link: LINK_SETTINGS_MEMBERS_LIST.replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK),
                active: pathname.includes("settings/members-list/"),
                count: userTotalCount,
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsEmployees,
                ]),
            });
        }

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push(
                fnsServiceTab,
                {
                    name: "Исправление документов",
                    link: LINK_SETTINGS_DOCUMENTS_CORRECTION,
                    active: pathname.includes(LINK_SETTINGS_DOCUMENTS_CORRECTION),
                    isVisible: isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingCorrectionDocument,
                    ]),
                },
            );
        }

        if ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            tabs.push({
                name: "Группы компаний",
                link: LINK_SETTINGS_CLIENT_GROUP,
                active: pathname.includes(LINK_SETTINGS_CLIENT_GROUP),
                isVisible: isAccessTabs,
            });
        }

        if ([ADMIN, NM_MANAGER].includes(role)) {
            tabs.push({
                name: "Сервис",
                link: LINK_SETTINGS_SERVICE_SETTINGS,
                active: [
                    LINK_SETTINGS_SERVICE_SETTINGS,
                    LINK_SETTINGS_TKB_TRANSFERS,
                    LINK_SETTINGS_SERVICE_TASKS_STATS,
                ],
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsService,
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabsService,
                ]),
            });

            tabs.push({
                name: "Технические работы",
                link: LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
                active: LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabsService,
                ]),
            });
        }

        if ([ADMIN, NM_COORDINATOR].includes(role)) {
            tabs.push({
                name: "Машинное обучение",
                link: LINK_SETTINGS_ML,
                active: pathname.includes(LINK_SETTINGS_ML),
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsMachineLearning,
                ]),
            });
        }

        if ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            tabs.push({
                name: "Сбор банковских реквизитов",
                link: LINK_SETTINGS_COLLECTION_BANK_DETAILS,
                active: pathname.includes(LINK_SETTINGS_COLLECTION_BANK_DETAILS),
                isVisible: isAccessTabs,
            });
        }

        return tabs;
    };

    return (
        <NmPage
            className={block()}
            header={
                <NmTitle>
                    {t("settings.settings-title")}
                </NmTitle>
            }
            subHeader={
                <Tabs
                    panes={getTabs()}
                />
            }
        >
            <SettingsRoute />
        </NmPage>
    );
};

export default Settings;