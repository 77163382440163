import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

export const notificationSelector = state => state.bff.notifications;

export const notificationTotalPagesSelector = createSelector(
    notificationSelector,
    ({notificationTotalCount, notificationPageData: {pageSize = 0}}) => {
        return getTotalPages(notificationTotalCount, pageSize);
    },
);
export const notificationListSelector = createSelector(
    notificationSelector,
    ({notificationList}) => notificationList,
);
export const notificationUnreadCountSelector = createSelector(
    notificationSelector,
    ({unreadCount}) => unreadCount,
);
export const notificationTotalCountSelector = createSelector(
    notificationSelector,
    ({notificationTotalCount}) => notificationTotalCount,
);
export const notificationProgressSelector = createSelector(
    notificationSelector,
    ({progress}) => progress,
);
