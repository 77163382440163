export const BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST";
export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS";
export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR = "BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR";

export const BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE = "BFF_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CLEAR_STORE";

export const BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_UPDATE_STORE = "BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_UPDATE_STORE";
