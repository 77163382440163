export const BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST = "BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST";
export const BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS = "BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS";
export const BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR = "BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR";

export const BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST = "BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST";
export const BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS = "BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR = "BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR";

export const BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST = "BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST";
export const BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS = "BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_UPDATE_REGISTRY_INVITATIONS_ERROR = "BFF_UPDATE_REGISTRY_INVITATIONS_ERROR";

export const BFF_ADD_REGISTRY_INVITATIONS_REQUEST = "BFF_ADD_REGISTRY_INVITATIONS_REQUEST";
export const BFF_ADD_REGISTRY_INVITATIONS_SUCCESS = "BFF_ADD_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_ADD_REGISTRY_INVITATIONS_ERROR = "BFF_ADD_REGISTRY_INVITATIONS_ERROR";

export const BFF_DELETE_REGISTRY_INVITATIONS_REQUEST = "BFF_DELETE_REGISTRY_INVITATIONS_REQUEST";
export const BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS = "BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_DELETE_REGISTRY_INVITATIONS_ERROR = "BFF_DELETE_REGISTRY_INVITATIONS_ERROR";