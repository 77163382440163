import {
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    shortList: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    fetchError: false,
    typeDict: {},
    clientUsageList: [],
    clientUsagePageData: {},
    clientUsageTotalCount: 0,
    clientUsageActionTypeDict: {},
    clientUsageOrderTypesDict: {},
    templateLogs: [],
    templateLogsPageData: {},
    templateLogsTotalCount: 0,
    additionEffectDict: {},
    pageData: {},
    frameContractTemplatesList: [],
    orderApplicationTemplatesList: [],
    actTemplatesList: [],
    otherDocumentsTemplatesList: [],
    eventLogs: [],
    eventLogsTotalCount: 0,
    eventLogsPageData: {},
    eventLogTypeDict: {},
    defaultTemplatesAggregation: {},
    replacementsForUnavailableList: [],
    progressReplacementsForUnavailable: false,
    customDocumentTemplatesDisabledForClient: false,
    aggregationShortTemplates: {},
    progressFile: false,
    templateLogsProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS:
            return {
                ...state,
                defaultTemplatesAggregation: payload,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS:
            return {
                ...state,
                clientUsageOrderTypesDict: payload,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS:
            return {
                ...state,
                clientUsageActionTypeDict: payload,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS: {
            const {results = [], totalCount = 0} = payload;

            return {
                ...state,
                clientUsageList: results,
                clientUsageTotalCount: totalCount,
            };
        }
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST:
            return {
                ...state,
                clientUsagePageData: payload,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST:
            return {
                ...state,
                progressReplacementsForUnavailable: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR:
            return {
                ...state,
                progressReplacementsForUnavailable: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS:
            return {
                ...state,
                customDocumentTemplatesDisabledForClient: payload.customDocumentTemplatesDisabledForClient,
                clientDefaultFrameContractChanged: payload.clientDefaultFrameContractChanged,
                replacementsForUnavailableList: payload.result || [],
                progressReplacementsForUnavailable: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST:
            return {
                ...state,
                templateLogsPageData: payload,
                templateLogsProgress: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS:
            return {
                ...state,
                eventLogTypeDict: payload,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS: {
            const {results, totalCount} = payload;

            return {
                ...state,
                templateLogs: results,
                templateLogsTotalCount: totalCount,
                templateLogsProgress: false,
            };
        }
        case DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS:
            const {
                fieldName = "shortList",
                aggregationFieldName,
                isAggregation,
            } = payload;

            if (isAggregation) {
                return {
                    ...state,
                    [fieldName]: {
                        ...state[fieldName],
                        [aggregationFieldName]: payload.results,
                    },
                };
            }

            return {
                ...state,
                [fieldName]: payload.results,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR:
            return {
                ...state,
                progressFile: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS:
            return {
                ...state,
                progressFile: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST:
            return {
                ...state,
                progressFile: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS:
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR:
        case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS:
        case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR:
        case DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS:
        case DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR: {
            return {
                ...state,
                templateLogsProgress: false,
            };
        }
        case DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST:
        case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST:
        case DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                progress: false,
                fetchError: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST:
            const {
                pageNum,
                pageSize,
            } = payload;

            return {
                ...state,
                eventLogsPageData: {
                    pageNum,
                    pageSize,
                },
                progress: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS:
            const {
                results: eventLogs,
                totalCount: eventLogsTotalCount,
            } = payload;

            return {
                ...state,
                eventLogs,
                eventLogsTotalCount,
                progress: false,
                fetchErrorLogs: false,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                fetchError: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR:
            return {
                ...state,
                progress: false,
                fetchErrorLogs: true,
            };
        case DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};