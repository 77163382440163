import React from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderHistoryPopup from "../order-history-popup";

import {
    getOrderContractorHistory,
    updateStoreOrders,
} from "../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    contractorOrderHistoryProgressSelector,
    contractorOrderHistoryTotalCountSelector,
    contractorOrderHistoryTotalPagesSelector,
    getContractorOrderHistorySelector,
} from "../../../ducks/bff/orders/orders-registry/selectors";

const ClientCardOrderHistoryPopup = (props) => {
    const dispatch = useDispatch();

    const progress = useSelector(contractorOrderHistoryProgressSelector);
    const totalCount = useSelector(contractorOrderHistoryTotalCountSelector);
    const totalPages = useSelector(contractorOrderHistoryTotalPagesSelector);
    const list = useSelector(getContractorOrderHistorySelector);

    return (
        <OrderHistoryPopup
            {...props}
            isCompanyOrdersPage={true}
            events={list}
            totalCount={totalCount}
            totalPages={totalPages}
            progress={progress}
            onFetchList={(data) => {
                dispatch(getOrderContractorHistory(data));
            }}
            onClearStore={() => {
                dispatch(updateStoreOrders({
                    contractorHistoryPageData: {},
                    contractorHistoryTotalCount: 0,
                    contractorHistoryList: [],
                    contractorHistoryProgress: false,
                }));
            }}
        />
    );
};

export default ClientCardOrderHistoryPopup;