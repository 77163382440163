import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {
    getPageActRegistryPayments,
    getRichActRegistry,
    updateActRegistriesCardStore,
} from "../../../../../ducks/bff/orders/order-act-registries/card/actionCreators";
import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {
    getAllTasks,
    jobTasksActRegistrySelector,
} from "../../../../../ducks/job";

export function useActRegistryCardFetchData(props) {
    const {
        clientId,
        registryId,
        pageSize,
        pageNum,
        filter,
        payProgress,
    } = props;

    const progressTasks = useSelector(jobTasksActRegistrySelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const dispatch = useDispatch();
    const prevTasksList = useGetPrevValue(progressTasks);

    useEffect(() => {
        fetchCard();

        return () => {
            dispatch(updateActRegistriesCardStore({card: {}}));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum, payProgress]);

    useEffect(() => {
        if (isEmpty(progressTasks) && !isEmpty(prevTasksList)) {
            fetchList();
        }
    }, [progressTasks]);

    const fetchTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    const fetchCard = () => {
        dispatch(getRichActRegistry({
            clientId,
            registryId,
        }));
    };

    const fetchList = () => {
        dispatch(getPageActRegistryPayments({
            clientId,
            registryId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
        fetchTasks,
    };
}