export const CLIENT_OBJECTS_GET_PAGE_REQUEST = "CLIENT_OBJECTS_GET_PAGE_REQUEST";
export const CLIENT_OBJECTS_GET_PAGE_SUCCESS = "CLIENT_OBJECTS_GET_PAGE_SUCCESS";
export const CLIENT_OBJECTS_GET_PAGE_ERROR = "CLIENT_OBJECTS_GET_PAGE_ERROR";

export const CLIENT_OBJECTS_ADD_REQUEST = "CLIENT_OBJECTS_ADD_REQUEST";
export const CLIENT_OBJECTS_ADD_SUCCESS = "CLIENT_OBJECTS_ADD_SUCCESS";
export const CLIENT_OBJECTS_ADD_ERROR = "CLIENT_OBJECTS_ADD_ERROR";

export const CLIENT_OBJECTS_UPDATE_REQUEST = "CLIENT_OBJECTS_UPDATE_REQUEST";
export const CLIENT_OBJECTS_UPDATE_SUCCESS = "CLIENT_OBJECTS_UPDATE_SUCCESS";
export const CLIENT_OBJECTS_UPDATE_ERROR = "CLIENT_OBJECTS_UPDATE_ERROR";

export const CLIENT_OBJECTS_UPDATE_STATUS_REQUEST = "CLIENT_OBJECTS_UPDATE_STATUS_REQUEST";
export const CLIENT_OBJECTS_UPDATE_STATUS_SUCCESS = "CLIENT_OBJECTS_UPDATE_STATUS_SUCCESS";
export const CLIENT_OBJECTS_UPDATE_STATUS_ERROR = "CLIENT_OBJECTS_UPDATE_STATUS_ERROR";

export const CLIENT_OBJECTS_CLOSE_REQUEST = "CLIENT_OBJECTS_CLOSE_REQUEST";
export const CLIENT_OBJECTS_CLOSE_SUCCESS = "CLIENT_OBJECTS_CLOSE_SUCCESS";
export const CLIENT_OBJECTS_CLOSE_ERROR = "CLIENT_OBJECTS_CLOSE_ERROR";

export const CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST = "CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST";
export const CLIENT_OBJECTS_ADD_DEPOSIT_SUCCESS = "CLIENT_OBJECTS_ADD_DEPOSIT_SUCCESS";
export const CLIENT_OBJECTS_ADD_DEPOSIT_ERROR = "CLIENT_OBJECTS_ADD_DEPOSIT_ERROR";

export const CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST = "CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST";
export const CLIENT_OBJECTS_RETURN_DEPOSIT_SUCCESS = "CLIENT_OBJECTS_RETURN_DEPOSIT_SUCCESS";
export const CLIENT_OBJECTS_RETURN_DEPOSIT_ERROR = "CLIENT_OBJECTS_RETURN_DEPOSIT_ERROR";

export const IMPORT_OBJECT_DEPOSIT_REQUEST = "IMPORT_OBJECT_DEPOSIT_REQUEST";
export const IMPORT_OBJECT_DEPOSIT_SUCCESS = "IMPORT_OBJECT_DEPOSIT_SUCCESS";
export const IMPORT_OBJECT_DEPOSIT_ERROR = "IMPORT_OBJECT_DEPOSIT_ERROR";

export const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST";
export const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS";
export const GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR = "GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR";

export const CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST = "CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST";
export const CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS = "CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS";
export const CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR = "CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR";

export const OBJECTS_CHANGE_ARCHIVE_REQUEST = "OBJECTS_CHANGE_ARCHIVE_REQUEST";
export const OBJECTS_CHANGE_ARCHIVE_SUCCESS = "OBJECTS_CHANGE_ARCHIVE_SUCCESS";
export const OBJECTS_CHANGE_ARCHIVE_ERROR = "OBJECTS_CHANGE_ARCHIVE_ERROR";

export const CLIENT_OBJECTS_EXPORT_REQUEST = "CLIENT_OBJECTS_EXPORT_REQUEST";
export const CLIENT_OBJECTS_EXPORT_SUCCESS = "CLIENT_OBJECTS_EXPORT_SUCCESS";
export const CLIENT_OBJECTS_EXPORT_ERROR = "CLIENT_OBJECTS_EXPORT_ERROR";

export const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST";
export const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS";
export const OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR = "OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR";

export const CLIENT_OBJECTS_UPDATE_STORE = "CLIENT_OBJECTS_UPDATE_STORE";

export const CLIENT_OBJECTS_CLEAR_STORE = "CLIENT_OBJECTS_CLEAR_STORE";