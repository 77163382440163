import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getObjectsController} from "../objects/sagas";
import {getPageClientProjects} from "./actionCreators";
import {
    CLIENT_PROJECTS_ADD_DEPOSIT_ERROR,
    CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS,
    CLIENT_PROJECTS_ADD_ERROR,
    CLIENT_PROJECTS_ADD_REQUEST,
    CLIENT_PROJECTS_ADD_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
    CLIENT_PROJECTS_CHANGE_STATUS_ERROR,
    CLIENT_PROJECTS_CHANGE_STATUS_REQUEST,
    CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS,
    CLIENT_PROJECTS_GET_PAGE_ERROR,
    CLIENT_PROJECTS_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_GET_PAGE_SUCCESS,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_ERROR,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS,
    CLIENT_PROJECTS_IMPORT_ERROR,
    CLIENT_PROJECTS_IMPORT_REQUEST,
    CLIENT_PROJECTS_IMPORT_SUCCESS,
    CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR,
    CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS,
    CLIENT_PROJECTS_UPDATE_ERROR,
    CLIENT_PROJECTS_UPDATE_REQUEST,
    CLIENT_PROJECTS_UPDATE_SUCCESS,
} from "./actions";
import {getClientProjectCard} from "./card/actionCreators";
import {clientProjectsListPageDataSelector} from "./selectors";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../utils/url";

import {LINK_PROJECTS_CARD} from "../../../../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [LINK_PROJECTS_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects/project-card",
                client: "/client-adm/client/projects/project-card",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/projects",
            adminRolesUrl: "/adm/clients/client-card/client/projects",
        },
        "/adm/clients/client-card/client/projects",
        true,
        )
    );
};

const handleResponse = function* (data) {
    const {
        isFetchList,
        isFetchCard,
        projectId,
        clientId,
    } = data;

    if (isFetchList) {
        const state = yield select();
        const pageData = clientProjectsListPageDataSelector(state);

        yield put(getPageClientProjects(pageData));
    }

    if (isFetchCard) {
        yield put(getClientProjectCard({
            projectId,
            clientId,
        }));
    }
};

const getPageClientProjectsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get-project-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_PROJECTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_GET_PAGE_ERROR,
        });
    }
};

const addClientProjectSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/addProject`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Проект успешно добавлен");

        yield handleResponse({
            isFetchList: true,
        });

        yield put({
            type: CLIENT_PROJECTS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_ADD_ERROR,
        });
    }
};

const updateClientProjectSaga = function* ({payload}) {
    try {
        const {
            isGetList,
            isGetCard,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/updateProject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Проект успешно изменен");

        yield handleResponse({
            isFetchList: isGetList,
            isFetchCard: isGetCard,
            clientId: payload.clientId,
            projectId: payload.projectId,
        });

        yield put({
            type: CLIENT_PROJECTS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_UPDATE_ERROR,
        });
    }
};

const changeClientProjectStatusSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/changeProjectStatus`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CHANGE_STATUS_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Статус успешно обновлен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CHANGE_STATUS_ERROR,
        });
    }
};

const changeClientProjectArchivedSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/changeProjectArchived`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Статус объекта успешно изменен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR,
        });
    }
};

const importClientProjectsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            formData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/import/${clientId}/file`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_IMPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Загрузка файла успешно завершена. Новые проекты и объекты станут доступны через некоторое время.");

        yield handleResponse({isFetchList: true});

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_PROJECTS_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_IMPORT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/projects/add-project-deposit -АЧ
// POST /bff/client-adm/client/projects/add-project-deposit - КЧ
// Пополнение депозита
const addClientProjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-project-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_ADD_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Индивидуальный депозит проекта успешно изменен");

        yield handleResponse({
            isFetchList: true,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_ADD_DEPOSIT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/projects/return-project-deposit -АЧ
// POST /bff/client-adm/client/projects/return-project-deposit -КЧ
// Вернуть депозит
const returnClientProjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/return-project-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Депозит проекта успешно изменен");

        yield handleResponse({
            isFetchList: true,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/projects/npd/get-clean-history-page - АЧ
// POST /bff/client-adm/client/projects/npd/get-clean-history-page  - КЧ
// История изменения депозита проекта НПД
// POST /bff/adm/clients/client-card/client/projects/ndfl/get-clean-history-page -АЧ
// POST /bff/client-adm/client/projects/ndfl/get-clean-history-page - КЧ
// История изменения депозита проекта НДФЛ
const getProjectsDepositHistoryPage = function* ({payload}) {
    const {
        getResult,
        isNdfl,
        ...reqData
    } = payload;

    try {
        const type = isNdfl ? "ndfl" : "npd";
        const result = yield request.bff.post(`${getController()}/${type}/get-clean-history-page`, reqData);
        const {errorMessage} = result;

        if (getResult) {
            getResult();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_HISTORY_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (getResult) {
            getResult();
        }

        yield put({
            type: CLIENT_PROJECTS_HISTORY_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/projects/change-orders-limit-for-projects - АЧ
// POST /bff/client-adm/client/projects/change-orders-limit-for-projects - КЧ
// Изменить лимит заказов на проекте
const changeProjectOrdersLimit = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/change-orders-limit-for-projects`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Операция успешно выполнена");

        yield handleResponse({
            isFetchList: true,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR,
        });
    }
};

// Раздел Компании → Проекты → Карточка проекта
// POST - АЧ - /bff/adm/clients/client-card/client/projects/project-card/objects/change-orders-limit-from-file
// POST - КЧ - /bff/client-adm/client/projects/project-card/change-orders-limit-from-file
// Раздел Компании → Объекты
// POST - АЧ - /bff/adm/clients/client-card/client/objects/change-orders-limit-from-file
// POST - КЧ - bff/client-adm/client/objects/change-orders-limit-from-file
// POST /bff/adm/clients/client-card/client/projects/change-orders-limit-from-file -АЧ
// POST /bff/client-adm/client/projects/change-orders-limit-from-file - КЧ
const changeProjectsObjectsOrdersLimit = function* ({payload}) {
    try {
        const {
            isObjects,
            formData,
            onSuccess,
        } = payload;

        const controller = isObjects
            ? getObjectsController()
            : getController();

        const result = yield request.bff.post(
            `${controller}/change-orders-limit-from-file`,
            formData,
            {...getMultipartHeaders()},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Загрузка файла в процессе. Процесс загрузки в разделе \"Список задач\"");

        yield handleResponse({isFetchList: true});

        yield put({
            type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_PROJECTS_GET_PAGE_REQUEST, getPageClientProjectsSaga),
        takeEvery(CLIENT_PROJECTS_ADD_REQUEST, addClientProjectSaga),
        takeEvery(CLIENT_PROJECTS_UPDATE_REQUEST, updateClientProjectSaga),
        takeEvery(CLIENT_PROJECTS_CHANGE_STATUS_REQUEST, changeClientProjectStatusSaga),
        takeEvery(CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST, changeClientProjectArchivedSaga),
        takeEvery(CLIENT_PROJECTS_IMPORT_REQUEST, importClientProjectsSaga),
        takeEvery(CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST, returnClientProjectDepositSaga),
        takeEvery(CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST, addClientProjectDepositSaga),
        takeEvery(CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST, getProjectsDepositHistoryPage),
        takeEvery(CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST, changeProjectOrdersLimit),
        takeEvery(CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST, changeProjectsObjectsOrdersLimit),
    ]);
}