import React from "react";

export const headersNmContractorList = [
    {
        key: "contractorInfo",
        content: "",
    },
    {
        key: "actions",
        content: "",
    },
];

export const headersOrderListForInviting = [
    {
        key: "orderNumField",
        content: "Номер",
    },
    {
        key: "name",
        content: "Название",
    },
    {
        key: "orderStatus",
        content: "Статус",
    },
];


export const headersForemanRequests = [
    {
        key: "requestNumber",
        content: "№",
    },
    {
        key: "creationDate",
        content: "Дата операции",
    },
    {
        key: "object",
        content: "Объект",
    },
    {
        key: "requestDescription",
        content: "Описание заявки",
    },
    {
        key: "foremanFullName",
        content: "ФИО бригадира",
    },
    {
        key: "coordinatorFullName",
        content: "ФИО Координатора",
    },
    {
        key: "orderInfo",
        content: "Номер и название заказа",
    },
    {
        key: "status",
        content: "Статус",
    },
    {
        key: "action",
        content: "",
        align: "right",
    },
];

export const headersBrigadeList = [
    {
        key: "index",
        content: "№",
    },
    {
        key: "datetime",
        content: "Дата назначения",
    },
    {
        key: "fullName",
        content: "ФИО бригадира",
    },
    {
        key: "phone",
        content: "Телефон",
    },
    {
        key: "coordinatorName",
        content: "ФИО Координатора",
    },
    {
        key: "action",
        content: "",
        align: "right",
    },
];

export const headersTimesheetList = [
    {
        key: "timeSheetNum",
        content: "№",
    },
    {
        key: "operationDate",
        content: "Дата выхода на работу",
    },
    {
        key: "object",
        content: "Объект",
    },
    {
        key: "contractorsCount",
        content: "Количество человек ",
    },
    {
        key: "fullName",
        content: "ФИО Бригадира",
    },
    {
        key: "orderInfo",
        content: "Номер и название заказа",
    },
    {
        key: "action",
        content: "",
        align: "right",
    },
];

export const headersProjectsObjectList = [
    {
        key: "name",
        content: "Название",
        isSort: true,
        typeSort: "desc",
    },
    {
        key: "amount",
        content: "Бюджет, ₽",
    },
    {
        key: "objectExtId",
        content: "ID объекта",
    },
    {
        key: "address",
        content: "Адрес",
    },
    {
        key: "status",
        content: "Статус",
    },
    {
        key: "action",
        content: "Действия",
    },
];

export const headersProjectsObjectListWithoutAction = [
    {
        key: "name",
        content: "Название",
        isSort: true,
        typeSort: "desc",
    },
    {
        key: "amount",
        content: "Бюджет, ₽",
    },
    {
        key: "objectExtId",
        content: "ID объекта",
    },
    {
        key: "address",
        content: "Адрес",
    },
    {
        key: "status",
        content: "Статус",
    },
];

export const headersProjectTeamList = [
    {
        key: "fullName",
        content: "Фио",
        typeSort: "asc",
        isSort: true,
    },
    {
        key: "position",
        content: "Должность",
    },
    {
        key: "phone",
        content: "Номер телефона",
    },
    {
        key: "email",
        content: "E-mail",
    },
    {
        key: "role",
        content: "Роль",
    },
    {
        key: "action",
        content: "",
        align: "right",
    },
];

export const headersObjectUserList = [
    {
        key: "name",
        content: "ФИО",
    },
    {
        key: "action",
        content: "",
    },
];

export const headersStopWordsList = [
    {
        key: "value",
        content: "Стоп-слова",
    },
    {
        key: "stopWordForms",
        content: "Словоформы",
    },
    {
        key: "edit",
        content: "",
        align: "right",
    },
];

export const headersSpecialtyList = [
    {
        key: "specialty",
        content: "Вид деятельности",
    },
    {
        key: "edit",
        content: "",
        align: "right",
    },
];

export const edoExportListHeaders = [
    {
        key: "dateCreated",
        content: "Дата создания",
    },
    {
        key: "owner",
        content: "Инициатор",
    },
    {
        key: "type",
        content: "Тип документов",
    },
    {
        key: "count",
        content: "Количество документов",
    },
    {
        key: "stateDescription",
        content: "Статус выгрузки",
    },
    {
        key: "action",
        content: "",
    },
];

export const headersPromoCodeChannelList = [
    {
        key: "name",
        content: "Наименование канала",
    },
    {
        key: "count",
        content: "Кол-во промокодов",
    },
    {
        key: "period",
        content: "Период активности",
    },
    {
        key: "active",
        content: "Активность",
    },
    {
        key: "applicationCount",
        content: "Кол-во применений",
    },
    {
        key: "action",
        content: "",
    },
];

export const promocodeCardHeaders = {
    countOfUseByClients: {
        key: "clientActiveCount",
        content: "Кол-во применений заказчиками",
        isSort: true,
        typeSort: "desc",
    },
    countOfUseByContractors: {
        key: "contractorActiveCount",
        content: "Кол-во применений исполнителями",
        isSort: true,
        typeSort: "desc",
    },
    list: [
        {
            key: "promocode",
            content: "Наименование",
        },
        {
            key: "active",
            content: "Активность",
            isSort: true,
            typeSort: "asc",
        },
    ],
};

export const headersFinanceOrders = [
    {
        key: "orderNum",
        content: "№",
    },
    {
        key: "customer",
        content: "Заказчик",
    },
    {
        key: "name",
        content: "Название",
    },
    {
        key: "payout",
        content: "Выплачено/Всего, ₽",
    },
    {
        key: "createDate",
        content: "Дата создания",
    },
    {
        key: "workStartEndDate",
        content: "Период работ",
    },
    {
        key: "working",
        content: "Факт/План/Ожидают",
    },
    {
        key: "status",
        content: "Статус заказа",
    },
    {
        key: "history",
        content: "История",
    },
    {
        key: "checks",
        content: "Чеки",
    },
];

export const headersClientMemberList = [
    {
        key: "fullName",
        content: "ФИО",
        isSort: true,
        typeSort: "desc",
    },
    {
        key: "position",
        content: "Должность",
    },
    {
        key: "phone",
        content: "Номер телефона",
    },
    {
        key: "email",
        content: "E-mail",
    },
    {
        key: "role",
        content: "Роль",
    },
    {
        key: "action",
        content: "",
    },
];

export const headersTechsupportList = [
    {
        key: "dateTime",
        content: "Дата",
    },
    {
        key: "fioContractor",
        content: "ФИО самозанятого",
    },
    {
        key: "phone",
        content: "Номер телефона самозанятого",
    },
    {
        key: "typeOfAppeal",
        content: "Тип обращения",
    },
    {
        key: "topicOfAppeal",
        content: "Тема сообщения",
    },
    {
        key: "caseStatus",
        content: "Статус обращения",
    },
];

export const headersDepartmentFederalMigration = [
    {
        key: "nameDepartment",
        content: "Наименование",
    },
    {
        key: "region",
        content: "Регион",
    },
    {
        key: "address",
        content: "Адрес",
    },
    {
        key: "action",
    },
];

export const headersProjectsList = [
    {
        key: "name",
        content: "Название",
        isSort: true,
        typeSort: "desc",
        width: "25%",
    },
    {
        key: "amount",
        content: "Бюджет, ₽",
    },
    {
        key: "objectCount",
        content: "Количество объектов",
        align: "center",
    },
    {
        key: "status",
        content: "Статус",
    },
    {
        key: "action",
        content: "Действия",
        align: "center",
    },
];

export const headersFinanceBankList = [
    {
        key: "transactionDate",
        content: "Дата в банке",
        isSort: true,
        typeSort: "desc",
    },
    {
        key: "createDatePlatform",
        content: "Дата на площадке",
    },
    {
        key: "arrivalAmount",
        content: "Приход, ₽",
        align: "center",
    },
    {
        key: "consumptionAmount",
        content: "Расход, ₽",
        align: "center",
    },
    {
        key: "clientInfo",
        content: "Название контрагента",
    },
    {
        key: "reason",
        content: "Назначение платежа",
    },
    {
        key: "requisites",
        content: "Реквизиты контрагента",
    },
    {
        key: "status",
        content: "Статус",
    },
];

export const headersProjectsForemanList = [
    {
        key: "name",
        content: "Название",
        isSort: true,
        typeSort: "desc",
        width: "25%",
    },
    {
        key: "amount",
        content: "Бюджет, ₽",
    },
    {
        key: "objectCount",
        content: "Количество объектов",
        align: "center",
    },
    {
        key: "status",
        content: "Статус",
    },
];

export const headersClientList = (actionContent) => [
    {
        key: "name",
        content: "Заказчик",
    },
    {
        key: "address",
        content: "Адрес",
    },
    {
        key: "action",
        content: actionContent,
    },
];

export const headersClientLogEditingOperationsPayments = () => [
    {
        key: "createDate",
        content: "Дата и время события",
    },
    {
        key: "paymentsWithoutConfirmNmAdmin",
        content: "Установленное значение",
    },
    {
        key: "clientFio",
        content: "Инициатор",
    },
];

export const headersInsuranceList = [
    {
        key: "period",
        content: "Период",
    },
    {
        key: "totalHours",
        content: "Итого часов",
    },
    {
        key: "amount",
        content: "Сумма страхования, ₽",
    },
    {
        key: "reserveAmount",
        content: "Резерв страхования, ₽",
    },
    {
        key: "fio",
        content: "ФИО застрахованного",
    },
    {
        key: "insurancePolicy",
        content: "Страховой полис",
    },
    {
        key: "orderInfo",
        content: "Заказ",
    },
];

export const headersPublishedOrderList = [
    {
        key: "name",
        content: "Номер и название заказа",
    },
    {
        key: "object",
        content: "Объект",
    },
];

export const headersAwaitedContractorList = [
    {
        key: "name",
        content: "Номер и название заказа",
    },
    {
        key: "object",
        content: "Объект",
    },
    {
        key: "awaitingSignatureCount",
        content: "Откликов",
    },
];

export const headersHiredContractorList = [
    {
        key: "name",
        content: "Номер и название заказа",
    },
    {
        key: "object",
        content: "Объект",
    },
    {
        key: "orderContractorHiredCount",
        content: "Исполнителей",
    },
];

export const headersVacancyList = [
    {
        key: "name",
        content: "Номер и название заказа",
    },
    {
        key: "object",
        content: "Объект",
    },
    {
        key: "vacanciesCount",
        content: "Вакантных мест",
    },
];

export const headersFinanceUploadLogList = [
    {
        key: "initDate",
        content: "Дата создания запроса",
    },
    {
        key: "startPeriodDate",
        content: "Дата начала периода",
    },
    {
        key: "endPeriodDate",
        content: "Дата окончания периода",
    },
    {
        key: "manager",
        content: "Исполнитель",
    },
    {
        key: "documentType",
        content: "Тип выгрузки",
    },
    {
        key: "status",
        content: "Статус",
    },
    {
        key: "actions",
        content: "",
    },
];

export const headersDeviceLogList = [
    {
        key: "date",
        content: "Дата",
    },
    {
        key: "model",
        content: "Модель",
    },
    {
        key: "name",
        content: "Наименование",
    },
    {
        key: "ram",
        content: "ОЗУ",
    },
    {
        key: "screenResolution",
        content: "Разрешение экрана",
    },
    {
        key: "version",
        content: "Версия ОС",
    },
    {
        key: "versionApp",
        content: "Версия МП",
    },
];

export const financeExportListHeader = [
    {
        key: "createDatetime",
        content: "Дата создания запроса",
    },
    {
        key: "fromClientName",
        content: "Заказчик",
    },
    {
        key: "managerName",
        content: "Исполнитель",
    },
    {
        key: "fromDate",
        content: "Дата начала периода",
    },
    {
        key: "toDate",
        content: "Дата окончания периода",
    },
    {
        key: "uploadType",
        content: "Тип выгрузки",
    },
    {
        key: "uploadStatus",
        content: "Статус выгрузки",
    },
    {
        key: "formatType",
        content: "Формат файла",
    },
    {
        key: "download",
        content: "Скачать",
    },
];

export const financeExportListClientCardHeader = [
    {
        key: "createDatetime",
        content: "Дата создания запроса",
    },
    {
        key: "managerName",
        content: "Исполнитель",
    },
    {
        key: "fromDate",
        content: "Дата начала периода",
    },
    {
        key: "toDate",
        content: "Дата окончания периода",
    },
    {
        key: "uploadType",
        content: "Тип выгрузки",
    },
    {
        key: "uploadStatus",
        content: "Статус выгрузки",
    },
    {
        key: "formatType",
        content: "Формат файла",
    },
    {
        key: "download",
        content: "Скачать",
    },
];

export const patentsPivotHeaderList = [
    {
        key: "clientName",
        content: "Заказчик",
    },
    {
        key: "balance",
        content: "Остаток, ₽",
        isRurField: true,
    },
    {
        key: "arrivalColumn",
        content: "Приход, ₽",
    },
    {
        key: "paymentSum",
        content: "Выплачено, ₽",
        isRurField: true,
    },
    {
        key: "paymentCommission",
        content: "Комиссия\n заказчика, ₽",
        isRurField: true,
    },
    {
        key: "paymentSumWithCommission",
        content: "Выплачено\n с комиссией, ₽",
        isRurField: true,
    },
    {
        key: "rnkoCommission",
        content: "Комиссия\n РНКО, ₽",
        isRurField: true,
    },
    {
        key: "incomeSum",
        content: "Доход, ₽",
        isRurField: true,
    },
    {
        key: "outstandingPaymentsSum",
        content: "Незавершенные платежи, ₽",
    },
    {
        key: "outstandingPaymentsCommission",
        content: "Комиссия по незавершенным платежам, ₽",
    },
    {
        key: "lastArrivalDate",
        content: "Дата последнего\n пополнения",
        isSort: true,
        typeSort: "desc",
    },
];

export const contractorEventLogsHeaders = [
    {
        key: "dateTime",
        content: "Дата",
    },
    {
        key: "fullName",
        content: "ФИО",
    },
    {
        key: "groupType",
        content: "Тип события",
    },
    {
        key: "info",
        content: "Описание события",
    },
];

export const settingsLogsHeaders = [
    {
        key: "dateTime",
        content: "Дата и время",
    },
    {
        key: "section",
        content: "Раздел",
    },
    {
        key: "actionType",
        content: "Тип события",
    },
    {
        key: "event",
        content: "Событие",
    },
    {
        key: "initiator",
        content: "Инициатор",
    },
];

export const settingsDocumentLogsHeaders = [
    {
        key: "transactionDatetime",
        content: "Дата и время",
    },
    {
        key: "documentType",
        content: "Тип документа",
    },
    {
        key: "documentId",
        content: "Идентификатор документа",
    },
    {
        key: "transactionId",
        content: "№ лога транзакции",
    },
    {
        key: "document",
        content: "Документ",
    },
    {
        key: "performerName",
        content: "Подписант",
    },
    {
        key: "info",
        content: "Дополнительная информация",
    },
];

export const JoiningRequests = [
    {
        key: "organizationName",
        content: "Название организации",
    },
    {
        key: "fio",
        content: "ФИО",
    },
    {
        key: "inn",
        content: "ИНН",
    },
    {
        key: "phone",
        content: "Телефон",
    },
    {
        key: "date",
        content: "Дата",
    },
    {
        key: "email",
        content: "E-mail",
    },
    {
        key: "sendEmail",
        content: "Отправлен E-mail",
    },
];

export const brigadePaymentsHeaders = [
    {
        key: "paymentNumber",
        content: "№",
    },
    {
        key: "operationDate",
        content: "Дата операции",
    },
    {
        key: "orderInfo",
        content: "Номер и название заказа",
    },
    {
        key: "foremanFullName",
        content: "ФИО бригадира",
    },
    {
        key: "contractorFullName",
        content: "ФИО Самозанятого",
    },
    {
        key: "payAmount",
        content: "Сумма",
    },
    {
        key: "action",
    },
];

export const duplicateCheckPaymentsHeaders = [
    {
        key: "paymentNumberConfirmed",
        content: "Номер подтверждаемой выплаты",
    },
    {
        key: "paymentsNumberDuplicated",
        content: "Номер аналогичной выплаты",
    },
    {
        key: "performer",
        content: "Исполнитель",
    },
    {
        key: "sumOfMoney",
        content: "Сумма выплат, ₽",
    },
];

export const exportDocumentsHeaders = [
    {
        key: "createdDateTime",
        content: "Дата создания запроса",
    },
    {
        key: "creatorClientUserName",
        content: "Инициатор",
    },
    {
        key: "clientName",
        content: "Заказчик",
    },
    {
        key: "period",
        content: "Период",
    },
    {
        key: "counts",
        content: "Документы",
    },
    {
        key: "status",
        content: "Статус",
    },
    {
        key: "download",
        content: "Скачать",
    },
];

export const financePatentsPaymentsListHeaders = [
    {
        key: "operationDateTime",
        content: "Дата операции",
    },
    {
        key: "paymentNumber",
        content: "Номер платежа",
    },
    {
        key: "amount",
        content: "Сумма операции, ₽",
    },
    {
        key: "commission",
        content: "Комиссия заказчика, ₽",
    },
    {
        key: "clientName",
        content: "Заказчик",
    },
    {
        key: "contractorName",
        content: "Исполнитель",
    },
    {
        key: "registryName",
        content: "Реестр",
    },
    {
        key: "nmStatus",
        content: "Статус Наймикса",
    },
    {
        key: "bankStatus",
        content: "Банковский статус",
    },
    {
        key: "action",
        content: "",
    },
];

export const  groupClientsListHeaders = [
    {
        key: "clientName",
        content: "Заказчик",
    },
    {
        key: "inn",
        content: "ИНН",
    },
    {
        key: "delete",
        content: "",
    },
];

export const  groupContractorsListHeaders = [
    {
        key: "fio",
        content: "ФИО исполнителя",
    },
    {
        key: "phone",
        content: "Телефон",
    },
    {
        key: "inn",
        content: "ИНН",
    },
    {
        key: "delete",
        content: "",
    },
];

export const groupMembersListHeaders = [
    {
        key: "fio",
        content: "Сотрудник",
    },
    {
        key: "phone",
        content: "Телефон",
    },
    {
        key: "email",
        content: "E-mail",
    },
    {
        key: "action",
        content: "",
    },
];

export const addGroupMemberFormHeaders = [
    {
        key: "fio",
        content: "ФИО",
    },
    {
        key: "phone",
        content: "Телефон",
    },
    {
        key: "email",
        content: "E-mail",
    },
];

export const clientModulesAndTariffsHeaders = [
    {
        key: "module",
        content: "Модуль",
    },
    {
        key: "tariff",
        content: "Тариф",
    },
];

export const registryPaymentDocumentHeaders = [
    {
        key: "workDescription",
        content: "Описание выполненных работ",
    },
    {
        key: "workStartDate",
        content: "Начало работ",
    },
    {
        key: "workEndDate",
        content: "Окончание работ",
    },
    {
        key: "workExecutionAddresses",
        content: "Адрес выполнения работ",
    },
    {
        key: "amount",
        content: "Стоимость работ Net, ₽",
    },
    {
        key: "orderAmount",
        content: "Стоимость работ Gross, ₽",
    },
];

export const registryCivilPaymentDocumentHeaders = [
    {
        key: "workDescription",
        content: "Описание выполненных работ",
    },
    {
        key: "amount",
        content: "Сумма по договору, ₽",
    },
];

export const onboardingRecruitersHeaders = [
    {
        key: "fullName",
        content: "Рекрутер",
    },
    {
        key: "creationDateTime",
        content: "Дата добавления",
    },
    {
        key: "creatorFullName",
        content: "Добавил",
    },
    {
        key: "invitationLink",
        content: "Ссылка",
    },
    {
        key: "invitationQrFileLink",
        content: "QR-код",
    },
    {
        key: "linkUsedByContractorsCount",
        content: "Перешли по ссылке",
        isSort: true,
        typeSort: "desc",
    },
];

export const onboardingContractorsHeaders = [
    {
        key: "fullName",
        content: "Исполнитель",
    },
    {
        key: "source",
        content: "Источник",
    },
    {
        key: "contractorPhone",
        content: "Телефон",
    },
    {
        key: "contractorInn",
        content: "ИНН",
    },
    {
        key: "citizenship",
        content: "Гражданство",
    },
    {
        key: "recruiterFullName",
        content: "Рекрутер",
    },
    {
        key: "registrationStatusState",
        content: "Статус самозанятого",
    },
    {
        key: "taxOfferRightsState",
        content: "Автоуплата налогов",
    },
    {
        key: "taxStatusState",
        content: "Партнерство с Наймикс в ФНС",
    },
    {
        key: "frameContractStatus",
        content: "Договор",
    },
    {
        key: "additionalDocumentsStatus",
        content: "Доп. документы",
    },
    {
        key: "otherDocumentStatus",
        content: "Иной документ",
    },
    {
        key: "scenarioStatuses",
        content: "Сценарии",
    },
    {
        key: "action",
        content: "",
        isSort: true,
        typeSort: "desc",
    },
];

export const onboardingLeadsHeaders = [
    {
        key: "externalContractorName",
        content: "Исполнитель",
    },
    {
        key: "creationDateTime",
        content: "Лид от",
        isSort: true,
        typeSort: "desc",
    },
    {
        key: "externalContractorPhone",
        content: "Телефон",
    },
    {
        key: "externalContractorInn",
        content: "ИНН",
    },
    {
        key: "registrationStatus",
        content: "Статус регистрации",
    },
    {
        key: "basicRegistrationDateTime",
        content: "Дата простой регистрации",
    },
    {
        key: "personalDataSubmissionDateTimes",
        content: "Дата первой и последней заявки ПД",
    },
    {
        key: "externalLeadId",
        content: "Идентификатор лида",
    },
    {
        key: "externalFrameContractSigningDateTime",
        content: "Дата подписания РД",
    },
    {
        key: "action",
        content: "",
    },
];

export const settingsDirectoriesFlashCallHeaders = [
    {
        key: "countryName",
        content: "Страна",
    },
    {
        key: "code",
        content: "Код",
    },
    {
        key: "callsSupported",
        content: "Возможность звонков",
    },
];