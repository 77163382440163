import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return getBffUrl({
        isClientCard: true,
        clientRolesUrl: "/client-adm/recruitment/access-control/job-boards/account",
        adminRolesUrl: "/adm/clients/client-card/recruitment/access-control/job-boards/account",
    });
};

// POST /bff/adm/clients/client-card/recruitment/access-control/job-boards/account/{id}/archive
// POST /bff/client-adm/recruitment/access-control/job-boards/account/{id}/archive
// Архивировать аккаунт
const archiveRecruitmentAccessControlJobBoardAccount = function* ({payload}) {
    const {
        jobBoardAccountId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${jobBoardAccountId}/archive`);
        const {errorMessage, successMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (successMessage) {
            toastSuccess(successMessage);
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/access-control/job-boards/account/search
// POST /bff/client-adm/recruitment/access-control/job-boards/account/search
// Получить список аккаунтов джоб-бордов
const searchRecruitmentAccessControlJobBoardAccounts = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
        });
    }
};


//POST /bff/adm/clients/client-card/recruitment/vacancy/vacancy/getLogs
//POST /bff/client-adm/recruitment/access-control/job-boards/account/getLogs
//Получить лог по аккаунту джоб-борда
const getRecruitmentAccessControlJobBoardAccountsLogPage = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getLogs`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/access-control/job-boards/account/getOperationType
// GET /bff/client-adm/recruitment/access-control/job-boards/account/getOperationType
// Получить словарь типов операций для лога по аккаунту джоб-борда
const getRecruitmentAccessControlJobBoardAccountsOperationTypes = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getOperationType`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_ARCHIVE_JOB_BOARD_ACCOUNT_REQUEST, archiveRecruitmentAccessControlJobBoardAccount),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST, searchRecruitmentAccessControlJobBoardAccounts),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST, getRecruitmentAccessControlJobBoardAccountsLogPage),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_REQUEST, getRecruitmentAccessControlJobBoardAccountsOperationTypes),
    ]);
}