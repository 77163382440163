import React, {useEffect, useState} from "react";
import {debounce} from "lodash";

import {ReactComponent as ScanSolutionImageGlareIcon} from "../../images/scan_solution_type1.svg";
import {ReactComponent as ScanSolutionImagePartiallyMissingIcon} from "../../images/scan_solution_type2.svg";
import {ReactComponent as ScanSolutionDeclinedIcon} from "../../images/scan_solution_type3.svg";
import {ReactComponent as ScanSolutionApprovedIcon} from "../../images/scan_solution_type4.svg";
import {ReactComponent as ScanSolutionApprovedWithRequestInnIcon} from "../../images/scan_solution_type6.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import NmTitle from "../NmTitle";
import ScanSolutionItem from "./item";

import bem from "../../utils/bem";

import {
    SCAN_DECISION_TEXT,
    SCAN_DECISION_TITLE,
    SCAN_DECISION_TYPE,
    SCAN_SOLUTION_TYPES,
} from "../../constants/contractor";

import "./style.sass";

export type ScanSolutionType = {
    value: string,
    isInnCheck?: boolean,
    scanSolutionsType?: any,
    onChange: (decisionType: string, decisionReason?: string) => void,
    id?: string,
    solutionError?: string
    isPassportPageNumber19Check?: boolean,
}

const ScanSolution = (props: ScanSolutionType) => {
    const {
        value = "",
        isInnCheck = false,
        isPassportPageNumber19Check = false,
        onChange,
        scanSolutionsType = SCAN_SOLUTION_TYPES.OTHER,
        id = "",
        solutionError = "",
    } = props;

    const [block, element] = bem("scan-solution");

    const [solution, setSolution] = useState("");
    const [error, setError] = useState("");

    const infoMsg = "При завершении проверки по всем фотографиям режим проверки автоматически закроется.\n Если режим проверки не закрывается, то значит есть неоцененные фотографии.";

    useEffect(() => {
        setSolution(value);
    }, [id, value]);

    useEffect(() => {
        setError(solutionError);
    }, [solutionError]);

    const handleChangeRadioGroup = debounce((decisionType: string) => {
        if (!solutionError) {
            setError("");
        }

        onChange(decisionType);
    }, 100);

    const getApproveButtonTooltipText = () => {
        if (isInnCheck && isPassportPageNumber19Check) {
            return "Требуется лист ИНН и фотография разворота паспорта со сведениями о ранее выданных паспортах";
        }

        if (isInnCheck) {
            return "Требуется лист с ИНН";
        }

        if (isPassportPageNumber19Check) {
            return "Требуется фотография разворота паспорта со сведениями о ранее выданных паспортах";
        }
    };

    const getApproveButton = () => {
        const isVisibleApproveButtonWithTooltip = isInnCheck || isPassportPageNumber19Check;

        return isVisibleApproveButtonWithTooltip ?
            <NmAdvancedTooltip
                hover
                type="light"
                children={getApproveButtonTooltipText()}
                position="bottom-right"
                trigger={
                    <ScanSolutionItem
                        checked={solution === SCAN_DECISION_TYPE.FILE_APPROVED}
                        solutionIcon={<ScanSolutionApprovedWithRequestInnIcon />}
                        warningIconType={true}
                        onChange={() => handleChangeRadioGroup(SCAN_DECISION_TYPE.FILE_APPROVED)}
                    />
                }
            /> :
            <ScanSolutionItem
                checked={solution === SCAN_DECISION_TYPE.FILE_APPROVED}
                solutionIcon={<ScanSolutionApprovedIcon />}
                checkIconType={true}
                onChange={() => handleChangeRadioGroup(SCAN_DECISION_TYPE.FILE_APPROVED)}
                text={SCAN_DECISION_TEXT[scanSolutionsType][SCAN_DECISION_TYPE.FILE_APPROVED]}
            />;
    };

    return (
        <div className={block()}>
            <NmTitle
                className={element("title")}
                size="lg"
            >
                Решение
            </NmTitle>
            <div className={element("solution-block")}>
                <div className={element("row")}>
                    <ScanSolutionItem
                        checked={solution === SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE}
                        solutionIcon={<ScanSolutionImageGlareIcon />}
                        checkIconType={false}
                        onChange={() => handleChangeRadioGroup(SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE)}
                        text={SCAN_DECISION_TEXT[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]}
                        title={SCAN_DECISION_TITLE[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]}
                    />
                    <ScanSolutionItem
                        checked={solution === SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING}
                        solutionIcon={<ScanSolutionImagePartiallyMissingIcon />}
                        checkIconType={false}
                        onChange={() => handleChangeRadioGroup(SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING)}
                        text={SCAN_DECISION_TEXT[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]}
                        title={SCAN_DECISION_TITLE[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]}
                    />
                    <ScanSolutionItem
                        checked={solution === SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON}
                        solutionIcon={<ScanSolutionDeclinedIcon />}
                        checkIconType={false}
                        onChange={() => handleChangeRadioGroup(SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON)}
                        text={SCAN_DECISION_TEXT[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]}
                        title={SCAN_DECISION_TITLE[scanSolutionsType][SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]}
                    />
                    {getApproveButton()}
                </div>
                <div className={element("info")}>
                    {infoMsg}
                </div>
                {
                    error &&
                    <div className={element("info", {error: true})}>
                        {error}
                    </div>
                }
            </div>
        </div>
    );
};

export default ScanSolution;