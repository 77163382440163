import {useState} from "react";

export const useObjectsSort = () => {
    const [sortType, setSortType] = useState("DESC");
    const [sortName, setSortName] = useState("datetime");

    const onClickSort = ({sortType: fioSort, sortName}) => {
        setSortType(fioSort);
        setSortName(sortName);
    };

    const getSortOptions = () => {
        return [
            {
                key: "date",
                value: "date",
                text: "По дате добавления",
                sortType: sortType,
                asc: "ASC",
                desc: "DESC",
                sortName: "datetime",
                isDefaultSort: true,
            },
            {
                key: "fio",
                value: "fio",
                sortType: sortType,
                sortName: "name",
                text: "По наименованию",
                asc: "ASC",
                desc: "DESC",
            },
        ];
    };

    return {
        sortName,
        sortType,
        onClickSort,
        options: getSortOptions(),
    };
};