export const BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST = "BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST";
export const BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS = "BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS";
export const BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR = "BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR";

export const BFF_RUN_REGISTRY_INVITATIONS_REQUEST = "BFF_RUN_REGISTRY_INVITATIONS_REQUEST";
export const BFF_RUN_REGISTRY_INVITATIONS_SUCCESS = "BFF_RUN_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_RUN_REGISTRY_INVITATIONS_ERROR = "BFF_RUN_REGISTRY_INVITATIONS_ERROR";

export const BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST = "BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST";
export const BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS = "BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR = "BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR";

export const BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST = "BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST";
export const BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS = "BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR = "BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR";

export const BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST = "BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST";
export const BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS = "BFF_IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS";
export const BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR = "BFF_IMPORT__REGISTRY_INVITATIONS_CONTRACTORS_ERROR";

export const BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST = "BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST";
export const BFF_RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS = "BFF_RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS";
export const BFF_RUN_REGISTRY_INVITATIONS_ITEM_ERROR = "BFF_RUN_REGISTRY_INVITATIONS_ITEM_ERROR";

export const BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST = "BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST";
export const BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS = "BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS";
export const BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR = "BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR";

export const BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST = "BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST";
export const BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS = "BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS";
export const BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR = "BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR";

export const BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST = "BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST";
export const BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS = "BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS";
export const BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR = "BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR";

export const BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST = "BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST";
export const BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_SUCCESS = "BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_SUCCESS";
export const BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_ERROR = "BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_ERROR";
