import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {MediaButtons} from "../../../components/ActualComponents/MediaControls";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CreateDepositBaseInvoice from "../../../components/CreateDepositBaseIncoive";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPageCard from "../../../components/NmPageCard";
import TaskLoadingStatus from "../../../components/TaskLoadingStatus";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import ObjectsAddDeposit from "../../objects/components/add-deposit";
import ObjectsEdit from "../../objects/components/edit";
import OrderTemplateInfo from "../../order/template-info";
import ProjectObjectRoute from "../project-object-route";
import {ProjectsObjectsTabs} from "../projects-objects-tabs";
import {Dimmer, Loader} from "semantic-ui-react";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {getOnOffText} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {
    GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
    SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
} from "../../../constants/deposit";
import {LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST} from "../../../constants/links";
import {OBJECT_STATUS_DICT} from "../../../constants/objects";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {getNpdDepositReplenishmentAccountBasisFile} from "../../../ducks/bff/clients/finances/npd-deposit/actionCreators";
import {clientCardInfoSelector, clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {getClientObjectCard} from "../../../ducks/bff/clients/objects/card/actionCreators";
import {
    clientObjectCardInfoSelector,
    clientObjectCardProgressSelector,
} from "../../../ducks/bff/clients/objects/card/selectors";
import {getClientProjectCard} from "../../../ducks/bff/clients/projects/card/actionCreators";
import {clientProjectCardInfoSelector} from "../../../ducks/bff/clients/projects/card/selectors";
import {bffCrowdTasksTotalCountSelector} from "../../../ducks/bff/crowd/tasks/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {getClientDepositValuesV2} from "../../../ducks/deposit";
import {onboardingContractorsListTotalCountSelector} from "../../../ducks/onboardingContractors";
import {orderTotalCountSelector} from "../../../ducks/order";

import "./style.sass";

class ProjectsObjectCard extends Component {
    constructor(props) {
        super(props);
        const {
            match: {
                params: {
                    clientId,
                    projectId,
                    objectId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.role = ls(USER_ROLE);
        this.objectId = objectId;
        this.projectId = projectId;

        this.state = {
            historyData: "",
            description: "",
            isOpenAccountModal: false,
            hideDetails: false,
        };
    }

    componentDidMount() {
        const {
            getHideDepositDetails,
        } = this.props;

        this.getObject();
        this.saveHistoryData();
        this.fetchProject();

        getHideDepositDetails({
            urls: GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.OBJECT,
            getResult: (hideDetails) => {
                this.setState({
                    hideDetails,
                });
            },
        });
    }

    updateHideDepositDetails = () => {
        const {setHideDepositDetails} = this.props;
        const {hideDetails} = this.state;

        setHideDepositDetails({
            urls: SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.OBJECT,
            value: hideDetails,
        });
    };

    handleChangeHideDetails = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                hideDetails: !prevState.hideDetails,
            };
        }, this.updateHideDepositDetails);
    };

    // Необходимо для корректной работы полей бюджет на форме создания/редактирования объекта
    fetchProject = () => {
        const {getClientProjectCard} = this.props;

        getClientProjectCard({
            clientId: this.clientId,
            projectId: this.projectId,
        });
    };

    get localizationData() {
        const {
            t,
        } = this.props;

        return {
            editBtn: t("button.update"),
        };
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    getObject = () => {
        const {getClientObjectCard} = this.props;
        const {
            clientId,
            objectId,
        } = this;

        getClientObjectCard({
            clientId,
            objectId,
        });
    };

    getDepositValuesAmountInfo = () => {
        const {getClientDepositValuesV2} = this.props;

        getClientDepositValuesV2({
            clientId: this.clientId,
            objectId: this.objectId,
        });

        getClientDepositValuesV2({
            clientId: this.clientId,
            objectId: this.objectId,
            isCivil: true,
        });
    };

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {pageData, filterData} = state;

            this.setState({
                historyData: {prevPath, pageData, filterData},
            });
        }
    };

    handleOnClickBack = () => {
        const {historyData} = this.state;
        const {prevPath} = historyData;

        if (prevPath) {
            const {filterData, pageData} = historyData;

            history.push(prevPath, {pageData, filterData});

            return;
        }

        const linkToObjectList = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", this.projectId)
            .replace(":clientId", this.clientId);


        history.push(linkToObjectList);
    };

    openAccountModal = () => {
        this.setState({
            isOpenAccountModal: true,
        });
    };

    closeAccountModal = () => {
        this.setState({
            isOpenAccountModal: false,
        });
    };

    openEditModal = () => {
        this.setState({
            isOpenEditModal: true,
        });
    };

    openAddDepositModal = () => {
        this.setState({
            isOpenAddDeposit: true,
        });
    };

    closeEditModal = () => {
        this.setState({
            isOpenEditModal: false,
        });
    };

    renderEditObjectWindow() {
        const {
            object,
            project,
        } = this.props;
        const {
            isOpenEditModal,
        } = this.state;

        if (isOpenEditModal) {
            return (
                <ObjectsEdit
                    handleCancel={this.closeEditModal}
                    editObject={object}
                    selectedClientId={this.clientId}
                    fetchCard={this.getObject}
                    projectData={project}
                />
            );
        }
        return null;
    }

    renderAddDeposit = () => {
        const {
            object: {
                name,
            },
            project,
        } = this.props;

        const {
            isOpenAddDeposit,
        } = this.state;

        return (
            isOpenAddDeposit &&
            <ObjectsAddDeposit
                name={name}
                projectName={project.name}
                clientId={this.clientId}
                projectId={this.projectId}
                objectId={this.objectId}
                fetchData={this.getDepositValuesAmountInfo}
                onClose={() => {
                    this.setState({
                        isOpenAddDeposit: false,
                    });
                }}
            />
        );
    };

    getHeaderMediaControls = () => {
        const {
            object,
            clientProperties: {
                enableIndividualProjectAndObjectDeposit,
            },
            hideDepositDetailsProgress,
        } = this.props;

        const {
            status,
        } = object;

        const {
            hideDetails,
        } = this.state;

        const {editBtn} = this.localizationData;

        return (
            {
                renderCount: {
                    desktop: 3,
                    tablet: 3,
                    mobile: 0,
                },
                buttons: [
                    {
                        isAdditional: true,
                        component: COMPONENT.HORIZONTAL_TOGGLE,
                        props: {
                            disabled: hideDepositDetailsProgress,
                            noWrapLabel: true,
                            leftLabel: "Скрыть детали",
                            onChange: this.handleChangeHideDetails,
                            checked: hideDetails,
                            single: true,
                            duplex: false,
                        },
                        asset: {
                            mobile: {children: hideDetails ? "Показать детали" : "Скрыть детали"},
                        },
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: "Пополнить депозит объекта",
                            onClick: this.openAddDepositModal,
                            size: "xl",
                        },
                        visible: enableIndividualProjectAndObjectDeposit
                            && isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_addOrRefundDepositObjects,
                                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientObjects,
                            ])
                            && status === OBJECT_STATUS_DICT.IN_WORK.VALUE
                            && ![
                                PROJECT_MANAGER,
                                OBJECT_MANAGER,
                                FOREMAN,
                                NM_COORDINATOR,
                                NM_CHIEF_ACCOUNTANT,
                            ].includes(this.role),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: editBtn,
                            icon: <ModeIcon />,
                            onClick: this.openEditModal,
                            size: "xl",
                        },
                        visible: !this.isClientArchived
                            && [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)
                            && isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editClientObject,
                                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientObjects,
                                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
                            ]),
                    },
                ],
            }
        );
    };

    getTemplateLabelText = (id, link) => {
        return (
            <div className="flex">
                {id ? "Да (" : "Нет"}
                {
                    id &&
                    <>
                        <OrderTemplateInfo
                            id={id}
                            linkName="скачать шаблон"
                            useLinkName={false}
                            link={link}
                        />
                        )
                    </>
                }
            </div>
        );
    };

    render() {
        const {
            object,
            progressCard,
            pathname,
            orderCount,
            taskTotalCount,
            clientProperties: {
                civilRegistryPaymentsAvailable,
                enableIndividualProjectAndObjectDeposit,
            },
            onboardingContractorsTotalCount,
            t,
        } = this.props;
        const {
            status,
            name,
            projectUserCount,
            objectCount,
            projectName,
            address,
            addressId,
            metroInfo,
            latitude,
            longitude,
            clientUserCount,
            otherDocumentTemplateId,
            otherDocumentTemplatePdfDownloadLink,
            smzFrameContractTemplateId,
            smzFrameContractTemplatePdfDownloadLink,
            civilFrameContractTemplateId,
            civilFrameContractTemplatePdfDownloadLink,
            individualFrameContractTemplateId,
            individualFrameContractTemplatePdfDownloadLink,
            onboardingContractorsCount,
            crowdTasksCount,
            brandName,
            enableIndividualDeposit,
            closeProjectTaskProcessing,
            changeProjectDepositTaskProcessing,
        } = object;

        const {
            isOpenAccountModal,
            hideDetails,
        } = this.state;

        return (
            <NmPageCard
                header={
                    <NmPageCardHeader
                        size="xl"
                        content={`Объект "${name}"`}
                        statusBadge={
                            status ?
                                <NmBadge
                                    mod={OBJECT_STATUS_DICT[status].BADGE_MOD}
                                    className="ms-3"
                                    text={OBJECT_STATUS_DICT[status].TEXT}
                                /> :
                                null
                        }
                        handleOnClickBack={this.handleOnClickBack}
                        controls={
                            <MediaButtons
                                size="xl"
                                config={this.getHeaderMediaControls()}
                            />
                        }
                        isClearButtonMargin={true}
                    />
                }
                description={
                    <div className="d-flex flex-column flex-xl-row justify-content-between align-items-start">
                        <div className="col-16 col-md-8">
                            {
                                (closeProjectTaskProcessing || changeProjectDepositTaskProcessing) &&
                                <TaskLoadingStatus
                                    isObjects={true}
                                    closeProjectTaskProcessing={closeProjectTaskProcessing}
                                    changeProjectDepositTaskProcessing={changeProjectDepositTaskProcessing}
                                    className="mb-3 mb-md-4"
                                />
                            }
                            {
                                !hideDetails &&
                                <>
                                    <NmLabelText
                                        type="page"
                                        label="Проект"
                                        text={projectName}
                                    />
                                    <NmLabelText
                                        type="page"
                                        className="mt-2"
                                        label="Адрес"
                                        text={address}
                                    />
                                </>
                            }
                            {
                                (!hideDetails && metroInfo) &&
                                <NmLabelText
                                    type="page"
                                    className="mt-2"
                                    label="Ближайшие станции метро"
                                    text={metroInfo}
                                />
                            }
                            {
                                !hideDetails &&
                                <>
                                    <NmLabelText
                                        type="page"
                                        className="mt-2"
                                        label="Заключать отдельный договор НПД на объекте"
                                        columnOnMobile
                                        text={this.getTemplateLabelText(smzFrameContractTemplateId, smzFrameContractTemplatePdfDownloadLink)}
                                    />
                                    <NmLabelText
                                        type="page"
                                        className="mt-2"
                                        label="Заключать отдельный договор НДФЛ на объекте"
                                        columnOnMobile
                                        text={this.getTemplateLabelText(civilFrameContractTemplateId, civilFrameContractTemplatePdfDownloadLink)}
                                    />
                                    <NmLabelText
                                        type="page"
                                        className="mt-2"
                                        label="Заключать отдельный договор ИП на объекте"
                                        columnOnMobile
                                        text={this.getTemplateLabelText(individualFrameContractTemplateId, individualFrameContractTemplatePdfDownloadLink)}
                                    />
                                    <NmLabelText
                                        type="page"
                                        className="mt-2"
                                        label="Запрашивать подписание иного документа на объекте"
                                        columnOnMobile
                                        text={this.getTemplateLabelText(otherDocumentTemplateId, otherDocumentTemplatePdfDownloadLink)}
                                    />
                                    {
                                        enableIndividualProjectAndObjectDeposit &&
                                        <NmLabelText
                                            type="page"
                                            className="mt-2"
                                            label="Индивидуальный депозит объекта"
                                            columnOnMobile
                                            text={getOnOffText(enableIndividualDeposit)}
                                        />
                                    }
                                    {
                                        brandName &&
                                        <NmLabelText
                                            type="page"
                                            className="mt-2"
                                            label="Бренд"
                                            text={brandName}
                                        />
                                    }
                                </>
                            }
                        </div>
                        {/*Временная загрушка для Ашана - первый id в массиве ашана*/}
                        {
                            ["855bda92-9c37-4ec1-bdcf-66cbc8e8b3f7", "ecf2f1eb-e374-456d-8b48-7e0f3d414936", "05fe9264-5d57-4bf5-b476-f9c7c0c3b74d"].includes(this.clientId) &&
                            <div>
                                <NmButton
                                    className="objects-card__create-invoice"
                                    color="grey"
                                    size="xl"
                                    onClick={this.openAccountModal}
                                >
                                    {t("client-deposit-history.create-an-account")}
                                </NmButton>
                            </div>
                        }
                    </div>
                }
                info={
                    <>
                        {
                            (
                                ![FOREMAN].includes(this.role)
                                && isAccessByRestrictions([
                                    CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_getProjectsAndObjectsDepositValues,
                                ])
                            ) &&
                            <div className="mt-2 mb-4">
                                <div className="registry-list__details-item">
                                    <Text
                                        className="mb-2"
                                        medium
                                        level="2"
                                        color={COLOR.SECONDARY_70}
                                        noWrap
                                    >
                                        Детализация по счету для выплат исполнителям с типом налогообложения НПД
                                    </Text>
                                    <DepositValuesAmountInfo
                                        enableIndividualDeposit={enableIndividualDeposit}
                                        hideDetails={hideDetails}
                                        clientId={this.clientId}
                                        objectId={this.objectId}
                                    />
                                </div>
                                {
                                    civilRegistryPaymentsAvailable &&
                                    <div className="registry-list__details-item">
                                        <Text
                                            className="mb-2"
                                            medium
                                            level="2"
                                            color={COLOR.SECONDARY_70}
                                            noWrap
                                        >
                                            Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                                        </Text>
                                        <DepositValuesAmountInfo
                                            enableIndividualDeposit={enableIndividualDeposit}
                                            hideDetails={hideDetails}
                                            clientId={this.clientId}
                                            objectId={this.objectId}
                                            isCivil
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            >
                {this.renderAddDeposit()}
                <Dimmer
                    inverted
                    active={progressCard}
                >
                    <Loader content={t("loader.content")} />
                </Dimmer>
                {
                    this.renderEditObjectWindow()
                }
                {
                    isOpenAccountModal &&
                    <CreateDepositBaseInvoice
                        isNdfl={false}
                        clientId={this.clientId}
                        onSubmit={(data) => {
                            const {getNpdDepositReplenishmentAccountBasisFile} = this.props;

                            const {
                                sum,
                                ...requestData
                            } = data;

                            getNpdDepositReplenishmentAccountBasisFile({
                                ...requestData,
                                totalAmount: sum,
                            });
                        }}
                        isVisibleAccountNumber={true}
                        onClose={this.closeAccountModal}
                    />
                }
                <Tabs
                    className="mb-4 mb-xxl-5"
                    panes={ProjectsObjectsTabs({
                        clientId: this.clientId,
                        projectId: this.projectId,
                        objectId: this.objectId,
                        pathname,
                        orderCount,
                        taskCount: pathname.includes("tasks") ? taskTotalCount : crowdTasksCount,
                        projectUserCount,
                        clientUserCount,
                        objectCount,
                        onboardingContractorsCount,
                        onboardingContractorsTotalCount,
                    })}
                />
                <div className="objects-card__tab-content">
                    <ProjectObjectRoute
                        hideDetails={hideDetails}
                        latitude={latitude}
                        longitude={longitude}
                        addressId={addressId}
                    />
                </div>
            </NmPageCard>
        );
    }
}

export default connect(
    state => ({
        object: clientObjectCardInfoSelector(state),
        progressCard: clientObjectCardProgressSelector(state),
        pathname: state.router.location.pathname,
        orderCount: orderTotalCountSelector(state),
        taskTotalCount: bffCrowdTasksTotalCountSelector(state),
        project: clientProjectCardInfoSelector(state),
        client: clientCardInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        hideDepositDetailsProgress: hideDepositDetailsProgressSelector(state),
        onboardingContractorsTotalCount: onboardingContractorsListTotalCountSelector(state),
    }),
    {
        getClientObjectCard,
        getClientProjectCard,
        getHideDepositDetails,
        setHideDepositDetails,
        getClientDepositValuesV2,
        getNpdDepositReplenishmentAccountBasisFile,
    },
)((withTranslation()(ProjectsObjectCard)));
