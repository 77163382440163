import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import MediaQueriesRenderer from "../../../../components/ActualComponents/MediaQueriesRenderer";
import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmItemStatus from "../../../../components/ActualComponents/NmItemStatus";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import ContractorNoteForClientTooltip from "../../../../components/ContractorNoteForClientTooltip";
import ExtLink from "../../../../components/ExtLink";
import InvitePerformerToNaimix from "../../../../components/InvitePerformerToNaimix";
import LocatedOutsideRussiaTooltip from "../../../../components/LocatedOutsideRussiaTooltip";
import NmBadge from "../../../../components/NmBadge";
import NmButton from "../../../../components/NmButton";
import NmEmptyPage from "../../../../components/NmEmptyPage";
import NmIcon from "../../../../components/NmIcon";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import UnavailableTemplates from "../../../../components/UnavailableTemplates";
import {withPageData} from "../../../../components/withPageData";
import {ReactComponent as BlockedIcon} from "../../../../images/lock_24.svg";
import OrderTemplateInfo from "../../../order/template-info";
import FcRegistryCardFilter from "../card-filter/index";
import RegistryFrameContractNotificationData from "../notification-data-form";

import {getUnavailableTemplatesFlags} from "../../../../components/UnavailableTemplates/utils";
import dateFormat, {convertUtcToLocal, formatLocalDate} from "../../../../utils/dateFormat";
import {
    getFcRegistryCardActionOptions,
    getFcRegistryItemActionOptions,
    getResubmittingConfirmText,
    getSubmittingConfirmText,
    REGISTRY_OPTIONS,
} from "../../../../utils/fcRegistries";
import {getHistoryBackLink} from "../../../../utils/historyNavigationhelper";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {
    getFullName,
    phoneFormat,
    removePhoneMask,
} from "../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";
import {toastWarning} from "../../../../utils/toastHelper";
import {
    isAcceptedAddingContractorsToFcRegistry,
    isAcceptedFcRegistryActions,
    isAcceptedFcRegistryExporting,
    isAcceptedFcRegistryMassActions,
    isAcceptedSubmittingToSigningFcRegistry,
    isAcceptedUploadingContactorListToFcRegistry,
} from "../../../../utils/user-rights/fcRegistries";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../../components/UnavailableTemplates/constants";
import {citizenshipsDict} from "../../../../constants/citizenships";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    FC_REGISTRIES_STATUS_TO_NM_STATUS,
    FC_REGISTRY_ACTION,
    FC_REGISTRY_ACTION_TEXT,
    FC_REGISTRY_CARD_BTN_OPTIONS,
    FC_REGISTRY_CARD_OPTIONS,
    FC_REGISTRY_ITEM_ACTION,
    FC_REGISTRY_ITEM_STATUS,
    FC_REGISTRY_ITEM_STATUS_OBJECTS,
    FC_REGISTRY_ITEMS_MASS_ACTIONS,
} from "../../../../constants/fcRegistries";
import {
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../../constants/finance";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_REGISTRY_FC_ADD_ITEM_FORM,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../constants/links";
import {HR_MANAGER, isUserFromNm, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, RNKO} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {
    cancelFcRegistryItem,
    deleteFcRegistryItems,
    deleteNotSignedItemsFcRegistry,
    exportFcRegistry,
    getFcRegistryById,
    getFcRegistryItemsPage,
    getFcRegistryItemStatusDict,
    getFcRegistryStatusDict,
    getFcRegistrySubmittingFlags,
    importItemsFcRegistry,
    resubmitFcRegistryToSigning,
    submitFcRegistryToSigning,
} from "../../../../ducks/bff/clients/documents/frame-contracts-registry/actionCreators";
import {
    fcRegistryActionSelector,
    fcRegistryCardSelector,
    fcRegistryItemListProgressSelector,
    fcRegistryItemListSelector,
    fcRegistryItemListTotalCountSelector,
    fcRegistryItemListTotalPagesSelector,
    fcRegistryItemsImportProgressSelector,
    fcRegistryItemStatusDictSelector,
    fcRegistryItemStatusOptionsSelector,
    fcRegistryProgressSelector,
    fcRegistryStatusDictSelector,
    fcRegistrySubmittingFlagsSelector,
} from "../../../../ducks/bff/clients/documents/frame-contracts-registry/selectors";
import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {getDocumentsCustomTemplateReplacementsForUnavailableList} from "../../../../ducks/bff/documents-templates/actionCreators";
import {documentCustomTemplateProgressReplacementsForUnavailableSelector} from "../../../../ducks/bff/documents-templates/selectors";
import {getCitizenshipSelector} from "../../../../ducks/contractor";
import {registryFilterByTicketSelector} from "../../../../ducks/crm/ticket";
import {downloadDocument} from "../../../../ducks/documents";
import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";
import {specialitiesAllV2OptionsSelector} from "../../../../ducks/speciality";

import "./style.sass";

class FcRegistryCard extends Component {
    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    registryId,
                },
            },
            pageNum,
            pageSize,
        } = props;

        this.clientId = clientId;
        this.registryId = registryId;

        this.state = {
            pageNum: pageNum || 1,
            pageSize: pageSize || 25,
            filterData: {},
            selectedList: [],
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getFcRegistryStatusDict,
            getFcRegistryItemStatusDict,
            registryFilterByTicket,
        } = this.props;

        this.fetchRegistry();
        this.fetchList(registryFilterByTicket);

        getFcRegistryStatusDict();
        getFcRegistryItemStatusDict();
    }

    componentWillUnmount() {
        if (this.fetchTimeout) {
            clearTimeout(this.fetchTimeout);
        }
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    get isAccessActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsFrameContractRegistry,
        ]);
    }

    get isAccessItemsActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsFrameContractRegistryItems,
        ]);
    }

    static getDerivedStateFromProps(props, state) {
        const {list: oldList, selectedList} = state;
        const {list} = props;

        if (!isEqual(list, oldList) || list.length !== selectedList.length) {
            return {
                ...state,
                selectedList: list,
                selectedListCounter: 0,
                list,
            };
        }

        return state;
    }

    fetchRegistry = () => {
        const {
            getFcRegistryById,
        } = this.props;

        getFcRegistryById({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
        });
    };


    getStatusesFilter = (statusFilter) => {
        if (!statusFilter) {
            return;
        }

        if (statusFilter === FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE) {
            return [FC_REGISTRY_ITEM_STATUS.AWAITING_SIGNATURE, FC_REGISTRY_ITEM_STATUS.FOR_SIGNING];
        }

        return [handleFormString(statusFilter)];
    };

    fetchList = (filterData = {}) => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            getFcRegistryItemsPage,
        } = this.props;

        const {
            phoneFilter,
            statusFilter,
            ...otherFilterData
        } = filterData;

        getFcRegistryItemsPage({
            data: {
                pageNum,
                pageSize,
                clientId: this.clientId,
                registryId: this.registryId,
                ...otherFilterData,
                phoneFilter: handleFormString(removePhoneMask(phoneFilter)),
                statusesFilter: this.getStatusesFilter(statusFilter),
            },
        });
    };
    exportRegistry = () => {
        const {
            exportFcRegistry,
            card: {
                name,
            },
        } = this.props;

        exportFcRegistry({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
            fileName: `${name}.xlsx`,
        });
    };

    cancelRegistryItem = (item) => {
        return () => {
            const {
                cancelFcRegistryItem,
            } = this.props;

            const {
                clientId,
                registryId,
                seqNum,
            } = item;

            cancelFcRegistryItem({
                data: {
                    clientId,
                    registryId,
                    seqNum,
                },
                onSuccess: this.onActionSuccess,
            });
        };
    };

    deleteFcRegistryItems = () => {
        const {
            deleteFcRegistryItems,
        } = this.props;

        const {
            selectedList,
        } = this.state;

        const data = selectedList.filter(item => item.isSelected).map((item) => {
            return {
                clientId: this.clientId,
                registryId: this.registryId,
                seqNum: item.seqNum,
            };
        });

        deleteFcRegistryItems({
            data,
            onSuccess: this.onActionSuccess,
        });
    };

    resubmitFcRegistryToSigning = () => {
        const {
            resubmitFcRegistryToSigning,
        } = this.props;


        resubmitFcRegistryToSigning({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
            onSuccess: this.onActionSuccess,
        });

    };

    submitFcRegistryToSigning = () => {
        const {
            submitFcRegistryToSigning,
        } = this.props;

        submitFcRegistryToSigning({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
            onError: () => {
                this.setState({
                    dropdownAction: {
                        text: "При отправке реестра РД на подписание произошла ошибка. Попробуйте позднее",
                        action: this.closeActionConfirm,
                        isOnlyConfirm: true,
                        confirmButton: "Ок",
                    },
                    isOpenConfirm: true,
                });
            },
            onSuccess: (data) => {
                this.onActionSuccess(data);
                this.onCloseUnavailableTemplates();
            },
        });
    };

    deleteNotSignedItemsFcRegistry = () => {
        const {
            deleteNotSignedItemsFcRegistry,
        } = this.props;


        deleteNotSignedItemsFcRegistry({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
            onSuccess: this.onActionSuccess,
        });

    };

    fetchData = () => {
        this.fetchRegistry();
        this.fetchList();
    };

    onActionSuccess = (data = {}) => {
        const {
            hasWarning, warningMessage,
        } = data;

        this.setState({
            isOpenConfirm: false,
        });

        if (hasWarning) {
            toastWarning(warningMessage);
            return;
        }

        this.fetchTimeout = setTimeout(this.fetchData, 1000);
    };

    submitFilter = (filterData) => {
        this.setState({pageNum: 1}, () => {
            this.fetchList(filterData);
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handleOnClickBack = () => {
        const {
            location,
            pathname,
        } = this.props;

        const historyBackLink = getHistoryBackLink(location);

        if (historyBackLink && historyBackLink !== pathname) {
            history.push(historyBackLink);
            return;
        }

        history.push(LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", this.clientId));
    };

    updateState = (newStateFields, cbFn = () => {
    }) => {
        return () => {
            this.setState({
                ...newStateFields,
            }, cbFn);
        };
    };

    openImportFileModal = (isShow) => {
        return () => {
            this.setState({
                isOpenImportItemsModal: isShow,
            });
            this.onActionSuccess();
        };
    };

    submitImportForm = ({file}) => {
        const {importItemsFcRegistry} = this.props;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientId", this.clientId);
        formData.append("registryId", this.registryId);

        importItemsFcRegistry({
            data: formData,
            onSuccess: this.openImportFileModal(false),
        });
    };

    isShowCheckBox = ({status}) => {
        return [FC_REGISTRY_ITEM_STATUS.CREATED, FC_REGISTRY_ITEM_STATUS.DECLINED, FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR].includes(status);
    };

    isContractorFinded = ({contractorLastName, contractorFullName}) => {
        return !isNullOrWhitespace(contractorLastName) || !isNullOrWhitespace(contractorFullName);
    };

    isRussian = ({contractorCitizenship}) => {
        return [citizenshipsDict.RU.value].includes(contractorCitizenship) || isNullOrWhitespace(contractorCitizenship);
    };

    get isIndividualEntrepreneurRegistry() {
        const {card: {contractType}} = this.props;

        return contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    };

    renderTooltip = (tooltipText) => {
        return (
            <HelpTooltip
                info
                hover
                width={18}
                height={18}
                position="bottom-left"
                children={tooltipText}
            />
        );
    };

    renderBlockedInfo = ({blocked, blockedReason, contractorNoteForClient}) => {
        return (
            <div className="d-flex align-items-center">
                {
                    blocked &&
                    <NmAdvancedTooltip
                        className="d-flex align-items-center pointer"
                        trigger={
                            <BlockedIcon
                                width={24}
                                height={24}
                                color="red"
                            />
                        }
                        hover
                    >
                        {blockedReason}
                    </NmAdvancedTooltip>
                }
                <ContractorNoteForClientTooltip
                    className="ms-2"
                    note={contractorNoteForClient}
                />
            </div>
        );
    };

    renderListCard = (item) => {
        const {
            citizenshipDict,
        } = this.props;

        const {
            contractorCitizenship,
            ovmUnitName,
            speciality,
            contractorPhone,
            workAddress,
            signedDateTime,
            status,
            seqNum,
            locatedOutsideRussia,
        } = item;

        const citizenship = isNullOrWhitespace(contractorCitizenship) ? "Не определено" : citizenshipDict[contractorCitizenship];

        const notRequiredText = "Не требуется";
        const notRequiredCondition = this.isRussian(item) || this.isIndividualEntrepreneurRegistry;
        const ovmUnitText = notRequiredCondition ? notRequiredText : ovmUnitName;
        const specialityText = notRequiredCondition ? notRequiredText : speciality;
        const workAddressText = notRequiredCondition ? notRequiredText : workAddress;

        //TODO вынести в языковой файл
        const ovmHelp = "Наименование ОВМ, которое указывается в поле \"Настоящее уведомление предоставляется в\" при формировании документа \"Уведомление в ОВМ о заключении договора с иностранным гражданином (лицом без гражданства)\"";

        const specialityHelp = "Вид деятельности (профессия), которое указывается в поле \"Профессия (специальность, должность, вид трудовой деятельности) по трудовому или гражданско-правовому договору\" при формировании документа \"Уведомление в ОВМ о заключении договора с иностранным гражданином (лицом без гражданства)\"";

        const workAddressHelp = "Адрес, который указывается в поле \"Адрес места осуществления трудовой деятельности принятого на работу иностранного гражданина (лица без гражданства)\" при формировании документа \"Уведомление в ОВМ о заключении договора с иностранным гражданином (лицом без гражданства)\"";

        return (
            <NmListCard
                avatar
                checkbox
                noDivider
                alignItems="flex-start"
                classNameMainContent="col-16 col-md-16 col-xl-16 col-xxl-16"
                secondaryHeaderStatus={this.renderStatus(item)}
                secondaryHeader={signedDateTime && this.renderSignedDate(status, signedDateTime)}
                primaryHeader={this.renderContractorInfo(item, this.isContractorFinded(item))}
                primaryHeaderTooltip={this.renderBlockedInfo(item)}
                actions={this.renderActionCell(item)}
                isFixedActions
                cards={[]}
                labels={[
                    {label: "Номер строки", text: seqNum},
                    {
                        label: "Телефон",
                        text: phoneFormat(contractorPhone, locatedOutsideRussia),
                        classNameText: "flex align-items-center",
                        textOverflowUnset: true,
                        textTooltip:
                            locatedOutsideRussia &&
                            <LocatedOutsideRussiaTooltip />,
                    },
                    {label: "Гражданство", text: citizenship, noWrap: false, columnOnMobile: false},
                    {
                        label: "Наименование ОВМ",
                        text: <NmShowMoreText
                            title="Наименование ОВМ"
                            lines={1}
                            children={ovmUnitText}
                            more="Подробнее"
                            anchor="blue"
                        />,
                        noWrap: false,
                        noWrapLabel: true,
                        columnOnMobile: false,
                        className: "d-flex align-items-md-center",
                        textTooltip: this.renderTooltip(ovmHelp),
                    },
                    {
                        label: "Виды деятельности",
                        text: specialityText,
                        noWrap: false,
                        noWrapLabel: true,
                        columnOnMobile: false,
                        className: "d-flex align-items-md-center",
                        textTooltip: this.renderTooltip(specialityHelp),
                    },
                    {
                        label: "Адрес места осуществления трудовой деятельности иностранного гражданина",
                        text: workAddressText,
                        noWrap: false,
                        noWrapLabel: false,
                        columnOnMobile: true,
                        textTooltip: this.renderTooltip(workAddressHelp),
                    },
                ]}
                actionsClassName="col-1 flex-aligned-end flex-content-end"
            />
        );
    };

    getRows = () => {
        const {
            userAvatarDict,
        } = this.props;
        const {
            selectedList,
        } = this.state;


        return selectedList.map(item => {
            return {
                ...item,
                isWithSubHeader: ![FC_REGISTRY_ITEM_STATUS.CREATED].includes(item.status),
                isSelected: item.isSelected,
                showCheckBox: this.isShowCheckBox(item),
                avatar: <Avatar
                    addedToMyClientGroup={item.addedToMyClientGroup}
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
                contentRow: this.renderListCard(item),
                error: item.hasOvmRequiredToFill,
            };
        });
    };

    handleChange = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    goToAddContractorPage = () => {
        history.push(LINK_CLIENT_REGISTRY_FC_ADD_ITEM_FORM.replace(":clientId", this.clientId).replace(":registryId", this.registryId));
    };

    onSelectedRows = (selectedList, isAllSelected) => {
        this.setState({
            isAllSelected,
            selectedList,
            selectedListCounter: selectedList.filter(item => item.isSelected).length,
        });
    };

    clearSelection = () => {
        const {
            list,
        } = this.props;

        this.setState({
            selectedList: list,
            selectedListCounter: 0,
        });
    };

    closeActionConfirm = () => {
        this.setState({
            dropdownAction: null,
            isOpenConfirm: false,
        });
    };

    showDeleteItemsConfirm = () => {
        this.setState({
            dropdownAction: {
                text: "Удалить выбранные строки реестра?",
                action: this.deleteFcRegistryItems,
            },
            isOpenConfirm: true,
        });
    };

    showCancelItemConfirm = (item) => {
        this.setState({
            dropdownAction: {
                text: "Отменить строку реестра?",
                action: this.cancelRegistryItem(item),
            },
            isOpenConfirm: true,
        });
    };

    handleDownload({documentType, downloadLink, isDownload}) {
        const {
            downloadDocument,
        } = this.props;

        downloadDocument({
            isDownload,
            downloadLink,
            documentType,
        });
    }

    onClickActionCell = (option, item) => {
        const {t} = this.props;

        const {
            ovmNotificationLink,
            frameContractLink,
        } = item;

        switch (option.value) {
            case FC_REGISTRY_ITEM_ACTION.EDIT:
                this.setState({
                    isShowEditForm: true,
                    editableItem: item,
                });
                return;
            case FC_REGISTRY_ITEM_ACTION.CANCEL:
                this.showCancelItemConfirm(item);
                return;
            case FC_REGISTRY_ITEM_ACTION.DOWNLOAD_FRAME_CONTRACT:
                this.handleDownload({
                    isDownload: true,
                    downloadLink: frameContractLink,
                    documentType: t("finance-payment-list.download-frame-contract"),
                });
                return;
            case FC_REGISTRY_ITEM_ACTION.DOWNLOAD_OVM_NOTIFICATION:
                this.handleDownload({
                    isDownload: true,
                    downloadLink: ovmNotificationLink,
                    documentType: "Уведомление ОВМ",
                });
                return;
        }
    };

    getFcRegistrySubmittingFlags = (type) => {
        const {
            getFcRegistrySubmittingFlags,
        } = this.props;

        getFcRegistrySubmittingFlags({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
            onSuccess: () => {
                this.setState({
                    isOpenConfirm: true,
                    dropdownAction: this.getActionObj(type),
                });
            },
        });
    };

    checkUnavailableTemplates = () => {
        const {card, getDocumentsCustomTemplateReplacementsForUnavailableList} = this.props;

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId: this.clientId,
            frameContractsRegistryId: this.registryId,
            customDocumentIds: [
                card.frameContractTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем при отключенной опции "Свои шаблоны"
                if (isDisabledTemplateClientSetting) {
                    this.onOpenUnavailableTemplates();

                    return;
                }

                this.submitFcRegistryToSigning();
            },
        });
    };

    getActionObj = (type) => {
        const {
            submittingFlags,
            card: {
                name,
                contractType,
            },
        } = this.props;

        let resultObj = null;

        switch (type) {
            case FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING:
                resultObj = {
                    text: getSubmittingConfirmText(submittingFlags, this.isIndividualEntrepreneurRegistry),
                    action: this.checkUnavailableTemplates,
                };
                break;
            case FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING:
                resultObj = {
                    text: getResubmittingConfirmText(submittingFlags, this.isIndividualEntrepreneurRegistry),
                    action: this.resubmitFcRegistryToSigning,
                };
                break;
            case FC_REGISTRY_ACTION.DELETE_NOT_SIGNED:
                resultObj = {
                    text: FC_REGISTRY_ACTION_TEXT[FC_REGISTRY_ACTION.DELETE_NOT_SIGNED],
                    action: this.deleteNotSignedItemsFcRegistry,
                };
                break;
        }

        return {
            ...resultObj,
            text: resultObj.text.replace("{{name}}", name),
        };
    };

    showActionConfirm = (type) => {
        switch (type) {
            case FC_REGISTRY_ACTION.RESUBMIT_TO_SIGNING:
            case FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING:
                this.getFcRegistrySubmittingFlags(type);
                return;
            default:
                this.setState({
                    dropdownAction: this.getActionObj(type),
                    isOpenConfirm: true,
                });
        }
    };

    onClickMobileDropdownItem = ({value: action}) => {
        switch (action) {
            case FC_REGISTRY_ITEMS_MASS_ACTIONS.DELETE:
                this.showDeleteItemsConfirm();

                return;
            case FC_REGISTRY_ITEMS_MASS_ACTIONS.CANCEL:
                this.clearSelection();

                return;
            default:
                return;
        }
    };

    getMobileDropdownMassActionOptions = (selectedListCounter) => {
        if (this.isClientArchived) {
            return null;
        }

        if (!isAcceptedFcRegistryMassActions(this.role)) {
            return null;
        }

        const deleteRow = {
            key: FC_REGISTRY_ITEMS_MASS_ACTIONS.DELETE,
            text: "Удалить строки",
            value: FC_REGISTRY_ITEMS_MASS_ACTIONS.DELETE,
            disabled: selectedListCounter === 0,
        };

        const cancelSelection = {
            key: FC_REGISTRY_ITEMS_MASS_ACTIONS.CANCEL,
            text: "Очистить",
            value: FC_REGISTRY_ITEMS_MASS_ACTIONS.CANCEL,
            disabled: selectedListCounter === 0,
        };

        return [
            deleteRow,
            cancelSelection,
        ];
    };

    onClickHeaderContextMenuBtn = ({value: action}) => {
        switch (action) {
            case FC_REGISTRY_CARD_BTN_OPTIONS.INVITE_TO_NM.value:
                this.handleChange({openAddContractorForm: true})();
                return;
            case FC_REGISTRY_CARD_BTN_OPTIONS.INVITE_TO_REGISTRY.value:
                this.goToAddContractorPage();
                return;
            case FC_REGISTRY_CARD_BTN_OPTIONS.UPLOAD_LIST.value:
                this.openImportFileModal(true)();
                return;
            default:
                console.error("action doesn't exists");
        }
    };

    openFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            openFilter: !prevState.openFilter,
        }));
    };

    onClickContextMenuItem = ({value: action}) => {
        switch (action) {
            case FC_REGISTRY_CARD_OPTIONS.UPLOAD.value:
                this.exportRegistry();
                return;
            case FC_REGISTRY_CARD_OPTIONS.SUBMIT_TO_SIGNING.value:
                this.checkUnavailableTemplates();
                return;
            case REGISTRY_OPTIONS.RESUBMIT_TO_SIGNING.value:
                this.resubmitFcRegistryToSigning();
                return;
            case REGISTRY_OPTIONS.DELETE_NOT_SIGNED.value:
                this.deleteNotSignedItemsFcRegistry();
                return;
            case FC_REGISTRY_CARD_OPTIONS.FIlTER.value:
                this.openFilter();
                return;
            default:
                console.error("action doesn't exists");
        }
    };

    getStatusInfo = (status, declinedReason) => {
        if (status === FC_REGISTRY_ITEM_STATUS.DECLINED && !declinedReason) {
            return "Подписание договора отменено по инициативе заказчика";
        }
        return declinedReason;

    };

    renderColorStatusField = (status, declinedReason) => {
        const {
            itemStatusDict,
        } = this.props;

        return (
            <NmBadge
                mod={FC_REGISTRY_ITEM_STATUS_OBJECTS[status].MOD}
                tooltipPosition="right-top"
                rightTooltipText={this.getStatusInfo(status, declinedReason)}
                text={itemStatusDict[status]}
            />
        );
    };

    renderSignedDate = (status, signedDateTime) => {
        if (![FC_REGISTRY_ITEM_STATUS.SIGNED].includes(status)) {
            return null;
        }
        return (
            <div
                className="fc-registry-card__signed-date ms-0"
            >
                {`Дата подписания: ${dateFormat(convertUtcToLocal(signedDateTime))}`}
            </div>
        );
    };

    renderStatus = ({status, declinedReason, signedDateTime}) => {
        if ([FC_REGISTRY_ITEM_STATUS.CREATED].includes(status)) {
            return "";
        }

        if ([FC_REGISTRY_ITEM_STATUS.SIGNATURE_PREPARATION_ERROR, FC_REGISTRY_ITEM_STATUS.SIGNATURE_ERROR, FC_REGISTRY_ITEM_STATUS.DECLINED].includes(status)) {
            return (
                <div className="mt-5 mt-md-0">
                    {this.renderColorStatusField(status, declinedReason)}
                </div>
            );
        }

        return this.renderColorStatusField(status, null, signedDateTime);
    };

    renderActionCell(item) {
        const {card} = this.props;

        const options = getFcRegistryItemActionOptions({
            ...item,
            externalDocument: card.externalDocument,
            isClientArchived: this.isClientArchived,
            isIndividualEntrepreneurRegistry: this.isIndividualEntrepreneurRegistry,
        }, this.role);

        if (
            isEmpty(options)
            || !this.isAccessItemsActions
        ) {
            return null;
        }

        return (
            <div>
                <ContextMenu
                    options={options}
                    onClickItem={(option) => {
                        this.onClickActionCell(option, item);
                    }}
                />
            </div>
        );
    }

    renderAddContractorToNmForm() {
        const {
            openAddContractorForm,
        } = this.state;

        return (openAddContractorForm &&
            <InvitePerformerToNaimix
                close={this.handleChange({openAddContractorForm: false})}
                clientId={this.clientId}
            />
        );
    }

    renderContractorInfo = (contractor, isContractorFinded) => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            historyData,
        } = this.props;

        const {
            contractorFirstName,
            contractorLastName,
            contractorPatronymic,
            contractorFullName,
            contractorId,
            status,
        } = contractor;

        const fullName = isNullOrWhitespace(contractorLastName) ? contractorFullName : `${contractorLastName} ${contractorFirstName} ${contractorPatronymic || ""}`;

        const contractorLink = ([HR_MANAGER, RNKO].includes(this.role) ? LINK_CONTRACTOR_PERSONAL_INFO : LINK_CONTRACTOR_PROFILE)
            .replace(":contractorId", contractorId);

        let marginBottomClass = "";


        return (
            <Media query={{maxWidth: 767}}>
                {
                    mobile => {
                        if (!this.isShowCheckBox(contractor)) {
                            if ([FC_REGISTRY_ITEM_STATUS.SIGNED].includes(status)) {
                                marginBottomClass = "mt-0";
                            } else if (mobile) {
                                marginBottomClass = "mt-5";
                            }
                        }

                        return (
                            <div
                                className={marginBottomClass}
                            >
                                {isContractorFinded ? <ExtLink
                                    extData={historyData}
                                    pageData={{
                                        pageNum, pageSize,
                                    }}
                                    to={contractorLink}
                                    historyEnabled
                                >
                                    {fullName}
                                </ExtLink> : "Не определено"}
                            </div>
                        );
                    }
                }
            </Media>
        );
    };

    renderImportRegistriesForm() {
        const {progressImport} = this.props;
        const {isOpenImportItemsModal} = this.state;

        return (
            isOpenImportItemsModal &&
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Реестр_на_подписание_РД.xlsx"
                onSubmit={this.submitImportForm}
                onClose={this.openImportFileModal(false)}
                progress={progressImport}
            />
        );
    }

    renderUploadListButton = () => {
        const {
            card,
        } = this.props;

        return (isAcceptedUploadingContactorListToFcRegistry(this.role, card) &&
            <div
                className="fc-registry-card__func-link"
                onClick={this.openImportFileModal(true)}
            >
                <NmIcon
                    className="fc-registry-card__func-link-icon"
                    name="load-list"
                />
                Загрузить список исполнителей
            </div>
        );
    };

    get canShowCheckboxByRole() {
        return ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role);
    }

    renderList() {
        const {
            selectedList,
            selectedListCounter,
        } = this.state;

        if (isEmpty(selectedList)) {
            return (
                <NmEmptyPage
                    title="Реестр пуст"
                />
            );
        }
        return (
            <CheckboxList
                withCheckbox={this.isAccessItemsActions}
                header={this.renderMassActionsBlock()}
                className="fc-registry-card__table"
                rows={this.getRows()}
                onSelectedRows={this.canShowCheckboxByRole && this.onSelectedRows}
                actionOptions={this.getMobileDropdownMassActionOptions(selectedListCounter)}
                onClickActionItem={this.onClickMobileDropdownItem}
            />
        );
    }

    renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    renderClientUserDeletedTooltip = () => {
        const {
            card,
        } = this.props;

        const {
            creatorClientUserLastName,
            creatorClientUserFirstName,
            creatorClientUserPatronymic,
            creatorClientUserDeleted,
        } = card;

        if (creatorClientUserDeleted) {
            return (
                <HelpTooltip
                    type="error"
                    children="Пользователь удалён"
                    hover={true}
                    height={18}
                    width={18}
                />
            );
        }

        return getFullName(
            creatorClientUserLastName,
            creatorClientUserFirstName,
            creatorClientUserPatronymic,
        );
    };

    renderRegistryInfo() {
        const {
            card,
        } = this.props;

        const {
            creationDateTime,
            commentary,
            contractType,
            frameContractByObjectTemplate,
            externalDocumentSource,
        } = card;

        return (!isEmpty(card) &&
            <div
                className="fc-registry-card__info"
            >
                <div
                    className="fc-registry-card__info-name"
                >
                    {`Реестр РД от ${dateFormat(convertUtcToLocal(creationDateTime), "dd.MM.yyyy")}`}
                </div>
                {
                    externalDocumentSource?.description &&
                    <NmLabelText
                        type="page"
                        className="fc-registry-card__info-other"
                        text={externalDocumentSource.description}
                    />
                }
                <div
                    className="fc-registry-card__info-other"
                >
                    {commentary}
                </div>
                {
                    frameContractByObjectTemplate &&
                    <NmLabelText
                        type="page"
                        className="fc-registry-card__info-other"
                        label="Заключать договоры по объекту"
                        text={this.renderObjectInfo(card)}
                    />
                }
                <NmLabelText
                    type="page"
                    className="fc-registry-card__info-other"
                    label="Ответственный"
                    noWrap={false}
                    text={this.renderClientUserDeletedTooltip()}
                />
                <NmLabelText
                    className="fc-registry-card__info-other"
                    type="page"
                    label="Договор"
                    text={ORDER_WORK_REPORT_TYPE_TRANSLATE[contractType]}
                />
                <NmLabelText
                    className="fc-registry-card__info-other"
                    type="page"
                    label="Шаблон договора"
                    textTile={card.frameContractTemplateName}
                    textMaxWidth={true}
                    inline={true}
                    noWrap={false}
                    text={
                        <OrderTemplateInfo
                            id={card.frameContractTemplateId}
                            linkName={card.frameContractTemplateName}
                            link={card.frameContractTemplatePdfDownloadLink}
                        />
                    }
                />
                {
                    contractType !== ORDER_WORK_REPORT_TYPE.INDIVIDUAL &&
                    <NmLabelText
                        className="fc-registry-card__info-other"
                        type="page"
                        label="Разрешить подписывать РД до проверки персональных данных Администрацией Наймикс"
                        noWrapLabelOnMobile={true}
                        columnOnMobile={true}
                        text={card.signingBeforeCheckPersonalData ? "Да" : "Нет"}
                    />
                }
                {
                    contractType === ORDER_WORK_REPORT_TYPE.SMZ &&
                    <>
                        <NmLabelText
                            className="fc-registry-card__info-other"
                            type="page"
                            label="Разрешить подписывать РД при отсутствии статуса НПД и партнерства с Наймикс"
                            text={card.signingWithoutTaxpayerAndPartner ? "Да" : "Нет"}
                            noWrapLabelOnMobile={true}
                            columnOnMobile={true}
                        />
                        <NmLabelText
                            className="fc-registry-card__info-other"
                            type="page"
                            label="Разрешить подписывать РД при наличии подключенной автоуплаты налога"
                            text={card.signingOnlyWithSmzTaxOffer ? "Да" : "Нет"}
                            noWrapLabelOnMobile={true}
                            columnOnMobile={true}
                        />
                    </>
                }
                {
                    card.designatedSigningDateTime &&
                    <NmLabelText
                        className="fc-registry-card__info-other"
                        type="page"
                        label="Дата подписания РД"
                        text={
                            card.designatedSigningDateTime
                                ? dateFormat(card.designatedSigningDateTime, "dd.MM.yyyy HH:mm")
                                : undefined
                        }
                    />
                }
            </div>
        );
    }

    renderRegistryStatus() {
        const {
            statusDict,
            card: {
                status,
            },
        } = this.props;

        return (
            <div className="fc-registry-card__status">
                <NmItemStatus
                    text={statusDict[status]}
                    status={FC_REGISTRIES_STATUS_TO_NM_STATUS[status]}
                />
            </div>
        );
    }

    renderInviteButton() {
        const {
            card,
        } = this.props;

        return (
            isAcceptedAddingContractorsToFcRegistry(this.role, card) &&
            <div
                className="fc-registry-card__func-link"
                onClick={this.goToAddContractorPage}
            >
                <NmIcon
                    className="fc-registry-card__func-link-icon"
                    name="plus-green-bg"
                />
                Добавить исполнителя в реестр
            </div>
        );
    }

    renderInviteToNmButton() {
        const {
            card,
        } = this.props;

        if (!isAcceptedFcRegistryActions(this.role, card)) {
            return null;
        }

        return (
            <div
                className="fc-registry-card__func-link"
                onClick={this.handleChange({openAddContractorForm: true})}
            >
                <NmIcon
                    className="fc-registry-card__func-link-icon"
                    name="green-people"
                />
                Пригласить исполнителя в Наймикс
            </div>
        );
    }

    getSubheaderOptions = () => {
        const {
            card,
        } = this.props;

        if (this.isClientArchived) {
            return;
        }

        const result = [];

        if (isAcceptedAddingContractorsToFcRegistry(this.role, card)) {
            result.push(FC_REGISTRY_CARD_BTN_OPTIONS.INVITE_TO_REGISTRY);
        }

        if (isAcceptedFcRegistryActions(this.role, card)) {
            result.push(FC_REGISTRY_CARD_BTN_OPTIONS.INVITE_TO_NM);
        }

        if (isAcceptedUploadingContactorListToFcRegistry(this.role, card)) {
            result.push(FC_REGISTRY_CARD_BTN_OPTIONS.UPLOAD_LIST);
        }

        return result;
    };

    getSubHeaderDesktopControls() {
        return (
            <div className="flex">
                {!this.isClientArchived && this.renderInviteButton()}
                {!this.isClientArchived && this.renderUploadListButton()}
                {!this.isClientArchived && this.renderInviteToNmButton()}
            </div>
        );
    }

    getSubHeaderTabletAndMobileControls() {

        return (
            <ContextMenu
                className="fc-registry-card__subheader-menu"
                size="xl"
                inline={false}
                options={this.getSubheaderOptions()}
                onClickItem={(option) => {
                    this.onClickHeaderContextMenuBtn(option);
                }}
            />
        );
    }

    renderSubHeader() {
        const {
            totalCount,
        } = this.props;

        return (
            <div className="flex flex-aligned-center mt-sm-7 mt-5 mb-sm-8 mb-4">
                <NmPageHeader
                    text="Исполнители"
                    totalCount={totalCount}
                />
                {
                    this.isAccessItemsActions &&
                    <MediaQueriesRenderer
                        config={{
                            desktop: {
                                render: () => (
                                    this.getSubHeaderDesktopControls()
                                ),
                            },
                            tablet: {
                                render: () => (
                                    this.getSubHeaderTabletAndMobileControls()
                                ),
                            },
                            mobile: {
                                render: () => (
                                    this.getSubHeaderTabletAndMobileControls()
                                ),
                            },
                        }}
                        mediaQueries={{
                            mobile: {maxWidth: 767},
                            tablet: {minWidth: 768, maxWidth: 1199},
                            desktop: {minWidth: 1200},
                        }}
                    />
                }
            </div>
        );
    }

    renderMediaControls = () => {
        const {
            card,
            totalCount,
        } = this.props;

        const options = getFcRegistryCardActionOptions({
            ...card,
            isClientArchived: this.isClientArchived,
            isAccessItemsActions: this.isAccessItemsActions,
        });

        const exportRegistryBtn = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.exportRegistry,
                children: "Выгрузить",
            },
            visible: totalCount !== 0 && isAcceptedFcRegistryExporting(this.role),
        };

        const submitToSigninigBtn = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => this.showActionConfirm(FC_REGISTRY_ACTION.SUBMIT_TO_SIGNING),
                children: "Передать на подписание",
            },
            visible: !this.isClientArchived
                && this.isAccessActions
                && this.isAccessItemsActions
                && isAcceptedSubmittingToSigningFcRegistry(this.role, card),
        };

        const buttons = [exportRegistryBtn, submitToSigninigBtn];

        if (!isEmpty(options) && isAcceptedFcRegistryActions(this.role, card)) {
            options.forEach((option) => {
                buttons.push(
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                this.showActionConfirm(option.value, card);
                            },
                            children: option.text,
                        },
                    },
                );
            });
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            buttons,
        };
    };

    renderMassActionsBlock() {
        const {
            selectedListCounter,
        } = this.state;

        if (this.isClientArchived) {
            return null;
        }

        if (!isAcceptedFcRegistryMassActions(this.role)) {
            return null;
        }

        return (
            <SelectionCountWithAction
                adaptiveLogic
                count={selectedListCounter}
                onClick={this.showDeleteItemsConfirm}
                buttonContent="Удалить строки"
                buttonColor="green"
                otherActions={
                    <NmButton
                        size="lg"
                        color="grey"
                        className="fc-registry-card__mass-button"
                        disabled={selectedListCounter === 0}
                        onClick={this.clearSelection}
                    >
                        Очистить
                    </NmButton>
                }
            />
        );
    }

    renderConfirm() {
        const {progressReplacementsForUnavailable} = this.props;
        const {
            isOpenConfirm,
            dropdownAction,
        } = this.state;

        if (!isOpenConfirm) {
            return null;
        }

        const {
            action,
            text,
            isOnlyConfirm,
            confirmButton,
        } = dropdownAction;

        return (isOpenConfirm &&
            <NmConfirmV2
                content={text}
                onCancel={this.closeActionConfirm}
                onConfirm={action}
                disabled={progressReplacementsForUnavailable}
                confirmButton={confirmButton || "Подтвердить"}
                cancelButton="Отменить"
                isOnlyConfirm={isOnlyConfirm}
            />
        );
    }

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
        });
    };

    onOpenUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: true,
        });
    };

    renderUnavailableTemplates = () => {
        const {actionProgress} = this.props;
        const {
            isOpenUnavailableTemplates,
        } = this.state;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_DOCUMENTS_RD}
                progress={actionProgress}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={this.submitFcRegistryToSigning}
                header="Передача реестра на подписание"
                submitBtnContent="Передать на подписание"
            />
        );
    };

    renderEditForm() {
        const {
            card: {
                ovmRequired,
            },
        } = this.props;
        const {
            isShowEditForm,
            editableItem,
        } = this.state;

        if (!isShowEditForm) {
            return null;
        }

        const {
            contractorId,
            seqNum,
            speciality,
            workAddress,
            ovmUnitName,
            contractorLastName,
            contractorFirstName,
            contractorPatronymic,
            contractorPhone,
            contractorCitizenship,
            contractType,
        } = editableItem;

        return (
            <RegistryFrameContractNotificationData
                registryId={this.registryId}
                clientId={this.clientId}
                close={this.updateState({isShowEditForm: false})}
                isEdit
                onSuccess={() => {
                    this.updateState({isShowEditForm: false})();
                    this.fetchList();
                }}
                contractorObj={{
                    contractorId,
                    seqNum,
                    contractorLastName,
                    contractorFirstName,
                    contractorPatronymic,
                    phone: contractorPhone,
                    contractorCitizenship,
                }}
                notificationObj={{
                    speciality,
                    workAddress,
                    ovmUnitName,
                    contractType,
                }}
                ovmRequired={ovmRequired}
                isIndividualEntrepreneurRegistry={this.isIndividualEntrepreneurRegistry}
            />
        );
    }

    renderFilter() {
        const {
            itemStatusOptions,
        } = this.props;

        return (
            <div className="nm-page__filters-base">
                <FcRegistryCardFilter
                    statusOptions={itemStatusOptions}
                    submitFilter={this.submitFilter}
                />
            </div>
        );
    }

    render() {
        const {
            pageNum,
            pageSize,
            openFilter,
        } = this.state;

        const {
            totalPages = 0,
            listProgress,
            cardProgress,
            actionProgress,
            card: {
                name,
            },
            totalCount,
        } = this.props;

        const isAdminClientCard = isUserFromNm(this.role);

        return (
            <NmPage
                widthByFilter
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                className={`fc-registry-card ${isAdminClientCard ? "mt-15" : ""}`}
                typeFilter="vertical"
                isLoaded={listProgress || cardProgress || actionProgress}
                openFilter={openFilter}
                onOpenFilter={this.openFilter}
                header={
                    <div className="flex flex-aligned-center">
                        <NmPageHeader
                            text={name}
                            handleOnClickBack={this.handleOnClickBack}
                        />
                        {this.renderRegistryStatus()}
                    </div>
                }
                mediaControls={this.renderMediaControls()}
                filtersBase={this.renderFilter()}
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                totalCount={totalCount}
            >
                {this.renderUnavailableTemplates()}
                {this.renderEditForm()}
                {this.renderConfirm()}
                {this.renderAddContractorToNmForm()}
                {this.renderImportRegistriesForm()}
                {this.renderRegistryInfo()}
                {this.renderSubHeader()}
                {this.renderList()}
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        list: fcRegistryItemListSelector(state),
        listProgress: fcRegistryItemListProgressSelector(state),
        totalCount: fcRegistryItemListTotalCountSelector(state),
        totalPages: fcRegistryItemListTotalPagesSelector(state),
        cardProgress: fcRegistryProgressSelector(state),
        actionProgress: fcRegistryActionSelector(state),
        progressReplacementsForUnavailable: documentCustomTemplateProgressReplacementsForUnavailableSelector(state),
        progressImport: fcRegistryItemsImportProgressSelector(state),
        card: fcRegistryCardSelector(state),
        statusDict: fcRegistryStatusDictSelector(state),
        itemStatusDict: fcRegistryItemStatusDictSelector(state),
        itemStatusOptions: fcRegistryItemStatusOptionsSelector(state),
        citizenshipDict: getCitizenshipSelector(state),
        submittingFlags: fcRegistrySubmittingFlagsSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        client: clientCardInfoSelector(state),
        userAvatarDict: avatarBase64ImagesListSelector(state),
        location: state.router.location,
        pathname: state.router.location.pathname,
        registryFilterByTicket: registryFilterByTicketSelector(state),
    }),
    {
        getFcRegistryById,
        getFcRegistryItemsPage,
        getFcRegistryStatusDict,
        getFcRegistryItemStatusDict,
        importItemsFcRegistry,
        exportFcRegistry,
        cancelFcRegistryItem,
        deleteFcRegistryItems,
        getFcRegistrySubmittingFlags,
        submitFcRegistryToSigning,
        resubmitFcRegistryToSigning,
        deleteNotSignedItemsFcRegistry,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        downloadDocument,

    },
)(withTranslation()(FcRegistryCard)));