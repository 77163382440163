import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import Steps from "../../../components/Steps";
import {SmartLinkClientSettings} from "./components/client-settings";
import {SmartLinkCount} from "./components/count";
import {SmartLinkCreateByFilters} from "./components/create-by-filters";
import {SmartLinkCreateByNumbers} from "./components/create-by-numbers";

import {useModal} from "../../../hooks/useModal";

import {getSmartLinkInitialValues} from "./utils/getInitialValues";
import {getSmartLinkRequestData} from "./utils/getRequestData";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {
    SMART_LINK_EDIT_STEP,
    SMART_LINK_EDIT_STEPS,
    SMART_LINK_FILTER_BODY_TYPE,
    SMART_LINK_FORM_TYPE,
} from "./constants";

import {
    createSmartLink,
    editSmartLinkFilterPart,
    getSmartLinkCard,
    saveSmartLinkFilterPart,
    updateSmartLink,
    updateSmartLinkStore,
} from "../../../ducks/smart-link/actionCreators";
import {
    smartLinkCardSelector,
    smartLinkProgressActionSelector,
    smartLinkProgressCardSelector,
} from "../../../ducks/smart-link/selectors";

export const SmartLinkEdit = (props) => {
    const {
        onClose,
        smartLinkId,
        fetchList,
        isDuplicate,
        filterType,
        isEdit,
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(smartLinkProgressCardSelector);
    const progressAction = useSelector(smartLinkProgressActionSelector);
    const card = useSelector(smartLinkCardSelector);
    const [isHiddenModal, setIsHiddenModal] = useState(false);

    const initialValues = getSmartLinkInitialValues({
        card,
        filterType,
        smartLinkId,
    });

    const {
        handleSubmit,
        values,
        touched,
        setValues,
        setFieldValue,
        errors,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: yup.object().shape({
            name: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .min(3, "Минимальная длина - 3 символа")
                .max(150, "Максимальная длина - 150 символов"),
        }),
        validateOnBlur: false,
    });

    const isTask = values.type === SMART_LINK_FORM_TYPE.TASK;

    useEffect(() => {
        if (smartLinkId || isDuplicate) {
            dispatch(getSmartLinkCard({
                smartLinkId,
            }));
        }

        return () => {
            dispatch(updateSmartLinkStore({
                card: {},
                numbers: [],
                clients: [],
                count: 0,
            }));
        };
    }, []);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    function onSubmit(values) {
        const requestData = getSmartLinkRequestData(values);

        if (values.filterType === SMART_LINK_FILTER_BODY_TYPE.NUMBERS) {
            const payload = {
                numberModel: requestData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            };

            if (smartLinkId && !isDuplicate) {
                dispatch(updateSmartLink({
                    smartLinkId: requestData.smartLinkId,
                    ...payload,
                }));

                return;
            }

            dispatch(createSmartLink(payload));

            return;
        }

        if (isEdit || (values.smartLinkId && !isDuplicate)) {
            dispatch(editSmartLinkFilterPart({
                ...requestData,
                onSuccess: () => {
                    setValues({
                        ...values,
                        activeStep: SMART_LINK_EDIT_STEP.CLIENT_SETTINGS,
                    });
                },
            }));

            return;
        }

        dispatch(saveSmartLinkFilterPart({
            ...requestData,
            onSuccess: ({result}) => {
                setValues({
                    ...values,
                    smartLinkId: result,
                    activeStep: SMART_LINK_EDIT_STEP.CLIENT_SETTINGS,
                });
            },
        }));
    }

    const onChange = (event, {name, value}) => {
        if (name === "type") {
            onOpenModal({
                isOpenConfirm: true,
                value,
            });

            return;
        }

        setFieldValue(name, value);
    };

    if (modalData?.isOpenConfirm) {
        return (
            <NmConfirmV2
                mobile="column"
                isHiddenCancelOnMobile
                disabled={progressAction}
                onCancel={onCloseModal}
                onConfirm={() => {
                    const {
                        filterType,
                        name,
                        ...data
                    } = getSmartLinkInitialValues();

                    dispatch(updateSmartLinkStore({
                        count: 0,
                        numbers: [],
                    }));

                    setValues({
                        ...values,
                        ...data,
                        type: modalData.value,
                    });
                    onCloseModal();
                }}
                content="Внесенные ранее данные будут очищены. Вы уверены, что хотите переключить параметр Заказы/Задания?"
                confirmButton="Да"
                cancelButton="Нет"
                isNeedClosing={false}
            />
        );
    }

    const getByNumbers = () => {
        if (values.filterType !== SMART_LINK_FILTER_BODY_TYPE.NUMBERS) {
            return null;
        }

        return (
            <>
                <SmartLinkCreateByNumbers
                    isTask={isTask}
                    type={values.type}
                    nums={values.nums}
                    onChange={onChange}
                    setIsHiddenModal={setIsHiddenModal}
                />
                <SmartLinkCount values={values} />
            </>
        );
    };

    const getByFilters = () => {
        if (values.filterType !== SMART_LINK_FILTER_BODY_TYPE.FILTERS) {
            return null;
        }

        return (
            <SmartLinkCreateByFilters
                values={values}
                onChange={onChange}
            />
        );
    };

    return (
        <>
            {
                values.activeStep === SMART_LINK_EDIT_STEP.CLIENT_SETTINGS &&
                <SmartLinkClientSettings
                    isEdit={isEdit}
                    isDuplicate={isDuplicate}
                    setIsHiddenModal={setIsHiddenModal}
                    values={values}
                    goBack={() => {
                        setFieldValue("activeStep", SMART_LINK_EDIT_STEP.GENERAL_FILTERS);
                    }}
                    fetchList={fetchList}
                    onClose={onClose}
                />
            }
            <NmModal
                size="md"
                onClose={onClose}
                isHiddenModal={isHiddenModal || values.activeStep === SMART_LINK_EDIT_STEP.CLIENT_SETTINGS}
                header={
                    <NmTitle size="lg">
                        {isEdit ? "Редактирование ссылки" : "Создание ссылки"}
                    </NmTitle>
                }
                loading={progress}
                footer={
                    <ApplyButtons
                        isHiddenCancelOnMobile
                        mobile="column"
                        cancelBtnContent="Отменить"
                        submitBtnContent={
                            values.activeStep === SMART_LINK_EDIT_STEP.GENERAL_FILTERS
                            && values.filterType === SMART_LINK_FILTER_BODY_TYPE.FILTERS
                                ? "Дальше"
                                : "Сохранить"
                        }
                        onClose={onClose}
                        submit={handleSubmit}
                        disabled={progressAction}
                    />
                }
            >
                <NmForm addMargin>
                    {
                        values.filterType === SMART_LINK_FILTER_BODY_TYPE.FILTERS &&
                        <Steps
                            withAdditionalParams={true}
                            items={SMART_LINK_EDIT_STEPS}
                            activeStep={values.activeStep}
                        />
                    }
                    {
                        values.activeStep === SMART_LINK_EDIT_STEP.GENERAL_FILTERS &&
                        <>
                            <NmInputV2
                                size="xl"
                                required
                                name="name"
                                value={values.name}
                                onChange={onChange}
                                label="Название"
                                placeholder="Введите название"
                                maxLength={150}
                                error={
                                    touched?.name && errors?.name ?
                                        errors?.name :
                                        undefined
                                }
                            />
                            <div className="d-flex">
                                <NmRadioV2
                                    label="Заказы"
                                    name="type"
                                    disabled={Boolean(smartLinkId)}
                                    checked={values.type === SMART_LINK_FORM_TYPE.ORDER}
                                    value={SMART_LINK_FORM_TYPE.ORDER}
                                    className="me-4"
                                    onChange={onChange}
                                />
                                <NmRadioV2
                                    label="Задания"
                                    name="type"
                                    disabled={Boolean(smartLinkId)}
                                    checked={values.type === SMART_LINK_FORM_TYPE.TASK}
                                    value={SMART_LINK_FORM_TYPE.TASK}
                                    onChange={onChange}
                                />
                            </div>
                            {getByFilters()}
                            {getByNumbers()}
                        </>
                    }
                </NmForm>
            </NmModal>
        </>
    );
};