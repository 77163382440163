import React from "react";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmFilterAccordion from "../../../../../components/NmFilterAccordion";

import {MASK_PHONE} from "../../../../../constants/mask";

const OrderHistoryFilter = (props) => {
    const {
        filter,
        onChangeFilter,
        onSearch,
        onClear,
    } = props;

    const renderFilter = () => {
        return (
            <NmForm
                className="flex flex-wrap align-items-end mb-4"
            >
                <div className="col-16 col-md-8 col-xxl-5">
                    <NmDateRangePickerV2
                        className="mb-2 mb-md-4 mb-xxl-0"
                        size="lg"
                        startFieldName="logStartDateFilter"
                        endFieldName="logEndDateFilter"
                        value={{
                            logStartDateFilter: filter.logStartDateFilter,
                            logEndDateFilter: filter.logEndDateFilter,
                        }}
                        isClearable={true}
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-8 col-xxl-4">
                    <NmInputV2
                        className="ps-xxl-2 ps-md-2 mb-2 mb-md-4 mb-xxl-0"
                        size="lg"
                        name="contractorFioFilter"
                        onChange={onChangeFilter}
                        value={filter.contractorFioFilter || ""}
                        placeholder="ФИО"
                    />
                </div>
                <div className="col-16 col-md-8 col-xxl-4">
                    <NmInputV2
                        className="ps-xxl-2 mb-2 mb-md-0"
                        size="lg"
                        mask={MASK_PHONE}
                        name="contractorPhoneFilter"
                        value={filter.contractorPhoneFilter || ""}
                        placeholder="Телефон"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-8 col-xxl-3">
                    <FilterButtonsV2
                        className="ps-xxl-2 ps-md-2"
                        onSearch={() => onSearch(filter)}
                        onClear={onClear}
                    />
                </div>
            </NmForm>
        );
    };

    return <NmFilterAccordion renderFilter={renderFilter} />;
};

export default OrderHistoryFilter;