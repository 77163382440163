import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";
import {isEmpty} from "lodash";
import {isEqual} from "lodash";

import {MediaButtons} from "../../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import ScrollableContainer from "../../../../../components/ActualComponents/ScrollableContainer";
import Text from "../../../../../components/ActualComponents/Text";
import ButtonBack from "../../../../../components/ButtonBack";
import ExtLink from "../../../../../components/ExtLink";
import NmButtonContact from "../../../../../components/NmButtonContact";
import {ReactComponent as PhoneIcon} from "../../../../../images/phone_24.svg";
import ChatMessage from "../message";

import {useInfiniteScrollPagination} from "../../../../../hooks/useInfiniteScrollPagination";
import {useModal} from "../../../../../hooks/useModal";
import {useFetchMessages} from "./hooks/useFetchMessages";

import {isNMUsers} from "../../../../../utils/access";
import bem from "../../../../../utils/bem";
import {convertUtcToLocal, formatLocalDate, getChatMessageDate} from "../../../../../utils/dateFormat";
import {getFileExtension} from "../../../../../utils/file";
import {
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";
import {scrollToBottom} from "../../../../../utils/scrollToBottom";
import {getFullName, phoneFormat} from "../../../../../utils/stringFormat";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {IMAGE_EXTENSIONS, naimixDefaultMessage} from "../../../../../constants/chats";
import {NM_CLIENT_INFO} from "../../../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../../constants/color";
import {NM_ADMIN_INFO} from "../../../../../constants/contractorInfo";
import {CRM_CHAT_MESSAGE_TYPE} from "../../../../../constants/crm/ticket";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_ORDER_CARD,
} from "../../../../../constants/links";
import {
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    USER_TYPE,
} from "../../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {
    chatListSelector,
    chatMessageListPageNumSelector,
    chatMessageListSelector,
    chatMessageListTotalPagesSelector,
    chatMessagesPageDataSelector,
    crowdChatLock,
    crowdChatMarkUnread,
    crowdChatUnlock,
    currentChatSelector,
    isShowNaimixDefaultMessageSelector,
    sessionMessageUploadingStatusesSelector,
    updateFieldChat,
} from "../../../../../ducks/chat";
import {lockUserChat} from "../../../../../ducks/chatList";
import {
    fileStoreImagesSelector,
    getChatFile,
    progressChatFilesIdsSelector,
} from "../../../../../ducks/fileStore";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../../constants/link-params";

function ChatArea(props) {
    const {
        className,
        classNameMessages = "",
        mobile = false,
        withoutHeader = false,
        isTicketCard = false,
        isCrowd = false,
    } = props;
    const [block, element] = bem("chat-client-area", className);
    const [blockHeader, elementHeader] = bem("chat-client-area-header");

    const chatInfo = useSelector(currentChatSelector);
    const chatList = useSelector(chatListSelector);
    const messagesList = useSelector(chatMessageListSelector);
    const {
        isUpdatedLocal,
    } = useSelector(chatMessagesPageDataSelector);
    const totalPages = useSelector(chatMessageListTotalPagesSelector);
    const pageNum = useSelector(chatMessageListPageNumSelector);
    const isShowNaimixDefaultMessage = useSelector(isShowNaimixDefaultMessageSelector);
    const sessionMessageUploadingStatuses = useSelector(sessionMessageUploadingStatusesSelector);
    const images = useSelector(fileStoreImagesSelector);
    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);
    const progressChatFilesIds = useSelector(progressChatFilesIdsSelector);

    const currentClientId = ls(CURRENT_CLIENT_ID);
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    const role = ls(USER_ROLE);

    const $scrollableContainer = useRef(null);
    const dispatch = useDispatch();

    const {
        isNamemixChat = false,
        chatId,
        contractorId,
        clientId,
        contractorFio,
        orderName,
        orderId,
        orderNum,
        projectId,
        projectName,
        objectId,
        objectName,
        phone,
        managerLastName,
        managerFirstName,
        managerPatronymic,
        orderManagerId,
        locked,
        unreadMessageCount,
        markedUnread,
    } = chatInfo;

    const {
        pageData,
        infiniteScrollProps,
        setPageData,
    } = useInfiniteScrollPagination({
        nextPageNum: pageNum,
        totalPages,
    });

    useFetchMessages({
        chatId,
        contractorId,
        clientId,
        unreadMessageCount,
        isNamemixChat: isNamemixChat || isTicketCard,
        pageData,
        setPageData,
        isCrowd,
        markedUnread,
    });

    const {
        isOpen: isOpenConfirm,
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
    } = useModal();

    const handleCopyContact = value => {
        copy(value);
        toastSuccess("Номер телефона скопирован");
    };

    useEffect(() => {
        getFiles(messagesList);

        if (
            !isUpdatedLocal &&
            $scrollableContainer &&
            $scrollableContainer?.current
        ) {
            const top = $scrollableContainer?.current?.offsetHeight;

            return $scrollableContainer?.current?.scrollTo({top});
        }

        scrollToBottom($scrollableContainer);
    }, [messagesList]);

    const getFiles = list => {
        list.forEach(({messageId, files}) => {
            if (isEmpty(files)) {
                return;
            }

            files.forEach(({fileName, fileUuid, contentUrl}) => {
                const file = images.find(value => (fileUuid && value.fileUuid === fileUuid)) || {};
                const fileExtension = getFileExtension(fileName);

                if (
                    !IMAGE_EXTENSIONS.includes(fileExtension)
                    || !isEmpty(file)
                    || progressChatFilesIds.includes(fileUuid)
                ) {
                    return;
                }

                dispatch(getChatFile({
                    messageId,
                    fileName,
                    fileUuid,
                    contentUrl,
                }, "multiSave"));
            });
        });
    };

    const closeUnreadCrowdChat = ({
        onSuccess = () => {
        },
    }) => {
        dispatch(crowdChatMarkUnread({
            chatId,
            clientId,
            markedUnread: true,
            onSuccess: () => {
                onClickBackToChatList();
            },
        }));
    };

    const sendLockCrowdChat = ({
        onSuccess = () => {
        },
    }) => {
        if (locked) {
            dispatch(crowdChatUnlock({
                chatId,
                clientId,
                onSuccess,
            }));

            return;
        }

        dispatch(crowdChatLock({
            chatId,
            clientId,
            onSuccess,
        }));
    };

    const sendLockUserChat = () => {
        const onSuccess = () => {
            dispatch(updateFieldChat({
                currentChat: {
                    ...chatInfo,
                    locked: !locked,
                },
            }));
        };

        if (isCrowd) {
            onOpenConfirm({
                content: `Вы хотите ${locked ? "разблокировать" : "заблокировать"} чат с исполнителем ${contractorFio}?`,
                onConfirm: () => sendLockCrowdChat({onSuccess}),
            });

            return;
        }

        dispatch(lockUserChat({
            chatId,
            contractorId,
            locked: !locked,
            onSuccess,
        }));
    };

    const onClickBackToChatList = () => {
        dispatch(updateFieldChat({
            currentChat: {},
        }));
    };

    const getButtonBack = () => {
        if (!mobile) {
            return null;
        }

        return (
            <ButtonBack
                onClick={onClickBackToChatList}
                className="me-2 me-md-3"
            />
        );
    };

    const getLink = ({content, link}) => {
        return (
            <ExtLink
                title={content}
                historyEnabled
                to={link}
            >
                {content}
            </ExtLink>
        );
    };

    const getOrderChatDetailInfo = () => {
        if (mobile) {
            return null;
        }

        const orderContent = `№${orderNum} - ${orderName}`;

        return (
            <>
                {
                    projectName &&
                    <NmLabelText
                        type="page"
                        label="Проект"
                        text={
                            (
                                ![HR_MANAGER].includes(role)
                                && projectId
                            )
                                ? getLink({
                                    content: projectName,
                                    link: LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
                                        .replace(":clientId", clientId)
                                        .replace(":projectId", projectId),
                                })
                                : projectName
                        }
                    />
                }
                {
                    objectName &&
                    <NmLabelText
                        type="page"
                        label="Объект"
                        text={
                            (
                                ![HR_MANAGER].includes(role)
                                && objectId
                            )
                                ? getLink({
                                    content: objectName,
                                    link: LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
                                        .replace(":clientId", clientId)
                                        .replace(":projectId", projectId)
                                        .replace(":objectId", objectId),
                                })
                                : objectName
                        }
                    />
                }
                {
                    orderName &&
                    <NmLabelText
                        type="page"
                        label="Заказ"
                        text={
                            (
                                ![HR_MANAGER].includes(role)
                                && orderId
                            )
                                ? getLink({
                                    content: orderContent,
                                    link: LINK_ORDER_CARD
                                        .replace(":clientId", clientId)
                                        .replace(":orderId", orderId)
                                        .replace(":page", SELF_CONTRACTOR)
                                        .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK),
                                })
                                : orderContent
                        }
                    />
                }
                {
                    managerLastName &&
                    <NmLabelText
                        type="page"
                        label="Ответственный"
                        text={getFullName(managerLastName, managerFirstName, managerPatronymic)}
                    />
                }
            </>
        );
    };

    function renderHeader() {
        if (withoutHeader) {
            return null;
        }

        if (isNamemixChat) {
            return (
                <div className={blockHeader()}>
                    {getButtonBack()}
                    <div className={elementHeader("content")}>
                        <Text
                            level={5}
                            bold
                        >
                            Служба поддержки Наймикс
                        </Text>
                        <Text
                            level={4}
                            className={elementHeader("sub-text")}
                        >
                            Поддержка 24/7
                        </Text>
                    </div>
                    <div>
                        <NmButtonContact
                            icon={<PhoneIcon />}
                            popup={phoneFormat(phone)}
                            onClick={() => {
                                handleCopyContact(phone);
                            }}
                        />
                    </div>
                </div>
            );
        }

        const contractorLink = role === HR_MANAGER
            ? LINK_CONTRACTOR_REVIEWS_LIST
            : LINK_CONTRACTOR_PROFILE;

        return (
            chatId &&
            <div className={blockHeader()}>
                {getButtonBack()}
                <div className={elementHeader("content")}>
                    <Text
                        color={locked && COLOR.NEGATIVE_100}
                        level={5}
                        bold
                    >
                        {
                            isCrowd
                                ? contractorFio
                                : getLink({
                                    content: contractorFio,
                                    link: contractorLink.replace(":contractorId", contractorId),
                                })
                        }
                        {locked ? " - Чат заблокирован" : ""}
                    </Text>
                    {getOrderChatDetailInfo()}
                </div>
                <MediaButtons
                    className={element("header-media-controls")}
                    config={{
                        renderCount: {
                            desktop: 2,
                            tablet: 2,
                            mobile: 0,
                        },
                        size: "xl",
                        buttons: [
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    color: "grey",
                                    size: "xl",
                                    onClick: closeUnreadCrowdChat,
                                    children: "Закрыть непрочитанным",
                                },
                                visible: isCrowd,
                            },
                            {
                                component: COMPONENT.BUTTON_CONTACT,
                                props: {
                                    onClick: () => handleCopyContact(phone),
                                    icon: <PhoneIcon />,
                                    color: "grey",
                                    onlyIcon: true,
                                    size: "xl",
                                    popup: phoneFormat(phone),
                                },
                                asset: {
                                    mobile: {
                                        children: "Скопировать телефон",
                                    },
                                },
                                visible: Boolean(phone)
                                    && (isNMUsers() || canViewContractorContacts),
                            },
                            {
                                component: COMPONENT.BUTTON,
                                props: {
                                    onClick: sendLockUserChat,
                                    children: locked ? "Разблокировать" : "Заблокировать",
                                },
                                visible: orderManagerId === currentUserId
                                    || (
                                        isCrowd
                                        && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)
                                        && isAccessByRestrictions([
                                            CLIENT_USER_RESTRICTIONS_VARIABLE.blockCrowdTaskChat,
                                        ])
                                    ),
                            },
                        ],
                    }}
                />
            </div>
        );
    }

    const renderDateDivider = (item) => {
        return (
            isEqual(item, messagesList.filter(value => formatLocalDate(value.dateTimeCreate, "dd.MM.yyyy") === formatLocalDate(item.dateTimeCreate, "dd.MM.yyyy"))[0]) &&
            <div className={element("date-divider")}>
                <hr />
                <Text
                    level="2"
                    color={COLOR.SECONDARY_70}
                    className={element("date-divider-value")}
                >
                    {getChatMessageDate(item.dateTimeCreate)}
                </Text>
                <hr />
            </div>
        );
    };

    function renderMessages() {
        const list = [
            isShowNaimixDefaultMessage && isNamemixChat && naimixDefaultMessage,
            ...messagesList,
        ].filter(item => Boolean(item));

        if (!list.length && (currentUserId === orderManagerId || isCrowd)) {
            return (
                <div className={element("empty-dialog")}>
                    <Text
                        level="5"
                        color={COLOR.SECONDARY_45}
                    >
                        {
                            isCrowd && !chatList.length ?
                                "Начните чат с исполнителем из карточки задания" :
                                "Начните чат с исполнителем"
                        }
                    </Text>
                </div>
            );
        }

        return list.map((item, index) => {
            const {
                content,
                dateTimeCreate,
                firstName,
                fullName,
                clientId,
                clientUserId,
                read,
                partiesId,
                partiesType,
                files,
                messageId,
                temporaryMessageId,
                messageType,
            } = item;

            const userName = partiesId === NM_ADMIN_INFO.ID ? NM_CLIENT_INFO.NAME : firstName || fullName;
            const isNotificationMessage = isTicketCard && [
                CRM_CHAT_MESSAGE_TYPE.PAID_PAYMENT_NOTIFICATION_FOR_CONTRACTOR,
            ].includes(messageType);

            return (
                <div className={element("message")}>
                    {dateTimeCreate && renderDateDivider(item)}
                    <ChatMessage
                        contractorId={contractorId}
                        dateTime={temporaryMessageId ? dateTimeCreate : convertUtcToLocal(dateTimeCreate)}
                        message={content}
                        outgoing={
                            !isNotificationMessage &&
                            currentClientId === clientId ||
                            partiesId === currentUserId
                        }
                        isRenderAvatar={partiesId !== list[index - 1]?.partiesId}
                        userFirstName={userName}
                        isNaimixUser={
                            clientId === NM_CLIENT_INFO.ID
                            || partiesType === USER_TYPE.NAIMIX_USER.VALUE
                        }
                        isClientUser={
                            partiesType === USER_TYPE.CLIENT_USER.VALUE
                            || (
                                Boolean(clientUserId)
                                && clientId !== NM_CLIENT_INFO.ID
                            )
                        }
                        read={read}
                        files={files}
                        isLoading={
                            !messageId &&
                            !sessionMessageUploadingStatuses[temporaryMessageId]
                        }
                        isError={
                            !messageId &&
                            sessionMessageUploadingStatuses[temporaryMessageId]?.isError
                        }
                        isNotificationMessage={isNotificationMessage}
                    />
                </div>
            );
        });
    }

    const renderConfirmModal = () => {
        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={confirmData.content}
                onCancel={onCloseConfirm}
                onConfirm={confirmData.onConfirm}
                confirmButton="Да"
                cancelButton="Отмена"
            />
        );
    };

    return (
        <div className={block()}>
            {renderConfirmModal()}
            {renderHeader()}
            <ScrollableContainer
                ref={$scrollableContainer}
                className={`${element("messages")} ${classNameMessages}`}
            >
                {
                    infiniteScrollProps &&
                    <div {...infiniteScrollProps} />
                }
                {renderMessages()}
            </ScrollableContainer>
        </div>
    );
}

export default ChatArea;