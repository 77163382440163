import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmModal from "../../../../components/ActualComponents/NmModal";
import Tabs from "../../../../components/ActualComponents/Tabs";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as ArchiveIcon} from "../../../../images/archive.svg";
import EdfStaffCardRoute from "../edm-staff-card-route";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAcceptedViewStaffDocuments, isAcceptedViewStaffPersonalData} from "../../../../utils/user-rights/edmStaff";

import {STAFF_STATUS, STAFF_STATUS_DESCRIPTION} from "../../../../constants/edmStaff";
import {
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_APPS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_PERS_DATA,
} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

import {edoAccessListSelector} from "../../../../ducks/bff/clients/edo/documents/selectors";
import {archiveEdmStaff, getEdmStaffById} from "../../../../ducks/bff/clients/edo/staff/actionCreators";
import {edmStaffActionProgressSelector, edmStaffCardSelector} from "../../../../ducks/bff/clients/edo/staff/selectors";
import {getCountsEdoByStaffId} from "../../../../ducks/bff/clients/edo/statements/actionCreators";
import {edoCountsByStaffSelector} from "../../../../ducks/bff/clients/edo/statements/selectors";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

class EdmStaffCardCommon extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    staffId,
                },
            },
        } = this.props;

        this.clientId = clientId;
        this.staffId = staffId;

        this.state = {
            isShowArchivingConfirm: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.fetchStaff();
        this.getCountsEdoByStaffId();
    }

    getCountsEdoByStaffId() {
        const {getCountsEdoByStaffId} = this.props;
        const {staffId, clientId} = this;

        getCountsEdoByStaffId({
            staffId,
            clientId,
        });
    }

    fetchStaff = () => {
        const {
            getEdmStaffById,
        } = this.props;

        getEdmStaffById({
            data: {
                clientId: this.clientId,
                staffId: this.staffId,
            },
        });
    };

    get link() {
        const documentListLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS.replace(":clientId", this.clientId).replace(":staffId", this.staffId);
        const appsListLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_APPS.replace(":clientId", this.clientId).replace(":staffId", this.staffId);
        const pdLink = LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_PERS_DATA.replace(":clientId", this.clientId).replace(":staffId", this.staffId);

        return {
            documentListLink,
            appsListLink,
            pdLink,
        };
    }

    get links() {
        const {
            documentListLink,
            appsListLink,
            pdLink,
        } = this.link;

        const {
            edoAccessList,
            pathname,
            edoCountsByStaff: {
                documentsCount,
                statementsCount,
            },
            clientProperties: {
                personalDataAccessGranted,
            },
        } = this.props;

        const POSSIBLE_TABS = {
            DOCUMENT_TAB: {
                active: pathname.includes(documentListLink),
                link: documentListLink,
                name: "Документы",
            },
            APPS_TAB: {
                active: pathname.includes(appsListLink),
                link: appsListLink,
                name: "Заявления",
            },
            PERSONAL_DATA_TAB: {
                active: pathname.includes(pdLink),
                link: pdLink,
                name: "Персональные данные",
            },
        };

        const tabs = [];

        if (isAcceptedViewStaffDocuments(edoAccessList)) {
            tabs.push(POSSIBLE_TABS.DOCUMENT_TAB);
            tabs.push(POSSIBLE_TABS.APPS_TAB);
        }

        if (isAcceptedViewStaffPersonalData(edoAccessList) && personalDataAccessGranted) {
            tabs.push(POSSIBLE_TABS.PERSONAL_DATA_TAB);
        }

        return tabs;
    }

    handleOnClickBack = () => {
        history.push(LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL.replace(":clientId", this.clientId));
    };

    showArchiveConfirm = (isShow) => {
        return () => {
            this.setState({
                isShowArchivingConfirm: isShow,
            });
        };
    };

    archiveStaff = (archive) => {
        const {
            staff: {
                staffId,
            },
            archiveEdmStaff,
        } = this.props;

        return () => {

            archiveEdmStaff({
                data: {
                    clientId: this.clientId,
                    staffId,
                    archive,
                },
                onSuccess: () => {
                    this.showArchiveConfirm(false)();
                    this.fetchStaff();
                },
            });
        };
    };

    renderHeader() {
        const {
            staff: {
                fio,
                status,
                positionName,
            },
        } = this.props;

        return (
            <NmPageHeader
                text={fio}
                buttonBackSize="xl"
                status={STAFF_STATUS_DESCRIPTION[status]}
                subTitle={positionName}
                handleOnClickBack={this.handleOnClickBack}
            />
        );
    };

    renderArchiveStaffConfirm() {
        const {
            staff: {
                status,
                fio,
            },
        } = this.props;

        const {
            isShowArchivingConfirm,
        } = this.state;

        const isStaffWorking = STAFF_STATUS.WORKING === status;

        return (
            isShowArchivingConfirm &&
                <NmModal
                    onClose={this.showArchiveConfirm(false)}
                    header={
                        <NmTitle size="lg">
                            {`Вы действительно хотите ${isStaffWorking ? "архивировать" : "восстановить из архива"} сотрудника ${fio}?`}
                        </NmTitle>
                    }
                    footer={
                        <ApplyButtons
                            submit={this.archiveStaff(isStaffWorking)}
                            onClose={this.showArchiveConfirm(false)}
                            submitBtnContent="Да"
                            cancelBtnContent="Нет"
                        />
                    }
                    children={null}
                />
        );
    };

    renderControls() {
        const {
            staff: {
                status,
            },
        } = this.props;

        const isStaffWorking = STAFF_STATUS.WORKING === status;

        return (
            <NmButton
                size="xl"
                color="white"
                onClick={this.showArchiveConfirm(true)}
                icon={
                    <ArchiveIcon
                        className={
                            isStaffWorking ?
                                "edf-staff-card-common__archive" :
                                "edf-staff-card-common__archive edf-staff-card-common__archive_rotated"
                        }
                    />
                }
            >
                {isStaffWorking ? "Архивировать" : "Из архива"}
            </NmButton>
        );
    };

    render() {
        const {
            actionProgress,
        } = this.props;

        return (
            <div className="edf-staff-card-common">
                <NmPage
                    header={this.renderHeader()}
                    controls={this.renderControls()}
                    isLoaded={actionProgress}
                >
                    {this.renderArchiveStaffConfirm()}
                    <Tabs
                        className="mb-4 mb-md-5"
                        panes={this.links}
                    />
                    <EdfStaffCardRoute
                        {...this.props}
                    />
                </NmPage>
            </div>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        staff: edmStaffCardSelector(state),
        edoAccessList: edoAccessListSelector(state),
        actionProgress: edmStaffActionProgressSelector(state),
        edoCountsByStaff: edoCountsByStaffSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
    }),
    {
        getEdmStaffById,
        archiveEdmStaff,
        getCountsEdoByStaffId,
    },
)(withTranslation()(EdmStaffCardCommon));
