import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as BlockedIcon} from "../../../../images/lock_24.svg";

import {phoneFormat} from "../../../../utils/stringFormat";
import {toastSuccess} from "../../../../utils/toastHelper";
import validate from "../../../../utils/validate";

import {COLOR} from "../../../../constants/color";
import {EDM_STAFF_PRIVILEGES, NEW_STAFF_FORM_TYPE} from "../../../../constants/edmStaff";
import {edmSelectStaffFormRule} from "../../../../constants/validationRules";

import {getEdmPositionList} from "../../../../ducks/bff/clients/edo/directory/positions/actionCreators";
import {edmPositionListOptionsSelector} from "../../../../ducks/bff/clients/edo/directory/positions/selectors";
import {getEdmSubdivisionsList} from "../../../../ducks/bff/clients/edo/directory/subdivisions/actionCreators";
import {edmSubdivisionsOptionsSelector} from "../../../../ducks/bff/clients/edo/directory/subdivisions/selectors";
import {addEdmStaff, updateEdmStaff} from "../../../../ducks/bff/clients/edo/staff/actionCreators";

import "./style.sass";

const EdmStaffNewFinishingForm = (props) => {
    const {
        onCancel,
        onBackStepClick,
        type,
        user,
        preloader,
        clientId,
        isEdit,
    } = props;

    useEffect(() => {
        dispatch(getEdmPositionList({
            clientId,
            pageNum: 1,
            pageSize: 1000,
        }));
        dispatch(getEdmSubdivisionsList({
            clientId,
            pageNum: 1,
            pageSize: 1000,
        }));
    }, []);

    const [form, setForm] = useState({
        positionId: user.positionId,
        subdivisionId: user.subdivisionId,
        edmPrivileges: user.edmPrivileges || [EDM_STAFF_PRIVILEGES.RECEIVE_DOCUMENT],
    });

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const positionOptions = useSelector(edmPositionListOptionsSelector);
    const subdivisionsOptions = useSelector(edmSubdivisionsOptionsSelector);

    const isClientUser = type === NEW_STAFF_FORM_TYPE.USER;

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const isFormValid = () => {
        const formError = validate(form, edmSelectStaffFormRule, "");

        setErrors(formError);

        return Object.values(formError).length === 0;
    };

    const submitEdmStaff = () => {
        if (!isFormValid()) {
            return;
        }

        const {
            contractorId,
            clientUserId,
        } = user;

        const reqData = {
            clientId,
            contractorId: isClientUser ? undefined : contractorId,
            clientUserId: isClientUser ? clientUserId : undefined,
            staffId: user.staffId,
            ...form,
        };

        if (isEdit) {
            dispatch(updateEdmStaff({
                data: reqData,
                onSuccess: () => {
                    toastSuccess("Данные сотрудника успешно обновлены");
                    onCancel(true);
                },
            }));
            return;
        }
        dispatch(addEdmStaff({
            data: reqData,
            onSuccess: () => {
                onCancel(true);
            },
        }));
    };

    const handleCheckBox = (e, {name, checked}) => {
        let privileges = [...form.edmPrivileges];
        if (checked) {
            privileges.push(name);
        } else {
            privileges = form.edmPrivileges.filter(item => item !== name);
        }
        setForm({
            ...form,
            edmPrivileges: privileges,
        });
    };

    const renderPriveleges = () => {
        return (
            <div>
                <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                >
                    Может быть выбран в качестве
                </Text>
                {
                    isClientUser && <NmCheckboxV2
                        info
                        tooltipText="Установите, если сотрудник может подписывать документы со стороны юридического лица"
                        className="mt-2"
                        onChange={handleCheckBox}
                        label="Отправителя документа"
                        helpTooltipType="right"
                        name={EDM_STAFF_PRIVILEGES.SEND_DOCUMENT}
                        checked={form.edmPrivileges.includes(EDM_STAFF_PRIVILEGES.SEND_DOCUMENT)}
                    />
                }
                <NmCheckboxV2
                    info
                    tooltipText="Установите, если сотрудник может быть выбран получателем при загрузке документа (для подписания документа)"
                    className="mt-2"
                    onChange={handleCheckBox}
                    label="Получателя документа"
                    helpTooltipType="right"
                    name={EDM_STAFF_PRIVILEGES.RECEIVE_DOCUMENT}
                    checked={form.edmPrivileges.includes(EDM_STAFF_PRIVILEGES.RECEIVE_DOCUMENT)}
                />
                <NmCheckboxV2
                    info
                    tooltipText="Установите, если сотрудник может быть выбран согласующим при подаче заявления"
                    className="mt-2"
                    onChange={handleCheckBox}
                    label="Cогласующего заявления"
                    name={EDM_STAFF_PRIVILEGES.APPROVE_STATEMENT}
                    checked={form.edmPrivileges.includes(EDM_STAFF_PRIVILEGES.APPROVE_STATEMENT)}
                />
                {
                    isClientUser &&
                    <NmCheckboxV2
                        info
                        tooltipText="Установите, если сотрудник может подписывать заявления со стороны юридического лица"
                        className="mt-2"
                        onChange={handleCheckBox}
                        label="Получателя заявления"
                        helpTooltipType="right"
                        name={EDM_STAFF_PRIVILEGES.RECEIVE_STATEMENT}
                        checked={form.edmPrivileges.includes(EDM_STAFF_PRIVILEGES.RECEIVE_STATEMENT)}
                    />
                }
            </div>
        );
    };

    const renderInfo = () => {
        const {
            fio,
            phone,
            blocked,
        } = user;

        return (
            <>
                <div className="d-flex">
                    <Text.Title
                        level="4"
                        bold
                        color={COLOR.SECONDARY_100}
                    >
                        {fio}
                    </Text.Title>
                    {
                        blocked &&
                        <BlockedIcon
                            className="ml-2"
                            color="red"
                        />
                    }
                </div>
                <Text
                    level="3"
                    color={COLOR.SECONDARY_70}
                    className="mb-3 mb-md-4"
                >
                    {phoneFormat(phone)}
                </Text>
                <NmForm addMargin>
                    <NmDropdownV2
                        label="Должность"
                        error={errors.positionId}
                        onChange={onChange}
                        name="positionId"
                        tooltip={
                            <HelpTooltip
                                info
                                position="bottom-left"
                                hover
                            >
                                Выберите должность сотрудника из сформированного в рамках Вашей компании справочника должностей
                            </HelpTooltip>
                        }
                        value={form.positionId}
                        options={positionOptions}
                    />
                    <NmDropdownV2
                        label="Структурное подразделение компании"
                        error={errors.subdivisionId}
                        onChange={onChange}
                        name="subdivisionId"
                        value={form.subdivisionId}
                        options={subdivisionsOptions}
                        tooltip={
                            <HelpTooltip
                                info
                                position="bottom-left"
                                hover
                            >
                                "Наименование производственного подразделения, в которое направляется сотрудник"
                            </HelpTooltip>
                        }
                    />
                    {renderPriveleges()}
                </NmForm>
            </>
        );
    };

    const renderFooter = () => {
        return (
            <div className={`flex ${isEdit ? "flex-content-end" : "flex-content-spaced"} fluid`}>
                {!isEdit && <NmButton
                    size="xl"
                    color="white"
                    onClick={onBackStepClick}
                >
                    Назад
                </NmButton>}
                <ApplyButtons
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={submitEdmStaff}
                    onClose={onCancel}
                />
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onCancel}
            className="edm-staff-new-finishing-form"
            header={
                <NmTitle size="lg">
                    {isEdit ? "Редактирование данных о сотруднике" : "Заполнение данных о сотруднике"}
                </NmTitle>
            }
            classNameContent="edm-staff-new-finishing-form__content"
            footer={renderFooter()}
        >
            {preloader}
            {renderInfo()}
        </NmModal>
    );
};

export default EdmStaffNewFinishingForm;