import {useDispatch, useSelector} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {
    toastError,
    toastSuccess,
} from "../../../../utils/toastHelper";

import {FULL_REGISTRATION_STEP} from "../../../../components/FullRegistration/constants";
import {CONTRACTOR_FILE_TYPES} from "../../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {CONTRACTOR_FIELD, CONTRACTOR_TAX_STATUS} from "../../../../constants/contractor";
import {
    CONTRACTOR_SEGMENT_INFO_TYPE,
    FNS_ERRORS,
    FNS_SUCCESS_TEXT,
} from "../../../../constants/contractorInfo";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../constants/roles";

import {getInfoContractorCard} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {refreshContractorTaxStatus} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    contractorActionProgressSelector,
} from "../../../../ducks/contractor";

export const useContractorInnCard = (params) => {
    const {
        card,
    } = params;
    const dispatch = useDispatch();
    const role = ls(USER_ROLE);

    const progress = useSelector(contractorActionProgressSelector);

    const isAccessCheckStatus = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.checkStatusSmz,
    ]);

    const {
        isFakeInn,
        contractorId,
    } = card;

    const requestForPartnership = () => {
        dispatch(refreshContractorTaxStatus({
            contractorId,
            requestPartnership: true,
            onSuccess: () => {
                toastSuccess("Запрос на привязку к платформе успешно создан");
            },
        }));
    };

    const updateNpdStatus = () => {
        dispatch(refreshContractorTaxStatus({
            contractorId,
            getResult: ({taxStatus}) => {
                if (taxStatus === CONTRACTOR_TAX_STATUS.OK) {
                    toastSuccess(FNS_SUCCESS_TEXT);
                    dispatch(getInfoContractorCard(contractorId));

                    return;
                }

                toastError(
                    !taxStatus ?
                        "Исполнитель с данным ИНН не найден в ФНС" :
                        FNS_ERRORS[taxStatus].TEXT,
                );
            },
        }));
    };

    return {
        innCard: {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.INN,
            registrationStep: FULL_REGISTRATION_STEP.INN,
            title: "ИНН",
            labels: [
                {label: "Номер", text: card[CONTRACTOR_FIELD.INN]},
            ],
            scans: [
                {
                    label: "Лицевая сторона ИНН",
                    type: CONTRACTOR_FILE_TYPES.INN,
                },
            ],
            editButtonAssets: {
                tablet: {
                    children: "Редактировать",
                },
            },
            mediaControlsButtons: [
                {
                    onClick: updateNpdStatus,
                    size: "sm",
                    color: "grey",
                    children: "Проверить статус НПД",
                    disabled: progress,
                    visible: !isFakeInn
                        && isAccessCheckStatus
                        && [
                            ADMIN,
                            NM_MANAGER,
                            NM_COORDINATOR,
                            NM_CHIEF_ACCOUNTANT,
                            NM_OPERATOR,
                        ].includes(role),
                },
                {
                    onClick: requestForPartnership,
                    size: "sm",
                    color: "grey",
                    children: "Запрос на партнерство",
                    disabled: progress,
                    visible: !isFakeInn
                        && isAccessCheckStatus
                        && [
                            ADMIN,
                            NM_MANAGER,
                            NM_COORDINATOR,
                            NM_CHIEF_ACCOUNTANT,
                            NM_OPERATOR,
                        ].includes(role),
                },
            ],
            mediaControlsRenderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 3,
            },
            isVisible: true,
        },
    };
};