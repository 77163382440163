import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

const controller = "/adm/ndfl-deposit";

const CIVIL_UPDATE_STORE = "CIVIL_UPDATE_STORE";

const CIVIL_CLIENT_DEPOSIT_GET_PAGE_REQUEST = "CIVIL_CLIENT_DEPOSIT_GET_PAGE_REQUEST";
const CIVIL_CLIENT_DEPOSIT_GET_PAGE_SUCCESS = "CIVIL_CLIENT_DEPOSIT_GET_PAGE_SUCCESS";
const CIVIL_CLIENT_DEPOSIT_GET_PAGE_ERROR = "CIVIL_CLIENT_DEPOSIT_GET_PAGE_ERROR";

const CIVIL_ADD_CLIENT_DEPOSIT_REQUEST = "CIVIL_ADD_CLIENT_DEPOSIT_REQUEST";
const CIVIL_ADD_CLIENT_DEPOSIT_SUCCESS = "CIVIL_ADD_CLIENT_DEPOSIT_SUCCESS";
const CIVIL_ADD_CLIENT_DEPOSIT_ERROR = "CIVIL_ADD_CLIENT_DEPOSIT_ERROR";

const CIVIL_RETURN_CLIENT_DEPOSIT_REQUEST = "CIVIL_RETURN_CLIENT_DEPOSIT_REQUEST";
const CIVIL_RETURN_CLIENT_DEPOSIT_SUCCESS = "CIVIL_RETURN_CLIENT_DEPOSIT_SUCCESS";
const CIVIL_RETURN_CLIENT_DEPOSIT_ERROR = "CIVIL_RETURN_CLIENT_DEPOSIT_ERROR";

const initial = {
    depositValues: {},
    pageData: {},
    totalCount: 0,
    list: [],
    progress: false,
    progressAction: false,
    commissionHistoryModels: [],
    commissionHistoryTotalCount: 0,
    commissionHistoryPageData: {},
    progressCommissionHistory: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CIVIL_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case CIVIL_CLIENT_DEPOSIT_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CIVIL_CLIENT_DEPOSIT_GET_PAGE_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                progress: false,
            };
        case CIVIL_CLIENT_DEPOSIT_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CIVIL_RETURN_CLIENT_DEPOSIT_REQUEST:
        case CIVIL_ADD_CLIENT_DEPOSIT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CIVIL_ADD_CLIENT_DEPOSIT_SUCCESS:
        case CIVIL_RETURN_CLIENT_DEPOSIT_SUCCESS:
        case CIVIL_ADD_CLIENT_DEPOSIT_ERROR:
        case CIVIL_RETURN_CLIENT_DEPOSIT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        default:
            return state;
    }
};

// админский список депозит НДФЛ
export const getCivilDepositList = (payload) => ({
    type: CIVIL_CLIENT_DEPOSIT_GET_PAGE_REQUEST,
    payload,
});

export const updateCivilStore = (payload) => ({
    type: CIVIL_UPDATE_STORE,
    payload,
});

// пополнить депозит
export const addCivilClientDeposit = (payload) => ({
    type: CIVIL_ADD_CLIENT_DEPOSIT_REQUEST,
    payload,
});

// возврат депозита
export const returnCivilClientDeposit = (payload) => ({
    type: CIVIL_RETURN_CLIENT_DEPOSIT_REQUEST,
    payload,
});

export const civilClientDepositSelector = state => state.civil;
export const civilProgressActionSelector = createSelector(civilClientDepositSelector, ({progressAction}) => progressAction);

// депозит НДФЛ
export const civilDepositListSelector = createSelector(civilClientDepositSelector, ({list}) => list);
export const civilDepositProgressListSelector = createSelector(civilClientDepositSelector, ({progress}) => progress);
export const civilDepositListTotalPagesSelector = createSelector(civilClientDepositSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const civilDepositListTotalCountSelector = createSelector(civilClientDepositSelector, ({totalCount}) => totalCount);

//POST /bff/adm/ndfl-deposit/getPage
export const getCivilDepositListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CIVIL_CLIENT_DEPOSIT_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CIVIL_CLIENT_DEPOSIT_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CIVIL_CLIENT_DEPOSIT_GET_PAGE_ERROR, payload: error});
    }
};

//POST /bff/adm/ndfl-deposit/addDeposit
export const addCivilClientDepositSaga = function* (action) {
    try {
        const {
            onSuccess,
            ...reqData
        } = action.payload;

        const result = yield request.bff.post(`${controller}/addDeposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CIVIL_ADD_CLIENT_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Депозит успешно добавлен");

        yield put({type: CIVIL_ADD_CLIENT_DEPOSIT_SUCCESS});
    } catch (error) {
        toastError(error.message);

        yield put({type: CIVIL_ADD_CLIENT_DEPOSIT_ERROR, payload: error});
    }
};

//POST /bff/adm/npd-deposit/returnDeposit
export const returnCivilClientDepositSaga = function* (action) {
    try {
        const {
            onSuccess,
            ...reqData
        } = action.payload;

        const result = yield request.bff.post(`${controller}/returnDeposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CIVIL_RETURN_CLIENT_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Возврат средств проведен успешно");

        yield put({type: CIVIL_RETURN_CLIENT_DEPOSIT_SUCCESS});
    } catch (error) {
        toastError(error.message);

        yield put({type: CIVIL_RETURN_CLIENT_DEPOSIT_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CIVIL_CLIENT_DEPOSIT_GET_PAGE_REQUEST, getCivilDepositListSaga),
        takeEvery(CIVIL_ADD_CLIENT_DEPOSIT_REQUEST, addCivilClientDepositSaga),
        takeEvery(CIVIL_RETURN_CLIENT_DEPOSIT_REQUEST, returnCivilClientDepositSaga),
    ]);
}