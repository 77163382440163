import {
    BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST,
    BFF_CONTRACTOR_READ_REQUEST,
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    CONTRACTOR_ADD_FAVORITE_REQUEST,
    CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
    CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
    CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
    CONTRACTOR_SHORT_LIST_READ_REQUEST,
    ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST,
    UPDATE_CONTRACTOR_REGISTRY_STORE,
} from "./actions";

export const getPageContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
        payload,
    };
};

export const importContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
        payload,
    };
};

export const addContractorsRegistry = (payload) => {
    return {
        type: BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
        payload,
    };
};

export const deleteContractorFromBlackList = (payload) => {
    return {
        type: CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
        payload,
    };
};

export const deleteFavoriteContractors = (payload) => {
    return {
        type: CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
        payload,
    };
};

export const addFavoriteContractors = (payload) => {
    return {
        type: CONTRACTOR_ADD_FAVORITE_REQUEST,
        payload,
    };
};

export const addContractorToBlackList = (payload) => {
    return {
        type: CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
        payload,
    };
};

export const addSeveralOrderContractors = (payload) => {
    return {
        type: ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST,
        payload,
    };
};

export const getContractorShortList = (payload) => {
    return {
        type: CONTRACTOR_SHORT_LIST_READ_REQUEST,
        payload,
    };
};

export const getContractorCardById = (payload) => {
    return {
        type: BFF_CONTRACTOR_READ_REQUEST,
        payload,
    };
};

export const bbfGetContractorByIds = (payload) => {
    return {
        type: BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST,
        payload,
    };
};

export const updateStoreContractorsRegistry = (payload) => {
    return {
        type: UPDATE_CONTRACTOR_REGISTRY_STORE,
        payload,
    };
};