import React from "react";
import {useDispatch} from "react-redux";

import Text from "../../../../ActualComponents/Text";
import JobBoardLogo from "../../../../JobBoardLogo";
import {ExternalResumeLink} from "../../../ExternalResumeLink";

import {getClassNames} from "../../../../../utils/classNames";

import {COLOR} from "../../../../../constants/color";
import {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../../../../containers/recruitment/vacancies/constants";

import {downloadDocument} from "../../../../../ducks/documents";

export const RecruitmentCandidateResumeLink = (props) => {
    const {
        className = "",
        isName,
        fileName,
        candidateId,
        responseId,
        type,
        name,
        isDownload = true,
        comment,
        originalUrl,
        downloadFileLink,
    } = props;

    const dispatch = useDispatch();

    const onClickDownload = () => {
        if (responseId) {
            dispatch(downloadDocument({
                isDownload,
                downloadLink: `/api/filestore/recruitment/response/files/jobBoard/resume/get?responseId=${responseId}&fileName=${fileName}`,
            }));

            return;
        }

        dispatch(downloadDocument({
            isDownload,
            downloadLink: downloadFileLink
                ? downloadFileLink
                : `/api/filestore/recruitment/candidate/get?candidateId=${candidateId}&fileName=${fileName}`,
        }));
    };

    return (
        <div
            style={{
                overflow: "hidden",
                flexWrap: "wrap",
            }}
            className={getClassNames([
                "d-flex align-items-center",
                className,
            ])}
        >
            {
                type &&
                <JobBoardLogo
                    width={24}
                    height={24}
                    style={{minWidth: 24}}
                    name={type}
                    className="me-2"
                />
            }
            {
                isName &&
                <Text
                    color={COLOR.PASSIVE_100}
                    level="3"
                    noWrap={true}
                    className="me-md-2"
                    onClick={onClickDownload}
                    title={isDownload ? "Скачать" : "Открыть в новой вкладке"}
                >
                    {
                        name
                            ? name
                            : RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[type]
                    }
                </Text>
            }
            {
                originalUrl &&
                <ExternalResumeLink originalUrl={originalUrl} />
            }
            {
                comment &&
                <Text
                    color={COLOR.SECONDARY_80}
                    level="3"
                >
                    {`(${comment})`}
                </Text>
            }
        </div>
    );
};
