import {getUserRole} from "./access";

import {APP_NAIMIX_INFO_LINK, LOCAL_PROD_NAIMIX_INFO_LINK, NAIMIX_INFO_LINK} from "../constants/links";
import {isClientUser} from "../constants/roles";

export const isPromoPoiskPage = () => {
    return [process.env.REACT_APP_PROMO_POISK].includes(window.location.origin);
};

export const getBffUrl = (urlsAssociation, defaultUrl, isReverseComparison = false) => {
    const pageUrl = Object.keys(urlsAssociation).find(url => {
        if (isReverseComparison) {
            return window.location.pathname.includes(url);
        }

        return url.includes(window.location.pathname);
    });

    if (!pageUrl && urlsAssociation.isClientCard) {
        const role = getUserRole();
        const isClientRole = isClientUser(role);

        const {
            clientRolesUrl,
            adminRolesUrl,
        } = urlsAssociation;

        if (isClientRole) {
            return clientRolesUrl;
        }

        return adminRolesUrl;
    }

    if (!pageUrl) {
        return defaultUrl;
    }

    if (urlsAssociation[pageUrl]) {
        return urlsAssociation[pageUrl];
    }

    return defaultUrl;
};

export const getBffControllerClientCardPage = (controllers) => {
    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: controllers.client,
            adminRolesUrl: controllers.admin,
        },
    );
};

export const getBffCommonController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/common",
        client: "/client-adm/common",
    });
};

export const isProd = () => {
    return [
        LOCAL_PROD_NAIMIX_INFO_LINK,
        NAIMIX_INFO_LINK,
        APP_NAIMIX_INFO_LINK,
        process.env.REACT_APP_PROMO_POISK,
    ].includes(window.location.origin);
};

/**
 * вход: https://nm-predprod.mmtr.ru/bff/client-adm/reports/export-reports/get-file-link?file=c73f0f07-9d38
 * выход: /bff/client-adm/reports/export-reports/get-file-link?file=c73f0f07-9d38
 * @param link
 * @returns {`${string}${string}`}
 */
export const getLinkWithoutDomain = (link) => {
    const parse = require("url-parse");

    const {query, pathname} = parse(link);

    return `${pathname}${query}`;
};