import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmLabelText from "../../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmBadge from "../../../../../../components/NmBadge";
import NmFilterAccordion from "../../../../../../components/NmFilterAccordion";
import NmPage from "../../../../../../components/NmPage";
import NmTitle from "../../../../../../components/NmTitle";
import {
    getRecruitmentAccessControlJobBoardAccountsLogPage,
    getRecruitmentAccessControlJobBoardAccountsOperationTypes,
    updateRecruitmentAccessControlJobBoardAccountsLogStore,
} from "../../../../../../ducks/bff/recruitment/access-control/job-boards/actionsCreators";
import {
    bffRecruitmentAccessControlJobBoardAccountListTotalCountSelector,
    bffRecruitmentAccessControlJobBoardAccountListTotalPageSelector,
    bffRecruitmentAccessControlJobBoardAccountLogListProgressSelector,
    bffRecruitmentAccessControlJobBoardAccountLogListSelector,
    bffRecruitmentVacancyOperationTypesOptionsSelector,
    bffRecruitmentVacancyOperationTypesSelector,
} from "../../../../../../ducks/bff/recruitment/access-control/job-boards/selectors";

import {useFilter} from "../../../../../../hooks/useFilter";
import {usePagination} from "../../../../../../hooks/usePagination";

import {formatDateWithoutTime,formatLocalDate} from "../../../../../../utils/dateFormat";
import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";

const RecruitmentAccessControlJobBoards = (props) => {
    const {
        jobBoardAccountId,
        jobBoardAccountLogin,
        onClose,
    } = props;

    const list = useSelector(bffRecruitmentAccessControlJobBoardAccountLogListSelector);
    const totalPages = useSelector(bffRecruitmentAccessControlJobBoardAccountListTotalPageSelector);
    const totalCount = useSelector(bffRecruitmentAccessControlJobBoardAccountListTotalCountSelector);
    const progress = useSelector(bffRecruitmentAccessControlJobBoardAccountLogListProgressSelector);
    const options = useSelector(bffRecruitmentVacancyOperationTypesOptionsSelector);
    const operationTypes = useSelector(bffRecruitmentVacancyOperationTypesSelector);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            dateFrom: null,
            dateTo: null,
            types: [],
        },
        setPagination,
        pageSize,
    });

    useEffect(() => {
        dispatch(getRecruitmentAccessControlJobBoardAccountsOperationTypes());

        return () => {
            dispatch(updateRecruitmentAccessControlJobBoardAccountsLogStore({
                logPageData: {},
                logList: [],
                logTotalCount: 0,
                logProgress: false,
                operationTypes: {},
            }));
        };
    }, []);

    const fetchList = () => {
        const format = "yyyy-MM-dd";
        const {
            dateFrom,
            dateTo,
            types,
        } = filterData;

        dispatch(getRecruitmentAccessControlJobBoardAccountsLogPage({
            pageSize,
            pageNum,
            jobBoardAccountId,
            dateFrom: isNullOrWhitespace(dateFrom) ? undefined : formatDateWithoutTime(dateFrom, format),
            dateTo: isNullOrWhitespace(dateTo) ? undefined : formatDateWithoutTime(dateTo, format),
            types: isEmpty(types) ? undefined : types,
        }));
    };

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const getRows = () => {
        return list.map(item => {
            const {
                dateTime,
                operation,
                clientUserName,
                errorMessage,
                logId,
            } = item;

            return {
                ...item,
                key: logId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader={operationTypes[operation]}
                        secondaryHeaderStatus={
                            errorMessage &&
                            <NmBadge
                                mod="red"
                                text="Ошибка"
                            />
                        }
                        labels={[
                            {
                                label: "Дата и время",
                                text: formatLocalDate(dateTime, "dd.MM.yyyy HH:mm:ss"),
                            },
                            {
                                label: "Инициатор",
                                text: clientUserName,
                            },
                            errorMessage && {
                                label: "Текст ошибки",
                                text: errorMessage,
                                noWrap: false,
                                inline: true,
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    function renderFilter() {
        return (
            <NmForm
                horizontal
                className="flex align-items-end mb-2 mb-md-0"
            >
                <div className="col-16 col-md-7 col-xl-5">
                    <NmDateRangePickerV2
                        size="lg"
                        startFieldName="dateFrom"
                        endFieldName="dateTo"
                        value={{dateFrom: filter.dateFrom, dateTo: filter.dateTo}}
                        isClearable
                        label="Дата операции"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-4 col-xl-6">
                    <NmDropdownV2
                        size="lg"
                        search={true}
                        label="Тип события"
                        placeholder="Выберите тип"
                        onChange={onChangeFilter}
                        name="types"
                        value={filter.types}
                        options={options}
                        multiple={true}
                    />
                </div>
                <div className="col-16 col-md-4">
                    <FilterButtonsV2
                        onClear={onClear}
                        onSearch={() => onSearch(filter)}
                    />
                </div>
            </NmForm>  
        );
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Лог по аккаунту
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                header={
                    <NmLabelText
                        label={
                            <Text level="3">
                                Логин от джоб-борда
                            </Text>
                        }
                        text={
                            <Text level="3">
                                {jobBoardAccountLogin}
                            </Text>
                        }
                    />
                }
                headerClassName="mb-3 mb-md-4"
                overflowUnset
                noPadding
                typeFilter="horizontal"
                filtersBase={<NmFilterAccordion renderFilter={renderFilter} />}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
                isEmptyPage={isEmpty(list)}
                emptyPageProps={{
                    isSearch,
                }}
            >
                <CheckboxList rows={getRows()} />
            </NmPage>
        </NmModal>
    );
};

export default RecruitmentAccessControlJobBoards;