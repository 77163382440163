import {citizenshipsDict} from "./citizenships";

export const CONTRACTOR_FIELD = {
    ADDRESS: "address",
    BANK_CARD_NUMBER: "bankCardNumber",
    BIRTH_DATE: "birthDate",
    BIRTH_PLACE: "birthPlace",
    CARD_ID_THE_BANK: "cardIdTheBank",
    CITIZENSHIP: "citizenship",
    MIGRATION_STATUS: "migrationStatus",
    CONTRACTOR_DOCUMENT_ID_BANK: "contractorDocumentIdBank",
    CONTRACTOR_ID: "contractorId",
    CONTRACTOR_ID_THE_BANK: "contractorIdTheBank",
    PASSPORT_RESIDENCE_ADDRESS: "passportResidenceAddress",
    DELETED: "deleted",
    EMAIL: "email",
    FIRST_NAME: "firstName",
    FULL_NAME: "fullName",
    GENDER: "gender",
    ID_DOC_ISSUED_BY: "idDocIssuedBy",
    WORK_REGION: "workRegion",
    WORK_REGION_LIST: "workRegionList",
    BLOCKED: "blocked",
    BLOCKED_BANK_OPERATIONS: "blockedBankOperation",
    BLOCKED_REASON: "blockedReason",
    REMOTE_WORK: "remoteWork",
    MIGRATION_CARD_ISSUED_DATE: "migrationCardIssuedDate",
    MIGRATION_CARD_NUMBER: "migrationCardNumber",
    MIGRATION_CARD_VALID_TO_DATE: "migrationCardValidToDate",
    ID_DOC_ISSUED_BY_DEPARTMENT_CODE: "idDocIssuedByDepartmentCode",
    ID_DOC_ISSUED_DATE: "idDocIssuedDate",
    ID_DOC_NUMBER: "idDocNumber",
    ID_DOC_VALID_TO_DATE: "idDocValidToDate",
    INDUSTRY: "industry",
    INN: "inn",
    IS_SELECTED: "isSelected",
    LAST_NAME: "lastName",
    LOCKED: "locked",
    PASSWORD: "password",
    PATRONYMIC: "patronymic",
    PHONE: "phone",
    REALUSER: "realUser",
    REGISTRATION_DATE: "registrationDate",
    LENGTH_OF_STAY_EXPIRATION_DATE: "lengthOfStayExpirationDate",
    WORK_ADDRESS: "workAddressFiasId",
    DEPARTURE_ADDRESS: "departureAddressesFiasIds",
    SPECIALTY: "specialtyList",
    TAX_STATUS: "taxStatus",
    NAIMIX_JOINING_DATE: "naimixJoiningDate",
    ARRIVAL_DOC_TYPE: "arrivalDocType",
    MAIN_DOCUMENT_STAMP_ADDRESS: "mainDocumentStampAddress",
    MAIN_DOCUMENT_STAMP_ISSUED_DATE: "mainDocumentStampIssuedDate",
    MAIN_DOCUMENT_STAMP_VALID_TO_DATE: "mainDocumentStampValidToDate",
    RESIDENCE_PERMIT_NUMBER: "residencePermitNumber",
    RESIDENCE_PERMIT_DECISION_NUMBER: "residencePermitDecisionNumber",
    RESIDENCE_PERMIT_DECISION_DATE: "residencePermitDecisionDate",
    RESIDENCE_PERMIT_ISSUED_DATE: "residencePermitIssuedDate",
    RESIDENCE_PERMIT_VALID_TO_DATE: "residencePermitValidToDate",
    RESIDENCE_PERMIT_ISSUED_BY: "residencePermitIssuedBy",
    ARRIVAL_NOTICE_STAY_END_DATE: "arrivalNoticeStayEndDate",
    PASSPORT_TYPE: "passportType",
    ADDED_VIA_ADMIN_UI: "addedViaAdminUI",
    ADDRESS_FIAS_IDS_FILTER: "addressFiasIdsFilter",
    SPECIALTY_IDS: "specialityIds",
    MAIN_SPECIALITY_ID: "mainSpecialityId",
    SPECIALITIES: "specialities",
    EIN: "ein",
    EMPLOYMENT_HISTORY_PRESENT: "employmentHistoryPresent",
    EMPLOYMENT_HISTORY_NUM: "employmentHistoryNum",
    SNILS_PRESENT: "snilsPresent",
    SNILS_NUM: "snilsNum",
    VHI_POLICY_PRESENT: "vhiPolicyPresent",
    VHI_POLICY_VALID_FROM_DATE: "vhiPolicyValidFromDate",
    VHI_POLICY_VALID_TO_DATE: "vhiPolicyValidToDate",
    EMPLOYMENT_PATENT_SERIES: "employmentPatentSeries",
    EMPLOYMENT_PATENT_NUM: "employmentPatentNum",
    EMPLOYMENT_PATENT_ISSUED_DATE: "employmentPatentIssuedDate",
    EMPLOYMENT_PATENT_REGISTRATION_NUMBER: "employmentPatentRegistrationNumber",
    EDM_PASSPORT_TYPE: "edmPassportType",
    WORK_ADDRESS_FIAS_ID: "workAddressFiasId",
    EDM_ENABLED: "edmEnabled",
    EDM_STATUS: "edmStatus",
    RESIDENCE_PERMIT_ADDRESS: "residencePermitAddress",
    STATUS: "status",
    PREVIOUS_BIRTH_DATE: "previousPassportBirthDate",
    PREVIOUS_FIRST_NAME: "previousPassportFirstName",
    PREVIOUS_ID_DOC_NUMBER: "previousPassportIdDocNumber",
    PREVIOUS_LAST_NAME: "previousPassportLastName",
    PREVIOUS_PATRONYMIC: "previousPassportPatronymic",
    BANK_ADDRESS: "bankAddress",
    BANK_NAME: "bankName",
    BIC: "bic",
    KS: "ks",
    RS: "rs",
    TEMPORARY_RESIDENCE_PERMIT_NUM: "temporaryResidencePermitNum",
    TEMPORARY_RESIDENCE_PERMIT_DECISION_ISSUED_BY: "temporaryResidencePermitDecisionIssuedBy",
    TEMPORARY_RESIDENCE_PERMIT_DECISION_DATE: "temporaryResidencePermitDecisionDate",
    TEMPORARY_RESIDENCE_PERMIT_VALID_TO_DATE: "temporaryResidencePermitValidToDate",
};

export const CONTRACTOR_SCAN_FIELD = {
    BASE_STR: "base64str",
    ERROR_CODE: "errorCode",
    ERROR_MESSAGE: "errorMessage",
    FILE_NAME: "fileName",
};

export const CONTRACTOR_FAMILY_STATUS = {
    SINGLE: {
        TEXT: "Холост/Не замужем",
        VALUE: "SINGLE",
    },
    MARRIED: {
        TEXT: "Женат/Замужем",
        VALUE: "MARRIED",
    },
};

export const CONTRACTOR_FAMILY_STATUS_OPTIONS = [
    {
        key: CONTRACTOR_FAMILY_STATUS.SINGLE.VALUE,
        text: CONTRACTOR_FAMILY_STATUS.SINGLE.TEXT,
        value: CONTRACTOR_FAMILY_STATUS.SINGLE.VALUE,
    },
    {
        key: CONTRACTOR_FAMILY_STATUS.MARRIED.VALUE,
        text: CONTRACTOR_FAMILY_STATUS.MARRIED.TEXT,
        value: CONTRACTOR_FAMILY_STATUS.MARRIED.VALUE,
    },
];

export const CONTRACTOR_ARRIVAL_DOC_TYPE_CODE = {
    ARRIVAL_NOTIFICATION: "ARRIVAL_NOTIFICATION",
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
};

export const CONTRACTOR_ARRIVAL_DOC_TYPE_DICT = {
    [CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION]: "Уведомление о прибытии",
    [CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT]: "Штамп в документе",
};

export const CONTRACTORS_TABS_TYPES = {
    ALL: "all",
    FAVORITE: "favorite",
    BLACK_LIST: "blacklist",
    STOP_LIST: "stop-list",
    MY_CONTRACTORS: "my-contractors",
    RESOURCES: "resources",
    BY_ORDER: "by-order",
    ML_SEARCH: "ml-search",
    ONBOARDING: "onboarding",
};

export const CONTRACTOR_ADDING_TYPE = {
    BY_PHONE: "byPhone",
    BY_EMAIL: "byEmail",
};

export const EDM_PASSPORT_TYPE = {
    OLD_MODEL_PASSPORT: {
        VALUE: "OLD_MODEL_PASSPORT",
        TEXT: "Загранпаспорт нового образца",
    },
    NEW_MODEL_PASSPORT: {
        VALUE: "NEW_MODEL_PASSPORT",
        TEXT: "Внутренний паспорт нового образца",
    },
};

export const CONTRACTOR_TYPE_BLOCKED = {
    SUSPICIOUS_ACTIVITY: "SUSPICIOUS_ACTIVITY",
    DOCUMENTS_EXPIRED: "DOCUMENTS_EXPIRED",
    TERRORISM_AND_EXTREMIST: "TERRORISM_AND_EXTREMIST",
    ANY_MATCH: "ANY_MATCH",
};

export const CONTRACTOR_TYPE_BLOCKED_DICT = {
    [CONTRACTOR_TYPE_BLOCKED.SUSPICIOUS_ACTIVITY]: "Подозрительная активность",
    [CONTRACTOR_TYPE_BLOCKED.DOCUMENTS_EXPIRED]: "Истек срок действия документов",
    [CONTRACTOR_TYPE_BLOCKED.TERRORISM_AND_EXTREMIST]: "Совпадение данных с перечнем террористов и экстремистов",
};

export const CONTRACTOR_TYPE_BLOCKED_FILTER_DICT = {
    [CONTRACTOR_TYPE_BLOCKED.ANY_MATCH]: "Присутствует блокировка (любая причина)",
    [CONTRACTOR_TYPE_BLOCKED.SUSPICIOUS_ACTIVITY]: "Подозрительная активность",
    [CONTRACTOR_TYPE_BLOCKED.DOCUMENTS_EXPIRED]: "Истек срок действия документов",
    [CONTRACTOR_TYPE_BLOCKED.TERRORISM_AND_EXTREMIST]: "Исполнитель в списке террористов",
};

export const CONTRACTOR_INN_CHECK_STATUS_DICT = {
    NOT_EQUALS: "По данным получен другой ИНН",
    OK: "Мэтчинг успешно пройден",
    NOT_CHECKED: "Мэтчинг не проводился (сервис недоступен)",
    NOT_EXIST: "По данным не получен ИНН",
};

export const EDM_UA_PASSPORT_TYPE = {
    OLD_MODEL_PASSPORT: {
        VALUE: "OLD_MODEL_PASSPORT",
        TEXT: "Загранпаспорт нового образца",
    },
    NEW_MODEL_PASSPORT: {
        VALUE: "NEW_MODEL_PASSPORT",
        TEXT: "Внутренний паспорт нового образца",
    },
};

export const EDM_PASSPORT_TYPE_OPTIONS = [
    {
        key: EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE,
        value: EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE,
        text: EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.TEXT,
    },
    {
        key: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
        value: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
        text: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.TEXT,
    },
];

export const EDM_UA_PASSPORT_TYPE_OPTIONS = [
    {
        key: EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE,
        value: EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE,
        text: EDM_UA_PASSPORT_TYPE.OLD_MODEL_PASSPORT.TEXT,
    },
    {
        key: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
        value: EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE,
        text: EDM_UA_PASSPORT_TYPE.NEW_MODEL_PASSPORT.TEXT,
    },
];

export const TYPE_REASON_FOR_REFUSAL = {
    NO_REASON: {
        VALUE: "NO_REASON",
        TEXT: "Без указания причины",
        NOTIFICATION_TEXT: "Спасибо за ваш интерес! К сожалению, заявка отклонена. Не расстраивайтесь и с новыми силами откликайтесь на другие заказы",
    },
    REFUSED_COMPLY: {
        VALUE: "REFUSED_COMPLY",
        TEXT: "Исполнитель отказался выполнять заказ",
        NOTIFICATION_TEXT: "К сожалению, заявка отклонена. Так бывает. Вы можете выбрать другой наиболее подходящий заказ для вас",
    },
    IRRELEVANT_TASK: {
        VALUE: "IRRELEVANT_TASK",
        TEXT: "Заказ перестал быть актуальным",
        NOTIFICATION_TEXT: "Спасибо за ваш интерес! К сожалению, данный заказ перестал быть актуальным. В разделе Поиск вы можете найти много других заказов",
    },
    ORDER_BOOKED: {
        VALUE: "ORDER_BOOKED",
        TEXT: "Заказ уже забронирован другим исполнителем",
        NOTIFICATION_TEXT: "Спасибо за ваш интерес! На данный заказ выйдет исполнитель, который откликнулся раньше. Успейте откликнуться первым на другие заказы!",
    },
    CONTRACTOR_DO_NOT_MEET_THE_REQUIREMENTS: {
        VALUE: "CONTRACTOR_DO_NOT_MEET_THE_REQUIREMENTS",
        TEXT: "Исполнитель не подходит по требованиям",
        NOTIFICATION_TEXT: "К сожалению, заявка отклонена из-за требований заказчика. Попробуйте заполнить больше данных о вас или откликайтесь на заказы других компаний",
    },
    NEGATIVE_EXPERIENCE: {
        VALUE: "NEGATIVE_EXPERIENCE",
        TEXT: "У заказчика был негативный опыт сотрудничества с исполнителем",
        NOTIFICATION_TEXT: "К сожалению, заявка отклонена заказчиком. Порой случается. Смело откликайтесь на заказы других компаний",
    },
};

export const CONTRACTOR_TAX_STATUS = {
    // Ожидается подтверждение от самозанятого в приложении Мой налог
    CONFIRM_IN_MY_TAX: "CONFIRM_IN_MY_TAX",
    // Исполнитель не сделал площадку Наймикс партнером в приложении Мой налог
    TAXPAYER_UNBOUND: "TAXPAYER_UNBOUND",
    // Исполнитель не является самозанятым
    TAXPAYER_UNREGISTERED: "TAXPAYER_UNREGISTERED",
    // Исполнитель не является самозанятым
    REQUEST_VALIDATION_ERROR: "REQUEST_VALIDATION_ERROR",
    // Исполнитель зарегистрирован в качестве самозанятого и подтвердил партнерство с Наймикс
    OK: "OK",
};

export const CONTRACTOR_WORK_SCHEDULE_DAY_DICT = {
    MONDAY: "Пн: ",
    TUESDAY: "Вт: ",
    WEDNESDAY: "Ср: ",
    THURSDAY: "Чт: ",
    FRIDAY: "Пт: ",
    SATURDAY: "Сб: ",
    SUNDAY: "Вс: ",
};

export const CONTRACTOR_REGISTRATION_STATUS = {
    ADMINISTRATOR_CHECK: "На проверке администратором",
    ADMINISTRATOR_CHECK_CHANGES: "Изменения на проверке администратором",
    ADMINISTRATOR_CHECK_PASSED: "Проверка пройдена",
    BASIC_REGISTRATION: "Пройдена простая регистрация",
};

export const CONTRACTOR_STATUS = {
    ADMINISTRATOR_CHECK: "ADMINISTRATOR_CHECK",//На проверке администратором
    ADMINISTRATOR_CHECK_CHANGES: "ADMINISTRATOR_CHECK_CHANGES",//Изменения на проверке администратором
    ADMINISTRATOR_CHECK_PASSED: "ADMINISTRATOR_CHECK_PASSED",//Проверка пройдена
    BANK_CHECK: "BANK_CHECK",//На проверке банком"
    BANK_CHECK_FAILED: "BANK_CHECK_FAILED",//Банковская проверка не пройдена
    BANK_CHECK_PASSED: "BANK_CHECK_PASSED",//Проверка пройдена
    BASIC_REGISTRATION: "BASIC_REGISTRATION",//"Пройдена простая регистрация"
};

export const CONTRACTOR_EDM_STATUS = {
    ADMINISTRATOR_CHECK: "ADMINISTRATOR_CHECK",//На проверке администратором
    ADMINISTRATOR_CHECK_CHANGES: "ADMINISTRATOR_CHECK_CHANGES",//Изменения на проверке администратором
    ADMINISTRATOR_CHECK_PASSED: "ADMINISTRATOR_CHECK_PASSED",//Проверка пройдена
    BASIC_REGISTRATION: "BASIC_REGISTRATION",//"Пройдена простая регистрация"
};

export const CONTRACTOR_CHECK_PASSPORT_TEXT = {
    [CONTRACTOR_STATUS.BASIC_REGISTRATION]: "Исполнитель не прошел регистрацию по паспорту",
    [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK]: "Исполнитель ввел информацию о документах, данные на проверке",
    [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES]: "Исполнитель ввел информацию о документах, данные на проверке",
    [CONTRACTOR_STATUS.BANK_CHECK_PASSED]: "Исполнитель прошел проверку документов",
    [CONTRACTOR_STATUS.BANK_CHECK_FAILED]: "Исполнитель прошел проверку документов",
    [CONTRACTOR_STATUS.BANK_CHECK]: "Исполнитель прошел проверку документов",
};

export const SUBSCRIBE_TO_CONTRACTORS_SUCCESS_TEXT = "Ваша заявка успешно сохранена. Мы сообщим, когда найдутся интересующие Вас исполнители.";

export const REMOTE_WORK_OPTION = {
    key: "remoteWork",
    value: "remoteWork",
    text: "Работа удалённо",
};

export const CONTRACTOR_LIST_STATUS = {
    ACTIVE: "active",
    FINISHED: "finished",
};

export const CONTRACTOR_PAYMENT_POSSIBILITY_CHECK = {
    "BANK_REGISTRATION": "Исполнитель не зарегистрирован в банке",
    "FULL_REGISTRATION": "Исполнитель не прошел полную регистрацию в Наймикс - отсутствуют данные для составления договора. Свяжитесь с исполнителем и попросите пройти полную регистрацию по паспорту",
    "PODFT_CHECK": "Исполнитель не прошел проверку в банке. Для новых зарегистрированных исполнителей проверка обычно занимает 1-2 рабочих дня. Проверка проводится разово. Подробности по номеру телефона: 8 (800) 500 5654",
    "OVERALL_INCOME": "В соответствии с п. 8 ч. 2 ст. 4 Федерального закона № 422-ФЗ доход самозанятого гражданина не может превышать 2,4 млн руб. за календарный год",
    "FNS_REGISTRATION": "Исполнитель не является самозанятым. Свяжитесь с исполнителем и попросите проверить статус самозанятого в приложении Мой налог",
    "INN": "Некорректный инн",
    "NDP_NALOG": "Исполнитель не сделал площадку Наймикс партнером в приложении Мой налог. Свяжитесь с исполнителем и попросите проверить партнерство с Наймикс в приложении Мой налог",
};

export const TIME_INTERVAL_PAYMENT_POSSIBILITY = 2000;

export const PASSPORT_NUMBER_MASK_BY_CITIZENSHIP = {
    [citizenshipsDict.UA.value]: {
        NEW: "*********",
        OLD: "aa999999",
    },
    [citizenshipsDict.TJ.value]: {
        NEW: "**************************************************",
        OLD: "**************************************************",
    },
};

export const SCAN_DECISION_TYPE = {
    FILE_APPROVED: "FILE_APPROVED", //Подтверждение
    NEED_INN_SCAN: "NEED_INN_SCAN", // Подтверждение с запросом листа с ИНН
    FILE_DECLINED_IMAGE_GLARE: "FILE_DECLINED_IMAGE_GLARE", //Наличие блика
    FILE_DECLINED_IMAGE_PARTIALLY_MISSING: "FILE_DECLINED_IMAGE_PARTIALLY_MISSING", //Отсутствует часть изображения
    FILE_DECLINED_OTHER_REASON: "FILE_DECLINED_OTHER_REASON", //Иная причина отклонения
    FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS: "FILE_DECLINED_INN_AND_PASSPORT_DATA_NOT_EQUALS", //ИНН не соответствует паспортным данным
};

export const SCAN_DECISION_REASON = {
    NEED_INN_SCAN: "Требуется фотография, подтверждающая ИНН",
};

export const SCAN_SOLUTION_TYPES = {
    SELFIE: "SELFIE",
    OTHER: "OTHER",
};

export const SCAN_DECISION_TEXT = {
    [SCAN_SOLUTION_TYPES.SELFIE]: {
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]: "Плохое качество фотографии или часть данных закрыта - невозможно определить принадлежность паспорта",
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]: "Человек, изображённый на фотографии, не совпадает с фотографией в паспорте",
        [SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]: "Фотография не является \"Селфи с паспортом\"",
        [SCAN_DECISION_TYPE.FILE_APPROVED]: "Подтверждение",
    },
    [SCAN_SOLUTION_TYPES.OTHER]: {
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]: "Наличие блика",
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]: "Отсутствует часть изображения",
        [SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]: "Иная причина",
        [SCAN_DECISION_TYPE.FILE_APPROVED]: "Подтверждение",
    },
};

export const SCAN_DECISION_TITLE = {
    [SCAN_SOLUTION_TYPES.SELFIE]: {
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]: "Размыто",
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]: "Нет сходства",
        [SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]: "Не селфи",
    },
    [SCAN_SOLUTION_TYPES.OTHER]: {
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_GLARE]: "Блик",
        [SCAN_DECISION_TYPE.FILE_DECLINED_IMAGE_PARTIALLY_MISSING]: "Обрезано",
        [SCAN_DECISION_TYPE.FILE_DECLINED_OTHER_REASON]: "Иная",
    },
};

export const EXECUTOR_CHECK_STATUS = {
    NOT_VERIFIED: {
        value: "NOT_VERIFIED",
        text: "Не проверено",
        mod: "not-verified",
    },
    REJECTED: {
        value: "REJECTED",
        text: "Есть отклоненные фотографии",
        mod: "rejected",
    },
    APPROVED: {
        value: "APPROVED",
        text: "Фотографии подтверждены",
        mod: "approved",
    },
};

export const CONTRACTOR_MIGRATION_STATUS = {
    STUDENT: "STUDENT", // Студент
    RESIDENCE_PERMIT: "RESIDENCE_PERMIT", // Вид на жительство
    REFUGEE: "REFUGEE", // Беженец
    VISA: "VISA", // Виза
    TEMPORARY_REFUGEE: "TEMPORARY_REFUGEE", // Временное убежище
    TEMPORARY_RESIDENCE_PERMIT: "TEMPORARY_RESIDENCE_PERMIT", // Разрешение на временное проживание
    PATENT: "PATENT", // Патент
    NONE: "NONE", // Нет
};

export const CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS = {
    ADMINISTRATOR_CHECK: "ADMINISTRATOR_CHECK", // На проверке
    ADMINISTRATOR_CHECK_PASSED: "ADMINISTRATOR_CHECK_PASSED", // Проверен
    DECLINED: "DECLINED", // Отклонен
    NOT_PRESENT: "NOT_PRESENT", // Отсутствует
};

//Тип полиса
export const CONTRACTOR_VHI_POLICE_TYPE = {
    COMPULSORY: "COMPULSORY", //ОМС
    VOLUNTARY: "VOLUNTARY", //ДМС
};

export const CONTRACTOR_VHI_POLICE_FORMAT = {
    PAPER: "PAPER", //Бумажный
    PLASTIC: "PLASTIC", //Пластиковый
};

export const CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE = {
    SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED: "SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED",
};

export const CONTRACTOR_RECOMMENDATION_TYPE = {
    DEFAULT: "DEFAULT",
    ML: "ML",
    FILTER: "FILTER",
    OTHER_FILTER: "OTHER_FILTER",
    CONTRACTOR_LIST: "CONTRACTOR_LIST",
    CONTRACTOR_PAGE: "CONTRACTOR_PAGE",
};

export const CONTRACTOR_LAST_LOGIN_TYPE = {
    LESS_THAN_ONE_DAY: "LESS_THAN_ONE_DAY",
    LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
    LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
    MORE_THAN_THIRTY_DAYS: "MORE_THAN_THIRTY_DAYS",
};

export const CONTRACTOR_LAST_LOGIN_TYPE_DICT = {
    [CONTRACTOR_LAST_LOGIN_TYPE.LESS_THAN_ONE_DAY]: "Менее 1 дня назад",
    [CONTRACTOR_LAST_LOGIN_TYPE.LAST_SEVEN_DAYS]: "Последние 7 дней",
    [CONTRACTOR_LAST_LOGIN_TYPE.LAST_THIRTY_DAYS]: "Последние 30 дней",
    [CONTRACTOR_LAST_LOGIN_TYPE.MORE_THAN_THIRTY_DAYS]: "Более 30 дней назад",
};

export const CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS = {
    NOT_CONNECTED: "NOT_CONNECTED",
    CONNECTED: "CONNECTED",
    INVALID: "INVALID",
};

export const CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DICT = {
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED]: "Не подключен",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED]: "Подключен",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID]: "Недействителен",
};

export const CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_FILTER_DICT = {
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED]: "Исполнитель без статуса ИП",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED]: "Действующий статус ИП",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID]: "Недействующий статус ИП",
};

export const CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DESCRIPTION = {
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED]: "Исполнитель не имеет статуса ИП",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED]: "Исполнитель имеет действующий статус ИП",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID]: "Статус ИП исполнителя недействителен. Он может быть ликвидирован, в процессе ликвидации или другая причина",
};

export const CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_CHECK_RESULT_MESSAGE = {
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED]: "Статуса ИП нет, данные обновлены",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED]: "Статус ИП действующий, данные обновлены",
    [CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID]: "Статус ИП не действующий, данные обновлены",
};

export const CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS = {
    CREATING_IN_NAMIX: "CREATING_IN_NAMIX",
    CREATING_ERROR_IN_NAMIX: "CREATING_ERROR_IN_NAMIX",
    CREATED: "CREATED",
    IN_PROGRESS: "IN_PROGRESS",
    ACCEPTING: "ACCEPTING",
    ACCEPTED: "ACCEPTED",
    REJECTING: "REJECTING",
    REJECTED: "REJECTED",
};

export const CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS_DICT = {
    CREATING_IN_NAMIX: "Формируется на стороне Наймикс",
    CREATING_ERROR_IN_NAMIX: "Ошибка при формировании на стороне Наймикс",
    CREATED: "Создана в банке",
    IN_PROGRESS: "В обработке банком",
    ACCEPTING: "Принятие банком",
    ACCEPTED: "Принята банком",
    REJECTING: "Отклонение банком",
    REJECTED: "Отклонена банком",
};

export const CONTRACTOR_MEDICAL_BOOK_STATUS = {
    OK: "OK",
    EXPIRED: "EXPIRED",
    ABSENT: "ABSENT",
};

export const CONTRACTOR_DOCUMENTS_CHECK_EDIT_ERROR_MESSAGE = "Редактирование данных исполнителя недоступно при наличии заявки на внесение/изменение персональных данных";