import {all, put, select, takeEvery} from "redux-saga/effects";

import {getOrderWorkReportList} from "./bff/orders/order-contractors/actionCreators";
import {getOrderWorkReportPageDataSelector} from "./bff/orders/order-contractors/selectors";

import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import request from "../utils/postman";
import {isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

import {CHECK_MESSAGE} from "../constants/messages";

//*  TYPES  *//

const FINANCE_CREATE_OFFLINE_CHECK_REQUEST = "FINANCE_CREATE_OFFLINE_CHECK_REQUEST";
const FINANCE_CREATE_OFFLINE_CHECK_SUCCESS = "FINANCE_CREATE_OFFLINE_CHECK_SUCCESS";
const FINANCE_CREATE_OFFLINE_CHECK_ERROR = "FINANCE_CREATE_OFFLINE_CHECK_ERROR";

const FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST = "FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST";
const FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS = "FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS";
const FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR = "FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR";

//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    transactionLog: [],
    progressCorrect: false,
    bankStatusMessage: "",
    systemTransactionLog: [],
    progressIndividualConclusion: false,
};

//*  REDUCER  *//

//*  ACTION CREATORS  *//

export function createOfflineCheck(payload) {
    return {
        type: FINANCE_CREATE_OFFLINE_CHECK_REQUEST,
        payload,
    };
}

export function registrationOfflineCheck(payload) {
    return {
        type: FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const financeLogSelector = state => state.financeLog;

//*  SAGA  *//

//GET /api/taxAuthorities/registrationOfflineCheck
//GET /api/taxAuthorities/getByPaymentNumberOrderIdReceipt
export const registrationOfflineCheckSaga = function* (action) {
    try {
        const {payload} = action;
        const {orderId, paymentNumber} = payload;

        const result = yield request.get("taxAuthorities/getByPaymentNumberOrderIdReceipt", {
            params: {
                orderId,
                paymentNumber,
            },
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (isNullOrWhitespace(result)) {
            toastError("Отсутствует оффлайн чек");
            return {
                done: true,
            };
        }

        const resultRegistration = yield request.get("taxAuthorities/registrationOfflineCheck", {
            params: {
                receiptId: result,
            },
        });

        const {
            errorMessage: errorMessageResultRegistration,
            link,
        } = resultRegistration;

        if (errorMessageResultRegistration) {
            toastError(errorMessageResultRegistration);

            return {
                done: true,
            };
        }

        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", link);
        tempLink.setAttribute("target", "_blank");

        document.body.appendChild(tempLink);

        tempLink.click();

        setTimeout(() => {
            tempLink.remove();
        }, 500);

        const state = yield select();
        yield put(getOrderWorkReportList(getOrderWorkReportPageDataSelector(state)));

        yield put({
            type: FINANCE_REGISTRATION_OFFLINE_CHECK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: FINANCE_REGISTRATION_OFFLINE_CHECK_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/common/download-client-files/create-offline-receipt/create-offline-receipt
//POST /bff/client-adm/common/download-client-files/create-offline-receipt/create-offline-receipt
export const createOfflineCheckSaga = function* (action) {
    try {
        const {payload} = action;

        const _url = getBffControllerClientCardPage({
            admin: "/adm/common/download-client-files/create-offline-receipt",
            client: "/client-adm/common/download-client-files/create-offline-receipt",
        });

        const result = yield request.bff.post(_url, payload);

        const {errorMessage, downloadLink} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_CREATE_OFFLINE_CHECK_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Оффлайн чек успешно создан");

        const response = yield fetch(`${new URL(window.location.href).origin}/${downloadLink}`, {
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
            },
            method: "GET",
        });

        if (response.status === 404) {
            toastError(CHECK_MESSAGE.ERROR_404);

            return {
                done: true,
            };
        }

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);
        tempLink.setAttribute("target", "_blank");

        document.body.appendChild(tempLink);

        tempLink.click();

        setTimeout(() => {
            tempLink.remove();
        }, 500);

        yield put({
            type: FINANCE_CREATE_OFFLINE_CHECK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_CREATE_OFFLINE_CHECK_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FINANCE_CREATE_OFFLINE_CHECK_REQUEST, createOfflineCheckSaga),
        takeEvery(FINANCE_REGISTRATION_OFFLINE_CHECK_REQUEST, registrationOfflineCheckSaga),
    ]);
}
