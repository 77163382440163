import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getEdmPositionCommonCount, getEdmPositionList} from "./actionCreators";
import {
    EDM_POSITION_ADD_ERROR,
    EDM_POSITION_ADD_REQUEST,
    EDM_POSITION_ADD_SUCCESS,
    EDM_POSITION_COMMON_COUNT_ERROR,
    EDM_POSITION_COMMON_COUNT_REQUEST,
    EDM_POSITION_COMMON_COUNT_SUCCESS,
    EDM_POSITION_DELETE_ERROR,
    EDM_POSITION_DELETE_REQUEST,
    EDM_POSITION_DELETE_SUCCESS,
    EDM_POSITION_LIST_READ_ERROR,
    EDM_POSITION_LIST_READ_REQUEST,
    EDM_POSITION_LIST_READ_SUCCESS,
    EDM_POSITION_UPDATE_ERROR,
    EDM_POSITION_UPDATE_REQUEST,
    EDM_POSITION_UPDATE_SUCCESS,
} from "./actions";
import {edmPositionListPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {
    toastError,
    toastSuccess,
} from "../../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../../utils/url";


const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/dictionaries/positions",
        client: "/client-adm/edo/dictionaries/positions",
    });
};

const getEdmPositionListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDM_POSITION_LIST_READ_ERROR,
            payload: error,
        });
    }
};

const updateEdmPositionSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            positionId,
        } = data;

        const result = yield request.bff.patch(`${getController()}/${clientId}/${positionId}`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_UPDATE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно изменена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
    } catch (error) {
        yield put({
            type: EDM_POSITION_UPDATE_ERROR,
            payload: error,
        });
    }
};

const addEdmPositionSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_ADD_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно добавлена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
        yield put(getEdmPositionCommonCount({clientId: data.clientId}));
    } catch (error) {
        yield put({
            type: EDM_POSITION_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteEdmPositionSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            positionId,
        } = data;

        const result = yield request.bff.delete(`${getController()}/${clientId}/${positionId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_DELETE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Должность успешно удалена");

        const state = yield select();
        yield put(getEdmPositionList(edmPositionListPageDataSelector(state)));
        yield put(getEdmPositionCommonCount({clientId}));
    } catch (error) {
        yield put({
            type: EDM_POSITION_DELETE_ERROR,
            payload: error,
        });
    }
};

const getEdmPositionCommonCountSaga = function* ({payload}) {
    try {
        const {
            clientId,
        } = payload;

        const state = yield select();
        const pageData = yield edmPositionListPageDataSelector(state);

        const result = yield request.bff.post(`${getController()}/count`, {clientId, ...pageData});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_POSITION_COMMON_COUNT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_POSITION_COMMON_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDM_POSITION_COMMON_COUNT_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(EDM_POSITION_LIST_READ_REQUEST, getEdmPositionListSaga),
        takeEvery(EDM_POSITION_UPDATE_REQUEST, updateEdmPositionSaga),
        takeEvery(EDM_POSITION_DELETE_REQUEST, deleteEdmPositionSaga),
        takeEvery(EDM_POSITION_ADD_REQUEST, addEdmPositionSaga),
        takeEvery(EDM_POSITION_COMMON_COUNT_REQUEST, getEdmPositionCommonCountSaga),
    ]);
}