import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const clientObjectCardSelector = state => state.bff.clientObjectCard;
export const clientObjectCardInfoSelector = createSelector(clientObjectCardSelector, ({card}) => card);
export const clientObjectCardProgressSelector = createSelector(clientObjectCardSelector, ({progress}) => progress);
export const clientObjectCardObjectUserListProgressSelector = createSelector(clientObjectCardSelector, ({progressObjectUserList}) => progressObjectUserList);
export const clientObjectCardObjectUserListSelector = createSelector(clientObjectCardSelector, ({objectUserList}) => objectUserList);
export const clientObjectCardObjectUsersTotalPagesSelector = createSelector(clientObjectCardSelector, ({usersTotalCount, objectUsersPageData: {pageSize = 0}}) => getTotalPages(usersTotalCount, pageSize));
export const clientObjectCardObjectUsersTotalCountSelector = createSelector(clientObjectCardSelector, ({usersTotalCount}) => usersTotalCount);