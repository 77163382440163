import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST, BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/recruitment/vacancyCandidates",
        client: "/client-adm/recruitment/vacancyCandidates",
    });
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/responses
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/responses
// Получить отклики кандидата
const getRecruitmentVacancyCandidatesResponses = function* ({payload}) {
    const {
        isLoadMore,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/responses`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// PUT /bff/client-adm/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// Удалить связь отклика и кандидата
const deleteRecruitmentVacancyCandidatesResponse = function* ({payload}) {
    const {
        candidateId,
        responseId,
        vacancyId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.put(
            `${getController()}/${candidateId}/responses/${responseId}`,
            undefined,
            {params: {vacancyId}},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// GET /bff/client-adm/recruitment/vacancyCandidates/{candidateId}/responses/{responseId}
// Получить отклик кандидата
const getRecruitmentVacancyCandidatesResponse = function* ({payload}) {
    const {
        candidateId,
        vacancyId,
        responseId,
    } = payload;

    try {
        const result = yield request.bff.get(
            `${getController()}/${candidateId}/responses/${responseId}`,
            {params: {vacancyId}},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_REQUEST, getRecruitmentVacancyCandidatesResponses),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_DELETE_REQUEST, deleteRecruitmentVacancyCandidatesResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RESPONSES_GET_REQUEST, getRecruitmentVacancyCandidatesResponse),
    ]);
}