import {
    ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST,
    CANCEL_REGISTRY_PAYMENT_REQUEST,
    CLEAR_REGISTRY_PAYMENT_LOG,
    GET_REGISTRY_REQUEST,
    REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_DELETE_REQUEST,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_EXPORT_REQUEST,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST,
    REGISTRY_PAYMENT_LIST_REQUEST,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST,
    REGISTRY_PAYMENT_ROW_PAY_REQUEST,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_UPDATE_STORE,
} from "./actions";

export function getRegistryById(payload) {
    return {
        type: GET_REGISTRY_REQUEST,
        payload,
    };
}

export function getRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_LIST_REQUEST,
        payload,
    };
}

export function cancelRegistryPayment(payload) {
    return {
        type: CANCEL_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
}

export function clearRegistryPaymentLog() {
    return {
        type: CLEAR_REGISTRY_PAYMENT_LOG,
    };
}

export function updateRegistryPaymentsStore(payload) {
    return {
        type: REGISTRY_PAYMENT_UPDATE_STORE,
        payload,
    };
}

export function deleteOrCancelRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_DELETE_REQUEST,
        payload,
    };
}

export function registryPaymentPay(payload) {
    return {
        type: REGISTRY_PAYMENT_ROW_PAY_REQUEST,
        payload,
    };
}

export function addContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST,
        payload,
    };
}


export function importFromFileRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST,
        payload,
    };
}


export function updateContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST,
        payload,
    };
}

export function editContractorToRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST,
        payload,
    };
}

export function registryPaymentRefreshPerformer(payload) {
    return {
        type: REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST,
        payload,
    };
}

export function registryPaymentHasInProgress(payload) {
    return {
        type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST,
        payload,
    };
}

export function getRegistryAvailableForPayments(payload) {
    return {
        type: REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
        payload,
    };
}

export function exportRegistryPayments(payload) {
    return {
        type: REGISTRY_PAYMENT_EXPORT_REQUEST,
        payload,
    };
}

export function addRegistryPaymentsDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}