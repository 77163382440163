import React, {KeyboardEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import AmountInput from "../../AmountInput";
import ClientUsersFioDropdown from "../../ClientUsersFioDropdown";
import PhoneWithCodeFilter from "../../PhoneWithCodeFilter";
import FilterButtonsV2 from "../FilterButtonsV2";
import FilterContractor from "../FilterContractor";
import FilterCustomer from "../FilterCustomer";
import FilterFunnelStatuses from "../FilterFunnelStatuses";
import FilterObject from "../FilterObject";
import FilterProject from "../FilterProject";
import FilterSpecialities from "../FilterSpecialities";
import NmCheckboxV2 from "../NmCheckboxV2";
import NmDadata from "../NmDadata";
import NmDatePicker from "../NmDatePicker";
import NmDateRangePickerV2 from "../NmDateRangePickerV2";
import NmDropdownV2 from "../NmDropdownV2";
import NmForm from "../NmForm";
import NmInputV2 from "../NmInputV2";
import NmRangeInput from "../NmRangeInput";

import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";
import {getDadataAddress} from "../../../utils/dadata";
import {getFilterDefaultState} from "./utils/getDefaultState";
import {getFilterDefaultValue} from "./utils/getDefaultValue";
import {getFilterValue} from "./utils/getValue";

import {
    FilterComponent,
    FilterComponentAttributes,
    FilterComponentParams,
    FilterFormState,
    FiltersConfig,
    FilterValueType,
    OnChangeFilterParamType,
} from "./types";

import "./style.sass";

export const FILTER: Record<string, keyof typeof FilterComponent> = {
    // фильтр Заказчик
    CUSTOMER: "customer",
    // фильтр Вид деятельности
    SPECIALITIES: "specialities",
    // фильтр Этап отбора
    FUNNEL_STATUSES: "funnelStatuses",
    // фильтр Проект
    PROJECT: "project",
    // фильтр Объект
    OBJECT: "object",
    // обычный инпут
    INPUT: "input",
    // от до
    RANGE_INPUT: "rangeInput",
    // обычный дропдаун
    DROPDOWN: "dropdown",
    // датапикер
    DATE: "date",
    // период дат
    DATE_RANGE: "dateRange",
    //дадата
    DADATA: "dadata",
    // чекбокс
    CHECKBOX: "checkbox",
    // фильтр Номер телефона с выбором страны
    PHONE_WITH_CODE: "phoneWithCode",
    // фильтр сотрудников
    CLIENT_USERS_FIO: "clientUsersFio",
    // фильтр "Исполнитель" для поиска по ФИО, ИНН или номеру телефона
    CONTRACTOR: "contractor",
    // целых или дробных чисел до 2 знаков в дробной части
    AMOUNT_INPUT: "amountInput",
};
// Все компоненты которые могут использоваться
const controls = {
    [FILTER.CUSTOMER]: {
        render: FilterCustomer,
    },
    [FILTER.PROJECT]: {
        render: FilterProject,
    },
    [FILTER.OBJECT]: {
        render: FilterObject,
    },
    [FILTER.SPECIALITIES]: {
        render: FilterSpecialities,
    },
    [FILTER.FUNNEL_STATUSES]: {
        render: FilterFunnelStatuses,
    },
    [FILTER.INPUT]: {
        render: NmInputV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.RANGE_INPUT]: {
        render: NmRangeInput,
        props: {
            size: "lg",
        },
    },
    [FILTER.DROPDOWN]: {
        render: NmDropdownV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE]: {
        render: NmDatePicker,
        props: {
            size: "lg",
        },
    },
    [FILTER.DATE_RANGE]: {
        render: NmDateRangePickerV2,
        props: {
            size: "lg",
        },
    },
    [FILTER.DADATA]: {
        render: NmDadata,
        props: {
            size: "lg",
            formatter: getDadataAddress,
        },
    },
    [FILTER.PHONE_WITH_CODE]: {
        render: PhoneWithCodeFilter,
    },
    [FILTER.CHECKBOX]: {
        render: NmCheckboxV2,
    },
    [FILTER.CLIENT_USERS_FIO]: {
        render: ClientUsersFioDropdown,
    },
    [FILTER.CONTRACTOR]: {
        render: FilterContractor,
        props: {
            size: "lg",
        },
    },
    [FILTER.AMOUNT_INPUT]: {
        render: AmountInput,
        props: {
            size: "lg",
            newInput: true,
        },
    },
};

interface IFilter {
    filters?: FiltersConfig,
    wrapFilters?: Array<FilterComponentParams>,
    filterState?: Record<string, FilterValueType>,
    onSubmit: (params?: Record<string, FilterValueType>) => void,
    updateFilter?: (params?: Record<string, FilterValueType>) => void,
    onKeyDown?: (event: KeyboardEvent<HTMLFormElement>) => void,
    initState?: Record<string, FilterValueType>,
    isInitStateEqualEmpty?: boolean,
    className?: string,
    clearFilter: (params?: Record<string, FilterValueType>) => void,
    actionsBottomPosition?: boolean,
    classNameField?: string,
}

const Filter = (props: IFilter) => {
    const {
        onKeyDown,
        filters,
        initState,
        filterState,
        onSubmit,
        updateFilter,
        className = "",
        isInitStateEqualEmpty = false,
        clearFilter,
        actionsBottomPosition,
        wrapFilters,
        classNameField,
    } = props;

    const {t} = useTranslation();
    const [form, setForm] = useState<FilterFormState>(getFilterDefaultState({
        filters,
        wrapFilters,
        initState,
    }));
    const [block, element] = bem("filter", className);
    const flatFieldsArray = useRef<Array<FilterComponentParams>>([]);

    useEffect(() => {
        if (filterState) {
            setForm(filterState);
        }
    }, [
        filterState,
    ]);

    useEffect(() => {
        if (isEmpty(filters)) {
            return;
        }

        const result = filters?.reduce((result, item) => {
            const {
                row,
            } = item;

            return [
                ...result,
                ...row,
            ];
        }, [] as Array<FilterComponentParams>);

        if (!result) {
            return;
        }

        flatFieldsArray.current = result;
    }, [
        filters,
    ]);

    const onChange = (_event: any, params: OnChangeFilterParamType, componentAttributes?: FilterComponentAttributes) => {
        const {
            value,
        } = params;

        if (componentAttributes?.props.fetchCallback) {
            componentAttributes.props.fetchCallback(value);
        }

        if (componentAttributes?.props.clearDependsName) {
            onChangeWithClearDependsFields(params, componentAttributes);

            return;
        }

        updateStateFields(params);
    };

    const updateStateFields = (params: OnChangeFilterParamType, additionalData?: Record<string, any>) => {
        const {
            name,
            value,
            checked,
        } = params;

        const _value = typeof checked === "boolean" ? checked : value;

        const updatedData = additionalData
            ? {
                [name]: _value,
                ...additionalData,
            }
            : {
                [name]: _value,
            };

        if (updateFilter) {
            updateFilter({
                ...form,
                ...updatedData,
            });

            if (filterState) {
                return;
            }
        }

        setForm({
            ...form,
            ...updatedData,
        });
    };

    const onChangeWithClearDependsFields = (params: OnChangeFilterParamType, componentAttributes: FilterComponentAttributes) => {
        const fieldInfo = flatFieldsArray.current.find(item => item.name === componentAttributes.props.clearDependsName);

        if (!fieldInfo) {
            return;
        }

        const defaultValue = getFilterDefaultValue({
            name: fieldInfo.component,
            props: fieldInfo,
        });

        updateStateFields(params, defaultValue);
    };

    const _onClear = () => {
        if (isInitStateEqualEmpty) {
            if (!filterState) {
                setForm({
                    ...initState,
                });
            }

            clearFilter({
                ...initState,
            });

            return;
        }

        if (!filterState) {
            setForm({
                ...getFilterDefaultState({
                    filters,
                    initState: {},
                }),
            });
        }

        clearFilter({
            ...getFilterDefaultState({
                filters,
                initState: {},
            }),
        });
    };

    const _onSubmit = () => {
        onSubmit(form);
    };

    const getProps = (
        componentName: keyof typeof FilterComponent,
        props: Omit<FilterComponentParams, "component">,
        t: (name: string) => string,
    ) => {
        const {
            useTranslation,
            disabledDependsName,
            clearDependsName,
            fetchCallback,
            ...otherProps
        } = props;
        const defaultProps = controls[componentName].props;
        const value = getFilterValue({
            name: componentName,
            props: otherProps,
            form,
        });
        const disabledDependsValue = disabledDependsName && form[disabledDependsName];
        const disabled = Array.isArray(disabledDependsValue) ?
            Boolean(disabledDependsValue.length) :
            Boolean(disabledDependsValue);

        let resultProps: Omit<FilterComponentParams, "component" | "useTranslation" | "disabledDependsName"> = {
            ...otherProps,
            ...defaultProps,
            ...value,
            disabled: disabledDependsName && disabled,
            onChange: (_event: any, params: Omit<OnChangeFilterParamType, "fetchCallback">) => {
                onChange(_event, params, {componentName, props});
            },
        };

        if (useTranslation) {
            resultProps.label = t(resultProps.label);
        }

        if (componentName === FILTER.CHECKBOX) {
            resultProps = {
                ...resultProps,
                checked: Boolean(value.value),
            };
        }

        // Пробрасываем автоматически кнопки покзазать, очистить
        if (componentName === FILTER.DROPDOWN) {
            resultProps = {
                ...resultProps,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        if (componentName === FILTER.DADATA) {
            resultProps = {
                ...resultProps,
                initialAddressIds: value.value,
                onSubmit: resultProps.multiple ? _onSubmit : undefined,
                onClear: _onClear,
            };
        }

        return resultProps;
    };

    const getFilters = () => {
        if (wrapFilters) {
            const filters = wrapFilters.map((item, index) => {
                const {
                    component: componentName,
                    ...props
                } = item;

                const component = controls[componentName].render;
                const resultProps = getProps(componentName, props, t);

                return (
                    <div
                        className={getClassNames([
                            classNameField,
                            "filter__wrap-item",
                        ])}
                        key={index}
                    >
                        {React.createElement(component, resultProps)}
                    </div>
                );
            });

            return (
                <div className="row gx-2">
                    {filters}
                </div>
            );
        }

        const filteredFilters = filters?.map(item => {
            const {
                row,
                ...otherParams
            } = item;

            // ниже фильтруются поля по свойству из конфига внутри row фильтров
            // отображает поле, если у поля showFieldDependsName значение равно одному из showFieldDependsValues
            return {
                ...otherParams,
                row: row.filter(item => {
                    const {
                        showFieldDependsName,
                        showFieldDependsValues,
                    } = item;

                    if (!showFieldDependsName || !showFieldDependsValues) {
                        return true;
                    }

                    const fieldValue = form[showFieldDependsName];

                    return fieldValue && showFieldDependsValues.includes(fieldValue);
                }),
            };
            // если у row не осталось полей, после предыдущего фильтра - не отображаем row
        }).filter(({isVisible = true, row}) => isVisible && Boolean(row.length));

        return filteredFilters?.map((data, index: number) => {
            const {
                row,
            } = data;

            if (row.length === 1) {
                const [{
                    component: componentName,
                    ...props
                }] = row;

                const component = controls[componentName].render;
                const resultProps = getProps(componentName, props, t);

                return (
                    <div
                        key={index}
                        className={element("row")}
                    >
                        {React.createElement(component, resultProps)}
                    </div>
                );
            }

            if (row.length === 2) {
                return (
                    <div
                        key={index}
                        className={element("row", {divide: true})}
                    >
                        {
                            row.map((componentConfig) => {
                                const {
                                    component: componentName,
                                    ...props
                                } = componentConfig;

                                const component = controls[componentName].render;
                                const resultProps = getProps(componentName, props, t);

                                return React.createElement(component, resultProps);
                            })
                        }
                    </div>
                );
            }

            return (
                <div
                    key={index}
                    style={{color: "red"}}
                >
                    Максимальное количество столбцов 2
                </div>
            );
        });
    };

    const getButtons = () => {
        return (
            <FilterButtonsV2
                className={element("actions", {wrap: Boolean(wrapFilters)})}
                onClear={_onClear}
                onSearch={_onSubmit}
            />
        );
    };

    return (
        <NmForm
            className={block({
                wrap: Boolean(wrapFilters),
            })}
            onKeyDown={onKeyDown}
        >
            {
                !actionsBottomPosition && !wrapFilters &&
                getButtons()
            }
            {getFilters()}
            {
                actionsBottomPosition &&
                getButtons()
            }
        </NmForm>
    );
};

export default Filter;