import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {ReactComponent as SuccessIcon} from "../../images/check-bg.svg";
import {ReactComponent as ErrorIcon} from "../../images/fault-bgV2.svg";
import {ReactComponent as NoInfoIcon} from "../../images/no-info.svg";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";
import RefreshButton from "../RefreshButton";

import bem from "../../utils/bem";
import {
    convertUtcToLocal,
    formatDateWithoutTime,
} from "../../utils/dateFormat";

import {NM_COORDINATOR} from "../../constants/roles";

import {getInfoContractorCard} from "../../ducks/bff/contractor-сard/actionCreators";
import {refreshInnAndPassportDataValidStatus} from "../../ducks/contractorPendingPersonalData";

import "./style.sass";

const classNameIcon = "nm-status-passport-inn-contractor__icon";
const getClassNameWithColor = (color) => {
    return `${classNameIcon} ${classNameIcon}_${color}`;
};

export const STATUS_PASSPORT_INN = {
    OK: {
        ICON: <SuccessIcon
            className={classNameIcon}
        />,
        VALUE: "OK",
        TOOLTIP_CONTENT: "ИНН соответствует паспортным данным пользователя",
        IS_REFRESH: false,
    },
    NOT_EXIST: {
        ICON: <NoInfoIcon
            className={getClassNameWithColor("inert")}
        />,
        VALUE: "NOT_EXIST",
        TOOLTIP_CONTENT: "По введенным персональным данным в ФНС России еще не зарегистрирован ИНН",
        WARNING_DATA_CHECK_CONTENT: "Согласование невозможно, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        WARNING_BANK_SENDING_CONTENT: "Отправка в банк невозможна, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        IS_REFRESH: true,
    },
    NOT_EQUALS: {
        ICON: <ErrorIcon
            className={getClassNameWithColor("negative")}
        />,
        VALUE: "NOT_EQUALS",
        TOOLTIP_CONTENT: "ИНН не соответствует паспортным данным пользователя",
        WARNING_DATA_CHECK_CONTENT: "Согласование невозможно, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        WARNING_BANK_SENDING_CONTENT: "Отправка в банк невозможна, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        IS_REFRESH: true,
    },
    NOT_CHECKED: {
        ICON: <ErrorIcon
            className={getClassNameWithColor("secondary")}
        />,
        VALUE: "NOT_CHECKED",
        TOOLTIP_CONTENT: "Проверка еще не выполнялась или невозможна, поскольку сервис ФНС России недоступен. Вы можете отправить повторный запрос, нажав на кнопку обновления справа от статуса",
        WARNING_DATA_CHECK_CONTENT: "Согласование невозможно, поскольку не проведено соответствие ИНН и паспортных данных, вследствие недоступности сервиса ФНС России. Попробуйте запустить проверку повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        WARNING_BANK_SENDING_CONTENT: "Отправка в банк невозможна, поскольку не проведено соответствие ИНН и паспортных данных, вследствие недоступности сервиса ФНС России. Попробуйте запустить проверку повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        IS_REFRESH: true,
    },
    FNS_ERROR: {
        ICON: <ErrorIcon
            className={getClassNameWithColor("secondary")}
        />,
        VALUE: "FNS_ERROR",
        TOOLTIP_CONTENT: "Проверка еще не выполнялась или невозможна, поскольку сервис ФНС России недоступен. Вы можете отправить повторный запрос, нажав на кнопку обновления справа от статуса",
        WARNING_DATA_CHECK_CONTENT: "Согласование невозможно, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        WARNING_BANK_SENDING_CONTENT: "Отправка в банк невозможна, поскольку ИНН не соответствует паспортным данным. Необходимо скорректировать данные и проверить соответствие повторно, нажав на кнопку обновления статуса справа от признака \"Соответствие паспорта и ИНН\"",
        IS_REFRESH: true,
    },
};

function NmStatusPassportInnContractor(props) {
    const {
        label = "Соответствие паспорта и ИНН",
        className = "",
        verificationData,
        role,
        contractor: {
            innPassportDataValidStatus,
            inn,
            idDocNumber,
            lastName,
            birthDate,
            firstName,
            patronymic,
            contractorId,
            citizenship,
            fakeInn,
        },
        onUpdate = () => {
        },
        classNameRefreshButton = "",
        isFetchContractorOnUpdate = true,
    } = props;
    const [block] = bem("nm-status-passport-inn-contractor", className);

    const [isRefresh, setIsRefresh] = useState(false);
    const [status, setStatus] = useState(innPassportDataValidStatus);

    const dispatch = useDispatch();

    const isAccessRefresh = lastName && firstName && birthDate && idDocNumber;

    useEffect(() => {
        if (innPassportDataValidStatus) {
            setStatus(innPassportDataValidStatus);
        }
    }, [innPassportDataValidStatus]);

    const refresh = () => {
        setIsRefresh(true);
        dispatch(refreshInnAndPassportDataValidStatus({
            contractorId,
            inn,
            idDocNumber,
            lastName,
            birthDate: formatDateWithoutTime(convertUtcToLocal(birthDate), "yyyy-MM-dd"),
            firstName,
            patronymic,
            citizenship,
            getResult: (result) => {
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                const {
                    errorMessage,
                    status,
                    errorCode,
                } = result;

                if (errorMessage && !["PASSPORT_DATA_FNS_ERROR", "PASSPORT_DATA_HAS_NOT_INN"].includes(errorCode)) {
                    return;
                }

                onUpdate(status);
                setStatus(status);

                if (isFetchContractorOnUpdate) {
                    dispatch(getInfoContractorCard(contractorId));
                }
            },
        }));
    };

    if (!status) {
        return null;
    }

    return (
        <div className={block({verificationData})}>
            <NmAdvancedTooltip
                hover
                position="bottom-left"
                trigger={
                    STATUS_PASSPORT_INN[status].ICON
                }
                children={STATUS_PASSPORT_INN[status].TOOLTIP_CONTENT}
            />
            <Text
                level="3"
                noWrap
            >
                {label}
            </Text>
            {STATUS_PASSPORT_INN[status].IS_REFRESH && isAccessRefresh && NM_COORDINATOR !== role && !fakeInn &&
            <div className="nm-status-passport-inn-contractor__refresh">
                <RefreshButton
                    animate={isRefresh}
                    onClick={refresh}
                    disabled={isRefresh}
                    className={classNameRefreshButton}
                />
            </div>}
        </div>
    );
}

export default NmStatusPassportInnContractor;