import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_ERROR,
    BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_REQUEST,
    BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/common/client",
        client: "/client-adm/common/client",
    });
};

const controller = getController();

// GET /bff/adm/common/client/maintenance-notice
// GET /bff/client-adm/common/client/maintenance-notice
// Получение уведомления о тех. работах
const getClientMaintenanceNoticeSaga = function* () {
    try {
        const result = yield request.bff.get(`${controller}/maintenance-notice`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
    
            yield put({
                type: BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_ERROR,
                payload: errorMessage,
            });
    
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_REQUEST, getClientMaintenanceNoticeSaga),
    ]);
}