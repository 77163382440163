import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import CreateDepositBaseInvoice from "../../../../../components/CreateDepositBaseIncoive";
import NmButton from "../../../../../components/NmButton";

import {
    createNdflDepositReplenishmentInvoice,
} from "../../../../../ducks/bff/clients/finances/ndfl-deposit/actionCreators";
import {
    getNpdDepositReplenishmentAccountBasisFile,
} from "../../../../../ducks/bff/clients/finances/npd-deposit/actionCreators";
import {
    createPatentDepositReplenishmentInvoice,
} from "../../../../../ducks/bff/clients/finances/patents/actionCreators";
import {clientCardInfoSelector} from "../../../../../ducks/bff/clients/info/selectors";

const DepositClientListAccountBasis = (props) => {
    const {
        clientId,
        accountNumber,
        isNdfl,
        isPatentsPage,
    } = props;

    const dispatch = useDispatch();

    const {name} = useSelector(clientCardInfoSelector);

    const [isOpen, setOpen] = useState(false);

    const openAccountModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const onSubmit = (data) => {
        if (isPatentsPage) {
            dispatch(createPatentDepositReplenishmentInvoice(data));

            return;
        }

        if (isNdfl) {
            dispatch(createNdflDepositReplenishmentInvoice({
                ...data,
                name,
            }));

            return;
        }

        const {
            sum,
            ...requestData
        } = data;

        dispatch(getNpdDepositReplenishmentAccountBasisFile({
            ...requestData,
            totalAmount: sum,
        }));
    };

    const getModal = () => {
        if (!isOpen) {
            return null;
        }

        return (
            <CreateDepositBaseInvoice
                isNdfl={isNdfl}
                accountNumber={accountNumber}
                isVisibleAccountNumber={isNdfl}
                clientId={clientId}
                onSubmit={onSubmit}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <>
            <NmButton
                color="grey"
                size="xl"
                onClick={openAccountModal}
            >
                Сформировать счет-основание
            </NmButton>
            {getModal()}
        </>
    );
};

export default DepositClientListAccountBasis;