import {useState} from "react";
import {useDispatch} from "react-redux";

import {toastSuccess} from "../../../../utils/toastHelper";

import {getClientObjectCard} from "../../../../ducks/bff/clients/objects/card/actionCreators";
import {
    deleteOnboardingObjectContactorsList,
    exportOnboardingObjectContactorsList,
} from "../../../../ducks/onboardingContractors";

export const useObjectContractorsListAction = (props) => {
    const {
        clientId,
        objectId,
        pageSize,
        pageNum,
        fetchList,
        selectedList,
        filter,
        clearSelectedRows,
    } = props;

    const [confirmData, setConfirmData] = useState({});

    const dispatch = useDispatch();

    const exportList = () => {
        dispatch(exportOnboardingObjectContactorsList({
            clientIdFilter: clientId,
            objectIdFilter: objectId,
            pageSize,
            pageNum,
            ...filter,
        }));
    };

    const deleteContractors = () => {
        const contractorIds = selectedList.filter(item => item.isSelected).map(({contractorId}) => contractorId);

        dispatch(deleteOnboardingObjectContactorsList({
            clientId,
            objectId,
            contractorIds,
            onSuccess: () => {
                clearSelectedRows();
                fetchList();
                dispatch(getClientObjectCard({
                    clientId,
                    objectId,
                }));

                toastSuccess("Выбранные исполнители успешно удалены");
            },
        }));
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    return {
        exportList,
        deleteContractors,
        confirmData,
        setConfirmData,
        onCloseConfirm,
    };
};