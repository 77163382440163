import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {getBffCommonController} from "../utils/url";

const controller = "/objects";
//*  TYPES  *//

const OBJECTS_LIST_REQUEST = "OBJECTS_LIST_REQUEST";
const OBJECTS_LIST_SUCCESS = "OBJECTS_LIST_SUCCESS";
const OBJECTS_LIST_ERROR = "OBJECTS_LIST_ERROR";

const GET_OBJECT_REQUEST = "GET_OBJECT_REQUEST";
const GET_OBJECT_SUCCESS = "GET_OBJECT_SUCCESS";
const GET_OBJECT_ERROR = "GET_OBJECT_ERROR";

const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST";
const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS";
const GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR = "GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR";

const GET_PAGE_FOR_OBJECTS_REQUEST = "GET_PAGE_FOR_OBJECTS_REQUEST";
const GET_PAGE_FOR_OBJECTS_SUCCESS = "GET_PAGE_FOR_OBJECTS_SUCCESS";
const GET_PAGE_FOR_OBJECTS_ERROR = "GET_PAGE_FOR_OBJECTS_ERROR";

const GET_RICH_PAGE_FOR_OBJECTS_REQUEST = "GET_RICH_PAGE_FOR_OBJECTS_REQUEST";
const GET_RICH_PAGE_FOR_OBJECTS_SUCCESS = "GET_RICH_PAGE_FOR_OBJECTS_SUCCESS";
const GET_RICH_PAGE_FOR_OBJECTS_ERROR = "GET_RICH_PAGE_FOR_OBJECTS_ERROR";

const UPDATE_OBJECTS_FIELD_STORE = "UPDATE_OBJECTS_FIELD_STORE";

//*  INITIAL STATE  *//

const initial = {
    objects: [],
    pageData: {},
    totalCount: 0,
    card: {},
    objectsUserResponsibleFor: [],
    progressCheck: null,
    progressCard: false,
    progressChangeDeposit: false,
    userCanCreateOrderOnlyByTemplate: false,
    userCanNotCreateOrderByTemplate: false,
    progressUserCanNotCreateOrderByTemplate: null,
    progressUserCanCreateOrderOnlyByTemplate: null,
    optimizedObjects: [],
    objectsForOrders: [],
    objectsForTasks: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_FOR_OBJECTS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case GET_PAGE_FOR_OBJECTS_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case GET_PAGE_FOR_OBJECTS_SUCCESS: {
            const {
                objects,
                totalCount,
                fieldName = "optimizedObjects",
            } = payload;

            return {
                ...state,
                [fieldName]: objects,
                progressList: false,
                totalCount,
            };
        }
        case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST:
            return {
                ...state,
                progressUserCanNotCreateOrderByTemplate: true,
            };
        case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS:
            return {
                ...state,
                progressUserCanNotCreateOrderByTemplate: false,
                userCanNotCreateOrderByTemplate: payload,
            };
        case GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR:
            return {
                ...state,
                progressUserCanNotCreateOrderByTemplate: false,
            };
        case UPDATE_OBJECTS_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_RICH_PAGE_FOR_OBJECTS_REQUEST:
        case OBJECTS_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case GET_RICH_PAGE_FOR_OBJECTS_SUCCESS:
        case OBJECTS_LIST_SUCCESS:
            const {
                objects,
                totalCount,
                fieldName = "objects",
            } = payload;

            return {
                ...state,
                [fieldName]: objects,
                progressList: false,
                totalCount,
            };
        case GET_OBJECT_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case GET_RICH_PAGE_FOR_OBJECTS_ERROR:
        case GET_OBJECT_ERROR:
            return {
                ...state,
                progressCard: false,
                error: payload,
            };
        case OBJECTS_LIST_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        default:
            return state;
    }
};


//*  ACTION CREATORS  *//

export const updateFieldsObjectsStore = payload => ({
    type: UPDATE_OBJECTS_FIELD_STORE,
    payload,
});

export function getObjects(payload) {
    return {
        type: OBJECTS_LIST_REQUEST,
        payload,
    };
}

export function getUserCanNotCreateOrderByTemplate(payload) {
    return {
        type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST,
        payload,
    };
}

// оптимизированный список объектов
export function getOptimizedObjects(payload) {
    return {
        type: GET_PAGE_FOR_OBJECTS_REQUEST,
        payload,
    };
}

// обогащенный список объектов
export function getRichObjects(payload) {
    return {
        type: GET_RICH_PAGE_FOR_OBJECTS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

const objectsSelector = state => state.objects;
export const objectsProgressListSelector = createSelector(objectsSelector, ({progressList}) => progressList);
export const objectsTotalPagesSelector = createSelector(objectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const objectsCardSelector = createSelector(objectsSelector, ({card}) => card);
export const objectsTotalCountSelector = createSelector(objectsSelector, ({totalCount}) => totalCount);
export const objectsProgressUserCanNotCreateOrderByTemplateSelector = createSelector(objectsSelector, ({progressUserCanNotCreateOrderByTemplate}) => progressUserCanNotCreateOrderByTemplate);

export const objectsOptimizedSelector = createSelector(objectsSelector, ({optimizedObjects}) => optimizedObjects);
export const objectsOptimizedOptionsSelector = createSelector(objectsSelector, ({optimizedObjects}) => {
    return optimizedObjects.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});
export const objectsForOrdersOptionsSelector = createSelector(objectsSelector, ({objectsForOrders}) => {
    return objectsForOrders.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});
export const objectsForTasksOptionsSelector = createSelector(objectsSelector, ({objectsForTasks}) => {
    return objectsForTasks.map(item => ({
        key: item.objectId,
        text: item.name || "",
        value: item.objectId,
    }));
});

//*  SAGA  *//

export const getObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: OBJECTS_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: OBJECTS_LIST_ERROR, payload: error});
    }
};

//GET /api/objects/getById
export const getObjectByIdSaga = function* (action) {
    try {
        const {payload: params} = action;
        const result = yield request.get(`${controller}/getById`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_OBJECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_OBJECT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_OBJECT_ERROR, payload: error});
    }
};

// POST /api/objects/userCanNotCreateOrderByTemplate
export const getUserCanNotCreateOrderByTemplateSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/userCanNotCreateOrderByTemplate`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/common/order/get-optimized-objects-page
//POST /bff/client-adm/common/order/get-optimized-objects-page
// оптимизированный список объектов
export const getOptimizedObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.bff.post(`${getBffCommonController()}/order/get-optimized-objects-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_FOR_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_FOR_OBJECTS_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PAGE_FOR_OBJECTS_ERROR, payload: error});
    }
};

// обогащенный список объектов
export const getRichObjectsSaga = function* ({payload}) {
    try {
        const {fieldName, ...reqData} = payload;

        const result = yield request.post(`${controller}/getRichPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RICH_PAGE_FOR_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_RICH_PAGE_FOR_OBJECTS_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_RICH_PAGE_FOR_OBJECTS_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(OBJECTS_LIST_REQUEST, getObjectsSaga),
        takeEvery(GET_OBJECT_REQUEST, getObjectByIdSaga),
        takeEvery(GET_USER_CAN_NOT_CREATE_ORDER_BY_TEMPLATE_REQUEST, getUserCanNotCreateOrderByTemplateSaga),
        takeEvery(GET_PAGE_FOR_OBJECTS_REQUEST, getOptimizedObjectsSaga),
        takeEvery(GET_RICH_PAGE_FOR_OBJECTS_REQUEST, getRichObjectsSaga),

    ]);
}
