export const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST";
export const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS";
export const DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR = "DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR";

export const DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE = "DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE";