import {useDispatch} from "react-redux";

import {useModal} from "../../../../hooks/useModal";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    deleteAdmKedoTemplate,
} from "../../../../ducks/bff/adm/document-templates/kedo/templates/services";
import {downloadDocument} from "../../../../ducks/documents";

export const useKedoTemplatesActions = (params) => {
    const {
        fetchList,
    } = params;

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const downloadFile = ({downloadLink, fileName, extension}) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
            fileName,
            extension,
        }));
    };

    const getListActions = (item) => {
        if (
            ![ADMIN, NM_MANAGER].includes(role)
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsTemplatesKedo,
            ])
        ) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            downloadFile({
                                downloadLink: item.pdfDownloadLink,
                                fileName: `${item.name}.pdf`,
                            });
                        },
                        children: "Скачать PDF",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            downloadFile({
                                downloadLink: item.htmlDownloadLink,
                                fileName: `${item.name}.html`,
                            });
                        },
                        children: "Скачать HTML",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                id: item.id,
                                name: item.name,
                                htmlDownloadLink: item.htmlDownloadLink,
                                userReferenceParamModels: item.userReferenceParamModels,
                            });
                        },
                        children: "Редактировать",
                    },
                    visible: [ADMIN].includes(role),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenConfirm: true,
                            content: "Вы действительно хотите удалить данный шаблон?",
                            onConfirm: () => {
                                dispatch(deleteAdmKedoTemplate({
                                    templateId: item.id,
                                    onSuccess: fetchList,
                                }));
                            },
                        }),
                        children: "Удалить",
                    },
                    visible: !item.inUse
                        && [ADMIN].includes(role),
                },
            ],
        };
    };

    return {
        getListActions,
        onOpenModal,
        onCloseModal,
        modalData,
    };
};