import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getOrdersController} from "../orders/orders-registry/sagas";
import {
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../../../utils/localstorage";
import request, {getMultipartHeaders} from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../utils/url";

import {CLIENT_CARD} from "../../../constants/links";

export const getDocumentsTemplatesController = () => {
    return (
        getBffUrl({
            [CLIENT_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/documents/document-templates",
                client: "/client-adm/documents/document-templates",
            }),
        },
        "/adm/document-templates/naimix/templates",
        true,
        )
    );
};

const getCommonController = () => {
    return (
        getBffUrl({
            isClientCard: true,
            clientRolesUrl: "/client-adm/common/document",
            adminRolesUrl: "/adm/common/document",
        })
    );
};

const getDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_ERROR,
            payload: error,
        });
    }
};

const deleteDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.delete(`${getDocumentsTemplatesController()}/delete`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();
        toastSuccess("Шаблон успешно удален");

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_ERROR, payload: error});
    }
};

const addDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            requestData,
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/add`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateShortPageSaga = function* ({payload}) {
    try {
        const {
            isAggregation,
            aggregationFieldName,
            fieldName,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getCommonController()}/get-document-short-page`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_SUCCESS,
            payload: {
                ...result,
                fieldName,
                aggregationFieldName,
                isAggregation,
            },
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_ERROR,
            payload: error,
        });
    }
};

const updateDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            requestData,
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/update`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_SUCCESS});
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_ERROR,
            payload: error,
        });
    }
};

const validateDocumentsCustomTemplatePageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            requestData,
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/validate-fields`, requestData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            onError(result);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        payload.onError();
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_ERROR, payload: error});
    }
};

const getDocumentsCustomTemplateLogsSaga = function* ({payload}) {
    try {
        const {
            isClientCard,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/log/page`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_ERROR,
            payload: error,
        });
    }
};

const generateDocumentsCustomTemplatePdfSaga = function* ({payload}) {
    const {
        onError,
        requestData,
    } = payload;

    try {
        const response = yield request.bff.post(
            `${getDocumentsTemplatesController()}/validate-pdf`,
            requestData,
            {...getMultipartHeaders()},
        );

        // обработка ошибки
        if (response.status === 200 && response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                onError && onError();

                if (errorMessage) {
                    toastError(errorMessage);
                } else {
                    toastError(data);
                }

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        yield downloadBlob(blob, response.headers);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_SUCCESS});
    } catch (error) {
        onError();
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_ERROR,
            payload: error,
        });
    }
};

const getDocumentCustomTemplateFileByIdSaga = function* ({payload}) {
    const {
        id,
        onSuccess,
        name,
        downloadLink,
    } = payload;

    try {
        const response = yield request.getFile(downloadLink, {params: id});

        const blob = yield response.blob();

        const file = new File([blob], name, {type: blob.type});

        onSuccess(file);

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_SUCCESS});
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateClientUsagePageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/client-usage/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_ERROR,
            payload: error,
        });
    }
};

const updateDocumentsCustomTemplateClientUsageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            changed,
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/client-usage/update`, changed);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_SUCCESS});
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateClientUsageDictSaga = function* () {
    try {
        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/actions-type/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateClientUsageOrderTypeDictSaga = function* () {
    try {
        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/order-types/dict`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateEventLogListSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const result = yield request.bff.post("/adm/document-templates/naimix/event-log/page", data);

        const {errorMessage} = result;

        if (getResult) {
            getResult();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateEventLogTypeDictSaga = function* () {
    try {

        const result = yield request.bff.post("/adm/common/dicts/get-documents-custom-template-event-type");

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_ERROR, payload: error});
    }
};

const setDefaultDocumentsCustomTemplateByIdSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getDocumentsTemplatesController()}/set-default`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_SUCCESS});
    } catch (error) {
        yield put({type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_ERROR, payload: error});
    }
};

const getDocumentsCustomTemplateShortDefaultSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;
        const result = yield request.bff.post(`${getCommonController()}/get-documents-short-page/default`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateShortDefaultAggregation = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getOrdersController()}/get-custom-document-aggregation`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_ERROR,
            payload: error,
        });
    }
};

const getDocumentsCustomTemplateReplacementsForUnavailableListSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getCommonController()}/get-replacements-for-unavailable`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST, getDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST, deleteDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST, addDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST, getDocumentsCustomTemplateShortPageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST, updateDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST, validateDocumentsCustomTemplatePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST, getDocumentsCustomTemplateLogsSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST, generateDocumentsCustomTemplatePdfSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST, getDocumentCustomTemplateFileByIdSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST, getDocumentsCustomTemplateClientUsagePageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST, updateDocumentsCustomTemplateClientUsageSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST, getDocumentsCustomTemplateClientUsageDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST, getDocumentsCustomTemplateClientUsageOrderTypeDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST, setDefaultDocumentsCustomTemplateByIdSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST, getDocumentsCustomTemplateEventLogListSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST, getDocumentsCustomTemplateEventLogTypeDictSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST, getDocumentsCustomTemplateShortDefaultSaga),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST, getDocumentsCustomTemplateShortDefaultAggregation),
        takeEvery(DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST, getDocumentsCustomTemplateReplacementsForUnavailableListSaga),
    ]);
}