import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

export const settingsClientGroupsCardSearchContractorsSelector = state => state.bff.settingsClientGroupsCardSearchContractors;
export const settingsClientGroupsCardSearchContractorsTotalPagesSelector = createSelector(settingsClientGroupsCardSearchContractorsSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const settingsClientGroupsCardSearchContractorsTotalCountSelector = createSelector(settingsClientGroupsCardSearchContractorsSelector, ({totalCount}) => totalCount);
export const settingsClientGroupsCardSearchContractorsListSelector = createSelector(settingsClientGroupsCardSearchContractorsSelector, ({list}) => list);
export const settingsClientGroupsCardSearchContractorsProgressSelector = createSelector(settingsClientGroupsCardSearchContractorsSelector, ({progress}) => progress);
export const settingsClientGroupsCardSearchContractorsProgressActionSelector = createSelector(settingsClientGroupsCardSearchContractorsSelector, ({progressAction}) => progressAction);
