export const DEPOSIT_OPERATION = {
    ADD_DEPOSIT: "ADD_DEPOSIT",
    RETURN_DEPOSIT: "RETURN_DEPOSIT",
    ADD_OBJECT_DEPOSIT: "ADD_OBJECT_DEPOSIT",
    RETURN_DEPOSIT_FOR_OBJECT: "RETURN_DEPOSIT_FOR_OBJECT",
    RETURN_OBJECT_DEPOSIT: "RETURN_OBJECT_DEPOSIT",
    ADD_DEPOSIT_FROM_OBJECT: "ADD_DEPOSIT_FROM_OBJECT",
    ADD_PROJECT_DEPOSIT: "ADD_PROJECT_DEPOSIT",
    RETURN_DEPOSIT_FOR_PROJECT: "RETURN_DEPOSIT_FOR_PROJECT",
    RETURN_PROJECT_DEPOSIT: "RETURN_PROJECT_DEPOSIT",
    ADD_DEPOSIT_FROM_PROJECT: "ADD_DEPOSIT_FROM_PROJECT",
    ADD_DEPOSIT_FROM_CLIENT: "ADD_DEPOSIT_FROM_CLIENT",
    RETURN_DEPOSIT_FOR_CLIENT_NON_DISTRIBUTED: "RETURN_DEPOSIT_FOR_CLIENT_NON_DISTRIBUTED",
    RETURN_DEPOSIT_FOR_CLIENT: "RETURN_DEPOSIT_FOR_CLIENT",
    ADD_DEPOSIT_FROM_CLIENT_NON_DISTRIBUTED: "ADD_DEPOSIT_FROM_CLIENT_NON_DISTRIBUTED",
    CLOSE_DEPOSIT: "CLOSE_DEPOSIT",
    ADD_CLOSED_DEPOSIT: "ADD_CLOSED_DEPOSIT",
};

export const DEPOSIT_OPERATION_TRANSCRIPTION = {
    [DEPOSIT_OPERATION.ADD_DEPOSIT]: "Пополнение депозита",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT]: "Возврат денежных средств",
    [DEPOSIT_OPERATION.ADD_OBJECT_DEPOSIT]: "Пополнение депозита объекта",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT_FOR_OBJECT]: "Возврат депозита для объекта",
    [DEPOSIT_OPERATION.RETURN_OBJECT_DEPOSIT]: "Возврат денежных средств с объекта",
    [DEPOSIT_OPERATION.ADD_DEPOSIT_FROM_OBJECT]: "Пополнение депозита с объекта",
    [DEPOSIT_OPERATION.ADD_PROJECT_DEPOSIT]: "Пополнение депозита проекта",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT_FOR_PROJECT]: "Возврат депозита для проекта",
    [DEPOSIT_OPERATION.RETURN_PROJECT_DEPOSIT]: "Возврат денежных средств с проекта",
    [DEPOSIT_OPERATION.ADD_DEPOSIT_FROM_PROJECT]: "Пополнение депозита с проекта ",
    [DEPOSIT_OPERATION.ADD_DEPOSIT_FROM_CLIENT]: "Пополнение нераспределенного депозита компании из компании",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT_FOR_CLIENT_NON_DISTRIBUTED]: "Возврат депозита для нераспределенного депозита",
    [DEPOSIT_OPERATION.RETURN_DEPOSIT_FOR_CLIENT]: "Возврат нераспределенных денежных средств компании для компании",
    [DEPOSIT_OPERATION.ADD_DEPOSIT_FROM_CLIENT_NON_DISTRIBUTED]: "Пополнение депозита с нераспределенного депозита",
    [DEPOSIT_OPERATION.CLOSE_DEPOSIT]: "Выключение индивидуального депозита",
    [DEPOSIT_OPERATION.ADD_CLOSED_DEPOSIT]: "Пополнение депозита в связи с закрытием депозита уровнем ниже",
};

export const COMMISSION_TYPE = {
    SMZ: "SMZ",
    SMZ_ORDER: "SMZ_ORDER",
    SMZ_REGISTRY: "SMZ_REGISTRY",
    CIVIL: "CIVIL",
    CIVIL_ORDER: "CIVIL_ORDER",
    CIVIL_REGISTRY: "CIVIL_REGISTRY",
    INDIVIDUAL: "INDIVIDUAL",
    INDIVIDUAL_ORDER: "INDIVIDUAL_ORDER",
    INDIVIDUAL_REGISTRY: "INDIVIDUAL_REGISTRY",
};

export const SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS = {
    LIST_OBJECTS: {
        clientRolesUrl: "/client-adm/client/objects/setHideDetailsListObjects",
        adminRolesUrl: "/adm/clients/client-card/client/objects/setHideDetailsListObjects",
    },
    OBJECT: {
        clientRolesUrl: "/client-adm/client/objects/object-card/setHideDetailsObject",
        adminRolesUrl: "/adm/clients/client-card/client/objects/object-card/setHideDetailsObject",
    },
    LIST_PROJECTS: {
        clientRolesUrl: "/client-adm/client/projects/setHideDetailsListProjects",
        adminRolesUrl: "/adm/clients/client-card/client/projects/setHideDetailsListProjects",
    },
    PROJECT: {
        clientRolesUrl: "/client-adm/client/projects/project-card/setHideDetailsProject",
        adminRolesUrl: "/adm/clients/client-card/client/projects/project-card/setHideDetailsProject",
    },
    ORDERS: {
        clientRolesUrl: "/client-adm/orders/orders-registry/set-hide-details-orders",
        adminRolesUrl: "/adm/clients/client-card/orders/orders-registry/set-hide-details-orders",
    },
};

export const GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS = {
    LIST_OBJECTS: {
        clientRolesUrl: "/client-adm/client/objects/getHideDetailsListObjects",
        adminRolesUrl: "/adm/clients/client-card/client/objects/getHideDetailsListObjects",
    },
    OBJECT: {
        clientRolesUrl: "/client-adm/client/objects/object-card/getHideDetailsObject",
        adminRolesUrl: "/adm/clients/client-card/client/objects/object-card/getHideDetailsObject",
    },
    LIST_PROJECTS: {
        clientRolesUrl: "/client-adm/client/projects/getHideDetailsListProjects",
        adminRolesUrl: "/adm/clients/client-card/client/projects/getHideDetailsListProjects",
    },
    PROJECT: {
        clientRolesUrl: "/client-adm/client/projects/project-card/getHideDetailsProject",
        adminRolesUrl: "/adm/clients/client-card/client/projects/project-card/getHideDetailsProject",
    },
    ORDERS: {
        clientRolesUrl: "/client-adm/orders/orders-registry/get-hide-details-orders",
        adminRolesUrl: "/adm/clients/client-card/orders/orders-registry/get-hide-details-orders",
    },
};

export const DEPOSIT_TYPE = {
    OBJECT_INDIVIDUAL_DEPOSIT: "OBJECT_INDIVIDUAL_DEPOSIT",
    PROJECT_INDIVIDUAL_DEPOSIT: "PROJECT_INDIVIDUAL_DEPOSIT",
    CLIENT_DEPOSIT: "CLIENT_DEPOSIT",
};