import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import NmAccordion from "../../../../../components/NmAccordion";
import OrderTimePeriodPicker from "../../../../../components/OrderTimePeriodPicker";
import {OrderEditAccessField} from "../../access-edit";

import {ORDER_CREATION_MODE, ORDER_EDIT_ACCESS} from "../../../../../constants/order";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

class OrderEditAdditional extends Component {
    get translate() {
        const {t} = this.props;

        return {
            insurance: t("order-new.insurance"),
            checkInLabel: t("order-new.check-in"),
            checkInPhotoLabel: t("order-new.request-photo"),
            checkOutLabel: t("order-new.check-out"),
            checkOutPhotoLabel: t("order-new.request-photo"),
            insuranceLabel: t("order-new.insurance-label"),
            checkInHeader: t("order-new.address-control"),
            insuranceSuggestionContent: t("order-new.insuranceSuggestionContent"),
            checkInSuggestionContent: t("order-new.checkInSuggestionContent"),
            checkInPhotoSuggestionContent: t("order-new.checkInPhotoSuggestionContent"),
            checkOutSuggestionContent: t("order-new.checkOutSuggestionContent"),
            checkOutPhotoSuggestionContent: t("order-new.checkOutPhotoSuggestionContent"),
            checkInPeriod: t("order-new.check-in-period"),
            checkOutPeriod: t("order-new.check-out-period"),
        };
    }

    getValue(name, value) {
        const {formData} = this.props;

        const result = [...formData[name]];

        if (formData[name].includes(value)) {
            const index = result.findIndex(foodType => (foodType === value));

            result.splice(index, 1);
        } else {
            result.push(value);
        }

        return result;
    }

    onChange = (event, {name, value}) => {
        const {handleChange} = this.props;

        if (name === "driverLicenseTypes") {
            const licenseTypes = this.getValue(name, value);

            handleChange(event, {name, value: licenseTypes});

            return;
        }

        handleChange(event, {name, value});
    };

    renderControlCard = (mobile, className) => {
        const {
            clientPropertiesCard: {insuranceAvailable},
            formData,
            isPromptOn,
            isDisabled,
            handleChange,
            isDuplicateOrder,
            order,
            creatingMode,
            formError,
        } = this.props;
        const {
            insurance,
            checkInLabel,
            checkInPhotoLabel,
            checkOutLabel,
            checkOutPhotoLabel,
            insuranceLabel,
            checkInHeader,
            insuranceSuggestionContent,
            checkInSuggestionContent,
            checkInPhotoSuggestionContent,
            checkOutSuggestionContent,
            checkOutPhotoSuggestionContent,
            checkInPeriod,
            checkOutPeriod,
        } = this.translate;

        return (
            <div className={className}>
                {insuranceAvailable ?
                    <>
                        {
                            !mobile &&
                            <div className="order-edit-additional__header">
                                {insurance}
                            </div>
                        }
                        <NmCheckboxV2
                            disabled={
                                isDisabled ||
                                (
                                    creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                                    typeof order.insurance === "boolean"
                                )
                            }
                            className="order-edit-additional__row"
                            name="insurance"
                            onChange={handleChange}
                            checked={formData.insurance}
                            label={insuranceLabel}
                            isVisibleTooltip={isPromptOn}
                            tooltip={
                                <HelpTooltip
                                    info
                                    text={insuranceSuggestionContent}
                                    position="bottom"
                                />
                            }
                        />
                    </> :
                    null
                }
                {
                    (!mobile && !insuranceAvailable || insuranceAvailable) &&
                    <div className="order-edit-additional__header">
                        {checkInHeader}
                    </div>
                }
                <OrderEditAccessField
                    name="checkInRequiredEditStatus"
                    className="order-edit-additional__row"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || (
                                formData.insurance
                                && !isDuplicateOrder
                            )
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkInRequiredEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="checkInRequired"
                        onChange={handleChange}
                        checked={formData.checkInRequired}
                        label={checkInLabel}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                info
                                hover
                                text={checkInSuggestionContent}
                                position="bottom"
                            />
                        }
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="checkInPhotoRequiredEditStatus"
                    className="order-edit-additional__row order-edit-additional__row_indent"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || !formData.checkInRequired
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkInPhotoRequiredEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="checkInPhotoRequired"
                        onChange={handleChange}
                        checked={formData.checkInPhotoRequired}
                        label={checkInPhotoLabel}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                info
                                hover
                                text={checkInPhotoSuggestionContent}
                                position="bottom"
                            />
                        }
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="checkInFromToEditStatus"
                    className="order-edit-additional__row order-edit-additional__row_indent"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || !formData.checkInRequired
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkInFromToEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="isRequiredCheckInPeriod"
                        onChange={handleChange}
                        checked={formData.isRequiredCheckInPeriod}
                        label={checkInPeriod}
                    />
                </OrderEditAccessField>
                {
                    formData.isRequiredCheckInPeriod &&
                    <OrderTimePeriodPicker
                        isFilteringEndValues={false}
                        disabled={
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && formData.templateParams.checkInFromToEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                        }
                        className="order-edit-additional__row order-edit-additional__row_indent"
                        name="checkInTimePeriod"
                        onChange={handleChange}
                        value={formData.checkInTimePeriod}
                        error={formError.checkInTimePeriod}
                    />
                }
                <OrderEditAccessField
                    name="checkOutRequiredEditStatus"
                    className="order-edit-additional__row"
                    params={{
                        tooltip: (
                            <HelpTooltip
                                info
                                hover
                                text={checkOutSuggestionContent}
                                position="bottom"
                            />
                        ),
                    }}
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || (formData.insurance && !isDuplicateOrder)
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkOutRequiredEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="checkOutRequired"
                        onChange={handleChange}
                        checked={formData.checkOutRequired}
                        label={checkOutLabel}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                info
                                hover
                                text={checkOutSuggestionContent}
                                position="bottom"
                            />
                        }
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="checkOutPhotoRequiredEditStatus"
                    className="order-edit-additional__row order-edit-additional__row_indent"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || !formData.checkOutRequired
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkOutPhotoRequiredEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="checkOutPhotoRequired"
                        onChange={handleChange}
                        checked={formData.checkOutPhotoRequired}
                        label={checkOutPhotoLabel}
                        isVisibleTooltip={isPromptOn}
                        tooltip={
                            <HelpTooltip
                                info
                                hover
                                text={checkOutPhotoSuggestionContent}
                                position="bottom"
                            />
                        }
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="checkOutFromToEditStatus"
                    className="order-edit-additional__row order-edit-additional__row_indent"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || !formData.checkOutRequired
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.checkOutFromToEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="isRequiredCheckOutPeriod"
                        onChange={handleChange}
                        checked={formData.isRequiredCheckOutPeriod}
                        label={checkOutPeriod}
                    />
                </OrderEditAccessField>
                {
                    formData.isRequiredCheckOutPeriod &&
                    <OrderTimePeriodPicker
                        isFilteringEndValues={false}
                        disabled={
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && formData.templateParams.checkOutFromToEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                        }
                        startValue="9:00"
                        endValue="18:00"
                        className="order-edit-additional__row_indent"
                        name="checkOutTimePeriod"
                        error={formError.checkOutTimePeriod}
                        onChange={handleChange}
                        value={formData.checkOutTimePeriod}
                    />
                }
            </div>
        );
    };

    renderDriverParams = () => {
        const {
            formData,
            isDisabled,
            handleChange,
            driverError,
            creatingMode,
        } = this.props;

        const isForbiddenEdit = formData.templateParams.driverLicenseTypesAndContractorHasCarEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN;

        return (
            <div className="order-edit-additional__driver">
                <NmCheckboxV2
                    disabled={
                        isDisabled ||
                        !formData.needDriverLicense ||
                        (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="driverLicenseTypes"
                    checked={formData.driverLicenseTypes.includes("A")}
                    onChange={this.onChange}
                    errorStyle={driverError}
                    value="A"
                    label="A"
                />
                <NmCheckboxV2
                    disabled={
                        isDisabled
                        || !formData.needDriverLicense
                        || (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="driverLicenseTypes"
                    checked={formData.driverLicenseTypes.includes("B")}
                    onChange={this.onChange}
                    errorStyle={driverError}
                    value="B"
                    label="B"
                />
                <NmCheckboxV2
                    disabled={
                        isDisabled
                        || !formData.needDriverLicense
                        || (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="driverLicenseTypes"
                    checked={formData.driverLicenseTypes.includes("C")}
                    onChange={this.onChange}
                    errorStyle={driverError}
                    value="C"
                    label="C"
                />
                <NmCheckboxV2
                    disabled={
                        isDisabled
                        || !formData.needDriverLicense
                        || (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="driverLicenseTypes"
                    checked={formData.driverLicenseTypes.includes("D")}
                    onChange={this.onChange}
                    errorStyle={driverError}
                    value="D"
                    label="D"
                />
                <NmCheckboxV2
                    disabled={
                        isDisabled
                        || !formData.needDriverLicense
                        || (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="driverLicenseTypes"
                    checked={formData.driverLicenseTypes.includes("E")}
                    onChange={this.onChange}
                    errorStyle={driverError}
                    value="E"
                    label="E"
                />
                <NmCheckboxV2
                    disabled={
                        isDisabled
                        || !formData.needDriverLicense
                        || (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                            && isForbiddenEdit
                        )
                    }
                    name="contractorHasCar"
                    onChange={handleChange}
                    checked={formData.contractorHasCar}
                    label="Есть автомобиль"
                    error={driverError}
                />
            </div>
        );
    };

    renderRequirementsCard = (mobile, className) => {
        const {
            formData,
            isDisabled,
            handleChange,
            creatingMode,
            order,
        } = this.props;

        return (
            <div className={className}>
                {
                    !mobile &&
                    <div className="order-edit-additional__header">
                        Требования к самозанятому
                    </div>
                }
                <OrderEditAccessField
                    name="needMedicalBookEditStatus"
                    className="order-edit-additional__row"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.needMedicalBookEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="needMedicalBook"
                        onChange={handleChange}
                        checked={formData.needMedicalBook}
                        label="Медицинская книжка"
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="needDriverLicenseEditStatus"
                    className="order-edit-additional__row"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.needDriverLicenseEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="needDriverLicense"
                        onChange={handleChange}
                        checked={formData.needDriverLicense}
                        label="Водительские права"
                    />
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="driverLicenseTypesAndContractorHasCarEditStatus"
                    className="order-edit-additional__row order-edit-additional__row_indent"
                >
                    {this.renderDriverParams()}
                </OrderEditAccessField>
                <OrderEditAccessField
                    name="needUniformEditStatus"
                    className="order-edit-additional__row"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled ||
                            (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.needUniformEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="needUniform"
                        onChange={handleChange}
                        checked={formData.needUniform}
                        label="Требуется униформа"
                    />
                </OrderEditAccessField>
                <div className="order-edit-additional__header">
                    Дополнительные условия
                </div>
                <NmTextareaV2
                    disabled={
                        (
                            creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE &&
                            Boolean(order.additionalTerms)
                        )
                    }
                    name="additionalTerms"
                    value={formData.additionalTerms}
                    maxLength={1000}
                    maxRows={6}
                    onChange={handleChange}
                />
            </div>
        );
    };

    renderMessagesCard = (mobile, className) => {
        const {
            formData,
            formError,
            isDisabled,
            handleChange,
            creatingMode,
        } = this.props;

        return (
            <div className={className}>
                {
                    !mobile &&
                    <div className="order-edit-additional__header">
                        Сообщение при отклике
                    </div>
                }
                <OrderEditAccessField
                    name="contractorJoinMessageEditStatus"
                    className="order-edit-additional__row"
                >
                    <NmCheckboxV2
                        disabled={
                            isDisabled
                            || (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.contractorJoinMessageEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="useContractorJoinMessage"
                        onChange={handleChange}
                        checked={formData.useContractorJoinMessage}
                        label="Отправлять сообщение при отклике"
                    />
                </OrderEditAccessField>
                {
                    formData.useContractorJoinMessage &&
                    <NmTextareaV2
                        required={formData.useContractorJoinMessage}
                        error={formError.contractorJoinMessage}
                        disabled={
                            (
                                creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                                && formData.templateParams.contractorJoinMessageEditStatus === ORDER_EDIT_ACCESS.FORBIDDEN
                            )
                        }
                        name="contractorJoinMessage"
                        value={formData.contractorJoinMessage}
                        maxLength={255}
                        maxRows={6}
                        onChange={handleChange}
                        placeholder="Напишите текст сообщения"
                    />
                }
            </div>
        );
    };

    renderResponseMessages = (isMobile, cardClassName) => {
        return (
            isMobile ?
                <NmAccordion
                    defaultOpen
                    className="mb-2"
                    name="Сообщение при отклике"
                    classNameTitle="order-edit-additional__header order-edit-additional__header_no-mb"
                >
                    {this.renderMessagesCard(isMobile, cardClassName)}
                </NmAccordion> :
                this.renderMessagesCard(isMobile, cardClassName)
        );
    };

    render() {
        const {
            clientPropertiesCard: {insuranceAvailable},
            isMobile,
        } = this.props;
        const {
            insurance,
            checkInHeader,
        } = this.translate;

        const cardClassName = isMobile ? "order-edit-additional__card order-edit-additional__card_mobile" : "order-edit-additional__card";

        return (
            <div className="order-edit-additional">
                {
                    isMobile ?
                        <NmAccordion
                            defaultOpen
                            className="mb-2"
                            name={insuranceAvailable ? insurance : checkInHeader}
                            classNameTitle="order-edit-additional__header order-edit-additional__header_no-mb"
                        >
                            {this.renderControlCard(isMobile, cardClassName)}
                        </NmAccordion> :
                        this.renderControlCard(isMobile, cardClassName)
                }
                {
                    isMobile ?
                        <NmAccordion
                            defaultOpen
                            className="mb-2"
                            name="Требования к самозанятому"
                            classNameTitle="order-edit-additional__header order-edit-additional__header_no-mb"
                        >
                            {this.renderRequirementsCard(isMobile, cardClassName)}
                        </NmAccordion> :
                        this.renderRequirementsCard(isMobile, cardClassName)
                }
                {this.renderResponseMessages(isMobile, cardClassName)}
            </div>
        );
    }
}

export default connect(state => ({
    clientPropertiesCard: clientCardPropertiesSelector(state),
}), {
})(withTranslation()(OrderEditAdditional));