export const DOCUMENT_TYPE = {
    FRAME_CONTRACT: "FRAME_CONTRACT",
    AGENCY_CONTRACT: "AGENCY_CONTRACT",
    END_WORK_NOTIFY: "END_WORK_NOTIFY",
    START_WORK_NOTIFY: "START_WORK_NOTIFY",
    CONSENT_TO_PROCESSING_PERSONAL_DATA: "CONSENT_TO_PROCESSING_PERSONAL_DATA",
    AGENCY_CONTRACT_CLIENT: "AGENCY_CONTRACT_CLIENT",
    INSURANCE_POLICY: "INSURANCE_POLICY",
    INSURANCE_POLICY_ACTUAL: "INSURANCE_POLICY_ACTUAL",
    INSURANCE_POLICY_NOT_ACTUAL: "INSURANCE_POLICY_NOT_ACTUAL",
    CASH_RECEIPT: "CASH_RECEIPT", // Квитанция о перечислении денежных средств на карту исполнителя
    NAIMIX_REWARD_RECEIPT: "NAIMIX_REWARD_RECEIPT", // Квитанция о перечислении вознаграждения Наймикс
    ORDER_APPLICATION: "ORDER_APPLICATION",
    ACT_OF_ACCEPTANCE_OF_WORK: "ACT_OF_ACCEPTANCE_OF_WORK",
    RECEIPT_OF_PAYMENT: "RECEIPT_OF_PAYMENT", // Квитанция об уплате налоговых отчислений
    CIVIL_FRAME_CONTRACT: "CIVIL_FRAME_CONTRACT",
    INDIVIDUAL_FRAME_CONTRACT: "INDIVIDUAL_FRAME_CONTRACT",
    TRANSACTION_RECEIPT: "TRANSACTION_RECEIPT", // Квитанция о перечислении денежных средств со счета ЭДС на карту
    CONTRACTOR_PHONE_TRANSACTION_RECEIPT: "CONTRACTOR_PHONE_TRANSACTION_RECEIPT",
    OTHER_DOCUMENT: "OTHER_DOCUMENT",
    TASK_APPLICATION: "TASK_APPLICATION",
    ACT_OF_ACCEPTANCE_OF_TASK: "ACT_OF_ACCEPTANCE_OF_TASK",
    ADDITIONAL_AGREEMENT: "ADDITIONAL_AGREEMENT", // Доп. соглашения
    DEFERRED_ACT_OF_ACCEPTANCE_OF_WORK: "DEFERRED_ACT_OF_ACCEPTANCE_OF_WORK", // Отложенные акты
};

export const DOCUMENT_FIELD = {
    AGENCY_CONTRACT: "agencyContract",
    CONSENT_TO_PROCESSING_PERSONAL_DATA: "consentToPersonalData",
    FRAME_CONTRACT: "frameContract",
    ORDER_APPLICATION: "orderApplication",
    ACT_OF_ACCEPTANCE_OF_WORK: "actOfAcceptanceOfWork",
    INSURANCE_POLICY: "insurancePolicy",
    RECEIPT_RNKO: "receiptRNKO",
};

export const DOCUMENT_STATUS = {
    ACT_OF_ACCEPTANCE_SIGNED: "ACT_OF_ACCEPTANCE_SIGNED",
    ADDITIONAL_AGREEMENT_SIGNED: "ADDITIONAL_AGREEMENT_SIGNED",
    ADDITIONAL_AGREEMENT_TERMINATED: "ADDITIONAL_AGREEMENT_TERMINATED",
    AGENCY_CONTRACT_ACTIVE: "AGENCY_CONTRACT_ACTIVE",
    AGENCY_CONTRACT_TERMINATED: "AGENCY_CONTRACT_TERMINATED",
    CONSENT_TO_PROCESSING_PERSONAL_DATA_ACTIVE: "CONSENT_TO_PROCESSING_PERSONAL_DATA_ACTIVE",
    CONSENT_TO_PROCESSING_PERSONAL_DATA_ARCHIVED: "CONSENT_TO_PROCESSING_PERSONAL_DATA_ARCHIVED",
    END_WORK_NOTIFY_ARCHIVED: "END_WORK_NOTIFY_ARCHIVED",
    END_WORK_NOTIFY_FORMED: "END_WORK_NOTIFY_FORMED",
    FRAME_CONTRACT_ACTIVE: "FRAME_CONTRACT_ACTIVE",
    FRAME_CONTRACT_TERMINATED: "FRAME_CONTRACT_TERMINATED",
    INSURANCE_POLICY_ACTIVE: "INSURANCE_POLICY_ACTIVE",
    INSURANCE_POLICY_ARCHIVED: "INSURANCE_POLICY_ARCHIVED",
    ORDER_APPLICATION_COMPLETED: "ORDER_APPLICATION_COMPLETED",
    ORDER_APPLICATION_IN_WORK: "ORDER_APPLICATION_IN_WORK",
    START_WORK_NOTIFY_ARCHIVED: "START_WORK_NOTIFY_ARCHIVED",
    START_WORK_NOTIFY_FORMED: "START_WORK_NOTIFY_FORMED",
    TASK_APPLICATION_COMPLETED: "TASK_APPLICATION_COMPLETED",
    TASK_APPLICATION_IN_WORK: "TASK_APPLICATION_IN_WORK",
};

export const DOCUMENT_STATUS_COLOR_TRANSCRIPT = {
    ACT_OF_ACCEPTANCE_SIGNED: "green",
    ADDITIONAL_AGREEMENT_SIGNED: "light-green",
    ADDITIONAL_AGREEMENT_TERMINATED: "red",
    AGENCY_CONTRACT_ACTIVE: "light-green",
    AGENCY_CONTRACT_TERMINATED: "red",
    CONSENT_TO_PROCESSING_PERSONAL_DATA_ACTIVE: "light-green",
    CONSENT_TO_PROCESSING_PERSONAL_DATA_ARCHIVED: "gray",
    END_WORK_NOTIFY_ARCHIVED: "gray",
    END_WORK_NOTIFY_FORMED: "light-green",
    FRAME_CONTRACT_ACTIVE: "light-green",
    FRAME_CONTRACT_TERMINATED: "red",
    INSURANCE_POLICY_ACTIVE: "light-green",
    INSURANCE_POLICY_ARCHIVED: "gray",
    ORDER_APPLICATION_COMPLETED: "gray",
    ORDER_APPLICATION_IN_WORK: "light-green",
    START_WORK_NOTIFY_ARCHIVED: "gray",
    START_WORK_NOTIFY_FORMED: "light-green",
    OTHER_DOCUMENT_SIGNED: "green",
    OTHER_DOCUMENT_NOT_SIGNED: "gray",
};

export const DOCUMENT_TYPE_DICT = {
    [DOCUMENT_TYPE.FRAME_CONTRACT]: {
        TEXT: "Договор",
        VALUE:  DOCUMENT_TYPE.FRAME_CONTRACT,
        FIELD: "FrameContract",
    },
    [DOCUMENT_TYPE.INSURANCE_POLICY]: {
        TEXT: "Страховой полис",
        VALUE:  DOCUMENT_TYPE.INSURANCE_POLICY,
        FIELD: "FrameContract",
    },
    [DOCUMENT_TYPE.AGENCY_CONTRACT]: {
        TEXT: "Агентский договор с самозанятым",
        VALUE:  DOCUMENT_TYPE.AGENCY_CONTRACT,
    },
    [DOCUMENT_TYPE.END_WORK_NOTIFY]: {
        TEXT: "Уведомление в ФМС о прекращении договора",
        VALUE:  DOCUMENT_TYPE.END_WORK_NOTIFY,
    },
    [DOCUMENT_TYPE.START_WORK_NOTIFY]: {
        TEXT: "Уведомление в ФМС о заключении договора",
        VALUE:  DOCUMENT_TYPE.START_WORK_NOTIFY,
    },
    [DOCUMENT_TYPE.CONSENT_TO_PROCESSING_PERSONAL_DATA]: {
        TEXT: "Согласие на обработку персональных данных",
        VALUE:  DOCUMENT_TYPE.CONSENT_TO_PROCESSING_PERSONAL_DATA,
    },
    [DOCUMENT_TYPE.AGENCY_CONTRACT_CLIENT]: {
        TEXT: "Агентский договор с заказчиком",
        VALUE:  DOCUMENT_TYPE.AGENCY_CONTRACT_CLIENT,
    },

};

export const documentType1c = {
    ONE_ACT_EXPORT: {
        key: "ONE_ACT_EXPORT",
        text: "Поступление товаров услуг",
        value: "ONE_ACT_EXPORT",
    },
    // SALES_REPORT: {
    //     key: "SALES_REPORT",
    //     value: "SALES_REPORT",
    //     text: "Отчет комиссионера о продажах",
    // },
    ONE_WRITE_OFF_EXPORT: {
        key: "ONE_WRITE_OFF_EXPORT",
        text: "Списание c расчетного счета",
        value: "ONE_WRITE_OFF_EXPORT",
    },
    ONE_ACT_AND_WRITE_OFF_EXPORT: {
        key: "ONE_ACT_AND_WRITE_OFF_EXPORT",
        text: "Поступление товаров услуг и списание c расчетного счета",
        value: "ONE_ACT_AND_WRITE_OFF_EXPORT",
    },
    ONE_PATENT_PAYMENTS_REPORT: {
        key: "ONE_PATENT_PAYMENTS_REPORT",
        text: "Отчет по оплатам патентов в формате XML",
        value: "ONE_PATENT_PAYMENTS_REPORT",
    },
    ONE_SMZ_PAYMENTS_EXPORT: {
        key: "ONE_SMZ_PAYMENTS_EXPORT",
        text: "Отчет по оплатам Самозанятым в формате XML ",
        value: "ONE_SMZ_PAYMENTS_EXPORT",
    },
    ONE_INDIVIDUAL_PAYMENTS_EXPORT: {
        key: "ONE_INDIVIDUAL_PAYMENTS_EXPORT",
        text: "Отчет по оплатам ИП в формате XML",
        value: "ONE_INDIVIDUAL_PAYMENTS_EXPORT",
    },
};

export const documentType1cAll = {
    ...documentType1c,
    ONE_SALES_REPORT: {
        key: "ONE_SALES_REPORT",
        value: "ONE_SALES_REPORT",
        text: "Отчет комиссионера о продажах",
    },
    ONE_WRITE_OFF_EXPORT: {
        key: "ONE_WRITE_OFF_EXPORT",
        text: "Списание c расчетного счета",
        value: "ONE_WRITE_OFF_EXPORT",
    },
    ONE_ACT_AND_WRITE_OFF_EXPORT: {
        key: "ONE_ACT_AND_WRITE_OFF_EXPORT",
        text: "Поступление товаров услуг и списание c расчетного счета",
        value: "ONE_ACT_AND_WRITE_OFF_EXPORT",
    },
};

export const DOCUMENT_FIELD_NAME = {
    CLIENT_ID: "clientId",
    CLIENT_NAME: "clientName",
    CONTRACTOR_ID: "contractorId",
    CONTRACTOR_NAME: "contractorName",
    CONTRACTOR_PHONE: "contractorPhone",
    DATE_TIME: "datetime",
    DOCUMENT_TYPE: "documentType",
    DOWNLOAD_LINK: "downloadLink",
    EXT_DOWNLOAD_LINK: "extDownloadLink",
    ORDER_ID: "orderId",
    ORDER_NAME: "orderName",
    ORDER_NUM: "orderNum",
};

export const DOCUMENT_NOTIFICATIONS_FIELD_NAME = {
    ADDRESS: "address",
    LATITUDE: "latitude",
    LONGITUDE: "longitude",
    REGION: "region",
    FEDERAL_ID: "federalId",
};

export const DOCUMENT_UNFILLED_REQUISITES_OVM = "Для скачивания уведомления в ФМС " +
    "необходимо указать адрес места выполнения работ и подразделение ОВМ. " +
    "Для указания данных реквизитов нажмите кнопку подменю в соответствующей " +
    "строке и выберите пункт \"Редактировать\"";

export const DOCUMENT_TEMPLATES_LIST_FIELD = {
    FRAME_CONTRACT: "frameContractTemplatesList",
    ORDER_APPLICATION: "orderApplicationTemplatesList",
    ACT_OF_ACCEPTANCE_OF_WORK: "actTemplatesList",
    OTHER_DOCUMENTS: "otherDocumentsTemplatesList",
};

export const DOCUMENT_SOURCE_TYPE = {
    FRAME_CONTRACT: "FRAME_CONTRACT",
    ORDER: "ORDER",
};

export const DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS = {
    CREATED: "CREATED", // Формируется
    SUBMITTED_TO_SIGNING: "SUBMITTED_TO_SIGNING", // Передан на подписание
    PARTIALLY_SIGNED: "PARTIALLY_SIGNED", // Подписан частично
    DECLINED: "DECLINED", // Отклонен
    FULLY_SIGNED: "FULLY_SIGNED", // Подписан полностью
};

export const DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS = {
    CREATED: "CREATED", // Формируется
    SUBMITTED_TO_SIGNING: "SUBMITTED_TO_SIGNING", // Передан на подписание
    PARTIALLY_SIGNED: "PARTIALLY_SIGNED", // Подписан частично
    DECLINED: "DECLINED", // Отклонен
    FULLY_SIGNED: "FULLY_SIGNED", // Подписан полностью
};

// Константа используется также для реестров отложенных актов
export const DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD = {
    CREATED: "gray", // Формируется
    SUBMITTED_TO_SIGNING: "orange", // Передан на подписание
    PARTIALLY_SIGNED: "light-green", // Подписан частично
    DECLINED: "red", // Отклонен
    FULLY_SIGNED: "green", // Подписан полностью
};


export const DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS = {
    SUBMIT_ERROR: "SUBMIT_ERROR", // Ошибка при отправке акта
    REJECTED: "REJECTED", // Отклонено
    IN_WORK: "IN_WORK", // В работе
    SIGNED: "SIGNED", // Подписано исполнителем
    CREATED: "CREATED", // Формируется
};

export const DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS_BADGE_STATUS_MOD = {
    SUBMIT_ERROR: "red", // Ошибка при отправке акта
    REJECTED: "red", // Отклонено
    IN_WORK: "light-green", // В работе
    SIGNED: "green", // Подписано исполнителем
    CREATED: "gray", // Формируется
};

export const DOCUMENT_ADDITIONAL_AGREEMENT_STATUS = {
    SIGNATURE_PREPARATION_ERROR: "SIGNATURE_PREPARATION_ERROR", // Ошибка при передаче на подписание
    DECLINED: "DECLINED", // Отклонен
    AWAITING_SIGNATURE: "AWAITING_SIGNATURE", // На подписании
    SIGNED: "SIGNED", // Подписан
};

export const DOCUMENT_ADDITIONAL_AGREEMENT_BADGE_STATUS_MOD = {
    SIGNATURE_PREPARATION_ERROR: "red", // Ошибка при передаче на подписание
    DECLINED: "red", // Отклонен
    AWAITING_SIGNATURE: "orange", // На подписании
    SIGNED: "green", // Подписан
};