import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ImageSlider from "../ActualComponents/ImageSlider";
import Text from "../ActualComponents/Text";
import LabelTextApp from "../LabelTextApp";
import {Loader} from "semantic-ui-react";

import bem from "../../utils/bem";
import {getClassNames} from "../../utils/classNames";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {CONTRACTOR_ADDITIONAL_FILE_TYPES, CONTRACTOR_FILE_TYPES} from "../../constants/clientList";
import {COLOR} from "../../constants/color";
import {PRELOADER_DOCUMENT_BASE64} from "../../constants/document/documentContractorScan";

import {contractorCardInfoSelector} from "../../ducks/bff/contractor-сard/selectors";
import {
    fileContractorOriginalScanObjSelector,
    fileContractorScanObjSelector,
    getContractorAdditionalDocumentFile,
    getFileContractorAdditionalScan,
    getFileContractorScan,
} from "../../ducks/fileStore";

import "./style.sass";

const ContractorInfoCardContent = (props) => {
    const {
        className,
        labels,
        scans,
        isFullWidthInfoBlocks,
    } = props;

    const {contractorId} = useSelector(contractorCardInfoSelector);
    const contractorScanObj = useSelector(fileContractorScanObjSelector);
    const contractorOriginalScanObj = useSelector(fileContractorOriginalScanObjSelector);

    const [fullScreenScanType, setFullScreenScanType] = useState("");
    const [scanSliderData, setScanSliderData] = useState({});

    const [block, element] = bem("contractor-info-card-content", className);
    const infoBlocksDesktopWidthClassName = isFullWidthInfoBlocks ? "" : "col-xl-8";

    const dispatch = useDispatch();

    const fetchContractorOriginalScan = (params) => {
        const {
            type,
            index,
        } = params;

        if (Object.keys(CONTRACTOR_ADDITIONAL_FILE_TYPES).includes(type)) {
            dispatch(getContractorAdditionalDocumentFile({
                contractorId,
                type,
                thumbnail: false,
                field: "contractorOriginalScanObj",
                index,
            }));

            return;
        }

        if ([CONTRACTOR_FILE_TYPES.INN].includes(type)) {
            dispatch(getFileContractorAdditionalScan({
                contractorId,
                field: "contractorOriginalScanObj",
                contractorFileType: type,
                thumbnail: false,
                index,
            }));

            return;
        }

        dispatch(getFileContractorScan({
            contractorId,
            contractorFileType: type,
            thumbnail: false,
            field: "contractorOriginalScanObj",
            isPending: true,
            index,
        }));
    };

    const showFullScreenScan = (params) => {
        const {
            isShowFullSlider,
            type,
            totalCount,
        } = params;

        if (isShowFullSlider) {
            setScanSliderData({
                isOpen: true,
                type,
                totalCount,
            });

            return;
        }

        if (!contractorOriginalScanObj[type]) {
            fetchContractorOriginalScan({type});
        }

        setFullScreenScanType(type);
    };

    const mapLabels = (labels) => {
        if (!labels) {
            return null;
        }

        return (
            labels.map((labelProps, index) => {
                if (!labelProps) {
                    return null;
                }

                const {
                    className,
                    row,
                    blockName,
                    ...otherProps
                } = labelProps;

                if (blockName) {
                    return (
                        <Text
                            className="mt-4 mb-4"
                            type="title"
                        >
                            {blockName}
                        </Text>
                    );
                }

                if (row) {
                    return (
                        <div
                            key={index}
                            className={element("labels-row")}
                        >
                            {mapLabels(row.labels)}
                        </div>
                    );
                }

                return (
                    <LabelTextApp
                        {...otherProps}
                        emptyText={<Text
                            level="2"
                            color={COLOR.SECONDARY_45}
                        >
                            Сведения пока не добавлены
                        </Text>}
                        key={index}
                        className={`${element("labels-item")} ${className}`}
                    />
                );
            })
        );
    };

    const mapScans = (scans) => {
        return (
            scans.map((scanProps, index) => {
                if (!scanProps) {
                    return null;
                }

                const {
                    block,
                    className,
                    label,
                    type,
                    isShowFullSlider,
                    totalCount,
                } = scanProps;

                if (block) {
                    return (
                        <div
                            key={index}
                            className={element("scans-block")}
                        >
                            <Text
                                type="title"
                                className={element("scans-block-title")}
                            >
                                {block.title}
                            </Text>
                            <div className="flex flex-wrap">
                                {mapScans(block.scans)}
                            </div>
                        </div>
                    );
                }

                const screenObj = isNullOrWhitespace(scanProps.index)
                    ? contractorScanObj[type]
                    : contractorScanObj[type]?.[scanProps.index];
                const {base64str = ""} = screenObj || {};
                const scansCount = totalCount ? ` ${totalCount} фото` : "";

                return (
                    <div
                        key={index}
                        className={`${element("scans-item-container")} ${className}`}
                    >
                        <Text
                            level="2"
                            color={COLOR.SECONDARY_75}
                        >
                            {label}
                            {scansCount}
                        </Text>
                        {
                            isNullOrWhitespace(base64str) ?
                                <Text
                                    level="2"
                                    color={COLOR.SECONDARY_45}
                                >
                                    Файл не загружен
                                </Text> :
                                <div
                                    className={`${element("scans-item-img-container")} flex flex-content-center`}
                                    onClick={() => showFullScreenScan({
                                        isShowFullSlider,
                                        type,
                                        totalCount,
                                    })}
                                >
                                    <img
                                        alt="Cкан"
                                        src={`data:image/jpeg;charset=utf-8;base64, ${base64str || PRELOADER_DOCUMENT_BASE64}`}
                                    />
                                </div>
                        }
                    </div>
                );
            })
        );
    };

    const renderSlider = () => {
        return (
            scanSliderData?.isOpen &&
            <ImageSlider
                onClose={() => setScanSliderData({})}
                images={contractorOriginalScanObj[scanSliderData.type]}
                totalCount={scanSliderData?.totalCount}
                fetchFile={(index) => fetchContractorOriginalScan({
                    type: scanSliderData.type,
                    index,
                })}
            />
        );
    };

    const renderFullScreenScan = () => {
        if (!fullScreenScanType) {
            return null;
        }

        const screenObj = contractorOriginalScanObj[fullScreenScanType];
        const {base64str = ""} = screenObj || {};

        return (
            <div
                className={element("full-screen-scan-container")}
                onClick={() => setFullScreenScanType("")}
            >
                {
                    base64str ?
                        <img
                            alt="Cкан"
                            src={`data:image/jpeg;charset=utf-8;base64, ${base64str}`}
                        /> :
                        <Loader
                            active
                            inverted
                            content="Загрузка"
                        />
                }
            </div>
        );
    };

    return (
        <div className={block()}>
            {renderSlider()}
            {renderFullScreenScan()}
            <div
                className={getClassNames([
                    "col-16",
                    infoBlocksDesktopWidthClassName,
                ])}
            >
                {mapLabels(labels)}
            </div>
            {
                scans &&
                <div
                    className={getClassNames([
                        "flex flex-wrap flex-aligned-start col-16",
                        infoBlocksDesktopWidthClassName,
                        labels && "mt-3 mt-xl-0",
                    ])}
                >
                    {mapScans(scans)}
                </div>
            }
        </div>
    );
};

export default ContractorInfoCardContent;