import React from "react";
import {useContext} from "react";

import ScrollableContainer from "../../../../components/ActualComponents/ScrollableContainer";
import {AppContext} from "../../../../components/AppContext";

export const ClientCardContainer = (props) => {
    const {
        children,
        className,
    } = props;
    const {
        filter,
    } = useContext(AppContext);

    return (
        <ScrollableContainer
            saveScrollPosition={true}
            isOpenFilter={filter.filterData.isOpenFilter}
            className={className}
        >
            {children}
        </ScrollableContainer>
    );
};