import {
    EDM_POSITION_ADD_REQUEST,
    EDM_POSITION_COMMON_COUNT_REQUEST,
    EDM_POSITION_DELETE_REQUEST,
    EDM_POSITION_LIST_READ_REQUEST,
    EDM_POSITION_UPDATE_REQUEST,
} from "./actions";

export function getEdmPositionList(payload) {
    return {
        type: EDM_POSITION_LIST_READ_REQUEST,
        payload,
    };
}

export function updateEdmPosition(payload) {
    return {
        type: EDM_POSITION_UPDATE_REQUEST,
        payload,
    };
}

export function deleteEdmPosition(payload) {
    return {
        type: EDM_POSITION_DELETE_REQUEST,
        payload,
    };
}

export function addEdmPosition(payload) {
    return {
        type: EDM_POSITION_ADD_REQUEST,
        payload,
    };
}

export function getEdmPositionCommonCount(payload) {
    return {
        type: EDM_POSITION_COMMON_COUNT_REQUEST,
        payload,
    };
}