import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {withRouter} from "react-router";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import ButtonArchive from "../../../components/ButtonArchive";
import NmButton from "../../../components/NmButton";
import NmEmptyPage from "../../../components/NmEmptyPage";
import NmPage from "../../../components/NmPage";
import UserFilter from "../../../components/UserFilter";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import SettingsUsersTabs from "./components/SettingsUsersTabs";
import UserEditModal from "./edit-modal";
import SettingsUsersList from "./list_2";

import useConfirm, {closeConfirmAction, onConfirmAction} from "../../../hooks/useConfirm";
import {usePagination} from "../../../hooks/usePagination";
import {useUsersFetchList} from "./hooks/useFetchList";
import useUserFormEdit, {changeVisibleForm} from "./hooks/useUserFormEdit";
import useUserList from "./hooks/useUserList";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {OptionType} from "../../document-management/document-management-statement/list/item/utils/getOptions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {MEMBER_POSITIONS_DICT} from "../../../constants/memberPositions";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    RNKO,
    roleSettingsList,
    roleSettingsListByCurrentMemberRole,
} from "../../../constants/roles";

import {clientMemberProgressAddSelector} from "../../../ducks/bff/clients/employees/selectors";
import {
    bffSettingsSettingsEmployeesClientsForSelectionOptionsSelector,
    bffSettingsSettingsEmployeesListSelector,
    bffSettingsSettingsEmployeesProgressUpdateSelector,
    bffSettingsSettingsEmployeesTotalCountDataSelector,
    bffSettingsSettingsEmployeesTotalPagesDataSelector,
} from "../../../ducks/bff/settings/employees/selectors";
import {
    promocodePartnerClientsOptionsSelector,
    promocodePartnerContractorsOptionsSelector,
} from "../../../ducks/promocode";

import "./style.sass";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../constants/link-params";

const initFilter = {
    roleListFilter: [],
    projectIdsFilter: [],
    emailFilter: "",
    phoneFilter: "",
    fioFilter: "",
    sampleType: "",
};

const SettingsUsersContainer = (props: any) => {
    const {
        subpage,
        clientId,
    } = props.match.params;
    const role = ls(USER_ROLE) || "";

    const savingProgress = useSelector(bffSettingsSettingsEmployeesProgressUpdateSelector);
    const addingProgress = useSelector(clientMemberProgressAddSelector);
    const {otherTotalPages, partnerTotalPages} = useSelector(bffSettingsSettingsEmployeesTotalPagesDataSelector);
    const {otherCount, partnerCount} = useSelector(bffSettingsSettingsEmployeesTotalCountDataSelector);
    const list = useSelector(bffSettingsSettingsEmployeesListSelector);
    const clientsForSelectionOptions = useSelector(bffSettingsSettingsEmployeesClientsForSelectionOptionsSelector);
    const totalCount = subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK ? otherCount : partnerCount;
    const totalPages = subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK ? otherTotalPages : partnerTotalPages;

    function mapOptions(role: string): OptionType[] {
        if (role === ADMIN) {
            return roleSettingsList;
        }

        // @ts-ignore
        return roleSettingsListByCurrentMemberRole[role];
    }

    const roleOptions = useMemo(() => mapOptions(role), [role]);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const isAccessActions = isAccessByRestrictions([CLIENT_USER_RESTRICTIONS_VARIABLE.accessEmployees]);
    const positionsOptions = useMemo(() => dictionaryToOptions(MEMBER_POSITIONS_DICT), []);
    const isEditable = useMemo(() => {
        return (
            [ADMIN, NM_MANAGER].includes(role)
            && isAccessActions
        );
    }, [
        role,
        isAccessActions,
    ]);
    const promocodePartnerClientsOptions = useSelector(promocodePartnerClientsOptionsSelector);
    const promocodePartnerContractorsOptions = useSelector(promocodePartnerContractorsOptionsSelector);
    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm();
    const [sortData, setSortData] = useState<string | null | undefined>();
    const [filter, setFilter] = useState(initFilter);
    const {archived, loading, setArchived, fetchList} = useUsersFetchList({
        pageNum,
        pageSize,
        subpage,
        sortData,
        filter,
    });
    const {t} = useTranslation();
    const [form, formError, dispatchForm, isVisibleForm, passwordForm, isEditPassword] = useUserFormEdit(subpage, fetchList);

    const [dispatchList] = useUserList(fetchList);


    useEffect(() => {
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setFilter(initFilter);
    }, [subpage]);

    const toggleArchived = useCallback(() => {
        setArchived(!archived);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    }, [archived, pageSize]);

    const openEditForm = () => {
        dispatchForm(changeVisibleForm());
    };

    const handleOnConfirm = () => {
        dispatchConfirm(onConfirmAction());
    };

    function getTitleModal(): string {
        if (subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK) {
            return form.clientUserId ? `Редактирование: ${form.fullName}` : "Новый сотрудник";
        }

        return form.clientUserId ? `Редактирование: ${form.fullName}` : "Новый партнер";
    }

    const submitFilter = (filter: any) => {
        setFilter({...filter});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const onClickSort = (option: OptionType) => {
        const {sortType} = option;

        setSortData(sortType);
    };

    return (
        <NmPage
            header={
                [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                <SettingsUsersTabs
                    otherCount={otherCount}
                    partnerCount={partnerCount}
                    subpage={subpage}
                    role={role}
                />
            }
            controls={<>
                {
                    ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, RNKO].includes(role) &&
                    !archived &&
                    isAccessActions &&
                    <NmButton
                        icon={<AddIcon />}
                        size="xl"
                        onClick={openEditForm}
                        className="settings-user-container__add"
                    >
                        {subpage === SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK ? "Добавить сотрудника" : "Добавить партнера"}
                    </NmButton>
                }
                <ButtonArchive
                    onClick={toggleArchived}
                    disabled={loading}
                    archivedFilter={archived}
                />
            </>}
            openFilter
            widthByFilter
            typeFilter="vertical"
            filtersBase={
                <UserFilter
                    clientsForSelectionOptions={clientsForSelectionOptions}
                    subpage={subpage}
                    roleOptions={roleSettingsList}
                    initFilter={initFilter}
                    submitFilter={submitFilter}
                />
            }
            isLoaded={loading}
            className="settings-user-container"
            currentPageSize={totalCount < 25 ? 0 : pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        >
            {
                isVisibleForm &&
                <UserEditModal
                    currentClientUserId={ls(CURRENT_CLIENT_USER_ID) ?? ""}
                    roleOptions={roleOptions}
                    positionsOptions={positionsOptions}
                    isEditPassword={isEditPassword}
                    title={getTitleModal()}
                    passwordForm={passwordForm}
                    promocodePartnerClientsOptions={promocodePartnerClientsOptions}
                    promocodePartnerContractorsOptions={promocodePartnerContractorsOptions}
                    loading={savingProgress || addingProgress}
                    subpage={subpage}
                    user={form}
                    t={t}
                    formError={formError}
                    dispatchForm={dispatchForm}
                    clientId={clientId}
                />
            }
            {list.length === 0 &&
                <NmEmptyPage
                    title="Информация отсутствует"
                />
            }
            {isOpenConfirm &&
                <NmConfirmV2
                    content={contentConfirm}
                    onCancel={() => dispatchConfirm(closeConfirmAction())}
                    onConfirm={handleOnConfirm}
                    confirmButton="Да"
                    cancelButton="Нет"
                    isOnlyConfirm
                />
            }
            {
                list.length > 0 &&
                <SettingsUsersList
                    archived={archived}
                    isEditable={isEditable}
                    dispatchForm={dispatchForm}
                    dispatchList={dispatchList}
                    dispatchConfirm={dispatchConfirm}
                    onClickSort={onClickSort}
                    sortData={sortData}
                    list={list}
                    subpage={subpage}
                />
            }
        </NmPage>
    );
};

export default withRouter(SettingsUsersContainer);