import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

import {
    ADMIN,
    NM_MANAGER,
} from "../../../../constants/roles";

import {
    addDirectoriesDocuments,
    deleteDirectoriesDocuments,
    getDirectoriesDocumentsPage,
    updateDirectoriesDocuments,
} from "../../../../ducks/bff/settings/directories/naimix/documents/actionCreators";
import {
    settingsDirectoriesNaimixDocumentsListSelector,
    settingsDirectoriesNaimixDocumentsTotalCountSelector,
    settingsDirectoriesNaimixDocumentsTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/documents/selectors";

const ADD_BUTTON_TEXT = "Добавить тип документа";
const LABEL_FILTER = "Тип документа";
const POPUP_TITLE = {
    EDIT: "Редактирование типа документа",
    DELETE: "Вы действительно хотите удалить данный тип документа из справочника?",
    ADD: "Добавление типа документа",
};

const SettingsDirectoriesDocumentTypesContainer = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        initFilter: {valueFilter: ""},
        pageSize,
        setPagination,
    });

    const dispatch = useDispatch();

    const totalPages = useSelector(settingsDirectoriesNaimixDocumentsTotalPagesSelector);
    const totalCount = useSelector(settingsDirectoriesNaimixDocumentsTotalCountSelector);
    const list = useSelector(settingsDirectoriesNaimixDocumentsListSelector);

    const isEditable = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER],
    });

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    const fetchList = () => {
        const {
            valueFilter,
        } = filterData;

        dispatch(getDirectoriesDocumentsPage({
            pageNum,
            pageSize,
            valueFilter: valueFilter ? valueFilter : undefined,
        }));
    };

    const onAdd = data => {
        dispatch(addDirectoriesDocuments(data));
    };

    const onDelete = data => {
        dispatch(deleteDirectoriesDocuments(data));
    };

    const onEdit = data => {
        dispatch(updateDirectoriesDocuments(data));
    };

    return (
        <UpdatingDirectories
            isSearch={isSearch}
            isEditable={isEditable}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            filter={filter}
            fetchList={fetchList}
            clearFilter={onClear}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            onSubmitFilter={onSearch}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText={ADD_BUTTON_TEXT}
            popupTitleAdd={POPUP_TITLE.ADD}
            popupTitleEdit={POPUP_TITLE.EDIT}
            popupTitleDelete={POPUP_TITLE.DELETE}
            labelFilter={LABEL_FILTER}
            inputTypePlaceholder="Тип документа"
        />
    );
};

export default SettingsDirectoriesDocumentTypesContainer;