import {
    CHECK_STATEMENT_FILE_REQUEST,
    DOWNLOAD_EDO_EXPORT_FILES_REQUEST,
    GET_EDO_COUNTS_BY_STAFF_ID_REQUEST,
    GET_EDO_EXPORT_LIST_REQUEST,
    GET_EDO_EXPORT_STATUSES_REQUEST,
    GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST,
    GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST,
    GET_STATEMENTS_ARCHIVE_REQUEST,
    GET_STATEMENTS_INFO_COUNT_REQUEST,
    GET_STATEMENTS_PAGE_REQUEST,
    GET_STATEMENTS_SOURCE_FILE_REQUEST,
    GET_STATEMENTS_TYPES_PAGE_REQUEST,
    REJECT_STATEMENT_REQUEST,
    REPLACE_EDO_STATEMENT_REQUEST,
    REVIEW_STATEMENT_REQUEST,
    SEND_STATEMENT_VERIFICATION_CODE_REQUEST,
    SIGN_STATEMENT_REQUEST,
    TASK_EXPORT_EDO_DOCUMENT_REQUEST,
    UPLOAD_EDO_STATEMENT_REQUEST,
} from "./actions";

export function getEdoExportStatuses(payload) {
    return {
        type: GET_EDO_EXPORT_STATUSES_REQUEST,
        payload,
    };
}

export function downloadEdoExportFiles(payload) {
    return {
        type: DOWNLOAD_EDO_EXPORT_FILES_REQUEST,
        payload,
    };
}


export function getEdoExportList(payload) {
    return {
        type: GET_EDO_EXPORT_LIST_REQUEST,
        payload,
    };
}

export function taskEdoExportDocument(payload) {
    return {
        type: TASK_EXPORT_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function getStatementsPage(payload) {
    return {
        type: GET_STATEMENTS_PAGE_REQUEST,
        payload,
    };
}

export function getStatementTypesPage(payload) {
    return {
        type: GET_STATEMENTS_TYPES_PAGE_REQUEST,
        payload,
    };
}

export function archiveStatement(payload) {
    return {
        type: GET_STATEMENTS_ARCHIVE_REQUEST,
        payload,
    };
}

export function getStatementsSourceFile(payload) {
    return {
        type: GET_STATEMENTS_SOURCE_FILE_REQUEST,
        payload,
    };
}

export function getStatementsTemplatePreFilledFile(payload) {
    return {
        type: GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST,
        payload,
    };
}

export function getStatementTemplateEmptyFile(payload) {
    return {
        type: GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST,
        payload,
    };
}

export function uploadEdoStatement(payload) {
    return {
        type: UPLOAD_EDO_STATEMENT_REQUEST,
        payload,
    };
}

export function replaceEdoStatement(payload) {
    return {
        type: REPLACE_EDO_STATEMENT_REQUEST,
        payload,
    };
}

export function sendStatementCodeVerification(payload) {
    return {
        type: SEND_STATEMENT_VERIFICATION_CODE_REQUEST,
        payload,
    };
}

export function signStatement(payload) {
    return {
        type: SIGN_STATEMENT_REQUEST,
        payload,
    };
}

export function rejectStatement(payload) {
    return {
        type: REJECT_STATEMENT_REQUEST,
        payload,
    };
}

export function reviewStatement(payload) {
    return {
        type: REVIEW_STATEMENT_REQUEST,
        payload,
    };
}

export function getCountsEdoByStaffId(payload) {
    return {
        type: GET_EDO_COUNTS_BY_STAFF_ID_REQUEST,
        payload,
    };
}

export function checkStatementFile(payload) {
    return {
        type: CHECK_STATEMENT_FILE_REQUEST,
        payload,
    };
}

export function getStatementsInfoCount(payload) {
    return {
        type: GET_STATEMENTS_INFO_COUNT_REQUEST,
        payload,
    };
}