export const GET_CLIENT_INFO_REQUEST = "GET_CLIENT_INFO_REQUEST";
export const GET_CLIENT_INFO_SUCCESS = "GET_CLIENT_INFO_SUCCESS";
export const GET_CLIENT_INFO_ERROR = "GET_CLIENT_INFO_ERROR";

export const UPDATE_CLIENT_INFO_REQUEST = "UPDATE_CLIENT_INFO_REQUEST";
export const UPDATE_CLIENT_INFO_SUCCESS = "UPDATE_CLIENT_INFO_SUCCESS";
export const UPDATE_CLIENT_INFO_ERROR = "UPDATE_CLIENT_INFO_ERROR";

export const GET_CURRENT_CLIENT_INFO_REQUEST = "GET_CURRENT_CLIENT_INFO_REQUEST";
export const GET_CURRENT_CLIENT_INFO_SUCCESS = "GET_CURRENT_CLIENT_INFO_SUCCESS";
export const GET_CURRENT_CLIENT_INFO_ERROR = "GET_CURRENT_CLIENT_INFO_ERROR";

export const GET_CLIENT_PROPERTIES_REQUEST = "GET_CLIENT_PROPERTIES_REQUEST";
export const GET_CLIENT_PROPERTIES_SUCCESS = "GET_CLIENT_PROPERTIES_SUCCESS";
export const GET_CLIENT_PROPERTIES_ERROR = "GET_CLIENT_PROPERTIES_ERROR";

export const UPDATE_CLIENT_PROPERTIES_REQUEST = "UPDATE_CLIENT_PROPERTIES_REQUEST";
export const UPDATE_CLIENT_PROPERTIES_SUCCESS = "UPDATE_CLIENT_PROPERTIES_SUCCESS";
export const UPDATE_CLIENT_PROPERTIES_ERROR = "UPDATE_CLIENT_PROPERTIES_ERROR";

export const UPDATE_CLIENT_REQUISITES_REQUEST = "UPDATE_CLIENT_REQUISITES_REQUEST";
export const UPDATE_CLIENT_REQUISITES_SUCCESS = "UPDATE_CLIENT_REQUISITES_SUCCESS";
export const UPDATE_CLIENT_REQUISITES_ERROR = "UPDATE_CLIENT_REQUISITES_ERROR";

export const GET_CLIENT_REF_PROGRAM_REQUEST = "GET_CLIENT_REF_PROGRAM_REQUEST";
export const GET_CLIENT_REF_PROGRAM_SUCCESS = "GET_CLIENT_REF_PROGRAM_SUCCESS";
export const GET_CLIENT_REF_PROGRAM_ERROR = "GET_CLIENT_REF_PROGRAM_ERROR";

export const ADD_CLIENT_REF_PROGRAM_REQUEST = "ADD_CLIENT_REF_PROGRAM_REQUEST";
export const ADD_CLIENT_REF_PROGRAM_SUCCESS = "ADD_CLIENT_REF_PROGRAM_SUCCESS";
export const ADD_CLIENT_REF_PROGRAM_ERROR = "ADD_CLIENT_REF_PROGRAM_ERROR";

export const GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST = "GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST";
export const GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS = "GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS";
export const GET_CLIENT_REF_PROGRAM_HISTORY_ERROR = "GET_CLIENT_REF_PROGRAM_HISTORY_ERROR";

export const CHECK_CLIENT_PROMOCODE_REQUEST = "CHECK_CLIENT_PROMOCODE_REQUEST";
export const CHECK_CLIENT_PROMOCODE_SUCCESS = "CHECK_CLIENT_PROMOCODE_SUCCESS";
export const CHECK_CLIENT_PROMOCODE_ERROR = "CHECK_CLIENT_PROMOCODE_ERROR";

export const GET_CLIENT_SPECIALITIES_REQUEST = "GET_CLIENT_SPECIALITIES_REQUEST";
export const GET_CLIENT_SPECIALITIES_SUCCESS = "GET_CLIENT_SPECIALITIES_SUCCESS";
export const GET_CLIENT_SPECIALITIES_ERROR = "GET_CLIENT_SPECIALITIES_ERROR";

export const GET_ORDER_CATEGORY_ALL_REQUEST = "GET_ORDER_CATEGORY_ALL_REQUEST";
export const GET_ORDER_CATEGORY_ALL_SUCCESS = "GET_ORDER_CATEGORY_ALL_SUCCESS";
export const GET_ORDER_CATEGORY_ALL_ERROR = "GET_ORDER_CATEGORY_ALL_ERROR";

export const UPDATE_FIELDS_CLIENT_CARD_INFO_STORE = "UPDATE_FIELDS_CLIENT_CARD_INFO_STORE";

export const CLEAR_FIELDS_CLIENT_CARD_INFO_STORE = "CLEAR_FIELDS_CLIENT_CARD_INFO_STORE";