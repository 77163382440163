import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import SettingsServicesMaintenanceNoticeForm from "./components/form";

import {updateStoreSettingsStats} from "../../../../ducks/bff/settings/service/actionCreators";

const SettingsServiceMaintenanceNotice = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsStats({
                notifications: {},
            }));
        };
    }, []);

    return (
        <NmPage
            overflowUnset={true}
            noPadding
            header={
                <NmPageHeader text="Уведомление о ведении технических работ" />
            }
        >
            <div className="row">
                <div className="col-16 col-md-8 col-xxl-5">
                    <SettingsServicesMaintenanceNoticeForm  />
                </div>
                <div className="col-16 col-md-8 col-xxl-5">
                    <SettingsServicesMaintenanceNoticeForm
                        isContractor={true}
                    />
                </div>
            </div>
        </NmPage>
    );
};

export default SettingsServiceMaintenanceNotice;