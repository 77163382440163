export const PATENTS_REGISTRY_LIST_REQUEST = "PATENTS_REGISTRY_LIST_REQUEST";
export const PATENTS_REGISTRY_LIST_SUCCESS = "PATENTS_REGISTRY_LIST_SUCCESS";
export const PATENTS_REGISTRY_LIST_ERROR = "PATENTS_REGISTRY_LIST_ERROR";

export const GET_PATENT_REGISTRY_BY_ID_REQUEST = "GET_PATENT_REGISTRY_BY_ID_REQUEST";
export const GET_PATENT_REGISTRY_BY_ID_SUCCESS = "GET_PATENT_REGISTRY_BY_ID_SUCCESS";
export const GET_PATENT_REGISTRY_BY_ID_ERROR = "GET_PATENT_REGISTRY_BY_ID_ERROR";

export const ADD_PATENTS_REGISTRY_REQUEST = "ADD_PATENTS_REGISTRY_REQUEST";
export const ADD_PATENTS_REGISTRY_SUCCESS = "ADD_PATENTS_REGISTRY_SUCCESS";
export const ADD_PATENTS_REGISTRY_ERROR = "ADD_PATENTS_REGISTRY_ERROR";

export const UPDATE_PATENTS_REGISTRY_REQUEST = "UPDATE_PATENTS_REGISTRY_REQUEST";
export const UPDATE_PATENTS_REGISTRY_SUCCESS = "UPDATE_PATENTS_REGISTRY_SUCCESS";
export const UPDATE_PATENTS_REGISTRY_ERROR = "UPDATE_PATENTS_REGISTRY_ERROR";

export const ARCHIVE_PATENTS_REGISTRY_REQUEST = "ARCHIVE_PATENTS_REGISTRY_REQUEST";
export const ARCHIVE_PATENTS_REGISTRY_SUCCESS = "ARCHIVE_PATENTS_REGISTRY_SUCCESS";
export const ARCHIVE_PATENTS_REGISTRY_ERROR = "ARCHIVE_PATENTS_REGISTRY_ERROR";

export const RESTORE_PATENTS_REGISTRY_REQUEST = "RESTORE_PATENTS_REGISTRY_REQUEST";
export const RESTORE_PATENTS_REGISTRY_SUCCESS = "RESTORE_PATENTS_REGISTRY_SUCCESS";
export const RESTORE_PATENTS_REGISTRY_ERROR = "RESTORE_PATENTS_REGISTRY_ERROR";

export const PAY_PATENT_REGISTRY_BY_ID_REQUEST = "PAY_PATENT_REGISTRY_BY_ID_REQUEST";
export const PAY_PATENT_REGISTRY_BY_ID_SUCCESS = "PAY_PATENT_REGISTRY_BY_ID_SUCCESS";
export const PAY_PATENT_REGISTRY_BY_ID_ERROR = "PAY_PATENT_REGISTRY_BY_ID_ERROR";

export const PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST";
export const PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS";
export const PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR = "PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR";

export const CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST = "CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST";
export const CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS = "CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS";
export const CHECK_PATENT_REGISTRY_DUPLICATES_ERROR = "CHECK_PATENT_REGISTRY_DUPLICATES_ERROR";

export const CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST = "CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST";
export const CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS = "CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS";
export const CREATE_DUPLICATE_PATENT_REGISTRY_ERROR = "CREATE_DUPLICATE_PATENT_REGISTRY_ERROR";

export const DELETE_FAILED_PATENT_REGISTRY_REQUEST = "DELETE_FAILED_PATENT_REGISTRY_REQUEST";
export const DELETE_FAILED_PATENT_REGISTRY_SUCCESS = "DELETE_FAILED_PATENT_REGISTRY_SUCCESS";
export const DELETE_FAILED_PATENT_REGISTRY_ERROR = "DELETE_FAILED_PATENT_REGISTRY_ERROR";

export const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST";
export const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS";
export const GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR = "GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR";

export const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST";
export const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS";
export const CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR = "CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR";

export const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST";
export const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS";
export const REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR = "REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR";

export const REGISTRY_PATENT_LOG_REQUEST = "REGISTRY_PATENT_LOG_REQUEST";
export const REGISTRY_PATENT_LOG_SUCCESS = "REGISTRY_PATENT_LOG_SUCCESS";
export const REGISTRY_PATENT_LOG_ERROR = "REGISTRY_PATENT_LOG_ERROR";

export const UPDATE_FIELD_PATENT_REGISTRY_STORE = "UPDATE_FIELD_PATENT_REGISTRY_STORE";