import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_ERROR,
    BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_API_ERROR,
    BFF_CLIENTS_GET_SETTINGS_API_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_API_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_OTHER_ERROR,
    BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST,
    BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST,
    BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_API_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_API_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST,
    BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {
    LINK_DEPOSIT_LIST,
    LINK_FINANCE_EXPORT,
    LINK_NDFL_DEPOSIT_LIST,
    LINK_ORDER_ACT_REGISTRY,
    LINK_ORDER_LIST,
    LINK_ORDER_TEMPLATES_LIST,
} from "../../../../constants/links";

const controller = "/adm/clients/:id/settings";

//GET /bff/adm/clients/{id}/settings/payments
//GET /bff/adm/clients/client-card/finances/npd-deposit/get-client-payment-settings
//GET /bff/client-adm/finances/npd-deposit/get-client-payment-settings
//GET /bff/adm/clients/client-card/finances/ndfl-deposit/get-client-payment-settings
//GET /bff/client-adm/finances/ndfl-deposit/get-client-payment-settings
//GET /bff/adm/clients/client-card/orders/orders-registry/card/contractors/common/get-client-payment-settings
//GET /bff/client-adm/orders/orders-registry/card/contractors/common/get-client-payment-settings
//GET /bff/adm/clients/client-card/orders/act-registry/get-client-payment-settings
//GET /bff/client-adm/orders/act-registry/get-client-payment-settings
//GET /bff/adm/clients/client-card/orders/order-templates/get-client-payment-settings
//GET /bff/client-adm/orders/order-templates/get-client-payment-settings
// Получение настроек: Способы проведения оплат
const getClientsSettingsPayments = function* ({payload}) {
    try {
        const {
            clientId,
            sendParams = true,
        } = payload;

        const url = getBffUrl({
            [LINK_DEPOSIT_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/npd-deposit/get-client-payment-settings",
                client: "/client-adm/finances/npd-deposit/get-client-payment-settings",
            }),
            [LINK_NDFL_DEPOSIT_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/ndfl-deposit/get-client-payment-settings",
                client: "/client-adm/finances/ndfl-deposit/get-client-payment-settings",
            }),
            [LINK_ORDER_TEMPLATES_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/order-templates/get-client-payment-settings",
                client: "/client-adm/orders/order-templates/get-client-payment-settings",
            }),
            [LINK_ORDER_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/orders-registry/card/contractors/common/get-client-payment-settings",
                client: "/client-adm/orders/orders-registry/card/contractors/common/get-client-payment-settings",
            }),
            [LINK_ORDER_ACT_REGISTRY]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/act-registry/get-client-payment-settings",
                client: "/client-adm/orders/act-registry/get-client-payment-settings",
            }),
        },
        `${controller.replace(":id", clientId)}/payments`,
        true,
        );

        const result = yield request.bff.get(url, sendParams && {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_PAYMENTS_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/payments
// Сохранение настроек: Способы проведения оплат
const updateClientsSettingsPayments = function* ({payload}) {
    const {
        clientId,
        reqData,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/payments`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_ERROR,
        });
    }
};


//GET /bff/adm/clients/{id}/settings/misc
// Получение настроек: Способы проведения оплат
const getClientsSettingsOther = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller.replace(":id", clientId)}/misc`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_OTHER_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_OTHER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_OTHER_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/misc
// Сохранение настроек: Способы проведения оплат
const updateClientsSettingsOther = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/misc`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_OTHER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_OTHER_ERROR,
        });
    }
};

// GET /bff/adm/clients/{id}/settings/api
// Получение настроек: Методы внешнего API
const getClientsSettingsApi = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller.replace(":id", clientId)}/api`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_API_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_API_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_API_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/api
// Сохранение настроек: Методы внешнего API
const updateClientsSettingsApi = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/api`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_API_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_API_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_API_ERROR,
        });
    }
};

// GET /bff/adm/clients/{id}/settings/contractors
// Получение настроек: Работа с исполнителями
const getClientsSettingsContractors = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller.replace(":id", clientId)}/contractors`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/contractors
// Сохранение настроек: Работа с исполнителями
const updateClientsSettingsContractors = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/contractors`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR,
        });
    }
};

// GET /bff/adm/clients/{id}/settings/additionalModules
// GET /bff/adm/clients/client-card/reports/export-reports/additional-modules
// GET /bff/client-adm/reports/export-reports/additional-modules
// Получение настроек: Дополнительные модули
const getClientsSettingsAdditionalModules = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const url = getBffUrl(
            {
                [LINK_FINANCE_EXPORT]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/reports/export-reports/additional-modules",
                    client: "/client-adm/reports/export-reports/additional-modules",
                }),
            },
            `${controller.replace(":id", clientId)}/additionalModules`,
            true,
        );

        const result = yield request.bff.get(url, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/additionalModules
// Сохранение настроек: Дополнительные модули
const updateClientsSettingsAdditionalModules = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/additionalModules`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_ERROR,
        });
    }
};


// GET /bff/adm/clients/{id}/settings/documents
// Получение настроек: Документы
const getClientsSettingsDocuments = function* ({payload}) {
    const {
        clientId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller.replace(":id", clientId)}/documents`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_ERROR,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/documents
// Сохранение настроек: Документы
const updateClientsSettingsDocuments = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/documents`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/hideOrdersProjectsPage
// Получение настроек: Скрытие заказов после завершение подбора - страница проектов
const getClientsSettingsHideOrderProjectsPage = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller.replace(":id", clientId)}/hidingOrdersProjectsPage`, {...reqData, clientId});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/hideOrdersProjectsObjectsList
// Получение настроек: Скрытие заказов после завершение подбора - список проектов с объектами
const getClientsSettingsHideOrderProjectsObjectsList = function* ({payload}) {
    const {
        clientId,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller.replace(":id", clientId)}/hidingOrdersProjectsObjectsList`, {...reqData, clientId});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/hideOrdersObjectsPage
// Получение настроек: Скрытие заказов после завершение подбора - страница объектов
const getClientsSettingsHideOrdersObjectsPage = function* ({payload}) {
    const {
        clientId,
        changedProjectInfo,
        changedObjects,
        changedProject,
        _changedObjects,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller.replace(":id", clientId)}/hidingOrdersObjectsPage`, {...reqData, clientId});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_SUCCESS,
            payload: {
                ...result,
                projectId: reqData.projectIdsFilter[0],
            },
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/client/info/hideOrders
// PUT /bff/client-adm/client/info/hideOrders
// Сохранение настроек: Скрытие заказов после завершение подбора
const hideClientsSettingsOrders = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const controller = getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/client/info",
        client: "/client-adm/client/info",
    });

    try {
        const result = yield request.bff.put(`${controller}/hidingOrders`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_ERROR,
        });
    }
};

// POST bff/adm/clients/{clientId}/settings/log-page
const getClientsSettingsLogs = function* ({payload}) {
    try {
        const {
            clientId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`/adm/clients/${clientId}/settings/log-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_LOGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_LOGS_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/tree/projects/page
// Получение древовидных настроек по типу (страница проектов)
const getClientSettingsProjectsTree = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(
            `${controller.replace(":id", clientId)}/tree/projects/page`,
            {...reqData, clientId},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/tree/objects/page
// Получение древовидных настроек по типу (страница объектов)
const getClientSettingsObjectsTree = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(
            `${controller.replace(":id", clientId)}/tree/objects/page`,
            {...reqData, clientId},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_SUCCESS,
            payload: {
                ...result,
                projectId: reqData.projectIdsFilter[0],
            },
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/{id}/settings/tree/list
// Получение древовидных настроек по типу (список проектов с объектами)
const getClientSettingsTreeList = function* ({payload}) {
    const {
        clientId,
        changedProjectInfo,
        changedObjects,
        changedProject,
        _changedObjects,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(
            `${controller.replace(":id", clientId)}/tree/list`,
            {...reqData, clientId},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_LIST_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/{id}/settings/tree/save
// Сохранение древовидных настроек по типу
const saveClientSettingsTree = function* ({payload}) {
    const {
        clientId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${controller.replace(":id", clientId)}/tree/save`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CLIENTS_GET_SETTINGS_PAYMENTS_REQUEST, getClientsSettingsPayments),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_PAYMENTS_REQUEST, updateClientsSettingsPayments),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_OTHER_REQUEST, getClientsSettingsOther),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_OTHER_REQUEST, updateClientsSettingsOther),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_API_REQUEST, updateClientsSettingsApi),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_API_REQUEST, getClientsSettingsApi),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_CONTRACTORS_REQUEST, updateClientsSettingsContractors),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_CONTRACTORS_REQUEST, getClientsSettingsContractors),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_ADDITIONAL_MODULES_REQUEST, updateClientsSettingsAdditionalModules),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_ADDITIONAL_MODULES_REQUEST, getClientsSettingsAdditionalModules),
        takeEvery(BFF_CLIENTS_PUT_SETTINGS_DOCUMENTS_REQUEST, updateClientsSettingsDocuments),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_DOCUMENTS_REQUEST, getClientsSettingsDocuments),
        takeEvery(BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_PAGE_REQUEST, getClientsSettingsHideOrderProjectsPage),
        takeEvery(BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_PROJECTS_OBJECTS_LIST_REQUEST, getClientsSettingsHideOrderProjectsObjectsList),
        takeEvery(BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_OBJECTS_PAGE_REQUEST, getClientsSettingsHideOrdersObjectsPage),
        takeEvery(BFF_CLIENTS_POST_SETTINGS_HIDE_ORDERS_REQUEST, hideClientsSettingsOrders),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_LOGS_REQUEST, getClientsSettingsLogs),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_TREE_PROJECTS_PAGE_REQUEST, getClientSettingsProjectsTree),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_TREE_OBJECTS_PAGE_REQUEST, getClientSettingsObjectsTree),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_TREE_LIST_REQUEST, getClientSettingsTreeList),
        takeEvery(BFF_CLIENTS_GET_SETTINGS_TREE_SAVE_REQUEST, saveClientSettingsTree),
    ]);
}
