import {useEffect, useLayoutEffect} from "react";
import {useDispatch} from "react-redux";

import {getSettingsClientGroupsCard} from "../../../../../ducks/bff/settings/client-groups/card/actionCreators";

export function useGetInfoCardGroup(groupId, importProgress) {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        getCard();
    }, []);

    useEffect(() => {
        if(!importProgress){
            getCard();
        }
    }, [importProgress]);

    function getCard() {
        dispatch(getSettingsClientGroupsCard({groupId}));
    }
}