import {all, put, takeEvery} from "redux-saga/effects";

import {
    BFF_KEDO_STAFF_ADD_ERROR,
    BFF_KEDO_STAFF_ADD_REQUEST,
    BFF_KEDO_STAFF_ADD_SUCCESS,
    BFF_KEDO_STAFF_COMMON_ERROR,
    BFF_KEDO_STAFF_COMMON_REQUEST,
    BFF_KEDO_STAFF_COMMON_SUCCESS,
    BFF_KEDO_STAFF_GET_BY_ID_ERROR,
    BFF_KEDO_STAFF_GET_BY_ID_REQUEST,
    BFF_KEDO_STAFF_GET_BY_ID_SUCCESS,
    BFF_KEDO_STAFF_GET_PAGE_ERROR,
    BFF_KEDO_STAFF_GET_PAGE_REQUEST,
    BFF_KEDO_STAFF_GET_PAGE_SUCCESS,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST,
    BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST,
    BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST,
    BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS,
    BFF_KEDO_STAFF_UPDATE_ERROR,
    BFF_KEDO_STAFF_UPDATE_REQUEST,
    BFF_KEDO_STAFF_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError} from "../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../utils/url";

import {KEDO_ERROR_CODE_TYPE} from "../../../constants/kedo";
import {LINK_CLIENT_KEDO_PROFILE} from "../../../constants/links";

const controllers = {
    client: "/client-adm/kedo/staff-registry",
    admin: "/adm/clients/client-card/kedo/staff-registry",
};

// POST /bff/client-adm/kedo/staff-registry/update
// Обновление сотрудника
const updateKedoStaff = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            [
            LINK_CLIENT_KEDO_PROFILE
                .replace(":clientId", reqData.clientId)
                .replace(":kedoStaffId", reqData.kedoStaffId)
            ]: getBffControllerClientCardPage({
                admin: `${controllers.admin}/staff-card/update`,
                client: `${controllers.client}/staff-card/update`,
            }),
            isClientCard: true,
            clientRolesUrl: `${controllers.client}/update`,
            adminRolesUrl: `${controllers.admin}/update`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_UPDATE_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ERROR,
        });
    }
};

// POST /bff/client-adm/kedo/staff-registry/updateArchiveStatus
// POST /bff/adm/clients/kedo/staff-registry/updateArchiveStatus
// Обновление архивного статуса
const updateKedoArchiveStatusStaff = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}/updateArchiveStatus`,
            adminRolesUrl: `${controllers.admin}/updateArchiveStatus`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            if (
                ![
                    KEDO_ERROR_CODE_TYPE.STAFF_EXISTS_IN_ROUTE,
                    KEDO_ERROR_CODE_TYPE.STAFF_EXIST_ON_SIGN_DOCUMENTS,
                    KEDO_ERROR_CODE_TYPE.DEPARTMENT_ROLE_EXISTS,
                ].includes(errorCode)
            ) {
                toastError(errorMessage);
            }

            yield put({
                type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_ERROR,
        });
    }
};

// POST /bff/client-adm/kedo/staff-registry/importFromFile
// Добавление списка сотрудников из файла
const importKedoStaffFromFile = function* ({payload}) {
    const {
        onSuccess,
        file,
        clientId,
        isClientCard = true,
    } = payload;

    const otherUrl = `/importFromFile?clientId=${clientId}`;

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}${otherUrl}`,
            adminRolesUrl: `${controllers.admin}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(
            url,
            file,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_IMPORT_FROM_FILE_ERROR,
        });
    }
};

// POST /bff/client-adm/kedo/staff-registry/getPage
// Получение страницы Сотрудники
const getKedoStaffPage = function* ({payload}) {
    const otherUrl = "/getPage";

    const url = getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: `${controllers.client}${otherUrl}`,
            adminRolesUrl: `${controllers.admin}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/client-adm/kedo/staff-registry/add
// Получение страницы Сотрудники
const addKedoStaff = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    const otherUrl = "/add";

    const url = getBffUrl(
        {
            isClientCard,
            clientRolesUrl: `${controllers.client}${otherUrl}`,
            adminRolesUrl: `${controllers.admin}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_ADD_SUCCESS,
            payload: result,
        });

        onSuccess();
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_ADD_ERROR,
        });
    }
};

// GET /bff/client-adm/kedo/staff-registry/getById
// Получение страницы сотрудника по id
const getKedoStaffById = function* ({payload}) {
    const {
        pathname,
        isClientCard = true,
        ...reqData
    } = payload;

    const otherUrl = "/getById";

    const url = getBffUrl(
        {
            [pathname]: "/adm/clients/client-card/kedo/staff-registry/staff-card/profile",
            isClientCard,
            clientRolesUrl: `${controllers.client}${otherUrl}`,
            adminRolesUrl: `${controllers.admin}${otherUrl}`,
        },
    );

    try {
        const result = yield request.bff.get(url, {params: reqData});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_BY_ID_ERROR,
        });
    }
};

// GET /bff/client-adm/kedo/staff-registry/staff-card/common
// Получение общей страницы сотрудника
const getKedoStaffCommon = function* ({payload}) {
    const url = getBffUrl(
        {
            isClientCard: true,
            adminRolesUrl: `${controllers.admin}/staff-card/common`,
        },
    );

    try {
        const result = yield request.bff.get(url, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_COMMON_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_COMMON_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_COMMON_ERROR,
        });
    }
};

// POST  /bff/adm/clients/client-card/kedo/staff-registry/staff-card/signature-transactions
// POST /bff/client-adm/kedo/staff-registry/staff-card/signature-transactions
// Получение страницы журнала подписания документов сотрудника
const getKedoStaffSignatureTransactions = function* ({payload}) {
    try {
        const otherUrl = "/staff-card/signature-transactions";
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_KEDO_STAFF_UPDATE_REQUEST, updateKedoStaff),
        takeEvery(BFF_KEDO_STAFF_UPDATE_ARCHIVE_STATUS_REQUEST, updateKedoArchiveStatusStaff),
        takeEvery(BFF_KEDO_STAFF_IMPORT_FROM_FILE_REQUEST, importKedoStaffFromFile),
        takeEvery(BFF_KEDO_STAFF_GET_PAGE_REQUEST, getKedoStaffPage),
        takeEvery(BFF_KEDO_STAFF_ADD_REQUEST, addKedoStaff),
        takeEvery(BFF_KEDO_STAFF_GET_BY_ID_REQUEST, getKedoStaffById),
        takeEvery(BFF_KEDO_STAFF_COMMON_REQUEST, getKedoStaffCommon),
        takeEvery(BFF_KEDO_STAFF_GET_SIGNATURE_TRANSACTIONS_REQUEST, getKedoStaffSignatureTransactions),
    ]);
}