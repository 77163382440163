import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../../../fileStore";
import {
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {getMultipartHeaders} from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/documents-registry/documents-registry-card",
        client: "/client-adm/edo/documents-registry/documents-registry-card",
    });
};

const getDocumentsEdoRegistriesItemsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/richPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR,
            payload: error.message,
        });
    }
};

const getByIdDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${clientId}/${registryId}/rich`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR,
            payload: error.message,
        });
    }
};

const addDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");
        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const updateDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const deleteDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const importDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            registryId,
            onSuccess,
        } = payload;

        const result = yield request.post("/job/edoc/registries/items/import", formData, {
            params: {
                clientId,
                registryId,
            },
            ...getMultipartHeaders(),
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Загрузка файла успешно завершена. Новые исполнители станут доступны через некоторое время.");
        if (onSuccess) {
            onSuccess();
        }
        yield put({
            type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const rejectDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/reject`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const deleteNotCompletedDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/deleteNotCompletedItems`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const checkImportTaskExistsDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.get(`/job/edoc/registries/items/import/task/exists/${clientId}/${registryId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

const updateReceiversDocumentsEdoRegistriesItemsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.post("/job/edoc/registries/items/updateReceiversData", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Задача по обновлению параметров исполнителей успешно запущена. Данные обновятся через некоторое время.");
        if (onSuccess) {
            onSuccess();
        }
        yield put({
            type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST, getDocumentsEdoRegistriesItemsPageSaga),
        takeEvery(DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST, getByIdDocumentsEdoRegistriesItemsSaga),
        takeEvery(ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, addDocumentsEdoRegistriesItemsSaga),
        takeEvery(UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, updateDocumentsEdoRegistriesItemsSaga),
        takeEvery(DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, deleteDocumentsEdoRegistriesItemsSaga),
        takeEvery(IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, importDocumentsEdoRegistriesItemsSaga),
        takeEvery(REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, rejectDocumentsEdoRegistriesItemsSaga),
        takeEvery(DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, deleteNotCompletedDocumentsEdoRegistriesItemsSaga),
        takeEvery(CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, checkImportTaskExistsDocumentsEdoRegistriesItemsSaga),
        takeEvery(UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST, updateReceiversDocumentsEdoRegistriesItemsSaga),
    ]);
}