import {
    CLIENT_OBJECTS_CARD_CLEAR_STORE,
    CLIENT_OBJECTS_CARD_GET_ERROR,
    CLIENT_OBJECTS_CARD_GET_REQUEST,
    CLIENT_OBJECTS_CARD_GET_SUCCESS,
    CLIENT_OBJECTS_CARD_UPDATE_STORE,
    GET_PROJECT_OBJECT_USER_LIST_ERROR,
    GET_PROJECT_OBJECT_USER_LIST_REQUEST,
    GET_PROJECT_OBJECT_USER_LIST_SUCCESS,
} from "./actions";

const initial = {
    card: {},
    progress: false,
    progressAction: false,
    progressObjectUserList: false,
    objectUsersPageData: {},
    objectUserList: [],
    usersTotalCount: 0,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_OBJECTS_CARD_GET_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENT_OBJECTS_CARD_GET_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case CLIENT_OBJECTS_CARD_GET_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_OBJECTS_CARD_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_PROJECT_OBJECT_USER_LIST_REQUEST:
            return {
                ...state,
                progressObjectUserList: true,
                objectUsersPageData: payload,
            };
        case GET_PROJECT_OBJECT_USER_LIST_SUCCESS: {
            const {
                projectUserObjectModels,
                totalCount: usersTotalCount,
            } = payload;

            return {
                ...state,
                progressObjectUserList: false,
                usersTotalCount,
                objectUserList: projectUserObjectModels,
            };
        }
        case GET_PROJECT_OBJECT_USER_LIST_ERROR:
            return {
                ...state,
                progressObjectUserList: false,
            };
        case CLIENT_OBJECTS_CARD_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};