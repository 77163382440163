import {createSelector} from "reselect";

import {ordersOrderGroupSelector} from "../selectors";

export const ordersOrderGroupCardSelector = state => state.bff.orderGroupsCard;

export const getOrderGroupCardSelector = createSelector(
    ordersOrderGroupCardSelector,
    ({card}) => card,
);

export const getOrderGroupCardProgressSelector = createSelector(
    ordersOrderGroupSelector,
    ({progress}) => progress,
);

export const getOrderGroupsCardActionProgressSelector = createSelector(
    ordersOrderGroupSelector,
    ({actionProgress}) => actionProgress,
);