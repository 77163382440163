import {useState} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ADMIN} from "../../../../constants/roles";

import {deleteClientRemoteContractor} from "../../../../ducks/clientRemoteContractors";

export const useClientRemoteContractorsAction = (params) => {
    const {
        fetchList,
    } = params;

    const role = ls(USER_ROLE);

    const [confirmData, setConfirmData] = useState({});

    const dispatch = useDispatch();

    const onClickDelete = (item) => {
        setConfirmData({
            content: "Вы действительно хотите удалить запись?",
            confirm: () => {
                dispatch(deleteClientRemoteContractor({
                    id: item.id,
                    getResult: () => {
                        setConfirmData({});
                        fetchList();
                    },
                }));
            },
        });
    };

    const getMediaControls = (item) => {
        if (![ADMIN].includes(role)) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onClickDelete(item);
                        },
                        color: "grey",
                        children: "Удалить",
                    },
                    visible: isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.deleteLead,
                    ]),
                },
            ],
        };
    };

    return {
        getMediaControls,
        setConfirmData,
        confirmData,
    };
};