import {
    ORDER_ANALYTICS_PAGE_REQUEST,
    ORDER_ANALYTICS_REQUEST,
} from "./actions";

export function getOrderAnalytics(payload) {
    return {
        type: ORDER_ANALYTICS_REQUEST,
        payload,
    };
}

export function getOrderAnalyticsPage(payload) {
    return {
        type: ORDER_ANALYTICS_PAGE_REQUEST,
        payload,
    };
}