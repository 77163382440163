import React, {ReactNode, useState} from "react";

import {ReactComponent as IconChevron} from "../../images/chevron-down.svg";

import bem from "../../utils/bem";

import "./style.sass";

interface INmAccordion {
    title?: string | ReactNode,
    children: ReactNode,
    className?: string,
    cloClass?: string,
    onOpen?: (open: boolean) => void,
    defaultOpen?: boolean,
    classNameTitle?: string,
    additionalTitle?: string | ReactNode,
    noStyles?: boolean,
    isAdditionalTitleHiddenByOpen?: boolean
    iconColor?: string,
}

const NmAccordionV2 = (props: INmAccordion) => {
    const {
        title,
        children,
        className,
        defaultOpen = false,
        classNameTitle = "",
        onOpen,
        additionalTitle,
        noStyles,
        isAdditionalTitleHiddenByOpen = true,
        iconColor,
    } = props;
    const [open, setOpen] = useState(defaultOpen);
    const [block, element] = bem("nm-accordion-v2", className);

    const onClick = () => {
        setOpen(!open);
        onOpen && onOpen(!open);
    };

    return (
        <div className={block({open, noStyles})}>
            <IconChevron
                width={24}
                height={24}
                onClick={onClick}
                className={element("icon", {noStyles, withColor: !iconColor})}
                color={iconColor}
            />
            <div
                onClick={onClick}
                className={`${element("name")} ${classNameTitle}`}
            >
                {title}
                {" "}
                {isAdditionalTitleHiddenByOpen && open ? null : additionalTitle}
            </div>
            {open && children}
        </div>
    );
};

export default NmAccordionV2;