import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import {ClientSettings} from "../../components/ActualComponents/ClientSettings";
import Filter from "../../components/ActualComponents/Filter";
import {MediaButtons} from "../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../components/ActualComponents/NmHorizontalToggleV2";
import NmModal from "../../components/ActualComponents/NmModal";
import Text from "../../components/ActualComponents/Text";
import ApplyButtons from "../../components/ApplyButtons";
import CheckboxList from "../../components/CheckboxList";
import LogoThumbnail from "../../components/LogoThumbnail";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import NmTitle from "../../components/NmTitle";
import {ReactComponent as LogoIcon} from "../../images/company-logo.svg";
import {ReactComponent as InfoIcon} from "../../images/info_20.svg";
import ClientSettingsLogList from "./components/client-settings-log-list";
import ClientListNew from "./components/new";

import {usePagination} from "../../hooks/usePagination";
import {useClientListAction} from "./hooks/useAction";
import {useClientListFetch} from "./hooks/useFetch";
import {useClientListFilter} from "./hooks/useFilter";

import {getQueryParam} from "../../utils/gueryStringHelper";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {isAccessByRestrictions} from "../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {COLOR} from "../../constants/color";
import {LINK_CLIENT_INFO} from "../../constants/links";
import {ADMIN} from "../../constants/roles";
import {CLIENT_LIST_OPERATION} from "./constants";

import {
    clientSystemPropertiesDataSelector,
    clientSystemPropertiesProgressActionSelector,
    clientSystemPropertiesProgressSelector,
} from "../../ducks/bff/clients/system-properties/selectors";
import {
    clientErrorFetchSelector,
    clientListOptimizedSelector,
    clientPageDataSelector,
    clientProgressSelector,
    clientTotalCountSelector,
    clientTotalPagesSelector,
} from "../../ducks/client";

const ClientListV2 = () => {
    const {t} = useTranslation();
    const role = ls(USER_ROLE);
    const pageData = useSelector(clientPageDataSelector);
    const location = useSelector(state => state.router.location);

    const [archivedFilter, setArchivedFilter] = useState(() => {
        if (pageData.archivedFilter) {
            return pageData.archivedFilter;
        }

        return getQueryParam(location, "active") === "false";
    });

    const list = useSelector(clientListOptimizedSelector);
    const totalPages = useSelector(clientTotalPagesSelector);
    const totalCount = useSelector(clientTotalCountSelector);
    const progress = useSelector(clientProgressSelector);
    const fetchError = useSelector(clientErrorFetchSelector);
    const systemClientProperties = useSelector(clientSystemPropertiesDataSelector);
    const systemClientPropertiesProgress = useSelector(clientSystemPropertiesProgressSelector);
    const systemClientPropertiesProgressAction = useSelector(clientSystemPropertiesProgressActionSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination(
        "nm-page",
        pageData.pageSize,
        pageData.pageNum,
    );

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filtersBase,
        filter,
    } = useClientListFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useClientListFetch({
        filterData,
        pageNum,
        pageSize,
        archivedFilter,
    });

    const {
        getListCardActions,
        favoriteData,
        settingsData,
        onCloseClientSettings,
        onCloseFavorite,
        updateFavorite,
        mediaControls,
        modalData,
        showConfirmWindow,
        handleCancel,
        handleConfirm,
        isOpenNewClient,
        onCloseNewClient,
        logModalData,
        setLogModalData,
    } = useClientListAction({
        fetchList,
        setPagination,
        setArchivedFilter,
        archivedFilter,
    });

    const getHeaderConfirmWindow = () => {
        const {
            currentOperation,
            titleConfirm,
            textConfirm,
        } = modalData;

        if (
            [
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN,
            ].includes(currentOperation)
        ) {
            return null;
        }

        return (
            <NmTitle size="lg">
                {titleConfirm ? titleConfirm : textConfirm}
            </NmTitle>
        );
    };

    const getConfirmWindowContent = () => {
        const {
            currentOperation,
            titleConfirm,
            textConfirm,
        } = modalData;

        if (
            [
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN,
            ].includes(currentOperation)
        ) {
            return textConfirm;
        }

        return titleConfirm ? textConfirm : null;
    };

    const renderConfirmWindow = () => {
        const {
            openConfirmWindow,
            submitBtnContent,
            cancelBtnContent,
            isHiddenConfirmationButtons,
            confirmData: {
                formId,
            },
            currentOperation,
        } = modalData;

        return (
            openConfirmWindow &&
            <NmModal
                onClose={handleCancel}
                size="md"
                header={getHeaderConfirmWindow()}
                footer={
                    !isHiddenConfirmationButtons &&
                    <ApplyButtons
                        mobile="column"
                        align={
                            [
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN,
                            ].includes(currentOperation) ? "center" : "right"
                        }
                        isHiddenCancelOnMobile
                        submitBtnContent={submitBtnContent || t("button.yes")}
                        submit={!formId && handleConfirm}
                        formId={formId}
                        onClose={handleCancel}
                        cancelBtnContent={cancelBtnContent || t("button.no")}
                    />
                }
                children={getConfirmWindowContent()}
            />
        );
    };

    const renderConfirmFavoriteWindow = () => {
        if (!favoriteData) {
            return null;
        }

        const {
            clientName,
            favorite,
        } = favoriteData;

        const content = t(favorite ? "deposit-list.favorite-add" : "deposit-list.favorite-remove", {clientName});

        return (
            <NmConfirmV2
                content={content}
                onCancel={onCloseFavorite}
                onConfirm={updateFavorite}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    const renderName = ({name, clientId, brand}) => {
        const link = LINK_CLIENT_INFO.replace(":clientId", clientId);
        const nameWithBrand = brand ? `${name} (${brand})` : name;

        return (
            <Link to={link}>
                <Text
                    noWrap
                    color={COLOR.PASSIVE_100}
                    className="me-2"
                    level="5"
                >
                    {nameWithBrand}
                </Text>
            </Link>
        );
    };

    const getRows = () => {
        return list.map(value => {
            const {
                clientId,
                base64Logo,
            } = value;

            return {
                ...value,
                key: clientId,
                contentRow: (
                    <div className="d-flex align-items-start">
                        <div className="d-flex fluid-flex-grow overflow-hidden">
                            {
                                base64Logo ?
                                    <LogoThumbnail
                                        size="md-list-card"
                                        className="me-4"
                                        width={50}
                                        minWidth={50}
                                        height={50}
                                        src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
                                    /> :
                                    <LogoIcon
                                        className="me-4"
                                        width={50}
                                        height={50}
                                        style={{minWidth: 50}}
                                    />
                            }
                            <div className="d-flex flex-column overflow-hidden">
                                {renderName(value)}
                                <Text
                                    className="mt-2"
                                    level="2"
                                >
                                    {value.actualAddress || value.registeredAddress || ""}
                                </Text>
                            </div>
                        </div>
                        <MediaButtons
                            inline
                            config={getListCardActions(value)}
                        />
                    </div>
                ),
            };
        });
    };

    const renderClientSettings = () => {
        if (!settingsData) {
            return null;
        }

        return (
            <ClientSettings
                onClose={onCloseClientSettings}
                name={settingsData?.name}
                clientId={settingsData?.clientId || "b3eb6d5d-b58c-4f8d-af15-177b7840ca17"}
            />
        );
    };

    const renderPaymentSettingLabel = ({label, type}) => {
        return (
            <>
                {label}
                <InfoIcon
                    className="ms-1 cursor-pointer"
                    onClick={() => {
                        showConfirmWindow({
                            flag: false,
                            titleConfirm: "Лог изменений опции ведения оплат",
                            type,
                            isHiddenConfirmationButtons: true,
                        });
                    }}
                />
            </>
        );
    };

    const renderSystemSettingsBtn = () => {
        if (
            ![ADMIN].includes(role)
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.settingAutoSendPayment,
            ])
        ) {
            return null;
        }

        const {
            paymentsWithoutConfirmNmAdmin = false,
            civilPaymentsWithoutConfirmNmAdmin = false,
            individualPaymentsWithoutConfirmNmAdmin = false,
        } = systemClientProperties;

        return (
            <>
                <Text
                    level="3"
                    className="mb-2"
                >
                    Автоотправка платежей в банк (ведение оплат без согласования администратором Наймикс)
                </Text>
                <NmHorizontalToggleV2
                    disabled={systemClientPropertiesProgressAction}
                    leftLabel={renderPaymentSettingLabel({
                        label: "Оплаты по договорам НПД",
                        type: CLIENT_LIST_OPERATION.GET_LOG_EDITING_OPTIONS,
                    })}
                    className="mb-2"
                    checked={paymentsWithoutConfirmNmAdmin}
                    single
                    duplex={false}
                    onChange={(e, {checked}) => {
                        showConfirmWindow({
                            flag: checked,
                            type: checked ?
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN :
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                            requestData: {paymentsWithoutConfirmNmAdmin: checked},
                            cancelBtnContent: "Отменить",
                        });
                    }}
                    name="paymentsWithoutConfirmNmAdmin"
                />
                <NmHorizontalToggleV2
                    disabled={systemClientPropertiesProgressAction}
                    leftLabel={renderPaymentSettingLabel({
                        label: "Оплаты по договорам НДФЛ",
                        type: CLIENT_LIST_OPERATION.CIVIL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE,
                    })}
                    className="mb-2"
                    checked={civilPaymentsWithoutConfirmNmAdmin}
                    single
                    duplex={false}
                    onChange={(e, {checked}) => {
                        showConfirmWindow({
                            flag: checked,
                            type: checked ?
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN :
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                            requestData: {civilPaymentsWithoutConfirmNmAdmin: checked},
                            cancelBtnContent: "Отменить",
                        });
                    }}
                    name="civilPaymentsWithoutConfirmNmAdmin"
                />
                <NmHorizontalToggleV2
                    disabled={systemClientPropertiesProgressAction}
                    leftLabel={renderPaymentSettingLabel({
                        label: "Оплаты по договорам ИП",
                        type: CLIENT_LIST_OPERATION.INDIVIDUAL_PAYMENTS_WITHOUT_CONFIRM_NM_ADMIN_TYPE,
                    })}
                    checked={individualPaymentsWithoutConfirmNmAdmin}
                    single
                    duplex={false}
                    onChange={(e, {checked}) => {
                        showConfirmWindow({
                            flag: checked,
                            type: checked ?
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITHOUT_ADMIN :
                                CLIENT_LIST_OPERATION.SET_PAYMENT_WITH_ADMIN,
                            requestData: {individualPaymentsWithoutConfirmNmAdmin: checked},
                            cancelBtnContent: "Отменить",
                        });
                    }}
                    name="individualPaymentsWithoutConfirmNmAdmin"
                />
            </>
        );
    };

    const renderClientNew = () => {
        if (!isOpenNewClient) {
            return null;
        }

        return (
            <ClientListNew
                onClose={onCloseNewClient}
                fetchList={fetchList}
            />
        );
    };

    const renderClientSettingsLogList = () => {
        return (
            !isEmpty(logModalData) &&
            <ClientSettingsLogList
                {...logModalData}
                onClose={() => setLogModalData({})}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    totalCount={totalCount}
                    text={t("client-list.client-list-title")}
                />
            }
            subHeader={renderSystemSettingsBtn()}
            mediaControls={mediaControls}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            typeFilter="vertical"
            totalPages={totalPages}
            isLoaded={progress || systemClientPropertiesProgress}
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filtersBase}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
        >
            {renderConfirmFavoriteWindow()}
            {renderConfirmWindow()}
            {renderClientSettings()}
            {renderClientNew()}
            {renderClientSettingsLogList()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                        fetchError={fetchError}
                        title="Информация отсутствует"
                    /> :
                    <CheckboxList
                        rows={getRows()}
                    />
            }
        </NmPage>
    );
};

export default ClientListV2;