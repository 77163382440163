import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";

import {EMPTY_OPTION_KEY} from "../../../constants/dropdown";
import {STAFF_STATUS_OPTIONS_WITH_EMPTY_OPTION} from "../../../constants/edmStaff";

import {getEdmPositionList} from "../../../ducks/bff/clients/edo/directory/positions/actionCreators";
import {edmPositionListOptionsSelector} from "../../../ducks/bff/clients/edo/directory/positions/selectors";
import {getEdmSubdivisionsList} from "../../../ducks/bff/clients/edo/directory/subdivisions/actionCreators";
import {edmSubdivisionsOptionsSelector} from "../../../ducks/bff/clients/edo/directory/subdivisions/selectors";

import "./style.sass";

const initForm = {
    fio: "",
    phone: "",
    positions: [],
    subdivisions: [],
    status: EMPTY_OPTION_KEY,
};

const DocumentManagementPersonnelFilter = (props) => {
    const {
        submitFilter,
        clientId,
    } = props;

    const [form, setForm] = useState({
        ...initForm,
    });

    const {
        fio,
        phone,
        positions,
        status,
        subdivisions,
    } = form;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEdmPositionList({
            clientId,
            pageNum: 1,
            pageSize: 1000,
        }));
        dispatch(getEdmSubdivisionsList({
            clientId,
            pageNum: 1,
            pageSize: 1000,
        }));
    }, []);

    const positionOptions = useSelector(edmPositionListOptionsSelector);
    const subdivisionsOptions = useSelector(edmSubdivisionsOptionsSelector);

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = () => {
        submitFilter(form);
    };

    const clear = () => {
        submitFilter({
            ...initForm,
        });

        setForm({
            ...initForm,
        });
    };

    return (
        <NmForm
            addMargin
            className="document-management-personnel-filter"
        >
            <FilterButtonsV2
                className="document-management-personnel-filter__buttons"
                onSearch={submit}
                onClear={clear}
            />
            <NmInputV2
                size="lg"
                label="Фио сотрудника"
                className="document-management-personnel-filter__input"
                name="fio"
                value={fio}
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                mask="+7 (999) 999 99 99"
                onChange={onChange}
                maskChar={null}
                name="phone"
                label="Номер телефона сотрудника"
                value={phone}
            />
            <div className="flex flex-content-spaced flex-aligned-end">
                <NmDropdownV2
                    size="lg"
                    label="Статус"
                    onChange={onChange}
                    className="document-management-personnel-filter__dropdown document-management-personnel-filter__dropdown_first"
                    name="status"
                    value={status}
                    options={STAFF_STATUS_OPTIONS_WITH_EMPTY_OPTION}
                />
                <NmDropdownV2
                    size="lg"
                    multiple
                    label="Должность"
                    onChange={onChange}
                    search
                    className="document-management-personnel-filter__dropdown"
                    name="positions"
                    placeholder="Показать все"
                    value={positions}
                    options={positionOptions}
                    onSubmit={submit}
                />
            </div>
            <NmDropdownV2
                size="lg"
                multiple
                label="Подразделение"
                onChange={onChange}
                search
                name="subdivisions"
                placeholder="Показать все"
                value={subdivisions}
                options={subdivisionsOptions}
                onSubmit={submit}
            />
        </NmForm>
    );
};

export default DocumentManagementPersonnelFilter;