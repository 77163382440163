import {
    ADD_REGISTRY_REQUEST,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST,
    BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST,
    DISCARD_OUTSTANDING_REQUEST,
    DUPLICATE_REGISTRY_REQUEST,
    GET_REGISTRY_STATUS_DICT_REQUEST,
    IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST,
    PAY_REGISTRY_REQUEST,
    REGISTRY_DELETE_REQUEST,
    REGISTRY_PAYMENT_ARCHIVE_REQUEST,
    REGISTRY_PAYMENT_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_PAY_REQUEST,
    REGISTRY_UPDATE_REQUEST,
    TRANSFER_REGISTRY_TO_PAY_REQUEST,
    UPDATE_FIELD_REGISTRY_STORE,
} from "./actions";

export const getPageRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_GET_PAGE_REQUEST,
        payload,
    };
};

export const archiveRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_ARCHIVE_REQUEST,
        payload,
    };
};

export const payRegistryPayment = (payload) => {
    return {
        type: REGISTRY_PAYMENT_PAY_REQUEST,
        payload,
    };
};

export function deleteRegistry(payload) {
    return {
        type: REGISTRY_DELETE_REQUEST,
        payload,
    };
}

export function createDuplicateRegistry(payload) {
    return {
        type: DUPLICATE_REGISTRY_REQUEST,
        payload,
    };
}

export function updateRegistryStoreField(payload) {
    return {
        type: UPDATE_FIELD_REGISTRY_STORE,
        payload,
    };
}

export function updateRegistry(payload) {
    return {
        type: REGISTRY_UPDATE_REQUEST,
        payload,
    };
}

export function addRegistry(payload) {
    return {
        type: ADD_REGISTRY_REQUEST,
        payload,
    };
}

export function getRegistryStatusDict(payload) {
    return {
        type: GET_REGISTRY_STATUS_DICT_REQUEST,
        payload,
    };
}

export function transferRegistryToPay(payload) {
    return {
        type: TRANSFER_REGISTRY_TO_PAY_REQUEST,
        payload,
    };
}

export function payRegistry(payload) {
    return {
        type: PAY_REGISTRY_REQUEST,
        payload,
    };
}

export function discardOutstandingPayments(payload) {
    return {
        type: DISCARD_OUTSTANDING_REQUEST,
        payload,
    };
}

export const createRegistryPaymentDeferredActs = (payload) => {
    return {
        type: BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST,
        payload,
    };
};

export function getIsFrameContractSignedForContractorsOnRegistry(payload) {
    return {
        type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST,
        payload,
    };
}

export function getRegistryPaymentClientSettings(payload) {
    return {
        type: BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST,
        payload,
    };
}