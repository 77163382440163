import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {
    crowdChatMessagesItemToGeneralFormat,
    crowdChatsItemToGeneralFormat,
} from "../utils/chats";
import {getPageNum, getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../utils/url";

import {CLIENT_CARD} from "../constants/links";
import {DISPUTE_MESSAGES} from "../constants/messages";

const disputeChatController = "/disputeChat";

const getOrderChatController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/chats",
        client: "/client-adm/chats",
    });
};

const getDisputeChatController = () => {
    return getBffUrl({
        [CLIENT_CARD]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/disputes/chat",
            client: "/client-adm/disputes/chat",
        }),
    },
    getBffControllerClientCardPage({
        admin: "/adm/disputes/chat",
        client: "/client-adm/disputes/chat",
    }),
    true,
    );
};

const CHAT_ADD_MESSAGE_REQUEST = "CHAT_ADD_MESSAGE_REQUEST";
const CHAT_ADD_MESSAGE_SUCCESS = "CHAT_ADD_MESSAGE_SUCCESS";
const CHAT_ADD_MESSAGE_ERROR = "CHAT_ADD_MESSAGE_ERROR";

const DISPUTE_CHAT_ADD_MESSAGE_REQUEST = "DISPUTE_CHAT_ADD_MESSAGE_REQUEST";
const DISPUTE_CHAT_ADD_MESSAGE_SUCCESS = "DISPUTE_CHAT_ADD_MESSAGE_SUCCESS";
const DISPUTE_CHAT_ADD_MESSAGE_ERROR = "DISPUTE_CHAT_ADD_MESSAGE_ERROR";

const BFF_CROWD_CHAT_ADD_MESSAGE_REQUEST = "BFF_CROWD_CHAT_ADD_MESSAGE_REQUEST";
const BFF_CROWD_CHAT_ADD_MESSAGE_SUCCESS = "BFF_CROWD_CHAT_ADD_MESSAGE_SUCCESS";
const BFF_CROWD_CHAT_ADD_MESSAGE_ERROR = "BFF_CROWD_CHAT_ADD_MESSAGE_ERROR";

const CHAT_GET_MESSAGE_LIST_REQUEST = "CHAT_GET_MESSAGE_LIST_REQUEST";
const CHAT_GET_MESSAGE_LIST_SUCCESS = "CHAT_GET_MESSAGE_LIST_SUCCESS";
const CHAT_GET_MESSAGE_LIST_ERROR = "CHAT_GET_MESSAGE_LIST_ERROR";

const DISPUTE_GET_MESSAGE_LIST_REQUEST = "DISPUTE_GET_MESSAGE_LIST_REQUEST";
const DISPUTE_GET_MESSAGE_LIST_SUCCESS = "DISPUTE_GET_MESSAGE_LIST_SUCCESS";
const DISPUTE_GET_MESSAGE_LIST_ERROR = "DISPUTE_GET_MESSAGE_LIST_ERROR";

const GET_CHAT_LIST_REQUEST = "GET_CHAT_LIST_REQUEST";
const GET_CHAT_LIST_SUCCESS = "GET_CHAT_LIST_SUCCESS";
const GET_CHAT_LIST_ERROR = "GET_CHAT_LIST_ERROR";

const BFF_CROWD_CHAT_GET_PAGE_REQUEST = "BFF_CROWD_CHAT_GET_PAGE_REQUEST";
const BFF_CROWD_CHAT_GET_PAGE_SUCCESS = "BFF_CROWD_CHAT_GET_PAGE_SUCCESS";
const BFF_CROWD_CHAT_GET_PAGE_ERROR = "BFF_CROWD_CHAT_GET_PAGE_ERROR";

const BFF_CROWD_CHAT_GET_MESSAGE_LIST_REQUEST = "BFF_CROWD_CHAT_GET_MESSAGE_LIST_REQUEST";
const BFF_CROWD_CHAT_GET_MESSAGE_LIST_SUCCESS = "BFF_CROWD_CHAT_GET_MESSAGE_LIST_SUCCESS";
const BFF_CROWD_CHAT_GET_MESSAGE_LIST_ERROR = "BFF_CROWD_CHAT_GET_MESSAGE_LIST_ERROR";

const CHAT_UPDATE_FIELD = "CHAT_UPDATE_FIELD";

const CHAT_MESSAGES_UPDATE = "CHAT_MESSAGES_UPDATE";

const CHAT_GET_NEXT_MESSAGE_LIST_REQUEST = "CHAT_GET_NEXT_MESSAGE_LIST_REQUEST";
const CHAT_GET_NEXT_MESSAGE_LIST_SUCCESS = "CHAT_GET_NEXT_MESSAGE_LIST_SUCCESS";
const CHAT_GET_NEXT_MESSAGE_LIST_ERROR = "CHAT_GET_NEXT_MESSAGE_LIST_ERROR";

const GET_CRM_CHAT_WITH_NAMEMIX_REQUEST = "GET_CRM_CHAT_WITH_NAMEMIX_REQUEST";
const GET_CRM_CHAT_WITH_NAMEMIX_SUCCESS = "GET_CRM_CHAT_WITH_NAMEMIX_SUCCESS";
const GET_CRM_CHAT_WITH_NAMEMIX_ERROR = "GET_CRM_CHAT_WITH_NAMEMIX_ERROR";

const CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_REQUEST = "CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_REQUEST";
const CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_SUCCESS = "CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_SUCCESS";
const CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_ERROR = "CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_ERROR";

const CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_REQUEST = "CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_REQUEST";
const CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_SUCCESS = "CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_SUCCESS";
const CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_ERROR = "CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_ERROR";

const CRM_CHAT_GET_MESSAGE_COUNT_REQUEST = "CRM_CHAT_GET_MESSAGE_COUNT_REQUEST";
const CRM_CHAT_GET_MESSAGE_COUNT_SUCCESS = "CRM_CHAT_GET_MESSAGE_COUNT_SUCCESS";
const CRM_CHAT_GET_MESSAGE_COUNT_ERROR = "CRM_CHAT_GET_MESSAGE_COUNT_ERROR";

const CHAT_MESSAGE_MARK_READ_REQUEST = "CHAT_MESSAGE_MARK_READ_REQUEST";
const CHAT_MESSAGE_MARK_READ_SUCCESS = "CHAT_MESSAGE_MARK_READ_SUCCESS";
const CHAT_MESSAGE_MARK_READ_ERROR = "CHAT_MESSAGE_MARK_READ_ERROR";

const BFF_CROWD_CHAT_MESSAGE_MARK_READ_REQUEST = "BFF_CROWD_CHAT_MESSAGE_MARK_READ_REQUEST";
const BFF_CROWD_CHAT_MESSAGE_MARK_READ_SUCCESS = "BFF_CROWD_CHAT_MESSAGE_MARK_READ_SUCCESS";
const BFF_CROWD_CHAT_MESSAGE_MARK_READ_ERROR = "BFF_CROWD_CHAT_MESSAGE_MARK_READ_ERROR";

const BFF_CROWD_CHAT_LOCK_REQUEST = "BFF_CROWD_CHAT_LOCK_REQUEST";
const BFF_CROWD_CHAT_LOCK_SUCCESS = "BFF_CROWD_CHAT_LOCK_SUCCESS";
const BFF_CROWD_CHAT_LOCK_ERROR = "BFF_CROWD_CHAT_LOCK_ERROR";

const BFF_CROWD_CHAT_UNLOCK_REQUEST = "BFF_CROWD_CHAT_UNLOCK_REQUEST";
const BFF_CROWD_CHAT_UNLOCK_SUCCESS = "BFF_CROWD_CHAT_UNLOCK_SUCCESS";
const BFF_CROWD_CHAT_UNLOCK_ERROR = "BFF_CROWD_CHAT_UNLOCK_ERROR";

const BFF_CROWD_CHAT_MARK_UNREAD_REQUEST = "BFF_CROWD_CHAT_MARK_UNREAD_REQUEST";
const BFF_CROWD_CHAT_MARK_UNREAD_SUCCESS = "BFF_CROWD_CHAT_MARK_UNREAD_SUCCESS";
const BFF_CROWD_CHAT_MARK_UNREAD_ERROR = "BFF_CROWD_CHAT_MARK_UNREAD_ERROR";

const initial = {
    messageList: [],
    isShowNaimixDefaultMessage: false,
    newMessageList: [],
    pageData: {},
    totalCount: 0,
    needScroll: false,
    isAddToChatSuccess: false,
    loading: false,
    loadingChats: false,
    progressAddMessage: false,
    scrollToLastElement: false,
    chatList: [],
    chatListPageData: {},
    chatListTotalCount: 0,
    currentChat: {},
    sessionMessageUploadingStatuses: {},
    crmChatUnderMessageCount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CHAT_UPDATE_FIELD:
            return {
                ...state,
                ...payload,
            };
        case CHAT_MESSAGES_UPDATE:
            return {
                ...state,
                messageList: [
                    ...state.messageList,
                    payload,
                ],
            };
        case CHAT_GET_NEXT_MESSAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CHAT_GET_MESSAGE_LIST_REQUEST:
        case DISPUTE_GET_MESSAGE_LIST_REQUEST:
        case BFF_CROWD_CHAT_GET_MESSAGE_LIST_REQUEST:
        case CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                isAddToChatSuccess: false,
                loading: !payload.isLoadMore,
            };
        case CHAT_ADD_MESSAGE_REQUEST:
        case DISPUTE_CHAT_ADD_MESSAGE_REQUEST:
        case BFF_CROWD_CHAT_ADD_MESSAGE_REQUEST:
        case CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_REQUEST: {
            return {
                ...state,
                progressAddMessage: true,
                pageData: {
                    ...state.pageData,
                    isUpdatedLocal: true,
                },
            };
        }
        case CHAT_GET_NEXT_MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                messageList: payload.messages.concat(state.messageList),
                loading: false,
                scrollToLastElement: true,
            };
        case CHAT_GET_MESSAGE_LIST_SUCCESS:
        case DISPUTE_GET_MESSAGE_LIST_SUCCESS:
        case BFF_CROWD_CHAT_GET_MESSAGE_LIST_SUCCESS:
            return {
                ...state,
                messageList: payload.isLoadMore ? payload.messages.concat(state.messageList) : payload.messages,
                totalCount: payload.totalCount,
                needScroll: true,
                loading: false,
            };
        case CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_SUCCESS:
            const _messages = [...payload.results].reverse();

            return {
                ...state,
                messageList: payload.isLoadMore ? _messages.concat(state.messageList) : _messages,
                totalCount: payload.totalCount,
                needScroll: true,
                loading: false,
            };
        case CHAT_ADD_MESSAGE_SUCCESS:
        case DISPUTE_CHAT_ADD_MESSAGE_SUCCESS:
        case BFF_CROWD_CHAT_ADD_MESSAGE_SUCCESS:
        case CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_SUCCESS: {
            return {
                ...state,
                progressAddMessage: false,
                sessionMessageUploadingStatuses: {
                    ...state.sessionMessageUploadingStatuses,
                    [payload.temporaryMessageId]: {isLoaded: true},
                },
                messageList: state.messageList.map(item => {
                    if (item.temporaryMessageId === payload.temporaryMessageId) {
                        return {
                            ...item,
                            messageId: payload.guid || payload.result?.messageId,
                        };
                    }

                    return item;
                }),
            };
        }
        case GET_CHAT_LIST_REQUEST:
        case BFF_CROWD_CHAT_GET_PAGE_REQUEST: {
            const {pageNum, pageSize} = payload;
            return {
                ...state,
                chatListPageData: {
                    pageNum,
                    pageSize,
                },
                loadingChats: !payload.isLoadMore,
            };
        }
        case GET_CHAT_LIST_SUCCESS:
        case BFF_CROWD_CHAT_GET_PAGE_SUCCESS: {
            return {
                ...state,
                chatListTotalCount: payload.totalCount,
                loadingChats: false,
            };
        }
        case GET_CHAT_LIST_ERROR:
        case BFF_CROWD_CHAT_GET_PAGE_ERROR: {
            return {
                ...state,
                loadingChats: false,
            };
        }
        case CHAT_GET_MESSAGE_LIST_ERROR:
        case DISPUTE_GET_MESSAGE_LIST_ERROR:
        case BFF_CROWD_CHAT_GET_MESSAGE_LIST_ERROR:
        case CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_ERROR:
        case CHAT_GET_NEXT_MESSAGE_LIST_ERROR: {
            return {
                ...state,
                error: payload,
                loading: false,
            };
        }
        case CHAT_ADD_MESSAGE_ERROR:
        case DISPUTE_CHAT_ADD_MESSAGE_ERROR:
        case BFF_CROWD_CHAT_ADD_MESSAGE_ERROR:
        case CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_ERROR: {
            return {
                ...state,
                error: payload.errorMessage,
                loading: false,
                progressAddMessage: false,
                sessionMessageUploadingStatuses: {
                    ...state.sessionMessageUploadingStatuses,
                    [payload.temporaryMessageId]: {isError: true},
                },
            };
        }
        case CRM_CHAT_GET_MESSAGE_COUNT_SUCCESS: {
            return {
                ...state,
                crmChatUnderMessageCount: payload || 0,
            };
        }
        default:
            return state;
    }
};

export function chatAddMessage(payload) {
    return {
        type: CHAT_ADD_MESSAGE_REQUEST,
        payload,
    };
}

export function addMessageDispute(payload) {
    return {
        type: DISPUTE_CHAT_ADD_MESSAGE_REQUEST,
        payload,
    };
}

export function crowdChatAddMessage(payload) {
    return {
        type: BFF_CROWD_CHAT_ADD_MESSAGE_REQUEST,
        payload,
    };
}

export function getMessageList(payload) {
    return {
        type: CHAT_GET_MESSAGE_LIST_REQUEST,
        payload,
    };
}

export function getDisputeMessageList(payload) {
    return {
        type: DISPUTE_GET_MESSAGE_LIST_REQUEST,
        payload,
    };
}

export function getMessageListCrowd(payload) {
    return {
        type: BFF_CROWD_CHAT_GET_MESSAGE_LIST_REQUEST,
        payload,
    };
}

export function getNewMessageList(payload) {
    return {
        type: CHAT_GET_NEXT_MESSAGE_LIST_REQUEST,
        payload,
    };
}

export function updateFieldChat(payload) {
    return {
        type: CHAT_UPDATE_FIELD,
        payload,
    };
}

export function updateChatMessages(payload) {
    return {
        type: CHAT_MESSAGES_UPDATE,
        payload,
    };
}

export function getChatList(payload) {
    return {
        type: GET_CHAT_LIST_REQUEST,
        payload,
    };
}

export function getPageCrowdChat(payload) {
    return {
        type: BFF_CROWD_CHAT_GET_PAGE_REQUEST,
        payload,
    };
}

export function getCrmChatWithNamemix(payload) {
    return {
        type: GET_CRM_CHAT_WITH_NAMEMIX_REQUEST,
        payload,
    };
}

export function crmChatWithNamemixAddMessage(payload) {
    return {
        type: CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_REQUEST,
        payload,
    };
}

export function crmChatWithNamemixGetMessagePage(payload) {
    return {
        type: CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_REQUEST,
        payload,
    };
}

export function crmChatGetMessageCount(payload) {
    return {
        type: CRM_CHAT_GET_MESSAGE_COUNT_REQUEST,
        payload,
    };
}

export function chatMessageMarkRead(payload) {
    return {
        type: CHAT_MESSAGE_MARK_READ_REQUEST,
        payload,
    };
}


export function crowdChatMessageMarkRead(payload) {
    return {
        type: BFF_CROWD_CHAT_MESSAGE_MARK_READ_REQUEST,
        payload,
    };
}

export function crowdChatLock(payload) {
    return {
        type: BFF_CROWD_CHAT_LOCK_REQUEST,
        payload,
    };
}

export function crowdChatUnlock(payload) {
    return {
        type: BFF_CROWD_CHAT_UNLOCK_REQUEST,
        payload,
    };
}

export function crowdChatMarkUnread(payload) {
    return {
        type: BFF_CROWD_CHAT_MARK_UNREAD_REQUEST,
        payload,
    };
}

export const chatSelector = state => state.messagesList;

export const chatMessageListSelector = createSelector(chatSelector, ({messageList}) => messageList);
export const chatMessagesPageDataSelector = createSelector(chatSelector, ({pageData}) => pageData);
export const chatLoadingSelector = createSelector(chatSelector, ({loading}) => loading);
export const chatLoadingChatsSelector = createSelector(chatSelector, ({loadingChats}) => loadingChats);
export const chatScrollToLastElementSelector = createSelector(chatSelector, ({scrollToLastElement}) => scrollToLastElement);
export const chatMessageListTotalCountSelector = createSelector(chatSelector, ({totalCount}) => totalCount);
export const chatMessageListTotalPagesSelector = createSelector(chatSelector, ({totalCount, pageData = {}}) => {
    return getTotalPages(totalCount, pageData.pageSize);
});
export const chatMessageListPageNumSelector = createSelector(chatSelector, ({messageList = [], pageData = {}}) => {
    return getPageNum(messageList.length, pageData.pageSize);
});
export const chatMessageListNeedScrollSelector = createSelector(chatSelector, ({needScroll}) => needScroll);
export const chatTotalPagesSelector = createSelector(chatSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});
export const chatListSelector = createSelector(chatSelector, ({chatList}) => chatList);
export const chatListTotalCountSelector = createSelector(chatSelector, ({chatListTotalCount}) => chatListTotalCount);
export const currentChatSelector = createSelector(chatSelector, ({currentChat}) => currentChat);
export const isShowNaimixDefaultMessageSelector = createSelector(chatSelector, ({isShowNaimixDefaultMessage}) => isShowNaimixDefaultMessage);
export const sessionMessageUploadingStatusesSelector = createSelector(chatSelector, ({sessionMessageUploadingStatuses}) => sessionMessageUploadingStatuses);
export const crmChatUnderMessageCountSelector = createSelector(chatSelector, ({crmChatUnderMessageCount}) => crmChatUnderMessageCount);

export const chatAddMessageSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            temporaryMessageId,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrderChatController()}/messages/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: CHAT_ADD_MESSAGE_ERROR,
                payload: {
                    ...result,
                    temporaryMessageId,
                },
            });
            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: CHAT_ADD_MESSAGE_SUCCESS, payload: {
                ...result,
                temporaryMessageId,
            },
        });
    } catch (error) {
        toastError(DISPUTE_MESSAGES.CHAT_ADD_MESSAGE_ERROR);

        yield put({
            type: CHAT_ADD_MESSAGE_ERROR,
            payload: error,
        });
    }
};

export const addMessageDisputeSaga = function* ({payload}) {
    try {
        const {
            getResult,
            temporaryMessageId,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/disputes/chat/message/add",
            client: "/client-adm/disputes/chat/message/add",
        });

        const result = yield request.bff.post(url, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: DISPUTE_CHAT_ADD_MESSAGE_ERROR,
                payload: {
                    ...result,
                    temporaryMessageId,
                },
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: DISPUTE_CHAT_ADD_MESSAGE_SUCCESS, payload: {
                ...result,
                temporaryMessageId,
            },
        });
    } catch (error) {
        toastError(DISPUTE_MESSAGES.CHAT_ADD_MESSAGE_ERROR);

        yield put({
            type: DISPUTE_CHAT_ADD_MESSAGE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/messages/addMessage
// Добавить сообщение в крауд чат
export const crowdChatAddMessageSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            simpleChat,
            temporaryMessageId,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/clients/client-card/crowd-tasks/chats/messages/addMessage", reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: BFF_CROWD_CHAT_ADD_MESSAGE_ERROR,
                payload: {
                    ...result,
                    temporaryMessageId,
                },
            });
            return {
                done: true,
            };
        }

        getResult(result.result);
        yield put({
            type: BFF_CROWD_CHAT_ADD_MESSAGE_SUCCESS, payload: {
                ...result,
                temporaryMessageId,
            },
        });
    } catch (error) {
        yield put({
            type: CHAT_ADD_MESSAGE_ERROR,
            payload: error,
        });
    }
};

export const getMessageListSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            isLoadMore,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrderChatController()}/get-message-page`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }
        onSuccess();

        yield put({
            type: CHAT_GET_MESSAGE_LIST_SUCCESS, payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {

        yield put({
            type: CHAT_GET_MESSAGE_LIST_ERROR,
            payload: error,
        });
    }
};

export const getDisputeMessageListSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            isLoadMore,
            simpleChat,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getDisputeChatController()}/message/page`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }
        onSuccess();

        yield put({
            type: DISPUTE_GET_MESSAGE_LIST_SUCCESS, payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {

        yield put({
            type: DISPUTE_GET_MESSAGE_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/messages/getPage
// Получить страницу сообщений крауд чата
export const getMessageListCrowdSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            isLoadMore,
            ...reqData
        } = payload;

        const result = yield request.bff.post("/adm/clients/client-card/crowd-tasks/chats/messages/getPage", reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_CROWD_CHAT_GET_MESSAGE_LIST_SUCCESS, payload: {
                ...result,
                messages: [...result.results].reverse().map(item => crowdChatMessagesItemToGeneralFormat(item)),
                isLoadMore,
            },
        });
    } catch (error) {

        yield put({
            type: BFF_CROWD_CHAT_GET_MESSAGE_LIST_ERROR,
            payload: error,
        });
    }
};

export const getNewMessageListSaga = function* (action) {
    try {
        const {payload} = action;
        const currentCtrl = payload.simpleChat ? getOrderChatController() : getDisputeChatController();
        const result = yield request.bff.post(`${currentCtrl}/message/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_GET_NEXT_MESSAGE_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHAT_GET_NEXT_MESSAGE_LIST_SUCCESS, payload: result});
    } catch (error) {

        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);

        yield put({
            type: CHAT_GET_NEXT_MESSAGE_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/orders/orders-registry/order/chats/getChatsPage
//POST /bff/client-adm/chats/get-chats-page
// Получение страницы чатов
export const getChatListSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...reqData
        } = payload;

        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/chats/get-chats-page",
                adminRolesUrl: "/adm/clients/client-card/orders/orders-registry/order/chats/getChatsPage",
            },
        );

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: GET_CHAT_LIST_ERROR, payload: errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        if (result.chats?.length) {
            const contractorIds = result.chats.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        getResult(result);
        yield put({type: GET_CHAT_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CHAT_LIST_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/getPage
//POST /bff/client-adm/crowd-tasks/chats/page
// Получить страницу крауд чатов
export const getPageCrowdChatSaga = function* ({payload}) {
    try {
        const {
            getResult,
            getError,
            isLoadMore,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/crowd-tasks/chats/getPage",
            client: "/client-adm/crowd-tasks/chats/page",
        });

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: BFF_CROWD_CHAT_GET_PAGE_ERROR, payload: errorMessage});

            if (getError) {
                getError(result);
            }

            return {
                done: true,
            };
        }

        if (result.results?.length) {
            const contractorIds = result.results.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        const _result = {
            ...result,
            chats: result.results.map(item => crowdChatsItemToGeneralFormat(item)),
        };

        if (getResult) {
            getResult(_result);
        }

        yield put({
            type: BFF_CROWD_CHAT_GET_PAGE_SUCCESS,
            payload: _result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_CROWD_CHAT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

//POST /api/crm/chat/message/add
export const crmChatWithNamemixAddMessageSaga = function* ({payload}) {
    try {
        const {
            currentUserId,
            temporaryMessageId,
            getResult = () => {
            },
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.post("/crm/chat/message/add", reqData);

        if (errorMessage) {
            yield put({
                type: CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_ERROR, payload: {
                    errorMessage,
                    temporaryMessageId,
                },
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_SUCCESS, payload: {
                ...result,
                temporaryMessageId,
            },
        });

        if (payload.chatId && currentUserId) {
            yield put({
                type: GET_CRM_CHAT_WITH_NAMEMIX_REQUEST, payload: {userId: currentUserId},
            });
        }
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_ERROR,
            payload: error,
        });
    }
};

//POST  /api/crm/chat/message/getPage
export const crmChatWithNamemixGetMessagePageSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            isLoadMore,
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.post("/crm/chat/message/getPage", reqData);

        if (errorMessage) {
            yield put({type: CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_ERROR, payload: errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_SUCCESS, payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_ERROR,
            payload: error,
        });
    }
};

//GET  /api/crm/chat/getByUserId/{userId}
export const getCrmChatWithNamemixSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            userId,
        } = payload;

        const {errorMessage, ...result} = yield request.get(`/crm/chat/getByUserId/${userId}`);

        if (errorMessage) {
            yield put({type: GET_CRM_CHAT_WITH_NAMEMIX_ERROR, payload: errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: GET_CRM_CHAT_WITH_NAMEMIX_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_CRM_CHAT_WITH_NAMEMIX_ERROR,
            payload: error,
        });
    }
};

//POST /api/crm/chat/message/getMessageCount
export const crmChatGetMessageCountSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.post("/crm/chat/message/getMessageCount", reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: CRM_CHAT_GET_MESSAGE_COUNT_ERROR, payload: errorMessage});

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        getResult(result);
        yield put({type: CRM_CHAT_GET_MESSAGE_COUNT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CRM_CHAT_GET_MESSAGE_COUNT_ERROR,
            payload: error,
        });
    }
};

export const chatMessageMarkReadSaga = function* ({payload}) {
    try {
        const {
            isCrmChat,
            isDisputeChat,
            ...reqData
        } = payload;

        const result = yield request.post(`${isCrmChat ? "/crm" : ""}${isDisputeChat ? "/disputeChat" : "/chat/message"}/markRead`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: CHAT_MESSAGE_MARK_READ_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: CHAT_MESSAGE_MARK_READ_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHAT_MESSAGE_MARK_READ_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/messages/markRead
// Отметить все сообщения крауд чата до указанного как прочитанные
export const crowdChatMessageMarkReadSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/clients/client-card/crowd-tasks/chats/messages/markRead", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: BFF_CROWD_CHAT_MESSAGE_MARK_READ_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: BFF_CROWD_CHAT_MESSAGE_MARK_READ_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_CROWD_CHAT_MESSAGE_MARK_READ_ERROR,
            payload: error,
        });
    }
};

//PUT /bff/adm/clients/client-card/crowd-tasks/chats/lockChat
//PUT /bff/client-adm/crowd-tasks/chats/lock-chat
// Заблокировать крауд чат
export const crowdChatLockSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/crowd-tasks/chats/lockChat",
            client: "/client-adm/crowd-tasks/chats/lock-chat",
        });

        const result = yield request.bff.put(url, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: BFF_CROWD_CHAT_LOCK_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_CHAT_LOCK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_CROWD_CHAT_LOCK_ERROR,
            payload: error,
        });
    }
};

//PUT /bff/adm/clients/client-card/crowd-tasks/chats/unlockChat
//PUT /bff/client-adm/crowd-tasks/chats/unlock-chat
// Разблокировать крауд чат
export const crowdChatUnlockSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/crowd-tasks/chats/unlockChat",
            client: "/client-adm/crowd-tasks/chats/unlock-chat",
        });

        const result = yield request.bff.put(url, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: BFF_CROWD_CHAT_UNLOCK_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_CHAT_UNLOCK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_CROWD_CHAT_UNLOCK_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/crowd-tasks/chats/markChatUnread
//POST /bff/client-adm/crowd-tasks/chats/mark-chat-unread
// Отметить чат как непрочитанный
export const crowdChatMarkUnreadSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/crowd-tasks/chats/markChatUnread",
            client: "/client-adm/crowd-tasks/chats/mark-chat-unread",
        });

        const result = yield request.bff.post(url, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: BFF_CROWD_CHAT_MARK_UNREAD_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_CHAT_MARK_UNREAD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_CROWD_CHAT_MARK_UNREAD_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CHAT_GET_MESSAGE_LIST_REQUEST, getMessageListSaga),
        takeEvery(DISPUTE_GET_MESSAGE_LIST_REQUEST, getDisputeMessageListSaga),
        takeEvery(BFF_CROWD_CHAT_GET_MESSAGE_LIST_REQUEST, getMessageListCrowdSaga),
        takeEvery(CHAT_ADD_MESSAGE_REQUEST, chatAddMessageSaga),
        takeEvery(DISPUTE_CHAT_ADD_MESSAGE_REQUEST, addMessageDisputeSaga),
        takeEvery(BFF_CROWD_CHAT_ADD_MESSAGE_REQUEST, crowdChatAddMessageSaga),
        takeEvery(CHAT_GET_NEXT_MESSAGE_LIST_REQUEST, getNewMessageListSaga),
        takeEvery(GET_CHAT_LIST_REQUEST, getChatListSaga),
        takeEvery(BFF_CROWD_CHAT_GET_PAGE_REQUEST, getPageCrowdChatSaga),
        takeEvery(CRM_CHAT_WITH_NAMEMIX_ADD_MESSAGE_REQUEST, crmChatWithNamemixAddMessageSaga),
        takeEvery(CRM_CHAT_WITH_NAMEMIX_GET_MESSAGE_PAGE_REQUEST, crmChatWithNamemixGetMessagePageSaga),
        takeEvery(GET_CRM_CHAT_WITH_NAMEMIX_REQUEST, getCrmChatWithNamemixSaga),
        takeEvery(CRM_CHAT_GET_MESSAGE_COUNT_REQUEST, crmChatGetMessageCountSaga),
        takeEvery(CHAT_MESSAGE_MARK_READ_REQUEST, chatMessageMarkReadSaga),
        takeEvery(BFF_CROWD_CHAT_MESSAGE_MARK_READ_REQUEST, crowdChatMessageMarkReadSaga),
        takeEvery(BFF_CROWD_CHAT_LOCK_REQUEST, crowdChatLockSaga),
        takeEvery(BFF_CROWD_CHAT_UNLOCK_REQUEST, crowdChatUnlockSaga),
        takeEvery(BFF_CROWD_CHAT_MARK_UNREAD_REQUEST, crowdChatMarkUnreadSaga),
    ]);
}