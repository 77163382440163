import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useGetPrevValue} from "../../../../../hooks/useGetPrevValue";

import {getRegistryInvitationsItemStatusDict} from "../../../../../ducks/bff/common/dicts/actionCreators";
import {
    getRegistryInvitationsById,
    getRegistryInvitationsContractorsPage,
} from "../../../../../ducks/bff/orders/order-invitations-registry/card/actionCreators";
import {clientCurrentMemberSelector} from "../../../../../ducks/clientMember";
import {
    getAllTasks,
    jobTasksImportRegistryInvitationsSelector,
} from "../../../../../ducks/job";

export function useRegistryInvitationsFetchData({clientId, list, registryInvitationId, pageSize, pageNum, filter}) {
    const importTasks = useSelector(jobTasksImportRegistryInvitationsSelector);
    const currentMember = useSelector(clientCurrentMemberSelector);

    const dispatch = useDispatch();
    const prevTasksList = useGetPrevValue(importTasks);

    useEffect(() => {
        dispatch(getRegistryInvitationsItemStatusDict());
        fetchCard();
    }, []);

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum]);

    useEffect(() => {
        if (isEmpty(importTasks) && !isEmpty(prevTasksList)) {
            fetchList();
        }
    }, [importTasks]);

    const fetchTasks = () => {
        const {
            clientId,
            clientUserId,
        } = currentMember;

        dispatch(getAllTasks({
            clientId,
            clientUserId,
        }));
    };

    const fetchCard = () => {
        dispatch(getRegistryInvitationsById({
            clientId,
            registryInvitationId,
        }));
    };

    const fetchList = () => {
        dispatch(getRegistryInvitationsContractorsPage({
            clientId,
            registryInvitationId,
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
        fetchTasks,
    };
}