import React from "react";
import {useSelector} from "react-redux";

import NmBadge from "../../NmBadge";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";

export const HH_POLITENESS_INDEX = {
    NOT_SENT: "NOT_SENT",
    NOT_SENT_ERROR: "NOT_SENT_ERROR",
    SENT: "SENT",
};

const HH_POLITENESS_INDEX_DICT = {
    NOT_SENT: "Отклик не отправлен",
    NOT_SENT_ERROR: "Отклик не отправлен",
    SENT: "Отклик отправлен",
};

const HH_POLITENESS_INDEX_BADGE = {
    NOT_SENT: "gray",
    NOT_SENT_ERROR: "light-red",
    SENT: "green",
};

export const HhPolitenessIndex = (props) => {
    const {
        hhPolitenessIndex,
    } = props;

    const {recruitmentHHPolitenessIndex} = useSelector(clientCardPropertiesSelector);

    const isRecruitmentHHPolitenessIndexOff = !recruitmentHHPolitenessIndex;

    const getTooltipText = () => {
        if (hhPolitenessIndex === HH_POLITENESS_INDEX.NOT_SENT_ERROR) {
            return "Отправьте приглашение повторно";
        }

        if (isRecruitmentHHPolitenessIndexOff) {
            return "Для включения настройки обратитесь к вашему администратору";
        }

        return null;
    };

    return (
        <NmBadge
            text={isRecruitmentHHPolitenessIndexOff ? "Отключен" : HH_POLITENESS_INDEX_DICT[hhPolitenessIndex]}
            mod={isRecruitmentHHPolitenessIndexOff ? "gray" : HH_POLITENESS_INDEX_BADGE[hhPolitenessIndex]}
            rightTooltipText={getTooltipText()}
        />
    );
};