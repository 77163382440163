import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../components/ActualComponents/Text";
import {ReactComponent as MaintenanceIcon} from "../../../images/maintenance.svg";

import bem from "../../../utils/bem";

import {
    getAuthMaintenanceNotice,
    updateStoreSettingsStats,
} from "../../../ducks/bff/settings/service/actionCreators";
import {bffSettingsServiceIsMaintenanceNoticePublishedSelector} from "../../../ducks/bff/settings/service/selectors";

import "./style.sass";

const MaintenanceBanner = () => {
    const maintenanceNotice = useSelector(bffSettingsServiceIsMaintenanceNoticePublishedSelector);
    const {publish, description} = maintenanceNotice;

    const dispatch = useDispatch();

    const [block, element] = bem("maintenance-banner");

    useEffect(() => {
        dispatch(getAuthMaintenanceNotice());

        return () => {
            dispatch(updateStoreSettingsStats({
                maintenanceNotice: {
                    publish: false,
                },
            }));
        };
    }, []);

    if (!publish) {
        return null;
    }

    return (
        <div className={block()}>
            <MaintenanceIcon className="me-4 flex-shrink-0" />
            <Text level="3">
                {description}
            </Text>
        </div>
    );
};

export default MaintenanceBanner;