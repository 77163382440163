import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearDeferredActsOfAcceptanceWorkStore,
    getDeferredActsOfAcceptanceWorkPage,
    getDeferredActsOfAcceptanceWorkStatusDict,
} from "../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/actionCreators";

export const useDeferredActsOfAcceptanceWorkFetch = (params) => {
    const {
        filterData,
        pageNum,
        pageSize,
        clientId,
        archivedFilter,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {

        fetchList();
    }, [
        filterData,
        pageNum,
        pageSize,
        archivedFilter,
    ]);

    useEffect(() => {
        dispatch(getDeferredActsOfAcceptanceWorkStatusDict());

        return () => {
            dispatch(clearDeferredActsOfAcceptanceWorkStore());
        };
    }, []);

    const fetchList = () => {
        dispatch(getDeferredActsOfAcceptanceWorkPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
            archivedFilter,
            ...filterData,
        }));
    };

    return null;
};