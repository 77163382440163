import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";

import {changeProjectsObjectsOrdersLimit} from "../../../ducks/bff/clients/projects/actionCreators";
import {clientProjectsProgressActionSelector} from "../../../ducks/bff/clients/projects/selectors";

const ChangeProjectObjectsLimit = (props) => {
    const {
        onClose,
        clientId,
        isObjects,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(clientProjectsProgressActionSelector);

    const importNumbers = ({file}) => {
        const formData = new FormData();

        const params = {
            clientId,
        };

        formData.append("file", file);
        formData.append("params", new Blob([JSON.stringify(params)], {
            type: "application/json",
        }));

        dispatch(changeProjectsObjectsOrdersLimit({
            isObjects,
            formData,
            onSuccess: () => {
                onClose();
            },
        }));
    };

    const patternLink = "/files/Шаблон_Изменение_лимита_заказов_на_проектах_и_объектах.xlsx";

    return (
        <ImportFromFilePatternV2
            maxSize={10}
            patternLink={patternLink}
            onSubmit={importNumbers}
            onClose={onClose}
            progress={progress}
        />
    );
};

export default ChangeProjectObjectsLimit;