import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {
    archiveCrowdTaskGroup,
    deleteCrowdTaskGroup,
    unarchiveCrowdTaskGroup,
} from "../../../../ducks/bff/crowd/taskGroups/actionCreators";

const useCrowdTaskGroupsAction = (props) => {
    const {
        clientId,
        fetchList,
        archivedFilter,
        setArchivedFilter,
        onOpenConfirm,
        onCloseConfirm,
        progress,
    } = props;

    const [editFormData, setEditFormData] = useState({});

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const role = ls(USER_ROLE);
    const isAccessAction = [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        OBJECT_MANAGER,
        PROJECT_MANAGER,
    ].includes(role)
    && isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTaskGroups,
    ]);

    const getMediaControls = () => {
        const archive = getArchiveButton(t, archivedFilter, {mobile: true});

        return {
            renderCount: {
                desktop: 2,
                tablet: 1,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => setEditFormData({
                            isOpen: true,
                        }),
                        icon: <AddIcon />,
                        children: "Добавить группу",
                    },
                    visible: isAccessAction && !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progress,
                        onClick: () => setArchivedFilter(value => !value),
                    },
                },
            ],
        };
    };

    const getItemMediaControls = (item) => {
        const {
            name,
            archived,
        } = item;

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => setEditFormData({
                            isOpen: true,
                            data: item,
                        }),
                        children: "Редактировать",
                    },
                    visible: isAccessAction && !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => onOpenConfirm({
                            content: `Вы действительно хотите удалить группу заданий "${name}"`,
                            onConfirm: () => onConfirmDeleteAction(item),
                        }),
                        children: "Удалить",
                    },
                    visible: isAccessAction && !archivedFilter,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => onOpenConfirm({
                            content: archived ?
                                `Вы уверены, что хотите восстановить группу заданий "${name}" из архива? Статусы заданий при восстановлении из архива группы не изменятся` :
                                `Вы действительно хотите добавить в архив группу заданий "${name}"?`,
                            onConfirm: () => onConfirmArchiveAction(item),
                        }),
                        children: archived ? "Из архива" : "В архив",
                    },
                    visible: isAccessAction,
                },
            ],
        };
    };

    const onConfirmArchiveAction = ({archived, taskGroupId}) => {
        const reqData = {
            clientId,
            taskGroupId,
            onSuccess: () => {
                onCloseConfirm();
                fetchList();
                toastSuccess(
                    archived ?
                        "Группа заданий успешно восстановлена из архива" :
                        "Группа заданий успешно перенесена в архив",
                );
            },
        };

        if (archived) {
            dispatch(unarchiveCrowdTaskGroup(reqData));

            return;
        }

        dispatch(archiveCrowdTaskGroup(reqData));
    };

    const onConfirmDeleteAction = ({taskGroupId}) => {
        dispatch(deleteCrowdTaskGroup({
            clientId,
            taskGroupId,
            onSuccess: () => {
                onCloseConfirm();
                fetchList();
                toastSuccess("Группа заданий успешно удалена");
            },
        }));
    };

    return {
        getMediaControls,
        getItemMediaControls,
        editFormData,
        setEditFormData,
    };
};

export default useCrowdTaskGroupsAction;