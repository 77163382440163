import {createSelector} from "reselect";

//*  SELECTORS  *//
export const bffCommonDictsSelector = state => state.bff.common.order;

export const bffCommonOrderObjectsOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({orderObjects}) => orderObjects.map((item) => {
        return {
            key: item.objectId,
            value: item.objectId,
            text: `${item.name}; ${item.address}`,
        };
    }),
);
export const bffCommonOrderProjectsOptionsSelector = createSelector(
    bffCommonDictsSelector,
    ({orderProjects}) => orderProjects.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    }),
);