import dateFormat from "../../../../utils/dateFormat";
import {setNullToEmptyValues} from "../../../../utils/objectHelper";
import {removeSnilsMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {citizenshipsDict} from "../../../../constants/citizenships";

export const getKedoStaffRequestData = (values) => {
    const {
        password,
        generateAndSendPassword,
        repeatPassword,
        isVisiblePasswordChange,
        ...requestData
    } = values;

    const isCitizenshipNotSpecified = values.citizenship === citizenshipsDict.NOT_SPECIFIED.value;

    const generalFields = {
        phone: handleFormString(values.phone),
        birthDate: values.birthDate ?
            dateFormat(values.birthDate, "yyyy-MM-dd") :
            undefined,
        idDocValidToDate: (
            isCitizenshipNotSpecified
            || !values.idDocValidToDate
        )
            ? undefined
            : dateFormat(values.idDocValidToDate, "yyyy-MM-dd"),
        idDocIssuedDate: (
            isCitizenshipNotSpecified
            || !values.idDocIssuedDate
        )
            ? undefined
            : dateFormat(values.idDocIssuedDate, "yyyy-MM-dd"),
        idDocIssuedByDepartmentCode: values.citizenship === citizenshipsDict.RU.value
            ? values.idDocIssuedByDepartmentCode
            : undefined,
        idDocIssuedBy: (
            isCitizenshipNotSpecified
            || !values.idDocIssuedBy
        )
            ? undefined
            : values.idDocIssuedBy,
        snils: values.snils ?
            removeSnilsMask(values.snils) :
            undefined,
        generateAndSendPassword: values.generateAndSendPassword,
    };

    return setNullToEmptyValues({
        ...requestData,
        ...generalFields,
    });
};
