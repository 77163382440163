import React from "react";
import {ScrollMenu} from "react-horizontal-scrolling-menu";
import {isEmpty} from "lodash";

import {ReactComponent as CloseIcon} from "../../images/close_24.svg";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import Text from "../ActualComponents/Text";
import NmButton from "../NmButton";

import bem from "../../utils/bem";
import {onWheel} from "../ActualComponents/Tabs/utils/onWheel";

import {COLOR} from "../../constants/color";

import "./style.sass";

interface IAddItemsForm {
    error?: string,
    onAddItem: () => void,
    items: Array<string>,
    isVisibleAddBlock?: boolean,
    onChangeInput: (event: any, params: any) => void,
    onClickDelete: (item: string) => void,
    inputValue: string,
    inputName?: string,
    inputLabel?: string,
    className?: string,
}

const AddItemsForm = (props: IAddItemsForm) => {
    const {
        error,
        isVisibleAddBlock,
        inputValue,
        className = "",
        onClickDelete,
        onAddItem,
        onChangeInput,
        items,
        inputLabel,
        inputName,
    } = props;
    const [block] = bem("add-items-form", className);

    return (
        <NmForm className={block()}>
            {
                !isVisibleAddBlock &&
                <div className="add-items-form__add-form">
                    <NmInputV2
                        size="xl"
                        error={error}
                        label={inputLabel}
                        value={inputValue}
                        name={inputName}
                        onChange={onChangeInput}
                    />
                    <NmButton
                        size="xl"
                        className="add-items-form__button"
                        onClick={onAddItem}
                        color="light-green"
                    >
                        Добавить
                    </NmButton>
                </div>
            }
            {
                !isEmpty(items) &&
                <ScrollMenu
                    onWheel={onWheel}
                    wrapperClassName="add-items-form__wrapper"
                    scrollContainerClassName={`add-items-form__scroll-container${isVisibleAddBlock ? "_edit-mode" : ""}`}
                    itemClassName="add-items-form__item"
                >
                    {
                        items.map(item => {
                            return (
                                <div
                                    key={item}
                                    //@ts-ignore
                                    itemId={item}
                                    className="add-items-form__email"
                                >
                                    <Text
                                        level="2"
                                        noWrap
                                        color={COLOR.SECONDARY_75}
                                        title={item}
                                    >
                                        {item}
                                    </Text>
                                    {
                                        !isVisibleAddBlock &&
                                        <CloseIcon
                                            className="add-items-form__icon"
                                            onClick={() => {
                                                onClickDelete(item);
                                            }}
                                        />
                                    }
                                </div>
                            );
                        })
                    }
                </ScrollMenu>
            }
        </NmForm>
    );
};

export default AddItemsForm;