import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../../components/CheckboxList";
import NmTitle from "../../../../../components/NmTitle";
import {Dimmer, Loader} from "semantic-ui-react";

import {usePagination} from "../../../../../hooks/usePagination";

import dateFormat, {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {formatAmount} from "../../../../../utils/stringFormat";

import {
    getMinCommissionHistory,
    minCommissionHistoryListProgressSelector,
    minCommissionHistoryListSelector,
    minCommissionHistoryTotalCountSelector,
    minCommissionHistoryTotalPagesSelector,
} from "../../../../../ducks/minCommissions";

const MinCommissionHistory = (props) => {
    const {
        client,
        isCivil,
        onClose,
        commissionType,
    } = props;

    const {
        clientId,
        clientName,
    } = client;

    const dispatch = useDispatch();

    const totalPages = useSelector(minCommissionHistoryTotalPagesSelector);
    const totalCount = useSelector(minCommissionHistoryTotalCountSelector);
    const progress = useSelector(minCommissionHistoryListProgressSelector);

    const list = useSelector(minCommissionHistoryListSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getMinCommissionHistory({
            clientId,
            isCivil,
            pageNum,
            pageSize,
            commissionType,
        }));
    };

    const getRows = () => {
        return list.map((item) => {
            const {
                fromDatetime,
                toDatetime,
                currentCommissionRate,
                threshold,
                changerLastName,
                changerFirstName,
                amount,
            } = item;

            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={[
                            {
                                label: "Период действия",
                                text: `с ${dateFormat(convertUtcToLocal(fromDatetime))} по - ${toDatetime ? dateFormat(convertUtcToLocal(toDatetime)): ""}`,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            isCivil && {
                                label: "Комиссия",
                                text: `${amount} ₽`,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            !isCivil && {
                                label: "Дополнительная комиссия",
                                text: `${currentCommissionRate} ₽ за выплату менее ${formatAmount(threshold)} ₽`,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            {
                                label: "Инициатор",
                                text: changerLastName ? `${changerLastName} ${changerFirstName}` : "Не определено",
                                noWrap: false,
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    const renderTable = () => {
        if (!list.length) {
            return "История изменений отсутствует";
        }

        return (
            progress ?
                <Dimmer
                    active
                    inverted
                >
                    <Loader />
                </Dimmer> :
                <CheckboxList
                    className="fluid"
                    rows={getRows()}
                />
        );
    };

    const renderPagination = () => {
        return (
            <NmPagination
                className="mt-2 mt-md-5 mt-xxl-7"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {
                        `${
                            isCivil ? 
                                "История изменений минимальной комиссии за выплаты НДФЛ компании" :
                                "История изменений дополнительной комиссии по выплатам для компании"
                        } ${clientName}`
                    }
                </NmTitle>
            }
            footer={renderPagination()}
        >
            {renderTable()}
        </NmModal>
    );
};

export default MinCommissionHistory;