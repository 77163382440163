export const PATENTS_PAYMENTS_LIST_REQUEST = "PATENTS_PAYMENTS_LIST_REQUEST";
export const PATENTS_PAYMENTS_LIST_SUCCESS = "PATENTS_PAYMENTS_LIST_SUCCESS";
export const PATENTS_PAYMENTS_LIST_ERROR = "PATENTS_PAYMENTS_LIST_ERROR";

export const ADD_PATENTS_PAYMENT_REQUEST = "ADD_PATENTS_PAYMENT_REQUEST";
export const ADD_PATENTS_PAYMENT_SUCCESS = "ADD_PATENTS_PAYMENT_SUCCESS";
export const ADD_PATENTS_PAYMENT_ERROR = "ADD_PATENTS_PAYMENT_ERROR";

export const ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST = "ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST";
export const ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS = "ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS";
export const ADD_PATENTS_PAYMENT_FROM_FILE_ERROR = "ADD_PATENTS_PAYMENT_FROM_FILE_ERROR";

export const UPDATE_PATENTS_PAYMENT_REQUEST = "UPDATE_PATENTS_PAYMENT_REQUEST";
export const UPDATE_PATENTS_PAYMENT_SUCCESS = "UPDATE_PATENTS_PAYMENT_SUCCESS";
export const UPDATE_PATENTS_PAYMENT_ERROR = "UPDATE_PATENTS_PAYMENT_ERROR";

export const DELETE_PATENTS_PAYMENT_REQUEST = "DELETE_PATENTS_PAYMENT_REQUEST";
export const DELETE_PATENTS_PAYMENT_SUCCESS = "DELETE_PATENTS_PAYMENT_SUCCESS";
export const DELETE_PATENTS_PAYMENT_ERROR = "DELETE_PATENTS_PAYMENT_ERROR";

export const GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST = "GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST";
export const GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS = "GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS";
export const GET_PATENTS_PAYMENT_STATUS_DICT_ERROR = "GET_PATENTS_PAYMENT_STATUS_DICT_ERROR";

export const PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST = "PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST";
export const PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS = "PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS";
export const PATENTS_PAYMENTS_ADMIN_PAGE_ERROR = "PATENTS_PAYMENTS_ADMIN_PAGE_ERROR";

export const PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST";
export const PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS";
export const PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR = "PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR";

export const REFRESH_PATENT_PAYMENT_STATUS_REQUEST = "REFRESH_PATENT_PAYMENT_STATUS_REQUEST";
export const REFRESH_PATENT_PAYMENT_STATUS_SUCCESS = "REFRESH_PATENT_PAYMENT_STATUS_SUCCESS";
export const REFRESH_PATENT_PAYMENT_STATUS_ERROR = "REFRESH_PATENT_PAYMENT_STATUS_ERROR";

export const DECLINE_PATENT_PAYMENT_REQUEST = "DECLINE_PATENT_PAYMENT_STATUS_REQUEST";
export const DECLINE_PATENT_PAYMENT_SUCCESS = "DECLINE_PATENT_PAYMENT_STATUS_SUCCESS";
export const DECLINE_PATENT_PAYMENT_ERROR = "DECLINE_PATENT_PAYMENT_STATUS_ERROR";

export const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST";
export const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS";
export const PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR = "PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR";

export const PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST = "PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST";
export const PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS = "PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS";
export const PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR = "PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR";

export const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST";
export const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS";
export const ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR = "ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR";

export const PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST = "PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST";
export const PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS = "PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS";
export const PATENTS_GET_AVAILABLE_FOR_PAYMENTS_ERROR = "PATENTS_GET_AVAILABLE_FOR_PAYMENTS_ERROR";

export const PATENTS_PAYMENTS_UPDATE_STORE = "PATENTS_PAYMENTS_UPDATE_STORE";