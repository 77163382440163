export const EDM_POSITION_LIST_READ_REQUEST = "EDM_POSITION_LIST_READ_REQUEST";
export const EDM_POSITION_LIST_READ_SUCCESS = "EDM_POSITION_LIST_READ_SUCCESS";
export const EDM_POSITION_LIST_READ_ERROR = "EDM_POSITION_LIST_READ_ERROR";

export const EDM_POSITION_UPDATE_REQUEST = "EDM_POSITION_UPDATE_REQUEST";
export const EDM_POSITION_UPDATE_SUCCESS = "EDM_POSITION_UPDATE_SUCCESS";
export const EDM_POSITION_UPDATE_ERROR = "EDM_POSITION_UPDATE_ERROR";

export const EDM_POSITION_DELETE_REQUEST = "EDM_POSITION_DELETE_REQUEST";
export const EDM_POSITION_DELETE_SUCCESS = "EDM_POSITION_DELETE_SUCCESS";
export const EDM_POSITION_DELETE_ERROR = "EDM_POSITION_DELETE_ERROR";

export const EDM_POSITION_ADD_REQUEST = "EDM_POSITION_ADD_REQUEST";
export const EDM_POSITION_ADD_SUCCESS = "EDM_POSITION_ADD_SUCCESS";
export const EDM_POSITION_ADD_ERROR = "EDM_POSITION_ADD_ERROR";

export const EDM_POSITION_COMMON_COUNT_REQUEST = "EDM_POSITION__COMMON_COUNT_REQUEST";
export const EDM_POSITION_COMMON_COUNT_SUCCESS = "EDM_POSITION__COMMON_COUNT_SUCCESS";
export const EDM_POSITION_COMMON_COUNT_ERROR = "EDM_POSITION__COMMON_COUNT_ERROR";