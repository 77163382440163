import {DEPOSIT_SUB_PAGE} from "../index";

import {isClientUser} from "../../../../constants/roles";
import {
    CLIENT_STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS,
    STATUS_PAYMENT,
    STATUS_PAYMENT_TRANSCRIPTION,
    STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT,
    STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS,
} from "../../../../constants/status";

export const getDepositStatusNaimixOptions = (params) => {
    const {
        subTab,
        subPage,
        role,
    } = params;

    if (subTab === DEPOSIT_SUB_PAGE.INDIVIDUAL_ENTREPRENEUR) {
        return isClientUser(role)
            ? {
                [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
                [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
                [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
                [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
                [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
            }
            : {
                [STATUS_PAYMENT.AWAITING_CONFIRMATION]: STATUS_PAYMENT_TRANSCRIPTION.AWAITING_CONFIRMATION,
                [STATUS_PAYMENT.CONFIRMED]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMED,
                [STATUS_PAYMENT.FOR_PAYMENT]: STATUS_PAYMENT_TRANSCRIPTION.FOR_PAYMENT,
                [STATUS_PAYMENT.PAYMENT_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.PAYMENT_ERROR,
                [STATUS_PAYMENT.CONFIRMATION_ERROR]: STATUS_PAYMENT_TRANSCRIPTION.CONFIRMATION_ERROR,
            };
    }

    if (subPage === DEPOSIT_SUB_PAGE.PAYMENTS_PENDING) {
        return STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT;
    }

    return isClientUser(role)
        ? CLIENT_STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS
        : STATUS_PAYMENT_TRANSCRIPTION_FOR_DEPOSIT_PAYMENTS_IN_PROCESS;
};