import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    GET_ALL_APPLICATIONS_ERROR,
    GET_ALL_APPLICATIONS_REQUEST,
    GET_ALL_APPLICATIONS_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getAllApplicationsSaga = function* ({payload}) {
    try {
        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/reports/order-analytics/responses/getAllApplicationsRichPage",
            client: "/client-adm/reports/order-analytics/responses/get-all-applications-rich-page",
        });

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ALL_APPLICATIONS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ALL_APPLICATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_ALL_APPLICATIONS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ALL_APPLICATIONS_REQUEST, getAllApplicationsSaga),
    ]);
}