import {
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST,
    CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE,
    RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST,
    RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST,
    RESIDENT_VALIDATION_REQUEST,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST,
    TERMINATE_FRAME_CONTRACT_REQUEST,
    UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST,
} from "./actions";

export const getClientDocumentsRegistryPage = (payload) => {
    return {
        type: CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST,
        payload,
    };
};

export const recreateStartWorkNotification = (payload) => {
    return {
        type: RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST,
        payload,
    };
};

export const recreateEndWorkNotification = (payload) => {
    return {
        type: RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST,
        payload,
    };
};

export const updateWorkNotificationsArchived = (payload) => {
    return {
        type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST,
        payload,
    };
};

export const searchContractorByInnOrPhone = (payload) => {
    return {
        type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST,
        payload,
    };
};

export const terminateFrameContract = (payload) => {
    return {
        type: TERMINATE_FRAME_CONTRACT_REQUEST,
        payload,
    };
};

export const validateContractorForContractAction = (payload) => {
    return {
        type: RESIDENT_VALIDATION_REQUEST,
        payload,
    };
};

export const updateClientDocumentsRegistryStore = (payload) => {
    return {
        type: CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE,
        payload,
    };
};