import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositChangeModal from "../../../components/DepositChangeModal";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {changeProjectOrdersLimit} from "../../../ducks/bff/clients/projects/actionCreators";
import {clientProjectsProgressActionSelector} from "../../../ducks/bff/clients/projects/selectors";

const ChangeProjectOrdersLimit = (props) => {
    const {
        name,
        onClose,
        clientId,
        projectId,
    } = props;
    const dispatch = useDispatch();
    const progress = useSelector(clientProjectsProgressActionSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
            type: paymentType,
        } = requestData;

        dispatch(changeProjectOrdersLimit({
            ordersLimit: amount,
            clientId,
            projectId,
            paymentType,
            onSuccess: () => {
                onClose();
            },
        }));
    };

    return (
        <DepositChangeModal
            onClose={onClose}
            targetText="объекта"
            targetName={name}
            onSubmit={onSubmit}
            progress={progress}
            isHideConfirm={true}
            header={`Изменение лимита заказов на проекте "${name}"`}
            amountLabel="Сумма лимита заказов на проекте"
            text="Изменить лимит заказов на проекте по счету"
            maxValueNpd={clientProperties.ordersLimit}
            maxValueNdfl={clientProperties.civilOrdersLimit}
            minValue={0}
        />
    );
};

export default ChangeProjectOrdersLimit;