import {createSelector} from "reselect";

import {getWorkAddressInfoArr} from "../../../utils/dadata";
import {getTotalPages} from "../../../utils/mathHelper";

const advertisementSelector = state => state.bff.advertisement;

export const advertisementListSelector = createSelector(
    advertisementSelector,
    ({list}) => list,
);
export const advertisementListFormattedToTaskIdsSelector = createSelector(
    advertisementSelector, ({list}) => {
        return list.map(value => `${value.baseModel.clientId}:${value.baseModel.advertId}`);
    },
);

export const advertisementProgressSelector = createSelector(
    advertisementSelector,
    ({progress, progressAction, progressContractorList}) => {
        return {
            progress,
            progressAction,
            progressContractorList,
        };
    },
);

export const advertisementTotalPagesSelector = createSelector(
    advertisementSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);

export const advertisementTotalCountSelector = createSelector(
    advertisementSelector,
    ({totalCount}) => totalCount,
);

export const advertisementCardSelector = createSelector(
    advertisementSelector,
    ({card}) => card,
);

export const advertisementContractorListSelector = createSelector(advertisementSelector,
    ({contractorList}) => contractorList,
);

export const advertisementContractorsTotalCountSelector = createSelector(advertisementSelector,
    ({contractorsTotalCount}) => contractorsTotalCount,
);

export const advertisementContractorsTotalPagesSelector = createSelector(advertisementSelector,
    ({contractorsTotalCount, contractorsPageData: {pageSize = 0}}) => {
        return getTotalPages(contractorsTotalCount, pageSize);
    },
);

export const advertisementErrorSelector = createSelector(
    advertisementSelector, ({error}) => error,
);

export const advertisementActionPageDataSelector = createSelector(
    advertisementSelector, ({actionPageData}) => actionPageData,
);

const funnelsSelector = (state) => state.bff.advertisement.funnels;

export const advertisementFunnelsListSelector = createSelector(
    funnelsSelector,
    ({list}) => {
        return list.map(item => {
            const {
                workAddressInfo,
                departureAddressesInfo,
            } = item;

            return {
                ...item,
                workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
            };
        });
    },
);
export const advertisementFunnelsContractorIdsSelector = createSelector(
    funnelsSelector,
    ({list}) => {
        return [...new Set(list.map(item => item.contractorId))];
    },
);
export const advertisementFunnelsTotalCountSelector = createSelector(
    funnelsSelector,
    ({totalCount}) => totalCount,
);

export const advertisementFunnelsProgressSelector = createSelector(
    funnelsSelector,
    ({progress}) => progress,
);
export const advertisementFunnelsCountMapSelector = createSelector(
    funnelsSelector,
    ({countMap}) => countMap,
);
export const advertisementFunnelsPageDataSelector = createSelector(
    funnelsSelector,
    ({pageData}) => pageData,
);
