import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_ADD_EMAIL_NOTIFICATION_ERROR,
    BFF_ADD_EMAIL_NOTIFICATION_REQUEST,
    BFF_ADD_EMAIL_NOTIFICATION_SUCCESS,
    BFF_DELETE_EMAIL_NOTIFICATION_ERROR,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS,
    BFF_DELETE_EMAIL_NOTIFICATION_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS,
    BFF_GET_EMAIL_NOTIFICATION_BY_ID_ERROR,
    BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST,
    BFF_GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS,
    BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR,
    BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST,
    BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {
    toastError,
    toastSuccess,
} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        client: "/client-adm/notifications/email-notifications",
        admin: "/adm/clients/client-card/notifications/email-notifications",
    });
};

// POST bff/client-adm/notifications/notification-list/add
// POST bff/adm/clients/client-card/notifications/notification-list/add
export const addEmailNotificationSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_EMAIL_NOTIFICATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Рассылка на почту успешно сохранена");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ADD_EMAIL_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADD_EMAIL_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

// DELETE bff/client-adm/notifications/notification-list/delete
// DELETE bff/adm/clients/client-card/notifications/notification-list/delete
export const deleteEmailNotificationSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            notificationEmailId,
            clientId,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete?notificationEmailId=${notificationEmailId}&clientId=${clientId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: BFF_DELETE_EMAIL_NOTIFICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Рассылка на почту успешно удалена");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DELETE_EMAIL_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

// POST bff/client-adm/notifications/notification-list/page
// POST bff/adm/clients/client-card/notifications/notification-list/page
// Получение страницы
export const getPageSettingNotificationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, reqData);

        const {errorMessage} = result;

        getResult();

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR,
            payload: error,
        });
    }
};

// DELETE bff/client-adm/notifications/notification-list/delete-all
// DELETE bff/adm/clients/client-card/notifications/notification-list/delete-all
export const deleteEmailNotificationListSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete-all`, {data});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(data.notificationEmailIds.length > 1 ? "Рассылки на почту успешно удалены" : "Рассылка на почту успешно удалена");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR,
            payload: error,
        });
    }
};

// GET bff/client-adm/notifications/notification-list/find-by-id
// GET bff/adm/clients/client-card/notifications/notification-list/find-by-id
export const getEmailNotificationByIdSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/find-by-id`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_EMAIL_NOTIFICATION_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: BFF_GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_EMAIL_NOTIFICATION_BY_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST, getPageSettingNotificationSaga),
        takeEvery(BFF_ADD_EMAIL_NOTIFICATION_REQUEST, addEmailNotificationSaga),
        takeEvery(BFF_DELETE_EMAIL_NOTIFICATION_REQUEST, deleteEmailNotificationSaga),
        takeEvery(BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST, deleteEmailNotificationListSaga),
        takeEvery(BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST, getEmailNotificationByIdSaga),
    ]);
}