import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DropzoneV2 from "../../../../../../../components/ActualComponents/DropzoneV2";
import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {
    useContractorInfoAdditionalDocumentsVerificationWarnings,
} from "../../../additional-documents-verification/components/form/hooks/useWarnings";
import {useDataMedicalBookInfoEditForm} from "./hooks/useData";

import {
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../../constants/clientList";

import {
    contractorAdditionalDocumentsProgressUpdateDataSelector,
} from "../../../../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {
    fileContractorScanProgressAddSelector,
    fileContractorScanProgressSelector,
} from "../../../../../../../ducks/fileStore";

const ContractorMedicalBookInfoEditForm = (props) => {
    const {
        onClose = () => {
        },
        contractorId,
        data,
    } = props;

    const progress = useSelector(contractorAdditionalDocumentsProgressUpdateDataSelector);
    const progressScan = useSelector(fileContractorScanProgressAddSelector);
    const progressGetScan = useSelector(fileContractorScanProgressSelector);

    const {
        files,
        onDropScan,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
    } = useDataMedicalBookInfoEditForm({
        data,
        contractorId,
        onClose,
    });

    const {
        warnings,
    } = useContractorInfoAdditionalDocumentsVerificationWarnings({
        values,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Медицинская книжка (доп. документ)
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    className="col-16"
                    classNameMainButtons="col-16 justify-content-between"
                    submit={handleSubmit}
                    onClose={onClose}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progress}
                />
            }
            loading={progress || progressScan || progressGetScan}
        >
            <NmForm addMargin>
                <NmInputV2
                    required
                    size="xl"
                    maskChar={null}
                    mask="99999999"
                    label="Типографский номер"
                    value={values.medicalBookNumber}
                    name="medicalBookNumber"
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    onBlur={() => setFieldTouched("medicalBookNumber", true)}
                    error={
                        touched?.medicalBookNumber &&
                        errors?.medicalBookNumber
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография разворота главной страницы медицинской книжки"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]) ? "Выбрать файл" : "Заменить"}
                    error={
                        touched?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN] &&
                        errors?.files?.[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]
                    }
                />
                <DropzoneV2
                    isTopImagePreview
                    label="Фотография QR-кода для проверки медицинской книжки"
                    isVisibleLabel
                    files={files[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN]}
                    multiple={false}
                    maxSize={10}
                    accept=".bmp, .png, .jpeg, .jpg"
                    format="*.bmp, *.png, *.jpeg, *.jpg"
                    onChange={(files) => onDropScan(files, CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN)}
                    uploadBtnText={isEmpty(files[CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN]) ? "Выбрать файл" : "Заменить"}
                />
                <NmDatePicker
                    size="xl"
                    label="Дата Выдачи"
                    dateFormat="dd.MM.yy"
                    selected={values.medicalBookIssuedDate}
                    name="medicalBookIssuedDate"
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    isClearable={true}
                    maxDate={new Date()}
                    warning={warnings.medicalBookIssuedDate}
                />
            </NmForm>
        </NmModal>
    );
};

export default ContractorMedicalBookInfoEditForm;