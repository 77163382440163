import {all, put, takeEvery} from "@redux-saga/core/effects";
import {isBoolean} from "lodash";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/recruitment/access-control",
        client: "/client-adm/recruitment/access-control",
    });
};

//POST /bff/adm/clients/client-card/recruitment/access-control/totalCounts
// GET /bff/client-adm/recruitment/access-control/totalCounts
// Получить счетчики для вкладок раздела управления доступом
const getTotalCountsRecruitmentAccessControl = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/totalCounts?clientId=${payload.clientId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/access-control/{id}/can-set-candidate-status
// GET /bff/client-adm/recruitment/access-control/id/can-set-candidate-status
// Получить флаг доступа наблюдателя к перемещению кандидатов в воронке
// Возвращает null, если наблюдатель не найден или не совпадает clientId из токена
const getRecruitmentAccessControlCanSetCandidateStatus = function* ({payload}) {
    const {
        id,
    } = payload;

    try {
        const result = yield request.bff.get(`${getController()}/${id}/can-set-candidate-status`, payload);

        if (!isBoolean(result)) {
            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST, getTotalCountsRecruitmentAccessControl),
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_GET_CAN_SET_CANDIDATE_STATUS_REQUEST, getRecruitmentAccessControlCanSetCandidateStatus),
    ]);
}