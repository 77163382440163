import React from "react";

import {ReactComponent as DoneFilledIcon} from "../../../images/done_white_filled_24.svg";
import AdaptiveIcon from "../../ActualComponents/AdaptiveIcon";

import {COLOR} from "../../../constants/color";
import {
    CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS,
    CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS_DICT,
} from "../../../constants/contractor";

const ContractorBankSenderStatus = (props) => {
    const {
        lastIdentificationRequest,
        className,
    } = props;

    if (!CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS[lastIdentificationRequest?.status]) {
        return null;
    }

    const getIcon = () => {
        if (
            [
                CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.CREATING_ERROR_IN_NAMIX,
                CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.REJECTED,
                CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.REJECTING,
            ].includes(lastIdentificationRequest?.status)
        ) {
            return (
                <AdaptiveIcon>
                    <DoneFilledIcon color={COLOR.NEGATIVE_100} />
                </AdaptiveIcon>
            );
        }

        return (
            <AdaptiveIcon>
                <DoneFilledIcon
                    color={
                        [
                            CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.ACCEPTED,
                            CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.ACCEPTING,
                        ].includes(lastIdentificationRequest?.status)
                            ? COLOR.PRIMARY_100
                            : COLOR.SECONDARY_10
                    }
                />
            </AdaptiveIcon>
        );
    };

    return (
        <div className={`d-flex align-items-center ${className}`}>
            <div className="me-1">
                {getIcon()}
            </div>
            {CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS_DICT[lastIdentificationRequest?.status]?.TEXT}
        </div>
    );
};

export default ContractorBankSenderStatus;