import {useDispatch, useSelector} from "react-redux";

import {useModal} from "../../../../hooks/useModal";

import {getUserRole} from "../../../../utils/access";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS} from "../../../../constants/documentType";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    archiveDeferredActsAcceptance,
    cancelOutstandingDeferredActsAcceptanceWork,
    submitDeferredActsAcceptanceWork,
} from "../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/actionCreators";
import {exportDeferredActsOfAcceptanceWorkCard} from "../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";

// toDo: src/containers/documents/additional-agreements/hooks/useAction.js, функционал схож, проанализировать на предмет унификации логики
export const useDeferredActsOfAcceptanceWorkAction = (params) => {
    const {
        isList,
        isCard,
        clientId,
        registryId,
    } = params;

    const dispatch = useDispatch();

    const role = getUserRole();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const getMediaControls = (item) => {
        const isAccessActionPostActRegistry = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionPostActRegistry,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionPostActRegistryItem,
        ]);

        const isVisibleSignButton = item.totalItemsCount
            && !item.archived
            && item.status?.value === DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.CREATED
            && isAccessActionPostActRegistry;

        const sendToSign = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите передать реестр на подписание?",
                            onConfirm: () => {
                                dispatch(submitDeferredActsAcceptanceWork({
                                    clientId,
                                    registryId: item.registryId,
                                }));
                            },
                        },
                    });
                },
                color: "green",
                children: "Передать на подписание",
            },
            visible: isVisibleSignButton,
        };
        const cancelUnfulfilled = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите отменить подписание и удалить невыполненные строки реестра?",
                            onConfirm: () => {
                                dispatch(cancelOutstandingDeferredActsAcceptanceWork({
                                    clientId,
                                    registryId: item.registryId,
                                }));
                            },
                        },
                    });
                },
                children: "Отменить невыполненные",
            },
            visible: !!item.hasNotCompletedItems
                && !item.archived
                &&  [
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.DECLINED,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.SUBMITTED_TO_SIGNING,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.PARTIALLY_SIGNED,
                ].includes(item.status?.value)
                && isAccessActionPostActRegistry,
        };
        const repeatUnfulfilled = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        confirmProps: {
                            content: "Вы действительно хотите повторить невыполненные строки реестра?",
                            onConfirm: () => {
                                dispatch(submitDeferredActsAcceptanceWork({
                                    clientId,
                                    isReSubmit: true,
                                    registryId: item.registryId,
                                }));
                            },
                        },
                    });
                },
                children: "Повторить невыполненные",
            },
            visible: !!item.hasNotCompletedItems
                && !item.archived
                &&  [
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.DECLINED,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.SUBMITTED_TO_SIGNING,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.PARTIALLY_SIGNED,
                ].includes(item.status?.value)
                && isAccessActionPostActRegistry,
        };
        const log = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        isOpenLog: true,
                        item,
                    });
                },
                children: "Лог",
            },
            visible: [
                ADMIN,
                NM_MANAGER,
            ].includes(role)
                && isAccessByRestrictions([CLIENT_USER_RESTRICTIONS_VARIABLE.viewPostActRegistryLog]),
        };

        if (isList) {
            const edit = {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            isOpenEdit: true,
                            registryId: item.registryId,
                        });
                    },
                    color: "grey",
                    children: "Редактировать",
                },
                visible: isList
                    && !item.archived
                    && item.status?.value === DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.CREATED
                    && isAccessActionPostActRegistry,
            };
            const archive = {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        onOpenModal({
                            confirmProps: {
                                content: item.archived
                                    ? "Вы уверены, что хотите перенести реестр актов из архива?"
                                    : "Вы уверены, что хотите перенести реестр актов в архив?",
                                onConfirm: () => {
                                    dispatch(archiveDeferredActsAcceptance({
                                        registryId: item.registryId,
                                        clientId,
                                        archived: item.archived,
                                    }));
                                },
                            },
                        });
                    },
                    children: item.archived ? "Из архива" : "Архивировать",
                },
                visible: isList
                    && [
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.CREATED,
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.DECLINED,
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS.FULLY_SIGNED,
                    ].includes(item.status?.value)
                    && isAccessActionPostActRegistry,
            };

            return {
                renderCount: {
                    mobile: 0,
                    tablet: 0,
                    desktop: 0,
                },
                buttons: [
                    sendToSign,
                    edit,
                    repeatUnfulfilled,
                    cancelUnfulfilled,
                    archive,
                    log,
                ],
            };
        }

        const upload = {
            component: COMPONENT.BUTTON,
            props: {
                size: "xl",
                color: "light-green",
                children: "Выгрузить",
                onClick: () => {
                    dispatch(exportDeferredActsOfAcceptanceWorkCard({
                        clientId,
                        registryId,
                    }));
                },
            },
            visible: isCard
                && Boolean(item.totalItemsCount)
                && isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.uploadPostActRegistry,
                    CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_uploadPostActRegistry,
                ]),
        };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            size: "xl",
            buttons: [
                sendToSign,
                upload,
                repeatUnfulfilled,
                cancelUnfulfilled,
                log,
            ],
        };
    };

    return {
        onOpenModal,
        modalData,
        onCloseModal,
        getMediaControls,
    };
};
