import React from "react";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import Text from "../../../../components/ActualComponents/Text";
import {CitizenshipWithFlag} from "../../../../components/CitizenshipWithFlag";

import {getFullRegistrationSteps} from "../../../../components/FullRegistration/utils/getSteps";
import {isVisiblePreviouslyIssuedPassport} from "../../../../utils/contractors";
import dateFormat from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {FULL_REGISTRATION_STEP} from "../../../../components/FullRegistration/constants";
import {citizenshipsDict} from "../../../../constants/citizenships";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
    CONTRACTOR_FILE_TYPES,
} from "../../../../constants/clientList";
import {COLOR} from "../../../../constants/color";
import {
    CONTRACTOR_ARRIVAL_DOC_TYPE_CODE,
    CONTRACTOR_ARRIVAL_DOC_TYPE_DICT,
    CONTRACTOR_MIGRATION_STATUS,
    CONTRACTOR_STATUS,
    CONTRACTOR_VHI_POLICE_FORMAT,
    EDM_PASSPORT_TYPE,
} from "../../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE, CONTRACTOR_SEGMENT_INFO_TYPE, GENDER} from "../../../../constants/contractorInfo";
import {
    ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../constants/roles";

export const getMainDocumentsCardsList = (params) => {
    const {
        card,
        migrationStatusDict,
        vhiPolicyTypesDict,
        vhiPolicyFormatsDict,
        isVisibleParentDocumentScans,
        onOpenFullRegistration,
        innCard,
        individualEntrepreneurCard,
        passportTypeDict,
        additionalDocumentsAggregation,
    } = params;

    const {
        citizenship,
        passportType,
        edmPassportType,
        migrationStatus,
        arrivalDocType,
        locatedOutsideRussia,
        registrationStatus,
        isPreviouslyIssuedPassportsInfoRequired,
    } = card;

    const steps = getFullRegistrationSteps({
        citizenship,
        migrationStatus,
        locatedOutsideRussia,
        registrationStatus,
        isPreviouslyIssuedPassportsInfoRequired,
    });

    const role = ls(USER_ROLE);
    const isRu = citizenshipsDict.RU.value === citizenship;
    const vhiPolicyTypeText = vhiPolicyTypesDict[card.vhiPolicyType];
    const isInternalPassport = ([
        citizenshipsDict.BY.value,
        citizenshipsDict.KZ.value,
        citizenshipsDict.KG.value,
    ].includes(citizenship) &&
            passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) ||
        (
            [
                citizenshipsDict.TJ.value,
                citizenshipsDict.UA.value,
            ].includes(citizenship) &&
            edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE
        );

    const getAdditionalDocumentsScanCount = (params) => {
        const {
            documentType,
            scanType,
        } = params;

        return additionalDocumentsAggregation[documentType]?.indexedFilesInfo?.[scanType]?.count;
    };

    const additionalDocumentsCounts = {
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECK_SCAN]: getAdditionalDocumentsScanCount({
            documentType: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECKS,
            scanType: CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECK_SCAN,
        }),
    };

    const getMainSidePassportType = () => {
        switch (citizenship) {
            case citizenshipsDict.RU.value:
                return CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO;
            case citizenshipsDict.TJ.value:
            case citizenshipsDict.UA.value:
                return edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE ?
                    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE :
                    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA;
            default:
                return passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT ?
                    CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE :
                    CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA;
        }
    };

    const getMainSidePassportLabel = () => {
        if (isInternalPassport) {
            return "Фотография лицевой стороны паспорта";
        }

        if (citizenship === citizenshipsDict.BY.value) {
            return "Фотография разворота главной страницы заграничного паспорта";
        }

        return [
            citizenshipsDict.DN.value,
            citizenshipsDict.LG.value,
        ].includes(citizenship) ?
            "Фотография разворота главной страницы паспорта" :
            "Основной разворот паспорта";
    };

    const getInfoPassportData = () => {
        const isVisiblePassportType = [
            citizenshipsDict.BY.value,
            citizenshipsDict.KZ.value,
            citizenshipsDict.KG.value,
            citizenshipsDict.TJ.value,
            citizenshipsDict.UA.value,
        ].includes(citizenship) &&
            ![
                CONTRACTOR_MIGRATION_STATUS.REFUGEE,
                CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE,
            ].includes(migrationStatus);

        const baseLabels = [
            isVisiblePassportType && {
                label: "Тип паспорта",
                text: [citizenshipsDict.TJ.value, citizenshipsDict.UA.value].includes(citizenship) ?
                    EDM_PASSPORT_TYPE[card.edmPassportType]?.TEXT : passportTypeDict[card.passportType],
            },
            {label: "Фамилия", text: card.lastName},
            {label: "Имя", text: card.firstName},
            {label: "Отчество", text: card.patronymic},
            {
                row: {
                    labels: [
                        {
                            label: "Пол",
                            text: GENDER[card.gender],
                            className: "col-16 col-md-4",
                        },
                        {
                            label: "Дата рождения",
                            text: dateFormat(card.birthDate, "dd.MM.yyyy"),
                            className: "col-16 col-md-4",
                        },
                    ],
                },
            },
        ];

        if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.REFUGEE) {
            return {
                title: "Данные из удостоверения беженца",
                labels: [
                    ...baseLabels,
                    {label: "Серия и номер", text: card.refugeeCertificateNum},
                    {label: "Кем выдано", text: card.refugeeCertificateIssuedBy},
                    {label: "Номер личного дела", text: card.refugeeCertificatePersonalFileNum},
                    {
                        row: {
                            labels: [
                                {
                                    label: "Дата выдачи",
                                    text: dateFormat(card.refugeeCertificateIssuedDate, "dd.MM.yyyy"),
                                    className: "col-16 col-md-4",
                                },
                                {
                                    label: "Дата окончания срока действия",
                                    text: dateFormat(card.refugeeCertificateValidToDate, "dd.MM.yyyy"),
                                },
                            ],
                        },
                    },
                    {label: "Место рождения", text: card.refugeeCertificateBirthPlace},
                ],
                scans: [
                    {
                        label: "Фотография разворота удостоверения с персональной информацией",
                        type: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD,
                    },
                    {
                        label: "Фотография разворота удостоверения с отметкой о продлении срока действия",
                        type: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK,
                    },
                    {
                        label: "Селфи с удостоверением беженца",
                        type: CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE,
                    },
                ],
            };
        }

        if (migrationStatus === CONTRACTOR_MIGRATION_STATUS.TEMPORARY_REFUGEE) {
            return {
                title: "Данные из свидетельства о предоставлении временного убежища",
                labels: [
                    ...baseLabels,
                    {label: "Серия и номер", text: card.temporaryRefugeeCertificateNum},
                    {label: "Кем выдано", text: card.temporaryRefugeeCertificateIssuedBy},
                    {label: "Номер личного дела", text: card.temporaryRefugeeCertificatePersonalFileNum},
                    {
                        row: {
                            labels: [
                                {
                                    label: "Дата выдачи",
                                    text: dateFormat(card.temporaryRefugeeCertificateIssuedDate, "dd.MM.yyyy"),
                                    className: "col-16 col-md-4",
                                },
                                {
                                    label: "Дата окончания срока действия",
                                    text: dateFormat(card.temporaryRefugeeCertificateValidToDate, "dd.MM.yyyy"),
                                },
                            ],
                        },
                    },
                    {label: "Место рождения", text: card.temporaryRefugeeCertificateBirthPlace},
                ],
                scans: [
                    {
                        label: "Фотография разворота свидетельства о предоставлении временного убежища с персональной информацией",
                        type: CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA,
                    },
                    {
                        label: "Селфи со свидетельством о предоставлении временного убежища",
                        type: CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE,
                    },
                ],
            };
        }

        if (citizenship === citizenshipsDict.NOT_SPECIFIED.value) {
            return {
                title: "Данные из временного удостоверения личности лица без гражданства",
                labels: [
                    ...baseLabels,
                    {label: "Номер", text: card.statelessPersonTemporaryIdNum},
                    {label: "Причина выдачи", text: card.statelessPersonTemporaryIdIssueReason},
                    {label: "Кем выдано", text: card.statelessPersonTemporaryIdIssuedBy},
                    {
                        row: {
                            labels: [
                                {
                                    label: "Дата выдачи",
                                    text: dateFormat(card.statelessPersonTemporaryIdIssuedDate, "dd.MM.yyyy"),
                                    className: "col-16 col-md-4",
                                },
                                {
                                    label: "Дата окончания срока действия",
                                    text: dateFormat(card.statelessPersonTemporaryIdValidToDate, "dd.MM.yyyy"),
                                },
                            ],
                        },
                    },
                    {label: "Место рождения", text: card.statelessPersonTemporaryIdBirthPlace},
                ],
                scans: [
                    {
                        label: "Фотография разворота удостоверения личности лица без гражданства с персональной информацией",
                        type: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD,
                    },
                    {
                        label: "Селфи с временным удостоверением личности лица без гражданства",
                        type: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE,
                    },
                ],
            };
        }

        return {
            title: "Паспортные данные",
            labels: [
                ...baseLabels,
                {label: "Место рождения", text: card.birthPlace},
                {label: "Кем выдан", text: card.idDocIssuedBy},
                {
                    row: {
                        labels: [
                            {
                                label: "Дата выдачи",
                                text: dateFormat(card.idDocIssuedDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            [
                                citizenshipsDict.RU.value,
                                citizenshipsDict.DN.value,
                            ].includes(citizenship) && {
                                label: "Код подразделения",
                                text: card.idDocIssuedByDepartmentCode,
                                className: "col-16 col-md-4",
                            },
                            ![
                                citizenshipsDict.RU.value,
                                citizenshipsDict.LG.value,
                                citizenshipsDict.DN.value,
                            ].includes(citizenship) && {
                                label: "Дата окончания срока действия",
                                text: dateFormat(card.idDocValidToDate, "dd.MM.yyyy"),
                            },
                        ].filter(Boolean),
                    },
                },
                {label: isRu ? "Серия и номер паспорта" : "Номер паспорта", text: card.idDocNumber},
                ![
                    citizenshipsDict.RU.value,
                    citizenshipsDict.AM.value,
                    citizenshipsDict.LG.value,
                    citizenshipsDict.DN.value,
                    citizenshipsDict.UZ.value,
                ].includes(citizenship) &&
                {label: "Идентификационный номер", text: card.ein},
            ],
            scans: [
                {
                    label: getMainSidePassportLabel(),
                    type: getMainSidePassportType(),
                },
                isInternalPassport && {
                    label: "Фотография обратной стороны паспорта",
                    type: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
                },
                citizenship === citizenshipsDict.BY.value && !isInternalPassport && {
                    label: "Фотография разворота страницы заграничного паспорта с местом рождения",
                    type: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
                },
                {
                    label: "Селфи с паспортом",
                    type: CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
                },
            ],
        };
    };

    const getInfoResidencePlaceNotResident = () => {
        if (arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.RESIDENCE_PERMIT) {
            return ({
                labels: [
                    {label: "Адрес", text: card.mainDocumentStampAddress},
                    {
                        label: "Дата выдачи в штампе",
                        text: dateFormat(card.registrationDate || card.mainDocumentStampIssuedDate, "dd.MM.yyyy"),
                    },
                    {
                        label: "Дата окончания срока действия в штампе",
                        text: dateFormat(card.arrivalNoticeStayEndDate || card.mainDocumentStampValidToDate, "dd.MM.yyyy"),
                    },
                ],
                scans: [
                    {
                        label: "Штамп регистрации по месту жительства в документе",
                        type: CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS,
                    },
                ],
            });
        }

        if (arrivalDocType === CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION) {
            return ({
                labels: [
                    {label: "Адрес", text: card.address},
                    {label: "Дата постановки на учет", text: dateFormat(card.registrationDate, "dd.MM.yyyy")},
                    {
                        label: "Дата окончания срока пребывания",
                        text: dateFormat(card.arrivalNoticeStayEndDate, "dd.MM.yyyy"),
                    },
                ],
                scans: [
                    {
                        label: "Фотография лицевой стороны бланка о прибытии",
                        type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
                    },
                    {
                        label: "Фотография обратной стороны бланка о прибытии",
                        type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
                    },
                ],
            });
        }

        return {
            labels: [],
            scans: [],
        };
    };

    return ([
        {
            className: "mt-4",
            title: "Гражданство",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.CITIZENSHIP_AND_MIGRATION_STATUS,
            registrationStep: FULL_REGISTRATION_STEP.CITIZENSHIP_AND_MIGRATION_STATUS,
            labels: [
                {
                    text: <CitizenshipWithFlag
                        textLevel="4"
                        citizenship={citizenship}
                    />,
                },
                !locatedOutsideRussia &&
                ![
                    citizenshipsDict.RU.value,
                    citizenshipsDict.DN.value,
                    citizenshipsDict.LG.value,
                ].includes(citizenship) && {
                    label: <Text
                        type="title"
                        level="4"
                        color={COLOR.SECONDARY_100}
                    >
                        Миграционный статус
                    </Text>,
                    text: migrationStatusDict[migrationStatus],
                },
                card.locatedOutsideRussia && {
                    text: <NmCheckboxV2
                        disabled
                        label="Исполнитель за пределами России"
                        checked={card.locatedOutsideRussia}
                        onChange={() => {
                        }}
                    />,
                },
            ],
            editButtonAssets: {
                tablet: {
                    children: "Редактировать",
                },
            },
            mediaControlsButtons: [
                {
                    onClick: () => {
                        onOpenFullRegistration({});
                    },
                    size: "sm",
                    color: "green",
                    children: "Регистрация/Предрегистрация",
                    visible: card.status === CONTRACTOR_STATUS.BASIC_REGISTRATION && [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role),
                },
            ],
            mediaControlsRenderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 2,
            },
            isVisible: true,
        },
        innCard,
        individualEntrepreneurCard,
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.PASSPORT_DATA,
            registrationStep: FULL_REGISTRATION_STEP.PERSONAL_DATA,
            title: getInfoPassportData().title,
            labels: getInfoPassportData().labels,
            scans: getInfoPassportData().scans,
            isVisible: steps.includes(FULL_REGISTRATION_STEP.PERSONAL_DATA),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.PASSPORT_TRANSLATION,
            registrationStep: FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION,
            title: "Перевод паспорта",
            scans: [
                {
                    label: "Фотография перевода паспорта на русский язык",
                    type: CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION,
                },
                {
                    label: "Фотография нотариального заверения перевода паспорта (при наличии)",
                    type: CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.RESIDENCE_PLACE_RESIDENT,
            registrationStep: FULL_REGISTRATION_STEP.RESIDENCE,
            title: "Регистрация",
            labels: [
                {label: "Адрес", text: card.address},
            ],
            scans: [
                {
                    label: "Разворот с регистрацией",
                    type: CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.RESIDENCE),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.PREVIOUSLY_ISSUED_PASSPORTS_INFO_RESIDENT,
            registrationStep: FULL_REGISTRATION_STEP.PREVIOUSLY_ISSUED_PASSPORTS_INFO_RESIDENT,
            title: "Сведения о ранее выданных паспортах",
            scans: [
                {
                    label: "Фотография разворота паспорта со сведениями о ранее выданных паспортах",
                    type: CONTRACTOR_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN,
                },
            ],
            isVisible: isVisiblePreviouslyIssuedPassport({
                isPreviouslyIssuedPassportsInfoRequired,
                registrationStatus,
                citizenship,
            }),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.RESIDENCE_PLACE_NOT_RESIDENT,
            registrationStep: FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT,
            title: "Регистрация иностранного гражданина по месту жительства",
            labels: [
                {label: "Регистрация", text: CONTRACTOR_ARRIVAL_DOC_TYPE_DICT[card.arrivalDocType]},
                ...getInfoResidencePlaceNotResident().labels,
            ],
            scans: [
                ...getInfoResidencePlaceNotResident().scans,
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.VISA,
            registrationStep: FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD,
            title: "Виза",
            labels: [
                {label: "Номер", text: card.visaNum},
                {label: "Дата выдачи", text: dateFormat(card.visaIssuedDate, "dd.MM.yyyy")},
                {
                    row: {
                        labels: [
                            {
                                label: "Дата начала действия",
                                text: dateFormat(card.visaActiveFromDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Дата окончания действия",
                                text: dateFormat(card.visaActiveUntilDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                {label: "ID визы", text: card.visaId},
            ],
            scans: [
                {
                    label: "Фотография визы с персональной информацией",
                    type: CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.MIGRATION_CARD,
            registrationStep: FULL_REGISTRATION_STEP.MIGRATION_CARD,
            title: "Миграционная карта",
            labels: [
                {
                    label: "Серия и номер карты",
                    text: card.migrationCardNumber,
                    classNameText: "text-break-all",
                },
                {label: "Дата выдачи документа", text: dateFormat(card.migrationCardIssuedDate, "dd.MM.yyyy")},
                {label: "Дата окончания срока действия", text: dateFormat(card.migrationCardValidToDate, "dd.MM.yyyy")},
            ],
            scans: [
                {
                    label: "Фотография лицевой стороны миграционной карты",
                    type: CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD,
                },
                {
                    label: "Фотография обратной стороны миграционной карты",
                    type: CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.MIGRATION_CARD),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.LABOR_PATENT,
            registrationStep: FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT,
            title: "Патент на осуществление трудовой деятельности в России",
            labels: [
                {
                    row: {
                        labels: [
                            {
                                label: "Серия",
                                text: card.employmentPatentSeries,
                                className: "col-16 col-md-2",
                            },
                            {
                                label: "Номер",
                                text: card.employmentPatentNum,
                                className: "col-16 col-md-2",
                            },
                        ],
                    },
                },
                {
                    row: {
                        labels: [
                            {
                                label: "Дата выдачи",
                                text: dateFormat(card.employmentPatentIssuedDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-2",
                            },
                            {
                                label: "Регистрационный номер",
                                text: card.employmentPatentRegistrationNumber,
                                className: "col-16 col-md-2",
                            },
                        ],
                    },
                },
            ],
            scans: [
                {
                    label: "Лицевая сторона патента",
                    type: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN,
                },
                {
                    label: "Обратная сторона патента",
                    type: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN,
                },
                {
                    label: "Чек об оплате патента",
                    type: CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN,
                },
                {
                    label: "Чеки об оплате патента (дополнительно)",
                    type: CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECK_SCAN,
                    isShowFullSlider: true,
                    index: 0,
                    totalCount: additionalDocumentsCounts[CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_PATENT_ADDITIONAL_CHECK_SCAN],
                },
            ],
            isFullWidthInfoBlocks: true,
            isVisible: steps.includes(FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.TEMPORARY_RESIDENCE_PERMIT,
            registrationStep: FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
            title: "Разрешение на временное проживание",
            labels: [
                {label: "Номер", text: card.temporaryResidencePermitNum},
                {
                    row: {
                        labels: [
                            {
                                label: "Дата принятия решения",
                                text: dateFormat(card.temporaryResidencePermitDecisionDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Срок действия",
                                text: dateFormat(card.temporaryResidencePermitValidToDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
                {label: "Кем выдан", text: card.temporaryResidencePermitDecisionIssuedBy},
            ],
            scans: [
                {
                    label: "Фотография разворота РВП с персональной информацией",
                    type: CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.RESIDENCE_PERMIT,
            registrationStep: FULL_REGISTRATION_STEP.RESIDENCE_PERMIT,
            title: "Вид на жительство",
            labels: [
                {label: "Серия и номер", text: card.residencePermitNumber},
                {label: "Номер принятия решения", text: card.residencePermitDecisionNumber},
                {
                    label: "Дата принятия решения",
                    text: dateFormat(card.residencePermitDecisionDate, "dd.MM.yyyy"),
                },
                {
                    row: {
                        labels: [
                            {
                                label: "Дата выдачи",
                                text: dateFormat(card.residencePermitIssuedDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Дата окончания срока действия",
                                text: dateFormat(card.residencePermitValidToDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-5",
                            },
                        ],
                    },
                },
                {label: "Кем выдан", text: card.residencePermitIssuedBy},
            ],
            scans: [
                {
                    label: "Фотография разворота вида на жительство",
                    type: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.RESIDENCE_PERMIT),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.RESIDENCE_PERMIT,
            registrationStep: FULL_REGISTRATION_STEP.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
            title: "Вид на жительство лица без гражданства",
            labels: [
                {label: "Номер", text: card.statelessPersonResidencePermitNumber},
                {label: "Кем выдано", text: card.statelessPersonResidencePermitIssuedBy},
                {
                    row: {
                        labels: [
                            {
                                label: "Дата выдачи",
                                text: dateFormat(card.statelessPersonResidencePermitIssuedDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Дата окончания срока действия",
                                text: dateFormat(card.statelessPersonResidencePermitValidToDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-5",
                            },
                        ],
                    },
                },
            ],
            scans: [
                {
                    label: "Фотография разворота вида на жительство с персональной информацией",
                    type: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.PARENTS_CONSENT_TO_WORK_BY_MINOR,
            registrationStep: FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            title: "Согласие родителей на работу несовершеннолетних исполнителей",
            scans: [
                {
                    label: "Согласие на совершение сделок несовершеннолетними",
                    type: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
                },
                {
                    label: "Согласие на обработку персональных данных родителя",
                    type: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA,
                },
            ],
            isVisible: isVisibleParentDocumentScans,
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.COLLEGE,
            registrationStep: FULL_REGISTRATION_STEP.UNIVERSITY_LICENSE,
            title: "Высшее учебное заведение",
            scans: [
                {
                    block: {
                        title: "Лицензия ВУЗа",
                        scans: [
                            {
                                label: "Фотография лицевой стороны лицензии ВУЗа",
                                type: CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE,
                            },
                            {
                                label: "Фотография обратной стороны лицензии ВУЗа",
                                type: CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE,
                            },
                        ],
                    },
                },
                {
                    block: {
                        title: "Аккредитация ВУЗа",
                        scans: [
                            {
                                label: "Фотография лицевой стороны аккредитации",
                                type: CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE,
                            },
                            {
                                label: "Фотография обратной стороны аккредитации",
                                type: CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE,
                            },
                        ],
                    },
                },
                {
                    block: {
                        title: "Расписание занятий",
                        scans: [
                            {
                                label: "Фотография расписания занятий",
                                type: CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER,
                            },
                        ],
                    },
                },
                {
                    block: {
                        title: "Справка из ВУЗа",
                        scans: [
                            {
                                label: "Фотография справки из ВУЗа",
                                type: CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY,
                            },
                        ],
                    },
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.UNIVERSITY_LICENSE),
        },
        {
            className: "mt-4",
            segmentInfoType: CONTRACTOR_SEGMENT_INFO_TYPE.INSURANCE_POLICY,
            registrationStep: FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            title: "Страховой полис",
            labels: [
                {label: "Полис", text: vhiPolicyTypeText},
                {label: "Тип полиса", text: vhiPolicyFormatsDict[card.vhiPolicyFormat]},
                {
                    row: {
                        labels: [
                            {
                                label: "Дата начала действия",
                                text: dateFormat(card.vhiPolicyValidFromDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                            {
                                label: "Дата окончания действия",
                                text: dateFormat(card.vhiPolicyValidToDate, "dd.MM.yyyy"),
                                className: "col-16 col-md-4",
                            },
                        ],
                    },
                },
            ],
            scans: [
                card.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PAPER && {
                    label: `Фотография полиса ${vhiPolicyTypeText}`,
                    type: CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
                },
                card.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                    label: `Фотография лицевой стороны пластикового полиса ${vhiPolicyTypeText}`,
                    type: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
                },
                card.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                    label: `Фотография обратной стороны пластикового полиса ${vhiPolicyTypeText}`,
                    type: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
                },
            ],
            isVisible: steps.includes(FULL_REGISTRATION_STEP.VHI_POLICY_SCAN),
        },
    ].filter(({isVisible}) => isVisible));
};