import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import copy from "copy-to-clipboard";

import {ReactComponent as LinkIcon} from "../../images/link_24.svg";
import {ReactComponent as MailIcon} from "../../images/mail_24.svg";
import {ReactComponent as OkIcon} from "../../images/ok_24.svg";
import {ReactComponent as VkIcon} from "../../images/vk_24.svg";
import ContextMenuContent from "../ActualComponents/ContextMenu/Content";

import {OPEN_NAIMIX_CATALOG_ORDER} from "../../constants/links";

import {shortLinkSelector, shortUrl} from "../../ducks/urlShortener";

import "./style.sass";

const winParams = `width=655, height=430, top=${(window.screen.height - 430) / 2}, left=${(window.screen.width - 655) / 2}, resizable=yes, scrollbars=no, status=yes`;
const okWinParams = `width=572, height=480, top=${(window.screen.height - 548) / 2}, left=${(window.screen.width - 588) / 2}, resizable=yes, scrollbars=no, status=yes`;

class ShareSocial extends Component {
    $modal = React.createRef();

    constructor(props) {
        super(props);
        const {
            clientId,
            orderId,
        } = props;

        this.shareLink = OPEN_NAIMIX_CATALOG_ORDER
            .replace(":domain", process.env.REACT_APP_NAIMIX_OPEN_SITE_LINK)
            .replace(":clientId", clientId)
            .replace(":orderId", orderId);
    };

    detectOutClick = event => {
        if (this.$modal && this.$modal.current && !this.$modal.current.contains(event.target)) {
            const {toggle} = this.props;

            if (toggle) {
                toggle("");
            }
        }
    };

    componentDidMount() {
        document.addEventListener("click", this.detectOutClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.detectOutClick);
    }

    get socials() {
        const {t} = this.props;

        return [
            {
                key: "vk",
                text: t("shareSocial.vk"),
                icon: <VkIcon />,
                link: "https://vk.com/share.php?url=",
            },
            {
                key: "email",
                icon: <MailIcon />,
                text: t("shareSocial.email"),
                link: `mailto:?body=${this.shareLink}`,
            },
            {
                key: "ok",
                icon: <OkIcon />,
                text: t("shareSocial.ok"),
                link: "https://connect.ok.ru/offer?url=",

            },
            // {
            //     key: "fb",
            //     icon: <FacebookIcon />,
            //     text: t("shareSocial.fb"),
            //     link: "https://www.facebook.com/sharer.php?src=sp&u="
            // },
            {
                key: "copy",
                icon: <LinkIcon />,
                text: t("shareSocial.copy"),
            },
        ];
    }

    switchShare = ({key, link}) => {
        const {
            toggle,
            orderId,
            isOrderCard,
        } = this.props;

        toggle("");

        switch (key) {
            case "vk": {
                window.open(`${link}${this.shareLink}`,"sharer", winParams);

                return;
            }
            case "copy": {
                const {shortUrl} = this.props;

                shortUrl({
                    orderId: isOrderCard ? orderId : undefined,
                    originalUrl: this.shareLink,
                    callBack: copy,
                });

                return;
            }
            case "ok": {
                window.open(`${link}${this.shareLink}`,"sharer", okWinParams);

                return;
            }
            case "fb": {
                window.open(`${link}${this.shareLink}`,"sharer", winParams);

                return;
            }
            case "email": {
                window.open(link);

                return;
            }
        }
    };

    render() {
        return (
            <div
                ref={this.$modal}
                className="share-social"
            >
                <ContextMenuContent
                    inline
                    options={this.socials}
                    onClickItem={this.switchShare}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        shortenedUrl: shortLinkSelector(state),
    }), {
        shortUrl,
    },
)(withTranslation()(ShareSocial));