export const GET_STATEMENTS_PAGE_REQUEST = "GET_STATEMENTS_PAGE_REQUEST";
export const GET_STATEMENTS_PAGE_SUCCESS = "GET_STATEMENTS_PAGE_SUCCESS";

export const GET_STATEMENTS_INFO_COUNT_REQUEST = "GET_STATEMENTS_INFO_COUNT_REQUEST";
export const GET_STATEMENTS_INFO_COUNT_SUCCESS = "GET_STATEMENTS_INFO_COUNT_SUCCESS";
export const GET_STATEMENTS_INFO_COUNT_ERROR = "GET_STATEMENTS_INFO_COUNT_ERROR";

export const GET_STATEMENTS_TYPES_PAGE_REQUEST = "GET_STATEMENTS_TYPES_PAGE_REQUEST";
export const GET_STATEMENTS_TYPES_PAGE_SUCCESS = "GET_STATEMENTS_TYPES_PAGE_SUCCESS";

export const GET_STATEMENTS_ARCHIVE_REQUEST = "GET_STATEMENTS_ARCHIVE_REQUEST";

export const GET_STATEMENTS_SOURCE_FILE_REQUEST = "GET_STATEMENTS_SOURCE_FILE_REQUEST";

export const UPLOAD_EDO_STATEMENT_REQUEST = "UPLOAD_EDO_STATEMENT_REQUEST";

export const REPLACE_EDO_STATEMENT_REQUEST = "REPLACE_EDO_STATEMENT_REQUEST";

export const SEND_STATEMENT_VERIFICATION_CODE_REQUEST = "SEND_STATEMENT_VERIFICATION_CODE_REQUEST";

export const SIGN_STATEMENT_REQUEST = "SIGN_STATEMENT_REQUEST";

export const REJECT_STATEMENT_REQUEST = "REJECT_STATEMENT_REQUEST";

export const REVIEW_STATEMENT_REQUEST = "REVIEW_STATEMENT_REQUEST";

export const GET_EDO_COUNTS_BY_STAFF_ID_REQUEST = "GET_EDO_COUNTS_BY_STAFF_ID_REQUEST";
export const GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS = "GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS";

export const TASK_EXPORT_EDO_DOCUMENT_REQUEST = "TASK_EXPORT_EDO_DOCUMENT_REQUEST";
export const TASK_EXPORT_EDO_DOCUMENT_SUCCESS = "TASK_EXPORT_EDO_DOCUMENT_SUCCESS";

export const CHECK_STATEMENT_FILE_REQUEST = "CHECK_STATEMENT_FILE_REQUEST";

export const GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST = "GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST";

export const GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST = "GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST";

export const GET_EDO_EXPORT_LIST_REQUEST = "GET_EDO_EXPORT_LIST_REQUEST";
export const GET_EDO_EXPORT_LIST_SUCCESS = "GET_EDO_EXPORT_LIST_SUCCESS";
export const GET_EDO_EXPORT_LIST_ERROR = "GET_EDO_EXPORT_LIST_ERROR";

export const GET_EDO_EXPORT_STATUSES_REQUEST = "GET_EDO_EXPORT_STATUSES_REQUEST";
export const GET_EDO_EXPORT_STATUSES_SUCCESS = "GET_EDO_EXPORT_STATUSES_SUCCESS";

export const DOWNLOAD_EDO_EXPORT_FILES_REQUEST = "DOWNLOAD_EDO_EXPORT_FILES_REQUEST";
export const DOWNLOAD_EDO_EXPORT_FILES_SUCCESS = "DOWNLOAD_EDO_EXPORT_FILES_SUCCESS";
export const DOWNLOAD_EDO_EXPORT_FILES_ERROR = "DOWNLOAD_EDO_EXPORT_FILES_ERROR";