import React from "react";
import {useSelector} from "react-redux";

import DocumentList from "../document-list";

import {
    clientDocumentsRegistryListSelector,
    clientDocumentsRegistryProgressSelector,
    clientDocumentsRegistryTotalCountSelector,
    clientDocumentsRegistryTotalPagesSelector,
} from "../../../ducks/bff/clients/documents/registry/selectors";

const ClientDocumentList = (props) => {
    const list = useSelector(clientDocumentsRegistryListSelector);
    const totalCount = useSelector(clientDocumentsRegistryTotalCountSelector);
    const totalPages = useSelector(clientDocumentsRegistryTotalPagesSelector);
    const progress = useSelector(clientDocumentsRegistryProgressSelector);

    return (
        <DocumentList
            documentList={list}
            progressList={progress}
            totalCount={totalCount}
            totalPages={totalPages}
            {...props}
        />
    );
};

export default ClientDocumentList;