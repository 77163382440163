import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDivider from "../../../components/ActualComponents/NmDivider";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import Text from "../../../components/ActualComponents/Text";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import MassConfirm from "../../../components/MassConfirm";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import Task from "../../../components/NmTask";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import DepositCalculator from "../../deposit/deposit-calculator";
import ClientCardOrderHistoryPopup from "../client-card-order-history-popup";
import OrderEdit from "../order-edit";
import OrderListAmountCalculate from "./amount-calculate";
import OrderListMassChecking from "./mass-checking";
import OrderCreateSuccess from "./order-create-success";
import OrderListTable from "./order-list-table";
import OrdersFilter from "./orders-filter";
import OrderListWarningOrdersPublication from "./warning-orders-publication";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {isNMUsers} from "../../../utils/access";
import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {isChatSupported} from "../../../utils/firebaseHelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {handleFormString} from "../../../utils/stringHelper";
import {toastWarning} from "../../../utils/toastHelper";
import {getBffCommonController} from "../../../utils/url";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {ORDER_STATUS} from "../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {
    GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
    SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
} from "../../../constants/deposit";
import {CHAT_LINK_PARAMS, DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE} from "../../../constants/disputes";
import {EMPTY_OPTION_KEY} from "../../../constants/dropdown";
import {IS_CREATE_ORDER_OPEN} from "../../../constants/history";
import {
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_CLIENT_ADD_ORDERS_GROUP_PAGE,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_SETTINGS_INSTRUCTION,
} from "../../../constants/links";
import {ORDER} from "../../../constants/messages";
import {OBJECT_STATUS_DICT} from "../../../constants/objects";
import {
    ORDER_ACTION,
    ORDER_MASS_ACTION,
    ORDER_MASS_ACTION_TYPE,
    ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE,
    ORDER_SOURCE_TYPES,
    orderListDefaultFilter,
    VISIBLE_TO_CONTRACTOR_TRANSLATE,
} from "../../../constants/order";
import {PROJECT_STATUS_DICT} from "../../../constants/projects";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
    RNKO,
} from "../../../constants/roles";
import {orderClientAdminStatuses} from "../../../constants/status";
import {ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS} from "../order-edit/constants";

import {history} from "../../../store/configureStore";

import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector,
    clientCardPropertiesSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {getClientProjectCard} from "../../../ducks/bff/clients/projects/card/actionCreators";
import {
    deleteOrdersFromGroup,
    downloadOrderGroups,
    getOrderGroupById,
    uploadOrderList,
} from "../../../ducks/bff/orders/order-groups/card/actionCreators";
import {
    getOrderGroupCardProgressSelector,
    getOrderGroupCardSelector, getOrderGroupsCardActionProgressSelector,
} from "../../../ducks/bff/orders/order-groups/card/selectors";
import {
    archiveOrders,
    checkMultipleAction,
    closeOrders,
    completeOrder,
    deleteDraftOrder,
    deleteOrders,
    getPageOrders,
    updateOrder,
    updateOrderArchive,
} from "../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    ordersRegistryActionSelector,
    ordersRegistryCreatedOrderInfoSelector,
    ordersRegistryListSelector,
    ordersRegistryProgressSelector,
    ordersRegistryTotalCountSelector,
    ordersRegistryTotalPagesSelector,
} from "../../../ducks/bff/orders/orders-registry/selectors";
import {
    clientCurrentClientIdSelector,
    clientCurrentMemberIdSelector,
    clientCurrentMemberSelector,
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {downloadDocument} from "../../../ducks/documents";
import {saveFinanceLinkBack} from "../../../ducks/financeExport";
import {
    getAllTasks,
    jobTasksContractorStatusProcessingSelector,
    jobTasksOrderMassActionProcessingSelector,
    jobTasksOrdersImportClosingSelector,
} from "../../../ducks/job";
import {
    objectsCardSelector,
    objectsProgressUserCanNotCreateOrderByTemplateSelector,
} from "../../../ducks/objects";
import {
    getOrderCardOpenSelector,
    orderIsSearchSelector,
    orderListTaskIdsSelector,
    orderProgressActionSelector,
    setEditAvailableOrderStatus,
    toggleOrderCard,
    toggleOrdersArchived,
    updateFieldOrderStore,
} from "../../../ducks/order";
import {projectStatusSelector} from "../../../ducks/projects";

import PropTypes from "prop-types";

import "./style.sass";

class OrderList extends Component {
    static propTypes = {
        isOpenCard: PropTypes.bool,
    };

    static defaultProps = {
        isOpenCard: false,
    };

    constructor(props) {
        super(props);
        const {
            match: {
                params: {
                    clientId,
                    projectId,
                    objectId,
                    orderGroupId,
                },
            },
            objectLatitude,
            objectLongitude,
            addressId,
        } = props;

        this.defaultPageData = {
            pageNum: 1,
            pageSize: 25,
            isSearch: false,
        };

        this.projectId = projectId;
        this.objectId = objectId;
        this.clientId = clientId;
        this.orderGroupId = orderGroupId;
        this.role = ls(USER_ROLE);
        this.statusOptions = dictionaryToOptions(orderClientAdminStatuses);
        this.pageSizes = [25, 50, 100];

        this.isProjectForm = Boolean(this.projectId || this.objectId);
        this.isOrderGroupPage = Boolean(this.orderGroupId);

        this.isShowDepositValues = !this.projectId && !this.objectId && ![FOREMAN, RECRUITER].includes(this.role);

        this.state = {
            pageData: {
                ...this.defaultPageData,
            },
            addressId,
            objectLatitude,
            objectLongitude,
            amountCalculateData: {},
            isOpenOrderHistory: false,
            history: {},
            editOrder: {},
            currentUserId: "",
            archivedFilter: false,
            openConfirmWindow: false,
            isOrderArchive: false,
            confirmText: "",
            orderIdEdit: "",
            form: {
                orderSourceTypes: isNMUsers() ? [] : [ORDER_SOURCE_TYPES.TYPE_1, ORDER_SOURCE_TYPES.TYPE_2],
                statusFilter: "ALL",
                orderContractPaymentTypeFilter: "ALL",
                projectIdsFilter: this.projectId ? [this.projectId] : [],
                objectIdsFilter: this.objectId ? [this.objectId] : [],
                specialityIds: [],
                contractorRecruitmentRequiredFilter: this.isProjectForm ? undefined : EMPTY_OPTION_KEY,
                visibleToContractor: "all",
            },
            isShowDepositCalc: false,
            successCreate: false,
            actionType: "",
            isResetSelectedList: false,
            isOpenCreatingTypeModal: false,
            openFilter: false,
            listMassConfirmAllPossible: [],
            listDeleteFromGroup: [],
            isOpenImportGroupOrdersForm: false,
            isSecondTypeImportOrder: false,
            hideDetails: false,
        };

    }

    componentDidMount() {
        const {
            pageNum,
            pageSize,
            filterData,
            getClientCardProperties,
            getHideDepositDetails,
        } = this.props;

        getClientCardProperties({clientId: this.clientId});

        if (this.isOrderGroupPage) {
            this.fetchOrderGroupCard();
        }

        if (pageNum) {
            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    ...filterData,
                },
                pageData: {
                    pageNum,
                    pageSize,
                },
            }), () => {
                if (this.role !== FOREMAN) {
                    this.fetchList();
                }
            });

            return;
        }

        if (history.location.state?.action === IS_CREATE_ORDER_OPEN) {
            this.onOpenCreatingType();
            history.replace({...history.location, state: {}});
        }

        const {currentUserId} = this.props;

        if (this.role === FOREMAN && currentUserId) {
            this.setState({
                currentUserId,
            }, this.fetchList);
        }

        if (this.role !== FOREMAN) {
            this.fetchList();
        }

        if (!this.isOrderGroupPage && this.isAccessActions) {
            getHideDepositDetails({
                urls: GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.ORDERS,
                getResult: (hideDetails) => {
                    this.setState({
                        hideDetails,
                    });
                },
            });
        }
    }

    getProject() {
        const {getClientProjectCard} = this.props;

        if (this.projectId) {
            getClientProjectCard({
                clientId: this.clientId,
                projectId: this.projectId,
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldOrderStore} = this.props;

        updateFieldOrderStore("list", []);
    }

    componentDidUpdate(prevProps, state) {
        const {
            currentUserId,
            totalCount,
            importCloseTasks,
            progressUpdate,
            createdOrderInfo: {status},
            orders,
            location: {
                pathname,
            },
            objectLatitude,
            objectLongitude,
            addressId,
            currentMember,
        } = this.props;
        const {currentUserId: _currentUserId} = this.state;
        const {
            progressUpdate: _progressUpdate,
            orders: _orders,
            location: {
                pathname: oldPathname,
            },
        } = prevProps;

        // в карточке объекта при создании заказа надо сетить на форму addressId из модели объекта, чтобы отображался индикатор тарифной ставки
        if (addressId && addressId !== prevProps.addressId) {
            this.setState({
                addressId,
                objectLatitude,
                objectLongitude,
            });
        }

        if (pathname !== oldPathname) {
            this.isOrderGroupPage = pathname.includes("groups");

            this.fetchList();
        }

        if (!isEqual(orders, _orders)) {
            this.updateSelectedSum({});
        }

        if (
            !currentMember.contractorInvitationProhibited
            && !progressUpdate
            && progressUpdate !== _progressUpdate
            && Boolean(status) && ![ORDER_STATUS.DRAFT, ORDER_STATUS.PUBLISHED].includes(status)
        ) {
            this.setState({
                successCreate: true,
            });
        }

        if (this.role === FOREMAN && _currentUserId === "") {
            this.setState({
                currentUserId,
            }, this.fetchList);
        }

        if (totalCount !== prevProps.totalCount) {
            this.getProject();
        }

        if (isEmpty(importCloseTasks) && !isEmpty(prevProps.importCloseTasks)) {
            this.fetchList();
        }
    }

    get isAccessActions() {
        if (this.objectId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientObjects,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
            ]);
        }

        if (this.projectId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientProjects,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderList,
        ]);
    }

    fetchOrderGroupCard = () => {
        const {getOrderGroupById} = this.props;

        getOrderGroupById({
            clientId: this.clientId,
            orderGroupId: this.orderGroupId,
        });
    };

    fetchList = () => {
        const {
            pageData,
            archivedFilter,
            form,
        } = this.state;

        const {
            statusFilter,
            dateWorkEndFilter,
            dateWorkStartFilter,
            fromDateCreateFilter,
            toDateCreateFilter,
            projectIdsFilter = [],
            objectIdsFilter,
            visibleToContractor,
            orderContractPaymentTypeFilter,
            contractorRecruitmentRequiredFilter,
            orderManagerFilter,
            addressFiasIdsFilter,
        } = form;

        const {getPageOrders, currentUserId} = this.props;

        const statuses = [ORDER_STATUS.DRAFT, ORDER_STATUS.CLOSED, ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH];

        const requestData = {
            ...pageData,
            ...form,
            objectIdsFilter: objectIdsFilter && objectIdsFilter.length ? objectIdsFilter : undefined,
            objectIdFilter: this.objectId,
            projectIdsFilter: projectIdsFilter.length ? projectIdsFilter : undefined,
            dateWorkEndFilter: dateWorkEndFilter ? convertUtcToLocal(dateWorkEndFilter) : undefined,
            dateWorkStartFilter: dateWorkStartFilter ? convertUtcToLocal(dateWorkStartFilter) : undefined,
            fromDateCreateFilter: fromDateCreateFilter ? convertUtcToLocal(fromDateCreateFilter) : undefined,
            toDateCreateFilter: toDateCreateFilter ? convertUtcToLocal(toDateCreateFilter) : undefined,
            clientId: this.clientId,
            statusFilter: statusFilter === "ALL" ? statuses : [statusFilter],
            orderContractPaymentTypeFilter: orderContractPaymentTypeFilter === "ALL" ? undefined : orderContractPaymentTypeFilter,
            clientUserIdsFilter: this.role === FOREMAN && currentUserId ? [currentUserId] : undefined,
            visibleToContractor: VISIBLE_TO_CONTRACTOR_TRANSLATE[visibleToContractor],
            archivedFilter,
            addressFiasIdsFilter,
            contractorRecruitmentRequiredFilter: contractorRecruitmentRequiredFilter === EMPTY_OPTION_KEY
                ? undefined :
                contractorRecruitmentRequiredFilter,
            orderGroupFilter: this.isOrderGroupPage ? [this.orderGroupId] : undefined,
            orderManagerFilter: handleFormString(orderManagerFilter),
        };

        getPageOrders(requestData);
    };

    clearFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
            },
            form: orderListDefaultFilter({
                projectId: this.projectId,
                objectId: this.objectId,
                isOrdersFilter: !this.isProjectForm,
            }),
        }), () => {
            this.fetchList();
        });
    };

    handleChange = (event, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value,
            },
        }));
    };

    submitFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
                isSearch: true,
            },
        }), this.fetchList);
    };

    handleOnChangePageSize = pageSize => {
        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageSize,
                pageNum: 1,
            },
        }), this.fetchList);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageData: {pageNum: pageNumOld}} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            pageData: {
                ...prevState.pageData,
                pageNum,
            },
        }), this.fetchList);
    };

    openOrderHistory = (orderId, orderNum, name, clientId) => {
        this.setState({
            isOpenOrderHistory: true,
            historyClientId: clientId,
            history: {
                orderId,
                orderNum,
                name,
            },
        });
    };

    handleCloseDepositCalc = () => {
        this.setState({
            isShowDepositCalc: false,
        });
    };

    handleShowDepositCalc = () => {
        this.setState({
            isShowDepositCalc: true,
        });
    };

    closeOrderHistory = () => {
        this.setState({
            isOpenOrderHistory: false,
            historyClientId: "",
            history: {},
        });
    };

    deleteDraftOrder = () => {
        const {deleteDraftOrder, match} = this.props;
        const {params = {}} = match;
        const {projectId} = params;
        const {
            orderIdUpdate: orderId,
        } = this.state;

        deleteDraftOrder({
            orderId,
            clientId: this.clientId,
            projectId,
            onSuccess: this.fetchList,
        });

        this.handleCancelConfirm();
    };

    deleteOrdersFromGroup = () => {
        const {deleteOrdersFromGroup} = this.props;
        const {
            listDeleteFromGroup,
        } = this.state;

        deleteOrdersFromGroup({
            clientId: this.clientId,
            orderGroupId: this.orderGroupId,
            orderList: listDeleteFromGroup,
            onSuccess: this.fetchList,
        });

        this.handleCancelConfirm();
    };

    updateOrderArchive = () => {
        const {updateOrderArchive, match} = this.props;
        const {params = {}} = match;
        const {projectId} = params;
        const {
            orderIdUpdate: orderId,
            isOrderArchive,
        } = this.state;

        updateOrderArchive(
            {
                orderId,
                clientId: this.clientId,
                projectId,
                isArchive: isOrderArchive,
            },
        );

        this.handleCancelConfirm();
    };

    handleCancelConfirm = () => {
        this.setState({
            openConfirmWindow: false,
            confirmText: "",
            confirmTitle: "",
            warning: false,
            isOrderArchive: false,
            orderIdUpdate: "",
            onConfirm: undefined,
        });
    };

    handleOpenOrderEdit = (orderIdEdit = "", status) => {
        const {toggleOrderCard} = this.props;

        this.setState({
            isOpenOrderNew: true,
            orderIdEdit,
            status,
        }, () => toggleOrderCard(true));
    };

    showConfirmWindow = confirmData => {
        this.setState({
            openConfirmWindow: true,
            ...confirmData,
        });
    };

    fetchTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    handleCloseOrderNew = () => {
        const {toggleOrderCard} = this.props;

        toggleOrderCard(false);

        this.setState({
            addressId: undefined,
            objectLatitude: undefined,
            objectLongitude: undefined,
            isOpenOrderNew: false,
            orderIdEdit: "",
            status: "",
        });
    };

    updateOrder = actionType => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
            updateOrder,
            orders,
        } = this.props;
        const {orderIdUpdate} = this.state;

        const order = orders.find(item => item.orderId === orderIdUpdate);

        if (!order) {
            console.warn("Заявка не найдена!!!");
            return;
        }

        if (actionType === ORDER_ACTION.COMPLETE_SELECTION) {
            updateOrder({
                ...order,
                contractorsFound: true,
                status: ORDER_STATUS.PUBLISHED,
                successToast: ORDER.ORDER_UPDATE_SUCCESS_TEXT,
                onSuccess: ({jobId}) => {
                    if (!jobId) {
                        this.fetchList();

                        return;
                    }

                    getAllTasks({
                        clientId,
                        clientUserId,
                    });
                },
            });

            this.handleCancelConfirm();

            return;
        }

        this.closeOrder(order);
    };

    setEditAvailableStatus = ({clientId, orderId}) => {
        const {
            setEditAvailableOrderStatus,
        } = this.props;

        setEditAvailableOrderStatus({
            clientId,
            orderId,
            onSuccess: () => {
                this.handleCancelConfirm();
                this.handleOpenOrderEdit(orderId, ORDER_STATUS.DRAFT);
            },
        });
    };

    unLoad = (clientId, orderId, name) => {
        const {downloadDocument} = this.props;

        downloadDocument({
            downloadLink: `/bff${getBffCommonController()}/download-client-files/get-receipt-file?clientId=${clientId}&orderId=${orderId}`,
            documentType: `${name}_${formatDate(new Date())}`,
            extension: "xlsx",
        });
    };

    toggleArchived = () => {
        const {
            toggleOrdersArchived,
            isOrdersArchived,
        } = this.props;

        this.setState(prevState => ({
            ...prevState,
            archivedFilter: !prevState.archivedFilter,
            pageData: {
                ...prevState.pageData,
                pageNum: 1,
            },
        }), () => {
            this.fetchList();
            toggleOrdersArchived(!isOrdersArchived);
        });
    };

    onClickMassActionContextMenu = ({value: actionType}, list) => {
        if (actionType === ORDER_MASS_ACTION.DELETE_ORDERS_FROM_GROUP) {
            const listDeleteFromGroup = list.filter(value => value.isSelected).map(({orderId}) => orderId);

            const confirmData = {
                actionType,
                confirmText: "Вы действительно хотите удалить выбранные заказы из группы?",
            };

            this.setState({
                listDeleteFromGroup,
            }, () => this.showConfirmWindow(confirmData));

            return;
        }

        if ([ORDER_MASS_ACTION.COMPLETE_SELECTION, ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK].includes(actionType)) {
            const listMassConfirmAllPossible = list.filter(value => value.isSelected).map((item) => {
                const {
                    clientId,
                    orderId,
                    registryId,
                    orderNum,
                    name,
                    status,
                    orderContractPaymentType,
                } = item;

                return {
                    clientId,
                    orderId,
                    registryId,
                    status,
                    orderContractPaymentType,
                    actOfAcceptanceOfWorkTemplateId: item.actOfAcceptanceOfWorkTemplateId,
                    orderApplicationTemplateId: item.orderApplicationTemplateId,
                    frameContractTemplateId: item.frameContractTemplateId,
                    name: `№ ${orderNum} - ${name}`,
                    publishPossible: true,
                };
            });

            if ([ORDER_MASS_ACTION.PUBLISH_ORDERS_IN_WORK].includes(actionType)) {
                this.checkToActionOrders({
                    actionType: ORDER_MASS_ACTION_TYPE.CREATE,
                    orders: listMassConfirmAllPossible.map(({clientId, orderId, registryId}) => ({
                        clientId,
                        orderId,
                        registryId,
                    })),
                    listMassConfirmAllPossible,
                });

                return;
            }

            this.setState({
                actionType,
                listMassConfirmAllPossible,
                isOpenMassConfirm: true,
            });

            return;
        }

        const orders = list.filter(value => value.isSelected).map(({clientId, orderId, registryId}) => ({
            clientId,
            orderId,
            registryId,
        }));

        this.checkToActionOrders({
            actionType,
            orders,
        });
    };

    checkToActionOrders = (params) => {
        const {
            actionType,
            orders,
        } = params;
        const {
            checkMultipleAction,
        } = this.props;

        checkMultipleAction({
            actionType,
            orders,
            onSuccess: (result = []) => {
                const isAllOrdersNotCorrect = orders.length === result.filter(value => !value.ok).length;

                if (
                    [ORDER_MASS_ACTION.CLOSE_ORDERS, ORDER_MASS_ACTION.PUBLISH_ORDERS].includes(actionType) &&
                    isAllOrdersNotCorrect
                ) {
                    this.setState({
                        actionType,
                        isOpenNoCorrectOrders: true,
                    });

                    return;
                }

                this.setState({
                    actionType,
                    isOpenMassConfirm: true,
                });
            },
        });
    };

    onClickActionItem = (option, item) => {
        const {t} = this.props;
        const {value: action} = option;

        switch (action) {
            case ORDER_ACTION.SHOW_HISTORY: {
                this.openOrderHistory(item.orderId, item.orderNum, item.name, item.clientId);

                return;
            }
            case ORDER_ACTION.EDIT_ORDER: {
                if (item.status === ORDER_STATUS.CONTRACTOR_SEARCH) {
                    this.showConfirmWindow({
                        confirmText: "В момент редактирования, заказ будет переведен в статус \"Черновик\" с возможностью повторной публикации",
                        warning: true,
                        confirmButton: "Продолжить",
                        cancelButton: "Отменить",
                        onConfirm: () => this.setEditAvailableStatus(item),
                    });

                    return;
                }
                this.handleOpenOrderEdit(item.orderId, item.status);

                return;
            }
            case ORDER_ACTION.GET_CHECKS: {
                this.unLoad(item.clientId, item.orderId, item.name);

                return;
            }
            case ORDER_ACTION.CLOSE_ORDER: {
                const confirmData = {
                    confirmTitle: t("order-list.titleEndOrder", {nums: item.orderNum}),
                    confirmText: t("order-list.closeOneOrderMsg"),
                    orderIdUpdate: item.orderId,
                    warning: true,
                    actionType: ORDER_ACTION.CLOSE_ORDER,
                    jobId: `${item.clientId}:${item.orderId}`,
                };

                this.showConfirmWindow(confirmData);

                return;
            }
            case ORDER_ACTION.DELETE_DRAFT_ORDER:
                const confirmData = {
                    confirmText: t("order-list.deleteDraftOrder", {name: item.name}),
                    orderIdUpdate: item.orderId,
                    actionType: ORDER_ACTION.DELETE_DRAFT_ORDER,
                };

                this.showConfirmWindow(confirmData);

                return;
            case ORDER_ACTION.ARCHIVE_ORDER: {
                const confirmText = `${t("order-list.really-want")} ${item.archived ?
                    `${t("order-list.restore-order")} "${item.name}" ${t("order-list.from-archive")}?` :
                    `${t("order-list.add-order")} "${item.name}" ${t("order-list.to-archive")}?`}`;

                const confirmData = {
                    isOrderArchive: !item.archived,
                    confirmText,
                    orderIdUpdate: item.orderId,
                    actionType: ORDER_ACTION.ARCHIVE_ORDER,
                };

                this.showConfirmWindow(confirmData);

                return;
            }
            case ORDER_ACTION.COMPLETE_SELECTION: {
                const confirmData = {
                    actionType: ORDER_ACTION.COMPLETE_SELECTION,
                    confirmText: t("order-list.complete-selection-confirm-text"),
                    orderIdUpdate: item.orderId,
                };

                this.showConfirmWindow(confirmData);

                return;
            }
            case ORDER_ACTION.SHARE: {
                this.toggleShareModal(item.orderId, true);

                return;
            }
            case ORDER_ACTION.GO_DISPUTES: {
                this.goDisputes(item.orderId);

                return;
            }
            case ORDER_ACTION.GO_CHAT: {
                this.goChat(item.orderId);

                return;
            }
            default:
                console.error("Нет обработчика действия");
        }
    };

    toggleShareModal = (orderId) => {
        this.setState({
            shareModalOrderId: orderId,
        });
    };

    triggerIsResetSelectedList = () => {
        this.setState(prevState => ({
            ...prevState,
            isResetSelectedList: !prevState.isResetSelectedList,
        }
        ));
    };

    switchDropdownOperation = () => {
        const {actionType} = this.state;

        switch (actionType) {
            case ORDER_ACTION.CLOSE_ORDERS: {
                this.closeOrders();

                return;
            }
            case ORDER_ACTION.CLOSE_ORDER:
            case ORDER_ACTION.COMPLETE_SELECTION: {
                this.updateOrder(actionType);

                return;
            }
            case ORDER_ACTION.ARCHIVE_ORDER: {
                this.updateOrderArchive();

                return;
            }
            case ORDER_ACTION.DELETE_DRAFT_ORDER: {
                this.deleteDraftOrder();

                return;
            }
            case ORDER_MASS_ACTION.DELETE_ORDERS_FROM_GROUP: {
                this.deleteOrdersFromGroup();

                return;
            }
            default:
                return;
        }
    };

    closeOrder = ({clientId, orderId}) => {
        const {
            completeOrder,
            currentMember: {
                clientUserId,
            },
            getAllTasks,
        } = this.props;

        completeOrder({
            orderId,
            clientId,
            handleResponse: ({jobId}) => {
                if (!jobId) {
                    this.fetchList();

                    return;
                }

                getAllTasks({
                    clientId,
                    clientUserId,
                });
            },
        });

        this.handleCancelConfirm();
    };

    goDisputes = orderId => {
        this.saveBackLink();

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) {
            const {match: {params: {clientId}}} = this.props;

            history.push(
                LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST
                    .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, CHAT_LINK_PARAMS.OPEN)
                    .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, clientId)
                    .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                    .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
                    .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
                    .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "all"),
            );

            return;
        }

        history.push(
            LINK_DISPUTES_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, CHAT_LINK_PARAMS.OPEN)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "all"),
        );
    };

    goChat = orderId => {
        if (!isChatSupported()) {
            toastWarning(DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE);
        }

        this.saveBackLink();

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) {
            history.push(LINK_CHAT_LIST
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
                .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, "all")
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId)
                .replace(CHAT_LINK_PARAMS.LINK_CLIENTID_PARAM, this.clientId),
            );

            return;
        }

        history.push(LINK_ALL_ROLES_CHAT_LIST
            .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, "1")
            .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, "25")
            .replace(CHAT_LINK_PARAMS.LINK_CHATID_PARAM, "all")
            .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, orderId),
        );
    };

    saveBackLink = () => {
        const {saveFinanceLinkBack, pathname} = this.props;

        saveFinanceLinkBack(pathname);
    };

    onOpenFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            openFilter: !prevState.openFilter,
        }));
    };

    renderOrderHistory() {
        const {
            isOpenOrderHistory,
            historyClientId,
            history,
        } = this.state;

        return (
            isOpenOrderHistory &&
            <ClientCardOrderHistoryPopup
                clientId={historyClientId}
                orderInfo={history}
                handleClose={this.closeOrderHistory}
            />
        );
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            confirmText,
            warning,
            confirmTitle,
            confirmButton,
            cancelButton,
            onConfirm,
        } = this.state;
        const {
            progressAction,
            t,
        } = this.props;

        return openConfirmWindow &&
            <NmConfirmV2
                size="md"
                onCancel={this.handleCancelConfirm}
                onConfirm={() => {
                    if (onConfirm) {
                        onConfirm();
                    }

                    this.switchDropdownOperation();
                }}
                confirmButton={confirmButton || t("button.yes")}
                cancelButton={cancelButton || t("button.no")}
                content={confirmText}
                warning={warning}
                title={confirmTitle}
                disabled={progressAction}
            />;
    }

    renderOrderNewWindow = () => {
        const {
            orderIdEdit,
            status,
            addressId, // для сеттинга на форму из карточки объекта
            objectLatitude, // для сеттинга на форму из карточки объекта
            objectLongitude, // для сеттинга на форму из карточки объекта
        } = this.state;
        const {
            isOpenCard,
            objectCard: {
                name: objectName,
            },
        } = this.props;

        return isOpenCard ?
            <OrderEdit
                handleClose={this.handleCloseOrderNew}
                addressId={addressId}
                objectLatitude={objectLatitude}
                objectLongitude={objectLongitude}
                objectId={this.objectId}
                fetchList={this.fetchList}
                projectId={this.projectId}
                clientId={this.clientId}
                orderStatus={status}
                editOrderId={orderIdEdit}
                onOpenCreatingType={this.onOpenCreatingType}
                orderGroupId={this.isOrderGroupPage ? this.orderGroupId : undefined}
                initialValueObjectFilter={this.objectId ? objectName : ""}
            /> :
            null;
    };

    get clientArchived() {
        const {
            client: {
                archived,
            },
        } = this.props;

        return archived;
    }

    getMediaControls = (isShowControls) => {
        if (
            [RECRUITER].includes(this.role)
        ) {
            return null;
        }

        const {
            projectStatus,
            objectCard: {
                status: objectStatus,
            },
            hideDepositDetailsProgress,
            progressGetList,
            orderGroupActionProgress,
            progressUserCanNotCreateOrderByTemplate,
            clientPropertiesCard: {
                withoutContract,
            },
            totalCount,
            t,
        } = this.props;

        const {
            hideDetails,
            archivedFilter,
        } = this.state;

        if (this.isOrderGroupPage) {
            if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role)) {
                return null;
            }

            return {
                renderCount: {
                    mobile: 0,
                    tablet: 0,
                    desktop: 2,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            color: "green",
                            children: "Загрузить список",
                            onClick: () => this.setIsOpenImportForm(true),
                        },
                        visible: ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role)
                            && !withoutContract
                            && isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
                            ]),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            size: "xl",
                            color: "grey",
                            children: "Выгрузить",
                            onClick: this.exportOrderGroup,
                            disabled: orderGroupActionProgress,
                        },
                        visible: Boolean(totalCount)
                            && ![
                                NM_COORDINATOR,
                                NM_CHIEF_ACCOUNTANT,
                                NM_OPERATOR,
                            ].includes(this.role)
                            && isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
                            ]),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: "Создать заказ",
                            onClick: this.onOpenCreatingType,
                        },
                        visible: isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
                        ]),
                    },
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            children: "Добавить заказ",
                            onClick: () => {
                                history.push(LINK_CLIENT_ADD_ORDERS_GROUP_PAGE
                                    .replace(":clientId", this.clientId)
                                    .replace(":orderGroupId", this.orderGroupId),
                                );
                            },
                        },
                        visible: isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
                        ]),
                    },
                ],
            };
        }

        const archive = getArchiveButton(t, archivedFilter, {mobile: true});
        const hideDetailsAsset = {children: hideDetails ? "Показать детали" : "Скрыть детали"};

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 3,
            },
            buttons: [
                {
                    isAdditional: true,
                    component: COMPONENT.HORIZONTAL_TOGGLE,
                    props: {
                        disabled: hideDepositDetailsProgress,
                        noWrapLabel: true,
                        leftLabel: "Скрыть детали",
                        onChange: this.handleChangeHideDetails,
                        checked: hideDetails,
                        single: true,
                        duplex: false,
                    },
                    asset: {
                        mobile: hideDetailsAsset,
                        tablet: hideDetailsAsset,
                    },
                    visible: this.isShowDepositValues
                        && this.isAccessActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        children: "Создать заказ",
                        onClick: this.onOpenCreatingType,
                        disabled: progressUserCanNotCreateOrderByTemplate,
                    },
                    settings: {
                        exception: true,
                    },
                    visible: this.getIsShowCreateOrder(isShowControls)
                        && !this.clientArchived
                        && !withoutContract
                        && !(
                            [NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(this.role) ||
                            projectStatus === PROJECT_STATUS_DICT.CLOSE.VALUE ||
                            objectStatus === OBJECT_STATUS_DICT.CLOSE.VALUE
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Загрузить группу заказов",
                        onClick: this.openOrderGroupsPage,
                    },
                    visible: this.getIsShowCreateOrder(isShowControls)
                        && this.isAccessActions
                        && !withoutContract,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progressGetList,
                        onClick: this.toggleArchived,
                    },
                    visible: this.isAccessActions,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Калькулятор",
                        onClick: this.handleShowDepositCalc,
                    },
                    visible: isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderList,
                    ]),
                },
            ],
        };
    };

    renderDepositCalc() {
        const {isShowDepositCalc} = this.state;

        if (isShowDepositCalc) {
            return (
                <DepositCalculator
                    handleClose={this.handleCloseDepositCalc}
                />
            );
        }
        return null;
    }

    get classNames() {
        const classNames = ["orders"];

        if (this.isProjectForm) {
            classNames.push("orders_project-card-style");
        }

        if (this.role === FOREMAN && !this.isProjectForm) {
            classNames.push("orders_padded");
        }

        return classNames.join(" ");
    }


    handleCloseSuccessCreate = () => {
        this.setState({
            successCreate: false,
        });
    };

    renderSuccessCreate() {
        const {
            successCreate,
            pageData,
            form: filterData,
        } = this.state;

        return successCreate &&
            <OrderCreateSuccess
                pageData={pageData}
                filterData={filterData}
                clientId={this.clientId}
                handleClose={this.handleCloseSuccessCreate}
            />;
    }

    updateSelectedSum = (amountCalculateData) => {
        this.setState({
            amountCalculateData,
        });
    };

    onOpenCreatingType = () => {
        const {toggleOrderCard} = this.props;

        toggleOrderCard(true);
    };

    updateHideDepositDetails = () => {
        const {setHideDepositDetails} = this.props;
        const {hideDetails} = this.state;

        setHideDepositDetails({
            urls: SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.ORDERS,
            value: hideDetails,
        });
    };

    handleChangeHideDetails = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                hideDetails: !prevState.hideDetails,
            };
        }, this.updateHideDepositDetails);
    };

    renderContent() {
        const {
            orders,
            projectObjectHideDetails,
        } = this.props;
        const {
            pageData,
            form,
            archivedFilter,
            shareModalOrderId,
            isResetSelectedList,
            hideDetails,
        } = this.state;

        return (
            <OrderListTable
                archivedFilter={archivedFilter}
                updateSelectedSum={this.updateSelectedSum}
                triggerIsResetSelectedList={this.triggerIsResetSelectedList}
                isResetSelectedList={isResetSelectedList}
                orders={orders}
                clientId={this.clientId}
                projectId={this.projectId}
                role={this.role}
                pageData={pageData}
                form={form}
                goChat={this.goChat}
                goDisputes={this.goDisputes}
                onClickActionItem={this.onClickActionItem}
                onClickMassActionContextMenu={this.onClickMassActionContextMenu}
                shareModalOrderId={shareModalOrderId}
                toggleShareModal={this.toggleShareModal}
                handleOpenOrderEdit={this.handleOpenOrderEdit}
                onSelectedRows={this.onSelectedRows}
                isOrderGroupPage={this.isOrderGroupPage}
                hideDetails={hideDetails || projectObjectHideDetails}
                isAccessActions={this.isAccessActions}
            />
        );
    }

    onCancelMassConfirm = () => {
        this.setState({
            isOpenMassConfirm: false,
            actionType: "",
            listMassConfirmAllPossible: [],
            listDeleteFromGroup: [],
        });
    };

    onSuccessMassAction = () => {
        this.fetchTasks();
        this.triggerIsResetSelectedList();
        this.onCancelMassConfirm();
    };

    onCloseNoCorrectOrdersForClosing = () => {
        this.setState({
            actionType: "",
            isOpenNoCorrectOrders: false,
        });
    };

    renderNoCorrectOrdersForClosing() {
        const {t} = this.props;
        const {isOpenNoCorrectOrders, actionType} = this.state;

        return isOpenNoCorrectOrders &&
            <MassConfirm
                title={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].TITLE}
                text={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE[actionType].NO_CORRECT_ORDERS}
                confirmButton={t("button.ok")}
                cancelButton={t("button.cancel")}
                onCancel={this.onCloseNoCorrectOrdersForClosing}
                onConfirm={this.onCloseNoCorrectOrdersForClosing}
            />;
    }

    onCancelWarningPublication = () => {
        this.setState({
            actionType: "",
            isOpenWarningOrdersPublication: false,
        });
    };

    onSuccessWarningPublication = () => {
        this.fetchTasks();
        this.triggerIsResetSelectedList();
        this.onCancelWarningPublication();
    };

    renderWarningOrdersPublication() {
        const {isOpenWarningOrdersPublication, actionType} = this.state;

        if (!isOpenWarningOrdersPublication) {
            return null;
        }

        return (
            <OrderListWarningOrdersPublication
                actionType={actionType}
                clientId={this.clientId}
                onSuccess={this.onSuccessWarningPublication}
                onCancel={this.onCancelWarningPublication}
            />
        );
    }

    onWarningPublication = () => {
        this.setState({
            isOpenWarningOrdersPublication: true,
        });
    };

    renderMassConfirm() {
        const {
            isOpenMassConfirm,
            listMassConfirmAllPossible,
            actionType,
        } = this.state;

        if (!isOpenMassConfirm) {
            return null;
        }

        return (
            <OrderListMassChecking
                clientId={this.clientId}
                actionType={actionType}
                listMassConfirmAllPossible={listMassConfirmAllPossible}
                onWarningPublication={this.onWarningPublication}
                onSuccess={this.onSuccessMassAction}
                onCancel={this.onCancelMassConfirm}
            />
        );
    }

    toggleTypeImportOrders = (event, {checked}) => {
        this.setState(prevState => {
            return {
                ...prevState,
                isSecondTypeImportOrder: checked,
            };
        });
    };

    setIsOpenImportForm = (value) => {
        this.setState(prevState => {
            return {
                ...prevState,
                isOpenImportGroupOrdersForm: value,
                isSecondTypeImportOrder: false,
            };
        });
    };

    submitImportGroupOrdersForm = ({file}) => {
        const {uploadOrderList} = this.props;
        const {isSecondTypeImportOrder} = this.state;

        const formData = new FormData();
        formData.append("file", file);

        uploadOrderList({
            clientId: this.clientId,
            orderGroupId: this.orderGroupId,
            type: isSecondTypeImportOrder ? ORDER_SOURCE_TYPES.TYPE_2 : ORDER_SOURCE_TYPES.TYPE_1,
            formData,
            onSuccess: () => {
                this.fetchTasks();
                this.setIsOpenImportForm(false);
            },
        });
    };

    exportOrderGroup = () => {
        const {downloadOrderGroups} = this.props;

        downloadOrderGroups({
            clientId: this.clientId,
            orderGroupId: this.orderGroupId,
        });
    };

    openOrderGroupsPage = () => {
        history.push(LINK_CLIENT_ORDER_GROUPS_LIST.replace(":clientId", this.clientId));
    };

    renderOrderGroupInfo() {
        const {
            orderGroupCard: {
                orderGroupName,
                comment,
            },
        } = this.props;

        return (
            <div className="col-16 col-xxl-9 orders__group">
                <Text.Title
                    level="4"
                    className="orders__group-name"
                >
                    {orderGroupName}
                </Text.Title>
                <Text
                    level="3"
                    className="registry-card__group-description"
                >
                    {comment}
                </Text>
            </div>
        );
    }

    renderImportGroupOrdersForm = () => {
        const {orderGroupActionProgress} = this.props;
        const {
            isOpenImportGroupOrdersForm,
            isSecondTypeImportOrder,
        } = this.state;

        return (
            isOpenImportGroupOrdersForm &&
            <ImportFromFilePatternV2
                patternLink={
                    isSecondTypeImportOrder ?
                        "/files/Шаблон_Заказы по типу 2.xlsx" :
                        "/files/Шаблон_Заказы по типу 1.xlsx"
                }
                headerControls={
                    <NmHorizontalToggleV2
                        leftLabel="Тип заказа 1"
                        rightLabel="Тип заказа 2"
                        tooltipIconHeight={20}
                        tooltipIconWidth={20}
                        checked={isSecondTypeImportOrder}
                        onChange={this.toggleTypeImportOrders}
                        contentRows={ORDER_EDIT_TYPE_SUGGESTION_CONTENT_ROWS}
                    />
                }
                onSubmit={this.submitImportGroupOrdersForm}
                onClose={() => this.setIsOpenImportForm(false)}
                progress={orderGroupActionProgress}
            />
        );
    };

    getIsShowCreateOrder(flag = true) {
        const {
            client: {
                archived,
            },
            clientProperties: {
                withoutContract,
            },
            objectCard: {
                status,
            },
        } = this.props;

        if (this.isProjectForm && status === OBJECT_STATUS_DICT.CLOSE.VALUE) {
            return false;
        }

        if (archived || withoutContract) {
            return false;
        }

        if (
            [
                NM_CONSULTANT,
                NM_COORDINATOR,
                RNKO,
                NM_CHIEF_ACCOUNTANT,
                NM_OPERATOR,
                RECRUITER,
            ].includes(this.role)
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.createOrder,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderListAddEditPublish,
                this.projectId && CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
                this.objectId && CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
            ].filter(Boolean))
        ) {
            return false;
        }

        return flag;
    }

    renderEmptyPage() {
        const {
            isSearch,
            clientId,
            client: {
                archived,
            },
        } = this.props;

        const {
            archivedFilter,
        } = this.state;

        return (
            this.isOrderGroupPage ?
                <NmEmptyPageV2 /> :
                <NmEmptyPageV2
                    title={!archivedFilter ? "Создавайте, размещайте и управляйте вашими заказами на площадке Наймикс" : "Данные отсутствуют"}
                    description={!archivedFilter && "Для начала работы просто создайте свой первый заказ и сохраните его как черновик или опубликуйте его на нашей площадке для поиска возможных исполнителей"}
                    textAction={this.getIsShowCreateOrder() && "Создать заказ"}
                    isShowAction={typeof archived === "boolean" && !archived && !archivedFilter}
                    onClickAction={this.onOpenCreatingType}
                    textLink={this.getIsShowCreateOrder(!archivedFilter) && "Подробная инструкция"}
                    isSearch={isSearch}
                    patchLink={
                        clientId !== "00000000-0000-0000-0000-000000000000"
                            ? LINK_INSTRUCTION_CLIENT_ADMIN.replace(":clientId", clientId)
                            : LINK_SETTINGS_INSTRUCTION
                    }
                />
        );
    }

    render() {
        const {
            totalCount,
            t,
            totalPages,
            orders,
            progressGetList,
            isSearch,
            ordersFormattedToTaskIds,
            massActionTasks,
            orderGroupProgress,
            orderGroupCard: {
                orderGroupNumber,
            },
            clientProperties: {
                civilRegistryPaymentsAvailable,
            },
            projectObjectHideDetails,
        } = this.props;

        const {
            pageData,
            form,
            pageData: {
                pageSize,
                pageNum,
            },
            openFilter,
            amountCalculateData,
            hideDetails,
        } = this.state;

        const isShowControls = orders?.length || isSearch;

        return (
            <NmPage
                className={this.classNames}
                header={
                    <div className="orders__header">
                        <NmPageHeader
                            text={this.isOrderGroupPage && orderGroupNumber ? `Группа заказов №${orderGroupNumber}` : t("order-list.orders-title")}
                            totalCount={isShowControls && !this.isProjectForm && !this.isOrderGroupPage ? totalCount : undefined}
                            handleOnClickBack={this.isOrderGroupPage && this.openOrderGroupsPage}
                        />
                    </div>
                }
                subHeader={
                    isShowControls && !this.isOrderGroupPage &&
                    <>
                        {
                            this.isShowDepositValues &&
                            <>
                                <div className="mb-4 mb-md-5">
                                    <Text
                                        className="mb-2"
                                        medium
                                        level="2"
                                        color={COLOR.SECONDARY_70}
                                        noWrap
                                    >
                                        Детализация по счету для выплат исполнителям с типом налогообложения НПД
                                    </Text>
                                    <DepositValuesAmountInfo
                                        hideDetails={hideDetails}
                                        clientId={this.clientId}
                                        isOrdersPage
                                    />
                                </div>
                                {
                                    civilRegistryPaymentsAvailable &&
                                    <div className="mb-4 mb-md-5 mb-xxl-0">
                                        <Text
                                            className="mb-2"
                                            medium
                                            level="2"
                                            color={COLOR.SECONDARY_70}
                                            noWrap
                                        >
                                            Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                                        </Text>
                                        <DepositValuesAmountInfo
                                            hideDetails={hideDetails}
                                            clientId={this.clientId}
                                            isCivil
                                            isOrdersPage
                                        />
                                    </div>
                                }
                            </>
                        }
                        {
                            !hideDetails && !projectObjectHideDetails && ![RECRUITER].includes(this.role) &&
                            <>
                                {
                                    this.isShowDepositValues &&
                                    <NmDivider className="orders__divider" />
                                }
                                {
                                    isAccessByRestrictions([
                                        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderList,
                                        this.projectId && CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
                                    ].filter(Boolean)) &&
                                    <OrderListAmountCalculate
                                        className="orders__selected"
                                        data={amountCalculateData}
                                        role={this.role}
                                    />
                                }
                            </>
                        }
                    </>
                }
                mediaControls={this.getMediaControls(isShowControls)}
                classNameContentContainer="orders__content-container"
                openFilter={openFilter}
                onOpenFilter={this.onOpenFilter}
                typeFilter="vertical"
                filtersBase={
                    <div className="orders__sub-header">
                        <OrdersFilter
                            isProjectForm={this.isProjectForm}
                            clientId={this.clientId}
                            pageData={pageData}
                            clearFilter={this.clearFilter}
                            handleChange={this.handleChange}
                            submitFilter={this.submitFilter}
                            statusOptions={this.statusOptions}
                            searchData={form}
                        />
                    </div>
                }
                filterClassName="orders__filter"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleOnChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                isLoaded={progressGetList || orderGroupProgress}
            >
                <Task />
                <ByTaskUpdater
                    fetch={this.fetchList}
                    taskIds={massActionTasks}
                    dataIds={ordersFormattedToTaskIds}
                />
                {this.isOrderGroupPage && this.renderOrderGroupInfo()}
                {this.renderImportGroupOrdersForm()}
                {this.renderWarningOrdersPublication()}
                {this.renderNoCorrectOrdersForClosing()}
                {this.renderConfirmWindow()}
                {this.renderOrderHistory()}
                {this.renderDepositCalc()}
                {this.renderSuccessCreate()}
                {this.renderMassConfirm()}
                {this.renderOrderNewWindow()}
                {
                    !progressGetList && (
                        orders.length
                            ? this.renderContent()
                            : this.renderEmptyPage()
                    )
                }
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        location: state.router.location,
        pathname: state.router.location.pathname,
        orders: ordersRegistryListSelector(state),
        totalCount: ordersRegistryTotalCountSelector(state),
        totalPages: ordersRegistryTotalPagesSelector(state),
        progressGetList: ordersRegistryProgressSelector(state),
        currentUserId: clientCurrentMemberIdSelector(state),
        clientId: clientCurrentClientIdSelector(state),
        isOpenCard: getOrderCardOpenSelector(state),
        projectId: state.projects.project.projectId,
        isOrdersArchived: state.order.isOrdersArchived,
        createdOrderInfo: ordersRegistryCreatedOrderInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        client: clientCardInfoSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        projectStatus: projectStatusSelector(state),
        importCloseTasks: jobTasksOrdersImportClosingSelector(state),
        updateCompleteTasks: jobTasksContractorStatusProcessingSelector(state),
        progressUpdate: ordersRegistryActionSelector(state),
        objectCard: objectsCardSelector(state),
        clientPropertiesCard: clientCardPropertiesSelector(state),
        isSearch: orderIsSearchSelector(state),
        ordersFormattedToTaskIds: orderListTaskIdsSelector(state),
        massActionTasks: jobTasksOrderMassActionProcessingSelector(state),
        orderGroupCard: getOrderGroupCardSelector(state),
        orderGroupProgress: getOrderGroupCardProgressSelector(state),
        orderGroupActionProgress: getOrderGroupsCardActionProgressSelector(state),
        hideDepositDetailsProgress: hideDepositDetailsProgressSelector(state),
        progressUserCanNotCreateOrderByTemplate: objectsProgressUserCanNotCreateOrderByTemplateSelector(state),
        progressAction: orderProgressActionSelector(state),
    }),
    {
        getPageOrders,
        updateOrder,
        toggleOrderCard,
        completeOrder,
        saveFinanceLinkBack,
        updateOrderArchive,
        updateFieldOrderStore,
        downloadDocument,
        getClientProjectCard,
        toggleOrdersArchived,
        getClientCardProperties,
        archiveOrders,
        deleteOrders,
        closeOrders,
        getAllTasks,
        deleteDraftOrder,
        checkMultipleAction,
        getOrderGroupById,
        uploadOrderList,
        downloadOrderGroups,
        deleteOrdersFromGroup,
        getHideDepositDetails,
        setHideDepositDetails,
        setEditAvailableOrderStatus,

    },
)(withTranslation()(OrderList)));