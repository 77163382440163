import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import copy from "copy-to-clipboard";
import {isEmpty, isEqual} from "lodash";

import OrderPayPopup from "../../containers/order/order-pay-popup";
import OrderReasonCompletionModal from "../../containers/order/order-reason-completion-modal";
import PayoutHistory from "../../containers/payout-history";
import TimeSheet from "../../containers/time-sheet";
import {ReactComponent as ResetIcon} from "../../images/reset_24.svg";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import Tabs from "../ActualComponents/Tabs";
import Text from "../ActualComponents/Text";
import CheckboxList from "../CheckboxList";
import NmButton from "../NmButton";
import NmLoader from "../NmLoader";
import RejectionReasonPopup from "../RejectionReasonPopup";
import OrderContractorsCard from "./Card";

import {
    ls,
    USER_ROLE,
} from "../../utils/localstorage";
import {isAccessByRestrictions} from "../../utils/restrictions";
import {
    getFullName,
    phoneFormatWithoutSevenNumber,
} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";
import {toastSuccess, toastWarning} from "../../utils/toastHelper";

import {citizenshipsDict} from "../../constants/citizenships";
import {ORDER_STATUS} from "../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {COLOR} from "../../constants/color";
import {CLIENT_TYPE_CODE} from "../../constants/dicts";
import {ORDER_CARD_PERFORMERS_TABS} from "../../constants/order";
import {isUserFromNm} from "../../constants/roles";
import {COMPONENT} from "../ActualComponents/MediaControls/constants";

import {clientCardInfoSelector, getClientTypeSelector} from "../../ducks/bff/clients/info/selectors";
import {
    approveContractorByOrder,
    declineContractorByOrder,
    isFrameContractSigned,
    rejectContractorByOrder,
} from "../../ducks/bff/orders/order-contractors/actionCreators";
import {getOrderRichById} from "../../ducks/bff/orders/orders-registry/actionCreators";
import {ordersRegistryCardSelector} from "../../ducks/bff/orders/orders-registry/selectors";
import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {getCitizenshipSelector} from "../../ducks/contractor";
import {
    contractorPaymentPossibilityRefreshActionProgressSelector,
    contractorsPaymentPossibilityListSelector,
    updateFieldsContractorPaymentPossibilityState,
} from "../../ducks/contractorBankCheck";
import {
    approveOrderContractorAllInvitations,
    contractorStatusSelector,
    declineOrderContractorAllInvitations,
    getPageShortOrderContractors,
    orderProgressActionSelector,
    rejectOrderContractorAllInvitations,
    updateOrderContractorPin,
} from "../../ducks/order";
import {
    completeOrderContractor,
    refusalCompleteOrder,
} from "../../ducks/orderContractors";

import PropTypes from "prop-types";

import "./style.sass";

import {
    SUB_PAGE_ORDER_CONTRACTOR,
    TAB_TYPE_BY_SUB_PAGE,
} from "../../constants/link-params";

export const ACTION = {
    PAY: "pay",
    SHOW_HISTORY: "show-history",
    TIME_SHEET: "time-sheet",
    REJECT: "reject",
    APPROVE: "approve",
    DECLINE: "decline",
    END_ORDER: "end-order",
    APPROVE_END_ORDER: "approve-end-order",
    DECLINE_END_ORDER: "decline-end-order",
    // Для адаптива
    COPY: "copy",
    GO_CHAT: "goChat",
    ATTACH_TO_ORDER: "attachToOrder",
};

const CONFIRM_MESSAGE = {
    [ACTION.APPROVE_END_ORDER]: "Перед подтверждением завершения заказа не забудьте оплатить исполнителю!",
    [ACTION.DECLINE_END_ORDER]: "В случае отклонения будет открыт спор",
};

const getKey = ids => {
    const {clientId, orderId, contractorId} = ids;
    return `${clientId}:${orderId}:${contractorId}`;
};

export const ORDER_CONTRACTORS_SORT_TYPE = {
    CREATE_DATE_ASC: "CREATE_DATE_ASC",
    CREATE_DATE_DESC: "CREATE_DATE_DESC",
};

class OrderContractors extends Component {
    static propTypes = {
        baseUrl: PropTypes.string,
        subpage: PropTypes.string,
        orderId: PropTypes.string,
        clientId: PropTypes.string,
        fetchList: PropTypes.func,
        countMap: PropTypes.object,
        list: PropTypes.array,
        progress: PropTypes.bool,
        isShowOrderInfo: PropTypes.bool,
        isAccessActions: PropTypes.bool,
        isHideOrderContractorsActions: PropTypes.bool,
        totalCount: PropTypes.number,
    };

    static defaultProps = {
        isShowOrderInfo: false,
        isAccessActions: true,
    };

    state = {
        order: {},
        selectedAction: {
            actionType: ACTION.APPROVE,
        },
        isRefresh: false,
        openConfirmWindow: false,
        action: {},
        modalData: {},
        isOpenRejectionReason: false,
        isOpenTimeSheet: false,
        isOpenPayoutHistory: false,
        isOpenReasonCompletion: false,
        selectedList: [],
        countSelected: 0,
        notResidentsWithoutFrameContractList: [],
    };

    role = ls(USER_ROLE);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            list,
            getBankChecks,
            subpage,
        } = this.props;
        const {
            list: oldList,
            subpage: oldSubpage,
        } = prevProps;

        if (!isEmpty(list) && !isEqual(list, oldList)) {
            getBankChecks();
        }

        if (subpage !== oldSubpage) {
            this.clearSelectedRows();
        }
    }

    componentWillUnmount() {
        const {updateFieldsContractorPaymentPossibilityState} = this.props;

        updateFieldsContractorPaymentPossibilityState({
            contractorsPossibility: [],
        });
    }

    get showCheckBox() {
        const {subpage} = this.props;

        return (
            [
                SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK,
                SUB_PAGE_ORDER_CONTRACTOR.INVITED.LINK,
                SUB_PAGE_ORDER_CONTRACTOR.PRE_APPROVED.LINK,
                SUB_PAGE_ORDER_CONTRACTOR.SIGN_AGREEMENT.LINK,
            ].includes(subpage)
        );
    }

    get isAccessRefusalInviteAndSecureActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.refusalInviteAndSecurePositionOrderContactors,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_refusalInviteAndSecurePositionOrderContactors,
        ]);
    }

    get isAccessInvitationActions() {
        const {isAnalyticsPage} = this.props;

        if (isAnalyticsPage) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOrderAnalytics,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsInvitationOrder,
        ]);
    }

    get tabData() {
        const {
            subpage,
            countMap,
            baseUrl,
        } = this.props;

        const linkResponses = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
        const linkHired = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.HIRED.LINK);
        const linkInvited = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.INVITED.LINK);
        const linkPreApproved = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.PRE_APPROVED.LINK);
        const linkSignAgreement = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.SIGN_AGREEMENT.LINK);
        const linkRefusals = baseUrl.replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.REFUSALS.LINK);

        return [
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.TAB_TYPE],
                name: "Отклики",
                link: linkResponses,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK,
            },
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.INVITED.TAB_TYPE],
                name: "Приглашенные",
                link: linkInvited,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.INVITED.LINK,
            },
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.PRE_APPROVED.TAB_TYPE],
                name: "Предварительно одобренные",
                link: linkPreApproved,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.PRE_APPROVED.LINK,
            },
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.SIGN_AGREEMENT.TAB_TYPE],
                name: "Подписывают договор",
                link: linkSignAgreement,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.SIGN_AGREEMENT.LINK,
            },
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.HIRED.TAB_TYPE],
                name: "Нанятые",
                link: linkHired,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.HIRED.LINK,
            },
            {
                count: countMap[SUB_PAGE_ORDER_CONTRACTOR.REFUSALS.TAB_TYPE],
                name: "Отказы",
                link: linkRefusals,
                active: subpage === SUB_PAGE_ORDER_CONTRACTOR.REFUSALS.LINK,
            },
        ];
    }

    handleSelectedRows = (selectedList, isAllSelected) => {
        this.setState({
            isAllSelected,
            selectedList,
            countSelected: selectedList.filter(value => value.isSelected).length,
        });
    };

    clearSelectedRows = () => {
        this.setState({
            isAllSelected: false,
            selectedList: [],
            countSelected: 0,
        });
    };

    fetchList = () => {
        const {fetchList} = this.props;

        fetchList();
    };

    rejectInvitation = (contractorIds) => {
        const {
            rejectOrderContractorAllInvitations,
            clientId,
            orderId,
            subpage,
        } = this.props;

        rejectOrderContractorAllInvitations({
            clientId,
            orderId,
            tabType: TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE,
            contractorIds,
            onSuccess: () => {
                toastSuccess("Запущен процесс массового отзыва приглашений. Результат можно посмотреть в разделе \"Список задач\"");
                this.clearSelectedRows();
            },
        });
    };

    declineInvitation = (contractorIds) => {
        const {
            declineOrderContractorAllInvitations,
            clientId,
            orderId,
            subpage,
        } = this.props;

        declineOrderContractorAllInvitations({
            clientId,
            orderId,
            tabType: TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE,
            contractorIds,
            onSuccess: () => {
                toastSuccess("Запущена задача отказа исполнителям. Результат задачи можно посмотреть в разделе \"Список задач\"");
                this.clearSelectedRows();
            },
        });
    };

    approveInvitation = (contractorIds) => {
        const {
            approveOrderContractorAllInvitations,
            clientId,
            orderId,
            subpage,
        } = this.props;

        approveOrderContractorAllInvitations({
            clientId,
            orderId,
            tabType: TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE,
            contractorIds,
            onSuccess: () => {
                toastSuccess("Запущена задача одобрения откликов. Результат задачи можно посмотреть в разделе \"Список задач\"");
                this.clearSelectedRows();
            },
        });
    };

    getNotResidentsWithoutFrameContractList = (contractorIds) => {
        const {
            getPageShortOrderContractors,
            clientId,
            orderId,
        } = this.props;

        getPageShortOrderContractors({
            clientId,
            orderId,
            tabType: SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.TAB_TYPE,
            pageNum: 1,
            pageSize: 1000,
            sortType: ORDER_CONTRACTORS_SORT_TYPE.CREATE_DATE_ASC,
            resident: false,
            documentsCriteria: {
                signFrameContract: false,
            },
            contractorsCriteria: {
                contractorIds,
            },
            getResult: ({results}) => {
                if (results?.length) {
                    this.setState(prevState => ({
                        ...prevState,
                        confirmData: {
                            notResidentsWithoutFrameContractList: results,
                            onConfirm: () => this.approveInvitation(contractorIds),
                        },
                    }));

                    return;
                }

                this.approveInvitation(contractorIds);
            },
        });
    };

    handleCloseRejectionReason = () => {
        this.setState({
            isOpenRejectionReason: false,
            selectedAction: {},
        });
    };

    handleOpenRejectionReason = (selectedAction) => {
        this.setState({
            isOpenRejectionReason: true,
            selectedAction,
        });
    };

    handleCopyContact = value => {
        copy(value);
        toastSuccess("Номер телефона скопирован.");
    };

    blockRow = (keys) => {
        this.setState({rowKey: getKey(keys)});
    };

    handleSuccess = (successText) => {
        this.setState({
            successText,
        }, () => {
            setTimeout(() => {
                this.setState({
                    rowKey: "",
                    successText: "",
                }, this.fetchList);
            }, 3000);
        });
    };

    approveContractor = ids => {
        const {approveContractorByOrder} = this.props;
        this.blockRow(ids);

        approveContractorByOrder({
            ...ids,
            onError: () => {
                this.blockRow({});
            },
            onSuccess: () => {
                this.handleSuccess("Исполнитель одобрен");
            },
        });

        this.toggleConfirmWindow(false);
    };

    rejectContractor = ids => {
        const {
            rejectContractorByOrder,
            subpage,
        } = this.props;

        this.blockRow(ids);

        rejectContractorByOrder({
            ...ids,
            onError: () => {
                this.blockRow({});
            },
            onSuccess: () => {
                this.handleSuccess([
                    TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.SIGN_AGREEMENT].LINK,
                ].includes(subpage) ? "Подписание договора отозвано" : "Приглашение отозвано");
            },
        });

        this.toggleConfirmWindow(false);
    };

    declineContractor = (declineObj) => {
        const {
            selectedAction,
        } = this.state;

        this.blockRow(selectedAction);

        const {
            contractorId,
            orderId,
            clientId,
        } = selectedAction;

        const {
            declineContractorByOrder,
            subpage,
        } = this.props;

        declineContractorByOrder({
            contractorId,
            orderId,
            clientId,
            ...declineObj,
            subpage,
            onError: () => {
                this.blockRow({});
            },
            onSuccess: () => {
                this.handleSuccess("Исполнитель отклонен");
            },
        });

        this.handleCloseRejectionReason();
    };

    completeOrderContractor = () => {
        const {completeOrderContractor} = this.props;
        const {
            action,
        } = this.state;

        this.blockRow(action);

        const {
            contractorId,
            orderId,
            clientId,
        } = action;

        completeOrderContractor({
            contractorId,
            orderId,
            clientId,
            onError: () => {
                this.blockRow({});
            },
            onSuccess: () => {
                this.handleSuccess("Завершение работ для исполнителя подтверждено");
            },
        });

        this.toggleConfirmWindow(false);
    };

    actionSwitcher = (params) => {
        const {
            actionType,
            contractorId,
            fullName,
            lastName,
            firstName,
            patronymic,
            orderId,
            clientId,
            orderKind,
            orderCreatedByTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            orderContractPaymentType,
            chatId,
            phone,
            locatedOutsideRussia,
            pin,
        } = params;

        const {
            fetchList,
            subpage,
        } = this.props;

        switch (actionType) {
            case ACTION.ATTACH_TO_ORDER: {
                const {
                    updateOrderContractorPin,
                } = this.props;

                updateOrderContractorPin({
                    clientId,
                    orderId,
                    contractorId,
                    pin: !pin,
                    subpage,
                    onSuccess: () => {
                        fetchList();
                    },
                });

                return;
            }
            case ACTION.COPY: {
                this.handleCopyContact(phoneFormatWithoutSevenNumber(phone));

                return;
            }
            case ACTION.APPROVE: {
                const {
                    isFrameContractSigned,
                    clientType,
                    t,
                } = this.props;

                const onSuccessAfterCheckContract = (frameContractSigned) => {
                    const {citizenship} = params;

                    const confirmText = `Вы действительно хотите пригласить исполнителя ${fullName} на заказ?`;

                    const isShowWarning = citizenship !== citizenshipsDict.RU.value &&
                        clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY &&
                        !isNullOrWhitespace(citizenship) &&
                        !locatedOutsideRussia &&
                        !frameContractSigned.result;

                    const additionalAction = {};

                    if (isShowWarning) {
                        additionalAction.title = confirmText;
                        additionalAction.className = "order-contractors__migration-confirm";
                        additionalAction.classNameContent = "order-contractors__migration-confirm-content";
                        additionalAction.confirmBody = t("order-micro-job-seekers.migration-warning-msg");
                    }

                    const action = {
                        confirmText,
                        actionType,
                        onConfirm: () => {
                            this.approveContractor({contractorId, clientId, orderId});
                        },
                        contractorId,
                        warning: false,
                        ...additionalAction,
                    };

                    this.toggleConfirmWindow(true, action);
                };

                isFrameContractSigned({data: {contractorId, clientId}, onSuccess: onSuccessAfterCheckContract});

                break;
            }
            case ACTION.DECLINE: {
                const action = {
                    contractorId,
                    clientId,
                    orderId,
                    confirmText: `Вы действительно хотите отклонить заявку ${fullName}?`,
                };

                this.handleOpenRejectionReason(action);

                break;
            }
            case ACTION.REJECT: {
                const action = {
                    confirmText: "Вы действительно хотите отозвать заявку?",
                    actionType,
                    onConfirm: () => {
                        this.rejectContractor({contractorId, orderId, clientId});
                    },
                    warning: false,
                };

                this.toggleConfirmWindow(true, action);

                break;
            }
            case ACTION.SHOW_HISTORY: {
                this.openPayoutHistoryModal({contractorId, clientId, orderId, orderKind});

                break;
            }
            case ACTION.TIME_SHEET: {
                const {
                    contractorStatus,
                    orderStatus,
                    checkInRequired,
                    checkOutRequired,
                } = params;

                this.handleOpenTimeSheet({
                    contractorId,
                    contractorStatus,
                    orderStatus,
                    clientId,
                    orderId,
                    checkInRequired,
                    checkOutRequired,
                    contractorFullName: lastName
                        ? getFullName(lastName, firstName, patronymic)
                        : fullName,
                });

                break;
            }
            case ACTION.END_ORDER: {
                this.openReasonCompletionModal({contractorId, clientId, orderId});

                break;
            }
            case ACTION.APPROVE_END_ORDER: {
                const action = {
                    confirmText: CONFIRM_MESSAGE[ACTION.APPROVE_END_ORDER],
                    title: "Вы подтверждаете завершение заказа для исполнителя?",
                    onConfirm: this.completeOrderContractor,
                    contractorId,
                    orderId,
                    clientId,
                    warning: true,
                };

                this.toggleConfirmWindow(true, action);

                break;
            }
            case ACTION.DECLINE_END_ORDER: {
                const action = {
                    confirmText: CONFIRM_MESSAGE[ACTION.DECLINE_END_ORDER],
                    title: "Вы действительно хотите отклонить завершение заказа для исполнителя?",
                    onConfirm: () => {
                        this.refusalCompleteOrder({contractorId, orderId, clientId});
                    },
                    contractorId,
                    warning: true,
                };

                this.toggleConfirmWindow(true, action);

                break;
            }
            case ACTION.PAY: {
                const {orderWorkReportTemplateId} = params;

                this.openPayModal({
                    contractorId,
                    clientId,
                    orderId,
                    fullName,
                    orderKind,
                    orderCreatedByTemplateId,
                    actOfAcceptanceOfWorkTemplateId,
                    orderContractPaymentType,
                    orderWorkReportTemplateId,
                });

                break;
            }
            default: {
                break;
            }
        }
    };

    refusalCompleteOrder = ({contractorId, orderId, clientId}) => {
        const {refusalCompleteOrder} = this.props;

        this.blockRow({contractorId, clientId, orderId});

        refusalCompleteOrder({
            contractorId,
            clientId,
            orderId,
            onSuccess: () => {
                this.handleSuccess("Завершение работ для исполнителя отклонено. Открыт спор");
            },
        });

        this.toggleConfirmWindow(false);
    };

    toggleConfirmWindow = (openConfirmWindow, action = {}) => {
        this.setState(prevState => ({
            ...prevState,
            openConfirmWindow,
            action,
        }));
    };

    openPayoutHistoryModal = data => {
        this.setState(prevState => ({
            ...prevState,
            modalData: {...data},
            isOpenPayoutHistory: !prevState.isOpenPayoutHistory,
        }));
    };

    renderPayoutHistoryModal() {
        const {
            isOpenPayoutHistory,
            modalData: {
                contractorId,
                clientId,
                orderId,
                orderKind,
            },
        } = this.state;

        return isOpenPayoutHistory &&
            <PayoutHistory
                orderKind={orderKind}
                clientId={clientId}
                orderId={orderId}
                contractorId={contractorId}
                handleClose={this.openPayoutHistoryModal}
            />;
    }

    openReasonCompletionModal = data => {
        this.setState(prevState => ({
            ...prevState,
            modalData: {...data},
            isOpenReasonCompletion: !prevState.isOpenReasonCompletion,
        }));
    };

    renderReasonCompletionModal() {
        const {
            isOpenReasonCompletion,
            modalData: {
                contractorId,
                clientId,
                orderId,
            },
        } = this.state;

        return isOpenReasonCompletion &&
            <OrderReasonCompletionModal
                blockRow={this.blockRow}
                handleSuccess={this.handleSuccess}
                clientId={clientId}
                orderId={orderId}
                contractorId={contractorId}
                close={this.openReasonCompletionModal}
            />;
    }

    onSuccessOpenPayPopup = (clientId, orderId) => {
        const {
            pathname,
            getOrderRichById,
        } = this.props;

        // В списке всех откликов по заказам надо начитывать order, чтобы данные с заказа подгружались на форму,
        // также необходимо для корректной работы калькулятора
        const isNeedGetOrder = pathname.includes("/application");

        if (isNeedGetOrder) {
            getOrderRichById({
                orderId,
                clientId,
            });
        }
    };

    openPayModal = data => {
        const {
            clientId,
            orderId,
        } = data;

        this.setState({
            modalData: {...data},
            isOpenPay: true,
        }, () => {
            this.onSuccessOpenPayPopup(clientId, orderId);
        });
    };

    closePayModal = () => {
        this.setState({
            modalData: {},
            isOpenPay: false,
        });
    };

    renderPayModal() {
        const {isAnalyticsPage} = this.props;
        const {
            isOpenPay,
            modalData: {
                fullName,
                contractorId,
                orderId,
                orderKind,
                orderContractPaymentType,
                orderCreatedByTemplateId,
                actOfAcceptanceOfWorkTemplateId,
                orderWorkReportTemplateId,
                clientId,
            },
        } = this.state;

        return isOpenPay &&
            <OrderPayPopup
                orderId={orderId}
                contractorId={contractorId}
                clientId={clientId}
                orderKind={orderKind}
                orderWorkReportTemplateId={orderWorkReportTemplateId}
                orderContractPaymentType={orderContractPaymentType}
                orderCreatedByTemplateId={orderCreatedByTemplateId}
                actOfAcceptanceOfWorkTemplateId={actOfAcceptanceOfWorkTemplateId}
                fullName={fullName}
                handleClose={this.closePayModal}
                isAnalyticsPage={isAnalyticsPage}
            />;
    }

    getRows = () => {
        const {
            list,
            isShowOrderInfo,
            subpage,
            contractorStatusDict,
            currentMember,
            isVisibleResponsesActions,
            isVisibleCreateAct,
            contractorsPaymentPossibilityList,
            isAccessActions,
            isAnalyticsPage,
        } = this.props;
        const {
            rowKey,
            successText,
            selectedList,
        } = this.state;

        return list.map((item) => {
            const {
                contractorId,
                clientId,
                orderId,
                orderStatus,
            } = item;

            const {isSelected = false} = selectedList.find(contractor => (contractor.contractorId === contractorId)) || {};

            return {
                ...item,
                key: `${orderId}-${contractorId}`,
                isSelected,
                showCheckBox: this.showCheckBox,
                contentRow: (
                    <OrderContractorsCard
                        key={contractorId}
                        isVisibleResponsesActions={isVisibleResponsesActions}
                        isVisibleCreateAct={isVisibleCreateAct}
                        notification={rowKey === getKey({contractorId, clientId, orderId}) ? successText : null}
                        handleCopyContact={this.handleCopyContact}
                        actionSwitcher={this.actionSwitcher}
                        orderStatus={this.props.orderStatus || orderStatus}
                        orderSource={item.orderSource}
                        orderContractPaymentType={item.orderContractPaymentType}
                        role={this.role}
                        contractor={item}
                        contractorStatusDict={contractorStatusDict}
                        subpage={subpage}
                        isShowOrderInfo={isShowOrderInfo}
                        location={location}
                        contractorsPossibility={contractorsPaymentPossibilityList}
                        rowKey={rowKey}
                        currentMember={currentMember}
                        isAccessActions={isAccessActions}
                        isAccessRefusalInviteAndSecureActions={this.isAccessRefusalInviteAndSecureActions}
                        isAccessInvitationActions={this.isAccessInvitationActions}
                        isAnalyticsPage={isAnalyticsPage}
                    />
                ),
            };
        });
    };

    renderPanes() {
        return (
            <Tabs
                secondary
                className="flex-grow-1"
                panes={this.tabData}
            />
        );
    }

    handleOpenTimeSheet = data => {
        this.setState(prevState => ({
            ...prevState,
            isOpenTimeSheet: !prevState.isOpenTimeSheet,
            modalData: !prevState.isOpenTimeSheet ? {...data} : {},
        }));
    };

    renderTimeSheet() {
        const {orderId: urlOrderId} = this.props;
        const {
            isOpenTimeSheet,
            modalData: {
                contractorId,
                contractorFullName,
                contractorStatus,
                orderStatus,
                orderId,
                checkInRequired,
                checkOutRequired,
                clientId,
            },
        } = this.state;

        const isOpenFromOrderCard = Boolean(urlOrderId);
        const isOpenOrder = orderStatus !== ORDER_STATUS.CLOSED;

        return isOpenTimeSheet &&
            <TimeSheet
                needFetchContractors={isOpenFromOrderCard}
                contractorStatus={contractorStatus}
                checkOutRequired={checkOutRequired}
                checkInRequired={checkInRequired}
                orderId={orderId}
                clientId={clientId}
                contractorId={contractorId}
                contractorFullName={contractorFullName}
                isOpenOrder={isOpenOrder}
                onClose={this.handleOpenTimeSheet}
            />;
    }

    renderHeader() {
        const {
            children,
            refreshBankChecks,
            isAccessActions,
            isHideOrderContractorsActions,
        } = this.props;

        return (
            <div className="flex-column">
                <div className="order-contractors__header">
                    {this.renderConfirmWindow()}
                    {this.renderPanes()}
                    {
                        isAccessActions &&
                        !isHideOrderContractorsActions &&
                        <div className="order-contractors__actions">
                            <div>
                                <div className="order-contractors__refresh-container">
                                    <HelpTooltip
                                        position="bottom-right"
                                        info
                                    >
                                        Проверить возможность оплаты всех исполнителей
                                    </HelpTooltip>
                                    <NmButton
                                        onlyIcon
                                        className="order-contractors__refresh"
                                        color="grey"
                                        icon={<ResetIcon />}
                                        onClick={() => {
                                            refreshBankChecks();
                                        }}
                                    />
                                </div>
                            </div>
                            {children}
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            action: {
                confirmText,
                onConfirm,
                confirmBody,
                actionType,
            },
        } = this.state;

        const {t} = this.props;
        if (!openConfirmWindow) {
            return null;
        }

        const cancelButton = actionType !== ACTION.REJECT ? t("button.cancel") : t("button.no");
        const confirmButton = actionType !== ACTION.REJECT ? t("button.confirm") : t("button.yes");

        return (
            <NmConfirmV2
                title={confirmBody ? confirmText : null}
                content={confirmBody ? confirmBody : confirmText}
                onCancel={() => this.toggleConfirmWindow(false)}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />
        );
    }

    renderRejectReasonPopup() {
        const {isOpenRejectionReason} = this.state;

        return isOpenRejectionReason &&
            <RejectionReasonPopup
                submit={this.declineContractor}
                close={this.handleCloseRejectionReason}
            />;
    }

    getSortDateText = () => {
        const {subpage} = this.props;

        switch (TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE) {
            case TAB_TYPE_BY_SUB_PAGE.responses.TAB_TYPE:
            case TAB_TYPE_BY_SUB_PAGE["pre-approved"].TAB_TYPE:
                return "По дате отклика";
            case TAB_TYPE_BY_SUB_PAGE.invited.TAB_TYPE:
                return "По дате приглашения";
            default:
                return "";
        }
    };

    getSortOptions = () => {
        const {sortType} = this.props;

        return [
            {
                key: "date",
                value: "date",
                text: this.getSortDateText(),
                sortType: sortType,
                asc: ORDER_CONTRACTORS_SORT_TYPE.CREATE_DATE_ASC,
                desc: ORDER_CONTRACTORS_SORT_TYPE.CREATE_DATE_DESC,
                sortName: "datetime",
                isDefaultSort: true,
            },
        ];
    };

    getMassMediaControls = () => {
        const {
            subpage,
            progressAction,
            isAnalyticsPage,
        } = this.props;
        const {
            countSelected,
            selectedList,
        } = this.state;

        if (
            isAnalyticsPage
            || !this.isAccessRefusalInviteAndSecureActions
            || !this.isAccessInvitationActions
        ) {
            return null;
        }

        const contractorIds = selectedList.filter(item => item.isSelected).map(({contractorId}) => contractorId);

        return ({
            renderCount: {
                desktop: 3,
                tablet: 3,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
                    props: {
                        buttonProps: {
                            size: "lg",
                            color: "grey",
                            children: "Одобрить",
                            isIconPositionEnd: true,
                            isContentFullWidth: true,
                            disabled: progressAction,
                        },
                        children: "Одобрить",
                        options: [
                            {
                                key: "numbers",
                                value: "numbers",
                                text: "Все",
                                onClick: () => this.getNotResidentsWithoutFrameContractList(),
                            },
                            {
                                key: "filter",
                                value: "filter",
                                text: "Выбранные",
                                onClick: () => this.getNotResidentsWithoutFrameContractList(contractorIds),
                                disabled: !countSelected,
                            },
                        ],
                    },
                    visible: subpage === SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK,
                },
                {
                    component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
                    props: {
                        buttonProps: {
                            size: "lg",
                            color: "grey",
                            children: "Отказать",
                            isIconPositionEnd: true,
                            isContentFullWidth: true,
                            disabled: progressAction,
                        },
                        children: "Отказать",
                        options: [
                            {
                                key: "numbers",
                                value: "numbers",
                                text: "Всем",
                                onClick: () => this.declineInvitation(),
                            },
                            {
                                key: "filter",
                                value: "filter",
                                text: "Выбранным",
                                onClick: () => this.declineInvitation(contractorIds),
                                disabled: !countSelected,
                            },
                        ],
                    },
                    visible: [
                        SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK,
                        SUB_PAGE_ORDER_CONTRACTOR.PRE_APPROVED.LINK,
                    ].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON_WITH_CONTEXT_MENU,
                    props: {
                        buttonProps: {
                            size: "lg",
                            color: "grey",
                            children: "Отозвать приглашения",
                            isIconPositionEnd: true,
                            isContentFullWidth: true,
                            disabled: progressAction,
                        },
                        children: "Отозвать приглашения",
                        tooltip:
                            <HelpTooltip
                                info={true}
                                children="Приглашения будут отозваны для всех исполнителей, кроме закрепленных"
                                position="bottom-left"
                            />,
                        options: [
                            {
                                key: "numbers",
                                value: "numbers",
                                text: "Все",
                                onClick: () => this.rejectInvitation(),
                            },
                            {
                                key: "filter",
                                value: "filter",
                                text: "Выбранные",
                                onClick: () => this.rejectInvitation(contractorIds),
                                disabled: !countSelected,
                            },
                        ],
                    },
                    visible: [
                        SUB_PAGE_ORDER_CONTRACTOR.INVITED.LINK,
                        SUB_PAGE_ORDER_CONTRACTOR.SIGN_AGREEMENT.LINK,
                    ].includes(subpage),
                },
            ],
        });
    };

    renderNotResidentsWithoutFrameContractListConfirm = () => {
        const {confirmData} = this.state;
        const {
            notResidentsWithoutFrameContractList,
            onConfirm,
        } = confirmData || {};

        return (
            Boolean(notResidentsWithoutFrameContractList?.length) &&
            <NmConfirmV2
                title="Вы действительно хотите одобрить отклики следующих исполнителей на заказе?"
                titleAlign="left"
                contentAlign="left"
                content={
                    <div>
                        <Text
                            color={COLOR.SECONDARY_90}
                            level="4"
                        >
                            Обращаем внимание, что вы собираетесь заключить рамочный договор с иностранными гражданами
                            (лицами без гражданства).

                            В соответствии с законодательством РФ необходимо уведомить территориальное подразделение МВД
                            России по вопросам миграции о заключении договора с иностранным гражданином (лицом без
                            гражданства) в течение 3 рабочих дней с даты заключения такого договора.
                        </Text>
                        <ul style={{paddingLeft: 16}}>
                            {
                                notResidentsWithoutFrameContractList.map(item => {
                                    return (
                                        <li
                                            key={item.contractorId}
                                            className="mt-1"
                                        >
                                            <Text
                                                color={COLOR.SECONDARY_70}
                                                level="3"
                                            >
                                                {
                                                    item.contractorLastName
                                                        ? getFullName(item.contractorLastName, item.contractorFirstName, item.contractorPatronymic)
                                                        : item.contractorFullName
                                                }
                                            </Text>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                }
                onCancel={() => this.setState(prevState => ({
                    ...prevState,
                    confirmData: {},
                }))}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    renderContent() {
        const {
            progress,
            list,
            onClickSort,
            subpage,
            emptyDescription = "Дождитесь отклика исполнителей, приглашайте исполнителей на заказ и подписывайте с ними договор, чтобы увидеть информацию на вкладке",
            emptyTitle = "На заказе пока нет исполнителей с таким статусом",
            isSearch,
            sortElement,
            isVisibleDefaultContractorsSorts = true,
            isAnalyticsPage,
        } = this.props;
        const {countSelected} = this.state;

        return (
            <div className="order-contractors__container">
                <NmLoader
                    classNameDimmer="order-contractors__content-dimmer"
                    active={progress}
                    inverted
                />
                {
                    !isEmpty(list) ?
                        <>
                            {sortElement}
                            <CheckboxList
                                sort={
                                    isVisibleDefaultContractorsSorts &&
                                    [
                                        TAB_TYPE_BY_SUB_PAGE.responses.TAB_TYPE,
                                        TAB_TYPE_BY_SUB_PAGE.invited.TAB_TYPE,
                                        TAB_TYPE_BY_SUB_PAGE["pre-approved"].TAB_TYPE,
                                    ].includes(TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE)
                                }
                                sortOptions={this.getSortOptions()}
                                onClickSort={onClickSort}
                                rows={this.getRows()}
                                onSelectedRows={this.handleSelectedRows}
                                count={countSelected}
                                mediaControls={this.getMassMediaControls()}
                                withCheckbox={
                                    !isAnalyticsPage
                                    && this.isAccessRefusalInviteAndSecureActions
                                    && this.showCheckBox
                                }
                            />
                        </> :
                        <NmEmptyPageV2
                            isSearch={isSearch}
                            title={emptyTitle}
                            description={emptyDescription}
                        />
                }
            </div>
        );
    }

    render() {
        return (
            <div className="order-contractors">
                {this.renderTimeSheet()}
                {this.renderRejectReasonPopup()}
                {this.renderPayoutHistoryModal()}
                {this.renderReasonCompletionModal()}
                {this.renderPayModal()}
                {this.renderNotResidentsWithoutFrameContractListConfirm()}
                {this.renderHeader()}
                {this.renderContent()}
            </div>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        order: ordersRegistryCardSelector(state),
        contractorStatusDict: contractorStatusSelector(state),
        clientType: getClientTypeSelector(state),
        card: clientCardInfoSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        location: state.router.location,
        refreshActionProcess: contractorPaymentPossibilityRefreshActionProgressSelector(state),
        citizenshipDict: getCitizenshipSelector(state),
        contractorsPaymentPossibilityList: contractorsPaymentPossibilityListSelector(state),
        progressAction: orderProgressActionSelector(state),
    }),
    {
        isFrameContractSigned,
        rejectContractorByOrder,
        declineContractorByOrder,
        approveContractorByOrder,
        completeOrderContractor,
        refusalCompleteOrder,
        updateFieldsContractorPaymentPossibilityState,
        getOrderRichById,
        updateOrderContractorPin,
        rejectOrderContractorAllInvitations,
        declineOrderContractorAllInvitations,
        approveOrderContractorAllInvitations,
        getPageShortOrderContractors,

    },
)(withTranslation()(OrderContractors));
