import {FILTER} from "../index";

import {FilterGetValueParams} from "../types";

export const getFilterDefaultValue = (params: Omit<FilterGetValueParams, "form">) => {
    const {
        name,
        props,
    } = params;

    switch (name) {
        case FILTER.DATE_RANGE:
            return {
                [props.startFieldName]: null,
                [props.endFieldName]: null,
            };
        case FILTER.RANGE_INPUT:
            return {
                [props.nameStart]: "",
                [props.nameEnd]: "",
            };
        case FILTER.CHECKBOX:
            return {
                [props.name]: false,
            };
        default:
            return {
                [props.name]: props.multiple ? [] : "",
            };
    }
};