import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router-dom";

import Instructions from "../../../components/Instructions";
import {InstructionsNaimix} from "../../../components/Instructions/components/naimix";
import {InstructionsPage} from "../../../components/Instructions/components/page";

import {getUserRestrictions} from "../../../utils/access";
import {isVisibleClientWorkTypeTabs} from "../../../utils/clientWorkType";
import {isAccessByRestrictions} from "../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {USER_KEDO_INSTRUCTION_TYPE_DICT} from "../../../constants/dicts";
import {
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
} from "../../../constants/links";

import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {
    getClientsKedoInstructions,
} from "../../../ducks/bff/clients/instructions/kedo/actionCreators";
import {bffClientsInstructionsKedoSelector} from "../../../ducks/bff/clients/instructions/kedo/selectors";
import {downloadDocument} from "../../../ducks/documents";

export const ClientInstructions = (props) => {
    const clientId = props.match.params.clientId;

    const dispatch = useDispatch();

    const clientProperties = useSelector(clientCardPropertiesSelector);
    const instructionInfoGroup = useSelector(bffClientsInstructionsKedoSelector);

    const currentUserRestrictions = getUserRestrictions();

    useEffect(() => {
        dispatch(getClientCardProperties({clientId}));
    }, []);

    const tabs = [
        {
            name: "Наймикс",
            link: LINK_INSTRUCTION_CLIENT_ADMIN.replace(":clientId", clientId),
        },
        {
            name: "КЭДО",
            link: LINK_INSTRUCTION_KEDO_CLIENT_ADMIN.replace(":clientId", clientId),
            isVisible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessSettingInstructionsKedo,
            ]),
        },
    ];

    const fetchList = () => {
        dispatch(getClientsKedoInstructions());
    };

    const getFile = ({downloadLink, filename: fileName}) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
            fileName,
        }));
    };

    return (
        <Instructions
            tabs={tabs}
            isVisibleTabs={isVisibleClientWorkTypeTabs(clientProperties.clientWorkType)}
            pages={
                <Switch>
                    <Route
                        exact
                        path={LINK_INSTRUCTION_CLIENT_ADMIN}
                        component={InstructionsNaimix}
                    />
                    <Route
                        path={LINK_INSTRUCTION_KEDO_CLIENT_ADMIN}
                        render={() => {
                            return (
                                <InstructionsPage
                                    fetchList={fetchList}
                                    instructionInfoGroup={instructionInfoGroup}
                                    getFile={getFile}
                                    instructionTypeDict={USER_KEDO_INSTRUCTION_TYPE_DICT}
                                    isKedo={true}
                                />
                            );
                        }}
                    />
                </Switch>
            }
        />
    );
};