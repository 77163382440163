import {useState} from "react";
import {useDispatch} from "react-redux";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../../constants/roles";
import {
    ADVERTISEMENT_ACTION_TYPE,
    ADVERTISEMENT_ACTION_TYPE_OPTION,
    ADVERTISEMENT_STATUS,
} from "../constants";

import {getAdvertisement} from "../../../../ducks/bff/advertisement/actionCreators";

export function useAdvertisementActions(props) {
    const {
        deleteAdvertisement,
        clientUserId,
        pushNotificationPublicationProhibited,
        isAccessActionsMassiveAdvertisementClient,
    } = props;

    const [isShowEditModal, setShowEditModal] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [confirmData, setConfirmData] = useState({});

    const role = ls(USER_ROLE);
    const dispatch = useDispatch();

    const onCloseEditModal = () => {
        setDuplicate(false);
        setIsEdit(false);
        setShowEditModal(false);
    };

    const selectForEdit = ({advertId, clientId, duplicate}) => {
        dispatch(getAdvertisement({
            clientId,
            advertId,
            getResult: ({errorMessage}) => {
                if (errorMessage) {
                    return;
                }

                if (!duplicate) {
                    setIsEdit(true);
                }

                setShowEditModal(true);
            },
        }));
    };

    const onClickActionItem = ({value}, item) => {
        const {advertId, clientId} = item;

        switch (value) {
            case ADVERTISEMENT_ACTION_TYPE.EDIT.VALUE:
                selectForEdit({advertId, clientId});

                return;
            case ADVERTISEMENT_ACTION_TYPE.CREATE_DUPLICATE.VALUE:
                setDuplicate(true);
                selectForEdit({advertId, clientId, duplicate: true});

                return;
            case ADVERTISEMENT_ACTION_TYPE.CANCEL_PUBLISH.VALUE:
                setConfirmData({
                    content: "Вы действительно хотите отменить публикацию объявления?",
                    onConfirm: () => {
                        deleteAdvertisement({
                            requestData: [{clientId, advertId}],
                        });
                    },
                });

                return;
            case ADVERTISEMENT_ACTION_TYPE.DELETE.VALUE:
                deleteAdvertisement({
                    requestData: [{clientId, advertId}],
                });

                return;
            default:
                return;
        }
    };

    const getOptions = (item) => {
        const {status, managerId} = item;

        if (
            [
                NM_CHIEF_ACCOUNTANT,
                NM_COORDINATOR,
                NM_CONSULTANT,
                RECRUITER,
            ].includes(role)
            || (
                [FOREMAN].includes(role)
                && pushNotificationPublicationProhibited
            )
        ) {
            return [];
        }

        const edit = (
            ![PROJECT_MANAGER, OBJECT_MANAGER].includes(role) ||
            [PROJECT_MANAGER, OBJECT_MANAGER].includes(role) &&
            managerId === clientUserId
        ) &&
        status !== ADVERTISEMENT_STATUS.CLOSED.VALUE ? [ADVERTISEMENT_ACTION_TYPE_OPTION.EDIT] : [];

        const cancelPublish = [
            ADVERTISEMENT_STATUS.AWAIT_PUBLISH.VALUE,
            ADVERTISEMENT_STATUS.PUBLISHED.VALUE,
        ].includes(status) &&
            (
                ![PROJECT_MANAGER, OBJECT_MANAGER, NM_COORDINATOR].includes(role) ||
                [PROJECT_MANAGER, OBJECT_MANAGER].includes(role) &&
                managerId === clientUserId
            ) ?
            [ADVERTISEMENT_ACTION_TYPE_OPTION.CANCEL_PUBLISH] : [];

        const createDuplicate = [
            ADVERTISEMENT_STATUS.DRAFT.VALUE,
            ADVERTISEMENT_STATUS.AWAIT_PUBLISH.VALUE,
            ADVERTISEMENT_STATUS.PUBLISHED.VALUE,
            ADVERTISEMENT_STATUS.CLOSED.VALUE,
        ].includes(status) ?
            [ADVERTISEMENT_ACTION_TYPE_OPTION.CREATE_DUPLICATE] : [];

        const deleteAdv = [ADVERTISEMENT_STATUS.DRAFT.VALUE].includes(status)
            && (
                ![PROJECT_MANAGER, OBJECT_MANAGER].includes(role) ||
                [PROJECT_MANAGER, OBJECT_MANAGER].includes(role) &&
                managerId === clientUserId
            )
            && isAccessActionsMassiveAdvertisementClient
            ? [ADVERTISEMENT_ACTION_TYPE_OPTION.DELETE]
            : [];

        return [
            ...edit,
            ...cancelPublish,
            ...createDuplicate,
            ...deleteAdv,
        ];
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    return {
        isEdit,
        isShowEditModal,
        setShowEditModal,
        duplicate,
        onClickActionItem,
        role,
        onCloseEditModal,
        getOptions,
        confirmData,
        onCloseConfirm,
    };
}