import {STAFF_STATUS} from "../../constants/edmStaff";

import {EDO_ACCESS_RIGHTS} from "../../constants/document-management/document-list";

export const getEdmStaffListActionOptions = ({status, clientUserId}, accessList, currentClientUserId) => {
    const resultOptions = [];

    const options = {
        archiveStaff: {
            key: "archive-staff",
            text: "Архивировать",
            value: "archive-staff",
        },
        unarchiveStaff: {
            key: "unarchive-staff",
            text: "Вернуть из архива",
            value: "unarchive-staff",
        },
        editStaff: {
            key: "edit-staff",
            text: "Редактировать",
            value: "edit-staff",
        },
    };

    if (isAcceptedEditEdmStaff(accessList)) {
        resultOptions.push(options.editStaff);
    }

    if (isAcceptedArchiveEdmStaff(accessList) && status === STAFF_STATUS.WORKING && clientUserId !== currentClientUserId) {
        resultOptions.push(options.archiveStaff);
    }

    if (isAcceptedUnarchiveEdmStaff(accessList) && status === STAFF_STATUS.ARCHIVED && clientUserId !== currentClientUserId) {
        resultOptions.push(options.unarchiveStaff);
    }

    return resultOptions;
};

export const isAcceptedCreatingEdmStaff = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_ADD);
};

export const isAcceptedEditEdm = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_DOCUMENTS_ADD);
};

export const isAcceptedDictionaryManageEdm = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_DICTIONARY_MANAGE);
};

export const isAcceptedImportEdmStaff = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_IMPORT);
};

export const isAcceptedShowingEdmStaffCard = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_SHOW_DOCUMENTS_STATEMENTS) || accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_SHOW_PERSONAL_DATA);
};

export const isAcceptedEditEdmStaff = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_EDIT);
};

export const isAcceptedArchiveEdmStaff = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_ARCHIVE);
};

export const isAcceptedUnarchiveEdmStaff = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_RESTORE_ARCHIVE);
};

export const isAcceptedViewStaffDocuments = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_SHOW_DOCUMENTS_STATEMENTS);
};

export const isAcceptedViewStaffPersonalData = (accessList) => {
    return accessList.includes(EDO_ACCESS_RIGHTS.EDM_STAFF_SHOW_PERSONAL_DATA);
};