import {
    ADD_PATENTS_REGISTRY_ERROR,
    ADD_PATENTS_REGISTRY_REQUEST,
    ADD_PATENTS_REGISTRY_SUCCESS,
    ARCHIVE_PATENTS_REGISTRY_ERROR,
    ARCHIVE_PATENTS_REGISTRY_REQUEST,
    ARCHIVE_PATENTS_REGISTRY_SUCCESS,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS,
    CHECK_PATENT_REGISTRY_DUPLICATES_ERROR,
    CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST,
    CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS,
    CREATE_DUPLICATE_PATENT_REGISTRY_ERROR,
    CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST,
    CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS,
    DELETE_FAILED_PATENT_REGISTRY_ERROR,
    DELETE_FAILED_PATENT_REGISTRY_REQUEST,
    DELETE_FAILED_PATENT_REGISTRY_SUCCESS,
    GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS,
    GET_PATENT_REGISTRY_BY_ID_ERROR,
    GET_PATENT_REGISTRY_BY_ID_REQUEST,
    GET_PATENT_REGISTRY_BY_ID_SUCCESS,
    PATENTS_REGISTRY_LIST_ERROR,
    PATENTS_REGISTRY_LIST_REQUEST,
    PATENTS_REGISTRY_LIST_SUCCESS,
    PAY_PATENT_REGISTRY_BY_ID_ERROR,
    PAY_PATENT_REGISTRY_BY_ID_REQUEST,
    PAY_PATENT_REGISTRY_BY_ID_SUCCESS,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS,
    REGISTRY_PATENT_LOG_REQUEST,
    REGISTRY_PATENT_LOG_SUCCESS,
    RESTORE_PATENTS_REGISTRY_ERROR,
    RESTORE_PATENTS_REGISTRY_REQUEST,
    RESTORE_PATENTS_REGISTRY_SUCCESS,
    UPDATE_FIELD_PATENT_REGISTRY_STORE,
    UPDATE_PATENTS_REGISTRY_ERROR,
    UPDATE_PATENTS_REGISTRY_REQUEST,
    UPDATE_PATENTS_REGISTRY_SUCCESS,
} from "./actions";

const initial = {
    registries: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    registryStatusDict: {},
    isSuccessAction: false,
    paymentAbilityPatentContractor: {},
    log: {
        list: [],
        totalCount: 0,
        pageData: {
            pageSize: 25,
            pageNum: 1,
        },
    },
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case PATENTS_REGISTRY_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case GET_PATENT_REGISTRY_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case ADD_PATENTS_REGISTRY_REQUEST:
        case UPDATE_PATENTS_REGISTRY_REQUEST:
        case ARCHIVE_PATENTS_REGISTRY_REQUEST:
        case RESTORE_PATENTS_REGISTRY_REQUEST:
        case PAY_PATENT_REGISTRY_BY_ID_REQUEST:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST:
        case CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST:
        case CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST:
        case DELETE_FAILED_PATENT_REGISTRY_REQUEST:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case PATENTS_REGISTRY_LIST_SUCCESS:
            const {
                registries,
                totalCount,
            } = payload;

            return {
                ...state,
                registries,
                progressList: false,
                totalCount,
            };
        case GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS:
            return {
                ...state,
                paymentAbilityPatentContractor: payload,
            };
        case GET_PATENT_REGISTRY_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        case ADD_PATENTS_REGISTRY_SUCCESS:
        case UPDATE_PATENTS_REGISTRY_SUCCESS:
        case ARCHIVE_PATENTS_REGISTRY_SUCCESS:
        case RESTORE_PATENTS_REGISTRY_SUCCESS:
        case PAY_PATENT_REGISTRY_BY_ID_SUCCESS:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS:
        case CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS:
        case CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS:
        case DELETE_FAILED_PATENT_REGISTRY_SUCCESS:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
            };
        case PATENTS_REGISTRY_LIST_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        case GET_PATENT_REGISTRY_BY_ID_ERROR:
            return {
                ...state,
                error: payload,
                progressCard: false,
            };
        case ADD_PATENTS_REGISTRY_ERROR:
        case UPDATE_PATENTS_REGISTRY_ERROR:
        case ARCHIVE_PATENTS_REGISTRY_ERROR:
        case RESTORE_PATENTS_REGISTRY_ERROR:
        case PAY_PATENT_REGISTRY_BY_ID_ERROR:
        case PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR:
        case CHECK_PATENT_REGISTRY_DUPLICATES_ERROR:
        case CREATE_DUPLICATE_PATENT_REGISTRY_ERROR:
        case DELETE_FAILED_PATENT_REGISTRY_ERROR:
        case CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR:
        case REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR:
            return {
                ...state,
                error: payload,
                actionRegistryProgress: false,
            };
        case REGISTRY_PATENT_LOG_REQUEST:
            return {
                ...state,
                log: {
                    ...state.log,
                    pageData: {
                        pageSize: payload.pageSize,
                        pageNum: payload.pageNum,
                    },
                },
            };
        case REGISTRY_PATENT_LOG_SUCCESS:
            return {
                ...state,
                log: {
                    ...state.log,
                    list: payload.patentsLogEventModels,
                    totalCount: payload.totalCount,
                },
            };
        case UPDATE_FIELD_PATENT_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};