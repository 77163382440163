import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";

export const useOrderHistoryPage = () => {
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();
    const initFilter = {
        contractorFioFilter: "",
        contractorPhoneFilter: "",
        logStartDateFilter: null,
        logEndDateFilter: null,
        types: [],
    };
    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
    } = useFilter({
        initFilter,
        setPagination,
        pageSize,
    });

    return {
        filter,
        filterData,
        pageNum,
        pageSize,
        onSearch,
        onClear,
        onChangeFilter,
        onChangePageSize,
        onPaginationChange,
    };
};