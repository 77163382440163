import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmpty, isEqual} from "lodash";

import DropzoneV2 from "../../../ActualComponents/DropzoneV2";
import NmDatePicker from "../../../ActualComponents/NmDatePicker";
import NmForm from "../../../ActualComponents/NmForm";
import NmInputV2 from "../../../ActualComponents/NmInputV2";
import NmModal from "../../../ActualComponents/NmModal";
import ApplyButtons from "../../../ApplyButtons";
import NmTitle from "../../../NmTitle";

import {
    convertDateToDateWithoutTimeString,
    convertStringToDate,
    handleFormString,
} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {instructionRule, requiredMessage} from "../../../../constants/validationRules";

const InstructionEdit = (props) => {
    const {
        onClose,
        data,
        addFile,
        save,
        instructionTypeDict,
    } = props;
    const [form, setForm] = useState({});
    const [files, setFiles] = useState({});
    const [error, setError] = useState({});
    const {t} = useTranslation();

    useEffect(() => {
        if (!isEqual(form, data)) {
            setForm({
                ...data,
                date: convertStringToDate(data.date, false),
                fileName: data.filename,
            });

            if (data.filename) {
                setFiles([{name: data.filename}]);
            }
        }
    }, [data]);

    const onSave = () => {
        const {
            fileName,
            date,
            version,
        } = form;

        const info = {
            date: convertDateToDateWithoutTimeString(date),
            fileExists: true,
            filename: fileName,
            instructionType: data.instructionType,
            version: handleFormString(version),
        };

        if (!isEmpty(files) && files[0].path) {
            const [file] = files;
            const formData = new FormData();

            formData.append("file", file);

            addFile({
                instructionType: data.instructionType,
                file: formData,
                info,
                additionalParams: {
                    isRequestSaveInfo: true,
                    isRequestList: true,
                },
            });

            return;
        }

        save(info);
    };

    const onSubmit = () => {
        if (!isValidForm()) {
            return;
        }

        onSave();
        onClose();
    };

    const isValidForm = () => {
        const {
            version,
            file,
            date,
            fileName,
        } = form;

        const data = {
            version,
            file,
            date,
            fileName,
        };

        const errorFromRule = validate(data, instructionRule, "");
        const fileError = isEmpty(files) ? {file: requiredMessage} : {};
        const errors = {
            ...errorFromRule,
            ...fileError,
        };

        setError(errors);

        return Object.values(errors).length === 0;
    };

    const onChangeFile = (files) => {
        setFiles(files);
        setForm({
            ...form,
            fileName: !isEmpty(files) ? files[0].name : null,
        });
    };

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {instructionTypeDict[data.instructionType].TEXT}
                </NmTitle>
            }
            onClose={onClose}
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent={t("button.save")}
                    cancelBtnContent={t("button.cancel")}
                    onClose={onClose}
                    submit={onSubmit}
                />
            }
        >
            <NmForm addMargin>
                <NmInputV2
                    size="xl"
                    required
                    name="version"
                    onChange={onChange}
                    label={t("instruction.version")}
                    value={form.version || ""}
                    error={error.version}
                    maxLength={20}
                />
                <NmDatePicker
                    size="xl"
                    name="date"
                    dateFormatMask="99.99.9999"
                    isClearable
                    label={t("instruction.date")}
                    required
                    error={error.date}
                    selected={form.date}
                    strictParsing
                    onChange={onChange}
                />
                <DropzoneV2
                    accept={[".pdf"]}
                    format="*.PDF"
                    files={files}
                    onChange={onChangeFile}
                    maxSize={20}
                    error={error.file}
                />
            </NmForm>
        </NmModal>
    );
};

export default InstructionEdit;