import {
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR,
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST,
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_UPDATE_STORE,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS,
    BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS,
    BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS,
} from "./actions";

const initialState = {
    progressCardAction: false,
    card: {},
    progressCard: false,
    list: [],
    totalCount: 0,
    pageData: {},
    paymentCard: {},
    paymentProgressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST:
        case BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST:
        case BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST:
        case BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS:
        case BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR:
        case BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS:
        case BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR:
        case BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS:
        case BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR:
        case BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS:
        case BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST:
            return {
                ...state,
                pageData: payload,
                paymentProgress: true,
            };
        case BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS:
            const {
                registryPayments: list,
                totalCount,
            } = payload;

            return {
                ...state,
                list,
                totalCount,
                paymentProgress: false,
            };
        case BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR:
            return {
                ...state,
                error: payload,
                paymentProgress: false,
            };
        case BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST:
            return {
                ...state,
                paymentProgress: true,
            };
        case BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS:
            return {
                ...state,
                paymentCard: payload,
                paymentProgress: false,
            };
        case BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR:
            return {
                ...state,
                error: payload,
                paymentProgress: false,
            };
        case BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST:
        case BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST:
            return {
                ...state,
                paymentProgressAction: true,
            };
        case BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS:
        case BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR:
            return {
                ...state,
                paymentProgressAction: false,
            };
        case BFF_ACT_REGISTRIES_CARD_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};