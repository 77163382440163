import {
    ARCHIVE_ORDERS_ERROR,
    ARCHIVE_ORDERS_REQUEST,
    ARCHIVE_ORDERS_SUCCESS,
    CLEAR_ORDERS_STORE,
    CLOSE_ORDERS_ERROR,
    CLOSE_ORDERS_REQUEST,
    CLOSE_ORDERS_SUCCESS,
    DELETE_ORDERS_ERROR,
    DELETE_ORDERS_REQUEST,
    DELETE_ORDERS_SUCCESS,
    GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
    GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST,
    GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS,
    ORDER_CARD_RICH_ERROR,
    ORDER_CARD_RICH_REQUEST,
    ORDER_CARD_RICH_SUCCESS,
    ORDER_COMPLETE_ERROR,
    ORDER_COMPLETE_REQUEST,
    ORDER_COMPLETE_SUCCESS,
    ORDER_CONTRACTOR_HISTORY_ERROR,
    ORDER_CONTRACTOR_HISTORY_REQUEST,
    ORDER_CONTRACTOR_HISTORY_SUCCESS,
    ORDER_HISTORY_ERROR,
    ORDER_HISTORY_REQUEST,
    ORDER_HISTORY_SUCCESS,
    ORDER_LIST_FOR_INVITATION_ERROR,
    ORDER_LIST_FOR_INVITATION_REQUEST,
    ORDER_LIST_FOR_INVITATION_SUCCESS,
    ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR,
    ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS,
    ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS,
    ORDER_WORK_REPORT_PAID_SUM_ERROR,
    ORDER_WORK_REPORT_PAID_SUM_REQUEST,
    ORDER_WORK_REPORT_PAID_SUM_SUCCESS,
    ORDERS_ADD_ERROR,
    ORDERS_ADD_REQUEST,
    ORDERS_ADD_SUCCESS,
    ORDERS_GET_PAGE_ERROR,
    ORDERS_GET_PAGE_REQUEST,
    ORDERS_GET_PAGE_SUCCESS,
    ORDERS_UPDATE_ERROR,
    ORDERS_UPDATE_REQUEST,
    ORDERS_UPDATE_SUCCESS,
    PUBLISH_ORDERS_ERROR,
    PUBLISH_ORDERS_REQUEST,
    PUBLISH_ORDERS_SUCCESS,
    UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
    UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST,
    UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS,
    UPDATE_ORDERS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    createdOrderInfo: {},
    ordersNotByTemplateCreationAvailability: false,
    progressCheckWarningBeforePublish: false,
    progressOrdersMassAction: false,
    progressCard: false,
    card: {},
    dataPaidSum: {},
    paidSumList: [],
    paidSumProgress: false,
    updateContractorsFoundProgress: false,
    orderCreationData: {},
    warningOrderList: [],
    checkMultipleList: [],
    contractorHistoryPageData: {},
    contractorHistoryTotalCount: 0,
    contractorHistoryList: [],
    contractorHistoryProgress: false,
    historyPageData: {},
    historyTotalCount: 0,
    historyList: [],
    historyProgress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDERS_GET_PAGE_REQUEST:
        case ORDER_LIST_FOR_INVITATION_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case ORDERS_GET_PAGE_SUCCESS:
        case ORDER_LIST_FOR_INVITATION_SUCCESS:
            const {
                nameField = "list",
                nameFieldTotalCount = "totalCount",
                orders,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                orderTemplatesProgress: false,
                [nameField]: orders,
                [nameFieldTotalCount]: totalCount,
            };
        case ORDERS_GET_PAGE_ERROR:
        case ORDER_LIST_FOR_INVITATION_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ORDERS_ADD_REQUEST:
        case ORDERS_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
                createdOrderInfo: {},
            };
        case ORDERS_ADD_SUCCESS:
        case ORDERS_UPDATE_SUCCESS:
            const {
                guid,
                status,
                name,
                orderNum,
                specialityId,
            } = payload;

            return {
                ...state,
                createdOrderInfo: {
                    orderId: guid,
                    status,
                    name,
                    orderNum,
                    specialityId,
                },
                progressAction: false,
            };
        case ORDERS_ADD_ERROR:
        case ORDERS_UPDATE_ERROR:
        case ORDER_COMPLETE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case ORDER_COMPLETE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ORDER_COMPLETE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case ORDER_CONTRACTOR_HISTORY_REQUEST:
            return {
                ...state,
                contractorHistoryPageData: payload,
                contractorHistoryProgress: true,
            };
        case ORDER_CONTRACTOR_HISTORY_SUCCESS:
            return {
                ...state,
                contractorHistoryTotalCount: payload.totalCount,
                contractorHistoryList: payload.events,
                contractorHistoryProgress: false,
            };
        case ORDER_CONTRACTOR_HISTORY_ERROR:
            return {
                ...state,
                contractorHistoryProgress: false,
            };
        case ORDER_HISTORY_REQUEST:
            return {
                ...state,
                historyPageData: payload,
                historyProgress: true,
            };
        case ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                historyTotalCount: payload.totalCount,
                historyList: payload.results,
                historyProgress: false,
            };
        case ORDER_HISTORY_ERROR:
            return {
                ...state,
                historyProgress: false,
            };
        case ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS:
            return {
                ...state,
                ordersNotByTemplateCreationAvailability: payload.result,
            };
        case GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS:
        case GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR:
            return {
                ...state,
                progressCheckWarningBeforePublish: false,
            };
        case GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST:
            return {
                ...state,
                progressCheckWarningBeforePublish: true,
            };
        case CLOSE_ORDERS_REQUEST:
        case PUBLISH_ORDERS_REQUEST:
        case DELETE_ORDERS_REQUEST:
        case ARCHIVE_ORDERS_REQUEST:
            return {
                ...state,
                progressOrdersMassAction: true,
            };
        case CLOSE_ORDERS_SUCCESS:
        case PUBLISH_ORDERS_SUCCESS:
        case DELETE_ORDERS_SUCCESS:
        case ARCHIVE_ORDERS_SUCCESS:
            return {
                ...state,
                progressOrdersMassAction: false,
            };
        case CLOSE_ORDERS_ERROR:
        case PUBLISH_ORDERS_ERROR:
        case DELETE_ORDERS_ERROR:
        case ARCHIVE_ORDERS_ERROR:
            return {
                ...state,
                progressOrdersMassAction: false,
                error: payload,
            };
        case UPDATE_ORDERS_STORE:
            return {
                ...state,
                ...payload,
            };
        case ORDER_CARD_RICH_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case ORDER_CARD_RICH_SUCCESS: {
            const {
                result,
                nameField = "card",
            } = payload;
            return {
                ...state,
                [nameField]: result,
                progressCard: false,
            };
        }
        case ORDER_CARD_RICH_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case ORDER_WORK_REPORT_PAID_SUM_REQUEST:
            return {
                ...state,
                dataPaidSum: payload,
            };
        case ORDER_WORK_REPORT_PAID_SUM_SUCCESS:
            return {
                ...state,
                paidSumList: payload,
            };
        case ORDER_WORK_REPORT_PAID_SUM_ERROR:
            return {
                ...state,
                error: payload,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST:
            return {
                ...state,
                updateContractorsFoundProgress: true,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS:
            return {
                ...state,
                updateContractorsFoundProgress: false,
            };
        case UPDATE_ORDER_CONTRACTORS_FOUND_ERROR:
            return {
                ...state,
                updateContractorsFoundProgress: false,
                error: payload,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR:
            return {
                ...state,
                progressWarningOrders: false,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST:
            return {
                ...state,
                progressWarningOrders: true,
            };
        case ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS: {
            const {orders} = payload;

            return {
                ...state,
                progressWarningOrders: false,
                warningOrderList: orders,
            };
        }
        case ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS:
            return {
                ...state,
                checkMultipleList: payload,
                progressCheckMultiple: false,
            };
        case ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR:
            return {
                ...state,
                progressCheckMultiple: false,
            };
        case ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST:
            return {
                ...state,
                progressCheckMultiple: true,
            };
        case CLEAR_ORDERS_STORE:
            return initial;
        default:
            return state;
    }
};