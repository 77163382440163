export const EDM_SUBDIVISION_LIST_REQUEST = "EDM_SUBDIVISION_LIST_REQUEST";
export const EDM_SUBDIVISION_LIST_SUCCESS = "EDM_SUBDIVISION_LIST_SUCCESS";
export const EDM_SUBDIVISION_LIST_ERROR = "EDM_SUBDIVISION_LIST_ERROR";

export const EDM_SUBDIVISION_UPDATE_REQUEST = "EDM_SUBDIVISION_UPDATE_REQUEST";
export const EDM_SUBDIVISION_UPDATE_SUCCESS = "EDM_SUBDIVISION_UPDATE_SUCCESS";
export const EDM_SUBDIVISION_UPDATE_ERROR = "EDM_SUBDIVISION_UPDATE_ERROR";

export const EDM_SUBDIVISION_DELETE_REQUEST = "EDM_SUBDIVISION_DELETE_REQUEST";
export const EDM_SUBDIVISION_DELETE_SUCCESS = "EDM_SUBDIVISION_DELETE_SUCCESS";
export const EDM_SUBDIVISION_DELETE_ERROR = "EDM_SUBDIVISION_DELETE_ERROR";

export const EDM_SUBDIVISION_ADD_REQUEST = "EDM_SUBDIVISION_ADD_REQUEST";
export const EDM_SUBDIVISION_ADD_SUCCESS = "EDM_SUBDIVISION_ADD_SUCCESS";
export const EDM_SUBDIVISION_ADD_ERROR = "EDM_SUBDIVISION_ADD_ERROR";

export const EDM_SUBDIVISION_COMMON_COUNT_REQUEST = "EDM_SUBDIVISION_COMMON_COUNT_REQUEST";
export const EDM_SUBDIVISION_COMMON_COUNT_SUCCESS = "EDM_SUBDIVISION_COMMON_COUNT_SUCCESS";
export const EDM_SUBDIVISION_COMMON_COUNT_ERROR = "EDM_SUBDIVISION_COMMON_COUNT_ERROR";