import {
    ADD_PATENTS_REGISTRY_REQUEST,
    ARCHIVE_PATENTS_REGISTRY_REQUEST,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST,
    CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST,
    CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST,
    DELETE_FAILED_PATENT_REGISTRY_REQUEST,
    GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST,
    GET_PATENT_REGISTRY_BY_ID_REQUEST,
    PATENTS_REGISTRY_LIST_REQUEST,
    PAY_PATENT_REGISTRY_BY_ID_REQUEST,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST,
    REGISTRY_PATENT_LOG_REQUEST,
    RESTORE_PATENTS_REGISTRY_REQUEST,
    UPDATE_FIELD_PATENT_REGISTRY_STORE,
    UPDATE_PATENTS_REGISTRY_REQUEST,
} from "./actions";

export function getPatentsRegistries(payload) {
    return {
        type: PATENTS_REGISTRY_LIST_REQUEST,
        payload,
    };
}

export function addPatentsRegistry(payload) {
    return {
        type: ADD_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function updatePatentsRegistry(payload) {
    return {
        type: UPDATE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function archivePatentsRegistry(payload) {
    return {
        type: ARCHIVE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function restorePatentsRegistry(payload) {
    return {
        type: RESTORE_PATENTS_REGISTRY_REQUEST,
        payload,
    };
}

export function getPatentRegistryById(payload) {
    return {
        type: GET_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function payPatentRegistry(payload) {
    return {
        type: PAY_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function payUnpaidPatentRegistry(payload) {
    return {
        type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST,
        payload,
    };
}

export function checkPatentRegistryPaymentsDuplicates(payload) {
    return {
        type: CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST,
        payload,
    };
}

export function createDuplicatePatentRegistry(payload) {
    return {
        type: CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST,
        payload,
    };
}

export function deleteFailedPatentRegistryPayments(payload) {
    return {
        type: DELETE_FAILED_PATENT_REGISTRY_REQUEST,
        payload,
    };
}

export function getContractorsPaymentAbilityPatent(payload) {
    return {
        type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST,
        payload,
    };
}

export function checkPatentRegistryContractorPresentPayments(payload) {
    return {
        type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST,
        payload,
    };
}

export function updateFieldPatentRegistryStore(payload) {
    return {
        type: UPDATE_FIELD_PATENT_REGISTRY_STORE,
        payload,
    };
}

export function refreshPatentRegistryPaymentsStatus(payload) {
    return {
        type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST,
        payload,
    };
}

export function getRegistryPatentsLog(payload) {
    return {
        type: REGISTRY_PATENT_LOG_REQUEST,
        payload,
    };
}