import {
    CLEAR_SETTINGS_CLIENT_GROUPS_CARD_SEARCH_CONTRACTORS_STORE,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_REQUEST,
} from "./actions";

export function getPageSettingsClientGroupsCardSearchContractors(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsClientGroupsCardSearchContractors(payload) {
    return {
        type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_REQUEST,
        payload,
    };
}

export const clearStoreSettingsClientGroupsSearchContractors = () => {
    return {
        type: CLEAR_SETTINGS_CLIENT_GROUPS_CARD_SEARCH_CONTRACTORS_STORE,
    };
};