import {getUserRole} from "../../../../utils/access";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

export const getSettingsDirectoriesAccessActions = ({roles, restriction}) => {
    const role = getUserRole();

    if (restriction) {
        return (
            roles.includes(role)
            && isAccessByRestrictions([
                restriction,
            ])
        );
    }

    return (
        roles.includes(role)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingDirectory,
        ])
    );
};