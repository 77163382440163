import {all, call, put, select, takeEvery} from "@redux-saga/core/effects";

import {DEPOSIT_SUB_PAGE} from "../../../../../../containers/deposit/client-list";
import {getUserAvatarListThumbnails} from "../../../../../fileStore";
import {getPatentsPayments, getPatentsPaymentsAdminPage} from "./actionCreators";
import {
    ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR,
    ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST,
    ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS,
    ADD_PATENTS_PAYMENT_ERROR,
    ADD_PATENTS_PAYMENT_FROM_FILE_ERROR,
    ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST,
    ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS,
    ADD_PATENTS_PAYMENT_REQUEST,
    ADD_PATENTS_PAYMENT_SUCCESS,
    DECLINE_PATENT_PAYMENT_ERROR,
    DECLINE_PATENT_PAYMENT_REQUEST,
    DECLINE_PATENT_PAYMENT_SUCCESS,
    DELETE_PATENTS_PAYMENT_ERROR,
    DELETE_PATENTS_PAYMENT_REQUEST,
    DELETE_PATENTS_PAYMENT_SUCCESS,
    GET_PATENTS_PAYMENT_STATUS_DICT_ERROR,
    GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST,
    GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS,
    PATENTS_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
    PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
    PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
    PATENTS_PAYMENTS_ADMIN_PAGE_ERROR,
    PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST,
    PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS,
    PATENTS_PAYMENTS_LIST_ERROR,
    PATENTS_PAYMENTS_LIST_REQUEST,
    PATENTS_PAYMENTS_LIST_SUCCESS,
    PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR,
    PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST,
    PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS,
    REFRESH_PATENT_PAYMENT_STATUS_ERROR,
    REFRESH_PATENT_PAYMENT_STATUS_REQUEST,
    REFRESH_PATENT_PAYMENT_STATUS_SUCCESS,
    UPDATE_PATENTS_PAYMENT_ERROR,
    UPDATE_PATENTS_PAYMENT_REQUEST,
    UPDATE_PATENTS_PAYMENT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../../../utils/url";

import {LINK_FINANCE_PATENT_PAYMENT_LIST} from "../../../../../../constants/links";

const getController = (params = {}) => {
    const {
        isPatentsPage,
    } = params;

    const depositPagesControllers = isPatentsPage
        ? {
            [DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/patents/deposit/completed-payments",
                client: "/client-adm/client/patents/deposit/completed-payments",
            }),
            [DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/patents/deposit/payment-in-process",
                client: "/client-adm/client/patents/deposit/payment-in-process",
            }),
        }
        : {
            [DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/ndfl-deposit/completed-payments/patents",
                client: "/client-adm/finances/ndfl-deposit/completed-payments/patents",
            }),
            [DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/ndfl-deposit/payments-in-process/patents",
                client: "/client-adm/finances/ndfl-deposit/payments-in-process/patents",
            }),
            [DEPOSIT_SUB_PAGE.PAYMENTS_PENDING]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/ndfl-deposit/pending-payments/patents",
                client: "/client-adm/finances/ndfl-deposit/pending-payments/patents",
            }),
        };

    return (
        getBffUrl({
            ...depositPagesControllers,
            isClientCard: true,
            adminRolesUrl: "/adm/clients/client-card/patents/pay-patents/patent-card",
            clientRolesUrl: "/client-adm/client/patents/pay-patents/patent-card",
        },
        null,
        true,
        )
    );
};

const getPatentsPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPaymentPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {payments} = result;

        if (payments?.length) {
            const contractorIds = payments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: PATENTS_PAYMENTS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_LIST_ERROR,
            payload: error,
        });
    }
};

const addPatentsPaymentSaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.bff.post(`${getController()}/addPayment`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_PATENTS_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");

        if (meta) {
            yield call(meta, null);
        }

        yield put({
            type: ADD_PATENTS_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_PATENTS_PAYMENT_ERROR,
            payload: error,
        });
    }
};

const updatePatentsPaymentSaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.bff.post(`${getController()}/updatePayment`, data);

        const {errorMessage} = result;

        if (errorMessage) {

            toastError(errorMessage);

            yield put({
                type: UPDATE_PATENTS_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Параметры исполнителя успешно изменены");

        if (meta) {
            yield call(meta, null);
        }

        yield put({
            type: UPDATE_PATENTS_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_PATENTS_PAYMENT_ERROR,
            payload: error,
        });
    }
};

const addPatentsPaymentsFromFileSaga = function* ({payload}) {
    try {
        const {
            data,
            clientId,
            patentRegistryId,
            clientDefiningRegistryParameterType,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/addPaymentFromFile?clientId=${clientId}&patentRegistryId=${patentRegistryId}&clientDefiningRegistryParameterType=${clientDefiningRegistryParameterType}`, data, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_PATENTS_PAYMENT_FROM_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Загрузка файла успешно завершена.");

        const {
            patentsPayments: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsPayments(pageData));

        yield put({
            type: ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_PATENTS_PAYMENT_FROM_FILE_ERROR,
            payload: error,
        });
    }
};

const deletePatentPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/deletePayments`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_PATENTS_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_PATENTS_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_PATENTS_PAYMENT_ERROR,
            payload: error,
        });
    }
};

const getPaymentStatusDictSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/getPaymentStatusDict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PATENTS_PAYMENT_STATUS_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PATENTS_PAYMENT_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

const getPatentsPaymentsAdminPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getAdminPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_ADMIN_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_ADMIN_PAGE_ERROR,
            payload: error,
        });
    }
};

const getPatentsPaymentsContractorPageSaga = function* ({payload}) {
    const {
        paymentNumberFilter,
    } = payload;

    const link = LINK_FINANCE_PATENT_PAYMENT_LIST.replace(":paymentNumberFilter?", paymentNumberFilter);

    const url = getBffUrl({
        [link]: "/adm/finances/patent-payments/page",
    }, "/adm/contractors/contractors-registry/contractor-card/finances/patent/page");

    try {
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
            payload: error,
        });
    }
};

const refreshPatentPaymentStatusSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/refreshPayment`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_PATENT_PAYMENT_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Задача на обновление статуса оплаты по патентам успешно запущена");

        yield put({
            type: REFRESH_PATENT_PAYMENT_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REFRESH_PATENT_PAYMENT_STATUS_ERROR,
            payload: error,
        });
    }
};

const declinePatentPaymentSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/declinePayment`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DECLINE_PATENT_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Платеж по патенту отклонен");

        const {
            patentsPayments: {
                pageData,
            },
        } = yield select();

        yield put(getPatentsPaymentsAdminPage(pageData));
        yield put({
            type: DECLINE_PATENT_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: DECLINE_PATENT_PAYMENT_ERROR, payload: error});
    }
};

const getPatentPaymentsDepositPageSaga = function* ({payload}) {
    try {
        const {
            isPatentsPage,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isPatentsPage})}/get-patent-payments-deposit-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR,
            payload: error,
        });
    }
};

const getPatentPaymentsTotalAmountSaga = function* ({payload}) {
    try {
        const {
            isPatentsPage,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isPatentsPage})}/get-patent-payments-total-amount`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_PAYMENTS_TOTAL_AMOUNT_ERROR,
            payload: error,
        });
    }
};

const addPatentsRegistryPaymentsDocumentsExportSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/registry/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_ERROR,
            payload: error.message,
        });
    }
};

const getPatentsAvailableForPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getAvailableForPayments`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(PATENTS_PAYMENTS_LIST_REQUEST, getPatentsPaymentsSaga),
        takeEvery(DELETE_PATENTS_PAYMENT_REQUEST, deletePatentPaymentSaga),
        takeEvery(ADD_PATENTS_PAYMENT_REQUEST, addPatentsPaymentSaga),
        takeEvery(ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST, addPatentsPaymentsFromFileSaga),
        takeEvery(UPDATE_PATENTS_PAYMENT_REQUEST, updatePatentsPaymentSaga),
        takeEvery(GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST, getPaymentStatusDictSaga),
        takeEvery(PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST, getPatentsPaymentsAdminPageSaga),
        takeEvery(PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST, getPatentsPaymentsContractorPageSaga),
        takeEvery(REFRESH_PATENT_PAYMENT_STATUS_REQUEST, refreshPatentPaymentStatusSaga),
        takeEvery(DECLINE_PATENT_PAYMENT_REQUEST, declinePatentPaymentSaga),
        takeEvery(PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST, getPatentPaymentsDepositPageSaga),
        takeEvery(PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST, getPatentPaymentsTotalAmountSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST, addPatentsRegistryPaymentsDocumentsExportSaga),
        takeEvery(PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST, getPatentsAvailableForPaymentsSaga),
    ]);
}