import React from "react";

import bem from "../../../utils/bem";

import "./style.sass";

export const LayoutsContent = (props) => {
    const {
        children,
    } = props;

    const [block] = bem("layouts-content");

    return (
        <div className={block()}>
            {children}
        </div>
    );
};