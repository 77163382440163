import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffBankW1Selector = state => state.bff.bank.w1;
export const bffBankW1ListSelector = createSelector(bffBankW1Selector, ({list}) => list);
export const bffBankW1TotalCountSelector = createSelector(bffBankW1Selector, ({totalCount}) => totalCount);
export const bffBankW1TotalPagesSelector = createSelector(bffBankW1Selector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffBankW1ProgressSelector = createSelector(bffBankW1Selector, ({progress}) => progress);
export const bffBankW1DataSelector = createSelector(bffBankW1Selector, ({data}) => data);
export const bffBankW1ProgressActionSelector = createSelector(bffBankW1Selector, ({progressAction}) => progressAction);