import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../../images/mode.svg";
import {ReactComponent as TelegramIcon} from "../../../../../images/telegram_20.svg";
import {NotificationsSubscribesDescription} from "../description";
import {NotificationsSubscribesEdit} from "../edit";

import {useAppModal} from "../../../../../hooks/useAppModal";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";
import {useNotificationsSubscribesFetch} from "../../hooks/useFetch";
import {useNotificationsSubscribesFilter} from "../../hooks/useFilter";
import {useSubscribeNotifications} from "../../hooks/useSubscribe";

import {getUserRole} from "../../../../../utils/access";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {
    ADMIN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
} from "../../../../../constants/roles";

import {
    deleteNotificationSubscription,
    updateNotificationsSubscribeStore,
} from "../../../../../ducks/bff/notifications/subscribe/actionCreators";
import {
    notificationSubscribesErrorSelector,
    notificationSubscribesListSelector,
    notificationSubscribesProgressActionSelector,
    notificationSubscribesProgressSelector,
    notificationSubscribesTotalCountSelector,
    notificationSubscribesTotalPagesSelector,
} from "../../../../../ducks/bff/notifications/subscribe/selectors";

export const NotificationsSubscribesPage = (props) => {
    const {
        isAllSubscriptions,
        headerText = "Мои подписки",
        clientId,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(notificationSubscribesProgressSelector);
    const totalCount = useSelector(notificationSubscribesTotalCountSelector);
    const list = useSelector(notificationSubscribesListSelector);
    const totalPages = useSelector(notificationSubscribesTotalPagesSelector);
    const progressAction = useSelector(notificationSubscribesProgressActionSelector);
    const error = useSelector(notificationSubscribesErrorSelector);

    const {
        onCloseModal,
    } = useAppModal();

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateNotificationsSubscribeStore({
                    error: null,
                }));
            }

            onCloseModal();
        },
        progressAction,
        error,
    });

    const {
        onClickSubscribeNotifications,
    } = useSubscribeNotifications();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        filters,
        onSearch,
        onClear,
        filterData,
        isSearch,
    } = useNotificationsSubscribesFilter({
        pageSize,
        setPagination,
        clientId,
    });

    useNotificationsSubscribesFetch({
        filterData,
        pageSize,
        pageNum,
        isAllSubscriptions,
        clientId,
    });

    const role = getUserRole();
    const isClientRole = isClientUser(role);
    const isAdminRole = [
        ADMIN,
        NM_MANAGER,
        NM_COORDINATOR,
        NM_CHIEF_ACCOUNTANT,
    ].includes(role);

    const {
        modalData,
        onOpenModal,
    } = useAppModal();

    const getMediaControls = (item) => {
        const editSubscribes = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    onOpenModal({
                        isOpenEdit: true,
                        ...item,
                    });
                },
                color: "grey",
                children: "Редактировать подписки",
            },
            asset: {
                tablet: {
                    onlyIcon: true,
                    icon: <ModeIcon />,
                    children: undefined,
                },
            },
        };

        const renderCount = {
            mobile: 0,
            tablet: 2,
            desktop: 2,
        };

        if (isAllSubscriptions) {
            const confirmProps = {
                content: "Вы действительно хотите удалить данную подписку на уведомления?",
                onConfirm: () => {
                    dispatch(deleteNotificationSubscription({
                        subscribeId: item.subscribeId,
                        clientUserId: item.clientUserId,
                        clientId: item.clientId,
                    }));
                },
            };

            const deleteSubscribes = {
                component: COMPONENT.BUTTON,
                props: {
                    color: "grey",
                    onClick: () => {
                        onOpenModal({
                            confirmProps,
                        });
                    },
                    onlyIcon: true,
                    icon: <DeleteIcon />,
                },
                asset: {
                    mobile: {children: "Удалить"},
                },
            };

            return {
                renderCount,
                buttons: [
                    editSubscribes,
                    deleteSubscribes,
                ],
            };
        }

        const subscribeNotificationsButton =  {
            component: COMPONENT.BUTTON,
            props: {
                onClick: () => {
                    const openModal = () => {
                        onOpenModal({
                            isOpenDescription: true,
                            ...item,
                        });
                    };

                    onClickSubscribeNotifications({
                        openModal,
                    });
                },
                icon: <TelegramIcon />,
                color: "grey",
                children: "Подключить уведомления в Telegram",
            },
            visible: isClientRole,
        };

        return {
            renderCount,
            buttons: [
                subscribeNotificationsButton,
                editSubscribes,
            ],
        };
    };

    const getCount = (recruitmentSubscribes, marketplaceSubscribes) => {
        if (isEmpty(recruitmentSubscribes) && isEmpty(marketplaceSubscribes)) {
            return "Нет";
        }

        const recruitmentCount = recruitmentSubscribes.length || 0;
        const marketplaceCount = marketplaceSubscribes.length || 0;

        return recruitmentCount + marketplaceCount;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                marketplaceSubscribesTelegram,
                marketplaceSubscribesEmail,
                recruitmentSubscribesTelegram,
                recruitmentSubscribesEmail,
                email,
                clientUserName,
                subscribeId,
                isAuthInTelegramBot,
            } = item;

            const labels = [
                {
                    label: "Почта",
                    text: email,
                    noWrap: false,
                    columnOnMobile: true,
                    wrapped: true,
                },
                {
                    label: "Авторизация в Telegram",
                    text: isAuthInTelegramBot ? "Да" : "Нет",
                    noWrap: false,
                    textTooltip: (
                        <HelpTooltip
                            hover={true}
                            type="light"
                            text='Признак авторизации данного пользователя в ТГ-боте "Наймикс. Уведомления"'
                            position="bottom-left"
                        />
                    ),
                },
                {
                    label: "Уведомления на почту",
                    text: getCount(marketplaceSubscribesEmail, recruitmentSubscribesEmail),
                },
                {
                    label: "Уведомления в Telegram",
                    text: getCount(marketplaceSubscribesTelegram, recruitmentSubscribesTelegram),
                },
            ];

            return {
                ...item,
                key: subscribeId,
                contentRow: (
                    <NmListCard
                        noDivider={true}
                        classNameMainContent={`col-16 ${isClientRole ? "col-md-8" : "col-md-9"} col-xxl-13`}
                        primaryHeader={clientUserName}
                        labels={labels}
                        mediaControls={getMediaControls(item)}
                        isFixedActions={true}
                        actionsClassName="col-1"
                    />
                ),
            };
        });
    };

    const getPageMediaControls = () => {
        if (!isAllSubscriptions) {
            return;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        children: "Добавить новую подписку",
                        onClick: () => {
                            onOpenModal({
                                isOpenEdit: true,
                                clientId,
                            });
                        },
                    },
                },
            ],
        };
    };

    const getEmptyPageProps = () => {
        if (isAllSubscriptions) {
            return;
        }

        return {
            isSearch,
            isActionAddIcon: true,
            textAction: "Добавить подписку",
            isShowAction: isAdminRole || isClientRole,
            onClickAction: () => {
                onOpenModal({
                    isOpenEdit: true,
                    clientId,
                });
            },
        };
    };

    const getModals = () => {
        if (modalData?.confirmProps) {
            return (
                <NmConfirmV2
                    content={modalData.confirmProps.content}
                    onConfirm={modalData.confirmProps.onConfirm}
                    isNeedClosing={false}
                    onCancel={onCloseModal}
                    disabled={progressAction}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                />
            );
        }

        if (modalData?.isOpenDescription) {
            return (
                <NotificationsSubscribesDescription onClose={onCloseModal} />
            );
        }

        if (modalData?.isOpenEdit) {
            return (
                <NotificationsSubscribesEdit
                    isAllSubscriptions={isAllSubscriptions}
                    clientId={clientId}
                />
            );
        }

        return null;
    };

    return (
        <NmPage
            isRelative={true}
            noPadding={true}
            isLoaded={progress}
            header={
                <>
                    {getModals()}
                    <NmPageHeader
                        text={headerText}
                        totalCount={
                            isClientRole
                                ? undefined
                                : totalCount
                        }
                    />
                </>
            }
            showHeaderBlock={true}
            typeFilter={"vertical"}
            filtersBase={
                <Filter
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            mediaControls={getPageMediaControls()}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            isEmptyPage={isEmpty(list)}
            emptyPageProps={getEmptyPageProps()}
        >
            <CheckboxList rows={getRows()} />
        </NmPage>
    );
};