import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";

const patentsPaymentsSelector = state => state.bff.clientsPatentsPayCard;
export const patentsPaymentsListSelector = createSelector(patentsPaymentsSelector, ({payments}) => payments);
export const patentsPaymentsProgressListSelector = createSelector(patentsPaymentsSelector, ({progressList}) => progressList);
export const patentsPaymentsActionProgressSelector = createSelector(patentsPaymentsSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const patentsPaymentsTotalCountSelector = createSelector(patentsPaymentsSelector, ({totalCount}) => totalCount);
export const patentsPaymentsListTotalPagesSelector = createSelector(patentsPaymentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const paymentStatusDictSelector = createSelector(patentsPaymentsSelector, ({paymentStatusDict}) => paymentStatusDict);
export const patentPaymentsTotalAmountSelector = createSelector(patentsPaymentsSelector, ({patentPaymentsTotalAmount}) => patentPaymentsTotalAmount);
export const patentPaymentsAvailableForPaymentsSelector = createSelector(patentsPaymentsSelector, ({availableForPayments}) => availableForPayments);