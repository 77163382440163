import {
    BFF_COMMON_ORDER_OBJECTS_SUCCESS,
    BFF_COMMON_ORDER_PROJECTS_SUCCESS,
    BFF_COMMON_ORDER_UPDATE_STORE,
} from "./actions";

const initial = {
    orderObjects: [],
    orderProjects: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_COMMON_ORDER_OBJECTS_SUCCESS:
            return {
                ...state,
                orderObjects: payload.results,
            };
        case BFF_COMMON_ORDER_PROJECTS_SUCCESS:
            return {
                ...state,
                orderProjects: payload.results,
            };
        case BFF_COMMON_ORDER_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};