import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../../fileStore";
import {
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR,
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST,
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS,
    BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_ERROR,
    BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST,
    BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_SUCCESS,
    BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR,
    BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST,
    BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS,
    BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS,
    BFF_DELETE_ACT_REGISTRY_PAYMENTS_ERROR,
    BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS,
    BFF_IMPORT_ACT_REGISTRY_PAYMENTS_ERROR,
    BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

import {FINANCE_EXPORT_MESSAGES} from "../../../../../constants/messages";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/act-registry/card",
        client: "/client-adm/orders/act-registry/card",
    });
};

// GET /bff/adm/clients/client-card/orders/act-registry/card/get-by-id
// GET /bff/client-adm/orders/act-registry/card/get-by-id
const getActRegistryCardSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getController()}/get-by-id`, {params});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/check-for-duplicates
// POST /bff/client-adm/orders/act-registry/card/check-for-duplicates
const checkDuplicateActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/check-for-duplicates`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/pay
// POST /bff/client-adm/orders/act-registry/card/pay
const payActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            getError,
        } = payload;

        const result = yield request.bff.post(`${getController()}/pay`, payload);

        if (result.errorMessage) {
            if (getError) {
                getError(result);
            }

            yield put({
                type: BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/act-registry-payment/pay-single-act
// POST /bff/client-adm/orders/act-registry/card/act-registry-payment/pay-single-act
const paySingleActSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            getError,
        } = payload;

        const result = yield request.bff.post(`${getController()}/act-registry-payment/pay-single-act`, payload);

        if (result.errorMessage) {
            if (getError) {
                getError(result);
            }

            yield put({
                type: BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/page
// POST /bff/client-adm/orders/act-registry/card/page
const getPageActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {registryPayments} = result;

        if (registryPayments?.length) {
            const contractorIds = registryPayments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/act-registry-payment/add
// POST /bff/client-adm/orders/act-registry/card/act-registry-payment/add
const addActRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/act-registry-payment/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/act-registry-payment/delete-all
// POST /bff/client-adm/orders/act-registry/card/act-registry-payment/delete-all
const deleteActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/act-registry-payment/delete-all`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DELETE_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DELETE_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/act-registry-payment/get-by-id
// POST /bff/client-adm/orders/act-registry/card/act-registry-payment/get-by-id
const getByIdActRegistryPaymentSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/act-registry-payment/get-by-id`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/act-registry-payment/update
// POST /bff/client-adm/orders/act-registry/card/act-registry-payment/update
const updateActRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/act-registry-payment/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/import/acts/{clientId}/{registryId}/{definingRegistryParameterType}
// POST /bff/client-adm/orders/act-registry/card/import/acts/{clientId}/{registryId}/{definingRegistryParameterType}
const importActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            registryId,
            definingRegistryParameterType,
            formData,
        } = payload;

        const result = yield request.bff.post(
            `${getController()}/import/acts/${clientId}/${registryId}/${definingRegistryParameterType}`,
            formData,
            {...getMultipartHeaders()},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_IMPORT_ACT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_IMPORT_ACT_REGISTRY_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

const exportActRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/add-export`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_REGISTRY_SUCCESS);

        yield put({
            type: BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/card/is-client-forbidden-by-contractor
// POST /bff/client-adm/orders/act-registry/card/is-client-forbidden-by-contractor
const checkForbiddenContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/is-client-forbidden-by-contractor`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST, getActRegistryCardSaga),
        takeEvery(BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST, payActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST, checkDuplicateActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST, paySingleActSaga),
        takeEvery(BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST, addActRegistryPaymentSaga),
        takeEvery(BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST, deleteActRegistryPaymentsSaga),
        takeEvery(BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST, getByIdActRegistryPaymentSaga),
        takeEvery(BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST, updateActRegistryPaymentSaga),
        takeEvery(BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST, importActRegistryPaymentsSaga),
        takeEvery(BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST, getPageActRegistryPaymentsSaga),
        takeEvery(BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST, exportActRegistryPaymentsSaga),
        takeEvery(BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST, checkForbiddenContractorSaga),
    ]);
}