import React from "react";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../../components/ActualComponents/FilterButtonsV2";
import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import PhoneWithCodeFilter from "../../../../../../components/PhoneWithCodeFilter";

import {dictionaryToOptions} from "../../../../../../utils/objectHelper";

import {
    REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS,
    REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS,
} from "../../../../../../constants/registry";

import {registryInvitationsContractorsStatusDictSelector} from "../../../../../../ducks/bff/common/dicts/selectors";

const RegistryInvitationsCardFilter = props => {
    const {
        submitFilter,
        clearFilter,
        onChange,
        filter,
    } = props;

    const {
        fioFilter,
        phoneFilter,
        contractFrameStatusFilter,
        paymentAbility,
        serialNumber,
        orderNumFilter,
        statusFilter,
    } = filter;

    const statusDict = useSelector(registryInvitationsContractorsStatusDictSelector);
    const statusFilterOptions = dictionaryToOptions(statusDict);

    return (
        <NmForm addMargin>
            <NmInputV2
                name="fioFilter"
                onChange={onChange}
                value={fioFilter || ""}
                label="ФИО"
                size="lg"
            />
            <PhoneWithCodeFilter
                name="phoneFilter"
                value={phoneFilter}
                onChange={onChange}
            />
            <NmDropdownV2
                name="statusFilter"
                options={statusFilterOptions}
                onChange={onChange}
                value={statusFilter || ""}
                label="Статус строки"
                size="lg"
            />
            <NmInputV2
                name="serialNumber"
                onChange={onChange}
                value={serialNumber || ""}
                mask="999999999999999999"
                maskChar={null}
                label="Номер строки"
                size="lg"
            />
            <NmInputV2
                name="orderNumFilter"
                onChange={onChange}
                value={orderNumFilter || ""}
                mask="999999999999999999"
                maskChar={null}
                label="Номер заказа"
                size="lg"
            />
            <NmDropdownV2
                name="contractFrameStatusFilter"
                options={REGISTRY_PAYMENT_FILTER_CONTRACT_FRAME_OPTIONS}
                onChange={onChange}
                value={contractFrameStatusFilter || ""}
                label="Рамочный договор"
                size="lg"
                multiple
            />
            <NmDropdownV2
                name="paymentAbility"
                options={REGISTRY_PAYMENT_FILTER_PAYMENT_ABILITY_OPTIONS}
                onChange={onChange}
                value={paymentAbility || ""}
                label="Возможность оплаты"
                size="lg"
            />
            <FilterButtonsV2
                className="registry-card-filter__buttons"
                onSearch={() => submitFilter(filter)}
                onClear={clearFilter}
            />
        </NmForm>
    );
};

export default RegistryInvitationsCardFilter;