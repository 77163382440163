import {
    ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_DUPLICATES_REGISTRY_REQUEST,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CONFIRM_DUPLICATES_REGISTRY_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
} from "./actions";

export const getDocumentsEdoRegistriesPage = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST,
    payload,
});

export const getByIdDocumentsEdoRegistries = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST,
    payload,
});

export const addDocumentsEdoRegistries = (payload) => ({
    type: ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistries = (payload) => ({
    type: UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});


export const archiveDocumentsEdoRegistries = (payload) => ({
    type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesStore = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE,
    payload,
});

export const checkContractorsExistsDocumentsEdoRegistries = (payload) => ({
    type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const sendSmsDocumentsEdoRegistries = (payload) => ({
    type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const signDocumentsEdoRegistries = (payload) => ({
    type: SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const getDocumentsEdoRegistriesAttachmentsFile = (payload) => ({
    type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST,
    payload,
});

export const exportDocumentsEdoRegistries = (payload) => ({
    type: EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const checkDuplicatesRegistry = (payload) => ({
    type: CHECK_DUPLICATES_REGISTRY_REQUEST,
    payload,
});

export const confirmDuplicatesRegistry = (payload) => ({
    type: CONFIRM_DUPLICATES_REGISTRY_REQUEST,
    payload,
});

export const checkDuplicatesDocumentsEdoRegistries = (payload) => ({
    type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});

export const confirmDuplicatesDocumentsEdoRegistries = (payload) => ({
    type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    payload,
});