import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import Text from "../../components/ActualComponents/Text";
import CheckboxList from "../../components/CheckboxList";
import ExtLink from "../../components/ExtLink";
import NmBadge from "../../components/NmBadge";
import NmButton from "../../components/NmButton";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import OnboardingScenarioEditForm from "./components/edit-form";
import OnboardingScenarioLogModal from "./components/scenario-log";

import {usePagination} from "../../hooks/usePagination";
import {useFetchOnboardingList} from "./hooks/useFetch";
import {useOnboardingListFilter} from "./hooks/useFilter";

import formatDate, {convertUtcToLocal} from "../../utils/dateFormat";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {isAccessByRestrictions} from "../../utils/restrictions";
import {getFullName} from "../../utils/stringFormat";
import {getOnboardingFrameContractType} from "./utils/getFrameContractType";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {COMMON_ALL_OPTION} from "../../constants/common";
import {
    LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
} from "../../constants/links";
import {ONBOARDING_SCENARIO_STATUS} from "../../constants/onboarding";
import {
    CLIENT_ACCOUNTANT,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    RECRUITER,
} from "../../constants/roles";

import {history} from "../../store/configureStore";

import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";
import {
    onboardingListProgressSelector,
    onboardingListSelector,
    onboardingListTotalCountSelector,
    onboardingListTotalPagesSelector,
} from "../../ducks/onboarding";

const initFilter = {
    nameFilter: "",
    numberFilter: "",
    creationDateTimeFrom: null,
    creationDateTimeTo: null,
    statusFilter: null,
    frameContractTypeFilter: COMMON_ALL_OPTION.key,
};

const OnboardingList = (props) => {
    const {
        clientId,
        tabs,
    } = props;

    const [logModalData, setLogModalData] = useState({});
    const [isOpenEditForm, setIsOpenEditForm] = useState(false);

    const totalPages = useSelector(onboardingListTotalPagesSelector);
    const totalCount = useSelector(onboardingListTotalCountSelector);
    const list = useSelector(onboardingListSelector);
    const progress = useSelector(onboardingListProgressSelector);
    const {archived: isClientArchived} = useSelector(clientCardInfoSelector);

    const role = ls(USER_ROLE);
    const isAccessActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOnboardingScenario,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOnboardingScenario,
    ]);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    } = useOnboardingListFilter({});

    const {
        fetchList,
    } = useFetchOnboardingList({
        filter: filterDto,
        pageSize,
        pageNum,
        clientId,
    });

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    const renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    const renderBoundScenarios = (boundScenarios) => {
        return (
            <div className="flex flex-wrap align-items-center">
                {
                    boundScenarios.map((value, index) => {
                        return (
                            <div
                                key={value.scenarioId}
                                className="flex align-items-center"
                            >
                                <Text noWrap>
                                    {value.name}
                                </Text>
                                {
                                    index !== boundScenarios.length - 1 &&
                                    <div className="nm-selected-list__delimiter" />
                                }
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                scenarioId,
                creationDateTime,
                name,
                number,
                responsibleUserFirstName,
                responsibleUserLastName,
                responsibleUserPatronymic,
                recruitersCount,
                contractorsCount,
                status,
                boundScenarios,
                frameContractType,
            } = item;

            return {
                ...item,
                key: scenarioId,
                contentRow: (
                    <NmListCard
                        noDivider
                        alignItems="flex-end"
                        secondaryHeader={`Дата создания: ${formatDate(convertUtcToLocal(creationDateTime), "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                text={ONBOARDING_SCENARIO_STATUS[status]?.TEXT}
                                mod={ONBOARDING_SCENARIO_STATUS[status]?.MOD || "gray"}
                                className="me-2"
                            />
                        }
                        primaryHeader={name}
                        primaryHeaderLink
                        onClickLink={() => {
                            history.push(
                                LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD
                                    .replace(":clientId", clientId)
                                    .replace(":scenarioId", scenarioId),
                            );
                        }}
                        classNameMainContent="col-16 col-xl-9"
                        labels={[
                            {
                                label: "Номер сценария",
                                text: number,
                            },
                            {
                                label: "Ответственный",
                                text: getFullName(responsibleUserLastName, responsibleUserFirstName, responsibleUserPatronymic),
                            },
                            {
                                label: "Объект",
                                text: renderObjectInfo(item),
                            },
                            {
                                label: "Тип договора",
                                text: getOnboardingFrameContractType(frameContractType),
                            },
                            {
                                label: "Связанные сценарии",
                                text: boundScenarios?.length ? renderBoundScenarios(boundScenarios) : "-",
                            },
                            status === ONBOARDING_SCENARIO_STATUS.INACTIVE.VALUE && {
                                label: "Назначен другой сценарий",
                                text: "-",
                            },
                        ]}
                        cards={[
                            {
                                title: "Исполнители",
                                value: contractorsCount || 0,
                                className: "col-16 col-md-4 col-xl-2 mt-xl-4 mt-xxl-0",
                            },
                            {
                                title: "Рекрутеры",
                                value: recruitersCount || 0,
                                className: "col-16 col-md-4 col-xl-2 mt-xl-4 mt-xxl-0",
                            },
                        ]}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 0,
                                tablet: 0,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "grey",
                                        size: "lg",
                                        onClick: () => {
                                            setLogModalData(item);
                                        },
                                        children: "Лог",
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };


    const renderLogModal = () => {
        if (isEmpty(logModalData)) {
            return null;
        }

        return (
            <OnboardingScenarioLogModal
                {...logModalData}
                onClose={() => setLogModalData({})}
            />
        );
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <OnboardingScenarioEditForm
                clientId={clientId}
                onClose={() => setIsOpenEditForm(false)}
                fetchList={fetchList}
            />
        );
    };

    return (
        <NmPage
            noPadding
            heightUnset
            header={
                <NmPageHeader
                    text="Реестр исполнителей"
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={(form) => submitFilter(form, true)}
                    clearFilter={() => submitFilter(initFilter, false)}
                />
            }
            controls={
                isAccessActions &&
                !isClientArchived &&
                ![
                    NM_COORDINATOR,
                    NM_CHIEF_ACCOUNTANT,
                    FOREMAN,
                    CLIENT_ACCOUNTANT,
                    RECRUITER,
                    NM_OPERATOR,
                ].includes(role) &&
                <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={() => {
                        setIsOpenEditForm(true);
                    }}
                >
                    Создать сценарий
                </NmButton>
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {tabs}
            {renderLogModal()}
            {renderEditForm()}
            {
                totalCount > 0
                    ? <CheckboxList
                        rows={getRows()}
                    />
                    : <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default OnboardingList;