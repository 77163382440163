import {
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
    ADD_ITEM_INTO_FC_REGISTRY_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_REQUEST,
    ARCHIVE_FC_REGISTRY_REQUEST,
    CANCEL_FC_REGISTRY_ITEM_REQUEST,
    DELETE_FC_REGISTRY_ITEMS_REQUEST,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST,
    EXPORT_FC_REGISTRY_REQUEST,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST,
    GET_FC_REGISTRIES_PAGE_REQUEST,
    GET_FC_REGISTRY_BY_ID_REQUEST,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_ITEMS_PAGE_REQUEST,
    GET_FC_REGISTRY_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,
    IMPORT_ITEMS_FC_REGISTRY_REQUEST,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST,
} from "./actions";

export const getFcRegistrySubmittingFlags = (payload) => ({
    type: GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,
    payload,
});

export const deleteFcRegistryItems = (payload) => ({
    type: DELETE_FC_REGISTRY_ITEMS_REQUEST,
    payload,
});

export const cancelFcRegistryItem = (payload) => ({
    type: CANCEL_FC_REGISTRY_ITEM_REQUEST,
    payload,
});

export const exportFcRegistry = (payload) => ({
    type: EXPORT_FC_REGISTRY_REQUEST,
    payload,
});

export const addItemIntoFcRegistry = (payload) => ({
    type: ADD_ITEM_INTO_FC_REGISTRY_REQUEST,
    payload,
});

export const getContractorsPageForFcRegistry = (payload) => ({
    type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST,
    payload,
});

export const deleteNotSignedItemsFcRegistry = (payload) => ({
    type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST,
    payload,
});

export const submitFcRegistryToSigning = (payload) => ({
    type: SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    payload,
});

export const resubmitFcRegistryToSigning = (payload) => ({
    type: RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    payload,
});

export const getFcRegistryPage = (payload) => ({
    type: GET_FC_REGISTRIES_PAGE_REQUEST,
    payload,
});

export const getFcRegistryById = (payload) => ({
    type: GET_FC_REGISTRY_BY_ID_REQUEST,
    payload,
});

export const getFcRegistryItemsPage = (payload) => ({
    type: GET_FC_REGISTRY_ITEMS_PAGE_REQUEST,
    payload,
});

export const getFcRegistryStatusDict = () => ({
    type: GET_FC_REGISTRY_STATUS_DICT_REQUEST,
});

export const getFcRegistryItemStatusDict = () => ({
    type: GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST,
});

export const archiveFcRegistry = (payload) => ({
    type: ARCHIVE_FC_REGISTRY_REQUEST,
    payload,
});

export const importItemsFcRegistry = (payload) => ({
    type: IMPORT_ITEMS_FC_REGISTRY_REQUEST,
    payload,
});

export const addRegistryFrameContract = (payload) => ({
    type: ADD_REGISTRY_FRAME_CONTRACT_REQUEST,
    payload,
});

export const updateRegistryFrameContract = (payload) => ({
    type: UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST,
    payload,
});

export const addRegistryFrameContractItems = (payload) => ({
    type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    payload,
});

export const updateRegistryFrameContractItems = (payload) => ({
    type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    payload,
});

export const addClientFrameContractExternal = (payload) => {
    return {
        type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
        payload,
    };
};