import {createSelector} from "reselect";

import {RECRUITMENT_TEXT_NO_PARAM} from "../../../../constants/recruitment";

import {getRecruitmentDirectoriesFunnelOption} from "../../../../containers/recruitment/directories/funnels/components/edit-modal/utils/getOptions";
import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions, getOptionFromStringValue} from "../../../../utils/objectHelper";

export const bffRecruitmentDirectorySelector = state => state.bff.recruitment.directory;
export const bffRecruitmentDirectoryStatusListSelector = createSelector(bffRecruitmentDirectorySelector, ({statusList}) => {
    return statusList.map(item => {
        return {
            ...item,
            value: item.name,
        };
    });
});
export const bffRecruitmentDirectoryStatusOptionsSelector = createSelector(bffRecruitmentDirectorySelector, ({statusList}) => {
    return statusList.map(getRecruitmentDirectoriesFunnelOption);
});
export const bffRecruitmentDirectoryStatusTotalCountSelector = createSelector(bffRecruitmentDirectorySelector, ({statusTotalCount}) => statusTotalCount);
export const bffRecruitmentDirectoryStatusTotalPagesSelector = createSelector(bffRecruitmentDirectorySelector, ({statusTotalCount, statusPageData: {pageSize = 0}}) => getTotalPages(statusTotalCount, pageSize));
export const bffRecruitmentDirectoryJobRequirementListSelector = createSelector(bffRecruitmentDirectorySelector, ({jobRequirementList}) => {
    return jobRequirementList.map(item => {
        return {
            ...item,
            value: item.name,
        };
    });
});
export const bffRecruitmentDirectoryJobRequirementOptionsSelector = createSelector(bffRecruitmentDirectorySelector, ({jobRequirementList}) => {
    return jobRequirementList.map((item) => {
        return {
            key: item.id,
            value: item.id,
            text: item.name,
        };
    });
});
export const bffRecruitmentDirectoryJobRequirementTotalCountSelector = createSelector(bffRecruitmentDirectorySelector, ({jobRequirementTotalCount}) => jobRequirementTotalCount);
export const bffRecruitmentDirectoryJobRequirementTotalPagesSelector = createSelector(bffRecruitmentDirectorySelector, ({jobRequirementTotalCount, jobRequirementPageData: {pageSize = 0}}) => getTotalPages(jobRequirementTotalCount, pageSize));
export const bffRecruitmentDirectoryFunnelListSelector = createSelector(bffRecruitmentDirectorySelector, ({funnelList}) => funnelList);
export const bffRecruitmentDirectoryFunnelOptionsSelector = createSelector(bffRecruitmentDirectorySelector, ({funnelList}) => {
    return funnelList.map((item) => {
        return {
            key: item.id,
            value: item.id,
            text: item.name,
        };
    });
});
export const bffRecruitmentDirectoryFunnelTotalCountSelector = createSelector(bffRecruitmentDirectorySelector, ({funnelTotalCount}) => funnelTotalCount);
export const bffRecruitmentDirectoryFunnelTotalPagesSelector = createSelector(bffRecruitmentDirectorySelector, ({funnelTotalCount, funnelPageData: {pageSize = 0}}) => getTotalPages(funnelTotalCount, pageSize));
export const bffRecruitmentDirectoryProgressSelector = createSelector(bffRecruitmentDirectorySelector, ({progress}) => progress);
export const bffRecruitmentDirectoryStatusListProgressSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({statusListProgress}) => statusListProgress,
);
export const bffRecruitmentDirectoryProgressActionSelector = createSelector(bffRecruitmentDirectorySelector, ({progressAction}) => progressAction);
export const bffRecruitmentDirectoryCountsSelector = createSelector(bffRecruitmentDirectorySelector, ({counts}) => counts);
export const bffRecruitmentDirectoryAvitoProfessionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoProfessionsDict}) => avitoProfessionsDict,
);
export const bffRecruitmentDirectoryAvitoManagerPhoneNumbersOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoManagerPhoneNumbers}) => {
        return avitoManagerPhoneNumbers.map(getOptionFromStringValue);
    },
);
export const bffRecruitmentDirectoryAvitoProfessionsOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoProfessionsDict}) => dictionaryToOptions(avitoProfessionsDict),
);
export const bffRecruitmentDirectoryAvitoSchedulesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoSchedulesDict}) => avitoSchedulesDict,
);
export const bffRecruitmentDirectoryAvitoSchedulesOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoSchedulesDict}) => dictionaryToOptions(avitoSchedulesDict),
);
export const bffRecruitmentDirectoryAvitoExperiencesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoExperiencesDict}) => avitoExperiencesDict,
);
export const bffRecruitmentDirectoryAvitoExperiencesOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoExperiencesDict}) => dictionaryToOptions(avitoExperiencesDict),
);
export const bffRecruitmentDirectoryAvitoBillingTypesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoBillingTypesDict}) => avitoBillingTypesDict,
);
export const bffRecruitmentDirectoryAvitoBillingTypesOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoBillingTypesDict}) => dictionaryToOptions(avitoBillingTypesDict),
);
export const bffRecruitmentDirectoryAvitoBusinessAreasSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoBusinessAreasDict}) => avitoBusinessAreasDict,
);
export const bffRecruitmentDirectoryAvitoBusinessAreasOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({avitoActualBusinessAreasDict}) => dictionaryToOptions(avitoActualBusinessAreasDict),
);
export const bffRecruitmentDirectoryRejectionReasonListSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({rejectionReasonsList}) => rejectionReasonsList,
);
export const bffRecruitmentDirectoryRejectionReasonTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({rejectionReasonsTotalCount}) => rejectionReasonsTotalCount,
);
export const bffRecruitmentDirectoryRejectionReasonTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({rejectionReasonsTotalCount, rejectionReasonsPageData: {pageSize = 0}}) => {
        return getTotalPages(rejectionReasonsTotalCount, pageSize);
    },
);
export const bffRecruitmentDirectoryRejectionReasonOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({rejectionReasonsList}) => {
        return rejectionReasonsList.map(item => {
            return {
                key: item.id,
                value: item.id,
                text: item.value,
            };
        });
    },
);

export const bffHHVacancyBrandedTemplatesDictSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({hhBrandTemplatesDict}) => hhBrandTemplatesDict,
);
export const bffRecruitmentDirectoryMessageTemplatesListSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplatesList}) => {
        return messageTemplatesList.map(item => {
            return {
                ...item,
                value: item.name,
                templateText: item.value,
            };
        });
    },
);
export const bffRecruitmentDirectoryMessageTemplatesTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplatesTotalCount}) => messageTemplatesTotalCount,
);
export const bffRecruitmentDirectoryMessageTemplatesTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplatesTotalCount, messageTemplatesPageData: {pageSize = 0}}) => {
        return getTotalPages(messageTemplatesTotalCount, pageSize);
    },
);
export const bffRecruitmentDirectoryErrorSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({error}) => error,
);
export const bffRecruitmentDirectoryManagersOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({managers}) => managers.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.fullName,
            ...item,
        };
    }),
);
export const bffRecruitmentDirectoryMessageTemplateParamsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplateParams}) => messageTemplateParams.reduce((result, item) => {
        return {
            ...result,
            [item.name]: item.size,
        };
    }, {}),
);
export const bffRecruitmentDirectoryMessageTemplateSearchWordsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplateParams}) => messageTemplateParams.map(item => {
        return `${RECRUITMENT_TEXT_NO_PARAM} ${item.name}`;
    }),
);
export const bffRecruitmentDirectoryMessageTemplateParamsOptionsSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({messageTemplateParams}) => messageTemplateParams.map((item) => {
        return {
            key: item.value,
            value: item.value,
            text: item.name,
        };
    }),
);