import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositChangeModal from "../../../../components/DepositChangeModal";

import {toastSuccess} from "../../../../utils/toastHelper";

import {addClientObjectDeposit} from "../../../../ducks/bff/clients/objects/actionCreators";
import {clientObjectsProgressActionSelector} from "../../../../ducks/bff/clients/objects/selectors";

const ObjectsAddDeposit = (props) => {
    const {
        name,
        onClose,
        clientId,
        objectId,
        projectId,
        projectName,
        fetchData = () => {},
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(clientObjectsProgressActionSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
        } = requestData;

        dispatch(addClientObjectDeposit({
            amount,
            clientId,
            objectId,
            projectId,
            paymentType: requestData.type,
            onSuccess: () => {
                toastSuccess("Депозит объекта успешно изменен");
                fetchData();
                onClose();
            },
        }));
    };

    return (
        <DepositChangeModal
            onClose={onClose}
            targetText="объекта"
            targetName={name}
            onSubmit={onSubmit}
            progress={progress}
        />
    );
};

export default ObjectsAddDeposit;