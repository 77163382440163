import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as DoneIcon} from "../../../images/done_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import NotificationsListFilter from "../filter";
import NotificationMailingListEdit from "../mailing-list-edit";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {handleFormString} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    isClientUser,
    NM_MANAGER,
} from "../../../constants/roles";

import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {getEmailTypeMap} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    emailNotificationsTypeMapOptionsSelector,
    emailNotificationsTypeMapSelector,
} from "../../../ducks/bff/common/dicts/selectors";
import {
    deleteEmailNotification,
    deleteEmailNotificationList,
    getEmailNotificationList,
} from "../../../ducks/bff/notifications/email-notifications/actionCreators";
import {
    getListSettingNotificationSelector,
    getTotalCountSettingNotificationSelector,
    getTotalPagesSettingNotificationSelector,
} from "../../../ducks/bff/notifications/email-notifications/selectors";

import "./style.sass";

class NotificationMailingList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedList: [],
            isAllSelected: false,
            countEmailNotificationSelected: 0,
            pageNum: 1,
            pageSize: 25,
            typeSearchFilter: "",
            emailSearchFilter: "",
            typeSubstring: "",
            isOpenModal: false,
            emails: [],
            email: "",
            notificationTypes: [],
            isCheckedTypesAll: false,
            isEditSettingNotifications: false,
            openConfirmWindow: false,
            action: {},
            loading: false,
            isSearch: false,
        };

        const {match} = props;

        const {
            params: {
                clientId,
            },
        } = match;

        this.clientId = clientId;

        this.role = ls(USER_ROLE);
        this.isAccess = [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role);
    }

    componentDidMount() {
        const {
            getEmailTypeMap,
        } = this.props;

        getEmailTypeMap();
        this.fetchList();
    }

    fetchList = () => {
        this.setLoading(true);

        const {
            getEmailNotificationList,
            emailTypeMap,
        } = this.props;

        const {
            pageNum,
            pageSize,
            typeSearchFilter,
            emailSearchFilter,
            typeSubstring,
        } = this.state;

        getEmailNotificationList({
            clientId: this.clientId,
            pageNum,
            pageSize,
            typeSearchFilter: emailTypeMap[typeSearchFilter] || handleFormString(typeSubstring),
            emailSearchFilter: handleFormString(emailSearchFilter),
            getResult: this.setLoading,
        });
    };

    get accessActions() {
        if (isClientUser(this.role)) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editClientEmailNotifications,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientEmailNotifications,
        ]);
    }

    setLoading = (loading = false) => {
        this.setState({loading});
    };

    sendFilter = (filter, isSearch) => {
        this.setState(prevState => ({
            ...prevState,
            ...filter,
            pageNum: 1,
            isSearch,
        }), this.fetchList);
    };

    deleteEmailSettingNotification = (notificationEmailId) => {
        const {deleteEmailNotification} = this.props;

        deleteEmailNotification({
            onSuccess: this.handleSuccess,
            clientId: this.clientId,
            notificationEmailId,
        });

        this.setState(prevState => ({
            ...prevState,
            selectedList: prevState.selectedList.filter(value => value.notificationEmailId !== notificationEmailId),
            countEmailNotificationSelected: prevState.selectedList.filter(value => value.notificationEmailId !== notificationEmailId && value.isSelected).length,
        }));

        this.fetchList();
    };

    handleSuccess = () => {
        this.toggleConfirmWindow();
        this.fetchList();
    };

    handleAddSuccess = () => {
        this.fetchList();
    };

    deleteSelectedEmail = () => {
        const {selectedList} = this.state;
        const {deleteEmailNotificationList} = this.props;

        deleteEmailNotificationList({
            onSuccess: this.handleSuccess,
            clientId: this.clientId,
            notificationEmailIds: selectedList.filter(email => email.isSelected).map(({notificationEmailId}) => notificationEmailId),
        });

        this.resetSelectedAllRows();
    };

    handleChangePageSize = pageSize => {
        this.setState({
            pageNum: 1,
            pageSize,
        }, this.fetchList);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleSelectedRows = (selectedList, isAllSelected) => {
        this.setState({
            isAllSelected,
            selectedList,
            countEmailNotificationSelected: selectedList.filter(value => value.isSelected).length,
        });
    };

    resetSelectedAllRows = () => {
        this.setState(prevState => ({
            ...prevState,
            isAllSelected: false,
            selectedList: prevState.selectedList.map(item => ({
                ...item,
                isSelected: false,
            })),
            countEmailNotificationSelected: 0,
        }));
    };

    openCreateModal = () => {
        this.setState({
            isOpenModal: true,
            isEditSettingNotifications: false,
        });
    };

    openEditModal = (notificationEmailId) => {
        this.setState({
            isOpenModal: true,
            isEditSettingNotifications: true,
            notificationEmailId,
        });
    };

    closeModal = () => {
        this.setState({
            isOpenModal: false,
            isEditSettingNotifications: false,
        });
    };

    toggleConfirmWindow = (action = {}) => {
        this.setState(prevState => ({
            ...prevState,
            action,
            openConfirmWindow: !prevState.openConfirmWindow,
        }));
    };

    getRows() {
        const {selectedList} = this.state;
        const {
            list,
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return list && list.map(row => {
            const notificationSelected = selectedList.find(item => (item.notificationEmailId === row.notificationEmailId)) || {};
            const {isSelected = false} = notificationSelected;

            return {
                ...row,
                isSelected,
                showCheckBox: (this.isAccess && !isClientArchived),
                contentRow: this.getContentRow(row),
            };
        });
    }

    getContentRow(row) {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;
        const {
            email,
            notificationTypeMap,
            notificationTypes,
            notificationEmailId,
        } = row;

        return (
            <NmListCard
                checkbox
                isShowCheckboxLabel
                alignItems="flex-start"
                classNameMainContent="col-16 col-xxl-3"
                primaryHeader={email}
                classNamePrimaryHeaderValue="notifications-settings-list__header-value"
                classNameDivider="notifications-settings-list__divider"
                primaryHeaderNoWrap
                otherContent={
                    <div className="col-16 col-md-12 col-xxl-11 notifications-settings-list__notification-type-block">
                        {
                            notificationTypes.map(type => {
                                return (
                                    <div
                                        key={type}
                                        className="notifications-settings-list__type"
                                    >
                                        <DoneIcon className="notifications-settings-list__icon" />
                                        <Text
                                            color={COLOR.SECONDARY_70}
                                            level="3"
                                        >
                                            {notificationTypeMap[type]}
                                        </Text>
                                    </div>
                                );
                            })
                        }
                    </div>
                }
                isFixedActions
                actionsClassName="col-1 col-xxl-2"
                mediaControls={{
                    renderCount: {
                        mobile: 0,
                        tablet: 2,
                        desktop: 2,
                    },
                    size: "lg",
                    buttons: [
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                onClick: () => {
                                    this.openEditModal(notificationEmailId);
                                },
                                icon: <ModeIcon />,
                                onlyIcon: true,
                            },
                            asset: {
                                mobile: {children: "Редактировать"},
                            },
                            visible: !isClientArchived && this.isAccess && this.accessActions,
                        },
                        {
                            component: COMPONENT.BUTTON,
                            props: {
                                color: "grey",
                                onClick: () => {
                                    this.toggleConfirmWindow({
                                        confirmText: "Вы действительно хотите удалить выбранную настройку?",
                                        onConfirm: () => {
                                            this.deleteEmailSettingNotification(notificationEmailId);
                                        },
                                    });
                                },
                                icon: <DeleteIcon />,
                                onlyIcon: true,
                            },
                            asset: {
                                mobile: {children: "Удалить"},
                            },
                            visible: !isClientArchived && this.isAccess && this.accessActions,
                        },
                    ],
                }}
            />
        );
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            action: {
                confirmText,
                onConfirm,
            },
        } = this.state;

        if (!openConfirmWindow) {
            return null;
        }

        return (
            <NmConfirmV2
                isNeedClosing={false}
                onCancel={this.toggleConfirmWindow}
                onConfirm={onConfirm}
                content={confirmText}
                confirmButton="Да"
                cancelButton="Нет"
                size="sm"
            />
        );
    }

    render() {
        const {
            totalPages,
            emailTypeMapOptions,
            totalCount,
            client: {
                archived: isClientArchived,
            },
        } = this.props;
        const {
            countEmailNotificationSelected,
            pageNum,
            pageSize,
            isOpenModal,
            isEditSettingNotifications,
            notificationEmailId,
            loading,
            isSearch,
        } = this.state;

        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                className="notifications-list"
                header={
                    <NmTitle
                        size="xl"
                        children="Рассылки на почту"
                    />
                }
                filtersBase={
                    <NotificationsListFilter
                        isSettingNotifications={true}
                        typeEmailList={emailTypeMapOptions}
                        sendFilter={this.sendFilter}
                    />
                }
                typeFilter="vertical"
                isLoaded={loading}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                controls={
                    (this.accessActions && !isClientArchived && this.isAccess && isShowControls) &&
                    <NmButton
                        size="xl"
                        icon={<AddIcon />}
                        onClick={this.openCreateModal}
                    >
                        Добавить новую настройку
                    </NmButton>
                }
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
            >
                {
                    isOpenModal &&
                    <NotificationMailingListEdit
                        handleSuccess={this.handleAddSuccess}
                        isEdit={isEditSettingNotifications}
                        close={this.closeModal}
                        clientId={this.clientId}
                        notificationEmailId={notificationEmailId}
                    />
                }
                {this.renderConfirmWindow()}
                {
                    !(totalCount || loading) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction="Добавить новую настройку"
                            isShowAction={!isClientArchived && this.isAccess && this.accessActions}
                            onClickAction={!isClientArchived && this.openCreateModal}
                            isSearch={isSearch}
                        /> :
                        <CheckboxList
                            isShowCheckboxLabel
                            onSelectedRows={this.handleSelectedRows}
                            withCheckbox={!isClientArchived && this.isAccess && this.accessActions}
                            rows={this.getRows()}
                            count={countEmailNotificationSelected}
                            mediaControls={{
                                renderCount: {
                                    mobile: 0,
                                    tablet: 1,
                                    desktop: 1,
                                },
                                buttons: [
                                    {
                                        component: COMPONENT.BUTTON,
                                        props: {
                                            color: "grey",
                                            disabled: countEmailNotificationSelected === 0,
                                            size: "lg",
                                            onClick: () => {
                                                this.toggleConfirmWindow({
                                                    confirmText: "Вы действительно хотите удалить выбранные настройки из списка?",
                                                    onConfirm: this.deleteSelectedEmail,
                                                });
                                            },
                                            children: "Удалить",
                                        },
                                    },
                                ],
                            }}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(state => ({
    emailTypeMap: emailNotificationsTypeMapSelector(state),
    emailTypeMapOptions: emailNotificationsTypeMapOptionsSelector(state),
    list: getListSettingNotificationSelector(state),
    totalPages: getTotalPagesSettingNotificationSelector(state),
    totalCount: getTotalCountSettingNotificationSelector(state),
    client: clientCardInfoSelector(state),
}),
{
    getEmailTypeMap,
    getEmailNotificationList,
    deleteEmailNotification,
    deleteEmailNotificationList,

},
)(withTranslation()(NotificationMailingList));
