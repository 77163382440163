import {
    CREATE_EXPORT_STOP_LIST_REQUEST,
    IMPORT_STOP_LIST_REQUEST,
    STOP_LIST_ADD_INN_REQUEST,
    STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST,
    STOP_LIST_DELETE_INNS_REQUEST,
    STOP_LIST_READ_REQUEST,
} from "./actions";

export function getStopList(payload) {
    return {
        type: STOP_LIST_READ_REQUEST,
        payload,
    };
}

export function addInnForbidden(payload) {
    return {
        type: STOP_LIST_ADD_INN_REQUEST,
        payload,
    };
}

export function deleteAllInnClient(payload) {
    return {
        type: STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST,
        payload,
    };
}

export function deleteInnsForbidden(payload) {
    return {
        type: STOP_LIST_DELETE_INNS_REQUEST,
        payload,
    };
}

export function importInnsFromFile(payload) {
    return {
        type: IMPORT_STOP_LIST_REQUEST,
        payload,
    };
}

export function exportStopList(payload) {
    return {
        type: CREATE_EXPORT_STOP_LIST_REQUEST,
        payload,
    };
}