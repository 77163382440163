import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {ADMIN, isClientUser, NM_MANAGER, RECRUITER} from "../../../../constants/roles";

export const getRecruitmentDirectoriesAccessActions = (params) => {
    const {
        role,
        clientProperties,
    } = params;

    if (
        role === RECRUITER
        && clientProperties?.restrictUpdateFunnelToRecruiters
    ) {
        return false;
    }

    return (
        (
            [ADMIN, NM_MANAGER].includes(role)
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.manageDirectoriesAdmin,
            ])
        )
        || (isClientUser(role))
    );
};