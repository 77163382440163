import React from "react";
import {Route, Switch, withRouter} from "react-router";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import CrowdTaskRegistry from "../../crowd/task-registry";
import OrderList from "../../order/order-list";
import ObjectContractorsList from "../project-object-contractors";
import ProjectsTeamList from "../projects-team-list";

import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_CONTRACTORS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TEAM_LIST,
} from "../../../constants/links";

const ProjectObjectRoute = ({addressId, latitude, longitude, hideDetails}) => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST}
                render={
                    (props) =>
                        <OrderList
                            {...props}
                            projectObjectHideDetails={hideDetails}
                            objectLatitude={latitude}
                            objectLongitude={longitude}
                            addressId={addressId}
                        />
                }
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TEAM_LIST}
                component={ProjectsTeamList}
            />
            <Route
                exact
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_CONTRACTORS_LIST}
                component={ObjectContractorsList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST}
                component={CrowdTaskRegistry}
            />
        </Switch>
    );
};

export default withRouter(ProjectObjectRoute);