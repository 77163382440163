import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import {
    checkPatentCard,
} from "../../controlDateReadOnly";
import validationSchema from "../validation";

import {convertStringToDate} from "../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

const useDataForm = (card) => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const initialValues = {
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]) || null,
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]: card[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES] || "",
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]: card[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM] || "",
        [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]: card[CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER] || "",
        [CONTRACTOR_FIELD.EDM_STATUS]: card[CONTRACTOR_FIELD.EDM_STATUS] || "",
        [CONTRACTOR_FIELD.STATUS]: card[CONTRACTOR_FIELD.STATUS] || "",
    };

    const toggleCard = () => {
        validateForm().then(() => {});

        if (isEdit && !isValid) {
            return;
        }

        if (isEdit) {
            handleSubmit();
        }

        setIsEdit(prevState => !prevState);
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setValues(initialValues);
    };

    const {
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        setValues,
        status,
        setStatus,
        dirty,
        errors,
        touched,
        isValid,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting}) => {
            // eslint-disable-next-line no-undef
            dispatch(updateContractor({
                ...card,
                ...values,
            }));

            setSubmitting(false);
        },
        validationSchema: validationSchema(),
        validateOnBlur: false,
        initialTouched: {
            [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]: true,
            [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]: true,
            [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]: true,
            [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]: true,
        },
    });

    useEffect(() => {
        const message = checkPatentCard({
            values,
            card: values,
        });

        setStatus({[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: message});
    }, []);

    useEffect(() => {
        if (dirty) {
            setStatus({});
        }
    }, [dirty]);

    const getError = (field) => {
        if (status && status[field]) {
            return status[field];
        }

        if (touched[field] && errors[field]) {
            return errors[field];
        }
    };

    return {
        values,
        setFieldTouched,
        setFieldValue,
        getError,
        isEdit,
        handleChange,
        handleBlur,
        cancelEdit,
        toggleCard,
    };
};

export default useDataForm;