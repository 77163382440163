import React, {FC, ReactNode} from "react";

import bem from "../../../utils/bem";

import "./style.sass";

interface AdaptiveIcon {
    children: ReactNode,
    className?: string,
    size?: AdaptiveIconSize,
}

export enum AdaptiveIconSize {
    md = "md",
    xl = "xl",
}

const AdaptiveIcon: FC<AdaptiveIcon> = (props) => {
    const {
        size = AdaptiveIconSize.md,
        className,
    } = props;

    const [block] = bem("adaptive-icon", className);

    return (
        <div
            className={block({
                size,
            })}
        >
            {props.children}
        </div>
    );
};

export default AdaptiveIcon;
