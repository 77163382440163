import React, {Component} from "react";
import Media from "react-media";

import Menu from "../../components/Menu";
import WebSocketComponent from "../../components/WebSocket";
import {LayoutsContent} from "./content";

import {NM_OPERATOR} from "../../constants/roles";

import "./style.sass";

class Layouts extends Component {
    render() {
        const {
            children,
            path,
            menuList,
            role,
        } = this.props;

        return (
            <div className="layouts">
                {[NM_OPERATOR].includes(role) && <WebSocketComponent />}
                <Media
                    queries={{
                        mobileTablet: "(max-width: 1199px)",
                        preDesktop: "(min-width: 1200px) and (max-width: 1599px)",
                    }}
                >
                    {
                        (matches) =>
                            <Menu
                                path={path}
                                menuList={menuList}
                                media={matches}
                            />
                    }
                </Media>
                <LayoutsContent>
                    {children}
                </LayoutsContent>
            </div>
        );
    }
}

export default Layouts;
