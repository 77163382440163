import {isAccessByRestrictions} from "./restrictions";

import {CLIENT_TYPE_CODE} from "../constants/dicts";
import {
    NM_COORDINATOR,
    NM_OPERATOR,
} from "../constants/roles";

export const RUS_PHONE_MASK = "+7 999 999 9999";
const FOREIGN_PHONE_MASK = "+9 999 999 9999";

export const clientPhoneMask = {
    [CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY]: FOREIGN_PHONE_MASK,
    [CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR]: RUS_PHONE_MASK,
    [CLIENT_TYPE_CODE.RUSSIAN_LEGAL_ENTITY]: RUS_PHONE_MASK,
};

/***
 * Метод для фильтрации ссылок взависимости от включенных настроек компании
 * и ограничений пользователя
 * @param item
 * @param checks - настройки компании
 * @param restrictions - ограничения пользователя
 * @returns {boolean}
 */
export const filterDynamicLinks = ({item, checks = {}}) => {
    const {
        edmAvailable = false,
        foremanEnabled = false,
        isAccessClientResources = false,
        insuranceAvailable = false,
        civilRegistryPaymentsAvailable = false,
        individualRegistryPaymentsAvailable = false,
        registryPaymentsAvailable = false,
        migrantLicensePaymentEnabled = false,
        withoutContract = false,
        isRecruitmentAvailable = false,
        crowdTasksAvailable = false,
        role,
    } = checks;

    const withoutContractAccessPages = [
        "edo",
        "patents",
        "reports",
        "company",
        "notifications",
    ];

    if (item.id === "edo" && !edmAvailable) {
        return false;
    }

    if (["insurance"].includes(item.id) && [NM_OPERATOR].includes(role)) {
        return false;
    }

    if (item.id === "brigade" && !foremanEnabled) {
        return false;
    }

    if (item.id === "resources" && !isAccessClientResources) {
        return false;
    }

    if (item.id === "insurance" && !insuranceAvailable) {
        return false;
    }

    if (
        item.id === "registryPayments"
        && !registryPaymentsAvailable
        && !civilRegistryPaymentsAvailable
        && !individualRegistryPaymentsAvailable
    ) {
        return false;
    }

    if (item.id === "cancelledChecks" && [NM_COORDINATOR].includes(role)) {
        return false;
    }

    if (item.id === "recruitment" && !isRecruitmentAvailable) {
        return false;
    }

    if (item.id === "crowd" && !crowdTasksAvailable) {
        return false;
    }

    if (item.id === "patents" && !migrantLicensePaymentEnabled) {
        return false;
    }

    if (withoutContract && !withoutContractAccessPages.includes(item.id)) {
        return false;
    }

    if (item.restrictionVariable) {
        return checkMenuItemRestrictions(item);
    }

    return true;
};

export const checkMenuItemRestrictions = (item) => {
    if (!item.restrictionVariable) {
        return true;
    }

    if (Array.isArray(item.restrictionVariable)) {
        return isAccessByRestrictions(item.restrictionVariable);
    }

    return isAccessByRestrictions([item.restrictionVariable]);
};