import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_NOTIFICATION_ADD_VIEW_ERROR,
    BFF_NOTIFICATION_ADD_VIEW_REQUEST,
    BFF_NOTIFICATION_ADD_VIEW_SUCCESS,
    BFF_NOTIFICATION_ADD_VIEWS_ERROR,
    BFF_NOTIFICATION_ADD_VIEWS_REQUEST,
    BFF_NOTIFICATION_ADD_VIEWS_SUCCESS,
    BFF_NOTIFICATION_GET_PAGE_ERROR,
    BFF_NOTIFICATION_GET_PAGE_REQUEST,
    BFF_NOTIFICATION_GET_PAGE_SUCCESS,
    BFF_NOTIFICATION_GET_UNREAD_COUNT_ERROR,
    BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST,
    BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {getBffControllerClientCardPage} from "../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        client: "/client-adm/notifications/notification-list",
        admin: "/adm/clients/client-card/notifications/notification-list",
    });
};

// POST bff/client-adm/notifications/notification-list/page
// POST bff/adm/clients/client-card/notifications/notification-list/page
// Получение страницы
export const getPageNotificationSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getSuccess();

        yield put({
            type: BFF_NOTIFICATION_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET bff/client-adm/notifications/notification-list/add-view
// GET bff/adm/clients/client-card/notifications/notification-list/add-view
export const addViewNotificationSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/add-view`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_ADD_VIEW_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({
            type: BFF_NOTIFICATION_ADD_VIEW_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_ADD_VIEW_ERROR,
            payload: error,
        });
    }
};

// POST bff/client-adm/notifications/notification-list/add-views
// POST bff/adm/clients/client-card/notifications/notification-list/add-views
export const addViewsNotificationSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-views`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_ADD_VIEWS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getSuccess();

        yield put({
            type: BFF_NOTIFICATION_ADD_VIEWS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_ADD_VIEWS_ERROR,
            payload: error,
        });
    }
};

// GET bff/client-adm/notifications/notification-list/unread-count
// GET bff/adm/clients/client-card/notifications/notification-list/unread-count
export const getNotificationUnreadCountSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getController()}/unread-count`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_NOTIFICATION_GET_UNREAD_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_NOTIFICATION_GET_UNREAD_COUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_NOTIFICATION_GET_PAGE_REQUEST, getPageNotificationSaga),
        takeEvery(BFF_NOTIFICATION_ADD_VIEW_REQUEST, addViewNotificationSaga),
        takeEvery(BFF_NOTIFICATION_ADD_VIEWS_REQUEST, addViewsNotificationSaga),
        takeEvery(BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST, getNotificationUnreadCountSaga),
    ]);
}