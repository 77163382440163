import {
    BFF_ADD_ADVERTISEMENT_DRAFT_ERROR,
    BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST,
    BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS,
    BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR,
    BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST,
    BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS,
    BFF_ADVERTISEMENT_CLOSE_ERROR,
    BFF_ADVERTISEMENT_CLOSE_REQUEST,
    BFF_ADVERTISEMENT_CLOSE_SUCCESS,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS,
    BFF_ADVERTISEMENT_ORDER_INVITE_ERROR,
    BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST,
    BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE,
    BFF_GET_ADVERTISEMENT_ERROR,
    BFF_GET_ADVERTISEMENT_PAGE_ERROR,
    BFF_GET_ADVERTISEMENT_PAGE_REQUEST,
    BFF_GET_ADVERTISEMENT_PAGE_SUCCESS,
    BFF_GET_ADVERTISEMENT_REQUEST,
    BFF_GET_ADVERTISEMENT_SUCCESS,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS,
    BFF_UPDATE_ADVERTISEMENT_ERROR,
    BFF_UPDATE_ADVERTISEMENT_REQUEST,
    BFF_UPDATE_ADVERTISEMENT_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    card: {
        baseModel: {},
    },
    contractorList: [],
    progress: false,
    progressAction: false,
    progressContractorList: false,
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    contractorsPageData: {
        pageSize: 25,
        pageNum: 1,
    },
    funnels: {
        list: [],
        pageData: {},
        progress: false,
        countMap: {},
        totalCount: 0,
    },
    contractorsTotalCount: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_GET_ADVERTISEMENT_PAGE_REQUEST:
            const {
                pageSize,
                pageNum,
            } = payload;

            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case BFF_GET_ADVERTISEMENT_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case BFF_GET_ADVERTISEMENT_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST:
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST:
        case BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST:
        case BFF_UPDATE_ADVERTISEMENT_REQUEST:
        case BFF_ADVERTISEMENT_CLOSE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS:
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS:
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR:
        case BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS:
        case BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR:
        case BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS:
        case BFF_ADD_ADVERTISEMENT_DRAFT_ERROR:
        case BFF_UPDATE_ADVERTISEMENT_SUCCESS:
        case BFF_UPDATE_ADVERTISEMENT_ERROR:
        case BFF_ADVERTISEMENT_CLOSE_SUCCESS:
        case BFF_ADVERTISEMENT_CLOSE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE:
            return {
                ...state,
                ...payload,
            };
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST:
        case BFF_GET_ADVERTISEMENT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_GET_ADVERTISEMENT_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS:
        case BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR:
        case BFF_GET_ADVERTISEMENT_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST:
            return {
                ...state,
                progressContractorList: true,
            };
        case BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS:
            const {
                results: contractorList,
                totalCount: contractorsTotalCount,
            } = payload;

            return {
                ...state,
                contractorList,
                contractorsTotalCount,
                progressContractorList: false,
            };
        case BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR:
            return {
                ...state,
                progressContractorList: false,
            };
        case BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST:
            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS: {
            const {
                contractors,
                totalCount,
                applicationCountMap,
            } = payload;

            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    totalCount,
                    list: contractors,
                    countMap: applicationCountMap,
                    progress: false,
                },
            };
        }
        case BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR: {
            return {
                ...state,
                funnels: {
                    ...state.funnels,
                    progress: false,
                },
            };
        }
        case BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST:
            return {
                ...state,
                progressAction: true,
                actionPageData: payload,
            };
        case BFF_ADVERTISEMENT_ORDER_INVITE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        default:
            return state;
    }
};