import {useMemo} from "react";

import {isDayOutOfYear} from "../../../../../../../../utils/dateFormat";

export const useContractorInfoAdditionalDocumentsVerificationWarnings = (params) => {
    const {
        values,
    } = params;

    const getMedicalBookIssuedDateWarning = () => {
        if (isDayOutOfYear(values.medicalBookIssuedDate)) {
            return "Срок действия документа истек";
        }

        return "";
    };

    const warnings = useMemo(() => {
        return {
            medicalBookIssuedDate: getMedicalBookIssuedDateWarning(),
        };
    }, [values]);

    return {
        warnings,
    };
};