import {
    BFF_NOTIFICATION_GET_PAGE_ERROR,
    BFF_NOTIFICATION_GET_PAGE_REQUEST,
    BFF_NOTIFICATION_GET_PAGE_SUCCESS,
    BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS,
} from "./actions";

const initialState = {
    notificationList: [],
    notificationPageData: {},
    notificationTotalCount: 0,
    unreadCount: 0,
    progress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_NOTIFICATION_GET_PAGE_REQUEST:
            const {getSuccess, ...notificationPageData} = payload;

            return {
                ...state,
                progress: true,
                notificationPageData,
            };
        case BFF_NOTIFICATION_GET_PAGE_SUCCESS:
            const {
                notificationList,
                totalCount: notificationTotalCount,
            } = payload;

            return {
                ...state,
                notificationList,
                progress: false,
                notificationTotalCount,
            };
        case BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS:
            return {
                ...state,
                unreadCount: payload,
            };
        case BFF_NOTIFICATION_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};
