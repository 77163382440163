import React from "react";
import {Switch} from "react-router-dom";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import {KedoTemplatesLog} from "../template-logs";
import {KedoTemplatesList} from "./components/list";

import {
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
} from "../../../constants/links";

export const TemplatesKedoRoute = () => {
    return (
        <Switch>
            <CheckRoleRoute
                path={LINK_TEMPLATES_KEDO_LIST}
                component={KedoTemplatesList}
            />
            <CheckRoleRoute
                exact
                path={LINK_TEMPLATES_KEDO_LOG}
                component={KedoTemplatesLog}
            />
        </Switch>
    );
};