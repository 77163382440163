import {all, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getOrdersController} from "./bff/orders/orders-registry/sagas";
import {getNonDecisionCount} from "./clientSpecialityOffer";
import {getContractorCount} from "./contractor";

import {getWorkAddressInfoArr} from "../utils/dadata";
import {
    ls,
    USER_ROLE,
} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request from "../utils/postman";
import {transformIndustryProfessions} from "../utils/professionHelper";
import {getFullName} from "../utils/stringFormat";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {
    getBffCommonController,
    getBffControllerClientCardPage,
    getBffUrl,
} from "../utils/url";

import {ORDER_STATUS} from "../constants/clientList";
import {LINK_APPLICATION_ANALYTICS} from "../constants/links";
import {ORDER} from "../constants/messages";
import {
    ADMIN,
    NM_MANAGER,
} from "../constants/roles";

const controller = "/orders";

export const FIELD_NAME_ORDER_LIST_ENUM = {
    TEMPLATE: {
        FIELD_LIST: "templatesListRich",
        PAGE_DATA: "pageDataTemplatesRich",
        TOTAL_COUNT: "totalCountTemplatesRich",
    },
    DEFAULT: {
        FIELD_LIST: "list",
        PAGE_DATA: "pageData",
        TOTAL_COUNT: "totalCount",
    },
};

export const ORDERS_ARCHIVED_SUCCESS_MSG = {
    pageData: {
        archived: "Заказ успешно добавлен в архив",
        unArchive: "Заказ успешно восстановлен",
    },
    pageDataTemplatesRich: {
        archived: "Шаблон заказов успешно добавлен в архив",
        unArchive: "Шаблон успешно восстановлен",
    },
};

//*  TYPES  *//

const ADD_ORDER_RECOMMEND_STAT_REQUEST = "ADD_ORDER_RECOMMEND_STAT_REQUEST";
const ADD_ORDER_RECOMMEND_STAT_SUCCESS = "ADD_ORDER_RECOMMEND_STAT_SUCCESS";
const ADD_ORDER_RECOMMEND_STAT_ERROR = "ADD_ORDER_RECOMMEND_STAT_ERROR";

const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
const ORDER_LIST_ERROR = "ORDER_LIST_ERROR";

const CONTRACTOR_STATUS_LIST_REQUEST = "CONTRACTOR_STATUS_LIST_REQUEST";
const CONTRACTOR_STATUS_LIST_SUCCESS = "CONTRACTOR_STATUS_LIST_SUCCESS";
const CONTRACTOR_STATUS_LIST_ERROR = "CONTRACTOR_STATUS_LIST_ERROR";

const ORDER_STATUS_LIST_REQUEST = "ORDER_STATUS_LIST_REQUEST";
const ORDER_STATUS_LIST_SUCCESS = "ORDER_STATUS_LIST_SUCCESS";
const ORDER_STATUS_LIST_ERROR = "ORDER_STATUS_LIST_ERROR";

const ORDER_UPDATE_FIELD_STORE = "ORDER_UPDATE_FIELD_STORE";

const CONTRACTOR_PROFESSIONS_REQUEST = "CONTRACTOR_PROFESSIONS_REQUEST";
const CONTRACTOR_PROFESSIONS_SUCCESS = "CONTRACTOR_PROFESSIONS_SUCCESS";

const CLEAR_ORDER_STORE = "CLEAR_ORDER_STORE";

const ORDER_INDUSTRY_PROFESSIONS_REQUEST = "ORDER_INDUSTRY_PROFESSIONS_REQUEST";
const ORDER_INDUSTRY_PROFESSIONS_SUCCESS = "ORDER_INDUSTRY_PROFESSIONS_SUCCESS";
const ORDER_INDUSTRY_PROFESSIONS_ERROR = "ORDER_INDUSTRY_PROFESSIONS_ERROR";

const ORDER_EDIT_REQUEST = "ORDER_EDIT_REQUEST";
const ORDER_EDIT_SUCCESS = "ORDER_EDIT_SUCCESS";
const ORDER_EDIT_ERROR = "ORDER_EDIT_ERROR";

const ORDER_TOGGLE_CARD = "ORDER_TOGGLE_CARD";

const ORDER_RICH_LIST_REQUEST = "ORDER_RICH_LIST_REQUEST";
const ORDER_RICH_LIST_SUCCESS = "ORDER_RICH_LIST_SUCCESS";
const ORDER_RICH_LIST_ERROR = "ORDER_RICH_LIST_ERROR";

const ORDER_TOGGLE_ARCHIVED = "ORDER_TOGGLE_ARCHIVED";

const GET_FOREMAN_PAYMENT_STATUSES_REQUEST = "GET_FOREMAN_PAYMENT_STATUSES_REQUEST";
const GET_FOREMAN_PAYMENT_STATUSES_SUCCESS = "GET_FOREMAN_PAYMENT_STATUSES_SUCCESS";
const GET_FOREMAN_PAYMENT_STATUSES_ERROR = "GET_FOREMAN_PAYMENT_STATUSES_ERROR";

const GET_FOREMAN_REQUEST_STATUSES_REQUEST = "GET_FOREMAN_REQUEST_STATUSES_REQUEST";
const GET_FOREMAN_REQUEST_STATUSES_SUCCESS = "GET_FOREMAN_REQUEST_STATUSES_SUCCESS";
const GET_FOREMAN_REQUEST_STATUSES_ERROR = "GET_FOREMAN_REQUEST_STATUSES_ERROR";

const GET_FOREMAN_REQUEST_TYPES_REQUEST = "GET_FOREMAN_REQUEST_TYPES_REQUEST";
const GET_FOREMAN_REQUEST_TYPES_SUCCESS = "GET_FOREMAN_REQUEST_TYPES_SUCCESS";
const GET_FOREMAN_REQUEST_TYPES_ERROR = "GET_FOREMAN_REQUEST_TYPES_ERROR";

const ORDER_CLEAR_FIELD_STORE = "ORDER_CLEAR_FIELD_STORE";

const GET_ORDER_WORK_UNITS_REQUEST = "GET_ORDER_WORK_UNITS_REQUEST";
const GET_ORDER_WORK_UNITS_SUCCESS = "GET_ORDER_WORK_UNITS_SUCCESS";
const GET_ORDER_WORK_UNITS_ERROR = "GET_ORDER_WORK_UNITS_ERROR";

const ORDER_LIST_FOR_TEMPLATES_REQUEST = "ORDER_LIST_FOR_TEMPLATES_REQUEST";

const GET_SEARCH_ORDER_PAGE_REQUEST = "GET_SEARCH_ORDER_PAGE_REQUEST";
const GET_SEARCH_ORDER_PAGE_SUCCESS = "GET_SEARCH_ORDER_PAGE_SUCCESS";
const GET_SEARCH_ORDER_PAGE_ERROR = "GET_SEARCH_ORDER_PAGE_ERROR";

const GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST = "GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST";
const GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS = "GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS";
const GET_DEPOSIT_HISTORY_APIS_DICT_ERROR = "GET_DEPOSIT_HISTORY_APIS_DICT_ERROR";

const SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST = "SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST";
const SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS = "SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS";
const SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR = "SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR";

const BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST = "UPDATE_ORDER_CONTRACTOR_PIN_REQUEST";
const BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS = "UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS";
const BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR = "UPDATE_ORDER_CONTRACTOR_PIN_ERROR";

const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST";
const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS";
const BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR = "BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR";

const BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST = "BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS = "BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS";
const BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR = "BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR";

const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS";
const BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR = "BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR";

const BFF_ORDER_MANAGERS_REQUEST = "BFF_ORDER_MANAGERS_REQUEST";
const BFF_ORDER_MANAGERS_SUCCESS = "BFF_ORDER_MANAGERS_SUCCESS";
const BFF_ORDER_MANAGERS_ERROR = "BFF_ORDER_MANAGERS_ERROR";

//*  INITIAL STATE  *//

const initial = {
    registryOrdersAmount: {
        commission: 0,
        amount: 0,
    },
    progressRegistryOrdersAmount: false,
    list: [],
    card: {},
    orders: [],
    events: [],
    orderFinance: {},
    listFinance: [],
    financePageData: {
        pageNum: 1,
        pageSize: 25,
        totalCount: 0,
    },
    isOpenOrderCard: false,
    historyPageData: {
        pageNum: 1,
        pageSize: 25,
        totalCount: 0,
    },
    pageData: {
        pageNum: 1,
        pageSize: 25,
        statusFilter: [ORDER_STATUS.DRAFT, ORDER_STATUS.PUBLISHED],
        isSearch: false,
    },
    contractorOrderList: [],
    totalCount: 0,
    totalCountContractorOrderList: 0,
    pageDataContractorOrderList: {
        pageNum: 1,
        pageSize: 25,
    },
    error: null,
    createdOrderInfo: {},
    isSuccessAdd: false,
    contractorStatusList: {},
    orderStatusList: {},
    industryProfessions: {},
    progress: false,
    updateContractorsFoundProgress: false,
    progressCard: false,
    professions: {},
    statusFilter: [],
    progressGetList: false,
    completeProgress: false,
    orderListByIds: [],
    registryOrders: [],
    progressRegistryOrders: false,
    registryOrdersPageData: {
        pageNum: 1,
        pageSize: 25,
    },
    registryOrdersTotalCount: 0,
    isOrdersArchived: false,
    foremanPaymentStatusesDict: [],
    foremanRequestStatusesDict: [],
    foremanRequestTypesDict: [],
    orderWorkUnitsDict: [],
    sharedOrder: {},
    progressUpdate: false,
    progressAction: false,
    orderTemplates: [],
    orderTemplatesProgress: false,
    checkMultipleProgress: true,
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.FIELD_LIST]: [],
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.TOTAL_COUNT]: 0,
    [FIELD_NAME_ORDER_LIST_ENUM.TEMPLATE.PAGE_DATA]: {},
    progressFinance: false,
    depositHistoryApisDict: {},
    orderManagers: [],
    applicationCountMap: {},
    orderContractorPageData: {},
    orderContractorList: [],
    orderContractorTotalCount: 0,
    orderContractorProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_ORDER_MANAGERS_SUCCESS:
            return {
                ...state,
                orderManagers: payload.results,
            };
        case GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS:
            return {
                ...state,
                depositHistoryApisDict: payload,
            };
        case ORDER_LIST_FOR_TEMPLATES_REQUEST:
            return {
                ...state,
                orderTemplatesProgress: true,
            };
        case ORDER_EDIT_REQUEST:
            return {
                ...state,
                createdOrderInfo: {},
                progressGetList: true,
                progressUpdate: true,
            };
        case ORDER_CLEAR_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_ORDER_WORK_UNITS_SUCCESS:
            return {
                ...state,
                orderWorkUnitsDict: payload,
            };
        case GET_FOREMAN_REQUEST_TYPES_SUCCESS:
            return {
                ...state,
                foremanRequestTypesDict: payload,
            };
        case GET_FOREMAN_REQUEST_STATUSES_SUCCESS:
            return {
                ...state,
                foremanRequestStatusesDict: payload,
            };
        case GET_FOREMAN_PAYMENT_STATUSES_SUCCESS:
            return {
                ...state,
                foremanPaymentStatusesDict: payload,
            };
        case ORDER_TOGGLE_CARD:
            return {
                ...state,
                isOpenOrderCard: payload,
            };
        case ORDER_INDUSTRY_PROFESSIONS_SUCCESS:
            return {
                ...state,
                industryProfessions: payload,
            };
        case ORDER_UPDATE_FIELD_STORE:
            const {
                field,
                value,
            } = payload;

            return {
                ...state,
                progress: false,
                [field]: value,
            };
        case CLEAR_ORDER_STORE:
            const {
                foremanPaymentStatusesDict,
                foremanRequestStatusesDict,
                foremanRequestTypesDict,
                industryProfessions,
                orderStatusList,
                contractorStatusList,
                orderWorkUnitsDict,
            } = state;

            return {
                ...initial,
                //Справочник не чистим
                orderWorkUnitsDict,
                contractorStatusList,
                orderStatusList,
                foremanPaymentStatusesDict,
                foremanRequestStatusesDict,
                foremanRequestTypesDict,
                industryProfessions,
            };
        case ORDER_STATUS_LIST_REQUEST:
            return {
                ...state,
                card: {},
            };
        case ORDER_EDIT_ERROR:
            return {
                ...state,
                progressGetList: false,
            };
        case CONTRACTOR_STATUS_LIST_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_RICH_LIST_REQUEST:
        case ORDER_LIST_REQUEST:
            return {
                ...state,
                progressGetList: true,
                pageData: {...payload},
            };

        case ORDER_EDIT_SUCCESS: {
            const {
                guid: orderId,
                name,
                status,
                orderNum,
                specialityId,
            } = payload;

            return {
                ...state,
                createdOrderInfo: {
                    orderId,
                    status,
                    name,
                    orderNum,
                    specialityId,
                },
                progressUpdate: false,
            };
        }
        case CONTRACTOR_PROFESSIONS_SUCCESS:
            return {
                ...state,
                professions: payload,
            };
        case CONTRACTOR_STATUS_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                contractorStatusList: payload,
            };
        case ORDER_STATUS_LIST_SUCCESS:
            return {
                ...state,
                progress: false,
                orderStatusList: payload,
            };
        case ORDER_RICH_LIST_SUCCESS:
        case ORDER_LIST_SUCCESS:
            const {
                nameField = "list",
                nameFieldTotalCount = "totalCount",
                orders,
                totalCount,
            } = payload;

            return {
                ...state,
                progressGetList: false,
                orderTemplatesProgress: false,
                [nameField]: orders,
                [nameFieldTotalCount]: totalCount,
            };
        case GET_SEARCH_ORDER_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
            };
        case GET_FOREMAN_REQUEST_TYPES_ERROR:
        case GET_FOREMAN_REQUEST_STATUSES_ERROR:
        case GET_FOREMAN_PAYMENT_STATUSES_ERROR:
        case ORDER_RICH_LIST_ERROR:
        case ORDER_LIST_ERROR:
            return {
                ...state,
                error: payload,
                progressGetList: false,
                orderTemplatesProgress: false,
            };
        case ORDER_INDUSTRY_PROFESSIONS_ERROR:
        case ORDER_STATUS_LIST_ERROR:
        case CONTRACTOR_STATUS_LIST_ERROR:
            return {
                ...state,
                error: payload,
            };
        case ORDER_TOGGLE_ARCHIVED:
            return {
                ...state,
                isOrdersArchived: payload,
            };
        case SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS:
        case SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS:
        case BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS:
        case BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST:
            return {
                ...state,
                orderContractorPageData: payload,
                orderContractorProgress: true,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS:
            return {
                ...state,
                orderContractorList: payload.contractors,
                orderContractorTotalCount: payload.totalCount,
                applicationCountMap: payload.applicationCountMap,
                orderContractorProgress: false,
            };
        case BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR:
            return {
                ...state,
                orderContractorProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export const addOrderRecommendStat = (payload) => ({
    type: ADD_ORDER_RECOMMEND_STAT_REQUEST,
    payload,
});

export function orderClearFieldStore(payload) {
    return {
        type: ORDER_CLEAR_FIELD_STORE,
        payload,
    };
}

export function getForemanRequestTypes() {
    return {
        type: GET_FOREMAN_REQUEST_TYPES_REQUEST,
    };
}

export function getForemanRequestStatues() {
    return {
        type: GET_FOREMAN_REQUEST_STATUSES_REQUEST,
    };
}

export function getForemanPaymentStatuses() {
    return {
        type: GET_FOREMAN_PAYMENT_STATUSES_REQUEST,
    };
}

export function getProfessions() {
    return {
        type: CONTRACTOR_PROFESSIONS_REQUEST,
    };
}

export function getIndustryProfessions() {
    return {
        type: ORDER_INDUSTRY_PROFESSIONS_REQUEST,
    };
}

export function updateFieldOrderStore(field, value) {
    return {
        type: ORDER_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function clearOrderStore() {
    return {
        type: CLEAR_ORDER_STORE,
    };
}

export function editOrder(result) {
    return {
        type: ORDER_EDIT_REQUEST,
        payload: result,
    };
}


export function getOrderList(pageData) {
    return {
        type: ORDER_LIST_REQUEST,
        payload: {...pageData},
    };
}

export function getOrderListForTemplates(payload) {
    return {
        type: ORDER_LIST_FOR_TEMPLATES_REQUEST,
        payload,
    };
}

export function getOrderRichList(pageData) {
    return {
        type: ORDER_RICH_LIST_REQUEST,
        payload: {...pageData},
    };
}

export function getContractorStatuses() {
    return {
        type: CONTRACTOR_STATUS_LIST_REQUEST,
    };
}

export function getOrderStatusList() {
    return {
        type: ORDER_STATUS_LIST_REQUEST,
    };
}

export function toggleOrderCard(payload) {
    return {
        type: ORDER_TOGGLE_CARD,
        payload,
    };
}

export function toggleOrdersArchived(payload) {
    return {
        type: ORDER_TOGGLE_ARCHIVED,
        payload,
    };
}

export function getOrderWorkUnits() {
    return {
        type: GET_ORDER_WORK_UNITS_REQUEST,
    };
}

export function getSearchOrderList(payload) {
    return {
        type: GET_SEARCH_ORDER_PAGE_REQUEST,
        payload,
    };
}

export function getDepositHistoryApisDict() {
    return {
        type: GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST,
    };
}

export function setEditAvailableOrderStatus(payload) {
    return {
        type: SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST,
        payload,
    };
}

export function updateOrderContractorPin(payload) {
    return {
        type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST,
        payload,
    };
}

export function rejectOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function declineOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function approveOrderContractorAllInvitations(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST,
        payload,
    };
}

export function getPageOrderContractors(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST,
        payload,
    };
}

export function getPageShortOrderContractors(payload) {
    return {
        type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST,
        payload,
    };
}

export function getOrderManagers(payload) {
    return {
        type: BFF_ORDER_MANAGERS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const orderSelector = state => state.order;
export const orderListSelector = createSelector(orderSelector, ({list}) => list);
export const orderListTaskIdsSelector = createSelector(orderSelector, ({list}) => list.map(value => `${value.clientId}:${value.orderId}`));
export const orderIsSearchSelector = createSelector(orderSelector, ({pageData: {isSearch}}) => isSearch);
export const getOrderListProgressSelector = createSelector(orderSelector, ({progressGetList}) => progressGetList);
export const contractorStatusSelector = createSelector(orderSelector, ({contractorStatusList}) => contractorStatusList);
export const orderProgressSelector = createSelector(orderSelector, ({progress}) => progress);
export const orderProgressCheckWarningBeforePublishSelector = createSelector(orderSelector, ({progressCheckWarningBeforePublish}) => progressCheckWarningBeforePublish);

export const orderIndustryProfessionsOptionsWithoutPostfixSelector = createSelector(orderSelector, ({industryProfessions}) => dictionaryToOptions(transformIndustryProfessions(industryProfessions, false)));

export const orderTotalCountSelector = createSelector(orderSelector, ({totalCount}) => totalCount);
export const orderTotalPagesSelector = createSelector(orderSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));

export const getOrderCardOpenSelector = createSelector(orderSelector, ({isOpenOrderCard}) => isOpenOrderCard);
export const foremanPaymentStatusesDictSelector = createSelector(orderSelector, ({foremanPaymentStatusesDict}) => foremanPaymentStatusesDict);
export const foremanRequestStatusesDictSelector = createSelector(orderSelector, ({foremanRequestStatusesDict}) => foremanRequestStatusesDict);
export const orderWorkUnitsOptionsSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    orderWorkUnitsDict.map(({name, orderWorkUnits}) => ({
        key: orderWorkUnits,
        text: name,
        value: orderWorkUnits,
    })),
);
export const unitsAbbreviationDictSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    new Map(
        orderWorkUnitsDict.map(({abbreviation, orderWorkUnits}) => [orderWorkUnits, abbreviation]),
    ),
);
export const orderWorkUnitsDictSelector = createSelector(orderSelector, ({orderWorkUnitsDict}) =>
    orderWorkUnitsDict.reduce((dict, value) => {
        const {orderWorkUnits, name} = value;

        dict[orderWorkUnits] = name;

        return dict;
    }, {}),
);
export const orderStatusSelector = createSelector(orderSelector, ({orderStatusList}) => orderStatusList);
export const orderProgressActionSelector = createSelector(orderSelector, ({progressAction}) => progressAction);
export const orderTemplatesOptionsSelector = createSelector(orderSelector, ({orderTemplates}) => orderTemplates.map(item => ({
    key: item.orderId,
    value: item.orderId,
    text: item.templateName,
})));
export const orderListOptionsSelector = createSelector(orderSelector, ({list}) => {
    return list.map(item => ({
        key: item.orderId,
        value: item.orderId,
        text: `№${item.orderNum} ${item.name}`,
    }));
},
);
export const depositHistoryApisDictSelector = createSelector(orderSelector, ({depositHistoryApisDict}) => depositHistoryApisDict);
export const orderManagersOptionsSelector = createSelector(orderSelector, ({orderManagers}) => {
    return orderManagers.map(item => {
        return {
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(
                item.lastName,
                item.firstName,
                item.patronymic,
            ),
        };
    });
});
export const orderContractorListSelector = createSelector(orderSelector, ({orderContractorList}) => {
    return orderContractorList.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});
export const orderContractorTotalPagesSelector = createSelector(orderSelector, ({
    orderContractorTotalCount,
    orderContractorPageData: {pageSize},
}) => getTotalPages(orderContractorTotalCount, pageSize));
export const orderContractorTotalCountSelector = createSelector(orderSelector, ({orderContractorTotalCount}) => orderContractorTotalCount);
export const orderContractorProgressSelector = createSelector(orderSelector, ({orderContractorProgress}) => orderContractorProgress);
export const orderApplicationCountMapSelector = createSelector(orderSelector, ({applicationCountMap}) => applicationCountMap);

//*  SAGA  *//

export const addOrderRecommendStatSaga = function* ({payload}) {
    try {
        const {
            data,
        } = payload;
        const result = yield request.post("/order/view/stat/recommends/add", data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ORDER_RECOMMEND_STAT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ADD_ORDER_RECOMMEND_STAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_ORDER_RECOMMEND_STAT_ERROR,
            payload: error,
        });
    }
};

export const orderListReadSaga = function* ({payload}) {
    try {
        const {
            nameField,
            nameFieldTotalCount,
            nameFieldPageData,
            ...reqData
        } = payload;
        const result = yield request.bff.post(`${getOrdersController()}/get-order-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_LIST_SUCCESS,
            payload: {
                nameField,
                nameFieldTotalCount,
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: ORDER_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/orderStatus
export const getOrderStatusListSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/orderStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_STATUS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/contractorStatus
export const getContractorStatusesReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/contractorStatus`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_STATUS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/profession
export const getContractorProfessionReadSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/profession`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_STATUS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_STATUS_LIST_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/foremanPaymentStatuses

export const getForemanPaymentStatusesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/foremanPaymentStatuses`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_PAYMENT_STATUSES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_PAYMENT_STATUSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_PAYMENT_STATUSES_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/getForemanRequestTypes

export const getForemanRequestTypesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/getForemanRequestTypes`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_REQUEST_TYPES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_REQUEST_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_REQUEST_TYPES_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/getForemanRequestStatuses

export const getForemanRequestStatusesSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/getForemanRequestStatuses`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FOREMAN_REQUEST_STATUSES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_FOREMAN_REQUEST_STATUSES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FOREMAN_REQUEST_STATUSES_ERROR,
            payload: error,
        });
    }
};

export const getIndustryProfessionsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/industryProfessions`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_INDUSTRY_PROFESSIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_INDUSTRY_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_INDUSTRY_PROFESSIONS_ERROR,
            payload: error,
        });
    }
};

export const editOrderSaga = function* ({payload}) {
    try {
        const {
            addDocumentFile,
            ...reqData
        } = payload;
        const result = yield request.post(`${controller}/edit`, reqData);

        const {errorMessage} = result;

        if (!errorMessage) {
            const {orderId, offeredSpecialityName} = reqData;

            addDocumentFile(orderId);

            toastSuccess(ORDER.ORDER_UPDATE_SUCCESS_TEXT);

            const {status, guid, orderNum} = result;
            const {name} = payload;

            const {
                clientId,
                specialityId,
                objectAddressFiasIds,
            } = reqData;

            const data = {
                clientId,
                specialityIdsFilter: specialityId ? [
                    specialityId,
                ] : undefined,
                remoteWorkFilter: null,
                strictAddressFiasIdsFilter: objectAddressFiasIds,
                orderSearchParams: {
                    orderId: guid,
                    searchByObjectAddressFiasIdsStrict: false,
                    searchByObjectAddressFiasIds: true,
                },
            };

            yield put(getContractorCount(data));

            yield put({
                type: ORDER_EDIT_SUCCESS,
                payload: {
                    status,
                    guid,
                    name,
                    orderNum,
                    specialityId,
                },
            });

            const state = yield select();
            yield put(getOrderRichList(state.order.pageData));

            const userRole = ls(USER_ROLE);

            // Обновляем счетчик справочника ВД, если предложен свой ВД
            if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
                yield put(getNonDecisionCount());
            }

        } else {
            toastError(errorMessage);

            yield put({
                type: ORDER_EDIT_ERROR,
                payload: result,
            });
        }
    } catch (error) {
        toastError(ORDER.ORDER_UPDATE_ERROR_TEXT);

        yield put({
            type: ORDER_EDIT_ERROR,
            payload: error,
        });
    }
};

export const orderRichListReadSaga = function* (action) {
    try {
        const {
            payload: {
                nameField = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.FIELD_LIST,
                nameFieldTotalCount = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.TOTAL_COUNT,
                ...data
            },
        } = action;
        const result = yield request.post(`${controller}/getRichPage`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_RICH_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_RICH_LIST_SUCCESS,
            payload: {
                ...result,
                nameField,
                nameFieldTotalCount,
            },
        });
    } catch (error) {
        yield put({
            type: ORDER_RICH_LIST_ERROR,
            payload: error,
        });
    }
};

export const getOrderWorkUnitsSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/orderWorkUnits`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_WORK_UNITS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_WORK_UNITS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_ORDER_WORK_UNITS_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/chats-search/page
export const getSearchOrderListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/chats-search/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SEARCH_ORDER_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_SEARCH_ORDER_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SEARCH_ORDER_PAGE_ERROR,
            payload: error,
        });
    }
};

//GET /api/orders/dicts/deposit/history/apis
export const getDepositHistoryApisDictSaga = function* () {
    try {
        const result = yield request.get(`${controller}/dicts/deposit/history/apis`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DEPOSIT_HISTORY_APIS_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_DEPOSIT_HISTORY_APIS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DEPOSIT_HISTORY_APIS_DICT_ERROR,
            payload: error,
        });
    }
};

//POST /api/orders/setEditAvailableStatus
// Перевод статуса заказа для возможности редактирования
export const setEditAvailableOrderStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/setEditAvailableStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SET_EDIT_AVAILABLE_ORDER_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SET_EDIT_AVAILABLE_ORDER_STATUS_ERROR,
            payload: error,
        });
    }
};

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/orders-registry",
        client: "/client-adm/orders/orders-registry",
    });
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/update/pin
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/update/pin
// Закрепление заявки в заказе
export const updateOrderContractorsPinSaga = function* ({payload}) {
    const {
        onSuccess = () => {
        },
        isClientCard = true,
        subpage,
        ...reqData
    } = payload;

    const url = getBffUrl(
        {
            [LINK_APPLICATION_ANALYTICS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/reports/order-analytics/responses",
                client: "/client-adm/reports/order-analytics/responses",
            }),
        },
        `${getController()}/card/contractors`,
        true,
    );

    try {
        const result = yield request.bff.post(`${url}/${subpage}/update/pin`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_UPDATE_ORDER_CONTRACTOR_PIN_ERROR,
        });
    }
};

// POST /bff/adm/common/job/reject-all-order-contractors
// POST /bff/client-adm/common/job/reject-all-order-contractors
// Массовый отзыв приглашений
export const orderContractorsRejectAllInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getBffCommonController()}/job/reject-all-order-contractors`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/decline-all
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/decline-all
// Массовый отзыв приглашений
export const declineAllOrderContractorsInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/order/contractors/decline-all`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/approve-all
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/approve-all
// Массовый отзыв приглашений
export const approveAllOrderContractorsInvitationsSaga = function* ({payload}) {
    const {
        onSuccess,
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/order/contractors/approve-all`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/get-page
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/get-page
export const getPageOrderContractorsSaga = function* ({payload}) {
    const {
        onSuccess = () => {
        },
        isClientCard = true,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/order/contractors/get-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/orders/orders-registry/order/contractors/get-applications-page
// POST /bff/adm/clients/client-card/orders/orders-registry/order/contractors/get-applications-page
export const getPageShortOrderContractorsSaga = function* ({payload}) {
    const {
        isClientCard = true,
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/order/contractors/get-applications-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/orders-registry/order-managers/page
// POST /bff/client-adm/orders/orders-registry/order-managers/page
// Получить список менеджеров для фильтра
export const getOrderManagersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/order-managers/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDER_MANAGERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDER_MANAGERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDER_MANAGERS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_LIST_REQUEST, orderListReadSaga),
        takeEvery(CONTRACTOR_STATUS_LIST_REQUEST, getContractorStatusesReadSaga),
        takeEvery(ORDER_STATUS_LIST_REQUEST, getOrderStatusListSaga),
        takeEvery(CONTRACTOR_PROFESSIONS_REQUEST, getContractorProfessionReadSaga),
        takeEvery(ORDER_INDUSTRY_PROFESSIONS_REQUEST, getIndustryProfessionsSaga),
        takeEvery(ORDER_EDIT_REQUEST, editOrderSaga),
        takeEvery(ORDER_RICH_LIST_REQUEST, orderRichListReadSaga),
        takeEvery(GET_FOREMAN_PAYMENT_STATUSES_REQUEST, getForemanPaymentStatusesSaga),
        takeEvery(GET_FOREMAN_REQUEST_STATUSES_REQUEST, getForemanRequestStatusesSaga),
        takeEvery(GET_FOREMAN_REQUEST_TYPES_REQUEST, getForemanRequestTypesSaga),
        takeEvery(GET_ORDER_WORK_UNITS_REQUEST, getOrderWorkUnitsSaga),
        takeEvery(ORDER_LIST_FOR_TEMPLATES_REQUEST, orderListReadSaga),
        takeEvery(ADD_ORDER_RECOMMEND_STAT_REQUEST, addOrderRecommendStatSaga),
        takeEvery(GET_SEARCH_ORDER_PAGE_REQUEST, getSearchOrderListSaga),
        takeEvery(GET_DEPOSIT_HISTORY_APIS_DICT_REQUEST, getDepositHistoryApisDictSaga),
        takeEvery(SET_EDIT_AVAILABLE_ORDER_STATUS_REQUEST, setEditAvailableOrderStatusSaga),
        takeEvery(BFF_UPDATE_ORDER_CONTRACTOR_PIN_REQUEST, updateOrderContractorsPinSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_REJECT_ALL_INVITATIONS_REQUEST, orderContractorsRejectAllInvitationsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_DECLINE_ALL_INVITATIONS_REQUEST, declineAllOrderContractorsInvitationsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_APPROVE_ALL_INVITATIONS_REQUEST, approveAllOrderContractorsInvitationsSaga),
        takeEvery(BFF_ORDER_MANAGERS_REQUEST, getOrderManagersSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_GET_PAGE_REQUEST, getPageOrderContractorsSaga),
        takeEvery(BFF_ORDER_CONTRACTOR_GET_PAGE_SHORT_REQUEST, getPageShortOrderContractorsSaga),
    ]);
}