import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../../../hooks/useFilter";

import {removePhoneMask} from "../../../../../../utils/stringFormat";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {bffDeferredActsOfAcceptanceWorkCardItemsStatusDictOptionsSelector} from "../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/selectors";

export const useDocumentsDeferredActsOfAcceptanceWorkCardFilter = (props) => {
    const {
        pageSize,
        setPagination,
    } = props;

    const statusOptions = useSelector(bffDeferredActsOfAcceptanceWorkCardItemsStatusDictOptionsSelector);

    const initFilter = {
        contractorNameFilter: "",
        contractorPhoneFilter: "",
        statusFilter: "",
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            contractorNameFilter,
            contractorPhoneFilter,
            statusFilter,
            ...otherFilters
        } = filter;

        return {
            contractorNameFilter: handleFormString(contractorNameFilter),
            contractorPhoneFilter: handleFormString(removePhoneMask(contractorPhoneFilter)),
            statusFilter: handleFormString(statusFilter),
            ...otherFilters,
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "contractorNameFilter",
                        label: "ФИО",
                        placeholder: "Введите ФИО",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhoneFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        label: "Статус строки",
                        placeholder: "Не выбран",
                        clearable: true,
                        compact: true,
                        name: "statusFilter",
                        options: statusOptions,
                    },
                ],
            },
        ];
    }, [
        statusOptions,
    ]);

    return {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    };
};