import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import DepositTypeText from "../../../../components/DepositTypeText";
import ExtLink from "../../../../components/ExtLink";
import IconDispute from "../../../../components/IconDispute";
import NmButton from "../../../../components/NmButton";
import NmIcon from "../../../../components/NmIcon";
import OrderSpecialityParagraph from "../../../../components/OrderSpecialityParagraph";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import ShareSocial from "../../../../components/ShareSocial";
import ViewsCount from "../../../../components/ViewsCount";
import OrderTemplateInfo from "../../template-info";
import OrderListNotificationIcon from "../order-list-notification-icon";
import OrderListStatus from "../order-list-status";

import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";
import {roundNumberWithPoint} from "../../../../utils/mathHelper";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {formatAmount, formatNumber} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {DEPOSIT_TYPE} from "../../../../constants/deposit";
import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../../constants/finance";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {
    ORDER_ACTION,
    ORDER_CONTEXT_MENU_MASS_ACTION_OPTION,
    ORDER_SOURCE_TYPE,
    ORDER_SOURCE_TYPES,
} from "../../../../constants/order";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../../constants/roles";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {clientCurrentMemberIdSelector, clientCurrentMemberSelector} from "../../../../ducks/clientMember";
import {jobTasksOrderCloseInfoSelector} from "../../../../ducks/job";
import {getOrderListProgressSelector} from "../../../../ducks/order";

import "./style.sass";

import {SELF_CONTRACTOR, SUB_PAGE_ORDER_CONTRACTOR} from "../../../../constants/link-params";

const styleButton = {
    width: 40,
    height: 40,
};

//ToDO: Переделать на обычные условия if
const getOptionsByOrderStatusAndRole = (status, role, optionList, isAccessActions) => {
    if (!isAccessActions) {
        return (
            status === ORDER_STATUS.CONTRACTOR_SEARCH
                ? [...optionList.completeSelection]
                : []
        );
    }

    switch (status) {
        case ORDER_STATUS.DRAFT: {
            switch (role) {
                case ADMIN:
                case NM_MANAGER:
                case CLIENT_ADMIN:
                case PROJECT_MANAGER:
                case OBJECT_MANAGER:
                case CLIENT_ACCOUNTANT:
                case FOREMAN: {
                    const {
                        deleteDraftOrder,
                        unArchiveOrder,
                        editOrder,
                    } = optionList;

                    return [
                        ...editOrder,
                        ...deleteDraftOrder,
                        ...unArchiveOrder,
                    ];
                }
                default:
                    return [];
            }
        }
        case ORDER_STATUS.CONTRACTOR_SEARCH: {
            const {
                editOrder,
                share,
                showHistory,
                closeOrder,
                completeSelection,
            } = optionList;

            switch (role) {
                case ADMIN:
                case NM_MANAGER:
                case CLIENT_ADMIN:
                case PROJECT_MANAGER:
                case OBJECT_MANAGER:
                case CLIENT_ACCOUNTANT:
                case FOREMAN: {
                    return [
                        ...editOrder,
                        ...share,
                        ...showHistory,
                        ...closeOrder,
                        ...completeSelection,
                    ];
                }
                case NM_COORDINATOR:
                case NM_OPERATOR:
                case NM_CHIEF_ACCOUNTANT:
                    return [
                        ...share,
                        ...showHistory,
                    ];
                default:
                    return [];
            }
        }
        case ORDER_STATUS.PUBLISHED: {
            const {
                editOrder,
                share,
                showHistory,
                closeOrder,
                getChecks,
            } = optionList;

            switch (role) {
                case ADMIN:
                case NM_MANAGER:
                case CLIENT_ADMIN:
                case PROJECT_MANAGER:
                case OBJECT_MANAGER:
                case CLIENT_ACCOUNTANT:
                case FOREMAN: {
                    return [
                        ...editOrder,
                        ...share,
                        ...showHistory,
                        ...closeOrder,
                        ...getChecks,
                    ];
                }
                case NM_COORDINATOR:
                case NM_OPERATOR:
                case NM_CHIEF_ACCOUNTANT: {
                    return [
                        ...share,
                        ...showHistory,
                        ...getChecks,
                    ];
                }
                default:
                    return [];
            }
        }
        case ORDER_STATUS.CLOSED: {
            const {
                share,
                showHistory,
                archiveOrder,
                unArchiveOrder,
                getChecks,
            } = optionList;

            switch (role) {
                case ADMIN:
                case NM_MANAGER:
                case CLIENT_ADMIN:
                case PROJECT_MANAGER:
                case OBJECT_MANAGER:
                case CLIENT_ACCOUNTANT:
                case FOREMAN: {
                    return [
                        ...share,
                        ...showHistory,
                        ...archiveOrder,
                        ...unArchiveOrder,
                        ...getChecks,
                    ];
                }
                case NM_COORDINATOR:
                case NM_OPERATOR:
                case NM_CHIEF_ACCOUNTANT: {
                    return [
                        ...share,
                        ...showHistory,
                        ...getChecks,
                    ];
                }
                default:
                    return [];
            }

        }
        default:
            return [];
    }
};

class OrderListTable extends Component {
    state = {
        selectedList: [],
        selectedCounts: {
            total: 0,
            publishedCount: 0,
            closedCount: 0,
            draftCount: 0,
            contractorSearchCount: 0,
        },
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {orders, isResetSelectedList, triggerIsResetSelectedList} = this.props;
        const {
            orders: _orders,
            isResetSelectedList: oldIsResetSelectedList,
        } = prevProps;

        if (!isEqual(orders, _orders)) {
            this.resetSelectedList();
        }

        if (isResetSelectedList && isResetSelectedList !== oldIsResetSelectedList) {
            this.resetSelectedList();
            triggerIsResetSelectedList();
        }
    }

    get isVisibleCheckbox() {
        const {
            isOrderGroupPage,
            projectId,
        } = this.props;

        if (isOrderGroupPage) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderListAddEditPublish,
            projectId && CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
        ].filter(Boolean));
    }

    get isAccessCompleteAction() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.publishOrder,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_publishOrders,
        ]);
    }

    get isAccessEditOrderInGroup() {
        const {
            isOrderGroupPage,
        } = this.props;

        if (!isOrderGroupPage) {
            return true;
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editOrderInGroup,
        ]);
    }

    get isAccessDisputeClient() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessDisputeMessage,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
        ]);
    }

    get isAccessMassActionOrder() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionOrder,
        ]);
    }

    renderRegistryLink = ({registryId, clientId, registryOrdinalNumber, registryName, registrySeqNum}) => {
        const {
            pageData,
            form,
        } = this.props;

        const registryCardLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", clientId)
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", registryId);

        return (
            <ExtLink
                pageData={pageData}
                filterData={form}
                historyEnabled
                to={registryCardLink}
            >
                {`№${registryOrdinalNumber} - ${registryName} (строка ${registrySeqNum})`}
            </ExtLink>
        );
    };

    renderLink = (content, {orderId, clientId, status, archived}) => {
        const {t} = this.props;

        if (
            (status === ORDER_STATUS.DRAFT && archived)
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderCard,
            ])
        ) {
            return (
                <span title={archived ? t("order-list.link-order") : ""}>
                    {content}
                </span>
            );
        }

        if (status === ORDER_STATUS.DRAFT) {
            const {handleOpenOrderEdit} = this.props;

            return (
                <span
                    onClick={() => {
                        handleOpenOrderEdit(orderId, ORDER_STATUS.DRAFT);
                    }}
                >
                    {content}
                </span>
            );
        }

        const link = LINK_ORDER_CARD.replace(":clientId", clientId);

        const linkOrder = link.replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        const {pageData, form} = this.props;

        return (
            <ExtLink
                pageData={pageData}
                filterData={form}
                historyEnabled
                to={linkOrder}
            >
                {content}
            </ExtLink>
        );
    };

    getOptions = (order, isMobile) => {
        const {
            status,
            edit,
            archived,
            forPaymentReport,
            orderId,
            clientId,
            orderSource,
        } = order;
        const {
            t,
            role,
            jobInfo,
            isAccessActions,
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        const isAccessEditPublishCloseOrder = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editPublishCloseOrder,
        ]);
        const blockedOrderClose = jobInfo
            ? (jobInfo.includes(`${clientId}:${orderId}`) && isAccessEditPublishCloseOrder) :
            false;

        const isAccessEditOrder = () => {
            if (isClientArchived) {
                return false;
            }

            return (
                !archived
                && edit
                && isAccessEditPublishCloseOrder
                && isAccessActions
                && isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderListAddEditPublish,
                ])
            );
        };

        const optionListForArchivedClient = {
            showHistory: [{
                key: ORDER_ACTION.SHOW_HISTORY,
                text: t("order-list.show-history"),
                value: ORDER_ACTION.SHOW_HISTORY,
            }],
            share: ![ORDER_SOURCE_TYPE.REGISTRY, ORDER_SOURCE_TYPE.API].includes(orderSource) ? [{
                key: ORDER_ACTION.SHARE,
                text: t("order-list.share"),
                value: ORDER_ACTION.SHARE,
            }] : [],
            closeOrder: !blockedOrderClose
            && this.isAccessEditOrderInGroup
            && isAccessEditPublishCloseOrder
                ? [{
                    key: ORDER_ACTION.CLOSE_ORDER,
                    text: t("order-list.complete-order"),
                    value: ORDER_ACTION.CLOSE_ORDER,
                }]
                : [],
            editOrder: [],
            getChecks: [],
            archiveOrder: [],
            unArchiveOrder: [],
            completeSelection: [],
            deleteDraftOrder: [],
        };

        const optionList = {
            ...optionListForArchivedClient,
            editOrder: isAccessEditOrder()
            && ![ORDER_SOURCE_TYPE.REGISTRY, ORDER_SOURCE_TYPE.API].includes(orderSource)
            && this.isAccessEditOrderInGroup
                ? [{
                    key: ORDER_ACTION.EDIT_ORDER,
                    text: t("order-list.edit-order"),
                    value: ORDER_ACTION.EDIT_ORDER,
                }]
                : [],
            getChecks: forPaymentReport
            && orderSource !== ORDER_SOURCE_TYPE.API
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_uploadOrderChecks,
            ])
                ? [{
                    key: ORDER_ACTION.GET_CHECKS,
                    text: t("order-list.get-checks"),
                    value: ORDER_ACTION.GET_CHECKS,
                }]
                : [],
            archiveOrder: (
                !archived
                && isAccessEditPublishCloseOrder
            )
                ? [{
                    key: ORDER_ACTION.ARCHIVE_ORDER,
                    text: t("order-list.archive-order"),
                    value: ORDER_ACTION.ARCHIVE_ORDER,
                }]
                : [],
            unArchiveOrder: (
                archived
                && isAccessEditPublishCloseOrder
            )
                ? [{
                    key: ORDER_ACTION.ARCHIVE_ORDER,
                    text: t("order-list.un-archive-order"),
                    value: ORDER_ACTION.ARCHIVE_ORDER,
                }]
                : [],
            deleteDraftOrder: (
                !archived
                && isAccessEditPublishCloseOrder
            )
                ? [{
                    key: ORDER_ACTION.DELETE_DRAFT_ORDER,
                    text: t("order-list.delete"),
                    value: ORDER_ACTION.DELETE_DRAFT_ORDER,
                }]
                : [],
            completeSelection: (
                orderSource !== ORDER_SOURCE_TYPE.API
                && this.isAccessCompleteAction
            )
                ? [{
                    key: ORDER_ACTION.COMPLETE_SELECTION,
                    text: t("order-list.complete-selection"),
                    value: ORDER_ACTION.COMPLETE_SELECTION,
                }]
                : [],
        };

        const currentOptionList = isClientArchived ? optionListForArchivedClient : optionList;

        const options = getOptionsByOrderStatusAndRole(status, role, currentOptionList, isAccessActions);

        return (
            (
                isMobile
                && this.isAccessEditOrderInGroup
                && this.isAccessDisputeClient
            )
                ? [
                    ...options,
                    {
                        key: ORDER_ACTION.GO_DISPUTES,
                        text: "В спор",
                        value: ORDER_ACTION.GO_DISPUTES,
                    },
                ]
                : options
        );
    };

    getAction = (item, isMobile) => {
        const {
            role,
        } = this.props;

        if (
            [NM_CONSULTANT].includes(role)
        ) {
            return null;
        }

        const options = this.getOptions(item, isMobile);

        if (!options.length) {
            return null;
        }

        const {onClickActionItem} = this.props;

        return (
            <div className="order-list-table__context-menu-container">
                {this.renderShare(item)}
                <ContextMenu
                    size="lg"
                    className="order-list-table__button-dropdown"
                    options={options}
                    onClickItem={(option) => {
                        onClickActionItem(option, item);
                    }}
                />
            </div>
        );
    };

    getNotifications = ({orderManagerId, unreadMessageCount, orderId, invitedToDispute}) => {
        const {
            currentUserId,
            goDisputes,
            role,
        } = this.props;

        return (
            <>
                {
                    (
                        Boolean(unreadMessageCount)
                        && (
                            orderManagerId === currentUserId
                            || (
                                [CLIENT_ADMIN].includes(role)
                                && Boolean(invitedToDispute)
                            )
                            || [ADMIN].includes(role)
                        )
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderList,
                        ])
                    ) ?
                        <OrderListNotificationIcon
                            isVisibleIndicator={unreadMessageCount}
                            className="order-list-table__notification"
                            count={unreadMessageCount}
                        >
                            <NmButton
                                onlyIcon
                                color="grey"
                                icon={<IconDispute />}
                                style={styleButton}
                                onClick={() => {
                                    goDisputes(orderId);
                                }}
                            />
                        </OrderListNotificationIcon> :
                        null
                }
            </>
        );
    };

    renderShare = ({orderId, clientId}) => {
        const {shareModalOrderId, toggleShareModal} = this.props;

        return shareModalOrderId === orderId &&
            <ShareSocial
                clientId={clientId}
                orderId={orderId}
                toggle={toggleShareModal}
            />;
    };

    renderActions = (order) => {
        return (
            <Media
                queries={{
                    mobile: "(max-width: 767px)",
                }}
            >
                {
                    (matches) => !matches.mobile ?
                        <>
                            {
                                (
                                    ![ORDER_SOURCE_TYPES.API].includes(order.orderSource)
                                    && this.isAccessEditOrderInGroup
                                    && this.isAccessDisputeClient
                                ) &&
                                this.getNotifications(order)
                            }
                            {this.getAction(order)}
                        </> :
                        <>
                            {this.getAction(order, matches.mobile)}
                        </>
                }
            </Media>
        );
    };

    onSelectedRows = (selectedList) => {
        const {updateSelectedSum} = this.props;

        const selectedOrders = selectedList.filter(value => value.isSelected);

        const countOrdersSelected = selectedOrders.length;

        const publishedCount = selectedOrders.filter(value => [ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH].includes(value.status)).length;
        const closedCount = selectedOrders.filter(value => [ORDER_STATUS.CLOSED].includes(value.status)).length;
        const draftCount = selectedOrders.filter(value =>
            [ORDER_STATUS.DRAFT].includes(value.status) &&
            ![ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(value.orderSource),
        ).length;
        const contractorSearchCount = selectedOrders.filter(value =>
            [ORDER_STATUS.CONTRACTOR_SEARCH].includes(value.status) &&
            ![ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(value.orderSource),
        ).length;

        const amountCalculateData = selectedOrders.reduce((sum, order) => {
            const {
                amount = 0,
                awaitingConfirmationTotalAmount = 0,
                notConfirmedTotalAmount = 0,
                inProgressTotalAmount = 0,
                paidSum = 0,
                paymentErrorTotalAmount = 0,
                remainderForPaymentsAmount = 0,
                limitSpentAmount = 0,
            } = sum;

            return {
                amount: order.amount + amount,
                awaitingConfirmationTotalAmount: order.awaitingConfirmationTotalAmount + awaitingConfirmationTotalAmount,
                notConfirmedTotalAmount: order.notConfirmedTotalAmount + notConfirmedTotalAmount,
                inProgressTotalAmount: order.inProgressTotalAmount + inProgressTotalAmount,
                paidSum: order.paidSum + paidSum,
                paymentErrorTotalAmount: order.paymentErrorTotalAmount + paymentErrorTotalAmount,
                remainderForPaymentsAmount: order.remainderForPaymentsAmount + remainderForPaymentsAmount,
                limitSpentAmount: order.limitSpentAmount + limitSpentAmount,
            };
        }, {});

        updateSelectedSum(amountCalculateData);

        this.setState({
            selectedList,
            selectedCounts: {
                total: countOrdersSelected,
                publishedCount,
                closedCount,
                draftCount,
                contractorSearchCount,
            },
        });
    };

    getProjectLink = (params) => {
        const {
            clientId,
            projectId,
            projectName,
        } = params;
        const {
            form,
            pageData,
        } = this.props;

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <ExtLink
                title={projectName}
                pageData={pageData}
                filterData={form}
                historyEnabled
                to={link}
            >
                {projectName}
            </ExtLink>
        );
    };

    getObjectLink = ({clientId, projectId, objectId, objectName}) => {
        if (!objectId) {
            return "-";
        }

        const {form, pageData} = this.props;

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                title={objectName}
                pageData={pageData}
                filterData={form}
                historyEnabled
                to={link}
            >
                {objectName}
            </ExtLink>
        );
    };

    resetSelectedList() {
        this.setState({
            selectedList: [],
            selectedCounts: {
                total: 0,
                publishedCount: 0,
                closedCount: 0,
                draftCount: 0,
            },
        });
    }

    renderIndicator = ({clientSpecialityOffer, status}) => {
        if (!clientSpecialityOffer || status !== ORDER_STATUS.DRAFT) {
            return null;
        }

        const {
            accepted,
            offeredSpecialityName,
        } = clientSpecialityOffer;

        if (accepted === null) {
            return null;
        }

        const acceptedText = accepted ? "Одобрил" : "Отклонил";

        return (
            <div className="order-list-table__indicator ps-0 pe-0">
                <NmAdvancedTooltip
                    hover
                    position="left"
                    trigger={
                        <NmIcon
                            className="order-list-table__indicator-icon"
                            name={accepted ? "like" : "dislike"}
                        />
                    }
                    header={{title: `Администратор Наймикс ${acceptedText} ваше предложение`}}
                    body={[{
                        textBlock: <>
                            Вид деятельности:
                            <span
                                className="order-list-table__indicator-value"
                            >
                                {offeredSpecialityName}
                            </span>
                        </>,
                    }]}
                    compound
                >
                    <div>
                        <div className="order-list-table__indicator-title">
                            Администратор Наймикс
                            {" "}
                            {acceptedText}
                            {" "}
                            ваше предложение
                        </div>
                        <div className="order-list-table__indicator-speciality">
                            Вид деятельности:
                            {" "}
                            <span
                                className="order-list-table__indicator-value"
                            >
                                {offeredSpecialityName}
                            </span>
                        </div>
                    </div>
                </NmAdvancedTooltip>
            </div>
        );
    };

    getRows = () => {
        const {
            t,
            orders,
            jobInfo,
            hideDetails,
        } = this.props;
        const {selectedList} = this.state;

        return orders.map(order => {
            const {
                orderId,
                status,
                createDate,
                amount,
                workStartDate,
                workEndDate,
                contractorsNeededCount,
                orderViewCount,
                orderNum,
                name,
                projectName,
                objectId,
                objectName,
                projectId,
                registryId,
                clientId,
                hiredCount,
                paidSum,
                awaitingConfirmationTotalAmount,
                notConfirmedTotalAmount,
                inProgressTotalAmount,
                paymentErrorTotalAmount,
                remainderForPaymentsAmount,
                limitSpentAmount,
                speciality,
                orderSource,
                orderContractPaymentType,
                clientSpecialityOffer,
                specialityId,
                frameContractTemplateId,
                frameContractTemplateName,
                frameContractTemplatePdfDownloadLink,
                orderApplicationTemplateId,
                orderApplicationTemplateName,
                orderApplicationTemplatePdfDownloadLink,
                actOfAcceptanceOfWorkTemplateId,
                actOfAcceptanceOfWorkTemplateName,
                actOfAcceptanceOfWorkTemplatePdfDownloadLink,
                clientAwaitingCount,
                depositType,
            } = order;
            const orderSelected = selectedList.find(item => (item.orderId === orderId)) || {};
            const {isSelected = false} = orderSelected;
            const hasBlockedOrderClose = jobInfo ? jobInfo.includes(`${clientId}:${orderId}`) : false;
            const totalAmount = amount ? formatNumber(formatAmount(roundNumberWithPoint(amount))) : t("is-not-specified.content-a");
            const payout = formatNumber(formatAmount(roundNumberWithPoint(paidSum || 0)));
            const isNotRegistryOrApiOrder = ![ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(orderSource);

            return {
                key: orderId,
                status,
                awaitingConfirmationTotalAmount,
                notConfirmedTotalAmount,
                inProgressTotalAmount,
                paidSum,
                paymentErrorTotalAmount,
                remainderForPaymentsAmount,
                limitSpentAmount,
                orderId,
                name,
                orderNum,
                clientId,
                orderSource,
                registryId,
                amount,
                actOfAcceptanceOfWorkTemplateId: order.actOfAcceptanceOfWorkTemplateId,
                orderApplicationTemplateId: order.orderApplicationTemplateId,
                frameContractTemplateId: order.frameContractTemplateId,
                disabledCheckBox: hasBlockedOrderClose,
                showCheckBox: isNotRegistryOrApiOrder ||
                    !isNotRegistryOrApiOrder &&
                    [ORDER_STATUS.CLOSED, ORDER_STATUS.CONTRACTOR_SEARCH, ORDER_STATUS.PUBLISHED].includes(status),
                isSelected,
                orderContractPaymentType,
                contentRow: (
                    <NmListCard
                        checkbox
                        primaryHeaderLink
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={
                            <div className="flex flex-aligned-center">
                                <div className="me-2">
                                    {`Заказ от ${createDate ? dateFormat(convertUtcToLocal(createDate), "dd.MM.yyyy HH:mm") : t("is-not-specified.content-o")}`}
                                </div>
                                {isNotRegistryOrApiOrder && <ViewsCount count={orderViewCount} />}
                            </div>
                        }
                        secondaryHeaderStatus={
                            <OrderListStatus
                                status={status}
                            />
                        }
                        primaryHeader={this.renderLink(`№${orderNum} - ${name}`, order)}
                        labels={[
                            !isNullOrWhitespace(registryId) && !hideDetails && {
                                label: "Реестр оплат",
                                text: this.renderRegistryLink(order),
                            },
                            !hideDetails && {
                                label: "Проект",
                                text: this.getProjectLink({clientId, projectId, projectName}),
                            },
                            !hideDetails && {
                                label: "Объект",
                                text: this.getObjectLink({projectId, objectId, objectName, clientId}),
                            },
                            !hideDetails && {
                                text: (
                                    <OrderSpecialityParagraph
                                        show={specialityId || clientSpecialityOffer}
                                        speciality={{speciality, clientSpecialityOffer}}
                                    />
                                ),
                            },
                            {
                                label: "Договор",
                                text: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType],
                            },
                            depositType && {
                                label: "Депозит",
                                text: (
                                    <DepositTypeText
                                        depositType={depositType}
                                        clientId={order.clientId}
                                        objectId={order.objectId}
                                        objectName={order.objectName}
                                        projectId={order.depositProjectId}
                                        projectName={order.depositProjectName}
                                    />
                                ),
                            },
                            {
                                label: "Шаблон договора",
                                text: (
                                    <OrderTemplateInfo
                                        id={frameContractTemplateId}
                                        linkName={frameContractTemplateName}
                                        link={frameContractTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                            {
                                label: "Шаблон заявки",
                                text: (
                                    <OrderTemplateInfo
                                        id={orderApplicationTemplateId}
                                        linkName={orderApplicationTemplateName}
                                        link={orderApplicationTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                            {
                                label: "Шаблон акта",
                                text: (
                                    <OrderTemplateInfo
                                        id={actOfAcceptanceOfWorkTemplateId}
                                        linkName={actOfAcceptanceOfWorkTemplateName}
                                        link={actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-md-16 col-xxl-8 mt-md-4 mt-xxl-0"
                        cards={[
                            {
                                title: "План по выплатам, ₽",
                                className: "col-16 col-md-5",
                                values: [{bold: true, text: payout}, {text: totalAmount}],
                            },
                            {
                                title: "План по исполнителям",
                                className: "col-16 col-md-4",
                                values: [{bold: true, text: hiredCount}, {text: contractorsNeededCount}],
                                overflowEllipseText: true,
                            },
                            {
                                title: "Ожидают",
                                className: "col-16 col-md-3",
                                value: clientAwaitingCount,
                            },
                            {
                                title: "Период работ",
                                className: "col-16 col-md-4",
                                value: `${dateFormat(workStartDate, "dd.MM.yy")} - ${dateFormat(workEndDate, "dd.MM.yy")}`,
                                wrapValue: true,
                            },
                        ]}
                        actionsClassName="col-1"
                        actions={this.renderActions(order)}
                        otherContent={this.renderIndicator(order)}
                    />
                ),
            };
        });
    };

    onClickMassActionContextMenu = (option) => {
        const {selectedList} = this.state;
        const {onClickMassActionContextMenu} = this.props;

        onClickMassActionContextMenu(option, selectedList);
    };

    getMassActionContextMenuOptions() {
        const {
            isOrderGroupPage,
            currentMember,
            role,
            isAccessActions,
        } = this.props;
        const {
            selectedCounts,
        } = this.state;

        if ([NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)) {
            return null;
        }

        // Кнопка "Закрыть заказы" отображается, если выбран хотя бы 1 заказ в статусе "В работе" или "Поиск исполнителей"
        const closeOrders = selectedCounts.publishedCount > 0
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
        ])
            ? [ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.CLOSE_ORDERS]
            : [];
        // Кнопка "Архивировать закрытые заказы" отображается, если выбран хотя бы 1 заказ в статусе "Закрыт"
        const archiveOrders = selectedCounts.closedCount > 0 ?
            [ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.ARCHIVE_CLOSED_ORDERS] :
            [];
        // Кнопка "Удалить черновики" отображается, если выбран хотя бы 1 заказ в статусе "Черновик"
        const deleteDrafts = selectedCounts.draftCount > 0 ?
            [ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.DELETE_DRAFTS] :
            [];
        // Кнопки "Опубликовать в статус "Поиск исполнителей"" и "Опубликовать в статус "В работе"" отображаются, если выбран хотя бы 1 заказ в статусе "Черновик"
        const publishOrders = (
            selectedCounts.draftCount > 0 ||
            [
                FOREMAN,
            ].includes(role)
            && currentMember.ordersPublicationProhibited
        )
            ? [
                isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOrderListAddEditPublish,
                ]) && ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.PUBLISH_ORDERS,
                isAccessActions && ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.PUBLISH_ORDERS_IN_WORK,
            ].filter(Boolean)
            : [];

        // Кнопка "Завершить подбор" отображается, если выбран хотя бы 1 заказ в статусе "Поиск исполнителей"
        const completeSelection = (
            selectedCounts.contractorSearchCount > 0
            && this.isAccessCompleteAction
        )
            ? [ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.COMPLETE_SELECTION]
            : [];

        // Кнопка "Удалить из группы" отображается на странице карточки группы заказов, если выбран хотя бы 1 заказ
        const deleteOrdersFromGroup = isOrderGroupPage
        && selectedCounts.total > 0
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessMassActionGroupOrder,
        ])
            ? [ORDER_CONTEXT_MENU_MASS_ACTION_OPTION.DELETE_ORDERS_FROM_GROUP]
            : [];

        if (!this.isAccessMassActionOrder || !isAccessActions) {
            return [
                ...publishOrders,
                ...completeSelection,
            ];
        }

        return [
            ...closeOrders,
            ...archiveOrders,
            ...deleteDrafts,
            ...publishOrders,
            ...completeSelection,
            ...deleteOrdersFromGroup,
        ];
    }

    getMassActionContextMenu() {
        return (
            <ContextMenu
                size="lg"
                inline={false}
                onClickItem={this.onClickMassActionContextMenu}
                options={this.getMassActionContextMenuOptions()}
            />
        );
    }

    getMassActions() {
        const {role} = this.props;
        const {selectedCounts} = this.state;

        return (
            ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role) &&
            <div className="order-list-table__context-menu-container order-list-table__context-menu-container_fluid">
                {
                    selectedCounts.total === 0 ?
                        <NmAdvancedTooltip
                            type="light"
                            position="bottom-right"
                            trigger={this.getMassActionContextMenu()}
                            children="Нет доступных действий (вы не выбрали заказ)"
                        /> :
                        this.getMassActionContextMenu()
                }
            </div>
        );
    }

    render() {
        const {
            progressGetList,
            client: {
                archived: isArchivedClient,
            },
            archivedFilter,
            role,
        } = this.props;
        const {selectedCounts} = this.state;

        return (
            <CheckboxList
                loading={progressGetList}
                className="order-list-table"
                header={
                    this.isVisibleCheckbox &&
                    !archivedFilter &&
                    <SelectionCountWithAction
                        adaptiveLogic
                        count={selectedCounts.total}
                        otherActions={this.getMassActions()}
                    />
                }
                disabledMobileContextMenu={selectedCounts.total === 0}
                rows={this.getRows()}
                checkBoxClassName="order-list-table__checkbox"
                headerCheckBoxClassName="order-list-table__header-checkbox"
                onSelectedRows={![NM_OPERATOR, RECRUITER].includes(role) && !isArchivedClient && !archivedFilter && this.onSelectedRows}
                actionOptions={this.getMassActionContextMenuOptions()}
                onClickActionItem={this.onClickMassActionContextMenu}
                withCheckbox={this.isVisibleCheckbox}
            />
        );
    }
}

export default connect(
    state => ({
        currentUserId: clientCurrentMemberIdSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        progressGetList: getOrderListProgressSelector(state),
        jobInfo: jobTasksOrderCloseInfoSelector(state),
        client: clientCardInfoSelector(state),
    }),
    null,
)(withTranslation()(OrderListTable));