import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {downloadBlob} from "../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../utils/localstorage";
import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

/*TYPES*/
const GET_LIST_EXPORT_PATENT_REQUEST = "GET_LIST_EXPORT_PATENT_REQUEST";
const GET_LIST_EXPORT_PATENT_SUCCESS = "GET_LIST_EXPORT_PATENT_SUCCESS";

const DOWNLOAD_PATENT_EXPORT_DOCUMENTS_REQUEST = "DOWNLOAD_PATENT_EXPORT_DOCUMENTS_REQUEST";
const DOWNLOAD_PATENT_EXPORT_DOCUMENTS_SUCCESS = "DOWNLOAD_PATENT_EXPORT_DOCUMENTS_SUCCESS";

//*  INITIAL STATE  *//
const initial = {
    exportDocumentList: [],
    pageData: {},
    totalCount: 0,
    statusDict: {},
};

//*  REDUCER  *//
export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_LIST_EXPORT_PATENT_REQUEST:
            const {pageSize, pageNum} = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_LIST_EXPORT_PATENT_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                exportDocumentList: results,
                totalCount,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export function getExportPatentPage(payload) {
    return {
        type: GET_LIST_EXPORT_PATENT_REQUEST,
        payload,
    };
}

export function downloadExportDocument(payload) {
    return {
        type: DOWNLOAD_PATENT_EXPORT_DOCUMENTS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//
export const patentsDocumentsExportSelector = state => state.patentsDocumentsExport;
export const patentsDocumentsExportListSelector = createSelector(patentsDocumentsExportSelector, ({exportDocumentList}) => exportDocumentList);
export const patentsDocumentsExportTotalPagesSelector = createSelector(patentsDocumentsExportSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));
export const patentsDocumentsExportTotalCountSelector = createSelector(patentsDocumentsExportSelector, ({totalCount}) => totalCount);

//* SAGAS *//

const getListExportPatentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/patents/export/page",
            client: "/client-adm/client/patents/export/page",
        });

        const result = yield request.bff.post(url, data);

        yield put({type: GET_LIST_EXPORT_PATENT_SUCCESS, payload: result});
    } catch (error) {
        console.error("error getListExportPatentSaga: ", error.message);
    }
};
// /GET /api/filestore/patent/documents/get
const downloadExportDocumentSaga = function* ({payload}) {
    const {
        exportFileName,
        downloadLink,
    } = payload;
    try {
        const initParams = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "application/octet-stream",
            },
        };

        const result = yield request.getFile(downloadLink, initParams);

        const {headers} = result;

        if (result.status === 200 && result.headers.get("Content-Type") && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();

        yield downloadBlob(blob, headers, exportFileName);

        yield put({type: DOWNLOAD_PATENT_EXPORT_DOCUMENTS_SUCCESS, payload: result});
    } catch (error) {
        console.error("error downloadExportDocumentSaga: ", error.message);
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_LIST_EXPORT_PATENT_REQUEST, getListExportPatentSaga),
        takeEvery(DOWNLOAD_PATENT_EXPORT_DOCUMENTS_REQUEST, downloadExportDocumentSaga),
    ]);
}