import {all, call, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastSuccess} from "../utils/toastHelper";
import {toastError} from "../utils/toastHelper";

const controller = "/clientgroups";
//*  TYPES  *//

const GET_CLIENT_GROUPS_PAGE_REQUEST = "GET_CLIENT_GROUPS_PAGE_REQUEST";
const GET_CLIENT_GROUPS_PAGE_SUCCESS = "GET_CLIENT_GROUPS_PAGE_SUCCESS";
const GET_CLIENT_GROUPS_PAGE_ERROR = "GET_CLIENT_GROUPS_PAGE_ERROR";

//*  INITIAL STATE  *//

const initial = {
    clientGroupList: [],
    error: {},
    clientGroupTotalCount: 0,
    clientGroupPageData: {},
    clientsList: [],
    clientsTotalCount: 0,
    clientsPageData: {},
    contractorList: [],
    contractorTotalCount: 0,
    contractorPageData: {},
    clientGroupCard: {},
    actionProgress: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CLIENT_GROUPS_PAGE_REQUEST:
            return {
                ...state,
                clientGroupPageData: payload,
            };
        case GET_CLIENT_GROUPS_PAGE_SUCCESS:
            const {
                results: clientGroupList,
                totalCount: clientGroupTotalCount,
            } = payload;

            return {
                ...state,
                clientGroupList,
                clientGroupTotalCount,
            };
        case GET_CLIENT_GROUPS_PAGE_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientGroups(payload) {
    return {
        type: GET_CLIENT_GROUPS_PAGE_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientGroupsSelector = ({clientGroups}) => clientGroups;
export const clientGroupsListOptionsSelector = createSelector(clientGroupsSelector,
    ({clientGroupList}) => clientGroupList.map(({groupId, groupName}) => {
        return {
            key: groupId,
            text: groupName,
            value: groupId,
        };
    }));

//*  SAGA  *//

//POST /api/clientgroups/getGroupsPage
const getClientGroupsSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...requestData
        } = payload;

        const result = yield request.post(`${controller}/getGroupsPage`, requestData);

        const {errorMessage} = result;

        yield getResult(errorMessage);

        if (errorMessage) {
            console.error("getClientGroupsSaga: ", errorMessage);
            yield put({
                type: GET_CLIENT_GROUPS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_GROUPS_PAGE_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_GROUPS_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_GROUPS_PAGE_REQUEST, getClientGroupsSaga),
    ]);
}
