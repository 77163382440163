import {
    BFF_ADD_REGISTRY_INVITATIONS_REQUEST,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_REGISTRY_INVITATIONS_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST,
} from "./actions";

export function getRegistryInvitationsPage(payload) {
    return {
        type: BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST,
        payload,
    };
}

export function archivedRegistryInvitations(payload) {
    return {
        type: BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function updateRegistryInvitations(payload) {
    return {
        type: BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function addRegistryInvitations(payload) {
    return {
        type: BFF_ADD_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function deleteRegistryInvitations(payload) {
    return {
        type: BFF_DELETE_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}