import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getActRegistries} from "../../../../ducks/bff/orders/order-act-registries/actionCreators";

export const useOrderActRegistryFetch = (params) => {
    const {
        archived,
        pageNum,
        pageSize,
        filter,
        clientId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        onFetchList();
    }, [pageNum, pageSize, filter, archived]);

    const onFetchList = () => {
        dispatch(getActRegistries({
            ...filter,
            clientId,
            archivedFilter: archived,
            pageNum,
            pageSize,
        }));
    };

    return {
        onFetchList,
    };
};