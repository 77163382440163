import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import dateFormat from "../../../utils/dateFormat";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

import {createRegistryPaymentDeferredActs} from "../../../ducks/bff/clients/registry-payment/actionCreators";
import {registriesActionRegistryProgressSelector} from "../../../ducks/bff/clients/registry-payment/selectors";

export const CreateRegistryPaymentDeferredActs = (props) => {
    const {
        onClose,
        clientId,
        paymentRegistryId,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(registriesActionRegistryProgressSelector);

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
    } = useFormik({
        onSubmit,
        initialValues: {
            actDate: null,
        },
        validationSchema: yup.object().shape({
            actDate: yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
        }),
        enableReinitialize: true,
        validateOnBlur: false,
    });

    function onSubmit() {
        dispatch(createRegistryPaymentDeferredActs({
            clientId,
            paymentRegistryId,
            actDate: dateFormat(values.actDate, "yyyy-MM-dd"),
            onSuccess: () => {
                onClose();
            },
        }));
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Выберите дату создания отложенных актов выполненных работ
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progress}
                    onClose={onClose}
                    submitBtnContent="Добавить"
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            loading={progress}
        >
            <NmDatePicker
                size="xl"
                label="Дата акта"
                required={true}
                dateFormat="dd.MM.yy"
                selected={values.actDate}
                maxDate={new Date()}
                error={touched.actDate && errors.actDate}
                name="actDate"
                onChange={(_event, {value}) => {
                    setFieldValue("actDate", value);
                }}
            />
        </NmModal>
    );
};