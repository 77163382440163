import {useDispatch, useSelector} from "react-redux";

import {useSuccessActionCallback} from "../../../../../../hooks/useSuccessAction";
import {useDeferredActsOfAcceptanceWorkAction} from "../../../hooks/useAction";

import {isAccessByRestrictions} from "../../../../../../utils/restrictions";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";
import {DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS} from "../../../../../../constants/documentType";

import {
    deleteDeferredActsOfAcceptanceWorkCardItems,
    updateDeferredActsOfAcceptanceWorkCardStore,
} from "../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";
import {
    bffDeferredActsOfAcceptanceWorkCardErrorSelector,
    bffDeferredActsOfAcceptanceWorkCardProgressActionSelector,
} from "../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/selectors";
import {downloadDocument} from "../../../../../../ducks/documents";

export const useDocumentsDeferredActsOfAcceptanceWorkCardAction = (props) => {
    const {
        clientId,
        registryId,
        selectedList,
        countSelected,
        clearSelectedRows,
    } = props;

    const dispatch = useDispatch();

    const error = useSelector(bffDeferredActsOfAcceptanceWorkCardErrorSelector);
    const progressAction = useSelector(bffDeferredActsOfAcceptanceWorkCardProgressActionSelector);

    const isActionPostActRegistryItem = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionPostActRegistryItem,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionPostActRegistryItem,
    ]);

    const {
        modalData,
        onCloseModal,
        onOpenModal,
        getMediaControls,
    } = useDeferredActsOfAcceptanceWorkAction({
        isCard: true,
        registryId,
        clientId,
    });

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateDeferredActsOfAcceptanceWorkCardStore({
                    error: null,
                }));
            }

            onCloseModal();
            clearSelectedRows();
        },
        progressAction,
        error,
    });

    const getListMediaControls = (item) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenItemEditForm: true,
                            editData: item,
                        }),
                        children: "Редактировать",
                    },
                    visible: [
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.CREATED,
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.SUBMIT_ERROR,
                    ].includes(item.status?.value)
                    && isActionPostActRegistryItem,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            dispatch(downloadDocument({
                                isDownload: false,
                                downloadLink: item.actDownloadLink,
                            }));
                        },
                        children: "Отложенный акт выполненных работ",
                    },
                    visible: [
                        DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.SIGNED,
                    ].includes(item.status?.value)
                        && Boolean(item.actDownloadLink),
                },
            ],
        };
    };

    const getMobileMassActionOptions = () => {
        return [
            {
                key: "delete",
                value: "delete",
                text: "Удалить строку",
                onClick: openConfirmDeleteSelected,
                disabled: countSelected === 0,
                visible: isActionPostActRegistryItem,
            },
            {
                key: "clear",
                value: "clear",
                text: "Очистить",
                onClick: clearSelectedRows,
                disabled: countSelected === 0,
            },
        ];
    };

    const openConfirmDeleteSelected = () => {
        onOpenModal({
            confirmProps: {
                content: "Вы действительно хотите удалить выбранные строки?",
                onConfirm: deleteItems,
            },
        });
    };

    const deleteItems = () => {
        const seqNums = selectedList.filter(({isSelected}) => isSelected).map(({seqNum}) => seqNum);

        dispatch(deleteDeferredActsOfAcceptanceWorkCardItems({
            clientId,
            registryId,
            seqNums,
        }));
    };

    return {
        modalData,
        onOpenModal,
        onCloseModal,
        getListMediaControls,
        getMediaControls,
        deleteItems,
        openConfirmDeleteSelected,
        mobileMassActionOptions: getMobileMassActionOptions(),
        isActionPostActRegistryItem,
    };
};