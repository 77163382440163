export const CLIENT_PROJECTS_CARD_GET_REQUEST = "CLIENT_PROJECTS_CARD_GET_REQUEST";
export const CLIENT_PROJECTS_CARD_GET_SUCCESS = "CLIENT_PROJECTS_CARD_GET_SUCCESS";
export const CLIENT_PROJECTS_CARD_GET_ERROR = "CLIENT_PROJECTS_CARD_GET_ERROR";

export const CLIENT_PROJECTS_CARD_GET_USERS_REQUEST = "CLIENT_PROJECTS_CARD_GET_USERS_REQUEST";
export const CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS = "CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS";
export const CLIENT_PROJECTS_CARD_GET_USERS_ERROR = "CLIENT_PROJECTS_CARD_GET_USERS_ERROR";

export const CLIENT_PROJECTS_CARD_ADD_USER_REQUEST = "CLIENT_PROJECTS_CARD_ADD_USER_REQUEST";
export const CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS = "CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS";
export const CLIENT_PROJECTS_CARD_ADD_USER_ERROR = "CLIENT_PROJECTS_CARD_ADD_USER_ERROR";

export const CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST = "CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST";
export const CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS = "CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS";
export const CLIENT_PROJECTS_CARD_DELETE_USER_ERROR = "CLIENT_PROJECTS_CARD_DELETE_USER_ERROR";

export const CLIENT_PROJECTS_CARD_UPDATE_STORE = "CLIENT_PROJECTS_UPDATE_STORE";

export const CLIENT_PROJECTS_CARD_CLEAR_STORE = "CLIENT_PROJECTS_CLEAR_STORE";