import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {ClientSettingsContext} from "../../ContextProvider";

import {useModal} from "../../../../../../hooks/useModal";

import {toastSuccess} from "../../../../../../utils/toastHelper";
import {getClientSettingsOtherRequestData} from "../utils/getRequestData";
import {isClientSettingsOtherChanged} from "../utils/isChangedData";

import {CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE, CLIENT_SETTINGS_TAB} from "../../../constants";

import {
    getClientsSettingsOther,
    updateClientsSettingsOther,
} from "../../../../../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsOtherAvailableClientManagersIdsSelector,
    bffClientsSettingsOtherSelector,
} from "../../../../../../ducks/bff/clients/settings/selectors";
import {getMenuDesignDict} from "../../../../../../ducks/bff/common/dicts/actionCreators";

export const useClientSettingsOtherForm = (params) => {
    const {
        clientId,
        onClose,
    } = params;

    const {
        isOpenUnsavedDataConfirm,
        onCancelUnsavedDataConfirm,
        updateChanged,
    } = useContext(ClientSettingsContext);

    const dispatch = useDispatch();
    const card = useSelector(bffClientsSettingsOtherSelector);
    const availableClientManagersIds = useSelector(bffClientsSettingsOtherAvailableClientManagersIdsSelector);

    useEffect(() => {
        dispatch(getMenuDesignDict());
        dispatch(getClientsSettingsOther({clientId}));
    }, []);

    const {
        values,
        setFieldValue,
        setValues,
    } = useFormik({
        onSubmit,
        initialValues: {
            availableClientManagersIds,
            innRequired: card.innRequired,
            ordersVolumeOfWorkRequired: card.ordersVolumeOfWorkRequired,
            menuDesign: card.menuDesign,
            foremanEnabled: card.foremanEnabled,
            objectManagerHasAccessToAllPaymentRegistries: card.objectManagerHasAccessToAllPaymentRegistries,
            foremanHasUnrestrictedReadAccessToDocumentsRegistry: card.foremanHasUnrestrictedReadAccessToDocumentsRegistry,
            addBrandNameToObject: card.addBrandNameToObject,
            amountForReport: card.amountForReport,
            orderPublishingProhibitedToObjectManager: card.orderPublishingProhibitedToObjectManager,
            workPeriodWithinOneMonthOnly: card.workPeriodWithinOneMonthOnly,
            multiplePaymentsPerOrderContractorProhibited: card.multiplePaymentsPerOrderContractorProhibited,
            workPeriodIsNotEarlierCurrentDate: card.workPeriodIsNotEarlierCurrentDate,
            autoCloseOrdersWithoutContractorsAfterThreeDays: card.autoCloseOrdersWithoutContractorsAfterThreeDays,
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        const isChangedData = isClientSettingsOtherChanged({
            values,
            card,
            availableClientManagersIds,
        });

        updateChanged({
            name: CLIENT_SETTINGS_TAB.OTHER,
            isChangedData,
        });
    }, [card, values]);

    function onSubmit({isClose}) {
        const requestData = getClientSettingsOtherRequestData(values);

        dispatch(updateClientsSettingsOther({
            clientId,
            ...requestData,
            onSuccess: () => {
                toastSuccess(CLIENT_SETTINGS_SUCCESS_UPDATING_MESSAGE);

                if (isOpenUnsavedDataConfirm) {
                    onCancelUnsavedDataConfirm();
                }

                if (isClose) {

                    onClose();

                    return;
                }

                dispatch(getClientsSettingsOther({clientId}));
            },
        }));
    }

    const {
        modalData: confirmData,
        onOpenModal: onOpenConfirm,
        onCloseModal: onCloseConfirm,
        isOpen: isOpenConfirm,
    } = useModal();

    const disableForeman = () => {
        setFieldValue("foremanEnabled", false);
        onCloseConfirm();
    };

    const archiveType2Orders = () => {
        setValues({
            ...values,
            ordersVolumeOfWorkRequired: false,
            foremanEnabled: true,
        });
        onCloseConfirm();
    };

    const archiveType1Orders = () => {
        setFieldValue("ordersVolumeOfWorkRequired", true);
        onCloseConfirm();
    };

    const onChange = (event, {name, value, checked}) => {
        if (name === "foremanEnabled") {
            if (!checked) {
                onOpenConfirm({
                    confirmText: "Все активные бригадиры будут отключены",
                    onConfirm: disableForeman,
                });

                return;
            }

            if (card.hasVolumeOfWorkBasedDrafts) {
                onOpenConfirm({
                    confirmText: "Заказы в статусе \"Черновик\", созданные по типу 2 будут отправлены в архив",
                    onConfirm: archiveType2Orders,
                });

                return;
            }

            setValues({
                ...values,
                ordersVolumeOfWorkRequired: false,
                foremanEnabled: checked,
            });

            return;
        }

        if (name === "ordersVolumeOfWorkRequired" && checked && card.hasAmountBasedDrafts) {
            onOpenConfirm({
                confirmText: "Заказы в статусе \"Черновик\", созданные по типу 1 будут отправлены в архив",
                onConfirm: archiveType1Orders,
            });

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    return {
        onChange,
        values,
        onSubmit,
        confirmData,
        isOpenConfirm,
        onCloseConfirm,
        isLoading: isEmpty(card),
    };
};