import {useDispatch} from "react-redux";

import {useModal} from "../../../../hooks/useModal";

import {deleteSettingsUsersRole} from "../../../../ducks/settingsUsersRoles";

export function useSettingsUsersRolesAction(props) {
    const {fetchList} = props;

    const dispatch = useDispatch();

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const deleteAction = ({id, name}) => {
        onOpenModal({
            isOpenConfirm: true,
            content: `Вы уверены, что хотите удалить роль \"${name}\"?`,
            onConfirm: () => {
                dispatch(deleteSettingsUsersRole({
                    id,
                    archived: true,
                    onSuccess: () => {
                        if (fetchList) {
                            fetchList();
                        }

                        onCloseModal();
                    },
                }));
            },
        });
    };

    return {
        deleteAction,
        modalData,
        onOpenModal,
        onCloseModal,
    };
}