import {
    DELETE_EMPTY_RECORD_REQUEST,
    GET_AUTH_MAINTENANCE_NOTICE_REQUEST,
    GET_EMPTY_RECORD_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_REQUEST,
    SYSTEM_SETTING_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_SET_REQUEST,
    UPDATE_SETTINGS_SERVICE_STORE,
} from "./actions";

export const getMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    payload,
});

export const setMaintenanceNoticeSettings = (payload) => ({
    type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    payload,
});

export const getAuthMaintenanceNotice = () => ({
    type: GET_AUTH_MAINTENANCE_NOTICE_REQUEST,
});

export const getSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_GET_REQUEST,
    payload,
});

export const setSystemSetting = (payload) => ({
    type: SYSTEM_SETTING_SET_REQUEST,
    payload,
});

export const setExpasoftCollingSetting = (payload) => ({
    type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    payload,
});

export function getEmptyRecord(payload) {
    return {
        type: GET_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function deleteEmptyRecord(payload) {
    return {
        type: DELETE_EMPTY_RECORD_REQUEST,
        payload,
    };
}

export function correctSettingNpdDeposit(payload) {
    return {
        type: SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST,
        payload,
    };
}

export function correctSettingNdflDeposit(payload) {
    return {
        type: SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST,
        payload,
    };
}

export function isSystemSettingsMaintenanceNoticePublished(payload) {
    return {
        type: SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_REQUEST,
        payload,
    };
}

export const updateStoreSettingsStats = (payload) => {
    return {
        type: UPDATE_SETTINGS_SERVICE_STORE,
        payload,
    };
};