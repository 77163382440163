import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const orderContractorsSelector = state => state.bff.orderContractors;
export const actRegistriesProgressActionSelector = createSelector(
    orderContractorsSelector,
    ({progressAction}) => progressAction,
);
export const getOrderWorkReportListSelector = createSelector(orderContractorsSelector, ({workReportList}) => workReportList);
export const getOrderWorkReportPageDataSelector = createSelector(orderContractorsSelector, ({workReportPageData}) => workReportPageData);
export const getOrderWorkProgressSelector = createSelector(orderContractorsSelector, ({workReportProgress}) => workReportProgress);
export const getOrderWorkReportTotalPagesSelector = createSelector(orderContractorsSelector, ({workReportTotalCount, workReportPageData: {pageSize = 0}}) => ({
    totalPages: getTotalPages(workReportTotalCount, pageSize),
    workReportTotalCount,
}));
export const orderWorkReportListTotalCount = createSelector(orderContractorsSelector, ({workReportTotalCount}) => workReportTotalCount);
export const financeReportDuplicatedPaymentsSelector = createSelector(orderContractorsSelector, ({duplicatedPaymentsInfo}) => duplicatedPaymentsInfo);
export const financeReportProcessDuplicatedPaymentsSelector = createSelector(orderContractorsSelector, ({processDuplicatedPayments}) => processDuplicatedPayments);