import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";

import {REFERRAL_CHANNEL_NAME} from "../../../constants/refProgram";

export const bffPromotionChannelsSelector = state => state.bff.promotionChannels;
export const bffPromotionChannelsListSelector = createSelector(bffPromotionChannelsSelector, ({list}) => list);
export const bffPromotionChannelsPageDataSelector = createSelector(bffPromotionChannelsSelector, ({pageData}) => pageData);
export const bffPromotionChannelsTotalCountSelector = createSelector(bffPromotionChannelsSelector, ({totalCount}) => totalCount);
export const bffPromotionChannelsTotalPagesSelector = createSelector(bffPromotionChannelsSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const bffPromotionChannelsProgressSelector = createSelector(bffPromotionChannelsSelector, ({progress}) => progress);
export const bffPromotionChannelsProgressActionSelector = createSelector(bffPromotionChannelsSelector, ({progressAction}) => progressAction);
export const bffPromotionChannelsFetchErrorSelector = createSelector(bffPromotionChannelsSelector, ({fetchError}) => fetchError);
export const bffPromotionChannelsClientWithPartnersOptionsSelector = createSelector(bffPromotionChannelsSelector, ({clientsWithPartners}) => clientsWithPartners.map(({
    clientId,
    clientPartnerId,
    name,
    brand,
}) => {
    const itemId = clientPartnerId ? clientPartnerId : clientId;
    const brandText = brand ? `(${brand})` : "";
    const text = clientPartnerId ? `${name} ${REFERRAL_CHANNEL_NAME.PARTNER}` : `${name}${brandText}`;

    return ({
        key: itemId,
        value: itemId,
        text,
        clientName: name,
    });
}));
export const promocodeClientOptionsSelector = createSelector(bffPromotionChannelsSelector, ({clientsWithPartners}) => clientsWithPartners
    .filter(item => !item.clientPartnerId)
    .map(({clientId, name: text}) => {
        return {
            key: clientId,
            value: clientId,
            text,
        };
    }));

export const promocodePartnersOptionsSelector = createSelector(bffPromotionChannelsSelector, ({clientsWithPartners}) => clientsWithPartners
    .filter(item => Boolean(item.clientPartnerId))
    .map(({clientPartnerId: value, name: text}) => {
        return {
            key: value,
            value: value,
            text,
        };
    }));