import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_ERROR,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/recruitment/job-boards-webhook",
        client: "/client-adm/recruitment/job-boards-webhook",
    });
};

// POST /bff/adm/clients/client-card/recruitment/job-boards-webhook/vacancies-by-account-id
// POST /bff/client-adm/recruitment/job-boards-webhook/vacancies-by-account-id
// Получить вакансии из подписки на события джоб-борда по фильтру
const getJobBoardSubscriptions = function* ({payload}) {
    const {
        isLoadMore,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/vacancies-by-account-id`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_SUCCESS,
            payload: {
                ...result,
                isLoadMore,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/job-boards-webhook/refresh-by-account-id 
// PUT /bff/client-adm/recruitment/job-boards-webhook/refresh-by-account-id 
// Обновить подписку на события джоб-борда для аккаунта
const refreshJobBoardSubscriptions = function* ({payload}) {
    try {
        const {
            onSuccess,
            jobBoardAccountId,
        } = payload;

        const result = yield request.bff.put(
            `${getController()}/refresh-by-account-id`,
            undefined,
            {params: {jobBoardAccountId}},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_ERROR,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST, getJobBoardSubscriptions),
        takeEvery(BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_REQUEST, refreshJobBoardSubscriptions),
    ]);
}