import {isFilePdf} from "../../../utils/file";

import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";

export const getFullRegistrationInnFileType = (fileType) => {
    const isPdf = isFilePdf(fileType);

    return isPdf
        ? CONTRACTOR_FILE_TYPES.INN_SCAN_PDF
        : CONTRACTOR_FILE_TYPES.INN;
};