import React, {useState} from "react";

import {ReactComponent as HistoryIcon} from "../../../../images/history_24.svg";

import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";


const useCrowdTaskRegistryAction = () => {
    const [logFormData, setLogFormData] = useState({});

    const getMediaControls = (item) => {
        if (
            !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsCrowdTasksList,
            ])
        ) {
            return null;
        }

        const {
            linkFNS,
        } = item;

        return {
            renderCount: {
                desktop: 2,
                tablet: 0,
                mobile: 0,
            },
            size: "lg",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            openLinkByUrl(linkFNS);
                        },
                        children: "Чек ФНС России",
                        color: "grey",
                        size: "xl",
                    },
                    visible: Boolean(linkFNS),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => setLogFormData(item),
                        children: "История",
                        icon: <HistoryIcon />,
                        color: "grey",
                        size: "xl",
                    },
                },
            ],
        };
    };


    return {
        getMediaControls,
        logFormData,
        setLogFormData,
    };
};

export default useCrowdTaskRegistryAction;