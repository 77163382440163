import {
    EDM_POSITION_ADD_ERROR,
    EDM_POSITION_ADD_REQUEST,
    EDM_POSITION_ADD_SUCCESS,
    EDM_POSITION_COMMON_COUNT_SUCCESS,
    EDM_POSITION_DELETE_ERROR,
    EDM_POSITION_DELETE_REQUEST,
    EDM_POSITION_DELETE_SUCCESS,
    EDM_POSITION_LIST_READ_ERROR,
    EDM_POSITION_LIST_READ_REQUEST,
    EDM_POSITION_LIST_READ_SUCCESS,
    EDM_POSITION_UPDATE_ERROR,
    EDM_POSITION_UPDATE_REQUEST,
    EDM_POSITION_UPDATE_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    progress: false,
    actionProgress: false,
    commonCount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EDM_POSITION_ADD_REQUEST:
        case EDM_POSITION_DELETE_REQUEST:
        case EDM_POSITION_UPDATE_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case EDM_POSITION_LIST_READ_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case EDM_POSITION_COMMON_COUNT_SUCCESS:
            return {
                ...state,
                commonCount: payload,
            };
        case EDM_POSITION_ADD_SUCCESS:
        case EDM_POSITION_DELETE_SUCCESS:
        case EDM_POSITION_UPDATE_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case EDM_POSITION_LIST_READ_SUCCESS:
            const {
                results = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case EDM_POSITION_ADD_ERROR:
        case EDM_POSITION_DELETE_ERROR:
        case EDM_POSITION_UPDATE_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case EDM_POSITION_LIST_READ_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};