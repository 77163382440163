import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPageSystemTransactions} from "../../../ducks/bff/system-transactions/actionCreators";

export const useSystemTransactionsFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        onFetchList();
    }, [pageNum, pageSize, filter]);

    const onFetchList = () => {
        dispatch(getPageSystemTransactions(
            {
                pageNum,
                pageSize,
                ...filter,
            },
        ));
    };

    return {
        onFetchList,
    };
};