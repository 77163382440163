import {
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR,
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST,
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS,
    UPDATE_FINANCES_PAID_APIS_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    totalAmount: 0,
    progress: false,
    progressAction: false,
    progressSettings: false,
    settingsInfo: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                totalAmount: payload.totalAmount,
                settingsInfo: {
                    projectNameForPaidApis: payload.projectNameForPaidApis,
                    objectNameForPaidApis: payload.objectNameForPaidApis,
                    useClientDepositForPaidApis: payload.useClientDepositForPaidApis,
                },
                progress: false,
            };
        case BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST:
            return {
                ...state,
                progressSettings: true,
            };
        case BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS:
            return {
                ...state,
                progressSettings: false,
                settingsInfo: payload,
            };
        case BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR:
            return {
                ...state,
                progressSettings: false,
                error: payload,
            };
        case BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case UPDATE_FINANCES_PAID_APIS_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};