import {DOCUMENT_TYPE} from "../../../../constants/documentType";

export const DOCUMENTS_LIST_TEMPLATE_LABEL_BY_DOCUMENT_TYPE = {
    [DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_WORK]: "Шаблон акта",
    [DOCUMENT_TYPE.ACT_OF_ACCEPTANCE_OF_TASK]: "Шаблон акта",
    [DOCUMENT_TYPE.ORDER_APPLICATION]: "Шаблон заявки",
    [DOCUMENT_TYPE.TASK_APPLICATION]: "Шаблон заявки",
    [DOCUMENT_TYPE.FRAME_CONTRACT]: "Шаблон договора",
    [DOCUMENT_TYPE.CIVIL_FRAME_CONTRACT]: "Шаблон договора",
    [DOCUMENT_TYPE.INDIVIDUAL_FRAME_CONTRACT]: "Шаблон договора",
    [DOCUMENT_TYPE.OTHER_DOCUMENT]: "Шаблон документа",
    [DOCUMENT_TYPE.ADDITIONAL_AGREEMENT]: "Шаблон доп. соглашения",
    [DOCUMENT_TYPE.DEFERRED_ACT_OF_ACCEPTANCE_OF_WORK]: "Шаблон акта",
};

export const DOCUMENTS_LIST_EXTERNAL_FRAME_CONTRACT_NAME = "ДС к внешнему договору :type";