export const BFF_GET_CLIENT_MEMBER_LIST_REQUEST = "BFF_GET_CLIENT_MEMBER_LIST_REQUEST";
export const BFF_GET_CLIENT_MEMBER_LIST_SUCCESS = "BFF_GET_CLIENT_MEMBER_LIST_SUCCESS";
export const BFF_GET_CLIENT_MEMBER_LIST_ERROR = "BFF_GET_CLIENT_MEMBER_LIST_ERROR";

export const BFF_CLIENT_MEMBER_ARCHIVE_REQUEST = "BFF_CLIENT_MEMBER_ARCHIVE_REQUEST";
export const BFF_CLIENT_MEMBER_ARCHIVE_ERROR = "BFF_CLIENT_MEMBER_ARCHIVE_ERROR";

export const BFF_CLIENT_MEMBER_ADD_REQUEST = "BFF_CLIENT_MEMBER_ADD_REQUEST";
export const BFF_CLIENT_MEMBER_ADD_SUCCESS = "BFF_CLIENT_MEMBER_ADD_SUCCESS";
export const BFF_CLIENT_MEMBER_ADD_ERROR = "BFF_CLIENT_MEMBER_ADD_ERROR";

export const BFF_CLIENT_MEMBER_UPDATE_REQUEST = "BFF_CLIENT_MEMBER_UPDATE_REQUEST";
export const BFF_CLIENT_MEMBER_UPDATE_SUCCESS = "BFF_CLIENT_MEMBER_UPDATE_SUCCESS";
export const BFF_CLIENT_MEMBER_UPDATE_ERROR = "BFF_CLIENT_MEMBER_UPDATE_ERROR";

export const BFF_GET_CLIENT_MEMBER_CARD_REQUEST = "BFF_GET_CLIENT_MEMBER_CARD_REQUEST";
export const BFF_GET_CLIENT_MEMBER_CARD_SUCCESS = "BFF_GET_CLIENT_MEMBER_CARD_SUCCESS";
export const BFF_GET_CLIENT_MEMBER_CARD_ERROR = "BFF_GET_CLIENT_MEMBER_CARD_ERROR";

export const BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST = "BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST";
export const BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS = "BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS";
export const BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR = "BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR";

export const BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE = "BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE";
