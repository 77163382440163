import React, {useEffect} from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import {CitizenshipWithFlag} from "../../../../../../../components/CitizenshipWithFlag";
import VerificationDocumentForm from "../../../../../../../components/VerificationDocumentForm";
import ContractorVerificationInfoBlock from "../../../../../contractor-verification-data/components/info-block";
import ContractorVerificationInputContainer from "../../../../../contractor-verification-data/components/input-container";
import ContractorVerificationPreviousValueTooltip
    from "../../../../../contractor-verification-data/components/previous-value-tooltip";
import ContractorVerificationRowContainer from "../../../../../contractor-verification-data/components/row-container";
import ContractorAdditionalDocumentCheckingErrorModal from "../error-modal";

import useContractorVerificationDataForm from "../../../../../../../components/VerificationDocumentForm/hooks/useDataForm";
import {useContractorVerificationLocalization} from "../../../../../contractor-verification-data/hooks/useLocalization";
import {useContractorAdditionalDocumentsVerification} from "./hooks/useAction";
import {useContractorInfoAdditionalDocumentsVerificationWarnings} from "./hooks/useWarnings";

import {getPersonalDataStep} from "../../../../../../../components/FullRegistration/utils/getPersonalDataStep";
import {formatDateWithoutTime} from "../../../../../../../utils/dateFormat";
import {
    getFullRegistrationPassportFields,
} from "../../../../../contractor-verification-data/components/form/utils/getPassportFields";
import {
    getContractorPassportTitle,
} from "../../../../../contractor-verification-data/components/form/utils/getPassportTitle";
import {getContractorAddress} from "../../utils/getAddress";
import {getContractorAdditionalVerificationInitialValues} from "../../utils/getInitialValues";
import {getContractorAdditionalDocumentsVerificationValidation} from "../../utils/getValidation";

import {
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../../constants/clientList";
import {GENDER_DICT} from "../../../../../../../constants/contractorInfo";
import {REG_EXP} from "../../../../../../../constants/regExp";

import {contractorCardInfoSelector} from "../../../../../../../ducks/bff/contractor-сard/selectors";
import {
    contractorAdditionalDocumentsProgressDecideSelector,
} from "../../../../../../../ducks/contractorAdditionalDocuments";

const ContractorInfoAdditionalDocumentsVerificationForm = (props) => {
    const {
        updateDocumentStepForm,
        pendingFileTypes,
        initialValues,
        currentStep,
        savedFiles,
        checkedDocumentName,
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const contractor = useSelector(contractorCardInfoSelector);
    const progressCompletionChecking = useSelector(contractorAdditionalDocumentsProgressDecideSelector);

    const {
        birthPlace: birthPlaceField,
    } = getPersonalDataStep({
        citizenship: contractor.citizenship,
        migrationStatus: contractor.migrationStatus,
        isCheckPersonalData: true,
    });

    const {
        localizationData,
    } = useContractorVerificationLocalization();

    const {
        handleSave,
        scans,
        handleClose,
        fetchScansFunc,
        fetchScans,
        getFileId,
        errorModalData,
        onCloseErrorModal,
        onClickCancelErrorModal,
    } = useContractorAdditionalDocumentsVerification({
        contractorId,
        updateDocumentStepForm,
        currentStep,
    });

    const {
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        handleFocus,
        isFocus,
        dirty,
        validateForm,
        getChanged,
    } = useContractorVerificationDataForm({
        validationSchema: getContractorAdditionalDocumentsVerificationValidation(),
        initialValues: getContractorAdditionalVerificationInitialValues(initialValues),
        handleClose,
    });

    const {
        warnings,
    } = useContractorInfoAdditionalDocumentsVerificationWarnings({
        values,
    });


    useEffect(() => {
        validateForm();
    }, [currentStep, values]);

    const stepsConfig = {
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_FRONT_SIDE_SCAN]: {
            title: "Документ о прохождении дактилоскопии, лицевая сторона",
            render: renderFingerPrintingPassingFromtSideScan,
            fields: [
                "fingerprintingDocumentNum",
            ],
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.FINGERPRINTING_PASSING_BACK_SIDE_SCAN]: {
            title: "Документ о прохождении дактилоскопии, обратная сторона",
            render: renderFingerPrintingPassingBackSide,
            fields: [
                "fingerprintingDocumentIssuedBy",
                "fingerprintingDocumentIssuedDate",
            ],
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_PERSONAL_DATA_SCAN]: {
            title: "Медицинское освидетельствование, персональные информация",
            render: renderMedicalPersonalInfo,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_HIV_ABSENCE_SCAN]: {
            title: "Медицинское освидетельствование, сертификат об отсутствии ВИЧ",
            render: renderMedicalPersonalInfo,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_SIGNATURES_SEALS_SCAN]: {
            title: "Медицинское освидетельствование, подписи и печати врачей",
            render: () => ({content: null}),
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_CERTIFICATION_REPORT_RESULTS_SCAN]: {
            title: "Медицинское освидетельствование, результаты заключения",
            render: renderMedicalPersonalInfo,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]: {
            title: "Разворот главной страницы медицинской книжки",
            fields: [
                "medicalBookNumber",
                "medicalBookIssuedDate",
            ],
            render: renderMedicalBook,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN]: {
            title: "QR-код для проверки медицинской книжки",
            render: renderMedicalBook,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.COVID_19_VACCINATION_CERTIFICATE_SCAN]: {
            title: "Сертификат о вакцинации от COVID-19 или QR-код",
            render: renderQrCodeVaccination,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN]: {
            title: "Трудовая книжка",
            fields: [
                "employmentHistoryNum",
            ],
            render: renderEmploymentHistorySpreadScan,
        },
        [CONTRACTOR_ADDITIONAL_FILE_TYPES.SNILS_FRONT_SIDE_SCAN]: {
            title: "СНИЛС",
            fields: [
                "snilsNum",
            ],
            render: renderPageSnils,
        },
    };

    function renderEmploymentHistorySpreadScan() {
        return {
            content: (
                <ContractorVerificationInputContainer full>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("employmentHistoryNum")}
                        name="employmentHistoryNum"
                        value={values.employmentHistoryNum}
                        onChange={handleChange}
                        label="Номер"
                        tooltip={getPreviousValue("employmentHistoryNum")}
                        error={errors && errors.employmentHistoryNum}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        pattern={REG_EXP.ONLY_NUMBERS}
                    />
                </ContractorVerificationInputContainer>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic || "Нет",
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                    ]}
                />
            ),
        };
    }

    function renderPageSnils() {
        return {
            content: (
                <ContractorVerificationInputContainer full>
                    <NmInputV2
                        size="xl"
                        changed={getChanged("snilsNum")}
                        name="snilsNum"
                        mask="999-999-999 99"
                        value={values.snilsNum}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        label="Номер"
                        tooltip={getPreviousValue("snilsNum")}
                        required
                        error={errors && errors.snilsNum}
                    />
                </ContractorVerificationInputContainer>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic || "Нет",
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.placeBirth,
                            value: values[birthPlaceField],
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderQrCodeVaccination() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            migrationStatus: contractor.migrationStatus,
            citizenship: contractor.citizenship,
            localizationData,
            fields: ["num"],
        });

        return {
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus: contractor.migrationStatus,
                                citizenship: contractor.citizenship,
                            }),
                            isTitle: true,
                        },
                        ...passportFields,
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                    ]}
                />
            ),
        };
    }

    function renderMedicalBook(scanType) {
        const isMedicalBookQrScan = scanType === CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN;

        return {
            content: (
                <ContractorVerificationRowContainer>
                    <ContractorVerificationInputContainer full>
                        <div className="d-flex flex-column flex-lg-row">
                            <NmInputV2
                                size="xl"
                                changed={getChanged("medicalBookNumber")}
                                name="medicalBookNumber"
                                disabled={isMedicalBookQrScan}
                                label="Типографский номер"
                                value={values.medicalBookNumber}
                                maskChar={null}
                                mask="99999999"
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                tooltip={getPreviousValue("medicalBookNumber")}
                                error={errors && errors.medicalBookNumber}
                                required
                            />
                            <NmDatePicker
                                className="ms-0 ms-lg-4 mt-2 mt-lg-0"
                                size="xl"
                                label="Дата Выдачи"
                                dateFormat="dd.MM.yy"
                                disabled={isMedicalBookQrScan}
                                selected={values.medicalBookIssuedDate}
                                name="medicalBookIssuedDate"
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                required={true}
                                isClearable={true}
                                warning={warnings.medicalBookIssuedDate}
                                maxDate={new Date()}
                                error={errors && errors.medicalBookIssuedDate}
                            />
                        </div>
                    </ContractorVerificationInputContainer>
                </ContractorVerificationRowContainer>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                        !isMedicalBookQrScan && {
                            title: "Регистрация",
                            isTitle: true,
                        },
                        !isMedicalBookQrScan && {
                            title: "Адрес",
                            value: getContractorAddress({values}),
                        },
                    ]}
                />
            ),
        };
    }

    function renderMedicalPersonalInfo() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            migrationStatus: contractor.migrationStatus,
            citizenship: contractor.citizenship,
            localizationData,
        });

        return {
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus: contractor.migrationStatus,
                                citizenship: contractor.citizenship,
                            }),
                            isTitle: true,
                        },
                        {
                            title: localizationData.citizenship,
                            value: <CitizenshipWithFlag
                                textLevel="4"
                                citizenship={values.citizenship}
                                flagPositionEnd
                            />,
                        },
                        ...passportFields,
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic,
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                        {
                            title: "Регистрация",
                            isTitle: true,
                        },
                        {
                            title: "Адрес",
                            value: getContractorAddress({values}),
                        },
                    ]}
                />
            ),
        };
    }

    const getPreviousValue = (name, options) => {
        return (
            <ContractorVerificationPreviousValueTooltip
                getChanged={getChanged}
                name={name}
                options={options}
            />
        );
    };

    function renderFingerPrintingPassingFromtSideScan() {
        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("fingerprintingDocumentNum")}
                                name="fingerprintingDocumentNum"
                                required
                                label="Номер документа о прохождении дактилоскопии (лицевая сторона)"
                                value={values.fingerprintingDocumentNum}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("fingerprintingDocumentNum")}
                                error={errors && errors.fingerprintingDocumentNum}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: localizationData.titlePersonalData,
                            isTitle: true,
                        },
                        {
                            title: localizationData.lastName,
                            value: values.lastName,
                        },
                        {
                            title: localizationData.firstName,
                            value: values.firstName,
                        },
                        {
                            title: localizationData.patronymic,
                            value: values.patronymic || "Нет",
                        },
                        {
                            title: localizationData.birthDate,
                            value: formatDateWithoutTime(values.birthDate),
                        },
                        {
                            title: localizationData.gender,
                            value: GENDER_DICT[values.gender],
                        },
                    ]}
                />
            ),
        };
    }

    function renderFingerPrintingPassingBackSide() {
        const passportFields = getFullRegistrationPassportFields({
            values,
            migrationStatus: contractor.migrationStatus,
            citizenship: contractor.citizenship,
            localizationData,
            fields: ["num", "issuedBy", "issuedDate"],
        });

        return {
            content: (
                <>
                    <ContractorVerificationRowContainer>
                        <ContractorVerificationInputContainer full>
                            <NmDatePicker
                                size="xl"
                                dateFormatMask="99.99.9999"
                                changed={getChanged("fingerprintingDocumentIssuedDate")}
                                name="fingerprintingDocumentIssuedDate"
                                label="Дата выдачи документа о прохождении дактилоскопии"
                                onFocus={handleFocus}
                                required
                                selected={values.fingerprintingDocumentIssuedDate}
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                                error={errors && errors.fingerprintingDocumentIssuedDate}
                                tooltip={getPreviousValue("fingerprintingDocumentIssuedDate")}
                            />
                        </ContractorVerificationInputContainer>
                        <ContractorVerificationInputContainer full>
                            <NmInputV2
                                size="xl"
                                changed={getChanged("fingerprintingDocumentIssuedBy")}
                                name="fingerprintingDocumentIssuedBy"
                                required
                                label="Кем выдан документ о прохождении дактилоскопии"
                                value={values.fingerprintingDocumentIssuedBy}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                tooltip={getPreviousValue("fingerprintingDocumentIssuedBy")}
                                error={errors && errors.fingerprintingDocumentIssuedBy}
                            />
                        </ContractorVerificationInputContainer>
                    </ContractorVerificationRowContainer>
                </>
            ),
            bottomContent: (
                <ContractorVerificationInfoBlock
                    list={[
                        {
                            title: getContractorPassportTitle({
                                migrationStatus: contractor.migrationStatus,
                                citizenship: contractor.citizenship,
                            }),
                            isTitle: true,
                        },
                        {
                            title: localizationData.citizenship,
                            value: <CitizenshipWithFlag
                                textLevel="4"
                                citizenship={values.citizenship}
                                flagPositionEnd
                            />,
                        },
                        ...passportFields,
                    ]}
                />
            ),
        };
    }

    return (
        <>
            {
                errorModalData &&
                <ContractorAdditionalDocumentCheckingErrorModal
                    content={errorModalData.content}
                    onClickCancel={onClickCancelErrorModal}
                    onClose={onCloseErrorModal}
                />
            }
            <VerificationDocumentForm
                isAdditionalDocuments={true}
                fetchScans={fetchScans}
                contractorId={contractorId}
                savedFiles={savedFiles}
                contractor={contractor}
                pendingFileTypes={pendingFileTypes}
                isFocus={isFocus}
                dirty={dirty}
                handleSave={handleSave}
                stepsConfig={stepsConfig}
                validateForm={validateForm}
                getFileId={getFileId}
                values={values}
                scans={scans}
                handleClose={handleClose}
                fetchScansFunc={fetchScansFunc}
                isCheckingStatusInnOnSave={false}
                progressCompletionChecking={progressCompletionChecking}
                errors={errors}
                checkedDocumentName={checkedDocumentName}
                isVisibleSaveButton={false}
            />
        </>
    );
};

export default ContractorInfoAdditionalDocumentsVerificationForm;