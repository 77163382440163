import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {isEmpty} from "lodash";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import FilterProject from "../../../components/ActualComponents/FilterProject";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";

import {handleFormString} from "../../../utils/stringHelper";

import PropTypes from "prop-types";

class NotificationsListFilter extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        isSettingNotifications: PropTypes.bool,
        sendFilter: PropTypes.func,
        typeEmailList: PropTypes.array,
    };

    static defaultProps = {
        onKeyPress: () => {
        },
        onChange: () => {
        },
        sendFilter: () => {
        },
        clearFilter: () => {

        },
        exportDocuments: () => {

        },
        filter: {},
        isExportFilterType: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.defaultFilter,
        };
    }

    get defaultFilter() {
        return {
            searchText: "",
            fromDate: null,
            toDate: null,
            notViewed: true,
            emailSearchFilter: "",
            typeSearchFilter: "",
            typeSubstring: "",
            projectIdsFilter: [],
        };
    };

    handleOnKeyPress = ({key}) => {
        if (key !== "Enter") {
            return;
        }

        this.sendFilter();
    };

    sendFilter = (isSearch = true) => {
        const {sendFilter, isSettingNotifications} = this.props;
        const {
            fromDate,
            searchText,
            toDate,
            notViewed,
            typeSearchFilter,
            emailSearchFilter,
            typeSubstring,
            projectIdsFilter,
        } = this.state;

        if (isSettingNotifications) {
            sendFilter({
                typeSearchFilter,
                emailSearchFilter,
                typeSubstring,
            }, isSearch);

            return;
        }

        sendFilter({
            fromDate: fromDate ? new Date(fromDate) : undefined,
            searchText: handleFormString(searchText),
            toDate: toDate ? new Date(toDate) : undefined,
            notViewed: notViewed || undefined,
            projectIdsFilter: isEmpty(projectIdsFilter) ? undefined : projectIdsFilter,
        }, isSearch);
    };

    clearFilter = () => {
        this.setState({
            ...this.defaultFilter,
        }, () => this.sendFilter(false));
    };

    handleOnChangeField = (e, {name, value, checked}) => {
        const {isSettingNotifications} = this.props;

        if (isSettingNotifications) {
            this.setState({
                [name]: value,
            });

            return;
        }

        this.setState({[name]: checked === undefined ? value : checked});
    };

    handleOnChangeSelect = (e, {name, value, checked}) => {
        const {isSettingNotifications} = this.props;

        if (isSettingNotifications) {
            this.setState({
                [name]: value,
                typeSubstring: "",
            });

            return;
        }

        this.setState({[name]: checked === undefined ? value : checked});
    };

    onSearchChange = (typeSubstring) => {
        this.setState({typeSubstring});
    };

    renderFilterFields = () => {
        const {
            isSettingNotifications,
            typeEmailList,
            clientId,
        } = this.props;

        const {
            searchText,
            fromDate,
            toDate,
            notViewed = false,
            emailSearchFilter,
            typeSearchFilter,
            typeSubstring,
            projectIdsFilter,
        } = this.state;
        
        if (isSettingNotifications) {
            return (
                <>
                    <NmInputV2
                        size="lg"
                        label="Получатель"
                        name="emailSearchFilter"
                        value={emailSearchFilter}
                        onChange={this.handleOnChangeField}
                    />
                    <NmDropdownV2
                        size="lg"
                        onSearchChange={this.onSearchChange}
                        searchQuery={typeSubstring}
                        label="Уведомление"
                        name="typeSearchFilter"
                        value={typeSearchFilter}
                        options={typeEmailList}
                        selectOnBlur={false}
                        noResultsMessage="Нет результата поиска"
                        clearable
                        compact
                        selection
                        onChange={this.handleOnChangeSelect}
                    />
                </>
            );
        }

        return (
            <>
                <NmInputV2
                    size="lg"
                    label="Поиск по уведомлениям"
                    name="searchText"
                    placeholder="Введите уведомление"
                    value={searchText}
                    onChange={this.handleOnChangeField}
                />
                <NmDateRangePickerV2
                    size="lg"
                    label="Период"
                    value={{fromDate, toDate}}
                    startFieldName="fromDate"
                    endFieldName="toDate"
                    isClearable={true}
                    onChange={this.handleOnChangeField}
                />
                <NmCheckboxV2
                    label="к прочтению"
                    name="notViewed"
                    checked={notViewed}
                    onChange={this.handleOnChangeField}
                />
                <FilterProject
                    isActiveStyle={false}
                    onChange={this.handleOnChangeField}
                    clientId={clientId}
                    projectIdsFilter={projectIdsFilter}
                    label="Проект"
                    name="projectIdsFilter"
                    placeholder="Выберите проект"
                />
            </>
        );
    };

    render() {
        return (
            <NmForm
                addMargin
                onKeyPress={this.handleOnKeyPress}
            >
                {this.renderFilterFields()}
                <FilterButtonsV2
                    onSearch={this.sendFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }
}


export default withTranslation()(NotificationsListFilter);
