import {isAccessByRestrictions} from "../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
} from "../../../constants/links";

import "./style.sass";

export function ProjectsTabs(props) {
    const {
        projectId,
        clientId,
        pathname,
        orderCount = 0,
        taskCount = 0,
        projectUserCount = 0,
        objectCount = 0,
    } = props;

    const linkToObjectList = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId);
    const linkToTeamList = LINK_CLIENT_PROJECTS_CARD_TEAM_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId);
    const linkToOrderList = LINK_CLIENT_PROJECTS_CARD_ORDER_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId);
    const linkToTaskList = LINK_CLIENT_PROJECTS_CARD_TASK_LIST
        .replace(":projectId", projectId)
        .replace(":clientId", clientId);

    return [
        {
            name: "Объекты",
            count: objectCount,
            link: linkToObjectList,
            active: pathname.includes("objects"),
        },
        {
            name: "Заказы",
            count: orderCount,
            link: linkToOrderList,
            active: pathname.includes("orders"),
        },
        {
            name: "Задания",
            count: taskCount,
            link: linkToTaskList,
            active: pathname.includes("tasks"),
            isVisible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessCrowdTasks,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
            ]),
        },
        {
            name: "Команда",
            count: projectUserCount,
            link: linkToTeamList,
            active: pathname.includes("teams"),
        },
    ];
}