import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Box from "../../../../components/ActualComponents/Box";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import {AgencyContractFileLink} from "../file-link";

import bem from "../../../../utils/bem";
import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {getTerminateContractInfo} from "../../../../ducks/bff/clients/documents/contract-agency/actionCreators";
import {terminateContractInfoSelector} from "../../../../ducks/bff/clients/documents/contract-agency/selectors";
import {getFileNotice} from "../../../../ducks/client";

import "./style.sass";

const AgencyContractTerminate = (props) => {
    const {clientId, className} = props;

    const [block] = bem("agency-terminate-contract", className);

    const dispatch = useDispatch();
    const terminateContractInfo = useSelector(terminateContractInfoSelector);

    useEffect(() => {
        dispatch(getTerminateContractInfo(clientId));
    }, []);

    const renderTerminateFileNames = () => {
        if (isEmpty(terminateContractInfo)) {
            return null;
        }

        return terminateContractInfo.map((value, index) => {
            const {
                name,
                id,
            } = value;

            return (
                <AgencyContractFileLink
                    key={id}
                    onClick={() => {
                        dispatch(getFileNotice({
                            name,
                            clientId,
                            field: id,
                        }));
                    }}
                    isLastElement={index === terminateContractInfo.length - 1}
                    fileName={name}
                />
            );
        });
    };

    if (isEmpty(terminateContractInfo)) {
        return null;
    }

    return (
        <Box
            theme="page"
            title="Сведения о расторжении"
            className={block()}
        >
            <NmLabelText
                type="page"
                className="flex-column mb-2"
                label="Дата получения уведомления о расторжении"
                text={dateFormat(convertUtcToLocal(terminateContractInfo[0].noticeOfTerminationDate), "dd.MM.yyyy")}
            />
            <NmLabelText
                type="page"
                className="flex-column mb-2"
                label="Дата расторжения договора"
                text={dateFormat(convertUtcToLocal(terminateContractInfo[0].terminationDate),"dd.MM.yyyy")}
            />
            <NmLabelText
                type="page"
                label="Документы"
                className="flex-column"
                text={renderTerminateFileNames()}
                noWrap={false}
                wrapped
            />
        </Box>
    );
};

export default AgencyContractTerminate;