import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {DepositHistoryModal} from "../../../components/DepositHistoryModal";

import {OBJECT_LOG_DEPOSIT_ACTION} from "../../../constants/objects";
import {PROJECT_LOG_DEPOSIT_ACTION} from "../../../constants/projects";

import {
    getObjectsDepositHistoryPage,
} from "../../../ducks/bff/clients/objects/actionCreators";
import {
    objectsDepositHistoryPageSelector,
    objectsDepositHistoryTotalCountSelector,
    objectsDepositHistoryTotalPagesSelector,
} from "../../../ducks/bff/clients/objects/selectors";

export default function ObjectLogDepositHistoryModal(props) {
    const {
        onClose,
        logData,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(objectsDepositHistoryPageSelector);
    const totalPages = useSelector(objectsDepositHistoryTotalPagesSelector);
    const totalCount = useSelector(objectsDepositHistoryTotalCountSelector);

    const fetchList = (data) => {
        dispatch(getObjectsDepositHistoryPage(data));
    };

    return (
        <DepositHistoryModal
            headerTextBeforeName="История изменений депозита объекта"
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            fetchList={fetchList}
            logData={logData}
            onClose={onClose}
            actionDict={OBJECT_LOG_DEPOSIT_ACTION}
        />
    );
}
