import {
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
} from "./actions";

export const getDocumentsEdoRegistriesItemsPage = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST,
    payload,
});

export const getByIdDocumentsEdoRegistriesItems = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST,
    payload,
});

export const addDocumentsEdoRegistriesItems = (payload) => ({
    type: ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesItems = (payload) => ({
    type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const deleteDocumentsEdoRegistriesItems = (payload) => ({
    type: DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateDocumentsEdoRegistriesStoreItems = (payload) => ({
    type: DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE,
    payload,
});

export const importDocumentsEdoRegistriesItems = (payload) => ({
    type: IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const rejectDocumentsEdoRegistriesItems = (payload) => ({
    type: REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const deleteNotCompletedDocumentsEdoRegistriesItems = (payload) => ({
    type: DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const checkImportTaskExistsDocumentsEdoRegistriesItems = (payload) => ({
    type: CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});

export const updateReceiversDocumentsEdoRegistriesItems = (payload) => ({
    type: UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    payload,
});