import React, {useContext, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {AppContext} from "../../../../components/AppContext";

import {getUserRestrictions, getUserRole} from "../../../../utils/access";
import {openLinkByUrl} from "../../../../utils/downloadBlob";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {toastError} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {citizenshipsDict} from "../../../../constants/citizenships";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../../constants/contractor";
import {CLIENT_TYPE_CODE} from "../../../../constants/dicts";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {
    REGISTRY_CONFIRM_TEXT,
    REGISTRY_OPERATION,
    REGISTRY_PAYMENT_ERRORS,
    REGISTRY_PAYMENTS_STATUS_DICT,
    REGISTRY_STATUS_DICT,
} from "../../../../constants/registry";
import {
    ADMIN,
    CLIENT_ADMIN,
    isUserFromNm,
    NM_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";
import {phoneRegWithForeign} from "../../../../constants/validationRules";

import {clientCardInfoSelector} from "../../../../ducks/bff/clients/info/selectors";
import {
    cancelRegistryPayment,
    findRegistryPaymentBankCheckList,
    isRegistryPaymentFrameContractSigned,
    payRegistryPayment,
    refreshRegistryPaymentBankCache,
} from "../../../../ducks/bff/finances/registry-payments/actionCreators";
import {findRegistryPaymentBankCheckListCancelToken} from "../../../../ducks/bff/finances/registry-payments/sagas";
import {
    bffFinancesRegistryPaymentsListSelector,
    bffFinancesRegistryPaymentsProgressActionSelector,
} from "../../../../ducks/bff/finances/registry-payments/selectors";

export const useRegistryPaymentsAction = (params = {}) => {
    const {
        clientId,
        isEmptyPayments,
        isAllPayments,
        card,
        uniqKey,
    } = params;

    const role = getUserRole();
    const currentUserRestrictions = getUserRestrictions();

    const dispatch = useDispatch();
    const checkBankPossibleTimer = useRef(null);

    const registryPayments = useSelector(bffFinancesRegistryPaymentsListSelector);
    const progressAction = useSelector(bffFinancesRegistryPaymentsProgressActionSelector);

    const contractorIds = useMemo(() => {
        return registryPayments.map(({contractorId}) => contractorId).filter(item => Boolean(item));
    }, [registryPayments]);

    const isAccessPayActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.payRegistry,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_paymentRegisterPayments,
    ]);

    const client = useSelector(clientCardInfoSelector);

    const {
        archived: isClientArchived,
        clientType,
    } = client;

    const {
        onOpenModal,
    } = useContext(AppContext);

    const getPaymentPossibility = () => {
        if (isEmpty(contractorIds)) {
            return;
        }

        dispatch(findRegistryPaymentBankCheckList({
            onRequest: () => {
                findRegistryPaymentBankCheckListCancelToken.cancel();
                clearTimeout(checkBankPossibleTimer.current);
            },
            handleResponse: (result) => {
                const {
                    hasNoResultItems,
                } = result;

                if (!hasNoResultItems) {
                    return;
                }

                checkBankPossibleTimer.current = setTimeout(getPaymentPossibility, TIME_INTERVAL_PAYMENT_POSSIBILITY);
            },
            clientId,
            contractorIds,
        }));
    };

    const handleResponseRefreshContractorsPaymentPossibility = ({errorMessage}) => {
        findRegistryPaymentBankCheckListCancelToken.cancel();

        clearTimeout(checkBankPossibleTimer.current);

        if (errorMessage) {
            return;
        }

        getPaymentPossibility();
    };

    const refreshPaymentPossibility = () => {
        if (progressAction || isEmptyPayments) {
            return;
        }

        dispatch(refreshRegistryPaymentBankCache({
            contractorIds,
            useFnsThreshold: true,
            handleResponse: handleResponseRefreshContractorsPaymentPossibility,
        }));
    };

    const pay = (params = {}) => {
        dispatch(payRegistryPayment({
            ...params,
            handleError: (result) => {
                const {
                    errorCode,
                    warningMessage,
                    errorMessage,
                } = result;

                switch (errorCode) {
                    case REGISTRY_PAYMENT_ERRORS.APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE:
                        onOpenModal({
                            uniqKey,
                            confirmData: {
                                confirmText: warningMessage,
                                onConfirm: () => {
                                    pay({
                                        ...params,
                                        invalidApplicationDateConfirmed: true,
                                    });
                                },
                            },
                        });

                        return;
                    default: {
                        toastError(errorMessage);

                        return;
                    }
                }
            },
        }));
    };

    const retryPayment = (item) => {
        const {
            clientId,
            contractorId,
            citizenship,
            registrySeqNum,
            registryId,
        } = item;

        const onSuccess = (frameContractSigned = {}) => {
            const isShowWarning = citizenship !== citizenshipsDict.RU.value &&
                clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY &&
                !isNullOrWhitespace() &&
                !frameContractSigned.result;

            const confirmData = {
                confirmTitle: isShowWarning
                    ? REGISTRY_CONFIRM_TEXT.PAY
                    : "",
                contentAlign: "left",
                confirmText: isShowWarning
                    ? "\nОбращаем внимание, что вы собираетесь заключить рамочный договор с иностранным гражданином " +
                    "(лицом без гражданства). \n\nВ соответствии с законодательством РФ необходимо уведомить " +
                    "территориальное подразделение МВД России по вопросам миграции о заключении договора с " +
                    "иностранным гражданином (лицом без гражданства) в течение 3 рабочих дней с\u00A0даты заключения такого договора."
                    : REGISTRY_CONFIRM_TEXT.PAY,
                onConfirm: () => {
                    pay({
                        registrySeqNum,
                        clientId,
                        registryId,
                    });
                },
            };

            onOpenModal({
                uniqKey,
                confirmData,
                typeOperation: REGISTRY_OPERATION.PAY,
                data: {registrySeqNum},
            });
        };

        dispatch(isRegistryPaymentFrameContractSigned({
            contractorId,
            clientId,
            onSuccess,
        }));
    };

    const getMediaControls = (payment) => {
        const {
            deletionStart,
            status,
        } = payment;

        const isVisibleRetryPayment = [
            REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.DECLINED.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
            REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
        ].includes(status) &&
            [
                ADMIN,
                CLIENT_ADMIN,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
            ].includes(role) &&
            !isClientArchived &&
            isAccessPayActions &&
            !deletionStart;

        const isVisibleCancel = (
            [
                REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_CONTRACTOR_CONFIRMATION.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
                REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
            ].includes(status)
            && [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                PROJECT_MANAGER,
            ].includes(role)
        )
            && !(
                !isUserFromNm()
                && !isAccessPayActions
            );

        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Повторить платеж",
                        onClick: () => {
                            retryPayment(payment);
                        },
                    },
                    visible: isVisibleRetryPayment,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Редактировать",
                        onClick: () => {
                            const data = {
                                contractorData: {contractorId: payment.contractorId},
                                paymentParams: {...payment},
                                isEditRealContractor: Boolean(payment.contractorId),
                            };

                            const isValidContractor = payment.contractorId
                                && payment.contractorPhone
                                && phoneRegWithForeign.test(payment.contractorPhone);

                            if (isValidContractor) {
                                onOpenModal({
                                    uniqKey,
                                    isOpenContractorParamsEdit: true,
                                    ...data,
                                });

                                return;
                            }

                            onOpenModal({
                                uniqKey,
                                isOpenSearchContractor: true,
                                ...data,
                            });
                        },
                    },
                    visible: !isAllPayments
                        && (
                            status === REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE
                            || !status
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: (
                            <div className="app-link">
                                Чек ФНС России
                            </div>
                        ),
                        onClick: () => {
                            openLinkByUrl(payment.checkLink);
                        },
                    },
                    visible: !isAllPayments
                        && status === REGISTRY_PAYMENTS_STATUS_DICT.PAID.VALUE
                        && ![
                            ORDER_WORK_REPORT_TYPE.CIVIL,
                            ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                        ].includes(payment.orderContractPaymentType),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Отменить",
                        onClick: () => {
                            const confirmData = {
                                confirmText: REGISTRY_CONFIRM_TEXT.CANCEL,
                                onConfirm: () => {
                                    dispatch(cancelRegistryPayment({
                                        registrySeqNum: payment.registrySeqNum,
                                        clientId: payment.clientId,
                                        registryId: payment.registryId,
                                    }));
                                },
                            };

                            onOpenModal({
                                uniqKey,
                                confirmData,
                            });
                        },
                    },
                    visible: isVisibleCancel,
                },
            ],
        };
    };

    const deleteRow = () => {
        const {status} = card;

        const confirmText = status === REGISTRY_STATUS_DICT.DRAFT.VALUE
            ? REGISTRY_CONFIRM_TEXT.DELETE_PAYMENTS
            : REGISTRY_CONFIRM_TEXT.CANCEL_SELECTED;

        onOpenModal({
            uniqKey,
            confirmData: {
                confirmText,
                onConfirm: () => {
                    // здесь появится логика, после внедрения логики из реестровых выплат в карточке реестра
                },
            },
            //typeOperation: REGISTRY_OPERATION.DELETE_ROW,
        });
    };

    return {
        refreshPaymentPossibility,
        deleteRow,
        getMediaControls,
        contractorIds,
    };
};