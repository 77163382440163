import React from "react";

import dateFormat, {convertUtcToLocal} from "./dateFormat";
import {formatAmount, formatNumber, getFullName} from "./stringFormat";

import {MENU_DESIGN_TYPE} from "../constants/menu";
import {REG_EXP} from "../constants/regExp";

export const isNullOrWhitespace = input => {
    if (typeof input === "undefined" || input == null || input === "") {
        return true;
    }

    return input.toString().replace(/\s/g, "").length < 1;
};

export function convertStringToDate(date, isConvertToLocal) {
    if (isNullOrWhitespace(date)) {
        return null;
    }

    return isConvertToLocal ? new Date(convertUtcToLocal(date)) : new Date(date);
}

export function trimPartOfString(message, number) {
    if(isNullOrWhitespace(message)){
        return message;
    }

    if (message.length <= number) {
        return message;
    }
    return `${message.slice(0, number)}... `;
}


export function minutesToTextHourAndMinutes(minutes) {
    if (isNullOrWhitespace(minutes) || minutes === 0) {
        return "—";
    }

    const wholeHours = Math.floor((minutes / 60));

    const minutesLeft = minutes - wholeHours * 60;

    return `${wholeHours} час. ${minutesLeft} мин.`;
}

export function handleFormString(str) {
    return isNullOrWhitespace(str) ? undefined : str.toString().trim();
}

export function convertDateToDateWithoutTimeString(date) {
    return isNullOrWhitespace(date) ? null : dateFormat(date, "yyyy-MM-dd");
}

export function handleDateFieldFilter(dateFieldFilter) {
    return isNullOrWhitespace(dateFieldFilter) ? undefined : convertUtcToLocal(dateFieldFilter).toJSON();
}


export function handleFieldFilter(fieldFilter) {
    return isNullOrWhitespace(fieldFilter) ? undefined : fieldFilter;
}

export function handleNumber(sumMoney) {
    return isNullOrWhitespace(sumMoney) ? undefined : Number(sumMoney.toString().replace(",", "."));
}

export function handleNumberForInput(sumMoney) {
    return isNullOrWhitespace(sumMoney) ? undefined : sumMoney.toString().replace(".", ",");
}

/***
 * Проверяет строку на соответсвие дробному виду xx,xx с запятой разделителем, два знака после запятой
 * @param value
 * @returns {boolean}
 */

export const isValidFractionalNumber = (value) => {
    const regex = /^\d+\,{0,1}\d{0,2}$/;

    value = value.replace(/\s+/g, "");


    return regex.test(value);
};

export function getAmount(amount) {
    const style = amount >= 10_000_000_000 ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 150,
    } : {};

    return <div
        style={style}
        title={amount}
    >
        {formatAmount(formatNumber(amount, 2))}
    </div>;
}

export function getSimpleText(value) {
    return value ? "Да" : "Нет";
}

/***
 * Убрать бренд из строки с наименованием
 * @param str
 * @returns {*}
 */
export function getClientNameWithoutBrand(str) {
    return str.replace(/\(.+\)/, "").trim();
}

/***
 * Получтьб наименование клиента с брендом
 * @returns {{clientName}}
 */
export const getClientNameWithBrand = (name, brand) => {
    const _brand = brand ? ` (${brand})` : "";

    return `${name}${_brand}`;
};

/***
 * Ищет есть ли в строке невалидный параметр (валидный параметр - {0}, {22}, не валидный - {}, {str}})
 * @param string
 * @returns {boolean}
 */
export function isAnyInvalidParamInString(string) {
    const tempStringWithoutValidParams = string.replace(new RegExp(REG_EXP.STRING_PARAM, "g"), "");

    return tempStringWithoutValidParams.search(/({.*?})/g) !== -1;
}

export const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

/**
 * Recursive function which replaces the usage of `str.matchAll(regex)` which happens to be troublesome on Android once compiled.
 * @param regex - regex expression to be executed. If passed with the `/g` global flag the result will return all matches.
 * @param str - string value to be searched for matches by the regex expression
 * @param matches - parameter used to pass on the current resulting array through recursion iterations
 * @returns array of all matches found while executing the regex
 */
export function findAllMatches(regex, str, matches = []) {
    const res = regex.exec(str);
    res && matches.push(res) && findAllMatches(regex, str, matches);
    return matches;
}

export function isPictureByFilenameExtension(filename) {
    if (!filename) {
        return false;
    }

    const parts = filename.split(".");

    if (filename.length < 2) {
        return false;
    }

    const extension = parts[1];

    return [
        "jpeg",
        "jpg",
        "png",
    ].includes(extension);
};

export const insertIntoString = (str, index, value) => {
    return str.substr(0, index) + value + str.substr(index);
};

export const getRegistryContractorName = (lastName, firstName, patronymic, contractorFio) => {
    if (!isNullOrWhitespace(lastName)) {
        return getFullName(lastName, firstName, patronymic);
    }

    if (!isNullOrWhitespace(contractorFio)) {
        return contractorFio;
    }

    return "Не определено";
};

export const getTypicalDocumentsHeader = (clientProperties) => {
    const {
        designMenuType,
    } = clientProperties;

    return designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU
        ? "Типовые документы площадки"
        : "Типовые документы Наймикс";
};

export const getOnOffText = (isEnabled) => {
    return isEnabled ? "Включено" : "Выключено";
};