import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST,
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS,
    CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
    CONTRACTOR_TAX_STATUS_REFRESH_REQUEST,
    CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS,
    GET_CONTRACTOR_BANK_STATUS_SUCCESS,
    GET_INN_BY_PERSONAL_INFO_ERROR,
    GET_INN_BY_PERSONAL_INFO_REQUEST,
    GET_INN_BY_PERSONAL_INFO_SUCCESS,
    GET_PENDING_PERSONAL_DATA_SUCCESS,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS,
    GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
    GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS,
    UPDATE_CONTRACTOR_PERSONAL_DATA_STORE,
} from "./actions";

const initial = {
    verificationSteps: [],
    progressVerificationSteps: false,
    actionProgress: false,
    progressCheckInn: false,
    progressCheckOnEnd: false,
    progressAdditionalDocumentUpdateData: false,
    progressAdditionalDocumentAggregation: false,
    additionalDocumentAggregation: {},
    pendingPersonalData: {
        isLoaded: false,
        data: null,
    },
    comparisonData: {},
    progressComparison: false,
    contractorBankStatus: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST:
            return {
                ...state,
                progressComparison: true,
            };
        case GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS:
            return {
                ...state,
                comparisonData: payload,
                progressComparison: false,
            };
        case GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR:
            return {
                ...state,
                progressComparison: false,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST:
            return {
                ...state,
                progressAdditionalDocumentAggregation: true,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS:
            return {
                ...state,
                progressAdditionalDocumentAggregation: false,
                additionalDocumentAggregation: payload,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR:
            return {
                ...state,
                progressAdditionalDocumentAggregation: false,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST:
            return {
                ...state,
                progressAdditionalDocumentUpdateData: true,
            };
        case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS:
        case CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR:
            return {
                ...state,
                progressAdditionalDocumentUpdateData: false,
            };
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST:
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST:
            return {
                ...state,
                progressCheckOnEnd: true,
            };
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS:
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS:
            return {
                ...state,
                progressCheckOnEnd: false,
            };
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR:
        case CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR:
            return {
                ...state,
                progressCheckOnEnd: false,
            };
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST:
        case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST:
        case CONTRACTOR_TAX_STATUS_REFRESH_REQUEST:
        case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST:
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS:
        case UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR:
        case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS:
        case DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR:
        case CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS:
        case CONTRACTOR_TAX_STATUS_REFRESH_ERROR:
        case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS:
        case GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR:
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS:
        case CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        case CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST:
            return {
                ...state,
                progressVerificationSteps: true,
            };
        case CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS:
            return {
                ...state,
                verificationSteps: payload.result,
                progressVerificationSteps: false,
            };
        case CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR:
            return {
                ...state,
                progressVerificationSteps: false,
            };
        case GET_INN_BY_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                progressCheckInn: true,
            };
        case GET_INN_BY_PERSONAL_INFO_SUCCESS:
        case GET_INN_BY_PERSONAL_INFO_ERROR:
            return {
                ...state,
                progressCheckInn: false,
            };
        case GET_PENDING_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                pendingPersonalData: {
                    data: payload,
                    isLoaded: true,
                },
            };
        case GET_CONTRACTOR_BANK_STATUS_SUCCESS:
            return {
                ...state,
                contractorBankStatus: payload,
            };
        case UPDATE_CONTRACTOR_PERSONAL_DATA_STORE:
            return {
                ...initial,
            };
        default:
            return state;
    }
};