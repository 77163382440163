import {
    BFF_ORDERS_ADD_ORDER_GROUP_ERROR,
    BFF_ORDERS_ADD_ORDER_GROUP_REQUEST,
    BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_DELETE_ORDER_GROUP_ERROR,
    BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS,
    BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR,
    BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    pageData: {},
    progress: false,
    actionProgress: false,
    totalCount: 0,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;
            return {
                ...state,
                progress: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS:
            const {
                groups: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list,
                totalCount,
            };
        case BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR:
        case BFF_ORDERS_ADD_ORDER_GROUP_REQUEST:
        case BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST:
        case BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST:
        case BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS:
        case BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS:
        case BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS:
        case BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case BFF_ORDERS_ADD_ORDER_GROUP_ERROR:
        case BFF_ORDERS_DELETE_ORDER_GROUP_ERROR:
        case BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR:
        case BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        default:
            return state;
    }
};