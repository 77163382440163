import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../utils/url";

import {
    LINK_CLIENT_MEMBERS_CARD,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_SETTINGS_MEMBERS_LIST,
    LINK_SETTINGS_USERS_ROLES_LIST,
} from "../constants/links";
import {USERS_ROLES_TYPE} from "../constants/settingsUsersRoles";

import {SUB_PAGE_SETTINGS_MEMBER} from "../constants/link-params";

const getController = () => {
    return (
        getBffUrl({
            [LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.NAIMIX)]: "/adm/settings/client-users-roles/naimix",
            [LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.CLIENT)]: "/adm/settings/client-users-roles/client",
        })
    );
};
//*  TYPES  *//

const SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST = "SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST";
const SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS = "SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS";
const SETTINGS_USERS_ROLES_RICH_PAGE_ERROR = "SETTINGS_USERS_ROLES_RICH_PAGE_ERROR";

const SETTINGS_USERS_ROLES_BY_ID_REQUEST = "SETTINGS_USERS_ROLES_BY_ID_REQUEST";
const SETTINGS_USERS_ROLES_BY_ID_SUCCESS = "SETTINGS_USERS_ROLES_BY_ID_SUCCESS";
const SETTINGS_USERS_ROLES_BY_ID_ERROR = "SETTINGS_USERS_ROLES_BY_ID_ERROR";

const SETTINGS_USERS_ROLES_ADD_REQUEST = "SETTINGS_USERS_ROLES_ADD_REQUEST";
const SETTINGS_USERS_ROLES_ADD_SUCCESS = "SETTINGS_USERS_ROLES_ADD_SUCCESS";
const SETTINGS_USERS_ROLES_ADD_ERROR = "SETTINGS_USERS_ROLES_ADD_ERROR";

const SETTINGS_USERS_ROLES_UPDATE_REQUEST = "SETTINGS_USERS_ROLES_UPDATE_REQUEST";
const SETTINGS_USERS_ROLES_UPDATE_SUCCESS = "SETTINGS_USERS_ROLES_UPDATE_SUCCESS";
const SETTINGS_USERS_ROLES_UPDATE_ERROR = "SETTINGS_USERS_ROLES_UPDATE_ERROR";

const SETTINGS_USERS_ROLES_DELETE_REQUEST = "SETTINGS_USERS_ROLES_DELETE_REQUEST";
const SETTINGS_USERS_ROLES_DELETE_SUCCESS = "SETTINGS_USERS_ROLES_DELETE_SUCCESS";
const SETTINGS_USERS_ROLES_DELETE_ERROR = "SETTINGS_USERS_ROLES_DELETE_ERROR";

const SETTINGS_USERS_ROLES_CONFIGURE_ROLE_REQUEST = "SETTINGS_USERS_ROLES_CONFIGURE_ROLE_REQUEST";
const SETTINGS_USERS_ROLES_CONFIGURE_ROLE_SUCCESS = "SETTINGS_USERS_ROLES_CONFIGURE_ROLE_SUCCESS";
const SETTINGS_USERS_ROLES_CONFIGURE_ROLE_ERROR = "SETTINGS_USERS_ROLES_CONFIGURE_ROLE_ERROR";

const SETTINGS_USERS_ROLES_CONFIG_LOGS_REQUEST = "SETTINGS_USERS_ROLES_CONFIG_LOGS_REQUEST";
const SETTINGS_USERS_ROLES_CONFIG_LOGS_SUCCESS = "SETTINGS_USERS_ROLES_CONFIG_LOGS_SUCCESS";
const SETTINGS_USERS_ROLES_CONFIG_LOGS_ERROR = "SETTINGS_USERS_ROLES_CONFIG_LOGS_ERROR";

const UPDATE_SETTINGS_USERS_ROLES_FIELD = "UPDATE_SETTINGS_USERS_ROLES_FIELD";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    restrictions: [],
    progress: false,
    progressAction: false,
    counts: {},
    logs: {
        pageData: {},
        list: [],
        totalCount: 0,
        progress: false,
        error: null,
    },
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case SETTINGS_USERS_ROLES_ADD_REQUEST:
        case SETTINGS_USERS_ROLES_UPDATE_REQUEST:
        case SETTINGS_USERS_ROLES_DELETE_REQUEST:
        case SETTINGS_USERS_ROLES_CONFIGURE_ROLE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS:
            const {
                results,
                totalCount,
                counts,
            } = payload;

            return {
                ...state,
                totalCount,
                list: results,
                progress: false,
                counts,
            };
        case SETTINGS_USERS_ROLES_ADD_SUCCESS:
        case SETTINGS_USERS_ROLES_UPDATE_SUCCESS:
        case SETTINGS_USERS_ROLES_DELETE_SUCCESS:
        case SETTINGS_USERS_ROLES_CONFIGURE_ROLE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case SETTINGS_USERS_ROLES_RICH_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SETTINGS_USERS_ROLES_ADD_ERROR:
        case SETTINGS_USERS_ROLES_UPDATE_ERROR:
        case SETTINGS_USERS_ROLES_DELETE_ERROR:
        case SETTINGS_USERS_ROLES_CONFIGURE_ROLE_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case SETTINGS_USERS_ROLES_CONFIG_LOGS_REQUEST:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    pageData: payload,
                    progress: true,
                },
            };
        case SETTINGS_USERS_ROLES_CONFIG_LOGS_SUCCESS:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case SETTINGS_USERS_ROLES_CONFIG_LOGS_ERROR:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    progress: false,
                    error: payload,
                },
            };
        case UPDATE_SETTINGS_USERS_ROLES_FIELD:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getSettingsUsersRolesRichPage(payload) {
    return {
        type: SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST,
        payload,
    };
}

export function addSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_ADD_REQUEST,
        payload,
    };
}

export function updateSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_UPDATE_REQUEST,
        payload,
    };
}

export function deleteSettingsUsersRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_DELETE_REQUEST,
        payload,
    };
}

export function configureSettingsUsersClientRole(payload) {
    return {
        type: SETTINGS_USERS_ROLES_CONFIGURE_ROLE_REQUEST,
        payload,
    };
}

export function getSettingsUsersClientRoleConfigLogs(payload) {
    return {
        type: SETTINGS_USERS_ROLES_CONFIG_LOGS_REQUEST,
        payload,
    };
}

export function updateFieldSettingsUsersRoles() {
    return {
        type: UPDATE_SETTINGS_USERS_ROLES_FIELD,
    };
}

const settingsUsersRolesSelector = state => state.settingsUsersRoles;
export const settingsUsersRolesTotalPagesSelector = createSelector(settingsUsersRolesSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const settingsUsersRolesListSelector = createSelector(settingsUsersRolesSelector, ({list}) => list);
export const settingsUsersRolesOptionsSelector = createSelector(settingsUsersRolesSelector, ({list}) => {
    return list.map(({id, name}) => {
        return {
            key: id,
            text: name,
            value: id,
        };
    },
    );
},
);
export const settingsUsersRolesTotalCountSelector = createSelector(settingsUsersRolesSelector, ({totalCount}) => totalCount);
export const settingsUsersRolesProgressSelector = createSelector(settingsUsersRolesSelector, ({progress}) => progress);
export const settingsUsersRolesProgressActionSelector = createSelector(settingsUsersRolesSelector, ({progressAction}) => progressAction);
export const settingsUsersRolesCountsSelector = createSelector(settingsUsersRolesSelector, ({counts}) => counts);
export const settingsUsersClientRolesConfigureLogListSelector = createSelector(settingsUsersRolesSelector, ({logs}) => logs.list);
export const settingsUsersClientRolesConfigureLogTotalCountSelector = createSelector(settingsUsersRolesSelector, ({logs}) => logs.totalCount);
export const settingsUsersClientRolesConfigureLogTotalPagesSelector = createSelector(settingsUsersRolesSelector, ({
    logs: {
        totalCount,
        pageData: {pageSize},
    },
}) => getTotalPages(totalCount, pageSize));
export const settingsUsersClientRolesConfigureLogProgressSelector = createSelector(settingsUsersRolesSelector, ({logs}) => logs.progress);

//*  SAGA  *//

const getSettingsUsersRolesRichPageSaga = function* ({payload}) {
    try {
        const clientEmploeesController = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/client/employees/get-roles-page",
            client: "/client-adm/client/employees/get-roles-page",
        });

        const {
            clientId,
            clientContractorId,
            ...reqData
        } = payload;

        const url = getBffUrl(
            {
                [LINK_SETTINGS_MEMBERS_LIST.replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.GENERAL.LINK)]: "/adm/settings/employees/roles/page",
                [LINK_SETTINGS_MEMBERS_LIST.replace(":subpage", SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK)]: "/adm/settings/employees/roles/page",
                [LINK_CLIENT_MEMBERS_LIST.replace(":clientId", clientId)]: clientEmploeesController,
                [LINK_CLIENT_MEMBERS_CARD.replace(":clientId", clientId).replace(":clientContractorId", clientContractorId)]: clientEmploeesController,
            },
            `${getController()}/rich-page`,
        );

        const {errorMessage, ...result} = yield request.bff.post(url, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_RICH_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: SETTINGS_USERS_ROLES_RICH_PAGE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_RICH_PAGE_ERROR, payload: error});
    }
};

const addSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.bff.post(`${getController()}/add`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_ADD_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_ADD_ERROR, payload: error});
    }
};

const updateSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            ...reqData
        } = payload;

        const {errorMessage, ...result} = yield request.bff.post(`${getController()}/update`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_UPDATE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_UPDATE_ERROR, payload: error});
    }
};

const deleteSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
            id,
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${getController()}/delete/${id}`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: SETTINGS_USERS_ROLES_DELETE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: SETTINGS_USERS_ROLES_DELETE_ERROR, payload: error});
    }
};

const configureSettingsUsersRoleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${getController()}/configure-client-role`, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_CONFIGURE_ROLE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_USERS_ROLES_CONFIGURE_ROLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_USERS_ROLES_CONFIGURE_ROLE_ERROR,
            payload: error,
        });
    }
};

const getSettingsUsersClientRoleConfigLogsSaga = function* ({payload}) {
    try {
        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${getController()}/client-role-config-logs`, payload);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_USERS_ROLES_CONFIG_LOGS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_USERS_ROLES_CONFIG_LOGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_USERS_ROLES_CONFIG_LOGS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_USERS_ROLES_RICH_PAGE_REQUEST, getSettingsUsersRolesRichPageSaga),
        takeEvery(SETTINGS_USERS_ROLES_ADD_REQUEST, addSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_UPDATE_REQUEST, updateSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_DELETE_REQUEST, deleteSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_CONFIGURE_ROLE_REQUEST, configureSettingsUsersRoleSaga),
        takeEvery(SETTINGS_USERS_ROLES_CONFIG_LOGS_REQUEST, getSettingsUsersClientRoleConfigLogsSaga),
    ]);
}