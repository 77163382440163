import {
    BFF_DISPUTE_EMPTY_CREATE_REQUEST,
    BFF_DISPUTE_GET_CHAT_LIST_REQUEST,
    BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST,
    BFF_DISPUTE_GET_REQUEST,
    BFF_DISPUTE_LIST_GET_REQUEST,
    BFF_DISPUTE_UPDATE_FIELD_STORE,
    BFF_DISPUTE_UPDATE_REQUEST,
    BFF_DISPUTE_UPDATE_STATUS_REQUEST,
    BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST,
    BFF_DISPUTES_CHAT_FILE_ADD_REQUEST,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST,
    BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST,
    BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST,
    BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST,
} from "./actions";

export function getDisputesList(payload) {
    return {
        type: BFF_DISPUTE_LIST_GET_REQUEST,
        payload,
    };
}

export function updateDispute(payload) {
    return {
        type: BFF_DISPUTE_UPDATE_REQUEST,
        payload,
    };
}

export function createEmptyDispute() {
    return {
        type: BFF_DISPUTE_EMPTY_CREATE_REQUEST,
    };
}

export function updateStatusDispute(payload) {
    return {
        type: BFF_DISPUTE_UPDATE_STATUS_REQUEST,
        payload,
    };
}

export function getOpenDisputesTotalCount() {
    return {
        type: BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST,
    };
}

export function getChatList(payload) {
    return {
        type: BFF_DISPUTE_GET_CHAT_LIST_REQUEST,
        payload,
    };
}

export function getDisputeById(payload) {
    return {
        type: BFF_DISPUTE_GET_REQUEST,
        payload,
    };
}

export function getDisputeFiles(payload) {
    return {
        type: BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST,
        payload,
    };
}

export function addDisputeFile(payload) {
    return {
        type: BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST,
        payload,
    };
}

export function deleteDisputeFile(payload) {
    return {
        type: BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST,
        payload,
    };
}

export function addFileToDisputesChat(payload) {
    return {
        type: BFF_DISPUTES_CHAT_FILE_ADD_REQUEST,
        payload,
    };
}

export function updateFieldDisputeStore(payload) {
    return {
        type: BFF_DISPUTE_UPDATE_FIELD_STORE,
        payload,
    };
}

export function addToDisputeChat(payload) {
    return {
        type: BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST,
        payload,
    };
}

export function getDisputesOrderListContractor(payload) {
    return {
        type: BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}