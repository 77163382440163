import {all, call, put, takeEvery} from "@redux-saga/core/effects";

import {getEdoDocumentInfoCount} from "./actionCreators";
import {
    ARCHIVE_EDO_DOCUMENT_REQUEST,
    CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST,
    GET_DOCUMENT_INFO_COUNT_ERROR,
    GET_DOCUMENT_INFO_COUNT_REQUEST,
    GET_DOCUMENT_INFO_COUNT_SUCCESS,
    GET_EDO_ACCESS_LIST_REQUEST,
    GET_EDO_ACCESS_LIST_SUCCESS,
    GET_EDO_DOCUMENT_STATES_REQUEST,
    GET_EDO_DOCUMENT_STATES_SUCCESS,
    GET_EDO_DOCUMENT_TYPES_REQUEST,
    GET_EDO_DOCUMENT_TYPES_SUCCESS,
    GET_EDO_PRE_FILLED_TEMPLATE_REQUEST,
    GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS,
    GET_PAGE_EDO_STAFF_REQUEST,
    GET_PAGE_EDO_STAFF_SUCCESS,
    GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST,
    GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS,
    REJECT_EDO_DOCUMENT_REQUEST,
    REPLACE_EDO_DOCUMENT_REQUEST,
    SEND_EDO_CODE_VERIFICATION_REQUEST,
    SIGN_EDO_DOCUMENT_ERROR,
    SIGN_EDO_DOCUMENT_REQUEST,
    SIGN_EDO_DOCUMENT_SUCCESS,
    UPDATE_EDO_TEMPLATE_REQUEST,
    UPLOAD_EDO_DOCUMENT_REQUEST,
    UPLOAD_EDO_TEMPLATE_REQUEST,
    VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST,
} from "./actions";

import {getMultipartHeaders} from "../../../../../utils/postman";
import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../../utils/url";

import {LINK_PATENTS_PAYMENTS_CARD} from "../../../../../constants/links";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/documents",
        client: "/client-adm/edo/documents",
    });
};

const getRichPageEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${getController()}/richPage`, data);

        if (errorMessage) {
            return {
                done: true,
            };
        }

        if (getSuccess) {
            getSuccess();
        }

        yield put({
            type: GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.getError) {
            payload.getError();
        }

        console.error("Method: getRichPageEdoDocumentSaga: ", error);
    }
};

const getDocumentInfoCountSaga = function* () {
    try {
        const url = getBffControllerClientCardPage({
            admin: "/adm/common/document/edocuments/info/count",
            client: "/client-adm/common/document/edocuments/info/count",
        });

        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: GET_DOCUMENT_INFO_COUNT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_DOCUMENT_INFO_COUNT_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getDocumentInfoCountSaga: ", error);
    }
};

const getEdoDocumentStatesSaga = function* () {
    try {
        const {
            errorMessage,
            ...result
        } = yield request.bff.get(`${getController()}/info/edoStates`);

        if (errorMessage) {
            return {
                done: true,
            };
        }

        yield put({type: GET_EDO_DOCUMENT_STATES_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoDocumentStatesSaga: ", error);
    }
};

const rejectEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/reject`, data);

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        if (getSuccess) {
            getSuccess();
        }

        yield put(getEdoDocumentInfoCount());
        toastSuccess("Успешно отменена");
    } catch (error) {
        console.error("Method: rejectEdoDocumentSaga: ", error);
    }
};

const signEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/sign`, data);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            errorMessage !== "SMS_CODE_ERROR" && toastError(errorMessage);

            yield put({
                type: SIGN_EDO_DOCUMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put(getEdoDocumentInfoCount());
        yield put({type: SIGN_EDO_DOCUMENT_SUCCESS});
        toastSuccess("Успешно подписан документ");
    } catch (error) {
        yield put({
            type: SIGN_EDO_DOCUMENT_ERROR,
            payload: error,
        });
    }
};

const getEdoDocumentTypesSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            ...data
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.post(`${getController()}/document-type/page`, data);

        if (errorMessage) {
            return {
                done: true,
            };
        }

        if (getSuccess) {
            getSuccess();
        }

        yield put({type: GET_EDO_DOCUMENT_TYPES_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getEdoDocumentTypesSaga: ", error);
    }
};

const getPageStaffSaga = function* ({payload}) {
    try {
        const {
            getSuccess,
            field,
            ...data
        } = payload;

        const url = getBffUrl({
            [LINK_PATENTS_PAYMENTS_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/patents/pay-patents/patent-card/users/page",
                client: "/client-adm/client/patents/pay-patents/patent-card/users/page",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/edo/staff/staff/richPage",
            adminRolesUrl: "/adm/clients/client-card/edo/staff/staff/richPage",
        },
        null,
        true,
        );
        
        const {
            errorMessage,
            ...result
        } = yield request.bff.post(url, data);

        if (errorMessage) {
            return {
                done: true,
            };
        }

        if (getSuccess) {
            getSuccess();
        }

        yield put({
            type: GET_PAGE_EDO_STAFF_SUCCESS, payload: {
                field,
                ...result,
            },
        });
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

const uploadEdoDocumentSaga = function* ({payload}) {
    const {
        meta,
        handleError,
        formData,
    } = payload;

    try {
        const {
            errorMessage,
        } = yield request.bff.post(`${getController()}/upload`, formData, {...getMultipartHeaders()});

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Документ успешно загружен");
    } catch (error) {
        if (handleError) {
            handleError();
        }

        console.error("Method: getPageStaffSaga: ", error);
    }
};

const uploadEdoDocumentByTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.post(`${getController()}/upload/template`, data);

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Документ успешно загружен");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};


const updateEdoDocumentByTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/replace/template`, data);

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: updateEdoDocumentByTemplateSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Документ успешно загружен");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

const sendCodeVerificationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/verification`, {params});

        if (getResult) {
            getResult(result);
        }

        const {
            errorMessage,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess("Код успешно отправлен");
    } catch (error) {
        console.error("Method: sendCodeVerificationSaga: ", error);
    }
};

const archiveEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            archived,
            documentId,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/${archived ? "archive" : "unArchive"}/${documentId}`);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess(`Документ успешно ${archived ? "архивирован" : "восстановлен из архива"}`);
    } catch (error) {
        console.error("Method: archiveEdoDocumentSaga: ", error);
    }
};

const replaceEdoDocumentSaga = function* ({payload}) {
    try {
        const {
            formData,
            meta,
            handleError,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/replace`, formData, {...getMultipartHeaders()});


        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            console.error("Method: replaceEdoDocumentSaga: ", errorMessage);
            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Документ успешно изменен");
    } catch (error) {
        console.error("Method: replaceEdoDocumentSaga: ", error);
    }
};

const getEdoAccessListSaga = function* ({payload = {}}) {
    const {
        getResult = () => {
        },
        onError = () => {
        },
    } = payload;

    try {
        const url = getBffControllerClientCardPage({
            admin: "/adm/common/info/edocuments/access/self",
            client: "/client-adm/common/info/edocuments/access/self",
        });

        const {
            errorMessage,
            ...result
        } = yield request.bff.get(url);

        getResult();

        if (errorMessage) {
            onError();

            return {
                done: true,
            };
        }

        yield put({
            type: GET_EDO_ACCESS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error("Method: getEdoAccessSaga: ", error);

        onError();
    }
};

const getEdoPreFilledTemplateSaga = function* ({payload}) {
    try {
        const {
            meta,
            data,
        } = payload;

        const {
            typeValidate = "",
            ...pushDAta
        } = data;

        const {errorMessage} = yield request.bff.post(`${getController()}/${typeValidate}template/validate`, pushDAta);

        if (errorMessage) {
            toastError(errorMessage);

            if (meta) {
                yield call(meta, null);
            }

            return {
                done: true,
            };
        }

        const myInit = {
            method: "post",
            body: JSON.stringify(data),
        };

        const response = yield request.bff.getFile(`${getController()}/${typeValidate}template/pre-filled`, myInit);

        const blob = yield response.blob();

        yield put({
            type: GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS,
            payload: blob,
        });

        if (meta) {
            yield call(meta, null);
        }
    } catch (error) {
        console.error("Method: getEdoAccessSaga: ", error);
    }
};

const validateEdoCompletedDocumentSaga = function* ({payload}) {
    try {
        const {
            handleSuccess,
            handleError,
            data,
        } = payload;

        const {errorMessage} = yield request.bff.post(`${getController()}/checkFile`, data);

        if (errorMessage) {
            toastError(errorMessage);

            if (handleError) {
                yield call(handleError, null);
            }

            return {
                done: true,
            };
        }

        if (handleSuccess) {
            yield call(handleSuccess, null);
        }
    } catch (error) {
        console.error("Method: validateEdoCompletedDocumentSaga: ", error);
    }
};

const checkEdoDuplicateDocumentSaga = function* ({payload}) {
    try {
        const {
            handleSuccess,
            handleError,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/checkPreviouslySent`, data);

        if (handleSuccess) {
            yield call(() => handleSuccess(result), null);
        }
    } catch (error) {
        console.error("Method: checkEdoDuplicateDocumentSaga: ", error);
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST, getRichPageEdoDocumentSaga),
        takeEvery(GET_EDO_DOCUMENT_STATES_REQUEST, getEdoDocumentStatesSaga),
        takeEvery(REJECT_EDO_DOCUMENT_REQUEST, rejectEdoDocumentSaga),
        takeEvery(SIGN_EDO_DOCUMENT_REQUEST, signEdoDocumentSaga),
        takeEvery(GET_EDO_DOCUMENT_TYPES_REQUEST, getEdoDocumentTypesSaga),
        takeEvery(GET_PAGE_EDO_STAFF_REQUEST, getPageStaffSaga),
        takeEvery(UPLOAD_EDO_DOCUMENT_REQUEST, uploadEdoDocumentSaga),
        takeEvery(SEND_EDO_CODE_VERIFICATION_REQUEST, sendCodeVerificationSaga),
        takeEvery(ARCHIVE_EDO_DOCUMENT_REQUEST, archiveEdoDocumentSaga),
        takeEvery(REPLACE_EDO_DOCUMENT_REQUEST, replaceEdoDocumentSaga),
        takeEvery(GET_EDO_ACCESS_LIST_REQUEST, getEdoAccessListSaga),
        takeEvery(GET_EDO_PRE_FILLED_TEMPLATE_REQUEST, getEdoPreFilledTemplateSaga),
        takeEvery(UPLOAD_EDO_TEMPLATE_REQUEST, uploadEdoDocumentByTemplateSaga),
        takeEvery(UPDATE_EDO_TEMPLATE_REQUEST, updateEdoDocumentByTemplateSaga),
        takeEvery(GET_DOCUMENT_INFO_COUNT_REQUEST, getDocumentInfoCountSaga),
        takeEvery(VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST, validateEdoCompletedDocumentSaga),
        takeEvery(CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST, checkEdoDuplicateDocumentSaga),
    ]);
}