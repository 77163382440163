import {
    BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_UPDATE_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS,
    BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE,
} from "./actions";

const initial = {
    list: [],
    listLoading: false,
    listTotalCount: 0,
    listPageData: {},
    progressAction: false,
    logPageData: {},
    logList: [],
    logTotalCount: 0,
    logProgress: false,
    operationTypes: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_GET_OPERATION_TYPE_SUCCESS:
            return {
                ...state,
                operationTypes: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_REQUEST:
            return {
                ...state,
                listLoading: true,
                listPageData: payload,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_SUCCESS:
            return {
                ...state,
                listLoading: false,
                list: payload.results,
                listTotalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_ERROR:
            return {
                ...state,
                listLoading: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_REQUEST: {
            return {
                ...state,
                logPageData: payload,
                logProgress: true,
            };
        }
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_SUCCESS:
            return {
                ...state,
                logList: payload.results,
                logTotalCount: payload.totalCount,
                logProgress: false,
            };
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARD_ACCOUNT_LOGS_ERROR: {
            return {
                ...state,
                logProgress: false,
            };
        }
        case BFF_RECRUITMENT_ACCESS_CONTROL_JOB_BOARD_ACCOUNTS_LOG_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case BFF_RECRUITMENT_ACCESS_CONTROL_SEARCH_JOB_BOARDS_CLEAR_STORE:
            return initial;
        default: return state;
    }
};