import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ClientNamesDropdown from "../../../../../../components/ActualComponents/ClientNamesDropdown";
import DropzoneV2 from "../../../../../../components/ActualComponents/DropzoneV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import KedoDocumentRouteStepsList from "../../../../../../components/KedoDocumentRouteStepsList";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../../../../images/add.svg";

import {useKedoDocumentRouteEditModalForm} from "./hooks/useForm";

import {getUserRole} from "../../../../../../utils/access";
import {isAccessByRestrictions} from "../../../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";
import {ADMIN} from "../../../../../../constants/roles";
import {KEDO_DOCUMENT_ROUTE_MAX_STEPS_COUNT} from "./constants";

import {kedoDirectoriesProgressActionSelector} from "../../../../../../ducks/kedo/directories/selectors";

export const KedoDocumentRouteEditModal = (props) => {
    const {
        onClose,
        clientId,
        editData,
    } = props;

    const progress = useSelector(kedoDirectoriesProgressActionSelector);

    const isEdit = !isEmpty(editData);
    const role = getUserRole();

    const {
        errors,
        handleSubmit,
        values,
        onChange,
        touched,
        onChangeStepTemplates,
        onDeleteStepsItem,
        addStepsItem,
        progressDetails,
        clientInitialOption,
    } = useKedoDocumentRouteEditModalForm({
        clientId,
        editData,
        isEdit,
    });

    const renderFieldsByRouteType = () => {
        if (values.flexRouteType) {
            return (
                <DropzoneV2
                    maxSize={10}
                    files={values.files}
                    onChange={(files) => {
                        onChange(null, {
                            value: files,
                            name: "files",
                        });
                    }}
                    multiple={false}
                    format="*.bpmn"
                    accept={[".bpmn"]}
                    error={touched?.files && errors?.files}
                />
            );
        }

        return (
            <>
                <KedoDocumentRouteStepsList
                    clientId={values.clientId}
                    name="stepTemplates"
                    list={values.stepTemplates}
                    errors={errors?.stepTemplates}
                    touched={touched?.stepTemplates}
                    onChange={onChange}
                    onChangeStepTemplates={onChangeStepTemplates}
                    onDeleteStep={onDeleteStepsItem}
                />
                {
                    values.stepTemplates.length < KEDO_DOCUMENT_ROUTE_MAX_STEPS_COUNT &&
                    <NmButton
                        size="xl"
                        onClick={addStepsItem}
                        color="light-green"
                        icon={<AddIcon />}
                    >
                        Добавить участника
                    </NmButton>
                }
            </>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {
                        isEdit
                            ? "Редактирование маршрута"
                            : "Добавление маршрута"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progress}
                    onClose={onClose}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            loading={progressDetails}
        >
            <NmForm addMargin={true}>
                {
                    (!clientId && [ADMIN].includes(role)) &&
                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                        <NmRadioV2
                            name="flexRouteType"
                            value={true}
                            checked={values.flexRouteType === true}
                            className="me-md-4 mb-2 mb-md-0"
                            label="Загрузить гибкий маршрут"
                            onChange={onChange}
                        />
                        <NmRadioV2
                            disabled={!isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsUserRouteTypeKedo,
                                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsUserRouteTypeKedo,
                            ])}
                            name="flexRouteType"
                            checked={values.flexRouteType === false}
                            value={false}
                            label="Ввести данные пользовательского маршрута"
                            onChange={onChange}
                        />
                    </div>
                }
                {
                    !clientId &&
                    <ClientNamesDropdown
                        required={true}
                        kedoModuleAvailableFilter={true}
                        label="Компания"
                        placeholder="Не выбрано"
                        onChange={onChange}
                        name="clientId"
                        value={values.clientId}
                        initialOption={clientInitialOption}
                        error={touched?.clientId && errors?.clientId}
                    />
                }
                {
                    !clientId &&
                    <NmInputV2
                        size="xl"
                        required={true}
                        name="name"
                        value={values.name}
                        onChange={onChange}
                        label="Наименование"
                        placeholder="Введите наименование"
                        maxLength={255}
                        error={touched?.name && errors?.name}
                    />
                }
                <NmInputV2
                    size="xl"
                    label={
                        clientId
                            ? "Наименование"
                            : "Наименование для компании"
                    }
                    placeholder="Введите наименование"
                    name="nameForClient"
                    value={values.nameForClient}
                    maxLength={255}
                    required={true}
                    onChange={onChange}
                    error={touched?.nameForClient && errors?.nameForClient}
                />
                {renderFieldsByRouteType()}
            </NmForm>
        </NmModal>
    );
};