import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import ForemanRequests from "./foremanRequests";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";

import {
    clearForemenRequests,
    getForemenRequestsRichPage,
    updateForemanRequest,
} from "../../../ducks/bff/clients/foremen/requests/actionCreators";

import "./style.sass";

class ForemanRequestsContainer extends Component {
    constructor(props) {
        super(props);
        const {match: {params: {clientId}}} = props;

        this.clientId = clientId;

        this.state = {
            pageNum: 1,
            pageSize: 25,
        };
    }

    componentDidMount() {
        this.fetchList();
    }

    componentWillUnmount() {
        const {clearForemenRequests} = this.props;

        clearForemenRequests();
    };

    get translate() {
        const {t} = this.props;

        return {
            buttonYes: t("button.yes"),
            buttonNo: t("button.no"),
            confirmRejectMessage: t("brigadeRequests.confirmRejectMessage"),
            confirmApplyMessage: t("brigadeRequests.confirmApplyMessage"),
            buttonConfirm: t("button.confirm"),
            buttonReject: t("button.reject"),
        };
    }

    fetchList = () => {
        const {getForemenRequestsRichPage} = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        getForemenRequestsRichPage({
            clientId: this.clientId,
            pageNum,
            pageSize,
        });
    };

    updateRequest = requestData => {
        const {updateForemanRequest} = this.props;

        updateForemanRequest({
            ...requestData,
            onSuccess: this.fetchList,
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    render() {
        const {pageNum, pageSize} = this.state;

        return (
            <ForemanRequests
                pageNum={pageNum}
                pageSize={pageSize}
                handlePaginationChange={this.handlePaginationChange}
                handleChangePageSize={this.handleChangePageSize}
                updateRequest={this.updateRequest}
                translate={this.translate}
            />
        );
    }
}

export default connect(
    null,
    {
        getForemenRequestsRichPage,
        updateForemanRequest,

        clearForemenRequests,
    },
)(withTranslation()(ForemanRequestsContainer));