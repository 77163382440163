import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const registryPaymentsSelector = state => state.bff.registryPayments;
export const bffFinancesRegistryPaymentsListSelector = createSelector(
    registryPaymentsSelector,
    ({registryPayments}) => registryPayments,
);
export const bffFinancesRegistryPaymentsProgressSelector = createSelector(
    registryPaymentsSelector,
    ({progress}) => progress,
);
export const bffFinancesRegistryPaymentsTotalCountSelector = createSelector(
    registryPaymentsSelector,
    ({totalCount}) => totalCount,
);
export const bffFinancesRegistryPaymentsTotalPagesSelector = createSelector(
    registryPaymentsSelector,
    ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize),
);
export const bffFinancesRegistryPaymentsCardSelector = createSelector(
    registryPaymentsSelector,
    ({card}) => card,
);
export const bffFinancesRegistryPaymentsProgressCardSelector = createSelector(
    registryPaymentsSelector,
    ({progressCard}) => progressCard,
);
export const bffFinancesRegistryPaymentsProgressActionSelector = createSelector(
    registryPaymentsSelector,
    ({progressAction}) => progressAction,
);
export const bffFinancesRegistryPaymentsContractorSelector = createSelector(
    registryPaymentsSelector,
    ({contractor}) => contractor,
);
export const bffFinancesRegistryPaymentsErrorSelector = createSelector(
    registryPaymentsSelector,
    ({error}) => error,
);
export const bffFinancesRegistryPaymentsContractorsSelector = createSelector(
    registryPaymentsSelector,
    ({contractors}) => contractors,
);
