import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import FilterObject from "../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../components/ActualComponents/FilterProject";
import NmDadata from "../../../components/ActualComponents/NmDadata";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmButton from "../../../components/NmButton";
import NmLoader from "../../../components/NmLoader";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {withPageData} from "../../../components/withPageData";
import OrderDetailAnalytics from "./detail";

import {
    dadataValueCityFormatter,
    getOptionsUniqCity,
} from "../../../utils/dadata";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {formatNumber} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {
    headersAwaitedContractorList,
    headersHiredContractorList,
    headersPublishedOrderList,
    headersVacancyList,
} from "../../../constants/headersTable";
import {LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS} from "../../../constants/links";
import {
    ORDER_ANALYTIC_FIELDS,
    ORDER_ANALYTIC_TYPES,
} from "../../../constants/orderAnalytics";

import {history} from "../../../store/configureStore";

import {getOrderAnalytics} from "../../../ducks/bff/clients/order-analytics/actionCreators";
import {
    orderAnalyticsProgressSelector,
    orderAnalyticsSelector,
} from "../../../ducks/bff/clients/order-analytics/selectors";
import {getFederalRegionOptionsSelector} from "../../../ducks/federalMigration";

import "./style.sass";

import {TAB_TYPE_BY_SUB_PAGE} from "../../../constants/link-params";

class OrderAnalytics extends Component {
    constructor(props) {

        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
            filterData,
        } = props;

        //тип открытого окна при возврате на страницу назад
        let type = null;

        if (!isNullOrWhitespace(filterData)) {
            type = filterData.type;
        }

        this.state = {
            ...filterData,
            isShowModal: false,
            isShowPublishedOrdersModal: type === ORDER_ANALYTIC_TYPES.PUBLISHED_ORDERS,
            isShowAwaitedContractorsModal: type === ORDER_ANALYTIC_TYPES.AWAITING_SIGNATURE,
            isShowVacanciesModal: type === ORDER_ANALYTIC_TYPES.VACANCIES,
            isShowProgressOrdersModal: type === ORDER_ANALYTIC_TYPES.ORDER_PROGRESS,
            isShowHiredContractorsModal: type === ORDER_ANALYTIC_TYPES.ORDER_CONTRACTOR_HIRED,
            isShowOrdersWithoutResponseModal: type === ORDER_ANALYTIC_TYPES.ORDERS_NOT_AWAITING,
            regionsOptions: [],
            regionsIds: [],
            detailFilter: {...filterData},
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId;
    }

    componentDidMount() {
        this.fetchOrderAnalyticList();

    }

    fetchOrderAnalyticList = () => {
        const {
            getOrderAnalytics,
        } = this.props;

        const {
            objectIdsFilter,
            projectIdsFilter,
            regionsFilter,
        } = this.state;


        const requestData = {
            objectIdsFilter: objectIdsFilter?.length ? objectIdsFilter : undefined,
            projectIdsFilter: projectIdsFilter?.length ? projectIdsFilter : undefined,
            addressFiasIds: regionsFilter?.length ? regionsFilter : undefined,
        };

        this.setState(prevState => ({
            ...prevState,
            detailFilter: requestData,
        }));

        getOrderAnalytics({
            clientId: this.clientId,
            ...requestData,
        });
    };

    handleShowWindow = (updObj) => {
        return () => {
            this.setState({
                ...updObj,
            });
        };
    };

    fillAnalitycsList = () => {
        const {
            orderAnalytics,
            t,
        } = this.props;

        const list = [];

        list.push({
            name: t("order-analytics.order-published-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.ORDER_PUBLISHED_COUNT],
            onCountClick: this.handleShowWindow({isShowPublishedOrdersModal: true, pageNum: 1, isShowModal: true}),
        });

        list.push({
            name: t("order-analytics.orders-without-response-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.ORDERS_NOT_AWAITING_COUNT],
            onCountClick: this.handleShowWindow({isShowOrdersWithoutResponseModal: true, pageNum: 1, isShowModal: true}),
        });

        list.push({
            name: t("order-analytics.contractors-needed-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.CONTRACTORS_NEEDED_COUNT],
        });

        list.push({
            name: t("order-analytics.order-response-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.AWAITING_SIGNATURE_COUNT_FOR_PUBLISHED_ORDER],
            isShowRatio: true,
            ratio: `${formatNumber(orderAnalytics[ORDER_ANALYTIC_FIELDS.AWAITING_SIGNATURE_COUNT_FOR_PUBLISHED_ORDER_RATIO], 2)}% ${t("order-analytics.from-plan")}`,
            onCountClick: this.handleShowWindow({isShowAwaitedContractorsModal: true, pageNum: 1, isShowModal: true}),
        });

        list.push({
            name: t("order-analytics.vacancies-without-response-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.VACANCIES_COUNT_FOR_PUBLISHED_ORDER],
            onCountClick: this.handleShowWindow({isShowVacanciesModal: true, pageNum: 1, isShowModal: true}),
        });

        list.push({
            name: t("order-analytics.progress-orders-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.ORDER_PROGRESS_COUNT],
            onCountClick: this.handleShowWindow({isShowProgressOrdersModal: true, pageNum: 1, isShowModal: true}),
        });

        list.push({
            name: t("order-analytics.worked-contractors-count"),
            count: orderAnalytics[ORDER_ANALYTIC_FIELDS.CONTRACTORS_HIRED_COUNT],
            onCountClick: this.handleShowWindow({isShowHiredContractorsModal: true, pageNum: 1, isShowModal: true}),
        });

        return list;
    };

    renderCount = ({count, onCountClick}) => {
        if (onCountClick) {
            return (
                <div
                    className="order-analytics__table-link"
                    onClick={onCountClick}
                >
                    {count}
                </div>
            );
        }

        return count;
    };

    getRows = () => {
        const list = this.fillAnalitycsList();

        return list.map((item, index) => {
            const {ratio, name, isShowRatio} = item;

            const percents = isShowRatio ? [{label: "Проценты", text: ratio}] : [];

            return {
                ...item,
                key: index,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={[
                            {label: "Название", inline: true, noWrap: false, text: name || "-"},
                            {label: "Количество", text: this.renderCount(item)},
                            ...percents,
                        ]}
                    />
                ),
                count: this.renderCount(item),
            };
        });
    };

    handleChange = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    handleChangeProject = (e, {name, value}) => {
        this.setState({
            [name]: value,
            objectIdsFilter: [],
        });
    };

    clearFilter = () => {
        this.setState({
            objectIdsFilter: [],
            projectIdsFilter: [],
            regionsFilter: [],
            regionsOptions: [],
            regionsIds: [],
            detailFilter: {},
        }, () => {
            this.fetchOrderAnalyticList();
        });
    };

    onSearchChange = ({fetchList, filterParam}) => {
        return (e, {searchQuery}) => {
            this.setState({[filterParam]: searchQuery}, fetchList);
        };
    };

    goResponsesPage = () => {
        const link = LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS.replace(":clientId", this.clientId)
            .replace(":subpage", TAB_TYPE_BY_SUB_PAGE.responses.LINK);

        history.push(link);
    };

    renderModalWindows() {
        const {
            isShowPublishedOrdersModal,
            isShowAwaitedContractorsModal,
            isShowVacanciesModal,
            isShowProgressOrdersModal,
            isShowHiredContractorsModal,
            isShowOrdersWithoutResponseModal,
            detailFilter,
            pageNum: pageNumFromState,
        } = this.state;

        const {
            objectIdsFilter,
            projectIdsFilter,
            addressFiasIds,
        } = detailFilter;

        const {
            t,
            pageNum,
            pageSize,
        } = this.props;

        return (
            <>
                {
                    isShowPublishedOrdersModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.PUBLISHED_ORDERS}
                        tableHeaders={headersPublishedOrderList}
                        header={t("order-analytics.published-orders")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({isShowPublishedOrdersModal: false, isShowModal: false})}
                    />
                }
                {
                    isShowOrdersWithoutResponseModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.ORDERS_NOT_AWAITING}
                        tableHeaders={headersPublishedOrderList}
                        header={t("order-analytics.orders-without-response")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({
                            isShowOrdersWithoutResponseModal: false,
                            isShowModal: false,
                        })}
                    />
                }
                {
                    isShowAwaitedContractorsModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.AWAITING_SIGNATURE}
                        tableHeaders={headersAwaitedContractorList}
                        header={t("order-analytics.orders-responses-count")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({isShowAwaitedContractorsModal: false, isShowModal: false})}
                    />
                }
                {
                    isShowVacanciesModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.VACANCIES}
                        tableHeaders={headersVacancyList}
                        header={t("order-analytics.vacancies-count")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({isShowVacanciesModal: false, isShowModal: false})}
                    />
                }
                {
                    isShowProgressOrdersModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.ORDER_PROGRESS}
                        tableHeaders={headersPublishedOrderList}
                        header={t("order-analytics.progress-orders")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({isShowProgressOrdersModal: false, isShowModal: false})}
                    />
                }
                {
                    isShowHiredContractorsModal &&
                    <OrderDetailAnalytics
                        objectIdsFilter={objectIdsFilter}
                        projectIdsFilter={projectIdsFilter}
                        regionsFilter={addressFiasIds}
                        clientId={this.clientId}
                        type={ORDER_ANALYTIC_TYPES.ORDER_CONTRACTOR_HIRED}
                        className=""
                        tableHeaders={headersHiredContractorList}
                        header={t("order-analytics.hired-contractors-count")}
                        pageData={{pageNum: pageNumFromState || pageNum, pageSize}}
                        handleClose={this.handleShowWindow({isShowHiredContractorsModal: false, isShowModal: false})}
                    />
                }
            </>
        );
    }

    renderAnalyticsFilter() {
        const {
            t,
        } = this.props;

        const {
            projectIdsFilter,
            objectIdsFilter,
            regionsIds,
        } = this.state;

        return (
            <NmForm addMargin>
                <FilterProject
                    clientId={this.clientId}
                    isAllSelectionShown={false}
                    label={t("order-analytics.project")}
                    isClearable
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="projectIdsFilter"
                    multiple
                    onChange={this.handleChangeProject}
                    search
                    projectIdsFilter={projectIdsFilter}
                    placeholder={t("orders-filter.show-all")}
                />
                <FilterObject
                    isAllSelectionShown={false}
                    clientId={this.clientId}
                    selectOnBlur={false}
                    isClearable
                    noResultsMessage={t("orders-filter.no-search-result")}
                    name="objectIdsFilter"
                    multiple
                    onChange={this.handleChange}
                    search
                    projectIdsFilter={projectIdsFilter}
                    objectIdsFilter={objectIdsFilter}
                    placeholder={t("orders-filter.show-all")}
                />
                <NmDadata
                    size="lg"
                    label={t("order-analytics.city")}
                    multiple
                    name="regionsFilter"
                    onChange={this.handleChange}
                    formatter={dadataValueCityFormatter}
                    optionsFormatter={getOptionsUniqCity}
                    fromBound="city"
                    toBound="settlement"
                    isAllSelectionShown={false}
                    initialAddressIds={regionsIds}
                />
                <FilterButtonsV2
                    className="order-analytics__filter-actions"
                    onSearch={this.fetchOrderAnalyticList}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    render() {
        const {
            progress,
            t,
        } = this.props;
        const {
            isShowModal,
        } = this.state;

        return (
            <div className="order-analytics">
                {this.renderModalWindows()}
                <NmPage
                    header={
                        <NmTitle size="xl">
                            {t("client-orders-common.analytics")}
                        </NmTitle>
                    }
                    className="fluid-flex-grow"
                    typeFilter="vertical"
                    filtersBase={this.renderAnalyticsFilter()}
                >
                    {
                        !isShowModal &&
                        <NmLoader
                            inverted
                            active={progress}
                        />
                    }
                    <CheckboxList
                        className="order-analytics__list"
                        rows={this.getRows()}
                    />
                    <div className="order-analytics__button-container">
                        <NmButton
                            color="light-green"
                            className="order-analytics__button"
                            onClick={this.goResponsesPage}
                            size="xl"
                        >
                            Показать все отклики
                        </NmButton>
                    </div>
                </NmPage>
            </div>
        );
    };
}

export default withPageData(connect(
    state => ({
        progress: orderAnalyticsProgressSelector(state),
        orderAnalytics: orderAnalyticsSelector(state),
        federalRegionsOptions: getFederalRegionOptionsSelector(state),
    }),
    {
        getOrderAnalytics,
    },
)(withTranslation()(OrderAnalytics)));