import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmPage from "../../../../components/NmPage";
import {withPageData} from "../../../../components/withPageData";
import ArrivalNoticeCard2 from "../../../contractor/contractor_info/arrival-notice-card-2";
import ContractorFullPersonalData2 from "../../../contractor/contractor_info/contractor-full-personal-data-2";
import EmploymentHistory from "../../../contractor/contractor_info/employment-history";
import EmploymentPatent2 from "../../../contractor/contractor_info/employment-patent-2";
import MigrationCard2 from "../../../contractor/contractor_info/migration-card-v2";
import ContractorInfoNoAccessPersonalData from "../../../contractor/contractor_info/no-access-personal-data";
import SnilsInfo from "../../../contractor/contractor_info/snils-info";
import VhiPolicy2 from "../../../contractor/contractor_info/vhi-policy-2";
import ContractorScanList from "../../../contractor/contractor_scan_list";
import EdmStaffCardClientUserForm from "../edm-staff-card-client-user-form";

import {getContractorParentDocumentScans, getContractorScans} from "../../../../utils/contractors";
import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../../constants/clientList";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {getEdmStaffById} from "../../../../ducks/bff/clients/edo/staff/actionCreators";
import {edmStaffCardProgressSelector, edmStaffCardSelector} from "../../../../ducks/bff/clients/edo/staff/selectors";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {getInfoContractorCard} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {contractorCardInfoSelector} from "../../../../ducks/bff/contractor-сard/selectors";
import {
    clientMemberCardSelector,
    clientMemberProgressSelector,
    getClientMemberById,
} from "../../../../ducks/clientMember";
import {
    contractorProgressSelector,
    updateFieldContractorStore,
} from "../../../../ducks/contractor";
import {getFileContractorScan} from "../../../../ducks/fileStore";

import "./style.sass";

class EdmStaffCardPersonalData extends Component {
    static propTypes = {};

    static defaultProps = {};

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    staffId,
                },
            },
        } = props;

        this.clientId = clientId;
        this.staffId = staffId;

        this.role = ls(USER_ROLE);

        this.scanReqExecuted = false;
    };

    componentDidMount() {
        this.fetchStaffData();
    };

    componentDidUpdate(prevProps) {
        const {
            contractor,
            staffCard: {
                contractorId,
            },
        } = this.props;

        const {
            passportType,
            citizenship,
        } = contractor;

        if (citizenship && contractorId && !this.scanReqExecuted) {
            this.scanReqExecuted = true;
            this.fetchContractorScan(citizenship, passportType);
        }
    }

    componentWillUnmount() {
        const {
            updateFieldContractorStore,
        } = this.props;

        updateFieldContractorStore({card: {}});
    };

    initDocumentTypeFilter() {
    };

    fetchStaffData = () => {
        const {
            getEdmStaffById,
        } = this.props;

        getEdmStaffById({
            data: {
                clientId: this.clientId,
                staffId: this.staffId,
            },
            onSuccess: this.fetchUserData,
        });
    };

    get isClientRole() {
        return [CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, HR_MANAGER].includes(this.role);
    }

    fetchContractorScan = (citizenship, passportType, hasPendingPersonalData = false) => {
        const {
            getFileContractorScan,
            contractor,
            clientProperties: {hasAccessToContractorPDFiles},
        } = this.props;

        const {
            contractorId,
        } = contractor;

        if (this.isClientRole && !hasAccessToContractorPDFiles) {
            return;
        }

        getContractorScans({
            contractorId,
            getFileContractorScan,
            citizenship,
            passportType,
            hasPendingPersonalData,
            exceptionsForFetch: {
                [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: this.isClientRole,
            },
            registrationStatus: contractor.registrationStatus,
            isPreviouslyIssuedPassportsInfoRequired: contractor.isPreviouslyIssuedPassportsInfoRequired,
        });

        getContractorParentDocumentScans(contractor, getFileContractorScan, hasPendingPersonalData);
    };

    fetchUserData = () => {
        const {
            staffCard: {
                contractorId,
                clientUserId,
            },
            getInfoContractorCard,
            getClientMemberById,
        } = this.props;

        if (isEmpty(contractorId)) {
            getClientMemberById({clientUserId});
        } else {
            getInfoContractorCard(contractorId);
        }
    };


    updateState = (newStateFields) => {
        return () => {
            this.setState({
                ...newStateFields,
            });
        };
    };

    mapTableData = () => {
        return {
            headers: [],
            rows: [],
        };
    };

    getScans() {
        const {
            contractor: {
                contractorId,
            },
            clientProperties: {hasAccessToContractorPDFiles},
            hasPendingPersonalData,
        } = this.props;

        if (this.isClientRole && !hasAccessToContractorPDFiles) {
            return null;
        }

        return (
            <div className="col-16 col-xxl-4 mt-4 mt-md-5 mt-xxl-0">
                <ContractorScanList
                    contractorId={contractorId}
                    hasPendingPersonalData={hasPendingPersonalData}
                    isEditableAlwaysDisabled
                />
            </div>
        );
    }

    renderContractorInfoBlock() {
        const {
            contractor,
        } = this.props;

        const {
            citizenship,
            personalDataAccessGranted,
            contractorId,
        } = contractor;

        if (this.isClientRole && !personalDataAccessGranted) {
            return (
                <ContractorInfoNoAccessPersonalData
                    contractorId={contractorId}
                />
            );
        }

        const isRu = citizenship === citizenshipsDict.RU.value;
        const isBy = citizenship === citizenshipsDict.BY.value;
        const isUzOrTj = [citizenshipsDict.UZ.value, citizenshipsDict.TJ.value].includes(citizenship);

        return (
            <div className="row">
                <div className="col-16 col-xxl-12">
                    <div className="row">
                        <div className="col-16 mb-4 mb-md-5">
                            <ContractorFullPersonalData2
                                isClientRole={this.isClientRole}
                                isEditable={false}
                                citizenship={citizenship}
                            />
                        </div>
                        {
                            isRu ?
                                <div className="edm-staff-personal-data__row">
                                    <div className="edm-staff-personal-data__column">
                                        <SnilsInfo
                                            className="edm-staff-personal-data__item"
                                            isEditable={false}
                                        />
                                    </div>
                                </div> :
                                <>
                                    <div className="col-16 col-md-8">
                                        {
                                            !isBy &&
                                            <MigrationCard2
                                                isEditable={false}
                                                className="edm-staff-personal-data__item"
                                            />
                                            // <MigrationCard
                                            //     isEditable={false}
                                            //     className="edm-staff-personal-data__item"
                                            // />
                                        }
                                        <ArrivalNoticeCard2
                                            className="edm-staff-personal-data__item"
                                            isEditable={false}
                                            card={contractor}
                                        />
                                        {
                                            isUzOrTj &&
                                            <VhiPolicy2
                                                className="edm-staff-personal-data__item"
                                                isEditable={false}
                                                card={contractor}
                                            />
                                        }
                                    </div>
                                    <div className="col-16 col-md-8 mt-3 mt-md-4 mt-xxl-0">
                                        {
                                            isUzOrTj &&
                                            <EmploymentHistory
                                                className="edm-staff-personal-data__item"
                                                isEditable={false}
                                            />
                                        }
                                        <SnilsInfo
                                            className="edm-staff-personal-data__item"
                                            isEditable={false}
                                        />
                                        {
                                            isUzOrTj &&
                                            <EmploymentPatent2
                                                className="edm-staff-personal-data__item"
                                                isEditable={false}
                                                card={contractor}
                                            />
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </div>
                {this.getScans()}
            </div>
        );
    };

    renderClientUserInfoBlock() {
        const {
            clientUser,
        } = this.props;

        return (
            <div className="row">
                <div className="col-16 col-xxl-6">
                    <EdmStaffCardClientUserForm
                        clientUser={clientUser}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            staffCard: {
                clientUserId,
                contractorId,
            },
            clientUser,
            contractor,
            staffProgress,
            contractorProgress,
            clientUserProgress,
        } = this.props;

        return (
            <NmPage
                className="edm-staff-personal-data"
                showHeaderBlock={false}
                isLoaded={staffProgress || contractorProgress || clientUserProgress}
            >
                {!isEmpty(contractor) && contractorId && this.renderContractorInfoBlock()}
                {!isEmpty(clientUser) && clientUserId && this.renderClientUserInfoBlock()}
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        staffCard: edmStaffCardSelector(state),
        staffProgress: edmStaffCardProgressSelector(state),
        contractorProgress: contractorProgressSelector(state),
        clientUserProgress: clientMemberProgressSelector(state),
        contractor: contractorCardInfoSelector(state),
        clientUser: clientMemberCardSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
    }),
    {
        getEdmStaffById,
        getFileContractorScan,
        getClientMemberById,
        updateFieldContractorStore,
        getInfoContractorCard,
    },
)(withTranslation()(EdmStaffCardPersonalData)));
