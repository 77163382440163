import {
    BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
    BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST,
    BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS,
    BFF_BANK_TKB_UPDATE_302_ERROR,
    BFF_BANK_TKB_UPDATE_302_REQUEST,
    BFF_BANK_TKB_UPDATE_302_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    data: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS: {
            const {
                histories,
                totalCount,
                ...otherData
            } = payload;

            return {
                ...state,
                list: histories,
                totalCount: totalCount,
                data: otherData,
                progress: false,
            };
        }
        case BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_BANK_TKB_UPDATE_302_ERROR: {
            return {
                ...state,
                progressAction: false,
            };
        }
        case BFF_BANK_TKB_UPDATE_302_REQUEST: {
            return {
                ...state,
                progressAction: true,
            };
        }
        case BFF_BANK_TKB_UPDATE_302_SUCCESS: {
            const {result} = payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    account302Balance: result,
                },
                progressAction: false,
            };
        }
        default:
            return state;
    }
};