import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";

import {getUserRole} from "../../../../utils/access";

import {
    KEDO_DOCUMENT_ROUTES,
    KEDO_LOAD_DOCUMENT_TYPES,
    KEDO_POSITIONS,
    KEDO_SUBDIVISIONS,
    KEDO_TEMPLATE_DOCUMENT_TYPES,
} from "../../../../constants/links";
import {
    ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../constants/roles";

import {kedoDirectoriesCountsSelector} from "../../../../ducks/kedo/directories/selectors";


export const KedoDirectoriesTabs = (props) => {
    const {
        clientId,
        isSearch,
        baseUrl,
    } = props;

    const role = getUserRole();

    const counts = useSelector(kedoDirectoriesCountsSelector);

    const {
        templateDocumentTypesCount,
        uploadedDocumentTypesCount,
        positionsCount,
        subdivisionsCount,
        routesCount,
    } = counts;

    const tabs = useMemo(() => {
        return [
            {
                active: [KEDO_LOAD_DOCUMENT_TYPES],
                link: `${baseUrl}${KEDO_LOAD_DOCUMENT_TYPES}`.replace(":clientId", clientId),
                name: "Типы загруженных документов",
                count: isSearch && typeof props.uploadedDocumentTypesCount === "number"
                    ? props.uploadedDocumentTypesCount
                    : uploadedDocumentTypesCount,
            },
            {
                active: [KEDO_TEMPLATE_DOCUMENT_TYPES],
                link: `${baseUrl}${KEDO_TEMPLATE_DOCUMENT_TYPES}`.replace(":clientId", clientId),
                name: "Типы шаблонных документов",
                count: isSearch && typeof props.templateDocumentTypesCount === "number"
                    ? props.templateDocumentTypesCount
                    : templateDocumentTypesCount,
                isVisible: [
                    ADMIN,
                    NM_MANAGER,
                    NM_OPERATOR,
                ].includes(role),
            },
            {
                active: [KEDO_POSITIONS],
                link: `${baseUrl}${KEDO_POSITIONS}`.replace(":clientId", clientId),
                name: "Должности",
                count: isSearch && typeof props.positionsCount === "number"
                    ? props.positionsCount
                    : positionsCount,
            },
            {
                active: [KEDO_SUBDIVISIONS],
                link: `${baseUrl}${KEDO_SUBDIVISIONS}`.replace(":clientId", clientId),
                name: "Структурные подразделения",
                count: isSearch && typeof props.subdivisionsCount === "number"
                    ? props.subdivisionsCount
                    : subdivisionsCount,
            },
            {
                active: [KEDO_DOCUMENT_ROUTES],
                link: `${baseUrl}${KEDO_DOCUMENT_ROUTES}`.replace(":clientId", clientId),
                name: "Маршруты подписания",
                count: isSearch && typeof props.routesCount === "number"
                    ? props.routesCount
                    : routesCount,
            },
        ].filter(Boolean);
    }, [
        counts,
        isSearch,
        props.uploadedDocumentTypesCount,
        props.templateDocumentTypesCount,
        props.positionsCount,
        props.subdivisionsCount,
        props.routesCount,
    ]);

    return (
        <Tabs
            {...props}
            className="mb-6"
            panes={tabs}
        />
    );
};