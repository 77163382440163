import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

//*  TYPES  *//

const ADD_DOCUMENTS_EXPORT_REQUEST = "ADD_DOCUMENTS_EXPORT_REQUEST";
const ADD_DOCUMENTS_EXPORT_SUCCESS = "ADD_DOCUMENTS_EXPORT_SUCCESS";
const ADD_DOCUMENTS_EXPORT_ERROR = "ADD_DOCUMENTS_EXPORT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    documentsExportPage: [],
    progressPage: false,
    totalCount: 0,
    pageData: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//


export function addDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const documentsExport = state => state.documentsExport;
export const getDocumentExportTotalCountSelector = createSelector(documentsExport, ({totalCount}) => totalCount);

//*  SAGA  *//

export const addDocumentsExportSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post("job/export/addDocExport", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: ADD_DOCUMENTS_EXPORT_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_DOCUMENTS_EXPORT_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(ADD_DOCUMENTS_EXPORT_REQUEST, addDocumentsExportSaga),
    ]);
}
