import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {nanoid} from "nanoid";

import NmDivider from "../../../../../components/ActualComponents/NmDivider";
import OrderEditFormPublishClientInfo from "./components/client-info";
import OrderEditFormPublishClientInfoRating from "./components/client-info-rating";
import OrderEditFormPublishLabelText from "./components/label-text";
import OrderEditFormPublishLogo from "./components/logo";
import OrderEditFormPublishRating from "./components/rating";

import formatDate from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {
    formatAmount,
    formatNumber,
    formatNumberWithComma,
    getNumberFromFormattedAmount,
} from "../../../../../utils/stringFormat";

import {ORDER_FIELDS_NAME} from "../../../../../constants/clientList";
import {COLOR} from "../../../../../constants/color";

import {clientCardInfoSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {
    documentCustomTemplateAggregationShortTemplatesSelector,
} from "../../../../../ducks/bff/documents-templates/selectors";
import {clientObjectListSelector} from "../../../../../ducks/clientObject";
import {downloadDocument} from "../../../../../ducks/documents";
import {unitsAbbreviationDictSelector} from "../../../../../ducks/order";

import PropTypes from "prop-types";

import "./style.sass";

class OrderEditPublish extends Component {
    static propTypes = {
        editOrderId: PropTypes.string,
    };

    static defaultProps = {
        editOrderId: "",
    };

    constructor(props) {
        super(props);
        this.state = {
            objectInfo: {},
            objectBrandLogo: null,
        };
    }

    role = ls(USER_ROLE);

    get translate() {
        const {t} = this.props;

        return {
            checkParametersText: t("order-new.checkParametersText"),
            infoHeadText: t("order-new.infoHeadText"),
            infoFoodText: t("order-new.infoFoodText"),
            companyText: t("order-new.companyText"),
            contactPersonText: t("order-new.contactPersonText"),
            resultLabel: t("order-new.resultLabel"),
            deadlineLabel: t("order-new.deadlineLabel"),
            readAllText: t("order-card.read-completely"),
            descriptionLabel: t("order-new.descriptionLabel"),
            placeOfWorkLabel: t("order-new.placeOfWorkLabel"),
            customerLabel: t("order-new.customerLabel"),
            contactPersonLabel: t("order-new.contactPersonLabel"),
            responseButton: t("order-new.responseButton"),
            periodLabel: t("order-new.periodLabel"),
            statusSearchPerformers: t("order-new.statusSearchPerformers"),
            statusInWork: t("order-new.statusInWork"),
        };
    }

    componentDidMount() {
        const {
            formData,
            objectList,
            downloadDocument,
        } = this.props;

        const objectInfo = objectList.find(item => item.objectId === formData.objectId) || {};

        if (objectInfo.brandId) {
            this.setState({
                objectInfo,
            });

            downloadDocument({
                isDownload: false,
                getResultFile: (file) => {
                    const objectBrandLogo = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    },
                    );

                    this.setState({objectBrandLogo});
                },
                downloadLink: `/bff/adm/download-client-files/getClientBrandLogoThumbnailById?brandId=${objectInfo.brandId}`,
                isShowFileNotFoundErrorMessage: false,
            });
        }
    }

    renderDescription() {
        const {formData: {description = ""}} = this.props;
        const {readAllText} = this.translate;
        const isTooLong = description.length > 100;

        return (
            <>
                {isTooLong ? `${description.slice(0, 97)}... ` : description}
                {isTooLong ? <div className="order-edit-publish__read">
                    {readAllText}
                </div> : null}
            </>
        );
    }

    get description() {
        const {formData} = this.props;

        if (formData[ORDER_FIELDS_NAME.DESCRIPTION].length > 100) {
            return `${formData[ORDER_FIELDS_NAME.DESCRIPTION].slice(0, 97)}... `;
        }
    }

    renderMobViewKeywords = () => {
        const {formData: {keywords}} = this.props;

        let maxWidth = 375;
        const moreWidth = 60;
        let moreCount = keywords.length;
        const wordPadding = 14;
        const wordMargin = 5;
        const wordBorderWidth = 2;
        const symbolSize = 9.8;

        return keywords.length !== 0 &&
            <div className="order-edit-publish__keywords">
                {keywords.map(({content: keyword}) => {
                    const keywordSize = keyword.length * symbolSize + wordPadding + wordMargin + wordBorderWidth;

                    if (maxWidth - moreWidth > keywordSize) {
                        moreCount -= 1;
                        maxWidth = maxWidth - keywordSize;

                        return (
                            <div
                                key={nanoid(4)}
                                className="order-edit-publish__keyword"
                            >
                                {keyword}
                            </div>
                        );
                    }
                })}
                {
                    !!moreCount &&
                    <div className="order-edit-publish__keyword">
                        {`И еще ${moreCount}`}
                    </div>
                }
            </div>;
    };

    renderKeywords = () => {
        const {formData: {keywords}} = this.props;

        return keywords.length !== 0 &&
            <div className="order-edit-publish__keywords">
                {keywords.map(({content: keyword}) => {
                    return (
                        <div
                            key={nanoid(4)}
                            className="order-edit-publish__keyword order-edit-publish__keyword_no-mob"
                        >
                            {keyword}
                        </div>
                    );
                },
                )}
            </div>;
    };

    renderThings() {
        const {
            formData: {
                needMedicalBook,
                driverLicenseTypes,
                contractorHasCar,
                needDriverLicense,
                needUniform,
            },
        } = this.props;

        if (
            !needMedicalBook &&
            driverLicenseTypes.length === 0 &&
            !contractorHasCar &&
            !needDriverLicense &&
            !needUniform
        ) {
            return null;
        }

        const things = [];

        if (needUniform) {
            things.push("Униформа");
        }

        if (needMedicalBook) {
            things.push("Медицинская книжка");
        }

        if (needDriverLicense) {
            const types = driverLicenseTypes.join(", ");
            const strTypes = types ? ` ${types}` : "";

            things.push(`Водительские права${strTypes}`);
        }

        if (contractorHasCar) {
            things.push("Личный автомобиль");
        }

        return (
            <OrderEditFormPublishLabelText
                className="mb-2"
                label="Должны быть"
                text={things.join(", ")}
            />
        );
    }

    renderCustomerProvides() {
        const {
            formData: {
                toolsProvided,
                overallsProvided,
                canTransferContractorToObject,
                canTransferContractorFromObject,
                foodTypes,
                hasChangeHouse,
            },
        } = this.props;

        if (
            !toolsProvided &&
            !overallsProvided &&
            !canTransferContractorToObject &&
            !canTransferContractorFromObject &&
            foodTypes.length === 0 &&
            !hasChangeHouse
        ) {
            return null;
        }

        const provided = [];

        if (canTransferContractorToObject) {
            provided.push("Трансфер исполнителя на объект");
        }

        if (canTransferContractorFromObject) {
            provided.push("Трансфер исполнителя с объекта");
        }

        if (foodTypes.includes("BREAKFAST")) {
            provided.push("Завтрак");
        }

        if (foodTypes.includes("LUNCH")) {
            provided.push("Обед");
        }

        if (foodTypes.includes("DINNER")) {
            provided.push("Ужин");
        }

        if (hasChangeHouse) {
            provided.push("Комната для переодевания и хранения вещей");
        }

        if (overallsProvided) {
            provided.push("Спецодежда");
        }

        if (toolsProvided) {
            provided.push("Инструменты");
        }

        return (
            <OrderEditFormPublishLabelText
                className="mb-2"
                label="Заказчик предоставляет"
                text={provided.join(", ")}
            />
        );
    }

    getSpeciality = () => {
        const {
            formData: {
                specialityId,
            },
            offeredSpecialityName,
            specialitiesOptions,
        } = this.props;

        if (offeredSpecialityName) {
            return offeredSpecialityName;
        }

        const {text} = specialitiesOptions.find(item => (item.value === specialityId)) || {};

        return text;
    };

    getTariffIndicator = (mobile) => {
        const {formData: {workUnitPrice}, scale} = this.props;

        if (typeof scale !== "number") {
            return null;
        }

        const price = getNumberFromFormattedAmount(workUnitPrice);

        if (price < scale) {
            return null;
        }


        return (
            <div
                className={
                    mobile ?
                        "order-edit-publish__tariff-indicator order-edit-publish__tariff-indicator_mobile" :
                        "order-edit-publish__tariff-indicator"
                }
            >
                Хорошая цена
            </div>
        );
    };

    renderDocumentTemplates = () => {
        const {
            formData: {
                frameContractTemplateId,
                orderApplicationTemplateId,
                actOfAcceptanceOfWorkTemplateId,
            },
            aggregationShortTemplates,
        } = this.props;

        return [
            {value: frameContractTemplateId, name: "frameContractTemplateId", content: "Шаблон договора"},
            {value: orderApplicationTemplateId, name: "orderApplicationTemplateId", content: "Шаблон заявки"},
            {value: actOfAcceptanceOfWorkTemplateId, name: "actOfAcceptanceOfWorkTemplateId", content: "Шаблон акта"},
        ].map((item, index) => {
            const {
                value,
                name,
                content,
            } = item;

            if (!value) {
                return null;
            }

            const templateOptions = aggregationShortTemplates[name] || [];
            const {text} = templateOptions.find(item => (item.value === value)) || {};

            return (
                <OrderEditFormPublishLabelText
                    key={index}
                    className="mb-2"
                    label={content}
                    text={text}
                />
            );
        });
    };

    renderMobileView({clientName}) {
        const {
            clientInfo: {
                base64Logo,
            },
            formData,
            formData: {
                workUnitPrice,
                workUnit,
                volumeOfWork,
                contractorsNeededCount,
                contractorsNeededCountSecondType,
                orderNum,
                name: orderName,
            },
            calculatorData: {
                amountPerOne = 0,
            },
            isSecondType,
            abbreviationMap,
            documentFileUrl,
            isDisabledSpeciality,
        } = this.props;
        const {
            objectInfo,
            objectBrandLogo,
        } = this.state;
        const {
            infoHeadText,
            infoFoodText,
            responseButton,
        } = this.translate;

        const period = `${formatDate(formData[ORDER_FIELDS_NAME.WORK_START_DATE], "dd.MM.yyyy")} - ${formatDate(formData[ORDER_FIELDS_NAME.WORK_END_DATE], "dd.MM.yyyy")}`;
        const contractorsCount = isSecondType ? contractorsNeededCountSecondType : contractorsNeededCount;
        const volumePerOne = (getNumberFromFormattedAmount(volumeOfWork) / contractorsCount).toFixed(2);
        const speciality = this.getSpeciality();

        const unit = abbreviationMap.get(workUnit);

        return (
            <div className="order-edit-publish__column">
                <div className="order-edit-publish__description">
                    <div className="order-edit-publish__description-head">
                        {infoHeadText}
                    </div>
                    <div className="order-edit-publish__description-foot">
                        {infoFoodText}
                    </div>
                </div>
                <div className="order-edit-publish__card-app order-edit-publish__card-app_mobile">
                    <div className="order-edit-publish__card-app-mobile-header">
                        <div className="d-flex align-items-center">
                            <OrderEditFormPublishLogo
                                base64Logo={base64Logo}
                                brandId={objectInfo.brandId}
                                objectBrandLogo={objectBrandLogo}
                            />
                            <div className="order-edit-publish__card-app-mobile-client-name">
                                {objectInfo.brandName ? objectInfo.brandName : clientName}
                            </div>
                        </div>
                        <OrderEditFormPublishRating
                            classNameValue="order-edit-publish__card-app-mobile-rating-value"
                            className="order-edit-publish__card-app-mobile-rating"
                        />
                    </div>
                    <div className="order-edit-publish__card-app-mobile-content">
                        <div className="order-edit-publish__sum order-edit-publish__sum_fixed">
                            <div className="order-edit-publish__sum-value order-edit-publish__sum-value_mobile">
                                {formatAmount(formatNumber(amountPerOne))}
                                {" "}
₽
                            </div>
                            {this.getTariffIndicator(true)}
                            {
                                isSecondType ?
                                    <div className="order-edit-publish__second-type">
                                        <div className="order-edit-publish__sum-row order-edit-publish__sum-row_mobile">
                                            {workUnitPrice}
                                            {" "}
₽/
                                            {unit}
                                        </div>
                                        <div className="order-edit-publish__sum-row order-edit-publish__sum-row_mobile">
~
                                            {volumePerOne} 
                                            {" "}
                                            {unit}
                                        </div>
                                    </div> :
                                    null
                            }
                        </div>
                        <div className="order-edit-publish__name order-edit-publish__name_mobile">
                            <span>
                                {orderNum ? `№${orderNum}: ` : "№000: "}
                            </span>
                            {orderName}
                        </div>
                        <div className="order-edit-publish__period">
                            {period}
                        </div>
                        {
                            !isDisabledSpeciality &&
                            <div className="order-edit-publish__speciality">
                                <span className="order-edit-publish__speciality-name">
                                    {"Вид деятельности: "}
                                </span>
                                <span className="order-edit-publish__speciality-value">
                                    {speciality}
                                </span>
                            </div>
                        }
                        <div className="order-edit-publish__address">
                            {formData[ORDER_FIELDS_NAME.ADDRESS]}
                        </div>
                        {this.renderMobViewKeywords()}
                        {
                            documentFileUrl &&
                            <div className="d-flex">
                                <div className="order-edit-publish__keyword mt-2">
                                    Документы по форме заказчика
                                </div>
                            </div>
                        }
                        <div className="order-edit-publish__response mt-3">
                            {responseButton}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderGeneralView() {
        const {
            clientInfo: {
                clientId,
                name,
                brand,
                base64Logo,
            },
            formData,
            formData: {
                additionalTerms,
                workUnitPrice,
                workUnit,
                volumeOfWork,
                contractorsNeededCount,
                contractorsNeededCountSecondType,
                orderNum,
                name: orderName,
            },
            managerFirstName,
            calculatorData: {
                amountPerOne = 0,
                amountToCard = 0,
                performerTax = 0,
                performerCommission = 0,
            },
            isSecondType,
            abbreviationMap,
            documentFileUrl,
            isDuplicateOrder,
            metroInfo,
            isDisabledSpeciality,
        } = this.props;
        const {
            objectInfo,
            objectBrandLogo,
        } = this.state;
        const {
            descriptionLabel,
            resultLabel,
            placeOfWorkLabel,
            customerLabel,
            contactPersonLabel,
            responseButton,
            periodLabel,
        } = this.translate;

        const period = `${formatDate(formData[ORDER_FIELDS_NAME.WORK_START_DATE], "dd.MM.yyyy")} - ${formatDate(formData[ORDER_FIELDS_NAME.WORK_END_DATE], "dd.MM.yyyy")}`;
        const contractorsCount = isSecondType ? contractorsNeededCountSecondType : contractorsNeededCount;
        const volumePerOne = (getNumberFromFormattedAmount(volumeOfWork) / contractorsCount).toFixed(2);
        const title = orderNum && !isDuplicateOrder ? `№${orderNum}: ${orderName}` : `№000: ${orderName}`;
        const speciality = this.getSpeciality();

        const unit = abbreviationMap.get(workUnit);

        return (
            <div className="order-edit-publish__column">
                <div className="order-edit-publish__description order-edit-publish__description_full">
                    <div className="order-edit-publish__description-head order-edit-publish__description-head_no-mb">
                        {resultLabel}
                    </div>
                </div>
                <div className="order-edit-publish__card-app order-edit-publish__card-app_full">
                    <div className="order-edit-publish__name">
                        {title}
                    </div>
                    <div className="order-edit-publish__sum order-edit-publish__sum_un-fixed">
                        <div className="order-edit-publish__amount">
                            <div className="order-edit-publish__sum-value">
                                {formatAmount(formatNumber(amountPerOne))}
                                {" "}
₽
                            </div>
                            {this.getTariffIndicator()}
                            {
                                isSecondType &&
                                <div className="order-edit-publish__second-type">
                                    <div className="order-edit-publish__sum-row">
                                        {workUnitPrice}
                                        {" "}
₽/
                                        {unit}
                                        {" "}
(~
                                        {volumePerOne} 
                                        {" "}
                                        {unit}
)
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="order-edit-publish__sum-stats">
                            <div className="order-edit-publish__sum-row">
                                На карту: 
                                {" "}
                                {formatNumberWithComma(amountToCard)}
                                {" "}
₽
                            </div>
                            <div className="order-edit-publish__sum-row">
                                Налог: 
                                {" "}
                                {formatNumberWithComma(performerTax)}
                                {" "}
₽
                            </div>
                            <div className="order-edit-publish__sum-row order-edit-publish__sum-row">
                                Комиссия: 
                                {" "}
                                {formatNumberWithComma(performerCommission)}
                                {" "}
₽
                            </div>
                        </div>
                    </div>
                    <NmDivider
                        className="mb-4"
                        color={COLOR.SECONDARY_5}
                    />
                    <OrderEditFormPublishClientInfoRating
                        clientId={clientId}
                        label={customerLabel}
                        className="mb-4"
                    />
                    <OrderEditFormPublishClientInfo
                        className="mb-4"
                        base64Logo={base64Logo}
                        brand={brand}
                        clientName={name}
                        contactPersonLabel={contactPersonLabel}
                        managerFirstName={managerFirstName}
                        objectInfo={objectInfo}
                        objectBrandLogo={objectBrandLogo}
                    />
                    <NmDivider
                        className="mb-4"
                        color={COLOR.SECONDARY_5}
                    />
                    <OrderEditFormPublishLabelText
                        className="mb-2"
                        label={periodLabel}
                        text={period}
                    />
                    {
                        isSecondType &&
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Общий объем работ"
                            text={`${volumeOfWork} ${unit}`}
                        />
                    }
                    <OrderEditFormPublishLabelText
                        className="mb-2"
                        label={descriptionLabel}
                        text={this.renderDescription()}
                    />
                    <OrderEditFormPublishLabelText
                        className="mb-2"
                        label={placeOfWorkLabel}
                        text={
                            <>
                                {formData[ORDER_FIELDS_NAME.ADDRESS]}
                                <div className="order-edit-publish__show-on-map">
Показать на карте
                                </div>
                            </>
                        }
                    />
                    {
                        metroInfo &&
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Ближайшие станции метро"
                            text={metroInfo}
                        />
                    }
                    {this.renderThings()}
                    {/*{this.renderCustomerProvides()}*/}
                    {
                        additionalTerms &&
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Дополнительные условия"
                            text={additionalTerms}
                        />
                    }
                    {/*{this.renderDocumentTemplates()}*/}
                    {
                        documentFileUrl &&
                        <div className="order-edit-publish__documentation">
                            Документация к заказу
                            <span className="material-icons order-edit-publish__icon">
                                        navigate_next
                            </span>
                        </div>
                    }
                    {
                        !isDisabledSpeciality &&
                        <OrderEditFormPublishLabelText
                            className="mb-2"
                            label="Вид деятельности"
                            text={speciality}
                        />

                    }
                    {this.renderKeywords()}
                    <div className="order-edit-publish__response">
                        {responseButton}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            clientInfo,
        } = this.props;
        const {
            checkParametersText,
        } = this.translate;

        const clientName = clientInfo.brand ? clientInfo.brand : clientInfo.name;

        return (
            <div className="order-edit-publish">
                <div className="order-edit-publish__header">
                    {checkParametersText}
                </div>
                <div className="order-edit-publish__content">
                    {this.renderMobileView({clientName})}
                    {this.renderGeneralView()}
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    aggregationShortTemplates: documentCustomTemplateAggregationShortTemplatesSelector(state),
    clientInfo: clientCardInfoSelector(state),
    abbreviationMap: unitsAbbreviationDictSelector(state),
    objectList: clientObjectListSelector(state),
}), {
    downloadDocument,
})(withTranslation()(OrderEditPublish));