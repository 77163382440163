import React from "react";

import {ReactComponent as CertificateFromUniversity} from "../../../images/contractor-files/certificate-from-university.svg";
import {ReactComponent as EmploymentPatentBackSideScan} from "../../../images/contractor-files/employment-patent-back-side-scan.svg";
import {ReactComponent as EmploymentPatentFrontSideScan} from "../../../images/contractor-files/employment-patent-front-side-scan.svg";
import {ReactComponent as Inn} from "../../../images/contractor-files/inn.svg";
import {ReactComponent as InternalPersonalDataBackSide} from "../../../images/contractor-files/internal-personal-data-back-side.svg";
import {ReactComponent as InternalPersonalDataFrontSide} from "../../../images/contractor-files/internal-personal-data-front-side.svg";
import {ReactComponent as MainDocumentStampAddress} from "../../../images/contractor-files/main-document-stamp-address.svg";
import {ReactComponent as MigrationCard} from "../../../images/contractor-files/migration-card.svg";
import {ReactComponent as MigrationCardBackSide} from "../../../images/contractor-files/migration-card-back-side.svg";
import {ReactComponent as NotificationOfArrivalFirst} from "../../../images/contractor-files/notification-of-arrival-first.svg";
import {ReactComponent as NotificationOfArrivalSecond} from "../../../images/contractor-files/notification-of-arrival-second.svg";
import {ReactComponent as PaidEmploymentCheckScan} from "../../../images/contractor-files/paid-employment-check-scan.svg";
import {ReactComponent as ParentsConsentToProccesingPersonalData} from "../../../images/contractor-files/parents-consent-to-proccesing-personal-data.svg";
import {ReactComponent as ParentsConsentToTransactionsByMinor} from "../../../images/contractor-files/parents-consent-to-transactions-by-minor.svg";
import {ReactComponent as InternationalPersonalData} from "../../../images/contractor-files/passport/international-personal-data.svg";
import {ReactComponent as InternationalPersonalDataBelorussian} from "../../../images/contractor-files/passport/international-personal-data_by.svg";
import {ReactComponent as InternationalPersonalDataUa} from "../../../images/contractor-files/passport/international-personal-data_ua.svg";
import {ReactComponent as InternationalPersonalDataBirthPlacePage} from "../../../images/contractor-files/passport/international-personal-data-birth-place-page.svg";
import {ReactComponent as RefugeeCertificateExtensionMark} from "../../../images/contractor-files/passport/refugee-certificate-extension-mark.svg";
import {ReactComponent as RefugeeCertificateSpread} from "../../../images/contractor-files/passport/refugee-certificate-spread.svg";
import {ReactComponent as StatelessPersonTemporaryIdPersonalDataSpread} from "../../../images/contractor-files/passport/stateless-person-temporaryId-personal-data-spread.svg";
import {ReactComponent as TemporaryRefugeeCertificatePersonalData} from "../../../images/contractor-files/passport/temporary-refugee-certificate-personal-data.svg";
import {ReactComponent as PassportSelfieInternationalForeign} from "../../../images/contractor-files/passport-selfie/passort-selfie_international-foreign.svg";
import {ReactComponent as PassportSelfieInternalBy} from "../../../images/contractor-files/passport-selfie/passport-selfie_internal-by.svg";
import {ReactComponent as PassportSelfieInternalForeign} from "../../../images/contractor-files/passport-selfie/passport-selfie_internal-foreign.svg";
import {ReactComponent as PassportSelfieInternalUa} from "../../../images/contractor-files/passport-selfie/passport-selfie_internal-ua.svg";
import {ReactComponent as PassportSelfieNoCitizenship} from "../../../images/contractor-files/passport-selfie/passport-selfie_no-citizenship.svg";
import {ReactComponent as PassportSelfieRefugee} from "../../../images/contractor-files/passport-selfie/passport-selfie_refugee.svg";
import {ReactComponent as PassportSelfieRu} from "../../../images/contractor-files/passport-selfie/passport-selfie_ru.svg";
import {ReactComponent as PassportSelfieTemporaryRefugee} from "../../../images/contractor-files/passport-selfie/passport-selfie_temporary-refugee.svg";
import {ReactComponent as PassportTranslation} from "../../../images/contractor-files/passport-translation.svg";
import {ReactComponent as PassportTranslationNotarization} from "../../../images/contractor-files/passport-translation-notarization.svg";
import {ReactComponent as PersonalData} from "../../../images/contractor-files/personal-data.svg";
import {ReactComponent as PlasticVhiPolicyScanBackSideCompulsory} from "../../../images/contractor-files/plastic-vhi-policy-scan-back-side_compulsory.svg";
import {ReactComponent as PlasticVhiPolicyScanBackSideVoluntary} from "../../../images/contractor-files/plastic-vhi-policy-scan-back-side_voluntary.svg";
import {ReactComponent as PlasticVhiPolicyScanFrontSideCompulsory} from "../../../images/contractor-files/plastic-vhi-policy-scan-front-side_compulsory.svg";
import {ReactComponent as PlasticVhiPolicyScanFrontSideVoluntary} from "../../../images/contractor-files/plastic-vhi-policy-scan-front-side_voluntary.svg";
import {ReactComponent as PreviouslyIssuedPassportsInfoScan} from "../../../images/contractor-files/previously-issued-passoprts-info-scan.svg";
import {ReactComponent as Residence} from "../../../images/contractor-files/residence.svg";
import {ReactComponent as ResidencePermit} from "../../../images/contractor-files/residence-permit.svg";
import {ReactComponent as StatelessPersonResidencePermitPersonalData} from "../../../images/contractor-files/stateless-person-residence-permit-personal-data.svg";
import {ReactComponent as TemporaryResidencePermitPersonalData} from "../../../images/contractor-files/temporary-residence-permit-personal-data.svg";
import {ReactComponent as UniversityAccreditationBackSide} from "../../../images/contractor-files/university-accreditation-back-side.svg";
import {ReactComponent as UniversityAccreditationFrontSide} from "../../../images/contractor-files/university-accreditation-front-side.svg";
import {ReactComponent as UniversityLicenseBackSide} from "../../../images/contractor-files/university-license-back-side.svg";
import {ReactComponent as UniversityLicenseFrontSide} from "../../../images/contractor-files/university-license-front-side.svg";
import {ReactComponent as UniversityScheduleForSemester} from "../../../images/contractor-files/university-schedule-for-semester.svg";
import {ReactComponent as VhiPolicyScanCompulsory} from "../../../images/contractor-files/vhi-policy-scan_compulsory.svg";
import {ReactComponent as VhiPolicyScanVoluntary} from "../../../images/contractor-files/vhi-policy-scan_voluntary.svg";
import {ReactComponent as Visa} from "../../../images/contractor-files/visa.svg";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_VHI_POLICE_TYPE} from "../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE} from "../../../constants/contractorInfo";

export const getFullRegistrationFileExamples = (params) => {
    const {
        fileType,
        citizenship,
        passportType,
        vhiPolicyType,
    } = params;

    const examples = {
        [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR]: <ParentsConsentToTransactionsByMinor />,
        [CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA]: <ParentsConsentToProccesingPersonalData />,
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SPREAD]: <RefugeeCertificateSpread />,
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_EXTENSION_MARK]: <RefugeeCertificateExtensionMark />,
        [CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO]: <PersonalData />,
        [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE]: <InternalPersonalDataFrontSide />,
        [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE]: <InternalPersonalDataBackSide />,
        [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA]: getInternationalPassportExample({
            citizenship,
        }),
        [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE]: <InternationalPersonalDataBirthPlacePage />,
        [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA]: <TemporaryRefugeeCertificatePersonalData />,
        [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_PERSONAL_DATA]: <TemporaryRefugeeCertificatePersonalData />,
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_PERSONAL_DATA_SPREAD]: <StatelessPersonTemporaryIdPersonalDataSpread />,
        [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: <MigrationCard />,
        [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: <MigrationCardBackSide />,
        [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST]: <NotificationOfArrivalFirst />,
        [CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND]: <NotificationOfArrivalSecond />,
        [CONTRACTOR_FILE_TYPES.MAIN_DOCUMENT_STAMP_ADDRESS]: <MainDocumentStampAddress />,
        [CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD]: <Visa />,
        [CONTRACTOR_FILE_TYPES.REFUGEE_CERTIFICATE_SELFIE]: <PassportSelfieRefugee />,
        [CONTRACTOR_FILE_TYPES.TEMPORARY_REFUGEE_CERTIFICATE_SELFIE]: <PassportSelfieTemporaryRefugee />,
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_TEMPORARY_ID_SELFIE]: <PassportSelfieNoCitizenship />,
        [CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN]: getPassportSelfie({
            citizenship,
            passportType,
        }),
        [CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT]: <ResidencePermit />,
        [CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION]: <Residence />,
        [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: vhiPolicyType === CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY ?
            <VhiPolicyScanCompulsory /> :
            <VhiPolicyScanVoluntary />,
        [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE]: vhiPolicyType === CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY ?
            <PlasticVhiPolicyScanFrontSideCompulsory /> :
            <PlasticVhiPolicyScanFrontSideVoluntary />,
        [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE]: vhiPolicyType === CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY ?
            <PlasticVhiPolicyScanBackSideCompulsory /> :
            <PlasticVhiPolicyScanBackSideVoluntary />,
        [CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA]: <TemporaryResidencePermitPersonalData />,
        [CONTRACTOR_FILE_TYPES.INN]: <Inn />,
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE]: <UniversityLicenseFrontSide />,
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE]: <UniversityLicenseBackSide />,
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE]: <UniversityAccreditationFrontSide />,
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE]: <UniversityAccreditationBackSide />,
        [CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER]: <UniversityScheduleForSemester />,
        [CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY]: <CertificateFromUniversity />,
        [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: <EmploymentPatentFrontSideScan />,
        [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: <EmploymentPatentBackSideScan />,
        [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: <PaidEmploymentCheckScan />,
        [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA]: <StatelessPersonResidencePermitPersonalData />,
        [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION] : <PassportTranslation />,
        [CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION]: <PassportTranslationNotarization />,
        [CONTRACTOR_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]: <PreviouslyIssuedPassportsInfoScan />,
    };

    return examples[fileType];
};

const getInternationalPassportExample = (params) => {
    const {
        citizenship,
    } = params;

    if (citizenship === citizenshipsDict.BY.value) {
        return <InternationalPersonalDataBelorussian />;
    }

    if (citizenship === citizenshipsDict.UA.value) {
        return <InternationalPersonalDataUa />;
    }

    return <InternationalPersonalData />;
};

const getPassportSelfie = (params) => {
    const {
        citizenship,
        passportType,
    } = params;

    if (citizenship === citizenshipsDict.RU.value) {
        return <PassportSelfieRu />;
    }

    if (citizenship === citizenshipsDict.BY.value && passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
        return <PassportSelfieInternalBy />;
    }

    if (citizenship === citizenshipsDict.UA.value && passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
        return <PassportSelfieInternalUa />;
    }

    if (passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT) {
        return <PassportSelfieInternalForeign />;
    }

    return <PassportSelfieInternationalForeign />;
};