import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_COMMON_ORDER_OBJECTS_ERROR,
    BFF_COMMON_ORDER_OBJECTS_REQUEST,
    BFF_COMMON_ORDER_OBJECTS_SUCCESS,
    BFF_COMMON_ORDER_PROJECTS_ERROR,
    BFF_COMMON_ORDER_PROJECTS_REQUEST,
    BFF_COMMON_ORDER_PROJECTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const getController = () => {
    return (
        getBffUrl({
            isClientCard: true,
            clientRolesUrl: "/client-adm/common/order",
            adminRolesUrl: "/adm/common/order",
        })
    );
};

// POST /bff/adm/common/order/filters/objects/names/page
const getCommonOrderObjectsSaga = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}/filters/objects/names/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_ORDER_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_ORDER_OBJECTS_SUCCESS,
            payload: result,
        });
    } catch (event) {
        yield put({
            type: BFF_COMMON_ORDER_OBJECTS_ERROR,
            payload: event,
        });
    }
};

// POST /bff/adm/common/order/filters/projects/names/page
const getCommonProjects = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}/filters/projects/names/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_COMMON_ORDER_PROJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_COMMON_ORDER_PROJECTS_SUCCESS,
            payload: result,
        });
    } catch (event) {
        yield put({
            type: BFF_COMMON_ORDER_PROJECTS_ERROR,
            payload: event,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_COMMON_ORDER_OBJECTS_REQUEST, getCommonOrderObjectsSaga),
        takeEvery(BFF_COMMON_ORDER_PROJECTS_REQUEST, getCommonProjects),
    ]);
}