import React, {useEffect, useState} from "react";
import Media from "react-media";

import {getCreateTypeDescription} from "../../containers/registries/registryActsType";
import FilterButtonsV2 from "../ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmListCard from "../ActualComponents/NmList/Card";
import NmModal from "../ActualComponents/NmModal";
import NmPagination from "../ActualComponents/NmPagination";
import CheckboxList from "../CheckboxList";
import NmFilterAccordion from "../NmFilterAccordion";
import NmHint from "../NmHint";
import NmTitle from "../NmTitle";

import {useFilter} from "../../hooks/useFilter";
import {usePagination} from "../../hooks/usePagination";

import bem from "../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../utils/dateFormat";
import {phoneFormat, removePhoneMask} from "../../utils/stringFormat";
import {handleFormString} from "../../utils/stringHelper";

import {ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../constants/finance";
import {EVENT_REGISTRY_TYPE} from "../../constants/status";

import "./style.sass";

const initFilter = {
    contractorFioFilter: "",
    contractorPhoneFilter: "",
    logEndDateFilter: null,
    logStartDateFilter: null,
};

function ActRegistryLog(props) {
    const {
        handleClose,
        ordinalNumber,
        name,
        clientId,
        registryId,
        patentRegistryId,
        list,
        totalPages,
        totalCount,
        onFetchList,
        onClearStore,
        isRegistryPatents,
    } = props;

    const [block, element] = bem("act-registry-log");

    const [isSearch, setIsSearch] = useState(false);
    const {filter, onChangeFilter, setFilter} = useFilter({initFilter});
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        fetchList();
        return () => {
            onClearStore();
        };
    }, [pageNum, pageSize, isSearch]);

    const fetchList = () => {
        onFetchList({
            clientId,
            registryId: isRegistryPatents ? patentRegistryId : registryId,
            pageNum,
            pageSize,
            contractorFioFilter: handleFormString(contractorFioFilter),
            contractorPhoneFilter: handleFormString(removePhoneMask(contractorPhoneFilter)),
            logEndDateFilter: logEndDateFilter === null ? undefined : formatDate(logEndDateFilter, "yyyy-MM-dd"),
            logStartDateFilter: logStartDateFilter === null ? undefined : formatDate(logStartDateFilter, "yyyy-MM-dd"),
        });
    };

    const {
        contractorPhoneFilter,
        contractorFioFilter,
        logEndDateFilter,
        logStartDateFilter,
    } = filter;

    function renderPagination() {
        return (
            <NmPagination
                className={element("pagination")}
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePageSize={onChangePageSize}
                onChangePagination={onPaginationChange}
            />
        );
    }

    const clearForm = () => {
        setIsSearch(false);
        setFilter({...initFilter});
    };

    function renderEmpty() {
        if (!isSearch) {
            return <div>
Нет записей
            </div>;
        }
        return <NmEmptyPageV2 isSearch={isSearch} />;
    }

    const submitFilter = () => {
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    function getLabelsErrorData(item) {
        const labels = [];

        if (item.dataResponse) {
            const {
                success,
                errorMessage,
            } = JSON.parse(item.dataResponse);

            if (!success && errorMessage) {
                labels.push({
                    label: "Операция не выполнена. Причина",
                    text: errorMessage,
                    noWrap: false,
                    columnOnMobile: true,
                    flexWrap: true,
                });
            }
        }

        return labels;
    }
    function getLabelsData(item) {
        const labels = [];

        if (item.managerFio && item.eventType !== "UPDATE_REGISTRY") {
            labels.push({
                label: "Ответственный",
                text: item.managerFio,
                noWrap: false,
                columnOnMobile: true,
            });
        }

        if (item.contractorFio) {
            labels.push({
                label: "Исполнитель",
                text: item.contractorFio,
                noWrap: false,
                columnOnMobile: true,
            });
        }
        if (item.contractorPhone && item.eventType !== "CANCEL_FAILED_ROWS_REGISTRY") {
            labels.push({
                label: "Телефон",
                text: phoneFormat(item.contractorPhone),
                noWrap: false,
                columnOnMobile: true,
            });
        }
        if (item.foremanFio || item.contractorFio) {
            labels.push({
                label: "Инициатор",
                text: item.foremanFio ? item.foremanFio : item.contractorFio,
                noWrap: false,
                columnOnMobile: true,
            });
        }

        if (item.data) {
            const {
                createActsOfAcceptanceOfWorkType,
                totalCount,
                orderContractPaymentType,
                rows,
            } = JSON.parse(item.data);

            if(orderContractPaymentType){
                labels.push({
                    label: "Тип",
                    text: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType],
                    noWrap: false,
                    columnOnMobile: true,
                });
            }

            if(createActsOfAcceptanceOfWorkType){
                labels.push({
                    label: "Формировать акты выполненных работ",
                    text: getCreateTypeDescription(createActsOfAcceptanceOfWorkType),
                    noWrap: false,
                    columnOnMobile: true,
                });
            }

            if(item.eventType === "DELETE_SELECTED_ROWS_REGISTRY" && !patentRegistryId){
                labels.push({
                    label: "Формировать акты выполненных работ",
                    text: getCreateTypeDescription(createActsOfAcceptanceOfWorkType),
                    noWrap: false,
                    columnOnMobile: true,
                });
            }

            if (item.eventType === "IMPORT_REGISTRY") {
                labels.push({
                    label: "Кол-во добавленных строк",
                    text: isRegistryPatents ?
                        item.dataResponse || "-" :
                        item.data,
                    noWrap: false,
                    columnOnMobile: true,
                });
            }

            if (item.eventType === "DELETE_SELECTED_ROWS_REGISTRY") {
                labels.push({
                    label: "Кол-во удалённых строк",
                    text: isRegistryPatents ?
                        totalCount :
                        item.data || "",
                    noWrap: false,
                    columnOnMobile: true,
                });
            }

            if (item.eventType === "CANCEL_FAILED_ROWS_REGISTRY" && rows?.length) {
                labels.push({
                    label: "Телефон",
                    text: rows.map(({contractorPhone}) => contractorPhone).join(","),
                    noWrap: false,
                    columnOnMobile: true,
                });

                labels.push({
                    label: "Количество отмененных строк",
                    text: totalCount,
                    noWrap: false,
                    columnOnMobile: true,
                });
            }
        }
        return labels;
    }

    function getCardPrimaryHeader(item) {
        if (item.eventType === "IS_DUPLICATE_REGISTRY") {
            const {
                parentRegistryId,
            } = JSON.parse(item.data);

            return `Создан реестр как дубликат от ${parentRegistryId}`;
        }

        return EVENT_REGISTRY_TYPE[item.eventType];
    }

    function getCards() {
        return list.map(item => {
            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={getCardPrimaryHeader(item)}
                        labels={[
                            ...getLabelsErrorData(item),
                            {
                                label: "Дата",
                                text: formatDate(convertUtcToLocal(item.dateTime), "dd.MM.yyyy HH:mm:ss"),
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            ...getLabelsData(item),
                        ]}
                    />
                ),
            };
        });
    }

    function renderFilter() {
        return (
            <NmForm
                horizontal
                className={element("form")}
            >
                <NmDateRangePickerV2
                    label="Период события"
                    size="lg"
                    className={element("date")}
                    startFieldName="logStartDateFilter"
                    endFieldName="logEndDateFilter"
                    value={{
                        logStartDateFilter,
                        logEndDateFilter,
                    }}
                    isClearable={true}
                    onChange={onChangeFilter}
                    suffix="EditReport"
                />
                <NmInputV2
                    size="lg"
                    className={element("input")}
                    name="contractorFioFilter"
                    onChange={onChangeFilter}
                    value={contractorFioFilter}
                    placeholder="ФИО исполнителя"
                    label="ФИО исполнителя"
                />
                <NmInputV2
                    size="lg"
                    mask="+7 (999) 999 99 99"
                    label="Номер телефона"
                    className={element("input")}
                    name="contractorPhoneFilter"
                    value={contractorPhoneFilter}
                    placeholder="Телефон"
                    onChange={onChangeFilter}
                />
                <FilterButtonsV2
                    onSearch={submitFilter}
                    onClear={clearForm}
                />
            </NmForm>
        );
    }

    const getTitle = () => {
        if (ordinalNumber) {
            return `Лог по реестру #${ordinalNumber} - ${name}`;
        }

        return `Лог по реестру - ${name}`;
    };

    return (
        <NmModal
            className={block()}
            onClose={handleClose}
            dsadds={() => {
                return 22 >= 0;
            }}
            header={
                <NmTitle size="lg">
                    {getTitle()}
                </NmTitle>
            }
        >
            <div className={element("content")}>
                <NmFilterAccordion renderFilter={renderFilter} />
                {list.length !== 0 ?
                    <CheckboxList rows={getCards()} /> :
                    renderEmpty()
                }
                {renderPagination()}
            </div>
        </NmModal>
    );
}

export default ActRegistryLog;