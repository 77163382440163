export const GET_FC_REGISTRIES_PAGE_REQUEST = "GET_FC_REGISTRIES_PAGE_REQUEST";
export const GET_FC_REGISTRIES_PAGE_SUCCESS = "GET_FC_REGISTRIES_PAGE_SUCCESS";
export const GET_FC_REGISTRIES_PAGE_ERROR = "GET_FC_REGISTRIES_PAGE_ERROR";

export const GET_FC_REGISTRY_BY_ID_REQUEST = "GET_FC_REGISTRY_BY_ID_REQUEST";
export const GET_FC_REGISTRY_BY_ID_SUCCESS = "GET_FC_REGISTRY_BY_ID_SUCCESS";
export const GET_FC_REGISTRY_BY_ID_ERROR = "GET_FC_REGISTRY_BY_ID_ERROR";

export const GET_FC_REGISTRY_ITEMS_PAGE_REQUEST = "GET_FC_REGISTRY_ITEMS_PAGE_REQUEST";
export const GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS = "GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS";
export const GET_FC_REGISTRY_ITEMS_PAGE_ERROR = "GET_FC_REGISTRY_ITEMS_PAGE_ERROR";

export const GET_FC_REGISTRY_STATUS_DICT_REQUEST = "GET_FC_REGISTRY_STATUS_DICT_REQUEST";
export const GET_FC_REGISTRY_STATUS_DICT_SUCCESS = "GET_FC_REGISTRY_STATUS_DICT_SUCCESS";
export const GET_FC_REGISTRY_STATUS_DICT_ERROR = "GET_FC_REGISTRY_STATUS_DICT_ERROR";

export const GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST = "GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST";
export const GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS = "GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS";
export const GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR = "GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR";

export const ARCHIVE_FC_REGISTRY_REQUEST = "ARCHIVE_FC_REGISTRY_REQUEST";
export const ARCHIVE_FC_REGISTRY_SUCCESS = "ARCHIVE_FC_REGISTRY_SUCCESS";
export const ARCHIVE_FC_REGISTRY_ERROR = "ARCHIVE_FC_REGISTRY_ERROR";

export const SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST = "SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST";
export const SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS = "SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS";
export const SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR = "SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR";

export const RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST = "RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST";
export const RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS = "RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS";
export const RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR = "RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR";

export const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST";
export const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS";
export const DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR = "DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR";

export const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST";
export const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS";
export const GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR = "GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR";

export const ADD_ITEM_INTO_FC_REGISTRY_REQUEST = "ADD_ITEM_INTO_FC_REGISTRY_REQUEST";
export const ADD_ITEM_INTO_FC_REGISTRY_SUCCESS = "ADD_ITEM_INTO_FC_REGISTRY_SUCCESS";
export const ADD_ITEM_INTO_FC_REGISTRY_ERROR = "ADD_ITEM_INTO_FC_REGISTRY_ERROR";

export const CANCEL_FC_REGISTRY_ITEM_REQUEST = "CANCEL_FC_REGISTRY_ITEM_REQUEST";
export const CANCEL_FC_REGISTRY_ITEM_SUCCESS = "CANCEL_FC_REGISTRY_ITEM_SUCCESS";
export const CANCEL_FC_REGISTRY_ITEM_ERROR = "CANCEL_FC_REGISTRY_ITEM_ERROR";

export const IMPORT_ITEMS_FC_REGISTRY_REQUEST = "IMPORT_ITEMS_FC_REGISTRY_REQUEST";
export const IMPORT_ITEMS_FC_REGISTRY_SUCCESS = "IMPORT_ITEMS_FC_REGISTRY_SUCCESS";
export const IMPORT_ITEMS_FC_REGISTRY_ERROR = "IMPORT_ITEMS_FC_REGISTRY_ERROR";

export const EXPORT_FC_REGISTRY_REQUEST = "EXPORT_FC_REGISTRY_REQUEST";
export const EXPORT_FC_REGISTRY_SUCCESS = "EXPORT_FC_REGISTRY_SUCCESS";
export const EXPORT_FC_REGISTRY_ERROR = "EXPORT_FC_REGISTRY_ERROR";

export const DELETE_FC_REGISTRY_ITEMS_REQUEST = "DELETE_FC_REGISTRY_ITEMS_REQUEST";
export const DELETE_FC_REGISTRY_ITEMS_SUCCESS = "DELETE_FC_REGISTRY_ITEMS_SUCCESS";
export const DELETE_FC_REGISTRY_ITEMS_ERROR = "DELETE_FC_REGISTRY_ITEMS_ERROR";

export const GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST = "GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST";
export const GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS = "GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS";
export const GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR = "GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR";

export const ADD_REGISTRY_FRAME_CONTRACT_REQUEST = "ADD_REGISTRY_FRAME_CONTRACT_REQUEST";
export const ADD_REGISTRY_FRAME_CONTRACT_SUCCESS = "ADD_REGISTRY_FRAME_CONTRACT_SUCCESS";
export const ADD_REGISTRY_FRAME_CONTRACT_ERROR = "ADD_REGISTRY_FRAME_CONTRACT_ERROR";

export const UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST = "UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST";
export const UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS = "UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS";
export const UPDATE_REGISTRY_FRAME_CONTRACT_ERROR = "UPDATE_REGISTRY_FRAME_CONTRACT_ERROR";

export const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST";
export const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS";
export const ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR = "ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR";

export const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST";
export const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS";
export const UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR = "UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR";

export const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST";
export const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS";
export const ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR = "ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR";