import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const patentsRegistriesSelector = state => state.bff.clientsPatentsPay;
export const patentsRegistriesListSelector = createSelector(patentsRegistriesSelector, ({registries}) => registries);
export const patentsRegistriesProgressListSelector = createSelector(patentsRegistriesSelector, ({progressList}) => progressList);
export const patentsRegistriesTotalCountSelector = createSelector(patentsRegistriesSelector, ({totalCount}) => totalCount);
export const patentsRegistriesListTotalPagesSelector = createSelector(patentsRegistriesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const patentsRegistriesActionRegistryProgressSelector = createSelector(patentsRegistriesSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const patentsRegistryCardSelector = createSelector(patentsRegistriesSelector, ({card}) => card);
export const patentsRegistryArchivedParamSelector = createSelector(patentsRegistriesSelector, ({card: {archived = false}}) => archived);
export const patentsRegistriesProgressCardSelector = createSelector(patentsRegistriesSelector, ({progressCard}) => progressCard);
export const patentsRegistryPaymentAbility = createSelector(patentsRegistriesSelector, ({paymentAbilityPatentContractor}) => {
    return paymentAbilityPatentContractor;
});
export const registryPatentsLogTotalCountSelector = createSelector(patentsRegistriesSelector, ({log: {totalCount}}) => totalCount);
export const registryPatentsLogTotalPagesSelector = createSelector(patentsRegistriesSelector,
    ({log: {totalCount, pageData: {pageSize = 0}}}) => getTotalPages(totalCount, pageSize));
export const registryPatentsLogsListSelector = createSelector(patentsRegistriesSelector, ({log: {list}}) => list);