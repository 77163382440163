import {
    BFF_DISPUTE_EMPTY_CREATE_ERROR,
    BFF_DISPUTE_EMPTY_CREATE_REQUEST,
    BFF_DISPUTE_EMPTY_CREATE_SUCCESS,
    BFF_DISPUTE_GET_CHAT_LIST_ERROR,
    BFF_DISPUTE_GET_CHAT_LIST_REQUEST,
    BFF_DISPUTE_GET_CHAT_LIST_SUCCESS,
    BFF_DISPUTE_GET_ERROR,
    BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS,
    BFF_DISPUTE_GET_REQUEST,
    BFF_DISPUTE_GET_SUCCESS,
    BFF_DISPUTE_LIST_GET_ERROR,
    BFF_DISPUTE_LIST_GET_REQUEST,
    BFF_DISPUTE_LIST_GET_SUCCESS,
    BFF_DISPUTE_UPDATE_ERROR,
    BFF_DISPUTE_UPDATE_FIELD_STORE,
    BFF_DISPUTE_UPDATE_REQUEST,
    BFF_DISPUTE_UPDATE_STATUS_ERROR,
    BFF_DISPUTE_UPDATE_STATUS_REQUEST,
    BFF_DISPUTE_UPDATE_STATUS_SUCCESS,
    BFF_DISPUTE_UPDATE_SUCCESS,
    BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS,
    BFF_DISPUTES_CHAT_FILE_ADD_ERROR,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS,
    BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS,
    BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS,
    BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR,
    BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS,
} from "./actions";

const initialState = {
    guid: "",
    dispute: {},
    disputeLoading: false,
    disputeList: [],
    totalCount: 0,
    totalCountOpen: 0,
    actionProgress: false,
    loading: false,
    pageData: {},
    isDisputeSaveSuccess: false,
    isDisputeChangeStatusSuccess: false,
    totalCountDisputesOpen: 0,
    isAddToChatSuccess: false,
    chatList: [],
    disputeImages: [],
    error: null,
    isSuccessAddFile: false,
    isSuccessDeleteFile: false,
    orderContractors: {
        pageData: {},
        list: [],
        totalCount: 0,
    },
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_DISPUTE_LIST_GET_REQUEST:
            return {
                ...state,
                pageData: payload,
                isDisputeSaveSuccess: false,
                loading: true,
            };
        case BFF_DISPUTE_LIST_GET_SUCCESS:
            return {
                ...state,
                disputeList: payload.disputes,
                totalCount: payload.totalCount,
                totalCountOpen: payload.totalCountOpen,
                totalCountCloseCancel: payload.totalCountCloseCancel,
                loading: false,
            };
        case BFF_DISPUTE_UPDATE_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case BFF_DISPUTE_UPDATE_SUCCESS:
            return {
                ...state,
                isDisputeSaveSuccess: true,
                actionProgress: false,
            };
        case BFF_DISPUTE_GET_CHAT_LIST_ERROR:
        case BFF_DISPUTE_UPDATE_STATUS_ERROR:
        case BFF_DISPUTE_LIST_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        case BFF_DISPUTE_EMPTY_CREATE_ERROR:
        case BFF_DISPUTE_UPDATE_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case BFF_DISPUTE_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case BFF_DISPUTE_EMPTY_CREATE_REQUEST:
            return {
                ...state,
                guid: "",
                isDisputeSaveSuccess: false,
                actionProgress: true,
                dispute: {},
            };
        case BFF_DISPUTE_EMPTY_CREATE_SUCCESS:
            return {
                ...state,
                guid: payload.guid,
                actionProgress: false,
            };
        case BFF_DISPUTE_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                isDisputeChangeStatusSuccess: false,
                loading: true,
            };
        case BFF_DISPUTE_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                isDisputeChangeStatusSuccess: true,
                loading: false,
            };
        case BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS:
            return {
                ...state,
                totalCountDisputesOpen: payload,
            };
        case BFF_DISPUTE_GET_CHAT_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                isAddToChatSuccess: false,
                loading: true,
            };
        case BFF_DISPUTE_GET_CHAT_LIST_SUCCESS:
            return {
                ...state,
                chatList: payload.disputes,
                totalCount: payload.totalCount,
                totalCountOpen: payload.totalCountOpen,
                loading: false,
            };
        case BFF_DISPUTE_GET_REQUEST:
            return {
                ...state,
                disputeLoading: true,
            };
        case BFF_DISPUTE_GET_SUCCESS:
            return {
                ...state,
                disputeLoading: false,
                dispute: payload,
            };
        case BFF_DISPUTE_GET_ERROR:
            return {
                ...state,
                disputeLoading: false,
                error: payload,
            };
        case BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS:
            return {
                ...state,
                disputeImages: payload,
            };
        case BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR:
            return {
                ...state,
                error: payload,
            };
        case BFF_DISPUTES_CHAT_FILE_ADD_ERROR:
            return {
                ...state,
                error: payload,
            };
        case BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS:
            return {
                ...state,
                isAddToChatSuccess: true,
            };
        case BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST:
            return {
                ...state,
                orderContractors: {
                    pageData: payload,
                },
            };
        case BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS:
            const {
                orderContractors,
                totalCount,
            } = payload;

            return {
                ...state,
                ...payload,
                orderContractors: {
                    list: orderContractors,
                    totalCount,
                },
            };
        case BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS:
            return {
                ...state,
                isSuccessAddFile: true,
            };
        case BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS:
            return {
                ...state,
                isSuccessDeleteFile: true,
            };
        default:
            return state;
    }
};