export const REGISTRY_PAYMENT_GET_PAGE_REQUEST = "REGISTRY_PAYMENT_GET_PAGE_REQUEST";
export const REGISTRY_PAYMENT_GET_PAGE_SUCCESS = "REGISTRY_PAYMENT_GET_PAGE_SUCCESS";
export const REGISTRY_PAYMENT_GET_PAGE_ERROR = "REGISTRY_PAYMENT_GET_PAGE_ERROR";

export const REGISTRY_PAYMENT_ARCHIVE_REQUEST = "REGISTRY_PAYMENT_ARCHIVE_REQUEST";
export const REGISTRY_PAYMENT_ARCHIVE_SUCCESS = "REGISTRY_PAYMENT_ARCHIVE_SUCCESS";
export const REGISTRY_PAYMENT_ARCHIVE_ERROR = "REGISTRY_PAYMENT_ARCHIVE_ERROR";

export const REGISTRY_PAYMENT_PAY_REQUEST = "REGISTRY_PAYMENT_PAY_REQUEST";
export const REGISTRY_PAYMENT_PAY_SUCCESS = "REGISTRY_PAYMENT_PAY_SUCCESS";
export const REGISTRY_PAYMENT_PAY_ERROR = "REGISTRY_PAYMENT_PAY_ERROR";

export const REGISTRY_DELETE_REQUEST = "REGISTRY_DELETE_REQUEST";
export const REGISTRY_DELETE_SUCCESS = "REGISTRY_DELETE_SUCCESS";
export const REGISTRY_DELETE_ERROR = "REGISTRY_DELETE_ERROR";

export const REGISTRY_UPDATE_REQUEST = "REGISTRY_UPDATE_REQUEST";
export const REGISTRY_UPDATE_SUCCESS = "REGISTRY_UPDATE_SUCCESS";
export const REGISTRY_UPDATE_ERROR = "REGISTRY_UPDATE_ERROR";

export const ADD_REGISTRY_REQUEST = "ADD_REGISTRY_REQUEST";
export const ADD_REGISTRY_SUCCESS = "ADD_REGISTRY_SUCCESS";
export const ADD_REGISTRY_ERROR = "ADD_REGISTRY_ERROR";

export const DUPLICATE_REGISTRY_REQUEST = "DUPLICATE_REGISTRY_REQUEST";
export const DUPLICATE_REGISTRY_SUCCESS = "DUPLICATE_REGISTRY_SUCCESS";
export const DUPLICATE_REGISTRY_ERROR = "DUPLICATE_REGISTRY_ERROR";

export const PAY_REGISTRY_REQUEST = "PAY_REGISTRY_REQUEST";
export const PAY_REGISTRY_SUCCESS = "PAY_REGISTRY_SUCCESS";
export const PAY_REGISTRY_ERROR = "PAY_REGISTRY_ERROR";

export const GET_REGISTRY_STATUS_DICT_REQUEST = "GET_REGISTRY_STATUS_DICT_REQUEST";
export const GET_REGISTRY_STATUS_DICT_SUCCESS = "GET_REGISTRY_STATUS_DICT_SUCCESS";
export const GET_REGISTRY_STATUS_DICT_ERROR = "GET_REGISTRY_STATUS_DICT_ERROR";

export const UPDATE_FIELD_REGISTRY_STORE = "UPDATE_FIELD_REGISTRY_STORE";

export const TRANSFER_REGISTRY_TO_PAY_ERROR = "TRANSFER_REGISTRY_TO_PAY_ERROR";
export const TRANSFER_REGISTRY_TO_PAY_REQUEST = "TRANSFER_REGISTRY_TO_PAY_REQUEST";
export const TRANSFER_REGISTRY_TO_PAY_SUCCESS = "TRANSFER_REGISTRY_TO_PAY_SUCCESS";

export const DISCARD_OUTSTANDING_ERROR = "DISCARD_OUTSTANDING_ERROR";
export const DISCARD_OUTSTANDING_REQUEST = "DISCARD_OUTSTANDING_REQUEST";
export const DISCARD_OUTSTANDING_SUCCESS = "DISCARD_OUTSTANDING_SUCCESS";

export const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST";
export const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS";
export const IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR = "IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR";

export const BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST = "BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST";
export const BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS = "BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS";
export const BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR = "BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR";

export const BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST = "BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST";
export const BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS = "BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS";
export const BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_ERROR = "BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_ERROR";