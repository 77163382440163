import React, {useMemo} from "react";

import ClientNamesDropdown from "../../../../../../components/ActualComponents/ClientNamesDropdown";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import {KedoDocumentCodes} from "../../../../../../components/KedoDocumentCodes";
import {KedoDocumentRouteDropdown} from "../../../../../../components/KedoDocumentRouteDropdown";
import NmTitle from "../../../../../../components/NmTitle";

import {useKedoDocumentTypesEdit} from "./hooks/useEdit";

import {getUserRole} from "../../../../../../utils/access";
import {isAccessByRestrictions} from "../../../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";
import {NM_MANAGER} from "../../../../../../constants/roles";

export const KedoDirectoriesDocumentTypesEdit = (props) => {
    const {
        editData,
        fetchList,
        onClose,
        clientId,
        fetchCounts,
    } = props;

    const role = getUserRole();

    const {
        errors,
        handleSubmit,
        progressAction,
        onChange,
        values,
        touched,
        documentRouteTypesFilter,
    } = useKedoDocumentTypesEdit({
        editData,
        fetchList,
        onClose,
        fetchCounts,
        clientId,
    });

    const initialOption = useMemo(() => {
        if (editData) {
            return {
                key: editData.clientId,
                value: editData.clientId,
                text: editData.clientName,
            };
        }
    }, []);

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        editData ?
                            "Редактирование типа загруженных документов" :
                            "Добавление типа загруженных документов"
                    }
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progressAction}
                    mobile="column"
                    isHiddenCancelOnMobile
                    onClose={onClose}
                    submitBtnContent={editData ? "Сохранить" : "Добавить"}
                    cancelBtnContent="Отменить"
                    submit={handleSubmit}
                />
            }
            onClose={onClose}
        >
            <NmForm addMargin>
                {
                    !clientId &&
                    <ClientNamesDropdown
                        required={
                            role === NM_MANAGER
                            || !isAccessByRestrictions([
                                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsDocumentSystemTypesKedo,
                            ])
                        }
                        label="Компания"
                        placeholder="Не выбрано"
                        onChange={onChange}
                        name="clientId"
                        value={values.clientId}
                        initialOption={initialOption}
                        error={
                            touched?.clientId && errors?.clientId ?
                                errors?.clientId :
                                undefined
                        }
                    />
                }
                <NmInputV2
                    size="xl"
                    required
                    name="name"
                    value={values.name}
                    onChange={onChange}
                    label="Наименование"
                    placeholder="Введите наименование"
                    maxLength={255}
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                {
                    !clientId &&
                    <NmInputV2
                        size="xl"
                        label="Внешний идентификатор"
                        placeholder="Введите внешний идентификатор типа документа"
                        name="externalId"
                        value={values.externalId}
                        maxLength={50}
                        onChange={onChange}
                        error={
                            touched?.externalId && errors?.externalId ?
                                errors?.externalId :
                                undefined
                        }
                    />
                }
                <KedoDocumentCodes
                    required={true}
                    value={values.documentCode}
                    onChange={onChange}
                    error={
                        touched?.documentCode && errors?.documentCode ?
                            errors?.documentCode :
                            undefined
                    }
                />
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <NmRadioV2
                        name="baseRouteType"
                        value={true}
                        checked={values.baseRouteType === true}
                        className="me-md-4 mb-2 mb-md-0"
                        label="Базовый маршрут"
                        onChange={onChange}
                    />
                    <NmRadioV2
                        name="baseRouteType"
                        checked={values.baseRouteType === false}
                        value={false}
                        label="Маршрут из справочника"
                        onChange={onChange}
                    />
                </div>
                <KedoDocumentRouteDropdown
                    required={true}
                    clientId={clientId}
                    baseRouteTypeFilter={values.baseRouteType}
                    typesFilter={documentRouteTypesFilter}
                    initialOption={
                        editData?.routeId && {
                            key: editData.routeId,
                            value: editData.routeId,
                            text: editData.routeName,
                        }
                    }
                    value={values.routeId}
                    onChange={onChange}
                    error={touched?.routeId && errors?.routeId}
                />
            </NmForm>
        </NmModal>
    );
};
