import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import FilterCustomer from "../../../../../components/ActualComponents/FilterCustomer";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";

import {handleFormString} from "../../../../../utils/stringHelper";

import {
    configureSettingsUsersClientRole,
    settingsUsersRolesProgressActionSelector,
} from "../../../../../ducks/settingsUsersRoles";

const ClientRoleSettingsModal = (props) => {
    const {
        editData,
        onClose,
        fetchList,
    } = props;

    const dispatch = useDispatch();
    const progress = useSelector(settingsUsersRolesProgressActionSelector);

    const initialValues = useMemo(() => {
        return {
            nameForClient: editData?.nameForClient || "",
            relatedClientIds: editData?.relatedClientIds
                ? editData?.relatedClientIds.map(item => item.clientId)
                : [],
        };
    }, [editData]);

    const initialRelatedClientOptions = useMemo(() => {
        if (!editData?.relatedClientIds) {
            return null;
        }

        return editData.relatedClientIds.map(item => {
            return {
                key: item.clientId,
                value: item.clientId,
                text: item.name,
            };
        });
    }, [editData]);

    const onSubmit = () => {
        dispatch(configureSettingsUsersClientRole({
            roleId: editData.roleId,
            nameForClient: handleFormString(values.nameForClient),
            relatedClientIds: values.relatedClientIds,
            onSuccess: () => {
                if (fetchList) {
                    fetchList();
                }
            },
        }));
    };

    const {
        handleSubmit,
        values,
        handleChange,
        setFieldValue,
    } = useFormik({
        onSubmit,
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
    });

    return (
        <NmModal
            size="md"
            contentAlign="left"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Настройка роли "${editData?.name}" для компаний`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submit={handleSubmit}
                    submitBtnContent="Создать"
                    onClose={onClose}
                    cancelBtnContent="Отменить"
                    disabled={progress}
                />
            }
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Название роли для компании"
                    placeholder="Напишите название"
                    name="nameForClient"
                    value={values.nameForClient}
                    onChange={handleChange}
                />
                <FilterCustomer
                    isBff={true}
                    size="xl"
                    name="relatedClientIds"
                    onChange={(event, {name, value}) => setFieldValue(name, value)}
                    value={values.relatedClientIds}
                    label="Наименование компании"
                    placeholder="Выберите компанию"
                    multiple={true}
                    initialOptions={initialRelatedClientOptions}
                />
            </NmForm>
        </NmModal>
    );
};

export default ClientRoleSettingsModal;