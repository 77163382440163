import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {COMPANY_SAMPLE_TYPE} from "../edit-modal";

import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";

import {NM_ADMIN_INFO} from "../../../../constants/contractorInfo";
import {NM_PARTNER} from "../../../../constants/roles";

import {getPageSettingsEmployees} from "../../../../ducks/bff/settings/employees/actionCreators";

import {SUB_PAGE_SETTINGS_MEMBER} from "../../../../constants/link-params";

export type PartnerFetchListType = {
    pageSize: number,
    pageNum: number,
    subpage: string,
    sortData?: string | null,
    filter: any
};

export function useUsersFetchList({pageSize, pageNum, subpage, sortData, filter}: PartnerFetchListType) {
    const dispatch = useDispatch();

    const [archived, setArchived] = useState(false);

    useEffect(() => {
        fetchList();
    }, [subpage, archived, pageSize, pageNum, sortData, filter]);

    const [loading, setLoading] = useState(false);

    const fetchList = () => {
        const {
            fioFilter,
            roleListFilter,
            emailFilter,
            phoneFilter,
            clientUserPositionFilter,
            availableClients,
            sampleType,
        } = filter;

        setLoading(true);

        const _roleListFilter = roleListFilter.length ? roleListFilter : undefined;

        dispatch(getPageSettingsEmployees({
            pageNum,
            pageSize,
            fioFilter: handleFormString(fioFilter),
            emailFilter: handleFormString(emailFilter),
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
            roleListFilter: subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK ? [NM_PARTNER] : _roleListFilter,
            roleFilter: subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK ? NM_PARTNER : undefined,
            clientId: NM_ADMIN_INFO.ID,
            archiveFilter: archived,
            fioSort: sortData,
            clientUserPositionFilter: subpage === SUB_PAGE_SETTINGS_MEMBER.PARTNERS.LINK || isEmpty(clientUserPositionFilter) ? undefined : clientUserPositionFilter,
            manageableClientsFilter: sampleType === COMPANY_SAMPLE_TYPE.MULTIPLE ? availableClients : undefined,
            getResult: () => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            },
        }));
    };

    return {
        archived,
        loading,
        setArchived,
        fetchList,
    };
}