import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import useConfirm, {openConfirmAction} from "../../../../hooks/useConfirm";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {
    archiveActRegistry,
    deleteActRegistry,
} from "../../../../ducks/bff/orders/order-act-registries/actionCreators";

export const useOrderActRegistryAction = ({setPagination, pageSize}) => {
    const [editData, setEditData] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [archived, setArchived] = useState(false);

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm();

    const archive = getArchiveButton(t, archived, {mobile: true});

    const isVisibleAction = [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
    ].includes(role)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.addActRegistry,
        ]);

    const mediaControls = {
        renderCount: {
            desktop: 2,
            tablet: 2,
            mobile: 1,
        },
        buttons: [
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: () => {
                        setOpenEdit(true);
                    },
                    size: "xl",
                    children: "Добавить реестр",
                    color: "green",
                    icon: <AddIcon />,
                },
                visible: isVisibleAction,
            },
            {
                component: COMPONENT.BUTTON,
                props: {
                    ...archive.props,
                    onClick: () => {
                        setArchived(!archived);
                        setPagination({
                            pageSize,
                            pageNum: 1,
                        });
                    },
                },
            },
        ],
    };

    const onArchive = (item, onFetchList) => {
        const {registryId, clientId, archived} = item;

        dispatch(archiveActRegistry({
            clientId,
            registryId,
            archived: !archived,
            onSuccess: onFetchList,
        }));
    };

    const onDelete = (item, onFetchList) => {
        const {registryId, clientId} = item;

        dispatch(deleteActRegistry({
            clientId,
            registryId,
            onSuccess: onFetchList,
        }));
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
        setEditData(null);
    };

    const getListCardActions = (item, onFetchList) => {
        return {
            renderCount: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать",
                        onClick: () => {
                            setOpenEdit(true);
                            setEditData(item);
                        },
                    },
                    visible: isVisibleAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Удалить",
                        onClick: () => {
                            dispatchConfirm(
                                openConfirmAction("Вы уверены, что хотите удалить реестр актов?",
                                    () => {
                                        onDelete(item, onFetchList);
                                    },
                                ),
                            );
                        },
                    },
                    visible: isVisibleAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: !archived ? "В архив" : "Из архива",
                        onClick: () => {
                            dispatchConfirm(
                                openConfirmAction(
                                    !archived ?
                                        "Вы уверены, что хотите перенести реестр актов в архив?" :
                                        "Вы уверены, что хотите перенести реестр актов из архива?",
                                    () => {
                                        onArchive(item, onFetchList);
                                    },
                                ),
                            );
                        },
                    },
                    visible: isVisibleAction,
                },
            ],
        };
    };

    return {
        mediaControls,
        getListCardActions,
        editData,
        archived,
        openEdit,
        dispatchConfirm,
        isOpenConfirm,
        onCloseEdit,
        role,
        contentConfirm,
    };
};