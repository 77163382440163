import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputPassword from "../../../components/ActualComponents/NmInputPassword";
import NmModal from "../../../components/ActualComponents/NmModal";
import {LoginButton} from "../button";
import LoginSuccessNotification from "../success-notification";

import validate from "../../../utils/validate";

import {LINK_LOGIN} from "../../../constants/links";
import {passwordChangeRule} from "../../../constants/validationRules";

import {history} from "../../../store/configureStore";

import {
    clearFieldStore,
    endSaveSelector,
    progressSavePasswordRecoverySelector,
    savePassword,
} from "../../../ducks/clientUsers";

import PropTypes from "prop-types";

import "./style.sass";

class LoginChange extends Component {
    static propTypes = {
        progressSave: PropTypes.bool,
        isCompleteSave: PropTypes.bool,
        savePassword: PropTypes.func,
        clearFieldStore: PropTypes.func,
    };

    static defaultProps = {
        progressSave: false,
        isCompleteSave: false,
        savePassword: () => {
        },
        clearFieldStore: () => {
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            password: "",
            repeatPassword: "",
            errorRepeatPassword: "",
            errorForm: {},
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            password,
            repeatPassword,
            errorForm: {
                passwordErrorMessage,
            },
            errorRepeatPassword,
        } = this.state;
        const {
            password: _password,
            repeatPassword: _repeatPassword,
        } = prevState;

        if (repeatPassword && repeatPassword !== _repeatPassword && errorRepeatPassword ||
            password && password !== _password && passwordErrorMessage) {
            this.validation();
            this.validationRepeatPassword();
        }
    }

    componentWillUnmount() {
        const {
            clearFieldStore,
        } = this.props;

        clearFieldStore({isCompleteSave: false});
    }

    handleChange = (event, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    validation = () => {
        const {password, repeatPassword} = this.state;

        const errorForm = validate({password, repeatPassword}, passwordChangeRule);

        this.setState({errorForm});

        return Object.keys(errorForm).length === 0;
    };

    validationRepeatPassword = () => {
        const {t} = this.props;
        const {password, repeatPassword} = this.state;

        if (password === null) {
            return;
        }

        if (password !== repeatPassword) {
            this.setState({
                errorRepeatPassword: t("recovery-form.error_repeat_password"),
            });

            return false;
        }

        this.setState({
            errorRepeatPassword: null,
        });

        this.setState({error: null});

        return true;
    };

    savePassword = () => {
        const {password} = this.state;
        const {savePassword, match: {params: {token}}, progressSave} = this.props;

        if (progressSave) {
            return;
        }

        const isValidForm = this.validation();

        if (!this.validationRepeatPassword() || !isValidForm) {
            return;
        }

        const requestedData = {
            newPassword: password,
            token,
        };

        savePassword(requestedData);
    };

    returnToPrevPage = (e) => {
        e.preventDefault();

        history.push(LINK_LOGIN);
    };

    renderForm() {
        const {errorForm, errorRepeatPassword, password, repeatPassword} = this.state;
        const {progressSave, t} = this.props;

        return (
            <>
                <NmInputPassword
                    openNaimix
                    size="xl"
                    required
                    name="password"
                    value={password}
                    label="Пароль"
                    labelClassName="d-flex"
                    className="mb-3 mb-md-4"
                    onChange={this.handleChange}
                    type="password"
                    placeholder="Введите пароль"
                    error={errorForm.passwordErrorMessage}
                />
                <NmInputPassword
                    openNaimix
                    size="xl"
                    required
                    labelClassName="d-flex"
                    name="repeatPassword"
                    label="Повтор пароля"
                    value={repeatPassword}
                    onChange={this.handleChange}
                    className="mb-4 mb-md-5"
                    placeholder="Введите пароль"
                    error={errorRepeatPassword}
                />
                <LoginButton
                    size="xl"
                    loading={progressSave}
                    disabled={progressSave}
                    className="login-change__button"
                    onClick={this.savePassword}
                >
                    {t("button.save")}
                </LoginButton>
            </>
        );
    }

    render() {
        const {isCompleteSave, t} = this.props;

        if (isCompleteSave) {
            return (
                <LoginSuccessNotification
                    title={t("recovery-form.success-message")}
                    description="Для того, чтобы войти в личный кабинет, введите адрес электронной почты и новый пароль"
                    buttonText="Вход для заказчиков"
                    onClose={this.returnToPrevPage}
                    onSubmit={this.returnToPrevPage}
                />
            );
        }

        return (
            <NmModal
                openNaimix
                header={
                    <div className="login-change__title">
                        {t("recovery-form.enter-new-password")}
                    </div>
                }
                onClose={this.returnToPrevPage}
                className="login-change"
            >
                <NmForm className="login-change__form">
                    {this.renderForm()}
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        progressSave: progressSavePasswordRecoverySelector(state),
        isCompleteSave: endSaveSelector(state),
    }),
    {
        savePassword,
        clearFieldStore,
    },
)(withTranslation()(LoginChange));
