import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientMemberList} from "../../employees/actionCreators";
import {getClientProjectCardUsers} from "./actionCreators";
import {
    CLIENT_PROJECTS_CARD_ADD_USER_ERROR,
    CLIENT_PROJECTS_CARD_ADD_USER_REQUEST,
    CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS,
    CLIENT_PROJECTS_CARD_DELETE_USER_ERROR,
    CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST,
    CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS,
    CLIENT_PROJECTS_CARD_GET_ERROR,
    CLIENT_PROJECTS_CARD_GET_REQUEST,
    CLIENT_PROJECTS_CARD_GET_SUCCESS,
    CLIENT_PROJECTS_CARD_GET_USERS_ERROR,
    CLIENT_PROJECTS_CARD_GET_USERS_REQUEST,
    CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return (
        getBffUrl({
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/projects/project-card",
            adminRolesUrl: "/adm/clients/client-card/client/projects/project-card",
        })
    );
};

const handleResponse = function* (data) {
    const {
        isFetchUsers,
    } = data;

    if (isFetchUsers) {
        const state = yield select();

        yield put(getClientMemberList(state.bff.clientEmployees.pageData));
        yield put(getClientProjectCardUsers(state.bff.clientProjectCard.projectUsersPageData));
    }
};

const getClientProjectCardSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getProjectById`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CARD_GET_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_PROJECTS_CARD_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CARD_GET_ERROR,
        });
    }
};

const getClientProjectCardUsersSaga = function* ({payload}) {
    try {
        const {
            fieldName,
            callBack,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/project-users/getProjectUserPage`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CARD_GET_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (callBack) {
            const {projectUserModels} = result;
            const projectIds = projectUserModels.map(value => value.projectIdsList).flat();

            callBack(projectIds);
        }

        yield put({
            type: CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CARD_GET_USERS_ERROR,
            payload: error,
        });
    }
};

const addUserClientProjectCardSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.bff.post(`${getController()}/project-users/addProjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CARD_ADD_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно добавлен");

        yield handleResponse({isFetchUsers: true});

        yield put({
            type: CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_PROJECTS_CARD_ADD_USER_ERROR,
            payload: error,
        });
    }
};

const deleteUserClientProjectCardSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.bff.post(`${getController()}/project-users/deleteProjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_PROJECTS_CARD_DELETE_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удален из проекта");

        yield handleResponse({isFetchUsers: true});

        yield put({type: CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CLIENT_PROJECTS_CARD_DELETE_USER_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_PROJECTS_CARD_GET_REQUEST, getClientProjectCardSaga),
        takeEvery(CLIENT_PROJECTS_CARD_GET_USERS_REQUEST, getClientProjectCardUsersSaga),
        takeEvery(CLIENT_PROJECTS_CARD_ADD_USER_REQUEST, addUserClientProjectCardSaga),
        takeEvery(CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST, deleteUserClientProjectCardSaga),
    ]);
}