export const GET_LIST_CONTRACTOR_BANKS_REQUEST = "GET_LIST_CONTRACTOR_BANKS_REQUEST";
export const GET_LIST_CONTRACTOR_BANKS_SUCCESS = "GET_LIST_CONTRACTOR_BANKS_SUCCESS";
export const GET_LIST_CONTRACTOR_BANKS_ERROR = "GET_LIST_CONTRACTOR_BANKS_ERROR";

export const SENDING_CONTRACTOR_TO_BANK_REQUEST = "SENDING_CONTRACTOR_TO_BANK_REQUEST";
export const SENDING_CONTRACTOR_TO_BANK_SUCCESS = "SENDING_CONTRACTOR_TO_BANK_SUCCESS";
export const SENDING_CONTRACTOR_TO_BANK_ERROR = "SENDING_CONTRACTOR_TO_BANK_ERROR";

export const REFRESH_CONTRACTOR_BANK_STATUS_REQUEST = "REFRESH_CONTRACTOR_BANK_STATUS_REQUEST";
export const REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS = "REFRESH_CONTRACTOR_BANK_STATUS_SUCCESS";
export const REFRESH_CONTRACTOR_BANK_STATUS_ERROR = "REFRESH_CONTRACTOR_BANK_STATUS_ERROR";

export const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST";
export const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_SUCCESS";
export const REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR = "REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_ERROR";

export const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST";
export const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS";
export const CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR = "CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR";

export const GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST = "GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST";
export const GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_SUCCESS = "GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_SUCCESS";
export const GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_ERROR = "GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_ERROR";

export const CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST = "CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST";
export const CREATE_CONTRACTOR_BANK_IDENTIFICATION_SUCCESS = "CREATE_CONTRACTOR_BANK_IDENTIFICATION_SUCCESS";
export const CREATE_CONTRACTOR_BANK_IDENTIFICATION_ERROR = "CREATE_CONTRACTOR_BANK_IDENTIFICATION_ERROR";

export const CONTRACTOR_BANKS_UPDATE_STORE = "CONTRACTOR_BANKS_UPDATE_STORE";