import {
    ADD_DOCUMENTS_EDO_REGISTRIES_ERROR,
    ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR,
    SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    card: {},
    totalCount: 0,
    pageData: {},
    progressList: false,
    progressAction: false,
    progressGetFile: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
                progressList: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST:
            return {
                ...state,
                progressGetFile: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                list: payload.results,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS:
            return {
                ...state,
                progressGetFile: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR:
        case EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR:
            return {
                ...state,
                progressGetFile: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};