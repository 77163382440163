import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientMemberList} from "../../employees/actionCreators";
import {
    getClientObjectCard,
    getObjectUserList,
} from "./actionCreators";
import {
    ADD_OBJECT_USER_ERROR,
    ADD_OBJECT_USER_REQUEST,
    ADD_OBJECT_USER_SUCCESS,
    CLIENT_OBJECTS_CARD_GET_ERROR,
    CLIENT_OBJECTS_CARD_GET_REQUEST,
    CLIENT_OBJECTS_CARD_GET_SUCCESS,
    DELETE_OBJECT_USER_ERROR,
    DELETE_OBJECT_USER_REQUEST,
    DELETE_OBJECT_USER_SUCCESS,
    GET_PROJECT_OBJECT_USER_LIST_ERROR,
    GET_PROJECT_OBJECT_USER_LIST_REQUEST,
    GET_PROJECT_OBJECT_USER_LIST_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return (
        getBffUrl({
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/objects/object-card",
            adminRolesUrl: "/adm/clients/client-card/client/objects/object-card",
        })
    );
};

const handleResponse = function* (data) {
    const {
        isFetchCard,
        isFetchUsers,
    } = data;

    if (isFetchCard) {
        const state = yield select();

        const {clientId, objectId} = state.bff.clientObjectCard.objectUsersPageData;

        yield put(getClientObjectCard({clientId, objectId}));
    }

    if (isFetchUsers) {
        const state = yield select();

        yield put(getClientMemberList(state.bff.clientEmployees.pageData));
        yield put(getObjectUserList(state.bff.clientObjectCard.objectUsersPageData));
    }
};

const getClientObjectCardSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.get(`${getController()}/getRichById`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_CARD_GET_ERROR,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: CLIENT_OBJECTS_CARD_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_CARD_GET_ERROR,
        });
    }
};

const getObjectUserListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/project-users/getProjectObjectUserPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_OBJECT_USER_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_PROJECT_OBJECT_USER_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PROJECT_OBJECT_USER_LIST_ERROR,
            payload: error,
        });
    }
};

const addObjectUserSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/project-users/addProjectObjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_OBJECT_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно добавлен");

        yield handleResponse({
            isFetchCard: true,
            isFetchUsers: true,
        });

        yield put({
            type: ADD_OBJECT_USER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_OBJECT_USER_ERROR,
            payload: error,
        });
    }
};

const deleteObjectUserSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/project-users/deleteProjectObjectUser`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_OBJECT_USER_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сотрудник успешно удалён");

        yield handleResponse({
            isFetchCard: true,
            isFetchUsers: true,
        });

        yield put({
            type: DELETE_OBJECT_USER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_OBJECT_USER_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_OBJECTS_CARD_GET_REQUEST, getClientObjectCardSaga),
        takeEvery(GET_PROJECT_OBJECT_USER_LIST_REQUEST, getObjectUserListSaga),
        takeEvery(ADD_OBJECT_USER_REQUEST, addObjectUserSaga),
        takeEvery(DELETE_OBJECT_USER_REQUEST, deleteObjectUserSaga),
    ]);
}