import {getPaymentStatusChecksFailed} from "../../../finance/finance-payment-list/utils/getStatusChecksFailed";

import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {REGISTRY_PAYMENTS_STATUS_DICT} from "../../../../constants/registry";

export const getRegistryPaymentStatus = (params) => {
    const {
        paymentStatuses,
        status,
        orderContractPaymentType,
    } = params;

    const statusChecksFailed = getPaymentStatusChecksFailed(status);

    if (statusChecksFailed) {
        return statusChecksFailed;
    }

    if (
        orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL &&
        status === REGISTRY_PAYMENTS_STATUS_DICT.PAID.VALUE
    ) {
        return "Исполнено";
    }

    return paymentStatuses[status];
};