import React from "react";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";

export const RequestPassportPageNumber19 = (props) => {
    const {
        className,
        onChange,
        isRequestPassportPageNumber19,
        disabled,
    } = props;

    return (
        <NmCheckboxV2
            info
            disabled={disabled}
            className={className}
            onChange={onChange}
            label="Запросить 19 стр. паспорта"
            helpTooltipType="right"
            checked={isRequestPassportPageNumber19}
            isVisibleTooltip
            tooltip={
                <HelpTooltip
                    hover
                    type="light"
                >
                    Пользователю будет предложено добавить фотографию сведений о ранее выданных паспортах (19 стр. паспорта)
                </HelpTooltip>
            }
        />
    );
};