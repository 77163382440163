import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";

import Tabs from "../../../components/ActualComponents/Tabs";
import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {KedoDirectories} from "../../kedo/directories";
import DirectoriesRoute from "../routes/settings-directories-route";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
    LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES,
    LINK_SETTINGS_DIRECTORIES_FLASH_CALL,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_TARRIF_GRID,
    LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION,
    LINK_SETTINGS_DOCUMENT_TYPES,
    LINK_SETTINGS_KEYWORDS,
    LINK_SETTINGS_ORDER_CATEGORIES,
    LINK_SETTINGS_SPECIALTY_LIST,
    LINK_SETTINGS_SPECIALTY_OFFER,
    LINK_SETTINGS_STATEMENT_TYPES,
    LINK_SETTINGS_STOP_WORDS,
} from "../../../constants/links";
import {ADMIN, NM_CHIEF_ACCOUNTANT, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";

import "./style.sass";

class SettingsDirectories extends Component {
    role = ls(USER_ROLE);

    get links() {
        const {
            t,
            specialityNonDecisionCount,
            countStopWords,
        } = this.props;

        const baseTabs = [
            {
                active: LINK_SETTINGS_KEYWORDS,
                link: LINK_SETTINGS_KEYWORDS,
                name: t("settings.keywords"),
            },
            {
                active: LINK_SETTINGS_ORDER_CATEGORIES,
                link: LINK_SETTINGS_ORDER_CATEGORIES,
                name: t("settings.client-category"),
            },
            {
                active: LINK_SETTINGS_SPECIALTY_LIST,
                link: LINK_SETTINGS_SPECIALTY_LIST,
                name: t("settings.specialty"),
            },
            {
                active: LINK_SETTINGS_DIRECTORIES_TARRIF_GRID,
                link: LINK_SETTINGS_DIRECTORIES_TARRIF_GRID,
                name: "Тарифная сетка",
            },
            {
                active: LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST,
                link: LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST,
                name: t("settings.fms-units"),
            },
            {
                active: LINK_SETTINGS_DOCUMENT_TYPES,
                link: LINK_SETTINGS_DOCUMENT_TYPES,
                name: t("settings.documentTypes"),
            },
            {
                active: LINK_SETTINGS_STATEMENT_TYPES,
                link: LINK_SETTINGS_STATEMENT_TYPES,
                name: t("settings.statementTypes"),
            },
            {
                active: LINK_SETTINGS_STOP_WORDS,
                link: LINK_SETTINGS_STOP_WORDS,
                name: t("settings.stopWords"),
                count: countStopWords || null,
            },
            {
                active: LINK_SETTINGS_SPECIALTY_OFFER,
                link: LINK_SETTINGS_SPECIALTY_OFFER,
                name: t("settings.specialty-offer"),
                count: specialityNonDecisionCount,
                isVisible: [ADMIN, NM_MANAGER].includes(this.role)
                    && specialityNonDecisionCount !== 0
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingDirectorySpecialtyOffer,
                    ]),
            },
            {
                active: LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES,
                link: LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES,
                name: "Категории видов деятельности",
            },
            {
                active: LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
                link: LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
                name: "Банки",
            },
            {
                active: LINK_SETTINGS_DIRECTORIES_FLASH_CALL,
                link: LINK_SETTINGS_DIRECTORIES_FLASH_CALL,
                name: "Звонки через Flash Call",
            },
            {
                active: LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION,
                link: LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION,
                name: "Комиссия за вывод средств",
            },
        ];

        return baseTabs.filter(tab => tab);
    }

    get firstLevelTabs() {
        const {pathname} = this.props;


        if ([NM_OPERATOR].includes(this.role)) {
            return [
                {
                    active: [LINK_SETTINGS_DIRECTORIES_KEDO],
                    link: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                    name: "КЭДО",
                },
            ];
        }

        return [
            {
                active: [LINK_SETTINGS_DIRECTORIES] && !pathname.includes(LINK_SETTINGS_DIRECTORIES_KEDO),
                link: LINK_SETTINGS_KEYWORDS,
                name: "Наймикс",
            },
            {
                active: [LINK_SETTINGS_DIRECTORIES_KEDO],
                link: LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
                name: "КЭДО",
                isVisible: ![NM_CHIEF_ACCOUNTANT].includes(this.role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
                    ]),
            },
        ];
    };

    render() {
        return (
            <NmPage
                noPadding={true}
                header={
                    <NmPageHeader text="Справочники" />
                }
                subHeader={
                    <Tabs
                        secondary
                        className="settings-directories__tabs"
                        panes={this.firstLevelTabs}
                    />
                }
            >
                <Switch>
                    <CheckRoleRoute
                        path={LINK_SETTINGS_DIRECTORIES_KEDO}
                        component={KedoDirectories}
                    />
                    <Route
                        path={LINK_SETTINGS_DIRECTORIES}
                        render={(props) => {
                            return (
                                <>
                                    <Tabs
                                        secondary
                                        className="settings-directories__tabs"
                                        panes={this.links}
                                    />
                                    <DirectoriesRoute {...props} />
                                </>
                            );
                        }}
                    />
                </Switch>
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        specialityNonDecisionCount: state.clientSpecialityOffer.nonDecisionCount,
        countStopWords: state.stopWords.totalCount,
    }),
    {},
)(withTranslation()(SettingsDirectories));