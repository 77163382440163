import {useFormik} from "formik";

import {formatChangeDepositValues} from "../utils/dto";
import {getChangeDepositValidation} from "../utils/getValidation";

import {CHANGE_DEPOSIT_RADIO_TYPE} from "../constants";

export const useChangeDepositForm = (params) => {
    const {
        isHideConfirm,
        setOpenConfirm,
        maxValueNpd,
        maxValueNdfl,
        minValue,
    } = params;
    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues: {
            type: CHANGE_DEPOSIT_RADIO_TYPE.NPD,
            amount: "",
        },
        validateOnBlur: false,
        enableReinitialize: false,
        validationSchema: getChangeDepositValidation({
            maxValueNpd,
            maxValueNdfl,
            minValue,
        }),
    });

    function onSubmit() {
        if (!isHideConfirm) {
            setOpenConfirm(true);

            return;
        }

        const requestData = formatChangeDepositValues(values);

        params.onSubmit(requestData);
    }

    const onSubmitWithoutValidation = () => {
        const requestData = formatChangeDepositValues(values);

        params.onSubmit(requestData);
    };

    return {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        onSubmitWithoutValidation,
    };
};