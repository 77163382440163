import {all, call, put, select, takeEvery} from "@redux-saga/core/effects";

import {getPageRegistryPayment} from "./actionCreators";
import {
    ADD_REGISTRY_ERROR,
    ADD_REGISTRY_REQUEST,
    ADD_REGISTRY_SUCCESS,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS,
    BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_ERROR,
    BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST,
    BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS,
    DISCARD_OUTSTANDING_ERROR,
    DISCARD_OUTSTANDING_REQUEST,
    DISCARD_OUTSTANDING_SUCCESS,
    DUPLICATE_REGISTRY_ERROR,
    DUPLICATE_REGISTRY_REQUEST,
    DUPLICATE_REGISTRY_SUCCESS,
    GET_REGISTRY_STATUS_DICT_ERROR,
    GET_REGISTRY_STATUS_DICT_REQUEST,
    GET_REGISTRY_STATUS_DICT_SUCCESS,
    IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR,
    IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST,
    IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS,
    PAY_REGISTRY_ERROR,
    PAY_REGISTRY_REQUEST,
    PAY_REGISTRY_SUCCESS,
    REGISTRY_DELETE_ERROR,
    REGISTRY_DELETE_REQUEST,
    REGISTRY_DELETE_SUCCESS,
    REGISTRY_PAYMENT_ARCHIVE_ERROR,
    REGISTRY_PAYMENT_ARCHIVE_REQUEST,
    REGISTRY_PAYMENT_ARCHIVE_SUCCESS,
    REGISTRY_PAYMENT_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_GET_PAGE_SUCCESS,
    REGISTRY_PAYMENT_PAY_ERROR,
    REGISTRY_PAYMENT_PAY_REQUEST,
    REGISTRY_PAYMENT_PAY_SUCCESS,
    REGISTRY_UPDATE_ERROR,
    REGISTRY_UPDATE_REQUEST,
    REGISTRY_UPDATE_SUCCESS,
    TRANSFER_REGISTRY_TO_PAY_ERROR,
    TRANSFER_REGISTRY_TO_PAY_REQUEST,
    TRANSFER_REGISTRY_TO_PAY_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {LINK_CLIENT_REGISTRY_PAYMENTS_CARD, LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED} from "../../../../constants/links";
import {REGISTRY_TOAST_TEXT} from "../../../../constants/registry";

const getController = () => {
    const adminPageController = "/adm/finances/registry-payments/registry";

    return (
        getBffUrl({
            [LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED.replace(":archived", false)]: adminPageController,
            [LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED.replace(":archived", true)]: adminPageController,
            ["/card"]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/registry-payments/registry/registry-card",
                client: "/client-adm/finances/registry-payments/registry/registry-card",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/registry-payments/registry",
            adminRolesUrl: "/adm/clients/client-card/finances/registry-payments/registry",
        },
        null,
        true,
        )
    );
};

const handleResponse = function* (data) {
    const {
        isFetchList,
    } = data;

    if (isFetchList) {
        const state = yield select();

        yield put(getPageRegistryPayment(state.bff.registryPayment.pageData));
    }
};

const getPageRegistryPaymentSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const archiveRegistryPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            registryId,
            archived,
        } = payload;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryId}/archived/${archived}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ARCHIVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REGISTRY_PAYMENT_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

const payRegistryPayment = function* ({payload}) {
    try {
        const {
            data,
            handleError,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/pay`, data);

        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_PAY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (payModel && payload.handleResponse) {
            payload.handleResponse(result);

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REGISTRY_PAYMENT_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_PAY_ERROR,
            payload: error,
        });
    }
};

const updateRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно изменен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_UPDATE_ERROR,
            payload: error,
        });
    }
};

const deleteRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${clientId}/${registryId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно удалён");

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_DELETE_ERROR,
            payload: error,
        });
    }
};

const createDuplicateRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryId}/add-duplicate`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DUPLICATE_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Дубликат успешно создан");

        yield handleResponse({isFetchList: true});

        yield put({
            type: DUPLICATE_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DUPLICATE_REGISTRY_ERROR,
            payload: error,
        });
    }
};


const addRegistrySaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${getController()}/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: ADD_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const payRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            handleError,
        } = payload;

        const result = yield request.bff.post(`${getController()}/pay`, data);

        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: TRANSFER_REGISTRY_TO_PAY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PAY_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (payModel && payload.handleResponse) {
            payload.handleResponse(result);

            return {
                done: true,
            };
        }

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage);
        }

        toastSuccess("Успешно запущена задача оплаты.");

        yield handleResponse({isFetchList: true});

        yield put({
            type: PAY_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAY_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const getRegistryStatusDictSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get("/adm/common/dicts/all-registry-statuses", {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_STATUS_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_REGISTRY_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_REGISTRY_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

const transferRegistryToPaySaga = function* ({payload}) {
    try {
        const {
            data,
            handleError,
            onSuccess,
            isFetchList = true,
        } = payload;

        const result = yield call(request.bff.post, `${getController()}/pay`, data);

        const {
            errorMessage,
            payModel,
            errorCode,
            warningMessage,
        } = result;

        if (errorCode && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: TRANSFER_REGISTRY_TO_PAY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TRANSFER_REGISTRY_TO_PAY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (payModel && payload.handleResponse) {
            payload.handleResponse(result);

            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.PAY_SUCCESS);

        if (onSuccess) {
            onSuccess();
        }

        yield handleResponse({isFetchList});

        yield put({
            type: TRANSFER_REGISTRY_TO_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: TRANSFER_REGISTRY_TO_PAY_ERROR,
            payload: error,
        });
    }
};

const discardOutstandingPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/discard-errors`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DISCARD_OUTSTANDING_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Невыполненные платежи успешно отменены");

        yield put({
            type: DISCARD_OUTSTANDING_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DISCARD_OUTSTANDING_ERROR,
            payload: error,
        });
    }
};

const isFrameContractSignedForContractorsOnRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/is-frame-contract-signed-for-contractors-on-registry`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_ERROR,
            payload: error,
        });
    }
};

// POST /bff/client-adm/finances/registry-payments/registry/form-deferred-acts
// POST /bff/adm/clients/client-card/finances/registry-payments/registry/form-deferred-acts
// Формирование отложенных актов
const createRegistryPaymentDeferredActs = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const url = getController();

    try {
        const result = yield request.bff.post(`${url}/form-deferred-acts`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR,
        });
    }
};

// POST /bff/client-adm/finances/registry-payments/registry/get-client-payment-settings
// POST /bff/adm/clients/client-card/finances/registry-payments/registry/get-client-payment-settings
// Формирование отложенных актов
const getRegistryPaymentClientSettings = function* ({payload: params}) {
    const url = getController();

    try {
        const result = yield request.bff.get(`${url}/get-client-payment-settings`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_GET_PAGE_REQUEST, getPageRegistryPaymentSaga),
        takeEvery(REGISTRY_PAYMENT_ARCHIVE_REQUEST, archiveRegistryPayment),
        takeEvery(REGISTRY_PAYMENT_PAY_REQUEST, payRegistryPayment),
        takeEvery(REGISTRY_UPDATE_REQUEST, updateRegistrySaga),
        takeEvery(REGISTRY_DELETE_REQUEST, deleteRegistrySaga),
        takeEvery(ADD_REGISTRY_REQUEST, addRegistrySaga),
        takeEvery(GET_REGISTRY_STATUS_DICT_REQUEST, getRegistryStatusDictSaga),
        takeEvery(DUPLICATE_REGISTRY_REQUEST, createDuplicateRegistrySaga),
        takeEvery(PAY_REGISTRY_REQUEST, payRegistrySaga),
        takeEvery(TRANSFER_REGISTRY_TO_PAY_REQUEST, transferRegistryToPaySaga),
        takeEvery(DISCARD_OUTSTANDING_REQUEST, discardOutstandingPaymentsSaga),
        takeEvery(IS_FRAME_CONTRACT_SIGNED_FOR_CONTRACTORS_ON_REGISTRY_REQUEST, isFrameContractSignedForContractorsOnRegistrySaga),
        takeEvery(BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST, createRegistryPaymentDeferredActs),
        takeEvery(BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_REQUEST, getRegistryPaymentClientSettings),
    ]);
}