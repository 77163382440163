import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useMedia} from "react-media";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";
import {cloneDeep, isEmpty} from "lodash";

import ContextMenu from "../../../../../components/ActualComponents/ContextMenu";
import NmAdvancedTooltip from "../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ContractorNoteForClientTooltip from "../../../../../components/ContractorNoteForClientTooltip";
import ContractorPassportStatus from "../../../../../components/ContractorPassportStatus";
import ExtLink from "../../../../../components/ExtLink";
import NmButton from "../../../../../components/NmButton";
import NmCheck from "../../../../../components/NmCheck";
import NmIcon from "../../../../../components/NmIcon";
import NmReadonlyRating from "../../../../../components/NmReadonlyRating";
import NmSelectedList from "../../../../../components/NmSelectedList";
import SelfEmployedIndicator from "../../../../../components/SelfEmployedIndicator";
import {ReactComponent as ArchiveIcon} from "../../../../../images/archive.svg";
import {styleLargeLink} from "../../../../../styles/inline";
import {
    checkEndDate,
    checkPassportDataBy,
    checkPassportDataRu,
    getCheckDate,
} from "../../../../contractor/contractor_info/controlDateReadOnly";

import bem from "../../../../../utils/bem";
import formatDate, {calculateAge, convertUtcToLocal, formatLocalDate} from "../../../../../utils/dateFormat";
import {getContractorStatusesFlags} from "../../../../../utils/edm";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {pluralizeYears} from "../../../../../utils/pluralize";
import {getFullName, phoneFormat, phoneFormatWithoutSevenNumber} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {toastSuccess} from "../../../../../utils/toastHelper";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {
    CONTRACTOR_FIELD,
    CONTRACTOR_STATUS,
} from "../../../../../constants/contractor";
import {LINK_CONTRACTOR_PROFILE} from "../../../../../constants/links";
import {isUserFromNm} from "../../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../../ducks/bff/clients/info/selectors";
import {
    addContractorToBlackList,
    addFavoriteContractors,
    deleteContractorFromBlackList,
    deleteFavoriteContractors,
} from "../../../../../ducks/bff/contractors/registry/actionCreators";
import {
    contractorStatusDictSelector,
    getCitizenshipSelector,
} from "../../../../../ducks/contractor";
import {specialitiesAllV2DictSelector} from "../../../../../ducks/speciality";

import "./style.sass";

const ACTION = {
    COPY: "COPY",
    ADD_TO_FAVOURITE: "ADD_TO_FAVOURITE",
    ADD_TO_BLACK_LIST: "ADD_TO_BLACK_LIST",
    INVITE_TO_ORDER: "INVITE_TO_ORDER",
};

const getContextMenuOptions = (contractor, canViewContractorContacts) => {
    return {
        copy: {
            key: ACTION.COPY,
            value: ACTION.COPY,
            text: "Скопировать телефон",
            visible: canViewContractorContacts && contractor.phone,
        },
        addToFavourite: {
            key: ACTION.ADD_TO_FAVOURITE,
            value: ACTION.ADD_TO_FAVOURITE,
            text: contractor.favourite ? "Удалить из избранного" : "Добавить в избранное",
        },
        addToBlackList: {
            key: ACTION.ADD_TO_BLACK_LIST,
            value: ACTION.ADD_TO_BLACK_LIST,
            text: contractor.blacklist ? "Удалить из черного списка" : "Добавить в черный список",
        },
        inviteToOrder: {
            key: ACTION.INVITE_TO_ORDER,
            value: ACTION.INVITE_TO_ORDER,
            text: "Пригласить на заказ",
            visible: canViewContractorContacts && !contractor.archived && !isEmpty(contractor),
        },
    };
};

function AdvertisementContractorCard(props) {
    const {
        contractor,
        clientId,
        isMassOperation,
        openInvitePopup,
        fetchList,
        setContractorId,
    } = props;

    const {
        blacklist,
        favourite,
        contractorId,
        lastName,
        firstName,
        patronymic,
        fullName,
        phone,
        interestDate,
        archived,
    } = contractor;

    const role = ls(USER_ROLE);

    const {
        isShowFnsStatus,
    } = getContractorStatusesFlags(contractor, role);

    const contractorStatusDict = useSelector(contractorStatusDictSelector);
    const citizenshipList = useSelector(getCitizenshipSelector);
    const specialityDict = useSelector(specialitiesAllV2DictSelector);
    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);

    const [block, element] = bem("advertisement-contractor-card");
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isDesktop = useMedia({query: {minWidth: 1920}});

    const [reason, setReason] = useState("");
    const [action, setAction] = useState({contractor: {}, type: ""});
    const [isShowConfirmWindow, setShowConfirmWindow] = useState(false);
    const [error, setError] = useState("");

    function handleCopyContact() {
        copy(phoneFormatWithoutSevenNumber(phone));
        toastSuccess("Номер телефона скопирован.");
    }

    function validateBlacklistReason() {
        if (isNullOrWhitespace(reason)) {
            setError(t("contractor-list.required-reason-field"));

            return false;
        }

        return true;
    }

    const addFavoriteContractor = () => {
        const {contractor: {contractorId}} = action;

        dispatch(addFavoriteContractors({
            data: {
                contractorId,
                clientId,
            },
            onSuccess: () => {
                fetchList();
                closeConfirm();
            },
        }));
    };

    const deleteFavoriteContractor = () => {
        const {contractor: {contractorId}} = action;

        dispatch(deleteFavoriteContractors({
            data: {
                contractorId,
                clientId,
            },
            onSuccess: () => {
                fetchList();
                closeConfirm();
            },
        }));
    };

    const addContractorToBlacklist = () => {
        const {contractor: {contractorId}} = action;

        if (!validateBlacklistReason()) {
            return;
        }

        dispatch(addContractorToBlackList({
            data: {
                contractorId,
                clientId,
                reason,
            },
            onSuccess: () => {
                fetchList();
                closeConfirm();
            },
        }));
    };

    const _deleteContractorFromBlackList = () => {
        const {contractor: {contractorId}} = action;

        if (!validateBlacklistReason()) {
            return;
        }

        dispatch(deleteContractorFromBlackList({
            data: {
                contractorId,
                clientId,
                reason,
            },
            onSuccess: () => {
                fetchList();
                closeConfirm();
            },
        }));
    };

    const localizationData = {
        btnYesText: t("button.yes"),
        btnNoText: t("button.no"),
        btnConfirmText: t("button.confirm"),
        btnCancelText: t("button.cancel"),
        addInFavoritesConfirm: (name) => {
            return t("contractor-list.add-contractor-in-favorites-confirm", {name});
        },
        addOnceToOrderConfirm: (name, orderName) => {
            return t("contractor-list.invite-contractor-to-order-confirm", {name, orderName});
        },
        addSeveralToOrderConfirm: (names, orderName) => {
            return t("contractor-list.invite-contractors-to-order-confirm", {names, orderName});
        },
        addSeveralToOrderError: t("contractor-list.invite-contractors-to-order-error"),
        cancelInviteToOrderConfirm: (name, orderName) => {
            return t("contractor-list.cancel-invite-contractor-to-order-confirm", {name, orderName});
        },
        addSeveralInFavoritesConfirm: (names) => {
            return t("contractor-list.add-contractors-in-favorites-confirm", {names});
        },
        addSeveralInFavoritesError: t("contractor-list.add-contractors-in-favorites-error"),
        deleteSeveralFromFavoritesConfirm: (names) => {
            return t("contractor-list.delete-contractors-from-favorites-confirm", {names});
        },
        deleteSeveralFromBlackListConfirm: (names) => {
            return t("contractor-list.delete-contractors-from-blacklist-confirm", {names});
        },
        deleteFromFavoritesConfirm: (name) => {
            return t("contractor-list.delete-contractor-from-favorites-confirm", {name});
        },
        addOnceToBlackListConfirm: (name) => {
            return t("contractor-list.add-contractor-to-black-list-confirm", {name});
        },
        deleteContractorFromBlackListConfirm: (name) => {
            return t("contractor-list.delete-contractor-from-blacklist-confirm", {name});
        },
        online: t("contractor-list.online"),
        specialtiesLabel: t("contractor-list.specialties"),
        firstRegistration: t("contractor-list.first-registration"),
        citizenship: t("contractor-list.citizenship"),
        FNS: t("contractor-list.FNS"),
        completedOrders: t("contractor-list.completed-orders"),
        completedOrdersHelp: t("contractor-list.completed-orders-help"),
        age: t("contractor-list.ageLabel"),
        isCheckPassport: t("contractor-list.check-passport"),
    };

    function getActions() {
        const {contractor: {fullName}} = action;
        return {
            ADD_FAVORITE_CONTRACTOR: {
                title: localizationData.addInFavoritesConfirm(fullName),
                submitBtnContent: localizationData.btnYesText,
                cancelBtnContent: localizationData.btnNoText,
                submit: addFavoriteContractor,
            },
            DELETE_FROM_FAVORITES: {
                title: localizationData.deleteFromFavoritesConfirm(fullName),
                submitBtnContent: localizationData.btnYesText,
                cancelBtnContent: localizationData.btnNoText,
                submit: deleteFavoriteContractor,
            },
            ADD_CONTRACTOR_TO_BLACK_LIST: {
                title: localizationData.addOnceToBlackListConfirm(fullName),
                submitBtnContent: localizationData.btnYesText,
                cancelBtnContent: localizationData.btnNoText,
                submit: addContractorToBlacklist,
                showReason: true,
            },
            DELETE_CONTRACTOR_FROM_BLACK_LIST: {
                title: localizationData.deleteContractorFromBlackListConfirm(fullName),
                submitBtnContent: localizationData.btnYesText,
                cancelBtnContent: localizationData.btnNoText,
                submit: _deleteContractorFromBlackList,
                showReason: true,
            },
        };
    }

    function renderLink() {
        if (isEmpty(contractor)) {
            return "Исполнитель в архиве";
        }

        const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);
        const contractorName = getFullName(lastName, firstName, patronymic);

        return (
            <div className="flex flex-aligned-center">
                <ExtLink
                    to={link}
                    style={styleLargeLink}
                >
                    {contractorName || fullName}
                </ExtLink>
                {
                    archived && <ArchiveIcon />
                }
            </div>
        );
    }

    function closeConfirm() {
        setShowConfirmWindow(false);
        setReason("");
        setError("");
    }

    function onChangeReason(e, {value}) {
        setReason(value);
    }

    function renderConfirmWindow() {
        const {
            submit,
            submitBtnContent,
            cancelBtnContent,
            showReason,
            title,
        } = getActions()[action.type] || {};

        return (
            isShowConfirmWindow &&
            <NmConfirmV2
                content={
                    <>
                        {title}
                        <NmForm addMargin>
                            {
                                showReason &&
                                <NmTextareaV2
                                    placeholder={t("contractor-list.reason-placeholder")}
                                    name="reason"
                                    maxLength={255}
                                    error={error}
                                    value={reason}
                                    onChange={onChangeReason}
                                />
                            }
                        </NmForm>
                    </>
                }
                onCancel={closeConfirm}
                onConfirm={submit}
                confirmButton={submitBtnContent}
                cancelButton={cancelBtnContent}
                isOnlyConfirm
            />
        );
    }

    const onClickItemContextMenu = ({value: action}) => {
        switch (action) {
            case ACTION.COPY:
                handleCopyContact();

                return;
            case ACTION.ADD_TO_FAVOURITE:
                onAddToFavourite();

                return;
            case ACTION.ADD_TO_BLACK_LIST:
                onAddToBlacklist();

                return;
            case ACTION.INVITE_TO_ORDER:
                onInviteToOrder();

                return;
            default:
                return;
        }
    };

    const getContextMenu = () => {
        if (archived || isEmpty(contractor)) {
            return null;
        }

        const options = getContextMenuOptions(contractor, canViewContractorContacts);

        const inviteToOrder = isMassOperation ? [{
            ...options.inviteToOrder,
            disabled: true,
        }] : [options.inviteToOrder];
        const copy = canViewContractorContacts && phone ? [options.copy] : [];

        return (
            <ContextMenu
                options={[
                    ...copy,
                    options.addToFavourite,
                    options.addToBlackList,
                    ...inviteToOrder,
                ]}
                onClickItem={onClickItemContextMenu}
            />
        );
    };

    const onAddToFavourite = () => {
        setAction(favourite ? {
            type: "DELETE_FROM_FAVORITES",
            contractor,
        } : {type: "ADD_FAVORITE_CONTRACTOR", contractor});
        setShowConfirmWindow(true);
    };

    const onAddToBlacklist = () => {
        setAction(blacklist ? {
            type: "DELETE_CONTRACTOR_FROM_BLACK_LIST",
            contractor,
        } : {type: "ADD_CONTRACTOR_TO_BLACK_LIST", contractor});
        setShowConfirmWindow(true);
    };

    const onInviteToOrder = () => {
        setContractorId(contractorId);
        openInvitePopup();
    };

    function renderActions() {
        return isDesktop
            ? <>
                {
                    canViewContractorContacts && phone &&
                    <NmAdvancedTooltip
                        className={element("actions-button")}
                        hover
                        trigger={
                            <NmButton
                                onlyIcon
                                color="grey"
                                reactMaterialIcon="local_phone"
                                onClick={handleCopyContact}
                            />
                        }
                    >
                        {phoneFormat(phone)}
                    </NmAdvancedTooltip>
                }
                <NmButton
                    onlyIcon
                    className={element("actions-button")}
                    color="white"
                    onClick={onAddToFavourite}
                >
                    <NmIcon name={favourite ? "favorite-checked" : "favorite"} />
                </NmButton>
                <NmButton
                    onlyIcon
                    className={element("actions-button")}
                    color="white"
                    onClick={onAddToBlacklist}
                >
                    <NmIcon name={blacklist ? "blacklist-checked" : "blacklist"} />
                </NmButton>
                {
                    canViewContractorContacts && !archived && !isEmpty(contractor) &&
                    <NmButton
                        className={element("actions-button")}
                        color="light-green"
                        disabled={isMassOperation}
                        onClick={onInviteToOrder}
                    >
                        Пригласить на заказ
                    </NmButton>
                }
            </>
            : getContextMenu();
    }

    const renderContractorStatus = () => {
        const {registrationStatus, fullRegistrationDate, pendingPersonalDataSubmissionDate} = contractor;
        const isPending = [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(registrationStatus);
        const convertDate = formatDate(convertUtcToLocal(isPending && pendingPersonalDataSubmissionDate ? pendingPersonalDataSubmissionDate : fullRegistrationDate), "dd.MM.yyyy HH:mm:ss");
        const fullRegistrationDateTime = convertDate ? `(${convertDate})` : "";

        return !isEmpty(registrationStatus) ? [{
            label: "Статус",
            text: `${contractorStatusDict[registrationStatus]} ${fullRegistrationDateTime}`,
        }] : [];
    };

    const renderNetworkContractorStatus = () => {
        const {online, lastLoginDate} = contractor;

        if (online) {
            return [{
                render: () =>
                    <div
                        className="contractor-list-info__contractor-online"
                    >
                        <span
                            className="contractor-list-info__contractor-online-icon"
                        />
                        {localizationData.online}
                    </div>,
            }];
        }

        return !isNullOrWhitespace(lastLoginDate) ?
            [{
                label: "Последний визит",
                text: `${formatLocalDate(lastLoginDate, "dd.MM.yyyy HH:mm")}`,
            }] : [];
    };

    const getIsUzOrTj = (citizenship) => [citizenshipsDict.UZ.value, citizenshipsDict.TJ.value].includes(citizenship);

    const renderFnsCheckInfo = () => {
        const {taxStatus, citizenship} = contractor;

        if (getIsUzOrTj(citizenship)) {
            return null;
        }

        return (
            <SelfEmployedIndicator
                className="advertisement-contractor-card__rating-row-item"
                taxStatus={taxStatus}
            />
        );
    };

    const getErrorMessagePassport = () => {
        const {citizenship} = contractor;

        if ([citizenshipsDict.RU.value, citizenshipsDict.BY.value, citizenshipsDict.TJ.value].includes(citizenship)) {
            const passportDateEnd = contractor[CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE];

            if (!passportDateEnd) {
                return "";
            }

            const message = "Истекает срок действия документов";
            const field = getCheckDate(passportDateEnd, 0, 1);

            return checkEndDate({
                field,
                card: contractor,
                message,
            });
        }

        if (citizenshipsDict.BY.value === citizenship) {
            const errorMessage = checkPassportDataBy({values: contractor, card: contractor});

            return errorMessage ? "Истекает срок действия документов" : "";
        }

        if (citizenshipsDict.RU.value === citizenship) {
            const firstCheck = checkPassportDataRu({values: contractor, card: contractor});

            return firstCheck ? "Истекает срок действия документов" : secondCheckPassportRu(contractor);
        }
    };

    const secondCheckPassportRu = (data) => {
        const passportDate = data[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE];
        const birthDate = data[CONTRACTOR_FIELD.BIRTH_DATE];
        const leftPartEquation = getCheckDate(passportDate, 0, 30);
        const rightPartEquation1 = getCheckDate(birthDate, 20, 0);
        const rightPartEquation2 = getCheckDate(birthDate, 20, 0);

        const userAges = calculateAge(passportDate);

        if ((userAges === 20) && leftPartEquation < rightPartEquation1) {
            return "Истекает срок действия документов";
        }

        if ((userAges === 45) && leftPartEquation < rightPartEquation2) {
            return "Истекает срок действия документов";
        }

        return "";
    };

    const renderPassportCheckInfo = () => {
        return (
            <ContractorPassportStatus
                className="advertisement-contractor-card__rating-row-item"
                contractor={contractor}
            />
        );
    };

    const renderOrderCompletedCount = () => {
        const {orderCompletedCount, currentClientOrderCompletedCount} = contractor;

        if ((orderCompletedCount || currentClientOrderCompletedCount)) {
            return (
                <NmAdvancedTooltip
                    className="advertisement-contractor-card__rating-row-item"
                    position="bottom-left"
                    children={localizationData.completedOrdersHelp}
                    trigger={
                        <NmCheck
                            classNameIcon="advertisement-contractor-card__check"
                            isCheck={true}
                            label={`${localizationData.completedOrders} ${orderCompletedCount || 0}/${currentClientOrderCompletedCount || 0}`}
                        />
                    }
                    hover
                />
            );
        }

        return null;
    };

    const isShowPersonalData = ({status}) => {
        return isUserFromNm(role) || ![CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status);
    };

    const renderAge = () => {
        const {
            age,
        } = contractor;

        return !isNullOrWhitespace(age) && age !== 0 && isShowPersonalData(contractor) ? [{
            label: localizationData.age,
            text: pluralizeYears(age),
        }] : [];
    };

    const renderCitizenship = () => {
        const {
            citizenship,
        } = contractor;

        return isShowPersonalData(contractor) && !isNullOrWhitespace(citizenship) ? [{
            label: localizationData.citizenship,
            text: citizenshipList[citizenship],
        }] : [];
    };

    const getCities = () => {
        const {
            workAddressInfoArr,
            remoteWork,
        } = contractor;

        const workAddressArr = cloneDeep(workAddressInfoArr) || [];

        if (remoteWork && !isEmpty(workAddressArr)) {
            workAddressArr[0].remoteWork = remoteWork;
        }

        return !isEmpty(workAddressArr) ? [{
            columnOnMobile: true,
            noWrap: false,
            label: "Города ведения деятельности",
            text: (
                <NmSelectedList
                    showedItemsCount={3}
                    highlightingFirstItem
                    showListWithoutValue
                    list={workAddressArr}
                    dropdownWidth={250}
                />
            ),
        }] : [];
    };

    const getSpecialities = () => {
        const {
            mainSpecialityId,
            additionalSpecialityIds,
        } = contractor;

        const additionSpecialityValues = additionalSpecialityIds || [];

        return !isEmpty(mainSpecialityId) ? [{
            columnOnMobile: true,
            label: localizationData.specialtiesLabel,
            text: (
                <NmSelectedList
                    showedItemsCount={6}
                    highlightingFirstItem={true}
                    showListWithoutValue={true}
                    list={[mainSpecialityId, ...additionSpecialityValues].map(item => ({text: specialityDict.get(item)}))}
                    dropdownWidth={250}
                />
            ),
        }] : [];
    };

    return (
        <NmListCard
            noDivider
            alignItems="flex-start"
            classNameMainContent="col-16 col-xxl-6"
            avatar
            checkbox
            primaryHeader={renderLink()}
            primaryHeaderTooltip={
                <ContractorNoteForClientTooltip
                    className="ms-2"
                    note={contractor?.noteForClient}
                />
            }
            secondaryHeader={interestDate && `Дата отклика: ${formatDate(convertUtcToLocal(interestDate), "dd.MM.yyyy HH:mm")}`}
            labels={!isEmpty(contractor) ? [
                ...renderContractorStatus(),
                ...renderNetworkContractorStatus(),
                {
                    noWrap: false,
                    render: () => (
                        <div className="advertisement-contractor-card__rating-row">
                            <NmReadonlyRating
                                className="advertisement-contractor-card__rating-row-item me-5"
                                rating={contractor.overallRating || 0}
                            />
                            {isShowFnsStatus && renderFnsCheckInfo(contractor)}
                            {renderPassportCheckInfo(contractor)}
                            {renderOrderCompletedCount(contractor)}
                        </div>
                    ),
                },
                ...renderAge(),
                ...renderCitizenship(),
                {
                    label: localizationData.firstRegistration,
                    text: formatDate(convertUtcToLocal(contractor.naimixJoiningDate), "dd.MM.yyyy"),
                },
                ...getCities(),
                ...getSpecialities(),
            ] : []}
            actionsClassName={element("actions")}
            actions={renderActions()}
            isFixedActions
            otherContent={renderConfirmWindow()}
        />
    );
}

export default AdvertisementContractorCard;