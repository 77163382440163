import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import {
    getRecruitmentMessageTemplatesParams,
    updateRecruitmentDirectoryStore,
} from "../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    getVacancyOperationTypes,
    updateRecruitmentVacancyStore,
} from "../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {ClientCardRecruitmentRoute} from "./Route";

import {isNMUsers} from "../../../../utils/access";

import {
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_RECRUITMENT_CANDIDATES,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_MESSAGE_TEMPLATES,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES,
} from "../../../../constants/links";

import "./style.sass";

const ClientCardRecruitment = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVacancyOperationTypes());
        dispatch(getRecruitmentMessageTemplatesParams());

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                messageTemplateParams: [],
            }));
            dispatch(updateRecruitmentVacancyStore({
                pageData: {},
                list: [],
                totalCount: 0,
                operationTypes: {},
            }));
        };
    }, []);

    const getLink = () => {
        const recruitmentDirectoriesLink = LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS.replace(":clientId", clientId);
        const recruitmentVacanciesLink = LINK_CLIENT_RECRUITMENT_VACANCIES.replace(":clientId", clientId);
        const recruitmentCandidatesLink = LINK_CLIENT_RECRUITMENT_CANDIDATES.replace(":clientId", clientId);
        const recruitmentAccessControlLink = LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS.replace(":clientId", clientId);

        return {
            recruitmentDirectoriesLink,
            recruitmentVacanciesLink,
            recruitmentCandidatesLink,
            recruitmentAccessControlLink,
        };
    };

    const tabLinks = useMemo(() => {
        const {
            recruitmentDirectoriesLink,
            recruitmentVacanciesLink,
            recruitmentCandidatesLink,
            recruitmentAccessControlLink,
        } = getLink();

        return [
            {
                active: recruitmentVacanciesLink,
                link: recruitmentVacanciesLink,
                name: "Вакансии",
            },
            {
                active: recruitmentCandidatesLink,
                link: recruitmentCandidatesLink,
                name: "Кандидаты по вакансиям",
            },
            {
                active: [
                    recruitmentDirectoriesLink,
                    LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES.replace(":clientId", clientId),
                    LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS.replace(":clientId", clientId),
                    LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS.replace(":clientId", clientId),
                    LINK_CLIENT_RECRUITMENT_DIRECTORIES_MESSAGE_TEMPLATES.replace(":clientId", clientId),
                ],
                link: recruitmentDirectoriesLink,
                name: "Справочники",
            },
            {
                active: [
                    recruitmentAccessControlLink,
                    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS.replace(":clientId", clientId),
                    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS.replace(":clientId", clientId),
                    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD.replace(":clientId", clientId),
                ],
                link: recruitmentAccessControlLink,
                name: "Управление доступом",
            },
        ];
    }, []);

    return (
        <div className="client-card-patents">
            {
                isNMUsers() &&
                <Tabs
                    {...props}
                    className="client-card-recruitment__tabs"
                    panes={tabLinks}
                    secondary
                />
            }
            <ClientCardRecruitmentRoute
                {...props}
            />
        </div>
    );
};

export default ClientCardRecruitment;
