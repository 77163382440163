import React from "react";

import HelpTooltip from "../ActualComponents/HelpTooltip";
import Text from "../ActualComponents/Text";
import {Loader} from "semantic-ui-react";

import {getClassNames} from "../../utils/classNames";

import "./style.sass";

const TaskLoadingStatus = (props) => {
    const {
        className,
        isObjects,
        closeProjectTaskProcessing,
        changeProjectDepositTaskProcessing,
    } = props;

    const getText = () => {
        if (changeProjectDepositTaskProcessing) {
            return isObjects
                ? "Задача на выключение индивидуального депозита на связанном проекте в процессе"
                : "Задача на выключение индивидуального депозита на проекте в процессе";
        }

        if (closeProjectTaskProcessing) {
            return isObjects
                ? "Задача на закрытие связанного проекта в процессе"
                : "Задача на закрытие проекта в процессе";
        }
    };

    const message = `${getText()}...`;

    return (
        <div
            className={getClassNames([
                "individual-deposit-task-loading d-flex align-items-center",
                className,
            ])}
        >
            <div>
                <Loader
                    style={{zIndex: 990}}
                    active
                    inline
                    size="mini"
                />
            </div>
            <Text
                level="3"
                className="ms-1"
            >
                {message}
            </Text>
            <HelpTooltip
                info
                hover
                width={16}
                height={16}
                className="ms-1"
                children="Обновите страницу"
            />
        </div>
    );
};

export default TaskLoadingStatus;