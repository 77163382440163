import React, {useState} from "react";
import {useSelector} from "react-redux";

import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import ContractorOrderHistory from "./components/contractor-on-order-history";
import OrderHistory from "./components/order-history";

import {orderHistoryProgressSelector} from "../../../ducks/bff/orders/orders-registry/selectors";
import {contractorStatusSelector} from "../../../ducks/order";

const OrderHistoryPopup = (props) => {
    const {
        clientId,
        orderInfo,
        handleClose,
        events = [],
        totalCount,
        totalPages,
        progress,
        onFetchList,
        onClearStore,
        isCompanyOrdersPage = false,
    } = props;

    const contractorStatusDict = useSelector(contractorStatusSelector);
    const historyProgress = useSelector(orderHistoryProgressSelector);

    const [isOrderHistoryTab, setIsOrderHistoryTab] = useState(false);

    function getTabs() {
        if (isCompanyOrdersPage) {
            return [
                {
                    name: "История исполнителей на заказе",
                    active: !isOrderHistoryTab,
                    onClick: () => setIsOrderHistoryTab(false),
                },
                {
                    name: "История заказа",
                    active: isOrderHistoryTab,
                    onClick: () => setIsOrderHistoryTab(true),
                },
            ];
        }

        return null;
    }

    const renderContent = () => {
        if (isCompanyOrdersPage && isOrderHistoryTab) {
            return (
                <OrderHistory
                    tabs={getTabs()}
                    orderId={orderInfo.orderId}
                />
            );
        }

        return (
            <ContractorOrderHistory
                tabs={isCompanyOrdersPage ? getTabs() : null}
                clientId={clientId}
                events={events}
                contractorStatusDict={contractorStatusDict}
                onClearStore={onClearStore}
                totalCount={totalCount}
                totalPages={totalPages}
                onFetchList={onFetchList}
                orderId={orderInfo.orderId}
            />
        );
    };

    return (
        <NmModal
            onClose={handleClose}
            size="lg"
            classNameContent="flex-column"
            header={
                <Text
                    type="title"
                    level="3"
                    bold
                >
                    {`История по заказу #${orderInfo?.orderNum} - ${orderInfo?.name}`}
                </Text>
            }
            loading={progress || historyProgress}
        >
            {renderContent()}
        </NmModal>
    );
};

export default OrderHistoryPopup;