import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getDocumentsCustomTemplateReplacementsForUnavailableList} from "../../../ducks/bff/documents-templates/actionCreators";
import {documentCustomTemplateProgressReplacementsForUnavailableSelector} from "../../../ducks/bff/documents-templates/selectors";

export const useUnavailableTemplates = () => {
    const [open, setOpen] = useState(false);

    const progressReplacementsForUnavailableSelector = useSelector(documentCustomTemplateProgressReplacementsForUnavailableSelector);
    const dispatch = useDispatch();

    const onOpenUnavailableTemplates = () => {
        setOpen(true);
    };

    const onCloseUnavailableTemplates = () => {
        setOpen(false);
    };

    const checkUnavailableTemplates = (data) => {
        dispatch(getDocumentsCustomTemplateReplacementsForUnavailableList({
            ...data,
        }));
    };

    return {
        progressReplacementsForUnavailableSelector,
        isOpenUnavailableTemplates: open,
        onOpenUnavailableTemplates,
        onCloseUnavailableTemplates,
        checkUnavailableTemplates,
    };
};