export const BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST = "BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST";
export const BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS = "BFF_ACT_REGISTRIES_CARD_GET_BY_ID_SUCCESS";
export const BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR = "BFF_ACT_REGISTRIES_CARD_GET_BY_ID_ERROR";

export const BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST = "BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST";
export const BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS = "BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_SUCCESS";
export const BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR = "BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_ERROR";

export const BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST = "BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST";
export const BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS = "BFF_ACT_REGISTRIES_CARD_GET_PAY_SUCCESS";
export const BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR = "BFF_ACT_REGISTRIES_CARD_GET_PAY_ERROR";

export const BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST = "BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST";
export const BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_SUCCESS = "BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_SUCCESS";
export const BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_ERROR = "BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_ERROR";

export const BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST = "BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST";
export const BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS = "BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_SUCCESS";
export const BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR = "BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_ERROR";

export const BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST = "BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST";
export const BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS = "BFF_ADD_ACT_REGISTRY_PAYMENT_SUCCESS";
export const BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR = "BFF_ADD_ACT_REGISTRY_PAYMENT_ERROR";

export const BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST = "BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST";
export const BFF_DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS = "BFF_DELETE_ACT_REGISTRY_PAYMENTS_SUCCESS";
export const BFF_DELETE_ACT_REGISTRY_PAYMENTS_ERROR = "BFF_DELETE_ACT_REGISTRY_PAYMENTS_ERROR";

export const BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST = "BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST";
export const BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS = "BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_SUCCESS";
export const BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR = "BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_ERROR";

export const BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST = "BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST";
export const BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS = "BFF_UPDATE_ACT_REGISTRY_PAYMENT_SUCCESS";
export const BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR = "BFF_UPDATE_ACT_REGISTRY_PAYMENT_ERROR";

export const BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST = "BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST";
export const BFF_IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS = "BFF_IMPORT_ACT_REGISTRY_PAYMENTS_SUCCESS";
export const BFF_IMPORT_ACT_REGISTRY_PAYMENTS_ERROR = "BFF_IMPORT_ACT_REGISTRY_PAYMENTS_ERROR";

export const BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST = "BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST";
export const BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS = "BFF_ACT_REGISTRY_PAYMENTS_EXPORT_SUCCESS";
export const BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR = "BFF_ACT_REGISTRY_PAYMENTS_EXPORT_ERROR";

export const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST";
export const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_SUCCESS";
export const BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR = "BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_ERROR";

export const BFF_ACT_REGISTRIES_CARD_UPDATE_STORE = "BFF_ACT_REGISTRIES_CARD_UPDATE_STORE";