export const BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST = "BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST";
export const BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS = "BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_SUCCESS";
export const BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR = "BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_ERROR";

export const BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST = "BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST";
export const BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS = "BFF_FINANCES_PAID_APIS_GET_SETTINGS_SUCCESS";
export const BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR = "BFF_FINANCES_PAID_APIS_GET_SETTINGS_ERROR";

export const BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST = "BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST";
export const BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS = "BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_SUCCESS";
export const BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR = "BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_ERROR";

export const UPDATE_FINANCES_PAID_APIS_STORE = "UPDATE_FINANCES_PAID_APIS_STORE";