import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {debounce, isBoolean, isEmpty, isEqual} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTooltip from "../../../components/ActualComponents/NmTooltip";
import ApplyButtons from "../../../components/ApplyButtons";
import Steps from "../../../components/Steps";
import OrderEditActions from "./actions";
import {calculateAmounts} from "./calculator";
import OrderEditContextProvider from "./context-provider";
import OrderEditForm from "./form";
import OrderEditHeader from "./header";
import {OrderEditTariffScaleChecker} from "./tariff-scale-checker";
import OrderEditUnavailableTemplates from "./unavailable-templates";

import {getUnavailableTemplatesFlags} from "../../../components/UnavailableTemplates/utils";
import {isNMUsers} from "../../../utils/access";
import dateFormat, {
    convertUTCTimeToLocalTime,
    getDateWithoutTime,
    getHoursFromTime,
    getMinutesFromTime,
} from "../../../utils/dateFormat";
import {
    CURRENT_CLIENT_ID,
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {
    formatAmountWithComma,
    getNumberFromFormattedAmount,
} from "../../../utils/stringFormat";
import {
    convertDateToDateWithoutTimeString,
    convertStringToDate,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";
import validate from "../../../utils/validate";
import {getEditActTemplateRequestData} from "../order-templates/components/edit-act-template/utils/dto";
import {isTemplateOrderActEmpty} from "../order-templates/components/edit-act-template/utils/isActEmpty";
import {checkOfferedSpecialityWarning} from "./utils/checkOfferedSpecialityWarning";
import {getCurrentStep, getNextStep} from "./utils/getCurrentStep";
import {getOrderEditHeader} from "./utils/getHeader";
import {getOrderEditRequestDataForUnavailableTemplates} from "./utils/getReqDataForUnavailableTemplates";
import {
    getOrderEditRequestData,
    getOrderEditRequestDataAmount,
    getOrderEditRequestDataContractorsNeededCount,
} from "./utils/getRequestData";
import {getOrderEditTemplateId} from "./utils/getTemplateId";
import {getOrderEditValidations} from "./utils/getValidation";
import {validateOrderEditWorkStartDate} from "./utils/validate/workStartDate";

import {
    ORDER_FIELDS_NAME,
    ORDER_STATUS,
} from "../../../constants/clientList";
import {
    ORDER,
    TEMPLATE_MESSAGE,
} from "../../../constants/messages";
import {OBJECT_STATUS_DICT} from "../../../constants/objects";
import {
    getOrderFormInitState,
    MIN_PAYOUT_TO_CONTRACTOR,
    MIN_PAYOUT_TO_CONTRACTOR_ERROR_MESSAGE,
    ORDER_CREATION_MODE,
    ORDER_ERROR_CODE,
    ORDER_KIND,
    ORDER_MIN_SERVICE_VALUE,
    ORDER_WARNING_CODES,
    SPECIALITY_EXISTS_ERROR,
} from "../../../constants/order";
import {PROJECT_STATUS_DICT} from "../../../constants/projects";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_COORDINATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";
import {
    requiredMessage,
} from "../../../constants/validationRules";
import {ORDER_TEMPLATES_MESSAGE} from "../order-templates/constants";
import {
    ORDER_CREATE_STEPS,
    TABS,
    TEMPLATE_TABS,
} from "./constants";

import {getClientMemberList} from "../../../ducks/bff/clients/employees/actionCreators";
import {
    clientMemberProgressGetUsersSelector,
    orderAdminMembersSelector,
    orderManagerOptionsNoObjectSelector,
} from "../../../ducks/bff/clients/employees/selectors";
import {getClientCardProperties, getClientSpecialities} from "../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardCurrentInfoSelector,
    clientCardInfoProgressSelector,
    clientCardInfoSelector,
    clientCardPropertiesSelector,
    clientSpecialitiesSelector,
    getCurrentNmContractDateConclusionSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {
    getDocumentsCustomTemplateReplacementsForUnavailableList,
} from "../../../ducks/bff/documents-templates/actionCreators";
import {
    addOrder,
    getOrderRichById,
    getOrdersNotByTemplateCreationAvailability,
    getWarningBeforePublishOrder,
    updateOrder,
    updateStoreOrders,
} from "../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    orderCreationDataSelector,
    ordersNotByTemplateCreationAvailabilitySelector,
    ordersRegistryCardProgressSelector,
} from "../../../ducks/bff/orders/orders-registry/selectors";
import {
    clientCurrentMemberIdSelector,
    clientCurrentMemberSelector,
    currentMemberOptionTypeSelector,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";
import {
    clientObjectListSelector,
    getClientObjectList,
    updateFieldClientObjectStore,
} from "../../../ducks/clientObject";
import {
    findByOrderAndClientIds,
} from "../../../ducks/clientSpecialityOffer";
import {industryListSelector} from "../../../ducks/dict";
import {
    deleteOrderAttachmentsFile,
    getOrderAttachmentsFile,
    saveOrderAttachmentsFile,
} from "../../../ducks/fileStore";
import {
    updateFieldKeyword,
} from "../../../ducks/keyword";
import {
    getObjects,
    objectsCardSelector,
} from "../../../ducks/objects";
import {
    editOrder,
    orderIndustryProfessionsOptionsWithoutPostfixSelector,
    updateFieldOrderStore,
} from "../../../ducks/order";
import {
    getObjectAndProjectMembers,
    getOrderTemplateProjectsList,
    getUserTeamByClientUserId,
    managerListByUserOptionsSelector,
    managerListByUserSelector,
    managersObjectSelectedSelector,
    orderManagerOptionsWithObjectSelector,
    projectGetUsersAllProgressSelector,
    updateFieldsStoreProject,
} from "../../../ducks/projects";
import {
    specialitiesAllListV2Selector,
} from "../../../ducks/speciality";
import {checkStopWords} from "../../../ducks/stopWords";

import {orderType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

export const isValidWorkersCount = (value) => {
    value = value.replace(/\s+/g, "");

    return /^\d*$/.test(value) || value === "";
};

class OrderEdit extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        orderGroupId: PropTypes.string,
        editOrderId: PropTypes.string,
        order: orderType,
        managersOptions: PropTypes.array,
        currentMemberId: PropTypes.string,
        handleClose: PropTypes.func,
        createOrder: PropTypes.func,
        updateOrder: PropTypes.func,
        getOrderRichById: PropTypes.func,
        updateFieldOrderStore: PropTypes.func,
        getClientMemberList: PropTypes.func,
        duplicate: PropTypes.bool,
        objectId: PropTypes.string,
        formData: PropTypes.object,
    };

    static defaultProps = {
        clientId: "",
        editOrderId: "",
        currentMemberId: "",
        order: {},
        managersOptions: [],
        handleClose: () => {
        },
        createOrder: () => {
        },
        updateOrder: () => {
        },
        getOrderRichById: () => {
        },
        updateFieldOrderStore: () => {
        },
        getClientMemberList: () => {
        },
        duplicate: false,
        objectId: "",
        formData: {},
    };

    constructor(props) {
        super(props);
        const {
            duplicate,
            clientId,
            objectId,
            projectId,
            formData,
            isForemans,
            isForemanRequestDuplicate,
            isForemanRequestForAddition,
            clientPropertiesCard: {
                canCreateOnlyVolumeOfWorkBasedOrders,
                orderByTemplate,
            },
            creatingMode,
            addressId,
            objectLatitude,
            objectLongitude,
            disabledOfferedSpeciality,
            initialValueObjectFilter,
        } = props;

        this.isDuplicateOrder = duplicate;
        this.isForemans = isForemans;
        this.isForemanRequestForAddition = isForemanRequestForAddition;
        this.isForemanRequestDuplicate = isForemanRequestDuplicate;

        const role = ls(USER_ROLE);
        const isPromptOn = ls("promptActivateOrderEdit");
        this.role = role;
        this.isTemplate = creatingMode === ORDER_CREATION_MODE.TEMPLATE;

        this.state = {
            formData: {
                ...getOrderFormInitState({
                    isTemplate: this.isTemplate,
                }),
                orderManagerId: this.isTemplate ? null : this.responsibleValue,
                objectId,
                projectId,
                orderKind: canCreateOnlyVolumeOfWorkBasedOrders ?
                    ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER :
                    ORDER_KIND.AMOUNT_BASED_ORDER,
                ...formData,
                isTemplateType: orderByTemplate,
            },
            creatingMode,
            addressId,
            objectLatitude,
            objectLongitude,
            scale: null,
            documentFile: {},
            formError: {},
            order: {},
            isPromptOn: isPromptOn !== undefined ? JSON.parse(isPromptOn) : true,
            isShowMainPrompt: isPromptOn !== undefined ? JSON.parse(isPromptOn) : true,
            creationStatus: "",
            isOpenConfirmWindow: false,
            isOpenCalc: false,
            calcForm: {
                numberHours: "",
                calcPrice: "",
            },
            documentFileUrl: "",
            clientPropertiesCard: {},
            activeItems: [ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE],
            managerName: "",
            selectedManager: {},
            isSecondType: Boolean(canCreateOnlyVolumeOfWorkBasedOrders),
            calculatorData: {},
            isDisabledOfferedSpeciality: disabledOfferedSpeciality,
            isDisabledSpeciality: false,
            nameSubstringFilter: "",
            valueObjectFilter: initialValueObjectFilter || "",
        };

        this.clientId = clientId || ls(CURRENT_CLIENT_ID);
        this.tabs = this.isTemplate ? TEMPLATE_TABS : TABS;
    }

    get responsibleOptions() {
        if ([OBJECT_MANAGER, PROJECT_MANAGER].includes(this.role)) {
            const {managerListByUserOptions} = this.props;

            return managerListByUserOptions;
        }

        const {formData: {objectId}} = this.state;

        if (objectId) {
            const {managerOptionsWithObject} = this.props;

            return managerOptionsWithObject;
        }

        const {managersOptions} = this.props;

        return managersOptions;
    }

    get responsibleList() {
        if ([OBJECT_MANAGER, PROJECT_MANAGER].includes(this.role)) {
            const {managerListByUser} = this.props;

            return managerListByUser;
        }

        const {formData: {objectId}} = this.state;

        if (objectId) {
            const {managersObjectSelected} = this.props;

            return managersObjectSelected;
        }

        const {managersNoObjectSelected} = this.props;

        return managersNoObjectSelected;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            order,
            objectList: objects,
            objectId,
            editOrderId,
        } = this.props;
        const {
            order: _order,
            objectList: _objects,
        } = prevProps;
        const {
            formData: {
                objectId: formObjectId,
                address,
            },
            formData,
            isSecondType,
            valueObjectFilter,
        } = this.state;
        const {
            formData: {
                objectId: _formObjectId,
            },
            isSecondType: _isSecondType,
            valueObjectFilter: _valueObjectFilter,
        } = prevState;

        if (valueObjectFilter !== _valueObjectFilter) {
            this.getObjects();
        }

        if (editOrderId && !isEqual(objects, _objects)) {
            const {objectId} = order;
            const objectInfo = objects.find(item => (item.objectId === objectId)) || {};
            const {projectId} = objectInfo;

            if (projectId && projectId !== this.state.formData.projectId) {
                this.setState({
                    projectId,
                }, this.getManagers);
            }
        }

        if (
            this.isTemplate &&
            !isEqual(prevState.formData.templateProjectIds, this.state.formData.templateProjectIds) ||
            prevState.formData.isTemplateType !== this.state.formData.isTemplateType
        ) {
            this.getObjects();
        }

        if (!this.isTemplate && _formObjectId !== formObjectId) {
            this.getManagers();
        }

        if (objectId && objects.length && !isEqual(objects, _objects)) {
            this.saveAddress(objects, objectId);
        }

        // На всякий случай, если у заказа каким-то образом случайно оказалось не
        // заполнено поле address, и запрос на получение заказа выполнился позже, чем запрос
        // на получение объектов
        if (editOrderId && !address && !isEqual(order, _order) && objects.length) {
            const {objectId} = order;

            this.saveAddress(objects, objectId);
        }

        if (editOrderId && !this.isForemanRequestDuplicate && objects.length && !isEqual(objects, _objects)) {
            this.saveAddress(objects, objectId || order.objectId);
        }

        if (editOrderId && this.isForemanRequestDuplicate && objects.length && !isEqual(objects, _objects)) {
            this.saveAddress(objects, formObjectId);
        }

        const isTypeToggled = isSecondType !== _isSecondType;

        this.updateFields(prevState.formData, formData, isTypeToggled);
    }

    updateFields = (old, current, isTypeToggled) => {
        const {
            contractorsNeededCount: _contractorsNeededCount,
            contractorsNeededCountSecondType: _contractorsNeededCountSecondType,
            insurance: _insurance,
            workStartDate: _workStartDate,
            workEndDate: _workEndDate,
            workUnitPrice: _workUnitPrice,
            volumeOfWork: _volumeOfWork,
            amount: _amount,
        } = old;
        const {
            contractorsNeededCount,
            contractorsNeededCountSecondType,
            insurance,
            workStartDate,
            workEndDate,
            workUnitPrice,
            volumeOfWork,
            amount,
        } = current;

        if (
            contractorsNeededCount !== _contractorsNeededCount ||
            insurance !== _insurance ||
            workStartDate !== _workStartDate ||
            workEndDate !== _workEndDate ||
            workUnitPrice !== _workUnitPrice ||
            volumeOfWork !== _volumeOfWork ||
            amount !== _amount ||
            contractorsNeededCountSecondType !== _contractorsNeededCountSecondType ||
            isTypeToggled
        ) {
            this.updateCalculator();
        }
    };

    updateCalculator = () => {
        const {formData, isSecondType} = this.state;
        const {clientPropertiesCard} = this.props;

        const calculatorData = calculateAmounts(formData, isSecondType, clientPropertiesCard);

        this.setState({
            calculatorData,
        });
    };

    debounceCalculator = debounce(this.updateCalculator, 2000);

    static getDerivedStateFromProps(props, state) {
        const {
            order,
            editOrderId,
            duplicate,
            clientPropertiesCard,
        } = props;
        const {insuranceAvailable} = clientPropertiesCard;

        // Сохраняем тип заказа, страхование
        if (!isEqual(state.clientPropertiesCard, clientPropertiesCard)) {
            const {canCreateOnlyVolumeOfWorkBasedOrders: isSecondType} = clientPropertiesCard;

            if (!editOrderId) {
                return {
                    ...state,
                    isSecondType,
                    formData: {
                        ...state.formData,
                        orderKind: isSecondType ?
                            ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER :
                            ORDER_KIND.AMOUNT_BASED_ORDER,
                        insurance: insuranceAvailable,
                        checkInRequired: insuranceAvailable,
                        checkOutRequired: insuranceAvailable,
                    },
                    clientPropertiesCard,
                };
            }
        }

        const isLoadFromTemplate = Boolean(
            state.formData.isTemplateType && // Выбран переключатель - Заказ по шаблону
            state.formData.templateId, // Выбран шаблон заказа
        );
        if (
            (
                editOrderId &&
                order.orderId &&
                !isEqual(order, state.order)
            ) ||
            // Для подгрузки шаблона на форму
            (
                isLoadFromTemplate &&
                !isEqual(order, state.order)
            )
        ) {
            let workStartDate = !order.workStartDate ? null : new Date(order.workStartDate);
            let workEndDate = !order.workEndDate ? null : new Date(order.workEndDate);
            const {
                status,
                orderCategoryId,
            } = order;

            const {
                currentMemberId,
                isForemanRequestForAddition,
                isForemanRequestDuplicate,
                disabledOfferedSpeciality,
            } = props;

            const role = ls(USER_ROLE);

            if (isForemanRequestDuplicate) {
                workStartDate = props.formData.workStartDate;
                workEndDate = props.formData.workEndDate;
            }

            if (duplicate) {
                const dateNowWithoutTime = getDateWithoutTime(new Date());

                if (workStartDate !== null && workStartDate < dateNowWithoutTime) {
                    workStartDate = null;
                }

                if (workEndDate !== null && workEndDate < dateNowWithoutTime) {
                    workEndDate = null;
                }
            }

            const isResponsibleCurrentMember = ((isForemanRequestForAddition || isForemanRequestDuplicate) &&
                    [CLIENT_ADMIN, CLIENT_ACCOUNTANT, FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER].includes(role)) ||
                (isLoadFromTemplate && [FOREMAN].includes(role)) || (duplicate && !isNMUsers());
            const isSecondType = order.orderKind !== ORDER_KIND.AMOUNT_BASED_ORDER;
            const isDisabledOfferedSpeciality =
                status !== ORDER_STATUS.DRAFT &&
                !isForemanRequestDuplicate &&
                (
                    !duplicate || disabledOfferedSpeciality
                );

            // Шаблон заказа
            const templateId = isLoadFromTemplate ? state.formData.templateId : order.createByTemplateId;

            // Ответственный
            const orderManagerId = order.orderManagerId ? order.orderManagerId : state.formData.orderManagerId;
            const valueObjectFilter = isNullOrWhitespace(state.valueObjectFilter) ? order.objectName : state.valueObjectFilter;

            return {
                ...state,
                isSecondType,
                isDisabledOfferedSpeciality,
                isDisabledSpeciality: Boolean(orderCategoryId) && !duplicate && status !== ORDER_STATUS.DRAFT,
                formData: {
                    ...order,
                    // Сохраняем положение переключателя свободный заказ/по шаблону
                    templateParams: order.templateParams ? order.templateParams : {},
                    isTemplateType: Boolean(templateId),
                    objectId: isLoadFromTemplate ? state.formData.objectId : order.objectId,
                    projectId: isLoadFromTemplate ? state.formData.projectId : order.projectId,
                    templateId,
                    clientSpecialityOffer: undefined,
                    speciality: undefined,
                    workStartDate,
                    workEndDate,
                    orderManagerId: isResponsibleCurrentMember ? currentMemberId : orderManagerId,
                    amount: !isNullOrWhitespace(order.amount) ? formatAmountWithComma(order.amount) : "",
                    address: props.formData.address || order.address || "",
                    contractorsNeededCount: isSecondType ? "" : (order.contractorsNeededCount || ""),
                    contractorsNeededCountSecondType: isSecondType
                        ? (order.contractorsNeededCount || "")
                        : ORDER_MIN_SERVICE_VALUE,
                    workUnit: isSecondType ? order.workUnit : null,
                    keywords: order.keywords ? order.keywords.map(({keyword}) => ({content: keyword})) : [],
                    volumeOfWork: isSecondType && order.volumeOfWork ?
                        String(order.volumeOfWork).replace(".", ",") :
                        null,
                    checkOutTimePeriod: {
                        from: order.checkOutFrom ? convertUTCTimeToLocalTime(order.checkOutFrom) : null,
                        to: order.checkOutTo ? convertUTCTimeToLocalTime(order.checkOutTo) : null,
                    },
                    checkInTimePeriod: {
                        from: order.checkInFrom ? convertUTCTimeToLocalTime(order.checkInFrom) : null,
                        to: order.checkInTo ? convertUTCTimeToLocalTime(order.checkInTo) : null,
                    },
                    isRequiredCheckInPeriod: Boolean(order.checkInFrom || order.checkInTo),
                    isRequiredCheckOutPeriod: Boolean(order.checkOutFrom || order.checkOutTo),
                    outTimeMinutes: getMinutesFromTime(order.outTime),
                    outTimeHours: getHoursFromTime(order.outTime),
                    timeForExecutionMinutes: getMinutesFromTime(order.timeForExecutionHours),
                    timeForExecutionHours: getHoursFromTime(order.timeForExecutionHours),
                    contractorJoinMessage: order.contractorJoinMessage || "",
                    ...props.formData,
                },
                addressId: order.objectAddressId,
                objectLatitude: order.objectLatitude,
                objectLongitude: order.objectLongitude,
                metroInfo: order.metroInfo,
                valueObjectFilter: valueObjectFilter || "",
                order,
            };
        }

        return null;
    }

    componentWillUnmount() {
        const {
            updateFieldOrderStore,
            updateFieldKeyword,
            updateFieldClientObjectStore,
            updateFieldClientMemberStore,
            updateStoreOrders,
            updateFieldsStoreProject,
        } = this.props;

        this.debounceCalculator.cancel();

        if (!this.isDuplicateOrder) {
            updateFieldOrderStore("card", {});
        }

        if ([PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role)) {
            updateFieldsStoreProject({projectsUserResponsibleFor: [], managerListByUser: []});
        }

        updateStoreOrders({orderCreationData: {}});
        updateFieldsStoreProject({objectAndProjectMembers: []});
        updateFieldClientMemberStore({adminMembers: []});
        updateFieldClientObjectStore("list", []);
        updateFieldKeyword({autocompleteKeywords: []});
        updateFieldOrderStore("ordersNotByTemplateCreationAvailability", false);
    }

    componentDidMount() {
        const {
            editOrderId: orderId,
            getOrderRichById,
            getClientCardProperties,
            currentMemberId: clientUserId,
            getClientSpecialities,
            getOrdersNotByTemplateCreationAvailability,
        } = this.props;
        const {
            formData,
        } = this.state;

        getClientSpecialities({
            clientId: this.clientId,
            filterBySetting: true,
        });

        if (orderId && !this.isForemanRequestForAddition) {
            getOrderRichById({
                orderId,
                clientId: this.clientId,
                nameField: "orderCreationData",
                callBack: this.getOrderByIdCallback,
            });
        }

        if (
            this.isForemanRequestForAddition ||
            this.isDuplicateOrder ||
            this.isTemplate
        ) {
            this.updateCalculator();
        }

        getClientCardProperties({clientId: this.clientId});

        if (!this.isTemplate) {
            this.getManagers();
            this.getObjectsByManager({role: this.role, clientUserId});
        }

        if (this.isTemplate) {
            this.getProjects();
        }

        if (this.isTemplate && formData.projectId) {
            this.getObjects();
        }

        getOrdersNotByTemplateCreationAvailability({clientId: this.clientId});
    }

    getProjects = () => {
        const {getOrderTemplateProjectsList} = this.props;

        getOrderTemplateProjectsList({
            clientId: this.clientId,
            status: PROJECT_STATUS_DICT.IN_WORK.VALUE,
            skipProjectWithAllClosedObject: true,
        });
    };

    getOrderByIdCallback = (order) => {
        const {
            orderId,
            attachmentFileName,
            specialityId,
            clientSpecialityOffer,
        } = order;

        // Подгружаем прикрепленные файлы, если есть
        if (attachmentFileName) {
            this.getAttachment(attachmentFileName, orderId);
        }

        // Подгружаем предложенный ВД если нет обычного и
        // Предложенный ВД не отклонен и не согласован (на рассмотрении)
        // (если согласован, должен приходить в specialityId и в справочнике ВД)
        if (!specialityId && clientSpecialityOffer && clientSpecialityOffer.accepted === null) {
            this.fetchSpeciality(orderId);
        }
    };

    fetchSpeciality = (orderId) => {
        const {
            findByOrderAndClientIds,
        } = this.props;

        findByOrderAndClientIds({
            callBack: this.saveOfferedSpeciality,
            reqData: [{
                clientId: this.clientId,
                orderId,
            }],
        });
    };

    saveOfferedSpeciality = result => {
        const isEmptyResult = isEmpty(result);

        if (isEmptyResult) {
            console.error("Ошибка при сохранении предложенного ВД");
        }

        const offeredSpecialities = !isEmptyResult ? result : [{}];

        const [{
            clientOfferedSpecialityName = "",
        }] = offeredSpecialities;

        this.setState({
            offeredSpecialityName: clientOfferedSpecialityName,
        });
    };

    getAttachment = (fileName, orderId) => {
        const {getOrderAttachmentsFile} = this.props;

        getOrderAttachmentsFile({orderId, fileName, clientId: this.clientId, setFile: this.setFile});
    };

    getObjectsByManager = (managerInfo = {}) => {
        const {role, clientUserId} = managerInfo;

        if ([PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(role)) {
            this.getObjects(clientUserId);

            return;
        }

        this.getObjects();
    };

    getObjects = (clientUserId, projectIds) => {
        const {getClientObjectList} = this.props;
        const {valueObjectFilter} = this.state;
        const {formData: {isTemplateType}} = this.state;

        if (this.isTemplate) {
            const {
                formData: {
                    templateProjectIds: projectIds,
                },
            } = this.state;

            getClientObjectList({
                clientId: this.clientId,
                projectIds,
                orderType: "ASC",
                pageNum: 1,
                //onlyByTemplate: true,
                //skipOnlyByTemplate: false,
                pageSize: 2000,
                status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
                nameSubstringFilter: valueObjectFilter,
            });

            return;
        }

        getClientObjectList({
            clientId: this.clientId,
            clientUserId,
            projectIds,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            onlyByTemplate: false,
            skipOnlyByTemplate: !isTemplateType,
            onlyNotByTemplateIfRequired: !isTemplateType,
            onlyFromOrderTemplates: isTemplateType,
            status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
            nameSubstringFilter: valueObjectFilter,
        });
    };

    getManagers = () => {
        if ([PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role)) {
            const {currentMemberId} = this.props;

            this.fetchUserTeamByUserId(currentMemberId);

            return;
        }

        const {formData: {objectId}} = this.state;

        if (objectId) {
            this.getResponsibleWithObject();
        }

        if (!objectId) {
            this.getResponsibleNoObjectSelected();
        }
    };

    addSpecialityToForm = (offeredSpecialityName) => {
        this.setState({
            offeredSpecialityName,
            specialityExistsError: null,
        });
    };

    getResponsibleNoObjectSelected = () => {
        const {
            projectId,
            getClientMemberList,
        } = this.props;

        getClientMemberList({
            clientId: this.clientId,
            archiveFilter: false,
            roleListFilter: [FOREMAN, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT],
            projectId,
            pageNum: 1,
            pageSize: 300,
            fieldName: "adminMembers",
        });
    };

    getResponsibleWithObject = () => {
        const {formData: {objectId, projectId}} = this.state;
        const {
            getClientMemberList,
            getObjectAndProjectMembers,
        } = this.props;

        getObjectAndProjectMembers({
            objectId,
            projectId,
            clientId: this.clientId,
        });

        getClientMemberList({
            clientId: this.clientId,
            archiveFilter: false,
            roleFilter: this.role === CLIENT_ACCOUNTANT ? CLIENT_ACCOUNTANT : CLIENT_ADMIN,
            objectId,
            projectId,
            pageNum: 1,
            pageSize: 300,
            fieldName: "adminMembers",
        });
    };

    fetchUserTeamByUserId = clientUserId => {
        const {getUserTeamByClientUserId} = this.props;

        getUserTeamByClientUserId({
            clientId: this.clientId,
            clientUserId,
            roleFilters: [PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN],
        });
    };

    saveAddress = (objects, objectId) => {
        const object = objects.find(value => (value.objectId === objectId)) || {};
        const {address = ""} = object;

        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                address,
            },
        }));
    };

    get isDisabled() {
        const {
            orderStatus,
        } = this.props;

        if (this.isDuplicateOrder) {
            return false;
        }

        return [ORDER_STATUS.PUBLISHED, ORDER_STATUS.CONTRACTOR_SEARCH].includes(orderStatus) || this.role === NM_COORDINATOR;
    }

    /***
     * при нажатии на Enter в дропдауне, срабатывает только при условии,
     * что в дропдауне отображается 0 вариантов
     * @param specialityName
     * @returns {boolean}
     */
    saveSpecialityByEnter = (specialityName) => {
        const {
            isDisabledOfferedSpeciality,
        } = this.state;

        if (isDisabledOfferedSpeciality) {
            return false;
        }

        const {
            allSpecialities,
            specialitiesClient,
        } = this.props;

        // Сначала ищем во всем справочнике ВД, чтобы понять предлагают свой ВД или нет
        const specialityExists = allSpecialities.find(item => (item.value.toLowerCase() === specialityName.trim().toLowerCase()));

        // Если не найден ВД в справочнике, то по нажатию на enter добавляем свой ВД
        if (!specialityExists) {
            this.addSpecialityToForm(specialityName);

            return true;
        }

        const {id} = specialityExists;

        const specialityClientExists = specialitiesClient.find(item => (item.id === id));

        // Если ВД не найден в списке ВД компаний, то отображаем ошибку
        if (!specialityClientExists) {
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    specialityId: "",
                },
                offeredSpecialityName: "",
                specialityExistsError: SPECIALITY_EXISTS_ERROR,
            }));

            return false;
        }

        this.addSpecialityToForm(specialityName);

        return true;
    };

    handleChange = (event, {checked, name, value, onChangeCallback}) => {
        const {ordersNotByTemplateCreationAvailability} = this.props;

        if (name === "templateId") {
            this.setState(prevState => ({
                ...prevState,
                creatingMode: ORDER_CREATION_MODE.FROM_TEMPLATE,
                formData: {
                    ...prevState.formData,
                    templateId: value,
                },
            }), () => {
                const {getOrderRichById} = this.props;

                getOrderRichById({
                    orderId: this.state.formData.templateId,
                    clientId: this.clientId,
                    nameField: "orderCreationData",
                    callBack: this.getOrderByIdCallback,
                });
            });
        }

        if (name === "isTemplateType" && !checked && !ordersNotByTemplateCreationAvailability) {
            this.setState({
                isOpenConfirmWindow: true,
                contentConfirm: "Создание свободных заказов невозможно, поскольку все объекты по которым Вы можете создать заказ могут быть созданы только по шаблону.",
                onConfirm: this.handleCancelConfirm,
                submitBtnContent: "Ок",
                isHiddenCancel: true,
            });

            return;
        }

        if (name === "isTemplateType") {
            this.setState(prevState => ({
                ...prevState,
                creatingMode: null,
                formData: {
                    ...prevState.formData,
                    isTemplateType: checked,
                    objectId: "",
                },
            }));
        }

        if (name === "orderKind") {
            this.setState(prevState => {
                const isSecondType = value === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER;
                const workUnit = prevState.formData.workUnit ? prevState.formData.workUnit : "SERVICE";

                if (isSecondType && this.isTemplate) {
                    const volumeOfWork = workUnit === "SERVICE" ? ORDER_MIN_SERVICE_VALUE : prevState.formData.volumeOfWork;

                    return {
                        ...prevState,
                        isSecondType,
                        formData: {
                            ...prevState.formData,
                            orderKind: value,
                            workUnit,
                            volumeOfWork,
                        },
                    };
                }

                if (isSecondType) {
                    const volumeOfWork = prevState.formData.volumeOfWork
                        ? prevState.formData.volumeOfWork
                        : ORDER_MIN_SERVICE_VALUE;

                    return {
                        ...prevState,
                        isSecondType,
                        formData: {
                            ...prevState.formData,
                            orderKind: value,
                            workUnit,
                            volumeOfWork,
                        },
                    };
                }

                return {
                    ...prevState,
                    isSecondType,
                    formData: {
                        ...prevState.formData,
                        orderKind: value,
                    },
                };
            }, onChangeCallback);

            return;
        }

        if (name === ORDER_FIELDS_NAME.SPECIALTY_ID) {
            this.setState(prevState => ({
                ...prevState,
                specialityExistsError: null,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
                offeredSpecialityName: "",
                formError: {
                    ...prevState.formError,
                    speciality: null,
                },
            }));

            return;
        }

        if (name === ORDER_FIELDS_NAME.OBJECT_ID) {
            const {projectId, address, addressId, metroInfo, latitude, longitude} = this.objectInfo(value);

            this.setState(prevState => ({
                ...prevState,
                addressId,
                metroInfo,
                objectLatitude: latitude,
                objectLongitude: longitude,
                formData: {
                    ...prevState.formData,
                    projectId,
                    templateId: "", // При выборе объекта надо подчищать значение в списке шаблон заказа
                    [name]: value,
                    address,
                },
            }));

            return;
        }

        if (name === ORDER_FIELDS_NAME.RESPONSIBLE) {
            const selectedManager = this.responsibleList.find(item => item.clientUserId === value) || {};

            const {
                role,
                clientUserId,
                firstName: managerName,
            } = selectedManager;

            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
                managerName,
                selectedManager,
            }), () => {
                this.getObjectsByManager({role, clientUserId});
            });

            return;
        }

        if (name === ORDER_FIELDS_NAME.KEYWORDS) {
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            }));

            return;
        }

        if (name === ORDER_FIELDS_NAME.CHECK_IN_REQUIRED) {
            const {order} = this.props;
            const {creatingMode} = this.state;

            const isChecked = !checked && false;
            const isRequiredCheckInPeriodFilling = checked && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                && (
                    order.checkInFrom
                    || order.checkInTo
                );
            const isCheckInPhotoRequiredFilling = checked
                && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                && isBoolean(order.checkInPhotoRequired);

            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: checked,
                    checkInPhotoRequired: isCheckInPhotoRequiredFilling
                        ? order.checkInPhotoRequired
                        : isChecked,
                    isRequiredCheckInPeriod: isRequiredCheckInPeriodFilling
                        ? true
                        : isChecked,
                },
            }));

            return;
        }

        if (name === ORDER_FIELDS_NAME.CHECK_OUT_REQUIRED) {
            const {order} = this.props;
            const {creatingMode} = this.state;

            const isChecked = !checked && false;
            const isRequiredCheckOutPeriodFilling = checked && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                && (
                    order.checkOutFrom
                    || order.checkOutTo
                );
            const isCheckOutPhotoRequiredFilling = checked
                && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE
                && isBoolean(order.checkOutPhotoRequired);

            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: checked,
                    checkOutPhotoRequired: isCheckOutPhotoRequiredFilling
                        ? order.checkOutPhotoRequired
                        : isChecked,
                    isRequiredCheckOutPeriod: isRequiredCheckOutPeriodFilling
                        ? true
                        : isChecked,
                },
            }));

            return;
        }

        if (name === ORDER_FIELDS_NAME.INSURANCE) {
            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: checked,
                    [ORDER_FIELDS_NAME.CHECK_IN_REQUIRED]: checked,
                    [ORDER_FIELDS_NAME.CHECK_OUT_REQUIRED]: checked,
                    [ORDER_FIELDS_NAME.CHECK_IN_PHOTO_REQUIRED]: !checked && false,
                    [ORDER_FIELDS_NAME.CHECK_OUT_PHOTO_REQUIRED]: !checked && false,
                },
            }));

            return;
        }

        if ([ORDER_FIELDS_NAME.CONTRACTORS_NEEDED_COUNT_SECOND_TYPE, ORDER_FIELDS_NAME.CONTRACTORS_NEEDED_COUNT].includes(name) && !isValidWorkersCount(value)) {
            return;
        }

        if (name === ORDER_FIELDS_NAME.WORK_UNIT && value === "SERVICE") {
            this.setState(prevState => {
                const {
                    formData: {
                        contractorsNeededCountSecondType,
                    },
                } = prevState;

                return {
                    ...prevState,
                    formData: {
                        ...prevState.formData,
                        [ORDER_FIELDS_NAME.WORK_UNIT]: value,
                        [ORDER_FIELDS_NAME.VOLUME_OF_WORK]: contractorsNeededCountSecondType
                            ? contractorsNeededCountSecondType
                            : ORDER_MIN_SERVICE_VALUE,
                        [ORDER_FIELDS_NAME.CONTRACTORS_NEEDED_COUNT]: ORDER_MIN_SERVICE_VALUE,
                    },
                };
            });

            return;
        }

        if (name === ORDER_FIELDS_NAME.NEED_DRIVER_LICENSE) {
            const {order} = this.props;
            const {creatingMode} = this.state;

            const isDriverLicenseTypesFilling = checked && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE && !isEmpty(order.driverLicenseTypes);
            const isContractorHasCarFilling = checked && creatingMode === ORDER_CREATION_MODE.FROM_TEMPLATE && isBoolean(order.contractorHasCar);

            this.setState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: checked,
                    driverLicenseTypes: isDriverLicenseTypesFilling
                        ? order.driverLicenseTypes
                        : [],
                    contractorHasCar: isContractorHasCarFilling
                        ? order.contractorHasCar
                        : false,
                },
            }));

            return;
        }

        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: typeof checked === "boolean" ? checked : value,
            },
        }));
    };

    validationForm({creationStatus, formData}) {
        const {
            isSecondType,
            activeItems,
            isDisabledSpeciality,
            specialityExistsError,
        } = this.state;

        const rule = getOrderEditValidations({
            isTemplate: this.isTemplate,
            creationStatus,
            isSecondType,
            activeItems,
            isDisabledSpeciality,
            tabs: this.tabs,
            isDetectingParamsInActDescription: this.isTemplate && Boolean(formData.parameterizedOrderWorkReportTemplate),
            isTemplateType: formData.isTemplateType,
        });

        const errorMessage = validate(formData, rule, "");

        this.setState(prevState => ({
            formError: {
                ...prevState.formError,
                ...errorMessage,
            },
        }));

        return Object.values(errorMessage).length === 0 && !specialityExistsError;
    };

    onSearchObject = (valueObjectFilter) => {
        this.setState(prevState => ({
            ...prevState,
            valueObjectFilter,
        }));
    };

    handleCreate = (creationStatus) => {
        const {
            nmContractDateConclusion,
            t,
        } = this.props;
        const {
            formData,
            offeredSpecialityName,
        } = this.state;
        const {
            specialityId,
            amount,
        } = formData;

        const validatedData = {
            ...formData,
            amount: amount ? getNumberFromFormattedAmount(amount) : amount,
            speciality: offeredSpecialityName ? offeredSpecialityName : specialityId,
        };

        if (creationStatus) {
            const isValid = this.validationForm({creationStatus, formData: validatedData});

            if (!isValid) {
                return;
            }
        }

        if (this.state.creationStatus === ORDER_STATUS.CONTRACTOR_SEARCH && nmContractDateConclusion !== null) {
            const _nmContractDateConclusion = convertStringToDate(convertDateToDateWithoutTimeString(nmContractDateConclusion));
            const wordStartDate = convertStringToDate(formData[ORDER_FIELDS_NAME.WORK_START_DATE], true);

            if (wordStartDate < _nmContractDateConclusion) {
                toastError(`${t("order-new.error-message-start-period")} ${dateFormat(_nmContractDateConclusion, "dd.MM.yyyy")}`);
                this.handleCancelConfirm();

                return;
            }
        }

        this.sendQuery(creationStatus);

        this.handleCancelConfirm();
    };

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
            creationStatus: "",
        });
    };

    onOpenUnavailableTemplates = (creationStatus) => {
        this.setState({
            creationStatus,
            isOpenUnavailableTemplates: true,
        });
    };

    sendQuery = (creationStatus) => {
        const {
            offeredSpecialityName,
            formData,
            creatingMode,
        } = this.state;
        const {
            orderStatus,
            editOrderId,
            editOrder,
            updateOrder,
            addOrder,
            updateRequest,
            order: {version},
            duplicate,
            handleClose,
            order,
            fetchList,
        } = this.props;

        const requestData = getOrderEditRequestData({
            order,
            isDuplicateOrder: this.isDuplicateOrder,
            creationStatus: creationStatus ? creationStatus : this.state.creationStatus,
            formData,
            isSecondType: this.state.isSecondType,
            calculatorData: this.state.calculatorData,
            offeredSpecialityName,
            editOrderId: this.props.editOrderId,
            clientId: this.props.clientId,
            orderGroupId: this.props.orderGroupId,
            clientPropertiesCard: this.props.clientPropertiesCard,
        });

        // Заявки бригадиров
        if (this.isForemans) {
            updateRequest({
                ...requestData,
                close: handleClose,
                version,
            });

            return;
        }

        // для статуса "В работе" при редактировании
        if (orderStatus === ORDER_STATUS.PUBLISHED) {
            editOrder({
                ...requestData,
                addDocumentFile: this.addDocumentFile,
                version,
            });

            handleClose();

            return;
        }

        if (orderStatus === ORDER_STATUS.TEMPLATE) {
            updateOrder({
                ...requestData,
                status: ORDER_STATUS.TEMPLATE,
                addDocumentFile: this.addDocumentFile,
                successToast: TEMPLATE_MESSAGE.UPDATE_SUCCESS,
                errorToast: TEMPLATE_MESSAGE.UPDATE_ERROR,
                close: handleClose,
                onSuccess: this.props.onSubmitActCreation,
                version,
            });

            return;
        }

        // Для статуса "Черновик", "Поиск исполнителей" при редактировании
        if (editOrderId && !this.isDuplicateOrder && creatingMode !== ORDER_CREATION_MODE.FROM_TEMPLATE) {
            updateOrder({
                ...requestData,
                addDocumentFile: this.addDocumentFile,
                close: handleClose,
                successToast: ORDER.ORDER_UPDATE_SUCCESS_TEXT,
                errorToast: ORDER.ORDER_UPDATE_ERROR_TEXT,
                onSuccess: ({status}) => {
                    checkOfferedSpecialityWarning({
                        creationStatus,
                        status,
                        offeredSpecialityName,
                    });

                    fetchList();
                },
                version,
            });

            return;
        }

        const {region} = this.objectInfo();

        if (this.isTemplate) {
            addOrder({
                ...requestData,
                addDocumentFile: this.addDocumentFile,
                close: handleClose,
                archived: false,
                isNeedFetch: false,
                successToast: TEMPLATE_MESSAGE.ADD_SUCCESS,
                errorToast: TEMPLATE_MESSAGE.ADD_ERROR,
                status: ORDER_STATUS.TEMPLATE,
                onSuccess: this.props.onSubmitActCreation,
                onError: this.onErrorCreateOrder,
                region,
                duplicate,
            });

            return;
        }

        addOrder({
            ...requestData,
            onSuccess: ({status}) => {
                checkOfferedSpecialityWarning({
                    creationStatus,
                    status,
                    offeredSpecialityName,
                });
            },
            addDocumentFile: this.addDocumentFile,
            close: handleClose,
            archived: false,
            templateObjectIds: null,
            templateObjects: null,
            templateProjectIds: null,
            templateProjects: null,
            orderNum: null,
            orderId: null,
            createByTemplateId: getOrderEditTemplateId(formData, order, creatingMode),
            successToast: creationStatus === ORDER_STATUS.DRAFT
                ? ORDER.ORDER_ADD_SUCCESS_TEXT
                : ORDER.ORDER_PUBLISH_SUCCESS_TEXT,
            errorToast: ORDER.ORDER_ADD_ERROR_TEXT,
            onError: this.onErrorCreateOrder,
            region,
            duplicate,
        });
    };

    addDocumentFile = (orderId) => {
        const {documentFile, documentFile: {name}} = this.state;

        const {order: {attachmentFileExists}} = this.props;

        if (attachmentFileExists && !name) {
            const {order: {orderId}} = this.props;
            const {deleteOrderAttachmentsFile} = this.props;

            deleteOrderAttachmentsFile({clientId: this.clientId, orderId});

            return;
        }

        if (!name) {
            return;
        }

        const {saveOrderAttachmentsFile} = this.props;

        const formData = new FormData();

        formData.append("file", documentFile);

        saveOrderAttachmentsFile({clientId: this.clientId, orderId, file: formData});
    };

    validateStopWords = (currentStep) => {
        const {
            formData,
        } = this.state;
        const {
            name,
            description,
        } = formData;
        const queries = [];

        if (name.length) {
            queries.push(name);
        }

        if (description.length) {
            queries.push(description);
        }

        if (!isEmpty(queries)) {
            const {checkStopWords} = this.props;

            checkStopWords({
                data: {
                    errorMessageType: "ORDER_PUBLICATION",
                    queries,
                },
                onSuccess: () => this.goNext(currentStep),
            });

            return;
        }

        this.goNext(currentStep);
    };

    onClickGoNext = () => {
        const {
            activeItems,
        } = this.state;

        const currentStep = getCurrentStep({activeItems, tabs: this.tabs});

        // Проверка на стоп-слова
        if (currentStep === ORDER_CREATE_STEPS.FIRST_STEP.VALUE) {
            this.validateStopWords(currentStep);

            return;
        }

        this.goNext(currentStep);
    };

    setTariffScale = (scale) => {
        this.setState({
            scale,
        });
    };

    goNext = (currentStep) => {
        const {
            formData,
            offeredSpecialityName,
            activeItems,
        } = this.state;
        const {
            specialityId,
            volumeOfWork,
            workUnitPrice,
            amount,
        } = formData;

        const validatedData = {
            ...formData,
            amount: amount ? getNumberFromFormattedAmount(amount) : amount,
            speciality: offeredSpecialityName ? offeredSpecialityName : specialityId,
            volumeOfWork: volumeOfWork ? getNumberFromFormattedAmount(volumeOfWork) : volumeOfWork,
            workUnitPrice: workUnitPrice ? getNumberFromFormattedAmount(workUnitPrice) : workUnitPrice,
        };

        const {
            formData: {
                workStartDate,
                workEndDate,
            },
        } = this.state;

        const {
            clientPropertiesCard,
            currentMember,
        } = this.props;

        const workStartDateError = validateOrderEditWorkStartDate({
            currentStep,
            workStartDate,
            workEndDate,
            currentMember,
            clientProperties: clientPropertiesCard,
            isTemplate: this.isTemplate,
            isForemans: this.isForemans,
        });

        this.setState(prevState => ({
            ...prevState,
            formError: {
                ...prevState,
                workStartDate: workStartDateError,
            },
        }));

        const isValid = this.validationForm({formData: validatedData});

        if (!isValid || workStartDateError) {
            this.handleCancelConfirm();

            return;
        }

        if (currentStep === ORDER_CREATE_STEPS.SECOND_STEP.VALUE) {
            const {
                formData: {
                    driverLicenseTypes,
                    contractorHasCar,
                    needDriverLicense,
                    isRequiredCheckInPeriod,
                    isRequiredCheckOutPeriod,
                    checkOutTimePeriod,
                    checkInTimePeriod,
                    useContractorJoinMessage,
                    contractorJoinMessage,
                },
                formError,
            } = this.state;

            // Валидация для периода чекина
            if (isRequiredCheckInPeriod && (!checkInTimePeriod.to || !checkInTimePeriod.from)) {
                this.setState(prevState => ({
                    ...prevState,
                    formError: {
                        ...prevState,
                        checkInTimePeriod: requiredMessage,
                    },
                }));

                return;
            } else if (formError.checkInTimePeriod) {
                this.setState(prevState => ({
                    ...prevState,
                    formError: {
                        ...prevState,
                        checkInTimePeriod: null,
                    },
                }));
            }

            // Валидация для периода чекаута
            if (isRequiredCheckOutPeriod && (!checkOutTimePeriod.to || !checkOutTimePeriod.from)) {
                this.setState(prevState => ({
                    ...prevState,
                    formError: {
                        ...prevState,
                        checkOutTimePeriod: requiredMessage,
                    },
                }));

                return;
            } else if (formError.checkOutTimePeriod) {
                this.setState(prevState => ({
                    ...prevState,
                    formError: {
                        ...prevState,
                        checkOutTimePeriod: null,
                    },
                }));
            }

            // Валидация Сообщения при отклике
            if (useContractorJoinMessage) {
                if (!contractorJoinMessage) {
                    this.setState(prevState => ({
                        ...prevState,
                        formError: {
                            ...prevState,
                            contractorJoinMessage: requiredMessage,
                        },
                    }));

                    return;
                }

                if (contractorJoinMessage.length < 5 || contractorJoinMessage.length > 255) {
                    this.setState(prevState => ({
                        ...prevState,
                        formError: {
                            ...prevState,
                            contractorJoinMessage: "Добавьте текст сообщения от 5 до 255 символов",
                        },
                    }));

                    return;
                }

                this.setState(prevState => ({
                    ...prevState,
                    formError: {
                        ...prevState,
                        contractorJoinMessage: null,
                    },
                }));
            }

            if (needDriverLicense && (driverLicenseTypes.length === 0 && !contractorHasCar)) {
                this.setState({
                    driverError: "Необходимо выставить хотя бы один флаг",
                });

                return;
            }

            if (needDriverLicense && (driverLicenseTypes.length !== 0 || contractorHasCar)) {
                this.setState({
                    driverError: null,
                });
            }
        }

        const nextStep = getNextStep({
            activeItems,
            tabs: this.tabs,
        });

        this.setState(prevState => ({
            ...prevState,
            activeItems: [...prevState.activeItems, nextStep],
        }));
    };

    goBack = () => {
        const {activeItems} = this.state;
        const _activeItems = [...activeItems];
        _activeItems.pop();

        this.setState({
            activeItems: _activeItems,
        });
    };

    handleFocus = () => {
        const {isShowMainPrompt} = this.state;

        if (!isShowMainPrompt) {
            return;
        }

        this.setState({
            isShowMainPrompt: false,
        });
    };

    checkUnavailableTemplates = (params) => {
        const {
            onSuccessCheck,
            unavailableCheckingFlags,
            status,
            orderId,
        } = params;

        const {
            getDocumentsCustomTemplateReplacementsForUnavailableList,
            order,
        } = this.props;

        const reqDataForUnavailableTemplates = getOrderEditRequestDataForUnavailableTemplates({
            formData: this.state.formData,
            clientId: this.clientId,
            orderId,
            order,
        });

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            ...reqDataForUnavailableTemplates,
            onSuccess: (data) => {
                const flags = getUnavailableTemplatesFlags(data);

                const isErrors = unavailableCheckingFlags.some(flag => flags[flag]);

                if (isErrors) {
                    this.onOpenUnavailableTemplates(status);

                    return;
                }

                onSuccessCheck(status);
            },
        });
    };

    publishTemplate = ({status, checkingForDeleting}) => {
        const {formData} = this.state;

        const minAmountError = this.getMinAmountError();

        if (minAmountError) {
            toastError(minAmountError);

            return;
        }

        if (checkingForDeleting && formData.orderWorkReportTemplateId) {
            const {actCreationForm} = this.props;
            const requestData = getEditActTemplateRequestData({
                form: actCreationForm,
            });

            if (isTemplateOrderActEmpty(requestData)) {
                this.setState({
                    isOpenConfirmWindow: true,
                    creationStatus: status,
                    confirmWarning: false,
                    contentConfirm: ORDER_TEMPLATES_MESSAGE.ACT_TEMPLATE_DELETING,
                    onConfirm: () => {
                        this.publishTemplate({status: ORDER_STATUS.TEMPLATE, checkingForDeleting: false});
                    },
                });

                return;
            }
        }

        const {editOrderId, actCreationForm} = this.props;

        const isValid = this.validationForm({
            creationStatus: status,
            formData: {
                ...formData,
                actDescription: actCreationForm.description,
            },
        });

        if (!isValid) {
            return;
        }

        this.checkUnavailableTemplates({
            onSuccessCheck: this.handleCreate,
            status,
            orderId: editOrderId,
            // Отображаем для удаленных шаблонов или
            // При отключенной опции "Свои шаблоны"
            unavailableCheckingFlags: ["isTemplatesDeleted", "isDisabledTemplateClientSetting"],
        });
    };

    saveOrder = (status) => {
        const {formData} = this.state;
        const {editOrderId} = this.props;

        const isValid = this.validationForm({
            creationStatus: status,
            formData,
        });

        if (!isValid) {
            return;
        }

        this.checkUnavailableTemplates({
            onSuccessCheck: this.handleCreate,
            status,
            orderId: editOrderId,
            // Отображаем для удаленных шаблонов или
            // При отключенной опции "Свои шаблоны"
            unavailableCheckingFlags: ["isTemplatesDeleted", "isDisabledTemplateClientSetting"],
        });
    };

    getMinAmountError = () => {
        const {
            volumeOfWork,
            workUnitPrice,
        } = this.state.formData;

        if (
            this.isTemplate
            && this.state.isSecondType
            && (
                !volumeOfWork
                || !workUnitPrice
            )
        ) {
            return null;
        }

        const amount = getOrderEditRequestDataAmount({
            isSecondType: this.state.isSecondType,
            formData: this.state.formData,
            calculatorData: this.state.calculatorData,
        });
        const contractorsNeededCount = getOrderEditRequestDataContractorsNeededCount({
            isSecondType: this.state.isSecondType,
            formData: this.state.formData,
        });

        // значение может не заполняться в шаблоне, минимальное кол-во исполнителей на заказе === 1
        const count = contractorsNeededCount || 1;

        if ((amount / count) < MIN_PAYOUT_TO_CONTRACTOR) {
            return MIN_PAYOUT_TO_CONTRACTOR_ERROR_MESSAGE;
        }
    };

    saveToContractorSearchStatus = (creationStatus) => {
        const minAmountError = this.getMinAmountError();

        if (minAmountError) {
            toastError(minAmountError);

            return;
        }

        this.checkUnavailableTemplates({
            onSuccessCheck: this.checkOrderForWarningBeforePublication,
            status: creationStatus,
            // Отображаем для удаленных шаблонов или
            // При отключенной опции "Свои шаблоны"
            // При изменении дефолтного шаблона РД у клиента,
            unavailableCheckingFlags: [
                "isTemplatesDeleted",
                "isDisabledTemplateClientSetting",
                "isChangedDefaultTemplateRD",
            ],
        });
    };

    checkOrderForWarningBeforePublication = (creationStatus) => {
        const {getWarningBeforePublishOrder} = this.props;

        const reqData = getOrderEditRequestData({
            order: this.props.order,
            isDuplicateOrder: this.isDuplicateOrder,
            formData: this.state.formData,
            isSecondType: this.state.isSecondType,
            calculatorData: this.state.calculatorData,
            creationStatus: creationStatus ? creationStatus : this.state.creationStatus,
            offeredSpecialityName: this.state.offeredSpecialityName,
            editOrderId: this.props.editOrderId,
            clientId: this.props.clientId,
            orderGroupId: this.props.orderGroupId,
            clientPropertiesCard: this.props.clientPropertiesCard,
        });

        getWarningBeforePublishOrder({
            ...reqData,
            onSuccess: (result) => {
                const {
                    hasWarning,
                    warningCode,
                    warningMessage,
                } = result;

                if (!hasWarning) {
                    this.setState({
                        isOpenUnavailableTemplates: false,
                        isOpenConfirmWindow: true,
                        onConfirm: this.handleCreate,
                        confirmWarning: true,
                        contentConfirm: ORDER.ORDER_CONFIRM_PUBLISHED_DIRECT_TEXT,
                        creationStatus,
                    });

                    return;
                }

                this.setState({
                    cancelBtnContent: "Отмена",
                    errorCode: warningCode,
                    errorMessage: warningMessage,
                    isOpenUnavailableTemplates: false,
                    isShowWarningDialog: true,
                });
            },
            onError: () => {
                this.setState({
                    isOpenUnavailableTemplates: false,
                    isOpenConfirmWindow: true,
                    confirmWarning: true,
                    contentConfirm: ORDER.ORDER_CONFIRM_PUBLISHED_DIRECT_TEXT,
                    onConfirm: this.handleCreate,
                    creationStatus,
                });
            },
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            isOpenConfirmWindow: false,
            contentConfirm: "",
            onConfirm: null,
            confirmWarning: false,
            creationStatus: "",
            submitBtnContent: "",
            isHiddenCancelOnMobile: false,
            isHiddenCancel: false,
            mobile: null,
        });
    };

    get responsibleValue() {
        const {currentMemberId} = this.props;

        return [CLIENT_ADMIN, CLIENT_ACCOUNTANT, FOREMAN, PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) ? currentMemberId : "";
    }

    getManagerName = () => {
        const {
            formData: {orderManagerId},
            managerName,
        } = this.state;

        if (this.role === FOREMAN) {
            const {currentMember: {firstName}} = this.props;

            return firstName;
        }

        if (managerName) {
            return managerName;
        }

        const selectedManager = this.responsibleList.find(item => item.clientUserId === orderManagerId) || {};
        const {firstName} = selectedManager;

        return firstName;
    };

    get translate() {
        const {t} = this.props;

        return {
            buttonConfirm: t("button.confirm"),
            titleEdit: t("order-new.title-edit"),
            titleNew: t("order-new.title-new"),
            responsibleLabel: t("order-new.responsible"),
            responsiblePlaceholder: t("order-new.responsible"),
            buttonCancel: t("button.cancel"),
            orderSuggestionTitle: t("order-new.orderSuggestionTitle"),
            orderSuggestionContent: t("order-new.orderSuggestionContent"),
        };
    }

    setFile = ({documentFile, documentFileUrl}) => {
        this.setState({
            documentFile,
            documentFileUrl,
        });
    };

    objectInfo(objectId) {
        const {formData} = this.state;
        const {objectList} = this.props;

        const _objectId = arguments.length === 0 ? formData.objectId : objectId;

        return objectList.find((object) => (object.objectId === _objectId)) || {};
    };

    onChangePrompt = (isPromptOn) => {
        this.setState({
            isShowMainPrompt: isPromptOn,
            isPromptOn,
        });
    };

    onErrorCreateOrderAction = () => {
        const {
            errorCode,
            newTemplateGuid,
        } = this.state;

        if (errorCode === ORDER_ERROR_CODE.TEMPLATE_IS_OUT_OF_DATE) {
            const {
                getOrderRichById,
                updateFieldKeyword,
            } = this.props;

            updateFieldKeyword({autocompleteKeywords: []});

            this.setState(prevState => {
                return {
                    isShowWarningDialog: false,
                    errorCode: "",
                    errorMessage: "",
                    activeItems: [ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE],
                    order: {},
                    formData: {
                        ...getOrderFormInitState({
                            isTemplate: this.isTemplate,
                        }),
                        projectId: prevState.formData.projectId,
                        orderManagerId: prevState.formData.orderManagerId,
                        objectId: prevState.formData.objectId,
                    },
                };
            });

            getOrderRichById({
                orderId: newTemplateGuid,
                clientId: this.clientId,
                nameField: "orderCreationData",
                callBack: this.getOrderByIdCallback,
            });
        }

        if (errorCode === ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED) {
            const {handleClose, onOpenCreatingType} = this.props;

            handleClose();
            this.setState({
                isShowWarningDialog: false,
                errorCode: "",
                errorMessage: "",
                newTemplateGuid: "",
            });
            onOpenCreatingType();
        }

        if (
            [
                ORDER_WARNING_CODES.AMOUNT_MORE_FREE_BALANCE,
                ORDER_WARNING_CODES.AMOUNT_MORE_LIMIT_BALANCE,
                ORDER_WARNING_CODES.AMOUNT_MORE_OBJECT_BALANCE,
                ORDER_WARNING_CODES.AMOUNT_MORE_OBJECT_BALANCE_AND_LIMIT_BALANCE,
                ORDER_WARNING_CODES.AMOUNT_MORE_OBJECT_BUDGET,
                ORDER_WARNING_CODES.AMOUNT_MORE_OBJECT_BUDGET_AND_FREE_BALANCE,
            ].includes(errorCode)
        ) {
            this.setState({
                isShowWarningDialog: false,
                cancelBtnContent: "",
                errorCode: "",
                errorMessage: "",
                isOpenConfirmWindow: true,
                confirmWarning: true,
                contentConfirm: ORDER.ORDER_CONFIRM_PUBLISHED_DIRECT_TEXT,
                onConfirm: this.handleCreate,
                creationStatus: ORDER_STATUS.CONTRACTOR_SEARCH,
            });
        }
    };

    onErrorCreateOrder = ({errorCode, errorMessage, guid}) => {
        if ([ORDER_ERROR_CODE.TEMPLATE_IS_OUT_OF_DATE, ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED].includes(errorCode)) {
            this.setState({
                errorCode,
                errorMessage,
                newTemplateGuid: guid,
                isShowWarningDialog: true,
            });
        }
    };

    onCancelWarningDialog = () => {
        this.setState({
            cancelBtnContent: "",
            isShowWarningDialog: false,
            errorCode: "",
            errorMessage: "",
        });
    };

    renderWarningDialog() {
        const {
            isShowWarningDialog,
            cancelBtnContent,
            errorMessage,
        } = this.state;

        return isShowWarningDialog &&
            <NmModal
                size="md"
                onClose={this.onCancelWarningDialog}
                footer={
                    <ApplyButtons
                        align="center"
                        submit={this.onErrorCreateOrderAction}
                        submitBtnContent="Ок"
                        onClose={this.onCancelWarningDialog}
                        cancelBtnContent={cancelBtnContent}
                    />
                }
                children={errorMessage}
            />;
    }

    renderConfirmWindow() {
        const {
            isOpenConfirmWindow,
            contentConfirm,
            onConfirm,
            confirmWarning,
            submitBtnContent,
            isHiddenCancelOnMobile,
            isHiddenCancel,
            mobile,
        } = this.state;
        const {
            buttonConfirm,
            buttonCancel,
        } = this.translate;

        return isOpenConfirmWindow &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={onConfirm}
                content={contentConfirm}
                confirmButton={submitBtnContent || buttonConfirm}
                cancelButton={!isHiddenCancel && buttonCancel}
                warning={confirmWarning}
                isHiddenCancelOnMobile={isHiddenCancelOnMobile}
                mobile={mobile}
            />;
    }

    renderActions() {
        const {
            activeItems,
            creatingMode,
        } = this.state;
        const {
            orderStatus,
            handleClose,
            currentMember: {
                ordersPublicationProhibited,
            },
        } = this.props;

        return (
            <OrderEditActions
                saveOrder={this.saveOrder}
                publishTemplate={this.publishTemplate}
                ordersPublicationProhibited={ordersPublicationProhibited}
                creatingMode={creatingMode}
                role={this.role}
                orderStatus={orderStatus}
                activeItems={activeItems}
                goNext={this.onClickGoNext}
                goBack={this.goBack}
                handleClose={handleClose}
                saveToContractorSearchStatus={this.saveToContractorSearchStatus}
                tabs={this.tabs}
            />
        );
    }

    renderMainSuggestion(editOrderId, activeItems) {
        const {
            isPromptOn,
            isShowMainPrompt,
        } = this.state;
        const {
            orderSuggestionTitle,
            orderSuggestionContent,
        } = this.translate;

        const currentStep = getCurrentStep({activeItems, tabs: this.tabs});

        return !editOrderId && currentStep === ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE &&
            <NmTooltip
                compound
                isPromptOn={isPromptOn && isShowMainPrompt}
                onClose={this.handleFocus}
                header={{title: orderSuggestionTitle, icon: true}}
                body={[{textBlock: orderSuggestionContent}]}
                position="right"
                width={240}
                className="order-edit__suggestion_main"
                title={orderSuggestionTitle}
            />;
    }

    renderHeader() {
        const {creatingMode} = this.state;
        const {editOrderId} = this.props;

        return (
            <OrderEditHeader
                isDuplicateOrder={this.isDuplicateOrder}
                creatingMode={creatingMode}
                editOrderId={editOrderId}
                className="order-edit__header"
            />
        );
    }

    setForm = (data) => {
        this.setState((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                ...data,
            },
        }));
    };

    renderUnavailableTemplates = () => {
        const {
            isOpenUnavailableTemplates,
            creationStatus,
            creatingMode,
        } = this.state;
        const {editOrderId, t} = this.props;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <OrderEditUnavailableTemplates
                isTemplate={this.isTemplate}
                header={getOrderEditHeader({
                    creatingMode,
                    editOrderId,
                    isDuplicateOrder: this.isDuplicateOrder,
                    t,
                })}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={() => {
                    if ([ORDER_STATUS.TEMPLATE, ORDER_STATUS.DRAFT].includes(creationStatus)) {
                        this.handleCreate(creationStatus);

                        return;
                    }

                    this.checkOrderForWarningBeforePublication(creationStatus);
                }}
            />
        );
    };

    render() {
        const {
            activeItems,
            formData,
            formError,
            isPromptOn,
            valueObjectFilter,
            isSecondType,
            calculatorData,
            documentFile,
            documentFileUrl,
            driverError,
            offeredSpecialityName,
            specialityExistsError,
            isDisabledOfferedSpeciality,
            isDisabledSpeciality,
            scale,
            addressId,
            objectLatitude,
            objectLongitude,
            metroInfo,
            creatingMode,
        } = this.state;
        const {
            handleClose,
            editOrderId,
            nmContractDateConclusion,
            classNameSteps = "",
            progressClientProperties,
            actCreationForm,
            onChangeActCreation,
            progressCard,
        } = this.props;

        const managerFirstName = this.getManagerName();

        return (
            <NmModal
                className="order-edit"
                onClose={handleClose}
                classNameContent="order-edit__popup-content"
                lsKeyEditPrompt="promptActivateOrderEdit"
                onChangePrompt={this.onChangePrompt}
                loading={progressCard || progressClientProperties}
                dimmerClassName="order-edit__dimmer"
                loaderInverted
                goBack={this.goBack}
                isShowBackButton={activeItems.length > 1}
            >
                {this.renderUnavailableTemplates()}
                {this.renderConfirmWindow()}
                {this.renderMainSuggestion(editOrderId, activeItems)}
                {this.renderWarningDialog()}
                <OrderEditContextProvider
                    editOrderId={editOrderId}
                    clientId={this.clientId}
                    formData={formData}
                    setForm={this.setForm}
                    tabs={this.tabs}
                    actCreationForm={actCreationForm}
                    onChangeActCreation={onChangeActCreation}
                    isForemanRequestForAddition={this.isForemanRequestForAddition}
                    isDuplicateOrder={this.isDuplicateOrder}
                    isTemplate={this.isTemplate}
                    creatingMode={creatingMode}
                >
                    {this.renderHeader()}
                    <OrderEditTariffScaleChecker
                        formData={formData}
                        addressId={addressId}
                        setTariffScale={this.setTariffScale}
                        isSecondType={isSecondType}
                    />
                    <Steps
                        withAdditionalParams
                        items={this.tabs}
                        activeItems={activeItems}
                        className={`order-edit__steps ${classNameSteps}`}
                    />
                    <OrderEditForm
                        isDisabledSpeciality={isDisabledSpeciality}
                        role={this.role}
                        clientId={this.clientId}
                        scale={scale}
                        formData={formData}
                        formError={formError}
                        activeItems={activeItems}
                        isPromptOn={isPromptOn}
                        valueObjectFilter={valueObjectFilter}
                        isDuplicateOrder={this.isDuplicateOrder}
                        isDisabled={this.isDisabled}
                        handleChange={this.handleChange}
                        handleFocus={this.handleFocus}
                        nmContractDateConclusion={nmContractDateConclusion}
                        onSearchObject={this.onSearchObject}
                        managerFirstName={managerFirstName}
                        actions={this.renderActions()}
                        editOrderId={editOrderId}
                        dropDocument={this.setFile}
                        isSecondType={isSecondType}
                        calculatorData={calculatorData}
                        documentFile={documentFile}
                        documentFileUrl={documentFileUrl}
                        driverError={driverError}
                        addSpecialityToForm={this.addSpecialityToForm}
                        offeredSpecialityName={offeredSpecialityName}
                        specialityExistsError={specialityExistsError}
                        isDisabledOfferedSpeciality={isDisabledOfferedSpeciality}
                        saveSpecialityByEnter={this.saveSpecialityByEnter}
                        creatingMode={creatingMode}
                        isForemans={this.isForemans}
                        preloadedData={this.props.formData}
                        setTariffScale={this.setTariffScale}
                        metroInfo={metroInfo}
                        objectLatitude={objectLatitude}
                        objectLongitude={objectLongitude}
                        responsibleOptions={this.responsibleOptions}
                    />
                </OrderEditContextProvider>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        object: objectsCardSelector(state),
        order: orderCreationDataSelector(state),
        managersObjectSelected: managersObjectSelectedSelector(state),
        managersNoObjectSelected: orderAdminMembersSelector(state),
        objectManagerWithoutObjectOption: currentMemberOptionTypeSelector(state),
        managerOptionsWithObject: orderManagerOptionsWithObjectSelector(state),
        managersOptions: orderManagerOptionsNoObjectSelector(state),
        currentMemberId: clientCurrentMemberIdSelector(state),
        industryList: industryListSelector(state),
        clientPropertiesCard: clientCardPropertiesSelector(state),
        professionsOptions: orderIndustryProfessionsOptionsWithoutPostfixSelector(state),
        nmContractDateConclusion: getCurrentNmContractDateConclusionSelector(state),
        clientInfo: clientCardInfoSelector(state),
        currentClient: clientCardCurrentInfoSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        objectList: clientObjectListSelector(state),
        progressCard: ordersRegistryCardProgressSelector(state),
        managerListByUserOptions: managerListByUserOptionsSelector(state),
        managerListByUser: managerListByUserSelector(state),
        progressGetUsers: clientMemberProgressGetUsersSelector(state),
        getUsersAllProgress: projectGetUsersAllProgressSelector(state),
        progressClientProperties: clientCardInfoProgressSelector(state),
        specialitiesClient: clientSpecialitiesSelector(state),
        allSpecialities: specialitiesAllListV2Selector(state),
        ordersNotByTemplateCreationAvailability: ordersNotByTemplateCreationAvailabilitySelector(state),
    }), {
        addOrder,
        updateOrder,
        getOrderRichById,
        updateFieldOrderStore,
        getClientMemberList,
        getClientObjectList,
        getClientCardProperties,
        editOrder,
        updateFieldKeyword,
        getObjectAndProjectMembers,
        updateFieldClientObjectStore,
        updateFieldClientMemberStore,
        getObjects,
        updateFieldsStoreProject,
        getOrderAttachmentsFile,
        getUserTeamByClientUserId,
        saveOrderAttachmentsFile,
        deleteOrderAttachmentsFile,
        getClientSpecialities,
        findByOrderAndClientIds,
        getOrderTemplateProjectsList,
        getWarningBeforePublishOrder,
        updateStoreOrders,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        checkStopWords,
        getOrdersNotByTemplateCreationAvailability,
    },
)(withTranslation()(OrderEdit));