import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS,
} from "./actions";

import {getBasePageState} from "../../../../utils/redux";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
    statusDict: {},
    logs: getBasePageState(),
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    pageData: payload,
                    progress: true,
                },
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    progress: false,
                },
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS:
            return {
                ...state,
                statusDict: payload,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST:
            return {
                ...state,
                error: null,
                progressAction: true,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};