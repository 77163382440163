import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {validationSchemeAdvertModal} from "../validation";

import {getStartDate} from "../../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {handleFormString, isNullOrWhitespace} from "../../../../utils/stringHelper";
import {getAdvertFormDataValuesFromForm, getInitialAdvertValuesFromCard} from "../utils/dto";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {EMPTY_OPTION_KEY} from "../../../../constants/dropdown";
import {isClientUser} from "../../../../constants/roles";
import {ADVERTISEMENT_STATUS} from "../../list/constants";

import {
    addAdvertisementDraft,
    addAdvertisementPublish,
    bffClearFieldsAdvertisementStore,
    getAdvertisementContractorsCount,
    updateAdvertisement,
} from "../../../../ducks/bff/advertisement/actionCreators";
import {advertisementCardSelector} from "../../../../ducks/bff/advertisement/selectors";
import {getClientSpecialities} from "../../../../ducks/bff/clients/info/actionCreators";
import {downloadDocument} from "../../../../ducks/documents";
import {getFile} from "../../../../ducks/fileStore";

export const SAVE_TYPE = {
    PUBLISH: "PUBLISH",
    DRAFT: "DRAFT",
    EDIT: "EDIT",
};

const defaultForm = {
    type: SAVE_TYPE.PUBLISH,
    managerId: "",
    name: "",
    description: "",
    dateFrom: null,
    dateTo: null,
    publicationDateFrom: null,
    publicationDateTo: null,
    specialityId: "",
    workAddressFiasId: "",
    citizenships: [],
    ageFrom: "",
    ageTo: "",
    file: {},
    createDate: "",
    status: "",
    advertId: "",
    gender: EMPTY_OPTION_KEY,
    workAddressFiasValue: "",
};

export function useAdvertisementEditModal(props) {
    const {
        clientId,
        onClose,
        fetchList,
        isClearCard,
        duplicate,
        isEdit,
    } = props;

    const role = ls(USER_ROLE);
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);

    const isAccessActionsMassiveAdvertisementClient = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsMassiveAdvertisement,
    ]);

    const [countContractors, setCountContractors] = useState(0);
    const [progressCountContractors, setProgressCountContractors] = useState(false);
    const [initialValues, setInitialValues] = useState({
        ...defaultForm,
        managerId: isClientUser(role) ? currentUserId : defaultForm.managerId,
    });
    const [openWarning, setOpenWarning] = useState(false);

    const card = useSelector(advertisementCardSelector);
    const progressAdd = useSelector(state => state.bff.advertisement.progressAdd);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientSpecialities({
            clientId,
            filterBySetting: true,
        }));

        return () => {
            if (isClearCard) {
                dispatch(bffClearFieldsAdvertisementStore({card: {baseModel: {}}}));
            }
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(card.baseModel)) {
            setInitialValues(getInitialAdvertValuesFromCard(defaultForm, card, duplicate));

            if (card.baseModel.fileName) {
                dispatch(getFile({
                    fileName: card.baseModel.fileName,
                    filePath: card.filePath,
                    setFile: ({file}) => {
                        setInitialValues(prevState => (
                            {
                                ...prevState,
                                fileValue: file,
                            }),
                        );
                    },
                }));
            }
        }
    }, [card]);

    const {
        values,
        setFieldValue,
        isValid,
        handleChange,
        handleBlur,
        setFieldTouched,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        onSubmit,
        initialValues,
        enableReinitialize: true,
        validationSchema: validationSchemeAdvertModal(),
    });

    useEffect(() => {
        if (values.workAddressFiasId) {
            handleAdvertisementContractorsCount();
        }
    }, [
        values.specialityId,
        values.workAddressFiasId,
        values.citizenships,
        values.ageFrom,
        values.ageTo,
        values.gender,
    ]);

    const onSuccess = () => {
        fetchList();
        onClose();
    };

    function onSubmit(values) {
        if (!isValid) {
            return;
        }

        const {
            type,
            status,
            publicationDateFrom,
        } = values;

        const _values = {
            ...values,
            status: (type === SAVE_TYPE.PUBLISH || (type === SAVE_TYPE.EDIT && +getStartDate(publicationDateFrom) === +getStartDate(new Date()))) && isEdit ? ADVERTISEMENT_STATUS.PUBLISHED.VALUE : status,
        };

        const formData = getAdvertFormDataValuesFromForm(_values, clientId);

        if (type === SAVE_TYPE.EDIT || ([SAVE_TYPE.DRAFT, SAVE_TYPE.PUBLISH].includes(type) && isEdit)) {
            dispatch(updateAdvertisement({
                formData,
                onSuccess,
            }));

            return;
        }

        if (type === SAVE_TYPE.PUBLISH) {
            handleAdvertisementContractorsCount({
                onSuccess: ({value: count}) => {
                    if (count === 0 && (new Date() < new Date(values.publicationDateTo))) {
                        setOpenWarning(true);

                        return;
                    }

                    dispatch(addAdvertisementPublish({
                        formData,
                        onSuccess,
                    }));
                },
            });

            return;
        }

        dispatch(addAdvertisementDraft({
            formData,
            onSuccess,
        }));
    }

    const publishAdvert = () => {
        const formData = getAdvertFormDataValuesFromForm(values, clientId);

        dispatch(addAdvertisementPublish({
            formData,
            onSuccess,
        }));
    };

    const handleAdvertisementContractorsCount = (params = {}) => {
        const {onSuccess} = params;

        setProgressCountContractors(true);

        dispatch(getAdvertisementContractorsCount({
            clientId,
            ageFrom: handleFormString(values.ageFrom),
            ageTo: handleFormString(values.ageTo),
            workAddressFiasId: isNullOrWhitespace(values.workAddressFiasId) ? undefined : values.workAddressFiasId,
            specialityId: isNullOrWhitespace(values.specialityId) ? undefined : values.specialityId,
            citizenships: isEmpty(values.citizenships) ? undefined : values.citizenships,
            gender: values.gender === EMPTY_OPTION_KEY ? undefined : values.gender,
            onSuccess,
            getResult: (result) => {
                const {errorMessage, value} = result;
                if (!errorMessage) {
                    setCountContractors(value);
                }

                setTimeout(() => setProgressCountContractors(false), 500);
            },
        }));
    };
    
    const handleDownload = ({fileName, filePath}) => {
        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink: filePath,
        }));    
    };

    return {
        openWarning,
        setOpenWarning,
        values,
        errors,
        handleChange,
        handleBlur,
        handleAdvertisementContractorsCount,
        handleSubmit,
        handleDownload,
        touched,
        countContractors,
        setFieldValue,
        progressCountContractors,
        setFieldTouched,
        publishAdvert,
        status: card.baseModel.status,
        progressAdd,
        isAccessActionsMassiveAdvertisementClient,
    };
}