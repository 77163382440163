import {DEPOSIT_TYPE} from "../constants/deposit";

export const getDepositType = (depositType) => {
    if (depositType === DEPOSIT_TYPE.OBJECT_INDIVIDUAL_DEPOSIT) {
        return DEPOSIT_TYPE.OBJECT_INDIVIDUAL_DEPOSIT;
    }

    if (depositType === DEPOSIT_TYPE.PROJECT_INDIVIDUAL_DEPOSIT) {
        return DEPOSIT_TYPE.PROJECT_INDIVIDUAL_DEPOSIT;
    }

    return DEPOSIT_TYPE.CLIENT_DEPOSIT;
};

export const getAvailableForPaymentsTitle = (depositType) => {
    if (depositType === DEPOSIT_TYPE.OBJECT_INDIVIDUAL_DEPOSIT) {
        return "Доступно для выплат по объекту";
    }

    if (depositType === DEPOSIT_TYPE.PROJECT_INDIVIDUAL_DEPOSIT) {
        return "Доступно для выплат по проекту";
    }

    return "Доступно для выплат";
};