import {
    CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST,
    CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
} from "./actions";

export function getClientDepositReplenishmentAndRefundsPage(payload) {
    return {
        type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
        payload,
    };
}

export function editClientDepositReplenishmentAndRefunds(payload) {
    return {
        type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
        payload,
    };
}

export function updateCommentClientDepositReplenishmentAndRefunds(payload) {
    return {
        type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
        payload,
    };
}

export function addClientGeneralDeposit(payload) {
    return {
        type: CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST,
        payload,
    };
}

export function returnClientGeneralDeposit(payload) {
    return {
        type: CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST,
        payload,
    };
}

export function clearFieldClientDepositReplenishmentAndRefunds() {
    return {
        type: CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS,
    };
}