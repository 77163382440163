import * as yup from "yup";

import {SAVE_TYPE} from "./hooks/useAdvertisementEditModal";

import {getDateWithoutTime} from "../../../utils/dateFormat";
import {validateAge} from "../../../utils/validate";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";

const MIN_NUMBER_OF_YEARS = 14;

export const validationSchemeAdvertModal = () => {
    return yup.object().shape({
        managerId: yup.string()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        name: yup.string()
            .min(5, "Минимальная длина строки 5 символов")
            .max(255, "Допустимое количество символов 255")
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        description: yup.string()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .max(5000, "Не более 5000 символов")
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        publicationDateFrom: yup.string()
            .nullable()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED)
                    .test("publicationDateFromCheck", "Дата начала публикации раньше чем сегодня", function(publicationDateFrom) {
                        if (!publicationDateFrom) {
                            return true;
                        }

                        return getDateWithoutTime(new Date(publicationDateFrom)) >= getDateWithoutTime(new Date());
                    }),
            }),
        publicationDateTo: yup.string()
            .nullable()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        specialityId: yup.string()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        workAddressFiasId: yup.string()
            .when("type", {
                is: (value) => [SAVE_TYPE.PUBLISH, SAVE_TYPE.EDIT].includes(value),
                then: yup.string()
                    .nullable()
                    .required(VALIDATIONS_MESSAGE.REQUIRED),
            }),
        ageFrom: yup.mixed()
            .test("age-check", "Минимальное значение 14", (value) => validateAge(value, MIN_NUMBER_OF_YEARS))
            .test("age", "\"От\" не может быть больше \"До\"", function(ageFrom) {
                if (!ageFrom || !this.parent.ageTo) {
                    return true;
                }

                return +ageFrom <= +this.parent.ageTo;
            }),
        ageTo: yup.string()
            .test("age-check", "Минимальное значение 14", (value) => validateAge(value, MIN_NUMBER_OF_YEARS)),
    });
};

export default validationSchemeAdvertModal;