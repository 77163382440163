import {
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS,
    BFF_FINANCES_PAYMENTS_CONFIRM_ERROR,
    BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS,
    BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_ERROR,
    BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_SUCCESS,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_ERROR,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_SUCCESS,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_SUCCESS,
    BFF_FINANCES_PAYMENTS_REJECT_ERROR,
    BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_PAYMENTS_REJECT_SUCCESS,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_ERROR,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_SUCCESS,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_ERROR,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_SUCCESS,
    CHECK_ORDER_WORK_ACTS_ERROR,
    CHECK_ORDER_WORK_ACTS_REQUEST,
    CHECK_ORDER_WORK_ACTS_SUCCESS,
    FINANCE_PAYMENT_STEPS_LOG_READ_ERROR,
    FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST,
    FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS,
    ORDER_WORK_REPORT_LOG_ERROR,
    ORDER_WORK_REPORT_LOG_REQUEST,
    ORDER_WORK_REPORT_LOG_SUCCESS,
    UPDATE_FINANCES_FINANCES_STORE,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    data: {},
    error: null,
    spentLimitPageData: {},
    spentLimitList: [],
    spentLimitTotalCount: 0,
    spentLimitProgress: false,
    spentLimitFinanceProgress: false,
    spentLimitFinance: {},
    paymentsTotalAmount: 0,
    paymentsTotalAmountProgress: false,
    registryOrdersAmount: {
        commission: 0,
        amount: 0,
    },
    registryOrdersAmountProgress: false,
    registryOrders: [],
    progressRegistryOrders: false,
    registryOrdersPageData: {
        pageNum: 1,
        pageSize: 25,
    },
    registryOrdersTotalCount: 0,
    paidApiPageData: {},
    paidApiProgress: false,
    paidApiList: [],
    paidApiTotalCount: 0,
    paidApiTotalAmount: 0,
    settingsInfo: {},
    transactionLogTotalCount: 0,
    transactionLogPageData: {},
    transactionLog: [],
    transactionLogProgress: false,
    orderWorkReportLogs: [],
    orderWorkReportLogsProgress: false,
    totalCountLogs: 0,
    pageDataReportLog: {},
    blockedContractors: {
        isBlockedContractorsLoaded: false,
        contractors: [],
    },
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS: {
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                list: results,
                totalCount: totalCount,
                progress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST:
        case BFF_FINANCES_PAYMENTS_REJECT_REQUEST:
        case BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST:
        case CHECK_ORDER_WORK_ACTS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS:
        case BFF_FINANCES_PAYMENTS_REJECT_SUCCESS:
        case BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS:
        case CHECK_ORDER_WORK_ACTS_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_FINANCES_PAYMENTS_CONFIRM_ERROR:
        case BFF_FINANCES_PAYMENTS_REJECT_ERROR:
        case BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR:
        case CHECK_ORDER_WORK_ACTS_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST: {
            return {
                ...state,
                spentLimitPageData: payload,
                spentLimitProgress: true,
            };
        }
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_SUCCESS: {
            return {
                ...state,
                spentLimitList: payload.orders,
                spentLimitTotalCount: payload.totalCount,
                spentLimitProgress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_ERROR: {
            return {
                ...state,
                spentLimitProgress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST: {
            return {
                ...state,
                spentLimitFinanceProgress: true,
            };
        }
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_SUCCESS: {
            return {
                ...state,
                spentLimitFinance: payload,
                spentLimitFinanceProgress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_ERROR: {
            return {
                ...state,
                spentLimitFinanceProgress: false,
            };
        }
        case BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST:
            return {
                ...state,
                paymentsTotalAmountProgress: true,
            };
        case BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_SUCCESS:
            return {
                ...state,
                paymentsTotalAmount: payload,
                paymentsTotalAmountProgress: false,
            };
        case BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_ERROR:
            return {
                ...state,
                paymentsTotalAmountProgress: false,
            };
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST:
            return {
                ...state,
                registryOrdersAmountProgress: true,
            };
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_SUCCESS:
            return {
                ...state,
                registryOrdersAmount: payload,
                registryOrdersAmountProgress: false,
            };
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_ERROR:
            return {
                ...state,
                registryOrdersAmountProgress: false,
            };
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST:
            return {
                ...state,
                progressRegistryOrders: true,
                registryOrdersPageData: payload,
            };
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_SUCCESS: {
            const {results, totalCount} = payload;

            return {
                ...state,
                registryOrders: results,
                progressRegistryOrders: false,
                registryOrdersTotalCount: totalCount,
            };
        }
        case BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_ERROR:
            return {
                ...state,
                progressRegistryOrders: false,
            };
        case FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST:
            return {
                ...state,
                transactionLogProgress: true,
                transactionLogPageData: payload,
            };
        case FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS:
            return {
                ...state,
                transactionLogProgress: false,
                transactionLog: payload.results || [],
                transactionLogTotalCount: payload.totalCount,
            };
        case FINANCE_PAYMENT_STEPS_LOG_READ_ERROR: {
            return {
                ...state,
                error: payload,
                transactionLogProgress: false,
            };
        }
        case ORDER_WORK_REPORT_LOG_REQUEST:
            return {
                ...state,
                pageDataReportLog: payload,
                orderWorkReportLogsProgress: true,
            };
        case ORDER_WORK_REPORT_LOG_SUCCESS:
            const {
                results: orderWorkReportLogs,
                totalCount: totalCountLogs,
            } = payload;

            return {
                ...state,
                orderWorkReportLogs,
                totalCountLogs,
                orderWorkReportLogsProgress: false,
            };
        case ORDER_WORK_REPORT_LOG_ERROR:
            return {
                ...state,
                error: payload,
                orderWorkReportLogsProgress: false,
            };
        case BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_SUCCESS:
            return {
                ...state,
                blockedContractors: {
                    contractors: payload,
                    isBlockedContractorsLoaded: true,
                },
            };
        case UPDATE_FINANCES_FINANCES_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};