import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import Tabs from "../../../../components/ActualComponents/Tabs";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import RecruitmentLinkingResponseToCandidate from "../../../../components/Recruitment/LinkingResponseToCandidate";
import {
    bffRecruitmentVacancyCardSelector,
} from "../../../../ducks/bff/recruitment/vacancy/selectors";
import CloseVacancyJobBoards from "../components/close-job-boards";
import RecruitmentColdSearchModal from "../components/cold-search-modal";
import RecruitmentVacancyEditForm from "../components/edit-form";
import {RecruitmentProvideVacancyAccess} from "../components/provide-vacancy-access";
import VacancyLogs from "../components/vacancy-logs";
import RecruitmentVacancyCardRoute from "./route";

import {useRecruitmentFunnelFilters} from "./description/hooks/useFunnelFilters";
import useRecruitmentVacancyCardAction from "./hooks/useAction";
import {useRecruitmentVacanciesCardFetch} from "./hooks/useFetch";
import useRecruitmentCardFilter from "./hooks/useFilter";

import bem from "../../../../utils/bem";
import {getVacancyTitle} from "../utils/getTitle";

import {
    LINK_CLIENT_RECRUITMENT_VACANCIES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_JOB_BOARDS,
} from "../../../../constants/links";
import {RECRUITMENT_VACANCY_STATUS} from "../constants";

import "./styles.sass";

const RecruitmentVacancyCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                vacancyId,
            },
        },
    } = props;

    const {
        fetchCard,
    } = useRecruitmentVacanciesCardFetch({
        vacancyId,
    });

    const card = useSelector(bffRecruitmentVacancyCardSelector);

    const [block] = bem("recruitment-vacancy-card");

    const jobBoardsLink = LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_JOB_BOARDS
        .replace(":vacancyId", vacancyId)
        .replace(":clientId", clientId);

    const {
        vacancyNumber = "",
        title = "",
        status,
        workAddressInfo,
    } = card;

    const {
        confirmData,
        onCloseConfirm,
        isOpenLog,
        onCloseLog,
        getMediaControls,
        isOpenEdit,
        editData,
        onCloseEdit,
        isOpenAddCandidate,
        onCloseAddCandidate,
        isOpenColdSearchModal,
        onCloseColdSearchModal,
        vacancyCloseActions,
        modals,
    } = useRecruitmentVacancyCardAction({
        isVisibleAddCandidateButton: true,
        clientId,
        fetchCallback: fetchCard,
    });

    const {
        onClear,
        onSearch,
        filter,
        setFilter,
        filters,
    } = useRecruitmentCardFilter({
        vacancyId,
        clientId,
    });

    const {
        fetchFunnelFilters,
    } = useRecruitmentFunnelFilters({
        vacancyId,
        clientId,
    });

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Подтвердить"
                cancelButton="Отмена"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    const renderEditForm = () => {
        return (
            isOpenEdit &&
            <RecruitmentVacancyEditForm
                clientId={clientId}
                onClose={onCloseEdit}
                editData={editData}
                fetchData={fetchCard}
            />
        );
    };

    const renderLogModal = () => {
        return (
            isOpenLog &&
            <VacancyLogs
                vacancy={card}
                onClose={onCloseLog}
            />
        );
    };

    const renderAddCandidate = () => {
        if (!isOpenAddCandidate) {
            return null;
        }

        return (
            <RecruitmentLinkingResponseToCandidate
                isAddCandidate={true}
                clientId={clientId}
                vacancyId={vacancyId}
                title="Добавление кандидата в вакансию"
                onClose={onCloseAddCandidate}
                onSuccess={fetchFunnelFilters}
            />
        );
    };

    const renderRecruitmentColdSearchModal = () => {
        return (
            isOpenColdSearchModal &&
            <RecruitmentColdSearchModal
                clientId={clientId}
                vacancyId={vacancyId}
                onClose={onCloseColdSearchModal}
                onSuccess={fetchFunnelFilters}
            />
        );
    };

    const renderVacancyClose = () => {
        if (vacancyCloseActions.isOpenJobBoards) {
            return (
                <CloseVacancyJobBoards
                    onClose={vacancyCloseActions.onCloseModal}
                    onSubmit={(jobBoardTypes) => {
                        vacancyCloseActions.closeVacancy({
                            vacancy: vacancyCloseActions.modalData.vacancy,
                            jobBoardTypes,
                        });
                    }}
                />
            );
        }
    };

    const renderVacancyAccess = () => {
        if (!modals.modalData?.isOpenAccessProvide) {
            return null;
        }

        return (
            <RecruitmentProvideVacancyAccess
                clientId={clientId}
                vacancyId={modals.modalData.vacancyId}
                onClose={modals.onCloseModal}
            />
        );
    };

    return (
        <NmPage
            noPadding
            className={block()}
            header={
                <NmPageHeader
                    text={getVacancyTitle({
                        isVisibleVacancyText: true,
                        vacancyNumber,
                        title,
                        workAddressInfo,
                    })}
                    badge={
                        <NmBadge
                            text={RECRUITMENT_VACANCY_STATUS[status]?.TEXT || status}
                            mod={RECRUITMENT_VACANCY_STATUS[status]?.MOD || "gray"}
                        />
                    }
                    buttonBackSize="lg"
                    backLink={
                        LINK_CLIENT_RECRUITMENT_VACANCIES
                            .replace(":vacancyId", vacancyId)
                            .replace(":clientId", clientId)
                    }
                />
            }
            mediaControls={getMediaControls(card)}
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filterState={filter}
                    updateFilter={setFilter}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                    filters={filters}
                />
            }
        >
            {renderVacancyAccess()}
            {renderVacancyClose()}
            {renderAddCandidate()}
            {renderLogModal()}
            {renderConfirmWindow()}
            {renderEditForm()}
            {renderRecruitmentColdSearchModal()}
            <Tabs
                panes={[
                    {
                        active: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION
                            .replace(":vacancyId", vacancyId)
                            .replace(":clientId", clientId),
                        link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES
                            .replace(":vacancyId", vacancyId)
                            .replace(":clientId", clientId),
                        name: "Описание",
                    },
                    {
                        active: jobBoardsLink,
                        link: jobBoardsLink,
                        name: "Джоб-борды",
                    },
                ]}
                className="mb-3 mb-md-4"
            />
            <RecruitmentVacancyCardRoute
                {...props}
            />
        </NmPage>
    );
};

export default RecruitmentVacancyCard;