import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {searchRecruitmentDirectoryMessageTemplates} from "./actionCreators";
import {
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
    BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MANAGERS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MANAGERS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MANAGERS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS,
} from "./actions";
import {bffRecruitmentDirectorySelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const getController = () => {
    return getBffUrl({
        isClientCard: true,
        clientRolesUrl: "/client-adm/recruitment/directory",
        adminRolesUrl: "/adm/clients/client-card/recruitment/directory",
    });
};

// POST /bff/adm/clients/client-card/recruitment/directory/status/search
// POST /bff/client-adm/recruitment/directory/status/search
// Получение списка статусов
const getPageRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/status/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/status
// POST /bff/client-adm/recruitment/directory/status
// Добавление статуса
const addRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/status`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/directory/status/{id}
// PUT /bff/client-adm/recruitment/directory/status/{id}
// Редактирование статуса
const updateRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/status/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/directory/status/{id}
// DELETE /bff/client-adm/recruitment/directory/status/{id}
// Удаление статуса
const deleteRecruitmentDirectoryStatus = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${getController()}/status/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/vacancyRequirement/search
// POST /bff/client-adm/recruitment/directory/vacancyRequirement/search
// Получение списка дополнительных требований
const getPageRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/vacancyRequirement/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/vacancyRequirement
// POST /bff/client-adm/recruitment/directory/vacancyRequirement
// Добавление дополнительного требования
const addRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/vacancyRequirement`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/directory/vacancyRequirement/{id}
// PUT /bff/client-adm/recruitment/directory/vacancyRequirement/{id}
// Редактирование дополнительного требования
const updateRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/vacancyRequirement/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/directory/vacancyRequirement/{id}
// DELETE /bff/client-adm/recruitment/directory/vacancyRequirement/{id}
// Удаление дополнительного требования
const deleteRecruitmentDirectoryJobRequirement = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${getController()}/vacancyRequirement/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/funnel/search
// POST /bff/client-adm/recruitment/directory/funnel/search
// Получение списка воронок
const getPageRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/funnel/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/funnel
// POST /bff/client-adm/recruitment/directory/funnel
// Добавление воронки
const addRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/funnel`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/directory/funnel/{id}
// PUT /bff/client-adm/recruitment/directory/funnel/{id}
// Редактирование воронки
const updateRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/funnel/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/directory/funnel/{id}
// DELETE /bff/client-adm/recruitment/directory/funnel/{id}
// Удаление воронки
const deleteRecruitmentDirectoryFunnel = function* ({payload}) {
    try {
        const {
            id,
            onSuccess = () => {},
        } = payload;

        const result = yield request.bff.delete(`${getController()}/funnel/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/totalCounts
// POST /bff/client-adm/recruitment/directory/totalCounts
const getCountsRecruitmentDirectory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/totalCounts`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/manager-phone-numbers
// GET /bff/client-adm/recruitment/directory/manager-phone-numbers
// Получить список телефонов менеджера аккаунта Авито
export const getAvitoManagerPhoneNumbersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/manager-phone-numbers`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/professions
// GET /bff/client-adm/recruitment/directory/avito/professions
// Получить список профессий Авито
const getAvitoProfessionsSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/avito/professions`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/schedules
// GET /bff/client-adm/recruitment/directory/avito/schedules
// Получить справочник График работы Авито
const getAvitoSchedulesSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/avito/schedules`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/experiences
// GET /bff/client-adm/recruitment/directory/avito/experiences
// Получить справочник Опыт работы Авито
const getAvitoExperiencesSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/avito/experiences`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/billingTypes
// GET /bff/client-adm/recruitment/directory/avito/billingTypes
// Получить справочник Тип размещения Авито
const getAvitoBillingTypesSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/avito/billingTypes`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/business-areas
// GET /bff/client-adm/recruitment/directory/avito/business-areas
// Получить весь справочник Виды деятельности Авито, включая устаревшие
const getAvitoBusinessAreasSaga = function* (payload) {
    const {
        isActual,
    } = payload;

    try {
        const result = yield request.bff.get(`${getController()}/avito/business-areas`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/avito/new-business-areas
// GET /bff/client-adm/recruitment/directory/avito/new-business-areas
// Получить новый справочник Виды деятельности Авито
const getAvitoActualBusinessAreas = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/avito/new-business-areas`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/rejection-reason/search
// POST /bff/client-adm/recruitment/directory/rejection-reason/search
// Получить список причин отказа
const searchRecruitmentRejectionReason = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/rejection-reason/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/rejection-reason
// POST /bff/client-adm/recruitment/directory/rejection-reason
// Добавить причину отказа
const addRecruitmentRejectionReason = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/rejection-reason`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/directory/rejection-reason/{id}
// PUT /bff/client-adm/recruitment/directory/rejection-reason/{id}
// Редактировать причину отказа
const editRecruitmentRejectionReason = function* ({payload}) {
    const {
        id,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.put(`${getController()}/rejection-reason/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/directory/rejection-reason/{id}
// DELETE /bff/client-adm/recruitment/directory/rejection-reason/{id}
// Удалить причину отказа
const deleteRecruitmentRejectionReason = function* ({payload}) {
    const {
        id,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.delete(`${getController()}/rejection-reason/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/vacancy-branded-templates
// GET /bff/client-adm/recruitment/directory/vacancy-branded-templates
// Получить список брендированных шаблонов с HH
export const getHHVacancyBrandedTemplatesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/vacancy-branded-templates`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/recruitment/directory/message-template/{id}
// PUT /bff/client-adm/recruitment/directory/message-template/{id}
// Редактировать шаблон сообщения
export const updateRecruitmentDirectoryMessageTemplate = function* ({payload}) {
    const {
        id,
    } = payload;

    try {
        const result = yield request.bff.put(`${getController()}/message-template/${id}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон успешно обновлен");

        const state = yield select();
        const data = bffRecruitmentDirectorySelector(state);

        yield put(searchRecruitmentDirectoryMessageTemplates(data.messageTemplatesPageData));

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_ERROR,
            payload: error,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/directory/message-template/{id}
// DELETE /bff/client-adm/recruitment/directory/message-template/{id}
// Удалить шаблон сообщения
export const deleteRecruitmentDirectoryMessageTemplate = function* ({payload}) {
    const {
        id,
        handleResponse,
    } = payload;

    try {
        const result = yield request.bff.delete(`${getController()}/message-template/${id}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон успешно удален");

        handleResponse();

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/message-template
// POST /bff/client-adm/recruitment/directory/message-template
// Добавить шаблон сообщения
export const addRecruitmentDirectoryMessageTemplate = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/message-template`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Шаблон успешно добавлен");

        const state = yield select();
        const data = bffRecruitmentDirectorySelector(state);

        yield put(searchRecruitmentDirectoryMessageTemplates(data.messageTemplatesPageData));

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/directory/message-template/search
// POST /bff/client-adm/recruitment/directory/message-template/search
// Получить список шаблонов сообщений
export const searchRecruitmentDirectoryMessageTemplatesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/message-template/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/managers
// GET /bff/client-adm/recruitment/directory/managers
// Получить список менеджеров работодателя HH
export const getRecruitmentManagersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/managers`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MANAGERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MANAGERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MANAGERS_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/directory/message-template/parameters
// GET /bff/client-adm/recruitment/directory/message-template/parameters
// Получить словарь параметров для шаблонов сообщений
export const getRecruitmentMessageTemplatesParams = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/message-template/parameters`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST, getPageRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST, addRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST, updateRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST, deleteRecruitmentDirectoryStatus),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST, getPageRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST, addRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST, updateRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST, deleteRecruitmentDirectoryJobRequirement),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST, getPageRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST, addRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST, updateRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST, deleteRecruitmentDirectoryFunnel),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST, getCountsRecruitmentDirectory),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST, getAvitoSchedulesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST, getAvitoExperiencesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST, getAvitoBillingTypesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST, getAvitoBusinessAreasSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST, getAvitoActualBusinessAreas),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST, getAvitoProfessionsSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST, searchRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST, addRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST, editRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST, deleteRecruitmentRejectionReason),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_HH_VACANCY_BRANDED_TEMPLATES_REQUEST, getHHVacancyBrandedTemplatesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_UPDATE_REQUEST, updateRecruitmentDirectoryMessageTemplate),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_DELETE_REQUEST, deleteRecruitmentDirectoryMessageTemplate),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_ADD_REQUEST, addRecruitmentDirectoryMessageTemplate),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_SEARCH_REQUEST, searchRecruitmentDirectoryMessageTemplatesSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MANAGERS_REQUEST, getRecruitmentManagersSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_AVITO_MANAGER_PHONE_NUMBERS_REQUEST, getAvitoManagerPhoneNumbersSaga),
        takeEvery(BFF_RECRUITMENT_DIRECTORY_MESSAGE_TEMPLATE_PARAMETERS_REQUEST, getRecruitmentMessageTemplatesParams),
    ]);
}