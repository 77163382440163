import {useRef, useState} from "react";
import {useSelector} from "react-redux";

import {useAppContextFilter} from "./useFilter";

export const useAppContextPageData = () => {
    const containerRef = useRef(null);

    const pathname = useSelector((state) => state.router.location.pathname);

    const [scrollPosition, setScrollPosition] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, _setFilterData] = useState({
        state: {},
        isOpenFilter: false,
        filters: null,
    });

    const filter = useAppContextFilter({});

    return {
        containerRef,
        scrollPosition,
        setScrollPosition,
        pathname,
        isSearch,
        setIsSearch,
        filterData,
        filter,
    };
};