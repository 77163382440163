import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import {
    getAvitoManagerPhoneNumbers,
    getHHVacancyBrandedTemplates,
    getRecruitmentManagers, updateRecruitmentDirectoryStore,
} from "../../../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    bffHHVacancyBrandedTemplatesDictSelector,
    bffRecruitmentDirectoryAvitoBillingTypesOptionsSelector,
    bffRecruitmentDirectoryAvitoBusinessAreasOptionsSelector,
    bffRecruitmentDirectoryAvitoExperiencesOptionsSelector,
    bffRecruitmentDirectoryAvitoManagerPhoneNumbersOptionsSelector,
    bffRecruitmentDirectoryAvitoProfessionsOptionsSelector,
    bffRecruitmentDirectoryAvitoSchedulesOptionsSelector,
    bffRecruitmentDirectoryManagersOptionsSelector,
} from "../../../../../../ducks/bff/recruitment/directory/selectors";
import {
    addAvitoVacancyLogo,
    createAtJobBoardRecruitmentVacancy,
    updateAtJobBoardRecruitmentVacancy,
} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {bffRecruitmentVacancyCardSelector} from "../../../../../../ducks/bff/recruitment/vacancy/selectors";

import {dictionaryToOptions} from "../../../../../../utils/objectHelper";
import {hhDictToOptions} from "../../../utils/externalJobBoards";
import {getCreateVacancyJobBoardRequestData} from "../utils/dto";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";
import {
    AVITO_BILLING_TYPES,
    AVITO_EXPERIENCE,
    AVITO_RESPONSE_PARAMS,
    AVITO_RESPONSE_PARAMS_OPTIONS,
    AVITO_SCHEDULE,
    RECRUITMENT_SOURCE_JOB_BOARD,
    SUPER_JOB_PUBLISHED_OPTIONS,
} from "../../../constants";

import {
    externalJobBoardsHhAreasOptionsSelector,
    externalJobBoardsHhBillingTypeDictSelector,
    externalJobBoardsHhExperienceDictSelector,
    externalJobBoardsHhScheduleDictSelector,
    externalJobBoardsHhVacancyTypeDictSelector,
    externalJobBoardsSuperJobExperienceDictSelector,
    externalJobBoardsSuperJobSpecialtiesOptionsSelector,
    externalJobBoardsSuperJobTownsOptionsSelector,
    externalJobBoardsSuperJobTypeOfWorkSelector,
    getHeadHunterAreas,
    getHeadHunterDictionaries,
    getSuperJobReferences,
    getSuperJobSpecialties,
    getSuperJobTowns,
} from "../../../../../../ducks/externalJobBoards";

const useCreateVacancyOnJobBoardForm = (props) => {
    const {
        clientId,
        vacancyId,
        jobBoardType,
        data,
        isUpdate,
        onClose,
        fetchInfo,
    } = props;

    const hhAreasOptions = useSelector(externalJobBoardsHhAreasOptionsSelector);
    const hhExperienceDict = useSelector(externalJobBoardsHhExperienceDictSelector);
    const hhScheduleDict = useSelector(externalJobBoardsHhScheduleDictSelector);
    const hhBillingTypeDict = useSelector(externalJobBoardsHhBillingTypeDictSelector);
    const hhVacancyTypeDict = useSelector(externalJobBoardsHhVacancyTypeDictSelector);
    const superJobSpecialtiesOptions = useSelector(externalJobBoardsSuperJobSpecialtiesOptionsSelector);
    const hhBrandTemplatesDict = useSelector(bffHHVacancyBrandedTemplatesDictSelector);
    const superJobTownsOptions = useSelector(externalJobBoardsSuperJobTownsOptionsSelector);
    const superJobExperienceDictOptions = useSelector(externalJobBoardsSuperJobExperienceDictSelector);
    const superJobTypeOfWorkOptions = useSelector(externalJobBoardsSuperJobTypeOfWorkSelector);
    const naimixVacancyCard = useSelector(bffRecruitmentVacancyCardSelector);
    const avitoManagerPhoneNumbersOptions = useSelector(bffRecruitmentDirectoryAvitoManagerPhoneNumbersOptionsSelector);
    const avitoProfessionsOptions = useSelector(bffRecruitmentDirectoryAvitoProfessionsOptionsSelector);
    const avitoSchedulesOptions = useSelector(bffRecruitmentDirectoryAvitoSchedulesOptionsSelector);
    const avitoExperiencesOptions = useSelector(bffRecruitmentDirectoryAvitoExperiencesOptionsSelector);
    const avitoBillingTypesOptions = useSelector(bffRecruitmentDirectoryAvitoBillingTypesOptionsSelector);
    const avitoBusinessAreasOptions = useSelector(bffRecruitmentDirectoryAvitoBusinessAreasOptionsSelector);
    const managersOptions = useSelector(bffRecruitmentDirectoryManagersOptionsSelector);

    const initialValues = useMemo(() => {
        const {
            data = {},
        } = props;

        switch (jobBoardType) {
            case RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER:
            case RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU: {
                const publishTypeField = jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER ?
                    {publishType: "open"} :
                    {publish: "open"};

                return {
                    jobBoardAccountId: "",
                    description: naimixVacancyCard.description || "",
                    billingType: "free",
                    ...publishTypeField,
                    brandTemplate: jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER ? {value: ""} : undefined,
                    city: null,
                    experience: "noExperience",
                    schedule: "fullDay",
                    ...data,
                    manager: data.manager
                        ? {
                            ...data.manager,
                            value: data.manager.id,
                            key: data.manager.id,
                            text: data.manager.fullName,
                        }
                        : {},
                };
            }
            case RECRUITMENT_SOURCE_JOB_BOARD.AVITO: {
                return {
                    jobBoardAccountId: "",
                    managerPhone: data.manager?.phone || "",
                    billingType: AVITO_BILLING_TYPES.PACKAGE,
                    description: naimixVacancyCard.description || "",
                    businessArea: "",
                    experience: AVITO_EXPERIENCE.NO_MATTER,
                    schedule: AVITO_SCHEDULE.FULL_DAY,
                    ...data,
                    profession: data.profession ? String(data.profession) : "",
                    responseParam: data.responseParam || AVITO_RESPONSE_PARAMS.ALL,
                    logo: [],
                };
            }
            case RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB: {
                return {
                    jobBoardAccountId: "",
                    profession: null,
                    published: true,
                    city: null,
                    experience: "1",
                    typeOfWork: "6",
                    ...data,
                };
            }
            default: {
                return {};
            }
        }
    }, [jobBoardType, data, naimixVacancyCard]);

    const dispatch = useDispatch();

    useEffect(() => {

        if ([RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER, RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU].includes(jobBoardType)) {
            dispatch(getHeadHunterAreas());
            dispatch(getHeadHunterDictionaries());
        }

        if (jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB) {
            dispatch(getSuperJobSpecialties());
            dispatch(getSuperJobTowns());
            dispatch(getSuperJobReferences());
        }

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                managers: [],
                avitoManagerPhoneNumbers: [],
            }));
        };
    }, []);

    const getTooltip = () => {
        return (
            <HelpTooltip
                hover={false}
                info={true}
                className="ms-1"
                children="После создания вакансии на джоб-борде нельзя отредактировать"
            />
        );
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        const jobBoardParams = getCreateVacancyJobBoardRequestData({
            values,
            jobBoardType,
        });

        const reqData = {
            clientId,
            vacancyId,
            jobBoardParams,
            onSuccess: () => {
                onClose();
                fetchInfo();
            },
        };

        const submit = () => {
            if (isUpdate) {
                dispatch(updateAtJobBoardRecruitmentVacancy({
                    ...reqData,
                }));

                return;
            }

            dispatch(createAtJobBoardRecruitmentVacancy({
                jobBoardAccountId: values.jobBoardAccountId,
                ...reqData,
            }));
        };

        if (!isEmpty(values.logo)) {
            const formData = new FormData();
            const [file] = values.logo;

            formData.append("file", file);
            formData.append("vacancyId", vacancyId);

            dispatch(addAvitoVacancyLogo({
                formData,
                handleResponse: submit,
            }));

            return;
        }

        submit();
    };

    //toDo: hhDictToOptions - вынести в селектор (все в компоненте)
    const formConfig = useMemo(() => {
        const hhVacancyPostingType = {
            required: true,
            name: "billingType",
            label: "Тип размещения вакансии",
            options: hhDictToOptions(hhBillingTypeDict),
        };
        const hhExperience = {
            name: "experience",
            label: "Опыт работы",
            options: hhDictToOptions(hhExperienceDict),
        };
        const hhSchedule = {
            name: "schedule",
            label: "График работы",
            options: hhDictToOptions(hhScheduleDict),
        };
        const hhBrandTemplate = {
            name: "brandTemplate",
            label: "Брендированный шаблон",
            options: hhDictToOptions(hhBrandTemplatesDict),
            isOptionInState: true,
        };
        const manager = {
            name: "manager",
            required: true,
            label: "Менеджер вакансии",
            options: managersOptions,
            isOptionInState: true,
            isValueInitialOption: true,
        };

        const jobBoardAccountIdValidation = !isUpdate && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED);

        const hhValidations = {
            jobBoardAccountId: jobBoardAccountIdValidation,
            billingType: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
            city: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
            description: yup.string()
                .nullable()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .min(200, "Не менее 200 символов")
                .max(5000, "Не более 5000 символов"),
        };

        switch (jobBoardType) {
            case RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER:
                return {
                    fields: [
                        hhVacancyPostingType,
                        manager,
                        {
                            required: true,
                            name: "publishType",
                            label: "Тип доступа вакансии",
                            options: hhDictToOptions(hhVacancyTypeDict),
                            disabled: isUpdate,
                            tooltip: getTooltip(),
                        },
                        {
                            required: true,
                            name: "city",
                            label: "Город",
                            options: hhAreasOptions,
                            disabled: isUpdate,
                            tooltip: getTooltip(),
                        },
                        hhExperience,
                        hhSchedule,
                        hhBrandTemplate,
                    ],
                    validation: () => {
                        return yup.object().shape({
                            ...hhValidations,
                            publishType: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            manager: yup.object().shape({
                                value: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            }),
                        });
                    },
                };
            case RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU: {
                return {
                    fields: [
                        hhVacancyPostingType,
                        {
                            required: true,
                            name: "type",
                            label: "Тип доступа вакансии",
                            options: hhDictToOptions(hhVacancyTypeDict),
                        },
                        {
                            required: true,
                            name: "city",
                            label: "Город",
                            options: hhAreasOptions,
                        },
                        hhExperience,
                        hhSchedule,
                    ],
                    validation: () => {
                        return yup.object().shape({
                            ...hhValidations,
                            type: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                        });
                    },
                };
            }
            case RECRUITMENT_SOURCE_JOB_BOARD.AVITO: {
                return {
                    fields: [
                        {
                            required: true,
                            name: "managerPhone",
                            label: "Телефон",
                            options: avitoManagerPhoneNumbersOptions,
                        },
                        {
                            required: true,
                            name: "billingType",
                            label: "Тип размещения вакансии",
                            options: avitoBillingTypesOptions,
                        },
                        {
                            required: true,
                            name: "profession",
                            label: "Профессия",
                            options: avitoProfessionsOptions,
                        },
                        {
                            required: true,
                            name: "businessArea",
                            label: "Сфера деятельности",
                            options: avitoBusinessAreasOptions,
                        },
                        {
                            required: true,
                            name: "experience",
                            label: "Опыт работы",
                            options: avitoExperiencesOptions,
                        },
                        {
                            required: true,
                            name: "schedule",
                            label: "График работы",
                            options: avitoSchedulesOptions,
                        },
                        {
                            required: true,
                            name: "responseParam",
                            label: "Отклики на вакансию",
                            options: AVITO_RESPONSE_PARAMS_OPTIONS,
                        },
                    ],
                    validation: () => {
                        return yup.object().shape({
                            jobBoardAccountId: jobBoardAccountIdValidation,
                            managerPhone: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            profession: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            billingType: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            businessArea: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            experience: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            schedule: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            responseParam: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            description: hhValidations.description,
                        });
                    },
                };
            }

            //toDo: dictionaryToOptions - вынести в селектор
            case RECRUITMENT_SOURCE_JOB_BOARD.SUPER_JOB: {
                return {
                    fields: [
                        {
                            required: true,
                            name: "profession",
                            label: "Специальность",
                            options: superJobSpecialtiesOptions,
                        },
                        {
                            required: true,
                            name: "published",
                            label: "Тип доступа вакансии",
                            options: SUPER_JOB_PUBLISHED_OPTIONS,
                        },
                        {
                            required: true,
                            name: "city",
                            label: "Город",
                            options: superJobTownsOptions,
                        },
                        {
                            name: "experience",
                            label: "Опыт работы",
                            options: dictionaryToOptions(superJobExperienceDictOptions),
                        },
                        {
                            name: "schedule",
                            label: "График работы",
                            options: dictionaryToOptions(superJobTypeOfWorkOptions),
                        },
                    ],
                    validation: () => {
                        return yup.object().shape({
                            jobBoardAccountId: jobBoardAccountIdValidation,
                            profession: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            published: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                            city: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                        });
                    },
                };
            }
            default: {
                return {};
            }
        }
    }, [
        jobBoardType,
        hhAreasOptions,
        hhBillingTypeDict,
        hhExperienceDict,
        hhScheduleDict,
        hhBrandTemplatesDict,
        hhVacancyTypeDict,
        superJobExperienceDictOptions,
        superJobSpecialtiesOptions,
        superJobTownsOptions,
        superJobTypeOfWorkOptions,
        managersOptions,
    ]);

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        handleChange,
    } = useFormik({
        onSubmit,
        initialValues,
        validationSchema: formConfig.validation(),
        enableReinitialize: true,
    });

    useEffect(() => {
        if (
            jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.AVITO
            && values.jobBoardAccountId
        ) {
            dispatch(getAvitoManagerPhoneNumbers({
                jobBoardAccountId: values.jobBoardAccountId,
            }));
        }

        setFieldValue("managerPhone", "");
    }, [values.jobBoardAccountId]);

    useEffect(() => {
        if (avitoManagerPhoneNumbersOptions.length === 1) {
            setFieldValue("managerPhone", avitoManagerPhoneNumbersOptions[0].value);
        }
    }, [avitoManagerPhoneNumbersOptions]);

    useEffect(() => {
        if (
            jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER
            && values.jobBoardAccountId
        ) {
            dispatch(getRecruitmentManagers({
                jobBoardAccountId: values.jobBoardAccountId,
            }));
            dispatch(getHHVacancyBrandedTemplates({
                jobBoardAccountId: values.jobBoardAccountId,
            }));
        }

        setFieldValue("brandTemplate", {value: ""});
    }, [values.jobBoardAccountId]);

    return {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        errors,
        isValid,
        formConfig,
        handleChange,
    };
};

export default useCreateVacancyOnJobBoardForm;