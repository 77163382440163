import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Media from "react-media";
import {connect} from "react-redux";
import ShowMoreText from "react-show-more-text";
import {debounce, isEmpty, isEqual, round} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInfoCard from "../../../components/ActualComponents/NmInfoCard";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../components/ApplyButtons";
import BankIndicator from "../../../components/BankIndicator";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmIcon from "../../../components/NmIcon";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import RejectionReason from "../../../components/RejectionReason";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as ReceiptIcon} from "../../../images/receipt_24.svg";
import {ReactComponent as ResetIcon} from "../../../images/reset_24.svg";
import FinanceLogPopup from "../../finance-log-popup";
import FinancePaymentListAmountValues from "./amount-values";
import FinancePaymentListClientMassActions from "./client-mass-actions";
import ClientFinancePaymentListTabs from "./client-tabs";
import FinancePaymentListDuplicateCheck from "./duplicate-check";
import FinanceClientPaymentListFilter from "./finance-client-payment-list-filter";
import FinancePaymentListFilter from "./finance-payment-list-filter";
import ReceiptDuplicateList from "./receipt-duplicate-list";
import FinancePaymentListWarningCheck from "./warning-check";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {getListUniqueOptions} from "../../../utils/listHelper";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {replacer} from "../../../utils/replacer";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {
    formatAmount,
    formatNumber,
} from "../../../utils/stringFormat";
import {
    handleFormString,
    handleNumber,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import {
    toastError,
    toastSuccess,
    toastWarning,
} from "../../../utils/toastHelper";
import {getBffCommonController} from "../../../utils/url";
import validate from "../../../utils/validate";
import {filterContextMenuByPropsVisibleRules} from "./utils/filterByPropsVisibleRules";
import {getFinanceListCommission} from "./utils/getCommission";
import {getPaymentError} from "./utils/getError";
import {getFinanceListNdflCommission} from "./utils/getNdflCommission";
import {getPaymentStatus} from "./utils/getPaymentStatus";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {BANK_TYPE} from "../../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../constants/contractor";
import {ORDER_SOURCE_TYPE, ORDER_WORK_REPORT_TYPE, PAYMENT_SOURCE_TYPE_DICT} from "../../../constants/finance";
import {FINANCE_LOG_TYPE} from "../../../constants/financeLogType";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {ORDER_WORK_REPORT} from "../../../constants/messages";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    GR,
    HR_MANAGER,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER, NM_OPERATOR,
    NM_PARTNER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../../constants/roles";
import {
    STATUS_OF_TRANSFER,
    STATUS_OF_TRANSFER_NEW,
    STATUS_OF_TRANSFER_TRANSCRIPTION,
    STATUS_PAYMENT,
} from "../../../constants/status";

import {getClientMemberList} from "../../../ducks/bff/clients/employees/actionCreators";
import {clientMemberOptionsSelector} from "../../../ducks/bff/clients/employees/selectors";
import {
    clientCardInfoSelector,
    clientCardPropertiesSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {
    getRunningPaymentsUpdateAllBankStatus,
    paymentsUpdateAllBankStatus,
} from "../../../ducks/bff/common/job/actionCreators";
import {
    commonJobProgressPaymentsUpdateBankStatusActionSelector,
    commonJobProgressPaymentsUpdateBankStatusSelector,
} from "../../../ducks/bff/common/job/selectors";
import {
    clientCurrentMemberSelector,
} from "../../../ducks/clientMember";
import {
    bankCheckCancelToken,
    contractorsPaymentPossibilityListSelector,
    getContractorsPaymentPossibility,
    updateFieldsContractorPaymentPossibilityState,
} from "../../../ducks/contractorBankCheck";
import {
    correctionReceiptInfoSelector,
    createContractorCorrectionReceipt,
    createContractorReceipt,
    createContractorReturnReceipt,
    getCorrectionReceiptInfo,
    getReceiptDuplicates,
    getReturnReceiptInfo,
    returnReceiptInfoSelector,
} from "../../../ducks/contractorReceiptController";
import {downloadDocument} from "../../../ducks/documents";
import {getFederalRegionOptionsSelector} from "../../../ducks/federalMigration";
import {
    createOfflineCheck,
    registrationOfflineCheck,
} from "../../../ducks/financeLog";
import {
    cancelReceiptFinancesPayments,
    checkOrderWorkActs,
    confirmFinancesPayments,
    getFinancesPaymentsBlockedContractors,
    getPageFinancesPayments,
    getReceiptFinancesPayments,
    rejectFinancesPayments,
    retryPayment,
} from "../../../ducks/financePayments/actionCreators";
import {
    bffFinancesPaymentsBlockedContractorsIdsSelector,
    bffFinancesPaymentsListSelector,
    bffFinancesPaymentsProgressActionSelector,
    bffFinancesPaymentsProgressSelector,
    bffFinancesPaymentsTotalCountSelector,
    bffFinancesPaymentsTotalPagesSelector,
} from "../../../ducks/financePayments/selectors";
import {
    getContractorListForFilter,
    getContractorListForFilterSelector,
    getOrderWorkReportOptimizedListTaskIdsSelector,
} from "../../../ducks/financeReport";
import {
    getAllTasks,
    jobTaskLoadSignalSelector,
    jobTasksPaymentsInfoSelector,
    updateJobFieldStore,
} from "../../../ducks/job";
import {
    getOrderWorkConfirmPaymentSuccessSelector,
    updateFieldOrderWorkReportStore,
} from "../../../ducks/orderWorkReport";

import PropTypes from "prop-types";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

export const OPERATION = {
    PAY: "PAY",
    REJECT: "REJECT",
    REJECT_ONCE: "REJECT_ONCE",
    CONFIRM: "CONFIRM",
    CHECK: "CHECK",
};

const ACTION_MOBILE_ADMIN_OPTIONS = [
    {
        key: OPERATION.PAY,
        text: "Оплатить",
        value: OPERATION.PAY,
    },
    {
        key: OPERATION.REJECT,
        text: "Отклонить",
        value: OPERATION.REJECT,
    },
];

const ACTION_MOBILE_CLIENT_OPTIONS = [
    {
        key: OPERATION.CONFIRM,
        text: "Подтвердить",
        value: OPERATION.CONFIRM,
    },
    {
        key: OPERATION.REJECT,
        text: "Отклонить",
        value: OPERATION.REJECT,
    },
];

const defaultDataFilter = {
    fromSumMoneyFilter: "",
    toSumMoneyFilter: "",
    clientId: "",
    paymentNumberFilter: "",
    registryNumberFilter: "",
    orderNumFilter: "",
    orderNameFilter: "",
    contractorFioFilter: "",
    fromClientCommissionAmountFilter: "",
    toClientCommissionAmountFilter: "",
    toPlatformAmountFilter: "",
    fromPlatformAmountFilter: "",
    projectIdsFilter: [],
    objectIdsFilter: [],
    addressFiasIds: [],
    sourceTypeFilter: "ALL",
    clientUserIdFilter: "",
    invoiceIdFilter: "",
};

const defaultSearchData = {clientName: ""};

const getRowSelectedCount = (row) => Object.values(row).filter(isSelected => isSelected).length;

class FinancePaymentList extends Component {
    static propTypes = {
        filterData: PropTypes.object,
    };

    static defaultProps = {
        filterData: {},
        orderWorkReportType: ORDER_WORK_REPORT_TYPE.SMZ,
    };

    constructor(props) {
        super(props);

        this.pageSizes = [25, 50, 100];
        const [pageSize] = this.pageSizes;

        const {params} = props.match;
        this.clientId = params.clientId;
        this.payment = params.paymentNumberFilter;

        this.isShowClientPayments = !isNullOrWhitespace(this.clientId);

        this.state = {
            pageSize,
            pageNum: 1,
            paymentList: [],
            selectedList: [],
            dataFilter: {
                ...defaultDataFilter,
                paymentNumberFilter: this.payment,
            },
            searchData: {
                ...defaultSearchData,
            },
            isOpenConfirm: false,
            orderId: null,
            paymentNumbers: [],
            operation: "",
            isOpenLog: false,
            logType: "",
            logData: {},
            isOpenFilter: true,
            isSubmit: false,
            rowsSelectedStatusByPaymentNumber: {},
            orderIdsByPaymentNumber: {},
            numberSelected: 0,
            isCorrectionReceiptModalShown: false,
            correctionReceiptDescription: null,
            correctionReceiptDate: null,
            currentPayment: null,
            errorForm: {},
            isRecieptLoading: false,
            isPaymentListLoaded: false,
            contractorsIds: [],
            receiptDuplicates: [],
            isSearch: false,
        };

        this.bankStatusOptions = dictionaryToOptions(STATUS_OF_TRANSFER_TRANSCRIPTION);

        this.role = ls(USER_ROLE);
        this.isClientRole = isClientUser(this.role);
        this.isNpd = props.orderWorkReportType === ORDER_WORK_REPORT_TYPE.SMZ;
        this.isCivil = props.orderWorkReportType === ORDER_WORK_REPORT_TYPE.CIVIL;
        this.isIndividualEntrepreneur = props.orderWorkReportType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;
    }

    loadData = filterData => {
        if (!filterData) {
            this.fetchPaymentList();

            return;
        }

        const {paymentNumber} = filterData;

        if (paymentNumber) {
            this.setState(prevState => ({
                ...prevState,
                dataFilter: {
                    ...prevState.dataFilter,
                    paymentNumberFilter: paymentNumber,
                },
                isOpenFilter: true,
            }), this.fetchPaymentList);
        } else {
            this.fetchPaymentList();
        }
    };

    componentDidMount() {
        const {
            filterData,
        } = this.props;

        this.loadData(filterData);

        if (this.isShowClientPayments) {
            this.fetchResponsible();
        }

        this.getContractorsPaymentPossibility();

        if ([ADMIN, NM_MANAGER].includes(this.role)) {
            this.getRunningUpdateAllBankStatus();
        }

        this.debounceRunningUpdate = debounce(this.getRunningUpdateAllBankStatus, 2000);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            getFinancesPaymentsBlockedContractors,
            progressPaymentsUpdateBankStatus,
        } = this.props;

        const newSelectedRowsCount = getRowSelectedCount(this.state.rowsSelectedStatusByPaymentNumber);

        if (getRowSelectedCount(prevState.rowsSelectedStatusByPaymentNumber) !== newSelectedRowsCount) {
            this.setState({
                numberSelected: newSelectedRowsCount,
            });
        }

        const {
            confirmPaymentSuccess,
            updateFieldOrderWorkReportStore,
            jobInfo,
        } = this.props;

        const {jobInfo: oldJobInfo} = prevProps;
        const isChangedList = (
            !isEmpty(this.props.paymentList)
            && !isEqual(this.props.paymentList, prevProps.paymentList)
        );

        if (isEmpty(jobInfo) && !isEmpty(oldJobInfo)) {
            this.fetchPaymentList();
        }

        if (confirmPaymentSuccess) {
            updateFieldOrderWorkReportStore({confirmPaymentSuccess: false});
            this.fetchPaymentList();
        }

        if (isChangedList) {
            const contractorsIds = this.props.paymentList.map(({contractorId}) => contractorId);

            getFinancesPaymentsBlockedContractors({
                clientId: this.clientId,
                contractorsIds,
            });
        }

        if (isChangedList && [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)) {
            this.getContractorsPaymentPossibility();
        }

        if (progressPaymentsUpdateBankStatus) {
            this.debounceRunningUpdate();
        }
    }

    componentWillUnmount() {
        const {
            updateFieldsContractorPaymentPossibilityState,
            updateJobFieldStore,
        } = this.props;

        updateJobFieldStore({tasks: []});

        updateFieldsContractorPaymentPossibilityState({
            contractorsPossibility: [],
        });

        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);
        this.debounceRunningUpdate.cancel();
    }

    get header() {
        if (this.isShowClientPayments) {
            const {header = "Выплаты исполнителям (НПД)"} = this.props;

            return header;
        }

        const {header = "Оплаты (НПД)"} = this.props;

        return header;
    }

    get isAccessPaymentSolution() {
        if (this.isClientRole && this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsIndividualPayments,
            ]);
        }

        if (this.isClientRole) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_confirmOrderWorkReport,
            ]);
        }

        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentNdflSolution,
            ]);
        }

        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentNpdSolution,
            ]);
        }

        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPayments,
            ]);
        }

        return true;
    };

    get isAccessGetReceipt() {
        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getCivilReceipt,
                CLIENT_USER_RESTRICTIONS_VARIABLE.getCivilPaymentDocuments,
            ]);
        }

        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getNpdReceipt,
                CLIENT_USER_RESTRICTIONS_VARIABLE.getSmzPaymentDocuments,
            ]);
        }

        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPaymentDocs,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsIndividualPaymentDocs,
            ]);
        }

        return true;
    }

    get isAccessActFileOrOrderApplicationFileOrFrameContractFile() {

        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getNdflActFileOrOrderApplicationFileOrFrameContractFile,
                CLIENT_USER_RESTRICTIONS_VARIABLE.getCivilPaymentDocuments,
            ]);
        }

        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getActFileOrOrderApplicationFileOrFrameContractFile,
                CLIENT_USER_RESTRICTIONS_VARIABLE.getSmzPaymentDocuments,
            ]);
        }

        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPaymentDocs,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsIndividualPaymentDocs,
            ]);
        }

        return true;
    }

    get isAccessDownloadActOrCheck() {
        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPaymentDocs,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsIndividualPaymentDocs,
            ]);
        }

        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getCivilPaymentDocuments,
            ]);
        }

        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getSmzPaymentDocuments,
            ]);
        }

        return true;
    }

    get isAccessCreateOffline() {
        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentNpdReceipt,
            ]);
        }

        return true;
    }

    get isAccessActionsOrderPayments() {
        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPayments,
            ]);
        }

        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsNdflOrderPayments,
            ]);
        }

        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOrderPayments,
            ]);
        }

        return true;
    }

    get isAccessUpdatePaymentsStatus() {
        if (this.isNpd) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOrderWorkReport,
            ]);
        }

        if (this.isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.updateNdflPaymentsStatus,
            ]);
        }

        if (this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPayments,
            ]);
        }

        return true;
    }

    get isAccessActionCompanyPayments() {
        if (this.isClientRole && this.isIndividualEntrepreneur) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsIndividualPayments,
            ]);
        }

        if (this.isClientRole) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_confirmOrderWorkReport,
            ]);
        }

        if (this.isCivil && this.clientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionCompanyNdflPayments,
            ]);
        }

        if (this.isNpd && this.clientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionCompanyNpdPayments,
            ]);
        }

        return true;
    };

    get isAccessActionCompanyVerification() {
        if (this.isNpd && this.clientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.verificationCompanyNpdPayment,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_checkPayment,
            ]);
        }

        if (this.isCivil && this.clientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.verificationCompanyNdflPayment,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_checkPayment,
            ]);
        }

        if (this.isIndividualEntrepreneur && this.clientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_checkIndividualPayments,
            ]);
        }

        return true;
    };

    fetchResponsible = () => {
        const {getClientMemberList} = this.props;

        getClientMemberList({
            clientId: this.clientId,
            archiveFilter: false,
            pageNum: 1,
            pageSize: 300,
        });
    };

    fetchPaymentList = () => {
        const {
            orderWorkReportType,
            getPageFinancesPayments,
        } = this.props;

        const {
            pageSize,
            pageNum,
            dataFilter,
        } = this.state;

        const {
            fromSumMoneyFilter,
            toSumMoneyFilter,
            clientId,
            paymentNumberFilter,
            registryNumberFilter,
            orderNumFilter,
            orderNameFilter,
            contractorFioFilter,
            fromClientCommissionAmountFilter,
            toClientCommissionAmountFilter,
            fromPlatformAmountFilter,
            toPlatformAmountFilter,
            sourceTypeFilter,
            objectIdsFilter,
            projectIdsFilter,
            addressFiasIds,
            reportStatusFilter,
            clientUserIdFilter,
            fromDateFilter,
            toDateFilter,
            statusOfTheTransferFilter,
            checked,
            invoiceIdFilter,
            contractorIdFilter,
        } = dataFilter;

        const data = {
            statusOfTheTransferFilter:
                isNullOrWhitespace(statusOfTheTransferFilter) || isEqual(statusOfTheTransferFilter, ["ALL"]) ?
                    undefined :
                    statusOfTheTransferFilter,
            checked,
            dateFilter: {
                from: fromDateFilter,
                to: toDateFilter,
            },
            objectIdsFilter: objectIdsFilter.length ? objectIdsFilter : undefined,
            projectIdsFilter: projectIdsFilter.length ? projectIdsFilter : undefined,
            addressFiasIds: addressFiasIds.length ? addressFiasIds : undefined,
            sumOfMoneyFilter: {
                from: handleNumber(fromSumMoneyFilter),
                to: handleNumber(toSumMoneyFilter),
            },
            clientId: this.isShowClientPayments ? this.clientId : handleFormString(clientId),
            paymentNumberFilter: handleFormString(paymentNumberFilter),
            registryNumberFilter: handleFormString(registryNumberFilter),
            orderNumFilter: handleFormString(orderNumFilter),
            orderNameFilter: handleFormString(orderNameFilter),
            contractorFioFilter: handleFormString(contractorFioFilter),
            clientCommissionAmountFilter: {
                from: handleNumber(fromClientCommissionAmountFilter),
                to: handleNumber(toClientCommissionAmountFilter),
            },
            platformAmountFilter: {
                from: handleNumber(fromPlatformAmountFilter),
                to: handleNumber(toPlatformAmountFilter),
            },
            pageSize,
            pageNum,
            orderContractPaymentTypeFilter: orderWorkReportType,
            sourceTypeFilter: isNullOrWhitespace(sourceTypeFilter) || sourceTypeFilter === "ALL" ? undefined : sourceTypeFilter,
            reportStatusFilter,
            priorityPaymentStatusFilter: this.clientId ? undefined : STATUS_PAYMENT.CONFIRMED,
            needToEnrichByProjectAndObjects: true,
            clientUserIdFilter: handleFormString(clientUserIdFilter),
            contractorIdFilter: handleFormString(contractorIdFilter),
            invoiceIdFilter,
            sortByStatuses: true,
        };

        getPageFinancesPayments(data);
    };

    onOpenFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpenFilter: !prevState.isOpenFilter,
        }));
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchPaymentList,
        );
    };

    getContractorsPaymentPossibility = () => {
        const {
            getContractorsPaymentPossibility,
            paymentList,
        } = this.props;

        if (this.isShowClientPayments) {
            return;
        }

        const contractorIds = [...new Set(paymentList.map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(this.checkBankPossibleTimer);
            },
            handleResponse: this.handleResponsePaymentPossibility,
            clientId: this.clientId,
            contractorIds,
        });
    };

    handleResponsePaymentPossibility = (data = {}) => {
        const {
            hasNoResultItems,
        } = data;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getContractorsPaymentPossibility, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchPaymentList,
        );
    };

    onSelectedRows = (selectedList, isAllSelected) => {
        const rowsSelectedStatusByPaymentNumber = Object.fromEntries(selectedList
            .map(({paymentNumber, isSelected}) => ([[paymentNumber], isSelected || false])));
        const ordersIds = Object.fromEntries(selectedList
            .map(({paymentNumber, orderId}) => ([[paymentNumber], orderId])),
        );
        const selectedIdsForChecking = selectedList.filter(({isSelected}) => isSelected).map(({key}) => key);

        this.setState({
            isAllSelected,
            selectedList,
            selectedIdsForChecking,
            rowsSelectedStatusByPaymentNumber: {
                ...this.state.rowsSelectedStatusByPaymentNumber,
                ...rowsSelectedStatusByPaymentNumber,
            },
            orderIdsByPaymentNumber: {
                ...this.state.orderIdsByPaymentNumber,
                ...ordersIds,
            },
        });
    };

    renderOrderInfo(info) {
        const {
            orderNum,
            orderName,
            clientId,
            orderId,
        } = info;

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                to={orderLink}
                historyEnabled
            >
                {orderNum}
                {" "}
                {orderName}
            </ExtLink>
        );
    }

    renderContractorInfo({contractorId, contractorFullName}, isBlocked) {
        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

        return (
            <div className="finance-payment-list__contractor-name">
                {
                    isBlocked &&
                    <NmIcon
                        className="finance-payment-list__icon-block"
                        name="lock"
                    />
                }
                <ExtLink
                    to={contractorLink}
                    historyEnabled
                >
                    {contractorFullName}
                </ExtLink>
            </div>
        );
    }

    renderClientInfo({clientId, clientName, brand}) {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        const brandValue = brand ? ` (${brand})` : "";

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {`${clientName}${brandValue}`}
            </ExtLink>
        );
    }

    handleDownload({downloadLink, isDownload}) {
        const {
            downloadDocument,
            t,
        } = this.props;

        if (isNullOrWhitespace(downloadLink)) {
            toastWarning(t("finance-payment-list.check-not-formed"));

            return;
        }

        downloadDocument({
            isDownload,
            downloadLink,
        });
    }

    isNotVisibleChecks = (payment) => {
        const {
            statusOfTheTransfer,
            paymentStatus,
        } = payment;

        return (
            (
                statusOfTheTransfer?.value !== STATUS_OF_TRANSFER_NEW.FINISHED.value
                && paymentStatus?.value !== STATUS_PAYMENT.PAYMENT_COMPLETED
            )
            || [STATUS_PAYMENT.REJECTED_CONTRACTOR, STATUS_PAYMENT.REJECTED].includes(paymentStatus?.value)
            || (
                (
                    this.isClientRole
                    || this.isShowClientPayments
                )
                && !this.isIndividualEntrepreneur
            )
        );
    };

    renderReceipts(data) {
        const {
            link: downloadLink,
            contractorCommission,
        } = data;

        if (this.isNotVisibleChecks(data)) {
            return <div />;
        }

        return (
            <div className="finance-payment-list__checks">
                {
                    this.isNpd &&
                    this.isAccessGetReceipt &&
                    <NmButton
                        isLink
                        color="light-green"
                        href={downloadLink}
                        className="finance-payment-list-table-receipts__item"
                        target="_blank"
                        rel="noopener noreferrer"
                        icon={<ReceiptIcon />}
                    >
                        Чек ФНС России
                    </NmButton>
                }
                {
                    contractorCommission !== 0 &&
                    this.isAccessGetReceipt &&
                    <NmButton
                        color="light-green"
                        className="finance-payment-list-table-receipts__item"
                        icon={<ReceiptIcon />}
                        onClick={() => this.getReceipt(data)}
                    >
                        Чек Атол
                    </NmButton>
                }
            </div>
        );
    }

    renderBankStatus({statusOfTheTransfer, bankDetailsError, paymentStatus}) {
        const {
            color = "",
        } = STATUS_OF_TRANSFER_NEW[statusOfTheTransfer.value] || {};

        return (
            <div className="flex flex-aligned-center">
                <div
                    style={{color}}
                    className="mr-2"
                >
                    {statusOfTheTransfer.description}
                </div>
                {
                    (paymentStatus?.value === STATUS_PAYMENT.PAYMENT_ERROR && bankDetailsError !== null) &&
                    <HelpTooltip
                        width={20}
                        height={20}
                        type="light"
                        attention
                        position="bottom-right"
                    >
                        {bankDetailsError}
                    </HelpTooltip>
                }
            </div>
        );
    }

    handlePopupLogOpen(logType, logData) {
        this.setState({
            isOpenLog: true,
            logType,
            logData,
        });
    }

    handlePopupLogClose = () => {
        this.setState({
            isOpenLog: false,
            logType: "",
            logData: {},
        });
    };

    getOptions(params) {
        const {
            t,
            orderWorkReportType,
        } = this.props;
        const {
            paymentStatus: _paymentStatus,
            statusOfTheTransfer: _statusOfTheTransfer,
            existActWork,
            sourceType,
            contractorCommission,
            existsAtolReceipt,
            bankType,
        } = params;

        const {
            value: paymentStatus,
        } = _paymentStatus || {};

        const {
            value: statusOfTheTransfer,
        } = _statusOfTheTransfer || {};

        const option = {
            rejectPayment: {
                key: "reject-payment",
                text: t("finance-payment-list.reject-payment"),
                value: "reject-payment",
                visible: this.isAccessPaymentSolution,
            },
            downloadOnline: {
                key: "download-online-check",
                text: t("finance-payment-list.download-online-check"),
                value: "download-online-check",
                visible: this.isAccessGetReceipt,
            },
            reservePayment: {
                key: "return-payment-reserve",
                text: t("finance-payment-list.return-payment-reserve"),
                value: "return-payment-reserve",
                visible: this.isAccessActionsOrderPayments,
            },
            // Аннулировать чек в ФНС
            cancelCheck: {
                key: "cancel-check",
                text: t("finance-payment-list.cancel-check"),
                value: "cancel-check",
                visible: this.isNpd
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.paymentNpdReceiptCancel,
                    ]),
            },
            // Offline-чек ФНС России
            createOffline: {
                key: "create-offline-check",
                text: t("finance-payment-list.create-offline-check"),
                value: "create-offline-check",
                visible: this.isNpd && this.isAccessCreateOffline,
            },
            // Отправить Offline-чек ФНС России
            sendOfflineCheck: {
                key: "send-offline-check",
                text: t("finance-payment-list.send-offline-check"),
                value: "send-offline-check",
                visible: this.isNpd && this.isAccessCreateOffline,
            },
            [FINANCE_LOG_TYPE.TRANSACTION_LOG]: {
                key: FINANCE_LOG_TYPE.TRANSACTION_LOG,
                text: t("finance-log.payment-bank-log"),
                value: FINANCE_LOG_TYPE.TRANSACTION_LOG,
            },
            cashReceipt: {
                key: "cash-receipt",
                text: t("finance-log.cash-receipt"),
                value: "cash-receipt",
                visible: this.isAccessGetReceipt,
            },
            rewardReceipt: {
                key: "reward-receipt",
                text: t("finance-log.reward-receipt"),
                value: "reward-receipt",
                visible: contractorCommission !== 0
                    && this.isAccessGetReceipt,
            },
            // Чек ФНС
            downloadCheck: {
                key: "download-check",
                text: t("finance-payment-list.download-check"),
                value: "download-check",
                visible: this.isNpd && this.isAccessDownloadActOrCheck,
            },
            downloadOrderApp: {
                key: "download-order-app",
                text: t("finance-payment-list.download-order-app"),
                value: "download-order-app",
            },
            downloadFrameContract: {
                key: "download-frame-contract",
                text: t("finance-payment-list.download-frame-contract"),
                value: "download-frame-contract",
            },
            downloadAct: {
                key: "download-act",
                text: t("finance-payment-list.download-act"),
                value: "download-act",
                visible: this.isAccessDownloadActOrCheck,
            },
            retryPayment: {
                key: "retry-payment",
                text: t("finance-payment-list.retry-payment"),
                value: "retry-payment",
                visible: this.isAccessActionsOrderPayments,
            },
            correctionReceipt: {
                key: "correction-receipt",
                text: t("finance-payment-list.correction-receipt"),
                value: "correction-receipt",
                visible: this.isAccessActionsOrderPayments,
            },
            returnReceipt: {
                key: "return-receipt",
                text: t("finance-payment-list.return-receipt"),
                value: "return-receipt",
                visible: this.isAccessActionsOrderPayments,
            },
            demoAct: {
                key: "demo-act",
                text: "Просмотр акта",
                value: "demo-act",
                visible: this.isAccessActFileOrOrderApplicationFileOrFrameContractFile,
            },
        };

        const isPlutonium = bankType?.value === BANK_TYPE.PLUTONIUM_W1.VALUE;
        const downloadOrderAppAndFrameContractAppOptions = this.isAccessActFileOrOrderApplicationFileOrFrameContractFile
            ? [
                option["downloadOrderApp"],
                option["downloadFrameContract"],
            ]
            : [];
        const initIndividualEntrepreneurPaymentsOptions = orderWorkReportType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL
            ? [
                existActWork && option["downloadAct"],
                ...downloadOrderAppAndFrameContractAppOptions,
            ].filter(item => Boolean(item))
            : [];

        const transactionLogOptions = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.getPaymentTransactionLogs,
        ])
        && !(
            this.isIndividualEntrepreneur
            && !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPaymentDocs,
            ])
        )
            ? [option[FINANCE_LOG_TYPE.TRANSACTION_LOG]]
            : [];
        const correctionAndReturnReceiptsOptions = (!isPlutonium && this.isCivil) || this.isIndividualEntrepreneur
            ? []
            : [
                option["correctionReceipt"],
                option["returnReceipt"],
            ];

        let options = [...initIndividualEntrepreneurPaymentsOptions];

        if (
            [ADMIN, NM_MANAGER, NM_CHIEF_ACCOUNTANT].includes(this.role)
            && (
                (isPlutonium && statusOfTheTransfer === STATUS_OF_TRANSFER.FINISHED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED)
            )
            && !existsAtolReceipt
            && contractorCommission !== 0
            && !(!isPlutonium && this.isCivil)
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.createReceiptAtolOrderPayments,
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsOrderWorkReport,
            ])
        ) {
            options = [
                ...options,
                option["downloadOnline"],
            ];
        }

        if ([ADMIN].includes(this.role) && paymentStatus === STATUS_PAYMENT.AWAITING_CONFIRMATION) {
            return [
                ...options,
                option["demoAct"],
                option["rejectPayment"],
                ...downloadOrderAppAndFrameContractAppOptions,
            ];
        }

        if ([
            ADMIN,
            NM_COORDINATOR,
            NM_MANAGER,
            NM_CHIEF_ACCOUNTANT,
            NM_PARTNER,
            CLIENT_ADMIN,
            OBJECT_MANAGER,
            CLIENT_ACCOUNTANT,
            HR_MANAGER,
            GR,
            RNKO,
            NM_CONSULTANT,
            PROJECT_MANAGER,
            FOREMAN,
        ].includes(this.role) &&
            [STATUS_PAYMENT.NOT_CONFIRMED, STATUS_PAYMENT.AWAITING_CONFIRMATION].includes(paymentStatus)
        ) {
            options = [
                ...options,
                option["demoAct"],
            ];
        }

        if (
            this.isShowClientPayments &&
            (
                (isPlutonium && statusOfTheTransfer === STATUS_OF_TRANSFER.FINISHED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED)
            ) &&
            ![STATUS_PAYMENT.REJECTED_CONTRACTOR, STATUS_PAYMENT.REJECTED].includes(paymentStatus)
        ) {
            if (sourceType?.value === "REGISTRY" && !existActWork) {
                return [
                    ...options,
                    option["downloadCheck"],
                    ...downloadOrderAppAndFrameContractAppOptions,
                ];
            }
            return [
                ...options,
                option["downloadCheck"],
                ...downloadOrderAppAndFrameContractAppOptions,
                option["downloadAct"],
            ];
        }
        if (this.isShowClientPayments) {
            if ([CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role) && paymentStatus === STATUS_PAYMENT.AWAITING_CONFIRMATION) {
                return [
                    ...options,
                    ...downloadOrderAppAndFrameContractAppOptions,
                    option["rejectPayment"],
                ];
            }

            return [
                ...options,
                ...downloadOrderAppAndFrameContractAppOptions,
            ];
        }

        if (
            [ADMIN].includes(this.role) &&
            (
                (isPlutonium && statusOfTheTransfer === STATUS_OF_TRANSFER.FINISHED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED)
            )
        ) {
            return [
                ...options,
                option["cashReceipt"],
                option["rewardReceipt"],
                option["cancelCheck"],
                ...transactionLogOptions,
                ...correctionAndReturnReceiptsOptions,
            ];
        }

        if (
            this.role === NM_MANAGER &&
            (
                (isPlutonium && statusOfTheTransfer === STATUS_OF_TRANSFER.FINISHED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED)
            )
        ) {
            return [
                ...options,
                option["cashReceipt"],
                option["rewardReceipt"],
                ...transactionLogOptions,
            ];
        }

        if (
            [NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role) &&
            (
                (isPlutonium && statusOfTheTransfer === STATUS_OF_TRANSFER.FINISHED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED)
            )
        ) {
            return [
                ...options,
                option["cashReceipt"],
                option["rewardReceipt"],
                ...transactionLogOptions,
            ];
        }

        if (this.role === ADMIN && paymentStatus === STATUS_PAYMENT.CONFIRMED) {
            return filterContextMenuByPropsVisibleRules([
                ...options,
                option["createOffline"],
                option["sendOfflineCheck"],
                ...transactionLogOptions,
            ]);
        }

        const errorBankStatuses = [
            STATUS_OF_TRANSFER.PREVIOUSFAILED,
            STATUS_OF_TRANSFER.UNKNOWN,
            STATUS_OF_TRANSFER.EXPIRED,
            STATUS_OF_TRANSFER.FAILED,
        ];

        if (
            this.role === ADMIN &&
            (
                (isPlutonium && errorBankStatuses.includes(statusOfTheTransfer) && paymentStatus !== STATUS_PAYMENT.CANCELLED) ||
                (!isPlutonium && paymentStatus === STATUS_PAYMENT.PAYMENT_ERROR)
            )
        ) {
            if ([STATUS_PAYMENT.REJECTED_CONTRACTOR, STATUS_PAYMENT.REJECTED].includes(paymentStatus)) {
                return [
                    ...options,
                    ...transactionLogOptions,
                ];
            }
            return [
                ...options,
                option["retryPayment"],
                ...transactionLogOptions,
                option["reservePayment"],
                option["rejectPayment"],
            ];
        }

        if (this.role === NM_MANAGER && paymentStatus === STATUS_PAYMENT.PAYMENT_ERROR) {
            return [
                ...transactionLogOptions,
                option["reservePayment"],
            ];
        }

        if ((this.role === ADMIN || this.role === NM_MANAGER) && paymentStatus === STATUS_PAYMENT.FOR_PAYMENT) {
            return [
                ...options,
                ...transactionLogOptions,
            ];
        }

        if (
            (this.role === ADMIN || this.role === NM_MANAGER) &&
            (paymentStatus === STATUS_PAYMENT.PAYMENT_COMPLETED || paymentStatus === STATUS_PAYMENT.PAYMENT_ERROR)
        ) {
            if ([STATUS_PAYMENT.PAYMENT_ERROR].includes(paymentStatus)) {
                return [
                    ...options,
                    option["retryPayment"],
                    ...transactionLogOptions,
                ];
            }

            return [
                ...options,
                ...transactionLogOptions,
            ];
        }

        if (
            [NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role) &&
            (
                [
                    STATUS_PAYMENT.CONFIRMED,
                    STATUS_PAYMENT.PAYMENT_COMPLETED,
                    STATUS_PAYMENT.PAYMENT_ERROR,
                    STATUS_PAYMENT.FOR_PAYMENT,
                    STATUS_PAYMENT.PAYMENT_ERROR,
                ].includes(paymentStatus) ||
                (errorBankStatuses.includes(statusOfTheTransfer) && paymentStatus !== STATUS_PAYMENT.CANCELLED)
            )
        ) {
            return [
                ...options,
                ...transactionLogOptions,
            ];
        }

        return options;
    }

    getMobileOptions = (payment) => {
        const {link, contractorCommission} = payment;

        if (this.isNotVisibleChecks(payment)) {
            return [];
        }

        return [
            {
                key: "fns",
                text: "Чек ФНС России",
                value: "fns",
                href: link,
                visible: this.isNpd && this.isAccessGetReceipt,
            },
            {
                key: "atol",
                text: "Чек Атол",
                value: "atol",
                visible: contractorCommission !== 0 && this.isAccessGetReceipt,
            },
        ];
    };

    renderContextMenu(payment, mobile) {
        const {paymentStatus} = payment;

        if (NM_OPERATOR === this.role) {
            return null;
        }

        if (!this.isShowClientPayments &&
            paymentStatus?.value === STATUS_PAYMENT.CANCELLED
        ) {
            return null;
        }

        const options = !mobile ?
            this.getOptions(payment) :
            [
                ...this.getOptions(payment),
                ...this.getMobileOptions(payment),
            ];

        if (isEmpty(options.filter(({visible = true}) => visible))) {
            return null;
        }

        return (
            <ContextMenu
                options={getListUniqueOptions(options)}
                onClickItem={(option) => {
                    this.onClickActionItem(option, payment);
                }}
            />
        );
    }

    createOfflineCheck({contractorId, description, orderId, paymentNumber, sumOfMoney, clientId}) {
        const {
            createOfflineCheck,
        } = this.props;

        const reqData = {
            contractorId,
            description,
            orderId,
            paymentNumber,
            sumOfMoney,
            clientId,
        };

        createOfflineCheck(reqData);
    }

    rejectPayments = (rejectModalNumberPayments, orderId, reasonForRefusal = "", clientId) => {
        const {
            rejectFinancesPayments,
        } = this.props;

        const requestedData = {
            clientId: this.clientId,
            payments: rejectModalNumberPayments.map((paymentNumber) => {
                return {
                    paymentNumber,
                    orderId,
                    clientId: this.clientId || clientId,
                };
            }),
            reasonForRefusal,
            onSuccess: () => {
                this.fetchPaymentList();
                this.getTasks();
            },
        };

        rejectFinancesPayments(requestedData);
    };

    downloadOnlineCheck({paymentNumber}) {
        const {downloadDocument, t} = this.props;

        if (!paymentNumber) {
            toastError(t("finance-payment-list.error-no-payment-number"));
            return;
        }

        downloadDocument({
            documentType: `${t("finance-payment-list.online-check-commission")}_${paymentNumber}`,
            downloadLink: `/api/documents/getAtolIncomeCheckFile?paymentNumber=${paymentNumber}`,
        });
    }

    registrationOfflineCheck(data) {
        const {registrationOfflineCheck} = this.props;

        registrationOfflineCheck(data);
    }

    openLink(url) {
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        tempLink.setAttribute("target", "_blank");

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();
    }

    retryPayment(data) {
        const {
            retryPayment,
        } = this.props;

        retryPayment({
            ...data,
            handleResponse: this.handleResponseRetryPayment,
        });
    }

    handleResponseRetryPayment = (result) => {
        const {t} = this.props;
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            return;
        }

        toastSuccess(t("finance-payment-list.payment-retry-completed"));
        this.fetchPaymentList();

    };

    handleResponseGetReceiptDuplicates = (result) => {
        if (!isNullOrWhitespace(result)) {
            this.setState({
                isOpenReceiptDuplicateList: true,
                duplicates: result,
            });

            return;
        }

        this.createContractorReturnReceipt();
    };

    submitReceiptDuplicates = (externalId) => {
        this.createContractorReturnReceipt(externalId);
    };

    createContractorReturnReceipt(externalId) {
        const {createContractorReturnReceipt} = this.props;
        const {currentPayment} = this.state;

        this.setReceiptLoading(true);

        createContractorReturnReceipt({
            data: {
                ...currentPayment,
                externalId,
            },
            getSuccess: (link) => {
                this.openLink(link);
                this.closeReceiptDuplicateModal();
            },
            setLoading: this.setReceiptLoading,
        });
    }

    onClickMobileAction = ({value}) => {
        switch (value) {
            case OPERATION.CONFIRM:
                this.openWindowConfirm({operation: OPERATION.CONFIRM, checkingDuplicates: true, checkWarning: true});

                return;
            case OPERATION.PAY:
                this.openWindowConfirm({operation: OPERATION.PAY, checkingDuplicates: true});

                return;
            case OPERATION.REJECT:
                this.openWindowConfirm({operation: OPERATION.REJECT});

                return;
            default:
                return;
        }
    };


    getReceipt = (payment) => {
        const {
            getReceiptFinancesPayments,
        } = this.props;

        const {
            orderId,
            paymentNumber,
            clientId,
            orderWorkReportId,
        } = payment;

        const reqData = {
            paymentNumber,
            orderWorkReportId,
            orderId,
            clientId,
            getSuccess: (link) => {
                this.openLink(link);
            },
            setLoading: this.setReceiptLoading,
        };

        getReceiptFinancesPayments(reqData);
    };

    onClickActionItem(option, payment) {
        const {
            createContractorReceipt,
            getReturnReceiptInfo,
            getCorrectionReceiptInfo,
            orderWorkReportType,
        } = this.props;

        const {value: action} = option;

        const {
            contractorId,
            description,
            orderId,
            paymentNumber,
            sumOfMoney,
            clientId,
            receiptId,
            link,
            orderWorkReportId,
            bankType,
            frameContractDownloadLink,
        } = payment;

        switch (action) {
            case "atol": {
                this.getReceipt(payment);

                return;
            }
            // //Чек ФНС
            case "download-check": {
                this.openLink(link);
                return;
            }
            //Заявка к договору
            case "download-order-app": {
                const link = `/bff${getBffCommonController()}/download-client-files/getOrderApplicationFile`;

                this.handleDownload({
                    isDownload: true,
                    downloadLink: `${link}?clientId=${clientId}&orderId=${orderId}&contractorId=${contractorId}`,
                });

                return;
            }
            //Рамочный договор
            case "download-frame-contract": {
                this.handleDownload({
                    isDownload: true,
                    downloadLink: frameContractDownloadLink,
                });

                return;
            }
            //Акт выполненных работ
            case "download-act": {
                this.handleDownload({
                    isDownload: true,
                    downloadLink: `/bff${getBffCommonController()}/download-client-files/getActOfAcceptanceOfWorkFile?paymentNumber=${paymentNumber}`,
                });

                return;
            }
            // Квитанция о перечислении денежных средств
            case "cash-receipt": {
                this.handleDownload({
                    isDownload: false,
                    downloadLink: `/bff${getBffCommonController()}/download-client-files/getCashReceipt?paymentNumber=${paymentNumber}`,
                });

                return;
            }
            // Квитанция о вознаграждении Наймикс
            case "reward-receipt": {
                this.handleDownload({
                    isDownload: false,
                    downloadLink: `/bff${getBffCommonController()}/download-client-files/getNaimixRewardReceipt?paymentNumber=${paymentNumber}`,
                });

                return;
            }
            case "demo-act": {
                this.handleDownload({
                    isDownload: true,
                    downloadLink: `/bff${getBffCommonController()}/download-client-files/get-act-of-acceptance-of-work-demo-file?paymentNumber=${paymentNumber}`,
                });

                return;
            }
            case "retry-payment": {
                this.retryPayment({paymentNumber});
                return;
            }
            //Отклонить платёж
            case "reject-payment": {
                this.openPaymentConfirm(OPERATION.REJECT_ONCE, orderId, paymentNumber, clientId);
                return;
            }
            //Вернуть платеж в резерв
            case "return-payment-reserve": {
                this.rejectPayments([paymentNumber], orderId, null, clientId);
                return;
            }
            //Сформировать Атол чек
            case "download-online-check": {
                createContractorReceipt({
                    paymentNumber,
                    getSuccess: (link) => {
                        if (link) {
                            this.openLink(link);
                        } else {
                            this.downloadOnlineCheck({
                                paymentNumber,
                            });
                        }
                    },
                    setLoading: this.setReceiptLoading,
                });

                return;
            }
            case "send-offline-check": {
                this.registrationOfflineCheck({receiptId, paymentNumber, orderId});

                return;
            }
            case "create-offline-check": {
                this.createOfflineCheck({
                    contractorId,
                    description,
                    orderId,
                    paymentNumber,
                    sumOfMoney,
                    clientId,
                });
                return;
            }
            case FINANCE_LOG_TYPE.TRANSACTION_LOG: {
                this.handlePopupLogOpen(FINANCE_LOG_TYPE.TRANSACTION_LOG, {
                    paymentNumber,
                    clientId,
                    orderId,
                    description,
                    orderWorkReportId,
                    bankType: bankType?.value,
                    orderWorkReportType,
                });
                return;
            }
            case "cancel-check": {
                this.submitCancelReceipt(payment);

                return;
            }
            case "correction-receipt": {
                this.setState({
                    isCorrectionReceiptModalShown: true,
                    currentPayment: payment,
                });

                getCorrectionReceiptInfo({
                    data: {
                        clientId,
                        orderId,
                        orderWorkReportId,
                        paymentNumber,
                    },
                });

                return;
            }
            case "return-receipt": {
                this.setState({
                    isReturnReceiptModalShow: true,
                    currentPayment: payment,
                });

                getReturnReceiptInfo({
                    data: {
                        clientId,
                        contractorId,
                        orderId,
                        orderWorkReportId,
                        paymentNumber,
                    },
                });

                return;
            }
            default:
                break;
        }
    }

    submitCancelReceipt = (payment) => {
        const {
            receiptId,
            orderId,
            paymentNumber,
            contractorInn,
        } = payment;

        const {
            cancelReceiptFinancesPayments,
            t,
        } = this.props;

        if (receiptId === null) {
            toastError(t("finance-payment-list.error-no-check-id"));
            return;
        }

        if (contractorInn === null) {
            toastError(t("finance-payment-list.error-no-inn-contractor"));
            return;
        }

        const reqData = {
            inn: contractorInn,
            receiptId,
            //ToDo: Временно, потом будем выбирать через форму
            description: t("finance-payment-list.refund"),
            orderId,
            paymentNumber,
        };

        cancelReceiptFinancesPayments(reqData);
    };

    updateAllBankStatus = () => {
        const {
            paymentsUpdateAllBankStatus,
            orderWorkReportType,
        } = this.props;

        paymentsUpdateAllBankStatus({
            type: orderWorkReportType,
            onSuccess: () => {
                toastSuccess("Обновление статусов запущено, за статусом можно следить в \"Списке задач\"");
                this.getRunningUpdateAllBankStatus();
            },
        });
    };

    getRunningUpdateAllBankStatus = () => {
        const {
            getRunningPaymentsUpdateAllBankStatus,
            orderWorkReportType,
        } = this.props;

        getRunningPaymentsUpdateAllBankStatus({
            type: orderWorkReportType,
        });
    };

    renderHeaderStatus(payment) {
        const {orderWorkReportType} = this.props;
        const {
            paymentStatus,
            paymentNumber,
            clientId,
            orderId,
            description,
        } = payment;

        const {
            status,
            color,
        } = getPaymentStatus({
            isClientRole: this.isClientRole,
            paymentStatus,
            orderWorkReportType,
        });

        const rightTooltipText = getPaymentError(payment);

        return (
            <div className="flex flex-column flex-md-row">
                <NmBadge
                    pointer
                    title="По нажатию откроется лог платежа"
                    text={status}
                    mod={color}
                    onClick={(event) => {
                        event.stopPropagation();

                        this.handlePopupLogOpen(FINANCE_LOG_TYPE.ORDER_WORK_REPORT_LOG, {
                            paymentNumber,
                            clientId,
                            orderId,
                            description,
                        });
                    }}
                    tooltipPosition="bottom-left"
                    rightTooltipText={rightTooltipText}
                />
                {
                    payment.checked &&
                    <NmBadge
                        noWrap
                        className="mt-2 mt-md-0 ms-md-2"
                        text="Акт проверен"
                        mod="light-green"
                    />
                }
            </div>
        );
    }

    _formatAmount = (amount) => isNullOrWhitespace(amount) ? "" : formatAmount(formatNumber(amount, 2));

    renderCommissionClient(orderWorkReport) {
        if (this.isCivil) {
            return getFinanceListNdflCommission(orderWorkReport); //ндфл
        }

        return getFinanceListCommission(orderWorkReport);
    }

    renderContractorCommission({contractorCommission, platformAmount}) {
        const contractorCommissionTxt = this._formatAmount(contractorCommission * 100);
        const amountTxt = this._formatAmount(platformAmount);

        return [
            {
                text: `${amountTxt}(${contractorCommissionTxt}%)`,
            },
        ];
    }

    checkStatus = (row) => {
        const {
            paymentStatus,
        } = row;

        if (this.isShowClientPayments) {
            return [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role) && [
                STATUS_PAYMENT.NOT_CONFIRMED,
                STATUS_PAYMENT.NOT_CONFIRMED_ERROR,
            ].includes(paymentStatus?.value);
        }

        return this.role === ADMIN && [
            STATUS_PAYMENT.CONFIRMED,
            STATUS_PAYMENT.CONFIRMATION_ERROR,
        ].includes(paymentStatus?.value);
    };

    renderSumField = (item) => {
        return (
            <div className="finance-payment-list__sum-field">
                <div>
                    {this._formatAmount(item.sumOfMoney)}
                </div>
            </div>
        );
    };

    openReadMoreModal = (shoreMoreContent) => {
        this.setState({
            isOpenMoreModal: true,
            shoreMoreContent,
        });
    };

    renderShowMoreModal() {
        const {
            isOpenMoreModal,
            shoreMoreContent,
        } = this.state;

        if (!isOpenMoreModal) {
            return null;
        }

        return (
            <NmModal
                size="md"
                children={shoreMoreContent}
                onClose={() => {
                    this.setState({
                        isOpenMoreModal: false,
                        shoreMoreContent: undefined,
                    });
                }}
            />
        );
    }

    getShowMore = (content) => {
        return (
            <ShowMoreText
                lines={1}
                more="Подробнее"
                anchorClass="finance-payment-list__show-more-anchor"
                expanded={false}
                expandByClick={false}
                onClick={() => {
                    this.openReadMoreModal(content);
                }}
            >
                {content || "-"}
            </ShowMoreText>
        );
    };

    getCards = (row) => {
        // Комиссия заказчика
        const clientCommission = this.role !== FOREMAN ?
            [{
                title: "Комиссия заказчика, ₽",
                values: this.renderCommissionClient(row),
                className: "col-16 col-md-6",
                wrapValue: true,
            }] :
            [];

        const contractorCommission = (this.isShowClientPayments || this.isClientRole) ? [] :
            [{
                title: "Комиссия исполнителя, ₽",
                values: this.renderContractorCommission(row),
                className: "col-16 col-md-5",
            }];

        return [
            {title: "Сумма операции, ₽", value: this.renderSumField(row), className: "col-16 col-md-5"},
            ...clientCommission,
            ...contractorCommission,
        ];
    };

    getBankStatus(row) {
        if (this.isClientRole || !row.statusOfTheTransfer) {
            return [];
        }

        return [{label: "Банковский статус", text: this.renderBankStatus(row), noWrap: false}];
    }

    getAdditionalLabels = (item) => {
        const {
            sourceType,
        } = item;

        if (sourceType?.value === ORDER_SOURCE_TYPE.REGISTRY) {
            return [
                {label: "Реестр оплат", text: this.renderRegistryLink(item)},
            ];
        }
        return [];
    };

    getRows = () => {
        const {
            jobInfo,
            blockedContractorsIds,
            client: {
                archived: isClientArchived,
            },
            clientProperties,
            paymentList,
            isNpd,
        } = this.props;
        const {
            rowsSelectedStatusByPaymentNumber,
            isOpenFilter,
        } = this.state;

        return paymentList.map(row => {
            const {
                orderId,
                paymentNumber,
                clientId,
                contractorId,
                description,
                sourceType,
                bankType,
                paymentPath,
                paymentLocation,
                lastUpdate,
                sumOfMoney,
                legalEntityCommissionTotalAmount,
                amountForReport,
            } = row;

            const hasBlockedPayment = jobInfo ? jobInfo.includes(`${clientId}:${orderId}:${paymentNumber}`) : false;
            const isContractorBlocked = blockedContractorsIds.includes(contractorId);

            // Банковский статус
            const bankStatus = this.getBankStatus(row);
            // Возможность оплаты
            const possible = this.isShowClientPayments || this.isIndividualEntrepreneur ? [] : [{
                label: "Проверено банком",
                text: this.renderPossible(row),
                alignItems: "center",
                noWrap: false,
            }];
            // Заказчик
            const clientInfo = (this.isShowClientPayments || this.isClientRole) ? [] : [{
                label: "Заказчик",
                text: this.renderClientInfo(row),
            }];

            const isNotVisibleChecks = this.isNotVisibleChecks(row);

            const classNameMainContent = isNotVisibleChecks ?
                "col-16 col-xxl-6" :
                "col-16 col-xxl-5";
            const cardsContainerClassName = isNotVisibleChecks ?
                "col-16 col-md-12 col-xxl-9 mt-md-4 mt-xxl-0" :
                "col-16 col-md-12 col-xxl-7 mt-md-4 mt-xxl-0";

            return {
                key: row.orderWorkReportId,
                paymentNumber,
                lastUpdate,
                orderId,
                clientId,
                sumOfMoney,
                legalEntityCommissionTotalAmount,
                isSelected: rowsSelectedStatusByPaymentNumber[row.paymentNumber],
                showCheckBox: !isClientArchived && this.isShowCheckBox() && this.checkStatus(row),
                disabledCheckBox: hasBlockedPayment,
                contentRow: (
                    <NmListCard
                        checkbox
                        classNameMainContent={isOpenFilter ? "col-16 col-xxl-16" : classNameMainContent}
                        secondaryHeader={dateFormat(convertUtcToLocal(lastUpdate))}
                        primaryHeader={this.renderContractorInfo(row, isContractorBlocked)}
                        secondaryHeaderStatus={this.renderHeaderStatus(row)}
                        labels={[
                            ...clientInfo,
                            {label: "Номер оплаты", text: paymentNumber},
                            !this.isClientRole && {
                                label: "Способ оплаты",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            !this.isClientRole && {
                                label: "Местонахождение платежа",
                                text: paymentLocation?.description || "-",
                            },
                            {label: "Номер и наим. заказа", text: this.renderOrderInfo(row)},
                            ...bankStatus,
                            {
                                label: "Описание платежа",
                                columnOnMobile: true,
                                text: this.getShowMore(description),
                            },
                            {label: "Тип оплаты", text: PAYMENT_SOURCE_TYPE_DICT[sourceType.value]},
                            ...this.getAdditionalLabels(row),
                            {label: "Проект", text: this.renderProjectInfo(row)},
                            {label: "Объект", text: this.renderObjectInfo(row)},
                            ...possible,
                            isNpd && clientProperties.amountForReport && {
                                label: "Сумма для отчета",
                                columnOnMobile: true,
                                text: amountForReport ? formatAmount(amountForReport) : "-",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName={isOpenFilter ? "col-16 col-md-12 col-xxl-10 mt-md-4" : cardsContainerClassName}
                        cardsContainerAlign={isOpenFilter ? "left" : "right"}
                        cards={this.getCards(row)}
                        actionsClassName={isNotVisibleChecks ? "col-1" : "col-3 finance-payment-list__actions"}
                        actions={
                            <Media query={{maxWidth: 1919}}>
                                {
                                    mobile =>
                                        mobile ?
                                            this.renderContextMenu(row, mobile) :
                                            <>
                                                {this.renderReceipts(row)}
                                                {this.renderContextMenu(row)}
                                            </>
                                }
                            </Media>
                        }
                    />
                ),
            };
        });
    };

    renderRegistryLink = ({registryId, clientId, registryNumber, registrySeqNum}) => {
        const {
            t,
        } = this.props;

        const registryCardLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", clientId)
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", registryId);

        const _registryNumber = `${t("finance-payment-list.payment-register-number")} ${registryNumber}`;
        const _registrySeqNum = `номер строки: ${registrySeqNum}`;

        return (
            <ExtLink
                historyEnabled
                to={registryCardLink}
            >
                {`${_registryNumber}, ${_registrySeqNum}`}
            </ExtLink>
        );
    };

    renderProjectInfo = ({projectName, projectId, clientId}) => {
        if (!projectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId || this.clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId}) => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    renderPossible = ({contractorId}) => {
        const {contractorsPossibility} = this.props;

        return (
            <BankIndicator
                size="md"
                hideLoaderContent
                loaderSize="tiny"
                position="bottom"
                contractorsPossibility={contractorsPossibility}
                contractorId={contractorId}
            />
        );
    };

    sendFilter = () => {
        this.setState({
            pageNum: 1,
            rowsSelectedStatusByPaymentNumber: {},
            orderIdsByPaymentNumber: {},
            isSearch: true,
        }, this.fetchPaymentList);
    };

    setFilter({value, name}, callback) {
        this.setState(prevState => ({
            ...prevState,
            pageNum: 1,
            searchData: {
                ...prevState.searchData,
                clientName: name === "clientId" ? "" : prevState.searchData.clientName,
            },
            dataFilter: {
                ...prevState.dataFilter,
                [name]: value,
            },
        }), callback);
    }

    onChangeFilter = (e, data) => {
        this.setFilter(data);
    };

    clearSearchData() {
        this.setState({
            searchData: {...defaultSearchData},
        });
    }

    clearFilter = () => {
        this.clearSearchData();

        this.setState({
            dataFilter: {...defaultDataFilter},
            rowsSelectedStatusByPaymentNumber: {},
            orderIdsByPaymentNumber: {},
            isSearch: false,
        }, () => {
            this.fetchPaymentList();
        });
    };

    closeWindowConfirm = (params) => {
        this.setState(prevState => ({
            ...prevState,
            operation: "",
            checkingDuplicates: false,
            checkWarning: false,
            isOpenConfirm: false,
            orderId: null,
            paymentNumbers: [],
            isSubmit: false,
            rowsSelectedStatusByPaymentNumber: !params?.resetSelected
                ? prevState.rowsSelectedStatusByPaymentNumber
                : {},
            orderIdsByPaymentNumber: {},
        }));
    };

    openPaymentConfirm = (operation, orderId, paymentNumber, clientId) => {
        this.setState({
            operation,
            isOpenConfirm: true,
            orderId,
            clientId,
            paymentNumbers: [paymentNumber],
        });
    };

    openWindowConfirm = ({operation, checkingDuplicates, checkWarning}) => {
        const {rowsSelectedStatusByPaymentNumber} = this.state;

        const paymentNumbers = Object
            .entries(rowsSelectedStatusByPaymentNumber)
            .filter(([, status]) => status)
            .map(([id]) => id);

        this.setState({
            operation,
            checkWarning,
            checkingDuplicates,
            isOpenConfirm: true,
            orderId: null,
            paymentNumbers,
        });
    };

    checkOrderWorkActs = () => {
        const {
            checkOrderWorkActs,
        } = this.props;

        const {
            selectedIdsForChecking,
        } = this.state;

        checkOrderWorkActs({
            data: selectedIdsForChecking,
            clientId: this.clientId,
            onSuccess: this.fetchPaymentList,
        });
    };

    isShowCheckBox = () => {
        return (
            [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)
            && isAccessByRestrictions([
                this.isIndividualEntrepreneur && CLIENT_USER_RESTRICTIONS_VARIABLE.actionsIndividualPayments,
            ].filter(Boolean))
        );
    };

    openDuplicatePaymentsModal = ({operation, warningPayments, duplicatedPayments}) => {
        this.setState({
            operation,
            warningPayments,
            duplicatedPayments,
            isOpenDuplicatePayments: true,
            checkWarning: false,
            checkingDuplicates: false,
        });
    };

    closeDuplicatePaymentsModal = () => {
        this.setState({
            operation: "",
            isOpenDuplicatePayments: false,
        });
    };

    closeReceiptDuplicateModal = () => {
        this.setState({
            duplicates: [],
            currentPayment: {},
            isOpenReceiptDuplicateList: false,
        });

        this.setReceiptLoading(false);
    };

    payOrderPayments = (listPayments) => {
        const {
            confirmFinancesPayments,
        } = this.props;
        const {checkingDuplicates} = this.state;

        const requestData = {
            clientId: this.clientId,
            checkingDuplicates,
            listPayments,
            onSuccess: ({paymentsResultList, confirmedPayments}) => {
                if (paymentsResultList && paymentsResultList.length !== 0) {
                    this.openDuplicatePaymentsModal({
                        operation: OPERATION.PAY,
                        duplicatedPayments: paymentsResultList,
                    });
                }

                if (confirmedPayments && confirmedPayments.length !== 0) {
                    const paymentNumbers = confirmedPayments.join(", ");

                    toastSuccess(replacer(":number", ORDER_WORK_REPORT.PAYMENT_TASK.SUCCESS, paymentNumbers));
                }

                this.fetchPaymentList();
                this.getTasks();
            },
        };

        confirmFinancesPayments(requestData);
    };

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    confirmOrderPayments = ({listPayments, rejectPayments}) => {
        const {
            confirmFinancesPayments,
        } = this.props;
        const {
            checkingDuplicates,
            checkWarning,
        } = this.state;

        const requestData = {
            clientId: this.clientId,
            checkingDuplicates,
            checkWarning,
            listPayments,
            rejectPayments,
            onSuccess: (result) => {
                const {
                    paymentsResultList = [],
                    confirmedPayments,
                    warningPayments,
                } = result;
                // подтвержденные выплаты
                if (confirmedPayments && confirmedPayments.length !== 0) {
                    const paymentNumbers = confirmedPayments.join(", ");

                    toastSuccess(replacer(":number", ORDER_WORK_REPORT.PAYMENT_REPORT.SUCCESS, paymentNumbers));

                    this.setState({
                        confirmedPayments,
                    });
                }

                // платежи по которым нашли дубликаты
                // могут прийти одновременно warningPayments - для них отдельное МО
                if (paymentsResultList && paymentsResultList.length !== 0) {
                    this.openDuplicatePaymentsModal({
                        operation: OPERATION.CONFIRM,
                        warningPayments,
                        duplicatedPayments: paymentsResultList,
                    });

                    return;
                }

                // сохраняем платежи которые не прошли контроли, для последующего использования в мо подтверждения
                if (warningPayments && warningPayments.length !== 0) {
                    this.setState({
                        warningPayments, // Сохраняем варнинги
                        checkingDuplicates: false, // выключаем проверку на дубликаты
                        checkWarning: false, // выключаем проверку на варнинги
                        isOpenWarningsCheck: true, // открываем МО с варнингами
                    });
                }

                this.getTasks();
                this.fetchPaymentList();
            },
        };

        confirmFinancesPayments(requestData);
    };

    submitDuplicatedPayments = (payments) => {
        const {operation, warningPayments} = this.state;

        if (operation === OPERATION.PAY) {
            this.payOrderPayments(payments);

            return;
        }

        // Если есть платежи, не прошедшие контроли, открываем МО с варнингами для дальнейшего подтверждения платежей
        if (!isEmpty(warningPayments)) {
            this.setState({
                confirmedDuplicatedPayments: payments, // сохраняем дубликаты
                isOpenDuplicatePayments: false, // закрываем мо с дубликатами
                isOpenWarningsCheck: true, // открываем МО с варнингами
                checkingDuplicates: false, // выключаем проверку на дубликаты
                checkWarning: false, // выключаем проверку на варнинги
            });

            return;
        }

        // operation === OPERATION.CONFIRM
        this.confirmOrderPayments({listPayments: payments});
    };

    actionSwitcher = (reasonForRefusal) => {
        const {
            rejectPaymentMethod,
            rejectFinancesPayments,
        } = this.props;

        const {
            operation,
            rowsSelectedStatusByPaymentNumber,
            orderIdsByPaymentNumber,
            paymentNumbers,
            orderId,
            clientId,
            selectedList,
        } = this.state;

        const payments = Object
            .entries(rowsSelectedStatusByPaymentNumber)
            .filter(([, status]) => status)
            .map(([id]) => {
                const {clientId} = selectedList.find(({paymentNumber}) => paymentNumber === Number(id));

                return {
                    paymentNumber: Number(id),
                    orderId: orderIdsByPaymentNumber[id],
                    clientId,
                };
            });

        switch (operation) {
            case OPERATION.PAY:
                this.payOrderPayments(payments);

                break;
            case OPERATION.CONFIRM:
                this.confirmOrderPayments({listPayments: payments});

                break;
            case OPERATION.REJECT_ONCE:
                this.rejectPayments(paymentNumbers, orderId, reasonForRefusal, clientId);

                break;
            default:
                const reqData = {
                    clientId: this.clientId,
                    payments,
                    reasonForRefusal,
                    onSuccess: () => {
                        this.fetchPaymentList();
                        this.getTasks();
                    },
                };

                if (rejectPaymentMethod) {
                    rejectPaymentMethod(reqData);
                    return;
                }

                rejectFinancesPayments(reqData);
        }

        this.closeWindowConfirm({resetSelected: true});
    };

    handleOnSubmit = (reasonForRefusal) => {
        this.actionSwitcher(reasonForRefusal);

        this.setState(prevState => ({
            ...prevState,
            selectedList: prevState.selectedList.map(item => ({
                ...item,
                isSelected: false,
            })),
            rowsSelectedStatusByPaymentNumber: {},
            orderIdsByPaymentNumber: {},
        }));
    };

    renderConfirmWindow() {
        const {
            isOpenConfirm,
            operation,
            paymentNumbers,
        } = this.state;

        const {t} = this.props;

        if (!paymentNumbers.length) {
            toastWarning(t("finance-payment-list.no-payouts-selected"));
            this.setState({isOpenConfirm: false});
            return null;
        }

        const getContent = () => {
            const ending = paymentNumbers.length === 1 ? "у" : "ы";
            const operationMsg = operation === OPERATION.PAY || operation === OPERATION.CONFIRM ? t("button.confirm") : t("button.reject");

            return `Вы действительно хотите ${operationMsg.toLowerCase()} выплат${ending} ${paymentNumbers.join(", ")}?`;
        };

        if ([OPERATION.REJECT_ONCE, OPERATION.REJECT].includes(operation)) {
            return (
                <RejectionReason
                    title={t("finance-payment-list.reason")}
                    close={this.closeWindowConfirm}
                    submit={this.handleOnSubmit}
                />
            );
        }

        return (
            isOpenConfirm &&
            <NmConfirmV2
                isNeedClosing={false}
                onCancel={this.closeWindowConfirm}
                onConfirm={() => this.setState({isSubmit: true}, this.handleOnSubmit)}
                content={getContent()}
                confirmButton="Да"
                cancelButton="Нет"
                size="sm"
            />
        );
    }

    renderClientMassActionsBtns() {
        const {
            numberSelected,
        } = this.state;

        if (![ADMIN, CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) {
            return;
        }

        return (
            <FinancePaymentListClientMassActions
                numberSelected={numberSelected}
                clientId={this.clientId}
                isCivil={this.isCivil}
                isAccessActionPayments={this.isAccessActionCompanyPayments}
                isAccessActionVerification={this.isAccessActionCompanyVerification}
                role={this.role}
                selectedSum={this.selectedSum}
                onOpenConfirm={this.openWindowConfirm}
                onCheckOrderWorkActs={this.checkOrderWorkActs}
            />
        );
    }

    renderNmAdminMassActionsBtns() {
        const {
            numberSelected,
        } = this.state;

        const {
            t,
        } = this.props;

        return (
            (
                [ADMIN].includes(this.role) &&
                this.isAccessPaymentSolution
            ) &&
            <SelectionCountWithAction
                adaptiveLogic
                count={numberSelected}
                onClick={() => {
                    this.openWindowConfirm({
                        operation: OPERATION.PAY,
                        checkingDuplicates: true,
                    });
                }}
                buttonColor="green"
                buttonContent={t("finance-payment-list.pay-button")}
                otherActions={
                    <NmButton
                        disabled={numberSelected === 0}
                        size="lg"
                        color="grey"
                        onClick={() => {
                            this.openWindowConfirm({operation: OPERATION.REJECT});
                        }}
                    >
                        {t("button.reject")}
                    </NmButton>
                }
            />
        );
    }

    renderMassActionsBtns() {
        if (this.isShowClientPayments) {
            return this.renderClientMassActionsBtns();
        } else {
            return this.renderNmAdminMassActionsBtns();
        }
    }

    getMassActionOptions = () => {
        if (!this.isAccessActionsOrderPayments) {
            return null;
        }

        if (this.isShowClientPayments) {
            return this.getMassActionsMobileClientOptions();
        }

        return ACTION_MOBILE_ADMIN_OPTIONS;
    };

    getMassActionsMobileClientOptions() {
        if (![ADMIN, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role) || !this.isAccessPaymentSolution) {
            return null;
        }

        return ACTION_MOBILE_CLIENT_OPTIONS;
    }

    get classNames() {
        const {pathname} = this.props;
        const classNames = ["finance-payment-list"];

        if (!this.companyId) {
            classNames.push("finance-payment-list_overflow");
        }

        if (pathname.indexOf("client-card") === -1) {
            classNames.push("finance-payment-list_pd-all");
        }

        return classNames.join(" ");
    }

    selectedSum = (hasCommission = true) => {
        const {selectedList} = this.state;

        const result = selectedList.filter(item => item.isSelected).reduce((prev, item) => {
            const {sumOfMoney, legalEntityCommissionTotalAmount} = item;
            const sum = prev + sumOfMoney;
            return hasCommission ? sum + legalEntityCommissionTotalAmount : sum;
        }, 0);

        return round(result, 2);
    };

    isValidForm = () => {
        const {
            correctionReceiptDate,
        } = this.state;

        const data = {
            correctionReceiptDate,
        };

        const rules = {
            "correctionReceiptDate": {
                required: "Обязательное поле",
            },
        };

        const error = validate(data, rules, "");

        this.setState({errorForm: {...error}});

        return Object.values(error).length === 0;
    };

    setReceiptLoading = (isLoading) => {
        this.setState({
            isRecieptLoading: isLoading,
        });
    };

    handlesubmitCorrectionReceipt = (event) => {
        event.preventDefault();

        const isValid = this.isValidForm();

        if (!isValid) {
            return;
        }

        const {
            correctionReceiptDate,
            currentPayment: {
                clientId,
                orderId,
                orderWorkReportId,
                paymentNumber,
            },
        } = this.state;

        const {
            createContractorCorrectionReceipt,
        } = this.props;

        this.setReceiptLoading(true);

        createContractorCorrectionReceipt({
            data: {
                clientId,
                correctionDate: convertUtcToLocal(correctionReceiptDate, "dd.MM.yy HH:mm:ss"),
                orderId,
                orderWorkReportId,
                paymentNumber,
            },
            getSuccess: (link) => {
                this.openLink(link);
            },
            setLoading: this.setReceiptLoading,
        });

        this.setState({
            isCorrectionReceiptModalShown: false,
            correctionReceiptDate: null,
            correctionReceiptDescription: null,
            currentPayment: null,
        });
    };

    renderDuplicateCheck() {
        const {
            isOpenDuplicatePayments,
            duplicatedPayments,
        } = this.state;

        return isOpenDuplicatePayments &&
            <FinancePaymentListDuplicateCheck
                close={this.closeDuplicatePaymentsModal}
                submit={this.submitDuplicatedPayments}
                payments={duplicatedPayments}
            />;
    }

    onCloseWarningsCheck = () => {
        this.setState({
            confirmedPayments: [],
            isOpenWarningsCheck: false,
            warningPayments: [],
            confirmedDuplicatedPayments: [],
            operation: "",
        });
    };

    renderWarningsCheck() {
        const {isOpenWarningsCheck, warningPayments, confirmedDuplicatedPayments, confirmedPayments} = this.state;

        return isOpenWarningsCheck &&
            <FinancePaymentListWarningCheck
                confirmedPayments={confirmedPayments}
                confirmedDuplicatedPayments={confirmedDuplicatedPayments}
                warningPayments={warningPayments}
                onClose={this.onCloseWarningsCheck}
                onSubmit={this.confirmOrderPayments}
            />;
    }

    renderReceiptDuplicateList() {
        const {
            isOpenReceiptDuplicateList,
            duplicates,
        } = this.state;

        return isOpenReceiptDuplicateList &&
            <ReceiptDuplicateList
                duplicates={duplicates}
                close={this.closeReceiptDuplicateModal}
                submit={this.submitReceiptDuplicates}
            />;
    }

    renderReturnReceiptInfoBlock() {
        const {
            isReturnReceiptModalShow,
        } = this.state;

        if (!isReturnReceiptModalShow) {
            return null;
        }

        const {
            currentPayment: {
                paymentNumber,
            },
        } = this.state;

        const {
            returnReceiptInfo: {
                client = {},
                company = {},
                items = [],
                payments = [],
            },
            getReceiptDuplicates,
        } = this.props;

        const {
            email,
            inn,
            name,
            phone,
        } = client;

        const {
            email: companyEmail,
            inn: companyInn,
            payment_address,
            sno,
        } = company;


        return (
            <NmModal
                size="sm"
                className="finance-payment-list__modal"
                header={
                    <NmTitle size="lg">
                        Параметры чека на возврат прихода
                    </NmTitle>
                }
                onClose={() => {
                    this.setState({
                        isReturnReceiptModalShow: false,
                    });
                }}
                footer={
                    <ApplyButtons
                        submitBtnContent="Отправить"
                        submit={() => {
                            getReceiptDuplicates({
                                paymentNumber,
                                onSuccess: this.handleResponseGetReceiptDuplicates,
                            });
                        }
                        }
                    />
                }
            >
                <NmForm>
                    <div className="finance-payment-list__block-header">
                        Исполнитель
                    </div>
                    <NmLabelText
                        className="mt-2"
                        label="ФИО"
                        text={name}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Телефон"
                        text={phone}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Email"
                        text={email}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="ИНН"
                        text={inn}
                    />
                    <div className="finance-payment-list__block-header">
                        Компания
                    </div>
                    <NmLabelText
                        className="mt-2"
                        label="Email"
                        text={companyEmail}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="ИНН"
                        text={companyInn}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Адрес платежа"
                        text={payment_address}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="SNO"
                        text={sno}
                    />
                    <div className="finance-payment-list__block-header">
                        Строки
                    </div>
                    {items.map((item) => {
                        return (
                            <>
                                <NmLabelText
                                    className="mt-2"
                                    label="Наименование"
                                    text={item.name}
                                />
                                <NmInfoCard
                                    className="mt-2 finance-payment-list__modal-info-card"
                                    title="Сумма, ₽"
                                    value={item.sum}
                                />
                                <NmLabelText
                                    className="mt-2"
                                    label="Метод платежа"
                                    text={item.payment_method}
                                />
                                <NmLabelText
                                    className="mt-2"
                                    label="Объект платежа"
                                    text={item.payment_object}
                                />
                                <NmLabelText
                                    className="mt-2"
                                    label="Количество"
                                    text={item.quantity}
                                />
                                <NmInfoCard
                                    className="mt-2 finance-payment-list__modal-info-card"
                                    title="Цена, ₽"
                                    value={item.price}
                                />
                            </>
                        );
                    })}
                    <div className="finance-payment-list__block-header">
                        Оплаты
                    </div>
                    {payments.map((item) => {
                        return (
                            <>
                                <NmLabelText
                                    className="mt-2"
                                    label="Тип"
                                    text={item.type}
                                />
                                <NmInfoCard
                                    className="mt-2 finance-payment-list__modal-info-card"
                                    title="Сумма, ₽"
                                    value={item.sum}
                                />
                            </>
                        );
                    })}
                </NmForm>
            </NmModal>
        );
    }

    renderCorrectionReceiptInfoBlock() {
        const {
            isCorrectionReceiptModalShown,
            errorForm,
        } = this.state;

        const {
            correctionReceiptInfo: {
                company = {},
                correction_info = {},
                payments = [],
                vats = [],
            },
        } = this.props;

        if (!isCorrectionReceiptModalShown) {
            return null;
        }

        const {
            email,
            inn,
            payment_address,
            sno,
        } = company;

        const {
            base_date,
            base_name,
            base_number,
            type,
        } = correction_info;

        return (
            <NmModal
                size="sm"
                header={
                    <NmTitle size="lg">
                        Параметры чека коррекции
                    </NmTitle>
                }
                onClose={() => {
                    this.setState({
                        isCorrectionReceiptModalShown: false,
                        correctionReceiptDate: null,
                        correctionReceiptDescription: null,
                        paymentNumber: null,
                        errorForm: {},
                    });
                }}
                footer={
                    <ApplyButtons
                        submitBtnContent="Отправить"
                        submit={this.handlesubmitCorrectionReceipt}
                    />
                }
            >
                <NmForm>
                    <NmDatePicker
                        size="xl"
                        label="Дата совершения корректировки"
                        error={errorForm["correctionReceiptDate"]}
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText="дд.мм.гггг"
                        dateFormatMask="99.99.9999 99:99"
                        showTimeSelect
                        name="correctionReceiptDate"
                        onChange={(event, {value}) => {
                            this.setState({
                                correctionReceiptDate: value,
                            });
                        }}
                        selected={this.state.correctionReceiptDate}
                    />
                    <div className="finance-payment-list__block-header">
                        Компания
                    </div>
                    <NmLabelText
                        className="mt-2"
                        label="Email"
                        text={email}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="ИНН"
                        text={inn}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Адрес платежа"
                        text={payment_address}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="SNO"
                        text={sno}
                    />
                    <div className="finance-payment-list__block-header">
                        Информация коррекции
                    </div>
                    <NmLabelText
                        className="mt-2"
                        label="Базовая дата"
                        text={base_date}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Базовое наименование"
                        text={base_name}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Базовый номер"
                        text={base_number}
                    />
                    <NmLabelText
                        className="mt-2"
                        label="Тип"
                        text={type}
                    />
                    <div className="finance-payment-list__block-header">
                        Оплаты
                    </div>
                    {payments.map((item, index) => {
                        return (
                            <div key={index}>
                                <NmLabelText
                                    className="mt-2"
                                    label="Тип"
                                    text={item.type}
                                />
                                <NmInfoCard
                                    className="mt-2 finance-payment-list__modal-info-card"
                                    title="Сумма, ₽"
                                    value={item.sum}
                                />
                            </div>
                        );
                    })}
                    <div className="finance-payment-list__block-header">
                        НДС
                    </div>
                    {vats.map((item, index) => {
                        return (
                            <div key={index}>
                                <NmLabelText
                                    className="mt-2"
                                    label="Тип"
                                    text={item.type}
                                />
                                <NmInfoCard
                                    className="mt-2 finance-payment-list__modal-info-card"
                                    title="Сумма, ₽"
                                    value={item.sum}
                                />
                            </div>
                        );
                    })}
                </NmForm>
            </NmModal>
        );
    }

    getHeaderMediaControls() {
        const {
            progressPaymentsUpdateBankStatus,
            progressPaymentsUpdateBankStatusAction,
        } = this.props;

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: this.updateAllBankStatus,
                        size: "xl",
                        children: "Обновить статусы платежей из банка",
                        color: "grey",
                        icon: <ResetIcon />,
                        disabled: progressPaymentsUpdateBankStatus || progressPaymentsUpdateBankStatusAction,
                    },
                    visible: [ADMIN, NM_MANAGER].includes(this.role)
                        && this.isAccessActionsOrderPayments
                        && this.isAccessUpdatePaymentsStatus,
                },
            ],
        };
    }

    render() {
        const {
            contractorListForFilter,
            federalRegionsOptions,
            responsibleOptions,
            loadSignal,
            jobInfo,
            paymentListTaskIds,
            client: {
                archived: isClientArchived,
            },
            clientProperties: {
                crowdTasksAvailable,
            },
            paymentList,
            loadingData,
            actionProgress,
            totalCount,
            totalPages,
            orderWorkReportType,
        } = this.props;

        const {
            isOpenFilter,
            pageNum,
            pageSize,
            dataFilter,
            isOpenConfirm,
            isOpenLog,
            logData,
            logType,
            searchData,
            isRecieptLoading,
            isSearch,
            isReturnReceiptModalShow,
            isCorrectionReceiptModalShown,
        } = this.state;

        const orderLink = LINK_CLIENT_ORDER_LIST.replace(":clientId", this.clientId);
        const isShowControls = !!paymentList?.length || isSearch;
        const _loadSignal = [NM_CONSULTANT, NM_COORDINATOR, RNKO].includes(this.role) ? false : loadSignal;
        const isOpenModal = isReturnReceiptModalShow || isCorrectionReceiptModalShown || isOpenLog;

        return (
            <NmPage
                widthByFilter
                className={this.classNames}
                header={
                    <NmPageHeader
                        totalCount={!isEmpty(paymentList) && totalCount}
                        text={this.header}
                    />
                }
                subHeader={
                    isShowControls && ![FOREMAN].includes(this.role) &&
                    <FinancePaymentListAmountValues
                        isShow={isShowControls}
                        clientId={this.clientId}
                        isCivil={this.isCivil}
                        selectedSum={this.selectedSum}
                        isClientPayments={this.isShowClientPayments}
                    />
                }
                mediaControls={this.getHeaderMediaControls()}
                openFilter={isOpenFilter && !isOpenModal}
                onOpenFilter={this.onOpenFilter}
                typeFilter="vertical"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
                isLoaded={loadingData || isRecieptLoading || _loadSignal || actionProgress}
                filtersBase={
                    <>
                        {
                            this.isShowClientPayments ?
                                <FinanceClientPaymentListFilter
                                    clientId={this.clientId}
                                    isClientRole={this.isClientRole}
                                    dataFilter={dataFilter}
                                    clearFilter={this.clearFilter}
                                    sendFilter={this.sendFilter}
                                    onChange={this.onChangeFilter}
                                    bankStatusOptions={this.bankStatusOptions}
                                    contractorListForFilter={contractorListForFilter}
                                    onSearchContractorChange={this.onSearchContractorChange}
                                    onSearchOrderChange={this.onSearchOrderChange}
                                    searchData={searchData}
                                    handleOnSearchFederalMigration={this.handleOnSearchFederalMigration}
                                    federalRegionsOptions={federalRegionsOptions}
                                    responsibleOptions={responsibleOptions}
                                    orderWorkReportType={orderWorkReportType}
                                /> :
                                <FinancePaymentListFilter
                                    dataFilter={dataFilter}
                                    clearFilter={this.clearFilter}
                                    sendFilter={this.sendFilter}
                                    onChange={this.onChangeFilter}
                                    bankStatusOptions={this.bankStatusOptions}
                                    contractorListForFilter={contractorListForFilter}
                                    onSearchContractorChange={this.onSearchContractorChange}
                                    onSearchOrderChange={this.onSearchOrderChange}
                                    searchData={searchData}
                                    orderWorkReportType={orderWorkReportType}
                                />
                        }
                    </>
                }
            >
                {
                    this.clientId &&
                    this.isNpd &&
                    crowdTasksAvailable &&
                    <ClientFinancePaymentListTabs
                        {...this.props}
                    />
                }
                <Task />
                {isOpenConfirm ? this.renderConfirmWindow() : null}
                {isOpenLog ?
                    <FinanceLogPopup
                        handleClose={this.handlePopupLogClose}
                        logType={logType}
                        logData={logData}
                        isShowClientPayments={this.isShowClientPayments}
                        isCivil={this.isCivil}
                    />
                    : null
                }
                {this.renderShowMoreModal()}
                {this.renderDuplicateCheck()}
                {this.renderWarningsCheck()}
                {this.renderReceiptDuplicateList()}
                {this.renderReturnReceiptInfoBlock()}
                {this.renderCorrectionReceiptInfoBlock()}
                <ByTaskUpdater
                    fetch={this.fetchPaymentList}
                    taskIds={jobInfo}
                    dataIds={paymentListTaskIds}
                />
                {
                    paymentList?.length ?
                        <CheckboxList
                            header={
                                !isClientArchived && isShowControls && this.renderMassActionsBtns()
                            }
                            onSelectedRows={this.onSelectedRows}
                            withCheckbox={
                                !isClientArchived
                                && this.isShowCheckBox()
                            }
                            rows={this.getRows()}
                            onClickActionItem={this.onClickMobileAction}
                            actionOptions={this.getMassActionOptions()}
                        /> :
                        <NmEmptyPageV2
                            title="Выплаты исполнителям отсутствуют"
                            description="В реестре будет отображаться список платежей по заказам.
                            Для инициирования выплаты исполнителю за оказанные услуги сформируйте акт выполненных работ
                            в разделе “Заказы” "
                            textLink="Перейти в реестр заказов"
                            patchLink={orderLink}
                            isSearch={isSearch}
                            fetchProgress={loadingData}
                        />
                }
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        pathname: state.router.location.pathname,
        paymentListTaskIds: getOrderWorkReportOptimizedListTaskIdsSelector(state),
        paymentList: bffFinancesPaymentsListSelector(state),
        actionProgress: bffFinancesPaymentsProgressActionSelector(state),
        totalCount: bffFinancesPaymentsTotalCountSelector(state),
        totalPages: bffFinancesPaymentsTotalPagesSelector(state),
        contractorListForFilter: getContractorListForFilterSelector(state),
        loadingData: bffFinancesPaymentsProgressSelector(state),
        confirmPaymentSuccess: getOrderWorkConfirmPaymentSuccessSelector(state),
        federalRegionsOptions: getFederalRegionOptionsSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        jobInfo: jobTasksPaymentsInfoSelector(state),
        blockedContractorsIds: bffFinancesPaymentsBlockedContractorsIdsSelector(state),
        correctionReceiptInfo: correctionReceiptInfoSelector(state),
        returnReceiptInfo: returnReceiptInfoSelector(state),
        loadSignal: jobTaskLoadSignalSelector(state),
        client: clientCardInfoSelector(state),
        contractorsPossibility: contractorsPaymentPossibilityListSelector(state),
        responsibleOptions: clientMemberOptionsSelector(state),
        progressPaymentsUpdateBankStatus: commonJobProgressPaymentsUpdateBankStatusSelector(state),
        progressPaymentsUpdateBankStatusAction: commonJobProgressPaymentsUpdateBankStatusActionSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        downloadDocument,
        getReturnReceiptInfo,
        getContractorListForFilter,
        createContractorReceipt,
        createOfflineCheck,
        registrationOfflineCheck,
        updateFieldOrderWorkReportStore,
        getCorrectionReceiptInfo,
        retryPayment,
        checkOrderWorkActs,
        createContractorCorrectionReceipt,
        createContractorReturnReceipt,
        getFinancesPaymentsBlockedContractors,
        updateFieldsContractorPaymentPossibilityState,
        getContractorsPaymentPossibility,
        getReceiptDuplicates,
        getReceiptFinancesPayments,
        getClientMemberList,
        updateJobFieldStore,
        paymentsUpdateAllBankStatus,
        getRunningPaymentsUpdateAllBankStatus,
        getAllTasks,
        getPageFinancesPayments,
        rejectFinancesPayments,
        cancelReceiptFinancesPayments,
        confirmFinancesPayments,
    },
)(withTranslation()(FinancePaymentList)));
