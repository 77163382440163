import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ColumnLabelTextValues from "../../../../../../components/ActualComponents/ColumnLabelTextValues";
import Filter from "../../../../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import {MediaButtons} from "../../../../../../components/ActualComponents/MediaControls";
import NmAdvancedTooltip from "../../../../../../components/ActualComponents/NmAdvancedTooltip";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Text from "../../../../../../components/ActualComponents/Text";
import ExtLink from "../../../../../../components/ExtLink";
import NmBadge from "../../../../../../components/NmBadge";
import NmCheck from "../../../../../../components/NmCheck";
import NmPage from "../../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../../components/NmPageHeader";
import TableDiv from "../../../../../../components/TableDiv";
import {ReactComponent as CheckIcon} from "../../../../../../images/check_bg_v2.svg";
import {ReactComponent as ResetIcon} from "../../../../../../images/reset_24.svg";

import {usePagination} from "../../../../../../hooks/usePagination";
import useSortList, {sortAction} from "../../../../../../hooks/useSortList";
import {useFetchOnboardingContractorsList} from "./hooks/useFetch";
import {useOnboardingContractorsListFilter} from "./hooks/useFilter";

import bem from "../../../../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {
    getFullName,
    phoneFormat,
} from "../../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../../../constants/color";
import {COMMON_ALL_OPTION} from "../../../../../../constants/common";
import {CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS} from "../../../../../../constants/contractor";
import {onboardingContractorsHeaders} from "../../../../../../constants/headersTable";
import {
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
} from "../../../../../../constants/links";
import {
    ONBOARDING_BOUND_SCENARIOS_STATUS,
    ONBOARDING_LEAD_TYPE,
    ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS,
    ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS,
    ONBOARDING_TAX_STATUS,
} from "../../../../../../constants/onboarding";
import {HR_MANAGER} from "../../../../../../constants/roles";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENTS_DICT,
} from "../../../../../contractor/contractor-info-new/components/additional-documents-verification/constants";

import {onboardingScenarioCardSelector} from "../../../../../../ducks/onboarding";
import {
    onboardingContractorsListProgressSelector,
    onboardingContractorsListSelector,
    onboardingContractorsListTotalCountSelector,
    onboardingContractorsListTotalPagesSelector,
    onboardingContractorsProgressActionSelector,
} from "../../../../../../ducks/onboardingContractors";

import "./style.sass";

const initFilter = {
    contractorNameFilter: "",
    sourceFilter: COMMON_ALL_OPTION.key,
};

const TOOLTIP_TOP_POSITION_ROW_COUNT = 2;
const DELETED_LEAD_MESSAGE = "Лид онбординга не найден. Возможно, он был удален";

const OnboardingScenarioContractorList = (props) => {
    const {
        clientId,
        scenarioId,
        isBoundScenarios,
    } = props;

    const [, sortType, headers, sortDispatch] = useSortList(onboardingContractorsHeaders);

    const totalPages = useSelector(onboardingContractorsListTotalPagesSelector);
    const totalCount = useSelector(onboardingContractorsListTotalCountSelector);
    const list = useSelector(onboardingContractorsListSelector);
    const progress = useSelector(onboardingContractorsListProgressSelector);
    const progressAction = useSelector(onboardingContractorsProgressActionSelector);
    const {
        documentParams,
        contractorParams,
    } = useSelector(onboardingScenarioCardSelector);

    const [block, element] = bem("onboarding-scenario-contractor-list");

    const role = ls(USER_ROLE);

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    } = useOnboardingContractorsListFilter({clientId, scenarioId});

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        exportContactorsList,
        refreshContactorsStatusesList,
        downloadFile,
        resubmitFrameContract,
    } = useFetchOnboardingContractorsList({
        filter: filterDto,
        sortType,
        pageSize,
        pageNum,
        clientId,
        scenarioId,
    });

    const handleChangeSearch = (field, sortType) => {
        if (sortType !== "asc" && sortType !== "desc") {
            return;
        }

        sortDispatch(sortAction([field, sortType]));
    };

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    const renderContractorLink = (fullName, contractorId) => {
        if (isNullOrWhitespace(contractorId)) {
            return fullName;
        }

        const link = (
            role === HR_MANAGER
                ? LINK_CONTRACTOR_REVIEWS_LIST
                : LINK_CONTRACTOR_PROFILE
        ).replace(":contractorId", contractorId);

        return (
            <ExtLink
                pageData={{pageNum, pageSize}}
                historyEnabled
                to={link}
            >
                {fullName}
            </ExtLink>
        );
    };

    const renderCheckIcon = (isCheck) => {
        return (
            <CheckIcon
                color={isCheck ? COLOR.PRIMARY_100 : COLOR.SECONDARY_10}
                width={20}
                height={20}
            />
        );
    };

    const getLeadInfo = (item) => {
        const {leadInfo, source} = item;

        if (![ONBOARDING_LEAD_TYPE.LEAD].includes(source.value) || !leadInfo) {
            return null;
        }

        const firstPersonalDataSubmissionDateTime = formatDate(convertUtcToLocal(leadInfo.firstPersonalDataSubmissionDateTime));
        const lastPersonalDataSubmissionDateTime = formatDate(convertUtcToLocal(leadInfo.lastPersonalDataSubmissionDateTime));

        return (
            <HelpTooltip
                info
                type="light"
                text={
                    leadInfo.deleted ?
                        DELETED_LEAD_MESSAGE :
                        <ColumnLabelTextValues
                            values={[
                                {
                                    label: "Лид от",
                                    text: formatDate(convertUtcToLocal(leadInfo.creationDateTime)) || "-",
                                },
                                {
                                    label: "Телефон",
                                    text: phoneFormat(leadInfo.externalContractorPhone) || "-",
                                },
                                {
                                    label: "ИНН",
                                    text: leadInfo.externalContractorInn || "-",
                                },
                                {
                                    label: "Статус регистрации",
                                    text: leadInfo.registrationStatus?.description || "-",
                                },
                                {
                                    label: "Дата простой регистрации",
                                    text: formatDate(convertUtcToLocal(leadInfo.basicRegistrationDateTime)) || "-",
                                },
                                {
                                    label: "Дата первой и последней заявки ПД",
                                    text: `${firstPersonalDataSubmissionDateTime} - ${lastPersonalDataSubmissionDateTime}`,
                                },
                                {
                                    label: "Идентификатор лида",
                                    text: leadInfo.externalLeadId || "-",
                                },
                            ]}
                        />
                }
            />
        );
    };

    const renderSource = (item) => {
        return (
            <div className="d-flex align-item-center justify-content-between fluid">
                <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                >
                    {item.source.description}
                </Text>
                {getLeadInfo(item)}
            </div>
        );
    };

    const renderFrameContractStatus = ({frameContractStatus, frameContractInfo}, index) => {
        const isTopPositionTooltip = list.length - index <= TOOLTIP_TOP_POSITION_ROW_COUNT && list.length > TOOLTIP_TOP_POSITION_ROW_COUNT;

        return (
            <div>
                <NmBadge
                    noWrap
                    mod={ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS[frameContractStatus]?.MOD}
                    text={ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS[frameContractStatus]?.TEXT}
                />
                {
                    frameContractInfo &&
                    <NmAdvancedTooltip
                        type="light"
                        className="mt-2"
                        position={isTopPositionTooltip ? "top" : "bottom"}
                        trigger={
                            <Text
                                color={COLOR.PASSIVE_100}
                                level="2"
                                isCursorPointer
                            >
                                Подробнее
                            </Text>
                        }
                        classNameTooltip="onboarding-scenario-contractor-list__tooltip"
                        children={
                            <ColumnLabelTextValues
                                values={[
                                    {
                                        label: "Дата отправки",
                                        text: formatDate(convertUtcToLocal(frameContractInfo.submittedDateTime), "dd.MM.yyyy HH:mm") || "27.07.2018 19:31",
                                    },
                                    {
                                        label: "Дата подписания",
                                        text: formatDate(convertUtcToLocal(frameContractInfo.signedDateTime)) || "27.07.2018 19:31",
                                        isVisible: frameContractStatus === ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.SIGNED.VALUE,
                                    },
                                    {
                                        label: "Причина отклонения",
                                        text: frameContractInfo.declinedReason || "declinedReason",
                                        isVisible: frameContractStatus === ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.DECLINED.VALUE,
                                    },
                                    {
                                        label: "Реестр",
                                        text: (
                                            <ExtLink
                                                to={
                                                    LINK_CLIENT_REGISTRY_FC_CARD
                                                        .replace(":clientId", frameContractInfo.clientId)
                                                        .replace(":registryId", frameContractInfo.registryId)
                                                }
                                                historyEnabled
                                            >
                                                Ссылка на реестр
                                            </ExtLink>
                                        ),
                                    },
                                ]}
                            />
                        }
                    />
                }
            </div>
        );
    };

    const renderAdditionalDocumentsStatus = ({additionalDocumentsStatus, additionalDocumentStates}) => {
        const checkDocumentList = Object.keys(additionalDocumentStates || {}).filter(item => additionalDocumentStates?.[item] === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK);
        const checkPassedDocumentList = Object.keys(additionalDocumentStates || {}).filter(item => additionalDocumentStates?.[item] === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.ADMINISTRATOR_CHECK_PASSED);
        const declinedDocumentList = Object.keys(additionalDocumentStates || {}).filter(item => additionalDocumentStates?.[item] === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.DECLINED);
        const notPresentDocumentList = Object.keys(additionalDocumentStates || {}).filter(item => additionalDocumentStates?.[item] === CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS.NOT_PRESENT);

        return (
            <div>
                <NmBadge
                    noWrap
                    mod={ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS[additionalDocumentsStatus]?.MOD}
                    text={ONBOARDING_SCENARIO_CONTRACTOR_ADDITIONAL_DOCUMENT_STATUS[additionalDocumentsStatus]?.TEXT}
                />
                {
                    !isEmpty(additionalDocumentStates) &&
                    <NmAdvancedTooltip
                        type="light"
                        className="mt-2"
                        position="bottom"
                        trigger={
                            <Text
                                level="2"
                                color={COLOR.PASSIVE_100}
                                isCursorPointer
                            >
                                Подробнее
                            </Text>
                        }
                        children={
                            <div className="onboarding-scenario-contractor-list__additional-documents-tooltip-content">
                                {
                                    Boolean(checkDocumentList.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            На проверке
                                        </Text>
                                        {
                                            checkDocumentList.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center mt-2"
                                                    >
                                                        <NmCheck isWarning={true} />
                                                        <Text className="ms-2">
                                                            {CONTRACTOR_ADDITIONAL_DOCUMENTS_DICT[item]}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                {
                                    Boolean(checkPassedDocumentList.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            Одобрено
                                        </Text>
                                        {
                                            checkPassedDocumentList.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center mt-2"
                                                    >
                                                        <NmCheck isCheck={true} />
                                                        <Text className="ms-2">
                                                            {CONTRACTOR_ADDITIONAL_DOCUMENTS_DICT[item]}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                {
                                    Boolean(declinedDocumentList.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            Отклонено
                                        </Text>
                                        {
                                            declinedDocumentList.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center mt-2"
                                                    >
                                                        <NmCheck isCheck={false} />
                                                        <Text className="ms-2">
                                                            {CONTRACTOR_ADDITIONAL_DOCUMENTS_DICT[item]}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                {
                                    Boolean(notPresentDocumentList.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            Не загружено
                                        </Text>
                                        {
                                            notPresentDocumentList.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center mt-2"
                                                    >
                                                        <NmCheck isNotPresent={true} />
                                                        <Text className="ms-2">
                                                            {CONTRACTOR_ADDITIONAL_DOCUMENTS_DICT[item]}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    />
                }
            </div>
        );
    };

    const renderScenarioStatuses = ({mainScenarioStatus, boundScenariosStatus, boundScenariosProgressList}) => {
        const mainScenario = {
            name: "Базовый сценарий",
        };

        const completedScenarios = [
            mainScenarioStatus === ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED && mainScenario,
            ...(boundScenariosProgressList || []).filter(({status}) => status === ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED),
        ].filter(item => Boolean(item));

        const notCompletedScenarios = [
            mainScenarioStatus !== ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED && mainScenario,
            ...(boundScenariosProgressList || []).filter(({status}) => status !== ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED),
        ].filter(item => Boolean(item));

        return (
            <div>
                <div className={element("scenario-statuses")}>
                    {renderCheckIcon(mainScenarioStatus === ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED)}
                    {
                        isBoundScenarios &&
                        renderCheckIcon(boundScenariosStatus === ONBOARDING_BOUND_SCENARIOS_STATUS.COMPLETED)
                    }
                </div>
                {
                    boundScenariosProgressList?.length &&
                    <NmAdvancedTooltip
                        type="light"
                        className="mt-2"
                        position="bottom"
                        trigger={
                            <Text
                                level="2"
                                color={COLOR.PASSIVE_100}
                                isCursorPointer
                            >
                                Подробнее
                            </Text>
                        }
                        children={
                            <div>
                                {
                                    Boolean(completedScenarios.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            Пройдены
                                        </Text>
                                        {
                                            completedScenarios.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center"
                                                    >
                                                        {renderCheckIcon(true)}
                                                        <Text className="ms-2">
                                                            {item.name}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                                {
                                    Boolean(notCompletedScenarios.length) &&
                                    <div>
                                        <Text
                                            level="3"
                                            medium
                                        >
                                            Не пройдены
                                        </Text>
                                        {
                                            notCompletedScenarios.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="flex align-items-center"
                                                    >
                                                        {renderCheckIcon(false)}
                                                        <Text className="ms-2">
                                                            {item.name}
                                                        </Text>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    />
                }
            </div>
        );
    };

    const getRowMediaControls = (item) => {
        const {
            frameContractStatus,
            frameContractDownloadLink,
            frameContractName,
        } = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            downloadFile(frameContractDownloadLink, frameContractName);
                        },
                        children: "Скачать РД",
                    },
                    visible: Boolean(frameContractDownloadLink),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            resubmitFrameContract(item);
                        },
                        children: "Отправить договор повторно",
                    },
                    visible: frameContractStatus === ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS.DECLINED.VALUE,
                },
            ],
        };
    };

    const mapTableData = () => {
        const rows = list.map((item, index) => {
            const {
                contractorId,
                contractorFirstName,
                contractorLastName,
                contractorPatronymic,
                contractorPhone,
                registrationStatusState,
                taxStatusState,
                taxOfferRightsState,
                otherDocumentStatus,
                contractorCitizenshipDescription,
                contractorCitizenshipMatchScenarioRules,
            } = item;

            const trackedAdditionalDocuments = Object.keys(contractorParams?.additionalDocumentParams || {}).filter(item => contractorParams?.additionalDocumentParams[item]?.tracked);

            return {
                ...item,
                rowClass: "align-items-baseline",
                fullName: renderContractorLink(getFullName(contractorLastName, contractorFirstName, contractorPatronymic), contractorId),
                source: renderSource(item),
                contractorPhone: phoneFormat(contractorPhone),
                citizenship: contractorCitizenshipDescription && <div className="flex">
                    {contractorCitizenshipDescription}
                    {
                        !contractorCitizenshipMatchScenarioRules &&
                        <HelpTooltip
                            className="ml-2"
                            attention
                            position="top"
                            children="Гражданство не соответствует сценарию"
                        />
                    }
                </div>,
                registrationStatusState: <NmCheck isCheck={registrationStatusState === ONBOARDING_TAX_STATUS.PRESENT} />,
                taxOfferRightsState: <NmCheck isCheck={taxOfferRightsState === ONBOARDING_TAX_STATUS.PRESENT} />,
                taxStatusState: <NmCheck isCheck={taxStatusState === ONBOARDING_TAX_STATUS.PRESENT} />,
                frameContractStatus: documentParams?.offerContractToSign ? renderFrameContractStatus(item, index) : "-",
                additionalDocumentsStatus: trackedAdditionalDocuments.length ?
                    renderAdditionalDocumentsStatus(item) : "-",
                otherDocumentStatus: documentParams?.offerOtherDocumentToSign ? <NmBadge
                    noWrap
                    mod={ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS[otherDocumentStatus]?.MOD}
                    text={ONBOARDING_SCENARIO_CONTRACTOR_CONTRACT_STATUS[otherDocumentStatus]?.TEXT}
                /> : "-",
                scenarioStatuses: renderScenarioStatuses(item),
                action: <MediaButtons
                    inline={true}
                    config={getRowMediaControls(item)}
                />,
            };
        });

        return {
            rows,
            headers,
        };
    };

    const getMediaControls = () => {
        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "xl",
                        onClick: exportContactorsList,
                        children: "Выгрузить список",
                        disabled: totalCount < 1 || progressAction,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: refreshContactorsStatusesList,
                        children: "Обновить статусы исполнителей",
                        color: "grey",
                        icon: <ResetIcon />,
                        disabled: totalCount < 1 || progressAction,
                    },
                    settings: {
                        exception: true,
                    },
                },
            ],
        };
    };

    return (
        <NmPage
            noPadding
            className={block()}
            header={
                <NmPageHeader
                    text="Исполнители"
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={(form) => submitFilter(form, true)}
                    clearFilter={() => submitFilter(initFilter, true)}
                />
            }
            mediaControls={getMediaControls()}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {
                totalCount > 0 ?
                    <TableDiv
                        isOverflowX={true}
                        className={element("table")}
                        handleChangeSearch={handleChangeSearch}
                        tableData={mapTableData()}
                    /> :
                    <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default OnboardingScenarioContractorList;