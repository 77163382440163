export const CLIENT_USER_RESTRICTIONS_VARIABLE = {
    editDepositNpd: "editDepositNpd", //Возможность редактирования депозита НПД
    editDepositNdfl: "editDepositNdfl", //Возможность редактирования депозита НДФЛ
    accessCompanySettings: "accessCompanySettings", //Настройка параметров компании
    settingAutoSendPayment: "settingAutoSendPayment", //Настройка автоотправки платежей в банк
    addFavoritesClient: "addFavoritesClient", //Добавление/Удаление из избранного компании
    accessClientLog: "accessClientLog", //Просмотр лога изменений настроек компании
    addClient: "addClient", //Добавление компании
    accessClientArchive: "accessClientArchive", //Архивирование компании
    accessOperatorReports: "accessOperatorReports", //Доступ к подразделу Отчеты оператора
    actionsOperatorReports: "actionsOperatorReports", //Редактирование отчётов оператора
    exportOperatorReportsDocx: "exportOperatorReportsDocx", //Скачивание отчётов оператора в docx
    accessBank: "accessBank", //Доступ к подразделу Финансы > Банк
    accessOrders: "accessOrders", //Доступ к подразделу Финансы > Заказы
    editInterestRateNpd: "editInterestRateNpd", //Редактирование процентной ставки комиссии компании по выплатам НПД
    depositNpdRefill: "depositNpdRefill", //Пополнение депозита компании по выплатам НПД
    depositNpdRefund: "depositNpdRefund", //Возврат депозита компании по выплатам НПД
    getAmountBlocks: "getAmountBlocks", //Отображение блоков с суммами на вкладках с оплатами и заказами
    editInterestRateNdfl: "editInterestRateNdfl", //Редактирование процентной ставки комиссии компании по выплатам НДФЛ
    depositNdflRefill: "depositNdflRefill", //Пополнение депозита компании по выплатам НДФЛ
    depositNdflRefund: "depositNdflRefund", //Возврат депозита компании по выплатам НДФЛ
    paymentPatentCancel: "paymentPatentCancel", //Отмена оплаты патента со статусом "Платеж не выполнен"
    updateStatusBankPatentPayment: "updateStatusBankPatentPayment", //Обновление статуса патента из банка, просмотр лога взаимодействия с банком
    accessCanceledChecksFts: "accessCanceledChecksFts", //Доступ к подразделу Аннулированные чеки ФНС России
    uploadCanceledChecks: "uploadCanceledChecks", //Массовая выгрузка аннулированных чеков по компании
    accessCancelledReceipts: "accessCancelledReceipts", //Доступ к подразделу Аннулированные чеки
    unloadFinancesCancelledReceipts: "unloadFinancesCancelledReceipts", //Выгрузка списка аннулированных чеков ФНС
    accessSystemTransactions: "accessSystemTransactions", //Доступ к подразделу Финансы > Системные транзакции
    paymentNpdSolution: "paymentNpdSolution", //Подтверждение/Отклонение оплат НПД
    actionCompanyNpdPayments: "actionCompanyNpdPayments", //Подтверждение/Отклонение оплат НПД компании
    verificationCompanyNpdPayment: "verificationCompanyNpdPayment", //Проверка оплаты в статусе "На подтверждении заказчиком" в компании
    verificationCompanyNdflPayment: "verificationCompanyNdflPayment", //Проверка оплаты в статусе "На подтверждении заказчиком" в компании
    actionsOrderPayments: "actionsOrderPayments", //Действия с оплатами НПД
    paymentNpdReceipt: "paymentNpdReceipt", //Создание и отправка Offline-чеков об оплате НПД
    accessRecruitmentAdmin: "accessRecruitmentAdmin", //ограничение на доступ к рекрутменту для юзеров наймикса
    accessClientCrowdTasks: "accessClientCrowdTasks", //Доступ к разделу "Задания"
    accessFinancesExport: "accessFinancesExport", //Доступ к вкладке Отчеты компании
    actionsClient1c: "actionsClient1c", //Действия для выгрузки отчёта в 1С
    accessDevClient1c: "accessDevClient1c", //Отображение раздела обработчиков для 1С
    accessCrowdTasks: "accessCrowdTasks", //Доступ к подразделу Финансы > Задания
    addCrowdPayments: "addCrowdPayments", //Формирование актов по заданию
    accessCrowdTaskChat: "accessCrowdTaskChat", //Доступ к чатам по заданиям
    payCrowdRegistry: "payCrowdRegistry", //Запуск и повтор строк реестра актов по заданиям
    actionsCrowdTasksList: "actionsCrowdTasksList", //Действия на списковой форме заданий: создание, завершение, архив, удаление. публикация, продление периода
    actionsCrowdTasksCard: "actionsCrowdTasksCard", //Действия в карточке задания: завершение, приглашение, дубликат, продление, проверка работы
    actionsCrowdTaskGroups: "actionsCrowdTaskGroups", //Действия на списковой форме группы заданий: добавление, редактирование, удаление, архив
    actionsCrowdTaskOneGroup: "actionsCrowdTaskOneGroup", //Действия в карточке группы заданий
    blockCrowdTaskChat: "blockCrowdTaskChat", //Блокировка/Разблокировка исполнителя в чате по заданиям
    actionsCrowdActRegistries: "actionsCrowdActRegistries", //Действия в реестре актов по заданиям
    actionsCrowdActRegistryItem: "actionsCrowdActRegistryItem", //Действия в карточке реестра актов по заданиям
    accessCrowdTaskAnalytics: "accessCrowdTaskAnalytics", //Доступ к вкладке "Аналитика по заданиям"
    repeatFailedCrowdPayments: "repeatFailedCrowdPayments", //Массовый повтор проверок оплат по заданиям в статусе "Проверка не пройдена"
    actionsCrowdPayments: "actionsCrowdPayments", //Работа с оплатами по заданиям
    actionsCrowdPaymentsInfo: "actionsCrowdPaymentsInfo", //Действия с информацией по оплата по заданиям: чек, акт, заявка, РД
    getActFileOrOrderApplicationFileOrFrameContractFile: "getActFileOrOrderApplicationFileOrFrameContractFile", //Просмотр акта ,заявки, РД (НПД)
    getNdflActFileOrOrderApplicationFileOrFrameContractFile: "getNdflActFileOrOrderApplicationFileOrFrameContractFile", //Просмотр акта ,заявки, РД (НДФЛ)
    paymentNpdReceiptCancel: "paymentNpdReceiptCancel", //Аннулирование чеков ФНС об оплате НПД
    paymentNdflSolution: "paymentNdflSolution", //Подтверждение/Отклонение оплат НДФЛ
    actionCompanyNdflPayments: "actionCompanyNdflPayments", //Подтверждение/Отклонение оплат НДФЛ компании
    actionsNdflOrderPayments: "actionsNdflOrderPayments", //Действия с оплатами НДФЛ
    updateNdflPaymentsStatus: "updateNdflPaymentsStatus", //Обновление статусов платежей НДФЛ из банка
    actionsOrderWorkReport: "actionsOrderWorkReport", //Действия с оплатами НПД: Обновить статусы платежей из банка, Сформировать Атол чек
    accessReconciliationReports: "accessReconciliationReports", //Доступ к подразделу Акты сверки
    actionsReconciliationReports: "actionsReconciliationReports", //Действия с формированием и удалением актов сверки
    deleteReportMutualSettlementsReconciliationAct: "deleteReportMutualSettlementsReconciliationAct", //Удаление отчета
    exportReconciliationReports: "exportReconciliationReports", //Скачивание акт сверки в формате pdf или xlsx
    closeRequestCrm: "closeRequestCrm", //	Закрытие обращения в разделе  CRM КЦ, если пользоваться не является ответственным
    editParamSystemTickets: "editParamSystemTickets", //Изменение параметра "Системные тикеты"
    activateSystemTickets: "activateSystemTickets", // Применение настроек системных тикетов
    accessRegisterPayments: "accessRegisterPayments", //Доступ к подразделу Реестровые выплаты
    changesRegisterPayments: "changesRegisterPayments", //Редактирование/Удаление/Перемещение в архив/Восстановление из архива/ Отмена невыполненных строк/Оплата невыполненных строк/ Создание дубликата реестра
    getRegisterPaymentsLog: "getRegisterPaymentsLog", //Просмотр лога по реестру оплат
    paymentRegisterPayments: "paymentRegisterPayments", //Передача в оплату реестра платежей
    creatDuplicateRegistry: "creatDuplicateRegistry", // Создание дубликата реестра выплат
    payRegistry: "payRegistry", // Передача реестра в оплату, повтор невыполненных, повтор платежа
    actionsPayRegistryItem: "actionsPayRegistryItem", // Действия в карточке в реестре оплат: удаление, редактирование строк, скачивание документов
    addEditDelPayRegistries: "addEditDelPayRegistries", // Действия с реестрами оплат: добавить, редактировать, удалить
    addPayRegistryContractors: "addPayRegistryContractors", // Импорт исполнителей в карточку реестра
    unloadRegisterPaymentsDocuments: "unloadRegisterPaymentsDocuments", // Выгрузка документов реестра в карточке реестра оплат
    updateRegisterPaymentsPersonalInfo: "updateRegisterPaymentsPersonalInfo", // Обновление данных исполнителей и их платежных данных
    getPayRegistriesLog: "getPayRegistriesLog", // Просмотр лога по реестру оплат
    changeTickets: "changeTickets", //Взятие обращение в работу/изменение параметров/перевод на другого сотрудника
    addTicketsComment: "addTicketsComment", //Просмотр и добавление комментария к обращению
    editTicketsParameters: "editTicketsParameters", //Редактирование пар-ров обращения
    callInMango: "callInMango", //Совершение звонков через Наймикс в Манго
    getCivilReceipt: "getCivilReceipt", //Просмотр квитанций НДФЛ
    getNpdReceipt: "getNpdReceipt", //Просмотр квитанций НПД
    createReceiptAtolOrderPayments: "createReceiptAtolOrderPayments", //Формирование АТОЛ чека
    getPaymentTransactionLogs: "getPaymentTransactionLogs", //Просмотр лога транзакций по платежу (НПД, НДФЛ)
    getSmzPaymentDocuments: "getSmzPaymentDocuments", //Просмотр РД/Заявки к РД/Акта выполненных работ/чека ФНС_НПД
    getCivilPaymentDocuments: "getCivilPaymentDocuments", //Просмотр РД/Заявки к РД/Акта выполненных работ_НДФЛ
    accessAllDepositSMZ: "accessAllDepositSMZ", //Доступ к разделу Депозит (НПД)
    accessAllDepositCivil: "accessAllDepositCivil", //Доступ к разделу Депозит (НДФЛ)
    manageSubscriptionAdmin: "manageSubscriptionAdmin", //Управление подписками пользователя (Административная часть)
    addVacanciesAdmin: "addVacanciesAdmin", //Создание вакансий вручную (Административная часть)
    accessManageVacanciesAdmin: "accessManageVacanciesAdmin", //Действия с вакансиями (Административная часть)
    addCommentAdmin: "addCommentAdmin", //Действия с вакансиями (Административная часть)
    manageCommentAdmin: "manageCommentAdmin", //Управление чужими комментариями (Административная часть)
    manageCandidatesAdmin: "manageCandidatesAdmin", //Управление кандидатами (Административная часть)
    accessResponsesAdmin: "accessResponsesAdmin", //Действия с откликами (Административная часть)
    manageDirectoriesAdmin: "manageDirectoriesAdmin", //Действия в меню Справочники (Административная часть)
    uploadTypePaymentsTransactionOr1c: "uploadTypePaymentsTransactionOr1c", //Выгрузка отчетов по оплатам _сист транзакции или 1с
    uploadTypePayments1cSmzOrCivil: "uploadTypePayments1cSmzOrCivil", //Выгрузка отчетов по оплатам _1с НПД и НДФЛ
    addExportTypePaymentChecksLeads: "addExportTypePaymentChecksLeads", //Выгрузка отчётов: Оплаты, чеки, 1с, лиды
    accessSmartLink: "accessSmartLink", //Доступ к подразделу Конструктор ссылок
    changePromotion: "changePromotion", //Управление каналами привлечения
    accessDocumentsExport: "accessDocumentsExport", //Доступ к подразделу Экспорт документов
    addExportPaymentType: "addExportPaymentType", //Выгрузка отчётов по оплатам
    addExportTypePaidApis: "addExportTypePaidApis", //Выгрузка отчета "Тарифицируемые вызовы API" - админка
    uploadPaidApis: "uploadPaidApis", //Выгрузка отчета "Тарифицируемые вызовы API" - через карточку компании
    startResourceAnalysis: "startResourceAnalysis", //Запуск анализа ресурсов компании
    accessClientRemote: "accessClientRemote", //Отображение подраздела "Исполнители заказчиков"
    editSettingBankRequisites: "editSettingBankRequisites", //Добавление/изменение/удаление условий сбора банковских реквизитов
    accessAdmTaskList: "accessAdmTaskList", //Доступ к подразделу список задач
    accessSettingCorrectionDocument: "accessSettingCorrectionDocument", //Доступ к вкладке "Исправление документов"
    accessClientProjectsAndObjects: "accessClientProjectsAndObjects", //Доступ к объектам, проектам компании
    getObjectsDepositHistory: "getObjectsDepositHistory", // Просмотр истории изменения депозита объекта
    accessClientTabs: "accessClientTabs", //Доступ к разделам компании
    clientPropertiesCommissionAndLimitsAndProjectParams: "clientPropertiesCommissionAndLimitsAndProjectParams", //Редактирование блока "Комиссия и лимиты", "Параметры проекта"
    editClientProperties: "editClientProperties", //Редактирование блоков информации о компании: реквизиты, тарифы, категория, промо, пар-ры компании
    accessBrandForAnalytics: "accessBrandForAnalytics", //Отображение блока "Бренд для аналитики"
    editBrandForAnalytics: "editBrandForAnalytics", //Редактирование блока "Бренд для аналитики"
    checkDadataClientInfo: "checkDadataClientInfo", //Проверка данных компании
    accessContractorsList: "accessContractorsList", //Доступ к разделу Исполнители
    accessRetail: "accessRetail", //Доступ к подразделу Ресурсы
    addContractors: "addContractors", //Добавление исполнителя через UI/файл
    accessClients: "accessClients", //Доступ к разделу Компании
    accessClientBrands: "accessClientBrands", //Доступ к брендам компании
    accessClientChat: "accessClientChat", //Доступ к вкладке Чаты
    accessMarketing: "accessMarketing", //Доступ к разделу Маркетинг
    accessAtolReceiptsExport: "accessAtolReceiptsExport", //Доступ к вкладке "1С /Выгрузка онлайн-чеков Атол"
    addContractorPushNotification: "addContractorPushNotification", //Создание рассылки
    changeContractorPushNotification: "changeContractorPushNotification", //Действия над рассылками
    accessContractorsPromotion: "accessContractorsPromotion", //Доступ к разделу Каналы привлечения
    accessPromoSection: "accessPromoSection", //Просмотр блоков: "Промо-код для исполнителей", "Промо-код для компании"
    accessMap: "accessMap", // Доступ к разделу "Карта"
    accessCrmTicket: "accessCrmTicket", // Доступ к разделу CRM КЦ
    accessAllExport: "accessAllExport", // Доступ к разделу  "Экспорт"
    accessReportExport: "accessReportExport", // Доступ к разделу Экспорт Отчетов
    accessAllSettings: "accessAllSettings", // Доступ к разделу Настройки
    accessSettingsTabs: "accessSettingsTabs", // Доступ к вкладкам раздела "Настройки": Сообщения техподдержки, Ролевая модель, Реквизиты, Инструкции, Группы компаний, Сбор банковских реквизитов
    editContractorProjectParam: "editContractorProjectParam", //Редактирование ProjectParam у исполнителя
    updateContractorProjectParams: "updateContractorProjectParams", //Редактирование ProjectParam и банковских реквизитов
    addNotes: "addNotes", //Работа с заметками по исполнителю
    checkStatusSmz: "checkStatusSmz", //Проверка статуса НПД, партнерства
    getContractorCardTopInfo: "getContractorCardTopInfo", //Отображение информации в шапке карточки исполнителя
    updateProfileInfo: "updateProfileInfo", //Добавление информации об исполнителе на вкладке "Профиль"
    sendBankPersonalData: "sendBankPersonalData", //Отправка ПД/сканов исполнителя в банк на вкладке "Банки"
    updateContractors: "updateContractors", //Редактирование контактных данных исполнителя на вкладке "Персональные данные"
    removeCheckContractors: "removeCheckContractors", //Снятие с проверки на вкладке "Персональные данные"
    blockContractors: "blockContractors", //Блокировка/разблокировка исполнителя в карточке Исполнителя
    accessProfileBlocks: "accessProfileBlocks", //Отображение вкладок в карточке исполнителя: Профиль, история заказов, заданий, документы, финансы, ЖС, обращения
    updateContractorDocuments: "updateContractorDocuments", //Обновление документов на вкладке "Документы" на карточке исполнителя
    setContractorSmzTaxOffer: "setContractorSmzTaxOffer", //Включение/выключение автоматической уплаты налогов для исполнителя на вкладке Финансы - Баланс
    deleteContractorScores: "deleteContractorScores", //Возможность удаления отзывов исполнителя
    archivingContractor: "archivingContractor", //Архивирование учетной записи исполнителя
    needBankDetails: "needBankDetails", //Изменение обязательности сбора банковских реквизитов
    cancelVerificationContractor: "cancelVerificationContractor", //Снятие с проверки заявки ПД исполнителя
    accessFinanceBlocks: "accessFinanceBlocks", // Доступ ко всей группе раздела Финансы
    accessIndividualPayments: "accessIndividualPayments", // Доступ к странице Оплаты (ИП)
    actionsIndividualPayments: "actionsIndividualPayments", // Действия с оплатами ИП
    actionsIndividualPaymentDocs: "actionsIndividualPaymentDocs", // Просмотр документов, квитанций по оплатам ИП
    accessClientTickets: "accessClientTickets", // Доступ к вкладке Обращения
    accessContractorTickets: "accessContractorTickets", // Отображение вкладки "Обращения" на карточке исполнителя
    accessFtsService: "accessFtsService", // Доступ к вкладке "ФНС России сервис" раздела "Настройки"
    accessRoleModel: "accessRoleModel", // Доступ к вкладке "Ролевая модель"
    accessSettingsService: "accessSettingsService", // Доступ к вкладке "Сервис" раздела "Настройки"
    editUserInstructions: "editUserInstructions", // Изменение инструкций
    accessSettingInstructionsKedo: "accessSettingInstructionsKedo", // Доступ к вкладке "Инструкции для пользователей Клиентской части / КЭДО"
    editSystemSettings: "editSystemSettings", // Изменение сервисных настроек на вкладке "Сервис" раздела "Настройки"
    editSettingDirectory: "editSettingDirectory", // Управление справочниками
    editSystemRequisites: "editSystemRequisites", // Изменение реквизитов системы
    editSettingClientGroups: "editSettingClientGroups", // Управление группой компаний
    editClientGroupUsersSetting: "editClientGroupUsersSetting", // Доступ к настройкам сотрудников в группе компаний
    deleteEmptyAccounts: "deleteEmptyAccounts", // Удаление пустых учетных записей
    editExpasoftCalling: "editExpasoftCalling", // Включение обзвона Expasoft
    accessRequestConnection: "accessRequestConnection", // Доступ к вкладке "Заявки на подключение компаний" раздела "Настройки"
    accessSettingsEmployees: "accessSettingsEmployees", // Доступ к вкладке "Сотрудники" раздела "Настройки"
    accessEmployees: "accessEmployees", // Действия на вкладке "Сотрудники" раздела "Настройки"
    accessSettingsMachineLearning: "accessSettingsMachineLearning", // Доступ к вкладке Настройки > Машинное обучение
    editStatusRequest: "editStatusRequest", // Изменение статуса обращения
    accessAdmAllTaskList: "accessAdmAllTaskList", // Отображение подраздела "Все задачи"
    actionsClientObjectBrand: "actionsClientObjectBrand", // Действия с брендами компании
    confirmPaymentForemans: "confirmPaymentForemans", // Подтверждение и отклонение выплаты бригадиру
    confirmObjectApplicationForemans: "confirmObjectApplicationForemans", // Подтверждение/отклонение заявок с объектов
    setForemans: "setForemans", // Назначение/снятие с бригадира
    accessReportsSummary: "accessReportsSummary", // Доступ к подразделу Финансы > Сводная НПД/НДФЛ/по патентам
    accessReviews: "accessReviews", // Доступ к разделу  "Отзывы"
    actionsReviews: "actionsReviews", // подтверждение и отклонение отзывов
    actionsClientScores: "actionsClientScores", // Действия над отзывами
    submitClientComplaints: "submitClientComplaints", // Подача жалобы на отзыв
    createOrder: "createOrder", // Создание заказов в реестре заказов
    accessMassActionOrder: "accessMassActionOrder", // Действия над списком заказов
    publishOrder: "publishOrder", // Действия над списком заказов
    editPublishCloseOrder: "editPublishCloseOrder", // Редактирование, завершение подбора, завершение заказа
    accessDisputeMessage: "accessDisputeMessage", // Переход в чат спора по заказу
    actionsTemplateOrders: "actionsTemplateOrders", // Действия над шаблоном заказов
    addGroupOrder: "addGroupOrder", // Добавление группы заказов
    changeGroupOrder: "changeGroupOrder", // Действия над группой заказов
    editClientDepositHistory: "editClientDepositHistory", // Редактирование суммы для операций "Возврат денежных средств" и "Пополнение депозита" НПД
    editCivilClientDepositHistory: "editCivilClientDepositHistory", // Редактирование суммы для операций "Возврат денежных средств" и "Пополнение депозита" НДФЛ
    addCommentDepositSmz: "addCommentDepositSmz", // Добавление/редактирование/удаление комментария к операции пополнения/возврата депозита
    accessClientForemans: "accessClientForemans", // Доступ ко вкладке Бригадиры
    accessKedo: "accessKedo", // Доступ к модулю КЭДО
    accessEmployeesKedo: "accessEmployeesKedo", // Доступ к разделу "Сотрудники КЭДО"
    actionsAccountEmployeesKedo: "actionsAccountEmployeesKedo", // Запрет действий с учетными записями сотрудников КЭДО
    accessDocumentFlowKedo: "accessDocumentFlowKedo", // Доступ к разделу "Документооборот КЭДО"
    actionsDocumentKedo: "actionsDocumentKedo", // Запрет действий с документами КЭДО
    accessLog1cKedo: "accessLog1cKedo", // Доступ к разделу "Лог интеграции с 1С"
    actionsSettingInstructionsKedo: "actionsSettingInstructionsKedo", // Запрет действий с инструкциями КЭДО
    accessTaskListKedo: "accessTaskListKedo", // Доступ к разделу "Список задач КЭДО"
    accessCrmCcKedo: "accessCrmCcKedo", // Доступ к разделу "CRM КЦ КЭДО"
    accessTemplateKedo: "accessTemplateKedo", // Доступ к разделу "Шаблоны документов КЭДО"
    actionsTemplatesKedo: "actionsTemplatesKedo", // Запрет действий с шаблонами документов КЭДО
    accessCatalogKedo: "accessCatalogKedo", // Доступ к разделу "Справочники КЭДО"
    actionsDirectoriesTemplateDocTypeKedo: "actionsDirectoriesTemplateDocTypeKedo", // Запрет действий со справочником типов шаблонных документов КЭДО
    actionsDirectoriesUploadedDocTypeKedo: "actionsDirectoriesUploadedDocTypeKedo", // Запрет действий со справочником типов загруженных документов КЭДО
    actionsPositionsKedo: "actionsPositionsKedo", // Запрет действий со справочником должностей КЭДО
    actionsSubdivisionsKedo: "actionsSubdivisionsKedo", // Запрет действий со справочником структурных подразделений КЭДО
    actionsDocumentSystemTypesKedo: "actionsDocumentSystemTypesKedo", // Запрет действий с системными типами загруженных документов КЭДО
    actionsRouteForDocumentTypesKedo: "actionsRouteForDocumentTypesKedo", // Запрет действий с системными типами загруженных документов КЭДО
    accessAdvertisement: "accessAdvertisement", // Доступ к разделу "Объявления"
    actionsAdvertisement: "actionsAdvertisement", // Работа с объявлениями
    accessClientEmailNotifications: "accessClientEmailNotifications", // Доступ к вкладке Уведомления
    actionsClientEmailNotifications: "actionsClientEmailNotifications", // Доступ к действиям настроек рассылки на почту
    addOrderInvitationsRegistry: "addOrderInvitationsRegistry", // Добавление реестра приглашений
    changeOrderInvitationsRegistry: "changeOrderInvitationsRegistry", //  Действия с реестром приглашений: Редактировать, удалить, в архив
    runOrderInvitationsRegistry: "runOrderInvitationsRegistry", // Передача в работу реестра приглашений
    changeOrderInvitationsRegistryItem: "changeOrderInvitationsRegistryItem", // Единичная передача, удаление и редактирование строк из реестра
    unloadOrderInvitationsRegistry: "unloadOrderInvitationsRegistry", // Выгрузка реестра приглашений
    addOrderInvitationsRegistryContractors: "addOrderInvitationsRegistryContractors", // Импорт исполнителей в Наймикс
    addActRegistry: "addActRegistry", // Действия над реестром актов
    runActRegistry: "runActRegistry", // Отправка реестра актов
    unloadActRegistry: "unloadActRegistry", // Выгрузка реестра актов
    changeActRegistryItem: "changeActRegistryItem", // Действия над строками реестра актов
    addActRegistryContractors: "addActRegistryContractors", // Импорт исполнителей в Наймикс
    actionsWithClientsUser: "actionsWithClientsUser", // Создание/редактирование/архивирование/восстановление сотрудника
    actionsOnboardingScenario: "actionsOnboardingScenario", // Действия над сценарием онбординга
    addOnboardingScenarioRecruiters: "addOnboardingScenarioRecruiters", // Добавление рекрутеров в сценарий онбординга
    unloadOnboardingScenarioRecruiters: "unloadOnboardingScenarioRecruiters", // Выгрузка списка рекрутеров в сценарии онбординга
    actionsOnboardingScenarioLeads: "actionsOnboardingScenarioLeads", // Загрузка лидов из эксель-файла в сценарии онбординга
    accessMassActionGroupOrder: "accessMassActionGroupOrder", // Действия над заказами в группе заказов: загрузка списка, выгрузка списка, создание заказа, добавление заказа, удаление и закрытие
    editOrderInGroup: "editOrderInGroup", // Действия над заказом в группе заказов: редактирование заказа, завершение заказа, переход в чат
    accessOrderTemplates: "accessOrderTemplates", // Доступ к вкладке Шаблоны заказов
    actionsClientObjects: "actionsClientObjects", // Действия в объектах из списка
    actionsClientProjects: "actionsClientProjects", // Действия в проектах из списка
    actionsClientsUserBrigade: "actionsClientsUserBrigade", // Добавление/удаление сотрудника Карточка объекта - вкладка Команда
    delObjectContractors: "delObjectContractors", // Удаление исполнителя из списка в карточке объекта
    accessPatentsTabs: "accessPatentsTabs", // Доступ к вкладкам раздела "Патенты": Депозит по патентам, Документы по патентам, Экспорт
    actionsPatentsRegistry: "actionsPatentsRegistry", // Действия для реестра оплаты по патентам
    actionsPatentsRegistryItem: "actionsPatentsRegistryItem", // Действия для строк реестра оплаты по патентам
    unloadRegisterPatentsDocuments: "unloadRegisterPatentsDocuments", // Выгрузка документов в реестре патентов
    refreshPatentPaymentsUsingHandler: "refreshPatentPaymentsUsingHandler", // Обновление платежных данных в реестре патентов
    payPatentsRegistry: "payPatentsRegistry", // Передача реестра в оплату, отмена(удаление)невыполненных строк,  оплата невыполненных строк реестра
    accessClientDocuments: "accessClientDocuments", // Доступ к вкладке Документы компании
    actionsWorkNotify: "actionsWorkNotify", // Работа с уведомлениями ОВМ:  в архив, из архива, редактировать
    unloadOperatorReportDocx: "unloadOperatorReportDocx", // Скачивание отчета в DOCX
    signClientsOperatorReport: "signClientsOperatorReport", // Подписание отчета в статусе "На подписании у заказчика
    actionsClientsContractHistory: "actionsClientsContractHistory", // Работа с агентским договором
    accessCustomDocuments: "accessCustomDocuments", // Доступ к разделу Шаблоны документов
    terminateFrameContract: "terminateFrameContract", // Расторжение договора в реестре документов
    actionsFrameContractRegistry: "actionsFrameContractRegistry", // Управление реестром рамочных договоров
    actionsFrameContractRegistryItems: "actionsFrameContractRegistryItems", // Действия над строками реестра РД с исполнителями
    deleteLead: "deleteLead", // Удаление лидов
    invitationContractors: "invitationContractors", // Приглашение исполнителей
    downloadContractors: "downloadContractors", // Загрузка исполнителей
    delBlackListContractors: "delBlackListContractors", // Массовое удаление из чёрного списка
    actionsFavouriteContractors: "actionsFavouriteContractors", // Действия с реестром исполнителей на вкладке "Избранное"
    refusalInviteAndSecurePositionOrderContactors: "refusalInviteAndSecurePositionOrderContactors", // Массовый отзыв приглашений и закрепление/открепление исполнителей
    actionsContractorIndividual: "actionsContractorIndividual", // Действия в блоке "Статус ИП": запросить статус, редактировать, удалить
    addOrderWorkReport: "addOrderWorkReport", // Формирование акта
    actionsOrderAnalytics: "actionsOrderAnalytics", // Действия в аналитике по заказам
    closeInAnalyticsOrder: "closeInAnalyticsOrder", // Завершение заказа из аналитики по заказам
    accessStopListContractors: "accessStopListContractors", // Доступ к вкладке Стоп-лист
    accessRetailContractors: "accessRetailContractors", // Доступ к вкладке Ресурсы
    accessCompanyContractors: "accessCompanyContractors", // Доступ к вкладке Мои исполнители
    accessDisputes: "accessDisputes", //  Доступ к разделу  "Споры"
    closeDispute: "closeDispute", // Закрывать спор
    inviteEmployeesToDispute: "inviteEmployeesToDispute", // приглашать сотрудников компании в спор
    addClientDispute: "addClientDispute", // Создание споров
    actionsClientDispute: "actionsClientDispute", // Доступ к действиям над спором
    accessBlockedClient: "accessBlockedClient", // Запрет на просмотр заблокированных заказчиков
    accessEdo: "accessEdo", // Доступ к вкладке ЭДО компании
    editEdoDirectory: "editEdoDirectory", // Создание/редактирование/удаление должности/структурного подразделения в ЭДО-Кадры
    actionsEdoStaffKn: "actionsEdoStaffKn", // Работа с кадрами в ЭДО
    actionsEdoRegistries: "actionsEdoRegistries", // Действия c реестром на списковой форме реестров ЭДО: добавление, редактирование, архив, удаление
    actionsEdoRegistryItem: "actionsEdoRegistryItem", // Действия в карточке реестра ЭДО: удаление, добавление, редактирование, отмена, удаление
    accessOrderTimeLog: "accessOrderTimeLog", // Доступ к Журналу учета времени
    loginInTicketsTab: "loginInTicketsTab", // Переход в раздел "Список обращений" при логине
    loginInContractorsTab: "loginInContractorsTab", // Переход в раздел "Исполнители" при логине
    loginInReportsTab: "loginInReportsTab", // Переход в раздел "Экспорт отчетов" при логине
    accessOrgStructureKedo : "accessOrgStructureKedo", // Доступ к разделу "Организационная структура КЭДО"
    actionsSystemRouteTypeKedo: "actionsSystemRouteTypeKedo", // Запрет действий с базовыми  маршрутами подписания
    actionsOrgStructureKedo: "actionsOrgStructureKedo", // Действия в Организационной структуре КЭДО для АЧ
    actionsUserRouteTypeKedo: "actionsUserRouteTypeKedo", // Запрет действий с пользовательскими  маршрутами подписания
    updateSettingDirectoryBankSbp: "updateSettingDirectoryBankSbp", // Обновление справочника "Банки для платежей по СБП"
    updateSettingDirectoryContractorWalletWithdrawAvailable: "updateSettingDirectoryContractorWalletWithdrawAvailable", // Разрешение вывода денежных средств с кошельков в справочнике банков
    updateSettingDirectoryPhoneCode: "updateSettingDirectoryPhoneCode", // Разрешение звонков через Flash Call
    editSettingDirectoryCrowd: "editSettingDirectoryCrowd", // Редактирование комиссий по заданиям в справочнике "Комиссия за вывод средств"
    accessContractorNoteForClient: "accessContractorNoteForClient", // Отображение примечания в карточке исполнителя
    actionsContractorNoteForClient: "actionsContractorNoteForClient", // Создание/редактирование/удаление примечания в карточке исполнителя
    accessSettingsServiceTaskStats: "accessSettingsServiceTaskStats", // Доступ к вкладке Настройки → Сервис → График исполнения джобов
    createDuplicateOrder: "createDuplicateOrder", // Создание дубликата заказа
    hideResponsesOrder: "hideResponsesOrder", // Скрытие заказа для откликов
    searchOrderContractor: "searchOrderContractor", // Поиск исполнителей на заказ
    completeOrderByContractor: "completeOrderByContractor", // Завершение заказа из карточки заказа по исполнителю
    actionsInvitationOrder: "actionsInvitationOrder", // Работа с исполнителями на заказе: Отозвать приглашение, одобрить/отказать, ведение учета времени
    actionsTimeLog: "actionsTimeLog", // Работа с Журналом учета времени
    getClientDepositHistoryBank: "getClientDepositHistoryBank", // Отображение данных из банка о пополнении и возврате депозита НПД, НДФЛ компании
    accessPostActRegistry: "accessPostActRegistry", // Доступ ко вкладке "Реестры отложенных актов"
    actionPostActRegistry: "actionPostActRegistry", // Передача на подписание, редактирование, архивирование, восстановление из архива, повтор и отмена невыполненного реестра
    uploadPostActRegistry: "uploadPostActRegistry", // Выгрузка реестра
    actionPostActRegistryItem: "actionPostActRegistryItem", // Редактирование и удаление строк реестра
    createPostActRegistry: "createPostActRegistry", // Формирование реестра отложенных актов из реестра оплат (кнопка контекстного меню Сформировать акты)
    accessSettingDirectorySpecialtyOffer: "accessSettingDirectorySpecialtyOffer", // Отображение вкладки  "Обновление справочников" в разделе Настройки - Справочники
    accessSettingsTabsService: "accessSettingsTabsService", // Доступ к вкладкам раздела "Настройки": Сервис, Технические работы
    accessRegisterAdditionalAgreements: "accessRegisterAdditionalAgreements", // Доступ к разделу Реестры на подписание доп. соглашений
    actionsRegisterAdditionalAgreements: "actionsRegisterAdditionalAgreements", // Запрет действий с реестрами доп. соглашений
    accessReCreateSubscription: "accessReCreateSubscription", // Доступ к управлению подпиской
    viewPostActRegistryLog: "viewPostActRegistryLog", // Просмотр логов реестра отложенных актов
    //Клиентские
    CLNT_accessClientScores: "CLNT_accessClientScores", // Доступ к подразделам  "Отзывы"
    CLNT_accessClientOrders: "CLNT_accessClientOrders", // Доступ к разделу Заказы
    CLNT_accessOrderSomeBlocks: "CLNT_accessOrderSomeBlocks", // Доступ к вкладкам "Группы заказов", "Реестр приглашений", "Реестр актов"
    CLNT_accessTemplateOrders: "CLNT_accessTemplateOrders", // Доступ к подразделу Шаблоны заказов
    CLNT_accessCrowdTasks: "CLNT_accessCrowdTasks", // Доступ к разделу "Задания"
    CLNT_accessAdvertisement: "CLNT_accessAdvertisement", // Доступ к разделу Объявления
    CLNT_editClientPropertiesSomeBlock: "CLNT_editClientPropertiesSomeBlock", // Редактирование блоков в информации о компании: Реквизиты, категория, параметры, руководитель и др
    CLNT_actionsProjectsAdd: "CLNT_actionsProjectsAdd", // Добавление объекта
    CLNT_actionsProjectsEdit: "CLNT_actionsProjectsEdit", // Действия на вкладке "Проекты" компании: , редактирование, открытие, закрытие
    CLNT_addClientObject: "CLNT_addClientObject", // Добавление объекта
    CLNT_editClientObject: "CLNT_editClientObject", // Редактирование параметров объекта
    CLNT_loadProjectsAndObjects: "CLNT_loadProjectsAndObjects", // Загрузка списка проектов и объектов
    CLNT_editClientDepositHistoryComment: "CLNT_editClientDepositHistoryComment", // Редактирование комментария по возврату/пополнению депозита
    CLNT_accessClientStopList: "CLNT_accessClientStopList", // Доступ к вкладке Стоп-листы
    CLNT_accessClient1c: "CLNT_accessClient1c", // Доступ к подразделу Выгрузка в 1С
    CLNT_accessClientProperty: "CLNT_accessClientProperty", // Доступ к подразделу "Информация"
    CLNT_accessClientsEmailNotifications: "CLNT_accessClientsEmailNotifications", // Доступ вкладки "Рассылки на почту"
    CLNT_accessClientBrands: "CLNT_accessClientBrands", // Доступ к подразделу "Бренды компании"
    CLNT_accessClientFinance: "CLNT_accessClientFinance", // Доступ к разделу Финансы
    CLNT_actionsContractorCardTopSome: "CLNT_actionsContractorCardTopSome", // Действия в шапке карточки исполнителя: избранное, пригласить, пожаловаться, в ЧС
    CLNT_accessDocumentMutualSettlementsReconciliationAct: "CLNT_accessDocumentMutualSettlementsReconciliationAct", // Доступ к вкладке "Акты сверки взаиморасчетов" из раздела "Документы"
    CLNT_accessDirectoriesClient: "CLNT_accessDirectoriesClient", // Рекрутмент - Доступ к меню Справочники
    CLNT_actionsClientsUsersAddEditArchive: "CLNT_actionsClientsUsersAddEditArchive", // Действия на вкладке "Сотрудники" компании: добавление, редактирование, архив
    CLNT_accessSettingAccessToChats: "CLNT_accessSettingAccessToChats", // Доступ к настройке "Настроить доступ в чаты заказов"
    CLNT_accessContractorBlockProfile: "CLNT_accessContractorBlockProfile", // Доступ к вкладке "Профиль"
    CLNT_accessClientsUsers: "CLNT_accessClientsUsers", // Доступ к подразделу Сотрудники
    CLNT_accessClientForemans: "CLNT_accessClientForemans", // Доступ к подразделам "Бригадиры"
    CLNT_editClientEmailNotifications: "CLNT_editClientEmailNotifications", // Работа с настройками рассылки на почту
    CLNT_accessClientExport: "CLNT_accessClientExport", // Доступ к разделу "Отчеты"
    CLNT_accessClientProjectsAndObjects: "CLNT_accessClientProjectsAndObjects", // Доступ к подразделам Проекты, Объекты
    CLNT_addOrDelProjectAndObjectUser: "CLNT_addOrDelProjectAndObjectUser", // Добавление, удаление сотрудников в команду проекта/объекта
    CLNT_addOrRefundDepositObjects: "CLNT_addOrRefundDepositObjects", // Пополнение/возврат депозита объектов
    CLNT_actionsClientProjects: "CLNT_actionsClientProjects", // Действия в проектах
    CLNT_actionsClientObjects: "CLNT_actionsClientObjects", // Действия в объектах
    CLNT_accessRecruitmentClient: "CLNT_accessRecruitmentClient", // Доступ к разделу рекрутмент (Клиентская часть)
    CLNT_addVacanciesClient: "CLNT_addVacanciesClient", // Создание вакансий вручную (Клиентская часть)
    CLNT_accessResponsesClient: "CLNT_accessResponsesClient", // Действия с откликами (Клиентская часть)
    CLNT_accessPatents: "CLNT_accessPatents", // Доступ к разделу Патенты
    CLNT_actionsDocumentWorkNotify: "CLNT_actionsDocumentWorkNotify", // Работа с уведомлениями ОВМ: подать, вернуть, скачать
    CLNT_editDocumentWorkNotify: "CLNT_editDocumentWorkNotify", // Редактирование уведомлений ОВМ
    CLNT_accessDocumentSomeBlock: "CLNT_accessDocumentSomeBlock", // Доступ к вкладкам из раздела "Документы": "Отчеты оператора", "Агентский договор", "Шаблоны документов"
    CLNT_accessPlatformCustomerDocuments: "CLNT_accessPlatformCustomerDocuments", // Доступ к вкладке "Типовые документы"
    CLNT_accessLogsClient: "CLNT_accessLogsClient", // Просмотр лога по вакансии (Клиентская часть)
    CLNT_accessResponsesForRecruiterClient: "CLNT_accessResponsesForRecruiterClient", // Действия с откликами для рекрутера (Клиентская часть)
    CLNT_accessContractorSomeBlocks: "CLNT_accessContractorSomeBlocks", // Доступ к вкладкам в карточке исполнителя "История заказов", "История заданий", "Документы"
    CLNT_accessContractorBlockScores: "CLNT_accessContractorBlockScores", // Доступ к вкладке "Отзывы"
    CLNT_accessClientDepositHistory: "CLNT_accessClientDepositHistory", // Доступ к разделу Депозит НПД/ Депозит НДФЛ
    CLNT_accessManageVacanciesClient: "CLNT_accessManageVacanciesClient", // Действия с вакансиями (Клиентская часть)
    CLNT_accessSetStatusCandidateClient: "CLNT_accessSetStatusCandidateClient", // Запрет на перемещение кандидата по воронке
    CLNT_editPropertiesSomeBlock: "CLNT_editPropertiesSomeBlock", // Редактирование блоков блоков на вкладке \"Информация\": Комиссия и лимиты, Основной параметр для реестров, Скрытие заказов компании
    CLNT_accessPromoContractors: "CLNT_accessPromoContractors", // Просмотр блока информации о компании "Промокод для исполнителей"
    CLNT_accessClientNotification: "CLNT_accessClientNotification", // Доступ к разделу Уведомления
    CLNT_manageSubscriptionClient: "CLNT_manageSubscriptionClient", // Управление подписками пользователя (Клиентская часть)
    CLNT_manageCandidatesClient: "CLNT_manageCandidatesClient", // Управление кандидатами (Клиентская часть)
    CLNT_manageCommentClient: "CLNT_manageCommentClient", // Управление чужими комментариями (Клиентская часть)
    CLNT_accessDocumentSomeBlockR: "CLNT_accessDocumentSomeBlockR", // Доступ к вкладке "Типовые документы"
    CLNT_addExportTypeAnalytNpdResponse: "CLNT_addExportTypeAnalytNpdResponse", // Выгрузка отчетов с тимпами: Аналитика по оплатам НПД, Отклики по заказам
    CLNT_actionsOnboardingScenario: "CLNT_actionsOnboardingScenario", // Действия над сценарием онбординга
    CLNT_actionsOnboardingScenarioRecruiters: "CLNT_actionsOnboardingScenarioRecruiters", // Добавление рекрутеров и создание ссылки
    CLNT_accessAccessControlClient: "CLNT_accessAccessControlClient", // Доступ к меню Управление доступом (Клиентская часть)
    CLNT_accessGroupsClient: "CLNT_accessGroupsClient", // Доступ во вкладку Группы для рекрутеров(Клиентская часть)
    CLNT_terminateFrameContract: "CLNT_terminateFrameContract", // Расторжение договора
    CLNT_addExternalDocumentRegistry: "CLNT_addExternalDocumentRegistry", // Добавление внешнего договора
    CLNT_addExportTypeProjectAndObject: "CLNT_addExportTypeProjectAndObject", // Создание и экспорт с  типом выгрузки "Проекты и объекты"
    CLNT_getProjectsAndObjectsDepositValues: "CLNT_getProjectsAndObjectsDepositValues", // Доступ к группе блоков с детализацией по бюджету/выплатам
    CLNT_checkPayment: "CLNT_checkPayment", // Проверка оплат
    CLNT_checkIndividualPayments: "CLNT_checkIndividualPayments", // Проверка оплат
    CLNT_confirmOrderWorkReport: "CLNT_confirmOrderWorkReport", // Подтверждение(НПД)/Отклонение(НПД, НДФЛ) оплат в статусе "На подтверждении заказчиком"
    CLNT_actionsClientRegistryListAndCard: "CLNT_actionsClientRegistryListAndCard", // Действия с реестром выплат: создание, редактирование, удаление
    CLNT_actionsOrderList: "CLNT_actionsOrderList", // Действия в Реестре заказов: редактирование (в поиске исп), завершение заказа, просмотр показателей депозита и пр
    CLNT_actionsOrderListAddEditPublish: "CLNT_actionsOrderListAddEditPublish", // Действия в Реестре заказов: создание заказов, редактирование черновиков, публикация
    CLNT_accessOrderCard: "CLNT_accessOrderCard", // Доступ к карточке заказа
    CLNT_actionsOrderCard: "CLNT_actionsOrderCard", // Доступ к действиям в карточке заказа (кроме обработки отклики и приглашения)
    CLNT_publishOrders: "CLNT_publishOrders", // Доступ к завершению подбора по заказу
    CLNT_uploadOrderChecks: "CLNT_uploadOrderChecks", // Выгрузка чеков по заказу из карточки проекта или объекта
    CLNT_actionsOrderTimeLog: "CLNT_actionsOrderTimeLog", // Работа в журнале учета времени в карточке заказа
    CLNT_accessActionsJobBoardClient: "CLNT_accessActionsJobBoardClient", // Доступ управление во вкладку Джоб-борды (Клиентская часть)
    CLNT_accessReCreateSubscriptionClient: "CLNT_accessReCreateSubscriptionClient", // Доступ к управлению подпиской
    CLNT_accessActionsGroupsClient: "CLNT_accessActionsGroupsClient", // Доступ управление во вкладку Группы для рекрутеров(Клиентская часть)
    CLNT_actionsMassiveAdvertisement: "CLNT_actionsMassiveAdvertisement", // Массовая публикация/ удаление черновиков объявлений
    CLNT_actionsAdvertisementCityCodeOrLoadingList: "CLNT_actionsAdvertisementCityCodeOrLoadingList", // Объявления: определение кода города, загрузка списка объявлений из файла
    CLNT_actionsClientContractors: "CLNT_actionsClientContractors", // Приглашение на заказ, Добавление/удаление в избранное, добавление/удаление в ЧС
    CLNT_accessClientChats: "CLNT_accessClientChats", // Доступ к разделу Чаты
    CLNT_accessClientEdoNotifChats: "CLNT_accessClientEdoNotifChats", // Доступ к разделам ЭДО, Уведомления, Чаты
    CLNT_refusalInviteAndSecurePositionOrderContactors: "CLNT_refusalInviteAndSecurePositionOrderContactors", // Массовый отзыв приглашений и закрепление/открепление исполнителей
    CLNT_addRepeatOrderWorkReport: "CLNT_addRepeatOrderWorkReport", // Повтор строк и отправка реестра актов
    CLNT_paymentRegisterPayments: "CLNT_paymentRegisterPayments", // Управление реестровыми выплатами
    CLNT_accessClientContractorsDocTask: "CLNT_accessClientContractorsDocTask", // Доступ к разделам: Исполнители, Компания, Документы, Список задач
    CLNT_actionsContractorIndividual: "CLNT_actionsContractorIndividual", // Действия в блоке "Статус ИП": запросить статус, редактировать, удалить
    CLNT_accessIndividualPayments: "CLNT_accessIndividualPayments", // Доступ к странице Выплаты исполнителям  (ИП)
    CLNT_actionsIndividualPayments: "CLNT_actionsIndividualPayments", // Действия с оплатами ИП
    CLNT_actionsIndividualPaymentDocs: "CLNT_actionsIndividualPaymentDocs", // Просмотр документов по оплатам ИП
    CLNT_accessClientDisputes: "CLNT_accessClientDisputes", // Доступ к разделу Споры
    CLNT_accessClientDispute: "CLNT_accessClientDispute", // Переход в чат спора по заказу
    CLNT_accessEdo: "CLNT_accessEdo", // Доступ к разделу ЭДО
    CLNT_uploadUnloadEdoDocuments: "CLNT_uploadUnloadEdoDocuments", // Загрузка и выгрузка документов ЭДО
    CLNT_actionsEdoRegistries: "CLNT_actionsEdoRegistries", // Действия c реестром документов ЭДО: подписание, повтор невыполненных
    CLNT_confirmEdoStatement: "CLNT_confirmEdoStatement", // Одобрение/отклонение заявления
    CLNT_archiveEdoStatement: "CLNT_archiveEdoStatement", // Архивирование/восстановление из архива заявления
    CLNT_actionsEdoSubdivisions: "CLNT_actionsEdoSubdivisions", // Работа с кадрами в ЭДО: добавление, редактирование, архив
    CLNT_accessEdoPersonalOtherDocuments: "CLNT_accessEdoPersonalOtherDocuments", // Доступ к блоку "Другие документы" в ПД исполнителя - кадры - ЭДО
    CLNT_actionsDirectoryEdo: "CLNT_actionsDirectoryEdo", // Работа с должностями, со структурными подразделениями в справочниках ЭДО
    CLNT_accessOrderTimeLog: "CLNT_accessOrderTimeLog", // Доступ к Журналу учета времени
    CLNT_loginInProjectsTab: "CLNT_loginInProjectsTab", // Переход в раздел "Компания → Проекты" при логине
    CLNT_loginInOrdersTab: "CLNT_loginInOrdersTab", // Переход в раздел "Заказы → Реестр заказов" при логине
    CLNT_loginInVacanciesTab: "CLNT_loginInVacanciesTab", // Переход в раздел "Рекрутмент → Вакансии" при логине
    CLNT_addRecruitmentsCandidatesToKedo: "CLNT_addRecruitmentsCandidatesToKedo", // Добавление кандидата в КЭДО из рекрутмента
    CLNT_accessKedo: "CLNT_accessKedo", // Доступ к модулю КЭДО
    CLNT_accessEmployeesKedo: "CLNT_accessEmployeesKedo", // Доступ к разделу "Сотрудники КЭДО"
    CLNT_actionsAccountEmployeesKedo: "CLNT_actionsAccountEmployeesKedo", // Запрет действий с учетными записями сотрудников КЭДО
    CLNT_accessDocumentFlowKedo: "CLNT_accessDocumentFlowKedo", // Доступ к разделу "Документооборот КЭДО"
    CLNT_actionsDocumentKedo: "CLNT_actionsDocumentKedo", // Запрет действий с документами КЭДО
    CLNT_accessExportKedo: "CLNT_accessExportKedo", // Доступ к разделу "Экспорт КЭДО"
    CLNT_actionsExportKedo: "CLNT_actionsExportKedo", // Запрет действий с выгрузками КЭДО
    CLNT_accessCatalogKedo: "CLNT_accessCatalogKedo", // Доступ к разделу "Справочники КЭДО"
    CLNT_actionsDirectoriesUploadedDocTypeKedo: "CLNT_actionsDirectoriesUploadedDocTypeKedo", // Запрет действий со справочником типов загруженных документов КЭДО
    CLNT_actionsPositionsKedo: "CLNT_actionsPositionsKedo", // Запрет действий со справочником должностей КЭДО
    CLNT_actionsSubdivisionsKedo: "CLNT_actionsSubdivisionsKedo", // Запрет действий со справочником структурных подразделений КЭДО
    CLNT_accessLog1cKedo: "CLNT_accessLog1cKedo", // Доступ к разделу "Лог интеграции с 1С"
    CLNT_accessSettingInstructionsKedo: "CLNT_accessSettingInstructionsKedo", // Доступ к разделу "Инструкции КЭДО"
    CLNT_actionsSettingInstructionsKedo: "CLNT_actionsSettingInstructionsKedo", // Запрет действий с инструкциями КЭДО
    CLNT_accessTaskListKedo: "CLNT_accessTaskListKedo", // Доступ к разделу "Список задач КЭДО"
    CLNT_accessOrgStructureKedo: "CLNT_accessOrgStructureKedo", // Доступ к разделу "Организационная структура КЭДО"
    CLNT_actionsOrgStructureKedo : "CLNT_actionsOrgStructureKedo", // Действия в Организационной структуре КЭДО для КЧ
    CLNT_actionsRouteForDocumentTypesKedo: "CLNT_actionsRouteForDocumentTypesKedo", // Запрет назначение маршрута для типов документа
    CLNT_actionsUserRouteTypeKedo: "CLNT_actionsUserRouteTypeKedo", // Запрет действий с пользовательскими  маршрутами подписания
    CLNT_actionsTimeLog: "CLNT_actionsTimeLog", // Работа с Журналом учета времени
    CLNT_accessPostActRegistry: "CLNT_accessOrgStructureKedo", // Доступ ко вкладке "Реестры отложенных актов"
    CLNT_actionPostActRegistry: "CLNT_accessOrgStructureKedo", // Передача на подписание, редактирование, архивирование, восстановление из архива, повтор и отмена невыполненного реестра
    CLNT_uploadPostActRegistry: "uploadPostActRegistry", // Выгрузка реестра
    CLNT_actionPostActRegistryItem: "actionPostActRegistryItem", // Редактирование и удаление строк реестра
    CLNT_createPostActRegistry: "createPostActRegistry", // Формирование реестра отложенных актов из реестра оплат (кнопка контекстного меню Сформировать акты)
    CLNT_accessRegisterAdditionalAgreements: "CLNT_accessRegisterAdditionalAgreements", // Доступ к разделу Реестры на подписание доп. соглашений
    CLNT_actionsRegisterAdditionalAgreements: "CLNT_actionsRegisterAdditionalAgreements", // Запрет действий с реестрами доп. соглашений
};