import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_NOTE_CONTRACTOR_CARD_ERROR,
    ADD_NOTE_CONTRACTOR_CARD_REQUEST,
    ADD_NOTE_CONTRACTOR_CARD_SUCCESS,
    ARCHIVE_CONTRACTOR_CARD_ERROR,
    ARCHIVE_CONTRACTOR_CARD_REQUEST,
    ARCHIVE_CONTRACTOR_CARD_SUCCESS,
    CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR,
    CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
    EMAIL_COMPLAINT_ERROR,
    EMAIL_COMPLAINT_REQUEST,
    EMAIL_COMPLAINT_SUCCESS,
    GET_INFO_CONTRACTOR_CARD_ERROR,
    GET_INFO_CONTRACTOR_CARD_REQUEST,
    GET_INFO_CONTRACTOR_CARD_SUCCESS,
    GET_NOTE_CONTRACTOR_CARD_ERROR,
    GET_NOTE_CONTRACTOR_CARD_REQUEST,
    GET_NOTE_CONTRACTOR_CARD_SUCCESS,
    GET_PROMOCODE_CONTRACTOR_CARD_ERROR,
    GET_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    GET_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
    GET_RATING_CONTRACTOR_CARD_ERROR,
    GET_RATING_CONTRACTOR_CARD_REQUEST,
    GET_RATING_CONTRACTOR_CARD_SUCCESS,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
    UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_ERROR,
    UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST,
    UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_SUCCESS,
    UPDATE_CONTRACTOR_BLOCKED_ERROR,
    UPDATE_CONTRACTOR_BLOCKED_REQUEST,
    UPDATE_CONTRACTOR_BLOCKED_SUCCESS,
    UPDATE_NOTE_CONTRACTOR_CARD_ERROR,
    UPDATE_NOTE_CONTRACTOR_CARD_REQUEST,
    UPDATE_NOTE_CONTRACTOR_CARD_SUCCESS,
    UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_ERROR,
    UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST,
    UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_SUCCESS,
} from "./actions";

import request from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";
import {getBffUrl} from "../../../utils/url";

import {CONTRACTOR_MESSAGE} from "../../../constants/messages";

const controllers = {
    client: "/client-adm/contractors/contractor-card",
    admin: "/adm/contractors/contractors-registry/contractor-card",
};

//POST /bff/adm/contractors/contractors-registry/contractor-card/update-project-params
const updateProjectParamsContractorCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const otherUrl = "/update-project-params";
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.post(url, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        yield put({
            type: UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_SUCCESS,
            payload: errorMessage,
        });

    } catch (error) {
        yield put({
            type: UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_ERROR,
            payload: error.message,
        });
    }
};

//POST /bff/adm/contractors/contractors-registry/contractor-card/contractor/archive
const archiveContractorCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;

        const otherUrl = `/contractor/archive?contractorId=${contractorId}`;
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.post(url);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: ARCHIVE_CONTRACTOR_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ARCHIVE_CONTRACTOR_CARD_ERROR,
            payload: error.message,
        });
    }
};

//POST /bff/adm/contractors/contractors-registry/contractor-card/contractor-note/add
const addNoteContractorCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const otherUrl = "/contractor-note/add";
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: ADD_NOTE_CONTRACTOR_CARD_SUCCESS, payload: result.text});
    } catch (error) {
        yield put({type: ADD_NOTE_CONTRACTOR_CARD_ERROR, payload: error});
    }
};

//PATCH /bff/adm/contractors/contractors-registry/contractor-card/contractor-note/update
const updateNoteContractorCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const otherUrl = "/contractor-note/update";
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.patch(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_NOTE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_NOTE_CONTRACTOR_CARD_SUCCESS,
            payload: result.text,
        });
    } catch (error) {
        yield put({
            type: UPDATE_NOTE_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/contractors/contractors-registry/contractor-card/contractor-note/get
const getNoteContractorCard = function* ({payload}) {
    try {
        const {
            clientId,
            contractorId,
        } = payload;

        const otherUrl = `/contractor-note/get?clientId=${clientId}&contractorId=${contractorId}`;
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_NOTE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_NOTE_CONTRACTOR_CARD_SUCCESS, payload: result.text});
    } catch (error) {
        yield put({type: GET_NOTE_CONTRACTOR_CARD_ERROR, payload: error});
    }
};

//GET /bff/adm/contractors/contractors-registry/contractor-card/contractor-info/{contractorId}/get
const getInfoContractorCard = function* ({payload}) {
    try {
        const otherUrl = `/contractor-info/${payload}/get`;
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_INFO_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_INFO_CONTRACTOR_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_INFO_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/contractors/contractors-registry/contractor-card/bankRequisites/{contractorId}/required/{required}
const updateBankRequisitesRequiredContractorCard = function* ({payload}) {
    try {
        const {
            contractorId,
            required,
            onSuccess,
        } = payload;

        const otherUrl = `/bankRequisites/${contractorId}/required/${required}`;
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );
        const result = yield request.bff.post(url);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/contractors/contractors-registry/contractor-card/contractor-rating/{contractorId}/get
const getRatingContractorCard = function* ({payload}) {
    try {
        const {contractorId} = payload;

        const otherUrl = `/contractor-rating/${contractorId}/get`;
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );

        const result = yield request.bff.get(url);
        const {errorMessage} = payload;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RATING_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_RATING_CONTRACTOR_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_RATING_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

const getPromocodeByContractorId = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...params
        } = payload;

        const otherUrl = "/get-promocode-by-contractor-id";
        const url = getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: `${controllers.client}${otherUrl}`,
                adminRolesUrl: `${controllers.admin}${otherUrl}`,
            },
        );
        
        const result = yield request.bff.get(url, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROMOCODE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: GET_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: GET_PROMOCODE_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

const registrationPromocodeContractorCard = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controllers.admin}/registration-promocode`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(`Промокод \"${reqData.promocode}\" успешно применен. Промокод можно использовать только один раз.`);

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: REGISTRATION_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: REGISTRATION_PROMOCODE_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

const checkPromocodeContractorCard = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controllers.admin}/check-promocode`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: CHECK_PROMOCODE_CONTRACTOR_CARD_SUCCESS,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHECK_PROMOCODE_CONTRACTOR_CARD_ERROR,
            payload: error,
        });
    }
};

const updateContractorBlocked = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controllers.admin}/blocked/update`, reqData);
        const {errorMessage} = result;

        getResult && getResult(errorMessage);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_BLOCKED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put({
            type: UPDATE_CONTRACTOR_BLOCKED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_BLOCKED_ERROR,
            payload: error,
        });
    }
};

// POST /bff/client-adm/contractors/contractor-card/complaintEmail
// Пожаловаться
const complaintByEmailSaga = function* ({payload}) {
    const {
        data,
        onSuccess,
        onError,
    } = payload;

    try {
        const {errorMessage} = yield request.bff.post(`${controllers.client}/complaintEmail`, data);

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: EMAIL_COMPLAINT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Жалоба на исполнителя успешно создана и в ближайшее время будет обработана");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: EMAIL_COMPLAINT_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        if (onError) {
            onError();
        }

        yield put({
            type: EMAIL_COMPLAINT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST, updateProjectParamsContractorCard),
        takeEvery(ARCHIVE_CONTRACTOR_CARD_REQUEST, archiveContractorCard),
        takeEvery(ADD_NOTE_CONTRACTOR_CARD_REQUEST, addNoteContractorCard),
        takeEvery(GET_NOTE_CONTRACTOR_CARD_REQUEST, getNoteContractorCard),
        takeEvery(UPDATE_NOTE_CONTRACTOR_CARD_REQUEST, updateNoteContractorCard),
        takeEvery(GET_INFO_CONTRACTOR_CARD_REQUEST, getInfoContractorCard),
        takeEvery(UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST, updateBankRequisitesRequiredContractorCard),
        takeEvery(GET_RATING_CONTRACTOR_CARD_REQUEST, getRatingContractorCard),
        takeEvery(GET_PROMOCODE_CONTRACTOR_CARD_REQUEST, getPromocodeByContractorId),
        takeEvery(REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST, registrationPromocodeContractorCard),
        takeEvery(CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST, checkPromocodeContractorCard),
        takeEvery(UPDATE_CONTRACTOR_BLOCKED_REQUEST, updateContractorBlocked),
        takeEvery(EMAIL_COMPLAINT_REQUEST, complaintByEmailSaga),
    ]);
}