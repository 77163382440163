import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";

import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import validationRegisterPatent from "./validate-rule";

import bem from "../../../utils/bem";
import {CURRENT_CLIENT_USER_ID, ls} from "../../../utils/localstorage";

import {PATENTS_REGISTRY_STATUS} from "../../../constants/patentsRegistry";

import {patentsRegistriesActionRegistryProgressSelector} from "../../../ducks/bff/clients/patents/pay/selectors";
import {clientObjectOptionsSelector, getClientObjectList} from "../../../ducks/clientObject";

import "./style.sass";

const CreateEditRegistryPatent = (props) => {
    const {
        className,
        data,
        onClose,
        onSubmit,
        objectList,
        getPatentsRegistries,
    } = props;

    const {
        registryName,
        registryComment,
        clientId,
        patentRegistryId,
        status,
        objectId,
    } = data;

    const objectOptions = useSelector(clientObjectOptionsSelector);
    const actionProgress = useSelector(patentsRegistriesActionRegistryProgressSelector);
    const clientUserId = ls(CURRENT_CLIENT_USER_ID);

    const [valueObjectFilter, setValueObjectFilter] = useState("");

    const [block, element] = bem("create-edit-registry-patent", className);

    const dispatch = useDispatch();

    const fetchObjects = () => {
        dispatch(getClientObjectList({
            clientId,
            clientUserId,
            orderType: "ASC",
            pageNum: 1,
            pageSize: 300,
            status: "IN_WORK",
            nameSubstringFilter: valueObjectFilter,
        }));
    };

    useEffect(() => {
        fetchObjects();
    }, [valueObjectFilter]);

    return (
        <Formik
            initialValues={{
                registryName: registryName || "",
                objectId: objectId || "",
                registryComment: registryComment || "",
            }}
            onSubmit={(values, actions) => {
                const projectId = objectList.find(value => value.objectId === values.objectId)?.projectId || undefined;

                onSubmit({
                    data: {
                        clientId,
                        projectId,
                        patentRegistryId,
                        status,
                        ...values,
                    },
                    meta: () => {
                        onClose();
                        getPatentsRegistries();
                    },
                });

                actions.setSubmitting(false);
            }}
            enableReinitialize
            validationSchema={validationRegisterPatent()}
        >
            {(props) => {
                const {
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    handleBlur,
                    values,
                    errors,
                    touched,
                    handleSubmit,
                } = props;

                const renderHeader = () => {
                    return (
                        <NmTitle
                            className={element("header")}
                            size="lg"
                        >
                            {patentRegistryId ?
                                "Редактирование реестра на оплату патентов" :
                                "Добавление реестра на оплату патентов"
                            }
                        </NmTitle>
                    );
                };

                const renderFooter = () => {
                    return (
                        <ApplyButtons
                            mobile="column"
                            cancelBtnContent="Отменить"
                            isHiddenCancelOnMobile
                            submitBtnContent={patentRegistryId ? "Сохранить" : "Добавить"}
                            formId="CreateEditRegistryPatent"
                            onClose={onClose}
                            disabled={actionProgress}
                        />
                    );
                };

                return (
                    <NmModal
                        size="md"
                        onClose={onClose}
                        header={renderHeader()}
                        footer={renderFooter()}
                    >
                        <NmForm
                            noValidate
                            onSubmit={handleSubmit}
                            id="CreateEditRegistryPatent"
                        >
                            <NmInputV2
                                name="registryName"
                                error={touched?.registryName ? errors && errors.registryName : false}
                                size="xl"
                                label="Название"
                                value={values.registryName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                maxLength={100}
                                className="mb-3 mb-md-4"
                                placeholder="Введите наименование реестра"
                                required
                            />
                            <NmDropdownV2
                                disabled={Boolean(patentRegistryId) && status !== PATENTS_REGISTRY_STATUS.FORMED}
                                className="mb-3 mb-md-4"
                                search
                                placeholder="Выберите объект"
                                label="Объект"
                                name="objectId"
                                onChange={(e, {name, value}) => setFieldValue(name, value)}
                                onBlur={() => setFieldTouched("objectId", true)}
                                onSearchChange={(value) => setValueObjectFilter(value)}
                                value={objectId}
                                options={objectOptions}
                                error={touched?.objectId ? errors && errors.objectId : false}
                                required
                            />
                            <NmTextareaV2
                                name="registryComment"
                                error={touched?.registryComment ? errors && errors.registryComment : false}
                                size="xl"
                                label="Комментарий"
                                value={values.registryComment}
                                onChange={handleChange}
                                maxLength={255}
                                placeholder="При необходимости укажите комментарий"
                                maxRows={4}
                                minRows={4}
                            />
                        </NmForm>
                    </NmModal>
                );
            }}
        </Formik>
    );
};

export default CreateEditRegistryPatent;