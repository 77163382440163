import {useState} from "react";

export const useRequestPassportPageNumber19 = () => {
    const [isRequestPassportPageNumber19, setRequestPassportPageNumber19] = useState(false);

    const onChangeRequestPassportPageNumber19 = () => {
        setRequestPassportPageNumber19(!isRequestPassportPageNumber19);
    };

    return {
        isRequestPassportPageNumber19,
        onChangeRequestPassportPageNumber19,
    };
};