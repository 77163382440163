import {
    BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_SUCCESS,
    BFF_COMMON_CLIENT_UPDATE_STORE,
} from "./actions";

const initial = {
    maintenanceNotice: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_SUCCESS:
            return {
                ...state,
                maintenanceNotice: payload,
            };
        case BFF_COMMON_CLIENT_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};