export const ORDERS_GET_PAGE_REQUEST = "ORDERS_GET_PAGE_REQUEST";
export const ORDERS_GET_PAGE_SUCCESS = "ORDERS_GET_PAGE_SUCCESS";
export const ORDERS_GET_PAGE_ERROR = "ORDERS_GET_PAGE_ERROR";

export const ORDERS_ADD_REQUEST = "ORDERS_ADD_REQUEST";
export const ORDERS_ADD_SUCCESS = "ORDERS_ADD_SUCCESS";
export const ORDERS_ADD_ERROR = "ORDERS_ADD_ERROR";

export const ORDERS_UPDATE_REQUEST = "ORDERS_UPDATE_REQUEST";
export const ORDERS_UPDATE_SUCCESS = "ORDERS_UPDATE_SUCCESS";
export const ORDERS_UPDATE_ERROR = "ORDERS_UPDATE_ERROR";

export const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
export const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
export const ORDER_HISTORY_ERROR = "ORDER_HISTORY_ERROR";

export const ORDER_CONTRACTOR_HISTORY_REQUEST = "ORDER_CONTRACTOR_HISTORY_REQUEST";
export const ORDER_CONTRACTOR_HISTORY_SUCCESS = "ORDER_CONTRACTOR_HISTORY_SUCCESS";
export const ORDER_CONTRACTOR_HISTORY_ERROR = "ORDER_CONTRACTOR_HISTORY_ERROR";

export const ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST = "ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST";
export const ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS = "ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS";
export const ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR = "ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR";

export const ORDER_ARCHIVE_UPDATE_REQUEST = "ORDER_ARCHIVE_UPDATE_REQUEST";
export const ORDER_ARCHIVE_UPDATE_SUCCESS = "ORDER_ARCHIVE_UPDATE_SUCCESS";
export const ORDER_ARCHIVE_UPDATE_ERROR = "ORDER_ARCHIVE_UPDATE_ERROR";

export const ORDER_COMPLETE_REQUEST = "ORDER_COMPLETE_REQUEST";
export const ORDER_COMPLETE_SUCCESS = "ORDER_COMPLETE_SUCCESS";
export const ORDER_COMPLETE_ERROR = "ORDER_COMPLETE_ERROR";

export const CLOSE_ORDERS_REQUEST = "CLOSE_ORDERS_REQUEST";
export const CLOSE_ORDERS_SUCCESS = "CLOSE_ORDERS_SUCCESS";
export const CLOSE_ORDERS_ERROR = "CLOSE_ORDERS_ERROR";

export const PUBLISH_ORDERS_REQUEST = "PUBLISH_ORDERS_REQUEST";
export const PUBLISH_ORDERS_SUCCESS = "PUBLISH_ORDERS_SUCCESS";
export const PUBLISH_ORDERS_ERROR = "PUBLISH_ORDERS_ERROR";

export const ARCHIVE_ORDERS_REQUEST = "ARCHIVE_ORDERS_REQUEST";
export const ARCHIVE_ORDERS_SUCCESS = "ARCHIVE_ORDERS_SUCCESS";
export const ARCHIVE_ORDERS_ERROR = "ARCHIVE_ORDERS_ERROR";

export const DELETE_ORDERS_REQUEST = "DELETE_ORDERS_REQUEST";
export const DELETE_ORDERS_SUCCESS = "DELETE_ORDERS_SUCCESS";
export const DELETE_ORDERS_ERROR = "DELETE_ORDERS_ERROR";

export const ORDER_DELETE_DRAFT_REQUEST = "ORDER_DELETE_DRAFT_REQUEST";
export const ORDER_DELETE_DRAFT_SUCCESS = "ORDER_DELETE_DRAFT_SUCCESS";
export const ORDER_DELETE_DRAFT_ERROR = "ORDER_DELETE_DRAFT_ERROR";

export const GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST = "GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST";
export const GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS = "GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS";
export const GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR = "GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR";

export const ORDERS_END_CONTRACTOR_SEARCH_REQUEST = "ORDERS_END_CONTRACTOR_SEARCH_REQUEST";
export const ORDERS_END_CONTRACTOR_SEARCH_SUCCESS = "ORDERS_END_CONTRACTOR_SEARCH_SUCCESS";
export const ORDERS_END_CONTRACTOR_SEARCH_ERROR = "ORDERS_END_CONTRACTOR_SEARCH_ERROR";

export const ORDER_CARD_RICH_REQUEST = "ORDER_CARD_RICH_REQUEST";
export const ORDER_CARD_RICH_SUCCESS = "ORDER_CARD_RICH_SUCCESS";
export const ORDER_CARD_RICH_ERROR = "ORDER_CARD_RICH_ERROR";

export const ORDER_WORK_REPORT_PAID_SUM_REQUEST = "ORDER_WORK_REPORT_PAID_SUM_REQUEST";
export const ORDER_WORK_REPORT_PAID_SUM_SUCCESS = "ORDER_WORK_REPORT_PAID_SUM_SUCCESS";
export const ORDER_WORK_REPORT_PAID_SUM_ERROR = "ORDER_WORK_REPORT_PAID_SUM_ERROR";

export const UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST = "UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST";
export const UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS = "UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS";
export const UPDATE_ORDER_CONTRACTORS_FOUND_ERROR = "UPDATE_ORDER_CONTRACTORS_FOUND_ERROR";

export const ORDER_LIST_FOR_INVITATION_REQUEST = "ORDER_LIST_FOR_INVITATION_REQUEST";
export const ORDER_LIST_FOR_INVITATION_SUCCESS = "ORDER_LIST_FOR_INVITATION_SUCCESS";
export const ORDER_LIST_FOR_INVITATION_ERROR = "ORDER_LIST_FOR_INVITATION_ERROR";

export const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST";
export const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS";
export const ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR = "ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR";

export const ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST = "ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST";
export const ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS = "ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS";
export const ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR = "ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR";

export const UPDATE_ORDERS_STORE = "UPDATE_EXPORT_REPORTS_STORE";

export const CLEAR_ORDERS_STORE = "CLEAR_ORDERS_STORE";