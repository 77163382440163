import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getUserAvatarListThumbnails} from "./fileStore";

import {getTotalPages} from "../utils/mathHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

import {MESSAGES_EXPORT_TOAST} from "../constants/messages";
import {ONBOARDING_SCENARIO_UPDATE_ERROR_CODE} from "../constants/onboarding";

const getController = () => {
    return (
        getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/contractors/onboarding",
            client: "/client-adm/contractors/onboarding",
        })
    );
};

const ONBOARDING_SCENARIO_LIST_REQUEST = "ONBOARDING_SCENARIO_LIST_REQUEST";
const ONBOARDING_SCENARIO_LIST_SUCCESS = "ONBOARDING_SCENARIO_LIST_SUCCESS";
const ONBOARDING_SCENARIO_LIST_ERROR = "ONBOARDING_SCENARIO_LIST_ERROR";

const GET_ONBOARDING_SCENARIO_LOG_REQUEST = "GET_ONBOARDING_SCENARIO_LOG_REQUEST";
const GET_ONBOARDING_SCENARIO_LOG_SUCCESS = "GET_ONBOARDING_SCENARIO_LOG_SUCCESS";
const GET_ONBOARDING_SCENARIO_LOG_ERROR = "GET_ONBOARDING_SCENARIO_LOG_ERROR";

const GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_REQUEST = "GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_REQUEST";
const GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_SUCCESS = "GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_SUCCESS";
const GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_ERROR = "GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_ERROR";

const GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_REQUEST = "GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_REQUEST";
const GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_SUCCESS = "GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_SUCCESS";
const GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_ERROR = "GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_ERROR";

const GET_ONBOARDING_SCENARIO_PROMOCODE_REQUEST = "GET_ONBOARDING_SCENARIO_PROMOCODE_REQUEST";
const GET_ONBOARDING_SCENARIO_PROMOCODE_SUCCESS = "GET_ONBOARDING_SCENARIO_PROMOCODE_SUCCESS";
const GET_ONBOARDING_SCENARIO_PROMOCODE_ERROR = "GET_ONBOARDING_SCENARIO_PROMOCODE_ERROR";

const GET_ONBOARDING_SCENARIO_OBJECTS_REQUEST = "GET_ONBOARDING_SCENARIO_OBJECTS_REQUEST";
const GET_ONBOARDING_SCENARIO_OBJECTS_SUCCESS = "GET_ONBOARDING_SCENARIO_OBJECTS_SUCCESS";
const GET_ONBOARDING_SCENARIO_OBJECTS_ERROR = "GET_ONBOARDING_SCENARIO_OBJECTS_ERROR";

const GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_REQUEST = "GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_REQUEST";
const GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_SUCCESS = "GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_SUCCESS";
const GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_ERROR = "GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_ERROR";

const GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_REQUEST = "GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_REQUEST";
const GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_SUCCESS = "GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_SUCCESS";
const GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_ERROR = "GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_ERROR";

const ADD_ONBOARDING_SCENARIO_REQUEST = "ADD_ONBOARDING_SCENARIO_REQUEST";
const ADD_ONBOARDING_SCENARIO_SUCCESS = "ADD_ONBOARDING_SCENARIO_SUCCESS";
const ADD_ONBOARDING_SCENARIO_ERROR = "ADD_ONBOARDING_SCENARIO_ERROR";

const UPDATE_ONBOARDING_SCENARIO_REQUEST = "UPDATE_ONBOARDING_SCENARIO_REQUEST";
const UPDATE_ONBOARDING_SCENARIO_SUCCESS = "UPDATE_ONBOARDING_SCENARIO_SUCCESS";
const UPDATE_ONBOARDING_SCENARIO_ERROR = "UPDATE_ONBOARDING_SCENARIO_ERROR";

const UPDATE_STATUS_ONBOARDING_SCENARIO_REQUEST = "UPDATE_STATUS_ONBOARDING_SCENARIO_REQUEST";
const UPDATE_STATUS_ONBOARDING_SCENARIO_SUCCESS = "UPDATE_STATUS_ONBOARDING_SCENARIO_SUCCESS";
const UPDATE_STATUS_ONBOARDING_SCENARIO_ERROR = "UPDATE_STATUS_ONBOARDING_SCENARIO_ERROR";

const GET_ONBOARDING_SCENARIO_CARD_REQUEST = "GET_ONBOARDING_SCENARIO_CARD_REQUEST";
const GET_ONBOARDING_SCENARIO_CARD_SUCCESS = "GET_ONBOARDING_SCENARIO_CARD_SUCCESS";
const GET_ONBOARDING_SCENARIO_CARD_ERROR = "GET_ONBOARDING_SCENARIO_CARD_ERROR";

const ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_REQUEST = "ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_REQUEST";
const ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_SUCCESS = "ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_SUCCESS";
const ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_ERROR = "ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_ERROR";

const GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_REQUEST = "GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_REQUEST";
const GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_SUCCESS = "GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_SUCCESS";
const GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_ERROR = "GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_ERROR";

const ONBOARDING_SCENARIO_DELETE_LEAD_REQUEST = "ONBOARDING_SCENARIO_DELETE_LEAD_REQUEST";
const ONBOARDING_SCENARIO_DELETE_LEAD_SUCCESS = "ONBOARDING_SCENARIO_DELETE_LEAD_SUCCESS";
const ONBOARDING_SCENARIO_DELETE_LEAD_ERROR = "ONBOARDING_SCENARIO_DELETE_LEAD_ERROR";

const GET_ONBOARDING_LEADS_GET_PAGE_REQUEST = "GET_ONBOARDING_LEADS_GET_PAGE_REQUEST";
const GET_ONBOARDING_LEADS_GET_PAGE_SUCCESS = "GET_ONBOARDING_LEADS_GET_PAGE_SUCCESS";
const GET_ONBOARDING_LEADS_GET_PAGE_ERROR = "GET_ONBOARDING_LEADS_GET_PAGE_ERROR";

const ONBOARDING_LEADS_DELETE_REQUEST = "ONBOARDING_LEADS_DELETE_REQUEST";
const ONBOARDING_LEADS_DELETE_SUCCESS = "ONBOARDING_LEADS_DELETE_SUCCESS";
const ONBOARDING_LEADS_DELETE_ERROR = "ONBOARDING_LEADS_DELETE_ERROR";

const ONBOARDING_SCENARIO_LEADS_IMPORT_REQUEST = "ONBOARDING_SCENARIO_LEADS_IMPORT_REQUEST";
const ONBOARDING_SCENARIO_LEADS_IMPORT_SUCCESS = "ONBOARDING_SCENARIO_LEADS_IMPORT_SUCCESS";
const ONBOARDING_SCENARIO_LEADS_IMPORT_ERROR = "ONBOARDING_SCENARIO_LEADS_IMPORT_ERROR";

const ONBOARDING_SCENARIO_LEADS_EXPORT_REQUEST = "ONBOARDING_SCENARIO_LEADS_EXPORT_REQUEST";
const ONBOARDING_SCENARIO_LEADS_EXPORT_SUCCESS = "ONBOARDING_SCENARIO_LEADS_EXPORT_SUCCESS";
const ONBOARDING_SCENARIO_LEADS_EXPORT_ERROR = "ONBOARDING_SCENARIO_LEADS_EXPORT_ERROR";

const ONBOARDING_UPDATE_STORE = "ONBOARDING_UPDATE_STORE";

const initial = {
    list: [],
    card: {},
    listProgress: false,
    pageData: 0,
    totalCount: 0,
    logList: [],
    logListProgress: false,
    logPageData: 0,
    logTotalCount: 0,
    promoCodeVariants: [],
    projectParamsVariants: [],
    bindingVariants: [],
    objectVariants: [],
    leads: {
        list: [],
        pageData: {},
    },
    contractorsLeads: {
        list: [],
        totalCount: 0,
        pageData: {},
    },
    progressAction: false,
    progress: false,
    isClientBelongsGroup: false,
    additionalDocTypeDict: {},
    welcomeString: "",
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_SUCCESS:
            return {
                ...state,
                welcomeString: payload.result,
            };
        case GET_ONBOARDING_LEADS_GET_PAGE_ERROR:
            return {
                ...state,
                contractorsLeads: {
                    ...state.contractorsLeads,
                    progress: false,
                },
            };
        case GET_ONBOARDING_LEADS_GET_PAGE_REQUEST:
            return {
                ...state,
                contractorsLeads: {
                    ...state.contractorsLeads,
                    progress: true,
                    pageData: payload,
                },
            };
        case GET_ONBOARDING_LEADS_GET_PAGE_SUCCESS:
            return {
                ...state,
                contractorsLeads: {
                    ...state.contractorsLeads,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_ERROR:
            return {
                ...state,
                leads: {
                    ...state.leads,
                    progress: false,
                },
            };
        case GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_REQUEST:
            return {
                ...state,
                leads: {
                    ...state.leads,
                    progress: true,
                    isSearch: true,
                    pageData: payload,
                },
            };
        case GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_SUCCESS:
            return {
                ...state,
                leads: {
                    ...state.leads,
                    list: payload.results,
                    totalCount: payload.totalCount,
                    progress: false,
                },
            };
        case ONBOARDING_SCENARIO_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                listProgress: true,
            };
        case ONBOARDING_SCENARIO_LIST_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                listProgress: false,
            };
        case ONBOARDING_SCENARIO_LIST_ERROR:
            return {
                ...state,
                error: payload,
                listProgress: false,
            };
        case GET_ONBOARDING_SCENARIO_LOG_REQUEST:
            return {
                ...state,
                logPageData: payload,
                logListProgress: true,
            };
        case GET_ONBOARDING_SCENARIO_LOG_SUCCESS:
            return {
                ...state,
                logList: payload.results,
                logTotalCount: payload.totalCount,
                logListProgress: false,
            };
        case GET_ONBOARDING_SCENARIO_LOG_ERROR:
            return {
                ...state,
                error: payload,
                logListProgress: false,
            };
        case GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_SUCCESS:
            return {
                ...state,
                projectParamsVariants: payload.results,
            };
        case GET_ONBOARDING_SCENARIO_PROMOCODE_SUCCESS:
            return {
                ...state,
                promoCodeVariants: payload.results,
            };
        case GET_ONBOARDING_SCENARIO_OBJECTS_SUCCESS:
            return {
                ...state,
                objectVariants: payload.results,
            };
        case GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_SUCCESS:
            return {
                ...state,
                bindingVariants: payload.results,
            };
        case GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_SUCCESS:
            return {
                ...state,
                additionalDocTypeDict: payload,
            };
        case ONBOARDING_SCENARIO_LEADS_EXPORT_REQUEST:
        case ONBOARDING_SCENARIO_LEADS_IMPORT_REQUEST:
        case ONBOARDING_LEADS_DELETE_REQUEST:
        case ONBOARDING_SCENARIO_DELETE_LEAD_REQUEST:
        case ADD_ONBOARDING_SCENARIO_REQUEST:
        case UPDATE_ONBOARDING_SCENARIO_REQUEST:
        case UPDATE_STATUS_ONBOARDING_SCENARIO_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ONBOARDING_SCENARIO_LEADS_EXPORT_SUCCESS:
        case ONBOARDING_SCENARIO_LEADS_EXPORT_ERROR:
        case ONBOARDING_SCENARIO_LEADS_IMPORT_SUCCESS:
        case ONBOARDING_SCENARIO_LEADS_IMPORT_ERROR:
        case ONBOARDING_LEADS_DELETE_SUCCESS:
        case ONBOARDING_LEADS_DELETE_ERROR:
        case ONBOARDING_SCENARIO_DELETE_LEAD_SUCCESS:
        case ONBOARDING_SCENARIO_DELETE_LEAD_ERROR:
        case ADD_ONBOARDING_SCENARIO_SUCCESS:
        case UPDATE_ONBOARDING_SCENARIO_SUCCESS:
        case ADD_ONBOARDING_SCENARIO_ERROR:
        case UPDATE_ONBOARDING_SCENARIO_ERROR:
        case UPDATE_STATUS_ONBOARDING_SCENARIO_SUCCESS:
        case UPDATE_STATUS_ONBOARDING_SCENARIO_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_ONBOARDING_SCENARIO_CARD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_ONBOARDING_SCENARIO_CARD_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case GET_ONBOARDING_SCENARIO_CARD_ERROR:
            return {
                ...state,
                progress: false,
            };
        case ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_SUCCESS:
            return {
                ...state,
                isClientBelongsGroup: payload.result,
            };
        case ONBOARDING_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export const getOnboardingScenarioList = (payload) => ({
    type: ONBOARDING_SCENARIO_LIST_REQUEST,
    payload,
});

export const getOnboardingScenarioLog = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_LOG_REQUEST,
    payload,
});

export const getOnboardingProjectParamsVariants = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_REQUEST,
    payload,
});

export const getOnboardingPromoCodeVariants = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_PROMOCODE_REQUEST,
    payload,
});

export const getOnboardingObjectVariants = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_OBJECTS_REQUEST,
    payload,
});

export const getOnboardingBindingVariants = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_REQUEST,
    payload,
});

export const getOnboardingAdditionalDocumentsTypeDict = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_REQUEST,
    payload,
});

export const addOnboardingScenario = (payload) => ({
    type: ADD_ONBOARDING_SCENARIO_REQUEST,
    payload,
});

export const updateOnboardingScenario = (payload) => ({
    type: UPDATE_ONBOARDING_SCENARIO_REQUEST,
    payload,
});

export const getOnboardingScenarioCard = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_CARD_REQUEST,
    payload,
});

export const updateStatusOnboardingScenario = (payload) => ({
    type: UPDATE_STATUS_ONBOARDING_SCENARIO_REQUEST,
    payload,
});

export const checkClientBelongsToAnyClientGroup = (payload) => ({
    type: ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_REQUEST,
    payload,
});

export const updateOnboardingStore = (payload) => ({
    type: ONBOARDING_UPDATE_STORE,
    payload,
});

export const getOnboardingScenarioLeadsPage = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_REQUEST,
    payload,
});

export const getOnboardingLeadsPage = (payload) => ({
    type: GET_ONBOARDING_LEADS_GET_PAGE_REQUEST,
    payload,
});

export const deleteOnboardingScenarioLead = (payload) => ({
    type: ONBOARDING_SCENARIO_DELETE_LEAD_REQUEST,
    payload,
});

export const deleteOnboardingLead = (payload) => ({
    type: ONBOARDING_LEADS_DELETE_REQUEST,
    payload,
});

export const importOnboardingScenarioLeads = (payload) => ({
    type: ONBOARDING_SCENARIO_LEADS_IMPORT_REQUEST,
    payload,
});

export const exportOnboardingScenarioLeads = (payload) => ({
    type: ONBOARDING_SCENARIO_LEADS_EXPORT_REQUEST,
    payload,
});

export const getOnboardingScenarioDefaultWelcomeString = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_REQUEST,
    payload,
});

export const onboardingSelector = state => state.onboarding;
export const onboardingListSelector = createSelector(onboardingSelector, ({list}) => list);
export const onboardingScenarioCardSelector = createSelector(onboardingSelector, ({card}) => card);
export const onboardingScenarioProgressSelector = createSelector(onboardingSelector, ({progress}) => progress);
export const onboardingScenarioProgressActionSelector = createSelector(onboardingSelector, ({progressAction}) => progressAction);
export const onboardingListTotalPagesSelector = createSelector(onboardingSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const onboardingListTotalCountSelector = createSelector(onboardingSelector, ({totalCount}) => totalCount);
export const onboardingListProgressSelector = createSelector(onboardingSelector, ({listProgress}) => listProgress);
export const onboardingLogListSelector = createSelector(onboardingSelector, ({logList}) => logList);
export const onboardingLogListTotalPagesSelector = createSelector(onboardingSelector, ({
    logTotalCount,
    logPageData: {pageSize = 0},
}) => getTotalPages(logTotalCount, pageSize));
export const onboardingLogListTotalCountSelector = createSelector(onboardingSelector, ({logTotalCount}) => logTotalCount);
export const onboardingLogListProgressSelector = createSelector(onboardingSelector, ({logListProgress}) => logListProgress);
export const onboardingPromoCodeVariantsOptionsSelector = createSelector(onboardingSelector, ({promoCodeVariants}) => {
    return promoCodeVariants.map(item => ({
        key: item,
        text: item,
        value: item,
    }));
});
export const onboardingProjectParamsVariantsOptionsSelector = createSelector(onboardingSelector, ({projectParamsVariants}) => {
    return projectParamsVariants.map(item => ({
        key: item,
        text: item,
        value: item,
    }));
});
export const onboardingObjectVariantsSelector = createSelector(onboardingSelector, ({objectVariants}) => objectVariants);
export const onboardingObjectVariantsOptionsSelector = createSelector(onboardingSelector, ({objectVariants}) => {
    return objectVariants.map(item => ({
        key: item.objectId,
        text: item.objectName || "",
        value: item.objectId,
    }));
});
export const onboardingBindingVariantsOptionsSelector = createSelector(onboardingSelector, ({bindingVariants}) => {
    return bindingVariants.map(item => ({
        key: item.scenarioId,
        text: item.name || "",
        value: item.scenarioId,
    }));
});
export const onboardingBindingVariantsSelector = createSelector(onboardingSelector, ({bindingVariants}) => bindingVariants);
export const onboardingIsClientBelongsGroupSelector = createSelector(onboardingSelector, ({isClientBelongsGroup}) => isClientBelongsGroup);
export const additionalDocTypeDictSelector = createSelector(onboardingSelector, ({additionalDocTypeDict}) => additionalDocTypeDict);
export const onboardingScenarioLeadsSelector = createSelector(onboardingSelector, ({leads}) => {
    return {
        ...leads,
        totalPages: getTotalPages(leads.totalCount, leads.pageData.pageSize),
    };
});
export const onboardingLeadsSelector = createSelector(onboardingSelector, ({contractorsLeads}) => {
    return {
        ...contractorsLeads,
        totalPages: getTotalPages(contractorsLeads.totalCount, contractorsLeads.pageData.pageSize),
    };
});

export const onBoardingWelcomeStringSelector = createSelector(
    onboardingSelector,
    ({welcomeString}) => welcomeString,
);

export const getOnboardingScenarioListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getOnboardingScenariosPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_SCENARIO_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: ONBOARDING_SCENARIO_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: ONBOARDING_SCENARIO_LIST_ERROR, payload: error});
    }
};

export const getOnboardingScenarioLogListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getOnboardingScenarioEventLogPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_LOG_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_LOG_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_LOG_ERROR, payload: error});
    }
};

export const getOnboardingProjectParamsVariantsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/projectParamsVariants/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_ERROR, payload: error});
    }
};

export const getOnboardingPromoCodeVariantsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/promoCodeVariants/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_PROMOCODE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_PROMOCODE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_PROMOCODE_ERROR, payload: error});
    }
};

export const getOnboardingObjectVariantsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/objectVariants/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_OBJECTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_OBJECTS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_OBJECTS_ERROR, payload: error});
    }
};

export const getOnboardingBindingVariantsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/bindingVariants/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_ERROR, payload: error});
    }
};

export const getOnboardingAdditionalDocumentsTypeDictSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/common/dicts/getContractorAdditionalDocumentTypeDict", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_ERROR, payload: error});
    }
};

export const addOnboardingScenarioSaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.bff.post(`${getController()}/addOnboardingScenario`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ONBOARDING_SCENARIO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(result);
        yield put({type: ADD_ONBOARDING_SCENARIO_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: ADD_ONBOARDING_SCENARIO_ERROR, payload: error});
    }
};

export const updateOnboardingScenarioSaga = function* ({payload}) {
    try {
        const {
            formData,
            onError = () => {
            },
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.bff.post(`${getController()}/scenario-card/updateParams`, formData, {...getMultipartHeaders()});

        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {
            errorCode === ONBOARDING_SCENARIO_UPDATE_ERROR_CODE.UPDATED_ONBOARDING_SCENARIO_ALREADY_IN_USE_BY_CONTRACTORS ?
                onError(errorCode) :
                toastError(errorMessage);

            yield put({
                type: UPDATE_ONBOARDING_SCENARIO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: UPDATE_ONBOARDING_SCENARIO_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: UPDATE_ONBOARDING_SCENARIO_ERROR, payload: error});
    }
};

export const getOnboardingScenarioCardSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/scenario-card/getOnboardingScenarioRichModel`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_CARD_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_CARD_ERROR, payload: error});
    }
};

export const updateStatusOnboardingScenarioSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.bff.post(`${getController()}/scenario-card/updateStatus`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_STATUS_ONBOARDING_SCENARIO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: UPDATE_STATUS_ONBOARDING_SCENARIO_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: UPDATE_STATUS_ONBOARDING_SCENARIO_ERROR, payload: error});
    }
};

export const checkClientBelongsToAnyClientGroupSaga = function* ({payload}) {
    try {
        const {
            clientId,
            onSuccess = () => {
            },
        } = payload;

        const result = yield request.bff.post(`${getController()}/isClientBelongsToAnyClientGroup?clientId=${clientId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_ERROR, payload: error});
    }
};

// Получить страницу лидов сценария
export const getOnboardingScenarioLeadsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/scenario-card/lead/leads/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        if (error.message) {
            toastError(error.message);
        }

        yield put({type: GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_ERROR, payload: error});
    }
};

// POST /bff/adm/contractors/client-contractors/onboarding/leads/getPage
// Получить страницу лидов онбординга
export const getOnboardingLeadsLeadsPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/contractors/client-contractors/onboarding/leads/getPage", payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_LEADS_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (result.results.length) {
            const contractorIds = result.results.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({type: GET_ONBOARDING_LEADS_GET_PAGE_SUCCESS, payload: result});
    } catch (error) {
        if (error.message) {
            toastError(error.message);
        }

        yield put({type: GET_ONBOARDING_LEADS_GET_PAGE_ERROR, payload: error});
    }
};

// POST /bff/adm/clients/client-card/contractors/onboarding/scenario-card/lead/leads/delete
// POST /bff/client-adm/contractors/onboarding/scenario-card/lead/leads/delete
// Удалить лид онбординга по id
export const deleteOnboardingScenarioLeadSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/scenario-card/lead/leads/delete`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_SCENARIO_DELETE_LEAD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: ONBOARDING_SCENARIO_DELETE_LEAD_SUCCESS});
    } catch (error) {
        if (error.message) {
            toastError(error.message);
        }

        yield put({type: ONBOARDING_SCENARIO_DELETE_LEAD_ERROR, payload: error});
    }
};

// POST /bff/adm/onboarding/leads/delete
// POST /bff/adm/contractors/client-contractors/onboarding/leads/delete
// Удалить лид онбординга по id
export const deleteOnboardingLeadSaga = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post("/adm/contractors/client-contractors/onboarding/leads/delete", reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_LEADS_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: ONBOARDING_LEADS_DELETE_SUCCESS});
    } catch (error) {
        if (error.message) {
            toastError(error.message);
        }

        yield put({type: ONBOARDING_LEADS_DELETE_ERROR, payload: error});
    }
};

// Импорт лидов в сценарий онбординга
export const importOnboardingScenarioLeadsSaga = function* ({payload}) {
    const {
        onSuccess,
        clientId,
        scenarioId,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(
            `${getController()}/scenario-card/lead/${clientId}/${scenarioId}/leads/import`,
            formData,
            {...getMultipartHeaders()},
        );
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_SCENARIO_LEADS_IMPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: ONBOARDING_SCENARIO_LEADS_IMPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ONBOARDING_SCENARIO_LEADS_IMPORT_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/contractors/onboarding/scenario-card/leads/export
//POST /bff/client-adm/contractors/onboarding/scenario-card/leads/export
// Запустить экспорт лидов сценария
export const exportOnboardingScenarioLeadsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/scenario-card/leads/export`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ONBOARDING_SCENARIO_LEADS_EXPORT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(MESSAGES_EXPORT_TOAST.replace(":name", "лидов"));

        yield put({type: ONBOARDING_SCENARIO_LEADS_EXPORT_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: ONBOARDING_SCENARIO_LEADS_EXPORT_ERROR, payload: error});
    }
};

export const getOnboardingScenarioDefaultWelcomeStringSaga = function* ({payload}) {
    const {clientId} = payload;

    try {
        const result = yield request.bff.get(`${getController()}/default-welcome-string`, {params: {clientId}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(ONBOARDING_SCENARIO_LIST_REQUEST, getOnboardingScenarioListSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_LOG_REQUEST, getOnboardingScenarioLogListSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_PROJECT_PARAMS_REQUEST, getOnboardingProjectParamsVariantsSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_PROMOCODE_REQUEST, getOnboardingPromoCodeVariantsSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_OBJECTS_REQUEST, getOnboardingObjectVariantsSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_BINDING_VARIANTS_REQUEST, getOnboardingBindingVariantsSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_ADDITIONAL_DOC_TYPE_DICT_REQUEST, getOnboardingAdditionalDocumentsTypeDictSaga),
        takeEvery(ADD_ONBOARDING_SCENARIO_REQUEST, addOnboardingScenarioSaga),
        takeEvery(UPDATE_ONBOARDING_SCENARIO_REQUEST, updateOnboardingScenarioSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_CARD_REQUEST, getOnboardingScenarioCardSaga),
        takeEvery(UPDATE_STATUS_ONBOARDING_SCENARIO_REQUEST, updateStatusOnboardingScenarioSaga),
        takeEvery(ONBOARDING_CHECK_IS_CLIENT_BELONGS_GROUP_REQUEST, checkClientBelongsToAnyClientGroupSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_LEADS_GET_PAGE_REQUEST, getOnboardingScenarioLeadsPageSaga),
        takeEvery(GET_ONBOARDING_LEADS_GET_PAGE_REQUEST, getOnboardingLeadsLeadsPageSaga),
        takeEvery(ONBOARDING_SCENARIO_DELETE_LEAD_REQUEST, deleteOnboardingScenarioLeadSaga),
        takeEvery(ONBOARDING_LEADS_DELETE_REQUEST, deleteOnboardingLeadSaga),
        takeEvery(ONBOARDING_SCENARIO_LEADS_IMPORT_REQUEST, importOnboardingScenarioLeadsSaga),
        takeEvery(ONBOARDING_SCENARIO_LEADS_EXPORT_REQUEST, exportOnboardingScenarioLeadsSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_DEFAULT_WELCOME_STRING_REQUEST, getOnboardingScenarioDefaultWelcomeStringSaga),
    ]);
}