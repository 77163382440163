import {
    CLIENT_PROJECTS_CARD_ADD_USER_REQUEST,
    CLIENT_PROJECTS_CARD_CLEAR_STORE,
    CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST,
    CLIENT_PROJECTS_CARD_GET_REQUEST,
    CLIENT_PROJECTS_CARD_GET_USERS_REQUEST,
    CLIENT_PROJECTS_CARD_UPDATE_STORE,
} from "./actions";

export const getClientProjectCard = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_GET_REQUEST,
        payload,
    };
};

export const getClientProjectCardUsers = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_GET_USERS_REQUEST,
        payload,
    };
};

export const addUserClientProjectCard = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_ADD_USER_REQUEST,
        payload,
    };
};

export const deleteUserClientProjectCard = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST,
        payload,
    };
};

export const updateStoreClientProjectCard = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_UPDATE_STORE,
        payload,
    };
};

export const clearStoreClientProjectCard = (payload) => {
    return {
        type: CLIENT_PROJECTS_CARD_CLEAR_STORE,
        payload,
    };
};