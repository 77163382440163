import React from "react";
import Media from "react-media";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmReadonlyRating from "../../../components/NmReadonlyRating";
import ReviewsList from "../../reviews/components/reviews-list";
import ReviewEditForm from "./components/add-form";

import {usePagination} from "../../../hooks/usePagination";
import {useContractorReviewsAction} from "./hooks/useAction";
import {useContractorReviewsFetchList} from "./hooks/useFetchList";

import bem from "../../../utils/bem";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import pluralize from "../../../utils/pluralize";
import {isAccessByRestrictions} from "../../../utils/restrictions";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {REVIEW_REPLY_STATUS} from "../../../constants/reviews";
import {
    ADMIN,
    isClientUser,
    NM_MANAGER,
} from "../../../constants/roles";

import {contractorCardInfoSelector} from "../../../ducks/bff/contractor-сard/selectors";
import {
    reviewsContractorListSelector,
    reviewsContractorProgressActionSelector,
    reviewsContractorProgressSelector,
    reviewsContractorTotalCountSelector,
    reviewsContractorTotalPagesSelector,
} from "../../../ducks/bff/reviews/contractor/selectors";

import "./style.sass";

export const REVIEW_STATUS = {
    DRAFT: "DRAFT",
    PUBLISHED: "PUBLISHED",
};

function ContractorReviewsPage(props) {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const [block, element] = bem("contractor-reviews-page");

    const contractorCard = useSelector(contractorCardInfoSelector);
    const list = useSelector(reviewsContractorListSelector);
    const totalCount = useSelector(reviewsContractorTotalCountSelector);
    const totalPages = useSelector(reviewsContractorTotalPagesSelector);
    const progressPage = useSelector(reviewsContractorProgressSelector);
    const actionProgress = useSelector(reviewsContractorProgressActionSelector);

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        fetchList,
    } = useContractorReviewsFetchList({
        contractorId,
        pageNum,
        pageSize,
    });

    const {
        confirmAction,
        setConfirmAction,
        closeConfirmModal,
        isOpenAddForm,
        setIsOpenAddForm,
        onConfirmContractorScore,
        onDeleteContractorScore,
        onDeclineContractorScore,
        changeStatusScoreReply,
    } = useContractorReviewsAction({fetchList, contractorId});

    const getMediaActions = (item) => {
        if (
            ![ADMIN, NM_MANAGER].includes(role)
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsReviews,
            ])
        ) {
            return null;
        }

        const {
            baseModel: {
                status,
                id,
                reply,
            },
        } = item;

        const isReplyPresent = !isEmpty(reply);

        return {
            renderCount: {
                desktop: 5,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Подтвердить отзыв",
                        color: "light-green",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите опубликовать отзыв?",
                                onConfirm: () => {
                                    onConfirmContractorScore(id);
                                },
                            });
                        },
                    },
                    visible: status === REVIEW_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отклонить отзыв",
                        color: "grey",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите отклонить публикацию отзыва?",
                                onConfirm: () => {
                                    onDeclineContractorScore(id);
                                },
                            });
                        },
                    },
                    visible: status === REVIEW_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Подтвердить ответ",
                        color: "light-green",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите опубликовать ответ на отзыв?",
                                onConfirm: () => changeStatusScoreReply(id, REVIEW_REPLY_STATUS.PUBLISHED),
                            });
                        },
                    },
                    visible: reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отклонить ответ",
                        color: "grey",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: "Вы уверены, что хотите отклонить публикацию ответа на отзыв?",
                                onConfirm: () => changeStatusScoreReply(id, REVIEW_REPLY_STATUS.DECLINED),
                            });
                        },
                    },
                    visible: reply?.status === REVIEW_REPLY_STATUS.DRAFT,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Удалить",
                        color: "grey",
                        onClick: () => {
                            setConfirmAction({
                                isOpen: true,
                                content: `Вы уверены, что хотите удалить отзыв${isReplyPresent ? " и ответ на него" : ""}?`,
                                onConfirm: () => {
                                    onDeleteContractorScore(id);
                                },
                            });
                        },
                    },
                    visible: status === REVIEW_STATUS.PUBLISHED
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.deleteContractorScores,
                        ]),
                },
            ],
        };
    };

    const renderAddForm = () => {
        return (
            isOpenAddForm &&
            <ReviewEditForm
                contractorId={contractorId}
                fetchList={fetchList}
                onClose={() => {
                    setIsOpenAddForm(false);
                }}
                progress={actionProgress}
            />
        );
    };

    const renderConfirmModal = () => {
        const {
            isOpen,
            content,
            onConfirm,
        } = confirmAction;

        return (
            isOpen &&
            <NmConfirmV2
                content={content}
                onCancel={closeConfirmModal}
                onConfirm={onConfirm}
                confirmButton="Продолжить"
                cancelButton="Отменить"
                isOnlyConfirm
                loading={actionProgress}
            />
        );
    };

    const renderInfoBlock = () => {
        const {
            overallRating,
            overallQualityRating,
            overallTimeRating,
            scoreCount,
        } = contractorCard;

        return (
            <Media query={{maxWidth: 767}}>
                {
                    mobile =>
                        <div className={element("info-block", {mobile})}>
                            <div>
                                <NmLabelText
                                    className="mb-2"
                                    type="page"
                                    label="Общий рейтинг"
                                    classNameLabel={element("info-block-label")}
                                    text={<NmReadonlyRating
                                        rating={overallRating}
                                        maxRating={5}
                                        fixedNumber={2}
                                    />}
                                />
                                <NmLabelText
                                    className="mb-2"
                                    type="page"
                                    label="Качество работ"
                                    classNameLabel={element("info-block-label")}
                                    text={<NmReadonlyRating
                                        rating={overallQualityRating}
                                        maxRating={5}
                                        fixedNumber={2}
                                    />}
                                />
                                <NmLabelText
                                    className="mb-2"
                                    type="page"
                                    label="Соблюдение сроков"
                                    classNameLabel={element("info-block-label")}
                                    text={<NmReadonlyRating
                                        rating={overallTimeRating}
                                        maxRating={5}
                                        fixedNumber={2}
                                    />}
                                />
                                <NmLabelText
                                    type="page"
                                    label="Всего"
                                    classNameLabel={element("info-block-label")}
                                    text={scoreCount ? pluralize(scoreCount, ["оценка", "оценки", "оценок"]) : "Отзывов пока нет"}
                                />
                            </div>
                            {
                                isClientUser(role) &&
                                contractorCard.frameContractSigned &&
                                <NmButton
                                    className={element("info-block-button", {mobile})}
                                    size="lg"
                                    color="light-green"
                                    onClick={() => setIsOpenAddForm(true)}
                                >
                                    Оставить отзыв
                                </NmButton>
                            }
                        </div>
                }
            </Media>
        );
    };

    return (
        <NmPage
            className={block()}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progressPage}
            showHeaderBlock={false}
            noPadding
        >
            {renderAddForm()}
            {renderConfirmModal()}
            {renderInfoBlock()}
            {
                list?.length ?
                    <ReviewsList
                        role={role}
                        isContractorPage
                        list={list}
                        getMediaActions={(item) => getMediaActions(item)}
                    /> :
                    <NmEmptyPageV2
                        fetchProgress={progressPage}
                    />
            }
        </NmPage>
    );
}

export default ContractorReviewsPage;