import React from "react";
import {useEffect, useState} from "react";

import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmListCard from "../ActualComponents/NmList/Card";
import NmModal from "../ActualComponents/NmModal";
import Tabs from "../ActualComponents/Tabs";
import CheckboxList from "../CheckboxList";
import NmPage from "../NmPage";
import NmTitle from "../NmTitle";

import {usePagination} from "../../hooks/usePagination";

import {formatLocalDate} from "../../utils/dateFormat";
import {_delay} from "../../utils/delay";
import {formatAmountWithNullChecking, getFullName} from "../../utils/stringFormat";

export const DepositHistoryModal = (props) => {
    const {
        list,
        totalPages,
        totalCount,
        fetchList,
        headerTextBeforeName,
        onClose,
        logData,
        actionDict,
    } = props;

    const {
        name,
        clientId,
        objectId,
        projectId,
    } = logData;

    const [loading, setLoading] = useState(false);
    const [isNdfl, setIsNdfl] = useState(false);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        setLoading(true);
        fetchList({
            pageNum,
            pageSize,
            projectId,
            objectId,
            clientId,
            isNdfl,
            getResult: () => _delay(() => setLoading(false)),
        });
    },
    [
        pageNum,
        pageSize,
        isNdfl,
    ]);

    function getTabs() {
        return [
            {
                name: "Депозит объекта НПД",
                active: !isNdfl,
                onClick: () => setIsNdfl(false),
            },
            {
                name: "Депозит объекта НДФЛ",
                active: isNdfl,
                onClick: () => setIsNdfl(true),
            },
        ];
    }

    function getRows() {
        return list.map(item => {
            const {action, datetime, amount, firstName, lastName, patronymic} = item;

            return {
                ...item,
                key: datetime,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-md-12 col-xxl-12 col-xl-12"
                        primaryHeader={actionDict[action] || action}
                        labels={[
                            {
                                label: "Дата",
                                text: formatLocalDate(datetime, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Пользователь",
                                text: getFullName(lastName, firstName, patronymic),
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма, ₽",
                                value: formatAmountWithNullChecking(amount),
                                className: "col-16 col-md-4 col-xl-4 col-xxl-4",
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`${headerTextBeforeName} ${name}`}
                </NmTitle>
            }
            loading={loading}
        >
            <NmPage
                showHeaderBlock={false}
                noPadding
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
            >
                <Tabs
                    className="mb-4"
                    panes={getTabs()}
                />
                {
                    list.length
                        ? <CheckboxList rows={getRows()} />
                        : <NmEmptyPageV2 title="Данные отсутствуют" />
                }
            </NmPage>
        </NmModal>
    );
};