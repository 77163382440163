import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
    BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST,
    BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS,
    BFF_CONTRACTOR_READ_ERROR,
    BFF_CONTRACTOR_READ_REQUEST,
    BFF_CONTRACTOR_READ_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
    BFF_CONTRACTOR_REGISTRY_ADD_REQUEST,
    BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST,
    BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
    BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
    BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST,
    BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
    CONTRACTOR_ADD_FAVORITE_ERROR,
    CONTRACTOR_ADD_FAVORITE_REQUEST,
    CONTRACTOR_ADD_FAVORITE_SUCCESS,
    CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
    CONTRACTOR_ADD_TO_BLACKLIST_REQUEST,
    CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS,
    CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
    CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST,
    CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS,
    CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
    CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST,
    CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS,
    CONTRACTOR_SHORT_LIST_READ_ERROR,
    CONTRACTOR_SHORT_LIST_READ_REQUEST,
    CONTRACTOR_SHORT_LIST_READ_SUCCESS,
    ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
    ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST,
    ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError, toastSuccess, toastWarning} from "../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../utils/url";

import {
    CLIENT_CARD,
    CONTRACTOR_CARD,
    LINK_ADVERTISEMENT, LINK_APPLICATION_ANALYTICS,
    LINK_BlACK_LIST,
    LINK_CLIENT_CONTRACTORS_ALL,
    LINK_CLIENT_CONTRACTORS_MY,
    LINK_CONTRACTORS_RESOURCES,
    LINK_FAVORITE,
    LINK_FINANCES_REGISTRY,
    LINK_ORDER_ACT_REGISTRY,
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
} from "../../../../constants/links";
import {STAFF} from "../../../../constants/messages";

const getController = () => {
    return getBffUrl({
        [LINK_ADVERTISEMENT]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/advertisements",
            client: "/adm/clients/client-card/advertisements",
        }),
        [LINK_ORDER_ACT_REGISTRY]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/orders/act-registry/card/contractor",
            client: "/client-adm/orders/act-registry/card/contractor",
        }),
        [LINK_FINANCES_REGISTRY]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/finances/registry-payments/registry/registry-card/contractor",
            client: "/client-adm/finances/registry-payments/registry/registry-card/contractor",
        }),
        [LINK_BlACK_LIST]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/contractors/black-list",
            client: "/client-adm/contractors/contractors-registry/black-list",
        }),
        [LINK_FAVORITE]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/contractors/favourite",
            client: "/client-adm/contractors/contractors-registry/favourite",
        }),
        [LINK_CLIENT_CONTRACTORS_ALL]: "/client-adm/contractors/contractors-registry/all",
        [LINK_CONTRACTORS_RESOURCES]: "/adm/contractors/contractors-resources",
        [LINK_CLIENT_CONTRACTORS_MY]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/contractors/company-contractors",
            client: "/client-adm/contractors/contractors-registry/company-contractors",
        }),
        [CLIENT_CARD]: getBffControllerClientCardPage({
            admin: "adm/clients/client-card/contractors/contractors-registry",
            client: "/client-adm/contractors/contractors-registry",
        }),
        [CONTRACTOR_CARD]: getBffControllerClientCardPage({
            admin: "/adm/contractors/contractors-registry/contractor-card",
            client: "/client-adm/contractors/contractor-card",
        }),
    },
    "/adm/contractors/contractors-registry",
    true,
    );
};

const getPageContractorsRegistry = function* ({payload}) {
    try {
        const otherUrl = getBffUrl({
            [LINK_ORDER_ACT_REGISTRY]: "/page",
        },
        "/getPage",
        true,
        );

        const result = yield request.bff.post(`${getController()}${otherUrl}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const importContractorsRegistry = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/prefilled/import`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR,
        });
    }
};

const addContractorsRegistry = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_REGISTRY_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteContractorFromBlackListSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {contractorId} = data;

        const otherUrl = getBffUrl({
            [LINK_BlACK_LIST]: contractorId ? "/delete" : "/deleteAll",
        },
        "/deleteFromBlacklist",
        true,
        );

        const result = yield request.bff.delete(`${getController()}${otherUrl}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (payload.onError) {
                payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Удаление из чёрного списка выполнено успешно");

        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.onError) {
            payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

const deleteFavoriteContractorsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {contractorId} = data;

        const otherUrl = getBffUrl({
            [LINK_FAVORITE]: contractorId ? "/delete" : "/deleteAll",
        },
        "/deleteFromFavourite",
        true,
        );

        const result = yield request.bff.delete(`${getController()}${otherUrl}`, {
            data,
        });

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage, {type: "error"});

            if (payload.onError) {
                payload.onError();
            }

            yield put({
                type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Удаление из избранных выполнено успешно");

        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.onError) {
            payload.onError();
        }

        yield put({
            type: CONTRACTOR_DELETE_FROM_FAVORITE_ERROR,
            payload: error,
        });
    }
};

const addFavoriteContractorsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {contractorId} = data;

        const result = yield request.bff.post(`${getController()}/${contractorId ? "addToFavourite" : "addAllToFavourite"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (payload.onError) {
                payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_FAVORITE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в избранные выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.onError) {
            payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_FAVORITE_ERROR,
            payload: error,
        });
    }
};

const addContractorToBlackListSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {contractorId} = data;

        const result = yield request.bff.post(`${getController()}/${contractorId ? "addToBlacklist" : "addAllToBlacklist"}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (payload.onError) {
                payload.onError();
            }

            yield put({
                type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Добавление в чёрный список выполнено успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.onError) {
            payload.onError();
        }

        yield put({
            type: CONTRACTOR_ADD_TO_BLACKLIST_ERROR,
            payload: error,
        });
    }
};

const addSeveralOrderContractorsSaga = function* ({payload}) {
    try {
        const {
            toastMessage,
            onSuccess,
            isMassOperation,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/addAll`, reqData);

        const {
            errorMessage,
            chosenProcessingType,
            syncResult,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        if (chosenProcessingType === "SYNC") {
            const {
                failCount,
                successCount,
                totalCount,
                orderInvitationResult,
            } = syncResult;

            const {contractorInvitationResult} = orderInvitationResult[`${payload.orderIds[0]}`];
            const error = Object.values({...contractorInvitationResult}).find(value => !value.success);


            if (error) {
                const {errorMessage, errorCode} = error;
                let _errorMessage = errorMessage;

                if ((errorCode === "PROHIBITION_CONTRACTING_NON_RESIDENTS")) {
                    _errorMessage = successCount > 0 ? "Не удалось пригласить некоторых исполнителей. В компании стоит запрет на заключение договоров с нерезидентами, обратитесь к администратору компании" : errorMessage;
                }

                toastError(_errorMessage);

                yield put({
                    type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
                    payload: result,
                });

                return {
                    done: true,
                };
            }

            if (successCount === totalCount) {
                toastSuccess("Выбранные исполнители успешно приглашены");
            }
            if (successCount !== 0 && failCount !== 0) {
                toastWarning("Не удалось пригласить некоторых исполнителей. Возможно они уже откликнулись или оказывают услуги(выполняют работы) на выбранных заказах");
            }
            if (failCount === totalCount) {
                toastError("Не удалось пригласить выбранных исполнителей. Возможно они уже откликнулись или оказывают услуги(выполняют работы) на выбранных заказах");
            }
        } else {
            toastSuccess(toastMessage);
        }

        yield put({
            type: ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS,
        });
    } catch (error) {
        toastError(STAFF.STAFF_ADD_ERROR_TEXT);

        yield put({
            type: ORDER_CONTRACTOR_ADD_SEVERAL_ERROR,
            payload: error,
        });
    }
};

const getContractorShortListSaga = function* ({payload}) {
    try {
        const controller = getBffControllerClientCardPage({
            admin: "/adm/common/contractor",
            client: "/client-adm/common/contractor",
        });

        const result = yield request.bff.post(`${controller}/get-contractor-short-page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTOR_SHORT_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_SHORT_LIST_READ_ERROR,
            payload: error,
        });
    }
};

const getContractorCardByIdSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/get-by-id`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CONTRACTOR_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_READ_ERROR,
            payload: error,
        });
    }
};

const contractorListByIdsSaga = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                [LINK_APPLICATION_ANALYTICS]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/reports/order-analytics/responses/hired/get-contractor-list-by-ids",
                    client: "/client-adm/reports/order-analytics/responses/hired/get-contractor-list-by-ids",
                }),
            },
            `${getController()}/getListByIds`,
            true,
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST, getPageContractorsRegistry),
        takeEvery(BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST, importContractorsRegistry),
        takeEvery(BFF_CONTRACTOR_REGISTRY_ADD_REQUEST, addContractorsRegistry),
        takeEvery(CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST, deleteContractorFromBlackListSaga),
        takeEvery(CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST, deleteFavoriteContractorsSaga),
        takeEvery(CONTRACTOR_ADD_FAVORITE_REQUEST, addFavoriteContractorsSaga),
        takeEvery(CONTRACTOR_ADD_TO_BLACKLIST_REQUEST, addContractorToBlackListSaga),
        takeEvery(ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST, addSeveralOrderContractorsSaga),
        takeEvery(CONTRACTOR_SHORT_LIST_READ_REQUEST, getContractorShortListSaga),
        takeEvery(BFF_CONTRACTOR_READ_REQUEST, getContractorCardByIdSaga),
        takeEvery(BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST, contractorListByIdsSaga),
    ]);
}