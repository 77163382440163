import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import FilterCustomer from "../../../components/ActualComponents/FilterCustomer";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";

import {useFilter} from "../../../hooks/useFilter";
import {usePagination} from "../../../hooks/usePagination";
import {useFinanceReconciliationActsActions} from "./hooks/useAction";
import {useFinanceReconciliationActsFetch} from "./hooks/useFetch";

import {formatLocalDate} from "../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {LINK_CLIENT_INFO} from "../../../constants/links";

import {
    mutualSettlementsReconciliationActListSelector,
    mutualSettlementsReconciliationActProgressActionSelector,
    mutualSettlementsReconciliationActProgressAddSelector,
    mutualSettlementsReconciliationActProgressSelector,
    mutualSettlementsReconciliationActTotalCountSelector,
    mutualSettlementsReconciliationActTotalPagesSelector,
} from "../../../ducks/mutualSettlementsReconciliationActs";

const FinanceMutualSettlementsReconciliationActs = (props) => {
    const {
        match: {params: {clientId}},
    } = props;

    const {t} = useTranslation();

    const list = useSelector(mutualSettlementsReconciliationActListSelector);
    const progress = useSelector(mutualSettlementsReconciliationActProgressSelector);
    const progressAction = useSelector(mutualSettlementsReconciliationActProgressActionSelector);
    const totalPages = useSelector(mutualSettlementsReconciliationActTotalPagesSelector);
    const totalCount = useSelector(mutualSettlementsReconciliationActTotalCountSelector);
    const progressAdd = useSelector(mutualSettlementsReconciliationActProgressAddSelector);

    const isClient = !isNullOrWhitespace(clientId);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        onChangeFilter,
        filter,
        filterData,
    } = useFilter({
        initFilter: {
            clientId: clientId ? clientId : "",
            toDate: null,
            fromDate: null,
        },
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useFinanceReconciliationActsFetch({
        filterData,
        pageNum,
        pageSize,
        clientId,
    });

    const {
        filters,
        confirmData,
        onCloseConfirm,
        getMediaControls,
        onClickAdd,
    } = useFinanceReconciliationActsActions({
        fetchList,
        clientId,
        filter,
    });

    const getClient = ({clientId, clientName, brand}) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {brand ? `${clientName} (${brand})` : clientName}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.actId,
                contentRow: (
                    <NmListCard
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-xxl-12"
                        secondaryHeader={`Дата создания ${formatLocalDate(item.createDateTime, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {
                                label: "Заказчик",
                                text: getClient(item),
                            },
                            {
                                label: "Период",
                                text: `${formatLocalDate(item.fromDate)}-${formatLocalDate(item.toDate)}`,
                            },
                        ]}
                        actionsClassName="col-1 col-xxl-4 justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                isNeedClosing={false}
                disabled={progressAction}
                content={content}
                confirmButton={t("button.confirm")}
                cancelButton={t("button.cancel")}
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    const renderFormingBlock = () => {
        if (
            !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.actionsReconciliationReports,
            ])
        ) {
            return null;
        }

        return (
            <NmForm
                horizontal
                addMargin
                className="mb-4 mb-xxl-5"
            >
                <div className="col-16 col-xxl-8">
                    <div className="row align-items-end gx-2">
                        {
                            !isClient &&
                            <div className="col-16 col-md-8 col-xxl-6">
                                <FilterCustomer
                                    isBff={true}
                                    name="clientId"
                                    onChange={onChangeFilter}
                                    value={filter.clientIdsFilter}
                                    placeholder="Выберите заказчика"
                                />
                            </div>
                        }
                        <div className="col-16 col-md-8 col-xxl-6">
                            <NmDateRangePickerV2
                                label="Период события"
                                size="lg"
                                startFieldName="fromDate"
                                endFieldName="toDate"
                                value={{
                                    fromDate: filter.fromDate,
                                    toDate: filter.toDate,
                                }}
                                isClearable={true}
                                onChange={onChangeFilter}
                            />
                        </div>
                        <div className="col-16 col-md-8 col-xxl-4 mt-3 mt-md-2 mt-xxl-0">
                            <NmButton
                                disabled={progressAdd}
                                color="green"
                                size="lg"
                                onClick={onClickAdd}
                            >
                                Сформировать
                            </NmButton>
                        </div>
                    </div>
                </div>
            </NmForm>
        );
    };

    return (
        <NmPage
            noPadding={isClient}
            className="system-transactions"
            isLoaded={progress}
            header={
                <NmTitle size="xl">
                    Акт сверки взаиморасчетов
                </NmTitle>
            }
            showHeaderBlock={true}
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            typeFilter="vertical"
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        >
            {renderConfirmWindow()}
            {renderFormingBlock()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};

export default FinanceMutualSettlementsReconciliationActs;