import * as Yup from "yup";

import {REG_EXP} from "../../../../../constants/regExp";
import {requiredMessage} from "../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

export const registryNewValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required(requiredMessage)
        .max(100, "Максимальная длина - 100 символов"),
    description: Yup.string()
        .max(255, "Максимальная длина - 255 символов"),
    objectId: Yup.string()
        .required(requiredMessage),
    paymentMethod: Yup.string()
        .nullable()
        .required(requiredMessage),
    automaticActRejectionDelayInDays: Yup.string()
        .when("automaticActRejection", {
            is: true,
            then: Yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .test("check", "Введите целое число от 1 до 60", (value) => {
                    if (!value) {
                        return true;
                    }

                    const _value = +value;
                    const isError = _value > 60 || _value < 1 || !REG_EXP.ONLY_NUMBERS.test(value);

                    return !isError;
                }),
        }),
});

export const registryNewAdminValidationSchema = registryNewValidationSchema.shape({
    creatorClientUserId: Yup.string()
        .required(requiredMessage),
});