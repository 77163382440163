import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositChangeModal from "../../../../components/DepositChangeModal";

import {returnClientObjectDeposit} from "../../../../ducks/bff/clients/objects/actionCreators";
import {clientObjectsProgressActionSelector} from "../../../../ducks/bff/clients/objects/selectors";

const ObjectsReturnDeposit = (props) => {
    const {
        name,
        onClose,
        clientId,
        objectId,
        projectId,
        projectName,
        fetchData = () => {},
    } = props;
    const dispatch = useDispatch();
    const progress = useSelector(clientObjectsProgressActionSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
        } = requestData;

        dispatch(returnClientObjectDeposit({
            amount,
            clientId,
            objectId,
            projectId,
            paymentType: requestData.type,
            onSuccess: () => {
                fetchData();
                onClose();
            },
        }));
    };

    return (
        <DepositChangeModal
            isReturnDeposit={true}
            onClose={onClose}
            targetText="объекта"
            targetName={name}
            onSubmit={onSubmit}
            progress={progress}
            lastPartConfirmText={` в индивидуальный депозит проекта "${projectName}"`}
        />
    );
};

export default ObjectsReturnDeposit;