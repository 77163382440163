import {createSelector} from "reselect";

import {getModalVacanciesList} from "../access-control/observers/selectors";

import {getTotalPages} from "../../../../utils/mathHelper";

const jobBoardsWebhookSelector = state => state.bff.recruitment.jobBoardsWebhook;
export const bffRecruitmentJobBoardsWebhookListSelector = createSelector(
    jobBoardsWebhookSelector,
    ({list}) => getModalVacanciesList(list),
);
export const bffRecruitmentJobBoardsWebhookTotalPagesSelector = createSelector(
    jobBoardsWebhookSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffRecruitmentJobBoardsProgressSelector = createSelector(
    jobBoardsWebhookSelector,
    ({progress}) => progress,
);