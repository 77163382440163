import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS,
} from "./actions";

export const getRecruitmentVacancyCandidatesInitialState = () => {
    return {
        progressAction: false,
        progress: false,
        progressCard: false,
        card: {},
        pageData: {},
        list: [],
        candidatesForResponsePageData: {},
        candidatesForResponseList: [],
        candidatesForResponseTotalCount: 0,
        candidatesForResponseProgress: false,
        error: null,
        cardError: null,
        feedList: [],
        feedProgress: false,
        messageTemplates: [],
        progressMessageTemplates: false,
        recruitersList: [],
    };
};

const initialState = getRecruitmentVacancyCandidatesInitialState();

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST:
            return {
                ...state,
                progressMessageTemplates: true,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_ERROR:
            return {
                ...state,
                progressMessageTemplates: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_SUCCESS:
            return {
                ...state,
                messageTemplates: payload.results,
                progressMessageTemplates: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR:
            return {
                ...state,
                cardError: payload,
                progressCard: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload.result,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS:
            return {
                ...state,
                progress: false,
                list: state.pageData.isLoadMore ? [...state.list, ...payload.results] : payload.results,
                totalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR:
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST:
            return {
                ...state,
                candidatesForResponsePageData: payload,
                candidatesForResponseProgress: true,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS:
            return {
                ...state,
                candidatesForResponseProgress: false,
                candidatesForResponseList: payload.results,
                candidatesForResponseTotalCount: payload.totalCount,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR:
            return {
                ...state,
                candidatesForResponseProgress: false,
            };
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST: {
            return {
                ...state,
                feedProgress: true,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS: {
            return {
                ...state,
                feedList: payload || [],
                feedProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR: {
            return {
                ...state,
                feedProgress: false,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_SUCCESS: {
            return {
                ...state,
                recruitersList: payload.clientUsers,
            };
        }
        case BFF_RECRUITMENT_VACANCY_CANDIDATES_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        default:
            return state;
    }
};