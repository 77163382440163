import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPageContractorFinanceBalance} from "../../../../../ducks/bff/contractor-сard/finance/balance/actionCreators";

export function useContractorFinanceBalanceFetch({filter = {}, pageSize, pageNum, contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getPageContractorFinanceBalance({
            pageNum,
            pageSize,
            contractorId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}