import React, {useMemo} from "react";
import {Draggable} from "react-beautiful-dnd";

import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as DragIndicatorIcon} from "../../../images/drag-indicator.svg";
import NmDropdownV2 from "../../ActualComponents/NmDropdownV2";
import NmForm from "../../ActualComponents/NmForm";
import Text from "../../ActualComponents/Text";
import {KedoDepartmentsDropdown} from "../../KedoDepartmentsDropdown";
import {KedoStaffDropdown} from "../../KedoStaffDropdown";
import NmButton from "../../NmButton";
import NmHint from "../../NmHint";

import {useDraggableInPortal} from "../hooks/useDraggableInPortal";

import {dictionaryToOptions} from "../../../utils/objectHelper";

import {COLOR} from "../../../constants/color";

import {
    KEDO_DOCUMENT_ROUTE_ACTION_TYPE,
    KEDO_DOCUMENT_ROUTE_ACTION_TYPE_DICT,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE_DICT,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_NUMBER_WORDS,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE,
    KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE_DICT,
    KEDO_DOCUMENT_ROUTE_SING_TYPE,
    KEDO_DOCUMENT_ROUTE_SING_TYPE_DICT,
} from "../../../constants/kedo/document-route";

const KedoDocumentRouteStepsListItem = (props) => {
    const {
        clientId,
        index,
        step,
        errors,
        touched,
        onDelete,
    } = props;

    const {
        temporaryId,
        matchParticipantType,
        actionType,
        isRootDepartment,
    } = step;

    const renderDraggable = useDraggableInPortal();

    const signTypeOptions = useMemo(() => {
        const options = dictionaryToOptions(KEDO_DOCUMENT_ROUTE_SING_TYPE_DICT);

        if (
            matchParticipantType !== KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.ROOT_DEPARTMENT_HEAD_OR_MCHD_USER
            && actionType === KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN
        ) {
            return options.filter(item => item.key !== KEDO_DOCUMENT_ROUTE_SING_TYPE.UKEP);
        }

        if (
            matchParticipantType === KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.ROOT_DEPARTMENT_HEAD_OR_MCHD_USER
            && actionType === KEDO_DOCUMENT_ROUTE_ACTION_TYPE.SIGN
        ) {
            return options.filter(item => item.key === KEDO_DOCUMENT_ROUTE_SING_TYPE.UKEP);
        }

        if (actionType === KEDO_DOCUMENT_ROUTE_ACTION_TYPE.APPROVE) {
            return options.filter(item => item.key === KEDO_DOCUMENT_ROUTE_SING_TYPE.PEP);
        }

        return options;
    }, [
        matchParticipantType,
        actionType,
    ]);

    const departmentRoleOptions = useMemo(() => {
        const options = dictionaryToOptions(KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE_DICT);

        if (isRootDepartment) {
            return options.filter(item => item.key === KEDO_DOCUMENT_ROUTE_PARTICIPANT_DEPARTMENT_ROLE_TYPE.DEPUTY_DEPARTMENT_HEAD);
        }

        return options;
    }, [
        isRootDepartment,
    ]);

    const onChange = (_event, params) => {
        if (props.onChange) {
            props.onChange(index, params);
        }
    };

    const getFields = () => {
        if (matchParticipantType === KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.FIXED_USER) {
            const staffInitialOption = step.kedoStaffId
                ? {
                    key: step.kedoStaffId,
                    value: step.kedoStaffId,
                    text: step.participantFio,
                }
                : null;

            return (
                <KedoStaffDropdown
                    clientId={clientId}
                    excludeRootDepartmentHead={true}
                    sortType="FIO_ASC"
                    required={true}
                    name="kedoStaffId"
                    value={step.kedoStaffId}
                    initialOption={staffInitialOption}
                    onChange={onChange}
                    error={touched?.kedoStaffId && errors?.kedoStaffId}
                />
            );
        }

        if (matchParticipantType === KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE.DEPARTMENT_USER) {
            const departmentsInitialOption = step.kedoDepartmentId
                ? {
                    key: step.kedoDepartmentId,
                    value: step.kedoDepartmentId,
                    text: step.departmentName,
                }
                : null;

            return (
                <>
                    <KedoDepartmentsDropdown
                        clientId={clientId}
                        required={true}
                        label="Управленческий отдел"
                        placeHolder="Выберите отдел"
                        name="kedoDepartmentId"
                        value={step.kedoDepartmentId}
                        initialOption={departmentsInitialOption}
                        onChange={onChange}
                        error={touched?.kedoDepartmentId && errors?.kedoDepartmentId}
                    />
                    <NmDropdownV2
                        required={true}
                        label="Роль в отделе"
                        placeHolder="Выберите роль"
                        name="departmentRole"
                        value={step.departmentRole}
                        onChange={onChange}
                        error={touched?.departmentRole && errors?.departmentRole}
                        options={departmentRoleOptions}
                    />
                </>
            );
        }

        return null;
    };

    return (
        <Draggable
            key={temporaryId}
            draggableId={temporaryId}
            index={index}
        >

            {renderDraggable((provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="d-flex flex-column mt-4"
                    style={{
                        borderRadius: 7,
                        backgroundColor: snapshot.isDragging
                            ? COLOR.SECONDARY_5
                            : COLOR.WHITE,
                        ...provided.draggableProps.style,
                        ...snapshot,
                    }}
                >
                    <NmHint
                        className={snapshot.isDragging && "p-4"}
                        reverse={true}
                        buttonColor="black"
                        chevronColor={COLOR.SECONDARY_45}
                        customButton={
                            <Text.Title
                                level="4"
                                className="mb-2"
                            >
                                {KEDO_DOCUMENT_ROUTE_PARTICIPANT_NUMBER_WORDS[index]}
                                {" "}
                                участник
                            </Text.Title>
                        }
                        actions={
                            index > 1 &&
                            <NmButton
                                onlyIcon={true}
                                color="grey"
                                icon={<DeleteIcon />}
                                size="sm"
                                onClick={() => onDelete(index)}
                            />
                        }
                        isActions={true}
                        requiredContent={
                            <div className="flex align-items-start pb-6">
                                <div
                                    {...provided.dragHandleProps}
                                >
                                    <NmButton
                                        className="mt-5 me-2"
                                        size="xl"
                                        onlyIcon
                                        color="grey"
                                        icon={
                                            <DragIndicatorIcon
                                                width={24}
                                                height={24}
                                            />
                                        }
                                    />
                                </div>
                                <NmDropdownV2
                                    required={true}
                                    label="Тип участника"
                                    placeholder="Выберете тип участника"
                                    name="matchParticipantType"
                                    options={dictionaryToOptions(KEDO_DOCUMENT_ROUTE_PARTICIPANT_TYPE_DICT)}
                                    value={matchParticipantType}
                                    onChange={onChange}
                                    error={touched?.matchParticipantType && errors?.matchParticipantType}
                                />
                            </div>
                        }
                        chevronSize={20}
                        fullWidth={true}
                        defaultOpen={true}
                    >
                        {
                            matchParticipantType &&
                            <NmForm addMargin={true}>
                                {getFields()}
                                <NmDropdownV2
                                    required={true}
                                    label="Тип действия"
                                    placeHolder="Выберите тип действия"
                                    name="actionType"
                                    value={step.actionType}
                                    onChange={onChange}
                                    error={touched?.actionType && errors?.actionType}
                                    options={dictionaryToOptions(KEDO_DOCUMENT_ROUTE_ACTION_TYPE_DICT)}
                                />
                                <NmDropdownV2
                                    required={true}
                                    label="Тип подписи"
                                    placeHolder="Выберите тип подписи"
                                    name="signType"
                                    value={step.signType}
                                    onChange={onChange}
                                    error={touched?.signType && errors?.signType}
                                    options={signTypeOptions}
                                />
                            </NmForm>
                        }
                    </NmHint>
                </div>
            ))}
        </Draggable>
    );
};

export default KedoDocumentRouteStepsListItem;