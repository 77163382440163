import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDivider from "../../../components/ActualComponents/NmDivider";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmLabel from "../../../components/ActualComponents/NmLabel";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmShowMoreText from "../../../components/ActualComponents/NmShowMoreText";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import Text from "../../../components/ActualComponents/Text";
import ApplyButtons from "../../../components/ApplyButtons";
import ExtLink from "../../../components/ExtLink";
import NmDropzoneApiImages from "../../../components/ImagesBlock";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as HammerIcon} from "../../../images/hammer_24.svg";
import {Divider} from "semantic-ui-react";

import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getText, getTextByProperty} from "../../../utils/objectHelper";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";
import validate from "../../../utils/validate";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    CHAT_LINK_PARAMS,
    DISPUTE_FIELD,
    DISPUTE_STATUS_RESULT_MSG,
} from "../../../constants/disputes";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_MEMBERS_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {DISPUTE_MESSAGES} from "../../../constants/messages";
import {ADMIN, CLIENT_ADMIN, FOREMAN, NM_MANAGER} from "../../../constants/roles";
import {STATUS} from "../../../constants/status";
import {disputeNewRule, newDisputeRegistryOrderRule, requiredMessage} from "../../../constants/validationRules";

import {
    getClientMemberById,
    getClientMemberList,
} from "../../../ducks/bff/clients/employees/actionCreators";
import {clientMemberOptionsSelector} from "../../../ducks/bff/clients/employees/selectors";
import {
    getDisputeCausesDict,
    getDisputeStatusDict,
    getTreatmentChannelDict,
} from "../../../ducks/bff/common/dicts/actionCreators";
import {
    causesDisputeDictsOptionsSelector,
    disputeStatusSelector,
    treatmentChannelsDictsOptionsSelector,
    treatmentChannelsDictsOptionsWithMobileSelector,
} from "../../../ducks/bff/common/dicts/selectors";
import {
    addDisputeFile,
    createEmptyDispute,
    deleteDisputeFile,
    getDisputeById,
    getDisputeFiles,
    getDisputesOrderListContractor,
    updateDispute,
    updateFieldDisputeStore,
    updateStatusDispute,
} from "../../../ducks/bff/disputes/actionCreators";
import {
    disputeChangeStatusSuccessSelector,
    disputeFileIsSuccessAddSelector,
    disputeFileIsSuccessDeleteSelector,
    disputeImageListSelector,
    disputeLoadingSelector,
    disputeOrderContractorOptionsListSelector,
    disputesActionProgressSelector,
    disputeSaveSuccessSelector,
    disputeSelector,
    emptyDisputeGuidSelector,
} from "../../../ducks/bff/disputes/selectors";
import {getPageOrders} from "../../../ducks/bff/orders/orders-registry/actionCreators";
import {ordersOptionsForCreatingDisputeSelector} from "../../../ducks/bff/orders/orders-registry/selectors";
import {
    clientListOptimizedOptionsSelector,
    getOptimizedClientList,
} from "../../../ducks/client";
import {
    clientMemberCardSelector,
    updateFieldClientMemberStore,
} from "../../../ducks/clientMember";

import PropTypes from "prop-types";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

class DisputesNew extends Component {
    static propTypes = {
        handleClose: PropTypes.func,
        showChatList: PropTypes.func,
        getOptimizedClientList: PropTypes.func,
        getPageOrders: PropTypes.func,
        disputeId: PropTypes.string,
        updateFieldDisputeStore: PropTypes.func,
        isFromChat: PropTypes.bool,
        orderId: PropTypes.string,
    };

    static defaultProps = {
        getOptimizedClientList: () => {
        },
        getPageOrders: () => {
        },
        dispute: {},
    };

    constructor(props) {
        super(props);

        const {disputeId} = this.props;

        this.state = {
            isShowExistDispute: !isNullOrWhitespace(disputeId),
            disputeId,
            disputeCheckForProps: {},
            dispute: {},
            isShowApprovedBlock: false,
            isShowContractorField: true,
            approvDescription: "",
            errorObj: {},
        };

        //isShowContractorField=false для реестровых споров
    }

    componentDidMount() {
        const {
            isShowExistDispute,
            disputeId,
        } = this.state;

        const {
            getDisputeById,
            getTreatmentChannelDict,
            getDisputeCausesDict,
            getDisputeStatusDict,
            getDisputeFiles,
            updateFieldDisputeStore,
            updateFieldClientMemberStore,
        } = this.props;

        this.role = ls(USER_ROLE);

        updateFieldDisputeStore({
            disputeImages: [],
            isSuccessDeleteFile: false,
        });

        if (isShowExistDispute) {
            getDisputeById({disputeId});
            getDisputeFiles({disputeId});
        } else {
            updateFieldClientMemberStore({card: {}});
            this.createEmptyDispute();
            this.fetchClientList();
        }

        getTreatmentChannelDict();
        getDisputeCausesDict();
        getDisputeStatusDict();
    }

    static getDerivedStateFromProps(props, state) {
        const {
            disputeCheckForProps,
        } = state;

        const {
            dispute,
        } = props;

        if (JSON.stringify(disputeCheckForProps) !== JSON.stringify(dispute)) {
            return {
                ...state,
                dispute,
                disputeCheckForProps: dispute,
            };
        }
        return state;
    }

    componentDidUpdate(prevProps) {
        const {
            isDisputeSavedSuccess,
            handleClose,
            changeStatusSuccess,
            isImageLoaded,
            updateFieldDisputeStore,
            isImageDeleted,
        } = this.props;

        if (isDisputeSavedSuccess || changeStatusSuccess) {
            handleClose();
            return;
        }
        if (isImageLoaded || isImageDeleted) {
            updateFieldDisputeStore({
                isSuccessAddFile: false,
                isSuccessDeleteFile: false,
            });
            this.fetchDisputeFiles();
        }
    }

    componentWillUnmount() {
        const {
            updateFieldDisputeStore,
        } = this.props;

        updateFieldDisputeStore({
            disputeImages: [],
            isSuccessAddFile: false,
            isSuccessDeleteFile: false,
        });
    };

    createEmptyDispute = () => {
        const {
            createEmptyDispute,
        } = this.props;

        createEmptyDispute();
    };

    updateDispute = () => {
        const {
            dispute,
            isShowContractorField,
            registryContractorId,
        } = this.state;

        //меняем правило для реестровых заказов - исполнитель не нужен
        const error = validate(dispute, isShowContractorField ? disputeNewRule : newDisputeRegistryOrderRule, "");

        if (Object.values(error).length !== 0) {
            this.setState({errorObj: {...error}});
            return;
        }
        const {emptyDisputeGuid, updateDispute} = this.props;

        const requestData = {
            ...dispute,
            disputeId: emptyDisputeGuid,
            contractorId: isShowContractorField ? dispute[DISPUTE_FIELD.CONTRACTOR_ID] : registryContractorId,
            statusDispute: CHAT_LINK_PARAMS.OPEN.toUpperCase(),
        };
        updateDispute(requestData);
    };

    fetchClientList = () => {
        const {
            getOptimizedClientList,
        } = this.props;

        const {
            clientNameFilter,
        } = this.state;

        getOptimizedClientList({
            pageNum: 1,
            pageSize: 25,
            disputeFilter: true,
            nameSubstringFilter: isNullOrWhitespace(clientNameFilter) ? null : clientNameFilter.trim(),
        });
    };

    fetchOrdersList = () => {
        const {
            orderNameFilter,
            orderField,
            sortType,
            dispute,
        } = this.state;

        const {
            getPageOrders,
        } = this.props;

        const requestData = {
            pageNum: 1,
            pageSize: 25,
            clientId: dispute.clientId,
            orderField,
            sortType,
            includeRegistries: true,
            disputeFilter: true,
            nameSubstringFilter: isNullOrWhitespace(orderNameFilter) ? null : orderNameFilter.trim(),
        };

        getPageOrders(requestData);
    };

    fetchContractorsList = () => {
        const {
            getDisputesOrderListContractor,
        } = this.props;

        const {
            dispute,
        } = this.state;

        getDisputesOrderListContractor({
            pageNum: 1,
            pageSize: 100,
            disputeFilter: true,
            statusFilter: [
                STATUS.HIRED,
            ],
            orderId: dispute.orderId,
            clientId: dispute.clientId,
        });
    };

    fetchOrderManagerById = (orderManagerId) => {
        const {
            getClientMemberById,
        } = this.props;

        getClientMemberById({clientUserId: orderManagerId});
    };

    fetchClientAdminsList = () => {
        const {
            getClientMemberList,
        } = this.props;

        const {
            dispute,
            clientAdminNameFilter,
        } = this.state;

        if (!dispute.clientId) {
            return;
        }

        getClientMemberList({
            clientId: dispute.clientId,
            pageNum: 1,
            pageSize: 25,
            roleFilter: CLIENT_ADMIN,
            nameSubstringFilter: isNullOrWhitespace(clientAdminNameFilter) ? "  " : clientAdminNameFilter.trim(),
            fieldName: "adminMembers",
            archiveFilter: false,
        });
    };

    onSearchChangeClient = (clientNameFilter) => {
        this.setState({clientNameFilter}, this.fetchClientList);
    };

    onSearchChangeOrder = (orderNameFilter) => {
        this.setState({orderNameFilter}, this.fetchOrdersList);
    };

    onSearchChangeContractor = (contractorNameFilter) => {
        this.setState({contractorNameFilter}, this.fetchContractorsList);
    };

    onChangeOrder = (e, data) => {
        const {
            orderList,
        } = this.props;
        const {
            value: orderId,
        } = data;

        const orderObj = orderList.find(item => item.value === orderId);

        this.setState(prevState => ({
            ...prevState,
            dispute: {
                ...prevState.dispute,
                orderId,
            },
        }), () => {
            this.fetchOrderManagerById(orderObj.orderManagerId);
        });


        ///для реестровых заказов отключаем выбор исполнителя
        const {
            registryId,
            registryContractorId,
        } = orderObj;

        const isRegistryOrder = !isNullOrWhitespace(registryId);

        if (isRegistryOrder) {
            this.setState({
                isShowContractorField: false,
                registryContractorId,
            });
        } else {
            this.setState(prevState => ({
                ...prevState,
                dispute: {
                    ...prevState.dispute,
                    orderId,
                },
                isShowContractorField: true,
                registryContractorId: null,
            }), this.fetchContractorsList);
        }
    };

    onChangeApprDescription = (e, {value: approvDescription}) => {
        this.setState({approvDescription});
    };

    onSearchChangeClientAdmin = (clientAdminNameFilter) => {
        this.setState({clientAdminNameFilter}, this.fetchClientAdminsList);
    };

    handleOnChangeDispute = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            dispute: {
                ...prevState.dispute,
                [name]: value,
            },
        }),
        );
    };

    onChangeClient = (e, {value: clientId}) => {
        this.setState(prevState => ({
            ...prevState,
            dispute: {
                ...prevState.dispute,
                clientId,
            },
        }), () => {
            this.fetchOrdersList();
            this.fetchClientAdminsList();
        });
    };

    showApprovedBlock(isShowApprovedBlock) {
        return () => {
            this.setState({isShowApprovedBlock});
        };
    };

    updateStatusDispute = () => {
        const {
            isApproved,
            dispute,
            approvDescription,
            errorObj,
        } = this.state;

        const {
            updateStatusDispute,
        } = this.props;

        const _errorObj = {...errorObj};
        if (isNullOrWhitespace(approvDescription)) {
            _errorObj.approvDescription = requiredMessage;
            this.setState({errorObj: _errorObj});
            return;
        }
        if (approvDescription.length > 255) {
            _errorObj.approvDescription = "Максимальная длина сообщения 255 символов";
            this.setState({errorObj: _errorObj});
            return;
        }
        updateStatusDispute({
            disputeId: dispute[DISPUTE_FIELD.DISPUTE_ID],
            description: approvDescription,
            disputeStatus: CHAT_LINK_PARAMS.CLOSED.toUpperCase(),
            status: isApproved ? "APPROVED" : "REFUSED",
        });

        this.setState({errorObj: {}});
    };

    fetchDisputeFiles = () => {
        const {
            getDisputeFiles,
            emptyDisputeGuid,
        } = this.props;

        getDisputeFiles({disputeId: emptyDisputeGuid});
    };

    uploadFiles = (files) => {
        const {
            images,
            addDisputeFile,
            emptyDisputeGuid,
        } = this.props;

        if (images.length + files.length > 5) {
            toastError(DISPUTE_MESSAGES.DISPUTE_IMAGES_COUNT_ERROR);

            return;
        }

        files.forEach((file) => {
            const formData = new FormData();

            formData.append("file", file);

            addDisputeFile({
                disputeId: emptyDisputeGuid,
                file: formData,
            });
        });
    };

    deleteImage = ({fileName}) => {
        const {
            deleteDisputeFile,
            emptyDisputeGuid,
        } = this.props;

        deleteDisputeFile({disputeId: emptyDisputeGuid, name: fileName});
    };

    showChatList = (disputeId) => {
        return (e) => {
            const {
                isFromChat,
                handleClose,
                showChatList,
                updateFieldDisputeStore,
            } = this.props;

            if (!isNullOrWhitespace(e)) {
                e.stopPropagation();
            }

            if (isFromChat) {
                handleClose();
                return;
            }
            disputeId = isNullOrWhitespace(disputeId) ? "all" : disputeId;

            if (showChatList) {
                updateFieldDisputeStore({dispute: {}});
                showChatList(disputeId)();
            }
        };
    };

    handleImageFullScreen = (imageFullScreenBase64) => {
        return () => {
            this.setState({imageFullScreenBase64});
        };
    };

    handleClose = () => {
        const {
            handleClose,
            images,
            updateFieldDisputeStore,
        } = this.props;

        const {
            isShowExistDispute,
        } = this.state;

        if (!isShowExistDispute) {
            images.forEach((item) => {
                this.deleteImage({fileName: item.fileName});
            });
        }

        updateFieldDisputeStore({
            disputeImages: [],
        });

        handleClose();
    };

    renderEditPartBlock() {
        const {
            dispute,
            errorObj,
            isShowContractorField,
        } = this.state;

        const {
            clientList,
            orderList,
            contractorsList,
            clientUserList,
            treatmentChannels,
            disputeCauses,
            managerCard,
            t,
        } = this.props;

        return (
            <>
                <div className="disputes-new__form-block-header">
                    {t("disputes-new.parties-dispute")}
                </div>
                <NmDropdownV2
                    label={t("disputes-new.client")}
                    noResultsMessage={t("no-search-result.content")}
                    onSearchChange={this.onSearchChangeClient}
                    onChange={this.onChangeClient}
                    className="disputes-new__form-block-content_dropdown"
                    placeholder={t("disputes-new.enter-client-name")}
                    search
                    required
                    selection
                    value={dispute[DISPUTE_FIELD.CLIENT_ID]}
                    options={clientList}
                    error={errorObj[DISPUTE_FIELD.CLIENT_ID]}
                />
                <NmDropdownV2
                    label={t("disputes-new.order")}
                    noResultsMessage={t("no-search-result.content")}
                    onSearchChange={this.onSearchChangeOrder}
                    onChange={this.onChangeOrder}
                    className="disputes-new__form-block-content_dropdown"
                    placeholder={t("disputes-new.enter-order-name")}
                    search
                    required
                    disabled={!dispute[DISPUTE_FIELD.CLIENT_ID]}
                    value={dispute[DISPUTE_FIELD.ORDER_ID]}
                    error={errorObj[DISPUTE_FIELD.ORDER_ID]}
                    options={orderList}
                />
                {managerCard.lastName &&
                    <div className="disputes-new__form-block-content_responsible-block">
                        <div className="disputes-new__responsible-text">
                            <div>
                                {t("disputes-new.responsible")}
                            </div>
                            <div
                                className="disputes-new__responsible-value"
                            >
                                {managerCard.lastName}
                                {" "}
                                {managerCard.firstName}
                                {" "}
                                {managerCard.patronymic}
                            </div>
                        </div>
                    </div>}
                {
                    isShowContractorField &&
                    <NmDropdownV2
                        label={t("disputes-new.contractor")}
                        noResultsMessage={t("no-search-result.content")}
                        onSearchChange={this.onSearchChangeContractor}
                        name={DISPUTE_FIELD.CONTRACTOR_ID}
                        onChange={this.handleOnChangeDispute}
                        className="disputes-new__form-block-content_dropdown"
                        placeholder={t("disputes-new.enter-contractor-name")}
                        search
                        required
                        disabled={!dispute[DISPUTE_FIELD.ORDER_ID]}
                        value={dispute[DISPUTE_FIELD.CONTRACTOR_ID]}
                        error={errorObj[DISPUTE_FIELD.CONTRACTOR_ID]}
                        options={contractorsList}
                    />
                }
                <NmDropdownV2
                    label={t("disputes-new.client-administrator")}
                    noResultsMessage={t("no-search-result.content")}
                    onSearchChange={this.onSearchChangeClientAdmin}
                    name={DISPUTE_FIELD.CLIENT_ADMIN_ID}
                    onChange={this.handleOnChangeDispute}
                    className="disputes-new__form-block-content_dropdown"
                    placeholder={t("disputes-new.enter-name-administrator")}
                    search
                    disabled={!dispute.clientId}
                    error={errorObj[DISPUTE_FIELD.CLIENT_ADMIN_ID]}
                    value={dispute[DISPUTE_FIELD.CLIENT_ADMIN_ID]}
                    options={clientUserList}
                />
                <NmDropdownV2
                    label={t("disputes-new.call-channel")}
                    name={DISPUTE_FIELD.TREATMENT_CHANNEL}
                    onChange={this.handleOnChangeDispute}
                    className="disputes-new__form-block-content_dropdown"
                    placeholder={t("disputes-new.select-call-channel")}
                    required
                    value={dispute[DISPUTE_FIELD.TREATMENT_CHANNEL]}
                    options={treatmentChannels}
                    error={errorObj[DISPUTE_FIELD.TREATMENT_CHANNEL]}
                />
                <Divider className="divider-fluid" />
                <div className="disputes-new__form-block-header">
                    {t("disputes-new.reasons-dispute")}
                </div>
                <div className="disputes-new__form-block-content">
                    <NmDropdownV2
                        label="Причина спора"
                        name={DISPUTE_FIELD.CAUSE_DISPUTE}
                        onChange={this.handleOnChangeDispute}
                        className="mb-4"
                        placeholder={t("disputes-new.select-reasons-dispute")}
                        required
                        value={dispute[DISPUTE_FIELD.CAUSE_DISPUTE]}
                        options={disputeCauses}
                        error={errorObj[DISPUTE_FIELD.CAUSE_DISPUTE]}
                    />
                    <NmTextareaV2
                        label="Описание проблемы"
                        value={dispute[DISPUTE_FIELD.DESCRIPTION]}
                        name={DISPUTE_FIELD.DESCRIPTION}
                        required
                        maxLength="500"
                        onChange={this.handleOnChangeDispute}
                        placeholder={t("disputes-new.describe-problem")}
                        error={errorObj[DISPUTE_FIELD.DESCRIPTION]}
                    />
                </div>
            </>
        );
    };

    renderActionsBlock(isDisputeOpen) {
        const {
            dispute,
        } = this.state;

        const {t} = this.props;

        const isAccessCloseDispute = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientDispute,
            CLIENT_USER_RESTRICTIONS_VARIABLE.closeDispute,
        ]);

        return (
            <div className="d-flex flex-column flex-md-row justify-content-between mt-4 mt-md-5 mt-xxl-7">
                {
                    isDisputeOpen &&
                    [ADMIN, NM_MANAGER].includes(this.role) &&
                    isAccessCloseDispute &&
                    <NmButton
                        size="xl"
                        color="light-green"
                        className="mb-2 mb-md-0"
                        onClick={this.showApprovedBlock(true)}
                    >
                        {t("disputes-list.close-dispute")}
                    </NmButton>
                }
                <NmButton
                    size="xl"
                    onClick={this.showChatList(dispute[DISPUTE_FIELD.DISPUTE_ID])}
                    count={dispute[DISPUTE_FIELD.DISPUTE_UNREAD_MESSAGES_COUNT]}
                >
                    {t("disputes-new.open-chat")}
                </NmButton>
            </div>
        );
    };

    renderReadOnlyPartBlock() {
        const {
            dispute,
        } = this.state;

        const {
            treatmentChannelsWithMobile,
            disputeCauses,
            t,
        } = this.props;

        const isForeman = this.role === FOREMAN;
        const isClientAdmin = this.role === CLIENT_ADMIN;
        const isDisputeOpen = isNullOrWhitespace(dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]);

        const treatmentObj = treatmentChannelsWithMobile.find(item => item.value === dispute[DISPUTE_FIELD.TREATMENT_CHANNEL]);
        const subjectObj = disputeCauses.find(item => item.value === dispute[DISPUTE_FIELD.CAUSE_DISPUTE]);
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", dispute[DISPUTE_FIELD.CLIENT_ID]);
        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", dispute[DISPUTE_FIELD.CLIENT_ID])
            .replace(":orderId", dispute[DISPUTE_FIELD.ORDER_ID])
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", dispute[DISPUTE_FIELD.CONTRACTOR_ID]);
        const orderManagerLink = LINK_CLIENT_MEMBERS_CARD.replace(":clientId", dispute[DISPUTE_FIELD.CLIENT_ID]).replace(":clientContractorId", dispute[DISPUTE_FIELD.ORDER_MANAGER_ID]);

        return (
            <>
                <div className="disputes-new__header-row">
                    <div className="d-flex">
                        <NmBadge
                            mod={isDisputeOpen ? "red" : "green"}
                            icon={
                                <HammerIcon
                                    width={16}
                                    height={16}
                                />
                            }
                            text={isDisputeOpen ? ` ${t("disputes-new.open-dispute")}` : ` ${t("disputes-new.close-dispute")}`}
                        />
                    </div>
                    <Text
                        className="mt-2 mt-md-0 ms-md-2"
                        level="3"
                    >
                        {`${t("disputes-new.call-channel")} - ${getTextByProperty(treatmentObj, "text")}`}
                    </Text>
                </div>
                <div className="disputes-new__form-block-content">
                    {
                        !isDisputeOpen &&
                        <>

                            <NmLabelText
                                type="page"
                                label={t("disputes-new.dispute-result")}
                                className="flex-column"
                                text={dispute[DISPUTE_FIELD.DISPUTE_RESULT] || DISPUTE_STATUS_RESULT_MSG[dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]]}
                            />
                            <NmDivider className="my-3 my-md-4" />
                        </>
                    }
                    <NmLabelText
                        type="page"
                        label={t("disputes-new.contractor")}
                        className="flex-column"
                        text={
                            <ExtLink
                                to={contractorLink}
                                enabled={!isForeman && !isClientAdmin}
                                historyEnabled
                            >
                                {`${dispute[DISPUTE_FIELD.CONTRACTOR_LAST_NAME]} ${dispute[DISPUTE_FIELD.CONTRACTOR_FIRST_NAME]} ${dispute[DISPUTE_FIELD.CONTRACTOR_PATRONYMIC] || ""}`}
                            </ExtLink>
                        }
                    />
                    <NmDivider className="my-3 my-md-4" />
                    <NmLabelText
                        type="page"
                        label={t("disputes-new.client")}
                        className="flex-column mb-3 mb-md-4"
                        text={
                            <ExtLink
                                to={clientLink}
                                enabled={!isForeman}
                                historyEnabled
                            >
                                {dispute[DISPUTE_FIELD.CLIENT_NAME]}
                                {dispute[DISPUTE_FIELD.BRAND] && `(${dispute[DISPUTE_FIELD.BRAND]})`}
                            </ExtLink>
                        }
                    />
                    <NmLabelText
                        type="page"
                        label={t("disputes-new.order")}
                        className="flex-column mb-3 mb-md-4"
                        text={
                            <ExtLink
                                to={orderLink}
                                historyEnabled
                            >
                                {dispute[DISPUTE_FIELD.ORDER_NAME]}
                            </ExtLink>
                        }
                    />
                    <NmLabelText
                        type="page"
                        label={t("disputes-new.responsible")}
                        className="flex-column"
                        text={
                            <ExtLink
                                to={orderManagerLink}
                                enabled={!isForeman}
                                historyEnabled
                            >
                                {`${getText(dispute[DISPUTE_FIELD.MANAGER_LAST_NAME])} ${getText(dispute[DISPUTE_FIELD.MANAGER_FIRST_NAME])} ${getText(dispute[DISPUTE_FIELD.MANAGER_PATRONYMIC])}`}
                            </ExtLink>
                        }
                    />
                    <NmDivider className="my-3 my-md-4" />
                    <NmLabelText
                        type="page"
                        label={t("disputes-new.reasons-dispute")}
                        className="flex-column"
                        text={subjectObj ? subjectObj.text : ""}
                    />
                    <NmDivider className="my-3 my-md-4" />
                    <NmLabel
                        label={t("disputes-new.problem")}
                    />
                    <NmShowMoreText
                        anchor="blue"
                        className="flex-column mb-3 mb-md-4"
                        title={t("disputes-new.problem")}
                        children={dispute[DISPUTE_FIELD.DESCRIPTION]}
                        lines={2}
                        more="Подробнее"
                    />
                </div>
            </>
        );
    };

    renderModalApproveBlock() {
        const {errorObj, approvDescription} = this.state;
        const {t} = this.props;

        return (
            <NmModal
                size="sm"
                onClose={this.showApprovedBlock(false)}
                header={
                    <NmTitle size="lg">
                        Закрытие спора
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        submitBtnContent={t("button.confirm")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={this.showApprovedBlock(false)}
                        submit={this.updateStatusDispute}
                    />
                }
            >
                <NmForm>
                    <NmTextareaV2
                        minRows={2}
                        placeholder={t("disputes-new.indicate-result-dispute")}
                        onChange={this.onChangeApprDescription}
                        value={approvDescription}
                        error={errorObj.approvDescription}
                    />
                </NmForm>
            </NmModal>
        );
    };

    renderFiles() {
        const {
            isShowExistDispute,
        } = this.state;
        const {
            images,
            t,
        } = this.props;

        return (
            <NmDropzoneApiImages
                label={t("disputes-new.evidence")}
                maxImgCount={5}
                fileNameList={images}
                isVisibleLabel={images.length !== 0}
                isAddFilesByOnDrop
                isShowDropzone={!isShowExistDispute}
                isShowDeleteButton={!isShowExistDispute}
                isFileNameListIncludesBase64str
                isDownload={false}
                isFetchFullImage={false}
                onUploadFile={this.uploadFiles}
                onDeleteFile={this.deleteImage}
            />
        );
    };

    render() {
        const {
            t,
            disputeLoading,
            updateProgress,
        } = this.props;
        const {
            isShowExistDispute,
            isShowApprovedBlock,
            dispute,
        } = this.state;

        const isDisputeOpen = isNullOrWhitespace(dispute[DISPUTE_FIELD.DISPUTE_STATUS_RESULT]);
        const header = isShowExistDispute ?
            `${t("disputes-new.dispute-parameters-from")} ${dateFormat(convertUtcToLocal(dispute[DISPUTE_FIELD.CREATION_DATE]))} ${isDisputeOpen ? "" : ` ${t("disputes-new.to")} ` + dateFormat(convertUtcToLocal(dispute[DISPUTE_FIELD.CHANGE_DATE]))}` :
            t("disputes-new.disputes-new-title");

        return (
            <>
                <NmModal
                    size="md"
                    className="disputes-new"
                    onClose={this.handleClose}
                    header={
                        <NmTitle size="lg">
                            {header}
                        </NmTitle>
                    }
                    footer={
                        isShowExistDispute ?
                            this.renderActionsBlock(isDisputeOpen) :
                            <ApplyButtons
                                onClose={this.handleClose}
                                submit={this.updateDispute}
                                submitBtnContent={t("button.create")}
                                cancelBtnContent={t("button.cancel")}
                            />
                    }
                    loading={disputeLoading || updateProgress}
                >
                    <NmForm
                        addMargin
                    >
                        {isShowExistDispute ? this.renderReadOnlyPartBlock() : this.renderEditPartBlock()}
                        {this.renderFiles()}
                    </NmForm>
                </NmModal>
                {isShowApprovedBlock && this.renderModalApproveBlock()}
            </>
        );
    }
}

export default connect(
    state => ({
        clientList: clientListOptimizedOptionsSelector(state),
        orderList: ordersOptionsForCreatingDisputeSelector(state),
        contractorsList: disputeOrderContractorOptionsListSelector(state),
        clientUserList: clientMemberOptionsSelector(state),
        emptyDisputeGuid: emptyDisputeGuidSelector(state),
        isDisputeSavedSuccess: disputeSaveSuccessSelector(state),
        dispute: disputeSelector(state),
        changeStatusSuccess: disputeChangeStatusSuccessSelector(state),
        isImageLoaded: disputeFileIsSuccessAddSelector(state),
        images: disputeImageListSelector(state),
        isImageDeleted: disputeFileIsSuccessDeleteSelector(state),
        treatmentChannels: treatmentChannelsDictsOptionsSelector(state),
        treatmentChannelsWithMobile: treatmentChannelsDictsOptionsWithMobileSelector(state),
        disputeCauses: causesDisputeDictsOptionsSelector(state),
        disputeStatusList: disputeStatusSelector(state),
        managerCard: clientMemberCardSelector(state),
        disputeLoading: disputeLoadingSelector(state),
        updateProgress: disputesActionProgressSelector(state),
    }), {
        getOptimizedClientList,
        getPageOrders,
        getDisputesOrderListContractor,
        getClientMemberList,
        createEmptyDispute,
        updateDispute,
        updateStatusDispute,
        updateFieldDisputeStore,
        addDisputeFile,
        getDisputeFiles,
        deleteDisputeFile,
        getTreatmentChannelDict,
        getDisputeCausesDict,
        getDisputeStatusDict,
        getDisputeById,
        getClientMemberById,
        updateFieldClientMemberStore,
    },
)(withTranslation()(DisputesNew));