import React, {useMemo} from "react";
import Highlighter from "react-highlight-words";
import {useDispatch, useSelector} from "react-redux";

import HelpTooltip from "../HelpTooltip";
import NmDropdownV2 from "../NmDropdownV2";
import NmLabelText from "../NmLabelText";

import bem from "../../../utils/bem";
import {getFullName} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";

import {REG_EXP} from "../../../constants/regExp";

import {getContractorShortList} from "../../../ducks/bff/contractors/registry/actionCreators";
import {
    bffContractorsShortModelListPageDataSelector,
    bffContractorsShortModelListProgressSelector,
    bffContractorsShortModelListSelector,
    bffContractorsShortModelListTotalCountSelector,
} from "../../../ducks/bff/contractors/registry/selectors";

import "./style.sass";

interface IFilterContractor {
    value: [],
    name?: string,
    onChange: (event: any, params: any) => void,
    className?: string,
    isMountLoad?: boolean,
    onSubmit?: () => void,
    isBff?: boolean,
    label?: string | object,
    relatedClientId?: string,
    ignoreClientGroupRelations?: boolean,
}

type ContractorListItem = {
    lastName?: string,
    firstName?: string,
    patronymic?: string,
    fullName?: string,
    phone?: string,
    inn?: string,
    contractorId?: string,
}

const FilterContractor = (props: IFilterContractor) => {
    const {
        label = "Исполнитель",
        className,
        relatedClientId,
        ignoreClientGroupRelations,
    } = props;

    const [block, element] = bem("filter-contractor", className);

    const dispatch = useDispatch();

    const list = useSelector(bffContractorsShortModelListSelector);
    const totalCount = useSelector(bffContractorsShortModelListTotalCountSelector);
    const progress = useSelector(bffContractorsShortModelListProgressSelector);
    const {fullTextFilter: nameSubstringFilter} = useSelector(bffContractorsShortModelListPageDataSelector);

    const renderHighlighter = (text?: string) => {
        return (
            <Highlighter
                highlightClassName={element("highlight")}
                searchWords={nameSubstringFilter?.replace(REG_EXP.NUMBERS_LETTERS_SPACE, "").split(" ") || []}
                textToHighlight={text || "-"}
            />
        );
    };

    const options = useMemo(() => {
        return list.map((item: ContractorListItem) => {
            const {
                contractorId,
                lastName,
                firstName,
                patronymic,
                phone,
                inn,
            } = item;

            const fullName = lastName ? getFullName(lastName, firstName, patronymic) : item.fullName;

            return {
                key: contractorId,
                value: contractorId,
                text: fullName,
                customContent: <div className="col-16">
                    <NmLabelText
                        type="page"
                        classNameLabel={element("info-label")}
                        label="ФИО"
                        text={renderHighlighter(fullName)}
                        noWrap={false}
                        fluidText={true}
                    />
                    <div className="row gx-4">
                        <div className="col-8">
                            <NmLabelText
                                type="page"
                                classNameLabel={element("info-label")}
                                label="Телефон"
                                text={renderHighlighter(phone)}
                            />
                        </div>
                        <div className="col-8">
                            <NmLabelText
                                type="page"
                                classNameLabel={element("info-label")}
                                label="ИНН"
                                text={renderHighlighter(inn)}
                            />
                        </div>
                    </div>
                </div>,
                className: "col-16",
            };
        });
    }, [list]);

    const fetchList = (valueFilter: string) => {
        dispatch(getContractorShortList({
            relatedClientId,
            pageNum: 1,
            pageSize: 10,
            fullTextFilter: handleFormString(valueFilter),
            ignoreClientGroupRelations,
        }));
    };

    const onSearchChange = (valueFilter: string) => {
        fetchList(valueFilter);
    };

    return (
        <NmDropdownV2
            {...props}
            className={block()}
            size="lg"
            label={label}
            isMountLoad={!nameSubstringFilter && !props.value}
            onSearchChange={onSearchChange}
            search={true}
            options={options}
            optionsTotalCount={totalCount}
            searchCount={10}
            searchPromptSubjectText="10 исполнителей"
            isVisibleSearchPrompt={true}
            isSetTextCustomContent={false}
            isLocalSearchFilter={false}
            isLoading={progress}
            noResultMessage="Исполнитель не найден"
            isVisibleTooltip={true}
            isClearable={true}
            isClearSearchDependingValue={true}
            placeholder="Введите ФИО, телефон или ИНН"
            tooltip={
                <HelpTooltip
                    info={true}
                    isFilterTooltip={true}
                    children="Вводите данные через пробел. Например: Иванов 5484"
                />
            }
        />
    );
};

export default FilterContractor;