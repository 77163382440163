import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {getRegistryPaymentStatusesForFilter} from "../../../../ducks/bff/common/dicts/actionCreators";
import {
    clearRegistryPaymentsStore,
    getRegistryPayments,
    getRegistryPaymentsThumbnailList,
} from "../../../../ducks/bff/finances/registry-payments/actionCreators";
import {getUserAvatarListThumbnails} from "../../../../ducks/fileStore";
import {
    getRegistryPaymentStatuses,
    getRegistryPaymentStatusesAllForFilters,
    updateRegistryPaymentStatusesStore,
} from "../../../../ducks/registryPaymentStatuses";

export const useRegistryPaymentsFetch = (params) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
        contractorIds,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegistryPaymentStatusesForFilter());
        dispatch(getRegistryPaymentStatusesAllForFilters({}));
        dispatch(getRegistryPaymentStatuses({}));

        return () => {
            dispatch(clearRegistryPaymentsStore());
            dispatch(updateRegistryPaymentStatusesStore({
                statusesAllForFilter: {},
                paymentStatuses: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(contractorIds)) {
            return;
        }

        dispatch(getUserAvatarListThumbnails({
            contractorIds,
        }));
    }, [
        contractorIds,
    ]);

    useEffect(() => {
        dispatch(getRegistryPayments({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
        }));
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    return {
        contractorIds,
    };
};