import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {
    archiveRecruitmentVacancy,
    deleteRecruitmentVacancy,
    exportRecruitmentVacancy,
    unarchiveRecruitmentVacancy,
} from "../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {ReactComponent as IconCheck} from "../../../../../images/check_box_24.svg";
import {useCloseVacancy} from "./useCloseVacancy";

import {useModal} from "../../../../../hooks/useModal";

import {isVisibleRecruitmentActions} from "../../../../../utils/access";
import {
    ls,
    USER_ROLE,
} from "../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";
import {
    toastError,
    toastSuccess,
} from "../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../../constants/roles";
import {RECRUITMENT_VACANCY_STATUS} from "../../constants";

const useRecruitmentVacancyCardAction = (props) => {
    const {
        clientId,
        fetchCallback,
        isVisibleArchiveButton = false,
        isVisibleAddCandidateButton = false,
        renderCount = {
            mobile: 0,
            tablet: 1,
            desktop: 2,
        },
    } = props;

    const isAccessManageVacanciesClient = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessManageVacanciesClient,
    ]);

    const [confirmData, setConfirmData] = useState({});

    const vacancyCloseActions = useCloseVacancy({
        clientId,
        fetchCallback,
        setConfirmData,
    });

    const {
        isOpen: isOpenLog,
        modalData: logData,
        onOpenModal: onOpenLog,
        onCloseModal: onCloseLog,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        modalData: editData,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const {
        isOpen: isOpenAddCandidate,
        onOpenModal: onOpenAddCandidate,
        onCloseModal: onCloseAddCandidate,
    } = useModal();

    const {
        isOpen: isOpenColdSearchModal,
        onOpenModal: onOpenColdSearchModal,
        onCloseModal: onCloseColdSearchModal,
    } = useModal();

    const modals = useModal();

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    const isAccessAction = isVisibleRecruitmentActions();

    const onConfirmDelete = (vacancy) => {
        const {
            vacancyId,
        } = vacancy;

        dispatch(deleteRecruitmentVacancy({
            vacancyId,
            onSuccess: () => {
                toastSuccess("Вакансия успешно удалена");
                fetchCallback();
            },
        }));
    };

    const onConfirmArchive = (vacancy) => {
        const {
            archived,
            vacancyId,
        } = vacancy;

        const reqData = {
            vacancyId,
            onSuccess: () => {
                toastSuccess(
                    archived ?
                        "Вакансия успешно восстановлена из архива" :
                        "Вакансия успешно добавлена в архив",
                );
                fetchCallback();
            },
        };

        if (archived) {
            dispatch(unarchiveRecruitmentVacancy(reqData));

            return;
        }

        dispatch(archiveRecruitmentVacancy(reqData));
    };

    const exportVacancy = (vacancy) => {
        const {
            totalCandidates,
            totalResponses,
            vacancyId,
        } = vacancy;

        if (!totalCandidates && !totalResponses) {
            toastError("По вакансии отсутствуют отклики/кандидаты для выгрузки");

            return;
        }

        dispatch(exportRecruitmentVacancy({
            vacancyId,
            onSuccess: () => {
                toastSuccess("Выгрузка успешно запущена. Скачать файл по готовности можно в разделе \"Экспорт отчетов\"");
            },
        }));
    };

    const getMediaControls = (vacancy) => {
        if (
            !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
            ])
        ) {
            return null;
        }

        return {
            renderCount,
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "light-green",
                        children: "Холодный поиск",
                        onClick: () => {
                            onOpenColdSearchModal({});
                        },
                    },
                    visible: isVisibleAddCandidateButton
                        && vacancy.status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE
                        && isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Редактировать",
                        onClick: () => {
                            if (vacancy.status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE && vacancy.totalResponses > 0) {
                                setConfirmData({
                                    onConfirm: () => {
                                        onOpenEdit(vacancy);
                                    },
                                    content: "На вакансии уже есть отклики. Вы точно хотите ее отредактировать?",
                                });

                                return;
                            }

                            onOpenEdit(vacancy);
                        },
                    },
                    visible: vacancy.status !== RECRUITMENT_VACANCY_STATUS.CLOSED.VALUE
                        && isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Закрыть вакансию",
                        icon: <IconCheck />,
                        onClick: () => {
                            vacancyCloseActions.fetchJobBoards(vacancy);
                        },
                    },
                    visible: vacancy.status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE
                        && isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Лог операций",
                        onClick: () => {
                            onOpenLog(vacancy);
                        },
                    },
                    visible: (
                        [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)
                            || isAccessAction
                    )
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessLogsClient,
                        ]),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Выгрузить в файл",
                        onClick: () => {
                            exportVacancy(vacancy);
                        },
                    },
                    visible: (
                        [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)
                            || isAccessAction
                    )
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Создать дубликат",
                        onClick: () => onOpenEdit({
                            isDuplicate: true,
                            ...vacancy,
                        }),
                    },
                    visible: isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Удалить",
                        onClick: () => {
                            setConfirmData({
                                onConfirm: () => {
                                    onConfirmDelete(vacancy);
                                },
                                content: "Вы действительно хотите удалить вакансию?",
                            });
                        },
                    },
                    visible: vacancy.status === RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Добавить кандидата",
                        onClick: () => {
                            onOpenAddCandidate({});
                        },
                    },
                    visible: isVisibleAddCandidateButton
                        && vacancy.status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE
                        && isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: vacancy.archived ? "Из архива" : "В архив",
                        onClick: () => {
                            setConfirmData({
                                onConfirm: () => {
                                    onConfirmArchive(vacancy);
                                },
                                content: vacancy.archived ?
                                    "Вы действительно хотите восстановить вакансию из архива?" :
                                    "Вы действительно хотите перенести вакансию в архив?",
                            });
                        },
                    },
                    visible: isVisibleArchiveButton
                        && [
                            RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE,
                            RECRUITMENT_VACANCY_STATUS.CLOSED.VALUE,
                        ].includes(vacancy.status)
                        && isAccessAction
                        && isAccessManageVacanciesClient,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Предоставить доступ",
                        onClick: () => {
                            modals.onOpenModal({
                                isOpenAccessProvide: true,
                                vacancyId: vacancy.vacancyId,
                            });
                        },
                    },
                    visible: vacancy.status === RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE
                        && !vacancy.archived
                        && isAccessManageVacanciesClient,
                },
            ],
        };
    };

    const onCloseConfirm = () => {
        setConfirmData({});
    };

    return {
        confirmData,
        onCloseLog,
        getMediaControls,
        onCloseConfirm,
        isOpenLog,
        isOpenEdit,
        vacancyCloseActions,
        editData,
        onCloseEdit,
        onOpenEdit,
        logData,
        isOpenAddCandidate,
        onCloseAddCandidate,
        isOpenColdSearchModal,
        onCloseColdSearchModal,
        modals,
    };
};

export default useRecruitmentVacancyCardAction;