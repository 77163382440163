import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";
import LabelTextApp from "../../../../components/LabelTextApp";

import validate from "../../../../utils/validate";

import {CONTRACTOR_FIELD} from "../../../../constants/contractor";
import {CONTRACTOR_CARD_FIELDS} from "../../../../constants/contractorInfo";
import {employmentHistoryDataRule} from "../../../../constants/validationRules";

import {updateContractor} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {pendingPersonalDataSelector} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {
    contractorCardInfoSelector,
    contractorEmploymentHistoryData,
} from "../../../../ducks/bff/contractor-сard/selectors";

import "./style.sass";

const EmploymentHistory = (props) => {
    const {
        isEditable,
        className,
    } = props;

    const [form, setForm] = useState({
        [CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT]: false,
        [CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM]: "",
    });

    const [isEdit, setIsEdit] = useState(false);
    const [errorForm, setErrorForm] = useState({});

    const dispatch = useDispatch();

    const card = useSelector(contractorCardInfoSelector);
    const employmentHistory = useSelector(contractorEmploymentHistoryData);
    const {data} = useSelector(pendingPersonalDataSelector);
    const {pendingPersonalDataFields = []} = data || {};

    useEffect(() => {
        if (!isEmpty(employmentHistory)) {
            setForm({...employmentHistory});
        }
    }, [employmentHistory]);

    const isValidForm = () => {
        const rule = form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] ? employmentHistoryDataRule : {};
        const error = validate(form, rule, "");

        setErrorForm({...error});

        return Object.values(error).length === 0;
    };

    const save = () => {
        dispatch(updateContractor({
            ...card,
            ...form,
        }));
    };

    const cancelEdit = () => {
        setIsEdit(false);
        setErrorForm({});
        setForm({...employmentHistory});
    };

    const toggleCard = () => {
        if (isEdit) {
            const isValid = isValidForm();

            if (!isValid) {
                return;
            }
        }

        setIsEdit(!isEdit);

        if (isEdit) {
            save();
        }
    };

    const handleChange = (e, {value, name, checked}) => {
        setForm({
            ...form,
            [name]: checked === undefined ? value : checked,
            employmentHistoryNum: (name === "employmentHistoryPresent" && checked === false) ? "" : value,
        });
    };

    const hasBorder = () => CONTRACTOR_CARD_FIELDS.EMPLOYMENT_HISTORY.some(field => pendingPersonalDataFields.includes(field));

    const renderText = (value) => {
        return value || "Не указан";
    };

    const renderCardReadOnly = () => {
        return (
            <>
                <NmCheckboxV2
                    className="employment-history__item"
                    label="Трудовая книжка присутствует"
                    disabled={true}
                    checked={form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT]}
                    onChange={() => {}}
                />
                {
                    form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] &&
                    <LabelTextApp
                        label="Номер"
                        text={renderText(form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM])}
                    />
                }
            </>
        );
    };

    const renderCardEdit = () => {
        return (
            <NmForm
                addMargin
                className="fluid"
            >
                <NmCheckboxV2
                    label="Трудовая книжка присутствует"
                    onChange={handleChange}
                    checked={form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT]}
                    name={CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT}
                />
                {
                    form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_PRESENT] &&
                    <NmInputV2
                        size="lg"
                        className="employment-history__input"
                        error={errorForm[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM]}
                        label="Номер"
                        onChange={handleChange}
                        value={form[CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM]}
                        name={CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM}
                        required
                    />
                }
            </NmForm>
        );
    };

    return (
        <CardApp
            border={hasBorder()}
            className={className}
            title="Трудовая книжка"
            isEditable={isEditable}
            onClickIcon={toggleCard}
            onClickCancelIcon={cancelEdit}
            isEdit={isEdit}
        >
            {isEdit ? renderCardEdit() : renderCardReadOnly()}
        </CardApp>
    );
};

export default EmploymentHistory;