import React from "react";
import {isEmpty} from "lodash";

import {styleLargeLink} from "../../styles/inline";
import ExtLink from "../ExtLink";

import {
    getUserRole,
} from "../../utils/access";
import {isAccessByRestrictions} from "../../utils/restrictions";
import {getFullName} from "../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {CONTRACTOR_STATUS} from "../../constants/contractor";
import {
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
} from "../../constants/links";
import {
    HR_MANAGER,
    isUserFromNm,
    RNKO,
} from "../../constants/roles";

const ContractorLink = (props) => {
    const {
        contractor,
        pageData,
        isShowTitle,
        extData,
        filterData,
    } = props;

    const {
        contractorId,
        registrationStatus,
        name,
        fullName,
        firstName,
        lastName,
        patronymic,
    } = contractor;

    const role = getUserRole();
    const isNmUser = isUserFromNm(role);

    const isContractorOnAdminCheck = [
        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK,
        CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES,
    ].includes(registrationStatus);

    const getName = () => {
        if (name) {
            return name;
        }

        if (
            !isEmpty(firstName)
            && (isNmUser || !isContractorOnAdminCheck)
        ) {
            return getFullName(lastName, firstName, patronymic);
        }

        return fullName;
    };

    const getLink = () => {
        let link = LINK_CONTRACTOR_PROFILE;

        if (role === HR_MANAGER
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorBlockProfile,
            ])
        ) {
            link = LINK_CONTRACTOR_REVIEWS_LIST;
        }

        if (RNKO === role || (isContractorOnAdminCheck && isNmUser)) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        return link.replace(":contractorId", contractorId);
    };

    return (
        <ExtLink
            title={isShowTitle && getName()}
            pageData={pageData}
            historyEnabled
            style={styleLargeLink}
            to={getLink()}
            extData={extData}
            filterData={filterData}
        >
            {getName()}
        </ExtLink>
    );
};

export default ContractorLink;
