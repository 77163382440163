import {
    CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_ADD_REQUEST,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_PROJECTS_CHANGE_STATUS_REQUEST,
    CLIENT_PROJECTS_CLEAR_STORE,
    CLIENT_PROJECTS_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_IMPORT_REQUEST,
    CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_UPDATE_REQUEST,
    CLIENT_PROJECTS_UPDATE_STORE,
} from "./actions";

export const getPageClientProjects = (payload) => {
    return {
        type: CLIENT_PROJECTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addClientProject = (payload) => {
    return {
        type: CLIENT_PROJECTS_ADD_REQUEST,
        payload,
    };
};

export const updateClientProject = (payload) => {
    return {
        type: CLIENT_PROJECTS_UPDATE_REQUEST,
        payload,
    };
};

export const changeClientProjectStatus = (payload) => {
    return {
        type: CLIENT_PROJECTS_CHANGE_STATUS_REQUEST,
        payload,
    };
};

export const changeClientProjectArchived = (payload) => {
    return {
        type: CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST,
        payload,
    };
};

export const importClientProjects = (payload) => {
    return {
        type: CLIENT_PROJECTS_IMPORT_REQUEST,
        payload,
    };
};

export const returnClientDeposit = (payload) => {
    return {
        type: CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST,
        payload,
    };
};

export const addClientDeposit = (payload) => {
    return {
        type: CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST,
        payload,
    };
};

export function getProjectsDepositHistoryPage(payload){
    return {
        type: CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
}

export function changeProjectOrdersLimit(payload){
    return {
        type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
        payload,
    };
}

export function changeProjectsObjectsOrdersLimit(payload){
    return {
        type: CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST,
        payload,
    };
}

export const updateStoreClientProject = (payload) => {
    return {
        type: CLIENT_PROJECTS_UPDATE_STORE,
        payload,
    };
};

export const clearStoreClientProject = (payload) => {
    return {
        type: CLIENT_PROJECTS_CLEAR_STORE,
        payload,
    };
};