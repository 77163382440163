import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {clientCurrentMemberSelector} from "../../clientMember";
import {getUserAvatarListThumbnails} from "../../fileStore";
import {getAllTasks} from "../../job";
import {
    BFF_ADD_ADVERTISEMENT_DRAFT_ERROR,
    BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST,
    BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS,
    BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR,
    BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST,
    BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS,
    BFF_ADVERTISEMENT_CLOSE_ERROR,
    BFF_ADVERTISEMENT_CLOSE_REQUEST,
    BFF_ADVERTISEMENT_CLOSE_SUCCESS,
    BFF_ADVERTISEMENT_CONTRACTORS_COUNT_ERROR,
    BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST,
    BFF_ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS,
    BFF_ADVERTISEMENT_IMPORT_LIST_ERROR,
    BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST,
    BFF_ADVERTISEMENT_IMPORT_LIST_SUCCESS,
    BFF_ADVERTISEMENT_ORDER_INVITE_ERROR,
    BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST,
    BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS,
    BFF_GET_ADVERTISEMENT_ERROR,
    BFF_GET_ADVERTISEMENT_PAGE_ERROR,
    BFF_GET_ADVERTISEMENT_PAGE_REQUEST,
    BFF_GET_ADVERTISEMENT_PAGE_SUCCESS,
    BFF_GET_ADVERTISEMENT_REQUEST,
    BFF_GET_ADVERTISEMENT_SUCCESS,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS,
    BFF_UPDATE_ADVERTISEMENT_ERROR,
    BFF_UPDATE_ADVERTISEMENT_REQUEST,
    BFF_UPDATE_ADVERTISEMENT_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../utils/url";

import {ADVERTISEMENT_TOAST_MESSAGE} from "../../../containers/advertisement/list/constants";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/advertisements",
        client: "/client-adm/advertisements",
    });
};

// POST bff/adm/clients/client-card/advertisements/add/publish
// POST bff/client-adm/advertisements/add/publish
// Опубликовать объявление
const addAdvertisementPublishSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add/publish`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.PUBLISH.SUCCESS);
        onSuccess();

        yield put({
            type: BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/add/draft
// POST bff/client-adm/advertisements/add/draft
// Сохранить черновик объявления
const addAdvertisementDraftSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add/draft`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_ADVERTISEMENT_DRAFT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.ADD_DRAFT.SUCCESS);
        onSuccess();

        yield put({
            type: BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_ADD_ADVERTISEMENT_DRAFT_ERROR,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/page/rich
// POST bff/client-adm/advertisements/page/rich
// Возвращает обогащенную страницу по заданным фильтрам
const getAdvertisementPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page/rich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_ADVERTISEMENT_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_ADVERTISEMENT_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_ADVERTISEMENT_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/close
// POST bff/client-adm/advertisements/close
// Закрытие объявлений
const advertisementCloseSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            requestData,
            toastSuccess: toastSuccessMsg,
        } = payload;

        const result = yield request.bff.post(`${getController()}/close`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_CLOSE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        if (onSuccess) {
            onSuccess();
        }

        if (toastSuccessMsg) {
            toastSuccess(toastSuccessMsg);
        }

        yield put({
            type: BFF_ADVERTISEMENT_CLOSE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_CLOSE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/import/{clientId}
// POST bff/client-adm/advertisements/import/{clientId}
// Импорт файла xlsx
const importAdvertisementListSaga = function* ({payload}) {
    try {
        const {
            clientId,
            getResult,
            formData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/import/${clientId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_IMPORT_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ADVERTISEMENT_IMPORT_LIST_SUCCESS,
            payload: result,
        });

    } catch (error) {

        yield put({
            type: BFF_ADVERTISEMENT_IMPORT_LIST_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/update
// POST bff/client-adm/advertisements/update
// Редактирование объявления
const updateAdvertisementSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            formData,
        } = payload;
        const result = yield request.bff.post(`${getController()}/update`, formData, {...getMultipartHeaders()});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_UPDATE_ADVERTISEMENT_ERROR,
            });

            return {
                done: true,
            };
        }

        toastSuccess(ADVERTISEMENT_TOAST_MESSAGE.UPDATE.SUCCESS);
        onSuccess();

        yield put({
            type: BFF_UPDATE_ADVERTISEMENT_SUCCESS,
        });

    } catch (error) {
        yield put({
            type: BFF_UPDATE_ADVERTISEMENT_ERROR,
        });
    }
};

// GET bff/adm/clients/client-card/advertisements/get/rich
// GET bff/client-adm/advertisements/get/rich
// Возвращает обогащенное объявление по идентификатору
const getAdvertisementSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/get/rich`, {params});

        if (getResult) {
            getResult(result);
        }

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_GET_ADVERTISEMENT_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_ADVERTISEMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_ADVERTISEMENT_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/before/publish/multiple
// POST bff/client-adm/advertisements/before/publish/multiple
// Метод перед публикацией объявления, возвращающий результат проверки
const advertisementPublishMultipleCheckSaga = function* ({payload}) {
    try {
        const {
            getResult,
            requestData,
        } = payload;

        const result = yield request.bff.post(`${getController()}/before/publish/multiple`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/publish/multiple
// POST bff/client-adm/advertisements/publish/multiple
// Публикация объявлений
const advertisementPublishMultipleSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            requestData,
            isShowToastSuccess = false,
        } = payload;

        const result = yield request.bff.post(`${getController()}/publish/multiple`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        if (onSuccess) {
            onSuccess();
        }

        if (isShowToastSuccess) {
            toastSuccess("Задача на публикацию объявлений передана в обработку");
        }

        yield put({
            type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/page/view/rich
// POST bff/client-adm/advertisements/page/view/rich
// Возвращает страницу по заданным фильтрам
const getAdvertisementViewPageSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/page/view/rich`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult();
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({baseModel: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/count/contractors
// POST bff/client-adm/advertisements/count/contractors
// Возвращает количество исполнителей подходящих под условия сегментации объявления
const getAdvertisementContractorsCountSaga = function* ({payload}) {
    try {
        const {
            getResult,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/count/contractors`, reqData);
        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_CONTRACTORS_COUNT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_CONTRACTORS_COUNT_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/funnel
// POST bff/client-adm/advertisements/funnel
// Воронки
const getAdvertisementFunnelPageSaga = function* ({payload}) {
    const {
        isSearch,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/funnel`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/advertisements/order/addAll
// POST bff/client-adm/advertisements/order/addAll
// Пригласить исполнителей к заказу
const advertisementOrderInviteSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            isMassOperation,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/order/addAll`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError();
            }

            yield put({
                type: BFF_ADVERTISEMENT_ORDER_INVITE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Выбранные исполнители успешно приглашены");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADVERTISEMENT_ORDER_INVITE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_ADVERTISEMENT_PAGE_REQUEST, getAdvertisementPageSaga),
        takeEvery(BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST, addAdvertisementPublishSaga),
        takeEvery(BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST, addAdvertisementDraftSaga),
        takeEvery(BFF_ADVERTISEMENT_CLOSE_REQUEST, advertisementCloseSaga),
        takeEvery(BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST, importAdvertisementListSaga),
        takeEvery(BFF_UPDATE_ADVERTISEMENT_REQUEST, updateAdvertisementSaga),
        takeEvery(BFF_GET_ADVERTISEMENT_REQUEST, getAdvertisementSaga),
        takeEvery(BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST, advertisementPublishMultipleCheckSaga),
        takeEvery(BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST, advertisementPublishMultipleSaga),
        takeEvery(BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST, getAdvertisementViewPageSaga),
        takeEvery(BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST, getAdvertisementContractorsCountSaga),
        takeEvery(BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST, getAdvertisementFunnelPageSaga),
        takeEvery(BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST, advertisementOrderInviteSaga),
    ]);
}
