export const BFF_DISPUTE_LIST_GET_REQUEST = "BFF_DISPUTE_LIST_GET_REQUEST";
export const BFF_DISPUTE_LIST_GET_SUCCESS = "BFF_DISPUTE_LIST_GET_SUCCESS";
export const BFF_DISPUTE_LIST_GET_ERROR = "BFF_DISPUTE_LIST_GET_ERROR";

export const BFF_DISPUTE_UPDATE_REQUEST = "BFF_DISPUTE_UPDATE_REQUEST";
export const BFF_DISPUTE_UPDATE_SUCCESS = "BFF_DISPUTE_UPDATE_SUCCESS";
export const BFF_DISPUTE_UPDATE_ERROR = "BFF_DISPUTE_UPDATE_ERROR";

export const BFF_DISPUTE_EMPTY_CREATE_REQUEST = "BFF_DISPUTE_EMPTY_CREATE_REQUEST";
export const BFF_DISPUTE_EMPTY_CREATE_SUCCESS = "BFF_DISPUTE_EMPTY_CREATE_SUCCESS";
export const BFF_DISPUTE_EMPTY_CREATE_ERROR = "BFF_DISPUTE_EMPTY_CREATE_ERROR";

export const BFF_DISPUTE_UPDATE_STATUS_REQUEST = "BFF_DISPUTE_UPDATE_STATUS_REQUEST";
export const BFF_DISPUTE_UPDATE_STATUS_SUCCESS = "BFF_DISPUTE_UPDATE_STATUS_SUCCESS";
export const BFF_DISPUTE_UPDATE_STATUS_ERROR = "BFF_DISPUTE_UPDATE_STATUS_ERROR";

export const BFF_DISPUTE_GET_REQUEST = "BFF_DISPUTE_GET_REQUEST";
export const BFF_DISPUTE_GET_SUCCESS = "BFF_DISPUTE_GET_SUCCESS";
export const BFF_DISPUTE_GET_ERROR = "BFF_DISPUTE_GET_ERROR";

export const BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST = "BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST";
export const BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS = "BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS";
export const BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR = "BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR";

export const BFF_DISPUTE_GET_CHAT_LIST_REQUEST = "BFF_DISPUTE_GET_CHAT_LIST_REQUEST";
export const BFF_DISPUTE_GET_CHAT_LIST_SUCCESS = "BFF_DISPUTE_GET_CHAT_LIST_SUCCESS";
export const BFF_DISPUTE_GET_CHAT_LIST_ERROR = "BFF_DISPUTE_GET_CHAT_LIST_ERROR";

export const BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST = "BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST";
export const BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS = "BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS";
export const BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR = "BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR";

export const BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST = "BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST";
export const BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS = "BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS";
export const BFF_FILE_STORE_DISPUTE_ADD_FILE_ERROR = "BFF_FILE_STORE_DISPUTE_ADD_FILE_ERROR";

export const BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST = "BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST";
export const BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS = "BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS";
export const BFF_FILE_STORE_DISPUTE_DELETE_FILE_ERROR = "BFF_FILE_STORE_DISPUTE_DELETE_FILE_ERROR";

export const BFF_DISPUTES_CHAT_FILE_ADD_REQUEST = "BFF_DISPUTES_CHAT_FILE_ADD_REQUEST";
export const BFF_DISPUTES_CHAT_FILE_ADD_SUCCESS = "BFF_DISPUTES_CHAT_FILE_ADD_SUCCESS";
export const BFF_DISPUTES_CHAT_FILE_ADD_ERROR = "BFF_DISPUTES_CHAT_FILE_ADD_ERROR";

export const BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST = "BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST";
export const BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS = "BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS";
export const BFF_DISPUTES_CHAT_ADD_TO_CHAT_ERROR = "BFF_DISPUTES_CHAT_ADD_TO_CHAT_ERROR";

export const BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST = "BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST";
export const BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS = "BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS";
export const BFF_DISPUTES_ORDER_CONTRACTOR_LIST_ERROR = "BFF_DISPUTES_ORDER_CONTRACTOR_LIST_ERROR";

export const BFF_DISPUTE_UPDATE_FIELD_STORE = "BFF_DISPUTE_UPDATE_FIELD_STORE";
