export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR = "BFF_CONTRACTOR_REGISTRY_GET_PAGE_ERROR";

export const BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST = "BFF_CONTRACTOR_REGISTRY_IMPORT_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS = "BFF_CONTRACTOR_REGISTRY_IMPORT_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR = "BFF_CONTRACTOR_REGISTRY_IMPORT_ERROR";

export const BFF_CONTRACTOR_REGISTRY_ADD_REQUEST = "BFF_CONTRACTOR_REGISTRY_ADD_REQUEST";
export const BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS = "BFF_CONTRACTOR_REGISTRY_ADD_SUCCESS";
export const BFF_CONTRACTOR_REGISTRY_ADD_ERROR = "BFF_CONTRACTOR_REGISTRY_ADD_ERROR";

export const CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST = "CONTRACTOR_DELETE_FROM_BLACKLIST_REQUEST";
export const CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS = "CONTRACTOR_DELETE_FROM_BLACKLIST_SUCCESS";
export const CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR = "CONTRACTOR_DELETE_FROM_BLACKLIST_ERROR";

export const CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST = "CONTRACTOR_DELETE_FROM_FAVORITE_REQUEST";
export const CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS = "CONTRACTOR_DELETE_FROM_FAVORITE_SUCCESS";
export const CONTRACTOR_DELETE_FROM_FAVORITE_ERROR = "CONTRACTOR_DELETE_FROM_FAVORITE_ERROR";

export const CONTRACTOR_ADD_FAVORITE_REQUEST = "CONTRACTOR_ADD_FAVORITE_REQUEST";
export const CONTRACTOR_ADD_FAVORITE_SUCCESS = "CONTRACTOR_ADD_FAVORITE_SUCCESS";
export const CONTRACTOR_ADD_FAVORITE_ERROR = "CONTRACTOR_ADD_FAVORITE_ERROR";

export const CONTRACTOR_ADD_TO_BLACKLIST_REQUEST = "CONTRACTOR_ADD_TO_BLACKLIST_REQUEST";
export const CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS = "CONTRACTOR_ADD_TO_BLACKLIST_SUCCESS";
export const CONTRACTOR_ADD_TO_BLACKLIST_ERROR = "CONTRACTOR_ADD_TO_BLACKLIST_ERROR";

export const ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST = "ORDER_CONTRACTOR_ADD_SEVERAL_REQUEST";
export const ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS = "ORDER_CONTRACTOR_ADD_SEVERAL_SUCCESS";
export const ORDER_CONTRACTOR_ADD_SEVERAL_ERROR = "ORDER_CONTRACTOR_ADD_SEVERAL_ERROR";

export const CONTRACTOR_SHORT_LIST_READ_REQUEST = "CONTRACTOR_SHORT_LIST_READ_REQUEST";
export const CONTRACTOR_SHORT_LIST_READ_SUCCESS = "CONTRACTOR_SHORT_LIST_READ_SUCCESS";
export const CONTRACTOR_SHORT_LIST_READ_ERROR = "CONTRACTOR_SHORT_LIST_READ_ERROR";

export const BFF_CONTRACTOR_READ_REQUEST = "BFF_CONTRACTOR_READ_REQUEST";
export const BFF_CONTRACTOR_READ_SUCCESS = "BFF_CONTRACTOR_READ_SUCCESS";
export const BFF_CONTRACTOR_READ_ERROR = "BFF_CONTRACTOR_READ_ERROR";

export const BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST = "BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_REQUEST";
export const BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS = "BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_SUCCESS";
export const BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR = "BFF_CONTRACTOR_LIST_NO_BODY_BY_IDS_ERROR";

export const UPDATE_CONTRACTOR_REGISTRY_STORE = "UPDATE_CONTRACTOR_REGISTRY_STORE";