import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_CLEAR_STORE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_UPDATE_STORE_ERROR,
} from "./actions";

const initialState = {
    card: {},
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressCard: false,
    progressAction: false,
    error: null,
    itemsStatusDict: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST: {
            return {
                ...state,
                progressCard: true,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS: {
            return {
                ...state,
                card: payload,
                progressCard: false,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR: {
            return {
                ...state,
                progressCard: false,
                error: payload,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST: {
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS: {
            return {
                ...state,
                list: payload.results,
                totalCount: payload.totalCount,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR: {
            return {
                ...state,
                progress: false,
            };
        }
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR:
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS:
            return {
                ...state,
                itemsStatusDict: payload,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_UPDATE_STORE_ERROR:
            return {
                ...state,
                ...payload,
            };
        case BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_CLEAR_STORE_ERROR:
            return initialState;
        default:
            return state;
    }
};