import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmButton from "../../../../components/NmButton";

import {getCurrentStep} from "../utils/getCurrentStep";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {
    ORDER_CREATION_MODE,
} from "../../../../constants/order";
import {FOREMAN, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR} from "../../../../constants/roles";
import {ORDER_CREATE_STEPS} from "../constants";

import {clientForemenRequestsProgressUpdateSelector} from "../../../../ducks/bff/clients/foremen/requests/selectors";
import {
    documentCustomTemplateProgressReplacementsForUnavailableSelector,
} from "../../../../ducks/bff/documents-templates/selectors";
import {ordersRegistryActionSelector} from "../../../../ducks/bff/orders/orders-registry/selectors";
import {
    orderProgressCheckWarningBeforePublishSelector,
} from "../../../../ducks/order";

import "./style.sass";

const OrderEditActions = (props) => {
    const {
        role,
        orderStatus,
        activeItems,
        saveToContractorSearchStatus,
        goNext,
        goBack,
        saveOrder,
        handleClose,
        creatingMode,
        ordersPublicationProhibited,
        publishTemplate,
        tabs,
    } = props;
    const {t} = useTranslation();
    const progressUpdate = useSelector(ordersRegistryActionSelector);
    const foremanProgress = useSelector(clientForemenRequestsProgressUpdateSelector);
    const progressCheckWarningBeforePublish = useSelector(orderProgressCheckWarningBeforePublishSelector);
    const progressReplacementsForUnavailable = useSelector(documentCustomTemplateProgressReplacementsForUnavailableSelector);

    const isLoading = progressUpdate ||
        foremanProgress ||
        progressReplacementsForUnavailable ||
        progressCheckWarningBeforePublish;

    const currentStep = getCurrentStep({activeItems, tabs});

    if (creatingMode !== ORDER_CREATION_MODE.TEMPLATE) {
        return (
            <div className="order-edit-actions">
                {
                    currentStep === ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE ?
                        <NmButton
                            size="xl"
                            color="grey"
                            onClick={handleClose}
                            className="order-edit-actions__cancel"
                        >
                            {t("button.cancel")}
                        </NmButton> :
                        <NmButton
                            size="xl"
                            color="grey"
                            onClick={goBack}
                            className="order-edit-actions__back"
                        >
                            {t("order-new.buttonBack")}
                        </NmButton>
                }
                {
                    ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR].includes(role) &&
                    <div className="order-edit-actions__column">
                        {
                            orderStatus !== ORDER_STATUS.PUBLISHED &&
                            creatingMode !== ORDER_CREATION_MODE.FROM_TEMPLATE &&
                            currentStep !== ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE &&
                            <NmButton
                                size="xl"
                                color="grey"
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={() => {
                                    saveOrder(ORDER_STATUS.DRAFT);
                                }}
                                className="order-edit-actions__button"
                            >
                                {t("order-new.button-save-draft")}
                            </NmButton>
                        }
                        {
                            currentStep === ORDER_CREATE_STEPS.PUBLISH_STEP.VALUE ?
                                (ordersPublicationProhibited && role === FOREMAN) ? null : <NmButton
                                    size="xl"
                                    onClick={() => {
                                        saveToContractorSearchStatus(ORDER_STATUS.CONTRACTOR_SEARCH);
                                    }}
                                    className="order-edit-actions__button order-edit-actions__button_no-mt"
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    {orderStatus === ORDER_STATUS.PUBLISHED ? t("order-new.save-changes") : t("order-new.button-publish")}
                                </NmButton> :
                                <NmButton
                                    size="xl"
                                    onClick={goNext}
                                    className="order-edit-actions__button order-edit-actions__button_no-mt"
                                >
                                    {t("order-new.buttonNext")}
                                </NmButton>
                        }
                    </div>
                }
            </div>
        );
    }

    return (
        <div className="order-edit-actions">
            {
                currentStep !== ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE &&
                <NmButton
                    size="xl"
                    color="grey"
                    className="order-edit-actions__back"
                    onClick={goBack}
                >
                    {t("order-new.buttonBack")}
                </NmButton>
            }
            {
                role !== NM_COORDINATOR &&
                <div className="order-edit-actions__column">
                    {
                        <NmButton
                            size="xl"
                            color="grey"
                            onClick={handleClose}
                            className="order-edit-actions__cancel"
                        >
                            {t("button.cancel")}
                        </NmButton>
                    }
                    {
                        currentStep === ORDER_CREATE_STEPS.ACT_TEMPLATE.VALUE ?
                            <NmButton
                                size="xl"
                                onClick={() => {
                                    publishTemplate({
                                        status: ORDER_STATUS.TEMPLATE,
                                        checkingForDeleting: true,
                                    });
                                }}
                                className="order-edit-actions__button order-edit-actions__button_no-mt"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Сохранить шаблон
                            </NmButton> :
                            <NmButton
                                size="xl"
                                className="order-edit-actions__button order-edit-actions__button_no-mt"
                                onClick={goNext}
                            >
                                {t("order-new.buttonNext")}
                            </NmButton>
                    }
                </div>
            }
        </div>
    );
};

export default OrderEditActions;