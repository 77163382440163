import {
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR,
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST,
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS,
    BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR,
    BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST,
    BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS,
} from "./actions";

const initialState = {
    card: {},
    progress: false,
    actionProgress: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST:
        case BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST:
        case BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST:
        case BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST:
            return {
                ...state,
                actionProgress: true,
                error: null,
            };
        case BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS:
        case BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS:
        case BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS:
        case BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR:
        case BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR:
        case BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR:
        case BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        default:
            return state;
    }
};