import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getPageOrderContractorWorkLog} from "./actionCreators";
import {
    ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST, 
    ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST,
    ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../utils/url";

import {LINK_APPLICATION_ANALYTICS} from "../../../../constants/links";

const getController = () => {
    return getBffUrl(
        {
            [LINK_APPLICATION_ANALYTICS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/reports/order-analytics/responses/hired/contractor-work-log",
                client: "/client-adm/reports/order-analytics/responses/hired/contractor-work-log",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/orders/orders-registry/card/contractors/hired/contractor-work-log",
            adminRolesUrl: "/adm/clients/client-card/orders/orders-registry/card/contractors/hired/contractor-work-log",
        },
        null,
        true,
    );
};

const getPageOrderContractorWorkLogSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const updateOrderContractorWorkLogCommonInfoSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/common-info/add-or-update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
            payload: error,
        });
    }
};

const removeOrderContractorWorkLogCommonInfoSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.delete(`${getController()}/common-info/remove-pay-time`, {data});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_ERROR,
            payload: error,
        });
    }
};

const addOrderContractorWorkLogClientTimeSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_ERROR,
            payload: error,
        });
    }
};

const updateOrderContractorWorkLogClientTimeSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_ERROR,
            payload: error,
        });
    }
};

const deleteOrderContractorWorkLogClientTimeSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete`, {data});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_ERROR,
            payload: error,
        });
    }
};

const addOrderContractorWorkLogSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-range`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();

        yield put(getPageOrderContractorWorkLog(state.bff.orderContractorWorkLog.pageData));

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteOrderContractorWorkLogSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete-range`, {data});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_WORK_LOG_DELETE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_GET_PAGE_REQUEST, getPageOrderContractorWorkLogSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_UPDATE_REQUEST, updateOrderContractorWorkLogCommonInfoSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_COMMON_INFO_REMOVE_PAY_TIME_REQUEST, removeOrderContractorWorkLogCommonInfoSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_ADD_REQUEST, addOrderContractorWorkLogClientTimeSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_UPDATE_REQUEST, updateOrderContractorWorkLogClientTimeSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_CLIENT_TIME_DELETE_REQUEST, deleteOrderContractorWorkLogClientTimeSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_ADD_REQUEST, addOrderContractorWorkLogSaga),
        takeEvery(ORDER_CONTRACTOR_WORK_LOG_DELETE_REQUEST, deleteOrderContractorWorkLogSaga),
    ]);
}