import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmInfoCard from "../../../components/ActualComponents/NmInfoCard";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ContractorBankSender from "../../../components/ContractorBankSender";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import RefreshButton from "../../../components/RefreshButton";

import {useContractorBanksAction} from "./hooks/useAction";
import {useContractorBanksFetch} from "./hooks/useFetch";

import {getUserRestrictions} from "../../../utils/access";
import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmountWithNullChecking} from "../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {CONTRACTOR_BANK_IDENTIFICATION_STATUS, CONTRACTOR_BANK_STATUS_DICT} from "../../../constants/contractorBanks";
import {BANK_TYPES_CONTRACTOR_FINANCE} from "../../../constants/contractorFinance";

import {
    getContractorBanksListSelector,
    getContractorBanksProgressSelector,
} from "../../../ducks/bff/contractor-сard/banks/selectors";
import {contractorCardInfoSelector} from "../../../ducks/bff/contractor-сard/selectors";

const ContractorBanksList = (props) => {
    const {
        match: {
            params: {
                contractorId,
            },
        },
    } = props;

    const list = useSelector(getContractorBanksListSelector);
    const progress = useSelector(getContractorBanksProgressSelector);
    const contractor = useSelector(contractorCardInfoSelector);

    const currentUserRestrictions = getUserRestrictions();

    const {
        fetchList,
    } = useContractorBanksFetch({
        contractorId,
    });

    const {
        refreshIdentificationStatus,
        refreshBankStatus,
        progressRefreshIdentificationStatus,
        progressRefreshBankStatus,
    } = useContractorBanksAction({
        contractorId,
        fetchList,
    });

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                bankType,
                lastIdentificationRequest,
                bankIdentificationStatus,
                bankContractorId,
                bankContractorStatus,
                changeBankStatusAt,
                bankName,
                balance,
                walletLimit,
                dayIncome,
                monthIncome,
                availableDayIncome,
                availableMonthIncome,
                blockDetails,
            } = item;

            const {
                balance: balanceValue = 0,
                reserveBalance = 0,
                withdrawalBalance = 0,
            } = balance || {};

            const {
                singleLimit = 0,
                dayLimit = 0,
                monthLimit = 0,
            } = walletLimit || {};

            const isProgressRefreshIdentificationStatus = progressRefreshIdentificationStatus.includes(bankType);
            const isProgressRefreshBankStatus = progressRefreshBankStatus.includes(bankType);

            return {
                ...item,
                key: bankType,
                contentRow: (
                    <NmListCard
                        primaryHeader={bankName}
                        noDivider
                        secondaryHeader={
                            <div className="flex">
                                {formatDate(convertUtcToLocal(changeBankStatusAt), "dd.MM.yyyy HH:mm")}
                                <RefreshButton
                                    className="ml-2"
                                    containerIcon
                                    disabled={isProgressRefreshIdentificationStatus}
                                    animate={isProgressRefreshIdentificationStatus}
                                    onClick={() => refreshIdentificationStatus(bankType)}
                                    style={{
                                        color: COLOR.SECONDARY_70,
                                        width: "18px",
                                        height: "18px",
                                    }}
                                />
                            </div>

                        }
                        classNameMainContent="col-16"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={
                                    CONTRACTOR_BANK_IDENTIFICATION_STATUS[bankIdentificationStatus]?.TEXT ||
                                    "Не зарегистрирован"
                                }
                                mod={CONTRACTOR_BANK_IDENTIFICATION_STATUS[bankIdentificationStatus]?.MOD || "gray"}
                            />
                        }
                        labels={[
                            {
                                label: "Статус в банке",
                                text: <div className="flex flex-aligned-center">
                                    {
                                        CONTRACTOR_BANK_STATUS_DICT[bankContractorStatus] ||
                                        "Исполнитель не зарегистрирован в банке"
                                    }
                                    {
                                        blockDetails &&
                                        <HelpTooltip
                                            className="ml-2"
                                            info
                                            hover
                                            children={blockDetails}
                                        />
                                    }
                                    <RefreshButton
                                        className="ml-2"
                                        containerIcon
                                        disabled={isProgressRefreshBankStatus}
                                        animate={isProgressRefreshBankStatus}
                                        onClick={() => refreshBankStatus(bankType)}
                                        style={{
                                            color: COLOR.SECONDARY_70,
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                </div>,
                                columnOnMobile: true,
                                noWrap: false,
                                wrapped: true,
                            },
                            {
                                label: "Идентификатор",
                                text: bankContractorId || "-",
                                columnOnMobile: true,
                            },
                        ]}
                        otherContent={
                            <div className="col-16 mt-4">
                                <div className="flex flex-column flex-xl-row col-16 mb-3">
                                    <NmInfoCard
                                        className="col-16 col-xl-3"
                                        title="Баланс кошелька, ₽"
                                        value={formatAmountWithNullChecking(balanceValue)}
                                    />
                                    {
                                        bankType === BANK_TYPES_CONTRACTOR_FINANCE.HERMES_W1 &&
                                        <>
                                            <NmInfoCard
                                                className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                                title="Захолдировано, ₽"
                                                value={formatAmountWithNullChecking(reserveBalance)}
                                            />
                                            <NmInfoCard
                                                className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                                title="Доступно к выводу, ₽"
                                                value={formatAmountWithNullChecking(withdrawalBalance)}
                                            />
                                        </>
                                    }
                                </div>
                                <div className="flex flex-column flex-xl-row col-16 mb-3">
                                    <NmInfoCard
                                        className="col-16 col-xl-3"
                                        title="Разовый / Суточный лимит, ₽"
                                        values={[
                                            {text: formatAmountWithNullChecking(singleLimit)},
                                            {text: formatAmountWithNullChecking(dayLimit)},
                                        ]}
                                    />
                                    <NmInfoCard
                                        className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                        title="Израсходовано суточный лимит, ₽"
                                        value={formatAmountWithNullChecking(dayIncome)}
                                    />
                                    <NmInfoCard
                                        className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                        title="Остаток, ₽"
                                        value={formatAmountWithNullChecking(availableDayIncome)}
                                    />
                                </div>
                                <div className="flex flex-column flex-xl-row col-16 mb-3">
                                    <NmInfoCard
                                        className="col-16 col-xl-3"
                                        title="Месячный лимит, ₽"
                                        value={formatAmountWithNullChecking(monthLimit)}
                                    />
                                    <NmInfoCard
                                        className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                        title="Израсходовано месячный лимит, ₽"
                                        value={formatAmountWithNullChecking(monthIncome)}
                                    />
                                    <NmInfoCard
                                        className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                                        title="Остаток, ₽"
                                        value={formatAmountWithNullChecking(availableMonthIncome)}
                                    />
                                </div>
                                {
                                    isAccessByRestrictions([
                                        CLIENT_USER_RESTRICTIONS_VARIABLE.sendBankPersonalData,
                                    ]) &&
                                    <ContractorBankSender
                                        {...contractor}
                                        contractorId={contractorId}
                                        bankType={bankType}
                                        lastIdentificationRequest={lastIdentificationRequest}
                                        bankIdentificationStatus={bankIdentificationStatus}
                                    />
                                }
                            </div>
                        }
                    />
                ),
            };
        });
    }, [
        list,
        progressRefreshIdentificationStatus,
        progressRefreshBankStatus,
        contractor,
        currentUserRestrictions,
    ]);

    return (
        <NmPage
            noPadding
            overflowUnset
            isLoaded={progress}
        >
            {
                list.length ?
                    <CheckboxList
                        rows={rows}
                    /> :
                    <NmEmptyPageV2
                        title="Информация отсутствует"
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default ContractorBanksList;