import {FILTER} from "../index";

import {FilterGetValueParams, FilterGetValueParamsReturn} from "../types";

export const getFilterValue = (params: FilterGetValueParams): FilterGetValueParamsReturn => {
    const {
        name,
        props,
        form,
    } = params;

    switch (name) {
        case FILTER.DATE_RANGE:
            return {
                value: {
                    [props.startFieldName]: form[props.startFieldName],
                    [props.endFieldName]: form[props.endFieldName],
                },
            };
        case FILTER.RANGE_INPUT:
            return {
                valueStart: form[props.nameStart],
                valueEnd: form[props.nameEnd],
            };
        case FILTER.PROJECT:
            return {
                projectIdsFilter: form[props.name],
            };
        case FILTER.OBJECT:
            return {
                objectIdsFilter: form[props.name],
            };
        case FILTER.INPUT:
            return {
                value: form[props.name] || "",
            };
        default:
            return {
                value: form[props.name],
            };
    }
};