import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getAdvertisementPage} from "../../../../ducks/bff/advertisement/actionCreators";

export function useAdvertisementFetchList({filter = {}, clientId, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        const requestData = {
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            ...filter,
        };

        dispatch(getAdvertisementPage(requestData));
    };

    return {
        fetchList,
    };
}