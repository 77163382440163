import {
    BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_REQUEST,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE,
    BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST,
} from "./actions";

export const getPageRecruitmentVacancyResponses = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST,
        payload,
    };
};

export const setCandidateRecruitmentVacancyResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST,
        payload,
    };
};

export const setRecruitmentVacancyResponseHeadHunterStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyResponseCardById = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST,
        payload,
    };
};

export const uploadRecruitmentVacancyResponsesFile = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancyResponsesFile = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST,
        payload,
    };
};

export const rejectRecruitmentResponse = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST,
        payload,
    };
};

export const setRecruitmentVacancyResponseStatus = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST,
        payload,
    };
};

export const addRecruitmentKedoStaff = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_REQUEST,
        payload,
    };
};


export const updateRecruitmentKedoStaff = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyResponsesStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE,
        payload,
    };
};