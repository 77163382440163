import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SMART_LINK_ARCHIVE_ERROR,
    SMART_LINK_ARCHIVE_REQUEST,
    SMART_LINK_ARCHIVE_SUCCESS,
    SMART_LINK_CACHED_CLIENTS_ERROR,
    SMART_LINK_CACHED_CLIENTS_REQUEST,
    SMART_LINK_CACHED_CLIENTS_SUCCESS,
    SMART_LINK_CHECK_ORDER_EXISTS_ERROR,
    SMART_LINK_CHECK_ORDER_EXISTS_REQUEST,
    SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS,
    SMART_LINK_CLIENT_PART_SAVE_ERROR,
    SMART_LINK_CLIENT_PART_SAVE_REQUEST,
    SMART_LINK_CLIENT_PART_SAVE_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST,
    SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS,
    SMART_LINK_CREATE_ERROR,
    SMART_LINK_CREATE_REQUEST,
    SMART_LINK_CREATE_SUCCESS,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST,
    SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS,
    SMART_LINK_DELETE_CACHED_CLIENT_ERROR,
    SMART_LINK_DELETE_CACHED_CLIENT_REQUEST,
    SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS,
    SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR,
    SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS,
    SMART_LINK_FILTER_PART_SAVE_ERROR,
    SMART_LINK_FILTER_PART_SAVE_REQUEST,
    SMART_LINK_FILTER_PART_SAVE_SUCCESS,
    SMART_LINK_GET_CARD_ERROR,
    SMART_LINK_GET_CARD_REQUEST,
    SMART_LINK_GET_CARD_SUCCESS,
    SMART_LINK_GET_COUNT_BY_FILTER_ERROR,
    SMART_LINK_GET_COUNT_BY_FILTER_REQUEST,
    SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS,
    SMART_LINK_GET_DETAILS_TREE_ERROR,
    SMART_LINK_GET_DETAILS_TREE_REQUEST,
    SMART_LINK_GET_DETAILS_TREE_SUCCESS,
    SMART_LINK_IMPORT_NUMBER_ERROR,
    SMART_LINK_IMPORT_NUMBER_REQUEST,
    SMART_LINK_IMPORT_NUMBER_SUCCESS,
    SMART_LINK_PAGE_ERROR,
    SMART_LINK_PAGE_REQUEST,
    SMART_LINK_PAGE_SUCCESS,
    SMART_LINK_UPDATE_ERROR,
    SMART_LINK_UPDATE_REQUEST,
    SMART_LINK_UPDATE_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../utils/postman";
import {toastError, toastSuccess} from "../../utils/toastHelper";
import {getBffUrl} from "../../utils/url";

import {LINK_MAILINGS_LIST} from "../../constants/links";

const controller = "/adm/marketing/link-constructor";

// POST /bff/adm/marketing/link-constructor/page
// POST /bff/adm/marketing/notifications/get-smart-link-page
// Получение страницы
const getSmartLinkPage = function* ({payload}) {
    try {
        const url = getBffUrl(
            {
                [LINK_MAILINGS_LIST]: "/adm/marketing/notifications/get-smart-link-page",
            },
            `${controller}/page`,
            true,
        );

        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMART_LINK_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/marketing/link-constructor/{smartLinkId}
// Получение ссылки
const getSmartLinkCard = function* ({payload}) {
    const {
        smartLinkId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${smartLinkId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_GET_CARD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMART_LINK_GET_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_GET_CARD_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/marketing/link-constructor/{smartLinkId}/update
// Обновление ссылки
const updateSmartLink = function* ({payload}) {
    const {
        onSuccess,
        smartLinkId,
        ...data
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${smartLinkId}/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/import-number
// Импорт номеров из файла
const importSmartLinkOrderNumbers = function* ({payload}) {
    const {
        onSuccess,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/import-number`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_IMPORT_NUMBER_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_IMPORT_NUMBER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_IMPORT_NUMBER_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/marketing/link-constructor/validate-number
// Проверить существование номера заказа/задания
const checkSmartLinkOrderNumberExists = function* ({payload}) {
    const {
        saveNumber,
        ...data
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/validate-number`, {params: data});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CHECK_ORDER_EXISTS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        saveNumber(result);

        yield put({
            type: SMART_LINK_CHECK_ORDER_EXISTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CHECK_ORDER_EXISTS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/get-count-by-filter
// Получить количество заказов/заданий по фильтру
const getSmartLinkCountByFilter = function* ({payload}) {
    const {
        onSuccess,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/get-count-by-filter`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_GET_COUNT_BY_FILTER_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMART_LINK_GET_COUNT_BY_FILTER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_GET_COUNT_BY_FILTER_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/get-client-settings/project
// Получить дерево настроек клиента - проекты
const getSmartLinkClientProjects = function* ({payload}) {
    const {
        onSuccess,
        isSearchCleared,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/get-client-settings/project`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_PROJECT_SUCCESS,
            payload: {
                ...result,
                clientId: data.clientId,
            },
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_PROJECT_ERROR,
            payload: {
                error,
                clientId: data.clientId,
            },
        });
    }
};

// POST /bff/adm/marketing/link-constructor/get-client-settings/object
// Получить дерево настроек клиента - объекты
const getSmartLinkClientObjects = function* ({payload}) {
    const {
        onSuccess,
        ...data
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/get-client-settings/object`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_OBJECT_SUCCESS,
            payload: {
                ...result,
                projectId: data.projectIds[0],
            },
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_OBJECT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/get-client-settings/search-tree
// Получить дерево настроек клиента - список проектов с объектами
const getSmartLinkObjectsProjectList = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get-client-settings/search-tree`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/marketing/link-constructor/{smartLinkId}/client-settings/{clientId}
// Получить настройки заказчика
const getSmartLinkDetailsTree = function* ({payload}) {
    const {
        clientId,
        smartLinkId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${smartLinkId}/client-settings/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_GET_DETAILS_TREE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_GET_DETAILS_TREE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_GET_DETAILS_TREE_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/marketing/link-constructor/{smartLinkId}/archive
// PUT /bff/adm/marketing/link-constructor/{smartLinkId}/unarchive
// Добавить ссылку в архив / Удалить ссылку из архива
const archiveSmartLink = function* ({payload}) {
    const {
        smartLinkId,
        archived,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.put(`${controller}/${smartLinkId}/${archived ? "unarchive" : "archive"}`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_ARCHIVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(archived ? "Ссылка восстановлена" : "Ссылка в архиве");

        onSuccess();

        yield put({
            type: SMART_LINK_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/filter-part/save
// Сохранение основных фильтров в кэш
const saveSmartLinkFilterPart = function* ({payload}) {
    const {
        onSuccess,
        smartLinkId = "",
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/filter-part/save?smartLinkId=${smartLinkId}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_FILTER_PART_SAVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: SMART_LINK_FILTER_PART_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_FILTER_PART_SAVE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/create
// Поиск основных фильтров в кэше, объединение с деревом настройки клиента и сохранение в БД
// Перед использованием данного метода необходимо добавить основную часть фильтра в кэш при помощи метода "Сохранение основных фильтров в кэш"
const createSmartLink = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/create`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_CREATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CREATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/client-part/save
// Сохранение настроек клиента в кэш
const saveSmartLinkClientPart = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/client-part/save`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CLIENT_PART_SAVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: SMART_LINK_CLIENT_PART_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CLIENT_PART_SAVE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/{smartLinkId}/filter-part/save
// Сохранение основных фильтров в кэш при редактировании
const editSmartLinkFilterPart = function* ({payload}) {
    const {
        onSuccess,
        smartLinkId,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/${smartLinkId}/filter-part/save`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: SMART_LINK_EDIT_FILTER_PART_SAVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_EDIT_FILTER_PART_SAVE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/marketing/link-constructor/{smartLinkId}/cached-clients
// Получить из кэша настройки заказчиков
const getSmartLinkCachedClients = function* ({payload}) {
    const {
        smartLinkId,
    } = payload;

    try {
        const result = yield request.bff.get(`${controller}/${smartLinkId}/cached-clients`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_CACHED_CLIENTS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SMART_LINK_CACHED_CLIENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_CACHED_CLIENTS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/marketing/link-constructor/delete-cached-client
// Удалить из кэша настройки заказчика
const deleteSmartLinkCachedClient = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/delete-cached-client`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_DELETE_CACHED_CLIENT_ERROR,
                payload: {
                    ...result,
                    clientId: payload.clientId,
                },
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_DELETE_CACHED_CLIENT_SUCCESS,
            payload: {
                ...result,
                clientId: payload.clientId,
            },
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_DELETE_CACHED_CLIENT_ERROR,
            payload: {
                error,
                clientId: payload.clientId,
            },
        });
    }
};

//POST /bff/adm/marketing/link-constructor/delete-all-cached-clients
// Удалить из кэша настройки заказчика
const deleteSmartLinkAllCachedClients = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/delete-all-cached-clients`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: SMART_LINK_DELETE_ALL_CACHED_CLIENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SMART_LINK_DELETE_ALL_CACHED_CLIENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SMART_LINK_PAGE_REQUEST, getSmartLinkPage),
        takeEvery(SMART_LINK_GET_CARD_REQUEST, getSmartLinkCard),
        takeEvery(SMART_LINK_UPDATE_REQUEST, updateSmartLink),
        takeEvery(SMART_LINK_IMPORT_NUMBER_REQUEST, importSmartLinkOrderNumbers),
        takeEvery(SMART_LINK_CHECK_ORDER_EXISTS_REQUEST, checkSmartLinkOrderNumberExists),
        takeEvery(SMART_LINK_GET_COUNT_BY_FILTER_REQUEST, getSmartLinkCountByFilter),
        takeEvery(SMART_LINK_CLIENT_SETTINGS_PROJECT_REQUEST, getSmartLinkClientProjects),
        takeEvery(SMART_LINK_CLIENT_SETTINGS_OBJECT_REQUEST, getSmartLinkClientObjects),
        takeEvery(SMART_LINK_CLIENT_SETTINGS_OBJECT_PROJECT_LIST_REQUEST, getSmartLinkObjectsProjectList),
        takeEvery(SMART_LINK_GET_DETAILS_TREE_REQUEST, getSmartLinkDetailsTree),
        takeEvery(SMART_LINK_ARCHIVE_REQUEST, archiveSmartLink),
        takeEvery(SMART_LINK_FILTER_PART_SAVE_REQUEST, saveSmartLinkFilterPart),
        takeEvery(SMART_LINK_CREATE_REQUEST, createSmartLink),
        takeEvery(SMART_LINK_CLIENT_PART_SAVE_REQUEST, saveSmartLinkClientPart),
        takeEvery(SMART_LINK_EDIT_FILTER_PART_SAVE_REQUEST, editSmartLinkFilterPart),
        takeEvery(SMART_LINK_CACHED_CLIENTS_REQUEST, getSmartLinkCachedClients),
        takeEvery(SMART_LINK_DELETE_CACHED_CLIENT_REQUEST, deleteSmartLinkCachedClient),
        takeEvery(SMART_LINK_DELETE_ALL_CACHED_CLIENTS_REQUEST, deleteSmartLinkAllCachedClients),
    ]);
}