import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isEmpty} from "lodash";

import {isRequiredFullRegistrationVhiPolicySwitch} from "../utils/isRequiredVhiPolicySwitch";
import {useFullRegistrationValidationError} from "./useShowValidationError";
import {useFullRegistrationSteps} from "./useSteps";

import {useCounter} from "../../../hooks/useCounter";
import {useMigrationStatusDict} from "../../../hooks/useMigrationStatusDict";

import {convertUtcToLocal} from "../../../utils/dateFormat";
import {getInitialTouched} from "../../../utils/objectHelper";
import {handleFormString} from "../../../utils/stringHelper";
import {
    getDocNumber,
    getFullRegistrationRequestData,
    getFullRegistrationRequestDataFiles,
} from "../utils/dto";
import {getFullRegistrationInnFileType} from "../utils/getInnFileType";
import {getFullRegistrationStepsConfig} from "../utils/getStepsConfig";
import {getFullRegistrationVhiPolicyType} from "../utils/getVhiPolicyType";
import {getFullRegistrationFields} from "../utils/helpers/getFields";
import {isOnlyArrivalNotification} from "../utils/isOnlyArrivalNotification";
import {isOnlyMigrationCardPresent} from "../utils/isOnlyMigrationCardPresent";

import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {CONTRACTOR_ARRIVAL_DOC_TYPE_CODE, CONTRACTOR_STATUS} from "../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {
    FULL_REGISTRATION_COMPONENT_TYPE,
    FULL_REGISTRATION_STEP,
} from "../constants";

import {getInfoContractorCard} from "../../../ducks/bff/contractor-сard/actionCreators";
import {
    getInnByPersonalInfo,
    updateContractor,
} from "../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    checkInnBeforeAddition,
    contractorPersonalDataPendingDraftComplete,
    getContractorFilesRequiredList,
} from "../../../ducks/contractor";
import {
    addContractorFileScanFileToFileStore,
    addFileContractorAdditionalScan,
    fileContractorOriginalScanBase64ObjSelector,
    fileContractorScanObjSelector,
    getFileContractorAdditionalScan,
    getFileContractorScan,
    updateFieldFilesStore,
} from "../../../ducks/fileStore";

export const useFullRegistrationForm = (params) => {
    const {
        goNext,
        currentStep,
        initialValues,
        setSteps,
        contractor,
        onClose,
        steps,
    } = params;

    const scans = useSelector(fileContractorOriginalScanBase64ObjSelector);
    const thumbnailScans = useSelector(fileContractorScanObjSelector);
    const dispatch = useDispatch();

    const [validationSchema, setValidationSchema] = useState(null);
    const [progress, setProgress] = useState(false);
    const [submitWithRequestProgress, setSubmitWithRequestProgress] = useState(false);
    const [hideSubmitWithoutInn, setHideSubmitWithoutInn] = useState(false);
    const [filesErrors, setFilesErrors] = useState({});

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        errors,
        setErrors,
        isValid,
        validateForm,
        touched,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        initialTouched: getInitialTouched(initialValues),
        validationSchema,
        validateOnBlur: false,
    });

    const addedToFormFileTypes = getFullRegistrationRequestDataFiles({
        values,
        migrationStatus: values.migrationStatus,
        citizenship: values.citizenship,
        locatedOutsideRussia: contractor.locatedOutsideRussia,
    });

    const {
        isVisibleValidationError,
        onErrorUpdateContractor,
        setErrorsContractorFiles,
    } = useFullRegistrationValidationError({
        values,
        currentStep,
        setErrors,
    });

    const {
        migrationStatusOptions,
    } = useMigrationStatusDict({
        citizenship: values.citizenship,
        nameField: "registrationMigrationStatusDict",
    });

    const {} = useFullRegistrationSteps({
        setSteps,
        values,
        migrationStatusOptions,
    });

    const checkFiles = (withoutInn) => {
        const requestData = getFullRegistrationRequestData({
            values,
            steps,
            withoutInn,
        });

        dispatch(getContractorFilesRequiredList({
            ...contractor,
            ...requestData,
            needInnScan: values.hasInn,
            getResult: ({list = []}) => {
                const _list = list.filter((item) => !addedToFormFileTypes.includes(item));
                if (_list.length) {
                    setErrorsContractorFiles(_list);
                    setProgress(false);

                    return;
                }

                setSubmitWithRequestProgress(true);
                updateContractorInfo({actualData: false, withoutInn});
            },
        }));
    };

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: addedToFormFileTypes});

    useEffect(() => {
        if (isCounterFinish && submitWithRequestProgress) {
            personalDataPendingDraftComplete();

            return;
        }

        if (isCounterFinish) {
            finishSubmitForm();
        }
    }, [isCounterFinish]);

    useEffect(() => {
        if (isEmpty(currentStep)) {
            return;
        }

        if (
            !(contractor.registrationStatus !== CONTRACTOR_STATUS.BASIC_REGISTRATION && !contractor.fakeInn) &&
            currentStep.value === FULL_REGISTRATION_STEP.INN
        ) {
            dispatch(getInnByPersonalInfo({
                firstName: handleFormString(values.firstName),
                lastName: handleFormString(values.lastName),
                patronymic: handleFormString(values.patronymic),
                citizenship: values.citizenship,
                birthDate: values.birthDate,
                idDocNumber: handleFormString(values.idDocNumber),
                residencePermitNumber: getDocNumber({
                    series: values.residencePermitSeries,
                    number: values.residencePermitNumber,
                }),
                temporaryResidencePermitNum: handleFormString(values.temporaryResidencePermitNum),
                refugeeCertificateNum: handleFormString(values.refugeeCertificateNum),
                temporaryRefugeeCertificateNum: handleFormString(values.temporaryRefugeeCertificateNum),
                statelessPersonResidencePermitNumber: handleFormString(values.statelessPersonResidencePermitNumber),
                getResult: ({inn}) => {
                    if (inn) {
                        setValues({
                            ...values,
                            inn,
                        });

                        setHideSubmitWithoutInn(true);

                        return;
                    }

                    setHideSubmitWithoutInn(false);
                },
            }));
        }

        const fields = currentStep.fields(values);
        const scanFields = fields.filter(item => item.type === FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE);

        if (isEmpty(scanFields)) {
            return;
        }

        scanFields.forEach(item => {
            if (
                scans[item.name] ||
                // если нет миниатюры (подгружаются на вкладке пд), то не подгружаем изображение
                !thumbnailScans[item.name]
            ) {
                return;
            }

            if (
                [
                    CONTRACTOR_FILE_TYPES.INN,
                ].includes(item.name)
            ) {
                dispatch(getFileContractorAdditionalScan({
                    contractorId: contractor.contractorId,
                    field: "contractorOriginalScanObj",
                    contractorFileType: CONTRACTOR_FILE_TYPES.INN,
                    thumbnail: false,
                }));

                return;
            }

            dispatch(getFileContractorScan({
                contractorId: contractor.contractorId,
                contractorFileType: item.name,
                field: "contractorOriginalScanObj",
                thumbnail: false,
                isPending: true,
            }));
        });
    }, [currentStep.value]);

    useEffect(() => {
        return () => {
            dispatch(updateFieldFilesStore({
                contractorOriginalScanObj: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (!currentStep.validationSchema) {
            return;
        }

        const validationScheme = currentStep.validationSchema(values);

        setValidationSchema(validationScheme);
    }, [currentStep, values]);

    useEffect(() => {
        setErrors({});
    }, [values.vhiPolicyPresent]);

    useEffect(() => {
        if (
            isOnlyArrivalNotification({
                citizenship: values.citizenship,
                migrationStatus: values.migrationStatus,
            })
        ) {
            setFieldValue("arrivalDocType", CONTRACTOR_ARRIVAL_DOC_TYPE_CODE.ARRIVAL_NOTIFICATION);
        }

        if (
            isRequiredFullRegistrationVhiPolicySwitch({
                citizenship: values.citizenship,
                migrationStatus: values.migrationStatus,
            })
        ) {
            setFieldValue("vhiPolicyPresent", true);
        }

        const vhiPolicyType = getFullRegistrationVhiPolicyType({
            citizenship: values.citizenship,
            migrationStatus: values.migrationStatus,
        });

        if (vhiPolicyType.disabled) {
            setFieldValue("vhiPolicyType", vhiPolicyType.value);
        }

        const isMigrationCardPresent = isOnlyMigrationCardPresent({
            citizenship: values.citizenship,
            migrationStatus: values.migrationStatus,
        });

        if (isMigrationCardPresent) {
            setFieldValue("migrationCardPresent", true);
        }
    }, [
        values.migrationStatus,
        values.citizenship,
    ]);


    function personalDataPendingDraftComplete() {
        dispatch(contractorPersonalDataPendingDraftComplete({
            contractorId: contractor.contractorId,
            onSuccess: finishSubmitForm,
        }));
    }

    function finishSubmitForm() {
        setProgress(false);
        setSubmitWithRequestProgress(false);
        dispatch(getInfoContractorCard(contractor.contractorId));
        onClose();
    }

    function onSubmit({withoutInn = false}) {
        if (!isValid) {
            return;
        }

        if (currentStep.isLastStep && !withoutInn && !values.inn) {
            setErrors({
                ...errors,
                inn: VALIDATIONS_MESSAGE.REQUIRED,
            });

            return;
        }

        if (currentStep.value === FULL_REGISTRATION_STEP.INN && Boolean(values.inn) && values.inn !== contractor.inn && !withoutInn) {
            checkInn({
                onSuccess: () => {
                    setProgress(true);
                    checkFiles();
                },
            });

            return;
        }

        if (currentStep.isLastStep) {
            setProgress(true);

            checkFiles(withoutInn);

            return;
        }

        goNext();
    }

    function checkInn({onSuccess = () => {}}) {
        dispatch(checkInnBeforeAddition({
            inn: values.inn,
            getResult: ({errorMessage}) => {
                if (errorMessage) {
                    setErrors({
                        ...errors,
                        inn: errorMessage,
                    });

                    setProgress(false);

                    return;
                }

                onSuccess();
            },
        }));
    }

    function onSubmitWithoutInn() {
        validateForm().then(() => {
            if (!isValid) {
                return;
            }

            onSubmit({withoutInn: true});
        });
    }

    const updateContractorInfo = ({actualData, withoutInn = false}) => {
        const requestData = getFullRegistrationRequestData({
            values,
            steps,
            withoutInn,
        });

        // были ли выключены переключатели на форме
        const isOffMigrationCardPresent = contractor.migrationCardPresent && !values.migrationCardPresent;
        const isOffVhiPolicyPresent = contractor.vhiPolicyPresent && !values.vhiPolicyPresent;

        dispatch(updateContractor({
            ...contractor,
            ...requestData,
            isGetAfter: false,
            wizardSubmission: true,
            actualData,
            successText: !actualData && "Заявка на проверку персональных данных успешно создана",
            onError: (data) => {
                onErrorUpdateContractor(data);
                setProgress(false);
            },
            onSuccess: () => {
                updateScansByPresentFlags({
                    isOffVhiPolicyPresent,
                    isOffMigrationCardPresent,
                });

                if (addedToFormFileTypes.length) {
                    addContractorFiles({actualData});

                    return;
                }

                if (!actualData) {
                    personalDataPendingDraftComplete();

                    return;
                }

                finishSubmitForm();
            },
        }));
    };

    const updateScansByPresentFlags = (params) => {
        const {
            isOffVhiPolicyPresent,
            isOffMigrationCardPresent,
        } = params;
        // если в модели исполнителя флаг переключателя === false, фото не придёт
        if (isOffMigrationCardPresent) {
            const [step] = getFullRegistrationStepsConfig({
                steps: [FULL_REGISTRATION_STEP.MIGRATION_CARD],
                citizenship: values.citizenship,
                migrationStatus: values.migrationStatus,
                locatedOutsideRussia: values.locatedOutsideRussia,
                migrationStatusOptions: [],
            });

            const fields = getFullRegistrationFields({
                step,
                values: {
                    ...values,
                    migrationCardPresent: true,
                },
                onlyFiles: true,
            });

            fields.forEach(contractorFileType => {
                dispatch(getFileContractorScan({
                    contractorId: contractor.contractorId,
                    contractorFileType,
                    isPending: true,
                    thumbnail: true,
                    includingPending: true,
                }));
            });
        }

        if (isOffVhiPolicyPresent) {
            const [step] = getFullRegistrationStepsConfig({
                steps: [FULL_REGISTRATION_STEP.MIGRATION_CARD],
                citizenship: values.citizenship,
                migrationStatus: values.migrationStatus,
                locatedOutsideRussia: values.locatedOutsideRussia,
                vhiPolicyTypeDict: {},
                vhiPolicyFormatDict: {},
            });

            const fields = getFullRegistrationFields({
                step,
                values: {
                    ...values,
                    vhiPolicyPresent: true,
                },
                onlyFiles: true,
            });

            fields.forEach(contractorFileType => {
                dispatch(getFileContractorScan({
                    contractorId: contractor.contractorId,
                    contractorFileType,
                    isPending: true,
                    thumbnail: true,
                    includingPending: true,
                }));
            });
        }
    };

    const addContractorFiles = ({actualData}) => {
        addedToFormFileTypes.forEach(contractorFileType => {
            const formData = new FormData();

            const [file] = values[contractorFileType];

            formData.append("file", file);

            if (
                [
                    CONTRACTOR_FILE_TYPES.INN,
                ].includes(contractorFileType)
            ) {
                const contractorFileType = getFullRegistrationInnFileType(file.type);

                dispatch(addFileContractorAdditionalScan({
                    contractorId: contractor.contractorId,
                    contractorFileType,
                    file: formData,
                    actualData,
                    onSuccess: () => {
                        increaseCounter();

                        dispatch(getFileContractorAdditionalScan({
                            contractorId: contractor.contractorId,
                            contractorFileType: CONTRACTOR_FILE_TYPES.INN,
                            thumbnail: true,
                        }));
                    },
                    onError: () => {
                        increaseCounter();
                    },
                }));

                return;
            }

            dispatch(addContractorFileScanFileToFileStore({
                contractorId: contractor.contractorId,
                contractorFileType,
                file: formData,
                actualData,
                isGetAfter: false,
                onSuccess: () => {
                    increaseCounter();

                    dispatch(getFileContractorScan({
                        contractorId: contractor.contractorId,
                        contractorFileType,
                        isPending: true,
                        thumbnail: true,
                        includingPending: true,
                    }));
                },
                onError: () => {
                    increaseCounter();
                },
            }));
        });
    };

    const saveWithoutCreatingRequest = () => {
        setProgress(true);

        if (contractor.inn !== values.inn) {
            checkInn({
                onSuccess: () => {
                    updateContractorInfo({actualData: true});
                },
            });

            return;
        }

        updateContractorInfo({actualData: true});
    };

    const onChange = (event, {name, value, checked}) => {
        if (name === "citizenship") {
            setValues({
                ...values,
                citizenship: value,
                migrationStatus: null,
            });

            return;
        }

        const _value = value instanceof Date ? convertUtcToLocal(value) : value;

        setFieldValue(name, _value || checked);
    };

    return {
        handleSubmit,
        values,
        errors,
        onChange,
        saveWithoutCreatingRequest,
        progress,
        isVisibleValidationError,
        scans,
        thumbnailScans,
        onSubmitWithoutInn,
        hideSubmitWithoutInn,
        filesErrors,
        setFilesErrors,
        touched,
    };
};