import {
    BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST,
    BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST,
    BFF_ADVERTISEMENT_CLOSE_REQUEST,
    BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST,
    BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST,
    BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST,
    BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST,
    BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST,
    BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE,
    BFF_GET_ADVERTISEMENT_PAGE_REQUEST,
    BFF_GET_ADVERTISEMENT_REQUEST,
    BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST,
    BFF_UPDATE_ADVERTISEMENT_REQUEST,
} from "./actions";

export function addAdvertisementPublish(payload) {
    return {
        type: BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST,
        payload,
    };
}

export function addAdvertisementDraft(payload) {
    return {
        type: BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST,
        payload,
    };
}

export function getAdvertisementPage(payload) {
    return {
        type: BFF_GET_ADVERTISEMENT_PAGE_REQUEST,
        payload,
    };
}

export function updateAdvertisement(payload) {
    return {
        type: BFF_UPDATE_ADVERTISEMENT_REQUEST,
        payload,
    };
}

export function closeAdvertisement(payload) {
    return {
        type: BFF_ADVERTISEMENT_CLOSE_REQUEST,
        payload,
    };
}

export function importAdvertisementList(payload) {
    return {
        type: BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST,
        payload,
    };
}

export function bffClearFieldsAdvertisementStore(payload) {
    return {
        type: BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE,
        payload,
    };
}

export function getAdvertisement(payload) {
    return {
        type: BFF_GET_ADVERTISEMENT_REQUEST,
        payload,
    };
}


export function getAdvertisementPublishMultipleCheck(payload) {
    return {
        type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST,
        payload,
    };
}

export function getAdvertisementPublishMultiple(payload) {
    return {
        type: BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST,
        payload,
    };
}

export function getAdvertisementViewPage(payload) {
    return {
        type: BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST,
        payload,
    };
}

export function getAdvertisementContractorsCount(payload) {
    return {
        type: BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST,
        payload,
    };
}

export function getAdvertisementFunnelPage(payload) {
    return {
        type: BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST,
        payload,
    };
}

export function advertisementOrderInvite(payload) {
    return {
        type: BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST,
        payload,
    };
}