import {
    GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
    GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
    GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS,
    ORDER_CONTRACTOR_ADD_ERROR,
    ORDER_CONTRACTOR_ADD_REQUEST,
    ORDER_CONTRACTOR_ADD_SUCCESS,
    ORDER_CONTRACTOR_APPROVE_ERROR,
    ORDER_CONTRACTOR_APPROVE_REQUEST,
    ORDER_CONTRACTOR_APPROVE_SUCCESS,
    ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
    ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST,
    ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS,
    ORDER_CONTRACTOR_DECLINE_ERROR,
    ORDER_CONTRACTOR_DECLINE_REQUEST,
    ORDER_CONTRACTOR_DECLINE_SUCCESS,
    ORDER_CONTRACTOR_INIT_PAYMENT_ERROR,
    ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST,
    ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS,
    ORDER_CONTRACTOR_REJECT_ERROR,
    ORDER_CONTRACTOR_REJECT_REQUEST,
    ORDER_CONTRACTOR_REJECT_SUCCESS,
    ORDER_WORK_REPORT_LIST_ERROR,
    ORDER_WORK_REPORT_LIST_REQUEST,
    ORDER_WORK_REPORT_LIST_SUCCESS,
} from "./actions";

const initialState = {
    progressAction: false,
    error: null,
    progressCard: false,
    workReportPageData: {},
    workReportList: [],
    workReportTotalCount: 0,
    workReportProgress: false,
    processDuplicatedPayments: false,
    duplicatedPaymentsInfo: {
        paymentsNumberDuplicated: [],
        sumOfMoney: 0,
    },
    contractorScore: {},
    contractorScoreProgress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case ORDER_CONTRACTOR_REJECT_REQUEST:
        case ORDER_CONTRACTOR_APPROVE_REQUEST:
        case ORDER_CONTRACTOR_DECLINE_REQUEST:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST:
        case ORDER_CONTRACTOR_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ORDER_CONTRACTOR_REJECT_SUCCESS:
        case ORDER_CONTRACTOR_APPROVE_SUCCESS:
        case ORDER_CONTRACTOR_DECLINE_SUCCESS:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS:
        case ORDER_CONTRACTOR_ADD_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case ORDER_CONTRACTOR_REJECT_ERROR:
        case ORDER_CONTRACTOR_APPROVE_ERROR:
        case ORDER_CONTRACTOR_DECLINE_ERROR:
        case ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR:
        case ORDER_CONTRACTOR_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case ORDER_WORK_REPORT_LIST_REQUEST:
            return {
                ...state,
                workReportPageData: payload,
                workReportProgress: true,
            };
        case ORDER_WORK_REPORT_LIST_SUCCESS:
            return {
                ...state,
                workReportTotalCount: payload.totalCount,
                workReportList: payload.reports,
                workReportProgress: false,
            };
        case ORDER_WORK_REPORT_LIST_ERROR:
            return {
                ...state,
                error: payload,
                workReportProgress: false,
            };
        case ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST:
            return {
                ...state,
                processDuplicatedPayments: true,
            };
        case ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS:
            const {
                paymentsNumberDuplicated,
                warnings,
                sumOfMoney,
                errors,
            } = payload;

            return {
                ...state,
                processDuplicatedPayments: false,
                duplicatedPaymentsInfo: {
                    warnings,
                    paymentsNumberDuplicated,
                    errors,
                    sumOfMoney,
                },
            };
        case ORDER_CONTRACTOR_INIT_PAYMENT_ERROR:
            return {
                ...state,
                processDuplicatedPayments: false,
            };
        case GET_SCORE_ACTUAL_CONTRACTOR_REQUEST:
            return {
                ...state,
                contractorScoreProgress: true,
            };
        case GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS:
            return {
                ...state,
                contractorScore: payload,
                contractorScoreProgress: false,
            };
        case GET_SCORE_ACTUAL_CONTRACTOR_ERROR:
            return {
                ...state,
                error: payload,
                contractorScoreProgress: false,
            };
        default:
            return state;
    }
};