export const BFF_ADD_EMAIL_NOTIFICATION_REQUEST = "BFF_ADD_EMAIL_NOTIFICATION_REQUEST";
export const BFF_ADD_EMAIL_NOTIFICATION_SUCCESS = "BFF_ADD_EMAIL_NOTIFICATION_SUCCESS";
export const BFF_ADD_EMAIL_NOTIFICATION_ERROR = "BFF_ADD_EMAIL_NOTIFICATION_ERROR";

export const BFF_DELETE_EMAIL_NOTIFICATION_REQUEST = "BFF_DELETE_EMAIL_NOTIFICATION_REQUEST";
export const BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS = "BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS";
export const BFF_DELETE_EMAIL_NOTIFICATION_ERROR = "BFF_DELETE_EMAIL_NOTIFICATION_ERROR";

export const BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST = "BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST";
export const BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS = "BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS";
export const BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR = "BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR";

export const BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST = "BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST";
export const BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS = "BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS";
export const BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR = "BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR";

export const BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST = "BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST";
export const BFF_GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS = "BFF_GET_EMAIL_NOTIFICATION_BY_ID_SUCCESS";
export const BFF_GET_EMAIL_NOTIFICATION_BY_ID_ERROR = "BFF_GET_EMAIL_NOTIFICATION_BY_ID_ERROR";
