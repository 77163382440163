import {useMemo} from "react";

import {FILTER} from "../../../../../components/ActualComponents/Filter";

import {useKedoDocumentCodes} from "../../../../../components/KedoDocumentCodes/hooks/useKedoDocumentCodes";
import {useKedoDocumentRoute} from "../../../../../components/KedoDocumentRouteDropdown/hooks/useKedoDocumentRoute";
import {useFilter} from "../../../../../hooks/useFilter";

import {getClientNamesFilter} from "../../../../../components/ActualComponents/Filter/utils/getClientsFilter";

export const useKedoDocumentTypesFilter = ({setPagination, pageSize, clientId}) => {
    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const kedoDocumentCodesProps = useKedoDocumentCodes();
    const kedoDocumentRouteProps = useKedoDocumentRoute({
        clientId,
    });

    const filters = useMemo(() => {
        return [
            !clientId && {
                row: [
                    getClientNamesFilter(),
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameFilter",
                        label: "Наименование",
                        placeholder: "Введите наименование",
                        size: "lg",
                    },
                ],
            },
            !clientId && {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "externalIdFilter",
                        label: "Внешний идентификатор",
                        placeholder: "Введите идентификатор",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...kedoDocumentCodesProps,
                        placeholder: "Не выбрано",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...kedoDocumentRouteProps,
                        placeholder: "Не выбрано",
                    },
                ],
            },
        ].filter(Boolean);
    }, [
        kedoDocumentCodesProps,
        kedoDocumentRouteProps,
    ]);

    return {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    };
};