import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import {COLOR} from "../../constants/color";
import {UNAVAILABLE_TEMPLATES_MESSAGE} from "./constants";

import {updateDocumentCustomTemplateStore} from "../../ducks/bff/documents-templates/actionCreators";
import {getDocumentsTemplatesController} from "../../ducks/bff/documents-templates/sagas";
import {
    clientDefaultFrameContractChangedSelector,
    customDocumentTemplatesDisabledForClientSelector,
    documentCustomTemplateReplacementsForUnavailableSelector,
} from "../../ducks/bff/documents-templates/selectors";
import {downloadDocument} from "../../ducks/documents";

import "./style.sass";

const UnavailableTemplates = (props) => {
    const {
        header,
        onSubmit,
        onClose,
        disabledSettingContentType,
        submitBtnContent = "Создать",
        progress,
    } = props;

    const dispatch = useDispatch();
    const replacementsForUnavailableList = useSelector(documentCustomTemplateReplacementsForUnavailableSelector);
    const customDocumentTemplatesDisabledForClient = useSelector(customDocumentTemplatesDisabledForClientSelector);
    const clientDefaultFrameContractChanged = useSelector(clientDefaultFrameContractChangedSelector);

    useEffect(() => {
        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                replacementsForUnavailableList: [],
                customDocumentTemplatesDisabledForClient: false,
                clientDefaultFrameContractChanged: false,
            }));
        };
    }, []);

    const onClick = (id) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `${getDocumentsTemplatesController()}/pdf/${id}`,
        }));
    };

    const getContent = () => {
        if (clientDefaultFrameContractChanged) {
            return UNAVAILABLE_TEMPLATES_MESSAGE.CLIENT_DEFAULT_FRAME_CONTRACT_CHANGED;
        }

        if (!customDocumentTemplatesDisabledForClient) {
            return UNAVAILABLE_TEMPLATES_MESSAGE.TEMPLATES_WAS_DELETED;
        }

        return UNAVAILABLE_TEMPLATES_MESSAGE.TEMPLATES_SETTINGS_WAS_DISABLED[disabledSettingContentType];
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            className="unavailable-templates"
            header={
                <NmTitle size="lg">
                    {header}
                </NmTitle>
            }
            classNameContent="flex-column"
            footer={
                <ApplyButtons
                    mobile="column"
                    onClose={onClose}
                    submitBtnContent={submitBtnContent}
                    submit={onSubmit}
                    disabled={progress}
                    cancelBtnContent="Отменить"
                />
            }
        >
            <Text level="3">
                {getContent()}
            </Text>
            <div className="mt-1">
                <ul style={{paddingLeft: 16}}>
                    {
                        replacementsForUnavailableList.map(item => {
                            return (
                                <li
                                    className="mt-1"
                                    onClick={() => onClick(item.id)}
                                >
                                    <Text
                                        style={{cursor: "pointer"}}
                                        color={COLOR.PASSIVE_100}
                                        level="2"
                                    >
                                        {item.name}
                                    </Text>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </NmModal>
    );
};

export default UnavailableTemplates;