import {createSelector} from "reselect";

import {getTotalPages} from "../../utils/mathHelper";

export const bffFinancesPaymentsSelector = state => state.financePayments;
export const bffFinancesPaymentsListSelector = createSelector(bffFinancesPaymentsSelector, ({list}) => list);
export const bffFinancesPaymentsTotalCountSelector = createSelector(bffFinancesPaymentsSelector, ({totalCount}) => totalCount);
export const bffFinancesPaymentsTotalPagesSelector = createSelector(bffFinancesPaymentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffFinancesPaymentsProgressSelector = createSelector(bffFinancesPaymentsSelector, ({progress}) => progress);
export const bffFinancesPaymentsProgressActionSelector = createSelector(bffFinancesPaymentsSelector, ({progressAction}) => progressAction);
export const bffFinancesPaymentsSpentLimitListSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitList}) => spentLimitList);
export const bffFinancesPaymentsSpentLimitTotalCountSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitTotalCount}) => spentLimitTotalCount);
export const bffFinancesPaymentsSpentLimitTotalPagesSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitTotalCount, spentLimitPageData: {pageSize = 0}}) => getTotalPages(spentLimitTotalCount, pageSize));
export const bffFinancesPaymentsSpentLimitProgressSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitProgress}) => spentLimitProgress);
export const bffFinancesPaymentsSpentLimitFinanceSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitFinance}) => spentLimitFinance);
export const bffFinancesPaymentsSpentLimitFinanceProgressSelector = createSelector(bffFinancesPaymentsSelector, ({spentLimitFinanceProgress}) => spentLimitFinanceProgress);
export const bffFinancesPaymentsTotalAmountSelector = createSelector(bffFinancesPaymentsSelector, ({paymentsTotalAmount}) => paymentsTotalAmount);
export const bffFinancesPaymentsTotalAmountProgressSelector = createSelector(bffFinancesPaymentsSelector, ({paymentsTotalAmountProgress}) => paymentsTotalAmountProgress);
export const bffFinancesPaymentsRegistryOrdersAmountProgressSelector = createSelector(bffFinancesPaymentsSelector, ({registryOrdersAmountProgress}) => registryOrdersAmountProgress);
export const bffFinancesPaymentsRegistryOrdersTotalCountSelector = createSelector(bffFinancesPaymentsSelector, ({registryOrdersTotalCount}) => registryOrdersTotalCount);
export const bffFinancesPaymentsRegistryOrdersTotalPagesSelector = createSelector(bffFinancesPaymentsSelector, ({registryOrdersTotalCount, registryOrdersPageData: {pageSize = 0}}) =>
    getTotalPages(registryOrdersTotalCount, pageSize),
);
export const bffFinancesPaymentsRegistryOrdersSelector = createSelector(bffFinancesPaymentsSelector, ({registryOrders}) => registryOrders);
export const bffFinancesPaymentsRegistryOrdersProgressSelector = createSelector(bffFinancesPaymentsSelector, ({progressRegistryOrders}) => progressRegistryOrders);

export const bffFinancesPaymentsRegistryOrdersOrdersAmountSelector = createSelector(bffFinancesPaymentsSelector, ({registryOrdersAmount}) => registryOrdersAmount);
export const financeLogTotalPagesSelector = createSelector(bffFinancesPaymentsSelector, ({
    transactionLogTotalCount,
    transactionLogPageData: {pageSize},
}) => getTotalPages(transactionLogTotalCount, pageSize));
export const financeLogTotalCountSelector = createSelector(bffFinancesPaymentsSelector, ({transactionLogTotalCount}) => transactionLogTotalCount);
export const financeLogListSelector = createSelector(bffFinancesPaymentsSelector, ({transactionLog}) => transactionLog);
export const financeLogListProgressSelector = createSelector(bffFinancesPaymentsSelector, ({transactionLogProgress}) => transactionLogProgress);
export const getOrderWorkReportLogsSelector = createSelector(bffFinancesPaymentsSelector, ({orderWorkReportLogs}) => orderWorkReportLogs);
export const getOrderWorkReportLogsTotalPagesSelector = createSelector(bffFinancesPaymentsSelector, ({totalCountLogs, pageDataReportLog: {pageSize = 0}}) => getTotalPages(totalCountLogs, pageSize));
export const getTotalCountLogSelector = createSelector(bffFinancesPaymentsSelector, ({totalCountLogs}) => totalCountLogs);
export const getOrderWorkReportLogsProgressSelector = createSelector(bffFinancesPaymentsSelector, ({orderWorkReportLogsProgress}) => orderWorkReportLogsProgress);
export const bffFinancesPaymentsBlockedContractorsIdsSelector = createSelector(
    bffFinancesPaymentsSelector,
    ({blockedContractors}) => blockedContractors.contractors
        .filter(({blocked}) => blocked)
        .map(({contractorId}) => contractorId),
);