import {useState} from "react";
import {useDispatch} from "react-redux";

import {UPLOAD_TYPE} from "../../../../../constants/financeExport";

import {
    addTaskOrderInvitationsRegistryExport,
    checkValidResidentRegistryInvitations,
    deleteContractorsFromRegistryInvitations,
    importRegistryInvitationsContractors,
    runRegistryInvitations,
    runRegistryInvitationsItem,
} from "../../../../../ducks/bff/orders/order-invitations-registry/card/actionCreators";

function useRegistryInvitationsCardAction({fetchList, clientId, registryInvitationId, clearSelectedRows, fetchTasks}) {
    const [editItem, setEditItem] = useState({});
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isInviteViaEmailOpen, setIsInviteViaEmailOpen] = useState(false);
    const [isOpenInvitationContractorEditForm, setIsOpenInvitationContractorEditForm] = useState(false);
    const [isOpenWarningConfirm, setIsOpenWarningConfirm] = useState(false);
    const [selectedSerialNumber, setSelectedSerialNumber] = useState("");

    const dispatch = useDispatch();

    const onClickRunRegistry = (serialNumber = "") => {
        const requestData = {
            clientId,
            registryInvitationId,
            serialNumber: serialNumber ? serialNumber : undefined,
            getResult: (result) => {
                if (result) {
                    runRegistry(serialNumber);

                    return;
                }

                setSelectedSerialNumber(serialNumber);
                setIsOpenWarningConfirm(true);
            },
        };

        dispatch(checkValidResidentRegistryInvitations(requestData));
    };

    const runRegistry = (serialNumber = "") => {
        if (serialNumber) {
            dispatch(runRegistryInvitationsItem({
                clientId,
                registryInvitationId,
                serialNumber,
                onSuccess: () => {
                    fetchList();
                    closeConfirm();
                },
            }));

            return;
        }

        dispatch(runRegistryInvitations({
            clientId,
            registryInvitationId,
            onSuccess: () => {
                closeConfirm();
            },
        }));
    };

    const onClickRunRegistryItem = (serialNumber) => {
        dispatch(runRegistryInvitationsItem({
            clientId,
            registryInvitationId,
            serialNumber,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    const onClickEditRegistryItem = (item) => {
        setEditItem({
            ...item,
            phoneNumber: item.contractorPhone,
            orderNum: item.baseModel.orderNum,
        });
        setIsOpenInvitationContractorEditForm(true);
    };

    const onCloseEditForm = () => {
        setEditItem({});
        setIsOpenInvitationContractorEditForm(false);
    };

    const onClickExportRegistry = () => {
        dispatch(addTaskOrderInvitationsRegistryExport({
            fromClientId: clientId,
            registryId: registryInvitationId,
            uploadType: UPLOAD_TYPE.REGISTRY_INVITATION_EXPORT.value,
        }));
    };

    const deleteRegistryItems = (requestData) => {
        dispatch(deleteContractorsFromRegistryInvitations({
            requestData,
            onSuccess: () => {
                clearSelectedRows();
                fetchList();
            },
        }));
    };

    const onSubmitImportRegistryInvitationsContractors = ({file}) => {
        const formData = new FormData();
        formData.append("file", file);

        dispatch(importRegistryInvitationsContractors({
            clientId,
            registryInvitationId,
            formData,
            onSuccess: () => {
                fetchTasks();
                setIsImportModalOpen(false);
            },
        }));
    };

    const onConfirm = () => {
        runRegistry(selectedSerialNumber);
    };

    const closeConfirm = () => {
        setIsOpenWarningConfirm(false);
        setSelectedSerialNumber("");
    };


    return {
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        setIsImportModalOpen,
        isImportModalOpen,
        onClickRunRegistry,
        onClickExportRegistry,
        onSubmitImportRegistryInvitationsContractors,
        onClickRunRegistryItem,
        deleteRegistryItems,
        isOpenInvitationContractorEditForm,
        setIsOpenInvitationContractorEditForm,
        editItem,
        onCloseEditForm,
        onClickEditRegistryItem,
        isOpenWarningConfirm,
        setIsOpenWarningConfirm,
        onConfirm,
        closeConfirm,
    };
}

export default useRegistryInvitationsCardAction;