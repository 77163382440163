import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

import {EDM_EXPORT_STATUS} from "../../../../../constants/edmExport";

export const statementsSelector = state => state.bff.clientsEdoStatements;
export const edoCountsByStaffSelector = createSelector(statementsSelector, ({edoCounts}) => edoCounts);
export const statementsListSelector = createSelector(statementsSelector, ({list}) => list);
export const statementsTotalPagesSelector = createSelector(statementsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const statementsTotalCountSelector = createSelector(statementsSelector, ({totalCount}) => totalCount);
export const statementsInfoCountSelector = createSelector(statementsSelector, ({statementsInfoCount}) => statementsInfoCount);
export const statementsTypesSelector = createSelector(statementsSelector, ({types}) => types.map(({
    templateType,
    id,
    value,
}) => ({
    value: id,
    templateType,
    key: id,
    text: value,
    id,
})));
export const edoExportListSelector = createSelector(statementsSelector, ({exportList}) => exportList);
export const edoExportListTotalPagesSelector = createSelector(statementsSelector, ({exportListTotalCount, exportListPageData: {pageSize = 0}}) => getTotalPages(exportListTotalCount, pageSize));
export const edoExportListTotalCountSelector = createSelector(statementsSelector, ({exportListTotalCount}) => exportListTotalCount);
export const edoExportListProgressSelector = createSelector(statementsSelector, ({exportListProgress}) => exportListProgress);
export const edoExportStatusListSelector = createSelector(statementsSelector, ({exportStatuses}) => exportStatuses);
export const edoExportDataTaskIdsSelector = createSelector(statementsSelector, ({exportList}) => (exportList
    .filter(({state}) => state === EDM_EXPORT_STATUS.IN_PROGRESS))
    .map(({exportId}) => (exportId))
    .filter(Boolean));