import React, {useContext} from "react";
import {useMedia} from "react-media";
import {useSelector} from "react-redux";

import NmForm from "../../../../components/ActualComponents/NmForm";
import {OrderEditContext} from "../context-provider";
import OrderEditActTemplate from "./act-template";
import OrderEditAdditional from "./additional";
import OrderEditGeneral from "./general";
import OrderEditFormPreConditions from "./pre-conditions";
import OrderEditPublish from "./publish";

import {getCurrentStep} from "../utils/getCurrentStep";

import {ORDER_CREATE_STEPS} from "../constants";

import {clientSpecialitiesOptionsSelector} from "../../../../ducks/bff/clients/info/selectors";
import {orderCreationDataSelector} from "../../../../ducks/bff/orders/orders-registry/selectors";

import "./style.sass";

const OrderEditForm = (props) => {
    const {
        objectLatitude,
        objectLongitude,
        scale,
        clientId,
        formData,
        formError,
        activeItems,
        isPromptOn,
        valueObjectFilter,
        isDuplicateOrder,
        isDisabled,
        handleChange,
        handleFocus,
        onSearchObject,
        managerFirstName,
        actions,
        nmContractDateConclusion,
        editOrderId,
        dropDocument,
        isSecondType,
        onFormFieldBlur,
        calculatorData,
        documentFile,
        documentFileUrl,
        driverError,
        addSpecialityToForm,
        offeredSpecialityName,
        specialityExistsError,
        isDisabledOfferedSpeciality,
        saveSpecialityByEnter,
        isDisabledSpeciality,
        creatingMode,
        isForemans,
        preloadedData,
        responsibleOptions,
        onSearchProject,
        metroInfo,
    } = props;
    const {
        tabs,
    } = useContext(OrderEditContext);

    const isMobile = useMedia({query: {maxWidth: 1199}});

    const specialitiesOptions = useSelector(clientSpecialitiesOptionsSelector);
    const order = useSelector(orderCreationDataSelector);
    const currentStep = getCurrentStep({activeItems, tabs});

    const getPeriodMinDate = () => {
        return nmContractDateConclusion ? new Date(nmContractDateConclusion) : null;
    };

    const getPreConditionsStep = () => {
        const {text: managerName} = responsibleOptions.find(item => (item.key === formData.orderManagerId)) || {};

        return (
            <OrderEditFormPreConditions
                isDisabled={isDisabled}
                responsibleOptions={responsibleOptions}
                isDuplicateOrder={isDuplicateOrder}
                activeItems={activeItems}
                creatingMode={creatingMode}
                handleChange={handleChange}
                formError={formError}
                formData={formData}
                managerName={managerName}
                handleFocus={handleFocus}
                onSearchObject={onSearchObject}
                preloadedData={preloadedData}
                valueObjectFilter={valueObjectFilter}
                isPromptOn={isPromptOn}
            />
        );
    };

    const getFirstStep = () => {
        const minDate = getPeriodMinDate();

        return (
            <OrderEditGeneral
                objectLatitude={objectLatitude}
                objectLongitude={objectLongitude}
                isDisabledSpeciality={isDisabledSpeciality}
                specialitiesOptions={specialitiesOptions}
                addSpecialityToForm={addSpecialityToForm}
                saveSpecialityByEnter={saveSpecialityByEnter}
                offeredSpecialityName={offeredSpecialityName}
                formData={formData}
                formError={formError}
                isPromptOn={isPromptOn}
                valueObjectFilter={valueObjectFilter}
                handleChange={handleChange}
                handleFocus={handleFocus}
                periodMinDate={minDate}
                isDisabled={isDisabled}
                dropDocument={dropDocument}
                isSecondType={isSecondType}
                onFormFieldBlur={onFormFieldBlur}
                calculatorData={calculatorData}
                documentFile={documentFile}
                documentFileUrl={documentFileUrl}
                specialityExistsError={specialityExistsError}
                isDisabledOfferedSpeciality={isDisabledOfferedSpeciality}
                creatingMode={creatingMode}
                isForemans={isForemans}
                order={order}
                scale={scale}
                onSearchProject={onSearchProject}
                isMobile={isMobile}
                isDuplicateOrder={isDuplicateOrder}
            />
        );
    };

    const getSecondStep = () => {
        return (
            <OrderEditAdditional
                formError={formError}
                formData={formData}
                isPromptOn={isPromptOn}
                isDuplicateOrder={isDuplicateOrder}
                isDisabled={isDisabled}
                handleChange={handleChange}
                driverError={driverError}
                order={order}
                preloadedData={preloadedData}
                isMobile={isMobile}
                creatingMode={creatingMode}
            />
        );
    };

    const getThirdStep = () => {
        return (
            <OrderEditPublish
                isDisabledSpeciality={isDisabledSpeciality}
                specialitiesOptions={specialitiesOptions}
                formData={formData}
                managerFirstName={managerFirstName}
                editOrderId={editOrderId}
                isDuplicateOrder={isDuplicateOrder}
                calculatorData={calculatorData}
                documentFileUrl={documentFileUrl}
                isSecondType={isSecondType}
                offeredSpecialityName={offeredSpecialityName}
                scale={scale}
                metroInfo={metroInfo}
            />
        );
    };

    const stopDefaultEvent = (event) => {
        // При фокусе на каком-либо элементе и нажатии на энтер странное поведение - открывается диалог выбора файла...
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    };

    const getActTemplate = () => {
        return (
            <OrderEditActTemplate
                formData={formData}
                formError={formError}
                editActTemplateData={formData}
                clientId={clientId}
            />
        );
    };

    const getForm = () => {
        return [
            {
                isVisible: currentStep === ORDER_CREATE_STEPS.PRE_CONDITIONS.VALUE,
                render: getPreConditionsStep,
            },
            {
                isVisible: currentStep === ORDER_CREATE_STEPS.FIRST_STEP.VALUE,
                render: getFirstStep,
            },
            {
                isVisible: currentStep === ORDER_CREATE_STEPS.SECOND_STEP.VALUE,
                render: getSecondStep,
            },
            {
                isVisible: currentStep === ORDER_CREATE_STEPS.PUBLISH_STEP.VALUE,
                render: getThirdStep,
            },
            {
                isVisible: currentStep === ORDER_CREATE_STEPS.ACT_TEMPLATE.VALUE,
                render: getActTemplate,
            },
        ].filter(({isVisible}) => isVisible).map(item => item.render());
    };

    return (
        <NmForm
            onKeyDown={stopDefaultEvent}
            className="order-edit__form"
        >
            <div className="order-edit__main-content">
                {getForm()}
            </div>
            {actions}
        </NmForm>
    );
};

export default OrderEditForm;