import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/patents/deposit";

/*TYPES*/
const GET_LIST_DEPOSIT_PATENTS_REQUEST = "GET_LIST_DEPOSIT_PATENTS_REQUEST";
const GET_LIST_DEPOSIT_PATENTS_SUCCESS = "GET_LIST_DEPOSIT_PATENTS_SUCCESS";
const GET_LIST_DEPOSIT_PATENTS_ERROR = "GET_LIST_DEPOSIT_PATENTS_ERROR";

const GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_REQUEST = "GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_REQUEST";
const GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_SUCCESS = "GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_SUCCESS";
const GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_ERROR = "GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_ERROR";

/*INITIAL STATE*/
const initial = {
    totalCount: 0,
    accountNumberDeposit: "",
    pageData: {},
    pageSize: 0,
};

/*REDUCER*/
export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_LIST_DEPOSIT_PATENTS_REQUEST:
            return {
                ...state,
                progress: true,
                pageSize: payload.pageSize,
            };
        case GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_SUCCESS:
            return {
                ...state,
                accountNumberDeposit: payload,
            };
        case GET_LIST_DEPOSIT_PATENTS_SUCCESS:
            return {
                ...state,
                pageData: payload,
                totalCount: payload.totalCount,
            };
        case GET_LIST_DEPOSIT_PATENTS_ERROR:
        case GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

/*ACTION CREATORS*/
export const getListDepositPatents = (payload) => ({
    type: GET_LIST_DEPOSIT_PATENTS_REQUEST,
    payload,
});

export const getDepositAccount = (payload) => ({
    type: GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_REQUEST,
    payload,
});

/*SELECTORS*/
export const patentsDepositSelector = ({patentsDeposit}) => patentsDeposit;
export const patentsDepositPageDataSelector = createSelector(patentsDepositSelector, ({pageData}) => pageData);
export const patentsDepositTotalCountSelector = createSelector(patentsDepositSelector, ({totalCount}) => totalCount);
export const patentsDepositTotalPagesSelector = createSelector(patentsDepositSelector, ({totalCount, pageSize}) => getTotalPages(totalCount, pageSize));

/*SAGA*/
// /api/patents/deposit/getDepositById
export const getDepositAccountSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getDepositAccountByClientId`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_SUCCESS,
            payload: result?.account,
        });
    } catch (error) {
        yield put({
            type: GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_ERROR,
            payload: error,
        });
    }
};

// /api/patents/deposit/getRichPage
export const getListDepositPatentsSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_LIST_DEPOSIT_PATENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_LIST_DEPOSIT_PATENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_LIST_DEPOSIT_PATENTS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_LIST_DEPOSIT_PATENTS_REQUEST, getListDepositPatentsSaga),
        takeEvery(GET_ACCOUNT_NUMBER_DEPOSIT_PATENTS_REQUEST, getDepositAccountSaga),
    ]);
}