import {isNullOrWhitespace} from "./stringHelper";

import {FILE_TYPE} from "../constants/file";

export const downloadFile = (link, fileName) => {
    const tempLink = document.createElement("a");

    tempLink.setAttribute("href", link);
    tempLink.setAttribute("target", "_blank");
    fileName && tempLink.setAttribute("download", fileName);

    document.body.appendChild(tempLink);

    tempLink.click();
    tempLink.remove();
};

export const getFileNameWithoutExtension = (fileName) => {
    if (!fileName) {
        return;
    }

    const fullName = fileName.split(".");

    fullName.pop();

    return fullName.join(".");
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return new File([blob], "avatar.png", {type: blob.type});
};

export const getDocumentIconName = (fileExtension) => {
    switch (fileExtension) {
        case "odt":
        case "doc":
        case "docx":
            return "large file word outline";
        case "pdf":
            return "large file pdf outline";
        case "tif":
        case "tiff":
            return "large image outline";
        default:
            return "large file";
    }
};

export const getFileExtension = (fileName) => {
    if (isNullOrWhitespace(fileName)) {
        return "";
    }

    return fileName.split(".").pop().toLowerCase();
};

export const isFilePdf = (fileType) => {
    if (!fileType) {
        return false;
    }

    return fileType.split("/")[1] === FILE_TYPE.PDF;
};