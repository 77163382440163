import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ORDER_ANALYTICS_ERROR,
    ORDER_ANALYTICS_PAGE_ERROR,
    ORDER_ANALYTICS_PAGE_REQUEST,
    ORDER_ANALYTICS_PAGE_SUCCESS,
    ORDER_ANALYTICS_REQUEST,
    ORDER_ANALYTICS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/reports/order-analytics",
        client: "/client-adm/reports/order-analytics",
    });
};

const getOrderAnalyticsPageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${getController()}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_ANALYTICS_PAGE_ERROR,
                payload: result,
            });
        }

        yield put({
            type: ORDER_ANALYTICS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_ANALYTICS_PAGE_ERROR,
            payload: error,
        });
    }
};

const getOrderAnalyticsSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${getController()}/get`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_ANALYTICS_ERROR,
                payload: result,
            });
        }

        yield put({
            type: ORDER_ANALYTICS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_ANALYTICS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_ANALYTICS_REQUEST, getOrderAnalyticsSaga),
        takeEvery(ORDER_ANALYTICS_PAGE_REQUEST, getOrderAnalyticsPageSaga),
    ]);
}