import {dictionaryToOptions} from "../utils/objectHelper";

export const SOURCE_TYPE_OPTIONS = [
    {
        key: "ALL",
        text: "Все",
        value: "ALL",
    },
    {
        key: "UI",
        text: "По заказам (оплаты, созданные по стандартным заказам)",
        value: "UI",
    },
    {
        key: "REGISTRY",
        text: "Реестровые (оплаты, созданные по реестрам)",
        value: "REGISTRY",
    },
    {
        key: "API",
        text: "Оплаты через внешнее API",
        value: "API",
    },
];

export const PAYMENT_SOURCE_TYPE_DICT = {
    UI: "По заказу",
    REGISTRY: "Реестровая",
    API: "Через внешнее API",
};

export const ORDER_SOURCE_TYPE = {
    "UI": "UI",
    "REGISTRY": "REGISTRY",
    "API": "API",
};

export const SYSTEM_TRANSACTION_TYPE = {
    "ALL": "Все",
    "PAYMENT_TAX": "Уплата налогов",
    "PAYMENT_DEBT": "Уплата задолженности",
    "PAYMENT_PENALTY": "Уплата пени",
    "TRANSFER_CARD": "Вывод средств на карту",
    "TRANSFER_PHONE": "Вывод средств на телефон",
    "CROWD_TRANSFER_CARD": "Вывод средств по заданию",
};

export const CHECKED_ACTS_FILTER_OPTIONS = [
    {
        key: true,
        text: "Проверены",
        value: true,
    },
    {
        key: false,
        text: "Без проверки",
        value: false,
    },
];

export const ORDER_WORK_REPORT_TYPE = {
    SMZ: "SMZ", // НПД
    CIVIL: "CIVIL", // НДФЛ
    INDIVIDUAL: "INDIVIDUAL", // ИП
};

export const ORDER_WORK_REPORT_TYPE_TRANSLATE = {
    CIVIL: "НДФЛ",
    SMZ: "НПД",
    INDIVIDUAL: "ИП",
};

export const ORDER_AMOUNT_CALCULATION_METHOD = {
    NET: "NET",
    GROSS: "GROSS",
};

export const ORDER_AMOUNT_CALCULATION_METHOD_DICT = {
    [ORDER_AMOUNT_CALCULATION_METHOD.NET]: "NET (\"на руки\" исполнителю)",
    [ORDER_AMOUNT_CALCULATION_METHOD.GROSS]: "GROSS без комиссии платформы",
};

export const ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS = [
    {
        key: "ALL",
        text: "Все",
        value: "ALL",
    },
    {
        key: ORDER_WORK_REPORT_TYPE.SMZ,
        text: ORDER_WORK_REPORT_TYPE_TRANSLATE.SMZ,
        value: ORDER_WORK_REPORT_TYPE.SMZ,
    },
    {
        key: ORDER_WORK_REPORT_TYPE.CIVIL,
        text: ORDER_WORK_REPORT_TYPE_TRANSLATE.CIVIL,
        value: ORDER_WORK_REPORT_TYPE.CIVIL,
    },
    {
        key: ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
        text: ORDER_WORK_REPORT_TYPE_TRANSLATE.INDIVIDUAL,
        value: ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
    },
];

export const CANCELED_RECEIPT_PAYMENT_PURPOSE_TYPE = {
    CROWD_TO_BALANCE: "CROWD_TO_BALANCE",
};