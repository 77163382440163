export const BFF_NOTIFICATION_GET_PAGE_REQUEST = "BFF_NOTIFICATION_GET_PAGE_REQUEST";
export const BFF_NOTIFICATION_GET_PAGE_SUCCESS = "BFF_NOTIFICATION_GET_PAGE_SUCCESS";
export const BFF_NOTIFICATION_GET_PAGE_ERROR = "BFF_NOTIFICATION_GET_PAGE_ERROR";

export const BFF_NOTIFICATION_ADD_VIEW_REQUEST = "BFF_NOTIFICATION_ADD_VIEW_REQUEST";
export const BFF_NOTIFICATION_ADD_VIEW_SUCCESS = "BFF_NOTIFICATION_ADD_VIEW_SUCCESS";
export const BFF_NOTIFICATION_ADD_VIEW_ERROR = "BFF_NOTIFICATION_ADD_VIEW_ERROR";

export const BFF_NOTIFICATION_ADD_VIEWS_REQUEST = "BFF_NOTIFICATION_ADD_VIEWS_REQUEST";
export const BFF_NOTIFICATION_ADD_VIEWS_SUCCESS = "BFF_NOTIFICATION_ADD_VIEWS_SUCCESS";
export const BFF_NOTIFICATION_ADD_VIEWS_ERROR = "BFF_NOTIFICATION_ADD_VIEWS_ERROR";

export const BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST = "BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST";
export const BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS = "BFF_NOTIFICATION_GET_UNREAD_COUNT_SUCCESS";
export const BFF_NOTIFICATION_GET_UNREAD_COUNT_ERROR = "BFF_NOTIFICATION_GET_UNREAD_COUNT_ERROR";
