import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import FinanceLogPopup from "../../../containers/finance-log-popup";

import {FINANCE_LOG_TYPE} from "../../../constants/financeLogType";

import {
    getContractorBankIdentificationLogs,
    updateContractorBanksBalanceStore,
} from "../../../ducks/bff/contractor-сard/banks/actionCreators";
import {
    contractorBanksIdentificationLogsLogsProgressSelector,
    contractorCardLogsSelector,
} from "../../../ducks/bff/contractor-сard/banks/selectors";

export const ContractorBankSenderLogs = (props) => {
    const {
        onClose,
        contractorId,
        lastIdentificationRequest,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(contractorCardLogsSelector);
    const progress = useSelector(contractorBanksIdentificationLogsLogsProgressSelector);

    const fetchList = () => {
        dispatch(getContractorBankIdentificationLogs({
            contractorId,
            contractorBankIdentificationRequestId: lastIdentificationRequest?.contractorBankIdentificationRequestId,
        }));
    };

    useEffect(() => {
        fetchList();

        return () => {
            dispatch(updateContractorBanksBalanceStore({
                identificationLogs: [],
            }));
        };
    }, []);

    return (
        <FinanceLogPopup
            isRenderAllList={true}
            handleClose={onClose}
            logType={FINANCE_LOG_TYPE.SYSTEM_TRANSACTION_LOG}
            list={list}
            progress={progress}
        />
    );
};