import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter, {FILTER} from "../../../../../components/ActualComponents/Filter";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import OrderContractors from "../../../../../components/OrderContractors";

import {useBankIndicator} from "../../../../../components/BankIndicator/hooks/useBankIndicator";
import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {handleFormString} from "../../../../../utils/stringHelper";

import {
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
} from "../../../../../constants/links";
import {REG_EXP} from "../../../../../constants/regExp";

import {
    bffClearFieldsAdvertisementStore,
    getAdvertisementFunnelPage,
} from "../../../../../ducks/bff/advertisement/actionCreators";
import {
    advertisementFunnelsContractorIdsSelector,
    advertisementFunnelsCountMapSelector,
    advertisementFunnelsListSelector,
    advertisementFunnelsProgressSelector,
} from "../../../../../ducks/bff/advertisement/selectors";
import {getCitizenshipOptionsSelector} from "../../../../../ducks/contractor";

import {TAB_TYPE_BY_SUB_PAGE} from "../../../../../constants/link-params";

const AdvertisementContractorsOnOrders = (props) => {
    const {
        subpage,
        clientId,
        advertisementId,
    } = props.match.params;

    const dispatch = useDispatch();

    const list = useSelector(advertisementFunnelsListSelector);
    const contractorIds = useSelector(advertisementFunnelsContractorIdsSelector);
    const countMap = useSelector(advertisementFunnelsCountMapSelector);
    const progress = useSelector(advertisementFunnelsProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const totalCount = countMap[TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE];

    const totalPages = useMemo(() => {
        return getTotalPages(totalCount, pageSize);
    }, [
        countMap,
        subpage,
        pageSize,
    ]);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        onPaginationChange,
        mapFilterDto: (filters) => {
            return {
                addressFiasIdsFilter: isEmpty(filters.addressFiasIdsFilter) ? undefined : filters.addressFiasIdsFilter,
                specialityIdsFilter: isEmpty(filters.specialityIdsFilter) ? undefined : filters.specialityIdsFilter,
                fioFilter: handleFormString(filters.fioFilter),
                orderNumFilter: handleFormString(filters.orderNumFilter),
                orderNameFilter: handleFormString(filters.orderNameFilter),
                projectIdsFilter: isEmpty(filters.projectIdsFilter) ? undefined : filters.projectIdsFilter,
                objectIdsFilter: isEmpty(filters.objectIdsFilter) ? undefined : filters.objectIdsFilter,
                citizenshipFilter: isEmpty(filters.citizenshipFilter) ? undefined : filters.citizenshipFilter,
                ageFromFilter: filters.ageFromFilter ? +filters.ageFromFilter : undefined,
                ageToFilter: filters.ageToFilter ? +filters.ageToFilter : undefined,
            };
        },
    });

    useEffect(() => {
        fetchList();
    }, [
        subpage,
        pageNum,
        pageSize,
        filterData,
    ]);

    const citizenshipOptions = useSelector(getCitizenshipOptionsSelector);

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DADATA,
                        multiple: true,
                        fromBound: "region",
                        toBound: "settlement",
                        isAllSelectionShown: false,
                        isMountLoad: false,
                        name: "addressFiasIdsFilter",
                        label: "Регион или город",
                        placeholder: "Не выбран",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.SPECIALITIES,
                        multiple: true,
                        name: "specialityIdsFilter",
                        label: "Виды деятельности",
                        placeholder: "Показать все",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "fioFilter",
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО",
                        size: "lg",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "citizenshipFilter",
                        label: "Гражданство",
                        placeholder: "Выберите гражданство",
                        search: true,
                        multiple: true,
                        options: citizenshipOptions,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.RANGE_INPUT,
                        nameStart: "ageFromFilter",
                        nameEnd: "ageToFilter",
                        label: "Возраст",
                        placeholderStart: "От",
                        placeholderEnd: "До",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "orderNumFilter",
                        label: "Номер заказа",
                        placeholder: "Введите номер заказа",
                        pattern: REG_EXP.ONLY_NUMBERS,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "orderNameFilter",
                        label: "Наименование заказа",
                        placeholder: "Введите название заказа",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PROJECT,
                        clientId,
                        name: "projectIdsFilter",
                        placeholder: "Показать все",
                        isActiveStyle: false,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.OBJECT,
                        name: "objectIdsFilter",
                        clientId,
                        placeholder: "Показать все",
                        isActiveStyle: false,
                    },
                ],
            },
        ];
    }, []);

    const fetchList = () => {
        dispatch(getAdvertisementFunnelPage({
            isSearch,
            clientId,
            advertId: advertisementId,
            tabType: TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE,
            pageNum,
            pageSize,
            ...filterData,
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(bffClearFieldsAdvertisementStore({
                funnels: {
                    list: [],
                    pageData: {},
                    progress: false,
                    countMap: {},
                    totalCount: 0,
                },
            }));
        };
    }, []);

    const {
        getBankChecks,
        refreshBankChecks,
    } = useBankIndicator({
        clientId,
        contractorIds,
    });

    return (
        <NmPage
            noPadding
            totalPages={totalPages}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            header={
                <NmPageHeader text="Исполнители на заказах" />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            hardVisibleFilter={false}
            showHeaderBlock={false}
        >
            <OrderContractors
                isSearch={isSearch}
                isVisibleOrderContractorsActions={false}
                isVisibleDefaultContractorsSorts={false}
                isVisibleResponsesActions={false}
                isVisibleCreateAct={false}
                emptyTitle="Данные отсутствуют"
                emptyDescription={null}
                baseUrl={
                    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL
                        .replace(":clientId", clientId)
                        .replace(":advertisementId", advertisementId)
                }
                fetchList={fetchList}
                subpage={subpage}
                clientId={clientId}
                countMap={countMap}
                list={list}
                progress={progress}
                isShowOrderInfo
                refreshBankChecks={refreshBankChecks}
                getBankChecks={getBankChecks}
            />
        </NmPage>
    );
};

export default AdvertisementContractorsOnOrders;