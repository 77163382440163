export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST";
export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS";
export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR";

export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST";
export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS";
export const DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR = "DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR";

export const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST";
export const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS";
export const UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR = "UPDATE_RECEIVERS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR";

export const DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE = "DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE";