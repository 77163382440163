import {isEmpty} from "lodash";

import dateFormat from "../../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../../utils/stringFormat";

export const getRecruitmentCandidatesEditRequestData = (values, editData) => {
    return {
        ...editData,
        name: values.name,
        citizenship: values.citizenship ? values.citizenship : null,
        gender: values.gender ? values.gender : null,
        birthDate: values.birthDate ? dateFormat(values.birthDate, "yyyy-MM-dd") : null,
        phone: values.phone ? removePhoneMask(values.phone) : null,
        email: values.email ? values.email : null,
        specialityIds: !isEmpty(values.specialityIds) ? values.specialityIds : null,
        addressFiasIds: !isEmpty(values.addressFiasIds) ? values.addressFiasIds : null,
        statusId: values.statusId ? values.statusId : null,
    };
};

export const getRecruitmentCandidatesEditForm = (params) => {
    const {
        editData,
        isVisibleFunnelStage,
    } = params;

    const statusId = isVisibleFunnelStage ? "" : null;

    if (editData) {
        return {
            name: editData.name,
            citizenship: editData.citizenship || "",
            // Если кандидата создают на основе отклика и в отклике есть пол, то по умолчанию это поле предзаполнено
            // данным значением с возможностью изменения, иначе поле остается пустым.
            gender: editData.gender || "",
            birthDate: editData.birthDate ? new Date(editData.birthDate) : null,
            phone: editData.phone || null,
            email: editData.email || "",
            specialityIds: editData.specialityIds,
            addressFiasIds: !isEmpty(editData.addressFiasIds) ? editData.addressFiasIds : [],
            statusId,
        };
    }

    return {
        name: "",
        citizenship: "",
        gender: "",
        birthDate: null,
        phone: "",
        email: "",
        specialityIds: [],
        addressFiasIds: [],
        statusId,
    };
};