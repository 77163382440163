import React from "react";
import {isEmpty} from "lodash";

import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as EditIcon} from "../../../images/mode.svg";
import {ReactComponent as NoOnIcon} from "../../../images/no_on.svg";
import {ReactComponent as NoInfoIcon} from "../../../images/no-info.svg";
import {ReactComponent as NotOnIcon} from "../../../images/not_on.svg";
import {ReactComponent as YesOnIcon} from "../../../images/yes_on.svg";
import ContextMenu from "../ContextMenu";
import {MediaButtons} from "../MediaControls";
import Text from "../Text";

import {isNMUsers} from "../../../utils/access";
import bem from "../../../utils/bem";
import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";

import {COLOR} from "../../../constants/color";
import {COMPONENT} from "../MediaControls/constants";

import "./style.sass";

export default function NmMiniInfoCard(props) {
    const {
        children,
        options,
        title,
        titleLevel,
        onClickItem = () => {
        },
        className = "",
        onClickAdd,
        onClickEdit,
        onClickDelete,
        isVisibleDeleteButton = false,
        headerAdditionalContent,
        accessEdit,
        mediaControlsSize = "sm",
        additionalActions,
        mediaControls,
        mediaControlsButtons,
        mediaControlsRenderCount,
        editButtonAssets = {},
        deleteButtonAssets = {},
        mode,
        lastUpdate,
        lastUpdateText = "Последнее изменение",
        warningText,
        isHeaderDivider = true,
    } = props;

    const isMediaControls = Boolean(mediaControls || mediaControlsButtons || mediaControlsRenderCount);
    const [block, element] = bem("nm-mini-info-card", className);
    const _accessEdit = accessEdit === undefined ? isNMUsers() : accessEdit;

    function renderActions() {
        if (onClickAdd) {
            return <AddIcon
                className={element("action-icon")}
                color={COLOR.SECONDARY_45}
                onClick={onClickAdd}
            />;
        }

        if (onClickEdit && _accessEdit) {
            return <EditIcon
                className={element("action-icon")}
                color={COLOR.SECONDARY_45}
                onClick={onClickEdit}
            />;
        }

        if (!isEmpty(options)) {
            return (
                <ContextMenu
                    notBorder
                    clearWidth
                    horizontalIcon
                    transform={false}
                    disabled={false}
                    options={options}
                    onClickItem={onClickItem}
                />
            );
        }

        return null;
    }

    const getHeaderIcon = () => {
        switch (mode) {
            case "green":
                return (
                    <YesOnIcon
                        className={element("mode-icon")}
                        color={COLOR.PRIMARY_100}
                    />
                );
            case "yellow":
                return (
                    <NotOnIcon
                        className={element("mode-icon")}
                        color={COLOR.INERT_70}
                    />
                );
            case "red":
                return (
                    <NoOnIcon
                        className={element("mode-icon")}
                        color={COLOR.NEGATIVE_100}
                    />
                );
            case "gray":
                return (
                    <NoInfoIcon
                        className={element("mode-icon")}
                        color={COLOR.SECONDARY_10}
                    />
                );
            default:
                return null;
        }
    };

    const getMediaControls = () => {
        if (mediaControls) {
            return (
                <MediaButtons
                    inline={true}
                    config={mediaControls}
                />
            );
        }

        const {mediaControlsButtons = []} = props;

        const buttons = mediaControlsButtons?.map(item => {
            const {
                visible = true,
                ...buttonProps
            } = item;

            return {
                component: COMPONENT.BUTTON,
                props: {
                    ...buttonProps,
                },
                visible,
            };
        });

        const editButton = {
            component: COMPONENT.BUTTON,
            props: {
                onlyIcon: true,
                icon: <EditIcon
                    color={COLOR.SECONDARY_45}
                    className={element("action-icon")}
                />,
                color: "light-green",
                className: element("button"),
                onClick: onClickEdit,
            },
            visible: _accessEdit && Boolean(onClickEdit),
            asset: {
                ...editButtonAssets,
                mobile: {
                    children: "Редактировать",
                },
            },
        };

        const deleteButton = {
            component: COMPONENT.BUTTON,
            props: {
                onlyIcon: true,
                icon: <DeleteIcon
                    color={COLOR.SECONDARY_45}
                    className={element("action-icon")}
                />,
                color: "light-green",
                className: element("button"),
                onClick: onClickDelete,
            },
            visible: Boolean(onClickDelete) && isVisibleDeleteButton,
            asset: {
                ...deleteButtonAssets,
                mobile: {
                    children: "Удалить",
                },
            },
        };

        const config = {
            renderCount: props.mediaControlsRenderCount || {
                mobile: 1,
                tablet: 1,
                desktop: 1,
            },
            size: mediaControlsSize,
            buttons: [
                ...buttons,
                editButton,
                deleteButton,
            ],
        };

        return (
            <MediaButtons
                inline={true}
                notBorder={true}
                config={config}
            />
        );
    };

    return (
        <div className={block({mode})}>
            <div className={`${element("header", {border: isHeaderDivider})} flex flex-content-spaced flex-aligned-center pb-3 pb-xl-4`}>
                <div className="d-flex">
                    <div className="flex align-items-start align-items-md-center flex-column flex-md-row">
                        <div className="d-flex">
                            {getHeaderIcon()}
                            <Text
                                type="title"
                                level={titleLevel}
                            >
                                {title}
                            </Text>
                        </div>
                        {
                            lastUpdate &&
                                <Text
                                    className="ms-md-2"
                                    level="2"
                                    color={COLOR.PRIMARY_100}
                                >
                                    {lastUpdateText}
                                    {" "}
                                    {formatDate(convertUtcToLocal(lastUpdate), "dd.MM.yyyy HH:mm")}
                                </Text>
                        }
                        {
                            warningText &&
                                <Text
                                    className="ms-md-2"
                                    level="2"
                                    color={COLOR.NEGATIVE_100}
                                >
                                    {warningText}
                                </Text>
                        }
                        {headerAdditionalContent && headerAdditionalContent}
                    </div>
                </div>
                {_accessEdit && !isMediaControls && <div>
                    {renderActions()}
                </div>}
                {!!additionalActions && additionalActions}
                {
                    isMediaControls &&
                    getMediaControls()
                }
            </div>
            <div className={element("content", {paddingTop: isHeaderDivider})}>
                {children}
            </div>
        </div>
    );
}