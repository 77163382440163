import React from "react";
import {useSelector} from "react-redux";

import ImportFromFilePatternV2 from "../../../../../components/ActualComponents/ImportFromFilePatternV2";
import CityCodeDetect from "../../../../../components/CityCodeDetect";
import {bffRecruitmentVacancyProgressActionSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import {useRecruitmentVacancyImportForm} from "./hooks/useForm";

import {dadataFromCityToApartmentFilter} from "../../../../../utils/dadata";

const RecruitmentVacancyImportForm = (props) => {
    const {
        clientId,
        onClose,
    } = props;

    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);

    const {
        onSubmit,
    } = useRecruitmentVacancyImportForm({
        clientId,
        onClose,
    });

    return (
        <ImportFromFilePatternV2
            headerTitle="Загрузка списка вакансий"
            patternLink="/files/Шаблон_Вакансии.xlsx"
            onSubmit={onSubmit}
            onClose={onClose}
            progress={progressAction}
            classNameContent="flex-column"
            additionalContent={
                <div className="mt-6">
                    <CityCodeDetect
                        onClose={progressAction}
                        filter={dadataFromCityToApartmentFilter}
                        returnOnlyForm
                    />
                </div>
            }
        />
    );
};

export default RecruitmentVacancyImportForm;