import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../../images/add.svg";

import {useModal} from "../../../../../hooks/useModal";

import {getUserRestrictions} from "../../../../../utils/access";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_MANAGER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../../../constants/roles";

export const useRecruitmentCandidatesActions = () => {
    const currentUserRestrictions = getUserRestrictions();
    const isBannedManageCandidates = !isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_manageCandidatesClient,
        CLIENT_USER_RESTRICTIONS_VARIABLE.manageCandidatesAdmin,
    ]);
    
    const role = ls(USER_ROLE);

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const headerMediaControls = useMemo(() => {
        if (isBannedManageCandidates) {
            return null;
        }

        return   {
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        onClick: () => {
                            onOpenEdit({});
                        },
                        children: "Добавить кандидата",
                    },
                    visible: [
                        ADMIN,
                        NM_MANAGER,
                        CLIENT_ADMIN,
                        PROJECT_MANAGER,
                        OBJECT_MANAGER,
                        FOREMAN,
                        CLIENT_ACCOUNTANT,
                        HR_MANAGER,
                        RECRUITER,
                    ].includes(role),
                },
            ],
        };
    }, [
        currentUserRestrictions,
    ]);

    return {
        headerMediaControls,
        onCloseEdit,
        isOpenEdit,
    };
};