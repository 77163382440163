import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {useCounter} from "../../../../../../../../hooks/useCounter";
import {useContractorAdditionalDocuments} from "../../../../../hooks/useAdditionalDocuments";

import dateFormat from "../../../../../../../../utils/dateFormat";
import {convertStringToDate} from "../../../../../../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../../../../../../utils/toastHelper";

import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES,
    CONTRACTOR_ADDITIONAL_FILE_TYPES,
} from "../../../../../../../../constants/clientList";
import {FILE_ERROR} from "../../../../../../../../constants/messages";
import {maxSizeScanFile} from "../../../../../../../../constants/validationRules";

import {updateDataContractorAdditionalDocument} from "../../../../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    addFileContractorAdditionalScanV2,
    fileContractorOriginalScanObjSelector,
    getContractorAdditionalDocumentFile,
    updateFieldFilesStore,
} from "../../../../../../../../ducks/fileStore";

export const useDataMedicalBookInfoEditForm = ({data, contractorId, onClose}) => {
    const contractorScanObj = useSelector(fileContractorOriginalScanObjSelector);

    const {
        getAdditionalDocumentAggregation,
        getAdditionalOriginalScan,
        getPrevScan,
    } = useContractorAdditionalDocuments({contractorId});

    const getInitialFiles = () => {
        return {
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN),
            [CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN]: getPrevScan(CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN),
        };
    };

    const initialValues = {
        medicalBookNumber: data.medicalBookNumber || "",
        medicalBookIssuedDate: convertStringToDate(data.medicalBookIssuedDate) || null,
        files: getInitialFiles(),
    };

    const [editedFiles, setEditedFiles] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        [
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_SPREAD_SCAN,
            CONTRACTOR_ADDITIONAL_FILE_TYPES.MEDICAL_BOOK_QR_SCAN,
        ].forEach(contractorFileType => {
            getAdditionalOriginalScan(contractorFileType);
        });

        return () => {
            dispatch(updateFieldFilesStore({
                contractorOriginalScanObj: {},
            }));
        };
    }, []);

    useEffect(() => {
        setValues({
            ...values,
            files: getInitialFiles(),
        });
    }, [contractorScanObj]);

    const {
        isCounterFinish,
        increaseCounter,
    } = useCounter({countList: editedFiles});

    useEffect(() => {
        if (isCounterFinish) {
            updateData();
        }
    }, [isCounterFinish]);

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        if (!editedFiles.length) {
            updateData();

            return;
        }

        editedFiles.forEach(scanType => {
            const [file] = values.files[scanType];

            if (file) {
                uploadFile(file, scanType);
            }
        });
    };

    const updateData = () => {
        const requestData = {
            contractorId,
            data: {
                ...values,
                medicalBookIssuedDate: values.medicalBookIssuedDate
                    ? dateFormat(values.medicalBookIssuedDate, "yyyy-MM-dd")
                    : undefined,
            },
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_TYPES.MEDICAL_BOOK,
            onSuccess: () => {
                getAdditionalDocumentAggregation();
                onClose();
                toastSuccess("Данные успешно обновлены");
            },
        };

        dispatch(updateDataContractorAdditionalDocument(requestData));
    };

    const uploadFile = (file, contractorFileType) => {
        if (file.size > maxSizeScanFile) {
            toastError(FILE_ERROR.SCAN_SIZE);

            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        dispatch(addFileContractorAdditionalScanV2({
            contractorId,
            contractorFileType,
            file: formData,
            onSuccess: () => {
                increaseCounter();
                dispatch(getContractorAdditionalDocumentFile({
                    contractorId,
                    type: contractorFileType,
                    thumbnail: true,
                }));
            },
        }));
    };

    const onDropScan = (_files, scanType) => {
        setValues({
            ...values,
            files: {
                ...values.files,
                [scanType]: _files,
            },
        });
        setEditedFiles([
            ...new Set([
                ...editedFiles,
                scanType,
            ]),
        ]);
    };

    const {
        handleSubmit,
        values,
        setValues,
        setFieldValue,
        handleChange,
        setFieldTouched,
        errors,
        touched,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: false,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });

    return {
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleSubmit,
        files: values.files,
        onDropScan,
    };
};