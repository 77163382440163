import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DepositHistoryModal} from "../../../components/DepositHistoryModal";

import {PROJECT_LOG_DEPOSIT_ACTION} from "../../../constants/projects";

import {
    getProjectsDepositHistoryPage,
    updateStoreClientProject,
} from "../../../ducks/bff/clients/projects/actionCreators";
import {
    clientProjectsDepositHistoryPageSelector,
    clientProjectsDepositHistoryTotalCountSelector,
    clientProjectsDepositHistoryTotalPagesSelector,
} from "../../../ducks/bff/clients/projects/selectors";

export const ProjectDepositHistory = (props) => {
    const {
        onClose,
        logData,
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(clientProjectsDepositHistoryPageSelector);
    const totalPages = useSelector(clientProjectsDepositHistoryTotalPagesSelector);
    const totalCount = useSelector(clientProjectsDepositHistoryTotalCountSelector);

    useEffect(() => {
        return () => {
            dispatch(updateStoreClientProject({
                depositHistoryPageData: {},
                depositHistoryList: [],
                depositHistoryTotalCount: 0,
            }));
        };
    }, []);

    const fetchList = (data) => {
        dispatch(getProjectsDepositHistoryPage(data));
    };

    return (
        <DepositHistoryModal
            headerTextBeforeName="История изменений депозита проекта"
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            fetchList={fetchList}
            logData={logData}
            onClose={onClose}
            actionDict={PROJECT_LOG_DEPOSIT_ACTION}
        />
    );
};