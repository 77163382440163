import {all,put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

const controller = "/financesexport";

const FINANCES_EXPORT_LINK_SAVE = "FINANCES_EXPORT_LINK_SAVE";
const FINANCES_EXPORT_UPDATE_STORE = "FINANCES_EXPORT_UPDATE_STORE";

const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST";
const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS";
const IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR = "IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR";

const initial = {
    taskList: [],
    loading: false,
    totalCount: 0,
    pageData: {},
    addTaskSuccess: false,
    backLink: "",
    progress: false,
    isReferralPromoCodeClientsRewardsExportAvailable: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case FINANCES_EXPORT_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case FINANCES_EXPORT_LINK_SAVE:
            return {
                ...state,
                backLink: payload,
            };
        case IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS:
            return {
                ...state,
                isReferralPromoCodeClientsRewardsExportAvailable: payload.result,
            };
        default:
            return state;
    }
};

export function saveFinanceLinkBack(payload) {
    return {
        type: FINANCES_EXPORT_LINK_SAVE,
        payload,
    };
}

export function updateFinanceStore(payload) {
    return {
        type: FINANCES_EXPORT_UPDATE_STORE,
        payload,
    };
}

export function getReferralPromoCodeClientsRewardsExportAvailable() {
    return {
        type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST,
    };
}

export const financeExportSelector = state => state.financeExport;
export const financeBackLinkSelector = createSelector(financeExportSelector, ({backLink}) => backLink);
export const addFinanceExportSuccessTaskSelector = createSelector(financeExportSelector, ({addTaskSuccess}) => addTaskSuccess);
export const referralPromoCodeClientsRewardsExportAvailableSelector = createSelector(financeExportSelector, ({isReferralPromoCodeClientsRewardsExportAvailable}) => isReferralPromoCodeClientsRewardsExportAvailable);

//GET /api/financesexport/isReferralPromoCodeClientsRewardsExportAvailable
// Получить флаг возможности выгрузки экспорта промо кодов клиентов
export const getReferralPromoCodeClientsRewardsExportAvailableSaga = function* () {
    try {
        const {errorMessage, ...result} = yield request.get(`${controller}/isReferralPromoCodeClientsRewardsExportAvailable`);

        if (errorMessage) {
            yield put({
                type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR,
                payload: errorMessage,
            });

            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(IS_REFERRAL_PROMO_CADE_CLIENTS_EXPORT_AVAILABLE_REQUEST, getReferralPromoCodeClientsRewardsExportAvailableSaga),
    ]);
}