import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import CoordsInput from "../../../components/ActualComponents/CoordsInput";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import OpenStreetMap from "../../../components/OpenStreetMap";

import {getAddress} from "../../../utils/map";
import {isValidCoordinate} from "../../../utils/mathHelper";
import {getKeyFromMigrationDictByDadata} from "../../../utils/objectHelper";
import validate from "../../../utils/validate";

import {
    DOCUMENT_NOTIFICATIONS_FIELD_NAME,
    DOCUMENT_TYPE,
} from "../../../constants/documentType";
import {headersObjectUserList} from "../../../constants/headersTable";
import {
    blueIcon,
    darkBlueIcon,
} from "../../../constants/map";
import {MAP_MARKER_COLOR} from "../../../constants/objects";
import {documentNotificationRule} from "../../../constants/validationRules";

import {
    recreateEndWorkNotification,
    recreateStartWorkNotification,
} from "../../../ducks/bff/clients/documents/registry/actionCreators";
import {clientDocumentsRegistryProgressActionSelector} from "../../../ducks/bff/clients/documents/registry/selectors";
import {
    dadataGeoDataSelector,
    getAddressByCoords,
    getCoordsByAddress,
    updateGeoDataStore,
} from "../../../ducks/dadata";
import {
    clearStoreField,
    federalMigrationMarkersOVMSelector,
    federalMigrationProgressByIdSelector,
    getByIdMigration, getFederalMigrationByOrderId,
    getFederalMigrationCardSelector,
    getFederalMigrationOptionsSelector,
    getFederalRegionDictSelector,
    getFederalRegionOptionsSelector,
    getListMigrations,
    nearestMigrationOVMSelector,
} from "../../../ducks/federalMigration";

import PropTypes from "prop-types";

import "./style.sass";

class DocumentNotificationRecreateForm extends Component {
    static propTypes = {
        handleCancel: PropTypes.func,
        document: PropTypes.object,
        progressUpdate: PropTypes.bool,
    };

    static defaultProps = {
        handleCancel: () => {
        },
    };

    constructor(props) {
        super(props);

        const {
            document: {
                federalId,
            },
        } = props;

        this.state = {
            placeMarks: [],
            nameDepartmentFilter: "",
            formError: {},
            pageNum: 1,
            pageSize: 5,
            isLoadOVMInEditMode: Boolean(federalId),
            address: "",
            region: undefined,
            federalId: undefined,
            regionIdState: undefined,
            longitude: null,
            latitude: null,
            progressUpdate: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {
            federalMigration: {
                region: regionId,
                federalId,
            },
        } = props;

        const {
            regionId: oldRegionId,
            regionIdState,
        } = state;

        if (regionId && regionId !== regionIdState) {
            return {
                ...state,
                region: regionId,
                federalId,
                regionIdState: regionId,
            };
        }

        if (!oldRegionId && regionId !== oldRegionId) {
            return {
                ...state,
                regionId: regionId,
                federalId,
            };
        }
        return null;
    }

    componentDidMount() {
        this.fetchFederalMigrationById();

        const {document: {workAddress}} = this.props;

        if (workAddress) {
            this.fetchCoordsByAddress(workAddress);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            federalMigration: {
                region,
            },
            document: {
                workAddress,
            },
            placeMarks,
            nearestOVM,
            handleCancel,
            progressUpdate,
            dadataGeoData,
        } = this.props;
        const {
            isLoadOVMInEditMode,
        } = this.state;

        const {
            federalMigration: {
                region: _region,
            },
            placeMarks: _placeMarks,
            dadataGeoData: _dadataGeoData,
        } = prevProps;

        // После получения информации о ОВМ из списка
        if (!workAddress && region && region !== _region) {
            this.getListMigrations();
        }

        // При получении ближайших ОВМ
        if (!isEmpty(placeMarks) && !isEqual(placeMarks, _placeMarks)) {
            const {
                federalId,
            } = this.state;

            // В случае если форма открыта на редактирование, то первый раз при прогрузке не проставляем
            // ближайший ОВМ на форме, а используем тот что уже сохранен
            this.setState(prevState => ({
                ...prevState,
                placeMarks,
                federalId: !isLoadOVMInEditMode ? nearestOVM : prevState.federalId,
            }), () => {
                if (isLoadOVMInEditMode) {
                    this.updateMarkers(federalId);

                    this.setState({
                        isLoadOVMInEditMode: false,
                    });
                }
            });
        }

        if (!progressUpdate && prevProps.progressUpdate !== progressUpdate) {
            handleCancel();
        }

        if (!isEqual(dadataGeoData, _dadataGeoData) && !isEmpty(dadataGeoData)) {
            const [
                {
                    result: address,
                    region,
                    geo_lat: latitude,
                    geo_lon: longitude,
                },
            ] = dadataGeoData;

            if (!latitude || !longitude) {
                if (region) {
                    const regionId = getKeyFromMigrationDictByDadata(region, this.props.federalRegionDict);

                    this.setState({
                        region: regionId,
                    });
                }

                return;
            }

            if (!region) {
                return;
            }

            const regionId = getKeyFromMigrationDictByDadata(region, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                latitude,
                longitude,
                address,
                region: regionId,
            }), this.getListMigrations);
        }
    }

    componentWillUnmount() {
        const {
            clearStoreField,
            updateGeoDataStore,
        } = this.props;

        clearStoreField({
            federalMigrationList: {
                totalCount: 0,
                pageData: {
                    pageSize: 25,
                    pageNum: 1,
                },
                list: [],
            },
            card: {},
        });
        updateGeoDataStore({
            dadataGeoData: [{}],
            nominatimGeoData: {},
        });
    }

    fetchFederalMigrationById = () => {
        const {
            getByIdMigration,
            document: {
                workAddress,
                federalId,
            },
        } = this.props;

        if (!federalId) {
            return;
        }

        getByIdMigration({
            federalId,
            onSuccess: ({region}) => {
                this.setState(prevState => ({
                    ...prevState,
                    address: workAddress,
                    region,
                }), this.getListMigrations);
            },
        });
    };

    getListMigrations = () => {
        const {getListMigrations} = this.props;
        const {
            region,
            longitude: longitudeFilter,
            latitude: latitudeFilter,
        } = this.state;

        getListMigrations({
            pageNum: 1,
            pageSize: 200,
            longitudeFilter,
            latitudeFilter,
            region,
        });
    };

    submitForm = () => {
        const {
            document: {
                clientId,
                orderId,
                contractorId,
                documentType,
                objectId,
            },
            recreateStartWorkNotification,
            recreateEndWorkNotification,
        } = this.props;
        const {
            federalId: predefinedFederalId,
            address: predefinedWorkAddress,
        } = this.state;

        const isValid = this.validationForm();

        if (!isValid) {
            return;
        }

        const requestData = {
            clientId,
            orderId,
            contractorId,
            predefinedFederalId,
            predefinedWorkAddress,
            objectId,
        };

        if (documentType === DOCUMENT_TYPE.START_WORK_NOTIFY) {
            recreateStartWorkNotification(requestData);

            return;
        }

        recreateEndWorkNotification(requestData);
    };

    updateForm = data => {
        this.setState(prevState => ({
            ...prevState,
            ...data,
        }));
    };

    updateMarkers = (federalId) => {
        const {placeMarks} = this.state;

        const _placeMarks = placeMarks.map(value => {
            return {
                ...value,
                icon: blueIcon,
            };
        });

        const clickedOVM = _placeMarks.find(value => (value.federalId === federalId)) || {};

        clickedOVM.icon = darkBlueIcon;

        this.setState(prevState => ({
            ...prevState,
            federalId,
            placeMarks: _placeMarks,
        }));
    };

    updateOVMPlaceMarks = federalId => {
        const {placeMarks} = this.state;

        const _placeMarks = placeMarks.map(value => {
            return {
                ...value,
                iconColor: MAP_MARKER_COLOR.DEFAULT,
            };
        });

        const selected = _placeMarks.find(value => (value.federalId === federalId)) || {};

        if (Object.keys(selected).length) {
            selected.iconColor = MAP_MARKER_COLOR.SELECTED_OVM;

            this.setState(prevState => ({
                ...prevState,
                placeMarks: _placeMarks,
                federalId,
            }));

            return;
        }

        this.setState({
            placeMarks: _placeMarks,
        });
    };

    handleChangeFieldsObject = (event, {checked, value, name: field}) => {
        if ([DOCUMENT_NOTIFICATIONS_FIELD_NAME.LATITUDE, DOCUMENT_NOTIFICATIONS_FIELD_NAME.LONGITUDE].includes(field) && !isValidCoordinate(value) && value !== "") {
            return;
        }
        if (field === DOCUMENT_NOTIFICATIONS_FIELD_NAME.FEDERAL_ID) {
            this.updateOVMPlaceMarks(value);
        }

        this.setState(prevState =>
            ({
                ...prevState,
                [field]: value || checked,
            }),
        );
    };

    validationForm() {
        const {
            address,
            region,
            federalId,
            longitude,
            latitude,
        } = this.state;

        const errorMessage = validate({
            address,
            region,
            federalId,
            longitude,
            latitude,
        }, documentNotificationRule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    onChangeRegion = (e, {value}) => {
        this.setState(prevState => ({
            ...prevState,
            region: value,
        }),
        this.getListMigrations,
        );
    };

    searchFederalMigration = (nameDepartmentFilter) => {
        this.setState({nameDepartmentFilter}, this.getListMigrations);
    };

    mapTableData() {
        const {userList} = this.props;

        const rows = userList.map(item => {

            return {
                ...item,
                name: `${item.firstName} ${item.lastName} ${item.patronymic || ""}`,
            };
        });
        return {
            headers: headersObjectUserList,
            rows,
        };
    }

    handleClickOVM = (federalId) => {
        const {placeMarks} = this.state;

        const _placeMarks = placeMarks.map(value => {
            return {
                ...value,
                iconColor: MAP_MARKER_COLOR.DEFAULT,
            };
        });

        const clickedOVM = _placeMarks.find(value => (value.federalId === federalId)) || {};

        clickedOVM.iconColor = MAP_MARKER_COLOR.SELECTED_OVM;

        this.setState(prevState => ({
            ...prevState,
            federalId,
            placeMarks: _placeMarks,
        }));
    };

    renderFormFields() {
        const {
            federalMigrationOptions,
            federalRegionsOptions,
            t,
        } = this.props;
        const {
            address,
            region,
            federalId,
            longitude,
            latitude,
            formError,
        } = this.state;

        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmDadataInput
                            className="document-notification-recreate-form__dadata"
                            label={t("document-notification-recreate-form.address")}
                            query={address || ""}
                            error={formError[DOCUMENT_NOTIFICATIONS_FIELD_NAME.ADDRESS]}
                            // onChange={(data) => {this.handleOnChangeAddress(data)}}
                            placeholder={t("document-notification-recreate-form.address")}
                            onBlur={(value) => {
                                this.fetchCoordsByAddress(value);
                            }}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <CoordsInput
                            nameStart={DOCUMENT_NOTIFICATIONS_FIELD_NAME.LATITUDE}
                            nameEnd={DOCUMENT_NOTIFICATIONS_FIELD_NAME.LONGITUDE}
                            size="xl"
                            valueStart={latitude || ""}
                            valueEnd={longitude || ""}
                            onChange={this.handleChangeFieldsObject}
                            label={t("document-notification-recreate-form.coordinates")}
                            placeholderStart={t("document-notification-recreate-form.location-latitude")}
                            placeholderEnd={t("document-notification-recreate-form.location-longitude")}
                            error={formError[DOCUMENT_NOTIFICATIONS_FIELD_NAME.LATITUDE] || formError[DOCUMENT_NOTIFICATIONS_FIELD_NAME.LONGITUDE]}
                        />
                    </div>
                </div>
                {this.renderNewMap()}
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            size="xl"
                            search
                            label={t("client-object-new.ovm-region")}
                            name={DOCUMENT_NOTIFICATIONS_FIELD_NAME.REGION}
                            error={formError[DOCUMENT_NOTIFICATIONS_FIELD_NAME.REGION]}
                            options={federalRegionsOptions}
                            placeholder={t("document-notification-recreate-form.ovm-region")}
                            onChange={this.onChangeRegion}
                            value={region}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmDropdownV2
                            size="xl"
                            label={t("client-object-new.ovm")}
                            name={DOCUMENT_NOTIFICATIONS_FIELD_NAME.FEDERAL_ID}
                            error={formError[DOCUMENT_NOTIFICATIONS_FIELD_NAME.FEDERAL_ID]}
                            onSearchChange={this.searchFederalMigration}
                            onChange={this.handleChangeFieldsObject}
                            placeholder={t("document-notification-recreate-form.ovm")}
                            search
                            value={federalId}
                            options={federalMigrationOptions}
                        />
                    </div>
                </div>
            </>
        );
    }

    fetchCoordsByAddress = (address) => {
        if (!address) {
            this.setState({
                address: "",
            });

            return;
        }

        const {getCoordsByAddress} = this.props;

        getCoordsByAddress([address]);
    };

    fetchAddressByCoords = ({lat, lng}) => {
        const {getAddressByCoords} = this.props;

        const onSuccess = (result) => {
            const {getListMigrations} = this.props;
            const {
                address: {
                    state,
                },
            } = result;

            const address = getAddress(result);
            const regionId = getKeyFromMigrationDictByDadata(state, this.props.federalRegionDict);

            this.setState(prevState => ({
                ...prevState,
                latitude: lat,
                longitude: lng,
                address,
                region: regionId,
            }));

            getListMigrations({
                pageNum: 1,
                pageSize: 200,
                longitudeFilter: lng,
                latitudeFilter: lat,
                region: regionId,
            });
        };

        getAddressByCoords({
            lat,
            lng,
            onSuccess,
        });
    };

    renderNewMap() {
        const {
            latitude,
            longitude,
            address,
            placeMarks,
        } = this.state;

        return (
            <OpenStreetMap
                zoom={10}
                scrollWheelZoom={true}
                className="document-notification-recreate-form__map"
                updateMarkers={this.updateMarkers}
                latitude={latitude}
                placeMarks={placeMarks}
                longitude={longitude}
                tooltipContent={address}
                fetchAddressByCoords={this.fetchAddressByCoords}
                updateForm={this.updateForm}
                OVMs
            />
        );
    }

    renderActions() {
        const {
            t,
            handleCancel,
            progressUpdate,
        } = this.props;

        return (
            <ApplyButtons
                mobile="column"
                submitBtnContent={t("button.to-form")}
                cancelBtnContent={t("button.cancel")}
                onClose={handleCancel}
                isHiddenCancelOnMobile
                submit={this.submitForm}
                disabled={progressUpdate}
                loading={progressUpdate}
            />
        );
    }

    render() {
        const {
            document: {
                contractorName,
            },
            handleCancel,
            progressById,
            t,
        } = this.props;

        return (
            <NmModal
                onClose={handleCancel}
                className="document-notification-recreate-form"
                size="md"
                header={
                    <NmTitle size="lg">
                        Редактирование уведомления
                    </NmTitle>
                }
                footer={this.renderActions()}
                loading={progressById}
                loaderInverted
            >
                <NmForm addMargin>
                    <Text level="3">
                        {t("document-notification-recreate-form.title", {contractorName})}
                    </Text>
                    {this.renderFormFields()}
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(state => ({
    federalMigrationOptions: getFederalMigrationOptionsSelector(state),
    federalMigration: getFederalMigrationCardSelector(state),
    placeMarks: federalMigrationMarkersOVMSelector(state),
    federalRegionDict: getFederalRegionDictSelector(state),
    federalRegionsOptions: getFederalRegionOptionsSelector(state),
    nearestOVM: nearestMigrationOVMSelector(state),
    progressById: federalMigrationProgressByIdSelector(state),
    dadataGeoData: dadataGeoDataSelector(state),
    progressUpdate: clientDocumentsRegistryProgressActionSelector(state),
}), {
    recreateStartWorkNotification,
    recreateEndWorkNotification,
    getListMigrations,
    getByIdMigration,
    clearStoreField,
    getFederalMigrationByOrderId,
    getAddressByCoords,
    getCoordsByAddress,
    updateGeoDataStore,
})(withTranslation()(DocumentNotificationRecreateForm));