import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

const registryInvitationsSelector = state => state.bff.registryInvitations;

export const registryInvitationsTotalPagesSelector = createSelector(
    registryInvitationsSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const registryInvitationsTotalCountSelector = createSelector(
    registryInvitationsSelector,
    ({totalCount}) => totalCount,
);
export const registryInvitationsListSelector = createSelector(
    registryInvitationsSelector,
    ({list}) => list,
);
export const registryInvitationsProgressSelector = createSelector(
    registryInvitationsSelector,
    ({progress}) => progress,
);
export const registryInvitationsActionProgressSelector = createSelector(
    registryInvitationsSelector,
    ({actionProgress}) => actionProgress,
);
