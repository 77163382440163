import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls, USER_RESTRICTIONS, USER_ROLE} from "./localstorage";
import {isAccessByRestrictions} from "./restrictions";
import {isProd} from "./url";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../constants/clientUserRestrictions";
import {
    ADMIN,
    adminAccessLinks,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    clientAccountantAccessLinks,
    clientAdminAccessLinks,
    clientObjectManagerAccessLinks,
    clientProjectManagerAccessLinks,
    FOREMAN,
    foremanAccessLinks,
    HR_MANAGER,
    hrManagerAccessLinks,
    linksRestrictionsVariables,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    nmChiefAccountantAccessLinks,
    nmConsultantAccessLinks,
    nmCoordinatorAccessLinks,
    nmManagerAccessLinks,
    nmOperatorAccessLinks,
    nmPartnerAccessLinks,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
    recruiterAccessLinks,
    RECRUITMENT_OBSERVER,
    recruitmentObserverAccessLinks,
    RNKO,
    RNKOAccessLinks,
    seniorRecruiterAccessLinks,
} from "../constants/roles";

import {
    adminMenuList,
    clientAdminMenuList,
    clientObjectManagerMenuList,
    clientProjectManagerMenuList,
    foremanMenuList,
    getRecruitmentObserverMenuList,
    hrManagerMenuList,
    nmChiefAccountantMenuList,
    nmConsultantMenuList,
    nmCoordinatorMenuList,
    nmManagerMenuList,
    operatorMenuList,
    partnerMenuList,
    recruiterMenuList,
    RNKOMenuList,
} from "../constants/menu-links";

export function isNMUsers() {
    const role = ls(USER_ROLE);

    return [
        ADMIN,
        NM_CONSULTANT,
        NM_COORDINATOR,
        NM_MANAGER,
        RNKO,
        NM_CHIEF_ACCOUNTANT,
        NM_OPERATOR,
    ].includes(role);
}

export function isAccessEditMemberByRole(memberRole) {
    const role = ls(USER_ROLE);
    if (role === ADMIN) {
        return true;
    }

    if (role === NM_MANAGER) {
        return [
            CLIENT_ACCOUNTANT,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CONSULTANT,
            NM_CHIEF_ACCOUNTANT,
            RNKO,
            CLIENT_ADMIN,
            FOREMAN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            HR_MANAGER,
            NM_PARTNER,
            RECRUITER,
        ].includes(memberRole);
    }

    if (role === NM_CONSULTANT) {
        return [
            CLIENT_ACCOUNTANT,
            CLIENT_ADMIN,
            FOREMAN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            HR_MANAGER,
            RECRUITER,
        ].includes(memberRole);
    }

    if (role === CLIENT_ADMIN) {
        return [
            CLIENT_ACCOUNTANT,
            CLIENT_ADMIN,
            FOREMAN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            HR_MANAGER,
            RECRUITER,
            RECRUITMENT_OBSERVER,
        ].includes(memberRole);
    }

    if (role === PROJECT_MANAGER) {
        return [
            FOREMAN,
            PROJECT_MANAGER,
            OBJECT_MANAGER,
            HR_MANAGER,
            RECRUITER,
        ].includes(memberRole);
    }

    if (role === OBJECT_MANAGER) {
        return [FOREMAN, OBJECT_MANAGER, RECRUITER].includes(memberRole);
    }

    return false;
}

export function isAccessEditMemberByUserId(userId) {
    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    return userId !== currentUserId;
}

export const getAccessLink = (props) => {
    const {
        role,
        currentMember = {},
    } = props;

    const linksDict = {
        ADMIN: adminAccessLinks,
        NM_MANAGER: nmManagerAccessLinks,
        NM_COORDINATOR: nmCoordinatorAccessLinks,
        NM_PARTNER: nmPartnerAccessLinks,
        NM_CHIEF_ACCOUNTANT: nmChiefAccountantAccessLinks,
        NM_CONSULTANT: nmConsultantAccessLinks,
        RNKO: RNKOAccessLinks,
        CLIENT_ADMIN: clientAdminAccessLinks,
        CLIENT_ACCOUNTANT: clientAccountantAccessLinks,
        PROJECT_MANAGER: clientProjectManagerAccessLinks,
        OBJECT_MANAGER: clientObjectManagerAccessLinks,
        FOREMAN: foremanAccessLinks,
        HR_MANAGER: hrManagerAccessLinks,
        NM_OPERATOR: nmOperatorAccessLinks,
        RECRUITER: currentMember.seniorRecruiter
            ? seniorRecruiterAccessLinks
            : recruiterAccessLinks,
        RECRUITMENT_OBSERVER: recruitmentObserverAccessLinks,
    };

    const links = linksDict[role];

    return links?.filter(item => {
        const checkRestrictionsList = linksRestrictionsVariables[item] || [];

        return isAccessByRestrictions(checkRestrictionsList);
    });
};

export const getMenuList = (params) => {
    const {
        role,
        clientId,
        edoAccessList,
        clientUserStaffExist,
        migrantLicensePaymentEnabled,
        clientProperties,
        currentMember,
        currentUserRestrictions,
    } = params;

    return {
        ADMIN: adminMenuList({
            currentUserRestrictions,
        }),
        NM_MANAGER: nmManagerMenuList({
            currentUserRestrictions,
        }),
        NM_COORDINATOR: nmCoordinatorMenuList({
            currentUserRestrictions,
        }),
        NM_PARTNER: partnerMenuList,
        NM_CHIEF_ACCOUNTANT: nmChiefAccountantMenuList({
            currentUserRestrictions,
        }),
        NM_CONSULTANT: nmConsultantMenuList,
        RNKO: RNKOMenuList,
        CLIENT_ADMIN: clientAdminMenuList({
            clientId,
            edoAccessList,
            clientUserStaffExist,
            migrantLicensePaymentEnabled,
            clientProperties,
        }),
        CLIENT_ACCOUNTANT: clientAdminMenuList({
            clientId,
            edoAccessList,
            clientUserStaffExist,
            migrantLicensePaymentEnabled,
            clientProperties,
            role,
        }),
        PROJECT_MANAGER: clientProjectManagerMenuList({
            clientId,
            edoAccessList,
            clientUserStaffExist,
            migrantLicensePaymentEnabled,
            clientProperties,
        }),
        OBJECT_MANAGER: clientObjectManagerMenuList({
            clientId,
            edoAccessList,
            clientProperties,
        }),
        FOREMAN: foremanMenuList({
            clientId,
            edoAccessList,
            clientProperties,
        }),
        RECRUITER: recruiterMenuList({
            clientId,
            edoAccessList,
            clientProperties,
            currentMember,
        }),
        HR_MANAGER: hrManagerMenuList({
            clientId,
            edoAccessList,
            clientProperties,
        }),
        RECRUITMENT_OBSERVER: getRecruitmentObserverMenuList({
            clientId,
            clientProperties,
        }),
        [NM_OPERATOR]: operatorMenuList,
    }[role];
};

export const getAccessDataByRole = (params) => {
    const {
        path,
        edoAccessList,
        clientUserStaffExist,
        migrantLicensePaymentEnabled,
        clientProperties = {},
        currentMember = {},
    } = params;

    const role = ls(USER_ROLE);
    const currentUserRestrictions = getUserRestrictions();
    const accessLink = getAccessLink({
        role,
        currentUserRestrictions,
    }) || "";
    const clientId = ls(CURRENT_CLIENT_ID);

    return {
        isAccess: accessLink.indexOf(path) !== -1,
        menuList: getMenuList({
            role,
            clientId,
            edoAccessList,
            clientUserStaffExist,
            migrantLicensePaymentEnabled,
            clientProperties,
            currentMember,
            currentUserRestrictions,
        }),
    };
};

export const isAccessByRoles = (availableRoles) => {
    const role = ls(USER_ROLE);

    return availableRoles.includes(role);
};

/***
 * Разрешение для чекбокса "Исключить исполнителей из закрытого контура" в разделе "Рассылки"
 * @param email - email юзера
 * @returns {boolean}
 */
export const isAccessToClosedGroups = (email) => {
    if (!isProd()) {
        return true;
    }

    return [
        "klimenko@naimix.info",
        "vatamanchugov@mmtr.ru",
        "yu.zvezdkina@naimix.info",
        "o.kharlashkina@naimix.info",
    ].includes(email);
};

export const isVisibleRecruitmentActions = () => {
    const role = ls(USER_ROLE);

    return [
        ADMIN,
        NM_MANAGER,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
        HR_MANAGER,
        RECRUITER,
    ].includes(role);
};

export const getUserRole = () => {
    return ls(USER_ROLE);
};

export const getUserRestrictions = () => {
    const restrictions = ls(USER_RESTRICTIONS);

    if (!restrictions) {
        return [];
    }

    return JSON.parse(restrictions);
};