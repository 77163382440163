export const BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSE_SET_CANDIDATE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_SET_CANDIDATE_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR ="BFF_RECRUITMENT_VACANCY_RESPONSES_HEAD_HUNTER_SET_STATUS_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_CARD_BY_ID_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_PUT_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPLOAD_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_DELETE_FILE_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_REJECT_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_SET_STATUS_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_ADD_KEDO_STAFF_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_REQUEST = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_REQUEST";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_SUCCESS = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_SUCCESS";
export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_ERROR = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_KEDO_STAFF_ERROR";

export const BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE = "BFF_RECRUITMENT_VACANCY_RESPONSES_UPDATE_STORE";