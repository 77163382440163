import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

import {LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED} from "../../../../../constants/links";

const getController = () => {
    const adminPageController = "/adm/finances/registry-payments/registry/log";

    return (
        getBffUrl({
            [LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED.replace(":archived", false)]: adminPageController,
            [LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED.replace(":archived", true)]: adminPageController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/registry-payments/registry/log",
            adminRolesUrl: "/adm/clients/client-card/finances/registry-payments/registry/log",
        })
    );
};

//POST /bff/adm/finances/registry-payments/log/page
//POST /bff/adm/clients/client-card/finances/registry-payments/registry/log/page
//POST /bff/client-adm/finances/registry-payments/registry/log/page
// Получить страницу логов реестровых оплат
const getPageRegistryPaymentLog = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_LOG_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(REGISTRY_PAYMENT_LOG_GET_PAGE_REQUEST, getPageRegistryPaymentLog),
    ]);
}