import {isEmpty} from "lodash";

import {PAYMENT_METHOD, PAYMENT_METHOD_OPTION_DICT} from "../constants/clientSettings";
import {ORDER_WORK_REPORT_TYPE} from "../constants/finance";
import {PAYMENT_TYPE} from "../constants/settings";

const getPaymentMethodOptions = (props) => {
    const {
        bankTypeCard,
        bankTypeSbp,
        bankTypeRequisites,
    } = props || {};
    return [
        Boolean(bankTypeCard) && PAYMENT_METHOD_OPTION_DICT.CARD_LOCAL,
        Boolean(bankTypeSbp) && PAYMENT_METHOD_OPTION_DICT.SBP,
        Boolean(bankTypeRequisites) && PAYMENT_METHOD_OPTION_DICT.BANK_REQUISITES,
    ].filter(item => Boolean(item));
};

export const getPaymentMethodOptionsByClientPaymentsSettings = ({paymentsSettings, paymentType, contractType}) => {
    const {
        smzRegistry = {},
        civilRegistry = {},
        smzMarketplace = {},
        civilMarketplace = {},
        individualRegistry = {},
        individualMarketplace = {},
    } = paymentsSettings || {};

    if (contractType === ORDER_WORK_REPORT_TYPE.CIVIL) {
        const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? civilRegistry : civilMarketplace;

        return getPaymentMethodOptions(settingsBlock);
    }

    if (contractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
        const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? individualRegistry : individualMarketplace;

        return getPaymentMethodOptions(settingsBlock);
    }

    const settingsBlock = paymentType === PAYMENT_TYPE.REGISTRY ? smzRegistry : smzMarketplace;

    return getPaymentMethodOptions(settingsBlock);
};

export const getSameBankTypeCard = ({paymentsSettings, isNdfl}) => {
    const {
        smzRegistry,
        smzMarketplace = {},
        smzApi = {},
        civilRegistry = {},
        civilMarketplace = {},
        civilApi = {},
    } = paymentsSettings || {};

    if (
        isNdfl &&
        civilRegistry.bankTypeCard === civilMarketplace.bankTypeCard &&
        civilMarketplace.bankTypeCard === civilApi.bankTypeCard
    ) {
        return civilRegistry.bankTypeCard;
    }

    if (
        smzRegistry.bankTypeCard === smzMarketplace.bankTypeCard &&
        smzMarketplace.bankTypeCard === smzApi.bankTypeCard
    ) {
        return smzRegistry.bankTypeCard;
    }

    return null;
};

/**
 * Хелпер для определения отображения поля "Проведение оплаты" в модальном окне формирования счета-основания
 * Поле отображается, если у компании на один тип оплат (на карты РФ) установлены разные банки.
 * Например, по заказам НПД - Плутониум, по реестру НПД - Гермес. Иначе не отображается.
 * @param paymentsSettings
 * @param isNdfl
 * @returns {boolean}
 */
export const isClientHasDifferentPaymentMethods = (paymentsSettings, isNdfl) => {
    const {
        smzRegistry,
        smzMarketplace,
        smzApi,
        civilRegistry,
        civilMarketplace,
        civilApi,
    } = paymentsSettings;

    const paymentSources = isNdfl
        ? [
            civilRegistry,
            civilMarketplace,
            civilApi,
        ]
        : [
            smzRegistry,
            smzMarketplace,
            smzApi,
        ];

    // Собираем в удобную структуру, чтобы потом пробежаться
    const result = paymentSources
        .filter(item => !isEmpty(item))
        .reduce((result, item) => {
            const paymentMethod = item.paymentMethod;

            return {
                [item.paymentMethod]: result[paymentMethod]
                    ? [
                        ...result[paymentMethod],
                        item.bankTypeCard,
                    ]
                    : [
                        item.bankTypeCard,
                    ],
            };
        }, {});

    return Object.keys(result).some((paymentMethod) => {
        return result[paymentMethod].some((bankTypeCard, _index, array) => {
            return array[0] !== bankTypeCard;
        });
    });
};