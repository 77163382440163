import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

const controller = "/orderWorkReport";
//*  TYPES  *//

const ORDER_WORK_REPORT_UPDATE_FIELD_STORE = "ORDER_WORK_REPORT_UPDATE_FIELD_STORE";

const ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST";
const ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS";
const ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR = "ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR";

const ADD_VIEW_CANCELLED_CHECKS_REQUEST = "ADD_VIEW_CANCELLED_CHECKS_REQUEST";

const HAS_CANCELLED_CHECKS_REQUEST = "HAS_CANCELLED_CHECKS_REQUEST";
const HAS_CANCELLED_CHECKS_SUCCESS = "HAS_CANCELLED_CHECKS_SUCCESS";

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    error: {},
    paidSumList: [],
    orderPaymentRequestParams: {},
    orderPaymentList: [],
    listLastChangePayments: [],
    orderPaymentTotalCount: 0,
    orderWorkReportLogs: [],
    totalCountLogs: 0,
    pageDataReportLog: {},
    confirmPaymentSuccess: false,
    cancelledChecks: [],
    hasCancelledChecks: false,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case HAS_CANCELLED_CHECKS_SUCCESS:
            return {
                ...state,
                hasCancelledChecks: payload,
            };
        case ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS:
            return {
                ...state,
                listLastChangePayments: payload,
            };
        case ORDER_WORK_REPORT_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR:
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//
export function updateFieldOrderWorkReportStore(payload) {
    return {
        type: ORDER_WORK_REPORT_UPDATE_FIELD_STORE,
        payload,
    };
}

export function fetchWorkLogLastChangePayment(payload) {
    return {
        type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST,
        payload,
    };
}

// Добавляет просмотр пользователем аннулированных чеков
export function addViewCancelledChecks(payload) {
    return {
        type: ADD_VIEW_CANCELLED_CHECKS_REQUEST,
        payload,
    };
}

// Есть ли аннулированные чеки, индикатор
export function hasCancelledChecks(payload) {
    return {
        type: HAS_CANCELLED_CHECKS_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const orderWorkReportSelector = state => state.orderWorkReport;
export const getOrderWorkConfirmPaymentSuccessSelector = createSelector(orderWorkReportSelector, ({confirmPaymentSuccess}) => confirmPaymentSuccess);
export const getOrderWorkReportLastChangePaymentsSelector = createSelector(orderWorkReportSelector, ({listLastChangePayments}) => listLastChangePayments);
export const cancelledChecksIndicatorSelector = createSelector(orderWorkReportSelector, ({hasCancelledChecks}) => hasCancelledChecks);

//*  SAGA  *//
// POST /bff/adm/clients/client-card/reports/order-analytics/responses/hired/get-order-work-report-log-last-change-by-ids
// POST /bff/client-adm/reports/order-analytics/responses/hired/get-order-work-report-log-last-change-by-ids
export const getOrdersWorkReportLogLastChangeByIdSaga = function* ({payload}) {
    try {
        const {paymentsNumber} = payload;
        const controller = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/reports/order-analytics/responses/hired",
            client: "/client-adm/reports/order-analytics/responses/hired",
        });

        const result = yield request.bff.post(`${controller}/get-order-work-report-log-last-change-by-ids`, paymentsNumber);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_LOG_LAST_CHANGE_ERROR,
            payload: error,
        });
    }
};

// GET /api/orderWorkReport/cancelled/hasCancelledChecks
// Индикатор аннулированных чеков
export const hasCancelledChecksSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
        } = payload;
        const result = yield request.get(`${controller}/cancelled/hasCancelledChecks?clientId=${clientId}&clientUserId=${clientUserId}`);

        yield put({
            type: HAS_CANCELLED_CHECKS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error("hasCancelledChecksSaga error: ", error);
    }
};

// GET /api/orderWorkReport/cancelled/addViewCancelledChecks
// Просмотр аннулированных чеков
export const addViewCancelledChecksSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
        } = payload;

        const result = yield request.get(`${controller}/cancelled/addViewCancelledChecks?clientId=${clientId}&clientUserId=${clientUserId}`);

        yield put(hasCancelledChecks(payload));

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            console.error("addViewCancelledChecksSaga error: ", errorMessage);
        }
    } catch (error) {
        console.error("addViewCancelledChecksSaga error: ", error);
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_WORK_REPORT_LOG_LAST_CHANGE_REQUEST, getOrdersWorkReportLogLastChangeByIdSaga),
        takeEvery(ADD_VIEW_CANCELLED_CHECKS_REQUEST, addViewCancelledChecksSaga),
        takeEvery(HAS_CANCELLED_CHECKS_REQUEST, hasCancelledChecksSaga),
    ]);
}
