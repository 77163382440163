import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as AddIcon} from "../../../../images/add.svg";

import {useInterval} from "../../../../hooks/useInterval";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {toastError} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {PHONE_CODE_TYPE, RUSSIA_PHONE_CODE_ID} from "../../../../constants/phone";

import {
    archiveDocumentsEdoRegistries,
    checkContractorsExistsDocumentsEdoRegistries,
    checkDuplicatesDocumentsEdoRegistries,
    checkDuplicatesRegistry,
    confirmDuplicatesDocumentsEdoRegistries,
    confirmDuplicatesRegistry,
    sendSmsDocumentsEdoRegistries,
    signDocumentsEdoRegistries,
} from "../../../../ducks/bff/clients/edo/registries/actionCreators";
import {
    checkImportTaskExistsDocumentsEdoRegistriesItems,
    deleteNotCompletedDocumentsEdoRegistriesItems,
} from "../../../../ducks/bff/clients/edo/registries/card/actionCreators";
import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

import {EDO_ACCESS_RIGHTS} from "../../../../constants/document-management/document-list";

const DEFAULT_TIME = 60;

export const useEdoDocumentsRegistriesAction = (params) => {
    const {
        clientId,
        edoAccessList,
        isAccessRegistryManageByRestrictions,
    } = params;

    const {edmSignWithoutSmsCodeEnable} = useSelector(clientCardPropertiesSelector);

    const {t} = useTranslation();
    const [archived, setArchived] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isOpenEditForm, setIsOpenEditForm] = useState(false);
    const [editRegistryData, setEditRegistryData] = useState({});
    const [isCodeConfirmOpen, setCodeConfirmOpen] = useState(false);
    const [codeConfirmType, setCodeConfirmType] = useState(PHONE_CODE_TYPE.SMS);
    const [dataDuplicate, setDataDuplicate] = useState({});
    const [smsCode, setSmsCode] = useState("");
    const [timer, setTimer] = useState(DEFAULT_TIME);
    const [currentRegistryId, setCurrentRegistryId] = useState(null);

    const archive = getArchiveButton(t, archived, {mobile: true});

    const dispatch = useDispatch();

    useInterval(() => {
        if (timer > 0) {
            setTimer(timer - 1);
        }
    }, 1000);

    const onChangeCode = (value) => {
        setSmsCode(value);
    };

    const getHeaderMediaControls = () => {
        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => setArchived(!archived),
                        ...archive.props,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <AddIcon />,
                        onClick: () => setIsOpenEditForm(true),
                        size: "xl",
                        children: "Добавить реестр",
                        color: "green",
                    },
                    visible: edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_MANAGE)
                        && isAccessRegistryManageByRestrictions,
                },
            ],
        };
    };

    const archiveRegistries = (data) => {
        dispatch(archiveDocumentsEdoRegistries({
            clientId,
            ...data,
        }));
    };

    const onCloseEditForm = () => {
        setIsOpenEditForm(false);
        setEditRegistryData({});
    };

    const openSignConfirm = ({registryId, fetchList, content}) => {
        setConfirmData({
            content,
            onConfirm: () => {
                setCurrentRegistryId(registryId);
                checkImportTaskExists(registryId, fetchList);
            },
        });
    };

    const checkImportTaskExists = (registryId, fetchList) => {
        dispatch(checkImportTaskExistsDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            getResult: ({result}) => {
                if (result) {
                    toastError("Задача на загрузку списка исполнителей не завершена. Пожалуйста, повторите попытку позже");

                    return;
                }

                checkDuplicatesItems(registryId, fetchList);
            },
        }));
    };

    const checkDuplicatesItems = (registryId, fetchList) => {
        dispatch(checkDuplicatesRegistry({
            clientId,
            registryId,
            getResult: ({result}) => {
                if (result) {
                    setConfirmData({
                        content: "Внимание! В реестре найдены повторяющиеся строки с одним и тем же исполнителем (получателем). После передачи реестра на подписание дубли строк будут удалены автоматически. Вы подтверждаете операцию удаления дублей строк и передачи реестра на подписание?",
                        onConfirm: () => confirmDuplicatesRegistryItems(registryId, fetchList),
                    });

                    return;
                }
                checkDuplicatesDocumentsEdoItems(registryId, fetchList);
            },
        }));
    };

    const confirmDuplicatesRegistryItems = (registryId) => {
        dispatch(confirmDuplicatesRegistry({
            clientId,
            registryId,
            onSuccess: () => checkDuplicatesDocumentsEdoItems(registryId),
        }));
    };

    const checkDuplicatesDocumentsEdoItems = (registryId, fetchList) => {
        dispatch(checkDuplicatesDocumentsEdoRegistries({
            clientId,
            registryId,
            getResult: ({duplicates, duplicatesInRegistryExists}) => {
                if (!duplicatesInRegistryExists && !duplicates?.length) {
                    checkContractorsExists(registryId, fetchList);

                    return;
                }

                if (duplicatesInRegistryExists && !duplicates?.length) {
                    setConfirmData({
                        content: "Внимание! В реестре найдены повторяющиеся строки с одним и тем же исполнителем (получателем). После передачи реестра на подписание дубли строк будут удалены автоматически. Вы подтверждаете операцию удаления дублей строк и передачи реестра на подписание?",
                        onConfirm: (seqNums) => confirmDuplicatesItems(registryId, seqNums, fetchList),
                    });

                    return;
                }

                setDataDuplicate({
                    duplicatesInRegistryExists,
                    duplicates,
                });
            },
        }));
    };

    const confirmDuplicatesItems = (seqNums) => {
        dispatch(confirmDuplicatesDocumentsEdoRegistries({
            clientId,
            registryId: currentRegistryId,
            seqNums,
            onSuccess: () => {
                setDataDuplicate({});
                checkContractorsExists(currentRegistryId);
            },
        }));
    };

    const checkContractorsExists = (registryId, fetchList) => {
        const onConfirm = () => {
            if (edmSignWithoutSmsCodeEnable) {
                signRegistry({registryId, fetchList});

                return;
            }

            sendSms({registryId});
        };

        dispatch(checkContractorsExistsDocumentsEdoRegistries({
            clientId,
            registryId,
            getResult: ({success}) => {
                if (!success) {
                    setConfirmData({
                        content: "Внимание! В реестре найдены строки с отсутствующими на площадке исполнителями. В случае подтверждения операции этим исполнителям будет предложено зарегистрироваться на площадке для использования функционала электронного документооборота с Вашей компанией. Если Вы не хотите этого делать, отмените данную операцию, удалите строки с отсутствующими на площадке исполнителями из реестра и повторите операцию.",
                        onConfirm,
                    });

                    return;
                }

                onConfirm();
            },
        }));
    };

    const sendSms = ({registryId, codeType}) => {
        dispatch(sendSmsDocumentsEdoRegistries({
            clientId,
            registryId,
            phoneCodeId: RUSSIA_PHONE_CODE_ID,
            codeType,
            getResult: ({codeType}) => {
                setTimer(DEFAULT_TIME);
                setCurrentRegistryId(registryId);
                setCodeConfirmOpen(true);
                setCodeConfirmType(codeType);
            },
        }));
    };

    const signRegistry = ({
        registryId, fetchList = () => {
        },
    }) => {
        dispatch(signDocumentsEdoRegistries({
            clientId,
            registryId: registryId || currentRegistryId,
            smsCode: smsCode ? smsCode : undefined,
            onSuccess: () => {
                setCodeConfirmOpen(false);
                fetchList();
            },
        }));
    };

    const deleteNotCompletedItems = ({
        registryId, fetchList = () => {
        },
    }) => {
        dispatch(deleteNotCompletedDocumentsEdoRegistriesItems({
            clientId,
            registryId,
            onSuccess: () => {
                fetchList();
            },
        }));
    };

    return {
        headerMediaControls: getHeaderMediaControls(),
        archiveRegistries,
        archived,
        confirmData,
        setConfirmData,
        onCloseEditForm,
        isOpenEditForm,
        setIsOpenEditForm,
        editRegistryData,
        setEditRegistryData,
        dataDuplicate,
        setDataDuplicate,
        confirmDuplicatesItems,
        timer,
        sendSms,
        signRegistry,
        isCodeConfirmOpen,
        setCodeConfirmOpen,
        onChangeCode,
        openSignConfirm,
        setCurrentRegistryId,
        deleteNotCompletedItems,
        codeConfirmType,
    };
};