import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty,isEqual} from "lodash";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import Text from "../../../components/ActualComponents/Text";
import CardApp from "../../../components/CardApp";
import NmAccordion from "../../../components/NmAccordion";
import {
    checkArrivalNoticeCardDate,
    checkMigrationCardDate,
    checkPassportDate,
    checkPatentCardDate, checkVhiPolicyDate,
} from "./checkDateScanList";
import ContractorScanListOtherDocuments from "./components/other-documents";
import {Button, Dimmer, Loader} from "semantic-ui-react";

import {isShowParentDocumentScans} from "../../../utils/contractors";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {getPartObj} from "../../../utils/objectHelper";
import {isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError} from "../../../utils/toastHelper";
import {getContractorScanListFiles} from "./utils/getFiles";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {
    CONTRACTOR_FIELD,
    CONTRACTOR_STATUS,
    EDM_PASSPORT_TYPE,
} from "../../../constants/contractor";
import {CONTRACTOR_PASSPORT_TYPE,PASSPORT_BLOCK_FIELDS} from "../../../constants/contractorInfo";
import {PRELOADER_DOCUMENT_BASE64} from "../../../constants/document/documentContractorScan";
import {FILE_ERROR} from "../../../constants/messages";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_MANAGER, NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {updateContractor} from "../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {pendingPersonalDataSelector} from "../../../ducks/bff/contractor-сard/personal-data/selectors";
import {contractorCardInfoSelector} from "../../../ducks/bff/contractor-сard/selectors";
import {
    addOtherContractorFile,
    deleteOtherContractorFile,
    getContractorOtherFileList,
    getOtherContractorFile,
} from "../../../ducks/contractorOtherFiles";
import {
    contractorScanProgressSendSelector,
    sendContractorScans,
} from "../../../ducks/contractorScan";
import {downloadDocument} from "../../../ducks/documents";
import {
    addContractorFileScanFileToFileStore,
    addFileContractorAdditionalScan,
    clearFileStoreReduxStore,
    fileContractorOriginalScanObjSelector,
    fileContractorScanObjSelector,
    fileContractorScanProgressSelector,
    getFileContractorAdditionalScan,
    getFileContractorScan,
    updateContractorScanFieldFilesStore,
} from "../../../ducks/fileStore";

import PropTypes from "prop-types";

import "./style.sass";

class ContractorScanList extends Component {
    static propTypes = {
        getFileContractorScan: PropTypes.func,
        addContractorFileScanFileToFileStore: PropTypes.func,
        sendEmplyedScans: PropTypes.func,
        isEditableAlwaysDisabled: PropTypes.bool,
    };

    static defaultProps = {
        getFileContractorScan: () => {
        },
        addContractorFileScanFileToFileStore: () => {
        },
        clearFileStoreReduxStore: () => {
        },
        isEditableAlwaysDisabled: false,
    };

    constructor(props) {
        super();

        const {
            contractorId,
        } = props;

        this.contractorId = contractorId;

        this.state = {
            isScanPersonalInfoEdit: false,
            isScanRegistrationEdit: false,
            isScanMigrationCardEdit: false,
            isScanTemporaryHousingFirstEdit: false,
            isScanTemporaryHousingSecondEdit: false,
            showRegistrationFullScreen: false,
            showPersonalInfoFullScreen: false,
            showMigrationCardFullScreen: false,
            showTemporaryHousingFirstFullScreen: false,
            showTemporaryHousingSecondFullScreen: false,
            docScanInfo: [],
            docScanRegistration: [],
            docScanMigrationCard: [],
            docScanTemporaryHousingFirst: [],
            docScanTemporaryHousingSecond: [],
            progressScanPersonalInfo: true,
            progressScanRegInfo: true,
            progressScanMigrationCard: true,
            progressScanTemporaryHousingFirst: true,
            progressScanTemporaryHousingSecond: true,
            passportInfo: {},

            scanObj: {},
            scanCopyObj: {},
            scanFullScreenObj: {},
            isAllFilesChanged: false,
            scanErrors: {},
            scanWarnings: {},
        };

        this.role = ls(USER_ROLE);
    }

    static getDerivedStateFromProps(props, state) {
        const {contractor, isContractorInit, scanErrors, scanWarnings} = state;
        const {currentContractor} = props;

        if (
            !isContractorInit &&
            JSON.stringify(contractor) !== JSON.stringify(currentContractor) &&
            !isNullOrWhitespace(currentContractor) && !isNullOrWhitespace(currentContractor[CONTRACTOR_FIELD.CONTRACTOR_ID])
        ) {
            const contractor = {
                ...currentContractor,
                [CONTRACTOR_FIELD.BIRTH_DATE]: new Date(
                    currentContractor[CONTRACTOR_FIELD.BIRTH_DATE],
                ),
                [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: new Date(
                    currentContractor[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE],
                ),
            };

            return {
                ...state,
                isContractorInit: true,
                contractor,
                passportInfo: getPartObj(contractor, PASSPORT_BLOCK_FIELDS),
            };
        }

        if (
            JSON.stringify(contractor) !== JSON.stringify(currentContractor) &&
            !isNullOrWhitespace(currentContractor) && !isNullOrWhitespace(currentContractor[CONTRACTOR_FIELD.CONTRACTOR_ID])
        ) {
            const errorMessagePassport = checkPassportDate(currentContractor);
            const warningMessageMigrationCard = checkMigrationCardDate(currentContractor);
            const warningArrivalNoticeCard = checkArrivalNoticeCardDate(currentContractor);
            const errorMessagePatentCard = checkPatentCardDate(currentContractor);
            const warningMessageVhiPolicy = checkVhiPolicyDate(currentContractor);

            return {
                ...state,
                scanErrors: {
                    ...scanErrors,
                    ...errorMessagePassport,
                    ...errorMessagePatentCard,
                },
                scanWarnings: {
                    ...scanWarnings,
                    ...warningMessageMigrationCard,
                    ...warningArrivalNoticeCard,
                    ...warningMessageVhiPolicy,
                },
            };
        }

        return state;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            contractorScanObj,
        } = this.props;

        if (!isEqual(prevProps.contractorScanObj, contractorScanObj) && !isEmpty(contractorScanObj)) {
            this.setState({
                contractorScanObj,
                scanObj: getContractorScanListFiles(contractorScanObj),
            });
        }
    }

    componentDidMount() {
        this.fetchEployedOtherFileList();
    }

    componentWillUnmount() {
        this.props.clearFileStoreReduxStore();
    }

    get snilsPresent() {
        const {
            currentContractor: {
                snilsPresent,
            },
        } = this.props;

        return snilsPresent;
    }

    get isEditable() {
        const {
            currentContractor: {
                status,
                archived,
            },
            isEditableAlwaysDisabled,
        } = this.props;


        if (isEditableAlwaysDisabled || archived) {
            return false;
        }

        const role = ls(USER_ROLE);

        return [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) && CONTRACTOR_STATUS.BANK_CHECK !== status;
    }

    get isClientRole() {
        return [CLIENT_ADMIN, CLIENT_ACCOUNTANT, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, HR_MANAGER].includes(this.role);
    }

    isAdditionalScanType = (type) => {
        const {
            currentContractor: {
                passportType,
                edmPassportType,
            },
        } = this.props;

        const isInternalPassport = passportType === CONTRACTOR_PASSPORT_TYPE.INTERNAL_PASSPORT || edmPassportType === EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE;
        const isRu = this.citizenship === citizenshipsDict.RU.value;

        return (
            !isRu && (isInternalPassport && [CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA].includes(type) ||
                !isInternalPassport && [CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE, CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE].includes(type)) ||
            [CONTRACTOR_FILE_TYPES.PREVIOUS_PASSPORT, CONTRACTOR_FILE_TYPES.INN].includes(type)
        );
    };

    fetchEployedOtherFileList = () => {
        const {getContractorOtherFileList} = this.props;

        getContractorOtherFileList(this.contractorId);
    };

    fetchContractorOriginalScan = (contractorFileType) => {
        const {
            getFileContractorScan,
            getFileContractorAdditionalScan,
            hasPendingPersonalData,
        } = this.props;
        const contractorId = this.contractorId;

        if (this.isAdditionalScanType(contractorFileType)) {
            getFileContractorAdditionalScan({
                contractorId,
                contractorFileType,
                thumbnail: false,
                field: "contractorOriginalScanObj",
            });

            return;
        }

        getFileContractorScan({
            contractorId,
            contractorFileType,
            thumbnail: false,
            field: "contractorOriginalScanObj",
            isPending: true,
            includingPending: hasPendingPersonalData,
        });
    };

    downloadContractorOriginalScan = (contractorFileType) => {
        return () => {
            const {
                getFileContractorScan,
                getFileContractorAdditionalScan,
            } = this.props;
            const contractorId = this.contractorId;

            if (this.isAdditionalScanType(contractorFileType)) {
                getFileContractorAdditionalScan({
                    contractorId,
                    contractorFileType,
                    thumbnail: false,
                    field: "contractorOriginalScanObj",
                    isNeedDownload: true,
                });

                return;
            }

            getFileContractorScan({
                contractorId,
                contractorFileType,
                isPending: true,
                field: "contractorOriginalScanObj",
                isNeedDownload: true,
                includingPending: true,
                thumbnail: false,
            });
        };
    };

    getEdmPassportType = (type, oldEdmPassportType) => {
        if ([CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE, CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE].includes(type)) {
            return EDM_PASSPORT_TYPE.NEW_MODEL_PASSPORT.VALUE;
        }

        if ([CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA].includes(type)) {
            return EDM_PASSPORT_TYPE.OLD_MODEL_PASSPORT.VALUE;
        }

        return oldEdmPassportType;
    };

    uploadFile = type => {
        const {scanObj, [type]: oldBlockState} = this.state;

        const {
            updateContractorScanFieldFilesStore,
            addContractorFileScanFileToFileStore,
            addFileContractorAdditionalScan,
            getFileContractorScan,
            getFileContractorAdditionalScan,
            hasPendingPersonalData,
            updateContractor,
            currentContractor,
        } = this.props;

        const files = scanObj[type];


        const [file] = files;

        if (!file) {
            return;
        }

        if (file.size > 10000000) {
            toastError(FILE_ERROR.SCAN_SIZE);

            return;
        }

        const formData = new FormData();

        formData.append("file", file);

        const contractorId = this.contractorId;

        const contractorFileType = type;

        updateContractorScanFieldFilesStore(type, []);

        if (this.isAdditionalScanType(type)) {
            addFileContractorAdditionalScan({
                contractorId,
                contractorFileType,
                file: formData,
                onSuccess: () => {
                    getFileContractorAdditionalScan({
                        contractorId,
                        contractorFileType,
                        thumbnail: true,
                    });
                },
            });
        } else {
            addContractorFileScanFileToFileStore({
                contractorId,
                contractorFileType,
                file: formData,
                isGetAfter: false,
                onSuccess: () => {
                    getFileContractorScan({
                        contractorId,
                        contractorFileType,
                        isPending: true,
                        includingPending: hasPendingPersonalData,
                    });
                    getFileContractorScan({
                        contractorId,
                        contractorFileType,
                        thumbnail: false,
                        isPending: true,
                        includingPending: true,
                        field: "contractorOriginalScans",
                    });

                    updateContractor({
                        ...currentContractor,
                        edmPassportType: this.getEdmPassportType(type, currentContractor.edmPassportType),
                    });
                },
            });
        }

        this.setState(() => ({
            [type]: !oldBlockState,
        }));
    };


    sendScansToBank = () => {
        const {sendContractorScans} = this.props;

        sendContractorScans({
            contractorId: this.contractorId,
        });
    };

    renderButtonSendScans() {
        const {
            isProgressSendScansToBank,
            currentContractor: {scansIsBank},
            t,
        } = this.props;

        return (
            <div className="contractor-scan-container_send_button">
                <Button
                    compact
                    loading={isProgressSendScansToBank}
                    onClick={this.sendScansToBank}
                >
                    {t("contractor-document-scans.unload-to-bank-button")}
                </Button>
                <NmCheckboxV2
                    className="ml-15"
                    label={t("contractor-document-scans.unloading-at-bank-toggle")}
                    disabled
                    onChange={() => {
                    }}
                    checked={scansIsBank}
                />
            </div>
        );
    }

    isRussian = () => {
        const {passportInfo} = this.state;
        return passportInfo[CONTRACTOR_FIELD.CITIZENSHIP] === "RU";
    };

    get citizenship() {
        const {passportInfo} = this.state;
        return passportInfo[CONTRACTOR_FIELD.CITIZENSHIP];
    }

    get passportType() {
        const {
            passportInfo: {
                passportType,
            },
        } = this.state;

        return passportType;
    }

    isBelorussian = () => {
        const {passportInfo} = this.state;
        return passportInfo[CONTRACTOR_FIELD.CITIZENSHIP] === "BY";
    };

    toggleScanEdit = (block) => {
        return () => {
            const {[block]: oldBlockState, scanObj, scanCopyObj} = this.state;

            if (oldBlockState) {
                this.uploadFile(block);

                return;
            } else {
                scanCopyObj[block] = scanObj[block];
                this.setState({
                    scanCopyObj,
                });
            }

            this.setState(() => ({
                [block]: !oldBlockState,
            }));
        };
    };

    cancelScanEdit = (block) => {
        return () => {
            const {scanObj, scanCopyObj} = this.state;

            scanObj[block] = scanCopyObj[block];

            this.setState(prevState => ({
                ...prevState,
                [block]: false,
                scanObj,
            }));
        };
    };

    onDropScan = (files, block) => {
        const {scanObj} = this.state;

        scanObj[block] = files;

        this.setState(prevState => ({
            ...prevState,
            scanObj,
        }));
    };

    renderScanEdit(block) {
        const {t} = this.props;
        const {scanObj: {[block]: files}} = this.state;

        return (
            <DropzoneV2
                isImagePreview
                files={files}
                multiple={false}
                maxSize={10}
                accept=".bmp, .png, .jpeg, .jpg"
                format="*.bmp, *.png, *.jpeg, *.jpg"
                onChange={(files) => {
                    this.onDropScan(files, block);
                }}
                uploadBtnText={isEmpty(files) ? t("button.select-file") : t("button.replace")}
            />
        );
    }

    showFullScreenScan = (block) => {
        return () => {
            const {scanFullScreenObj} = this.state;
            !scanFullScreenObj[block] && this.fetchContractorOriginalScan(block);
            scanFullScreenObj[block] = !scanFullScreenObj[block];
            this.setState({scanFullScreenObj});
        };
    };

    renderScanReadOnly(block) {
        const {scanFullScreenObj} = this.state;

        const {contractorScanObj, contractorOriginalScanObj = {}, t} = this.props;
        const screenObj = scanFullScreenObj[block] ? contractorOriginalScanObj[block] : contractorScanObj[block];
        const {base64str = ""} = screenObj || {};

        return (
            <div className="contractor-scan-container">
                {isNullOrWhitespace(base64str) ? (
                    <span className="contractor-info-scans__no-upload">
                        {t("contractor-document-scans.file-not-uploaded")}
                    </span>
                ) : (
                    <div
                        className={
                            scanFullScreenObj[block] ? "contractor-scan-container_full " : ""
                        }
                        onClick={this.showFullScreenScan(block)}
                    >
                        <div
                            className={`${scanFullScreenObj[block] ? "flex flex-content-center" : "contractor-scan-container_full_img"}`}
                        >
                            <img
                                alt={t("contractor-document-scans.scan-alt")}
                                src={` data:image/jpeg;charset=utf-8;base64, ${base64str || PRELOADER_DOCUMENT_BASE64}`}
                                className={`${scanFullScreenObj[block] ? "" : "contractor-scan-container__img"}`}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderSimpleScanForm = (scanData) => {
        const {
            type: block,
            text: title,
        } = scanData;
        const {
            [block]: blockState,
            scanErrors,
            scanWarnings,
        } = this.state;
        const {
            pendingPersonalData: {
                data,
            },
        } = this.props;

        const {scanFullScreenObj} = this.state;

        const {pendingFileTypes = []} = data || {};

        let isChanged = pendingFileTypes.includes(block);

        if (block === CONTRACTOR_FILE_TYPES.INN) {
            isChanged = pendingFileTypes.includes(`${CONTRACTOR_FILE_TYPES.INN}_SCAN`);
        }

        const {contractorScanObj, contractorOriginalScanObj = {}} = this.props;
        const screenObj = scanFullScreenObj[block] ? contractorOriginalScanObj[block] : contractorScanObj[block];
        const {base64str = ""} = screenObj || {};

        const isScanNotLoaded = isNullOrWhitespace(base64str);

        return (
            <div className="contractor-info-scans__card-container col-16 col-md-8 col-xxl-16">
                <CardApp
                    isScanStyle
                    title={title}
                    error={!isScanNotLoaded && scanErrors[block]}
                    warning={!isScanNotLoaded && scanWarnings[block]}
                    className={`contractor-info-scans__card ${isChanged ? "contractor-info-scans__card_changed" : ""}`}
                    isEditable={this.isEditable}
                    downloadEnabled
                    onDownloadClick={this.downloadContractorOriginalScan(block)}
                    onClickIcon={this.toggleScanEdit(block)}
                    onClickCancelIcon={this.cancelScanEdit(block)}
                    isEdit={blockState}
                >
                    {blockState
                        ? this.renderScanEdit(block)
                        : this.renderScanReadOnly(block)}
                </CardApp>
            </div>
        );
    };

    renderRussianBlock() {
        if (![citizenshipsDict.RU.value].includes(this.citizenship)) {
            return null;
        }

        const scanBlocks = [
            {
                type: CONTRACTOR_FILE_TYPES.SCAN_PERSONAL_INFO,
                text: "Персональная информация",
            },
            {
                type: CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION,
                text: "Регистрация",
            },
            {
                type: CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN,
                text: "Скан СНИЛСа",
            },
        ];

        const _filteredScanBlocks = this.snilsPresent
            ? scanBlocks : scanBlocks.filter(({type}) => type !== CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN);

        return _filteredScanBlocks.flat().map((value) => this.renderSimpleScanForm(value));
    }

    renderParentsDocumentsBlock() {
        const {
            currentContractor,
        } = this.props;

        if (!isShowParentDocumentScans(currentContractor, this.role)) {
            return null;
        }

        const scanBlocks = [
            {
                type: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
                text: "Согласие на совершение сделок несовершеннолетними",
            },
            {
                type: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA,
                text: "Согласие на обработку персональных данных родителя",
            },
        ];

        return scanBlocks.flat().map((value) => this.renderSimpleScanForm(value));
    }

    renderTjAndUzScan() {
        if (![citizenshipsDict.TJ.value, citizenshipsDict.UA.value, citizenshipsDict.UZ.value].includes(this.citizenship)) {
            return null;
        }

        const scanBlocks = [
            {
                type: CONTRACTOR_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN,
                text: "Скан трудовой книжки",
            },
            {
                type: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN,
                text: "Скан патента, 1 сторона",
            },
            {
                type: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN,
                text: "Скан патента, 2 сторона",
            },
            {
                type: CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN,
                text: "Скан чека об оплате патента",
            },
        ];

        const {
            currentContractor: {
                employmentHistoryPresent,
            },
        } = this.props;

        const _filteredScanBlocks = employmentHistoryPresent
            ? scanBlocks : scanBlocks.filter(({type}) => type !== CONTRACTOR_FILE_TYPES.EMPLOYMENT_HISTORY_SPREAD_SCAN);

        return _filteredScanBlocks.flat().map((value) => this.renderSimpleScanForm(value));
    }

    renderBelorussianBlock() {
        if (![citizenshipsDict.BY.value].includes(this.citizenship)) {
            return null;
        }

        const {
            t,
            currentContractor,
        } = this.props;

        // Тип документа о прибытии - Вид на жительство
        const isResidence = currentContractor.arrivalDocType === CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT;

        const scanBlocksBasic = [
            {
                type: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                text: t("contractor-document-scans.foreign-passport-scan-personal-information"),
            },
            {
                type: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA_BIRTH_PLACE_PAGE,
                text: t("contractor-document-scans.foreign-passport-scan-registration"),
            },
            {
                type: CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN,
                text: "Скан СНИЛСа",
            },
        ];

        const residenceScan = [{
            type: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
            text: t("contractor-document-scans.residence-permit"),
        }, {
            type: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT_ADDRESS,
            text: "Скан страницы с адресом места жительства",
        }];

        const notificationsScans = [
            {
                type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
                text: t("contractor-document-scans.VP-scan-first-side"),
            },
            {
                type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
                text: t("contractor-document-scans.VP-scan-second-side"),
            },
        ];

        const scanBlockFull = [
            ...scanBlocksBasic,
            isResidence ? residenceScan : notificationsScans,
        ];

        const filteredScanBlockFull = this.snilsPresent
            ? scanBlockFull : scanBlockFull.filter(({type}) => type !== CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN);

        return filteredScanBlockFull.flat().map((value) => this.renderSimpleScanForm(value));
    }

    renderOtherNationBlock() {
        if ([citizenshipsDict.BY.value, citizenshipsDict.RU.value].includes(this.citizenship)) {
            return null;
        }

        const {
            t,
            currentContractor: {
                arrivalDocType,
            },
        } = this.props;

        // Тип документа о прибытии - Вид на жительство
        const isResidencePermit = arrivalDocType === CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT;

        const passportScans = [
            {
                type: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_FRONT_SIDE,
                text: t("contractor-document-scans.internal-pasport-first-side"),
            },
            {
                type: CONTRACTOR_FILE_TYPES.INTERNAL_PERSONAL_DATA_BACK_SIDE,
                text: t("contractor-document-scans.internal-pasport-second-side"),
            },
            {
                type: CONTRACTOR_FILE_TYPES.INTERNATIONAL_PERSONAL_DATA,
                text: t("contractor-document-scans.foreign-passport-scan-personal-information"),
            },
        ];

        const arrivalScans = isResidencePermit
            ? [
                {
                    type: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
                    text: t("contractor-document-scans.residence-permit"),
                },
                {
                    type: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT_ADDRESS,
                    text: "Скан страницы с адресом места жительства",
                },
            ]
            : [
                {
                    type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_FIRST,
                    text: t("contractor-document-scans.VP-scan-first-side"),
                },
                {
                    type: CONTRACTOR_FILE_TYPES.NOTIFICATION_OF_ARRIVAL_SECOND,
                    text: t("contractor-document-scans.VP-scan-second-side"),
                },
            ];

        const scans = [
            ...passportScans,
            {
                type: CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD,
                text: "Скан миграционной карты, лицевая сторона",
            },
            {
                type: CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
                text: "Скан миграционной карты, обратная сторона",
            },
            ...arrivalScans,
            {
                type: CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN,
                text: "Скан СНИЛСа",
            },
        ];

        const _filteredScanBlocks = this.snilsPresent
            ? scans : scans.filter(({type}) => type !== CONTRACTOR_FILE_TYPES.SNILS_FRONT_SIDE_SCAN);

        return _filteredScanBlocks.flat().map((value) => this.renderSimpleScanForm(value));
    }

    get classNameCard() {
        const {isAllFilesChanged} = this.state;

        const className = "contractor-info-scans__general";

        return isAllFilesChanged ? `${className} contractor-info-scans__general_border` : className;
    }

    renderSelfieScanBlock() {
        if (this.isClientRole) {
            return null;
        }

        const scanBlocks = [
            {
                type: CONTRACTOR_FILE_TYPES.SELFIE_CHECK_SCAN,
                text: "Селфи с паспортом",
            },
        ];

        return scanBlocks.flat().map((value) => this.renderSimpleScanForm(value));
    };

    renderPreviousPersonalDataScanBlock() {
        if (![citizenshipsDict.RU.value].includes(this.citizenship)) {
            return null;
        }

        return this.renderSimpleScanForm({
            type: CONTRACTOR_FILE_TYPES.PREVIOUS_PASSPORT,
            text: "Сведения о ранее выданных паспортах",
        });
    };

    renderCertificateInnScanBlock() {
        return this.renderSimpleScanForm({
            type: CONTRACTOR_FILE_TYPES.INN,
            text: "Свидетельство о постановке на учет физического лица в налоговом органе (ИНН)",
        });
    };

    renderVhiPolicyScanBlock() {
        if ([citizenshipsDict.BY.value, citizenshipsDict.RU.value].includes(this.citizenship)) {
            return null;
        }

        return this.renderSimpleScanForm({
            type: CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
            text: "Скан договора ДМС или полиса ДМС",
        });
    };

    render() {
        const {
            progressGet,
            t,
        } = this.props;

        if (progressGet) {
            return (
                <Dimmer
                    active={progressGet}
                    inverted
                >
                    <Loader content={t("loader.content")} />
                </Dimmer>
            );
        }

        return (
            <div className="contractor-info-scans">
                <NmAccordion
                    className={this.classNameCard}
                    defaultOpen={true}
                    classNameIcon="contractor-info-scans__accordion-icon"
                    name={
                        <Text.Title
                            level="3"
                            color={COLOR.SECONDARY_100}
                        >
                            Сканы документов
                        </Text.Title>
                    }
                >
                    <div className="row">
                        {this.renderRussianBlock()}
                        {this.renderOtherNationBlock()}
                        {this.renderTjAndUzScan()}
                        {this.renderVhiPolicyScanBlock()}
                        {this.renderBelorussianBlock()}
                        {this.renderParentsDocumentsBlock()}
                        {this.renderSelfieScanBlock()}
                        {this.renderPreviousPersonalDataScanBlock()}
                        {this.renderCertificateInnScanBlock()}
                    </div>
                </NmAccordion>
                {
                    !this.isClientRole &&
                    <ContractorScanListOtherDocuments
                        className="mt-4 mt-xxl-5"
                        contractorId={this.contractorId}
                        isEditable={this.isEditable}
                    />
                }
            </div>
        );
    }
}

export default connect(
    state => ({
        contractorScanObj: fileContractorScanObjSelector(state),
        contractorOriginalScanObj: fileContractorOriginalScanObjSelector(state),
        isProgressSendScansToBank: contractorScanProgressSendSelector(state),
        progressGet: fileContractorScanProgressSelector(state),
        currentContractor: contractorCardInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        pendingPersonalData: pendingPersonalDataSelector(state),
    }),
    {
        getFileContractorScan,
        getFileContractorAdditionalScan,
        addContractorFileScanFileToFileStore,
        addFileContractorAdditionalScan,
        sendContractorScans,
        clearFileStoreReduxStore,
        getContractorOtherFileList,
        addOtherContractorFile,
        getOtherContractorFile,
        deleteOtherContractorFile,
        updateContractorScanFieldFilesStore,
        downloadDocument,
        updateContractor,
    },
)(withTranslation()(ContractorScanList));
