import {isString} from "lodash";

import {RECRUITMENT_VACANCY_REGISTRATION_STATUS} from "../../../../constants/recruitment";

import {COMMON_ALL_OPTION} from "../../../../constants/common";

export const getRecruitmentRegistrationStatuses = (registrationStatuses) => {
    if (registrationStatuses === COMMON_ALL_OPTION.value) {
        return [
            RECRUITMENT_VACANCY_REGISTRATION_STATUS.BASIC_REGISTRATION,
            RECRUITMENT_VACANCY_REGISTRATION_STATUS.FULL_REGISTRATION,
        ];
    }

    if (isString(registrationStatuses)) {
        return [registrationStatuses];
    }

    return registrationStatuses;
};