import {
    ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST,
    ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST,
    ADD_PATENTS_PAYMENT_REQUEST,
    DECLINE_PATENT_PAYMENT_REQUEST,
    DELETE_PATENTS_PAYMENT_REQUEST,
    GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST,
    PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
    PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST,
    PATENTS_PAYMENTS_LIST_REQUEST,
    PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST,
    PATENTS_PAYMENTS_UPDATE_STORE,
    REFRESH_PATENT_PAYMENT_STATUS_REQUEST,
    UPDATE_PATENTS_PAYMENT_REQUEST,
} from "./actions";

export function getPatentsPayments(payload) {
    return {
        type: PATENTS_PAYMENTS_LIST_REQUEST,
        payload,
    };
}

export function addPatentsPayment(payload) {
    return {
        type: ADD_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function updatePatentsPayment(payload) {
    return {
        type: UPDATE_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function addPatentsPaymentsFromFile(payload) {
    return {
        type: ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST,
        payload,
    };
}

export function deletePatentsPayment(payload) {
    return {
        type: DELETE_PATENTS_PAYMENT_REQUEST,
        payload,
    };
}

export function getPaymentStatusDict() {
    return {
        type: GET_PATENTS_PAYMENT_STATUS_DICT_REQUEST,
    };
}

export function getPatentsPaymentsAdminPage(payload) {
    return {
        type: PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST,
        payload,
    };
}

export function getPatentsPaymentsContractorPage(payload) {
    return {
        type: PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST,
        payload,
    };
}

export function refreshPatentPaymentStatus(payload) {
    return {
        type: REFRESH_PATENT_PAYMENT_STATUS_REQUEST,
        payload,
    };
}

export function declinePatentPayment(payload) {
    return {
        type: DECLINE_PATENT_PAYMENT_REQUEST,
        payload,
    };
}

export function getPatentPaymentsDepositPage(payload) {
    return {
        type: PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST,
        payload,
    };
}

export function getPatentPaymentsTotalAmount(payload) {
    return {
        type: PATENTS_PAYMENTS_TOTAL_AMOUNT_REQUEST,
        payload,
    };
}

export function addPatentsRegistryPaymentsDocumentsExport(payload) {
    return {
        type: ADD_DOCUMENTS_EXPORT_PATENTS_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
}

export function getPatentsAvailableForPayments(payload) {
    return {
        type: PATENTS_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
        payload,
    };
}

export function updatePatentPaymentsStore(payload) {
    return {
        type: PATENTS_PAYMENTS_UPDATE_STORE,
        payload,
    };
}