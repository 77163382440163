import {
    BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE,
    BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST,
    BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE,
} from "./actions";

export const getRegistryPayments = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const updateRegistryPayment = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_REQUEST,
        payload,
    };
};


export const cancelRegistryPayment = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_CANCEL_PAYMENT_REQUEST,
        payload,
    };
};


export const refreshRegistryPaymentBankCache = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_REFRESH_BANK_CACHE_REQUEST,
        payload,
    };
};


export const payRegistryPayment = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_PAY_REQUEST,
        payload,
    };
};


export const findRegistryPaymentBankCheckList = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_FIND_BANK_CHECK_LIST_REQUEST,
        payload,
    };
};

export const getRegistryPaymentCard = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CARD_REQUEST,
        payload,
    };
};

export const clearRegistryPaymentsStore = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_CLEAR_STORE,
        payload,
    };
};

export const isRegistryPaymentFrameContractSigned = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_IS_FRAME_CONTRACT_SIGNED_REQUEST,
        payload,
    };
};

export const getRegistryPaymentContractor = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_GET_CONTRACTOR_REQUEST,
        payload,
    };
};

export const searchRegistryPaymentContractors = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_SEARCH_CONTRACTOR_REQUEST,
        payload,
    };
};

export const updateRegistryPaymentsStore = (payload) => {
    return {
        type: BFF_FINANCES_REGISTRY_PAYMENTS_UPDATE_STORE,
        payload,
    };
};