import {isEmpty} from "lodash";

import {getRecruitmentRegistrationStatuses} from "./getRegistrationStatuses";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString, handleNumber} from "../../../../utils/stringHelper";

import {COMMON_ALL_OPTION} from "../../../../constants/common";

export const getRecruitmentVacancyCardFilterRequestData = (filter, isResponsesPage) => {
    const generalFilters = {
        name: handleFormString(filter.name),
        phone: filter.phone ? removePhoneMask(filter.phone) : undefined,
        email: handleFormString(filter.email),
        ageFrom: handleNumber(filter.ageFrom),
        ageTo: handleNumber(filter.ageTo),
        gender: filter.gender === COMMON_ALL_OPTION.value ? undefined : handleFormString(filter.gender),
        specialityIds: !isEmpty(filter.specialityIds) ? filter.specialityIds : undefined,
        citizenships: !isEmpty(filter.citizenships) ? filter.citizenships : undefined,
        addressFiasIds: !isEmpty(filter.addressFiasIds) ? filter.addressFiasIds : undefined,
        authorsAdditionsToVacancy: !isEmpty(filter.authorsAdditionsToVacancy) ? filter.authorsAdditionsToVacancy : undefined,
    };

    if (isResponsesPage) {
        return {
            dateFrom: getStartFilterDateWithTimeInUtc(filter.dateFrom),
            dateTo: getEndFilterDateWithTimeInUtc(filter.dateTo),
            ...generalFilters,
            responseSources: filter.responseSources?.length ? filter.responseSources : undefined,
        };
    }

    return {
        dateFrom: getStartFilterDateWithTimeInUtc(filter.dateFrom),
        dateTo: getEndFilterDateWithTimeInUtc(filter.dateTo),
        registrationStatuses: getRecruitmentRegistrationStatuses(filter.registrationStatuses),
        ...generalFilters,
    };
};