import React from "react";

import {RECRUITMENT_REGISTRATION_STATUS_DICT} from "../../../constants/recruitment";
import ExtLink from "../../ExtLink";

import {formatLocalDate} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";

import {LINK_CONTRACTOR_PROFILE} from "../../../constants/links";
import {RECRUITMENT_OBSERVER} from "../../../constants/roles";

export const RecruitmentCandidateContractorAvailableIndicator = (props) => {
    const {
        contractorId,
        contractorName,
        registrationStatus,
        registrationDate,
    } = props;

    const isExistsContractor = registrationStatus && registrationDate;

    if (!isExistsContractor) {
        return "Нет";
    }

    const role = ls(USER_ROLE);

    if (role === RECRUITMENT_OBSERVER) {
        return contractorName;
    }

    const date = registrationDate ? formatLocalDate(registrationDate, "dd.MM.yyyy HH:mm") : "";
    const link = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);
    const text = `Да (${RECRUITMENT_REGISTRATION_STATUS_DICT[registrationStatus]} ${date})`;

    return (
        <ExtLink
            historyEnabled
            to={link}
            title={`Перейти в карточку исполнителя ${contractorName}`}
        >
            {text}
        </ExtLink>
    );
};