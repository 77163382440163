import {
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST,
    BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST,
    BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST,
    BFF_RUN_REGISTRY_INVITATIONS_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST,
} from "./actions";

export function getRegistryInvitationsById(payload) {
    return {
        type: BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST,
        payload,
    };
}

export function runRegistryInvitations(payload) {
    return {
        type: BFF_RUN_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function checkValidResidentRegistryInvitations(payload) {
    return {
        type: BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function deleteContractorsFromRegistryInvitations(payload) {
    return {
        type: BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function importRegistryInvitationsContractors(payload) {
    return {
        type: BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST,
        payload,
    };
}

export function runRegistryInvitationsItem(payload) {
    return {
        type: BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST,
        payload,
    };
}

export function getRegistryInvitationsContractorsPage(payload) {
    return {
        type: BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST,
        payload,
    };
}

export function addContractorToRegistryInvitations(payload) {
    return {
        type: BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST,
        payload,
    };
}

export function updateRegistryInvitationsContractor(payload) {
    return {
        type: BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST,
        payload,
    };
}

export function addTaskOrderInvitationsRegistryExport(payload) {
    return {
        type: BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST,
        payload,
    };
}