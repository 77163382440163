import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";
import {useKedoDepartmentDeputyHead} from "./useDepartmentDeputyHead";
import {useKedoDepartmentHead} from "./useDepartmentHead";

import {useKedoDepartments} from "../../../../components/KedoDepartmentsDropdown/hooks/useDepartments";
import {useFilter} from "../../../../hooks/useFilter";

import {filterEmptyValues} from "../../../../utils/objectHelper";

import {kedoDepartmentsRootSelector} from "../../../../ducks/kedo/departments/selectors";

export const useKedoOrganizationStructureFilters = (params) => {
    const {
        pageSize,
        setPagination,
        clientId,
        kedoDepartmentId,
    } = params;

    const {rootDepartmentId} = useSelector(kedoDepartmentsRootSelector);

    const headDropdownProps = useKedoDepartmentHead({
        clientId,
    });
    const deputyHeadDropdownProps = useKedoDepartmentDeputyHead({
        clientId,
    });
    const departmentsDropdownProps = useKedoDepartments({
        clientId,
        parentDepartmentId: kedoDepartmentId || rootDepartmentId,
        isRootDepartment: true,
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        search: true,
                        multiple: true,
                        name: "kedoDepartmentIds",
                        isMountLoad: false,
                        ...departmentsDropdownProps,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...headDropdownProps,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        ...deputyHeadDropdownProps,
                    },
                ],
            },
        ];
    }, [
        headDropdownProps.options,
        deputyHeadDropdownProps.options,
        departmentsDropdownProps.options,
    ]);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        mapFilterDto: filterEmptyValues,
        pageSize,
        setPagination,
        filters,
        defaultOpenFilter: true,
    });

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    };
};