import {all, call, put, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {getMaintenanceNoticeSettings} from "./actionCreators";
import {
    DELETE_EMPTY_RECORD_ERROR,
    DELETE_EMPTY_RECORD_REQUEST,
    DELETE_EMPTY_RECORD_SUCCESS,
    GET_AUTH_MAINTENANCE_NOTICE_ERROR,
    GET_AUTH_MAINTENANCE_NOTICE_REQUEST,
    GET_AUTH_MAINTENANCE_NOTICE_SUCCESS,
    GET_EMPTY_RECORD_ERROR,
    GET_EMPTY_RECORD_REQUEST,
    GET_EMPTY_RECORD_SUCCESS,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS,
    SYSTEM_SETTING_GET_ERROR,
    SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_ERROR,
    SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_REQUEST,
    SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_SUCCESS,
    SYSTEM_SETTING_GET_REQUEST,
    SYSTEM_SETTING_GET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_ERROR,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_ERROR,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_SET_ERROR,
    SYSTEM_SETTING_SET_REQUEST,
    SYSTEM_SETTING_SET_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

const controller = "/adm/settings/service/settings";

const getMaintenanceNoticeSettingsSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${controller}/maintenance-notice/get`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS,
            payload: {
                ...result,
                type: params.type,
            },
        });
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_ERROR, payload: error});
    }
};

const setMaintenanceNoticeSettings = function* ({payload}) {
    const {
        getResult,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/maintenance-notice/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult();

        toastSuccess("Параметры публикации уведомления успешно обновлены");

        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS});
        yield put(getMaintenanceNoticeSettings({
            type: payload.type,
        }));
    } catch (error) {
        yield put({type: SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR, payload: error});
    }
};

const getAuthMaintenanceNoticeSaga = function* () {
    try {
        const result = yield call(axios, {
            url: "/nmapi/public/auth-maintenance-notice/get",
            method: "get",
            params: {
                type: "CLIENTS",
            },
        });

        const {errorMessage} = result.data;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_AUTH_MAINTENANCE_NOTICE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_AUTH_MAINTENANCE_NOTICE_SUCCESS,
            payload: result.data,
        });
    } catch (error) {
        yield put({
            type: GET_AUTH_MAINTENANCE_NOTICE_ERROR,
            payload: error,
        });
    }
};

const setSystemSetting = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/system-setting/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SYSTEM_SETTING_SET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_SET_ERROR, payload: error});
    }
};

const setExpasoftCollingSetting = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/expasoft-calling/set`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR, payload: error});
    }
};

const getSystemSetting = function* ({payload}) {
    try {
        const {getResult, key} = payload;

        const result = yield request.bff.get(`${controller}/system-setting/get`, {params: {key}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: SYSTEM_SETTING_GET_SUCCESS});
    } catch (error) {
        yield put({type: SYSTEM_SETTING_GET_ERROR, payload: error});
    }
};

const getEmptyRecord = function* ({payload}) {
    try {
        const {
            contractorPhone,
            getResult,
        } = payload;
        const result = yield request.bff.get(`${controller}/contractors/get-empty-record`, {params: {contractorPhone}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

const deleteEmptyRecord = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${controller}/contractors/delete-empty-record?contractorId=${contractorId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_EMPTY_RECORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_EMPTY_RECORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_EMPTY_RECORD_ERROR,
            payload: error,
        });
    }
};

const correctSettingNpdDeposit = function* ({payload}) {
    try {
        const {
            clientId,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${controller}/client-deposit/correct/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_ERROR,
            payload: error,
        });
    }
};

const correctSettingNdflDeposit = function* ({payload}) {
    try {
        const {
            clientId,
            getResult,
        } = payload;

        const result = yield request.bff.post(`${controller}/client-civil-deposit/correct/${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/settings/service/settings/maintenance-notice/is-published
// Для отображения тех. уведомления
const isSystemSettingsMaintenanceNoticePublished = function* ({payload}) {
    const {
        getResult,
    } = payload;

    try {
        const result = yield request.bff.post(`${controller}/is-published`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_REQUEST, getMaintenanceNoticeSettingsSaga),
        takeEvery(SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST, setMaintenanceNoticeSettings),
        takeEvery(GET_AUTH_MAINTENANCE_NOTICE_REQUEST, getAuthMaintenanceNoticeSaga),
        takeEvery(SYSTEM_SETTING_SET_REQUEST, setSystemSetting),
        takeEvery(SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST, setExpasoftCollingSetting),
        takeEvery(SYSTEM_SETTING_GET_REQUEST, getSystemSetting),
        takeEvery(GET_EMPTY_RECORD_REQUEST, getEmptyRecord),
        takeEvery(DELETE_EMPTY_RECORD_REQUEST, deleteEmptyRecord),
        takeEvery(SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST, correctSettingNpdDeposit),
        takeEvery(SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST, correctSettingNdflDeposit),
        takeEvery(SYSTEM_SETTING_GET_MAINTENANCE_NOTICE_REQUEST, isSystemSettingsMaintenanceNoticePublished),
    ]);
}