import React from "react";
import {useMedia} from "react-media";
import {useSelector} from "react-redux";

import ChatDisputeHeader from "../dispute-header";
import ChatSimpleHeader from "../simple-header";

import bem from "../../../../utils/bem";

import {disputeSelector} from "../../../../ducks/bff/disputes/selectors";
import {chatSelector} from "../../../../ducks/chatList";

import "./style.sass";

const ChatHeader = (props) => {
    const {
        isSimpleChat,
        onClickBackToChatList,
        currentClientUserId,
        showDisputeInfo,
        handleOnChangeDisputeChat,
        sendLockUserChat,
        isOpenChat,
        fullName,
        role,
        showApprovedBlock,
    } = props;
    const [block, element] = bem("chat-header");
    const dispute = useSelector(disputeSelector);
    const chat = useSelector(chatSelector);
    const {mobile, tablet, desktop} = useMedia({
        queries: {
            mobile: {maxWidth: 767},
            tablet: {minWidth: 768, maxWidth: 1919},
            desktop: {minWidth: 1920},
        },
    });

    return (
        <div className={block()}>
            <div className={element("container")}>
                {
                    isSimpleChat ?
                        <ChatSimpleHeader
                            fullName={fullName}
                            onClickBackToChatList={onClickBackToChatList}
                            mobile={mobile}
                            isOpenChat={isOpenChat}
                            chat={chat}
                            desktop={desktop}
                            sendLockUserChat={sendLockUserChat}
                        /> :
                        <ChatDisputeHeader
                            fullName={fullName}
                            onClickBackToChatList={onClickBackToChatList}
                            mobile={mobile}
                            isOpenChat={isOpenChat}
                            showDisputeInfo={showDisputeInfo}
                            dispute={dispute}
                            currentClientUserId={currentClientUserId}
                            role={role}
                            desktop={desktop}
                            handleOnChangeDisputeChat={handleOnChangeDisputeChat}
                            showApprovedBlock={showApprovedBlock}
                        />
                }
            </div>
        </div>
    );
};

export default ChatHeader;