import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../../../components/ActualComponents/HelpTooltip";
import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import AmountInput from "../../../../../../components/AmountInput";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import ContractorEditPaymentInfo from "../../../../../../components/ContractorEditPaymentInfo";
import NmRatingFieldsForm from "../../../../../../components/NmRatingFieldsForm";
import NmTitle from "../../../../../../components/NmTitle";

import {useOrderActRegistryEditPaymentAction} from "./hooks/useAction";
import {useOrderActRegistryEditPaymentFetch} from "./hooks/useFetch";
import {useOrderActRegistryEditPaymentPrompt} from "./hooks/usePrompt";

import {getDateObject} from "../../../../../../utils/dateFormat";

import {ORDER_KIND} from "../../../../../../constants/order";
import {ORDER_ACT_REGISTRY_EDIT_PAYMENT_LS_KEY} from "./constants";

import {
    clientCardPropertiesSelector,
    clientCurrentDataSelector,
} from "../../../../../../ducks/bff/clients/info/selectors";
import {bffContractorCardSelector} from "../../../../../../ducks/bff/contractors/registry/selectors";
import {
    actRegistriesCardSelector,
    actRegistryPaymentProgressActionSelector,
    actRegistryPaymentProgressSelector,
} from "../../../../../../ducks/bff/orders/order-act-registries/card/selectors";

export const OrderActRegistryCardEditPayment = (props) => {
    const {
        editData,
        contractorData: {
            contractorId,
        },
        clientId,
        registryId,
        onSetStep,
        prevStep,
        onClose,
        fetchList,
    } = props;

    const isEdit = !isEmpty(editData);
    const {t} = useTranslation();

    const clientProperties = useSelector(clientCardPropertiesSelector);
    const progressCard = useSelector(actRegistryPaymentProgressSelector);
    const progressAction = useSelector(actRegistryPaymentProgressActionSelector);
    const {nmContractDateConclusion} = useSelector(clientCurrentDataSelector);
    const contractor = useSelector(bffContractorCardSelector);
    const registryCard = useSelector(actRegistriesCardSelector);
    // Настройка даты акта
    const {actDateAvailable} = clientProperties;

    const isEditRealContractor = Boolean(contractorId);

    const {
        contractorsPossibility,
    } = useOrderActRegistryEditPaymentFetch({contractorId, isEditRealContractor, editData});

    const {
        handleSubmit,
        values,
        errors,
        touched,
        estimates,
        onChangeFields,
        onGoBack,
    } = useOrderActRegistryEditPaymentAction({
        clientId,
        registryId,
        contractorId,
        registryCard,
        editData,
        onSetStep,
        fetchList,
        prevStep,
        onClose,
    });

    const {
        isPromptOn,
        onOpenPrompt,
    } = useOrderActRegistryEditPaymentPrompt({key: ORDER_ACT_REGISTRY_EDIT_PAYMENT_LS_KEY});

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {
                        isEdit ?
                            "Редактирование параметров оплаты для исполнителя" :
                            "Добавление параметров оплаты для исполнителя"
                    }
                </NmTitle>
            }
            lsKeyEditPrompt={ORDER_ACT_REGISTRY_EDIT_PAYMENT_LS_KEY}
            onChangePrompt={onOpenPrompt}
            footer={
                <ApplyButtons
                    align="right"
                    mobile="column"
                    isHiddenCancelOnMobile
                    disabled={progressAction}
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent={t("button.cancel")}
                    submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                    additionalBtnContent={!isEdit && "Назад"}
                    onClickAdditional={onGoBack}
                />
            }
            onClose={onClose}
            loading={progressCard}
        >
            <NmForm addMargin>
                <ContractorEditPaymentInfo
                    contractor={contractor}
                    contractorId={contractorId}
                    contractorsPossibility={contractorsPossibility}
                />
                <NmInputV2
                    required
                    size="xl"
                    mask="99999999999999"
                    maskChar={null}
                    label="Номер заказа"
                    placeholder="Введите номер заказа"
                    name="orderNumber"
                    value={values.orderNumber}
                    onChange={onChangeFields}
                    error={touched.orderNumber && errors.orderNumber}
                />
                <NmTextareaV2
                    required
                    maxRows={3}
                    minRows={2}
                    maxLength={5000}
                    label="Описание выполненных работ для акта"
                    placeholder="Введите описание выполненных работ для акта"
                    error={touched.orderDescription && errors.orderDescription}
                    name="orderDescription"
                    onChange={onChangeFields}
                    value={values.orderDescription}
                    isVisibleTooltip={isPromptOn}
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            children="Описание будет отображаться в акте выполненных работ"
                            position="bottom-left"
                        />
                    }
                />
                <NmTextareaV2
                    required
                    size="xl"
                    maxRows={3}
                    minRows={2}
                    maxLength={160}
                    label="Описание выполненных работ для чека ФНС России"
                    placeholder="Введите описание выполненных работ для чека ФНС России"
                    error={touched.orderDescriptionFns && errors.orderDescriptionFns}
                    name="orderDescriptionFns"
                    onChange={onChangeFields}
                    value={values.orderDescriptionFns}
                    isVisibleTooltip={isPromptOn}
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            children="Описание будет отображаться в чеке ФНС России"
                            position="bottom-left"
                        />
                    }
                />
                <NmTextareaV2
                    maxRows={3}
                    minRows={2}
                    maxLength={5000}
                    label="Комментарий"
                    placeholder="Комментарий"
                    name="comment"
                    error={touched.comment && errors.comment}
                    onChange={onChangeFields}
                    value={values.comment}
                />
                <NmDateRangePickerV2
                    required
                    size="xl"
                    label="Период работ"
                    minDate={{
                        start: getDateObject(nmContractDateConclusion),
                        end: values.orderWorkStartDate || getDateObject(nmContractDateConclusion),
                    }}
                    error={(touched.orderWorkStartDate || touched.orderWorkEndDate) && (errors.orderWorkStartDate || errors.orderWorkEndDate)}
                    startFieldName="orderWorkStartDate"
                    endFieldName="orderWorkEndDate"
                    value={{
                        orderWorkStartDate: values.orderWorkStartDate,
                        orderWorkEndDate: values.orderWorkEndDate,
                    }}
                    maxDate={{
                        end: new Date(),
                    }}
                    onChange={onChangeFields}
                    returnString={false}
                    isVisibleTooltip={isPromptOn}
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            children="Укажите дату начала и окончания работ по заказу"
                            position="bottom-left"
                        />
                    }
                    isClearable
                />
                <AmountInput
                    required
                    addZeroAfterDivider
                    newInput
                    size="xl"
                    error={touched.sumOrAmount && errors.sumOrAmount}
                    name="sumOrAmount"
                    label={
                        registryCard.actOrderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER ?
                            "Объем работ, ед.из" : "Сумма, ₽"
                    }
                    placeholder="0,00"
                    value={values.sumOrAmount}
                    onChange={onChangeFields}
                    isVisibleTooltip={isPromptOn}
                    tooltip={
                        <HelpTooltip
                            hover
                            info
                            children="GROSS (комиссию платформы и налоги платит исполнитель)"
                            position="bottom-left"
                        />
                    }
                />
                {
                    clientProperties.amountForReport &&
                    <AmountInput
                        newInput
                        size="xl"
                        error={touched.amountForReport && errors.amountForReport}
                        name="amountForReport"
                        label="Сумма для отчёта, ₽"
                        placeholder="0,00"
                        value={values.amountForReport}
                        onChange={onChangeFields}
                    />
                }
                {
                    actDateAvailable &&
                    <NmDatePicker
                        readOnlyInput={false}
                        dateFormat="dd.MM.yy"
                        name="actDate"
                        error={touched.actDate && errors.actDate}
                        selected={values.actDate}
                        onChange={onChangeFields}
                        isClearable
                        label="Дата акта"
                    />
                }
                <div className="fluid">
                    <NmRatingFieldsForm
                        handleOnRate={onChangeFields}
                    >
                        {estimates}
                    </NmRatingFieldsForm>
                </div>
            </NmForm>
        </NmModal>
    );
};

export default OrderActRegistryCardEditPayment;