import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {InstructionsPage} from "../page";

import {USER_INSTRUCTION_TYPE_DICT} from "../../../../constants/dicts";

import {
    addNaimixInstructionFile, clearNaimixInstructionStore,
    getNaimixInstructions,
    saveNaimixInstruction,
} from "../../../../ducks/bff/settings/instructions/naimix/actionCreators";
import {settingsInstructionsInfoGroupSelector} from "../../../../ducks/bff/settings/instructions/naimix/selectors";
import {downloadDocument} from "../../../../ducks/documents";

export const InstructionsNaimix = () => {
    const dispatch = useDispatch();

    const instructionInfoGroup = useSelector(settingsInstructionsInfoGroupSelector);

    useEffect(() => {
        return () => {
            dispatch(clearNaimixInstructionStore());
        };
    }, []);

    const fetchList = () => {
        dispatch(getNaimixInstructions());
    };

    const getFile = (item) => {
        const {
            filename: fileName,
            downloadLink,
        } = item;

        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
            fileName,
        }));
    };

    const addFile = (data) => {
        const {
            instructionType,
            file,
            additionalParams,
            info,
        } = data;

        dispatch(addNaimixInstructionFile({
            instructionType,
            file,
            additionalParams,
            info,
        }));
    };

    const save = (info) => {
        dispatch(saveNaimixInstruction(info));
    };

    return (
        <InstructionsPage
            fetchList={fetchList}
            instructionInfoGroup={instructionInfoGroup}
            getFile={getFile}
            addFile={addFile}
            save={save}
            instructionTypeDict={USER_INSTRUCTION_TYPE_DICT}
        />
    );
};