import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmPageInfoCardsAccordion from "../../../../../components/ActualComponents/NmPageInfoCardsAccordion";

import {getDepositValuesCards} from "../../../../../components/DepositValuesAmountInfo/utils/getDepositValuesCards";
import {formatAmountWithNullChecking} from "../../../../../utils/stringFormat";

import {
    getDepositStateCrowdTasks,
    updateStoreCrowdTasks,
} from "../../../../../ducks/bff/crowd/tasks/actionCreators";
import {
    bffCrowdTasksDepositStateProgressSelector,
    bffCrowdTasksDepositStateSelector,
} from "../../../../../ducks/bff/crowd/tasks/selectors";

const CrowdDepositValuesAmountInfo = (props) => {
    const {
        clientId,
        className = "",
    } = props;

    const dispatch = useDispatch();

    const depositValues = useSelector(bffCrowdTasksDepositStateSelector);
    const isLoading = useSelector(bffCrowdTasksDepositStateProgressSelector);

    const {
        tasksSpentAmount = 0,
        availableForOrdersAmount = 0,
        enableNonDistributedClientDeposit,
    } = depositValues || {};

    useEffect(() => {
        dispatch(getDepositStateCrowdTasks({clientId}));

        return () => {
            dispatch(updateStoreCrowdTasks({depositState: {}}));
        };
    }, []);

    const {
        deposit,
        totalDeposit,
        nonDistributedDeposit,
    } = getDepositValuesCards({
        depositValues,
        isLoading,
    });

    const cards = useMemo(() => [
        {
            ...totalDeposit,
            className: "col-16 col-xxl-2",
        },
        {
            ...nonDistributedDeposit,
            className: "col-16 col-xxl-2",
        },
        {
            ...deposit,
            className: "col-16 col-xxl-2",
        },
        {
            isLoading,
            title: "Сумма опубликованных заданий",
            value: `${formatAmountWithNullChecking(tasksSpentAmount)} ₽`,
            helpTooltip: {
                text: enableNonDistributedClientDeposit ?
                    "Сумма опубликованных и неоплаченных заданий по объекту с учётом комиссии" :
                    "Сумма опубликованных и неоплаченных заданий с учётом комиссии",
                type: "light",
                position: "bottom-left",
            },
            className: "col-16 col-xxl-2",
        },
        {
            isLoading,
            title: "Сумма свободных ср-в для создания заданий",
            value: `${formatAmountWithNullChecking(availableForOrdersAmount)} ₽`,
            helpTooltip: {
                text: "Остаток суммы лимита для публикации новых заказов для Самозанятых. Рассчитывается как разница между лимитом заказов и израсходованным лимитом",
                type: "light",
                position: "bottom-left",
            },
            className: "col-16 col-xxl-2",
        },
    ], [
        depositValues,
        isLoading,
    ]);

    return (
        <NmPageInfoCardsAccordion
            bootstrap={true}
            className={className}
            cards={cards}
            desktopViewFrom="xxl"
        />
    );
};

export default CrowdDepositValuesAmountInfo;