import {CREATE_ACTS_TYPE} from "../containers/registries/registryActsType";
import {isAccessByRestrictions} from "./restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../constants/clientUserRestrictions";
import {REGISTRY_STATUS} from "../constants/registry";

export const isVisibleRegistryCreateDeferredActsBtn = (params) => {
    const {
        createActsOfAcceptanceOfWorkType,
        status,
        userHasAccessToFormDeferredActsOfAcceptanceWorkRegistry,
    } = params;

    return Boolean(userHasAccessToFormDeferredActsOfAcceptanceWorkRegistry)
        && createActsOfAcceptanceOfWorkType === CREATE_ACTS_TYPE.NONE
        && [
            REGISTRY_STATUS.FULLY_PAID,
            REGISTRY_STATUS.PARTIALLY_PAID,
        ].includes(status)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.createPostActRegistry,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_createPostActRegistry,
        ]);
};