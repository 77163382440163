import {getUserRestrictions} from "./access";

export const isAccessByRestrictions = (checkList) => {
    const currentUserRestrictions = getUserRestrictions();

    if (!currentUserRestrictions || !checkList) {
        return true;
    }

    return !checkList.some(item => currentUserRestrictions.includes(item));
};