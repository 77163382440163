import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES} from "../constants";

import {
    getDocumentCustomTemplateShortActual,
    getDocumentCustomTemplateShortDefault,
    updateDocumentCustomTemplateStore,
} from "../../../../../ducks/bff/documents-templates/actionCreators";
import {getOrderListForTemplates} from "../../../../../ducks/order";

export const useOrderEditFetchTemplates = (params) => {
    const {
        clientId,
        formData,
        setForm,
        editOrderId,
        isForemanRequestForAddition,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                defaultTemplatesAggregation: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (formData.isTemplateType) {
            getTemplates({});
        }
    }, [formData.isTemplateType, formData.objectId]);

    const getTemplates = () => {
        if (!formData.objectId) {
            return;
        }

        dispatch(getOrderListForTemplates({
            pageNum: 1,
            pageSize: 25,
            clientId,
            archivedFilter: false,
            statusFilter: ["TEMPLATE"],
            nameFieldTotalCount: "orderTemplatesTotalCount",
            nameFieldPageData: "orderTemplatesPageData",
            nameField: "orderTemplates",
            templateProjectIds: [formData.projectId],
            templateObjectIds: [formData.objectId],
        }));
    };

    useEffect(() => {
        const orderIdFilter = formData.templateId ? formData.templateId : editOrderId;

        fetchTemplates({
            orderIdFilter,
        });
    }, [
        formData.orderKind,
        formData.orderContractPaymentType,
        formData.orderId,
    ]);

    const fetchTemplates = ({orderIdFilter}) => {
        ORDER_EDIT_PRE_CONDITIONS_TEMPLATES_FETCH_QUERIES.forEach(item => {
            const {
                documentTypeFilter,
                nameDocumentField,
                fieldName,
            } = item;

            // Для РД список подгружать не нужно
            if (!["frameContractTemplateId"].includes(fieldName)) {
                // Получение списка шаблонов
                dispatch(getDocumentCustomTemplateShortActual({
                    isAggregation: true,
                    aggregationFieldName: fieldName,
                    clientIdFilter: clientId,
                    orderIdFilter,
                    orderContractPaymentTypeFilter: formData.orderContractPaymentType,
                    documentTypeFilter,
                    orderKindFilter: formData.orderKind,
                    fieldName: "aggregationShortTemplates",
                    pageNum: 1,
                    pageSize: 200,
                }));
            }

            // При редактировании заказа/шаблона значения подтягиваются из модели заказа
            // для дополнений бригадиров необходимо подтягивать дефолтные шаблоны
            if (editOrderId && !isForemanRequestForAddition) {
                return;
            }

            // Если на форме выбран переключатель - Заказ по шаблону и
            // Ввыбран шаблон по заказу, дефолтное значение подгружать не требуется
            if (formData.isTemplateType && formData.templateId) {
                return;
            }

            // Получение дефолтного шаблона
            dispatch(getDocumentCustomTemplateShortDefault({
                clientIdFilter: clientId,
                orderIdFilter,
                orderContractPaymentTypeFilter: formData.orderContractPaymentType,
                documentTypeFilter,
                orderKindFilter: formData.orderKind,
                onSuccess: ({id, name}) => {
                    if (nameDocumentField) {
                        setForm({
                            [fieldName]: id,
                            [nameDocumentField]: name,
                        });

                        return;
                    }

                    setForm({
                        [fieldName]: id,
                    });
                },
            }));
        });
    };

    return {
    };
};