import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "nanoid";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import Tabs from "../../../../components/ActualComponents/Tabs";
import NmPage from "../../../../components/NmPage";
import {withPageData} from "../../../../components/withPageData";
import DisputesNew from "../../../disputes/disputes-new";
import OrderCardDisputesCard from "./card";

import {usePagination} from "../../../../hooks/usePagination";

import {isChatSupported} from "../../../../utils/firebaseHelper";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import {toastWarning} from "../../../../utils/toastHelper";

import {
    CHAT_LINK_PARAMS,
    DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE,
} from "../../../../constants/disputes";
import {
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {getDisputeCausesDict} from "../../../../ducks/bff/common/dicts/actionCreators";
import {causesDisputeDictSelector} from "../../../../ducks/bff/common/dicts/selectors";
import {getDisputesList} from "../../../../ducks/bff/disputes/actionCreators";
import {
    disputeListSelector,
    disputeTotalCountForOrderCardSelector,
    disputeTotalPagesSelector,
} from "../../../../ducks/bff/disputes/selectors";

import "./style.sass";

import {DISPUTES} from "../../../../constants/link-params";

interface IOrderCardDisputes {
    clientId: string,
    orderId: string,
    subpage: string,
    role: string,
    location: any,
    historyData: any
}

const OrderCardDisputes = (props: IOrderCardDisputes) => {
    const {
        clientId,
        orderId,
        subpage,
        role,
        location,
        historyData,
    } = props;
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");
    const dispatch = useDispatch();
    const totalPages = useSelector(disputeTotalPagesSelector);
    const totalCount = useSelector(disputeTotalCountForOrderCardSelector);
    const disputes = useSelector(disputeListSelector);
    const disputeCauses = useSelector(causesDisputeDictSelector);
    const {t} = useTranslation();
    const [isOpenNew, setOpenNew] = useState(false);
    const [modalData, setModalData] = useState<any>({});

    useEffect(() => {
        fetchDisputesList(subpage);
    }, [subpage]);

    useEffect(() => {
        dispatch(getDisputeCausesDict());
    }, []);

    const fetchDisputesList = (subpage: string) => {
        dispatch(getDisputesList({
            pageNum: 1,
            pageSize: 25,
            clientIdFilter: clientId,
            orderIdFilter: orderId,
            disputeStatusFilter: subpage === "open" ? ["OPEN"] : ["CLOSE", "CANCEL"],
        }));
    };

    const onOpenNewDispute = (disputeId: string) => {
        setOpenNew(true);
        setModalData({disputeId});
    };

    const onCloseNewDispute = () => {
        setOpenNew(false);
        setModalData({});
    };

    const goChat = (id: string) => {
        return (e: any) => {
            if (!isChatSupported()) {
                toastWarning(DISPUTE_IS_SUPPORT_BROWSER_CHAT_MESSAGE);
            }

            if (!isNullOrWhitespace(e)) {
                e.stopPropagation();
            }

            const state = {prevPath: location.pathname, pageData: {pageNum, pageSize}, extData: historyData};

            const disputeId = isNullOrWhitespace(id) ? "all" : id;

            if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, CLIENT_ACCOUNTANT].includes(role)) {
                const to = LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST
                    .replace(":clientId", clientId)
                    .replace(":status", subpage)
                    .replace(":pageNumber", "1")
                    .replace(":pageSize", "10")
                    .replace(":disputeId?", disputeId)
                    .replace(":orderId?", orderId);

                history.push({pathname: to, state});

                return;
            }

            const to = LINK_DISPUTES_CHAT_LIST
                .replace(":status", subpage)
                .replace(":pageNumber", "1")
                .replace(":pageSize", "10")
                .replace(":disputeId?", disputeId)
                .replace(":orderId?", orderId);

            history.push({pathname: to, state});
        };
    };

    const link = LINK_ORDER_CARD.replace(":clientId", clientId).replace(":orderId", orderId).replace(":page", DISPUTES);
    const linkOpened = link.replace(":subpage", CHAT_LINK_PARAMS.OPEN);
    const linkClosed = link.replace(":subpage", CHAT_LINK_PARAMS.CLOSED);

    return (
        <NmPage
            showHeaderBlock={false}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        >
            {
                isOpenNew &&
                <DisputesNew
                    disputeId={modalData.disputeId}
                    isFromOrderCard
                    showChatList={goChat}
                    handleClose={onCloseNewDispute}
                />
            }
            <Tabs
                className="order-card-disputes__tabs"
                secondary
                panes={
                    [
                        {
                            link: linkOpened,
                            active: linkOpened,
                            name:  t("disputes-list.open-disputes"),
                        },
                        {
                            link: linkClosed,
                            active: linkClosed,
                            name: t("disputes-list.close-disputes"),
                        },
                    ]
                }
            />
            {
                totalCount ?
                    disputes.map((dispute: any) => {
                        return (
                            <OrderCardDisputesCard
                                className="order-card-disputes__item"
                                key={nanoid(3)}
                                onOpenNewDispute={onOpenNewDispute}
                                dispute={dispute}
                                disputeCauses={disputeCauses}
                                goChat={goChat}
                            />
                        );
                    }) :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                    />
            }
        </NmPage>
    );
};

export default withPageData(OrderCardDisputes);