import React from "react";

import {KedoLoginIndicator} from "../../../../../components/ActualComponents/KedoLoginIndicator";
import ExtLink from "../../../../../components/ExtLink";
import {KedoStaffRegistryEdit} from "../edit";

import {useModal} from "../../../../../hooks/useModal";

import {getUserRole} from "../../../../../utils/access";
import {getFormattedFullName, getFullName} from "../../../../../utils/stringFormat";

import {COLOR} from "../../../../../constants/color";
import {LINK_CLIENT_KEDO_PROFILE} from "../../../../../constants/links";
import {isClientUser} from "../../../../../constants/roles";

const KedoStaffProfileLink = (props) => {
    const {
        clientId,
        info,
        isFormattedFullName,
    } = props;

    const role = getUserRole();
    const title = "Открыть карточку сотрудника для просмотра";

    const {
        isOpen,
        onOpenModal,
        modalData,
        onCloseModal,
    } = useModal();

    const renderContent = () => {
        const fullName = getFullName(info.lastName, info.firstName, info.patronymic);

        return (
            <>
                {
                    isFormattedFullName
                        ? getFormattedFullName(fullName)
                        : fullName
                }
                {
                    !info.loggedIn &&
                    <KedoLoginIndicator className="ms-2" />
                }
            </>
        );
    };

    if (isClientUser(role)) {
        return (
            <>
                {
                    isOpen &&
                    <KedoStaffRegistryEdit
                        onClose={onCloseModal}
                        clientId={clientId}
                        kedoStaffId={modalData.kedoStaffId}
                        isViewMode={true}
                    />
                }
                <div
                    style={{
                        cursor: "pointer",
                        color: COLOR.PASSIVE_100,
                    }}
                    title={title}
                    onClick={() => {
                        onOpenModal(info);
                    }}
                    className="d-flex align-items-center"
                >
                    {renderContent()}
                </div>
            </>
        );
    }

    return (
        <ExtLink
            historyEnabled
            title={title}
            to={
                LINK_CLIENT_KEDO_PROFILE
                    .replace(":clientId", info.clientId)
                    .replace(":kedoStaffId", info.kedoStaffId)
            }
            className="d-flex align-items-center"
        >
            {renderContent()}
        </ExtLink>
    );
};

export default KedoStaffProfileLink;