import {createSelector} from "reselect";

import {getFormattedFullName, getFullName} from "../../../../utils/stringFormat";

const clientEmployeesSelector = state => state.bff.clientEmployees;

export const clientMemberListSelector = createSelector(
    clientEmployeesSelector,
    ({list}) => list,
);
export const clientMemberProgressListSelector = createSelector(
    clientEmployeesSelector,
    ({progressList}) => progressList,
);
export const clientMemberProgressGetUsersSelector = createSelector(
    clientEmployeesSelector,
    ({progressGetUsers}) => progressGetUsers,
);
export const clientMemberTotalCountSelector = createSelector(
    clientEmployeesSelector,
    ({totalCount}) => totalCount,
);
export const clientMemberTotalPagesSelector = createSelector(
    clientEmployeesSelector,
    ({totalCount, pageData}) => {
        const {pageSize} = pageData;
        return pageSize ? Math.ceil(totalCount / pageSize) : 0;
    },
);
export const clientMemberOptionsSelector = createSelector(
    clientEmployeesSelector,
    state => {
        return state.usersForFilterList.map(item => ({
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(item.lastName, item.firstName, item.patronymic),
        }));
    },
);
export const clientMemberIsSuccessAddSelector = createSelector(
    clientEmployeesSelector,
    ({isSuccessAdd}) => isSuccessAdd,
);
export const clientMemberProgressAddSelector = createSelector(
    clientEmployeesSelector,
    ({progressAdd}) => progressAdd,
);
export const clientMemberProgressUpdateSelector = createSelector(
    clientEmployeesSelector, 
    ({progressUpdate}) => progressUpdate,
);
export const clientMemberIsSuccessUpdateSelector = createSelector(
    clientEmployeesSelector,
    ({isSuccessUpdate}) => isSuccessUpdate,
);
export const clientMemberCardSelector = createSelector(
    clientEmployeesSelector,
    ({card}) => card,
);
export const clientMemberProgressSelector = createSelector(
    clientEmployeesSelector,
    ({progress}) => progress,
);
export const clientForemanListSelector = createSelector(
    clientEmployeesSelector,
    ({foremanList}) => foremanList,
);
export const foremanOptionsSelector = createSelector(
    clientEmployeesSelector,
    ({foremanList}) => {
        return foremanList.map(item => ({
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(item.lastName, item.firstName, item.patronymic),
        }));
    },
);
export const clientProjectManagerListSelector = createSelector(
    clientEmployeesSelector,
    ({projectManagers}) => projectManagers,
);
export const managerOptionsSelector = createSelector(
    clientEmployeesSelector,
    ({projectManagers}) => {
        return projectManagers.map(item => ({
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFullName(item.lastName, item.firstName, item.patronymic),
            role: item.role,
        }));
    },
);
export const orderAdminMembersSelector = createSelector(
    clientEmployeesSelector, 
    ({adminMembers}) => adminMembers,
);
export const orderManagerOptionsNoObjectSelector = createSelector(
    clientEmployeesSelector,
    ({adminMembers}) =>
        adminMembers.map(item => ({
            key: item.clientUserId,
            value: item.clientUserId,
            text: getFormattedFullName(`${item.lastName} ${item.firstName} ${item.patronymic}`),
        })),
);