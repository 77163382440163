import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    isEmpty,
    isEqual} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import CardApp from "../../../../components/CardApp";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";
import validate from "../../../../utils/validate";

import {CLIENT_FIELD_NAME} from "../../../../constants/clientList";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";
import {clientCategoryRule} from "../../../../constants/validationRules";

import {
    getClientSpecialities,
    getOrderCategoriesAll,
    updateClientCardInfo,
} from "../../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoSelector,
    clientCardPropertiesSelector,
    clientSpecialitiesOptionsSelector,
    getCategoryInfoSelector,
    getOrderCategoriesAllOptionsSelector,
    orderCategoriesAllSelector,
} from "../../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

class IndustriesCardBlock extends Component {
    static propTypes = {
        isEditable: PropTypes.bool,
        categoryInfo: PropTypes.object,
        categoryOptions: PropTypes.array,
        getOrderCategoriesAll: PropTypes.func,
        clientId: PropTypes.string,
        getClientSpecialities: PropTypes.func,
    };

    static defaultProps = {
        isEditable: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            error: {},
            categoryInfo: {},
            editCategoryInfo: {},
            editCategoryInfoCopy: {},
            isOpenConfirm: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            getOrderCategoriesAll,
            getClientSpecialities,
            clientId,
        } = this.props;

        getClientSpecialities({clientId});
        getOrderCategoriesAll();
    }

    static getDerivedStateFromProps(newProps, state) {
        const {
            editCategoryInfoCopy,
            isEdit,
        } = state;

        const {categoryInfo} = newProps;

        if (!isEqual(categoryInfo, editCategoryInfoCopy) && !isEdit) {
            return {
                ...state,
                editCategoryInfo: {...categoryInfo},
                categoryInfo,
                editCategoryInfoCopy: {...categoryInfo},
            };
        }

        return null;
    }

    handleChangeSelect = (event, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            editCategoryInfo: {
                ...prevState.editCategoryInfo,
                [name]: value,
            },
        }));
    };

    handleChange = (event, {value, name, checked}) => {
        this.setState(prevState => ({
            ...prevState,
            editCategoryInfo: {
                ...prevState.editCategoryInfo,
                [name]: checked !== undefined ? checked : value,
            },

        }));
    };

    onSearchChange = (searchQuery) => {
        this.setState({
            searchQuery,
        });
    };

    toggleCard = () => {
        const {isEdit, editCategoryInfo} = this.state;

        if (!isEdit) {
            this.setState({
                isEdit: true,
                editCategoryInfoCopy: editCategoryInfo,
            });
        } else {
            if (this.validateCategoryFields(editCategoryInfo)) {
                this.submitIndustriesInfo();
            }
        }
    };

    validateCategoryFields = (category) => {
        const {SPECIALITY_IDS} = CLIENT_FIELD_NAME;
        const rule = [ADMIN, NM_MANAGER].includes(this.role) ? clientCategoryRule : {[SPECIALITY_IDS]: clientCategoryRule[SPECIALITY_IDS]};

        const errorMessage = validate(category, rule, "");

        this.setState({error: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    submitIndustriesInfo = () => {
        const {updateClientCardInfo, categoryInfo, client} = this.props;

        const {editCategoryInfo} = this.state;

        const {
            categoryId,
            okved,
            foremanFunctionalityAvailable,
            specialityIds,
        } = editCategoryInfo;

        if (JSON.stringify(editCategoryInfo) === JSON.stringify(categoryInfo)) {
            this.cancelEdit();
            return;
        }

        const requestData = {
            ...client,
            categoryId,
            okved,
            specialityIds,
            foremanFunctionalityAvailable,
        };

        updateClientCardInfo({
            ...requestData,
            onSuccess: this.cancelEdit,
        });
    };

    cancelEdit = () => {
        const {editCategoryInfoCopy} = this.state;

        this.setState({
            isEdit: false,
            error: {},
            editCategoryInfo: editCategoryInfoCopy,
        });
    };

    renderCardReadOnly(editCategoryInfo) {
        const {
            categoryOptions,
            specialitiesClientOptions,
            t,
            categoryInfo: {
                categoryId,
                specialityIds,
            },
        } = this.props;

        const {okved} = editCategoryInfo;

        let categoryText = "Не указан";

        if (categoryOptions.length && !isNullOrWhitespace(categoryId)) {
            const items = categoryOptions.filter((item) => {
                return item.value === categoryId;
            });
            if (items.length) {
                categoryText = items[0].text;
            }
        }

        let specialitiesText = "Не указан";

        if (!isEmpty(specialitiesClientOptions) && !isEmpty(specialityIds)) {
            const selectedSpecialities = specialitiesClientOptions.filter(value => specialityIds.includes(value.key));

            specialitiesText = selectedSpecialities.map(value => value.text).join(", ");
        }

        return (
            <>
                <div className="label-text client-card-block__label">
                    <div className="label-text__name ">
                        {t("order-categories.okved")}
                    </div>
                    <div className="label-text__content">
                        {okved || "Не указан"}
                    </div>
                </div>
                <div className="label-text client-card-block__label">
                    <div className="label-text__name ">
                        {t("order-categories.category")}
                    </div>
                    <div className="label-text__content">
                        {categoryText}
                    </div>
                </div>
                <div className="label-text client-card-block__label">
                    <div className="label-text__name ">
                        {t("order-categories.specialities")}
                    </div>
                    <div className="label-text__content client-card-block__content">
                        {specialitiesText}
                    </div>
                </div>
            </>
        );
    }

    renderCardEditMode(editCategoryInfo) {
        return (
            [ADMIN, NM_MANAGER].includes(this.role) ?
                this.renderCardEditModeAdmin(editCategoryInfo) : this.renderCardEditModeClient(editCategoryInfo)
        );
    }

    renderCardEditModeAdmin(editCategoryInfo) {
        const {categoryOptions, specialitiesClientOptions, t} = this.props;

        const {error} = this.state;
        const {
            categoryId,
            okved,
            specialityIds,
        } = editCategoryInfo;

        return (
            <NmForm addMargin>
                <NmInputV2
                    size="lg"
                    label={t("order-categories.okved")}
                    error={error[CLIENT_FIELD_NAME.OKVED]}
                    name={CLIENT_FIELD_NAME.OKVED}
                    className="nmx-i-fluid"
                    value={okved}
                    onChange={this.handleChange}
                />
                <NmDropdownV2
                    size="lg"
                    options={categoryOptions}
                    value={categoryId}
                    name={CLIENT_FIELD_NAME.CATEGORY}
                    fullWidth
                    label={t("order-categories.category")}
                    onChange={this.handleChangeSelect}
                    error={error[CLIENT_FIELD_NAME.CATEGORY]}
                    placeholder="Выберите из списка"
                />
                <NmDropdownV2
                    size="lg"
                    options={specialitiesClientOptions}
                    value={specialityIds}
                    name={CLIENT_FIELD_NAME.SPECIALITY_IDS}
                    fullWidth={true}
                    onChange={this.handleChangeSelect}
                    search
                    multiple
                    onSearchChange={this.onSearchChange}
                    placeholder="Выберите из списка"
                    label={t("order-categories.specialities")}
                    error={error[CLIENT_FIELD_NAME.SPECIALITY_IDS]}
                />
            </NmForm>
        );
    }

    renderCardEditModeClient(editCategoryInfo) {
        const {categoryOptions, specialitiesClientOptions, t} = this.props;

        const {error} = this.state;
        const {
            categoryId,
            okved,
            specialityIds,
        } = editCategoryInfo;

        let categoryText = "Не указан";

        if (categoryOptions.length && !isNullOrWhitespace(categoryId)) {
            const items = categoryOptions.filter((item) => {
                return item.value === categoryId;
            });
            if (items.length) {
                categoryText = items[0].text;
            }
        }

        return (
            <NmForm>
                <div className="label-text client-card-block__label">
                    <div className="label-text__name ">
                        {t("order-categories.okved")}
                    </div>
                    <div className="label-text__content">
                        {okved || "Не указан"}
                    </div>
                </div>
                <div className="label-text client-card-block__label">
                    <div className="label-text__name ">
                        {t("order-categories.category")}
                    </div>
                    <div className="label-text__content">
                        {categoryText}
                    </div>
                </div>
                <NmDropdownV2
                    size="lg"
                    options={specialitiesClientOptions}
                    value={specialityIds}
                    name={CLIENT_FIELD_NAME.SPECIALITY_IDS}
                    onChange={this.handleChangeSelect}
                    search
                    multiple
                    onSearchChange={this.onSearchChange}
                    placeholder="Выберите из списка"
                    label={t("order-categories.specialities")}
                    tooltip={
                        <HelpTooltip
                            hover
                            position="top"
                            text="В карточке формировании заказа для выбора будут доступны только виды деятельности исполнителей,
                        которые отмечены в списке"
                        />
                    }
                    error={error[CLIENT_FIELD_NAME.SPECIALITY_IDS]}
                />
            </NmForm>
        );
    }

    render() {
        const {
            editCategoryInfo,
            isEdit,
        } = this.state;

        const {
            isEditable,
            t,
        } = this.props;

        return (
            <>
                <CardApp
                    title={t("client-industries-info.client-industries-title")}
                    isEditable={isEditable}
                    onClickCancelIcon={this.cancelEdit}
                    onClickIcon={this.toggleCard}
                    isEdit={isEdit}
                >
                    {isEdit
                        ? this.renderCardEditMode(editCategoryInfo)
                        : this.renderCardReadOnly(editCategoryInfo)
                    }
                </CardApp>
            </>
        );
    }
}

export default connect(state => ({
    categoryInfo: getCategoryInfoSelector(state),
    categoryOptions: getOrderCategoriesAllOptionsSelector(state),
    clientProperties: clientCardPropertiesSelector(state),
    client: clientCardInfoSelector(state),
    categoryList: orderCategoriesAllSelector(state),
    specialitiesClientOptions: clientSpecialitiesOptionsSelector(state),
}), {
    updateClientCardInfo,
    getOrderCategoriesAll,
    getClientSpecialities,
})(withTranslation()(IndustriesCardBlock));