import React from "react";
import {useDispatch, useSelector} from "react-redux";

import ActRegistryLog from "../../../../components/ActRegistryLog";

import {
    getPageRegistryPaymentLog,
    updateRegistryPaymentLogStore,
} from "../../../../ducks/bff/clients/registry-payment/log/actionCreators";
import {
    registryPaymentLogListSelector,
    registryPaymentLogTotalCountSelector,
    registryPaymentLogTotalPagesSelector,
} from "../../../../ducks/bff/clients/registry-payment/log/selectors";

function RegistriesListLog(props) {
    const dispatch = useDispatch();

    const list = useSelector(registryPaymentLogListSelector);
    const totalPages = useSelector(registryPaymentLogTotalPagesSelector);
    const totalCount = useSelector(registryPaymentLogTotalCountSelector);

    return (
        <ActRegistryLog
            {...props}
            list={list}
            totalPages={totalPages}
            totalCount={totalCount}
            onClearStore={() => {
                dispatch(updateRegistryPaymentLogStore({
                    pageData: {},
                    list: [],
                    totalCount: 0,
                }));
            }}
            onFetchList={(data) => {
                dispatch(getPageRegistryPaymentLog(data));
            }}
        />
    );
}

export default RegistriesListLog;