import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "./clientUserRestrictions";
import {
    LINK_ADDING_CLIENT_REMOTE_CONTRACTORS,
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_ARCHIVE_LIST,
    LINK_CHAT_LIST,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_CLIENT_CARD,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_FINANCE,
    LINK_CLIENT_FINANCE_CARD,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_CLIENT_INVITING_CONTRACTORS_LIST,
    LINK_CLIENT_KEDO,
    LINK_CLIENT_KEDO_DIRECTORIES,
    LINK_CLIENT_KEDO_DIRECTORIES_DOCUMENT_ROUTES,
    LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES,
    LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS,
    LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_KEDO_PROFILE,
    LINK_CLIENT_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_VERIFICATION_DATA,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CRM_TICKET,
    LINK_CRM_TICKET_CARD,
    LINK_CRM_TICKET_LIST,
    LINK_DEPOSIT_LIST,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_FINANCE,
    LINK_FINANCE_BANK,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_FINANCE_CARD,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_LOG,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_FINANCE_UPLOAD,
    LINK_FORBIDDEN_PAGE,
    LINK_FULL_INFO_MAP,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_MAILINGS_LIST,
    LINK_MAP_OBJECTS_LIST,
    LINK_MARKETING_BUILDER,
    LINK_NOTIFICATIONS_LIST,
    LINK_ORDER_CARD,
    LINK_PATENT_PIVOT_TABLE,
    LINK_PROJECTS,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_REVIEWS_LIST,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_SETTINGS,
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_SETTINGS_DOCUMENTS_CORRECTION,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_MEMBERS_LIST,
    LINK_SETTINGS_ML,
    LINK_SETTINGS_SERVICE,
    LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_SPECIALTY_OFFER,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TKB_TRANSFERS,
    LINK_SETTINGS_USERS_ROLES_LIST,
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_TEMPLATES_CONTRACTS_LIST,
    LINK_TEMPLATES_KEDO,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LINK_TEMPLATES_LIST,
    LINK_TEMPLATES_LIST_WITH_TAB,
    LinkNavigator,
} from "./links";

import {ls, USER_ROLE} from "../utils/localstorage";

// Административные роли
export const ADMIN = "ADMIN"; // Администратор Наймикс
export const NM_MANAGER = "NM_MANAGER"; // Менеджер Наймикс
export const NM_COORDINATOR = "NM_COORDINATOR"; // Координатор Наймикс
export const NM_CHIEF_ACCOUNTANT = "NM_CHIEF_ACCOUNTANT";//главный бухгалтер наймикс
export const NM_CONSULTANT = "NM_CONSULTANT"; // Консультант Наймикс
export const RNKO = "RNKO";//СБ
// Клиентские роли
export const CLIENT_ADMIN = "CLIENT_ADMIN"; // Администратор компании
export const FOREMAN = "FOREMAN"; // Координатор компании
export const PROJECT_MANAGER = "PROJECT_MANAGER"; // Менеджер Проекта
export const OBJECT_MANAGER = "OBJECT_MANAGER"; // Менеджер Объекта
export const EXTERNAL_USER = "EXTERNAL_USER"; // Внешний пользователь
export const CLIENT_ACCOUNTANT = "CLIENT_ACCOUNTANT";//бухгалтер
export const HR_MANAGER = "HR_MANAGER"; // HR-менеджер
export const CONTRACTOR = "CONTRACTOR"; // Самозанятый (Исполнитель)
export const NM_PARTNER = "NM_PARTNER"; // Наймикс партнер
export const GR = "GR";// Сотрудник компании, с правами админа компании https://jira.mmtr.ru/browse/NAMEMIX-13550
export const NM_OPERATOR = "NM_OPERATOR";//Оператор колл-центра
export const RECRUITER = "RECRUITER";//Рекрутер
export const RECRUITMENT_OBSERVER = "RECRUITMENT_OBSERVER"; // Наблюдатель в рекурутменте
// КЭДО
export const KEDO_HR = "KEDO_HR"; // Кадровый работник
export const KEDO_CLIENT_USER = "KEDO_CLIENT_USER"; // Сотрудник

export const adminAccessLinks = [
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CONTRACTOR_NEW,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_CROWD_TASKS,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_SETTINGS,
    LINK_ARCHIVE_LIST,
    LINK_ORDER_CARD,
    LINK_CLIENT_CARD_ORDERS,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_FINANCE,
    LINK_FINANCE_CARD,
    LINK_FINANCE_UPLOAD,
    LINK_CLIENT_FINANCE,
    LINK_FINANCE_LOG,
    LINK_CLIENT_CARD,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_SERVICE,
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
    LINK_SETTINGS_TKB_TRANSFERS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_SETTINGS_DOCUMENTS_CORRECTION,
    LINK_SETTINGS_ML,
    LINK_DEPOSIT_LIST,
    LinkNavigator.admin.civilDeposit,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_DISPUTES,
    LINK_FINANCE,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_PATENT_PIVOT_TABLE,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_EXPORT_FULL,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_ADDING_CLIENT_REMOTE_CONTRACTORS,
    LINK_NOTIFICATIONS_LIST,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_PROJECTS,
    LINK_FULL_INFO_MAP,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_MAILINGS_LIST,
    LINK_MARKETING_BUILDER,
    LINK_FINANCE_BANK,
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_CONTRACTORS_RESOURCES,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_CONTRACTOR_VERIFICATION_DATA,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_REVIEWS_LIST,
    LINK_MAP_OBJECTS_LIST,
    LINK_CRM_TICKET_LIST,
    LINK_CRM_TICKET,
    LINK_CRM_TICKET_CARD,
    LINK_SETTINGS_MEMBERS_LIST,
    LINK_TEMPLATES_LIST,
    LINK_TEMPLATES_LIST_WITH_TAB,
    LINK_TEMPLATES_CONTRACTS_LIST,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO,
    LINK_CLIENT_KEDO_PROFILE,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_TEMPLATES_KEDO,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_SETTINGS_USERS_ROLES_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
    LINK_SETTINGS_SPECIALTY_OFFER,
];

export const nmOperatorAccessLinks = [
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CLIENT_CARD,
    LINK_CONTRACTOR_LIST,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_VERIFICATION_DATA,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
    LINK_CRM_TICKET_LIST,
    LINK_CRM_TICKET,
    LINK_CRM_TICKET_CARD,
    LINK_SETTINGS,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_MAILINGS_LIST,
    LINK_FINANCE_EXPORT_FULL,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
];

export const nmChiefAccountantAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_SETTINGS,
    LINK_ARCHIVE_LIST,
    LINK_ORDER_CARD,
    LINK_CLIENT_CARD_ORDERS,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_FINANCE,
    LINK_FINANCE_CARD,
    LINK_FINANCE_UPLOAD,
    LINK_CLIENT_FINANCE,
    LINK_FINANCE_LOG,
    LINK_CLIENT_CARD,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_DEPOSIT_LIST,
    LinkNavigator.admin.civilDeposit,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_PATENT_PIVOT_TABLE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_NOTIFICATIONS_LIST,
    LINK_MAILINGS_LIST,
    LINK_FINANCE_BANK,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_FINANCE_SYSTEM_TRANSACTIONS,
    LINK_CONTRACTORS_RESOURCES,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_REVIEWS_LIST,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_FULL_INFO_MAP,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const nmManagerAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_TEMPLATES_KEDO_LIST,
    LINK_TEMPLATES_KEDO_LOG,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_SETTINGS,
    LINK_ARCHIVE_LIST,
    LINK_ORDER_CARD,
    LINK_CLIENT_CARD_ORDERS,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_FINANCE,
    LINK_FINANCE_CARD,
    LINK_FINANCE_UPLOAD,
    LINK_CLIENT_FINANCE,
    LINK_FINANCE_LOG,
    LINK_CLIENT_CARD,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_JOINING_REQUESTS,
    LINK_SETTINGS_FNS_SERVICE,
    LINK_SETTINGS_SERVICE,
    LINK_SETTINGS_SERVICE_SETTINGS,
    LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE,
    LINK_SETTINGS_TKB_TRANSFERS,
    LINK_SETTINGS_SERVICE_TASKS_STATS,
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_SETTINGS_DOCUMENTS_CORRECTION,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_DEPOSIT_LIST,
    LinkNavigator.admin.civilDeposit,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_FULL_INFO_MAP,
    LINK_CHAT_LIST,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_PATENT_PIVOT_TABLE,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_NOTIFICATIONS_LIST,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_PROJECTS,
    LINK_MAILINGS_LIST,
    LINK_MARKETING_BUILDER,
    LINK_FINANCE_BANK,
    LINK_CONTRACTORS_RESOURCES,
    LINK_FINANCE_CANCELED_CHECKS,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_ADDING_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_VERIFICATION_DATA,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS,
    LINK_TASK_LIST_KEDO_ALL,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_ALL,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_REVIEWS_LIST,
    LINK_CRM_TICKET_LIST,
    LINK_CRM_TICKET,
    LINK_CRM_TICKET_CARD,
    LINK_TEMPLATES_LIST,
    LINK_TEMPLATES_LIST_WITH_TAB,
    LINK_TEMPLATES_CONTRACTS_LIST,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO,
    LINK_CLIENT_KEDO_PROFILE,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES,
    LINK_TEMPLATES_KEDO,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
    LINK_SETTINGS_SPECIALTY_OFFER,
];

export const nmConsultantAccessLinks = [
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CLIENT_CARD,
    LINK_NOTIFICATIONS_LIST,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_INFO,
    LINK_CLIENT_MEMBERS_LIST,
];

export const nmPartnerAccessLinks = [
    LINK_FORBIDDEN_PAGE,
    LINK_FINANCE_EXPORT_FULL,
];

export const nmCoordinatorAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_SETTINGS_DIRECTORIES_KEDO,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_LIST,
    LINK_CONTRACTOR_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_SETTINGS,
    LINK_ARCHIVE_LIST,
    LINK_ORDER_CARD,
    LINK_CLIENT_CARD_ORDERS,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_FINANCE,
    LINK_FINANCE_CARD,
    LINK_FINANCE_UPLOAD,
    LINK_CLIENT_FINANCE,
    LINK_CLIENT_REMOTE_CONTRACTORS,
    LINK_CONTRACTOR_ONBOARDING_LEADS,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_FINANCE_LOG,
    LINK_CLIENT_CARD,
    LINK_SETTINGS_INFO,
    LINK_SETTINGS_TECHSUPPORT_LIST,
    LINK_SETTINGS_TECHSUPPORT_CARD,
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
    LINK_SETTINGS_ML,
    LINK_DEPOSIT_LIST,
    LinkNavigator.admin.civilDeposit,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_PROMOCODE_CHANNEL_LIST,
    LINK_PROMOCODE_CHANNEL_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_FINANCE_EXPORT_FULL,
    LINK_FINANCE_PIVOT_TABLE,
    LINK_CIVIL_PIVOT_TABLE,
    LINK_PATENT_PIVOT_TABLE,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_ORDERS,
    LINK_FINANCE_CROWD_TASKS,
    LINK_FINANCE_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_NOTIFICATIONS_LIST,
    LINK_MAILINGS_LIST,
    LINK_FINANCE_BANK,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_CONTRACTORS_RESOURCES,
    LINK_FINANCE_REGISTRY_PAYMENTS,
    LINK_FINANCE_REGISTRY_PAYMENTS_ROWS,
    LINK_FINANCE_EXPORT_DOCUMENTS,
    LINK_SETTINGS_CLIENT_GROUP_CARD,
    LINK_SETTINGS_CLIENT_GROUP,
    LINK_SETTINGS_DIRECTORIES,
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS,
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES,
    LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES,
    LINK_SETTINGS_COLLECTION_BANK_DETAILS,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_REVIEWS_LIST,
    LINK_FULL_INFO_MAP,
    LINK_CRM_TICKET_LIST,
    LINK_CRM_TICKET,
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CRM_TICKET_CARD,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_CARD_CRM_TICKET_LIST,
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CONTRACTOR_CARD_CRM_TICKET_LIST,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const clientAdminAccountantAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    // LINK_CLIENT_LIST,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_PROFILE,
    // LINK_SETTINGS,
    LINK_ARCHIVE_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CLIENT_INFO,
    LINK_CLIENT_LIST,
    LINK_CLIENT_INVITING_CONTRACTORS_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_FINANCE_CARD,
    LINK_CLIENT_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_NOTIFICATIONS_LIST,
    LINK_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_STOP_LIST,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const clientAdminAccessLinks = [
    ...clientAdminAccountantAccessLinks,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_DIRECTORIES,
    LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS,
    LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS,
    LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES,
    LINK_CLIENT_KEDO_DIRECTORIES_DOCUMENT_ROUTES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS_ALL,
];

export const clientAccountantAccessLinks = [
    ...clientAdminAccountantAccessLinks,
];

export const hrManagerAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_INFO,
    LINK_CLIENT_CARD,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_NOTIFICATIONS_LIST,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_SETTING_NOTIFICATIONS_LIST,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const clientProjectManagerAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CONTRACTOR_NEW,
    LINK_ARCHIVE_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CLIENT_FINANCE_CARD,
    LINK_CLIENT_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CHAT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_FINANCE_EXPORT_FULL,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_PATENTS,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST,
    LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const clientObjectManagerAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_ARCHIVE_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CLIENT_FINANCE_CARD,
    LINK_CLIENT_CARD,
    LINK_DISPUTES,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_CHAT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_INSURANCE_HISTORY,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_FINANCE_EXPORT_FULL,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_CROWD,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_BRANDS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const foremanAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CONTRACTORS_RESOURCES,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_ARCHIVE_LIST,
    LINK_CLIENT_CARD,
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CHAT_LIST,
    LINK_PROJECTS,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_REPORTS,
    LINK_CLIENT_REPORTS_RISK_MONITORING,
    LINK_CLIENT_FINANCE_UPLOAD,
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CLIENT_BRIGADE,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_CHAT_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_FEEDBACKS,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_REGISTRY_PAYMENTS,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD,
    LinkNavigator.client.edo.documentsRegistries,
    LinkNavigator.client.edo.documentsRegistriesCard,
];

export const recruiterAccessLinks = [
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
    LINK_TEMPLATES_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_MEMBERS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CONTRACTORS_RESOURCES,
    LINK_INSTRUCTION_CLIENT_ADMIN,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN,
    LINK_CONTRACTOR_NEW,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_ARCHIVE_LIST,
    LINK_CLIENT_CARD,
    LINK_ALL_ROLES_CHAT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT,
    LINK_DISPUTES_CHAT_LIST,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CHAT_LIST,
    LINK_PROJECTS,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_PAYMENTS_TASKS_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_CANCELLED_CHECKS,
    LINK_TASK_LIST_KEDO_MY,
    LINK_TASK_LIST_NAIMIX_MY,
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_OBJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD,
    LINK_CLIENT_PROJECTS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_PROJECTS_CARD_ORDER_LIST,
    LINK_CLIENT_PROJECTS_CARD_TASK_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST,
    LINK_CLIENT_PROJECTS_CARD_TEAM_LIST,
    LINK_CLIENT_CARD_ORDERS,
    LINK_ORDER_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_DOCUMENT_ROUTE,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
];

export const seniorRecruiterAccessLinks = [
    ...recruiterAccessLinks,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD,
];

export const RNKOAccessLinks = [
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_FORBIDDEN_PAGE,
    LINK_CONTRACTOR_LIST,
    LINK_CLIENT_ON_CHECK_CONTRACTORS,
    LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS,
    LINK_CONTRACTOR_CARD,
    LINK_CONTRACTOR_REVIEWS_LIST,
    LINK_CONTRACTOR_ORDER_LIST,
    LINK_CONTRACTOR_TASK_LIST,
    LINK_CONTRACTOR_DOCUMENT_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
];

export const recruitmentObserverAccessLinks = [
    LINK_FORBIDDEN_PAGE,
    LINK_CLIENT_CARD,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL,
    LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS,
];

export const roleSettingsList = [
    {
        key: ADMIN,
        text: "Администратор Наймикс",
        value: ADMIN,
    },
    {
        key: NM_MANAGER,
        text: "Менеджер Наймикс",
        value: NM_MANAGER,
    },
    {
        key: NM_COORDINATOR,
        text: "Координатор Наймикс",
        value: NM_COORDINATOR,
    },
    {
        key: NM_CHIEF_ACCOUNTANT,
        text: "Главный бухгалтер Наймикс",
        value: NM_CHIEF_ACCOUNTANT,
    },
    {
        key: NM_CONSULTANT,
        text: "Консультант Наймикс",
        value: NM_CONSULTANT,
    },
    {
        key: RNKO,
        text: "СБ",
        value: RNKO,
    },
    {
        key: NM_OPERATOR,
        text: "Оператор КЦ",
        value: NM_OPERATOR,
    },
];

export const clientRoleOptions = [
    {
        key: CLIENT_ADMIN,
        text: "Администратор компании",
        value: CLIENT_ADMIN,
    },
    {
        key: PROJECT_MANAGER,
        text: "Менеджер проекта",
        value: PROJECT_MANAGER,
    },
    {
        key: OBJECT_MANAGER,
        text: "Менеджер объекта",
        value: OBJECT_MANAGER,
    },
    {
        key: FOREMAN,
        text: "Координатор компании",
        value: FOREMAN,
    },
    {
        key: CLIENT_ACCOUNTANT,
        text: "Бухгалтер компании",
        value: CLIENT_ACCOUNTANT,
    },
    {
        key: HR_MANAGER,
        text: "HR-менеджер",
        value: HR_MANAGER,
    },
    {
        key: RECRUITER,
        text: "Рекрутер",
        value: RECRUITER,
    },
    {
        key: RECRUITMENT_OBSERVER,
        text: "Наблюдатель",
        value: RECRUITMENT_OBSERVER,
    },
];

export const roleOptionsByCurrentUserRole = (role) => {
    if (role === PROJECT_MANAGER) {
        return [
            {
                key: FOREMAN,
                text: "Координатор компании",
                value: FOREMAN,
            },
            {
                key: PROJECT_MANAGER,
                text: "Менеджер проекта",
                value: PROJECT_MANAGER,
            },
            {
                key: OBJECT_MANAGER,
                text: "Менеджер объекта",
                value: OBJECT_MANAGER,
            },
            {
                key: HR_MANAGER,
                text: "HR-менеджер",
                value: HR_MANAGER,
            },
            {
                key: RECRUITER,
                text: "Рекрутер",
                value: RECRUITER,
            },
        ];
    }

    if (role === OBJECT_MANAGER) {
        return [
            {
                key: FOREMAN,
                text: "Координатор компании",
                value: FOREMAN,
            },
            {
                key: OBJECT_MANAGER,
                text: "Менеджер объекта",
                value: OBJECT_MANAGER,
            },
            {
                key: RECRUITER,
                text: "Рекрутер",
                value: RECRUITER,
            },
        ];
    }

    const options = [
        {
            key: CLIENT_ADMIN,
            text: "Администратор компании",
            value: CLIENT_ADMIN,
        },
        {
            key: CLIENT_ACCOUNTANT,
            text: "Бухгалтер компании",
            value: CLIENT_ACCOUNTANT,
        },
        {
            key: FOREMAN,
            text: "Координатор компании",
            value: FOREMAN,
        },
        {
            key: PROJECT_MANAGER,
            text: "Менеджер проекта",
            value: PROJECT_MANAGER,
        },
        {
            key: OBJECT_MANAGER,
            text: "Менеджер объекта",
            value: OBJECT_MANAGER,
        },
        {
            key: HR_MANAGER,
            text: "HR-менеджер",
            value: HR_MANAGER,
        },
        {
            key: RECRUITER,
            text: "Рекрутер",
            value: RECRUITER,
        },
        {
            key: RECRUITMENT_OBSERVER,
            text: "Наблюдатель",
            value: RECRUITMENT_OBSERVER,
        },
    ];

    return options;
};

export const roleSettingsListByCurrentMemberRole = {
    ADMIN: [
        {
            key: ADMIN,
            text: "Администратор Наймикс",
            value: ADMIN,
        },
        {
            key: NM_MANAGER,
            text: "Менеджер Наймикс",
            value: NM_MANAGER,
        },
        {
            key: NM_COORDINATOR,
            text: "Координатор Наймикс",
            value: NM_COORDINATOR,
        },
        {
            key: NM_CONSULTANT,
            text: "Консультант Наймикс",
            value: NM_CONSULTANT,
        },
        {
            key: NM_CHIEF_ACCOUNTANT,
            text: "Главный бухгалтер Наймикс",
            value: NM_CHIEF_ACCOUNTANT,
        },
        {
            key: RNKO,
            text: "СБ",
            value: RNKO,
        },
        {
            key: NM_OPERATOR,
            text: "Оператор КЦ",
            value: NM_OPERATOR,
        },

    ],
    NM_MANAGER: [
        {
            key: NM_MANAGER,
            text: "Менеджер Наймикс",
            value: NM_MANAGER,
        },
        {
            key: NM_COORDINATOR,
            text: "Координатор Наймикс",
            value: NM_COORDINATOR,
        },
        {
            key: NM_CONSULTANT,
            text: "Консультант Наймикс",
            value: NM_CONSULTANT,
        },
        {
            key: RNKO,
            text: "СБ",
            value: RNKO,
        },
        {
            key: NM_CHIEF_ACCOUNTANT,
            text: "Главный бухгалтер Наймикс",
            value: NM_CHIEF_ACCOUNTANT,
        },
        {
            key: NM_OPERATOR,
            text: "Оператор КЦ",
            value: NM_OPERATOR,
        },
    ],
    NM_COORDINATOR: [
        {
            key: NM_COORDINATOR,
            text: "Координатор Наймикс",
            value: NM_COORDINATOR,
        },
        {
            key: NM_CONSULTANT,
            text: "Консультант Наймикс",
            value: NM_CONSULTANT,
        },
    ],
    NM_CONSULTANT: [
        {
            key: NM_CONSULTANT,
            text: "Консультант Наймикс",
            value: NM_CONSULTANT,
        },
    ],
};


export const ROLE_DICT = {
    ADMIN: {
        TEXT: "Администратор Наймикс",
        VALUE: ADMIN,
    },
    CLIENT_ADMIN: {
        TEXT: "Администратор компании",
        VALUE: CLIENT_ADMIN,
    },
    CONTRACTOR: {
        TEXT: "Самозанятый",
        VALUE: CONTRACTOR,
    },
    FOREMAN: {
        TEXT: "Координатор компании",
        VALUE: FOREMAN,
    },
    NM_MANAGER: {
        TEXT: "Менеджер Наймикс",
        value: NM_MANAGER,
    },
    NM_COORDINATOR: {
        TEXT: "Координатор Наймикс",
        value: NM_COORDINATOR,
    },
    NM_CONSULTANT: {
        TEXT: "Консультант Наймикс",
        value: NM_CONSULTANT,
    },
    [PROJECT_MANAGER]: {
        TEXT: "Менеджер проекта",
        value: PROJECT_MANAGER,
    },
    [OBJECT_MANAGER]: {
        TEXT: "Менеджер объекта",
        value: OBJECT_MANAGER,
    },
    [EXTERNAL_USER]: {
        TEXT: "Внешний пользователь",
        value: EXTERNAL_USER,
    },
    [RNKO]: {
        TEXT: "СБ",
        value: RNKO,
    },
    [CLIENT_ACCOUNTANT]: {
        TEXT: "Бухгалтер компании",
        value: CLIENT_ACCOUNTANT,
    },
    [HR_MANAGER]: {
        value: HR_MANAGER,
        TEXT: "HR-менеджер",
    },
    [NM_CHIEF_ACCOUNTANT]: {
        TEXT: "Главный бухгалтер Наймикс",
        value: NM_CHIEF_ACCOUNTANT,
    },
    [NM_PARTNER]: {
        TEXT: "Партнер Наймикс",
        value: NM_PARTNER,
    },
    [GR]: {
        TEXT: GR,
        value: GR,
    },
    RECRUITMENT_OBSERVER: {
        TEXT: "Наблюдатель",
        value: RECRUITMENT_OBSERVER,
    },
    NM_OPERATOR: {
        TEXT: "Оператор КЦ",
        value: NM_OPERATOR,
    },
    [RECRUITER]: {
        TEXT: "Рекрутер",
        value: RECRUITER,
    },
};

export const isUserFromNm = (role) => {
    role = role ? role : ls(USER_ROLE);

    return [ADMIN, NM_COORDINATOR, NM_MANAGER, NM_CHIEF_ACCOUNTANT, NM_PARTNER, NM_OPERATOR].includes(role);
};

export const isClientUser = (role) => {
    return [
        CLIENT_ADMIN,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
        CLIENT_ACCOUNTANT,
        EXTERNAL_USER,
        HR_MANAGER,
        CONTRACTOR,
        RECRUITER,
        RECRUITMENT_OBSERVER,
    ].includes(role);
};

// /**
//  * Исполнитель
//  */
// CONTRACTOR,
//     /**
//      * Сотрудник Наймикс
//      */
//     NAIMIX_USER,
//     /**
//      * Банк
//      */
//     BANK,

export const USER_TYPE = {
    CONTRACTOR: {
        VALUE: "CONTRACTOR",
        TEXT: "Исполнитель",
    },
    NAIMIX_USER: {
        VALUE: "NAIMIX_USER",
        TEXT: "Сотрудник Наймикс",
    },
    CLIENT_USER: {
        VALUE: "CLIENT_USER",
        TEXT: "Сотрудник компании",
    },
    BANK: {
        VALUE: "BANK",
        TEXT: "Банк",
    },
};

export const linksRestrictionsVariables = {
    [LINK_CLIENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClients,
    ],
    [LINK_CLIENT_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClients,
    ],
    [LINK_CLIENT_BRANDS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientBrands,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientBrands,
    ],
    [LINK_CLIENT_MEMBERS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientsUsers,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_TASK_LIST_NAIMIX_MY]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmTaskList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_TASK_LIST_NAIMIX_ALL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmTaskList,
    ],
    [LINK_TASK_LIST_KEDO_MY]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmTaskList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessTaskListKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessTaskListKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_TASK_LIST_KEDO_ALL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdmTaskList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessTaskListKedo,
    ],
    [LINK_SETTINGS_JOINING_REQUESTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRequestConnection,
    ],
    [LINK_FINANCE_SYSTEM_TRANSACTIONS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSystemTransactions,
    ],
    [LINK_CLIENT_RECRUITMENT]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRecruitmentAdmin,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessRecruitmentClient,
    ],
    [LINK_CLIENT_CROWD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientCrowdTasks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessCrowdTasks,
    ],
    [LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessCrowdTasks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientObjects,
    ],
    [LINK_CLIENT_FINANCE_EXPORT_FULL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinancesExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientExport,
    ],
    [LINK_CLIENT_FINANCE_UPLOAD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinancesExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClient1c,
    ],
    [LINK_CLIENT_ORDERS_ANALYTICS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinancesExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientExport,
    ],
    [LINK_CLIENT_REPORTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_REPORTS_RISK_MONITORING]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinancesExport,
    ],
    [LINK_CONTRACTOR_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorsList,
    ],
    [LINK_CONTRACTORS_RESOURCES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorsList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRetail,
    ],
    [LINK_CLIENT_REMOTE_CONTRACTORS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorsList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientRemote,
    ],
    [LINK_CRM_TICKET_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrmTicket,
    ],
    [LINK_CLIENT_CARD_CRM_TICKET_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTickets,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CARD_CRM_TICKET_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTickets,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CONTRACTOR_CARD_CRM_TICKET_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorTickets,
    ],
    [LINK_CONTRACTOR_CRM_TICKET_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorTickets,
    ],
    [LINK_CLIENT_CROWD_TASK_REGISTRY]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_REGISTRY_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_GROUPS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_GROUPS_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_ACT_REGISTRY]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LINK_CLIENT_CROWD_TASK_ANALYTICS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTaskAnalytics,
    ],
    [LINK_DEPOSIT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAllDepositSMZ,
    ],
    [LinkNavigator.admin.civilDeposit]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAllDepositCivil,
    ],
    [LINK_FINANCE_EXPORT_DOCUMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessDocumentsExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAllExport,
    ],
    [LINK_FINANCE_CANCELED_CHECKS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCanceledChecksFts,
    ],
    [LINK_FINANCE_BANK]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessBank,
    ],
    [LINK_FINANCE_CROWD_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
    ],
    [LINK_FINANCE_CROWD_TASKS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTasks,
    ],
    [LINK_CLIENT_CROWD_TASK_CHATS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTaskChat,
    ],
    [LINK_FINANCE_EXPORT_FULL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAllExport,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReportExport,
    ],
    [LINK_FINANCE_ORDERS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessOrders,
    ],
    [LINK_FINANCE_PATENT_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
    ],
    [LINK_FINANCE_NDFL_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
    ],
    [LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessIndividualPayments,
    ],
    [LINK_FINANCE_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
    ],
    [LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReconciliationReports,
    ],
    [LINK_PATENT_PIVOT_TABLE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReportsSummary,
    ],
    [LINK_CIVIL_PIVOT_TABLE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReportsSummary,
    ],
    [LINK_FINANCE_PIVOT_TABLE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReportsSummary,
    ],
    [LINK_SETTINGS_FNS_SERVICE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFtsService,
    ],
    [LINK_SETTINGS_USERS_ROLES_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRoleModel,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_MAILINGS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessMarketing,
    ],
    [LINK_FINANCE_UPLOAD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAtolReceiptsExport,
    ],
    [LINK_MARKETING_BUILDER]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessMarketing,
    ],
    [LINK_FULL_INFO_MAP]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessMap,
    ],
    [LINK_MARKETING_BUILDER]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSmartLink,
    ],
    [LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
    ],
    [LINK_CLIENT_PROJECTS_CARD_TEAM_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
    ],
    [LINK_CLIENT_PROJECTS_CARD_ORDER_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
    ],
    [LINK_CLIENT_PROJECTS_CARD_TASK_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessCrowdTasks,
    ],
    [LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
    ],
    [LINK_CLIENT_OBJECTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_PROJECTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProjectsAndObjects,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_PROMOCODE_CHANNEL_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessContractorsPromotion,
    ],
    [LINK_FINANCE_REGISTRY_PAYMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRegisterPayments,
    ],
    [LINK_FINANCE_OPERATOR_REPORTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinanceBlocks,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessOperatorReports,
    ],
    [LINK_SETTINGS_DOCUMENTS_CORRECTION]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingCorrectionDocument,
    ],
    [LINK_SETTINGS_ML]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsMachineLearning,
    ],
    [LINK_SETTINGS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAllSettings,
    ],
    [LINK_SETTINGS_INFO]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_CLIENT_GROUP_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_TECHSUPPORT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_TECHSUPPORT_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_SERVICE_SETTINGS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsService,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabsService,
    ],
    [LINK_SETTINGS_SERVICE_MAINTENANCE_NOTICE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabsService,
    ],
    [LINK_SETTINGS_SERVICE_TASKS_STATS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsService,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsServiceTaskStats,
    ],
    [LINK_SETTINGS_COLLECTION_BANK_DETAILS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_CLIENT_GROUP]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_INSTRUCTION]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_SETTINGS_MEMBERS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsEmployees,
    ],
    [LINK_SETTINGS_INSTRUCTION_KEDO]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingInstructionsKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingsTabs,
    ],
    [LINK_REVIEWS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessReviews,
    ],
    [LINK_CLIENT_FEEDBACKS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientScores,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_BRIGADE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientForemans,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientForemans,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_CHAT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientChat,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientChats,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CLIENT_CANCELLED_CHECKS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCancelledReceipts,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_CARD_ORDERS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientOrders,
    ],
    [LINK_CLIENT_ORDER_ACT_REGISTRY_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_ACT_REGISTRY_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_GROUPS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_GROUPS_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderSomeBlocks,
    ],
    [LINK_CLIENT_ORDER_TEMPLATES_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessOrderTemplates,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessTemplateOrders,
    ],
    [LINK_CLIENT_STOP_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessStopListContractors,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientStopList,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_INFO]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientProperty,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_ADVERTISEMENT]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdvertisement,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessAdvertisement,
    ],
    [LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdvertisement,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessAdvertisement,
    ],
    [LINK_CLIENT_CARD_ADVERTISEMENT]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdvertisement,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessAdvertisement,
    ],
    [LINK_CLIENT_PAYMENTS_ORDERS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_REGISTRY_PAYMENTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_REGISTRY_PAYMENTS_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_REGISTRY_PAYMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentMutualSettlementsReconciliationAct,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlockR,
    ],
    [LINK_CLIENT_RECRUITMENT_DIRECTORIES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDirectoriesClient,
    ],
    [LINK_CLIENT_KEDO]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessKedo,
    ],
    [LINK_CLIENT_KEDO_MEMBERS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessEmployeesKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessEmployeesKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_KEDO_PROFILE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessEmployeesKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessEmployeesKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_KEDO_DOCUMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessDocumentFlowKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentFlowKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_KEDO_EXPORTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessExportKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_KEDO_DIRECTORIES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessCatalogKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_KEDO_LOGS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessLog1cKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessLog1cKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_INSTRUCTION_KEDO_CLIENT_ADMIN]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessSettingInstructionsKedo,
    ],
    [LINK_TEMPLATES_KEDO_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessTemplateKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCustomDocuments,
    ],
    [LINK_TEMPLATES_KEDO_LOG]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessTemplateKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCustomDocuments,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_SETTINGS_DIRECTORIES_KEDO_DOCUMENT_ROUTES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCatalogKedo,
    ],
    [LINK_CLIENT_NOTIFICATIONS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientEmailNotifications,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientNotification,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CLIENT_SETTING_NOTIFICATIONS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientEmailNotifications,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientNotification,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientsEmailNotifications,
    ],
    [LINK_CLIENT_DOCUMENT_MANAGEMENT]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessEdo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessEdo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CONTRACTOR_PROFILE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorBlockProfile,
    ],
    [LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessPatentsTabs,
    ],
    [LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessPatentsTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDepositHistory,
    ],
    [LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessPatentsTabs,
    ],
    [LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessPatentsTabs,
    ],
    [LINK_CLIENT_DOCUMENT_ROUTE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_DOCUMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlockR,
    ],
    [LINK_CLIENT_EXPORT_DOCUMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlockR,
    ],
    [LINK_CONTRACTOR_ORDER_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorSomeBlocks,
    ],
    [LINK_CONTRACTOR_TASK_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorSomeBlocks,
    ],
    [LINK_CONTRACTOR_DOCUMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorSomeBlocks,
    ],
    [LINK_CONTRACTOR_REVIEWS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessContractorBlockScores,
    ],
    [LINK_CLIENT_DEPOSIT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDepositHistory,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_DEPOSIT_LIST_WITH_TAB]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDepositHistory,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_NDFL_DEPOSIT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDepositHistory,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_NDFL_DEPOSIT_LIST_WITH_TAB]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDepositHistory,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlock,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_CARD_TEMPLATES_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlock,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_STANDART_DOCUMENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessPlatformCustomerDocuments,
    ],
    [LINK_CLIENT_AGENCY_CONTRACT]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlock,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_OPERATOR_REPORTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessDocumentSomeBlock,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
    ],
    [LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessAccessControlClient,
    ],
    [LINK_TEMPLATES_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCustomDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_TEMPLATES_LIST_WITH_TAB]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCustomDocuments,
    ],
    [LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCustomDocuments,
    ],
    [LINK_ORDER_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrderCard,
    ],
    [LINK_CLIENT_CONTRACTORS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_CONTRACTORS_RESOURCES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRetailContractors,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientContractorsDocTask,
    ],
    [LINK_CLIENT_NDFL_PAYMENTS_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientFinance,
    ],
    [LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessIndividualPayments,
    ],
    [LINK_DISPUTES]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessDisputes,
    ],
    [LINK_DISPUTES_CLIENT_ADMIN]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientDisputes,
    ],
    [LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessOrgStructureKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessOrgStructureKedo,
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
    ],
    [LINK_CLIENT_SUBSCRIBE_NOTIFICATIONS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientEmailNotifications,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_CLIENT_PATENTS]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ],
    [LinkNavigator.client.edo.documentsRegistries]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LinkNavigator.client.edo.documentsRegistriesCard]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
    ],
    [LINK_SETTINGS_SPECIALTY_OFFER]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingDirectorySpecialtyOffer,
    ],
    [LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRegisterAdditionalAgreements,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessRegisterAdditionalAgreements,
    ],
    [LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD]: [
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessRegisterAdditionalAgreements,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessRegisterAdditionalAgreements,
    ],
};
