import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_ERROR,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_SUCCESS, CLIENT_DEPOSIT_REPLENISHMENT_RETURN_ERROR,
    CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST, CLIENT_DEPOSIT_REPLENISHMENT_RETURN_SUCCESS,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../../utils/url";

const getController = (params) => {
    const {
        isCivil,
        isPatentsPage,
    } = params;

    if (isPatentsPage) {
        return getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/client/patents/deposit/replenishment-and-refunds",
                adminRolesUrl: "/adm/clients/client-card/patents/deposit/replenishment-and-refunds",
            },
        );
    }

    if (isCivil) {
        return getBffUrl(
            {
                isClientCard: true,
                clientRolesUrl: "/client-adm/finances/ndfl-deposit/replenishment-and-refunds",
                adminRolesUrl: "/adm/clients/client-card/finances/ndfl-deposit/replenishment-and-refunds",
            },
        );
    }

    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/npd-deposit/replenishment-and-refunds",
            adminRolesUrl: "/adm/clients/client-card/finances/npd-deposit/replenishment-and-refunds",
        },
    );
};

const getClientDepositReplenishmentAndRefundsPageSaga = function* ({payload}) {
    try {
        const {
            isCivil,
            isPatentsPage,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isCivil, isPatentsPage})}/get-history-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

const updateCommentClientDepositReplenishmentAndRefundsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isCivil})}/update-client-deposit-history-comment`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");
        onSuccess && onSuccess();

        yield put({
            type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

const editClientDepositReplenishmentAndRefundsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isCivil})}/edit-history`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Изменения успешно сохранены");
        onSuccess && onSuccess();

        yield put({
            type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
            payload: error,
        });
    }
};

const addClientGeneralDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isCivil})}/add-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_DEPOSIT_REPLENISHMENT_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Операция завершена успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_DEPOSIT_REPLENISHMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_DEPOSIT_REPLENISHMENT_ADD_ERROR,
            payload: error,
        });
    }
};

const returnClientGeneralDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isCivil,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController({isCivil})}/return-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_DEPOSIT_REPLENISHMENT_RETURN_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Операция завершена успешно");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_DEPOSIT_REPLENISHMENT_RETURN_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_DEPOSIT_REPLENISHMENT_RETURN_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, getClientDepositReplenishmentAndRefundsPageSaga),
        takeEvery(UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, updateCommentClientDepositReplenishmentAndRefundsSaga),
        takeEvery(EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST, editClientDepositReplenishmentAndRefundsSaga),
        takeEvery(CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST, addClientGeneralDepositSaga),
        takeEvery(CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST, returnClientGeneralDepositSaga),
    ]);
}