import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import Filter from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as ReceiptIcon} from "../../../images/receipt_24.svg";

import {usePagination} from "../../../hooks/usePagination";
import useFinanceCanceledChecksFetch from "./hooks/useFetch";
import useFinanceCanceledChecksFilter from "./hooks/useFilter";

import formatDate, {convertUtcToLocal} from "../../../utils/dateFormat";
import {openLinkByUrl} from "../../../utils/downloadBlob";
import {
    CURRENT_CLIENT_ID,
    CURRENT_CLIENT_USER_ID,
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmount, formatNumber} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {CANCELED_RECEIPT_PAYMENT_PURPOSE_TYPE} from "../../../constants/finance";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {FINANCE_EXPORT_MESSAGES} from "../../../constants/messages";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../constants/roles";

import {exportFinancesReceiptsCancelled} from "../../../ducks/bff/finances/receipts-cancelled/actionCreators";
import {
    bffFinancesReceiptsCancelledListSelector,
    bffFinancesReceiptsCancelledProgressActionSelector,
    bffFinancesReceiptsCancelledProgressSelector,
    bffFinancesReceiptsCancelledTotalCountSelector,
    bffFinancesReceiptsCancelledTotalPagesSelector,
} from "../../../ducks/bff/finances/receipts-cancelled/selectors";
import {
    addViewCancelledChecks,
    cancelledChecksIndicatorSelector,
} from "../../../ducks/orderWorkReport";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

const FinanceCanceledChecks = (props) => {
    const {
        match: {
            params: {
                clientId: urlClientId,
            },
        },
    } = props;

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        setFilter,
        filters,
    } = useFinanceCanceledChecksFilter({
        setPagination,
        pageSize,
        urlClientId,
    });

    const role = ls(USER_ROLE);

    const dispatch = useDispatch();
    const progress = useSelector(bffFinancesReceiptsCancelledProgressActionSelector);
    const progressList = useSelector(bffFinancesReceiptsCancelledProgressSelector);
    const list = useSelector(bffFinancesReceiptsCancelledListSelector);
    const totalPages = useSelector(bffFinancesReceiptsCancelledTotalPagesSelector);
    const hasCancelledChecks = useSelector(cancelledChecksIndicatorSelector);
    const totalCount = useSelector(bffFinancesReceiptsCancelledTotalCountSelector);

    useFinanceCanceledChecksFetch({
        pageNum,
        pageSize,
        filter: filterData,
        urlClientId,
    });

    useEffect(() => {
        if (hasCancelledChecks) {
            dispatch(addViewCancelledChecks({
                clientId: ls(CURRENT_CLIENT_ID),
                clientUserId: ls(CURRENT_CLIENT_USER_ID),
            }));
        }
    }, [hasCancelledChecks]);

    const onExport = () => {
        dispatch(exportFinancesReceiptsCancelled({
            ...filter,
            clientIdFilter: urlClientId ? urlClientId : filter.clientIdFilter,
            onSuccess: () => {
                toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_CANCELLED_EXPORT_SUCCESS);
            },
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                clientName,
                paymentNumber,
                paymentDescription,
                contractorFullName,
                orderNum,
                orderName,
                dateTimeCancelled,
                taxLink,
                clientId,
                projectId,
                projectName,
                objectId,
                objectName,
                orderId,
                paymentAmount,
                createDateTimeTaxLink,
                paymentPurpose,
            } = item;

            const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);
            const orderLink = LINK_ORDER_CARD.replace(":clientId", clientId)
                .replace(":orderId", orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);
            const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                .replace(":taskId", orderId)
                .replace(":clientId", clientId)
                .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);
            const orderInfo = `${orderNum} ${orderName}`;
            const paymentInfo = `${paymentNumber} — ${paymentDescription}`;
            const projectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST.replace(":clientId", clientId)
                .replace(":projectId", projectId);
            const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST.replace(":clientId", clientId)
                .replace(":projectId", projectId)
                .replace(":objectId", objectId);

            const isCrowd = paymentPurpose === CANCELED_RECEIPT_PAYMENT_PURPOSE_TYPE.CROWD_TO_BALANCE;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        alignItems="flex-end"
                        secondaryHeader={
                            [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role) &&
                            <Link to={clientLink}>
                                {clientName}
                            </Link>
                        }
                        primaryHeader={
                            <ExtLink
                                historyEnabled={true}
                                isLoadDataTarget={true}
                                filterData={{
                                    paymentNumber,
                                }}
                                to={LINK_FINANCE_PAYMENT_LIST}
                                title={paymentInfo}
                            >
                                {paymentInfo}
                            </ExtLink>
                        }
                        primaryHeaderLink={true}
                        classNameMainContent="col-16 col-xxl-5"
                        labels={[
                            {
                                label: "ФИО исполнителя",
                                text: contractorFullName,
                            },
                            {
                                label: "Проект",
                                text: <Link
                                    to={projectLink}
                                    title={projectName}
                                >
                                    {projectName}
                                </Link>,
                            },
                            {
                                label: "Объект",
                                text: <Link
                                    to={objectLink}
                                    title={objectName}
                                >
                                    {objectName}
                                </Link>,
                            },
                            orderId && {
                                label: `Номер и описание ${isCrowd ? "задания" : "заказа"}`,
                                text: <Link
                                    to={isCrowd ? taskLink : orderLink}
                                    title={orderInfo}
                                >
                                    {orderInfo}
                                </Link>,
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма выплаты, ₽",
                                value: formatAmount(formatNumber(paymentAmount)),
                                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Дата чека",
                                value: formatDate(convertUtcToLocal(createDateTimeTaxLink)),
                                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Дата аннулирования",
                                value: formatDate(convertUtcToLocal(dateTimeCancelled)),
                                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-9 align-items-end"
                        mediaControls={{
                            renderCount: {
                                mobile: 0,
                                tablet: 0,
                                desktop: 1,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        icon: <ReceiptIcon />,
                                        size: "lg",
                                        color: "light-green",
                                        children: "Чек ФНС России",
                                        onClick: () => {
                                            openLinkByUrl(taxLink);
                                        },
                                    },
                                    visible: Boolean(taxLink),
                                },
                            ],
                        }}
                        actionsClassName="col-2 align-items-end"
                    />
                ),
            };
        });
    };

    const isShowControls = () => {
        if ([NM_OPERATOR].includes(role)) {
            return false;
        }

        if (!list?.length && !isSearch) {
            return false;
        }

        if (urlClientId) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.uploadCanceledChecks,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.unloadFinancesCancelledReceipts,
        ]);
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    tooltipText="В списке отображаются аннулированные чеки по оплатам заказов и заданий. Воспользуйтесь фильтром для поиска необходимых аннулированных чеков"
                >
                    Аннулированные чеки ФНС России
                </NmTitle>
            }
            mediaControls={{
                renderCount: {
                    mobile: 0,
                    tablet: 0,
                    desktop: 1,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON_WITH_TOOLTIP,
                        props: {
                            size: "xl",
                            color: "green",
                            children: "Выгрузить",
                            loading: progress,
                            disabled: progress,
                            onClick: onExport,
                            tooltip:
                                <HelpTooltip
                                    width="24px"
                                    height="24px"
                                    hover
                                    position="bottom-right"
                                    info
                                    children="Выгрузка списка аннулированных чеков будет выполнена в соответствии с заданными фильтрами"
                                />,
                        },
                        visible: isShowControls(),
                    },
                ],
            }}
            typeFilter="vertical"
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            filtersBase={
                <Filter
                    initState={filter}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                    filters={filters}
                    updateFilter={(filter) => setFilter(filter)}
                />
            }
            totalCount={totalCount}
            isLoaded={progressList}
        >
            {
                list?.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progressList}
                    />
            }
        </NmPage>
    );
};

export default FinanceCanceledChecks;