import {
    BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST,
    BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST,
    BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST,
    BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST,
    BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST,
    BFF_FINANCES_RETRY_PAYMENT_REQUEST,
    CHECK_ORDER_WORK_ACTS_REQUEST,
    FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST,
    ORDER_WORK_REPORT_LOG_REQUEST,
    UPDATE_FINANCES_FINANCES_STORE,
} from "./actions";

export const getPageFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getTotalAmountFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST,
        payload,
    };
};

export const confirmFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST,
        payload,
    };
};

export const rejectFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_REJECT_REQUEST,
        payload,
    };
};

export const cancelReceiptFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST,
        payload,
    };
};

export const getReceiptFinancesPayments = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST,
        payload,
    };
};

export const getPageFinancesPaymentsSpentLimit = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST,
        payload,
    };
};

export const getFinanceFinancesPaymentsSpentLimit = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST,
        payload,
    };
};

export const getFinancesPaymentsRegistryOrdersInProgressPage = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST,
        payload,
    };
};

export const getFinancesPaymentsRegistryOrdersInProgressAmount = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST,
        payload,
    };
};

export const getPaymentStepsLog = (payload) => {
    return {
        type: FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST,
        payload,
    };
};

export const getOrderWorkReportLogs = (payload) => {
    return {
        type: ORDER_WORK_REPORT_LOG_REQUEST,
        payload,
    };
};

export const checkOrderWorkActs = (payload) => {
    return {
        type: CHECK_ORDER_WORK_ACTS_REQUEST,
        payload,
    };
};

export const getFinancesPaymentsBlockedContractors = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_REQUEST,
        payload,
    };
};

export const retryPayment = (payload) => {
    return {
        type: BFF_FINANCES_RETRY_PAYMENT_REQUEST,
        payload,
    };
};

export const updateStoreFinancesPayments = (payload) => {
    return {
        type: UPDATE_FINANCES_FINANCES_STORE,
        payload,
    };
};