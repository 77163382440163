import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import TemplatesDropdown from "../../../../../components/TemplatesDropdown";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

import {updateDeferredActsAcceptanceWork} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/actionCreators";
import {
    getDeferredActsOfAcceptanceWorkCard,
    updateDeferredActsOfAcceptanceWorkCardStore,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";
import {
    bffDeferredActsOfAcceptanceWorkInitialValuesSelector,
    bffDeferredActsOfAcceptanceWorkProgressCardSelector,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/selectors";
import {bffDeferredActsOfAcceptanceWorkProgressActionSelector} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/selectors";

export const DocumentsDeferredActsOfAcceptanceWorkEdit = (props) => {
    const {
        clientId,
        registryId,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const progressCard = useSelector(bffDeferredActsOfAcceptanceWorkProgressCardSelector);
    const progressAction = useSelector(bffDeferredActsOfAcceptanceWorkProgressActionSelector);
    const initialValues = useSelector(bffDeferredActsOfAcceptanceWorkInitialValuesSelector);

    const initialOption = useMemo(() => {
        if (registryId) {
            return {
                key: initialValues?.templateId,
                value: initialValues?.templateId,
                text: initialValues?.templateName,
            };
        }
    }, [
        initialValues,
    ]);

    const {
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            name: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            commentary: yup.string(),
        }),
    });

    useEffect(() => {
        if (registryId) {
            dispatch(getDeferredActsOfAcceptanceWorkCard({
                clientId,
                registryId,
            }));
        }

        return () => {
            dispatch(updateDeferredActsOfAcceptanceWorkCardStore({
                card: {},
            }));
        };
    }, []);

    const onChange = (event, {name, value}) => {
        setFieldValue(name, value);
    };

    function onSubmit() {
        dispatch(updateDeferredActsAcceptanceWork({
            clientId,
            registryId,
            ...values,
        }));
    }

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Редактирование реестра отложенных актов
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            loading={progressAction || progressCard}
        >
            <NmForm addMargin={true}>
                <NmInputV2
                    required={true}
                    label="Название"
                    name="name"
                    maxLength={100}
                    onChange={onChange}
                    value={values.name}
                    size="xl"
                    error={
                        touched?.name && errors?.name ?
                            errors?.name :
                            undefined
                    }
                />
                <NmInputV2
                    label="Комментарий"
                    placeholder="При необходимости введите комментарий"
                    name="commentary"
                    maxLength={255}
                    onChange={onChange}
                    value={values.commentary}
                    size="xl"
                />
                <TemplatesDropdown
                    clientIdFilter={clientId}
                    disabled={true}
                    buttonText="Скачать акт"
                    label="Шаблон акта"
                    name="templateId"
                    initialOption={initialOption}
                    value={values.templateId}
                    error={
                        touched?.templateId && errors?.templateId ?
                            errors?.templateId :
                            undefined
                    }
                />
            </NmForm>
        </NmModal>
    );
};