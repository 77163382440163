import {
    STOP_LIST_READ_REQUEST,
    STOP_LIST_READ_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    totalCount: 0,
    pageData: {},
    error: null,
    progress: false,
    currentClient: {},
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case STOP_LIST_READ_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case STOP_LIST_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload,
            };
        default:
            return state;
    }
};