import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContractorBlackListWarningModal from "../../../components/ContractorBlackListWarningModal";

import {bffClearFieldsAdvertisementStore} from "../../../ducks/bff/advertisement/actionCreators";
import {
    advertisementActionPageDataSelector,
    advertisementErrorSelector,
} from "../../../ducks/bff/advertisement/selectors";

export const AdvertisementErrors = () => {
    const dispatch = useDispatch();

    const error = useSelector(advertisementErrorSelector);
    const actionPageData = useSelector(advertisementActionPageDataSelector);

    const onClose = () => {
        dispatch(bffClearFieldsAdvertisementStore({
            error: null,
            actionPageData: null,
        }));
    };

    if (isEmpty(error?.contractorsInBlacklist)) {
        return null;
    }

    return (
        <ContractorBlackListWarningModal
            onClose={onClose}
            isMassOperation={actionPageData?.isMassOperation}
            contractorsInBlacklist={error.contractorsInBlacklist}
        />
    );
};