import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";

import {getDocumentRouteTypesFilter} from "../../../../utils/getDocumentRouteTypesFilter";
import {getKedoTemplateDocumentTypesValidation} from "../utils/getValidation";

import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../../../../constants/dicts";

import {kedoDirectoriesProgressActionSelector} from "../../../../../../../ducks/kedo/directories/selectors";

import {
    addAdmKedoTemplateDocumentType,
    updateAdmKedoTemplateDocumentType,
} from "../../../../../../../ducks/bff/adm/settings/directories/kedo/template-document-types/services";

export const useKedoTemplateDocumentTypesEdit = (params) => {
    const {
        fetchList,
        editData,
        onClose,
        fetchCounts,
    } = params;

    const dispatch = useDispatch();

    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const initialValues = useMemo(() => {
        const {
            routeType,
            ...otherData
        } = editData || {};

        return {
            name: "",
            nameForClient: "",
            templateId: "",
            documentCode: "",
            routeId: "",
            canCreatedByClientUser: false,
            baseRouteType: routeType
                ? routeType === KEDO_DOCUMENTS_ROUTE_TYPE.BASE_ROUTE
                : true,
            ...otherData,
        };
    }, [editData]);

    const {
        handleSubmit,
        values,
        setValues,
        touched,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: getKedoTemplateDocumentTypesValidation(),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value, checked}) => {
        if (name === "baseRouteType") {
            setValues(prevState => ({
                ...prevState,
                [name]: value,
                routeId: "",
            }));
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    function onSubmit() {
        if (!isValid) {
            return;
        }

        const {
            name,
            nameForClient,
            templateId,
            documentCode,
            canCreatedByClientUser,
            routeId,
        } = values;

        const requestData = {
            name,
            nameForClient,
            templateId,
            documentCode,
            canCreatedByClientUser,
            routeId,
        };

        if (editData) {
            const {
                documentTypeId,
            } = editData;

            dispatch(updateAdmKedoTemplateDocumentType({
                documentTypeId,
                ...requestData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            }));

            return;
        }

        dispatch(addAdmKedoTemplateDocumentType({
            ...requestData,
            onSuccess: () => {
                fetchList();
                fetchCounts();
                onClose();
            },
        }));
    }

    const documentRouteTypesFilter = useMemo(() => {
        return getDocumentRouteTypesFilter(values.baseRouteType);
    }, [values.baseRouteType]);

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
        touched,
        documentRouteTypesFilter,
    };
};