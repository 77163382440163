import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffBankTkbSelector = state => state.bff.bank.tkb;
export const bffBankTkbListSelector = createSelector(bffBankTkbSelector, ({list}) => list);
export const bffBankTkbTotalCountSelector = createSelector(bffBankTkbSelector, ({totalCount}) => totalCount);
export const bffBankTkbTotalPagesSelector = createSelector(bffBankTkbSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffBankTkbProgressSelector = createSelector(bffBankTkbSelector, ({progress}) => progress);
export const bffBankTkbDataSelector = createSelector(bffBankTkbSelector, ({data}) => data);
export const bffBankTkbProgressActionSelector = createSelector(bffBankTkbSelector, ({progressAction}) => progressAction);