import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {uniqWith} from "lodash";

import OrderListMassTemplatePublicationDownloadBtn
    from "../../containers/order/order-list/mass-template-publication/components/download-btn";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import bem from "../../utils/bem";
import {toastError} from "../../utils/toastHelper";

import {
    getDocumentCustomTemplateShortActual,
    getDocumentCustomTemplateShortDefault,
    updateDocumentCustomTemplateStore,
} from "../../ducks/bff/documents-templates/actionCreators";
import {getDocumentsTemplatesController} from "../../ducks/bff/documents-templates/sagas";
import {documentCustomTemplateShortListOptionsSelector} from "../../ducks/bff/documents-templates/selectors";
import {downloadDocument} from "../../ducks/documents";

import "./style.sass";

const TemplatesDropdown = (props) => {
    const {
        clientIdFilter,
        actsRegistryIdFilter,
        frameContractsRegistryIdFilter,
        paymentsRegistryIdFilter,
        orderKindFilter,
        orderContractPaymentTypeFilter,
        buttonText,
        isFetch = true,
        isFetchDefault = false,
        documentTypeFilter,
        orderIdFilter,
        className = "",
        name,
        onChange,
        fieldName,
        aggregationFieldName,
        isAggregation,
        initialOption,
        ...otherProps
    } = props;
    const dispatch = useDispatch();
    const templateOptions = useSelector(documentCustomTemplateShortListOptionsSelector);
    const [block] = bem("templates-dropdown", className);

    const options = useMemo(() => {
        if (initialOption) {
            return uniqWith(
                [
                    ...templateOptions,
                    initialOption,
                ],
                (arrVal, othVal) => {
                    return arrVal.key === othVal.key;
                });
        }

        return templateOptions;
    }, [
        templateOptions,
        initialOption,
    ]);

    useEffect(() => {
        if (isFetch) {
            dispatch(getDocumentCustomTemplateShortActual({
                fieldName,
                aggregationFieldName,
                isAggregation,
                clientIdFilter,
                orderIdFilter,
                actsRegistryIdFilter,
                frameContractsRegistryIdFilter,
                paymentsRegistryIdFilter,
                orderContractPaymentTypeFilter,
                documentTypeFilter,
                orderKindFilter,
                pageNum: 1,
                pageSize: 200,
            }));
        }

        if (isFetchDefault) {
            // Получение дефолтного шаблона
            dispatch(getDocumentCustomTemplateShortDefault({
                clientIdFilter,
                orderIdFilter,
                actsRegistryIdFilter,
                frameContractsRegistryIdFilter,
                paymentsRegistryIdFilter,
                orderContractPaymentTypeFilter,
                documentTypeFilter,
                orderKindFilter,
                onSuccess: ({id}) => {
                    onChange(null, {
                        name,
                        value: id,
                    });
                },
            }));
        }
    }, [orderContractPaymentTypeFilter, orderKindFilter]);

    useEffect(() => {
        return () => {
            if (fieldName) {
                dispatch(updateDocumentCustomTemplateStore({
                    shortList: [],
                    [fieldName]: [],
                }));

                return;
            }

            dispatch(updateDocumentCustomTemplateStore({
                shortList: [],
            }));
        };
    }, []);

    const onClickDownload = () => {
        if (!props.value) {
            toastError("Нет выбранного шаблона для скачивания");

            return;
        }

        dispatch(downloadDocument({
            isDownload: true,
            downloadLink: `/bff${getDocumentsTemplatesController()}/pdf/${props.value}`,
        }));
    };

    return (
        <div className={block()}>
            <NmDropdownV2
                search
                placeholder="Выберите шаблон акта"
                label="Шаблон акта"
                className="mb-1"
                options={options}
                onChange={onChange}
                name={name}
                {...otherProps}
            />
            {
                props.value &&
                <OrderListMassTemplatePublicationDownloadBtn
                    text={buttonText}
                    onClick={onClickDownload}
                />
            }
        </div>
    );
};

export default TemplatesDropdown;