import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const documentsEdoRegistriesSelector = state => state.bff.clientsEdoRegistries;
export const documentsEdoRegistriesListSelector = createSelector(documentsEdoRegistriesSelector, ({list}) => list);
export const documentsEdoRegistriesTotalPagesSelector = createSelector(documentsEdoRegistriesSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const documentsEdoRegistriesTotalCountSelector = createSelector(documentsEdoRegistriesSelector, ({totalCount}) => totalCount);
export const documentsEdoRegistriesProgressListSelector = createSelector(documentsEdoRegistriesSelector, ({progressList}) => progressList);
export const documentsEdoRegistriesCardSelector = createSelector(documentsEdoRegistriesSelector, ({card}) => card);
export const documentsEdoRegistriesProgressActionSelector = createSelector(documentsEdoRegistriesSelector, ({progressAction}) => progressAction);
export const documentsEdoRegistriesGetFileProgressActionSelector = createSelector(documentsEdoRegistriesSelector, ({progressGetFile}) => progressGetFile);