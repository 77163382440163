export const BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST = "BFF_ADD_ADVERTISEMENT_PUBLISH_REQUEST";
export const BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS = "BFF_ADD_ADVERTISEMENT_PUBLISH_SUCCESS";
export const BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR = "BFF_ADD_ADVERTISEMENT_PUBLISH_ERROR";

export const BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST = "BFF_ADD_ADVERTISEMENT_DRAFT_REQUEST";
export const BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS = "BFF_ADD_ADVERTISEMENT_DRAFT_SUCCESS";
export const BFF_ADD_ADVERTISEMENT_DRAFT_ERROR = "BFF_ADD_ADVERTISEMENT_DRAFT_ERROR";

export const BFF_GET_ADVERTISEMENT_PAGE_REQUEST = "BFF_GET_ADVERTISEMENT_PAGE_REQUEST";
export const BFF_GET_ADVERTISEMENT_PAGE_SUCCESS = "BFF_GET_ADVERTISEMENT_PAGE_SUCCESS";
export const BFF_GET_ADVERTISEMENT_PAGE_ERROR = "BFF_GET_ADVERTISEMENT_PAGE_ERROR";

export const BFF_UPDATE_ADVERTISEMENT_REQUEST = "BFF_UPDATE_ADVERTISEMENT_REQUEST";
export const BFF_UPDATE_ADVERTISEMENT_SUCCESS = "BFF_UPDATE_ADVERTISEMENT_SUCCESS";
export const BFF_UPDATE_ADVERTISEMENT_ERROR = "BFF_UPDATE_ADVERTISEMENT_ERROR";

export const BFF_ADVERTISEMENT_CLOSE_REQUEST = "BFF_ADVERTISEMENT_CLOSE_REQUEST";
export const BFF_ADVERTISEMENT_CLOSE_SUCCESS = "BFF_ADVERTISEMENT_CLOSE_SUCCESS";
export const BFF_ADVERTISEMENT_CLOSE_ERROR = "BFF_ADVERTISEMENT_CLOSE_ERROR";

export const BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST = "BFF_ADVERTISEMENT_IMPORT_LIST_REQUEST";
export const BFF_ADVERTISEMENT_IMPORT_LIST_SUCCESS = "BFF_ADVERTISEMENT_IMPORT_LIST_SUCCESS";
export const BFF_ADVERTISEMENT_IMPORT_LIST_ERROR = "BFF_ADVERTISEMENT_IMPORT_LIST_ERROR";

export const BFF_GET_ADVERTISEMENT_REQUEST = "BFF_GET_ADVERTISEMENT_REQUEST";
export const BFF_GET_ADVERTISEMENT_SUCCESS = "BFF_GET_ADVERTISEMENT_SUCCESS";
export const BFF_GET_ADVERTISEMENT_ERROR = "BFF_GET_ADVERTISEMENT_ERROR";

export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_REQUEST";
export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_SUCCESS";
export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_CHECK_ERROR";

export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_REQUEST";
export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_SUCCESS";
export const BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR = "BFF_ADVERTISEMENT_PUBLISH_MULTIPLE_ERROR";

export const BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST = "BFF_GET_ADVERTISEMENT_VIEW_PAGE_REQUEST";
export const BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS = "BFF_GET_ADVERTISEMENT_VIEW_PAGE_SUCCESS";
export const BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR = "BFF_GET_ADVERTISEMENT_VIEW_PAGE_ERROR";

export const BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST = "BFF_ADVERTISEMENT_CONTRACTORS_COUNT_REQUEST";
export const BFF_ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS = "BFF_ADVERTISEMENT_CONTRACTORS_COUNT_SUCCESS";
export const BFF_ADVERTISEMENT_CONTRACTORS_COUNT_ERROR = "BFF_ADVERTISEMENT_CONTRACTORS_COUNT_ERROR";

export const BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST = "BFF_ADVERTISEMENT_FUNNEL_PAGE_REQUEST";
export const BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS = "BFF_ADVERTISEMENT_FUNNEL_PAGE_SUCCESS";
export const BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR = "BFF_ADVERTISEMENT_FUNNEL_PAGE_ERROR";

export const BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST = "BFF_ADVERTISEMENT_ORDER_INVITE_REQUEST";
export const BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS = "BFF_ADVERTISEMENT_ORDER_INVITE_SUCCESS";
export const BFF_ADVERTISEMENT_ORDER_INVITE_ERROR = "BFF_ADVERTISEMENT_ORDER_INVITE_ERROR";

export const BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE = "BFF_CLEAR_FIELDS_ADVERTISEMENT_STORE";
