import {formatAmountWithNullChecking} from "../../../utils/stringFormat";

import {INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT} from "../constants";

export const getDepositValuesCards = (params) => {
    const {
        depositValues,
        isCivil,
        isLoading,
        isAccordion,
        enableIndividualDeposit,
        isProjectCardPage,
        isObjectCardPage,
    } = params;

    const {
        nonDistributedDepositAmount = 0, //Нераспред. депозит
        availableForOrdersAmount = 0,// Доступно для заказов
        availableForPaymentsAmount = 0, // Доступно для выплат
        awaitingConfirmationPaymentsAmount = 0, // Оплаты на рассмотрении
        depositAmount = 0, // Депозит
        inProgressPaymentsAmount = 0, // В процессе оплаты
        limitAmount = 0, // Лимит заказов
        limitSpentAmount = 0, // Израсходовано
        ordersLimitBalance = 0, // Баланс лимита заказов
        totalDeposit = 0, // Общий депозит
        enableIndividualProjectAndObjectDeposit, // Индивидуальный депозит проектов и объектов
        enableNonDistributedClientDeposit, // Для компании нужен нераспределенный депозит
        clientDepositAmount, // Депозит компании
    } = depositValues;

    const projectPageText = isProjectCardPage ? " по проекту" : "";
    const objectPageText = isObjectCardPage ? " по объекту" : "";
    const paymentTypeText = isCivil ? "НДФЛ" : "НПД";

    const getDepositTooltip = () => {
        if (!enableIndividualProjectAndObjectDeposit) {
            return null;
        }

        return {
            text: isCivil?
                "Содержит сумму распределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НДФЛ" :
                "Содержит сумму распределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НПД",
            type: "light",
            position: "bottom-left",
        };
    };

    const getNonDistributedDepositTooltipText = () => {
        if (enableIndividualProjectAndObjectDeposit) {
            return INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.NON_DISTRIBUTED_DEPOSIT
                .replaceAll(":paymentType", paymentTypeText);
        }

        return isCivil ?
            "Содержит сумму нераспределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НДФЛ. Этот депозит не может использоваться в заказах и оплатах до тех пор, пока он не будет распределен по объектам компании. Для распределения депозита по объектам перейдите в раздел \"Компания → Проекты\", выберите необходимый в проекте объект и нажмите \"Пополнить депозит объекта\"" :
            "Содержит сумму нераспределенного по объектам депозита компании по счету для выплат исполнителям с типом налогообложения НПД. Этот депозит не может использоваться в заказах и оплатах до тех пор, пока он не будет распределен по объектам компании. Для распределения депозита по объектам перейдите в раздел \"Компания → Проекты\", выберите необходимый в проекте объект и нажмите \"Пополнить депозит объекта\"";
    };

    const isHideValue = !enableIndividualProjectAndObjectDeposit && (isProjectCardPage || isObjectCardPage);

    const getOrdersLimitTooltipText = () => {
        if (enableIndividualDeposit) {
            if (isProjectCardPage) {
                return INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.PROJECT_ORDERS_LIMIT
                    .replaceAll(":paymentType", paymentTypeText);
            }

            if (isObjectCardPage) {
                return INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.OBJECT_ORDERS_LIMIT
                    .replaceAll(":paymentType", paymentTypeText);
            }

            return INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.ORDERS_LIMIT
                .replaceAll(":paymentType", paymentTypeText);
        }

        return isCivil ?
            "Максимальная сумма, на которую единовременно могут быть созданы заказы для работающих по договорам НДФЛ исполнителей. Рассчитывается как сумма депозита и лимита для заказов без обеспечения (НДФЛ)" :
            "Максимальная сумма, на которую единовременно могут быть созданы заказы и задания для Самозанятых. Рассчитывается как сумма депозита и лимита для заказов без обеспечения (НПД)";
    };

    return {
        totalDeposit: {
            isLoading,
            title: "Общий депозит",
            value: `${formatAmountWithNullChecking(totalDeposit)} ₽`,
            helpTooltip: {
                text: INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.TOTAL_DEPOSIT.replace(":paymentType", paymentTypeText),
                type: "light",
                position: "bottom-left",
            },
            isVisible: Boolean(enableIndividualProjectAndObjectDeposit),
        },
        nonDistributedDeposit: {
            isLoading,
            title: "Нераспред. депозит",
            value: `${formatAmountWithNullChecking(nonDistributedDepositAmount)} ₽`,
            helpTooltip: {
                text: getNonDistributedDepositTooltipText(),
                type: "light",
                position: "bottom-left",
            },
            isVisible: Boolean(enableNonDistributedClientDeposit),
        },
        deposit:  {
            isLoading,
            title: "Депозит",
            value: isHideValue ? "Не установлен" : `${formatAmountWithNullChecking(depositAmount)} ₽`,
            helpTooltip: getDepositTooltip(),
            isVisible: !enableIndividualProjectAndObjectDeposit,
        },
        clientDeposit: {
            isLoading,
            title: "Депозит компании",
            value: isHideValue ? "Не установлен" : `${formatAmountWithNullChecking(clientDepositAmount)} ₽`,
            helpTooltip: {
                text: INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.CLIENT_DEPOSIT.replace(":paymentType", paymentTypeText),
                type: "light",
                position: "bottom-left",
            },
            isVisible: Boolean(enableIndividualProjectAndObjectDeposit) && !enableIndividualDeposit,
        },
        projectObjectDeposit: {
            isLoading,
            title: isProjectCardPage
                ? "Депозит проекта"
                : "Депозит объекта",
            value: enableIndividualDeposit
                ? `${formatAmountWithNullChecking(depositAmount)} ₽`
                : "Не установлен",
            helpTooltip: enableIndividualDeposit
                ? {
                    text: isProjectCardPage
                        ? INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.PROJECT_DEPOSIT
                        : INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT.OBJECT_DEPOSIT,
                    type: "light",
                    position: "bottom-left",
                }
                : undefined,
            isVisible: isProjectCardPage || isObjectCardPage,
        },
        awaitingConfirmationPayments: {
            isLoading,
            title: `Оплаты на рассмотрении${projectPageText}${objectPageText}`,
            value: `${formatAmountWithNullChecking(awaitingConfirmationPaymentsAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Содержит сумму выплат по договорам НДФЛ, которые находятся на подтверждении у исполнителей и заказчиков. Для ознакомления со списком оплат на рассмотрении перейдите в раздел \"Депозит (НДФЛ) → Оплаты на рассмотрении\"" :
                    "Содержит сумму выплат Самозанятым и ИП, которые находятся на подтверждении у исполнителей и заказчиков, сумму оплат по заданиям на проверке. Для ознакомления со списком оплат на рассмотрении перейдите в раздел \"Депозит (НПД) → Оплаты на рассмотрении\"",
                type: "light",
                position: "bottom-left",
            },
        },
        inProgressPayments: {
            isLoading,
            title: `В процессе оплаты${projectPageText}${objectPageText}`,
            value: `${formatAmountWithNullChecking(inProgressPaymentsAmount)} ₽`,
            helpTooltip: {
                text: isCivil?
                    "Содержит суммы выплат исполнителям с типом налогообложения НДФЛ и выплат по патентам, которые находятся в процессе проведения в банке, а также сумму, которая зарезервирована под реестровые и созданные через API выплаты для таких исполнителей. Для ознакомления со списком перейдите в раздел \"Депозит (НДФЛ) → В процессе оплаты\"" :
                    "Содержит сумму выплат Самозанятым и ИП, которые находятся в процессе проведения в банке, а также сумму, которая зарезервирована под реестровые и созданные через API выплаты Самозанятым, оплаты по заданиям в процессе согласования исполнителем или администрацией Наймикс. Для ознакомления со списком перейдите в раздел \"Депозит (НПД) → В процессе оплаты\"",
                type: "light",
                position: "bottom-left",
            },
        },
        availableForPayments: {
            isLoading,
            title: `Доступно для выплат${projectPageText}${objectPageText}`,
            value: isHideValue ? "Не установлено" : `${formatAmountWithNullChecking(availableForPaymentsAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Содержит сумму свободных средств для выплат по договорам НДФЛ. Рассчитывается как разница между депозитом и выплатами \"в процессе\"" :
                    "Содержит сумму свободных средств для выплат Самозанятым и ИП. Рассчитывается как разница между депозитом и выплатами \"в процессе\"",
                type: "light",
                position: "bottom-left",
            },
        },
        limit: {
            isLoading,
            title: `Лимит заказов${projectPageText}${objectPageText}`,
            value: isHideValue ? "Не установлен" : `${formatAmountWithNullChecking(limitAmount)} ₽`,
            helpTooltip: {
                text: getOrdersLimitTooltipText(),
                type: "light",
                position: "bottom-left",
            },
            subValue: enableIndividualProjectAndObjectDeposit && `${formatAmountWithNullChecking(ordersLimitBalance)} ₽`,
        },
        limitSpent: {
            isLoading,
            title: `Израсходовано лимита${projectPageText}${objectPageText}`,
            value: `${formatAmountWithNullChecking(limitSpentAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Сумма, которая в настоящее время зарезервирована в заказах для работающих по договорам НДФЛ исполнителей, с учетом комиссии" :
                    "Сумма, которая в настоящее время зарезервирована в заказах и заданиях для Самозанятых с учетом комиссии. Для ознакомления со списком заказов и заданий, которые зарезервировали лимит, перейдите в раздел \"Депозит (НПД) → Израсходовано лимита\"",
                type: "light",
                position: "bottom-left",
            },
        },
        availableForOrders: {
            isLoading,
            title: `Доступно для заказов${projectPageText}${objectPageText}`,
            value: isHideValue ? "Не установлено" : `${formatAmountWithNullChecking(availableForOrdersAmount)} ₽`,
            helpTooltip: {
                text: isCivil ?
                    "Остаток суммы лимита для публикации новых заказов для работающих по договорам НДФЛ исполнителей. Рассчитывается как разница между лимитом заказов и израсходованным лимитом" :
                    "Остаток суммы лимита для публикации новых заказов и заданий для Самозанятых. Рассчитывается как разница между лимитом заказов и израсходованным лимитом",
                type: "light",
                position: isAccordion ? "bottom-left" : "bottom-right",
            },
        },
    };
};