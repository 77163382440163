import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import LogoThumbnail from "../../../components/LogoThumbnail";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import NmRatingFieldsForm from "../../../components/NmRatingFieldsForm";
import {ReactComponent as LogoIcon} from "../../../images/company-logo.svg";
import {DEPOSIT_SUB_PAGE} from "../client-list";
import DepositCommissionHistory from "../commission-history";
import DepositFilter from "../deposit-filter";
import DepositOfFund from "../deposit-of-funds";
import DepositReturn from "../return";
import MinCommissionHistory from "./components/min-commission-history";
import DepositRateEdit from "./components/rate-edit";

import dateFormat from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {floatToPercent, removeFinalZeros} from "../../../utils/mathHelper";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {
    formatAmount,
    formatNumber,
    formatNumberWithComma,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COMMISSION_TYPE} from "../../../constants/deposit";
import {ORDER_WORK_REPORT_TYPE} from "../../../constants/finance";
import {
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
} from "../../../constants/links";
import {ADMIN} from "../../../constants/roles";

import {updateClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {
    updateClientDepositFavorite,
} from "../../../ducks/deposit";

import {depositType} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

const COMMISSION_HISTORY_TITLE = {
    [COMMISSION_TYPE.SMZ_ORDER]: "История изменений комиссии исполнителей за выплаты по заказам (НПД)",
    [COMMISSION_TYPE.SMZ_REGISTRY]: "История изменений комиссии исполнителей за реестровые выплаты (НПД)",
    [COMMISSION_TYPE.CIVIL_ORDER]: "История изменений комиссии исполнителей за выплаты по заказам (НДФЛ)",
    [COMMISSION_TYPE.CIVIL_REGISTRY]: "История изменений комиссии исполнителей за реестровые выплаты (НДФЛ)",
    [COMMISSION_TYPE.INDIVIDUAL_ORDER]: "История изменений комиссии исполнителей за выплаты по заказам (ИП)",
    [COMMISSION_TYPE.INDIVIDUAL_REGISTRY]: "История изменений комиссии исполнителей за реестровые выплаты (ИП)",
};

class DepositList extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
        list: PropTypes.arrayOf(depositType),
        progressAdd: PropTypes.bool,
        isCivil: PropTypes.bool,
    };

    static defaultProps = {
        totalPages: 0,
        list: [],
        progressAdd: false,
        isCivil: false,
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {match} = props;
        const {params} = match;
        const {clientId} = params;

        this.clientId = clientId;

        this.role = ls(USER_ROLE);
        this.isEditable = this.role === ADMIN;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            isOpenHistoryCommission: false,
            isOpenDepositOfFunds: false,
            isOpenDepositReturn: false,
            depositOfFundsDependentData: {},
            isShowConfirm: false,
            client: {},
            favoriteData: {},
            filter: {},
            editCommissionsType: null,
        };
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        const {onFetchList} = this.props;

        const {
            pageNum,
            pageSize,
            filter: {
                nameSubstringFilter,
            },
        } = this.state;

        onFetchList({
            nameSubstringFilter,
            pageNum,
            pageSize,
        });
    };

    openDepositOfFundsModal = depositOfFundsDependentData => {
        this.setState({
            isOpenDepositOfFunds: true,
            depositOfFundsDependentData,
        });
    };

    openDepositReturnModal = depositReturnDependentData => {
        this.setState({
            isOpenDepositReturn: true,
            depositReturnDependentData,
        });
    };

    closeDepositOfFundsModal = () => {
        this.setState({
            isOpenDepositOfFunds: false,
            depositOfFundsDependentData: {},
        });
    };

    closeDepositReturnModal = () => {
        this.setState({
            isOpenDepositReturn: false,
            depositReturnDependentData: {},
        });
    };

    changePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    closeConfirm = () => {
        this.setState({
            favoriteData: {},
            isShowConfirm: false,
        });
    };

    updateFavorite = () => {
        const {
            updateClientDepositFavorite,
            isCivil,
        } = this.props;

        const {
            favoriteData: {
                clientId,
                favorite,
            },
        } = this.state;

        updateClientDepositFavorite({
            data: {
                clientId,
                favorite,
            },
            isCivil,
            onSuccess: this.fetchList,
        });

        this.closeConfirm();
    };

    isAccessEditCommissions = () => {
        const {
            isCivil,
        } = this.props;

        if (isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.editInterestRateNdfl,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editInterestRateNpd,
        ]);
    };

    isAccessRefill = () => {
        const {
            isCivil,
        } = this.props;

        if (isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.depositNdflRefill,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.depositNpdRefill,
        ]);
    };

    isAccessRefund = () => {
        const {
            isCivil,
        } = this.props;

        if (isCivil) {
            return isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.depositNdflRefund,
            ]);
        }

        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.depositNpdRefund,
        ]);
    };

    getOptions() {
        const {
            isCivil,
            t,
        } = this.props;

        const isAccessEditDeposit = isAccessByRestrictions([
            isCivil && CLIENT_USER_RESTRICTIONS_VARIABLE.editDepositNdfl,
            !isCivil && CLIENT_USER_RESTRICTIONS_VARIABLE.editDepositNpd,
        ].filter(Boolean));

        return [
            {
                key: "make-deposit",
                text: t("deposit-list.make-deposit-button"),
                value: "make-deposit",
                visible: this.isEditable
                    && isAccessEditDeposit
                    && this.isAccessRefill(),
            },
            {
                key: "return-deposit",
                text: t("deposit-list.return-deposit-button"),
                value: "return-deposit",
                visible: this.isEditable
                    && isAccessEditDeposit
                    && this.isAccessRefund(),
            },
            {
                key: "edit-commissions",
                text: `Редактировать ставки ${!isCivil ? "НПД" : ""}`,
                value: "edit-commissions",
                visible: [ADMIN].includes(this.role)
                    && isAccessEditDeposit
                    && this.isAccessEditCommissions(),
            },
            {
                key: "edit-individual-commissions",
                text: "Редактировать ставки ИП",
                value: "edit-individual-commissions",
                visible: !isCivil
                    && [ADMIN].includes(this.role)
                    && isAccessEditDeposit
                    && this.isAccessEditCommissions(),
            },
        ];
    }

    onClickActionItem(option, payment) {
        const {isCivil} = this.props;

        const {value: action} = option;
        const {clientId, clientName} = payment;

        switch (action) {
            //Редактировать ставки
            case "edit-commissions":
                this.setState({
                    payment,
                    editCommissionsType: isCivil
                        ? ORDER_WORK_REPORT_TYPE.CIVIL
                        : ORDER_WORK_REPORT_TYPE.SMZ,
                });

                return;
            //Редактировать ставки
            case "edit-individual-commissions":
                this.setState({
                    payment,
                    editCommissionsType: ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                });

                return;
            //Внести депозит
            case "make-deposit": {
                this.openDepositOfFundsModal({clientId, clientName});
                return;
            }
            //Вернуть депозит
            case "return-deposit": {
                this.openDepositReturnModal({clientId, clientName});

                return;
            }
            default:
                break;
        }
    }

    renderActionCell(deposit) {
        const options = this.getOptions();

        if (options.length === 0) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.onClickActionItem(option, deposit);
                }}
            />
        );
    }

    renderDeferredCommissionRate = (deferredCommission, deferredDatetime, deferredRegionTime) => {
        if (!deferredCommission) {
            return null;
        }

        const rate = floatToPercent(deferredCommission);
        const startDate = dateFormat(deferredDatetime);
        const timezone = deferredRegionTime === "MSK" ? "МСК" : deferredRegionTime;

        return `${rate}% с ${startDate} (${timezone})`;
    };

    getCommissionRate(item, commissionType) {
        const {
            currentCommissionRate,
            contractorOrderCommission,
            contractorRegistryCommission,
            contractorIndividualOrderPaymentsCommission,
            contractorIndividualRegistryPaymentsCommission,
            minimalCommissionIndividualPaymentsAmount,
        } = item;

        if (commissionType === COMMISSION_TYPE.INDIVIDUAL_ORDER) {
            return contractorIndividualOrderPaymentsCommission;
        }

        if (commissionType === COMMISSION_TYPE.INDIVIDUAL_REGISTRY) {
            return contractorIndividualRegistryPaymentsCommission;
        }

        if (commissionType === COMMISSION_TYPE.INDIVIDUAL) {
            return minimalCommissionIndividualPaymentsAmount;
        }

        if ([COMMISSION_TYPE.SMZ_ORDER, COMMISSION_TYPE.CIVIL_ORDER].includes(commissionType)) {
            return contractorOrderCommission;
        }

        if ([COMMISSION_TYPE.SMZ_REGISTRY, COMMISSION_TYPE.CIVIL_REGISTRY].includes(commissionType)) {
            return contractorRegistryCommission;
        }

        return currentCommissionRate;
    };

    showMinCommissionHistory = (isShow, item, commissionType) => {
        return () => {
            this.setState({
                isShowMinCommissionHistory: isShow,
                item,
                minCommissionHistoryType: commissionType || null,
            });
        };
    };

    renderCommissionRate(item, commissionType) {
        const {
            clientName,
            clientId,
        } = item;
        const rate = floatToPercent(this.getCommissionRate(item, commissionType));

        return (
            <div>
                <div className="flex flex-vertical-center">
                    <div
                        className="deposit-list__rate mr-2"
                        onClick={() => {
                            this.toggleHistoryCommissionModal({
                                clientId,
                                commissionType,
                                title: `${COMMISSION_HISTORY_TITLE[commissionType] || "История изменений комиссии компании за выплаты"} "${clientName}"`,
                            });
                        }}
                    >
                        {removeFinalZeros(rate)}
                    </div>
                </div>
            </div>
        );
    }

    renderConfirmWindow = () => {
        const {t} = this.props;

        const {
            isShowConfirm,
            favoriteData: {
                clientName,
                favorite,
            },
        } = this.state;

        const content = t(favorite ? "deposit-list.favorite-add" : "deposit-list.favorite-remove", {clientName});

        return (
            isShowConfirm &&
            <NmConfirmV2
                content={content}
                onCancel={this.closeConfirm}
                onConfirm={this.updateFavorite}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    renderAmount(amount) {
        const fixedNumber = 2;

        return amount === null ? "" : formatAmount(formatNumber(amount, fixedNumber));
    }

    renderUsedToBalanceLimit({ordersUnsecured, ordersLimitAmount, ordersLimitBalance}) {
        return ordersUnsecured &&
            <p>
                {" "}
                {this.renderAmount(ordersLimitAmount)}
                {" "}
                /
                {" "}
                {this.renderAmount(ordersLimitBalance)}
                {" "}
            </p>;
    }

    handleOnChangeFavorite = (e, data) => {
        const {
            rating,
            clientId,
            clientName,
        } = data;

        this.setState({
            isShowConfirm: true,
            favoriteData: {
                favorite: !!rating,
                clientId,
                clientName,
            },
        });
    };

    renderFavorite(item) {
        const {favorite, clientId, clientName} = item;

        return (
            <NmRatingFieldsForm
                handleOnRate={(e, {value}) => {
                    this.handleOnChangeFavorite(e, {
                        rating: value,
                        clientId,
                        clientName,
                    },
                    );
                }}
                children={
                    [
                        {
                            rating: favorite ? 1 : 0,
                            maxRating: 1,
                            classNameRating: "deposit-list__rating",
                        },
                    ]
                }
            />
        );
    }

    renderClientName(item) {
        const {
            isCivil,
        } = this.props;

        const {
            clientId,
            clientName,
            brand,
        } = item;

        const link = (isCivil ? LINK_CLIENT_NDFL_DEPOSIT_LIST : LINK_CLIENT_DEPOSIT_LIST)
            .replace(":clientId", clientId)
            .replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS);

        return (
            <div className="flex flex-vertical-center">
                <ExtLink
                    to={link}
                    className="deposit-list__primary-header-link"
                    historyEnabled
                >
                    {clientName}
                    {brand && `(${brand})`}
                </ExtLink>
                {this.renderFavorite(item)}
            </div>
        );
    }

    renderLogo({base64Logo}) {
        return (
            base64Logo ?
                <LogoThumbnail
                    className="deposit-list__logo"
                    src={`data:image/jpeg;charset=utf-8;base64, ${base64Logo}`}
                /> :
                <LogoIcon className="deposit-list__logo deposit-list__logo_empty" />
        );
    }

    renderMinCommission = (item, commissionType) => {
        const {isCivil} = this.props;

        const {
            minCommission,
            threshold,
            minimalCommissionNdflPaymentsAmount = 0,
        } = item;

        return (
            <div
                className="deposit-list__min-com-link"
                onClick={this.showMinCommissionHistory(true, item, commissionType)}
            >
                {
                    isCivil ?
                        `${minimalCommissionNdflPaymentsAmount} ₽` :
                        `${minCommission} ₽ за выплату менее ${threshold} ₽`
                }
            </div>
        );
    };

    renderMinCommissionHistory() {
        const {isCivil} = this.props;
        const {
            isShowMinCommissionHistory,
            minCommissionHistoryType,
            item,
        } = this.state;

        return (isShowMinCommissionHistory &&
            <MinCommissionHistory
                onClose={this.showMinCommissionHistory(false)}
                client={item}
                isCivil={isCivil}
                commissionType={minCommissionHistoryType}
            />);
    };

    renderDefferedMinCommission = (item) => {
        const {
            isCivil,
        } = this.props;

        const {
            deferredMinCommission,
            deferredThreshold,
            deferredMinCommissionDatetime,
            deferredMinCommissionRegionTime,
            deferredMinimalCommissionNdflPaymentsAmount,
            deferredMinimalCommissionNdflPaymentsAmountDatetime,
            deferredMinimalCommissionNdflPaymentsAmountRegionTime,
        } = item;

        if (
            (isCivil && isNullOrWhitespace(deferredMinimalCommissionNdflPaymentsAmountDatetime)) ||
            (!isCivil && isNullOrWhitespace(deferredMinCommissionDatetime))
        ) {
            return null;
        }

        if (isCivil) {
            return (
                <div>
                    {`${deferredMinimalCommissionNdflPaymentsAmount} ₽ от ${dateFormat(deferredMinimalCommissionNdflPaymentsAmountDatetime)} (${deferredMinimalCommissionNdflPaymentsAmountRegionTime === "MSK" ? "МСК" : deferredMinimalCommissionNdflPaymentsAmountRegionTime})`}
                </div>
            );
        }

        return (
            <div>
                {`${deferredMinCommission} ₽ за выплату менее ${deferredThreshold} ₽ от ${dateFormat(deferredMinCommissionDatetime)} (${deferredMinCommissionRegionTime === "MSK" ? "МСК" : deferredMinCommissionRegionTime})`}
            </div>
        );
    };

    getCards = (item) => {
        const {
            isCivil,
        } = this.props;
        const {
            depositAmount,
            nonDistributedDepositAmount,
            availableForOrdersAmount,
            availableForPaymentsAmount,
            awaitingConfirmationPaymentsAmount,
            inProgressPaymentsAmount,
            limitAmount,
            limitSpentAmount,
            deferredCurrentCommissionRate,
            deferredDatetime,
            deferredRegionTime,
            deferredContractorOrderCommission,
            deferredContractorOrderCommissionDatetime,
            deferredContractorOrderCommissionRegionTime,
            deferredContractorRegistryCommission,
            deferredContractorRegistryCommissionDatetime,
            deferredContractorRegistryCommissionRegionTime,
            ordersLimitBalance,
            totalDeposit,
            enableIndividualProjectAndObjectDeposit,
            enableNonDistributedClientDeposit,
            deferredContractorIndividualOrderPaymentsCommission,
            deferredContractorIndividualOrderPaymentsCommissionDatetime,
            deferredContractorIndividualOrderPaymentsCommissionRegionTime,
            deferredMinimalCommissionIndividualPaymentsAmount,
            deferredMinimalCommissionIndividualPaymentsAmountDatetime,
            deferredMinimalCommissionIndividualPaymentsAmountRegionTime,
            deferredContractorIndividualRegistryPaymentsCommission,
            deferredContractorIndividualRegistryPaymentsCommissionDatetime,
            deferredContractorIndividualRegistryPaymentsCommissionRegionTime,
        } = item;

        const isIndividualProjectAndObjectDeposit = Boolean(enableIndividualProjectAndObjectDeposit);
        const isNonDistributedDepositAmount = Boolean(enableNonDistributedClientDeposit);

        const totalDepositCard = {
            title: "Общий депозит, ₽",
            value: formatNumberWithComma(totalDeposit),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3",
        };
        const depositCard = {
            title: enableIndividualProjectAndObjectDeposit ? "Депозит компании, ₽" : "Депозит, ₽",
            value: formatNumberWithComma(depositAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3",
        };
        const nonDistributedDepositCard = {
            title: "Нераспределенный депозит, ₽",
            value: formatNumberWithComma(nonDistributedDepositAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xl-0",
        };
        const awaitingConfirmationPaymentsCard = {
            title: "Оплаты на рассмотрении, ₽",
            value: formatNumberWithComma(awaitingConfirmationPaymentsAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3",
        };
        const inProcessCard = {
            title: "В процессе оплаты, ₽",
            value: formatNumberWithComma(inProgressPaymentsAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xl-0",
        };
        const availableForPaymentsAmountCard = {
            title: "Доступно для выплат, ₽",
            value: formatNumberWithComma(availableForPaymentsAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xl-0",
        };
        const limitAmountCard = {
            title: "Лимит заказов, ₽",
            value: formatNumberWithComma(limitAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3",
            subValue: enableIndividualProjectAndObjectDeposit && formatNumberWithComma(ordersLimitBalance),
        };
        const limitSpentAmountCard = {
            title: "Израсходовано лимита, ₽",
            value: formatNumberWithComma(limitSpentAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
        };
        const availableForOrdersAmountCard = {
            title: "Доступно для заказов, ₽",
            value: formatNumberWithComma(availableForOrdersAmount),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
        };
        const paymentsCommissionCard = {
            title: `Комиссия компании за выплаты ${isCivil ? "(НДФЛ)" : "(НПД)"}, %`,
            value: this.renderCommissionRate(
                item,
                isCivil
                    ? COMMISSION_TYPE.CIVIL
                    : COMMISSION_TYPE.SMZ,
            ),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
            subValue: this.renderDeferredCommissionRate(deferredCurrentCommissionRate, deferredDatetime, deferredRegionTime),
        };
        const ordersCommissionCard = {
            title: `Комиссия исполнителей за выплаты по заказам ${isCivil ? "(НДФЛ)" : "(НПД)"}, %`,
            value: this.renderCommissionRate(
                item,
                isCivil
                    ? COMMISSION_TYPE.CIVIL_ORDER
                    : COMMISSION_TYPE.SMZ_ORDER,
            ),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
            subValue: this.renderDeferredCommissionRate(
                deferredContractorOrderCommission,
                deferredContractorOrderCommissionDatetime,
                deferredContractorOrderCommissionRegionTime,
            ),
        };
        const minCommissionRegistryPaymentsCard = {
            title: `Комиссия исполнителей за реестровые выплаты ${isCivil ? "(НДФЛ)" : "(НПД)"}, %`,
            value: this.renderCommissionRate(
                item,
                isCivil
                    ? COMMISSION_TYPE.CIVIL_REGISTRY
                    : COMMISSION_TYPE.SMZ_REGISTRY,
            ),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
            subValue: this.renderDeferredCommissionRate(
                deferredContractorRegistryCommission,
                deferredContractorRegistryCommissionDatetime,
                deferredContractorRegistryCommissionRegionTime,
            ),
        };
        const minCommissionNdflCard = {
            title: isCivil ? "Минимальная комиссия компании за выплаты НДФЛ, ₽" : "Дополнительная комиссия компании для НПД, ₽",
            wrapValue: true,
            value: this.renderMinCommission(
                item,
                isCivil
                    ? COMMISSION_TYPE.CIVIL
                    : COMMISSION_TYPE.SMZ,
            ),
            className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
            subValue: this.renderDefferedMinCommission(item),
        };
        const individualEntrepreneurCommissionsCards = !isCivil
            ? [
                {
                    title: "Комиссия компании за выплаты ИП, %",
                    value: this.renderCommissionRate(
                        item,
                        COMMISSION_TYPE.INDIVIDUAL,
                    ),
                    className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
                    subValue: this.renderDeferredCommissionRate(
                        deferredMinimalCommissionIndividualPaymentsAmount,
                        deferredMinimalCommissionIndividualPaymentsAmountDatetime,
                        deferredMinimalCommissionIndividualPaymentsAmountRegionTime,
                    ),
                },
                {
                    title: "Комиссия исполнителей за выплаты по заказам ИП, %",
                    value: this.renderCommissionRate(
                        item,
                        COMMISSION_TYPE.INDIVIDUAL_ORDER,
                    ),
                    className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
                    subValue: this.renderDeferredCommissionRate(
                        deferredContractorIndividualOrderPaymentsCommission,
                        deferredContractorIndividualOrderPaymentsCommissionDatetime,
                        deferredContractorIndividualOrderPaymentsCommissionRegionTime,
                    ),
                },
                {
                    title: "Комиссия исполнителей за реестровые выплаты ИП, %",
                    value: this.renderCommissionRate(
                        item,
                        COMMISSION_TYPE.INDIVIDUAL_REGISTRY,
                    ),
                    className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
                    subValue: this.renderDeferredCommissionRate(
                        deferredContractorIndividualRegistryPaymentsCommission,
                        deferredContractorIndividualRegistryPaymentsCommissionDatetime,
                        deferredContractorIndividualRegistryPaymentsCommissionRegionTime,
                    ),
                },
                {
                    title: "Дополнительная комиссия компании для ИП, ₽",
                    wrapValue: true,
                    value: this.renderMinCommission({
                        ...item,
                        minCommission: item.minIndividualCommissionAmount,
                        threshold: item.individualPaymentsThresholdAmount,
                    },
                    COMMISSION_TYPE.INDIVIDUAL,
                    ),
                    className: "col-16 col-md-8 col-xl-3 col-xxl-3 mt-md-4 mt-xxl-4",
                    subValue: this.renderDefferedMinCommission({
                        deferredMinCommission: item.deferredMinIndividualCommissionAmount,
                        deferredThreshold: item.deferredIndividualPaymentsThresholdAmount,
                        deferredMinCommissionDatetime: item.deferredMinIndividualCommissionAmountDatetime,
                        deferredMinCommissionRegionTime: item.deferredMinIndividualCommissionAmountRegionTime,
                    }),
                },
            ]
            : [];

        if (isNonDistributedDepositAmount && isIndividualProjectAndObjectDeposit) {
            return [
                totalDepositCard,
                depositCard,
                nonDistributedDepositCard,
                {
                    ...awaitingConfirmationPaymentsCard,
                    className: `${awaitingConfirmationPaymentsCard.className} mt-md-4 mt-xl-0`,
                },
                inProcessCard,
                {
                    ...availableForPaymentsAmountCard,
                    className: `${availableForPaymentsAmountCard.className} mt-xl-4`,
                },
                {
                    ...limitAmountCard,
                    className: `${limitAmountCard.className} mt-md-4`,
                },
                limitSpentAmountCard,
                availableForOrdersAmountCard,
                paymentsCommissionCard,
                ordersCommissionCard,
                minCommissionRegistryPaymentsCard,
                minCommissionNdflCard,
                ...individualEntrepreneurCommissionsCards,
            ];
        }

        if (isIndividualProjectAndObjectDeposit) {
            return [
                totalDepositCard,
                depositCard,
                {
                    ...awaitingConfirmationPaymentsCard,
                    className: `${awaitingConfirmationPaymentsCard.className} mt-md-4 mt-xl-0`,
                },
                inProcessCard,
                availableForPaymentsAmountCard,
                {
                    ...limitAmountCard,
                    className: `${limitAmountCard.className} mt-md-4`,
                },
                limitSpentAmountCard,
                availableForOrdersAmountCard,
                paymentsCommissionCard,
                ordersCommissionCard,
                minCommissionRegistryPaymentsCard,
                minCommissionNdflCard,
                ...individualEntrepreneurCommissionsCards,
            ];
        }

        if (isNonDistributedDepositAmount) {
            return [
                depositCard,
                nonDistributedDepositCard,
                {
                    ...awaitingConfirmationPaymentsCard,
                    className: `${awaitingConfirmationPaymentsCard.className} mt-md-4 mt-xl-0`,
                },
                inProcessCard,
                availableForPaymentsAmountCard,
                {
                    ...limitAmountCard,
                    className: `${limitAmountCard.className} mt-md-4`,
                },
                limitSpentAmountCard,
                availableForOrdersAmountCard,
                paymentsCommissionCard,
                ordersCommissionCard,
                minCommissionRegistryPaymentsCard,
                minCommissionNdflCard,
                ...individualEntrepreneurCommissionsCards,
            ];
        }

        return [
            depositCard,
            awaitingConfirmationPaymentsCard,
            inProcessCard,
            availableForPaymentsAmountCard,
            {
                ...limitAmountCard,
                className: `${limitAmountCard.className} mt-md-4 mt-xl-0`,
            },
            {
                ...limitSpentAmountCard,
                className: `${limitSpentAmountCard.className} mt-xl-4`,
            },
            availableForOrdersAmountCard,
            paymentsCommissionCard,
            ordersCommissionCard,
            minCommissionRegistryPaymentsCard,
            minCommissionNdflCard,
            ...individualEntrepreneurCommissionsCards,
        ];
    };

    getRows() {
        const {
            list,
        } = this.props;

        return list.map((item) => {
            const {
                depositId,
            } = item;

            return {
                ...item,
                key: depositId,
                number: item.registrySeqNum,
                avatar: this.renderLogo(item),
                contentRow: (
                    <NmListCard
                        avatar
                        noDivider
                        alignItems="flex-start"
                        classNamePrimaryHeader="deposit-list__primary-header"
                        classNameMainContent="col-16 col-md-16 col-xl-16 col-xxl-16"
                        primaryHeader={this.renderClientName(item)}
                        primaryHeaderNoWrap
                        cards={this.getCards(item)}
                        labels={[]}
                        cardsWithContainer
                        cardsContainerClassName="deposit-list__item-cards-container col-16 col-md-16 col-xl-16 col-xxl-16 mt-md-4 mt-xxl-0"
                        cardsContainerRowClassName="justify-content-start"
                        actionsClassName="col-1 flex-aligned-end flex-content-end"
                        actions={this.renderActionCell(item)}
                        isFixedActions
                    />
                ),
            };
        });
    }

    toggleHistoryCommissionModal = (data) => {
        this.setState(prevState => ({
            ...prevState,
            historyCommissionModalData: data,
            isOpenHistoryCommission: !prevState.isOpenHistoryCommission,
        }));
    };

    renderCommissionHistory() {
        const {commissionHistory} = this.props;
        const {isOpenHistoryCommission, historyCommissionModalData} = this.state;

        return isOpenHistoryCommission ?
            <DepositCommissionHistory
                {...commissionHistory}
                data={historyCommissionModalData}
                close={this.toggleHistoryCommissionModal}
            /> :
            null;
    }

    renderDepositOfFunds() {
        const {
            onAddDeposit,
            progressAction,
            isCivil,
        } = this.props;

        const {isOpenDepositOfFunds, depositOfFundsDependentData} = this.state;

        return isOpenDepositOfFunds ? (
            <DepositOfFund
                onAddDeposit={onAddDeposit}
                dependentData={depositOfFundsDependentData}
                close={this.closeDepositOfFundsModal}
                isLoading={progressAction}
                isCivil={isCivil}
            />
        ) : null;
    }

    renderDepositReturn() {
        const {
            onReturnDeposit,
            progressAction,
            isCivil,
        } = this.props;

        const {isOpenDepositReturn, depositReturnDependentData} = this.state;

        return isOpenDepositReturn ? (
            <DepositReturn
                onReturnDeposit={onReturnDeposit}
                dependentData={depositReturnDependentData}
                close={this.closeDepositReturnModal}
                isLoading={progressAction}
                isCivil={isCivil}
            />
        ) : null;
    }

    onCloseRateEdit = () => {
        this.setState({
            payment: null,
            editCommissionsType: null,
        });
    };

    renderEditRate = () => {
        const {isCivil} = this.props;
        const {
            payment,
            editCommissionsType,
        } = this.state;

        if (!payment) {
            return null;
        }

        return (
            <DepositRateEdit
                isCivil={isCivil}
                type={editCommissionsType}
                payment={payment}
                onClose={this.onCloseRateEdit}
                onFetchList={this.fetchList}
            />
        );
    };

    sendFilter = (filter) => {
        this.setState({
            pageNum: 1,
            filter,
        },
        () => {
            setTimeout(this.fetchList, 500);
        },
        );
    };

    get classNames() {
        const classNames = ["deposit-list"];

        if (!this.clientId) {
            classNames.push("deposit-list_overflow");
        }

        return classNames.join(" ");
    }

    render() {
        const {
            totalPages,
            totalCount,
            header,
            list,
            progressList,
        } = this.props;
        const {pageNum, pageSize} = this.state;

        return (
            <NmPage
                header={
                    <NmPageHeader text={header} />
                }
                typeFilter="vertical"
                className={this.classNames}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                filtersBase={
                    <DepositFilter
                        sendFilter={this.sendFilter}
                    />
                }
                onChangePageSize={this.changePageSize}
                onPaginationChange={this.changePageNum}
                isLoaded={progressList}
            >
                {this.renderCommissionHistory()}
                {this.renderMinCommissionHistory()}
                {this.renderDepositOfFunds()}
                {this.renderDepositReturn()}
                {this.renderConfirmWindow()}
                {this.renderEditRate()}
                {
                    list.length !== 0 ?
                        <CheckboxList
                            classNameAvatar="deposit-list__checkbox-list-avatar"
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            fetchProgress={progressList}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({}),
    {
        updateClientCardProperties,
        updateClientDepositFavorite,

    },
)(withTranslation()(DepositList));