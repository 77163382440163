import {useMemo} from "react";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {handleFormString} from "../../../../utils/stringHelper";

export function useSettingsUsersRolesFilter(props) {
    const {
        pageSize,
        setPagination,
    } = props;

    const initFilter = {
        nameForClient: "",
        contractorPhoneFilter: "",
        clientId: "",
    };

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        initFilter,
        pageSize,
        setPagination,
        mapFilterDto,
    });

    function mapFilterDto(filter) {
        const {
            name,
            nameForClient,
            clientId,
            ...otherFilters
        } = filter;

        return {
            name: handleFormString(name),
            nameForClient: handleFormString(nameForClient),
            clientId: handleFormString(clientId),
            ...otherFilters,
        };
    }

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "name",
                        label: "Название роли (административное)",
                        placeholder: "Введите название роли",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        name: "nameForClient",
                        label: "Название роли для компании",
                        placeholder: "Введите название роли",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.CUSTOMER,
                        label: "Заказчик",
                        placeholder: "Выберите заказчика",
                        name: "clientId",
                        isClearable: true,
                        isBff: true,
                        search: true,
                    },
                ],
            },
        ];
    }, []);

    return {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    };
}