import React from "react";

import CKETextEditor from "../../../../../components/ActualComponents/CKETextEditor";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";

export const AdvertisementDescriptionEditor = (props) => {
    const {
        value,
        onChange,
        error,
        isVisibleTooltip,
        maxLength,
    } = props;

    return (
        <CKETextEditor
            label="Описание в объявлении"
            placeholder="Опишите подробно детали объявления"
            value={value}
            onChange={onChange}
            error={error}
            maxLength={maxLength}
            labelProps={{
                isVisibleTooltip,
                required: true,
                tooltip: (
                    <HelpTooltip
                        hover
                        info
                        width={20}
                        height={20}
                        text="Описание, отображаемое исполнителям в объявлении в мобильном приложении"
                    />
                ),
            }}
        />
    );
};