import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import {MediaButtons} from "../../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import {NmPageCardHeader} from "../../../../../components/ActualComponents/NmPageCardHeader";
import NmShowMoreText from "../../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../components/CheckboxList";
import NmBadge from "../../../../../components/NmBadge";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import NmPageCard from "../../../../../components/NmPageCard";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import SelectionCountWithAction from "../../../../../components/SelectionCountWithAction";
import OrderTemplateInfo from "../../../../order/template-info";
import DeferredActOfAcceptanceWorkLog from "../log";
import DocumentsDeferredActsOfAcceptanceWorkItemEditModal from "./components/item-edit-modal";

import {usePagination} from "../../../../../hooks/usePagination";
import {useSelectedList} from "../../../../../hooks/useSelectedList";
import {useDocumentsDeferredActsOfAcceptanceWorkCardAction} from "./hooks/useAction";
import {useDocumentsDeferredActsOfAcceptanceWorkCardFetch} from "./hooks/useFetch";
import {useDocumentsDeferredActsOfAcceptanceWorkCardFilter} from "./hooks/useFilter";

import {formatLocalDate, getDatePeriodLabelText} from "../../../../../utils/dateFormat";
import {formatAmount, getFullName, phoneFormat} from "../../../../../utils/stringFormat";

import {
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD,
    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS,
    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS_BADGE_STATUS_MOD,
} from "../../../../../constants/documentType";
import {
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
} from "../../../../../constants/links";

import {
    bffDeferredActsOfAcceptanceWorkCardItemsListSelector,
    bffDeferredActsOfAcceptanceWorkCardProgressSelector,
    bffDeferredActsOfAcceptanceWorkCardSelector,
    bffDeferredActsOfAcceptanceWorkCardTotalCountSelector,
    bffDeferredActsOfAcceptanceWorkCardTotalPagesSelector,
    bffDeferredActsOfAcceptanceWorkProgressCardSelector,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/selectors";

export const DocumentsDeferredActsOfAcceptanceWorkCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                id: registryId,
            },
        },
    } = props;

    const card = useSelector(bffDeferredActsOfAcceptanceWorkCardSelector);
    const progressCard = useSelector(bffDeferredActsOfAcceptanceWorkProgressCardSelector);
    const list = useSelector(bffDeferredActsOfAcceptanceWorkCardItemsListSelector);
    const totalCount = useSelector(bffDeferredActsOfAcceptanceWorkCardTotalCountSelector);
    const totalPages = useSelector(bffDeferredActsOfAcceptanceWorkCardTotalPagesSelector);
    const progressList = useSelector(bffDeferredActsOfAcceptanceWorkCardProgressSelector);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList({
        list,
    });

    const {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    } = useDocumentsDeferredActsOfAcceptanceWorkCardFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useDocumentsDeferredActsOfAcceptanceWorkCardFetch({
        clientId,
        registryId,
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {
        modalData,
        onCloseModal,
        getListMediaControls,
        getMediaControls,
        openConfirmDeleteSelected,
        mobileMassActionOptions,
        isActionPostActRegistryItem,
    } = useDocumentsDeferredActsOfAcceptanceWorkCardAction({
        clientId,
        registryId,
        fetchList,
        clearSelectedRows,
        selectedList,
        countSelected,
    });

    const getStatusErrors = (item) => {
        const {
            status,
            errors,
            failReason,
        } = item;

        if (
            ![
                DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.SUBMIT_ERROR,
                DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.REJECTED,
            ].includes(status?.value)
        ) {
            return null;
        }

        if ([DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.REJECTED].includes(status?.value)) {
            return failReason;
        }

        return errors?.map(({errorMessage}) => errorMessage).join(", ");
    };

    const rows = useMemo(() => {
        return list.map(item => {
            const {
                contractorFirstName,
                contractorLastName,
                contractorPatronymic,
                contractorPhone,
                actDate,
                status,
                seqNum,
                paymentNumber,
                workActDescription,
                amount,
                commentary,
                startWorkDate,
                endWorkDate,
                signDateTime,
            } = item;

            const {isSelected = false} = selectedList.find((item) => (item.seqNum === seqNum)) || {};

            return {
                ...item,
                key: seqNum,
                showCheckBox: true,
                disabledCheckBox: ![
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.CREATED,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.SUBMIT_ERROR,
                    DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS.REJECTED,
                ].includes(status?.value),
                isSelected,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-8"
                        primaryHeader={getFullName(contractorLastName, contractorFirstName, contractorPatronymic)}
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={DOCUMENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_ITEM_STATUS_BADGE_STATUS_MOD[status?.value]}
                                text={status?.description}
                                rightTooltipText={getStatusErrors(item)}
                                tooltipPosition="right-top"
                            />
                        }
                        secondaryHeader={
                            signDateTime &&
                            `Дата подписания акта: ${formatLocalDate(signDateTime, "dd.MM.yyyy")}`
                        }
                        labels={[
                            {
                                label: "Номер строки",
                                text: seqNum,
                            },
                            {
                                label: "Телефон",
                                text: phoneFormat(contractorPhone) || "-",
                            },
                            {
                                label: "Номер оплаты",
                                text: paymentNumber,
                            },
                            {
                                label: "Описание выполненных работ для акта",
                                columnOnMobile: true,
                                fluidText: true,
                                text: <NmShowMoreText
                                    lines={1}
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {workActDescription || "-"}
                                </NmShowMoreText>,
                            },
                            {
                                label: "Сумма, ₽",
                                text: formatAmount(amount),
                            },
                            {
                                label: "Комментарий",
                                columnOnMobile: true,
                                fluidText: true,
                                text: <NmShowMoreText
                                    lines={1}
                                    more="Подробнее"
                                    anchor="blue"
                                >
                                    {commentary || "-"}
                                </NmShowMoreText>,
                            },
                            {
                                label: "Период работ",
                                text: getDatePeriodLabelText(startWorkDate, endWorkDate),
                            },
                            {
                                label: "Дата акта",
                                text: formatLocalDate(actDate, "dd.MM.yyyy"),
                            },
                        ]}
                        mediaControls={getListMediaControls(item)}
                        isFixedActions={true}
                    />
                ),
            };
        });
    }, [
        list,
        selectedList,
    ]);

    const renderConfirmModal = () => {
        if (!modalData?.confirmProps) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                content={modalData.confirmProps.content}
                onCancel={onCloseModal}
                onConfirm={modalData.confirmProps.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const renderEditItemModal = () => {
        return (
            modalData?.isOpenItemEditForm &&
            <DocumentsDeferredActsOfAcceptanceWorkItemEditModal
                editData={modalData.editData}
                onClose={onCloseModal}
            />
        );
    };

    const renderLogModal = () => {
        if (!modalData?.isOpenLog) {
            return null;
        }

        return (
            <DeferredActOfAcceptanceWorkLog
                registry={modalData.item}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <NmPageCard
            header={
                <NmPageCardHeader
                    to={LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST.replace(":clientId", clientId)}
                    status={
                        <NmBadge
                            className="ms-4"
                            text={card.status?.description}
                            mod={DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD[card.status?.value] || "gray"}
                        />
                    }
                    content={`Реестр отложенных актов №${card.ordinalNumber || ""}`}
                    controls={
                        <MediaButtons
                            inline={true}
                            buttonOffset="sm"
                            config={getMediaControls(card)}
                        />
                    }
                />
            }
            description={
                <>
                    <div className="col-16 col-xxl-9">
                        <Text.Title level="4">
                            {card.name}
                        </Text.Title>
                    </div>
                    <NmLabelText
                        type="page"
                        label="Реестр актов от"
                        text={formatLocalDate(card.creationDateTime)}
                    />
                    <NmLabelText
                        type="page"
                        label="Комментарий"
                        text={card.commentary || "-"}
                        noWrap={false}
                        inline={true}
                    />
                    <NmLabelText
                        type="page"
                        label="Ответственный"
                        text={getFullName(
                            card.creatorClientUserLastName,
                            card.creatorClientUserFirstName,
                            card.creatorClientUserPatronymic,
                        )}
                    />
                    <NmLabelText
                        type="page"
                        label="Шаблон акта"
                        textTitle={card.templateName}
                        text={
                            <OrderTemplateInfo
                                id={card.templateId}
                                linkName={card.templateName}
                                link={card.templatePdfDownloadLink}
                            />
                        }
                    />
                </>
            }
        >
            {renderConfirmModal()}
            {renderEditItemModal()}
            {renderLogModal()}
            <NmPage
                header={
                    <NmPageHeader
                        size="xl"
                        text="Исполнители"
                        totalCount={totalCount}
                    />
                }
                typeFilter="vertical"
                widthByFilter={true}
                filtersBase={
                    <Filter
                        initState={initFilter}
                        filters={filters}
                        onSubmit={onSearch}
                        clearFilter={onClear}
                    />
                }
                isLoaded={progressCard || progressList}
                onPaginationChange={onPaginationChange}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                currentPageSize={pageSize}
                onChangePageSize={onChangePageSize}
                isEmptyPage={isEmpty(list)}
                emptyPageProps={{
                    isSearch,
                }}
            >
                <CheckboxList
                    header={
                        isActionPostActRegistryItem &&
                        <SelectionCountWithAction
                            adaptiveLogic={true}
                            count={countSelected}
                            buttonColor="grey"
                            buttonContent="Удалить строку"
                            onClick={openConfirmDeleteSelected}
                            otherActions={
                                <NmButton
                                    size="lg"
                                    color="grey"
                                    disabled={countSelected === 0}
                                    onClick={clearSelectedRows}
                                >
                                    Очистить
                                </NmButton>
                            }
                        />
                    }
                    actionOptions={mobileMassActionOptions}
                    rows={rows}
                    onSelectedRows={handleSelectedRows}
                />
            </NmPage>
        </NmPageCard>
    );
};