import {
    EDM_ADD_STAFF_ERROR,
    EDM_ADD_STAFF_REQUEST,
    EDM_ADD_STAFF_SUCCESS,
    EDM_ARCHIVE_STAFF_ERROR,
    EDM_ARCHIVE_STAFF_REQUEST,
    EDM_ARCHIVE_STAFF_SUCCESS,
    EDM_GET_STAFF_ERROR,
    EDM_GET_STAFF_LIST_ERROR,
    EDM_GET_STAFF_LIST_REQUEST,
    EDM_GET_STAFF_LIST_SUCCESS,
    EDM_GET_STAFF_REQUEST,
    EDM_GET_STAFF_SUCCESS,
    EDM_STAFF_FIND_CONTRACTORS_ERROR,
    EDM_STAFF_FIND_CONTRACTORS_REQUEST,
    EDM_STAFF_FIND_CONTRACTORS_SUCCESS,
    EDM_STAFF_FIND_USERS_ERROR,
    EDM_STAFF_FIND_USERS_REQUEST,
    EDM_STAFF_FIND_USERS_SUCCESS,
    EDM_STAFF_USER_EXIST_SUCCESS,
    EDM_UPDATE_STAFF_ERROR,
    EDM_UPDATE_STAFF_REQUEST,
    EDM_UPDATE_STAFF_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    pageData: {},
    totalCount: 0,
    listProgress: false,
    staffCard: {},
    gettingStaffProgress: false,
    findingContractorsList: [],
    findingContractorsProgress: false,
    findingContractorsPageData: {},
    findingContractorsTotalCount: 0,
    findingUsersProgress: false,
    findingUsersPageData: {},
    findingUsersTotalCount: 0,
    findingUsersList: [],
    actionProgress: false,
    clientUserStaffExist: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EDM_STAFF_FIND_USERS_REQUEST:
            return {
                ...state,
                findingUsersProgress: true,
                findingUsersPageData: payload,
            };
        case EDM_STAFF_FIND_CONTRACTORS_REQUEST:
            return {
                ...state,
                findingContractorsProgress: true,
                findingContractorsPageData: payload.data,
            };
        case EDM_UPDATE_STAFF_REQUEST:
        case EDM_ARCHIVE_STAFF_REQUEST:
        case EDM_ADD_STAFF_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case EDM_GET_STAFF_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                listProgress: true,
            };
        case EDM_GET_STAFF_REQUEST:
            return {
                ...state,
                gettingStaffProgress: true,
            };
        case EDM_STAFF_FIND_CONTRACTORS_SUCCESS:
            const {
                results: findingContractorsList,
                totalCount: findingContractorsTotalCount,
            } = payload;

            return {
                ...state,
                findingContractorsTotalCount,
                findingContractorsList,
                findingContractorsProgress: false,
            };
        case EDM_STAFF_FIND_USERS_SUCCESS:
            const {
                results: findingUsersList,
                totalCount: findingUsersTotalCount,
            } = payload;

            return {
                ...state,
                findingUsersList,
                findingUsersTotalCount,
                findingUsersProgress: false,
            };
        case EDM_GET_STAFF_SUCCESS:
            return {
                ...state,
                staffCard: payload,
                gettingStaffProgress: false,
            };
        case EDM_UPDATE_STAFF_SUCCESS:
        case EDM_ARCHIVE_STAFF_SUCCESS:
        case EDM_ADD_STAFF_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case EDM_GET_STAFF_LIST_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                list,
                totalCount,
                listProgress: false,
            };
        case EDM_STAFF_USER_EXIST_SUCCESS:
            return {
                ...state,
                clientUserStaffExist: payload,
            };
        case EDM_STAFF_FIND_CONTRACTORS_ERROR:
            return {
                ...state,
                error: payload,
                findingContractorsProgress: false,
            };
        case EDM_STAFF_FIND_USERS_ERROR:
            return {
                ...state,
                error: payload,
                findingUsersProgress: false,
            };
        case EDM_GET_STAFF_LIST_ERROR:
            return {
                ...state,
                error: payload,
                listProgress: false,
            };
        case EDM_UPDATE_STAFF_ERROR:
        case EDM_ARCHIVE_STAFF_ERROR:
        case EDM_ADD_STAFF_ERROR:
            return {
                ...state,
                error: payload,
                actionProgress: false,
            };
        case EDM_GET_STAFF_ERROR: {
            return {
                ...state,
                error: payload,
                gettingStaffProgress: false,
            };
        }
        default:
            return state;
    }
};