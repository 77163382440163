import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const clientProjectCardSelector = state => state.bff.clientProjectCard;
export const clientProjectCardInfoSelector = createSelector(clientProjectCardSelector, ({card}) => card);
export const clientProjectCardProgressSelector = createSelector(clientProjectCardSelector, ({progress}) => progress);
export const projectUsersSelector = createSelector(clientProjectCardSelector, ({projectUsers}) => projectUsers);
export const projectUsersTotalPagesSelector = createSelector(clientProjectCardSelector, ({projectUsersTotalCount, projectUsersPageData: {pageSize = 0}}) => getTotalPages(projectUsersTotalCount, pageSize));
export const projectUsersTotalCountSelector = createSelector(clientProjectCardSelector, ({projectUsersTotalCount}) => projectUsersTotalCount);
export const projectProgressUsersSelector = createSelector(clientProjectCardSelector, ({progressUsers}) => progressUsers);
