import React from "react";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";

import {useObjectsDropdown} from "./hooks/useObjects";

export const ObjectsDropdown = (props) => {
    const {
        filters,
        ...otherProps
    } = props;

    const defaultComponentProps = useObjectsDropdown({
        filters,
    });

    return (
        <NmDropdownV2
            {...defaultComponentProps}
            {...otherProps}
        />
    );
};