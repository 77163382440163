import {citizenshipsDict} from "./citizenships";
import {
    CLIENT_FIELD_NAME,
    CLIENT_MEMBER_FIELD_NAME,
    CLIENT_PROPERTIES_FIELD,
    FEDERAL_MIGRATION_FIELD,
    FINANCE_UPLOAD_FIELD_NAME,
    OBJECT_FIELD_NAME,
    PROMOCODE_CHANNEL_FIELD_NAME,
    SUPPORT_FIELD_NAME,
    UPLOAD_1C_FIELD_NAME,
} from "./clientList";
import {CONTRACTOR_FIELD} from "./contractor";
import {DEPOSIT_FIELD, DEPOSIT_REPLENISHMENT_ACCOUNT} from "./deposit/fields";
import {DISPUTE_FIELD} from "./disputes";
import {EDO_DOCUMENT_FIELD} from "./document-management/document-list";
import {DOCUMENT_NOTIFICATIONS_FIELD_NAME} from "./documentType";
import {LANDING_FIELDS_NAME} from "./landing";
import {ORDER_CATEGORY_FIELD} from "./orderCategory";
import {PROJECT_FIELD_NAME} from "./projects";
import {TYPE_PROMOTION} from "./promocode/dict";
import {REG_EXP} from "./regExp";
import {REGISTRY_PAYMENTS_CATEGORY_FIELDS} from "./registry";
import {REQUISITES_FIELD_NAME} from "./requisites";

import {
    detectAge,
    validateBik,
    validateCardNumber,
    validateIndividualInn,
    validateInn,
    validateInnVersion2,
    validateInstructionDate,
    validateInvoiceOfDepositReplenishmentSum,
    validateKpp,
    validateKs,
    validateMigrationNumber,
    validateOgrn,
    validateOgrnIP,
    validateOkved,
    validatePassword,
    validatePromocodeCount,
    validatePromocodeMultiCount,
    validateRs,
    validateSnils,
} from "../utils/validate";

export const regS = /\s+/g;
export const regDigits = /(\d)(?=(\d\d\d)+([^\d]|$))/g;
export const regNumber = /^\d+$/;
/* 2 bm max size */
export const maxSizeFile = 2097152;
/* 10 MB to B max size */
export const maxSizeScanFile = 10485760;
export const maxAgencyContractFileSize = 10485760;
export const maxPatternFileSize = 10_485_760;
export const maxSize25MB = 26214400;
export const maxSize15MB = 15728640;
export const maxSize4MB = 4_194_304;
export const maxSizeFile15MB = 15_728_640;
export const extensionsImage = ["jpeg", "jpg", "png", "bmp", "gif"];
export const PDF_EXTENSION = "pdf";
export const agencyContractExtensions = ["odt", "doc", "docx", "pdf", ...extensionsImage];
export const contractorCertificatesExtensions = ["png", "jpeg", "doc", "docx", "pdf"];
export const contractorVaccinationCertificateExtensions = ["png", "jpeg", "jpg", "pdf"];
export const contractorMedicalBookExtensions = ["png", "jpeg", "jpg"];

export const requiredMessage = "Обязательное поле";

export const emailReg = REG_EXP.EMAIL;
export const webSiteReg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

export const loginReg = /^[0-9a-zA-Z._\-@]+$/;

export const regFullName = /^[а-яА-ЯёЁ0-9a-zA-Z!().,:;"№#@$%&'*+\-/=?^_`{|}~\s]+$/;
export const regClientName = /^[а-яА-ЯёЁ0-9a-zA-Z.,№?!:;\-()"«»\s]+$/;
export const regBrand = /^[а-яА-ЯёЁ0-9a-zA-Z.,?!:;\-()"«»\s]+$/;

export const phoneReg = /^([78])?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
export const phoneRegWithForeign = /^\d{10,15}$/;
export const directoriesReg = /^[а-яА-ЯёЁ0-9a-zA-Z.,:;/\-_"'*№#=!?(){}%<>+\[\]«»\s]+$/;
export const stopWordReg = /^[а-яА-ЯёЁ.,\s\-()]+$/;
// Кириллица + цифры + спецсимволы
export const operatorReg = /^[^a-zA-Z]+$/;

export const passportNumberReg = /^[а-яА-ЯёЁ0-9a-zA-Z]+[0-9]+$/;
export const idDocNumberReg = /^[0-9a-zA-Z]+$/;
export const formattedAmountReg = /^[0-9\s]+$/;
// Кириллица + символ -
export const nameReg = /^[а-яёЁА-Я\-\s]+$/;
export const nameWithLatinReg = /^[а-яёЁА-Яa-zA-Z\-\s]+$/;

// Кириллица
const nameAndPatronymicReg = /^[а-яёА-Я\s]+$/;
// Кириллица
export const einReg = /^[A-Za-z0-9]+$/;

export const departamentCodeReg = /^([0-9]{3}[-]{1}[0-9]{3})?$/;
export const accountNumberReg = /^[а-яА-ЯёЁ0-9a-zA-Z\-./]+$/;

export const okvedFormatRegex = /^([0-9]{1,2}\.?)*[^.]$/;
export const okvedSymbolsRegex = /^[0-9.]+$/;

export const amountRegex = /^\d+\.{0,1}\d{0,2}$/;
export const amountRegexDelimited = /^\d+[.,]{0,1}\d{0,2}$/;
export const amountRegexUseDeleteBeforeSpace = /^\s[0-9]+$/;
export const amountWithOnlyCommaRegexDelimited = /^\d+[,]{0,1}\d{0,2}$/;
export const amountRegexDelimitedOrderType = /^\d+[,]{0,1}\d{0,2}$/;
export const descriptionPaymentReg = /^[а-яА-Яa-zA-Z0-9 .,:;|\n\-"'*№!?()[\]+<>{}%ёЁ=«»]+$/;
export const descriptionPaymentRegWithSlach = /^[а-яА-Яa-zA-Z0-9 .,:;|/\n\-"'*№!?()[\]+<>{}%ёЁ=«»]+$/;
// [а-яА-Яa-zA-Z0-9 .,:;-"'*№!?()[]+<>{}%ёЁ=«»]
export const upload1CRule = {
    [UPLOAD_1C_FIELD_NAME.DOCUMENT_TYPE]: {
        required: requiredMessage,
    },
    [UPLOAD_1C_FIELD_NAME.END_DATE]: {
        required: requiredMessage,
    },
    [UPLOAD_1C_FIELD_NAME.START_DATE]: {
        required: requiredMessage,
    },
};
export const depositAddRule = {
    [DEPOSIT_FIELD.AMOUNT]: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: "Введите значение больше 0",
        },
        match: {
            value: /^\d+\.{0,1}\d{0,2}$/,
            message: "Неверный формат суммы. Пример: 100,12",
        },
        maxValue: {
            value: 1000000000,
            message: "Сумма должна быть меньше 1\u200a000\u200a000\u200a000 руб.",
        },
    },
};

export const REJECTION_SIGN_DOCUMENT_RULE = {
    rejectionReason: {
        required: requiredMessage,
        minLength: {
            value: 1,
            message: "Минимальная длина строки 1 символ",
        },
        maxLength: {
            value: 255,
            message: "Максимальная длина строки 255 символов",
        },
    },
};

export const CONTRACTOR_WORK_EXPERIENCE_RULE = {
    employerName: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимальная длина строки 255 символов",
        },
    },
    position: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина строки 100 символов",
        },
    },
    responsibilities: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимальная длина строки 100 символов",
        },
    },
    startWorkDate: {
        required: requiredMessage,
    },
    endWorkDate: {
        required: requiredMessage,
    },
};

export const DOCUMENT_MANAGEMENT_LOADING_DOCUMENT_RULE = {
    [EDO_DOCUMENT_FIELD.SENDER_ID]: {
        required: requiredMessage,
    },
    [EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME]: {
        required: requiredMessage,
    },
    [EDO_DOCUMENT_FIELD.DOCUMENT_NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина строки 100 символов",
        },
    },
    [EDO_DOCUMENT_FIELD.TYPE]: {
        required: requiredMessage,
    },
};

export const depositRule = {
    [DEPOSIT_FIELD.AMOUNT]: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: "Введите значение больше 0",
        },
        match: {
            value: /^\d+\.{0,1}\d{0,2}$/,
            message: "Неверный формат суммы. Пример: 100,12",
        },
        maxValue: {
            value: 1000000000,
            message: "Сумма должна быть меньше 1\u200a000\u200a000\u200a000 руб.",
        },
    },
    [DEPOSIT_FIELD.COMMENT]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
};

export const editSpecialityOffer = {
    offeredSpecialityName: {
        required: requiredMessage,
    },
};

export const depositReplenishmentAccountRule = {
    [DEPOSIT_REPLENISHMENT_ACCOUNT.TOTAL_AMOUNT]: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: "Введите значение больше 0",
        },
        match: {
            value: /^\d+\.{0,1}\d{0,2}$/,
            message: "Неверный формат суммы. Пример: 100,12",
        },
    },
};

export const clientCategoryRule = {
    [CLIENT_FIELD_NAME.OKVED]: {
        required: requiredMessage,
        maxLength: {
            value: 8,
            message: "Максимальная длина - 8 символов",
        },
        minLength: {
            value: 2,
            message: "Минимальная длина строки 2 символа",
        },
        func: validateOkved,
    },
    [CLIENT_FIELD_NAME.CATEGORY]: {
        required: requiredMessage,
    },
    [CLIENT_FIELD_NAME.SPECIALITY_IDS]: {
        required: requiredMessage,
    },
};

export const orderCategoryRule = {
    [ORDER_CATEGORY_FIELD.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Максимальная длина - 50 символов",
        },
        minLength: {
            value: 3,
            message: "Минимальная длина строки 3 символа",
        },
    },
    [ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT]: {
        required: requiredMessage,
    },
    [ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT]: {
        required: requiredMessage,
    },
    [ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT]: {
        required: requiredMessage,
    },
};

export const federalMigrationRule = {
    [FEDERAL_MIGRATION_FIELD.REGION]: {
        required: requiredMessage,
    },
    [FEDERAL_MIGRATION_FIELD.ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    [FEDERAL_MIGRATION_FIELD.NAME_DEPARTMENT]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
};

export const financeUploadRule = {
    [FINANCE_UPLOAD_FIELD_NAME.START_PERIOD_DATE]: {
        required: requiredMessage,
    },
    [FINANCE_UPLOAD_FIELD_NAME.END_PERIOD_DATE]: {
        required: requiredMessage,
    },
};

export const worklLogRule = {
    localDatetime: {
        required: requiredMessage,
    },
};

/* ----------- CLIENT_NEW ----------------*/

export const commissionRate = {
    required: requiredMessage,
    minValueNotStrong: {
        value: 0,
        message: "Минимальное допустимое значение равно 0",
    },
    maxValue: {
        value: 100,
        message: "Максимальное допустимое значение равно 100",
    },
};

export const ordersUnsecuredLimitRule = {
    [CLIENT_PROPERTIES_FIELD.ORDERS_LIMIT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_PROPERTIES_FIELD.CIVIL_ORDERS_LIMIT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
};

export const migrantLicensePaymentCommissionRule = {
    [CLIENT_PROPERTIES_FIELD.MIGRANT_LICENSE_PAYMENT_COMMISSION]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
};

export const edoTariffRule = {
    [CLIENT_PROPERTIES_FIELD.EDO_TARIFF]: {
        required: requiredMessage,
    },
};

export const paymentsCommission = {
    required: requiredMessage,
    minValueNotStrong: {
        value: 0,
        message: "Минимальное допустимое значение равно 0",
    },
    maxValue: {
        value: 49.99,
        message: "Максимальное допустимое значение равно 49.99",
    },
};

const directoriesRule = {
    required: requiredMessage,
    match: {
        value: directoriesReg,
        message: "Поле содержит некорректные символы",
    },
    maxLength: {
        value: 100,
        message: "Максимальная длина - 100 символов",
    },
    minLength: {
        value: 3,
        message: "Минимальная длина строки 3 символов",
    },
};

export const keywordRule = {
    keyword: directoriesRule,
};

export const stopWordRule = {
    value: {
        required: requiredMessage,
        match: {
            value: stopWordReg,
            message: "Поле содержит некорректные символы",
        },
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
};

export const categoryRule = {
    value: directoriesRule,
};

export const subCategoryRule = {
    value: directoriesRule,
};

export const specialityRule = {
    required: requiredMessage,
    match: {
        value: directoriesReg,
        message: "Поле содержит некорректные символы",
    },
    maxLength: {
        value: 100,
        message: "Максимальная длина - 100 символов",
    },
    minLength: {
        value: 2,
        message: "Минимальная длина строки 2 символов",
    },
};

export const directoriesSpecialityRule = {
    value: specialityRule,
};

export const commentRule = {
    comment: {
        maxLength: {
            value: 255,
            message: "Максимальная количество 255 символов",
        },
    },
};

export const edmPositionRule = {
    position: {
        required: requiredMessage,
    },
};

export const edmSelectStaffFormRule = {
    positionId: {
        required: requiredMessage,
    },
};

export const addContractorToNmByEmailFormRule = {
    [CLIENT_FIELD_NAME.EMAIL]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Введен некорректный e-mail",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};

export const addContractorToNmByPhoneFormRule = {
    [CLIENT_FIELD_NAME.PHONE]: {
        required: requiredMessage,
        length: {
            value: 11,
            message: "Номер телефона должен состоять из 10 цифр",
        },
    },
};

export const subscribeOnContractorsFormRule = {
    [CLIENT_FIELD_NAME.EMAIL]: {
        required: requiredMessage,
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};

export const clientFullNameRule = {
    [CLIENT_FIELD_NAME.FULL_NAME]: {
        required: requiredMessage,
        match: {
            value: regFullName,
            message: "Допускается ввод цифр, спецсимволов, кириллицы и латиницы",
        },
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
};

export const clientNameRule = {
    [CLIENT_FIELD_NAME.NAME]: {
        required: requiredMessage,
        match: {
            value: regClientName,
            message: "Допускается ввод цифр, спецсимволов, кириллицы и латиницы",
        },
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
};

export const clientRule = {
    [CLIENT_FIELD_NAME.BRAND]: {
        match: {
            value: regBrand,
            message: "Допускается ввод цифр, кириллицы и латиницы, знаков . , ? ! ; : - ( ) \" \" « »",
        },
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 3,
            message: "Минимальная длина строки 3 символов",
        },
    },
    [CLIENT_FIELD_NAME.CIVIL_ORDERS_LIMIT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_FIELD_NAME.CIVIL_PAYMENT_COMMISSION]: commissionRate,
    [CLIENT_FIELD_NAME.CURRENT_COMMISSION_RATE]: commissionRate,
    [CLIENT_FIELD_NAME.CONTRACTOR_CIVIL_ORDER_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_CIVIL_REGISTRY_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_SMZ_ORDER_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.CONTRACTOR_SMZ_REGISTRY_PAYMENTS_COMMISSION]: paymentsCommission,
    [CLIENT_FIELD_NAME.MIN_COMMISSION_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    [CLIENT_FIELD_NAME.PAYMENTS_THRESHOLD_AMOUNT]: {
        required: requiredMessage,
        minValueNotStrong: {
            value: 0,
            message: "Минимальное значение - 0",
        },
    },
    ...clientNameRule,
    [CLIENT_FIELD_NAME.INN]: {
        required: requiredMessage,
        func: validateInn,
    },
    [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: {
        maxLength: {
            value: 150,
            message: "Максимальная длина - 150 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    [CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]: {
        length: {
            value: 11,
            message: "Номер телефона должен состоять из 10 цифр",
        },
    },
    [CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL]: {
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
        maxLength: {
            value: 320,
            message: "Максимальная длина - 320 символов",
        },
    },
    [CLIENT_FIELD_NAME.CATEGORY]: {
        required: requiredMessage,
    },
};

const addressClientRule = {
    [CLIENT_FIELD_NAME.REGISTERED_ADDRESS]: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина - 5 символов",
        },
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
    },
};

export const referralRateRule = {
    referralRate: {
        required: requiredMessage,
    },
};

//FOREIGN_LEGAL_ENTITY
export const clientForeignLegalEntity = {
    ...clientRule,
    ...clientFullNameRule,
    ...addressClientRule,
    [CLIENT_FIELD_NAME.INN]: {
        required: requiredMessage,
        minLength: {
            value: 9,
            message: "Количество введенных знаков должно быть 9",
        },
    },
};

//RUSSIAN_LEGAL_ENTITY
export const clientRussianLegalEntity = {
    ...clientRule,
    ...addressClientRule,
    [CLIENT_FIELD_NAME.CURRENT_COMMISSION_RATE]: commissionRate,
    ...clientFullNameRule,
};

export const registryRule = {
    name: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    objectId: {
        required: requiredMessage,
    },
};

export const registryAdminRule = {
    name: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    creatorClientUserId: {
        required: requiredMessage,
    },
    objectId: {
        required: requiredMessage,
    },
};

export const registryNotificationDataRule = {
    ovmUnitName: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    speciality: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    workAddress: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимальная длина - 255 символов",
        },
    },
};

export const registryPaymentsEditCategoryRule = {
    [REGISTRY_PAYMENTS_CATEGORY_FIELDS.CATEGORY_ID]: {
        required: requiredMessage,
    },
    [REGISTRY_PAYMENTS_CATEGORY_FIELDS.SUBCATEGORY_ID]: {
        required: requiredMessage,
    },
};

//INDIVIDUAL_LEGAL_ENTITY
export const clientIndividualRule = {
    [CLIENT_FIELD_NAME.CURRENT_COMMISSION_RATE]: commissionRate,
    ...clientRule,
    ...clientFullNameRule,
    ...addressClientRule,
    [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: {
        maxLength: {
            value: 150,
            message: "Максимальная длина - 150 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        match: {
            value: nameReg,
            message: "Только кириллица и знаки -",
        },
    },
    [CLIENT_FIELD_NAME.NAME]: {},
    [CLIENT_FIELD_NAME.INN]: {
        required: requiredMessage,
        func: validateIndividualInn,
    },
    [CLIENT_FIELD_NAME.CATEGORY]: {
        required: requiredMessage,
    },
};

/* ----------- CLIENT_INFO_CARD ------------- */

const clientGeneralFieldsRule = {
    [CLIENT_FIELD_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 4,
            message: "Минимальная длина строки 4 символа",
        },
    },
    [CLIENT_FIELD_NAME.REPRESENTATIVE_PHONE]: {
        length: {
            value: 11,
            message: "Меньше 10 символов",
        },
    },
    [CLIENT_FIELD_NAME.REPRESENTATIVE_EMAIL]: {
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
        maxLength: {
            value: 320,
            message: "Максимальная длина - 320 символов",
        },
    },
    [CLIENT_FIELD_NAME.REGISTERED_ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    [CLIENT_FIELD_NAME.CLIENT_TYPE]: {
        required: requiredMessage,
    },
    [CLIENT_FIELD_NAME.BRAND]: {
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 3,
            message: "Минимальная длина строки 3 символа",
        },
    },
};

const clientBankInfoGeneralFieldsRule = {
    [CLIENT_FIELD_NAME.BANK_NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    [CLIENT_FIELD_NAME.BANK_ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
};

export const merchandisingResponsiblePhoneRule = {
    required: requiredMessage,
    length: {
        value: 11,
        message: "Меньше 10 символов",
    },
};

export const clientCardRule = {
    clientInfoIndividual: {
        ...clientGeneralFieldsRule,
        [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: {
            maxLength: {
                value: 100,
                message: "Максимальная длина - 100 символов",
            },
            match: {
                value: nameReg,
                message: "Только кириллица и знаки -",
            },
        },
        [CLIENT_FIELD_NAME.PHONE]: {
            required: requiredMessage,
            length: {
                value: 11,
                message: "Меньше 10 символов",
            },
        },
        [CLIENT_FIELD_NAME.EMAIL]: {
            required: requiredMessage,
            match: {
                value: emailReg,
                message: "Введен некорректный email",
            },
            maxLength: {
                value: 320,
                message: "Максимальная длина - 320 символов",
            },
        },
        ...clientFullNameRule,
        [CLIENT_FIELD_NAME.OGRN]: {
            required: requiredMessage,
            func: validateOgrnIP,
        },
        [CLIENT_FIELD_NAME.INN]: {
            required: requiredMessage,
            func: validateIndividualInn,
        },
    },
    clientInfoForeign: {
        ...clientFullNameRule,
        ...clientGeneralFieldsRule,
        [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: {
            maxLength: {
                value: 100,
                message: "Максимальная длина - 100 символов",
            },
            match: {
                value: nameWithLatinReg,
                message: "Только латиница, кириллица и знаки -",
            },
        },
        [CLIENT_FIELD_NAME.ACTUAL_ADDRESS]: {
            required: requiredMessage,
            maxLength: {
                value: 250,
                message: "Максимальная длина - 250 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CLIENT_FIELD_NAME.PHONE]: {
            required: requiredMessage,
            length: {
                value: 11,
                message: "Меньше 10 символов",
            },
        },
        [CLIENT_FIELD_NAME.EMAIL]: {
            required: requiredMessage,
            match: {
                value: emailReg,
                message: "Введен некорректный email",
            },
            maxLength: {
                value: 320,
                message: "Максимальная длина - 320 символов",
            },
        },
        [CLIENT_FIELD_NAME.INN]: {
            required: requiredMessage,
            length: {
                value: 9,
                message: "EIN состоит из 9 цифр",
            },
        },

    },
    clientInfo: {
        ...clientGeneralFieldsRule,
        ...clientFullNameRule,
        [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME]: {
            maxLength: {
                value: 100,
                message: "Максимальная длина - 100 символов",
            },
            match: {
                value: nameWithLatinReg,
                message: "Только латиница, кириллица и знаки -",
            },
        },
        [CLIENT_FIELD_NAME.ACTUAL_ADDRESS]: {
            required: requiredMessage,
            maxLength: {
                value: 250,
                message: "Максимальная длина - 250 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CLIENT_FIELD_NAME.OGRN]: {
            required: requiredMessage,
            func: validateOgrn,
        },
        [CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE]: {
            required: requiredMessage,
            func: validateKpp,
        },
        [CLIENT_FIELD_NAME.INN]: {
            required: requiredMessage,
            func: validateInn,
        },
        [CLIENT_FIELD_NAME.PHONE]: {
            required: requiredMessage,
            length: {
                value: 11,
                message: "Меньше 10 символов",
            },
        },
        [CLIENT_FIELD_NAME.EMAIL]: {
            required: requiredMessage,
            match: {
                value: emailReg,
                message: "Введен некорректный email",
            },
            maxLength: {
                value: 320,
                message: "Максимальная длина - 320 символов",
            },
        },
    },
    bankInfo: {
        ...clientBankInfoGeneralFieldsRule,
        [CLIENT_FIELD_NAME.BIC]: {
            required: requiredMessage,
            func: validateBik,
        },
        [CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT]: {
            required: requiredMessage,
            func: validateRs,
            secondArg: CLIENT_FIELD_NAME.BIC,
        },
        [CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT]: {
            required: requiredMessage,
            func: validateKs,
            secondArg: CLIENT_FIELD_NAME.BIC,
        },

    },
    bankForeignInfo: {
        ...clientBankInfoGeneralFieldsRule,
        [CLIENT_FIELD_NAME.BIC]: {
            required: requiredMessage,
            match: {
                value: /^[0-9a-zA-Z]+$/,
                message: "Только латинские буквы и цифры",
            },
            maxLength: {
                value: 11,
                message: "Максимальная длина - 11 символов",
            },
            minLength: {
                value: 8,
                message: "Минимальная длина строки 8 символов",
            },
        },
        [CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT]: {
            required: requiredMessage,
            match: {
                value: /^[0-9]+$/,
                message: "Только цифры",
            },
            maxLength: {
                value: 34,
                message: "Максимальная длина - 34 символов",
            },
            minLength: {
                value: 8,
                message: "Минимальная длина строки 8 символов",
            },
        },
        [CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT]: {
            required: requiredMessage,
            match: {
                value: /^[0-9]+$/,
                message: "Только цифры",
            },
            length: {
                value: 9,
                message: "Количество символов - 9",
            },
        },
    },
    bossInfo: {
        [CLIENT_FIELD_NAME.MANAGER_NAME]: {
            maxLength: {
                value: 150,
                message: "Максимальная длина - 150 символов",
            },
            match: {
                value: nameReg,
                message: "Только кириллица и знаки -",
            },
        },
        [CLIENT_FIELD_NAME.MANAGER_PHONE]: {
            length: {
                value: 11,
                message: "Меньше 10 символов",
            },
        },
    },
    representativeInfo: {
        [CLIENT_FIELD_NAME.REPRESENTATIVE_NAME_GENITIVE]: {
            required: requiredMessage,
            maxLength: {
                value: 150,
                message: "Максимальная длина - 150 символов",
            },
        },
        [CLIENT_FIELD_NAME.RIGHT_TO_SIGN_DOCUMENT_DETAILS]: {
            required: requiredMessage,
            maxLength: {
                value: 500,
                message: "Максимальная длина - 500 символов",
            },
        },
    },
    signatoryInfo: {
        [CLIENT_FIELD_NAME.SIGNATORY]: {
            required: requiredMessage,
            maxLength: {
                value: 150,
                message: "Максимальная длина - 150 символов",
            },
        },
        [CLIENT_FIELD_NAME.SIGNATORY_DECODING]: {
            required: requiredMessage,
            maxLength: {
                value: 150,
                message: "Максимальная длина - 150 символов",
            },
        },
    },
    accountantInfo: {
        [CLIENT_FIELD_NAME.ACCOUNTANT_NAME]: {
            maxLength: {
                value: 150,
                message: "Максимальная длина - 150 символов",
            },
            match: {
                value: nameReg,
                message: "Только кириллица и знаки -",
            },
        },
        [CLIENT_FIELD_NAME.ACCOUNTANT_PHONE]: {
            length: {
                value: 11,
                message: "Меньше 10 символов",
            },
        },
    },
};


export const SETTINGS_INFO_RULE = {
    [REQUISITES_FIELD_NAME.OPERATOR]: {
        required: requiredMessage,
        match: {
            value: operatorReg,
            message: "Ввод латинских букв невозможен",
        },
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина - 5 символов",
        },
    },
    [REQUISITES_FIELD_NAME.ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина - 5 символов",
        },
    },
    [REQUISITES_FIELD_NAME.MAIL_ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 250,
            message: "Максимальная длина - 250 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина - 5 символов",
        },
    },
    [REQUISITES_FIELD_NAME.PHONE]: {
        required: requiredMessage,
        length: {
            value: 11,
            message: "Меньше 10 символов",
        },
    },
    [REQUISITES_FIELD_NAME.WEB_SITE]: {
        required: requiredMessage,
        match: {
            value: webSiteReg,
            message: "Неверный вебсайт",
        },
    },
    [REQUISITES_FIELD_NAME.EMAIL]: {
        required: requiredMessage,
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
        maxLength: {
            value: 320,
            message: "Максимальная длина - 320 символов",
        },
    },
    [REQUISITES_FIELD_NAME.BANK_NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Максимальная длина - 50 символов",
        },
    },
    [REQUISITES_FIELD_NAME.OGRN]: {
        required: requiredMessage,
        func: validateOgrn,
    },
    [REQUISITES_FIELD_NAME.INN]: {
        required: requiredMessage,
        func: validateInn,
    },
    [REQUISITES_FIELD_NAME.KPP]: {
        required: requiredMessage,
        func: validateKpp,
    },
    [REQUISITES_FIELD_NAME.RS]: {
        required: requiredMessage,
        func: validateRs,
        secondArg: REQUISITES_FIELD_NAME.BIK,
    },
    [REQUISITES_FIELD_NAME.KS]: {
        required: requiredMessage,
        func: validateKs,
        secondArg: REQUISITES_FIELD_NAME.BIK,
    },
    [REQUISITES_FIELD_NAME.BIK]: {
        required: requiredMessage,
        func: validateBik,
    },
    [REQUISITES_FIELD_NAME.REPRESENTATIVE_FIRST_NAME]: {
        required: requiredMessage,
        match: {
            value: nameAndPatronymicReg,
            message: "Только кириллица",
        },
        maxLength: {
            value: 50,
            message: "Максимальная длина - 50 символов",
        },
    },
    [REQUISITES_FIELD_NAME.REPRESENTATIVE_LAST_NAME]: {
        required: requiredMessage,
        match: {
            value: nameReg,
            message: "Только кириллица и знак \"-\"",
        },
        maxLength: {
            value: 50,
            message: "Максимальная длина - 50 символов",
        },
    },
    [REQUISITES_FIELD_NAME.REPRESENTATIVE_PATRONYMIC]: {
        required: requiredMessage,
        match: {
            value: nameAndPatronymicReg,
            message: "Только кириллица",
        },
        maxLength: {
            value: 50,
            message: "Максимальная длина - 50 символов",
        },
    },
    [REQUISITES_FIELD_NAME.POSITION]: {
        required: requiredMessage,
    },
    [REQUISITES_FIELD_NAME.NAMEMIX_REPRESENTATIVE_FIO]: {
        required: requiredMessage,
        match: {
            value: nameReg,
            message: "Только кириллица и знак \"-\"",
        },
        maxLength: {
            value: 150,
            message: "Максимальная длина - 150 символов",
        },
    },
    [REQUISITES_FIELD_NAME.REPRESENTATIVE_DOCUMENT]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
};

export const projectBudgetRule = {
    [PROJECT_FIELD_NAME.AMOUNT]: {
        required: requiredMessage,
        match: {
            value: /^\d+\.{0,1}\d{0,2}$/,
            message: "Неверный формат бюджета. Пример: 100,12",
        },
    },
};

export const objectBudgetRule = {
    [OBJECT_FIELD_NAME.AMOUNT]: {
        required: requiredMessage,
        match: {
            value: /^\d+\.{0,1}\d{0,2}$/,
            message: "Неверный формат бюджета. Пример: 100,12",
        },
    },
};

export const projectRule = {
    [PROJECT_FIELD_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная количество 100 символов",
        },
        minLength: {
            value: 1,
            message: "Минимальное количество - 1 символ",
        },
    },
    [PROJECT_FIELD_NAME.DESCRIPTION]: {
        maxLength: {
            value: 2000,
            message: "Максимальная количество 2000 символов",
        },
        minLength: {
            value: 1,
            message: "Минимальное количество - 1 символ",
        },
    },
    [PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID]: {
        maxValue: {
            value: 2147483647,
            message: "Максимальное число - 2147483647",
        },
    },
};

export const PROMOCODE_CHANNEL_TYPE_CLIENTS_RULES = {
    [PROMOCODE_CHANNEL_FIELD_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 500,
            message: "Максимальная длина - 500 символов",
        },
    },
};

export const PROMOCODE_CHANNEL_TYPE_CONTRACTORS_RULES = {
    [PROMOCODE_CHANNEL_FIELD_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 500,
            message: "Максимальная длина - 500 символов",
        },
    },
    [PROMOCODE_CHANNEL_FIELD_NAME.CLIENT_ID]: {
        required: requiredMessage,
    },
    [PROMOCODE_CHANNEL_FIELD_NAME.REFERRAL_RATE]: {
        required: requiredMessage,
    },
};

export const PROMOCODE_CHANNEL_RULE = {
    [TYPE_PROMOTION.SINGLE.VALUE]: {
        [PROMOCODE_CHANNEL_FIELD_NAME.WORD]: {
            required: requiredMessage,
            maxLength: {
                value: 50,
                message: "Максимальная длина - 50 символов",
            },
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.COUNT]: {
            required: requiredMessage,
            func: validatePromocodeCount,
            secondArg: PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]: {
            required: requiredMessage,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]: {
            required: requiredMessage,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Максимальная длина - 255 символов",
            },
        },
    },
    [TYPE_PROMOTION.MULTIPLE.VALUE]: {
        [PROMOCODE_CHANNEL_FIELD_NAME.WORD]: {
            required: requiredMessage,
            maxLength: {
                value: 50,
                message: "Максимальная длина - 50 символов",
            },
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.MULTIPLE_COUNT]: {
            required: requiredMessage,
            func: validatePromocodeMultiCount,
            secondArg: PROMOCODE_CHANNEL_FIELD_NAME.TYPE_PROMOTION,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.COUNT]: {
            required: requiredMessage,
            func: validatePromocodeCount,
            secondArg: PROMOCODE_CHANNEL_FIELD_NAME.POSTFIX_PROMOTION,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.TO_DATE]: {
            required: requiredMessage,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.FROM_DATE]: {
            required: requiredMessage,
        },
        [PROMOCODE_CHANNEL_FIELD_NAME.REQUISITES]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Максимальная длина - 255 символов",
            },
        },
    },
};

export const supportRule = {
    [SUPPORT_FIELD_NAME.ANSWER]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Максимально допустимое количество символов 255",
        },
    },
    [SUPPORT_FIELD_NAME.CASE_STATUS]: {
        required: requiredMessage,
    },
};

export const settingsMemberRule = {
    email: {
        maxLength: {
            value: 320,
            message: "Не более 320 символов",
        },
        required: requiredMessage,
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
    login: {
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        minLength: {
            value: 6,
            message: "Не менее 6 символов",
        },
        required: requiredMessage,
        match: {
            value: loginReg,
            message: "Латинские буквы, цифры, знаки ._-@. Ввод пробелов запрещен.",
        },
    },
    firstName: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в имени",
        },
    },
    lastName: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в фамилии",
        },
    },
    patronymic: {
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в отчестве",
        },
    },
    role: {
        required: requiredMessage,
    },
    phone: {
        required: requiredMessage,
        minLength: {
            value: 11,
            message: "Меньше 11 символов",
        },
    },
    position: {
        minLength: {
            value: 3,
            message: "Не менее 3 символов",
        },
        match: {
            value: /^[а-яА-ЯёЁ0-9a-zA-Z.,\-()\s]+$/,
            message: "Ввод кириллицы, латиницы, цифр, знаков . , - ( ), пробелов",
        },
    },
    [CLIENT_MEMBER_FIELD_NAME.INN]: {
        func: validateInnVersion2,
    },
};

export const settingsMemberWithPassRule = {
    ...settingsMemberRule,
    password: {
        required: requiredMessage,
        func: validatePassword,
    },
};

export const clientMemberRule = {
    ...settingsMemberRule,
    firstName: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    lastName: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    patronymic: {
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    snils: {
        func: validateSnils,
    },
};

export const clientMemberWithPassRule = {
    ...clientMemberRule,
    password: {
        required: requiredMessage,
        func: validatePassword,
    },
};

export const clientInfoRule = {
    [CLIENT_FIELD_NAME.REGISTRATION_REASON_CODE]: {
        func: validateKpp,
    },
    [CLIENT_FIELD_NAME.BIC]: {
        func: validateBik,
    },
    [CLIENT_FIELD_NAME.BANK_CORR_ACCOUNT]: {
        func: validateKs,
        secondArg: CLIENT_FIELD_NAME.BIC,
    },
    [CLIENT_FIELD_NAME.OGRN]: {
        func: validateOgrn,
    },
    [CLIENT_FIELD_NAME.BANK_CHECK_ACCOUNT]: {
        func: validateRs,
        secondArg: CLIENT_FIELD_NAME.BIC,
    },
    [CLIENT_FIELD_NAME.INN]: {
        func: validateInn,
    },
};

export const contractorRule = {
    [CONTRACTOR_FIELD.BANK_CARD_NUMBER]: {
        required: requiredMessage,
        func: validateCardNumber,
    },
    [CONTRACTOR_FIELD.INN]: {
        required: requiredMessage,
        func: validateIndividualInn,
    },
    [CONTRACTOR_FIELD.LAST_NAME]: {
        required: requiredMessage,
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в фамилии",
        },
    },
    [CONTRACTOR_FIELD.FIRST_NAME]: {
        required: requiredMessage,
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в имени",
        },
    },
    [CONTRACTOR_FIELD.LENGTH_OF_STAY_EXPIRATION_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.CITIZENSHIP]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.BIRTH_DATE]: {
        required: requiredMessage,
        func: date => detectAge(date, null, 14),
    },
    [CONTRACTOR_FIELD.BIRTH_PLACE]: {
        required: requiredMessage,
        match: {
            value: /^[а-я,.А-Я(/)ёЁa-zA-Z0-9\-\s]+$/,
            message: "Недопустимые символы в адресе",
        },
    },
    [CONTRACTOR_FIELD.ID_DOC_NUMBER]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: {
        required: requiredMessage,
        match: {
            value: departamentCodeReg,
            message: "Неверный формат кода подразделения",
        },
    },
    [CONTRACTOR_FIELD.ADDRESS]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.EMAIL]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
    [CONTRACTOR_FIELD.GENDER]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.SPECIALTY]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_NUMBER]: {
        required: requiredMessage,
        match: {
            value: /^[0-9]+$/,
            message: "Только цифры",
        },
        maxLength: {
            value: 9,
            message: "Не более 9 символов",
        },
        minLength: {
            value: 5,
            message: "Не менее 5 символов",
        },
    },
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_ISSUED_BY]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.TEMP_RESID_PERM_VALID_TO_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: {
        required: requiredMessage,
        func: validateMigrationNumber,
    },
    [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: {
        required: requiredMessage,
    },
};

export const contractorInitialPersonalDataRule = {
    [CONTRACTOR_FIELD.FULL_NAME]: {
        match: {
            value: nameWithLatinReg,
            message: "Недопустимые символы в ФИО",
        },
    },
    [CONTRACTOR_FIELD.EMAIL]: {
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};

export const ticketParamsDataRule = {
    description: {
        maxLength: {
            value: 500,
            message: "Не более 500 символов",
        },
        minLength: {
            value: 3,
            message: "Минимальная длина строки 3 символа",
        },
    },
    manualCallerName: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Не более 100 символов",
        },
        minLength: {
            value: 3,
            message: "Минимальная длина строки 3 символа",
        },
    },
    category: {
        required: requiredMessage,
    },
    type: {
        required: requiredMessage,
    },
};

const contractorForeignRule = {
    [CONTRACTOR_FIELD.CITIZENSHIP]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.BIRTH_PLACE]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
    },
    [CONTRACTOR_FIELD.EIN]: {
        required: requiredMessage,
        match: {
            value: einReg,
            message: "Ввод цифр и латинских букв",
        },
        length: {
            value: 14,
            message: "Допустимое количество символов 14",
        },
    },
    [CONTRACTOR_FIELD.ID_DOC_NUMBER]: {
        required: requiredMessage,
        match: {
            value: passportNumberReg,
            message: "Ввод только букв и цифр",
        },
        length: {
            value: 9,
            message: "Допустимое количество символов 9",
        },
    },
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
    },
    [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: {
        required: requiredMessage,
    },
};

export const contractorPassportDataRule = {
    [citizenshipsDict.RU.value]: {
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: {
            required: requiredMessage,
            match: {
                value: departamentCodeReg,
                message: "Неверный формат кода подразделения",
            },
            length: {
                //+ разделитель "-" по факту получатеся 7
                value: 7,
                message: "Допустимое количество символов 6",
            },
        },
        [CONTRACTOR_FIELD.CITIZENSHIP]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: {
            required: requiredMessage,
            length: {
                value: 11,
                message: "Допустимое количество символов 10",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
        },
    },
    [citizenshipsDict.BY.value]: {
        ...contractorForeignRule,
    },
    [citizenshipsDict.KG.value]: {
        ...contractorForeignRule,
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CONTRACTOR_FIELD.PASSPORT_TYPE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.EIN]: {
            required: requiredMessage,
            match: {
                value: einReg,
                message: "Ввод цифр и латинских букв",
            },
            minLength: {
                value: 12,
                message: "Минимальная длина строки 12 символов",
            },
            maxLength: {
                value: 14,
                message: "Максимальная длина строки 14 символов",
            },
        },
    },
    [citizenshipsDict.KZ.value]: {
        ...contractorForeignRule,
        [CONTRACTOR_FIELD.EIN]: {
            required: requiredMessage,
            match: {
                value: einReg,
                message: "Ввод цифр и латинских букв",
            },
            length: {
                value: 12,
                message: "Допустимое количество символов 12",
            },
        },
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CONTRACTOR_FIELD.PASSPORT_TYPE]: {
            required: requiredMessage,
        },
    },
    [citizenshipsDict.AM.value]: {
        ...contractorForeignRule,
        [CONTRACTOR_FIELD.EIN]: undefined,
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
    },
    [citizenshipsDict.TJ.value]: {
        [CONTRACTOR_FIELD.CITIZENSHIP]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: {
            required: requiredMessage,
            maxLength: {
                value: 9,
                message: "Не более 9-и символов",
            },
            minLength: {
                value: 1,
                message: "Минимальная длина номера 1 символ",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CONTRACTOR_FIELD.EDM_PASSPORT_TYPE]: {
            required: requiredMessage,
        },
    },
    [citizenshipsDict.UA.value]: {
        [CONTRACTOR_FIELD.CITIZENSHIP]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.EIN]: {
            required: requiredMessage,
            match: {
                value: einReg,
                message: "Ввод цифр и латинских букв",
            },
            length: {
                value: 13,
                message: "Допустимое количество символов 13",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
        },
        [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
        [CONTRACTOR_FIELD.EDM_PASSPORT_TYPE]: {
            required: requiredMessage,
        },
    },
    [citizenshipsDict.UZ.value]: {
        ...contractorForeignRule,
        [CONTRACTOR_FIELD.EIN]: undefined,
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: {
            required: requiredMessage,
            maxLength: {
                value: 50,
                message: "Не более 50 символов",
            },
            minLength: {
                value: 1,
                message: "Минимальная длина номера 1 символ",
            },
        },
        [CONTRACTOR_FIELD.BIRTH_PLACE]: {
            required: requiredMessage,
            maxLength: {
                value: 255,
                message: "Не более 255 символов",
            },
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
    },
};

export const contractorFullPersonalDataGeneralRule = {
    [CONTRACTOR_FIELD.GENDER]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.EMAIL]: {
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
    [CONTRACTOR_FIELD.BIRTH_DATE]: {
        required: requiredMessage,
        secondArg: CONTRACTOR_FIELD.NAIMIX_JOINING_DATE,
    },
    [CONTRACTOR_FIELD.PHONE]: {
        required: requiredMessage,
        minLength: {
            value: 11,
            message: "Номер телефона должен состоять из 11 цифр",
        },
    },
};

export const simpleContractorDataRule = {
    [CONTRACTOR_FIELD.PHONE]: {
        required: requiredMessage,
        minLength: {
            value: 11,
            message: "Номер телефона должен состоять из 11 цифр",
        },
    },
    [CONTRACTOR_FIELD.LAST_NAME]: {
        required: requiredMessage,
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в фамилии",
        },
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    [CONTRACTOR_FIELD.FIRST_NAME]: {
        required: requiredMessage,
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в имени",
        },
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    [CONTRACTOR_FIELD.PATRONYMIC]: {
        match: {
            value: /^[-а-яА-ЯёЁa-zA-Z\s]+$/,
            message: "Недопустимые символы в отчестве",
        },
        maxLength: {
            value: 50,
            message: "Не более 50 символов",
        },
    },
    [CONTRACTOR_FIELD.EMAIL]: {
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
    [CONTRACTOR_FIELD.WORK_ADDRESS]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.MAIN_SPECIALITY_ID]: {
        required: requiredMessage,
    },
};

export const blockContractorDataRule = {
    [CONTRACTOR_FIELD.BLOCKED_REASON]: {
        required: requiredMessage,
    },
};

export const contractorFullPersonalDataRule = {
    [citizenshipsDict.RU.value]: {
        ...contractorFullPersonalDataGeneralRule,
        [CONTRACTOR_FIELD.ADDRESS]: {
            required: requiredMessage,
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
    },
    [citizenshipsDict.KG.value]: {
        ...contractorFullPersonalDataGeneralRule,
        [CONTRACTOR_FIELD.PASSPORT_RESIDENCE_ADDRESS]: {
            required: requiredMessage,
            minLength: {
                value: 5,
                message: "Минимальная длина строки 5 символов",
            },
        },
    },
    [citizenshipsDict.AM.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },
    [citizenshipsDict.KZ.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },
    [citizenshipsDict.BY.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },
    [citizenshipsDict.TJ.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },
    [citizenshipsDict.UA.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },
    [citizenshipsDict.UZ.value]: {
        ...contractorFullPersonalDataGeneralRule,
    },

};

export const contractorWorkDataRule = {
    specialities: {
        required: requiredMessage,
    },
};

export const contractorWorkRegionDataRule = {
    [CONTRACTOR_FIELD.WORK_ADDRESS_FIAS_ID]: {
        required: requiredMessage,
    },
};

export const contractorMigrationDataRule = {
    [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: {
        required: requiredMessage,
        func: validateMigrationNumber,
    },
    [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: {
        required: requiredMessage,
    },
};
export const employmentHistoryDataRule = {
    [CONTRACTOR_FIELD.EMPLOYMENT_HISTORY_NUM]: {
        required: requiredMessage,
    },
};

export const vhiPolicyDataRule = {
    [CONTRACTOR_FIELD.VHI_POLICY_VALID_FROM_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.VHI_POLICY_VALID_TO_DATE]: {
        required: requiredMessage,
    },
};

export const employmentPatentRule = {
    [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_ISSUED_DATE]: {
        required: requiredMessage,
    },
    [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_SERIES]: {
        required: requiredMessage,
        maxLength: {
            value: 5,
            message: "Допустимое количество символов 5",
        },
    },
    [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_NUM]: {
        required: requiredMessage,
        maxLength: {
            value: 10,
            message: "Допустимое количество символов 10",
        },
    },
    [CONTRACTOR_FIELD.EMPLOYMENT_PATENT_REGISTRATION_NUMBER]: {
        required: requiredMessage,
        maxLength: {
            value: 50,
            message: "Допустимое количество символов 50",
        },
    },
};

export const instructionRule = {
    date: {
        required: requiredMessage,
        func: validateInstructionDate,
    },
    version: {
        required: requiredMessage,
        minLength: {
            value: 1,
            message: "Минимальная длина строки 1 символ",
        },
        maxLength: {
            value: 20,
            message: "Максимальная длина строки 20 символов",
        },
    },
    fileName: {
        required: requiredMessage,
    },
};
/*
ARRIVAL_NOTIFICATION: "Уведомление о прибытии иностранного гражданина в место пребывания"
RESIDENCE_PERMIT: "Вид на жительство"
* */
export const contractorArrivalDataRule = {
    ARRIVAL_NOTIFICATION: {
        [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.REGISTRATION_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ADDRESS]: {
            required: requiredMessage,
        },
    },
    RESIDENCE_PERMIT: {
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: {
            required: requiredMessage,
            length: {
                value: 10,
                message: "Допустимое количество символов 9",
            },
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: {
            required: requiredMessage,
        },
    },
    UNDEFINED: {
        [CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.REGISTRATION_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.ADDRESS]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: {
            required: requiredMessage,
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: {
            required: requiredMessage,
            length: {
                value: 10,
                message: "Допустимое количество символов 9",
            },
        },
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: {
            required: requiredMessage,
        },
    },
};

export const contractorPaymentDataRule = {
    [CONTRACTOR_FIELD.BANK_CARD_NUMBER]: {
        func: validateCardNumber,
    },
};

export const contractorPaymentDataDetailsRule = {
    [CONTRACTOR_FIELD.RS]: {
        required: requiredMessage,
        func: validateRs,
        secondArg: CONTRACTOR_FIELD.BIC,
    },
    [CONTRACTOR_FIELD.BANK_NAME]: {
        required: requiredMessage,
        minLength: {
            value: 1,
            message: "Минимальная длина - 1 символ",
        },
        maxLength: {
            value: 255,
            message: "Максимальная длина - 255 символов",
        },
    },
    [CONTRACTOR_FIELD.INN]: {
        required: requiredMessage,
        func: validateInn,
    },
    [CONTRACTOR_FIELD.BIC]: {
        required: requiredMessage,
        func: validateBik,
    },
    [CONTRACTOR_FIELD.BANK_ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Максимальная длина - 100 символов",
        },
    },
    [CONTRACTOR_FIELD.KS]: {
        required: requiredMessage,
        func: validateKs,
        secondArg: CONTRACTOR_FIELD.BIC,
    },
};

export const objectRule = {
    [OBJECT_FIELD_NAME.PROJECT_ID]: {
        required: requiredMessage,
    },
    [OBJECT_FIELD_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 500,
            message: "Не более 500 символов",
        },
    },
    [OBJECT_FIELD_NAME.ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
    },
    [OBJECT_FIELD_NAME.OBJECT_EXT_ID]: {
        maxLength: {
            value: 64,
            message: "Не более 64 символов",
        },
    },
    [OBJECT_FIELD_NAME.REGION]: {
        required: requiredMessage,
    },
    [OBJECT_FIELD_NAME.FEDERAL_ID]: {
        required: requiredMessage,
    },
    [OBJECT_FIELD_NAME.LONGITUDE]: {
        required: `${requiredMessage}. Выберите адрес на карте или из списка`,
    },
    [OBJECT_FIELD_NAME.LATITUDE]: {
        required: `${requiredMessage}. Выберите адрес на карте или из списка`,
    },
};

export const objectIdRequiredRule = {
    [OBJECT_FIELD_NAME.OBJECT_EXT_ID]: {
        required: requiredMessage,
    },
};

export const otherDocumentRequiredRule = {
    otherDocumentTemplateId: {
        required: requiredMessage,
    },
};

export const documentNotificationRule = {
    [DOCUMENT_NOTIFICATIONS_FIELD_NAME.ADDRESS]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
    },
    [DOCUMENT_NOTIFICATIONS_FIELD_NAME.REGION]: {
        required: requiredMessage,
    },
    [DOCUMENT_NOTIFICATIONS_FIELD_NAME.FEDERAL_ID]: {
        required: requiredMessage,
    },
    [DOCUMENT_NOTIFICATIONS_FIELD_NAME.LONGITUDE]: {
        required: `${requiredMessage}. Выберите адрес на карте или из списка`,
    },
    [DOCUMENT_NOTIFICATIONS_FIELD_NAME.LATITUDE]: {
        required: `${requiredMessage}. Выберите адрес на карте или из списка`,
    },
};

/* Форма напишите нам, лэндинг */

const landingFioRule = {
    required: requiredMessage,
    match: {
        value: /^[а-яa-zA-ZА-ЯёЁ\s-]+$/,
        message: "Только кириллица, латиница, пробелы и знаки тире",
    },
    maxLength: {
        value: 100,
        message: "Не более 100 символов",
    },
};

export const landingFeedbackGeneralFields = {
    [LANDING_FIELDS_NAME.FIO]: landingFioRule,
    [LANDING_FIELDS_NAME.QUESTION]: {
        required: requiredMessage,
    },
};

export const landingFeedbackPhoneRule = {
    ...landingFeedbackGeneralFields,
    [LANDING_FIELDS_NAME.PHONE]: {
        required: requiredMessage,
        length: {
            value: 11,
            message: "Номер телефона должен состоять из 10 цифр",
        },
    },
};

export const landingFeedbackEmailRule = {
    ...landingFeedbackGeneralFields,
    [LANDING_FIELDS_NAME.EMAIL]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};


/* Форма "Подключение", лэндинг, страница "Заказчикам" */
export const connectionFormRule = {
    [LANDING_FIELDS_NAME.NAME]: {
        required: requiredMessage,
        maxLength: {
            value: 100,
            message: "Не более 100 символов",
        },
    },
    [LANDING_FIELDS_NAME.EMAIL]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
    [LANDING_FIELDS_NAME.FIO]: landingFioRule,
    [LANDING_FIELDS_NAME.PHONE]: {
        required: requiredMessage,
        length: {
            value: 11,
            message: "Номер телефона должен состоять из 10 цифр",
        },
    },
    [LANDING_FIELDS_NAME.AGREEMENT_POLICY]: {
        agree: "Вы должны согласиться с условиями политики",
    },
};

export const passwordRule = {
    password: {
        required: requiredMessage,
        func: validatePassword,
    },
};

/* Форма изменения пароля */
export const passwordChangeRule = {
    password: {
        required: requiredMessage,
        func: validatePassword,
    },
    repeatPassword: {
        required: requiredMessage,
        func: validatePassword,
    },
};

export const disputeNewRule = {
    [DISPUTE_FIELD.CLIENT_ID]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.ORDER_ID]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.CONTRACTOR_ID]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.TREATMENT_CHANNEL]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.CAUSE_DISPUTE]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.DESCRIPTION]: {
        required: requiredMessage,
    },
};

export const newDisputeRegistryOrderRule = {
    [DISPUTE_FIELD.CLIENT_ID]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.ORDER_ID]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.TREATMENT_CHANNEL]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.CAUSE_DISPUTE]: {
        required: requiredMessage,
    },
    [DISPUTE_FIELD.DESCRIPTION]: {
        required: requiredMessage,
    },
};

export const emailRule = {
    [LANDING_FIELDS_NAME.EMAIL]: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};

export const specialtyDirectoryRule = {
    categoryId: {
        required: requiredMessage,
    },
    subCategoryId: {
        required: requiredMessage,
    },
};

export const joiningRequestRule = {
    organizationName: {
        required: requiredMessage,
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
    },
    phone: {
        required: requiredMessage,
        length: {
            value: 11,
            message: "Номер телефона должен состоять из 10 цифр",
        },
    },
    email: {
        maxLength: {
            value: 255,
            message: "Не более 255 символов",
        },
        match: {
            value: emailReg,
            message: "Введен некорректный email",
        },
    },
};

export const orderCreatingTypeRule = {
    orderManagerId: {
        required: requiredMessage,
    },
    projectId: {
        required: requiredMessage,
    },
};

export const stopListAddInnRule = {
    [CLIENT_FIELD_NAME.INN]: {
        required: requiredMessage,
        func: validateIndividualInn,
    },
};

export const citizenPendingValidationRules = {
    1: {
        [CONTRACTOR_FIELD.CITIZENSHIP]: contractorRule[CONTRACTOR_FIELD.CITIZENSHIP],
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY],
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE],
        [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY_DEPARTMENT_CODE],
        [CONTRACTOR_FIELD.ID_DOC_NUMBER]: contractorRule[CONTRACTOR_FIELD.ID_DOC_NUMBER],
        [CONTRACTOR_FIELD.GENDER]: contractorRule[CONTRACTOR_FIELD.GENDER],
        [CONTRACTOR_FIELD.BIRTH_DATE]: contractorRule[CONTRACTOR_FIELD.BIRTH_DATE],
        [CONTRACTOR_FIELD.BIRTH_PLACE]: contractorRule[CONTRACTOR_FIELD.BIRTH_PLACE],
        [CONTRACTOR_FIELD.INN]: {
            required: requiredMessage,
            func: validateInnVersion2,
        },
    },
    2: {
        [CONTRACTOR_FIELD.ADDRESS]: contractorRule[CONTRACTOR_FIELD.ADDRESS],
    },
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
};

export const foreignerPendingValidationRules = (step, citizenship, arrivalDocType) => {
    switch (step) {
        case 1:
            return {
                [CONTRACTOR_FIELD.PASSPORT_TYPE]: {required: requiredMessage},
                [CONTRACTOR_FIELD.CITIZENSHIP]: contractorRule[CONTRACTOR_FIELD.CITIZENSHIP],
                [CONTRACTOR_FIELD.ID_DOC_ISSUED_BY]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_BY],
                [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE],
                [CONTRACTOR_FIELD.ID_DOC_NUMBER]: contractorRule[CONTRACTOR_FIELD.ID_DOC_NUMBER],
                [CONTRACTOR_FIELD.GENDER]: contractorRule[CONTRACTOR_FIELD.GENDER],
                [CONTRACTOR_FIELD.BIRTH_PLACE]: contractorRule[CONTRACTOR_FIELD.BIRTH_PLACE],
                [CONTRACTOR_FIELD.EIN]: citizenship !== "AM" ? {
                    required: requiredMessage,
                    match: {
                        value: einReg,
                        message: "Ввод цифр и латинских букв",
                    },
                    length: {
                        value: 14,
                        message: "Допустимое количество символов 14",
                    },
                } : null,
                [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: {
                    required: requiredMessage,
                },
            };
        case 2:
            return {
                [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: citizenship !== "BY" ? contractorRule[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER] : null,
                [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: citizenship !== "BY" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: citizenship !== "BY" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.GENDER]: contractorRule[CONTRACTOR_FIELD.GENDER],
                [CONTRACTOR_FIELD.BIRTH_DATE]: contractorRule[CONTRACTOR_FIELD.BIRTH_DATE],
                [CONTRACTOR_FIELD.ID_DOC_NUMBER]: contractorRule[CONTRACTOR_FIELD.ID_DOC_NUMBER],
                [CONTRACTOR_FIELD.EIN]: citizenship !== "AM" ? {
                    required: requiredMessage,
                    match: {
                        value: einReg,
                        message: "Ввод цифр и латинских букв",
                    },
                    length: {
                        value: 14,
                        message: "Допустимое количество символов 14",
                    },
                } : null,
            };
        case 3:
            return {
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: arrivalDocType === "RESIDENCE_PERMIT" ? {
                    required: requiredMessage,
                    length: {
                        value: 10,
                        message: "Допустимое количество символов 9",
                    },
                } : null,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: arrivalDocType === "RESIDENCE_PERMIT" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: arrivalDocType === "RESIDENCE_PERMIT" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: arrivalDocType === "RESIDENCE_PERMIT" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: arrivalDocType === "RESIDENCE_PERMIT" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: arrivalDocType === "RESIDENCE_PERMIT" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.REGISTRATION_DATE]: arrivalDocType === "ARRIVAL_NOTIFICATION" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: arrivalDocType === "ARRIVAL_NOTIFICATION" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.ADDRESS]: arrivalDocType === "ARRIVAL_NOTIFICATION" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.BIRTH_DATE]: contractorRule[CONTRACTOR_FIELD.BIRTH_DATE],
                [CONTRACTOR_FIELD.BIRTH_PLACE]: contractorRule[CONTRACTOR_FIELD.BIRTH_PLACE],
                [CONTRACTOR_FIELD.PASSPORT_TYPE]: {required: requiredMessage},
                [CONTRACTOR_FIELD.ID_DOC_NUMBER]: contractorRule[CONTRACTOR_FIELD.ID_DOC_NUMBER],
                [CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE]: contractorRule[CONTRACTOR_FIELD.ID_DOC_ISSUED_DATE],
                [CONTRACTOR_FIELD.ID_DOC_VALID_TO_DATE]: {required: requiredMessage},
                [CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER]: citizenship !== "BY" ? contractorRule[CONTRACTOR_FIELD.MIGRATION_CARD_NUMBER] : null,
                [CONTRACTOR_FIELD.MIGRATION_CARD_ISSUED_DATE]: citizenship !== "BY" ? {required: requiredMessage} : null,
                [CONTRACTOR_FIELD.MIGRATION_CARD_VALID_TO_DATE]: citizenship !== "BY" ? {required: requiredMessage} : null,
            };
        default:
            return {
                [CONTRACTOR_FIELD.PHONE]: contractorFullPersonalDataGeneralRule[CONTRACTOR_FIELD.PHONE],
                [CONTRACTOR_FIELD.EMAIL]: contractorRule[CONTRACTOR_FIELD.EMAIL],
                [CONTRACTOR_FIELD.BANK_CARD_NUMBER]: contractorRule[CONTRACTOR_FIELD.BANK_CARD_NUMBER],
                [CONTRACTOR_FIELD.INN]: {
                    required: requiredMessage,
                    func: validateInnVersion2,
                },
            };
    }
};

export const mainSpecialtyRule = {
    [CLIENT_FIELD_NAME.MAIN_SPECIALITY_ID]: {
        required: requiredMessage,
    },
};

export const promocodeRule = {
    "promocode": {
        required: requiredMessage,
    },
};

export const orderByTemplateRule = {
    [CLIENT_PROPERTIES_FIELD.ORDER_BY_TEMPLATE_PROJECT_IDS]: {
        required: requiredMessage,
    },
};

export const clientPatentContractRule = {
    [CLIENT_FIELD_NAME.PATENT_CONTRACT_DATE]: {
        required: requiredMessage,
    },
    [CLIENT_FIELD_NAME.PATENT_CONTRACT_NUMBER]: {
        required: requiredMessage,
    },
};

export const invoiceOfDepositReplenishmentRule = {
    sum: {
        required: requiredMessage,
        func: validateInvoiceOfDepositReplenishmentSum,
    },
};

export const templateOtherDocumentDataRule = {
    name: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 150,
            message: "Максимальная длина строки 150 символов",
        },
    },
};