import {all} from "redux-saga/effects";

import {saga as kedoLogs} from "../kedo/logs/sagas";
import {saga as advertisementSaga} from "./advertisement/sagas";
import {saga as bankTkbSaga} from "./bank/tkb/sagas";
import {saga as bankW1Saga} from "./bank/w1/sagas";
import {saga as clientDocumentsContractAgencySaga} from "./clients/documents/contract-agency/sagas";
import {saga as clientFrameContractsRegistriesSaga} from "./clients/documents/frame-contracts-registry/sagas";
import {saga as clientDocumentsRegistrySaga} from "./clients/documents/registry/sagas";
import {saga as clientsEdoDirectoryPositionsSaga} from "./clients/edo/directory/positions/sagas";
import {saga as clientsEdoDirectorySubdivisionsSaga} from "./clients/edo/directory/subdivisions/sagas";
import {saga as clientsEdoDocumentsSaga} from "./clients/edo/documents/sagas";
import {saga as clientsEdoRegistriesCardSaga} from "./clients/edo/registries/card/sagas";
import {saga as clientsEdoRegistriesSaga} from "./clients/edo/registries/sagas";
import {saga as clientsEdoStaffSaga} from "./clients/edo/staff/sagas";
import {saga as clientsEdoStatementsSaga} from "./clients/edo/statements/sagas";
import {saga as clientCardEmployeesSaga} from "./clients/employees/sagas";
import {saga as clientDepositReplenishmentAndRefundsSaga} from "./clients/finances/deposit/replenishment-and-refunds/sagas";
import {saga as financeNdflDepositSaga} from "./clients/finances/ndfl-deposit/sagas";
import {saga as financeNpdDepositSaga} from "./clients/finances/npd-deposit/sagas";
import {saga as financePatentsSaga} from "./clients/finances/patents/sagas";
import {saga as clientForemenForemanContractorsSaga} from "./clients/foremen/foreman-contractors/sagas";
import {saga as clientForemenPaymentsSaga} from "./clients/foremen/payments/sagas";
import {saga as clientForemenRequestsSaga} from "./clients/foremen/requests/sagas";
import {saga as clientForemenTimesheetsSaga} from "./clients/foremen/timesheets/sagas";
import {saga as clientCardInfoSaga} from "./clients/info/sagas";
import {saga as clientsInstructionsKedoSaga} from "./clients/instructions/kedo/sagas";
import {saga as clientsModuleTariffsSaga} from "./clients/module-tariffs/sagas";
import {saga as clientsMonitoringRisk} from "./clients/monitoring-risk/sagas";
import {saga as notificationSendingToContractorSaga} from "./clients/notification-sending/to-contractor/sagas";
import {saga as clientObjectCardSaga} from "./clients/objects/card/sagas";
import {saga as clientObjectsSaga} from "./clients/objects/sagas";
import {saga as orderAnalyticsAllApplicationsSaga} from "./clients/order-analytics/all-applications/sagas";
import {saga as orderAnalyticsSaga} from "./clients/order-analytics/sagas";
import {saga as clientsPatentsPayCardSaga} from "./clients/patents/pay/card/sagas";
import {saga as clientsPatentsPaySaga} from "./clients/patents/pay/sagas";
import {saga as clientProjectCardSaga} from "./clients/projects/card/sagas";
import {saga as clientProjectsSaga} from "./clients/projects/sagas";
import {saga as registryPaymentCardSaga} from "./clients/registry-payment/card/sagas";
import {saga as registryPaymentLogSaga} from "./clients/registry-payment/log/sagas";
import {saga as registryPaymentSaga} from "./clients/registry-payment/sagas";
import {saga as clientCardReportsOneCSaga} from "./clients/reports/one-c/sagas";
import {saga as clientsSettingsSaga} from "./clients/settings/sagas";
import {saga as clientsStopListSaga} from "./clients/stop-list/sagas";
import {saga as clientsSystemPropertiesSaga} from "./clients/system-properties/sagas";
import {saga as commonClientSaga} from "./common/client/sagas";
import {saga as commonDictsSaga} from "./common/dicts/sagas";
import {saga as commonJobSaga} from "./common/job/sagas";
import {saga as commonOrder} from "./common/order/sagas";
import {saga as commonTreeSaga} from "./common/tree/sagas";
import {saga as contractorCardBanksSaga} from "./contractor-сard/banks/sagas";
import {saga as contractorCardDevicesSaga} from "./contractor-сard/devices/sagas";
import {saga as contractorDocumentsSaga} from "./contractor-сard/documents/sagas";
import {saga as contractorCardDocumentSigningSaga} from "./contractor-сard/event-log/document-signing/sagas";
import {saga as contractorCardNotificationLogSaga} from "./contractor-сard/event-log/notification-log/sagas";
import {saga as contractorCardPersonalDataEventsLogSaga} from "./contractor-сard/event-log/personal-data-events/sagas";
import {saga as contractorCardEventLogSaga} from "./contractor-сard/event-log/sagas";
import {saga as contractorCardFinanceBalanceSaga} from "./contractor-сard/finance/balance/sagas";
import {saga as contractorCardOrderHistorySaga} from "./contractor-сard/order-history/sagas";
import {saga as contractorCardPersonalDataSaga} from "./contractor-сard/personal-data/sagas";
import {saga as contractorCardProfileSaga} from "./contractor-сard/profile/sagas";
import {saga as contractorCardSaga} from "./contractor-сard/sagas";
import {saga as contractorTaskHistorySaga} from "./contractor-сard/task-history/sagas";
import {saga as contractorsRegistrySaga} from "./contractors/registry/sagas";
import {saga as contractorsResourcesSaga} from "./contractors/resources/sagas";
import {saga as crowdActRegistrySaga} from "./crowd/actRegistry/sagas";
import {saga as crowdActRegistryEntrySaga} from "./crowd/actRegistryEntry/sagas";
import {saga as crowdPaymentSaga} from "./crowd/payment/sagas";
import {saga as crowdTaskCardSaga} from "./crowd/taskCard/sagas";
import {saga as crowdTaskGroupSaga} from "./crowd/taskGroup/sagas";
import {saga as crowdTaskGroupsSaga} from "./crowd/taskGroups/sagas";
import {saga as crowdTasksSaga} from "./crowd/tasks/sagas";
import {saga as crowdTasksAnalyticsSaga} from "./crowd/tasksAnalytics/sagas";
import {saga as disputesSaga} from "./disputes/sagas";
import {saga as documentsAdditionalAgreementsRegistrySaga} from "./documents/additional-agreements/registry/sagas";
import {saga as documentsAdditionalAgreementsSaga} from "./documents/additional-agreements/sagas";
import {saga as deferredActsOfAcceptanceWorkCardSaga} from "./documents/deferred-acts-of-acceptance-work/card/sagas";
import {saga as deferredActsOfAcceptanceWorkSaga} from "./documents/deferred-acts-of-acceptance-work/sagas";
import {saga as documentsTemplatesSaga} from "./documents-templates/sagas";
import {saga as exportDocumentsSaga} from "./export/documents/sagas";
import {saga as exportReportsSaga} from "./export/reports/sagas";
import {saga as financesNdflPaymentsSaga} from "./finances/ndfl-payments/sagas";
import {saga as financesNdflSummarySaga} from "./finances/ndfl-summary/sagas";
import {saga as financesNpdPaymentsSaga} from "./finances/npd-payments/sagas";
import {saga as financesNpdSummarySaga} from "./finances/npd-summary/sagas";
import {saga as financesOrdersSaga} from "./finances/orders/sagas";
import {saga as financesPatentsSummarySaga} from "./finances/patent-summary/sagas";
import {saga as financesReceiptsCancelledSaga} from "./finances/receipts-cancelled/sagas";
import {saga as registryPaymentsSaga} from "./finances/registry-payments/sagas";
import {saga as mapSaga} from "./map/sagas";
import {saga as marketingNotificationsSaga} from "./marketing/notifications/sagas";
import {saga as emailNotificationsSaga} from "./notifications/email-notifications/sagas";
import {saga as notificationsSaga} from "./notifications/sagas";
import {saga as subscribeNotificationsSaga} from "./notifications/subscribe/sagas";
import {saga as oneCSaga} from "./one-c/sagas";
import {saga as orderContractorWorkLogSaga} from "./orders/contractor-work-log/sagas";
import {saga as orderActRegistriesCardSaga} from "./orders/order-act-registries/card/sagas";
import {saga as orderActRegistriesSaga} from "./orders/order-act-registries/sagas";
import {saga as orderContractorsSaga} from "./orders/order-contractors/sagas";
import {saga as orderGroupsCard} from "./orders/order-groups/card/sagas";
import {saga as orderGroups} from "./orders/order-groups/sagas";
import {saga as registryInvitationsCardSaga} from "./orders/order-invitations-registry/card/sagas";
import {saga as registryInvitationsSaga} from "./orders/order-invitations-registry/sagas";
import {saga as ordersRegistrySaga} from "./orders/orders-registry/sagas";
import {saga as patentPaymentsSaga} from "./patent/sagas";
import {saga as plutoniumTransactionLogSaga} from "./plutonium/transaction-log/sagas";
import {saga as promotionChannelsCardSaga} from "./promotion-channels/card/sagas";
import {saga as promotionChannelsSaga} from "./promotion-channels/sagas";
import {saga as recruitmentAccessControlJobBoards} from "./recruitment/access-control/job-boards/sagas";
import {saga as recruitmentAccessControlObservers} from "./recruitment/access-control/observers/sagas";
import {saga as recruitmentAccessControlRecruitersGroups} from "./recruitment/access-control/recruiters-groups/sagas";
import {saga as recruitmentAccessControl} from "./recruitment/access-control/sagas";
import {saga as recruitmentAccessControlVacancyPersonalAccess} from "./recruitment/access-control/vacancy-personal-access/sagas";
import {saga as recruitmentDirectorySaga} from "./recruitment/directory/sagas";
import {saga as recruitmentJobBoardsSaga} from "./recruitment/jobBoards/sagas";
import {saga as recruitmentJobBoardsWebHookSaga} from "./recruitment/jobBoardsWebhook/sagas";
import {saga as recruitmentVacancySaga} from "./recruitment/vacancy/sagas";
import {saga as recruitmentVacancyCandidatesResponsesSaga} from "./recruitment/vacancyCandidates/responses/sagas";
import {saga as recruitmentVacancyCandidatesSaga} from "./recruitment/vacancyCandidates/sagas";
import {saga as recruitmentVacancyCandidatesCommentsSaga} from "./recruitment/vacancyCandidatesComments/sagas";
import {saga as recruitmentVacancyResponsesSaga} from "./recruitment/vacancyResponses/sagas";
import {saga as reviewsClientSaga} from "./reviews/client/sagas";
import {saga as reviewsContractorSaga} from "./reviews/contractor/sagas";
import {saga as settingsClientGroupsCardClientUsersSaga} from "./settings/client-groups/card/client-users/sagas";
import {saga as settingsClientGroupsCardClientsSaga} from "./settings/client-groups/card/clients/sagas";
import {saga as settingsClientGroupsCardContractorsSaga} from "./settings/client-groups/card/contractors/sagas";
import {saga as settingsClientGroupsCardSaga} from "./settings/client-groups/card/sagas";
import {saga as settingsClientGroupsCardSearchContractorsSaga} from "./settings/client-groups/card/search-contractors/sagas";
import {saga as settingsClientGroupsSaga} from "./settings/client-groups/sagas";
import {saga as settingsCollectionBankDetailsSaga} from "./settings/collection-bank-details/sagas";
import {saga as settingsContractorSupportSaga} from "./settings/contractor-support/sagas";
import {saga as settingsDirectoriesNaimixBanksSaga} from "./settings/directories/naimix/banks/sagas";
import {saga as settingsDirectoriesNaimixCategorySpecialitiesSaga} from "./settings/directories/naimix/category-specialities/sagas";
import {saga as settingsDirectoriesNaimixClientCategoriesSaga} from "./settings/directories/naimix/client-categories/sagas";
import {saga as settingsDirectoriesNaimixDocumentsSaga} from "./settings/directories/naimix/documents/sagas";
import {saga as settingsDirectoriesNaimixFederalMigrationSaga} from "./settings/directories/naimix/federal-migration/sagas";
import {saga as settingsDirectoriesNaimixKeywordsSaga} from "./settings/directories/naimix/keywords/sagas";
import {saga as settingsDirectoriesNaimixSpecialitiesSaga} from "./settings/directories/naimix/specialities/sagas";
import {saga as settingsDirectoriesNaimixSpecialtyOfferSaga} from "./settings/directories/naimix/specialty-offer/sagas";
import {saga as settingsDirectoriesNaimixStatementsSaga} from "./settings/directories/naimix/statements/sagas";
import {saga as settingsDirectoriesNaimixStopWordsSaga} from "./settings/directories/naimix/stop-words/sagas";
import {saga as settingsDirectoriesNaimixTariffGridSaga} from "./settings/directories/naimix/tariff-grid/sagas";
import {saga as settingsDocumentsCorrectionSaga} from "./settings/documents-correction/sagas";
import {saga as settingsEmployeesSaga} from "./settings/employees/sagas";
import {saga as settingsInstructionsKedoSaga} from "./settings/instructions/kedo/sagas";
import {saga as settingsInstructionsNaimixSaga} from "./settings/instructions/naimix/sagas";
import {saga as settingsJoiningRequestsSaga} from "./settings/joining-requests/sagas";
import {saga as settingsMachineLearningSaga} from "./settings/machine-learning/sagas";
import {saga as settingsNaimixInfoSaga} from "./settings/naimix-info/sagas";
import {saga as settingsServiceSaga} from "./settings/service/sagas";
import {saga as settingsServiceTaskStatsSaga} from "./settings/service/task-stats/sagas";
import {saga as systemTransactionsSaga} from "./system-transactions/sagas";

import {saga as paidApisSaga} from "./finances/paid-apis/sagas";

export default function* rootBffSaga() {
    yield all([
        clientsSettingsSaga(),
        commonDictsSaga(),
        mapSaga(),
        recruitmentDirectorySaga(),
        recruitmentVacancyCandidatesSaga(),
        recruitmentVacancySaga(),
        recruitmentVacancyCandidatesCommentsSaga(),
        recruitmentVacancyResponsesSaga(),
        recruitmentVacancyCandidatesResponsesSaga(),
        recruitmentJobBoardsSaga(),
        recruitmentAccessControl(),
        recruitmentAccessControlRecruitersGroups(),
        bankW1Saga(),
        bankTkbSaga(),
        crowdTasksSaga(),
        crowdTaskCardSaga(),
        crowdTaskGroupsSaga(),
        settingsInstructionsKedoSaga(),
        settingsInstructionsNaimixSaga(),
        crowdTaskGroupSaga(),
        crowdPaymentSaga(),
        clientsInstructionsKedoSaga(),
        systemTransactionsSaga(),
        crowdTasksAnalyticsSaga(),
        crowdActRegistrySaga(),
        crowdActRegistryEntrySaga(),
        contractorTaskHistorySaga(),
        contractorDocumentsSaga(),
        contractorCardOrderHistorySaga(),
        kedoLogs(),
        clientsSystemPropertiesSaga(),
        clientsModuleTariffsSaga(),
        patentPaymentsSaga(),
        plutoniumTransactionLogSaga(),
        financesReceiptsCancelledSaga(),
        financesNpdPaymentsSaga(),
        financesNpdSummarySaga(),
        financesNdflSummarySaga(),
        financesPatentsSummarySaga(),
        financesNdflPaymentsSaga(),
        recruitmentAccessControlJobBoards(),
        recruitmentAccessControlObservers(),
        recruitmentAccessControlVacancyPersonalAccess(),
        recruitmentJobBoardsWebHookSaga(),
        notificationSendingToContractorSaga(),
        clientsMonitoringRisk(),
        contractorsRegistrySaga(),
        contractorsResourcesSaga(),
        exportReportsSaga(),
        exportDocumentsSaga(),
        promotionChannelsSaga(),
        promotionChannelsCardSaga(),
        commonTreeSaga(),
        contractorCardSaga(),
        contractorCardProfileSaga(),
        clientForemenPaymentsSaga(),
        clientForemenForemanContractorsSaga(),
        clientForemenRequestsSaga(),
        clientForemenTimesheetsSaga(),
        contractorCardPersonalDataSaga(),
        contractorCardBanksSaga(),
        contractorCardDevicesSaga(),
        contractorCardFinanceBalanceSaga(),
        contractorCardPersonalDataEventsLogSaga(),
        contractorCardEventLogSaga(),
        contractorCardDocumentSigningSaga(),
        contractorCardNotificationLogSaga(),
        documentsAdditionalAgreementsSaga(),
        documentsAdditionalAgreementsRegistrySaga(),
        marketingNotificationsSaga(),
        settingsServiceTaskStatsSaga(),
        settingsServiceSaga(),
        settingsJoiningRequestsSaga(),
        settingsEmployeesSaga(),
        settingsCollectionBankDetailsSaga(),
        settingsContractorSupportSaga(),
        settingsNaimixInfoSaga(),
        settingsClientGroupsSaga(),
        settingsClientGroupsCardSaga(),
        settingsClientGroupsCardClientsSaga(),
        settingsClientGroupsCardContractorsSaga(),
        settingsClientGroupsCardSearchContractorsSaga(),
        settingsClientGroupsCardClientUsersSaga(),
        settingsMachineLearningSaga(),
        settingsDirectoriesNaimixBanksSaga(),
        settingsDirectoriesNaimixKeywordsSaga(),
        settingsDirectoriesNaimixDocumentsSaga(),
        settingsDirectoriesNaimixStatementsSaga(),
        settingsDirectoriesNaimixClientCategoriesSaga(),
        settingsDirectoriesNaimixSpecialitiesSaga(),
        settingsDirectoriesNaimixCategorySpecialitiesSaga(),
        settingsDirectoriesNaimixTariffGridSaga(),
        settingsDirectoriesNaimixStopWordsSaga(),
        settingsDirectoriesNaimixSpecialtyOfferSaga(),
        settingsDirectoriesNaimixFederalMigrationSaga(),
        settingsDocumentsCorrectionSaga(),
        reviewsContractorSaga(),
        reviewsClientSaga(),
        financesOrdersSaga(),
        clientCardInfoSaga(),
        clientCardEmployeesSaga(),
        orderGroups(),
        orderGroupsCard(),
        orderContractorsSaga(),
        registryInvitationsSaga(),
        registryInvitationsCardSaga(),
        orderActRegistriesSaga(),
        orderActRegistriesCardSaga(),
        clientDepositReplenishmentAndRefundsSaga(),
        clientCardReportsOneCSaga(),
        oneCSaga(),
        commonClientSaga(),
        commonJobSaga(),
        ordersRegistrySaga(),
        orderContractorWorkLogSaga(),
        notificationsSaga(),
        emailNotificationsSaga(),
        advertisementSaga(),
        clientProjectsSaga(),
        clientProjectCardSaga(),
        clientObjectsSaga(),
        clientObjectCardSaga(),
        clientsPatentsPaySaga(),
        clientsPatentsPayCardSaga(),
        clientDocumentsRegistrySaga(),
        clientFrameContractsRegistriesSaga(),
        clientDocumentsContractAgencySaga(),
        documentsTemplatesSaga(),
        registryPaymentSaga(),
        registryPaymentCardSaga(),
        registryPaymentLogSaga(),
        orderAnalyticsSaga(),
        orderAnalyticsAllApplicationsSaga(),
        clientsStopListSaga(),
        disputesSaga(),
        registryPaymentsSaga(),
        clientsEdoDirectoryPositionsSaga(),
        clientsEdoDirectorySubdivisionsSaga(),
        clientsEdoStaffSaga(),
        clientsEdoDocumentsSaga(),
        clientsEdoRegistriesSaga(),
        clientsEdoRegistriesCardSaga(),
        clientsEdoStatementsSaga(),
        subscribeNotificationsSaga(),
        deferredActsOfAcceptanceWorkSaga(),
        deferredActsOfAcceptanceWorkCardSaga(),
        commonOrder(),
        financeNdflDepositSaga(),
        financeNpdDepositSaga(),
        financePatentsSaga(),
        paidApisSaga(),
    ]);
}