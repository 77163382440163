import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../../fileStore";
import {
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS,
    ADD_ITEM_INTO_FC_REGISTRY_ERROR,
    ADD_ITEM_INTO_FC_REGISTRY_REQUEST,
    ADD_ITEM_INTO_FC_REGISTRY_SUCCESS,
    ADD_REGISTRY_FRAME_CONTRACT_ERROR,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
    ADD_REGISTRY_FRAME_CONTRACT_REQUEST,
    ADD_REGISTRY_FRAME_CONTRACT_SUCCESS,
    ARCHIVE_FC_REGISTRY_ERROR,
    ARCHIVE_FC_REGISTRY_REQUEST,
    ARCHIVE_FC_REGISTRY_SUCCESS,
    CANCEL_FC_REGISTRY_ITEM_ERROR,
    CANCEL_FC_REGISTRY_ITEM_REQUEST,
    CANCEL_FC_REGISTRY_ITEM_SUCCESS,
    DELETE_FC_REGISTRY_ITEMS_ERROR,
    DELETE_FC_REGISTRY_ITEMS_REQUEST,
    DELETE_FC_REGISTRY_ITEMS_SUCCESS,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST,
    DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS,
    EXPORT_FC_REGISTRY_ERROR,
    EXPORT_FC_REGISTRY_REQUEST,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST,
    GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS,
    GET_FC_REGISTRIES_PAGE_ERROR,
    GET_FC_REGISTRIES_PAGE_REQUEST,
    GET_FC_REGISTRIES_PAGE_SUCCESS,
    GET_FC_REGISTRY_BY_ID_ERROR,
    GET_FC_REGISTRY_BY_ID_REQUEST,
    GET_FC_REGISTRY_BY_ID_SUCCESS,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS,
    GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
    GET_FC_REGISTRY_ITEMS_PAGE_REQUEST,
    GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS,
    GET_FC_REGISTRY_STATUS_DICT_ERROR,
    GET_FC_REGISTRY_STATUS_DICT_REQUEST,
    GET_FC_REGISTRY_STATUS_DICT_SUCCESS,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST,
    GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS,
    IMPORT_ITEMS_FC_REGISTRY_ERROR,
    IMPORT_ITEMS_FC_REGISTRY_REQUEST,
    IMPORT_ITEMS_FC_REGISTRY_SUCCESS,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
    SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
    SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST,
    SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
    UPDATE_REGISTRY_FRAME_CONTRACT_ERROR,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
    UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST, UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
    UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST,
    UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../../../utils/downloadBlob";
import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return (
        getBffUrl({
            ["/card"]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/documents/frame-contracts-registry/card",
                client: "/client-adm/documents/frame-contracts-registry/card",
            }),
            isClientCard: true,
            adminRolesUrl: "/adm/clients/client-card/documents/frame-contracts-registry",
            clientRolesUrl: "/client-adm/documents/frame-contracts-registry",
        },
        null,
        true,
        )
    );
};

const getFcRegistryByIdSaga = function* ({payload}) {
    try {
        const {
            data: params,
            onSuccess,
        } = payload;

        const result = yield request.bff.get(`${getController()}/get`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_FC_REGISTRY_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_BY_ID_ERROR,
        });
    }
};

const getContractorsPageForFcRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/get-contractor-page`, data);

        const {
            errorMessage,
            contractors,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (contractors?.length) {
            const contractorIds = contractors.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_ERROR,
        });
    }
};

const getFcRegistryItemsPageSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {items} = result;

        if (items?.length) {
            const contractorIds = items.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_FC_REGISTRY_ITEMS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_ITEMS_PAGE_ERROR,
        });
    }
};

const cancelFcRegistryItemSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/cancel-item`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CANCEL_FC_REGISTRY_ITEM_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Позиция реестра успешно отменена");

        yield put({
            type: CANCEL_FC_REGISTRY_ITEM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CANCEL_FC_REGISTRY_ITEM_ERROR,
        });
    }
};

const addItemIntoFcRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ITEM_INTO_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");

        yield put({
            type: ADD_ITEM_INTO_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_ITEM_INTO_FC_REGISTRY_ERROR,
        });
    }
};

const getFcRegistrySubmittingFlagsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/submitting-to-signing-flags`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_FC_REGISTRY_SUBMITTING_FLAGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_SUBMITTING_FLAGS_ERROR,
        });
    }
};

const deleteFcRegistryItemsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete-list`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_FC_REGISTRY_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_FC_REGISTRY_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_FC_REGISTRY_ITEMS_ERROR,
        });
    }
};

const deleteNotSignedItemsFcRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete-not-signed`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_ERROR,
        });
    }
};

const resubmitFcRegistryToSigningSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/resubmit-to-signing`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: RESUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: RESUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
        });
    }
};

const submitFcRegistryToSigningSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/submit-to-signing`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: SUBMIT_TO_SIGNING_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (payload.onError) {
            payload.onError();
        }

        yield put({
            type: SUBMIT_TO_SIGNING_FC_REGISTRY_ERROR,
        });
    }
};

const getFcRegistryPageSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/get-rich-page`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRIES_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_FC_REGISTRIES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRIES_PAGE_ERROR,
        });
    }
};

const getFcRegistryItemStatusDictSaga = function* () {
    try {
        const result = yield request.bff.get("/adm/common/dicts/frame-contract-registry-item-status");

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_ITEM_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_ITEM_STATUS_DICT_ERROR,
        });
    }
};

const getFcRegistryStatusDictSaga = function* () {
    try {
        const result = yield request.bff.get("/adm/common/dicts/frame-contract-registry-status");

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_FC_REGISTRY_STATUS_DICT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_FC_REGISTRY_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_FC_REGISTRY_STATUS_DICT_ERROR,
        });
    }
};

const exportFcRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
            fileName,
        } = payload;

        const reqData = {
            method: "POST",
            body: JSON.stringify(data),
        };

        const response = yield request.bff.getFile(`${getController()}/export`, reqData);

        if (response.status === 200 && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(data === "FILE_NOT_FOUND" ? "Файл не существует" : errorMessage);

                yield put({
                    type: EXPORT_FC_REGISTRY_ERROR,
                    payload: errorMessage,
                });

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        downloadBlob(blob, response.headers, fileName);

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ARCHIVE_FC_REGISTRY_SUCCESS,
            payload: response,
        });
    } catch (error) {
        yield put({
            type: EXPORT_FC_REGISTRY_ERROR,
        });
    }
};

const archiveFcRegistrySaga = function* ({payload}) {
    const {
        onSuccess,
        onError,
        data,
        archive,
    } = payload;

    try {
        const otherUrl = archive
            ? "archive-frame-contract"
            : "unarchive-frame-contract";
        const result = yield request.bff.post(`${getController()}/${otherUrl}`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ARCHIVE_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        toastSuccess(`Реестр успешно ${archive ? "архивирован" : "восстановлен из архива"}`);

        yield put({
            type: ARCHIVE_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        onError();
        yield put({
            type: ARCHIVE_FC_REGISTRY_ERROR,
        });
    }
};

const importItemsFcRegistrySaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/import`, data, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: IMPORT_ITEMS_FC_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        toastSuccess("Загрузка файла успешно завершена. Новые исполнители станут доступны через некоторое время.");

        yield put({
            type: IMPORT_ITEMS_FC_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IMPORT_ITEMS_FC_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const addRegistryFrameContractSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_REGISTRY_FRAME_CONTRACT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_REGISTRY_FRAME_CONTRACT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_REGISTRY_FRAME_CONTRACT_ERROR,
            payload: error,
        });
    }
};

const updateRegistryFrameContractSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_REGISTRY_FRAME_CONTRACT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_REGISTRY_FRAME_CONTRACT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_REGISTRY_FRAME_CONTRACT_ERROR,
            payload: error,
        });
    }
};

const addRegistryFrameContractItemsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Исполнитель успешно добавлен в реестр");

        yield put({
            type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
            payload: error,
        });
    }
};

const updateRegistryFrameContractItemsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Запись реестра успешна изменена");

        yield put({
            type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_ERROR,
            payload: error,
        });
    }
};

const addClientFrameContractExternalSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-external-frame-contract`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_FC_REGISTRIES_PAGE_REQUEST, getFcRegistryPageSaga),
        takeEvery(EXPORT_FC_REGISTRY_REQUEST, exportFcRegistrySaga),
        takeEvery(GET_FC_REGISTRY_STATUS_DICT_REQUEST, getFcRegistryStatusDictSaga),
        takeEvery(ARCHIVE_FC_REGISTRY_REQUEST, archiveFcRegistrySaga),
        takeEvery(GET_FC_REGISTRY_ITEMS_PAGE_REQUEST, getFcRegistryItemsPageSaga),
        takeEvery(SUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST, submitFcRegistryToSigningSaga),
        takeEvery(RESUBMIT_TO_SIGNING_FC_REGISTRY_REQUEST, resubmitFcRegistryToSigningSaga),
        takeEvery(GET_FC_REGISTRY_BY_ID_REQUEST, getFcRegistryByIdSaga),
        takeEvery(GET_FC_REGISTRY_ITEM_STATUS_DICT_REQUEST, getFcRegistryItemStatusDictSaga),
        takeEvery(ADD_ITEM_INTO_FC_REGISTRY_REQUEST, addItemIntoFcRegistrySaga),
        takeEvery(CANCEL_FC_REGISTRY_ITEM_REQUEST, cancelFcRegistryItemSaga),
        takeEvery(GET_CONTRACTORS_PAGE_FOR_FC_REGISTRY_REQUEST, getContractorsPageForFcRegistrySaga),
        takeEvery(DELETE_NOT_SIGNED_ITEMS_FC_REGISTRY_REQUEST, deleteNotSignedItemsFcRegistrySaga),
        takeEvery(DELETE_FC_REGISTRY_ITEMS_REQUEST, deleteFcRegistryItemsSaga),
        takeEvery(GET_FC_REGISTRY_SUBMITTING_FLAGS_REQUEST, getFcRegistrySubmittingFlagsSaga),
        takeEvery(IMPORT_ITEMS_FC_REGISTRY_REQUEST, importItemsFcRegistrySaga),
        takeEvery(ADD_REGISTRY_FRAME_CONTRACT_REQUEST, addRegistryFrameContractSaga),
        takeEvery(UPDATE_REGISTRY_FRAME_CONTRACT_REQUEST, updateRegistryFrameContractSaga),
        takeEvery(ADD_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST, addRegistryFrameContractItemsSaga),
        takeEvery(UPDATE_REGISTRY_FRAME_CONTRACT_ITEMS_REQUEST, updateRegistryFrameContractItemsSaga),
        takeEvery(ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST, addClientFrameContractExternalSaga),
    ]);
}