import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {currentChatSelector} from "../../chat";
import {getOpenDisputesTotalCount} from "./actionCreators";
import {
    BFF_DISPUTE_EMPTY_CREATE_ERROR,
    BFF_DISPUTE_EMPTY_CREATE_REQUEST,
    BFF_DISPUTE_EMPTY_CREATE_SUCCESS,
    BFF_DISPUTE_GET_CHAT_LIST_ERROR,
    BFF_DISPUTE_GET_CHAT_LIST_REQUEST,
    BFF_DISPUTE_GET_CHAT_LIST_SUCCESS,
    BFF_DISPUTE_GET_ERROR,
    BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR,
    BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST,
    BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS,
    BFF_DISPUTE_GET_REQUEST,
    BFF_DISPUTE_GET_SUCCESS,
    BFF_DISPUTE_LIST_GET_ERROR,
    BFF_DISPUTE_LIST_GET_REQUEST,
    BFF_DISPUTE_LIST_GET_SUCCESS,
    BFF_DISPUTE_UPDATE_ERROR,
    BFF_DISPUTE_UPDATE_REQUEST,
    BFF_DISPUTE_UPDATE_STATUS_ERROR,
    BFF_DISPUTE_UPDATE_STATUS_REQUEST,
    BFF_DISPUTE_UPDATE_STATUS_SUCCESS,
    BFF_DISPUTE_UPDATE_SUCCESS,
    BFF_DISPUTES_CHAT_ADD_TO_CHAT_ERROR,
    BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST,
    BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS,
    BFF_DISPUTES_CHAT_FILE_ADD_ERROR,
    BFF_DISPUTES_CHAT_FILE_ADD_REQUEST,
    BFF_DISPUTES_CHAT_FILE_ADD_SUCCESS,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_ERROR,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST,
    BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS,
    BFF_FILE_STORE_DISPUTE_ADD_FILE_ERROR,
    BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST,
    BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS,
    BFF_FILE_STORE_DISPUTE_DELETE_FILE_ERROR, BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST,
    BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS,
    BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR,
    BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST,
    BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../utils/postman";
import {toastError, toastSuccess} from "../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../utils/url";

import {
    CLIENT_CARD,
    LINK_DISPUTES_CHAT_PREFIX,
    LINK_ORDER_LIST,
} from "../../../constants/links";
import {DISPUTE_MESSAGES} from "../../../constants/messages";

const getController = () => {
    const defaultController =  getBffControllerClientCardPage({
        admin: "/adm/disputes",
        client: "/client-adm/disputes",
    });

    return (
        getBffUrl({
            [LINK_ORDER_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/orders-registry/card/disputes",
                client: "/client-adm/orders/orders-registry/card/disputes",
            }),
            [CLIENT_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/disputes",
                client: "/client-adm/disputes",
            }),
            [LINK_DISPUTES_CHAT_PREFIX]: "/adm/disputes/chat",
        },
        defaultController,
        true,
        )
    );
};

const getChatController = () => getBffControllerClientCardPage({
    admin: "/adm/disputes/chat",
    client: "/client-adm/disputes/chat",
});

// POST /bff/adm/clients/client-card/disputes/page --- АДМ-Компании-Карточка компании-Споры
// POST /bff/adm/disputes/page --- АДМ-Споры
// POST /bff/client-adm/disputes/page --- КЧ-Споры
// POST /bff/adm/clients/client-card/orders/orders-registry/card/disputes/page --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// POST /bff/client-adm/orders/orders-registry/card/disputes/page --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const getDisputesListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_LIST_GET_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DISPUTE_LIST_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);

        yield put({
            type: BFF_DISPUTE_LIST_GET_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/disputes/update --- АДМ-Компании-Карточка компании-Споры
// POST /bff/adm/clients/client-card/orders/orders-registry/card/disputes/update --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// POST /bff/adm/disputes/update --- АДМ-Споры
// POST /bff/client-adm/orders/orders-registry/card/disputes/update --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const updateDisputeSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        yield put({type: BFF_DISPUTE_UPDATE_SUCCESS, payload: result});

        toastSuccess(DISPUTE_MESSAGES.DISPUTE_CREATE_SUCCESS);
    } catch (error) {
        toastError(DISPUTE_MESSAGES.EMPTY_DISPUTE_CREATE_ERROR);

        yield put({
            type: BFF_DISPUTE_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/disputes/add --- АДМ-Компании-Карточка компании-Споры
// POST /bff/adm/clients/client-card/orders/orders-registry/card/disputes/add --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// POST /bff/adm/disputes/add --- АДМ-Споры
// POST /bff/client-adm/orders/orders-registry/card/disputes/add --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const createEmptyDisputeSaga = function* () {
    try {
        const result = yield request.bff.post(`${getController()}/add`, {});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_EMPTY_CREATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DISPUTE_EMPTY_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(DISPUTE_MESSAGES.EMPTY_DISPUTE_CREATE_ERROR);

        yield put({
            type: BFF_DISPUTE_EMPTY_CREATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/disputes/change-status --- АДМ-Компании-Карточка компании-Споры
// POST /bff/adm/clients/client-card/orders/orders-registry/card/disputes/change-status --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// POST /bff/adm/disputes/change-status --- АДМ-Споры
// POST /bff/adm/disputes/chat/change-status --- АДМ-Споры-Чат
// POST /bff/client-adm/orders/orders-registry/card/disputes/change-status --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const updateStatusDisputeSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/change-status`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_UPDATE_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        toastSuccess(DISPUTE_MESSAGES.DISPUTE_CHANGE_STATUS_SUCCESS);

        yield put({
            type: BFF_DISPUTE_UPDATE_STATUS_SUCCESS,
            payload: result,
        });

        yield put(getOpenDisputesTotalCount());
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_CHANGE_STATUS_ERROR);

        yield put({
            type: BFF_DISPUTE_UPDATE_STATUS_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/disputes/get-dispute-open-count --- АДМ-Компании-Карточка компании-Споры
// GET /bff/adm/clients/client-card/orders/orders-registry/card/disputes/get-dispute-open-count --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// GET /bff/adm/disputes/get-dispute-open-count --- АДМ-Споры
// GET /bff/client-adm/disputes/get-dispute-open-count --- КЧ-Споры
// GET /bff/client-adm/orders/orders-registry/card/disputes/get-dispute-open-count --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const getOpenDisputesTotalCountSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/get-dispute-open-count`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/disputes/chat/get-page-by-dispute-id
// POST /bff/client-adm/disputes/chat/get-page-by-dispute-id
export const getDisputesChatListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getChatController()}/get-page-by-dispute-id`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_DISPUTE_GET_CHAT_LIST_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({type: BFF_DISPUTE_GET_CHAT_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);

        yield put({
            type: BFF_DISPUTE_GET_CHAT_LIST_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/disputes/get --- АДМ-Компании-Карточка компании-Споры
// GET /bff/adm/disputes/get --- АДМ-Споры
// GET /bff/client-adm/disputes/get --- КЧ-Споры
// GET /bff/adm/clients/client-card/orders/orders-registry/card/disputes/get --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// GET /bff/client-adm/orders/orders-registry/card/disputes/get --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const getDisputeByIdSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getController()}/get`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTE_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DISPUTE_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DISPUTE_GET_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/disputes/get --- АДМ-Компании-Карточка компании-Споры
// GET /bff/adm/disputes/get --- АДМ-Споры
// GET /bff/client-adm/disputes/get --- КЧ-Споры
// GET /bff/adm/clients/client-card/orders/orders-registry/card/disputes/get --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// GET /bff/client-adm/orders/orders-registry/card/disputes/get --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
export const getDisputeFilesSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getController()}/file/get`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FILE_STORE_DISPUTE_GET_FILES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FILE_STORE_DISPUTE_GET_FILES_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/disputes/add --- АДМ-Компании-Карточка компании-Споры
// PUT /bff/adm/disputes/add --- АДМ-Споры
// PUT /bff/client-adm/disputes/add --- КЧ-Споры
// PUT /bff/adm/clients/client-card/orders/orders-registry/card/disputes/add --- АДМ-Компании-Карточка компании-Заказы-Реестр заказов-Карточка заказа-Споры
// PUT /bff/client-adm/orders/orders-registry/card/disputes/add --- КЧ-Заказы-Реестр заказов-Карточка заказа-Споры
const addDisputeFilesSaga = function* ({payload}) {
    try {
        const {
            disputeId,
            chatId,
            file: data,
            getResult,
        } = payload;

        const state = yield select();
        const currentChat = currentChatSelector(state);

        const result = yield request.bff.put(`${getController()}/file/add`, data, {
            params: {
                chatId: chatId || currentChat.chatId,
                disputeId,
            },
            ...getMultipartHeaders(),
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FILE_STORE_DISPUTE_ADD_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_FILE_STORE_DISPUTE_ADD_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_FILE_STORE_DISPUTE_ADD_FILE_ERROR,
            payload: error,
        });
    }
};

const deleteDisputeFileSaga = function* ({payload}) {
    try {
        const {errorMessage} = yield request.bff.delete(`${getController()}/file/delete`, {
            params: payload,
        },
        );

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_FILE_STORE_DISPUTE_DELETE_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_FILE_STORE_DISPUTE_DELETE_FILE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_FILE_STORE_DISPUTE_DELETE_FILE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/disputes/chat/file/add
// POST /bff/client-adm/disputes/chat/file/add
export const addFileToDisputesChatSaga = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;

        const result = yield request.bff.put(`${getChatController()}/file/add`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTES_CHAT_FILE_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_DISPUTES_CHAT_FILE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DISPUTES_CHAT_FILE_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/disputes/chat/dispute-part/add
// POST /bff/client-adm/disputes/chat/dispute-part/add
export const addToDisputeChatSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getChatController()}/dispute-part/add`, reqData);

        if (result.errorMessage) {
            yield put({
                type: BFF_DISPUTES_CHAT_ADD_TO_CHAT_ERROR,
                payload: result.errorMessage,
            });

            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess(DISPUTE_MESSAGES.DISPUTE_ADD_TO_CHAT_SUCCESS);

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DISPUTES_CHAT_ADD_TO_CHAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_DISPUTES_CHAT_ADD_TO_CHAT_ERROR,
            payload: error,
        });
    }
};

// POST /bff/client-adm/disputes/get-order-contractor-page
// POST /bff/adm/disputes/get-order-contractor-page
export const getDisputesOrderContractorListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.bff.post(`${getController()}/get-order-contractor-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DISPUTES_ORDER_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DISPUTES_ORDER_CONTRACTOR_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DISPUTES_ORDER_CONTRACTOR_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_DISPUTE_LIST_GET_REQUEST, getDisputesListSaga),
        takeEvery(BFF_DISPUTE_UPDATE_REQUEST, updateDisputeSaga),
        takeEvery(BFF_DISPUTE_EMPTY_CREATE_REQUEST, createEmptyDisputeSaga),
        takeEvery(BFF_DISPUTE_UPDATE_STATUS_REQUEST, updateStatusDisputeSaga),
        takeEvery(BFF_DISPUTE_GET_OPEN_TOTAL_COUNT_REQUEST, getOpenDisputesTotalCountSaga),
        takeEvery(BFF_DISPUTE_GET_CHAT_LIST_REQUEST, getDisputesChatListSaga),
        takeEvery(BFF_DISPUTE_GET_REQUEST, getDisputeByIdSaga),
        takeEvery(BFF_FILE_STORE_DISPUTE_GET_FILES_REQUEST, getDisputeFilesSaga),
        takeEvery(BFF_DISPUTES_CHAT_FILE_ADD_REQUEST, addFileToDisputesChatSaga),
        takeEvery(BFF_DISPUTES_CHAT_ADD_TO_CHAT_REQUEST, addToDisputeChatSaga),
        takeEvery(BFF_DISPUTES_ORDER_CONTRACTOR_LIST_REQUEST, getDisputesOrderContractorListSaga),
        takeEvery(BFF_FILE_STORE_DISPUTE_ADD_FILE_REQUEST, addDisputeFilesSaga),
        takeEvery(BFF_FILE_STORE_DISPUTE_DELETE_FILE_REQUEST, deleteDisputeFileSaga),
    ]);
}