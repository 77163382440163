import React from "react";
import {useSelector} from "react-redux";

import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";
import {
    bffRecruitmentDirectoryStatusListProgressSelector,
    bffRecruitmentDirectoryStatusListSelector,
    bffRecruitmentDirectoryStatusTotalCountSelector,
    bffRecruitmentDirectoryStatusTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/directory/selectors";
import {getRecruitmentDirectoriesAccessActions} from "../helpers/getAccessActions";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import useRecruitmentDirectoriesStatusesAction from "./hooks/useAction";
import useRecruitmentDirectoriesStatusesFetchList from "./hooks/useFetchList";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

const ClientRecruitmentDirectoriesStatuses = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentDirectoryStatusListSelector);
    const totalCount = useSelector(bffRecruitmentDirectoryStatusTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentDirectoryStatusTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentDirectoryStatusListProgressSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = getRecruitmentDirectoriesAccessActions({
        role,
        clientProperties,
    });

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentDirectoriesStatusesFetchList({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    const {
        onAdd,
        onEdit,
        onDelete,
    } = useRecruitmentDirectoriesStatusesAction({
        clientId,
    });

    const getItemActionDeleteVisible = ({isDefault}) => {
        return !isDefault;
    };

    return (
        <UpdatingDirectories
            title="Статусы"
            titleTooltipText="Страница предназначена для управления статусами, которые используются для формирования
                    воронок по вакансиям."
            labelFilter="Название статуса"
            placeholderFilter="Введите название статуса"
            onSubmitFilter={onSearch}
            isSearch={isSearch}
            filterNameField="name"
            clearFilter={onClear}
            filter={filterData}
            isEditable={isAccessAction}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            fetchList={fetchList}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText="Добавить статус"
            popupTitleAdd="Добавление статуса"
            popupTitleEdit="Редактирование статуса"
            popupTitleDelete="Вы действительно хотите удалить статус из справочника?"
            popupSubmitDelete="Подтвердить"
            popupCancelDelete="Отмена"
            inputTypeLabel="Статус"
            inputTypePlaceholder="Введите статус"
            inputTypeMaxLength={25}
            isLoaded={progressList}
            getItemActionDeleteVisible={getItemActionDeleteVisible}
            isNeedClosingOnSubmit={false}
        />
    );
};

export default ClientRecruitmentDirectoriesStatuses;