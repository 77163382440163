import {
    ADD_PATENTS_PAYMENT_ERROR,
    ADD_PATENTS_PAYMENT_FROM_FILE_ERROR,
    ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST,
    ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS,
    ADD_PATENTS_PAYMENT_REQUEST,
    ADD_PATENTS_PAYMENT_SUCCESS,
    DECLINE_PATENT_PAYMENT_ERROR,
    DECLINE_PATENT_PAYMENT_REQUEST,
    DECLINE_PATENT_PAYMENT_SUCCESS,
    DELETE_PATENTS_PAYMENT_ERROR,
    DELETE_PATENTS_PAYMENT_REQUEST,
    DELETE_PATENTS_PAYMENT_SUCCESS,
    GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS,
    PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
    PATENTS_PAYMENTS_ADMIN_PAGE_ERROR,
    PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST,
    PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST,
    PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST,
    PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS,
    PATENTS_PAYMENTS_LIST_ERROR,
    PATENTS_PAYMENTS_LIST_REQUEST,
    PATENTS_PAYMENTS_LIST_SUCCESS,
    PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS,
    PATENTS_PAYMENTS_UPDATE_STORE,
    REFRESH_PATENT_PAYMENT_STATUS_ERROR,
    REFRESH_PATENT_PAYMENT_STATUS_REQUEST,
    REFRESH_PATENT_PAYMENT_STATUS_SUCCESS,
    UPDATE_PATENTS_PAYMENT_ERROR,
    UPDATE_PATENTS_PAYMENT_REQUEST,
    UPDATE_PATENTS_PAYMENT_SUCCESS,
} from "./actions";

const initial = {
    payments: [],
    pageData: {},
    totalCount: 0,
    card: {},
    progressCard: false,
    progressList: false,
    actionRegistryProgress: false,
    paymentStatusDict: {},
    summaryData: [],
    summaryTotalData: {},
    summaryPageData: {},
    summaryDataProgress: false,
    summaryTotalProgress: false,
    summaryTotalCount: 0,
    isSuccessAction: false,
    patentPaymentsTotalAmount: 0,
    availableForPayments: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case PATENTS_PAYMENTS_CONTRACTOR_PAGE_REQUEST:
        case PATENTS_PAYMENTS_LIST_REQUEST:
        case PATENTS_PAYMENTS_ADMIN_PAGE_REQUEST:
        case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case ADD_PATENTS_PAYMENT_REQUEST:
        case ADD_PATENTS_PAYMENT_FROM_FILE_REQUEST:
        case UPDATE_PATENTS_PAYMENT_REQUEST:
        case DELETE_PATENTS_PAYMENT_REQUEST:
        case REFRESH_PATENT_PAYMENT_STATUS_REQUEST:
        case DECLINE_PATENT_PAYMENT_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case PATENTS_PAYMENTS_CONTRACTOR_PAGE_SUCCESS: {
            const {
                results: payments,
                totalCount,
            } = payload;

            return {
                ...state,
                payments,
                progressList: false,
                totalCount,
            };
        }
        case PATENTS_PAYMENTS_LIST_SUCCESS:
        case PATENTS_PAYMENTS_ADMIN_PAGE_SUCCESS:
            const {
                payments,
                totalCount,
            } = payload;

            return {
                ...state,
                payments,
                progressList: false,
                totalCount,
            };
        case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_SUCCESS:
            return {
                ...state,
                payments: payload.results,
                progressList: false,
                totalCount: payload.totalCount,
            };
        case GET_PATENTS_PAYMENT_STATUS_DICT_SUCCESS:
            return {
                ...state,
                paymentStatusDict: payload,
            };
        case PATENTS_PAYMENTS_TOTAL_AMOUNT_SUCCESS:
            return {
                ...state,
                patentPaymentsTotalAmount: payload,
            };
        case ADD_PATENTS_PAYMENT_SUCCESS:
        case ADD_PATENTS_PAYMENT_FROM_FILE_SUCCESS:
        case UPDATE_PATENTS_PAYMENT_SUCCESS:
        case DELETE_PATENTS_PAYMENT_SUCCESS:
        case REFRESH_PATENT_PAYMENT_STATUS_SUCCESS:
        case DECLINE_PATENT_PAYMENT_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
            };
        case PATENTS_PAYMENTS_CONTRACTOR_PAGE_ERROR:
        case PATENTS_PAYMENTS_LIST_ERROR:
        case PATENTS_PAYMENTS_ADMIN_PAGE_ERROR:
        case PATENTS_PAYMENTS_LIST_DEPOSIT_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        case ADD_PATENTS_PAYMENT_ERROR:
        case ADD_PATENTS_PAYMENT_FROM_FILE_ERROR:
        case UPDATE_PATENTS_PAYMENT_ERROR:
        case DELETE_PATENTS_PAYMENT_ERROR:
        case REFRESH_PATENT_PAYMENT_STATUS_ERROR:
        case DECLINE_PATENT_PAYMENT_ERROR:
            return {
                ...state,
                error: payload,
                actionRegistryProgress: false,
            };
        case PATENTS_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS:
            return {
                ...state,
                availableForPayments: payload,
            };
        case PATENTS_PAYMENTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};