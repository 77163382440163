import {all,put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {orderAdminMembersSelector} from "./bff/clients/employees/selectors";
import {getObjectUserList} from "./bff/clients/objects/card/actionCreators";

import {getTotalPages} from "../utils/mathHelper";
import {compareManagers} from "../utils/objectHelper";
import request from "../utils/postman";
import {getFormattedFullName, getFullName} from "../utils/stringFormat";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

const controller = "/project";
//*  TYPES  *//

const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
const GET_PROJECT_LIST_ERROR = "GET_PROJECT_LIST_ERROR";

const GET_PROJECTS_LIST_FOR_OBJECT_REQUEST = "GET_PROJECTS_LIST_FOR_OBJECT_REQUEST";

const GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST = "GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST";
const GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS = "GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS";
const GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR = "GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR";

const DELETE_ALL_OBJECT_USERS_REQUEST = "DELETE_ALL_OBJECT_USERS_REQUEST";
const DELETE_ALL_OBJECT_USERS_SUCCESS = "DELETE_ALL_OBJECT_USERS_SUCCESS";
const DELETE_ALL_OBJECT_USERS_ERROR = "DELETE_ALL_OBJECT_USERS_ERROR";

const UPDATE_FIELDS_STORE = "UPDATE_FIELDS_STORE";

const GET_USER_ALL_REQUEST = "GET_USER_ALL_REQUEST";
const GET_USER_ALL_SUCCESS = "GET_USER_ALL_SUCCESS";
const GET_USER_ALL_ERROR = "GET_USER_ALL_ERROR";

const GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST = "GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST";
const GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS = "GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS";
const GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR = "GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR";

//*  INITIAL STATE  *//

const initial = {
    progressList: false,
    error: {},
    pageData: {},
    projectsList: [],
    totalCount: 0,
    project: {},
    projectInfo: {},
    progressCard: false,
    objectUsersPageData: {},
    objectUserList: [],
    projectUsersPageData: {},
    usersTotalCount: 0,
    projectUsers: [],
    projectUsersTotalCount: 0,
    progressUsers: false,
    progress: false,
    objectAndProjectMembers: [],
    projectsUserResponsibleFor: [],
    objectsAndProjectsUserResponsibleFor: [],
    managerListByUser: [],
    getUsersAllProgress: false,
    projectsForObjectEdit: [],
    projectsForOrders: [],
    projectsForTasks: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PROJECTS_LIST_FOR_OBJECT_REQUEST:
        case GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case GET_USER_ALL_REQUEST:
            return {
                ...state,
                getUsersAllProgress: true,
            };
        case GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS:
            return {
                ...state,
                managerListByUser: payload,
            };
        case GET_USER_ALL_SUCCESS:
            return {
                ...state,
                objectAndProjectMembers: payload,
                getUsersAllProgress: false,
            };
        case GET_PROJECT_LIST_REQUEST:
            return {
                ...state,
                progressList: true,
                pageData: payload,
            };
        case GET_PROJECT_LIST_SUCCESS:
            const {
                projectModels,
                totalCount,
                fieldName = "projectsList",
            } = payload;

            return {
                ...state,
                progressList: false,
                [fieldName]: [...projectModels],
                totalCount: fieldName === "projectsList" ? totalCount : state.totalCount,
            };
        case GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS:
            return {
                ...state,
                progressList: false,
                projectsForObjectEdit: payload,
            };
        case GET_PROJECT_LIST_ERROR:
        case GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case UPDATE_FIELDS_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_USER_ALL_ERROR:
            return {
                ...state,
                getUsersAllProgress: false,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getProjectsList(payload) {
    return {
        type: GET_PROJECT_LIST_REQUEST,
        payload,
    };
}

export function getProjectsListForObject(payload) {
    return {
        type: GET_PROJECTS_LIST_FOR_OBJECT_REQUEST,
        payload,
    };
}

export function updateFieldsStoreProject(payload) {
    return {
        type: UPDATE_FIELDS_STORE,
        payload,
    };
}

export function getObjectAndProjectMembers(payload) {
    return {
        type: GET_USER_ALL_REQUEST,
        payload,
    };
}

export function getUserTeamByClientUserId(payload) {
    return {
        type: GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST,
        payload,
    };
}

export function getOrderTemplateProjectsList(payload) {
    return {
        type: GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const projectsSelector = state => state.projects;
export const projectProgressSelector = createSelector(projectsSelector, ({progress}) => progress);
export const projectGetUsersAllProgressSelector = createSelector(projectsSelector, ({getUsersAllProgress}) => getUsersAllProgress);
export const getProjectsTotalPagesSelector = createSelector(projectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getProjectsListSelector = createSelector(projectsSelector, ({projectsList}) => projectsList);
export const projectPageDataSelector = createSelector(projectsSelector, ({pageData}) => pageData);
export const getProjectsOptionsSelector = createSelector(projectsSelector, ({projectsList}) => {
    return projectsList.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});
export const getProjectsForOrdersOptionsSelector = createSelector(projectsSelector, ({projectsForOrders}) => {
    return projectsForOrders.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});
export const getProjectsForTasksOptionsSelector = createSelector(projectsSelector, ({projectsForTasks}) => {
    return projectsForTasks.map((item) => {
        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
        };
    });
});

export const getProjectsOptionsForObjectEditSelector = createSelector(projectsSelector, ({projectsForObjectEdit}) => {
    return projectsForObjectEdit.map((item) => {
        const {
            name, // Чтобы не перезаписать name из option в дропдауне
            ...data
        } = item;

        return {
            key: item.projectId,
            value: item.projectId,
            text: item.name,
            ...data,
        };
    });
});

export const orderManagerOptionsWithObjectSelector = createSelector(state => state, (state) => {
    const {projects: {objectAndProjectMembers: _objectAndProjectMembers}} = state;

    const _adminMembers = orderAdminMembersSelector(state);

    const adminMembers = [..._adminMembers];
    const objectAndProjectMembers = [..._objectAndProjectMembers];

    const joined = objectAndProjectMembers.concat(adminMembers);

    const options = joined.map(item => ({
        key: item.clientUserId,
        value: item.clientUserId,
        text: getFormattedFullName(getFullName(item.lastName, item.firstName, item.patronymic)),
    }));

    const ids = joined.map(value => value.clientUserId);
    const filteredIds = ids.filter((value, index) => ids.indexOf(value) === index);

    return filteredIds.map(key => {
        const optionValue = options.find(option => option.key === key) || {};

        return {
            ...optionValue,
        };
    });
});

export const managersObjectSelectedSelector = createSelector(state => state, (state) => {
    const {projects: {objectAndProjectMembers}} = state;

    const adminMembers = orderAdminMembersSelector(state);

    return [...objectAndProjectMembers, ...adminMembers];
});

export const getProjectsTotalCountSelector = createSelector(projectsSelector, ({totalCount}) => totalCount);
export const projectListForObjectEditSelector = createSelector(projectsSelector, ({projectsForObjectEdit}) => projectsForObjectEdit);
export const projectProgressListSelector = createSelector(projectsSelector, ({progressList}) => progressList);
export const projectStatusSelector = createSelector(projectsSelector, ({project: {status}}) => status);
export const managerListByUserSelector = createSelector(projectsSelector, ({managerListByUser}) => managerListByUser);
export const managerListByUserOptionsSelector = createSelector(projectsSelector, ({managerListByUser}) =>
    managerListByUser.map(manager => ({
        key: manager.clientUserId,
        value: manager.clientUserId,
        text: getFormattedFullName(getFullName(manager.lastName, manager.firstName, manager.patronymic)),
    })).sort(compareManagers),
);

//*  SAGA  *//

//POST /bff/adm/common/order/get-project-page
//POST /bff/client-adm/common/order/get-project-page
export const getProjectsListSaga = function* ({payload}) {
    try {
        const url = getBffControllerClientCardPage({
            admin: "/adm/common/order",
            client: "/client-adm/common/order",
        });

        const {fieldName, ...reqData} = payload;
        const result = yield request.bff.post(`${url}/get-project-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PROJECT_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PROJECT_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({type: GET_PROJECT_LIST_ERROR, payload: error});
    }
};

//POST /api/project/getProjectOrderTemplateModels
export const getOrderTemplateProjectsListSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getProjectOrderTemplateModels`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_TEMPLATE_PROJECTS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_ORDER_TEMPLATE_PROJECTS_LIST_ERROR, payload: error});
    }
};

export const deleteAllObjectUsersSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/deleteAllProjectObjectUser`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_ALL_OBJECT_USERS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Все сотрудники успешно удалены");

        const state = yield select();

        yield put(getObjectUserList(state.bff.clientObjectCard.objectUsersPageData));

        yield put({type: DELETE_ALL_OBJECT_USERS_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: DELETE_ALL_OBJECT_USERS_ERROR, payload: error});
    }
};

///api/project/getUserAll

// Получаем сотрудников, которые есть на объекте и проекте

export const getObjectAndProjectMembersSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getUserAll`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_USER_ALL_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_USER_ALL_ERROR, payload: error});
    }
};

export const getUserTeamByUserIdSaga = function* ({payload}) {
    try {
        const {
            clientId,
            clientUserId,
            roleFilters,
        } = payload;

        const result = yield request.get(
            `${controller}/getUserTeamByClientUserId?clientId=${clientId}&clientUserId=${clientUserId}&roleFilters=${roleFilters}`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_USER_ALL_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_USER_TEAM_BY_CLIENT_USER_ID_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: GET_USER_TEAM_BY_CLIENT_USER_ID_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_PROJECT_LIST_REQUEST, getProjectsListSaga),
        takeEvery(DELETE_ALL_OBJECT_USERS_REQUEST, deleteAllObjectUsersSaga),
        takeEvery(GET_USER_ALL_REQUEST, getObjectAndProjectMembersSaga),
        takeEvery(GET_USER_TEAM_BY_CLIENT_USER_ID_REQUEST, getUserTeamByUserIdSaga),
        takeEvery(GET_PROJECTS_LIST_FOR_OBJECT_REQUEST, getProjectsListSaga),
        takeEvery(GET_ORDER_TEMPLATE_PROJECTS_LIST_REQUEST, getOrderTemplateProjectsListSaga),
    ]);
}