import {ReactNode} from "react";
import {DropEvent, FileRejection} from "react-dropzone";

import {StatementParticipantType} from "./mapItemListToForm";

import {isAccessByRestrictions} from "../../../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";

import {EDO_ACCESS_RIGHTS} from "../../../../../../constants/document-management/document-list";
import {
    EDM_STATEMENT_PARTICIPANTS_TYPE,
    EDM_STATEMENT_STATUS,
} from "../../../../../../constants/document-management/statements";

export type StatementItem = {
    state: string,
    role: string,
    archived: boolean,
    attachmentFileName?: string,
    participants: StatementParticipantType[]
};

export type OptionType = {
    key: any,
    value: any,
    text: string | ReactNode,
    customContent?: string | ReactNode,
    templateType?: string,
    href?: string,
    useExtLink?: boolean,
    icon?: ReactNode,
    visible?: boolean,
    link?: string,
    onClick?: () => void,
    onDropFiles?: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
    asc?: string,
    desc?: string,
    sortType?: string | null,
    isDefaultSort?: boolean,
    disabled?: boolean,
    tooltipText?: string,
    options?: Array<OptionType> | [],
    className?: string,
}

export const
    STATEMENT_ACTION = {
        EDIT: {
            VALUE: "EDIT",
            TEXT: "Редактировать",
        },
        TO_APPROVE: {
            VALUE: "TO_APPROVE",
            TEXT: "Одобрить",
        },
        REJECT: {
            VALUE: "REJECT",
            TEXT: "Отклонить",
        },
        IN_ARCHIVE: {
            VALUE: "IN_ARCHIVE",
            TEXT: "В архив",
        },
        FROM_ARCHIVE: {
            VALUE: "FROM_ARCHIVE",
            TEXT: "Из архива",
        },
        ATTACHMENT_FILE: {
            VALUE: "ATTACHMENT_FILE",
            TEXT: "Скачать доп. файл",
        },
        SOURCE_FILE: {
            VALUE: "SOURCE_FILE",
            TEXT: "Скачать исходный файл",
        },
        SIGNED_FILE: {
            VALUE: "SIGNED_FILE",
            TEXT: "Скачать подписанный файл",
        },
    };

export const ACTION_OPTION = {
    [STATEMENT_ACTION.EDIT.VALUE]: {
        key: STATEMENT_ACTION.EDIT.VALUE,
        value: STATEMENT_ACTION.EDIT.VALUE,
        text: STATEMENT_ACTION.EDIT.TEXT,
    },
    [STATEMENT_ACTION.IN_ARCHIVE.VALUE]: {
        key: STATEMENT_ACTION.IN_ARCHIVE.VALUE,
        value: STATEMENT_ACTION.IN_ARCHIVE.VALUE,
        text: STATEMENT_ACTION.IN_ARCHIVE.TEXT,
    },
    [STATEMENT_ACTION.FROM_ARCHIVE.VALUE]: {
        key: STATEMENT_ACTION.FROM_ARCHIVE.VALUE,
        value: STATEMENT_ACTION.FROM_ARCHIVE.VALUE,
        text: STATEMENT_ACTION.FROM_ARCHIVE.TEXT,
    },
    [STATEMENT_ACTION.REJECT.VALUE]: {
        key: STATEMENT_ACTION.REJECT.VALUE,
        value: STATEMENT_ACTION.REJECT.VALUE,
        text: STATEMENT_ACTION.REJECT.TEXT,
    },
    [STATEMENT_ACTION.TO_APPROVE.VALUE]: {
        key: STATEMENT_ACTION.TO_APPROVE.VALUE,
        value: STATEMENT_ACTION.TO_APPROVE.VALUE,
        text: STATEMENT_ACTION.TO_APPROVE.TEXT,
    },
    [STATEMENT_ACTION.ATTACHMENT_FILE.VALUE]: {
        key: STATEMENT_ACTION.ATTACHMENT_FILE.VALUE,
        value: STATEMENT_ACTION.ATTACHMENT_FILE.VALUE,
        text: STATEMENT_ACTION.ATTACHMENT_FILE.TEXT,
    },
};

export function getOptions(statementItem: StatementItem, edoAccessList: string[], currentClientUserId: string): OptionType[] {
    const {
        state,
        archived,
        attachmentFileName,
        participants,
    } = statementItem;

    const isAccessArchiveActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_archiveEdoStatement,
    ]);

    let options: OptionType[] = attachmentFileName ? [
        ACTION_OPTION[STATEMENT_ACTION.ATTACHMENT_FILE.VALUE],
    ] : [];

    if (archived) {
        return (
            edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_RESTORE_ARCHIVE)
            && isAccessArchiveActions
        ) ? [
                ...options,
                ACTION_OPTION[STATEMENT_ACTION.FROM_ARCHIVE.VALUE],
            ] : [...options];
    }

    if (
        edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_ARCHIVE)
        && [EDM_STATEMENT_STATUS.SIGNED.VALUE, EDM_STATEMENT_STATUS.REJECTED.VALUE].includes(state)
        && isAccessArchiveActions
    ) {
        options = [
            ...options,
            ACTION_OPTION[STATEMENT_ACTION.IN_ARCHIVE.VALUE],
        ];
    }

    if (edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_RECIPIENT_APPROVER_INSTALLATION) && [EDM_STATEMENT_STATUS.ON_REVIEW.VALUE].includes(state)) {
        options = [
            ACTION_OPTION[STATEMENT_ACTION.TO_APPROVE.VALUE],
            ACTION_OPTION[STATEMENT_ACTION.REJECT.VALUE],
            ...options,
        ];
    }

    const isSender = Boolean((participants || []).find(item => item.type === EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.VALUE && currentClientUserId === item.clientUserId));

    if (isSender &&
        edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_STATEMENTS_EDIT) &&
        [
            EDM_STATEMENT_STATUS.ON_REVIEW.VALUE,
            EDM_STATEMENT_STATUS.ON_SIGNING.VALUE,
            EDM_STATEMENT_STATUS.REJECTED.VALUE,
        ].includes(state)) {
        options = [
            ACTION_OPTION[STATEMENT_ACTION.EDIT.VALUE],
            ...options,
        ];
    }

    return options;
}