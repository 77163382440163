import React from "react";
import {Switch} from "react-router-dom";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import AdvertisementContractorsOnOrders from "./components/contractors-on-orders";
import AdvertisementCard from "./index";

import {
    LINK_CLIENT_CARD_ADVERTISEMENT,
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL,
} from "../../../constants/links";

const AdvertisementRoute = () => {
    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL}
                render={(props) => {
                    return (
                        <AdvertisementCard
                            {...props}
                            isVisibleFiltersButton={false}
                        >
                            <AdvertisementContractorsOnOrders {...props} />
                        </AdvertisementCard>
                    );
                }}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_CARD_ADVERTISEMENT}
                component={AdvertisementCard}
            />
        </Switch>
    );
};

export default AdvertisementRoute;