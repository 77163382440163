import {isEmpty} from "lodash";

import {CLIENT_WORK_TYPE} from "../../../../../constants/clientSettings";
import {CLIENT_TYPE_CODE} from "../../../../../constants/dicts";

function getNewClientInitFormValues(currentApplication) {
    const {
        name,
        fullName,
        ...otherApplicationData
    } = currentApplication;

    return {
        clientType: CLIENT_TYPE_CODE.RUSSIAN_LEGAL_ENTITY,
        inn: "",
        fullName: currentApplication?.clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ? name : fullName || "",
        name: name || "",
        insuranceAvailable: false,
        registryPaymentsAvailable: false,
        registeredAddress: "",
        civilRegistryPaymentsAvailable: false,
        representativeName: "",
        representativePhone: "",
        brand: "",
        representativeEmail: "",
        currentCommissionRate: "2",
        civilPaymentCommission: "2",
        contractorCivilOrderPaymentsCommission: "4",
        contractorCivilRegistryPaymentsCommission: "4",
        contractorSmzOrderPaymentsCommission: "4",
        contractorSmzRegistryPaymentsCommission: "4",
        minCommissionAmount: "40",
        minimalCommissionNdflPaymentsAmount: "100",
        paymentsThresholdAmount: "3000",
        ordersLimit: "0",
        civilOrdersLimit: "0",
        addressSearchError: "",
        longitudeFilter: "",
        latitudeFilter: "",
        categoryId: "",
        edmAvailable: false,
        withoutContract: false,
        foremanFunctionalityAvailable: false,
        isShowForemanFunctionalityAvailable: false,
        fileObj: {},
        fileObjThumbnail: {},
        migrantLicensePaymentEnabled: false,
        migrantLicensePaymentCommission: "",
        edmTariff: "100 руб. за документ",
        clientWorkType: getClientWorkType(currentApplication),
        clientWorkTypeTariff: currentApplication.clientWorkTypeTariff || "",
        enableIndividualProjectAndObjectDeposit: false,
        enableNonDistributedClientDeposit: false,
        ...otherApplicationData,
    };
}

const getClientWorkType = (currentApplication) => {
    if (!isEmpty(currentApplication)) {
        return currentApplication.clientWorkType || "";
    }

    return CLIENT_WORK_TYPE.ONLY_NAIMIX;
};

export default getNewClientInitFormValues;