import {
    BFF_ORDERS_ADD_ORDER_GROUP_REQUEST,
    BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST,
    BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST,
} from "./actions";

export function getOrderGroupsPage(payload) {
    return {
        type: BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST,
        payload,
    };
}

export function addOrderGroup(payload) {
    return {
        type: BFF_ORDERS_ADD_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function deleteOrderGroup(payload) {
    return {
        type: BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function updateOrderGroup(payload) {
    return {
        type: BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST,
        payload,
    };
}

export function archiveOrRestoreOrderGroup(payload) {
    return {
        type: BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST,
        payload,
    };
}