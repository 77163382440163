import * as yup from "yup";

import {formatAmountWithComma, getNumberFromFormattedAmount} from "../../../utils/stringFormat";

import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {CHANGE_DEPOSIT_RADIO_TYPE} from "../constants";

export const getChangeDepositValidation = (params) => {
    const {
        maxValueNpd,
        maxValueNdfl,
        minValue = 0.01,
    } = params;

    const checkMinValue = function (value) {
        return getNumberFromFormattedAmount(value) >= minValue;
    };
    const getMessage = (value) => {
        return `Максимальное значение ограничено значением из настроек компании - ${formatAmountWithComma(value)}`;
    };
    const checkLimit = (value, limit) => {
        if (!value || !limit) {
            return true;
        }

        const result = getNumberFromFormattedAmount(value);

        return result <= limit;
    };

    return yup.object().shape({
        amount: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test(
                "check-min-value",
                `Минимальное значение ${formatAmountWithComma(minValue)}`,
                checkMinValue,
            )
            .when("type", {
                is: CHANGE_DEPOSIT_RADIO_TYPE.NPD,
                then: yup.string()
                    .test("maxNpdMessage", getMessage(maxValueNpd), function(value) {
                        return checkLimit(value, maxValueNpd);
                    }),
            })
            .when("type", {
                is: CHANGE_DEPOSIT_RADIO_TYPE.NDFL,
                then: yup.string()
                    .test("maxNdflMessage", getMessage("maxValueNdfl"), function(value) {
                        return checkLimit(value, maxValueNdfl);
                    }),
            }),
    });
};