import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const actRegistriesSelector = state => state.bff.orderActRegistriesCard;
export const actRegistriesCardSelector = createSelector(
    actRegistriesSelector,
    ({card}) => card,
);
export const actRegistriesProgressCardSelector = createSelector(
    actRegistriesSelector,
    ({progressCard}) => progressCard,
);
export const actRegistryPaymentListSelector = createSelector(
    actRegistriesSelector,
    ({list}) => list,
);
export const actRegistryPaymentTotalPagesSelector = createSelector(
    actRegistriesSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const actRegistryPaymentTotalCountSelector = createSelector(
    actRegistriesSelector,
    ({totalCount}) => totalCount,
);
export const actRegistryPaymentCardSelector = createSelector(
    actRegistriesSelector,
    ({paymentCard}) => paymentCard,
);
export const actRegistryPaymentProgressSelector = createSelector(
    actRegistriesSelector,
    ({paymentProgress}) => paymentProgress,
);
export const actRegistryPaymentProgressActionSelector = createSelector(
    actRegistriesSelector,
    ({paymentProgressAction}) => paymentProgressAction,
);