import React from "react";
import {useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import Tabs from "../../../../components/ActualComponents/Tabs";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";
import ContractorWalletWithdrawAvailableLog from "./components/log-list";
import SettingsDirectoriesBanksRoute from "./route";

import {useSettingsDirectoriesBanksAction} from "./hooks/useAction";
import {useSettingsDirectoriesBanksFetch} from "./hooks/useFetch";

import {ls, USER_ROLE} from "../../../../utils/localstorage";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES,
    LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
    LINK_SETTINGS_DIRECTORIES_BANKS_SBP,
} from "../../../../constants/links";
import {
    ADMIN,
    NM_CHIEF_ACCOUNTANT,
} from "../../../../constants/roles";

import {
    contractorWalletWithdrawAvailableSelector,
    settingsDirectoriesBanksBalancesAccessPageSelector,
    settingsDirectoriesBanksProgressActionSelector,
} from "../../../../ducks/bff/settings/directories/naimix/banks/selectors";

const SettingsDirectoriesBanks = () => {
    const contractorWalletWithdrawAvailable = useSelector(contractorWalletWithdrawAvailableSelector);
    const progressAction = useSelector(settingsDirectoriesBanksProgressActionSelector);
    const accessBalancesPage = useSelector(settingsDirectoriesBanksBalancesAccessPageSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN],
        restriction: CLIENT_USER_RESTRICTIONS_VARIABLE.updateSettingDirectoryContractorWalletWithdrawAvailable,
    });

    const role = ls(USER_ROLE);

    const {fetchSettings} = useSettingsDirectoriesBanksFetch();

    const {
        setSettings,
        isOpenLogModal,
        setOpenLogModal,
    } = useSettingsDirectoriesBanksAction({
        fetchSettings,
    });

    const renderContractorWalletWithdrawAvailableLog = () => {
        return (
            isOpenLogModal &&
            <ContractorWalletWithdrawAvailableLog
                onClose={() => setOpenLogModal(false)}
            />
        );
    };

    const getTabs = () => {
        return ([
            {
                name: "Банки",
                link: LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
                active: LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS,
            },
            {
                name: "Банки для платежей по СБП",
                link: LINK_SETTINGS_DIRECTORIES_BANKS_SBP,
                active: LINK_SETTINGS_DIRECTORIES_BANKS_SBP,
            },
            {
                name: "Баланс на р/с",
                link: LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES,
                active: LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES,
                isVisible: [ADMIN, NM_CHIEF_ACCOUNTANT].includes(role) && accessBalancesPage,
            },
        ]);
    };

    return (
        <NmPage
            overflowUnset={true}
            header={
                <NmPageHeader
                    text="Банки"
                />
            }
            subHeader={
                isAccessEdit &&
                <NmCheckboxV2
                    label="Разрешить исполнителям вывод денежных средств с кошельков"
                    checked={contractorWalletWithdrawAvailable}
                    disabled={progressAction}
                    onChange={() => setSettings(!contractorWalletWithdrawAvailable)}
                    toggle
                    isVisibleTooltip
                    tooltip={
                        <HelpTooltip
                            info
                            width={18}
                            height={18}
                            onClick={() => setOpenLogModal(true)}
                        />
                    }
                />
            }
            noPadding={true}
        >
            {renderContractorWalletWithdrawAvailableLog()}
            <Tabs
                className="mb-4 mb-xxl-5"
                panes={getTabs()}
            />
            <SettingsDirectoriesBanksRoute />
        </NmPage>
    );
};

export default SettingsDirectoriesBanks;