export const REGISTRY_PAYMENT_LIST_REQUEST = "REGISTRY_PAYMENT_LIST_REQUEST";
export const REGISTRY_PAYMENT_LIST_SUCCESS = "REGISTRY_PAYMENT_LIST_SUCCESS";
export const REGISTRY_PAYMENT_LIST_ERROR = "REGISTRY_PAYMENT_LIST_ERROR";

export const REGISTRY_PAYMENT_UPDATE_STORE = "REGISTRY_PAYMENT_UPDATE_STORE";

export const REGISTRY_PAYMENT_DELETE_REQUEST = "REGISTRY_PAYMENT_DELETE_REQUEST";
export const REGISTRY_PAYMENT_DELETE_SUCCESS = "REGISTRY_PAYMENT_DELETE_SUCCESS";
export const REGISTRY_PAYMENT_DELETE_ERROR = "REGISTRY_PAYMENT_DELETE_ERROR";

export const REGISTRY_PAYMENT_ROW_PAY_REQUEST = "REGISTRY_PAYMENT_ROW_PAY_REQUEST";
export const REGISTRY_PAYMENT_ROW_PAY_SUCCESS = "REGISTRY_PAYMENT_ROW_PAY_SUCCESS";
export const REGISTRY_PAYMENT_ROW_PAY_ERROR = "REGISTRY_PAYMENT_ROW_PAY_ERROR";

export const REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST";
export const REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS";
export const REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR = "REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR";

export const REGISTRY_PAYMENT_EXPORT_REQUEST = "REGISTRY_PAYMENT_EXPORT_REQUEST";
export const REGISTRY_PAYMENT_EXPORT_SUCCESS = "REGISTRY_PAYMENT_EXPORT_SUCCESS";
export const REGISTRY_PAYMENT_EXPORT_ERROR = "REGISTRY_PAYMENT_EXPORT_ERROR";

export const REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST";
export const REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS";
export const REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR";

export const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST";
export const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS";
export const REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR";

export const REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST";
export const REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS";
export const REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR = "REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR";

export const CANCEL_REGISTRY_PAYMENT_REQUEST = "CANCEL_REGISTRY_PAYMENT_REQUEST";
export const CANCEL_REGISTRY_PAYMENT_SUCCESS = "CANCEL_REGISTRY_PAYMENT_SUCCESS";
export const CANCEL_REGISTRY_PAYMENT_ERROR = "CANCEL_REGISTRY_PAYMENT_ERROR";

export const REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST = "REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST";
export const REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS = "REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS";
export const REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR = "REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR";

export const REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST";
export const REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS";
export const REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR = "REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR";

export const GET_REGISTRY_REQUEST = "GET_REGISTRY_REQUEST";
export const GET_REGISTRY_SUCCESS = "GET_REGISTRY_SUCCESS";
export const GET_REGISTRY_ERROR = "GET_REGISTRY_ERROR";

export const REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST = "REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST";
export const REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS = "REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS";
export const REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_ERROR = "REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_ERROR";

export const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST";
export const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS";
export const ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR = "ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR";

export const CLEAR_REGISTRY_PAYMENT_LOG = "CLEAR_REGISTRY_PAYMENT_LOG";