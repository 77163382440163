import React, {useState} from "react";
import {useDispatch} from "react-redux";

import NmForm from "../ActualComponents/NmForm";
import NmModal from "../ActualComponents/NmModal";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";

import validate from "../../utils/validate";

import {REJECTION_SIGN_DOCUMENT_RULE} from "../../constants/validationRules";

import {rejectEdoDocument} from "../../ducks/bff/clients/edo/documents/actionCreators";

const RejectionSigningDocumentModal = (props) => {
    const {
        onClose = () => {
        },
        documentIds,
        fetchList,
    } = props;

    const [form, setForm] = useState({
        rejectionReason: "",
    });

    const [errorForm, setErrorForm] = useState({});

    const dispatch = useDispatch();

    const {rejectionReason} = form;

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const submit = () => {
        if (!validateForm()) {
            return;
        }

        const payload = {
            rejectionReason,
            documentIds,
            getSuccess: () => {
                fetchList();
                handleClose();
            },
        };

        dispatch(rejectEdoDocument(payload));
    };

    const validateForm = () => {
        const _errorForm = validate(form, REJECTION_SIGN_DOCUMENT_RULE, "");
        setErrorForm({..._errorForm});

        return Object.values(_errorForm).length === 0;
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <NmModal
            size="md"
            onClose={handleClose}
            header={
                <NmTitle size="lg">
                    Отклонение подписания документа
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    onClose={handleClose}
                    submitBtnContent="Подтвердить"
                    cancelBtnContent="Отменить"
                    submit={submit}
                />
            }
        >
            <NmForm>
                <NmTextareaV2
                    error={errorForm.rejectionReason}
                    placeholder="Введите причину отклонения"
                    minRows={2}
                    maxRows={6}
                    maxLength="255"
                    name="rejectionReason"
                    onChange={onChange}
                    value={rejectionReason}
                />
            </NmForm>
        </NmModal>
    );
};

export default RejectionSigningDocumentModal;