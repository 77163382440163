import React from "react";
import {useDispatch} from "react-redux";

import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../components/ActualComponents/NmModal";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmTitle from "../../../components/NmTitle";
import RecruitmentResponseSource from "../../../components/Recruitment/ResponseSource";

import {getFullName} from "../../../utils/stringFormat";

import {COLOR} from "../../../constants/color";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CONTRACTOR_PERSONAL_INFO, LINK_CONTRACTOR_PROFILE,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {TASK_TYPE} from "../../../constants/task";
import {MAP_INVITE_MATCHING_TYPE} from "../../map/components/invite-modal/constants";

import {downloadDocument} from "../../../ducks/documents";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../constants/link-params";

const DETAILS_FIELD = {
    clientName: {
        text: "Название компании",
        value: "clientName",
    },
    groupName: {
        text: "Название группы компаний",
        value: "groupName",
    },
    contractorName: {
        text: "ФИО исполнителя",
        value: "contractorName",
    },
    orderNum: {
        text: "Номер заказа",
        value: "orderNum",
    },
    paymentNumber: {
        text: "Номер платежа",
        value: "paymentNumber",
    },
    errorMessage: {
        text: "Ошибка",
        value: "errorMessage",
    },
    errorMessageRow: {
        text: "Ошибка",
        value: "errorMessageRow",
    },
    orderName: {
        text: "Наименование заказа",
        value: "orderName",
    },
    crowdTaskNumber: {
        text: "Номер задания",
        value: "crowdTaskNumber",
    },
    crowdTaskName: {
        text: "Наименование задания",
        value: "crowdTaskName",
    },
    invitationInfo: {
        text: "Детализация",
        value: "invitationInfo",
    },
    rowNum: {
        text: "Номер строки файла",
        value: "rowNum",
    },
    rowNumber: {
        text: "Номер строки файла",
        value: "rowNumber",
    },
    registryName: {
        text: "Реестр по оплате патентов",
        value: "registryName",
    },
    itemNum: {
        text: "Номер строки",
        value: "itemNum",
    },
    advertName: {
        text: "Наименование объявления",
        value: "advertName",
    },
    managerFio: {
        text: "Ответственный",
        value: "managerFio",
    },
    seqNum: {
        text: "Номер строки файла",
        value: "seqNum",
    },
    errors: {
        text: "Ошибки",
        value: "errors",
    },
};

const SYSTEM_ERROR = {
    ORDER_MASS_ACTION: "Ошибка при выполнении операции с заказом",
};

export default function TaskDetailsModal(props) {
    const {
        close,
        details,
        details: {
            infoList = [],
            type,
            detailsInfo,
            additionalInfo = {},
        },
    } = props;

    const dispatch = useDispatch();

    const [firstItem] = infoList;

    function renderClientName() {
        if ([
            TASK_TYPE.DISCARD_REGISTRY_PAYMENT_ERRORS.VALUE,
            TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE,
            TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE,
            TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE,
            TASK_TYPE.IMPORT_OBJECTS_PROJECTS_V2.VALUE,
            TASK_TYPE.TICKET_MULTIPLE_CHANGE_MANAGER.VALUE,
            TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE,
            TASK_TYPE.ORDER_CONTRACTORS_APPROVE_ALL.VALUE,
            TASK_TYPE.ORDER_CONTRACTORS_DECLINE_ALL.VALUE,
            TASK_TYPE.ORDER_CONTRACTORS_REJECT_ALL.VALUE,
        ].includes(type)) {
            return null;
        }

        const clientName = [
            TASK_TYPE.IMPORT_STAFF.VALUE,
            TASK_TYPE.DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE.VALUE,
        ].includes(type)
            ? detailsInfo[DETAILS_FIELD.clientName.value]
            : firstItem[DETAILS_FIELD.clientName.value];
        const clientNameText = `${DETAILS_FIELD.clientName.text}: ${clientName}`;

        return (
            <Text.Sub
                level="1"
                medium
                color={COLOR.SECONDARY_100}
                className="mb-2 mb-md-4"
            >
                {clientNameText}
            </Text.Sub>
        );
    }

    function renderContractorName() {
        const link = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", detailsInfo.contractorId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {detailsInfo.contractorFio}
            </ExtLink>
        );
    }

    function renderClientGroupName() {
        if (![TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE, TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE].includes(type)) {
            return null;
        }

        return (
            <Text.Sub
                level="1"
                medium
                color={COLOR.SECONDARY_100}
                className="mb-2 mb-md-4"
            >
                {DETAILS_FIELD.groupName.text}
                :
                {detailsInfo[DETAILS_FIELD.groupName.value]}
            </Text.Sub>
        );
    }

    function renderRegistryName() {
        if (![TASK_TYPE.DISCARD_REGISTRY_PAYMENT_ERRORS.VALUE].includes(type)) {
            return null;
        }

        const link = LINK_CLIENT_REGISTRY_PAYMENTS_CARD.replace(":clientId", detailsInfo.clientId)
            .replace(":paymentNumberFilter?/", "")
            .replace(":archived", "false")
            .replace(":registryId", detailsInfo.registryId);

        return (
            <Text.Sub
                level="1"
                medium
                color={COLOR.SECONDARY_100}
                className="mb-2 mb-md-4"
            >
                Реестр:
                {" "}
                {
                    <ExtLink
                        to={link}
                        historyEnabled
                    >
                        {detailsInfo[DETAILS_FIELD.registryName.value]}
                    </ExtLink>
                }
            </Text.Sub>
        );
    }

    function renderInviteInfo() {
        if (![TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE].includes(type)) {
            return null;
        }

        if (additionalInfo.matchingType === MAP_INVITE_MATCHING_TYPE.ORDER_FOR_CONTRACTORS) {
            const orderLink = LINK_ORDER_CARD
                .replace(":clientId", additionalInfo.orderId.clientId)
                .replace(":orderId", additionalInfo.orderId.orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

            return (
                <Text.Sub
                    level="1"
                    medium
                    color={COLOR.SECONDARY_100}
                    className="mb-2 mb-md-4"
                >
                    Номер и название заказа:
                    {" "}
                    {
                        <ExtLink
                            to={orderLink}
                            historyEnabled
                        >
                            {`${additionalInfo.orderNum} - ${additionalInfo.orderName}`}
                        </ExtLink>
                    }
                </Text.Sub>
            );
        }

        if (additionalInfo.matchingType === MAP_INVITE_MATCHING_TYPE.CROWD_TASK_FOR_CONTRACTORS) {
            const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                .replace(":clientId", additionalInfo.crowdTaskId.clientId)
                .replace(":taskId", additionalInfo.crowdTaskId.taskId)
                .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED);

            return (
                <Text.Sub
                    level="1"
                    medium
                    color={COLOR.SECONDARY_100}
                    className="mb-2 mb-md-4"
                >
                    Номер и название задания:
                    {" "}
                    {
                        <ExtLink
                            to={taskLink}
                            historyEnabled
                        >
                            {`${additionalInfo.crowdTaskNum} - ${additionalInfo.crowdTaskName}`}
                        </ExtLink>
                    }
                </Text.Sub>
            );
        }

        const contractorLink = LINK_CONTRACTOR_PROFILE.replace(":contractorId", additionalInfo.contractorId);

        return (
            <Text.Sub
                level="1"
                medium
                color={COLOR.SECONDARY_100}
                className="mb-2 mb-md-4"
            >
                ФИО исполнителя:
                {" "}
                {
                    <ExtLink
                        to={contractorLink}
                        historyEnabled
                    >
                        {
                            additionalInfo.lastName ?
                                getFullName(additionalInfo.lastName, additionalInfo.firstName, additionalInfo.patronymic) :
                                additionalInfo.fullName
                        }
                    </ExtLink>
                }
            </Text.Sub>
        );
    }

    function renderOrderInfo() {
        if (
            ![
                TASK_TYPE.ORDER_CONTRACTORS_APPROVE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_DECLINE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_REJECT_ALL.VALUE,
            ].includes(type)
        ) {
            return null;
        }

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", detailsInfo.clientId)
            .replace(":orderId", detailsInfo.orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <Text.Sub
                level="1"
                medium
                color={COLOR.SECONDARY_100}
                className="mb-2 mb-md-4"
            >
                Заказ:
                {" "}
                {
                    <ExtLink
                        to={orderLink}
                        historyEnabled
                    >
                        {`№${detailsInfo.orderNum} - ${detailsInfo.orderName}`}
                    </ExtLink>
                }
            </Text.Sub>
        );
    }

    function renderErrorMessage() {
        return (
            <NmListCard
                noDivider
                labels={[
                    {
                        label: DETAILS_FIELD.errorMessage.text,
                        text: detailsInfo.errorMessage || "-",
                    },
                ]}
            />
        );
    }

    function getLink({link, text}) {
        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {text}
            </ExtLink>
        );
    }

    function fieldsByTaskType(detail, type) {
        const error = detail[DETAILS_FIELD.errorMessage.value] || detail[DETAILS_FIELD.errorMessageRow.value] ? [{
            label: DETAILS_FIELD.errorMessage.text,
            text: detail[DETAILS_FIELD.errorMessage.value || SYSTEM_ERROR.ORDER_MASS_ACTION] || detail[DETAILS_FIELD.errorMessageRow.value],
            noWrap: false,
            inline: true,
        }] : [];

        if (
            [
                TASK_TYPE.RECRUITMENT_CHECK_JOB_BOARD_PUBLISH_STATUS.VALUE,
            ].includes(type)
        ) {
            return [
                {
                    label: "Джоб-борд",
                    alignItems: "center",
                    text: (
                        <RecruitmentResponseSource
                            isVisibleOnlyJobBoardName={true}
                            source={{
                                responseSource: detail.jobBoardType,
                            }}
                        />
                    ),
                },
                {
                    label: "Аккаунт",
                    noWrap: false,
                    inline: true,
                    text: `${detail.userName || ""} ${detail.accountEmail || ""}`,
                },
                ...error,
            ];
        }

        if (
            [
                TASK_TYPE.ORDERS_CLOSING.VALUE,
                TASK_TYPE.ORDERS_ARCHIVING.VALUE,
                TASK_TYPE.ORDERS_DRAFT_DELETING.VALUE,
                TASK_TYPE.ORDERS_DRAFT_POSTING.VALUE,
                TASK_TYPE.ORDERS_END_CONTRACTOR_SEARCH.VALUE,
                TASK_TYPE.ORDERS_PUBLISH_IN_WORK.VALUE,
            ].includes(type)
        ) {
            return [
                {
                    label: DETAILS_FIELD.orderNum.text,
                    text: detail[DETAILS_FIELD.orderNum.value] || "-",
                },
                {
                    label: DETAILS_FIELD.orderName.text,
                    text: detail[DETAILS_FIELD.orderName.value] || "-",
                },
                ...error,
            ];
        }

        if (
            [
                TASK_TYPE.CROWD_TASKS_MULTIPLE_DELETE_OPERATIONS_EXECUTION.VALUE,
                TASK_TYPE.CROWD_TASKS_MULTIPLE_PUBLISH_OPERATIONS_EXECUTION.VALUE,
                TASK_TYPE.CROWD_TASKS_MULTIPLE_CLOSE_OPERATIONS_EXECUTION.VALUE,
                TASK_TYPE.CROWD_TASKS_MULTIPLE_ADD_TO_ARCHIVE_OPERATIONS_EXECUTION.VALUE,
                TASK_TYPE.CROWD_TASKS_MULTIPLE_EXTEND_WORK_END_DATE_OPERATIONS_EXECUTION.VALUE,
            ].includes(type)
        ) {
            return [
                {
                    label: DETAILS_FIELD.crowdTaskNumber.text,
                    text: detail[DETAILS_FIELD.crowdTaskNumber.value] || "-",
                },
                {
                    label: DETAILS_FIELD.crowdTaskName.text,
                    text: detail[DETAILS_FIELD.crowdTaskName.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.RECRUITMENT_VACANCIES_IMPORT.VALUE].includes(type)) {
            return [
                {
                    label: "Название вакансии",
                    text: detail.vacancyName || "-",
                },
                ...error,
            ];
        }

        if (
            [
                TASK_TYPE.PUBLISH_VACANCY.VALUE,
                TASK_TYPE.DELETE_VACANCY.VALUE,
            ].includes(type)
        ) {
            return [
                {
                    label: "Номер вакансии",
                    text: detail.number || "-",
                },
                {
                    label: "Название вакансии",
                    text: detail.title || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.ORDER_PAYMENTS_PAYMENT.VALUE, TASK_TYPE.ORDER_PAYMENTS_CONFIRMATION.VALUE, TASK_TYPE.ORDER_REJECTED_PAYMENT.VALUE, TASK_TYPE.PAY_PAYMENT_API.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.contractorName.text,
                    text: detail[DETAILS_FIELD.contractorName.value] || "-",
                },
                {
                    label: DETAILS_FIELD.orderNum.text,
                    text: detail[DETAILS_FIELD.orderNum.value] || "-",
                },
                {
                    label: DETAILS_FIELD.paymentNumber.text,
                    text: detail[DETAILS_FIELD.paymentNumber.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_ORDERS.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.rowNum.text,
                    text: detail[DETAILS_FIELD.rowNum.value] || "-",
                },
                {
                    label: DETAILS_FIELD.orderNum.text,
                    text: detail[DETAILS_FIELD.orderNum.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.PATENT_REGISTRY_PAYMENTS_HANDLING.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.registryName.text,
                    text: detail[DETAILS_FIELD.registryName.value] || "-",
                },
                {
                    label: DETAILS_FIELD.itemNum.text,
                    text: detail[DETAILS_FIELD.itemNum.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_REGISTRY_PAYMENTS.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.contractorName.text,
                    text: detail[DETAILS_FIELD.contractorName.value] || "-",
                },
                {
                    label: DETAILS_FIELD.rowNum.text,
                    text: detail[DETAILS_FIELD.rowNum.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_STAFF.VALUE, TASK_TYPE.DEPOSIT_REPLENISHMENT_OBJECT_FROM_FILE.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.rowNumber.text,
                    text: detail[DETAILS_FIELD.rowNumber.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_ADVERT.VALUE, TASK_TYPE.CLOSE_ADVERT.VALUE, TASK_TYPE.PUBLISH_ADVERT.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.managerFio.text,
                    text: detail[DETAILS_FIELD.managerFio.value] || "Нет информации",
                },
                {
                    label: DETAILS_FIELD.advertName.text,
                    text: detail[DETAILS_FIELD.advertName.value] || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_STAFF.VALUE, TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE, TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.seqNum.text,
                    text: detail[DETAILS_FIELD.seqNum.value] || "Нет информации",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.IMPORT_OBJECTS_PROJECTS_V2.VALUE].includes(type)) {
            return [
                {
                    label: DETAILS_FIELD.rowNum.text,
                    text: detail[DETAILS_FIELD.rowNum.value] || "-",
                },
                {
                    label: DETAILS_FIELD.errors.text,
                    text: detail[DETAILS_FIELD.errors.value].join("; ") || "-",
                },
                ...error,
            ];
        }

        if ([TASK_TYPE.TICKET_MULTIPLE_CHANGE_MANAGER.VALUE].includes(type)) {
            return [
                {
                    label: "Номер тикета",
                    text: detail.orderNumber || "-",
                },
                ...error,
                !detail.errorMessage && {
                    label: "Статус",
                    text: "Успешный перевод обращения",
                },
            ];
        }

        if ([TASK_TYPE.CROWD_TASKS_IMPORT_INTO_TASK_GROUP.VALUE].includes(type)) {
            return [
                detail.number && {
                    label: "Номер задания",
                    text: detail.number,
                },
                {
                    label: "Наименование задания",
                    text: detail.name || "-",
                },
                {
                    label: "Ошибка",
                    text: detail.error || "-",
                },
            ];
        }

        if ([TASK_TYPE.SEND_CONTRACTOR_INVITES_FROM_MAP.VALUE].includes(type)) {
            return [
                !detail.errorCode && {
                    text: "Приглашение отправлено",
                    color: "primary",
                },
                detail.errorCode && {
                    text: "Приглашение не отправлено",
                    color: "red",
                },
                detail.errorMessage && {
                    label: "Причина ошибки",
                    text: detail.errorMessage,
                    noWrap: false,
                    inline: true,
                },
                (detail.lastName || detail.fullName) && {
                    label: "ФИО исполнителя",
                    text: getLink({
                        text: detail.lastName ?
                            getFullName(detail.lastName, detail.firstName, detail.patronymic) :
                            detail.fullName,
                        link: LINK_CONTRACTOR_PROFILE.replace(":contractorId", detail.contractorId),
                    }),
                },
                detail.crowdTaskNum && {
                    label: "Номер и название задания",
                    text: getLink({
                        text: `${detail.crowdTaskNum} - ${detail.crowdTaskName}`,
                        link: LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
                            .replace(":clientId", detail.crowdTaskId.clientId)
                            .replace(":taskId", detail.crowdTaskId.taskId)
                            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED),
                    }),
                },
                detail.orderNum && {
                    label: "Номер и название заказа",
                    text: getLink({
                        text: `${detail.orderNum} - ${detail.orderName}`,
                        link: LINK_ORDER_CARD
                            .replace(":clientId", detail.orderId.clientId)
                            .replace(":orderId", detail.orderId.orderId)
                            .replace(":page", SELF_CONTRACTOR)
                            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK),
                    }),
                },
            ];
        }

        if (
            [
                TASK_TYPE.ORDER_CONTRACTORS_APPROVE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_DECLINE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_REJECT_ALL.VALUE,
            ].includes(type)
        ) {
            return [
                {
                    label: "Исполнитель",
                    text: detail.lastName
                        ? getFullName(detail.lastName, detail.firstName, detail.patronymic)
                        : detail.fullName,
                },
                {
                    label: "Ошибка",
                    labelColor: COLOR.NEGATIVE_100,
                    text: detail.errorMessage || "-",
                },
            ];
        }

        return [
            {
                label: DETAILS_FIELD.contractorName.text,
                text: detail[DETAILS_FIELD.contractorName.value] || "-",
            },
            {
                label: DETAILS_FIELD.orderNum.text,
                text: detail[DETAILS_FIELD.orderNum.value] || "-",
            },
            ...error,
        ];
    }

    const getList = () => {
        if (
            [
                TASK_TYPE.IMPORT_STAFF.VALUE,
                TASK_TYPE.IMPORT_CONTRACTORS_GROUP.VALUE,
                TASK_TYPE.IMPORT_CLIENTS_GROUP.VALUE,
            ].includes(type)
        ) {
            return detailsInfo.importRows;
        }

        if (
            [
                TASK_TYPE.ORDER_CONTRACTORS_APPROVE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_DECLINE_ALL.VALUE,
                TASK_TYPE.ORDER_CONTRACTORS_REJECT_ALL.VALUE,
            ].includes(type)
        ) {
            return detailsInfo.contractors;
        }

        return infoList;
    };

    const getRows = () => {
        if ([TASK_TYPE.DISCARD_REGISTRY_PAYMENT_ERRORS.VALUE].includes(type)) {
            return (detailsInfo?.seqNums || []).map(item => {
                return {
                    contentRow: (
                        <NmListCard
                            noDivider
                            labels={[
                                {
                                    label: "Номер строки",
                                    text: item,
                                },
                            ]}
                        />
                    ),
                };
            });
        }

        const list = getList();

        return list.map(item => {
            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={fieldsByTaskType(item, type)}
                    />
                ),
            };
        });
    };

    function renderContent() {
        if (
            [
                TASK_TYPE.KEDO_STAFF_IMPORT.VALUE,
                TASK_TYPE.KEDO_DEPARTMENTS_IMPORT.VALUE,
                TASK_TYPE.PROJECT_OBJECT_CHANGE_ORDERS_LIMIT_FROM_FILE.VALUE,
            ].includes(type)
        ) {
            return (
                <>
                    <NmLabelText
                        className="mb-2"
                        color="blue"
                        cursorPointer={true}
                        textTitle="Скачать на устройство"
                        label="Исходный файл"
                        onClickText={() => {
                            dispatch(downloadDocument({
                                isDownload: true,
                                downloadLink: details.initialFileLink,
                            }));
                        }}
                        text="Загруженный файл.xlsx"
                    />
                    {
                        details.reportFileLink &&
                        <NmLabelText
                            color="blue"
                            cursorPointer={true}
                            textTitle="Скачать на устройство"
                            label="Отчет об ошибках"
                            onClickText={() => {
                                dispatch(downloadDocument({
                                    isDownload: true,
                                    downloadLink: details.reportFileLink,
                                }));
                            }}
                            text="Незагруженные строки.xlsx"
                        />
                    }
                </>
            );
        }

        if ([TASK_TYPE.CANCEL_CLIENT_CONTRACTOR_NOTIFICATION_TASK.VALUE].includes(type)) {
            return renderErrorMessage();
        }

        if ([TASK_TYPE.PRE_VALIDATION_CONTRACTOR_BANK_CHECK.VALUE].includes(type)) {
            return (
                <>
                    <Text.Sub
                        level="1"
                        medium
                        color={COLOR.SECONDARY_100}
                        className="mb-2 mb-md-4"
                    >
                        Отправка ПД исполнителя в банк Плутониум вручную
                    </Text.Sub>
                    {renderContractorName()}
                    {renderErrorMessage()}
                </>
            );
        }

        return (
            infoList.length > 0 ?
                <>
                    {renderClientName()}
                    {renderClientGroupName()}
                    {renderRegistryName()}
                    {renderInviteInfo()}
                    {renderOrderInfo()}
                    <CheckboxList
                        className="task-details-modal__list"
                        rows={getRows()}
                    />
                </> :
                <NmEmptyPageV2 title="Данные отсутствуют" />
        );
    }

    return (
        <NmModal
            size="md"
            onClose={close}
            header={
                <NmTitle size="lg">
                    Детали
                </NmTitle>
            }
            classNameContent="task-details-modal__content"
        >
            {renderContent()}
        </NmModal>
    );
}