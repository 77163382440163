import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";

import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmDadataInput from "../../../components/ActualComponents/NmDadataInput";
import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import PatentsPaymentStatus from "../../../components/ActualComponents/PatentsPaymentStatus";
import Text from "../../../components/ActualComponents/Text";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";
import validationPatentPayment from "./validations-rule";

import bem from "../../../utils/bem";
import {getFullName, getNumberFromFormattedAmount, phoneFormat} from "../../../utils/stringFormat";
import {convertStringToDate} from "../../../utils/stringHelper";

import {EDM_STAFF_PRIVILEGES, STAFF_STATUS} from "../../../constants/edmStaff";
import {
    PATENTS_PAYMENTS_REASON_DICT,
} from "../../../constants/patentsPayments";

import {getPageEdoStaff} from "../../../ducks/bff/clients/edo/documents/actionCreators";
import {edoReceiversByClientUserOptionsSelector} from "../../../ducks/bff/clients/edo/documents/selectors";
import {
    getContractorsPaymentAbilityPatent,
    getPatentRegistryById,
} from "../../../ducks/bff/clients/patents/pay/actionCreators";
import {addPatentsPayment, updatePatentsPayment} from "../../../ducks/bff/clients/patents/pay/card/actionCreators";
import {patentsRegistryPaymentAbility} from "../../../ducks/bff/clients/patents/pay/selectors";
import {
    contractorCardSelector,
    getContractorById,
} from "../../../ducks/contractor";

import "./style.sass";

const CreateEditPatentPayment = (props) => {
    const {
        onClose,
        onChangePrevState,
        clientId,
        patentRegistryId,
        fetchList,
        fetchRegistry = () => {},
        contractorData: {
            contractorId,
        },
        className,
        data: {
            address,
            period,
            paymentSum,
            recipientId,
            reason,
            recipientName,
            recipientInn,
            recipientKpp,
            recipientKbk,
            recipientOktmo,
            recipientBik,
            recipientRs,
            patentPaymentId,
            fiasId,
            updateDate,
            createDate,
        },
        isEdit,
    } = props;

    const dispatch = useDispatch();

    const contractor = useSelector(contractorCardSelector);
    const registryPaymentPossibility = useSelector(patentsRegistryPaymentAbility);
    const receiversOptions = useSelector(edoReceiversByClientUserOptionsSelector);

    const {
        status,
        paymentAbility,
        paymentAbilityReason,
    } = registryPaymentPossibility;

    useEffect(() => {
        if (contractorId) {
            dispatch(getContractorById(contractorId));
        }

        dispatch(getContractorsPaymentAbilityPatent({
            handleResponse: () => {},
            clientId,
            contractorId: contractorId,
        }));

        clientId && dispatch(getPageEdoStaff({
            field: "receivers",
            status: STAFF_STATUS.WORKING,
            clientId,
            pageNum: 1,
            pageSize: 1000,
            privileges: [EDM_STAFF_PRIVILEGES.RECEIVE_STATEMENT],
        }));
    }, []);

    const refreshRegistryInfo = () => {
        dispatch(getPatentRegistryById({
            clientId,
            patentRegistryId,
        }));
    };

    const {
        lastName,
        firstName,
        patronymic,
        fullName,
        inn,
        phone,
        locatedOutsideRussia,
    } = contractor;

    const [, element] = bem("create-edit-patent-payment", className);
    const onlyRecipient = receiversOptions.length === 1 ? receiversOptions[0].value : "";

    return (
        <Formik
            initialValues={{
                address: address || "",
                period: convertStringToDate(period, true) || null,
                paymentSum: paymentSum || "",
                reason: reason || PATENTS_PAYMENTS_REASON_DICT.ADVANCE_REQUEST,
                recipientId: recipientId || onlyRecipient,
                recipientName: recipientName || "",
                recipientInn: recipientInn || "",
                recipientKpp: recipientKpp || "",
                recipientKbk: recipientKbk || "",
                recipientOktmo: recipientOktmo || "",
                recipientBik: recipientBik || "",
                recipientRs: recipientRs || "",
                fiasId: fiasId || "",
            }}
            onSubmit={(values, actions) => {
                values.paymentSum = getNumberFromFormattedAmount(values.paymentSum);
                isEdit ? dispatch(updatePatentsPayment({
                    data: {
                        patentRegistryId,
                        contractorId,
                        patentPaymentId,
                        updateDate,
                        createDate,
                        clientId,
                        ...values,
                    },
                    meta: () => {
                        refreshRegistryInfo();
                        fetchRegistry();
                        fetchList();
                        onClose();
                    },
                })) :
                    dispatch(addPatentsPayment({
                        data: {
                            patentRegistryId,
                            contractorId,
                            clientId,
                            ...values,
                        },
                        meta: () => {
                            refreshRegistryInfo();
                            fetchRegistry();
                            fetchList();
                            onClose();
                        },
                    }));

                actions.setSubmitting(false);
            }}
            enableReinitialize
            validationSchema={validationPatentPayment()}
        >
            {(props) => {
                const {
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    values,
                    errors,
                    touched,
                } = props;

                const handleTypeLoading = (e, {name}) => {
                    setFieldValue("reason", name);
                };

                const handleChangeDropdown = (e, {name, value}) => {
                    setFieldTouched(name, true);
                    setFieldValue(name, value);
                };

                const handleChangeInput = (e, {name}) => {
                    setFieldTouched(name, true);

                    handleChange(e);
                };

                const renderHeader = () => {
                    return (
                        <NmTitle
                            className={element("header")}
                            size="lg"
                        >
                            {!isEdit ?
                                "Заполнение данных для оплаты патента" :
                                "Редактирование данных для оплаты патента"
                            }
                        </NmTitle>
                    );
                };

                const renderFooter = () => {
                    return (
                        <ApplyButtons
                            align="right"
                            mobile="column"
                            isHiddenCancelOnMobile
                            onClose={onClose}
                            formId="CreateEditPatentPayment"
                            cancelBtnContent="Отменить"
                            submitBtnContent={isEdit ? "Сохранить" : "Добавить"}
                            additionalBtnContent={!isEdit && "Назад"}
                            onClickAdditional={onChangePrevState}
                        />
                    );
                };

                return (
                    <NmModal
                        size="md"
                        onClose={onClose}
                        header={renderHeader()}
                        footer={renderFooter()}
                    >
                        <NmForm
                            noValidate
                            onSubmit={handleSubmit}
                            id="CreateEditPatentPayment"
                        >
                            <div className={element("contractor-info")}>
                                <Text.Sub
                                    level="2"
                                    medium
                                    className="mb-2 mb-md-0"
                                >
                                    {lastName ? getFullName(lastName, firstName, patronymic) : fullName}
                                </Text.Sub>
                                <PatentsPaymentStatus
                                    className="ms-0 ms-md-4"
                                    status={status}
                                    hideLoaderContent
                                    paymentPossible={paymentAbility}
                                    paymentAbilityReason={paymentAbilityReason}
                                />
                            </div>
                            <Text
                                level="3"
                                className="mb-3 mb-md-4"
                            >
                                {inn && `${inn},`}
                                {phoneFormat(phone, locatedOutsideRussia)}
                            </Text>
                            <Text.Title
                                level="4"
                                className="mb-2 mb-md-4"
                            >
                                Сведения об исполнителе, за которого производится оплата патента
                            </Text.Title>
                            <NmDadataInput
                                required
                                label="Адрес"
                                size="xl"
                                className="mb-3 mb-md-4"
                                query={values.address}
                                onChange={({data, value} ) => {
                                    setFieldValue("addressInfo", data);
                                    setFieldTouched("address", true);
                                    setFieldValue("address", value);
                                }}
                                error={touched.address ? errors && errors.address : false}
                            />
                            <div className="row gx-4 mb-3 mb-md-4">
                                <div className="col-16 col-md-8 mb-3 mb-md-0">
                                    <NmDatePicker
                                        required
                                        placeholderText="ДД.ММ.ГГ"
                                        dateFormat="dd.MM.yy"
                                        name="period"
                                        selected={values.period}
                                        onChange={(e, {name, value}) => {
                                            setFieldTouched(name, true);
                                            setFieldValue(name, value);
                                        }}
                                        error={touched && touched.period ?
                                            errors && errors.period : false
                                        }
                                        isClearable
                                        label="Налоговый период"
                                    />
                                </div>
                                <div className="col-16 col-md-8">
                                    <AmountInput
                                        name="paymentSum"
                                        error={touched?.paymentSum ? errors && errors.paymentSum : false}
                                        size="xl"
                                        label="Сумма, ₽"
                                        value={values.paymentSum}
                                        onChange={handleChangeInput}
                                        placeholder="Введите сумму"
                                        newInput
                                        required
                                    />
                                </div>
                            </div>
                            <Text.Title
                                level="4"
                                className="mb-2 mb-md-4"
                            >
                                Сведения о получателе заявления на аванс
                            </Text.Title>
                            <NmDropdownV2
                                size="xl"
                                placeholder="Выберите получателя"
                                tooltip={
                                    <HelpTooltip
                                        position="top"
                                        hover
                                        text="Сотрудник компании из кадров ЭДО, который будет выбран получателем
                                            договора займа/заявления на аванс и заявления на уменьшение НДФЛ при их
                                            автоматическом формировании в процессе оплаты патента"
                                    />
                                }
                                fullWidth
                                required
                                className="mb-3 mb-md-4"
                                label="Получатель заявлений"
                                search
                                name="recipientId"
                                onChange={handleChangeDropdown}
                                value={values.recipientId}
                                options={receiversOptions}
                                error={touched?.recipientId ? errors && errors.recipientId : false}
                                // onSearchChange={}
                            />
                            <Text.Title
                                level="4"
                                className="mb-2 mb-md-4"
                            >
                                Сведения о получателе (ИФНС)
                            </Text.Title>
                            <NmInputV2
                                name="recipientName"
                                error={touched.recipientName ? errors && errors.recipientName : false}
                                size="xl"
                                label="Наименование получателя (ИФНС)"
                                value={values.recipientName}
                                onChange={handleChangeInput}
                                maxLength={1000}
                                className="mb-3 mb-md-4"
                                placeholder="Введите наименование получателя (ИФНС)"
                                required
                            />
                            <div className="row gx-4 mb-3 mb-md-4">
                                <div className="col-16 col-md-8 mb-3 mb-md-0">
                                    <NmInputV2
                                        name="recipientInn"
                                        mask="9999999999"
                                        maskChar={null}
                                        error={touched.recipientInn ? errors && errors.recipientInn : false}
                                        size="xl"
                                        label="ИНН получателя (ИФНС)"
                                        value={values.recipientInn}
                                        onChange={handleChangeInput}
                                        placeholder="Введите ИНН получателя (ИФНС)"
                                        required
                                    />
                                </div>
                                <div className="col-16 col-md-8">
                                    <NmInputV2
                                        name="recipientKpp"
                                        mask="999999999"
                                        maskChar={null}
                                        error={touched.recipientKpp ? errors && errors.recipientKpp : false}
                                        size="xl"
                                        label="КПП получателя (ИФНС)"
                                        value={values.recipientKpp}
                                        onChange={handleChangeInput}
                                        placeholder="Введите КПП получателя (ИФНС)"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row gx-4 mb-3 mb-md-4">
                                <div className="col-16 col-md-8 mb-3 mb-md-0">
                                    <NmInputV2
                                        name="recipientKbk"
                                        mask="99999999999999999999"
                                        maskChar={null}
                                        error={touched.recipientKbk ? errors && errors.recipientKbk : false}
                                        size="xl"
                                        label="КБК получателя (ИФНС)"
                                        value={values.recipientKbk}
                                        onChange={handleChangeInput}
                                        placeholder="Введите КБК получателя (ИФНС)"
                                    />
                                </div>
                                <div className="col-16 col-md-8">
                                    <NmInputV2
                                        name="recipientOktmo"
                                        mask="99999999999"
                                        maskChar={null}
                                        error={touched.recipientOktmo ? errors && errors.recipientOktmo : false}
                                        size="xl"
                                        label="ОКТМО получателя (ИФНС)"
                                        value={values.recipientOktmo}
                                        onChange={handleChangeInput}
                                        placeholder="Введите ОКТМО получателя (ИФНС)"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row gx-4">
                                <div className="col-16 col-md-8 mb-3 mb-md-0">
                                    <NmInputV2
                                        name="recipientBik"
                                        mask="999999999"
                                        maskChar={null}
                                        error={touched.recipientBik ? errors && errors.recipientBik : false}
                                        size="xl"
                                        label="БИК банка получателя (ИФНС)"
                                        value={values.recipientBik}
                                        onChange={handleChangeInput}
                                        placeholder="Введите БИК банка получателя (ИФНС)"
                                        required
                                    />
                                </div>
                                <div className="col-16 col-md-8">
                                    <NmInputV2
                                        name="recipientRs"
                                        mask="99999999999999999999"
                                        maskChar={null}
                                        error={touched.recipientRs ? errors && errors.recipientRs : false}
                                        size="xl"
                                        label="Р/С получателя (ИФНС)"
                                        value={values.recipientRs}
                                        onChange={handleChangeInput}
                                        placeholder="Введите Р/С получателя (ИФНС)"
                                        required
                                    />
                                </div>
                            </div>
                        </NmForm>
                    </NmModal>

                );
            }}
        </Formik>
    );
};

export default CreateEditPatentPayment;

CreateEditPatentPayment.defaultProps = {
    data: {},
};
