import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getStopList} from "./actionCreators";
import {
    CREATE_EXPORT_STOP_LIST_ERROR,
    CREATE_EXPORT_STOP_LIST_REQUEST,
    CREATE_EXPORT_STOP_LIST_SUCCESS,
    IMPORT_STOP_LIST_ERROR,
    IMPORT_STOP_LIST_REQUEST,
    IMPORT_STOP_LIST_SUCCESS,
    STOP_LIST_ADD_INN_ERROR,
    STOP_LIST_ADD_INN_REQUEST,
    STOP_LIST_ADD_INN_SUCCESS,
    STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR,
    STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST,
    STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS,
    STOP_LIST_DELETE_INNS_ERROR,
    STOP_LIST_DELETE_INNS_REQUEST,
    STOP_LIST_DELETE_INNS_SUCCESS,
    STOP_LIST_READ_ERROR,
    STOP_LIST_READ_REQUEST,
    STOP_LIST_READ_SUCCESS,
} from "./actions";
import {stopListPageDataSelector} from "./selectors";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

import {
    STOP_LIST_ADD_ERROR_TYPE,
    STOP_LIST_EXPORT_ERROR_TYPE,
    STOP_LIST_IMPORT_ERROR_TYPE,
} from "../../../../constants/stopList";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/contractors/stop-list",
        client: "/client-adm/contractors/stop-list",
    });
};

const handleResponse = function* (data) {
    const {
        isFetchList,
    } = data;

    if (isFetchList) {
        const state = yield select();
        const pageData = stopListPageDataSelector(state);

        yield put(getStopList(pageData));
    }
};

const getStopListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getClientForbiddenPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: STOP_LIST_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: STOP_LIST_READ_ERROR,
            payload: error,
        });
    }
};

const addInnForbiddenSaga = function* ({payload}) {
    try {
        const {clientId, inn} = payload;

        const result = yield request.bff.get(`${getController()}/addInnForbidden`, {params: {clientId, inn}});
        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_ADD_ERROR_TYPE.INN_ALREADY_ADDED) {
                toastError("Данный ИНН уже добавлен в стоп-лист компании");
            } else {
                toastError(errorMessage);
            }

            yield put({
                type: STOP_LIST_ADD_INN_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        yield put({type: STOP_LIST_ADD_INN_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: STOP_LIST_ADD_INN_ERROR, payload: error});
    }
};

const deleteAllInnClientSaga = function* ({payload}) {
    try {
        const {clientId} = payload;
        const result = yield request.bff.get(`${getController()}/deleteAllInnClient`, {params: {clientId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Стоп-лист компании успешно очищен");

        yield handleResponse({isFetchList: true});

        yield put({
            type: STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR,
            payload: error,
        });
    }
};

const deleteInnsForbiddenSaga = function* ({payload}) {
    try {
        const {listInns} = payload;
        const result = yield request.bff.post(`${getController()}/deleteInnsForbidden`, listInns);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: STOP_LIST_DELETE_INNS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Выбранные ИНН успешно удалены");

        yield handleResponse({isFetchList: true});

        yield put({
            type: STOP_LIST_DELETE_INNS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: STOP_LIST_DELETE_INNS_ERROR,
            payload: error,
        });
    }
};

const importInnsFromFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            formData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/importForbiddenInnClient?clientId=${clientId}`, formData, {...getMultipartHeaders()});
        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_IMPORT_ERROR_TYPE.NO_DATA_FILE_TO_IMPORT) {
                toastError("В выбранном файле отсутствуют записи для загрузки");
            } else {
                toastError(errorMessage);
            }

            yield put({
                type: IMPORT_STOP_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }
        toastSuccess("Загрузка файла успешно завершена. Список будет обновлен через некоторое время");

        yield put({
            type: IMPORT_STOP_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: IMPORT_STOP_LIST_ERROR,
            payload: error,
        });
    }
};

const exportStopListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/addExportClientForbidden`, payload);
        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {
            if (errorCode === STOP_LIST_EXPORT_ERROR_TYPE.NO_INN_CLIENT_FOUND_SPECIFIED_FILTERS) {
                toastError("Записи в стоп-листе компании отсутствуют. Операция невозможна");
            } else {
                toastError(errorMessage);
            }


            yield put({
                type: CREATE_EXPORT_STOP_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт\"");

        yield put({
            type: CREATE_EXPORT_STOP_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CREATE_EXPORT_STOP_LIST_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(STOP_LIST_READ_REQUEST, getStopListSaga),
        takeEvery(STOP_LIST_ADD_INN_REQUEST, addInnForbiddenSaga),
        takeEvery(STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST, deleteAllInnClientSaga),
        takeEvery(STOP_LIST_DELETE_INNS_REQUEST, deleteInnsForbiddenSaga),
        takeEvery(IMPORT_STOP_LIST_REQUEST, importInnsFromFileSaga),
        takeEvery(CREATE_EXPORT_STOP_LIST_REQUEST, exportStopListSaga),
    ]);
}