import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {isBoolean} from "lodash";
import * as yup from "yup";

import NmCheckboxV2 from "../../../../../../components/ActualComponents/NmCheckboxV2";
import NmDatePicker from "../../../../../../components/ActualComponents/NmDatePicker";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmHorizontalToggleV2 from "../../../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../components/NmTitle";

import {convertUtcToLocal, getUtcDateFilter} from "../../../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../../../utils/objectHelper";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

import {
    setMaintenanceNoticeSettings,
} from "../../../../../../ducks/bff/settings/service/actionCreators";
import {
    bffSettingsServiceNotificationsSelector,
    bffSettingsServiceProgressSelector,
} from "../../../../../../ducks/bff/settings/service/selectors";

export const SettingsServiceMaintenanceNoticeFormEdit = (props) => {
    const {
        onClose,
        isContractor,
        type,
    } = props;

    const dispatch = useDispatch();

    const progress = useSelector(bffSettingsServiceProgressSelector);
    const data = useSelector((state) => bffSettingsServiceNotificationsSelector(state, type));

    const {
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
    } = useFormik({
        onSubmit,
        initialValues: {
            title: data?.title || "",
            description: data?.description || "",
            timeIntervalStart: data?.timeIntervalStart ? convertUtcToLocal(data?.timeIntervalStart) : null,
            timeIntervalEnd: data?.timeIntervalEnd ? convertUtcToLocal(data?.timeIntervalEnd) : null,
            descriptionInnStep: data?.descriptionInnStep || "",
            additionalNoticeForInnStep: data?.additionalNoticeForInnStep || false,
            inTimeInterval: data?.inTimeInterval || false,
            publish: data?.publish || false,
        },
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            title: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            description: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
            descriptionInnStep: yup.string()
                .when("additionalNoticeForInnStep", {
                    is: true,
                    then: yup.string()
                        .required(VALIDATIONS_MESSAGE.REQUIRED),
                }),
        }),
    });

    function onSubmit() {
        const data = filterEmptyValues({
            ...values,
            timeIntervalStart: values.inTimeInterval && values.timeIntervalStart
                ? getUtcDateFilter(values.timeIntervalStart)
                : undefined,
            timeIntervalEnd: values.inTimeInterval && values.timeIntervalEnd
                ? getUtcDateFilter(values.timeIntervalEnd)
                : undefined,
            descriptionInnStep: values.additionalNoticeForInnStep
                ? values.descriptionInnStep
                : undefined,
        });

        dispatch(setMaintenanceNoticeSettings({
            type,
            ...data,
            getResult: () => {
                onClose();
            },
        }));
    }

    const onChange = (_event, {name, value, checked}) => {
        setFieldValue(name, isBoolean(checked) ? checked : value);
    };

    const getForm = () => {
        return (
            <>
                <NmInputV2
                    required={true}
                    size="xl"
                    label="Заголовок"
                    maskChar={null}
                    onChange={onChange}
                    name="title"
                    value={values.title}
                    maxLength={50}
                    placeholder="Введите заголовок уведомления"
                    error={touched.title && errors.title}
                />
                <NmTextareaV2
                    required={true}
                    size="xl"
                    minRows={2}
                    label="Описание"
                    placeholder="Введите описание"
                    name="description"
                    maxLength="3000"
                    value={values.description}
                    onChange={onChange}
                    error={touched.description && errors.description}
                />
                {
                    isContractor &&
                    <>
                        <NmCheckboxV2
                            name="additionalNoticeForInnStep"
                            label="Опубликовать дополнительное уведомление на шаге регистрации с ИНН"
                            checked={values.additionalNoticeForInnStep}
                            onChange={onChange}
                        />
                        {
                            values.additionalNoticeForInnStep &&
                            <NmInputV2
                                required={true}
                                size="xl"
                                label="Описание (для шага с ИНН)"
                                onChange={onChange}
                                name="descriptionInnStep"
                                value={values.descriptionInnStep}
                                maxLength={150}
                                placeholder="Введите описание"
                                error={touched.descriptionInnStep && errors.descriptionInnStep}
                            />
                        }
                    </>
                }
                <NmCheckboxV2
                    name="inTimeInterval"
                    label="Установить дату и время публикации"
                    checked={values.inTimeInterval}
                    onChange={onChange}
                />
                {
                    values.inTimeInterval &&
                    <>
                        <NmDatePicker
                            size="xl"
                            label="Дата и время начала публикации"
                            dateFormat="dd.MM.yy HH:mm"
                            dateFormatMask="99.99.99 99:99"
                            placeholderText="дд.мм.гг чч.мм"
                            selected={values.timeIntervalStart}
                            name="timeIntervalStart"
                            onChange={onChange}
                            showTimeSelect={true}
                        />
                        <NmDatePicker
                            size="xl"
                            label="Дата и время окончания публикации"
                            dateFormat="dd.MM.yy HH:mm"
                            dateFormatMask="99.99.99 99:99"
                            placeholderText="дд.мм.гг чч.мм"
                            selected={values.timeIntervalEnd}
                            name="timeIntervalEnd"
                            onChange={onChange}
                            showTimeSelect={true}
                        />
                    </>
                }
            </>
        );
    };

    const targetText = isContractor ? "исполнителей" : "заказчика";

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Для ${targetText}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    submit={handleSubmit}
                    onClose={onClose}
                    mobile="column"
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            classNameContent="flex-column"
            loading={progress}
        >
            <NmForm
                onSubmit={handleSubmit}
                addMargin={true}
            >
                <NmHorizontalToggleV2
                    duplex={false}
                    rightLabel={`Опубликовать уведомление для ${targetText}`}
                    name="publish"
                    checked={values.publish}
                    onChange={onChange}
                />
                {
                    values.publish &&
                    getForm()
                }
            </NmForm>
        </NmModal>
    );
};