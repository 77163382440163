import {getPageCrowdTasks} from "../../crowd/tasks/actionCreators";

import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = (params) => {
    const {
        isProjectPage,
        isObjectPage,
    } = params;

    if (isObjectPage) {
        return getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/client/objects/object-card/tasks",
            client: "/client-adm/client/objects/object-card/tasks",
        });
    }

    if (isProjectPage) {
        return getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/client/projects/project-card/tasks",
            client: "/client-adm/client/projects/project-card/tasks",
        });
    }

    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/crowd-tasks/registry",
        client: "/client-adm/crowd-tasks/registry",
    });
};

export const getPageAdmCrowdTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController({})}/page`,
        ...payload,
    });
};

export const getPageAdmCrowdProjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController({isProjectPage: true})}/by-project/page`,
        ...payload,
    });
};

export const getPageAdmCrowdObjectTasks = (payload) => {
    return getPageCrowdTasks({
        url: `${getController({isObjectPage: true})}/by-object/page`,
        ...payload,
    });
};