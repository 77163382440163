import dateFormat from "../../../../../../utils/dateFormat";

export const getAdditionalDocumentVerificationUpdatedData = (params) => {
    const {
        updatedPersonalData,
        updatedPersonalDataFields,
    } = params;

    return updatedPersonalDataFields.reduce((result, fieldName) => {
        return {
            ...result,
            [fieldName]: updatedPersonalData[fieldName] ? updatedPersonalData[fieldName] : undefined,
            fingerprintingDocumentIssuedDate: updatedPersonalData.fingerprintingDocumentIssuedDate
                ? dateFormat(updatedPersonalData.fingerprintingDocumentIssuedDate, "yyyy-MM-dd")
                : undefined,
            medicalBookIssuedDate: updatedPersonalData.medicalBookIssuedDate
                ? dateFormat(updatedPersonalData.medicalBookIssuedDate, "yyyy-MM-dd")
                : undefined,
        };
    }, {});
};