export const STOP_LIST_READ_REQUEST = "STOP_LIST_READ_REQUEST";
export const STOP_LIST_READ_SUCCESS = "STOP_LIST_READ_SUCCESS";
export const STOP_LIST_READ_ERROR = "STOP_LIST_READ_ERROR";

export const STOP_LIST_ADD_INN_REQUEST = "STOP_LIST_ADD_INN_REQUEST";
export const STOP_LIST_ADD_INN_SUCCESS = "STOP_LIST_ADD_INN_SUCCESS";
export const STOP_LIST_ADD_INN_ERROR = "STOP_LIST_ADD_INN_ERROR";

export const STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST = "STOP_LIST_DELETE_ALL_INN_CLIENT_REQUEST";
export const STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS = "STOP_LIST_DELETE_ALL_INN_CLIENT_SUCCESS";
export const STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR = "STOP_LIST_DELETE_ALL_INN_CLIENT_ERROR";

export const STOP_LIST_DELETE_INNS_REQUEST = "STOP_LIST_DELETE_INNS_REQUEST";
export const STOP_LIST_DELETE_INNS_SUCCESS = "STOP_LIST_DELETE_INNS_SUCCESS";
export const STOP_LIST_DELETE_INNS_ERROR = "STOP_LIST_DELETE_INNS_ERROR";

export const IMPORT_STOP_LIST_REQUEST = "IMPORT_STOP_LIST_REQUEST";
export const IMPORT_STOP_LIST_SUCCESS = "IMPORT_STOP_LIST_SUCCESS";
export const IMPORT_STOP_LIST_ERROR = "IMPORT_STOP_LIST_ERROR";

export const CREATE_EXPORT_STOP_LIST_REQUEST = "CREATE_EXPORT_STOP_LIST_REQUEST";
export const CREATE_EXPORT_STOP_LIST_SUCCESS = "CREATE_EXPORT_STOP_LIST_SUCCESS";
export const CREATE_EXPORT_STOP_LIST_ERROR = "CREATE_EXPORT_STOP_LIST_ERROR";