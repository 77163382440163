import {floatToPercent} from "../../../../../../utils/mathHelper";

import {ORDER_WORK_REPORT_TYPE} from "../../../../../../constants/finance";

export const getDepositRateEditInitialState = (params) => {
    const {
        data,
        type,
    } = params;

    const {
        currentCommissionRate,
        deferredCurrentCommissionRate,
        deferredDatetime,
        deferredRegionTime,
        contractorOrderCommission,
        deferredContractorOrderCommission,
        deferredContractorOrderCommissionDatetime,
        deferredContractorOrderCommissionRegionTime,
        contractorRegistryCommission,
        deferredContractorRegistryCommission,
        deferredContractorRegistryCommissionDatetime,
        deferredContractorRegistryCommissionRegionTime,
        minCommission,
        threshold,
        deferredMinCommission,
        deferredThreshold,
        deferredMinCommissionDatetime,
        deferredMinCommissionRegionTime,
        minimalCommissionNdflPaymentsAmount = 0,
        deferredMinimalCommissionNdflPaymentsAmount,
        deferredMinimalCommissionNdflPaymentsAmountDatetime,
        deferredMinimalCommissionNdflPaymentsAmountRegionTime,
        minimalCommissionIndividualPaymentsAmount,
        deferredMinimalCommissionIndividualPaymentsAmount,
        deferredMinimalCommissionIndividualPaymentsAmountDatetime,
        deferredMinimalCommissionIndividualPaymentsAmountRegionTime,
        contractorIndividualOrderPaymentsCommission,
        deferredContractorIndividualOrderPaymentsCommission,
        deferredContractorIndividualOrderPaymentsCommissionDatetime,
        deferredContractorIndividualOrderPaymentsCommissionRegionTime,
        contractorIndividualRegistryPaymentsCommission,
        deferredContractorIndividualRegistryPaymentsCommission,
        deferredContractorIndividualRegistryPaymentsCommissionDatetime,
        deferredContractorIndividualRegistryPaymentsCommissionRegionTime,
        minIndividualCommissionAmount,
        deferredMinIndividualCommissionAmount,
        individualPaymentsThresholdAmount,
        deferredIndividualPaymentsThresholdAmount,
        deferredMinIndividualCommissionAmountDatetime,
        deferredMinIndividualCommissionAmountRegionTime,
    } = data;

    if (type === ORDER_WORK_REPORT_TYPE.INDIVIDUAL) {
        return {
            clientCommissionChange: {
                rate: String(floatToPercent(minimalCommissionIndividualPaymentsAmount)),
                deferredRate: deferredMinimalCommissionIndividualPaymentsAmount
                    ? floatToPercent(deferredMinimalCommissionIndividualPaymentsAmount)
                    : "",
                fromDatetime: deferredMinimalCommissionIndividualPaymentsAmount
                    ? new Date(deferredMinimalCommissionIndividualPaymentsAmountDatetime)
                    : null,
                regionTime: deferredMinimalCommissionIndividualPaymentsAmount
                    ? deferredMinimalCommissionIndividualPaymentsAmountRegionTime
                    : "MSK",
                isDeferred: !!deferredMinimalCommissionIndividualPaymentsAmount,
            },
            contractorOrderCommissionChange: {
                rate: String(floatToPercent(contractorIndividualOrderPaymentsCommission)),
                deferredRate: deferredContractorIndividualOrderPaymentsCommission
                    ? floatToPercent(deferredContractorIndividualOrderPaymentsCommission)
                    : "",
                fromDatetime: deferredContractorIndividualOrderPaymentsCommission
                    ? new Date(deferredContractorIndividualOrderPaymentsCommissionDatetime)
                    : "",
                regionTime: deferredContractorIndividualOrderPaymentsCommission
                    ? deferredContractorIndividualOrderPaymentsCommissionRegionTime
                    : "MSK",
                isDeferred: !!deferredContractorIndividualOrderPaymentsCommission,
            },
            contractorRegistryCommissionChange: {
                rate: String(floatToPercent(contractorIndividualRegistryPaymentsCommission)),
                deferredRate: deferredContractorIndividualRegistryPaymentsCommission
                    ? floatToPercent(deferredContractorIndividualRegistryPaymentsCommission) :
                    "",
                fromDatetime: deferredContractorIndividualRegistryPaymentsCommission
                    ? new Date(deferredContractorIndividualRegistryPaymentsCommissionDatetime)
                    : "",
                regionTime: deferredContractorIndividualRegistryPaymentsCommission
                    ? deferredContractorIndividualRegistryPaymentsCommissionRegionTime
                    : "MSK",
                isDeferred: !!deferredContractorIndividualRegistryPaymentsCommission,
            },
            minCommissionChange: {
                minCommission: parseFloat(minIndividualCommissionAmount),
                thresholdAmount: parseFloat(individualPaymentsThresholdAmount),
                fromDatetime: deferredMinIndividualCommissionAmount
                    ? new Date(deferredMinIndividualCommissionAmountDatetime)
                    : null,
                regionTime: deferredMinIndividualCommissionAmount
                    ? deferredMinIndividualCommissionAmountRegionTime
                    : "MSK",
                isDeferred: !!deferredMinIndividualCommissionAmount,
                deferredMinCommission: deferredMinIndividualCommissionAmount
                    ? parseFloat(deferredMinIndividualCommissionAmount)
                    : undefined,
                deferredThreshold: deferredMinCommission
                    ? parseFloat(deferredIndividualPaymentsThresholdAmount)
                    : undefined,
            },
            minimalCommissionNdflChange: {
                type,
            },
        };
    }

    return {
        clientCommissionChange: {
            rate: String(floatToPercent(currentCommissionRate)),
            deferredRate: deferredCurrentCommissionRate ? floatToPercent(deferredCurrentCommissionRate) : "",
            fromDatetime: deferredCurrentCommissionRate ? new Date(deferredDatetime) : null,
            regionTime: deferredCurrentCommissionRate ? deferredRegionTime : "MSK",
            isDeferred: !!deferredCurrentCommissionRate,
        },
        contractorOrderCommissionChange: {
            rate: String(floatToPercent(contractorOrderCommission)),
            deferredRate: deferredContractorOrderCommission ? floatToPercent(deferredContractorOrderCommission) : "",
            fromDatetime: deferredContractorOrderCommission ? new Date(deferredContractorOrderCommissionDatetime) : "",
            regionTime: deferredContractorOrderCommission ? deferredContractorOrderCommissionRegionTime : "MSK",
            isDeferred: !!deferredContractorOrderCommission,
        },
        contractorRegistryCommissionChange: {
            rate: String(floatToPercent(contractorRegistryCommission)),
            deferredRate: deferredContractorRegistryCommission ? floatToPercent(deferredContractorRegistryCommission) : "",
            fromDatetime: deferredContractorRegistryCommission ? new Date(deferredContractorRegistryCommissionDatetime) : "",
            regionTime: deferredContractorRegistryCommission ? deferredContractorRegistryCommissionRegionTime : "MSK",
            isDeferred: !!deferredContractorRegistryCommission,
        },
        minCommissionChange: {
            minCommission: parseFloat(minCommission),
            thresholdAmount: parseFloat(threshold),
            fromDatetime: deferredMinCommission ? new Date(deferredMinCommissionDatetime) : null,
            regionTime: deferredMinCommission ? deferredMinCommissionRegionTime : "MSK",
            isDeferred: !!deferredMinCommission,
            deferredMinCommission: deferredMinCommission ? parseFloat(deferredMinCommission) : undefined,
            deferredThreshold: deferredMinCommission ? parseFloat(deferredThreshold) : undefined,
        },
        minimalCommissionNdflChange: {
            rate: parseFloat(minimalCommissionNdflPaymentsAmount),
            deferredRate: deferredMinimalCommissionNdflPaymentsAmount ? parseFloat(deferredMinimalCommissionNdflPaymentsAmount) : undefined,
            fromDatetime: deferredMinimalCommissionNdflPaymentsAmountDatetime ? new Date(deferredMinimalCommissionNdflPaymentsAmountDatetime) : "",
            regionTime: deferredMinimalCommissionNdflPaymentsAmountRegionTime ? deferredMinimalCommissionNdflPaymentsAmountRegionTime : "MSK",
            isDeferred: !!deferredMinimalCommissionNdflPaymentsAmount,
        },
    };
};