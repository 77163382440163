import {all, call, put, select, takeEvery} from "@redux-saga/core/effects";

import {clientCurrentMemberSelector} from "../../../../clientMember";
import {getAllTasks} from "../../../../job";
import {getPatentsRegistries} from "./actionCreators";
import {
    ADD_PATENTS_REGISTRY_ERROR,
    ADD_PATENTS_REGISTRY_REQUEST,
    ADD_PATENTS_REGISTRY_SUCCESS,
    ARCHIVE_PATENTS_REGISTRY_ERROR,
    ARCHIVE_PATENTS_REGISTRY_REQUEST,
    ARCHIVE_PATENTS_REGISTRY_SUCCESS,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST,
    CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS,
    CHECK_PATENT_REGISTRY_DUPLICATES_ERROR,
    CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST,
    CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS,
    CREATE_DUPLICATE_PATENT_REGISTRY_ERROR,
    CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST,
    CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS,
    DELETE_FAILED_PATENT_REGISTRY_ERROR,
    DELETE_FAILED_PATENT_REGISTRY_REQUEST,
    DELETE_FAILED_PATENT_REGISTRY_SUCCESS,
    GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR,
    GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST,
    GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS,
    GET_PATENT_REGISTRY_BY_ID_ERROR,
    GET_PATENT_REGISTRY_BY_ID_REQUEST,
    GET_PATENT_REGISTRY_BY_ID_SUCCESS,
    PATENTS_REGISTRY_LIST_ERROR,
    PATENTS_REGISTRY_LIST_REQUEST,
    PATENTS_REGISTRY_LIST_SUCCESS,
    PAY_PATENT_REGISTRY_BY_ID_ERROR,
    PAY_PATENT_REGISTRY_BY_ID_REQUEST,
    PAY_PATENT_REGISTRY_BY_ID_SUCCESS,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST,
    PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST,
    REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS,
    REGISTRY_PATENT_LOG_ERROR,
    REGISTRY_PATENT_LOG_REQUEST,
    REGISTRY_PATENT_LOG_SUCCESS,
    RESTORE_PATENTS_REGISTRY_ERROR,
    RESTORE_PATENTS_REGISTRY_REQUEST,
    RESTORE_PATENTS_REGISTRY_SUCCESS,
    UPDATE_PATENTS_REGISTRY_ERROR,
    UPDATE_PATENTS_REGISTRY_REQUEST,
    UPDATE_PATENTS_REGISTRY_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../../utils/url";

import {LINK_PATENTS_PAYMENTS_CARD} from "../../../../../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [LINK_PATENTS_PAYMENTS_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/patents/pay-patents/patent-card",
                client: "/client-adm/client/patents/pay-patents/patent-card",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/patents/pay-patents",
            adminRolesUrl: "/adm/clients/client-card/patents/pay-patents",
        },
        "/adm/clients/client-card/client/objects",
        true,
        )
    );
};

const getPatentsRegistriesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getRegistryPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PATENTS_REGISTRY_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: PATENTS_REGISTRY_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PATENTS_REGISTRY_LIST_ERROR,
            payload: error,
        });
    }
};

const addPatentsRegistrySaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.bff.post(`${getController()}/addRegistry`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_PATENTS_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Реестр успешно добавлен");

        yield put({
            type: ADD_PATENTS_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_PATENTS_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const updatePatentsRegistrySaga = function* ({payload}) {
    try {
        const {data, meta} = payload;

        const result = yield request.bff.post(`${getController()}/updateRegistry`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_PATENTS_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (meta) {
            yield call(meta, null);
        }

        toastSuccess("Реестр успешно изменен");

        yield put({
            type: UPDATE_PATENTS_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_PATENTS_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const archivePatentsRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/archiveRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVE_PATENTS_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен в архив");

        const state = yield select();
        yield put(getPatentsRegistries(state.bff.clientsPatentsPay.pageData));

        yield put({
            type: ARCHIVE_PATENTS_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ARCHIVE_PATENTS_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const restorePatentsRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/restoreRegistryById`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESTORE_PATENTS_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно восстановлен из архива");

        const state = yield select();
        yield put(getPatentsRegistries(state.bff.clientsPatentsPay.pageData));

        yield put({
            type: RESTORE_PATENTS_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: RESTORE_PATENTS_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const getPatentRegistryByIdSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/getRegistryById`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {

            toastError(errorMessage);

            yield put({
                type: GET_PATENT_REGISTRY_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_PATENT_REGISTRY_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: GET_PATENT_REGISTRY_BY_ID_ERROR, payload: error});
    }
};

const payPatentRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/payRegistryById`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PAY_PATENT_REGISTRY_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        yield put({
            type: PAY_PATENT_REGISTRY_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAY_PATENT_REGISTRY_BY_ID_ERROR,
            payload: error,
        });
    }
};

const payUnpaidPatentRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/payRegistryUnpaidPaymentsById`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();
        const {
            clientId,
            clientUserId,
        } = clientCurrentMemberSelector(state);

        yield put(getAllTasks({
            clientId,
            clientUserId,
        }));

        yield put({
            type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: PAY_UNPAID_PATENT_REGISTRY_BY_ID_ERROR,
            payload: error,
        });
    }
};

const checkPatentRegistryPaymentsDuplicatesSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/hasDuplicates`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_PATENT_REGISTRY_DUPLICATES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: CHECK_PATENT_REGISTRY_DUPLICATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_PATENT_REGISTRY_DUPLICATES_ERROR,
            payload: error,
        });
    }
};

const createDuplicatePatentRegistrySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/createDuplicate`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CREATE_DUPLICATE_PATENT_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Реестр успешно добавлен");

        const state = yield select();
        yield put(getPatentsRegistries(state.bff.clientsPatentsPay.pageData));

        yield put({
            type: CREATE_DUPLICATE_PATENT_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CREATE_DUPLICATE_PATENT_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const deleteFailedPatentRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/deleteFailed`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_FAILED_PATENT_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_FAILED_PATENT_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_FAILED_PATENT_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const getContractorsPaymentAbilityPatentSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...reqData
        } = payload;

        const data = yield request.bff.get(`${getController()}/getContractorPaymentAbility`, {params: reqData});

        if (handleResponse) {
            handleResponse(data);
        }

        yield put({
            type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_SUCCESS,
            payload: {
                ...data,
                status: "COMPLETED",
            },
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_ERROR,
            payload: error,
        });
    }
};

const checkPatentRegistryContractorPresentPaymentsSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/isContractorPresent`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_ERROR,
            payload: error,
        });
    }
};

const refreshPatentRegistryPaymentsStatusSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/refreshPaymentsUsingHandler`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Задача на обновление статусов оплат по патентам успешно запущена");

        yield put({
            type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_ERROR,
            payload: error,
        });
    }
};

const getRegistryPatentLogSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/getLogPage`, reqData);

        if (getResult) {
            getResult(result);
        }

        if (result.errorMessage) {
            toastError(result.errorMessage);
            yield put({
                type: REGISTRY_PATENT_LOG_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PATENT_LOG_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PATENT_LOG_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(PATENTS_REGISTRY_LIST_REQUEST, getPatentsRegistriesSaga),
        takeEvery(ADD_PATENTS_REGISTRY_REQUEST, addPatentsRegistrySaga),
        takeEvery(UPDATE_PATENTS_REGISTRY_REQUEST, updatePatentsRegistrySaga),
        takeEvery(ARCHIVE_PATENTS_REGISTRY_REQUEST, archivePatentsRegistrySaga),
        takeEvery(RESTORE_PATENTS_REGISTRY_REQUEST, restorePatentsRegistrySaga),
        takeEvery(GET_PATENT_REGISTRY_BY_ID_REQUEST, getPatentRegistryByIdSaga),
        takeEvery(PAY_PATENT_REGISTRY_BY_ID_REQUEST, payPatentRegistrySaga),
        takeEvery(CHECK_PATENT_REGISTRY_DUPLICATES_REQUEST, checkPatentRegistryPaymentsDuplicatesSaga),
        takeEvery(CREATE_DUPLICATE_PATENT_REGISTRY_REQUEST, createDuplicatePatentRegistrySaga),
        takeEvery(DELETE_FAILED_PATENT_REGISTRY_REQUEST, deleteFailedPatentRegistryPaymentsSaga),
        takeEvery(GET_CONTRACTOR_PAYMENT_ABILITY_PATENT_REQUEST, getContractorsPaymentAbilityPatentSaga),
        takeEvery(CHECK_PATENT_REGISTRY_CONTRACTOR_PRESENT_REQUEST, checkPatentRegistryContractorPresentPaymentsSaga),
        takeEvery(REFRESH_PATENT_REGISTRY_PAYMENTS_STATUS_REQUEST, refreshPatentRegistryPaymentsStatusSaga),
        takeEvery(PAY_UNPAID_PATENT_REGISTRY_BY_ID_REQUEST, payUnpaidPatentRegistrySaga),
        takeEvery(REGISTRY_PATENT_LOG_REQUEST, getRegistryPatentLogSaga),
    ]);
}