import React from "react";
import {useMedia} from "react-media";

import NmPageInfoCardsAccordion from "../ActualComponents/NmPageInfoCardsAccordion";

import {useDepositValuesFetch} from "./hooks/useDepositValuesFetch";

import bem from "../../utils/bem";
import {getDepositValuesCards} from "./utils/getDepositValuesCards";

import "./style.sass";

const DepositValuesAmountInfo = (props) => {
    const {
        clientId,
        className = "",
        isOpenFilter,
        isRegistry = false,
        isPatents = false,
        isObjectsPage = false,
        isProjectsPage = false,
        isDepositPage = false,
        isOrdersPage = false,
        // НДФЛ
        isCivil = false,
        addMarginBottom = false,
        hideDetails = false,
        projectId,
        objectId,
        enableIndividualDeposit,
        isHideClientDeposit,
    } = props;

    const [block] = bem("deposit-values-amount-info", className);
    const isAccordion = useMedia({query: {maxWidth: 1920}});

    const {
        depositValues,
        isLoading,
    } = useDepositValuesFetch({
        isCivil,
        clientId,
        projectId,
        objectId,
    });

    const isProjectCardPage = Boolean(projectId);
    const isObjectCardPage = Boolean(objectId);

    const {
        totalDeposit,
        deposit,
        clientDeposit,
        projectObjectDeposit,
        nonDistributedDeposit,
        awaitingConfirmationPayments,
        inProgressPayments,
        availableForPayments,
        limit,
        limitSpent,
        availableForOrders,
    } = getDepositValuesCards({
        isCivil,
        depositValues,
        isAccordion,
        isLoading,
        isProjectCardPage,
        isObjectCardPage,
        enableIndividualDeposit,
    });

    const isVisibleTotalDeposit = totalDeposit.isVisible
        && (
            isObjectsPage
            || isProjectsPage
            || isDepositPage
            || isOrdersPage
        );
    const width = isVisibleTotalDeposit ? 156 : undefined;

    const cards = [
        {
            ...projectObjectDeposit,
            width,
            className: "col-16 col-xxl-2",
        },
        {
            ...totalDeposit,
            isVisible: isVisibleTotalDeposit,
            width,
            className: isOpenFilter && !isRegistry ? "mt-xxl-4" : "",
        },
        {
            ...nonDistributedDeposit,
            isVisible: nonDistributedDeposit.isVisible && !hideDetails && !isPatents && !isProjectCardPage && !isObjectCardPage,
            width,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...clientDeposit,
            isVisible: !isHideClientDeposit && clientDeposit.isVisible,
            width,
            className: "col-16 col-xxl-2",
        },
        {
            ...deposit,
            isVisible: deposit.isVisible && !isProjectCardPage && !isObjectCardPage && !hideDetails,
            width,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...awaitingConfirmationPayments,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            width,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...inProgressPayments,
            isVisible: !hideDetails,
            width,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...availableForPayments,
            width,
            className: isOpenFilter ? `col-16 ${!isRegistry ? "mt-xxl-4" : ""} col-xxl-3` : "col-16 col-xxl-2",
        },
        {
            ...limit,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            width,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...limitSpent,
            isVisible: !isRegistry && !hideDetails && !isPatents,
            width,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
        {
            ...availableForOrders,
            isVisible: !isRegistry && !isPatents,
            width,
            className: isOpenFilter ? "col-16 mt-xxl-4 col-xxl-3" : "col-16 col-xxl-2",
        },
    ];

    return (
        <NmPageInfoCardsAccordion
            isIgnoreCardClassName={isVisibleTotalDeposit}
            bootstrap={!isVisibleTotalDeposit}
            className={block({mb: addMarginBottom})}
            cards={cards}
            desktopViewFrom="xxl"
        />
    );
};

export default DepositValuesAmountInfo;