import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import DropzoneV2 from "../../../../components/ActualComponents/DropzoneV2";
import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../components/ActualComponents/NmCheckboxV2";
import NmDadataInput from "../../../../components/ActualComponents/NmDadataInput";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import Text from "../../../../components/ActualComponents/Text";
import AmountInput from "../../../../components/AmountInput";
import ApplyButtons from "../../../../components/ApplyButtons";
import {ClientWorkTypeDropdown} from "../../../../components/ClientWorkTypeDropdown";
import ImageCrop from "../../../../components/ImageCrop";
import NmAccordionV2 from "../../../../components/NmAccordionV2";
import NmTitle from "../../../../components/NmTitle";
import {ClientNewInn} from "./inn";
import {Loader} from "semantic-ui-react";

import useNewClientForm from "./hooks/useNewClientForm";

import {clientPhoneMask} from "../../../../utils/clientHelper";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {
    dictionaryToOptions,
} from "../../../../utils/objectHelper";

import {CLIENT_WORK_TYPE} from "../../../../constants/clientSettings";
import {COLOR} from "../../../../constants/color";
import {
    BUSINESS_REGISTRATION_FORM_DICT,
    CLIENT_TYPE_CODE,
} from "../../../../constants/dicts";
import {
    ADMIN,
    NM_CONSULTANT,
    NM_MANAGER,
} from "../../../../constants/roles";

import {
    getOrderCategoriesAllOptionsSelector,
    orderCategoriesAllSelector,
} from "../../../../ducks/bff/clients/info/selectors";
import {bffSettingsSettingsJoiningRequestsProgressSelector} from "../../../../ducks/bff/settings/joining-requests/selectors";
import {
    clientProgressSelector,
} from "../../../../ducks/client";
import {dadataGeoDataSelector} from "../../../../ducks/dadata";
import {
    federalMigrationListProgressSelector,
    getFederalRegionDictSelector,
    nearestMigrationOVMCardSelector,
} from "../../../../ducks/federalMigration";

import PropTypes from "prop-types";

import "./style.sass";

const ClientListNew = (props) => {
    const {
        currentApplication = {},
        fetchList,
        onClose,
    } = props;

    const clientTypeOptions = dictionaryToOptions(BUSINESS_REGISTRATION_FORM_DICT);
    const role = ls(USER_ROLE);

    const progressAddClient = useSelector(clientProgressSelector);
    const progressConfirmApplication = useSelector(bffSettingsSettingsJoiningRequestsProgressSelector);
    const categoryOptions = useSelector(getOrderCategoriesAllOptionsSelector);
    const categoryList = useSelector(orderCategoriesAllSelector);
    const federalRegionDict = useSelector(getFederalRegionDictSelector);
    const nearestOVM = useSelector(nearestMigrationOVMCardSelector);
    const progressFederalMigration = useSelector(federalMigrationListProgressSelector);
    const dadataGeoData = useSelector(dadataGeoDataSelector);

    const progress = progressAddClient || progressFederalMigration || progressConfirmApplication;

    const {
        t,
    } = useTranslation();

    const {
        values,
        errors,
        isOpenImageCrop,
        handleSubmit,
        onDropFile,
        onChangeCategory,
        onChangeAddress,
        onUpdateForm,
        onSaveImageCropForm,
        onCloseImageCropForm,
        onChangeClient,
    } = useNewClientForm({
        nearestOVM,
        role,
        federalRegionDict,
        currentApplication,
        dadataGeoData,
        categoryOptions,
        categoryList,
        fetchList,
        onClose,
    });

    const renderImageCropForm = () => {
        const {
            fileObj: {
                "IMPORT_LOGO": importedFile,
            },
        } = values;

        const [file = {}] = importedFile || [];

        return (
            isOpenImageCrop && <ImageCrop
                file={file}
                submit={onSaveImageCropForm}
                close={onCloseImageCropForm}
            />);
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {t("client-new.client-new-title")}
                </NmTitle>
            }
        >
            {renderImageCropForm()}
            <NmForm
                id="FNS"
                addMargin
            >
                <NmDropdownV2
                    size="xl"
                    label={t("client-new.registration-form")}
                    options={clientTypeOptions}
                    error={errors.clientType}
                    value={values.clientType}
                    name="clientType"
                    onChange={onChangeClient}
                />
                {
                    values.clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY &&
                    <ClientNewInn
                        error={errors.inn}
                        onChange={onChangeClient}
                        inn={values.inn}
                        clientType={values.clientType}
                        onSuccess={onUpdateForm}
                    />
                }
                <NmInputV2
                    size="xl"
                    label={
                        values.clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ?
                            "ФИО ИП" :
                            t("client-new.official-client-name")
                    }
                    required
                    error={errors.fullName}
                    value={values.fullName}
                    name="fullName"
                    placeholder={values.clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ?
                        "Введите ФИО ИП" :
                        t("client-new.official-client-name-placeholder")}
                    onChange={onChangeClient}
                />
                {
                    values.clientType !== CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ?
                        <NmInputV2
                            size="xl"
                            label={t("client-new.short-client-name")}
                            required
                            error={errors.name}
                            name="name"
                            placeholder={t("client-new.short-client-name-placeholder")}
                            value={values.name}
                            onChange={onChangeClient}
                        /> :
                        null
                }
                {
                    values.clientType === CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY ?
                        <NmInputV2
                            size="xl"
                            label={t("client-new.ein")}
                            required
                            error={errors.inn}
                            type="text"
                            mask="99-9999999"
                            maskChar={null}
                            name="inn"
                            placeholder={t("client-new.ein-placeholder")}
                            value={values.inn}
                            onChange={onChangeClient}
                        /> :
                        null
                }
                <div className="relative">
                    <NmDadataInput
                        size="xl"
                        label={
                            values.clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ?
                                t("client-new.address-individual") :
                                t("client-new.address-organization")
                        }
                        required
                        query={values.registeredAddress}
                        error={errors.registeredAddress || values.addressSearchError}
                        name="registeredAddress"
                        onChange={onChangeAddress}
                        placeholder={t("client-new.address-placeholder")}
                    />
                    <div className="client-list-new__address-loader">
                        {progressFederalMigration ?
                            <Loader
                                active
                                inline
                                size="tiny"
                            /> :
                            null
                        }
                    </div>
                </div>
                <NmDropdownV2
                    size="xl"
                    label={t("client-new.category")}
                    required
                    error={errors.categoryId}
                    name="categoryId"
                    options={categoryOptions}
                    value={values.categoryId}
                    onChange={onChangeCategory}
                />
                <NmAccordionV2
                    noStyles={true}
                    className="mb-3 mb-md-4 mb-xxl-5"
                    iconColor={COLOR.PASSIVE_100}
                    title={
                        <Text
                            level="3"
                            color={COLOR.PASSIVE_100}
                        >
                            Дополнительно
                        </Text>
                    }
                >
                    <NmInputV2
                        size="xl"
                        className="mb-3 mb-md-4"
                        label={t("client-new.brand")}
                        error={errors.brand}
                        name="brand"
                        placeholder={t("client-new.brand-placeholder")}
                        value={values.brand}
                        onChange={onChangeClient}
                    />
                    <NmInputV2
                        size="xl"
                        className="mb-3 mb-md-4"
                        label={t("client-new.name-contact-person")}
                        error={errors.representativeName}
                        value={values.representativeName}
                        placeholder={t("client-new.name-contact-person-placeholder")}
                        name="representativeName"
                        onChange={onChangeClient}
                    />
                    <NmInputV2
                        className="mb-3 mb-md-4"
                        error={errors.representativePhone}
                        value={values.representativePhone}
                        name="representativePhone"
                        onChange={onChangeClient}
                        mask={clientPhoneMask[values.clientType]}
                        label={t("client-new.phone-number-contact-person")}
                        size="xl"
                        placeholder={t("client-new.phone-number-contact-person-placeholder")}
                        type="tel"
                    />
                    <NmInputV2
                        className="mb-3 mb-md-4"
                        size="xl"
                        label={t("client-new.email-contact-person")}
                        error={errors.representativeEmail}
                        name="representativeEmail"
                        placeholder={t("client-new.email-contact-person-placeholder")}
                        value={values.representativeEmail}
                        onChange={onChangeClient}
                    />
                </NmAccordionV2>
                <Text.Sub
                    level="2"
                    medium={true}
                    color={COLOR.SECONDARY_100}
                >
                    Комиссии и лимиты
                </Text.Sub>
                <AmountInput
                    newInput
                    size="xl"
                    label={t("client-new.commission")}
                    required
                    error={errors.currentCommissionRate}
                    value={values.currentCommissionRate}
                    name="currentCommissionRate"
                    onChange={onChangeClient}
                />
                <AmountInput
                    newInput
                    size="xl"
                    label="Комиссия исполнителей за выплаты по заказам (НПД), %"
                    required
                    error={errors.contractorSmzOrderPaymentsCommission}
                    value={values.contractorSmzOrderPaymentsCommission}
                    name="contractorSmzOrderPaymentsCommission"
                    onChange={onChangeClient}
                />
                <AmountInput
                    newInput
                    size="xl"
                    label="Комиссия исполнителей за реестровые выплаты (НПД), %"
                    required
                    error={errors.contractorSmzRegistryPaymentsCommission}
                    value={values.contractorSmzRegistryPaymentsCommission}
                    name="contractorSmzRegistryPaymentsCommission"
                    onChange={onChangeClient}
                />

                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <ClientWorkTypeDropdown
                            value={values.clientWorkType}
                            onChange={onChangeClient}
                            error={errors.clientWorkType}
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-2 mt-md-0">
                        <NmInputV2
                            size="xl"
                            required={
                                [
                                    CLIENT_WORK_TYPE.ONLY_KEDO,
                                    CLIENT_WORK_TYPE.NAIMIX_KEDO,
                                ].includes(values.clientWorkType)
                            }
                            label="Тариф КЭДО, ₽ "
                            name="clientWorkTypeTariff"
                            value={values.clientWorkTypeTariff}
                            onChange={onChangeClient}
                            error={errors.clientWorkTypeTariff}
                        />
                    </div>
                </div>
                <NmAccordionV2
                    noStyles={true}
                    className="mb-3 mb-md-4 mb-xxl-5"
                    iconColor={COLOR.PASSIVE_100}
                    title={
                        <Text
                            level="3"
                            color={COLOR.PASSIVE_100}
                        >
                            Дополнительно
                        </Text>
                    }
                >
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label={t("client-new.limit-for-orders-without-provision")}
                        required
                        error={errors.ordersLimit}
                        value={values.ordersLimit}
                        name="ordersLimit"
                        placeholder="Введите лимит для заказов"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Лимит для заказов без обеспечения (НДФЛ), ₽"
                        required
                        error={errors.civilOrdersLimit}
                        value={values.civilOrdersLimit}
                        name="civilOrdersLimit"
                        placeholder="Введите лимит для заказов"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Комиссия компании за выплаты (НДФЛ), %"
                        required
                        error={errors.civilPaymentCommission}
                        value={values.civilPaymentCommission}
                        name="civilPaymentCommission"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Минимальная сумма выплаты без доп. комиссии компании (НПД), ₽"
                        tooltip={
                            <HelpTooltip
                                hover
                                info
                                children="Задайте минимальную сумму выплаты исполнителю, при которой с заказчика не будет списываться заданная дополнительная сумма комиссии"
                            />
                        }
                        error={errors.paymentsThresholdAmount}
                        value={values.paymentsThresholdAmount}
                        name="paymentsThresholdAmount"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Дополнительная сумма комиссии компании за выплаты (НПД), ₽"
                        required
                        error={errors.minCommissionAmount}
                        value={values.minCommissionAmount}
                        name="minCommissionAmount"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Минимальная комиссия компании за выплаты (НДФЛ), ₽"
                        required
                        error={errors.minimalCommissionNdflPaymentsAmount}
                        value={values.minimalCommissionNdflPaymentsAmount}
                        name="minimalCommissionNdflPaymentsAmount"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Комиссия исполнителей за выплаты по заказам (НДФЛ), %"
                        required
                        error={errors.contractorCivilOrderPaymentsCommission}
                        value={values.contractorCivilOrderPaymentsCommission}
                        name="contractorCivilOrderPaymentsCommission"
                        onChange={onChangeClient}
                    />
                    <AmountInput
                        newInput
                        size="xl"
                        className="mb-3 mb-md-4"
                        label="Комиссия исполнителей за реестровые выплаты (НДФЛ), %"
                        required
                        error={errors.contractorCivilRegistryPaymentsCommission}
                        value={values.contractorCivilRegistryPaymentsCommission}
                        name="contractorCivilRegistryPaymentsCommission"
                        onChange={onChangeClient}
                    />
                    <div className="client-list-new__checkbox-container mb-3 mb-md-4">
                        <div className="row gx-4">
                            <div className="col-16 col-md-8">
                                <NmCheckboxV2
                                    label={t("client-new.edmAvailable")}
                                    error={errors.edmAvailable}
                                    checked={values.edmAvailable}
                                    name="edmAvailable"
                                    onChange={onChangeClient}
                                    className="mt-0 mt-md-5"
                                />
                            </div>
                            <div className="col-16 col-md-8 mt-2 mt-md-0">
                                <NmInputV2
                                    size="xl"
                                    label="Тариф, ₽"
                                    required={values.edmAvailable}
                                    disabled={!values.edmAvailable}
                                    name="edmTariff"
                                    value={values.edmTariff}
                                    onChange={onChangeClient}
                                    error={errors.edmTariff}
                                />
                            </div>
                        </div>
                    </div>
                    <NmCheckboxV2
                        label="Без договора"
                        className="mb-3 mb-md-4"
                        disabled={![ADMIN, NM_MANAGER, NM_CONSULTANT].includes(role) || !values.edmAvailable}
                        error={errors.withoutContract}
                        checked={values.withoutContract}
                        name="withoutContract"
                        onChange={onChangeClient}
                    />
                    <div className="row gx-4 mb-3 mb-md-4">
                        <div className="col-16 col-md-8">
                            <NmCheckboxV2
                                label={t("client-new.migrantLicensePayment")}
                                tooltip={
                                    <HelpTooltip
                                        hover
                                        info
                                        children="Модуль подключается совместно с модулем ЭДО"
                                    />
                                }
                                className="mt-0 mt-md-5"
                                error={errors.migrantLicensePaymentEnabled}
                                checked={values.migrantLicensePaymentEnabled}
                                disabled={!values.edmAvailable}
                                name="migrantLicensePaymentEnabled"
                                onChange={onChangeClient}
                            />
                        </div>
                        <div className="col-16 col-md-8 mt-2 mt-md-0">
                            <AmountInput
                                newInput
                                size="xl"
                                label={t("client-new.migrantLicensePaymentCommission")}
                                error={errors.migrantLicensePaymentCommission}
                                value={values.migrantLicensePaymentCommission}
                                name="migrantLicensePaymentCommission"
                                onChange={onChangeClient}
                                disabled={!values.migrantLicensePaymentEnabled}
                            />
                        </div>
                    </div>
                    <NmCheckboxV2
                        disabled
                        className="mb-3 mb-md-4"
                        label={t("client-new.insurance")}
                        error={errors.insuranceAvailable}
                        checked={values.insuranceAvailable}
                        name="insuranceAvailable"
                        onChange={onChangeClient}
                    />
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        label="Оплаты реестрами (НДФЛ)"
                        error={errors.civilRegistryPaymentsAvailable}
                        checked={values.civilRegistryPaymentsAvailable}
                        name="civilRegistryPaymentsAvailable"
                        onChange={onChangeClient}
                    />
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        label="Индивидуальный депозит на проектах и объектах"
                        error={errors.enableIndividualProjectAndObjectDeposit}
                        checked={values.enableIndividualProjectAndObjectDeposit}
                        name="enableIndividualProjectAndObjectDeposit"
                        onChange={onChangeClient}
                    />
                    <NmCheckboxV2
                        className="mb-3 mb-md-4"
                        disabled={!values.enableIndividualProjectAndObjectDeposit}
                        label="Для компании нужен нераспределенный депозит"
                        error={errors.enableNonDistributedClientDeposit}
                        checked={values.enableNonDistributedClientDeposit}
                        name="enableNonDistributedClientDeposit"
                        onChange={onChangeClient}
                    />
                </NmAccordionV2>
                <div className="client-list-new__checkbox-container">
                    {
                        values.isShowForemanFunctionalityAvailable &&
                            <NmCheckboxV2
                                label={t("client-new.foreman-functionality-available")}
                                error={errors.foremanFunctionalityAvailable}
                                checked={values.foremanFunctionalityAvailable}
                                name="foremanFunctionalityAvailable"
                                onChange={onChangeClient}
                            />
                    }
                    <NmCheckboxV2
                        label={t("client-new.payment-by-services")}
                        error={errors.registryPaymentsAvailable}
                        checked={values.registryPaymentsAvailable}
                        name="registryPaymentsAvailable"
                        onChange={onChangeClient}
                        disabled={values.withoutContract}
                    />
                </div>
                <DropzoneV2
                    isVisibleLabel={true}
                    label={t("client-new.logo")}
                    files={values.fileObjThumbnail}
                    onChange={onDropFile("IMPORT_LOGO")}
                    maxSize={10}
                    accept=".png, .jpg, .jpeg, .gif, .bmp"
                    format="*.png, *.jpg, *.jpeg, *.gif, *.bmp"
                />
                <div className="mt-2 mt-md-5 mt-md-7">
                    <ApplyButtons
                        submitBtnContent={t("button.add")}
                        cancelBtnContent={t("button.cancel")}
                        onClose={onClose}
                        submit={handleSubmit}
                        formId="FNS"
                        loading={progress}
                        disabled={progress}
                    />
                </div>
            </NmForm>
        </NmModal>
    );
};

ClientListNew.propTypes = {
    currentApplication: PropTypes.object,
    onClose
    :
    PropTypes.func,
};

export default ClientListNew;
