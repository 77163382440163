import React, {FC, ReactNode} from "react";
import Media from "react-media";

import NmHint from "../NmHint";

import bem from "../../utils/bem";

import "./style.sass";

interface INmFilterAccordion {
    className?: string,
    renderFilter: () => ReactNode,
}

const NmFilterAccordion: FC<INmFilterAccordion> = (props) => {
    const {className, renderFilter} = props;

    const [, element] = bem("nm-filter-accordion", className);

    const renderContent = (mobile: boolean) => {
        return mobile ?
            <NmHint
                className={className || element("hint-filter")}
                closeButtonText="Свернуть фильтр"
                openButtonText="Развернуть фильтр"
            >
                {renderFilter()}
            </NmHint> :
            renderFilter();
    };

    return (
        <Media query={{maxWidth: 767}}>
            {(mobile) => renderContent(mobile)}
        </Media>
    );
};

export default NmFilterAccordion;

