import {all, call, put, select, takeEvery} from "@redux-saga/core/effects";
import axios from "axios";

import {getUserAvatarListThumbnails} from "../../../../fileStore";
import {getRegistryPayments} from "./actionCreators";
import {
    ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR,
    ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST,
    ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS,
    CANCEL_REGISTRY_PAYMENT_ERROR,
    CANCEL_REGISTRY_PAYMENT_REQUEST,
    CANCEL_REGISTRY_PAYMENT_SUCCESS,
    GET_REGISTRY_ERROR,
    GET_REGISTRY_REQUEST,
    GET_REGISTRY_SUCCESS,
    REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
    REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST,
    REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS,
    REGISTRY_PAYMENT_DELETE_ERROR,
    REGISTRY_PAYMENT_DELETE_REQUEST,
    REGISTRY_PAYMENT_DELETE_SUCCESS,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS,
    REGISTRY_PAYMENT_EXPORT_ERROR,
    REGISTRY_PAYMENT_EXPORT_REQUEST,
    REGISTRY_PAYMENT_EXPORT_SUCCESS,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS,
    REGISTRY_PAYMENT_LIST_ERROR,
    REGISTRY_PAYMENT_LIST_REQUEST,
    REGISTRY_PAYMENT_LIST_SUCCESS,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS,
    REGISTRY_PAYMENT_ROW_PAY_ERROR,
    REGISTRY_PAYMENT_ROW_PAY_REQUEST,
    REGISTRY_PAYMENT_ROW_PAY_SUCCESS,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS,
} from "./actions";
import {registryPaymentsPageDataSelector} from "./selectors";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

import {FINANCE_EXPORT_MESSAGES} from "../../../../../constants/messages";
import {REGISTRY_TOAST_TEXT} from "../../../../../constants/registry";

const getController = () => {
    return (
        getBffUrl({
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/registry-payments/registry/registry-card",
            adminRolesUrl: "/adm/clients/client-card/finances/registry-payments/registry/registry-card",
        })
    );
};

const handleResponse = function* (data) {
    const {
        isFetchList,
    } = data;

    if (isFetchList) {
        const state = yield select();

        yield put(getRegistryPayments(registryPaymentsPageDataSelector(state)));
    }
};

const getRegistryByIdSaga = function* ({payload}) {
    try {
        const {
            getResult,
        } = payload;

        const result = yield request.bff.get(`${getController()}/get-registries-rich-by-id`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_REGISTRY_ERROR,
            payload: error,
        });
    }
};

const getRegistryPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get-registry-payment-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {registryPayments} = result;

        if (registryPayments?.length) {
            const contractorIds = registryPayments.map(({contractorId}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: REGISTRY_PAYMENT_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_LIST_ERROR,
            payload: error,
        });
    }
};

const importFromFileRegistryPaymentsSaga = function* ({payload}) {
    const {
        documentTablesEnabled,
        file: data,
        onSuccess,
        onError,
    } = payload;

    try {
        const otherUrl = documentTablesEnabled
            ? "/import-with-table"
            : "/import";
        const result = yield request.bff.post(`${getController()}${otherUrl}`, data, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR,
                payload: errorMessage,
            });

            if (onError) {
                onError();
            }

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError("Не удалось загрузить список исполнителей");

        if (onError) {
            onError();
        }

        yield put({
            type: REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR,
            payload: error,
        });
    }
};

const addContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, payload);

        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            yield put({
                type: REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        toastSuccess("Исполнитель успешно добавлен в реестр");

        yield put({
            type: REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

const updateContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);

        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            yield put({
                type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        toastSuccess("Параметры исполнителя успешно изменены");

        yield put({
            type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

const editContractorToRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            getResult,
        } = payload;

        const result = yield request.bff.put(`${getController()}/edit`, payload);
        const {errorMessage} = result;

        if (getResult) {
            getResult(result);
        }

        if (errorMessage) {
            yield put({
                type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        toastSuccess("Параметры исполнителя успешно изменены");

        yield put({
            type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

const deleteOrCancelRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete-or-cancel-all`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REGISTRY_PAYMENT_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_DELETE_ERROR,
            payload: error,
        });
    }
};

const cancelRegistryPaymentSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const {
            clientId,
            registryId,
            registrySeqNum,
        } = data;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryId}/${registrySeqNum}/cancel`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CANCEL_REGISTRY_PAYMENT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.CANCEL_SUCCESS);

        if (onSuccess) {
            onSuccess();
        }

        yield handleResponse({isFetchList: true});

        yield put({
            type: CANCEL_REGISTRY_PAYMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CANCEL_REGISTRY_PAYMENT_ERROR,
            payload: error,
        });
    }
};

const registryPaymentPaySaga = function* ({payload}) {
    try {
        const {
            data,
            handleError,
        } = payload;

        const result = yield request.bff.post(`${getController()}/pay-payment`, data);
        const {
            errorMessage, 
            warningMessage,
            errorCode,
        } = result;

        if (warningMessage && handleError) {
            handleError(errorCode, warningMessage, errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ROW_PAY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_ROW_PAY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(REGISTRY_TOAST_TEXT.PAY_SUCCESS);

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_PAYMENT_ROW_PAY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_ROW_PAY_ERROR,
            payload: error,
        });
    }
};

const registryPaymentRefreshPerformerSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/refresh-contractors`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        yield put({
            type: REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR,
            payload: error,
        });
    }
};

const registryPaymentHasInProgressSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/has-in-progress`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR,
            payload: error,
        });
    }
};

const getRegistryAvailableForPaymentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get-available-for-payments`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_ERROR,
            payload: error,
        });
    }
};

const exportRegistryPaymentsSaga = function* ({payload}) {
    try {
        const {errorMessage, error, ...result} = yield request.bff.post(`${getController()}/add-export`, payload);

        if (errorMessage || error) {
            yield put({
                type: REGISTRY_PAYMENT_EXPORT_ERROR,
                payload: errorMessage,
            });

            toastError(errorMessage || "Не удалось создать задачу");

            return {
                done: true,
            };
        }

        yield put({
            type: REGISTRY_PAYMENT_EXPORT_SUCCESS,
            payload: result,
        });

        toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_REGISTRY_SUCCESS);
    } catch (error) {
        yield put({
            type: REGISTRY_PAYMENT_EXPORT_ERROR,
            payload: error,
        });
    }
};

const addRegistryPaymentsDocumentsExportSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/documents-export/add`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);
        yield put({type: ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_ERROR, payload: error.message});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_REGISTRY_REQUEST, getRegistryByIdSaga),
        takeEvery(REGISTRY_PAYMENT_LIST_REQUEST, getRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_DELETE_REQUEST, deleteOrCancelRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_ROW_PAY_REQUEST, registryPaymentPaySaga),
        takeEvery(CANCEL_REGISTRY_PAYMENT_REQUEST, cancelRegistryPaymentSaga),
        takeEvery(REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST, addContractorToRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST, updateContractorToRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST, importFromFileRegistryPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST, registryPaymentRefreshPerformerSaga),
        takeEvery(REGISTRY_PAYMENT_HAS_IN_PROGRESS_REQUEST, registryPaymentHasInProgressSaga),
        takeEvery(REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST, editContractorToRegistryPaymentsSaga),
        takeEvery(REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_REQUEST, getRegistryAvailableForPaymentsSaga),
        takeEvery(REGISTRY_PAYMENT_EXPORT_REQUEST, exportRegistryPaymentsSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_REGISTRY_PAYMENTS_REQUEST, addRegistryPaymentsDocumentsExportSaga),
    ]);
}