import React from "react";
import {Route, Switch, withRouter} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import ContractorResources from "../../../contractor/contractor-resources";
import NmContractorList from "../../../contractor/nm-contractor-list";

import {
    LINK_CLIENT_CARD_CONTRACTORS_ON_CHECK,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CONTRACTORS_RESOURCES,
} from "../../../../constants/links";

export const ClientCardContractorsRoute = () => {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_CONTRACTORS_LIST}
                component={NmContractorList}
            />
            <Route
                exact
                path={LINK_CLIENT_CARD_CONTRACTORS_ON_CHECK}
                component={NmContractorList}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_CONTRACTORS_RESOURCES}
                component={ContractorResources}
            />
        </Switch>
    );
};

export default withRouter(ClientCardContractorsRoute);