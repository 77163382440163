import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../../../../../components/ActualComponents/NmCheckboxV2";
import NmDatePicker from "../../../../../components/ActualComponents/NmDatePicker";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import Text from "../../../../../components/ActualComponents/Text";
import {KedoDepartmentsDropdown} from "../../../../../components/KedoDepartmentsDropdown";
import {PositionsDropdown} from "../../../../../components/PositionsDropdown";
import {SubdivisionsDropdown} from "../../../../../components/SubdivisionsDropdown";
import {KedoStaffRegistryCardView} from "../card-view";
import {KedoStaffRegistryEditSubmitButtons} from "../submit-buttons";

import {useKedoStaffRegistryValues} from "../../hooks/useValues";

import {toastSuccess} from "../../../../../utils/toastHelper";
import {validateInnWithYup} from "../../../../../utils/validate";
import {getBirthDateValidation} from "../../../../../utils/yup-vaildations/birthDate";
import {getEmailValidation} from "../../../../../utils/yup-vaildations/email";
import {getSnilsValidation} from "../../../../../utils/yup-vaildations/snils";
import {getKedoStaffInitialValues} from "../../utils/getStaffInitialValues";
import {getKedoStaffRequestData} from "../../utils/getStaffRequestData";

import {citizenshipsDict} from "../../../../../constants/citizenships";
import {COLOR} from "../../../../../constants/color";
import {REG_EXP} from "../../../../../constants/regExp";
import {KEDO_CLIENT_USER} from "../../../../../constants/roles";
import {requiredMessage} from "../../../../../constants/validationRules";
import {KEDO_STAFF_REGISTRY_INPUT_TYPE} from "../../constants";

import {addKedoStaff, updateKedoStaff} from "../../../../../ducks/kedo/staff/actionCreators";
import {bffKedoStaffProgressActionSelector} from "../../../../../ducks/kedo/staff/selectors";

export const KedoStaffRegistryAddManual = (props) => {
    const {
        kedoStaffId,
        clientId,
        card,
        children,
        onClose,
        refFooter,
        fetchList,
        isEdit,
        isViewMode,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffKedoStaffProgressActionSelector);

    const {
        fields,
        getFields,
    } = useKedoStaffRegistryValues({
        clientId,
        isEdit,
    });

    const initialValues = getKedoStaffInitialValues({
        fields,
        card,
        isEdit,
    });

    const {
        handleSubmit,
        values,
        setFieldValue,
        setValues,
        touched,
        setFieldTouched,
        errors,
    } = useFormik({
        onSubmit,
        initialValues,
        enableReinitialize: true,
        validationSchema: yup.object({
            firstName: yup.string()
                .required(requiredMessage)
                .matches(
                    REG_EXP.NAME,
                    "Только кириллица, пробелы и знаки -",
                ).max(50, "Не более 50 символов"),
            login: yup.string()
                .required(requiredMessage)
                .matches(
                    REG_EXP.LOGIN,
                    "Ввод латиницы, цифр, знаков ._-@",
                )
                .min(6, "Минимальная длина строки 6 символов")
                .max(255, "Не более 255 символов"),
            lastName: yup.string()
                .required(requiredMessage)
                .matches(
                    REG_EXP.NAME,
                    "Только кириллица, пробелы и знаки -",
                ).max(50, "Не более 50 символов"),
            patronymic: yup.string()
                .matches(
                    REG_EXP.NAME,
                    "Только кириллица, пробелы и знаки -",
                ).max(50, "Не более 50 символов"),
            snils: getSnilsValidation(),
            address: yup.string().max(255, "Не более 255 символов"),
            inn: yup.string().test("inn", "Неверный формат ИНН", validateInnWithYup),
            birthDate: getBirthDateValidation(),
            idDocIssuedBy: yup.string().max(255, "Не более 255 символов"),
            birthPlace: yup.string().max(255, "Не более 255 символов"),
            idDocNumber: yup.string()
                .when("citizenship", {
                    is: citizenshipsDict.RU.value,
                    then: () => yup.string()
                        .length(11, "Допустимое количество символов 10"),
                })
                .when("citizenship", {
                    is: (value) => ![
                        citizenshipsDict.RU.value,
                        citizenshipsDict.NOT_SPECIFIED.value,
                    ].includes(value),
                    then: () => yup.string()
                        .min(5, "Минимальная длина номера 5 символов")
                        .max(20, "Не более 20 символов")
                        .matches(REG_EXP.ID_DOC_NUMBER, "Ввод только латинских букв и цифр"),
                }),
            email: getEmailValidation(),
            role: yup.string().required(requiredMessage),
            kedoDepartmentId: yup.string().required(requiredMessage),
            phone: yup.string().min(8, "Минимальная длина номера 8 символов"),
        }),
        validateOnBlur: false,
    });

    function onSubmit(values) {
        const requestData = getKedoStaffRequestData(values);

        if (isEdit) {
            dispatch(updateKedoStaff({
                clientId,
                kedoStaffId,
                ...requestData,
                onSuccess: () => {
                    toastSuccess("Данные сотрудника успешно отредактированы");

                    onClose();
                    fetchList();
                },
            }));

            return;
        }

        dispatch(addKedoStaff({
            clientId,
            ...requestData,
            onSuccess: () => {
                toastSuccess("Сотрудник успешно добавлен");

                onClose();
                fetchList();
            },
        }));
    }

    const getFieldDisabled = (field) => {
        if (field.name === "ukepAvailable") {
            return field.disabled || values.role === KEDO_CLIENT_USER;
        }

        return field.disabled;
    };

    const handleChange = (event, {name, value, checked}) => {
        if (
            name === "role"
            && value === KEDO_CLIENT_USER
        ) {
            setValues(prevstate => ({
                ...prevstate,
                [name]: value,
                ukepAvailable: false,
            }));

            return;
        }

        setFieldValue(name, typeof checked === "boolean" ? checked : value);
    };

    const getControl = (item) => {
        if (!item.name) {
            return null;
        }

        switch (item.type) {
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.CHECKBOX:
                return (
                    <NmCheckboxV2
                        name={item.name}
                        checked={Boolean(values[item.name])}
                        onChange={handleChange}
                        disabled={getFieldDisabled(item)}
                        label={item.label}
                        isVisibleTooltip={Boolean(item.tooltipText)}
                        tooltip={
                            <HelpTooltip
                                info
                                type="light"
                                position="bottom-left"
                                hover
                            >
                                {item.tooltipText}
                            </HelpTooltip>
                        }
                    />
                );
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT:
                return (
                    <NmInputV2
                        name={item.name}
                        size="xl"
                        required={item.required}
                        label={item.label}
                        value={values[item.name]}
                        placeholder={item.placeholder}
                        onChange={handleChange}
                        mask={item.mask}
                        maskChar={item.maskChar}
                        isDisabled={false}
                        error={
                            touched && touched[item.name] && errors ?
                                errors[item.name] :
                                undefined
                        }
                        onBlur={() => {
                            setFieldTouched(item.name, true);
                        }}
                        isVisibleTooltip={Boolean(item.tooltipText)}
                        tooltip={
                            <HelpTooltip
                                info
                                type="light"
                                position="bottom-left"
                                hover
                            >
                                {item.tooltipText}
                            </HelpTooltip>
                        }
                    />
                );
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.DATE:
                return (
                    <NmDatePicker
                        name={item.name}
                        required={item.required}
                        label={item.label}
                        maxDate={item.maxDate}
                        dateFormat="dd.MM.yyyy"
                        dateFormatMask="99.99.9999"
                        selected={values[item.name]}
                        onChange={handleChange}
                        error={
                            touched && touched[item.name] && errors ?
                                errors[item.name] :
                                undefined
                        }
                    />
                );
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.POSITIONS:
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.SUBDIVISIONS:
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS:
            case KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN:
                const COMPONENT_MAP = {
                    [KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN]: NmDropdownV2,
                    [KEDO_STAFF_REGISTRY_INPUT_TYPE.SUBDIVISIONS]: SubdivisionsDropdown,
                    [KEDO_STAFF_REGISTRY_INPUT_TYPE.POSITIONS]: PositionsDropdown,
                    [KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS]: KedoDepartmentsDropdown,
                };

                const Component = COMPONENT_MAP[item.type];

                return (
                    <Component
                        {...item}
                        disabled={
                            isEdit
                            && [KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS].includes(item.type)
                        }
                        required={[KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS].includes(item.type)}
                        initialOption={
                            item.text
                            && typeof item.text === "string"
                            && [
                                KEDO_STAFF_REGISTRY_INPUT_TYPE.POSITIONS,
                                KEDO_STAFF_REGISTRY_INPUT_TYPE.SUBDIVISIONS,
                                KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS,
                            ].includes(item.type)
                                ? {
                                    key: values[item.name],
                                    value: values[item.name],
                                    text: item.text,
                                }
                                : undefined
                        }
                        value={values[item.name]}
                        error={
                            touched && touched[item.name] && errors ?
                                errors[item.name] :
                                undefined
                        }
                        onBlur={() => {
                            setFieldTouched(item.name, true);
                        }}
                        onChange={handleChange}
                        isVisibleTooltip={Boolean(item.tooltipText)}
                        tooltip={
                            <HelpTooltip
                                info
                                position="bottom"
                                hover
                            >
                                {item.tooltipText}
                            </HelpTooltip>
                        }
                    />
                );
            default:
                return;
        }
    };

    const getAutoFields = () => {
        const fields = getFields({
            citizenship: values.citizenship,
        });

        return fields.map((item) => {
            return (
                <div key={item.header}>
                    <Text
                        color={COLOR.BLACK_80}
                        level="2"
                        type="sub"
                        medium={true}
                        className="mb-4"
                    >
                        {item.header}
                    </Text>
                    <div className="row">
                        {
                            item.items
                                .filter(({isEdit = true}) => isEdit)
                                .map((item) => {
                                    const {
                                        className = "col-16",
                                    } = item;

                                    const _className = `${className} mb-3 mb-md-4`;

                                    if (item.editFields) {
                                        return item.editFields.map(item => {
                                            return (
                                                <div
                                                    key={item.label}
                                                    className={_className}
                                                >
                                                    {getControl(item)}
                                                </div>
                                            );
                                        });
                                    }

                                    return (
                                        <div
                                            key={item.label}
                                            className={_className}
                                        >
                                            {getControl(item)}
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            );
        });
    };

    if (isViewMode) {
        return <KedoStaffRegistryCardView />;
    }

    return (
        <div>
            {getAutoFields()}
            {children}
            <KedoStaffRegistryEditSubmitButtons
                disabled={progressAction}
                onSubmit={handleSubmit}
                refFooter={refFooter}
                onClose={onClose}
            />
        </div>
    );
};
