import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {removePhoneMask} from "../../../../../../../utils/stringFormat";

import {CONTRACTOR_SEARCH_TYPE} from "../../../../../../../constants/registry";

import {
    addContractorToRegistryInvitations,
    updateRegistryInvitationsContractor,
} from "../../../../../../../ducks/bff/orders/order-invitations-registry/card/actionCreators";
import {getContractorList} from "../../../../../../../ducks/contractor";

const useDataForm = ({editItem, clientId, registryInvitationId, isEdit, fetchList, onClose, list, totalCount, progressSearch, pageNum}) => {
    const [isSearchContractor, setIsSearchContractor] = useState(false);
    const [isContractorsListFrom, setIsContractorsListFrom] = useState(false);

    const dispatch = useDispatch();

    const initialValues = {
        phoneNumber: "",
        fullName: "",
        orderNum: "",
        searchType: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
        ...editItem,
    };

    useEffect(() => {
        if (isSearchContractor && !progressSearch) {
            if (totalCount === 0) {
                const name = values.searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? "phoneNumber" : "fullName";
                const errorText = values.searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? "Исполнитель с данным номером не найден" : "Исполнитель с данным ФИО не найден";

                setErrors({
                    [name]: errorText,
                });

                return;
            }

            if (totalCount === 1) {
                const [{contractorId}] = list;

                submitRegistryItem(contractorId);

                return;
            }

            setIsContractorsListFrom(true);
        }
    }, [list]);

    useEffect(() => {
        if (isSearchContractor) {
            searchContractor();
        }
    }, [pageNum]);

    const onClear = () => {
        setValues(initialValues);
    };

    const submitRegistryItem = (contractorId) => {
        if (!isEdit) {
            dispatch(addContractorToRegistryInvitations({
                orderNum: values.orderNum,
                contractorId,
                registryInvitationId,
                clientId,
                onSuccess: () => {
                    fetchList();
                    closeEditModal();
                },
            }));

            return;
        }

        dispatch(updateRegistryInvitationsContractor({
            orderNum: values.orderNum,
            contractorId,
            registryInvitationId,
            clientId,
            serialNumber: editItem.baseModel.serialNumber,
            onSuccess: () => {
                fetchList();
                closeEditModal();
            },
        }));
    };

    const searchContractor = () => {
        if (!isValid) {
            return;
        }

        const {
            searchType,
            phoneNumber,
            fullName,
        } = values;

        const filterType = searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? "phoneFilter" : "fioFilter";
        const filterValue = searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ? removePhoneMask(phoneNumber) : fullName.trim();

        dispatch(getContractorList({
            [filterType]: filterValue,
            sortType: "FIO_ASC",
            pageSize: 5,
            pageNum,
            clientId,
        }));

        setIsSearchContractor(true);
    };

    const closeEditModal = () => {
        setIsSearchContractor(false);
        setIsContractorsListFrom(false);
        onClose();
    };

    const {
        handleSubmit,
        values,
        setFieldValue,
        errors,
        isValid,
        handleChange,
        setValues,
        touched,
        setFieldTouched,
        setErrors,
    } = useFormik({
        onSubmit: searchContractor,
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: false,
    });


    return {
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        onClear,
        touched,
        setFieldTouched,
        setIsSearchContractor,
        isContractorsListFrom,
        setIsContractorsListFrom,
        searchContractor,
        submitRegistryItem,
    };
};

export default useDataForm;