import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    clearKedoDepartmentsStaffStore,
    getKedoDepartmentsStaff,
} from "../../../../ducks/kedo/departments/staff/actionCreators";

export const useKedoDepartmentsStaffFetch = (params) => {
    const {
        pageSize,
        pageNum,
        filterData,
        rootDepartment,
        clientId,
        kedoDepartmentId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
        kedoDepartmentId,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearKedoDepartmentsStaffStore());
        };
    }, []);

    const fetchList = () => {
        dispatch(getKedoDepartmentsStaff({
            ...filterData,
            pageSize,
            pageNum,
            clientIdFilter: clientId,
            kedoDepartmentId: kedoDepartmentId || rootDepartment?.rootDepartmentId,
        }));
    };

    return null;
};