import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../components/ApplyButtons";
import NmTitle from "../../../components/NmTitle";

import {handleFormString, handleNumber} from "../../../utils/stringHelper";
import validate from "../../../utils/validate";

import {PROJECT_FIELD_NAME} from "../../../constants/projects";
import {projectRule} from "../../../constants/validationRules";

import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";
import {addClientProject, updateClientProject} from "../../../ducks/bff/clients/projects/actionCreators";
import {getClientProjectCard} from "../../../ducks/bff/clients/projects/card/actionCreators";
import {
    clientProjectCardInfoSelector,
    clientProjectCardProgressSelector,
} from "../../../ducks/bff/clients/projects/card/selectors";
import {
    updateFieldsStoreProject,
} from "../../../ducks/projects";

import PropTypes from "prop-types";

class ProjectEditModal extends React.Component {
    static propTypes = {
        projectData: PropTypes.object.isRequired,
        isEdit: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
    };

    static defaultProps = {
        projectData: {},
        isEdit: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            projectForm: {},
            error: {},
        };
    }

    componentDidMount() {
        const {isEdit} = this.props;
        if (isEdit) {
            this.getProject();
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {
            project,
            isEdit,
        } = props;

        const {projectForm} = state;

        if (isEdit && project.projectId && project.projectId !== projectForm.projectId) {
            return {
                ...state,
                projectForm: {...project},
            };
        }

        return null;
    }

    get localizationData() {
        const {
            t,
            isEdit,
        } = this.props;

        return {
            title: isEdit ? t("projects-edit-modal.edit-title") : t("projects-edit-modal.create-title"),
            paramsTitle: t("projects-edit-modal.sub-title-params"),
            nameTitle: t("projects-edit-modal.name-title"),
            descriptionTitle: t("projects-edit-modal.sub-title-description"),
            budgetLabel: t("projects-edit-modal.budget-label"),
            budgetLabelCheckbox: t("projects-edit-modal.budget-label-checkbox"),
            namePlaceholder: t("projects-edit-modal.name-placeholder"),
            descriptionPlaceholder: t("projects-edit-modal.description-placeholder"),
            loader: t("loader.content"),
        };
    }

    getProject() {
        const {
            getClientProjectCard,
            projectData: {
                projectId,
                clientId,
            },
        } = this.props;

        getClientProjectCard({
            projectId,
            clientId,
        });
    }

    handleChange = (e, {value, name, checked}) => {
        if (name === PROJECT_FIELD_NAME.DESCRIPTION) {
            if (value.length === 2001) {
                return;
            }

            if (value.length > 2000) {
                value = value.slice(0, 2000);
            }
        }

        this.setState(prevState => ({
            ...prevState,
            projectForm: {
                ...prevState.projectForm,
                [name]: value || checked,
            },
        }));
    };

    isValidForm = () => {
        const {projectForm} = this.state;

        const error = validate(projectForm, projectRule, "");

        this.setState({
            error,
        });

        return Object.keys(error).length === 0;
    };

    submit = () => {
        const {projectForm} = this.state;
        const {
            addClientProject,
            updateClientProject,
            isEdit,
            projectData,
            project,
            onClose,
            isGetCard,
        } = this.props;

        const isValid = this.isValidForm();

        if (!isValid) {
            return;
        }

        const data = {
            [PROJECT_FIELD_NAME.CLIENT_ID]: projectData[PROJECT_FIELD_NAME.CLIENT_ID],
            [PROJECT_FIELD_NAME.NAME]: handleFormString(projectForm[PROJECT_FIELD_NAME.NAME]),
            [PROJECT_FIELD_NAME.DESCRIPTION]: handleFormString(projectForm[PROJECT_FIELD_NAME.DESCRIPTION]),
            [PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID]: handleNumber(projectForm[PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID]),
            enableIndividualDeposit: projectForm.enableIndividualDeposit,
        };

        if (isEdit) {
            updateClientProject({
                ...project,
                ...data,
                isGetList: !isGetCard,
                isGetCard,
            });
        } else {
            addClientProject(data);
        }

        onClose();
    };

    renderActions() {
        const {onClose, t} = this.props;

        return (
            <ApplyButtons
                submitBtnContent={t("button.save")}
                cancelBtnContent={t("button.cancel")}
                onClose={onClose}
                submit={this.submit}
            />
        );
    }

    render() {
        const {
            projectForm,
            error,
        } = this.state;
        const {
            onClose,
            progressCard,
            clientProperties: {
                crowdTasksAvailable,
                enableIndividualProjectAndObjectDeposit,
            },
        } = this.props;
        const {
            title,
            descriptionTitle,
            namePlaceholder,
            nameTitle,
            descriptionPlaceholder,
        } = this.localizationData;

        return (
            <NmModal
                size="md"
                onClose={onClose}
                footer={this.renderActions()}
                header={
                    <NmTitle size="lg">
                        {title}
                    </NmTitle>
                }
                loaderInverted
                loading={progressCard}
            >
                <NmForm addMargin>
                    <NmInputV2
                        size="xl"
                        error={error[PROJECT_FIELD_NAME.NAME]}
                        name={PROJECT_FIELD_NAME.NAME}
                        placeholder={namePlaceholder}
                        value={projectForm[PROJECT_FIELD_NAME.NAME]}
                        onChange={this.handleChange}
                        label={nameTitle}
                        required
                    />
                    <NmTextareaV2
                        maxLength={2000}
                        name={PROJECT_FIELD_NAME.DESCRIPTION}
                        onChange={this.handleChange}
                        value={projectForm[PROJECT_FIELD_NAME.DESCRIPTION]}
                        label={descriptionTitle}
                        placeholder={descriptionPlaceholder}
                    />
                    {
                        crowdTasksAvailable &&
                        <NmInputV2
                            size="xl"
                            name={PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID}
                            placeholder="Введите идентификатор"
                            value={projectForm[PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID]}
                            error={error[PROJECT_FIELD_NAME.OPTIMUM_PROJECT_ID]}
                            onChange={this.handleChange}
                            label="Идентификатор проекта в системе Оптимум"
                            mask="9999999999"
                            maskChar={null}
                        />
                    }
                    {
                        enableIndividualProjectAndObjectDeposit &&
                        <NmCheckboxV2
                            label="Индивидуальный депозит проекта"
                            checked={projectForm.enableIndividualDeposit}
                            name="enableIndividualDeposit"
                            onChange={this.handleChange}
                        />
                    }
                </NmForm>
            </NmModal>
        );
    }
}

export default connect(
    state => ({
        project: clientProjectCardInfoSelector(state),
        progressCard: clientProjectCardProgressSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
    }),
    {
        addClientProject,
        getClientProjectCard,
        updateClientProject,
        updateFieldsStoreProject,
    },
)(withTranslation()(ProjectEditModal));