import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {edoStaffListToOptions} from "../../../../../utils/objectHelper";

export const edmStaffSelector = state => state.bff.clientsEdoStaff;
export const edmStaffCardSelector = createSelector(edmStaffSelector, ({staffCard}) => staffCard);
export const edmStaffCardProgressSelector = createSelector(edmStaffSelector, ({gettingStaffProgress}) => gettingStaffProgress);
export const edmStaffActionProgressSelector = createSelector(edmStaffSelector, ({actionProgress}) => actionProgress);
export const edmStaffListSelector = createSelector(edmStaffSelector, ({list}) => list);
export const edmStaffListOptionsSelector = createSelector(edmStaffSelector, ({list}) => edoStaffListToOptions(list));
export const edmStaffListProgressSelector = createSelector(edmStaffSelector, ({listProgress}) => listProgress);
export const edmStaffListTotalCountSelector = createSelector(edmStaffSelector, ({totalCount}) => totalCount);
export const edmStaffListTotalPagesSelector = createSelector(edmStaffSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const edmStaffFindingContractorListSelector = createSelector(edmStaffSelector, ({findingContractorsList}) => findingContractorsList);
export const edmStaffFindingContractorTotalPagesSelector = createSelector(edmStaffSelector,
    ({findingContractorsTotalCount, findingContractorsPageData: {pageSize = 0}}) => getTotalPages(findingContractorsTotalCount, pageSize));
export const edmStaffFindingUserListSelector = createSelector(edmStaffSelector, ({findingUsersList}) => findingUsersList);
export const edmStaffFindingUserProgressSelector = createSelector(edmStaffSelector, ({findingUsersProgress}) => findingUsersProgress);
export const edmStaffFindingUserTotalPagesSelector = createSelector(edmStaffSelector,
    ({findingUsersTotalCount, findingUsersPageData: {pageSize = 0}}) => getTotalPages(findingUsersTotalCount, pageSize));
export const clientUserStaffExistSelector = createSelector(edmStaffSelector, ({clientUserStaffExist}) => clientUserStaffExist);