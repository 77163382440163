import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import Tabs from "../../../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../../../components/CheckboxList";
import OrderHistoryFilter from "../filter";

import {useOrderHistoryPage} from "../../hooks/useOrderHistoryPage";

import formatDate, {
    convertUtcToLocal,
    getEndFilterDateWithTimeInUtc,
    getStartFilterDateWithTimeInUtc,
} from "../../../../../utils/dateFormat";
import {removePhoneMask} from "../../../../../utils/stringFormat";

import {getOrderHistory, updateStoreOrders} from "../../../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    getOrderHistorySelector,
    orderHistoryTotalCountSelector,
    orderHistoryTotalPagesSelector,
} from "../../../../../ducks/bff/orders/orders-registry/selectors";

const OrderHistory = (props) => {
    const {
        tabs,
        orderId,
    } = props;

    const dispatch = useDispatch();

    const totalCount = useSelector(orderHistoryTotalCountSelector);
    const totalPages = useSelector(orderHistoryTotalPagesSelector);
    const list = useSelector(getOrderHistorySelector);

    const onClearStore = () => {
        dispatch(updateStoreOrders({
            historyPageData: {},
            historyTotalCount: 0,
            historyList: [],
            historyProgress: false,
        }));
    };
    const onFetchList = (data) => {
        dispatch(getOrderHistory(data));
    };

    const {
        filter,
        filterData,
        pageNum,
        pageSize,
        onSearch,
        onClear,
        onChangeFilter,
        onChangePageSize,
        onPaginationChange,
    } = useOrderHistoryPage();

    useEffect(() => {
        fetchHistory();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            onClearStore();
        };
    }, []);

    const fetchHistory = () => {
        const {
            contractorFioFilter,
            contractorPhoneFilter,
            logEndDateFilter,
            logStartDateFilter,
        } = filterData;

        const requestData = {
            pageSize,
            pageNum,
            orderId,
            dateTimeFromFilter: getStartFilterDateWithTimeInUtc(logStartDateFilter),
            dateTimeToFilter: getEndFilterDateWithTimeInUtc(logEndDateFilter),
            initiatorNameFilter: isEmpty(contractorFioFilter) ? undefined : contractorFioFilter,
            initiatorPhoneFilter: isEmpty(contractorPhoneFilter) ? undefined : removePhoneMask(contractorPhoneFilter),
        };

        onFetchList({
            ...requestData,
        });
    };

    const getCards = () => {
        return list.map(item => {
            const title = item.title;
            const labels = [
                {
                    label: "Дата и время",
                    text: formatDate(convertUtcToLocal(item.dateTime), "dd.MM.yyyy HH:mm:ss"),
                    noWrap: false,
                    columnOnMobile: true,
                },
                {
                    label: "Инициатор",
                    text: item.initiatorName,
                    noWrap: false,
                    columnOnMobile: true,
                },
                {
                    label: "Новый статус",
                    text: item.newOrderStatusValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.newOrderStatusValue),
                },
                {
                    label: "Статус",
                    text: item.disputeStatusValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.disputeStatusValue),
                },
                {
                    label: "Причина",
                    text: item.reasonValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.reasonValue),
                },
                {
                    label: "Самозанятый",
                    text: item.contractorNameValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.contractorNameValue),
                },
                {
                    label: "Телефон",
                    text: item.contractorPhoneValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.contractorPhoneValue),
                },
                {
                    label: "Заказ по шаблону",
                    text: item.orderByTemplateValue,
                    noWrap: false,
                    columnOnMobile: true,
                    isHide: isEmpty(item.orderByTemplateValue),
                },
            ];

            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={title}
                        labels={labels}
                    />
                ),
            };
        });
    };

    const getContent = () => {
        if (isEmpty(list)) {
            return <NmEmptyPageV2 title="По заданным фильтрам записей не найдено" />;
        }

        return <CheckboxList rows={getCards()} />;
    };

    return (
        <NmForm addMargin={true}>
            <Tabs
                panes={tabs}
                className="mb-4 mb-md-6"
            />
            <OrderHistoryFilter
                filter={filter}
                onChangeFilter={onChangeFilter}
                onSearch={onSearch}
                onClear={onClear}
            />
            {getContent()}
            <NmPagination
                className="mt-2 mt-md-6 mt-xxl-8"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePagination={onPaginationChange}
                onChangePageSize={onChangePageSize}
            />
        </NmForm>
    );
};

export default OrderHistory;
