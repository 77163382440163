import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getCountReviewsClient, getPageReviewsClient} from "../../../ducks/bff/reviews/client/actionCreators";
import {
    reviewsClientListSelector,
    reviewsClientProgressActionSelector,
    reviewsClientProgressSelector,
    reviewsClientTotalCountSelector,
    reviewsClientTotalPagesSelector,
} from "../../../ducks/bff/reviews/client/selectors";
import {
    getCountReviewsContractor,
    getPageReviewsContractor,
} from "../../../ducks/bff/reviews/contractor/actionCreators";
import {
    reviewsContractorListSelector,
    reviewsContractorProgressActionSelector,
    reviewsContractorProgressSelector,
    reviewsContractorTotalCountSelector,
    reviewsContractorTotalPagesSelector,
} from "../../../ducks/bff/reviews/contractor/selectors";

import {SUBPAGE_CLIENTS_REVIEWS} from "../../../constants/link-params";

export function useReviewsFetchList({pageSize, pageNum, filter, subpage}) {
    const listClient = useSelector(reviewsClientListSelector);
    const totalCountClient = useSelector(reviewsClientTotalCountSelector);
    const totalPagesClient = useSelector(reviewsClientTotalPagesSelector);
    const progressPageClient = useSelector(reviewsClientProgressSelector);
    const progressClientAction = useSelector(reviewsClientProgressActionSelector);
    const listContractor = useSelector(reviewsContractorListSelector);
    const totalCountContractor = useSelector(reviewsContractorTotalCountSelector);
    const totalPagesContractor = useSelector(reviewsContractorTotalPagesSelector);
    const progressPageContractor = useSelector(reviewsContractorProgressSelector);
    const progressContractorAction = useSelector(reviewsContractorProgressActionSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum, subpage]);

    useEffect(() => {
        getContractorReviewsCount();
        getClientReviewsCount();
    }, []);


    const fetchList = () => {
        if (subpage === SUBPAGE_CLIENTS_REVIEWS) {
            dispatch(getPageReviewsClient({
                pageNum,
                pageSize,
                emptyReviewText: false,
                ...filter,
            }));

            return;
        }

        dispatch(getPageReviewsContractor({
            pageNum,
            pageSize,
            emptyReviewText: false,
            ...filter,
        }));
    };

    const getContractorReviewsCount = () => {
        dispatch(getCountReviewsContractor({
            emptyReviewText: false,
        }));
    };

    const getClientReviewsCount = () => {
        dispatch(getCountReviewsClient({
            emptyReviewText: false,
        }));
    };

    const getListByTab = () => {
        if (subpage === SUBPAGE_CLIENTS_REVIEWS) {
            return ({
                list: listClient,
                totalCount: totalCountClient,
                totalPages: totalPagesClient,
                progressPage: progressPageClient,
                progressAction: progressClientAction,
            });
        }

        return ({
            list: listContractor.map(item => ({
                contractorScore: true,
                ...item,
            })),
            totalCount: totalCountContractor,
            totalPages: totalPagesContractor,
            progressPage: progressPageContractor,
            progressAction: progressContractorAction,
        });
    };

    const {
        list,
        totalCount,
        totalPages,
        progressPage,
        progressAction,
    } = getListByTab();

    return {
        fetchList,
        totalCountContractor,
        totalCountClient,
        list,
        totalCount,
        totalPages,
        progressPage,
        progressAction,
        getContractorReviewsCount,
        getClientReviewsCount,
    };
}