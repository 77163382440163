import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_PROMOTION_CHANNELS_CARD_GET_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_SUCCESS,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST,
    BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/promotion-channels/card";

// GET /bff/adm/promotion-channels/card/get
// Получение обогащенной информации о странице промокода
const getPromotionChannelsCard = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${controller}/get`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_CARD_GET_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_GET_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_GET_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/promotion-channels/card/update
// Обновление канала привлечений
const updatePromotionChannelsCard = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/promotion-channels/card/update-promocode
// Обновление конкретного промокода
const updatePromotionChannelsCardPromocode = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${controller}/update-promocode`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/promotion-channels/card/get-page-promocode
// Получение информации о странице промокода
const getPagePromotionChannelsCardPromocode = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/get-page-promocode`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR, payload: errorMessage});
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_PROMOTION_CHANNELS_CARD_GET_REQUEST, getPromotionChannelsCard),
        takeEvery(BFF_PROMOTION_CHANNELS_CARD_UPDATE_REQUEST, updatePromotionChannelsCard),
        takeEvery(BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_UPDATE_REQUEST, updatePromotionChannelsCardPromocode),
        takeEvery(BFF_PROMOTION_CHANNELS_CARD_PROMOCODE_GET_PAGE_REQUEST, getPagePromotionChannelsCardPromocode),
    ]);
}