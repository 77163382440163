import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffFinancesPaidApisSelector = state => state.bff.paidApis;
export const bffFinancesPaidApisListSelector = createSelector(bffFinancesPaidApisSelector, ({list}) => list);
export const bffFinancesPaidApisTotalPagesSelector = createSelector(bffFinancesPaidApisSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffFinancesPaidApisTotalCountSelector = createSelector(bffFinancesPaidApisSelector, ({totalCount}) => totalCount);
export const bffFinancesPaidApisTotalAmountSelector = createSelector(bffFinancesPaidApisSelector, ({totalAmount}) => totalAmount);
export const bffFinancesPaidApisSettingsInfoSelector = createSelector(bffFinancesPaidApisSelector, ({settingsInfo}) => settingsInfo);
export const bffFinancesPaidApisProgressSelector = createSelector(bffFinancesPaidApisSelector, ({progress}) => progress);
export const bffFinancesPaidApisProgressSettingsSelector = createSelector(bffFinancesPaidApisSelector, ({progressSettings}) => progressSettings);
export const bffFinancesPaidApisProgressActionSelector = createSelector(bffFinancesPaidApisSelector, ({progressAction}) => progressAction);
export const bffFinancesPaidApisErrorSelector = createSelector(bffFinancesPaidApisSelector, ({error}) => error);