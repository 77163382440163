import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import {UkepIndicator} from "../../../../components/ActualComponents/UkepIndicator";
import {CitizenshipWithFlag} from "../../../../components/CitizenshipWithFlag";

import dateFormat from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {citizenshipsDict} from "../../../../constants/citizenships";
import {GENDER_DICT, GENDER_OPTIONS} from "../../../../constants/contractorInfo";
import {MASK_PHONE_FOREIGN} from "../../../../constants/mask";
import {ADMIN, CLIENT_ADMIN, isClientUser, NM_MANAGER} from "../../../../constants/roles";
import {KEDO_STAFF_REGISTRY_INPUT_TYPE} from "../constants";

import {
    bffCommonDictsKedoRoleDictOptionsSelector,
    bffCommonDictsKedoRoleDictSelector,
    bffCommonDictsPositionsOptionsSelector,
    bffCommonDictsSubdivisionsOptionsSelector,
} from "../../../../ducks/bff/common/dicts/selectors";
import {
    getCitizenshipSelector,
    getCitizenshipWithFlagsOptionsSelector,
} from "../../../../ducks/contractor";
import {bffKedoStaffCardSelector} from "../../../../ducks/kedo/staff/selectors";

export const useKedoStaffRegistryValues = (params = {}) => {
    const {
        isSort = true,
        clientId,
        isEdit,
    } = params;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const card = useSelector(bffKedoStaffCardSelector);

    const citizenshipDict = useSelector(getCitizenshipSelector);
    const roleDict = useSelector(bffCommonDictsKedoRoleDictSelector);

    const citizenshipOptions = useSelector(getCitizenshipWithFlagsOptionsSelector);
    const subdivisionsOptions = useSelector(bffCommonDictsSubdivisionsOptionsSelector);
    const positionsOptions = useSelector(bffCommonDictsPositionsOptionsSelector);
    const roleOptions = useSelector(bffCommonDictsKedoRoleDictOptionsSelector);

    const getFields = (params) => {
        const {
            citizenship,
        } = params;

        const fields= [
            {
                header: "Учетные данные",
                position: 5,
                items: [
                    {
                        label: "Роль",
                        text: roleDict[card.role],
                        additionalContent: card.ukepAvailable && <UkepIndicator />,
                        editFields: [
                            {
                                label: "Роль",
                                name: "role",
                                required: true,
                                type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN,
                                placeholder: "Выберите роль",
                                options: roleOptions,
                            },
                            {
                                type: KEDO_STAFF_REGISTRY_INPUT_TYPE.CHECKBOX,
                                disabled: isClientRole,
                                name: "ukepAvailable",
                                label: "Может подписывать документы УКЭП компании",
                                tooltipText: "Для активации данного признака обратитесь к администрации Наймикс",
                            },
                        ],
                    },
                    {
                        label: "Логин",
                        name: "login",
                        text: card.login,
                        placeholder: "Введите логин",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        required: true,
                        tooltipText: !isEdit && "Пароль будет сгенерирован автоматически и отправлен на указанную почту",
                    },
                    {
                        label: "Добавлен",
                        name: "createdAt",
                        text: card.createdAt ? dateFormat(card.createdAt, "dd.MM.yyyy") : "",
                        isEdit: false,
                    },
                    isEdit && {
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.CHECKBOX,
                        disabled: ![ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(role),
                        name: "generateAndSendPassword",
                        label: "Сгенерировать автоматически и отправить на почту",
                    },
                ].filter(Boolean),
            },
            {
                header: "Персональные данные",
                position: 1,
                items: [
                    {
                        label: "ФИО",
                        text: getFullName(
                            card.lastName,
                            card.firstName,
                            card.patronymic,
                        ),
                        editFields: [
                            {
                                label: "Фамилия",
                                name: "lastName",
                                placeholder: "Введите фамилию",
                                type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                                required: true,
                            },
                            {
                                label: "Имя",
                                name: "firstName",
                                placeholder: "Введите имя",
                                type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                                required: true,
                            },
                            {
                                label: "Отчество",
                                name: "patronymic",
                                placeholder: "Введите отчество",
                                type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                            },
                        ],
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                    },
                    {
                        label: "СНИЛС",
                        text: card.snils,
                        name: "snils",
                        placeholder: "Введите СНИЛС",
                        mask: "999-999-999 99",
                        maskChar: null,
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        required: true,
                        className: "col-16 col-md-8",
                    },
                    {
                        label: "ИНН",
                        text: card.inn,
                        name: "inn",
                        placeholder: "Введите ИНН",
                        mask: "999999999999",
                        maskChar: null,
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        className: "col-16 col-md-8",
                    },
                ],
            },
            {
                header: "Контактные данные",
                position: 2,
                items: [
                    {
                        label: "Номер телефона",
                        text: phoneFormat(card.phone),
                        name: "phone",
                        placeholder: "Введите телефон",
                        mask: MASK_PHONE_FOREIGN,
                        maskChar: null,
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        className: "col-16 col-md-8",
                    },
                    {
                        label: "E-mail",
                        name: "email",
                        required: true,
                        placeholder: "Введите e-mail",
                        text: card.email,
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        className: "col-16 col-md-8",
                    },
                ],
            },
            {
                header: "Должность и структурное подразделение",
                position: 3,
                items: [
                    {
                        text: card.position,
                        tooltipText: "Выберите должность сотрудника из сформированного справочника должностей",
                        label: "Должность",
                        search: true,
                        clientId,
                        name: "positionId",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.POSITIONS,
                        options: positionsOptions,
                        className: "col-16 col-md-8",
                    },
                    {
                        text: card.subdivision,
                        tooltipText: "Наименование производственного подразделения из справочника подразделений, в котором находится сотрудник",
                        label: "Структурное подразделение компании",
                        search: true,
                        clientId,
                        name: "subdivisionId",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.SUBDIVISIONS,
                        options: subdivisionsOptions,
                        className: "col-16 col-md-8",
                    },
                    {
                        text: card.departmentName,
                        label: "Управленческий отдел",
                        search: true,
                        clientId,
                        name: "kedoDepartmentId",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.KEDO_DEPARTMENTS,
                        className: "col-16 col-md-8",
                    },
                ],
            },
            {
                header: "Паспортные данные",
                position: 4,
                items: [
                    {
                        label: "Гражданство",
                        text: citizenship &&
                            <CitizenshipWithFlag
                                citizenship={citizenship}
                                textLevel="3"
                            />,
                        search: true,
                        name: "citizenship",
                        placeholder: "Выберите гражданство",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN,
                        options: citizenshipOptions,
                    },
                    {
                        label: "Дата рождения",
                        text: card.birthDate ? dateFormat(card.birthDate, "dd.MM.yyyy") : "",
                        name: "birthDate",
                        className: "col-16 col-md-8",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DATE,
                        maxDate: new Date(),
                    },
                    {
                        label: "Пол",
                        text: GENDER_DICT[card.gender],
                        name: "gender",
                        className: "col-16 col-md-8",
                        placeholder: "Выберите пол",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DROPDOWN,
                        options: GENDER_OPTIONS,
                    },
                    {
                        label: "Место рождения",
                        text: card.birthPlace,
                        name: "birthPlace",
                        placeholder: "Введите место рождения",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                    },
                    [
                        citizenshipsDict.RU.value,
                    ].includes(citizenship) && {
                        text: card.idDocNumber,
                        name: "idDocNumber",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        label: "Серия и номер паспорта",
                        maskChar: null,
                        mask: "9999 999999",
                        placeholder: "Введите серию и номер",
                    },
                    ![
                        citizenshipsDict.RU.value,
                        citizenshipsDict.NOT_SPECIFIED.value,
                    ].includes(citizenship) && {
                        text: card.idDocNumber,
                        name: "idDocNumber",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                        label: "Номер документа",
                        placeholder: "Введите номер документа",
                    },
                    citizenship !== citizenshipsDict.NOT_SPECIFIED.value && {
                        label: "Кем выдан",
                        citizenship,
                        text: card.idDocIssuedBy,
                        name: "idDocIssuedBy",
                        placeholder: "Введите кем выдан документ",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                    },
                    citizenship === citizenshipsDict.RU.value && {
                        label: "Код подразделения",
                        text: card.idDocIssuedByDepartmentCode,
                        name: "idDocIssuedByDepartmentCode",
                        maskChar: null,
                        mask: "999-999",
                        placeholder: "Введите код",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                    },
                    citizenship !== citizenshipsDict.NOT_SPECIFIED.value && {
                        label: "Дата выдачи",
                        text: card.idDocIssuedDate ? dateFormat(card.idDocIssuedDate, "dd.MM.yyyy") : "",
                        name: "idDocIssuedDate",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DATE,
                        className: "col-16 col-md-8",
                        maxDate: new Date(),
                    },
                    ![
                        citizenshipsDict.NOT_SPECIFIED.value,
                        citizenshipsDict.LG.value,
                        citizenshipsDict.DN.value,
                    ].includes(citizenship) && {
                        label: "Дата окончания срока действия",
                        text: card.idDocValidToDate ? dateFormat(card.idDocValidToDate, "dd.MM.yyyy") : "",
                        name: "idDocValidToDate",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.DATE,
                        className: "col-16 col-md-8",
                    },
                    {
                        label: "Адрес регистрации/места жительства",
                        text: card.address,
                        name: "address",
                        placeholder: "Введите адрес",
                        type: KEDO_STAFF_REGISTRY_INPUT_TYPE.INPUT,
                    },
                ].filter(Boolean),
            },
        ];

        if (isSort) {
            return fields.sort((a, b) => a.position - b.position);
        }

        return fields;
    };

    const fields = useMemo(() => {
        return getFields({
            citizenship: card.citizenship,
        });
    }, [
        card,
        citizenshipDict,
        roleDict,
        subdivisionsOptions,
        positionsOptions,
    ]);

    return {
        fields,
        getFields,
    };
};

