import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {uniqWith} from "lodash";

import {getPageRecruitmentDirectoryStatus} from "../../../ducks/bff/recruitment/directory/actionCreators";
import {bffRecruitmentDirectoryStatusOptionsSelector} from "../../../ducks/bff/recruitment/directory/selectors";
import NmDropdownV2 from "../NmDropdownV2";

import {handleFormString} from "../../../utils/stringHelper";

const FilterFunnelStatuses = props => {
    const {
        multiple = true,
        clientId,
        ...otherProps
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(bffRecruitmentDirectoryStatusOptionsSelector);

    const fetchClients = (clientName) => {
        const requestData = {
            pageNum: 1,
            pageSize: 25,
            clientId,
            name: handleFormString(clientName),
        };

        dispatch(getPageRecruitmentDirectoryStatus(requestData));
    };


    const onSearchChange = (valueFilter) => {
        fetchClients(valueFilter);
    };

    return (
        <NmDropdownV2
            {...otherProps}
            size="lg"
            multiple={multiple}
            onSearchChange={onSearchChange}
            search
            options={options}
        />
    );
};

export default FilterFunnelStatuses;