import {
    DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST,
    GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
    ORDER_CONTRACTOR_ADD_REQUEST,
    ORDER_CONTRACTOR_APPROVE_REQUEST,
    ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST,
    ORDER_CONTRACTOR_DECLINE_REQUEST,
    ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST,
    ORDER_CONTRACTOR_REJECT_REQUEST,
    ORDER_WORK_REPORT_LIST_REQUEST,
} from "./actions";

export const rejectContractorByOrder = (payload) => ({
    type: ORDER_CONTRACTOR_REJECT_REQUEST,
    payload,
});

export const approveContractorByOrder = (payload) => ({
    type: ORDER_CONTRACTOR_APPROVE_REQUEST,
    payload,
});

export const declineContractorByOrder = (payload) => ({
    type: ORDER_CONTRACTOR_DECLINE_REQUEST,
    payload,
});

export const getOrderWorkReportList = (payload) => ({
    type: ORDER_WORK_REPORT_LIST_REQUEST,
    payload,
});

export const orderContractorCompleteOrder = (payload) => ({
    type: ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST,
    payload,
});

export const initPayment = (payload) => ({
    type: ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST,
    payload,
});

export const isFrameContractSigned = (payload) => ({
    type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST,
    payload,
});

export const getActualScoreContractor = (payload) => ({
    type: GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
    payload,
});

export const addOrderContractor = (payload) => ({
    type: ORDER_CONTRACTOR_ADD_REQUEST,
    payload,
});