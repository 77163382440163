import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageSettingsClientGroups,
    updateStoreSettingsClientGroups,
} from "../../../../ducks/bff/settings/client-groups/actionCreators";

export function useClientGroupFetchList({filter = {}, pageSize, pageNum}) {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateStoreSettingsClientGroups({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getPageSettingsClientGroups({
            pageNum,
            pageSize,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
}