import {
    EDM_SUBDIVISION_ADD_REQUEST,
    EDM_SUBDIVISION_COMMON_COUNT_REQUEST,
    EDM_SUBDIVISION_DELETE_REQUEST,
    EDM_SUBDIVISION_LIST_REQUEST,
    EDM_SUBDIVISION_UPDATE_REQUEST,
} from "./actions";

export function getEdmSubdivisionsList(payload) {
    return {
        type: EDM_SUBDIVISION_LIST_REQUEST,
        payload,
    };
}

export function updateEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_UPDATE_REQUEST,
        payload,
    };
}

export function deleteEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_DELETE_REQUEST,
        payload,
    };
}

export function addEdmSubdivision(payload) {
    return {
        type: EDM_SUBDIVISION_ADD_REQUEST,
        payload,
    };
}

export function getEdmSubdivisionCommonCount(payload) {
    return {
        type: EDM_SUBDIVISION_COMMON_COUNT_REQUEST,
        payload,
    };
}