import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import CardApp from "../../../../../../components/CardApp";
import LabelTextApp from "../../../../../../components/LabelTextApp";
import {SettingsServiceMaintenanceNoticeFormEdit} from "../edit-form";

import {useModal} from "../../../../../../hooks/useModal";

import {getClassNames} from "../../../../../../utils/classNames";
import {formatLocalDate} from "../../../../../../utils/dateFormat";
import {isLastElementInArray} from "../../../../../../utils/objectHelper";

import {getMaintenanceNoticeSettings} from "../../../../../../ducks/bff/settings/service/actionCreators";
import {bffSettingsServiceNotificationsSelector} from "../../../../../../ducks/bff/settings/service/selectors";

const SettingsServicesMaintenanceNoticeForm = (props) => {
    const {
        isContractor,
        className,
    } = props;

    const dispatch = useDispatch();

    const type = isContractor ? "CONTRACTORS" : "CLIENTS";
    const data = useSelector((state) => bffSettingsServiceNotificationsSelector(state, type)) || {};

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = () => {
        dispatch(getMaintenanceNoticeSettings({
            type,
        }));
    };

    const {
        onOpenModal,
        modalData,
        onCloseModal,
    } = useModal();

    const stats = useMemo(() => {
        return [
            {
                label: "Заголовок",
                text: data.title,
            },
            {
                label: "Описание",
                text: data.description,
            },
            isContractor && {
                label: "Описание (для шага с ИНН)",
                text: data.descriptionInnStep,
            },
            {
                label: "Дата и время начала публикации",
                text: formatLocalDate(data.timeIntervalStart, "dd.MM.yyyy HH:mm"),
            },
            {
                label: "Дата и время окончания публикации",
                text: formatLocalDate(data.timeIntervalEnd, "dd.MM.yyyy HH:mm"),
            },
        ]
            .filter(Boolean)
            .map((item, index, array) => {
                const isLast = isLastElementInArray(array, index);

                return (
                    <LabelTextApp
                        key={item.label}
                        label={item.label}
                        text={item.text}
                        className={getClassNames([
                            !isLast && "mb-2",
                        ])}
                    />
                );
            });
    }, [data]);

    return (
        <CardApp
            className={className}
            title={
                isContractor
                    ? "Для исполнителя"
                    : "Для заказчика"
            }
            isEditable={true}
            onClickIcon={() => {
                onOpenModal({
                    isOpen: true,
                    isContractor,
                });
            }}
        >
            {
                modalData?.isOpen &&
                    <SettingsServiceMaintenanceNoticeFormEdit
                        onClose={onCloseModal}
                        isContractor={isContractor}
                        type={type}
                    />
            }
            {
                data.publish ?
                    stats :
                    "Выключены"
            }
        </CardApp>
    );
};

export default SettingsServicesMaintenanceNoticeForm;