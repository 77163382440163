import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";
import SettingsDirectoriesOrderCategoryListEdit from "./components/edit";
import {Dimmer, Loader} from "semantic-ui-react";

import {usePagination} from "../../../../hooks/usePagination";
import {useSettingsDirectoriesOrderCategoryListAction} from "./hooks/useAction";
import {useSettingsDirectoriesOrderCategoryListFetch} from "./hooks/useFetch";
import {useSettingsDirectoriesOrderCategoryListFilter} from "./hooks/useFilter";

import bem from "../../../../utils/bem";
import {formatAmount, formatNumber} from "../../../../utils/stringFormat";

import {ORDER_CATEGORY_FIELD} from "../../../../constants/orderCategory";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    getSettingsDirectoriesNaimixClientCategoriesListSelector,
    getSettingsDirectoriesNaimixClientCategoriesProgressActionSelector,
    getSettingsDirectoriesNaimixClientCategoriesProgressSelector,
    getSettingsDirectoriesNaimixClientCategoriesTotalCountSelector,
    getSettingsDirectoriesNaimixClientCategoriesTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/client-categories/selectors";

import "./style.sass";

const getFormatLimit = (limit) => {
    return formatAmount(formatNumber(limit || 0));
};

const SettingsDirectoriesOrderCategoryList = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {t} = useTranslation();

    const list = useSelector(getSettingsDirectoriesNaimixClientCategoriesListSelector);
    const totalPages = useSelector(getSettingsDirectoriesNaimixClientCategoriesTotalPagesSelector);
    const progress = useSelector(getSettingsDirectoriesNaimixClientCategoriesProgressActionSelector);
    const progressPage = useSelector(getSettingsDirectoriesNaimixClientCategoriesProgressSelector);
    const totalCount = useSelector(getSettingsDirectoriesNaimixClientCategoriesTotalCountSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER],
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
        filter,
    } = useSettingsDirectoriesOrderCategoryListFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useSettingsDirectoriesOrderCategoryListFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        onOpenEditModal,
        onCloseEditModal,
        getMediaControls,
        editData,
        confirmData,
        getDataForConfirmWindow,
    } = useSettingsDirectoriesOrderCategoryListAction({
        isAccessEdit,
        fetchList,
    });

    const rows = useMemo(() => {
        return list.map(item => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        className="align-items-stretch"
                        primaryHeader={item.value}
                        noDivider
                        classNameMainContent="col-16 col-xxl-11"
                        cards={[
                            {
                                title: "Лимиты, руб",
                                className: "col-16 col-md-6 col-xxl-3",
                                helpTooltip: {
                                    text: "Лимит 1 транзакции/Лимит в сутки на 1 исполнителя/Лимит в месяц на 1 исполнителя",
                                    position: "bottom-right",
                                },
                                values: [
                                    {
                                        bold: true,
                                        text: getFormatLimit(item[ORDER_CATEGORY_FIELD.TRANSACTION_LIMIT]),
                                    },
                                    {
                                        text: getFormatLimit(item[ORDER_CATEGORY_FIELD.DAY_TRANSACTION_LIMIT]),
                                    },
                                    {
                                        text: getFormatLimit(item[ORDER_CATEGORY_FIELD.MONTH_TRANSACTION_LIMIT]),
                                    },
                                ],
                            },
                        ]}
                        actionsClassName="col-1 col-xxl-2 align-items-end justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const renderModalWindow = () => {
        if (!confirmData) {
            return null;
        }

        const {
            content,
            onConfirm,
            onCancel,
            confirmButton,
            cancelButton,
        } = getDataForConfirmWindow();

        return (
            <NmConfirmV2
                disabled={progress}
                isNeedClosing={false}
                content={content}
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />
        );
    };

    const renderContent = () => {
        return (
            <>
                {
                    (progress || progressPage) ??
                    <Dimmer
                        active
                        inverted
                    >
                        <Loader content={t("loader.content")}>
                        </Loader>
                    </Dimmer>
                }
                <div className="order-categories-page__container">
                    {
                        list.length === 0 ?
                            <NmEmptyPageV2
                                title="Данные отсутствуют"
                                isSearch={isSearch}
                            /> :
                            <CheckboxList
                                className="order-categories-page__content"
                                rows={rows}
                            />
                    }
                </div>
            </>
        );
    };

    const renderEditBlock = () => {
        if (!editData) {
            return null;
        }

        return (
            <SettingsDirectoriesOrderCategoryListEdit
                onClose={onCloseEditModal}
                editData={editData}
            />
        );
    };

    const [block, element] = bem("order-categories-page");

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            typeFilter="vertical"
            className={block()}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            controls={
                isAccessEdit &&
                <NmButton
                    size="xl"
                    onClick={() => {
                        onOpenEditModal({});
                    }}
                    icon={<AddIcon />}
                >
                    {t("order-categories.add-btn")}
                </NmButton>
            }
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            classNameContent={element("content")}
        >
            {renderModalWindow()}
            {renderEditBlock()}
            {renderContent()}
        </NmPage>
    );
};


export default SettingsDirectoriesOrderCategoryList;
