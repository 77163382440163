import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const bffEmailNotificationSelector = state => state.bff.emailNotifications;

export const getListSettingNotificationSelector = createSelector(
    bffEmailNotificationSelector,
    ({list}) => list,
);
export const getTotalPagesSettingNotificationSelector = createSelector(
    bffEmailNotificationSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const getTotalCountSettingNotificationSelector = createSelector(
    bffEmailNotificationSelector, ({totalCount}) => totalCount,
);
