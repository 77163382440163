export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE";