import {
    BFF_ACT_REGISTRIES_ADD_ERROR,
    BFF_ACT_REGISTRIES_ADD_REQUEST,
    BFF_ACT_REGISTRIES_ADD_SUCCESS,
    BFF_ACT_REGISTRIES_GET_BY_ID_ERROR,
    BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS,
    BFF_ACT_REGISTRIES_GET_PAGE_ERROR,
    BFF_ACT_REGISTRIES_GET_PAGE_REQUEST,
    BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS,
    BFF_ACT_REGISTRIES_UPDATE_ERROR,
    BFF_ACT_REGISTRIES_UPDATE_REQUEST,
    BFF_ACT_REGISTRIES_UPDATE_STORE,
    BFF_ACT_REGISTRIES_UPDATE_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    totalCount: 0,
    progressAction: false,
    progress: false,
    pageData: {
        pageNum: 1,
        pageSize: 25,
    },
    card: {},
    progressCard: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_ACT_REGISTRIES_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS:
            const {
                registries,
                totalCount,
            } = payload;

            return {
                ...state,
                list: registries,
                totalCount,
                progress: false,
            };
        case BFF_ACT_REGISTRIES_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_ACT_REGISTRIES_UPDATE_REQUEST:
        case BFF_ACT_REGISTRIES_ADD_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case BFF_ACT_REGISTRIES_UPDATE_SUCCESS:
        case BFF_ACT_REGISTRIES_UPDATE_ERROR:
        case BFF_ACT_REGISTRIES_ADD_SUCCESS:
        case BFF_ACT_REGISTRIES_ADD_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case BFF_ACT_REGISTRIES_GET_BY_ID_ERROR:
            return {
                ...state,
                progressCard: false,
            };
        case BFF_ACT_REGISTRIES_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

