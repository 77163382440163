import {
    ARCHIVE_EDO_DOCUMENT_REQUEST,
    CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST,
    CLEAR_EDO_PRE_FILLED_TEMPLATE,
    GET_DOCUMENT_INFO_COUNT_REQUEST,
    GET_EDO_ACCESS_LIST_REQUEST,
    GET_EDO_DOCUMENT_STATES_REQUEST,
    GET_EDO_DOCUMENT_TYPES_REQUEST,
    GET_EDO_PRE_FILLED_TEMPLATE_REQUEST,
    GET_PAGE_EDO_STAFF_REQUEST,
    GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST,
    REJECT_EDO_DOCUMENT_REQUEST,
    REPLACE_EDO_DOCUMENT_REQUEST,
    SEND_EDO_CODE_VERIFICATION_REQUEST,
    SIGN_EDO_DOCUMENT_REQUEST,
    UPDATE_EDO_DOCUMENTS_STORE,
    UPDATE_EDO_TEMPLATE_REQUEST,
    UPLOAD_EDO_DOCUMENT_REQUEST,
    UPLOAD_EDO_TEMPLATE_REQUEST,
    VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST,
} from "./actions";

export function getRichPageEdoDocuments(payload) {
    return {
        type: GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST,
        payload,
    };
}

export function getEdoDocumentInfoCount(payload) {
    return {
        type: GET_DOCUMENT_INFO_COUNT_REQUEST,
        payload,
    };
}

export function getEdoDocumentStates(payload) {
    return {
        type: GET_EDO_DOCUMENT_STATES_REQUEST,
        payload,
    };
}

export function rejectEdoDocument(payload) {
    return {
        type: REJECT_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function signEdoDocument(payload) {
    return {
        type: SIGN_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function getEdoDocumentTypes(payload) {
    return {
        type: GET_EDO_DOCUMENT_TYPES_REQUEST,
        payload,
    };
}

export function getPageEdoStaff(payload) {
    return {
        type: GET_PAGE_EDO_STAFF_REQUEST,
        payload,
    };
}

export function uploadEdoDocument(payload) {
    return {
        type: UPLOAD_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function sendCodeVerification(payload) {
    return {
        type: SEND_EDO_CODE_VERIFICATION_REQUEST,
        payload,
    };
}

export function archiveEdoDocument(payload) {
    return {
        type: ARCHIVE_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function replaceEdoDocument(payload) {
    return {
        type: REPLACE_EDO_DOCUMENT_REQUEST,
        payload,
    };
}

export function updateEdoDocumentsStore(payload) {
    return {
        type: UPDATE_EDO_DOCUMENTS_STORE,
        payload,
    };
}

export function getEdoAccessList(payload) {
    return {
        type: GET_EDO_ACCESS_LIST_REQUEST,
        payload,
    };
}

export function getEdoPreFilledTemplate(payload) {
    return {
        type: GET_EDO_PRE_FILLED_TEMPLATE_REQUEST,
        payload,
    };
}

export function validateEdoCompletedDocument(payload) {
    return {
        type: VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST,
        payload,
    };
}

export function clearEdoPreFilledTemplate() {
    return {
        type: CLEAR_EDO_PRE_FILLED_TEMPLATE,
    };
}

export function uploadTemplate(payload) {
    return {
        type: UPLOAD_EDO_TEMPLATE_REQUEST,
        payload,
    };
}

export function updateTemplate(payload) {
    return {
        type: UPDATE_EDO_TEMPLATE_REQUEST,
        payload,
    };
}

export function checkEdoDuplicateDocument(payload) {
    return {
        type: CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST,
        payload,
    };
}