import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";

import useTemplatesLogsList from "./hooks/useList";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {getFullName} from "../../../../utils/stringFormat";

import {
    documentCustomTemplateEventLogFetchErrorListSelector,
    documentCustomTemplateEventLogListSelector,
    documentCustomTemplateEventLogTotalCountSelector,
    documentCustomTemplateEventLogTotalPagesSelector,
    documentCustomTemplateEventLogTypeDictSelector,
    documentCustomTemplateProgressSelector,
} from "../../../../ducks/bff/documents-templates/selectors";

const TemplatesLogs = () => {
    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        filterDto,
        isSearch,
        submitFilter,
        filtersBase,
        clearFilter,
    } = useTemplatesLogsList();

    const eventLogTypeDict = useSelector(documentCustomTemplateEventLogTypeDictSelector);

    const list = useSelector(documentCustomTemplateEventLogListSelector);
    const progress = useSelector(documentCustomTemplateProgressSelector);
    const fetchError = useSelector(documentCustomTemplateEventLogFetchErrorListSelector);
    const totalCount = useSelector(documentCustomTemplateEventLogTotalCountSelector);
    const totalPages = useSelector(documentCustomTemplateEventLogTotalPagesSelector);

    function getRows() {
        return list.map(item => {
            const {
                dateTime,
                performer: {
                    lastName,
                    firstName,
                    patronymic,
                },
                info,
                type,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Дата: ${formatLocalDate(dateTime, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={info}
                        noDivider
                        classNameMainContent="col-12"
                        labels={[
                            {
                                label: "ФИО",
                                text: getFullName(lastName, firstName, patronymic),
                            },
                            {
                                label: "Тип события",
                                text: eventLogTypeDict[type],
                            },
                        ]}
                    />
                ),
            };
        });
    }

    return (
        <NmPage
            noPadding
            widthByFilter
            typeFilter="vertical"
            filtersBase={<Filter
                initState={filterDto}
                filters={filtersBase}
                onSubmit={submitFilter}
                clearFilter={clearFilter}
            />}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            header={<NmPageHeader
                text="Журнал событий"
            />}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {list.length === 0 && <NmEmptyPageV2
                isSearch={isSearch}
                title="Информация отсутствует"
                fetchProgress={progress}
                fetchError={fetchError}
            />}
            {list.length > 0 && <CheckboxList
                rows={getRows()}
            />}
        </NmPage>
    );
};

export default TemplatesLogs;