import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getVacancyResponsesController} from "../vacancyResponses/sagas";
import {
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffUrl} from "../../../../utils/url";

const getController = () => {
    return getBffUrl({
        isClientCard: true,
        clientRolesUrl: "/client-adm/recruitment/vacancyCandidates",
        adminRolesUrl: "/adm/clients/client-card/recruitment/vacancyCandidates",
    });
};

// PUT /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}
// PUT /bff/client-adm/recruitment/vacancyCandidates/{id}
// Редактирование кандидата
const editRecruitmentVacancyCandidate = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/${id}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}
// DELETE /bff/client-adm/recruitment/vacancyCandidates/{id}
// Удаление кандидата
const deleteRecruitmentVacancyCandidate = function* ({payload}) {
    try {
        const {
            id,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${id}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/add
// POST /bff/client-adm/recruitment/vacancyCandidates/add
// Создать кандидата
const createRecruitmentVacancyCandidate = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/add`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/getPage
// POST /bff/client-adm/recruitment/vacancyCandidates/getPage
// Получить список кандидатов
const getRecruitmentVacancyCandidates = function* ({payload}) {
    const {
        isLoadMore,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/getPage`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{candidateId}/card
// POST /bff/client-adm/recruitment/vacancyCandidates/{candidateId}/card
// Получить карточку кандидата
const getRecruitmentVacancyCandidatesCard = function* ({payload}) {
    const {
        candidateId,
        vacancyId,
    } = payload;

    try {
        const result = yield request.bff.get(`${getController()}/${candidateId}/card`, {params: {vacancyId}});
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/uploadResume
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/uploadResume
// Добавить файл резюме
const uploadRecruitmentVacancyCandidatesResume = function* ({payload}) {
    const {
        onSuccess,
        id,
        formData,
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${id}/uploadResume`, formData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/setStatus
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/setStatus
// Установить статус кандидату кандидата
const setRecruitmentVacancyCandidateStatus = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/setStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{candidateId}/updateFromResponse
// POST /bff/client-adm/recruitment/vacancyCandidates/{candidateId}/updateFromResponse
// Перенести данные из отклика в кандидата
const updateRecruitmentVacancyCandidatesFromResponse = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/updateFromResponse`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/getPageForResponse
// POST /bff/client-adm/recruitment/vacancyCandidates/getPageForResponse
// Получить список кандидатов для карточки привязки отклика к кандидату
const getRecruitmentVacancyCandidatesForResponse = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPageForResponse`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/uploadFile
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/uploadFile
// Добавить файл
const uploadRecruitmentVacancyCandidatesFileSaga = function* ({payload}) {
    const {
        formData,
        candidateId,
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/uploadFile`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/deleteFile
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/deleteFile
// Удалить файл
const deleteRecruitmentVacancyCandidatesFileSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/deleteFile`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/deleteResume
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/deleteResume
// Удалить файл резюме
const deleteRecruitmentVacancyCandidatesResumeSaga = function* ({payload}) {
    const {
        onSuccess,
        candidateId,
        ...requestData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/deleteResume`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/vacancyCandidates/{candidateId}/getFeed
// GET /bff/client-adm/recruitment/vacancyCandidates/{candidateId}/getFeed
// Получить ленту событий по кандидату
const getFeedListRecruitmentVacancyCandidates = function* ({payload}) {
    try {
        const {
            candidateId,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/${candidateId}/getFeed`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/{id}/reject
// POST /bff/client-adm/recruitment/vacancyCandidates/{id}/reject
// Установить статус отказ кандидату
const rejectRecruitmentCandidateSaga = function* ({payload}) {
    const {
        candidateId,
        onSuccess,
        ...reqData
    } = payload;

    try {
        const result = yield request.bff.post(`${getController()}/${candidateId}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/recruitment/vacancyCandidates/message-template
// POST /bff/client-adm/recruitment/vacancyCandidates/message-template
// POST /bff/adm/clients/client-card/recruitment/vacancyResponses/message-template
// POST /bff/client-adm/recruitment/vacancyResponses/message-template
// Получить список шаблонов сообщений
const getRecruitmentMessageTemplates = function* ({payload}) {
    const {
        isVacancyCandidate,
        ...reqData
    } = payload;

    const controller = isVacancyCandidate
        ? getController()
        : getVacancyResponsesController();

    try {
        const result = yield request.bff.post(`${controller}/message-template`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/recruitment/vacancyCandidates/getClientUserPageByVacancyId
// GET /bff/client-adm/recruitment/vacancyCandidates/getClientUserPageByVacancyId
// Получить страницу рекрутеров по вакансии
const getRecruitmentVacancyCandidatesRecruiters = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getClientUserPageByVacancyId`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PUT_REQUEST, editRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_REQUEST, deleteRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_CREATE_REQUEST, createRecruitmentVacancyCandidate),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_REQUEST, getRecruitmentVacancyCandidates),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_CARD_REQUEST, getRecruitmentVacancyCandidatesCard),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_RESUME_REQUEST, uploadRecruitmentVacancyCandidatesResume),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_SET_STATUS_REQUEST, setRecruitmentVacancyCandidateStatus),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FROM_RESPONSE_REQUEST, updateRecruitmentVacancyCandidatesFromResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_PAGE_FOR_RESPONSE_REQUEST, getRecruitmentVacancyCandidatesForResponse),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_UPLOAD_FILE_REQUEST, uploadRecruitmentVacancyCandidatesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_FILE_REQUEST, deleteRecruitmentVacancyCandidatesFileSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_DELETE_RESUME_REQUEST, deleteRecruitmentVacancyCandidatesResumeSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_FEED_LIST_REQUEST, getFeedListRecruitmentVacancyCandidates),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_REJECT_REQUEST, rejectRecruitmentCandidateSaga),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_MESSAGE_TEMPLATES_REQUEST, getRecruitmentMessageTemplates),
        takeEvery(BFF_RECRUITMENT_VACANCY_CANDIDATES_RECRUITERS_LIST_REQUEST, getRecruitmentVacancyCandidatesRecruiters),
    ]);
}