import * as yup from "yup";

import {getFullRegistrationInnStep} from "./getInnStep";
import {getPersonalDataStep} from "./getPersonalDataStep";
import {getFullRegistrationResidencePlaceNotResidentStep} from "./getResidencePlaceNotResidentStep";
import {getFullRegistrationSelfieStep} from "./getSelfieStep";
import {getFullRegistrationValidations} from "./getValidations";
import {getFullRegistrationVhiPolicyType} from "./getVhiPolicyType";
import {isOnlyMigrationCardPresent} from "./isOnlyMigrationCardPresent";

import {dictionaryToOptions} from "../../../utils/objectHelper";

import {citizenshipsDict} from "../../../constants/citizenships";
import {CONTRACTOR_FILE_TYPES} from "../../../constants/clientList";
import {
    CONTRACTOR_VHI_POLICE_FORMAT,
    CONTRACTOR_VHI_POLICE_TYPE,
    EDM_PASSPORT_TYPE_OPTIONS,
} from "../../../constants/contractor";
import {VALIDATIONS_MESSAGE} from "../../../constants/validationsYup";
import {FULL_REGISTRATION_COMPONENT_TYPE, FULL_REGISTRATION_STEP} from "../constants";

export const getFullRegistrationStepsConfig = (params) => {
    const {
        isRequiredFields,
        citizenship,
        migrationStatus,
        locatedOutsideRussia,
        steps,
        citizenshipOptions,
        vhiPolicyTypeDict,
        vhiPolicyFormatDict,
        passportTypeOptions,
        genderOptions,
        migrationStatusOptions,
        contractor,
        scans,
    } = params;

    const config = {
        [FULL_REGISTRATION_STEP.INN]: getFullRegistrationInnStep({
            isRequiredFields,
            contractor,
        }),
        [FULL_REGISTRATION_STEP.CITIZENSHIP_AND_MIGRATION_STATUS]: {
            value: FULL_REGISTRATION_STEP.CITIZENSHIP_AND_MIGRATION_STATUS,
            modalTitle: "Регистрация",
            validation: (values) => {
                if (locatedOutsideRussia) {
                    return yup.object().shape({
                        citizenship: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                        passportType: [
                            citizenshipsDict.BY.value,
                            citizenshipsDict.KZ.value,
                            citizenshipsDict.KG.value,
                        ].includes(values.citizenship) && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                        edmPassportType: [
                            citizenshipsDict.TJ.value,
                            citizenshipsDict.UA.value,
                        ].includes(values.citizenship) && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    });
                }

                if (
                    ![
                        citizenshipsDict.RU.value,
                        citizenshipsDict.LG.value,
                        citizenshipsDict.DN.value,
                    ].includes(values.citizenship)
                ) {
                    return yup.object().shape({
                        citizenship: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                        migrationStatus: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable(),
                    });
                }
            },
            fields: (values) => {
                const citizenshipField = {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                    name: "citizenship",
                    label: "Гражданство",
                    placeholder: "Выберите гражданство",
                    search: true,
                    required: true,
                    options: citizenshipOptions,
                };

                const passportTypeField = [
                    citizenshipsDict.BY.value,
                    citizenshipsDict.KZ.value,
                    citizenshipsDict.KG.value,
                ].includes(values.citizenship) ? {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                        label: "Тип паспорта",
                        name: "passportType",
                        options: passportTypeOptions,
                        required: true,
                    } : null;

                const edmPassportTypeField = [
                    citizenshipsDict.TJ.value,
                    citizenshipsDict.UA.value,
                ].includes(values.citizenship) ? {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                        label: "Тип паспорта",
                        name: "edmPassportType",
                        options: EDM_PASSPORT_TYPE_OPTIONS,
                        required: true,
                    } : null;

                if (locatedOutsideRussia) {
                    return [
                        citizenshipField,
                        passportTypeField,
                        edmPassportTypeField,
                    ].filter(item => Boolean(item));
                }

                if (
                    [
                        citizenshipsDict.RU.value,
                        citizenshipsDict.LG.value,
                        citizenshipsDict.DN.value,
                    ].includes(values.citizenship)
                ) {
                    return [
                        citizenshipField,
                    ];
                }
                return [
                    citizenshipField,
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                        name: "migrationStatus",
                        options: migrationStatusOptions,
                        placeholder: "Выберите миграционный статус",
                        label: "Миграционный статус",
                        required: true,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.PERSONAL_DATA]: getPersonalDataStep({
            migrationStatus,
            citizenship,
            isRequiredFields,
            passportTypeOptions,
            genderOptions,
        }),
        [FULL_REGISTRATION_STEP.RESIDENCE]: {
            value: FULL_REGISTRATION_STEP.RESIDENCE,
            modalTitle: "Регистрация гражданина РФ по месту жительства",
            validation: () => {
                return yup.object().shape({
                    address: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(255, "Не более 255 символов") :
                        yup.string().max(255, "Не более 255 символов"),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.SCAN_REGISTRATION,
                        label: "Разворот с регистрацией",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Адрес прописки",
                        name: "address",
                        required: true,
                        placeholder: "Введите адрес",
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.RESIDENCE_PLACE_NOT_RESIDENT]: getFullRegistrationResidencePlaceNotResidentStep({
            isRequiredFields,
        }),
        [FULL_REGISTRATION_STEP.MIGRATION_CARD]: {
            value: FULL_REGISTRATION_STEP.MIGRATION_CARD,
            modalTitle: "Миграционная карта",
            validation: () => {
                return yup.object().shape({
                    migrationCardSeries: isRequiredFields ?
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED)
                            .length(4, "Допустимое количество символов 4") :
                        yup.string()
                            .length(4, "Допустимое количество символов 4"),
                    migrationCardNumber: isRequiredFields ?
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED)
                            .max(250, "Не более 250 символов")
                            .matches(/^[0-9]+$/, "Только цифры"):
                        yup.string()
                            .max(250, "Не более 250 символов")
                            .matches(/^[0-9]+$/, "Только цифры"),
                    migrationCardIssuedDate: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: ({migrationCardPresent = true}) => {
                const hasMigrationCardControl = !isOnlyMigrationCardPresent({citizenship, migrationStatus}) ? {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX,
                    label: "Миграционная карта присутствует",
                    name: "migrationCardPresent",
                } :
                    null;

                if (!migrationCardPresent) {
                    return [hasMigrationCardControl];
                }

                return [
                    hasMigrationCardControl,
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: "Фотография лицевой стороны миграционной карты",
                        name: CONTRACTOR_FILE_TYPES.SCAN_MIGRATION_CARD,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        label: "Фотография обратной стороны миграционной карты",
                        name: CONTRACTOR_FILE_TYPES.SCAN_BACK_SIDE_MIGRATION_CARD,
                        required: false,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Серия",
                        name: "migrationCardSeries",
                        maskChar: null,
                        mask: "9999",
                        required: true,
                        placeholder: "Введите серию",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "migrationCardNumber",
                        maskChar: null,
                        required: true,
                        placeholder: "Введите номер",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата выдачи",
                        maxDate: new Date(),
                        required: true,
                        name: "migrationCardIssuedDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата окончания срока действия",
                        minDate: new Date(),
                        required: false,
                        name: "migrationCardValidToDate",
                    },
                ].filter(value => value);
            },
        },
        [FULL_REGISTRATION_STEP.RESIDENCE_PERMIT]: {
            value: FULL_REGISTRATION_STEP.RESIDENCE_PERMIT,
            modalTitle: "Вид на жительство",
            validation: () => {
                return yup.object().shape({
                    residencePermitIssuedDate: isRequiredFields &&
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED),
                    residencePermitNumber: isRequiredFields &&
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED),
                    residencePermitSeries: isRequiredFields &&
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED),
                    residencePermitIssuedBy: isRequiredFields ?
                        yup.string()
                            .required(VALIDATIONS_MESSAGE.REQUIRED)
                            .max(255, "Допустимое количество символов 255") :
                        yup.string()
                            .max(255, "Допустимое количество символов 255"),
                    residencePermitDecisionNumber: yup.string()
                        .max(255, "Допустимое количество символов 255"),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.RESIDENCE_PERMIT,
                        label: "Фотография разворота вида на жительство",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Серия",
                        name: "residencePermitSeries",
                        maskChar: null,
                        mask: "99",
                        placeholder: "Введите серию",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "residencePermitNumber",
                        maskChar: null,
                        mask: "9999999",
                        placeholder: "Введите номер",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер принятия решения",
                        name: "residencePermitDecisionNumber",
                        placeholder: "Введите номер принятия решения",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата принятия решения",
                        maxDate: new Date(),
                        name: "residencePermitDecisionDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата выдачи",
                        maxDate: new Date(),
                        name: "residencePermitIssuedDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата окончания срока действия",
                        minDate: new Date(),
                        name: "residencePermitValidToDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Кем выдан",
                        name: "residencePermitIssuedBy",
                        placeholder: "Введите кем выдан документ",
                        required: true,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA]: {
            value: FULL_REGISTRATION_STEP.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
            modalTitle: "Разрешение на временное проживание",
            validation: () => {
                return yup.object().shape(getFullRegistrationValidations({
                    isRequiredFields,
                    fields: [
                        "temporaryResidencePermitNum",
                        "temporaryResidencePermitDecisionDate",
                        "temporaryResidencePermitValidToDate",
                        "temporaryResidencePermitDecisionIssuedBy",
                    ]},
                ));
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.TEMPORARY_RESIDENCE_PERMIT_PERSONAL_DATA,
                        label: "Фотография разворота РВП с персональной информацией",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "temporaryResidencePermitNum",
                        placeholder: "Введите номер документа",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата принятия решения",
                        maxDate: new Date(),
                        name: "temporaryResidencePermitDecisionDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Срок действия",
                        minDate: new Date(),
                        name: "temporaryResidencePermitValidToDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Кем выдан",
                        name: "temporaryResidencePermitDecisionIssuedBy",
                        placeholder: "Введите кем выдан документ",
                        required: true,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.VHI_POLICY_SCAN]: {
            value: FULL_REGISTRATION_STEP.VHI_POLICY_SCAN,
            modalTitle: "Страховой полис",
            validation: (values) => {
                if (!values.vhiPolicyPresent) {
                    return null;
                }

                return yup.object().shape({
                    vhiPolicyType: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    vhiPolicyFormat: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    vhiPolicyValidFromDate: isRequiredFields && yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
                    vhiPolicyValidToDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: (values) => {
                const hasPolicyControl = {
                    type: FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX,
                    label: "Полис ДМС или ОМС присутствует",
                    name: "vhiPolicyPresent",
                };

                if (!values.vhiPolicyPresent) {
                    return [hasPolicyControl];
                }

                if (values.vhiPolicyType === CONTRACTOR_VHI_POLICE_TYPE.COMPULSORY) {
                    return [
                        hasPolicyControl,
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                            label: "Тип полиса",
                            name: "vhiPolicyType",
                            placeholder: "Выберите тип полиса",
                            options: dictionaryToOptions(vhiPolicyTypeDict),
                            disabled: getFullRegistrationVhiPolicyType({
                                citizenship,
                                migrationStatus,
                            }).disabled,
                            required: true,
                        },
                        values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PAPER && {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            name: CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
                            label: "Фотография полиса ОМС",
                        },
                        values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            name: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
                            label: "Фотография лицевой стороны пластикового полиса ОМС",
                        },
                        values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                            name: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
                            label: "Фотография обратной стороны пластикового полиса ОМС",
                        },
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                            label: "Бумажный полис/Пластиковый полис",
                            name: "vhiPolicyFormat",
                            options: dictionaryToOptions(vhiPolicyFormatDict),
                            required: true,
                        },
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                            label: "Дата начала действия",
                            maxDate: new Date(),
                            name: "vhiPolicyValidFromDate",
                            required: true,
                        },
                        {
                            type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                            label: "Дата окончания действия",
                            minDate: new Date(),
                            name: "vhiPolicyValidToDate",
                            required: true,
                        },
                    ].filter(value => value);
                }

                return [
                    hasPolicyControl,
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                        label: "Тип полиса",
                        name: "vhiPolicyType",
                        placeholder: "Выберите тип полиса",
                        options: dictionaryToOptions(vhiPolicyTypeDict),
                        disabled: getFullRegistrationVhiPolicyType({
                            citizenship,
                            migrationStatus,
                        }).disabled,
                        required: true,
                    },
                    values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PAPER && {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.VHI_POLICY_SCAN,
                        label: "Фотография полиса ДМС",
                    },
                    values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_FRONT_SIDE,
                        label: "Фотография лицевой стороны пластикового полиса ДМС",
                    },
                    values.vhiPolicyFormat === CONTRACTOR_VHI_POLICE_FORMAT.PLASTIC && {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PLASTIC_VHI_POLICY_SCAN_BACK_SIDE,
                        label: "Фотография обратной стороны пластикового полиса ДМС",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPDOWN,
                        label: "Бумажный полис/Пластиковый полис",
                        name: "vhiPolicyFormat",
                        required: true,
                        options: dictionaryToOptions(vhiPolicyFormatDict),
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата начала действия",
                        maxDate: new Date(),
                        name: "vhiPolicyValidFromDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата окончания действия",
                        minDate: new Date(),
                        name: "vhiPolicyValidToDate",
                        required: true,
                    },
                ].filter(value => value);
            },
        },
        [FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION]: {
            value: FULL_REGISTRATION_STEP.PASSPORT_TRANSLATION,
            modalTitle: "Перевод паспорта",
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION,
                        label: "Фотография перевода паспорта на русский язык",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PASSPORT_TRANSLATION_NOTARIZATION,
                        label: "Фотография нотариального заверения перевода паспорта (при наличии)",
                        required: false,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD]: {
            value: FULL_REGISTRATION_STEP.VISA_PERSONAL_DATA_SPREAD,
            modalTitle: "Виза",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD]: isRequiredFields &&
                    yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    visaNum: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
                        yup.string().max(50, "Допустимое количество символов 50"),
                    visaId: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
                        yup.string().max(50, "Допустимое количество символов 50"),
                    visaIssuedDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                    visaActiveFromDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                    visaActiveUntilDate: isRequiredFields && yup.string().nullable().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.VISA_PERSONAL_DATA_SPREAD,
                        label: "Фотография визы с персональной информацией",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "visaNum",
                        placeholder: "Введите номер",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата выдачи",
                        maxDate: new Date(),
                        required: true,
                        name: "visaIssuedDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата начала действия",
                        maxDate: new Date(),
                        required: true,
                        name: "visaActiveFromDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата окончания действия",
                        minDate: new Date(),
                        required: true,
                        name: "visaActiveUntilDate",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "ID визы",
                        name: "visaId",
                        required: true,
                        placeholder: "Введите ID визы",
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.UNIVERSITY_LICENSE]: {
            value: FULL_REGISTRATION_STEP.UNIVERSITY_LICENSE,
            modalTitle: "Высшее учебное заведение",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_FRONT_SIDE,
                        label: "Фотография лицевой стороны лицензии ВУЗа",
                        noteText: "При необходимости сделайте скриншот лицевой стороны лицензии ВУЗа.",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.UNIVERSITY_LICENSE_BACK_SIDE,
                        label: "Фотография обратной стороны лицензии ВУЗа",
                        noteText: "При необходимости сделайте скриншот обратной стороны лицензии ВУЗа.",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_FRONT_SIDE,
                        label: "Фотография лицевой стороны аккредитации ВУЗа",
                        noteText: "При необходимости сделайте скриншот лицевой стороны аккредитации ВУЗа.",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.UNIVERSITY_ACCREDITATION_BACK_SIDE,
                        label: "Фотография обратной стороны аккредитации ВУЗа",
                        noteText: "При необходимости сделайте скриншот обратной стороны аккредитации ВУЗа.",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.UNIVERSITY_SCHEDULE_FOR_SEMESTER,
                        label: "Фотография расписания занятий на семестр",
                        required: false,
                        noteText: "При необходимости сделайте скриншот расписания занятий на семестр.",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.CERTIFICATE_FROM_UNIVERSITY,
                        label: "Фотография справки из ВУЗа",
                        noteText: "Необходимо загрузить оригинал справки из ВУЗа (колледжа, техникума) с синей печатью. Справка действительна в течение 3 месяцев с даты выдачи.",
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA]: {
            value: FULL_REGISTRATION_STEP.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
            modalTitle: "Вид на жительство лица без гражданств",
            validation: () => {
                return yup.object().shape(
                    getFullRegistrationValidations({
                        isRequiredFields,
                        fields: [
                            [CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA],
                            "statelessPersonResidencePermitNumber",
                            "statelessPersonResidencePermitIssuedBy",
                            "statelessPersonResidencePermitIssuedDate",
                            "statelessPersonResidencePermitValidToDate",
                        ]},
                    ),
                );
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.STATELESS_PERSON_RESIDENCE_PERMIT_PERSONAL_DATA,
                        label: "Фотография разворота вида на жительство с персональной информацией",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "statelessPersonResidencePermitNumber",
                        placeholder: "Введите номер",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Кем выдано",
                        name: "statelessPersonResidencePermitIssuedBy",
                        placeholder: "Введите кем выдан документ",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата выдачи",
                        maxDate: new Date(),
                        name: "statelessPersonResidencePermitIssuedDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата окончания срока действия",
                        minDate: new Date(),
                        name: "statelessPersonResidencePermitValidToDate",
                        required: true,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT]: {
            value: FULL_REGISTRATION_STEP.EMPLOYMENT_PATENT,
            modalTitle: "Патент на осуществление трудовой деятельности в России",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    employmentPatentSeries: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(5, "Допустимое количество символов 5") :
                        yup.string().max(5, "Допустимое количество символов 5"),
                    employmentPatentNum: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(10, "Допустимое количество символов 10").matches(/^[0-9]+$/, "Только цифры") :
                        yup.string().max(10, "Допустимое количество символов 10").matches(/^[0-9]+$/, "Только цифры"),
                    employmentPatentRegistrationNumber: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).max(50, "Допустимое количество символов 50") :
                        yup.string().max(50, "Допустимое количество символов 50"),
                    employmentPatentIssuedDate: isRequiredFields ?
                        yup.string().required(VALIDATIONS_MESSAGE.REQUIRED).nullable() :
                        yup.string().nullable(),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN,
                        label: "Лицевая сторона патента",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN,
                        label: "Обратная сторона патента",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PAID_EMPLOYMENT_PATENT_CHECK_SCAN,
                        label: "Чек об оплате патента",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Серия",
                        name: "employmentPatentSeries",
                        placeholder: "Введите серию",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Номер",
                        name: "employmentPatentNum",
                        placeholder: "Введите номер документа",
                        mask: "9999999999",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DATE,
                        label: "Дата выдачи",
                        maxDate: new Date(),
                        name: "employmentPatentIssuedDate",
                        required: true,
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.INPUT,
                        label: "Регистрационный номер",
                        name: "employmentPatentRegistrationNumber",
                        placeholder: "Введите регистрационный номер",
                        required: true,
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.SELFIE_MAIN_DOCUMENT]: getFullRegistrationSelfieStep({
            migrationStatus,
            citizenship,
            isRequiredFields,
            locatedOutsideRussia,
        }),
        [FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR]: {
            value: FULL_REGISTRATION_STEP.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
            modalTitle: "Согласие родителей на совершение сделок несовершеннолетним на площадке",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_FRONT_SIDE_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                    [CONTRACTOR_FILE_TYPES.EMPLOYMENT_PATENT_BACK_SIDE_SCAN]: isRequiredFields && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.LINK,
                        content: "Согласие на совершение сделок несовершеннолетним на электронной площадке Наймикс.pdf",
                        link: "/files/Согласие на совершение сделок несовершеннолетним на электронной площадке Наймикс.pdf",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.LINK,
                        content: "Шаблон согласия на обработку персональных данных родителя",
                        link: "/files/Письменное согласие на обработку (включая передачу) персональных данных законного представителя (родителя).pdf",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_TRANSACTIONS_BY_MINOR,
                        label: "Согласие на совершение сделок несовершеннолетним",
                    },
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PARENTS_CONSENT_TO_PROCESSING_PERSONAL_DATA,
                        label: "Согласие на обработку персональных данных родителя",
                    },
                ];
            },
        },
        [FULL_REGISTRATION_STEP.PREVIOUSLY_ISSUED_PASSPORTS_INFO_RESIDENT]: {
            value: FULL_REGISTRATION_STEP.PREVIOUSLY_ISSUED_PASSPORTS_INFO_RESIDENT,
            modalTitle: "Сведения о ранее выданных паспортах",
            validation: () => {
                return yup.object().shape({
                    [CONTRACTOR_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]: Boolean(contractor.isPreviouslyIssuedPassportsInfoRequired)
                        && !scans[CONTRACTOR_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN]
                        && yup.array().required(VALIDATIONS_MESSAGE.REQUIRED),
                });
            },
            fields: () => {
                return [
                    {
                        type: FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE,
                        name: CONTRACTOR_FILE_TYPES.PREVIOUSLY_ISSUED_PASSPORTS_INFO_SCAN,
                        label: "Фотография разворота паспорта со сведениями о ранее выданных паспортах",
                    },
                ];
            },
        },
    };

    return steps
        .filter(Boolean)
        .map((step) => {
            return {
                ...config[step],
                fields: config[step].fields,
            };
        });
};