import {
    BFF_COMMON_ORDER_OBJECTS_REQUEST,
    BFF_COMMON_ORDER_PROJECTS_REQUEST,
    BFF_COMMON_ORDER_UPDATE_STORE,
} from "./actions";

export function updateCommonOrderStore(payload) {
    return {
        type: BFF_COMMON_ORDER_UPDATE_STORE,
        payload,
    };
}
export function getCommonOrderProjects(payload) {
    return {
        type: BFF_COMMON_ORDER_PROJECTS_REQUEST,
        payload,
    };
}

export function getCommonOrderObjects(payload) {
    return {
        type: BFF_COMMON_ORDER_OBJECTS_REQUEST,
        payload,
    };
}