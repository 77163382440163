import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useTemplatesLogsFilter} from "./useFilter";

import {usePagination} from "../../../../../hooks/usePagination";

import {
    getDocumentsCustomTemplateEventLogList,
    getDocumentsCustomTemplateEventLogTypeDict,
    updateDocumentCustomTemplateStore,
} from "../../../../../ducks/bff/documents-templates/actionCreators";

export default function useTemplatesLogsList() {
    const dispatch = useDispatch();

    const {
        setFilterDto,
        setIsSearch,
        filterDto,
        mapFilterDto,
        ...otherFilter
    } = useTemplatesLogsFilter();

    const {
        pageNum,
        pageSize,
        setPagination,
        ...otherPagination
    } = usePagination("nm-page");

    useEffect(() => {
        dispatch(getDocumentsCustomTemplateEventLogTypeDict());
        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                eventLogs: [],
                eventLogsTotalCount: 0,
                eventLogsPageData: {},
                eventLogTypeDict: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [filterDto, pageNum, pageSize]);

    const fetchList = () => {
        const filter = mapFilterDto(filterDto);

        dispatch(getDocumentsCustomTemplateEventLogList({
            ...filter,
            pageNum,
            pageSize,
        }));
    };

    const submitFilter = (filter, isSearch) => {
        setFilterDto(filter);
        setPagination({pageSize, pageNum: 1});
        setIsSearch(isSearch);
    };

    const clearFilter = () => {
        setIsSearch(false);
        setFilterDto({});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    return {
        pageSize,
        pageNum,
        ...otherPagination,
        ...otherFilter,
        submitFilter,
        clearFilter,
        filterDto,
    };
}