import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmModal from "../../../../components/ActualComponents/NmModal";
import NmModalCardList from "../../../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as BlockedIcon} from "../../../../images/lock_24.svg";

import {phoneFormat} from "../../../../utils/stringFormat";

import {COLOR} from "../../../../constants/color";

import {findContractorsForEdmStaff} from "../../../../ducks/bff/clients/edo/staff/actionCreators";
import {
    edmStaffFindingContractorListSelector,
    edmStaffFindingContractorTotalPagesSelector,
} from "../../../../ducks/bff/clients/edo/staff/selectors";

import "./style.sass";

const EdmStaffNewSelectingForm = (props) => {
    const {
        onCancel,
        onBackStepClick,
        onSelectContractor,
        preloader,
        clientId,
        filter,
    } = props;

    const [pageNum, setPageNum] = useState(1);
    const contractorList = useSelector(edmStaffFindingContractorListSelector);
    const contractorsTotalPages = useSelector(edmStaffFindingContractorTotalPagesSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchContractorList();
    }, [pageNum]);

    const fetchContractorList = () => {
        dispatch(findContractorsForEdmStaff({
            data: {
                clientId,
                pageNum,
                pageSize: 5,
                ...filter,
            },
        }));
    };

    const getTitle = () => {
        if (isEmpty(contractorList)) {
            return "К сожалению, исполнителей с такими параметрами не нашлось";
        }

        if (contractorList.length === 1) {
            return "Добавлен в список кадров";
        }

        if (contractorList.length > 1) {
            return "По заданным фильтрам найдено более одного исполнителя, выберите нужного из списка";
        }
    };

    const handlePaginationChange = (e, {activePage: newPageNum}) => {
        if (pageNum === newPageNum) {
            return;
        }

        setPageNum(newPageNum);
    };

    const renderFio = (contractor) => {
        const {
            blocked,
            fio,
        } = contractor;

        return (
            <div className="d-flex align-items-center">
                {fio}
                {
                    blocked &&
                    <BlockedIcon
                        width={18}
                        height={18}
                        color={COLOR.NEGATIVE_100}
                        className="ms-1"
                    />
                }
            </div>
        );
    };

    const getCards = () => {
        return contractorList.map(item => {
            return {
                ...item,
                singleValues: [
                    {
                        label: "ФИО",
                        value: renderFio(item),
                    },
                    {label: "Телефон", value: phoneFormat(item.phone) || "-"},
                ],
                actions: (
                    <NmButton
                        color="light-green"
                        disabled={item.inStaff}
                        className="edm-staff-new-selecting-form__action"
                        onClick={onSelectContractor(item)}
                    >
                        {item.inStaff ? "Добавлен" : "Выбрать"}
                    </NmButton>
                ),
            };
        });
    };

    const renderContractorListTable = () => {
        if (isEmpty(contractorList)) {
            return null;
        }

        return (
            <NmModalCardList
                className="edm-staff-new-selecting-form__list"
                list={getCards()}
                pagination={
                    <NmPagination
                        pageNum={pageNum}
                        totalPages={contractorsTotalPages}
                        onChangePagination={handlePaginationChange}
                    />
                }
            />
        );
    };

    const renderFooter = () => {
        return (
            <NmButton
                color="grey"
                size="xl"
                onClick={onBackStepClick}
            >
                Назад
            </NmButton>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onCancel}
            header={
                <NmTitle size="lg">
                    {getTitle()}
                </NmTitle>
            }
            footer={renderFooter()}
        >
            {preloader}
            {renderContractorListTable()}
        </NmModal>
    );
};

export default EdmStaffNewSelectingForm;