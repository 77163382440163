import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getClientCardInfo} from "./bff/clients/info/actionCreators";
import {registrationPromocode} from "./promocode";

import {getTotalPages} from "../utils/mathHelper";
import {dictionaryToOptions} from "../utils/objectHelper";
import request, {getMultipartHeaders} from "../utils/postman";
import {convertStringToDate, isNullOrWhitespace} from "../utils/stringHelper";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffCommonController} from "../utils/url";

import {CLIENT_PROPERTIES_FIELD} from "../constants/clientList";
import {ARCHIVE, CLIENT, LOCK} from "../constants/messages";

const controller = "/clients";
const bffController = "/adm/clients";
//*  TYPES  *//

const CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST = "CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST";
const CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR = "CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR";

const CLIENT_ADD_REQUEST = "CLIENT_ADD_REQUEST";
const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
const CLIENT_ADD_ERROR = "CLIENT_ADD_ERROR";

const CLIENT_CUSTOM_LIST_READ_SUCCESS = "CLIENT_CUSTOM_LIST_READ_SUCCESS";

const CLIENT_LIST_READ_ERROR = "CLIENT_LIST_READ_ERROR";

const CLIENT_SET_ARCHIVED_REQUEST = "CLIENT_SET_ARCHIVED_REQUEST";
const CLIENT_SET_ARCHIVED_SUCCESS = "CLIENT_SET_ARCHIVED_SUCCESS";
const CLIENT_SET_ARCHIVED_ERROR = "CLIENT_SET_ARCHIVED_ERROR";

const CLIENT_CLEAR_STORE = "CLIENT_CLEAR_STORE";

const CLIENT_UPDATE_FIELD_STORE = "CLIENT_UPDATE_FIELD_STORE";

const CLIENT_GET_COMPANIES_REQUEST = "CLIENT_GET_COMPANIES_REQUEST";
const CLIENT_GET_COMPANIES_SUCCESS = "CLIENT_GET_COMPANIES_SUCCESS";
const CLIENT_GET_COMPANIES_ERROR = "CLIENT_GET_COMPANIES_ERROR";

const CLIENT_SET_LOCKED_REQUEST = "CLIENT_SET_LOCKED_REQUEST";
const CLIENT_SET_LOCKED_SUCCESS = "CLIENT_SET_LOCKED_SUCCESS";
const CLIENT_SET_LOCKED_ERROR = "CLIENT_SET_LOCKED_ERROR";

const CLIENT_UPDATE_FAVORITE_REQUEST = "CLIENT_UPDATE_FAVORITE_REQUEST";
const CLIENT_UPDATE_FAVORITE_SUCCESS = "CLIENT_UPDATE_FAVORITE_SUCCESS";
const CLIENT_UPDATE_FAVORITE_ERROR = "CLIENT_UPDATE_FAVORITE_ERROR";

const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST";
const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS";
const CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR = "CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR";

const CLIENT_NAME_MAP_READ_REQUEST = "CLIENT_NAME_MAP_READ_REQUEST";
const CLIENT_NAME_MAP_READ_SUCCESS = "CLIENT_NAME_MAP_READ_SUCCESS";
const CLIENT_NAME_MAP_READ_ERROR = "CLIENT_NAME_MAP_READ_ERROR";

const CLIENT_ROLE_MAP_READ_REQUEST = "CLIENT_ROLE_MAP_READ_REQUEST";
const CLIENT_ROLE_MAP_READ_SUCCESS = "CLIENT_ROLE_MAP_READ_SUCCESS";
const CLIENT_ROLE_MAP_READ_ERROR = "CLIENT_ROLE_MAP_READ_ERROR";

const CLIENT_TERMINATE_CONTRACT_REQUEST = "CLIENT_TERMINATE_CONTRACT_REQUEST";
const CLIENT_TERMINATE_CONTRACT_SUCCESS = "CLIENT_TERMINATE_CONTRACT_SUCCESS";
const CLIENT_TERMINATE_CONTRACT_ERROR = "CLIENT_TERMINATE_CONTRACT_ERROR";

const GET_FILE_NOTICE_REQUEST = "GET_FILE_NOTICE_REQUEST";
const GET_FILE_NOTICE_ERROR = "GET_FILE_NOTICE_ERROR";

const CLIENT_GROUP_PAGE_REQUEST = "CLIENT_GROUP_PAGE_REQUEST";
const CLIENT_GROUP_PAGE_SUCCESS = "CLIENT_GROUP_PAGE_SUCCESS";
const CLIENT_GROUP_PAGE_ERROR = "CLIENT_GROUP_PAGE_ERROR";

const CLIENT_LIST_BY_PARTNER_REQUEST = "CLIENT_LIST_BY_PARTNER_REQUEST";
const CLIENT_LIST_BY_PARTNER_SUCCESS = "CLIENT_LIST_BY_PARTNER_SUCCESS";
const CLIENT_LIST_BY_PARTNER_ERROR = "CLIENT_LIST_BY_PARTNER_ERROR";

const CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST";
const CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS";
const CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR = "CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR";

const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST";
const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS";
const BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR = "BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR";

const BFF_CLIENT_ADD_REQUEST = "BFF_CLIENT_ADD_REQUEST";
const BFF_CLIENT_ADD_SUCCESS = "BFF_CLIENT_ADD_SUCCESS";
const BFF_CLIENT_ADD_ERROR = "BFF_CLIENT_ADD_ERROR";

const GET_MEMBERS_PAGE_FOR_GROUP_REQUEST = "GET_MEMBERS_PAGE_FOR_GROUP_REQUEST";
const GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS = "GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS";
const GET_MEMBERS_PAGE_FOR_GROUP_ERROR = "GET_MEMBERS_PAGE_FOR_GROUP_ERROR";

const GET_RELATED_CLIENT_USERS_REQUEST = "GET_RELATED_CLIENT_USERS_REQUEST";
const GET_RELATED_CLIENT_USERS_SUCCESS = "GET_RELATED_CLIENT_USERS_SUCCESS";
const GET_RELATED_CLIENT_USERS_ERROR = "GET_RELATED_CLIENT_USERS_ERROR";

const GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST = "GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST";
const GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS = "GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS";
const GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR = "GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR";

const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST";
const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS";
const UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR = "UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR";

const GET_CLIENT_CIVIL_CONTRACT_REQUEST = "GET_CLIENT_CIVIL_CONTRACT_REQUEST";
const GET_CLIENT_CIVIL_CONTRACT_SUCCESS = "GET_CLIENT_CIVIL_CONTRACT_SUCCESS";
const GET_CLIENT_CIVIL_CONTRACT_ERROR = "GET_CLIENT_CIVIL_CONTRACT_ERROR";

const UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST = "UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST";
const UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS = "UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS";
const UPDATE_CLIENT_CIVIL_CONTRACT_ERROR = "UPDATE_CLIENT_CIVIL_CONTRACT_ERROR";

const CLIENT_GET_NAMES_LIST_REQUEST = "CLIENT_GET_NAMES_LIST_REQUEST";
const CLIENT_GET_NAMES_LIST_SUCCESS = "CLIENT_GET_NAMES_LIST_SUCCESS";
const CLIENT_GET_NAMES_LIST_ERROR = "CLIENT_GET_NAMES_LIST_ERROR";

const CLIENT_GET_DEFAULT_CATEGORY_REQUEST = "CLIENT_GET_DEFAULT_CATEGORY_REQUEST";
const CLIENT_GET_DEFAULT_CATEGORY_SUCCESS = "CLIENT_GET_DEFAULT_CATEGORY_SUCCESS";
const CLIENT_GET_DEFAULT_CATEGORY_ERROR = "CLIENT_GET_DEFAULT_CATEGORY_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    listWithPartners: [],
    customList: [],
    totalCount: 0,
    card: {},
    pageData: {},
    error: null,
    progress: false,
    errorFetch: false,
    isSuccessAdd: false,
    isSuccessArchived: false,
    isSuccessLocked: false,
    clientGroupPageProgress: false,
    newClientId: "",
    clientListByIds: [],
    responseIsArchive: {},
    enabledForemanFlag: false,
    clientNameIdMap: {},
    clientGroupPage: [],
    roleMap: {},
    clientListByPartner: [],
    clientListPage: [],
    optimizedClientList: [],
    groupMembersPageData: {},
    membersForGroupPageData: {},
    groupMembersTotalCount: 0,
    membersForGroupTotalCount: 0,
    groupMembersList: [],
    membersListForGroup: [],
    progressGroupMembersList: false,
    progressGroupMembersAction: false,
    progressMembersListForGroup: false,
    listRelatedClientUsers: [],
    progressPatentContract: false,
    patentContractCard: {},
    civilContract: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CLIENT_CIVIL_CONTRACT_SUCCESS:
            return {
                ...state,
                civilContract: payload,
            };
        case CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST:
        case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS:
        case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS: {
            const {
                clients = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                errorFetch: false,
                optimizedClientList: clients,
                totalCount,
            };
        }
        case CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR:
        case BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR:
            return {
                ...state,
                progress: false,
                errorFetch: true,
            };
        case CLIENT_GROUP_PAGE_REQUEST:
            return {
                ...state,
                clientGroupPageProgress: true,
            };
        case CLIENT_GET_COMPANIES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST:
            return {
                ...state,
                progressPatentContract: true,
            };
        case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST:
            return {
                ...state,
                progressPatentContract: true,
            };
        case CLIENT_UPDATE_FIELD_STORE:
            const {
                field,
                value,
            } = payload;
            return {
                ...state,
                progress: false,
                [field]: value,
            };
        case CLIENT_CLEAR_STORE:
            return {
                ...initial,
            };
        case CLIENT_LIST_BY_PARTNER_SUCCESS:
            return {
                ...state,
                clientListByPartner: payload,
            };
        case CLIENT_SET_LOCKED_REQUEST:
        case CLIENT_SET_ARCHIVED_REQUEST:
        case CLIENT_TERMINATE_CONTRACT_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENT_GET_COMPANIES_SUCCESS:
            return {
                ...state,
                clientListByIds: payload,
                progress: false,
            };
        case CLIENT_SET_LOCKED_SUCCESS:
            return {
                ...state,
                progress: false,
                isSuccessLocked: true,
            };
        case CLIENT_SET_ARCHIVED_SUCCESS:
            return {
                ...state,
                progress: false,
                isSuccessArchived: true,
            };
        case CLIENT_ADD_REQUEST:
        case BFF_CLIENT_ADD_REQUEST:
            return {
                ...state,
                progress: true,
                newClientId: "",
            };
        case CLIENT_ADD_SUCCESS:
        case BFF_CLIENT_ADD_SUCCESS:
            return {
                ...state,
                newClientId: payload,
                progress: false,
                isSuccessAdd: true,
            };
        case CLIENT_GROUP_PAGE_SUCCESS:
            return {
                ...state,
                clientGroupPageProgress: false,
                clientGroupPage: payload,
            };
        case CLIENT_GET_NAMES_LIST_SUCCESS: {
            const {
                results,
                totalCount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                optimizedClientList: results,
                totalCount,
            };
        }
        case CLIENT_CUSTOM_LIST_READ_SUCCESS:
            const {
                clients: customClients = [],
                totalCount: customTotalcount = 0,
            } = payload;

            return {
                ...state,
                progress: false,
                customList: [...customClients],
                totalCount: customTotalcount,
            };
        case CLIENT_TERMINATE_CONTRACT_SUCCESS:
        case CLIENT_TERMINATE_CONTRACT_ERROR:
        case GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS:
            return {
                ...state,
                progressPatentContract: false,
                patentContractCard: payload,
            };
        case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS:
            return {
                ...state,
                progressPatentContract: false,
            };
        case CLIENT_ADD_ERROR:
        case BFF_CLIENT_ADD_ERROR:
            return {
                ...state,
                isSuccessAdd: false,
                progress: false,
                error: payload,
            };
        case CLIENT_GET_COMPANIES_ERROR:
        case CLIENT_LIST_READ_ERROR:
        case CLIENT_SET_ARCHIVED_ERROR:
        case CLIENT_GROUP_PAGE_ERROR:
            return {
                ...state,
                clientGroupPageProgress: false,
                error: payload,
            };
        case CLIENT_NAME_MAP_READ_SUCCESS:
            return {
                ...state,
                clientNameIdMap: payload,
            };
        case CLIENT_ROLE_MAP_READ_SUCCESS:
            return {
                ...state,
                roleMap: payload,
            };
        case GET_RELATED_CLIENT_USERS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_RELATED_CLIENT_USERS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case GET_RELATED_CLIENT_USERS_SUCCESS:
            return {
                ...state,
                progress: false,
                listRelatedClientUsers: payload,
            };
        case GET_MEMBERS_PAGE_FOR_GROUP_REQUEST:
            return {
                ...state,
                membersForGroupPageData: payload,
                progressMembersListForGroup: true,
            };
        case GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS:
            const {
                clientUsers: membersListForGroup,
                totalCount: membersForGroupTotalCount,
            } = payload;

            return {
                ...state,
                membersListForGroup,
                membersForGroupTotalCount,
                progressMembersListForGroup: false,
            };
        case GET_MEMBERS_PAGE_FOR_GROUP_ERROR:
            return {
                ...state,
                progressMembersListForGroup: false,
            };
        case GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR:
            return {
                ...state,
                progressPatentContract: false,
            };
        case UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR:
            return {
                ...state,
                progressPatentContract: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getRelatedClientUsers(payload) {
    return {
        type: GET_RELATED_CLIENT_USERS_REQUEST,
        payload,
    };
}

export function getClientGroupPage(payload) {
    return {
        type: CLIENT_GROUP_PAGE_REQUEST,
        payload,
    };
}

export function addClient(payload) {
    return {
        type: CLIENT_ADD_REQUEST,
        payload: payload,
    };
}

export function bffAddClient(payload) {
    return {
        type: BFF_CLIENT_ADD_REQUEST,
        payload: payload,
    };
}

export function clearClientStore() {
    return {
        type: CLIENT_CLEAR_STORE,
    };
}

export function updateFieldClientStore(field, value) {
    return {
        type: CLIENT_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function setLockedClient(clientId, type) {
    return {
        type: CLIENT_SET_LOCKED_REQUEST,
        payload: {clientId, type},
    };
}

export function setArchivedClient(clientId, type) {
    return {
        type: CLIENT_SET_ARCHIVED_REQUEST,
        payload: {clientId, type},
    };
}

export function setTerminateContractClient(payload) {
    return {
        type: CLIENT_TERMINATE_CONTRACT_REQUEST,
        payload,
    };
}

export function updateClientFavorite(payload) {
    return {
        type: CLIENT_UPDATE_FAVORITE_REQUEST,
        payload,
    };
}

export function getClientNameMap() {
    return {
        type: CLIENT_NAME_MAP_READ_REQUEST,
    };
}

export function getClientRoleMap() {
    return {
        type: CLIENT_ROLE_MAP_READ_REQUEST,
    };
}

export function setProhibitionContractingNonResidents(payload) {
    return {
        type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST,
        payload,
    };
}

export function getClientsByPartner(payload) {
    return {
        type: CLIENT_LIST_BY_PARTNER_REQUEST,
        payload,
    };
}

export function getFileNotice(payload) {
    return {
        type: GET_FILE_NOTICE_REQUEST,
        payload,
    };
}

// оптимизированный список клиентов
export function getOptimizedClientList(payload) {
    return {
        type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST,
        payload,
    };
}

export function getBffClientListOptimized(payload) {
    return {
        type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST,
        payload,
    };
}

export function getMembersPageForGroup(payload) {
    return {
        type: GET_MEMBERS_PAGE_FOR_GROUP_REQUEST,
        payload,
    };
}

export function getClientContractDetails(payload) {
    return {
        type: GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST,
        payload,
    };
}

export function updateClientContractDetails(payload) {
    return {
        type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST,
        payload,
    };
}

export function checkPaymentsCreationProhibited(payload) {
    return {
        type: CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST,
        payload,
    };
}

export function getClientCivilContract(payload) {
    return {
        type: GET_CLIENT_CIVIL_CONTRACT_REQUEST,
        payload,
    };
}

export function updateClientCivilContract(payload) {
    return {
        type: UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST,
        payload,
    };
}

export function getClientNamesList(payload) {
    return {
        type: CLIENT_GET_NAMES_LIST_REQUEST,
        payload,
    };
}

export function getClientDefaultCategory(payload) {
    return {
        type: CLIENT_GET_DEFAULT_CATEGORY_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientSelector = state => state.client;

export const clientTotalPagesSelector = createSelector(clientSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const clientListByPartnerOptionsSelector = createSelector(clientSelector, ({clientListByPartner}) => {
    return clientListByPartner.map(({clientId, name}) => ({key: clientId, value: clientId, text: name}));
});

export const clientGroupOptionsSelector = createSelector(clientSelector, ({clientGroupPage}) => clientGroupPage.map(({clientId: value, name: text}) => ({
    key: value, value, text,
})));

export const clientTotalCountSelector = createSelector(clientSelector, ({totalCount}) => totalCount);
export const clientPageDataSelector = createSelector(clientSelector, ({pageData}) => pageData);

export const clientListOptimizedSelector = createSelector(clientSelector, ({optimizedClientList}) => optimizedClientList);
export const clientListOptimizedOptionsSelector = createSelector(clientSelector, ({optimizedClientList}) => optimizedClientList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));
export const clientOptionsCustomListSelector = createSelector(clientSelector, ({customList}) => customList.map(({clientId: value, name: text, brand}) => ({
    key: value, value, text: brand ? `${text} (${brand})` : text,
})));

export const clientProgressSelector = createSelector(clientSelector, ({progress}) => progress);
export const clientErrorFetchSelector = createSelector(clientSelector, ({errorFetch}) => errorFetch);


export const clientIsSuccessArchivedSelector = createSelector(clientSelector, state => {
    return state.isSuccessArchived;
});

export const clientNameIdMapSelector = createSelector(clientSelector, ({clientNameIdMap}) => dictionaryToOptions(clientNameIdMap));
export const clientRoleMapSelector = createSelector(clientSelector, ({roleMap}) => dictionaryToOptions(roleMap));

export const progressMembersListFromGroupSelector = createSelector(clientSelector, ({progressMembersListForGroup}) => progressMembersListForGroup);
export const membersListForGroupSelector = createSelector(clientSelector, ({membersListForGroup}) => membersListForGroup);
export const membersForGroupTotalCountSelector = createSelector(clientSelector, ({membersForGroupTotalCount}) => membersForGroupTotalCount);
export const membersForGroupTotalPagesSelector = createSelector(clientSelector, ({membersForGroupTotalCount, membersForGroupPageData: {pageSize = 0}}) => getTotalPages(membersForGroupTotalCount, pageSize));
export const progressGroupMembersActionSelector = createSelector(clientSelector, ({progressGroupMembersAction}) => progressGroupMembersAction);

export const relatedClientUsersListSelector = createSelector(clientSelector, ({listRelatedClientUsers}) => listRelatedClientUsers.filter(({enabled}) => enabled));

export const patentContractCardSelector = createSelector(clientSelector, ({patentContractCard}) => patentContractCard);
export const progressPatentContractSelector = createSelector(clientSelector, ({progressPatentContract}) => progressPatentContract);
export const clientCivilContractSelector = createSelector(clientSelector, ({civilContract}) => ({
    ...civilContract,
    civilContractNumber: civilContract.civilContractNumber || "",
    civilContractDate: civilContract.civilContractDate ? convertStringToDate(civilContract.civilContractDate) : undefined,
}));

//*  SAGA  *//

// /api/clients/users/isPaymentsCreationProhibited
export const checkPaymentsCreationProhibitedSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onClose,
        } = payload;

        const isPaymentsCreationProhibited = yield request.get(`${controller}/users/isPaymentsCreationProhibited`);

        if (isPaymentsCreationProhibited) {
            yield toastError("Возможность отправить акт исполнителю недоступна для вашей учетной записи. Обратитесь к руководству вашей компании");
            yield onClose();

            return {
                done: true,
            };
        }

        yield onSuccess();

    } catch (error) {
        yield put({
            type: CHECK_PAYMENTS_CREATION_PROHIBITED_ERROR,
            payload: error,
        });
    }
};

export const addClientSaga = function* (action) {
    const {
        payload: {
            data,
            promocode,
            onSuccess = () => {
            },
        },
    } = action;

    try {
        const result = yield request.post(`${controller}/add`, data);
        const {
            guid,
            errorMessage,
        } = result;

        if (!guid) {
            toastError(errorMessage ? errorMessage : CLIENT.CLIENT_ADD_ERROR_TEXT);

            yield put({
                type: CLIENT_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(guid);
        toastSuccess(CLIENT.CLIENT_ADD_SUCCESS_TEXT);

        yield put({
            type: CLIENT_ADD_SUCCESS,
            payload: guid,
        });

        if (!isNullOrWhitespace(promocode)) {
            yield put(registrationPromocode({
                data: {
                    clientId: guid,
                    promocode,
                },
            }));
        }

        const state = yield select();

        yield put(setLockedClient(guid, false));
        yield put(getOptimizedClientList(state.client.pageData));
    } catch (error) {
        toastError(CLIENT.CLIENT_ADD_ERROR_TEXT);

        yield put({
            type: CLIENT_ADD_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/add
// Добавление клиента
export const bffAddClientSaga = function* (action) {
    const {
        payload: {
            data,
            promocode,
            onSuccess = () => {
            },
        },
    } = action;

    try {
        const result = yield request.bff.post(`${bffController}/add`, data);
        const {
            guid,
            errorMessage,
        } = result;

        if (!guid) {
            toastError(errorMessage ? errorMessage : CLIENT.CLIENT_ADD_ERROR_TEXT);

            yield put({
                type: BFF_CLIENT_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess(guid);
        toastSuccess(CLIENT.CLIENT_ADD_SUCCESS_TEXT);

        yield put({
            type: BFF_CLIENT_ADD_SUCCESS,
            payload: guid,
        });

        if (!isNullOrWhitespace(promocode)) {
            yield put(registrationPromocode({
                data: {
                    clientId: guid,
                    promocode,
                },
            }));
        }

        yield put(setLockedClient(guid, false));
    } catch (error) {
        toastError(CLIENT.CLIENT_ADD_ERROR_TEXT);

        yield put({
            type: BFF_CLIENT_ADD_ERROR,
            payload: error,
        });
    }
};

export const clientSetArchivedSaga = function* ({payload}) {
    const {
        clientId,
        type,
        onSuccess,
    } = payload;

    try {
        const {errorMessage} = yield request.post(`${controller}/${clientId}/archived/${type}`);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_SET_ARCHIVED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CLIENT_SET_ARCHIVED_SUCCESS,
        });
        if (type) {
            toastSuccess(ARCHIVE.CLIENT_SET_ARCHIVED_SUCCESS_TEXT);
        } else {
            toastSuccess(ARCHIVE.CLIENT_SET_RESTORED_SUCCESS_TEXT);
        }
    } catch (error) {
        if (type) {
            toastError(ARCHIVE.CLIENT_SET_ARCHIVED_ERROR_TEXT);
        } else {
            toastError(ARCHIVE.CLIENT_SET_RESTORED_ERROR_TEXT);
        }
        yield put({
            type: CLIENT_SET_ARCHIVED_ERROR,
            payload: error,
        });
    }
};

//PUT /bff/adm/clients/{clientId}/archive
// Расторгнуть договор
export const terminateContractClientSaga = function* ({payload}) {
    const {
        clientId,
        data,
        onSuccess,
        onError,
    } = payload;

    try {
        const {errorMessage} = yield request.bff.put(`${bffController}/${clientId}/archive`, data, {...getMultipartHeaders()});

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_TERMINATE_CONTRACT_ERROR,
                payload: errorMessage,
            });

            if (onError) {
                yield call(onError, null);
            }

            return {
                done: true,
            };
        }

        if (onSuccess) {
            yield call(onSuccess, null);
        }

        yield put({
            type: CLIENT_TERMINATE_CONTRACT_SUCCESS,
        });

        // toastSuccess(ARCHIVE.CLIENT_SET_ARCHIVED_SUCCESS_TEXT);

    } catch (error) {
        // toastError(ARCHIVE.CLIENT_SET_RESTORED_ERROR_TEXT);

        yield put({
            type: CLIENT_TERMINATE_CONTRACT_ERROR,
            payload: error,
        });
    }
};

export const clientSetLockedSaga = function* (action) {
    const {clientId, type} = action.payload;
    try {
        yield request.post(`${controller}/${clientId}/locked/${type}`);

        yield put({
            type: CLIENT_SET_LOCKED_SUCCESS,
        });
        if (type) {
            toastSuccess(LOCK.CLIENT_SET_LOCKED_SUCCESS_TEXT);
        } else {
            toastSuccess(LOCK.CLIENT_SET_UNLOCKED_SUCCESS_TEXT);
        }
    } catch (error) {
        if (type) {
            toastError(LOCK.CLIENT_SET_LOCKED_ERROR_TEXT);
        } else {
            toastError(LOCK.CLIENT_SET_UNLOCKED_ERROR_TEXT);
        }
        yield put({
            type: CLIENT_SET_LOCKED_ERROR,
            payload: error,
        });
    }
};

export const getClientGroupPageSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.get(`${controller}/getDictionaryClientsForClientGroupFilter`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GROUP_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GROUP_PAGE_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        yield put({
            type: CLIENT_GROUP_PAGE_ERROR,
            payload: error,
        });
    }
};

export const getCompaniesSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getByIds`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_COMPANIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GET_COMPANIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_GET_COMPANIES_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/update-client-favorite
export const updateClientFavoriteSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${bffController}/update-client-favorite`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: CLIENT_UPDATE_FAVORITE_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({type: CLIENT_UPDATE_FAVORITE_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: CLIENT_UPDATE_FAVORITE_ERROR, payload: error.message});
    }
};

//POST /api/clients/{clientId}/prohibitionContractingNonResidents/{check}
export const setProhibitionContractingNonResidentsSaga = function* ({payload}) {
    try {
        const {
            data,
            getSuccess,
        } = payload;
        const {
            clientId,
        } = data;

        const result = yield request.post(`${controller}/${clientId}/prohibitionContractingNonResidents/${data[CLIENT_PROPERTIES_FIELD.PROHIBITION_CONTRACTING_NON_RESIDENTS]}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }
        if (getSuccess) {
            getSuccess();
        }

        yield put({
            type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/clientIdNameMap
export const getClientIdNameMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/users/clientIdNameMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_NAME_MAP_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CLIENT_NAME_MAP_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: CLIENT_NAME_MAP_READ_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/users/roleMap
export const getRoleMapSaga = function* () {
    try {
        const result = yield request.get(`${controller}/users/roleMap`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_ROLE_MAP_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CLIENT_ROLE_MAP_READ_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: CLIENT_ROLE_MAP_READ_ERROR,
            payload: error,
        });
    }
};

// GET/api/clients/getClientsByPartner
export const getClientListByPartnerSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getPartnerClientPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            console.error("getClientListByPartnerSaga:", errorMessage);

            yield put({
                type: CLIENT_LIST_BY_PARTNER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_LIST_BY_PARTNER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_LIST_BY_PARTNER_ERROR,
            payload: error,
        });
    }
};

// GET/api/clients/{clientId}/fileNotice/{fileId}
export const getFileNoticeSaga = function* ({payload}) {
    try {
        const {
            clientId,
            field,
            name,
            isDownload,
        } = payload;

        const requestOptions = {
            method: "get",
        };
        const response = yield request.getFile(`${window.location.origin}/api${controller}/${clientId}/fileNotice/${field}`, requestOptions);

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        if (isDownload) {
            tempLink.setAttribute("download", name);
        } else {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

    } catch (error) {
        yield put({
            type: GET_FILE_NOTICE_ERROR,
            payload: error,
        });
    }
};

export const getOptimizedClientListSaga = function* ({payload}) {
    try {
        const {
            isCustomRequest,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getBffCommonController()}/client/optimized-page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
            });

            return {
                done: true,
            };
        }

        if (isCustomRequest) {
            yield put({
                type: CLIENT_CUSTOM_LIST_READ_SUCCESS,
                payload: result,
            });
        } else {
            yield put({
                type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS,
                payload: result,
            });
        }
    } catch (error) {
        yield put({
            type: CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
        });
    }
};

export const getBffClientListOptimizedSaga = function* ({payload}) {
    try {
        const {
            isCustomRequest,
            url,
            ...reqData
        } = payload;
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        if (isCustomRequest) {
            yield put({
                type: CLIENT_CUSTOM_LIST_READ_SUCCESS,
                payload: result,
            });
        } else {
            yield put({
                type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_SUCCESS,
                payload: result,
            });
        }
    } catch (error) {
        yield put({
            type: BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_ERROR,
        });
    }
};

//POST /api/clients/users/getRelatedClientUsers
export const getRelatedClientUsersSaga = function* () {
    try {
        const result = yield request.post(`${controller}/users/getRelatedClientUsers`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_RELATED_CLIENT_USERS_ERROR,
            });

            return {
                done: true,
            };
        }


        yield put({
            type: GET_RELATED_CLIENT_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_RELATED_CLIENT_USERS_ERROR,
        });
    }
};

//POST  /api/clients/users/getPage
export const getMembersPageForGroupSaga = function* ({payload}) {
    try {

        const result = yield request.post(`${controller}/users/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_MEMBERS_PAGE_FOR_GROUP_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_MEMBERS_PAGE_FOR_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {

        yield put({
            type: GET_MEMBERS_PAGE_FOR_GROUP_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/patentContracts/{clientId}/get
export const getClientContractDetailsSaga = function* ({payload}) {
    const {clientId} = payload;

    try {
        const result = yield request.get(`${controller}/patentContracts/${clientId}/get`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
            payload: error,
        });
    }
};

//PATCH /api/clients/patentContracts/patch
export const updateClientContractDetailsSaga = function* ({payload}) {
    try {
        const {
            clientId,
        } = payload;

        const result = yield request.patch(`${controller}/patentContracts/patch`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        yield put(getClientContractDetails({clientId}));

        yield put({
            type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_ERROR,
            payload: error,
        });
    }
};

//GET /api/clients/civilContracts/get
export const getClientCivilContractSaga = function* ({payload}) {
    const {clientId} = payload;

    try {
        const result = yield request.get(`${controller}/civilContracts/get?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_CIVIL_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_CLIENT_CIVIL_CONTRACT_SUCCESS, payload: result});
    } catch (error) {
        yield put({
            type: GET_CLIENT_CIVIL_CONTRACT_ERROR,
            payload: error,
        });
    }
};

//PATCH /api/clients/civilContracts/patch
export const updateClientCivilContractSaga = function* ({payload}) {
    const {
        onSuccess,
        ...requestData
    } = payload;
    const {
        clientId,
    } = requestData;

    try {
        const result = yield request.patch(`${controller}/civilContracts/patch`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_CIVIL_CONTRACT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        yield put(getClientCivilContract({clientId}));
        yield put(getClientCardInfo({clientId}));

        yield put({
            type: UPDATE_CLIENT_CIVIL_CONTRACT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_CIVIL_CONTRACT_ERROR,
            payload: error,
        });
    }
};

export const getClientNameSListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`adm/common/filters${controller}/names/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_NAMES_LIST_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_GET_NAMES_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_GET_NAMES_LIST_ERROR,
        });
    }
};

export const getClientDefaultCategorySaga = function* ({payload}) {
    const {
        onSuccess,
    } = payload;

    try {
        const result = yield request.bff.get(`${bffController}/get-default-client-category`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_GET_DEFAULT_CATEGORY_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: CLIENT_GET_DEFAULT_CATEGORY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_GET_DEFAULT_CATEGORY_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_ADD_REQUEST, addClientSaga),
        takeEvery(BFF_CLIENT_ADD_REQUEST, bffAddClientSaga),
        takeEvery(CLIENT_SET_ARCHIVED_REQUEST, clientSetArchivedSaga),
        takeEvery(CLIENT_TERMINATE_CONTRACT_REQUEST, terminateContractClientSaga),
        takeEvery(CLIENT_GET_COMPANIES_REQUEST, getCompaniesSaga),
        takeEvery(CLIENT_SET_LOCKED_REQUEST, clientSetLockedSaga),
        takeEvery(CLIENT_UPDATE_FAVORITE_REQUEST, updateClientFavoriteSaga),
        takeEvery(CLIENT_SET_PROHIBITION_CONTRACTING_NON_RESIDENTS_REQUEST, setProhibitionContractingNonResidentsSaga),
        takeEvery(CLIENT_NAME_MAP_READ_REQUEST, getClientIdNameMapSaga),
        takeEvery(CLIENT_ROLE_MAP_READ_REQUEST, getRoleMapSaga),
        takeEvery(CLIENT_GROUP_PAGE_REQUEST, getClientGroupPageSaga),
        takeEvery(CLIENT_LIST_BY_PARTNER_REQUEST, getClientListByPartnerSaga),
        takeEvery(GET_FILE_NOTICE_REQUEST, getFileNoticeSaga),
        takeEvery(CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST, getOptimizedClientListSaga),
        takeEvery(BFF_CLIENT_GET_OPTIMIZED_CLIENT_LIST_REQUEST, getBffClientListOptimizedSaga),
        takeEvery(GET_RELATED_CLIENT_USERS_REQUEST, getRelatedClientUsersSaga),
        takeEvery(GET_MEMBERS_PAGE_FOR_GROUP_REQUEST, getMembersPageForGroupSaga),
        takeEvery(GET_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST, getClientContractDetailsSaga),
        takeEvery(UPDATE_CLIENT_PATENT_CONTRACT_DETAILS_REQUEST, updateClientContractDetailsSaga),
        takeEvery(CHECK_PAYMENTS_CREATION_PROHIBITED_REQUEST, checkPaymentsCreationProhibitedSaga),
        takeEvery(GET_CLIENT_CIVIL_CONTRACT_REQUEST, getClientCivilContractSaga),
        takeEvery(UPDATE_CLIENT_CIVIL_CONTRACT_REQUEST, updateClientCivilContractSaga),
        takeEvery(CLIENT_GET_NAMES_LIST_REQUEST, getClientNameSListSaga),
        takeEvery(CLIENT_GET_DEFAULT_CATEGORY_REQUEST, getClientDefaultCategorySaga),
    ]);
}
