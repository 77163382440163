export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR";

export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR";

export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS";
export const UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR = "UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR";

export const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST";
export const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS";
export const DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR = "DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR";

export const CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST = "CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST";
export const CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS = "CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS";
export const CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR = "CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR";

export const CONTRACTOR_TAX_STATUS_REFRESH_REQUEST = "CONTRACTOR_TAX_STATUS_REFRESH_REQUEST";
export const CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS = "CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS";
export const CONTRACTOR_TAX_STATUS_REFRESH_ERROR = "CONTRACTOR_TAX_STATUS_REFRESH_ERROR";

export const GET_INN_BY_PERSONAL_INFO_REQUEST = "GET_INN_BY_PERSONAL_INFO_REQUEST";
export const GET_INN_BY_PERSONAL_INFO_SUCCESS = "GET_INN_BY_PERSONAL_INFO_SUCCESS";
export const GET_INN_BY_PERSONAL_INFO_ERROR = "GET_INN_BY_PERSONAL_INFO_SUCCESS";

export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST";
export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS";
export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR";

export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST";
export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS";
export const CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR =
    "CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR";

export const UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST = "UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST";
export const UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS = "UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS";
export const UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR = "UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR";

export const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST";
export const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS";
export const CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR";

export const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST";
export const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS";
export const CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR = "CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR";

export const GET_PENDING_PERSONAL_DATA_REQUEST = "GET_PENDING_PERSONAL_DATA_REQUEST";
export const GET_PENDING_PERSONAL_DATA_SUCCESS = "GET_PENDING_PERSONAL_DATA_SUCCESS";
export const GET_PENDING_PERSONAL_DATA_ERROR = "GET_PENDING_PERSONAL_DATA_ERROR";

export const GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST = "GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST";
export const GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS = "GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS";
export const GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR = "GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR";

export const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST";
export const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS";
export const GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR = "GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR";

export const GET_CONTRACTOR_BANK_STATUS_REQUEST = "GET_CONTRACTOR_BANK_STATUS_REQUEST";
export const GET_CONTRACTOR_BANK_STATUS_SUCCESS = "GET_CONTRACTOR_BANK_STATUS_SUCCESS";
export const GET_CONTRACTOR_BANK_STATUS_ERROR = "GET_CONTRACTOR_BANK_STATUS_ERROR";

export const CONTRACTOR_UPDATE_REQUEST = "CONTRACTOR_UPDATE_REQUEST";
export const CONTRACTOR_UPDATE_SUCCESS = "CONTRACTOR_UPDATE_SUCCESS";
export const CONTRACTOR_UPDATE_ERROR = "CONTRACTOR_UPDATE_ERROR";

export const CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST";
export const CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS";
export const CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR = "CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR";

export const UPDATE_CONTRACTOR_PERSONAL_DATA_STORE = "UPDATE_CONTRACTOR_PERSONAL_DATA_STORE";