import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";
import {edmPositionsToOptions} from "../../../../../../utils/objectHelper";

export const edmPositionsSelector = state => state.bff.clientsEdoDirectoryPositions;
export const edmPositionTotalCountSelector = createSelector(edmPositionsSelector, ({totalCount}) => totalCount);
export const edmPositionListSelector = createSelector(edmPositionsSelector, ({list}) => list);
export const edmPositionListOptionsSelector = createSelector(edmPositionsSelector, ({list}) => edmPositionsToOptions(list));
export const edmPositionListProgressSelector = createSelector(edmPositionsSelector, ({progress}) => progress);
export const edmPositionActionProgressSelector = createSelector(edmPositionsSelector, ({actionProgress}) => actionProgress);
export const edmPositionListTotalPagesSelector = createSelector(edmPositionsSelector, ({
    totalCount,
    pageData: {pageSize = 0},
}) => getTotalPages(totalCount, pageSize));
export const edmPositionCommonCountSelector = createSelector(edmPositionsSelector, ({commonCount}) => commonCount);
export const edmPositionListPageDataSelector = createSelector(edmPositionsSelector, ({pageData}) => pageData);