import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import StepList from "../../../../components/ActualComponents/StepList";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {
    bffRecruitmentDirectoryFunnelListSelector,
    bffRecruitmentDirectoryFunnelTotalCountSelector,
    bffRecruitmentDirectoryFunnelTotalPagesSelector,
    bffRecruitmentDirectoryProgressActionSelector,
    bffRecruitmentDirectoryProgressSelector,
} from "../../../../ducks/bff/recruitment/directory/selectors";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {getRecruitmentDirectoriesAccessActions} from "../helpers/getAccessActions";
import RecruitmentDirectoriesFunnelEditModal from "./components/edit-modal";

import {usePagination} from "../../../../hooks/usePagination";
import useRecruitmentDirectoriesFunnelsAction from "./hooks/useAction";
import useRecruitmentDirectoriesFunnelsFetchList from "./hooks/useFetchList";
import {useRecruitmentDirectoriesFunnelsFilter} from "./hooks/useFilter";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

const ClientRecruitmentDirectoriesFunnels = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentDirectoryFunnelListSelector);
    const totalCount = useSelector(bffRecruitmentDirectoryFunnelTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentDirectoryFunnelTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentDirectoryProgressSelector);
    const progressAction = useSelector(bffRecruitmentDirectoryProgressActionSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = getRecruitmentDirectoriesAccessActions({
        role,
        clientProperties,
    });

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useRecruitmentDirectoriesFunnelsFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentDirectoriesFunnelsFetchList({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    const {
        confirmData,
        editModalData,
        isOpenEditForm,
        onCloseConfirm,
        openDeleteConfirm,
        openEditModal,
        closeEditModal,
    } = useRecruitmentDirectoriesFunnelsAction({
        clientId,
        fetchList,
    });

    const getMediaControls = (item) => {
        if (!isAccessAction) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => openEditModal(item),
                        color: "grey",
                        title: "Редактировать",
                        onlyIcon: true,
                        icon: <ModeIcon />,
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => openDeleteConfirm(item),
                        color: "grey",
                        title: "Удалить",
                        onlyIcon: true,
                        icon: <DeleteIcon />,
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const _statusList = item.statusList?.map(({statusModel}) => statusModel.name);

            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.name}
                        primarySubHeader={
                            <StepList
                                list={_statusList}
                            />
                        }
                        noDivider
                        classNameMainContent="col-16"
                        isFixedActions
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Подтвердить"
                cancelButton="Отмена"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
                disabled={progressAction}
            />
        );
    };

    const renderEditModal = () => {
        return (
            isOpenEditForm &&
            <RecruitmentDirectoriesFunnelEditModal
                clientId={clientId}
                editData={editModalData}
                onClose={closeEditModal}
                fetchList={fetchList}
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    size="xl"
                    children="Воронки"
                    tooltipText="Страница предназначена для управления воронками со статусами для возможности их
                    использования в вакансиях. Воронка статусов на уровне вакансии определяет по какой цепочке можно
                    перемещать кандидатов в рамках этой вакансии."
                />
            }
            controls={
                isAccessAction &&
                <NmButton
                    size="xl"
                    icon={<AddIcon />}
                    onClick={() => openEditModal({})}
                >
                    Добавить воронку
                </NmButton>
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progressList}
        >
            {renderEditModal()}
            {renderConfirmWindow()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progressList}
                    /> :
                    <CheckboxList rows={rows} />
            }
        </NmPage>
    );
};

export default ClientRecruitmentDirectoriesFunnels;