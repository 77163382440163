import React, {useMemo} from "react";

import ClientCardPage from "../../../../components/ClientCardPage";
import ClientCardKedoRoute from "./Route";

import {getUserRestrictions} from "../../../../utils/access";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS,
    LINK_CLIENT_KEDO_ORGANIZATION_STAFF_REGISTRY,
} from "../../../../constants/links";
import {ADMIN, CLIENT_ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";

const ClientCardKedo = (props) => {
    const clientId = props.match.params.clientId;

    const currentUserRestrictions = getUserRestrictions();
    const role = ls(USER_ROLE);

    const tabs = useMemo(() => {
        return [
            {
                active: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                name: "Сотрудники",
                isVisible: [
                    ADMIN,
                    NM_MANAGER,
                ].includes(role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessEmployeesKedo,
                    ]),
            },
            {
                active: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                name: "Документооборот",
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessDocumentFlowKedo,
                ]),
            },
            {
                active: [
                    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS.replace(":clientId", clientId),
                    LINK_CLIENT_KEDO_ORGANIZATION_STAFF_REGISTRY.replace(":clientId", clientId),
                ],
                link: LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS.replace(":clientId", clientId),
                name: "Организационная структура",
                isVisible: [ADMIN, NM_MANAGER, NM_MANAGER, NM_OPERATOR].includes(role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessOrgStructureKedo,
                    ]),
            },
            {
                active: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                name: "Экспорт документов",
                isVisible: [CLIENT_ADMIN].includes(role),
            },
            {
                active: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
                link: LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
                name: "Лог интеграции с 1С",
                isVisible: [ADMIN].includes(role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessLog1cKedo,
                    ]),
            },
        ];
    }, [
        currentUserRestrictions,
    ]);

    return (
        <ClientCardPage
            title="КЭДО"
            tabs={tabs}
        >
            <ClientCardKedoRoute {...props} />
        </ClientCardPage>
    );
};

export default ClientCardKedo;
