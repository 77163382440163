import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import Text from "../ActualComponents/Text";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";
import {withPageData} from "../withPageData";

import {ls, USER_ROLE} from "../../utils/localstorage";
import {removePhoneMask} from "../../utils/stringFormat";
import validate from "../../utils/validate";

import {CONTRACTOR_ADDING_TYPE, CONTRACTOR_FIELD} from "../../constants/contractor";
import {contractorMask} from "../../constants/contractorInfo";
import {addContractorToNmByEmailFormRule, addContractorToNmByPhoneFormRule} from "../../constants/validationRules";

import {
    emailInvitationProgressSelector,
    inviteViaEmail,
} from "../../ducks/email";
import {
    inviteViaSms,
    smsInvitationProgressSelector,
} from "../../ducks/sms";

import PropTypes from "prop-types";

import "./style.sass";

class InvitePerformerToNaimix extends Component {
    static propTypes = {
        close: PropTypes.func,
        clientId: PropTypes.string,
        isRegistryPayments: PropTypes.string,
    };

    static defaultProps = {
        close: () => {
        },
    };

    constructor(props) {

        super(props);

        this.state = {
            formData: {},
            errorForm: {},
            addingType: CONTRACTOR_ADDING_TYPE.BY_EMAIL,
        };

        this.role = ls(USER_ROLE);

        this.addingTypeOptions = [
            {
                key: CONTRACTOR_ADDING_TYPE.BY_EMAIL,
                text: "E-mail",
                value: CONTRACTOR_ADDING_TYPE.BY_EMAIL,
            },
            {
                key: CONTRACTOR_ADDING_TYPE.BY_PHONE,
                text: "SMS",
                value: CONTRACTOR_ADDING_TYPE.BY_PHONE,
            },
        ];
    }

    componentDidUpdate(prevProps) {
        const {
            inviteByEmailProgress,
            close,
        } = this.props;

        const {
            inviteByEmailProgress: _inviteByEmailProgress,
        } = prevProps;

        if (inviteByEmailProgress === false && _inviteByEmailProgress === true) {
            close();
        }
    }

    validateForm() {
        const {
            formData,
            addingType,
        } = this.state;

        formData.phone = removePhoneMask(formData.phone);

        const rule = addingType === CONTRACTOR_ADDING_TYPE.BY_EMAIL ? addContractorToNmByEmailFormRule : addContractorToNmByPhoneFormRule;

        const errorForm = validate(formData, rule, "");

        this.setState({errorForm});

        return Object.values(errorForm).length === 0;
    };

    sendInvite = () => {
        const {
            inviteViaEmail,
            inviteViaSms,
            clientId,
            close,
        } = this.props;

        const {
            formData: {
                email,
                phone,
            },
            addingType,
        } = this.state;

        if (!this.validateForm()) {
            return;
        }

        if (addingType === CONTRACTOR_ADDING_TYPE.BY_EMAIL) {
            inviteViaEmail({
                email,
                clientId,
                onSuccess: close,
            });

            return;
        }

        inviteViaSms({
            data: {
                phone: removePhoneMask(phone),
                clientId,
            },
            onSuccess: close,
        });
    };

    get localizationData() {
        const {t} = this.props;

        return {
            phoneNumber: t("add-contractor-to-nm-form.phone"),
            email: t("add-contractor-to-nm-form.email"),
            addingTypeText: t("add-contractor-to-nm-form.adding-type-text"),
            enterEmail: t("add-contractor-to-nm-form.enter-email"),
            enterPhone: t("add-contractor-to-nm-form.enter-phone"),
        };
    }

    handleChangeFormData = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    handleChange = (e, {value, name}) => {
        this.setState({
            [name]: value,
        });
    };

    render() {
        const {
            t,
            close,
            inviteByEmailProgress,
            inviteBySmsProgress,
        } = this.props;

        const {
            formData,
            errorForm,
            addingType,
        } = this.state;

        return (
            <NmModal
                size="md"
                header={
                    <NmTitle size="lg">
                        {t("add-contractor-to-nm-form.header")}
                    </NmTitle>
                }
                onClose={close}
                loading={inviteByEmailProgress || inviteBySmsProgress}
                footer={
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        cancelBtnContent={t("button.cancel")}
                        submitBtnContent={t("button.invite")}
                        onClose={close}
                        submit={this.sendInvite}
                    />
                }
            >
                <NmForm addMargin>
                    <Text level="3">
                        {t("add-contractor-to-nm-form.description")}
                    </Text>
                    <div className="row gx-4">
                        <div className="col-16 col-md-8">
                            <NmDropdownV2
                                label={this.localizationData.addingTypeText}
                                onChange={this.handleChange}
                                name="addingType"
                                className="invite-performer-to-naimix__dropdown"
                                value={addingType}
                                options={this.addingTypeOptions}
                            />
                        </div>
                        <div className="col-16 col-md-8">
                            {
                                addingType === CONTRACTOR_ADDING_TYPE.BY_PHONE &&
                                <NmInputV2
                                    size="xl"
                                    label={this.localizationData.phoneNumber}
                                    mask={contractorMask.PHONE.RUS}
                                    name={CONTRACTOR_FIELD.PHONE}
                                    value={formData[CONTRACTOR_FIELD.PHONE]}
                                    placeholder="Введите номер телефона"
                                    error={errorForm[CONTRACTOR_FIELD.PHONE]}
                                    onChange={this.handleChangeFormData}
                                    required
                                />
                            }
                            {
                                addingType === CONTRACTOR_ADDING_TYPE.BY_EMAIL &&
                                <NmInputV2
                                    size="xl"
                                    label={this.localizationData.email}
                                    name={CONTRACTOR_FIELD.EMAIL}
                                    placeholder="Введите E-mail"
                                    onChange={this.handleChangeFormData}
                                    error={errorForm[CONTRACTOR_FIELD.EMAIL]}
                                    value={formData[CONTRACTOR_FIELD.EMAIL]}
                                    required
                                />
                            }
                        </div>
                    </div>
                </NmForm>
            </NmModal>
        );
    };
}

export default withPageData(connect(
    state => ({
        inviteByEmailProgress: emailInvitationProgressSelector(state),
        inviteBySmsProgress: smsInvitationProgressSelector(state),
    }),
    {
        inviteViaEmail,
        inviteViaSms,
    },
)(withTranslation()(InvitePerformerToNaimix)));