import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_CLEAR_STORE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_UPDATE_STORE_ERROR,
} from "./actions";

export const getDeferredActsOfAcceptanceWorkCard = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST,
        payload,
    };
};

export const exportDeferredActsOfAcceptanceWorkCard = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST,
        payload,
    };
};

export const getDeferredActsOfAcceptanceWorkCardItemsPage = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST,
        payload,
    };
};

export const getDeferredActsOfAcceptanceWorkCardItemsStatusDict = () => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST,
    };
};

export const deleteDeferredActsOfAcceptanceWorkCardItems = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST,
        payload,
    };
};

export const updateDeferredActsOfAcceptanceWorkCardItem = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST,
        payload,
    };
};

export const updateDeferredActsOfAcceptanceWorkCardStore = () => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_UPDATE_STORE_ERROR,
    };
};

export const clearDeferredActsOfAcceptanceWorkCardStore = () => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_CLEAR_STORE_ERROR,
    };
};