import * as yup from "yup";

import {VALIDATIONS_MESSAGE} from "../../../../../../../constants/validationsYup";

export const getKedoTemplateDocumentTypesValidation = () => {
    return yup.object().shape({
        name: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Максимальная длина - 255 символов"),
        documentCode: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        nameForClient: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Максимальная длина - 50 символов"),
        templateId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
        routeId: yup.string().required(VALIDATIONS_MESSAGE.REQUIRED),
    });
};