import {
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR,
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST,
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS,
    CONTRACTOR_BANKS_UPDATE_STORE,
    CREATE_CONTRACTOR_BANK_IDENTIFICATION_ERROR,
    CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST,
    CREATE_CONTRACTOR_BANK_IDENTIFICATION_SUCCESS,
    GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_ERROR,
    GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST,
    GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_SUCCESS,
    GET_LIST_CONTRACTOR_BANKS_ERROR,
    GET_LIST_CONTRACTOR_BANKS_REQUEST,
    GET_LIST_CONTRACTOR_BANKS_SUCCESS,
    SENDING_CONTRACTOR_TO_BANK_ERROR,
    SENDING_CONTRACTOR_TO_BANK_REQUEST,
    SENDING_CONTRACTOR_TO_BANK_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    progress: false,
    progressAction: false,
    progressForciblySendingToBank: false,
    identificationLogsProgress: false,
    identificationLogs: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST:
            return {
                ...state,
                identificationLogsProgress: true,
            };
        case GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_ERROR:
            return {
                ...state,
                identificationLogsProgress: false,
            };
        case GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_SUCCESS:
            return {
                ...state,
                identificationLogsProgress: false,
                identificationLogs: payload.list,
            };
        case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_SUCCESS:
            return {
                ...state,
                progressForciblySendingToBank: false,
            };
        case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_ERROR:
            return {
                ...state,
                progressForciblySendingToBank: false,
            };
        case CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST:
            return {
                ...state,
                progressForciblySendingToBank: true,
            };
        case GET_LIST_CONTRACTOR_BANKS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_LIST_CONTRACTOR_BANKS_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload,
            };
        case GET_LIST_CONTRACTOR_BANKS_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST:
        case SENDING_CONTRACTOR_TO_BANK_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CREATE_CONTRACTOR_BANK_IDENTIFICATION_SUCCESS:
        case CREATE_CONTRACTOR_BANK_IDENTIFICATION_ERROR:
        case SENDING_CONTRACTOR_TO_BANK_SUCCESS:
        case SENDING_CONTRACTOR_TO_BANK_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case CONTRACTOR_BANKS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};