import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

const deferredActsOfAcceptanceWorkSelector = state => state.bff.deferredActsOfAcceptanceWork;

export const bffDeferredActsOfAcceptanceWorkListSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({list}) => list,
);
export const bffDeferredActsOfAcceptanceWorkPageDataSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({pageData}) => pageData,
);
export const bffDeferredActsOfAcceptanceWorkTotalCountSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({totalCount}) => totalCount,
);
export const bffDeferredActsOfAcceptanceWorkTotalPagesSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffDeferredActsOfAcceptanceWorkProgressSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({progress}) => progress,
);
export const bffDeferredActsOfAcceptanceWorkProgressActionSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({progressAction}) => progressAction,
);
export const bffDeferredActsOfAcceptanceWorkStatusDictOptionsSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({statusDict}) => dictionaryToOptions(statusDict),
);
export const bffDeferredActsOfAcceptanceWorkErrorSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({error}) => error,
);
export const bffDeferredActsOfAcceptanceWorkLogsSelector = createSelector(
    deferredActsOfAcceptanceWorkSelector,
    ({logs}) => logs,
);