import {
    CLEAR_EDO_PRE_FILLED_TEMPLATE, GET_DOCUMENT_INFO_COUNT_SUCCESS,
    GET_EDO_ACCESS_LIST_SUCCESS,
    GET_EDO_DOCUMENT_STATES_SUCCESS,
    GET_EDO_DOCUMENT_TYPES_REQUEST,
    GET_EDO_DOCUMENT_TYPES_SUCCESS,
    GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS,
    GET_PAGE_EDO_STAFF_SUCCESS,
    GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST,
    GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS,
    SIGN_EDO_DOCUMENT_ERROR,
    SIGN_EDO_DOCUMENT_REQUEST,
    SIGN_EDO_DOCUMENT_SUCCESS,
    UPDATE_EDO_DOCUMENTS_STORE,
} from "./actions";

export const EDO_STAFF_FIELD_LIST = {
    RECEIVERS: "receivers",
    SENDERS: "senders",
    APPROVER: "approver",
};

const initial = {
    documents: [],
    totalCount: 0,
    pageData: {},
    documentInfoCount: 0,
    documentTypes: {
        pageData: {},
        totalCount: 0,
        list: [],
    },
    statementTypes: {
        pageData: {},
        totalCount: 0,
        list: [],
    },
    statuses: [],
    [EDO_STAFF_FIELD_LIST.RECEIVERS]: [],
    [EDO_STAFF_FIELD_LIST.SENDERS]: [],
    [EDO_STAFF_FIELD_LIST.APPROVER]: [],
    accessList: [],
    preFilledTemplate: undefined,
    signProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case UPDATE_EDO_DOCUMENTS_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST:
            const {
                pageSize,
                pageNum,
            } = payload;
            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_EDO_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                documentTypes: {
                    ...state.documentTypes,
                    pageData: payload,
                },
            };
        case SIGN_EDO_DOCUMENT_REQUEST:
            return {
                ...state,
                signProgress: true,
            };
        case GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS:
            const {
                content: documents = [],
                totalCount,
            } = payload;

            return {
                ...state,
                totalCount,
                documents,
            };
        case GET_EDO_DOCUMENT_STATES_SUCCESS:
            const {statuses = []} = payload;

            return {
                ...state,
                statuses,
            };
        case GET_EDO_DOCUMENT_TYPES_SUCCESS: {
            const {
                results: list = [],
                totalCount,
            } = payload;

            return {
                ...state,
                documentTypes: {
                    ...state.documentTypes,
                    list,
                    totalCount,
                },
            };
        }
        case GET_PAGE_EDO_STAFF_SUCCESS:
            const {
                results: staffList = [],
                field,
            } = payload;

            return {
                ...state,
                [field]: staffList,
            };
        case GET_EDO_ACCESS_LIST_SUCCESS:
            return {
                ...state,
                accessList: Object.values(payload),
            };

        case GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS:
            return {
                ...state,
                preFilledTemplate: payload,
            };
        case CLEAR_EDO_PRE_FILLED_TEMPLATE:
            return {
                ...state,
                preFilledTemplate: undefined,
            };
        case GET_DOCUMENT_INFO_COUNT_SUCCESS:
            return {
                ...state,
                documentInfoCount: payload,
            };
        case SIGN_EDO_DOCUMENT_SUCCESS:
        case SIGN_EDO_DOCUMENT_ERROR:
            return {
                ...state,
                signProgress: false,
            };
        default:
            return state;
    }
};