export const CLIENT_PROJECTS_GET_PAGE_REQUEST = "CLIENT_PROJECTS_GET_PAGE_REQUEST";
export const CLIENT_PROJECTS_GET_PAGE_SUCCESS = "CLIENT_PROJECTS_GET_PAGE_SUCCESS";
export const CLIENT_PROJECTS_GET_PAGE_ERROR = "CLIENT_PROJECTS_GET_PAGE_ERROR";

export const CLIENT_PROJECTS_ADD_REQUEST = "CLIENT_PROJECTS_ADD_REQUEST";
export const CLIENT_PROJECTS_ADD_SUCCESS = "CLIENT_PROJECTS_ADD_SUCCESS";
export const CLIENT_PROJECTS_ADD_ERROR = "CLIENT_PROJECTS_ADD_ERROR";

export const CLIENT_PROJECTS_UPDATE_REQUEST = "CLIENT_PROJECTS_UPDATE_REQUEST";
export const CLIENT_PROJECTS_UPDATE_SUCCESS = "CLIENT_PROJECTS_UPDATE_SUCCESS";
export const CLIENT_PROJECTS_UPDATE_ERROR = "CLIENT_PROJECTS_UPDATE_ERROR";

export const CLIENT_PROJECTS_CHANGE_STATUS_REQUEST = "CLIENT_PROJECTS_CHANGE_STATUS_REQUEST";
export const CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS = "CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS";
export const CLIENT_PROJECTS_CHANGE_STATUS_ERROR = "CLIENT_PROJECTS_CHANGE_STATUS_ERROR";

export const CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST = "CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST";
export const CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS = "CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS";
export const CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR = "CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR";

export const CLIENT_PROJECTS_IMPORT_REQUEST = "CLIENT_PROJECTS_IMPORT_REQUEST";
export const CLIENT_PROJECTS_IMPORT_SUCCESS = "CLIENT_PROJECTS_IMPORT_SUCCESS";
export const CLIENT_PROJECTS_IMPORT_ERROR = "CLIENT_PROJECTS_IMPORT_ERROR";

export const CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST = "CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST";
export const CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS = "CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS";
export const CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR = "CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR";

export const CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST = "CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST";
export const CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS = "CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS";
export const CLIENT_PROJECTS_ADD_DEPOSIT_ERROR = "CLIENT_PROJECTS_ADD_DEPOSIT_ERROR";

export const CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST = "CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST";
export const CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS = "CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS";
export const CLIENT_PROJECTS_HISTORY_GET_PAGE_ERROR = "CLIENT_PROJECTS_HISTORY_GET_PAGE_ERROR";

export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST";
export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS";
export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR";

export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST";
export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS";
export const CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR = "CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR";

export const CLIENT_PROJECTS_UPDATE_STORE = "CLIENT_PROJECTS_UPDATE_STORE";

export const CLIENT_PROJECTS_CLEAR_STORE = "CLIENT_PROJECTS_CLEAR_STORE";