import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DepositCalculator from "../../containers/deposit/deposit-calculator";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import DropdownWithInput from "../ActualComponents/DropdownWithInput";
import HelpTooltip from "../ActualComponents/HelpTooltip";
import InputWithDropdownV2 from "../ActualComponents/InputWithDropdownV2";
import InputWithIcon from "../ActualComponents/InputWithIcon";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmDatePicker from "../ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import NmTextareaV2 from "../ActualComponents/NmTextareaV2";
import AmountInput from "../AmountInput";
import ApplyButtons from "../ApplyButtons";
import ButtonCalculator from "../ButtonCalculator";
import NmRatingFieldsForm from "../NmRatingFieldsForm";
import NmTitle from "../NmTitle";
import TemplatesDropdown from "../TemplatesDropdown";

import formatDate, {convertUtcToLocal, getDateObject} from "../../utils/dateFormat";
import {handleCardRate} from "../../utils/mathHelper";
import {getPaymentMethodOptionsByClientPaymentsSettings} from "../../utils/paymentMethodHelper";
import {replaceParams} from "../../utils/replacer";
import {
    formatAmount,
    formatNumber,
    getFullName,
    getNumberFromFormattedAmount,
} from "../../utils/stringFormat";
import {isNullOrWhitespace} from "../../utils/stringHelper";
import validate from "../../utils/validate";
import {calculatePayPopupWithRatingsAmounts} from "./utils/calculate";
import {getPayPopupWithRatingUndefinedValue} from "./utils/getUndefinedValue";
import {getActCreationValidationRule} from "./utils/getValidationRule";

import {ORDER_FIELDS_NAME} from "../../constants/clientList";
import {COLOR} from "../../constants/color";
import {ORDER_KIND} from "../../constants/order";
import {PAYMENT_TYPE} from "../../constants/settings";
import {TEMPLATES_TYPE_DOCUMENTS} from "../../containers/templates/constants";

import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";
import {
    getClientsSettingsPayments,
    updateStoreClientsSetting,
} from "../../ducks/bff/clients/settings/actionCreators";
import {bffClientsSettingsPaymentsSelector} from "../../ducks/bff/clients/settings/selectors";
import {ordersRegistryCardProgressSelector} from "../../ducks/bff/orders/orders-registry/selectors";
import {contractorOrderWorkReportInfoSelector, getContractorOrderWorkReportInfo} from "../../ducks/contractor";
import {checkStopWords, getStopWordProgressPageSelector} from "../../ducks/stopWords";

import "./style.sass";

const toNumber = (value) => formatAmount(formatNumber(value));

const ActCreation = (props) => {
    const {
        isModal = true,
        isComment,
        orderWorkTemplate = {},
        isTemplateAct,
        isTaskAct = false,
        clientId,
        orderId,
        taskId,
        orderContractPaymentType,
        orderCreatedByTemplateId,
        handleClose,
        orderDescription,
        taskDescription,
        taskContractorInfo,
        amount,
        startOrderDate,
        workUnit,
        workUnitPrice,
        contractorCommission,
        contractorId,
        header = "Оформление акта выполненных работ (оказанных услуг)",
        submitBtnContent = "Отправить акт исполнителю",
        paymentInfo: {
            contractorVolumePaid = 0,
            orderVolumePaid = 0,
            orderPaid,
            taskAmount,
        },
        formData: {
            description,
            descriptionFns,
            name,
            comment,
            reviewText,
            sumOfMoney = 0,
            amountForReport = 0,
            volumeOfWork,
            actOfAcceptanceOfWorkTemplateId,
            actOfAcceptanceOfTaskTemplateId,
            workEndDate,
            workStartDate = startOrderDate,
            actDate,
            paymentMethod,
            descriptionParams,
            needContractorApprove,
        },
        orderKind,
        isForemanForm,
        abbreviationMap,
        abbreviationOptions,
        volumeOfWork: volumeOfOrderWork,
        progress,
        handleChange,
        handleOnRate,
        workQualityScore,
        estimatedTimeScore,
        className = "",
        submit,
    } = props;

    const dispatch = useDispatch();

    const [formError, setFormError] = useState({});
    const {
        descriptionErrorMessage,
        descriptionFnsErrorMessage,
        sumOfMoneyErrorMessage,
        workStartDateErrorMessage,
        workEndDateErrorMessage,
        volumeSumErrorMessage,
        volumeOfWorkErrorMessage,
        reviewTextErrorMessage,
        paymentMethodErrorMessage,
        descriptionParamsErrorMessage,
    } = formError;
    const [isOpenCalculator, setOpenCalculator] = useState(false);
    const [calculateData, setCalculateData] = useState({
        amount: 0,
        card: 0,
        tax: 0,
        commission: 0,
    });
    const clientProperties = useSelector(clientCardPropertiesSelector);
    const contractorOrderWorkInfo = useSelector(contractorOrderWorkReportInfoSelector);
    const progressOrder = useSelector(ordersRegistryCardProgressSelector);
    const progressStopWordChecking = useSelector(getStopWordProgressPageSelector);
    const paymentsSettings = useSelector(bffClientsSettingsPaymentsSelector);

    const isVolumeOrderType = orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER;
    const {
        actDateAvailable,
    } = clientProperties;
    const isShowDescriptionParams = props.isShowDescriptionParams &&
        typeof orderWorkTemplate.descriptionParamsCount === "number" &&
        orderWorkTemplate.descriptionParamsCount > 0;
    const paymentMethodOptions = getPaymentMethodOptionsByClientPaymentsSettings({
        paymentsSettings,
        paymentType: PAYMENT_TYPE.MARKETPLACE,
        contractType: orderContractPaymentType,
    });

    useEffect(() => {
        dispatch(getClientsSettingsPayments({clientId}));

        if (isTemplateAct || isTaskAct) {
            return;
        }

        dispatch(
            getContractorOrderWorkReportInfo({
                data: {
                    clientId,
                    orderId,
                    contractorId,
                },
            }),
        );

        return () => {
            dispatch(updateStoreClientsSetting({payments: {}}));
        };
    }, []);

    useEffect(() => {
        const {
            amount,
            tax,
            commission,
            card,
        } = calculatePayPopupWithRatingsAmounts({
            orderKind,
            volumeOfWork,
            workUnitPrice,
            contractorCommission,
            sumOfMoney,
            isTemplateAct,
            isTaskAct,
            taskAmount,
        });

        setCalculateData({
            amount,
            tax,
            commission,
            card,
        });
    }, [volumeOfWork, sumOfMoney, workUnitPrice, contractorCommission, taskAmount]);

    const getJobEvaluationRatingContent = () => {
        const {
            workQualityScoreErrorMessage,
            estimatedTimeScoreErrorMessage,
        } = formError;

        return [
            {
                disabled: Boolean(orderWorkTemplate.contractorWorkQualityScore),
                rating: workQualityScore,
                name: "workQualityScore",
                label: "Оценка качества выполненных работ:",
                newError: workQualityScoreErrorMessage,
            },
            {
                disabled: Boolean(orderWorkTemplate.contractorEstimatedTimeScore),
                rating: estimatedTimeScore,
                name: "estimatedTimeScore",
                label: "Оценка по срокам выполненных работ:",
                newError: estimatedTimeScoreErrorMessage,
            },
        ];
    };

    const validateVolumeSum = () => {
        if (isTemplateAct || isTaskAct) {
            return {};
        }

        const volumeSum = getNumberFromFormattedAmount(volumeOfWork || 0) * workUnitPrice;
        let errorText = null;
        
        if (volumeSum < 10) {
            errorText = "Сумма должна быть больше 10 руб.";
        }

        if (isVolumeOrderType && !isEmpty(errorText)) {
            return {
                volumeSumErrorMessage: errorText,
            };
        }
        return {};
    };

    const validationForm = () => {
        if (isTemplateAct) {
            return true;
        }

        const checkedData = {
            workQualityScore,
            estimatedTimeScore,
            description,
            descriptionFns,
            volumeOfWork,
            workStartDate,
            workEndDate,
            actDate,
            name,
            paymentMethod,
            descriptionParams,
            descriptionParamsCount: orderWorkTemplate.descriptionParamsCount,
            sumOfMoney: sumOfMoney ? getNumberFromFormattedAmount(sumOfMoney) : sumOfMoney,
            reviewText,
        };

        const rule = getActCreationValidationRule({
            isVolumeOrderType,
            isShowDescriptionParams,
            isTaskAct,
            workQualityScore,
            estimatedTimeScore,
        });

        const formErrors = validate(checkedData, rule);

        const volumeSumError = validateVolumeSum();

        const errors = {
            ...formErrors,
            ...volumeSumError,
        };

        setFormError(errors);

        return Object.values(errors).length === 0;
    };

    const _checkStopWords = () => {
        // При создании шаблона акта могут не заполняться
        if (!description && !descriptionFns) {
            submit();

            return;
        }

        dispatch(checkStopWords({
            data: {
                errorMessageType: "CREATE_ORDER_WORK_REPORT",
                queries: [description, descriptionFns].filter(value => value),
            },
            onSuccess: submit,
        }));
    };

    const submitForm = () => {
        const isValid = validationForm();

        if (!isValid) {
            return;
        }

        _checkStopWords();
    };

    const onOpenCalculator = () => {
        setOpenCalculator(!isOpenCalculator);
    };

    const handleChangeFields = (event, {name, value}) => {
        if (name === "descriptionParams") {
            const replaces = value.map(item => item.content);
            const descriptionValue = replaceParams(orderWorkTemplate.description, replaces);

            handleChange(event, {name, value});
            handleChangeFields(event, {name: "description", value: descriptionValue});
        }

        if (name === "description") {
            handleChange(event, {name: "descriptionFns", value: value.slice(0, 160)});
        }

        if (name === "sumOfMoney") {
            handleChange(event, {name: "amountForReport", value});
        }

        handleChange(event, {name, value});
    };

    const renderVolumeSumField = () => {
        if (!isVolumeOrderType || isTemplateAct) {
            return null;
        }

        return (
            <div className="act-creation__row col-16 col-md-8 col-xxl-16">
                <InputWithDropdownV2
                    size="xl"
                    label="Оплачиваемый объём работ:"
                    className="act-creation__volume-field"
                    disabledDropdown={true}
                    selectName={ORDER_FIELDS_NAME.WORK_UNIT}
                    valueSelect={workUnit}
                    valueInput={volumeOfWork}
                    error={volumeOfWorkErrorMessage || volumeSumErrorMessage}
                    options={abbreviationOptions}
                    input={
                        <AmountInput
                            newInput
                            addZeroAfterDivider
                            value={volumeOfWork}
                            placeholder="0,00"
                            name="volumeOfWork"
                            disabled={
                                workUnit === "SERVICE"
                            }
                            size="xl"
                            onChange={handleChangeFields}
                        />
                    }
                />
            </div>
        );
    };

    const renderDescriptionBlock = () => {
        if (isTaskAct) {
            return (
                <div className="act-creation__info row gx-8">
                    <div className="act-creation__description-container col-16">
                        <div className="act-creation__description">
                            {taskDescription || "-"}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="act-creation__info row gx-8">
                <div className="act-creation__description-container col-16 col-md-9">
                    <div className="act-creation__description">
                        {orderDescription || "-"}
                    </div>
                </div>
                <div className="act-creation__stats-container col-16 col-md-7">
                    <div className="act-creation__stats">
                        <div className="act-creation__info-row">
                            <div className="act-creation__info-label act-creation__info-label_fixed">
                                Сумма заказа, ₽:
                            </div>
                            <div className="act-creation__info-value act-creation__info-value_fixed">
                                {amount ? toNumber(amount) : "-"}
                            </div>
                        </div>
                        {
                            isVolumeOrderType &&
                            <>
                                <div className="act-creation__info-row">
                                    <div
                                        className="act-creation__info-label act-creation__info-label_fixed"
                                    >
                                        Общий объём работ:
                                    </div>
                                    <div
                                        className="act-creation__info-value act-creation__info-value_fixed"
                                    >
                                        {`${toNumber(volumeOfOrderWork || 0)} ${abbreviationMap.get(workUnit)}`}
                                    </div>
                                </div>
                                <div className="act-creation__info-row">
                                    <div
                                        className="act-creation__info-label act-creation__info-label_fixed"
                                    >
                                        Стоимость за единицу, ₽:
                                    </div>
                                    <div
                                        className="act-creation__info-value act-creation__info-value_fixed"
                                    >
                                        {workUnitPrice ? toNumber(workUnitPrice || 0) : "-"}
                                    </div>
                                </div>
                            </>
                        }
                        <div className="act-creation__info-row">
                            <div className="act-creation__info-label act-creation__info-label_fixed">
                                Оплачено по заказу, ₽:
                            </div>
                            <div className="act-creation__info-value act-creation__info-value_fixed">
                                {toNumber(orderPaid)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getAmountBasedSum = () => {
        if (isTemplateAct) {
            return null;
        }

        if (isTaskAct) {
            return (
                <div className="act-creation__info-row act-creation__info-row_input">
                    <AmountInput
                        label="Сумма задания, ₽:"
                        newInput
                        size="xl"
                        value={taskAmount}
                        disabled={true}
                    />
                </div>
            );
        }

        return (
            <div className="act-creation__info-row act-creation__info-row_input">
                <InputWithIcon
                    label="Сумма исполнителю, ₽:"
                    required
                    newInput
                    size="xl"
                    placeholder="0"
                    name="sumOfMoney"
                    value={sumOfMoney}
                    onChange={handleChangeFields}
                    error={sumOfMoneyErrorMessage}
                    iconColor={COLOR.SECONDARY_40}
                    icon={ModeIcon}
                />
            </div>
        );
    };

    const getVolumeBasedSum = () => {
        return (
            <div className="act-creation__info-row act-creation__info-row_input">
                <div className="act-creation__calculate-label">
                    Сумма исполнителю, ₽:
                </div>
                <div className="act-creation__calculate-value">
                    {
                        volumeOfWork && workUnitPrice ?
                            formatAmount(formatNumber(((getNumberFromFormattedAmount(volumeOfWork || 0)) * workUnitPrice).toFixed(2)), 2) :
                            "-"
                    }
                </div>
            </div>
        );
    };

    const getDescriptionParams = () => {
        const {
            descriptionParamsCount = "",
        } = orderWorkTemplate;

        if (!isShowDescriptionParams) {
            return null;
        }

        const paramsCount = descriptionParams ? descriptionParams.length : 0;

        return (
            <div className="act-creation__row col-16 col-md-16 col-xxl-16">
                <DropdownWithInput
                    isShowChevron={false}
                    error={descriptionParamsErrorMessage}
                    values={descriptionParams}
                    name="descriptionParams"
                    required={true}
                    label={`Параметры выполненных работ (${paramsCount} из ${descriptionParamsCount})`}
                    placeholder="Введите значение"
                    onChange={handleChangeFields}
                    maxLength={descriptionParamsCount}
                    tooltip={
                        <HelpTooltip
                            info
                            hover
                            text="Значения из данного поля будут использоваться для заполнения описания параметров выполненных работ"
                            position="bottom"
                        />
                    }
                    size="xl"
                />
            </div>
        );
    };

    const renderForm = () => {
        return (
            <>
                <div className="row gx-8">
                    <div className="col-16 col-xxl-9">
                        <div className="row gy-4 gx-4">
                            <div className="act-creation__row col-16 col-md-16 col-xxl-16">
                                <NmTextareaV2
                                    minRows={2}
                                    maxRows={3}
                                    maxLength={5000}
                                    error={
                                        props.formError?.description ||
                                        descriptionErrorMessage
                                    }
                                    required={!isTemplateAct}
                                    disabled={Boolean(orderWorkTemplate.description)}
                                    value={description || ""}
                                    name="description"
                                    label="Описание выполненных работ для акта"
                                    placeholder="Описание работ"
                                    onChange={handleChangeFields}
                                />
                            </div>
                            {getDescriptionParams()}
                            <div className="act-creation__row col-16 col-md-16 col-xxl-16">
                                <NmInputV2
                                    error={descriptionFnsErrorMessage}
                                    value={descriptionFns || ""}
                                    name="descriptionFns"
                                    required={!isTemplateAct}
                                    disabled={Boolean(orderWorkTemplate.descriptionFns)}
                                    label="Описание выполненных работ для чека ФНС России"
                                    placeholder="Описание работ"
                                    onChange={handleChangeFields}
                                    maxLength={160}
                                    size={"xl"}
                                />
                            </div>
                            {
                                !isForemanForm && !isTemplateAct &&
                                <div className="act-creation__row col-16 col-md-8 col-xxl-16">
                                    <>
                                        <NmDateRangePickerV2
                                            startFieldName="workStartDate"
                                            endFieldName="workEndDate"
                                            label="Период выполнения работ"
                                            className="act-creation__period"
                                            isClearable
                                            minDate={{
                                                start: getDateObject(startOrderDate),
                                            }}
                                            disabled={Boolean(orderWorkTemplate.workEndDate || orderWorkTemplate.workStartDate)}
                                            isCurrentDateMax
                                            error={
                                                props.formError?.workStartDate ||
                                                props.formError?.workEndDate ||
                                                workStartDateErrorMessage ||
                                                workEndDateErrorMessage
                                            }
                                            value={{
                                                workStartDate: getDateObject(workStartDate),
                                                workEndDate: getDateObject(workEndDate),
                                            }}
                                            onChange={handleChangeFields}
                                            size={"xl"}
                                        />
                                    </>
                                </div>
                            }
                            {
                                !isTemplateAct && actDateAvailable &&
                                <div className="act-creation__row col-16 col-md-8 col-xxl-16">
                                    <NmDatePicker
                                        size="xl"
                                        label="Дата формирования акта"
                                        placeholderText="Выберите дату"
                                        dateFormat="dd.MM.yy"
                                        selected={actDate}
                                        disabled={Boolean(orderWorkTemplate.actDate)}
                                        minDate={getDateObject(workStartDate)}
                                        error={formError.actDateErrorMessage}
                                        maxDate={new Date()}
                                        name="actDate"
                                        onChange={handleChangeFields}
                                    />
                                </div>
                            }
                            {
                                !isTemplateAct && !isTaskAct &&
                                <TemplatesDropdown
                                    disabled={
                                        Boolean(orderCreatedByTemplateId) &&
                                        Boolean(actOfAcceptanceOfWorkTemplateId)
                                    }
                                    isFetchDefault={!actOfAcceptanceOfWorkTemplateId}
                                    orderIdFilter={orderId}
                                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_WORK}
                                    name="actOfAcceptanceOfWorkTemplateId"
                                    clientIdFilter={clientId}
                                    orderContractPaymentTypeFilter={orderContractPaymentType}
                                    orderKindFilter={orderKind}
                                    value={actOfAcceptanceOfWorkTemplateId}
                                    onChange={handleChangeFields}
                                    buttonText="Скачать акт"
                                />
                            }
                            {
                                isTaskAct &&
                                <TemplatesDropdown
                                    isFetchDefault={!actOfAcceptanceOfTaskTemplateId}
                                    documentTypeFilter={TEMPLATES_TYPE_DOCUMENTS.ACT_OF_ACCEPTANCE_OF_TASK}
                                    name="actOfAcceptanceOfTaskTemplateId"
                                    clientIdFilter={clientId}
                                    value={actOfAcceptanceOfTaskTemplateId}
                                    onChange={handleChangeFields}
                                    buttonText="Скачать акт"
                                />
                            }
                            {
                                !isTaskAct &&
                                <div className="act-creation__row col-16-16 mt-3 mt-md-4">
                                    <NmDropdownV2
                                        required={!isTemplateAct}
                                        label="Способ проведения оплаты"
                                        placeholder="Выберите способ проведения оплаты"
                                        name="paymentMethod"
                                        options={paymentMethodOptions}
                                        value={paymentMethod}
                                        onChange={handleChangeFields}
                                        error={paymentMethodErrorMessage}
                                    />
                                </div>
                            }
                            {
                                isComment &&
                                <div className="act-creation__row col-16 mt-3 mt-md-4">
                                    <NmTextareaV2
                                        minRows={2}
                                        maxRows={3}
                                        maxLength={5000}
                                        value={comment || ""}
                                        name="comment"
                                        label="Комментарий"
                                        placeholder="Оставьте комментарий"
                                        onChange={handleChangeFields}
                                    />
                                </div>
                            }
                            {renderVolumeSumField()}
                            {
                                isVolumeOrderType &&
                                <div className="act-creation__row col-16">
                                    <div
                                        className="act-creation__info-row act-creation__info-row_volume"
                                    >
                                        <div className="act-creation__info-label">
                                            Оплаченный объём по заказу:
                                        </div>
                                        <div className="act-creation__info-value">
                                            {orderVolumePaid || 0}
                                            {" "}
                                            {abbreviationMap.get(workUnit)}
                                        </div>
                                    </div>
                                    <div
                                        className="act-creation__info-row act-creation__info-row_volume"
                                    >
                                        <div className="act-creation__info-label">
                                            Оплаченный объём по исполнителю:
                                        </div>
                                        <div className="act-creation__info-value">
                                            {contractorVolumePaid || 0}
                                            {" "}
                                            {abbreviationMap.get(workUnit)}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="act-creation__divider" />
                        <div className="act-creation__rating">
                            <NmRatingFieldsForm
                                handleOnRate={handleOnRate}
                            >
                                {getJobEvaluationRatingContent()}
                            </NmRatingFieldsForm>
                        </div>
                        <div className="act-creation__row col-16 mt-3 mt-md-4">
                            <NmTextareaV2
                                minRows={2}
                                maxRows={3}
                                maxLength={1000}
                                error={reviewTextErrorMessage}
                                value={reviewText || ""}
                                name="reviewText"
                                disabled={Boolean(orderWorkTemplate.reviewText)}
                                label="Отзыв об исполнителе"
                                placeholder="Напишите свой отзыв об исполнителе"
                                onChange={handleChangeFields}
                            />
                        </div>
                    </div>
                    <div className="col-16 col-xxl-7">
                        <div className="act-creation__calculate">
                            {
                                orderKind === ORDER_KIND.VOLUME_OF_FORK_BASED_ORDER ?
                                    getVolumeBasedSum() :
                                    getAmountBasedSum()
                            }
                            <div className="act-creation__info-row">
                                <div
                                    className="act-creation__info-label act-creation__info-label_calculate"
                                >
                                    На
                                    {" "}
                                    {isTaskAct ? "баланс" : "карту"}
                                    {" "}
                                    исполнителю, ₽:
                                </div>
                                <div
                                    className="act-creation__info-value act-creation__info-value_calculate"
                                >
                                    {getPayPopupWithRatingUndefinedValue({
                                        value: calculateData.card,
                                        content: getNumberFromFormattedAmount(formatNumber(calculateData.card, 2)),
                                    })}
                                </div>
                            </div>
                            <div className="act-creation__info-row">
                                <div
                                    className="act-creation__info-label act-creation__info-label_calculate"
                                >
                                    Налог исполнителя, ₽:
                                </div>
                                <div
                                    className="act-creation__info-value act-creation__info-value_calculate"
                                >
                                    {getPayPopupWithRatingUndefinedValue({
                                        value: calculateData.tax,
                                        content: isTaskAct ? `${calculateData.tax} (6%)` : `+ ${calculateData.tax}`,
                                    })}
                                </div>
                            </div>
                            {
                                !isTaskAct &&
                                <>
                                    <div className="act-creation__info-row">
                                        <div
                                            className="act-creation__info-label act-creation__info-label_calculate"
                                        >
                                            Комиссия с исполнителя, ₽:
                                        </div>
                                        <div className="act-creation__info-value act-creation__info-value_calculate">
                                            {getPayPopupWithRatingUndefinedValue({
                                                value: calculateData.commission,
                                                content: `+ ${calculateData.commission}(${handleCardRate(contractorCommission)}%)`,
                                            })}
                                        </div>
                                    </div>
                                    {
                                        clientProperties.amountForReport &&
                                        <div className="mt-4 mt-md-6">
                                            <InputWithIcon
                                                label="Сумма для отчета, ₽:"
                                                newInput
                                                size="xl"
                                                placeholder="0"
                                                name="amountForReport"
                                                value={amountForReport}
                                                onChange={handleChangeFields}
                                                iconColor={COLOR.SECONDARY_40}
                                                icon={ModeIcon}
                                            />
                                        </div>
                                    }
                                    <ButtonCalculator
                                        className="act-creation__calc-btn"
                                        onClick={onOpenCalculator}
                                    />
                                </>
                            }
                        </div>
                        {
                            isTaskAct &&
                            <NmCheckboxV2
                                className="mt-3 mt-md-4"
                                name="needContractorApprove"
                                onChange={handleChange}
                                checked={needContractorApprove}
                                label="Требуется подтверждение акта исполнителем"
                            />
                        }
                    </div>
                </div>
            </>
        );
    };

    const renderDepositCalculator = () => {
        return isOpenCalculator &&
            <DepositCalculator
                handleClose={onOpenCalculator}
            />;
    };

    const renderTitleBlock = () => {
        const {
            firstname,
            lastname,
            patronymic,
            contractDate,
            actDate,
            lastPaymentDate,
            applicationDate,
        } = contractorOrderWorkInfo;

        const fullName = isTaskAct ?
            taskContractorInfo.contractorName :
            getFullName(lastname, firstname, patronymic);
        const _contractDate = isTaskAct ? taskContractorInfo.contractSigningDateTime : contractDate;
        const _applicationDate = isTaskAct ? taskContractorInfo.applicationSigningDateTime : applicationDate;

        return (!isNullOrWhitespace(fullName) &&
            <div className="act-creation__contractor-info">
                <div
                    className="act-creation__contractor-info-value"
                >
                    {fullName || ""}
                </div>
                {
                    !isNullOrWhitespace(_contractDate) &&
                    <div className="act-creation__contractor-info-value">
                        {`РД от ${formatDate(convertUtcToLocal(_contractDate), "dd.MM.yyyy")}`}
                    </div>
                }
                {
                    !isNullOrWhitespace(_applicationDate) &&
                    <div className="act-creation__contractor-info-value">
                        {`Заявка от ${formatDate(convertUtcToLocal(_applicationDate), "dd.MM.yyyy")}`}
                    </div>
                }
                {
                    !isTaskAct &&
                    <div className="act-creation__contractor-info-value">
                        Дата последней отправки акта (дата акта):
                        {" "}
                        {
                            !lastPaymentDate && !actDate ? "-" :
                                `${formatDate(convertUtcToLocal(lastPaymentDate), "dd.MM.yyyy") || "-"} (${formatDate(convertUtcToLocal(actDate), "dd.MM.yyyy") || "-"})`
                        }
                    </div>
                }
            </div>
        );
    };

    const getForm = () => {
        return (
            <>
                <NmForm className="act-creation__form">
                    {renderTitleBlock()}
                    <div className="act-creation__h">
                        Описание работ:
                    </div>
                    {renderDescriptionBlock()}
                    <div className="act-creation__divider act-creation__divider_mobile" />
                    {renderForm()}
                </NmForm>
                {renderDepositCalculator()}
            </>
        );
    };

    if (!isModal) {
        return getForm();
    }

    return (
        <NmModal
            onClose={handleClose}
            header={
                <NmTitle size="lg">
                    {header}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    classNameMainButtons="fluid flex-content-spaced"
                    cancelBtnContent="Отменить"
                    onClose={handleClose}
                    submitBtnContent={submitBtnContent}
                    submit={submitForm}
                    loading={progress || progressStopWordChecking}
                    disabled={progress || progressStopWordChecking}
                />
            }
            className={`act-creation ${className}`}
            loading={progressOrder}
            loaderInverted
        >
            {getForm()}
        </NmModal>
    );
};

export default ActCreation;