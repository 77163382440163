import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {
    getDeferredActsOfAcceptanceWorkCard,
    getDeferredActsOfAcceptanceWorkCardItemsPage,
} from "./actionCreators";
import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS,
} from "./actions";
import {bffDeferredActsOfAcceptanceWorkCardPageDataSelector} from "./selectors";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = (params) => {
    const {
        isPathParams,
        clientId,
        registryId,
    } = params;

    const pathParams = isPathParams ? `/${clientId}/${registryId}` : "";

    return getBffControllerClientCardPage({
        admin: `/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry${pathParams}`,
        client: `/client-adm/documents/deferred-acts-of-acceptance-work/registry${pathParams}`,
    });
};

const handleResponse = function* (data) {
    const {
        isFetchList,
        isFetchCard,
        clientId,
        registryId,
    } = data;

    if (isFetchCard) {
        yield put(getDeferredActsOfAcceptanceWorkCard({
            clientId,
            registryId,
        }));
    }

    if (isFetchList) {
        const state = yield select();
        const pageData = bffDeferredActsOfAcceptanceWorkCardPageDataSelector(state);

        yield put(getDeferredActsOfAcceptanceWorkCardItemsPage(pageData));
    }
};

//GET /adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/get
//GET /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/get
const getDeferredActsOfAcceptanceWorkCardSaga = function* ({payload}) {
    const controller = getController({
        isPathParams: true,
        ...payload,
    });

    try {
        const result = yield request.bff.get(`${controller}/get`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR,
            payload: error,
        });
    }
};

//POST /adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/items/page
//POST /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/items/page
const getDeferredActsOfAcceptanceWorkCardItemsPageSaga = function* ({payload}) {
    const {
        clientId,
        registryId,
        ...reqData
    } = payload;

    const controller = getController({
        isPathParams: true,
        clientId,
        registryId,
    });

    try {
        const result = yield request.bff.post(`${controller}/items/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR,
            payload: error,
        });
    }
};

//GET /adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/items/status/dict
//GET /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/items/status/dict
const getDeferredActsOfAcceptanceWorkCardItemsStatusDictSaga = function* () {
    const controller = getController({
        isPathParams: false,
    });

    try {
        const result = yield request.bff.get(`${controller}/items/status/dict`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

//DELETE /adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/items/delete
//DELETE /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/items/delete
const deleteDeferredActsOfAcceptanceWorkCardItemsSaga = function* ({payload}) {
    const {
        seqNums,
        ...otherParams
    } = payload;

    const controller = getController({
        isPathParams: true,
        ...otherParams,
    });

    try {
        const result = yield request.bff.delete(`${controller}/items/delete`, {data: seqNums});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({
            isFetchList: true,
            isFetchCard: true,
            ...otherParams,
        });

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR,
            payload: error,
        });
    }
};

//POST /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/start-export
//POST /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/start-export
const exportDeferredActsOfAcceptanceWorkCardSaga = function* ({payload}) {
    const controller = getController({
        isPathParams: true,
        ...payload,
    });

    try {
        const result = yield request.bff.post(`${controller}/start-export`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе \"Экспорт документов\".");

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR,
            payload: error,
        });
    }
};

//PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registry}/items/update
//PUT /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registryId}/items/update
const updateDeferredActsOfAcceptanceWorkCardItemSaga = function* ({payload}) {
    const controller = getController({
        isPathParams: true,
        ...payload,
    });

    try {
        const result = yield request.bff.put(`${controller}/items/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield handleResponse({isFetchList: true});

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST, getDeferredActsOfAcceptanceWorkCardSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST, getDeferredActsOfAcceptanceWorkCardItemsStatusDictSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST, getDeferredActsOfAcceptanceWorkCardItemsPageSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST, deleteDeferredActsOfAcceptanceWorkCardItemsSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST, exportDeferredActsOfAcceptanceWorkCardSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST, updateDeferredActsOfAcceptanceWorkCardItemSaga),
    ]);
}