import {all, put, takeEvery} from "@redux-saga/core/effects";

import {DEPOSIT_SUB_PAGE} from "../../../../containers/deposit/client-list";
import {
    BFF_CROWD_PAYMENT_ADD_ERROR,
    BFF_CROWD_PAYMENT_ADD_REQUEST,
    BFF_CROWD_PAYMENT_ADD_SUCCESS,
    BFF_CROWD_PAYMENT_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
    BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST,
    BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS,
    BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST,
    BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
    BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
    BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST,
    BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS,
    BFF_CROWD_PAYMENT_REJECT_ERROR,
    BFF_CROWD_PAYMENT_REJECT_REQUEST,
    BFF_CROWD_PAYMENT_REJECT_SUCCESS,
    BFF_CROWD_PAYMENT_REPEAT_ERROR,
    BFF_CROWD_PAYMENT_REPEAT_REQUEST,
    BFF_CROWD_PAYMENT_REPEAT_SUCCESS,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_REQUEST,
    BFF_CROWD_PAYMENTS_CHECK_EXISTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {
    LINK_PAYMENTS_TASKS_LIST,
} from "../../../../constants/links";

const getController = () => {
    return getBffUrl({
        [LINK_PAYMENTS_TASKS_LIST]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/finances/npd-payments/tasks",
            client: "/client-adm/finances/npd-payments/tasks",
        }),
    },
    "/adm/finances/crowd-payments",
    true,
    );
};

const getPageCrowdPayment = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_PAGE_ERROR,
        });
    }
};

const getTotalAmountCrowdPayment = function* ({payload}) {
    try {
        const url = getBffUrl({
            [DEPOSIT_SUB_PAGE.COMPLETED_PAYMENTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/npd-deposit/completed-payments/task",
                client: "/client-adm/finances/npd-deposit/completed-payments/task",
            }),
            [DEPOSIT_SUB_PAGE.PAYMENTS_IN_PROCESS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/npd-deposit/payments-in-process/task",
                client: "/client-adm/finances/npd-deposit/payments-in-process/task",
            }),
            [DEPOSIT_SUB_PAGE.PAYMENTS_PENDING]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/npd-deposit/pending-payments/task",
                client: "/client-adm/finances/npd-deposit/pending-payments/task",
            }),
        },
        "/adm/finances/crowd-payments",
        true,
        );

        const result = yield request.bff.post(`${url}/get/totalAmount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_ERROR,
        });
    }
};

const repeatCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/repeat`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_REPEAT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_REPEAT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_REPEAT_ERROR,
        });
    }
};

const rejectCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_REJECT_ERROR,
        });
    }
};

const massRejectCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/mass/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REJECT_ERROR,
        });
    }
};

const approveCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_APPROVE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_APPROVE_ERROR,
        });
    }
};

const massApproveCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/mass/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_APPROVE_ERROR,
        });
    }
};

const addCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_ADD_ERROR,
        });
    }
};

const getByIdCrowdPayment = function* ({payload}) {
    try {
        const {
            paymentId,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${paymentId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_GET_BY_ID_ERROR,
        });
    }
};

const getPageCrowdPaymentLog = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/events/get/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_LOG_GET_PAGE_ERROR,
        });
    }
};

const massRevalidateCrowdPayment = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/mass/revalidate`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENT_MASS_REVALIDATE_ERROR,
        });
    }
};

const checkExistsCrowdPayments = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/exists`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_PAYMENTS_CHECK_EXISTS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_PAYMENT_GET_PAGE_REQUEST, getPageCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_GET_TOTAL_AMOUNT_REQUEST, getTotalAmountCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_REPEAT_REQUEST, repeatCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_REJECT_REQUEST, rejectCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_MASS_REJECT_REQUEST, massRejectCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_APPROVE_REQUEST, approveCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_MASS_APPROVE_REQUEST, massApproveCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_ADD_REQUEST, addCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_GET_BY_ID_REQUEST, getByIdCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENT_LOG_GET_PAGE_REQUEST, getPageCrowdPaymentLog),
        takeEvery(BFF_CROWD_PAYMENT_MASS_REVALIDATE_REQUEST, massRevalidateCrowdPayment),
        takeEvery(BFF_CROWD_PAYMENTS_CHECK_EXISTS_REQUEST, checkExistsCrowdPayments),
    ]);
}