import {
    BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_REQUEST,
    BFF_COMMON_CLIENT_UPDATE_STORE,
} from "./actions";

export function getClientMaintenanceNotice() {
    return {
        type: BFF_COMMON_CLIENT_GET_MAINTENANCE_NOTICE_REQUEST,
    };
}

export function updateCommonClientStore(payload) {
    return {
        type: BFF_COMMON_CLIENT_UPDATE_STORE,
        payload,
    };
}
