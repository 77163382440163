import {
    CLIENT_PROJECTS_CARD_ADD_USER_ERROR,
    CLIENT_PROJECTS_CARD_ADD_USER_REQUEST,
    CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS,
    CLIENT_PROJECTS_CARD_CLEAR_STORE,
    CLIENT_PROJECTS_CARD_DELETE_USER_ERROR,
    CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST,
    CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS,
    CLIENT_PROJECTS_CARD_GET_ERROR,
    CLIENT_PROJECTS_CARD_GET_REQUEST,
    CLIENT_PROJECTS_CARD_GET_SUCCESS,
    CLIENT_PROJECTS_CARD_GET_USERS_ERROR,
    CLIENT_PROJECTS_CARD_GET_USERS_REQUEST,
    CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS,
    CLIENT_PROJECTS_CARD_UPDATE_STORE,
} from "./actions";

const initial = {
    card: {},
    progress: false,
    progressAction: false,
    error: null,
    projectUsersPageData: {},
    projectUsers: [],
    projectUsersTotalCount: 0,
    progressUsers: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_PROJECTS_CARD_GET_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case CLIENT_PROJECTS_CARD_GET_SUCCESS:
            return {
                ...state,
                card: payload,
                progress: false,
            };
        case CLIENT_PROJECTS_CARD_GET_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_PROJECTS_CARD_ADD_USER_REQUEST:
        case CLIENT_PROJECTS_CARD_DELETE_USER_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CLIENT_PROJECTS_CARD_ADD_USER_SUCCESS:
        case CLIENT_PROJECTS_CARD_DELETE_USER_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case CLIENT_PROJECTS_CARD_ADD_USER_ERROR:
        case CLIENT_PROJECTS_CARD_DELETE_USER_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case CLIENT_PROJECTS_CARD_GET_USERS_REQUEST:
            return {
                ...state,
                projectUsersPageData: payload,
                progressUsers: true,
            };
        case CLIENT_PROJECTS_CARD_GET_USERS_SUCCESS:
            const {
                results,
                totalCount: projectUsersTotalCount,
                fieldName = "projectUsers",
            } = payload;
            return {
                ...state,
                progressUsers: false,
                projectUsersTotalCount,
                [fieldName]: results,
            };
        case CLIENT_PROJECTS_CARD_GET_USERS_ERROR:
            return {
                ...state,
                progressUsers: false,
            };
        case CLIENT_PROJECTS_CARD_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLIENT_PROJECTS_CARD_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};