import {
    DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST,
    DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST,
} from "./actions";

export const getDocumentCustomTemplatePage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_PAGE_REQUEST,
        payload,
    };
};

export const deleteDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_DELETE_REQUEST,
        payload,
    };
};

export const addDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_ADD_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortDefaultAggregation = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_DEFAULT_AGGREGATION_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateFileById = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_GET_PDF_BY_ID_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateLogs = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_INSTANCE_LOG_PAGE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplate = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplateClientUsage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_UPDATE_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsagePage = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_PAGE_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsageActionTypeDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_TYPE_DICT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateClientUsageOrderTypesDict = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_ACTION_ORDER_TYPES_DICT_REQUEST,
        payload,
    };
};

// Сделать шаблон по умолчанию у клиента
export const setDefaultDocumentsCustomTemplateById = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SET_DEFAULT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortDefault = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_CLIENT_USAGE_SHORT_DEFAULT_REQUEST,
        payload,
    };
};

export const getDocumentCustomTemplateShortActual = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_SHORT_PAGE_REQUEST,
        payload,
    };
};

export const updateDocumentCustomTemplateStore = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_UPDATE_STORE,
        payload,
    };
};

export const validateDocumentCustomTemplateFields = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_FIELDS_REQUEST,
        payload,
    };
};

export const generateDocumentCustomTemplatePdf = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_VALIDATION_PDF_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateEventLogList = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_LOG_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateReplacementsForUnavailableList = (payload) => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_REPLACEMENTS_FOR_UNAVAILABLE_LIST_REQUEST,
        payload,
    };
};

export const getDocumentsCustomTemplateEventLogTypeDict = () => {
    return {
        type: DOCUMENTS_CUSTOM_TEMPLATE_EVENT_TYPE_DICT_REQUEST,
    };
};