import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    getRecruitmentVacancyCandidatesRecruiters,
    updateRecruitmentVacancyCandidatesStore,
} from "../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {bffRecruitmentVacancyRecruitersOptionsSelector} from "../../../../ducks/bff/recruitment/vacancyCandidates/selectors";

export const useVacancyRecruitersDropdown = (props) => {
    const {
        clientId,
        vacancyId,
    } = props;

    const dispatch = useDispatch();

    const options = useSelector(bffRecruitmentVacancyRecruitersOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentVacancyCandidatesStore({
                recruitersList: [],
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = () => {
        dispatch(getRecruitmentVacancyCandidatesRecruiters({
            clientId,
            vacancyId,
        }));
    };

    return {
        options,
        search: true,
        label: "Рекрутер",
        placeholder: "Показать все",
        name: "recruiterId",
    };
};