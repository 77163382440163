import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmListCard from "../../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import CheckboxList from "../../../../../../components/CheckboxList";
import NmTitle from "../../../../../../components/NmTitle";

import {
    getKedoDocumentRoutesFlexFullModel,
    getKedoDocumentRoutesFullModel,
    updateKedoDirectoriesStore,
} from "../../../../../../ducks/kedo/directories/actionCreators";
import {kedoDirectoriesDocumentRouteDetailsSelector} from "../../../../../../ducks/kedo/directories/selectors";

const KEDO_DOCUMENT_ROUTES_TYPE = {
    FLEX_ROUTE: "FLEX_ROUTE", // гибкий
    USER_ROUTE: "USER_ROUTE", // пользовательский
    BASE_ROUTE: "BASE_ROUTE", // базовый
};

const ACTION_TYPE = {
    SIGN_UKEP: "SIGN_UKEP", // подпись, тип УКЭП
    SIGN_UNEP: "SIGN_UNEP", // подпись, тип УНЕП
    SIGN_PEP: "SIGN_PEP", // подпись, тип ПЭП
    APPROVE_PEP: "APPROVE_PEP", // согласование, тип ПЭП
};

export const KedoDocumentRoutesDetailsModal = (props) => {
    const {
        routeId,
        typeRoute,
        onClose,
    } = props;

    const dispatch = useDispatch();

    const {
        details,
        progressDetails,
    } = useSelector(kedoDirectoriesDocumentRouteDetailsSelector);

    useEffect(() => {
        // Получение гибкого маршрута

        // if (typeRoute === KEDO_DOCUMENT_ROUTES_TYPE.FLEX_ROUTE) {
        //     dispatch(getKedoDocumentRoutesFlexFullModel({
        //         routeId,
        //     }));
        // }

        dispatch(getKedoDocumentRoutesFullModel({
            routeId,
        }));

        return () => {
            dispatch(updateKedoDirectoriesStore({
                details: {},
            }));
        };
    }, []);

    const getSignType = (actionType) => {
        if ([
            ACTION_TYPE.APPROVE_PEP,
            ACTION_TYPE.SIGN_PEP,
        ].includes(actionType)) {
            return "ПЭП";
        }

        if (actionType === ACTION_TYPE.SIGN_UNEP) {
            return "УНЕП";
        }

        if (actionType === ACTION_TYPE.SIGN_UKEP) {
            return "УКЕП";
        }

        return "-";
    };

    const getActionType = (actionType) => {
        if ([
            ACTION_TYPE.SIGN_UNEP,
            ACTION_TYPE.SIGN_UKEP,
            ACTION_TYPE.SIGN_PEP,
        ].includes(actionType)) {
            return "Подписать";
        }

        if (actionType === ACTION_TYPE.APPROVE_PEP) {
            return "Согласовать";
        }

        return "-";
    };

    const getRows = () => {
        return details.stepTemplates?.map(item => {
            const {
                stepName,
                stepTemplateId,
                matchParticipantTypeStr,
                matchParticipantParams,
                actionType,
            } = item;

            return {
                ...item,
                key: stepTemplateId,
                contentRow: (
                    <NmListCard
                        primaryHeader={
                            <Text.Sub
                                level="1"
                                bold={true}
                            >
                                {stepName}
                            </Text.Sub>
                        }
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Тип участника",
                                text: matchParticipantTypeStr,
                            },
                            matchParticipantParams?.participantFio && {
                                label: "ФИО",
                                text: matchParticipantParams.participantFio,
                            },
                            matchParticipantParams?.departmentName && {
                                label: "Управленческий отдел",
                                text: matchParticipantParams.departmentName,
                            },
                            matchParticipantParams?.departmentRoleStr && {
                                label: "Роль в отделе",
                                text: matchParticipantParams.departmentRoleStr,
                            },
                            {
                                label: "Действие",
                                text: getActionType(actionType),
                            },
                            {
                                label: "Тип подписи",
                                text: getSignType(actionType),
                            },
                        ]}
                    />
                ),
            };
        });
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Детали маршрута
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submit={onClose}
                    submitBtnContent="Понятно"
                />
            }
            loading={progressDetails}
            classNameContent="flex-column"
        >
            <Text
                level="4"
                type="title"
                className="mb-4"
            >
                {details.name}
            </Text>
            <CheckboxList
                rows={getRows()}
            />
        </NmModal>
    );
};