import React, {useMemo} from "react";
import {useDispatch} from "react-redux";

import Tabs from "../../../../components/ActualComponents/Tabs";
import {ClientCardCrowdRoute} from "./Route";

import {getUserRestrictions, isNMUsers} from "../../../../utils/access";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY,
    LINK_CLIENT_CROWD_TASK_ANALYTICS,
    LINK_CLIENT_CROWD_TASK_CHATS,
    LINK_CLIENT_CROWD_TASK_GROUPS,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
} from "../../../../constants/links";
import {
    NM_CHIEF_ACCOUNTANT,
    NM_OPERATOR,
} from "../../../../constants/roles";

import {SUB_PAGE_CROWD_TASK_ANALYTICS} from "../../../../constants/link-params";

const ClientCardCrowd = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const role = ls(USER_ROLE);
    const currentUserRestrictions = getUserRestrictions();

    const getLink = () => {
        const crowdTaskRegistryLink = LINK_CLIENT_CROWD_TASK_REGISTRY.replace(":clientId", clientId);
        const crowdTaskGroupsLink = LINK_CLIENT_CROWD_TASK_GROUPS.replace(":clientId", clientId);
        const crowdTaskChatsLink = LINK_CLIENT_CROWD_TASK_CHATS
            .replace(":clientId", clientId)
            .replace(":contractorId?", "");
        const crowdTaskActRegistryLink = LINK_CLIENT_CROWD_TASK_ACT_REGISTRY.replace(":clientId", clientId);
        const crowdTaskAnalyticsLink = LINK_CLIENT_CROWD_TASK_ANALYTICS
            .replace(":clientId", clientId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK);

        return {
            crowdTaskRegistryLink,
            crowdTaskGroupsLink,
            crowdTaskChatsLink,
            crowdTaskActRegistryLink,
            crowdTaskAnalyticsLink,
        };
    };

    const tabLinks = useMemo(() => {
        const {
            crowdTaskRegistryLink,
            crowdTaskGroupsLink,
            crowdTaskChatsLink,
            crowdTaskActRegistryLink,
            crowdTaskAnalyticsLink,
        } = getLink();

        return [
            {
                active: crowdTaskRegistryLink,
                link: crowdTaskRegistryLink,
                name: "Реестр заданий",
            },
            {
                active: crowdTaskGroupsLink,
                link: crowdTaskGroupsLink,
                name: "Группы заданий",
            },
            {
                active: crowdTaskChatsLink,
                link: crowdTaskChatsLink,
                name: "Чаты заданий",
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTaskChat,
                ]),
            },
            {
                active: crowdTaskActRegistryLink,
                link: crowdTaskActRegistryLink,
                name: "Реестр актов по заданиям",
            },
            {
                active: [
                    crowdTaskAnalyticsLink,
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.SIGN_CONTRACT.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.HIRED.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.IN_WORK.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK),
                    LINK_CLIENT_CROWD_TASK_ANALYTICS
                        .replace(":clientId", clientId)
                        .replace(":subpage", SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK),
                ],
                link: crowdTaskAnalyticsLink,
                name: "Аналитика по заданиям",
                isVisible: ![NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role)
                    && isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.accessCrowdTaskAnalytics,
                    ]),
            },
        ];
    }, [currentUserRestrictions]);

    return (
        <>
            {
                isNMUsers() &&
                <Tabs
                    {...props}
                    className="mt-4 mb-6"
                    panes={tabLinks}
                    secondary
                />
            }
            <ClientCardCrowdRoute
                {...props}
            />
        </>
    );
};

export default ClientCardCrowd;