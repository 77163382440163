import {
    CLIENT_PROJECTS_ADD_DEPOSIT_ERROR,
    CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS,
    CLIENT_PROJECTS_ADD_ERROR,
    CLIENT_PROJECTS_ADD_REQUEST,
    CLIENT_PROJECTS_ADD_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST,
    CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
    CLIENT_PROJECTS_CHANGE_STATUS_ERROR,
    CLIENT_PROJECTS_CHANGE_STATUS_REQUEST,
    CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS,
    CLIENT_PROJECTS_CLEAR_STORE,
    CLIENT_PROJECTS_GET_PAGE_ERROR,
    CLIENT_PROJECTS_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_GET_PAGE_SUCCESS,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST,
    CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS,
    CLIENT_PROJECTS_IMPORT_ERROR,
    CLIENT_PROJECTS_IMPORT_REQUEST,
    CLIENT_PROJECTS_IMPORT_SUCCESS,
    CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR,
    CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST,
    CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS,
    CLIENT_PROJECTS_UPDATE_ERROR,
    CLIENT_PROJECTS_UPDATE_REQUEST,
    CLIENT_PROJECTS_UPDATE_STORE,
    CLIENT_PROJECTS_UPDATE_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    error: null,
    depositHistoryPageData: {},
    depositHistoryList: [],
    depositHistoryTotalCount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_PROJECTS_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CLIENT_PROJECTS_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.projectModels,
                totalCount: payload.totalCount,
                progress: false,
            };
        case CLIENT_PROJECTS_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case CLIENT_PROJECTS_HISTORY_GET_PAGE_REQUEST:
            return {
                ...state,
                depositHistoryPageData: {
                    pageNum: payload.pageNum,
                    pageSize: payload.pageSize,
                },
            };
        case CLIENT_PROJECTS_HISTORY_GET_PAGE_SUCCESS:
            return {
                ...state,
                depositHistoryList: payload.results,
                depositHistoryTotalCount: payload.totalCount,
            };
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_REQUEST:
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_REQUEST:
        case CLIENT_PROJECTS_RETURN_DEPOSIT_REQUEST:
        case CLIENT_PROJECTS_ADD_DEPOSIT_REQUEST:
        case CLIENT_PROJECTS_ADD_REQUEST:
        case CLIENT_PROJECTS_UPDATE_REQUEST:
        case CLIENT_PROJECTS_CHANGE_STATUS_REQUEST:
        case CLIENT_PROJECTS_CHANGE_ARCHIVED_REQUEST:
        case CLIENT_PROJECTS_IMPORT_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_SUCCESS:
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_SUCCESS:
        case CLIENT_PROJECTS_RETURN_DEPOSIT_SUCCESS:
        case CLIENT_PROJECTS_ADD_DEPOSIT_SUCCESS:
        case CLIENT_PROJECTS_ADD_SUCCESS:
        case CLIENT_PROJECTS_UPDATE_SUCCESS:
        case CLIENT_PROJECTS_CHANGE_STATUS_SUCCESS:
        case CLIENT_PROJECTS_CHANGE_ARCHIVED_SUCCESS:
        case CLIENT_PROJECTS_IMPORT_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_FROM_FILE_ERROR:
        case CLIENT_PROJECTS_CHANGE_ORDERS_LIMIT_ERROR:
        case CLIENT_PROJECTS_RETURN_DEPOSIT_ERROR:
        case CLIENT_PROJECTS_ADD_DEPOSIT_ERROR:
        case CLIENT_PROJECTS_ADD_ERROR:
        case CLIENT_PROJECTS_UPDATE_ERROR:
        case CLIENT_PROJECTS_CHANGE_STATUS_ERROR:
        case CLIENT_PROJECTS_CHANGE_ARCHIVED_ERROR:
        case CLIENT_PROJECTS_IMPORT_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case CLIENT_PROJECTS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLIENT_PROJECTS_CLEAR_STORE:
            return initial;
        default:
            return state;
    }
};