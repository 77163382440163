import React from "react";
import {withRouter} from "react-router";

import Tabs from "../../../../components/ActualComponents/Tabs";
import OrderRoute from "../../order-route";

import {isNMUsers} from "../../../../utils/access";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
} from "../../../../constants/links";
import {
    ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../../../constants/roles";

function OrderCommon(props) {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const role = ls(USER_ROLE);

    function links() {
        const orderLink = LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId);
        const orderTemplatesLink = LINK_CLIENT_ORDER_TEMPLATES_LIST.replace(":clientId", clientId);
        const orderGroupsLink = LINK_CLIENT_ORDER_GROUPS_LIST.replace(":clientId", clientId);
        const orderRegistryInvitationsLink = LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST.replace(":clientId", clientId);

        return [
            {
                active: orderLink,
                link: orderLink,
                name: "Реестр заказов",
            },
            {
                active: orderGroupsLink,
                link: orderGroupsLink,
                name: "Группы заказов",
            },
            {
                active: orderRegistryInvitationsLink,
                link: orderRegistryInvitationsLink,
                name: "Реестр приглашений",
            },
            {
                active: LINK_CLIENT_ORDER_ACT_REGISTRY_LIST.replace(":clientId", clientId),
                link: LINK_CLIENT_ORDER_ACT_REGISTRY_LIST.replace(":clientId", clientId),
                name: "Реестр актов",
            },
            {
                active: orderTemplatesLink,
                link: orderTemplatesLink,
                name: "Шаблоны заказов",
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessOrderTemplates,
                ]),
            },
        ].filter(({link}) => !(link === orderTemplatesLink && ![ADMIN, NM_MANAGER, NM_OPERATOR].includes(role)));
    }

    return (
        <>
            {
                isNMUsers() &&
                <Tabs
                    {...props}
                    className="default-custom-tabs"
                    panes={links()}
                    secondary
                />
            }
            <OrderRoute />
        </>
    );
}

export default withRouter(OrderCommon);