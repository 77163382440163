import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {debounce,isEmpty} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";

import dateFormat, {formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {phoneFormat} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {PAGE_SIZES_DEFAULT} from "../../../constants/page";
import {PATENTS_EXPORT_DOCUMENTS_STATUS} from "../../../constants/patentsExport";
import {ADMIN} from "../../../constants/roles";

import {cancelExportById} from "../../../ducks/job";
import {
    downloadExportDocument,
    getExportPatentPage,
    patentsDocumentsExportListSelector,
    patentsDocumentsExportTotalCountSelector,
    patentsDocumentsExportTotalPagesSelector,
} from "../../../ducks/patentsDocumentsExport";

import "./style.sass";

const PatentExportList = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(PAGE_SIZES_DEFAULT[0]);
    const [confirmData, setConfirmData] = useState({});

    const totalCount = useSelector(patentsDocumentsExportTotalCountSelector);
    const totalPages = useSelector(patentsDocumentsExportTotalPagesSelector);
    const list = useSelector(patentsDocumentsExportListSelector);
    const listInProgress = list.filter(item => item.status === PATENTS_EXPORT_DOCUMENTS_STATUS.IN_PROGRESS.VALUE);

    const role = ls(USER_ROLE);
    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const delayedFetching = useCallback(debounce(() => {
        fetchList && fetchList();
    }, 500), [listInProgress]);

    useEffect(() => {
        if (!isEmpty(listInProgress)) {
            delayedFetching();
        }

        return delayedFetching.cancel;
    }, [listInProgress]);

    const fetchList = () => {
        dispatch(getExportPatentPage({
            clientIdFilter: clientId,
            pageNum,
            pageSize,
        }));
    };

    const cancelExport = (exportId) => {
        dispatch(cancelExportById({
            exportId,
            onSuccess: fetchList,
        }));
    };

    const handleChangePagination = (e, {activePage: pageNum}) => {
        setPageNum(prevState => {
            if (prevState !== pageNum) {
                return pageNum;
            }
        });

        document.querySelector(".client-card").scrollTo(0, 0);
    };

    const handleChangePageSize = pageSize => {
        setPageSize(pageSize);
        setPageNum(1);
    };

    const getStatus = ({status, progressPercents}) => {
        const text = status === PATENTS_EXPORT_DOCUMENTS_STATUS.IN_PROGRESS.VALUE ?
            `${PATENTS_EXPORT_DOCUMENTS_STATUS[status]?.TEXT} (${progressPercents}%)` :
            PATENTS_EXPORT_DOCUMENTS_STATUS[status]?.TEXT;

        return (
            <NmBadge
                noWrap
                mod={PATENTS_EXPORT_DOCUMENTS_STATUS[status].COLOR}
                text={text}
            />
        );
    };

    const getPeriod = ({fromDateTime, toDateTime}) => {
        if ([fromDateTime, toDateTime].every(value => value === null)) {
            return "За весь период";
        }

        if (fromDateTime && !toDateTime) {
            return `с ${dateFormat(fromDateTime, "dd.MM.yyyy")}`;
        }

        if (!fromDateTime && toDateTime) {
            return `по ${dateFormat(toDateTime, "dd.MM.yyyy")}`;
        }

        return `${dateFormat(fromDateTime, "dd.MM.yyyy")} - ${dateFormat(toDateTime, "dd.MM.yyyy")}`;
    };

    const getRows = () => {
        return list.map(item => {
            const {
                documentsExportId,
                creationDateTime,
                creatorName,
                downloadLink,
                exportFileName,
                contractorName,
                contractorPhone,
                creatorClientUserId,
                status,
            } = item;

            const isContractor = Boolean(contractorName || contractorPhone);

            const phone = isContractor ? [{label: "Номер телефона", text: phoneFormat(contractorPhone)}] : [];

            return {
                ...item,
                key: documentsExportId,
                contentRow: (
                    <NmListCard
                        noDivider
                        classNameMainContent="col-16 col-xxl-15"
                        alignItems="flex-start"
                        secondaryHeader={`Дата создания ${formatLocalDate(creationDateTime, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={getStatus(item)}
                        labels={[
                            {label: "Инициатор", text: creatorName},
                            {label: "Период", text: getPeriod(item)},
                            {label: "Исполнитель", text: isContractor ? contractorName : "Все"},
                            ...phone,
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 2,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        color: "light-green",
                                        onClick: () => dispatch(downloadExportDocument({
                                            downloadLink,
                                            exportFileName,
                                        })),
                                        children: "Скачать",
                                    },
                                    visible: Boolean(downloadLink),
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    props: {
                                        onClick: () =>
                                            setConfirmData({
                                                content: "Вы уверены, что хотите отменить формирование документа?",
                                                onConfirm: () => cancelExport(documentsExportId),
                                                confirmButton: "Да",
                                                cancelButton: "Нет",
                                            }),
                                        color: "grey",
                                        children: "Отменить",
                                    },
                                    visible: status === PATENTS_EXPORT_DOCUMENTS_STATUS.IN_PROGRESS.VALUE && ([ADMIN].includes(role) || creatorClientUserId === currentClientUserId),
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    };

    const renderModalWindow = () => {
        const {
            content,
            onConfirm,
            confirmButton,
            cancelButton,
        } = confirmData;

        return !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    };

    return (
        <>
            <NmPage
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                header={
                    <NmTitle size="xl">
                        Экспорт
                    </NmTitle>
                }
                onChangePageSize={handleChangePageSize}
                onPaginationChange={handleChangePagination}
            >
                {renderModalWindow()}
                {
                    !totalCount ?
                        <NmEmptyPageV2
                            title="История выгрузок отсутствует"
                        /> :
                        <CheckboxList
                            rows={getRows()}
                        />
                }
            </NmPage>
        </>
    );
};

export default PatentExportList;