import {createSelector} from "reselect";

export const bffSettingsServiceSelector = state => state.bff.settingsService;
export const bffSettingsServiceProgressSelector  = createSelector(bffSettingsServiceSelector, ({progress}) => progress);
export const bffSettingsServiceIsMaintenanceNoticePublishedSelector  = createSelector(bffSettingsServiceSelector, ({maintenanceNotice}) => maintenanceNotice);
export const bffSettingsServiceEmptyRecordProgressSelector  = createSelector(bffSettingsServiceSelector, ({emptyRecordProgress}) => emptyRecordProgress);
export const bffSettingsServiceProgressCorrectionSelector  = createSelector(bffSettingsServiceSelector, ({progressCorrection}) => progressCorrection);
export const bffSettingsServiceNotificationsSelector  = createSelector(
    (state, type) => {
        return state.bff.settingsService.notifications[type];
    },
    (notifications) => notifications,
);