import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useContractorVerificationEndCheckOnPersonalStatus} from "./useEndCheckOnPersonalStatus";

import {toastError} from "../../../../utils/toastHelper";
import {handleContractorCheckOnError} from "../../contractor-info-new/utils/handleCheckOnError";

import {LINK_CONTRACTOR_PERSONAL_INFO} from "../../../../constants/links";
import {CONTRACTOR_VERIFICATION_DATE_REQUEST_INN_DECISION} from "../constants";

import {history} from "../../../../store/configureStore";

import {getInfoContractorCard} from "../../../../ducks/bff/contractor-сard/actionCreators";
import {
    getContractorPendingVerificationWizard,
    getPendingPersonalData, getPersonalDataPendingComparison,
} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    contractorComparisonDataFormSelector,
    contractorComparisonDataSavedFilesSelector,
    contractorPendingPersonalDataProgressWizardSelector,
    contractorPendingPersonalDataWizardSelector,
    contractorPendingPersonalDataWizardStepNamesSelector,
    migrationComparisonDataProgressSelector,
} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {
    postPendingPersonalData,
} from "../../../../ducks/contractor";
import {
    updateContractorsPendingPersonalDataStore,
} from "../../../../ducks/contractorPendingPersonalData";
import {fileContractorOriginalScanObjSelector} from "../../../../ducks/fileStore";

export const useContractorVerificationPersonalData = (params) => {
    const {
        isRequestInnList,
        contractorId,
        isRequestPassportPageNumber19,
    } = params;
    const dispatch = useDispatch();

    const scans = useSelector(fileContractorOriginalScanObjSelector);
    const comparisonData = useSelector(contractorComparisonDataFormSelector);
    const {
        files,
        personalData,
        pendingPersonalData: {
            innPdfFormatFileName,
            innPdfFormatFileLink,
            pendingPersonalDataFields = [],
            requestId,
        },
        previousPersonalData,
        previousFiles,
    } = comparisonData;
    const savedFiles = useSelector(contractorComparisonDataSavedFilesSelector);
    const verificationSteps = useSelector(contractorPendingPersonalDataWizardSelector);
    const stepNames = useSelector(contractorPendingPersonalDataWizardStepNamesSelector);
    const progressVerificationSteps = useSelector(contractorPendingPersonalDataProgressWizardSelector);
    const progressComparisonData = useSelector(migrationComparisonDataProgressSelector);

    const {
        endCheck,
    } = useContractorVerificationEndCheckOnPersonalStatus(contractorId);

    useEffect(() => {
        dispatch(getInfoContractorCard(contractorId));
        dispatch(getContractorPendingVerificationWizard({
            contractorId,
            onSuccess: ({result}) => {
                if (isEmpty(result)) {
                    toastError("Нет данных для проверки");

                    const link = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId);

                    history.push(link);
                }
            },
        }));
        dispatch(getPersonalDataPendingComparison({
            contractorId,
        }));

        return () => {
            dispatch(updateContractorsPendingPersonalDataStore({
                verificationSteps: [],
            }));
        };
    }, []);

    const handleSave = ({updatedPersonalData, fileDecisions, finishCheck}) => {
        endCheck({
            onSuccess: () => {
                dispatch(postPendingPersonalData({
                    data: {
                        requestId,
                        contractorId,
                        needInnScan: isRequestInnList,
                        needPreviouslyIssuedPassportsInfoScan : isRequestPassportPageNumber19,
                        // toDo: Перенести логику со сбором fileDecisions, а именно добавление
                        //  CONTRACTOR_VERIFICATION_DATE_REQUEST_INN_DECISION в массив на бек,
                        //  чтобы на фронте не было данной логики
                        fileDecisions: isRequestInnList ? [
                            ...fileDecisions,
                            CONTRACTOR_VERIFICATION_DATE_REQUEST_INN_DECISION,
                        ] : fileDecisions,
                        updatedPersonalData,
                        finishCheck,
                    },
                    onSuccess: handleClose,
                    onError: (result) => {
                        const isCheckOnError = handleContractorCheckOnError(result);

                        if (isCheckOnError) {
                            return;
                        }

                        toastError(result.errorMessage);
                    },
                }));
            },
        });
    };

    function handleClose() {
        dispatch(getPendingPersonalData({
            contractorId,
        }));

        endCheck({
            onSuccess: () => {
                history.push(LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId));
            },
        });
    }

    const getFileId = ({fileType}) => {
        return files.find(({type}) => (type === fileType))?.fileId;
    };

    return {
        handleSave,
        scans,
        personalData,
        previousPersonalData,
        previousFiles,
        savedFiles,
        pendingPersonalDataFields,
        pendingFileTypes: verificationSteps,
        files,
        handleClose,
        stepNames,
        loading: progressComparisonData || progressVerificationSteps,
        comparisonData,
        getFileId,
        innPdfFormatFileName,
        innPdfFormatFileLink,
    };
};