import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientMemberList} from "./actionCreators";
import {
    BFF_CLIENT_MEMBER_ADD_ERROR,
    BFF_CLIENT_MEMBER_ADD_REQUEST,
    BFF_CLIENT_MEMBER_ADD_SUCCESS,
    BFF_CLIENT_MEMBER_ARCHIVE_ERROR,
    BFF_CLIENT_MEMBER_ARCHIVE_REQUEST,
    BFF_CLIENT_MEMBER_UPDATE_ERROR,
    BFF_CLIENT_MEMBER_UPDATE_REQUEST,
    BFF_CLIENT_MEMBER_UPDATE_SUCCESS,
    BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR,
    BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST,
    BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS,
    BFF_GET_CLIENT_MEMBER_CARD_ERROR,
    BFF_GET_CLIENT_MEMBER_CARD_REQUEST,
    BFF_GET_CLIENT_MEMBER_CARD_SUCCESS,
    BFF_GET_CLIENT_MEMBER_LIST_ERROR,
    BFF_GET_CLIENT_MEMBER_LIST_REQUEST,
    BFF_GET_CLIENT_MEMBER_LIST_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {
    getBffCommonController,
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../utils/url";

import {
    LINK_OBJECTS,
    LINK_PROJECTS,
} from "../../../../constants/links";
import {CLIENT_MEMBER, MEMBER_ARCHIVE_SUCCESS} from "../../../../constants/messages";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/client/employees",
        client: "/client-adm/client/employees",
    });
};

// POST /bff/adm/common/client/user/page
// POST /bff/client-adm/common/client/user/page
// POST /bff/adm/clients/client-card/client/objects/object-card/project-users/getPage
// POST /bff/client-adm/client/objects/object-card/project-users/getPage
// POST /bff/adm/clients/client-card/client/projects/project-card/project-users/getPage
// POST /bff/client-adm/client/projects/project-card/project-users/getPage
const getClientMemberListSaga = function* ({payload}) {
    try {
        const {
            fieldName,
            ...reqData
        } = payload;

        const url = getBffUrl(
            {
                [LINK_OBJECTS]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/client/objects/object-card/project-users/getPage",
                    client: "/client-adm/client/objects/object-card/project-users/getPage",
                }),
                [LINK_PROJECTS]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/client/projects/project-card/project-users/getPage",
                    client: "/client-adm/client/projects/project-card/project-users/getPage",
                }),
            },
            `${getBffCommonController()}/client/user/page`,
            true,
        );
        
        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_CLIENT_MEMBER_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_CLIENT_MEMBER_LIST_SUCCESS,
            payload: {
                ...result,
                fieldName,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_GET_CLIENT_MEMBER_LIST_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/client/employees/archive
// PUT /bff/client-adm/client/employees/archive
const clientArchiveMemberSaga = function* ({payload}) {
    try {
        const {
            canFetchList = true,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/archive`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_MEMBER_ARCHIVE_ERROR,
                payload: result,
            });
        }

        const state = yield select();
        console.log("ku-ku", state);

        if (canFetchList) {
            yield put(getClientMemberList(state.bff.clientEmployees.pageData));
        }

        if (!errorMessage) {
            toastSuccess(
                reqData.archive
                    ? MEMBER_ARCHIVE_SUCCESS.TO
                    : MEMBER_ARCHIVE_SUCCESS.FROM,
            );
        }
    } catch (error) {
        yield put({
            type: BFF_CLIENT_MEMBER_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/employees/add
// POST /bff/client-adm/client/employees/add
const addClientMemberSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_MEMBER_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(CLIENT_MEMBER.ADD_SUCCESS_TEXT);

        yield put({
            type: BFF_CLIENT_MEMBER_ADD_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT_MEMBER.ADD_ERROR_TEXT);

        yield put({
            type: BFF_CLIENT_MEMBER_ADD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/employees/update
// POST /bff/client-adm/client/employees/update
const updateClientMemberSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CLIENT_MEMBER_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(CLIENT_MEMBER.UPDATE_SUCCESS_TEXT);

        yield put({
            type: BFF_CLIENT_MEMBER_UPDATE_SUCCESS,
        });
    } catch (error) {
        toastError(CLIENT_MEMBER.UPDATE_ERROR_TEXT);

        yield put({
            type: BFF_CLIENT_MEMBER_UPDATE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/common/client/get-user-by-id
// GET /bff/client-adm/common/client/get-user-by-id
const getClientMemberCardSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getBffCommonController()}/client/get-user-by-id`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_CLIENT_MEMBER_CARD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_CLIENT_MEMBER_CARD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_CLIENT_MEMBER_CARD_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/client/employees/generate-password
// POST /bff/client-adm/client/employees/generate-password
const generatePasswordClientMemberSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/generate-password`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_CLIENT_MEMBER_LIST_REQUEST, getClientMemberListSaga),
        takeEvery(BFF_CLIENT_MEMBER_ARCHIVE_REQUEST, clientArchiveMemberSaga),
        takeEvery(BFF_CLIENT_MEMBER_ADD_REQUEST, addClientMemberSaga),
        takeEvery(BFF_CLIENT_MEMBER_UPDATE_REQUEST, updateClientMemberSaga),
        takeEvery(BFF_GET_CLIENT_MEMBER_CARD_REQUEST, getClientMemberCardSaga),
        takeEvery(BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST, generatePasswordClientMemberSaga),
    ]);
}