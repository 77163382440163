import {
    ADD_REGISTRY_ERROR,
    ADD_REGISTRY_REQUEST,
    ADD_REGISTRY_SUCCESS,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST,
    BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS,
    BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS,
    DUPLICATE_REGISTRY_ERROR,
    DUPLICATE_REGISTRY_REQUEST,
    DUPLICATE_REGISTRY_SUCCESS,
    GET_REGISTRY_STATUS_DICT_SUCCESS,
    PAY_REGISTRY_ERROR,
    PAY_REGISTRY_REQUEST,
    PAY_REGISTRY_SUCCESS,
    REGISTRY_DELETE_ERROR,
    REGISTRY_DELETE_REQUEST,
    REGISTRY_DELETE_SUCCESS,
    REGISTRY_PAYMENT_ARCHIVE_ERROR,
    REGISTRY_PAYMENT_ARCHIVE_REQUEST,
    REGISTRY_PAYMENT_ARCHIVE_SUCCESS,
    REGISTRY_PAYMENT_GET_PAGE_ERROR,
    REGISTRY_PAYMENT_GET_PAGE_REQUEST,
    REGISTRY_PAYMENT_GET_PAGE_SUCCESS,
    REGISTRY_PAYMENT_PAY_ERROR,
    REGISTRY_PAYMENT_PAY_REQUEST,
    REGISTRY_PAYMENT_PAY_SUCCESS,
    REGISTRY_UPDATE_REQUEST,
    REGISTRY_UPDATE_SUCCESS,
    TRANSFER_REGISTRY_TO_PAY_REQUEST,
    TRANSFER_REGISTRY_TO_PAY_SUCCESS,
    UPDATE_FIELD_REGISTRY_STORE,
} from "./actions";

const initialState = {
    pageData: {},
    list: [],
    totalCount: 0,
    progressList: false,
    progressAction: false,
    actionRegistryProgress: false,
    isSuccessAddOrEdit: false,
    registryStatusDict: {},
    isSuccessAction: false,
    paymentSettings: {},
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_REGISTRY_PAYMENTS_GET_CLIENT_PAYMENT_SETTINGS_SUCCESS:
            return {
                ...state,
                paymentSettings: payload.result,
            };
        case REGISTRY_PAYMENT_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progressList: true,
            };
        case REGISTRY_PAYMENT_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.registries,
                totalCount: payload.totalCount,
                progressList: false,
            };
        case REGISTRY_PAYMENT_GET_PAGE_ERROR:
            return {
                ...state,
                error: payload,
                progressList: false,
            };
        case REGISTRY_PAYMENT_ARCHIVE_REQUEST:
        case REGISTRY_PAYMENT_PAY_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case REGISTRY_PAYMENT_ARCHIVE_SUCCESS:
        case REGISTRY_PAYMENT_ARCHIVE_ERROR:
        case REGISTRY_PAYMENT_PAY_SUCCESS:
        case REGISTRY_PAYMENT_PAY_ERROR:
            return {
                ...state,
                progressAction: true,
            };
        case TRANSFER_REGISTRY_TO_PAY_SUCCESS:
            return {
                ...state,
                payProcess: false,
            };
        case TRANSFER_REGISTRY_TO_PAY_REQUEST:
            return {
                ...state,
                payProcess: true,
            };
        case REGISTRY_UPDATE_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_REQUEST:
        case PAY_REGISTRY_REQUEST:
        case DUPLICATE_REGISTRY_REQUEST:
        case REGISTRY_DELETE_REQUEST:
        case ADD_REGISTRY_REQUEST:
            return {
                ...state,
                actionRegistryProgress: true,
            };
        case UPDATE_FIELD_REGISTRY_STORE:
            return {
                ...state,
                ...payload,
            };
        case GET_REGISTRY_STATUS_DICT_SUCCESS:
            return {
                ...state,
                registryStatusDict: payload,
            };
        case BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_SUCCESS:
        case DUPLICATE_REGISTRY_SUCCESS:
        case PAY_REGISTRY_SUCCESS:
        case REGISTRY_DELETE_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
                isSuccessAction: true,
            };
        case ADD_REGISTRY_SUCCESS:
        case REGISTRY_UPDATE_SUCCESS:
            return {
                ...state,
                actionRegistryProgress: false,
                isSuccessAddOrEdit: true,
            };
        case BFF_REGISTRY_PAYMENTS_FORM_DEFERRED_ACTS_ERROR:
        case DUPLICATE_REGISTRY_ERROR:
        case REGISTRY_DELETE_ERROR:
        case PAY_REGISTRY_ERROR:
        case ADD_REGISTRY_ERROR:
            return {
                ...state,
                error: payload,
                actionRegistryProgress: false,
            };
        default:
            return state;
    }
};