import {
    BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST,
    BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST,
    BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST,
    UPDATE_FINANCES_PAID_APIS_STORE,
} from "./actions";

export const getFinancesPaymentsPaidApiPage = (payload) => {
    return {
        type: BFF_FINANCES_PAYMENTS_PAID_API_GET_PAGE_REQUEST,
        payload,
    };
};

export const getFinancesPaidApisSettings = (payload) => {
    return {
        type: BFF_FINANCES_PAID_APIS_GET_SETTINGS_REQUEST,
        payload,
    };
};

export const updateFinancesPaidApisSettings = (payload) => {
    return {
        type: BFF_FINANCES_PAID_APIS_UPDATE_SETTINGS_REQUEST,
        payload,
    };
};

export const updateStoreFinancesPaidApi = (payload) => {
    return {
        type: UPDATE_FINANCES_PAID_APIS_STORE,
        payload,
    };
};