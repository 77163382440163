import React, {FC, ReactNode, useEffect, useState} from "react";
import {
    DropzoneProps,
    FileRejection,
    useDropzone,
} from "react-dropzone";
import {isEmpty} from "lodash";
import {nanoid} from "nanoid";

import NmButton from "../../NmButton";
import ErrorTooltip from "../ErrorTooltip";
import NmConfirmV2 from "../NmConfirmV2";
import NmLabel from "../NmLabel";
import Text from "../Text";
import {DropzoneUploadedArea} from "./components/uploaded-area";

import useConfirm, {closeConfirmAction, openConfirmAction} from "../../../hooks/useConfirm";

import bem from "../../../utils/bem";
import {downloadLocalFile} from "../../../utils/downloadBlob";
import {getBytesFromMb} from "./utils/getBytesFromMB";
import {getFilesWithPreview} from "./utils/getFilesWithPreview";

import {COLOR} from "../../../constants/color";
import {REG_EXP} from "../../../constants/regExp";

import {
    EnumDropzoneErrorCode,
    TDropzoneConfirmData,
    TDropzoneFileRejection,
    TDropzoneOnRemoveFile,
    TFileWithPreview,
    TLocalErrorFile,
    TOnDownload} from "./types";

import "./style.sass";

interface IDropzoneV2 extends DropzoneProps {
    format?: string,
    files: Array<TFileWithPreview>,
    onChange: (acceptedFiles: Array<File>, errors?: string | null) => void,
    error?: string | null,
    // Проверка на совпадающие имена файлов при загрузке
    isEqualCheck?: boolean,
    // Показывать конфирмы при удалении прикрепленных файлов
    isShowConfirmForDeleteFile?: boolean,
    // Показывать кнопку скачивания у прикрепленных файлов
    isDownload?: boolean,
    // Показывать превью изображения
    isImagePreview?: boolean,
    isTopImagePreview?: boolean,
    className?: string,
    innerLabel?: string,
    uploadBtnText?: string,
    onDownload?: (params: TOnDownload) => void,
    maxSize: number,
    uploadedFileLabel?: string,
    onDeleteFile?: ({fileName}: { fileName: string }) => void,
    isShowDeleteButton?: boolean,
    onOpenFullImage?: (data: TFileWithPreview) => void,
    onCloseFullImage?: (data: TFileWithPreview) => void,
    required?: boolean,
    label?: string,
    tooltip?: ReactNode,
    isVisibleTooltip?: boolean,
    isVisibleLabel?: boolean,
    additionalFiles?: Array<TFileWithPreview>,
    isShowDropzone?: boolean,
    example?: ReactNode,
    classNameExample?: string,
    noteText?: string,
    setFilesErrors?: (data: object) => void,
    isShowUploadedFileNameTitle?: boolean,
    isHideImageDeleteButton?: boolean,
}

const DropzoneV2: FC<IDropzoneV2> = (props) => {
    const {
        accept = "image/*",
        uploadedFileLabel = "Файл:",
        isShowUploadedFileNameTitle = false,
        uploadBtnText = "Выбрать файл",
        innerLabel = "Выберите или перетащите файл",
        className = "",
        label,
        tooltip,
        multiple = false,
        maxSize,
        minSize,
        error,
        format,
        additionalFiles,
        files,
        disabled,
        required,
        isDownload,
        isImagePreview,
        isTopImagePreview,
        isEqualCheck,
        isVisibleTooltip,
        isVisibleLabel,
        isShowConfirmForDeleteFile,
        isShowDeleteButton,
        isShowDropzone = true,
        onChange,
        onDownload,
        onDeleteFile,
        onOpenFullImage,
        onCloseFullImage,
        example,
        classNameExample,
        noteText,
        setFilesErrors,
        isHideImageDeleteButton,
    } = props;
    const [block, element] = bem("dropzone-v2", className);
    const {
        fileRejections,
        getRootProps,
        getInputProps,
    } = useDropzone({
        validator: (file) => {
            if (file.name.search(REG_EXP.FORBIDDEN_WIN_SYMBOLS) > 0) {
                return {
                    code: EnumDropzoneErrorCode.winForbiddenSymbols,
                    message: "Не удалось загрузить файл. Файл содержит в наименовании запрещенные символы: /\\:*?<>\"",
                };
            }

            if (!isEqualCheck) {
                return null;
            }

            const fileNames = files.map((file) => file.name);

            if (fileNames.includes(file.name)) {
                return {
                    code: EnumDropzoneErrorCode.equalNames,
                    message: `Не удалось загрузить файл ${file.name}. Файл с таким именем уже существует.`,
                };
            }

            return null;
        },
        disabled,
        accept,
        onDrop,
        multiple: multiple,
        maxSize: getBytesFromMb(maxSize),
        minSize: getBytesFromMb(minSize),
    });

    const [dispatchConfirm, isOpenConfirm, contentConfirm] = useConfirm();

    const [confirmData, setConfirmData] = useState<TDropzoneConfirmData>({name: "", isError: false});
    const [errors, setErrors] = useState<Array<TLocalErrorFile>>([]);

    const isVisibleUploaded = !isEmpty(files) || !isEmpty(errors) || !isEmpty(additionalFiles);
    const isFixedTooltip = Boolean(error) && isVisibleUploaded;

    useEffect(() => {
        const _fileRejections = fileRejections.map(({file, errors}: TDropzoneFileRejection) => {
            if (isImagePreview && file.type.includes("image/")) {
                return {
                    preview: URL.createObjectURL(file),
                    type: file.type,
                    id: nanoid(4),
                    name: file.name,
                    errors,
                };
            }

            return {
                id: nanoid(4),
                name: file.name,
                errors,
            };
        });

        setErrors(_fileRejections);
    }, [fileRejections]);

    useEffect(() => {
        if (setFilesErrors) {
            setFilesErrors(errors);
        }
    }, [errors]);

    function onDrop(acceptedFiles: Array<File>) {
        if (!onChange) {
            return;
        }

        const _acceptedFiles = isImagePreview || isTopImagePreview ? getFilesWithPreview(acceptedFiles, accept) : acceptedFiles;

        if (isEqualCheck) {
            onChange([...files, ..._acceptedFiles]);

            return;
        }

        onChange(_acceptedFiles);
    }

    const deleteFile = (name: string, isError?: boolean) => {
        if (isOpenConfirm) {
            dispatchConfirm(closeConfirmAction());
        }

        if (isError) {
            const _errors = [...errors];

            const index = _errors.findIndex(file => (file.name === name));

            _errors.splice(index, 1);

            setErrors(_errors);

            return;
        }

        const _files = [...files];

        const index = _files.findIndex(file => (file.name === name));

        _files.splice(index, 1);

        onChange(_files);
    };

    const onRemoveFile = ({name, isError, isRemote}: TDropzoneOnRemoveFile) => {
        if (isShowConfirmForDeleteFile && !isError) {
            setConfirmData({name, isError});
            dispatchConfirm(openConfirmAction(`Вы действительно хотите удалить файл ${name}?`));

            if (isRemote && onDeleteFile) {
                onDeleteFile({
                    fileName: name,
                });

                return;
            }

            return;
        }

        if (isRemote && onDeleteFile) {
            onDeleteFile({
                fileName: name,
            });

            return;
        }

        deleteFile(name, isError);
    };

    const onDownloadFile = (file: any) => {
        const {
            isRemote,
            filePath,
            name,
        } = file;

        if (isRemote && onDownload) {
            onDownload({
                fileName: name,
                filePath,
            });

            return;
        }

        downloadLocalFile(file, name);
    };

    const getBaseContent = () => {
        if (!isShowDropzone) {
            return null;
        }

        const formatText = format ? `В формате ${format.toUpperCase()} ` : "";
        const sizeText = maxSize ? `размером до ${maxSize}МБ` : "";

        return (
            <div
                {...getRootProps({
                    className: element("target", {
                        disabled,
                        error: Boolean(error),
                        example: Boolean(example),
                    }),
                })}
            >
                <input {...getInputProps()} />
                <div className={element("info", {example: Boolean(example)})}>
                    {
                        example &&
                        <div className={`${element("example")} ${classNameExample}`}>
                            <Text
                                level="2"
                                color={COLOR.SECONDARY_70}
                                className="mb-2"
                            >
                                Образец
                            </Text>
                            <div className={element("example-img-container")}>
                                <div className={element("border-elem", {top: true, left: true})} />
                                <div className={element("border-elem", {top: true, right: true})} />
                                <div className={element("border-elem", {bottom: true, left: true})} />
                                <div className={element("border-elem", {bottom: true, right: true})} />
                                <div className={element("example-image")}>
                                    {example}
                                </div>
                            </div>
                        </div>
                    }
                    <Text
                        className={element("info-title", {example: Boolean(example)})}
                        level="4"
                    >
                        {innerLabel}
                    </Text>
                    <Text
                        className={element("info-text")}
                        level="2"
                    >
                        {formatText}
                        {sizeText}
                    </Text>
                    {
                        noteText &&
                        <Text
                            className={element("note-text")}
                            level="2"
                        >
                            <Text
                                inline
                                color={COLOR.INERT_100}
                                level="2"
                            >
                                Примечание.&nbsp;
                            </Text>
                            {noteText}
                        </Text>
                    }
                </div>
                <div className={element("button", {disabled, example: Boolean(example)})}>
                    {uploadBtnText}
                </div>
            </div>
        );
    };

    const getReplaceButton = () => {
        return isTopImagePreview &&
            <div
                {...getRootProps({
                    className: element("target-replace"),
                })}
            >
                <input {...getInputProps()} />
                <NmButton color="white">
                    Заменить
                </NmButton>
            </div>;
    };

    const getUploaded = () => {
        if (!isVisibleUploaded) {
            return null;
        }

        return (
            <DropzoneUploadedArea
                files={files}
                className={element("dropzone-uploaded-area")}
                isFixedTooltip={isFixedTooltip}
                isImagePreview={isImagePreview}
                isTopImagePreview={isTopImagePreview}
                isShowDeleteButton={isShowDeleteButton}
                isShowUploadedFileNameTitle={isShowUploadedFileNameTitle}
                uploadedFileLabel={uploadedFileLabel}
                multiple={multiple}
                isDownload={isDownload}
                onDownloadFile={onDownloadFile}
                onRemoveFile={onRemoveFile}
                maxSize={maxSize}
                minSize={minSize}
                format={format}
                errors={errors}
                additionalFiles={additionalFiles}
                onOpenFullImage={onOpenFullImage}
                onCloseFullImage={onCloseFullImage}
                replaceButton={getReplaceButton()}
                isHideImageDeleteButton={isHideImageDeleteButton}
            />
        );
    };

    const getError = () => {
        if (!error) {
            return null;
        }

        return (
            <ErrorTooltip
                className={element("tooltip", {fixed: isFixedTooltip})}
                error={error}
            />
        );
    };

    const getMultiple = () => {
        return (
            <>
                {getBaseContent()}
                {getError()}
                {getUploaded()}
            </>
        );
    };

    const getSingle = () => {
        if (isVisibleUploaded && isTopImagePreview) {
            return (
                <>
                    {getUploaded()}
                </>
            );
        }

        if (isVisibleUploaded && isImagePreview) {
            return (
                <>
                    {getBaseContent()}
                    {getUploaded()}
                </>
            );
        }

        if (isVisibleUploaded) {
            return getUploaded();
        }

        return (
            <>
                {getBaseContent()}
                {getError()}
            </>
        );
    };

    const getConfirm = () => {
        return isOpenConfirm &&
            <NmConfirmV2
                content={contentConfirm}
                onCancel={() => dispatchConfirm(closeConfirmAction())}
                onConfirm={() => {
                    deleteFile(confirmData.name, confirmData.isError);
                    setConfirmData({name: "", isError: false});
                }}
                confirmButton="Да"
                cancelButton="Нет"
                isOnlyConfirm
            />;
    };

    return (
        <div className={block()}>
            {getConfirm()}
            {
                label && isVisibleLabel &&
                <NmLabel
                    disabled={disabled}
                    required={required}
                    label={label}
                    tooltip={tooltip}
                    isVisibleTooltip={isVisibleTooltip}
                />
            }
            {
                multiple ?
                    getMultiple() :
                    getSingle()
            }
        </div>
    );
};

export default DropzoneV2;