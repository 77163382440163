import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {clientCardInfoSelector} from "../../info/selectors";
import {
    FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_ERROR,
    FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_REQUEST,
    FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../../../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../../../../../utils/localstorage";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: "/client-adm/patents/deposit",
            adminRolesUrl: "/adm/clients/client-card/patents/deposit",
        },
    );
};

// POST /bff/adm/clients/client-card/patents/deposit/invoice-of-deposit-replenishment-ndfl
// POST /bff/client-adm/client/patents/deposit/invoice-of-deposit-replenishment-ndfl
export const getDepositReplenishmentAccountBasisFileSaga = function* (action) {
    const {
        payload,
    } = action;

    const {
        onSuccess,
        onError,
        ...reqData
    } = payload;


    try {
        const controller = getController();

        const myInit = {
            method: "POST",
            mode: "cors",
            cache: "default",
            headers: {
                Authorization: `Bearer ${ls(ACCESS_TOKEN_KEY)}`,
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(reqData),
        };

        const result = yield fetch(`${new URL(window.location.href).origin}/bff${controller}/invoice-of-deposit-replenishment-ndfl`, myInit);

        if (result.status === 200 && result.headers.get("Content-Type").includes("json")) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                onError();

                return {
                    done: true,
                };
            }
        }

        onSuccess();

        const blob = yield result.blob();
        const state = yield select();
        const client = clientCardInfoSelector(state);
        const {name} = client;
        const fileName = `Счет-основание на пополнение депозита_${name}_${result.headers.get("Content-Disposition").split("filename=")[1]}`;

        downloadBlob(blob, result.headers, fileName);

        yield put({
            type: FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        onError();

        yield put({
            type: FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(FINANCE_PATENTS_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_REQUEST, getDepositReplenishmentAccountBasisFileSaga),
    ]);
}