import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty,isEqual} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import LinkMarkdown from  "../../../components/LinkMarkdown";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import NmSelectedList from "../../../components/NmSelectedList";
import NotificationsStoredAtFrontAndFormatted from "../../../components/NotificationsStoredAtFrontAndFormatted";
import NotificationsCard from "../card";
import NotificationsListFilter from "../filter";

import {formatLocalDate} from "../../../utils/dateFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";

import {
    addViewsNotification,
    getNotificationUnreadCount,
    getPageNotification,
} from "../../../ducks/bff/notifications/actionCreators";
import {
    notificationListSelector,
    notificationProgressSelector,
    notificationTotalCountSelector,
    notificationTotalPagesSelector,
} from "../../../ducks/bff/notifications/selectors";
import {downloadDocument} from "../../../ducks/documents";

class NotificationsList extends Component {
    constructor(props) {
        super(props);
        const {clientId} = props.match.params;

        this.state = {
            selectedList: [],
            countOrdersSelected: 0,
            loader: false,
            pageNum: 1,
            pageSize: 50,
            isOpenCard: false,
            notification: {},
            filter: {
                notViewed: true,
            },
            openConfirmWindow: false,
            isSearch: false,
        };

        this.clientId = clientId;
    }

    componentDidMount() {
        this.fetchList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list} = this.props;
        const {list: oldList} = prevProps;

        if (!isEqual(list, oldList)) {
            this.setState({
                selectedList: [],
                countOrdersSelected: 0,
            });
        }
    }

    fetchList = () => {
        this.setLoader(true);

        const {
            pageNum,
            pageSize,
            filter,
        } = this.state;

        const {getPageNotification} = this.props;

        getPageNotification({
            clientId: this.clientId,
            ...filter,
            pageNum,
            pageSize,
            getSuccess: this.handleGetSuccess,
        });
    };

    downloadFile(file) {
        const {downloadDocument} = this.props;
        const {link, name: fileName} = file;

        downloadDocument({
            isDownload: true,
            isFillOrigin: true,
            fileName,
            downloadLink: link,
        });
    }

    handleGetSuccess = () => {
        this.setLoader();
    };

    setLoader = (loader = false) => {
        this.setState({loader});
    };

    sendFilter = (filter, isSearch) => {
        this.setState({
            filter,
            pageNum: 1,
            isSearch,
        }, this.fetchList);
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    getRows() {
        const {selectedList} = this.state;
        const {list} = this.props;

        return list.map(row => {
            const notificationSelected = selectedList.find(item => (item.notificationId === row.notificationId)) || {};
            const {isSelected = false} = notificationSelected;

            return {
                ...row,
                isSelected,
                showCheckBox: true,
                disabledCheckBox: row.viewed,
                contentRow: this.getContentRow(row),
            };
        });
    }

    handleSelectedRows = (selectedList) => {
        this.setState({
            selectedList,
            countOrdersSelected: selectedList.filter(value => value.isSelected).length,
        });
    };

    toggleCardModal = (notification = {}) => {
        this.setState(prevState => ({
            ...prevState,
            notification,
            isOpenCard: !prevState.isOpenCard,
        }));
    };

    addViewsNotification = () => {
        const {
            addViewsNotification,
            getNotificationUnreadCount,
        } = this.props;
        const {selectedList} = this.state;

        const notificationIdList = selectedList.filter(value => value.isSelected).map(item => item.notificationId);

        addViewsNotification({
            getSuccess: () => {
                getNotificationUnreadCount({clientId: this.clientId});
                this.fetchList();
                this.toggleConfirmWindow();
            },
            notificationIdList,
            clientId: this.clientId,
        });
    };

    toggleConfirmWindow = () => {
        this.setState(prevState => ({
            ...prevState,
            openConfirmWindow: !prevState.openConfirmWindow,
        }));
    };

    //При наличии вложений у уведомления палитрой отобразить наименования файлов вложения.
    // В случае, если прикреплённых вложений больше трёх, отобразить три вложения и вывести рядом счетчик вложений.
    renderInfoFiles(row) {
        const {files} = row;

        if (isEmpty(files)) {
            return null;
        }

        const list = files.map(file => {
            const {name} = file;

            return {
                text: name,
                title: "Скачать",
                onClick: () => {this.downloadFile(file);},
            };
        });

        return (
            <NmSelectedList
                showedItemsCount={3}
                isLimiterComma
                isItemCursorPointer
                itemColor={COLOR.PASSIVE_100}
                highlightingFirstItem={false}
                showListWithoutValue
                list={list}
            />
        );
    }

    getContentRow(row) {
        const {
            title,
            sendTime,
            message,
            files,
        } = row;

        const docs = !isEmpty(files) ? [{
            label: "Документы", columnOnMobile: true, text: this.renderInfoFiles(row),
        }] : [];

        return (
            <NmListCard
                noDivider
                secondaryHeader={formatLocalDate(sendTime, "dd.MM.yyyy HH:mm")}
                primaryHeader={title}
                primaryHeaderLink
                onClickLink={() => {this.toggleCardModal(row);}}
                labels={[
                    {
                        text: (
                            <Text
                                preWrap={true}
                                level="2"
                            >
                                <LinkMarkdown>
                                    {message}
                                </LinkMarkdown>
                            </Text>
                        ),
                        noWrap: false,
                    },
                    ...docs,
                ]}
            />
        );
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
        } = this.state;
        const {t} = this.props;

        if (!openConfirmWindow) {
            return null;
        }

        return (
            <NmConfirmV2
                content="Отметить прочитанными выбранные уведомления?"
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
                onCancel={this.toggleConfirmWindow}
                onConfirm={this.addViewsNotification}
                isNeedClosing={false}
            />
        );
    }

    render() {
        const {
            totalPages,
            totalCount,
            list,
            progress,
            location,
        } = this.props;

        const {
            countOrdersSelected,
            pageNum,
            pageSize,
            isOpenCard,
            notification,
            isSearch,
        } = this.state;

        return (
            <>
                {this.renderConfirmWindow()}
                {
                    isOpenCard &&
                    <NotificationsCard
                        location={location}
                        cardTitle="Уведомление"
                        fetchList={this.fetchList}
                        notification={notification}
                        notificationMessage={
                            <NotificationsStoredAtFrontAndFormatted
                                notification={notification}
                            />
                        }
                        close={() => {
                            this.toggleCardModal();
                        }}
                        clientId={this.clientId}
                    />
                }
                <NmPage
                    header={
                        <NmPageHeader
                            text="Уведомления"
                            onSelectedRows={this.handleSelectedRows}
                            totalCount={totalCount}
                        />
                    }
                    typeFilter="vertical"
                    currentPageSize={pageSize}
                    currentPageNum={pageNum}
                    totalPages={totalPages}
                    onChangePageSize={this.handleChangePageSize}
                    onPaginationChange={this.handlePaginationChange}
                    filtersBase={
                        <NotificationsListFilter
                            clientId={this.clientId}
                            sendFilter={this.sendFilter}
                        />
                    }
                    isLoaded={progress}
                    totalCount={totalCount}
                >
                    {
                        list?.length
                            ? <CheckboxList
                                onSelectedRows={this.handleSelectedRows}
                                rows={this.getRows()}
                                count={countOrdersSelected}
                                mediaControls={{
                                    renderCount: {
                                        mobile: 0,
                                        tablet: 1,
                                        desktop: 1,
                                    },
                                    buttons: [
                                        {
                                            component: COMPONENT.BUTTON,
                                            props: {
                                                size: "lg",
                                                color: "grey",
                                                disabled: countOrdersSelected === 0,
                                                onClick: this.toggleConfirmWindow,
                                                children: "Отметить прочитанными",
                                            },
                                        },
                                    ],
                                }}
                            />
                            : <NmEmptyPageV2
                                isSearch={isSearch}
                                fetchProgress={progress}
                            />
                    }
                </NmPage>
            </>
        );
    }
}

export default connect(state => ({
    totalPages: notificationTotalPagesSelector(state),
    list: notificationListSelector(state),
    totalCount: notificationTotalCountSelector(state),
    progress: notificationProgressSelector(state),
}),
{
    getPageNotification,
    addViewsNotification,
    getNotificationUnreadCount,
    downloadDocument,
},
)(withTranslation()(NotificationsList));
