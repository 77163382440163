import React from "react";
import {useSelector} from "react-redux";

import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import Tabs from "../../../../components/ActualComponents/Tabs";
import ButtonBack from "../../../../components/ButtonBack";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import Task from "../../../../components/NmTask";
import NmTitle from "../../../../components/NmTitle";
import {GroupCardRoutes} from "./group-card-routes";

import {useActionsCardGroup} from "./hooks/useActionsCradGroup";
import {useGetInfoCardGroup} from "./hooks/useGetInfoCardGroup";
import {useImportTasks} from "./hooks/useImportTasks";

import bem from "../../../../utils/bem";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {LINK_SETTINGS_CLIENT_GROUP, LINK_SETTINGS_CLIENT_GROUP_CARD} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../../constants/roles";

import {history} from "../../../../store/configureStore";

import {
    settingsClientGroupsCardInfoSelector,
    settingsClientGroupsProgressActionSelector,
    settingsClientGroupsProgressSelector,
} from "../../../../ducks/bff/settings/client-groups/card/selectors";
import {tasksImportGroupSelector} from "../../../../ducks/job";

import "./style.sass";

const GroupCard = (props) => {
    const {
        location: {
            pathname,
        },
        match: {
            params: {
                groupId,
                tab,
            },
        },
    } = props;

    const [block, element] = bem("group-card");
    const role = ls(USER_ROLE);

    const tasksImportGroup = useSelector(tasksImportGroupSelector);
    const {importProgress} = useImportTasks(tasksImportGroup, groupId);

    useGetInfoCardGroup(groupId, importProgress);

    const {
        groupName,
        description,
        countClients,
        countContractors,
        countClientUsers,
    } = useSelector(settingsClientGroupsCardInfoSelector);
    const loading = useSelector(settingsClientGroupsProgressSelector);
    const progressAction = useSelector(settingsClientGroupsProgressActionSelector);

    const isAccessEdit = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingClientGroups,
    ]);

    const getTabs = () => {
        const linkCard = LINK_SETTINGS_CLIENT_GROUP_CARD.replace(":groupId", groupId);
        const linkClients = linkCard.replace(":tab", "clients");
        const linkContractors = linkCard.replace(":tab", "contractors");
        const linkMembers = linkCard.replace(":tab", "members-list");

        return [
            {
                name: "Заказчики",
                link: linkClients,
                active: pathname.includes("clients"),
                count: countClients,
            },
            {
                name: "Исполнители",
                link: linkContractors,
                active: pathname.includes("contractors"),
                count: countContractors,
            },
            {
                name: "Сотрудники",
                link: linkMembers,
                active: pathname.includes("members-list"),
                count: countClientUsers,
            },
        ];
    };

    const {
        isOpenImport,
        onCloseModal,
        onSubmitImportList,
        onClickImportList,
    } = useActionsCardGroup(groupId, tab);

    const renderModalWindow = () => {
        if (!isOpenImport) {
            return null;
        }

        return (
            <ImportFromFilePatternV2
                patternLink="/files/Шаблон_Группы компаний.xlsx"
                onSubmit={onSubmitImportList}
                onClose={onCloseModal}
                progress={progressAction}
            />
        );
    };

    return (
        <NmPage
            header={
                <div className={element("header")}>
                    <ButtonBack
                        onClick={() => history.push(LINK_SETTINGS_CLIENT_GROUP)}
                    />
                    <NmTitle className={element("header-title")}>
                        {groupName}
                    </NmTitle>
                </div>
            }
            controls={
                <>
                    {
                        isAccessEdit &&
                        ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role) &&
                        <NmButton
                            color="grey"
                            onClick={onClickImportList}
                            loading={importProgress}
                            disabled={importProgress}
                        >
                            Загрузить список
                        </NmButton>
                    }
                </>
            }
            subHeader={
                <>
                    <NmTitle size="md">
                        Группа компаний для новых заказчиков
                    </NmTitle>
                    {description}
                </>
            }
            isLoaded={loading}
        >
            {!!tasksImportGroup.length && <Task />}
            <Tabs
                className="base-tabset base-tabset_big-offset contractor-card__tabs"
                panes={getTabs()}
            />
            <GroupCardRoutes
                groupId={groupId}
                isAccessEdit={isAccessEdit}
            />
            {renderModalWindow()}
        </NmPage>
    );
};

export default GroupCard;