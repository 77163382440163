import {COMPANY_SAMPLE_TYPE, UserType} from "../edit-modal";
import {UserListItem} from "../list";

import {getFormattedFullName, getFullName} from "../../../../utils/stringFormat";

export type PromotionType = {
    promotionId: string,
    requisites: string,
    type: string,
    name: string,
    clientUserId: string,
    clientId: string
}

export default function mapUserListItemToUserForm(userListItem: UserListItem): UserType {
    return {
        fullName: getFormattedFullName(getFullName(userListItem.lastName, userListItem.firstName, userListItem.patronymic)),
        clientUserId: userListItem.clientUserId,
        email: userListItem.email,
        firstName: userListItem.firstName,
        patronymic: userListItem.patronymic,
        login: userListItem.login,
        phone: userListItem.phone,
        role: userListItem.role || "",
        roleId: userListItem.roleId || "",
        restrictionRoleName : userListItem.restrictionRoleName || "",
        position: userListItem.position,
        snils: userListItem.snils,
        clientId: userListItem.clientId,
        lastName: userListItem.lastName,
        partnerPromotionContractorIdList: userListItem.partnerPromotionContractorList?.map(s => s.promotionId),
        partnerPromotionClientIdList: userListItem.partnerPromotionClientList?.map(s => s.promotionId),
        archived: userListItem.archived,
        password: userListItem.password,
        availableClients: userListItem.availableClients,
        canManageAllClients: userListItem.canManageAllClients,
        sampleType: userListItem.canManageAllClients ? COMPANY_SAMPLE_TYPE.ALL : COMPANY_SAMPLE_TYPE.MULTIPLE,
        sipNumber: userListItem.sipNumber,
        extensionNumber: userListItem.extensionNumber,
        sendPassword: userListItem.sendPassword,
    };
}