import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../../utils/mathHelper";
import {edmSubdivisionsToOptions} from "../../../../../../utils/objectHelper";

export const edmSubdivisionsSelector = state => state.bff.clientsEdoDirectorySubdivisions;
export const edmSubdivisionsTotalCountSelector = createSelector(edmSubdivisionsSelector, ({totalCount}) => totalCount);
export const edmSubdivisionsCommonCountSelector = createSelector(edmSubdivisionsSelector, ({commonCount}) => commonCount);
export const edmSubdivisionsPageDataSelector = createSelector(edmSubdivisionsSelector, ({pageData}) => pageData);
export const edmSubdivisionsListSelector = createSelector(edmSubdivisionsSelector, ({list}) => list);
export const edmSubdivisionsOptionsSelector = createSelector(edmSubdivisionsSelector, ({list}) => edmSubdivisionsToOptions(list));
export const edmSubdivisionsTotalPagesSelector = createSelector(edmSubdivisionsSelector, ({
    totalCount, pageData: {
        pageSize = 0,
    },
}) => getTotalPages(totalCount, pageSize));