import React, {useMemo,useState} from "react";
import {useDispatch} from "react-redux";

import NmModal from "../../components/ActualComponents/NmModal";
import ApplyButtons from "../../components/ApplyButtons";
import NmTitle from "../../components/NmTitle";
import {ReactComponent as CancelIcon} from "../../images/close_24.svg";
import {ReactComponent as DoneIcon} from "../../images/done_24.svg";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import NmConfirmV2Content from "../ActualComponents/NmConfirmV2/Content";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmListCard from "../ActualComponents/NmList/Card";
import CheckboxList from "../CheckboxList";

import bem from "../../utils/bem";
import {formatLocalDate} from "../../utils/dateFormat";
import {isAccessByRestrictions} from "../../utils/restrictions";
import validate from "../../utils/validate";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {LINK_ORDER_CARD} from "../../constants/links";
import {editSpecialityOffer} from "../../constants/validationRules";
import {COMPONENT} from "../ActualComponents/MediaControls/constants";

import {
    decisionDirectoriesSpecialtyOffer,
    editDirectoriesSpecialtyOffer,
} from "../../ducks/bff/settings/directories/naimix/specialty-offer/actionCreators";

import PropTypes from "prop-types";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../constants/link-params";

const SpecialtyOffer = (props) => {
    const {
        isSearch,
        list = [],
    } = props;

    const [isModalShown, setModalShown] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [currentOfferData, setCurrentOfferData] = useState({});
    const [specialty, setSpecialty] = useState("");
    const [error, setError] = useState({});
    const [, element] = bem("specialty-offer");

    const isAccessActions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.editSettingDirectory,
    ]);

    const validateFormEditSpeciality = () => {
        const errorMessage = validate({offeredSpecialityName: specialty}, editSpecialityOffer, "");

        setError(errorMessage);

        return Object.values(errorMessage).length === 0;
    };

    const dispatch = useDispatch();

    const handleShowAcceptModal = (offer) => {
        setModalShown(true);
        setModalType("accept");
        setCurrentOfferData(offer);
    };

    const handleShowDeclineModal = (offer) => {
        setModalShown(true);
        setModalType("decline");
        setCurrentOfferData(offer);
    };

    const handleShowEditModal = (offer) => {
        setModalShown(true);
        setModalType("edit");
        setCurrentOfferData(offer);
        setSpecialty(offer.offeredSpecialityName);
    };

    const handleCloseModal = () => {
        setModalShown(false);
        setModalType(null);
        setCurrentOfferData({});
        setSpecialty("");
    };

    const handleDecisionSpecialty = (accept, id) => {
        dispatch(decisionDirectoriesSpecialtyOffer({id, accept}));
        handleCloseModal();
    };

    const handleEditSpecialty = (id, name) => {
        if (validateFormEditSpeciality()) {
            dispatch(editDirectoriesSpecialtyOffer({id, name}));
            handleCloseModal();
        }
    };

    const getMediaControls = (offer) => {
        if (!isAccessActions) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 3,
                desktop: 3,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            handleShowAcceptModal(offer);
                        },
                        color: "light-green",
                        onlyIcon: true,
                        icon: (
                            <DoneIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Одобрить",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            handleShowDeclineModal(offer);
                        },
                        color: "red",
                        onlyIcon: true,
                        icon: (
                            <CancelIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Отказать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            handleShowEditModal(offer);
                        },
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((offer) => {
            const {
                offeredSpecialityName,
                clientName,
                orderNum,
                offeredDate,
                clientId,
                orderId,
            } = offer;

            const orderLink = LINK_ORDER_CARD
                .replace(":clientId", clientId)
                .replace(":orderId", orderId)
                .replace(":page", SELF_CONTRACTOR)
                .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

            return {
                ...offer,
                key: offer.id,
                contentRow: (
                    <NmListCard
                        secondaryHeader={formatLocalDate(offeredDate)}
                        primaryHeader={
                            <div className={element("primary-header-value")}>
                                {offeredSpecialityName}
                            </div>
                        }
                        classNamePrimaryHeaderValue="fluid"
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        labels={[
                            {
                                label: "Закзачик",
                                text: clientName,
                            },
                            {
                                label: "Номер заказа",
                                text: <a
                                    target="_blank"
                                    href={orderLink}
                                    rel="noreferrer"
                                      >
                                    {orderNum}
                                </a>,
                            },
                        ]}
                        isFixedActions
                        mediaControls={getMediaControls(offer)}
                    />
                ),
            };
        });
    }, [list]);

    const renderModalContentByType = () => {
        const {
            offeredSpecialityName,
            id,
            clientName,
            orderNum,
            offeredDate,
        } = currentOfferData;

        if (modalType === "accept") {
            return (
                <div className="d-flex flex-column">
                    <NmConfirmV2Content
                        align="center"
                        content={`Вы действительно хотите добавить "${offeredSpecialityName}" в список видов деятельности?`}
                    />
                    <ApplyButtons
                        align="center"
                        className="mt-4 mt-md-5 mt-xxl-7"
                        submitBtnContent="Да"
                        cancelBtnContent="Отмена"
                        onClose={handleCloseModal}
                        submit={() => {
                            handleDecisionSpecialty(true, id);
                        }}
                    />
                </div>
            );
        }

        if (modalType === "decline") {
            return (
                <div className="d-flex flex-column">
                    <NmConfirmV2Content
                        align="center"
                        content={`Вы действительно хотите отклонить добавление в список видов деятельности позиции "${offeredSpecialityName}"?`}
                    />
                    <ApplyButtons
                        align="center"
                        className="mt-4 mt-md-5 mt-xxl-7"
                        submitBtnContent="Да"
                        cancelBtnContent="Отмена"
                        onClose={handleCloseModal}
                        submit={() => {
                            handleDecisionSpecialty(false, id);
                        }}
                    />
                </div>
            );
        }

        if (modalType === "edit") {
            return (
                <NmForm
                    addMargin
                    noValidate
                >
                    <NmTitle size="lg">
                        Редактирование вида деятельности
                    </NmTitle>
                    <NmInputV2
                        size="xl"
                        maxLength={100}
                        label={"Вид деятельности"}
                        onChange={(e, {value}) => setSpecialty(value)}
                        value={specialty}
                        name={"offeredSpecialityName"}
                        error={error["offeredSpecialityName"]}
                    />
                    <NmInputV2
                        size="xl"
                        value={clientName}
                        label="Заказчик"
                        disabled
                    />
                    <div className="row gx-4">
                        <div className="col-16 col-md-8">
                            <NmInputV2
                                size="xl"
                                value={orderNum}
                                label={"Номер заказа"}
                                disabled
                            />
                        </div>
                        <div className="col-16 col-md-8 mt-3 mt-md-0">
                            <NmInputV2
                                size="xl"
                                value={formatLocalDate(offeredDate)}
                                label={"Дата предложения"}
                                disabled
                            />
                        </div>
                    </div>
                    <ApplyButtons
                        mobile="column"
                        isHiddenCancelOnMobile
                        className="mt-4 mt-md-5 mt-xxl-7"
                        submitBtnContent="Сохранить"
                        cancelBtnContent="Отменить"
                        onClose={handleCloseModal}
                        submit={() => {
                            handleEditSpecialty(id, specialty);
                        }}
                    />
                </NmForm>
            );
        }
    };

    return (
        <>
            {
                list.length ?
                    <CheckboxList
                        rows={rows}
                    /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    />
            }
            {
                isModalShown &&
                <NmModal
                    onClose={handleCloseModal}
                    size="md"
                >
                    {renderModalContentByType()}
                </NmModal>
            }
        </>
    );
};

SpecialtyOffer.propTypes = {
    className: PropTypes.string,
};

export default SpecialtyOffer;