import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import NmPageInfoCardsAccordion from "../../../../components/ActualComponents/NmPageInfoCardsAccordion";
import {ReactComponent as ErrorIcon} from "../../../../images/fault-bg.svg";

import {useDepositValuesFetch} from "../../../../components/DepositValuesAmountInfo/hooks/useDepositValuesFetch";

import {getDepositValuesCards} from "../../../../components/DepositValuesAmountInfo/utils/getDepositValuesCards";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";

const FinancePaymentListAmountValues = ({isClientPayments, isCivil, selectedSum, clientId, isShow}) => {
    const {
        enableIndividualProjectAndObjectDeposit,
    } = useSelector(clientCardPropertiesSelector);

    const {
        depositValues,
        isLoading,
    } = useDepositValuesFetch({
        isCivil,
        clientId,
        isFetchValues: isClientPayments,
    });
    const {availableForPaymentsAmount = 0} = depositValues;

    const {t} = useTranslation();

    const getAvailableForPaymentCard = () => {
        const card =  {
            title: t("finance-payment-list.free-balance-after-payment"),
            isLoading,
            className: "col-16 col-xxl-2",
        };

        if (enableIndividualProjectAndObjectDeposit) {
            card.value = "-";

            return card;
        }

        const amount = availableForPaymentsAmount - selectedSum();

        card.value = `${formatAmountWithNullChecking(amount)} ₽`;
        card.icon = amount < 0 ?
            <ErrorIcon
                width={16}
                height={16}
            /> :
            null;

        return card;
    };

    const getCards = () => {
        if (!isClientPayments) {
            return [
                {
                    title: t("finance-payment-list.amount-selected-payments"),
                    value: `${formatAmountWithNullChecking(selectedSum(false))} ₽`,
                    className: "col-16 col-xxl-2",
                },
            ];
        }

        const {
            totalDeposit,
            clientDeposit,
            nonDistributedDeposit,
            deposit,
            awaitingConfirmationPayments,
            inProgressPayments,
            availableForPayments,
        } = getDepositValuesCards({
            isCivil,
            depositValues,
            isLoading,
        });

        return [
            {
                ...totalDeposit,
                className: "col-16 col-xxl-2",
            },
            {
                ...nonDistributedDeposit,
                className: "col-16 col-xxl-2",
            },
            {
                ...clientDeposit,
                className: "col-16 col-xxl-2",
            },
            {
                ...deposit,
                className: "col-16 col-xxl-2",
            },
            {
                ...awaitingConfirmationPayments,
                className: "col-16 col-xxl-2",
            },
            {
                ...inProgressPayments,
                className: "col-16 col-xxl-2",
            },
            {
                ...availableForPayments,
                className: "col-16 col-xxl-2",
            },
            {
                title: t("finance-payment-list.amount-selected-payments"),
                value: `${formatAmountWithNullChecking(selectedSum())} ₽`,
                className: "col-16 col-xxl-2",
            },
            getAvailableForPaymentCard(),
        ];
    };

    if (!isShow) {
        return null;
    }

    return (
        <NmPageInfoCardsAccordion
            bootstrap={true}
            cards={getCards()}
            desktopViewFrom="xxl"
        />
    );
};

export default FinancePaymentListAmountValues;