import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import NmSelectedList from "../../../../../components/NmSelectedList";
import NmTitle from "../../../../../components/NmTitle";

import {usePagination} from "../../../../../hooks/usePagination";

import {formatLocalDate} from "../../../../../utils/dateFormat";

import {USERS_ROLES_ACTION_TYPE} from "../../../../../constants/settingsUsersRoles";

import {
    getSettingsUsersClientRoleConfigLogs,
    settingsUsersClientRolesConfigureLogListSelector,
    settingsUsersClientRolesConfigureLogProgressSelector,
    settingsUsersClientRolesConfigureLogTotalCountSelector,
    settingsUsersClientRolesConfigureLogTotalPagesSelector,
    updateFieldSettingsUsersRoles,
} from "../../../../../ducks/settingsUsersRoles";

const ClientRoleSettingsLogModal = (props) => {
    const {
        onClose,
        roleId,
        name,
    } = props;

    const list = useSelector(settingsUsersClientRolesConfigureLogListSelector);
    const totalPages = useSelector(settingsUsersClientRolesConfigureLogTotalPagesSelector);
    const totalCount = useSelector(settingsUsersClientRolesConfigureLogTotalCountSelector);
    const progress = useSelector(settingsUsersClientRolesConfigureLogProgressSelector);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    useEffect(() => {
        return () => {
            dispatch(updateFieldSettingsUsersRoles({
                logs: {
                    pageData: {},
                    list: [],
                    totalCount: 0,
                    progress: false,
                    error: null,
                },
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize]);

    const fetchList = () => {
        dispatch(getSettingsUsersClientRoleConfigLogs({
            pageNum,
            pageSize,
            roleId,
            type: USERS_ROLES_ACTION_TYPE.CONFIGURE_CLIENT_ROLE,
        }));
    };

    const rows = useMemo(() => {
        return list.map(item => {
            const {
                dateTime,
                initiator,
                nameForClient,
                clients,
            } = item;

            return {
                ...item,
                key: dateTime,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader="Изменение настроек роли для компаний"
                        labels={[
                            {
                                label: "Дата",
                                text: formatLocalDate(dateTime, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Инициатор",
                                text: initiator || "-",
                            },
                            {
                                label: "Название роли для компаний",
                                text: nameForClient || "-",
                            },

                            {
                                label: "Компании",
                                columnOnMobile: true,
                                text: clients?.length
                                    ? <NmSelectedList
                                        showListWithoutValue={true}
                                        showedItemsCount={3}
                                        highlightingFirstItem={false}
                                        list={
                                            clients.map(({name, clientId}) => ({
                                                text: name,
                                                value: clientId,
                                                key: clientId,
                                            }))
                                        }
                                    />
                                    : "-",
                            },
                        ]}
                    />
                ),
            };
        });
    }, [list]);

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Лог изменений настроек роли "${name}"`}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset={true}
                showHeaderBlock={false}
                noPadding={true}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
                isEmptyPage={isEmpty(list)}
            >
                <CheckboxList
                    rows={rows}
                />
            </NmPage>
        </NmModal>
    );
};

export default ClientRoleSettingsLogModal;