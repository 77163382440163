import React from "react";
import {useSelector} from "react-redux";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../../../images/add.svg";
import KedoTemplateEdit from "../edit";

import {usePagination} from "../../../../../hooks/usePagination";
import {useKedoTemplatesActions} from "../../hooks/useActions";
import {useKedoTemplateFetch} from "../../hooks/useFetch";
import {useKedoTemplatesFilter} from "../../hooks/useFilter";

import {formatLocalDate} from "../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";
import {getFormattedFullName, getFullName} from "../../../../../utils/stringFormat";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {ADMIN} from "../../../../../constants/roles";

import {
    kedoTemplatesListSelector,
    kedoTemplatesProgressSelector,
    kedoTemplatesTotalCountSelector,
    kedoTemplatesTotalPageSelector,
} from "../../../../../ducks/kedo/templates/selectors";

export const KedoTemplatesList = () => {
    const role = ls(USER_ROLE);

    const list = useSelector(kedoTemplatesListSelector);
    const totalPages = useSelector(kedoTemplatesTotalPageSelector);
    const totalCount = useSelector(kedoTemplatesTotalCountSelector);
    const progress = useSelector(kedoTemplatesProgressSelector);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        initFilter,
        filters,
        onClear,
        onSearch,
        isSearch,
        filterData,
    } = useKedoTemplatesFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useKedoTemplateFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        getListActions,
        onCloseModal,
        onOpenModal,
        modalData,
    } = useKedoTemplatesActions({
        fetchList,
    });

    const renderEdit = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <KedoTemplateEdit
                onClose={onCloseModal}
                fetchList={fetchList}
                name={modalData.name}
                id={modalData.id}
                htmlDownloadLink={modalData.htmlDownloadLink}
                userReferenceParamModels={modalData.userReferenceParamModels}
            />
        );
    };

    const renderConfirm = () => {
        if (!modalData?.isOpenConfirm) {
            return null;
        }

        const {
            content,
            onConfirm,
        } = modalData;

        return (
            <NmConfirmV2
                content={content}
                onCancel={onCloseModal}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отмена"
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                lastVersionDateTime,
                name,
                addedByClientUserLastName,
                addedByClientUserFirstName,
                addedByClientUserPatronymic,
            } = item;

            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        secondaryHeader={`Дата создания ${formatLocalDate(lastVersionDateTime, "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={name}
                        classNameMainContent="col-16 col-xxl-12"
                        labels={[
                            {
                                label: "Создатель",
                                text: getFormattedFullName(
                                    getFullName(
                                        addedByClientUserLastName,
                                        addedByClientUserFirstName,
                                        addedByClientUserPatronymic,
                                    ),
                                ),
                            },
                        ]}
                        isFixedActions
                        actionsClassName="col-4"
                        mediaControls={getListActions(item)}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            typeFilter="vertical"
            widthByFilter
            noPadding={true}
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            controls={
                (
                    isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsTemplatesKedo,
                    ])
                    && [ADMIN].includes(role)
                ) && <NmButton
                    icon={<AddIcon />}
                    size="xl"
                    onClick={() => {
                        onOpenModal({
                            isOpenEdit: true,
                        });
                    }}
                >
                    Создать шаблон
                </NmButton>
            }
            header={
                <NmTitle
                    size="xl"
                    count={totalCount}
                >
                    Шаблоны
                </NmTitle>
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {renderEdit()}
            {renderConfirm()}
            {
                list.length ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2 isSearch={isSearch} />
            }
        </NmPage>
    );
};