import React from "react";
import {useSelector} from "react-redux";

import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";
import {
    bffRecruitmentDirectoryJobRequirementListSelector,
    bffRecruitmentDirectoryJobRequirementTotalCountSelector,
    bffRecruitmentDirectoryJobRequirementTotalPagesSelector,
    bffRecruitmentDirectoryProgressSelector,
} from "../../../../ducks/bff/recruitment/directory/selectors";
import {getRecruitmentDirectoriesAccessActions} from "../helpers/getAccessActions";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import useRecruitmentDirectoriesJobRequirementsAction from "./hooks/useAction";
import useRecruitmentDirectoriesJobRequirementsFetchList from "./hooks/useFetchList";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

const ClientRecruitmentDirectoriesJobRequirements = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentDirectoryJobRequirementListSelector);
    const totalCount = useSelector(bffRecruitmentDirectoryJobRequirementTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentDirectoryJobRequirementTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentDirectoryProgressSelector);

    const role = ls(USER_ROLE);
    const isAccessAction = getRecruitmentDirectoriesAccessActions({
        role,
    });

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentDirectoriesJobRequirementsFetchList({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    const {
        onAdd,
        onEdit,
        onDelete,
    } = useRecruitmentDirectoriesJobRequirementsAction({
        clientId,
    });

    return (
        <UpdatingDirectories
            title="Дополнительные требования по вакансиям"
            titleTooltipText="Страница предназначена для управления дополнительными требованиями, которые используются
            при создании вакансий."
            labelFilter="Дополнительное требование"
            placeholderFilter="Введите название доп. требования"
            onSubmitFilter={onSearch}
            isSearch={isSearch}
            filterNameField="name"
            clearFilter={onClear}
            filter={filterData}
            isEditable={isAccessAction}
            onAdd={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            fetchList={fetchList}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText="Добавить доп. требование"
            popupTitleAdd="Добавление доп. требования для вакансий"
            popupTitleEdit="Редактирование доп. требования для вакансий"
            popupTitleDelete="Вы действительно хотите удалить доп. требование из справочника?"
            popupSubmitDelete="Подтвердить"
            popupCancelDelete="Отмена"
            inputTypeLabel="Доп. требование"
            inputTypePlaceholder="Введите доп. требование"
            inputTypeMaxLength={50}
            isLoaded={progressList}
            isNeedClosingOnSubmit={false}
        />
    );
};

export default ClientRecruitmentDirectoriesJobRequirements;