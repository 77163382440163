import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {useUnavailableTemplates} from "../../../../../components/UnavailableTemplates/hooks/useTemplates";

import {getUnavailableTemplatesFlags} from "../../../../../components/UnavailableTemplates/utils";

import {ORDER_LIST_MASS_TEMPLATE_PUBLICATION_FETCH_QUERIES_SCHEME} from "../constants";

import {
    getDocumentCustomTemplateShortActual,
    getDocumentCustomTemplateShortDefaultAggregation,
    updateDocumentCustomTemplateStore,
} from "../../../../../ducks/bff/documents-templates/actionCreators";
import {
    documentCustomTemplateAggregationShortTemplatesSelector,
    documentCustomTemplateDefaultAggregationSelector,
} from "../../../../../ducks/bff/documents-templates/selectors";

export const useOrderListMassTemplatePublicationForm = (params) => {
    const {
        clientId,
        isNpd,
        isNdfl,
        customTemplateIds,
    } = params;

    const [form, setForm] = useState({useActualTemplates: false});
    const dispatch = useDispatch();

    const {
        progressReplacementsForUnavailableSelector,
        isOpenUnavailableTemplates,
        onOpenUnavailableTemplates,
        onCloseUnavailableTemplates,
        checkUnavailableTemplates,
    } = useUnavailableTemplates();

    const defaultTemplatesAggregation = useSelector(documentCustomTemplateDefaultAggregationSelector);
    const allOptionsInfo = useSelector(documentCustomTemplateAggregationShortTemplatesSelector);

    useEffect(() => {
        const {NDFL, NPD} = ORDER_LIST_MASS_TEMPLATE_PUBLICATION_FETCH_QUERIES_SCHEME;

        if (isNpd) {
            NPD.forEach(item => {
                dispatch(getDocumentCustomTemplateShortActual({
                    isAggregation: true,
                    aggregationFieldName: item.NAME,
                    fieldName: "aggregationShortTemplates",
                    clientIdFilter: clientId,
                    ...item.PARAMS,
                    pageNum: 1,
                    pageSize: 1000,
                }));
            });
        }

        if (isNdfl) {
            NDFL.forEach(item => {
                dispatch(getDocumentCustomTemplateShortActual({
                    isAggregation: true,
                    aggregationFieldName: item.NAME,
                    fieldName: "aggregationShortTemplates",
                    clientIdFilter: clientId,
                    ...item.PARAMS,
                    pageNum: 1,
                    pageSize: 1000,
                }));
            });
        }

        dispatch(getDocumentCustomTemplateShortDefaultAggregation({
            clientIdFilter: clientId,
        }));

        return () => {
            dispatch(updateDocumentCustomTemplateStore({
                defaultTemplatesAggregation: {},
            }));
        };
    }, []);

    useEffect(() => {
        if (isEmpty(defaultTemplatesAggregation)) {
            return;
        }

        let _form = {...form};

        if (isNpd) {
            const {
                actOfAcceptanceOfWorkSmzAmountBased,
                actOfAcceptanceOfWorkSmzVolumeOfWorkBased,
                frameContractSmz,
                orderApplicationSmzAmountBased,
                orderApplicationSmzVolumeOfWorkBased,
            } = defaultTemplatesAggregation || {};

            _form = {
                actOfAcceptanceOfWorkSmzAmountBasedId: actOfAcceptanceOfWorkSmzAmountBased ?
                    actOfAcceptanceOfWorkSmzAmountBased.id : undefined,
                actOfAcceptanceOfWorkSmzVolumeOfWorkBasedId: actOfAcceptanceOfWorkSmzVolumeOfWorkBased ?
                    actOfAcceptanceOfWorkSmzVolumeOfWorkBased.id : undefined,
                frameContractSmzId: frameContractSmz ?
                    frameContractSmz.id : undefined,
                orderApplicationSmzAmountBasedId: orderApplicationSmzAmountBased ?
                    orderApplicationSmzAmountBased.id : undefined,
                orderApplicationSmzVolumeOfWorkBasedId: orderApplicationSmzVolumeOfWorkBased ?
                    orderApplicationSmzVolumeOfWorkBased.id : undefined,
            };
        }

        if (isNdfl) {
            const {
                actOfAcceptanceOfWorkCivilAmountBased,
                actOfAcceptanceOfWorkCivilVolumeOfWorkBased,
                frameContractCivil,
                orderApplicationCivilAmountBased,
                orderApplicationCivilVolumeOfWorkBased,
            } = defaultTemplatesAggregation;

            _form = {
                ..._form,
                actOfAcceptanceOfWorkCivilAmountBasedId: actOfAcceptanceOfWorkCivilAmountBased ?
                    actOfAcceptanceOfWorkCivilAmountBased.id : undefined,
                actOfAcceptanceOfWorkCivilVolumeOfWorkBasedId: actOfAcceptanceOfWorkCivilVolumeOfWorkBased ?
                    actOfAcceptanceOfWorkCivilVolumeOfWorkBased.id : undefined,
                frameContractCivilId: frameContractCivil ?
                    frameContractCivil.id : undefined,
                orderApplicationCivilAmountBasedId: orderApplicationCivilAmountBased ?
                    orderApplicationCivilAmountBased.id : undefined,
                orderApplicationCivilVolumeOfWorkBasedId: orderApplicationCivilVolumeOfWorkBased ?
                    orderApplicationCivilVolumeOfWorkBased.id : undefined,
            };
        }

        setForm(_form);
    }, [defaultTemplatesAggregation]);

    const onChange = (event, {name, value, checked}) => {
        setForm({
            ...form,
            [name]: value || checked,
        });
    };

    const _checkUnavailableTemplates = (customDocumentIds) => {
        checkUnavailableTemplates({
            clientId,
            customDocumentIds,
            onSuccess: (data) => {
                const {
                    isTemplatesDeleted,
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем для удаленных шаблонов
                if (isTemplatesDeleted || isDisabledTemplateClientSetting) {
                    onOpenUnavailableTemplates();

                    return;
                }

                params.onSubmit(form);
            },
        });
    };

    const onSubmitUnavailableTemplates = () => {
        params.onSubmit(form);
    };

    const onSubmit = () => {
        const {
            useActualTemplates,
            ...otherForm
        } = form;

        if (useActualTemplates) {
            _checkUnavailableTemplates(customTemplateIds);

            return;
        }

        const customDocumentIds = Object.keys(otherForm).map(key => form[key]);

        _checkUnavailableTemplates(customDocumentIds);
    };

    return {
        form,
        onChange,
        onSubmit,
        allOptionsInfo,
        progressReplacementsForUnavailableSelector,
        isOpenUnavailableTemplates,
        onCloseUnavailableTemplates,
        onSubmitUnavailableTemplates,
    };
};