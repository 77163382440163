import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../../components/ActualComponents/NmDropdownV2";

import {ORDER_FIELDS_NAME} from "../../../../../../../constants/clientList";
import {FOREMAN} from "../../../../../../../constants/roles";

import {clientMemberProgressGetUsersSelector} from "../../../../../../../ducks/bff/clients/employees/selectors";
import {projectGetUsersAllProgressSelector} from "../../../../../../../ducks/projects";

const OrderEditFormPreConditionsResponsible = (props) => {
    const {
        role,
        isDisabled,
        responsibleOptions,
        onChange,
        error,
        formData,
        onFocus,
    } = props;
    const progressGetUsers = useSelector(clientMemberProgressGetUsersSelector);
    const getUsersAllProgress = useSelector(projectGetUsersAllProgressSelector);
    // const order = useSelector(orderCreationDataSelector);

    return (
        <NmDropdownV2
            label="Ответственный"
            disabled={
                isDisabled ||
                progressGetUsers ||
                getUsersAllProgress ||
                role === FOREMAN
                // (
                //     [
                //         ORDER_CREATION_MODE.FROM_TEMPLATE,
                //     ].includes(creatingMode) &&
                //     Boolean(order.orderManagerId)
                // )
            }
            required
            error={error}
            name={ORDER_FIELDS_NAME.RESPONSIBLE}
            value={formData.orderManagerId}
            options={responsibleOptions}
            placeholder="Ответственный"
            onChange={onChange}
            onFocus={onFocus}
        />
    );
};

export default OrderEditFormPreConditionsResponsible;