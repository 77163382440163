import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import RejectionReason from "../../../components/RejectionReason";
import FinanceLogPopup from "../../finance-log-popup";
import FinancePatentPaymentsFilter from "./filter";

import {usePagination} from "../../../hooks/usePagination";
import {useFinancePatentPaymentsFetchList} from "./hooks/useFetchList";

import bem from "../../../utils/bem";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmount, formatAmountWithNullChecking, formatNumber} from "../../../utils/stringFormat";
import {mapFinancePatentPaymentsFilter} from "./utils/mapFinancePatentPaymentsFilter";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {FINANCE_LOG_TYPE} from "../../../constants/financeLogType";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_FINANCE_PATENT_PAYMENT_LIST,
} from "../../../constants/links";
import {
    FINANCE_PATENTS_PAYMENTS_ACTION,
    FINANCE_PATENTS_PAYMENTS_STATUS_DICT,
    FINANCE_PATENTS_PAYMENTS_STATUS_MOD,
    PATENTS_PAYMENTS_STATUS_CODE,
} from "../../../constants/patentsPayments";
import {ADMIN, isClientUser, NM_MANAGER} from "../../../constants/roles";
import {
    STATUS_OF_TRANSFER,
    STATUS_OF_TRANSFER_NEW,
} from "../../../constants/status";

import {history} from "../../../store/configureStore";

import {
    patentsPaymentsActionProgressSelector,
    patentsPaymentsListSelector,
    patentsPaymentsListTotalPagesSelector,
    patentsPaymentsProgressListSelector,
    patentsPaymentsTotalCountSelector,
} from "../../../ducks/bff/clients/patents/pay/card/selectors";
import {
    declinePatentPayment,
    refreshPatentPayment,
} from "../../../ducks/bff/patent/actionCreators";
import {downloadDocument} from "../../../ducks/documents";

const FinancePatentPayments = (props) => {
    const {
        noPadding,
        header,
        contractorId,
        isBff,
        overflowUnset,
        match: {
            params: {
                paymentNumberFilter,
            },
        },
    } = props;

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const [block] = bem("finance-patent-payments");

    const paymentsList = useSelector(patentsPaymentsListSelector);
    const progress = useSelector(patentsPaymentsProgressListSelector);
    const progressAction = useSelector(patentsPaymentsActionProgressSelector);
    const totalCount = useSelector(patentsPaymentsTotalCountSelector);
    const totalPages = useSelector(patentsPaymentsListTotalPagesSelector);

    const [isSearch, setIsSearch] = useState(false);
    const [filter, setFilter] = useState({paymentNumberFilter});
    const [action, setAction] = useState(null);
    const [currentPayment, setCurrentPayment] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [logData, setLogData] = useState({});

    const {
        fetchList,
    } = useFinancePatentPaymentsFetchList({
        pageNum,
        pageSize,
        filter,
        contractorId,
    });

    const dispatch = useDispatch();

    const sendFilter = (filter) => {
        setIsSearch(true);
        setFilter(mapFinancePatentPaymentsFilter(filter));
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        if (paymentNumberFilter) {
            history.push(LINK_FINANCE_PATENT_PAYMENT_LIST.replace(":paymentNumberFilter?", ""));
        }

        setIsSearch(false);
        setFilter({});
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const handleDownload = ({downloadLink, isDownload}) => {
        dispatch(downloadDocument({
            isDownload,
            downloadLink,
        }));
    };

    const refreshStatusPayment = ({clientId, patentRegistryId, patentPaymentId}) => {
        dispatch(refreshPatentPayment({
            clientId,
            patentRegistryId,
            patentPaymentId,
            onSuccess: fetchList,
        }));
    };

    const declinePaymentOnSubmit = (reason) => {
        const {
            clientId,
            patentRegistryId,
            patentPaymentId,
        } = currentPayment;

        dispatch(declinePatentPayment({
            clientId,
            patentRegistryId,
            patentPaymentId,
            reason,
            onSuccess: fetchList,
        }));

        setIsOpenModal(false);
        setLogData({});
    };

    const handlePopupLogOpen = (logData) => {
        setAction(FINANCE_PATENTS_PAYMENTS_ACTION.LOG_BANK_INTERACTION);
        setLogData(logData);
        setIsOpenModal(true);
    };

    const handleDeclineReasonModalOpen = (item) => {
        setAction(FINANCE_PATENTS_PAYMENTS_ACTION.CANCEL_PAYMENT);
        setCurrentPayment(item);
        setIsOpenModal(true);
    };

    const handleModalClose = () => {
        setIsOpenModal(false);
        setLogData({});
        setAction(null);
    };

    const getItemActionOptions = (item) => {
        const {
            paymentStatus,
            receiptDocumentDownloadLink,
        } = item;

        const _paymentStatus = props.contractorId ? paymentStatus.value : paymentStatus;

        const options = {
            PAYMENT_ORDER: {
                key: FINANCE_PATENTS_PAYMENTS_ACTION.PAYMENT_ORDER,
                text: "Платежное поручение",
                value: FINANCE_PATENTS_PAYMENTS_ACTION.PAYMENT_ORDER,
            },
            REFRESH_STATUS: {
                key: FINANCE_PATENTS_PAYMENTS_ACTION.REFRESH_STATUS,
                text: "Обновить статус платежа из банка",
                value: FINANCE_PATENTS_PAYMENTS_ACTION.REFRESH_STATUS,
            },
            LOG_BANK_INTERACTION: {
                key: FINANCE_PATENTS_PAYMENTS_ACTION.LOG_BANK_INTERACTION,
                text: "Лог взаимодействия с банком",
                value: FINANCE_PATENTS_PAYMENTS_ACTION.LOG_BANK_INTERACTION,
            },
            CANCEL_PAYMENT: {
                key: FINANCE_PATENTS_PAYMENTS_ACTION.CANCEL_PAYMENT,
                text: "Отклонить оплату",
                value: FINANCE_PATENTS_PAYMENTS_ACTION.CANCEL_PAYMENT,
            },
        };

        const resultOptions = [];
        const isAccessUpdateStatusBank = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.updateStatusBankPatentPayment,
        ]);

        if ([PATENTS_PAYMENTS_STATUS_CODE.DEPOSITED].includes(_paymentStatus) && receiptDocumentDownloadLink) {
            resultOptions.push(options.PAYMENT_ORDER);
        }

        if (contractorId) {
            return resultOptions;
        }

        if (
            [
                PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR,
                PATENTS_PAYMENTS_STATUS_CODE.PROCESSING,
            ].includes(_paymentStatus)
            && [ADMIN, NM_MANAGER].includes(role)
            && isAccessUpdateStatusBank
        ) {
            resultOptions.push(options.REFRESH_STATUS);
        }

        if ([ADMIN, NM_MANAGER].includes(role) && isAccessUpdateStatusBank) {
            resultOptions.push(options.LOG_BANK_INTERACTION);
        }

        if (
            [PATENTS_PAYMENTS_STATUS_CODE.PAYMENT_ERROR].includes(_paymentStatus)
            && [ADMIN].includes(role)
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentPatentCancel,
            ])
        ) {
            resultOptions.push(options.CANCEL_PAYMENT);
        }

        return resultOptions;
    };

    const onClickActionCell = (option, item) => {
        switch (option.value) {
            case FINANCE_PATENTS_PAYMENTS_ACTION.PAYMENT_ORDER:
                const {receiptDocumentDownloadLink} = item;

                handleDownload({
                    isDownload: false,
                    downloadLink: receiptDocumentDownloadLink,
                });

                return;
            case FINANCE_PATENTS_PAYMENTS_ACTION.REFRESH_STATUS:
                refreshStatusPayment(item);

                return;
            case FINANCE_PATENTS_PAYMENTS_ACTION.LOG_BANK_INTERACTION:
                const {
                    patentPaymentId,
                    clientId,
                    paymentSum,
                    contractorFio,
                } = item;

                handlePopupLogOpen({
                    patentPaymentId,
                    clientId,
                    paymentSum: formatAmount(formatNumber(paymentSum, 2)),
                    contractorFio,
                });

                return;
            case FINANCE_PATENTS_PAYMENTS_ACTION.CANCEL_PAYMENT:
                handleDeclineReasonModalOpen(item);

                return;
            // no default
        }
    };

    const renderLink = (text, link) => {
        return (
            <ExtLink to={link}>
                {text}
            </ExtLink>
        );
    };

    const renderBankStatus = ({bankStatus, statusReason}) => {
        const _bankStatus = contractorId ? bankStatus?.value : bankStatus;

        const {
            color = "",
            text = "",
        } = STATUS_OF_TRANSFER_NEW[_bankStatus] || {};

        return (
            <div className="flex flex-aligned-center">
                <div
                    style={{color}}
                    className="mr-2"
                >
                    {text}
                </div>
                {
                    ([STATUS_OF_TRANSFER.FAILED].includes(_bankStatus) && statusReason) &&
                    <HelpTooltip
                        position="bottom"
                        width={16}
                        height={16}
                        text={statusReason}
                    />
                }
            </div>
        );
    };

    const renderActionCell = (item) => {
        const options = getItemActionOptions(item);

        if (isEmpty(options)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    onClickActionCell(option, item);
                }}
            />
        );
    };

    const renderModal = () => {
        if (!isOpenModal) {
            return null;
        }

        switch (action) {
            case FINANCE_PATENTS_PAYMENTS_ACTION.LOG_BANK_INTERACTION:
                return (
                    <FinanceLogPopup
                        handleClose={handleModalClose}
                        logType={FINANCE_LOG_TYPE.PATENT_PAYMENTS_LOG}
                        logData={logData}
                    />
                );
            case FINANCE_PATENTS_PAYMENTS_ACTION.CANCEL_PAYMENT:
                return (
                    <RejectionReason
                        title="Причина отказа"
                        close={handleModalClose}
                        submit={declinePaymentOnSubmit}
                    />
                );
            // no default
        }
    };

    const renderPaymentNumber = (paymentNumber) => {
        if (!paymentNumber) {
            return null;
        }

        const link = LINK_FINANCE_PATENT_PAYMENT_LIST.replace(":paymentNumberFilter?", paymentNumber);

        return (
            <ExtLink
                filterData={{
                    paymentNumber,
                }}
                isLoadDataTarget
                historyEnabled
                to={link}
            >
                {paymentNumber}
            </ExtLink>
        );
    };

    const getRows = () => {
        return paymentsList.map(item => {
            const {
                operationDateTime,
                paymentSum,
                paymentCommission,
                clientName,
                brand,
                clientId,
                contractorFio,
                contractorId,
                patentRegistryName,
                patentRegistryId,
                paymentNumber,
                paymentStatus,
                statusReason,
                bankType,
                paymentPath,
                paymentLocation,
            } = item;

            const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId),
                contractorLink = LINK_CONTRACTOR_PERSONAL_INFO.replace(":contractorId", contractorId),
                registryLink = LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD.replace(":clientId", clientId).replace(":patentRegistryId", patentRegistryId);

            const _paymentStatus = props.contractorId ? paymentStatus.value : paymentStatus;

            return {
                ...item,
                key: item.patentPaymentId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-11"
                        secondaryHeaderStatus={
                            <NmBadge
                                noWrap
                                text={FINANCE_PATENTS_PAYMENTS_STATUS_DICT[_paymentStatus]}
                                mod={FINANCE_PATENTS_PAYMENTS_STATUS_MOD[_paymentStatus] || "gray"}
                                description={[PATENTS_PAYMENTS_STATUS_CODE.DECLINED].includes(_paymentStatus) && statusReason}
                                tooltipType="dark"
                                tooltipPosition="bottom"
                                tooltipHover
                            />
                        }
                        secondaryHeader={`Дата операции ${dateFormat(convertUtcToLocal(operationDateTime), "dd.MM.yyyy HH:mm")}`}
                        primaryHeader={renderLink(patentRegistryName, registryLink)}
                        primaryHeaderLink
                        labels={[
                            {
                                label: "Номер платежа",
                                text: props.contractorId ? renderPaymentNumber(paymentNumber) : paymentNumber,
                            },
                            props.contractorId && !isClientRole && {
                                label: "Способ оплаты",
                                text: `${bankType?.description || "-"} / ${paymentPath?.description || "-"}`,
                            },
                            props.contractorId && !isClientRole && {
                                label: "Местонахождение платежа",
                                text: paymentLocation?.description || "-",
                            },
                            {
                                label: "Заказчик",
                                text: renderLink(`${clientName} ${brand ? `(${brand})` : ""}`, clientLink),
                            },
                            !props.contractorId && {
                                label: "Исполнитель",
                                text: renderLink(contractorFio, contractorLink),
                            },
                            {
                                label: "Банковский статус",
                                text: renderBankStatus(item),
                                noWrap: false,
                            },
                            props.contractorId && {
                                label: "Название реестра оплат по патентам",
                                text: renderLink(
                                    item.patentRegistryName,
                                    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD.replace(":clientId", clientId).replace(":patentRegistryId", patentRegistryId),
                                ),
                            },
                        ]}
                        cards={[
                            {
                                title: "Сумма операции, ₽",
                                value: formatAmountWithNullChecking(paymentSum),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                            {
                                title: "Комиссия заказчика, ₽ ",
                                value: formatAmountWithNullChecking(paymentCommission),
                                className: "col-16 col-md-4 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        actions={renderActionCell(item)}
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            noPadding={noPadding}
            className={block()}
            overflowUnset={overflowUnset}
            header={
                header ?
                    header :
                    <NmTitle
                        count={totalCount}
                    >
                        Оплаты патентов
                    </NmTitle>
            }
            typeFilter="vertical"
            filtersBase={
                <FinancePatentPaymentsFilter
                    isBff={isBff}
                    sendFilter={sendFilter}
                    clearFilter={clearFilter}
                    initPaymentNumberFilter={paymentNumberFilter}
                    contractorId={contractorId}
                />
            }
            isLoaded={progress || progressAction}
            currentPageNum={pageNum}
            currentPageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
        >
            {renderModal()}
            {
                !(totalCount || progress) ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList rows={getRows()} />
            }
        </NmPage>
    );
};

export default FinancePatentPayments;