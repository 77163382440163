import React, {useEffect, useMemo} from "react";
import {isEmpty} from "lodash";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmPagination from "../../../../../components/ActualComponents/NmPagination";
import Tabs from "../../../../../components/ActualComponents/Tabs";
import CheckboxList from "../../../../../components/CheckboxList";
import OrderHistoryFilter from "../filter";

import {useOrderHistoryPage} from "../../hooks/useOrderHistoryPage";

import formatDate, {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {phoneFormat, removePhoneMask} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {EVENT_TYPE} from "../../../../../constants/status";

const ContractorOrderHistory = (props) => {
    const {
        tabs,
        orderId,
        clientId,
        events,
        contractorStatusDict,
        totalCount,
        totalPages,
        onClearStore,
        onFetchList,
    } = props;

    const filteredEventsList = useMemo(
        () => {
            return events.filter(({eventType}) => {
                return !["ClientEvaluationRequired", "ClientEvaluationDone"].includes(eventType);
            });
        },
        [events],
    );

    const {
        filter,
        filterData,
        pageNum,
        pageSize,
        onSearch,
        onClear,
        onChangeFilter,
        onChangePageSize,
        onPaginationChange,
    } = useOrderHistoryPage();

    useEffect(() => {
        fetchHistory();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    useEffect(() => {
        return () => {
            onClearStore();
        };
    }, []);

    const fetchHistory = () => {
        const {
            contractorFioFilter,
            contractorPhoneFilter,
            logEndDateFilter,
            logStartDateFilter,
        } = filterData;
        const dateFormat = "yyyy-MM-dd";

        const requestData = {
            pageSize,
            pageNum,
            orderId,
            logStartDateFilter: isNullOrWhitespace(logStartDateFilter) ? undefined : formatDate(logStartDateFilter, dateFormat),
            logEndDateFilter: isNullOrWhitespace(logEndDateFilter) ? undefined : formatDate(logEndDateFilter, dateFormat),
            contractorFioFilter: isEmpty(contractorFioFilter) ? undefined : contractorFioFilter,
            contractorPhoneFilter: isEmpty(contractorPhoneFilter) ? undefined : removePhoneMask(contractorPhoneFilter),
        };

        onFetchList({
            ...requestData,
            clientId,
        });
    };

    const getCards = () => {
        return filteredEventsList.map(item => {
            const title = `${EVENT_TYPE[item.eventType]} ${contractorStatusDict[item.status] ? `- ${contractorStatusDict[item.status]}` : ""}`;
            const labels = [
                {
                    label: "Дата",
                    text: formatDate(convertUtcToLocal(item.dateTime), "dd.MM.yyyy HH:mm:ss"),
                    noWrap: false,
                    columnOnMobile: true,
                },
                {
                    label: "Самозанятый",
                    text: item.contractorFio,
                    noWrap: false,
                    columnOnMobile: true,
                },
                {
                    label: "Телефон",
                    text: phoneFormat(item.contractorPhone),
                    noWrap: false,
                    columnOnMobile: true,
                },
                {
                    label: "Инициатор",
                    text: item.foremanFio ? item.foremanFio : item.contractorFio,
                    noWrap: false,
                    columnOnMobile: true,
                },
            ];

            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={title}
                        labels={labels}
                    />
                ),
            };
        });
    };

    const getContent = () => {
        if (isEmpty(filteredEventsList)) {
            return <NmEmptyPageV2 title="По заданным фильтрам записей не найдено" />;
        }

        return <CheckboxList rows={getCards()} />;
    };

    return (
        <NmForm addMargin={true}>
            {
                tabs &&
                <Tabs
                    panes={tabs}
                    className="mb-4 mb-md-5"
                />
            }
            <OrderHistoryFilter
                filter={filter}
                onChangeFilter={onChangeFilter}
                onSearch={onSearch}
                onClear={onClear}
            />
            {getContent()}
            <NmPagination
                className="mt-2 mt-md-6 mt-xxl-8"
                totalCount={totalCount}
                pageNum={pageNum}
                totalPages={totalPages}
                pageSize={pageSize}
                onChangePagination={onPaginationChange}
                onChangePageSize={onChangePageSize}
            />
        </NmForm>
    );
};

export default ContractorOrderHistory;
