import {useState} from "react";

const initState = {
    isOpenFilter: false,
};

export const useAppContextFilter = () => {
    const [filterData, setFilterData] = useState(initState);

    const clearData = () => {
        setFilterData(initState);
    };

    const setOpenState = (isOpenFilter) => {
        setFilterData(prevState => {
            return {
                ...prevState,
                isOpenFilter,
            };
        });
    };

    return {
        filterData,
        clearData,
        setOpenState,
    };
};