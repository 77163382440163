import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const actRegistriesSelector = state => state.bff.orderActRegistries;
export const actRegistriesListSelector = createSelector(
    actRegistriesSelector,
    ({list}) => list,
);
export const actRegistriesProgressListSelector = createSelector(
    actRegistriesSelector,
    ({progress}) => progress,
);
export const actRegistriesTotalCountSelector = createSelector(
    actRegistriesSelector,
    ({totalCount}) => totalCount,
);
export const actRegistriesTotalPagesSelector = createSelector(
    actRegistriesSelector,
    ({
        totalCount,
        pageData: {pageSize = 0},
    }) => getTotalPages(totalCount, pageSize),
);
export const actRegistriesProgressActionSelector = createSelector(
    actRegistriesSelector,
    ({progressAction}) => progressAction,
);

export const actRegistriesCardSelector = createSelector(
    actRegistriesSelector,
    ({card}) => card,
);
export const actRegistriesProgressCardSelector = createSelector(
    actRegistriesSelector,
    ({progressCard}) => progressCard,
);