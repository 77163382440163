import {useState} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";

import validationSchema from "../validation";

import {convertStringToDate} from "../../../../../utils/stringHelper";

import {CONTRACTOR_FIELD} from "../../../../../constants/contractor";

import {updateContractor} from "../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";

const useDataForm = (card) => {
    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);

    const initialValues = {
        [CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]: card[CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE] || "",
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER] || "",
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER] || "",
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]) || null,
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY] || "",
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]) || null,
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]) || null,
        [CONTRACTOR_FIELD.ADDRESS]: card[CONTRACTOR_FIELD.ADDRESS] || "",
        [CONTRACTOR_FIELD.REGISTRATION_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.REGISTRATION_DATE]) || null,
        [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]) || null,
        [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS]: card[CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS] || "",
        [CONTRACTOR_FIELD.BIRTH_DATE]: convertStringToDate(card[CONTRACTOR_FIELD.BIRTH_DATE]) || null,
    };

    const {
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        setValues,
        errors,
        touched,
        isValid,
        validateForm,
    } = useFormik({
        initialValues: initialValues,
        onSubmit: ((values, {setSubmitting}) => {
            const requestData = {
                ...card,
                ...values,
            };

            dispatch(updateContractor(requestData));

            setSubmitting(false);
        }),
        enableReinitialize: true,
        validationSchema: validationSchema(),
        validateOnBlur: false,
        initialTouched: {
            [CONTRACTOR_FIELD.ARRIVAL_DOC_TYPE]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_NUMBER]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_NUMBER]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_DECISION_DATE]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_BY]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ISSUED_DATE]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_VALID_TO_DATE]: true,
            [CONTRACTOR_FIELD.ADDRESS]: true,
            [CONTRACTOR_FIELD.REGISTRATION_DATE]: true,
            [CONTRACTOR_FIELD.ARRIVAL_NOTICE_STAY_END_DATE]: true,
            [CONTRACTOR_FIELD.RESIDENCE_PERMIT_ADDRESS]: true,
            [CONTRACTOR_FIELD.BIRTH_DATE]: true,
        },
    });

    const cancelEdit = () => {
        setIsEdit(false);
        setValues(initialValues);
    };

    const toggleCard = () => {
        validateForm().then(() => {
            if (!isValid && isEdit) {
                return;
            }

            if (isEdit) {
                handleSubmit();
            }

            setIsEdit(prevState => !prevState);
        });
    };

    return {
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        isEdit,
        cancelEdit,
        toggleCard,
    };

};

export default useDataForm;