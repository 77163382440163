import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

const contractHistorySelector = state => state.bff.clientDocumentsContractAgency;
export const getContractHistoryListSelector = createSelector(contractHistorySelector, ({historyList}) => historyList);
export const getActualVersionSelector = createSelector(contractHistorySelector, ({actualVersion}) => actualVersion);
export const getPageDataSelector = createSelector(contractHistorySelector, ({pageData}) => pageData);
export const getAddProgressSelector = createSelector(contractHistorySelector, ({addProgress}) => addProgress);
export const getUpdateProgressSelector = createSelector(contractHistorySelector, ({updateProgress}) => updateProgress);
export const getTotalPagesHistorySelector = createSelector(contractHistorySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const getTotalCountHistorySelector = createSelector(contractHistorySelector, ({totalCount}) => totalCount);
export const getActualFileNamesSelector = createSelector(contractHistorySelector, ({actualVersion: {fileNames}}) => fileNames);
export const terminateContractInfoSelector = createSelector(contractHistorySelector, ({terminateContractList}) => terminateContractList);