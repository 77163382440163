import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {getFullName} from "../../../../utils/stringFormat";

import {CONTRACTOR} from "../../../../constants/roles";

export const bffContractorsRegistrySelector = state => state.bff.contractorsRegistry;
export const bffContractorsRegistryListSelector  = createSelector(
    bffContractorsRegistrySelector,
    ({list}) => list,
);
export const bffContractorsRegistryTotalCountSelector  = createSelector(
    bffContractorsRegistrySelector,
    ({totalCount}) => totalCount,
);
export const bffContractorsRegistryTotalPagesSelector  = createSelector(
    bffContractorsRegistrySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffContractorsRegistryProgressSelector  = createSelector(
    bffContractorsRegistrySelector,
    ({progress}) => progress,
);
export const bffContractorsRegistryActionSelector  = createSelector(
    bffContractorsRegistrySelector,
    ({progressAction}) => progressAction,
);
export const bffContractorsShortModelListSelector = createSelector(
    bffContractorsRegistrySelector,
    ({shortPage: {list}}) => list,
);
export const bffContractorsShortModelListTotalCountSelector = createSelector(
    bffContractorsRegistrySelector,
    ({shortPage: {totalCount}}) => totalCount,
);
export const bffContractorsShortModelListProgressSelector = createSelector(
    bffContractorsRegistrySelector,
    ({shortPage: {progress}}) => progress,
);
export const bffContractorsShortModelListPageDataSelector = createSelector(
    bffContractorsRegistrySelector,
    ({shortPage: {pageData}}) => pageData,
);
export const bffContractorCardSelector = createSelector(
    bffContractorsRegistrySelector,
    ({card}) => card,
);
export const bffContractorCardProgressSelector = createSelector(
    bffContractorsRegistrySelector,
    ({progressCard}) => progressCard,
);
export const bffContractorListWithFullNameSelector = createSelector(bffContractorsRegistrySelector, ({contractorList}) => {
    return contractorList.map(item => ({
        ...item,
        fullName: getFullName(item.lastName, item.firstName, item.patronymic),
    }));
});
export const bffContractorListHistoryPaySelector = createSelector(bffContractorsRegistrySelector, ({contractorList}) => {
    return contractorList.map(value => ({...value, clientUserId: value.contractorId, role: CONTRACTOR}));
});