import {createSelector} from "reselect";

import {getApplicationCount} from "../../../../../utils/contractors";
import {getWorkAddressInfoArr} from "../../../../../utils/dadata";
import {getTotalPages} from "../../../../../utils/mathHelper";

export const orderAnalyticsAllApplicationsSelector = state => state.bff.orderAnalyticsAllApplications;
export const allApplicationsCountSelector = createSelector(orderAnalyticsAllApplicationsSelector, ({applicationCountMap}) => getApplicationCount(applicationCountMap));
export const contractorsAllApplicationCountMap = createSelector(orderAnalyticsAllApplicationsSelector, ({applicationCountMap}) => applicationCountMap);
export const contractorsAllApplicationsProgressSelector = createSelector(orderAnalyticsAllApplicationsSelector, ({progress}) => progress);
export const contractorsAllApplicationsSelector = createSelector(orderAnalyticsAllApplicationsSelector, ({list}) => {
    return list.map(item => {
        const {
            workAddressInfo,
            departureAddressesInfo,
        } = item;

        return {
            ...item,
            workAddressInfo: getWorkAddressInfoArr(workAddressInfo, departureAddressesInfo),
        };
    });
});
export const contractorsAllApplicationsTotalCountSelector = createSelector(orderAnalyticsAllApplicationsSelector, ({totalCount}) => totalCount);
export const contractorsAllApplicationsTotalPagesSelector = createSelector(orderAnalyticsAllApplicationsSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));