import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositChangeModal from "../../../../components/DepositChangeModal";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {changeObjectOrdersLimit} from "../../../../ducks/bff/clients/objects/actionCreators";
import {clientObjectsProgressActionSelector} from "../../../../ducks/bff/clients/objects/selectors";

const ChangeObjectOrdersLimit = (props) => {
    const {
        name,
        onClose,
        clientId,
        projectId,
        objectId,
    } = props;
    const dispatch = useDispatch();
    const progress = useSelector(clientObjectsProgressActionSelector);
    const clientProperties = useSelector(clientCardPropertiesSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
            type: paymentType,
        } = requestData;

        dispatch(changeObjectOrdersLimit({
            ordersLimit: amount,
            clientId,
            projectId,
            objectId,
            paymentType,
            onSuccess: () => {
                onClose();
            },
        }));
    };

    return (
        <DepositChangeModal
            onClose={onClose}
            targetText="объекта"
            targetName={name}
            onSubmit={onSubmit}
            progress={progress}
            isHideConfirm={true}
            header={`Изменение лимита заказов на объекте "${name}"`}
            amountLabel="Сумма лимита заказов на объекте"
            text="Изменить лимит заказов на проекте по счету"
            maxValueNpd={clientProperties.ordersLimit}
            maxValueNdfl={clientProperties.civilOrdersLimit}
            minValue={0}
        />
    );
};

export default ChangeObjectOrdersLimit;