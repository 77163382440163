import {
    CLIENT_UPDATE_NM_CONTRACT_ERROR,
    CLIENT_UPDATE_NM_CONTRACT_REQUEST,
    CLIENT_UPDATE_NM_CONTRACT_SUCCESS,
    CONTRACT_HISTORY_ADD_ERROR,
    CONTRACT_HISTORY_ADD_REQUEST,
    CONTRACT_HISTORY_ADD_SUCCESS,
    CONTRACT_HISTORY_CLEAR_FIELDS,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS,
    CONTRACT_HISTORY_GET_LIST_ERROR,
    CONTRACT_HISTORY_GET_LIST_REQUEST,
    CONTRACT_HISTORY_GET_LIST_SUCCESS,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
    GET_TERMINATE_CONTRACT_INFO_SUCCESS,
} from "./actions";

const initial = {
    actualVersion: {},
    pageData: {},
    historyList: [],
    totalCount: 0,
    error: null,
    requestData: {},
    addProgress: false,
    actualFileNames: [],
    progressUpdate: false,
    terminateContractList: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST:
        case CLIENT_UPDATE_NM_CONTRACT_REQUEST:
            return {
                ...state,
                progressUpdate: true,
            };
        case CONTRACT_HISTORY_GET_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
            };
        case CONTRACT_HISTORY_ADD_REQUEST:
            return {
                ...state,
                addProgress: true,
            };
        case CONTRACT_HISTORY_CLEAR_FIELDS:
            return {
                ...state,
                ...initial,
            };
        case CONTRACT_HISTORY_ADD_SUCCESS:
            return {
                ...state,
                addProgress: false,
            };
        case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS:
        case CLIENT_UPDATE_NM_CONTRACT_SUCCESS:
            return {
                ...state,
                progressUpdate: false,
            };
        case CONTRACT_HISTORY_ADD_ERROR:
            return {
                ...state,
                addProgress: false,
            };
        case CONTRACT_HISTORY_GET_LIST_SUCCESS:
            const {
                contractHistoryEntityList,
                totalCount,
            } = payload;

            return {
                ...state,
                historyList: contractHistoryEntityList,
                totalCount,
            };
        case CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST:
            return {
                ...state,
                requestData: payload,
            };
        case CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS:
            return {
                ...state,
                actualVersion: payload,
            };
        case CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR:
        case CLIENT_UPDATE_NM_CONTRACT_ERROR:
            return {
                ...state,
                progressUpdate: false,
                error: payload,
            };
        case GET_TERMINATE_CONTRACT_INFO_SUCCESS:
            return {
                ...state,
                terminateContractList: payload,
            };
        case CONTRACT_HISTORY_GET_LIST_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};