import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientCardInfo} from "../../info/actionCreators";
import {getActualVersion, getHistoryList} from "./actionCreators";
import {
    CLIENT_UPDATE_NM_CONTRACT_ERROR,
    CLIENT_UPDATE_NM_CONTRACT_REQUEST,
    CLIENT_UPDATE_NM_CONTRACT_SUCCESS,
    CONTRACT_HISTORY_ADD_ERROR,
    CONTRACT_HISTORY_ADD_REQUEST,
    CONTRACT_HISTORY_ADD_SUCCESS,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS,
    CONTRACT_HISTORY_GET_FILE_ERROR,
    CONTRACT_HISTORY_GET_FILE_REQUEST,
    CONTRACT_HISTORY_GET_FILE_SUCCESS,
    CONTRACT_HISTORY_GET_LIST_ERROR,
    CONTRACT_HISTORY_GET_LIST_REQUEST,
    CONTRACT_HISTORY_GET_LIST_SUCCESS,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
    GET_TERMINATE_CONTRACT_INFO_ERROR,
    GET_TERMINATE_CONTRACT_INFO_REQUEST,
    GET_TERMINATE_CONTRACT_INFO_SUCCESS,
} from "./actions";
import {getActualVersionSelector, getPageDataSelector} from "./selectors";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";


const getController = () => {
    return (
        getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/documents/contract-agency",
            client: "/client-adm/documents/contract-agency",
        })
    );
};

const addActualVersionSaga = function* ({payload}) {
    try {
        const {
            params,
            data,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add?clientId=${params.clientId}`, data, {
            params,
            ...getMultipartHeaders(),
        },
        );
        const {data: responseData = {}} = result;

        const {errorMessage} = responseData;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Файлы успешно добавлены");

        const state = yield select();

        const {clientId} = params;

        yield put(getActualVersion({clientId}));
        yield put(getHistoryList(getPageDataSelector(state)));

        yield put({
            type: CONTRACT_HISTORY_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CONTRACT_HISTORY_ADD_ERROR,
            payload: error,
        });
    }
};

const getHistoryListSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/getHistoryRichPage`, data);
        const {errorMessage} = result;

        if (handleResponse) {
            handleResponse();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_GET_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACT_HISTORY_GET_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACT_HISTORY_GET_LIST_ERROR,
            payload: error,
        });
    }
};

const getAgencyContractActualSaga = function* ({payload}) {
    try {
        const {clientId} = payload;

        const result = yield request.bff.get(`${getController()}/getActual?clientId=${clientId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS,
            payload: result === null ? {} : result,
        });
    } catch (error) {
        yield put({
            type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR,
            payload: error,
        });
    }
};

const getContractHistoryFileSaga = function* ({payload}) {
    try {
        const {
            fileName,
            contractHistoryId,
            isDownload,
        } = payload;

        const myInit = {
            method: "POST",
            body: JSON.stringify({contractHistoryId, fileName}),
        };

        const response = yield request.bff.getFile(`${getController()}/getFile`, myInit);

        const blob = yield response.blob();

        const url = URL.createObjectURL(blob);
        const tempLink = document.createElement("a");

        tempLink.setAttribute("href", url);

        if (isDownload) {
            tempLink.setAttribute("download", fileName);
        } else {
            tempLink.setAttribute("target", "_blank");
        }

        document.body.appendChild(tempLink);

        tempLink.click();
        tempLink.remove();

        yield put({type: CONTRACT_HISTORY_GET_FILE_SUCCESS});
    } catch (error) {
        yield put({
            type: CONTRACT_HISTORY_GET_FILE_ERROR,
            payload: error,
        });
    }
};

const updateActualVersionSaga = function* ({payload}) {
    try {
        const {
            params,
            data,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, data, {
            params,
            ...getMultipartHeaders(),
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Файлы успешно обновлены");

        const state = yield select();

        yield put(getActualVersion(getActualVersionSelector(state)));

        yield put({
            type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR,
            payload: error,
        });
    }
};

const getTerminateContractInfoSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.get(`${getController()}/${payload}/terminateContractInfo`);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_TERMINATE_CONTRACT_INFO_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_TERMINATE_CONTRACT_INFO_SUCCESS,
            payload: result.results,
        });
    } catch (error) {
        yield put({
            type: GET_TERMINATE_CONTRACT_INFO_ERROR,
            payload: error,
        });
    }
};

const updateNmContractSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/updateNmContract`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_UPDATE_NM_CONTRACT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess("Информация успешно отредактирована");

        yield put({
            type: CLIENT_UPDATE_NM_CONTRACT_SUCCESS,
            payload: result,
        });

        const {clientId} = payload;

        yield put(getClientCardInfo({clientId}));
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLIENT_UPDATE_NM_CONTRACT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CONTRACT_HISTORY_ADD_REQUEST, addActualVersionSaga),
        takeEvery(CONTRACT_HISTORY_GET_LIST_REQUEST, getHistoryListSaga),
        takeEvery(CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST, getAgencyContractActualSaga),
        takeEvery(CONTRACT_HISTORY_GET_FILE_REQUEST, getContractHistoryFileSaga),
        takeEvery(CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST, updateActualVersionSaga),
        takeEvery(GET_TERMINATE_CONTRACT_INFO_REQUEST, getTerminateContractInfoSaga),
        takeEvery(CLIENT_UPDATE_NM_CONTRACT_REQUEST, updateNmContractSaga),
    ]);
}