import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_SUCCESS,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_ERROR,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_REQUEST,
    SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_SUCCESS,
} from "./actions";

import request from "../../../../../../utils/postman";
import {toastError} from "../../../../../../utils/toastHelper";

const controller = "/adm/contractors/client-remote/client-group-contractor-search";

const getPageSettingsClientGroupsCardSearchContractors = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/page`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addSettingsClientGroupsCardSearchContractors = function* ({payload}) {
    try {
        const {
            onSuccess,
            groupId,
            contractorId,
        } = payload;

        const result = yield request.bff.post(`${controller}/add?contractorId=${contractorId}&groupId=${groupId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_GET_PAGE_REQUEST, getPageSettingsClientGroupsCardSearchContractors),
        takeEvery(SETTINGS_CLIENT_GROUP_CARD_SEARCH_CONTRACTORS_ADD_REQUEST, addSettingsClientGroupsCardSearchContractors),
    ]);
}