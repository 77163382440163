import {BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST, BFF_BANK_W1_UPDATE_302_REQUEST} from "./actions";

export const getPageBankW1History = (payload) => {
    return {
        type: BFF_BANK_W1_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
};

export const update302FromBankW1 = (payload) => {
    return {
        type: BFF_BANK_W1_UPDATE_302_REQUEST,
        payload,
    };
};