export const BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST = "BFF_ORDERS_GET_ORDER_GROUP_PAGE_REQUEST";
export const BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS = "BFF_ORDERS_GET_ORDER_GROUP_PAGE_SUCCESS";
export const BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR = "BFF_ORDERS_GET_ORDER_GROUP_PAGE_ERROR";

export const BFF_ORDERS_ADD_ORDER_GROUP_REQUEST = "BFF_ORDERS_ADD_ORDER_GROUP_REQUEST";
export const BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS = "BFF_ORDERS_ADD_ORDER_GROUP_SUCCESS";
export const BFF_ORDERS_ADD_ORDER_GROUP_ERROR = "BFF_ORDERS_ADD_ORDER_GROUP_ERROR";

export const BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST = "BFF_ORDERS_DELETE_ORDER_GROUP_REQUEST";
export const BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS = "BFF_ORDERS_DELETE_ORDER_GROUP_SUCCESS";
export const BFF_ORDERS_DELETE_ORDER_GROUP_ERROR = "BFF_ORDERS_DELETE_ORDER_GROUP_ERROR";

export const BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST = "BFF_ORDERS_UPDATE_ORDER_GROUP_REQUEST";
export const BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS = "BFF_ORDERS_UPDATE_ORDER_GROUP_SUCCESS";
export const BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR = "BFF_ORDERS_UPDATE_ORDER_GROUP_ERROR";

export const BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST = "BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_REQUEST";
export const BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS = "BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_SUCCESS";
export const BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR = "BFF_ORDERS_ARCHIVE_OR_RESTORE_ORDER_GROUP_ERROR";