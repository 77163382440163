import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmDropdownV2 from "../NmDropdownV2";

import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {NM_ADMIN_INFO} from "../../../constants/contractorInfo";
import {NM_OPERATOR} from "../../../constants/roles";

import {getClientMemberList} from "../../../ducks/bff/clients/employees/actionCreators";
import {clientMemberOptionsSelector} from "../../../ducks/bff/clients/employees/selectors";

interface IFilterClientUser {
    value: [],
    name?: string,
    onChange: (event: any, params: any) => void,
    className?: string,
    isMountLoad?: boolean,
    onSubmit?: () => void,
    label?: string | object,
    roleListFilter?: Array<string>,
    clientId?: string,
    size?: "xl" | "lg",
    pageSize?: number
}

const FilterClientUser = (props: IFilterClientUser) => {
    const {
        label = "Оператор",
        roleListFilter = [NM_OPERATOR],
        clientId = NM_ADMIN_INFO.ID,
        size = "lg",
        pageSize = 1000,
        ...otherProps
    } = props;
    const dispatch = useDispatch();

    const options = useSelector(clientMemberOptionsSelector);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = (nameSubstringFilter?: string) => {
        dispatch(getClientMemberList({
            clientId: NM_ADMIN_INFO.ID,
            archiveFilter: false,
            roleListFilter,
            pageNum: 1,
            pageSize,
            nameSubstringFilter: isNullOrWhitespace(nameSubstringFilter) ? undefined : nameSubstringFilter?.trim(),
        }));
    };

    const onSearchChange = (valueFilter: string) => {
        fetch(valueFilter);
    };

    return (
        <NmDropdownV2
            {...otherProps}
            label={label}
            onSearchChange={onSearchChange}
            search
            options={options}
        />
    );
};

export default FilterClientUser;