import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getPatentsPaymentsContractorPage} from "../../../../ducks/bff/clients/patents/pay/card/actionCreators";
import {
    updateStorePatent,
} from "../../../../ducks/bff/patent/actionCreators";

export function useFinancePatentPaymentsFetchList(params) {
    const {
        pageSize,
        pageNum,
        filter,
        contractorId,
    } = params;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [filter, pageSize, pageNum]);

    const fetchList = () => {
        dispatch(getPatentsPaymentsContractorPage({
            contractorId,
            ...filter,
            bankStatusFilter: filter.bankStatusFilter === "ALL" ? undefined : filter.bankStatusFilter,
            pageNum,
            pageSize,
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(updateStorePatent({
                list: [],
                totalCount: 0,
                pageData: {},
            }));
        };
    }, []);

    return {
        fetchList,
    };
}