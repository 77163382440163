import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_ERROR,
    NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_REQUEST,
    NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../../../utils/downloadBlob";
import request, {isResponseSuccessOfJsonType} from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return getBffUrl(
        {
            isClientCard: true,
            clientRolesUrl: "/client-adm/finances/ndfl-deposit",
            adminRolesUrl: "/adm/clients/client-card/finances/ndfl-deposit",
        },
    );
};

// GET /bff/adm/clients/client-card/finances/ndfl-deposit/invoice-of-deposit-replenishment-ndfl
// GET /bff/client-adm/finances/ndfl-deposit/invoice-of-deposit-replenishment-ndfl
export const generateInvoiceOfDepositReplenishmentByNdflSaga = function* ({payload}) {
    const {
        onError,
        name,
        onSuccess,
        ...reqData
    } = payload;

    const controller = getController();

    try {
        const settings = {
            method: "post",
            body: JSON.stringify(reqData),
        };

        const result = yield request.getFile(
            `${new URL(window.location.href).origin}/bff${controller}/invoice-of-deposit-replenishment-ndfl`,
            settings,
        );

        if (isResponseSuccessOfJsonType(result)) {
            const data = yield result.json();

            onError();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage || data);
                return {
                    done: true,
                };
            }
        }

        onSuccess();

        const fileName = `Счет-основание на пополнение депозита для выплат по НДФЛ_${name}_${result.headers.get("Content-Disposition").split("filename=")[1]}`;
        const blob = yield result.blob();

        downloadBlob(blob, result.headers, fileName);

        yield put({
            type: NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        onError();

        yield put({
            type: NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(NDFL_DEPOSIT_INVOICE_OF_DEPOSIT_REPLENISHMENT_REQUEST, generateInvoiceOfDepositReplenishmentByNdflSaga),
    ]);
}