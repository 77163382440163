import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../../components/CheckboxList";
import NmButton from "../../../../../components/NmButton";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import {ReactComponent as ResetIcon} from "../../../../../images/reset_24.svg";

import {usePagination} from "../../../../../hooks/usePagination";
import {useSettingsDirectoriesBanksSbpAction} from "./hooks/useAction";
import {useSettingsDirectoriesBanksSbpFetch} from "./hooks/useFetch";
import {useSettingsDirectoriesBanksSbpFilter} from "./hooks/useFilter";

import {getUserRole} from "../../../../../utils/access";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {ADMIN} from "../../../../../constants/roles";

import {
    settingsDirectoriesBanksProgressActionSelector,
    settingsDirectoriesBanksProgressSelector,
    settingsDirectoriesSbpBanksListSelector,
    settingsDirectoriesSbpBanksTotalCountSelector,
    settingsDirectoriesSbpBanksTotalPagesSelector,
} from "../../../../../ducks/bff/settings/directories/naimix/banks/selectors";

export const SettingsDirectoriesBanksSbp = () => {
    const list = useSelector(settingsDirectoriesSbpBanksListSelector);
    const totalPages = useSelector(settingsDirectoriesSbpBanksTotalPagesSelector);
    const totalCount = useSelector(settingsDirectoriesSbpBanksTotalCountSelector);
    const progress = useSelector(settingsDirectoriesBanksProgressSelector);
    const progressAction = useSelector(settingsDirectoriesBanksProgressActionSelector);

    const role = getUserRole();
    const isAccessEdit =  [ADMIN].includes(role)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.updateSettingDirectoryBankSbp,
        ]);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
        filters,
    } = useSettingsDirectoriesBanksSbpFilter({
        pageSize,
        setPagination,
    });

    useSettingsDirectoriesBanksSbpFetch({
        pageNum,
        pageSize,
        filter: filterData,
    });

    const {refreshSbpBanks} = useSettingsDirectoriesBanksSbpAction();

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                id,
                displayName,
                name,
                bik,
            } = item;

            return {
                ...item,
                key: id,
                contentRow: (
                    <NmListCard
                        primaryHeader={`${displayName} (${name})`}
                        noDivider
                        classNameMainContent="col-16"
                        labels={[
                            {
                                label: "Идентификатор",
                                text: id || "-",
                                columnOnMobile: true,
                            },
                            {
                                label: "БИК",
                                text: bik || "-",
                                columnOnMobile: true,
                            },
                        ]}
                    />
                ),
            };
        });
    }, [list]);

    return (
        <NmPage
            overflowUnset={true}
            header={
                <NmPageHeader
                    text="Банки для платежей по СБП"
                />
            }
            controls={
                isAccessEdit &&
                <NmButton
                    icon={<ResetIcon />}
                    size="xl"
                    onClick={refreshSbpBanks}
                    disabled={progressAction}
                    color="grey"
                >
                    Обновить справочник
                </NmButton>
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={filter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            noPadding={true}
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
        >
            {
                !isEmpty(list) ?
                    <CheckboxList rows={rows} /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};