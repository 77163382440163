import {
    ADD_NOTE_CONTRACTOR_CARD_REQUEST,
    ARCHIVE_CONTRACTOR_CARD_REQUEST,
    CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    CONTRACTOR_CARD_UPDATE_STORE,
    EMAIL_COMPLAINT_REQUEST,
    GET_INFO_CONTRACTOR_CARD_REQUEST,
    GET_NOTE_CONTRACTOR_CARD_REQUEST,
    GET_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    GET_RATING_CONTRACTOR_CARD_REQUEST,
    REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST,
    UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST,
    UPDATE_CONTRACTOR_BLOCKED_REQUEST,
    UPDATE_NOTE_CONTRACTOR_CARD_REQUEST,
    UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST,
} from "./actions";

export const updateProjectParamsContractorCard = (payload) => {
    return {
        type: UPDATE_PROJECT_PARAMS_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const archiveContractorCard = (payload) => {
    return {
        type: ARCHIVE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const addNoteContractorCard = (payload) => {
    return {
        type: ADD_NOTE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const updateBankRequisitesRequiredContractorCard = (payload) => {
    return {
        type: UPDATE_BANK_REQUISITES_REQUIRED_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const updateNoteContractorCard = (payload) => {
    return {
        type: UPDATE_NOTE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const getRatingContractorCard = (payload) => {
    return {
        type: GET_RATING_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const getNoteContractorCard = (payload) => {
    return {
        type: GET_NOTE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const getInfoContractorCard = (payload) => {
    return {
        type: GET_INFO_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const getPromocodeByContractorId = (payload) => {
    return {
        type: GET_PROMOCODE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const checkPromocodeContractorCard = (payload) => {
    return {
        type: CHECK_PROMOCODE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export const registrationPromocodeContractorCard = (payload) => {
    return {
        type: REGISTRATION_PROMOCODE_CONTRACTOR_CARD_REQUEST,
        payload,
    };
};

export function updateContractorBlocked(payload) {
    return {
        type: UPDATE_CONTRACTOR_BLOCKED_REQUEST,
        payload,
    };
}

export const complaintByEmail = payload => {
    return {
        type: EMAIL_COMPLAINT_REQUEST,
        payload,
    };
};

export const updateContractorCardStore = (payload) => {
    return {
        type: CONTRACTOR_CARD_UPDATE_STORE,
        payload,
    };
};