import {getDateWithoutTime} from "../../../utils/dateFormat";
import {validateDescriptionParams} from "../utils/getValidationRule";

import {descriptionPaymentReg, requiredMessage} from "../../../constants/validationRules";

export const ACT_CREATION_DESCRIPTION_PARAMS_VALIDATION = {
    required: requiredMessage,
    func: validateDescriptionParams,
    secondArg: "descriptionParamsCount",
};

export const ACT_CREATION_REVIEW_VALIDATION = {
    required: "При отправке негативной оценки (три звезды и меньше) обязательно нужно написать текст отзыва",
};

export const ACT_CREATE_VOLUME_BASED_VALIDATION_RULE = {
    description: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
    },
    descriptionFns: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 160,
            message: "Максимальная длина - 160 символов",
        },
        match: {
            value: descriptionPaymentReg,
            message: "Неверный формат описания выполненных работ для чека ФНС России. Для ввода доступны следующие символы: кириллица, латиница, цифры, пробелы, знаки . , : ; - \" ' * № ! ? ( ) { } % < > + [ ] « » |",
        },
    },
    volumeOfWork: {
        required: requiredMessage,
        notZero: requiredMessage,
    },
    actDate: {
        func: checkActDate,
        secondArg: "workStartDate",
    },
    estimatedTimeScore: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: requiredMessage,
        },
    },
    workStartDate: {
        required: requiredMessage,
    },
    workEndDate: {
        required: requiredMessage,
    },
    workQualityScore: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: requiredMessage,
        },
    },
    paymentMethod: {
        required: requiredMessage,
    },
};

export const ACT_CREATE_AMOUNT_BASED_VALIDATION_RULE = {
    description: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
    },
    descriptionFns: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 160,
            message: "Максимальная длина - 160 символов",
        },
        match: {
            value: descriptionPaymentReg,
            message: "Неверный формат описания выполненных работ для чека ФНС России. Для ввода доступны следующие символы: кириллица, латиница, цифры, пробелы, знаки . , : ; - \" ' * № ! ? ( ) { } % < > + [ ] « » |",
        },
    },
    sumOfMoney: {
        required: requiredMessage,
        minValue: {
            value: 9,
            message: "Сумма выплаты не может быть менее 10 Р",
        },
        match: {
            value: /^\d+[.,]{0,1}\d{0,2}$/,
            message: "Неверный формат суммы.Пример: 100,12",
        },
    },
    actDate: {
        func: checkActDate,
        secondArg: "workStartDate",
    },
    estimatedTimeScore: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: requiredMessage,
        },
    },
    workStartDate: {
        required: requiredMessage,
    },
    workEndDate: {
        required: requiredMessage,
    },
    workQualityScore: {
        required: requiredMessage,
        minValue: {
            value: 0,
            message: requiredMessage,
        },
    },
    paymentMethod: {
        required: requiredMessage,
    },
};

export const ACT_CREATE_TASK_RULE = {
    description: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 5000,
            message: "Максимальная длина - 5000 символов",
        },
    },
    descriptionFns: {
        required: requiredMessage,
        minLength: {
            value: 5,
            message: "Минимальная длина строки 5 символов",
        },
        maxLength: {
            value: 160,
            message: "Максимальная длина - 160 символов",
        },
        match: {
            value: descriptionPaymentReg,
            message: "Неверный формат описания выполненных работ для чека ФНС России. Для ввода доступны следующие символы: кириллица, латиница, цифры, пробелы, знаки . , : ; - \" ' * № ! ? ( ) { } % < > + [ ] « » |",
        },
    },
    actDate: {
        func: checkActDate,
        secondArg: "workStartDate",
        required: requiredMessage,
    },
    estimatedTimeScore: {
        required: requiredMessage,
    },
    workStartDate: {
        required: requiredMessage,
    },
    workEndDate: {
        required: requiredMessage,
    },
    workQualityScore: {
        required: requiredMessage,
    },
};

function checkActDate(actDate, workStartDate) {
    if (!workStartDate) {
        return null;
    }

    if (getDateWithoutTime(actDate) < getDateWithoutTime(new Date(workStartDate))) {
        return "Дата акта не может быть раньше даты начала периода работ";
    }

    return null;
}