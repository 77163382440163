import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import {RECRUITMENT_KEDO_ERROR_CODE} from "../../../../../constants/recruitment";
import {
    addRecruitmentKedoStaff,
    updateRecruitmentKedoStaff,
    updateRecruitmentVacancyResponsesStore,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/actionCreators";
import {
    bffRecruitmentVacancyResponsesErrorSelector,
    bffRecruitmentVacancyResponsesProgressActionSelector,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/selectors";
import HelpTooltip from "../../../../ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../../ActualComponents/NmConfirmV2";
import NmConfirmV2Content from "../../../../ActualComponents/NmConfirmV2/Content";
import NmForm from "../../../../ActualComponents/NmForm";
import NmInputV2 from "../../../../ActualComponents/NmInputV2";
import NmModal from "../../../../ActualComponents/NmModal";
import Text from "../../../../ActualComponents/Text";
import ApplyButtons from "../../../../ApplyButtons";
import NmTitle from "../../../../NmTitle";

import {useModal} from "../../../../../hooks/useModal";
import {useSuccessActionCallback} from "../../../../../hooks/useSuccessAction";

import {filterEmptyValues} from "../../../../../utils/objectHelper";
import {removeSnilsMask} from "../../../../../utils/stringFormat";
import {getSnilsValidation} from "../../../../../utils/validate";

import {COLOR} from "../../../../../constants/color";
import {MASK_PHONE_FOREIGN} from "../../../../../constants/mask";
import {REG_EXP} from "../../../../../constants/regExp";
import {requiredMessage} from "../../../../../constants/validationRules";
import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

export const RecruitmentKedoIntegration = (props) => {
    const {
        onClose,
        vacancyId,
        candidateId,
        clientId,
        card,
    } = props;

    const dispatch = useDispatch();

    const error = useSelector(bffRecruitmentVacancyResponsesErrorSelector);
    const progressAction = useSelector(bffRecruitmentVacancyResponsesProgressActionSelector);
    const isConfirmError = error && [
        RECRUITMENT_KEDO_ERROR_CODE.KEDO_STAFF_WITH_GIVEN_SNILS_EXISTS,
    ].includes(error.errorCode);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    useEffect(() => {
        if (isConfirmError) {
            onOpenModal({
                isOpenConfirm: true,
            });
        }
    }, [isConfirmError]);

    useSuccessActionCallback({
        callback: () => {
            if (isConfirmError) {
                return;
            }

            if (error) {
                dispatch(updateRecruitmentVacancyResponsesStore({
                    error: null,
                }));

                return;
            }

            onClose();
        },
        progressAction,
    });

    const words = card.name
        ? card.name.split(" ")
        : [];
    const [
        lastName = "",
        firstName = "",
        _patronymic = "",
    ] = words;
    const patronymic = _patronymic
        ? words
            .splice(2, words.length)
            .join(" ")
        : "";

    const {
        values,
        setFieldValue,
        touched,
        errors,
        handleSubmit,
    } = useFormik({
        initialValues: {
            vacancyId,
            candidateId,
            clientId,
            firstName,
            lastName,
            patronymic,
            login: "",
            email: card.email || "",
            phone: card.phone || "",
            snils: "",
        },
        validationSchema: yup.object().shape({
            lastName: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .matches(REG_EXP.NAME, {
                    message: "Только кириллица, пробелы и знаки -",
                    excludeEmptyString: false,
                }),
            firstName: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .matches(REG_EXP.NAME, {
                    message: "Только кириллица, пробелы и знаки -",
                    excludeEmptyString: false,
                }),
            patronymic: yup.string()
                .matches(REG_EXP.NAME, {
                    message: "Только кириллица, пробелы и знаки -",
                    excludeEmptyString: false,
                }),
            snils: getSnilsValidation(),
            email: yup.string()
                .required(VALIDATIONS_MESSAGE.REQUIRED)
                .max(255, "Не более 255 символов")
                .matches(REG_EXP.EMAIL, {
                    message: "Введен некорректный email",
                }),
            login: yup.string()
                .required(requiredMessage)
                .matches(
                    REG_EXP.LOGIN,
                    "Введен некорректный логин",
                )
                .min(6, "Минимальная длина строки 6 символов")
                .max(255, "Не более 255 символов"),
            phone: yup.string()
                .min(8, "Минимальная длина номера 8 символов"),
        }),
        onSubmit,
    });

    const submit = (isUpdate) => {
        const data = filterEmptyValues({
            vacancyId,
            candidateId,
            clientId,
            ...values,
            snils: removeSnilsMask(values.snils),
        });

        if (isUpdate) {
            dispatch(updateRecruitmentKedoStaff(data));

            return;
        }

        dispatch(addRecruitmentKedoStaff({
            ...values,
            snils: removeSnilsMask(values.snils),
        }));
    };

    function onSubmit() {
        submit();
    }

    const onChange = (_event, {name, value}) => {
        setFieldValue(name, value);
    };

    if (modalData?.isOpenConfirm) {
        return (
            <NmConfirmV2
                disabled={progressAction}
                content="Данный кандидат уже отправлялся в модуль Наймикс КЭДО. При повторной отправки данные сотрудника с этим СНИЛС перезапишутся. Продолжить?"
                onCancel={() => {
                    dispatch(updateRecruitmentVacancyResponsesStore({
                        error: null,
                    }));
                    onCloseModal();
                }}
                onConfirm={() => {
                    submit(true);
                }}
                isNeedClosing={false}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    }

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    Добавление сотрудника в КЭДО
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    isHiddenCancelOnMobile
                    mobile="column"
                    onClose={onClose}
                    submit={handleSubmit}
                    cancelBtnContent="Отменить"
                    submitBtnContent="Добавить"
                />
            }
            onClose={onClose}
            loading={progressAction}
        >
            <NmForm addMargin={true}>
                <NmConfirmV2Content
                    warning
                    content='После нажатия кнопки "Добавить" данный кандидат будет добавлен в качестве сотрудника компании Наймикс.КЭДО'
                />
                <Text.Sub
                    level="2"
                    medium={true}
                    color={COLOR.SECONDARY_100}
                >
                    Персональные данные
                </Text.Sub>
                <NmInputV2
                    required={true}
                    size="xl"
                    maxLength={50}
                    label="Фамилия"
                    name="lastName"
                    value={values.lastName}
                    error={touched.lastName && errors.lastName}
                    placeholder="Введите фамилию"
                    onChange={onChange}
                />
                <NmInputV2
                    required={true}
                    size="xl"
                    maxLength={50}
                    label="Имя"
                    name="firstName"
                    value={values.firstName}
                    error={touched.firstName && errors.firstName}
                    placeholder="Введите имя"
                    onChange={onChange}
                />
                <NmInputV2
                    size="xl"
                    maxLength={50}
                    label="Отчество"
                    name="patronymic"
                    value={values.patronymic}
                    error={touched.patronymic && errors.patronymic}
                    placeholder="Введите отчество"
                    onChange={onChange}
                />
                <NmInputV2
                    required={true}
                    label="Снилс"
                    size="xl"
                    mask="999-999-999-99"
                    name="snils"
                    value={values.snils}
                    error={touched.snils && errors.snils}
                    placeholder="Введите СНИЛС"
                    onChange={onChange}
                />
                <Text.Sub
                    level="2"
                    medium={true}
                    color={COLOR.SECONDARY_100}
                >
                    Контактные данные
                </Text.Sub>
                <div className="row gx-4 gy-4">
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            size="xl"
                            maskChar={null}
                            mask={MASK_PHONE_FOREIGN}
                            label="Номер телефона"
                            name="phone"
                            value={values.phone}
                            error={touched.phone && errors.phone}
                            placeholder="Введите телефон"
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-16 col-md-8">
                        <NmInputV2
                            required={true}
                            size="xl"
                            maxLength={255}
                            autoComplete="off"
                            label="Email"
                            placeholder="Введите email"
                            name="email"
                            value={values.email}
                            error={touched.email && errors.email}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <NmInputV2
                    required={true}
                    size="xl"
                    maxLength={255}
                    autoComplete="off"
                    label="Логин"
                    placeholder="Введите логин"
                    name="login"
                    value={values.login}
                    error={touched.login && errors.login}
                    onChange={onChange}
                    tooltip={
                        <HelpTooltip
                            info={true}
                            type="light"
                            position="bottom-left"
                            hover={true}
                            children="Пароль для входа в личный кабинет сотрудника будет сгенерирован и автоматически отправлен на указанную почту"
                        />
                    }
                />
            </NmForm>
        </NmModal>
    );
};