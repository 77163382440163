import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_ADD_REGISTRY_INVITATIONS_ERROR,
    BFF_ADD_REGISTRY_INVITATIONS_REQUEST,
    BFF_ADD_REGISTRY_INVITATIONS_SUCCESS,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST,
    BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS,
    BFF_DELETE_REGISTRY_INVITATIONS_ERROR,
    BFF_DELETE_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS,
    BFF_UPDATE_REGISTRY_INVITATIONS_ERROR,
    BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/order-invitations-registry",
        client: "/client-adm/orders/order-invitations-registry",
    });
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/getPage
// POST bff/client-adm/orders/order-invitations-registry/getPage
// Получение страницы реестров приглашений
export const getRegistryInvitationsPageSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${getController()}/getPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/{clientId}/{registryInvitationId}/archived/{archived}
// POST bff/client-adm/orders/order-invitations-registry/{clientId}/{registryInvitationId}/archived/{archived}
// Добавление реестра в архив
export const archivedRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryInvitationId,
            archived,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryInvitationId}/archived/${archived}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ARCHIVED_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ARCHIVED_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/update
// POST bff/client-adm/orders/order-invitations-registry/update
// Обновление реестра
export const updateRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_UPDATE_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_UPDATE_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_UPDATE_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/add
// POST bff/client-adm/orders/order-invitations-registry/add
// Добавление реестра
export const addRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ADD_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADD_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// DELETE bff/adm/clients/client-card/orders/order-invitations-registry/{clientId}/{registryInvitationId}
// DELETE bff/client-adm/orders/order-invitations-registry/{clientId}/{registryInvitationId}
// Удаление реестра
export const deleteRegistryInvitationsSaga = function* ({payload} ) {
    try {
        const {
            clientId,
            registryInvitationId,
            onSuccess,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${clientId}/${registryInvitationId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DELETE_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DELETE_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DELETE_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_REGISTRY_INVITATIONS_PAGE_REQUEST, getRegistryInvitationsPageSaga),
        takeEvery(BFF_ARCHIVED_REGISTRY_INVITATIONS_REQUEST, archivedRegistryInvitationsSaga),
        takeEvery(BFF_UPDATE_REGISTRY_INVITATIONS_REQUEST, updateRegistryInvitationsSaga),
        takeEvery(BFF_ADD_REGISTRY_INVITATIONS_REQUEST, addRegistryInvitationsSaga),
        takeEvery(BFF_DELETE_REGISTRY_INVITATIONS_REQUEST, deleteRegistryInvitationsSaga),
    ]);
}
