import {useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {useDuplicateConfirm} from "../../../../../hooks/useDuplicateConfirm";

import {toastError} from "../../../../../utils/toastHelper";

import {UPLOAD_TYPE} from "../../../../../constants/financeExport";

import {
    checkDuplicateActRegistry,
    deleteActRegistryPayments,
    exportActRegistryPayments,
    importActRegistryPayments,
    payActRegistry,
    paySingleAct,
} from "../../../../../ducks/bff/orders/order-act-registries/card/actionCreators";
import {downloadDocument} from "../../../../../ducks/documents";

function useActRegistryCardAction({clientId, registryId, selectedList, fetchList, fetchTasks, clearSelectedRows}) {
    const [isAddContractorModalOpen, setIsAddContractorModalOpen] = useState(false);
    const [isInnTypeImportContractors, setIsInnTypeImportContractors] = useState(false);
    const [isHideDescriptionInList, setIsHideDescriptionInList] = useState(false);
    const [isInviteViaEmailOpen, setIsInviteViaEmailOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [confirmData, setConfirmData] = useState({});
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    const {dataDuplicates, setDataDuplicate} = useDuplicateConfirm();

    const dispatch = useDispatch();

    const definingRegistryParameterType = useMemo(() => {
        return isInnTypeImportContractors
            ? "INN"
            : "PHONE";
    }, [isInnTypeImportContractors]);

    const importPayments = ({file}) => {
        const formData = new FormData();
        formData.append("mFile", file);

        dispatch(importActRegistryPayments({
            clientId,
            registryId,
            definingRegistryParameterType,
            formData,
            onSuccess: () => {
                fetchTasks();
                setIsImportModalOpen(false);
            },
        }));
    };

    const onClickDownloadDocument = (downloadLink) => {
        dispatch(downloadDocument({
            downloadLink,
            isDownload: true,
        }));
    };

    const checkDuplicateAct = (fullName, seqNums) => {
        const confirm = () => {
            dispatch(checkDuplicateActRegistry({
                clientId,
                registryId,
                seqNums,
                onSuccess: ({payModel}) => {
                    if (payModel !== null && payModel.length) {
                        setDataDuplicate(payModel);
                        return;
                    }
                    fullName ? _paySingleAct(false, seqNums, []) : payRegistry(false, seqNums, []);
                },
            }));
        };
        setConfirmData({
            content: fullName ? `Вы действительно хотите отправить акт исполнителю ${fullName}?` : "Вы действительно хотите отправить акты исполнителям?",
            confirmButton: "Подтвердить",
            cancelButton: "Отменить",
            onConfirm: confirm,
        });
        setIsOpenConfirm(true);
    };

    const _paySingleAct = (ignoreCitizenship, seqNums, seqNumsToDelete) => {
        dispatch(paySingleAct({
            clientId,
            registryId,
            ignoreCitizenship,
            seqNums,
            seqNumsToDelete,
            getError: ({errorCode, errorMessage}) => {
                if (errorCode === "ERROR_ACT_PAYMENTS_RESIDENT") {
                    setConfirmData({
                        content: errorMessage,
                        confirmButton: "Подтвердить",
                        cancelButton: "Отменить",
                        onConfirm: () => _paySingleAct(true, seqNums, seqNumsToDelete),
                    });
                    setIsOpenConfirm(true);
                } else {
                    toastError(errorMessage);
                }
            },
            onSuccess: () => {
                fetchTasks();
            },
        }));
    };

    const closeActDuplicateConfirm = () => {
        setDataDuplicate([]);
    };

    const payRegistry = (ignoreCitizenship, seqNums, seqNumsToDelete) => {
        dispatch(payActRegistry({
            clientId,
            registryId,
            ignoreCitizenship,
            seqNums,
            seqNumsToDelete,
            getError: ({errorCode, errorMessage}) => {
                if (errorCode === "ERROR_ACT_PAYMENTS_RESIDENT") {
                    setConfirmData({
                        content: errorMessage,
                        confirmButton: "Подтвердить",
                        cancelButton: "Отменить",
                        onConfirm: () => payRegistry(true, seqNums, seqNumsToDelete),
                    });
                    setIsOpenConfirm(true);
                } else {
                    toastError(errorMessage);
                }
            },
            onSuccess: () => {
                fetchTasks();
            },
        }));
    };

    const deleteItems = () => {
        const registryPaymentSeqNumbers = selectedList.filter(value => value.isSelected).map(({registrySeqNum}) => registrySeqNum);
        dispatch(deleteActRegistryPayments({
            clientId,
            registryId,
            registryPaymentSeqNumbers,
            onSuccess: () => {
                clearSelectedRows();
                fetchList();
            },
        }));
    };

    const exportRegistry = () => {
        dispatch(exportActRegistryPayments({
            fromClientId: clientId,
            registryId,
            uploadType: UPLOAD_TYPE.ACT_REGISTRY_EXPORT.value,
        }));
    };

    const closeConfirm = () => {
        setIsOpenConfirm(false);
        setConfirmData({});
    };

    const openDeleteConfirm = () => {
        setConfirmData({
            content: "Вы действительно хотите удалить выбранные строки из реестра?",
            confirmButton: "Продолжить",
            cancelButton: "Отмена",
            onConfirm: deleteItems,
        });
        setIsOpenConfirm(true);
    };

    return {
        isAddContractorModalOpen,
        setIsAddContractorModalOpen,
        setIsInnTypeImportContractors,
        isInnTypeImportContractors,
        isHideDescriptionInList,
        setIsHideDescriptionInList,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        isImportModalOpen,
        setIsImportModalOpen,
        importPayments,
        exportRegistry,
        onClickDownloadDocument,
        payRegistry,
        isOpenConfirm,
        confirmData,
        closeConfirm,
        openDeleteConfirm,
        closeActDuplicateConfirm,
        dataDuplicates,
        checkDuplicateAct,
    };
}

export default useActRegistryCardAction;