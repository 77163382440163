export const CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST = "CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST";
export const CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS = "CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS";
export const CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR = "CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR";

export const RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST = "RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST";
export const RECREATE_START_DOCUMENT_NOTIFICATION_ERROR = "RECREATE_START_DOCUMENT_NOTIFICATION_ERROR";
export const RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS = "RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS";

export const RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST = "RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST";
export const RECREATE_END_DOCUMENT_NOTIFICATION_ERROR = "RECREATE_END_DOCUMENT_NOTIFICATION_ERROR";
export const RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS = "RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS";

export const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST";
export const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS";
export const UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR = "UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR";

export const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST";
export const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS";
export const SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR = "SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR";

export const TERMINATE_FRAME_CONTRACT_REQUEST = "TERMINATE_FRAME_CONTRACT_REQUEST";
export const TERMINATE_FRAME_CONTRACT_SUCCESS = "TERMINATE_FRAME_CONTRACT_SUCCESS";
export const TERMINATE_FRAME_CONTRACT_ERROR = "TERMINATE_FRAME_CONTRACT_ERROR";

export const RESIDENT_VALIDATION_REQUEST = "RESIDENT_VALIDATION_REQUEST";
export const RESIDENT_VALIDATION_SUCCESS = "RESIDENT_VALIDATION_SUCCESS";
export const RESIDENT_VALIDATION_ERROR = "RESIDENT_VALIDATION_ERROR";

export const CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE = "CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE";

export const CLIENT_DOCUMENTS_REGISTRY_CLEAR_STORE = "CLIENT_DOCUMENTS_REGISTRY_CLEAR_STORE";