import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import MemberEdit from "../../../components/MemberEdit";

import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {
    clearSpace,
    removePhoneMask,
} from "../../../utils/stringFormat";
import {
    handleFormString,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import validate from "../../../utils/validate";

import {CLIENT_MEMBER_FIELD_NAME} from "../../../constants/clientList";
import {MEMBER_POSITIONS_DICT} from "../../../constants/memberPositions";
import {
    ADMIN,
    CLIENT_ADMIN,
    FOREMAN,
    GR,
    isUserFromNm,
    NM_CONSULTANT,
    NM_MANAGER,
    roleOptionsByCurrentUserRole,
    roleSettingsList,
} from "../../../constants/roles";
import {
    clientMemberRule,
    passwordRule,
    requiredMessage,
    settingsMemberRule,
} from "../../../constants/validationRules";

import {
    addClientMember,
    updateFieldClientMemberStore,
} from "../../../ducks/bff/clients/employees/actionCreators";
import {
    clientMemberIsSuccessAddSelector,
    clientMemberProgressAddSelector,
} from "../../../ducks/bff/clients/employees/selectors";
import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {clientCardPropertiesSelector} from "../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

import "./style.sass";

class ClientMemberNew extends Component {
    static propTypes = {
        selectedClientId: PropTypes.string,
        onCloseModel: PropTypes.func,
        updateFieldClientMemberStore: PropTypes.func,
        open: PropTypes.bool,
        progressAdd: PropTypes.bool,
        isSuccessAdd: PropTypes.bool,
        fetchList: PropTypes.func,
        className: PropTypes.string,
        addClientMember: PropTypes.func,
    };

    static defaultProps = {
        selectedClientId: "",
        onCloseModel: () => {
        },
        updateFieldClientMemberStore: () => {
        },
        open: false,
        progressAdd: false,
        isSuccessAdd: false,
        fetchList: () => {
        },
        className: "",
        addClientMember: () => {
        },
        isSettingsAdmin: false,
    };

    defaultMember = {
        clientUserId: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        patronymic: "",
        position: "",
        phone: "",
        login: "",
        role: "",
        seniorRecruiter: false,
        snils: "",
        [CLIENT_MEMBER_FIELD_NAME.INN]: "",
        canEditContractorWorkLog: true,
        pushNotificationPublicationProhibited: true,
        sendPassword: false,
        requireWorkStartDateThreeDaysOffsetForOrder: false,
        contractorInvitationProhibited: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isEditPassword: true,
            passwordForm: {
                password: "",
                repeatPassword: "",
            },
            errorRepeatPasswordMessage: "",
            formError: {},
            newMember: {
                ...this.defaultMember,
            },
        };

        this.memberPositions = dictionaryToOptions(MEMBER_POSITIONS_DICT);
        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            selectedClientId,
            getClientCardProperties,
        } = this.props;

        if (selectedClientId) {
            getClientCardProperties({clientId: selectedClientId});
        }
    }

    componentDidUpdate(prevProps) {
        const {
            progressAdd,
            isSuccessAdd,
            fetchList,
        } = this.props;

        if (!progressAdd && isSuccessAdd && prevProps.progressAdd !== progressAdd) {
            fetchList();
            this.closeModel();
        }
    }

    submitForm = () => {
        const {
            newMember,
            isEditPassword,
        } = this.state;

        const {
            login,
            email,
            password,
            lastName,
            firstName,
            position,
            patronymic,
            role,
            phone,
            pushNotificationPublicationProhibited,
            sendPassword,
        } = newMember;

        const {
            addClientMember,
            selectedClientId,
        } = this.props;

        const _phone = phone ? removePhoneMask(phone.trim()) : null;

        const isValidForm = this.validationForm({...newMember, phone: _phone});

        if (!this.validationRepeatPassword() || !isValidForm) {
            return;
        }

        const _password = (isEditPassword && isNullOrWhitespace(password) || sendPassword) ? undefined : password;

        const requestData = {
            ...newMember,
            clientId: selectedClientId,
            email: email.trim(),
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            password: _password,
            patronymic: patronymic && patronymic.trim(),
            position: position ? position : null,
            phone: _phone,
            login: login.trim(),
            role,
            [CLIENT_MEMBER_FIELD_NAME.INN]: clearSpace(handleFormString(newMember[CLIENT_MEMBER_FIELD_NAME.INN])),
            pushNotificationPublicationProhibited: role === FOREMAN ? pushNotificationPublicationProhibited : false,
        };

        addClientMember(requestData);
    };

    onChange = (e, {value, name, checked}) => {
        if (["password", "repeatPassword"].includes(name)) {
            this.setState(prevState => ({
                ...prevState,
                passwordForm: {
                    ...prevState.passwordForm,
                    [name]: value,
                },
            }));
        }

        this.setState(prevState =>
            ({
                ...prevState,
                newMember: {
                    ...prevState.newMember,
                    [name]: checked === undefined ? value : checked,
                },
            }),
        );
    };

    validationRepeatPassword = () => {
        const {passwordForm: {password, repeatPassword}} = this.state;

        if (password === null) {
            return;
        }

        if (password !== repeatPassword) {
            this.setState({
                errorRepeatPasswordMessage: "Пароли не совпадают",
            });

            return false;
        }

        this.setState({errorRepeatPasswordMessage: ""});

        return true;
    };

    validationForm(member) {
        const {
            isSettingsAdmin,
            clientProperty: {
                innNecessary,
            },
        } = this.props;

        let rule = isSettingsAdmin ? settingsMemberRule : clientMemberRule;

        if (!member.sendPassword) {
            rule = {
                ...rule,
                ...passwordRule,
            };
        }

        if (!isSettingsAdmin && innNecessary) {
            rule = {
                ...rule,
                [CLIENT_MEMBER_FIELD_NAME.INN]: {
                    ...rule[CLIENT_MEMBER_FIELD_NAME.INN],
                    required: requiredMessage,
                },
            };
        }

        const errorMessage = validate(member, rule, "");

        this.setState({formError: {...errorMessage}});

        return Object.values(errorMessage).length === 0;
    };

    closeModel = () => {
        const {
            onCloseModel,
            updateFieldClientMemberStore,
        } = this.props;

        this.setState({
            ...this.defaultMember,
        });

        updateFieldClientMemberStore({isSuccessAdd: false});

        onCloseModel();
    };

    mapOptions = (activeItem) => {
        const {isSettingsAdmin} = this.props;

        const options = isSettingsAdmin ? roleSettingsList : roleOptionsByCurrentUserRole(this.role);

        const optionsByRole = this.role === NM_MANAGER ? options.filter(({key}) => key !== ADMIN) : options;

        if (isUserFromNm(this.role)) {
            optionsByRole.push({
                key: GR,
                text: GR,
                value: GR,
            });
        }

        return optionsByRole.map(item => ({
            ...item,
            active: activeItem === item.key || activeItem === item.text,
            selected: activeItem === item.key || activeItem === item.text,
        }));
    };

    onClickEditPassword = () => {
        this.setState(prevState => ({
            ...prevState,
            isEditPassword: !prevState.isEditPassword,
        }));
    };

    render() {
        const {
            className,
            progressAdd,
            t,
            clientProperty: {
                innNecessary,
            },
            selectedClientId,
        } = this.props;

        const {
            formError,
            newMember,
            errorRepeatPasswordMessage,
            isEditPassword,
            passwordForm,
        } = this.state;

        const {
            role,
        } = newMember;

        return (
            <MemberEdit
                innNecessary={innNecessary}
                isClient
                className={`client-member-new ${className}`}
                onClose={this.closeModel}
                title={t("client-member-new.title")}
                formError={formError}
                translate={t}
                user={newMember}
                submit={this.submitForm}
                onChange={this.onChange}
                roleOptions={this.mapOptions(role)}
                positionsOptions={this.memberPositions}
                toggleEditPassword={this.onClickEditPassword}
                isNewMember
                errorRepeatPasswordMessage={errorRepeatPasswordMessage}
                passwordForm={passwordForm}
                onBlurRepeatPassword={this.validationRepeatPassword}
                isEditPassword={isEditPassword}
                loading={progressAdd}
                isAccessToTimeSheet={[CLIENT_ADMIN, ADMIN, NM_MANAGER, NM_CONSULTANT].includes(this.role)}
                clientId={selectedClientId}
            />
        );
    }
}

export default connect(
    state => ({
        progressAdd: clientMemberProgressAddSelector(state),
        isSuccessAdd: clientMemberIsSuccessAddSelector(state),
        clientProperty: clientCardPropertiesSelector(state),
    }),
    {
        addClientMember,
        updateFieldClientMemberStore,
        getClientCardProperties,
    },
)(withTranslation()(ClientMemberNew));
