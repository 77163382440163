import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmAdvancedTooltip from "../../../../components/ActualComponents/NmAdvancedTooltip";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import {NmPageCardHeader} from "../../../../components/ActualComponents/NmPageCardHeader";
import Tabs from "../../../../components/ActualComponents/Tabs";
import ExtLink from "../../../../components/ExtLink";
import HoReCaIndicator from "../../../../components/HoReCaIndicator";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as HelpResourceIcon} from "../../../../images/help-resource-indicator.svg";
import {ReactComponent as PhoneIcon} from "../../../../images/phone_24.svg";
import TicketCardRoute from "../route";
import TransferTicketModal from "../transfer-modal";

import useTicketCard from "./hooks/useTicketCard";

import {getUserRestrictions} from "../../../../utils/access";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {getFormattedFullName, getFullName, phoneFormat} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_GROUP_TYPE} from "../../../../constants/clientGroup";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {SUB_PAGE_CRM_TICKET_CARD, TICKET_STATUS_DICT} from "../../../../constants/crm/ticket";
import {
    LINK_CLIENT_CARD_CRM_TICKET_CARD,
    LINK_CLIENT_INFO,
    LINK_CONTRACTOR_CRM_TICKET_CARD,
    LINK_CONTRACTOR_PROFILE,
    LINK_CRM_TICKET_CARD,
    LINK_CRM_TICKET_LIST_IN_WORK,
} from "../../../../constants/links";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";

import {
    getTicketCallHistoryRichPage,
    getTicketCallHistoryTotalCount,
    getTicketCallTypeDict,
    getTicketCategoryDict,
    getTicketStatusDict,
    getTicketTotalCount,
    getTicketTypeDict,
    reopenProgressSelector,
    ticketCallTypeDictSelector,
    ticketCardSelector,
    ticketCategoryDictSelector,
    ticketHistoryTotalCountSelector,
    ticketStatusDictSelector,
    ticketTypeDictSelector,
    unattachedCallTabTotalCountSelector,
} from "../../../../ducks/crm/ticket";

function TicketCard(props) {
    const {
        ticketId,
        _subpage: subpage,
        subpage: subpageList,
        clientId,
        contractorId,
    } = props.match.params;

    const [isShowUnattachedTab, setIsUnattachedPage] = useState(false);

    const card = useSelector(ticketCardSelector);
    const reopenProgress = useSelector(reopenProgressSelector);
    const {state} = useSelector(state => state.router.location);

    const role = ls(USER_ROLE) || "";
    const currentUserRestrictions = getUserRestrictions();
    const listLink = state?.prevPath || LINK_CRM_TICKET_LIST_IN_WORK;

    const isEditable = [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role);

    const {baseModel, managerModel, clientModel, contractorModel, clientUserModel} = card;

    const {
        closeTicket,
        confirmData,
        reopen,
        cancelConfirm,
        loading,
        getTicket,
        takeInWorkTicket,
        loadingTakeInWork,
        openTransferTicketModal,
        closeTransferTicketModal,
        isOpenTransferTicketModal,
        changeOperator,
        loadingChangeOperator,
        initCall,
        loadingInitCall,
    } = useTicketCard({ticketId});
    const {
        lastName,
        firstName,
        patronymic,
    } = managerModel || {};

    const {
        orderNumber,
        type,
        fromNumber,
        callType,
        status,
        createDate,
        closeDate,
    } = baseModel || {};

    const ticketTypeDict = useSelector(ticketTypeDictSelector);
    const ticketStatusDict = useSelector(ticketStatusDictSelector);
    const ticketCategoryDict = useSelector(ticketCategoryDictSelector);
    const ticketCallTypeDict = useSelector(ticketCallTypeDictSelector);
    const ticketHistoryTotalCount = useSelector(ticketHistoryTotalCountSelector);
    const unattachedCallTabTotalCount = useSelector(unattachedCallTabTotalCountSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(ticketTypeDict)) {
            dispatch(getTicketTypeDict());
        }
        if (isEmpty(ticketStatusDict)) {
            dispatch(getTicketStatusDict());
        }
        if (isEmpty(ticketCategoryDict)) {
            dispatch(getTicketCategoryDict());
        }
        if (isEmpty(ticketCallTypeDict)) {
            dispatch(getTicketCallTypeDict());
        }

    }, []);

    useEffect(() => {
        if (!fromNumber) {
            return;
        }

        dispatch(getTicketTotalCount({
            pageSize: 25,
            pageNum: 1,
            phoneFilter: fromNumber,
            statusesFilter: [
                TICKET_STATUS_DICT.RECALL.VALUE,
                TICKET_STATUS_DICT.IN_WORK.VALUE,
                TICKET_STATUS_DICT.CLOSED.VALUE,
                TICKET_STATUS_DICT.MISSED.VALUE,
            ],
        }));

        dispatch(getTicketCallHistoryTotalCount({
            ticketIsNullFilter: true,
            toNumberFilter: fromNumber,
            pageSize: 25,
            pageNum: 1,
        }));

        dispatch(getTicketCallHistoryRichPage({
            isUnattachedPage: true,
            ticketIsNullFilter: true,
            toNumberFilter: fromNumber,
            pageSize: 25,
            pageNum: 1,
            getResult: ({totalCount}) => {
                setIsUnattachedPage(Boolean(totalCount));
            },
        }));
    }, [fromNumber, ticketId]);


    function getLocalDate(date, format = "dd.MM.yyyy HH:mm") {
        return formatLocalDate(date, format);
    }

    const getContractorIndicator = () => {
        if (contractorModel.contractorClientGroupType === CLIENT_GROUP_TYPE.HO_RE_CA) {
            return <HoReCaIndicator className="ms-2" />;
        }

        if (contractorModel.contractorClientGroupType === CLIENT_GROUP_TYPE.HELP_RESOURCES) {
            return (
                <NmAdvancedTooltip
                    className="ms-2"
                    children='Исполнитель в закрытом контуре "HelpResource"'
                    hover
                    position="bottom-left"
                    trigger={
                        <HelpResourceIcon
                            width={24}
                            height={24}
                        />
                    }
                />
            );
        }

        return null;
    };

    function getPrimaryHeader() {
        const {name, clientId} = clientModel || {};
        const {lastName, firstName, patronymic} = clientUserModel || {};
        const {
            lastName: contractorLastName,
            firstName: contractorFirstName,
            patronymic: contractorPatronymic,
            fio,
            contractorId,
        } = contractorModel || {};

        if (clientModel && clientUserModel) {
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);

            return <div>
                <ExtLink
                    historyEnabled
                    to={linkCard}
                >
                    {name}
                </ExtLink>
                &nbsp;(
                {getFullName(lastName, firstName, patronymic)}
                )
            </div>;
        }

        if (clientModel) {
            const linkCard = LINK_CLIENT_INFO.replace(":clientId", clientId);

            return (
                <ExtLink
                    historyEnabled
                    to={linkCard}
                >
                    {name}
                </ExtLink>
            );
        }

        if (contractorModel) {
            const content = contractorLastName ? getFullName(contractorLastName, contractorFirstName, contractorPatronymic) : fio;
            const linkCard = LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId);

            return (
                <div className="flex align-items-center">
                    <ExtLink
                        historyEnabled
                        to={linkCard}
                    >
                        {content}
                    </ExtLink>
                    {getContractorIndicator()}
                </div>
            );
        }

        return "Не определено";
    }

    const getHeaderMediaControls = () => {
        if (
            !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.changeTickets,
            ])
        ) {
            return null;
        }

        return {
            renderCount: {
                desktop: 2,
                tablet: 1,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: takeInWorkTicket,
                        children: "Взять в работу",
                        disabled: loadingTakeInWork,
                        loading: loadingTakeInWork,
                    },
                    visible: [TICKET_STATUS_DICT.RECALL.VALUE].includes(status)
                        && !baseModel?.managerId
                        || [
                            TICKET_STATUS_DICT.IN_PROCESS.VALUE,
                            TICKET_STATUS_DICT.MISSED.VALUE,
                        ].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        onClick: openTransferTicketModal,
                        children: "Перевести",
                        disabled: reopenProgress,
                    },
                    visible: ![TICKET_STATUS_DICT.CLOSED.VALUE].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: initCall,
                        children: "Позвонить",
                        disabled: loadingInitCall,
                        icon: <PhoneIcon />,
                    },
                    visible: [NM_OPERATOR].includes(role)
                        && [TICKET_STATUS_DICT.IN_WORK.VALUE, TICKET_STATUS_DICT.RECALL.VALUE].includes(status)
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.callInMango,
                        ]),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: reopen,
                        children: "Переоткрыть",
                        disabled: reopenProgress,
                    },
                    visible: [TICKET_STATUS_DICT.CLOSED.VALUE].includes(status),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "grey",
                        onClick: closeTicket,
                        children: "Закрыть",
                        disabled: reopenProgress,
                    },
                    visible: ![TICKET_STATUS_DICT.CLOSED.VALUE].includes(status)
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.closeRequestCrm,
                        ]),
                },
            ],
        };
    };

    function generalInfo() {
        return (
            <NmListCard
                primaryHeader={getPrimaryHeader()}
                labels={[
                    {
                        label: "Телефон",
                        text: phoneFormat(fromNumber),
                        noWrap: false,
                    },
                    {
                        label: "Источник",
                        text: ticketCallTypeDict[callType],
                        noWrap: false,
                    },
                    {
                        label: "Дата и время создания обращения",
                        text: createDate ? getLocalDate(createDate) : "-",
                        noWrap: false,
                    },
                    {
                        label: "Дата и время закрытия обращения",
                        text: closeDate ? getLocalDate(closeDate) : "-",
                        noWrap: false,
                    },
                    {
                        label: "Ответственный",
                        text: lastName ? getFormattedFullName(getFullName(lastName, firstName, patronymic)) : "-",
                        noWrap: false,
                    },
                ]}
            />
        );
    }

    function replaceLink(_subpage) {
        let LINK_CARD = LINK_CRM_TICKET_CARD;

        if (clientId) {
            LINK_CARD = LINK_CLIENT_CARD_CRM_TICKET_CARD.replace(":clientId", clientId);
        }
        if (contractorId) {
            LINK_CARD = LINK_CONTRACTOR_CRM_TICKET_CARD.replace(":contractorId", contractorId);
        }

        return LINK_CARD
            .replace(":subpage", subpageList)
            .replace(":_subpage", _subpage)
            .replace(":ticketId", ticketId);
    }

    function getTabData() {
        const tabs = [
            {
                name: SUB_PAGE_CRM_TICKET_CARD.PARAMS.TEXT,
                link: replaceLink(SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET_CARD.PARAMS.LINK,
            },
            {
                name: SUB_PAGE_CRM_TICKET_CARD.CALL_HISTORY.TEXT,
                link: replaceLink(SUB_PAGE_CRM_TICKET_CARD.CALL_HISTORY.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET_CARD.CALL_HISTORY.LINK,
            },
            {
                name: SUB_PAGE_CRM_TICKET_CARD.HISTORY.TEXT,
                link: replaceLink(SUB_PAGE_CRM_TICKET_CARD.HISTORY.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET_CARD.HISTORY.LINK,
                count: ticketHistoryTotalCount,
            },
            {
                name: SUB_PAGE_CRM_TICKET_CARD.LOG.TEXT,
                link: replaceLink(SUB_PAGE_CRM_TICKET_CARD.LOG.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET_CARD.LOG.LINK,
            },
        ];

        if (isShowUnattachedTab) {
            tabs.push({
                name: SUB_PAGE_CRM_TICKET_CARD.UNATTACHED.TEXT,
                link: replaceLink(SUB_PAGE_CRM_TICKET_CARD.UNATTACHED.LINK),
                active: subpage === SUB_PAGE_CRM_TICKET_CARD.UNATTACHED.LINK,
                count: unattachedCallTabTotalCount,
            });
        }

        return tabs;
    }

    return (
        <NmPage
            overflowUnset
            noPadding={Boolean(clientId || contractorId)}
            isLoaded={loading}
            header={
                <NmPageCardHeader
                    to={listLink}
                    status={status}
                    statusDict={TICKET_STATUS_DICT}
                    content={`№${orderNumber} - ${type ? ticketTypeDict[type] : "Не определено"}`}
                />
            }
            mediaControls={getHeaderMediaControls()}
            currentPageNum={0}
            currentPageSize={0}
            totalPages={0}
        >
            <TransferTicketModal
                loading={loadingChangeOperator}
                onSubmit={changeOperator}
                isOpened={isOpenTransferTicketModal}
                onClose={closeTransferTicketModal}
            />
            {
                confirmData.content &&
                <NmConfirmV2
                    content={confirmData.content}
                    onCancel={cancelConfirm}
                    onConfirm={confirmData.confirm}
                    confirmButton="Продолжить"
                    cancelButton="Отмена"
                />
            }
            {generalInfo()}
            <Tabs
                panes={getTabData()}
            />
            <TicketCardRoute
                contractorId={contractorId}
                clientId={clientId}
                isEditable={isEditable && ![TICKET_STATUS_DICT.CLOSED.VALUE].includes(status)}
                subpageList={subpageList}
                ticketTypeDict={ticketTypeDict}
                ticketCategoryDict={ticketCategoryDict}
                card={card}
                ticketId={ticketId}
                getTicket={getTicket}
            />
        </NmPage>
    );
}

export default TicketCard;