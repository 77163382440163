export const EDM_GET_STAFF_REQUEST = "EDM_GET_STAFF_REQUEST";
export const EDM_GET_STAFF_SUCCESS = "EDM_GET_STAFF_SUCCESS";
export const EDM_GET_STAFF_ERROR = "EDM_GET_STAFF_ERROR";

export const EDM_GET_STAFF_LIST_REQUEST = "EDM_GET_STAFF_LIST_REQUEST";
export const EDM_GET_STAFF_LIST_SUCCESS = "EDM_GET_STAFF_LIST_SUCCESS";
export const EDM_GET_STAFF_LIST_ERROR = "EDM_GET_STAFF_LIST_ERROR";

export const EDM_ADD_STAFF_REQUEST = "EDM_ADD_STAFF_REQUEST";
export const EDM_ADD_STAFF_SUCCESS = "EDM_ADD_STAFF_SUCCESS";
export const EDM_ADD_STAFF_ERROR = "EDM_ADD_STAFF_ERROR";

export const EDM_UPDATE_STAFF_REQUEST = "EDM_UPDATE_STAFF_REQUEST";
export const EDM_UPDATE_STAFF_SUCCESS = "EDM_UPDATE_STAFF_SUCCESS";
export const EDM_UPDATE_STAFF_ERROR = "EDM_UPDATE_STAFF_ERROR";

export const EDM_ARCHIVE_STAFF_REQUEST = "EDM_ARCHIVE_STAFF_REQUEST";
export const EDM_ARCHIVE_STAFF_SUCCESS = "EDM_ARCHIVE_STAFF_SUCCESS";
export const EDM_ARCHIVE_STAFF_ERROR = "EDM_ARCHIVE_STAFF_ERROR";

export const EDM_STAFF_FIND_CONTRACTORS_REQUEST = "EDM_STAFF_FIND_CONTRACTORS_REQUEST";
export const EDM_STAFF_FIND_CONTRACTORS_SUCCESS = "EDM_STAFF_FIND_CONTRACTORS_SUCCESS";
export const EDM_STAFF_FIND_CONTRACTORS_ERROR = "EDM_STAFF_FIND_CONTRACTORS_ERROR";

export const EDM_STAFF_FIND_USERS_REQUEST = "EDM_STAFF_FIND_USERS_REQUEST";
export const EDM_STAFF_FIND_USERS_SUCCESS = "EDM_STAFF_FIND_USERS_SUCCESS";
export const EDM_STAFF_FIND_USERS_ERROR = "EDM_STAFF_FIND_USERS_ERROR";

export const EDM_STAFF_IMPORT_REQUEST = "EDM_STAFF_IMPORT_REQUEST";
export const EDM_STAFF_IMPORT_SUCCESS = "EDM_STAFF_IMPORT_SUCCESS";
export const EDM_STAFF_IMPORT_ERROR = "EDM_STAFF_IMPORT_ERROR";

export const EDM_STAFF_EXPORT_REQUEST = "EDM_STAFF_EXPORT_REQUEST";
export const EDM_STAFF_EXPORT_SUCCESS = "EDM_STAFF_EXPORT_SUCCESS";
export const EDM_STAFF_EXPORT_ERROR = "EDM_STAFF_EXPORT_ERROR";

export const EDM_STAFF_USER_EXIST_REQUEST = "EDM_STAFF_USER_EXIST_REQUEST";
export const EDM_STAFF_USER_EXIST_SUCCESS = "EDM_STAFF_USER_EXIST_SUCCESS";
export const EDM_STAFF_USER_EXIST_ERROR = "EDM_STAFF_USER_EXIST_ERROR";