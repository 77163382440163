import React from "react";
import {useSelector} from "react-redux";

import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";

import {updateOrderContractorsFoundProgressSelector} from "../../../../ducks/bff/orders/orders-registry/selectors";

import "./style.sass";

interface IOrderCardHideResponses {
    onChange: (e: any, {checked}: {checked: boolean}) => void,
    contractorsFound: boolean,
    disabled: boolean,
}

const OrderCardHideResponses = ({onChange, contractorsFound, disabled}: IOrderCardHideResponses) => {
    const progressHideResponses = useSelector(updateOrderContractorsFoundProgressSelector);

    return (
        <NmHorizontalToggleV2
            className="order-card-hide-responses"
            disabled={progressHideResponses || disabled}
            leftLabel="Скрыть для откликов"
            single
            onChange={onChange}
            checked={contractorsFound}
            duplex={false}
        />
    );
};

export default OrderCardHideResponses;