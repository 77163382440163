import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getUserAvatarListThumbnails} from "../../../../fileStore";
import {
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR,
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST,
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS,
    BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_ERROR,
    BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST,
    BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_SUCCESS,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS,
    BFF_RUN_REGISTRY_INVITATIONS_ERROR,
    BFF_RUN_REGISTRY_INVITATIONS_ITEM_ERROR,
    BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST,
    BFF_RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS,
    BFF_RUN_REGISTRY_INVITATIONS_REQUEST,
    BFF_RUN_REGISTRY_INVITATIONS_SUCCESS,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS,
} from "./actions";

import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

import {FINANCE_EXPORT_MESSAGES} from "../../../../../constants/messages";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/order-invitations-registry/card",
        client: "/client-adm/orders/order-invitations-registry/card",
    });
};

// GET bff/adm/clients/client-card/orders/order-invitations-registry/card/get-by-id
// GET bff/client-adm/orders/order-invitations-registry/card/get-by-id
// Получить реестр по идентификатору
export const getRegistryInvitationsByIdSaga = function* ({payload: params} ) {
    try {
        const result = yield request.bff.get(`${getController()}/get-by-id`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/run-registry
// POST bff/client-adm/orders/order-invitations-registry/card/run-registry
// Запустить реестр в работу
export const runRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/run-registry`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RUN_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Реестр приглашений передан в работу");
        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RUN_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RUN_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/is-valid-non-resident-frame-signed
// POST bff/client-adm/orders/order-invitations-registry/card/is-valid-non-resident-frame-signed
// Проверка рамочных договоров нерезидентов перед запуском в работу
export const checkValidResidentRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            getResult,
        } = payload;

        const result = yield request.bff.post(`${getController()}/is-valid-non-resident-frame-signed`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/delete-by-ids
// POST bff/client-adm/orders/order-invitations-registry/card/delete-by-ids
// Проверка рамочных договоров нерезидентов перед запуском в работу
export const deleteContractorsFromRegistryInvitationsSaga = function* ({payload} ) {
    try {
        const {
            requestData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete-by-ids`, requestData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/import/{clientId}/{registryInvitationId}
// POST bff/client-adm/orders/order-invitations-registry/card/import/{clientId}/{registryInvitationId}
// Импорт файла xlsx
const importRegistryInvitationsContractorsSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            registryInvitationId,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/import/${clientId}/${registryInvitationId}`, formData, {...getMultipartHeaders()});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/run-contractor
// POST bff/client-adm/orders/order-invitations-registry/card/run-contractor
// Передать в работу строку реестра
export const runRegistryInvitationsItemSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/run-contractor`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RUN_REGISTRY_INVITATIONS_ITEM_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Строка передана в работу");

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_RUN_REGISTRY_INVITATIONS_ITEM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RUN_REGISTRY_INVITATIONS_ITEM_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/page
// POST bff/client-adm/orders/order-invitations-registry/card/page
// Получить страницу строк реестра с дополнительной информацией
export const getRegistryInvitationsContractorsPageSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        const {results} = result;

        if (results?.length) {
            const contractorIds = results.map(({baseModel: {contractorId}}) => contractorId).filter(item => Boolean(item));

            yield put(getUserAvatarListThumbnails({
                contractorIds,
            }));
        }

        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/add
// POST bff/client-adm/orders/order-invitations-registry/card/add
// Добавление строки в реестр
export const addContractorToRegistryInvitationsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/update
// POST bff/client-adm/orders/order-invitations-registry/card/update
// Обновление строки реестра
export const updateRegistryInvitationsContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-invitations-registry/card/add-export
// POST bff/client-adm/orders/order-invitations-registry/card/add-export
// Выгрузить
export const addTaskOrderInvitationsRegistryExportSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/add-export`, payload);
        const {errorMessage, error} = result;

        if (errorMessage || error) {
            toastError(errorMessage || "Не удалось создать задачу");

            yield put({
                type: BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_SUCCESS);

        yield put({
            type: BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST, getRegistryInvitationsByIdSaga),
        takeEvery(BFF_RUN_REGISTRY_INVITATIONS_REQUEST, runRegistryInvitationsSaga),
        takeEvery(BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST, checkValidResidentRegistryInvitationsSaga),
        takeEvery(BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST, deleteContractorsFromRegistryInvitationsSaga),
        takeEvery(BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST, importRegistryInvitationsContractorsSaga),
        takeEvery(BFF_RUN_REGISTRY_INVITATIONS_ITEM_REQUEST, runRegistryInvitationsItemSaga),
        takeEvery(BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST, getRegistryInvitationsContractorsPageSaga),
        takeEvery(BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST, addContractorToRegistryInvitationsSaga),
        takeEvery(BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST, updateRegistryInvitationsContractorSaga),
        takeEvery(BFF_ADD_TASK_ORDER_INVITATIONS_REGISTRY_EXPORT_REQUEST, addTaskOrderInvitationsRegistryExportSaga),
    ]);
}
