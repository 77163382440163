import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const clientDocumentsRegistrySelector = state => state.bff.clientDocumentsRegistry;
export const clientDocumentsRegistryListSelector = createSelector(clientDocumentsRegistrySelector, ({list}) => list);
export const clientDocumentsRegistryListPageDataSelector = createSelector(clientDocumentsRegistrySelector, ({pageData}) => pageData);
export const clientDocumentsRegistryTotalCountSelector = createSelector(clientDocumentsRegistrySelector, ({totalCount}) => totalCount);
export const clientDocumentsRegistryTotalPagesSelector = createSelector(clientDocumentsRegistrySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const clientDocumentsRegistryProgressSelector = createSelector(clientDocumentsRegistrySelector, ({progress}) => progress);
export const clientDocumentsRegistryProgressActionSelector = createSelector(clientDocumentsRegistrySelector, ({progressAction}) => progressAction);
export const clientDocumentsRegistrySearchProgressSelector = createSelector(clientDocumentsRegistrySelector, ({searchProgress}) => searchProgress);
export const getDocumentListFormattedToTaskIdsSelector = createSelector(clientDocumentsRegistrySelector, ({list}) =>
    list.map(value => `${value.clientId}:${value.contractorId}`),
);