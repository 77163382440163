import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import CheckboxAccordionList from "../../../../components/ActualComponents/CheckboxAccordionList";
import Text from "../../../../components/ActualComponents/Text";
import MassConfirm from "../../../../components/MassConfirm";
import OrderListMassTemplatePublication from "../mass-template-publication";

import {useOrderListTemplatePublication} from "../mass-checking/hooks/useTemplatePublication";

import {toastError} from "../../../../utils/toastHelper";
import {getOrderListMassTemplatePublicationCustomDocumentIds} from "../mass-checking/utils/getCustomDocumentIds";
import {getOrderListMassCheckingPublishData} from "../mass-checking/utils/getPublishOrdersData";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {COLOR} from "../../../../constants/color";
import {ORDER_WORK_REPORT_TYPE} from "../../../../constants/finance";
import {ORDER_MASS_ACTION_MESSAGE, ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE} from "../../../../constants/order";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {publishOrders} from "../../../../ducks/bff/orders/orders-registry/actionCreators";
import {
    ordersWarningListSelector,
    progressOrdersMassActionSelector,
} from "../../../../ducks/bff/orders/orders-registry/selectors";
import {orderClearFieldStore} from "../../../../ducks/order";

const formatList = (list) => {
    return list.map(value => {
        const separator = "Внимание!";
        const isExistsSeparator = typeof value.warningBeforePublish === "string" ? value.warningBeforePublish.indexOf(separator) : false;

        if (isExistsSeparator) {
            const split = value.warningBeforePublish.split(separator);
            const messageStart = split[0] || "";
            const messageEnd = split[1] || "";

            return {
                ...value,
                showCheckBox: true,
                name: `№ ${value.orderNum} - ${value.name}`,
                content: (
                    <>
                        <Text level="2">
                            {messageStart}
                        </Text>
                        <Text level="2">
                            <Text
                                color={COLOR.INERT_100}
                                inline
                                level="2"
                            >
                                {separator}
                            </Text>
                            {messageEnd}
                        </Text>
                    </>
                ),
            };
        }

        return {
            ...value,
            showCheckBox: true,
            name: `${value.orderNum} - ${value.name}`,
            content: value.warningBeforePublish,
        };
    });
};

const OrderListWarningOrdersPublication = (props) => {
    const {
        onSuccess,
        clientId,
        onCancel,
    } = props;

    const [selectedList, setSelectedList] = useState([]);
    const [count, setCount] = useState(0);

    const {
        isOpen: isOpenTemplatePublication,
        onOpenTemplatePublication,
        onCloseTemplatePublication,
    } = useOrderListTemplatePublication();
    const {customDocumentTemplatesAvailable} = useSelector(clientCardPropertiesSelector);

    const dispatch = useDispatch();
    const list = useSelector(ordersWarningListSelector);

    const progressMassAction = useSelector(progressOrdersMassActionSelector);

    useEffect(() => {
        if (isEmpty(selectedList)) {
            setSelectedList(formatList(list));
        }
    }, [list]);

    useEffect(() => {
        return () => {
            dispatch(orderClearFieldStore({
                warningOrderList: [],
            }));
        };
    }, []);

    const okSelectedOrders = useMemo(() => {
        return selectedList.filter(value => value.isSelected && Boolean(value.ok));
    }, [selectedList]);

    const customTemplateIds = useMemo(() => {
        return getOrderListMassTemplatePublicationCustomDocumentIds(okSelectedOrders);
    }, [okSelectedOrders]);

    const onConfirm = (submitData = {}) => {
        if (isEmpty(okSelectedOrders)) {
            toastError(ORDER_MASS_ACTION_MESSAGE.PUBLISH_ORDERS.WARNING_NO_SELECTED_ORDERS);

            return;
        }

        const isAnyDraft = okSelectedOrders.find((item) => (item.status === ORDER_STATUS.DRAFT));

        if (
            customDocumentTemplatesAvailable &&
            okSelectedOrders.length &&
            isAnyDraft &&
            !isOpenTemplatePublication
        ) {
            onOpenTemplatePublication();

            return;
        }

        const orders = okSelectedOrders.map(({clientId, orderId, registryId}) => ({
            clientId,
            orderId,
            registryId,
        }));

        dispatch(publishOrders({
            data: getOrderListMassCheckingPublishData({
                submitData,
                customDocumentTemplatesAvailable,
                orders,
            }),
            onSuccess,
        }));
    };

    const onSelectedRows = (list) => {
        setCount(list.filter(value => value.isSelected).length);
        setSelectedList(list);
    };

    return (
        <>
            {
                isOpenTemplatePublication &&
                <OrderListMassTemplatePublication
                    customTemplateIds={customTemplateIds}
                    clientId={clientId}
                    progress={progressMassAction}
                    isNpd={
                        okSelectedOrders
                            .some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ))
                    }
                    isNdfl={
                        okSelectedOrders
                            .some(value => (value.orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL))
                    }
                    onClose={() => {
                        onCancel();
                        onCloseTemplatePublication();
                    }}
                    onSubmit={onConfirm}
                />
            }
            {
                !isOpenTemplatePublication &&
                <MassConfirm
                    loading={progressMassAction}
                    publicationCount={count}
                    title={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE.PUBLICATION_ORDERS_WITH_WARNING.TITLE}
                    text={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE.PUBLICATION_ORDERS_WITH_WARNING.TEXT}
                    publishTooltip={ORDER_MASS_CONFIRM_CONTENT_BY_ACTION_TYPE.PUBLICATION_ORDERS_WITH_WARNING.TOOLTIP}
                    confirmButton="Подтвердить"
                    cancelButton="Отменить"
                    isCheckboxList
                    content={
                        <CheckboxAccordionList
                            onSelectedRows={onSelectedRows}
                            list={selectedList}
                        />
                    }
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />
            }
        </>
    );
};

export default React.memo(OrderListWarningOrdersPublication);