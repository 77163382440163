import * as yup from "yup";

import {removeSnilsMask} from "../../../../../../utils/stringFormat";

import {VALIDATIONS_MESSAGE} from "../../../../../../constants/validationsYup";

export const getContractorAdditionalDocumentsVerificationValidation = () => {
    return yup.object().shape({
        medicalBookNumber: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .min(7, "Минимальная длина строки 7 символов")
            .max(8, "Допустимое количество символов 8"),
        medicalBookIssuedDate: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        fingerprintingDocumentNum: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(50, "Не более 50 символов"),
        fingerprintingDocumentIssuedBy: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(255, "Не более 255 символов"),
        fingerprintingDocumentIssuedDate: yup.string()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .nullable(),
        snilsNum: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .test("snilsNum", "Вы ввели неверный СНИЛС", function (value) {
                const snils = removeSnilsMask(value);

                if (!snils) {
                    return true;
                }

                if (snils.length !== 11) {
                    return this.createError({
                        message: "СНИЛС может состоять только из 11 цифр",
                        path: "snilsNum",
                    });
                }

                let sum = 0;

                for (let i = 0; i < 9; i++) {
                    sum += parseInt(snils[i]) * (9 - i);
                }

                let checkDigit = 0;

                if (sum < 100) {
                    checkDigit = sum;
                } else if (sum > 101) {
                    checkDigit = parseInt(sum % 101);
                    if (checkDigit === 100) {
                        checkDigit = 0;
                    }
                }

                return checkDigit === parseInt(snils.slice(-2));
            }),
        employmentHistoryNum: yup.string()
            .max(50, "Не более 50 символов"),
    });
};