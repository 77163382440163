import {
    CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_ERROR,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST,
    CLIENT_DEPOSIT_REPLENISHMENT_ADD_SUCCESS,
    CLIENT_DEPOSIT_REPLENISHMENT_RETURN_ERROR,
    CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST,
    CLIENT_DEPOSIT_REPLENISHMENT_RETURN_SUCCESS,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST,
    UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    totalCount: 0,
    list: [],
    progress: false,
    progressAction: false,
    totalAddedAmount: 0,
    totalReturnedAmount: 0,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS:
            const {
                results,
                totalCount,
                totalAddedAmount,
                totalReturnedAmount,
            } = payload;

            return {
                ...state,
                progress: false,
                totalCount,
                list: results,
                totalAddedAmount,
                totalReturnedAmount,
            };
        case GET_PAGE_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST:
        case UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_REQUEST:
        case CLIENT_DEPOSIT_REPLENISHMENT_ADD_REQUEST:
        case CLIENT_DEPOSIT_REPLENISHMENT_RETURN_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS:
        case EDIT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR:
        case UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_SUCCESS:
        case UPDATE_COMMENT_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS_ERROR:
        case CLIENT_DEPOSIT_REPLENISHMENT_ADD_SUCCESS:
        case CLIENT_DEPOSIT_REPLENISHMENT_ADD_ERROR:
        case CLIENT_DEPOSIT_REPLENISHMENT_RETURN_SUCCESS:
        case CLIENT_DEPOSIT_REPLENISHMENT_RETURN_ERROR:
            return {
                ...state,
                progressAction: true,
            };
        case CLEAR_FIELDS_CLIENT_DEPOSIT_REPLENISHMENT_AND_REFUNDS:
            return initial;
        default:
            return state;
    }
};