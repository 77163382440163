import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {
    getAvitoActualBusinessAreas,
    getAvitoBillingTypes,
    getAvitoBusinessAreas,
    getAvitoExperiences,
    getAvitoProfessions,
    getAvitoSchedules,
    updateRecruitmentDirectoryStore,
} from "../../../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    getRecruitmentJobBoards,
    updateRecruitmentJobBoardsStore,
} from "../../../../../../ducks/bff/recruitment/jobBoards/actionCreators";
import {getRecruitmentJobBoardsInitialState} from "../../../../../../ducks/bff/recruitment/jobBoards/reducer";
import {
    getVacancyJobBoards,
    updateRecruitmentVacancyStore,
} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";
import {bffRecruitmentVacancyJobBoardsListFiltersSelector} from "../../../../../../ducks/bff/recruitment/vacancy/selectors";

import {SSE_EVENT_TYPE} from "../../../../../../constants/sse";
import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../../constants";

import {
    bffClearFieldsAdvertisementStore,
    getAdvertisement,
} from "../../../../../../ducks/bff/advertisement/actionCreators";
import {
    getExternalJobBoardsInitialState,
    getHeadHunterAreas,
    getHeadHunterDictionaries,
    updateExternalJobBoardsStore,
} from "../../../../../../ducks/externalJobBoards";
import {sseEventDataSelector} from "../../../../../../ducks/serverSentEvents";

const useRecruitmentVacancyJobBoardListFetch = (props) => {
    const {
        clientId,
        vacancyId,
    } = props;

    const dispatch = useDispatch();

    const listInfo = useSelector(bffRecruitmentVacancyJobBoardsListFiltersSelector);
    const event = useSelector(sseEventDataSelector);

    useEffect(() => {
        dispatch(getAvitoProfessions());
        dispatch(getAvitoSchedules());
        dispatch(getAvitoExperiences());
        dispatch(getAvitoBillingTypes());
        dispatch(getAvitoBusinessAreas());
        dispatch(getAvitoActualBusinessAreas());

        dispatch(getRecruitmentJobBoards({clientId}));

        fetchInfo();

        return () => {
            dispatch(updateRecruitmentDirectoryStore({
                avitoProfessionsDict: {},
                avitoSchedulesDict: {},
                avitoExperiencesDict: {},
                avitoBillingTypesDict: {},
                avitoBusinessAreasDict: {},
                avitoActualBusinessAreasDict: {},
            }));
            dispatch(updateRecruitmentVacancyStore({
                jobBoardsList: [],
                jobBoardsProgress: false,
            }));
            dispatch(bffClearFieldsAdvertisementStore({card: {baseModel: {}}}));
            dispatch(updateExternalJobBoardsStore(getExternalJobBoardsInitialState()));
            dispatch(updateRecruitmentJobBoardsStore(getRecruitmentJobBoardsInitialState()));
        };
    }, []);

    useEffect(() => {
        if (event.eventType === SSE_EVENT_TYPE.AVITO_PUBLISHED_STATUS) {
            fetchInfo();
        }
    }, [
        event.eventType,
    ]);

    useEffect(() => {
        const infoNaimixJobBoard = listInfo.find(item => item.jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX) || {};

        if (infoNaimixJobBoard.externalId) {
            fetchNaimixAdvertisement(infoNaimixJobBoard.externalId);
        }

        if (!isEmpty(listInfo.find(item => [RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER, RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU].includes(item.jobBoardType)))) {
            dispatch(getHeadHunterAreas());
            dispatch(getHeadHunterDictionaries());
        }
    }, [listInfo]);

    const fetchInfo = () => {
        dispatch(getVacancyJobBoards({id: vacancyId}));
    };

    const fetchNaimixAdvertisement = (advertId) => {
        dispatch(getAdvertisement({
            clientId,
            advertId,
        }));
    };

    return {
        fetchInfo,
    };
};

export default useRecruitmentVacancyJobBoardListFetch;