import React, {useEffect, useState} from "react";
import Media, {useMedia} from "react-media";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {Counter} from "../../../components/ActualComponents/Counter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import {InfoMark} from "../../../components/ActualComponents/InfoMark";
import Text from "../../../components/ActualComponents/Text";
import DialogList from "../../../components/DialogList";
import NmButton from "../../../components/NmButton";
import {ReactComponent as FilterIcon} from "../../../images/filter_outline_24.svg";
import ChatArea from "./components/area";
import ChatsFilter from "./components/filter";
import ClientChatSendMessage from "./components/send-message";
import {Dimmer, Loader} from "semantic-ui-react";

import {useChatClientFilter} from "./hooks/useFilter";

import bem from "../../../utils/bem";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";

import {ORDER_STATUS} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {CROWD_CHAT_STATUS_FILTER_TYPE} from "../../../constants/crowd/chats";
import {isUserFromNm} from "../../../constants/roles";

import {
    currentChatSelector,
    updateFieldChat,
} from "../../../ducks/chat";

import "./style.sass";

const INITIAL_FILTER = {
    projectIdFilter: "",
    objectIdFilter: "",
    orderIdFilter: "",
    messageStatus: CROWD_CHAT_STATUS_FILTER_TYPE.ALL,
    contractorNameFilter: "",
    contractorPhone: "",
    currentUserIsOrderManager: false,
};

function ChatClientList(props) {
    const {
        match: {
            params: {
                clientId,
                orderId: orderIdFilter,
                chatId: chatIdFilter,
                newChatContractorId,
                contractorId,
            },
            path,
        },
    } = props;

    const [block, element] = bem("chat-client-list");
    const isMobile = useMedia({query: {maxWidth: 767}});
    const isCrowd = path.includes("task-chats");

    const chatInfo = useSelector(currentChatSelector);

    const [filter, setFilter] = useState(INITIAL_FILTER);
    const [isFiltersReset, setIsFiltersReset] = useState(false);
    const [isOpenFilter, setOpenFilter] = useState(false);
    const [loading, setLoading] = useState(false);

    const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);
    const role = ls(USER_ROLE);
    const isNmUser = isUserFromNm(role);
    const {
        chatId,
        orderManagerId,
        isNamemixChat,
        locked,
        orderStatus,
    } = chatInfo;

    const dispatch = useDispatch();

    const {
        filterDto,
        setFilterDto,
    } = useChatClientFilter({
        initFilter: {
            orderIdFilter,
            contractorId,
            messageStatus: isCrowd ? CROWD_CHAT_STATUS_FILTER_TYPE.ALL : undefined,
        },
        isCrowd,
    });

    const updateIsFiltersReset = () => {
        setIsFiltersReset(true);
        setTimeout(() => {
            setIsFiltersReset(false);
        }, 1000);
    };

    const submitFilter = (filter) => {
        updateIsFiltersReset();
        setFilterDto(filter);
    };

    const clearFilter = () => {
        updateIsFiltersReset();
        setFilter(INITIAL_FILTER);
        setFilterDto({});
    };

    const handleChangeFilter = (event, {name, value, checked}) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: checked !== undefined ? checked : value,
        }));
    };

    useEffect(() => {
        if (orderIdFilter) {
            setLoading(true);
            setOpenFilter(true);

            setFilter(prevFilter => ({
                ...prevFilter,
                orderIdFilter,
            }));
        }

        return () => {
            dispatch(updateFieldChat({
                currentChat: {},
                messageList: [],
            }));
        };
    }, []);

    const toggleFilter = () => {
        setOpenFilter(prevState => !prevState);
    };

    const getFilterButton = () => {
        const button = (
            <NmButton
                onlyIcon
                color="grey"
                icon={<FilterIcon />}
                size="xl"
                onClick={toggleFilter}
            />
        );

        return (
            <Media query={{minWidth: 768}}>
                {button}
            </Media>
        );
    };

    const getIsShowMessageBlock = () => {
        if (isNamemixChat || chatInfo.userHasAccessToSendMessage) {
            return true;
        }

        if (isCrowd) {
            return chatId && !locked;
        }

        return (
            chatId &&
            !locked &&
            orderManagerId === currentClientUserId &&
            orderStatus !== ORDER_STATUS.CLOSED
        );
    };

    return (
        <div className={block({padding: !isNmUser && !isMobile})}>
            {
                loading &&
                <Dimmer
                    active
                    inverted
                >
                    <Loader />
                </Dimmer>
            }
            <div className={element("dialogs", {displayNone: isMobile && !isEmpty(chatInfo)})}>
                <div className={element("filter", {adminPadding: isNmUser})}>
                    <div className={element("header")}>
                        <div className="flex-column">
                            <Text.Title
                                level="1"
                            >
                                {isCrowd ? "Чаты заданий" : "Чаты"}
                            </Text.Title>
                            {
                                !isCrowd &&
                                <div className="d-flex relative">
                                    <Text
                                        color={COLOR.SECONDARY_70}
                                        type="sub"
                                        className="me-1"
                                    >
                                    Индикатор новых сообщений
                                    </Text>
                                    <HelpTooltip
                                        type="light"
                                        position="bottom-left"
                                        text={
                                            <>
                                                <InfoMark
                                                    icon={
                                                        <Counter
                                                            count={1}
                                                            className="me-2"
                                                        />
                                                    }
                                                    text="Красный индикатор - цвет новых сообщений, где вы назначены ответственным"
                                                    className="mb-2"
                                                />
                                                <InfoMark
                                                    icon={
                                                        <Counter
                                                            count={1}
                                                            background={COLOR.SECONDARY_45}
                                                            className="me-2"
                                                        />
                                                    }
                                                    text="Серый индикатор - цвет новых сообщений, где вы не являетесь ответственным"
                                                />
                                            </>
                                        }
                                    />
                                </div>
                            }
                        </div>
                        <div>
                            {getFilterButton()}
                        </div>
                    </div>
                    {
                        isOpenFilter &&
                        <ChatsFilter
                            isCrowd={isCrowd}
                            actionsBottomPosition
                            clientId={clientId}
                            filter={filter}
                            submitFilter={submitFilter}
                            clearFilter={clearFilter}
                            onChange={handleChangeFilter}
                            isFiltersReset={isFiltersReset}
                        />
                    }
                </div>
                <DialogList
                    filter={filterDto}
                    clientId={clientId}
                    isOpenFilter={isOpenFilter}
                    isFiltersReset={isFiltersReset}
                    chatIdFilter={chatIdFilter}
                    contractorIdFilter={contractorId}
                    newChatContractorId={newChatContractorId}
                    orderIdFilter={orderIdFilter}
                    setLoading={setLoading}
                    isCrowd={isCrowd}
                />
            </div>
            <div className={element("messages", {displayNone: isMobile && isEmpty(chatInfo)})}>
                <ChatArea
                    className={element("area")}
                    isCrowd={isCrowd}
                    mobile={isMobile}
                />
                {
                    getIsShowMessageBlock() &&
                    <ClientChatSendMessage
                        clientId={clientId}
                        isCrowd={isCrowd}
                    />
                }
            </div>
        </div>
    );
}

export default ChatClientList;