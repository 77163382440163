import {all, put, takeEvery} from "redux-saga/effects";

import {getInfoContractorCard} from "../actionCreators";
import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST,
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS,
    CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
    CONTRACTOR_TAX_STATUS_REFRESH_REQUEST,
    CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS,
    CONTRACTOR_UPDATE_ERROR,
    CONTRACTOR_UPDATE_REQUEST,
    CONTRACTOR_UPDATE_SUCCESS,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS,
    GET_CONTRACTOR_BANK_STATUS_ERROR,
    GET_CONTRACTOR_BANK_STATUS_REQUEST,
    GET_CONTRACTOR_BANK_STATUS_SUCCESS,
    GET_INN_BY_PERSONAL_INFO_ERROR,
    GET_INN_BY_PERSONAL_INFO_REQUEST,
    GET_INN_BY_PERSONAL_INFO_SUCCESS,
    GET_PENDING_PERSONAL_DATA_ERROR,
    GET_PENDING_PERSONAL_DATA_REQUEST,
    GET_PENDING_PERSONAL_DATA_SUCCESS,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS,
    GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
    GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS,
    UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR,
    UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST,
    UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS,
} from "./actions";

import {getFiasIdByAddressType} from "../../../../utils/dadata";
import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

import {CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE} from "../../../../constants/contractor";
import {CONTRACTOR_MESSAGE} from "../../../../constants/messages";

const admController = "adm/contractors/contractors-registry/contractor-card/personal-data";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: admController,
        client: "/client-adm/contractors/contractor-card/personal-data",
    });
};

const updateContractorIndividualEntrepreneurLegalInfo = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${admController}/individual-entrepreneur/data/legal-info/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_ERROR,
            payload: error,
        });
    }
};

const updateContractorIndividualEntrepreneurStatus = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${admController}/individual-entrepreneur/data/legal-info-and-status/retrieve-and-save`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_ERROR,
            payload: error,
        });
    }
};

const deleteContractorIndividualEntrepreneurData = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.delete(`${admController}/individual-entrepreneur/data/delete`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_ERROR,
            payload: error,
        });
    }
};

const updateContractorIndividualEntrepreneurBankRequisites = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${admController}/individual-entrepreneur/data/bank-requisites-and-email/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_ERROR,
            payload: error,
        });
    }
};

const getContractorPendingVerificationWizard = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;
        const result = yield request.bff.post(`${admController}/verification-wizard/steps/info/list`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_SUCCESS, payload: result});
    } catch (error) {
        yield put({type: CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_ERROR, payload: error});
    }
};

const refreshContractorTaxStatus = function* ({payload}) {
    try {
        const {
            onSuccess,
            getResult,
            ...reqData
        } = payload;
        const result = yield request.bff.post(`${admController}/tax-status/refresh`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_TAX_STATUS_REFRESH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_TAX_STATUS_REFRESH_ERROR,
            payload: error,
        });
    }
};

const getInnByPersonalInfo = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;
        const result = yield request.bff.post(`${admController}/getInnByPersonalInfo`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: GET_INN_BY_PERSONAL_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_INN_BY_PERSONAL_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_INN_BY_PERSONAL_INFO_ERROR,
            payload: error,
        });
    }
};

const endMyCheckOnContractorPersonalDataStatus = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.bff.post(`${admController}/endMyCheckOnContractorPersonalDataStatus?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
            payload: error,
        });
    }
};

const endCheckOnContractorPersonalDataStatus = function* ({payload}) {
    try {
        const {
            onSuccess,
            contractorId,
        } = payload;
        const result = yield request.bff.post(`${admController}/endCheckOnContractorPersonalDataStatus?contractorId=${contractorId}`);

        const {errorMessage} = result;

        if (errorMessage) {
            yield put({
                type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_ERROR,
            payload: error,
        });
    }
};

const updateContractorBankRequisites = function* ({payload}) {
    try {
        const {
            contractorId,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${admController}/bankRequisites/${contractorId}/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CONTRACTOR_BANK_REQUISITES_ERROR,
            payload: error,
        });
    }
};

const updateDataContractorAdditionalDocument = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${admController}/additional-documents/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_ERROR,
            payload: error,
        });
    }
};

const getContractorAdditionalDocumentAggregation = function* ({payload}) {
    try {
        const {
            contractorId,
            getResult = () => {},
        } = payload;
        const result = yield request.bff.get(`${getController()}/additional-documents/get/aggregation`, {params: {contractorId}});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_ERROR,
        });
    }
};

const getPendingPersonalData = function* ({payload}) {
    try {
        const {
            contractorId,
            getResult,
        } = payload;

        const result = yield request.bff.get(`${admController}/pending/get`, {params: {contractorId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PENDING_PERSONAL_DATA_ERROR,
                payload: errorMessage,
            });
            
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: GET_PENDING_PERSONAL_DATA_SUCCESS, payload: result});

    } catch (error) {
        yield put({
            type: GET_PENDING_PERSONAL_DATA_ERROR,
        });
    }
};

const getPersonalDataPendingComparison = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${admController}/comparison`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_SUCCESS,
            payload: result,
        });

    } catch ({message}) {
        toastError(message);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_ERROR,
            payload: message,
        });
    }
};

const getPersonalDataPendingComparisonAvailability = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...params
        } = payload;

        const result = yield request.bff.get(`${admController}/comparison/availability`, {params});
        const {errorCode} = result;

        if (errorCode) {
            onError && onError(result);

            yield put({
                type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_SUCCESS,
            payload: result,
        });

    } catch ({message}) {
        toastError(message);

        yield put({
            type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_ERROR,
            payload: message,
        });
    }
};

const getContractorBankStatus = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/bank-status/get`, {params: payload});

        yield put({
            type: GET_CONTRACTOR_BANK_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CONTRACTOR_BANK_STATUS_ERROR,
            payload: error,
        });
    }
};

const updateContractorSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            onSuccess,
            onError,
            isGetAfter = true,
            successText,
            ...data
        } = payload;

        const {
            contractorId,
            workAddressFiasId,
            workAddressInfo,
            departureAddressesFiasIds,
            departureAddressesInfo,
        } = data;

        const dataFormatted = {
            ...data,
            workAddressFiasId: workAddressFiasId ? workAddressFiasId : (workAddressInfo ? getFiasIdByAddressType(workAddressInfo) : null),
            departureAddressesFiasIds: departureAddressesFiasIds ? departureAddressesFiasIds : (departureAddressesInfo ? Object.keys(departureAddressesInfo) : null),
            departureAddressesInfo: null,
            workAddressInfo: null,
        };

        const {
            errorMessage,
            errorCode,
            invalidFieldNames,
        } = yield request.bff.post(`${admController}/update`, dataFormatted);

        if (handleResponse) {
            handleResponse(errorMessage, errorCode);
        }

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError({errorCode, invalidFieldNames});
            }

            yield put({
                type: CONTRACTOR_UPDATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(successText || CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_SUCCESS_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_SUCCESS,
        });

        if (onSuccess) {
            onSuccess();
        }

        if (isGetAfter) {
            yield put(getInfoContractorCard(contractorId));
        }
    } catch (error) {
        toastError(CONTRACTOR_MESSAGE.CONTRACTOR_UPDATE_ERROR_TEXT);

        yield put({
            type: CONTRACTOR_UPDATE_ERROR,
            payload: error,
        });
    }
};

const updateContractorRegistrationStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
        } = payload;
        const result = yield request.bff.post(`${admController}/registration-status/update`, payload);
        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            errorCode !== CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR_CODE.SEMI_REQUIRED_PERSONAL_DATA_NOT_FILLED &&
            toastError(errorMessage);

            if (onError) {
                onError(result);
            }

            yield put({
                type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST, updateContractorIndividualEntrepreneurLegalInfo),
        takeEvery(UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST, updateContractorIndividualEntrepreneurStatus),
        takeEvery(DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST, deleteContractorIndividualEntrepreneurData),
        takeEvery(UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST, updateContractorIndividualEntrepreneurBankRequisites),
        takeEvery(CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST, getContractorPendingVerificationWizard),
        takeEvery(CONTRACTOR_TAX_STATUS_REFRESH_REQUEST, refreshContractorTaxStatus),
        takeEvery(GET_INN_BY_PERSONAL_INFO_REQUEST, getInnByPersonalInfo),
        takeEvery(CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST, endMyCheckOnContractorPersonalDataStatus),
        takeEvery(CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST, endCheckOnContractorPersonalDataStatus),
        takeEvery(UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST, updateContractorBankRequisites),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST, updateDataContractorAdditionalDocument),
        takeEvery(CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST, getContractorAdditionalDocumentAggregation),
        takeEvery(GET_PENDING_PERSONAL_DATA_REQUEST, getPendingPersonalData),
        takeEvery(GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST, getPersonalDataPendingComparison),
        takeEvery(GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST, getPersonalDataPendingComparisonAvailability),
        takeEvery(GET_CONTRACTOR_BANK_STATUS_REQUEST, getContractorBankStatus),
        takeEvery(CONTRACTOR_UPDATE_REQUEST, updateContractorSaga),
        takeEvery(CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST, updateContractorRegistrationStatusSaga),
    ]);
}