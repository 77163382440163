import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty,isEqual} from "lodash";

import {SUB_PAGE_ADVERTISEMENT} from "../index";

import {getContractorLastLoginFilters} from "../../../../utils/contractors";

import {
    getAdvertisement,
    getAdvertisementViewPage,
} from "../../../../ducks/bff/advertisement/actionCreators";
import {bffClearFieldsAdvertisementStore} from "../../../../ducks/bff/advertisement/actionCreators";

export function useAdvertisementFetchData(params) {
    const {
        initFilter,
        filter = initFilter,
        clientId,
        advertisementId,
        pageSize,
        pageNum,
        subPage,
    } = params;

    const pathname = useSelector(state => state.router.location.pathname);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!subPage) {
            return;
        }

        fetchList();
    }, [
        pageSize,
        pageNum,
        filter,
        subPage,
    ]);

    useEffect(() => {
        return () => {
            dispatch(bffClearFieldsAdvertisementStore({
                contractorList: [],
                contractorsTotalCount: 0,
                card: {
                    baseModel: {},
                },
            }));
        };
    }, []);

    const fetchList = () => {
        const {
            lastLoginFilter,
            ...otherFilters
        } = filter || {};
        const lastLoginFilters = getContractorLastLoginFilters(lastLoginFilter);

        const requestData = {
            pageNum,
            pageSize,
            clientIdFilter: clientId,
            advertIdFilter: advertisementId,
            interestFilter: pathname.includes(SUB_PAGE_ADVERTISEMENT.INTERESTED.LINK),
            hiredFilter: pathname.includes(SUB_PAGE_ADVERTISEMENT.HIRED.LINK),
            paymentFilter: pathname.includes(SUB_PAGE_ADVERTISEMENT.RECEIVED_PAYMENTS.LINK),
            contractorPageCriteria: (!isEmpty(filter) && !isEqual(filter, initFilter)) ? {
                ...lastLoginFilters,
                ...otherFilters,
            } : undefined,
            getResult: () => {},
        };

        dispatch(getAdvertisementViewPage(requestData));
    };

    const fetchCard = () => {
        dispatch(getAdvertisement({
            clientId,
            advertId: advertisementId,
        }));
    };

    return {
        fetchCard,
        fetchList,
    };
}