import React, {ReactNode} from "react";

import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmHintButton {
    onClick: () => void,
    closeButtonText: string,
    openButtonText: string,
    isShown: boolean,
    className?: string
    buttonIcon?: ReactNode,
    actions?: ReactNode,
    customButton?: ReactNode,
    color?: "blue" | "primary" | "gray" | "black",
    chevronSize?: number,
    chevronColor?: string,
    fullWidth?: boolean,
    isActions?: boolean
}

export const NmHintButton = (props: INmHintButton) => {
    const {
        onClick,
        closeButtonText,
        openButtonText,
        isShown,
        className = "",
        buttonIcon,
        color = "primary",
        customButton,
        chevronSize = 18,
        fullWidth = false,
        chevronColor,
        actions,
        isActions = false,
    } = props;

    const [block, element] = bem("nm-hint-button", className);

    const getText = () => {
        if (customButton) {
            return customButton;
        }

        return isShown ? closeButtonText : openButtonText;
    };

    return (
        <div
            className={block({color})}
            onClick={onClick}
        >
            {buttonIcon}
            {getText()}
            <div
                className={element("actions", {
                    fullWidth,
                })}
            >
                {actions}
                <IconChevron
                    width={chevronSize}
                    height={chevronSize}
                    className={element("icon", {
                        open: isShown,
                        isActions,
                    })}
                    color={chevronColor}
                />
            </div>
        </div>
    );
};

export default NmHintButton;