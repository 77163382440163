import {
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST,
    BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST,
} from "./actions";

export function getOrderGroupById(payload) {
    return {
        type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST,
        payload,
    };
}
export function uploadOrderList(payload) {
    return {
        type: BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST,
        payload,
    };
}

export function downloadOrderGroups(payload) {
    return {
        type: BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST,
        payload,
    };
}

export function addOrdersToGroup(payload) {
    return {
        type: BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST,
        payload,
    };
}

export function deleteOrdersFromGroup(payload) {
    return {
        type: BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST,
        payload,
    };
}