import React from "react";
import {Route, Switch} from "react-router";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import OrderActRegistry from "../act-registry";
import ActRegistryCard from "../act-registry/card";
import OrderCard from "../order-card";
import AddOrdersToGroupPage from "../order-groups/add-orders-to-group-page";
import OrderGroups from "../order-groups/list";
import OrderList from "../order-list";
import OrderTemplates from "../order-templates";
import RegistryInvitationsCard from "../registry-invitations/card";
import RegistryInvitationsList from "../registry-invitations/list";

import {
    LINK_CLIENT_ADD_ORDERS_GROUP_PAGE,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST,
    LINK_CLIENT_ORDER_GROUPS_CARD,
    LINK_CLIENT_ORDER_GROUPS_LIST,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST,
    LINK_CLIENT_ORDER_TEMPLATES_LIST,
    LINK_ORDER_CARD,
} from "../../../constants/links";

function OrderRoute() {
    return (
        <Switch>
            <Route
                exact
                path={LINK_CLIENT_ORDER_LIST}
                component={OrderList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_TEMPLATES_LIST}
                component={OrderTemplates}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_GROUPS_LIST}
                component={OrderGroups}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_GROUPS_CARD}
                component={OrderList}
            />
            <CheckRoleRoute
                exact
                path={LINK_ORDER_CARD}
                component={OrderCard}
            />
            <Route
                exact
                path={LINK_CLIENT_ADD_ORDERS_GROUP_PAGE}
                component={AddOrdersToGroupPage}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST}
                component={RegistryInvitationsList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_ACT_REGISTRY_LIST}
                component={OrderActRegistry}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD}
                component={RegistryInvitationsCard}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_ORDER_ACT_REGISTRY_CARD}
                component={ActRegistryCard}
            />
        </Switch>
    );
}

export default OrderRoute;