import {useState} from "react";
import {useDispatch} from "react-redux";

import {handleFormString, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {
    addEdmSubdivision,
    updateEdmSubdivision,
} from "../../../../../ducks/bff/clients/edo/directory/subdivisions/actionCreators";

const defaultForm = {
    value: "",
    subdivisionId: "",
};

export function useStructuralUtilsEditModal(clientId, fetchList) {
    const [isShowModal, toggleModal] = useState(false);
    const [formError, setFormError] = useState({});
    const [editForm, setEditForm] = useState({...defaultForm});

    const dispatch = useDispatch();

    function validateForm() {
        const {value} = editForm;

        if (isNullOrWhitespace(value)) {
            setFormError({value: "Обязательное поле"});
            return false;
        }

        if (value.length > 255) {
            setFormError({value: "Максимальная  длина строки 255 символов"});
            return false;
        }

        setFormError({});

        return true;
    }

    const submit = () => {
        const {subdivisionId, value} = editForm;

        const method = subdivisionId ? updateEdmSubdivision : addEdmSubdivision;

        if(!validateForm()){
            return;
        }

        dispatch(method({
            subdivisionId,
            value: handleFormString(value),
            clientId,
            onSuccess: () => {
                cancelEditModal();
                fetchList();
            },
        }));
    };

    const onChangeForm = (e, {name, value}) => {
        setEditForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openEditModal = (data = defaultForm) => {
        toggleModal(true);
        setEditForm({...data});
    };

    const cancelEditModal = () => {
        toggleModal(false);
        setEditForm({...defaultForm});
    };

    return {
        submit,
        cancelEditModal,
        openEditModal,
        onChangeForm,
        isShowModal,
        editForm,
        formError,
    };
}