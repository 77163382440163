import {
    EDM_SUBDIVISION_COMMON_COUNT_ERROR,
    EDM_SUBDIVISION_COMMON_COUNT_SUCCESS,
    EDM_SUBDIVISION_LIST_ERROR,
    EDM_SUBDIVISION_LIST_REQUEST,
    EDM_SUBDIVISION_LIST_SUCCESS,
} from "./actions";

const initial = {
    list: [],
    totalCount: 0,
    pageData: {
        pageSize: 25,
        pageNum: 1,
    },
    commonCount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EDM_SUBDIVISION_LIST_REQUEST:
            const {getResult, ...pageData} = payload;
            return {
                ...state,
                pageData,
            };
        case EDM_SUBDIVISION_LIST_SUCCESS:
            const {
                results = [],
                totalCount = 0,
            } = payload;

            return {
                ...state,
                list: results,
                totalCount,
            };
        case EDM_SUBDIVISION_COMMON_COUNT_SUCCESS:
            return {
                ...state,
                commonCount: payload,
            };
        case EDM_SUBDIVISION_COMMON_COUNT_ERROR:
        case EDM_SUBDIVISION_LIST_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};