import {
    DELETE_EMPTY_RECORD_ERROR,
    DELETE_EMPTY_RECORD_REQUEST,
    DELETE_EMPTY_RECORD_SUCCESS,
    GET_AUTH_MAINTENANCE_NOTICE_SUCCESS,
    GET_EMPTY_RECORD_ERROR,
    GET_EMPTY_RECORD_REQUEST,
    GET_EMPTY_RECORD_SUCCESS,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST,
    SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST,
    SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_ERROR,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_ERROR,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST,
    SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_SUCCESS,
    SYSTEM_SETTING_SET_ERROR,
    SYSTEM_SETTING_SET_REQUEST,
    SYSTEM_SETTING_SET_SUCCESS,
    UPDATE_SETTINGS_SERVICE_STORE,
} from "./actions";

const initial = {
    progress: false,
    maintenanceNotice: {},
    emptyRecordProgress: false,
    progressCorrection: false,
    ticketSettings: {},
    notifications: {},
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_GET_SUCCESS:
            const {type} = payload;

            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [type]: payload,
                },
            };
        case SYSTEM_SETTING_SET_REQUEST:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_REQUEST:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_REQUEST:
        case SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_REQUEST:
            return {
                ...state,
                progressCorrection: true,
            };
        case SYSTEM_SETTING_SET_SUCCESS:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_SUCCESS:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_SUCCESS:
        case SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_SUCCESS:
            return {
                ...state,
                progressCorrection: false,
            };
        case SYSTEM_SETTING_SET_ERROR:
        case SYSTEM_SETTING_EXPASOFT_COLLING_SET_ERROR:
        case SYSTEM_SETTING_MAINTENANCE_NOTICE_SET_ERROR:
            return {
                ...state,
                progress: false,
            };
        case SYSTEM_SETTING_NPD_DEPOSIT_CORRECT_ERROR:
        case SYSTEM_SETTING_NDFL_DEPOSIT_CORRECT_ERROR:
            return {
                ...state,
                progressCorrection: false,
                error: payload,
            };
        case GET_AUTH_MAINTENANCE_NOTICE_SUCCESS:
            return {
                ...state,
                maintenanceNotice: payload,
            };
        case GET_EMPTY_RECORD_REQUEST:
        case DELETE_EMPTY_RECORD_REQUEST:
            return {
                ...state,
                emptyRecordProgress: true,
            };
        case GET_EMPTY_RECORD_SUCCESS:
        case DELETE_EMPTY_RECORD_SUCCESS:
            return {
                ...state,
                emptyRecordProgress: false,
            };
        case GET_EMPTY_RECORD_ERROR:
        case DELETE_EMPTY_RECORD_ERROR:
            return {
                ...state,
                emptyRecordProgress: false,
            };
        case UPDATE_SETTINGS_SERVICE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};