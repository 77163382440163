import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import Box from "../../../../components/ActualComponents/Box";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import AgencyContractNew from "../edit";
import {AgencyContractFileLink} from "../file-link";

import dateFormat, {convertUtcToLocal} from "../../../../utils/dateFormat";

import {
    getActualVersion,
    getContractHistoryFile,
} from "../../../../ducks/bff/clients/documents/contract-agency/actionCreators";
import {getActualVersionSelector} from "../../../../ducks/bff/clients/documents/contract-agency/selectors";

import "./style.sass";

class AgencyContractActual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            actualVersion: {},
        };
    }

    componentDidMount() {
        const {
            getActualVersion,
            clientId,
        } = this.props;

        getActualVersion({
            clientId,
        });
    }

    openEditForm = () => {
        this.setState({isEdit: true});
    };

    closeEditForm = () => {
        this.setState({isEdit: false});
    };

    renderUserFullName(item) {
        const {
            creatorClientUserId,
            creatorClientUserName,
        } = item;

        if (creatorClientUserId) {
            return creatorClientUserName || "";
        }

        return "";
    }

    downloadFile(data) {
        const {getFile} = this.props;
        getFile(data);
    }

    renderActualFileNames(actual) {
        const {
            fileNames = [],
            contractHistoryId,
        } = actual;

        return fileNames.map((fileName, index) => {
            return (
                <AgencyContractFileLink
                    key={index}
                    onClick={() => {
                        this.downloadFile({
                            contractHistoryId,
                            fileName,
                            isDownload: !!fileName.split(".").find(str => str !== "docx" && str !== "odt" && str !== "doc"),
                        });
                    }}
                    isLastElement={index === fileNames.length - 1}
                    fileName={fileName}
                />
            );
        });
    }

    render() {
        const {
            actualVersion,
            t,
            isAccessEdit,
        } = this.props;

        const {isEdit} = this.state;

        if (!actualVersion.createDatetime) {
            return null;
        }

        return (
            <Box
                theme="page"
                title={t("agency-contract-actual.title")}
                isEdit={isAccessEdit}
                onClickEdit={this.openEditForm}
            >
                {
                    isEdit &&
                    <AgencyContractNew
                        isEdit
                        onCloseModel={this.closeEditForm}
                        contractHistoryId={actualVersion.contractHistoryId}
                    />
                }
                <NmLabelText
                    type="page"
                    label={t("agency-contract-actual.header-column-create-datetime")}
                    className="flex-column mb-2"
                    text={dateFormat(convertUtcToLocal(actualVersion.createDatetime))}
                />
                <NmLabelText
                    type="page"
                    label={t("agency-contract-actual.header-column-full-name-admin")}
                    className="flex-column mb-2"
                    text={this.renderUserFullName(actualVersion)}
                />
                <NmLabelText
                    type="page"
                    label={t("agency-contract-actual.header-column-documents")}
                    className="flex-column"
                    text={this.renderActualFileNames(actualVersion)}
                    noWrap={false}
                    wrapped
                />
            </Box>
        );
    }
}

export default connect(
    state => ({
        actualVersion: getActualVersionSelector(state),
    }),
    {
        getActualVersion,
        getFile: getContractHistoryFile,
    },
)(withTranslation()(AgencyContractActual));
