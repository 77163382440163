import React, {useMemo} from "react";
import {Route, Switch} from "react-router-dom";

import Tabs from "../../components/ActualComponents/Tabs";
import {CheckRoleRoute} from "../../components/CheckRoleRoute";
import NmPage from "../../components/NmPage";
import {NmPageHeader} from "../../components/NmPageHeader";
import SmartLink from "../marketing/smart-link";
import {AdminMailingsList} from "./mailings-list";

import {getUserRestrictions, getUserRole} from "../../utils/access";
import {isAccessByRestrictions} from "../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {
    LINK_MAILINGS_LIST,
    LINK_MARKETING_BUILDER,
} from "../../constants/links";
import {ADMIN, NM_MANAGER} from "../../constants/roles";

const Marketing = () => {
    const currentUserRestrictions = getUserRestrictions();
    const role = getUserRole();

    const tabs = useMemo(() => {
        return [
            {
                name: "Рассылка уведомлений",
                link: LINK_MAILINGS_LIST,
            },
            {
                name: "Конструктор ссылок",
                link: LINK_MARKETING_BUILDER,
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSmartLink,
                ]),
            },
        ];
    }, [currentUserRestrictions]);

    return (
        <NmPage
            header={
                <NmPageHeader text="Маркетинг" />
            }
            subHeader={
                [ADMIN, NM_MANAGER].includes(role) &&
                <Tabs panes={tabs} />
            }
        >
            <Switch>
                <Route
                    exact
                    path={LINK_MAILINGS_LIST}
                    component={AdminMailingsList}
                />
                <CheckRoleRoute
                    exact
                    path={LINK_MARKETING_BUILDER}
                    component={SmartLink}
                />
            </Switch>
        </NmPage>
    );
};

export default Marketing;