import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {debounce} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import {NmPageCardHeader} from "../../../components/ActualComponents/NmPageCardHeader";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import {ReactComponent as FileDownloadIcon} from "../../../images/file_download.svg";
import {Dimmer, Loader} from "semantic-ui-react";

import dateFormat, {convertUtcToLocal, formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {downloadStrapiFile, getHandlersOneS, mapHandlersOneS} from "../../../utils/strapiService";
import {getFullName} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";
import validate from "../../../utils/validate";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {UPLOAD_1C_FIELD_NAME} from "../../../constants/clientList";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {documentType1c, documentType1cAll} from "../../../constants/documentType";
import {FINANCE_EXPORT_PAGE_TYPE, formatFileDict, UPLOAD_STATUS} from "../../../constants/financeExport";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    NM_MANAGER,
    NM_OPERATOR,
    PROJECT_MANAGER,
} from "../../../constants/roles";
import {document1cTypeOptions} from "../../../constants/selectOptions";
import {upload1CRule} from "../../../constants/validationRules";

import {
    addClientReportOneC,
    cancelClientReportOneC,
    clearStoreClientReportsOneC,
    getPageClientReportsOneC,
    getTasksProgressClientReportsOneC,
} from "../../../ducks/bff/clients/reports/one-c/actionCreators";
import {
    bffClientCardReportsOneCListSelector,
    bffClientCardReportsOneCProgressExportDataSelector,
    bffClientCardReportsOneCProgressSelector,
    bffClientCardReportsOneCTaskIdsSelector,
    bffClientCardReportsOneCTotalCountSelector,
    bffClientCardReportsOneCTotalPagesSelector,
} from "../../../ducks/bff/clients/reports/one-c/selectors";
import {downloadDocument, getFileLink} from "../../../ducks/documents";

import PropTypes from "prop-types";

import "./style.sass";

class ClientFinanceUpload extends Component {
    static propTypes = {
        list: PropTypes.array,
        totalPages: PropTypes.number,
        totalCount: PropTypes.number,
        progress: PropTypes.bool,
    };

    static defaultProps = {
        list: [],
        totalPages: 0,
        totalCount: 0,
        progress: false,
    };

    constructor(props) {
        super(props);
        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            pageSizes: [25, 50, 100],
            formData: {},
            errorForm: {},
            openConfirmWindow: false,
            confirmData: {},
            isHandlesPage: false,
            handlersList: [],
        };

        this.clientId = clientId;

        this.role = ls(USER_ROLE);
        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

        this.isEditable = [CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT, ADMIN, NM_MANAGER].includes(this.role);
    }

    componentDidMount() {
        this.setState(prevState => ({
            ...prevState,
        }), this.fetchList);

        async function fetchHandlersData() {
            const list = await getHandlersOneS();

            return mapHandlersOneS(list);
        }

        fetchHandlersData().then(handlersList => {
            this.setState(prevState => ({
                ...prevState,
                handlersList,
            }));
        });

        this.debounceFetchFinanceExportProgress = debounce(this.fetchFinanceExportProgress, 3000);
    }

    componentDidUpdate(prevProps) {
        const {
            taskInProgressIds,
        } = this.props;

        if (taskInProgressIds.length !== 0) {
            this.debounceFetchFinanceExportProgress();
        }
    }

    componentWillUnmount() {
        const {
            clearStoreClientReportsOneC,
        } = this.props;

        clearStoreClientReportsOneC();
        this.debounceFetchFinanceExportProgress.cancel();
    }

    get isAccessActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClient1c,
        ]);
    }

    fetchFinanceExportProgress = () => {
        const {
            getTasksProgressClientReportsOneC,
            taskInProgressIds,
        } = this.props;

        if (taskInProgressIds.length === 0) {
            return;
        }
        getTasksProgressClientReportsOneC({
            reqData: taskInProgressIds,
            handleResponse: this.fetchList,
        });
    };

    fetchList = () => {
        const {getPageClientReportsOneC} = this.props;
        const {
            pageNum,
            pageSize,
        } = this.state;

        const requestData = {
            clientId: this.clientId,
            sourceType: FINANCE_EXPORT_PAGE_TYPE.CLIENT_1C,
            pageNum,
            pageSize,
        };

        getPageClientReportsOneC(requestData);
    };

    handleDownload = (item) => {
        const {link} = item;
        const {
            getFileLink,
            downloadDocument,
        } = this.props;

        getFileLink({
            downloadLink: link,
            getResult: ({url, fileName}) => {
                downloadDocument({
                    isDownload: true,
                    downloadLink: url,
                    fileName,
                });
            },
        });
    };

    cancelExport = (exportId) => {
        const {cancelClientReportOneC} = this.props;

        cancelClientReportOneC({
            exportId,
            onSuccess: this.fetchList,
        });
    };

    getVisibleDownload({uploadStatus, link, clientUserId}) {
        const isSuccessExport = uploadStatus === UPLOAD_STATUS.SUCCESSFULLY.key && Boolean(link);
        if (NM_OPERATOR === this.role) {
            return clientUserId === this.currentClientUserId && isSuccessExport;
        }
        return isSuccessExport;
    }

    renderActions(data) {
        if (!this.isAccessActions) {
            return null;
        }

        const {
            uploadStatus,
            clientUserId,
            financeExportId,
        } = data;

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => this.handleDownload(data),
                        color: "light-green",
                        children: "Скачать",
                    },
                    visible: [CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT, ADMIN, NM_MANAGER].includes(this.role) && this.getVisibleDownload(data),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.setState({
                                openConfirmWindow: true,
                                confirmData: {
                                    content: "Вы уверены, что хотите отменить формирование документа?",
                                    onConfirm: () => this.cancelExport(financeExportId),
                                    confirmButton: "Да",
                                    cancelButton: "Нет",
                                },
                            });
                        },
                        color: "grey",
                        children: "Отменить",
                    },
                    visible: uploadStatus === UPLOAD_STATUS.INITIALIZATION.key && ([ADMIN].includes(this.role) || clientUserId === this.currentClientUserId),
                },
            ],
        };
    }

    renderStatus = ({uploadStatus, taskId}) => {
        const {progressExportData} = this.props;

        const task = progressExportData.find(item => item.id === taskId);
        const progress = task?.progress ? `${JSON.parse(task.progress)?.percent || 0}%` : "";

        const text = uploadStatus === UPLOAD_STATUS.INITIALIZATION.key && progressExportData ?
            `${UPLOAD_STATUS[uploadStatus]?.text} ${progress}`
            : UPLOAD_STATUS[uploadStatus]?.text || uploadStatus;

        return (
            <NmBadge
                noWrap
                mod={UPLOAD_STATUS[uploadStatus]?.mod || "gray"}
                text={text}
            />
        );
    };

    getRows() {
        const {list} = this.props;

        return list.map(item => {
            const {
                createDatetime,
                uploadType,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        checkbox
                        alignItems="flex-end"
                        secondaryHeaderStatus={this.renderStatus(item)}
                        secondaryHeader={`Дата создания ${formatLocalDate(createDatetime, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            {
                                label: "Дата начала периода",
                                text: dateFormat(convertUtcToLocal(item.fromDate), "dd.MM.yyyy"),
                            },
                            {label: "Дата окончания периода", text: dateFormat(item.toDate, "dd.MM.yyyy")},
                            {label: "Исполнитель", text: getFullName(item.lastName, item.firstName, item.patronymic)},
                            {
                                label: "Тип выгрузки",
                                text: documentType1cAll[uploadType] ? documentType1cAll[uploadType].text : null,
                            },
                        ]}
                        classNameMainContent="col-16 col-xxl-6"
                        mediaControls={this.renderActions(item)}
                        isFixedActions
                    />
                ),
            };
        });
    };

    handleChange = (e, {value, name}) => {
        this.setState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;
        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    isValidForm = (formData) => {
        const {t} = this.props;
        const errorForm = validate(formData, upload1CRule, "");

        this.setState({errorForm: {...errorForm}});

        if (formData[UPLOAD_1C_FIELD_NAME.START_DATE] && formData[UPLOAD_1C_FIELD_NAME.END_DATE]) {
            if (formData[UPLOAD_1C_FIELD_NAME.END_DATE] < formData[UPLOAD_1C_FIELD_NAME.START_DATE]) {
                errorForm[UPLOAD_1C_FIELD_NAME.END_DATE] = t("client-finance-upload.period-error-message");
                this.setState({errorForm: {...errorForm}});
            }
        }

        return Object.values(errorForm).length === 0;
    };


    handleOnClickUpload = () => {
        const {formData} = this.state;
        const {addClientReportOneC} = this.props;

        if (!this.isValidForm(formData)) {
            return;
        }

        const requestData = {
            clientId: this.clientId,
            fromClientId: this.clientId,
            fromDate: dateFormat(formData[UPLOAD_1C_FIELD_NAME.START_DATE], "yyyy-MM-dd"),
            toDate: dateFormat(formData[UPLOAD_1C_FIELD_NAME.END_DATE], "yyyy-MM-dd"),
            uploadType: formData.documentType,
            formatType: formData.documentType === documentType1c.ONE_ACT_AND_WRITE_OFF_EXPORT.value ?
                formatFileDict.ZIP : formatFileDict.XML,
            onSuccess: () => {
                toastSuccess("Выгрузка успешно запущена");

                this.setState({
                    pageNum: 1,
                }, this.fetchList);
            },
        };

        addClientReportOneC(requestData);
    };


    renderUploadForm() {
        const {formData, errorForm} = this.state;
        const {
            progress,
            t,
        } = this.props;

        if (!this.isEditable || !this.isAccessActions) {
            return null;
        }

        return (
            <NmForm
                horizontal
                autoComplete="off"
            >
                <NmDateRangePickerV2
                    isClearable
                    returnString={false}
                    classNameError="client-finance-upload__datepicker-error"
                    size="lg"
                    label="Период"
                    isCurrentDateMax
                    className="client-finance-upload__datepicker"
                    error={errorForm[UPLOAD_1C_FIELD_NAME.START_DATE] || errorForm[UPLOAD_1C_FIELD_NAME.END_DATE]}
                    startFieldName={UPLOAD_1C_FIELD_NAME.START_DATE}
                    endFieldName={UPLOAD_1C_FIELD_NAME.END_DATE}
                    onChange={this.handleChange}
                    value={{
                        [UPLOAD_1C_FIELD_NAME.END_DATE]: formData[UPLOAD_1C_FIELD_NAME.END_DATE],
                        [UPLOAD_1C_FIELD_NAME.START_DATE]: formData[UPLOAD_1C_FIELD_NAME.START_DATE],
                    }}
                />
                <NmDropdownV2
                    isClearable
                    search={true}
                    classNameError="client-finance-upload__dropdown-error"
                    size="lg"
                    label={t("client-finance-upload.type-unloading")}
                    className="client-finance-upload__select client-finance-upload__column"
                    error={errorForm[UPLOAD_1C_FIELD_NAME.DOCUMENT_TYPE]}
                    name={UPLOAD_1C_FIELD_NAME.DOCUMENT_TYPE}
                    options={document1cTypeOptions()}
                    placeholder={t("client-finance-upload.type-unloading")}
                    value={formData[UPLOAD_1C_FIELD_NAME.DOCUMENT_TYPE]}
                    onChange={this.handleChange}
                />
                <NmButton
                    className="client-finance-upload__start-button"
                    onClick={this.handleOnClickUpload}
                    loading={progress}
                    disabled={progress}
                >
                    {t("client-finance-upload.starting-button")}
                </NmButton>
            </NmForm>
        );
    }

    renderList() {
        const {
            totalCount,
            progress,
            t,
        } = this.props;

        if (progress) {
            return (
                <Dimmer active>
                    <Loader content={t("loader.content")} />
                </Dimmer>);
        }

        if (!totalCount) {
            return (
                <NmEmptyPageV2
                    title="Данные отсутствуют"
                />
            );
        }

        return (
            <CheckboxList
                rows={this.getRows()}
            />
        );
    }

    renderHandlersList() {
        const {
            handlersList,
        } = this.state;

        if (!handlersList.length) {
            return (
                <NmEmptyPageV2
                    title="Данные отсутствуют"
                />
            );
        }

        return (
            <CheckboxList
                rows={handlersList.map(item => {
                    const {
                        updatedAt,
                        header,
                        description,
                        instruction,
                        handler,
                    } = item;


                    return {
                        ...item,
                        contentRow: (
                            <NmListCard
                                noDivider
                                alignItems="flex-end"
                                secondaryHeader={`Дата обновления: ${formatLocalDate(updatedAt, "dd.MM.yyyy HH:mm")}`}
                                primaryHeader={header}
                                labels={[
                                    {
                                        label: "Описание",
                                        text: description,
                                        noWrap: false,
                                        columnOnMobile: true,
                                        flexWrap: true,
                                    },
                                    {
                                        label: "Инструкция",
                                        text: <Text
                                            noWrap
                                            style={{cursor: "pointer"}}
                                            color={COLOR.PASSIVE_100}
                                            onClick={() => {
                                                downloadStrapiFile(instruction.url, instruction.name);
                                            }}
                                        >
                                            {instruction.name}
                                        </Text>,
                                        columnOnMobile: true,
                                    },
                                ]}
                                classNameMainContent="col-16 col-xxl-8"
                                actionsClassName="client-finance-upload__handlers-action"
                                mediaControls={{
                                    renderCount: {
                                        mobile: 1,
                                        tablet: 1,
                                        desktop: 1,
                                    },
                                    buttons: [
                                        {
                                            component: COMPONENT.BUTTON,
                                            props: {
                                                icon: <FileDownloadIcon />,
                                                onlyIcon: true,
                                                size: "lg",
                                                color: "grey",
                                                onClick: () => {
                                                    downloadStrapiFile(handler.url, handler.name);
                                                },
                                            },
                                            asset: {
                                                mobile: {
                                                    size: "sm",
                                                },
                                            },
                                        },
                                    ],
                                }}
                                isFixedActions
                            />
                        ),
                    };
                })}
            />
        );
    }

    renderModalWindow() {
        const {
            openConfirmWindow,
            confirmData: {
                content,
                onConfirm,
                confirmButton,
                cancelButton,
            },
        } = this.state;

        return openConfirmWindow &&
            <NmConfirmV2
                content={content}
                onCancel={() => {
                    this.setState({
                        openConfirmWindow: false,
                        confirmData: {},
                    });
                }}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    }

    getTabLinks = () => {
        const {isHandlesPage} = this.state;

        return [
            {
                active: !isHandlesPage,
                name: "Выгрузка в 1С",
                onClick: () => {
                    this.setState({
                        isHandlesPage: false,
                    });
                },
            },
            {
                active: isHandlesPage,
                name: "Обработчики для 1С",
                onClick: () => {
                    this.setState({
                        isHandlesPage: true,
                    });
                },
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessDevClient1c,
                ]),
            },
        ];
    };

    render() {
        const {
            totalPages,
            totalCount,
        } = this.props;
        const {
            pageNum,
            pageSize,
            isHandlesPage,
        } = this.state;

        return (
            <NmPage
                className="client-finance-upload"
                header={<NmPageCardHeader content="1С" />}
                subHeader={
                    ([ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) &&
                    <Tabs
                        //className="mb-4 mb-xxl-5"
                        secondary={false}
                        panes={this.getTabLinks()}
                    />
                }
                titleClassName="col-16"
                filtersBase={!isHandlesPage && this.renderUploadForm()}
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                currentPageNum={pageNum}
                isHasPageSize={!isHandlesPage}
                totalPages={!isHandlesPage ? totalPages : 1}
                currentPageSize={pageSize}
                totalCount={totalCount}
            >
                {this.renderModalWindow()}
                {
                    isHandlesPage ?
                        this.renderHandlersList() :
                        this.renderList()
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        list: bffClientCardReportsOneCListSelector(state),
        totalPages: bffClientCardReportsOneCTotalPagesSelector(state),
        totalCount: bffClientCardReportsOneCTotalCountSelector(state),
        progress: bffClientCardReportsOneCProgressSelector(state),
        progressExportData: bffClientCardReportsOneCProgressExportDataSelector(state),
        taskInProgressIds: bffClientCardReportsOneCTaskIdsSelector(state),
    }),
    {
        getPageClientReportsOneC,
        cancelClientReportOneC,
        addClientReportOneC,
        getTasksProgressClientReportsOneC,
        getFileLink,
        clearStoreClientReportsOneC,

        downloadDocument,
    },
)(withTranslation()(ClientFinanceUpload));
