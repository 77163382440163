import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmPage from "../../../../components/NmPage";
import NmTitle from "../../../../components/NmTitle";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import RegistryInvitationsEditForm from "./components/edit-form";
import OrderGroupsFilter from "./components/filter";

import {useFilter} from "../../../../hooks/useFilter";
import {usePagination} from "../../../../hooks/usePagination";
import useRegistryInvitationsAction from "./hooks/useAction";
import {useRegistryInvitationsFetchList} from "./hooks/useFetchList";
import {useRegistryInvitationsFilterDto} from "./hooks/useRegistryInvitationsFilterDto";

import {getArchiveButton} from "../../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD} from "../../../../constants/links";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_OPERATOR} from "../../../../constants/roles";

import {
    registryInvitationsActionProgressSelector,
    registryInvitationsListSelector,
    registryInvitationsProgressSelector,
    registryInvitationsTotalCountSelector,
    registryInvitationsTotalPagesSelector,
} from "../../../../ducks/bff/orders/order-invitations-registry/selectors";

export const REGISTRY_INVITATIONS_ACTIONS = {
    EDIT: "EDIT",
    ARCHIVE: "ARCHIVE",
    UNARCHIVE: "UNARCHIVE",
    DELETE: "DELETE",
    CANCEL_PAYMENT: "CANCEL_PAYMENT",
};

const initFilterForm = {
    titleFilter: "",
    fromCreateDateFilter: null,
    toCreateDateFilter: null,
};

function RegistryInvitationsList(props) {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(registryInvitationsListSelector);
    const totalCount = useSelector(registryInvitationsTotalCountSelector);
    const totalPages = useSelector(registryInvitationsTotalPagesSelector);
    const progress = useSelector(registryInvitationsProgressSelector);
    const actionProgress = useSelector(registryInvitationsActionProgressSelector);

    const isBannedChangeOrderInvitations = !isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.changeOrderInvitationsRegistry,
    ]);

    const role = ls(USER_ROLE);
    const isAccessAction = ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_OPERATOR].includes(role);
    const {t} = useTranslation();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination();

    const {
        filter,
        onChangeFilter,
        setFilter,
    } = useFilter({initFilter: initFilterForm});

    const {
        filterDto,
        setFilterDto,
        isSearch,
        setIsSearch,
    } = useRegistryInvitationsFilterDto();

    const {
        setArchived,
        archived,
        fetchList,
    } = useRegistryInvitationsFetchList({
        clientId,
        pageNum,
        pageSize,
        filter: filterDto,
    });

    const {
        editModal,
        openEditModal,
        closeEditModal,
        confirmAction,
        closeConfirmModal,
        onClickActionItem,
        unarchiveAction,
    } = useRegistryInvitationsAction({clientId, fetchList});

    const toggleArchived = useCallback(() => {
        setArchived(!archived);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    }, [archived, pageSize]);

    const submitFilter = (filter) => {
        setFilterDto(filter);
        setIsSearch(true);
        setPagination({
            pageSize,
            pageNum: 1,
        });
    };

    const clearFilter = () => {
        setFilter({...initFilterForm});
        setFilterDto({...initFilterForm});
        setIsSearch(false);
    };

    const getItemActionOptions = () => {
        const options = {
            EDIT: {
                key: REGISTRY_INVITATIONS_ACTIONS.EDIT,
                text: "Редактировать",
                value: REGISTRY_INVITATIONS_ACTIONS.EDIT,
            },
            ARCHIVE: {
                key: REGISTRY_INVITATIONS_ACTIONS.ARCHIVE,
                text: "В архив",
                value: REGISTRY_INVITATIONS_ACTIONS.ARCHIVE,
            },
            DELETE: {
                key: REGISTRY_INVITATIONS_ACTIONS.DELETE,
                text: "Удалить",
                value: REGISTRY_INVITATIONS_ACTIONS.DELETE,
            },
        };


        return [
            options.EDIT,
            options.DELETE,
            options.ARCHIVE,
        ];
    };

    const renderActionCell = (item) => {
        if (
            !isAccessAction
            || isBannedChangeOrderInvitations
        ) {
            return null;
        }

        const options = getItemActionOptions(item);

        if (isEmpty(options) || archived) {
            return null;
        }

        return (
            <ContextMenu
                className="patents-card__action-dropdown"
                options={options}
                onClickItem={(option) => {
                    onClickActionItem(option, item);
                }}
            />
        );
    };

    const getHeaderMediaControls = () => {
        const archive = getArchiveButton(t, archived, {mobile: true});

        return {
            renderCount: {
                mobile: 1,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: () => openEditModal({isEdit: false}),
                        icon: <AddIcon />,
                        children: "Добавить реестр",
                    },
                    visible: isAccessAction
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.addOrderInvitationsRegistry,
                        ]),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        disabled: progress,
                        onClick: toggleArchived,
                    },
                },
            ],
        };
    };

    const getMediaActions = (item) => {
        if (isBannedChangeOrderInvitations) {
            return null;
        }

        return archived && {
            renderCount: {
                desktop: 1,
                tablet: 1,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Из архива",
                        color: "grey",
                        onClick: () => {
                            unarchiveAction(item);
                        },
                    },
                },
            ],
        };
    };

    const renderNameLink = ({title, registryInvitationId}) => {
        const link = LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD
            .replace(":clientId", clientId)
            .replace(":registryInvitationId", registryInvitationId);

        return (
            <ExtLink
                pageData={{pageNum, pageSize}}
                filterData={filter}
                historyEnabled
                to={link}
            >
                {title}
            </ExtLink>
        );
    };

    const getRows = () => {
        return list.map(item => {
            const {
                createDate,
                comment,
            } = item;

            return {
                contentRow: (
                    <NmListCard
                        noDivider
                        fluidPrimaryHeader
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-14 col-xxl-8"
                        primaryHeader={renderNameLink(item)}
                        actionsClassName="col-1 col-xxl-8 justify-content-end"
                        secondaryHeader={`Реестр от ${formatLocalDate(createDate, "dd.MM.yyyy HH:mm")}`}
                        labels={[
                            comment && {label: "Комментарий", text: comment},
                        ]}
                        mediaControls={getMediaActions(item)}
                        actions={renderActionCell(item)}
                    />
                ),
            };
        });
    };

    const renderEditModal = () => {
        const {
            isOpen,
            isEdit,
            card,
        } = editModal;

        return isOpen &&
            <RegistryInvitationsEditForm
                isEdit={isEdit}
                onClose={closeEditModal}
                card={card}
                clientId={clientId}
                fetchList={fetchList}
            />;
    };

    const renderConfirmWindow = () => {
        const {
            isOpen,
            content,
            onConfirm,
        } = confirmAction;

        return (
            isOpen &&
            <NmConfirmV2
                content={content}
                onConfirm={onConfirm}
                onCancel={closeConfirmModal}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                disabled={actionProgress}
                loading={actionProgress}
                isOnlyConfirm
            />
        );
    };

    return (
        <NmPage
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Реестры приглашений на заказы
                </NmTitle>
            }
            mediaControls={getHeaderMediaControls()}
            typeFilter="vertical"
            filtersBase={
                <OrderGroupsFilter
                    filter={filter}
                    submitFilter={submitFilter}
                    onClear={clearFilter}
                    onChangeFilter={onChangeFilter}
                />
            }
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {renderEditModal()}
            {renderConfirmWindow()}
            {
                list?.length ?
                    <CheckboxList
                        rows={getRows()}
                    /> :
                    <NmEmptyPageV2
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }

        </NmPage>
    );
}

export default RegistryInvitationsList;