import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getContractorList} from "../../../contractor";
import {getPageOrderContractors} from "../../../order";
import {getStatusesOrderContractor} from "../../../orderContractors";
import {getAllApplications} from "../../clients/order-analytics/all-applications/actionCreators";
import {getPaidSumList} from "../orders-registry/actionCreators";
import {getDataPaidSumSelector} from "../orders-registry/selectors";
import {
    DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR,
    DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST,
    DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS,
    GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
    GET_SCORE_ACTUAL_CONTRACTOR_REQUEST,
    GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS,
    ORDER_CONTRACTOR_ADD_ERROR,
    ORDER_CONTRACTOR_ADD_REQUEST,
    ORDER_CONTRACTOR_ADD_SUCCESS,
    ORDER_CONTRACTOR_APPROVE_ERROR,
    ORDER_CONTRACTOR_APPROVE_REQUEST,
    ORDER_CONTRACTOR_APPROVE_SUCCESS,
    ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
    ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST,
    ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS,
    ORDER_CONTRACTOR_DECLINE_ERROR,
    ORDER_CONTRACTOR_DECLINE_REQUEST,
    ORDER_CONTRACTOR_DECLINE_SUCCESS,
    ORDER_CONTRACTOR_INIT_PAYMENT_ERROR,
    ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST,
    ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS,
    ORDER_CONTRACTOR_REJECT_ERROR,
    ORDER_CONTRACTOR_REJECT_REQUEST,
    ORDER_CONTRACTOR_REJECT_SUCCESS,
    ORDER_WORK_REPORT_LIST_ERROR,
    ORDER_WORK_REPORT_LIST_REQUEST,
    ORDER_WORK_REPORT_LIST_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {
    LINK_APPLICATION_ANALYTICS,
    LINK_FINANCES_REGISTRY,
} from "../../../../constants/links";
import {ORDER_WORK_REPORT, STAFF} from "../../../../constants/messages";

const getController = () => {
    return (
        getBffUrl({
            [LINK_APPLICATION_ANALYTICS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/reports/order-analytics/responses",
                client: "/client-adm/reports/order-analytics/responses",
            }),
            [LINK_FINANCES_REGISTRY]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/registry-payments/registry/registry-card",
                client: "/client-adm/finances/registry-payments/registry/registry-card",
            }),
        },
        getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/orders/orders-registry/card/contractors",
            client: "/client-adm/orders/orders-registry/card/contractors",
        }),
        true,
        )
    );
};

const rejectContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            toastMsg,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/invited/reject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            if (onError) {
                onError();
            }

            yield put({
                type: ORDER_CONTRACTOR_REJECT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        if (toastMsg) {
            toastSuccess(toastMsg);
        }

        yield put({
            type: ORDER_CONTRACTOR_REJECT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_REJECT_ERROR,
            payload: error.message,
        });
    }
};

const approveContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/responses/approve`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            onError();

            yield put({
                type: ORDER_CONTRACTOR_APPROVE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }


        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_APPROVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_APPROVE_ERROR,
            payload: error.message,
        });
    }
};

const declineContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            subpage = "responses",
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/${subpage}/decline`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_DECLINE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_DECLINE_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_DECLINE_ERROR,
            payload: error.message,
        });
    }
};

const getOrderWorkReportListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/hired/get-order-work-report-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_LIST_ERROR,
            payload: error,
        });
    }
};

const orderContractorCompleteOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/hired/complete-order`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR,
            payload: error.message,
        });
    }
};

const initPaymentSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isAnalyticsPage,
            ...reqData
        } = payload;

        const otherUrl = getBffUrl({
            [LINK_APPLICATION_ANALYTICS]: "/addV2",
        },
        "/common/addV2",
        true,
        );

        const result = yield request.bff.post(`${getController()}${otherUrl}`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_INIT_PAYMENT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS,
            payload: result,
        });

        const {
            paymentsNumberDuplicated,
            warnings,
            errors,
        } = result;

        if (
            warnings && warnings.length ||
            paymentsNumberDuplicated && paymentsNumberDuplicated.length ||
            errors
        ) {
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(ORDER_WORK_REPORT.ADD_SUCCESS_TEXT);

        const state = yield select();

        if (isAnalyticsPage) {
            yield put(getAllApplications(state.bff.orderAnalyticsAllApplications.pageData));
        } else {
            yield put(getPageOrderContractors(state.order.orderContractorPageData));
            yield put(getPaidSumList(getDataPaidSumSelector(state)));
        }
    } catch (error) {
        yield put({
            type: ORDER_CONTRACTOR_INIT_PAYMENT_ERROR,
            payload: error,
        });
    }
};

const isFrameContractSignedSaga = function* ({payload}) {
    try {
        const {
            data: params,
            onSuccess,
        } = payload;

        const otherUrl = getBffUrl({
            [LINK_APPLICATION_ANALYTICS]: "/is-frame-contract-signed",
            [LINK_FINANCES_REGISTRY]: "/is-frame-contract-signed",
        },
        "/responses/is-frame-contract-signed",
        true,
        );

        const result = yield request.bff.get(`${getController()}${otherUrl}`, {params});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR,
            payload: error,
        });
    }
};

//GET /bff/adm/clients/client-card/reports/order-analytics/responses/actual
//GET /bff/client-adm/reports/order-analytics/responses/actual
//GET /bff/adm/clients/client-card/orders/orders-registry/card/contractors/common/actual
//GET /bff/client-adm/orders/orders-registry/card/contractors/common/actual
const getActualScoreContractorSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...params
        } = payload;

        const otherUrl = getBffUrl({
            [LINK_APPLICATION_ANALYTICS]: "/actual",
        },
        "/common/actual",
        true,
        );

        const result = yield request.bff.get(`${getController()}${otherUrl}`, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (handleResponse) {
            handleResponse(result);
        }

        yield put({
            type: GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SCORE_ACTUAL_CONTRACTOR_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/clients/client-card/orders/orders-registry/order/contractors/add
//POST bff/client-adm/orders/orders-registry/order/contractors/add
const orderContractorAddSaga = function* ({payload}) {
    try {
        const {
            isFetchList,
            toastMessage,
            onSuccess,
            ...reqData
        } = payload;

        const controller = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/orders/orders-registry/order/contractors",
            client: "/client-adm/orders/orders-registry/order/contractors",
        });

        const result = yield request.bff.post(`${controller}/add`, reqData);

        const {errorMessage} = result;

        if (onSuccess) {
            onSuccess();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_ADD_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(toastMessage);

        if (isFetchList) {
            const state = yield select();
            yield put(getContractorList(state.contractor.pageData));
            yield put(getStatusesOrderContractor(state.orderContractors.statusesParams));
            yield put(getPageOrderContractors(state.order.orderContractorPageData));
        }

        yield put({
            type: ORDER_CONTRACTOR_ADD_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(STAFF.STAFF_ADD_ERROR_TEXT);
        yield put({type: ORDER_CONTRACTOR_ADD_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_CONTRACTOR_REJECT_REQUEST, rejectContractorSaga),
        takeEvery(ORDER_CONTRACTOR_APPROVE_REQUEST, approveContractorSaga),
        takeEvery(ORDER_CONTRACTOR_DECLINE_REQUEST, declineContractorSaga),
        takeEvery(ORDER_WORK_REPORT_LIST_REQUEST, getOrderWorkReportListSaga),
        takeEvery(ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST, orderContractorCompleteOrderSaga),
        takeEvery(ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST, initPaymentSaga),
        takeEvery(DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST, isFrameContractSignedSaga),
        takeEvery(GET_SCORE_ACTUAL_CONTRACTOR_REQUEST, getActualScoreContractorSaga),
        takeEvery(ORDER_CONTRACTOR_ADD_REQUEST, orderContractorAddSaga),
    ]);
}
