import {
    CLIENT_UPDATE_NM_CONTRACT_REQUEST,
    CONTRACT_HISTORY_ADD_REQUEST,
    CONTRACT_HISTORY_CLEAR_FIELDS,
    CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST,
    CONTRACT_HISTORY_GET_FILE_REQUEST,
    CONTRACT_HISTORY_GET_LIST_REQUEST,
    CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST,
    GET_TERMINATE_CONTRACT_INFO_REQUEST,
} from "./actions";

export function getHistoryList(payload) {
    return {
        type: CONTRACT_HISTORY_GET_LIST_REQUEST,
        payload,
    };
}

export function getActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST,
        payload,
    };
}

export function addActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_ADD_REQUEST,
        payload,
    };
}


export function getContractHistoryFile(payload) {
    return {
        type: CONTRACT_HISTORY_GET_FILE_REQUEST,
        payload,
    };
}

export function updateActualVersion(payload) {
    return {
        type: CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST,
        payload,
    };
}

export function getTerminateContractInfo(payload) {
    return {
        type: GET_TERMINATE_CONTRACT_INFO_REQUEST,
        payload,
    };
}

export function updateNmContract(payload) {
    return {
        type: CLIENT_UPDATE_NM_CONTRACT_REQUEST,
        payload,
    };
}

export function clearFields() {
    return {
        type: CONTRACT_HISTORY_CLEAR_FIELDS,
    };
}