import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getClientDocumentsRegistryPage} from "./actionCreators";
import {
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_ERROR,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_REQUEST,
    ADD_CLIENT_FRAME_CONTRACT_EXTERNAL_SUCCESS,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS,
    RECREATE_END_DOCUMENT_NOTIFICATION_ERROR,
    RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST,
    RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS,
    RECREATE_START_DOCUMENT_NOTIFICATION_ERROR,
    RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST,
    RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS,
    RESIDENT_VALIDATION_ERROR,
    RESIDENT_VALIDATION_REQUEST,
    RESIDENT_VALIDATION_SUCCESS,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS,
    TERMINATE_FRAME_CONTRACT_ERROR,
    TERMINATE_FRAME_CONTRACT_REQUEST,
    TERMINATE_FRAME_CONTRACT_SUCCESS,
    UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR,
    UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST,
    UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS,
} from "./actions";
import {clientDocumentsRegistryListPageDataSelector} from "./selectors";

import request from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../../utils/url";

import {DOCUMENT_NOTIFICATIONS_LIST} from "../../../../../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [DOCUMENT_NOTIFICATIONS_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/documents/ovm-notifications",
                client: "/client-adm/documents/ovm-notifications",
            }),
            isClientCard: true,
            adminRolesUrl: "/adm/clients/client-card/documents/registry",
            clientRolesUrl: "/client-adm/documents/registry",
        },
        "/adm/clients/client-card/documents/document-registry",
        true,
        )
    );
};

const handleResponse = function* (data) {
    const {
        isFetchList,
    } = data;

    if (isFetchList) {
        const state = yield select();
        const pageData = clientDocumentsRegistryListPageDataSelector(state);

        yield put(getClientDocumentsRegistryPage(pageData));
    }
};

const getClientDocumentsRegistryPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const recreateStartWorkNotificationSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/recreateStartWorkNotification`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RECREATE_START_DOCUMENT_NOTIFICATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Уведомление ОВМ успешно изменено");

        yield handleResponse({isFetchList: true});
        
        yield put({
            type: RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: RECREATE_START_DOCUMENT_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

const recreateEndWorkNotificationSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/recreateEndWorkNotification`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RECREATE_END_DOCUMENT_NOTIFICATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Уведомление ОВМ успешно изменено");

        yield handleResponse({isFetchList: true});

        yield put({
            type: RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: RECREATE_END_DOCUMENT_NOTIFICATION_ERROR,
            payload: error,
        });
    }
};

const updateWorkNotificationsArchivedSaga = function* ({payload}) {
    try {
        const {actuality} = payload;

        const result = yield request.bff.post(`${getController()}/actuality/update`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(
            actuality
                ? "Уведомления успешно восстановлены из архива"
                : "Уведомления успешно заархивированы",
        );

        yield handleResponse({isFetchList: true});

        yield put({
            type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: UPDATE_WORK_NOTIFICATIONS_ARCHIVED_ERROR,
            payload: error,
        });
    }
};

const searchContractorByInnOrPhoneSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.get(`${getController()}/contractors/find/by-inn-or-phone`, {params: reqData});

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            yield put({
                type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
            payload: error,
        });
    }
};

const terminateFrameContractSaga = function* ({payload}) {
    try {
        const {
            data: {
                documentId,
            },
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/frame-contract/${documentId}/terminate`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: TERMINATE_FRAME_CONTRACT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess("Договор успешно расторгнут");

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: TERMINATE_FRAME_CONTRACT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: TERMINATE_FRAME_CONTRACT_ERROR,
        });
    }
};

const validateContractorForContractActionSaga = function* ({payload}) {
    try {
        const {
            params,
            onSuccess,
            onNeedShowWarning,
        } = payload;

        const response = yield request.bff.get(`${getController()}/is-frame-contract-terminate-with-non-resident`, {params});

        const {
            errorMessage,
            result,
        } = response;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: RESIDENT_VALIDATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (result) {
            onNeedShowWarning && onNeedShowWarning();
        } else {
            onSuccess && onSuccess();
        }

        yield put({
            type: RESIDENT_VALIDATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: RESIDENT_VALIDATION_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST, getClientDocumentsRegistryPageSaga),
        takeEvery(RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST, recreateStartWorkNotificationSaga),
        takeEvery(RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST, recreateEndWorkNotificationSaga),
        takeEvery(UPDATE_WORK_NOTIFICATIONS_ARCHIVED_REQUEST, updateWorkNotificationsArchivedSaga),
        takeEvery(SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST, searchContractorByInnOrPhoneSaga),
        takeEvery(TERMINATE_FRAME_CONTRACT_REQUEST, terminateFrameContractSaga),
        takeEvery(RESIDENT_VALIDATION_REQUEST, validateContractorForContractActionSaga),
    ]);
}