import React from "react";
import {Route, Switch, withRouter} from "react-router";

import {CheckRoleRoute} from "../../../../components/CheckRoleRoute";
import AgencyContract from "../../../agency-contract";
import {DocumentsAdditionalAgreements} from "../../../documents/additional-agreements";
import {DocumentsAdditionalAgreementsCard} from "../../../documents/additional-agreements/components/card";
import ClientDocumentList from "../../../documents/client-document-list";
import {DocumentsDeferredActsOfAcceptanceWork} from "../../../documents/deferred-acts-of-acceptance-work";
import {
    DocumentsDeferredActsOfAcceptanceWorkCard,
} from "../../../documents/deferred-acts-of-acceptance-work/components/card";
import DocumentExportList from "../../../documents/document-export-list";
import FcRegistryCard from "../../../documents/fc-registers/card";
import FcRegistryList from "../../../documents/fc-registers/list";
import FinanceMutualSettlementsReconciliationActs from "../../../finance/mutual-settlements-reconciliation-acts";
import OperatorReports from "../../../reports/operator-reports";
import StandartDocumentsContainer from "../../../standart-documents-container";
import Templates from "../../../templates";

import {
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_CARD_TEMPLATES_LIST,
    LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
} from "../../../../constants/links";

const DocumentRoute = () => {
    return (
        <Switch>
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_EXPORT_DOCUMENT_LIST}
                component={DocumentExportList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_DOCUMENT_LIST}
                component={ClientDocumentList}
            />
            <Route
                exact
                path={LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST}
                component={ClientDocumentList}
            />
            <Route
                exact
                path={LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST}
                component={DocumentsDeferredActsOfAcceptanceWork}
            />
            <Route
                exact
                path={LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD}
                component={DocumentsDeferredActsOfAcceptanceWorkCard}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_OPERATOR_REPORTS}
                component={OperatorReports}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_AGENCY_CONTRACT}
                component={AgencyContract}
            />
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_FC_CARD}
                component={FcRegistryCard}
            />
            <Route
                exact
                path={LINK_CLIENT_REGISTRY_FC_LIST}
                component={FcRegistryList}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_STANDART_DOCUMENTS}
                component={StandartDocumentsContainer}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS}
                component={FinanceMutualSettlementsReconciliationActs}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CARD_TEMPLATES_LIST}
                component={Templates}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB}
                component={Templates}
            />
            <CheckRoleRoute
                exact
                path={LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD}
                component={DocumentsAdditionalAgreementsCard}
            />
            <CheckRoleRoute
                path={LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST}
                component={DocumentsAdditionalAgreements}
            />
        </Switch>
    );
};

export default withRouter(DocumentRoute);