import {useMemo} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";

import formatDate from "../../../../../../../../utils/dateFormat";
import {convertStringToDate, handleFormString} from "../../../../../../../../utils/stringHelper";

import {requiredMessage} from "../../../../../../../../constants/validationRules";

import {updateDeferredActsOfAcceptanceWorkCardItem} from "../../../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/actionCreators";

export const useDocumentsDeferredActsOfAcceptanceWorkItemEditModalForm = (params) => {
    const {
        editData,
    } = params;

    const dispatch = useDispatch();
    
    const initialValues = useMemo(() => {
        const {
            actDate,
            commentary,
            startWorkDate,
            endWorkDate,
            ...otherValues
        } = editData;

        return {
            commentary: commentary || "",
            actDate: convertStringToDate(actDate) || null,
            startWorkDate: convertStringToDate(startWorkDate) || null,
            endWorkDate: convertStringToDate(endWorkDate) || null,
            ...otherValues,
        };
    }, [editData]);


    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        dispatch(updateDeferredActsOfAcceptanceWorkCardItem({
            clientId: editData.clientId,
            registryId: editData.registryId,
            seqNum: editData.seqNum,
            commentary: handleFormString(values.commentary),
            actDate: formatDate(values.actDate, "yyyy-MM-dd"),
        }));
    };

    const {
        handleSubmit,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: yup.object().shape({
            commentary: yup.string()
                .nullable()
                .max(255, "Не более 255 символов"),
            actDate: yup.string()
                .nullable()
                .required(requiredMessage),
        }),
        validateOnBlur: false,
    });

    return {
        handleSubmit,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors,
    };
};