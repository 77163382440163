import React from "react";
import {useDispatch, useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";

import {handleFormString} from "../../../../../utils/stringHelper";

import {getEdoDocumentTypes} from "../../../../../ducks/bff/clients/edo/documents/actionCreators";
import {edoDocumentTypesUploadDocumentsSelector} from "../../../../../ducks/bff/clients/edo/documents/selectors";

import {EDO_DOCUMENTS_REGISTRIES_STATUS_OPTIONS} from "../../../../../constants/document-management/documents-registries";

export const EdoDocumentsRegistriesFilter = (props) => {
    const {
        filter: {
            name = "",
            createDatetimeFrom,
            createDatetimeTo,
            ownerFio = "",
            senderFio = "",
            documentType,
            status,
        },
        onChange,
        onSearch,
        onClear,
    } = props;

    const edoDocumentTypesOptions = useSelector(edoDocumentTypesUploadDocumentsSelector);

    const dispatch = useDispatch();

    const fetchEdoDocumentStates = (valueFilter) => {
        const payload = {
            pageNum: 1,
            pageSize: 100,
            valueFilter: handleFormString(valueFilter),
        };

        dispatch(getEdoDocumentTypes(payload));
    };

    const handleOnSearchDocumentType = (valueFilter) => {
        fetchEdoDocumentStates(valueFilter);
    };

    return (
        <NmForm
            addMargin
        >
            <NmInputV2
                size="lg"
                label="Наименование реестра"
                placeholder="Введите наименование"
                name="name"
                value={name}
                onChange={onChange}
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="createDatetimeFrom"
                endFieldName="createDatetimeTo"
                value={{createDatetimeFrom, createDatetimeTo}}
                label="Дата создания"
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="Ответственный за реестр"
                placeholder="ФИО ответственного"
                name="ownerFio"
                value={ownerFio}
                onChange={onChange}
            />
            <NmInputV2
                size="lg"
                label="Отправитель документов"
                placeholder="ФИО отправителя"
                name="senderFio"
                value={senderFio}
                onChange={onChange}
            />
            <NmDropdownV2
                size="xl"
                placeholder="Выберите из списка"
                label="Тип документа"
                search
                name="documentType"
                onChange={onChange}
                value={documentType}
                options={edoDocumentTypesOptions}
                onSearchChange={handleOnSearchDocumentType}
            />
            <NmDropdownV2
                placeholder="Все"
                emptyOptionText="Все"
                label="Статус реестра"
                name="status"
                onChange={onChange}
                value={status}
                options={EDO_DOCUMENTS_REGISTRIES_STATUS_OPTIONS}
                size="lg"
            />
            <FilterButtonsV2
                onSearch={onSearch}
                onClear={onClear}
            />
        </NmForm>
    );
};