import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getNonDecisionCount} from "../../../clientSpecialityOffer";
import {getContractorCount} from "../../../contractor";
import {
    FIELD_NAME_ORDER_LIST_ENUM,
    ORDERS_ARCHIVED_SUCCESS_MSG,
} from "../../../order";
import {getClientProjectCard} from "../../clients/projects/card/actionCreators";
import {
    getOrderRichById,
    getPageOrders,
} from "./actionCreators";
import {
    ARCHIVE_ORDERS_ERROR,
    ARCHIVE_ORDERS_REQUEST,
    ARCHIVE_ORDERS_SUCCESS,
    CLOSE_ORDERS_ERROR,
    CLOSE_ORDERS_REQUEST,
    CLOSE_ORDERS_SUCCESS,
    DELETE_ORDERS_ERROR,
    DELETE_ORDERS_REQUEST,
    DELETE_ORDERS_SUCCESS,
    GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
    GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST,
    GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS,
    ORDER_ARCHIVE_UPDATE_ERROR,
    ORDER_ARCHIVE_UPDATE_REQUEST,
    ORDER_ARCHIVE_UPDATE_SUCCESS,
    ORDER_CARD_RICH_ERROR,
    ORDER_CARD_RICH_REQUEST,
    ORDER_CARD_RICH_SUCCESS,
    ORDER_COMPLETE_ERROR,
    ORDER_COMPLETE_REQUEST,
    ORDER_COMPLETE_SUCCESS,
    ORDER_CONTRACTOR_HISTORY_ERROR,
    ORDER_CONTRACTOR_HISTORY_REQUEST,
    ORDER_CONTRACTOR_HISTORY_SUCCESS,
    ORDER_DELETE_DRAFT_ERROR,
    ORDER_DELETE_DRAFT_REQUEST,
    ORDER_DELETE_DRAFT_SUCCESS,
    ORDER_HISTORY_ERROR,
    ORDER_HISTORY_REQUEST,
    ORDER_HISTORY_SUCCESS,
    ORDER_LIST_FOR_INVITATION_ERROR,
    ORDER_LIST_FOR_INVITATION_REQUEST,
    ORDER_LIST_FOR_INVITATION_SUCCESS,
    ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR,
    ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS,
    ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR,
    ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST,
    ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS,
    ORDER_WORK_REPORT_PAID_SUM_ERROR,
    ORDER_WORK_REPORT_PAID_SUM_REQUEST,
    ORDER_WORK_REPORT_PAID_SUM_SUCCESS,
    ORDERS_ADD_ERROR,
    ORDERS_ADD_REQUEST,
    ORDERS_ADD_SUCCESS,
    ORDERS_END_CONTRACTOR_SEARCH_ERROR,
    ORDERS_END_CONTRACTOR_SEARCH_REQUEST,
    ORDERS_END_CONTRACTOR_SEARCH_SUCCESS,
    ORDERS_GET_PAGE_ERROR,
    ORDERS_GET_PAGE_REQUEST,
    ORDERS_GET_PAGE_SUCCESS,
    ORDERS_UPDATE_ERROR,
    ORDERS_UPDATE_REQUEST,
    ORDERS_UPDATE_SUCCESS,
    PUBLISH_ORDERS_ERROR,
    PUBLISH_ORDERS_REQUEST,
    PUBLISH_ORDERS_SUCCESS,
    UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
    UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST,
    UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS,
} from "./actions";

import {ls, USER_ROLE} from "../../../../utils/localstorage";
import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {ORDER_STATUS} from "../../../../constants/clientList";
import {
    LINK_ADVERTISEMENT,
    LINK_DISPUTES_PREFIX,
    LINK_OBJECTS,
    LINK_ORDER_GROUPS_LIST,
    LINK_ORDER_TEMPLATES_LIST,
    LINK_PROJECTS,
    LINK_RECRUITMENT,
} from "../../../../constants/links";
import {ORDER} from "../../../../constants/messages";
import {ORDER_ERROR_CODE, ORDER_MASS_ACTION_MESSAGE, ORDER_TEMPLATE_ARCHIVE} from "../../../../constants/order";
import {ADMIN, FOREMAN, NM_MANAGER} from "../../../../constants/roles";

export const getOrdersController = () => {
    const orderCardController = getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/orders-registry/card",
        client: "/client-adm/orders/orders-registry/card",
    });

    return (
        getBffUrl({
            [LINK_ORDER_TEMPLATES_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/order-templates",
                client: "/client-adm/orders/order-templates",
            }),
            [LINK_OBJECTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/objects/object-card/orders",
                client: "/client-adm/client/objects/object-card/orders",
            }),
            [LINK_PROJECTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects/project-card/orders",
                client: "/client-adm/client/projects/project-card/orders",
            }),
            [LINK_ORDER_GROUPS_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/orders/order-groups/order-group-card",
                client: "/client-adm/orders/order-groups/order-group-card",
            }),
            [LINK_DISPUTES_PREFIX]: getBffControllerClientCardPage({
                admin: "/adm/disputes",
                client: "/client-adm/disputes",
            }),
            ["/performers"]: orderCardController,
            ["/disputes"]: orderCardController,
            isClientCard: true,
            clientRolesUrl: "/client-adm/orders/orders-registry",
            adminRolesUrl: "/adm/clients/client-card/orders/orders-registry",
        },
        null,
        true,
        )
    );
};

const getPageOrdersSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getOrdersController()}/get-order-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDERS_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDERS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDERS_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const addOrderSaga = function* ({payload}) {
    try {
        const {
            addDocumentFile,
            close,
            isNeedFetch = true,
            onError,
            errorToast,
            onSuccess,
            successToast,
            fetchList,
            duplicate,
            ...otherData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/add-order`, otherData);
        const {guid, errorMessage, status, orderNum, errorCode} = result;

        // Для этих ошибок не нужно закрывать форму заказа
        if (![
            ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL,
            ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR,
            ORDER_ERROR_CODE.TEMPLATE_IS_OUT_OF_DATE,
            ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED,
        ].includes(errorCode)) {
            close();
        }

        if (errorMessage) {
            ![
                ORDER_ERROR_CODE.TEMPLATE_IS_ARCHIVED,
            ].includes(errorCode) &&
            toastError(errorMessage);

            yield put({
                type: ORDERS_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {
            clientId,
            specialityId,
            name,
            offeredSpecialityName,
        } = otherData;

        addDocumentFile(guid);

        const data = {
            clientId,
            specialityIdsFilter: specialityId ? [
                specialityId,
            ] : undefined,
            remoteWorkFilter: null,
            orderSearchParams: {
                orderId: guid,
                searchByObjectAddressFiasIdsStrict: false,
                searchByObjectAddressFiasIds: true,
            },
        };

        const toastMessage = duplicate ? ORDER.ORDER_CREATE_DUPLICATE : successToast;

        toastSuccess(toastMessage);

        const state = yield select();


        if (isNeedFetch && !duplicate && !fetchList) {
            yield put(getPageOrders(state.bff.ordersRegistry.pageData));
        }

        if (isNeedFetch && !duplicate && fetchList) {
            fetchList();
        }

        yield put(getContractorCount(data));

        const userRole = ls(USER_ROLE);

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }

        if (onSuccess) {
            onSuccess(result);
        }
        yield put({
            type: ORDERS_ADD_SUCCESS,
            payload: {
                guid,
                name,
                status,
                orderNum,
                specialityId,
            },
        });
    } catch (error) {
        yield put({
            type: ORDERS_ADD_ERROR,
            payload: error,
        });
    }
};

const updateOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            addDocumentFile,
            close,
            successToast,
            errorToast,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/update-order`, reqData);
        const {errorMessage, errorCode, guid} = result;

        // Для этих ошибок не нужно закрывать форму заказа
        if (
            close
            && ![
                ORDER_ERROR_CODE.OBJECT_AMOUNT_SMALL,
                ORDER_ERROR_CODE.INVALID_ORDER_AMOUNT_PER_CONTRACTOR,
            ].includes(errorCode)
        ) {
            close();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDERS_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        const {offeredSpecialityName} = reqData;

        if (addDocumentFile) {
            addDocumentFile(guid);
        }

        successToast && toastSuccess(successToast);

        yield put({
            type: ORDERS_UPDATE_SUCCESS,
            payload: {
                ...result,
                name: reqData.name,
            },
        });

        if (onSuccess) {
            onSuccess(result);
        } else {
            const state = yield select();

            yield put(getPageOrders(state.bff.ordersRegistry.pageData));
        }

        const userRole = ls(USER_ROLE);

        // Обновляем счетчик справочника ВД, если предложен свой ВД
        if (offeredSpecialityName && [ADMIN, NM_MANAGER].includes(userRole)) {
            yield put(getNonDecisionCount());
        }
    } catch (error) {
        yield put({
            type: ORDERS_UPDATE_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/orders-registry/get-order-history
// Получить историю заказа
const orderContractorHistorySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getOrdersController()}/get-order-history`, payload);
        const {errorMessage} = payload;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_HISTORY_ERROR,
                payload: errorMessage,
            });
        }

        yield put({
            type: ORDER_CONTRACTOR_HISTORY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError("Не удалось загрузить историю заказа");
        yield put({
            type: ORDER_CONTRACTOR_HISTORY_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/orders-registry/order-event-log/page
// Получить историю заказа (event log)
const orderHistorySaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getOrdersController()}/order-event-log/page`, payload);
        const {errorMessage} = payload;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_HISTORY_ERROR,
                payload: errorMessage,
            });
        }

        yield put({
            type: ORDER_HISTORY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError("Не удалось загрузить историю заказа");
        yield put({
            type: ORDER_HISTORY_ERROR,
            payload: error,
        });
    }
};

const getOrdersNotByTemplateCreationAvailabilitySaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getOrdersController()}/get-orders-not-by-template-creation-availability`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_ERROR,
        });
    }
};

const updateOrderArchiveSaga = function* ({payload}) {
    try {
        const {
            clientId,
            orderId,
            isArchive,
            projectId,
            isFetchList = true,
            isFetchCard = false,
            nameFieldPageData = FIELD_NAME_ORDER_LIST_ENUM.DEFAULT.PAGE_DATA,
            getResult,
        } = payload;

        const otherUrl = isArchive
            ? "/archive-order"
            : "/unarchive-order";
        const result = yield request.bff.post(`${getOrdersController()}${otherUrl}`, {
            clientId,
            orderId,
        });

        const {
            errorMessage,
            errorCode,
        } = result;

        if (errorMessage) {
            ![ORDER_TEMPLATE_ARCHIVE.LAST_TEMPLATE_IN_OBJECT_ERROR].includes(errorCode) &&
            toastError(errorMessage);

            yield put({
                type: ORDER_ARCHIVE_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        if (projectId) {
            yield put(getClientProjectCard({projectId, clientId}));
        }

        toastSuccess(
            isArchive
                ? ORDERS_ARCHIVED_SUCCESS_MSG[nameFieldPageData].archived
                : ORDERS_ARCHIVED_SUCCESS_MSG[nameFieldPageData].unArchive,
        );

        if (isFetchList) {
            const state = yield select();

            yield put(getPageOrders(state.bff.ordersRegistry.pageData));
        }

        if (isFetchCard) {
            yield put(getOrderRichById({
                orderId,
                clientId,
            }));
        }

        yield put({
            type: ORDER_ARCHIVE_UPDATE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_ARCHIVE_UPDATE_ERROR,
            payload: error,
        });
    }
};

const completeOrderSaga = function* ({payload}) {
    try {
        const {
            handleResponse,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/complete-order`, reqData);

        const {errorMessage, success, status} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_COMPLETE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        toastSuccess(
            status === ORDER_STATUS.CLOSED
                ? ORDER.COMPLETE_SUCCESS
                : ORDER.COMPLETE_LATER_SUCCESS,
        );

        const state = yield select();

        if (handleResponse) {
            handleResponse(result);
        } else {
            yield put(getPageOrders(state.bff.ordersRegistry.pageData));
        }

        yield put({
            type: ORDER_COMPLETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(ORDER.ORDER_COMPLETE_ERROR_TEXT);

        yield put({
            type: ORDER_COMPLETE_ERROR,
            payload: error,
        });
    }
};

const getWarningBeforePublishOrderSaga = function* ({payload}) {
    try {
        const isOrderPublicationProhibited = yield request.bff.get(`${getOrdersController()}/is-order-publication-prohibited`);
        const role = ls(USER_ROLE);

        if (isOrderPublicationProhibited && role === FOREMAN) {
            toastError("Возможность опубликовать заказ недоступна для вашей учетной записи. Обратитесь к руководству вашей компании");

            yield put({
                type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
            });

            return {
                done: true,
            };
        }

        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/get-warning-before-publish-order`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            onError();
            toastError(errorMessage);

            yield put({
                type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess(result);
        }

        yield put({
            type: GET_WARNING_BEFORE_PUBLISH_ORDER_SUCCESS,
            payload: result,
        });
    } catch (error) {
        payload.onError();

        yield put({
            type: GET_WARNING_BEFORE_PUBLISH_ORDER_ERROR,
            payload: error,
        });
    }
};

const closeOrdersSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/close-orders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLOSE_ORDERS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.CLOSE_ORDERS.SUCCESS);

        yield put({
            type: CLOSE_ORDERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CLOSE_ORDERS_ERROR,
            payload: error.message,
        });
    }
};

const archiveOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/archive-orders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ARCHIVE_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.ARCHIVE_CLOSED_ORDERS.SUCCESS);

        yield put({type: ARCHIVE_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: ARCHIVE_ORDERS_ERROR});
    }
};

const deleteOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/delete-orders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: DELETE_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.DELETE_DRAFTS.SUCCESS);

        yield put({type: DELETE_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: DELETE_ORDERS_ERROR});
    }
};

const publishOrdersSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/publish-orders`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: PUBLISH_ORDERS_ERROR});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.PUBLISH_ORDERS.SUCCESS);

        yield put({type: PUBLISH_ORDERS_SUCCESS});
    } catch (error) {
        yield put({type: PUBLISH_ORDERS_ERROR});
    }
};

const ordersEndContractorSearchSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            data,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/orders-end-contractor-search`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: ORDERS_END_CONTRACTOR_SEARCH_ERROR});

            return {
                done: true,
            };
        }

        toastSuccess(ORDER_MASS_ACTION_MESSAGE.COMPLETE_SELECTION.SUCCESS);

        if (onSuccess) {
            onSuccess();
        }

        yield put({type: ORDERS_END_CONTRACTOR_SEARCH_SUCCESS});
    } catch (error) {
        yield put({type: ORDERS_END_CONTRACTOR_SEARCH_ERROR});
    }
};

const deleteDraftOrderSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/delete-draft-order`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_DELETE_DRAFT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_DELETE_DRAFT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_DELETE_DRAFT_ERROR,
            payload: error,
        });
    }
};

const orderRichReadSaga = function* ({payload}) {
    try {
        const {
            nameField,
            callBack,
            ...params
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/common/order/get-order",
            client: "/client-adm/common/order/get-order",
        });

        const result = yield request.bff.get(url, {params});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CARD_RICH_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (callBack) {
            callBack(result);
        }

        yield put({
            type: ORDER_CARD_RICH_SUCCESS,
            payload: {
                result,
                nameField,
            },
        });
    } catch (error) {
        yield put({
            type: ORDER_CARD_RICH_ERROR,
            payload: error,
        });
    }
};

const getPaidSumSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getOrdersController()}/paid-sum`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_WORK_REPORT_PAID_SUM_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_WORK_REPORT_PAID_SUM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_WORK_REPORT_PAID_SUM_ERROR,
            payload: error,
        });
    }
};

const updateOrderContractorsFoundSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            contractorsFound, orderId, clientId, getResult = () => {
            },
        } = payload;

        const requestBody = {orderId, clientId};

        const result = yield request.bff.post(`${getOrdersController()}/update-order-contractors-found?contractorsFound=${contractorsFound}`, requestBody);

        const {errorMessage} = result;

        getResult(errorMessage);

        if (!errorMessage) {
            toastSuccess(ORDER.ORDER_UPDATE_SUCCESS_TEXT);

            yield put({
                type: UPDATE_ORDER_CONTRACTORS_FOUND_SUCCESS,
            });
        } else {
            toastError(errorMessage);

            yield put({
                type: UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
                payload: result,
            });
        }
    } catch (error) {
        toastError(ORDER.ORDER_UPDATE_ERROR_TEXT);

        yield put({
            type: UPDATE_ORDER_CONTRACTORS_FOUND_ERROR,
            payload: error,
        });
    }
};

//POST //bff/adm/clients/client-card/recruitment/vacancyCandidates/getPageForInvitation
//POST //bff/client-adm/recruitment/vacancyCandidates/getPageForInvitation
//POST //bff/adm/clients/client-card/advertisements/getPageForInvitation
//POST //bff/client-adm/advertisements/getPageForInvitation
//POST //bff/client-adm/contractors/contractors-registry/all/getPageForInvitation
const getOrderListForInvitationSaga = function* ({payload}) {
    try {
        const controller = getBffUrl(
            {
                [LINK_RECRUITMENT]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/recruitment/vacancyCandidates",
                    client: "/client-adm/recruitment/vacancyCandidates",
                }),
                [LINK_ADVERTISEMENT]: getBffControllerClientCardPage({
                    admin: "/adm/clients/client-card/advertisements",
                    client: "/client-adm/advertisements",
                }),
            },
            "/client-adm/contractors/contractors-registry/all",
            true,
        );

        const result = yield request.bff.post(`${controller}/getPageForInvitation`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_LIST_FOR_INVITATION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_LIST_FOR_INVITATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_LIST_FOR_INVITATION_ERROR,
            payload: error,
        });
    }
};

const checkMultipleActionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/check-orders-to-action`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: ORDER_MULTIPLE_CHECK_TO_ACTION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_MULTIPLE_CHECK_TO_ACTION_ERROR,
            payload: error,
        });
    }
};

const checkToWarningBeforePublishSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getOrdersController()}/check-to-warning-before-publish`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess(result);

        yield put({
            type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDERS_GET_PAGE_REQUEST, getPageOrdersSaga),
        takeEvery(ORDERS_ADD_REQUEST, addOrderSaga),
        takeEvery(ORDERS_UPDATE_REQUEST, updateOrderSaga),
        takeEvery(ORDER_CONTRACTOR_HISTORY_REQUEST, orderContractorHistorySaga),
        takeEvery(ORDER_HISTORY_REQUEST, orderHistorySaga),
        takeEvery(ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST, getOrdersNotByTemplateCreationAvailabilitySaga),
        takeEvery(ORDER_ARCHIVE_UPDATE_REQUEST, updateOrderArchiveSaga),
        takeEvery(ORDER_COMPLETE_REQUEST, completeOrderSaga),
        takeEvery(GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST, getWarningBeforePublishOrderSaga),
        takeEvery(CLOSE_ORDERS_REQUEST, closeOrdersSaga),
        takeEvery(ARCHIVE_ORDERS_REQUEST, archiveOrdersSaga),
        takeEvery(DELETE_ORDERS_REQUEST, deleteOrdersSaga),
        takeEvery(PUBLISH_ORDERS_REQUEST, publishOrdersSaga),
        takeEvery(ORDERS_END_CONTRACTOR_SEARCH_REQUEST, ordersEndContractorSearchSaga),
        takeEvery(ORDER_DELETE_DRAFT_REQUEST, deleteDraftOrderSaga),
        takeEvery(ORDER_CARD_RICH_REQUEST, orderRichReadSaga),
        takeEvery(ORDER_WORK_REPORT_PAID_SUM_REQUEST, getPaidSumSaga),
        takeEvery(UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST, updateOrderContractorsFoundSaga),
        takeEvery(ORDER_LIST_FOR_INVITATION_REQUEST, getOrderListForInvitationSaga),
        takeEvery(ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST, checkMultipleActionSaga),
        takeEvery(ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST, checkToWarningBeforePublishSaga),
    ]);
}