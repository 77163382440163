import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../utils/objectHelper";

import {REGISTRY_STATUS_DICT} from "../../../../constants/registry";

export const registryPaymentSelector = state => state.bff.registryPayment;
export const registryPaymentListSelector = createSelector(
    registryPaymentSelector,
    ({list}) => list,
);
export const registryPaymentTotalCountSelector = createSelector(
    registryPaymentSelector,
    ({totalCount}) => totalCount,
);
export const registryPaymentTotalPagesSelector = createSelector(
    registryPaymentSelector,
    ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize),
);
export const registryPaymentProgressListSelector = createSelector(
    registryPaymentSelector,
    ({progressList}) => progressList,
);
export const registriesListFormattedToTaskIdsSelector = createSelector(registryPaymentSelector, ({list}) =>
    list.map(value => `${value.clientId}:${value.registryId}`),
);
export const registriesSuccessAddSelector = createSelector(registryPaymentSelector, ({isSuccessAddOrEdit}) => isSuccessAddOrEdit);
export const registriesSuccessActionSelector = createSelector(registryPaymentSelector, ({isSuccessAction}) => isSuccessAction);
export const registriesActionRegistryProgressSelector = createSelector(registryPaymentSelector, ({actionRegistryProgress}) => actionRegistryProgress);
export const registryStatusDictSelector = createSelector(registryPaymentSelector, ({registryStatusDict}) => registryStatusDict);
export const registryStatusOptionsSelector = createSelector(registryPaymentSelector, ({registryStatusDict, pageData}) => {
    const {archivedFilter} = pageData;
    const options = dictionaryToOptions(registryStatusDict);
    return archivedFilter ? options.filter(item => ![REGISTRY_STATUS_DICT.FOR_PAYMENT.VALUE, REGISTRY_STATUS_DICT.PARTIALLY_PAID.VALUE].includes(item.value)) : options;
});
export const registryPayProcessSelector = createSelector(registryPaymentSelector, ({payProcess}) => payProcess);
export const registryClientPaymentSettingsSelector = createSelector(
    registryPaymentSelector,
    ({paymentSettings}) => paymentSettings,
);