import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {ls, USER_RESTRICTIONS} from "../utils/localstorage";
import request from "../utils/postman";
import {getFormattedFullName} from "../utils/stringFormat";
import {toastError} from "../utils/toastHelper";
import {
    getBffCommonController, getBffControllerClientCardPage,
    getBffUrl,
} from "../utils/url";

const controller = "/clients/users";
//*  TYPES  *//

const CLIENT_MEMBER_READ_REQUEST = "CLIENT_MEMBER_READ_REQUEST";
const CLIENT_MEMBER_READ_SUCCESS = "CLIENT_MEMBER_READ_SUCCESS";
const CLIENT_MEMBER_READ_ERROR = "CLIENT_MEMBER_READ_ERROR";

const CLIENT_MEMBERS_READ_REQUEST = "CLIENT_MEMBERS_READ_REQUEST";
const CLIENT_MEMBERS_READ_SUCCESS = "CLIENT_MEMBERS_READ_SUCCESS";
const CLIENT_MEMBERS_READ_ERROR = "CLIENT_MEMBERS_READ_ERROR";

const CLIENT_CURRENT_MEMBER_READ_REQUEST = "CLIENT_CURRENT_MEMBER_READ_REQUEST";
const CLIENT_CURRENT_MEMBER_READ_SUCCESS = "CLIENT_CURRENT_MEMBER_READ_SUCCESS";
const CLIENT_CURRENT_MEMBER_READ_ERROR = "CLIENT_CURRENT_MEMBER_READ_ERROR";

const CLIENT_MEMBER_CLEAR_STORE = "CLIENT_MEMBER_CLEAR_STORE";

const CLIENT_MEMBER_UPDATE_FIELD_STORE = "CLIENT_MEMBER_UPDATE_FIELD_STORE";

const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST";
const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS";
const SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR = "SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR";

const GET_HIDE_DEPOSIT_DETAILS_REQUEST = "GET_HIDE_DEPOSIT_DETAILS_REQUEST";
const GET_HIDE_DEPOSIT_DETAILS_SUCCESS = "GET_HIDE_DEPOSIT_DETAILS_SUCCESS";
const GET_HIDE_DEPOSIT_DETAILS_ERROR = "GET_HIDE_DEPOSIT_DETAILS_ERROR";

const SET_HIDE_DEPOSIT_DETAILS_REQUEST = "SET_HIDE_DEPOSIT_DETAILS_REQUEST";
const SET_HIDE_DEPOSIT_DETAILS_SUCCESS = "SET_HIDE_DEPOSIT_DETAILS_SUCCESS";
const SET_HIDE_DEPOSIT_DETAILS_ERROR = "SET_HIDE_DEPOSIT_DETAILS_ERROR";

const GET_CLIENT_USER_COUNT_REQUEST = "GET_CLIENT_USER_COUNT_REQUEST";
const GET_CLIENT_USER_COUNT_SUCCESS = "GET_CLIENT_USER_COUNT_SUCCESS";
const GET_CLIENT_USER_COUNT_ERROR = "GET_CLIENT_USER_COUNT_ERROR";

//*  INITIAL STATE  *//

const initial = {
    list: [],
    card: {},
    error: null,
    progress: false,
    currentMember: {},
    adminMembers: [],
    projectManagers: [],
    progressCurrentUser: true,
    hideDepositDetailsProgress: false,
    clientUserCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_MEMBER_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLIENT_MEMBER_CLEAR_STORE:
            return {
                ...initial,
            };
        case CLIENT_CURRENT_MEMBER_READ_REQUEST:
            return {
                ...state,
                progressCurrentUser: true,
            };
        case CLIENT_MEMBERS_READ_REQUEST:
        case CLIENT_MEMBER_READ_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case SET_HIDE_DEPOSIT_DETAILS_REQUEST:
            return {
                ...state,
                hideDepositDetailsProgress: true,
            };
        case CLIENT_CURRENT_MEMBER_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                progressCurrentUser: false,
                currentMember: payload,
            };
        case CLIENT_MEMBERS_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                list: payload,
            };
        case SET_HIDE_DEPOSIT_DETAILS_SUCCESS:
            return {
                ...state,
                hideDepositDetailsProgress: false,
            };
        case CLIENT_CURRENT_MEMBER_READ_ERROR:
            return {
                ...state,
                progressCurrentUser: false,
            };
        case CLIENT_MEMBER_READ_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case GET_CLIENT_USER_COUNT_SUCCESS:
            return {
                ...state,
                clientUserCount: payload,
            };
        case CLIENT_MEMBERS_READ_ERROR:
        case CLIENT_MEMBER_READ_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SET_HIDE_DEPOSIT_DETAILS_ERROR:
            return {
                ...state,
                hideDepositDetailsProgress: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientMemberById(payload) {
    return {
        type: CLIENT_MEMBER_READ_REQUEST,
        payload,
    };
}

export function getClientMemberByIds(payload) {
    return {
        type: CLIENT_MEMBERS_READ_REQUEST,
        payload,
    };
}

export function getClientCurrentMemberByLogin(payload) {
    return {
        type: CLIENT_CURRENT_MEMBER_READ_REQUEST,
        payload,
    };
}

export function clearClientMemberStore() {
    return {
        type: CLIENT_MEMBER_CLEAR_STORE,
    };
}

export function updateFieldClientMemberStore(payload) {
    return {
        type: CLIENT_MEMBER_UPDATE_FIELD_STORE,
        payload,
    };
}

export function sendComplaintOnContractorVaccinationCertificate(payload) {
    return {
        type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST,
        payload,
    };
}

export function getHideDepositDetails(payload) {
    return {
        type: GET_HIDE_DEPOSIT_DETAILS_REQUEST,
        payload,
    };
}

export function setHideDepositDetails(payload) {
    return {
        type: SET_HIDE_DEPOSIT_DETAILS_REQUEST,
        payload,
    };
}

export function getClientUserCount(payload) {
    return {
        type: GET_CLIENT_USER_COUNT_REQUEST,
        payload,
    };
}

//*  SELECTORS  *//

export const clientMemberSelector = state => state.clientMember;

export const clientCurrentMemberFullNameSelector = createSelector(clientMemberSelector, ({currentMember}) => {
    const {
        lastName,
        firstName,
        patronymic,
    } = currentMember || {};

    return lastName ? `${lastName} ${firstName} ${patronymic}` : "";
});

export const getProgressCurrentUser = createSelector(clientMemberSelector, ({progressCurrentUser}) => progressCurrentUser);

export const clientCurrentMemberSelector = createSelector(clientMemberSelector, ({currentMember}) => currentMember || {});

export const clientCurrentMemberIdSelector = createSelector(clientMemberSelector, ({currentMember: {clientUserId}}) => clientUserId);
export const clientCurrentClientIdSelector = createSelector(clientMemberSelector, ({currentMember: {clientId}}) => clientId);

export const clientMemberCardSelector = createSelector(clientMemberSelector, ({card}) => card);

export const clientMemberProgressSelector = createSelector(clientMemberSelector, ({progress}) => progress);

export const clientUserCountSelector = createSelector(clientMemberSelector, ({clientUserCount}) => clientUserCount);

export const clientMemberFullNameClientSelector = createSelector(clientMemberSelector, ({list}) => {
    return list.map(({lastName, clientUserId, firstName, patronymic}) => ({
        clientUserId,
        fullName: `${lastName} ${firstName} ${patronymic || ""}`,
    }));
});

export const hideDepositDetailsProgressSelector = createSelector(clientMemberSelector, ({hideDepositDetailsProgress}) => hideDepositDetailsProgress);

export const currentMemberOptionTypeSelector = createSelector(clientMemberSelector, ({currentMember}) => ([{
    key: currentMember.clientUserId,
    value: currentMember.clientUserId,
    text: getFormattedFullName(`${currentMember.lastName} ${currentMember.firstName} ${currentMember.patronymic}`),
}]));

//*  SAGA  *//

export const clientMemberReadSaga = function* ({payload}) {
    try {
        const {clientUserId} = payload;

        const result = yield request.bff.get(`${getBffCommonController()}/client/get-user-by-id`, {params: {clientUserId}});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBER_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_MEMBER_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBER_READ_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/reports/order-analytics/responses/hired/get-client-users-by-ids
// POST /bff/client-adm/reports/order-analytics/responses/hired/get-client-users-by-ids
export const clientMembersReadSaga = function* ({payload}) {
    try {
        const controller = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/reports/order-analytics/responses/hired",
            client: "/client-adm/reports/order-analytics/responses/hired",
        });

        const result = yield request.bff.post(`${controller}/get-client-users-by-ids`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_MEMBERS_READ_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_MEMBERS_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_MEMBERS_READ_ERROR,
            payload: error,
        });
    }
};

export const clientCurrentMemberReadSaga = function* (action) {
    try {
        const {payload: login} = action;
        const result = yield request.get(`${controller}/getRichByLogin`, {params: {login}});

        const {
            errorMessage,
            userRestrictions,
        } = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_CURRENT_MEMBER_READ_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (userRestrictions) {
            yield ls(USER_RESTRICTIONS, JSON.stringify(userRestrictions));
        }

        yield put({
            type: CLIENT_CURRENT_MEMBER_READ_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_CURRENT_MEMBER_READ_ERROR,
            payload: error,
        });
    }
};

// GET /api/clients/users/sendComplaintOnContractorVaccinationCertificate
const sendComplaintOnContractorVaccinationCertificateSaga = function* ({payload}) {
    try {
        const {getResult, ...params} = payload;

        const result = yield request.get(`${controller}/sendComplaintOnContractorVaccinationCertificate`, {params});

        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_ERROR,
            payload: error,
        });
    }
};

const getHideDepositDetailsSaga = function* ({payload}) {
    try {
        const {
            urls,
            getResult,
        } = payload;

        const url = getBffUrl(
            {
                isClientCard: true,
                ...urls,
            },
        );

        const result = yield request.bff.get(url);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_HIDE_DEPOSIT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_HIDE_DEPOSIT_DETAILS_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: GET_HIDE_DEPOSIT_DETAILS_ERROR,
            payload: error,
        });
    }
};

const setHideDepositDetailsSaga = function* ({payload}) {
    try {
        const {
            urls,
            ...data
        } = payload;

        const url = getBffUrl(
            {
                isClientCard: true,
                ...urls,
            },
        );

        const result = yield request.bff.post(url, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SET_HIDE_DEPOSIT_DETAILS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: SET_HIDE_DEPOSIT_DETAILS_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: SET_HIDE_DEPOSIT_DETAILS_ERROR,
            payload: error,
        });
    }
};

const getClientUserCountSaga = function* ({payload}) {
    try {
        const result = yield request.get(`${controller}/getClientUserCount`, {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_USER_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_USER_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_USER_COUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_MEMBER_READ_REQUEST, clientMemberReadSaga),
        takeEvery(CLIENT_CURRENT_MEMBER_READ_REQUEST, clientCurrentMemberReadSaga),
        takeEvery(CLIENT_MEMBERS_READ_REQUEST, clientMembersReadSaga),
        takeEvery(SEND_COMPLAINT_ON_CONTRACTOR_VACCINATION_CERTIFICATE_REQUEST, sendComplaintOnContractorVaccinationCertificateSaga),
        takeEvery(GET_HIDE_DEPOSIT_DETAILS_REQUEST, getHideDepositDetailsSaga),
        takeEvery(SET_HIDE_DEPOSIT_DETAILS_REQUEST, setHideDepositDetailsSaga),
        takeEvery(GET_CLIENT_USER_COUNT_REQUEST, getClientUserCountSaga),
    ]);
}
