import {all, put, takeEvery} from "@redux-saga/core/effects";

import {DEPOSIT_SUB_PAGE} from "../../../../containers/deposit/client-list";
import {
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST,
    BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS,
    BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
    BFF_CROWD_TASKS_ADD_DRAFT_REQUEST,
    BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST,
    BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_CLOSE_REQUEST,
    BFF_CROWD_TASKS_CLOSE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_DELETE_REQUEST,
    BFF_CROWD_TASKS_DELETE_SUCCESS,
    BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
    BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST,
    BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_EXTEND_REQUEST,
    BFF_CROWD_TASKS_EXTEND_SUCCESS,
    BFF_CROWD_TASKS_GET_BY_ID_ERROR,
    BFF_CROWD_TASKS_GET_BY_ID_REQUEST,
    BFF_CROWD_TASKS_GET_BY_ID_SUCCESS,
    BFF_CROWD_TASKS_GET_PAGE_ERROR,
    BFF_CROWD_TASKS_GET_PAGE_REQUEST,
    BFF_CROWD_TASKS_GET_PAGE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS,
    BFF_CROWD_TASKS_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST,
    BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST,
    BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS,
    BFF_CROWD_TASKS_UPDATE_ERROR,
    BFF_CROWD_TASKS_UPDATE_REQUEST,
    BFF_CROWD_TASKS_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {LINK_OBJECTS, LINK_PROJECTS} from "../../../../constants/links";

const getController = () => {
    return (
        getBffUrl({
            [LINK_OBJECTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/objects/object-card/tasks",
                client: "/client-adm/client/objects/object-card/tasks",
            }),
            [LINK_PROJECTS]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects/project-card/tasks",
                client: "/client-adm/client/projects/project-card/tasks",
            }),
            [DEPOSIT_SUB_PAGE.SPENT_LIMIT]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/finances/npd-deposit/spent-limit/task",
                client: "/client-adm/finances/npd-deposit/spent-limit/task",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/crowd-tasks/registry",
            adminRolesUrl: "/adm/clients/client-card/crowd-tasks/registry",
        },
        "/adm/clients/client-card/crowd-tasks/registry",
        true,
        )
    );
};

const getPageCrowdTasks = function* ({payload}) {
    try {
        const {
            url,
            ...reqData
        } = payload;

        const result = yield request.bff.post(url, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_GET_PAGE_ERROR,
        });
    }
};

const getPageAdminCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.post("/adm/finances/tasks/admin/page", payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADMIN_GET_PAGE_ERROR,
        });
    }
};

const addContractorSearchCrowdTask = function* ({payload}) {
    try {
        const {
            getResult,
            getError,
            isDuplicate,
            ...reqData
        } = payload;

        const otherUrl = isDuplicate
            ? "/task/add/duplicate/contractor-search"
            : "/add/contractor-search";

        const result = yield request.bff.post(`${getController()}${otherUrl}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            if (getError) {
                getError(result);
            }

            yield put({
                type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_ERROR,
        });
    }
};

const addDraftCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            isDuplicate,
            ...reqData
        } = payload;

        const otherUrl = isDuplicate
            ? "/task/add/duplicate/draft"
            : "/add/draft";

        const result = yield request.bff.post(`${getController()}${otherUrl}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_ADD_DRAFT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ADD_DRAFT_ERROR,
        });
    }
};

const updateCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/params/update`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }
        
        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_UPDATE_ERROR,
        });
    }
};

const updateAndPublishCrowdTask = function* ({payload}) {
    try {
        const {
            getResult,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/params/update/and-publish`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_ERROR,
        });
    }
};

const publishCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            getError,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/publish`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            if (getError) {
                getError(result);
            }

            yield put({
                type: BFF_CROWD_TASKS_PUBLISH_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_ERROR,
        });
    }
};

const publishMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/publish/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_PUBLISH_MULTIPLE_ERROR,
        });
    }
};

const archiveCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/add`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_ERROR,
        });
    }
};

const archiveMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/add/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_ERROR,
        });
    }
};

const unarchiveCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/archive/remove`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_ARCHIVE_REMOVE_ERROR,
        });
    }
};

const closeCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/close`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_CLOSE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_CLOSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_CLOSE_ERROR,
        });
    }
};

const closeMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/close/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_CLOSE_MULTIPLE_ERROR,
        });
    }
};

const deleteCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DELETE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_DELETE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DELETE_ERROR,
        });
    }
};

const deleteMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_DELETE_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DELETE_MULTIPLE_ERROR,
        });
    }
};

const getByIdCrowdTask = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/get`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_GET_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_GET_BY_ID_ERROR,
        });
    }
};

const getTotalAmountCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/total-amount`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_TOTAL_AMOUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_TOTAL_AMOUNT_ERROR,
        });
    }
};

const getDepositStateCrowdTasks = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/deposit-state`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_CROWD_TASKS_DEPOSIT_STATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_DEPOSIT_STATE_ERROR,
        });
    }
};

const extendCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/task/extend-work-end-date`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_EXTEND_ERROR,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_EXTEND_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_EXTEND_ERROR,
        });
    }
};

const extendMultipleCrowdTask = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/extend-work-end-date/multiple`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_CROWD_TASKS_EXTEND_MULTIPLE_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_CROWD_TASKS_GET_PAGE_REQUEST, getPageCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_ADD_CONTRACTOR_SEARCH_REQUEST, addContractorSearchCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ADD_DRAFT_REQUEST, addDraftCrowdTask),
        takeEvery(BFF_CROWD_TASKS_UPDATE_REQUEST, updateCrowdTask),
        takeEvery(BFF_CROWD_TASKS_UPDATE_AND_PUBLISH_REQUEST, updateAndPublishCrowdTask),
        takeEvery(BFF_CROWD_TASKS_PUBLISH_REQUEST, publishCrowdTask),
        takeEvery(BFF_CROWD_TASKS_PUBLISH_MULTIPLE_REQUEST, publishMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_ADD_REQUEST, archiveCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_ADD_MULTIPLE_REQUEST, archiveMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ARCHIVE_REMOVE_REQUEST, unarchiveCrowdTask),
        takeEvery(BFF_CROWD_TASKS_CLOSE_REQUEST, closeCrowdTask),
        takeEvery(BFF_CROWD_TASKS_CLOSE_MULTIPLE_REQUEST, closeMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_DELETE_REQUEST, deleteCrowdTask),
        takeEvery(BFF_CROWD_TASKS_DELETE_MULTIPLE_REQUEST, deleteMultipleCrowdTask),
        takeEvery(BFF_CROWD_TASKS_GET_BY_ID_REQUEST, getByIdCrowdTask),
        takeEvery(BFF_CROWD_TASKS_ADMIN_GET_PAGE_REQUEST, getPageAdminCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_TOTAL_AMOUNT_REQUEST, getTotalAmountCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_DEPOSIT_STATE_REQUEST, getDepositStateCrowdTasks),
        takeEvery(BFF_CROWD_TASKS_EXTEND_REQUEST, extendCrowdTask),
        takeEvery(BFF_CROWD_TASKS_EXTEND_MULTIPLE_REQUEST, extendMultipleCrowdTask),
    ]);
}