export const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST";
export const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS";
export const DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR = "DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR";

export const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST";
export const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS";
export const DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR = "DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR";

export const ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST = "ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const ADD_DOCUMENTS_EDO_REGISTRIES_ERROR = "ADD_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST = "UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR = "UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR = "ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR = "CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR = "CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR = "CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR = "SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST = "SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR = "SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST";
export const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS";
export const GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR = "GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR";

export const EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST = "EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST";
export const EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS = "EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS";
export const EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR = "EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR";

export const CHECK_DUPLICATES_REGISTRY_REQUEST = "CHECK_DUPLICATES_REGISTRY_REQUEST";
export const CHECK_DUPLICATES_REGISTRY_SUCCESS = "CHECK_DUPLICATES_REGISTRY_SUCCESS";
export const CHECK_DUPLICATES_REGISTRY_ERROR = "CHECK_DUPLICATES_REGISTRY_ERROR";

export const CONFIRM_DUPLICATES_REGISTRY_REQUEST = "CONFIRM_DUPLICATES_REGISTRY_REQUEST";
export const CONFIRM_DUPLICATES_REGISTRY_SUCCESS = "CONFIRM_DUPLICATES_REGISTRY_SUCCESS";
export const CONFIRM_DUPLICATES_REGISTRY_ERROR = "CONFIRM_DUPLICATES_REGISTRY_ERROR";

export const DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE = "DOCUMENTS_EDO_REGISTRIES_UPDATE_STORE";