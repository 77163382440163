import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isNumber} from "lodash";

import FilterButtonsV2 from "../../../../../components/ActualComponents/FilterButtonsV2";
import NmDateRangePickerV2 from "../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import CheckboxList from "../../../../../components/CheckboxList";
import NmFilterAccordion from "../../../../../components/NmFilterAccordion";
import NmPage from "../../../../../components/NmPage";
import NmTitle from "../../../../../components/NmTitle";

import {useFilter} from "../../../../../hooks/useFilter";
import {usePagination} from "../../../../../hooks/usePagination";

import dateFormat, {formatLocalDate} from "../../../../../utils/dateFormat";
import {getBasePageState} from "../../../../../utils/redux";
import {handleFormString, isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {
    getDeferredActsOfAcceptanceWorkLogPage, updateDeferredActsOfAcceptanceWorkStore,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/actionCreators";
import {
    bffDeferredActsOfAcceptanceWorkLogsSelector,
} from "../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/selectors";

const DeferredActOfAcceptanceWorkLog = (props) => {
    const {
        onClose,
        registry,
    } = props;
    const {
        clientId,
        registryId,
        name,
    } = registry;

    const {
        list,
        totalPages,
        totalCount,
        progress,
    } = useSelector(bffDeferredActsOfAcceptanceWorkLogsSelector);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination();

    const {
        onChangeFilter,
        filter,
        filterData,
        onClear,
        onSearch,
        isSearch,
    } = useFilter({
        initFilter: {
            logStartDateFilter: null,
            logEndDateFilter: null,
            creatorClientUserNameFilter: "",
        },
        setPagination,
        pageSize,
    });

    useEffect(() => {
        return () => {
            dispatch(updateDeferredActsOfAcceptanceWorkStore({
                logs: getBasePageState(),
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        const {
            logStartDateFilter,
            logEndDateFilter,
            creatorClientUserNameFilter,
        } = filterData;

        dispatch(getDeferredActsOfAcceptanceWorkLogPage({
            clientId,
            registryId,
            pageNum,
            pageSize,
            logStartDateFilter: !isNullOrWhitespace(logStartDateFilter)
                ? dateFormat(logStartDateFilter, "yyyy-MM-dd")
                : undefined,
            logEndDateFilter: !isNullOrWhitespace(logEndDateFilter)
                ? dateFormat(logEndDateFilter, "yyyy-MM-dd")
                : undefined,
            creatorClientUserNameFilter: handleFormString(creatorClientUserNameFilter),
        }));
    };

    const getRows = () => {
        return list.map(item => {
            const {
                logId,
                dateTime,
                data,
                eventType,
                initiatingClientUserFio,
            } = item;

            return {
                ...item,
                key: logId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16"
                        primaryHeader={eventType?.description}
                        labels={[
                            {
                                label: "Дата",
                                text: formatLocalDate(dateTime, "dd.MM.yyyy HH:mm"),
                            },
                            {
                                label: "Инициатор",
                                text: initiatingClientUserFio,
                            },
                            data?.contractorFio && {
                                label: "Исполнитель",
                                text: data.contractorFio,
                            },
                            data?.contractorPhone && {
                                label: "Телефон",
                                text: data?.contractorPhone,
                            },
                            isNumber(data?.itemCount) && {
                                label: "Кол-во удаленных строк",
                                text: data.itemCount,
                            },
                        ]}
                    />
                ),
            };
        });
    };

    function renderFilter() {
        return (
            <NmForm
                horizontal
                className="flex align-items-end mb-2 mb-md-0"
            >
                <div className="col-16 col-md-7 col-xl-5">
                    <NmDateRangePickerV2
                        size="lg"
                        startFieldName="logStartDateFilter"
                        endFieldName="logEndDateFilter"
                        value={{
                            logStartDateFilter: filter.logStartDateFilter,
                            logEndDateFilter: filter.logEndDateFilter,
                        }}
                        isClearable
                        label="Период события"
                        onChange={onChangeFilter}
                    />
                </div>
                <div className="col-16 col-md-4 col-xl-6">
                    <NmInputV2
                        size="lg"
                        placeholder="ФИО инициатора"
                        onChange={onChangeFilter}
                        name="creatorClientUserNameFilter"
                        value={filter.creatorClientUserNameFilter}
                    />
                </div>
                <div className="col-16 col-md-4">
                    <FilterButtonsV2
                        onClear={onClear}
                        onSearch={() => onSearch(filter)}
                    />
                </div>
            </NmForm>
        );
    };

    return (
        <NmModal
            size="lg"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    {`Лог по ${name}`}
                </NmTitle>
            }
            loading={progress}
        >
            <NmPage
                overflowUnset
                showHeaderBlock={false}
                noPadding
                typeFilter="horizontal"
                filtersBase={<NmFilterAccordion renderFilter={renderFilter} />}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onPaginationChange={onPaginationChange}
                onChangePageSize={onChangePageSize}
                totalCount={totalCount}
                isEmptyPage={isEmpty(list)}
                emptyPageProps={{
                    isSearch,
                }}
            >
                <CheckboxList rows={getRows()} />
            </NmPage>
        </NmModal>
    );
};

export default DeferredActOfAcceptanceWorkLog;