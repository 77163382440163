import {
    ARCHIVE_ORDERS_REQUEST,
    CLOSE_ORDERS_REQUEST,
    DELETE_ORDERS_REQUEST,
    GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST,
    ORDER_ARCHIVE_UPDATE_REQUEST,
    ORDER_CARD_RICH_REQUEST,
    ORDER_COMPLETE_REQUEST,
    ORDER_CONTRACTOR_HISTORY_REQUEST,
    ORDER_DELETE_DRAFT_REQUEST,
    ORDER_HISTORY_REQUEST,
    ORDER_LIST_FOR_INVITATION_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST,
    ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST,
    ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST,
    ORDER_WORK_REPORT_PAID_SUM_REQUEST,
    ORDERS_ADD_REQUEST,
    ORDERS_END_CONTRACTOR_SEARCH_REQUEST,
    ORDERS_GET_PAGE_REQUEST,
    ORDERS_UPDATE_REQUEST,
    PUBLISH_ORDERS_REQUEST,
    UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST,
    UPDATE_ORDERS_STORE,
} from "./actions";

export const getPageOrders = (payload) => {
    return {
        type: ORDERS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addOrder = (payload) => {
    return {
        type: ORDERS_ADD_REQUEST,
        payload,
    };
};

export const updateOrder = (payload) => {
    return {
        type: ORDERS_UPDATE_REQUEST,
        payload,
    };
};

export const getOrderContractorHistory = (payload) => {
    return {
        type: ORDER_CONTRACTOR_HISTORY_REQUEST,
        payload,
    };
};

export const getOrderHistory = (payload) => {
    return {
        type: ORDER_HISTORY_REQUEST,
        payload,
    };
};

export const getOrdersNotByTemplateCreationAvailability = (payload) => {
    return {
        type: ORDER_NOT_BY_TEMPLATE_CREATION_AVAILABILITY_REQUEST,
        payload,
    };
};

export const completeOrder = (payload) => {
    return {
        type: ORDER_COMPLETE_REQUEST,
        payload,
    };
};

export const updateOrderArchive = (payload) => {
    return {
        type: ORDER_ARCHIVE_UPDATE_REQUEST,
        payload,
    };
};

export const getWarningBeforePublishOrder = (payload) => {
    return {
        type: GET_WARNING_BEFORE_PUBLISH_ORDER_REQUEST,
        payload,
    };
};

export const closeOrders = (payload) => {
    return {
        type: CLOSE_ORDERS_REQUEST,
        payload,
    };
};

export const archiveOrders = (payload) => {
    return {
        type: ARCHIVE_ORDERS_REQUEST,
        payload,
    };
};

export const deleteOrders = (payload) => {
    return {
        type: DELETE_ORDERS_REQUEST,
        payload,
    };
};

export const publishOrders = (payload) => {
    return {
        type: PUBLISH_ORDERS_REQUEST,
        payload,
    };
};

export const ordersEndContractorSearch = (payload) => {
    return {
        type: ORDERS_END_CONTRACTOR_SEARCH_REQUEST,
        payload,
    };
};

export const deleteDraftOrder = (payload) => {
    return {
        type: ORDER_DELETE_DRAFT_REQUEST,
        payload,
    };
};

export const getOrderRichById = (payload) => {
    return {
        type: ORDER_CARD_RICH_REQUEST,
        payload,
    };
};

export const getPaidSumList = (payload) => {
    return {
        type: ORDER_WORK_REPORT_PAID_SUM_REQUEST,
        payload,
    };
};

export const updateOrderContractorsFound = (payload) => {
    return {
        type: UPDATE_ORDER_CONTRACTORS_FOUND_REQUEST,
        payload,
    };
};

export const getOrderListForInvitation = (payload) => {
    return {
        type: ORDER_LIST_FOR_INVITATION_REQUEST,
        payload,
    };
};

export const checkMultipleAction = (payload) => {
    return {
        type: ORDER_MULTIPLE_CHECK_TO_ACTION_REQUEST,
        payload,
    };
};

export const checkToWarningBeforePublish = (payload) => {
    return {
        type: ORDER_MULTIPLE_CHECK_TO_WARNING_BEFORE_PUBLISH_REQUEST,
        payload,
    };
};

export const updateStoreOrders = (payload) => {
    return {
        type: UPDATE_ORDERS_STORE,
        payload,
    };
};