import getOkved from "./getOkved";

import {getPercentFromNumber} from "../../../../../utils/mathHelper";
import {
    getNumberFromFormattedAmount,
    removePhoneMask,
} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";

import {CLIENT_TYPE_CODE} from "../../../../../constants/dicts";

function getNewClientRequestData(props) {
    const {
        formFields,
        nearestOVM,
        categoryList,
    } = props;

    const {
        longitudeFilter,
        latitudeFilter,
        representativePhone,
        representativeEmail,
        inn,
        clientType, registeredAddress,
        representativeName,
        name,
        fullName,
        brand,
        currentCommissionRate,
        civilPaymentCommission,
        contractorCivilOrderPaymentsCommission,
        contractorCivilRegistryPaymentsCommission,
        contractorSmzOrderPaymentsCommission,
        contractorSmzRegistryPaymentsCommission,
        insuranceAvailable,
        categoryId,
        ordersLimit,
        civilOrdersLimit,
        registryPaymentsAvailable,
        civilRegistryPaymentsAvailable,
        foremanFunctionalityAvailable,
        edmAvailable,
        migrantLicensePaymentEnabled,
        migrantLicensePaymentCommission,
        withoutContract,
        minCommissionAmount,
        paymentsThresholdAmount,
        minimalCommissionNdflPaymentsAmount,
        edmTariff,
        clientWorkTypeTariff,
        clientWorkType,
        enableIndividualProjectAndObjectDeposit,
        enableNonDistributedClientDeposit,
    } = formFields;

    const {
        federalId,
        region,
    } = nearestOVM;

    return {
        applicationId: formFields.applicationId,
        latitude: latitudeFilter,
        longitude: longitudeFilter,
        federalId,
        region,
        brand,
        edmAvailable,
        withoutContract,
        migrantLicensePaymentEnabled,
        categoryId,
        registryPaymentsAvailable,
        civilRegistryPaymentsAvailable,
        clientType,
        registeredAddress,
        foremanFunctionalityAvailable,
        insuranceAvailable,
        representativePhone: isNullOrWhitespace(representativePhone) ? null : removePhoneMask(representativePhone.trim()),
        representativeEmail: isNullOrWhitespace(representativeEmail) ? null : representativeEmail.trim(),
        inn: inn.trim(),
        representativeName: isNullOrWhitespace(representativeName) ? null : representativeName.trim(),
        name: clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR ? `ИП ${fullName.trim()}` : name.trim(),
        fullName: fullName.trim(),
        currentCommissionRate: getPercentFromNumber(currentCommissionRate),
        civilPaymentCommission: getPercentFromNumber(civilPaymentCommission),
        contractorCivilOrderPaymentsCommission: getPercentFromNumber(contractorCivilOrderPaymentsCommission),
        contractorCivilRegistryPaymentsCommission: getPercentFromNumber(contractorCivilRegistryPaymentsCommission),
        contractorSmzOrderPaymentsCommission: getPercentFromNumber(contractorSmzOrderPaymentsCommission),
        contractorSmzRegistryPaymentsCommission: getPercentFromNumber(contractorSmzRegistryPaymentsCommission),
        ordersLimit: getNumberFromFormattedAmount(ordersLimit),
        civilOrdersLimit: getNumberFromFormattedAmount(civilOrdersLimit),
        okved: getOkved(categoryId, categoryList),
        ordersUnsecured: true,
        migrantLicensePaymentCommission: migrantLicensePaymentEnabled ? getNumberFromFormattedAmount(migrantLicensePaymentCommission) : undefined,
        minCommissionAmount: getNumberFromFormattedAmount(minCommissionAmount),
        minimalCommissionNdflPaymentsAmount: getNumberFromFormattedAmount(minimalCommissionNdflPaymentsAmount),
        paymentsThresholdAmount: getNumberFromFormattedAmount(paymentsThresholdAmount),
        edmTariff,
        clientWorkTypeTariff,
        clientWorkType,
        enableIndividualProjectAndObjectDeposit,
        enableNonDistributedClientDeposit,
    };
}

export default getNewClientRequestData;