import {
    BFF_ADD_EMAIL_NOTIFICATION_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_REQUEST,
    BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST,
    BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST,
} from "./actions";

export function addEmailNotification(payload) {
    return {
        type: BFF_ADD_EMAIL_NOTIFICATION_REQUEST,
        payload,
    };
}

export function deleteEmailNotification(payload) {
    return {
        type: BFF_DELETE_EMAIL_NOTIFICATION_REQUEST,
        payload,
    };
}

export function getEmailNotificationList(payload) {
    return {
        type: BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST,
        payload,
    };
}

export function deleteEmailNotificationList(payload) {
    return {
        type: BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST,
        payload,
    };
}

export function getEmailNotificationById(payload) {
    return {
        type: BFF_GET_EMAIL_NOTIFICATION_BY_ID_REQUEST,
        payload,
    };
}