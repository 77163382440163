import {
    BFF_ACT_REGISTRIES_ADD_REQUEST,
    BFF_ACT_REGISTRIES_ARCHIVE_REQUEST,
    BFF_ACT_REGISTRIES_DELETE_REQUEST,
    BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_GET_PAGE_REQUEST,
    BFF_ACT_REGISTRIES_UPDATE_REQUEST,
    BFF_ACT_REGISTRIES_UPDATE_STORE,
} from "./actions";

export const getActRegistries = (payload) => ({
    type: BFF_ACT_REGISTRIES_GET_PAGE_REQUEST,
    payload,
});

export const archiveActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_ARCHIVE_REQUEST,
    payload,
});

export const deleteActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_DELETE_REQUEST,
    payload,
});

export const updateActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_UPDATE_REQUEST,
    payload,
});

export const addActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_ADD_REQUEST,
    payload,
});

export const getActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST,
    payload,
});

export const updateActRegistriesStore = (payload) => ({
    type: BFF_ACT_REGISTRIES_UPDATE_STORE,
    payload,
});
