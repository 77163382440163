import {TASK_TYPE} from "../constants/task";

/**
 * Для определения тасков, у которых вывод деталей - это текст (details является строкой)
 * @param type
 * @returns {boolean}
 */
export const isDetailsStringType = (type) => {
    return [
        TASK_TYPE.ENABLE_DISABLE_INDIVIDUAL_DEPOSIT.VALUE,
    ].includes(type);
};