import {createSelector} from "reselect";

import {getTotalPages} from "../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../utils/objectHelper";
import {getClientUsagesOrderTypesSortOptions} from "../../../utils/selectors";

export const documentsCustomTemplateSelector = state => state.bff.documentsTemplates;
export const documentCustomTemplateTotalPagesSelector = createSelector(documentsCustomTemplateSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const documentCustomTemplateTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({totalCount}) => totalCount);
export const documentCustomTemplateListSelector = createSelector(documentsCustomTemplateSelector, ({list}) => list);
export const documentCustomTemplateProgressSelector = createSelector(documentsCustomTemplateSelector, ({progress}) => progress);
export const documentCustomTemplateProgressActionSelector = createSelector(documentsCustomTemplateSelector, ({progressAction}) => progressAction);
export const documentCustomTemplateFetchErrorSelector = createSelector(documentsCustomTemplateSelector, ({fetchError}) => fetchError);
export const documentCustomTemplateProgressFileSelector = createSelector(documentsCustomTemplateSelector, ({progressFile}) => progressFile);
export const documentCustomTemplateShortListOptionsSelector = createSelector(documentsCustomTemplateSelector, ({shortList}) => {
    return shortList.map(item => {
        return {
            key: item.id,
            value: item.id,
            text: item.name,
        };
    });
});
export const documentCustomTemplateAggregationShortTemplatesSelector = createSelector(documentsCustomTemplateSelector, ({aggregationShortTemplates}) => {
    return Object.keys(aggregationShortTemplates).reduce((accum, key) => {
        accum[key] = aggregationShortTemplates[key].map(item => {
            return {
                key: item.id,
                value: item.id,
                text: item.name,
            };
        });

        return accum;
    }, {});
});
export const documentCustomTemplateEventLogTypeDictSelector = createSelector(documentsCustomTemplateSelector, ({eventLogTypeDict}) => eventLogTypeDict);
export const documentCustomTemplateEventLogTypeOptionsSelector = createSelector(documentsCustomTemplateSelector, ({eventLogTypeDict}) => dictionaryToOptions(eventLogTypeDict));
export const documentCustomTemplateLogsTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({templateLogsTotalCount, templateLogsPageData: {pageSize = 0}}) => getTotalPages(templateLogsTotalCount, pageSize));
export const documentCustomTemplateLogsTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({templateLogsTotalCount}) => templateLogsTotalCount);
export const documentCustomTemplateLogsListSelector = createSelector(documentsCustomTemplateSelector, ({templateLogs}) => templateLogs);
export const documentCustomTemplateClientUsageTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({clientUsageTotalCount, clientUsagePageData: {pageSize = 0}}) => getTotalPages(clientUsageTotalCount, pageSize));
export const documentCustomTemplateClientUsageTotalCountSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageTotalCount}) => clientUsageTotalCount);
export const documentCustomTemplateClientUsageListSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageList}) => clientUsageList);
export const documentCustomTemplateClientUsagesActionTypeDictSelector = createSelector(documentsCustomTemplateSelector, ({clientUsageActionTypeDict}) => clientUsageActionTypeDict);

export const documentCustomTemplateClientUsageOrderTypesSortOptionsSelector = createSelector(
    documentsCustomTemplateSelector,
    ({clientUsageOrderTypesDict}) => getClientUsagesOrderTypesSortOptions(clientUsageOrderTypesDict),
);
export const frameContractTemplatesListSelector = createSelector(documentsCustomTemplateSelector, ({frameContractTemplatesList}) => frameContractTemplatesList);
export const actTemplatesOptionsSelector = createSelector(documentsCustomTemplateSelector, ({actTemplatesList}) => {
    return actTemplatesList.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name,
    }));
},
);
export const documentCustomTemplateEventLogListSelector = createSelector(documentsCustomTemplateSelector, ({eventLogs}) => eventLogs);
export const documentCustomTemplateEventLogFetchErrorListSelector = createSelector(documentsCustomTemplateSelector, ({fetchErrorLogs}) => fetchErrorLogs);
export const documentCustomTemplateDefaultAggregationSelector = createSelector(documentsCustomTemplateSelector, ({defaultTemplatesAggregation}) => defaultTemplatesAggregation);
export const documentCustomTemplateEventLogTotalCountSelector =     createSelector(documentsCustomTemplateSelector, ({eventLogsTotalCount}) => eventLogsTotalCount);
export const documentCustomTemplateEventLogTotalPagesSelector =
    createSelector(documentsCustomTemplateSelector, ({eventLogsTotalCount, eventLogsPageData: {pageSize = 0}}) => getTotalPages(eventLogsTotalCount, pageSize));
export const documentCustomTemplateReplacementsForUnavailableSelector = createSelector(documentsCustomTemplateSelector, ({replacementsForUnavailableList}) =>
    replacementsForUnavailableList);
export const documentCustomTemplateProgressReplacementsForUnavailableSelector = createSelector(documentsCustomTemplateSelector, ({progressReplacementsForUnavailable}) =>
    progressReplacementsForUnavailable);
export const documentCustomTemplateLogsProgressSelector = createSelector(documentsCustomTemplateSelector, ({templateLogsProgress}) => templateLogsProgress);
export const customDocumentTemplatesDisabledForClientSelector = createSelector(documentsCustomTemplateSelector, ({customDocumentTemplatesDisabledForClient}) => customDocumentTemplatesDisabledForClient);
export const clientDefaultFrameContractChangedSelector = createSelector(documentsCustomTemplateSelector, ({clientDefaultFrameContractChanged}) => clientDefaultFrameContractChanged);