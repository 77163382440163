import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import OrderTemplateInfo from "../../order/template-info";
import {DocumentsDeferredActsOfAcceptanceWorkEdit} from "./components/edit";
import DeferredActOfAcceptanceWorkLog from "./components/log";

import {usePagination} from "../../../hooks/usePagination";
import {useSuccessActionCallback} from "../../../hooks/useSuccessAction";
import {useDeferredActsOfAcceptanceWorkAction} from "./hooks/useAction";
import {useDeferredActsOfAcceptanceWorkFetch} from "./hooks/useFetch";
import {useDeferredActsOfAcceptanceWorkFilters} from "./hooks/useFilters";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {formatLocalDate} from "../../../utils/dateFormat";
import {getFullName} from "../../../utils/stringFormat";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {
    DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD,
} from "../../../constants/documentType";
import {
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
} from "../../../constants/links";

import {updateDeferredActsOfAcceptanceWorkStore} from "../../../ducks/bff/documents/deferred-acts-of-acceptance-work/actionCreators";
import {
    bffDeferredActsOfAcceptanceWorkErrorSelector,
    bffDeferredActsOfAcceptanceWorkListSelector,
    bffDeferredActsOfAcceptanceWorkProgressActionSelector,
    bffDeferredActsOfAcceptanceWorkProgressSelector,
    bffDeferredActsOfAcceptanceWorkTotalCountSelector,
    bffDeferredActsOfAcceptanceWorkTotalPagesSelector,
} from "../../../ducks/bff/documents/deferred-acts-of-acceptance-work/selectors";

export const DocumentsDeferredActsOfAcceptanceWork = (props) => {
    const clientId = props.match.params.clientId;

    const [archivedFilter, setArchivedFilter] = useState(false);
    const [setOpenFilter, openFilter] = useState(true);

    const {t} = useTranslation();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
    } = useDeferredActsOfAcceptanceWorkFilters({
        pageSize,
        setPagination,
        clientId,
    });

    useDeferredActsOfAcceptanceWorkFetch({
        pageNum,
        pageSize,
        filterData,
        archivedFilter,
        clientId,
    });

    const list = useSelector(bffDeferredActsOfAcceptanceWorkListSelector);
    const totalCount = useSelector(bffDeferredActsOfAcceptanceWorkTotalCountSelector);
    const totalPages = useSelector(bffDeferredActsOfAcceptanceWorkTotalPagesSelector);
    const isLoading = useSelector(bffDeferredActsOfAcceptanceWorkProgressSelector);
    const error = useSelector(bffDeferredActsOfAcceptanceWorkErrorSelector);
    const progressAction = useSelector(bffDeferredActsOfAcceptanceWorkProgressActionSelector);

    const {
        modalData,
        onCloseModal,
        getMediaControls,
    } = useDeferredActsOfAcceptanceWorkAction({
        isList: true,
        clientId,
    });

    const dispatch = useDispatch();

    useSuccessActionCallback({
        callback: () => {
            if (error) {

                dispatch(updateDeferredActsOfAcceptanceWorkStore({
                    error: null,
                }));
            }

            onCloseModal();
        },
        progressAction,
        error,
    });


    const getConfirmWindow = () => {
        if (!modalData?.confirmProps) {
            return null;
        }

        return (
            <NmConfirmV2
                mobile="column"
                content={modalData.confirmProps.content}
                onCancel={onCloseModal}
                onConfirm={modalData.confirmProps.onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
            />
        );
    };

    const getPrimaryHeader = (item) => {
        const link = LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD
            .replace(":clientId", clientId)
            .replace(":id", item.registryId);

        return (
            <ExtLink
                historyEnabled
                title="Перейти в карточку"
                to={link}
            >
                {item.name}
            </ExtLink>
        );
    };

    const getEditModal = () => {
        if (!modalData?.isOpenEdit) {
            return null;
        }

        return (
            <DocumentsDeferredActsOfAcceptanceWorkEdit
                registryId={modalData?.registryId}
                clientId={clientId}
                onClose={onCloseModal}
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            const link = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
                .replace(":registryId", item.paymentRegistryId)
                .replace(":archived", item.archived)
                .replace(":clientId", clientId)
                .replace("/:paymentNumberFilter?", "");

            return {
                ...item,
                key: item.registryId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-8"
                        primaryHeaderLink
                        primaryHeader={getPrimaryHeader(item)}
                        secondaryHeader={`Реестр от ${formatLocalDate(item.creationDateTime, "dd.MM.yyyy HH:mm")}`}
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={DOCUMENT_ADDITIONAL_AGREEMENT_REGISTRY_STATUS_BADGE_STATUS_MOD[item.status?.value]}
                                text={item.status?.description}
                            />
                        }
                        labels={[
                            {
                                label: "Реестр оплат",
                                text: (
                                    <ExtLink
                                        historyEnabled
                                        title="Перейти в карточку"
                                        to={link}
                                    >
                                        {`Реестр оплат №${item.paymentRegistryNumber}`}
                                    </ExtLink>
                                ),
                            },
                            {
                                label: "Комментарий к реестру",
                                text: item.commentary,
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            {
                                label: "Ответственный",
                                inline: true,
                                noWrap: false,
                                text: getFullName(
                                    item.creatorClientUserLastName,
                                    item.creatorClientUserFirstName,
                                    item.creatorClientUserPatronymic,
                                ),
                            },
                            {
                                textTitle: item.templateName,
                                label: "Шаблон акта",
                                text: (
                                    <OrderTemplateInfo
                                        id={item.templateId}
                                        linkName={item.templateName}
                                        link={item.templatePdfDownloadLink}
                                    />
                                ),
                            },
                        ]}
                        cards={[
                            {
                                title: "Всего / Подписано / В процессе / Отклонено",
                                values: [
                                    {
                                        bold: true,
                                        text: item.totalItemsCount,
                                    },
                                    {
                                        text: item.signedItemsCount,
                                    },
                                    {
                                        text: item.submittedItemsCount,
                                    },
                                    {
                                        text: item.declinedItemsCount,
                                    },
                                ],
                                width: 278,
                                className: "d-flex justify-content-start justify-content-xxl-end mt-md-4 mt-xxl-0",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-7 align-items-end"
                        mediaControls={getMediaControls(item)}
                        actionsClassName="col-1"
                        primaryHeaderWordBreak
                    />
                ),
            };
        });
    };

    const mediaControls = useMemo(() => {
        const archive = getArchiveButton(t, archivedFilter, {mobile: true, tablet: true});

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        ...archive.props,
                        onClick: () => {
                            setArchivedFilter(!archivedFilter);
                        },
                    },
                },
            ],
        };
    }, [
        archivedFilter,
    ]);

    const getLogModal = () => {
        if (!modalData?.isOpenLog) {
            return null;
        }

        return (
            <DeferredActOfAcceptanceWorkLog
                registry={modalData.item}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <NmPage
            header={
                <>
                    <NmTitle
                        count={totalCount}
                        size="xl"
                    >
                        Реестры отложенных актов
                    </NmTitle>
                    {getConfirmWindow()}
                    {getEditModal()}
                    {getLogModal()}
                </>
            }
            mediaControls={mediaControls}
            openFilter={openFilter}
            onOpenFilter={setOpenFilter}
            typeFilter="vertical"
            widthByFilter
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={isLoading}
            isEmptyPage={isEmpty(list)}
            emptyPageProps={{
                isSearch,
            }}
        >
            <CheckboxList rows={getRows()} />
        </NmPage>
    );
};