import React, {useEffect,useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmButton from "../../components/NmButton";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmForm from "../ActualComponents/NmForm";
import Text from "../ActualComponents/Text";
import {STATUS_PASSPORT_INN} from "../NmStatusPassportInnContractor";
import {ContractorBankSenderLogs} from "./Logs";
import ContractorBankSenderStatus from "./Status";

import {useModal} from "../../hooks/useModal";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";
import {toastSuccess} from "../../utils/toastHelper";

import {BANK_TYPE} from "../../constants/clientSettings";
import {COLOR} from "../../constants/color";
import {
    CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS,
    CONTRACTOR_REGISTRATION_STATUS,
} from "../../constants/contractor";
import {CONTRACTOR_BANK_IDENTIFICATION_STATUS} from "../../constants/contractorBanks";
import {BANK_IDENTIFICATION_STATUS} from "../../constants/directoriesBanks";
import {
    ADMIN,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
} from "../../constants/roles";

import {
    getInfoContractorCard,
} from "../../ducks/bff/contractor-сard/actionCreators";
import {
    createContractorBankIdentificationRequest,
    sendingContractorToBank,
    sendToContractorBankForcibly,
} from "../../ducks/bff/contractor-сard/banks/actionCreators";
import {
    contractorBanksSendingProgressForciblySelector,
    getContractorBanksProgressActionSelector,
} from "../../ducks/bff/contractor-сard/banks/selectors";

import PropTypes from "prop-types";

import "./style.sass";

const ContractorBankSender = (props) => {
    const {
        lastIdentificationRequest,
        bankIdentificationStatus,
        contractorId,
        registrationAllowedWithoutInn,
        registrationStatus = "",
        archived,
        contractorDataToBankState,
        className,
        innPassportDataValidStatus,
        blocked,
        bankType,
    } = props;

    const {
        canSendPersonalData,
        canSendDocumentScans,
    } = contractorDataToBankState || {};

    const role = ls(USER_ROLE);
    const isNmManager = role === NM_MANAGER;
    const isNmOperator = role === NM_OPERATOR;

    const [isOpenWarningConfirm, setOpenWarningConfirm] = useState(false);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const [block, element] = bem("contractor-bank-sender", className);

    const dispatch = useDispatch();

    const progressSendingBank = useSelector(getContractorBanksProgressActionSelector);
    const progressForciblySendingToBank = useSelector(contractorBanksSendingProgressForciblySelector);

    useEffect(() => {
        const disabled = role === ADMIN ? false : [
            NM_COORDINATOR,
            NM_CONSULTANT,
        ].includes(role) || canSendPersonalData === false;

        const initialState = [
            {
                checked: canSendPersonalData === true,
                name: "sendPersonalData",
                label: "Персональные данные",
                disabled,
            },
            {
                checked: canSendDocumentScans === true,
                name: "sendDocumentScans",
                label: "Сканы документов",
                disabled,
            },
        ];

        setVariants(initialState);
    }, [canSendDocumentScans, canSendPersonalData]);

    const [variants, setVariants] = useState([]);

    const isSomeChecked = variants
        .reduce((acc, {checked}, i) => {
            acc[i] = checked;

            return acc;
        }, [])
        .some(item => item === true);

    const handleToggleVariants = (e, name) => {
        const newVariants = variants.map(item => {
            if (item.name === name) {
                return {
                    ...item,
                    checked: !item.checked,
                };
            }

            return item;
        });

        setVariants(newVariants);
    };

    const send = (isForcibly) => {
        const checkBoxesData = variants.reduce((acc, curr) => {
            acc[curr.name] = curr.checked;

            return acc;
        }, {});

        if (isForcibly) {
            dispatch(sendToContractorBankForcibly({
                contractorId,
                bankType,
                ...checkBoxesData,
                onSuccess: () => {
                    toastSuccess("Операция успешно выполнена");

                    dispatch(getInfoContractorCard(contractorId));
                },
            }));

            return;
        }

        dispatch(sendingContractorToBank({
            contractorId,
            bankType,
            ...checkBoxesData,
            onSuccess: () => {
                toastSuccess("Запущена задача на отправку в банк");
                dispatch(getInfoContractorCard(contractorId));
            },
        }));
    };

    const handleSend = () => {
        if (
            [
                STATUS_PASSPORT_INN.NOT_EXIST.VALUE,
                STATUS_PASSPORT_INN.NOT_EQUALS.VALUE,
                STATUS_PASSPORT_INN.NOT_CHECKED.VALUE,
                STATUS_PASSPORT_INN.FNS_ERROR.VALUE,
            ].includes(innPassportDataValidStatus)
        ) {
            setOpenWarningConfirm(true);

            return;
        }

        send();
    };

    function isDisabled() {
        if ([NM_COORDINATOR, NM_CONSULTANT].includes(role)) {
            return true;
        }

        if (role === ADMIN) {
            return !isSomeChecked || archived || progressSendingBank || blocked;
        }

        return progressSendingBank ||
            [CONTRACTOR_REGISTRATION_STATUS.BASIC_REGISTRATION].includes(registrationStatus) ||
            !isSomeChecked ||
            archived ||
            blocked;
    }

    const getTextModal = () => {
        if ((isNmManager || isNmOperator) && !registrationAllowedWithoutInn) {
            return "Согласование невозможно, поскольку не установлено соответствие ИНН и паспортных данных";
        }

        if (
            [
                STATUS_PASSPORT_INN.NOT_CHECKED.VALUE,
                STATUS_PASSPORT_INN.FNS_ERROR.VALUE,
            ].includes(innPassportDataValidStatus)) {
            return "Проверка соответствия ИНН и паспортных данных еще не проводилась либо сервис ФНС России " +
                "недоступен для проверки. Вы уверены, что хотите отправить исполнителя на проверку в банк?";
        }

        return "ИНН не соответствует паспортным данным. Вы уверены, что хотите отправить исполнителя на проверку в банк?";
    };

    const getHermesW1Controls = () => {
        const firstPartMessage = "Вы действительно хотите подать заявку для получения исполнителем статуса";
        const secondPartMessage = (
            <React.Fragment>
                и отправить документы исполнителя в&nbsp;банк&nbsp;РНКО?
            </React.Fragment>
        );

        const getMessage = (status) => {
            return (
                <>
                    {firstPartMessage}
                    {` "${status}" `}
                    {secondPartMessage}
                </>
            );
        };

        return (
            <>
                {
                    bankIdentificationStatus === CONTRACTOR_BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED.VALUE &&
                    !lastIdentificationRequest &&
                    <div className={element("button-container")}>
                        <NmButton
                            size="sm"
                            color="grey"
                            onClick={() => {
                                onOpenModal({
                                    isOpenConfirm: true,
                                    bankType,
                                    confirmProps: {
                                        content: getMessage("Упрощенная идентификация"),
                                        onConfirm: () => {
                                            dispatch(createContractorBankIdentificationRequest({
                                                contractorId,
                                                bankType: BANK_TYPE.HERMES_W1.VALUE,
                                                identificationLevel: CONTRACTOR_BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED.VALUE,
                                            }));
                                        },
                                    },
                                });
                            }}
                        >
                            Упрощенная идентификация
                        </NmButton>
                    </div>
                }
                {
                    [
                        CONTRACTOR_BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED.VALUE,
                        CONTRACTOR_BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED.VALUE,
                    ].includes(bankIdentificationStatus) &&
                    ![
                        CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.CREATED,
                        CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.IN_PROGRESS,
                        CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.ACCEPTED,
                        CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.ACCEPTING,
                        CONTRACTOR_BANK_IDENTIFICATION_REQUEST_STATUS.REJECTING,
                    ].includes(lastIdentificationRequest?.status) &&
                    <div className={element("button-container")}>
                        <NmButton
                            size="sm"
                            color="grey"
                            onClick={() => {
                                onOpenModal({
                                    isOpenConfirm: true,
                                    confirmProps: {
                                        content: getMessage("Полная идентификация"),
                                        onConfirm: () => {
                                            dispatch(createContractorBankIdentificationRequest({
                                                contractorId,
                                                bankType: BANK_TYPE.HERMES_W1.VALUE,
                                                identificationLevel: CONTRACTOR_BANK_IDENTIFICATION_STATUS.IDENTIFIED.VALUE,
                                            }));
                                        },
                                    },
                                });
                            }}
                        >
                            Полная идентификация
                        </NmButton>
                    </div>
                }
                <div className={element("button-container")}>
                    <NmButton
                        size="sm"
                        color="grey"
                        onClick={() => {
                            onOpenModal({
                                isOpenLogs: true,
                            });
                        }}
                    >
                        Лог
                    </NmButton>
                </div>
            </>
        );
    };

    const getSendingStatus = () => {
        const marginRight = [BANK_TYPE.HERMES_W1.VALUE].includes(bankType) ? "me-xl-1" : "me-xl-4";
        const className = `d-flex text-no-wrap ${marginRight}`;

        return (
            <Text
                level="3"
                className={className}
                color={COLOR.SECONDARY_70}
            >
                Отправка в банк:
                {
                    [BANK_TYPE.HERMES_W1.VALUE].includes(bankType) &&
                    <ContractorBankSenderStatus
                        className="ms-1"
                        lastIdentificationRequest={lastIdentificationRequest}
                    />
                }
            </Text>
        );
    };

    return (
        <div className={block()}>
            {
                modalData?.isOpenLogs &&
                <ContractorBankSenderLogs
                    onClose={onCloseModal}
                    contractorId={contractorId}
                    lastIdentificationRequest={lastIdentificationRequest}
                />
            }
            {
                modalData?.isOpenConfirm &&
                <NmConfirmV2
                    content={modalData.confirmProps.content}
                    onCancel={onCloseModal}
                    isNeedClosing={false}
                    onConfirm={modalData.confirmProps.onConfirm}
                    disabled={progressSendingBank}
                    confirmButton="Подтвердить"
                    cancelButton="Отклонить"
                />
            }
            {
                isOpenWarningConfirm &&
                <NmConfirmV2
                    size="sm"
                    content={getTextModal()}
                    onCancel={() => {
                        setOpenWarningConfirm(false);
                    }}
                    onConfirm={() => {
                        !isNmManager && !isNmOperator && send();
                        setOpenWarningConfirm(false);
                    }}
                    confirmButton={isNmManager || isNmOperator ? "Ок" : "Отправить"}
                    cancelButton={isNmManager || isNmOperator ? "" : "Отменить"}
                    cancelBtnColor="red"
                />
            }
            {getSendingStatus()}
            <NmForm className="flex flex-wrap mt-3 mt-md-4 mt-xl-0">
                {
                    [BANK_TYPE.HERMES_W1.VALUE].includes(bankType) ?
                        getHermesW1Controls() :
                        <>
                            <div className={element("checkbox-group")}>
                                {
                                    variants.map(({name, label, checked, disabled}) => (
                                        <NmCheckboxV2
                                            className={element("checkbox")}
                                            key={name}
                                            label={label}
                                            checked={checked === true && disabled === false}
                                            onChange={(e) => {
                                                handleToggleVariants(e, name);
                                            }}
                                            disabled={disabled}
                                        />
                                    ))
                                }
                            </div>
                            <div className={element("button-container")}>
                                <NmButton
                                    size="sm"
                                    color="grey"
                                    disabled={isDisabled()}
                                    onClick={handleSend}
                                >
                                    Отправить в банк
                                </NmButton>
                            </div>
                            {
                                [BANK_TYPE.PLUTONIUM_W1.VALUE].includes(bankType) &&
                                <div className={element("button-container")}>
                                    <NmButton
                                        size="sm"
                                        color="grey"
                                        disabled={
                                            ![ADMIN].includes(role) || progressForciblySendingToBank
                                        }
                                        onClick={() => {
                                            send(true);
                                        }}
                                    >
                                        Отправить в банк принудительно
                                    </NmButton>
                                </div>
                            }
                        </>
                }
            </NmForm>
        </div>
    );
};

ContractorBankSender.propTypes = {
    className: PropTypes.string,
};

export default ContractorBankSender;