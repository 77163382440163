import {createSelector} from "reselect";

import {getFullName} from "../../../utils/stringFormat";

const disputesSelector = state => state.bff.disputes;

export const disputeListSelector = createSelector(
    disputesSelector,
    ({disputeList}) => disputeList,
);

export const disputeTotalCountForOrderCardSelector = createSelector(
    disputesSelector,
    ({totalCount}) => totalCount,
);

export const disputeTotalPagesSelector = createSelector(
    disputesSelector,
    ({totalCount, pageData}) => {
        const {pageSize} = pageData;

        return pageSize ? Math.ceil(totalCount / pageSize) : 0;
    },
);

export const disputesTotalCountOpenSelector = createSelector(
    disputesSelector,
    ({totalCountOpen}) => totalCountOpen,
);

export const disputeListLoadingSelector = createSelector(
    disputesSelector,
    ({loading}) => loading,
);

export const disputesActionProgressSelector = createSelector(
    disputesSelector,
    ({actionProgress}) => actionProgress,
);

export const disputeSaveSuccessSelector = createSelector(
    disputesSelector,
    ({isDisputeSaveSuccess}) => isDisputeSaveSuccess,
);

export const emptyDisputeGuidSelector = createSelector(
    disputesSelector,
    ({guid}) => guid,
);

export const disputeChangeStatusSuccessSelector = createSelector(
    disputesSelector,
    ({isDisputeChangeStatusSuccess}) => isDisputeChangeStatusSuccess,
);

export const disputesMenuTotalCountOpenSelector = createSelector(
    disputesSelector,
    ({totalCountDisputesOpen}) => totalCountDisputesOpen,
);

export const disputesChatListSelector = createSelector(
    disputesSelector,
    ({chatList}) => chatList,
);

export const disputeSelector = createSelector(
    disputesSelector,
    ({dispute}) => dispute,
);

export const disputeLoadingSelector = createSelector(
    disputesSelector,
    ({disputeLoading}) => disputeLoading,
);

export const disputeImageListSelector = createSelector(disputesSelector, ({disputeImages}) => {
    return disputeImages.map(item => ({
        ...item,
        isRemote: true,
        type: "image/*",
        name: item.fileName,
    }));
});

export const disputeChatAddToChatSuccessSelector = createSelector(
    disputesSelector,
    ({isAddToChatSuccess}) => isAddToChatSuccess,
);

export const disputeOrderContractorOptionsListSelector = createSelector(
    disputesSelector,
    ({orderContractors: {list}}) => list?.map(({contractorId: value, firstName, lastName, patronymic}) => ({
        key: value, value, text: getFullName(lastName, firstName, patronymic),
    })),
);

export const disputeFileIsSuccessAddSelector = createSelector(disputesSelector, ({isSuccessAddFile}) => isSuccessAddFile);
export const disputeFileIsSuccessDeleteSelector = createSelector(disputesSelector, ({isSuccessDeleteFile}) => isSuccessDeleteFile);

