import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_SETTINGS_EMPLOYEES_ADD_ERROR,
    BFF_SETTINGS_EMPLOYEES_ADD_REQUEST,
    BFF_SETTINGS_EMPLOYEES_ADD_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_ARCHIVE_ERROR,
    BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_ARCHIVE_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_ERROR,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS,
    BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR,
    BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST,
    BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT_MEMBER, MEMBER_ARCHIVE_SUCCESS} from "../../../../constants/messages";

const controller = "/adm/settings/employees";

// POST /bff/adm/settings/employees/page
// Получение списка сотрудников Наймикс
const getPageSettingsEmployees = function* ({payload}) {
    try {
        const {getResult, ...reqData} = payload;

        const result = yield request.bff.post(`${controller}/page`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_EMPLOYEES_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: BFF_SETTINGS_EMPLOYEES_GET_PAGE_SUCCESS,
            payload: {
                ...result,
            },
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_EMPLOYEES_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

const generatePasswordSettingsEmployees = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/generate-password`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_ERROR,
            payload: error,
        });
    }
};

const archiveSettingsEmployees = function* ({payload}) {
    try {
        const {
            canFetchList = true,
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.put(`${controller}/archive`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_EMPLOYEES_ARCHIVE_ERROR,
                payload: result,
            });
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(data.archive ? MEMBER_ARCHIVE_SUCCESS.TO : MEMBER_ARCHIVE_SUCCESS.FROM);

        yield put({
            type: BFF_SETTINGS_EMPLOYEES_ARCHIVE_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({
            type: BFF_SETTINGS_EMPLOYEES_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

const updateSettingsEmployees = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${controller}/update`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(CLIENT_MEMBER.UPDATE_SUCCESS_TEXT);

        yield put({
            type: BFF_SETTINGS_EMPLOYEES_UPDATE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_EMPLOYEES_UPDATE_ERROR,
            payload: error,
        });
    }
};

const addSettingsEmployees = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${controller}/add`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_SETTINGS_EMPLOYEES_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(CLIENT_MEMBER.ADD_SUCCESS_TEXT);

        yield put({
            type: BFF_SETTINGS_EMPLOYEES_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_SETTINGS_EMPLOYEES_ADD_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_SETTINGS_EMPLOYEES_GET_PAGE_REQUEST, getPageSettingsEmployees),
        takeEvery(BFF_SETTINGS_EMPLOYEES_GENERATE_PASSWORD_REQUEST, generatePasswordSettingsEmployees),
        takeEvery(BFF_SETTINGS_EMPLOYEES_ARCHIVE_REQUEST, archiveSettingsEmployees),
        takeEvery(BFF_SETTINGS_EMPLOYEES_UPDATE_REQUEST, updateSettingsEmployees),
        takeEvery(BFF_SETTINGS_EMPLOYEES_ADD_REQUEST, addSettingsEmployees),
    ]);
}