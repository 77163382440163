import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import CheckboxList from "../../../../components/CheckboxList";
import ExtLink from "../../../../components/ExtLink";
import NmTitle from "../../../../components/NmTitle";
import {withPageData} from "../../../../components/withPageData";

import {getTotalPages} from "../../../../utils/mathHelper";

import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_ORDER_CARD,
} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

import {getOrderAnalyticsPage} from "../../../../ducks/bff/clients/order-analytics/actionCreators";
import {orderAnalyticsPageSelector} from "../../../../ducks/bff/clients/order-analytics/selectors";
import {orderProgressSelector} from "../../../../ducks/order";

import "./style.sass";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../constants/link-params";

class OrderDetailAnalytics extends Component {
    constructor(props) {

        super(props);

        const {pageData} = props;

        this.state = {
            pageNum: pageData.pageNum || 1 ,
            pageSize: pageData.pageSize || 10,
        };
    }

    componentDidMount() {
        this.fetchList();
        this.clearWindowHistory();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            regionsFilter,
            objectIdsFilter,
            projectIdsFilter,
        } = this.props;

        const filter = {
            regionsFilter,
            objectIdsFilter,
            projectIdsFilter,
        };
        const prevFilter = {
            regionsFilter: prevProps.regionsFilter,
            objectIdsFilter: prevProps.objectIdsFilter,
            projectIdsFilter: prevProps.projectIdsFilter,
        };

        if (!isEqual(filter, prevFilter)) {
            this.fetchList();
        }
    }

    clearWindowHistory = () => {
        const {pathname} = this.props;
        const fullState = window.history.state || {};

        const {state} = fullState;

        if (state) {
            history.replace(pathname, {...state, filterData: null, pageData: null});
        }
    };

    fetchList = () => {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            projectIdsFilter,
            objectIdsFilter,
            regionsFilter,
            clientId,
            type,
        } = this.props;

        const {getOrderAnalyticsPage} = this.props;

        const requestData = {
            addressFiasIds: regionsFilter,
            objectIdsFilter,
            projectIdsFilter,
            clientId,
            pageNum,
            pageSize,
            type,
        };

        getOrderAnalyticsPage(requestData);
    };

    renderNameLink = ({orderNum, orderName, orderId}) => {
        const {
            clientId,
            regionsFilter,
            objectIdsFilter,
            projectIdsFilter,
            type,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK);

        return (
            <ExtLink
                to={orderLink}
                pageData={{pageNum, pageSize}}
                filterData={{
                    regionsFilter,
                    objectIdsFilter,
                    projectIdsFilter,
                    type,
                }}
                historyEnabled
            >
                {`${orderNum} ${orderName}`}
            </ExtLink>
        );
    };

    renderObjectLink({objectId, objectName, projectId}) {
        if (!objectName) {
            return "-";
        }

        const {
            clientId,
            regionsFilter,
            objectIdsFilter,
            projectIdsFilter,
            type,
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        const objectLink = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":objectId", objectId)
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <ExtLink
                historyEnabled
                to={objectLink}
                pageData={{pageNum, pageSize}}
                filterData={{
                    regionsFilter,
                    objectIdsFilter,
                    projectIdsFilter,
                    type,
                }}
            >
                {objectName}
            </ExtLink>
        );
    }

    getLabels = (item) => {
        const {
            tableHeaders,
        } = this.props;

        return tableHeaders.map(({key, content}) => {
            if (key === "object") {
                return {
                    label: content,
                    text: this.renderObjectLink(item),
                };
            }

            if (key === "name") {
                return {
                    label: content,
                    text: this.renderNameLink(item),
                };
            }

            if (["awaitingSignatureCount", "vacanciesCount", "orderContractorHiredCount"].includes(key)) {
                return {
                    label: content,
                    text: item[key] ? item[key] : 0,
                };
            }

            return {
                label: content,
                text: item[key] || "-",
            };
        });
    };

    getRows = () => {
        const {
            analyticPage: {
                orderAnalyticsResultModels,
            },
        } = this.props;

        return orderAnalyticsResultModels.map(row => {
            return {
                ...row,
                contentRow: (
                    <NmListCard
                        noDivider
                        labels={this.getLabels(row)}
                    />
                ),
            };
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    renderPagination() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            analyticPage: {
                totalCount = 0,
            },
        } = this.props;

        const totalPages = getTotalPages(totalCount, pageSize);

        return (
            <NmPagination
                pageNum={pageNum}
                totalPages={totalPages}
                onChangePagination={this.handlePaginationChange}
            />
        );
    }

    render() {
        const {
            header,
            handleClose,
            progress,
            analyticPage: {
                orderAnalyticsResultModels,
            },
        } = this.props;

        return (
            <NmModal
                size="lg"
                onClose={handleClose}
                className="order-analytics-detail"
                classNameContent="order-analytics-detail__content"
                header={
                    <NmTitle size="lg">
                        {header}
                    </NmTitle>
                }
                footer={this.renderPagination()}
                loading={progress}
            >
                {
                    orderAnalyticsResultModels && orderAnalyticsResultModels.length ?
                        <CheckboxList rows={this.getRows()} /> :
                        <NmEmptyPageV2 />
                }
            </NmModal>
        );
    };

}

export default withPageData(connect(
    state => ({
        analyticPage: orderAnalyticsPageSelector(state),
        progress: orderProgressSelector(state),
    }),
    {
        getOrderAnalyticsPage,
    },
)(withTranslation()(OrderDetailAnalytics)));