import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import copy from "copy-to-clipboard";

import Filter from "../../../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../../../components/ActualComponents/NmEmptyPageV2";
import Text from "../../../../../../components/ActualComponents/Text";
import NmPage from "../../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../../components/NmPageHeader";
import TableDiv from "../../../../../../components/TableDiv";
import {
    onboardingRecruitersListProgressSelector,
    onboardingRecruitersListSelector,
    onboardingRecruitersListTotalCountSelector,
    onboardingRecruitersListTotalPagesSelector,
    onboardingRecruitersProgressActionSelector,
} from "../../../../../../ducks/onboardingRecruiters";
import {ReactComponent as AddIcon} from "../../../../../../images/add.svg";
import {ReactComponent as CopyIcon} from "../../../../../../images/copy.svg";
import OnboardingScenarioRecruiterEditForm from "./components/edit-form";

import {usePagination} from "../../../../../../hooks/usePagination";
import useSortList, {sortAction} from "../../../../../../hooks/useSortList";
import {useFetchOnboardingRecruitersList} from "./hooks/useFetch";
import {useOnboardingRecruitersListFilter} from "./hooks/useFilter";

import bem from "../../../../../../utils/bem";
import dateFormat, {convertUtcToLocal} from "../../../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../../utils/restrictions";
import {getFullName} from "../../../../../../utils/stringFormat";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../../../constants/color";
import {onboardingRecruitersHeaders} from "../../../../../../constants/headersTable";
import {
    CLIENT_ACCOUNTANT,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    RECRUITER,
} from "../../../../../../constants/roles";

import {clientCardInfoSelector} from "../../../../../../ducks/bff/clients/info/selectors";

import "./style.sass";

const initFilter = {
    fullNameFilter: "",
    creationDateTimeFrom: null,
    creationDateTimeTo: null,
};

const OnboardingScenarioRecruiterList = (props) => {
    const {
        clientId,
        scenarioId,
    } = props;

    const [isOpenEditForm, setIsOpenEditForm] = useState(false);

    const [, sortType, headers, sortDispatch] = useSortList(onboardingRecruitersHeaders);

    const totalPages = useSelector(onboardingRecruitersListTotalPagesSelector);
    const totalCount = useSelector(onboardingRecruitersListTotalCountSelector);
    const list = useSelector(onboardingRecruitersListSelector);
    const progress = useSelector(onboardingRecruitersListProgressSelector);
    const progressAction = useSelector(onboardingRecruitersProgressActionSelector);
    const {archived: isClientArchived} = useSelector(clientCardInfoSelector);

    const role = ls(USER_ROLE);

    const [block, element] = bem("onboarding-scenario-recruiter-list");

    const {
        filterDto,
        setFilterDto,
        setIsSearch,
        isSearch,
        filters,
    } = useOnboardingRecruitersListFilter({});

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        fetchList,
        downloadQRCode,
        exportRecruitersList,
    } = useFetchOnboardingRecruitersList({
        filter: filterDto,
        sortType,
        pageSize,
        pageNum,
        clientId,
        scenarioId,
    });

    const handleChangeSearch = (field, sortType) => {
        if (sortType !== "asc" && sortType !== "desc") {
            return;
        }

        sortDispatch(sortAction([field, sortType]));
    };

    const submitFilter = (filter, _isSearch) => {
        setFilterDto(filter);
        setPagination({
            pageSize,
            pageNum: 1,
        });
        setIsSearch(_isSearch);
    };

    const mapTableData = () => {
        const rows = list.map(item => {
            const {
                creationDateTime,
                creatorLastName,
                creatorFirstName,
                creatorPatronymic,
                invitationLink,
                invitationQrFileName,
                invitationQrFileLink,
            } = item;

            return {
                ...item,
                creationDateTime: dateFormat(convertUtcToLocal(creationDateTime)),
                creatorFullName: getFullName(creatorLastName, creatorFirstName, creatorPatronymic),
                invitationLink: <div className="flex">
                    <CopyIcon
                        color={COLOR.SECONDARY_45}
                        className={element("copy-icon")}
                        onClick={() => {
                            copy(invitationLink);
                            toastSuccess("Ссылка скопирована");
                        }}
                    />
                    <Text className="ms-2">
                        {invitationLink}
                    </Text>
                </div>,
                invitationQrFileLink: <Link onClick={() => downloadQRCode(invitationQrFileName, invitationQrFileLink)}>
                    скачать
                    QR-код
                </Link>,
            };
        });

        return {
            rows,
            headers,
        };
    };

    const getMediaControls = () => {
        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        onClick: () => {
                            setIsOpenEditForm(true);
                        },
                        children: "Добавить рекрутера",
                        icon: <AddIcon />,
                    },
                    visible: !isClientArchived
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.addOnboardingScenarioRecruiters,
                            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOnboardingScenarioRecruiters,
                        ])
                        && ![
                            FOREMAN,
                            CLIENT_ACCOUNTANT,
                            RECRUITER,
                            NM_COORDINATOR,
                            NM_CHIEF_ACCOUNTANT,
                            NM_OPERATOR,
                        ].includes(role),
                    settings: {
                        exception: true,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "xl",
                        onClick: exportRecruitersList,
                        children: "Выгрузить список",
                        disabled: totalCount < 1 || progressAction,
                    },
                    visible: isAccessByRestrictions([
                        CLIENT_USER_RESTRICTIONS_VARIABLE.unloadOnboardingScenarioRecruiters,
                        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsOnboardingScenarioRecruiters,
                    ])
                        && ![
                            FOREMAN,
                            CLIENT_ACCOUNTANT,
                            RECRUITER,
                            NM_COORDINATOR,
                            NM_CHIEF_ACCOUNTANT,
                        ].includes(role),
                },
            ],
        };
    };

    const renderEditForm = () => {
        return (
            isOpenEditForm &&
            <OnboardingScenarioRecruiterEditForm
                clientId={clientId}
                scenarioId={scenarioId}
                onClose={() => setIsOpenEditForm(false)}
                fetchList={fetchList}
            />
        );
    };

    return (
        <NmPage
            noPadding
            heightUnset
            className={block()}
            header={
                <NmPageHeader
                    text="Рекрутеры"
                />
            }
            typeFilter="vertical"
            filtersBase={
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={(form) => submitFilter(form, true)}
                    clearFilter={() => submitFilter(initFilter, true)}
                />
            }
            mediaControls={getMediaControls()}
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            paginationPaddingBottom
            totalCount={totalCount}
        >
            {renderEditForm()}
            {
                totalCount > 0 ?
                    <div className={element("overflow-container")}>
                        <TableDiv
                            className={element("table")}
                            handleChangeSearch={handleChangeSearch}
                            tableData={mapTableData()}
                        />
                    </div> : <NmEmptyPageV2
                        title="Информация отсутствует"
                        isSearch={isSearch}
                        fetchProgress={progress}
                    />
            }
        </NmPage>
    );
};

export default OnboardingScenarioRecruiterList;