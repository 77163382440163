import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import copy from "copy-to-clipboard";
import {isEmpty} from "lodash";

import {ReactComponent as ChatIcon} from "../../../../images/chat_24.svg";
import {ReactComponent as PhoneIcon} from "../../../../images/phone_24.svg";

import {getStartDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {phoneFormat, phoneFormatWithoutSevenNumber} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";
import {toastSuccess} from "../../../../utils/toastHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {CROWD_TASK_STATUS} from "../../../../constants/crowd/task";
import {LINK_CLIENT_CROWD_TASK_CHATS} from "../../../../constants/links";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../../constants/roles";

import {clientCardPropertiesSelector} from "../../../../ducks/bff/clients/info/selectors";
import {
    rejectCrowdPayment,
    repeatCrowdPayment,
} from "../../../../ducks/bff/crowd/payment/actionCreators";
import {
    approveResultCrowdTaskContractorsWorking,
    forceFinishCrowdTaskContractorsWorking,
    rejectResultCrowdTaskContractorsWorking,
} from "../../../../ducks/bff/crowd/taskCard/actionCreators";

import {STATUS_TASK_PAYMENT} from "../../../../constants/crowd/task-payment";
import {SUB_PAGE_CROWD_TASK_ANALYTICS} from "../../../../constants/link-params";

const useCrowdTaskAnalyticsAction = (props) => {
    const {
        fetchInfo,
        sortType,
        setSortType,
        subpage,
        onOpenConfirm,
        onCloseConfirm,
    } = props;

    const dispatch = useDispatch();

    const {canViewContractorContacts} = useSelector(clientCardPropertiesSelector);

    const [contractorRejectModalData, setContractorRejectModalData] = useState({});
    const [createActModalData, setCreateActModalData] = useState({});
    const [rejectReasonModalData, setRejectReasonModal] = useState({});

    const role = ls(USER_ROLE);
    const isAccessPaymentAction = [
        ADMIN,
        CLIENT_ADMIN,
        CLIENT_ACCOUNTANT,
        PROJECT_MANAGER,
        OBJECT_MANAGER,
        FOREMAN,
    ].includes(role)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.addCrowdPayments,
        ]);

    const handleCopyContact = ({phone}) => {
        copy(phoneFormatWithoutSevenNumber(phone));

        toastSuccess("Номер телефона скопирован.");
    };

    const onClickSort = ({sortType}) => {
        setSortType(sortType);
    };

    const sortOptions = useMemo(() => {
        return [
            {
                key: "fio",
                value: "fio",
                sortType: sortType,
                asc: "ASC",
                desc: "DESC",
            },
        ];
    }, [sortType]);

    const getMediaControls = (item) => {
        const {
            clientId,
            taskId,
            paymentData = {},
            contractorData = {},
            status,
        } = item;

        const isTaskNotCompleted = status === CROWD_TASK_STATUS.NOT_COMPLETED.VALUE;

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 12,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        onClick: () => handleCopyContact(contractorData),
                        icon: <PhoneIcon />,
                        color: "grey",
                        onlyIcon: true,
                        size: "lg",
                        popup: phoneFormat(contractorData?.phone),
                    },
                    asset: {
                        mobile: {
                            children: "Скопировать телефон",
                        },
                    },
                    visible: canViewContractorContacts && Boolean(contractorData?.phone) && ![
                        SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK,
                    ].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON_CONTACT,
                    props: {
                        href: LINK_CLIENT_CROWD_TASK_CHATS
                            .replace(":clientId", clientId)
                            .replace(":contractorId?", contractorData?.contractorId),
                        isLink: true,
                        onlyIcon: true,
                        icon: <ChatIcon />,
                        color: "grey",
                        size: "lg",
                        popup: "Перейти в чат",
                        showIndicator: Boolean(contractorData?.chatUnreadMessagesCount),
                    },
                    asset: {
                        mobile: {
                            children: "Перейти в чат",
                        },
                    },
                    visible: ![
                        SUB_PAGE_CROWD_TASK_ANALYTICS.NO_RESPONSES.LINK,
                    ].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "xl",
                        onClick: () => setContractorRejectModalData({
                            clientId,
                            taskId,
                            contractorId: contractorData.contractorId,
                        }),
                        children: "Отказать",
                    },
                    visible: [
                        SUB_PAGE_CROWD_TASK_ANALYTICS.SIGN_CONTRACT.LINK,
                        SUB_PAGE_CROWD_TASK_ANALYTICS.HIRED.LINK,
                    ].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "xl",
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите отправить задание на проверку?",
                            onConfirm: () => forceFinish(item),
                            confirmButton: "Подтвердить",
                            cancelButton: "Отменить",
                        }),
                        children: "Отправить на проверку",
                    },
                    visible: [SUB_PAGE_CROWD_TASK_ANALYTICS.IN_WORK.LINK].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => onOpenConfirm({
                            content: "Подтвердите, что работа исполнителя принята? Задание будет снято с публикации, работа исполнителя оплачена",
                            onConfirm: () => approveResult(item),
                            confirmButton: "Подтвердить",
                            cancelButton: "Закрыть",
                        }),
                        children: "Принять работу",
                    },
                    visible: [SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "lg",
                        onClick: () => onOpenConfirm({
                            content: "Вы действительно хотите отклонить работу исполнителя?",
                            onConfirm: () => onConfirmReject(item),
                            confirmButton: "Подтвердить",
                            cancelButton: "Закрыть",
                        }),
                        children: "Отклонить работу",
                    },
                    visible: [SUB_PAGE_CROWD_TASK_ANALYTICS.ON_REVIEW.LINK].includes(subpage),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => openActCreationModal(item),
                        children: "Сформировать акт",
                    },
                    visible: !isTaskNotCompleted && [SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK].includes(subpage)
                        && isEmpty(paymentData)
                        && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => openActCreationModal(item),
                        children: "Отправить акт повторно",
                    },
                    visible: !isTaskNotCompleted
                        && [SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK].includes(subpage)
                        && [
                            STATUS_TASK_PAYMENT.CONTRACTOR_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CLIENT_REJECTED.VALUE,
                            STATUS_TASK_PAYMENT.CANCELLED.VALUE,
                        ].includes(paymentData?.status)
                        && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "light-green",
                        size: "lg",
                        onClick: () => repeatPayment(paymentData),
                        children: "Повторить оплату",
                    },
                    visible: !isTaskNotCompleted
                        && [SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK].includes(subpage)
                        && paymentData?.status === STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE
                        && isAccessPaymentAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        color: "red",
                        size: "lg",
                        onClick: () => setRejectReasonModal({
                            isOpen: true,
                            paymentId: paymentData?.paymentId,
                        }),
                        children: "Отклонить оплату",
                    },
                    visible: !isTaskNotCompleted && [SUB_PAGE_CROWD_TASK_ANALYTICS.IN_PAYMENT.LINK].includes(subpage) &&
                        [
                            STATUS_TASK_PAYMENT.VALIDATION.VALUE,
                            STATUS_TASK_PAYMENT.VALIDATION_FAILED.VALUE,
                            STATUS_TASK_PAYMENT.FOR_CONTRACTOR_APPROVE.VALUE,
                        ].includes(paymentData?.status)
                        && isAccessPaymentAction,
                },
            ],
        };
    };

    const repeatPayment = ({paymentId, paymentNumber}) => {
        dispatch(repeatCrowdPayment({
            paymentId,
            onSuccess: () => {
                toastSuccess(`Запущена задача по повтору оплаты по заданию ${paymentNumber}`);
                fetchInfo();
            },
        }));
    };

    const approveResult = (item) => {
        const {
            clientId,
            taskId,
            contractorData,
        } = item;

        dispatch(approveResultCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId: contractorData?.contractorId,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                openActCreationModal(item);
            },
        }));
    };

    const openActCreationModal = (item) => {
        const {
            contractorData,
            contractSigningDateTime,
            applicationSigningDateTime,
            taskWorkFinishedDateTime,
            paymentAmount,
            workStartDate,
            workEndDate,
            servicesDescription,
            clientId,
            taskId,
        } = item;

        setCreateActModalData({
            clientId,
            taskId,
            contractorId: contractorData?.contractorId,
            contractorName: contractorData.name,
            contractSigningDateTime,
            applicationSigningDateTime,
            taskWorkFinishedDateTime,
            paymentAmount,
            workStartDate,
            workEndDate,
            servicesDescription,
        });
    };

    const onConfirmReject = ({workEndDate, clientId, taskId, contractorData}) => {
        const {
            contractorId,
        } = contractorData || {};
        const leftPartEquation = getStartDate(new Date(workEndDate));
        const rightPartEquation = getStartDate(new Date());

        if (leftPartEquation.getTime() < rightPartEquation.getTime()) {
            rejectResult({
                completeTask: true,
                successText: "Период выполнения задания закончился. Задание завершено как невыполненное",
                clientId,
                taskId,
                contractorId,
            });

            return;
        }

        onOpenConfirm({
            content: "Оставить задание в работе и назначить нового исполнителя из очереди?",
            onConfirm: () => rejectResult({
                completeTask: false,
                successText: "Работа отклонена. На задание будет назначен новой исполнитель из очереди.",
                clientId,
                taskId,
                contractorId,
            }),
            onCancel: () => rejectResult({
                completeTask: true,
                successText: "Задание завершено как невыполненное",
                clientId,
                taskId,
                contractorId,
            }),
            confirmButton: "Продолжить",
            cancelButton: "Завершить",
        });
    };

    const rejectResult = ({completeTask, successText, clientId, taskId, contractorId}) => {
        dispatch(rejectResultCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId,
            completeTask,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                toastSuccess(successText);
            },
        }));
    };

    const forceFinish = ({clientId, taskId, contractorData}) => {
        dispatch(forceFinishCrowdTaskContractorsWorking({
            clientId,
            taskId,
            contractorId: contractorData?.contractorId,
            onSuccess: () => {
                fetchInfo();
                onCloseConfirm();
                toastSuccess("Задание отправлено на проверку");
            },
        }));
    };

    const rejectPayment = ({paymentId, rejectReason}) => {
        dispatch(rejectCrowdPayment({
            paymentId,
            rejectReason: handleFormString(rejectReason),
            onSuccess: () => {
                fetchInfo();
                setRejectReasonModal({});
            },
        }));
    };

    return {
        sortOptions,
        onClickSort,
        sortType,
        getMediaControls,
        contractorRejectModalData,
        setContractorRejectModalData,
        createActModalData,
        setCreateActModalData,
        rejectReasonModalData,
        setRejectReasonModal,
        rejectPayment,
    };
};

export default useCrowdTaskAnalyticsAction;