import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const registryPaymentCardSelector = state => state.bff.registryPaymentCard;
export const registryPaymentsPageDataSelector = createSelector(registryPaymentCardSelector, ({registryPaymentsPageData}) => registryPaymentsPageData);
export const registryPaymentsListSelector = createSelector(registryPaymentCardSelector, ({registryPayments}) => {
    return registryPayments;
});
export const registryPaymentsTotalCountSelector = createSelector(registryPaymentCardSelector, ({totalCount}) => totalCount);
export const registryPaymentsContractDateConclusionSelector = createSelector(registryPaymentCardSelector, ({nmContractDateConclusion}) => nmContractDateConclusion);
export const registryPaymentsTotalPagesSelector = createSelector(registryPaymentCardSelector, ({totalCount, registryPaymentsPageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const registryPaymentsProgressSelector = createSelector(registryPaymentCardSelector, ({progressRegistryPayments}) => progressRegistryPayments);
export const registryProgressDataSelector = createSelector(registryPaymentCardSelector, ({successSaveContractor, progressSaveContractor}) => ({
    successSaveContractor,
    progressSaveContractor,
}));
export const registryViolationsSelector = createSelector(registryPaymentCardSelector, ({violations}) => (violations));
export const registryProgressImportFileSelector = createSelector(registryPaymentCardSelector, ({progressImportContractors}) => (progressImportContractors));
export const registryPaymentsProgressValidation = createSelector(registryPaymentCardSelector, ({progressValidation}) => (progressValidation));
export const refreshPerformerActionProcessSelector = createSelector(registryPaymentCardSelector, ({refreshPerformerActionProcess}) => (refreshPerformerActionProcess));
export const registryPaymentsHasInProgressSelector = createSelector(registryPaymentCardSelector, ({registryPaymentsHasInProgress}) => registryPaymentsHasInProgress);
export const registryProgressCardSelector = createSelector(registryPaymentCardSelector, ({progressCard}) => progressCard);
export const registriesCardSelector = createSelector(registryPaymentCardSelector, ({card}) => card);
export const registriesCardAvailableForPaymentsSelector = createSelector(registryPaymentCardSelector, ({availableForPayments}) => availableForPayments);