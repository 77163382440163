import {
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    totalCount: 0,
    pageData: {},
    progressList: false,
    progressAction: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_REQUEST:
            const {pageSize, pageNum} = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
                progressList: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_REQUEST:
            return {
                ...state,
                progressList: true,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_SUCCESS:
            return {
                ...state,
                totalCount: payload.totalCount,
                list: payload.results,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_SUCCESS:
            return {
                ...state,
                card: payload,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_GET_BY_ID_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case ADD_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case UPDATE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case DELETE_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case IMPORT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case REJECT_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case DELETE_NOT_COMPLETED_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
        case CHECK_IMPORT_TASK_EXISTS_DOCUMENTS_EDO_REGISTRIES_ITEMS_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case DOCUMENTS_EDO_REGISTRIES_ITEMS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};