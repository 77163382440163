import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmInfoCard from "../../../../../components/ActualComponents/NmInfoCard";
import NmLabelText from "../../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../../components/ActualComponents/Text";
import CheckboxList from "../../../../../components/CheckboxList";
import NmPage from "../../../../../components/NmPage";
import {NmPageHeader} from "../../../../../components/NmPageHeader";
import {ReactComponent as ModeIcon} from "../../../../../images/mode.svg";
import {getSettingsDirectoriesAccessActions} from "../../helpers/getAccessActions";
import SettingsDirectoriesBankEditForm from "../components/edit-form";
import OneTransactionLimitsEditModal from "../components/one-transaction-limits-edit-modal";

import {useModal} from "../../../../../hooks/useModal";
import {usePagination} from "../../../../../hooks/usePagination";
import {useSettingsDirectoriesBanksDetailsFetch} from "./hooks/useFetch";

import bem from "../../../../../utils/bem";
import {
    formatAmount,
    formatAmountWithNullChecking,
    formatNumber,
} from "../../../../../utils/stringFormat";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../../../constants/color";
import {
    BANK_IDENTIFICATION_STATUS,
    BANK_IDENTIFICATION_STATUS_DICT,
} from "../../../../../constants/directoriesBanks";
import {
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../../../constants/finance";
import {ADMIN} from "../../../../../constants/roles";

import {
    settingsDirectoriesBanksListSelector,
    settingsDirectoriesBanksProgressSelector,
    settingsDirectoriesBanksTotalCountSelector,
    settingsDirectoriesBanksTotalPagesSelector,
} from "../../../../../ducks/bff/settings/directories/naimix/banks/selectors";

import "./style.sass";

export const SettingsDirectoriesBanksDetails = () => {
    const list = useSelector(settingsDirectoriesBanksListSelector);
    const totalPages = useSelector(settingsDirectoriesBanksTotalPagesSelector);
    const totalCount = useSelector(settingsDirectoriesBanksTotalCountSelector);
    const progress = useSelector(settingsDirectoriesBanksProgressSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN],
    });

    const [block, element] = bem("settings-directories-banks-details");

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    const {
        fetchList,
    } = useSettingsDirectoriesBanksDetailsFetch({
        pageNum,
        pageSize,
    });

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenEditModal: true,
                            data: item,
                        }),
                        color: "grey",
                        children: "Редактировать",
                    },
                },
            ],
        };
    };

    const getLimitCards = ({dayLimit, monthLimit, singleLimit}) => {
        return (
            <div className="flex flex-column flex-xl-row col-16 mb-3">
                <NmInfoCard
                    className="col-16 col-xl-3"
                    title="Разовый лимит, ₽"
                    value={formatAmount(formatNumber(singleLimit))}
                />
                <NmInfoCard
                    className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                    title="Суточный лимит, ₽"
                    value={formatAmount(formatNumber(dayLimit))}
                />
                <NmInfoCard
                    className="col-16 col-xl-3 mt-md-2 mt-xl-0 ms-xl-4"
                    title="Месячный лимит, ₽"
                    value={formatAmount(formatNumber(monthLimit))}
                />
            </div>
        );
    };

    const getPaymentPaths = (params) => {
        const {
            DIRECT,
            THROUGH_WALLET,
        } = params;

        const wallet = `через кошелек: ${THROUGH_WALLET ? "Да" : "Нет"}`;
        const direct = `напрямую: ${DIRECT ? "Да" : "Нет"}`;

        return `${wallet} / ${direct}`;
    };

    const renderPaymentInfo = (params) => {
        const {
            title,
            paymentsMethods,
            isVisibleCardInfo = true,
            isVisibleSbpInfo = true,
        } = params;

        const {
            CARD_LOCAL,
            SBP,
            BANK_REQUISITES,
        } = paymentsMethods;

        return (
            <div className="mb-3">
                <Text
                    level="3"
                    bold
                    className="mb-3"
                >
                    {title}
                </Text>
                <NmLabelText
                    type="list"
                    className="mb-1"
                    label="На карты"
                    text={isVisibleCardInfo ? getPaymentPaths(CARD_LOCAL) : "-"}
                />
                <NmLabelText
                    type="list"
                    className="mb-1"
                    label="СБП"
                    text={isVisibleSbpInfo ? getPaymentPaths(SBP) : "-"}
                />
                <NmLabelText
                    type="list"
                    className="mb-1"
                    label="По реквизитам"
                    text={getPaymentPaths(BANK_REQUISITES)}
                />
            </div>
        );
    };

    const renderRequisitesBlock = (item) => {
        return (
            <div className="mb-3">
                <Text
                    level="3"
                    bold
                    className="mb-3"
                >
                    Реквизиты банка
                </Text>
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="Наименование банка"
                    text={item.bankSecondName || "-"}
                />
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="БИК"
                    text={item.bic || "-"}
                />
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="Р/сч. №"
                    text={item.checkingAccount || "-"}
                />
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="К/сч. №"
                    text={item.correspondentAccount || "-"}
                />
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="ИНН"
                    text={item.inn || "-"}
                />
                <NmLabelText
                    type="list"
                    className={element("label")}
                    label="КПП"
                    text={item.kpp || "-"}
                />
            </div>
        );
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                bankId,
                bankName,
                directPaymentLimits,
                walletLimits,
                paymentTypeLimits,
            } = item;

            return {
                ...item,
                key: bankId,
                contentRow: (
                    <NmListCard
                        primaryHeader={bankName}
                        noDivider
                        classNameMainContent="col-16"
                        otherContent={
                            <div className="col-16 mt-4">
                                {renderRequisitesBlock(item)}
                                {renderPaymentInfo({
                                    title: "Оплаты НПД",
                                    paymentsMethods: item.paymentsPathsInfo.SMZ,
                                })}
                                {renderPaymentInfo({
                                    title: "Оплаты НДФЛ",
                                    paymentsMethods: item.paymentsPathsInfo.CIVIL,
                                })}
                                {renderPaymentInfo({
                                    title: "Оплаты ИП",
                                    paymentsMethods: item.paymentsPathsInfo.INDIVIDUAL,
                                    isVisibleCardInfo: false,
                                    isVisibleSbpInfo: false,
                                })}
                                <div className="d-flex align-items-center mb-3">
                                    <Text
                                        level="3"
                                        bold={true}
                                    >
                                        Лимиты на один платеж
                                    </Text>
                                    <ModeIcon
                                        cursor="pointer"
                                        className="ms-2"
                                        color={COLOR.SECONDARY_45}
                                        onClick={() => onOpenModal({
                                            isOpenOneTransactionLimitsEditModal: true,
                                            data: item,
                                        })}
                                        width={20}
                                        height={20}
                                    />
                                </div>
                                <div className="flex flex-column flex-xl-row col-16 mb-3">
                                    {
                                        [
                                            ORDER_WORK_REPORT_TYPE.SMZ,
                                            ORDER_WORK_REPORT_TYPE.CIVIL,
                                            ORDER_WORK_REPORT_TYPE.INDIVIDUAL,
                                        ].map((item, index) => {
                                            const isFirst = index === 0;

                                            return (
                                                <NmInfoCard
                                                    key={index}
                                                    className={`col-16 col-xl-3 ${isFirst ? "" : "mt-md-2 mt-xl-0 ms-xl-4"}`}
                                                    title={`${ORDER_WORK_REPORT_TYPE_TRANSLATE[item]}, ₽`}
                                                    value={formatAmountWithNullChecking(paymentTypeLimits[item], "-")}
                                                />
                                            );
                                        })
                                    }
                                </div>
                                <Text
                                    level="3"
                                    bold
                                    className="mb-3"
                                >
                                    Лимиты по переводам через кошелек
                                </Text>
                                {
                                    [
                                        BANK_IDENTIFICATION_STATUS.NOT_IDENTIFIED,
                                        BANK_IDENTIFICATION_STATUS.SIMPLIFIED_IDENTIFIED,
                                        BANK_IDENTIFICATION_STATUS.IDENTIFIED,
                                    ].map(item => {
                                        const limits = walletLimits.find(({bankIdentificationStatus}) => bankIdentificationStatus === item) || {};

                                        return (
                                            <React.Fragment key={item}>
                                                <Text
                                                    level="2"
                                                    className="mb-2"
                                                >
                                                    {BANK_IDENTIFICATION_STATUS_DICT[item]}
                                                </Text>
                                                {getLimitCards(limits)}
                                            </React.Fragment>
                                        );
                                    })
                                }
                                <Text
                                    level="3"
                                    bold
                                    className="mb-3"
                                >
                                    Лимиты по переводам напрямую на карту
                                </Text>
                                {getLimitCards(directPaymentLimits[0] || {})}
                            </div>
                        }
                        isFixedActions={true}
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const renderEditForm = () => {
        return (
            modalData?.isOpenEditModal &&
            <SettingsDirectoriesBankEditForm
                editData={modalData.data}
                fetchList={fetchList}
                onClose={onCloseModal}
            />
        );
    };

    const renderOneTransactionLimitsEditModal = () => {
        return (
            modalData?.isOpenOneTransactionLimitsEditModal &&
            <OneTransactionLimitsEditModal
                data={modalData.data}
                fetchList={fetchList}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <NmPage
            overflowUnset={true}
            className={block()}
            header={
                <NmPageHeader
                    text="Банки"
                />
            }
            noPadding={true}
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={progress}
            modals={
                <>
                    {renderEditForm()}
                    {renderOneTransactionLimitsEditModal()}
                </>
            }
            isEmptyPage={isEmpty(list)}
            emptyPageProps={{
                fetchProgress: progress,
            }}
        >
            <CheckboxList rows={rows} />
        </NmPage>
    );
};