import {
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR,
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST,
    BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST,
    BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST,
    BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST,
    BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST,
    BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS,
    BFF_RUN_REGISTRY_INVITATIONS_ERROR,
    BFF_RUN_REGISTRY_INVITATIONS_REQUEST,
    BFF_RUN_REGISTRY_INVITATIONS_SUCCESS,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST,
    BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS,
} from "./actions";

const initialState = {
    card: {},
    progress: false,
    actionProgress: false,
    list: [],
    pageData: {},
    progressList: false,
    totalCount: 0,
    contractorsActionProgress: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_REQUEST:
            const {
                pageSize,
                pageNum,
            } = payload;

            return {
                ...state,
                progressList: true,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_SUCCESS:
            const {
                results: list,
                totalCount,
            } = payload;

            return {
                ...state,
                progressList: false,
                list,
                totalCount,
            };
        case BFF_GET_REGISTRY_INVITATIONS_BY_ID_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_GET_REGISTRY_INVITATIONS_BY_ID_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case BFF_GET_REGISTRY_INVITATIONS_CONTRACTORS_PAGE_ERROR:
            return {
                ...state,
                progressList: false,
            };
        case BFF_GET_REGISTRY_INVITATIONS_BY_ID_ERROR:
            return {
                ...state,
                progress: false,
            };
        case BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_REQUEST:
        case BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_REQUEST:
        case BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_REQUEST:
        case BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_REQUEST:
        case BFF_RUN_REGISTRY_INVITATIONS_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_SUCCESS:
        case BFF_UPDATE_REGISTRY_INVITATIONS_CONTRACTOR_ERROR:
        case BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_ADD_CONTRACTOR_TO_REGISTRY_INVITATIONS_ERROR:
        case BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_SUCCESS:
        case BFF_IMPORT_REGISTRY_INVITATIONS_CONTRACTORS_ERROR:
        case BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_DELETE_CONTRACTOR_FROM_REGISTRY_INVITATIONS_ERROR:
        case BFF_RUN_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_RUN_REGISTRY_INVITATIONS_ERROR:
            return {
                ...state,
                actionProgress: false,
            };
        case BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_REQUEST:
            return {
                ...state,
                contractorsActionProgress: true,
            };
        case BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_SUCCESS:
        case BFF_CHECK_VALID_RESIDENT_REGISTRY_INVITATIONS_ERROR:
            return {
                ...state,
                contractorsActionProgress: false,
            };
        default:
            return state;
    }
};