import {
    ADD_OBJECT_USER_REQUEST,
    CLIENT_OBJECTS_CARD_CLEAR_STORE,
    CLIENT_OBJECTS_CARD_GET_REQUEST,
    CLIENT_OBJECTS_CARD_UPDATE_STORE,
    DELETE_OBJECT_USER_REQUEST,
    GET_PROJECT_OBJECT_USER_LIST_REQUEST,
} from "./actions";

export const getClientObjectCard = (payload) => {
    return {
        type: CLIENT_OBJECTS_CARD_GET_REQUEST,
        payload,
    };
};

export const getObjectUserList = (payload) => {
    return {
        type: GET_PROJECT_OBJECT_USER_LIST_REQUEST,
        payload,
    };
};

export const addObjectUser = (payload) => {
    return {
        type: ADD_OBJECT_USER_REQUEST,
        payload,
    };
};

export const deleteObjectUser = (payload) => {
    return {
        type: DELETE_OBJECT_USER_REQUEST,
        payload,
    };
};

export const updateStoreClientObjectCard = (payload) => {
    return {
        type: CLIENT_OBJECTS_CARD_UPDATE_STORE,
        payload,
    };
};

export const clearStoreClientObjectCard = (payload) => {
    return {
        type: CLIENT_OBJECTS_CARD_CLEAR_STORE,
        payload,
    };
};