import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";

import {useKedoDocumentTypesBff} from "../../../hooks/useBff";

import {handleFormString} from "../../../../../../../utils/stringHelper";
import {getDocumentRouteTypesFilter} from "../../../../utils/getDocumentRouteTypesFilter";
import {getKedoLoadDocumentTypesValidation} from "../utils/getValidation";

import {KEDO_DOCUMENTS_ROUTE_TYPE} from "../../../../../../../constants/dicts";

import {kedoDirectoriesProgressActionSelector} from "../../../../../../../ducks/kedo/directories/selectors";

export const useKedoDocumentTypesEdit = (params) => {
    const {
        fetchList,
        editData,
        onClose,
        fetchCounts,
        clientId,
    } = params;

    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const {
        addDocumentType,
        updateDocumentType,
    } = useKedoDocumentTypesBff();

    const initialValues = useMemo(() => {
        const {
            routeType,
            ...otherData
        } = editData || {};

        return {
            name: "",
            externalId: "",
            clientId: "",
            documentCode: "",
            routeId: "",
            baseRouteType: routeType
                ? routeType === KEDO_DOCUMENTS_ROUTE_TYPE.BASE_ROUTE
                : true,
            ...otherData,
        };
    }, [editData]);

    const {
        handleSubmit,
        values,
        setValues,
        touched,
        setFieldValue,
        errors,
        isValid,
    } = useFormik({
        onSubmit,
        enableReinitialize: true,
        initialValues,
        validationSchema: getKedoLoadDocumentTypesValidation({
            clientId,
        }),
        validateOnBlur: false,
    });

    const onChange = (event, {name, value}) => {
        if (name === "baseRouteType") {
            setValues(prevState => ({
                ...prevState,
                [name]: value,
                routeId: "",
            }));
        }

        setFieldValue(name, value);
    };

    function onSubmit () {
        if (!isValid) {
            return;
        }

        const {
            name,
            externalId,
            clientId,
            documentCode,
            routeId,
        } = values;

        const requestData = {
            name,
            externalId: handleFormString(externalId),
            documentCode,
            routeId,
        };

        if (editData) {
            const {
                documentTypeId,
            } = editData;

            updateDocumentType({
                clientId: values.clientId,
                documentTypeId,
                ...requestData,
                onSuccess: () => {
                    fetchList();
                    onClose();
                },
            });

            return;
        }

        addDocumentType({
            clientId,
            ...requestData,
            onSuccess: () => {
                fetchList();
                fetchCounts();
                onClose();
            },
        });
    }

    const documentRouteTypesFilter = useMemo(() => {
        return getDocumentRouteTypesFilter(values.baseRouteType);
    }, [values.baseRouteType]);

    return {
        errors,
        handleSubmit,
        values,
        progressAction,
        onChange,
        touched,
        documentRouteTypesFilter,
    };
};