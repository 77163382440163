import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";

const controller = "adm/client-user-restrictions";

//*  TYPES  *//

const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST";
const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS";
const GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR = "GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR";

const UPDATE_CLIENT_USER_RESTRICTIONS_STORE = "CLEAR_CLIENT_USER_RESTRICTIONS_STORE";

const CLEAR_CLIENT_USER_RESTRICTIONS_STORE = "CLEAR_CLIENT_USER_RESTRICTIONS_STORE";

//*  INITIAL STATE  *//

const initial = {
    currentUserRestrictions: [],
    allRestrictions: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS:
            return {
                ...state,
                allRestrictions: payload || [],
            };
        case UPDATE_CLIENT_USER_RESTRICTIONS_STORE:
            return {
                ...state,
                ...payload,
            };
        case CLEAR_CLIENT_USER_RESTRICTIONS_STORE:
            return initial;
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getClientUserRestrictionsByRole(payload) {
    return {
        type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST,
        payload,
    };
}

export function updateClientUserRestrictionsStore() {
    return {
        type: UPDATE_CLIENT_USER_RESTRICTIONS_STORE,
    };
}

export function clearClientUserRestrictionsStore() {
    return {
        type: CLEAR_CLIENT_USER_RESTRICTIONS_STORE,
    };
}

//*  SELECTORS  *//

const clientUserRestrictionsSelector = state => state.clientUserRestrictions;
export const currentUserRestrictionsAllOptionsSelector = createSelector(clientUserRestrictionsSelector, ({allRestrictions}) => {
    return allRestrictions.map(({variable, name}) => {
        return {
            key: variable,
            text: name,
            value: variable,
        };
    },
    );
});

//*  SAGA  *//

//GET /bff/adm/client-user-restrictions/get-by-role
const getClientUserRestrictionsByRoleSaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${controller}/get-by-role`, {params});

        yield put({type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_SUCCESS, payload: result});
    } catch (error) {
        console.error(error);
        yield put({type: GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_USER_RESTRICTIONS_BY_ROLE_REQUEST, getClientUserRestrictionsByRoleSaga),
    ]);
}