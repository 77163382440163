import {createSelector} from "reselect";

import {clientObjectsSelector} from "../objects/selectors";

import {getTotalPages} from "../../../../utils/mathHelper";

export const clientProjectsSelector = state => state.bff.clientProjects;
export const clientProjectsListSelector = createSelector(clientProjectsSelector, ({list}) => list);
export const clientProjectsListPageDataSelector = createSelector(clientProjectsSelector, ({pageData}) => pageData);
export const clientProjectsTotalCountSelector = createSelector(clientProjectsSelector, ({totalCount}) => totalCount);
export const clientProjectsTotalPagesSelector = createSelector(clientProjectsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const clientProjectsProgressSelector = createSelector(clientProjectsSelector, ({progress}) => progress);
export const clientProjectsProgressActionSelector = createSelector(clientProjectsSelector, ({progressAction}) => progressAction);
export const clientProjectsDepositHistoryTotalCountSelector = createSelector(clientObjectsSelector, ({depositHistoryTotalCount}) => depositHistoryTotalCount);
export const clientProjectsDepositHistoryTotalPagesSelector = createSelector(
    clientProjectsSelector,
    ({depositHistoryTotalCount: totalCount, depositHistoryPageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const clientProjectsDepositHistoryPageSelector = createSelector(
    clientProjectsSelector,
    ({depositHistoryList}) => depositHistoryList,
);