import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import CityCodeDetect from "../../../../components/CityCodeDetect";
import NmPage from "../../../../components/NmPage";
import {ReactComponent as DeleteIcon} from "../../../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../../../images/mode.svg";
import {getSettingsDirectoriesAccessActions} from "../helpers/getAccessActions";
import {SettingsDirectoriesTariffGridEdit} from "./components/edit";
import {SettingsDirectoriesTarrifGridFilter} from "./components/filter";
import {SettingsDirectoriesTariffGridImport} from "./components/import";

import {usePagination} from "../../../../hooks/usePagination";
import {useTariffGridAction} from "./hooks/useAction";
import {useTariffGridFetch} from "./hooks/useFetch";
import {useTariffGridFilter} from "./hooks/useFilter";

import {dadataValueCityFilter, getDadataAddressObj} from "../../../../utils/dadata";
import {convertUtcToLocal, formatDateWithoutTime} from "../../../../utils/dateFormat";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {ADMIN, NM_MANAGER} from "../../../../constants/roles";

import {
    tariffScaleListSelector,
    tariffScaleTotalCountSelector,
    tariffScaleTotalPagesSelector,
} from "../../../../ducks/bff/settings/directories/naimix/tariff-grid/selectors";
import {orderWorkUnitsDictSelector} from "../../../../ducks/order";

import "./style.sass";

const SettingsDirectoriesTarrifGrid = () => {
    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("settings");

    const totalPages = useSelector(tariffScaleTotalPagesSelector);
    const list = useSelector(tariffScaleListSelector);
    const totalCount = useSelector(tariffScaleTotalCountSelector);
    const unitsDict = useSelector(orderWorkUnitsDictSelector);

    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER],
    });

    const {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    } = useTariffGridFilter({setPagination, pageSize});

    const {
        fetchList,
    } = useTariffGridFetch({
        filterData,
        pageNum,
        pageSize,
    });

    const {
        mediaButtons,
        setImportOpen,
        editData,
        progress,
        editProgress,
        contentConfirm,
        isImport,
        isEdit,
        isOpenConfirm,
        isOpenCityDetect,
        onSubmitEdit,
        onOpenDelete,
        onCloseEdit,
        onCancelConfirm,
        onConfirm,
        onOpenEdit,
        onCloseCityDetect,
    } = useTariffGridAction({
        fetchList,
        isAccessEdit,
    });

    const getMediaControls = (item) => {
        if (!isAccessEdit) {
            return null;
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenEdit(item);
                        },
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenDelete(item),
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            const {
                rate,
                addressInfo,
                lastUpdateDate,
                unit,
                specialityName,
            } = item;

            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xl-12"
                        secondaryHeader={`Дата обновления ${formatDateWithoutTime(convertUtcToLocal(lastUpdateDate))}`}
                        primaryHeader={specialityName}
                        labels={[
                            {
                                label: "Город",
                                columnOnMobile: true,
                                text: !isEmpty(addressInfo) ? getDadataAddressObj(addressInfo).text : "-",
                            },
                            {
                                label: "Единица измерения",
                                text: unitsDict[unit],
                            },
                        ]}
                        cards={[
                            {
                                title: "Ставка",
                                value: formatAmountWithNullChecking(rate),
                                className: "col-3 col-xl-16",
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xl-2 mt-4 mt-xl-0 flex-aligned-end"
                        actionsClassName="col-xl-2 flex-aligned-end justify-content-end"
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    return (
        <NmPage
            noPadding
            headerClassName="justify-content-end"
            className="settings-directories-tariff-grid"
            typeFilter="vertical"
            controls={
                <MediaButtons
                    size="xl"
                    config={mediaButtons}
                />
            }
            filtersBase={
                <SettingsDirectoriesTarrifGridFilter
                    onChange={onChangeFilter}
                    filter={filter}
                    onSearch={onSearch}
                    onClear={onClear}
                />
            }
            totalCount={totalCount}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
        >
            {
                isOpenConfirm &&
                <NmConfirmV2
                    disabled={progress}
                    onCancel={onCancelConfirm}
                    onConfirm={onConfirm}
                    content={contentConfirm}
                    confirmButton="Продолжить"
                    cancelButton="Отменить"
                    isNeedClosing={false}
                />
            }
            {
                isImport &&
                <SettingsDirectoriesTariffGridImport
                    fetchList={fetchList}
                    onClose={() => {
                        setImportOpen(false);
                    }}
                />
            }
            {
                isEdit &&
                <SettingsDirectoriesTariffGridEdit
                    progress={progress || editProgress}
                    onSubmit={onSubmitEdit}
                    editData={editData}
                    onClose={onCloseEdit}
                />
            }
            {
                isOpenCityDetect &&
                <CityCodeDetect
                    fromBound="city"
                    toBound="city"
                    filter={dadataValueCityFilter}
                    onClose={onCloseCityDetect}
                    address={{}}
                />
            }
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        rows={rows}
                    />
            }
        </NmPage>
    );
};

export default SettingsDirectoriesTarrifGrid;