import {all, put,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";
import {getBffCommonController} from "../utils/url";

//*  TYPES  *//

const GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST";
const GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS";
const GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR = "GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR";

const UPDATE_FIELD_KEYWORDS = "UPDATE_FIELD_KEYWORDS";

//*  INITIAL STATE  *//

const initial = {
    autocompleteKeywords: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS:
            return {
                ...state,
                autocompleteKeywords: payload.results,
            };
        case UPDATE_FIELD_KEYWORDS:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getKeywordsAutocompletePage(payload) {
    return {
        type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST,
        payload,
    };
}

export function updateFieldKeyword(payload) {
    return {
        type: UPDATE_FIELD_KEYWORDS,
        payload,
    };
}

//*  SELECTORS  *//
export const keywordSelector = state => state.keyword;
export const getKeywordsOptionsSelector = createSelector(keywordSelector, ({autocompleteKeywords}) => {
    return autocompleteKeywords.map(item => ({
        key: item.keywordUuid,
        value: item.keyword,
        text: item.keyword,
    }));
});

//*  SAGA  *//
//POST bff/adm/common/info/get-keywords-autocomplete-page
//POST bff/client-adm/common/info/get-keywords-autocomplete-page
export const getKeywordsAutocompletePageSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.bff.post(`${getBffCommonController()}/info/get-keywords-autocomplete-page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_AUTOCOMPLETE_PAGE_KEYWORDS_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_AUTOCOMPLETE_PAGE_KEYWORDS_REQUEST, getKeywordsAutocompletePageSaga),
    ]);
}
