import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";

const deferredActsOfAcceptanceWorkCardSelector = state => state.bff.deferredActsOfAcceptanceWorkCard;
export const bffDeferredActsOfAcceptanceWorkCardSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({card}) => card,
);
export const bffDeferredActsOfAcceptanceWorkProgressCardSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({progressCard}) => progressCard,
);
export const bffDeferredActsOfAcceptanceWorkCardItemsListSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({list}) => list,
);
export const bffDeferredActsOfAcceptanceWorkCardPageDataSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({pageData}) => pageData,
);
export const bffDeferredActsOfAcceptanceWorkCardTotalCountSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({totalCount}) => totalCount,
);
export const bffDeferredActsOfAcceptanceWorkCardTotalPagesSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const bffDeferredActsOfAcceptanceWorkCardProgressSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({progress}) => progress,
);
export const bffDeferredActsOfAcceptanceWorkCardProgressActionSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({progressAction}) => progressAction,
);
export const bffDeferredActsOfAcceptanceWorkCardItemsStatusDictOptionsSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({itemsStatusDict}) => dictionaryToOptions(itemsStatusDict),
);
export const bffDeferredActsOfAcceptanceWorkCardErrorSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({error}) => error,
);
export const bffDeferredActsOfAcceptanceWorkInitialValuesSelector = createSelector(
    deferredActsOfAcceptanceWorkCardSelector,
    ({card}) => {
        return {
            name: card.name || "",
            commentary: card.commentary || "",
            templateId: card.templateId || "",
            templateName: card.templateName || "",
        };
    },
);