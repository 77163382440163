import React from "react";

import Text from "../ActualComponents/Text";
import ExtLink from "../ExtLink";

import {DEPOSIT_TYPE} from "../../constants/deposit";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
} from "../../constants/links";

const DepositTypeText = (props) => {
    const {
        depositType,
        clientId,
        objectId,
        objectName,
        projectId,
        projectName,
    } = props;

    const getProjectLink = () => {
        if (!projectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId);

        return (
            <ExtLink
                title={projectName}
                to={link}
            >
                {projectName}
            </ExtLink>
        );
    };

    const getObjectLink = () => {
        if (!objectId) {
            return "-";
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                title={objectName}
                to={link}
            >
                {objectName}
            </ExtLink>
        );
    };

    if (depositType === DEPOSIT_TYPE.PROJECT_INDIVIDUAL_DEPOSIT) {
        return (
            <div className="d-flex">
                <Text className="me-1">
                    проект
                </Text>
                {getProjectLink()}
            </div>
        );
    }

    if (depositType === DEPOSIT_TYPE.OBJECT_INDIVIDUAL_DEPOSIT) {
        return (
            <div className="d-flex">
                <Text className="me-1">
                    объект
                </Text>
                {getObjectLink()}
            </div>
        );
    }

    if (depositType === DEPOSIT_TYPE.CLIENT_DEPOSIT) {
        return "компания";
    }

    return null;
};

export default DepositTypeText;