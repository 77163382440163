import {
    BFF_ADD_EMAIL_NOTIFICATION_ERROR,
    BFF_ADD_EMAIL_NOTIFICATION_REQUEST,
    BFF_ADD_EMAIL_NOTIFICATION_SUCCESS,
    BFF_DELETE_EMAIL_NOTIFICATION_ERROR,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS,
    BFF_DELETE_EMAIL_NOTIFICATION_REQUEST,
    BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS,
    BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR,
    BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST,
    BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    progressList: false,
    totalCount: 0,
    progressActon: false,
    error: {},
    pageData: {},
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_ADD_EMAIL_NOTIFICATION_REQUEST:
            return {
                ...state,
                progressActon: true,
            };
        case BFF_ADD_EMAIL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                progressActon: false,
            };
        case BFF_ADD_EMAIL_NOTIFICATION_ERROR:
            return {
                ...state,
                progressActon: false,
                error: payload,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_REQUEST:
            return {
                ...state,
                progressActon: true,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                progressActon: false,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_ERROR:
            return {
                ...state,
                progressActon: false,
                error: payload,
            };
        case BFF_GET_EMAIL_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case BFF_GET_EMAIL_NOTIFICATION_LIST_SUCCESS:
            const {
                results,
                totalCount,
            } = payload;

            return {
                ...state,
                progress: false,
                list: results,
                totalCount,
            };
        case BFF_GET_EMAIL_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                progressActon: true,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                progressActon: false,
            };
        case BFF_DELETE_EMAIL_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                progressActon: false,
                error: payload,
            };
        default:
            return state;
    }
};