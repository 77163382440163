import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as BlockedIcon} from "../../../images/lock_24.svg";
import {styleLargeLink} from "../../../styles/inline";
import DocumentManagementPersonnelFilter from "../document-management-personnel-filter";
import DocumentManagementPersonnelNew from "../document-management-personnel-new";
import EdmStaffNewFinishingForm from "../document-management-personnel-new/edm-staff-new-finishing-form";

import {clearEmptyOptionsFilterData} from "../../../utils/contractors";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {phoneFormat, removePhoneMask} from "../../../utils/stringFormat";
import {toastSuccess} from "../../../utils/toastHelper";
import {
    getEdmStaffListActionOptions,
    isAcceptedCreatingEdmStaff,
    isAcceptedImportEdmStaff,
    isAcceptedShowingEdmStaffCard,
} from "../../../utils/user-rights/edmStaff";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {NEW_STAFF_FORM_TYPE, STAFF_STATUS, STAFF_STATUS_DESCRIPTION} from "../../../constants/edmStaff";
import {formatFileDict, UPLOAD_TYPE} from "../../../constants/financeExport";
import {LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS} from "../../../constants/links";

import {edoAccessListSelector} from "../../../ducks/bff/clients/edo/documents/selectors";
import {
    archiveEdmStaff,
    exportEdmStaff,
    getEdmStaffList,
    importEdmStaffs,
} from "../../../ducks/bff/clients/edo/staff/actionCreators";
import {
    edmStaffListProgressSelector,
    edmStaffListSelector,
    edmStaffListTotalCountSelector,
    edmStaffListTotalPagesSelector,
} from "../../../ducks/bff/clients/edo/staff/selectors";
import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";
import {clientCurrentMemberIdSelector} from "../../../ducks/clientMember";

import "./style.sass";

class DocumentManagementPersonnel extends Component {
    static propTypes = {};

    static defaultProps = {};

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.clientId = clientId;


        this.state = {
            pageNum: 1,
            pageSize: 25,
            filterData: {},
            showArchiveStaffConfirm: false,
            showUnarchiveStaffConfirm: false,
            isSearch: false,
            isOpenImportModal: false,
            progressImport: false,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        this.fetchList();
    }

    get isAccessActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsEdoStaffKn,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsEdoSubdivisions,
        ]);
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    fetchList = () => {
        const {
            getEdmStaffList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const reqFilterData = clearEmptyOptionsFilterData(filterData);

        getEdmStaffList({
            clientId: this.clientId,
            pageNum,
            pageSize,
            ...reqFilterData,
            phone: removePhoneMask(filterData.phone),
        });
    };

    archiveStaff = (staffId, archive) => {
        return () => {
            const {
                archiveEdmStaff,
            } = this.props;

            this.closeArchiveConfirm();

            archiveEdmStaff({
                data: {
                    clientId: this.clientId,
                    staffId,
                    archive,
                },
                onSuccess: this.fetchList,
            });
        };
    };

    updateState = (newStateFields, cbFn = () => {
    }) => {
        return () => {
            this.setState({
                ...newStateFields,
            }, cbFn);
        };
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({pageNum}, () => {
            this.fetchList();
            document.querySelector(".client-card").scrollTo(0, 0);
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                dropdownPaymentNumber: "",
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    openArchiveStaffConfirm = (editableStaff) => {
        this.setState({
            showArchiveStaffConfirm: true,
            editableStaff,
        });
    };

    openUnarchiveStaffConfirm = (editableStaff) => {
        this.setState({
            showUnarchiveStaffConfirm: true,
            editableStaff,
        });
    };

    openEditStaffForm = (editableStaff) => {
        this.setState({
            showEditStaffForm: true,
            editableStaff,
        });
    };

    onClickActionItem = (option, staff) => {
        const {value: action} = option;

        switch (action) {
            case "archive-staff": {
                this.openArchiveStaffConfirm(staff);
                return;
            }
            case "unarchive-staff": {
                this.openUnarchiveStaffConfirm(staff);
                return;
            }
            case "edit-staff": {
                this.openEditStaffForm(staff);
                return;
            }
            default:
                break;
        }
    };

    getRows = () => {
        const {
            staffList,
        } = this.props;

        return staffList.map(staff => {
            const {
                staffId,
                phone,
                status,
                positionName,
                subdivisionName,
            } = staff;

            return {
                key: staffId,
                ...staff,
                contentRow: (
                    <NmListCard
                        noDivider
                        fluidPrimaryHeader
                        primaryHeader={this.renderStaffFullName(staff)}
                        secondaryHeaderStatus={this.renderStatus(status)}
                        labels={[
                            {label: "Телефон", text: phoneFormat(phone) || "-"},
                            {label: "Должность", text: positionName || "-"},
                            {label: "Подразделение", text: subdivisionName || "-"},
                        ]}
                        isFixedActions
                        actions={this.renderContextMenu(staff)}
                    />
                ),
            };
        });
    };

    submitFilter = (filterData) => {
        this.setState({
            filterData,
            isSearch: true,
        }, this.fetchList);
    };

    onCloseNewStaffForm = (isNeedRefresh) => {
        this.setState({
            showPersonnelNewForm: false,
        });

        isNeedRefresh && this.fetchList();
    };

    closeArchiveConfirm = () => {
        this.setState({
            showArchiveStaffConfirm: false,
            showUnarchiveStaffConfirm: false,
        });
    };

    renderContextMenu(staff) {
        const {
            edoAccessList,
            currentClientUserId,
        } = this.props;

        if (this.isClientArchived || !this.isAccessActions) {
            return null;
        }

        const options = getEdmStaffListActionOptions(staff, edoAccessList, currentClientUserId);

        if (isEmpty(options)) {
            return null;
        }

        return (
            <ContextMenu
                options={options}
                onClickItem={(option) => {
                    this.onClickActionItem(option, staff);
                }}
            />
        );
    };

    renderStaffFullName = (staff) => {
        const {
            fio,
            contractorBlocked,
        } = staff;

        const {
            edoAccessList,
        } = this.props;

        if (isAcceptedShowingEdmStaffCard(edoAccessList)) {
            return this.renderLink(staff);
        }

        return (
            <div className="document-management-personnel__fio-container">
                <div className="document-management-personnel__fio">
                    {fio}
                </div>
                {
                    contractorBlocked &&
                    <BlockedIcon
                        className="document-management-personnel__icon-blocked"
                        color="red"
                    />
                }
            </div>
        );
    };

    renderLink({fio, staffId, contractorBlocked}) {
        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const link = LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS.replace(":staffId", staffId).replace(":clientId", this.clientId);

        return (
            <ExtLink
                className="document-management-personnel__staff-link"
                pageData={{pageNum, pageSize}}
                filterData={
                    filterData
                }
                historyEnabled
                style={styleLargeLink}
                to={link}
            >
                {fio}
                {contractorBlocked && <BlockedIcon
                    className="document-management-personnel__icon-blocked"
                    color="red"
                />}
            </ExtLink>
        );
    };

    renderStatus = (status) => {
        const isWorking = status === STAFF_STATUS.WORKING;

        return (
            <NmBadge
                mod={isWorking ? "light-green" : "gray"}
                text={STAFF_STATUS_DESCRIPTION[status]}
            />
        );
    };

    renderNewStaffForm() {
        const {
            showPersonnelNewForm,
        } = this.state;

        return (
            showPersonnelNewForm &&
            <DocumentManagementPersonnelNew
                onCancel={this.onCloseNewStaffForm}
                clientId={this.clientId}
            />
        );
    };

    renderEditStaffForm() {
        const {
            showEditStaffForm,
            editableStaff,
        } = this.state;

        return (
            showEditStaffForm &&
            <EdmStaffNewFinishingForm
                type={!isEmpty(editableStaff.clientUserId) ? NEW_STAFF_FORM_TYPE.USER : NEW_STAFF_FORM_TYPE.CONTRACTOR}
                isEdit
                clientId={this.clientId}
                onCancel={this.updateState({showEditStaffForm: false}, this.fetchList)}
                user={editableStaff}
                onBackStepClick={this.backStepClick}
            />
        );
    };

    submitImportForm = ({file}) => {
        this.setState({progressImport: true});

        const {importEdmStaffs} = this.props;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientId", this.clientId);

        importEdmStaffs({
            data: formData,
            getResult: ({errorMessage}) => {
                this.setState({progressImport: false});
                if (!errorMessage) {
                    toastSuccess("Загрузка файла успешно завершена. Список кадров будет обновлен через некоторое время.");
                    this.openImportFileModal(false)();
                    setTimeout(this.fetchList, 5000);
                }
            },
        });
    };

    openImportFileModal = (isShow) => {
        return () => {
            this.setState({
                isOpenImportModal: isShow,
            });
        };
    };

    renderImportForm() {
        const {
            isOpenImportModal,
            progressImport,
        } = this.state;

        if (!isOpenImportModal) {
            return null;
        }

        return (
            <ImportFromFilePatternV2
                maxSize={25}
                patternLink="/files/Шаблон_Список_кадров.xlsx"
                onSubmit={this.submitImportForm}
                onClose={this.openImportFileModal(false)}
                progress={progressImport}
            />
        );
    };

    renderArchiveStaffConfirm() {
        const {
            showArchiveStaffConfirm,
            showUnarchiveStaffConfirm,
            editableStaff,
        } = this.state;

        const action = showArchiveStaffConfirm || showUnarchiveStaffConfirm;

        return (
            action &&
            <NmConfirmV2
                content={`Вы действительно хотите ${showArchiveStaffConfirm ? "архивировать" : "восстановить из архива"} сотрудника ${editableStaff.fio}?`}
                onCancel={this.closeArchiveConfirm}
                onConfirm={this.archiveStaff(editableStaff.staffId, showArchiveStaffConfirm)}
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    };

    onAddFinanceExportTask = () => {
        const {exportEdmStaff} = this.props;

        exportEdmStaff({
            clientId: this.clientId,
            uploadType: UPLOAD_TYPE.STAFF_EDO.value,
            fromClientId: this.clientId,
            formatType: formatFileDict.XLSX,
        });
    };

    getMediaControls = () => {
        const {totalCount, edoAccessList} = this.props;
        const {isSearch} = this.state;
        const isShowControls = totalCount || isSearch;

        return {
            renderCount: {
                mobile: 1,
                tablet: 2,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        children: "Добавить сотрудника",
                        icon: <AddIcon />,
                        onClick: this.updateState({showPersonnelNewForm: true}),
                    },
                    visible: !this.isClientArchived
                        && this.isAccessActions
                        && isAcceptedCreatingEdmStaff(edoAccessList)
                        && isShowControls,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Загрузить список",
                        onClick: this.openImportFileModal(true),
                    },
                    visible: !this.isClientArchived
                        && this.isAccessActions
                        && isAcceptedImportEdmStaff(edoAccessList),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Выгрузить",
                        onClick: this.onAddFinanceExportTask,
                    },
                    visible: !this.isClientArchived && isShowControls,
                },
            ],
        };
    };

    render() {
        const {
            pageNum,
            pageSize,
            isSearch,
        } = this.state;

        const {
            totalPages = 0,
            totalCount,
            listProgress,
            edoAccessList,
        } = this.props;

        return (
            <NmPage
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                className="document-management-personnel"
                typeFilter="vertical"
                isLoaded={listProgress}
                header={
                    <NmPageHeader
                        text="Кадры"
                        totalCount={totalCount}
                    />
                }
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                mediaControls={this.getMediaControls()}
                filtersBase={
                    <DocumentManagementPersonnelFilter
                        submitFilter={this.submitFilter}
                        clientId={this.clientId}
                    />
                }
                totalCount={totalCount}
            >
                {this.renderImportForm()}
                {this.renderArchiveStaffConfirm()}
                {this.renderNewStaffForm()}
                {this.renderEditStaffForm()}
                {
                    !(totalCount || listProgress) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction={(!this.isClientArchived && isAcceptedCreatingEdmStaff(edoAccessList)) && "Добавить сотрудника"}
                            onClickAction={!this.isClientArchived && this.updateState({showPersonnelNewForm: true})}
                            isSearch={isSearch}
                        /> :
                        <CheckboxList rows={this.getRows()} />
                }
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        staffList: edmStaffListSelector(state),
        totalPages: edmStaffListTotalPagesSelector(state),
        totalCount: edmStaffListTotalCountSelector(state),
        listProgress: edmStaffListProgressSelector(state),
        edoAccessList: edoAccessListSelector(state),
        currentClientUserId: clientCurrentMemberIdSelector(state),
        client: clientCardInfoSelector(state),
    }),
    {
        getEdmStaffList,
        archiveEdmStaff,
        importEdmStaffs,
        exportEdmStaff,
    },
)(withTranslation()(DocumentManagementPersonnel)));
