import {FILTER} from "../index";

import {FilterComponentParams, FilterFormState, FilterGetDefaultStateParams} from "../types";

// Преобразует из конфига начальный state
export const getFilterDefaultState = (params: FilterGetDefaultStateParams) => {
    const {
        filters,
        wrapFilters,
        initState,
    } = params;

    if (initState) {
        return {...initState};
    }

    const handleComponentDefaultStateValue = (componentConfig: FilterComponentParams, prev: any) => {
        const {component} = componentConfig;

        switch (component) {
            case FILTER.DATE_RANGE: {
                const startFieldName = componentConfig.startFieldName;
                const endFieldName = componentConfig.endFieldName;

                prev[startFieldName] = componentConfig.value[startFieldName];
                prev[endFieldName] = componentConfig.value[endFieldName];

                break;
            }
            case FILTER.RANGE_INPUT: {
                const startFieldName = componentConfig.nameStart;
                const endFieldName = componentConfig.nameEnd;

                prev[startFieldName] = componentConfig.valueStart;
                prev[endFieldName] = componentConfig.valueEnd;

                break;
            }
            default: {
                const fieldName = componentConfig.name;

                prev[fieldName] = componentConfig.value || "";
            }
        }
    };

    if (wrapFilters) {
        return wrapFilters.reduce((result, item) => {
            handleComponentDefaultStateValue(item, result);

            return result;
        }, {} as FilterFormState);
    }

    return filters?.reduce((prev, currentElem) => {
        const {row} = currentElem;

        row.map((componentConfig) => {
            handleComponentDefaultStateValue(componentConfig, prev);
        });

        return prev;
    }, {});
};