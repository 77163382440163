export const INDIVIDUAL_PROJECTS_DEPOSIT_TOOLTIP_TEXT = {
    NON_DISTRIBUTED_DEPOSIT: "Содержит сумму нераспределенного по проектам и объектам депозита компании " +
        "по счету для выплат исполнителям с типом налогообложения :paymentType. Этот депозит будет использоваться " +
        "для пополнения депозита компании. Для пополнения депозита компании перейдите " +
        "в раздел \"Компания → Финансы → Депозит (:paymentType)\" и нажмите \"Пополнить депозит компании\"",
    CLIENT_DEPOSIT: "Содержит сумму депозита компании по счету для выплат исполнителям с типом налогообложения :paymentType",
    ORDERS_LIMIT: "Максимальная сумма, на которую единовременно могут быть созданы заказы и задания для Самозанятых. " +
        "Рассчитывается как сумма депозита и лимита для заказов без обеспечения (:paymentType). Сумма внизу " +
        "блока - это сумма выделенного лимита для заказов без обеспечения (:paymentType) из настроек компании",
    TOTAL_DEPOSIT: "Содержит сумму депозита компании по счету для выплат исполнителям с типом налогообложения :paymentType по компании, проектам и объектам, нераспределенный депозит",
    PROJECT_DEPOSIT: "Содержит сумму индивидуального депозита по проекту компании по счету для выплат исполнителям с типом налогообложения НПД\" (или \"НДФЛ\" - если показатели в блоке НДФЛ)",
    OBJECT_DEPOSIT: "Содержит сумму индивидуального депозита по объекту компании по счету для выплат исполнителям с типом налогообложения НПД\" (или \"НДФЛ\" - если показатели в блоке НДФЛ)",
    OBJECT_ORDERS_LIMIT: "Максимальная сумма, на которую единовременно могут быть созданы заказы и задания " +
        "для Самозанятых, ИП на объекте. Рассчитывается как сумма депозита объекта и выделенного " +
        "лимита для заказов без обеспечения по объекту (:paymentType). Сумма внизу блока - это сумма выделенного " +
        "лимита для заказов без обеспечения (:paymentType) на данном проекте",
    PROJECT_ORDERS_LIMIT: "Максимальная сумма, на которую единовременно могут быть созданы заказы и задания " +
        "для Самозанятых, ИП на проекте. Рассчитывается как сумма депозита проекта и выделенного " +
        "лимита для заказов без обеспечения по проекту (:paymentType). Сумма внизу блока - это сумма выделенного " +
        "лимита для заказов без обеспечения (:paymentType) на данном проекте",
};