import React from "react";

import NmModal from "../../../ActualComponents/NmModal";
import ApplyButtons from "../../../ApplyButtons";

import bem from "../../../../utils/bem";

import {EXECUTOR_CHECK_STATUS} from "../../../../constants/contractor";

const ScanCheckConfirmWindow = (props) => {
    const {
        progressCompletionChecking,
        statusSolution,
        documentsText,
        contractorFullName,
        setOpenConfirmWindow,
        handleSaveDecisions,
        setFileDecisionsWithoutSelfie,
        isRejectSelfie,
        checkedDocumentName,
        isRequestInnList,
        isRequestPassportPageNumber19,
    } = props;

    const [, element] = bem("contractor-verification-date");

    const getApprovedContent = () => {
        if (checkedDocumentName) {
            return (
                <p>
                    Документ "
                    {checkedDocumentName}
                    "
                    {" "}
                    {contractorFullName}
                    {" "}
                    <span className={element("modal-msg", {confirmed: true})}>
                        одобрен
                    </span>
                </p>
            );
        }

        return (
            <p>
                Вы
                {" "}
                <span className={element("modal-msg", {confirmed: true})}>
                    одобрили
                </span>
                {" "}
                все
                документы
                {" "}
                <span>
                    {contractorFullName}
                </span>
            </p>
        );
    };

    const getRejectedContent = () => {
        if (checkedDocumentName) {
            return (
                <p>
                    Документ "
                    {checkedDocumentName}
                    "
                    {" "}
                    {contractorFullName}
                    {" "}
                    <span className={element("modal-msg", {rejected: true})}>
                        отклонен
                    </span>
                </p>
            );
        }

        return (
            <p>
                Вы
                {" "}
                <span className={element("modal-msg", {rejected: true})}>
                    отклонили
                </span>
                {" "}
                {documentsText}
                {" "}
                <span>
                    {contractorFullName}
                </span>
                <span>
                    {isRejectSelfie && " по причине \"Человек, изображённый на фотографии, не совпадает с фотографией в паспорте\""}
                </span>
                . Исполнителю будет отправлено
                уведомление
            </p>
        );
    };

    const getTextModal = () => {
        if (isRequestInnList && isRequestPassportPageNumber19) {
            const description = "Заявка будет отклонена с дозапросом фото листа с ИНН и фотографии разворота " +
                "паспорта со сведениями о ранее выданных паспортах. Исполнителю будет отправлено уведомление.";

            return `Вы запросили подтверждение ИНН и 19 стр. паспорта исполнителя ${contractorFullName}. ${description}`;
        }

        if (isRequestPassportPageNumber19) {
            const description = "Заявка будет отклонена с дозапросом фотографии разворота паспорта со " +
                "сведениями о ранее выданных паспортах. Исполнителю будет отправлено уведомление.";

            return `Вы запросили 19 стр. паспорта исполнителя ${contractorFullName}. ${description}`;
        }

        if (isRequestInnList) {
            const description = "Заявка будет отклонена с дозапросом фото листа с ИНН. Исполнителю будет отправлено уведомление";

            return `Вы запросили подтверждение ИНН исполнителя ${contractorFullName}.\n${description}`;
        }

        if (statusSolution === EXECUTOR_CHECK_STATUS.APPROVED.value) {
            return getApprovedContent();
        }

        return getRejectedContent();
    };

    return (
        <NmModal
            size="md"
            className={element("modal")}
            footer={
                <ApplyButtons
                    mobile="column"
                    submit={handleSaveDecisions}
                    submitBtnContent="Завершить проверку"
                    onClose={isRejectSelfie ? setFileDecisionsWithoutSelfie : () => {
                        setOpenConfirmWindow(false);
                    }}
                    disabled={progressCompletionChecking}
                    cancelBtnDisabled={progressCompletionChecking}
                    cancelBtnContent="Пересмотреть решение"
                    align="center"
                />
            }
            onClose={isRejectSelfie ? setFileDecisionsWithoutSelfie : () => {
                setOpenConfirmWindow(false);
            }}
            children={
                <div className={element("modal-msg")}>
                    {getTextModal()}
                </div>
            }
        />
    );
};

export default ScanCheckConfirmWindow;