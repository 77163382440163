import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

import {MESSAGES_EXPORT_TOAST} from "../constants/messages";

const getController = () => {
    return (
        getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/contractors/onboarding/scenario-card/recruiters",
            client: "/client-adm/contractors/onboarding/scenario-card/recruiters",
        })
    );
};

const GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST = "GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST";
const GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS = "GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS";
const GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR = "GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR";

const GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_REQUEST = "GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_REQUEST";
const GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_SUCCESS = "GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_SUCCESS";
const GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_ERROR = "GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_ERROR";

const ADD_ONBOARDING_SCENARIO_RECRUITER_REQUEST = "ADD_ONBOARDING_SCENARIO_RECRUITER_REQUEST";
const ADD_ONBOARDING_SCENARIO_RECRUITER_SUCCESS = "ADD_ONBOARDING_SCENARIO_RECRUITER_SUCCESS";
const ADD_ONBOARDING_SCENARIO_RECRUITER_ERROR = "ADD_ONBOARDING_SCENARIO_RECRUITER_ERROR";

const EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST = "EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST";
const EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS = "EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS";
const EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR = "EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR";

const ONBOARDING_RECRUITERS_UPDATE_STORE = "ONBOARDING_UPDATE_STORE";

const initial = {
    list: [],
    card: {},
    listProgress: false,
    pageData: 0,
    progressAction: false,
    namesList: [],
    isSearch: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST:
            return {
                ...state,
                pageData: payload,
                isSearch: true,
                listProgress: true,
            };
        case GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS:
            const {results, totalCount} = payload;

            return {
                ...state,
                list: results,
                totalCount,
                listProgress: false,
            };
        case GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR:
            return {
                ...state,
                error: payload,
                listProgress: false,
            };
        case ADD_ONBOARDING_SCENARIO_RECRUITER_REQUEST:
        case EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case ADD_ONBOARDING_SCENARIO_RECRUITER_SUCCESS:
        case ADD_ONBOARDING_SCENARIO_RECRUITER_ERROR:
        case EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS:
        case EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR:
            return {
                ...state,
                progressAction: false,
            };
        case GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_SUCCESS:
            return {
                ...state,
                namesList: payload.results,
            };
        case ONBOARDING_RECRUITERS_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default: return state;
    }
};

export const getOnboardingScenarioRecruitersList = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST,
    payload,
});

export const getOnboardingScenarioRecruitersNamesList = (payload) => ({
    type: GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_REQUEST,
    payload,
});

export const addOnboardingScenarioRecruiter = (payload) => ({
    type: ADD_ONBOARDING_SCENARIO_RECRUITER_REQUEST,
    payload,
});

export const exportOnboardingScenarioRecruitersList = (payload) => ({
    type: EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST,
    payload,
});

export const updateOnboardingRecruitersStore = (payload) => ({
    type: ONBOARDING_RECRUITERS_UPDATE_STORE,
    payload,
});

export const onboardingRecruitersSelector = state => state.onboardingRecruiters;
export const onboardingRecruitersListSelector = createSelector(onboardingRecruitersSelector, ({list}) => list);
export const onboardingRecruitersProgressActionSelector = createSelector(onboardingRecruitersSelector, ({progressAction}) => progressAction);
export const onboardingRecruitersListTotalPagesSelector = createSelector(onboardingRecruitersSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const onboardingRecruitersListTotalCountSelector = createSelector(onboardingRecruitersSelector, ({totalCount}) => totalCount);
export const onboardingRecruitersListProgressSelector = createSelector(onboardingRecruitersSelector, ({listProgress}) => listProgress);
export const onboardingRecruitersListIsSearchSelector = createSelector(onboardingRecruitersSelector, ({isSearch}) => isSearch);
export const onboardingRecruitersNamesListOptionsSelector = createSelector(onboardingRecruitersSelector, ({namesList}) => {
    return namesList.map(item => {
        return {
            key: item.recruiterId,
            value: item.recruiterId,
            text: item.fullName,
        };
    });
});

export const getOnboardingScenarioRecruitersListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR, payload: error});
    }
};

export const getOnboardingScenarioRecruitersNamesListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getOnboardingRecruiterNamesPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_ERROR, payload: error});
    }
};

export const addOnboardingScenarioRecruiterSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_ONBOARDING_SCENARIO_RECRUITER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();
        yield put({type: ADD_ONBOARDING_SCENARIO_RECRUITER_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: ADD_ONBOARDING_SCENARIO_RECRUITER_ERROR, payload: error});
    }
};

export const exportOnboardingScenarioRecruitersListSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/export`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess(MESSAGES_EXPORT_TOAST.replace(":name", "рекрутеров"));

        yield put({type: EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({type: EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST, getOnboardingScenarioRecruitersListSaga),
        takeEvery(GET_ONBOARDING_SCENARIO_RECRUITERS_NAMES_LIST_REQUEST, getOnboardingScenarioRecruitersNamesListSaga),
        takeEvery(ADD_ONBOARDING_SCENARIO_RECRUITER_REQUEST, addOnboardingScenarioRecruiterSaga),
        takeEvery(EXPORT_ONBOARDING_SCENARIO_RECRUITERS_LIST_REQUEST, exportOnboardingScenarioRecruitersListSaga),
    ]);
}