import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../../components/ActualComponents/NmDropdownV2";
import {
    VerticalMovingListDraggableItem,
} from "../../../../../../components/ActualComponents/VerticalMovingList/DraggableItem";
import {
    bffRecruitmentDirectoryStatusListProgressSelector,
} from "../../../../../../ducks/bff/recruitment/directory/selectors";

import {getWeekOptions} from "../../../../../../utils/objectHelper";

export const RecruitmentDirectoryFunnelEditFormRow = (props) => {
    const {
        index,
        draggableId,
        touched,
        values,
        errors,
        setFieldValue,
        statusOptions,
        deleteItem,
        isDefault,
    } = props;

    const progress = useSelector(bffRecruitmentDirectoryStatusListProgressSelector);

    const names = {
        statusId: `statusList[${index}].statusId`,
        isDefault: `statusList[${index}].isDefault`,
        statusDurationDays: `statusList[${index}].statusDurationDays`,
    };

    const onChange = (_event, {name, value}) => {
        setFieldValue(name, value);
    };

    const options = useMemo(() => {
        return getWeekOptions({
            isVisibleNotSelected: true,
        });
    }, []);

    return (
        <VerticalMovingListDraggableItem
            index={index}
            draggableId={draggableId}
            isHideDeleteButton={!!isDefault}
            deleteItem={deleteItem}
            deleteButtonClassName="mt-md-3"
            dragButtonClassName="mt-md-3"
            itemContainerClassName="align-items-start"
        >
            <div className="fluid-flex-grow row gx-4">
                <div className={`col-16 ${isDefault ? "col-md-16" : "col-md-10"}`}>
                    <NmDropdownV2
                        size="xl"
                        disabled={progress}
                        options={statusOptions}
                        name={names.statusId}
                        value={values.statusId}
                        onChange={(_event, {name, value, isDefault}) => {
                            setFieldValue(name, value);
                            setFieldValue(names.isDefault, isDefault);
                        }}
                        placeholder="Выберите из списка"
                        error={
                            touched &&
                            touched.statusId &&
                            errors &&
                            errors.statusId
                        }
                    />
                </div>
                {
                    !isDefault &&
                    <div className="col-16 col-md-6 mt-3 mt-md-0">
                        <NmDropdownV2
                            size="xl"
                            search={true}
                            disabled={progress}
                            options={options}
                            name={names.statusDurationDays}
                            value={values.statusDurationDays}
                            onChange={onChange}
                            placeholder="Не выбрано"
                            error={
                                touched &&
                                touched.statusDurationDays &&
                                errors &&
                                errors.statusDurationDays
                            }
                        />
                    </div>
                }
            </div>
        </VerticalMovingListDraggableItem>
    );
};