import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, Switch} from "react-router";

import {RecruitmentCandidatesComments} from "../../../containers/recruitment/candidates/comments";
import {RecruitmentCandidatesFeedList} from "../../../containers/recruitment/candidates/feed-list/candidate-page";
import {RecruitmentVacancyCandidatesFeedList} from "../../../containers/recruitment/candidates/feed-list/vacancy-page";
import {RecruitmentResponseCandidateCard} from "../../../containers/recruitment/candidates/response-card";
import {RecruitmentCandidatesResponses} from "../../../containers/recruitment/candidates/responses";
import {RecruitmentVacancyCandidateCard} from "../../../containers/recruitment/candidates/vacancy-card";
import {
    getRecruitmentAccessControlCanSetCandidateStatus,
} from "../../../ducks/bff/recruitment/access-control/actionsCreators";
import {
    updateRecruitmentVacancyCandidatesStore,
} from "../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import {
    bffRecruitmentVacancyCandidatesCardErrorSelector,
} from "../../../ducks/bff/recruitment/vacancyCandidates/selectors";
import NmEmptyPageV2 from "../../ActualComponents/NmEmptyPageV2";
import Tabs from "../../ActualComponents/Tabs";
import NmLoader from "../../NmLoader";
import RecruitmentCandidatesCardHeader from "../CandidatesCardHeader";

import {
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_FEED,
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO,
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_RESPONSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_RESPONSES,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO,
} from "../../../constants/links";

import {clientCurrentMemberIdSelector} from "../../../ducks/clientMember";

export const RecruitmentCandidatesCardPage = (props) => {
    const {
        candidateId,
        responseId,
        clientId,
        fetchList,
        vacancyId,
        isVacancyCandidate,
        activeFunnelStatusFilter,
        progress,
        isEmptyCard,
        card,
        cardLink,
        reject,
        fetchCard,
        buttonFunnelMoveName,
        isNewResponses,
        progressAction,
    } = props;

    const dispatch = useDispatch();
    const cardError = useSelector(bffRecruitmentVacancyCandidatesCardErrorSelector);
    const clientUserId = useSelector(clientCurrentMemberIdSelector);

    useEffect(() => {
        if (clientUserId) {
            dispatch(getRecruitmentAccessControlCanSetCandidateStatus({id: clientUserId}));
        }
    }, []);

    useEffect(() => {
        if (cardError) {
            dispatch(updateRecruitmentVacancyCandidatesStore({
                cardError: null,
            }));
        }
    }, [candidateId]);

    const panes = useMemo(() => {
        const responsesLink = LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_RESPONSES
            .replace(":id", candidateId)
            .replace(":clientId", clientId);

        if (candidateId && isVacancyCandidate) {
            return [
                {
                    active: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                    link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                    name: "Информация о кандидате",
                },
                {
                    active: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_RESPONSES
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                    link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_RESPONSES
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                    name: "Отклики кандидата",
                    //todo:рек2.0 привязать каунтер
                    count: 0,
                },
                {
                    name: "Лента событий",
                    active: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                    link: LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", candidateId)
                        .replace(":activeFunnelStatusFilter", activeFunnelStatusFilter),
                },
            ];
        }

        if (candidateId && !isVacancyCandidate) {
            return [
                {
                    active: LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO
                        .replace(":id", candidateId)
                        .replace(":clientId", clientId),
                    link: LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO
                        .replace(":id", candidateId)
                        .replace(":clientId", clientId),
                    name: "Информация о кандидате",
                },
                {
                    active: responsesLink,
                    link: responsesLink,
                    name: "Отклики кандидата",
                    //todo:рек2.0 привязать каунтер
                    count: 0,
                },
                {
                    name: "Лента событий",
                    active: LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_FEED
                        .replace(":clientId", clientId)
                        .replace(":id", candidateId),
                    link: LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_FEED
                        .replace(":clientId", clientId)
                        .replace(":id", candidateId),
                },
            ];
        }

        if (cardLink && responseId) {
            return [
                {
                    active: cardLink
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", responseId),
                    link: cardLink
                        .replace(":clientId", clientId)
                        .replace(":vacancyId", vacancyId)
                        .replace(":id", responseId),
                    name: "Информация из отклика",
                },
            ];
        }

        return [];
    }, [
        cardLink,
        clientId,
        candidateId,
        responseId,
        isVacancyCandidate,
        activeFunnelStatusFilter,
    ]);

    if (cardError?.errorCode === "CANDIDATE_NOT_FOUND") {
        return (
            <NmEmptyPageV2
                title="Информация о кандидате"
                description="Выберите кандидата"
            />
        );
    }

    if (isEmptyCard) {
        return (
            <NmLoader
                inverted={true}
                active={true}
            />
        );
    }

    return (
        <>
            {
                progress &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
            <RecruitmentCandidatesCardHeader
                clientId={clientId}
                candidateId={candidateId}
                responseId={responseId}
                fetchList={fetchList}
                card={card}
                vacancyId={vacancyId}
                activeFunnelStatusFilter={activeFunnelStatusFilter}
                isVacancyCandidate={isVacancyCandidate}
                reject={reject}
                fetchCard={fetchCard}
                buttonFunnelMoveName={buttonFunnelMoveName}
                isNewResponses={isNewResponses}
                cardLink={cardLink}
                progressAction={progressAction}
            />
            {
                isVacancyCandidate &&
                <RecruitmentCandidatesComments
                    candidateId={candidateId}
                    clientId={clientId}
                    vacancyId={vacancyId}
                    activeFunnelStatusFilter={activeFunnelStatusFilter}
                />
            }
            {
                candidateId &&
                <Tabs
                    {...props}
                    panes={panes}
                    className="mt-2 mt-md-4 mb-3 mb-md-4"
                />
            }
            <Switch>
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO}
                    component={RecruitmentResponseCandidateCard}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO}
                    component={RecruitmentResponseCandidateCard}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO}
                    component={RecruitmentVacancyCandidateCard}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO}
                    component={RecruitmentVacancyCandidateCard}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_RESPONSES}
                    component={RecruitmentCandidatesResponses}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_RESPONSES}
                    component={RecruitmentCandidatesResponses}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED}
                    component={RecruitmentVacancyCandidatesFeedList}
                />
                <Route
                    exact
                    path={LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_FEED}
                    component={RecruitmentCandidatesFeedList}
                />
            </Switch>
        </>
    );
};
