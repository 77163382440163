import {
    BFF_CLIENT_MEMBER_ADD_REQUEST,
    BFF_CLIENT_MEMBER_ARCHIVE_REQUEST,
    BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE,
    BFF_CLIENT_MEMBER_UPDATE_REQUEST,
    BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST,
    BFF_GET_CLIENT_MEMBER_CARD_REQUEST,
    BFF_GET_CLIENT_MEMBER_LIST_REQUEST,
} from "./actions";

export function getClientMemberList(payload) {
    return {
        type: BFF_GET_CLIENT_MEMBER_LIST_REQUEST,
        payload,
    };
}
export function archiveMember(payload) {
    return {
        type: BFF_CLIENT_MEMBER_ARCHIVE_REQUEST,
        payload,
    };
}
export function addClientMember(payload) {
    return {
        type: BFF_CLIENT_MEMBER_ADD_REQUEST,
        payload: payload,
    };
}
export function updateClientMember(payload) {
    return {
        type: BFF_CLIENT_MEMBER_UPDATE_REQUEST,
        payload,
    };
}
export function getClientMemberById(payload) {
    return {
        type: BFF_GET_CLIENT_MEMBER_CARD_REQUEST,
        payload,
    };
}
export function generatePasswordClientMember(payload) {
    return {
        type: BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST,
        payload,
    };
}
export function updateFieldClientMemberStore(payload) {
    return {
        type: BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE,
        payload,
    };
}