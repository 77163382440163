import {
    BFF_NOTIFICATION_ADD_VIEW_REQUEST,
    BFF_NOTIFICATION_ADD_VIEWS_REQUEST,
    BFF_NOTIFICATION_GET_PAGE_REQUEST,
    BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST,
} from "./actions";

export function getPageNotification(payload) {
    return {
        type: BFF_NOTIFICATION_GET_PAGE_REQUEST,
        payload,
    };
}

export function getNotificationUnreadCount(payload) {
    return {
        type: BFF_NOTIFICATION_GET_UNREAD_COUNT_REQUEST,
        payload,
    };
}

export function addViewNotification(payload) {
    return {
        type: BFF_NOTIFICATION_ADD_VIEW_REQUEST,
        payload,
    };
}

export function addViewsNotification(payload) {
    return {
        type: BFF_NOTIFICATION_ADD_VIEWS_REQUEST,
        payload,
    };
}