import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getCommonOrderObjects,
    updateCommonOrderStore,
} from "../../../ducks/bff/common/order/actionCreators";
import {
    bffCommonOrderObjectsOptionsSelector,
} from "../../../ducks/bff/common/order/selectors";

export const useObjectsDropdown = (params) => {
    const {
        filters,
    } = params;

    const dispatch = useDispatch();

    const options = useSelector(bffCommonOrderObjectsOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateCommonOrderStore({
                orderObjects: [],
            }));
        };
    }, []);

    const fetchList = (nameSubstring) => {
        dispatch(getCommonOrderObjects({
            ...filters,
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            nameSubstring: handleFormString(nameSubstring),
        }));
    };

    const onSearchChange = (nameSubstring) => {
        fetchList(nameSubstring);
    };

    return {
        options,
        label: "Проект",
        placeholder: "Выберите проект",
        onSearchChange,
        search: true,
    };
};