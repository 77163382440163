import {
    CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST,
    CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST,
    CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
    CONTRACTOR_TAX_STATUS_REFRESH_REQUEST, CONTRACTOR_UPDATE_REQUEST,
    CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
    DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
    GET_CONTRACTOR_BANK_STATUS_REQUEST,
    GET_INN_BY_PERSONAL_INFO_REQUEST,
    GET_PENDING_PERSONAL_DATA_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST,
    GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST,
    UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
    UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
    UPDATE_CONTRACTOR_PERSONAL_DATA_STORE,
} from "./actions";

export function updateContractorIndividualEntrepreneurLegalInfo(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_LEGAL_INFO_REQUEST,
        payload,
    };
}

export function updateContractorIndividualEntrepreneurBankRequisites(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_BANK_REQUISITES_REQUEST,
        payload,
    };
}

export function updateContractorIndividualEntrepreneurStatus(payload) {
    return {
        type: UPDATE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_REQUEST,
        payload,
    };
}

export function deleteContractorIndividualEntrepreneurData(payload) {
    return {
        type: DELETE_CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_DATA_REQUEST,
        payload,
    };
}

export function getContractorPendingVerificationWizard(payload) {
    return {
        type: CONTRACTORS_PENDING_VERIFICATION_WIZARD_STEPS_LIST_REQUEST,
        payload,
    };
}

export function refreshContractorTaxStatus(payload) {
    return {
        type: CONTRACTOR_TAX_STATUS_REFRESH_REQUEST,
        payload,
    };
}

export function getInnByPersonalInfo(payload) {
    return {
        type: GET_INN_BY_PERSONAL_INFO_REQUEST,
        payload,
    };
}

export function endCheckOnContractorPersonalDataStatus(payload) {
    return {
        type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
        payload,
    };
}

export function endMyCheckOnContractorPersonalDataStatus(payload) {
    return {
        type: CONTRACTORS_REGISTRY_CONTRACTOR_PERSONAL_DATA_END_MY_CHECK_ON_CONTRACTOR_PERSONAL_DATA_STATUS_REQUEST,
        payload,
    };
}

export function updateContractorBankRequisites(payload) {
    return {
        type: UPDATE_CONTRACTOR_BANK_REQUISITES_REQUEST,
        payload,
    };
}

export function updateDataContractorAdditionalDocument(payload) {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENT_DATA_UPDATE_REQUEST,
        payload,
    };
};

export function getContractorAdditionalDocumentAggregation(payload) {
    return {
        type: CONTRACTOR_ADDITIONAL_DOCUMENTS_GET_AGGREGATION_REQUEST,
        payload,
    };
};

export function getPendingPersonalData(payload) {
    return {
        type: GET_PENDING_PERSONAL_DATA_REQUEST,
        payload,
    };
}

export function getPersonalDataPendingComparison(payload) {
    return {
        type: GET_PERSONAL_DATA_PENDING_COMPARISON_REQUEST,
        payload,
    };
}

export function getPersonalDataPendingComparisonAvailability(payload) {
    return {
        type: GET_PERSONAL_DATA_PENDING_COMPARISON_AVAILABILITY_REQUEST,
        payload,
    };
}

export function getContractorBankStatus(payload) {
    return {
        type: GET_CONTRACTOR_BANK_STATUS_REQUEST,
        payload,
    };
}

export function updateContractor(payload) {
    return {
        type: CONTRACTOR_UPDATE_REQUEST,
        payload,
    };
}

export function updateContractorRegistrationStatus(payload) {
    return {
        type: CONTRACTOR_REGISTRATION_STATUS_UPDATE_REQUEST,
        payload,
    };
}

export function updateContractorPersonalDataStore(payload) {
    return {
        type: UPDATE_CONTRACTOR_PERSONAL_DATA_STORE,
        payload,
    };
}