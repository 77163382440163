export const BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST = "BFF_FINANCES_PAYMENTS_GET_PAGE_REQUEST";
export const BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS = "BFF_FINANCES_PAYMENTS_GET_PAGE_SUCCESS";
export const BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR = "BFF_FINANCES_PAYMENTS_GET_PAGE_ERROR";

export const BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST = "BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_REQUEST";
export const BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_SUCCESS = "BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_SUCCESS";
export const BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_ERROR = "BFF_FINANCES_PAYMENTS_GET_TOTAL_AMOUNT_ERROR";

export const BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST = "BFF_FINANCES_PAYMENTS_CONFIRM_REQUEST";
export const BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS = "BFF_FINANCES_PAYMENTS_CONFIRM_SUCCESS";
export const BFF_FINANCES_PAYMENTS_CONFIRM_ERROR = "BFF_FINANCES_PAYMENTS_CONFIRM_ERROR";

export const BFF_FINANCES_PAYMENTS_REJECT_REQUEST = "BFF_FINANCES_PAYMENTS_REJECT_REQUEST";
export const BFF_FINANCES_PAYMENTS_REJECT_SUCCESS = "BFF_FINANCES_PAYMENTS_REJECT_SUCCESS";
export const BFF_FINANCES_PAYMENTS_REJECT_ERROR = "BFF_FINANCES_PAYMENTS_REJECT_ERROR";

export const BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST = "BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_REQUEST";
export const BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS = "BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_SUCCESS";
export const BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR = "BFF_FINANCES_PAYMENTS_CANCEL_RECEIPT_ERROR";

export const BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST = "BFF_FINANCES_PAYMENTS_GET_RECEIPT_REQUEST";
export const BFF_FINANCES_PAYMENTS_GET_RECEIPT_SUCCESS = "BFF_FINANCES_PAYMENTS_GET_RECEIPT_SUCCESS";
export const BFF_FINANCES_PAYMENTS_GET_RECEIPT_ERROR = "BFF_FINANCES_PAYMENTS_GET_RECEIPT_ERROR";

export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_REQUEST";
export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_SUCCESS = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_SUCCESS";
export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_ERROR = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_PAGE_ERROR";

export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_REQUEST";
export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_SUCCESS = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_SUCCESS";
export const BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_ERROR = "BFF_FINANCES_PAYMENTS_SPENT_LIMIT_GET_FINANCE_ERROR";

export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_REQUEST";
export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_SUCCESS = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_SUCCESS";
export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_ERROR = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_PAGE_ERROR";

export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_REQUEST";
export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_SUCCESS = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_SUCCESS";
export const BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_ERROR = "BFF_FINANCES_PAYMENTS_REGISTRY_ORDERS_IN_PROGRESS_GET_AMOUNT_ERROR";

export const FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST = "FINANCE_PAYMENT_STEPS_LOG_READ_REQUEST";
export const FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS = "FINANCE_PAYMENT_STEPS_LOG_READ_SUCCESS";
export const FINANCE_PAYMENT_STEPS_LOG_READ_ERROR = "FINANCE_PAYMENT_STEPS_LOG_READ_ERROR";

export const ORDER_WORK_REPORT_LOG_REQUEST = "ORDER_WORK_REPORT_LOG_REQUEST";
export const ORDER_WORK_REPORT_LOG_SUCCESS = "ORDER_WORK_REPORT_LOG_SUCCESS";
export const ORDER_WORK_REPORT_LOG_ERROR = "ORDER_WORK_REPORT_LOG_ERROR";

export const CHECK_ORDER_WORK_ACTS_REQUEST = "CHECK_ORDER_WORK_ACTS_REQUEST";
export const CHECK_ORDER_WORK_ACTS_SUCCESS = "CHECK_ORDER_WORK_ACTS_SUCCESS";
export const CHECK_ORDER_WORK_ACTS_ERROR = "CHECK_ORDER_WORK_ACTS_ERROR";

export const BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_REQUEST = "BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_REQUEST";
export const BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_SUCCESS = "BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_SUCCESS";
export const BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_ERROR = "BFF_FINANCES_PAYMENTS_GET_BLOCKED_CONTRACTORS_ERROR";

export const BFF_FINANCES_RETRY_PAYMENT_REQUEST = "BFF_FINANCES_RETRY_PAYMENT_REQUEST";
export const BFF_FINANCES_RETRY_PAYMENT_SUCCESS = "BFF_FINANCES_RETRY_PAYMENT_SUCCESS";
export const BFF_FINANCES_RETRY_PAYMENT_ERROR = "BFF_FINANCES_RETRY_PAYMENT_ERROR";

export const UPDATE_FINANCES_FINANCES_STORE = "UPDATE_FINANCES_FINANCES_STORE";