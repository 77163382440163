import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getRichObjects} from "../../../objects";
import {getPageClientObjects} from "./actionCreators";
import {
    CLIENT_OBJECTS_ADD_DEPOSIT_ERROR,
    CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST,
    CLIENT_OBJECTS_ADD_DEPOSIT_SUCCESS,
    CLIENT_OBJECTS_ADD_ERROR,
    CLIENT_OBJECTS_ADD_REQUEST,
    CLIENT_OBJECTS_ADD_SUCCESS,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
    CLIENT_OBJECTS_CLOSE_ERROR,
    CLIENT_OBJECTS_CLOSE_REQUEST,
    CLIENT_OBJECTS_CLOSE_SUCCESS,
    CLIENT_OBJECTS_EXPORT_ERROR,
    CLIENT_OBJECTS_EXPORT_REQUEST,
    CLIENT_OBJECTS_EXPORT_SUCCESS,
    CLIENT_OBJECTS_GET_PAGE_ERROR,
    CLIENT_OBJECTS_GET_PAGE_REQUEST,
    CLIENT_OBJECTS_GET_PAGE_SUCCESS,
    CLIENT_OBJECTS_RETURN_DEPOSIT_ERROR,
    CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST,
    CLIENT_OBJECTS_RETURN_DEPOSIT_SUCCESS,
    CLIENT_OBJECTS_UPDATE_ERROR,
    CLIENT_OBJECTS_UPDATE_REQUEST,
    CLIENT_OBJECTS_UPDATE_STATUS_ERROR,
    CLIENT_OBJECTS_UPDATE_STATUS_REQUEST,
    CLIENT_OBJECTS_UPDATE_STATUS_SUCCESS,
    CLIENT_OBJECTS_UPDATE_SUCCESS,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS,
    IMPORT_OBJECT_DEPOSIT_ERROR,
    IMPORT_OBJECT_DEPOSIT_REQUEST,
    IMPORT_OBJECT_DEPOSIT_SUCCESS,
    OBJECTS_CHANGE_ARCHIVE_ERROR,
    OBJECTS_CHANGE_ARCHIVE_REQUEST,
    OBJECTS_CHANGE_ARCHIVE_SUCCESS,
    OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR,
    OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST,
    OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS,
} from "./actions";
import {getClientObjectCard} from "./card/actionCreators";
import {clientObjectsListPageDataSelector} from "./selectors";

import request, {getMultipartHeaders} from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {
    LINK_OBJECTS_CARD,
    LINK_PROJECTS_CARD,
    LINK_PROJECTS_LIST,
} from "../../../../constants/links";

export const getObjectsController = () => {
    return (
        getBffUrl({
            [LINK_OBJECTS_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/objects/object-card",
                client: "/client-adm/client/objects/object-card",
            }),
            [LINK_PROJECTS_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects",
                client: "/client-adm/client/projects",
            }),
            [LINK_PROJECTS_CARD]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects/project-card/objects",
                client: "/client-adm/client/projects/project-card/objects",
            }),
            isClientCard: true,
            clientRolesUrl: "/client-adm/client/objects",
            adminRolesUrl: "/adm/clients/client-card/client/objects",
        },
        "/adm/clients/client-card/client/objects",
        true,
        )
    );
};

const handleResponse = function* (data) {
    const {
        isFetchList,
        isFetchCard,
        projectId,
        objectId,
        clientId,
    } = data;

    if (isFetchList) {
        const state = yield select();
        const pageData = clientObjectsListPageDataSelector(state);

        yield put(getPageClientObjects(pageData));
    }

    if (isFetchCard) {
        yield put(getClientObjectCard({
            clientId,
            projectId,
            objectId,
        }));
    }
};

const getPageClientObjectsSaga = function* ({payload}) {
    try {
        const otherUrl = payload.projectId
            ? "/getOptimizedPage"
            : "/getRichPage";

        const result = yield request.bff.post(`${getObjectsController()}${otherUrl}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_GET_PAGE_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENT_OBJECTS_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_GET_PAGE_ERROR,
        });
    }
};

const addClientObjectsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            isFetchList = true,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/addObject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Объект успешно добавлен");

        yield handleResponse({isFetchList});

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_ADD_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/projects/project-card/objects/updateObject
const updateClientObjectSaga = function* ({payload}) {
    try {
        const {
            isGetList,
            isGetCard,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/updateObject`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_UPDATE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Объект успешно изменен");

        yield handleResponse({
            isFetchList: isGetList,
            isFetchCard: isGetCard,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_UPDATE_ERROR,
        });
    }
};

const updateClientObjectStatusSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/updateObjectStatus`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_UPDATE_STATUS_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Объект успешно изменен");

        yield handleResponse({
            isFetchList: true,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_UPDATE_STATUS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_UPDATE_STATUS_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/objects/add-object-deposit
// POST bff/adm/clients/client-card/client/objects/add-object-deposit
// Вернуть депозита объекта
const addClientObjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/add-object-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_ADD_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        yield handleResponse({
            isFetchList: true,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_ADD_DEPOSIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_ADD_DEPOSIT_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/client/objects/return-object-deposit
// POST bff/adm/clients/client-card/client/objects/return-object-deposit
// Вернуть депозита объекта
const returnClientObjectDepositSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/return-object-deposit`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_RETURN_DEPOSIT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Депозит объекта успешно изменен");

        yield handleResponse({
            isFetchList: true,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_RETURN_DEPOSIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_RETURN_DEPOSIT_ERROR,
        });
    }
};

const closeClientObjectSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/close`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_CLOSE_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Объект успешно закрыт");

        yield handleResponse({
            isFetchList: true,
            clientId: payload.clientId,
            projectId: payload.projectId,
            objectId: payload.objectId,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_CLOSE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_CLOSE_ERROR,
        });
    }
};

const importObjectDepositSaga = function* ({payload}) {
    try {
        const {
            clientId,
            formData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(
            `${getObjectsController()}/deposit/add-from-file/${clientId}`,
            formData,
            {...getMultipartHeaders()},
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({type: IMPORT_OBJECT_DEPOSIT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Загрузка файла успешно завершена. Депозит по заданным объектам будет пополнен через некоторое время");

        yield put({
            type: IMPORT_OBJECT_DEPOSIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: IMPORT_OBJECT_DEPOSIT_ERROR,
            payload: error,
        });
    }
};

// Раздел Компании → Проекты → Карточка проекта
// АЧ - /bff/adm/clients/client-card/client/projects/project-card/objects/ndfl/get-clean-history-page
// КЧ - /bff/client-adm/client/projects/project-card/objects/ndfl/get-clean-history-page
// Раздел Компании → Объекты
// АЧ - /bff/adm/clients/client-card/client/objects/ndfl/get-clean-history-page
// КЧ - bff/client-adm/client/objects/ndfl/get-clean-history-page
// История изменения депозита объекта
const getObjectsDepositHistoryPageSaga = function* ({payload}) {
    const {
        getResult,
        isNdfl,
        ...reqData
    } = payload;

    try {
        const type = isNdfl ? "ndfl" : "npd";
        const result = yield request.bff.post(`${getObjectsController()}/${type}/get-clean-history-page`, reqData);
        const {errorMessage} = result;

        if (getResult) {
            getResult();
        }

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        if (getResult) {
            getResult();
        }

        yield put({
            type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_ERROR,
            payload: error,
        });
    }
};


// Раздел Компании → Проекты → Карточка проекта
// POST - АЧ - /bff/adm/clients/client-card/client/projects/project-card/objects/change-orders-limit-for-objects
// POST - КЧ - /bff/client-adm/client/projects/project-card/change-orders-limit-for-objects
// Раздел Компании → Объекты
// POST - АЧ - /bff/adm/clients/client-card/client/objects/change-orders-limit-for-objects
// POST - КЧ - bff/client-adm/client/objects/change-orders-limit-for-objects
// Изменить лимит заказов на объекте
const changeObjectOrdersLimit = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/change-orders-limit-for-objects`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Операция успешно выполнена");

        yield handleResponse({
            isFetchList: true,
        });

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_ERROR,
        });
    }
};

const changeArchiveObjectSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/change-archived`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_CHANGE_ARCHIVE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();

        yield put(getPageClientObjects(state.bff.clientObjects.pageData));

        yield put({
            type: OBJECTS_CHANGE_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: OBJECTS_CHANGE_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

const exportClientObjectsSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const {errorMessage, error, ...result} = yield request.bff.post(`${getObjectsController()}/export`, reqData);

        if (errorMessage || error) {
            toastError(errorMessage || "Не удалось создать задачу");

            yield put({
                type: CLIENT_OBJECTS_EXPORT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CLIENT_OBJECTS_EXPORT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENT_OBJECTS_EXPORT_ERROR,
            payload: error,
        });
    }
};

//POST /bff/client-adm/client/projects/project-card/objects/multiple-change-archived
//POST /bff/adm/clients/client-card/client/projects/project-card/objects/multiple-change-archived
//POST /bff/client-adm/client/objects/multiple-change-archived
//POST /bff/adm/clients/client-card/client/objects/multiple-change-archived
const multipleChangeArchiveObjectSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getObjectsController()}/multiple-change-archived`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        const state = yield select();
        const pageData = clientObjectsListPageDataSelector(state);

        yield put(getPageClientObjects(pageData));

        yield put({
            type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENT_OBJECTS_GET_PAGE_REQUEST, getPageClientObjectsSaga),
        takeEvery(CLIENT_OBJECTS_ADD_REQUEST, addClientObjectsSaga),
        takeEvery(CLIENT_OBJECTS_UPDATE_REQUEST, updateClientObjectSaga),
        takeEvery(CLIENT_OBJECTS_UPDATE_STATUS_REQUEST, updateClientObjectStatusSaga),
        takeEvery(CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST, addClientObjectDepositSaga),
        takeEvery(CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST, returnClientObjectDepositSaga),
        takeEvery(CLIENT_OBJECTS_CLOSE_REQUEST, closeClientObjectSaga),
        takeEvery(IMPORT_OBJECT_DEPOSIT_REQUEST, importObjectDepositSaga),
        takeEvery(GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST, getObjectsDepositHistoryPageSaga),
        takeEvery(CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST, changeObjectOrdersLimit),
        takeEvery(OBJECTS_CHANGE_ARCHIVE_REQUEST, changeArchiveObjectSaga),
        takeEvery(CLIENT_OBJECTS_EXPORT_REQUEST, exportClientObjectsSaga),
        takeEvery(OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST, multipleChangeArchiveObjectSaga),
    ]);
}