import {
    CLIENT_DOCUMENTS_REGISTRY_CLEAR_STORE,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST,
    CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS,
    CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE,
    RECREATE_END_DOCUMENT_NOTIFICATION_ERROR,
    RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST,
    RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS,
    RECREATE_START_DOCUMENT_NOTIFICATION_ERROR,
    RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST,
    RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS,
    RESIDENT_VALIDATION_ERROR,
    RESIDENT_VALIDATION_REQUEST,
    RESIDENT_VALIDATION_SUCCESS,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST,
    SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS,
    TERMINATE_FRAME_CONTRACT_ERROR,
    TERMINATE_FRAME_CONTRACT_REQUEST,
    TERMINATE_FRAME_CONTRACT_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    progress: false,
    progressAction: false,
    searchProgress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_REQUEST:
            return {
                ...state,
                pageData: payload,
                progress: true,
            };
        case CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_SUCCESS:
            return {
                ...state,
                list: payload.documents,
                totalCount: payload.totalCount,
                progress: false,
            };
        case CLIENT_DOCUMENTS_REGISTRY_GET_PAGE_ERROR:
            return {
                ...state,
                progress: false,
            };
        case RECREATE_START_DOCUMENT_NOTIFICATION_REQUEST:
        case RECREATE_END_DOCUMENT_NOTIFICATION_REQUEST:
        case TERMINATE_FRAME_CONTRACT_REQUEST:
        case RESIDENT_VALIDATION_REQUEST:
            return {
                ...state,
                progressAction: true,
            };
        case RECREATE_START_DOCUMENT_NOTIFICATION_SUCCESS:
        case RECREATE_END_DOCUMENT_NOTIFICATION_SUCCESS:
        case TERMINATE_FRAME_CONTRACT_SUCCESS:
        case RESIDENT_VALIDATION_SUCCESS:
            return {
                ...state,
                progressAction: false,
            };
        case RECREATE_START_DOCUMENT_NOTIFICATION_ERROR:
        case RECREATE_END_DOCUMENT_NOTIFICATION_ERROR:
        case TERMINATE_FRAME_CONTRACT_ERROR:
        case RESIDENT_VALIDATION_ERROR:
            return {
                ...state,
                progressAction: false,
                error: payload,
            };
        case CLIENT_DOCUMENTS_REGISTRY_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_REQUEST:
            return {
                ...state,
                searchProgress: true,
            };
        case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_SUCCESS:
        case SEARCH_CONTRACTOR_BY_INN_OR_PHONE_ERROR:
            return {
                ...state,
                searchProgress: false,
            };
        case CLIENT_DOCUMENTS_REGISTRY_CLEAR_STORE: {
            return initial;
        }
        default: return state;
    }
};