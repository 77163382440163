import React, {useState} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import MassConfirm from "../../../components/MassConfirm";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import SelectionCountWithAction from "../../../components/SelectionCountWithAction";
import {
    bffRecruitmentVacancyListSelector,
    bffRecruitmentVacancyProgressActionSelector,
    bffRecruitmentVacancyProgressSelector,
    bffRecruitmentVacancyTotalCountSelector,
    bffRecruitmentVacancyTotalPageSelector,
} from "../../../ducks/bff/recruitment/vacancy/selectors";
import CloseVacancyJobBoards from "./components/close-job-boards";
import RecruitmentVacancyEditForm from "./components/edit-form";
import RecruitmentVacancyExportJobBoardsForm from "./components/export-form";
import RecruitmentVacancyImportForm from "./components/import-form";
import {RecruitmentProvideVacancyAccess} from "./components/provide-vacancy-access";
import VacancyLogs from "./components/vacancy-logs";

import {usePagination} from "../../../hooks/usePagination";
import {useSelectedList} from "../../../hooks/useSelectedList";
import useRecruitmentVacanciesAction, {OPEN_MODAL_TYPE} from "./hooks/useAction";
import useRecruitmentVacanciesFetch from "./hooks/useFetch";

import {isVisibleRecruitmentActions} from "../../../utils/access";
import bem from "../../../utils/bem";
import {formatLocalDate} from "../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {getVacancyTitle} from "./utils/getTitle";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES,
} from "../../../constants/links";
import {RECRUITMENT_VACANCY_STATUS} from "./constants";

const RecruitmentVacancies = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const list = useSelector(bffRecruitmentVacancyListSelector);
    const totalCount = useSelector(bffRecruitmentVacancyTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentVacancyTotalPageSelector);
    const progress = useSelector(bffRecruitmentVacancyProgressSelector);
    const progressAction = useSelector(bffRecruitmentVacancyProgressActionSelector);

    const [archived, setArchived] = useState(false);
    const [isOpenFilter, setOpenFilter] = useState(true);

    const isAccessAction = isVisibleRecruitmentActions();
    const isAccessManageVacancies = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessManageVacanciesAdmin,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessManageVacanciesClient,
    ]);
    const isAccessAddVacancies = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.addVacanciesAdmin,
        CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_addVacanciesClient,
    ]);

    const [block] = bem("recruitment-vacancies");

    const {
        pageNum,
        pageSize,
        paginationData,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("client-card");

    const {
        fetchList,
        isSearch,
        onClear,
        onSearch,
        filter,
        filterData,
    } = useRecruitmentVacanciesFetch({
        clientId,
        pageNum,
        pageSize,
        archived,
        setPagination,
    });

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        mediaControls,
        filters,
        massConfirmData,
        openModalType,
        openMassDeleteConfirm,
        onCloseModal,
        checkPublishPossible,
        onCloseMassConfirm,
        getListMediaControls,
        isOpenEdit,
        editData,
        onCloseEdit,
        confirmData,
        onCloseConfirm,
        isOpenLog,
        onCloseLog,
        logData,
        vacancyCloseActions,
        modals,
    } = useRecruitmentVacanciesAction({
        clientId,
        fetchList,
        setArchived,
        archived,
        selectedList,
        isAccessAction,
        clearSelectedRows,
        setPagination,
        filter,
        pageSize,
        isAccessAddVacancies,
    });

    const renderEditForm = () => {
        return (
            isOpenEdit &&
            <RecruitmentVacancyEditForm
                clientId={clientId}
                onClose={onCloseEdit}
                editData={editData}
                fetchData={fetchList}
            />
        );
    };

    const getRows = () => {
        return list.map((item) => {
            const {isSelected = false} = selectedList.find(_item => (_item.vacancyId === item.vacancyId)) || {};

            const {
                status,
                lastModifiedDate,
                vacancyNumber,
                title,
                totalResponses,
                totalCandidates,
                workAddressInfo,
            } = item;

            return {
                ...item,
                key: item.vacancyId,
                showCheckBox: isAccessAction && !archived,
                disabledCheckBox: status !== RECRUITMENT_VACANCY_STATUS.DRAFT.VALUE,
                isSelected,
                contentRow: (
                    <NmListCard
                        filterData={filterData}
                        paginationData={paginationData}
                        className="fluid"
                        secondaryHeaderStatus={
                            <NmBadge
                                text={RECRUITMENT_VACANCY_STATUS[status]?.TEXT || status}
                                mod={RECRUITMENT_VACANCY_STATUS[status]?.MOD || "gray"}
                            />
                        }
                        checkbox={true}
                        isVisibleEmptySecondaryHeader={true}
                        primaryHeader={getVacancyTitle({
                            vacancyNumber,
                            title,
                            workAddressInfo,
                        })}
                        primaryHeaderLink
                        link={
                            LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
                                .replace(":clientId", clientId)
                                .replace(":vacancyId", item.vacancyId)
                        }
                        noDivider
                        classNameMainContent="col-16 col-xxl-6"
                        cardsWithContainer={true}
                        cardsContainerClassName="col-16 col-xxl-9"
                        cards={[
                            {
                                title: "Неразобранных откликов",
                                value: totalResponses,
                                className: `col-16 col-md-6 ${isOpenFilter ? "col-xxl-5" : "col-xxl-4"} mt-md-4 mt-xxl-0`,
                            },
                            {
                                title: "Кандидатов на вакансию",
                                value: totalCandidates,
                                className: `col-16 col-md-5 ${isOpenFilter ? "col-xxl-5" : "col-xxl-4"} mt-md-4 mt-xxl-0`,
                            },
                            {
                                title: "Последние действия",
                                columnOnMobile: true,
                                value: lastModifiedDate
                                    ? formatLocalDate(lastModifiedDate, "dd.MM.yyyy HH:mm")
                                    : "-",
                                className: `col-16 col-md-5 ${isOpenFilter ? "col-xxl-5" : "col-xxl-4"} mt-md-4 mt-xxl-0`,
                            },
                        ]}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={getListMediaControls({
                            ...item,
                            archived,
                        })}
                    />
                ),
            };
        });
    };

    const renderLogModal = () => {
        return (
            isOpenLog &&
            <VacancyLogs
                vacancy={logData}
                onClose={onCloseLog}
            />
        );
    };

    const renderConfirmWindow = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                confirmButton="Подтвердить"
                cancelButton="Отмена"
                onConfirm={onConfirm}
                onCancel={onCloseConfirm}
            />
        );
    };

    const renderMassConfirm = () => {
        return (
            !isEmpty(massConfirmData) &&
            <MassConfirm
                {...massConfirmData}
                onCancel={onCloseMassConfirm}
                cancelButton="Отменить"
                disabled={progressAction}
            />
        );
    };

    const renderModalWindow = () => {
        if (openModalType === OPEN_MODAL_TYPE.IMPORT) {
            return (
                <RecruitmentVacancyImportForm
                    clientId={clientId}
                    onClose={onCloseModal}
                />
            );
        }

        if (openModalType === OPEN_MODAL_TYPE.EXPORT) {
            return (
                <RecruitmentVacancyExportJobBoardsForm
                    clientId={clientId}
                    onClose={onCloseModal}
                />
            );
        }

        return null;
    };

    const getContent = () => {
        if (!list.length) {
            return (
                <NmEmptyPageV2
                    isSearch={isSearch}
                    title="Список вакансий пуст"
                />
            );
        }

        return (
            <CheckboxList
                header={
                    isAccessAction && !archived &&
                    <SelectionCountWithAction
                        adaptiveLogic={true}
                        isHiddenMainButton={!isAccessAddVacancies}
                        count={countSelected}
                        buttonColor="green"
                        buttonContent="Перевести в работу"
                        onClick={checkPublishPossible}
                        otherActions={
                            isAccessManageVacancies &&
                            <NmButton
                                disabled={countSelected === 0}
                                size="lg"
                                color="grey"
                                onClick={openMassDeleteConfirm}
                            >
                                Удалить черновики
                            </NmButton>
                        }
                    />
                }
                rows={getRows()}
                isShowMassActions={isAccessAction && !archived}
                onSelectedRows={handleSelectedRows}
                checkBoxClassName="me-2"
            />
        );
    };

    const renderVacancyClose = () => {
        if (vacancyCloseActions.isOpenJobBoards) {
            return (
                <CloseVacancyJobBoards
                    onClose={vacancyCloseActions.onCloseModal}
                    onSubmit={(jobBoardTypes) => {
                        vacancyCloseActions.closeVacancy({
                            vacancy: vacancyCloseActions.modalData.vacancy,
                            jobBoardTypes,
                        });
                    }}
                />
            );
        }
    };

    const renderVacancyAccess = () => {
        if (!modals.modalData?.isOpenAccessProvide) {
            return null;
        }

        return (
            <RecruitmentProvideVacancyAccess
                clientId={clientId}
                vacancyId={modals.modalData.vacancyId}
                onClose={modals.onCloseModal}
            />
        );
    };

    return (
        <NmPage
            className={block()}
            header={
                <NmTitle
                    count={totalCount}
                    size="xl"
                >
                    Вакансии
                </NmTitle>
            }
            mediaControls={mediaControls}
            typeFilter="vertical"
            openFilter={isOpenFilter}
            widthByFilter={true}
            onOpenFilter={setOpenFilter}
            filtersBase={
                <Filter
                    initState={filterData}
                    clearFilter={onClear}
                    onSubmit={onSearch}
                    filters={filters}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            isLoaded={progress}
        >
            {renderVacancyAccess()}
            {renderVacancyClose()}
            {renderConfirmWindow()}
            {renderLogModal()}
            {renderModalWindow()}
            {renderEditForm()}
            {renderMassConfirm()}
            {getContent()}
        </NmPage>
    );
};

export default RecruitmentVacancies;