import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {InstructionsPage} from "../../../../components/Instructions/components/page";

import {USER_KEDO_INSTRUCTION_TYPE_DICT} from "../../../../constants/dicts";

import {
    addKedoInstructionFile,
    getKedoInstructions,
    saveKedoInstruction,
    updateKedoInstructionStore,
} from "../../../../ducks/bff/settings/instructions/kedo/actionCreators";
import {getSettingsInstructionsKedoInitialState} from "../../../../ducks/bff/settings/instructions/kedo/reducer";
import {settingsInstructionsInfoGroupSelector} from "../../../../ducks/bff/settings/instructions/kedo/selectors";
import {downloadDocument} from "../../../../ducks/documents";

export const InstructionsKedo = () => {
    const dispatch = useDispatch();

    const instructionInfoGroup = useSelector(settingsInstructionsInfoGroupSelector);

    useEffect(() => {
        return () => {
            const initialState = getSettingsInstructionsKedoInitialState();

            dispatch(updateKedoInstructionStore(initialState));
        };
    }, []);

    const fetchList = () => {
        dispatch(getKedoInstructions());
    };

    const getFile = ({downloadLink, filename: fileName}) => {
        dispatch(downloadDocument({
            isDownload: true,
            downloadLink,
            fileName,
        }));
    };

    const addFile = (data) => {
        const {
            instructionType,
            file,
            info,
        } = data;

        dispatch(addKedoInstructionFile({
            instructionType,
            file,
            info,
        }));
    };

    const save = (info) => {
        dispatch(saveKedoInstruction(info));
    };

    return (
        <InstructionsPage
            fetchList={fetchList}
            instructionInfoGroup={instructionInfoGroup}
            getFile={getFile}
            addFile={addFile}
            save={save}
            instructionTypeDict={USER_KEDO_INSTRUCTION_TYPE_DICT}
            isKedo={true}
        />
    );
};