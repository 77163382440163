import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {BaseDirectoryPage} from "../../../../components/BasicDirectory";
import {getSettingsDirectoriesAccessActions} from "../../../settings/directories/helpers/getAccessActions";
import {KedoDirectoriesTabs} from "../tabs";

import {useKedoDirectoryFetchCount} from "../hooks/useFetchCount";
import {useKedoDirectoriesSort} from "../hooks/useSort";
import {useKedoSubdivisionsBff} from "./hooks/useBff";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {
    ADMIN,
    CLIENT_ADMIN,
    isUserFromNm, 
    NM_MANAGER,
} from "../../../../constants/roles";

import {updateKedoDirectoriesStore} from "../../../../ducks/kedo/directories/actionCreators";
import {
    kedoDirectoriesProgressActionSelector,
    kedoDirectoriesSubdivisionsSelector,
} from "../../../../ducks/kedo/directories/selectors";

export const KedoSubdivisions = (props) => {
    const clientId = props.match.params.clientId;

    const [isSearch, setIsSearch] = useState(false);

    const {
        fetchCounts,
    } = useKedoDirectoryFetchCount({
        clientId,
    });

    const {
        onClickSort,
        sortType,
        sortOptions,
    } = useKedoDirectoriesSort();

    const {
        totalPages,
        totalCount,
        list,
        progress,
    } = useSelector(kedoDirectoriesSubdivisionsSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const dispatch = useDispatch();

    const restriction = isUserFromNm()
        ? CLIENT_USER_RESTRICTIONS_VARIABLE.actionsSubdivisionsKedo
        : CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsSubdivisionsKedo;
    const isAccessEdit = getSettingsDirectoriesAccessActions({
        roles: [ADMIN, NM_MANAGER, CLIENT_ADMIN],
        restriction,
    });

    const {
        deleteSubdivision,
        addSubdivision,
        fetchSubdivisionsPage,
        updateSubdivision,
    } = useKedoSubdivisionsBff({
        clientId,
        fetchCounts,
        sortType,
    });

    useEffect(() => {
        return () => {
            dispatch(updateKedoDirectoriesStore({
                subdivisions: {},
            }));
        };
    }, []);

    return (
        <>
            <KedoDirectoriesTabs
                clientId={clientId}
                isSearch={isSearch}
                subdivisionsCount={totalCount}
                {...props}
            />
            <BaseDirectoryPage
                sort={true}
                isClientField={!clientId}
                sortType={sortType}
                sortOptions={sortOptions}
                onClickSort={onClickSort}
                title="Структурные подразделения"
                setIsSearch={setIsSearch}
                isEditable={isAccessEdit}
                isNeedClosingOnSubmit={false}
                isLoaded={progress}
                progressAction={progressAction}
                totalPages={totalPages}
                totalCount={totalCount}
                list={list}
                onAdd={addSubdivision}
                onDelete={deleteSubdivision}
                getItems={fetchSubdivisionsPage}
                onEdit={updateSubdivision}
                inputTypeLabel="Структурное подразделение"
                inputTypeMaxLength={255}
                addButtonText="Добавить подразделение"
                popupTitleAdd="Добавление структурного подразделения"
                popupTitleEdit="Редактирование структурного подразделения"
                popupTitleDelete="Вы действительно хотите удалить данное подразделение из справочника?"
                labelFilter="Структурное подразделение"
                placeholderFilter="Введите наименование"
                inputTypePlaceholder="Введите подразделение"
            />
        </>
    );
};