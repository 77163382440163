import {
    GET_ALL_APPLICATIONS_ERROR,
    GET_ALL_APPLICATIONS_REQUEST,
    GET_ALL_APPLICATIONS_SUCCESS,
} from "./actions";

const initial = {
    pageData: {},
    list: [],
    totalCount: 0,
    applicationCountMap: {},
    progress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_ALL_APPLICATIONS_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case GET_ALL_APPLICATIONS_SUCCESS: {
            const {
                contractors,
                totalCount,
                applicationCountMap,
            } = payload;

            return {
                ...state,
                list: contractors,
                totalCount,
                applicationCountMap,
                progress: false,
            };
        }
        case GET_ALL_APPLICATIONS_ERROR:
            return {
                ...state,
                progress: false,
            };
        default:
            return state;
    }
};