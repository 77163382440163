import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_ACT_REGISTRIES_ADD_ERROR,
    BFF_ACT_REGISTRIES_ADD_REQUEST,
    BFF_ACT_REGISTRIES_ADD_SUCCESS,
    BFF_ACT_REGISTRIES_ARCHIVE_ERROR,
    BFF_ACT_REGISTRIES_ARCHIVE_REQUEST,
    BFF_ACT_REGISTRIES_ARCHIVE_SUCCESS,
    BFF_ACT_REGISTRIES_DELETE_ERROR,
    BFF_ACT_REGISTRIES_DELETE_REQUEST,
    BFF_ACT_REGISTRIES_DELETE_SUCCESS,
    BFF_ACT_REGISTRIES_GET_BY_ID_ERROR,
    BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS,
    BFF_ACT_REGISTRIES_GET_PAGE_ERROR,
    BFF_ACT_REGISTRIES_GET_PAGE_REQUEST,
    BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS,
    BFF_ACT_REGISTRIES_UPDATE_ERROR,
    BFF_ACT_REGISTRIES_UPDATE_REQUEST,
    BFF_ACT_REGISTRIES_UPDATE_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/act-registry",
        client: "/client-adm/orders/act-registry",
    });
};

// POST /bff/adm/clients/client-card/orders/act-registry/page
// POST /bff/client-adm/orders/act-registry/page
// Получение списка реестров актов
const getActRegistriesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/page`, payload);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/{clientId}/{registryId}/archived/{archived}
// POST /bff/client-adm/orders/act-registry/{clientId}/{registryId}/archived/{archived}
// Добавление в архив / исключение из архива
const archiveActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            archived,
            registryId,
        } = payload;

        const result = yield request.bff.post(`${getController()}/${clientId}/${registryId}/archived/${archived}`);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_ARCHIVE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_ACT_REGISTRIES_ARCHIVE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_ARCHIVE_ERROR,
        });
    }
};

// DELETE /bff/adm/clients/client-card/orders/act-registry/delete
// DELETE /bff/client-adm/orders/act-registry/delete
const deleteActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...params
        } = payload;

        const result = yield request.bff.delete(`${getController()}/delete`, {params});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_DELETE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_ACT_REGISTRIES_DELETE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_DELETE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/update
// POST /bff/client-adm/orders/act-registry/update
const updateActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_UPDATE_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_ACT_REGISTRIES_UPDATE_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_UPDATE_ERROR,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/act-registry/add
// POST /bff/client-adm/orders/act-registry/add
const addActRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, reqData);

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_ADD_ERROR,
            });

            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_ACT_REGISTRIES_ADD_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_ADD_ERROR,
        });
    }
};

// GET /bff/adm/clients/client-card/orders/act-registry/get-by-id
// GET /bff/client-adm/orders/act-registry/get-by-id
const getActRegistrySaga = function* ({payload: params}) {
    try {
        const result = yield request.bff.get(`${getController()}/get-by-id`, {params});

        if (result.errorMessage) {
            toastError(result.errorMessage);

            yield put({
                type: BFF_ACT_REGISTRIES_GET_BY_ID_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ACT_REGISTRIES_GET_BY_ID_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_ACT_REGISTRIES_GET_PAGE_REQUEST, getActRegistriesSaga),
        takeEvery(BFF_ACT_REGISTRIES_ARCHIVE_REQUEST, archiveActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_DELETE_REQUEST, deleteActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_UPDATE_REQUEST, updateActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_ADD_REQUEST, addActRegistrySaga),
        takeEvery(BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST, getActRegistrySaga),
    ]);
}
