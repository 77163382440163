import {getPageCrowdTasks} from "../../../../crowd/tasks/actionCreators";

import {getBffControllerClientCardPage} from "../../../../../../utils/url";

export const getPageClientFinancesDepositTasks = (payload) => {
    const controller = getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/finances/npd-deposit/spent-limit/task",
        client: "/client-adm/finances/npd-deposit/spent-limit/task",
    });
    
    return getPageCrowdTasks({
        url: `${controller}/page`,
        ...payload,
    });
};