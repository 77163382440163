import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import ImportFromFilePatternV2 from "../../../components/ActualComponents/ImportFromFilePatternV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../components/ActualComponents/NmHorizontalToggleV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import ButtonArchive from "../../../components/ButtonArchive";
import CheckboxList from "../../../components/CheckboxList";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import ExtLink from "../../../components/ExtLink";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage, {FILTER_OPTION} from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import TaskLoadingStatus from "../../../components/TaskLoadingStatus";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import ObjectsEdit from "../../objects/components/edit";
import ProjectAddDeposit from "../add-deposit";
import AddProjectsObjectsDeposit from "../add-projects-objects-deposit";
import ChangeProjectOrdersLimit from "../change-orders-limit";
import ChangeProjectObjectsLimit from "../change-project-objects-limit";
import {ProjectDepositHistory} from "../deposit-history";
import ProjectEditModal from "../projects-edit-modal";
import ProjectsFilter from "../projects-filter";
import ProjectReturnDeposit from "../return-deposit";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmountWithNullChecking, formatNumberWithComma} from "../../../utils/stringFormat";
import {handleFormString} from "../../../utils/stringHelper";
import {filterContextMenuByPropsVisibleRules} from "../../finance/finance-payment-list/utils/filterByPropsVisibleRules";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {
    GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
    SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS,
} from "../../../constants/deposit";
import {headersProjectsForemanList, headersProjectsList} from "../../../constants/headersTable";
import {LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST} from "../../../constants/links";
import {
    PROJECT_ACTION_DICT,
    PROJECT_FIELD_NAME,
    PROJECT_STATUS_DICT,
} from "../../../constants/projects";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RECRUITER,
} from "../../../constants/roles";

import {
    clientCardInfoSelector,
    clientCardPropertiesSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {clientObjectsProgressActionSelector} from "../../../ducks/bff/clients/objects/selectors";
import {
    changeClientProjectArchived,
    changeClientProjectStatus,
    getPageClientProjects,
    importClientProjects,
} from "../../../ducks/bff/clients/projects/actionCreators";
import {
    clientProjectsListSelector,
    clientProjectsProgressSelector,
    clientProjectsTotalCountSelector,
    clientProjectsTotalPagesSelector,
} from "../../../ducks/bff/clients/projects/selectors";
import {
    getHideDepositDetails,
    hideDepositDetailsProgressSelector,
    setHideDepositDetails,
} from "../../../ducks/clientMember";
import {
    projectProgressSelector,
    updateFieldsStoreProject,
} from "../../../ducks/projects";

import PropTypes from "prop-types";

import "./style.sass";

const HEADER_OPTIONS = {
    IMPORT_DEPOSIT: {
        key: "IMPORT_DEPOSIT",
        value: "IMPORT_DEPOSIT",
        text: "Пополнить депозит проектов и объектов из файла",
    },
    IMPORT_PROJECTS: {
        key: "IMPORT_PROJECTS",
        value: "IMPORT_PROJECTS",
        text: "Загрузить список",
    },
    HIDE_DETAILS: {
        value: "HIDE_DETAILS",
    },
    ARCHIVE: {
        value: "ARCHIVE",
    },
    CHANGE_PROJECTS_AND_OBJECTS_ORDERS_LIMIT: {
        key: "CHANGE_PROJECTS_AND_OBJECTS_ORDERS_LIMIT",
        value: "CHANGE_PROJECTS_AND_OBJECTS_ORDERS_LIMIT",
        text: "Изменить лимит заказов по проектам и объектам",
    },
};

class ProjectsList extends Component {
    static propTypes = {
        totalPages: PropTypes.number,
    };

    static defaultProps = {
        totalPages: 0,
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                },
            },
        } = props;

        this.role = ls(USER_ROLE);

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filter: {},
            projectData: {},
            func: () => {
            },
            projectIdEdit: undefined,
            archived: false,
            status: undefined,
            sortType: "desc",
            sortName: "datetime",
            headers: this.role === FOREMAN ? headersProjectsForemanList : headersProjectsList,
            isSearch: false,
            hideDetails: false,
            importModalData: {},
        };

        this.clientUserId = ls(CURRENT_CLIENT_USER_ID);
        this.clientId = clientId;
    }

    get localizationData() {
        const {t} = this.props;

        return {
            title: t("projects.list-title"),
            createBtn: t("projects.create-btn"),
            importBtn: t("button.upload-list"),
            notSet: t("projects.not-set"),
        };
    }

    get isAccessActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientProjects,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
        ]);
    }

    get isAccessEdit() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsProjectsEdit,
        ]);
    }

    get isAccessAdd() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsProjectsAdd,
        ]);
    }

    get isAccessImport() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;
        const {archived} = this.state;

        return (
            !archived
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_loadProjectsAndObjects,
            ])
            && !isClientArchived
            && [
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                NM_MANAGER,
                CLIENT_ADMIN,
                ADMIN,
                CLIENT_ACCOUNTANT,
            ].includes(this.role)
        );
    }

    componentDidMount() {
        const {
            getHideDepositDetails,
        } = this.props;
        this.fetchList();

        getHideDepositDetails({
            urls: GET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.LIST_PROJECTS,
            getResult: (hideDetails) => {
                this.setState({
                    hideDetails,
                });
            },
        });
    }

    componentWillUnmount() {
        const {updateFieldsStoreProject} = this.props;

        updateFieldsStoreProject({
            projectsList: [],
        });
    }

    fetchList = () => {
        const {getPageClientProjects} = this.props;

        const {
            pageNum,
            pageSize,
            archived,
            filter: {
                name,
                status,
                objectNameFilter,
            },
            sortType,
            sortName,
        } = this.state;

        getPageClientProjects({
            pageNum,
            pageSize,
            clientId: this.clientId,
            archived,
            clientUserId: [PROJECT_MANAGER, OBJECT_MANAGER].includes(this.role) ? this.clientUserId : undefined,
            status: archived ? PROJECT_STATUS_DICT.CLOSE.VALUE : status,
            nameSubstringFilter: handleFormString(name),
            objectNameFilter: handleFormString(objectNameFilter),
            sortType,
            sortName,
        });
    };

    changePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    openEditModal = (projectData = {}) => {
        this.setState({
            isOpenEditModal: true,
            projectData: {
                ...projectData,
                [PROJECT_FIELD_NAME.CLIENT_ID]: this.clientId,
            },
        });
    };

    openImportProjectsModal = () => {
        const {clientProperties} = this.props;

        this.setState({
            importModalData: {
                patternLink: clientProperties.enableIndividualProjectAndObjectDeposit
                    ? "/files/Шаблон_Проекты_Объекты_С_Индивид_Депозитом.xlsx"
                    : "/files/Шаблон_Проекты_Объекты.xlsx",
                onSubmit: this.submitImportProjectForm,
            },
        });
    };

    closeImportFileModal = () => {
        this.setState({
            importModalData: {},
        });
    };

    closeEditModal = () => {
        const {
            updateFieldsStoreProject,
        } = this.props;

        updateFieldsStoreProject({project: {}});

        this.setState({
            isOpenEditModal: false,
            projectData: {},
        });
    };

    changePageNum = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState({
            formFilter: {},
        }, this.fetchList);
    };

    handleChangeSearch = (sortName, sortType) => {
        if (sortType === "asc" || sortType === "desc") {
            this.setState(prevState => {
                const headers = [...prevState.headers];

                const index = headers.findIndex(h => h.key === sortName);
                const header = {
                    ...headers[index],
                    typeSort: sortType,
                };

                headers.splice(index, 1, header);

                return {
                    ...prevState,
                    sortType,
                    headers,
                    sortName,
                };
            },
            () => setTimeout(this.fetchList, 500),
            );
        }
    };

    updateHideDepositDetails = () => {
        const {setHideDepositDetails} = this.props;
        const {hideDetails} = this.state;

        setHideDepositDetails({
            urls: SET_HIDE_DEPOSIT_DETAILS_PAGE_URLS.LIST_PROJECTS,
            value: hideDetails,
        });
    };

    handleChangeHideDetails = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                hideDetails: !prevState.hideDetails,
            };
        }, this.updateHideDepositDetails);
    };

    showConfirmWindow = (data) => {
        const {
            func,
            confirmText,
            projectId: projectIdEdit,
            status,
            archivedEdit,
            ...otherData
        } = data;

        this.setState({
            openConfirmWindow: true,
            func,
            confirmText,
            projectIdEdit,
            status,
            archivedEdit,
            ...otherData,
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            openConfirmWindow: false,
            confirmText: "",
            func: () => {
            },
            projectIdEdit: "",
            confirmTitle: "",
            confirmWarning: false,
            status: undefined,
        });
    };

    changeProjectStatus = () => {
        const {changeClientProjectStatus} = this.props;
        const {
            status,
            projectIdEdit: projectId,
        } = this.state;

        changeClientProjectStatus({
            clientId: this.clientId,
            status,
            projectId,
        });

        this.handleCancelConfirm();
    };

    changeProjectArchived = () => {
        const {changeClientProjectArchived} = this.props;
        const {
            projectIdEdit: projectId,
            archivedEdit: archived,
        } = this.state;

        changeClientProjectArchived({
            clientId: this.clientId,
            archived,
            projectId,
        });

        this.handleCancelConfirm();
    };

    addObjectModalWindow = ({open, item}) => {
        return () => {
            this.setState({
                isOpenAddObjectWindow: open,
                item,
            });
        };
    };

    submitImportProjectForm = ({file}) => {
        const {importClientProjects} = this.props;

        const formData = new FormData();
        formData.append("file", file);

        importClientProjects({
            clientId: this.clientId,
            formData,
            onSuccess: this.closeImportFileModal,
        });
    };

    onClickActionItem = (option, item) => {
        const {
            t,
        } = this.props;
        const {value: action} = option;

        switch (action) {
            case PROJECT_ACTION_DICT.CHANGE_ORDERS_LIMIT.VALUE: {
                this.setState({
                    isOpenChangeOrdersLimit: true,
                    item,
                });

                break;
            }
            case PROJECT_ACTION_DICT.ADD_DEPOSIT.VALUE: {
                this.setState({
                    isOpenAddDeposit: true,
                    item,
                });

                break;
            }
            case PROJECT_ACTION_DICT.RETURN_DEPOSIT.VALUE: {
                this.setState({
                    isOpenReturnDeposit: true,
                    item,
                });

                break;
            }
            case PROJECT_ACTION_DICT.DEPOSIT_HISTORY.VALUE: {
                this.setState({
                    isOpenDepositHistory: true,
                    item,
                });

                break;
            }
            case PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE: {
                this.showConfirmWindow({
                    status: PROJECT_STATUS_DICT.IN_WORK.VALUE,
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.OPEN_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    func: this.changeProjectStatus,
                });
                break;
            }
            case PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE: {
                this.openEditModal({
                    projectId: item.projectId,
                });
                break;
            }
            case PROJECT_ACTION_DICT.ADD_OBJECT.VALUE: {
                this.addObjectModalWindow({
                    open: true,
                    item,
                })();
                break;
            }
            case PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.ARCHIVE_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    func: this.changeProjectArchived,
                    archivedEdit: !item.archived,
                });
                break;
            }
            case PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    openConfirmWindow: true,
                    confirmText: t(PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.CONFIRM_KEY, {name: item.name}),
                    projectId: item.projectId,
                    archivedEdit: !item.archived,
                    func: this.changeProjectArchived,
                });
                break;
            }
            case PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE: {
                this.showConfirmWindow({
                    status: PROJECT_STATUS_DICT.CLOSE.VALUE,
                    openConfirmWindow: true,
                    confirmWarning: true,
                    confirmText: t(
                        item.enableIndividualDeposit
                            ? "projects.close-individual-deposit-confirm-text"
                            : "projects.close-confirm-text",
                    ),
                    confirmTitle: `Вы действительно хотите закрыть проект "${item.name}"?`,
                    projectId: item.projectId,
                    func: this.changeProjectStatus,
                });

                break;
            }
            default:
                break;
        }
    };

    renderAddObjectWindow() {
        const {
            isOpenAddObjectWindow,
            item,
        } = this.state;

        if (isOpenAddObjectWindow) {
            return (
                <ObjectsEdit
                    handleCancel={this.addObjectModalWindow({open: false, projectId: null, item: null})}
                    selectedClientId={this.clientId}
                    projectData={item}
                    fetchList={this.fetchList}
                    isFetchObjectList={false}
                />
            );
        }

        return null;
    }

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            confirmText,
            confirmTitle,
            confirmWarning,
            func,
        } = this.state;

        return (
            openConfirmWindow &&
            <NmConfirmV2
                onCancel={this.handleCancelConfirm}
                onConfirm={func}
                confirmButton="Потвердить"
                cancelButton="Отменить"
                title={confirmTitle}
                content={confirmText}
                warning={confirmWarning}
                size="md"
            />
        );
    }

    getOptions = (item) => {
        const {
            archived,
        } = this.state;

        const addObjectAction = {
            key: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
            value: PROJECT_ACTION_DICT.ADD_OBJECT.VALUE,
            text: PROJECT_ACTION_DICT.ADD_OBJECT.TEXT,
            visible: PROJECT_STATUS_DICT.IN_WORK.VALUE === item.status
                && !archived
                && this.isAccessActions,
        };

        if ([OBJECT_MANAGER, PROJECT_MANAGER].includes(this.role)) {
            return [addObjectAction];
        }

        const {clientProperties} = this.props;

        const isVisibleAddDeposit = !archived
            && !!item.enableIndividualDeposit
            && [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                CLIENT_ACCOUNTANT,
            ].includes(this.role);
        const isAccessArchiveAction = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsClientProjects,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
        ]);

        return [
            addObjectAction,
            {
                key: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
                value: PROJECT_ACTION_DICT.EDIT_PROJECT.VALUE,
                text: PROJECT_ACTION_DICT.EDIT_PROJECT.TEXT,
                visible: !archived
                    && !item.closeProjectTaskProcessing
                    && !item.changeProjectDepositTaskProcessing
                    && this.isAccessActions
                    && this.isAccessEdit,
            },
            {
                key: PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE,
                value: PROJECT_ACTION_DICT.OPEN_PROJECT.VALUE,
                text: PROJECT_ACTION_DICT.OPEN_PROJECT.TEXT,
                visible: PROJECT_STATUS_DICT.CLOSE.VALUE === item.status
                    && !archived
                    && this.isAccessActions
                    && this.isAccessEdit,
            },
            {
                key: PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE,
                value: PROJECT_ACTION_DICT.CLOSE_PROJECT.VALUE,
                text: PROJECT_ACTION_DICT.CLOSE_PROJECT.TEXT,
                visible: PROJECT_STATUS_DICT.IN_WORK.VALUE === item.status
                    && !item.closeProjectTaskProcessing
                    && !item.changeProjectDepositTaskProcessing
                    && !archived
                    && this.isAccessActions
                    && this.isAccessEdit,
            },
            {
                key: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE,
                value: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.VALUE,
                text: PROJECT_ACTION_DICT.ARCHIVE_PROJECT.TEXT,
                visible: PROJECT_STATUS_DICT.CLOSE.VALUE === item.status
                    && !archived
                    && isAccessArchiveAction,
            },
            {
                key: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE,
                value: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.VALUE,
                text: PROJECT_ACTION_DICT.FROM_ARCHIVE_PROJECT.TEXT,
                visible: archived
                    && isAccessArchiveAction,
            },
            {
                key: PROJECT_ACTION_DICT.ADD_DEPOSIT.VALUE,
                value: PROJECT_ACTION_DICT.ADD_DEPOSIT.VALUE,
                text: PROJECT_ACTION_DICT.ADD_DEPOSIT.TEXT,
                visible: isVisibleAddDeposit,
            },
            {
                key: PROJECT_ACTION_DICT.RETURN_DEPOSIT.VALUE,
                value: PROJECT_ACTION_DICT.RETURN_DEPOSIT.VALUE,
                text: PROJECT_ACTION_DICT.RETURN_DEPOSIT.TEXT,
                visible: isVisibleAddDeposit,
            },
            {
                key: PROJECT_ACTION_DICT.DEPOSIT_HISTORY.VALUE,
                value: PROJECT_ACTION_DICT.DEPOSIT_HISTORY.VALUE,
                text: PROJECT_ACTION_DICT.DEPOSIT_HISTORY.TEXT,
                visible: !archived && !!item.enableIndividualDeposit,
            },
            {
                key: PROJECT_ACTION_DICT.CHANGE_ORDERS_LIMIT.VALUE,
                value: PROJECT_ACTION_DICT.CHANGE_ORDERS_LIMIT.VALUE,
                text: PROJECT_ACTION_DICT.CHANGE_ORDERS_LIMIT.TEXT,
                visible: !archived
                    && !!item.enableIndividualDeposit
                    && (
                        clientProperties.ordersLimit > 0
                        || clientProperties.civilOrdersLimit > 0
                    ),
            },
        ];
    };

    renderAction = (item) => {
        const {role} = this;

        if ([FOREMAN, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return null;
        }

        const options = filterContextMenuByPropsVisibleRules(this.getOptions(item));

        if (!options.length) {
            return null;
        }

        return (
            <div className="fluid flex flex-content-end">
                <ContextMenu
                    options={options}
                    onClickItem={(option) => {
                        this.onClickActionItem(option, item);
                    }}
                />
            </div>
        );
    };

    renderName(item) {
        const to = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":clientId", item.clientId)
            .replace(":projectId", item.projectId);

        return (
            <ExtLink
                historyEnabled
                to={to}
            >
                {item.name}
            </ExtLink>
        );
    }

    renderStatus = status => {
        return (
            <NmBadge
                text={PROJECT_STATUS_DICT[status]?.TEXT}
                mod={PROJECT_STATUS_DICT[status]?.MOD || "gray"}
            />
        );
    };

    getRows() {
        const {
            projectsList,
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        const isHideActions = isClientArchived || [NM_OPERATOR, RECRUITER].includes(this.role);

        return projectsList.map(item => {
            const {
                status,
                smzDepositAmount,
                civilDepositAmount,
                objectCount,
                enableNonDistributedClientDeposit,
                nonDistributedDepositAmount,
                enableIndividualDeposit,
                closeProjectTaskProcessing,
                changeProjectDepositTaskProcessing,
            } = item;

            const nonDistributedDepositCards = enableNonDistributedClientDeposit
                ? [
                    {
                        title: "Нераспределенный депозит, ₽",
                        value: formatNumberWithComma(nonDistributedDepositAmount),
                        className: "col-16 col-md-8 col-xl-3 col-xxl-3",
                    },
                ]
                : [];

            const depositInfoCards = ![FOREMAN].includes(this.role) ? [
                ...nonDistributedDepositCards,
                {
                    title: "Депозит НПД, ₽",
                    value: enableIndividualDeposit ? formatAmountWithNullChecking(smzDepositAmount) : "Не установлен",
                    className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                },
                {
                    title: "Депозит НДФЛ, ₽",
                    value: enableIndividualDeposit ? formatAmountWithNullChecking(civilDepositAmount) : "Не установлен",
                    className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                },
            ] : [];

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-9"
                        primaryHeader={this.renderName(item)}
                        primaryHeaderNoWrap
                        secondaryHeaderStatus={this.renderStatus(status)}
                        cards={[
                            ...depositInfoCards,
                            {
                                title: "Количество объектов",
                                value: objectCount,
                                className: "col-16 col-md-5 col-xxl-2 mt-md-4 mt-xxl-0",
                            },
                        ]}
                        secondaryHeaderNoReverse={true}
                        isHideSecondaryHeaderValue={true}
                        secondaryHeaderLoading={
                            (closeProjectTaskProcessing || changeProjectDepositTaskProcessing) &&
                            <TaskLoadingStatus
                                isObjects={true}
                                closeProjectTaskProcessing={closeProjectTaskProcessing}
                                changeProjectDepositTaskProcessing={changeProjectDepositTaskProcessing}
                            />
                        }
                        actionsClassName="col-1 justify-content-end"
                        actions={isHideActions ? null : this.renderAction(item)}
                    />
                ),
            };
        });
    }

    toggleArchived = () => {
        this.setState(prevState => ({
            ...prevState,
            archived: !prevState.archived,
            pageNum: 1,
        }), this.fetchList);
    };

    renderArchiveButton() {
        const {progressList} = this.props;
        const {archived} = this.state;

        return (
            <ButtonArchive
                disabled={progressList}
                onClick={this.toggleArchived}
                archivedFilter={archived}
            />
        );
    }

    renderCreateButton() {
        const {createBtn} = this.localizationData;
        const {
            client: {
                archived: isClientArchived,
            },
            totalCount,
        } = this.props;

        const {
            archived,
            isSearch,
        } = this.state;

        const isCanCreateProjects = [CLIENT_ADMIN, ADMIN, NM_MANAGER, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived;
        const isShowControls = totalCount || isSearch;

        if (
            archived
            || !isShowControls
            || !isCanCreateProjects
            || !this.isAccessActions
            || !this.isAccessAdd
            || !this.isAccessEdit
        ) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                icon={<AddIcon />}
                onClick={this.openEditModal}
            >
                {createBtn}
            </NmButton>
        );
    }

    renderImportProjectsButton() {
        const {importBtn} = this.localizationData;

        if (!this.isAccessImport || !this.isAccessActions) {
            return null;
        }

        return (
            <NmButton
                size="xl"
                onClick={this.openImportProjectsModal}
                className="projects-list__button ms-4"
            >
                {importBtn}
            </NmButton>
        );
    };

    renderImportForm() {
        const {
            progressImport,
            progressDepositImport,
        } = this.props;
        const {importModalData} = this.state;

        const {
            patternLink,
            onSubmit,
        } = importModalData;

        return (
            !isEmpty(importModalData) &&
            <ImportFromFilePatternV2
                patternLink={patternLink}
                onSubmit={onSubmit}
                onClose={this.closeImportFileModal}
                progress={progressImport || progressDepositImport}
            />
        );
    };

    sendFilter = (filter, isSearch) => {
        this.setState({
            filter,
            isSearch,
            pageNum: 1,
        }, this.fetchList);
    };

    getContextMenuOptions = () => {
        const {
            clientProperties: {
                enableIndividualProjectAndObjectDeposit,
                civilOrdersLimit,
                ordersLimit,
            },
        } = this.props;

        const {
            archived,
            hideDetails,
        } = this.state;

        const changeProjectsAndObjectsLimit = (
            !archived
            && Boolean(enableIndividualProjectAndObjectDeposit)
            && [
                ADMIN,
                NM_MANAGER,
                CLIENT_ADMIN,
                CLIENT_ACCOUNTANT,
            ].includes(this.role)
            && (
                ordersLimit > 0
                || civilOrdersLimit > 0
            )
        )
            ? [HEADER_OPTIONS.CHANGE_PROJECTS_AND_OBJECTS_ORDERS_LIMIT]
            : [];

        const distributedByObjectsOptions = (
            [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)
            && Boolean(enableIndividualProjectAndObjectDeposit)
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientProjects,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_addOrRefundDepositObjects,
            ])
        )
            ? [HEADER_OPTIONS.IMPORT_DEPOSIT]
            : [];

        const archivedOption = {
            key: HEADER_OPTIONS.ARCHIVE.value,
            text: archived ? "Список" : "Архив",
            value: HEADER_OPTIONS.ARCHIVE.value,
        };

        const hideDetailsOption = {
            key: HEADER_OPTIONS.HIDE_DETAILS.value,
            text: hideDetails ? "Показать детали" : "Скрыть детали",
            value: HEADER_OPTIONS.HIDE_DETAILS.value,
        };

        const importProjectsOptions = this.isAccessImport
            ? [HEADER_OPTIONS.IMPORT_PROJECTS]
            : [];

        return {
            mobile: [
                hideDetailsOption,
                FILTER_OPTION,
                archivedOption,
                ...importProjectsOptions,
                ...distributedByObjectsOptions,
                ...changeProjectsAndObjectsLimit,
            ],
            tablet: [
                hideDetailsOption,
                archivedOption,
                ...importProjectsOptions,
                ...distributedByObjectsOptions,
                ...changeProjectsAndObjectsLimit,
            ],
            other: [
                ...distributedByObjectsOptions,
                ...changeProjectsAndObjectsLimit,
            ],
        };
    };

    onClickContextMenuItem = ({value}) => {
        switch (value) {
            case HEADER_OPTIONS.CHANGE_PROJECTS_AND_OBJECTS_ORDERS_LIMIT.value:
                this.setState({
                    isOpenChangeProjectsAndObjectsOrdersLimit: true,
                });

                break;
            case HEADER_OPTIONS.HIDE_DETAILS.value:
                this.handleChangeHideDetails();

                return;
            case HEADER_OPTIONS.ARCHIVE.value:
                this.toggleArchived();

                return;
            case HEADER_OPTIONS.IMPORT_PROJECTS.value:
                this.openImportProjectsModal();

                return;
            case HEADER_OPTIONS.IMPORT_DEPOSIT.value:
                this.setState({
                    isOpenAddProjectsAndObjectsDeposit: true,
                });

                return;
            default:
                return;
        }
    };

    renderControls() {
        const {
            hideDetails,
        } = this.state;

        const {
            hideDepositDetailsProgress,
        } = this.props;

        return {
            other: {
                render: () =>
                    <>
                        <NmHorizontalToggleV2
                            className="me-9"
                            disabled={hideDepositDetailsProgress}
                            noWrapLabel
                            leftLabel="Скрыть детали"
                            single
                            onChange={this.handleChangeHideDetails}
                            checked={hideDetails}
                            duplex={false}
                        />
                        {this.renderCreateButton()}
                        {this.renderImportProjectsButton()}
                        {this.renderArchiveButton()}
                    </>,
            },
            tablet: {
                render: () => <>
                    {this.renderCreateButton()}
                </>,
            },
            mobile: {
                render: () => <>
                    {this.renderCreateButton()}
                </>,
            },
        };
    };


    renderAddDepositHistoryModal = () => {
        const {isOpenAddDeposit} = this.state;

        if (!isOpenAddDeposit) {
            return null;
        }

        const {item} = this.state;

        return (
            <ProjectAddDeposit
                name={item.name}
                onClose={() => {
                    this.setState({
                        isOpenAddDeposit: false,
                        item: undefined,
                    });
                }}
                clientId={this.clientId}
                objectId={item.objectId}
                projectId={item.projectId}
                fetchData={() => {
                    this.fetchList();
                }}
            />
        );
    };

    renderChangeProjectOrdersLimitModal = () => {
        const {isOpenChangeOrdersLimit} = this.state;

        if (!isOpenChangeOrdersLimit) {
            return null;
        }

        const {item} = this.state;

        return (
            <ChangeProjectOrdersLimit
                name={item.name}
                clientId={this.clientId}
                projectId={item.projectId}
                onClose={() => {
                    this.setState({
                        isOpenChangeOrdersLimit: false,
                        item: undefined,
                    });
                }}
            />
        );
    };

    renderReturnDepositHistoryModal = () => {
        const {isOpenReturnDeposit} = this.state;

        if (!isOpenReturnDeposit) {
            return null;
        }

        const {item} = this.state;

        return (
            <ProjectReturnDeposit
                name={item.name}
                onClose={() => {
                    this.setState({
                        isOpenReturnDeposit: false,
                        item: undefined,
                    });
                }}
                clientId={this.clientId}
                objectId={item.objectId}
                projectId={item.projectId}
                fetchData={() => {
                    this.fetchList();
                }}
            />
        );
    };

    renderChangeProjectsAndObjectsOrdersLimitModal = () => {
        const {isOpenChangeProjectsAndObjectsOrdersLimit} = this.state;

        if (!isOpenChangeProjectsAndObjectsOrdersLimit) {
            return null;
        }

        return (
            <ChangeProjectObjectsLimit
                clientId={this.clientId}
                onClose={() => {
                    this.setState({
                        isOpenChangeProjectsAndObjectsOrdersLimit: false,
                    });
                }}
            />
        );
    };

    renderAddProjectsAndObjectsDepositModal = () => {
        const {isOpenAddProjectsAndObjectsDeposit} = this.state;

        if (!isOpenAddProjectsAndObjectsDeposit) {
            return null;
        }

        return (
            <AddProjectsObjectsDeposit
                clientId={this.clientId}
                onClose={() => {
                    this.setState({
                        isOpenAddProjectsAndObjectsDeposit: false,
                    });
                }}
            />
        );
    };

    renderDepositHistoryModal = () => {
        const {isOpenDepositHistory} = this.state;

        if (!isOpenDepositHistory) {
            return null;
        }

        const {item} = this.state;

        return (
            <ProjectDepositHistory
                onClose={() => {
                    this.setState({
                        isOpenDepositHistory: false,
                        item: undefined,
                    });
                }}
                logData={item}
            />
        );
    };

    render() {
        const {
            pageNum,
            pageSize,
            isOpenEditModal,
            projectData,
            archived,
            isSearch,
            hideDetails,
            filter,
        } = this.state;

        const {
            totalPages,
            totalCount,
            progressList,
            pathname,
            client: {
                archived: isClientArchived,
            },
            projectsList,
            clientProperties: {
                civilRegistryPaymentsAvailable,
            },
        } = this.props;

        const {
            title,
        } = this.localizationData;

        const isCanCreateProjects = [CLIENT_ADMIN, ADMIN, NM_MANAGER, CLIENT_ACCOUNTANT].includes(this.role) && !isClientArchived;
        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                header={
                    <NmTitle
                        size="xl"
                        count={totalCount}
                    >
                        {title}
                    </NmTitle>
                }
                subHeader={
                    (
                        ![FOREMAN].includes(this.role)
                        && isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_getProjectsAndObjectsDepositValues,
                        ])
                    ) &&
                    <>
                        <div className="registry-list__details-item">
                            <Text
                                className="mb-2"
                                medium
                                level="2"
                                color={COLOR.SECONDARY_70}
                                noWrap
                            >
                                Детализация по счету для выплат исполнителям с типом налогообложения НПД
                            </Text>
                            <DepositValuesAmountInfo
                                hideDetails={hideDetails}
                                clientId={this.clientId}
                                isProjectsPage
                            />
                        </div>
                        {
                            civilRegistryPaymentsAvailable &&
                            <div className="registry-list__details-item">
                                <Text
                                    className="mb-2"
                                    medium
                                    level="2"
                                    color={COLOR.SECONDARY_70}
                                    noWrap
                                >
                                    Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                                </Text>
                                <DepositValuesAmountInfo
                                    hideDetails={hideDetails}
                                    clientId={this.clientId}
                                    isCivil
                                    isProjectsPage
                                />
                            </div>
                        }
                    </>
                }
                mediaQueries={{mobile: {maxWidth: 767}, tablet: {minWidth: 768, maxWidth: 1200}}}
                onClickContextMenuItem={this.onClickContextMenuItem}
                contextMenuOptions={this.getContextMenuOptions()}
                controlsByMediaQueries={this.renderControls()}
                typeFilter="vertical"
                filtersBase={
                    isShowControls &&
                    <ProjectsFilter
                        filter={filter}
                        archived={archived}
                        sendFilter={this.sendFilter}
                    />
                }
                onPaginationChange={this.changePageNum}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                currentPageSize={pageSize}
                onChangePageSize={this.changePageSize}
                isLoaded={progressList}
            >
                {this.renderConfirmWindow()}
                {this.renderAddObjectWindow()}
                {this.renderImportForm()}
                {this.renderDepositHistoryModal()}
                {this.renderAddDepositHistoryModal()}
                {this.renderReturnDepositHistoryModal()}
                {this.renderChangeProjectOrdersLimitModal()}
                {this.renderAddProjectsAndObjectsDepositModal()}
                {this.renderChangeProjectsAndObjectsOrdersLimitModal()}
                {
                    isOpenEditModal &&
                    <ProjectEditModal
                        pathname={pathname}
                        isEdit={!!projectData.projectId}
                        projectData={projectData}
                        onClose={this.closeEditModal}
                    />
                }
                <div className="projects-list">
                    {
                        !projectsList?.length ?
                            <NmEmptyPageV2
                                isShowAction={
                                    isCanCreateProjects
                                    && !archived
                                    && this.isAccessActions
                                    && this.isAccessAdd
                                    && this.isAccessEdit
                                }
                                textAction="Создать проект"
                                onClickAction={this.openEditModal}
                                isSearch={isSearch}
                            /> :
                            <CheckboxList
                                rows={this.getRows()}
                            />
                    }
                </div>
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        projectsList: clientProjectsListSelector(state),
        totalPages: clientProjectsTotalPagesSelector(state),
        totalCount: clientProjectsTotalCountSelector(state),
        progressList: clientProjectsProgressSelector(state),
        progressImport: projectProgressSelector(state),
        client: clientCardInfoSelector(state),
        pathname: state.router.location.pathname,
        clientProperties: clientCardPropertiesSelector(state),
        progressDepositImport: clientObjectsProgressActionSelector(state),
        hideDepositDetailsProgress: hideDepositDetailsProgressSelector(state),
    }),
    {
        getPageClientProjects,
        changeClientProjectStatus,
        changeClientProjectArchived,
        updateFieldsStoreProject,
        importClientProjects,
        getHideDepositDetails,
        setHideDepositDetails,

    },
)(withTranslation()(ProjectsList)));
