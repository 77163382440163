export const GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST = "GET_RICH_PAGE_EDO_DOCUMENTS_REQUEST";
export const GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS = "GET_RICH_PAGE_EDO_DOCUMENTS_SUCCESS";

export const GET_EDO_DOCUMENT_STATES_REQUEST = "GET_EDO_DOCUMENT_STATES_REQUEST";
export const GET_EDO_DOCUMENT_STATES_SUCCESS = "GET_EDO_DOCUMENT_STATES_SUCCESS";

export const GET_DOCUMENT_INFO_COUNT_REQUEST = "GET_DOCUMENT_INFO_COUNT_REQUEST";
export const GET_DOCUMENT_INFO_COUNT_SUCCESS = "GET_DOCUMENT_INFO_COUNT_SUCCESS";
export const GET_DOCUMENT_INFO_COUNT_ERROR = "GET_DOCUMENT_INFO_COUNT_ERROR";

export const REJECT_EDO_DOCUMENT_REQUEST = "REJECT_EDO_DOCUMENT_REQUEST";

export const SIGN_EDO_DOCUMENT_REQUEST = "SIGN_EDO_DOCUMENT_REQUEST";
export const SIGN_EDO_DOCUMENT_SUCCESS = "SIGN_EDO_DOCUMENT_SUCCESS";
export const SIGN_EDO_DOCUMENT_ERROR = "SIGN_EDO_DOCUMENT_ERROR";

export const GET_EDO_DOCUMENT_TYPES_REQUEST = "GET_EDO_DOCUMENT_TYPES_REQUEST";
export const GET_EDO_DOCUMENT_TYPES_SUCCESS = "GET_EDO_DOCUMENT_TYPES_SUCCESS";

export const GET_PAGE_EDO_STAFF_REQUEST = "GET_PAGE_EDO_STAFF_REQUEST";
export const GET_PAGE_EDO_STAFF_SUCCESS = "GET_PAGE_EDO_STAFF_SUCCESS";

export const UPLOAD_EDO_DOCUMENT_REQUEST = "UPLOAD_EDO_DOCUMENT_REQUEST";

export const SEND_EDO_CODE_VERIFICATION_REQUEST = "SEND_EDO_CODE_VERIFICATION_REQUEST";

export const ARCHIVE_EDO_DOCUMENT_REQUEST = "ARCHIVE_EDO_DOCUMENT_REQUEST";

export const REPLACE_EDO_DOCUMENT_REQUEST = "REPLACE_EDO_DOCUMENT_REQUEST";

export const UPDATE_EDO_DOCUMENTS_STORE = "UPDATE_EDO_DOCUMENTS_STORE";

export const GET_EDO_ACCESS_LIST_REQUEST = "GET_EDO_ACCESS_LIST_REQUEST";
export const GET_EDO_ACCESS_LIST_SUCCESS = "GET_EDO_ACCESS_LIST_SUCCESS";

export const GET_EDO_PRE_FILLED_TEMPLATE_REQUEST = "GET_EDO_PRE_FILLED_TEMPLATE_REQUEST";
export const GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS = "GET_EDO_PRE_FILLED_TEMPLATE_SUCCESS";

export const CLEAR_EDO_PRE_FILLED_TEMPLATE = "CLEAR_EDO_PRE_FILLED_TEMPLATE";

export const UPLOAD_EDO_TEMPLATE_REQUEST = "UPLOAD_EDO_TEMPLATE_REQUEST";

export const UPDATE_EDO_TEMPLATE_REQUEST = "UPDATE_EDO_TEMPLATE_REQUEST";

export const VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST = "VALIDATE_EDO_COMPLETED_DOCUMENT_REQUEST";

export const CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST = "CHECK_EDO_DUPLICATE_DOCUMENTS_REQUEST";