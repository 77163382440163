import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getDeferredActsOfAcceptanceWorkPage} from "./actionCreators";
import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS,
} from "./actions";
import {bffDeferredActsOfAcceptanceWorkPageDataSelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";
import {
    getBffControllerClientCardPage,
    getBffUrl,
} from "../../../../utils/url";

import {LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD} from "../../../../constants/links";

const getController = (params = {}) => {
    const {
        registryId,
        clientId,
    } = params;

    return getBffUrl({
        [
        LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_CARD
            .replace(":id", registryId)
            .replace(":clientId", clientId)
        ]: getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry",
            client: "/client-adm/documents/deferred-acts-of-acceptance-work/registry",
        }),
        isClientCard: true,
        clientRolesUrl: "/client-adm/documents/deferred-acts-of-acceptance-work",
        adminRolesUrl: "/adm/clients/client-card/documents/deferred-acts-of-acceptance-work",
    },
    null,
    true,
    );
};

// POST /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/page
// POST /bff/client-adm/documents/deferred-acts-of-acceptance-work/page
const getDeferredActsOfAcceptanceWorkPageSaga = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}/page`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_ERROR,
            payload: error,
        });
    }
};

// GET /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/status/dict
// GET /bff/client-adm/documents/deferred-acts-of-acceptance-work/status/dict
// Словарь статуса реестра
const getDeferredActsOfAcceptanceWorkStatusDict = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.get(`${controller}/status/dict`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/archive
// PUT /bff/client-adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/archive
// Архивировать реестр
// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/restore-from-archive
// PUT /bff/client-adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/restore-from-archive
// Восстановить из архива
export const archiveDeferredActsAcceptanceSaga = function* ({payload}) {
    const {
        clientId,
        registryId,
        archived,
    } = payload;

    const action = archived ? "restore-from-archive" : "archive";

    try {
        const result = yield request.bff.put(`${getController()}/${clientId}/${registryId}/${action}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = bffDeferredActsOfAcceptanceWorkPageDataSelector(state);

        yield put(getDeferredActsOfAcceptanceWorkPage(pageData));
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/submit
// PUT /bff/client-adm/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/submit
// Передать на подписание
// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/resubmit
// PUT /bff/client-adm/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/resubmit
// Повторить невыполненные
const submitDeferredActsAcceptanceWorkSaga = function* ({payload}) {
    const {
        clientId,
        registryId,
        isReSubmit,
    } = payload;

    const action = isReSubmit ? "resubmit" : "submit";

    try {
        const result = yield request.bff.put(`${getController({registryId, clientId})}/${clientId}/${registryId}/${action}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = bffDeferredActsOfAcceptanceWorkPageDataSelector(state);

        yield put(getDeferredActsOfAcceptanceWorkPage(pageData));
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/update
// PUT /bff/client-adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/update
// Редактировать реестр
const updateDeferredActsAcceptanceWorkSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            ...reqData
        } = payload;

        const result = yield request.bff.put(`${getController()}/${clientId}/${registryId}/update`, reqData);
        const {errorMessage} = result;

        console.log("saga1", errorMessage);
        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        const state = yield select();
        const pageData = bffDeferredActsOfAcceptanceWorkPageDataSelector(state);

        console.log("saga2", pageData);
        yield put(getDeferredActsOfAcceptanceWorkPage(pageData));

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_ERROR,
            payload: error,
        });
    }
};

// PUT /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/cancel
// PUT /bff/client-adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/cancel
// Отменить невыполненные
const cancelOutstandingDeferredActsAcceptanceWorkSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.bff.put(`${getController({clientId, registryId})}/${clientId}/${registryId}/cancel`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_SUCCESS,
            payload: result,
        });

        const state = yield select();
        const pageData = bffDeferredActsOfAcceptanceWorkPageDataSelector(state);

        yield put(getDeferredActsOfAcceptanceWorkPage(pageData));
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/log/page
// POST /bff/client-adm/documents/deferred-acts-of-acceptance-work/{clientId}/{registryId}/log/page
// POST /bff/adm/clients/client-card/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registryId}/log/page
// POST /bff/client-adm/documents/deferred-acts-of-acceptance-work/registry/{clientId}/{registryId}/log/page
// Получить страницу с логами
const getDeferredActsOfAcceptanceWorkLogPage = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const url = `${getController({clientId, registryId})}/${clientId}/${registryId}/log/page`;
        const result = yield request.bff.post(url, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST, getDeferredActsOfAcceptanceWorkPageSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST, getDeferredActsOfAcceptanceWorkStatusDict),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST, archiveDeferredActsAcceptanceSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST, submitDeferredActsAcceptanceWorkSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST, updateDeferredActsAcceptanceWorkSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST, cancelOutstandingDeferredActsAcceptanceWorkSaga),
        takeEvery(BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST, getDeferredActsOfAcceptanceWorkLogPage),
    ]);
}