import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import ImportFromFilePatternV2 from "../../../../components/ActualComponents/ImportFromFilePatternV2";
import {MediaButtons} from "../../../../components/ActualComponents/MediaControls";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmHorizontalToggleV2 from "../../../../components/ActualComponents/NmHorizontalToggleV2";
import NmLabelText from "../../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import {NmPageCardHeader} from "../../../../components/ActualComponents/NmPageCardHeader";
import Text from "../../../../components/ActualComponents/Text";
import Avatar from "../../../../components/Avatar";
import CheckboxList from "../../../../components/CheckboxList";
import CodeConfirm from "../../../../components/CodeConfirm";
import InvitePerformerToNaimix from "../../../../components/InvitePerformerToNaimix";
import NmBadge from "../../../../components/NmBadge";
import NmPage from "../../../../components/NmPage";
import Task from "../../../../components/NmTask";
import NmTitle from "../../../../components/NmTitle";
import SelectionCountWithAction from "../../../../components/SelectionCountWithAction";
import {ReactComponent as AddBoxIcon} from "../../../../images/add_box.svg";
import {ReactComponent as FileDownloadIcon} from "../../../../images/file_download.svg";
import {ReactComponent as UserIcon} from "../../../../images/user_24.svg";
import EdoDocumentDuplicateList
    from "../../document-managment-loading-document-v2/components/edo-document-duplicate-list";
import EdoDocumentsRegistriesItemEditForm from "./components/edit-form";
import {EdoDocumentsRegistriesCardFilter} from "./components/filter";

import {usePagination} from "../../../../hooks/usePagination";
import {useSelectedList} from "../../../../hooks/useSelectedList";
import {useEdoDocumentsRegistriesCardAction} from "./hooks/useAction";
import {useEdoDocumentsRegistriesCardFetch} from "./hooks/useFetch";
import {useEdoDocumentsRegistriesCardFilter} from "./hooks/useFilter";

import {getUserRestrictions} from "../../../../utils/access";
import bem from "../../../../utils/bem";
import {formatLocalDate} from "../../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {phoneFormat} from "../../../../utils/stringFormat";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {EDM_STAFF_PRIVILEGES, STAFF_STATUS} from "../../../../constants/edmStaff";
import {LINK_CONTRACTOR_PROFILE, LinkNavigator} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

import {
    edoAccessListSelector,
    edoDocumentTypesSelector,
} from "../../../../ducks/bff/clients/edo/documents/selectors";
import {
    documentsEdoRegistriesItemsListSelector,
    documentsEdoRegistriesItemsProgressActionSelector,
    documentsEdoRegistriesItemsProgressListSelector,
    documentsEdoRegistriesItemsTotalCountSelector,
    documentsEdoRegistriesItemsTotalPagesSelector,
} from "../../../../ducks/bff/clients/edo/registries/card/selectors";
import {
    documentsEdoRegistriesCardSelector,
    documentsEdoRegistriesProgressActionSelector,
} from "../../../../ducks/bff/clients/edo/registries/selectors";
import {clientCurrentMemberSelector} from "../../../../ducks/clientMember";
import {contractorStatusDictSelector} from "../../../../ducks/contractor";
import {avatarBase64ImagesListSelector} from "../../../../ducks/fileStore";

import "./style.sass";

import {EDO_ACCESS_RIGHTS, TEMPLATE_DOCUMENT} from "../../../../constants/document-management/document-list";
import {
    EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS,
    EDO_DOCUMENTS_REGISTRIES_STATUS,
} from "../../../../constants/document-management/documents-registries";

const EdoDocumentsRegistriesCard = (props) => {
    const {
        match: {
            params: {
                clientId,
                registryId,
            },
        },
    } = props;

    const card = useSelector(documentsEdoRegistriesCardSelector);
    const totalPages = useSelector(documentsEdoRegistriesItemsTotalPagesSelector);
    const list = useSelector(documentsEdoRegistriesItemsListSelector);
    const totalCount = useSelector(documentsEdoRegistriesItemsTotalCountSelector);
    const progressList = useSelector(documentsEdoRegistriesItemsProgressListSelector);
    const progressRegistryAction = useSelector(documentsEdoRegistriesProgressActionSelector);
    const progressItemsAction = useSelector(documentsEdoRegistriesItemsProgressActionSelector);
    const contractorStatusDict = useSelector(contractorStatusDictSelector);
    const userAvatarDict = useSelector(avatarBase64ImagesListSelector);
    const edoDocumentTypesDict = useSelector(edoDocumentTypesSelector);
    const {phone: currentUserPhone} = useSelector(clientCurrentMemberSelector);
    const edoAccessList = useSelector(edoAccessListSelector);

    const currentUserRestrictions = getUserRestrictions();
    const isAccessRegistryManageByRestrictions = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.actionsEdoRegistryItem,
    ]);
    const isAccessRegistryManage = edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_MANAGE)
        && isAccessRegistryManageByRestrictions;

    const [block, element] = bem("documents-edo-registries-card");

    const {
        name,
        description,
        ownerFio,
        senderFio,
        documentName,
        useTemplateFile,
        status,
        documentType,
        templateType,
        archivedDatetime,
        notCompletedItemsExists,
    } = card;

    const isRegistryArchived = Boolean(archivedDatetime);

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        selectedList,
        countSelected,
        handleSelectedRows,
        clearSelectedRows,
    } = useSelectedList();

    const {
        onChangeFilter,
        filter,
        onSearch,
        isSearch,
        filterData,
        onClear,
    } = useEdoDocumentsRegistriesCardFilter({setPagination, pageSize});

    const {
        fetchCard,
        fetchList,
        fetchTasks,
    } = useEdoDocumentsRegistriesCardFetch({pageNum, pageSize, clientId, registryId, filterData});

    const {
        confirmData,
        setConfirmData,
        isInviteViaEmailOpen,
        setIsInviteViaEmailOpen,
        isEditFormOpen,
        setEditFormOpen,
        editData,
        setEditData,
        dataDuplicate,
        setDataDuplicate,
        timer,
        isCodeConfirmOpen,
        setCodeConfirmOpen,
        isImportModalOpen,
        setImportModalOpen,
        isHideStaffInfo,
        setHideStaffInfo,
        openDeleteConfirm,
        openSignConfirm,
        onCloseEditForm,
        confirmDuplicatesItems,
        sendSms,
        signRegistry,
        onChangeCode,
        importItems,
        handleDownload,
        rejectSendItem,
        deleteNotCompletedItems,
        handleDownloadEmptyTemplate,
        exportRegistry,
        codeConfirmType,
    } = useEdoDocumentsRegistriesCardAction({
        clientId,
        registryId,
        selectedList,
        documentType,
        useTemplateFile,
        fetchCard,
        fetchList,
        fetchTasks,
        clearSelectedRows,
    });

    const getMobileDropdownMassActionOptions = () => {
        return [
            {
                text: "Удалить",
                disabled: countSelected === 0,
                onClick: openDeleteConfirm,
            },
            {
                text: isHideStaffInfo ? "Показать права в кадрах" : "Скрыть права в кадрах",
                onClick: () => setHideStaffInfo(!isHideStaffInfo),
            },

        ];
    };

    const getMediaControls = (item) => {
        const {
            status: itemStatus,
            documentId,
            seqNum,
        } = item;
        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Редактировать",
                        onClick: () => {
                            setEditFormOpen(true);
                            setEditData(item);
                        },
                    },
                    visible: !isRegistryArchived
                        && isAccessRegistryManage
                        && (
                            !itemStatus
                            || [
                                EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.ERROR.VALUE,
                                EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.REJECTED.VALUE,
                            ].includes(itemStatus)
                        ),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Отменить отправку документа",
                        onClick: () => {
                            setConfirmData({
                                content: "Вы действительно хотите отменить отправку документа на подписание исполнителю?",
                                onConfirm: () => rejectSendItem(seqNum),
                            });
                        },
                    },
                    visible: isAccessRegistryManage
                        && [
                            EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_REGISTRATION.VALUE,
                            EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_FULL_REGISTRATION.VALUE,
                        ].includes(itemStatus),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Скачать",
                        onClick: () => handleDownload(documentId),
                    },
                    visible: Boolean(documentId),
                },
            ],
        };
    };

    const getHeaderMediaControls = () => {
        const isAccessSignActions = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsEdoRegistries,
        ]);

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        onClick: openSignConfirm,
                        children: "Подписать",
                        disabled: progressItemsAction || progressRegistryAction,
                    },
                    visible: edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_SIGN)
                        && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status)
                        && isAccessSignActions
                        && Boolean(totalCount),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        onClick: exportRegistry,
                        children: "Выгрузить",
                        disabled: progressRegistryAction,
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setConfirmData({
                                content: `Вы действительно хотите повторить отправку документов по невыполненным строкам реестра "${name}"?`,
                                onConfirm: signRegistry,
                            });
                        },
                        children: "Повторить невыполненные",
                    },
                    visible: edoAccessList.includes(EDO_ACCESS_RIGHTS.EDM_REGISTRY_SIGN)
                        && notCompletedItemsExists
                        && isAccessSignActions
                        && !isRegistryArchived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setConfirmData({
                                content: `Вы действительно хотите удалить невыполненные строки реестра "${name}"?`,
                                onConfirm: deleteNotCompletedItems,
                            });
                        },
                        children: "Удалить невыполненные",
                    },
                    visible: notCompletedItemsExists && isAccessRegistryManage && !isRegistryArchived,
                },
            ],
        };
    };

    const getImportPatternLink = () => {
        if (!useTemplateFile) {
            return "/files/Шаблон_Реестр документов ЭДО_1.xlsx";
        }

        if (
            [
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7,
                TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8,
                TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION,
            ].includes(templateType)
        ) {
            return "/files/Шаблон_Реестр документов ЭДО_2.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_3.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.CIVIL_DEED].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_4.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_5.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_6.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_7.xlsx";
        }

        if ([TEMPLATE_DOCUMENT.ACT_CIVIL_DEED].includes(templateType)) {
            return "/files/Шаблон_Реестр документов ЭДО_8.xlsx";
        }

        return "/files/Шаблон_Реестр документов ЭДО_1.xlsx";
    };

    const getRows = () => {
        return list.map(item => {
            const {
                receiverFio,
                contractorId,
                seqNum,
                receiverPhone,
                contractorRegistrationStatus,
                documentDatetime,
                documentName,
                documentInformation,
                receiverId,
                receiverStatus,
                receiverEdmPrivileges,
                status: itemStatus,
                errors = [],
                message,
                signingDatetime,
            } = item;

            const {isSelected = false} = selectedList.find(item => (item.seqNum === seqNum)) || {};
            const disabledCheckBox = !isAccessRegistryManage || isRegistryArchived || [EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.AWAITING_RECEIVER_SIGNING.VALUE, EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.SIGNED.VALUE, EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS.FOR_SIGNING.VALUE].includes(itemStatus);
            const isRenderStatus = ![EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status);

            return {
                ...item,
                key: item.seqNum,
                showCheckBox: true,
                disabledCheckBox,
                isSelected,
                avatar: <Avatar
                    addedToMyClientGroup={item.addedToMyClientGroup}
                    className={element("avatar", {withStatus: isRenderStatus})}
                    contractorId={contractorId}
                    base64={userAvatarDict[contractorId]}
                />,
                contentRow: (
                    <NmListCard
                        avatar
                        checkbox
                        secondaryHeaderStatus={
                            isRenderStatus &&
                            <div
                                className={`${element("badge-container", {withSecondaryHeader: Boolean(signingDatetime)})} mt-5 mt-md-0`}
                            >
                                <NmBadge
                                    className="flex flex-aligned-center"
                                    mod={EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS[itemStatus]?.MOD}
                                    text={EDO_DOCUMENTS_REGISTRIES_ITEMS_STATUS[itemStatus]?.TEXT}
                                    description={message}
                                    tooltipPosition="bottom"
                                />
                            </div>
                        }
                        secondaryHeader={signingDatetime && `Дата подписания ${formatLocalDate(signingDatetime, "dd.MM.yyyy")}`}
                        primaryHeader={receiverFio || "Не определено"}
                        primaryHeaderTooltip={
                            Boolean(errors.length) &&
                            <HelpTooltip
                                attention
                                position="bottom-left"
                                info
                                width={24}
                                height={24}
                            >
                                {errors.map(({errorMessage}) => errorMessage).join(", ")}
                            </HelpTooltip>
                        }
                        primaryHeaderLink={Boolean(contractorId)}
                        onClickLink={() => {
                            history.push(LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId));
                        }}
                        labels={[
                            {label: "Номер строки", text: seqNum},
                            {label: "Телефон", text: phoneFormat(receiverPhone)},
                            {
                                label: "Статус регистрации",
                                text: contractorStatusDict[contractorRegistrationStatus] || "Не зарегистрирован на площадке",
                                noWrap: false,
                                columnOnMobile: true,
                            },
                            {
                                label: "Дата документа",
                                text: formatLocalDate(documentDatetime, "dd.MM.yyyy"),
                            },
                            {
                                label: "Наименование документа",
                                text: documentName,
                                columnOnMobile: true,
                                noWrap: false,
                                flexWrap: true,
                            },
                            documentInformation && {
                                label: "Дополнительные сведения",
                                text: documentInformation,
                                columnOnMobile: true,
                                noWrap: false,
                                flexWrap: true,
                            },
                            !isHideStaffInfo && {
                                label: "Добавлен в кадры компании",
                                text: receiverId ? "Да" : "Нет",
                            },
                            !isHideStaffInfo && {
                                label: "Статус в кадрах \"Работает\"",
                                text: receiverStatus === STAFF_STATUS.WORKING ? "Да" : "Нет",
                            },
                            !isHideStaffInfo && {
                                label: "Может быть выбран получателем документа в кадрах",
                                text: receiverEdmPrivileges?.includes(EDM_STAFF_PRIVILEGES.RECEIVE_DOCUMENT) ? "Да" : "Нет",
                                classNameLabel: element("label", {wrap: true}),
                                classNameText: element("text"),
                                columnOnMobile: true,
                            },
                        ]}
                        classNameMainContent={element("card-main-content")}
                        isFixedActions
                        mediaControls={getMediaControls(item)}
                    />
                ),
            };
        });
    };

    const renderInvitePerformerToNaimix = () => {
        return (
            isInviteViaEmailOpen &&
            <InvitePerformerToNaimix
                close={() => setIsInviteViaEmailOpen(false)}
                clientId={clientId}
            />
        );
    };

    const goToAddContractorPage = () => {
        history.push(LinkNavigator.client.edo.documentsRegistriesCardAddItem
            .replace(":clientId", clientId)
            .replace(":registryId", registryId),
        );
    };

    const renderConfirmModal = () => {
        const {
            content,
            onConfirm,
        } = confirmData;

        return (
            !isEmpty(confirmData) &&
            <NmConfirmV2
                content={content}
                onCancel={() => setConfirmData({})}
                onConfirm={onConfirm}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                loading={progressRegistryAction || progressItemsAction}
            />
        );
    };

    const renderEditForm = () => {
        return (
            isEditFormOpen &&
            <EdoDocumentsRegistriesItemEditForm
                clientId={clientId}
                registryId={registryId}
                documentType={documentType}
                isAddMissingContractor={!editData.contractorId}
                editData={editData}
                fetchList={fetchList}
                onClose={onCloseEditForm}
                loading={progressItemsAction}
                useTemplateFile={useTemplateFile}
                templateType={templateType}
            />
        );
    };

    const renderCodeConfirm = () => {
        return (
            isCodeConfirmOpen &&
            <CodeConfirm
                title="Подписание реестра документов"
                phone={currentUserPhone}
                timer={timer}
                sendConfirmationCodeToUser={(codeType) => sendSms(codeType)}
                submitCode={signRegistry}
                onClose={() => setCodeConfirmOpen(false)}
                loading={progressRegistryAction}
                onChange={onChangeCode}
                codeConfirmType={codeConfirmType}
            />
        );
    };

    const renderDuplicateList = () => {
        const {
            duplicates,
            duplicatesInRegistryExists,
        } = dataDuplicate;

        return (
            duplicates?.length &&
            <EdoDocumentDuplicateList
                isRegistry
                edoDocumentTypesDict={edoDocumentTypesDict}
                submit={confirmDuplicatesItems}
                dataDuplicate={duplicates}
                duplicatesInRegistryExists={duplicatesInRegistryExists}
                close={() => setDataDuplicate({})}
            />
        );
    };

    const renderImportModal = () => {
        return (
            isImportModalOpen &&
            <ImportFromFilePatternV2
                patternLink={getImportPatternLink()}
                onSubmit={importItems}
                onClose={() => setImportModalOpen(false)}
                progress={progressItemsAction}
            />
        );
    };

    const renderTemplate = () => {
        return (
            <div className="flex">
                {useTemplateFile ? "Да" : "Нет"}
                <div
                    className={element("link-file")}
                    onClick={handleDownloadEmptyTemplate}
                >
                    (посмотреть
                    {" "}
                    {useTemplateFile ? "предложенный шаблон" : "прикрепленный документ"}
                    )
                </div>
            </div>
        );
    };

    return (
        <NmPage
            header={
                <NmPageCardHeader
                    to={LinkNavigator.client.edo.documentsRegistries.replace(":clientId", clientId)}
                    status={status}
                    statusDict={EDO_DOCUMENTS_REGISTRIES_STATUS}
                    content={name}
                />
            }
            mediaControls={getHeaderMediaControls()}
            subHeader={
                <div className="col-16 col-xxl-9">
                    <Text
                        level="3"
                        className="mb-2"
                    >
                        {description}
                    </Text>
                    <NmLabelText
                        type="page"
                        label="Ответственный за реестр"
                        text={ownerFio}
                        columnOnMobile
                        noWrap={false}
                    />
                    <NmLabelText
                        type="page"
                        label="Отправитель документов"
                        text={senderFio || "-"}
                        columnOnMobile
                        noWrap={false}
                    />
                    <NmLabelText
                        type="page"
                        label="Тип документа"
                        text={documentName}
                        columnOnMobile
                        noWrap={false}
                    />
                    <NmLabelText
                        type="page"
                        label="Подача с использованием шаблона"
                        text={renderTemplate()}
                        columnOnMobile
                        noWrap={false}
                    />
                </div>
            }
            typeFilter="vertical"
            filtersBase={
                <EdoDocumentsRegistriesCardFilter
                    onChange={onChangeFilter}
                    filter={filter}
                    onSearch={onSearch}
                    onClear={onClear}
                />
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            isLoaded={progressList}
        >
            <Task />
            <div className="flex mb-4">
                <NmTitle
                    count={totalCount}
                    size="lg"
                >
                    Исполнители
                </NmTitle>
                <MediaButtons
                    className="ms-8"
                    config={{
                        renderCount: {
                            desktop: 4,
                            tablet: 0,
                            mobile: 0,
                        },
                        buttons: [
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    children: "Добавить исполнителя из списка",
                                    icon: <AddBoxIcon
                                        width={24}
                                        height={24}
                                    />,
                                    onClick: goToAddContractorPage,
                                },
                                visible: !isRegistryArchived
                                    && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status)
                                    && isAccessRegistryManage,
                            },
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    icon: <FileDownloadIcon
                                        width={24}
                                        height={24}
                                    />,
                                    children: "Загрузить список исполнителей",
                                    onClick: () => setImportModalOpen(true),
                                },
                                visible: !isRegistryArchived
                                    && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status)
                                    && isAccessRegistryManage,
                            },
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    icon: <AddBoxIcon
                                        width={24}
                                        height={24}
                                    />,
                                    children: "Добавить отсутствующего на площадке исполнителя",
                                    onClick: () => setEditFormOpen(true),
                                },
                                visible: !isRegistryArchived
                                    && [EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status)
                                    && isAccessRegistryManage,
                            },
                            {
                                component: COMPONENT.REGISTRY_BUTTON,
                                props: {
                                    icon: <UserIcon
                                        width={24}
                                        height={24}
                                    />,
                                    children: "Пригласить исполнителя в Наймикс",
                                    onClick: () => setIsInviteViaEmailOpen(true),
                                },
                                visible: ![EDO_DOCUMENTS_REGISTRIES_STATUS.DRAFT.VALUE].includes(status),
                            },
                        ],
                    }}
                />
            </div>
            {renderInvitePerformerToNaimix()}
            {renderConfirmModal()}
            {renderEditForm()}
            {renderCodeConfirm()}
            {renderDuplicateList()}
            {renderImportModal()}
            {
                list.length === 0 ?
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    /> :
                    <CheckboxList
                        className={element("checkbox-list")}
                        rows={getRows()}
                        header={
                            <SelectionCountWithAction
                                adaptiveLogic
                                count={countSelected}
                                buttonColor="grey"
                                buttonContent="Удалить строку"
                                onClick={openDeleteConfirm}
                            />
                        }
                        additionalActions={
                            <NmHorizontalToggleV2
                                noWrapLabel
                                leftLabel="Скрыть права в кадрах"
                                single
                                onChange={(e, {checked}) => {
                                    setHideStaffInfo(checked);
                                }}
                                checked={isHideStaffInfo}
                                duplex={false}
                            />
                        }
                        isCheckBox
                        onSelectedRows={handleSelectedRows}
                        actionOptions={getMobileDropdownMassActionOptions()}
                    />
            }
        </NmPage>
    );
};

export default EdoDocumentsRegistriesCard;