import {
    DOWNLOAD_EDO_EXPORT_FILES_ERROR,
    DOWNLOAD_EDO_EXPORT_FILES_REQUEST,
    DOWNLOAD_EDO_EXPORT_FILES_SUCCESS,
    GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS,
    GET_EDO_EXPORT_LIST_ERROR,
    GET_EDO_EXPORT_LIST_REQUEST,
    GET_EDO_EXPORT_LIST_SUCCESS,
    GET_EDO_EXPORT_STATUSES_SUCCESS,
    GET_STATEMENTS_INFO_COUNT_SUCCESS,
    GET_STATEMENTS_PAGE_REQUEST,
    GET_STATEMENTS_PAGE_SUCCESS,
    GET_STATEMENTS_TYPES_PAGE_SUCCESS,
} from "./actions";

export const STATEMENT_FILE_TYPE = {
    SOURCE_FILE: "source",
    ATTACHMENT_FILE: "attachment",
    SIGNED_FILE: "signed",
};

const initial = {
    pageData: {},
    totalCount: 0,
    list: [],
    types: [],
    edoCounts: {
        documentsCount: 0,
        statementsCount: 0,
    },
    statementsInfoCount: 0,
    exportList: [],
    exportListPageData: {},
    exportListProgress: false,
    exportStatuses: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case DOWNLOAD_EDO_EXPORT_FILES_REQUEST:
            return {
                ...state,
                exportListProgress: true,
            };
        case GET_EDO_EXPORT_LIST_REQUEST:
            return {
                ...state,
                exportListPageData: payload.data,
                exportListProgress: true,
            };
        case GET_STATEMENTS_PAGE_REQUEST:
            const {
                pageSize,
                pageNum,
            } = payload;

            return {
                ...state,
                pageData: {
                    pageSize,
                    pageNum,
                },
            };
        case GET_EDO_EXPORT_STATUSES_SUCCESS:
            return {
                ...state,
                exportStatuses: payload,
            };
        case DOWNLOAD_EDO_EXPORT_FILES_SUCCESS:
            return {
                ...state,
                exportListProgress: false,
            };
        case GET_EDO_EXPORT_LIST_SUCCESS:
            return {
                ...state,
                exportListTotalCount: payload.totalCount,
                exportList: payload.models,
                exportListProgress: false,
            };
        case GET_STATEMENTS_PAGE_SUCCESS:
            const {totalCount, models} = payload;
            return {
                ...state,
                totalCount,
                list: models,
            };
        case GET_STATEMENTS_TYPES_PAGE_SUCCESS:
            const {results} = payload;

            return {
                ...state,
                types: results,
            };
        case GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS:
            return {
                ...state,
                edoCounts: {...payload},
            };
        case GET_STATEMENTS_INFO_COUNT_SUCCESS:
            return {
                ...state,
                statementsInfoCount: payload,
            };
        case DOWNLOAD_EDO_EXPORT_FILES_ERROR:
        case GET_EDO_EXPORT_LIST_ERROR:
            return {
                ...state,
                exportListProgress: false,
            };
        default:
            return state;
    }
};