import React, {useMemo, useState} from "react";

import {ReactComponent as AddIcon} from "../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../images/delete_24.svg";
import {ReactComponent as ModeIcon} from "../../images/mode.svg";
import Filter, {FILTER} from "../ActualComponents/Filter";
import {MediaButtons} from "../ActualComponents/MediaControls";
import NmConfirmV2 from "../ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../ActualComponents/NmEmptyPageV2";
import NmListCard from "../ActualComponents/NmList/Card";
import CheckboxList from "../CheckboxList";
import NmButton from "../NmButton";
import NmPage from "../NmPage";
import NmTitle from "../NmTitle";
import {UpdatingDirectoriesEdit} from "./components/Edit";

import bem from "../../utils/bem";

import {COMPONENT} from "../ActualComponents/MediaControls/constants";
import {UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE} from "./constants";

import "./style.sass";

const UpdatingDirectories = props => {
    const {
        title,
        titleTooltipText,
        isSearch,
        filter,
        fetchList,
        clearFilter,
        pageSize,
        pageNum,
        totalPages,
        onChangePageSize,
        onPaginationChange,
        list,
        onAdd,
        onDelete,
        onEdit,
        addButtonText,
        totalCount,
        popupTitleAdd,
        popupTitleEdit,
        popupTitleDelete,
        popupSubmitDelete,
        popupCancelDelete,
        onSubmitFilter,
        labelFilter,
        placeholderFilter = "Введите тип заявления",
        isEditable,
        inputTypePlaceholder,
        inputTypeLabel,
        inputTypeMaxLength,
        isLoaded,
        getItemActionDeleteVisible,
        isNeedClosingOnSubmit = true,
        progressAction,
        inputTypeMinLength,
        filterNameField = "valueFilter",
        onClickSort,
        sort,
        sortOptions,
        isClientField,
        titleHiddenInMobile,
        header,
        openFilter,
        widthByFilter,
        getLabels,
        onClickEdit,
        popupAdd,
        onClickAdd,
    } = props;

    const [block] = bem("updating-directories");
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const openEdit = (modalData) => {
        if (modalData) {
            setModalData(modalData);
        }

        if (open) {
            setModalData({});
        }

        setOpen(!open);
    };

    const _getLabels = (item) => {
        if (isClientField) {
            return [
                {label: "Компания", text: item.clientName || "-"},
            ];
        }

        if (getLabels) {
            return getLabels(item);
        }

        return null;
    };

    const getRows = () => {
        return list.map(item => {
            return {
                ...item,
                key: item.id,
                contentRow: (
                    <NmListCard
                        primaryHeader={item.value}
                        noDivider
                        labels={_getLabels(item)}
                        classNameMainContent="col-16 col-xxl-14"
                        isFixedActions
                        actions={
                            isEditable &&
                            <MediaButtons
                                inline
                                config={getMediaControls(item)}
                            />
                        }
                    />
                ),
            };
        });
    };

    const getMediaControls = (value) => {
        return {
            renderCount: {
                mobile: 0,
                tablet: 2,
                desktop: 2,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            if (onClickEdit) {
                                onClickEdit(value);

                                return;
                            }

                            openEdit({
                                ...value,
                                actionType: UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE.EDIT,
                                header: popupTitleEdit,
                                submitBtnContent: "Сохранить",
                                cancelBtnContent: "Отменить",
                            });
                        },
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <ModeIcon />
                        ),
                    },
                    asset: {
                        mobile: {
                            children: "Редактировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            openEdit({
                                ...value,
                                actionType: UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE.DELETE,
                                header: popupTitleDelete,
                                submitBtnContent: popupSubmitDelete || "Да",
                                cancelBtnContent: popupCancelDelete || "Нет",
                            });
                        },
                        color: "grey",
                        onlyIcon: true,
                        icon: (
                            <DeleteIcon />
                        ),
                    },
                    visible: getItemActionDeleteVisible ? getItemActionDeleteVisible(value) : true,
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
            ],
        };
    };

    const getModal = () => {
        if (popupAdd) {
            return popupAdd;
        }

        if (modalData?.actionType === UPDATING_SPECIALITIES_DIRECTORIES_ACTION_TYPE.DELETE) {
            const {
                id,
                header,
                submitBtnContent,
                cancelBtnContent,
            } = modalData;

            return open &&
                <NmConfirmV2
                    content={header}
                    onCancel={openEdit}
                    isNeedClosing={isNeedClosingOnSubmit}
                    onConfirm={() => onDelete({
                        id,
                        callBack: () => {
                            fetchList();

                            if (!isNeedClosingOnSubmit) {
                                openEdit();
                            }
                        },
                    })}
                    disabled={progressAction}
                    confirmButton={submitBtnContent}
                    cancelButton={cancelBtnContent}
                />;
        }

        return open &&
            <UpdatingDirectoriesEdit
                isClientField={isClientField}
                onClose={openEdit}
                modalData={modalData}
                fetchList={fetchList}
                inputTypeLabel={inputTypeLabel}
                inputTypePlaceholder={inputTypePlaceholder}
                inputTypeMaxLength={inputTypeMaxLength}
                inputTypeMinLength={inputTypeMinLength}
                onAdd={onAdd}
                onEdit={onEdit}
                isNeedClosingOnSubmit={isNeedClosingOnSubmit}
                progressAction={progressAction}
            />;
    };

    const addDocumentType = () => {
        if (onClickAdd) {
            onClickAdd();

            return;
        }

        openEdit({
            header: popupTitleAdd,
            submitBtnContent: "Добавить",
            cancelBtnContent: "Отменить",
        });
    };

    const filtersBase = useMemo(() => {
        const baseFilterRow = {
            row: [
                {
                    component: FILTER.INPUT,
                    name: filterNameField,
                    label: labelFilter,
                    placeholder: placeholderFilter,
                },
            ],
        };

        if (isClientField) {
            return [
                {
                    row: [
                        {
                            component: FILTER.CUSTOMER,
                            label: "Компания",
                            placeholder: "Не выбрано",
                            name: "clientIdFilter",
                            isClearable: true,
                            isBff: true,
                            search: true,
                        },
                    ],
                },
                baseFilterRow,
            ];
        }

        return [
            baseFilterRow,
        ];
    }, []);

    const getHeader = () => {
        if (header) {
            return header;
        }

        if (title) {
            return (
                <NmTitle
                    size="xl"
                    children={title}
                    tooltipText={titleTooltipText}
                    noWrap={true}
                />
            );
        }

        return null;
    };

    return (
        <NmPage
            className={block()}
            header={getHeader()}
            titleHiddenInMobile={titleHiddenInMobile}
            headerClassName={!title && !header && "justify-content-end"}
            openFilter={openFilter}
            widthByFilter={widthByFilter}
            filtersBase={
                filter &&
                <Filter
                    initState={filter}
                    filters={filtersBase}
                    onSubmit={onSubmitFilter}
                    clearFilter={clearFilter}
                />
            }
            typeFilter={filter && "vertical"}
            controls={
                isEditable && <NmButton
                    size="xl"
                    onClick={addDocumentType}
                    icon={<AddIcon />}
                >
                    {addButtonText}
                </NmButton>
            }
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            isLoaded={isLoaded}
        >
            {getModal()}
            {
                list.length !== 0 ?
                    <CheckboxList
                        rows={getRows()}
                        sort={sort}
                        sortOptions={sortOptions}
                        onClickSort={onClickSort}
                    /> :
                    <NmEmptyPageV2
                        title="Данные отсутствуют"
                        isSearch={isSearch}
                    />
            }
        </NmPage>
    );
};

export default UpdatingDirectories;