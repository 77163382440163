import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../components/ActualComponents/Filter";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Tabs from "../../../components/ActualComponents/Tabs";
import Text from "../../../components/ActualComponents/Text";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmSelectedList from "../../../components/NmSelectedList";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as DeleteIcon} from "../../../images/delete_24.svg";
import {ReactComponent as CopyIcon} from "../../../images/file_copy_24.svg";
import {ReactComponent as InfoIcon} from "../../../images/info_24.svg";
import {ReactComponent as ModeIcon} from "../../../images/mode.svg";
import ClientRoleSettingsLogModal from "./components/client-role-settings-log-modal";
import ClientRoleSettingsModal from "./components/client-role-settings-modal";
import SettingsUsersRolesEditForm from "./components/edit-form";

import {usePagination} from "../../../hooks/usePagination";
import {useSettingsUsersRolesAction} from "./hooks/useAction";
import {useSettingsUsersRolesFetch} from "./hooks/useFetch";
import {useSettingsUsersRolesFilter} from "./hooks/useFilter";

import bem from "../../../utils/bem";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {COLOR} from "../../../constants/color";
import {LINK_SETTINGS_USERS_ROLES_LIST} from "../../../constants/links";
import {USERS_ROLES_ACTION_TYPE, USERS_ROLES_TYPE} from "../../../constants/settingsUsersRoles";

import {
    settingsUsersRolesCountsSelector,
    settingsUsersRolesListSelector,
    settingsUsersRolesProgressActionSelector,
    settingsUsersRolesProgressSelector,
    settingsUsersRolesTotalCountSelector,
    settingsUsersRolesTotalPagesSelector,
} from "../../../ducks/settingsUsersRoles";

import "./style.sass";

function SettingsUsersRoles(props) {
    const {
        match: {
            params: {
                subPage,
            },
        },
    } = props;

    const list = useSelector(settingsUsersRolesListSelector);
    const totalCount = useSelector(settingsUsersRolesTotalCountSelector);
    const totalPages = useSelector(settingsUsersRolesTotalPagesSelector);
    const progress = useSelector(settingsUsersRolesProgressSelector);
    const actionProgress = useSelector(settingsUsersRolesProgressActionSelector);
    const counts = useSelector(settingsUsersRolesCountsSelector);

    const isClientRolesPage = subPage === USERS_ROLES_TYPE.CLIENT;

    const [, element] = bem("settings-users-roles");

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        filters,
        isSearch,
        onClear,
        onSearch,
        filterData,
        initFilter,
    } = useSettingsUsersRolesFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
        restrictionDescriptions,
    } = useSettingsUsersRolesFetch({
        pageNum,
        pageSize,
        subPage,
        filterData,
    });

    const {
        toggleOpenEditForm,
        deleteAction,
        modalData,
        onCloseModal,
        onOpenModal,
    } = useSettingsUsersRolesAction({
        fetchList,
    });

    const getActions = (item) => {
        return ({
            renderCount: {
                mobile: 0,
                tablet: 3,
                desktop: 3,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <ModeIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => onOpenModal({
                            isOpenEditForm: true,
                            actionType: USERS_ROLES_ACTION_TYPE.EDIT,
                            name: item.name,
                            editData: item,
                        }),
                        asset: {
                            mobile: {
                                children: "Редактировать",
                            },
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <CopyIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => onOpenModal({
                            isOpenEditForm: true,
                            actionType: USERS_ROLES_ACTION_TYPE.COPY,
                            editData: {
                                ...item,
                                name: `Копия ${item.name}`,
                            },
                        }),
                    },
                    asset: {
                        mobile: {
                            children: "Копировать",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        icon: <DeleteIcon />,
                        onlyIcon: true,
                        size: "lg",
                        color: "grey",
                        onClick: () => {
                            deleteAction(item);
                        },
                    },
                    asset: {
                        mobile: {
                            children: "Удалить",
                        },
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "Настроить роль",
                        onClick: () => onOpenModal({
                            isOpenSettingsModal: true,
                            editData: item,
                        }),
                    },
                    visible: isClientRolesPage,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        children: "История изменения настроек",
                        onClick: () => onOpenModal({
                            isOpenSettingLogsModal: true,
                            roleId: item.id,
                            name: item.name,
                        }),
                    },
                    visible: isClientRolesPage,
                },
            ],
        });
    }
    ;

    const renderRestrictions = (restrictions) => {
        return restrictions.map((item) => {
            const {name, variable} = item || {};
            const tooltipText = restrictionDescriptions[variable] || "";

            return (
                <NmBadge
                    key={variable}
                    text={name}
                    mod="light-green"
                    rightIcon={
                        <HelpTooltip
                            info
                            classNameIcon={element("restrictions-item-icon")}
                            width={16}
                            height={16}
                            color={COLOR.PRIMARY_100}
                            children={
                                <div>
                                    <div className="flex flex-aligned-center">
                                        <InfoIcon
                                            className="me-2"
                                            width={16}
                                            height={16}
                                        />
                                        <Text level="2">
                                            {variable}
                                        </Text>
                                    </div>
                                    <Text bold>
                                        {name}
                                    </Text>
                                    {tooltipText}
                                </div>
                            }
                            outline
                            position="right-top"
                        />
                    }
                />
            );
        });
    };

    const rows = useMemo(() => {
        return list.map(item => {
            const {
                name,
                description,
                clientUserCount,
                restrictions,
                nameForClient,
                relatedClientIds,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={name}
                        classNameMainContent="col-16 col-md-14"
                        labels={[
                            {
                                label: "Описание",
                                text: description,
                            },
                            {
                                label: "Пользователей",
                                text: clientUserCount,
                            },
                            {
                                wrapped: true,
                                noWrap: false,
                                classNameText: element("restrictions-container"),
                                text: (
                                    <>
                                        <Text
                                            level="2"
                                            color={COLOR.SECONDARY_70}
                                        >
                                            Ограничения:
                                        </Text>
                                        {renderRestrictions(restrictions)}
                                    </>
                                ),
                            },
                            isClientRolesPage && {
                                label: "Название роли для компаний",
                                text: nameForClient || "-",
                            },
                            isClientRolesPage && {
                                label: "Компании",
                                columnOnMobile: true,
                                text: relatedClientIds?.length
                                    ? <NmSelectedList
                                        showListWithoutValue={true}
                                        showedItemsCount={3}
                                        highlightingFirstItem={false}
                                        list={
                                            relatedClientIds.map(({name, clientId}) => ({
                                                text: name,
                                                value: clientId,
                                                key: clientId,
                                            }))
                                        }
                                    />
                                    : "-",
                            },
                        ]}
                        mediaControls={getActions(item)}
                        isFixedActions={true}
                    />
                ),
            };
        });
    }, [list]);

    const getTabsPanes = () => {
        return ([
            {
                count: counts[USERS_ROLES_TYPE.NAIMIX] || 0,
                name: "Роли Наймикс",
                link: LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.NAIMIX),
                active: subPage === USERS_ROLES_TYPE.NAIMIX,
            },
            {
                count: counts[USERS_ROLES_TYPE.CLIENT] || 0,
                name: "Роли компании",
                link: LINK_SETTINGS_USERS_ROLES_LIST.replace(":subPage", USERS_ROLES_TYPE.CLIENT),
                active: subPage === USERS_ROLES_TYPE.CLIENT,
            },
        ]);
    };

    const renderConfirmWindow = () => {
        return (
            modalData?.isOpenConfirm &&
            <NmConfirmV2
                content={modalData.content}
                onConfirm={modalData.onConfirm}
                onCancel={onCloseModal}
                confirmButton="Продолжить"
                cancelButton="Отмена"
                disabled={actionProgress}
                loading={actionProgress}
                isOnlyConfirm={true}
            />
        );
    };

    return (
        <NmPage
            noPadding
            header={
                <Tabs
                    secondary
                    className="flex-grow-1"
                    panes={getTabsPanes()}
                />
            }
            controls={
                <NmButton
                    size="xl"
                    onClick={() => onOpenModal({
                        isOpenEditForm: true,
                    })}
                    icon={<AddIcon />}
                >
                    Добавить роль
                </NmButton>
            }
            typeFilter={isClientRolesPage && "vertical"}
            filtersBase={
                isClientRolesPage &&
                <Filter
                    initState={initFilter}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            isLoaded={progress}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
            isEmptyPage={isEmpty(list)}
            emptyPageProps={{
                fetchProgress: progress,
                isSearch: isSearch,
            }}
            modals={
                <>
                    {renderConfirmWindow()}
                    {
                        modalData?.isOpenEditForm &&
                        <SettingsUsersRolesEditForm
                            card={modalData.editData}
                            subPage={subPage}
                            restrictionDescriptions={restrictionDescriptions}
                            fetchList={fetchList}
                            onClose={onCloseModal}
                            isEdit={modalData.actionType === USERS_ROLES_ACTION_TYPE.EDIT}
                        />
                    }
                    {
                        modalData?.isOpenSettingsModal &&
                        <ClientRoleSettingsModal
                            editData={modalData?.editData}
                            onClose={onCloseModal}
                            fetchList={fetchList}
                        />
                    }
                    {
                        modalData?.isOpenSettingLogsModal &&
                        <ClientRoleSettingsLogModal
                            roleId={modalData?.roleId}
                            name={modalData?.name}
                            onClose={onCloseModal}
                        />
                    }
                </>
            }
        >
            <CheckboxList
                rows={rows}
            />
        </NmPage>
    );
}

export default SettingsUsersRoles;
