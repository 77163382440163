import React, {useMemo} from "react";
import {useSelector} from "react-redux";

import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../../../components/ActualComponents/NmShowMoreText";
import Text from "../../../../components/ActualComponents/Text";
import ExtLink from "../../../../components/ExtLink";
import RegistryPaymentError from "../../../../components/RegistryPaymentError";

import {useContractorIncomePaymentCardAction} from "./hooks/useAction";

import dateFormat, {convertUtcToLocal, formatLocalDate} from "../../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../../utils/localstorage";
import {formatAmountWithNullChecking} from "../../../../utils/stringFormat";
import {getClientNameWithBrand} from "../../../../utils/stringHelper";
import {getRegistryPaymentStatus} from "../../../registries/registry-card/utils/getStatus";

import {COLOR} from "../../../../constants/color";
import {
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD,
    LINK_CLIENT_INFO,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_FINANCE_CROWD_PAYMENT_LIST,
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST,
    LINK_FINANCE_NDFL_PAYMENT_LIST,
    LINK_FINANCE_PAYMENT_LIST,
    LINK_ORDER_CARD,
} from "../../../../constants/links";
import {REGISTRY_PAYMENTS_STATUS_DICT} from "../../../../constants/registry";
import {isClientUser} from "../../../../constants/roles";

import {registryPaymentStatusesDictSelector} from "../../../../ducks/registryPaymentStatuses";

import {
    SELF_CONTRACTOR,
    SUB_PAGE_CROWD_TASK_CARD,
    SUB_PAGE_ORDER_CONTRACTOR,
} from "../../../../constants/link-params";

const ContractorFinanceIncomePaymentListCard = (props) => {
    const {
        isNdfl,
        isIndividual,
        isCrowd,
        isRegistryPaymentsPage,
        payment,
        isShowActions,
    } = props;

    const paymentStatuses = useSelector(registryPaymentStatusesDictSelector);

    const role = ls(USER_ROLE);
    const isClientRole = isClientUser(role);

    const {
        paymentNumber,
        status,
        clientId,
        clientName,
        brand,
        clientOrBrandName,
        registryId,
        registryNumber,
        registrySeqNum,
        registryName,
        orderNumber,
        orderId,
        taskNumber,
        taskName,
        taskId,
    } = payment;

    const {
        getMediaControls,
    } = useContractorIncomePaymentCardAction({
        payment,
        isShowActions,
    });

    const getPaymentLink = () => {
        if (isIndividual) {
            return LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST;
        }

        if (isNdfl) {
            return LINK_FINANCE_NDFL_PAYMENT_LIST;
        }

        if (isCrowd) {
            return LINK_FINANCE_CROWD_PAYMENT_LIST.replace(":paymentNumberFilter?", "");
        }

        return LINK_FINANCE_PAYMENT_LIST;
    };

    const isShowPaymentLabels = !isClientRole && !isCrowd;

    const getPaymentNumber = () => {
        return (
            <ExtLink
                historyEnabled={true}
                isLoadDataTarget={true}
                filterData={{
                    paymentNumber,
                }}
                to={getPaymentLink()}
                title={paymentNumber}
            >
                {paymentNumber}
            </ExtLink>
        );
    };

    const getPaymentStatusErrorMessage = () => {
        if (!payment.message && status === REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE) {
            return REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.MESSAGE;
        }

        if (payment.message && status === REGISTRY_PAYMENTS_STATUS_DICT.AWAITING_SIGNATURE.VALUE) {
            return "";
        }

        return payment.message;
    };

    const renderPaymentStatus = () => {
        if (!status) {
            return "-";
        }

        const statusText = getRegistryPaymentStatus({
            paymentStatuses,
            status,
        });

        return (
            <RegistryPaymentError
                color={REGISTRY_PAYMENTS_STATUS_DICT[status]?.COLOR}
                statusText={statusText}
                error={getPaymentStatusErrorMessage()}
            />
        );
    };

    const getClient = () => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {
                    clientOrBrandName
                        ? clientOrBrandName
                        : getClientNameWithBrand(clientName, brand)
                }
            </ExtLink>
        );
    };

    const getOrder = () => {
        if (isRegistryPaymentsPage && !payment.orderNum) {
            return "-";
        }

        const orderLink = LINK_ORDER_CARD
            .replace(":clientId", clientId)
            .replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage",
                isRegistryPaymentsPage
                    ? SUB_PAGE_ORDER_CONTRACTOR.HIRED.LINK
                    : SUB_PAGE_ORDER_CONTRACTOR.RESPONSES.LINK,
            );

        return (
            <ExtLink
                to={orderLink}
                historyEnabled
            >
                {
                    isRegistryPaymentsPage
                        ? `№${payment.orderNum} - ${payment.orderName}`
                        : orderNumber
                }
            </ExtLink>
        );
    };

    const getCrowdTask = () => {
        const taskLink = LINK_CLIENT_CROWD_TASK_REGISTRY_CARD
            .replace(":taskId", taskId)
            .replace(":clientId", clientId)
            .replace(":subpage", SUB_PAGE_CROWD_TASK_CARD.INVITED.LINK);

        return (
            <ExtLink
                to={taskLink}
                historyEnabled
            >
                {`${taskNumber} ${taskName}`}
            </ExtLink>
        );
    };

    const getRegistry = () => {
        const registryLink = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
            .replace(":paymentNumberFilter?", registryNumber)
            .replace(":archived", "false")
            .replace(":clientId", clientId)
            .replace(":registryId", registryId);

        return (
            <ExtLink
                to={registryLink}
                historyEnabled
            >
                {
                    isRegistryPaymentsPage
                        ? registryName
                        : `Оплата по реестру № ${registryNumber}, номер строки: ${registrySeqNum}`
                }
            </ExtLink>
        );
    };

    const labels = useMemo(() => {
        if (isRegistryPaymentsPage) {
            return [
                {
                    label: "Номер и наим. заказа",
                    text: getOrder(),
                },
                {
                    label: "Строка",
                    text: payment.registrySeqNum,
                },
                (
                    payment.message
                    && [
                        REGISTRY_PAYMENTS_STATUS_DICT.ERROR.VALUE,
                        REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED.VALUE,
                        REGISTRY_PAYMENTS_STATUS_DICT.NM_ADMIN_DECLINED.VALUE,
                        REGISTRY_PAYMENTS_STATUS_DICT.CANCELED_BY_ADMIN.VALUE,
                        REGISTRY_PAYMENTS_STATUS_DICT.DECLINED.VALUE,
                        REGISTRY_PAYMENTS_STATUS_DICT.CONTRACTOR_DECLINED_CONTRACT_SIGNING.VALUE,
                    ].includes(status)
                ) && {
                    label: "Причина ошибки",
                    text: <NmShowMoreText
                        lines={1}
                        more="Подробнее"
                        anchor="blue"
                    >
                        {payment.message}
                    </NmShowMoreText>,
                },
                {
                    label: "Заказчик",
                    text: getClient(),
                },
            ];
        }

        return [
            {
                text: <Text
                    level="3"
                    color={COLOR.SECONDARY_100}
                >
                    {payment.description}
                </Text>,
                noWrap: false,
            },
            isShowPaymentLabels && {
                label: "Способ оплаты",
                text: `${payment.bankType?.description || "-"} / ${payment.paymentPath?.description || "-"}`,
            },
            isShowPaymentLabels && {
                label: "Местонахождение платежа",
                text: payment.paymentLocation?.description || "-",
            },
            {label: "Заказчик", text: getClient()},
            payment.orderId && {label: "Заказ", text: getOrder()},
            payment.taskId && {label: "Задание", text: getCrowdTask()},
            payment.registryId && {label: "Реестр оплат", text: getRegistry()},
        ];
    }, [payment]);

    const cards = useMemo(() => {
        if (isRegistryPaymentsPage) {
            return [
                {
                    title: "Сумма по договору, ₽",
                    value: formatAmountWithNullChecking(
                        isNdfl
                            ? payment.civilContractAmount
                            : payment.orderAmount,
                    ),
                    className: "col-16 col-md-6 mt-md-4 mt-xxl-0",
                },
                {
                    title: "Статус и номер оплаты",
                    subTitle: renderPaymentStatus(),
                    value: paymentNumber && getPaymentNumber(),
                    className: "col-16 col-md-6 mt-md-4 mt-xxl-0",
                    wrapValue: true,
                },
            ];
        }

        return [
            {
                title: "Сумма оплаты, ₽",
                value: formatAmountWithNullChecking(isCrowd ? payment.amount : payment.sumOfMoney),
                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
            },
            !isCrowd && {
                title: isIndividual ? "Сумма на р/с, ₽" : "Сумма на карту, ₽",
                value: formatAmountWithNullChecking(payment.smzAmount),
                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
            },
            !isCrowd && {
                title: "Комиссия исполнителя, ₽",
                value: formatAmountWithNullChecking(payment.platformAmount),
                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
            },
            !isNdfl && !isIndividual && !isCrowd && {
                title: "Налог исполнителя, ₽",
                value: formatAmountWithNullChecking(payment.taxAmount),
                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
            },
            isCrowd && {
                title: "Комиссия заказчика, ₽",
                value: formatAmountWithNullChecking(payment.clientCommission),
                className: "col-16 col-md-4 col-xxl-4 mt-md-4 mt-xxl-0",
            },
        ];
    }, [
        payment,
        paymentStatuses,
    ]);

    return (
        <NmListCard
            classNameMainContent={isNdfl || isIndividual ? "col-16 col-xxl-9" : "col-16 col-xxl-7"}
            secondaryHeader={
                isRegistryPaymentsPage
                    ? `Период работ: ${formatLocalDate(payment.orderWorkStartDate, "dd.MM.yy")} - ${formatLocalDate(payment.orderWorkEndDate, "dd.MM.yy")}`
                    : `Дата операции: ${dateFormat(convertUtcToLocal(payment.operationDate || payment.updateDate))}`}
            primaryHeader={
                isRegistryPaymentsPage
                    ? getRegistry()
                    : getPaymentNumber()
            }
            labels={labels}
            cards={cards}
            cardsWithContainer={true}
            cardsContainerClassName={`col-16 ${isNdfl || isIndividual ? "col-16 col-xxl-6" : "col-16 col-xxl-8"} align-items-end`}
            actionsClassName="col-1"
            mediaControls={getMediaControls()}
        />
    );
};

export default ContractorFinanceIncomePaymentListCard;