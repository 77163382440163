import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

import {REGISTRY_TOAST_TEXT} from "../constants/registry";

const SMS_INVITATION_SEND_REQUEST = "SMS_INVITATION_SEND_REQUEST";
const SMS_INVITATION_SEND_SUCCESS = "SMS_INVITATION_SEND_SUCCESS";
const SMS_INVITATION_SEND_ERROR = "SMS_INVITATION_SEND_ERROR";

const initial = {
    invitationProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case SMS_INVITATION_SEND_REQUEST:
            return {
                ...state,
                invitationProgress: true,
            };
        case SMS_INVITATION_SEND_SUCCESS:
            return {
                ...state,
                invitationProgress: false,
            };
        case SMS_INVITATION_SEND_ERROR:
            return {
                ...state,
                invitationProgress: false,
                error: payload,
            };
        default:
            return state;
    }
};

export const inviteViaSms = payload => {
    return {
        type: SMS_INVITATION_SEND_REQUEST,
        payload,
    };
};

export const smsSelector = state => state.sms;
export const smsInvitationProgressSelector = createSelector(smsSelector, ({invitationProgress}) => invitationProgress);

const inviteViaSmsSaga = function* (action) {
    try {
        const {payload: {
            data,
            onSuccess,
        }} = action;

        const url = getBffControllerClientCardPage({
            admin: "/adm/common/notification/sms/invitation",
            client: "/client-adm/common/notification/sms/invitation",
        });

        const {errorMessage} = yield request.bff.post(url, data);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SMS_INVITATION_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess(REGISTRY_TOAST_TEXT.SUCCESS_INVITE_VIA_EMAIL);
        yield put({type: SMS_INVITATION_SEND_SUCCESS});
    } catch (error) {
        yield put({type: SMS_INVITATION_SEND_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(SMS_INVITATION_SEND_REQUEST, inviteViaSmsSaga),
    ]);
}