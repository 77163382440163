import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    ADD_DOCUMENTS_EDO_REGISTRIES_ERROR,
    ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CHECK_DUPLICATES_REGISTRY_ERROR,
    CHECK_DUPLICATES_REGISTRY_REQUEST,
    CHECK_DUPLICATES_REGISTRY_SUCCESS,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    CONFIRM_DUPLICATES_REGISTRY_ERROR,
    CONFIRM_DUPLICATES_REGISTRY_REQUEST,
    CONFIRM_DUPLICATES_REGISTRY_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST,
    DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST,
    GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR,
    SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST,
    UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage, getBffUrl} from "../../../../../utils/url";

import {FINANCE_EXPORT_MESSAGES} from "../../../../../constants/messages";

const getController = () => {
    const cardController = getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/documents-registry/documents-registry-card",
        client: "/client-adm/edo/documents-registry/documents-registry-card",
    });

    return (
        getBffUrl({
            ["/card"]: cardController,
            ["/add"]: cardController,
            isClientCard: true,
            adminRolesUrl: "/adm/clients/client-card/edo/documents-registry",
            clientRolesUrl: "/client-adm/edo/documents-registry",
        },
        null,
        true,
        )
    );
};

const getDocumentsEdoRegistriesPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/richPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_PAGE_ERROR,
            payload: error.message,
        });
    }
};

const getByIdDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${clientId}/${registryId}/rich`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_ERROR,
            payload: error.message,
        });
    }
};

const addDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }


        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ADD_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const updateDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            formData,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, formData, {...getMultipartHeaders()});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const archiveDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.patch(`${getController()}/archive`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: ARCHIVE_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const checkContractorsExistsDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult,
        } = payload;

        const result = yield request.bff.get(`${getController()}/${clientId}/${registryId}/check/contractorsExists`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const sendSmsDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
        } = payload;

        const result = yield request.bff.post(`${getController()}/sendSms`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);
        yield put({
            type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SEND_SMS_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const signDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/sign`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: SIGN_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({type: SIGN_DOCUMENTS_EDO_REGISTRIES_ERROR, payload: error.message});
    }
};

const getDocumentsEdoRegistriesAttachmentsFileSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            fileName,
            getResult,
        } = payload;

        const url = `${getController()}/file?clientId=${clientId}&registryId=${registryId}`;
        const response = yield request.bff.getFile(url, {params: {clientId, registryId}});

        const blob = yield response.blob();

        const documentFile = new File([blob], fileName, {type: blob.type});

        if (getResult) {
            getResult(documentFile);
        }

        yield put({type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_SUCCESS});
    } catch (error) {
        toastError("Не удалось получить файл");
        yield put({
            type: GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_ERROR,
            payload: error,
        });
    }
};

const exportDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/export`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(FINANCE_EXPORT_MESSAGES.ADD_TASK_EXPORT_EDM_REGISTRY_SUCCESS);

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: EXPORT_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EXPORT_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const checkDuplicatesRegistrySaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.bff.get(`${getController()}/${clientId}/${registryId}/check/duplicates/registry`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_DUPLICATES_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_DUPLICATES_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_DUPLICATES_REGISTRY_ERROR,
            payload: error.message,
        });
    }
};

const confirmDuplicatesRegistrySaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/check/duplicates/registry/confirm`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CONFIRM_DUPLICATES_REGISTRY_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONFIRM_DUPLICATES_REGISTRY_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONFIRM_DUPLICATES_REGISTRY_ERROR,
            payload: error.message,
        });
    }
};

const checkDuplicatesDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            clientId,
            registryId,
            getResult = () => {
            },
        } = payload;

        const result = yield request.bff.get(`${getController()}/${clientId}/${registryId}/check/duplicates`);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        getResult(result);

        yield put({
            type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

const confirmDuplicatesDocumentsEdoRegistriesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/check/duplicates/confirm`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                ype: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(DOCUMENTS_EDO_REGISTRIES_GET_PAGE_REQUEST, getDocumentsEdoRegistriesPageSaga),
        takeEvery(DOCUMENTS_EDO_REGISTRIES_GET_BY_ID_REQUEST, getByIdDocumentsEdoRegistriesSaga),
        takeEvery(ADD_DOCUMENTS_EDO_REGISTRIES_REQUEST, addDocumentsEdoRegistriesSaga),
        takeEvery(UPDATE_DOCUMENTS_EDO_REGISTRIES_REQUEST, updateDocumentsEdoRegistriesSaga),
        takeEvery(ARCHIVE_DOCUMENTS_EDO_REGISTRIES_REQUEST, archiveDocumentsEdoRegistriesSaga),
        takeEvery(CHECK_CONTRACTORS_EXISTS_DOCUMENTS_EDO_REGISTRIES_REQUEST, checkContractorsExistsDocumentsEdoRegistriesSaga),
        takeEvery(SEND_SMS_DOCUMENTS_EDO_REGISTRIES_REQUEST, sendSmsDocumentsEdoRegistriesSaga),
        takeEvery(SIGN_DOCUMENTS_EDO_REGISTRIES_REQUEST, signDocumentsEdoRegistriesSaga),
        takeEvery(GET_DOCUMENTS_EDO_REGISTRIES_ATTACHMENTS_FILE_REQUEST, getDocumentsEdoRegistriesAttachmentsFileSaga),
        takeEvery(EXPORT_DOCUMENTS_EDO_REGISTRIES_REQUEST, exportDocumentsEdoRegistriesSaga),
        takeEvery(CHECK_DUPLICATES_REGISTRY_REQUEST, checkDuplicatesRegistrySaga),
        takeEvery(CONFIRM_DUPLICATES_REGISTRY_REQUEST, confirmDuplicatesRegistrySaga),
        takeEvery(CHECK_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST, checkDuplicatesDocumentsEdoRegistriesSaga),
        takeEvery(CONFIRM_DUPLICATES_DOCUMENTS_EDO_REGISTRIES_REQUEST, confirmDuplicatesDocumentsEdoRegistriesSaga),
    ]);
}