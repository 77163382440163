import {useState} from "react";
import {useDispatch} from "react-redux";

import {REGISTRY_INVITATIONS_ACTIONS} from "../index";

import {
    archivedRegistryInvitations,
    deleteRegistryInvitations,
} from "../../../../../ducks/bff/orders/order-invitations-registry/actionCreators";

const initialStateEditModal = {
    isOpen: false,
    isEdit: false,
};

const initialStateConfirmModal = {
    isOpen: false,
};

function useRegistryInvitationsAction({fetchList}) {
    const [editModal, setEditModal] = useState(initialStateEditModal);
    const [confirmAction, setConfirmAction] = useState(initialStateConfirmModal);

    const dispatch = useDispatch();

    const openEditModal = ({isEdit, card}) => {
        setEditModal({
            isOpen: true,
            isEdit,
            card,
        });
    };

    const closeEditModal = () => {
        setEditModal(initialStateConfirmModal);
    };

    const closeConfirmModal = () => {
        setConfirmAction(initialStateEditModal);
    };

    const unarchiveAction = ({clientId, registryInvitationId}) => {
        setConfirmAction({
            isOpen: true,
            content: "Вы уверены, что хотите перенести реестр приглашений из архива в основной список?",
            onConfirm: () => {
                dispatch(archivedRegistryInvitations({
                    clientId,
                    registryInvitationId,
                    archived: false,
                    onSuccess: () => {
                        fetchList();
                        setConfirmAction(initialStateEditModal);
                    },
                }));
            },
        });
    };

    const onClickActionItem = (option, item) => {
        const {
            clientId,
            registryInvitationId,
        } = item;

        switch (option.value) {
            case REGISTRY_INVITATIONS_ACTIONS.EDIT:
                openEditModal({isEdit: true, card: item});

                return;
            case REGISTRY_INVITATIONS_ACTIONS.ARCHIVE:
                setConfirmAction({
                    isOpen: true,
                    content: "Вы уверены, что хотите перенести реестр приглашений в архив?",
                    onConfirm: () => {
                        dispatch(archivedRegistryInvitations({
                            clientId,
                            registryInvitationId,
                            archived: true,
                            onSuccess: () => {
                                fetchList();
                                setConfirmAction(initialStateEditModal);
                            },
                        }));
                    },
                });

                return;
            case REGISTRY_INVITATIONS_ACTIONS.DELETE:
                setConfirmAction({
                    isOpen: true,
                    content: "Вы уверены, что хотите удалить реестр приглашений?",
                    onConfirm: () => {
                        dispatch(deleteRegistryInvitations({
                            clientId,
                            registryInvitationId,
                            onSuccess: () => {
                                fetchList();
                                setConfirmAction(initialStateEditModal);
                            },
                        }));
                    },
                });

                return;

            // no default
        }
    };

    return {
        editModal,
        setEditModal,
        openEditModal,
        closeEditModal,
        confirmAction,
        closeConfirmModal,
        onClickActionItem,
        unarchiveAction,
    };
}

export default useRegistryInvitationsAction;