import {
    BFF_CLIENT_MEMBER_ADD_ERROR,
    BFF_CLIENT_MEMBER_ADD_REQUEST,
    BFF_CLIENT_MEMBER_ADD_SUCCESS,
    BFF_CLIENT_MEMBER_UPDATE_ERROR,
    BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE,
    BFF_CLIENT_MEMBER_UPDATE_REQUEST,
    BFF_CLIENT_MEMBER_UPDATE_SUCCESS,
    BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR,
    BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST,
    BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS,
    BFF_GET_CLIENT_MEMBER_CARD_ERROR,
    BFF_GET_CLIENT_MEMBER_CARD_REQUEST,
    BFF_GET_CLIENT_MEMBER_CARD_SUCCESS,
    BFF_GET_CLIENT_MEMBER_LIST_ERROR,
    BFF_GET_CLIENT_MEMBER_LIST_REQUEST,
    BFF_GET_CLIENT_MEMBER_LIST_SUCCESS,
} from "./actions";

const initialState = {
    list: [],
    pageData: {},
    card: {},
    progress: false,
    usersForFilterList: [],
    progressList: false,
    progressGetUsers: false,
    progressAdd: false,
    isSuccessAdd: false,
    progressUpdate: false,
    isSuccessUpdate: false,
    projectManagers: [],
    foremanList: [],
    adminMembers: [],
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case BFF_GET_CLIENT_MEMBER_LIST_REQUEST:
            return {
                ...state,
                progressList: true,
                progressGetUsers: true,
                pageData: payload,
            };
        case BFF_GET_CLIENT_MEMBER_LIST_SUCCESS:
            const {
                clientUsers = [],
                totalCount = 0,
                fieldName = "list",
            } = payload;

            return {
                ...state,
                progressList: false,
                progressGetUsers: false,
                usersForFilterList: clientUsers,
                [fieldName]: clientUsers,
                totalCount,
            };
        case BFF_GET_CLIENT_MEMBER_LIST_ERROR:
            return {
                ...state,
                progressGetUsers: false,
                progressList: false,
                error: payload,
            };
        case BFF_CLIENT_MEMBER_ADD_REQUEST:
            return {
                ...state,
                progressAdd: true,
            };
        case BFF_CLIENT_MEMBER_ADD_SUCCESS:
            return {
                ...state,
                progressAdd: false,
                isSuccessAdd: true,
            };
        case BFF_CLIENT_MEMBER_ADD_ERROR:
            return {
                ...state,
                progressAdd: false,
                isSuccessAdd: false,
                error: payload,
            };
        case BFF_CLIENT_MEMBER_UPDATE_REQUEST:
        case BFF_GENERATE_PASSWORD_CLIENT_USER_REQUEST:
            return {
                ...state,
                progressUpdate: true,
            };
        case BFF_CLIENT_MEMBER_UPDATE_SUCCESS:
        case BFF_GENERATE_PASSWORD_CLIENT_USER_SUCCESS:
            return {
                ...state,
                progressUpdate: false,
                isSuccessUpdate: true,
            };
        case BFF_CLIENT_MEMBER_UPDATE_ERROR:
        case BFF_GENERATE_PASSWORD_CLIENT_USER_ERROR:
            return {
                ...state,
                progressUpdate: false,
                isSuccessUpdate: false,
                error: payload,
            };
        case BFF_GET_CLIENT_MEMBER_CARD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case BFF_GET_CLIENT_MEMBER_CARD_SUCCESS:
            return {
                ...state,
                progress: false,
                card: payload,
            };
        case BFF_GET_CLIENT_MEMBER_CARD_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case BFF_CLIENT_MEMBER_UPDATE_FIELD_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};