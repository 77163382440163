import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST,
    BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS,
} from "./actions";

import request from "../../../../../utils/postman";
import {toastError} from "../../../../../utils/toastHelper";
import {getBffUrl} from "../../../../../utils/url";

const getController = () => {
    return getBffUrl({
        isClientCard: true,
        clientRolesUrl: "/client-adm/recruitment/access-control/vacancy-personal-access",
        adminRolesUrl: "/adm/clients/client-card/recruitment/access-control/vacancy-personal-access",
    });
};

// POST /bff/adm/recruitment/access-control/vacancy-personal-access
// Добавить персональный доступ к вакансии
const addRecruitmentVacancyPersonalAccess = function* ({payload}) {
    const {
        onSuccess,
        ...reqData
    } = payload;

    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/vacancy-personal-access/search
// Получить список наблюдателей
const getRecruitmentVacancyPersonalAccessUsers = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}/search`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_ERROR,
        });
    }
};

// POST /bff/adm/recruitment/access-control/vacancy-personal-access/get-available-users
// Получить список пользователей для добавления предоставления персонального доступа к вакансии
const getRecruitmentVacancyPersonalAccessAvailableUsers = function* ({payload}) {
    const controller = getController();

    try {
        const result = yield request.bff.post(`${controller}/get-available-users`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_ADD_REQUEST, addRecruitmentVacancyPersonalAccess),
        takeEvery(BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_SEARCH_REQUEST, getRecruitmentVacancyPersonalAccessUsers),
        takeEvery(BFF_RECRUITMENT_VACANCY_PERSONAL_ACCESS_GET_AVAILABLE_USERS_REQUEST, getRecruitmentVacancyPersonalAccessAvailableUsers),
    ]);
}