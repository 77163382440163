import {all, put, takeEvery} from "@redux-saga/core/effects";

import {getStatementsInfoCount} from "./actionCreators";
import {
    CHECK_STATEMENT_FILE_REQUEST,
    DOWNLOAD_EDO_EXPORT_FILES_ERROR,
    DOWNLOAD_EDO_EXPORT_FILES_REQUEST,
    DOWNLOAD_EDO_EXPORT_FILES_SUCCESS,
    GET_EDO_COUNTS_BY_STAFF_ID_REQUEST,
    GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS,
    GET_EDO_EXPORT_LIST_ERROR,
    GET_EDO_EXPORT_LIST_REQUEST,
    GET_EDO_EXPORT_LIST_SUCCESS,
    GET_EDO_EXPORT_STATUSES_REQUEST,
    GET_EDO_EXPORT_STATUSES_SUCCESS,
    GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST,
    GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST,
    GET_STATEMENTS_ARCHIVE_REQUEST,
    GET_STATEMENTS_INFO_COUNT_ERROR,
    GET_STATEMENTS_INFO_COUNT_REQUEST,
    GET_STATEMENTS_INFO_COUNT_SUCCESS,
    GET_STATEMENTS_PAGE_REQUEST,
    GET_STATEMENTS_PAGE_SUCCESS,
    GET_STATEMENTS_SOURCE_FILE_REQUEST,
    GET_STATEMENTS_TYPES_PAGE_REQUEST,
    GET_STATEMENTS_TYPES_PAGE_SUCCESS,
    REJECT_STATEMENT_REQUEST,
    REPLACE_EDO_STATEMENT_REQUEST,
    REVIEW_STATEMENT_REQUEST,
    SEND_STATEMENT_VERIFICATION_CODE_REQUEST,
    SIGN_STATEMENT_REQUEST,
    TASK_EXPORT_EDO_DOCUMENT_REQUEST,
    TASK_EXPORT_EDO_DOCUMENT_SUCCESS,
    UPLOAD_EDO_STATEMENT_REQUEST,
} from "./actions";
import {STATEMENT_FILE_TYPE} from "./reducer";

import {downloadBlob, downloadByUrl} from "../../../../../utils/downloadBlob";
import {ACCESS_TOKEN_KEY, ls} from "../../../../../utils/localstorage";
import request from "../../../../../utils/postman";
import {getMultipartHeaders} from "../../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/statements",
        client: "/client-adm/edo/statements",
    });
};

const getExportController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/export",
        client: "/client-adm/edo/export",
    });
};

const getStatementsPageSaga = function* ({payload}) {
    try {
        const {getResult} = payload;
        const result = yield request.bff.post(`${getController()}/info/page`, payload);

        if (getResult) {
            getResult(result);
        }

        yield put({
            type: GET_STATEMENTS_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error(error.message);
    }
};

const getStatementTypesPageSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/types/page`, payload);

        yield put({
            type: GET_STATEMENTS_TYPES_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error(error.message);
    }
};

const archiveStatementSaga = function* ({payload}) {
    try {
        const {
            getResult,
            archived,
            statementId,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(`${getController()}/${archived ? "archive" : "unArchive"}/${statementId}`);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield toastSuccess(`Заявление успешно ${archived ? "архивировано" : "восстановлено из архива"}`);
    } catch (error) {
        console.error("Method: archiveStatementSaga: ", error);
    }
};

const getStatementsSourceFileSaga = function* ({payload}) {
    try {
        const {
            statementId,
            clientId,
            fileType = STATEMENT_FILE_TYPE.SOURCE_FILE,
            getResult,
            hasDownload = true,
        } = payload;

        const response = yield request.bff.getFile(`${getController()}/info/statement/${fileType}?statementId=${statementId}&clientId=${clientId}`);

        const {headers} = response;

        if (response.status === 200 && response.headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(data === "FILE_NOT_FOUND" ? "Файл не существует" : data || errorMessage);

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        getResult(blob);

        if (hasDownload) {
            yield downloadBlob(blob, headers);
        }
    } catch (error) {
        console.error("Method: getStatementsSourceFileSaga: ", error);
    }
};

const getStatementsCountSaga = function* () {
    try {
        const url = getBffControllerClientCardPage({
            admin: "/adm/common/document/documents/edo/info/statement/count",
            client: "/client-adm/common/document/documents/edo/info/statement/count",
        });

        const result = yield request.bff.get(url);
        const {errorMessage} = result;

        if (errorMessage) {
            yield put({type: GET_STATEMENTS_INFO_COUNT_ERROR, payload: errorMessage});

            return {
                done: true,
            };
        }

        yield put({type: GET_STATEMENTS_INFO_COUNT_SUCCESS, payload: result});
    } catch (error) {
        console.error("Method: getStatementsCountSaga: ", error);
    }
};

const uploadEdoStatementSaga = function* ({payload}) {
    try {
        const {
            hasTemplate = false,
            getResult,
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.post(`${getController()}${hasTemplate ? "/template" : ""}/upload`, formData, {...getMultipartHeaders()});

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

const sendStatementCodeVerificationSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...params
        } = payload;

        const result = yield request.bff.get(`${getController()}/verification`, {params});

        if (getResult) {
            getResult(result);
        }

        const {errorMessage} = result;

        if (errorMessage) {
            return {
                done: true,
            };
        }

        yield toastSuccess("Код успешно отправлен");
    } catch (error) {
        console.error("Method: sendStatementCodeVerificationSaga: ", error);
    }
};

const replaceEdoStatementtSaga = function* ({payload}) {
    try {
        const {
            getResult,
            hasTemplate = false,
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.post(
            `${getController()}${hasTemplate ? "/template" : ""}/statement/replace`, formData, {...getMultipartHeaders()});

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

const signEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult,
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(
            `${getController()}/statement/sign`
            , data);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield toastSuccess("Заявление успешно подписано");
    } catch (error) {
        console.error("Method: getPageStaffSaga: ", error);
    }
};

const rejectEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult,
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(
            `${getController()}/statement/reject`
            , data);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {

            return {
                done: true,
            };
        }

        yield put(getStatementsInfoCount());
        yield toastSuccess("Заявление успешно отклонено");
    } catch (error) {
        console.error("Method: rejectEdoStatementSaga: ", error);
    }
};

const reviewEdoStatementSaga = function* ({payload}) {
    try {
        const {
            getResult,
            data,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.patch(
            `${getController()}/statement/review`
            , data);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put(getStatementsInfoCount());
        yield toastSuccess("Заявление успешно рассмотрено");
    } catch (error) {
        console.error("Method: reviewEdoStatementSaga: ", error);
    }
};

const getCountsEdoByStaffIdSaga = function* ({payload}) {
    try {
        const {
            staffId,
            clientId,
        } = payload;

        const {
            errorMessage,
            ...result
        } = yield request.bff.get(
            `edo/info/${clientId}/counts/${staffId}`,
        );

        if (errorMessage) {
            return {
                done: true,
            };
        }

        yield put({type: GET_EDO_COUNTS_BY_STAFF_ID_SUCCESS, payload: result});

    } catch (error) {
        console.error("Method: getCountsEdoByStaffIdSaga: ", error);
    }
};

const getEdoExportStatusesSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.get(
            `${getExportController()}/getProcessState`,
            {params: {exportIds: data}})
        ;

        yield put({
            type: GET_EDO_EXPORT_STATUSES_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        console.error("Method: getCountsEdoByStaffIdSaga: ", error);
    }
};

//POST bff/adm/clients/client-card/edo/statements/checkFile
//POST bff/client-adm/edo/statements/checkFile
const checkStatementFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            formData,
        } = payload;

        const {
            errorMessage,
        } = yield request.bff.post(`${getController()}/checkFile`, formData, {...getMultipartHeaders()},
        );

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            return {
                done: true,
            };
        }

        yield toastSuccess("Файл успешно проверен");
    } catch (error) {
        console.error("Method: checkStatementFileSaga: ", error);
    }
};

const getStatementTemplateEmptyFileSaga = function* ({payload}) {
    try {
        const {
            type,
        } = payload;

        const result = yield request.bff.getFile(
            `${getController()}/template/statement/empty?type=${type}`,
        );

        if (result.errorMessage) {
            toastError(result.errorMessage);

            return {
                done: true,
            };
        }

        const blob = yield result.blob();
        yield downloadBlob(blob, result.headers);
    } catch (error) {
        console.error("Method: getStatementTemplateEmptyFileSaga: ", error);
    }
};

const getStatementsTemplatePreFilledFileSaga = function* ({payload}) {
    try {
        const {
            getResult,
            ...data
        } = payload;

        const response = yield request.bff.getFile(
            `${getController()}/template/statement/pre-filled`
            , {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    Authorization:
                        `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                    ,
                    "Content-Type": "application/json",
                },
            });

        const {
            headers,
            status,
        } = response;

        if (status === 200 && headers.get("Content-Type").includes("json")) {
            const data = yield response.json();

            if (data) {
                const {errorMessage} = data;

                toastError(
                    data === "FILE_NOT_FOUND"
                        ? "Файл не существует"
                        : errorMessage,
                );

                return {
                    done: true,
                };
            }
        }

        const blob = yield response.blob();

        yield getResult(blob);
    } catch (error) {
        console.error("Method: getStatementsTemplatePreFilledFileSaga: ", error.message);
    }
};

const taskEdoExportDocumentSaga = function* ({payload}) {
    try {
        const {
            getResult,
            resourceUrl = "documents",
            ...data
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/edo",
            client: "/client-adm/edo",
        });

        const {
            errorMessage,
        } = yield request.bff.post(`${url}/${resourceUrl}/export`, data);

        if (getResult) {
            getResult(errorMessage);
        }

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        yield put({
            type: TASK_EXPORT_EDO_DOCUMENT_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        console.error("Method: taskEdoExportDocumentSaga: ", error);
    }
};

const getEdoExportListSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getExportController()}/getPage`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_EDO_EXPORT_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_EDO_EXPORT_LIST_SUCCESS,
            payload: result,
        });

        onSuccess && onSuccess();
    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({
            type: GET_EDO_EXPORT_LIST_ERROR,
            payload: error.message,
        });
    }
};

const downloadEdoExportFilesSaga = function* ({payload}) {
    try {
        const {
            exportId,
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/edo/export",
            client: "/client-adm/edo/export",
        });

        const result = yield request.bff.post(`${url}/get/${exportId}`);

        const {errorMessage, fileName, link} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DOWNLOAD_EDO_EXPORT_FILES_ERROR,
                payload: result,
            });

            return {
                done: true,
            };
        }

        downloadByUrl(link, fileName);

        yield put({
            type: DOWNLOAD_EDO_EXPORT_FILES_SUCCESS,
            payload: result,
        });

    } catch (error) {
        toastError(error.message, {type: "error"});
        yield put({
            type: GET_EDO_EXPORT_LIST_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_STATEMENTS_PAGE_REQUEST, getStatementsPageSaga),
        takeEvery(GET_STATEMENTS_TYPES_PAGE_REQUEST, getStatementTypesPageSaga),
        takeEvery(GET_EDO_EXPORT_LIST_REQUEST, getEdoExportListSaga),
        takeEvery(GET_STATEMENTS_ARCHIVE_REQUEST, archiveStatementSaga),
        takeEvery(GET_STATEMENTS_SOURCE_FILE_REQUEST, getStatementsSourceFileSaga),
        takeEvery(UPLOAD_EDO_STATEMENT_REQUEST, uploadEdoStatementSaga),
        takeEvery(SEND_STATEMENT_VERIFICATION_CODE_REQUEST, sendStatementCodeVerificationSaga),
        takeEvery(REPLACE_EDO_STATEMENT_REQUEST, replaceEdoStatementtSaga),
        takeEvery(SIGN_STATEMENT_REQUEST, signEdoStatementSaga),
        takeEvery(REJECT_STATEMENT_REQUEST, rejectEdoStatementSaga),
        takeEvery(REVIEW_STATEMENT_REQUEST, reviewEdoStatementSaga),
        takeEvery(GET_EDO_EXPORT_STATUSES_REQUEST, getEdoExportStatusesSaga),
        takeEvery(GET_EDO_COUNTS_BY_STAFF_ID_REQUEST, getCountsEdoByStaffIdSaga),
        takeEvery(GET_STATEMENTS_INFO_COUNT_REQUEST, getStatementsCountSaga),
        takeEvery(DOWNLOAD_EDO_EXPORT_FILES_REQUEST, downloadEdoExportFilesSaga),
        takeEvery(CHECK_STATEMENT_FILE_REQUEST, checkStatementFileSaga),
        takeEvery(GET_STATEMENT_TEMPLATE_EMPTY_FILE_REQUEST, getStatementTemplateEmptyFileSaga),
        takeEvery(GET_STATEMENT_TEMPLATE_PRE_FILLED_FILE_REQUEST, getStatementsTemplatePreFilledFileSaga),
        takeEvery(TASK_EXPORT_EDO_DOCUMENT_REQUEST, taskEdoExportDocumentSaga),
    ],
    );
}