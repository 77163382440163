import React, {useRef} from "react";
import {useSelector} from "react-redux";

import NmEmptyPageV2 from "../../../../../components/ActualComponents/NmEmptyPageV2";
import CheckboxList from "../../../../../components/CheckboxList";
import NmLoader from "../../../../../components/NmLoader";
import {bffRecruitmentVacancyTabFilterDataSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";
import {
    bffRecruitmentVacancyResponsesListPageDataSelector,
    bffRecruitmentVacancyResponsesListSelector,
    bffRecruitmentVacancyResponsesPageNumSelector,
    bffRecruitmentVacancyResponsesProgressSelector,
    bffRecruitmentVacancyResponsesTotalPageSelector,
} from "../../../../../ducks/bff/recruitment/vacancyResponses/selectors";
import {fileStoreRecruitmentAvatarBase64ImagesSelector} from "../../../../../ducks/fileStoreRecruitment";
import {RecruitmentVacancyResponseListCard} from "../../components/list-card";
import {RecruitmentVacancyResponseCard} from "../response-card";

import {useInfiniteScrollPagination} from "../../../../../hooks/useInfiniteScrollPagination";
import useRecruitmentVacancyResponseListFetch from "./hooks/useFetch";

import {history} from "../../../../../store/configureStore";

const RecruitmentVacancyResponseList = (props) => {
    const {
        isNew,
        match: {
            params: {
                clientId,
                vacancyId,
                id: responseId,
            },
        },
        cardLink,
    } = props;

    const list = useSelector(bffRecruitmentVacancyResponsesListSelector);
    const {isLoadMore} = useSelector(bffRecruitmentVacancyResponsesListPageDataSelector);
    const totalPages = useSelector(bffRecruitmentVacancyResponsesTotalPageSelector);
    const pageNum = useSelector(bffRecruitmentVacancyResponsesPageNumSelector);
    const progress = useSelector(bffRecruitmentVacancyResponsesProgressSelector);
    const {isSearch} = useSelector(bffRecruitmentVacancyTabFilterDataSelector);
    const userAvatarDict = useSelector(fileStoreRecruitmentAvatarBase64ImagesSelector);

    const isShowRightContent = Boolean(responseId);
    const $contentRef = useRef(null);

    const {
        pageData,
        infiniteScrollProps,
    } = useInfiniteScrollPagination({
        nextPageNum: pageNum,
        totalPages,
    });

    const {
        fetchList,
    } = useRecruitmentVacancyResponseListFetch({
        clientId,
        vacancyId,
        pageData,
        isNew,
    });

    const getRows = () => {
        return list.map((item) => {
            const {
                age,
                lastOrganisation,
                lastPosition,
                applicantName,
            } = item;

            const isActive = item.responseId === responseId;

            return {
                ...item,
                key: item.responseId,
                isActive,
                contentRow: (
                    <RecruitmentVacancyResponseListCard
                        isActive={isActive}
                        responseId={item.responseId}
                        base64={userAvatarDict[item.responseId]}
                        onClickCard={() => {
                            const link =
                                cardLink
                                    .replace(":clientId", clientId)
                                    .replace(":vacancyId", vacancyId)
                                    .replace(":id", item.responseId);

                            history.push(link);
                        }}
                        name={applicantName}
                        age={age}
                        lastOrganisation={lastOrganisation}
                        lastPosition={lastPosition}
                    />
                ),
            };
        });
    };

    const getRightContent = () => {
        return (
            <RecruitmentVacancyResponseCard
                isNewResponses={isNew}
                fetchList={fetchList}
                cardLink={cardLink}
                responseId={responseId}
                clientId={clientId}
                vacancyId={vacancyId}
            />
        );
    };

    const getContent = () => {
        if (!list.length && progress) {
            return (
                <NmLoader
                    inverted={true}
                    active={true}
                />
            );
        }

        if (!list.length) {
            return (
                <NmEmptyPageV2
                    isSearch={isSearch}
                />
            );
        }

        return (
            <>
                {
                    !isLoadMore && progress &&
                    <NmLoader
                        inverted={true}
                        active={true}
                    />
                }
                <CheckboxList
                    rows={getRows()}
                    contentRef={$contentRef}
                    isShowRightContent={isShowRightContent}
                    rightContentLink={
                        cardLink
                            .replace(":clientId", clientId)
                            .replace(":vacancyId", vacancyId)
                    }
                    rightContent={getRightContent()}
                    rightContentId={responseId}
                    infiniteScrollProps={infiniteScrollProps}
                    infiniteProgress={progress && isLoadMore}
                />
            </>
        );
    };

    return (
        <div className="relative flex-grow-1 mb-6">
            {getContent()}
        </div>
    );
};

export default RecruitmentVacancyResponseList;