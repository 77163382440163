import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import HelpTooltip from "../../../../components/ActualComponents/HelpTooltip";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../components/ActualComponents/NmModal";
import NmModalCardList from "../../../../components/ActualComponents/NmModal/CardList";
import NmPagination from "../../../../components/ActualComponents/NmPagination";
import NmRadioV2 from "../../../../components/ActualComponents/NmRadioV2";
import ApplyButtons from "../../../../components/ApplyButtons";
import NmButton from "../../../../components/NmButton";
import NmTitle from "../../../../components/NmTitle";

import {phoneFormat} from "../../../../utils/stringFormat";

import {NEW_STAFF_FORM_TYPE} from "../../../../constants/edmStaff";
import {
    CONTRACTOR_SEARCH_TYPE,
    CONTRACTOR_SEARCH_TYPE_OPTIONS,
} from "../../../../constants/registry";

import {findUsersForEdmStaff} from "../../../../ducks/bff/clients/edo/staff/actionCreators";
import {
    edmStaffFindingUserListSelector,
    edmStaffFindingUserTotalPagesSelector,
} from "../../../../ducks/bff/clients/edo/staff/selectors";

import "./style.sass";


const EdmStaffNewFindingForm = (props) => {
    const {
        clientId,
        type,
        onSearchContractors,
        preloader,
        onSelectUser,
        onTypeChange,
        onCancel,
    } = props;

    const [pageNum, setPageNum] = useState(1);
    const [form, setForm] = useState({
        searchType: CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE,
    });

    const dispatch = useDispatch();

    const userList = useSelector(edmStaffFindingUserListSelector);
    const userTotalPages = useSelector(edmStaffFindingUserTotalPagesSelector);

    useEffect(() => {
        fetchUserList();
    }, [pageNum]);

    const fetchUserList = () => {
        dispatch(findUsersForEdmStaff({
            clientId,
            pageNum,
            pageSize: 5,
        }));
    };

    const onFindingTypeChange = (event, {value}) => {
        if (value === NEW_STAFF_FORM_TYPE.USER) {
            fetchUserList();
        }

        onTypeChange(value);
    };

    const onChange = (event, {name, value}) => {
        setForm({
            ...form,
            [name]: value,
            phone: name === "phone" ? value : null,
            fio: name === "fio" ? value : null,
        });
    };

    const handlePaginationChange = (e, {activePage: newPageNum}) => {
        if (pageNum === newPageNum) {
            return;
        }

        setPageNum(newPageNum);
    };

    const getCards = () => {
        return userList.map(item => {
            return {
                ...item,
                singleValues: [
                    {
                        label: "ФИО",
                        value: item.fio || "-",
                    },
                    {label: "Телефон", value: phoneFormat(item.phone) || "-"},
                ],
                actions: (
                    <NmButton
                        color="light-green"
                        disabled={item.inStaff}
                        className="edm-staff-new-finding-form__action"
                        onClick={onSelectUser(item)}
                    >
                        {item.inStaff ? "Добавлен" : "Выбрать"}
                    </NmButton>
                ),
            };
        });
    };

    const renderUserListTable = () => {
        return (
            type === NEW_STAFF_FORM_TYPE.USER &&
            <NmModalCardList
                list={getCards()}
                pagination={
                    <NmPagination
                        pageNum={pageNum}
                        totalPages={userTotalPages}
                        onChangePagination={handlePaginationChange}
                    />
                }
            />
        );
    };

    const renderContractorInviteForm = () => {
        return (
            type === NEW_STAFF_FORM_TYPE.CONTRACTOR &&
            <div className="row gx-4">
                <div className="col-16 col-md-8">
                    <NmDropdownV2
                        size="xl"
                        name="searchType"
                        value={form.searchType}
                        className="edm-staff-new-finding-form__input"
                        onChange={onChange}
                        options={CONTRACTOR_SEARCH_TYPE_OPTIONS}
                    />
                </div>
                <div className="col-16 col-md-8">
                    {form.searchType === CONTRACTOR_SEARCH_TYPE.PHONE_NUMBER.VALUE ?
                        <NmInputV2
                            size="xl"
                            placeholder="Телефон"
                            mask="+7 (999) 999 99 99"
                            onChange={onChange}
                            maskChar={null}
                            className="mt-3 mt-md-0"
                            name="phone"
                            value={form.phoneNumber}
                        /> :
                        <NmInputV2
                            size="xl"
                            placeholder="ФИО"
                            onChange={onChange}
                            maskChar={null}
                            className="mt-3 mt-md-0"
                            name="fio"
                            value={form.fio}
                        />
                    }
                </div>
            </div>
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onCancel}
            header={
                <NmTitle size="lg">
                    Добавление сотрудника
                </NmTitle>
            }
            footer={
                type !== NEW_STAFF_FORM_TYPE.USER &&
                <ApplyButtons
                    cancelBtnContent="Отменить"
                    submitBtnContent="Далее"
                    submit={() => {
                        onSearchContractors(form);
                    }}
                    onClose={onCancel}
                />
            }
        >
            {preloader}
            <NmForm addMargin>
                <div className="edm-staff-new-finding-form__type-block">
                    <div className="edm-staff-new-finding-form__type-block-elem">
                        <NmRadioV2
                            name={NEW_STAFF_FORM_TYPE.CONTRACTOR}
                            value={NEW_STAFF_FORM_TYPE.CONTRACTOR}
                            label={"Из списка исполнителей"}
                            checked={type === NEW_STAFF_FORM_TYPE.CONTRACTOR}
                            onChange={onFindingTypeChange}
                        />
                        <HelpTooltip
                            info
                            className="edm-staff-new-finding-form__radio-tooltip"
                        >
                            Добавить в ЭДО сотрудника из базы зарегистрированных в системе исполнителей
                        </HelpTooltip>
                    </div>
                    <div className="edm-staff-new-finding-form__type-block-elem mt-2 mt-md-0">
                        <NmRadioV2
                            name={NEW_STAFF_FORM_TYPE.USER}
                            value={NEW_STAFF_FORM_TYPE.USER}
                            label="Из списка сотрудников"
                            checked={type === NEW_STAFF_FORM_TYPE.USER}
                            onChange={onFindingTypeChange}
                        />
                        <HelpTooltip
                            info
                            className="edm-staff-new-finding-form__radio-tooltip"
                        >
                            Добавить в ЭДО сотрудника из раздела "Сотрудники" Вашей компании
                        </HelpTooltip>
                    </div>
                </div>
                {renderContractorInviteForm()}
                {renderUserListTable()}
            </NmForm>
        </NmModal>
    );
};

export default EdmStaffNewFindingForm;