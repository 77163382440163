import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useBankIndicator} from "../../../../../../../components/BankIndicator/hooks/useBankIndicator";

import {getUserRole} from "../../../../../../../utils/access";

import {isClientUser} from "../../../../../../../constants/roles";

import {getContractorCardById} from "../../../../../../../ducks/bff/contractors/registry/actionCreators";
import {getByIdActRegistryPayment} from "../../../../../../../ducks/bff/orders/order-act-registries/card/actionCreators";
import {
    contractorRegistryPaymentPossibilityListSelector,
    contractorsPaymentPossibilityListSelector,
} from "../../../../../../../ducks/contractorBankCheck";

export const useOrderActRegistryEditPaymentFetch = (params) => {
    const {
        isEditRealContractor,
        contractorId,
        editData,
    } = params;
    
    const dispatch = useDispatch();

    const registryPaymentPossibility = useSelector(contractorRegistryPaymentPossibilityListSelector);
    const contractorsPaymentPossibilityList = useSelector(contractorsPaymentPossibilityListSelector);

    const role = getUserRole();

    const {
        getBankChecks,
    } = useBankIndicator({nameField: "registryPaymentsPossibility"});

    useEffect(() => {
        getContractor();

        if (!isClientUser(role)) {
            getBankChecks([contractorId]);
        }

        if (isEditRealContractor && editData) {
            const {
                clientId,
                registryId,
                registrySeqNum,
            } = editData;

            dispatch(getByIdActRegistryPayment({
                clientId,
                registryId,
                registrySeqNum,
            }));
        }
    }, []);

    const getContractor = () => {
        dispatch(getContractorCardById({contractorId}));
    };

    return {
        contractorsPossibility: isEditRealContractor
            ? contractorsPaymentPossibilityList
            : registryPaymentPossibility,
    };
};