import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmConfirmV2 from "../../../../../components/ActualComponents/NmConfirmV2";
import Avatar from "../../../../../components/Avatar";
import CheckboxList from "../../../../../components/CheckboxList";
import ExtLink from "../../../../../components/ExtLink/index";
import InvitePerformerToNaimix from "../../../../../components/InvitePerformerToNaimix/index";
import NmButton from "../../../../../components/NmButton/index";
import NmPage from "../../../../../components/NmPage/index";
import {NmPageHeader} from "../../../../../components/NmPageHeader/index";
import {withPageData} from "../../../../../components/withPageData/index";
import {ReactComponent as PeopleIcon} from "../../../../../images/people.svg";
import {styleLargeLink} from "../../../../../styles/inline";
import RegistryFrameContractNotificationData from "../../../../documents/fc-registers/notification-data-form";
import ContractorListInfo from "../../contractor-list-info";
import InviteContractorToFcRegistryFilter from "../filter/index";

import {isNMUsers} from "../../../../../utils/access";
import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {clearSpace, removePhoneMask} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {isAcceptedAddContractorToNmByEmailAndSms} from "../../../../../utils/user-rights/contractor";

import {COMPONENT} from "../../../../../components/ActualComponents/MediaControls/constants";
import {CONTRACTOR_STATUS} from "../../../../../constants/contractor";
import {SORT_TYPE} from "../../../../../constants/contractorList";
import {ORDER_WORK_REPORT_TYPE} from "../../../../../constants/finance";
import {headersNmContractorList} from "../../../../../constants/headersTable";
import {
    LINK_CLIENT_REGISTRY_FC_CARD,
    LINK_CONTRACTOR_PERSONAL_INFO,
    LINK_CONTRACTOR_PROFILE,
} from "../../../../../constants/links";
import {HR_MANAGER, isUserFromNm, RNKO} from "../../../../../constants/roles";

import {history} from "../../../../../store/configureStore";

import {
    addItemIntoFcRegistry,
    getContractorsPageForFcRegistry, getFcRegistryById,
} from "../../../../../ducks/bff/clients/documents/frame-contracts-registry/actionCreators";
import {
    contractorListForFcRegistrySelector,
    contractorListForFcRegistryTotalCountSelector,
    contractorListForFcRegistryTotalPagesSelector,
    contractorListProgressForFcRegistrySelector,
    fcRegistryActionSelector, fcRegistryCardSelector,
} from "../../../../../ducks/bff/clients/documents/frame-contracts-registry/selectors";
import {addFavoriteContractors} from "../../../../../ducks/bff/contractors/registry/actionCreators";
import {
    contractorStatusDictSelector,
    contractorStatusOptionsSelector,
    getCitizenshipOptionsSelector,
    getCitizenshipSelector,
    getContractorStatusDict,
} from "../../../../../ducks/contractor";
import {getWorkSchedulesByContractorId} from "../../../../../ducks/contractorWorkSchedules";
import {avatarBase64ImagesListSelector} from "../../../../../ducks/fileStore";
import {
    specialitiesAllV2DictSelector,
    specialitiesAllV2OptionsSelector,
} from "../../../../../ducks/speciality";

import "./style.sass";

class InviteContractorsToFcRegistryForm extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    registryId,
                },
            },
            pageNum,
            pageSize,
        } = props;

        this.state = {
            pageSize: pageSize || 25,
            pageNum: pageNum || 1,
            pageSizes: [25, 50, 100],
            headers: headersNmContractorList,
            filterData: {},
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId;
        this.registryId = registryId;
    }

    componentDidMount() {
        this.fetchList();
        this.fetchContractorStatusDict();
        if(this.props.fcRegistryCard.registryId !== this.registryId){
            this.fetchRegistry();
        }
    }

    componentDidUpdate(prevProps) {
    };

    componentWillUnmount() {
    };

    fetchRegistry = () => {
        const {
            getFcRegistryById,
        } = this.props;

        getFcRegistryById({
            data: {
                registryId: this.registryId,
                clientId: this.clientId,
            },
        });
    };

    fetchContractorStatusDict = () => {
        const {
            getContractorStatusDict,
        } = this.props;

        getContractorStatusDict();
    };

    sendFilter = (filterData) => {
        this.setState({
            pageNum: 1,
            filterData: {...filterData},
        }, this.fetchList);
    };

    fetchList = () => {
        const {
            getContractorsPageForFcRegistry,
        } = this.props;

        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const {
            innFilter,
        } = filterData;

        const requestData = {
            pageNum,
            pageSize,
            clientId: this.clientId,
            ...filterData,
            remoteWorkFilter: filterData.remoteWorkFilter ? filterData.remoteWorkFilter : undefined,
            initRegionValueArr: undefined,
            innFilter: isNullOrWhitespace(innFilter) ? null : clearSpace(innFilter),
            sortType: SORT_TYPE.CONFIRMED_FIRST,
            phoneFilter: removePhoneMask(filterData.phoneFilter),
            frameContractRegistryClientId: this.clientId,
            frameContractRegistryId: this.registryId,
        };


        getContractorsPageForFcRegistry({
            data: requestData,
        });

    };

    addContractorIntoRegistry = (contractor) => {
        const {
            addItemIntoFcRegistry,
        } = this.props;

        const {
            contractorId,
        } = contractor;

        return () => {
            addItemIntoFcRegistry({
                data: {
                    contractorId,
                    registryId: this.registryId,
                    clientId: this.clientId,
                },
                onSuccess: () => {
                    this.hideAddConfirm();
                    this.fetchList();
                },
            });
        };
    };

    updateState = (newStateFields, cbFn = () => {
    }) => {
        return () => {
            this.setState({
                ...newStateFields,
            }, cbFn);
        };
    };

    onOpenFilter = () => {
        this.setState(prevState => ({
            ...prevState,
            openFilter: !prevState.openFilter,
        }));
    };

    //TODO убрать лишние
    get localizationData() {
        const {
            t,
        } = this.props;

        return {
            workRegionLabel: t("contractor-list.work-region"),
            workRegionsLabel: t("contractor-list.work-regions"),
            citizenship: t("contractor-list.citizenship"),
            emptyList: t("contractor-list.empty-list"),
            invited: t("contractor-list.invited"),
            online: t("contractor-list.online"),
            specialtiesLabel: t("contractor-list.specialties"),
            FNS: t("contractor-list.FNS"),
            isCheckPassport: t("contractor-list.check-passport"),
            completedOrders: t("contractor-list.completed-orders"),
            completedOrdersHelp: t("contractor-list.completed-orders-help"),
            inviteContractorToOrder: t("contractor-list.invite-contractor-to-order"),
            cancelInvite: t("contractor-list.cancel-invite"),
            lastLoginDate: t("contractor-list.last-login-date-text"),
            unknown: t("contractor-list.unknown"),
            addInFavorites: t("contractor-list.add-in-favorites"),
            addToBlackList: t("contractor-list.add-to-blacklist"),
            firstRegistration: t("contractor-list.first-registration"),
            fullRegistration: t("contractor-list.full-registration"),
            deleteFromBlackList: t("contractor-list.delete-from-blacklist"),
            contractorFnsError: t("contractor-list.contractor-fns-error"),
            myTaxFnsError: t("contractor-list.my-tax-fns-error"),
            fnsSuccess: t("contractor-list.fns-success"),
            bankRegistrationError: t("contractor-list.bank-registration-error"),
            checkPassportError: t("contractor-list.check-passport-error"),
            checkPassportSuccess: t("contractor-list.check-passport-success"),
            deleteFromFavorites: t("contractor-list.delete-from-favorites"),
            maxOrderDate: t("contractor-list.max-order-date"),
            age: t("contractor-list.age"),
            btnYesText: t("button.yes"),
            btnNoText: t("button.no"),
            btnConfirmText: t("button.confirm"),
            btnCancelText: t("button.cancel"),
            addInFavoritesConfirm: (name) => {
                return t("contractor-list.add-contractor-in-favorites-confirm", {name});
            },
            addOnceToOrderConfirm: (name, orderName) => {
                return t("contractor-list.invite-contractor-to-order-confirm", {name, orderName});
            },
            addSeveralToOrderConfirm: (names, orderName) => {
                return t("contractor-list.invite-contractors-to-order-confirm", {names, orderName});
            },
            addSeveralToOrderError: t("contractor-list.invite-contractors-to-order-error"),
            cancelInviteToOrderConfirm: (name, orderName) => {
                return t("contractor-list.cancel-invite-contractor-to-order-confirm", {name, orderName});
            },
            addSeveralInFavoritesConfirm: (names) => {
                return t("contractor-list.add-contractors-in-favorites-confirm", {names});
            },
            addSeveralInFavoritesError: t("contractor-list.add-contractors-in-favorites-error"),
            deleteSeveralFromFavoritesConfirm: (names) => {
                return t("contractor-list.delete-contractors-from-favorites-confirm", {names});
            },
            deleteSeveralFromBlackListConfirm: (names) => {
                return t("contractor-list.delete-contractors-from-blacklist-confirm", {names});
            },
            deleteFromFavoritesConfirm: (name) => {
                return t("contractor-list.delete-contractor-from-favorites-confirm", {name});
            },
            addOnceToBlackListConfirm: (name) => {
                return t("contractor-list.add-contractor-to-black-list-confirm", {name});
            },
            deleteContractorFromBlackListConfirm: (name) => {
                return t("contractor-list.delete-contractor-from-blacklist-confirm", {name});
            },
        };
    }

    handleChange = (updatedState) => {
        return () => {
            this.setState({
                ...updatedState,
            });
        };
    };

    submitFilter = () => {
        this.setState({pageNum: 1}, this.fetchList);
    };

    getContractorFullName = (contractor) => {
        const {
            fullName,
            firstName,
            lastName,
            patronymic,
            status,
            fio,
        } = contractor;

        if (!isEmpty(firstName) && (isUserFromNm(this.role) || ![CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status))) {
            return `${lastName} ${firstName} ${patronymic || ""}`;
        }
        return fullName || fio;
    };

    getRows = () => {
        const {
            list,
            userAvatarDict,
        } = this.props;


        return list.map(item => {
            return {
                ...item,
                contentRow: this.renderContractorInfo(item),
                isSelected: item.isSelected,
                avatar: <Avatar
                    addedToMyClientGroup={item.addedToMyClientGroup}
                    contractorId={item.contractorId}
                    base64={userAvatarDict[item.contractorId]}
                />,
            };
        });
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {

        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handleOnClickBack = () => {
        history.push(LINK_CLIENT_REGISTRY_FC_CARD.replace(":clientId", this.clientId).replace(":registryId", this.registryId));
    };

    closeOvmForm = () => {
        this.fetchList();
        this.setState({
            isShowOvmForm: false,
        });
    };

    showAddConfirm = (contractor) => {
        return () => {
            this.setState({
                isShowOvmForm: true,
                invitedContractor: contractor,
            });
        };
    };

    hideAddConfirm = () => {
        this.setState({
            invitedContractor: null,
            isShowConfirm: false,
        });
    };

    renderActions = (contractor) => {
        const {
            addedToRegistry,
            frameContractSigned,
        } = contractor;

        if (addedToRegistry) {
            return (
                <div className="flex fluid flex-content-center">
                    Добавлен в реестр
                </div>
            );
        }
        if (frameContractSigned) {
            return (
                <div className="flex fluid flex-content-center">
                    Договор подписан
                </div>
            );
        }

        return (
            <div className="flex fluid flex-content-center">
                <NmButton
                    onClick={this.showAddConfirm(contractor)}
                    color="light-green"
                >
Добавить в реестр
                </NmButton>
            </div>
        );
    };

    getMediaControls = () => {
        const {
            t,
        } = this.props;

        const inviteToNmBtn = {
            component: COMPONENT.BUTTON,
            props: {
                onClick: this.handleChange({openAddContractorForm: true}),
                icon: <PeopleIcon />,
                children: t("contractor-list.invite-contractor-to-nm"),
            },
            visible: isAcceptedAddContractorToNmByEmailAndSms(this.role) && this.clientId,
        };


        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                inviteToNmBtn,
            ],
        };
    };

    renderInviteWithOVMForm() {
        const {
            isShowOvmForm,
            invitedContractor,
        } = this.state;

        if (!isShowOvmForm) {
            return null;
        }

        const {
            contractorId,
            speciality,
            workAddress,
            ovmUnitName,
            lastName: contractorLastName,
            firstName: contractorFirstName,
            patronymic: contractorPatronymic,
            fullName,
            phone,
            contractType,
            citizenship,
            locatedOutsideRussia,
        } = invitedContractor;

        const {
            ovmRequired,
            contractType: registryContractType,
        } = this.props.fcRegistryCard;
        const isIndividualEntrepreneurRegistry = registryContractType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

        return (isShowOvmForm &&
            <RegistryFrameContractNotificationData
                ovmRequired={ovmRequired}
                isIndividualEntrepreneurRegistry={isIndividualEntrepreneurRegistry}
                registryId={this.registryId}
                clientId={this.clientId}
                close={this.updateState({isShowOvmForm: false})}
                isEdit={false}
                contractorObj={{
                    contractorId,
                    contractorLastName,
                    contractorFirstName,
                    contractorPatronymic,
                    fullName,
                    phone,
                    contractorCitizenship: citizenship,
                    locatedOutsideRussia,
                }}
                notificationObj={{
                    speciality,
                    workAddress,
                    ovmUnitName,
                    contractType,
                }}
                onSuccess={this.closeOvmForm}
            />
        );
    };

    renderInviteConfirm = () => {
        const {
            invitedContractor,
            isShowConfirm,
        } = this.state;

        if (!isShowConfirm) {
            return;
        }

        const {
            fullName,
            lastName,
            firstName,
            patronymic,
        } = invitedContractor;

        const contractorFullName = isNullOrWhitespace(lastName) ? fullName : `${lastName} ${firstName} ${patronymic || ""}`;

        return (
            <NmConfirmV2
                onCancel={this.hideAddConfirm}
                onConfirm={this.addContractorIntoRegistry(invitedContractor)}
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                content={`Вы действительно хотите добавить исполнителя ${contractorFullName} в реестр на подписание РД?`}
                size="sm"
            />
        );
    };

    renderLink = (contractor) => {
        const {
            pageNum,
            pageSize,
            filterData,
        } = this.state;

        const {
            contractorId,
            status,
        } = contractor;

        const {
            specialityIdsFilter,
            addressFiasIdsFilter,
            initRegionValueArr,
            clientGroupClientIds,
        } = filterData;

        let link = LINK_CONTRACTOR_PROFILE;

        if ([HR_MANAGER, RNKO].includes(this.role) || ([CONTRACTOR_STATUS.ADMINISTRATOR_CHECK, CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES].includes(status) && isNMUsers())) {
            link = LINK_CONTRACTOR_PERSONAL_INFO;
        }

        link = link.replace(":contractorId", contractorId);

        const contractorName = this.getContractorFullName(contractor);

        return (
            <ExtLink
                extData={
                    {
                        specialityIdsFilter,
                        addressFiasIdsFilter,
                        initRegionValueArr,
                        clientGroupClientIds,
                    }
                }
                pageData={{pageNum, pageSize}}
                filterData={
                    filterData
                }
                historyEnabled
                style={styleLargeLink}
                to={link}
            >
                {contractorName}
            </ExtLink>
        );
    };

    getMediaActions = (contractor) => {
        const {
            addedToRegistry,
            hasActiveFrameContract,
        } = contractor;

        const addToRegistry = addedToRegistry || hasActiveFrameContract ?
            {
                component: COMPONENT.TEXT,
                props: {
                    onClick: ()=>{},
                    children: hasActiveFrameContract ? "Договор подписан" : "Добавлен в реестр",
                },
                disabled: true,
            } :
            {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: this.showAddConfirm(contractor),
                    children: "Добавить в реестр",
                    color: "light-green",
                },
            };

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                addToRegistry,
            ],
        };
    };

    renderContractorInfo = (contractor) => {
        const {
            t,
            citizenshipList,
            contractorStatusDict,
            specialityDict,
            specialityOptions,
        } = this.props;

        return (
            <ContractorListInfo
                contractor={contractor}
                role={this.role}
                renderLinkFnc={this.renderLink}
                t={t}
                citizenshipList={citizenshipList}
                contractorStatusDict={contractorStatusDict}
                specialityOptions={specialityOptions}
                specialityDict={specialityDict}
                showPaymentInfo={false}
                actions={this.getMediaActions(contractor)}
            />
        );
    };

    renderAddContractorToNmForm() {
        const {
            openAddContractorForm,
        } = this.state;

        return (openAddContractorForm &&
            <InvitePerformerToNaimix
                close={this.handleChange({openAddContractorForm: false})}
                clientId={this.clientId}
            />
        );
    };

    renderFilter() {
        const {
            specialityOptions,
            citizenshipOptions,
            contractorStatusOptions,
            t,
        } = this.props;

        return (
            <InviteContractorToFcRegistryFilter
                t={t}
                submitFilter={this.sendFilter}
                specialityOptions={specialityOptions}
                сitizenshipOptions={citizenshipOptions}
                contractorStatusOptions={contractorStatusOptions}
            />
        );
    };

    renderTable() {
        const {
            pageSize,
        } = this.state;

        const {
            list,
            progressList,
        } = this.props;

        if (isEmpty(list) && !progressList) {
            return (
                <div className="invite-to-fc-registry-form__empty-list">
                    <div className="invite-to-fc-registry-form__empty-list-text">
                        {this.localizationData.emptyList}
                    </div>
                </div>
            );
        }

        return (
            <CheckboxList
                rows={this.getRows()}
                className="nm-contractor-list__table nm-contractor-list__table_wout-cb"
            />
        );
    };

    render() {
        const {
            pageSize,
            pageNum,
            pageSizes,
            openFilter,
        } = this.state;

        const {
            t,
            totalCount,
            progressList,
            actionProgress,
            totalPages,
        } = this.props;

        return (
            <div
                className="invite-to-fc-registry-form"
            >
                {this.renderAddContractorToNmForm()}
                <NmPage
                    className="fluid-flex-grow"
                    header={
                        <NmPageHeader
                            text="Поиск исполнителей для добавления в реестр на подписание РД"
                            totalCount={totalCount}
                            handleOnClickBack={this.handleOnClickBack}
                        />
                    }
                    openFilter={openFilter}
                    onOpenFilter={this.onOpenFilter}
                    mediaControls={
                        this.getMediaControls()
                    }
                    filtersBase={
                        this.renderFilter()
                    }
                    typeFilter="vertical"
                    isLoaded={progressList || actionProgress}
                    currentPageSize={pageSize}
                    currentPageNum={pageNum}
                    pageSizes={pageSizes}
                    totalPages={totalPages}
                    onChangePageSize={this.handleChangePageSize}
                    onPaginationChange={this.handlePaginationChange}
                    totalCount={totalCount}
                >
                    {this.renderInviteConfirm()}
                    {this.renderInviteWithOVMForm()}
                    {this.renderTable()}
                </NmPage>
            </div>
        );
    };
}

export default withPageData(connect(
    state => ({
        list: contractorListForFcRegistrySelector(state),
        progressList: contractorListProgressForFcRegistrySelector(state),
        actionProgress: fcRegistryActionSelector(state),
        totalPages: contractorListForFcRegistryTotalPagesSelector(state),
        totalCount: contractorListForFcRegistryTotalCountSelector(state),
        citizenshipList: getCitizenshipSelector(state),
        specialityOptions: specialitiesAllV2OptionsSelector(state),
        pathname: state.router.location.pathname,
        contractorStatusDict: contractorStatusDictSelector(state),
        contractorStatusOptions: contractorStatusOptionsSelector(state),
        specialityDict: specialitiesAllV2DictSelector(state),
        userAvatarDict: avatarBase64ImagesListSelector(state),
        citizenshipOptions: getCitizenshipOptionsSelector(state),
        fcRegistryCard: fcRegistryCardSelector(state),
    }),
    {
        getContractorsPageForFcRegistry,
        addFavoriteContractors,
        getContractorStatusDict,
        getWorkSchedulesByContractorId,
        addItemIntoFcRegistry,
        getFcRegistryById,
    },
)(withTranslation()(InviteContractorsToFcRegistryForm)));