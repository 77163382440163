import React, {useMemo} from "react";
import {nanoid} from "nanoid";

import {FULL_REGISTRATION_COMPONENT_MAP} from "../../../../containers/contractor/full-registration";

import {getFullRegistrationFileExamples} from "../../utils/getExamples";

import {FULL_REGISTRATION_COMPONENT_DEFAULT_PROPS, FULL_REGISTRATION_COMPONENT_TYPE} from "../../constants";

const FullRegistrationForm = (props) => {
    const {
        componentMap = FULL_REGISTRATION_COMPONENT_MAP,
        step,
        contractor,
        onChange,
        values,
        errors,
        warnings,
        scans,
        setFilesErrors,
        touched,
    } = props;

    return useMemo(() => {
        if (!step.fields) {
            return null;
        }

        const fields = step.fields(values);

        return fields.map((item) => {
            const {
                type,
                ...props
            } =  item;

            const Component = componentMap[type];

            if (!Component) {
                return null;
            }

            const defaultProps = FULL_REGISTRATION_COMPONENT_DEFAULT_PROPS[type] || {};
            const key = props.name || nanoid(3);
            const generalProps = {
                ...defaultProps,
                ...props,
                error: touched && touched[props.name] && errors && errors[props.name],
                warning: warnings && warnings[props.name],
            };

            if (type === FULL_REGISTRATION_COMPONENT_TYPE.LINK) {
                const {
                    content,
                    link,
                    ...otherProps
                } = props;

                return (
                    <div
                        key={key}
                        className="fluid"
                    >
                        <Component
                            children={content}
                            to={link}
                            target="_blank"
                            {...otherProps}
                        />
                    </div>
                );
            }

            if (type === FULL_REGISTRATION_COMPONENT_TYPE.DATE) {
                return (
                    <Component
                        key={key}
                        {...generalProps}
                        selected={values[props.name]}
                        onChange={onChange}
                    />
                );
            }

            if (type === FULL_REGISTRATION_COMPONENT_TYPE.CHECKBOX) {
                return (
                    <Component
                        key={key}
                        {...generalProps}
                        checked={values[props.name]}
                        onChange={onChange}
                    />
                );
            }

            if (type === FULL_REGISTRATION_COMPONENT_TYPE.DADATA_INPUT) {
                return (
                    <Component
                        key={key}
                        {...generalProps}
                        query={values[props.name]}
                        onChange={({value}) => {
                            onChange(null, {
                                name: props.name,
                                value,
                            });
                        }}
                    />
                );
            }

            if (type === FULL_REGISTRATION_COMPONENT_TYPE.DROPZONE) {
                const example = getFullRegistrationFileExamples({
                    fileType: props.name,
                    passportType: contractor.passportType,
                    citizenship: contractor.citizenship,
                    vhiPolicyType: values.vhiPolicyType,
                });
                const files = values[props.name]
                    || scans[props.name]
                    || [];

                return (
                    <Component
                        key={key}
                        {...generalProps}
                        isTopImagePreview={true}
                        example={example}
                        files={files}
                        onChange={(files) => {
                            onChange(null, {
                                name: props.name,
                                value: files,
                            });
                        }}
                        setFilesErrors={setFilesErrors}
                    />
                );
            }

            return (
                <Component
                    key={key}
                    {...generalProps}
                    value={values[props.name] || ""}
                    onChange={onChange}
                />
            );
        });
    }, [
        step.fields,
        values,
        errors,
        warnings,
        scans,
        touched,
    ]);
};

export default FullRegistrationForm;