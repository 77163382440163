import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositList from "../list";

import {COMMISSION_TYPE} from "../../../constants/deposit";

import {
    addCivilClientDeposit,
    civilDepositListSelector,
    civilDepositListTotalCountSelector,
    civilDepositListTotalPagesSelector,
    civilDepositProgressListSelector,
    civilProgressActionSelector,
    getCivilDepositList,
    returnCivilClientDeposit,
    updateCivilStore,
} from "../../../ducks/civil";
import {
    commissionHistoryModesSelector,
    commissionHistoryProgressSelector,
    commissionHistoryTotalCountSelector,
    commissionHistoryTotalPagesSelector,
    getRichCommissionHistory,
    getRichContractorCommissionHistory,
} from "../../../ducks/deposit";

const DepositCivilAdminList = (props) => {
    const dispatch = useDispatch();

    const progressCivilAction = useSelector(civilProgressActionSelector);
    const list = useSelector(civilDepositListSelector);
    const totalPages = useSelector(civilDepositListTotalPagesSelector);
    const totalCount = useSelector(civilDepositListTotalCountSelector);
    const progress = useSelector(civilDepositProgressListSelector);

    // История изменения комиссиии
    const historyList = useSelector(commissionHistoryModesSelector);
    const historyTotalPages = useSelector(commissionHistoryTotalPagesSelector);
    const historyProgress = useSelector(commissionHistoryProgressSelector);
    const historyTotalCount = useSelector(commissionHistoryTotalCountSelector);

    const onFetchList = ({nameSubstringFilter, pageNum, pageSize}) => {
        dispatch(getCivilDepositList({
            nameSubstringFilter,
            pageNum,
            pageSize,
        }));
    };

    const onFetchCommissionHistory = (reqData) => {
        const {
            commissionType,
            ...otherData
        } = reqData;

        if (
            [
                COMMISSION_TYPE.CIVIL_ORDER,
                COMMISSION_TYPE.CIVIL_REGISTRY,
            ].includes(commissionType)
        ) {
            dispatch(getRichContractorCommissionHistory({
                isCivil: true,
                ...reqData,
            }));

            return;
        }

        dispatch(getRichCommissionHistory({
            isCivil: true,
            ...otherData,
        }));
    };

    const onUpdateStore = (data) => {
        dispatch(updateCivilStore(data));
    };

    const onAddDeposit = (data) => {
        dispatch(addCivilClientDeposit(data));
    };

    const onReturnDeposit = (data) => {
        dispatch(returnCivilClientDeposit(data));
    };

    return (
        <DepositList
            {...props}
            isCivil
            header="Депозит (НДФЛ)"
            commissionHistory={{
                list: historyList,
                totalPages: historyTotalPages,
                historyProgress: historyProgress,
                historyTotalCount: historyTotalCount,
                onFetchList: onFetchCommissionHistory,
                onUpdateStore,
            }}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            onAddDeposit={onAddDeposit}
            onReturnDeposit={onReturnDeposit}
            onFetchList={onFetchList}
            progressAction={progressCivilAction}
            progressList={progress}
        />
    );
};

export default DepositCivilAdminList;