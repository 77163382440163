export const RECRUITMENT_VACANCY_LOG_TYPE = {
    CREATE_VACANCY: "CREATE_VACANCY",
    CREATE_VACANCY_FROM_JOB_BOARD: "CREATE_VACANCY_FROM_JOB_BOARD",
    CREATE_VACANCY_AT_JOB_BOARD: "CREATE_VACANCY_AT_JOB_BOARD",
    IMPORT_RESPONSES_FROM_JOB_BOARD: "IMPORT_RESPONSES_FROM_JOB_BOARD",
    UPDATE_VACANCY_PARAM: "UPDATE_VACANCY_PARAM",
    UPDATE_VACANCY_PARAM_FROM_JOB_BOARD: "UPDATE_VACANCY_PARAM_FROM_JOB_BOARD",
    UPDATE_VACANCY_PARAM_AT_JOB_BOARD: "UPDATE_VACANCY_PARAM_AT_JOB_BOARD",
    CLOSE_VACANCY: "CLOSE_VACANCY",
    CANCEL_PUBLICATION: "CANCEL_PUBLICATION",
    ARCHIVE_VACANCY: "ARCHIVE_VACANCY",
    UNARCHIVE_VACANCY: "UNARCHIVE_VACANCY",
    DELETE_VACANCY: "DELETE_VACANCY",
    ADD_CANDIDATE_TO_VACANCY: "ADD_CANDIDATE_TO_VACANCY",
};

export const RECRUITMENT_VACANCY_LOG_FIELD_DICT = {
    title: "Название вакансии",
    description: "Описание вакансии",
    dateFrom: "Период работ (от)",
    dateTo: "Период работ (до)",
    publishDateFrom: "Период размещения (от)",
    publishDateTo: "Период размещения (до)",
    salary: "Зарплата",
    requirementIds: "Дополнительные требования",
    specialityId: "Вид деятельности",
    workAddressFiasId: "Город публикации вакансии",
    citizenships: "Гражданство",
    ageFrom: "Возраст (от)",
    ageTo: "Возраст (до)",
    gender: "Пол",
    recruiterId: "Рекрутер",
    funnelId: "Воронка статусов",
};