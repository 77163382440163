import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import {ReactComponent as HorecaLogo} from "../../images/horeca-logo.svg";
import {ReactComponent as InfoIcon} from "../../images/info_24.svg";
import {ReactComponent as MaintenanceIcon} from "../../images/maintenance.svg";
import {Counter} from "../ActualComponents/Counter";
import NmAdvancedTooltip from "../ActualComponents/NmAdvancedTooltip";
import Text from "../ActualComponents/Text";
import MenuItem from "../MenuItem";
import NmIcon from "../NmIcon";
import ServerSentEvents from "../ServerSentEvents";
import MenuHamburger from "./Hamburger";
import {MenuItemsContainer} from "./ItemsContainer";
import MenuProfile from "./Profile";

import {getUserRestrictions, isNMUsers} from "../../utils/access";
import {
    checkMenuItemRestrictions,
    filterDynamicLinks,
} from "../../utils/clientHelper";
import {linkActiveChecker} from "../../utils/gueryStringHelper";
import {CURRENT_CLIENT_ID, CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../utils/localstorage";
import {isAccessByRestrictions} from "../../utils/restrictions";
import {isNullOrWhitespace} from "../../utils/stringHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {COLOR} from "../../constants/color";
import {MENU_DESIGN_TYPE} from "../../constants/menu";
import {isClientUser} from "../../constants/roles";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    HR_MANAGER,
    NM_CONSULTANT,
    NM_MANAGER,
    NM_PARTNER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../constants/roles";
import {SSE_EVENT_TYPE} from "../../constants/sse";

import {changeAccount, logoutRequest} from "../../ducks/auth";
import {getEdoDocumentInfoCount} from "../../ducks/bff/clients/edo/documents/actionCreators";
import {getStatementsInfoCount} from "../../ducks/bff/clients/edo/statements/actionCreators";
import {getForemenNewRequestsAndPaymentsCount} from "../../ducks/bff/clients/foremen/requests/actionCreators";
import {clientForemenNewRequestsAndPaymentsCountSelector} from "../../ducks/bff/clients/foremen/requests/selectors";
import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";
import {
    getClientMaintenanceNotice,
    updateCommonClientStore,
} from "../../ducks/bff/common/client/actionCreators";
import {bffCommonClientMaintenanceNoticeSelector} from "../../ducks/bff/common/client/selectors";
import {getOpenDisputesTotalCount} from "../../ducks/bff/disputes/actionCreators";
import {disputesMenuTotalCountOpenSelector} from "../../ducks/bff/disputes/selectors";
import {getNotificationUnreadCount} from "../../ducks/bff/notifications/actionCreators";
import {notificationUnreadCountSelector} from "../../ducks/bff/notifications/selectors";
import {
    crmChatGetMessageCount,
    crmChatUnderMessageCountSelector,
} from "../../ducks/chat";
import {
    chatUnreadCountSelector,
    getChatUnreadCount,
} from "../../ducks/chatList";
import {
    getRelatedClientUsers,
    relatedClientUsersListSelector,
    updateFieldClientStore,
} from "../../ducks/client";
import {clientCurrentMemberSelector, getClientUserCount} from "../../ducks/clientMember";
import {getNonDecisionCount} from "../../ducks/clientSpecialityOffer";
import {
    cancelledChecksIndicatorSelector,
    hasCancelledChecks,
    updateFieldOrderWorkReportStore,
} from "../../ducks/orderWorkReport";
import {sseEventDataSelector} from "../../ducks/serverSentEvents";

import {menuType} from "../../types";
import PropTypes from "prop-types";

import "./style.sass";

class Menu extends Component {
    static propTypes = {
        menuList: PropTypes.arrayOf(menuType),
        pathname: PropTypes.string,
        logoutRequest: PropTypes.func,
        currentMember: PropTypes.object,
    };

    static defaultProps = {
        menuList: [],
        pathname: "",
        logoutRequest: () => {
        },
        currentMember: {},
        media: {},
    };

    constructor(props) {
        super(props);
        const {
            menuList,
        } = props;

        this.role = ls(USER_ROLE);
        this.versionApp = "1.34.50";
        this.clientId = ls(CURRENT_CLIENT_ID);
        this.clientUserId = ls(CURRENT_CLIENT_USER_ID);
        this.restrictions = getUserRestrictions();

        this.state = {
            menuList,
            childMenuPosition: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {designMenuType} = state;

        if (!designMenuType && !isEqual(designMenuType, props.card.designMenuType)) {
            return {
                ...state,
                designMenuType: props.card.designMenuType,
            };
        }

        return null;
    }

    componentDidMount() {
        const {
            getOpenDisputesTotalCount,
            getForemenNewRequestsAndPaymentsCount,
            getNotificationUnreadCount,
            getNonDecisionCount,
            getStatementsInfoCount,
            getEdoDocumentInfoCount,
            getRelatedClientUsers,
            getClientUserCount,
            getClientMaintenanceNotice,
        } = this.props;

        if ([CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN, CLIENT_ACCOUNTANT].includes(this.role)) {
            getForemenNewRequestsAndPaymentsCount();
            this.getNotificationCount();
            this.hasCancelledChecks();
            this.getChatsUnreadMessageCount();
        }

        if (HR_MANAGER === this.role) {
            this.getNotificationCount();
        }

        if ([HR_MANAGER, FOREMAN, OBJECT_MANAGER, PROJECT_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)) {
            getStatementsInfoCount();
            getEdoDocumentInfoCount();
        }

        if (![NM_CONSULTANT, RNKO, HR_MANAGER, NM_PARTNER].includes(this.role)) {
            getOpenDisputesTotalCount();
        }

        if ([ADMIN, NM_MANAGER].includes(this.role)) {
            getNonDecisionCount();
            // Индикатор аннулированных чеков
            this.hasCancelledChecks();
            getClientUserCount({
                clientId: this.clientId,
            });
        }

        getRelatedClientUsers();

        getClientMaintenanceNotice();

        this.setMenu();
    }

    componentWillUnmount() {
        const {
            updateFieldOrderWorkReportStore,
            updateCommonClientStore,
        } = this.props;

        updateFieldOrderWorkReportStore({
            hasCancelledChecks: false,
        });

        updateCommonClientStore({
            maintenanceNotice: {},
        });
    }

    componentDidUpdate(prevProps) {
        const {
            pathname,
            currentMember: {
                clientUserId,
            },
            menuList,
            card,
            event,
        } = this.props;

        if (
            pathname !== prevProps.pathname
            || (clientUserId && clientUserId !== prevProps.currentMember.clientUserId)
        ) {
            this.updateMenu();
        }

        if (
            !isEmpty(menuList)
            && (
                !isEqual(prevProps.menuList, menuList)
                || !isEqual(card, prevProps.card)
            )
        ) {
            this.setState({
                menuList: [...menuList].filter(checkMenuItemRestrictions).map(this.checkChildMenu),
            }, () => {
                this.updateMenu();
            });
        }

        // if (!isEqual(prevProps.currentUserRestrictions, currentUserRestrictions)) {
        //     this.setMenu();
        // }

        if (
            !isEmpty(event)
            && !isEqual(prevProps.event, event)
            && [SSE_EVENT_TYPE.NEW_MESSAGE_CHAT].includes(event.eventType)
        ) {
            this.getChatsUnreadMessageCount();
        }
    }

    getChatsUnreadMessageCount = () => {
        const {
            crmChatGetMessageCount,
            getChatUnreadCount,
        } = this.props;

        getChatUnreadCount();
        crmChatGetMessageCount({readFilter: false});
    };

    getNotificationCount = () => {
        const {
            getNotificationUnreadCount,s,
        } = this.props;

        if (!isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientNotification,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessClientEdoNotifChats,
        ])) {
            return;
        }

        getNotificationUnreadCount({clientId: this.clientId});
    };

    hasCancelledChecks = () => {
        const {hasCancelledChecks} = this.props;

        hasCancelledChecks({
            clientId: this.clientId,
            clientUserId: this.clientUserId,
        });
    };

    renderMenuCounter(count) {
        if (isNullOrWhitespace(count) || count === 0) {
            return null;
        }

        return (
            <Counter count={count} />
        );
    }

    getInnerLinkContent(item) {
        const {
            icon,
            id,
            openChild,
        } = item;
        const {
            hasCancelledChecksIndicator,
            disputeTotalCount,
            unreadCount,
            newRequestsAndPaymentsCount,
            specialityNonDecisionCount,
            unreadMessagesCount,
            crmUnreadMessagesCount,
        } = this.props;

        if (icon === "business_24" && !openChild &&
            [
                CLIENT_ADMIN,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
                FOREMAN,
                CLIENT_ACCOUNTANT,
            ].includes(this.role)) {

            return this.renderMenuCounter(newRequestsAndPaymentsCount);
        }

        if (icon === "engineering") {
            return this.renderMenuCounter(newRequestsAndPaymentsCount);
        }

        if (id === "disputes") {
            return this.renderMenuCounter(disputeTotalCount);
        }

        if (id === "notifications") {
            return this.renderMenuCounter(unreadCount);
        }

        if (id === "settings" && [ADMIN, NM_MANAGER].includes(this.role)) {
            return this.renderMenuCounter(specialityNonDecisionCount);
        }

        if (id === "chat-list") {
            return this.renderMenuCounter(unreadMessagesCount + crmUnreadMessagesCount);
        }

        if (
            hasCancelledChecksIndicator &&
            id === "finance" &&
            !openChild &&
            [ADMIN, NM_MANAGER, CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, FOREMAN].includes(this.role)
        ) {
            return (
                <NmIcon
                    className="nmx-menu__canceled-checks-indicator"
                    name="canceled-checks-indicator"
                />
            );
        }

        return null;
    }

    openMenuItem = (id) => {
        const {menuList} = this.state;

        const _menuList = menuList.map(item => {
            if (item.id === id) {
                const {openChild} = item;

                return {
                    ...item,
                    openChild: !openChild,
                };
            }

            return {
                ...item,
                openChild: false,
            };
        });

        this.setState(prevState => ({
            ...prevState,
            menuList: _menuList,
            openId: prevState.openId === id ? null : id,
        }));
    };

    checkChildMenu = (item) => {
        const {
            pathname,
            card: {
                foremanEnabled,
                retailClient,
                retailClientUserIds,
                insuranceAvailable,
                registryPaymentsAvailable,
                civilRegistryPaymentsAvailable,
                individualRegistryPaymentsAvailable,
                migrantLicensePaymentEnabled,
                isRecruitmentAvailable,
                crowdTasksAvailable,
            },
            currentMember: {
                clientUserId,
            },
            media: {preDesktop},
        } = this.props;

        if (item.childMenu) {
            // Фильтруем подменю взаивимсоти от настроек клиента
            const filteredChildMenu = item.childMenu.filter(item => {
                const {
                    isVisible = true,
                } = item;
                return (
                    isVisible
                    && filterDynamicLinks({
                        item,
                        checks: {
                            foremanEnabled,
                            insuranceAvailable,
                            registryPaymentsAvailable,
                            civilRegistryPaymentsAvailable,
                            individualRegistryPaymentsAvailable,
                            isAccessClientResources: retailClient && retailClientUserIds.includes(clientUserId),
                            migrantLicensePaymentEnabled,
                            isRecruitmentAvailable,
                            crowdTasksAvailable,
                        },
                    })
                );
            });

            // Определяем активность пункта подменю
            const activeChildItem = filteredChildMenu.find(item => {
                const isActiveLink = linkActiveChecker(pathname, {
                    activeExceptions: item.activeExceptions,
                    activePath: item.activePath,
                    to: item.to,
                });

                return isActiveLink === true;
            });

            return {
                ...item,
                // в адаптивной версии меню от 1200 до 1920 при клике по ссылкам подменю надо закрывать подменю
                openChild: preDesktop ? false : !!activeChildItem,
                childMenu: [
                    ...filteredChildMenu,
                ],
            };
        }

        return item;
    };

    setMenu = () => {
        const {menuList} = this.props;

        const _menuList = menuList
            .filter(checkMenuItemRestrictions)
            .map(this.checkChildMenu);

        this.setState({
            menuList: _menuList,
        });
    };

    updateMenu = () => {
        const {pathname} = this.props;
        const {menuList} = this.state;

        // Когда уже есть какой-то открытый пункт меню в процессе использования
        const isOpenChildByConfig = menuList.filter(item => Boolean(item.openChild)).length > 0;

        if (isOpenChildByConfig) {
            this.setMenu();

            return;
        }

        // На момент первоначальной загрузки меню, когда в меню определяем открыто ли подменю по ссылке
        const isOpenChild = menuList.filter(item => Boolean(item.childMenu)).some(item => item.childMenu.some(item => {
            const isActiveChild = linkActiveChecker(pathname, {
                activePath: item.activePath,
                to: item.to,
            });

            return isActiveChild === true;
        }));

        if (isOpenChild) {
            this.setMenu();
        }
    };

    renderMenuItems() {
        const {
            pathname,
            hasCancelledChecksIndicator,
            media: {preDesktop},
        } = this.props;

        const {
            menuList,
            childMenuPosition,
            designMenuType,
            menuOpen,
        } = this.state;

        return menuList.filter(({isVisible = true}) => isVisible).map((item) => {
            const {
                childMenu,
                activePath,
                icon,
                to,
                content,
                isSearchActiveInAllPath,
                svg,
                id,
                openChild,
                activeExceptions,
            } = item;

            const isActiveLink = linkActiveChecker(pathname, {
                activePath,
                to,
                isSearchActiveInAllPath,
                activeExceptions,
            });

            return (
                <MenuItem
                    key={id}
                    svg={svg}
                    pathname={pathname}
                    openChild={openChild}
                    childMenuPosition={childMenuPosition}
                    setChildMenuPosition={this.setChildMenuPosition}
                    onClick={() => {
                        this.openMenuItem(id);
                    }}
                    onLinkClick={() => {
                        menuOpen && this.toggleMobileMenu();
                    }}
                    isActiveLink={isActiveLink}
                    icon={icon}
                    content={content}
                    childMenu={childMenu}
                    innerContent={this.getInnerLinkContent(item)}
                    to={to}
                    hasCancelledChecksIndicator={hasCancelledChecksIndicator}
                    preDesktop={preDesktop}
                    designMenuType={designMenuType}
                />
            );
        });
    }

    handleLogout = () => {
        this.props.logoutRequest();
    };

    get classNames() {
        const {menuOpen} = this.state;
        const classNames = ["nmx-menu"];

        if (this.role === CLIENT_ADMIN || this.role === PROJECT_MANAGER) {
            classNames.push("nmx-menu_client-admin");
        }

        if (menuOpen) {
            classNames.push("nmx-menu_open");
        }

        return classNames.join(" ");
    }

    setChildMenuPosition = (childMenuPosition) => {
        this.setState({
            childMenuPosition,
        });
    };

    toggleMobileMenu = () => {
        this.setState(prevState => {
            if (!prevState.menuOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }

            return {
                ...prevState,
                menuOpen: !prevState.menuOpen,
            };
        });
    };

    onScrollMenu = () => {
        const {openId} = this.state;

        if (openId) {
            this.openMenuItem(openId);
            this.setChildMenuPosition({});
        }
    };

    getMaintenanceSticker = () => {
        const {
            maintenanceNotice: {
                description,
                publish,
                title,
            },
        } = this.props;

        if (!publish || !isClientUser(this.role)) {
            return null;
        }

        return (
            <div className="nmx-menu__info nmx-menu__info_maintenance">
                <MaintenanceIcon
                    width={34}
                    height={34}
                    color={COLOR.INERT_70}
                    className="nmx-menu__maintenance-icon"
                />
                <div className="nmx-menu__maintenance-container">
                    <Text
                        level="3"
                        color={COLOR.INERT_70}
                        className="mt-3"
                    >
                        {title}
                    </Text>
                </div>
                <div className="nmx-menu__maintenance-description">
                    <NmAdvancedTooltip
                        trigger={
                            <InfoIcon color={COLOR.INERT_70} />
                        }
                        position="bottom-left"
                    >
                        {description}
                    </NmAdvancedTooltip>
                </div>
            </div>
        );
    };

    getLogo = () => {
        const {designMenuType} = this.state;

        if (isNMUsers()) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_naimix" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.HO_RE_CA_MENU) {
            return (
                <HorecaLogo className="nmx-menu__logo nmx-menu__logo_hoReCa" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.MTS_BANK_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_mts" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.ROS_KAP_STROI_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_ros-kap-stroi" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.HELP_RESOURCE_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_help-resource" />
            );
        }

        if (designMenuType === MENU_DESIGN_TYPE.PROMOPOISK_MENU) {
            return (
                <div className="nmx-menu__logo nmx-menu__logo_promopoisk" />
            );
        }

        return (
            <div className="nmx-menu__logo nmx-menu__logo_naimix" />
        );
    };

    render() {
        const {
            t,
            media,
            currentMember,
            listRelatedClientUsers,
            pathname,
            specialityNonDecisionCount,
        } = this.props;
        const {menuOpen} = this.state;

        return (
            <>
                <ServerSentEvents />
                <div className={this.classNames}>
                    <div className="nmx-menu__container">
                        <MenuProfile
                            currentMember={currentMember}
                            clientId={this.clientId}
                            media={media}
                            open={menuOpen}
                            listRelatedClientUsers={listRelatedClientUsers}
                            pathname={pathname}
                            specialityNonDecisionCount={specialityNonDecisionCount}
                            handleLogout={this.handleLogout}
                        />
                        <MenuHamburger
                            onClick={this.toggleMobileMenu}
                            open={menuOpen}
                        />
                        <MenuItemsContainer
                            className="nmx-menu__list-container"
                            onScroll={this.onScrollMenu}
                        >
                            {this.renderMenuItems()}
                        </MenuItemsContainer>
                        <div className="nmx-menu__bottom-container">
                            {this.getMaintenanceSticker()}
                            <div className="nmx-menu__info">
                                {this.getLogo()}
                                <div className="nmx-menu__version">
                                    <span className="nmx-menu__version-word">
                                        {t("menu.version-text")}
                                    </span>
                                    <span>
                                        {` UI ${this.versionApp}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    menuOpen &&
                    <div
                        onClick={this.toggleMobileMenu}
                        className="nmx-menu__layer"
                    />
                }
            </>
        );
    }
}


export default connect(
    state => ({
        pathname: state.router.location.pathname,
        currentMember: clientCurrentMemberSelector(state),
        disputeTotalCount: disputesMenuTotalCountOpenSelector(state),
        unreadCount: notificationUnreadCountSelector(state),
        newRequestsAndPaymentsCount: clientForemenNewRequestsAndPaymentsCountSelector(state),
        card: clientCardPropertiesSelector(state),
        specialityNonDecisionCount: state.clientSpecialityOffer.nonDecisionCount,
        hasCancelledChecksIndicator: cancelledChecksIndicatorSelector(state),
        listRelatedClientUsers: relatedClientUsersListSelector(state),
        unreadMessagesCount: chatUnreadCountSelector(state),
        crmUnreadMessagesCount: crmChatUnderMessageCountSelector(state),
        event: sseEventDataSelector(state),
        maintenanceNotice: bffCommonClientMaintenanceNoticeSelector(state),
    }),
    {
        logoutRequest,
        getOpenDisputesTotalCount,
        getForemenNewRequestsAndPaymentsCount,
        getNotificationUnreadCount,
        getNonDecisionCount,
        hasCancelledChecks,
        updateFieldOrderWorkReportStore,
        getStatementsInfoCount,
        getEdoDocumentInfoCount,
        getRelatedClientUsers,
        changeAccount,
        updateFieldClientStore,
        getClientUserCount,
        getChatUnreadCount,
        crmChatGetMessageCount,
        getClientMaintenanceNotice,
        updateCommonClientStore,
    },
)(withTranslation()(Menu));
