import {createSelector} from "reselect";

import {getTotalPages} from "../../../../../utils/mathHelper";

export const bffRegistryInvitationsCardSelector = state => state.bff.registryInvitationsCard;

export const registryInvitationsCardSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({card}) => card,
);
export const registryInvitationsProgressSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({progress}) => progress,
);
export const registryInvitationsActionProgressSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({actionProgress}) => actionProgress,
);
export const registryInvitationsContractorsTotalPagesSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);
export const registryInvitationsContractorsTotalCountSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({totalCount}) => totalCount,
);
export const registryInvitationsContractorsListSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({list}) => list,
);
export const registryInvitationsContractorsProgressListSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({progressList}) => progressList,
);
export const registryInvitationsContractorsActionProgressSelector = createSelector(
    bffRegistryInvitationsCardSelector,
    ({contractorsActionProgress}) => contractorsActionProgress,
);
