import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useInterval} from "../../../../../../hooks/useInterval";

import {isNullOrWhitespace} from "../../../../../../utils/stringHelper";
import {toastError} from "../../../../../../utils/toastHelper";

import {PHONE_CODE_TYPE, RUSSIA_PHONE_CODE_ID} from "../../../../../../constants/phone";

import {sendStatementCodeVerification} from "../../../../../../ducks/bff/clients/edo/statements/actionCreators";
import {clientCurrentMemberSelector} from "../../../../../../ducks/clientMember";

export type useCodeConfirmReturnType = {
    isVisibleCodeConfirm: boolean,
    loadingCode: boolean,
    setVisibleCodeConfirm: (flag: boolean) => void,
    userPhone: string,
    sendConfirmationCodeToUser: () => void,
    checkCode: () => void,
    onChangeCode: (value: string) => void,
    timer: number,
    errorMessageCode: string,
    setLoadingCode: (flag: boolean) => void,
    code?: string,
    codeConfirmType?: string,
};

export default function useCodeConfirm(submitForm: (code: string) => Promise<string>): useCodeConfirmReturnType {
    const [code, setCode] = useState("");

    const [isVisibleCodeConfirm, setVisibleCodeConfirm] = useState<boolean>(false);
    const [codeConfirmType, setCodeConfirmType] = useState(PHONE_CODE_TYPE.SMS);
    const [errorMessageCode, setErrorMessageCode] = useState<string>("");

    const [loadingCode, setLoadingCode] = useState<boolean>(false);

    const DEFAULT_TIME = 60;

    const {phone} = useSelector(clientCurrentMemberSelector);

    const [timer, setTimer] = useState(DEFAULT_TIME);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isVisibleCodeConfirm) {
            sendCode();
        } else {
            setErrorMessageCode("");
        }
    }, [isVisibleCodeConfirm]);


    const getResultSendCode = (errorMessage: string) => {
        setTimer(DEFAULT_TIME);
        if (errorMessage) {
            setCode("");
            toastError(errorMessage);
            console.error(errorMessage);
        }
    };

    const sendCode = (codeType?: string) => {
        dispatch(sendStatementCodeVerification({
            codeType,
            phoneCodeId: RUSSIA_PHONE_CODE_ID,
            getResult: ({codeType, errorMessage}: {codeType: string, errorMessage: string}) => {
                setCodeConfirmType(codeType);
                getResultSendCode(errorMessage);
            },
        }));
    };

    useInterval(() => {
        if (timer > 0) {
            setTimer(timer - 1);
        }
    }, 1000);


    const onChangeCode = (value: string) => {
        errorMessageCode && setErrorMessageCode("");
        setCode(value);
    };

    const submit = (): void => {
        if (errorMessageCode) {
            return;
        }

        if (isNullOrWhitespace(code)) {
            setErrorMessageCode("Обязательное поле");
            return;
        }

        setLoadingCode(true);

        submitForm(code)
            .then(() => {
                setLoadingCode(false);
                setVisibleCodeConfirm(false);
            })
            .catch((error) => {
                console.log("submit error", error);
                setErrorMessageCode(error);
            })
            .finally(() => setLoadingCode(false));
    };

    return {
        isVisibleCodeConfirm,
        setVisibleCodeConfirm,
        userPhone: phone,
        sendConfirmationCodeToUser: sendCode,
        loadingCode,
        checkCode: submit,
        onChangeCode,
        timer,
        code,
        errorMessageCode,
        setLoadingCode,
        codeConfirmType,
    };
}