import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmDropdownV2 from "../NmDropdownV2";

import {useGetPrevValue} from "../../../hooks/useGetPrevValue";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import {handleFormString} from "../../../utils/stringHelper";

import {
    getProjectsList,
    getProjectsOptionsSelector,
    updateFieldsStoreProject,
} from "../../../ducks/projects";

interface IFilterProject {
    onChange: (event: any, params: any) => void,
    projectIdsFilter: [],
    name: string,
    size?: "xl" | "lg",
    className?: string,
    clientId?: string,
    onSubmit?: () => void,
    isActiveStyle?: boolean,
    pageSize?: number,
    statusFilter?: string
    skipProjectWithAllClosedObject?: boolean,
    label?: string,
    multiple?: boolean,
    clientIdsFilter?: [],
    searchEmptyByValueFilter?: boolean,
    listFieldName?: string,
    options?: Array<OptionType>,
    isOnlyWithClientIdsFilter?: boolean,
    isMountLoad?: boolean,
}

const FilterProject = (props: IFilterProject) => {
    const {
        projectIdsFilter = [],
        clientIdsFilter,
        size = "lg",
        clientId,
        isActiveStyle = projectIdsFilter.length === 0,
        pageSize = 10,
        statusFilter,
        skipProjectWithAllClosedObject,
        label,
        multiple = true,
        searchEmptyByValueFilter = true,
        listFieldName = "projectsList",
        options,
        isOnlyWithClientIdsFilter = false,
        isMountLoad = false,
    } = props;
    const projectOptions = useSelector(getProjectsOptionsSelector);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const prevClientIdsFilter = useGetPrevValue(clientIdsFilter);

    useEffect(() => {
        if (isOnlyWithClientIdsFilter && isEmpty(clientIdsFilter)) {
            !isEmpty(prevClientIdsFilter) && dispatch(updateFieldsStoreProject({[listFieldName]: []}));

            return;
        }

        fetchProjects();
    }, [
        clientId,
        clientIdsFilter,
    ]);

    const fetchProjects = (nameSubstringFilter?: string) => {
        dispatch(getProjectsList({
            clientId,
            pageNum: 1,
            pageSize,
            sortType: "asc",
            sortName: "name",
            nameSubstringFilter: handleFormString(nameSubstringFilter),
            status: statusFilter,
            skipProjectWithAllClosedObject,
            clientIdsFilter,
            fieldName: listFieldName,
        }));
    };

    const handleOnSearchProject = (valueFilter: string) => {
        if (!searchEmptyByValueFilter && !valueFilter) {
            return;
        }

        fetchProjects(valueFilter);
    };

    return (
        <NmDropdownV2
            {...props}
            size={size}
            label={label || t("orders-filter.project")}
            multiple={multiple}
            onSearchChange={handleOnSearchProject}
            search
            value={projectIdsFilter}
            options={options || projectOptions}
            isActiveStyle={isActiveStyle}
            isMountLoad={isMountLoad}
        />
    );
};

export default FilterProject;