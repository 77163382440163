import {useMemo} from "react";
import {useSelector} from "react-redux";

import {FILTER} from "../../../../components/ActualComponents/Filter";

import {useFilter} from "../../../../hooks/useFilter";

import {getEndFilterDateWithTimeInUtc, getStartFilterDateWithTimeInUtc} from "../../../../utils/dateFormat";
import {filterEmptyValues} from "../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../utils/stringFormat";
import {handleFormString} from "../../../../utils/stringHelper";

import {bffDeferredActsOfAcceptanceWorkStatusDictOptionsSelector} from "../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/selectors";

export const useDeferredActsOfAcceptanceWorkFilters = (params) => {
    const {
        pageSize,
        setPagination,
    } = params;

    const statusDictOptions = useSelector(bffDeferredActsOfAcceptanceWorkStatusDictOptionsSelector);

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filter,
    } = useFilter({
        pageSize,
        setPagination,
        mapFilterDto: (filters) => {
            return filterEmptyValues({
                statusFilter: handleFormString(filters.statusFilter),
                creatorClientUserNameFilter: handleFormString(filters.creatorClientUserNameFilter),
                contractorNameFilter: handleFormString(filters.contractorNameFilter),
                nameSubstringFilter: handleFormString(filters.nameSubstringFilter),
                creationDateTimeFromFilter: getStartFilterDateWithTimeInUtc(filters.creationDateTimeFromFilter),
                creationDateTimeToFilter: getEndFilterDateWithTimeInUtc(filters.creationDateTimeToFilter),
                contractorPhoneFilter: removePhoneMask(filters.contractorPhoneFilter),
            });
        },
    });

    const filters = useMemo(() => {
        return [
            {
                row: [
                    {
                        component: FILTER.DROPDOWN,
                        name: "statusFilter",
                        options: statusDictOptions,
                        label: "Статус",
                        placeholder: "Выберите статус",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.DATE_RANGE,
                        startFieldName: "creationDateTimeFromFilter",
                        endFieldName: "creationDateTimeToFilter",
                        label: "Дата создания",
                        isClearable: true,
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Название реестра",
                        placeholder: "Введите название",
                        name: "nameSubstringFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "Ответственный",
                        placeholder: "Введите ФИО",
                        name: "creatorClientUserNameFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.INPUT,
                        label: "ФИО исполнителя",
                        placeholder: "Введите ФИО",
                        name: "contractorNameFilter",
                    },
                ],
            },
            {
                row: [
                    {
                        component: FILTER.PHONE_WITH_CODE,
                        name: "contractorPhoneFilter",
                        label: "Номер телефона",
                    },
                ],
            },
        ];
    }, [
        statusDictOptions,
    ]);

    return {
        isSearch,
        onClear,
        onSearch,
        filterData,
        filters,
        filter,
    };
};