import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import ContextMenu from "../../../components/ActualComponents/ContextMenu";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import Text from "../../../components/ActualComponents/Text";
import ByTaskUpdater from "../../../components/ByTaskUpdater";
import CheckboxList from "../../../components/CheckboxList";
import DepositTypeText from "../../../components/DepositTypeText";
import DepositValuesAmountInfo from "../../../components/DepositValuesAmountInfo";
import ExtLink from "../../../components/ExtLink";
import NmPage from "../../../components/NmPage";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import UnavailableTemplates from "../../../components/UnavailableTemplates";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import OrderTemplateInfo from "../../order/template-info";
import {CreateRegistryPaymentDeferredActs} from "../create-deferred-acts";
import {getCreateTypeDescription} from "../registryActsType";
import RegistriesListLog from "./log";
import RegistryDuplicatePaymentsList from "./registry-duplicate-payments-list";
import RegistryFilter from "./registry-filter";
import RegistryNewPopup from "./registry-new-popup";

import {getArchiveButton} from "../../../components/ActualComponents/MediaControls/utils/getArchiveButton";
import {getUnavailableTemplatesFlags} from "../../../components/UnavailableTemplates/utils";
import {isNMUsers} from "../../../utils/access";
import dateFormat, {convertUtcToLocal} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isVisibleRegistryCreateDeferredActsBtn} from "../../../utils/registry";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {formatAmount, formatNumber, getFullName} from "../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastError, toastSuccess} from "../../../utils/toastHelper";
import {filterContextMenuByPropsVisibleRules} from "../../finance/finance-payment-list/utils/filterByPropsVisibleRules";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE} from "../../../components/UnavailableTemplates/constants";
import {PAYMENT_METHOD_DICT} from "../../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {COLOR} from "../../../constants/color";
import {CLIENT_TYPE_CODE} from "../../../constants/dicts";
import {
    ORDER_AMOUNT_CALCULATION_METHOD_DICT,
    ORDER_WORK_REPORT_TYPE,
    ORDER_WORK_REPORT_TYPE_TRANSLATE,
} from "../../../constants/finance";
import {
    LINK_CLIENT_INFO,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST,
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST,
    LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED,
} from "../../../constants/links";
import {REGISTRY_PAYMENT_ERRORS, REGISTRY_STATUS, STATUS_COLOR_LIST} from "../../../constants/registry";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {getClientCardProperties} from "../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoProgressSelector,
    clientCardInfoSelector,
    clientCardPropertiesSelector,
    getClientTypeSelector,
} from "../../../ducks/bff/clients/info/selectors";
import {
    addRegistry,
    archiveRegistryPayment, createDuplicateRegistry,
    deleteRegistry,
    discardOutstandingPayments,
    getIsFrameContractSignedForContractorsOnRegistry,
    getPageRegistryPayment,
    getRegistryStatusDict,
    payRegistryPayment,
    updateRegistry,
    updateRegistryStoreField,
} from "../../../ducks/bff/clients/registry-payment/actionCreators";
import {getRegistryById} from "../../../ducks/bff/clients/registry-payment/card/actionCreators";
import {registriesCardSelector} from "../../../ducks/bff/clients/registry-payment/card/selectors";
import {
    registriesListFormattedToTaskIdsSelector,
    registriesSuccessActionSelector,
    registriesSuccessAddSelector,
    registryPaymentListSelector,
    registryPaymentProgressListSelector,
    registryPaymentTotalCountSelector,
    registryPaymentTotalPagesSelector, registryStatusDictSelector,
} from "../../../ducks/bff/clients/registry-payment/selectors";
import {getDocumentsCustomTemplateReplacementsForUnavailableList} from "../../../ducks/bff/documents-templates/actionCreators";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getDepositList, updateFieldDepositStore} from "../../../ducks/deposit";
import {getAllTasks, jobTasksRegistryDiscardPaymentsSelector} from "../../../ducks/job";

import "./style.sass";

class RegistryList extends Component {
    constructor(props) {
        super(props);

        const {
            match: {
                params: {
                    clientId,
                    archived = "false",
                },
            },
        } = props;

        this.state = {
            archived: JSON.parse(archived),
            pageNum: 1,
            pageSize: 25,
            isOpenFilter: true,
            registryObj: {},
            error: {},
            payModel: [],
            unavailableTemplatesModalData: {},
            confirmData: {},
            isSearch: false,
            dataLog: {},
            isOpenCreateDeferredActs: false,
            item: null,
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId;
        this.isClientCard = !isNullOrWhitespace(this.clientId);
    }

    componentDidMount() {
        const {
            getRegistryStatusDict,
            getClientCardProperties,
            getDepositList,
        } = this.props;

        {
            this.isClientCard &&
            getDepositList({
                clientId: this.clientId,
                pageNum: 1,
                pageSize: 1,
            });
        }

        document.addEventListener("keypress", this.onKeyPress);

        this.fetchRegistryList();


        getRegistryStatusDict();

        if (this.isClientCard) {
            getClientCardProperties({
                clientId: this.clientId,
            });
        }
    }

    componentWillUnmount() {
        const {updateFieldDepositStore} = this.props;

        updateFieldDepositStore({depositList: []});

        document.removeEventListener("keypress", this.onKeyPress);
    }

    componentDidUpdate(prevProps) {
        const {
            updateRegistryStoreField,
            isSuccessAdd,
            isSuccessAction,
        } = this.props;

        if (isSuccessAdd) {
            updateRegistryStoreField({
                isSuccessAddOrEdit: false,
            });

            this.showAddRegistryForm(false);
        }

        if (isSuccessAction) {
            updateRegistryStoreField({
                isSuccessAction: false,
            });
        }
    }

    get isAccessActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientRegistryListAndCard,
        ]);
    }

    get isAccessAddEditDelPayRegistries() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.addEditDelPayRegistries,
        ]);
    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    onKeyPress = event => {
        const {showAddRegistryForm} = this.state;

        if (event.keyCode === 13 && !showAddRegistryForm) {
            this.fetchRegistryList();
        }
    };

    fetchRegistryList = () => {
        const {getPageRegistryPayment} = this.props;

        const {
            pageNum,
            pageSize,
            numberFilter,
            nameFilter,
            clientFilter,
            statusesFilter,
            fromDateCreateFilter,
            toDateCreateFilter,
            archived: archivedFilter,
            createActsTypeFilter,
            orderContractPaymentTypeFilter,
            creatorFioFilter,
            projectIds,
            objectIds,
        } = this.state;

        getPageRegistryPayment({
            pageNum,
            pageSize,
            clientId: this.isClientCard ? this.clientId : clientFilter,
            numberFilter: numberFilter ? numberFilter : undefined,
            nameFilter: nameFilter ? nameFilter : undefined,
            statusesFilter: !isEmpty(statusesFilter) ? statusesFilter : undefined,
            fromDateCreateFilter: fromDateCreateFilter ? fromDateCreateFilter : undefined,
            toDateCreateFilter: toDateCreateFilter ? toDateCreateFilter : undefined,
            archivedFilter,
            createActsTypeFilter: createActsTypeFilter ? [createActsTypeFilter] : undefined,
            orderContractPaymentTypeFilter: orderContractPaymentTypeFilter === "ALL" ? undefined : orderContractPaymentTypeFilter,
            creatorFioFilter: handleFormString(creatorFioFilter),
            projectIds: !isEmpty(projectIds) ? projectIds : undefined,
            objectIds: !isEmpty(objectIds) ? objectIds : undefined,
        });
    };

    renderRegistryLink = ({registryId, name, ordinalNumber, clientId}) => {
        const {archived} = this.state;

        const link = LINK_CLIENT_REGISTRY_PAYMENTS_CARD
            .replace(":registryId", registryId)
            .replace(":archived", archived)
            .replace(":clientId", clientId)
            .replace("/:paymentNumberFilter?", "");

        return (
            <ExtLink to={link}>
                {`№${ordinalNumber} — ${name}`}
            </ExtLink>
        );
    };

    showConfirmWindow = ({confirmMethod, confirmText, confirmTitle}) => {
        this.setState({
            openConfirmWindow: true,
            confirmMethod,
            confirmData: {
                confirmText,
                confirmTitle,
            },
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            openConfirmWindow: false,
            confirmText: "",
            confirmMethod: () => {
            },
        });
    };

    renderConfirmWindow() {
        const {
            openConfirmWindow,
            confirmData: {
                confirmText,
                confirmTitle,
            },
            confirmMethod,
        } = this.state;

        const {t} = this.props;

        return openConfirmWindow &&
            <NmConfirmV2
                title={confirmTitle}
                content={confirmText}
                contentAlign={confirmTitle ? "left" : "center"}
                onCancel={this.handleCancelConfirm}
                onConfirm={confirmMethod}
                confirmButton={t("button.confirm")}
                cancelButton={t("button.cancel")}
            />;
    }

    deleteRegistryAction = (clientId, registryId) => {
        return () => {
            const {deleteRegistry} = this.props;

            deleteRegistry({
                clientId,
                registryId,
            });
            this.handleCancelConfirm();
        };
    };

    createDuplicateRegistryAction = (clientId, registryId) => {
        return () => {
            const {createDuplicateRegistry} = this.props;

            createDuplicateRegistry({
                clientId,
                registryId,
            });
            this.handleCancelConfirm();
        };
    };

    payOutstandingRegistryPayments = (clientId, registryId) => {
        const {
            getIsFrameContractSignedForContractorsOnRegistry,
            clientType,
            t,
        } = this.props;

        const onSuccess = (frameContractSigned = {}) => {
            const isShowWarning = clientType !== CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY && frameContractSigned.result;

            this.showConfirmWindow({
                confirmMethod: () => this.payOutstandingRegistryAction(clientId, registryId),
                confirmTitle: isShowWarning ? t("registries.action-pay-outstanding-confirm") : "",
                confirmText: isShowWarning ? t("order-micro-job-seekers.migration-warning-msg") : t("registries.action-pay-outstanding-confirm"),
            });
        };

        getIsFrameContractSignedForContractorsOnRegistry({
            clientId,
            registryId,
            onSuccess,
        });
    };

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    payOutstandingRegistryAction = (clientId, registryId) => {
        this.payRegistryAction(clientId, registryId);
        this.handleCancelConfirm();
    };

    handleErrorPay = (clientId, registryId, checkDuplicate = true) => {
        return (errorCode, warningMsg, errorMessage) => {
            switch (errorCode) {
                case REGISTRY_PAYMENT_ERRORS.APPLICATION_DATE_IS_BEFORE_FRAME_CONTRACT_DATE_OR_IS_AFTER_WORK_START_DATE:
                    this.showConfirmWindow({
                        confirmMethod: () => this.payRegistryActionContinue(clientId, registryId, checkDuplicate),
                        confirmText: warningMsg,
                    });
                    return;
                default: {
                    toastError(errorMessage);
                    return;
                }
            }
        };
    };

    handleResponsePay = (payModel) => {
        this.setState({
            payModel,
        });
    };

    payRegistryAction = (clientId, registryId, invalidApplicationDateConfirmed = null, checkDuplicate = true) => {
        const {payRegistryPayment} = this.props;

        payRegistryPayment({
            data: {
                clientId,
                registryId,
                checkDuplicate,
                invalidApplicationDateConfirmed,
            },
            handleResponse: this.handleResponsePay,
            handleError: this.handleErrorPay(clientId, registryId, checkDuplicate),
            onSuccess: () => {
                toastSuccess("Успешно запущена задача оплаты.");
                this.fetchRegistryList();
            },
        });
        this.handleCancelConfirm();
    };

    payRegistryActionContinue = (clientId, registryId, checkDuplicate) => {
        this.payRegistryAction(clientId, registryId, true, checkDuplicate);
    };

    cancelOutstandingRegistryAction = (clientId, registryId) => {
        return () => {
            const {discardOutstandingPayments} = this.props;

            discardOutstandingPayments({
                clientId,
                registryId,
                onSuccess: this.getTasks,
            });
            this.handleCancelConfirm();
        };
    };

    onOpenFilter = (isOpenFilter) => {
        this.setState({
            isOpenFilter,
        });
    };

    archiveRegistryAction = (clientId, registryId, archived) => {
        const {archiveRegistryPayment} = this.props;

        archiveRegistryPayment({
            clientId,
            registryId,
            archived,
            onSuccess: () => {
                toastSuccess(archived
                    ? "Реестр успешно добавлен в архив"
                    : "Реестр успешно восстановлен из архива",
                );
                this.fetchRegistryList();
                this.handleCancelConfirm();
            },
        });
    };

    getOptions = (item) => {
        const {
            registryId,
            status,
            paymentsTotalCount,
            paymentsDeclineCount,
            paymentsErrorCount,
            paymentsContractorDeclinedCount,
            description,
            creatorClientUserId,
            name,
            orderContractPaymentType,
            createActsOfAcceptanceOfWorkType,
            clientId,
            objectId,
            objectName,
            projectId,
            canceledCount,
            hasStartDeletingPayments,
            frameContractTemplateId,
            orderApplicationTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            orderAmountCalculationMethod,
            documentTablesEnabled,
            frameContractByObjectTemplate,
            paymentMethod,
            modificationAvailable,
        } = item;

        const {
            clientInfo: {
                civilRegistryPaymentsAvailable,
                individualRegistryPaymentsAvailable,
                registryPaymentsAvailable,
            },
            t,
        } = this.props;

        const {archived} = this.state;

        const LOG = {
            key: "LOG",
            text: "Лог",
            action: () => this.setDataLog(item),
            visible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.getRegisterPaymentsLog,
                CLIENT_USER_RESTRICTIONS_VARIABLE.getPayRegistriesLog,
            ]),
        };

        const DELETE_ACTION = {
            key: "DELETE_ACTION",
            text: t("registries.action-delete"),
            action: () => this.showConfirmWindow({
                confirmMethod: this.deleteRegistryAction(clientId, registryId),
                confirmText: t("registries.action-delete-confirm", {name}),
            }),
            visible: this.isAccessAddEditDelPayRegistries,
        };

        const ARCHIVE_ACTION = {
            key: "ARCHIVE_ACTION",
            text: t("registries.action-archive"),
            action: () => this.showConfirmWindow({
                confirmMethod: () => this.archiveRegistryAction(clientId, registryId, true),
                confirmText: t("registries.action-archive-confirm", {name}),
            }),
            visible: this.isAccessAddEditDelPayRegistries,
        };

        const UNARCHIVE_ACTION = {
            key: "UNARCHIVE_ACTION",
            text: t("registries.action-unarchive"),
            action: () => this.showConfirmWindow({
                confirmMethod: () => this.archiveRegistryAction(clientId, registryId, false),
                confirmText: t("registries.action-unarchive-confirm", {name}),
            }),
            visible: this.isAccessAddEditDelPayRegistries,
        };

        const CREATE_DUBLICATE_ACTION = {
            key: "CREATE_DUBLICATE_ACTION",
            text: t("registries.action-create-duplicate"),
            action: () => this.showConfirmWindow({
                confirmMethod: this.createDuplicateRegistryAction(clientId, registryId),
                confirmText: t("registries.action-create-duplicate-confirm", {name}),
            }),
            visible: this.isAccessAddEditDelPayRegistries,
        };

        const CREATE_DEFERRED_ACTS = {
            key: "CREATE_DEFFERED_ACTS",
            text: "Сформировать отложенные акты",
            action: () => {
                this.setState({
                    isOpenCreateDeferredActs: true,
                    item,
                });
            },
            visible: isVisibleRegistryCreateDeferredActsBtn(item),
        };

        const PAY_OUTSTANDING_ACTION = {
            key: "PAY_OUTSTANDING_ACTION",
            text: t("registries.action-pay-outstanding"),
            action: () => {
                this.payOutstandingRegistryPayments(clientId, registryId);
            },
            visible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentRegisterPayments,
                CLIENT_USER_RESTRICTIONS_VARIABLE.payRegistry,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_paymentRegisterPayments,
            ]),
        };

        const CANCEL_OUTSTANDING_ACTION = {
            key: "CANCEL_OUTSTANDING_ACTION",
            text: t("registries.action-cancel-outstanding"),
            action: () => this.showConfirmWindow({
                confirmMethod: this.cancelOutstandingRegistryAction(clientId, registryId),
                confirmText: t("registries.action-cancel-outstanding-confirm", {name}),
            }),
            visible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_paymentRegisterPayments,
            ]),
        };

        const PAY_ACTION = {
            key: "PAY_ACTION",
            text: t("registries.action-pay"),
            action: () => {
                this.checkUnavailableTemplates(item);
            },
            visible: isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.paymentRegisterPayments,
                CLIENT_USER_RESTRICTIONS_VARIABLE.payRegistry,
                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_paymentRegisterPayments,
            ]),
        };

        const EDIT_REGISTRY_ACTION = {
            key: "EDIT_REGISTRY_ACTION",
            text: t("registries.action-edit"),
            action: () => {
                this.showAddRegistryForm(true, item);
            },
            visible: this.isAccessAddEditDelPayRegistries,
        };

        if (
            NM_OPERATOR === this.role
            || !isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.changesRegisterPayments,
            ])
            || !this.isAccessActions
        ) {
            return [LOG];
        }

        if (this.isClientArchived) {

            return status === REGISTRY_STATUS.FULLY_PAID ? null : [CANCEL_OUTSTANDING_ACTION];
        }

        if (archived) {
            return [UNARCHIVE_ACTION];
        }

        const options = [
            CREATE_DEFERRED_ACTS,
        ];

        if (!modificationAvailable) {
            return [];
        }

        if (
            !documentTablesEnabled
            && (
                !this.clientId
                || (
                    orderContractPaymentType === ORDER_WORK_REPORT_TYPE.SMZ && registryPaymentsAvailable
                    || orderContractPaymentType === ORDER_WORK_REPORT_TYPE.CIVIL && civilRegistryPaymentsAvailable
                    || orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL && individualRegistryPaymentsAvailable
                )
            )
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.creatDuplicateRegistry,
                CLIENT_USER_RESTRICTIONS_VARIABLE.addEditDelPayRegistries,
            ])
        ) {
            options.push(CREATE_DUBLICATE_ACTION);
        }

        if (isNMUsers()) {
            options.push(LOG);
        }

        switch (status) {
            case REGISTRY_STATUS.DRAFT:
                if ([CLIENT_ACCOUNTANT, FOREMAN, OBJECT_MANAGER].includes(this.role)) {
                    return [
                        EDIT_REGISTRY_ACTION,
                        ARCHIVE_ACTION,
                        DELETE_ACTION,
                        ...options,
                    ];
                }

                if (paymentsTotalCount && [ADMIN, CLIENT_ADMIN, PROJECT_MANAGER].includes(this.role)) {
                    return [
                        PAY_ACTION,
                        EDIT_REGISTRY_ACTION,
                        ARCHIVE_ACTION,
                        DELETE_ACTION,
                        ...options,
                    ];
                }

                return [
                    EDIT_REGISTRY_ACTION,
                    ARCHIVE_ACTION,
                    DELETE_ACTION,
                    ...options,
                ];
            case REGISTRY_STATUS.FULLY_PAID:
                return [ARCHIVE_ACTION, ...options];
            case REGISTRY_STATUS.NOT_PAID:
                if ([CLIENT_ACCOUNTANT].includes(this.role)) {
                    return [ARCHIVE_ACTION, ...options];
                }

                if (
                    this.role === NM_MANAGER
                    && (
                        paymentsDeclineCount
                        || paymentsErrorCount
                        || paymentsContractorDeclinedCount
                    )
                    && !hasStartDeletingPayments
                ) {
                    return [ARCHIVE_ACTION, CANCEL_OUTSTANDING_ACTION, ...options];
                }

                if (
                    (
                        paymentsDeclineCount
                        || paymentsErrorCount
                        || paymentsContractorDeclinedCount
                    )
                    && !hasStartDeletingPayments
                    && paymentsTotalCount !== canceledCount
                ) {
                    return [OBJECT_MANAGER, FOREMAN].includes(this.role) ?
                        [ARCHIVE_ACTION, ...options] :
                        [PAY_OUTSTANDING_ACTION, CANCEL_OUTSTANDING_ACTION, ARCHIVE_ACTION, ...options];
                }

                return [ARCHIVE_ACTION, ...options];
            case REGISTRY_STATUS.FOR_PAYMENT:
            case REGISTRY_STATUS.PARTIALLY_PAID:
                if ([CLIENT_ACCOUNTANT, NM_MANAGER].includes(this.role)) {
                    return options;
                }

                if (
                    this.role === NM_MANAGER
                    && (
                        paymentsDeclineCount
                        || paymentsErrorCount
                        || paymentsContractorDeclinedCount
                    )
                    && !hasStartDeletingPayments
                ) {
                    return [ARCHIVE_ACTION, CANCEL_OUTSTANDING_ACTION, ...options];
                }


                if (
                    (
                        paymentsDeclineCount
                        || paymentsErrorCount
                        || paymentsContractorDeclinedCount
                    )
                    && !hasStartDeletingPayments
                    && paymentsTotalCount !== canceledCount
                ) {

                    return [OBJECT_MANAGER, FOREMAN].includes(this.role) ?
                        [CANCEL_OUTSTANDING_ACTION, ...options] :
                        [PAY_OUTSTANDING_ACTION, CANCEL_OUTSTANDING_ACTION, ...options];
                }

                return options;

            // no default
        }
    };

    checkUnavailableTemplates = (item) => {
        const {
            clientId,
            registryId,
            frameContractTemplateId,
            orderApplicationTemplateId,
            actOfAcceptanceOfWorkTemplateId,
            orderContractPaymentType,
        } = item;
        const {getDocumentsCustomTemplateReplacementsForUnavailableList, t} = this.props;

        // Проверяем на недоступные шаблоны
        getDocumentsCustomTemplateReplacementsForUnavailableList({
            clientId,
            paymentsRegistryId: registryId,
            paymentType: orderContractPaymentType,
            customDocumentIds: [
                frameContractTemplateId,
                orderApplicationTemplateId,
                actOfAcceptanceOfWorkTemplateId,
            ].filter(value => value),
            onSuccess: (data) => {
                const {
                    isDisabledTemplateClientSetting,
                } = getUnavailableTemplatesFlags(data);

                // Отображаем при отключенной опции "Свои шаблоны"
                if (isDisabledTemplateClientSetting) {
                    this.onOpenUnavailableTemplates(item);

                    return;
                }

                this.showConfirmWindow({
                    confirmMethod: () => this.payRegistryAction(clientId, registryId),
                    confirmText: t("registries.action-pay-confirm"),
                });
            },
        });
    };

    onCloseUnavailableTemplates = () => {
        this.setState({
            isOpenUnavailableTemplates: false,
            unavailableTemplatesModalData: {},
        });
    };

    onOpenUnavailableTemplates = (registry) => {
        this.setState({
            unavailableTemplatesModalData: {registry},
            isOpenUnavailableTemplates: true,
        });
    };

    renderUnavailableTemplates = () => {
        const {t} = this.props;
        const {
            isOpenUnavailableTemplates,
            unavailableTemplatesModalData: {
                registry: {
                    clientId,
                    registryId,
                } = {},
            },
        } = this.state;

        if (!isOpenUnavailableTemplates) {
            return null;
        }

        return (
            <UnavailableTemplates
                disabledSettingContentType={UNAVAILABLE_TEMPLATES_SETTING_DISABLED_CONTENT_TYPE.REGISTRY_PAYMENTS}
                onClose={this.onCloseUnavailableTemplates}
                onSubmit={() => {
                    this.showConfirmWindow({
                        confirmMethod: () => this.payRegistryAction(clientId, registryId),
                        confirmText: t("registries.action-pay-confirm"),
                    });
                    this.onCloseUnavailableTemplates();
                }}
                header="Передача реестра в оплату"
                submitBtnContent="Передать в оплату"
            />
        );
    };

    onClickRegistryActionItem = (option) => {
        option.action();
    };

    renderAction = (item) => {
        if (
            [NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)
            || !item.contextMenuVisible
        ) {
            return null;
        }

        const options = this.getOptions(item);

        if (isEmpty(filterContextMenuByPropsVisibleRules(options))) {
            return null;
        }

        return (
            <ContextMenu
                className="registry-list__list-dropdown"
                options={options}
                onClickItem={(option) => {
                    this.onClickRegistryActionItem(option);
                }}
            />
        );
    };

    renderStatus = status => {
        const {
            statusDict,
        } = this.props;

        return (
            <div
                className="registry-list__status"
                style={STATUS_COLOR_LIST[status] ? STATUS_COLOR_LIST[status] : {}}
            >
                {statusDict[status]}
            </div>
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }
        this.setState(
            {
                pageNum,
            },
            this.fetchRegistryList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchRegistryList,
        );
    };

    showAddRegistryForm = (showAddRegistryForm, registryObj = {}) => {
        this.setState(prevState => ({
            ...prevState,
            registryObj,
            showAddRegistryForm,
        }));
    };

    handleChange = (e, {name, value}) => {
        this.setState({[name]: value});
    };

    toggleArchived = () => {
        const {archived} = this.state;

        if (this.isClientCard) {
            history.push(LINK_CLIENT_REGISTRY_PAYMENTS_LIST
                .replace(":clientId", this.clientId)
                .replace(":archived", !archived)
                .replace("/:paymentNumberFilter?", ""),
            );
        } else {
            history.push(LINK_FINANCE_REGISTRY_PAYMENTS_ARCHIVED
                .replace(":archived", !archived),
            );
        }

        this.setState(prevState => ({
            ...prevState,
            pageNum: 1,
            archived: !prevState.archived,
        }), this.fetchRegistryList);
    };

    submitFilter = () => {
        this.setState({
            isSearch: true,
            pageNum: 1,
        }, this.fetchRegistryList);
    };

    clearFilter = () => {
        this.setState({
            numberFilter: "",
            nameFilter: "",
            clientFilter: "",
            statusesFilter: [],
            fromDateCreateFilter: "",
            toDateCreateFilter: "",
            creatorFioFilter: "",
            createActsTypeFilter: "",
            orderContractPaymentTypeFilter: "ALL",
            isSearch: false,
            pageNum: 1,
            projectIds: [],
            objectIds: [],
        }, this.fetchRegistryList);
    };


    renderBaseFilter() {
        const {
            numberFilter,
            nameFilter,
            clientFilter,
            statusesFilter,
            fromDateCreateFilter,
            toDateCreateFilter,
            creatorFioFilter,
            orderContractPaymentTypeFilter,
            createActsTypeFilter,
            projectIds,
            objectIds,
        } = this.state;

        return (
            <RegistryFilter
                numberFilter={numberFilter}
                nameFilter={nameFilter}
                clientFilter={clientFilter}
                statusesFilter={statusesFilter}
                fromDateCreateFilter={fromDateCreateFilter}
                toDateCreateFilter={toDateCreateFilter}
                creatorFioFilter={creatorFioFilter}
                createActsTypeFilter={createActsTypeFilter}
                orderContractPaymentTypeFilter={orderContractPaymentTypeFilter}
                projectIds={projectIds}
                objectIds={objectIds}
                clear={this.clearFilter}
                search={this.submitFilter}
                handleChange={this.handleChange}
                isClientCard={this.isClientCard}
                clientId={this.clientId}
            />
        );
    }

    renderAddRegistryForm() {
        const {
            showAddRegistryForm,
            registryObj,
        } = this.state;

        if (!showAddRegistryForm) {
            return null;
        }

        const {clientId} = registryObj;

        return (
            <RegistryNewPopup
                clientId={this.isClientCard ? this.clientId : clientId}
                registryObj={registryObj}
                close={() => {
                    this.showAddRegistryForm(false);
                }}
            />
        );
    }

    closeDuplicatePaymentsList = () => {
        this.setState({
            payModel: [],
        });
    };

    renderDuplicatePaymentsList() {
        const {
            payModel,
        } = this.state;

        const {
            registryId,
            clientId: clientFromResponse,
        } = payModel;

        if (payModel.length === 0) {
            return null;
        }

        const clientId = this.clientId || clientFromResponse;

        return (
            <RegistryDuplicatePaymentsList
                handleError={this.handleErrorPay(clientId, registryId, false)}
                clientId={clientId}
                registryId={registryId}
                data={payModel}
                close={this.closeDuplicatePaymentsList}
            />);
    }

    renderProjectInfo = ({projectName, projectId, clientId, projectAvailable}) => {
        if (!projectId) {
            return "-";
        }

        if (!projectAvailable) {
            return projectName;
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId || this.clientId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {projectName}
            </ExtLink>
        );
    };

    renderObjectInfo = ({objectName, projectId, objectId, clientId, objectAvailable}) => {
        if (!objectId) {
            return "-";
        }

        if (!objectAvailable) {
            return objectName;
        }

        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST
            .replace(":clientId", clientId || this.clientId)
            .replace(":projectId", projectId)
            .replace(":objectId", objectId);

        return (
            <ExtLink
                to={link}
                historyEnabled
            >
                {objectName}
            </ExtLink>
        );
    };

    renderClientInfo = ({clientId, clientName, clientBrand}) => {
        const clientLink = LINK_CLIENT_INFO.replace(":clientId", clientId);

        const brandValue = clientBrand ? ` (${clientBrand})` : "";

        return (
            <ExtLink
                to={clientLink}
                historyEnabled
            >
                {`${clientName}${brandValue}`}
            </ExtLink>
        );
    };

    getRows() {
        const {list, clientInfo} = this.props;

        return list.map(item => {
            const {
                paymentsTotalCount,
                paymentsSuccessCount,
                paymentsDeclineCount,
                paymentsInProgressCount,
                paymentsErrorCount,
                createdAt,
                registryPaymentSum,
                registryPaymentTotalSum,
                description,
                createActsOfAcceptanceOfWorkType,
                creatorLastName,
                creatorFirstName,
                creatorPatronymic,
                orderContractPaymentType,
                orderAmountCalculationMethod,
                registryId,
                actOfAcceptanceOfWorkTemplateId,
                actOfAcceptanceOfWorkTemplateName,
                actOfAcceptanceOfWorkTemplatePdfDownloadLink,
                frameContractTemplateId,
                frameContractTemplateName,
                frameContractTemplatePdfDownloadLink,
                orderApplicationTemplateId,
                orderApplicationTemplateName,
                orderApplicationTemplatePdfDownloadLink,
                paymentMethod,
            } = item;

            const errorCount = paymentsErrorCount || 0;
            const progressCount = paymentsInProgressCount || 0;

            //Всего
            const totalCount = paymentsTotalCount || 0;
            // Выплачено
            const paidCount = paymentsSuccessCount || 0;
            // В процессе
            const inProgressCount = errorCount + progressCount;
            // Отклонено
            const rejectCount = paymentsDeclineCount || 0;
            // Необходимый депозит
            const requiredDeposit = registryPaymentTotalSum || 0;

            const descriptionLabel = description ? [{label: "Описание", text: description}] : [];
            const clientLabel = !this.isClientCard ? [{label: "Компания", text: this.renderClientInfo(item)}] : [];
            const isIndividualEntrepreneur = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

            return {
                key: registryId,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-6"
                        secondaryHeader={`Реестр от ${dateFormat(convertUtcToLocal(createdAt))}`}
                        secondaryHeaderStatus={this.renderStatus(item.status)}
                        primaryHeader={this.renderRegistryLink(item)}
                        fluidPrimaryHeader
                        labels={[
                            ...descriptionLabel,
                            ...clientLabel,
                            {
                                label: "Ответственный",
                                text: getFullName(creatorLastName, creatorFirstName, creatorPatronymic),
                            },
                            {label: "Проект", text: this.renderProjectInfo(item)},
                            {label: "Объект", text: this.renderObjectInfo(item)},
                            {
                                label: "Договор",
                                text: ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType],
                            },
                            clientInfo.enableIndividualProjectAndObjectDeposit && {
                                label: "Депозит",
                                text: (
                                    <DepositTypeText
                                        depositType={item.depositType}
                                        clientId={item.clientId}
                                        objectId={item.objectId}
                                        objectName={item.objectName}
                                        projectId={item.projectId}
                                        projectName={item.projectName}
                                    />
                                ),
                            },
                            !isIndividualEntrepreneur && {
                                label: "Способ проведения оплат",
                                text: PAYMENT_METHOD_DICT[paymentMethod],
                            },
                            !isIndividualEntrepreneur && {
                                label: "Сумма на карту",
                                text: ORDER_AMOUNT_CALCULATION_METHOD_DICT[orderAmountCalculationMethod],
                            },
                            {label: "Акты", text: getCreateTypeDescription(createActsOfAcceptanceOfWorkType)},
                            {
                                textTitle: frameContractTemplateName,
                                label: "Шаблон договора",
                                text: (
                                    <OrderTemplateInfo
                                        id={frameContractTemplateId}
                                        linkName={frameContractTemplateName}
                                        link={frameContractTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                            {
                                textTitle: orderApplicationTemplateName,
                                label: "Шаблон заявки",
                                text: (
                                    <OrderTemplateInfo
                                        id={orderApplicationTemplateId}
                                        linkName={orderApplicationTemplateName}
                                        link={orderApplicationTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                            {
                                textTitle: actOfAcceptanceOfWorkTemplateName,
                                label: "Шаблон акта",
                                text: (
                                    <OrderTemplateInfo
                                        id={actOfAcceptanceOfWorkTemplateId}
                                        linkName={actOfAcceptanceOfWorkTemplateName}
                                        link={actOfAcceptanceOfWorkTemplatePdfDownloadLink}
                                    />
                                ),
                            },
                        ]}
                        cardsWithContainer
                        cardsContainerClassName="col-16 col-xxl-9 mt-md-4 mt-xxl-0"
                        cards={[
                            {
                                title: <>
                                    Всего/Выплачено/В&nbsp;процессе/Отменено
                                </>,
                                values: [{
                                    bold: true,
                                    text: totalCount,
                                }, {text: paidCount}, {text: inProgressCount}, {text: rejectCount}],
                                className: "col-16 col-md-6 col-xxl-6",
                            },
                            {
                                title: "Сумма реестра, ₽",
                                value: formatAmount(formatNumber(registryPaymentSum, 2)),
                                className: "col-16 col-md-4 col-xxl-5",
                            },
                            {
                                title: "Необходимый депозит, ₽",
                                value: formatAmount(formatNumber(requiredDeposit, 2)),
                                className: "col-16 col-md-6 col-xxl-5",
                            },
                        ]}
                        actionsClassName="col-1"
                        actions={this.renderAction(item)}
                    />
                ),
            };
        });
    }

    getSubHeader(isShowControls) {
        if (!isShowControls || !this.isClientCard) {
            return null;
        }

        const {isOpenFilter} = this.state;
        const {
            clientInfo: {
                registryPaymentsAvailable,
                civilRegistryPaymentsAvailable,
                enableIndividualProjectAndObjectDeposit,
            },
        } = this.props;

        return (
            !enableIndividualProjectAndObjectDeposit && ![FOREMAN].includes(this.role) &&
            <>
                {
                    registryPaymentsAvailable &&
                    <div className="registry-list__details-item">
                        <Text
                            className="mb-2"
                            medium
                            level="2"
                            color={COLOR.SECONDARY_70}
                            noWrap
                        >
                            Детализация по счету для выплат исполнителям с типом налогообложения НПД
                        </Text>
                        <DepositValuesAmountInfo
                            isOpenFilter={isOpenFilter}
                            clientId={this.clientId}
                            isRegistry
                            isHideClientDeposit
                        />
                    </div>
                }
                {
                    civilRegistryPaymentsAvailable &&
                    <div className="registry-list__details-item">
                        <Text
                            medium
                            level="2"
                            className="mb-2"
                            color={COLOR.SECONDARY_70}
                            noWrap
                        >
                            Детализация по счету для выплат исполнителям с типом налогообложения НДФЛ
                        </Text>
                        <DepositValuesAmountInfo
                            isCivil
                            isOpenFilter={isOpenFilter}
                            clientId={this.clientId}
                            isRegistry
                            isHideClientDeposit
                        />
                    </div>
                }
            </>
        );
    }

    setDataLog = (dataLog = {}) => {
        this.setState({dataLog});
    };

    renderCreateDeferredActs = () => {
        const {isOpenCreateDeferredActs} = this.state;

        if (!isOpenCreateDeferredActs) {
            return null;
        }

        const {item} = this.state;

        return (
            <CreateRegistryPaymentDeferredActs
                clientId={item.clientId}
                paymentRegistryId={item.registryId}
                onClose={() => {
                    this.setState({
                        isOpenCreateDeferredActs: false,
                        item: null,
                    });
                }}
            />
        );
    };

    render() {
        const {
            totalCount,
            t,
            clientInfo: {
                civilRegistryPaymentsAvailable,
                registryPaymentsAvailable,
                individualRegistryPaymentsAvailable,
            },
            progressList,
            progressClientProperties,
            totalPages,
            list,
            registriesFormattedToTaskIds,
            discardPaymentsTasks,
            match,
        } = this.props;

        const {
            pageNum,
            pageSize,
            isSearch,
            archived,
            dataLog,
        } = this.state;

        const isShowControls = list.length || isSearch;

        const isLoaded = progressList || progressClientProperties;

        const isPageAccess = civilRegistryPaymentsAvailable
            || registryPaymentsAvailable
            || individualRegistryPaymentsAvailable
            || !this.isClientCard;
        const archiveButton = getArchiveButton(t, archived, {mobile: true});

        return (
            <>
                {
                    isPageAccess ?
                        <NmPage
                            openFilter
                            noPadding={!match.params.clientId}
                            onOpenFilter={this.onOpenFilter}
                            widthByFilter
                            typeFilter="vertical"
                            headerClassName="registry-list__header"
                            className="fluid-flex-grow relative"
                            filterClassName="registry-list__filter"
                            isLoaded={isLoaded}
                            header={
                                <NmTitle
                                    size="xl"
                                    count={isShowControls && totalCount}
                                >
                                    {t("registries.header")}
                                </NmTitle>
                            }
                            mediaControls={{
                                renderCount: {
                                    mobile: 1,
                                    tablet: 1,
                                    desktop: 2,
                                },
                                buttons: [
                                    {
                                        component: COMPONENT.BUTTON,
                                        props: {
                                            size: "xl",
                                            color: "green",
                                            onClick: () => this.showAddRegistryForm(true),
                                            icon: <AddIcon />,
                                            children: "Добавить реестр",
                                        },
                                        visible: isShowControls
                                            && !this.isClientArchived
                                            && !archived
                                            && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)
                                            && this.isClientCard
                                            && this.isAccessActions
                                            && this.isAccessAddEditDelPayRegistries,
                                    },
                                    {
                                        component: COMPONENT.BUTTON,
                                        props: {
                                            ...archiveButton.props,
                                            disabled: isLoaded,
                                            onClick: this.toggleArchived,
                                        },
                                    },
                                ],
                            }}
                            subHeader={this.getSubHeader(isShowControls)}
                            filtersBase={this.renderBaseFilter()}
                            currentPageSize={pageSize}
                            currentPageNum={pageNum}
                            totalPages={totalPages}
                            onChangePageSize={this.handleChangePageSize}
                            onPaginationChange={this.handlePaginationChange}
                            totalCount={totalCount}
                        >
                            {
                                !isEmpty(dataLog) && <RegistriesListLog
                                    handleClose={() => this.setDataLog()}
                                    {...dataLog}
                                />
                            }
                            <Task />
                            {this.renderUnavailableTemplates()}
                            {this.renderConfirmWindow()}
                            {this.renderAddRegistryForm()}
                            {this.renderDuplicatePaymentsList()}
                            {this.renderCreateDeferredActs()}
                            <div className="registry-list__content">
                                {
                                    list?.length
                                        ? <>
                                            <ByTaskUpdater
                                                fetch={this.fetchRegistryList}
                                                taskIds={discardPaymentsTasks}
                                                dataIds={registriesFormattedToTaskIds}
                                            />
                                            <CheckboxList
                                                rows={this.getRows()}
                                            />
                                        </>
                                        : <NmEmptyPageV2
                                            title={!archived ? "Реестровые выплаты пока не сформированы" : "Данные отсутствуют"}
                                            description={!archived && "Реестровая выплата -  функция, которая позволяет совершать выплаты одновременно нескольким исполнителям. Для перехода к созданию реестровой выплаты нажмите на кнопку “Добавить реестр”"}
                                            isShowAction={!this.isClientArchived && !archived}
                                            textAction="Добавить реестр"
                                            onClickAction={() => this.showAddRegistryForm(true)}
                                            isSearch={isSearch}
                                            fetchProgress={isLoaded}
                                        />
                                }
                            </div>
                        </NmPage> :
                        <div className="registry-list__not-available-registry-text">
                            {
                                !civilRegistryPaymentsAvailable &&
                                !registryPaymentsAvailable &&
                                !individualRegistryPaymentsAvailable &&
                                t("registries.not-available-registry-text")
                            }
                        </div>
                }
            </>
        );
    };
}

export default withPageData(connect(
    state => ({
        progressList: registryPaymentProgressListSelector(state),
        totalCount: registryPaymentTotalCountSelector(state),
        totalPages: registryPaymentTotalPagesSelector(state),
        list: registryPaymentListSelector(state),
        isSuccessAdd: registriesSuccessAddSelector(state),
        statusDict: registryStatusDictSelector(state),
        isSuccessAction: registriesSuccessActionSelector(state),
        registryCard: registriesCardSelector(state),
        progressClientProperties: clientCardInfoProgressSelector(state),
        clientInfo: clientCardPropertiesSelector(state),
        clientType: getClientTypeSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        discardPaymentsTasks: jobTasksRegistryDiscardPaymentsSelector(state),
        client: clientCardInfoSelector(state),
        registriesFormattedToTaskIds: registriesListFormattedToTaskIdsSelector(state),
    }),
    {
        getPageRegistryPayment,
        addRegistry,
        updateRegistryStoreField,
        getRegistryStatusDict,
        deleteRegistry,
        archiveRegistryPayment,
        createDuplicateRegistry,
        discardOutstandingPayments,
        payRegistryPayment,
        getRegistryById,
        getClientCardProperties,
        updateRegistry,
        getDepositList,
        getIsFrameContractSignedForContractorsOnRegistry,
        getDocumentsCustomTemplateReplacementsForUnavailableList,
        updateFieldDepositStore,
        getAllTasks,

    },
)(withTranslation()(RegistryList)));