import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    getListContractorBanks,
    updateContractorBanksBalanceStore,
} from "../../../../ducks/bff/contractor-сard/banks/actionCreators";

export function useContractorBanksFetch({contractorId}) {
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();

        return () => {
            dispatch(updateContractorBanksBalanceStore({
                list: [],
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getListContractorBanks({contractorId}));
    };

    return {
        fetchList,
    };
}