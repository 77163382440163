import React from "react";
import {useSelector} from "react-redux";

import NmDatePicker from "../../../../../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../../../../../components/ActualComponents/NmDateRangePickerV2";
import NmForm from "../../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../../components/ApplyButtons";
import NmTitle from "../../../../../../../components/NmTitle";

import {useDocumentsDeferredActsOfAcceptanceWorkItemEditModalForm} from "./hooks/useForm";

import {
    getFullName,
    phoneFormat,
} from "../../../../../../../utils/stringFormat";

import {COLOR} from "../../../../../../../constants/color";

import {
    bffDeferredActsOfAcceptanceWorkCardProgressActionSelector,
} from "../../../../../../../ducks/bff/documents/deferred-acts-of-acceptance-work/card/selectors";

const DocumentsDeferredActsOfAcceptanceWorkItemEditModal = (props) => {
    const {
        editData,
        onClose,
    } = props;

    const progress = useSelector(bffDeferredActsOfAcceptanceWorkCardProgressActionSelector);

    const {
        handleSubmit,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors,
    } = useDocumentsDeferredActsOfAcceptanceWorkItemEditModalForm({
        editData,
        onClose,
    });

    return (
        <NmModal
            onClose={onClose}
            size="md"
            header={
                <NmTitle
                    size="lg"
                >
                    Редактирование параметров отложенного акта для исполнителя
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile={true}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                    onClose={onClose}
                    disabled={progress}
                    submit={handleSubmit}
                />
            }
        >
            <NmForm addMargin={true}>
                <Text.Sub
                    level="2"
                    medium={true}
                >
                    Данные об исполнителе
                </Text.Sub>
                <div>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_45}
                    >
                        ФИО исполнителя
                    </Text>
                    <Text
                        level="4"
                    >
                        {getFullName(
                            editData.contractorLastName,
                            editData.contractorFirstName,
                            editData.contractorPatronymic,
                        )}
                    </Text>
                </div>
                <div>
                    <Text
                        level="3"
                        color={COLOR.SECONDARY_45}
                    >
                        Номер телефона исполнителя
                    </Text>
                    <Text
                        level="4"
                    >
                        {phoneFormat(editData.contractorPhone) || "-"}
                    </Text>
                </div>
                <Text.Sub
                    level="2"
                    medium={true}
                >
                    Параметры акта
                </Text.Sub>
                <NmInputV2
                    required={true}
                    disabled={true}
                    size="xl"
                    value={values.orderNum}
                    label="Номер заказа"
                    onChange={handleChange}
                />
                <NmInputV2
                    required={true}
                    disabled={true}
                    size="xl"
                    value={values.workActDescription}
                    label="Описание выполненных работ для акта"
                    onChange={handleChange}
                />
                <NmInputV2
                    required={true}
                    disabled={true}
                    size="xl"
                    value={values.workFNSDescription}
                    label="Описание выполненных работ для чека ФНС России"
                    onChange={handleChange}
                />
                <NmInputV2
                    size="xl"
                    label="Комментарий"
                    placeholder="Комментарий"
                    maxLength={255}
                    onChange={handleChange}
                    name="commentary"
                    value={values.commentary}
                    error={touched.commentary && errors.commentary}
                />
                <NmDateRangePickerV2
                    required={true}
                    disabled={true}
                    size="xl"
                    label="Период работ"
                    startFieldName="startWorkDate"
                    endFieldName="endWorkDate"
                    value={{
                        startWorkDate: values.startWorkDate,
                        endWorkDate: values.endWorkDate,
                    }}
                    onChange={handleChange}
                />
                <NmDatePicker
                    required={true}
                    readOnlyInput={false}
                    dateFormat="dd.MM.yy"
                    name="actDate"
                    selected={values.actDate}
                    onChange={(e, {name, value}) => {
                        setFieldValue(name, value);
                    }}
                    maxDate={new Date()}
                    isClearable={true}
                    label="Дата акта"
                    error={touched.actDate && errors.actDate}
                />
            </NmForm>
        </NmModal>
    );
};

export default DocumentsDeferredActsOfAcceptanceWorkItemEditModal;