import {createSelector} from "reselect";

import clientFrameContractsRegistries from "./reducer";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {dictionaryToOptions} from "../../../../../utils/objectHelper";

const fcRegistrySelector = (state) => state.bff.clientFrameContractsRegistries;

export const fcRegistryListSelector = createSelector(fcRegistrySelector, ({list}) => list);
export const fcRegistryListProgressSelector = createSelector(fcRegistrySelector, ({listProgress}) => listProgress);
export const fcRegistryListTotalCountSelector = createSelector(fcRegistrySelector, ({totalCount}) => totalCount);
export const fcRegistryListTotalPagesSelector = createSelector(fcRegistrySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));

export const fcRegistryItemListSelector = createSelector(fcRegistrySelector, ({registryItemsList}) => registryItemsList);
export const fcRegistryItemListProgressSelector = createSelector(fcRegistrySelector, ({registryItemsProgress}) => registryItemsProgress);
export const fcRegistryItemsImportProgressSelector = createSelector(fcRegistrySelector, ({registryItemsImportProgress}) => registryItemsImportProgress);
export const fcRegistryItemListTotalCountSelector = createSelector(fcRegistrySelector, ({registryItemsTotalCount}) => registryItemsTotalCount);
export const fcRegistryItemListTotalPagesSelector = createSelector(fcRegistrySelector,
    ({registryItemsTotalCount, registryItemsPageData: {pageSize = 0}}) => getTotalPages(registryItemsTotalCount, pageSize));

export const contractorListForFcRegistrySelector = createSelector(fcRegistrySelector, ({contractorList}) => contractorList);
export const contractorListProgressForFcRegistrySelector = createSelector(fcRegistrySelector, ({contractorListProgress}) => contractorListProgress);
export const contractorListForFcRegistryTotalCountSelector = createSelector(fcRegistrySelector, ({contractorListTotalCount}) => contractorListTotalCount);
export const contractorListForFcRegistryTotalPagesSelector = createSelector(fcRegistrySelector,
    ({contractorListTotalCount, contractorListPageData: {pageSize = 0}}) => getTotalPages(contractorListTotalCount, pageSize));

export const fcRegistryCardSelector = createSelector(fcRegistrySelector, ({card}) => card);
export const fcRegistryProgressSelector = createSelector(fcRegistrySelector, ({registryProgress}) => registryProgress);

export const fcRegistryActionSelector = createSelector(fcRegistrySelector, ({actionProgress}) => actionProgress);

export const fcRegistryStatusDictSelector = createSelector(fcRegistrySelector, ({statusDict}) => statusDict);
export const fcRegistryStatusOptionsSelector = createSelector(fcRegistrySelector, ({statusDict}) => dictionaryToOptions(statusDict));

export const fcRegistryItemStatusDictSelector = createSelector(fcRegistrySelector, ({itemStatusDict}) => itemStatusDict);
export const fcRegistryItemStatusOptionsSelector = createSelector(fcRegistrySelector, ({itemStatusDict}) => dictionaryToOptions(itemStatusDict));
export const fcRegistrySubmittingFlagsSelector = createSelector(fcRegistrySelector, ({fcRegistrySubmittingFlags}) => fcRegistrySubmittingFlags);