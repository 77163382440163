import {all, put, select,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getContractorList} from "./contractor";
import {getPageOrderContractors} from "./order";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {STAFF} from "../constants/messages";

const controller = "/ordercontractors";
//*  TYPES  *//

const ORDER_CONTRACTOR_STATUS_REQUEST = "ORDER_CONTRACTOR_STATUS_REQUEST";
const ORDER_CONTRACTOR_STATUS_SUCCESS = "ORDER_CONTRACTOR_STATUS_SUCCESS";
const ORDER_CONTRACTOR_STATUS_ERROR = "ORDER_CONTRACTOR_STATUS_ERROR";

const ORDER_CONTRACTOR_UPDATE_FIELD_STORE = "ORDER_CONTRACTOR_UPDATE_FIELD_STORE";

const ORDER_CONTRACTOR_COMPLETE_REQUEST = "ORDER_CONTRACTOR_COMPLETE_REQUEST";
const ORDER_CONTRACTOR_COMPLETE_SUCCESS = "ORDER_CONTRACTOR_COMPLETE_SUCCESS";
const ORDER_CONTRACTOR_COMPLETE_ERROR = "ORDER_CONTRACTOR_COMPLETE_ERROR";

const REFUSAL_COMPLETE_ORDER_REQUEST = "REFUSAL_COMPLETE_ORDER_REQUEST";
const REFUSAL_COMPLETE_ORDER_SUCCESS = "REFUSAL_COMPLETE_ORDER_SUCCESS";
const REFUSAL_COMPLETE_ORDER_ERROR = "REFUSAL_COMPLETE_ORDER_ERROR";

const ORDER_CONTRACTOR_UPDATE_STORE = "ORDER_CONTRACTOR_UPDATE_STORE";

//*  INITIAL STATE  *//

const initial = {
    totalCount: 0,
    pageData: {},
    currentRole: "",
    list: [],
    statuses: [],
    statusesParams: {},
    contractorClientsCounts: [],
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDER_CONTRACTOR_UPDATE_FIELD_STORE:
            const {
                field,
                value,
            } = payload;
            return {
                ...state,
                progress: false,
                [field]: value,
            };
        case ORDER_CONTRACTOR_STATUS_REQUEST:
            return {
                ...state,
                statusesParams: payload,
            };
        case REFUSAL_COMPLETE_ORDER_REQUEST:
        case ORDER_CONTRACTOR_COMPLETE_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_CONTRACTOR_STATUS_SUCCESS:
            return {
                ...state,
                statuses: payload.orderContractors,
            };
        case REFUSAL_COMPLETE_ORDER_SUCCESS:
        case ORDER_CONTRACTOR_COMPLETE_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case REFUSAL_COMPLETE_ORDER_ERROR:
        case ORDER_CONTRACTOR_COMPLETE_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case ORDER_CONTRACTOR_UPDATE_STORE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getStatusesOrderContractor(payload) {
    return {type: ORDER_CONTRACTOR_STATUS_REQUEST, payload};
}

export function updateFieldOrderContractorStore(field, value) {
    return {
        type: ORDER_CONTRACTOR_UPDATE_FIELD_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function updateOrderContractorsStore(payload) {
    return {
        type: ORDER_CONTRACTOR_UPDATE_STORE,
        payload,
    };
}

// "Подтвердить завершение заказа". Перевод заявки в статус "Заказ завершен"
export const completeOrderContractor = payload => ({
    type: ORDER_CONTRACTOR_COMPLETE_REQUEST,
    payload,
});

// "Отклонить завершение заказа". Перевод заявки в статус "Переговоры по спору"
export const refusalCompleteOrder = payload => ({
    type: REFUSAL_COMPLETE_ORDER_REQUEST,
    payload,
});

//*  SELECTORS  *//

export const orderContractorSelector = state => state.orderContractors;
export const orderProgressListSelector = createSelector(orderContractorSelector, ({progress}) => progress);
export const orderContractorErrorSelector = createSelector(
    orderContractorSelector,
    ({error}) => error,
);
export const orderContractorActionPageDataSelector = createSelector(orderContractorSelector, ({actionPageData}) => actionPageData);

//*  SAGA  *//

//POST /api/ordercontractors/getOrderStatus
export const orderContractorStatusesSaga = function* (action) {
    try {
        const {payload} = action;

        const result = yield request.post(`${controller}/getOrderStatus`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ORDER_CONTRACTOR_STATUS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: ORDER_CONTRACTOR_STATUS_SUCCESS,
            payload: result,
        });

    } catch (error) {
        yield put({type: ORDER_CONTRACTOR_STATUS_ERROR, payload: error});
    }
};

export const completeContractorSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            ...reqData
        } = payload;

        const result = yield request.post(`${controller}/complete`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            onError();

            yield put({
                type: ORDER_CONTRACTOR_COMPLETE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_SUCCESS,
            payload: errorMessage,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: ORDER_CONTRACTOR_COMPLETE_ERROR,
            payload: error.message,
        });
    }
};

export const refusalCompleteOrderSaga = function* ({payload}) {
    try {
        const {onSuccess, ...reqData} = payload;

        const result = yield request.post(`${controller}/refusalCompleteOrder`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: REFUSAL_COMPLETE_ORDER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: REFUSAL_COMPLETE_ORDER_SUCCESS,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: REFUSAL_COMPLETE_ORDER_ERROR,
            payload: error.message,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(ORDER_CONTRACTOR_STATUS_REQUEST, orderContractorStatusesSaga),
        takeEvery(ORDER_CONTRACTOR_COMPLETE_REQUEST, completeContractorSaga),
        takeEvery(REFUSAL_COMPLETE_ORDER_REQUEST, refusalCompleteOrderSaga),
    ]);
}