import {combineReducers} from "redux";

import kedoLogs from "../kedo/logs/reducer";
import advertisement from "./advertisement/reducer";
import bankTkb from "./bank/tkb/reducer";
import bankW1 from "./bank/w1/reducer";
import clientDocumentsContractAgency from "./clients/documents/contract-agency/reducer";
import clientFrameContractsRegistries from "./clients/documents/frame-contracts-registry/reducer";
import clientDocumentsRegistry from "./clients/documents/registry/reducer";
import clientsEdoDirectoryPositions from "./clients/edo/directory/positions/reducer";
import clientsEdoDirectorySubdivisions from "./clients/edo/directory/subdivisions/reducer";
import clientsEdoDocuments from "./clients/edo/documents/reducer";
import clientsEdoRegistriesCard from "./clients/edo/registries/card/reducer";
import clientsEdoRegistries from "./clients/edo/registries/reducer";
import clientsEdoStaff from "./clients/edo/staff/reducer";
import clientsEdoStatements from "./clients/edo/statements/reducer";
import clientEmployees from "./clients/employees/reducer";
import clientDepositReplenishmentAndRefunds from "./clients/finances/deposit/replenishment-and-refunds/reducer";
import clientForemenForemanContractors from "./clients/foremen/foreman-contractors/reducer";
import clientForemenPayments from "./clients/foremen/payments/reducer";
import clientForemenRequests from "./clients/foremen/requests/reducer";
import foremenTimesheets from "./clients/foremen/timesheets/reducer";
import clientCardInfo from "./clients/info/reducer";
import clientsInstructionsKedo from "./clients/instructions/kedo/reducer";
import clientsModuleTariffs from "./clients/module-tariffs/reducer";
import clientsMonitoringRisk from "./clients/monitoring-risk/reducer";
import notificationSendingToContractor from "./clients/notification-sending/to-contractor/reducer";
import clientObjectCard from "./clients/objects/card/reducer";
import clientObjects from "./clients/objects/reducer";
import orderAnalyticsAllApplications from "./clients/order-analytics/all-applications/reducer";
import orderAnalytics from "./clients/order-analytics/reducer";
import clientsPatentsPayCard from "./clients/patents/pay/card/reducer";
import clientsPatentsPay from "./clients/patents/pay/reducer";
import clientProjectCard from "./clients/projects/card/reducer";
import clientProjects from "./clients/projects/reducer";
import registryPaymentCard from "./clients/registry-payment/card/reducer";
import registryPaymentLog from "./clients/registry-payment/log/reducer";
import registryPayment from "./clients/registry-payment/reducer";
import clientCardReportsOneC from "./clients/reports/one-c/reducer";
import clientsSettingsPayments from "./clients/settings/reducer";
import clientsStopList from "./clients/stop-list/reducer";
import clientsSystemProperties from "./clients/system-properties/reducer";
import commonClient from "./common/client/reducer";
import commonDicts from "./common/dicts/reducer";
import commonJob from "./common/job/reducer";
import commonOrder from "./common/order/reducer";
import commonTree from "./common/tree/reducer";
import contractorCardBanks from "./contractor-сard/banks/reducer";
import contractorCardDevices from "./contractor-сard/devices/reducer";
import contractorCardDocuments from "./contractor-сard/documents/reducer";
import contractorCardDocumentSigning from "./contractor-сard/event-log/document-signing/reducer";
import contractorCardNotificationLog from "./contractor-сard/event-log/notification-log/reducer";
import contractorCardPersonalDataEventsLog from "./contractor-сard/event-log/personal-data-events/reducer";
import contractorCardEventLog from "./contractor-сard/event-log/reducer";
import contractorCardFinanceBalance from "./contractor-сard/finance/balance/reducer";
import contractorCardOrderHistory from "./contractor-сard/order-history/reducer";
import contractorCardPersonalData from "./contractor-сard/personal-data/reducer";
import contractorCardProfile from "./contractor-сard/profile/reducer";
import contractorCard from "./contractor-сard/reducer";
import contractorCardTaskHistory from "./contractor-сard/task-history/reducer";
import contractorsRegistry from "./contractors/registry/reducer";
import contractorsResources from "./contractors/resources/reducer";
import crowdActRegistry from "./crowd/actRegistry/reducer";
import crowdActRegistryEntry from "./crowd/actRegistryEntry/reducer";
import crowdPayment from "./crowd/payment/reducer";
import crowdTaskCard from "./crowd/taskCard/reducer";
import crowdTaskGroup from "./crowd/taskGroup/reducer";
import crowdTaskGroups from "./crowd/taskGroups/reducer";
import crowdTasks from "./crowd/tasks/reducer";
import crowdTasksAnalytics from "./crowd/tasksAnalytics/reducer";
import disputes from "./disputes/reducer";
import documentsAdditionalAgreements from "./documents/additional-agreements/reducer";
import documentsAdditionalAgreementsRegistry from "./documents/additional-agreements/registry/reducer";
import deferredActsOfAcceptanceWorkCard from "./documents/deferred-acts-of-acceptance-work/card/reducer";
import deferredActsOfAcceptanceWork from "./documents/deferred-acts-of-acceptance-work/reducer";
import documentsTemplates from "./documents-templates/reducer";
import exportDocuments from "./export/documents/reducer";
import exportReports from "./export/reports/reducer";
import financesNdflPayments from "./finances/ndfl-payments/reducer";
import financesNdflSummary from "./finances/ndfl-summary/reducer";
import financesNpdPayments from "./finances/npd-payments/reducer";
import financesNpdSummary from "./finances/npd-summary/reducer";
import financesOrders from "./finances/orders/reducer";
import financesPatentsSummary from "./finances/patent-summary/reducer";
import financesReceiptsCancelled from "./finances/receipts-cancelled/reducer";
import registryPayments from "./finances/registry-payments/reducer";
import map from "./map/reducer";
import marketingNotifications from "./marketing/notifications/reducer";
import emailNotifications from "./notifications/email-notifications/reducer";
import notifications from "./notifications/reducer";
import subscribeNotifications from "./notifications/subscribe/reducer";
import oneC from "./one-c/reducer";
import orderContractorWorkLog from "./orders/contractor-work-log/reducer";
import orderActRegistriesCard from "./orders/order-act-registries/card/reducer";
import orderActRegistries from "./orders/order-act-registries/reducer";
import orderContractors from "./orders/order-contractors/reducer";
import orderGroupsCard from "./orders/order-groups/card/reducer";
import orderGroups from "./orders/order-groups/reducer";
import registryInvitationsCard from "./orders/order-invitations-registry/card/reducer";
import registryInvitations from "./orders/order-invitations-registry/reducer";
import ordersRegistry from "./orders/orders-registry/reducer";
import patentPayments from "./patent/reducer";
import plutoniumTransactionLog from "./plutonium/transaction-log/reducer";
import promotionChannelsCard from "./promotion-channels/card/reducer";
import promotionChannels from "./promotion-channels/reducer";
import recruitmentAccessControlJobBoards from "./recruitment/access-control/job-boards/reducer";
import recruitmentAccessControlObservers from "./recruitment/access-control/observers/reducer";
import recruitmentAccessControlRecruitersGroups from "./recruitment/access-control/recruiters-groups/reducer";
import recruitmentAccessControl from "./recruitment/access-control/reducer";
import recruitmentAccessControlVacancyPersonalAccess from "./recruitment/access-control/vacancy-personal-access/reducer";
import recruitmentDirectory from "./recruitment/directory/reducer";
import recruitmentJobBoards from "./recruitment/jobBoards/reducer";
import recruitmentJobBoardsWebHook from "./recruitment/jobBoardsWebhook/reducer";
import recruitmentVacancy from "./recruitment/vacancy/reducer";
import recruitmentVacancyCandidates from "./recruitment/vacancyCandidates/reducer";
import recruitmentVacancyCandidatesResponses from "./recruitment/vacancyCandidates/responses/reducer";
import recruitmentVacancyCandidatesComments from "./recruitment/vacancyCandidatesComments/reducer";
import recruitmentVacancyResponses from "./recruitment/vacancyResponses/reducer";
import reviewsClient from "./reviews/client/reducer";
import reviewsContractor from "./reviews/contractor/reducer";
import settingsClientGroupsCardClientUsers from "./settings/client-groups/card/client-users/reducer";
import settingsClientGroupsCardClients from "./settings/client-groups/card/clients/reducer";
import settingsClientGroupsCardContractors from "./settings/client-groups/card/contractors/reducer";
import settingsClientGroupsCard from "./settings/client-groups/card/reducer";
import settingsClientGroupsCardSearchContractors from "./settings/client-groups/card/search-contractors/reducer";
import settingsClientGroups from "./settings/client-groups/reducer";
import settingsCollectionBankDetails from "./settings/collection-bank-details/reducer";
import settingsContractorSupport from "./settings/contractor-support/reducer";
import settingsDirectoriesNaimixBanks from "./settings/directories/naimix/banks/reducer";
import settingsDirectoriesNaimixCategorySpecialities from "./settings/directories/naimix/category-specialities/reducer";
import settingsDirectoriesNaimixClientCategories from "./settings/directories/naimix/client-categories/reducer";
import settingsDirectoriesNaimixDocuments from "./settings/directories/naimix/documents/reducer";
import settingsDirectoriesNaimixFederalMigration from "./settings/directories/naimix/federal-migration/reducer";
import settingsDirectoriesNaimixKeywords from "./settings/directories/naimix/keywords/reducer";
import settingsDirectoriesNaimixSpecialities from "./settings/directories/naimix/specialities/reducer";
import settingsDirectoriesNaimixSpecialtyOffer from "./settings/directories/naimix/specialty-offer/reducer";
import settingsDirectoriesNaimixStatements from "./settings/directories/naimix/statements/reducer";
import settingsDirectoriesNaimixStopWords from "./settings/directories/naimix/stop-words/reducer";
import settingsDirectoriesNaimixTariffGrid from "./settings/directories/naimix/tariff-grid/reducer";
import settingsDocumentsCorrection from "./settings/documents-correction/reducer";
import settingsEmployees from "./settings/employees/reducer";
import settingsInstructionsKedo from "./settings/instructions/kedo/reducer";
import settingsInstructionsNaimix from "./settings/instructions/naimix/reducer";
import settingsJoiningRequests from "./settings/joining-requests/reducer";
import settingsMachineLearning from "./settings/machine-learning/reducer";
import settingsNaimixInfo from "./settings/naimix-info/reducer";
import settingsService from "./settings/service/reducer";
import settingsServiceTaskStats from "./settings/service/task-stats/reducer";
import systemTransactions from "./system-transactions/reducer";

import paidApis from "./finances/paid-apis/reducer";

export default (history) => combineReducers({
    clients: combineReducers({
        settings: clientsSettingsPayments,
        systemProperties: clientsSystemProperties,
        moduleTariffs: clientsModuleTariffs,
        instructions: combineReducers({
            kedo: clientsInstructionsKedo,
        }),
        kedo: combineReducers({
            logs: kedoLogs,
        }),
    }),
    common: combineReducers({
        dicts: commonDicts,
        order: commonOrder,
        client: commonClient,
    }),
    recruitment: combineReducers({
        jobBoards: recruitmentJobBoards,
        jobBoardsWebhook: recruitmentJobBoardsWebHook,
        directory: recruitmentDirectory,
        vacancyCandidates: recruitmentVacancyCandidates,
        vacancyCandidatesResponses: recruitmentVacancyCandidatesResponses,
        vacancyCandidatesComments: recruitmentVacancyCandidatesComments,
        vacancy: recruitmentVacancy,
        vacancyResponses: recruitmentVacancyResponses,
        accessControl: combineReducers({
            common: recruitmentAccessControl,
            recruitersGroups: recruitmentAccessControlRecruitersGroups,
            jobBoards: recruitmentAccessControlJobBoards,
            observers: recruitmentAccessControlObservers,
            vacancyPersonalAccess: recruitmentAccessControlVacancyPersonalAccess,
        }),
    }),
    bank: combineReducers({
        tkb: bankTkb,
        w1: bankW1,
    }),
    crowd: combineReducers({
        tasks: crowdTasks,
        taskCard: crowdTaskCard,
        taskGroups: crowdTaskGroups,
        taskGroup: crowdTaskGroup,
        payment: crowdPayment,
        tasksAnalytics: crowdTasksAnalytics,
        actRegistry: crowdActRegistry,
        actRegistryEntry: crowdActRegistryEntry,
    }),
    plutonium: combineReducers({
        transactionLog: plutoniumTransactionLog,
    }),
    finances: combineReducers({
        receiptsCancelled: financesReceiptsCancelled,
        npdPayments: financesNpdPayments,
        ndflPayments: financesNdflPayments,
    }),
    map,
    systemTransactions,
    patentPayments,
    notificationSendingToContractor,
    clientsMonitoringRisk,
    contractorsRegistry,
    contractorsResources,
    exportReports,
    exportDocuments,
    promotionChannels,
    promotionChannelsCard,
    commonTree,
    contractorCard,
    contractorCardProfile,
    contractorCardPersonalData,
    contractorCardPersonalDataEventsLog,
    contractorCardEventLog,
    contractorCardDocumentSigning,
    contractorCardNotificationLog,
    documentsAdditionalAgreements,
    documentsAdditionalAgreementsRegistry,
    settingsServiceTaskStats,
    settingsService,
    settingsJoiningRequests,
    settingsEmployees,
    settingsCollectionBankDetails,
    settingsContractorSupport,
    settingsNaimixInfo,
    contractorCardBanks,
    contractorCardDevices,
    contractorCardFinanceBalance,
    contractorCardTaskHistory,
    contractorCardOrderHistory,
    contractorCardDocuments,
    marketingNotifications,
    orderGroups,
    orderGroupsCard,
    orderContractors,
    orderActRegistries,
    orderActRegistriesCard,
    settingsClientGroups,
    settingsClientGroupsCard,
    settingsClientGroupsCardClients,
    settingsClientGroupsCardContractors,
    settingsClientGroupsCardSearchContractors,
    settingsClientGroupsCardClientUsers,
    settingsMachineLearning,
    settingsDirectoriesNaimixBanks,
    settingsDirectoriesNaimixKeywords,
    settingsDirectoriesNaimixDocuments,
    settingsDirectoriesNaimixStatements,
    settingsDirectoriesNaimixClientCategories,
    settingsDirectoriesNaimixSpecialities,
    settingsDirectoriesNaimixCategorySpecialities,
    settingsDirectoriesNaimixTariffGrid,
    settingsDirectoriesNaimixSpecialtyOffer,
    settingsDirectoriesNaimixStopWords,
    settingsDirectoriesNaimixFederalMigration,
    settingsInstructionsKedo,
    settingsInstructionsNaimix,
    settingsDocumentsCorrection,
    clientForemenPayments,
    clientForemenRequests,
    foremenTimesheets,
    clientForemenForemanContractors,
    registryInvitations,
    financesNpdSummary,
    financesNdflSummary,
    financesPatentsSummary,
    reviewsContractor,
    reviewsClient,
    financesOrders,
    clientCardInfo,
    clientDepositReplenishmentAndRefunds,
    clientCardReportsOneC,
    oneC,
    commonJob,
    commonClient,
    ordersRegistry,
    orderContractorWorkLog,
    notifications,
    emailNotifications,
    advertisement,
    registryInvitationsCard,
    clientEmployees,
    clientProjects,
    clientProjectCard,
    clientObjects,
    clientObjectCard,
    clientsPatentsPay,
    clientsPatentsPayCard,
    clientDocumentsRegistry,
    clientFrameContractsRegistries,
    clientDocumentsContractAgency,
    documentsTemplates,
    registryPayment,
    registryPaymentCard,
    registryPaymentLog,
    orderAnalytics,
    orderAnalyticsAllApplications,
    clientsStopList,
    disputes,
    registryPayments,
    clientsEdoDirectoryPositions,
    clientsEdoDirectorySubdivisions,
    clientsEdoStaff,
    clientsEdoDocuments,
    clientsEdoRegistries,
    clientsEdoRegistriesCard,
    clientsEdoStatements,
    subscribeNotifications,
    deferredActsOfAcceptanceWork,
    deferredActsOfAcceptanceWorkCard,
    paidApis,
});