export const ORDER_CONTRACTOR_REJECT_REQUEST = "ORDER_CONTRACTOR_REJECT_REQUEST";
export const ORDER_CONTRACTOR_REJECT_SUCCESS = "ORDER_CONTRACTOR_REJECT_SUCCESS";
export const ORDER_CONTRACTOR_REJECT_ERROR = "ORDER_CONTRACTOR_REJECT_ERROR";

export const ORDER_CONTRACTOR_APPROVE_REQUEST = "ORDER_CONTRACTOR_APPROVE_REQUEST";
export const ORDER_CONTRACTOR_APPROVE_SUCCESS = "ORDER_CONTRACTOR_APPROVE_SUCCESS";
export const ORDER_CONTRACTOR_APPROVE_ERROR = "ORDER_CONTRACTOR_APPROVE_ERROR";

export const ORDER_CONTRACTOR_DECLINE_REQUEST = "ORDER_CONTRACTOR_DECLINE_REQUEST";
export const ORDER_CONTRACTOR_DECLINE_SUCCESS = "ORDER_CONTRACTOR_DECLINE_SUCCESS";
export const ORDER_CONTRACTOR_DECLINE_ERROR = "ORDER_CONTRACTOR_DECLINE_ERROR";

export const ORDER_WORK_REPORT_LIST_REQUEST = "ORDER_WORK_REPORT_LIST_REQUEST";
export const ORDER_WORK_REPORT_LIST_SUCCESS = "ORDER_WORK_REPORT_LIST_SUCCESS";
export const ORDER_WORK_REPORT_LIST_ERROR = "ORDER_WORK_REPORT_LIST_ERROR";

export const ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST = "ORDER_CONTRACTOR_COMPLETE_ORDER_REQUEST";
export const ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS = "ORDER_CONTRACTOR_COMPLETE_ORDER_SUCCESS";
export const ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR = "ORDER_CONTRACTOR_COMPLETE_ORDER_ERROR";

export const ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST = "ORDER_CONTRACTOR_INIT_PAYMENT_REQUEST";
export const ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS = "ORDER_CONTRACTOR_INIT_PAYMENT_SUCCESS";
export const ORDER_CONTRACTOR_INIT_PAYMENT_ERROR = "ORDER_CONTRACTOR_INIT_PAYMENT_ERROR";

export const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_REQUEST";
export const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_SUCCESS";
export const DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR = "DOCUMENT_IS_FRAME_CONTRACT_SIGNED_ERROR";

export const GET_SCORE_ACTUAL_CONTRACTOR_REQUEST = "GET_SCORE_ACTUAL_CONTRACTOR_REQUEST";
export const GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS = "GET_SCORE_ACTUAL_CONTRACTOR_SUCCESS";
export const GET_SCORE_ACTUAL_CONTRACTOR_ERROR = "GET_SCORE_ACTUAL_CONTRACTOR_ERROR";

export const ORDER_CONTRACTOR_ADD_REQUEST = "ORDER_CONTRACTOR_ADD_REQUEST";
export const ORDER_CONTRACTOR_ADD_SUCCESS = "ORDER_CONTRACTOR_ADD_SUCCESS";
export const ORDER_CONTRACTOR_ADD_ERROR = "ORDER_CONTRACTOR_ADD_ERROR";