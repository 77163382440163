import {all, put, takeEvery} from "@redux-saga/core/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

import {REGISTRY_TOAST_TEXT} from "../constants/registry";

const EMAIL_INVITATION_SEND_REQUEST = "EMAIL_INVITATION_SEND_REQUEST";
const EMAIL_INVITATION_SEND_SUCCESS = "EMAIL_INVITATION_SEND_SUCCESS";
const EMAIL_INVITATION_SEND_ERROR = "EMAIL_INVITATION_SEND_ERROR";

const initial = {
    invitationProgress: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case EMAIL_INVITATION_SEND_REQUEST:
            return {
                ...state,
                invitationProgress: true,
            };
        case EMAIL_INVITATION_SEND_SUCCESS:
            return {
                ...state,
                invitationProgress: false,
            };
        case EMAIL_INVITATION_SEND_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

export const inviteViaEmail = payload => {
    return {
        type: EMAIL_INVITATION_SEND_REQUEST,
        payload,
    };
};

export const emailSelector = state => state.email;
export const emailInvitationProgressSelector = createSelector(emailSelector, ({invitationProgress}) => invitationProgress);

const inviteViaEmailSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const url = getBffControllerClientCardPage({
            admin: "/adm/common/notification/sendInvitationMail",
            client: "/client-adm/common/notification/send-invitation-mail",
        });

        const {errorMessage} = yield request.bff.post(url, reqData);

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EMAIL_INVITATION_SEND_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        toastSuccess(REGISTRY_TOAST_TEXT.SUCCESS_INVITE_VIA_EMAIL);
        yield put({type: EMAIL_INVITATION_SEND_SUCCESS});
    } catch (error) {
        yield put({type: EMAIL_INVITATION_SEND_ERROR, payload: error});
    }
};

export function* saga() {
    yield all([
        takeEvery(EMAIL_INVITATION_SEND_REQUEST, inviteViaEmailSaga),
    ]);
}