import {
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST,
    BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE,
} from "./actions";

export const getDeferredActsOfAcceptanceWorkPage = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_PAGE_REQUEST,
        payload,
    };
};

export const getDeferredActsOfAcceptanceWorkStatusDict = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_STATUS_DICT_REQUEST,
        payload,
    };
};

export const archiveDeferredActsAcceptance = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_ARCHIVE_REQUEST,
        payload,
    };
};

export const submitDeferredActsAcceptanceWork = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_SUBMIT_REQUEST,
        payload,
    };
};

export const updateDeferredActsAcceptanceWork = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_REQUEST,
        payload,
    };
};

export const cancelOutstandingDeferredActsAcceptanceWork = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CANCEL_OUTSTANDING_REQUEST,
        payload,
    };
};

export const updateDeferredActsOfAcceptanceWorkStore = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE,
        payload,
    };
};

export const getDeferredActsOfAcceptanceWorkLogPage = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_LOG_PAGE_REQUEST,
        payload,
    };
};

export const clearDeferredActsOfAcceptanceWorkStore = (payload) => {
    return {
        type: BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE,
        payload,
    };
};