import {
    CANCEL_REGISTRY_PAYMENT_ERROR,
    CANCEL_REGISTRY_PAYMENT_REQUEST,
    CANCEL_REGISTRY_PAYMENT_SUCCESS,
    CLEAR_REGISTRY_PAYMENT_LOG,
    GET_REGISTRY_ERROR,
    GET_REGISTRY_REQUEST,
    GET_REGISTRY_SUCCESS,
    REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS,
    REGISTRY_PAYMENT_EXPORT_ERROR,
    REGISTRY_PAYMENT_EXPORT_REQUEST,
    REGISTRY_PAYMENT_EXPORT_SUCCESS,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR,
    REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST,
    REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS,
    REGISTRY_PAYMENT_LIST_REQUEST,
    REGISTRY_PAYMENT_LIST_SUCCESS,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST,
    REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST,
    REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS,
    REGISTRY_PAYMENT_UPDATE_STORE,
} from "./actions";

const initialState = {
    card: {},
    progressCard: false,
    registryPaymentsPageData: {},
    registryPayments: [],
    progressRegistryPayments: false,
    totalCount: 0,
    successSaveContractor: false,
    progressSaveContractor: false,
    progressImportContractors: false,
    importFileSuccess: false,
    progressActionSuccess: false,
    errorMessage: "",
    progressValidation: false,
    progressChangeCategory: false,
    violations: [],
    refreshPerformerActionProcess: false,
    progressDownload: false,
    progressData: {},
    actionProgress: false,
    nmContractDateConclusion: null,
    registryPaymentsHasInProgress: false,
    registryPaymentLog: [],
    registryPaymentLogPageData: {
        pageSize: 25,
        pageNum: 1,
    },
    registryPaymentLogTotalCount: 0,
    availableForPayments: 0,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_REGISTRY_REQUEST:
            return {
                ...state,
                progressCard: true,
            };
        case GET_REGISTRY_SUCCESS:
            return {
                ...state,
                progressCard: false,
                card: payload,
            };
        case GET_REGISTRY_ERROR:
            return {
                ...state,
                progressCard: false,
                error: payload,
            };
        case CANCEL_REGISTRY_PAYMENT_REQUEST:
        case REGISTRY_PAYMENT_EXPORT_REQUEST:
            return {
                ...state,
                actionProgress: true,
            };
        case CLEAR_REGISTRY_PAYMENT_LOG:
            return {
                ...state,
                registryPaymentLog: [],
                registryPaymentLogPageData: {
                    pageSize: 25,
                    pageNum: 1,
                },
                registryPaymentLogTotalCount: 0,
            };
        case REGISTRY_PAYMENT_UPDATE_STORE: {
            return {
                ...state,
                ...payload,
            };
        }
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_REQUEST:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_REQUEST:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_REQUEST:
            return {
                ...state,
                progressSaveContractor: true,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_REQUEST:
            return {
                ...state,
                progressImportContractors: true,
            };
        case REGISTRY_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                registryPaymentsPageData: payload,
                progressRegistryPayments: true,
            };
        case CANCEL_REGISTRY_PAYMENT_SUCCESS:
        case REGISTRY_PAYMENT_EXPORT_SUCCESS:
            return {
                ...state,
                actionProgress: false,
            };
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_SUCCESS:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_SUCCESS:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_SUCCESS:
            return {
                ...state,
                progressSaveContractor: false,
                successSaveContractor: true,
            };
        case REGISTRY_PAYMENT_LIST_SUCCESS:
            const {
                registryPayments,
                totalCount,
                nmContractDateConclusion,
            } = payload;

            return {
                ...state,
                registryPayments,
                nmContractDateConclusion,
                totalCount,
                progressRegistryPayments: false,
            };
        case REGISTRY_PAYMENT_UPDATE_CONTRACTOR_ERROR:
        case REGISTRY_PAYMENT_EDIT_CONTRACTOR_ERROR:
        case REGISTRY_PAYMENT_ADD_CONTRACTOR_ERROR:
            return {
                ...state,
                progressSaveContractor: false,
                successSaveContractor: false,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_SUCCESS:
            return {
                ...state,
                importFileSuccess: true,
                progressImportContractors: false,
                registryPaymentsHasInProgress: true,
            };
        case REGISTRY_PAYMENT_IMPORT_FROM_FILE_ERROR:
            return {
                ...state,
                errorMessage: payload,
                progressImportContractors: false,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_REQUEST:
            return {
                ...state,
                refreshPerformerActionProcess: true,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_SUCCESS:
            return {
                ...state,
                refreshPerformerActionProcess: false,
            };
        case REGISTRY_PAYMENT_REFRESH_PERFORMER_ERROR:
            return {
                ...state,
                refreshPerformerActionProcess: false,
            };
        case CANCEL_REGISTRY_PAYMENT_ERROR:
        case REGISTRY_PAYMENT_EXPORT_ERROR:
            return {
                ...state,
                actionProgress: false,
                error: payload,
            };
        case REGISTRY_PAYMENT_HAS_IN_PROGRESS_SUCCESS:
            return {
                ...state,
                registryPaymentsHasInProgress: payload,
            };
        case REGISTRY_PAYMENT_HAS_IN_PROGRESS_ERROR:
            return {
                ...state,
                registryPaymentsHasInProgress: false,
            };
        case REGISTRY_GET_AVAILABLE_FOR_PAYMENTS_SUCCESS:
            return {
                ...state,
                availableForPayments: payload,
            };
        default:
            return state;
    }
};