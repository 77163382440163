import React from "react";
import {isEmpty} from "lodash";

import ContextMenu from "../../../../../components/ActualComponents/ContextMenu";
import HelpTooltip from "../../../../../components/ActualComponents/HelpTooltip";
import NmListCard from "../../../../../components/ActualComponents/NmList/Card";
import Text from "../../../../../components/ActualComponents/Text";
import NmBadge from "../../../../../components/NmBadge";
import {ReactComponent as BlockedIcon} from "../../../../../images/lock_24.svg";
import {ReactComponent as NoOnIcon} from "../../../../../images/no_on.svg";
import {ReactComponent as YesOnIcon} from "../../../../../images/yes_on.svg";

import {formatLocalDate} from "../../../../../utils/dateFormat";
import {replaceInformation} from "../../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../../utils/stringHelper";
import {getOptions, STATEMENT_ACTION} from "./utils/getOptions";

import "./style.sass";

import {
    EDM_STATEMENT_PARTICIPANTS_STATUS,
    EDM_STATEMENT_PARTICIPANTS_TYPE,
    EDM_STATEMENT_STATUS,
} from "../../../../../constants/document-management/statements";

export default function DocumentManagementStatementItem(props) {
    const {
        statementItem,
        role,
        archived,
        onClickActionItem,
        edoAccessList,
        currentUserRestrictions,
        currentClientUserId,
    } = props;

    const {
        name,
        state,
        participants,
        date,
        created,
        description,
        type,
        attachmentFileName,
    } = statementItem;

    const {value: typeValue} = type || {};

    const options = getOptions({
        role,
        archived,
        state,
        attachmentFileName,
        participants,
    }, edoAccessList, currentUserRestrictions, currentClientUserId);

    const getParticipantByType = (participants, type) => {
        return (participants || []).find(el => el.type === type) || {type};
    };

    const getIcon = (MOD, {type}) => {
        if (!MOD) {
            return null;
        }

        const Icon = MOD === EDM_STATEMENT_PARTICIPANTS_STATUS[type].REJECTED.MOD ? NoOnIcon : YesOnIcon;

        return (
            <Icon
                className={`document-management-statement-item__card-icon document-management-statement-item__card-icon_${MOD}`}
                width={16}
                height={16}
            />
        );
    };

    const renderCards = (participants) => {
        const sender = getParticipantByType(participants, EDM_STATEMENT_PARTICIPANTS_TYPE.SENDER.VALUE);
        const reviewer = getParticipantByType(participants, EDM_STATEMENT_PARTICIPANTS_TYPE.REVIEWER.VALUE);
        const receiver = getParticipantByType(participants, EDM_STATEMENT_PARTICIPANTS_TYPE.RECEIVER.VALUE);

        return [sender, reviewer, receiver].map(item => {
            const {
                rejectReason,
                state,
                contractorBlocked,
                name,
                type,
            } = item;
            const {TEXT, MOD} = EDM_STATEMENT_PARTICIPANTS_STATUS[type][state] || {};
            const text = TEXT || EDM_STATEMENT_PARTICIPANTS_TYPE[type].TEXT;

            return {
                title: text,
                value: (
                    <div className="d-flex align-items-center">
                        {name || "Не определен"}
                        {
                            contractorBlocked &&
                            <BlockedIcon
                                width={24}
                                height={24}
                                className="ms-1"
                                color="red"
                            />
                        }
                        {
                            rejectReason &&
                            <HelpTooltip
                                type="light"
                                width={24}
                                height={24}
                                className="ms-1"
                                position="bottom-right"
                            >
                                {rejectReason}
                            </HelpTooltip>
                        }
                    </div>
                ),
                className: "col-16 col-md-5 col-xxl-3",
                icon: getIcon(MOD, item),
            };
        });
    };

    const getApprovedValue = (approvalParticipants) => {
        if (approvalParticipants.length === 0) {
            return "Не определены";
        }

        return approvalParticipants.map((value, index) => {
            const {
                name,
                rejectReason,
            } = value;

            return (
                <React.Fragment key={index}>
                    {name}
                    {
                        rejectReason &&
                        <HelpTooltip
                            width={18}
                            height={18}
                            text={rejectReason}
                            className="document-management-statement-item__tooltip"
                            info
                        />
                    }
                    {index !== approvalParticipants.length - 1 ? ", " : ""}
                </React.Fragment>
            );
        });
    };

    const _description = !isNullOrWhitespace(description) ? [{
        text: (
            <Text
                noWrap
                dangerouslySetInnerHTML={{__html: replaceInformation(description)}}
                level="3"
            />
        ),
    }] : [];
    const approvalParticipants = participants.filter(item => item.type === EDM_STATEMENT_PARTICIPANTS_TYPE.APPROVER.VALUE);

    const {COLOR, TEXT} = EDM_STATEMENT_STATUS[state] || {};

    return (
        <NmListCard
            checkbox
            classNameMainContent="col-16 col-xxl-6"
            secondaryHeaderStatus={
                <NmBadge
                    text={TEXT}
                    mod={COLOR}
                />
            }
            secondaryHeader={`Заявление от ${formatLocalDate(created, "dd.MM.yyyy HH:mm")}`}
            primaryHeader={
                <span
                    className="app-link"
                    title={STATEMENT_ACTION.SIGNED_FILE.TEXT}
                    onClick={() => {
                        onClickActionItem({value: STATEMENT_ACTION.SIGNED_FILE.VALUE}, statementItem);
                    }}
                >
                    {name}
                </span>
            }
            labels={[
                ..._description,
                {label: "Тип заявления", text: typeValue || "Нет данных"},
                {label: "Дата заявления", text: date ? formatLocalDate(date) : "Нет данных"},
                {label: "Согласующие", noWrap: false, inline: true, text: getApprovedValue(approvalParticipants)},
            ]}
            cards={renderCards(participants)}
            actionsClassName="col-1"
            actions={
                !isEmpty(options) &&
                <ContextMenu
                    options={options}
                    onClickItem={(option) => {
                        onClickActionItem(option, statementItem);
                    }}
                />
            }
        />
    );
}