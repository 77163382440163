import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import FilterButtonsV2 from "../../../../components/ActualComponents/FilterButtonsV2";
import FilterCustomer from "../../../../components/ActualComponents/FilterCustomer";
import FilterObject from "../../../../components/ActualComponents/FilterObject";
import FilterProject from "../../../../components/ActualComponents/FilterProject";
import NmDateRangePickerV2 from "../../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../components/ActualComponents/NmInputV2";
import {CREATE_ACTS_TYPE_OPTIONS} from "../../registryActsType";

import {ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS} from "../../../../constants/finance";

import {registryStatusOptionsSelector} from "../../../../ducks/bff/clients/registry-payment/selectors";

import PropTypes from "prop-types";

import "./style.sass";
const RegistryFilter = (props) => {
    const {
        search,
        clear = () => {},
        handleChange,
        numberFilter,
        nameFilter = "",
        clientFilter,
        statusesFilter = [],
        fromDateCreateFilter = "",
        toDateCreateFilter = "",
        creatorFioFilter,
        createActsTypeFilter,
        orderContractPaymentTypeFilter,
        projectIds,
        objectIds,
        isClientCard,
        clientId,
        t,
    } = props;

    const statusOptions = useSelector(registryStatusOptionsSelector);

    const [isSearchClear, setSearchClear] = useState(false);

    const handleClearFilter = () => {
        clear();

        setSearchClear(true);
        setTimeout(() => {
            setSearchClear(false);
        }, 50);
    };

    return (
        <NmForm
            className="registry-list-filter"
            addMargin
        >
            <FilterButtonsV2
                className="registry-list-filter__buttons"
                onSearch={search}
                onClear={handleClearFilter}
            />
            <NmInputV2
                placeholder="Введите номер"
                value={numberFilter}
                name="numberFilter"
                label={t("registries.number-filter-text")}
                onChange={handleChange}
                maskChar={null}
                mask="99999999999999"
                size="lg"
            />
            <NmDateRangePickerV2
                size="lg"
                startFieldName="fromDateCreateFilter"
                endFieldName="toDateCreateFilter"
                value={{fromDateCreateFilter, toDateCreateFilter}}
                label={t("registries.date-filter-text")}
                onChange={handleChange}
            />
            <NmInputV2
                placeholder="Введите название"
                value={nameFilter}
                name="nameFilter"
                label={t("registries.name-filter-text")}
                onChange={handleChange}
                size="lg"
            />
            {
                !isClientCard &&
                <FilterCustomer
                    value={clientFilter}
                    name="clientFilter"
                    placeholder="Выберите из списка"
                    size="lg"
                    search
                    onSubmit={search}
                    onCancel={clear}
                    onChange={handleChange}
                />
            }
            <NmDropdownV2
                options={statusOptions}
                label={t("registries.status-filter-text")}
                value={statusesFilter}
                name="statusesFilter"
                placeholder="Все"
                size="lg"
                multiple
                onSubmit={search}
                onCancel={clear}
                onChange={handleChange}
            />
            <FilterProject
                clientId={clientId}
                label="Проект"
                placeholder="Выберите из списка"
                name="projectIds"
                onChange={handleChange}
                search
                projectIdsFilter={projectIds}
                isSearchClear={isSearchClear}
                isActiveStyle={false}
                onSubmit={search}
            />
            <FilterObject
                clientId={clientId}
                name="objectIds"
                placeholder="Выберите из списка"
                onChange={handleChange}
                projectIdsFilter={projectIds}
                objectIdsFilter={objectIds}
                isSearchClear={isSearchClear}
                isActiveStyle={false}
                onSubmit={search}
            />
            <NmDropdownV2
                options={ORDER_WORK_REPORT_TYPE_FILTER_OPTIONS}
                label="Договор"
                value={orderContractPaymentTypeFilter}
                name="orderContractPaymentTypeFilter"
                placeholder="Все"
                size="lg"
                onChange={handleChange}
            />
            <NmDropdownV2
                options={CREATE_ACTS_TYPE_OPTIONS}
                label={t("registries.acts")}
                value={createActsTypeFilter}
                name="createActsTypeFilter"
                placeholder="Все"
                size="lg"
                onChange={handleChange}
            />
            <NmInputV2
                placeholder="Введите ФИО"
                value={creatorFioFilter}
                name="creatorFioFilter"
                label={t("registries.responsible")}
                onChange={handleChange}
                size="lg"
            />
        </NmForm>
    );
};

RegistryFilter.propTypes = {
    search: PropTypes.func,
    clear: PropTypes.func,
    handleChange: PropTypes.func,
    numberFilter: PropTypes.number,
    nameFilter: PropTypes.string,
    clientFilter: PropTypes.string,
    statusesFilter: PropTypes.string,
    fromDateCreateFilter: PropTypes.object,
    toDateCreateFilter: PropTypes.object,
    isClientCard: PropTypes.bool,
    t: PropTypes.func,
};
export default withTranslation()(RegistryFilter);