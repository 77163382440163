import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import LogoCardBlock from "../../../components/LogoCardBlock";
import NmLoader from "../../../components/NmLoader";
import NmTitle from "../../../components/NmTitle";
import ClientInfoAbout from "./about";
import AccountantCardBlock from "./accountant-card-block";
import AnalyticsBrandBlock from "./analytics-brand-block";
import BankCardBlock from "./bank-card-block";
import BossCardBlock from "./boss-card-block";
import ClientCivilContract from "./civil-contract";
import ClientCardBlock from "./client-card-block";
import ClientSettingsBlock from "./client-settings-block";
import DefiningRegistryParameterBlock from "./defining-registry-parameter-block";
import IndustriesCardBlock from "./industries-card-block";
import ClientInfoInnCheck from "./inn-check";
import LimitsCardBlock from "./limits-card-block";
import ModulesAndTariffs from "./modules-and-tariffs-block";
import ProjectParamsBlock from "./project-params-block";
import PromocodeCardBlock from "./promocode-card-block";
import RepresentativeCardBlock from "./representative-card-block";
import SignatoryCardBlock from "./signatory-card-block";
import ClientTreeSettings from "./tree-settings";

import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {
    isShowPromoCodesBlock,
} from "../../../utils/user-rights/promoCodesAndLimites";

import {CLIENT_SETTING_TYPE} from "../../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {CLIENT_TYPE_CODE} from "../../../constants/dicts";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    HR_MANAGER,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
} from "../../../constants/roles";

import {
    getClientCardInfo,
    getClientCardProperties,
} from "../../../ducks/bff/clients/info/actionCreators";
import {
    clientCardInfoProgressSelector,
    clientCardInfoSelector,
    clientCardPropertiesSelector,
    getClientTypeSelector,
} from "../../../ducks/bff/clients/info/selectors";

import PropTypes from "prop-types";

import "./style.sass";

class ClientContactInfo extends Component {
    static propTypes = {
        getClientCardInfo: PropTypes.func,
        isEditable: PropTypes.bool,
        selectedClientId: PropTypes.string,
        updateClientCardInfo: PropTypes.func,
    };

    static defaultProps = {
        updateClientCardInfo: () => {
        },
        getClientCardInfo: PropTypes.func,
    };

    constructor() {
        super();
        this.state = {
            clientType: {},
        };

        this.role = ls(USER_ROLE);
    }

    static getDerivedStateFromProps(newProps, state) {
        const {clientType: _clientType} = state;
        const {clientType} = newProps;

        if (JSON.stringify(clientType) !== JSON.stringify(_clientType)) {
            return {
                ...state,
                clientType: {
                    isIndividualClient: clientType === CLIENT_TYPE_CODE.INDIVIDUAL_ENTREPRENEUR,
                    isForeignClient: clientType === CLIENT_TYPE_CODE.FOREIGN_LEGAL_ENTITY,
                    isRussianClient: clientType === CLIENT_TYPE_CODE.RUSSIAN_LEGAL_ENTITY,
                },
            };
        }

        return null;
    }

    componentDidMount() {
        this.getClientById();

        this.fetchClientProperties();
    }

    getClientById = () => {
        const {getClientCardInfo, selectedClientId} = this.props;

        getClientCardInfo({clientId: selectedClientId});
    };

    get isEditableAboutBlock() {
        return [
            ADMIN,
            NM_MANAGER,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
        ].includes(this.role);
    }

    get isShowAboutBlock() {
        return [
            ADMIN,
            NM_MANAGER,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            NM_COORDINATOR,
            NM_CONSULTANT,
            CLIENT_ADMIN,
            HR_MANAGER,
            NM_OPERATOR,
        ].includes(this.role);
    }

    get isShowProjectParamsBlock() {
        return [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CONSULTANT,
            NM_CHIEF_ACCOUNTANT,
            NM_OPERATOR,
        ].includes(this.role);
    }

    get isEditableProjectParamsBlock() {
        return [
            ADMIN,
        ].includes(this.role);
    }

    get isShowWithoutContract() {
        const {
            clientProperties: {
                withoutContract,
            },
        } = this.props;

        return !withoutContract;
    }

    get isShowCivilContract() {
        return [
            ADMIN,
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            NM_CONSULTANT,
            CLIENT_ADMIN,
            CLIENT_ACCOUNTANT,
            HR_MANAGER,
            NM_OPERATOR,
        ].includes(this.role);
    }

    fetchClientProperties = () => {
        const {getClientCardProperties, match} = this.props;
        const {params} = match;
        const {clientId} = params;

        getClientCardProperties({clientId: clientId});
    };

    render() {
        const {clientType} = this.state;
        const {
            match,
            selectedClientId,
            client: {
                archived,
            },
            progressClientProperties,
        } = this.props;
        const {params} = match;
        const {clientId} = params;

        const isAccessEditCommissionsAndProjectParams = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.clientPropertiesCommissionAndLimitsAndProjectParams,
        ]);
        const isAccessEditClientProperties = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.editClientProperties,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editClientPropertiesSomeBlock,
        ]);
        const isEditable = this.props.isEditable && isAccessEditClientProperties;
        const isAccessEditPropertiesSomeBlockClient = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editPropertiesSomeBlock,
        ]);
        const isAccessPromoBlock = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessPromoSection,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessPromoContractors,
        ]);

        return (
            <div className="client-contact-info container-fluid">
                <NmLoader
                    active={progressClientProperties}
                    fixed={true}
                    inverted
                />
                <div className="d-flex align-items-start justify-content-between mb-5">
                    <NmTitle size="xl">
                        Информация
                    </NmTitle>
                    {
                        !clientType.isForeignClient &&
                        isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.checkDadataClientInfo,
                        ]) &&
                        [ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT, NM_CONSULTANT].includes(this.role) &&
                        <ClientInfoInnCheck clientId={selectedClientId} />
                    }
                </div>
                <div className="row">
                    {clientId && (
                        <>
                            <div className="col-md-8 col-xl-5">
                                <ClientCardBlock
                                    clientType={clientType}
                                    isEditable={isEditable}
                                />
                                {
                                    this.isShowWithoutContract &&
                                    <LimitsCardBlock
                                        isEditable={
                                            this.props.isEditable
                                            && isAccessByRestrictions([
                                                CLIENT_USER_RESTRICTIONS_VARIABLE.clientPropertiesCommissionAndLimitsAndProjectParams,
                                                CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editPropertiesSomeBlock,
                                            ])
                                            && [ADMIN, CLIENT_ADMIN].includes(this.role)
                                        }
                                        clientId={clientId}
                                    />
                                }
                                <ModulesAndTariffs
                                    clientId={clientId}
                                    isEditable={isEditable}
                                />
                                {
                                    this.isShowWithoutContract &&
                                    <IndustriesCardBlock
                                        isEditable={isEditable}
                                        clientId={clientId}
                                    />
                                }
                                {
                                    (
                                        isShowPromoCodesBlock(this.role)
                                        && this.isShowWithoutContract
                                        && isAccessPromoBlock
                                    ) &&
                                    <PromocodeCardBlock
                                        clientId={clientId}
                                        isEditable={isEditable}
                                    />
                                }
                            </div>
                            <div className="col-md-8 col-xl-4">
                                <BankCardBlock
                                    isEditable={isEditable}
                                    clientType={clientType}
                                />
                                {
                                    this.isShowWithoutContract &&
                                    <AccountantCardBlock
                                        isEditable={isEditable}
                                    />
                                }
                                {
                                    this.isShowWithoutContract &&
                                    <ClientSettingsBlock
                                        clientId={clientId}
                                        isEditable={isEditable || this.role === CLIENT_ACCOUNTANT}
                                    />
                                }
                                {
                                    this.isShowCivilContract &&
                                    <ClientCivilContract
                                        clientId={clientId}
                                        isEditable={
                                            !archived
                                            && isAccessEditClientProperties
                                            && [ADMIN, NM_MANAGER, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)
                                        }
                                    />
                                }
                                {
                                    this.isShowProjectParamsBlock &&
                                    this.isShowWithoutContract &&
                                    <ProjectParamsBlock
                                        isEditable={
                                            !archived
                                            && this.isEditableProjectParamsBlock
                                            && isAccessEditCommissionsAndProjectParams
                                        }
                                    />
                                }
                                <DefiningRegistryParameterBlock
                                    isEditable={
                                        !archived
                                        && isAccessByRestrictions([
                                            CLIENT_USER_RESTRICTIONS_VARIABLE.editClientProperties,
                                            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_editPropertiesSomeBlock,
                                        ])
                                        && [ADMIN, NM_MANAGER, CLIENT_ADMIN].includes(this.role)
                                    }
                                />
                                <ClientTreeSettings
                                    clientId={clientId}
                                    checkboxLabel="Заказы только по шаблонам"
                                    modalTitle="Настройка заказов только по шаблонам"
                                    title="Заказы только по шаблонам"
                                    settingsType={CLIENT_SETTING_TYPE.ORDER_BY_TEMPLATE}
                                    settingsName="orderByTemplate"
                                    isEditable={
                                        !archived
                                        && isAccessEditClientProperties
                                        && isAccessEditPropertiesSomeBlockClient
                                        && [
                                            ADMIN,
                                            NM_MANAGER,
                                            CLIENT_ADMIN,
                                            PROJECT_MANAGER,
                                            OBJECT_MANAGER,
                                        ].includes(this.role)
                                    }
                                />
                                <ClientTreeSettings
                                    clientId={clientId}
                                    checkboxLabel="Скрывать заказы для откликов при завершении подбора"
                                    modalTitle="Настройка отображения заказов при завершении подбора"
                                    title="Скрытие заказов компании"
                                    settingsName="hideOrdersAfterEndContractorsSearch"
                                    isEditable={
                                        !archived
                                        && isAccessEditClientProperties
                                        && [
                                            ADMIN,
                                            NM_MANAGER,
                                            CLIENT_ADMIN,
                                            PROJECT_MANAGER,
                                            OBJECT_MANAGER,
                                        ].includes(this.role)
                                        && isAccessEditPropertiesSomeBlockClient
                                    }
                                />
                                {
                                    (
                                        [ADMIN, NM_MANAGER, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role)
                                        && isAccessByRestrictions([
                                            CLIENT_USER_RESTRICTIONS_VARIABLE.accessBrandForAnalytics,
                                        ])
                                    ) &&
                                    <AnalyticsBrandBlock
                                        isEditable={!archived}
                                    />
                                }
                            </div>
                            <div className="col-md-8 col-xl-4">
                                {
                                    this.isShowWithoutContract &&
                                    <BossCardBlock
                                        clientType={clientType}
                                        isEditable={isEditable}
                                    />
                                }
                                <RepresentativeCardBlock
                                    isEditable={isEditable}
                                />
                                <SignatoryCardBlock
                                    isEditable={isEditable}
                                />
                            </div>
                            <div className="col-md-8 col-xl-3">
                                {
                                    this.isShowAboutBlock && this.isShowWithoutContract &&
                                    <ClientInfoAbout
                                        isEditable={
                                            isAccessEditClientProperties
                                            && this.isEditableAboutBlock
                                        }
                                    />
                                }
                                {
                                    this.isShowWithoutContract &&
                                    <LogoCardBlock
                                        isEditable={isEditable}
                                    />
                                }
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        client: clientCardInfoSelector(state),
        clientProperties: clientCardPropertiesSelector(state),
        progressClientProperties: clientCardInfoProgressSelector(state),
        clientType: getClientTypeSelector(state),
    }),
    {
        getClientCardProperties,
        getClientCardInfo,
    },
)(withTranslation()(ClientContactInfo));
