import {
    ORDER_ANALYTICS_ERROR,
    ORDER_ANALYTICS_PAGE_ERROR,
    ORDER_ANALYTICS_PAGE_REQUEST,
    ORDER_ANALYTICS_PAGE_SUCCESS,
    ORDER_ANALYTICS_REQUEST,
    ORDER_ANALYTICS_SUCCESS,
} from "./actions";

const initial = {
    orderAnalytics: {},
    orderAnalyticsPage: {
        orderAnalyticsResultModels: [],
    },
    progress: false,
    error: null,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
        case ORDER_ANALYTICS_PAGE_REQUEST:
        case ORDER_ANALYTICS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case ORDER_ANALYTICS_PAGE_SUCCESS:
            return {
                ...state,
                orderAnalyticsPage: payload,
                progress: false,
            };
        case ORDER_ANALYTICS_SUCCESS:
            return {
                ...state,
                orderAnalytics: payload,
                progress: false,
            };
        case ORDER_ANALYTICS_PAGE_ERROR:
        case ORDER_ANALYTICS_ERROR:
            return {
                ...state,
                error: payload,
                progress: false,
            };
        default:
            return state;
    }
};