import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";

import {
    getMutualSettlementsReconciliationActs,
    updateMutualSettlementsReconciliationActsStore,
} from "../../../../ducks/mutualSettlementsReconciliationActs";

export const useFinanceReconciliationActsFetch = (params) => {
    const {
        pageNum,
        pageSize,
        filterData,
        clientId,
    } = params;
    const dispatch = useDispatch();

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filterData]);

    useEffect(() => {
        return () => {
            dispatch(updateMutualSettlementsReconciliationActsStore({
                list: [],
                totalCount: 0,
            }));
        };
    }, []);

    const fetchList = () => {
        dispatch(getMutualSettlementsReconciliationActs({
            pageNum,
            pageSize,
            ...filterData,
            clientIdsFilter: clientId ? [clientId] : filterData.clientIdsFilter,
        }),
        );
    };

    return {
        fetchList,
    };
};