import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEqual} from "lodash";

import FilterButtonsV2 from "../../components/ActualComponents/FilterButtonsV2";
import NmConfirmV2 from "../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../components/ActualComponents/NmDateRangePickerV2";
import NmEmptyPageV2 from "../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../components/ActualComponents/NmForm";
import NmInputV2 from "../../components/ActualComponents/NmInputV2";
import NmListCard from "../../components/ActualComponents/NmList/Card";
import NmShowMoreText from "../../components/ActualComponents/NmShowMoreText";
import CheckboxList from "../../components/CheckboxList";
import NmPage from "../../components/NmPage";
import Task from "../../components/NmTask";
import NmTitle from "../../components/NmTitle";
import SelectionCountWithAction from "../../components/SelectionCountWithAction";
import {withPageData} from "../../components/withPageData";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import StopListAddInnModal from "./stop-list-add-inn";
import StopListExportModal from "./stop-list-export";
import StopListLoadingFileModal from "./stop-list-loading-file";

import dateFormat from "../../utils/dateFormat";
import {
    CURRENT_CLIENT_ID,
    ls,
    USER_ROLE,
} from "../../utils/localstorage";

import {COMPONENT} from "../../components/ActualComponents/MediaControls/constants";
import {
    ADMIN,
    NM_MANAGER,
} from "../../constants/roles";

import {clientCardInfoSelector} from "../../ducks/bff/clients/info/selectors";
import {
    addInnForbidden,
    deleteAllInnClient,
    deleteInnsForbidden,
    exportStopList,
    getStopList,
    importInnsFromFile,
} from "../../ducks/bff/clients/stop-list/actionCreators";
import {
    getStopListSelector,
    getTotalCountStopListSelector,
    getTotalPagesListSelector,
    stopListProgressSelector,
} from "../../ducks/bff/clients/stop-list/selectors";
import {clientCurrentMemberSelector} from "../../ducks/clientMember";
import {
    getAllTasks,
    jobTasksImportForbiddenInnListSelector,
} from "../../ducks/job";

import PropTypes from "prop-types";

import "./style.sass";

const ACTION = {
    ADD_INN_FORBIDDEN: "ADD_INN_FORBIDDEN",
    IMPORTS_INN_FROM_FILES: "IMPORTS_INN_FROM_FILES",
    EXPORT_STOP_LIST: "EXPORT_STOP_LIST",
    DELETE_ALL: "DELETE_ALL",
    FILTER: "FILTER",
    DELETE: "DELETE",
};

const ACTION_MOBILE_OPTIONS = [
    {
        key: ACTION.DELETE,
        text: "Удалить",
        value: ACTION.DELETE,
    },
];

class StopList extends Component {
    static propTypes = {
        clientId: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {
        list: [],
    };

    constructor(props) {
        super(props);
        const {clientId} = props;

        this.state = {
            formFilter: {
                textFilter: "",
                fromDateFilter: null,
                toDateFilter: null,
            },
            pageSize: 25,
            pageNum: 1,
            confirmData: {},
            isRenderAddModal: false,
            isRenderLoadingFromFileModal: false,
            isRenderExportModal: false,
            list: [],
            selectedList: [],
            numberSelected: 0,
            isSearch: false,
        };

        this.role = ls(USER_ROLE);
        this.clientId = clientId || ls(CURRENT_CLIENT_ID);
        this.isAdminPage = [ADMIN, NM_MANAGER].includes(this.role);
    }

    componentDidMount() {
        this.fetchList();
    }

    componentDidUpdate(prevProps) {
        const {
            progressTasks,
        } = this.props;

        const {
            progressTasks: prevProgressTasks,
        } = prevProps;

        if (progressTasks.length < prevProgressTasks.length) {
            this.fetchList();
        }

    }

    get isClientArchived() {
        const {
            client: {
                archived: isClientArchived,
            },
        } = this.props;

        return isClientArchived;
    }

    static getDerivedStateFromProps(props, state) {
        const {list} = props;
        const {list: oldList} = state;

        if (!isEqual(list, oldList)) {
            const selectedList = list.map(item => ({
                ...item,
            }));

            return {
                ...state,
                selectedList,
                list,
                numberSelected: 0,
            };
        }

        return null;
    }

    fetchList = () => {
        const {getStopList} = this.props;
        const {
            pageNum,
            pageSize,
            formFilter: {
                innFilter,
                fromDateFilter,
                toDateFilter,
            },
        } = this.state;

        getStopList(
            {
                toDateFilter: toDateFilter ? dateFormat(toDateFilter, "yyyy-MM-dd") : null,
                fromDateFilter: fromDateFilter ? dateFormat(fromDateFilter, "yyyy-MM-dd") : null,
                innFilter,
                pageNum,
                pageSize,
                clientIdFilter: this.clientId,
            },
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    openConfirmDeleteAll = () => {
        this.openConfirm({
            confirmText: "Вы действительно хотите удалить выбранные ИНН из стоп-листа компании?",
            onConfirm: this.deleteSelectedInns,
        });
    };

    openConfirmDeleteSelected = () => {
        this.openConfirm({
            confirmText: "Вы действительно хотите удалить все ИНН из стоп-листа компании?",
            onConfirm: this.deleteAllInn,
        });
    };

    openConfirm(action) {
        this.setState({
            isShowConfirm: true,
            confirmData: action,
        });
    };

    closeConfirm = () => {
        this.setState({
            confirmData: {},
            isShowConfirm: false,
        });
    };

    deleteAllInn = () => {
        const {deleteAllInnClient} = this.props;
        deleteAllInnClient({
            clientId: this.clientId,
        });
        this.closeConfirm();
    };

    deleteSelectedInns = () => {
        const {deleteInnsForbidden} = this.props;
        const {selectedList} = this.state;

        const requestData = selectedList.filter(item => item.isSelected).map(({clientId, created, inn}) => ({
            clientId,
            created,
            inn,
        }));

        deleteInnsForbidden({listInns: requestData});

        this.closeConfirm();
    };

    renderConfirmWindow = () => {
        const {t} = this.props;

        const {
            isShowConfirm,
            confirmData: {
                confirmText,
                onConfirm,
            },
        } = this.state;

        if (!isShowConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                content={confirmText}
                onCancel={this.closeConfirm}
                onConfirm={onConfirm}
                confirmButton={t("button.yes")}
                cancelButton={t("button.no")}
            />
        );
    };

    handleChange = (updatedState) => {
        this.setState({
            ...updatedState,
        });
    };

    addInnForbidden = (inn) => {
        const {addInnForbidden} = this.props;

        addInnForbidden({
            clientId: this.clientId,
            inn,
        });
    };

    getTasks = () => {
        const {
            getAllTasks,
            currentMember: {
                clientId,
                clientUserId,
            },
        } = this.props;

        getAllTasks({
            clientId,
            clientUserId,
        });
    };

    importInnsFromFile = (formData) => {
        const {importInnsFromFile} = this.props;

        importInnsFromFile({
            clientId: this.clientId,
            formData,
            onSuccess: this.getTasks,
        });
    };

    exportStopList = (formatType) => {
        const {exportStopList} = this.props;

        exportStopList({
            clientId: this.clientId,
            formatType,
        });
    };

    renderModal = () => {
        const {isRenderAddModal, isRenderLoadingFromFileModal, isRenderExportModal} = this.state;

        return (
            <>
                {isRenderAddModal &&
                    <StopListAddInnModal
                        onSubmit={this.addInnForbidden}
                        onCancel={() => this.handleChange({isRenderAddModal: false})}
                    />
                }
                {isRenderLoadingFromFileModal &&
                    <StopListLoadingFileModal
                        onSubmit={this.importInnsFromFile}
                        onCancel={() => this.handleChange({isRenderLoadingFromFileModal: false})}
                    />
                }
                {isRenderExportModal &&
                    <StopListExportModal
                        onSubmit={this.exportStopList}
                        onCancel={() => this.handleChange({isRenderExportModal: false})}
                    />
                }
            </>
        );
    };

    handleFilter = (e, {name, value}) => {
        this.setState(prevState => ({
            ...prevState,
            formFilter: {
                ...prevState.formFilter,
                [name]: value,
            },
        }));
    };

    submitFilter = () => {
        this.setState({
            pageNum: 1,
            isSearch: true,
        }, this.fetchList);
    };

    clearFilter = () => {
        this.setState({
            formFilter: {
                innFilter: "",
                fromDateFilter: null,
                toDateFilter: null,
            },
            isSearch: false,
        }, this.fetchList);
    };

    renderFilter() {
        const {
            formFilter,
        } = this.state;

        return (
            <NmForm
                horizontal
                className={`stop-list__filter ${this.isAdminPage ? "stop-list__admin-filter" : ""}`}
            >
                <NmDateRangePickerV2
                    label="Период добавления"
                    onChange={this.handleFilter}
                    size="lg"
                    startFieldName="fromDateFilter"
                    endFieldName="toDateFilter"
                    value={{
                        fromDateFilter: formFilter.fromDateFilter,
                        toDateFilter: formFilter.toDateFilter,
                    }}
                    isCurrentDateMax
                />
                <NmInputV2
                    size="lg"
                    label="ИНН"
                    maskChar={null}
                    mask="999999999999"
                    onChange={this.handleFilter}
                    name="innFilter"
                    value={formFilter.innFilter}
                />
                <FilterButtonsV2
                    className="stop-list__actions"
                    onSearch={this.submitFilter}
                    onClear={this.clearFilter}
                />
            </NmForm>
        );
    }

    getRows = () => {
        const {selectedList} = this.state;

        return selectedList.map(row => {
            return {
                ...row,
                isSelected: Boolean(row.isSelected),
                showCheckBox: true,
                contentRow: (
                    <NmListCard
                        noDivider
                        checkbox
                        secondaryHeader={`Дата добавления ${dateFormat(row.created, "dd.MM.yyyy")}`}
                        labels={[{label: "ИНН", text: row.inn}]}
                    />
                ),
            };
        });
    };

    onSelectedRows = (selectedList) => {
        this.setState({
            selectedList,
            numberSelected: selectedList.filter(item => item.isSelected).length,
        });
    };

    onClickContextMenuItem = ({value}) => {
        switch (value) {
            case ACTION.DELETE_ALL:
                this.openConfirmDeleteSelected();

                return;
            case ACTION.EXPORT_STOP_LIST:
                this.handleChange({isRenderExportModal: true});

                return;
            case ACTION.IMPORTS_INN_FROM_FILES:
                this.handleChange({isRenderLoadingFromFileModal: true});

                return;
            case ACTION.ADD_INN_FORBIDDEN:
                this.handleChange({isRenderAddModal: true});

                return;
            default:
                return;
        }
    };

    getMediaControls = (isShowControls) => {
        return {
            renderCount: {
                mobile: 1,
                tablet: 1,
                desktop: 2,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "green",
                        icon: <AddIcon />,
                        children: "Добавить ИНН",
                        onClick: () => this.handleChange({isRenderAddModal: true}),
                    },
                    visible: !this.isClientArchived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        color: "grey",
                        children: "Удалить все",
                        onClick: () => this.openConfirmDeleteSelected(),
                    },
                    visible: isShowControls,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Загрузить список",
                        onClick: () => this.handleChange({isRenderLoadingFromFileModal: true}),
                    },
                    visible: !this.isClientArchived,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        size: "xl",
                        children: "Выгрузить список",
                        onClick: () => this.handleChange({isRenderExportModal: true}),
                    },
                    visible: isShowControls,
                },
            ],
        };
    };

    render() {
        const {
            progress,
            totalPages,
            totalCount,
            children,
        } = this.props;

        const {
            pageNum,
            pageSize,
            numberSelected,
            isSearch,
        } = this.state;

        const isShowControls = totalCount || isSearch;

        return (
            <NmPage
                className="fluid-flex-grow"
                isLoaded={progress}
                isShowFilterButton={false}
                header={
                    <NmTitle
                        size="xl"
                        count={totalCount}
                    >
                        {this.isAdminPage ? "Реестр исполнителей" : "Стоп-лист"}
                    </NmTitle>
                }
                typeFilter="vertical"
                filtersBase={
                    this.renderFilter()
                }
                mediaControls={this.getMediaControls(isShowControls)}
                controlsClassName="stop-list__controls"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={this.handleChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                totalCount={totalCount}
            >
                <Task />
                {children}
                <NmShowMoreText
                    isLoaded={!progress}
                    className="stop-list__info-text"
                    anchor="green"
                    lines={3}
                    children="Согласно 422-ФЗ запрещено оказывать услуги в качестве самозанятого заказчикам,
                    которые являются работодателями или бывшими работодателями менее двух лет назад.
                    В данном разделе Вы можете загрузить или добавить ИНН ваших текущих сотрудников и тех,
                    с которыми у вас были трудовые отношения (нанимали в штат) в ближайшие 2 года, чтобы сформировать
                    стоп-лист,
                    который: скроет заказы, запретит заключение РД и проведение выплат для подобных исполнителей"
                    more="Читать полностью"
                />
                {this.renderConfirmWindow()}
                {this.renderModal()}
                {
                    !(totalCount || progress) ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            textAction={!this.isClientArchived && "Загрузить список"}
                            onClickAction={
                                !this.isClientArchived ?
                                    () => this.handleChange({isRenderLoadingFromFileModal: true}) :
                                    null
                            }
                            isSearch={isSearch}
                        /> :
                        <>
                            <CheckboxList
                                header={
                                    <SelectionCountWithAction
                                        adaptiveLogic
                                        count={numberSelected}
                                        onClick={this.openConfirmDeleteAll}
                                        buttonContent="Удалить"
                                    />
                                }
                                disabledMobileContextMenu={numberSelected === 0}
                                rows={this.getRows()}
                                onSelectedRows={this.onSelectedRows}
                                actionOptions={ACTION_MOBILE_OPTIONS}
                                onClickActionItem={this.openConfirmDeleteAll}
                            />
                        </>
                }
            </NmPage>
        );
    }
}

export default withPageData(connect(
    state => ({
        list: getStopListSelector(state),
        totalPages: getTotalPagesListSelector(state),
        totalCount: getTotalCountStopListSelector(state),
        progress: stopListProgressSelector(state),
        client: clientCardInfoSelector(state),
        progressTasks: jobTasksImportForbiddenInnListSelector(state),
        currentMember: clientCurrentMemberSelector(state),
    }),
    {
        getStopList,
        deleteAllInnClient,
        deleteInnsForbidden,
        addInnForbidden,
        importInnsFromFile,
        exportStopList,
        getAllTasks,
    },
)(withTranslation()(StopList)));
