export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_INFO_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_EXPORT_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_PAGE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_STATUS_DICT_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEMS_DELETE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_REQUEST";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_SUCCESS";
export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_ITEM_UPDATE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_UPDATE_STORE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_UPDATE_STORE_ERROR";

export const BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CARD_CLEAR_STORE_ERROR = "BFF_DOCUMENTS_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_CLEAR_STORE_ERROR";