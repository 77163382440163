export const CLIENT_OBJECTS_CARD_GET_REQUEST = "CLIENT_OBJECTS_CARD_GET_REQUEST";
export const CLIENT_OBJECTS_CARD_GET_SUCCESS = "CLIENT_OBJECTS_CARD_GET_SUCCESS";
export const CLIENT_OBJECTS_CARD_GET_ERROR = "CLIENT_OBJECTS_CARD_GET_ERROR";

export const GET_PROJECT_OBJECT_USER_LIST_REQUEST = "GET_PROJECT_OBJECT_USER_LIST_REQUEST";
export const GET_PROJECT_OBJECT_USER_LIST_SUCCESS = "GET_PROJECT_OBJECT_USER_LIST_SUCCESS";
export const GET_PROJECT_OBJECT_USER_LIST_ERROR = "GET_PROJECT_OBJECT_USER_LIST_ERROR";

export const ADD_OBJECT_USER_REQUEST = "ADD_OBJECT_USER_REQUEST";
export const ADD_OBJECT_USER_SUCCESS = "ADD_OBJECT_USER_SUCCESS";
export const ADD_OBJECT_USER_ERROR = "ADD_OBJECT_USER_ERROR";

export const DELETE_OBJECT_USER_REQUEST = "DELETE_OBJECT_USER_REQUEST";
export const DELETE_OBJECT_USER_SUCCESS = "DELETE_OBJECT_USER_SUCCESS";
export const DELETE_OBJECT_USER_ERROR = "DELETE_OBJECT_USER_ERROR";

export const CLIENT_OBJECTS_CARD_UPDATE_STORE = "CLIENT_OBJECTS_UPDATE_STORE";

export const CLIENT_OBJECTS_CARD_CLEAR_STORE = "CLIENT_OBJECTS_CLEAR_STORE";