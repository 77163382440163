import {
    CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST,
    CLIENT_OBJECTS_ADD_REQUEST,
    CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
    CLIENT_OBJECTS_CLEAR_STORE,
    CLIENT_OBJECTS_CLOSE_REQUEST,
    CLIENT_OBJECTS_EXPORT_REQUEST,
    CLIENT_OBJECTS_GET_PAGE_REQUEST,
    CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST,
    CLIENT_OBJECTS_UPDATE_REQUEST,
    CLIENT_OBJECTS_UPDATE_STATUS_REQUEST,
    CLIENT_OBJECTS_UPDATE_STORE,
    GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST,
    IMPORT_OBJECT_DEPOSIT_REQUEST,
    OBJECTS_CHANGE_ARCHIVE_REQUEST,
    OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST,
} from "./actions";

export const getPageClientObjects = (payload) => {
    return {
        type: CLIENT_OBJECTS_GET_PAGE_REQUEST,
        payload,
    };
};

export const addClientObject = (payload) => {
    return {
        type: CLIENT_OBJECTS_ADD_REQUEST,
        payload,
    };
};

export const updateClientObject = (payload) => {
    return {
        type: CLIENT_OBJECTS_UPDATE_REQUEST,
        payload,
    };
};

export const updateClientObjectStatus = (payload) => {
    return {
        type: CLIENT_OBJECTS_UPDATE_STATUS_REQUEST,
        payload,
    };
};

export const addClientObjectDeposit = (payload) => {
    return {
        type: CLIENT_OBJECTS_ADD_DEPOSIT_REQUEST,
        payload,
    };
};

export const returnClientObjectDeposit = (payload) => {
    return {
        type: CLIENT_OBJECTS_RETURN_DEPOSIT_REQUEST,
        payload,
    };
};

export const closeClientObject = (payload) => {
    return {
        type: CLIENT_OBJECTS_CLOSE_REQUEST,
        payload,
    };
};

export function importObjectDeposit(payload) {
    return {
        type: IMPORT_OBJECT_DEPOSIT_REQUEST,
        payload,
    };
}

export function getObjectsDepositHistoryPage(payload){
    return {
        type: GET_PAGE_OBJECTS_DEPOSIT_HISTORY_REQUEST,
        payload,
    };
}

export function changeObjectOrdersLimit(payload){
    return {
        type: CLIENT_OBJECTS_CHANGE_ORDERS_LIMIT_REQUEST,
        payload,
    };
}

export function changeArchiveObject(payload) {
    return {
        type: OBJECTS_CHANGE_ARCHIVE_REQUEST,
        payload,
    };
}

export function exportClientObjects(payload) {
    return {
        type: CLIENT_OBJECTS_EXPORT_REQUEST,
        payload,
    };
}

export function multipleChangeArchiveObject(payload) {
    return {
        type: OBJECTS_MULTIPLE_CHANGE_ARCHIVE_REQUEST,
        payload,
    };
}

export const updateStoreClientObject = (payload) => {
    return {
        type: CLIENT_OBJECTS_UPDATE_STORE,
        payload,
    };
};

export const clearStoreClientObject = (payload) => {
    return {
        type: CLIENT_OBJECTS_CLEAR_STORE,
        payload,
    };
};