import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";

export const ordersRegistrySelector = state => state.bff.ordersRegistry;
export const ordersRegistryListSelector  = createSelector(ordersRegistrySelector, ({list}) => list);
export const ordersRegistryTotalCountSelector  = createSelector(ordersRegistrySelector, ({totalCount}) => totalCount);
export const ordersRegistryTotalPagesSelector  = createSelector(ordersRegistrySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const ordersRegistryProgressSelector  = createSelector(ordersRegistrySelector, ({progress}) => progress);
export const ordersRegistryActionSelector  = createSelector(ordersRegistrySelector, ({progressAction}) => progressAction);
export const ordersRegistryCreatedOrderInfoSelector  = createSelector(ordersRegistrySelector, ({createdOrderInfo}) => createdOrderInfo);
export const orderHistoryTotalPagesSelector = createSelector(
    ordersRegistrySelector,
    ({historyTotalCount, historyPageData: {pageSize = 0}}) => getTotalPages(historyTotalCount, pageSize),
);
export const contractorOrderHistoryTotalPagesSelector = createSelector(
    ordersRegistrySelector,
    ({contractorHistoryTotalCount, contractorHistoryPageData: {pageSize = 0}}) => getTotalPages(contractorHistoryTotalCount, pageSize),
);
export const orderHistoryProgressSelector = createSelector(ordersRegistrySelector, ({historyProgress}) => historyProgress);
export const contractorOrderHistoryProgressSelector = createSelector(ordersRegistrySelector, ({contractorHistoryProgress}) => contractorHistoryProgress);
export const orderHistoryTotalCountSelector = createSelector(ordersRegistrySelector, ({historyTotalCount}) => historyTotalCount);
export const contractorOrderHistoryTotalCountSelector = createSelector(ordersRegistrySelector, ({contractorHistoryTotalCount}) => contractorHistoryTotalCount);
export const getOrderHistorySelector = createSelector(ordersRegistrySelector, ({historyList}) => historyList);
export const getContractorOrderHistorySelector = createSelector(ordersRegistrySelector, ({contractorHistoryList}) => contractorHistoryList);
export const ordersNotByTemplateCreationAvailabilitySelector = createSelector(ordersRegistrySelector, ({ordersNotByTemplateCreationAvailability}) => ordersNotByTemplateCreationAvailability);
export const progressOrdersMassActionSelector = createSelector(ordersRegistrySelector, ({progressOrdersMassAction}) => progressOrdersMassAction);
export const ordersRegistryCardSelector = createSelector(ordersRegistrySelector, ({card}) => card);
export const ordersRegistryCardProgressSelector = createSelector(ordersRegistrySelector, ({progressCard}) => progressCard);
export const getOrderWorkReportPaidSumListSelector = createSelector(ordersRegistrySelector, ({paidSumList}) => paidSumList || []);
export const getDataPaidSumSelector = createSelector(ordersRegistrySelector, ({dataPaidSum}) => dataPaidSum);
export const updateOrderContractorsFoundProgressSelector = createSelector(ordersRegistrySelector, ({updateContractorsFoundProgress}) => updateContractorsFoundProgress);
export const orderCreationDataSelector = createSelector(ordersRegistrySelector, ({orderCreationData}) => orderCreationData);
export const ordersOptionsForCreatingDisputeSelector = createSelector(ordersRegistrySelector, ({list}) => list.map((item) => {
    const {
        orderId: value,
        name,
        orderManagerId,
        registryId,
        registrySeqNum,
        registryContractor,
        registry,
    } = item;

    return {
        key: value,
        value,
        text: registryId
            ? `Реестр № ${registry?.ordinalNumber} ${name}, строка ${registrySeqNum}, ${registryContractor?.fio || ""}`
            : name,
        orderManagerId,
        registryId,
        registryContractorId: registryContractor?.contractorId,
    };
}));
export const ordersCheckMultipleListSelector = createSelector(ordersRegistrySelector, ({checkMultipleList}) => checkMultipleList);
export const ordersWarningListSelector = createSelector(ordersRegistrySelector, ({warningOrderList}) => {
    return warningOrderList.filter(item => Boolean(item.warningBeforePublish));
});
