import React, {memo, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {FieldArray, withFormik} from "formik";
import {isEmpty} from "lodash";
import * as yup from "yup";

import DropzoneV2 from "../../../components/ActualComponents/DropzoneV2";
import HelpTooltip from "../../../components/ActualComponents/HelpTooltip";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDatePicker from "../../../components/ActualComponents/NmDatePicker";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../components/ActualComponents/NmModal";
import NmRadioV2 from "../../../components/ActualComponents/NmRadioV2";
import Text from "../../../components/ActualComponents/Text";
import AmountInput from "../../../components/AmountInput";
import ApplyButtons from "../../../components/ApplyButtons";
import FileViewerPdf from "../../../components/FileVIewerPdf";
import NmButton from "../../../components/NmButton";
import NmTitle from "../../../components/NmTitle";
import Steps from "../../../components/Steps";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import EdoDocumentDuplicateList from "./components/edo-document-duplicate-list";
import validationSchemaEdo from "./validations-rule";

import bem from "../../../utils/bem";
import {formatDateWithoutTime} from "../../../utils/dateFormat";
import {getDateObject} from "../../../utils/dateFormat";
import {getNumberFromFormattedAmount} from "../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";

import {STAFF_STATUS} from "../../../constants/edmStaff";

import {
    checkEdoDuplicateDocument, getEdoDocumentTypes, getEdoPreFilledTemplate, getPageEdoStaff, replaceEdoDocument,
    updateEdoDocumentsStore, updateTemplate, uploadEdoDocument, uploadTemplate, validateEdoCompletedDocument,
} from "../../../ducks/bff/clients/edo/documents/actionCreators";
import {EDO_STAFF_FIELD_LIST} from "../../../ducks/bff/clients/edo/documents/reducer";
import {
    edoDocumentTypesSelector,
    edoDocumentTypesUploadDocumentsSelector,
    edoPreFilledTemplate,
    edoReceiversOptionsSelector,
    edoSendersOptionsSelector,
} from "../../../ducks/bff/clients/edo/documents/selectors";
import {clearDocument, downloadDocument, getDocument, getDocumentSelector} from "../../../ducks/documents";

import PropTypes from "prop-types";

import "./style.sass";

import {
    EDO_DOCUMENT_FIELD,
    personalDataOptions,
    TEMPLATE_DOCUMENT,
    TYPE_LOADING_EDO_DOCUMENT,
    UPLOAD_EDO_STEP,
} from "../../../constants/document-management/document-list";

const PARAMS = {
    additionalContractorsDetail: "",
    type: "",
    documentNumber: "",
    documentDate: new Date(),
    documentPlace: "",
    documentSubject: "",
    startWorkDate: "",
    endWorkDate: "",
    targets: "",
    personalData: [],
    platformName: "",
    paymentCurrency: "",
    email: "",
    contractNumber: "",
    contractDate: new Date(),
    periodFrom: "",
    periodTo: "",
    jobList: [{
        count: "",
        price: "",
        workName: "",
        linkToResult: "",
    }],
};

const defaultValueDescriptionByDocumentType = {
    [TEMPLATE_DOCUMENT.REGISTRATION_LOG_PRIMARY_INSTRUCTION]: "Видеоролик инструктажа: https://clck.ru/Yvc2c Текст инструктажа: https://clck.ru/YvbzT",
    [TEMPLATE_DOCUMENT.REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION]: "Видеоролик инструктажа: https://clck.ru/Yvc2c Текст инструктажа: https://clck.ru/YvbzT",
};

const Formik = memo((props) => {
    const {
        touched,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        values,
        handleReset,
        setFieldTouched,
        //разделение на внутренние пропсы Formik и внешние
        onClose,
        className,
        clientId,
        clearDocument,
    } = props;

    const [block, element] = bem("document-management-loading-document", className);

    const steps = [
        {
            name: "Заполнение реквизитов",
        },
        {
            name: "Отправка на подпись",
        },
    ];
    const DATA_APPLY_BUTTONS = {
        STEP_ONE: {
            submitBtnContent: "Далее",
            cancelBtnContent: "Отмена",
            formId: "EDO",
            onClose: () => {
                onClose();
                handleReset();
            },
        },
        STEP_TWO: {
            submitBtnContent: "Отправить на подпись",
            cancelBtnContent: "Назад",
            submit: () => {
                dispatch(checkEdoDuplicateDocument({
                    clientId,
                    documentName: values.documentName,
                    documentType: values.type,
                    receiverStaffs: values.receiverIds,
                    senderStaff: values.senderId,
                    handleSuccess: (result) => {
                        if (isEmpty(result)) {
                            setOpenConfirmWindow(true);
                            setIsHiddenModalWindow(true);
                            return;
                        }
                        setDataDuplicate(result);
                        setFieldValue("dataDuplicate", result);
                        setIsShowDuplicateModal(true);
                    },
                },
                ));
            },
            onClose: () => {
                setFieldValue("step", UPLOAD_EDO_STEP.FIRST);
                setDataForApplyButtons(DATA_APPLY_BUTTONS.STEP_ONE);
            },
        },
    };

    const isFirstStep = values.step.join() === UPLOAD_EDO_STEP.FIRST.join();
    const isSecondStep = values.step.join() === UPLOAD_EDO_STEP.SECOND.join();

    const [isHiddenModalWindow, setIsHiddenModalWindow] = useState(false);

    const [isShowTypeLoading, setIsShowTypeLoading] = useState(false);
    const [dataForApplyButtons, setDataForApplyButtons] = useState(DATA_APPLY_BUTTONS.STEP_ONE);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [dataDuplicate, setDataDuplicate] = useState([]);
    const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);

    const edoDocumentTypesOptions = useSelector(edoDocumentTypesUploadDocumentsSelector);
    const edoDocumentTypesDict = useSelector(edoDocumentTypesSelector);
    const edoReceiversOptions = useSelector(edoReceiversOptionsSelector);
    const edoSendersOptions = useSelector(edoSendersOptionsSelector);
    const edoPersonalDataOptions = personalDataOptions();

    const dispatch = useDispatch();
    const templateDocument = useSelector(edoPreFilledTemplate);
    const document = useSelector(getDocumentSelector);

    useEffect(() => {
        if (edoReceiversOptions?.length === 1 && values.documentId) {
            handleChangeDropdown(null, {
                name: "receiverId",
                value: edoReceiversOptions[0].value,
            });
        }

        if (edoReceiversOptions?.length === 1 && !values.documentId) {
            handleChangeDropdown(null, {
                name: "receiverIds",
                value: [edoReceiversOptions[0].value],
            });
        }

        if (edoSendersOptions?.length === 1) {
            handleChangeDropdown(null, {
                name: "senderId",
                value: edoSendersOptions[0].value,
            });
        }

    }, [edoReceiversOptions, edoSendersOptions]);

    useEffect(() => {
        let isIgnoredResponse = false;

        if (!isIgnoredResponse) {
            fetchSenders();

            return;
        }

        return () => {
            isIgnoredResponse = true;
        };
    }, []);

    useEffect(() => {
        if (Boolean(values.documentId) && (!values.params.type)) {
            dispatch(getDocument({data: values.documentId, fileName: "Прикрепленный документ.pdf"}));
        }
    }, [values.documentId]);

    useEffect(() => {
        if (values.params.type && !values.documentId) {
            setIsShowTypeLoading(true);
            setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.TEMPLATE);

            return;
        }
        //определить как именно был загружен документ(шаблон или готвый)
        if (!values.params.type && values.templateType) {
            setIsShowTypeLoading(true);
            setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);

            return;
        }
        //определить как именно был загружен документ(шаблон или готвый)
        if (values.params.type && values.templateType) {
            setIsShowTypeLoading(true);
            setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.TEMPLATE);

            return;
        }

        setIsShowTypeLoading(false);
        setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);

    }, [values.params.type, values.templateType]);

    useEffect(() => {
        if (templateDocument) {
            setFieldValue("step", UPLOAD_EDO_STEP.SECOND);
            setFieldValue("file", templateDocument);
            setDataForApplyButtons(DATA_APPLY_BUTTONS.STEP_TWO);
        }
    }, [templateDocument]);

    useEffect(() => {
        if (!isEmpty(values.receiverIds)) {
            setFieldValue(EDO_DOCUMENT_FIELD.RECEIVERS_IDS, []);
            setFieldTouched(EDO_DOCUMENT_FIELD.RECEIVERS_IDS, true);
        }

        if (values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE) {
            return fetchReceivers({type: "CONTRACTOR"});
        }

        if (values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT) {
            return fetchReceivers();
        }
    }, [values.typeLoading]);

    useEffect(() => {
        if (isFirstStep) {
            setDataForApplyButtons(DATA_APPLY_BUTTONS.STEP_ONE);
        }

        if (isSecondStep) {
            setDataForApplyButtons(DATA_APPLY_BUTTONS.STEP_TWO);
        }
    }, [values.step]);

    useEffect(() => {
        if (document) {
            setFieldValue(EDO_DOCUMENT_FIELD.FILE, document);
        }

        return () => {
            clearDocument();
            dispatch(updateEdoDocumentsStore({receivers: []}));
        };
    }, [document]);

    useEffect(() => {
        if (!values.type) {
            return;
        }

        const selectedTemplateType = edoDocumentTypesDict.find(item => item.id === values.type)?.templateType;

        if (
            [
                TEMPLATE_DOCUMENT.REGISTRATION_LOG_PRIMARY_INSTRUCTION,
                TEMPLATE_DOCUMENT.REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION,
            ].includes(selectedTemplateType)
        ) {
            setFieldValue(EDO_DOCUMENT_FIELD.DESCRIPTION, defaultValueDescriptionByDocumentType[selectedTemplateType]);
            setFieldTouched(EDO_DOCUMENT_FIELD.DESCRIPTION, true);
            return;
        }
        setFieldValue(EDO_DOCUMENT_FIELD.DESCRIPTION, values.information);
        setFieldTouched(EDO_DOCUMENT_FIELD.DESCRIPTION, false);
    }, [values.type]);

    const handleTypeLoading = (e, {name}) => {
        if (values.templateType && !values.params.type) {
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, values.templateType);
        }

        setFieldValue("typeLoading", name);
    };

    const handleChangeInput = (e) => {
        handleChange(e);
        setFieldTouched(e.target.name, true);
    };

    const handleChangeDropdown = (e, {name, value}) => {
        setFieldValue(name, value);
    };

    const handleChangeTypeDocument = (e, data) => {
        const {name, value, templateType, text} = data;

        if (templateType) {
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, templateType);
        } else {
            setFieldValue("typeLoading", TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);
            setFieldValue(EDO_DOCUMENT_FIELD.PARAMS.TYPE, "");
        }

        setFieldValue(EDO_DOCUMENT_FIELD.DOCUMENT_NAME, text);
        setFieldTouched(name, true);
        setFieldValue(name, value);
    };

    const handleDownloadEmptyTemplate = () => {
        if (!values.params.type) {
            return;
        }

        dispatch(downloadDocument({
            downloadLink: `${window.location.origin}/api/edocuments/signing/template/empty?type=${values.type}`,
            extension: "pdf",
            isDownload: false,
            fileName: "_signed.pdf",
        }));
    };

    const handleDownloadCompletedFile = () => {
        dispatch(downloadDocument({
            downloadLink: `${window.location.origin}/api/filestore/edocuments/source/${values.documentId}`,
            extension: "pdf",
            fileName: values.file.name || `${values.documentId}_signed.pdf`,
        }));
    };

    const fetchEdoDocumentStates = () => {
        const {
            valueTypeFilter,
        } = values;

        const payload = {
            clientId,
            pageNum: 1,
            pageSize: 100,
            valueFilter: handleFormString(valueTypeFilter),
        };

        dispatch(getEdoDocumentTypes(payload));
    };

    const fetchSenders = (fio) => {
        const payload = getStaffPayload({
            field: EDO_STAFF_FIELD_LIST.SENDERS,
            fio,
            privileges: ["SEND_DOCUMENT"],
            callbackSuccess: () => {
                // setIsLoadingCard(false)
            },
        });

        dispatch(getPageEdoStaff(payload));

    };

    const fetchReceivers = (data = {}) => {
        const {
            fio,
            type,
        } = data;

        const payload = getStaffPayload({
            field: EDO_STAFF_FIELD_LIST.RECEIVERS,
            fio,
            type,
            privileges: ["RECEIVE_DOCUMENT"],
            callbackSuccess: () => {
                // setIsLoadingCard(false)
            },
        });

        dispatch(getPageEdoStaff(payload));

    };

    const getStaffPayload = (data) => {
        const {
            field,
            fio = "",
            privileges = [],
            type,
            callbackSuccess = () => {
            },
        } = data;

        return {
            clientId,
            pageNum: 1,
            pageSize: 1000,
            fio: handleFormString(fio),
            field,
            privileges,
            status: STAFF_STATUS.WORKING,
            getSuccess: callbackSuccess,
            type,
        };
    };

    const handleOnSearchDocumentType = (valueFilter) => {
        setFieldValue("valueTypeFilter", valueFilter);
    };

    useEffect(() => {
        fetchEdoDocumentStates();
    }, [values.valueTypeFilter]);

    const handleOnSearchReceiver = (fio) => {
        if (values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT) {
            return fetchReceivers({fio});
        }

        if (values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE) {
            return fetchReceivers({
                type: "CONTRACTOR",
                fio,
            });
        }

    };

    const handleOnSearchSender = (fio) => {
        fetchSenders(fio);
    };

    const onDropFile = (files) => {
        const [file] = files;

        setFieldValue(EDO_DOCUMENT_FIELD.FILE, file);
        setFieldTouched(EDO_DOCUMENT_FIELD.FILE, true);
    };

    const getError = (field) => {
        return touched[field]
            ? errors && errors[field]
            : false;
    };

    //поля формы
    const getDropZone = () => {
        return (
            values.typeLoading !== TYPE_LOADING_EDO_DOCUMENT.TEMPLATE &&
            <>
                <DropzoneV2
                    isDownload
                    multiple={false}
                    files={values.file ? [values.file] : []}
                    onChange={onDropFile}
                    format="*.PDF/A"
                    accept=".pdf"
                    maxSize={15}
                    error={touched[EDO_DOCUMENT_FIELD.FILE] ?
                        errors && errors[EDO_DOCUMENT_FIELD.FILE] :
                        false
                    }
                />
                <div className={element("link-pdf-helper")}>
                    <Link
                        to="/files/PDF-A_guide.pdf"
                        target="_blank"
                    >
                        Как сохранить документы в формате PDF/A?
                    </Link>
                </div>
            </>
        );
    };
    const getDocumentNumber = () => {
        return (
            <NmInputV2
                label="Номер документа"
                required
                size="xl"
                maxLength={50}
                placeholder="Укажите номер документа"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_NUMBER}
                value={values.params.documentNumber}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.documentNumber ?
                    errors && errors.params && errors.params.documentNumber :
                    false
                }
            />
        );
    };
    const getPersonalData = () => {
        return (
            <NmDropdownV2
                size="xl"
                fullWidth
                placeholder="Выберите перечнь ПД"
                label="Перечень ПД для обработки"
                required
                search
                multiple
                name={EDO_DOCUMENT_FIELD.PARAMS.PERSONAL_DATA}
                onChange={handleChangeDropdown}
                onBlur={() => {
                    setFieldTouched(EDO_DOCUMENT_FIELD.PARAMS.PERSONAL_DATA, true);
                }}
                value={values.params.personalData}
                options={edoPersonalDataOptions}
                error={touched && touched.params && touched.params.personalData ?
                    errors && errors.params && errors.params.personalData :
                    false
                }
            />
        );
    };
    const getDateDocument = () => {
        return (
            <NmDatePicker
                required
                placeholderText="ДД.ММ.ГГ"
                dateFormat="dd.MM.yy"
                name={EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME}
                selected={values[EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME]}
                onChange={(e, {name, value}) => setFieldValue(name, value)}
                error={touched && touched[EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME] ?
                    errors && errors[EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME] :
                    false
                }
                isClearable
                label="Дата документа"
            />
        );
    };
    const getNameDocument = () => {
        return (
            <NmInputV2
                label="Наименование документа"
                required
                size="xl"
                maxLength={500}
                placeholder="Например: Трудовой договор с Иванченко"
                name={EDO_DOCUMENT_FIELD.DOCUMENT_NAME}
                value={values[EDO_DOCUMENT_FIELD.DOCUMENT_NAME]}
                onChange={handleChangeInput}
                error={touched[EDO_DOCUMENT_FIELD.DOCUMENT_NAME] ?
                    errors && errors[EDO_DOCUMENT_FIELD.DOCUMENT_NAME] :
                    false
                }
            />
        );
    };
    const getAdditionalContractorsDetail = () => {
        return (
            <NmInputV2
                tooltip={
                    <HelpTooltip
                        hover
                        info
                        position="top"
                        text="Вы можете указать дополнительные реквизиты по исполнителю, например, платежные данные"
                    />
                }
                label="Доп. реквизиты исполнителя"
                size="xl"
                maxLength={1000}
                placeholder="Введите доп. реквизиты исполнителя"
                name={EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL}
                value={values.params.additionalContractorsDetail}
                onChange={handleChangeInput}
                error={touched[EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL] ?
                    errors && errors[EDO_DOCUMENT_FIELD.PARAMS.ADDITIONAL_CONTRACTORS_DETAIL] :
                    false
                }
            />
        );
    };
    const getDescription = () => {
        return (
            <NmInputV2
                label="Дополнительные сведения"
                size="xl"
                maxLength={255}
                placeholder="Введите дополнительные сведения"
                name={EDO_DOCUMENT_FIELD.DESCRIPTION}
                value={values[EDO_DOCUMENT_FIELD.DESCRIPTION]}
                onChange={handleChangeInput}
                error={touched[EDO_DOCUMENT_FIELD.DESCRIPTION] ?
                    errors && errors[EDO_DOCUMENT_FIELD.DESCRIPTION] :
                    false
                }
            />
        );
    };
    const getDocumentSubject = () => {
        return (
            <NmInputV2
                label="Предмет договора"
                required
                size="xl"
                maxLength={255}
                placeholder="Введите предмет договора"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_SUBJECT}
                value={values.params.documentSubject}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.documentSubject ?
                    errors && errors.params && errors.params.documentSubject :
                    false
                }
            />
        );
    };
    const getPaymentCurrency = () => {
        return (
            <NmInputV2
                label="Валюта оплаты"
                required
                size="xl"
                maxLength={255}
                placeholder="Укажите валюту оплаты"
                name={EDO_DOCUMENT_FIELD.PARAMS.PAYMENT_CURRENCY}
                value={values.params.paymentCurrency}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.paymentCurrency ?
                    errors && errors.params && errors.params.paymentCurrency :
                    false
                }
            />
        );
    };
    const getPlatformName = () => {
        return (
            <NmInputV2
                label="Наименование платформы"
                required
                size="xl"
                maxLength={1000}
                placeholder="Укажите наименование платформы"
                name={EDO_DOCUMENT_FIELD.PARAMS.PLATFORM_NAME}
                value={values.params.platformName}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.platformName ?
                    errors && errors.params && errors.params.platformName :
                    false
                }
            />
        );
    };
    const getStartOrEndWorkDate = () => {
        return (
            <NmDateRangePickerV2
                required
                label="Период выполнения работ"
                isClearable
                className={element("date-picker")}
                startFieldName={EDO_DOCUMENT_FIELD.PARAMS.START_WORK_DATE}
                endFieldName={EDO_DOCUMENT_FIELD.PARAMS.END_WORK_DATE}
                value={{
                    "params.startWorkDate": getDateObject(values.params.startWorkDate),
                    "params.endWorkDate": getDateObject(values.params.endWorkDate),
                }}
                error={touched && touched.params && (touched.params.startWorkDate || touched.params.endWorkDate) ?
                    errors && errors.params && (errors.params.startWorkDate || errors.params.endWorkDate) :
                    false
                }
                onChange={(event, {name, value}) => setFieldValue(name, value)}
                size="xl"
            />
        );
    };
    const getFromOrToPeriod = () => {
        return (
            <NmDateRangePickerV2
                required
                label="Период оказываемых услуг"
                isClearable
                className={element("date-picker")}
                startFieldName={EDO_DOCUMENT_FIELD.PARAMS.PERIOD_FROM}
                endFieldName={EDO_DOCUMENT_FIELD.PARAMS.PERIOD_TO}
                value={{
                    "params.periodFrom": getDateObject(values.params.periodFrom),
                    "params.periodTo": getDateObject(values.params.periodTo),
                }}
                error={touched && touched.params && (touched.params.periodFrom || touched.params.periodTo) ?
                    errors && errors.params && (errors.params.periodFrom || errors.params.periodTo) :
                    false
                }
                onChange={(event, {name, value}) => setFieldValue(name, value)}
                size="xl"
            />
        );
    };
    const getDocumentPlace = () => {
        return (
            <NmInputV2
                label="Место составления документа"
                required
                size="xl"
                maxLength={100}
                placeholder="Укажите место составления документа"
                name={EDO_DOCUMENT_FIELD.PARAMS.DOCUMENT_PLACE}
                value={values.params.documentPlace}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.documentPlace ?
                    errors && errors.params && errors.params.documentPlace :
                    false
                }
            />
        );
    };
    const getEmail = () => {
        return (
            <NmInputV2
                label="Эл. почта для отправки документов"
                required
                size="xl"
                maxLength={255}
                placeholder="Укажите e-mail"
                name={EDO_DOCUMENT_FIELD.PARAMS.EMAIL}
                value={values.params.email}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.email ?
                    errors && errors.params && errors.params.email :
                    false
                }
            />
        );
    };
    const getTargets = () => {
        return (
            <NmInputV2
                label="Обработка персональных данных в целях"
                size="xl"
                maxLength={255}
                required
                placeholder="Введите цели обработки персональных данных"
                name={EDO_DOCUMENT_FIELD.PARAMS.TARGETS}
                value={values.params.targets}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.targets ?
                    errors && errors.params && errors.params.targets :
                    false
                }
            />
        );
    };
    const getContractNumber = () => {
        return (
            <NmInputV2
                label="Номер договора"
                required
                size="xl"
                maxLength={50}
                placeholder="Укажите номер договора"
                name={EDO_DOCUMENT_FIELD.PARAMS.CONTRACT_NUMBER}
                value={values.params.contractNumber}
                onChange={handleChangeInput}
                error={touched && touched.params && touched.params.contractNumber ?
                    errors && errors.params && errors.params.contractNumber :
                    false
                }
            />
        );
    };
    const getContractDate = () => {
        return (
            <NmDatePicker
                required
                placeholderText="ДД.ММ.ГГ"
                dateFormat="dd.MM.yy"
                name={EDO_DOCUMENT_FIELD.PARAMS.CONTRACT_DATE}
                selected={values.params.contractDate}
                onChange={(e, {name, value}) => setFieldValue(name, value)}
                error={touched && touched?.params?.contractDate ?
                    errors && errors.params?.contractDate :
                    false
                }
                isClearable
                label="Дата договора"
            />
        );
    };
    const getJobListAttachment = () => {
        return (
            <FieldArray
                name="params.jobList"
                render={jobListHelper => {
                    return (
                        <>
                            <Text.Sub medium>
                                Перечень и стоимость оказанных услуг (работ)
                            </Text.Sub>
                            {values?.params?.jobList.map((job, index) => {
                                return (
                                    <div
                                        className="row gx-4"
                                        key={index}
                                    >
                                        <div className="col-16 col-md-8">
                                            <NmInputV2
                                                label="Наименование услуг"
                                                required
                                                size="xl"
                                                maxLength={255}
                                                placeholder="Наименований услуг"
                                                className="mb-3 mb-md-4"
                                                name={job.workName}
                                                value={job.workName}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].workName`, true);
                                                    setFieldValue(`params.jobList[${index}].workName`, e.target.value);
                                                }}
                                                error={(touched?.params?.jobList && touched?.params?.jobList[index]?.workName) ?
                                                    (errors?.params?.jobList && errors?.params?.jobList[index]?.workName) :
                                                    false
                                                }
                                            />
                                        </div>
                                        <div className="col-16 col-md-8">
                                            <AmountInput
                                                label="Количество"
                                                required
                                                size="xl"
                                                placeholder="Количество"
                                                className="mb-3 mb-md-4"
                                                name={job.count}
                                                value={job.count}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].count`, true);
                                                    setFieldValue(`params.jobList[${index}].count`, e.target.value);
                                                }}
                                                error={(touched?.params?.jobList && touched?.params?.jobList[index]?.count) ?
                                                    (errors?.params?.jobList && errors?.params?.jobList[index]?.count) :
                                                    false
                                                }
                                                newInput
                                            />
                                        </div>
                                        <div className="col-16 col-md-8">
                                            <AmountInput
                                                label="Стоимость Р"
                                                required
                                                size="xl"
                                                placeholder="Стоимость"
                                                name={job.price}
                                                value={job.price}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].price`, true);
                                                    setFieldValue(`params.jobList[${index}].price`, e.target.value);
                                                }}
                                                error={touched?.params?.jobList && touched?.params?.jobList[index]?.price ?
                                                    errors?.params?.jobList && errors?.params?.jobList[index]?.price :
                                                    false
                                                }
                                                newInput
                                            />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {
                                                (index >= 1) &&
                                                <NmButton
                                                    size="lg"
                                                    color="grey"
                                                    className="mt-3 mt-md-4"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        jobListHelper.remove(index);
                                                    }}
                                                >
                                                    Удалить
                                                </NmButton>
                                            }
                                            {
                                                (values.params?.jobList?.length < 20) && index === values.params?.jobList?.length - 1 &&
                                                <NmButton
                                                    size="lg"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        jobListHelper.push({
                                                            workName: "",
                                                            price: "",
                                                            count: "",
                                                        });
                                                    }}
                                                    className="ms-4 mt-3 mt-md-4"
                                                    color="light-green"
                                                    icon={<AddIcon />}
                                                >
                                                    Добавить еще
                                                </NmButton>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    );
                }}
            />
        );
    };
    const getJobListAct = () => {
        return (
            <FieldArray
                name="params.jobList"
                render={jobListHelper => {
                    return (
                        <>
                            <Text.Sub medium>
                                Перечень и стоимость оказанных услуг (работ)
                            </Text.Sub>
                            {values?.params?.jobList.map((job, index) => {
                                return (
                                    <div
                                        className="row gx-4"
                                        key={index}
                                    >
                                        <div className="col-16">
                                            <NmInputV2
                                                label="Наименование услуг"
                                                required
                                                size="xl"
                                                maxLength={255}
                                                placeholder="Наименований услуг"
                                                className="mb-3 mb-md-4"
                                                name={job.workName}
                                                value={job.workName}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].workName`, true);
                                                    setFieldValue(`params.jobList[${index}].workName`, e.target.value);
                                                }}
                                                error={(touched?.params?.jobList && touched?.params?.jobList[index]?.workName) ?
                                                    (errors?.params?.jobList && errors?.params?.jobList[index]?.workName) :
                                                    false
                                                }
                                            />
                                        </div>
                                        <div className="col-16 col-md-8">
                                            <AmountInput
                                                label="Количество"
                                                required
                                                size="xl"
                                                placeholder="Количество"
                                                className="mb-3 mb-md-4"
                                                name={job.count}
                                                value={job.count}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].count`, true);
                                                    setFieldValue(`params.jobList[${index}].count`, e.target.value);
                                                }}
                                                error={(touched?.params?.jobList && touched?.params?.jobList[index]?.count) ?
                                                    (errors?.params?.jobList && errors?.params?.jobList[index]?.count) :
                                                    false
                                                }
                                                newInput
                                            />
                                        </div>
                                        <div className="col-16 col-md-8">
                                            <AmountInput
                                                label="Стоимость Р"
                                                required
                                                size="xl"
                                                placeholder="Стоимость"
                                                className="mb-3 mb-md-4"
                                                name={job.price}
                                                value={job.price}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].price`, true);
                                                    setFieldValue(`params.jobList[${index}].price`, e.target.value);
                                                }}
                                                error={touched?.params?.jobList && touched?.params?.jobList[index]?.price ?
                                                    errors?.params?.jobList && errors?.params?.jobList[index]?.price :
                                                    false
                                                }
                                                newInput
                                            />
                                        </div>
                                        <div className="col-16">
                                            <NmInputV2
                                                label="Ссылка на результат"
                                                required
                                                size="xl"
                                                maxLength={255}
                                                placeholder="Результат"
                                                name={job.linkToResult}
                                                value={job.linkToResult}
                                                onChange={e => {
                                                    setFieldTouched(`params.jobList[${index}].linkToResult`, true);
                                                    setFieldValue(`params.jobList[${index}].linkToResult`, e.target.value);
                                                }}
                                                error={touched?.params?.jobList && touched?.params?.jobList[index]?.linkToResult ?
                                                    errors?.params?.jobList && errors?.params?.jobList[index]?.linkToResult :
                                                    false
                                                }
                                            />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {
                                                (index >= 1) &&
                                                <NmButton
                                                    size="lg"
                                                    color="grey"
                                                    className="mt-3 mt-md-4"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        jobListHelper.remove(index);
                                                    }}
                                                >
                                                    Удалить
                                                </NmButton>
                                            }
                                            {
                                                (values.params?.jobList?.length < 20) && index === values.params?.jobList?.length - 1 &&
                                                <NmButton
                                                    size="lg"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        jobListHelper.push({
                                                            workName: "",
                                                            price: "",
                                                            count: "",
                                                            linkToResult: "",
                                                        });
                                                    }}
                                                    className="ms-4 mt-3 mt-md-4"
                                                    color="light-green"
                                                    icon={<AddIcon />}
                                                >
                                                    Добавить еще
                                                </NmButton>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    );
                }}
            />
        );
    };

    // наборы полей в зависимости от типа шаблона
    const defaultDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getDropZone()}
                {getDescription()}
            </>
        );
    };
    const tradeSecretDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getDescription()}
            </>
        );
    }; //данные для форм: доп. соглашениек рамочному договору об оказании невозмездных услуг №1,2 и обязательство о неразглошении коммерческой тайны
    const additionalAgreementNumber5 = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getPlatformName()}
                {getDescription()}
            </>
        );

    }; // Дополнительное соглашение к рамочному договору об оказании возмездных услуг (работ) №5
    const processingOfPersonalDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getPersonalData()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getTargets()}
                {getDescription()}
            </>
        );
    }; // данные для формы согласие на обработку персональных данных
    const civilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getDocumentSubject()}
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getStartOrEndWorkDate()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDocumentPlace()}
                    </div>
                </div>
                {getDescription()}
            </>
        );
    }; // Договор гражданско-правового характера (НДФЛ)
    const civilDeedWithIntellectualProperty = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getPaymentCurrency()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getPlatformName()}
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getEmail()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDocumentPlace()}
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getAdditionalContractorsDetail()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDescription()}
                    </div>
                </div>
            </>
        );
    }; // Договор гражданско-правового характера с условиями передачи интеллектуальных прав
    const attachmentToCivilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getContractNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getContractDate()}
                    </div>
                </div>
                {getNameDocument()}
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getFromOrToPeriod()}
                    </div>
                </div>
                {getJobListAttachment()}
                {getDescription()}
            </>
        );
    };

    const actToCivilDeedDataForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDocumentNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getDateDocument()}
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getContractNumber()}
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        {getContractDate()}
                    </div>
                </div>
                {getNameDocument()}
                {getJobListAct()}
                {getDescription()}
            </>
        );
    };

    //журнал регистрация вводного инструктожа
    const registrationLogIntroductoryInstructionForm = () => {
        return (
            <>
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        {getDateDocument()}
                    </div>
                </div>
                {getNameDocument()}
                {getDescription()}
            </>
        );
    };

    const getBodyFormByTypeDocument = () => {
        if (values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT) {
            return defaultDataForm();
        }

        switch (values.params.type) {
            case TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7:
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8:
                return tradeSecretDataForm();
            case TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA:
                return processingOfPersonalDataForm();
            case TEMPLATE_DOCUMENT.CIVIL_DEED:
                return civilDeedDataForm();
            case TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY:
                return civilDeedWithIntellectualProperty();
            case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5:
                return additionalAgreementNumber5();
            case TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED:
                return attachmentToCivilDeedDataForm();
            case TEMPLATE_DOCUMENT.ACT_CIVIL_DEED:
                return actToCivilDeedDataForm();
            case TEMPLATE_DOCUMENT.REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION:
            case TEMPLATE_DOCUMENT.REGISTRATION_LOG_PRIMARY_INSTRUCTION:
                return registrationLogIntroductoryInstructionForm();
            default:
                return defaultDataForm();
        }
    };

    const renderFirstStep = () => {
        return (
            <>
                <NmDropdownV2
                    size="xl"
                    placeholder="Выберите из списка"
                    fullWidth
                    required
                    label="Тип документа"
                    search
                    name={EDO_DOCUMENT_FIELD.TYPE}
                    onChange={handleChangeTypeDocument}
                    searchQuery={values.valueTypeFilter}
                    value={values.type}
                    options={edoDocumentTypesOptions}
                    error={touched[EDO_DOCUMENT_FIELD.TYPE] ? errors[EDO_DOCUMENT_FIELD.TYPE] && errors[EDO_DOCUMENT_FIELD.TYPE] : false}
                    onSearchChange={handleOnSearchDocumentType}
                />
                {
                    isShowTypeLoading &&
                    <div className="row">
                        <div className="col-16 col-md-8">
                            <NmRadioV2
                                label={"Использовать предложенный шаблон"}
                                name={TYPE_LOADING_EDO_DOCUMENT.TEMPLATE}
                                value={values.typeLoading}
                                onChange={handleTypeLoading}
                                checked={values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE}
                            />
                            {
                                values.params.type &&
                                <div
                                    className={element("link-pre-filled-template")}
                                    onClick={handleDownloadEmptyTemplate}
                                >
                                    Посмотреть предложенный шаблон
                                </div>
                            }
                        </div>
                        <div className="col-16 col-md-8">
                            <NmRadioV2
                                label={"Загрузить заполненный документ"}
                                name={TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT}
                                value={values.typeLoading}
                                onChange={handleTypeLoading}
                                checked={values.typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT}
                            />
                        </div>
                    </div>
                }
                {getBodyFormByTypeDocument()}
                {/*<NmInputV2*/}
                {/*    label="Дополнительные сведения"*/}
                {/*    size="xl"*/}
                {/*    maxLength={255}*/}
                {/*    placeholder="Введите дополнительные сведения"*/}
                {/*    className={element("control")}*/}
                {/*    name={EDO_DOCUMENT_FIELD.DESCRIPTION}*/}
                {/*    value={values[EDO_DOCUMENT_FIELD.DESCRIPTION]}*/}
                {/*    onChange={handleChangeInput}*/}
                {/*    error={touched[EDO_DOCUMENT_FIELD.DESCRIPTION] ?*/}
                {/*        errors && errors[EDO_DOCUMENT_FIELD.DESCRIPTION] :*/}
                {/*        false*/}
                {/*    }*/}
                {/*/>*/}
                <div className="row gx-4">
                    <div className="col-16 col-md-8">
                        <NmDropdownV2
                            size="xl"
                            fullWidth
                            placeholder="Выберите отправителя"
                            required
                            label="Отправитель"
                            search
                            name={EDO_DOCUMENT_FIELD.SENDER_ID}
                            onChange={handleChangeDropdown}
                            onBlur={() => {
                                setFieldTouched(EDO_DOCUMENT_FIELD.SENDER_ID, true);
                            }}
                            value={values.senderId}
                            options={edoSendersOptions}
                            onSearchChange={handleOnSearchSender}
                            error={touched[EDO_DOCUMENT_FIELD.SENDER_ID]
                                ? errors && errors[EDO_DOCUMENT_FIELD.SENDER_ID]
                                : false
                            }
                        />
                    </div>
                    <div className="col-16 col-md-8 mt-3 mt-md-0">
                        <NmDropdownV2
                            size="xl"
                            fullWidth
                            placeholder="Выберите получателя"
                            label="Получатель"
                            required
                            search
                            multiple={!values.documentId}
                            name={values.documentId ? EDO_DOCUMENT_FIELD.RECEIVER_ID : EDO_DOCUMENT_FIELD.RECEIVERS_IDS}
                            onChange={handleChangeDropdown}
                            onBlur={() => {
                                setFieldTouched(values.documentId ? EDO_DOCUMENT_FIELD.RECEIVER_ID : EDO_DOCUMENT_FIELD.RECEIVERS_IDS, true);
                            }}
                            onSearchChange={handleOnSearchReceiver}
                            value={values.documentId ? values.receiverId : values.receiverIds}
                            options={edoReceiversOptions}
                            error={values.documentId ?
                                getError(EDO_DOCUMENT_FIELD.RECEIVER_ID) :
                                getError(EDO_DOCUMENT_FIELD.RECEIVERS_IDS)
                            }
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderSecondStep = () => {
        return (
            <>
                {isShowDuplicateModal && <EdoDocumentDuplicateList
                    edoDocumentTypesDict={edoDocumentTypesDict}
                    submit={(result) => {
                        setFieldValue("selectedDuplicateReceivers", result);
                        setIsShowDuplicateModal(false);
                        setDataDuplicate([]);
                        setOpenConfirmWindow(true);
                        setIsHiddenModalWindow(true);
                    }}
                    dataDuplicate={dataDuplicate}
                    close={() => {
                        setIsShowDuplicateModal(false);
                        setDataDuplicate([]);
                    }}
                />}
                <FileViewerPdf
                    className={element("pdf-viewer")}
                    file={values.file}
                />
            </>
        );
    };

    const renderConfirmWindow = () => {
        return openConfirmWindow &&
            <NmConfirmV2
                content="Вы действительно хотите отправить документ на подпись?"
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onCancel={() => {
                    setOpenConfirmWindow(false);
                    setIsHiddenModalWindow(false);
                }}
                onConfirm={() => {
                }}
                formId="EDO"
                disabled={values.loading}
                isNeedClosing={false}
            />;
    };

    return (
        <>
            <NmModal
                size="md"
                onClose={onClose}
                isHiddenModal={isHiddenModalWindow}
                header={
                    <NmTitle size="lg">
                        Загрузка документа
                    </NmTitle>
                }
                footer={
                    <ApplyButtons
                        loading={values.loading}
                        disabled={values.loading}
                        {...dataForApplyButtons}
                        mobile="column"
                        classNameMainButtons={element("apply-buttons")}
                    />
                }
            >
                <NmForm
                    id={"EDO"}
                    noValidate
                    addMargin
                    onSubmit={handleSubmit}
                    className={block()}
                >
                    <Steps
                        className="mb-2 mb-md-4"
                        items={steps}
                        activeItems={values.step}
                    />
                    {(isFirstStep) && renderFirstStep()}
                    {(isSecondStep) && renderSecondStep()}
                </NmForm>
            </NmModal>
            {renderConfirmWindow()}
        </>
    );
});

const DocumentManagementLoadingDocument2 = withFormik({
    mapPropsToValues: ({data}) => {
        const {
            docName,
            dateDocument,
            docTypeUuid,
            senderSignInfo,
            receiverSignInfo,
            params,
            information,
            docId,
            templateType,
            docType,
        } = data;

        const senderId = senderSignInfo ? senderSignInfo.clientStaffId : "";

        if (params) {
            params.contractDate = params.contractDate ? new Date(params.contractDate) : null;
            params.documnetDate = params.documnetDate ? new Date(params.documentDate) : null;
        }

        return {
            information: information || "",
            documentDateTime: dateDocument ? new Date(dateDocument) : new Date(),
            type: docTypeUuid || "",
            documentName: docName || "",
            senderId: senderId,
            description: information || "",
            receiverId: (receiverSignInfo && receiverSignInfo.clientStaffId) || undefined,
            file: undefined,
            params: {...PARAMS, ...params},
            typeLoading: undefined,
            step: UPLOAD_EDO_STEP.FIRST,
            loading: false,
            documentId: docId || undefined,
            receiverIds: docId ? undefined : [],
            templateType: templateType || undefined,
            valueTypeFilter: docType || "",
        };
    },

    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: yup.object().shape(
        validationSchemaEdo(),
    ),

    handleSubmit: (values, {props, setSubmitting, setFieldValue, resetForm}) => {
        const {typeLoading, step, loading, ...rest} = values;

        setFieldValue("loading", true);

        const mapDataTemplateDocument = (data) => {
            const {
                documentDateTime,
                params,
            } = data;

            const currentParams = getFieldByTemplate(params);

            const {
                contractDate,
                jobList,
            } = currentParams;

            const pushData = {
                typeValidate: data.documentId ? "replace/" : "",
                params: {
                    ...currentParams,
                    contractDate: contractDate && formatDateWithoutTime(contractDate, "yyyy-MM-dd"),
                    documentDate: documentDateTime && formatDateWithoutTime(documentDateTime, "yyyy-MM-dd"),
                    jobList: jobList && jobList.map((value, index) => {
                        value.price = getNumberFromFormattedAmount(value.price);
                        value.count = getNumberFromFormattedAmount(value.count);
                        value.rowNumber = Number(index + 1);

                        return value;
                    }),
                },
            };

            return {
                ...data,
                ...pushData,
            };

        };

        const isGetPreFilledTemplate = (step.join() === UPLOAD_EDO_STEP.FIRST.join() &&
            typeLoading === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE);

        const isUploadTemplate = (step.join() === UPLOAD_EDO_STEP.SECOND.join() &&
            typeLoading === TYPE_LOADING_EDO_DOCUMENT.TEMPLATE);

        const isUploadCompletedDocument = (step.join() === UPLOAD_EDO_STEP.SECOND.join() &&
            typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);

        const isPreFilledCompletedDocument = (step.join() === UPLOAD_EDO_STEP.FIRST.join() &&
            typeLoading === TYPE_LOADING_EDO_DOCUMENT.COMPLETED_DOCUMENT);

        const handleResult = () => {
            props.onClose();
            resetForm();
            props.fetchList();
            setFieldValue("loading", false);
        };

        const handleError = () => {
            setFieldValue("loading", false);
        };

        const handlePreview = () => {
            setFieldValue("loading", false);
        };

        if (isGetPreFilledTemplate) {
            const {
                file,
                ...otherValues
            } = rest;

            const pushData = mapDataTemplateDocument(otherValues);

            props.getEdoPreFilledTemplate({
                data: pushData,
                meta: handlePreview,
            });
        }

        if (isPreFilledCompletedDocument) {
            const getRequestFormData = () => {
                const formData = new FormData();

                !isEmpty(values.file) && values.file.size && formData.append("multipartFile", values.file);

                return formData;
            };

            props.validateEdoCompletedDocument({
                data: getRequestFormData(),
                handleSuccess: () => {
                    handlePreview();
                    setFieldValue("step", UPLOAD_EDO_STEP.SECOND);
                },
                handleError: handlePreview,
            });

        }

        if (isUploadTemplate) {
            const {
                file,
                ...otherValues
            } = rest;

            const pushData = mapDataTemplateDocument(otherValues);

            props.data.docId ?
                props.updateTemplate({
                    data: pushData,
                    meta: handleResult,
                    handleError: handleError,
                }) :
                props.uploadTemplate({
                    data: pushData,
                    meta: handleResult,
                    handleError: handleError,
                });
        }

        if (isUploadCompletedDocument) {
            const {
                receiverIds,
                description,
                documentName,
                senderId,
                documentDateTime,
                type,
                file,
                documentId,
                receiverId,
                additionalContractorsDetail,
                selectedDuplicateReceivers,
                dataDuplicate,
            } = rest;

            const getRequestFormData = () => {
                const formData = new FormData();

                !documentId && formData.append(EDO_DOCUMENT_FIELD.RECEIVERS_IDS,
                    isEmpty(selectedDuplicateReceivers) ?
                        receiverIds :
                        receiverIds.filter(item => !Array.from(new Set(dataDuplicate.map(({staff}) => staff.staffId))).includes(item)).concat(selectedDuplicateReceivers));
                Boolean(documentId) && formData.append(EDO_DOCUMENT_FIELD.RECEIVER_ID, receiverId);
                Boolean(documentId) && formData.append(EDO_DOCUMENT_FIELD.DOCUMENT_ID, documentId);
                formData.append(EDO_DOCUMENT_FIELD.DESCRIPTION, isNullOrWhitespace(description) ? "" : description.trim());
                formData.append(EDO_DOCUMENT_FIELD.ADDITIONAL_CONTRACTORS_DETAIL, isNullOrWhitespace(additionalContractorsDetail) ? "" : additionalContractorsDetail.trim());
                formData.append(EDO_DOCUMENT_FIELD.DOCUMENT_NAME, handleFormString(documentName));
                formData.append(EDO_DOCUMENT_FIELD.SENDER_ID, senderId);
                formData.append("fileName", file.name);
                formData.append(EDO_DOCUMENT_FIELD.DOCUMENT_DATE_TIME, documentDateTime.toJSON());
                formData.append(EDO_DOCUMENT_FIELD.TYPE, type);
                Boolean(documentId) && formData.append("reload", !isEmpty(file));
                !isEmpty(file) && file.size && formData.append(EDO_DOCUMENT_FIELD.FILE, file);

                return formData;
            };

            props.data.docId ?
                props.replaceEdoDocument({
                    formData: getRequestFormData(),
                    meta: handleResult,
                    handleError: handleError,
                }) :
                props.uploadEdoDocument({
                    formData: getRequestFormData(),
                    meta: handleResult,
                    handleError: handleError,
                });
        }

        setSubmitting(false);
    },
})(Formik);

export default withRouter(
    connect(
        null,
        {
            getEdoPreFilledTemplate,
            uploadEdoDocument,
            uploadTemplate,
            updateTemplate,
            replaceEdoDocument,
            getDocument,
            validateEdoCompletedDocument,
            clearDocument,
        },
    )(DocumentManagementLoadingDocument2),
);

DocumentManagementLoadingDocument2.propTypes = {
    data: PropTypes.shape({
        documentDateTime: PropTypes.instanceOf(Date),
        type: PropTypes.string,
        documentName: PropTypes.string,
        senderId: PropTypes.string,
        description: PropTypes.string,
        receiverIds: PropTypes.array,
        file: PropTypes.object,
        params: PropTypes.shape({
            type: PropTypes.string,
            documentNumber: PropTypes.string,
            documentPlace: PropTypes.string,
            documentSubject: PropTypes.string,
            startWorkDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
            endWorkDate: PropTypes.string,
            personalData: PropTypes.array,
            targets: PropTypes.string,
        }),
        step: PropTypes.arrayOf(PropTypes.number),
        loading: PropTypes.bool,
        typeLoading: PropTypes.string,
    }),
};

Formik.propTypes = {
    data: PropTypes.shape({
        documentDateTime: PropTypes.instanceOf(Date),
        type: PropTypes.string,
        documentName: PropTypes.string,
        senderId: PropTypes.string,
        description: PropTypes.string,
        receiverIds: PropTypes.array,
        receiverId: PropTypes.string,
        file: PropTypes.object,
        params: PropTypes.shape({
            type: PropTypes.string,
            documentNumber: PropTypes.string,
            documentPlace: PropTypes.string,
            documentSubject: PropTypes.string,
            startWorkDate: PropTypes.string,
            endWorkDate: PropTypes.string,
            personalData: PropTypes.array,
            targets: PropTypes.string,
            platformName: PropTypes.string,
            paymentCurrency: PropTypes.string,
            email: PropTypes.string,
        }),
        step: PropTypes.arrayOf(PropTypes.number),
        loading: PropTypes.bool,
        typeLoading: PropTypes.string,
    }),
};

DocumentManagementLoadingDocument2.defaultProps = {
    data: {
        documentDateTime: new Date(),
        type: "",
        documentName: "",
        senderId: "",
        description: "",
        receiverIds: [],
        receiverId: "",
        file: undefined,
        params: PARAMS,
        typeLoading: undefined,
        step: UPLOAD_EDO_STEP.FIRST,
        loading: false,
        documentId: undefined,
    },
};

function getFieldByTemplate(params) {
    const {
        additionalContractorsDetail,
        type,
        documentNumber,
        documentDate,
        documentPlace,
        documentSubject,
        startWorkDate,
        endWorkDate,
        targets,
        personalData,
        platformName,
        paymentCurrency,
        email,
        contractNumber,
        contractDate,
        periodFrom,
        periodTo,
        jobList,
    } = params;
    switch (type) {
        case TEMPLATE_DOCUMENT.REGISTRATION_LOG_INTRODUCTORY_INSTRUCTION:
        case TEMPLATE_DOCUMENT.REGISTRATION_LOG_PRIMARY_INSTRUCTION:
            return {
                type,
            };
        case TEMPLATE_DOCUMENT.ACT_CIVIL_DEED:
            return {
                documentNumber,
                contractNumber,
                contractDate,
                jobList,
                type,
            };
        case TEMPLATE_DOCUMENT.ATTACHMENT_CIVIL_DEED:
            return {
                documentNumber,
                documentDate,
                contractNumber,
                contractDate,
                periodFrom,
                jobList,
                periodTo,
                type,
            };
        case TEMPLATE_DOCUMENT.TRADE_SECRET_NONDISCLOSURE_OBLIGATION:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_2:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_3:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_4:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_6:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_7:
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_8:
            return {
                documentNumber,
                type,
            };
        case TEMPLATE_DOCUMENT.CONSENT_TO_THE_PROCESSING_OF_PERSONAL_DATA:
            return {
                personalData,
                targets,
                type,
            };
        case TEMPLATE_DOCUMENT.CIVIL_DEED_WITH_INTELLECTUAL_PROPERTY:
            return {
                documentNumber,
                paymentCurrency,
                platformName,
                email,
                documentPlace,
                additionalContractorsDetail,
                type,
            };
        case TEMPLATE_DOCUMENT.ADDITIONAL_AGREEMENT_TO_THE_FRAMEWORK_CONTRACT_5:
            return {
                documentNumber,
                platformName,
                type,
            };
        case TEMPLATE_DOCUMENT.CIVIL_DEED:
            return {
                documentNumber,
                documentSubject,
                startWorkDate,
                endWorkDate,
                documentPlace,
                type,
            };
        default:
            return params;
    }
}





