import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
    BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST,
    BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS,
    BFF_BANK_TKB_UPDATE_302_ERROR,
    BFF_BANK_TKB_UPDATE_302_REQUEST,
    BFF_BANK_TKB_UPDATE_302_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/finances/bank/tkb";

// POST /bff/adm/finances/bank/tkb/history/getPageRich
// Получение обогащенной страницы с депозитами из ТКБ
const getPageBankTkbHistory = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/history/getPageRich`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: BFF_BANK_TKB_HISTORY_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_BANK_TKB_HISTORY_GET_PAGE_ERROR,
        });
    }
};

// POST /bff/adm/finances/bank/tkb/update-302-from-bank
// Обновить баланс 302 счёта из ТКБ
const update302FromBankTkbSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/update-302-from-bank`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            
            yield put({
                type: BFF_BANK_TKB_UPDATE_302_ERROR,
            });

            return {
                done: true,
            };
        }
        
        yield put({
            type: BFF_BANK_TKB_UPDATE_302_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_BANK_TKB_UPDATE_302_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST, getPageBankTkbHistory),
        takeEvery(BFF_BANK_TKB_UPDATE_302_REQUEST, update302FromBankTkbSaga),
    ]);
}