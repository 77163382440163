import React, {useMemo} from "react";
import {Switch} from "react-router-dom";

import {CheckRoleRoute} from "../../../components/CheckRoleRoute";
import Instructions from "../../../components/Instructions";
import {InstructionsNaimix} from "../../../components/Instructions/components/naimix";
import {InstructionsKedo} from "./kedo";

import {getUserRole} from "../../../utils/access";
import {isAccessByRestrictions} from "../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {
    LINK_SETTINGS_INSTRUCTION,
    LINK_SETTINGS_INSTRUCTION_KEDO,
} from "../../../constants/links";
import {ADMIN, NM_MANAGER} from "../../../constants/roles";

export const SettingsInstructions = () => {
    const role = getUserRole();

    const tabs = useMemo(() => {
        return [
            {
                name: "Наймикс",
                link: LINK_SETTINGS_INSTRUCTION,
            },
            {
                name: "КЭДО",
                link: LINK_SETTINGS_INSTRUCTION_KEDO,
                isVisible: isAccessByRestrictions([
                    CLIENT_USER_RESTRICTIONS_VARIABLE.accessSettingInstructionsKedo,
                ]),
            },
        ];
    }, []);

    return (
        <Instructions
            noPadding={true}
            tabs={tabs}
            isVisibleTabs={[ADMIN, NM_MANAGER].includes(role)}
            pages={
                <Switch>
                    <CheckRoleRoute
                        exact
                        path={LINK_SETTINGS_INSTRUCTION}
                        component={InstructionsNaimix}
                    />
                    <CheckRoleRoute
                        path={LINK_SETTINGS_INSTRUCTION_KEDO}
                        component={InstructionsKedo}
                    />
                </Switch>
            }
        />
    );
};