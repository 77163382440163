import {useState} from "react";

export const useObjectsEdit = () => {
    const [editData, setEditData] = useState({});

    const onOpenEdit = ({open, object}) => {
        setEditData({
            isOpenEditObjectWindow: open,
            editObject: object,
        });
    };

    return {
        editData,
        onOpenEdit,
    };
};