import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {
    getDepositList,
    getDepositPageDataSelector,
} from "../../../deposit";
import {
    getClientCardInfo,
    getClientCardRefProgram,
    getClientCardRefProgramHistory,
} from "./actionCreators";
import {
    ADD_CLIENT_REF_PROGRAM_ERROR,
    ADD_CLIENT_REF_PROGRAM_REQUEST,
    ADD_CLIENT_REF_PROGRAM_SUCCESS,
    CHECK_CLIENT_PROMOCODE_ERROR,
    CHECK_CLIENT_PROMOCODE_REQUEST,
    CHECK_CLIENT_PROMOCODE_SUCCESS,
    GET_CLIENT_INFO_ERROR,
    GET_CLIENT_INFO_REQUEST,
    GET_CLIENT_INFO_SUCCESS,
    GET_CLIENT_PROPERTIES_ERROR,
    GET_CLIENT_PROPERTIES_REQUEST,
    GET_CLIENT_PROPERTIES_SUCCESS,
    GET_CLIENT_REF_PROGRAM_ERROR,
    GET_CLIENT_REF_PROGRAM_HISTORY_ERROR,
    GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST,
    GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS,
    GET_CLIENT_REF_PROGRAM_REQUEST,
    GET_CLIENT_REF_PROGRAM_SUCCESS,
    GET_CLIENT_SPECIALITIES_ERROR,
    GET_CLIENT_SPECIALITIES_REQUEST,
    GET_CLIENT_SPECIALITIES_SUCCESS,
    GET_CURRENT_CLIENT_INFO_ERROR,
    GET_CURRENT_CLIENT_INFO_REQUEST,
    GET_CURRENT_CLIENT_INFO_SUCCESS,
    GET_ORDER_CATEGORY_ALL_ERROR,
    GET_ORDER_CATEGORY_ALL_REQUEST,
    GET_ORDER_CATEGORY_ALL_SUCCESS,
    UPDATE_CLIENT_INFO_ERROR,
    UPDATE_CLIENT_INFO_REQUEST,
    UPDATE_CLIENT_INFO_SUCCESS,
    UPDATE_CLIENT_PROPERTIES_ERROR,
    UPDATE_CLIENT_PROPERTIES_REQUEST,
    UPDATE_CLIENT_PROPERTIES_SUCCESS,
    UPDATE_CLIENT_REQUISITES_ERROR,
    UPDATE_CLIENT_REQUISITES_REQUEST,
    UPDATE_CLIENT_REQUISITES_SUCCESS,
} from "./actions";
import {clientReferralHistoryPageDataSelector} from "./selectors";

import request from "../../../../utils/postman";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";
import {getBffCommonController, getBffControllerClientCardPage, getBffUrl} from "../../../../utils/url";

import {CLIENT_PROPERTIES_MESSAGE} from "../../../../constants/clientList";
import {
    LINK_INFO,
    LINK_PROJECTS_LIST,
} from "../../../../constants/links";
import {CLIENT} from "../../../../constants/messages";

const getController = () => getBffControllerClientCardPage({
    admin: "/adm/clients/client-card/client/info",
    client: "/client-adm/client/info",
});

//GET /bff/adm/clients/client-card/client/info/getRichClientById
//GET /bff/client-adm/client/info/getRichClientById
//GET /bff/adm/clients/client-card/client/projects/getRichClientById
//GET /bff/client-adm/client/projects/getRichClientById
//POST bff/adm/common/client/clientProperties/getRichClientById
//POST bff/client-adm/common/client/clientProperties/getRichClientById
const getClientCardInfoSaga = function* ({payload}) {
    try {
        const controller = getBffUrl({
            [LINK_PROJECTS_LIST]: getBffControllerClientCardPage({
                admin: "/adm/clients/client-card/client/projects",
                client: "/client-adm/client/projects",
            }),
            [LINK_INFO]: getController(),
        },
        `${getBffCommonController()}/client`,
        true,
        );

        const result = yield request.bff.get(`${controller}/getRichClientById`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_INFO_ERROR,
            payload: error,
        });
    }
};

const getCurrentClientCardInfoSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getBffCommonController()}/client/getRichClientById`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CURRENT_CLIENT_INFO_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CURRENT_CLIENT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CURRENT_CLIENT_INFO_ERROR,
            payload: error,
        });
    }
};

//POST bff/adm/clients/client-card/client/info/clientProperties/getRichByClientId
//POST bff//client-adm/client/info/clientProperties/getRichByClientId
//POST bff/adm/common/client/clientProperties/getRichByClientId
//POST bff/client-adm/common/client/clientProperties/getRichByClientId
const getClientCardPropertiesSaga = function* ({payload}) {
    try {
        const controller = getBffUrl(
            {
                [LINK_INFO]: getController(),
            },
            `${getBffCommonController()}/client`,
            true,
        );
        const result = yield request.bff.get(`${controller}/clientProperties/getRichByClientId`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_PROPERTIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_PROPERTIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_PROPERTIES_ERROR,
            payload: error,
        });
    }
};

const updateClientCardInfoSaga = function* (action) {
    try {
        const {payload} = action;
        const {
            clientId,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/update`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_INFO_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(CLIENT.CLIENT_UPDATE_SUCCESS_TEXT);

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: UPDATE_CLIENT_INFO_SUCCESS,
        });

        yield put(getClientCardInfo({clientId}));
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_INFO_ERROR,
            payload: error,
        });
    }
};

const updateClientCardPropertiesSaga = function* ({payload}) {
    try {
        const {
            isFetchDepositList,
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/clientProperties/update`, reqData);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: UPDATE_CLIENT_PROPERTIES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        toastSuccess(CLIENT_PROPERTIES_MESSAGE.SUCCESS_UPDATE);
        onSuccess && onSuccess();

        if (isFetchDepositList) {
            const state = yield select();

            yield put(getDepositList(getDepositPageDataSelector(state)));
        }

        yield put({
            type: UPDATE_CLIENT_PROPERTIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_PROPERTIES_ERROR,
            payload: error,
        });
    }
};

const getClientCardRefHistorySaga = function* ({payload}) {
    try {
        const {typeFilter} = payload;

        const result = yield request.bff.post(`${getController()}/getPageClientReferralHistory`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_REF_PROGRAM_HISTORY_ERROR,
                payload: {
                    ...errorMessage,
                    typeFilter,
                },
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_REF_PROGRAM_HISTORY_SUCCESS, payload: {
                ...result,
                typeFilter,
            },
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_REF_PROGRAM_HISTORY_ERROR,
            payload: error,
        });
    }
};

const getClientCardRefProgramSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getController()}/getPromocodesByClientId`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_REF_PROGRAM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_REF_PROGRAM_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_REF_PROGRAM_ERROR,
            payload: error,
        });
    }
};

//GET /bff/client-adm/common/info/find-specialities
//GET /bff/adm/common/info/find-specialities
const getClientSpecialitiesSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get(`${getBffCommonController()}/info/find-specialities`, {params: payload});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_SPECIALITIES_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_SPECIALITIES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_SPECIALITIES_ERROR,
            payload: error,
        });
    }
};

const checkClientPromocodeSaga = function* ({payload}) {
    try {
        const {
            data,
        } = payload;

        const result = yield request.bff.post(`${getController()}/check`, data);

        if (result === null) {
            yield put({
                type: CHECK_CLIENT_PROMOCODE_ERROR,
                payload: "Не удалось проверить промокод",
            });

            return {
                done: true,
            };
        }

        const {errorMessage, errorCode} = result;

        if (errorMessage) {
            const error = errorCode === "INVALID_PROMOCODE" ? "Введен неверный промо-код" : errorMessage;

            yield put({
                type: CHECK_CLIENT_PROMOCODE_ERROR,
                payload: error,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: CHECK_CLIENT_PROMOCODE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CHECK_CLIENT_PROMOCODE_ERROR,
            payload: error,
        });
    }
};

const updateClientCardRequisitesSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            onError,
            clientId,
            ...reqData
        } = payload;
        const result = yield request.bff.patch(`${getController()}/updateRequisites/${clientId}`, reqData);

        if (result.errorMessage) {
            onError && onError(result);

            yield put({
                type: UPDATE_CLIENT_REQUISITES_ERROR,
                error: result.errorMessage,
            });

            return {
                done: true,
            };
        }

        onSuccess && onSuccess();

        yield put({
            type: UPDATE_CLIENT_REQUISITES_SUCCESS,
        });
    } catch (error) {
        yield put({
            type: UPDATE_CLIENT_REQUISITES_ERROR,
            error,
        });
    }
};

const addClientCardRefProgramSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...reqData
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-referral-program`, reqData);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: ADD_CLIENT_REF_PROGRAM_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess && onSuccess();
        yield put({type: ADD_CLIENT_REF_PROGRAM_SUCCESS, payload: result});

        const state = yield select();
        yield put(getClientCardRefProgramHistory(clientReferralHistoryPageDataSelector(state)));

        const {
            clientId,
        } = payload;

        yield put(getClientCardRefProgram({clientId}));
    } catch (error) {
        yield put({
            type: ADD_CLIENT_REF_PROGRAM_ERROR,
            payload: error,
        });
    }
};

const getOrderCategoryAllSaga = function* () {
    try {
        const result = yield request.bff.get(`${getController()}/categories/all`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_ORDER_CATEGORY_ALL_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_ORDER_CATEGORY_ALL_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_ORDER_CATEGORY_ALL_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_CLIENT_INFO_REQUEST, getClientCardInfoSaga),
        takeEvery(GET_CURRENT_CLIENT_INFO_REQUEST, getCurrentClientCardInfoSaga),
        takeEvery(GET_CLIENT_PROPERTIES_REQUEST, getClientCardPropertiesSaga),
        takeEvery(UPDATE_CLIENT_INFO_REQUEST, updateClientCardInfoSaga),
        takeEvery(UPDATE_CLIENT_PROPERTIES_REQUEST, updateClientCardPropertiesSaga),
        takeEvery(GET_CLIENT_REF_PROGRAM_HISTORY_REQUEST, getClientCardRefHistorySaga),
        takeEvery(GET_CLIENT_REF_PROGRAM_REQUEST, getClientCardRefProgramSaga),
        takeEvery(GET_CLIENT_SPECIALITIES_REQUEST, getClientSpecialitiesSaga),
        takeEvery(CHECK_CLIENT_PROMOCODE_REQUEST, checkClientPromocodeSaga),
        takeEvery(UPDATE_CLIENT_REQUISITES_REQUEST, updateClientCardRequisitesSaga),
        takeEvery(ADD_CLIENT_REF_PROGRAM_REQUEST, addClientCardRefProgramSaga),
        takeEvery(GET_ORDER_CATEGORY_ALL_REQUEST, getOrderCategoryAllSaga),
    ]);
}