import {
    addClientBrand,
    addClientBrandLogo,
    deleteClientBrandLogo,
    getAssignBrandsObjectsPage,
    getAssignBrandsProjectsObjectsList,
    getAssignBrandsProjectsPage,
    getClientBrands,
    getLogsBrandChange,
    saveAssignBrandsObjects,
    updateClientBrand,
    updateClientBrandArchiveStatus,
} from "../../../client/brands/actionCreators";

const controller = "/client-adm/client/brands";

export const updateClientCompanyBrand = (payload) => {
    return updateClientBrand({
        url: `${controller}/update`,
        ...payload,
    });
};

export const updateClientCompanyArchiveStatus = (payload) => {
    return updateClientBrandArchiveStatus({
        url: `${controller}/updateArchiveStatus`,
        ...payload,
    });
};

export const getClientsCompanyBrands = (payload) => {
    return getClientBrands({
        url: `${controller}/getPage`,
        ...payload,
    });
};

export const deleteClientCompanyBrandLogo = (payload) => {
    return deleteClientBrandLogo({
        url: `${controller}/deleteLogo`,
        ...payload,
    });
};

export const addClientCompanyBrand = (payload) => {
    return addClientBrand({
        url: `${controller}/add`,
        ...payload,
    });
};

export const getClientAssignBrandsProjectsPage = (payload) => {
    return getAssignBrandsProjectsPage({
        url: `${controller}/assignBrandsProjectsPage`,
        ...payload,
    });
};

export const getClientAssignBrandsObjectsPage = (payload) => {
    return getAssignBrandsObjectsPage({
        url: `${controller}/assignBrandsObjectsPage`,
        ...payload,
    });
};

export const saveClientAssignBrandsObjects = (payload) => {
    return saveAssignBrandsObjects({
        url: `${controller}/saveAssignBrandsObjects`,
        ...payload,
    });
};

export const getClientLogsBrandChange = (payload) => {
    return getLogsBrandChange({
        url: `${controller}/getLogsBrandChange`,
        ...payload,
    });
};

export const getClientAssignBrandsProjectsObjectsList = (payload) => {
    return getAssignBrandsProjectsObjectsList({
        url: `${controller}/assignBrandsProjectsObjectsList`,
        ...payload,
    });
};

export const addClientCompanyBrandLogo = (payload) => {
    return addClientBrandLogo({
        url: `${controller}/addLogo`,
        ...payload,
    });
};