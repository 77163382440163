import {convertStringToDate} from "../../../../../../utils/stringHelper";

export const getContractorAdditionalVerificationInitialValues = (card) => {
    return {
        ...card,
        medicalBookNumber: card.medicalBookNumber || "",
        medicalBookIssuedDate: convertStringToDate(card.medicalBookIssuedDate) || null,
        fingerprintingDocumentNum: card.fingerprintingDocumentNum || "",
        employmentHistoryNum: card.employmentHistoryNum || "",
        fingerprintingDocumentIssuedBy: card.fingerprintingDocumentIssuedBy || "",
        snilsNum: card.snilsNum || "",
        fingerprintingDocumentIssuedDate: convertStringToDate(card.fingerprintingDocumentIssuedDate) || null,
    };
};