import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {
    getRestrictionDescriptions,
    getRoleRestrictions,
} from "../../../../utils/strapiService";

import {
    getSettingsUsersRolesRichPage,
    updateFieldSettingsUsersRoles,
} from "../../../../ducks/settingsUsersRoles";

export function useSettingsUsersRolesFetch(props) {
    const {
        pageSize,
        pageNum,
        subPage,
        filterData,
    } = props;

    const [restrictionDescriptions, setRestrictionDescriptions] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchRoleRestrictions() {
            const list = await getRoleRestrictions();

            return getRestrictionDescriptions(list);
        }

        fetchRoleRestrictions().then(result => {
            setRestrictionDescriptions(result);
        });

        return () => {
            dispatch(updateFieldSettingsUsersRoles({
                list: [],
                pageData: {},
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        subPage,
        filterData,
    ]);

    const fetchList = () => {
        dispatch(getSettingsUsersRolesRichPage({
            pageNum,
            pageSize,
            type: subPage,
            archived: false,
            ...filterData,
        }));
    };

    return {
        fetchList,
        restrictionDescriptions,
    };
}
