import {
    CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST,
    CONTRACTOR_BANKS_UPDATE_STORE,
    CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST,
    GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST,
    GET_LIST_CONTRACTOR_BANKS_REQUEST,
    REFRESH_CONTRACTOR_BANK_STATUS_REQUEST,
    REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST,
    SENDING_CONTRACTOR_TO_BANK_REQUEST,
} from "./actions";

export function getListContractorBanks(payload) {
    return {
        type: GET_LIST_CONTRACTOR_BANKS_REQUEST,
        payload,
    };
}

export function sendingContractorToBank(payload) {
    return {
        type: SENDING_CONTRACTOR_TO_BANK_REQUEST,
        payload,
    };
}

export function refreshContractorBankStatus(payload) {
    return {
        type: REFRESH_CONTRACTOR_BANK_STATUS_REQUEST,
        payload,
    };
}

export function refreshContractorIdentificationStatus(payload) {
    return {
        type: REFRESH_CONTRACTOR_IDENTIFICATION_STATUS_REQUEST,
        payload,
    };
}

export function updateContractorBanksBalanceStore(payload) {
    return {
        type: CONTRACTOR_BANKS_UPDATE_STORE,
        payload,
    };
}

export function createContractorBankIdentificationRequest(payload) {
    return {
        type: CREATE_CONTRACTOR_BANK_IDENTIFICATION_REQUEST,
        payload,
    };
}

export function getContractorBankIdentificationLogs(payload) {
    return {
        type: GET_CONTRACTOR_BANK_IDENTIFICATION_LOG_REQUEST,
        payload,
    };
}

export function sendToContractorBankForcibly(payload) {
    return {
        type: CONTRACTOR_BANK_SENDING_CONTRACTOR_TO_BANK_FORCIBLY_REQUEST,
        payload,
    };
}