import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";
import {getBffControllerClientCardPage} from "../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/patents/documents",
        client: "/client-adm/client/patents/documents",
    });
};

/*TYPES*/
const GET_DOCUMENTS_LIST_PATENTS_REQUEST = "GET_DOCUMENTS_LIST_PATENTS_REQUEST";
const GET_DOCUMENTS_LIST_PATENTS_SUCCESS = "GET_DOCUMENTS_LIST_PATENTS_SUCCESS";
const GET_DOCUMENTS_LIST_PATENTS_ERROR = "GET_DOCUMENTS_LIST_PATENTS_ERROR";

const GET_DOCUMENTS_LIST_TYPES_REQUEST = "GET_DOCUMENTS_LIST_TYPES_REQUEST";
const GET_DOCUMENTS_LIST_TYPES_SUCCESS = "GET_DOCUMENTS_LIST_TYPES_SUCCESS";
const GET_DOCUMENTS_LIST_TYPES_ERROR = "GET_DOCUMENTS_LIST_TYPES_ERROR";

const ADD_DOCUMENTS_EXPORT_PATENTS_REQUEST = "ADD_DOCUMENTS_EXPORT_PATENTS_REQUEST";

/*INITIAL STATE*/
const initial = {
    totalCount: 0,
    pageData: {},
    documentsList: [],
    progress: false,
    documentTypes: {},
};

/*REDUCER*/
export default (state = initial, {type, payload}) => {
    switch (type) {
        case GET_DOCUMENTS_LIST_TYPES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_DOCUMENTS_LIST_PATENTS_REQUEST:
            return {
                ...state,
                progress: true,
                pageData: payload,
            };
        case GET_DOCUMENTS_LIST_TYPES_SUCCESS:
            return {
                ...state,
                progress: false,
                documentTypes: payload,
            };
        case GET_DOCUMENTS_LIST_PATENTS_SUCCESS:
            return {
                ...state,
                progress: false,
                totalCount: payload.totalCount,
                documentsList: payload.results,
            };
        case GET_DOCUMENTS_LIST_PATENTS_ERROR:
        case GET_DOCUMENTS_LIST_TYPES_ERROR:
            return {
                ...state,
                error: payload,
            };
        default:
            return state;
    }
};

/*ACTIONS CREATORS*/
export const getDocumentsListPatents = (payload) => ({
    type: GET_DOCUMENTS_LIST_PATENTS_REQUEST,
    payload,
});

export const getDocumentsListTypesPatents = (payload) => ({
    type: GET_DOCUMENTS_LIST_TYPES_REQUEST,
    payload,
});

export const addDocumentsExportPatents = (payload) => ({
    type: ADD_DOCUMENTS_EXPORT_PATENTS_REQUEST,
    payload,
});

/*SELECTORS*/
export const patentsDocumentsSelector = ({patentsDocuments}) => patentsDocuments;
export const patentsDocumentsListTypesSelector = createSelector(patentsDocumentsSelector, ({documentTypes}) => documentTypes);
export const patentsDocumentsListSelector = createSelector(patentsDocumentsSelector, ({documentsList}) => documentsList);
export const patentsDocumentsTotalCountSelector = createSelector(patentsDocumentsSelector, ({totalCount}) => totalCount);
export const patentsDocumentsProgressSelector = createSelector(patentsDocumentsSelector, ({progress}) => progress);
export const patentsDocumentsTotalPagesSelector = createSelector(patentsDocumentsSelector, ({totalCount, pageData: {pageSize}}) => getTotalPages(totalCount, pageSize));

export const getDocumentsListPatentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/getRichPage`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DOCUMENTS_LIST_PATENTS_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_DOCUMENTS_LIST_PATENTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DOCUMENTS_LIST_PATENTS_ERROR,
            payload: error,
        });
    }
};

export const getDocumentsListTypesPatentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/document-type/dict`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_DOCUMENTS_LIST_TYPES_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: GET_DOCUMENTS_LIST_TYPES_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_DOCUMENTS_LIST_TYPES_ERROR,
            payload: error,
        });
    }
};

export const addDocumentsExportPatentsSaga = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${getController()}/export/add`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            return {
                done: true,
            };
        }

        toastSuccess("Сформированная выгрузка доступна в разделе Патенты → Экспорт");
    } catch (error) {
        console.error(error);
    }
};

export function* saga() {
    yield all([
        takeEvery(GET_DOCUMENTS_LIST_PATENTS_REQUEST, getDocumentsListPatentsSaga),
        takeEvery(GET_DOCUMENTS_LIST_TYPES_REQUEST, getDocumentsListTypesPatentsSaga),
        takeEvery(ADD_DOCUMENTS_EXPORT_PATENTS_REQUEST, addDocumentsExportPatentsSaga),
    ]);
}