import {all, put,takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError} from "../utils/toastHelper";

import {ORDER_WORK_REPORT} from "../constants/messages";

const controller = "/orderWorkReport";
//*  TYPES  *//

const FINANCE_REPORT_CONTRACTOR_LIST_REQUEST = "FINANCE_REPORT_CONTRACTOR_LIST_REQUEST";
const FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS = "FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS";
const FINANCE_REPORT_CONTRACTOR_LIST_ERROR = "FINANCE_REPORT_CONTRACTOR_LIST_ERROR";

const FINANCE_REPORT_PAYMENT_INFO_REQUEST = "FINANCE_REPORT_PAYMENT_INFO_REQUEST";
const FINANCE_REPORT_PAYMENT_INFO_SUCCESS = "FINANCE_REPORT_PAYMENT_INFO_SUCCESS";
const FINANCE_REPORT_PAYMENT_INFO_ERROR = "FINANCE_REPORT_PAYMENT_INFO_ERROR";

const FINANCE_REPORT_SUMMARY_DATA_REQUEST = "FINANCE_REPORT_SUMMARY_DATA_REQUEST";
const FINANCE_REPORT_SUMMARY_DATA_SUCCESS = "FINANCE_REPORT_SUMMARY_DATA_SUCCESS";
const FINANCE_REPORT_SUMMARY_DATA_ERROR = "FINANCE_REPORT_SUMMARY_DATA_ERROR";

const FINANCE_REPORT_TAX_LIST_REQUEST = "FINANCE_REPORT_TAX_LIST_REQUEST";
const FINANCE_REPORT_TAX_LIST_SUCCESS = "FINANCE_REPORT_TAX_LIST_SUCCESS";
const FINANCE_REPORT_TAX_LIST_ERROR = "FINANCE_REPORT_TAX_LIST_ERROR";

const UPDATE_FINANCE_REPORT_STORE = "UPDATE_FINANCE_REPORT_STORE";

//*  INITIAL STATE  *//

const initial = {
    paymentList: [],
    paymentListPageData: {
        data: {},
    },
    paymentListTotalCount: 0,
    contractorListForFilter: [],
    contractorListByIds: [],
    summaryDataLoading: false,
    summaryData: {},
    contractortTaxList: [],
    paymentInfo: {},
    paymentInfoLoading: false,
    optimizedList: [],
    paymentsTotalAmount: 0,
    loadingDataAmount: false,
    loadingData: false,
    contractorIncomeList: [],
    contractorIncomeTotal: {},
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
        case UPDATE_FINANCE_REPORT_STORE:
            return {
                ...state,
                ...payload,
            };
        case FINANCE_REPORT_SUMMARY_DATA_REQUEST:
            return {
                ...state,
                summaryDataLoading: true,
            };
        case FINANCE_REPORT_TAX_LIST_REQUEST:
            return {
                ...state,
                loadingData: true,
                paymentListPageData: {...payload},
            };
        case FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS:
            const {contractors: contractorListForFilter} = payload;
            return {
                ...state,
                loadingData: false,
                contractorListForFilter,
            };
        case FINANCE_REPORT_PAYMENT_INFO_REQUEST:
            return {
                ...state,
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_PAYMENT_INFO_SUCCESS:
            return {
                ...state,
                paymentInfo: {...payload},
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_PAYMENT_INFO_ERROR:
            return {
                ...state,
                paymentInfo: {},
                paymentInfoLoading: false,
            };
        case FINANCE_REPORT_SUMMARY_DATA_SUCCESS:
            return {
                ...state,
                summaryData: {...payload},
                summaryDataLoading: false,
            };
        case FINANCE_REPORT_TAX_LIST_SUCCESS:
            return {
                ...state,
                contractortTaxList: payload,
                loadingData: false,
            };
        case FINANCE_REPORT_TAX_LIST_ERROR:
            return {
                ...state,
                loadingData: false,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export function getPaymentInfo(payload) {
    return {
        type: FINANCE_REPORT_PAYMENT_INFO_REQUEST,
        payload,
    };
}

export function getContractorListForFilter(payload) {
    return {
        type: FINANCE_REPORT_CONTRACTOR_LIST_REQUEST,
        payload,
    };
}

export function updateFinanceReportStore(payload) {
    return {
        type: UPDATE_FINANCE_REPORT_STORE,
        payload,
    };
}

//*  SELECTORS  *//

const financeReportSelector = state => state.financeReport;
export const paymentInfoSelector = createSelector(financeReportSelector, ({paymentInfo}) => paymentInfo);
export const getContractorListForFilterSelector = createSelector(financeReportSelector, ({contractorListForFilter}) => contractorListForFilter.map(({contractorId: value, firstName, lastName, patronymic}) => ({
    key: value, value, text: `${lastName} ${firstName} ${patronymic}`,
})));
export const getOrderWorkReportOptimizedListTaskIdsSelector = createSelector(financeReportSelector, ({optimizedList}) =>
    optimizedList.map(value => `${value.clientId}:${value.orderId}:${value.paymentNumber}`),
);

//*  SAGA  *//

export const getContractorListSaga = function* (action) {
    try {
        const {payload: {data}} = action;
        const result = yield request.post("contractors/getPage", data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: FINANCE_REPORT_CONTRACTOR_LIST_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_CONTRACTOR_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_CONTRACTOR_LIST_ERROR,
            payload: error,
        });
    }
};

export const getContractorTaxListSaga = function* (action) {
    try {
        const {payload: {data}} = action;
        const result = yield request.post(`${controller}/getContractorTaxList`, data);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_TAX_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_TAX_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_TAX_LIST_ERROR,
            payload: error,
        });
    }
};

export const getSummaryDataSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getContractorMoneyStats`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_SUMMARY_DATA_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_SUMMARY_DATA_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: FINANCE_REPORT_SUMMARY_DATA_ERROR,
            payload: error,
        });
    }
};

export const getPaymentInfoSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getOrderPaymentInfo`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);
            yield put({
                type: FINANCE_REPORT_PAYMENT_INFO_ERROR,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: FINANCE_REPORT_PAYMENT_INFO_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(ORDER_WORK_REPORT.PAYMENT_CONFIRM_ERROR);
        yield put({
            type: FINANCE_REPORT_PAYMENT_INFO_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(FINANCE_REPORT_CONTRACTOR_LIST_REQUEST, getContractorListSaga),
        takeEvery(FINANCE_REPORT_SUMMARY_DATA_REQUEST, getSummaryDataSaga),
        takeEvery(FINANCE_REPORT_TAX_LIST_REQUEST, getContractorTaxListSaga),
        takeEvery(FINANCE_REPORT_PAYMENT_INFO_REQUEST, getPaymentInfoSaga),
    ]);
}
