import {all, put, select, takeEvery} from "@redux-saga/core/effects";

import {getEdmSubdivisionCommonCount} from "./actionCreators";
import {
    EDM_SUBDIVISION_ADD_ERROR,
    EDM_SUBDIVISION_ADD_REQUEST,
    EDM_SUBDIVISION_ADD_SUCCESS,
    EDM_SUBDIVISION_COMMON_COUNT_ERROR,
    EDM_SUBDIVISION_COMMON_COUNT_REQUEST,
    EDM_SUBDIVISION_COMMON_COUNT_SUCCESS,
    EDM_SUBDIVISION_DELETE_ERROR,
    EDM_SUBDIVISION_DELETE_REQUEST,
    EDM_SUBDIVISION_DELETE_SUCCESS,
    EDM_SUBDIVISION_LIST_ERROR,
    EDM_SUBDIVISION_LIST_REQUEST,
    EDM_SUBDIVISION_LIST_SUCCESS,
    EDM_SUBDIVISION_UPDATE_ERROR,
    EDM_SUBDIVISION_UPDATE_REQUEST,
    EDM_SUBDIVISION_UPDATE_SUCCESS,
} from "./actions";
import {edmSubdivisionsPageDataSelector} from "./selectors";

import request from "../../../../../../utils/postman";
import {
    toastError,
    toastSuccess,
} from "../../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../../utils/url";


const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/edo/dictionaries/subdivisions",
        client: "/client-adm/edo/dictionaries/subdivisions",
    });
};

const getEdmSubdivisionsListSaga = function* ({payload}) {
    try {
        const {
            getResult = () => {
            },
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/page`, data);
        const {errorMessage} = result;

        getResult(result);

        if (errorMessage) {
            yield put({
                type: EDM_SUBDIVISION_LIST_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        console.error(error);
        yield put({
            type: EDM_SUBDIVISION_LIST_ERROR,
            payload: error,
        });
    }
};

const updateEdmSubdivisionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            subdivisionId,
            value,
        } = payload;

        const result = yield request.bff.patch(`${getController()}/${clientId}/${subdivisionId}`, {
            clientId,
            subdivisionId,
            value,
        });
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_UPDATE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_UPDATE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно изменено");
    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_UPDATE_ERROR,
            payload: error,
        });
    }
};

const getEdmSubdivisionCommonCountSaga = function* ({payload}) {
    try {
        const {
            clientId,
        } = payload;

        const state = yield select();
        const pageData = yield edmSubdivisionsPageDataSelector(state);

        const result = yield request.bff.post(`${getController()}/count`, {clientId, ...pageData});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_COMMON_COUNT_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_COMMON_COUNT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_COMMON_COUNT_ERROR,
            payload: error,
        });
    }
};

const addEdmSubdivisionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            ...data
        } = payload;

        const result = yield request.bff.post(`${getController()}/add`, data);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_ADD_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_ADD_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно добавлено");
        yield put(getEdmSubdivisionCommonCount({clientId: data.clientId}));
    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_ADD_ERROR,
            payload: error,
        });
    }
};

const deleteEdmSubdivisionSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
            clientId,
            subdivisionId,
        } = payload;

        const result = yield request.bff.delete(`${getController()}/${clientId}/${subdivisionId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: EDM_SUBDIVISION_DELETE_ERROR,
                payload: errorMessage,
            });

            return {
                done: true,
            };
        }

        yield put({
            type: EDM_SUBDIVISION_DELETE_SUCCESS,
            payload: result,
        });

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Подразделение успешно удалено");
        yield put(getEdmSubdivisionCommonCount({clientId}));
    } catch (error) {
        yield put({
            type: EDM_SUBDIVISION_DELETE_ERROR,
            payload: error,
        });
    }
};


export function* saga() {
    yield all([
        takeEvery(EDM_SUBDIVISION_LIST_REQUEST, getEdmSubdivisionsListSaga),
        takeEvery(EDM_SUBDIVISION_UPDATE_REQUEST, updateEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_DELETE_REQUEST, deleteEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_ADD_REQUEST, addEdmSubdivisionSaga),
        takeEvery(EDM_SUBDIVISION_COMMON_COUNT_REQUEST, getEdmSubdivisionCommonCountSaga),
    ]);
}