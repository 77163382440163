export const BFF_ACT_REGISTRIES_GET_PAGE_REQUEST = "BFF_ACT_REGISTRIES_GET_PAGE_REQUEST";
export const BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS = "BFF_ACT_REGISTRIES_GET_PAGE_SUCCESS";
export const BFF_ACT_REGISTRIES_GET_PAGE_ERROR = "BFF_ACT_REGISTRIES_GET_PAGE_ERROR";

export const BFF_ACT_REGISTRIES_ARCHIVE_REQUEST = "BFF_ACT_REGISTRIES_ARCHIVE_REQUEST";
export const BFF_ACT_REGISTRIES_ARCHIVE_SUCCESS = "BFF_ACT_REGISTRIES_ARCHIVE_SUCCESS";
export const BFF_ACT_REGISTRIES_ARCHIVE_ERROR = "BFF_ACT_REGISTRIES_ARCHIVE_ERROR";

export const BFF_ACT_REGISTRIES_DELETE_REQUEST = "BFF_ACT_REGISTRIES_DELETE_REQUEST";
export const BFF_ACT_REGISTRIES_DELETE_SUCCESS = "BFF_ACT_REGISTRIES_DELETE_SUCCESS";
export const BFF_ACT_REGISTRIES_DELETE_ERROR = "BFF_ACT_REGISTRIES_DELETE_ERROR";

export const BFF_ACT_REGISTRIES_UPDATE_REQUEST = "BFF_ACT_REGISTRIES_UPDATE_REQUEST";
export const BFF_ACT_REGISTRIES_UPDATE_SUCCESS = "BFF_ACT_REGISTRIES_UPDATE_SUCCESS";
export const BFF_ACT_REGISTRIES_UPDATE_ERROR = "BFF_ACT_REGISTRIES_UPDATE_ERROR";

export const BFF_ACT_REGISTRIES_ADD_REQUEST = "BFF_ACT_REGISTRIES_ADD_REQUEST";
export const BFF_ACT_REGISTRIES_ADD_SUCCESS = "BFF_ACT_REGISTRIES_ADD_SUCCESS";
export const BFF_ACT_REGISTRIES_ADD_ERROR = "BFF_ACT_REGISTRIES_ADD_ERROR";

export const BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST = "BFF_ACT_REGISTRIES_GET_BY_ID_REQUEST";
export const BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS = "BFF_ACT_REGISTRIES_GET_BY_ID_SUCCESS";
export const BFF_ACT_REGISTRIES_GET_BY_ID_ERROR = "BFF_ACT_REGISTRIES_GET_BY_ID_ERROR";

export const BFF_ACT_REGISTRIES_UPDATE_STORE = "BFF_ACT_REGISTRIES_UPDATE_STORE";