import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import NmDropdownV2 from "../NmDropdownV2";

import {useGetPrevValue} from "../../../hooks/useGetPrevValue";

import {
    OptionType,
} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import {handleFormString} from "../../../utils/stringHelper";

import {
    getOptimizedObjects,
    objectsOptimizedOptionsSelector,
    updateFieldsObjectsStore,
} from "../../../ducks/objects";

interface IFilterObject {
    onChange: (event: any, params: any) => void,
    objectIdsFilter: [],
    name: string,
    size?: "xl" | "lg",
    className?: string,
    projectIdsFilter?: [],
    clientIdsFilter?: [],
    clientId?: string,
    onSubmit?: () => void,
    isActiveStyle?: boolean,
    pageSize?: number,
    statusFilter?: string,
    placeholder?: string,
    label?: string,
    multiple?: boolean,
    listFieldName?: string,
    options?: Array<OptionType>,
    isMountLoad?: boolean,
    isOnlyWithClientIdsFilter?: boolean,
}

const FilterObject = (props: IFilterObject) => {
    const {
        projectIdsFilter,
        clientIdsFilter,
        objectIdsFilter = [],
        size = "lg",
        clientId,
        isActiveStyle = objectIdsFilter ? objectIdsFilter.length === 0 : true,
        pageSize = 10,
        statusFilter,
        placeholder = "",
        label,
        multiple = true,
        listFieldName = "optimizedObjects",
        options,
        isMountLoad = false,
        isOnlyWithClientIdsFilter = false,
    } = props;

    const prevProjectIdsFilter = useGetPrevValue(projectIdsFilter);

    const dispatch = useDispatch();
    const objectOptions = useSelector(objectsOptimizedOptionsSelector);
    const {t} = useTranslation();
    const prevClientIdsFilter = useGetPrevValue(clientIdsFilter);

    useEffect(() => {
        if (isOnlyWithClientIdsFilter && isEmpty(clientIdsFilter)) {
            !isEmpty(prevClientIdsFilter) && dispatch(updateFieldsObjectsStore({[listFieldName]: []}));

            return;
        }

        if (!prevProjectIdsFilter || !isEqual(prevProjectIdsFilter, projectIdsFilter) || clientIdsFilter?.length) {
            fetchObjects();
        }
    }, [
        projectIdsFilter,
        clientId,
        clientIdsFilter,
    ]);

    const fetchObjects = (nameSubstringFilter?: string) => {
        dispatch(getOptimizedObjects({
            clientId,
            clientIdsFilter,
            pageNum: 1,
            pageSize,
            orderType: "ASC",
            sortName: "name",
            projectIds: projectIdsFilter && projectIdsFilter.length ? projectIdsFilter : undefined,
            nameSubstringFilter: handleFormString(nameSubstringFilter),
            status: statusFilter,
            fieldName: listFieldName,
        }));
    };

    const handleOnSearchObject = (valueFilter: string) => {
        fetchObjects(valueFilter);
    };

    return (
        <NmDropdownV2
            {...props}
            placeholder={placeholder}
            value={objectIdsFilter}
            isMountLoad={isMountLoad}
            size={size}
            multiple={multiple}
            search
            label={label || t("orders-filter.object")}
            onSearchChange={handleOnSearchObject}
            options={options || objectOptions}
            isActiveStyle={isActiveStyle}
        />
    );
};

export default FilterObject;