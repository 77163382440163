import {
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
    BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_REQUEST,
} from "./actions";

export const getRecruitmentJobBoardSubscriptions = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_GET_VACANCIES_BY_ID_REQUEST,
        payload,
    };
};

export const refreshJobBoardSubscriptions = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_REFRESH_SUBSCRIBE_VACANCIES_BY_ID_REQUEST,
        payload,
    };
};

export const clearRecruitmentJobBoardsStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_JOB_BOARDS_WEBHOOK_CLEAR_STORE,
        payload,
    };
};