import {createSelector} from "reselect";

import {EDO_STAFF_FIELD_LIST} from "./reducer";

import {getTotalPages} from "../../../../../utils/mathHelper";
import {
    edoDocumentStatusesToOptions,
    edoDocumentTypesToOptions,
    edoDocumentTypesToOptionsUploadEDO, edoStaffListByClientUserToOptions, edoStaffListToOptions,
} from "../../../../../utils/objectHelper";

export const edoDocumentsSelector = state => state.bff.clientsEdoDocuments;
export const edoDocumentsListSelector = createSelector(edoDocumentsSelector, ({documents}) => documents);
export const edoDocumentsTotalPagesSelector = createSelector(edoDocumentsSelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const edoDocumentsTotalCountSelector = createSelector(edoDocumentsSelector, ({totalCount}) => totalCount);
export const edoDocumentStatusesOptionsSelector = createSelector(edoDocumentsSelector, ({statuses}) => edoDocumentStatusesToOptions(statuses, true));
export const edoDocumentTypesOptionsSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => edoDocumentTypesToOptions(list));
export const edoDocumentTypesUploadDocumentsSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => edoDocumentTypesToOptionsUploadEDO(list));
export const edoDocumentInfoCountSelector = createSelector(edoDocumentsSelector, ({documentInfoCount}) => documentInfoCount);
export const edoReceiversOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.RECEIVERS]));
export const edoReceiversByClientUserOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListByClientUserToOptions(documents[EDO_STAFF_FIELD_LIST.RECEIVERS]));
export const edoSendersOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.SENDERS]));
export const edoApproversOptionsSelector = createSelector(edoDocumentsSelector, (documents) => edoStaffListToOptions(documents[EDO_STAFF_FIELD_LIST.APPROVER]));
export const edoAccessListSelector = createSelector(edoDocumentsSelector, ({accessList}) => accessList);
// Справочники ЭДО заявления
export const edoDocumentTypesSelector = createSelector(edoDocumentsSelector, ({documentTypes: {list}}) => list);
export const edoPreFilledTemplate = createSelector(edoDocumentsSelector, ({preFilledTemplate}) => preFilledTemplate);
export const edoSignProgressSelector  = createSelector(edoDocumentsSelector, ({signProgress}) => signProgress);