import React from "react";
import {useSelector} from "react-redux";

import Text from "../../../../../components/ActualComponents/Text";
import NmStatusPassportInnContractor from "../../../../../components/NmStatusPassportInnContractor";
import NmTitle from "../../../../../components/NmTitle";
import {RequestPassportPageNumber19} from "../request-passport-page-number-19";
import RequestInnList from "../requestInnList";

import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES} from "../../constants";

import {contractorComparisonDataFormSelector} from "../../../../../ducks/bff/contractor-сard/personal-data/selectors";

const ContractorVerificationDataDocumentChecking = (props) => {
    const {
        isVisibleInnScanLoadingInfo = true,
        className,
        setInnStatus,
        onChangeRequestInnList,
        isRequestInnList,
        isShowRequestInnList,
        isShowRequestPassportPageNumber19,
        isRequestPassportPageNumber19,
        onChangeRequestPassportPageNumber19,
        contractor,
        pendingFileTypes,
        isHideRequestPassportPageNumber19,
    } = props;

    const comparisonData = useSelector(contractorComparisonDataFormSelector);
    const isInnScan = pendingFileTypes.includes(CONTRACTOR_VERIFICATION_DATE_SCAN_TYPES.INN_SCAN);
    const {
        fakeInn,
    } = contractor;

    return (
        <div className={className}>
            <NmTitle
                className="mb-4"
                size="md"
            >
                Проверка документов
            </NmTitle>
            <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row">
                <NmStatusPassportInnContractor
                    className="me-5"
                    contractor={contractor}
                    onUpdate={(newStatus) => {setInnStatus(newStatus);}}
                    isFetchContractorOnUpdate={false}
                    verificationData
                />
                {
                    isShowRequestInnList &&
                    <RequestInnList
                        className="mt-3 mt-md-0 me-5"
                        onChangeRequestInnList={onChangeRequestInnList}
                        isRequestInnList={isRequestInnList}
                        disabled={fakeInn}
                    />
                }
                {
                    isShowRequestPassportPageNumber19 &&
                    !isHideRequestPassportPageNumber19 &&
                    comparisonData.previouslyIssuedPassportsInfoCanBeRequested &&
                    <RequestPassportPageNumber19
                        className="mt-3 mt-md-0"
                        onChange={onChangeRequestPassportPageNumber19}
                        isRequestPassportPageNumber19={isRequestPassportPageNumber19}
                    />
                }
            </div>
            {
                isInnScan && isVisibleInnScanLoadingInfo &&
                <Text
                    color={COLOR.SECONDARY_45}
                    level="2"
                    className="mt-2"
                >
                    Фотография, подтверждающая ИНН, загружена исполнителем и располагается после паспорта
                </Text>
            }
            {
                fakeInn &&
                <Text
                    color={COLOR.INERT_100}
                    level="2"
                    className="mt-2"
                >
                    Недоступно, т.к. ИНН сгенерирован автоматически
                </Text>
            }
        </div>
    );
};

export default ContractorVerificationDataDocumentChecking;