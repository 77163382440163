import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {_delay} from "../../../../../utils/delay";
import {toastError} from "../../../../../utils/toastHelper";

import {NM_ADMIN_INFO} from "../../../../../constants/contractorInfo";

import {
    changeTicketManager,
    checkTicketsBeforeClose,
    closeTickets,
    getTicketRichById,
    initCallTicketManager,
    reopenTicket,
    takeInWorkTicket,
    updateTicketStore,
} from "../../../../../ducks/crm/ticket";

export default function useTicketCard({ticketId}) {
    const dispatch = useDispatch();
    const [confirmData, setConfirmData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingTakeInWork, setLoadingTakeInWork] = useState(false);
    const [loadingChangeOperator, setLoadingChangeOperator] = useState(false);
    const [isOpenTransferTicketModal, setIsOpenTransferTicketModal] = useState(false);
    const [loadingInitCall, setLoadingInitCall] = useState(false);

    useEffect(() => {
        if (!ticketId) {
            return;
        }

        setLoading(true);
        getTicket();
    }, [ticketId]);

    useEffect(() => {
        return () => {
            dispatch(updateTicketStore({
                card: {},
            }));
        };
    }, []);

    const getTicket = () => {
        dispatch(getTicketRichById({
            ticketId,
            getResult: () => _delay(() => setLoading(false)),
        }));
    };

    const openTransferTicketModal = () => {
        setIsOpenTransferTicketModal(true);
    };

    const closeTransferTicketModal = () => {
        setIsOpenTransferTicketModal(false);
    };

    const _takeInWorkTicket = () => {
        setLoadingTakeInWork(true);
        dispatch(takeInWorkTicket({
            ticketId,
            getResult: ({errorMessage}) => {
                if (!errorMessage) {
                    getTicket();
                }
                _delay(() => setLoadingTakeInWork(false));
            },
        }));
    };

    const _reopenTicket = () => {
        dispatch(reopenTicket({
            ticketId,
            getResult: (errorMessage) => {
                if (!errorMessage) {
                    getTicket();
                }
            },
        }));
    };

    const closeTicket = () => {
        dispatch(checkTicketsBeforeClose({
            ticketIds: [ticketId],
            getResult: ({errorTickets}) => {
                if (errorTickets.length) {
                    toastError(errorTickets[0].errorMessage);
                    return;
                }
                confirm();
            },
        }));
    };

    const initCall = () => {
        setLoadingInitCall(true);
        dispatch(initCallTicketManager({
            ticketId,
            getResult: () => {
                _delay(() => setLoadingInitCall(false));
                getTicket();
            },
        }));
    };

    const changeOperator = (form) => {
        setLoadingChangeOperator(true);
        dispatch(changeTicketManager({
            ticketId,
            ...form,
            managerClientId: NM_ADMIN_INFO.ID,
            getResult: () => {
                _delay(() => setLoadingChangeOperator(false));
                closeTransferTicketModal();
                getTicket();
            },
        }));
    };

    const confirm = () => {
        const confirm = () => {
            dispatch(closeTickets({
                ticketIds: [ticketId],
                getResult: (errorMessage) => {
                    cancelConfirm();
                    if (!errorMessage) {
                        getTicket();
                    }
                },
            }));
        };

        setConfirmData({
            content: "Вы уверены, что хотите закрыть обращение?",
            confirm,
        });
    };

    const cancelConfirm = () => {
        setConfirmData({});
    };

    return {
        closeTicket,
        initCall,
        confirmData,
        cancelConfirm,
        loading,
        setLoading,
        getTicket,
        reopen: _reopenTicket,
        takeInWorkTicket: _takeInWorkTicket,
        loadingTakeInWork,
        isOpenTransferTicketModal,
        openTransferTicketModal,
        closeTransferTicketModal,
        changeOperator,
        loadingChangeOperator,
        loadingInitCall,
    };
}