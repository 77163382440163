import React, {FC} from "react";
import {useTranslation} from "react-i18next";

import Text, {TTextLevel} from "../../Text";

import bem from "../../../../utils/bem";

import "./style.sass";

interface INmConfirmContent {
    warning?: boolean,
    content?: string | React.ReactNode,
    align?: "left" | "right" | "center" | "justify",
    contentTextLevel?: TTextLevel,
    inline?: boolean,
    className?: string,
}

const NmConfirmV2Content: FC<INmConfirmContent> = (props) => {
    const {
        inline = true,
        warning,
        content,
        align,
        contentTextLevel = "4",
        className = "",
    } = props;
    const [block, element] = bem("nm-confirm-content", className);
    const {t} = useTranslation();

    return (
        <div className={block({warning, align})}>
            {
                warning &&
                <Text
                    level={contentTextLevel}
                    className={element("warning", {inline})}
                >
                    {t("nm-confirm.attention")}
                    &nbsp;
                </Text>
            }
            {
                content &&
                <Text
                    level={contentTextLevel}
                    className={element("message", {inline})}
                >
                    {content}
                </Text>
            }
        </div>
    );
};

export default NmConfirmV2Content;