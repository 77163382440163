import React, {Component} from "react";
import {connect} from "react-redux";
import {isEmpty} from "lodash";

import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import OrderContractors, {ORDER_CONTRACTORS_SORT_TYPE} from "../../../components/OrderContractors";
import OrderAllApplicationsFilter from "./filter";

import bem from "../../../utils/bem";
import {getHistoryBackLink} from "../../../utils/historyNavigationhelper";
import {isAccessByRestrictions} from "../../../utils/restrictions";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {TIME_INTERVAL_PAYMENT_POSSIBILITY} from "../../../constants/contractor";
import {
    LINK_CLIENT_ORDERS_ANALYTICS,
    LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS,
} from "../../../constants/links";

import {history} from "../../../store/configureStore";

import {getAllApplications} from "../../../ducks/bff/clients/order-analytics/all-applications/actionCreators";
import {
    allApplicationsCountSelector,
    contractorsAllApplicationCountMap,
    contractorsAllApplicationsProgressSelector,
    contractorsAllApplicationsSelector,
    contractorsAllApplicationsTotalCountSelector,
    contractorsAllApplicationsTotalPagesSelector,
} from "../../../ducks/bff/clients/order-analytics/all-applications/selectors";
import {
    updateFieldContractorStore,
} from "../../../ducks/contractor";
import {
    bankCheckCancelToken,
    getContractorsPaymentPossibility,
    refreshContractorsPaymentPossibility,
} from "../../../ducks/contractorBankCheck";

import "./style.sass";

import {TAB_TYPE_BY_SUB_PAGE} from "../../../constants/link-params";

class OrderAllApplications extends Component {
    constructor(props) {
        super(props);
        const {match: {params: {clientId}}} = props;

        this.clientId = clientId;

        const [block, element] = bem("order-all-applications");

        this.element = element;
        this.block = block;

        this.state = {
            pageNum: 1,
            pageSize: 25,
            filter: {},
            historyData: {},
            sortType: ORDER_CONTRACTORS_SORT_TYPE.CREATE_DATE_DESC,
        };
    }

    get baseUrl() {
        return LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS.replace(":clientId", this.clientId);
    }

    componentDidMount() {
        this.fetchList();
        this.saveHistoryData();
    }

    componentWillUnmount() {
        const {updateFieldContractorStore} = this.props;

        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);

        updateFieldContractorStore({allApplications: [], allApplicationCountMap: {}});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params: {subpage}}} = this.props;
        const {match: {params: {subpage: _subpage}}} = prevProps;

        if (subpage !== _subpage) {
            this.setState({
                pageNum: 1,
                pageSize: 25,
            }, this.fetchList);
        }
    }

    onClickSort = ({sortType}) => {
        this.setState({
            sortType,
        }, this.fetchList);
    };

    fetchList = () => {
        const {
            pageNum,
            pageSize,
            sortType,
            filter,
        } = this.state;
        const {
            getAllApplications,
            match: {params: {subpage}},
        } = this.props;

        getAllApplications({
            clientId: this.clientId,
            tabType: TAB_TYPE_BY_SUB_PAGE[subpage].TAB_TYPE,
            sortType,
            pageNum,
            pageSize,
            ...filter,
        });
    };

    clearFilter = () => {
        this.setState({
            pageNum: 1,
            filter: {},
        }, this.fetchList);
    };

    submitFilter = filter => {
        this.setState({
            pageNum: 1,
            filter,
        }, () => {
            this.fetchList();
        });
    };

    onChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchList,
        );
    };

    refreshBankChecks = () => {
        bankCheckCancelToken.cancel();
        clearTimeout(this.checkBankPossibleTimer);

        const {list, refreshContractorsPaymentPossibility} = this.props;

        const contractorIds = [...new Set(list.map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        refreshContractorsPaymentPossibility({
            contractorIds,
            useFnsThreshold: true,
            handleResponse: this.onSuccessRefreshBankChecks,
        });
    };

    onSuccessRefreshBankChecks = ({errorMessage}) => {
        if (errorMessage) {
            return;
        }

        this.getBankChecks();
    };

    getBankChecks = () => {
        const {
            getContractorsPaymentPossibility,
            list,
        } = this.props;

        const contractorIds = [...new Set(list.map(item => item.contractorId))];

        if (isEmpty(contractorIds)) {
            return;
        }

        getContractorsPaymentPossibility({
            onRequest: () => {
                bankCheckCancelToken.cancel();
                clearTimeout(this.checkBankPossibleTimer);
            },
            handleResponse: this.onSuccessGetBankChecks,
            clientId: this.clientId,
            contractorIds,
        });
    };

    onSuccessGetBankChecks = (result = {}) => {
        const {
            hasNoResultItems,
        } = result;

        if (!hasNoResultItems) {
            return;
        }

        this.checkBankPossibleTimer = setTimeout(this.getBankChecks, TIME_INTERVAL_PAYMENT_POSSIBILITY);
    };

    saveHistoryData = () => {
        const {location} = this.props;

        const {state} = location;

        if (!state) {
            return;
        }

        const {prevPath} = state;

        if (prevPath) {
            const {objectIdsFilter, projectIdsFilter, regionsFilter} = state;

            this.setState({
                historyData: {objectIdsFilter, projectIdsFilter, regionsFilter},
            });
        }
    };

    handleOnClickBack = () => {
        const {location} = this.props;
        const {historyData} = this.state;
        const {prevPath} = historyData;

        if (prevPath) {
            const {filterData, pageData} = historyData;

            history.push(prevPath, {pageData, filterData});

            return;
        }

        const historyBackLink = getHistoryBackLink(location);

        if (historyBackLink) {
            history.push(historyBackLink);
            return;
        }

        const linkAnalytics = LINK_CLIENT_ORDERS_ANALYTICS.replace(":clientId", this.clientId);

        history.push(linkAnalytics);
    };

    render() {
        const {
            match: {params: {subpage}},
            count,
            totalPages,
            countMap,
            list,
            progress,
            totalCount,
        } = this.props;
        const {
            pageNum,
            pageSize,
            sortType,
            filter,
        } = this.state;

        return (
            <NmPage
                className={this.block()}
                header={
                    <NmPageHeader
                        handleOnClickBack={this.handleOnClickBack}
                        text="Отклики по всем заказам"
                        totalCount={count}
                    />
                }
                typeFilter="vertical"
                filtersBase={
                    <OrderAllApplicationsFilter
                        clientId={this.clientId}
                        filter={filter}
                        clearFilter={this.clearFilter}
                        submitFilter={this.submitFilter}
                    />
                }
                totalPages={totalPages}
                currentPageSize={pageSize}
                totalCount={totalCount}
                currentPageNum={pageNum}
                onChangePageSize={this.onChangePageSize}
                onPaginationChange={this.handlePaginationChange}
            >
                <OrderContractors
                    totalCount={totalCount}
                    pageSize={pageSize}
                    baseUrl={this.baseUrl}
                    fetchList={this.fetchList}
                    subpage={subpage}
                    clientId={this.clientId}
                    countMap={countMap}
                    list={list}
                    progress={progress}
                    isShowOrderInfo
                    refreshBankChecks={this.refreshBankChecks}
                    getBankChecks={this.getBankChecks}
                    onClickSort={this.onClickSort}
                    sortType={sortType}
                    isAnalyticsPage={true}
                />
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        count: allApplicationsCountSelector(state),
        totalPages: contractorsAllApplicationsTotalPagesSelector(state),
        countMap: contractorsAllApplicationCountMap(state),
        list: contractorsAllApplicationsSelector(state),
        progress: contractorsAllApplicationsProgressSelector(state),
        location: state.router.location,
        totalCount: contractorsAllApplicationsTotalCountSelector(state),
    }),
    {
        getAllApplications,
        getContractorsPaymentPossibility,
        refreshContractorsPaymentPossibility,
        updateFieldContractorStore,

    },
)(OrderAllApplications);