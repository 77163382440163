import React from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../../components/ActualComponents/Text";

import {getUserRestrictions, getUserRole} from "../../../../utils/access";
import dateFormat from "../../../../utils/dateFormat";
import {isAccessByRestrictions} from "../../../../utils/restrictions";
import {toastError, toastSuccess} from "../../../../utils/toastHelper";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../constants/color";
import {
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS,
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_CHECK_RESULT_MESSAGE,
    CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DICT,
} from "../../../../constants/contractor";
import {ADMIN, NM_MANAGER, NM_OPERATOR} from "../../../../constants/roles";

import {
    deleteContractorIndividualEntrepreneurData,
    updateContractorIndividualEntrepreneurStatus,
} from "../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {contractorPersonalDataProgressActionSelector} from "../../../../ducks/bff/contractor-сard/personal-data/selectors";

export const useContractorIndividualEntrepreneurCard = (props) => {
    const {
        contractor,
        onOpenModal = () => {},
        onCloseModal = () => {},
        fetchContractorInfo = () => {},
    } = props;

    const progressAction = useSelector(contractorPersonalDataProgressActionSelector);

    const {
        contractorId,
        individualEntrepreneurStatus,
        individualEntrepreneurLegalInfo = {},
        individualEntrepreneurBankRequisitesModel = {},
    } = contractor;

    const dispatch = useDispatch();

    const role = getUserRole();
    const isAccessEdit = [ADMIN, NM_MANAGER, NM_OPERATOR].includes(role)
        && isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractors,
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsContractorIndividual,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsContractorIndividual,
        ]);
    const isNotConnected = individualEntrepreneurStatus === CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.NOT_CONNECTED;

    const updateStatus = () => {
        dispatch(updateContractorIndividualEntrepreneurStatus({
            contractorId,
            getResult: ({result, errorMessage}) => {
                if (errorMessage) {
                    toastError(errorMessage);

                    return;
                }

                if (result.individualEntrepreneurStatus) {
                    toastSuccess(CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_CHECK_RESULT_MESSAGE[result.individualEntrepreneurStatus]);
                    fetchContractorInfo();
                }
            },
        }));
    };

    const deleteData = () => {
        dispatch(deleteContractorIndividualEntrepreneurData({
            contractorId,
            onSuccess: () => {
                toastSuccess("Данные ИП удалены из Наймикс");
                onCloseModal();
            },
        }));
    };

    return {
        card: {
            className: "mt-4",
            isIndividualEntrepreneurStatusBlock: true,
            isHeaderDivider: !isNotConnected,
            title: <div className="flex">
                Статус ИП:
                <Text
                    className="ms-2"
                    color={COLOR.SECONDARY_45}
                    bold={true}
                >
                    {(CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS_DICT[individualEntrepreneurStatus] || "").toLowerCase()}
                </Text>
            </div>,
            labels: !isNotConnected &&
                [
                    {blockName: "Данные ИП"},
                    {label: "ОГРНИП", text: individualEntrepreneurLegalInfo.ogrnip},
                    {
                        label: "Дата выдачи",
                        text: dateFormat(individualEntrepreneurLegalInfo.registrationIssuedDateTime, "dd.MM.yyyy"),
                    },
                    {label: "Орган выдачи", text: individualEntrepreneurLegalInfo.registrationIssuedBy},
                    {blockName: "Реквизиты для получения платежей ИП"},
                    {label: "Номер расчетного счета", text: individualEntrepreneurBankRequisitesModel.rs},
                    {label: "Наименование банка", text: individualEntrepreneurBankRequisitesModel.bankName},
                    {label: "ИНН", text: individualEntrepreneurBankRequisitesModel.inn},
                    {label: "БИК", text: individualEntrepreneurBankRequisitesModel.bic},
                    {label: "Адрес банка", text: individualEntrepreneurBankRequisitesModel.bankAddress},
                    {label: "Корр. счет банка", text: individualEntrepreneurBankRequisitesModel.ks},
                ],
            mediaControlsButtons: [
                {
                    onClick: updateStatus,
                    size: "sm",
                    color: "grey",
                    children: "Запросить статус ИП",
                    disabled: progressAction,
                    visible: isAccessEdit,
                },
                {
                    onClick: () => onOpenModal({
                        isOpenIndividualEntrepreneurDataEditForm: true,
                    }),
                    children: "Редактировать данные ИП",
                    disabled: progressAction,
                    visible: isAccessEdit,
                },
                {
                    onClick: () => onOpenModal({
                        isOpenConfirm: true,
                        content: "Вы хотите удалить данные ИП с площадки Наймикс? Работа с оплатами ИП будет невозможна",
                        onConfirm: deleteData,
                        confirmButton: "Подтверждаю",
                        cancelButton: "Отмена",
                    }),
                    children: "Удалить данные ИП",
                    disabled: progressAction,
                    visible: isAccessEdit
                        && [
                            CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED,
                            CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID,
                        ].includes(individualEntrepreneurStatus),
                },
                {
                    onClick: () => onOpenModal({
                        isOpenIndividualEntrepreneurPaymentDataEditForm: true,
                    }),
                    children: "Редактировать реквизиты для получения платежей",
                    disabled: progressAction,
                    visible: isAccessEdit
                        && [
                            CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.CONNECTED,
                            CONTRACTOR_INDIVIDUAL_ENTREPRENEUR_STATUS.INVALID,
                        ].includes(individualEntrepreneurStatus),
                },
            ],
            mediaControlsRenderCount: {
                desktop: 1,
                tablet: 0,
                mobile: 0,
            },
            isVisible: true,
            isEditable: false,
        },
    };
};