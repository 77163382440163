import {
    EDM_ADD_STAFF_REQUEST,
    EDM_ARCHIVE_STAFF_REQUEST,
    EDM_GET_STAFF_LIST_REQUEST,
    EDM_GET_STAFF_REQUEST,
    EDM_STAFF_EXPORT_REQUEST,
    EDM_STAFF_FIND_CONTRACTORS_REQUEST,
    EDM_STAFF_FIND_USERS_REQUEST,
    EDM_STAFF_IMPORT_REQUEST,
    EDM_STAFF_USER_EXIST_REQUEST,
    EDM_UPDATE_STAFF_REQUEST,
} from "./actions";

export function getEdmStaffById(payload) {
    return {
        type: EDM_GET_STAFF_REQUEST,
        payload,
    };
}

export function getEdmStaffList(payload) {
    return {
        type: EDM_GET_STAFF_LIST_REQUEST,
        payload,
    };
}

export function addEdmStaff(payload) {
    return {
        type: EDM_ADD_STAFF_REQUEST,
        payload,
    };
}

export function updateEdmStaff(payload) {
    return {
        type: EDM_UPDATE_STAFF_REQUEST,
        payload,
    };
}

export function archiveEdmStaff(payload) {
    return {
        type: EDM_ARCHIVE_STAFF_REQUEST,
        payload,
    };
}

export function findContractorsForEdmStaff(payload) {
    return {
        type: EDM_STAFF_FIND_CONTRACTORS_REQUEST,
        payload,
    };
}

export function findUsersForEdmStaff(payload) {
    return {
        type: EDM_STAFF_FIND_USERS_REQUEST,
        payload,
    };
}

export function importEdmStaffs(payload) {
    return {
        type: EDM_STAFF_IMPORT_REQUEST,
        payload,
    };
}

export function edmStaffUserExist(payload) {
    return {
        type: EDM_STAFF_USER_EXIST_REQUEST,
        payload,
    };
}

export function exportEdmStaff(payload) {
    return {
        type: EDM_STAFF_EXPORT_REQUEST,
        payload,
    };
}