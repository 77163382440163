import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import HelpTooltip from "../ActualComponents/HelpTooltip";
import NmCheckboxV2 from "../ActualComponents/NmCheckboxV2";
import NmDropdownV2 from "../ActualComponents/NmDropdownV2";
import NmForm from "../ActualComponents/NmForm";
import NmInputV2 from "../ActualComponents/NmInputV2";
import NmModal from "../ActualComponents/NmModal";
import ApplyButtons from "../ApplyButtons";
import NmTitle from "../NmTitle";
import {ObjectsDropdown} from "../ObjectsDropdown";
import {ProjectsDropdown} from "../ProjectsDropdown";

import {dictionaryToOptions} from "../../utils/objectHelper";
import {getSameBankTypeCard, isClientHasDifferentPaymentMethods} from "../../utils/paymentMethodHelper";
import {getFormAmount} from "../../utils/stringFormat";
import validate from "../../utils/validate";

import {ORDER_WORK_REPORT_TYPE} from "../../constants/finance";
import {OBJECT_STATUS_DICT} from "../../constants/objects";
import {patternNumber} from "../../constants/order";
import {PAYMENT_TYPE_TRANSLATE} from "../../constants/settings";
import {invoiceOfDepositReplenishmentRule, requiredMessage} from "../../constants/validationRules";

import {clientCardPropertiesSelector} from "../../ducks/bff/clients/info/selectors";
import {getClientsSettingsPayments, updateStoreClientsSetting} from "../../ducks/bff/clients/settings/actionCreators";
import {
    bffClientsSettingsPaymentsProgressSelector,
    bffClientsSettingsPaymentsSelector,
} from "../../ducks/bff/clients/settings/selectors";
import {
    bffCommonOrderObjectsOptionsSelector, bffCommonOrderProjectsOptionsSelector,
} from "../../ducks/bff/common/order/selectors";

const CreateDepositBaseInvoice = (props) => {
    const {
        onClose,
        onSubmit,
        accountNumber,
        clientId,
        isNdfl,
    } = props;

    const objectOptions = useSelector(bffCommonOrderObjectsOptionsSelector);
    const projectOptions = useSelector(bffCommonOrderProjectsOptionsSelector);
    const {
        enableIndividualProjectAndObjectDeposit,
    } = useSelector(clientCardPropertiesSelector);
    const clientSettingsPayments = useSelector(bffClientsSettingsPaymentsSelector);
    const clientSettingsPaymentsProgress = useSelector(bffClientsSettingsPaymentsProgressSelector);
    const {t} = useTranslation();

    const [form, setForm] = useState({
        account: isNdfl ? accountNumber : undefined,
        sum: "",
        objectId: "",
        projectId: "",
        isAutoDeposit: false,
        isProjectAutoDeposit: false,
    });
    const [error, setError] = useState({});
    const [progress, setProgress] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setForm((prevState) => {
            return {
                ...prevState,
                account: accountNumber,
            };
        });
    }, [accountNumber]);

    useEffect(() => {
        if (
            form.isProjectAutoDeposit
            && projectOptions.length === 1
            && !form.projectId
        ) {

            setForm((prevState) => {
                return {
                    ...prevState,
                    projectId: projectOptions[0].value,
                };
            });
        }
    }, [projectOptions]);

    useEffect(() => {
        if (
            form.isAutoDeposit
            && objectOptions.length === 1
            && !form.objectId
        ) {
            setForm((prevState) => {
                return {
                    ...prevState,
                    objectId: objectOptions[0].value,
                };
            });
        }
    }, [objectOptions]);

    useEffect(() => {
        dispatch(getClientsSettingsPayments({clientId}));

        return () => {
            dispatch(updateStoreClientsSetting({payments: {}}));
        };
    }, []);

    // Видимость элемента формы "Проведение оплаты"
    const isVisibleOrdersSource = useMemo(() => {
        return isClientHasDifferentPaymentMethods(clientSettingsPayments, isNdfl);
    }, [
        clientSettingsPayments,
    ]);

    const validateForm = () => {
        const orderSourceRule = isVisibleOrdersSource
            ? {
                orderSource: {
                    required: requiredMessage,
                },
            }
            : {};
        const projectRule = form.isProjectAutoDeposit
            ? {
                projectId: {
                    required: requiredMessage,
                },
            }
            : {};
        const objectRule = form.isAutoDeposit
            ? {
                objectId: {
                    required: requiredMessage,
                },
            }
            : {};
        const accountRule = isNdfl
            ? {
                required: requiredMessage,
                length: {
                    value: 20,
                    message: "Номер счета должен состоять из 20 символов",
                },
            }
            : {};

        const rule = {
            ...invoiceOfDepositReplenishmentRule,
            ...accountRule,
            ...objectRule,
            ...projectRule,
            ...orderSourceRule,
        };

        const errorForm = validate(form, rule, "");

        setError(errorForm);

        return Object.values(errorForm).length === 0;
    };

    const onChange = (_event, {name, value, checked}) => {
        const _state = {};

        if (name === "isProjectAutoDeposit" && !checked) {
            _state.projectId = "";
        }

        if (name === "isAutoDeposit" && checked && form.isProjectAutoDeposit) {
            _state.isProjectAutoDeposit = false;
            _state.projectId = "";
        }

        if (name === "isProjectAutoDeposit" && checked && form.isAutoDeposit) {
            _state.isAutoDeposit = false;
            _state.objectId = false;
        }

        if (name === "isAutoDeposit" && !checked) {
            _state.objectId = "";
        }

        if (name === "isAutoDeposit" && checked && objectOptions.length === 1) {
            _state.objectId = objectOptions[0]?.value;
        }

        setForm((prevState) => {
            return {
                ...prevState,
                ..._state,
                [name]: value || checked,
            };
        });
    };

    const _onSubmit = () => {
        const isValid = validateForm();

        if (!isValid) {
            return;
        }

        setProgress(true);

        const sameBankTypeCard = getSameBankTypeCard({
            paymentsSettings: clientSettingsPayments,
            isNdfl,
        });

        const {
            account,
            sum,
            objectId,
            projectId,
            orderSource,
        } = form;

        onSubmit({
            clientId,
            sum: getFormAmount(sum),
            depositAccountBank: account ? account : undefined,
            objectId: objectId ? objectId : undefined,
            projectId: projectId ? projectId : undefined,
            orderSource,
            bankType: sameBankTypeCard ? sameBankTypeCard : undefined,
            onSuccess: () => {
                setProgress(false);
                onClose();
            },
            onError: () => {
                setProgress(false);
            },
        });
    };

    const depositType = isNdfl ? "НДФЛ" : "НПД";

    const getProjectObjectParams = () => {
        const tooltipText = "При поступлении указанной суммы средств на депозит компании в течение 10 дней с " +
            "момента создания счет-основания произойдет автоматическое пополнение депозита выбранного :target. " +
            "Иначе денежные средства останутся в нераспределенном депозите компании.";

        return (
            <>
                <NmCheckboxV2
                    className="mt-2 mb-2"
                    label="Автоматически пополнять депозит проекта"
                    checked={form.isProjectAutoDeposit}
                    name="isProjectAutoDeposit"
                    onChange={onChange}
                    isVisibleTooltip
                    tooltip={
                        <HelpTooltip
                            info
                            position="right"
                            text={tooltipText.replace(":target", "проекта")}
                        />
                    }
                />
                {
                    form.isProjectAutoDeposit &&
                    <ProjectsDropdown
                        required={true}
                        search
                        placeholder="Выберите проект"
                        label="Проект"
                        name="projectId"
                        onChange={onChange}
                        value={form.projectId}
                        error={error.projectId}
                        filters={{
                            clientId,
                            enableIndividualDeposit: true,
                        }}
                    />
                }
                <NmCheckboxV2
                    className="mt-2 mb-2"
                    label="Автоматически пополнять депозит объекта"
                    checked={form.isAutoDeposit}
                    name="isAutoDeposit"
                    onChange={onChange}
                    isVisibleTooltip
                    tooltip={
                        <HelpTooltip
                            info
                            position="right"
                            text={tooltipText.replace(":target", "объекта")}
                        />
                    }
                />
                {
                    form.isAutoDeposit &&
                    <ObjectsDropdown
                        required={true}
                        search
                        placeholder="Выберите объект"
                        label="Объект"
                        name="objectId"
                        onChange={onChange}
                        value={form.objectId}
                        error={error.objectId}
                        filters={{
                            clientId,
                            status: OBJECT_STATUS_DICT.IN_WORK.VALUE,
                            enableIndividualDeposit: true,
                        }}
                    />
                }
            </>
        );
    };

    return (
        <NmModal
            size="md"
            header={
                <NmTitle size="lg">
                    {`Счет-основание на пополнение депозита ${depositType}`}
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    disabled={progress}
                    submit={_onSubmit}
                    mobile="column"
                    isHiddenCancelOnMobile
                    submitBtnContent={t("button.to-form")}
                    onClose={onClose}
                    cancelBtnContent={t("button.cancel")}
                />
            }
            children={
                !clientSettingsPaymentsProgress &&
                !isEmpty(clientSettingsPayments) &&
                <NmForm>
                    {
                        isVisibleOrdersSource &&
                        <NmDropdownV2
                            required
                            className="mb-3 mb-md-4"
                            label="Проведение оплаты"
                            placeholder="Выберите способ проведения оплаты"
                            name="orderSource"
                            options={dictionaryToOptions(PAYMENT_TYPE_TRANSLATE)}
                            value={form.orderSource}
                            onChange={onChange}
                            error={error.orderSource}
                        />
                    }
                    {
                        isNdfl &&
                        <NmInputV2
                            className="mb-3 mb-md-4"
                            size="xl"
                            label={t("patents.accountNumber")}
                            error={error.account}
                            name="account"
                            value={form.account}
                            mask="99999999999999999999"
                            maskChar={null}
                            onChange={onChange}
                            disabled={accountNumber}
                            required
                        />
                    }
                    <NmInputV2
                        size="xl"
                        label={t("patents.sum")}
                        error={error.sum}
                        name="sum"
                        value={form.sum}
                        pattern={patternNumber}
                        onChange={onChange}
                        required
                    />
                    {
                        enableIndividualProjectAndObjectDeposit &&
                        getProjectObjectParams()
                    }
                </NmForm>
            }
            onClose={onClose}
            loading={clientSettingsPaymentsProgress}
        />
    );
};

export default CreateDepositBaseInvoice;