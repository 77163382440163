import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import ButtonArchive from "../../../components/ButtonArchive";
import CheckboxList from "../../../components/CheckboxList";
import ClientTree from "../../../components/ClientTreeModal";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import NmTitle from "../../../components/NmTitle";
import UserFilter from "../../../components/UserFilter";
import {withPageData} from "../../../components/withPageData";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import {ReactComponent as PenIcon} from "../../../images/pen.svg";
import ClientMemberNew from "../client_member_new";

import {
    isAccessEditMemberByRole,
    isAccessEditMemberByUserId,
} from "../../../utils/access";
import {
    ls,
    USER_ROLE,
} from "../../../utils/localstorage";
import {replacer} from "../../../utils/replacer";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {
    phoneFormat,
    removePhoneMask,
} from "../../../utils/stringFormat";
import {
    handleFormString,
    isNullOrWhitespace,
} from "../../../utils/stringHelper";
import {getBffControllerClientCardPage} from "../../../utils/url";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_SETTING_TYPE} from "../../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {headersClientMemberList} from "../../../constants/headersTable";
import {LINK_CLIENT_MEMBERS_CARD} from "../../../constants/links";
import {CLIENT_MEMBER_ARCHIVE} from "../../../constants/messages";
import {
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    clientRoleOptions,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_OPERATOR,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    ROLE_DICT,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    archiveMember,
    getClientMemberList,
    updateFieldClientMemberStore,
} from "../../../ducks/bff/clients/employees/actionCreators";
import {
    clientMemberListSelector,
    clientMemberProgressListSelector,
    clientMemberTotalCountSelector,
    clientMemberTotalPagesSelector,
} from "../../../ducks/bff/clients/employees/selectors";
import {clientCardInfoSelector} from "../../../ducks/bff/clients/info/selectors";

import {
    memberFormattedType,
    memberType,
} from "../../../types";
import PropTypes from "prop-types";

import "./style.sass";

const initFilter = {
    roleListFilter: [],
    accessibleProjectIdsFilter: [],
    accessibleObjectFilter: [],
    clientUserPositionFilter: "",
    emailFilter: "",
    phoneFilter: "",
    fioFilter: "",
};

class ClientMemberList extends Component {
    title = "Сотрудники";

    static propTypes = {
        totalPages: PropTypes.number,
        selectedClientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isEditable: PropTypes.bool,
        list: PropTypes.arrayOf(memberType),
        formattedList: PropTypes.arrayOf(memberFormattedType),
        progressList: PropTypes.bool,
        getClientMemberList: PropTypes.func,
    };

    static defaultProps = {
        totalPages: 0,
        selectedClientId: "",
        isEditable: false,
        list: [],
        formattedList: [],
        progressList: false,
        getClientMemberList: () => {
        },
    };

    pageSizes = [25, 50, 100];

    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 25,
            isOpenCreateForm: false,
            repeatPassword: "",
            isEditMember: false,
            archiveFilter: false,
            editMember: {},
            selectedList: [],
            formattedList: [],
            isOpenConfirm: false,
            item: {},
            fullNameMember: "",
            confirmText: "",
            sortName: "fioSort",
            sortType: "asc",
            headers: [...headersClientMemberList],
            filter: {
                ...initFilter,
            },
            previousPageNum: 1,
        };

        this.role = ls(USER_ROLE);
    }

    componentDidMount() {
        const {
            pageNum,
            pageSize,
        } = this.props;

        if (pageNum) {
            this.setState(prevState => ({
                ...prevState,
                pageNum,
                pageSize,
            }), this.fetchList);

            return;
        }

        this.fetchList();
    }

    componentWillUnmount() {
        const {
            updateFieldClientMemberStore,
        } = this.props;

        updateFieldClientMemberStore({list: []});
    }

    get isAccessAddEditArchiveActions() {
        return isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_actionsClientsUsersAddEditArchive,
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsWithClientsUser,
        ]);
    }

    fetchList = () => {
        const {
            getClientMemberList,
            clientId,
        } = this.props;
        const {
            pageNum,
            pageSize,
            archiveFilter,
            sortName,
            sortType,
            filter: {
                phoneFilter,
                roleListFilter,
                fioFilter,
                clientUserPositionFilter,
                accessibleProjectIdsFilter,
                accessibleObjectFilter,
                emailFilter,
            },
        } = this.state;

        getClientMemberList({
            clientId,
            pageNum,
            pageSize,
            archiveFilter,
            [sortName]: sortType,
            needToEnrichPhantomUsersByClientInfo: true,
            fioFilter: handleFormString(fioFilter),
            phoneFilter: isNullOrWhitespace(phoneFilter) ? undefined : removePhoneMask(phoneFilter),
            roleListFilter: roleListFilter.length ? roleListFilter : undefined,
            clientUserPositionFilter: handleFormString(clientUserPositionFilter),
            accessibleProjectIdsFilter: accessibleProjectIdsFilter.length ? accessibleProjectIdsFilter : undefined,
            accessibleObjectFilter: accessibleObjectFilter.length ? accessibleObjectFilter : undefined,
            emailFilter: handleFormString(emailFilter),
        });
    };

    handleChangePageSize = pageSize => {
        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchList,
        );
    };

    handlePaginationChange = (e, {"activePage": pageNum}) => {
        const {"pageNum": pageNumOld} = this.state;
        if (pageNum === pageNumOld) {
            return;
        }

        this.setState({
            pageNum,
        }, this.fetchList);
    };

    archiveMember = ({clientUserId, archived, clientId}) => {
        const {archiveMember} = this.props;

        archiveMember({
            clientUserId,
            clientId,
            archive: !archived,
        });

        this.handleCancelConfirm();
    };

    getClientName = ({clientName, clientBrand}) => {
        if (!clientName) {
            return "";
        }

        const brand = clientBrand ? `(${clientBrand})` : "";

        return `(${clientName}${brand})`;
    };

    closeCreateForm = () => {
        this.setState({
            isOpenCreateForm: false,
            isEditMember: false,
            editMember: {},
        });
    };

    openCreateForm = () => {
        this.setState({
            isOpenCreateForm: true,
        });
    };

    onClickEditRow = (clientUser) => {
        const {clientId, location} = this.props;
        const {pageNum, pageSize} = this.state;

        const state = {prevPath: location.pathname, pageData: {pageNum, pageSize}};
        const to = LINK_CLIENT_MEMBERS_CARD
            .replace(":clientId", clientId)
            .replace(":clientContractorId", clientUser.clientUserId);

        history.push({pathname: to, state});
    };

    renderNoMember() {
        const {archiveFilter} = this.state;
        const {t} = this.props;

        return (
            <div className="client-member-list_no-member">
                <span className="client-member-list_no-member-text">
                    {!archiveFilter ? t("client-member.first-client-member") : t("client-member.no-records-found")}
                </span>
            </div>
        );
    }

    toggleArchived = () => {
        this.setState(prevState => ({
            ...prevState,
            archiveFilter: !prevState.archiveFilter,
            pageNum: 1,
        }), this.fetchList);
    };

    showConfirmWindow = (item) => {
        const confirmText = item.archived ? CLIENT_MEMBER_ARCHIVE.FROM : CLIENT_MEMBER_ARCHIVE.TO;
        const fullNameMember = `${item.lastName} ${item.firstName} ${item.patronymic || ""}`;

        this.setState({
            isOpenConfirm: true,
            fullNameMember,
            confirmText,
            item,
        });
    };

    handleCancelConfirm = () => {
        this.setState({
            isOpenConfirm: false,
            item: {},
            fullNameMember: "",
            confirmText: "",
            confirmProps: null,
        });
    };

    renderConfirmWindow() {
        const {
            isOpenConfirm,
            confirmText,
            fullNameMember,
            item,
            confirmProps,
        } = this.state;

        if (isOpenConfirm && confirmProps) {
            return (
                <NmConfirmV2
                    content={confirmProps.content}
                    confirmButton={confirmProps.confirmButton}
                    cancelButton={confirmProps.cancelButton}
                    onCancel={this.handleCancelConfirm}
                    onConfirm={confirmProps.onConfirm}
                />
            );
        }

        return (
            isOpenConfirm &&
            <NmConfirmV2
                content={replacer(":name", confirmText, fullNameMember)}
                confirmButton="Да"
                cancelButton="Нет"
                onCancel={this.handleCancelConfirm}
                onConfirm={() => this.archiveMember(item)}
            />
        );
    }

    renderButtonAddClientMember() {
        const {
            isOpenCreateForm,
            archiveFilter,
        } = this.state;
        const {
            isEditable,
            t,
        } = this.props;

        if (
            archiveFilter
            || !this.isAccessAddEditArchiveActions
        ) {
            return null;
        }

        return (
            (isEditable && ![CLIENT_ACCOUNTANT, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR].includes(this.role)) ?
                <NmButton
                    size="xl"
                    className="client-member-list__add"
                    icon={<AddIcon />}
                    onClick={isOpenCreateForm ? this.closeCreateForm : this.openCreateForm}
                >
                    {t("client-member.add-client-member-button")}
                </NmButton>
                : null
        );
    }

    submitFilter = (filter, isFilter) => {
        this.setState(isFilter ? {
            previousPageNum: this.state.pageNum,
            pageNum: 1,
        } : {pageNum: this.state.previousPageNum, previousPageNum: 1});
        this.setState({filter}, this.fetchList);
    };

    getRows() {
        const {
            list,
            client,
        } = this.props;

        const isAccessChatsSettingsAction = isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.actionsWithClientsUser,
            CLIENT_USER_RESTRICTIONS_VARIABLE.CLNT_accessSettingAccessToChats,
        ]);

        return list.map(item => {
            const fullName = `${item.lastName} ${item.firstName} ${item.patronymic || ""}`;
            const partnerPromotionClientList = item.partnerPromotionClientList?.map((p) => `${p.name} (${p.requisites})`) || [];
            const partnerPromotionContractorList = item.partnerPromotionContractorList?.map((p) => `${p.name} (${p.requisites})`) || [];
            const isAccessEdit = isAccessEditMemberByRole(item.role) && !client?.archived;

            const archiveAsset = {children: !item.archived ? "В архив" : "Из архива"};

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        noDivider
                        primaryHeader={`${fullName} ${this.getClientName(item)}`}
                        otherContent={<div>
                            {
                                item.position &&
                                <NmLabelText
                                    className="mb-2"
                                    label="Должность"
                                    text={item.position}
                                />
                            }
                            <NmLabelText
                                className="mb-2"
                                label="Номер телефона"
                                text={phoneFormat(item.phone)}
                            />
                            <NmLabelText
                                className="mb-2"
                                label="Email"
                                text={item.email}
                            />
                            <NmLabelText
                                className={!partnerPromotionClientList.length && !partnerPromotionContractorList.length ? "" : "mb-2"}
                                label="Роль"
                                text={ROLE_DICT[item.role]?.TEXT}
                            />
                        </div>}
                        isFixedActions
                        mediaControls={{
                            renderCount: {
                                desktop: 2,
                                tablet: 1,
                                mobile: 0,
                            },
                            buttons: [
                                {
                                    component: COMPONENT.BUTTON,
                                    visible: !item.archived
                                        && !item.relatedClientId
                                        && isAccessEdit
                                        && this.isAccessAddEditArchiveActions,
                                    props: {
                                        children: <PenIcon />,
                                        size: "lg",
                                        color: "grey",
                                        onlyIcon: true,
                                        onClick: () => this.onClickEditRow(item),
                                    },
                                    asset: {
                                        mobile: {children: "Редактировать"},
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    visible: isAccessEdit
                                        && !item.relatedClientId
                                        && isAccessEditMemberByUserId(item.clientUserId)
                                        && this.isAccessAddEditArchiveActions,
                                    props: {
                                        children: (
                                            <span
                                                className="material-icons notranslate"
                                            >
                                                {!item.archived ? "archive" : "unarchive"}
                                            </span>
                                        ),
                                        onlyIcon: true,
                                        size: "lg",
                                        color: "grey",
                                        onClick: () => this.showConfirmWindow(item),
                                    },
                                    asset: {
                                        tablet: archiveAsset,
                                        mobile: archiveAsset,
                                    },
                                },
                                {
                                    component: COMPONENT.BUTTON,
                                    visible: !item.archived
                                        && isAccessChatsSettingsAction
                                        && [
                                            CLIENT_ADMIN,
                                            PROJECT_MANAGER,
                                            OBJECT_MANAGER,
                                            FOREMAN,
                                        ].includes(item.role)
                                        && ![
                                            CLIENT_ACCOUNTANT,
                                            NM_CHIEF_ACCOUNTANT,
                                            NM_COORDINATOR,
                                            NM_CONSULTANT,
                                            NM_OPERATOR,
                                        ].includes(this.role),
                                    props: {
                                        children: "Настроить доступ в чаты заказов",
                                        onClick: () => {
                                            this.setState({
                                                treeSettings: {
                                                    settingsType: CLIENT_SETTING_TYPE.ACCESS_TO_ORDER_CHATS,
                                                    modalTitle: "Доступ в чаты заказов",
                                                    buttonName: "Применить",
                                                    clientUserId: item.clientUserId,
                                                    clientId: item.clientId,
                                                },
                                            });
                                        },
                                    },
                                },
                            ],
                        }}
                    />
                ),
            };
        });
    }

    getSortOptions() {
        const {sortType} = this.state;

        return [
            {
                key: "date",
                value: "date",
                sortType,
                text: "По дате добавления",
                asc: "asc",
                desc: "desc",
                sortName: "dateSort",
            },
            {
                key: "fio",
                value: "fio",
                sortType,
                text: "По ФИО",
                isDefaultSort: true,
                asc: "asc",
                desc: "desc",
                sortName: "fioSort",
            },
        ];
    }

    handleClickSort = ({sortType, sortName}) => {
        this.setState({
            sortName,
            sortType,
        }, this.fetchList);
    };

    renderTreeSettingsModal() {
        const {treeSettings} = this.state;

        if (!treeSettings) {
            return null;
        }

        const controller = getBffControllerClientCardPage({
            admin: "/adm/clients/client-card/client/employees",
            client: "/client-adm/client/employees",
        });

        return (
            <ClientTree
                clientId={treeSettings.clientId}
                settingsType={treeSettings.settingsType}
                modalTitle={treeSettings.modalTitle}
                buttonName={treeSettings.buttonName}
                clientUserId={treeSettings.clientUserId}
                controller={controller}
                onClose={() => {
                    this.setState({
                        treeSettings: null,
                    });
                }}
            />
        );
    }

    render() {
        const {
            isOpenCreateForm,
            pageNum,
            pageSize,
            archiveFilter,
            filter,
        } = this.state;
        const {
            selectedClientId,
            progressList,
            list,
            totalPages,
            totalCount,
            t,
            clientId,
        } = this.props;

        if (!selectedClientId) {
            return null;
        }

        return (
            <NmPage
                header={
                    <NmTitle
                        className="client-member-list__title"
                        count={totalCount}
                        size="xl"
                    >
                        {t("settings.staff")}
                    </NmTitle>
                }
                controls={
                    <>
                        {
                            this.renderButtonAddClientMember()
                        }
                        <ButtonArchive
                            onClick={this.toggleArchived}
                            archivedFilter={archiveFilter}
                        />
                    </>
                }
                openFilter
                typeFilter="vertical"
                widthByFilter
                filtersBase={
                    <UserFilter
                        currentRole={this.role}
                        roleOptions={clientRoleOptions}
                        clientId={clientId}
                        initFilter={initFilter}
                        filter={filter}
                        submitFilter={this.submitFilter}
                    />
                }
                className="client-member-list"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                isLoaded={progressList}
            >
                {this.renderConfirmWindow()}
                {this.renderTreeSettingsModal()}
                {
                    isOpenCreateForm &&
                    <ClientMemberNew
                        onCloseModel={this.closeCreateForm}
                        selectedClientId={selectedClientId}
                        fetchList={this.fetchList}
                    />
                }
                <div className="client-member-list-body">
                    {!list.length && !progressList ? this.renderNoMember()
                        : <>
                            <div className="client-member-list-body-table">
                                <CheckboxList
                                    sort
                                    sortOptions={this.getSortOptions()}
                                    onClickSort={this.handleClickSort}
                                    rows={this.getRows()}
                                />
                            </div>
                        </>
                    }
                </div>
            </NmPage>
        );
    }
}


export default withPageData(connect(
    state => ({
        location: state.router.location,
        list: clientMemberListSelector(state),
        totalCount: clientMemberTotalCountSelector(state),
        totalPages: clientMemberTotalPagesSelector(state),
        client: clientCardInfoSelector(state),
        progressList: clientMemberProgressListSelector(state),
    }),
    {
        updateFieldClientMemberStore,
        getClientMemberList,
        archiveMember,

    },
)(withTranslation()(ClientMemberList)));
