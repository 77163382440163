import React from "react";
import {useDispatch, useSelector} from "react-redux";

import Text from "../../../../../components/ActualComponents/Text";
import NmButton from "../../../../../components/NmButton";
import ContractorInfoBlock from "../block";

import {ls, USER_ROLE} from "../../../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../../../utils/restrictions";
import {toastError} from "../../../../../utils/toastHelper";
import {getContractorCheckOnInfo} from "../../utils/getCheckOnInfo";
import {handleContractorCheckOnError} from "../../utils/handleCheckOnError";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../../constants/clientUserRestrictions";
import {COLOR} from "../../../../../constants/color";
import {CONTRACTOR_STATUS} from "../../../../../constants/contractor";
import {LINK_CONTRACTOR_VERIFICATION_DATA} from "../../../../../constants/links";
import {ADMIN} from "../../../../../constants/roles";

import {history} from "../../../../../store/configureStore";

import {getInfoContractorCard} from "../../../../../ducks/bff/contractor-сard/actionCreators";
import {
    endCheckOnContractorPersonalDataStatus,
    getPersonalDataPendingComparisonAvailability,
} from "../../../../../ducks/bff/contractor-сard/personal-data/actionCreators";
import {
    contractorActionProgressCheckOnEndSelector,
} from "../../../../../ducks/bff/contractor-сard/personal-data/selectors";
import {contractorCardInfoSelector} from "../../../../../ducks/bff/contractor-сard/selectors";
import {
    contractorActionProgressSelector,
} from "../../../../../ducks/contractor";

const TEXT_BY_STATUS = {
    [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK]: "Исполнитель внёс информацию о персональных данных. Пожалуйста, проверьте корректность данных.",
    [CONTRACTOR_STATUS.ADMINISTRATOR_CHECK_CHANGES]: "Исполнитель внёс изменения в персональные данные. Пожалуйста, проверьте корректность данных.",
};

const ContractorInfoPersonalDataChecking = (props) => {
    const {
        className,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(contractorActionProgressSelector);
    const progressCheckOnEnd = useSelector(contractorActionProgressCheckOnEndSelector);
    const contractor = useSelector(contractorCardInfoSelector);

    const role = ls(USER_ROLE);

    const {
        contractorId,
        registrationStatus,
        archived,
        personalDataCheckStatus,
    } = contractor;

    const onClickCheckPersonalData = () => {
        dispatch(getPersonalDataPendingComparisonAvailability({
            contractorId,
            onSuccess: () => {
                history.push(LINK_CONTRACTOR_VERIFICATION_DATA.replace(":contractorId", contractorId));
            },
            onError: (result) => {
                const isCheckOnError = handleContractorCheckOnError(result);

                if (isCheckOnError) {
                    return;
                }

                toastError(result.errorMessage);

                dispatch(getInfoContractorCard(contractorId));
            },
        }));
    };

    const getText = () => {
        if (personalDataCheckStatus?.checkInProgress) {
            const userInfo = getContractorCheckOnInfo(personalDataCheckStatus);

            return `Заявка исполнителя находится на проверке пользователем ${userInfo}`;
        }

        return TEXT_BY_STATUS[registrationStatus];
    };

    const onClickEndCheckOn = () => {
        dispatch(endCheckOnContractorPersonalDataStatus({
            contractorId,
            onSuccess: () => {
                dispatch(getInfoContractorCard(contractorId));
            },
        }));
    };

    return (
        <ContractorInfoBlock className={className}>
            <div
                className="flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between"
            >
                <Text
                    color={COLOR.SECONDARY_100}
                    level="4"
                >
                    {getText()}
                </Text>
                <div className="flex-column d-flex flex-xl-row mt-2 mt-xl-0">
                    {
                        personalDataCheckStatus?.checkInProgress &&
                        [ADMIN].includes(role) &&
                        isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.cancelVerificationContractor,
                            CLIENT_USER_RESTRICTIONS_VARIABLE.removeCheckContractors,
                        ]) &&
                        <NmButton
                            size="sm"
                            color="green"
                            disabled={progressCheckOnEnd}
                            onClick={onClickEndCheckOn}
                        >
                            Снять с проверки
                        </NmButton>
                    }
                    {
                        isAccessByRestrictions([
                            CLIENT_USER_RESTRICTIONS_VARIABLE.updateContractors,
                        ]) &&
                        <NmButton
                            size="sm"
                            color={personalDataCheckStatus?.checkInProgress ? "grey" : "green"}
                            className="mt-2 mt-xl-0 ms-0 ms-xl-4"
                            disabled={archived || progressAction}
                            onClick={onClickCheckPersonalData}
                        >
                            Проверить данные
                        </NmButton>
                    }
                </div>
            </div>
        </ContractorInfoBlock>
    );
};

export default ContractorInfoPersonalDataChecking;