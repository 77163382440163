import {
    BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST,
    BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST,
    BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST,
    BFF_ACT_REGISTRIES_CARD_UPDATE_STORE,
    BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST,
    BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST,
    BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST,
    BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST,
    BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST,
} from "./actions";

export const getRichActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_CARD_GET_BY_ID_REQUEST,
    payload,
});

export const checkDuplicateActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_CARD_CHECK_DUPLICATE_REQUEST,
    payload,
});

export const payActRegistry = (payload) => ({
    type: BFF_ACT_REGISTRIES_CARD_GET_PAY_REQUEST,
    payload,
});

export const paySingleAct = (payload) => ({
    type: BFF_ACT_REGISTRIES_CARD_SINGLE_PAY_REQUEST,
    payload,
});

export const getPageActRegistryPayments = (payload) => {
    return {
        type: BFF_GET_PAGE_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
};

export const addActRegistryPayment = (payload) => {
    return {
        type: BFF_ADD_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
};

export const deleteActRegistryPayments = (payload) => {
    return {
        type: BFF_DELETE_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
};

export const getByIdActRegistryPayment = (payload) => {
    return {
        type: BFF_GET_BY_ID_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
};

export const updateActRegistryPayment = (payload) => {
    return {
        type: BFF_UPDATE_ACT_REGISTRY_PAYMENT_REQUEST,
        payload,
    };
};

export const importActRegistryPayments = (payload) => {
    return {
        type: BFF_IMPORT_ACT_REGISTRY_PAYMENTS_REQUEST,
        payload,
    };
};

export const exportActRegistryPayments = (payload) => {
    return {
        type: BFF_ACT_REGISTRY_PAYMENTS_EXPORT_REQUEST,
        payload,
    };
};

export const checkForbiddenContractor = (payload) => {
    return {
        type: BFF_ACT_REGISTRY_CHECK_FORBIDDEN_CONTRACTOR_REQUEST,
        payload,
    };
};

export const updateActRegistriesCardStore = (payload) => ({
    type: BFF_ACT_REGISTRIES_CARD_UPDATE_STORE,
    payload,
});