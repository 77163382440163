import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getPageFinancesReceiptsCancelled,
    updateStoreFinancesReceiptsCancelled,
} from "../../../../ducks/bff/finances/receipts-cancelled/actionCreators";
import {updateFieldsObjectsStore} from "../../../../ducks/objects";
import {updateFieldsStoreProject} from "../../../../ducks/projects";

const useFinanceCanceledChecksFetch = (props) => {
    const {
        pageNum,
        pageSize,
        filter,
        urlClientId,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateFieldsObjectsStore({objects: []}));
            dispatch(updateFieldsStoreProject({projectsList: []}));
            dispatch(updateStoreFinancesReceiptsCancelled({
                list: [],
                pageData: {},
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageSize, pageNum, filter]);

    const fetchList = () => {
        dispatch(getPageFinancesReceiptsCancelled({
            pageNum,
            pageSize,
            clientIdFilter: urlClientId,
            ...filter,
        }));
    };

    return {
        fetchList,
    };
};

export default useFinanceCanceledChecksFetch;