import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../../components/CheckboxList";
import NmButton from "../../../../components/NmButton";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import NmSelectedList from "../../../../components/NmSelectedList";
import {ReactComponent as AddIcon} from "../../../../images/add.svg";
import {getSettingsDirectoriesAccessActions} from "../../../settings/directories/helpers/getAccessActions";
import {KedoDirectoriesTabs} from "../tabs";
import {KedoTemplatesClientUsage} from "./components/client-usage";
import {KedoTemplateDocumentTypesEdit} from "./components/edit";

import {usePagination} from "../../../../hooks/usePagination";
import {useKedoDirectoryFetchCount} from "../hooks/useFetchCount";
import {useKedoDirectoriesSort} from "../hooks/useSort";
import {useKedoTemplateDocumentTypesAction} from "./hooks/useAction";
import {useKedoTemplateDocumentTypesFetch} from "./hooks/useFetch";
import {useKedoTemplateDocumentTypesFilter} from "./hooks/useFilter";

import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../../constants/clientUserRestrictions";
import {LINK_CLIENT_INFO} from "../../../../constants/links";
import {ADMIN} from "../../../../constants/roles";

import {
    kedoDirectoriesProgressActionSelector,
    kedoDirectoriesTemplateDocumentTypesSelector,
} from "../../../../ducks/kedo/directories/selectors";

export const KedoTemplateDocumentTypes = (props) => {
    const {
        fetchCounts,
    } = useKedoDirectoryFetchCount();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    const {
        onClickSort,
        sortType,
        sortOptions,
    } = useKedoDirectoriesSort();

    const {
        totalPages,
        totalCount,
        list,
        progress,
    } = useSelector(kedoDirectoriesTemplateDocumentTypesSelector);
    const progressAction = useSelector(kedoDirectoriesProgressActionSelector);

    const isVisibleControls = getSettingsDirectoriesAccessActions({
        roles: [ADMIN],
        restriction: CLIENT_USER_RESTRICTIONS_VARIABLE.actionsDirectoriesTemplateDocTypeKedo,
    });

    const {
        onSearch,
        isSearch,
        filterData,
        onClear,
        filters,
    } = useKedoTemplateDocumentTypesFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useKedoTemplateDocumentTypesFetch({
        filterData,
        pageNum,
        pageSize,
        sortType,
    });

    const {
        modalData,
        onCloseModal,
        contentConfirm,
        editData,
        isOpenEdit,
        isOpenConfirm,
        onCloseEdit,
        onOpenEdit,
        onConfirm,
        onCancelConfirm,
        getListMediaControls,
    } = useKedoTemplateDocumentTypesAction({
        fetchList,
        fetchCounts,
        isVisibleControls,
    });
    const getCompanies = ({usedByClients}) => {
        if (isEmpty(usedByClients)) {
            return "-";
        }

        return (
            <NmSelectedList
                showedItemsCount={3}
                showListWithoutValue
                list={usedByClients.map(item => {
                    return {
                        linkTitle: "Перейти в карточку компании",
                        link: LINK_CLIENT_INFO.replace(":clientId", item.clientId),
                        text: item.name,
                    };
                })}
                dropdownWidth={250}
            />
        );
    };

    const rows = useMemo(() => {
        return list.map((item) => {
            return {
                ...item,
                key: item.documentTypeId,
                contentRow: (
                    <NmListCard
                        className="align-items-stretch"
                        primaryHeader={item.name}
                        noDivider
                        labels={[
                            {label: "Наименование для компании", text: item.nameForClient || "-"},
                            {label: "Наименование шаблона", text: item.customDocumentTemplateName || "-"},
                            {label: "Код документа", text: item.documentCode || "-"},
                            {label: "Маршрут подписания", text: item.routeName || "-"},
                            {label: "Компания", text: getCompanies(item)},
                        ]}
                        classNameMainContent="col-16 col-xxl-14"
                        isFixedActions
                        mediaControls={getListMediaControls(item)}
                    />
                ),
            };
        });
    }, [list]);

    const getClientUsage = () => {
        if (!modalData?.isOpenClientUsage) {
            return null;
        }

        return (
            <KedoTemplatesClientUsage
                documentTypeId={modalData.documentTypeId}
                fetchTemplates={fetchList}
                onClose={onCloseModal}
            />
        );
    };

    return (
        <>
            <KedoDirectoriesTabs
                isSearch={isSearch}
                templateDocumentTypesCount={totalCount}
                {...props}
            />
            <NmPage
                noPadding
                header={
                    <NmPageHeader
                        text="Типы шаблонных документов"
                        noWrapTitle={true}
                    />
                }
                controls={
                    isVisibleControls &&
                    <NmButton
                        size="xl"
                        onClick={() => {
                            onOpenEdit();
                        }}
                        icon={<AddIcon />}
                    >
                        Добавить тип
                    </NmButton>
                }
                hardVisibleFilter={false}
                filtersBase={
                    <Filter
                        initState={filterData}
                        filters={filters}
                        onSubmit={onSearch}
                        clearFilter={onClear}
                    />
                }
                typeFilter="vertical"
                totalCount={totalCount}
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                onChangePageSize={onChangePageSize}
                onPaginationChange={onPaginationChange}
                isLoaded={progress}
            >
                {getClientUsage()}
                {
                    isOpenEdit &&
                    <KedoTemplateDocumentTypesEdit
                        fetchCounts={fetchCounts}
                        fetchList={fetchList}
                        onClose={onCloseEdit}
                        editData={editData}
                    />
                }
                {
                    isOpenConfirm &&
                    <NmConfirmV2
                        mobile="column"
                        isHiddenCancelOnMobile
                        disabled={progressAction}
                        onCancel={onCancelConfirm}
                        onConfirm={onConfirm}
                        content={contentConfirm}
                        confirmButton="Да"
                        cancelButton="Нет"
                        isNeedClosing={false}
                    />
                }
                {
                    list.length === 0 ?
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                        /> :
                        <CheckboxList
                            rows={rows}
                            sort
                            sortOptions={sortOptions}
                            onClickSort={onClickSort}
                        />
                }
            </NmPage>
        </>
    );
};