import React from "react";
import {useDispatch, useSelector} from "react-redux";

import {NmPageHeader} from "../../../../components/NmPageHeader";
import UpdatingDirectories from "../../../../components/SettingsDirectoriesEdoTypes";
import {
    deleteRecruitmentDirectoryMessageTemplate,
    updateRecruitmentDirectoryStore,
} from "../../../../ducks/bff/recruitment/directory/actionCreators";
import {
    bffRecruitmentDirectoryErrorSelector,
    bffRecruitmentDirectoryMessageTemplatesListSelector,
    bffRecruitmentDirectoryMessageTemplatesTotalCountSelector,
    bffRecruitmentDirectoryMessageTemplatesTotalPagesSelector,
    bffRecruitmentDirectoryProgressActionSelector,
    bffRecruitmentDirectoryProgressSelector,
} from "../../../../ducks/bff/recruitment/directory/selectors";
import {getRecruitmentDirectoriesAccessActions} from "../helpers/getAccessActions";
import {RecruitmentMessageTemplatesEdit} from "./components/edit";

import {useFilter} from "../../../../hooks/useFilter";
import {useModal} from "../../../../hooks/useModal";
import {usePagination} from "../../../../hooks/usePagination";
import {useSuccessActionCallback} from "../../../../hooks/useSuccessAction";
import {useRecruitmentMessageTemplatesFetchList} from "./hooks/useFetchList";

import {formatLocalDate} from "../../../../utils/dateFormat";
import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";

const ClientRecruitmentDirectoriesMessageTemplates = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const dispatch = useDispatch();

    const list = useSelector(bffRecruitmentDirectoryMessageTemplatesListSelector);
    const totalCount = useSelector(bffRecruitmentDirectoryMessageTemplatesTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentDirectoryMessageTemplatesTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentDirectoryProgressSelector);
    const progressAction = useSelector(bffRecruitmentDirectoryProgressActionSelector);
    const error = useSelector(bffRecruitmentDirectoryErrorSelector);

    const {
        modalData,
        onOpenModal,
        onCloseModal,
    } = useModal();

    useSuccessActionCallback({
        callback: () => {
            if (error) {
                dispatch(updateRecruitmentDirectoryStore({
                    error: null,
                }));

                return;
            }

            onCloseModal();
        },
        progressAction,
    });

    const role = ls(USER_ROLE);
    const isAccessAction = getRecruitmentDirectoriesAccessActions({
        role,
    });

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("client-card");

    const {
        isSearch,
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        setPagination,
        pageSize,
    });

    const {
        fetchList,
    } = useRecruitmentMessageTemplatesFetchList({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    return (
        <UpdatingDirectories
            getLabels={(item) => {
                return [
                    {
                        label: "Автор",
                        text: item.clientUserName,
                    },
                    {
                        label: "Дата обновления",
                        text: formatLocalDate(item.updateDate),
                    },
                ];
            }}
            header={
                <NmPageHeader
                    noWrap={false}
                    text="Шаблоны сообщений"
                    tooltipText="Страница предназначена для управления шаблонами сообщений"
                />
            }
            openFilter={true}
            widthByFilter={true}
            titleHiddenInMobile={false}
            labelFilter="Название шаблона"
            placeholderFilter="Введите название шаблона"
            onSubmitFilter={onSearch}
            isSearch={isSearch}
            filterNameField="name"
            clearFilter={onClear}
            filter={filterData}
            isEditable={isAccessAction}
            onDelete={({id, callBack}) => {
                dispatch(deleteRecruitmentDirectoryMessageTemplate({
                    id,
                    handleResponse: callBack,
                }));
            }}
            fetchList={fetchList}
            pageNum={pageNum}
            pageSize={pageSize}
            onChangePageSize={onChangePageSize}
            onPaginationChange={onPaginationChange}
            totalPages={totalPages}
            totalCount={totalCount}
            list={list}
            addButtonText="Добавить шаблон"
            onClickAdd={() => {
                onOpenModal({
                    isOpenEdit: true,
                });
            }}
            onClickEdit={(data) => {
                onOpenModal({
                    isOpenEdit: true,
                    ...data,
                });
            }}
            popupAdd={
                modalData?.isOpenEdit &&
                <RecruitmentMessageTemplatesEdit
                    card={modalData}
                    onClose={onCloseModal}
                    clientId={clientId}
                />
            }
            popupTitleDelete="Вы действительно хотите удалить шаблон из справочника?"
            popupSubmitDelete="Подтвердить"
            popupCancelDelete="Отмена"
            isLoaded={progressList}
            isNeedClosingOnSubmit={false}
        />
    );
};

export default ClientRecruitmentDirectoriesMessageTemplates;