export const BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST = "BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST";
export const BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS = "BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS";
export const BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR = "BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR";

export const BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST = "BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST";
export const BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS = "BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS";
export const BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR = "BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR";

export const BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST = "BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST";
export const BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS = "BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS";
export const BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR = "BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR";

export const BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST = "BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST";
export const BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS = "BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS";
export const BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR = "BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR";

export const BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST = "BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST";
export const BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS = "BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS";
export const BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR = "BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR";