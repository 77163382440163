export const CONTRACT_HISTORY_ADD_REQUEST = "CONTRACT_HISTORY_ADD_REQUEST";
export const CONTRACT_HISTORY_ADD_SUCCESS = "CONTRACT_HISTORY_ADD_SUCCESS";
export const CONTRACT_HISTORY_ADD_ERROR = "CONTRACT_HISTORY_ADD_ERROR";

export const CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_REQUEST";
export const CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_SUCCESS";
export const CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR = "CONTRACT_HISTORY_GET_ACTUAL_VERSION_ERROR";

export const CONTRACT_HISTORY_GET_LIST_REQUEST = "CONTRACT_HISTORY_GET_LIST_REQUEST";
export const CONTRACT_HISTORY_GET_LIST_SUCCESS = "CONTRACT_HISTORY_GET_LIST_SUCCESS";
export const CONTRACT_HISTORY_GET_LIST_ERROR = "CONTRACT_HISTORY_GET_LIST_ERROR";

export const CONTRACT_HISTORY_GET_FILE_REQUEST = "CONTRACT_HISTORY_GET_FILE_REQUEST";
export const CONTRACT_HISTORY_GET_FILE_SUCCESS = "CONTRACT_HISTORY_GET_FILE_SUCCESS";
export const CONTRACT_HISTORY_GET_FILE_ERROR = "CONTRACT_HISTORY_GET_FILE_ERROR";

export const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_REQUEST";
export const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_SUCCESS";
export const CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR = "CONTRACT_HISTORY_UPDATE_ACTUAL_VERSION_ERROR";

export const GET_TERMINATE_CONTRACT_INFO_REQUEST = "GET_TERMINATE_CONTRACT_INFO_REQUEST";
export const GET_TERMINATE_CONTRACT_INFO_SUCCESS = "GET_TERMINATE_CONTRACT_INFO_SUCCESS";
export const GET_TERMINATE_CONTRACT_INFO_ERROR = "GET_TERMINATE_CONTRACT_INFO_ERROR";

export const CLIENT_UPDATE_NM_CONTRACT_REQUEST = "CLIENT_UPDATE_NM_CONTRACT_REQUEST";
export const CLIENT_UPDATE_NM_CONTRACT_SUCCESS = "CLIENT_UPDATE_NM_CONTRACT_SUCCESS";
export const CLIENT_UPDATE_NM_CONTRACT_ERROR = "CLIENT_UPDATE_NM_CONTRACT_ERROR";

export const CONTRACT_HISTORY_CLEAR_FIELDS = "CONTRACT_HISTORY_CLEAR_FIELDS";