import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash";

import FilterButtonsV2 from "../../../components/ActualComponents/FilterButtonsV2";
import {MediaButtons} from "../../../components/ActualComponents/MediaControls";
import NmCheckboxV2 from "../../../components/ActualComponents/NmCheckboxV2";
import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmLabelText from "../../../components/ActualComponents/NmLabelText";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import NmBadge from "../../../components/NmBadge";
import NmPage from "../../../components/NmPage";
import {NmPageHeader} from "../../../components/NmPageHeader";
import {ReactComponent as CreateIcon} from "../../../images/create.svg";
import PromoCodeChannelNew from "../promocode-channel-new";
import PromoCodeContractorModal from "../promocode-contractor-modal";

import dateFormat from "../../../utils/dateFormat";
import {addDays} from "../../../utils/dateFormat";
import {ls, USER_ROLE} from "../../../utils/localstorage";
import {isAccessByRestrictions} from "../../../utils/restrictions";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../../constants/clientUserRestrictions";
import {LINK_PROMOCODE_CHANNEL_LIST} from "../../../constants/links";
import {
    POSTFIX_PROMOTION, PROMOCODE_TYPE,
    TYPE_INFO,
    TYPE_PROMOTION,
} from "../../../constants/promocode/dict";
import {NM_CHIEF_ACCOUNTANT, NM_COORDINATOR} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    getPagePromotionChannelCardPromocode,
    getPagePromotionChannelsCard,
    updatePromotionChannelCardPromocode,
} from "../../../ducks/bff/promotion-channels/card/actionCreators";
import {
    bffPromotionChannelsCardInfoSelector,
    bffPromotionChannelsCardListSelector,
    bffPromotionChannelsCardProgressCardActionSelector,
    bffPromotionChannelsCardProgressListActionSelector,
    bffPromotionChannelsCardTotalCountSelector,
    bffPromotionChannelsCardTotalPagesSelector,
} from "../../../ducks/bff/promotion-channels/card/selectors";
import {bffPromotionChannelsPageDataSelector} from "../../../ducks/bff/promotion-channels/selectors";
import {downloadDocument} from "../../../ducks/documents";
import {
    getCheckNoActivePromocode,
    getPromocodeisExistActiveSelector,
    updateFieldPromocodeStore,
} from "../../../ducks/promocode";

import PropTypes from "prop-types";

import "./style.sass";

class PromoCodeChannelCard extends Component {
    static propTypes = {
        promocodeTotalPages: PropTypes.number,
    };

    static defaultProps = {
        promocodeTotalPages: 0,
    };

    constructor(props) {
        super(props);
        const {
            match: {
                params: {promotionId},
            },
        } = props;

        this.role = ls(USER_ROLE);

        this.promotionId = promotionId;

        this.pageSizes = [25, 50, 100];

        this.state = {
            pageNum: 1,
            pageSize: this.pageSizes[0],
            promocodeNameFilter: "",
            isOpenEditChannel: false,
            isOpenInfoByCountModal: false,
            isShowConfirmForChangeActive: false,
            promocode: {},
            sortField: "active",
            sortType: "desc",
            channelCard: {},
            isCurrentDateInPeriod: false,
            isExportWithInactive: false,
            selectedTypeInfo: undefined,
            historyData: {},
            card: {},
            isSearch: false,
        };
    }

    get isEditable() {
        return (
            ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)
            && isAccessByRestrictions([
                CLIENT_USER_RESTRICTIONS_VARIABLE.changePromotion,
            ])
        );
    }

    static getDerivedStateFromProps(props, state) {
        const {channelCard} = props;

        const {channelCard: channelCardState} = state;

        if (
            channelCard.promotionId &&
            (channelCardState.fromDate !== channelCard.fromDate ||
                channelCard.toDate !== channelCardState.toDate)
        ) {
            const currentDate = new Date().getTime();
            const toDate = addDays(new Date(channelCard.toDate), 1).getTime();
            const fromDate = new Date(channelCard.fromDate).getTime();

            return {
                ...state,
                channelCard,
                isCurrentDateInPeriod: currentDate <= toDate && currentDate >= fromDate,
            };
        }

        return null;
    }

    componentDidMount() {
        this.fetchCard();
        this.fetchPromocodeList();
    }

    componentWillUnmount() {
        const {updateFieldPromocodeStore} = this.props;

        updateFieldPromocodeStore({
            promocodeList: [],
            channelCard: {},
            isExistActive: null,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {card} = this.state;
        const {
            channelCard,
        } = this.props;
        const {
            channelCard: _channelCard,
        } = prevProps;

        if (!isEqual(channelCard, _channelCard) && !isEmpty(channelCard) && isEmpty(card)) {
            this.setState({
                card: channelCard,
            });
        }
    }

    fetchCard = () => {
        const {getPagePromotionChannelsCard} = this.props;

        getPagePromotionChannelsCard({
            promotionId: this.promotionId,
        });
    };

    fetchPromocodeList = () => {
        const {getPagePromotionChannelCardPromocode} = this.props;
        const {
            pageNum,
            pageSize,
            promocodeNameFilter,
            sortType,
            sortField,
        } = this.state;

        getPagePromotionChannelCardPromocode({
            promotionId: this.promotionId,
            pageNum,
            pageSize,
            sortType,
            sortField,
            promocode: isNullOrWhitespace(promocodeNameFilter)
                ? undefined
                : promocodeNameFilter.trim().toUpperCase(),
        });
    };

    handleOnClickBack = () => {
        const {promocodeChannelPageData: oldHistoryData} = this.props;
        history.push({
            pathname: LINK_PROMOCODE_CHANNEL_LIST,
            state: oldHistoryData,
            search: oldHistoryData.archived ? "?archived=true" : "",
        });

    };

    handleFilter = (e, {value}) => {
        this.setState({promocodeNameFilter: value});
    };

    submitFilter = () => {
        this.setState({
            pageNum: 1,
            isSearch: true,
        }, this.fetchPromocodeList);
    };

    clearFilter = () => {
        this.setState({
            promocodeNameFilter: "",
            pageNum: 1,
            isSearch: false,
        }, this.fetchPromocodeList);
    };

    downloadExcel = () => {
        const {isExportWithInactive} = this.state;

        const {
            downloadDocument,
            channelCard: {promotionId, name},
            isExistActive,
        } = this.props;

        const activeParam = isExistActive ? !isExportWithInactive : true;

        downloadDocument({
            downloadLink: `/api/documents/getPromotionPromocodesFile?promotionId=${promotionId}&active=${activeParam}`,
            documentType: name,
            extension: "xlsx",
        });

        this.closeConfirm();
    };

    getCheckNoActivePromocode = () => {
        const {
            getCheckNoActivePromocode,
            channelCard: {name},
        } = this.props;

        getCheckNoActivePromocode({
            promotionId: this.promotionId,
            name,
        });
    };

    closeEditModal = () => {
        this.setState({isOpenEditChannel: false});
    };

    editChannel = () => {
        this.setState({isOpenEditChannel: true});
    };

    openInfoByCountModal({promocode}, selectedTypeInfo) {
        this.setState({
            isOpenInfoByCountModal: true,
            promocode,
            selectedTypeInfo,
        });
    }

    closeContractorModal = () => {
        this.setState({
            isOpenInfoByCountModal: false,
            promocode: {},
            selectedTypeInfo: undefined,
        });
    };

    handleOnChangePageSize = pageSize => {
        this.setState(
            {
                pageNum: 1,
                pageSize,
            },
            this.fetchPromocodeList,
        );
    };

    handlePaginationChange = (e, {activePage: pageNum}) => {
        const {pageNum: pageNumOld} = this.state;
        if (pageNum === pageNumOld) {
            return;
        }

        this.setState(
            {
                pageNum,
            },
            this.fetchPromocodeList,
        );
    };

    getMediaControls = (item) => {
        const {
            channelCard: {archive},
        } = this.props;
        const {isCurrentDateInPeriod} = this.state;

        const {active} = item;

        return {
            renderCount: {
                mobile: 0,
                tablet: 1,
                desktop: 1,
            },
            className: "ms-1",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.showConfirmChangeActive(item);
                        },
                        color: active ? "grey" : "green",
                        children: active ? "Деактивировать" : "Активировать",
                    },
                    visible: this.isEditable && !archive && isCurrentDateInPeriod,
                },
            ],
        };
    };

    getRows() {
        const {
            promocodeList,
            channelCard: {type},
        } = this.props;

        return promocodeList.map((item) => {
            const {
                promocode,
                clientActiveCount,
                contractorActiveCount,
                active,
            } = item;

            return {
                ...item,
                contentRow: (
                    <NmListCard
                        secondaryHeaderStatus={
                            <NmBadge
                                mod={active ? "green" : "gray"}
                                text={active ? "Активен" : "Не активен"}
                            />
                        }
                        primaryHeader={promocode}
                        noDivider
                        classNameMainContent="col-16 col-xxl-14"
                        labels={[
                            type === PROMOCODE_TYPE.CLIENTS && {
                                label: "Кол-во применений заказчиками",
                                text: clientActiveCount,
                                onClickText: () => {
                                    this.openInfoByCountModal(item, TYPE_INFO.CLIENT);
                                },
                            },
                            type === PROMOCODE_TYPE.CONTRACTORS && {
                                label: "Количество применений исполнителями",
                                text: contractorActiveCount,
                                onClickText: () => {
                                    this.openInfoByCountModal(item, TYPE_INFO.CONTRACTOR);
                                },
                            },
                        ]}
                        isFixedActions
                        mediaControls={this.getMediaControls(item)}
                    />
                ),
            };
        });
    }

    closeConfirm = () => {
        const {updateFieldPromocodeStore} = this.props;

        updateFieldPromocodeStore({isExistActive: null});

        this.setState({
            promocode: {},
            isShowConfirmForChangeActive: false,
            isExportWithInactive: false,
        });
    };

    showConfirmChangeActive(promocode) {
        this.setState({
            isShowConfirmForChangeActive: true,
            promocode,
        });
    }

    changeActivePromocode = () => {
        const {updatePromotionChannelCardPromocode} = this.props;
        const {promocode} = this.state;

        updatePromotionChannelCardPromocode({
            ...promocode,
            active: !promocode.active,
            onSuccess: this.fetchPromocodeList,
        });
        this.closeConfirm();
    };

    getMediaButtons = () => {
        const {
            channelCard: {
                archive,
            },
        } = this.props;

        return ({
            renderCount: {
                desktop: 2,
                tablet: 0,
                mobile: 0,
            },
            size: "xl",
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: this.getCheckNoActivePromocode,
                        children: "Экспорт в Excel",
                        color: "light-green",
                    },
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: this.editChannel,
                        children: "Редактировать",
                        icon: <CreateIcon />,
                        color: "grey",
                    },
                    visible: this.isEditable && !archive,
                },
            ],
        });
    };

    renderContentConfirmWindow() {
        const {
            promocode: {active, promocode},
            isExportWithInactive,
            isShowConfirmForChangeActive,
        } = this.state;

        const {isExistActive, t} = this.props;

        const question = isShowConfirmForChangeActive
            ? `${t("promo-code-channel-card.really-want")} ${
                active ? t("promo-code-channel-card.deactivate") : t("promo-code-channel-card.activate")
            } ${t("promo-code-channel-card.promocode")} "${promocode}" ?`
            : `${t("promo-code-channel-card.really-want-to-export")}?`;

        if (!isShowConfirmForChangeActive) {
            return (
                <div className="confirm-content">
                    <div className="confirm-content__question">
                        {question}
                    </div>
                    {isExistActive ? (
                        <div>
                            <NmCheckboxV2
                                label={t("promo-code-channel-card.export-inactive")}
                                checked={isExportWithInactive}
                                onChange={(e, {checked}) => {
                                    this.setState({isExportWithInactive: checked});
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            );
        }

        return question;
    }

    renderConfirmWindow = () => {
        const {isExistActive} = this.props;

        const {isShowConfirmForChangeActive} = this.state;

        //isExistActive может содержать три значения: null - когда проверки еще не было, false/true - проверка была
        return (
            (isExistActive === true ||
                isExistActive === false ||
                isShowConfirmForChangeActive) &&
            <NmConfirmV2
                content={this.renderContentConfirmWindow()}
                onCancel={this.closeConfirm}
                onConfirm={
                    isShowConfirmForChangeActive
                        ? this.changeActivePromocode
                        : this.downloadExcel
                }
                confirmButton="Да"
                cancelButton="Нет"
            />
        );
    };

    renderChannelInfo = () => {
        const {
            channelCard: {
                applicationCount,
                activeCount,
                count,
                fromDate,
                toDate,
                word,
                promotionId,
                postfixPromotion,
                typePromotion,
            },
        } = this.props;

        return (
            <div className="flex flex-wrap">
                <div className="col-16 col-md-8 col-xl-4">
                    <NmLabelText
                        type="page"
                        label="Всего применений"
                        text={applicationCount}
                    />
                    <NmLabelText
                        type="page"
                        label="Активно"
                        text={<div>
                            <span
                                style={{fontWeight: "bold"}}
                            >
                                {activeCount}
                            </span>
                            /
                            {count}
                        </div>}
                    />
                    <NmLabelText
                        type="page"
                        label="Период активности"
                        text={`${dateFormat(fromDate, "dd.MM.yyyy")} - ${dateFormat(toDate, "dd.MM.yyyy")}`}
                    />
                </div>
                <div className="col-16 col-md-8 col-xl-12">
                    <NmLabelText
                        type="page"
                        label="Фиксированное слово/числовая последовательность"
                        text={word}
                    />
                    {
                        promotionId &&
                        <NmLabelText
                            type="page"
                            label="Постфикс"
                            text={POSTFIX_PROMOTION[postfixPromotion]?.TEXT}
                        />
                    }
                    {
                        promotionId &&
                        <NmLabelText
                            type="page"
                            label="Использование промо-кода"
                            text={TYPE_PROMOTION[typePromotion]?.TEXT}
                        />
                    }
                </div>
            </div>
        );
    };

    get sortOptions() {
        const {
            sortType,
        } = this.state;

        return [
            {
                key: "clientActiveCount",
                value: "clientActiveCount",
                text: "По количеству применений",
                sortType: sortType,
                asc: "asc",
                desc: "desc",
                sortName: "clientActiveCount",
                isDefaultSort: true,
            },
            {
                key: "active",
                value: "active",
                sortType: sortType,
                sortName: "active",
                text: "По активности",
                asc: "asc",
                desc: "desc",
            },
        ];
    }

    onClickSort({sortType, sortName}) {
        this.setState({
            sortField: sortName,
            sortType,
        }, this.fetchPromocodeList);
    }

    render() {
        const {
            channelCard,
            promocodeTotalPages,
            t,
            progressCard,
            progressList,
            promocodeTotalCount,
            promocodeList,
        } = this.props;

        const {
            pageNum,
            pageSize,
            promocodeNameFilter,
            isOpenEditChannel,
            isOpenInfoByCountModal,
            promocode,
            selectedTypeInfo,
            isSearch,
        } = this.state;

        return (
            <NmPage
                typeFilter="vertical"
                className="promocode-list"
                currentPageSize={pageSize}
                currentPageNum={pageNum}
                totalPages={promocodeTotalPages}
                totalCount={promocodeTotalCount}
                onChangePageSize={this.handleOnChangePageSize}
                onPaginationChange={this.handlePaginationChange}
                isLoaded={progressCard || progressList}
                header={
                    <NmPageHeader
                        text={`${t("promo-code-channel-card.attraction-channel")} - ${channelCard.name}`}
                        handleOnClickBack={this.handleOnClickBack}
                    />
                }
                subHeader={this.renderChannelInfo()}
                controls={
                    <MediaButtons
                        size="xl"
                        config={this.getMediaButtons()}
                    />
                }
                filtersBase={
                    <NmForm addMargin>
                        <NmInputV2
                            label="Промокод"
                            size="lg"
                            onChange={this.handleFilter}
                            value={promocodeNameFilter}
                            placeholder={t("promo-code-channel-card.enter-promocode")}
                        />
                        <FilterButtonsV2
                            onSearch={this.submitFilter}
                            onClear={this.clearFilter}
                        />
                    </NmForm>
                }
            >
                {isOpenInfoByCountModal ? (
                    <PromoCodeContractorModal
                        promocode={promocode}
                        close={this.closeContractorModal}
                        selectedTypeInfo={selectedTypeInfo}
                    />
                ) : null}
                {isOpenEditChannel ? (
                    <PromoCodeChannelNew
                        close={this.closeEditModal}
                        channelEdit={channelCard}
                    />
                ) : null}
                {this.renderConfirmWindow()}
                {
                    promocodeList.length ?
                        <CheckboxList
                            sort
                            sortOptions={this.sortOptions}
                            onClickSort={(data) => this.onClickSort(data)}
                            rows={this.getRows()}
                        /> :
                        <NmEmptyPageV2
                            title="Данные отсутствуют"
                            isSearch={isSearch}
                            fetchProgress={progressList}
                        />
                }
            </NmPage>
        );
    }
}

export default connect(
    state => ({
        channelCard: bffPromotionChannelsCardInfoSelector(state),
        promocodeList: bffPromotionChannelsCardListSelector(state),
        promocodeTotalPages: bffPromotionChannelsCardTotalPagesSelector(state),
        promocodeTotalCount: bffPromotionChannelsCardTotalCountSelector(state),
        isExistActive: getPromocodeisExistActiveSelector(state),
        location: state.router.location,
        progressCard: bffPromotionChannelsCardProgressCardActionSelector(state),
        progressList: bffPromotionChannelsCardProgressListActionSelector(state),
        promocodeChannelPageData: bffPromotionChannelsPageDataSelector(state),
    }),
    {
        getPagePromotionChannelsCard,
        getPagePromotionChannelCardPromocode,
        updateFieldPromocodeStore,
        downloadDocument,
        getCheckNoActivePromocode,
        updatePromotionChannelCardPromocode,
    },
)(withTranslation()(PromoCodeChannelCard));
