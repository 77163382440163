import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {handleFormString} from "../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../constants/page";

import {
    getSettingsUsersRolesRichPage,
    settingsUsersRolesOptionsSelector,
    updateFieldSettingsUsersRoles,
} from "../../../ducks/settingsUsersRoles";

export const useUserRolesDropdown = (params) => {
    const {
        clientId,
        clientContractorId,
        type,
    } = params;
    const dispatch = useDispatch();

    const options = useSelector(settingsUsersRolesOptionsSelector);

    useEffect(() => {
        return () => {
            dispatch(updateFieldSettingsUsersRoles({
                list: [],
                pageData: {},
                totalCount: 0,
            }));
        };
    }, []);

    const fetchList = (fioFilter) => {
        dispatch(getSettingsUsersRolesRichPage({
            pageNum: 1,
            pageSize: PAGE_DEFAULT_SIZE,
            name: handleFormString(fioFilter),
            archived: false,
            type,
            clientId,
            clientContractorId,
        }));
    };

    const onSearchChange = (nameFilter) => {
        fetchList(nameFilter);
    };

    return {
        options,
        label: "Роль",
        placeholder: "Выберите роль",
        onSearchChange,
        search: true,
    };
};