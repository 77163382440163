import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR,
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST,
    BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST,
    BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST,
    BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST,
    BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS,
    BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR,
    BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST,
    BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS,
} from "./actions";

import {downloadBlob} from "../../../../../utils/downloadBlob";
import {
    ACCESS_TOKEN_KEY,
    ls,
} from "../../../../../utils/localstorage";
import request, {getMultipartHeaders} from "../../../../../utils/postman";
import {
    toastError,
    toastSuccess,
} from "../../../../../utils/toastHelper";
import {getBffControllerClientCardPage} from "../../../../../utils/url";

const getController = () => {
    return getBffControllerClientCardPage({
        admin: "/adm/clients/client-card/orders/order-groups/order-group-card",
        client: "/client-adm/orders/order-groups/order-group-card",
    });
};

// POST /bff/adm/clients/client-card/orders/order-groups/order-group-card/get-order-group-by-id
// POST /bff/client-adm/orders/order-groups/order-group-card/get-order-group-by-id
export const getOrdersOrderGroupByIdSaga = function* ({payload} ) {
    try {
        const result = yield request.bff.post(`${getController()}/get-order-group-by-id`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_GET_ORDER_GROUP_BY_ID_ERROR,
            payload: error,
        });
    }
};

// POST /bff/adm/clients/client-card/orders/order-groups/order-group-card/upload-order-list
// POST /bff/client-adm/orders/order-groups/order-group-card/upload-order-list
// Загрузить список
const uploadOrdersOrderListSaga = function* ({payload}) {
    try {
        const {
            formData,
            clientId,
            orderGroupId,
            type,
            onSuccess,
        } = payload;

        const result = yield request.bff.post(
            `${getController()}/import/${clientId}/${orderGroupId}/${type}`,
            formData,
            {...getMultipartHeaders()},
        );

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        toastSuccess("Задача по импорту заказов передана в обработку");

        yield put({
            type: BFF_ORDERS_UPLOAD_ORDER_LIST_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_UPLOAD_ORDER_LIST_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-groups/order-group-card/download-order-groups
// POST bff/client-adm/orders/order-groups/order-group-card/download-order-groups
// Выгрузить
export const downloadOrdersOrderGroupsSaga = function* ({payload}) {
    try {
        const requestHeaders = {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization:
                    `Bearer ${ls(ACCESS_TOKEN_KEY)}`
                ,
                "Content-Type": "application/json",
            },
        };

        const result = yield request.bff.getFile(`${getController()}/download-order-groups`, requestHeaders);

        const {headers} = result;

        if (
            result.status === 200
            && result.headers.get("Content-Type")
            && result.headers.get("Content-Type").includes("json")
        ) {
            const data = yield result.json();

            if (data) {
                const {errorMessage} = data;

                toastError(errorMessage);
                yield put({
                    type: BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR,
                    payload: result,
                });
                return {
                    done: true,
                };
            }
        }

        const blob = yield result.blob();
        yield downloadBlob(blob, headers);

        yield put({
            type: BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        toastError(error.message);

        yield put({
            type: BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_ERROR,
            payload: error.message,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-groups/order-group-card/add-orders-to-group
// POST bff/client-adm/orders/order-groups/order-group-card/add-orders-to-group
// Добавить заказ в группу
export const addOrdersToGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/add-orders-to-group`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDERS_ADD_ORDERS_TO_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_ADD_ORDERS_TO_GROUP_ERROR,
            payload: error,
        });
    }
};

// POST bff/adm/clients/client-card/orders/order-groups/order-group-card/delete-orders-from-group
// POST bff/client-adm/orders/order-groups/order-group-card/delete-orders-from-group
// Удалить заказ из группы
export const deleteOrdersFromGroupSaga = function* ({payload}) {
    try {
        const {
            onSuccess,
        } = payload;

        const result = yield request.bff.post(`${getController()}/delete-orders-from-group`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR,
                payload: result,
            });
            return {
                done: true,
            };
        }

        if (onSuccess) {
            onSuccess();
        }

        yield put({
            type: BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_ORDERS_GET_ORDER_GROUP_BY_ID_REQUEST, getOrdersOrderGroupByIdSaga),
        takeEvery(BFF_ORDERS_UPLOAD_ORDER_LIST_REQUEST, uploadOrdersOrderListSaga),
        takeEvery(BFF_ORDERS_DOWNLOAD_ORDER_GROUPS_REQUEST, downloadOrdersOrderGroupsSaga),
        takeEvery(BFF_ORDERS_ADD_ORDERS_TO_GROUP_REQUEST, addOrdersToGroupSaga),
        takeEvery(BFF_ORDERS_DELETE_ORDERS_FROM_GROUP_REQUEST, deleteOrdersFromGroupSaga),
    ]);
}