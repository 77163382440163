import {DEPOSIT_SUB_PAGE} from "../deposit/client-list";

import {filterDynamicLinks} from "../../utils/clientHelper";
import {isAccessByRestrictions} from "../../utils/restrictions";

import {CLIENT_WORK_TYPE} from "../../constants/clientSettings";
import {CLIENT_USER_RESTRICTIONS_VARIABLE} from "../../constants/clientUserRestrictions";
import {CONTRACTORS_TABS_TYPES} from "../../constants/contractor";
import {CHAT_LINK_PARAMS} from "../../constants/disputes";
import {
    LINK_CLIENT_ADVERTISEMENT,
    LINK_CLIENT_AGENCY_CONTRACT,
    LINK_CLIENT_CARD_CRM_TICKET_LIST_IN_WORK,
    LINK_CLIENT_CARD_TEMPLATES_ACTS_ORDERS,
    LINK_CLIENT_CARD_TEMPLATES_ACTS_TASKS,
    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_ORDERS,
    LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_TASKS,
    LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST,
    LINK_CLIENT_CARD_TEMPLATES_OTHER_DOCUMENTS,
    LINK_CLIENT_CONTRACTORS_LIST,
    LINK_CLIENT_CROWD_TASK_REGISTRY,
    LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST,
    LINK_CLIENT_DEPOSIT_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
    LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
    LINK_CLIENT_DOCUMENT_LIST,
    LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST,
    LINK_CLIENT_FINANCE_EXPORT_FULL,
    LINK_CLIENT_INFO,
    LINK_CLIENT_KEDO_DOCUMENTS,
    LINK_CLIENT_KEDO_EXPORTS,
    LINK_CLIENT_KEDO_LOGS,
    LINK_CLIENT_KEDO_MEMBERS,
    LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS,
    LINK_CLIENT_KEDO_ORGANIZATION_STAFF_REGISTRY,
    LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE,
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS,
    LINK_CLIENT_NDFL_DEPOSIT_LIST,
    LINK_CLIENT_NDFL_PAYMENTS_LIST,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST,
    LINK_CLIENT_NOTIFICATIONS_LIST,
    LINK_CLIENT_OPERATOR_REPORTS,
    LINK_CLIENT_ORDER_LIST,
    LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST,
    LINK_CLIENT_RECRUITMENT,
    LINK_CLIENT_RECRUITMENT_VACANCIES,
    LINK_CLIENT_REGISTRY_FC_LIST,
    LINK_CLIENT_STANDART_DOCUMENTS,
    LINK_CRM_TICKET,
    LINK_DISPUTES_CLIENT_ADMIN,
    LINK_OBJECTS,
    LINK_ORDER_ACT_REGISTRY,
    LINK_ORDER_GROUPS_LIST,
    LINK_ORDER_LIST,
    LINK_ORDER_REGISTRY_INVITATIONS,
    LINK_ORDER_TEMPLATES_LIST,
    LINK_PROJECTS,
} from "../../constants/links";
import {
    ADMIN,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    RECRUITMENT_OBSERVER,
} from "../../constants/roles";

import {BRIGADE_ORDER_OBJECT_OBJECT} from "../../constants/link-params";

/*
* TODO: в компоненте используется хелпер filterDynamicLinks.
*  В будущем желательно эту фильтрацию реализовать в конфиге, сделать поле isVisible,
*  примеры в проекте присутствуют
*/
export function ClientCardTabs(params) {
    const {
        clientId,
        pathname,
        role,
        clientProperties,
    } = params;

    const {
        insuranceAvailable,
        foremanEnabled,
        edmAvailable,
        migrantLicensePaymentEnabled,
        civilRegistryPaymentsAvailable,
        individualRegistryPaymentsAvailable,
        isRecruitmentAvailable,
        crowdTasksAvailable,
        withoutContract,
        retailClient,
    } = clientProperties;

    const isAccessClientResources = [ADMIN, NM_MANAGER, NM_COORDINATOR].includes(role) && retailClient;
    const isAccessClientTabs = isAccessByRestrictions([
        CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTabs,
    ]);

    const financeOrderPaymentListLink = LINK_CLIENT_PAYMENTS_ORDERS_LIST.replace(":clientId", clientId);

    const getLinkClientCardKedo = () => {
        if (isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessEmployeesKedo,
        ])) {
            return LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId);
        }

        if (isAccessByRestrictions([
            CLIENT_USER_RESTRICTIONS_VARIABLE.accessDocumentFlowKedo,
        ])) {
            return LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId);
        }

        return LINK_CLIENT_KEDO_ORGANIZATION_STRUCTURE;
    };

    const tab = {
        orders: {
            name: "Заказы",
            link: LINK_CLIENT_ORDER_LIST.replace(":clientId", clientId),
            active: [
                LINK_ORDER_LIST,
                LINK_ORDER_TEMPLATES_LIST,
                LINK_ORDER_GROUPS_LIST,
                LINK_ORDER_REGISTRY_INVITATIONS,
                LINK_ORDER_ACT_REGISTRY,
            ],
            exceptions: [LINK_PROJECTS, BRIGADE_ORDER_OBJECT_OBJECT, "analytic"],
        },
        payments: {
            name: "Финансы",
            link: role === NM_OPERATOR ? financeOrderPaymentListLink : LINK_CLIENT_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", DEPOSIT_SUB_PAGE.REPLENISHMENT_AND_REFUNDS),
            active: [
                "/finance",
                "/deposit",
                LINK_CLIENT_NDFL_DEPOSIT_LIST.replace(":clientId", clientId).replace(":subPage", ""),
                LINK_CLIENT_NDFL_PAYMENTS_LIST.replace(":clientId", clientId)
                    .replace("/:paymentNumberFilter?", ""),
            ],
            exceptions: ["export", "upload", "insurance"],
        },
        contractorList: {
            name: "Исполнители",
            link: LINK_CLIENT_CONTRACTORS_LIST.replace(":clientId", clientId).replace(":type", CONTRACTORS_TABS_TYPES.FAVORITE),
            active: ["/contractors"],
            exceptions: ["projects"],
        },
        documents: {
            name: "Документы",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientDocuments,
            link: LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId),
            active: [
                LINK_CLIENT_DOCUMENT_LIST.replace(":clientId", clientId),
                LINK_CLIENT_AGENCY_CONTRACT.replace(":clientId", clientId),
                LINK_CLIENT_STANDART_DOCUMENTS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST.replace(":clientId", clientId),
                LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_ORDERS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_TASKS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_ACTS_ORDERS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_ACTS_TASKS.replace(":clientId", clientId),
                LINK_CLIENT_CARD_TEMPLATES_OTHER_DOCUMENTS.replace(":clientId", clientId),
                LINK_CLIENT_OPERATOR_REPORTS.replace(":clientId", clientId),
                LINK_CLIENT_EXPORT_DOCUMENT_LIST.replace(":clientId", clientId),
                LINK_CLIENT_REGISTRY_FC_LIST.replace(":clientId", clientId),
                LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST.replace(":clientId", clientId),
                LINK_CLIENT_DEFERRED_ACTS_OF_ACCEPTANCE_WORK_DOCUMENT_LIST.replace(":clientId", clientId),
            ],
            exceptions: ["document-management"],
        },
        edo: {
            id: "edo",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessEdo,
            name: "ЭДО",
            link: LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST.replace(":clientId", clientId),
            active: pathname.includes("document-management"),
        },
        kedo: {
            id: "kedo",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessKedo,
            name: "КЭДО",
            link: role === NM_OPERATOR ?
                LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId) :
                getLinkClientCardKedo(),
            active: [
                role === NM_OPERATOR ?
                    LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId) :
                    LINK_CLIENT_KEDO_MEMBERS.replace(":clientId", clientId),
                LINK_CLIENT_KEDO_ORGANIZATION_DEPARTMENTS.replace(":clientId", clientId),
                LINK_CLIENT_KEDO_ORGANIZATION_STAFF_REGISTRY.replace(":clientId", clientId),
                LINK_CLIENT_KEDO_DOCUMENTS.replace(":clientId", clientId),
                LINK_CLIENT_KEDO_EXPORTS.replace(":clientId", clientId),
                LINK_CLIENT_KEDO_LOGS.replace(":clientId", clientId),
            ],
            isVisible: [
                ADMIN,
                NM_MANAGER,
                NM_OPERATOR,
            ].includes(role) &&
                [
                    CLIENT_WORK_TYPE.NAIMIX_KEDO,
                    CLIENT_WORK_TYPE.ONLY_KEDO,
                ].includes(clientProperties.clientWorkType),
        },
        patents: {
            id: "patents",
            name: "Патенты",
            link: LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST.replace(":clientId", clientId),
            active: [
                "/patents",
                LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST,
                LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS,
            ],
        },
        reports: {
            id: "reports",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessFinancesExport,
            name: "Отчеты",
            link: LINK_CLIENT_FINANCE_EXPORT_FULL.replace(":clientId", clientId),
            active: ["finances/export", "upload", "order-analytics", "/reports"],
        },
        company: {
            id: "company",
            name: "Компания",
            link: LINK_CLIENT_INFO.replace(":clientId", clientId),
            exceptions: [
                LINK_CLIENT_RECRUITMENT.replace(":clientId", clientId),
            ],
            active: ["info", "members", "projects", "brigade", "feedback-list", LINK_OBJECTS],
        },
        dispute: {
            name: "Споры",
            link: LINK_DISPUTES_CLIENT_ADMIN
                .replace(":clientId", clientId)
                .replace(CHAT_LINK_PARAMS.LINK_STATUS_PARAM, CHAT_LINK_PARAMS.OPEN)
                .replace(CHAT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, 1)
                .replace(CHAT_LINK_PARAMS.LINK_PAGESIZE_PARAM, 25)
                .replace(CHAT_LINK_PARAMS.LINK_DISPUTEID_PARAM, "")
                .replace(CHAT_LINK_PARAMS.LINK_ORDERID_PARAM, ""),
            active: ["disputes"],
            exceptions: ["order-list"],
        },
        notifications: {
            id: "notifications",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientEmailNotifications,
            name: "Уведомления",
            link: LINK_CLIENT_NOTIFICATIONS_LIST.replace(":clientId", clientId),
            active: ["notifications-list", "setting-notifications"],
        },
        advertisement: {
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessAdvertisement,
            name: "Объявления",
            link: LINK_CLIENT_ADVERTISEMENT.replace(":clientId", clientId),
            active: ["advertisement"],
        },
        tickets: {
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientTickets,
            name: "Обращения",
            link: LINK_CLIENT_CARD_CRM_TICKET_LIST_IN_WORK.replace(":clientId", clientId),
            active: [LINK_CRM_TICKET],
        },
        recruitment: {
            id: "recruitment",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessRecruitmentAdmin,
            name: "Рекрутмент",
            link: LINK_CLIENT_RECRUITMENT_VACANCIES.replace(":clientId", clientId),
            active: ["/recruitment"],
        },
        crowd: {
            id: "crowd",
            restrictionVariable: CLIENT_USER_RESTRICTIONS_VARIABLE.accessClientCrowdTasks,
            name: "Задания",
            link: LINK_CLIENT_CROWD_TASK_REGISTRY.replace(":clientId", clientId),
            active: [
                "/crowd",
            ],
        },
    };

    if (!isAccessClientTabs) {
        return [
            tab.company,
            tab.advertisement,
        ];
    }

    if (role === RECRUITMENT_OBSERVER) {
        return isAccessClientTabs
            ? [
                tab.recruitment,
            ]
            : [];
    }

    if (!isAccessClientTabs) {
        return [
            tab.company,
        ];
    }

    if (role === NM_CONSULTANT) {
        const tabs = [
            tab.company,
            tab.advertisement,
        ];

        return tabs.filter(item => filterDynamicLinks({
            item,
        }));
    }

    if (role === NM_OPERATOR) {
        const tabs = [
            tab.orders,
            tab.crowd,
            tab.recruitment,
            tab.advertisement,
            tab.payments,
            tab.contractorList,
            tab.kedo,
            tab.patents,
            tab.company,
            tab.dispute,
            tab.tickets,
        ];

        return tabs.filter((item) => filterDynamicLinks({
            item,
            checks: {
                isRecruitmentAvailable,
                crowdTasksAvailable,
            },
        }));
    }

    const crowdTab = ![NM_COORDINATOR].includes(role) ? [tab.crowd] : [];

    const tabs = [
        tab.orders,
        ...crowdTab,
        tab.recruitment,
        tab.advertisement,
        tab.payments,
        tab.contractorList,
        tab.documents,
        tab.edo,
        tab.kedo,
        tab.patents,
        tab.reports,
        tab.company,
        tab.dispute,
        tab.notifications,
    ];

    if ([ADMIN, NM_MANAGER, NM_COORDINATOR].includes(role)) {
        tabs.push(tab.tickets);
    }

    return tabs.filter((item) => filterDynamicLinks({
        item,
        checks: {
            edmAvailable,
            foremanEnabled,
            isAccessClientResources,
            insuranceAvailable,
            civilRegistryPaymentsAvailable,
            individualRegistryPaymentsAvailable,
            migrantLicensePaymentEnabled,
            isRecruitmentAvailable,
            crowdTasksAvailable,
            withoutContract,
        },
    }));
}