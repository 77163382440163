import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DepositChangeModal from "../../../components/DepositChangeModal";

import {returnClientDeposit} from "../../../ducks/bff/clients/projects/actionCreators";
import {clientProjectsProgressActionSelector} from "../../../ducks/bff/clients/projects/selectors";

const ProjectReturnDeposit = (props) => {
    const {
        name,
        onClose,
        clientId,
        objectId,
        projectId,
        fetchData = () => {},
    } = props;
    const dispatch = useDispatch();
    const progress = useSelector(clientProjectsProgressActionSelector);

    const onSubmit = (requestData) => {
        const {
            amount,
        } = requestData;

        dispatch(returnClientDeposit({
            amount,
            clientId,
            objectId,
            projectId,
            paymentType: requestData.type,
            onSuccess: () => {
                fetchData();
                onClose();
            },
        }));
    };

    return (
        <DepositChangeModal
            isReturnDeposit={true}
            onClose={onClose}
            targetText="проекта"
            targetName={name}
            onSubmit={onSubmit}
            progress={progress}
            lastPartConfirmText={" в депозит компании"}
            text="Вернуть депозит проекта в депозит компании для выплат по счету"
        />
    );
};

export default ProjectReturnDeposit;