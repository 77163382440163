import {BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST,BFF_BANK_TKB_UPDATE_302_REQUEST} from "./actions";

export const getPageBankTkbHistory = (payload) => {
    return {
        type: BFF_BANK_TKB_HISTORY_GET_PAGE_REQUEST,
        payload,
    };
};

export const update302FromBankTkb = (payload) => {
    return {
        type: BFF_BANK_TKB_UPDATE_302_REQUEST,
        payload,
    };
};