import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import NmConfirmV2 from "../../../../components/ActualComponents/NmConfirmV2";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import ButtonBack from "../../../../components/ButtonBack";
import {withPageData} from "../../../../components/withPageData";
import AbstractInviteContractorsPage from "../abstract-invite-contractors-page";
import NmGeneralContractorListFilter from "../nm-general-contractor-list-filter";

import {clearSpace, removePhoneMask} from "../../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../../utils/stringHelper";

import {COMPONENT} from "../../../../components/ActualComponents/MediaControls/constants";
import {CONTRACTOR_LIST_FILTER_TYPE, SORT_TYPE} from "../../../../constants/contractorList";
import {LINK_SETTINGS_CLIENT_GROUP_CARD} from "../../../../constants/links";

import {history} from "../../../../store/configureStore";

import {
    addSettingsClientGroupsCardSearchContractors,
    clearStoreSettingsClientGroupsSearchContractors,
    getPageSettingsClientGroupsCardSearchContractors,
} from "../../../../ducks/bff/settings/client-groups/card/search-contractors/actionCreators";
import {
    settingsClientGroupsCardSearchContractorsListSelector,
    settingsClientGroupsCardSearchContractorsProgressSelector,
    settingsClientGroupsCardSearchContractorsTotalCountSelector,
    settingsClientGroupsCardSearchContractorsTotalPagesSelector,
} from "../../../../ducks/bff/settings/client-groups/card/search-contractors/selectors";

import "./style.sass";

class AddContractorsToClientGroupForm extends Component {
    static propTypes = {};

    static defaultProps = {
        sendFilter: () => {
        },
    };

    constructor(props) {

        super(props);

        const {
            match: {
                params: {
                    groupId,
                },
            },
            filter,
        } = props;

        this.state = {
            filterData: {...filter},
            pageNum: 1,
            pageSize: 25,
        };

        this.groupId = groupId;
    }

    componentWillUnmount() {
        const {
            clearStoreSettingsClientGroupsSearchContractors,
        } = this.props;

        clearStoreSettingsClientGroupsSearchContractors();
    }

    fetchList = (pageNum, pageSize) => {
        this.setState({
            pageNum,
            pageSize,
        });

        const {
            getPageSettingsClientGroupsCardSearchContractors,
        } = this.props;

        const {
            filterData,
            pageNum: savedPageNum,
            pageSize: savedPageSize,
        } = this.state;

        const {
            innFilter,
            sortType,
        } = filterData;

        const baseSortType = SORT_TYPE.FULL_REGISTRATION_JOIN_DATE_DESC;

        const requestData = {
            pageNum: pageNum || savedPageNum,
            pageSize: pageSize || savedPageSize,
            clientId: this.clientId,
            ...filterData,
            initRegionValueArr: undefined,
            innFilter: isNullOrWhitespace(innFilter) ? null : clearSpace(innFilter),
            sortType: isNullOrWhitespace(sortType) ? baseSortType : sortType,
            phoneFilter: removePhoneMask(filterData.phoneFilter),
        };

        getPageSettingsClientGroupsCardSearchContractors(requestData);
    };

    sendFilter = (filterData, isSearch = true) => {
        return () => {
            this.setState({
                pageNum: 1,
                filterData: {...filterData},
                isSearch,
            }, this.fetchList);
        };
    };

    addContractor = (item) => {
        const {
            addSettingsClientGroupsCardSearchContractors,
        } = this.props;

        const {
            contractorId,
        } = item;

        return () => {
            addSettingsClientGroupsCardSearchContractors({
                contractorId,
                groupId: this.groupId,
                onSuccess: () => {
                    this.changeConfirm(false, null)();
                    this.fetchList();
                },
            });
        };
    };

    changeConfirm = (isShowAddingConfirm, item) => {
        return () => {
            this.setState({
                isShowAddingConfirm,
                item,
            });
        };
    };

    renderFilter = () => {
        const {
            filterData,
        } = this.state;

        return (
            <NmGeneralContractorListFilter
                filterType={CONTRACTOR_LIST_FILTER_TYPE.ADMIN_FILTER}
                filter={filterData}
                sendFilter={this.sendFilter}
            />
        );
    };

    renderActions = (item) => {
        const {
            clientGroupId,
        } = item;

        let addBtn = null;

        if (clientGroupId === this.groupId) {
            addBtn = {
                component: COMPONENT.TEXT,
                props: {
                    onClick: ()=>{},
                    children: "Добавлен в группу компаний",
                },
                visible: clientGroupId === this.groupId,
                disabled: true,
            };
        } else {
            addBtn = {
                component: COMPONENT.BUTTON,
                props: {
                    onClick: this.changeConfirm(true, item),
                    children: "Добавить",
                },
                visible: clientGroupId !== this.groupId,
            };
        }

        return {
            renderCount: {
                mobile: 0,
                tablet: 0,
                desktop: 1,
            },
            buttons: [
                addBtn,
            ],
        };
    };

    renderConfirm() {
        const {
            isShowAddingConfirm,
            item,
        } = this.state;

        if (!isShowAddingConfirm) {
            return null;
        }

        return (
            <NmConfirmV2
                classNameHeader="add-contractors-to-group-form__confirm"
                content="Вы действительно хотите добавить исполнителя в группу компаний?"
                confirmButton="Подтвердить"
                cancelButton="Отменить"
                onConfirm={this.addContractor(item)}
                onCancel={this.changeConfirm(false, null)}
            />
        );
    };

    render() {
        const {
            pageNum,
            pageSize,
        } = this.state;

        const {
            list,
            progressList,
            totalPages,
            totalCount,
        } = this.props;

        return (
            <div className="add-contractors-to-group-form">
                {this.renderConfirm()}
                <AbstractInviteContractorsPage
                    pageHeader={
                        <div className="add-contractors-to-group-form__header">
                            <ButtonBack
                                onClick={() => history.replace(`${LINK_SETTINGS_CLIENT_GROUP_CARD
                                    .replace(":groupId", this.groupId)
                                    .replace(":tab", "contractors")
                                }`)}
                            />
                            <div className="add-contractors-to-group-form__header-title">
                                Поиск исполнителей для добавления в группу компаний
                            </div>
                        </div>
                    }
                    list={list}
                    fetchListFn={this.fetchList}
                    progress={progressList}
                    totalPages={totalPages}
                    totalCount={totalCount}
                    pageNum={pageNum}
                    pageSize={pageSize}
                    filter={this.renderFilter()}
                    actions={this.renderActions}
                    emptyData={<NmEmptyPageV2 />}
                />
            </div>
        );
    };
}

export default withPageData(connect(
    state => ({
        list: settingsClientGroupsCardSearchContractorsListSelector(state),
        progressList: settingsClientGroupsCardSearchContractorsProgressSelector(state),
        totalPages: settingsClientGroupsCardSearchContractorsTotalPagesSelector(state),
        totalCount: settingsClientGroupsCardSearchContractorsTotalCountSelector(state),
    }),
    {
        getPageSettingsClientGroupsCardSearchContractors,
        addSettingsClientGroupsCardSearchContractors,
        clearStoreSettingsClientGroupsSearchContractors,
    },
)(withTranslation()(AddContractorsToClientGroupForm)));